/* eslint-disable*/
import React from "react";
import Settlements_ViewSettlement from "./ViewSettlement";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ViewSettlement Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ViewSettlement />);
    });
  });
  afterEach(cleanup);
  test("is ViewSettlement Loads Successfully", () => {
    expect(screen.getByText("ViewSettlement")).toBeInTheDocument;
  });
  test("Custom Test Cases for ViewSettlement", () => {
    // START_USER_CODE-USER_ViewSettlement_Custom_Test_Case
    // END_USER_CODE-USER_ViewSettlement_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ViewSettlement />);
    });
  });
  afterEach(cleanup);
  test("btnAcctDist(Button Widget) Test Cases", async () => {
    const btnAcctDist = screen.getByTestId("btnAcctDist");
    expect(btnAcctDist).toBeInTheDocument;
    expect(btnAcctDist.textContent).toEqual(
      t("Settlements:ViewSettlement_btnAcctDist")
    );
  });
  test("Custom Test Cases for btnAcctDist", () => {
    // START_USER_CODE-USER_btnAcctDist_TEST
    // END_USER_CODE-USER_btnAcctDist_TEST
  });
  test("btnApplications(Button Widget) Test Cases", async () => {
    const btnApplications = screen.getByTestId("btnApplications");
    expect(btnApplications).toBeInTheDocument;
    expect(btnApplications.textContent).toEqual(
      t("Settlements:ViewSettlement_btnApplications")
    );
  });
  test("Custom Test Cases for btnApplications", () => {
    // START_USER_CODE-USER_btnApplications_TEST
    // END_USER_CODE-USER_btnApplications_TEST
  });
  test("btnCalculate(Button Widget) Test Cases", async () => {
    const btnCalculate = screen.getByTestId("btnCalculate");
    expect(btnCalculate).toBeInTheDocument;
    expect(btnCalculate.textContent).toEqual(
      t("Settlements:ViewSettlement_btnCalculate")
    );
  });
  test("Custom Test Cases for btnCalculate", () => {
    // START_USER_CODE-USER_btnCalculate_TEST
    // END_USER_CODE-USER_btnCalculate_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("Settlements:ViewSettlement_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCorrection(Button Widget) Test Cases", async () => {
    const btnCorrection = screen.getByTestId("btnCorrection");
    expect(btnCorrection).toBeInTheDocument;
    expect(btnCorrection.textContent).toEqual(
      t("Settlements:ViewSettlement_btnCorrection")
    );
  });
  test("Custom Test Cases for btnCorrection", () => {
    // START_USER_CODE-USER_btnCorrection_TEST
    // END_USER_CODE-USER_btnCorrection_TEST
  });
  test("btnCorrectionLog(Button Widget) Test Cases", async () => {
    const btnCorrectionLog = screen.getByTestId("btnCorrectionLog");
    expect(btnCorrectionLog).toBeInTheDocument;
    expect(btnCorrectionLog.textContent).toEqual(
      t("Settlements:ViewSettlement_btnCorrectionLog")
    );
  });
  test("Custom Test Cases for btnCorrectionLog", () => {
    // START_USER_CODE-USER_btnCorrectionLog_TEST
    // END_USER_CODE-USER_btnCorrectionLog_TEST
  });
  test("btnCorrectionReprint(Button Widget) Test Cases", async () => {
    const btnCorrectionReprint = screen.getByTestId("btnCorrectionReprint");
    expect(btnCorrectionReprint).toBeInTheDocument;
    expect(btnCorrectionReprint.textContent).toEqual(
      t("Settlements:ViewSettlement_btnCorrectionReprint")
    );
  });
  test("Custom Test Cases for btnCorrectionReprint", () => {
    // START_USER_CODE-USER_btnCorrectionReprint_TEST
    // END_USER_CODE-USER_btnCorrectionReprint_TEST
  });
  test("btnDeductTrack(Button Widget) Test Cases", async () => {
    const btnDeductTrack = screen.getByTestId("btnDeductTrack");
    expect(btnDeductTrack).toBeInTheDocument;
    expect(btnDeductTrack.textContent).toEqual(
      t("Settlements:ViewSettlement_btnDeductTrack")
    );
  });
  test("Custom Test Cases for btnDeductTrack", () => {
    // START_USER_CODE-USER_btnDeductTrack_TEST
    // END_USER_CODE-USER_btnDeductTrack_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("Settlements:ViewSettlement_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnPayments(Button Widget) Test Cases", async () => {
    const btnPayments = screen.getByTestId("btnPayments");
    expect(btnPayments).toBeInTheDocument;
    expect(btnPayments.textContent).toEqual(
      t("Settlements:ViewSettlement_btnPayments")
    );
  });
  test("Custom Test Cases for btnPayments", () => {
    // START_USER_CODE-USER_btnPayments_TEST
    // END_USER_CODE-USER_btnPayments_TEST
  });
  test("btnPremsDeduct(Button Widget) Test Cases", async () => {
    const btnPremsDeduct = screen.getByTestId("btnPremsDeduct");
    expect(btnPremsDeduct).toBeInTheDocument;
    expect(btnPremsDeduct.textContent).toEqual(
      t("Settlements:ViewSettlement_btnPremsDeduct")
    );
  });
  test("Custom Test Cases for btnPremsDeduct", () => {
    // START_USER_CODE-USER_btnPremsDeduct_TEST
    // END_USER_CODE-USER_btnPremsDeduct_TEST
  });
  test("btnReprint(Button Widget) Test Cases", async () => {
    const btnReprint = screen.getByTestId("btnReprint");
    expect(btnReprint).toBeInTheDocument;
    expect(btnReprint.textContent).toEqual(
      t("Settlements:ViewSettlement_btnReprint")
    );
  });
  test("Custom Test Cases for btnReprint", () => {
    // START_USER_CODE-USER_btnReprint_TEST
    // END_USER_CODE-USER_btnReprint_TEST
  });
  test("btnResendToKCMO(Button Widget) Test Cases", async () => {
    const btnResendToKCMO = screen.getByTestId("btnResendToKCMO");
    expect(btnResendToKCMO).toBeInTheDocument;
    expect(btnResendToKCMO.textContent).toEqual(
      t("Settlements:ViewSettlement_btnResendToKCMO")
    );
  });
  test("Custom Test Cases for btnResendToKCMO", () => {
    // START_USER_CODE-USER_btnResendToKCMO_TEST
    // END_USER_CODE-USER_btnResendToKCMO_TEST
  });
  test("btnSupersede(Button Widget) Test Cases", async () => {
    const btnSupersede = screen.getByTestId("btnSupersede");
    expect(btnSupersede).toBeInTheDocument;
    expect(btnSupersede.textContent).toEqual(
      t("Settlements:ViewSettlement_btnSupersede")
    );
  });
  test("Custom Test Cases for btnSupersede", () => {
    // START_USER_CODE-USER_btnSupersede_TEST
    // END_USER_CODE-USER_btnSupersede_TEST
  });
  test("btnVoid(Button Widget) Test Cases", async () => {
    const btnVoid = screen.getByTestId("btnVoid");
    expect(btnVoid).toBeInTheDocument;
    expect(btnVoid.textContent).toEqual(
      t("Settlements:ViewSettlement_btnVoid")
    );
  });
  test("Custom Test Cases for btnVoid", () => {
    // START_USER_CODE-USER_btnVoid_TEST
    // END_USER_CODE-USER_btnVoid_TEST
  });
  test("btnWorksheet(Button Widget) Test Cases", async () => {
    const btnWorksheet = screen.getByTestId("btnWorksheet");
    expect(btnWorksheet).toBeInTheDocument;
    expect(btnWorksheet.textContent).toEqual(
      t("Settlements:ViewSettlement_btnWorksheet")
    );
  });
  test("Custom Test Cases for btnWorksheet", () => {
    // START_USER_CODE-USER_btnWorksheet_TEST
    // END_USER_CODE-USER_btnWorksheet_TEST
  });
  test("gridWeightTickets(Grid Widget) Test Cases", async () => {
    let gridWeightTickets = screen.getByTestId("gridWeightTickets");
    let gridWeightTicketsbtn =
      gridWeightTickets.nextElementSibling.firstElementChild;
    gridWeightTickets =
      gridWeightTickets.parentElement.parentElement.parentElement;
    expect(gridWeightTickets.tagName).toBe("DIV");
    expect(gridWeightTickets.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:ViewSettlement_gridWeightTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridWeightTickets", () => {
    // START_USER_CODE-USER_gridWeightTickets_TEST
    // END_USER_CODE-USER_gridWeightTickets_TEST
  });
  test("grpbx1007Controluic0(GroupBox Widget) Test Cases", async () => {
    const grpbx1007Controluic0 = screen.getByTestId("grpbx1007Controluic0");
    expect(grpbx1007Controluic0.tagName).toBe("BUTTON");
    expect(grpbx1007Controluic0.type).toBe("button");
    expect(grpbx1007Controluic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx1007Controluic0", () => {
    // START_USER_CODE-USER_grpbx1007Controluic0_TEST
    // END_USER_CODE-USER_grpbx1007Controluic0_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxFarmInformation(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmInformation = screen.getByTestId("grpbxFarmInformation");
    expect(grpbxFarmInformation.tagName).toBe("BUTTON");
    expect(grpbxFarmInformation.type).toBe("button");
    expect(grpbxFarmInformation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmInformation", () => {
    // START_USER_CODE-USER_grpbxFarmInformation_TEST
    // END_USER_CODE-USER_grpbxFarmInformation_TEST
  });
  test("grpbxGradeCalculation(GroupBox Widget) Test Cases", async () => {
    const grpbxGradeCalculation = screen.getByTestId("grpbxGradeCalculation");
    expect(grpbxGradeCalculation.tagName).toBe("BUTTON");
    expect(grpbxGradeCalculation.type).toBe("button");
    expect(grpbxGradeCalculation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGradeCalculation", () => {
    // START_USER_CODE-USER_grpbxGradeCalculation_TEST
    // END_USER_CODE-USER_grpbxGradeCalculation_TEST
  });
  test("grpbxHMC(GroupBox Widget) Test Cases", async () => {
    const grpbxHMC = screen.getByTestId("grpbxHMC");
    expect(grpbxHMC.tagName).toBe("BUTTON");
    expect(grpbxHMC.type).toBe("button");
    expect(grpbxHMC.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxHMC", () => {
    // START_USER_CODE-USER_grpbxHMC_TEST
    // END_USER_CODE-USER_grpbxHMC_TEST
  });
  test("grpbxInspectorRemarks(GroupBox Widget) Test Cases", async () => {
    const grpbxInspectorRemarks = screen.getByTestId("grpbxInspectorRemarks");
    expect(grpbxInspectorRemarks.tagName).toBe("BUTTON");
    expect(grpbxInspectorRemarks.type).toBe("button");
    expect(grpbxInspectorRemarks.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxInspectorRemarks", () => {
    // START_USER_CODE-USER_grpbxInspectorRemarks_TEST
    // END_USER_CODE-USER_grpbxInspectorRemarks_TEST
  });
  test("grpbxPeanutInfouic0(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanutInfouic0 = screen.getByTestId("grpbxPeanutInfouic0");
    expect(grpbxPeanutInfouic0.tagName).toBe("BUTTON");
    expect(grpbxPeanutInfouic0.type).toBe("button");
    expect(grpbxPeanutInfouic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPeanutInfouic0", () => {
    // START_USER_CODE-USER_grpbxPeanutInfouic0_TEST
    // END_USER_CODE-USER_grpbxPeanutInfouic0_TEST
  });
  test("grpbxPrchsStrgWtCert(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsStrgWtCert = screen.getByTestId("grpbxPrchsStrgWtCert");
    expect(grpbxPrchsStrgWtCert.tagName).toBe("BUTTON");
    expect(grpbxPrchsStrgWtCert.type).toBe("button");
    expect(grpbxPrchsStrgWtCert.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsStrgWtCert", () => {
    // START_USER_CODE-USER_grpbxPrchsStrgWtCert_TEST
    // END_USER_CODE-USER_grpbxPrchsStrgWtCert_TEST
  });
  test("grpbxPurchases(GroupBox Widget) Test Cases", async () => {
    const grpbxPurchases = screen.getByTestId("grpbxPurchases");
    expect(grpbxPurchases.tagName).toBe("BUTTON");
    expect(grpbxPurchases.type).toBe("button");
    expect(grpbxPurchases.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPurchases", () => {
    // START_USER_CODE-USER_grpbxPurchases_TEST
    // END_USER_CODE-USER_grpbxPurchases_TEST
  });
  test("grpbxStorage(GroupBox Widget) Test Cases", async () => {
    const grpbxStorage = screen.getByTestId("grpbxStorage");
    expect(grpbxStorage.tagName).toBe("BUTTON");
    expect(grpbxStorage.type).toBe("button");
    expect(grpbxStorage.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStorage", () => {
    // START_USER_CODE-USER_grpbxStorage_TEST
    // END_USER_CODE-USER_grpbxStorage_TEST
  });
  test("grpbxValueCalculation(GroupBox Widget) Test Cases", async () => {
    const grpbxValueCalculation = screen.getByTestId("grpbxValueCalculation");
    expect(grpbxValueCalculation.tagName).toBe("BUTTON");
    expect(grpbxValueCalculation.type).toBe("button");
    expect(grpbxValueCalculation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxValueCalculation", () => {
    // START_USER_CODE-USER_grpbxValueCalculation_TEST
    // END_USER_CODE-USER_grpbxValueCalculation_TEST
  });
  test("grpbxVendorSplitRow0(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow0 = screen.getByTestId("grpbxVendorSplitRow0");
    expect(grpbxVendorSplitRow0.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow0.type).toBe("button");
    expect(grpbxVendorSplitRow0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow0", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow0_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow0_TEST
  });
  test("grpbxVendorSplitRow1(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow1 = screen.getByTestId("grpbxVendorSplitRow1");
    expect(grpbxVendorSplitRow1.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow1.type).toBe("button");
    expect(grpbxVendorSplitRow1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow1", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow1_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow1_TEST
  });
  test("grpbxVendorSplitRow2(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow2 = screen.getByTestId("grpbxVendorSplitRow2");
    expect(grpbxVendorSplitRow2.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow2.type).toBe("button");
    expect(grpbxVendorSplitRow2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow2", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow2_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow2_TEST
  });
  test("grpbxVendorSplitRow3(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow3 = screen.getByTestId("grpbxVendorSplitRow3");
    expect(grpbxVendorSplitRow3.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow3.type).toBe("button");
    expect(grpbxVendorSplitRow3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow3", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow3_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow3_TEST
  });
  test("grpbxVendorSplits(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplits = screen.getByTestId("grpbxVendorSplits");
    expect(grpbxVendorSplits.tagName).toBe("BUTTON");
    expect(grpbxVendorSplits.type).toBe("button");
    expect(grpbxVendorSplits.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplits", () => {
    // START_USER_CODE-USER_grpbxVendorSplits_TEST
    // END_USER_CODE-USER_grpbxVendorSplits_TEST
  });
  test("grpbxWeightCert(GroupBox Widget) Test Cases", async () => {
    const grpbxWeightCert = screen.getByTestId("grpbxWeightCert");
    expect(grpbxWeightCert.tagName).toBe("BUTTON");
    expect(grpbxWeightCert.type).toBe("button");
    expect(grpbxWeightCert.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWeightCert", () => {
    // START_USER_CODE-USER_grpbxWeightCert_TEST
    // END_USER_CODE-USER_grpbxWeightCert_TEST
  });
  test("grpbxWeightTickets(GroupBox Widget) Test Cases", async () => {
    const grpbxWeightTickets = screen.getByTestId("grpbxWeightTickets");
    expect(grpbxWeightTickets.tagName).toBe("BUTTON");
    expect(grpbxWeightTickets.type).toBe("button");
    expect(grpbxWeightTickets.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWeightTickets", () => {
    // START_USER_CODE-USER_grpbxWeightTickets_TEST
    // END_USER_CODE-USER_grpbxWeightTickets_TEST
  });
  test("lbl1007Control(Label Widget) Test Cases", async () => {
    const lbl1007Control = screen.getByTestId("lbl1007Control");
    expect(lbl1007Control.tagName).toBe("LABEL");
    expect(lbl1007Control.classList).toContain("form-label");
    expect(lbl1007Control.textContent).toEqual(
      t("Settlements:ViewSettlement_lbl1007Control")
    );
  });
  test("Custom Test Cases for lbl1007Control", () => {
    // START_USER_CODE-USER_lbl1007Control_TEST
    // END_USER_CODE-USER_lbl1007Control_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("Settlements:ViewSettlement_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("Settlements:ViewSettlement_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("Settlements:ViewSettlement_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("Settlements:ViewSettlement_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblCornFound(Label Widget) Test Cases", async () => {
    const lblCornFound = screen.getByTestId("lblCornFound");
    expect(lblCornFound.tagName).toBe("LABEL");
    expect(lblCornFound.classList).toContain("form-label");
    expect(lblCornFound.textContent).toEqual(
      t("Settlements:ViewSettlement_lblCornFound")
    );
  });
  test("Custom Test Cases for lblCornFound", () => {
    // START_USER_CODE-USER_lblCornFound_TEST
    // END_USER_CODE-USER_lblCornFound_TEST
  });
  test("lblDelPtObligation(Label Widget) Test Cases", async () => {
    const lblDelPtObligation = screen.getByTestId("lblDelPtObligation");
    expect(lblDelPtObligation.tagName).toBe("LABEL");
    expect(lblDelPtObligation.classList).toContain("form-label");
    expect(lblDelPtObligation.textContent).toEqual(
      t("Settlements:ViewSettlement_lblDelPtObligation")
    );
  });
  test("Custom Test Cases for lblDelPtObligation", () => {
    // START_USER_CODE-USER_lblDelPtObligation_TEST
    // END_USER_CODE-USER_lblDelPtObligation_TEST
  });
  test("lblFarmInformation(Label Widget) Test Cases", async () => {
    const lblFarmInformation = screen.getByTestId("lblFarmInformation");
    expect(lblFarmInformation.tagName).toBe("LABEL");
    expect(lblFarmInformation.classList).toContain("form-label");
    expect(lblFarmInformation.textContent).toEqual(
      t("Settlements:ViewSettlement_lblFarmInformation")
    );
  });
  test("Custom Test Cases for lblFarmInformation", () => {
    // START_USER_CODE-USER_lblFarmInformation_TEST
    // END_USER_CODE-USER_lblFarmInformation_TEST
  });
  test("lblFlavusFound(Label Widget) Test Cases", async () => {
    const lblFlavusFound = screen.getByTestId("lblFlavusFound");
    expect(lblFlavusFound.tagName).toBe("LABEL");
    expect(lblFlavusFound.classList).toContain("form-label");
    expect(lblFlavusFound.textContent).toEqual(
      t("Settlements:ViewSettlement_lblFlavusFound")
    );
  });
  test("Custom Test Cases for lblFlavusFound", () => {
    // START_USER_CODE-USER_lblFlavusFound_TEST
    // END_USER_CODE-USER_lblFlavusFound_TEST
  });
  test("lblGradeCalculation(Label Widget) Test Cases", async () => {
    const lblGradeCalculation = screen.getByTestId("lblGradeCalculation");
    expect(lblGradeCalculation.tagName).toBe("LABEL");
    expect(lblGradeCalculation.classList).toContain("form-label");
    expect(lblGradeCalculation.textContent).toEqual(
      t("Settlements:ViewSettlement_lblGradeCalculation")
    );
  });
  test("Custom Test Cases for lblGradeCalculation", () => {
    // START_USER_CODE-USER_lblGradeCalculation_TEST
    // END_USER_CODE-USER_lblGradeCalculation_TEST
  });
  test("lblGrams1(Label Widget) Test Cases", async () => {
    const lblGrams1 = screen.getByTestId("lblGrams1");
    expect(lblGrams1.tagName).toBe("LABEL");
    expect(lblGrams1.classList).toContain("form-label");
    expect(lblGrams1.textContent).toEqual(
      t("Settlements:ViewSettlement_lblGrams1")
    );
  });
  test("Custom Test Cases for lblGrams1", () => {
    // START_USER_CODE-USER_lblGrams1_TEST
    // END_USER_CODE-USER_lblGrams1_TEST
  });
  test("lblGrams2(Label Widget) Test Cases", async () => {
    const lblGrams2 = screen.getByTestId("lblGrams2");
    expect(lblGrams2.tagName).toBe("LABEL");
    expect(lblGrams2.classList).toContain("form-label");
    expect(lblGrams2.textContent).toEqual(
      t("Settlements:ViewSettlement_lblGrams2")
    );
  });
  test("Custom Test Cases for lblGrams2", () => {
    // START_USER_CODE-USER_lblGrams2_TEST
    // END_USER_CODE-USER_lblGrams2_TEST
  });
  test("lblHMCConvertedGrades(Label Widget) Test Cases", async () => {
    const lblHMCConvertedGrades = screen.getByTestId("lblHMCConvertedGrades");
    expect(lblHMCConvertedGrades.tagName).toBe("LABEL");
    expect(lblHMCConvertedGrades.classList).toContain("form-label");
    expect(lblHMCConvertedGrades.textContent).toEqual(
      t("Settlements:ViewSettlement_lblHMCConvertedGrades")
    );
  });
  test("Custom Test Cases for lblHMCConvertedGrades", () => {
    // START_USER_CODE-USER_lblHMCConvertedGrades_TEST
    // END_USER_CODE-USER_lblHMCConvertedGrades_TEST
  });
  test("lblInspectorRemarks(Label Widget) Test Cases", async () => {
    const lblInspectorRemarks = screen.getByTestId("lblInspectorRemarks");
    expect(lblInspectorRemarks.tagName).toBe("LABEL");
    expect(lblInspectorRemarks.classList).toContain("form-label");
    expect(lblInspectorRemarks.textContent).toEqual(
      t("Settlements:ViewSettlement_lblInspectorRemarks")
    );
  });
  test("Custom Test Cases for lblInspectorRemarks", () => {
    // START_USER_CODE-USER_lblInspectorRemarks_TEST
    // END_USER_CODE-USER_lblInspectorRemarks_TEST
  });
  test("lblPct1(Label Widget) Test Cases", async () => {
    const lblPct1 = screen.getByTestId("lblPct1");
    expect(lblPct1.tagName).toBe("LABEL");
    expect(lblPct1.classList).toContain("form-label");
    expect(lblPct1.textContent).toEqual(
      t("Settlements:ViewSettlement_lblPct1")
    );
  });
  test("Custom Test Cases for lblPct1", () => {
    // START_USER_CODE-USER_lblPct1_TEST
    // END_USER_CODE-USER_lblPct1_TEST
  });
  test("lblPct2(Label Widget) Test Cases", async () => {
    const lblPct2 = screen.getByTestId("lblPct2");
    expect(lblPct2.tagName).toBe("LABEL");
    expect(lblPct2.classList).toContain("form-label");
    expect(lblPct2.textContent).toEqual(
      t("Settlements:ViewSettlement_lblPct2")
    );
  });
  test("Custom Test Cases for lblPct2", () => {
    // START_USER_CODE-USER_lblPct2_TEST
    // END_USER_CODE-USER_lblPct2_TEST
  });
  test("lblPeanutInformation(Label Widget) Test Cases", async () => {
    const lblPeanutInformation = screen.getByTestId("lblPeanutInformation");
    expect(lblPeanutInformation.tagName).toBe("LABEL");
    expect(lblPeanutInformation.classList).toContain("form-label");
    expect(lblPeanutInformation.textContent).toEqual(
      t("Settlements:ViewSettlement_lblPeanutInformation")
    );
  });
  test("Custom Test Cases for lblPeanutInformation", () => {
    // START_USER_CODE-USER_lblPeanutInformation_TEST
    // END_USER_CODE-USER_lblPeanutInformation_TEST
  });
  test("lblPerLb(Label Widget) Test Cases", async () => {
    const lblPerLb = screen.getByTestId("lblPerLb");
    expect(lblPerLb.tagName).toBe("LABEL");
    expect(lblPerLb.classList).toContain("form-label");
    expect(lblPerLb.textContent).toEqual(
      t("Settlements:ViewSettlement_lblPerLb")
    );
  });
  test("Custom Test Cases for lblPerLb", () => {
    // START_USER_CODE-USER_lblPerLb_TEST
    // END_USER_CODE-USER_lblPerLb_TEST
  });
  test("lblPerLb2(Label Widget) Test Cases", async () => {
    const lblPerLb2 = screen.getByTestId("lblPerLb2");
    expect(lblPerLb2.tagName).toBe("LABEL");
    expect(lblPerLb2.classList).toContain("form-label");
    expect(lblPerLb2.textContent).toEqual(
      t("Settlements:ViewSettlement_lblPerLb2")
    );
  });
  test("Custom Test Cases for lblPerLb2", () => {
    // START_USER_CODE-USER_lblPerLb2_TEST
    // END_USER_CODE-USER_lblPerLb2_TEST
  });
  test("lblPerLb3(Label Widget) Test Cases", async () => {
    const lblPerLb3 = screen.getByTestId("lblPerLb3");
    expect(lblPerLb3.tagName).toBe("LABEL");
    expect(lblPerLb3.classList).toContain("form-label");
    expect(lblPerLb3.textContent).toEqual(
      t("Settlements:ViewSettlement_lblPerLb3")
    );
  });
  test("Custom Test Cases for lblPerLb3", () => {
    // START_USER_CODE-USER_lblPerLb3_TEST
    // END_USER_CODE-USER_lblPerLb3_TEST
  });
  test("lblPerTon(Label Widget) Test Cases", async () => {
    const lblPerTon = screen.getByTestId("lblPerTon");
    expect(lblPerTon.tagName).toBe("LABEL");
    expect(lblPerTon.classList).toContain("form-label");
    expect(lblPerTon.textContent).toEqual(
      t("Settlements:ViewSettlement_lblPerTon")
    );
  });
  test("Custom Test Cases for lblPerTon", () => {
    // START_USER_CODE-USER_lblPerTon_TEST
    // END_USER_CODE-USER_lblPerTon_TEST
  });
  test("lblPOrS(Label Widget) Test Cases", async () => {
    const lblPOrS = screen.getByTestId("lblPOrS");
    expect(lblPOrS.tagName).toBe("LABEL");
    expect(lblPOrS.classList).toContain("form-label");
    expect(lblPOrS.textContent).toEqual(
      t("Settlements:ViewSettlement_lblPOrS")
    );
  });
  test("Custom Test Cases for lblPOrS", () => {
    // START_USER_CODE-USER_lblPOrS_TEST
    // END_USER_CODE-USER_lblPOrS_TEST
  });
  test("lblPounds(Label Widget) Test Cases", async () => {
    const lblPounds = screen.getByTestId("lblPounds");
    expect(lblPounds.tagName).toBe("LABEL");
    expect(lblPounds.classList).toContain("form-label");
    expect(lblPounds.textContent).toEqual(
      t("Settlements:ViewSettlement_lblPounds")
    );
  });
  test("Custom Test Cases for lblPounds", () => {
    // START_USER_CODE-USER_lblPounds_TEST
    // END_USER_CODE-USER_lblPounds_TEST
  });
  test("lblPurchases(Label Widget) Test Cases", async () => {
    const lblPurchases = screen.getByTestId("lblPurchases");
    expect(lblPurchases.tagName).toBe("LABEL");
    expect(lblPurchases.classList).toContain("form-label");
    expect(lblPurchases.textContent).toEqual(
      t("Settlements:ViewSettlement_lblPurchases")
    );
  });
  test("Custom Test Cases for lblPurchases", () => {
    // START_USER_CODE-USER_lblPurchases_TEST
    // END_USER_CODE-USER_lblPurchases_TEST
  });
  test("lblRemitTo0(Label Widget) Test Cases", async () => {
    const lblRemitTo0 = screen.getByTestId("lblRemitTo0");
    expect(lblRemitTo0.tagName).toBe("LABEL");
    expect(lblRemitTo0.classList).toContain("form-label");
    expect(lblRemitTo0.textContent).toEqual(
      t("Settlements:ViewSettlement_lblRemitTo0")
    );
  });
  test("Custom Test Cases for lblRemitTo0", () => {
    // START_USER_CODE-USER_lblRemitTo0_TEST
    // END_USER_CODE-USER_lblRemitTo0_TEST
  });
  test("lblRemitTo1(Label Widget) Test Cases", async () => {
    const lblRemitTo1 = screen.getByTestId("lblRemitTo1");
    expect(lblRemitTo1.tagName).toBe("LABEL");
    expect(lblRemitTo1.classList).toContain("form-label");
    expect(lblRemitTo1.textContent).toEqual(
      t("Settlements:ViewSettlement_lblRemitTo1")
    );
  });
  test("Custom Test Cases for lblRemitTo1", () => {
    // START_USER_CODE-USER_lblRemitTo1_TEST
    // END_USER_CODE-USER_lblRemitTo1_TEST
  });
  test("lblRemitTo2(Label Widget) Test Cases", async () => {
    const lblRemitTo2 = screen.getByTestId("lblRemitTo2");
    expect(lblRemitTo2.tagName).toBe("LABEL");
    expect(lblRemitTo2.classList).toContain("form-label");
    expect(lblRemitTo2.textContent).toEqual(
      t("Settlements:ViewSettlement_lblRemitTo2")
    );
  });
  test("Custom Test Cases for lblRemitTo2", () => {
    // START_USER_CODE-USER_lblRemitTo2_TEST
    // END_USER_CODE-USER_lblRemitTo2_TEST
  });
  test("lblRemitTo3(Label Widget) Test Cases", async () => {
    const lblRemitTo3 = screen.getByTestId("lblRemitTo3");
    expect(lblRemitTo3.tagName).toBe("LABEL");
    expect(lblRemitTo3.classList).toContain("form-label");
    expect(lblRemitTo3.textContent).toEqual(
      t("Settlements:ViewSettlement_lblRemitTo3")
    );
  });
  test("Custom Test Cases for lblRemitTo3", () => {
    // START_USER_CODE-USER_lblRemitTo3_TEST
    // END_USER_CODE-USER_lblRemitTo3_TEST
  });
  test("lblSC95ES(Label Widget) Test Cases", async () => {
    const lblSC95ES = screen.getByTestId("lblSC95ES");
    expect(lblSC95ES.tagName).toBe("LABEL");
    expect(lblSC95ES.classList).toContain("form-label");
    expect(lblSC95ES.textContent).toEqual(
      t("Settlements:ViewSettlement_lblSC95ES")
    );
  });
  test("Custom Test Cases for lblSC95ES", () => {
    // START_USER_CODE-USER_lblSC95ES_TEST
    // END_USER_CODE-USER_lblSC95ES_TEST
  });
  test("lblSharePct(Label Widget) Test Cases", async () => {
    const lblSharePct = screen.getByTestId("lblSharePct");
    expect(lblSharePct.tagName).toBe("LABEL");
    expect(lblSharePct.classList).toContain("form-label");
    expect(lblSharePct.textContent).toEqual(
      t("Settlements:ViewSettlement_lblSharePct")
    );
  });
  test("Custom Test Cases for lblSharePct", () => {
    // START_USER_CODE-USER_lblSharePct_TEST
    // END_USER_CODE-USER_lblSharePct_TEST
  });
  test("lblStorage(Label Widget) Test Cases", async () => {
    const lblStorage = screen.getByTestId("lblStorage");
    expect(lblStorage.tagName).toBe("LABEL");
    expect(lblStorage.classList).toContain("form-label");
    expect(lblStorage.textContent).toEqual(
      t("Settlements:ViewSettlement_lblStorage")
    );
  });
  test("Custom Test Cases for lblStorage", () => {
    // START_USER_CODE-USER_lblStorage_TEST
    // END_USER_CODE-USER_lblStorage_TEST
  });
  test("lblUnloadedInfo(Label Widget) Test Cases", async () => {
    const lblUnloadedInfo = screen.getByTestId("lblUnloadedInfo");
    expect(lblUnloadedInfo.tagName).toBe("LABEL");
    expect(lblUnloadedInfo.classList).toContain("form-label");
    expect(lblUnloadedInfo.textContent).toEqual(
      t("Settlements:ViewSettlement_lblUnloadedInfo")
    );
  });
  test("Custom Test Cases for lblUnloadedInfo", () => {
    // START_USER_CODE-USER_lblUnloadedInfo_TEST
    // END_USER_CODE-USER_lblUnloadedInfo_TEST
  });
  test("lblValue0(Label Widget) Test Cases", async () => {
    const lblValue0 = screen.getByTestId("lblValue0");
    expect(lblValue0.tagName).toBe("LABEL");
    expect(lblValue0.classList).toContain("form-label");
    expect(lblValue0.textContent).toEqual(
      t("Settlements:ViewSettlement_lblValue0")
    );
  });
  test("Custom Test Cases for lblValue0", () => {
    // START_USER_CODE-USER_lblValue0_TEST
    // END_USER_CODE-USER_lblValue0_TEST
  });
  test("lblValue1(Label Widget) Test Cases", async () => {
    const lblValue1 = screen.getByTestId("lblValue1");
    expect(lblValue1.tagName).toBe("LABEL");
    expect(lblValue1.classList).toContain("form-label");
    expect(lblValue1.textContent).toEqual(
      t("Settlements:ViewSettlement_lblValue1")
    );
  });
  test("Custom Test Cases for lblValue1", () => {
    // START_USER_CODE-USER_lblValue1_TEST
    // END_USER_CODE-USER_lblValue1_TEST
  });
  test("lblValue2(Label Widget) Test Cases", async () => {
    const lblValue2 = screen.getByTestId("lblValue2");
    expect(lblValue2.tagName).toBe("LABEL");
    expect(lblValue2.classList).toContain("form-label");
    expect(lblValue2.textContent).toEqual(
      t("Settlements:ViewSettlement_lblValue2")
    );
  });
  test("Custom Test Cases for lblValue2", () => {
    // START_USER_CODE-USER_lblValue2_TEST
    // END_USER_CODE-USER_lblValue2_TEST
  });
  test("lblValue3(Label Widget) Test Cases", async () => {
    const lblValue3 = screen.getByTestId("lblValue3");
    expect(lblValue3.tagName).toBe("LABEL");
    expect(lblValue3.classList).toContain("form-label");
    expect(lblValue3.textContent).toEqual(
      t("Settlements:ViewSettlement_lblValue3")
    );
  });
  test("Custom Test Cases for lblValue3", () => {
    // START_USER_CODE-USER_lblValue3_TEST
    // END_USER_CODE-USER_lblValue3_TEST
  });
  test("lblValueCalculation(Label Widget) Test Cases", async () => {
    const lblValueCalculation = screen.getByTestId("lblValueCalculation");
    expect(lblValueCalculation.tagName).toBe("LABEL");
    expect(lblValueCalculation.classList).toContain("form-label");
    expect(lblValueCalculation.textContent).toEqual(
      t("Settlements:ViewSettlement_lblValueCalculation")
    );
  });
  test("Custom Test Cases for lblValueCalculation", () => {
    // START_USER_CODE-USER_lblValueCalculation_TEST
    // END_USER_CODE-USER_lblValueCalculation_TEST
  });
  test("lblVendor(Label Widget) Test Cases", async () => {
    const lblVendor = screen.getByTestId("lblVendor");
    expect(lblVendor.tagName).toBe("LABEL");
    expect(lblVendor.classList).toContain("form-label");
    expect(lblVendor.textContent).toEqual(
      t("Settlements:ViewSettlement_lblVendor")
    );
  });
  test("Custom Test Cases for lblVendor", () => {
    // START_USER_CODE-USER_lblVendor_TEST
    // END_USER_CODE-USER_lblVendor_TEST
  });
  test("lblVendorSplits(Label Widget) Test Cases", async () => {
    const lblVendorSplits = screen.getByTestId("lblVendorSplits");
    expect(lblVendorSplits.tagName).toBe("LABEL");
    expect(lblVendorSplits.classList).toContain("form-label");
    expect(lblVendorSplits.textContent).toEqual(
      t("Settlements:ViewSettlement_lblVendorSplits")
    );
  });
  test("Custom Test Cases for lblVendorSplits", () => {
    // START_USER_CODE-USER_lblVendorSplits_TEST
    // END_USER_CODE-USER_lblVendorSplits_TEST
  });
  test("lblWeightCert(Label Widget) Test Cases", async () => {
    const lblWeightCert = screen.getByTestId("lblWeightCert");
    expect(lblWeightCert.tagName).toBe("LABEL");
    expect(lblWeightCert.classList).toContain("form-label");
    expect(lblWeightCert.textContent).toEqual(
      t("Settlements:ViewSettlement_lblWeightCert")
    );
  });
  test("Custom Test Cases for lblWeightCert", () => {
    // START_USER_CODE-USER_lblWeightCert_TEST
    // END_USER_CODE-USER_lblWeightCert_TEST
  });
  test("lblWeightTickets(Label Widget) Test Cases", async () => {
    const lblWeightTickets = screen.getByTestId("lblWeightTickets");
    expect(lblWeightTickets.tagName).toBe("LABEL");
    expect(lblWeightTickets.classList).toContain("form-label");
    expect(lblWeightTickets.textContent).toEqual(
      t("Settlements:ViewSettlement_lblWeightTickets")
    );
  });
  test("Custom Test Cases for lblWeightTickets", () => {
    // START_USER_CODE-USER_lblWeightTickets_TEST
    // END_USER_CODE-USER_lblWeightTickets_TEST
  });
  test("lblWhseRcpt(Label Widget) Test Cases", async () => {
    const lblWhseRcpt = screen.getByTestId("lblWhseRcpt");
    expect(lblWhseRcpt.tagName).toBe("LABEL");
    expect(lblWhseRcpt.classList).toContain("form-label");
    expect(lblWhseRcpt.textContent).toEqual(
      t("Settlements:ViewSettlement_lblWhseRcpt")
    );
  });
  test("Custom Test Cases for lblWhseRcpt", () => {
    // START_USER_CODE-USER_lblWhseRcpt_TEST
    // END_USER_CODE-USER_lblWhseRcpt_TEST
  });
  test("txt1007(Textbox Widget) Test Cases", async () => {
    const txt1007 = screen.getByTestId("txt1007");
    expect(txt1007.tagName).toBe("INPUT");
    expect(txt1007.type).toBe("text");
    expect(txt1007.classList).toContain("textboxWidgetClass");
    expect(txt1007.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txt1007")
    );
    await act(async () => {
      userEvent.type(txt1007, "1");
    });
  });
  test("Custom Test Cases for txt1007", () => {
    // START_USER_CODE-USER_txt1007_TEST
    // END_USER_CODE-USER_txt1007_TEST
  });
  test("txtAccessFMLb(Textbox Widget) Test Cases", async () => {
    const txtAccessFMLb = screen.getByTestId("txtAccessFMLb");
    expect(txtAccessFMLb.tagName).toBe("INPUT");
    expect(txtAccessFMLb.type).toBe("text");
    expect(txtAccessFMLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAccessFMLb, "123");
    });
    expect(txtAccessFMLb.getAttribute("value")).toBe("");
    expect(txtAccessFMLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAccessFMLb", () => {
    // START_USER_CODE-USER_txtAccessFMLb_TEST
    // END_USER_CODE-USER_txtAccessFMLb_TEST
  });
  test("txtAccessFMTon(Textbox Widget) Test Cases", async () => {
    const txtAccessFMTon = screen.getByTestId("txtAccessFMTon");
    expect(txtAccessFMTon.tagName).toBe("INPUT");
    expect(txtAccessFMTon.type).toBe("text");
    expect(txtAccessFMTon.classList).toContain("textboxWidgetClass");
    expect(txtAccessFMTon.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtAccessFMTon")
    );
    await act(async () => {
      userEvent.type(txtAccessFMTon, "123");
    });
    expect(txtAccessFMTon.getAttribute("value")).toBe("");
    expect(txtAccessFMTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAccessFMTon", () => {
    // START_USER_CODE-USER_txtAccessFMTon_TEST
    // END_USER_CODE-USER_txtAccessFMTon_TEST
  });
  test("txtAFlavusLb(Textbox Widget) Test Cases", async () => {
    const txtAFlavusLb = screen.getByTestId("txtAFlavusLb");
    expect(txtAFlavusLb.tagName).toBe("INPUT");
    expect(txtAFlavusLb.type).toBe("text");
    expect(txtAFlavusLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAFlavusLb, "123");
    });
    expect(txtAFlavusLb.getAttribute("value")).toBe("");
    expect(txtAFlavusLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAFlavusLb", () => {
    // START_USER_CODE-USER_txtAFlavusLb_TEST
    // END_USER_CODE-USER_txtAFlavusLb_TEST
  });
  test("txtAFlavusTon(Textbox Widget) Test Cases", async () => {
    const txtAFlavusTon = screen.getByTestId("txtAFlavusTon");
    expect(txtAFlavusTon.tagName).toBe("INPUT");
    expect(txtAFlavusTon.type).toBe("text");
    expect(txtAFlavusTon.classList).toContain("textboxWidgetClass");
    expect(txtAFlavusTon.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtAFlavusTon")
    );
    await act(async () => {
      userEvent.type(txtAFlavusTon, "123");
    });
    expect(txtAFlavusTon.getAttribute("value")).toBe("");
    expect(txtAFlavusTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAFlavusTon", () => {
    // START_USER_CODE-USER_txtAFlavusTon_TEST
    // END_USER_CODE-USER_txtAFlavusTon_TEST
  });
  test("txtBluePanWt(Textbox Widget) Test Cases", async () => {
    const txtBluePanWt = screen.getByTestId("txtBluePanWt");
    expect(txtBluePanWt.tagName).toBe("INPUT");
    expect(txtBluePanWt.type).toBe("text");
    expect(txtBluePanWt.classList).toContain("textboxWidgetClass");
    expect(txtBluePanWt.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtBluePanWt")
    );
    await act(async () => {
      userEvent.type(txtBluePanWt, "123");
    });
    expect(txtBluePanWt.getAttribute("value")).toBe("");
    expect(txtBluePanWt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtBluePanWt", () => {
    // START_USER_CODE-USER_txtBluePanWt_TEST
    // END_USER_CODE-USER_txtBluePanWt_TEST
  });
  test("txtCleanSampleWt(Textbox Widget) Test Cases", async () => {
    const txtCleanSampleWt = screen.getByTestId("txtCleanSampleWt");
    expect(txtCleanSampleWt.tagName).toBe("INPUT");
    expect(txtCleanSampleWt.type).toBe("text");
    expect(txtCleanSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtCleanSampleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtCleanSampleWt")
    );
    await act(async () => {
      userEvent.type(txtCleanSampleWt, "123");
    });
    expect(txtCleanSampleWt.getAttribute("value")).toBe("");
    expect(txtCleanSampleWt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCleanSampleWt", () => {
    // START_USER_CODE-USER_txtCleanSampleWt_TEST
    // END_USER_CODE-USER_txtCleanSampleWt_TEST
  });
  test("gridWeightTickets_txtcolDate(Grid Widget) Test Cases", async () => {
    let gridWeightTickets_txtcolDate = screen.getByTestId("gridWeightTickets");
    let gridWeightTickets_txtcolDatebtn =
      gridWeightTickets_txtcolDate.nextElementSibling.firstElementChild;
    gridWeightTickets_txtcolDate =
      gridWeightTickets_txtcolDate.parentElement.parentElement.parentElement;
    expect(gridWeightTickets_txtcolDate.tagName).toBe("DIV");
    expect(gridWeightTickets_txtcolDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:ViewSettlement_gridWeightTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDate", () => {
    // START_USER_CODE-USER_txtcolDate_TEST
    // END_USER_CODE-USER_txtcolDate_TEST
  });
  test("gridWeightTickets_txtcolScaleTicket(Grid Widget) Test Cases", async () => {
    let gridWeightTickets_txtcolScaleTicket = screen.getByTestId(
      "gridWeightTickets"
    );
    let gridWeightTickets_txtcolScaleTicketbtn =
      gridWeightTickets_txtcolScaleTicket.nextElementSibling.firstElementChild;
    gridWeightTickets_txtcolScaleTicket =
      gridWeightTickets_txtcolScaleTicket.parentElement.parentElement
        .parentElement;
    expect(gridWeightTickets_txtcolScaleTicket.tagName).toBe("DIV");
    expect(gridWeightTickets_txtcolScaleTicket.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("Settlements:ViewSettlement_gridWeightTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolScaleTicket", () => {
    // START_USER_CODE-USER_txtcolScaleTicket_TEST
    // END_USER_CODE-USER_txtcolScaleTicket_TEST
  });
  test("gridWeightTickets_txtcolWtOfPeanuts(Grid Widget) Test Cases", async () => {
    let gridWeightTickets_txtcolWtOfPeanuts = screen.getByTestId(
      "gridWeightTickets"
    );
    let gridWeightTickets_txtcolWtOfPeanutsbtn =
      gridWeightTickets_txtcolWtOfPeanuts.nextElementSibling.firstElementChild;
    gridWeightTickets_txtcolWtOfPeanuts =
      gridWeightTickets_txtcolWtOfPeanuts.parentElement.parentElement
        .parentElement;
    expect(gridWeightTickets_txtcolWtOfPeanuts.tagName).toBe("DIV");
    expect(gridWeightTickets_txtcolWtOfPeanuts.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("Settlements:ViewSettlement_gridWeightTickets"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWtOfPeanuts", () => {
    // START_USER_CODE-USER_txtcolWtOfPeanuts_TEST
    // END_USER_CODE-USER_txtcolWtOfPeanuts_TEST
  });
  test("txtConcealedRMDGr(Textbox Widget) Test Cases", async () => {
    const txtConcealedRMDGr = screen.getByTestId("txtConcealedRMDGr");
    expect(txtConcealedRMDGr.tagName).toBe("INPUT");
    expect(txtConcealedRMDGr.type).toBe("text");
    expect(txtConcealedRMDGr.classList).toContain("textboxWidgetClass");
    expect(txtConcealedRMDGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtConcealedRMDGr")
    );
    await act(async () => {
      userEvent.type(txtConcealedRMDGr, "123");
    });
    expect(txtConcealedRMDGr.getAttribute("value")).toBe("");
    expect(txtConcealedRMDGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtConcealedRMDGr", () => {
    // START_USER_CODE-USER_txtConcealedRMDGr_TEST
    // END_USER_CODE-USER_txtConcealedRMDGr_TEST
  });
  test("txtConcealedRMDPct(Textbox Widget) Test Cases", async () => {
    const txtConcealedRMDPct = screen.getByTestId("txtConcealedRMDPct");
    expect(txtConcealedRMDPct.tagName).toBe("INPUT");
    expect(txtConcealedRMDPct.type).toBe("text");
    expect(txtConcealedRMDPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtConcealedRMDPct, "123");
    });
    expect(txtConcealedRMDPct.getAttribute("value")).toBe("");
    expect(txtConcealedRMDPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtConcealedRMDPct", () => {
    // START_USER_CODE-USER_txtConcealedRMDPct_TEST
    // END_USER_CODE-USER_txtConcealedRMDPct_TEST
  });
  test("txtContractSpotPricePrchs(Textbox Widget) Test Cases", async () => {
    const txtContractSpotPricePrchs = screen.getByTestId(
      "txtContractSpotPricePrchs"
    );
    expect(txtContractSpotPricePrchs.tagName).toBe("INPUT");
    expect(txtContractSpotPricePrchs.type).toBe("text");
    expect(txtContractSpotPricePrchs.classList).toContain("textboxWidgetClass");
    expect(
      txtContractSpotPricePrchs.previousElementSibling.textContent
    ).toEqual(t("Settlements:ViewSettlement_txtContractSpotPricePrchs"));
    await act(async () => {
      userEvent.type(txtContractSpotPricePrchs, "123");
    });
    expect(txtContractSpotPricePrchs.getAttribute("value")).toBe("");
    expect(txtContractSpotPricePrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtContractSpotPricePrchs", () => {
    // START_USER_CODE-USER_txtContractSpotPricePrchs_TEST
    // END_USER_CODE-USER_txtContractSpotPricePrchs_TEST
  });
  test("txtCorrectionCount(Textbox Widget) Test Cases", async () => {
    const txtCorrectionCount = screen.getByTestId("txtCorrectionCount");
    expect(txtCorrectionCount.tagName).toBe("INPUT");
    expect(txtCorrectionCount.type).toBe("text");
    expect(txtCorrectionCount.classList).toContain("textboxWidgetClass");
    expect(txtCorrectionCount.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtCorrectionCount")
    );
    await act(async () => {
      userEvent.type(txtCorrectionCount, "1");
    });
  });
  test("Custom Test Cases for txtCorrectionCount", () => {
    // START_USER_CODE-USER_txtCorrectionCount_TEST
    // END_USER_CODE-USER_txtCorrectionCount_TEST
  });
  test("txtCounty(Textbox Widget) Test Cases", async () => {
    const txtCounty = screen.getByTestId("txtCounty");
    expect(txtCounty.tagName).toBe("INPUT");
    expect(txtCounty.type).toBe("text");
    expect(txtCounty.classList).toContain("textboxWidgetClass");
    expect(txtCounty.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtCounty")
    );
    await act(async () => {
      userEvent.type(txtCounty, "1");
    });
  });
  test("Custom Test Cases for txtCounty", () => {
    // START_USER_CODE-USER_txtCounty_TEST
    // END_USER_CODE-USER_txtCounty_TEST
  });
  test("txtCrkOrBrkShellsGr(Textbox Widget) Test Cases", async () => {
    const txtCrkOrBrkShellsGr = screen.getByTestId("txtCrkOrBrkShellsGr");
    expect(txtCrkOrBrkShellsGr.tagName).toBe("INPUT");
    expect(txtCrkOrBrkShellsGr.type).toBe("text");
    expect(txtCrkOrBrkShellsGr.classList).toContain("textboxWidgetClass");
    expect(txtCrkOrBrkShellsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtCrkOrBrkShellsGr")
    );
    await act(async () => {
      userEvent.type(txtCrkOrBrkShellsGr, "123");
    });
    expect(txtCrkOrBrkShellsGr.getAttribute("value")).toBe("");
    expect(txtCrkOrBrkShellsGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCrkOrBrkShellsGr", () => {
    // START_USER_CODE-USER_txtCrkOrBrkShellsGr_TEST
    // END_USER_CODE-USER_txtCrkOrBrkShellsGr_TEST
  });
  test("txtCrkOrBrkShellsPct(Textbox Widget) Test Cases", async () => {
    const txtCrkOrBrkShellsPct = screen.getByTestId("txtCrkOrBrkShellsPct");
    expect(txtCrkOrBrkShellsPct.tagName).toBe("INPUT");
    expect(txtCrkOrBrkShellsPct.type).toBe("text");
    expect(txtCrkOrBrkShellsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCrkOrBrkShellsPct, "123");
    });
    expect(txtCrkOrBrkShellsPct.getAttribute("value")).toBe("");
    expect(txtCrkOrBrkShellsPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCrkOrBrkShellsPct", () => {
    // START_USER_CODE-USER_txtCrkOrBrkShellsPct_TEST
    // END_USER_CODE-USER_txtCrkOrBrkShellsPct_TEST
  });
  test("txtCRMD(Textbox Widget) Test Cases", async () => {
    const txtCRMD = screen.getByTestId("txtCRMD");
    expect(txtCRMD.tagName).toBe("INPUT");
    expect(txtCRMD.type).toBe("text");
    expect(txtCRMD.classList).toContain("textboxWidgetClass");
    expect(txtCRMD.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtCRMD")
    );
    await act(async () => {
      userEvent.type(txtCRMD, "123");
    });
    expect(txtCRMD.getAttribute("value")).toBe("");
    expect(txtCRMD.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCRMD", () => {
    // START_USER_CODE-USER_txtCRMD_TEST
    // END_USER_CODE-USER_txtCRMD_TEST
  });
  test("txtDACONum(Textbox Widget) Test Cases", async () => {
    const txtDACONum = screen.getByTestId("txtDACONum");
    expect(txtDACONum.tagName).toBe("INPUT");
    expect(txtDACONum.type).toBe("text");
    expect(txtDACONum.classList).toContain("textboxWidgetClass");
    expect(txtDACONum.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtDACONum")
    );
    await act(async () => {
      userEvent.type(txtDACONum, "123");
    });
    expect(txtDACONum.getAttribute("value")).toBe("");
    expect(txtDACONum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDACONum", () => {
    // START_USER_CODE-USER_txtDACONum_TEST
    // END_USER_CODE-USER_txtDACONum_TEST
  });
  test("txtDAM(Textbox Widget) Test Cases", async () => {
    const txtDAM = screen.getByTestId("txtDAM");
    expect(txtDAM.tagName).toBe("INPUT");
    expect(txtDAM.type).toBe("text");
    expect(txtDAM.classList).toContain("textboxWidgetClass");
    expect(txtDAM.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtDAM")
    );
    await act(async () => {
      userEvent.type(txtDAM, "123");
    });
    expect(txtDAM.getAttribute("value")).toBe("");
    expect(txtDAM.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDAM", () => {
    // START_USER_CODE-USER_txtDAM_TEST
    // END_USER_CODE-USER_txtDAM_TEST
  });
  test("txtDamagedKRS(Textbox Widget) Test Cases", async () => {
    const txtDamagedKRS = screen.getByTestId("txtDamagedKRS");
    expect(txtDamagedKRS.tagName).toBe("INPUT");
    expect(txtDamagedKRS.type).toBe("text");
    expect(txtDamagedKRS.classList).toContain("textboxWidgetClass");
    expect(txtDamagedKRS.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtDamagedKRS")
    );
    await act(async () => {
      userEvent.type(txtDamagedKRS, "123");
    });
    expect(txtDamagedKRS.getAttribute("value")).toBe("");
    expect(txtDamagedKRS.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDamagedKRS", () => {
    // START_USER_CODE-USER_txtDamagedKRS_TEST
    // END_USER_CODE-USER_txtDamagedKRS_TEST
  });
  test("txtDamageLbs(Textbox Widget) Test Cases", async () => {
    const txtDamageLbs = screen.getByTestId("txtDamageLbs");
    expect(txtDamageLbs.tagName).toBe("INPUT");
    expect(txtDamageLbs.type).toBe("text");
    expect(txtDamageLbs.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDamageLbs, "123");
    });
    expect(txtDamageLbs.getAttribute("value")).toBe("");
    expect(txtDamageLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDamageLbs", () => {
    // START_USER_CODE-USER_txtDamageLbs_TEST
    // END_USER_CODE-USER_txtDamageLbs_TEST
  });
  test("txtDamageSplits(Textbox Widget) Test Cases", async () => {
    const txtDamageSplits = screen.getByTestId("txtDamageSplits");
    expect(txtDamageSplits.tagName).toBe("INPUT");
    expect(txtDamageSplits.type).toBe("text");
    expect(txtDamageSplits.classList).toContain("textboxWidgetClass");
    expect(txtDamageSplits.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtDamageSplits")
    );
    await act(async () => {
      userEvent.type(txtDamageSplits, "123");
    });
    expect(txtDamageSplits.getAttribute("value")).toBe("");
    expect(txtDamageSplits.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDamageSplits", () => {
    // START_USER_CODE-USER_txtDamageSplits_TEST
    // END_USER_CODE-USER_txtDamageSplits_TEST
  });
  test("txtDamageTon(Textbox Widget) Test Cases", async () => {
    const txtDamageTon = screen.getByTestId("txtDamageTon");
    expect(txtDamageTon.tagName).toBe("INPUT");
    expect(txtDamageTon.type).toBe("text");
    expect(txtDamageTon.classList).toContain("textboxWidgetClass");
    expect(txtDamageTon.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtDamageTon")
    );
    await act(async () => {
      userEvent.type(txtDamageTon, "123");
    });
    expect(txtDamageTon.getAttribute("value")).toBe("");
    expect(txtDamageTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDamageTon", () => {
    // START_USER_CODE-USER_txtDamageTon_TEST
    // END_USER_CODE-USER_txtDamageTon_TEST
  });
  test("txtDeductionsPrchs(Textbox Widget) Test Cases", async () => {
    const txtDeductionsPrchs = screen.getByTestId("txtDeductionsPrchs");
    expect(txtDeductionsPrchs.tagName).toBe("INPUT");
    expect(txtDeductionsPrchs.type).toBe("text");
    expect(txtDeductionsPrchs.classList).toContain("textboxWidgetClass");
    expect(txtDeductionsPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtDeductionsPrchs")
    );
    await act(async () => {
      userEvent.type(txtDeductionsPrchs, "123");
    });
    expect(txtDeductionsPrchs.getAttribute("value")).toBe("");
    expect(txtDeductionsPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDeductionsPrchs", () => {
    // START_USER_CODE-USER_txtDeductionsPrchs_TEST
    // END_USER_CODE-USER_txtDeductionsPrchs_TEST
  });
  test("txtDeductionsStrg(Textbox Widget) Test Cases", async () => {
    const txtDeductionsStrg = screen.getByTestId("txtDeductionsStrg");
    expect(txtDeductionsStrg.tagName).toBe("INPUT");
    expect(txtDeductionsStrg.type).toBe("text");
    expect(txtDeductionsStrg.classList).toContain("textboxWidgetClass");
    expect(txtDeductionsStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtDeductionsStrg")
    );
    await act(async () => {
      userEvent.type(txtDeductionsStrg, "123");
    });
    expect(txtDeductionsStrg.getAttribute("value")).toBe("");
    expect(txtDeductionsStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDeductionsStrg", () => {
    // START_USER_CODE-USER_txtDeductionsStrg_TEST
    // END_USER_CODE-USER_txtDeductionsStrg_TEST
  });
  test("txtDiscoloredShellsGr(Textbox Widget) Test Cases", async () => {
    const txtDiscoloredShellsGr = screen.getByTestId("txtDiscoloredShellsGr");
    expect(txtDiscoloredShellsGr.tagName).toBe("INPUT");
    expect(txtDiscoloredShellsGr.type).toBe("text");
    expect(txtDiscoloredShellsGr.classList).toContain("textboxWidgetClass");
    expect(txtDiscoloredShellsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtDiscoloredShellsGr")
    );
    await act(async () => {
      userEvent.type(txtDiscoloredShellsGr, "123");
    });
    expect(txtDiscoloredShellsGr.getAttribute("value")).toBe("");
    expect(txtDiscoloredShellsGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDiscoloredShellsGr", () => {
    // START_USER_CODE-USER_txtDiscoloredShellsGr_TEST
    // END_USER_CODE-USER_txtDiscoloredShellsGr_TEST
  });
  test("txtDiscoloredShellsPct(Textbox Widget) Test Cases", async () => {
    const txtDiscoloredShellsPct = screen.getByTestId("txtDiscoloredShellsPct");
    expect(txtDiscoloredShellsPct.tagName).toBe("INPUT");
    expect(txtDiscoloredShellsPct.type).toBe("text");
    expect(txtDiscoloredShellsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDiscoloredShellsPct, "123");
    });
    expect(txtDiscoloredShellsPct.getAttribute("value")).toBe("");
    expect(txtDiscoloredShellsPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDiscoloredShellsPct", () => {
    // START_USER_CODE-USER_txtDiscoloredShellsPct_TEST
    // END_USER_CODE-USER_txtDiscoloredShellsPct_TEST
  });
  test("txtELK(Textbox Widget) Test Cases", async () => {
    const txtELK = screen.getByTestId("txtELK");
    expect(txtELK.tagName).toBe("INPUT");
    expect(txtELK.type).toBe("text");
    expect(txtELK.classList).toContain("textboxWidgetClass");
    expect(txtELK.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtELK")
    );
    await act(async () => {
      userEvent.type(txtELK, "123");
    });
    expect(txtELK.getAttribute("value")).toBe("");
    expect(txtELK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtELK", () => {
    // START_USER_CODE-USER_txtELK_TEST
    // END_USER_CODE-USER_txtELK_TEST
  });
  test("txtELKDamage(Textbox Widget) Test Cases", async () => {
    const txtELKDamage = screen.getByTestId("txtELKDamage");
    expect(txtELKDamage.tagName).toBe("INPUT");
    expect(txtELKDamage.type).toBe("text");
    expect(txtELKDamage.classList).toContain("textboxWidgetClass");
    expect(txtELKDamage.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtELKDamage")
    );
    await act(async () => {
      userEvent.type(txtELKDamage, "123");
    });
    expect(txtELKDamage.getAttribute("value")).toBe("");
    expect(txtELKDamage.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtELKDamage", () => {
    // START_USER_CODE-USER_txtELKDamage_TEST
    // END_USER_CODE-USER_txtELKDamage_TEST
  });
  test("txtELKGr(Textbox Widget) Test Cases", async () => {
    const txtELKGr = screen.getByTestId("txtELKGr");
    expect(txtELKGr.tagName).toBe("INPUT");
    expect(txtELKGr.type).toBe("text");
    expect(txtELKGr.classList).toContain("textboxWidgetClass");
    expect(txtELKGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtELKGr")
    );
    await act(async () => {
      userEvent.type(txtELKGr, "123");
    });
    expect(txtELKGr.getAttribute("value")).toBe("");
    expect(txtELKGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtELKGr", () => {
    // START_USER_CODE-USER_txtELKGr_TEST
    // END_USER_CODE-USER_txtELKGr_TEST
  });
  test("txtELKPct(Textbox Widget) Test Cases", async () => {
    const txtELKPct = screen.getByTestId("txtELKPct");
    expect(txtELKPct.tagName).toBe("INPUT");
    expect(txtELKPct.type).toBe("text");
    expect(txtELKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtELKPct, "123");
    });
    expect(txtELKPct.getAttribute("value")).toBe("");
    expect(txtELKPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtELKPct", () => {
    // START_USER_CODE-USER_txtELKPct_TEST
    // END_USER_CODE-USER_txtELKPct_TEST
  });
  test("txtELKPremiumLb(Textbox Widget) Test Cases", async () => {
    const txtELKPremiumLb = screen.getByTestId("txtELKPremiumLb");
    expect(txtELKPremiumLb.tagName).toBe("INPUT");
    expect(txtELKPremiumLb.type).toBe("text");
    expect(txtELKPremiumLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtELKPremiumLb, "123");
    });
    expect(txtELKPremiumLb.getAttribute("value")).toBe("");
    expect(txtELKPremiumLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtELKPremiumLb", () => {
    // START_USER_CODE-USER_txtELKPremiumLb_TEST
    // END_USER_CODE-USER_txtELKPremiumLb_TEST
  });
  test("txtELKPremiumTon(Textbox Widget) Test Cases", async () => {
    const txtELKPremiumTon = screen.getByTestId("txtELKPremiumTon");
    expect(txtELKPremiumTon.tagName).toBe("INPUT");
    expect(txtELKPremiumTon.type).toBe("text");
    expect(txtELKPremiumTon.classList).toContain("textboxWidgetClass");
    expect(txtELKPremiumTon.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtELKPremiumTon")
    );
    await act(async () => {
      userEvent.type(txtELKPremiumTon, "123");
    });
    expect(txtELKPremiumTon.getAttribute("value")).toBe("");
    expect(txtELKPremiumTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtELKPremiumTon", () => {
    // START_USER_CODE-USER_txtELKPremiumTon_TEST
    // END_USER_CODE-USER_txtELKPremiumTon_TEST
  });
  test("txtEVehicleNum(Textbox Widget) Test Cases", async () => {
    const txtEVehicleNum = screen.getByTestId("txtEVehicleNum");
    expect(txtEVehicleNum.tagName).toBe("INPUT");
    expect(txtEVehicleNum.type).toBe("text");
    expect(txtEVehicleNum.classList).toContain("textboxWidgetClass");
    expect(txtEVehicleNum.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtEVehicleNum")
    );
    await act(async () => {
      userEvent.type(txtEVehicleNum, "123");
    });
    expect(txtEVehicleNum.getAttribute("value")).toBe("");
    expect(txtEVehicleNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEVehicleNum", () => {
    // START_USER_CODE-USER_txtEVehicleNum_TEST
    // END_USER_CODE-USER_txtEVehicleNum_TEST
  });
  test("txtEWeightTkt(Textbox Widget) Test Cases", async () => {
    const txtEWeightTkt = screen.getByTestId("txtEWeightTkt");
    expect(txtEWeightTkt.tagName).toBe("INPUT");
    expect(txtEWeightTkt.type).toBe("text");
    expect(txtEWeightTkt.classList).toContain("textboxWidgetClass");
    expect(txtEWeightTkt.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtEWeightTkt")
    );
    await act(async () => {
      userEvent.type(txtEWeightTkt, "123");
    });
    expect(txtEWeightTkt.getAttribute("value")).toBe("");
    expect(txtEWeightTkt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEWeightTkt", () => {
    // START_USER_CODE-USER_txtEWeightTkt_TEST
    // END_USER_CODE-USER_txtEWeightTkt_TEST
  });
  test("txtEWtInclVeh(Textbox Widget) Test Cases", async () => {
    const txtEWtInclVeh = screen.getByTestId("txtEWtInclVeh");
    expect(txtEWtInclVeh.tagName).toBe("INPUT");
    expect(txtEWtInclVeh.type).toBe("text");
    expect(txtEWtInclVeh.classList).toContain("textboxWidgetClass");
    expect(txtEWtInclVeh.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtEWtInclVeh")
    );
    await act(async () => {
      userEvent.type(txtEWtInclVeh, "123");
    });
    expect(txtEWtInclVeh.getAttribute("value")).toBe("");
    expect(txtEWtInclVeh.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEWtInclVeh", () => {
    // START_USER_CODE-USER_txtEWtInclVeh_TEST
    // END_USER_CODE-USER_txtEWtInclVeh_TEST
  });
  test("txtEWtOfPnut(Textbox Widget) Test Cases", async () => {
    const txtEWtOfPnut = screen.getByTestId("txtEWtOfPnut");
    expect(txtEWtOfPnut.tagName).toBe("INPUT");
    expect(txtEWtOfPnut.type).toBe("text");
    expect(txtEWtOfPnut.classList).toContain("textboxWidgetClass");
    expect(txtEWtOfPnut.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtEWtOfPnut")
    );
    await act(async () => {
      userEvent.type(txtEWtOfPnut, "123");
    });
    expect(txtEWtOfPnut.getAttribute("value")).toBe("");
    expect(txtEWtOfPnut.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEWtOfPnut", () => {
    // START_USER_CODE-USER_txtEWtOfPnut_TEST
    // END_USER_CODE-USER_txtEWtOfPnut_TEST
  });
  test("txtEWtOfVeh(Textbox Widget) Test Cases", async () => {
    const txtEWtOfVeh = screen.getByTestId("txtEWtOfVeh");
    expect(txtEWtOfVeh.tagName).toBe("INPUT");
    expect(txtEWtOfVeh.type).toBe("text");
    expect(txtEWtOfVeh.classList).toContain("textboxWidgetClass");
    expect(txtEWtOfVeh.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtEWtOfVeh")
    );
    await act(async () => {
      userEvent.type(txtEWtOfVeh, "123");
    });
    expect(txtEWtOfVeh.getAttribute("value")).toBe("");
    expect(txtEWtOfVeh.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEWtOfVeh", () => {
    // START_USER_CODE-USER_txtEWtOfVeh_TEST
    // END_USER_CODE-USER_txtEWtOfVeh_TEST
  });
  test("txtExcessMoisture(Textbox Widget) Test Cases", async () => {
    const txtExcessMoisture = screen.getByTestId("txtExcessMoisture");
    expect(txtExcessMoisture.tagName).toBe("INPUT");
    expect(txtExcessMoisture.type).toBe("text");
    expect(txtExcessMoisture.classList).toContain("textboxWidgetClass");
    expect(txtExcessMoisture.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtExcessMoisture")
    );
    await act(async () => {
      userEvent.type(txtExcessMoisture, "123");
    });
    expect(txtExcessMoisture.getAttribute("value")).toBe("");
    expect(txtExcessMoisture.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtExcessMoisture", () => {
    // START_USER_CODE-USER_txtExcessMoisture_TEST
    // END_USER_CODE-USER_txtExcessMoisture_TEST
  });
  test("txtExcessSplitsLb(Textbox Widget) Test Cases", async () => {
    const txtExcessSplitsLb = screen.getByTestId("txtExcessSplitsLb");
    expect(txtExcessSplitsLb.tagName).toBe("INPUT");
    expect(txtExcessSplitsLb.type).toBe("text");
    expect(txtExcessSplitsLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtExcessSplitsLb, "123");
    });
    expect(txtExcessSplitsLb.getAttribute("value")).toBe("");
    expect(txtExcessSplitsLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtExcessSplitsLb", () => {
    // START_USER_CODE-USER_txtExcessSplitsLb_TEST
    // END_USER_CODE-USER_txtExcessSplitsLb_TEST
  });
  test("txtExcessSplitsTon(Textbox Widget) Test Cases", async () => {
    const txtExcessSplitsTon = screen.getByTestId("txtExcessSplitsTon");
    expect(txtExcessSplitsTon.tagName).toBe("INPUT");
    expect(txtExcessSplitsTon.type).toBe("text");
    expect(txtExcessSplitsTon.classList).toContain("textboxWidgetClass");
    expect(txtExcessSplitsTon.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtExcessSplitsTon")
    );
    await act(async () => {
      userEvent.type(txtExcessSplitsTon, "123");
    });
    expect(txtExcessSplitsTon.getAttribute("value")).toBe("");
    expect(txtExcessSplitsTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtExcessSplitsTon", () => {
    // START_USER_CODE-USER_txtExcessSplitsTon_TEST
    // END_USER_CODE-USER_txtExcessSplitsTon_TEST
  });
  test("txtFANCY(Textbox Widget) Test Cases", async () => {
    const txtFANCY = screen.getByTestId("txtFANCY");
    expect(txtFANCY.tagName).toBe("INPUT");
    expect(txtFANCY.type).toBe("text");
    expect(txtFANCY.classList).toContain("textboxWidgetClass");
    expect(txtFANCY.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtFANCY")
    );
    await act(async () => {
      userEvent.type(txtFANCY, "123");
    });
    expect(txtFANCY.getAttribute("value")).toBe("");
    expect(txtFANCY.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFANCY", () => {
    // START_USER_CODE-USER_txtFANCY_TEST
    // END_USER_CODE-USER_txtFANCY_TEST
  });
  test("txtFancyGr(Textbox Widget) Test Cases", async () => {
    const txtFancyGr = screen.getByTestId("txtFancyGr");
    expect(txtFancyGr.tagName).toBe("INPUT");
    expect(txtFancyGr.type).toBe("text");
    expect(txtFancyGr.classList).toContain("textboxWidgetClass");
    expect(txtFancyGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtFancyGr")
    );
    await act(async () => {
      userEvent.type(txtFancyGr, "123");
    });
    expect(txtFancyGr.getAttribute("value")).toBe("");
    expect(txtFancyGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFancyGr", () => {
    // START_USER_CODE-USER_txtFancyGr_TEST
    // END_USER_CODE-USER_txtFancyGr_TEST
  });
  test("txtFancyPct(Textbox Widget) Test Cases", async () => {
    const txtFancyPct = screen.getByTestId("txtFancyPct");
    expect(txtFancyPct.tagName).toBe("INPUT");
    expect(txtFancyPct.type).toBe("text");
    expect(txtFancyPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFancyPct, "123");
    });
    expect(txtFancyPct.getAttribute("value")).toBe("");
    expect(txtFancyPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFancyPct", () => {
    // START_USER_CODE-USER_txtFancyPct_TEST
    // END_USER_CODE-USER_txtFancyPct_TEST
  });
  test("txtFarm(Textbox Widget) Test Cases", async () => {
    const txtFarm = screen.getByTestId("txtFarm");
    expect(txtFarm.tagName).toBe("INPUT");
    expect(txtFarm.type).toBe("text");
    expect(txtFarm.classList).toContain("textboxWidgetClass");
    expect(txtFarm.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtFarm")
    );
    await act(async () => {
      userEvent.type(txtFarm, "1");
    });
  });
  test("Custom Test Cases for txtFarm", () => {
    // START_USER_CODE-USER_txtFarm_TEST
    // END_USER_CODE-USER_txtFarm_TEST
  });
  test("txtFarmerStockCostPrchs(Textbox Widget) Test Cases", async () => {
    const txtFarmerStockCostPrchs = screen.getByTestId(
      "txtFarmerStockCostPrchs"
    );
    expect(txtFarmerStockCostPrchs.tagName).toBe("INPUT");
    expect(txtFarmerStockCostPrchs.type).toBe("text");
    expect(txtFarmerStockCostPrchs.classList).toContain("textboxWidgetClass");
    expect(txtFarmerStockCostPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtFarmerStockCostPrchs")
    );
    await act(async () => {
      userEvent.type(txtFarmerStockCostPrchs, "123");
    });
    expect(txtFarmerStockCostPrchs.getAttribute("value")).toBe("");
    expect(txtFarmerStockCostPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFarmerStockCostPrchs", () => {
    // START_USER_CODE-USER_txtFarmerStockCostPrchs_TEST
    // END_USER_CODE-USER_txtFarmerStockCostPrchs_TEST
  });
  test("txtFM(Textbox Widget) Test Cases", async () => {
    const txtFM = screen.getByTestId("txtFM");
    expect(txtFM.tagName).toBe("INPUT");
    expect(txtFM.type).toBe("text");
    expect(txtFM.classList).toContain("textboxWidgetClass");
    expect(txtFM.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtFM")
    );
    await act(async () => {
      userEvent.type(txtFM, "123");
    });
    expect(txtFM.getAttribute("value")).toBe("");
    expect(txtFM.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFM", () => {
    // START_USER_CODE-USER_txtFM_TEST
    // END_USER_CODE-USER_txtFM_TEST
  });
  test("txtFMSampleWt(Textbox Widget) Test Cases", async () => {
    const txtFMSampleWt = screen.getByTestId("txtFMSampleWt");
    expect(txtFMSampleWt.tagName).toBe("INPUT");
    expect(txtFMSampleWt.type).toBe("text");
    expect(txtFMSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtFMSampleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtFMSampleWt")
    );
    await act(async () => {
      userEvent.type(txtFMSampleWt, "123");
    });
    expect(txtFMSampleWt.getAttribute("value")).toBe("");
    expect(txtFMSampleWt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFMSampleWt", () => {
    // START_USER_CODE-USER_txtFMSampleWt_TEST
    // END_USER_CODE-USER_txtFMSampleWt_TEST
  });
  test("txtForeignMaterial(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterial = screen.getByTestId("txtForeignMaterial");
    expect(txtForeignMaterial.tagName).toBe("INPUT");
    expect(txtForeignMaterial.type).toBe("text");
    expect(txtForeignMaterial.classList).toContain("textboxWidgetClass");
    expect(txtForeignMaterial.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtForeignMaterial")
    );
    await act(async () => {
      userEvent.type(txtForeignMaterial, "123");
    });
    expect(txtForeignMaterial.getAttribute("value")).toBe("");
    expect(txtForeignMaterial.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtForeignMaterial", () => {
    // START_USER_CODE-USER_txtForeignMaterial_TEST
    // END_USER_CODE-USER_txtForeignMaterial_TEST
  });
  test("txtForeignMaterialGr(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterialGr = screen.getByTestId("txtForeignMaterialGr");
    expect(txtForeignMaterialGr.tagName).toBe("INPUT");
    expect(txtForeignMaterialGr.type).toBe("text");
    expect(txtForeignMaterialGr.classList).toContain("textboxWidgetClass");
    expect(txtForeignMaterialGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtForeignMaterialGr")
    );
    await act(async () => {
      userEvent.type(txtForeignMaterialGr, "123");
    });
    expect(txtForeignMaterialGr.getAttribute("value")).toBe("");
    expect(txtForeignMaterialGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtForeignMaterialGr", () => {
    // START_USER_CODE-USER_txtForeignMaterialGr_TEST
    // END_USER_CODE-USER_txtForeignMaterialGr_TEST
  });
  test("txtForeignMaterialPct(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterialPct = screen.getByTestId("txtForeignMaterialPct");
    expect(txtForeignMaterialPct.tagName).toBe("INPUT");
    expect(txtForeignMaterialPct.type).toBe("text");
    expect(txtForeignMaterialPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtForeignMaterialPct, "123");
    });
    expect(txtForeignMaterialPct.getAttribute("value")).toBe("");
    expect(txtForeignMaterialPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtForeignMaterialPct", () => {
    // START_USER_CODE-USER_txtForeignMaterialPct_TEST
    // END_USER_CODE-USER_txtForeignMaterialPct_TEST
  });
  test("txtFreezeDamageGr(Textbox Widget) Test Cases", async () => {
    const txtFreezeDamageGr = screen.getByTestId("txtFreezeDamageGr");
    expect(txtFreezeDamageGr.tagName).toBe("INPUT");
    expect(txtFreezeDamageGr.type).toBe("text");
    expect(txtFreezeDamageGr.classList).toContain("textboxWidgetClass");
    expect(txtFreezeDamageGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtFreezeDamageGr")
    );
    await act(async () => {
      userEvent.type(txtFreezeDamageGr, "123");
    });
    expect(txtFreezeDamageGr.getAttribute("value")).toBe("");
    expect(txtFreezeDamageGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFreezeDamageGr", () => {
    // START_USER_CODE-USER_txtFreezeDamageGr_TEST
    // END_USER_CODE-USER_txtFreezeDamageGr_TEST
  });
  test("txtFreezeDamagePct(Textbox Widget) Test Cases", async () => {
    const txtFreezeDamagePct = screen.getByTestId("txtFreezeDamagePct");
    expect(txtFreezeDamagePct.tagName).toBe("INPUT");
    expect(txtFreezeDamagePct.type).toBe("text");
    expect(txtFreezeDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFreezeDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtFreezeDamagePct", () => {
    // START_USER_CODE-USER_txtFreezeDamagePct_TEST
    // END_USER_CODE-USER_txtFreezeDamagePct_TEST
  });
  test("txtFRZ(Textbox Widget) Test Cases", async () => {
    const txtFRZ = screen.getByTestId("txtFRZ");
    expect(txtFRZ.tagName).toBe("INPUT");
    expect(txtFRZ.type).toBe("text");
    expect(txtFRZ.classList).toContain("textboxWidgetClass");
    expect(txtFRZ.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtFRZ")
    );
    await act(async () => {
      userEvent.type(txtFRZ, "123");
    });
    expect(txtFRZ.getAttribute("value")).toBe("");
    expect(txtFRZ.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFRZ", () => {
    // START_USER_CODE-USER_txtFRZ_TEST
    // END_USER_CODE-USER_txtFRZ_TEST
  });
  test("txtG(Textbox Widget) Test Cases", async () => {
    const txtG = screen.getByTestId("txtG");
    expect(txtG.tagName).toBe("INPUT");
    expect(txtG.type).toBe("text");
    expect(txtG.classList).toContain("textboxWidgetClass");
    expect(txtG.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtG")
    );
    await act(async () => {
      userEvent.type(txtG, "123");
    });
    expect(txtG.getAttribute("value")).toBe("");
    expect(txtG.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtG", () => {
    // START_USER_CODE-USER_txtG_TEST
    // END_USER_CODE-USER_txtG_TEST
  });
  test("txtGrossWeight(Textbox Widget) Test Cases", async () => {
    const txtGrossWeight = screen.getByTestId("txtGrossWeight");
    expect(txtGrossWeight.tagName).toBe("INPUT");
    expect(txtGrossWeight.type).toBe("text");
    expect(txtGrossWeight.classList).toContain("textboxWidgetClass");
    expect(txtGrossWeight.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtGrossWeight")
    );
    await act(async () => {
      userEvent.type(txtGrossWeight, "123");
    });
    expect(txtGrossWeight.getAttribute("value")).toBe("");
    expect(txtGrossWeight.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtGrossWeight", () => {
    // START_USER_CODE-USER_txtGrossWeight_TEST
    // END_USER_CODE-USER_txtGrossWeight_TEST
  });
  test("txtH(Textbox Widget) Test Cases", async () => {
    const txtH = screen.getByTestId("txtH");
    expect(txtH.tagName).toBe("INPUT");
    expect(txtH.type).toBe("text");
    expect(txtH.classList).toContain("textboxWidgetClass");
    expect(txtH.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtH")
    );
    await act(async () => {
      userEvent.type(txtH, "123");
    });
    expect(txtH.getAttribute("value")).toBe("");
    expect(txtH.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtH", () => {
    // START_USER_CODE-USER_txtH_TEST
    // END_USER_CODE-USER_txtH_TEST
  });
  test("txtHullBrightness(Textbox Widget) Test Cases", async () => {
    const txtHullBrightness = screen.getByTestId("txtHullBrightness");
    expect(txtHullBrightness.tagName).toBe("INPUT");
    expect(txtHullBrightness.type).toBe("text");
    expect(txtHullBrightness.classList).toContain("textboxWidgetClass");
    expect(txtHullBrightness.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtHullBrightness")
    );
    await act(async () => {
      userEvent.type(txtHullBrightness, "123");
    });
    expect(txtHullBrightness.getAttribute("value")).toBe("");
    expect(txtHullBrightness.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHullBrightness", () => {
    // START_USER_CODE-USER_txtHullBrightness_TEST
    // END_USER_CODE-USER_txtHullBrightness_TEST
  });
  test("txtHullBrightnessPct(Textbox Widget) Test Cases", async () => {
    const txtHullBrightnessPct = screen.getByTestId("txtHullBrightnessPct");
    expect(txtHullBrightnessPct.tagName).toBe("INPUT");
    expect(txtHullBrightnessPct.type).toBe("text");
    expect(txtHullBrightnessPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullBrightnessPct, "123");
    });
    expect(txtHullBrightnessPct.getAttribute("value")).toBe("");
    expect(txtHullBrightnessPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHullBrightnessPct", () => {
    // START_USER_CODE-USER_txtHullBrightnessPct_TEST
    // END_USER_CODE-USER_txtHullBrightnessPct_TEST
  });
  test("txtHULLS(Textbox Widget) Test Cases", async () => {
    const txtHULLS = screen.getByTestId("txtHULLS");
    expect(txtHULLS.tagName).toBe("INPUT");
    expect(txtHULLS.type).toBe("text");
    expect(txtHULLS.classList).toContain("textboxWidgetClass");
    expect(txtHULLS.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtHULLS")
    );
    await act(async () => {
      userEvent.type(txtHULLS, "123");
    });
    expect(txtHULLS.getAttribute("value")).toBe("");
    expect(txtHULLS.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHULLS", () => {
    // START_USER_CODE-USER_txtHULLS_TEST
    // END_USER_CODE-USER_txtHULLS_TEST
  });
  test("txtHullsGr(Textbox Widget) Test Cases", async () => {
    const txtHullsGr = screen.getByTestId("txtHullsGr");
    expect(txtHullsGr.tagName).toBe("INPUT");
    expect(txtHullsGr.type).toBe("text");
    expect(txtHullsGr.classList).toContain("textboxWidgetClass");
    expect(txtHullsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtHullsGr")
    );
    await act(async () => {
      userEvent.type(txtHullsGr, "123");
    });
    expect(txtHullsGr.getAttribute("value")).toBe("");
    expect(txtHullsGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHullsGr", () => {
    // START_USER_CODE-USER_txtHullsGr_TEST
    // END_USER_CODE-USER_txtHullsGr_TEST
  });
  test("txtHullsPct(Textbox Widget) Test Cases", async () => {
    const txtHullsPct = screen.getByTestId("txtHullsPct");
    expect(txtHullsPct.tagName).toBe("INPUT");
    expect(txtHullsPct.type).toBe("text");
    expect(txtHullsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullsPct, "123");
    });
    expect(txtHullsPct.getAttribute("value")).toBe("");
    expect(txtHullsPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHullsPct", () => {
    // START_USER_CODE-USER_txtHullsPct_TEST
    // END_USER_CODE-USER_txtHullsPct_TEST
  });
  test("txtJumboGr(Textbox Widget) Test Cases", async () => {
    const txtJumboGr = screen.getByTestId("txtJumboGr");
    expect(txtJumboGr.tagName).toBe("INPUT");
    expect(txtJumboGr.type).toBe("text");
    expect(txtJumboGr.classList).toContain("textboxWidgetClass");
    expect(txtJumboGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtJumboGr")
    );
    await act(async () => {
      userEvent.type(txtJumboGr, "123");
    });
    expect(txtJumboGr.getAttribute("value")).toBe("");
    expect(txtJumboGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtJumboGr", () => {
    // START_USER_CODE-USER_txtJumboGr_TEST
    // END_USER_CODE-USER_txtJumboGr_TEST
  });
  test("txtJumboPct(Textbox Widget) Test Cases", async () => {
    const txtJumboPct = screen.getByTestId("txtJumboPct");
    expect(txtJumboPct.tagName).toBe("INPUT");
    expect(txtJumboPct.type).toBe("text");
    expect(txtJumboPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtJumboPct, "123");
    });
    expect(txtJumboPct.getAttribute("value")).toBe("");
    expect(txtJumboPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtJumboPct", () => {
    // START_USER_CODE-USER_txtJumboPct_TEST
    // END_USER_CODE-USER_txtJumboPct_TEST
  });
  test("txtKernelsREL(Textbox Widget) Test Cases", async () => {
    const txtKernelsREL = screen.getByTestId("txtKernelsREL");
    expect(txtKernelsREL.tagName).toBe("INPUT");
    expect(txtKernelsREL.type).toBe("text");
    expect(txtKernelsREL.classList).toContain("textboxWidgetClass");
    expect(txtKernelsREL.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtKernelsREL")
    );
    await act(async () => {
      userEvent.type(txtKernelsREL, "123");
    });
    expect(txtKernelsREL.getAttribute("value")).toBe("");
    expect(txtKernelsREL.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtKernelsREL", () => {
    // START_USER_CODE-USER_txtKernelsREL_TEST
    // END_USER_CODE-USER_txtKernelsREL_TEST
  });
  test("txtKernelsRPS(Textbox Widget) Test Cases", async () => {
    const txtKernelsRPS = screen.getByTestId("txtKernelsRPS");
    expect(txtKernelsRPS.tagName).toBe("INPUT");
    expect(txtKernelsRPS.type).toBe("text");
    expect(txtKernelsRPS.classList).toContain("textboxWidgetClass");
    expect(txtKernelsRPS.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtKernelsRPS")
    );
    await act(async () => {
      userEvent.type(txtKernelsRPS, "123");
    });
    expect(txtKernelsRPS.getAttribute("value")).toBe("");
    expect(txtKernelsRPS.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtKernelsRPS", () => {
    // START_USER_CODE-USER_txtKernelsRPS_TEST
    // END_USER_CODE-USER_txtKernelsRPS_TEST
  });
  test("txtKernelValueLb(Textbox Widget) Test Cases", async () => {
    const txtKernelValueLb = screen.getByTestId("txtKernelValueLb");
    expect(txtKernelValueLb.tagName).toBe("INPUT");
    expect(txtKernelValueLb.type).toBe("text");
    expect(txtKernelValueLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtKernelValueLb, "123");
    });
    expect(txtKernelValueLb.getAttribute("value")).toBe("");
    expect(txtKernelValueLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtKernelValueLb", () => {
    // START_USER_CODE-USER_txtKernelValueLb_TEST
    // END_USER_CODE-USER_txtKernelValueLb_TEST
  });
  test("txtKernelValueTon(Textbox Widget) Test Cases", async () => {
    const txtKernelValueTon = screen.getByTestId("txtKernelValueTon");
    expect(txtKernelValueTon.tagName).toBe("INPUT");
    expect(txtKernelValueTon.type).toBe("text");
    expect(txtKernelValueTon.classList).toContain("textboxWidgetClass");
    expect(txtKernelValueTon.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtKernelValueTon")
    );
    await act(async () => {
      userEvent.type(txtKernelValueTon, "123");
    });
    expect(txtKernelValueTon.getAttribute("value")).toBe("");
    expect(txtKernelValueTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtKernelValueTon", () => {
    // START_USER_CODE-USER_txtKernelValueTon_TEST
    // END_USER_CODE-USER_txtKernelValueTon_TEST
  });
  test("txtlblTotalDiscountsLb(Textbox Widget) Test Cases", async () => {
    const txtlblTotalDiscountsLb = screen.getByTestId("txtlblTotalDiscountsLb");
    expect(txtlblTotalDiscountsLb.tagName).toBe("INPUT");
    expect(txtlblTotalDiscountsLb.type).toBe("text");
    expect(txtlblTotalDiscountsLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtlblTotalDiscountsLb, "123");
    });
    expect(txtlblTotalDiscountsLb.getAttribute("value")).toBe("");
    expect(txtlblTotalDiscountsLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtlblTotalDiscountsLb", () => {
    // START_USER_CODE-USER_txtlblTotalDiscountsLb_TEST
    // END_USER_CODE-USER_txtlblTotalDiscountsLb_TEST
  });
  test("txtLocation(Textbox Widget) Test Cases", async () => {
    const txtLocation = screen.getByTestId("txtLocation");
    expect(txtLocation.tagName).toBe("INPUT");
    expect(txtLocation.type).toBe("text");
    expect(txtLocation.classList).toContain("textboxWidgetClass");
    expect(txtLocation.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtLocation")
    );
    await act(async () => {
      userEvent.type(txtLocation, "1");
    });
  });
  test("Custom Test Cases for txtLocation", () => {
    // START_USER_CODE-USER_txtLocation_TEST
    // END_USER_CODE-USER_txtLocation_TEST
  });
  test("txtLSK(Textbox Widget) Test Cases", async () => {
    const txtLSK = screen.getByTestId("txtLSK");
    expect(txtLSK.tagName).toBe("INPUT");
    expect(txtLSK.type).toBe("text");
    expect(txtLSK.classList).toContain("textboxWidgetClass");
    expect(txtLSK.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtLSK")
    );
    await act(async () => {
      userEvent.type(txtLSK, "123");
    });
    expect(txtLSK.getAttribute("value")).toBe("");
    expect(txtLSK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLSK", () => {
    // START_USER_CODE-USER_txtLSK_TEST
    // END_USER_CODE-USER_txtLSK_TEST
  });
  test("txtLSKGr(Textbox Widget) Test Cases", async () => {
    const txtLSKGr = screen.getByTestId("txtLSKGr");
    expect(txtLSKGr.tagName).toBe("INPUT");
    expect(txtLSKGr.type).toBe("text");
    expect(txtLSKGr.classList).toContain("textboxWidgetClass");
    expect(txtLSKGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtLSKGr")
    );
    await act(async () => {
      userEvent.type(txtLSKGr, "123");
    });
    expect(txtLSKGr.getAttribute("value")).toBe("");
    expect(txtLSKGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLSKGr", () => {
    // START_USER_CODE-USER_txtLSKGr_TEST
    // END_USER_CODE-USER_txtLSKGr_TEST
  });
  test("txtLSKPct(Textbox Widget) Test Cases", async () => {
    const txtLSKPct = screen.getByTestId("txtLSKPct");
    expect(txtLSKPct.tagName).toBe("INPUT");
    expect(txtLSKPct.type).toBe("text");
    expect(txtLSKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLSKPct, "1");
    });
  });
  test("Custom Test Cases for txtLSKPct", () => {
    // START_USER_CODE-USER_txtLSKPct_TEST
    // END_USER_CODE-USER_txtLSKPct_TEST
  });
  test("txtLSKvc(Textbox Widget) Test Cases", async () => {
    const txtLSKvc = screen.getByTestId("txtLSKvc");
    expect(txtLSKvc.tagName).toBe("INPUT");
    expect(txtLSKvc.type).toBe("text");
    expect(txtLSKvc.classList).toContain("textboxWidgetClass");
    expect(txtLSKvc.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtLSKvc")
    );
    await act(async () => {
      userEvent.type(txtLSKvc, "123");
    });
    expect(txtLSKvc.getAttribute("value")).toBe("");
    expect(txtLSKvc.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLSKvc", () => {
    // START_USER_CODE-USER_txtLSKvc_TEST
    // END_USER_CODE-USER_txtLSKvc_TEST
  });
  test("txtMeterReading(Textbox Widget) Test Cases", async () => {
    const txtMeterReading = screen.getByTestId("txtMeterReading");
    expect(txtMeterReading.tagName).toBe("INPUT");
    expect(txtMeterReading.type).toBe("text");
    expect(txtMeterReading.classList).toContain("textboxWidgetClass");
    expect(txtMeterReading.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtMeterReading")
    );
    await act(async () => {
      userEvent.type(txtMeterReading, "123");
    });
    expect(txtMeterReading.getAttribute("value")).toBe("");
    expect(txtMeterReading.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtMeterReading", () => {
    // START_USER_CODE-USER_txtMeterReading_TEST
    // END_USER_CODE-USER_txtMeterReading_TEST
  });
  test("txtMoistureReading(Textbox Widget) Test Cases", async () => {
    const txtMoistureReading = screen.getByTestId("txtMoistureReading");
    expect(txtMoistureReading.tagName).toBe("INPUT");
    expect(txtMoistureReading.type).toBe("text");
    expect(txtMoistureReading.classList).toContain("textboxWidgetClass");
    expect(txtMoistureReading.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtMoistureReading")
    );
    await act(async () => {
      userEvent.type(txtMoistureReading, "123");
    });
    expect(txtMoistureReading.getAttribute("value")).toBe("");
    expect(txtMoistureReading.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtMoistureReading", () => {
    // START_USER_CODE-USER_txtMoistureReading_TEST
    // END_USER_CODE-USER_txtMoistureReading_TEST
  });
  test("txtMST(Textbox Widget) Test Cases", async () => {
    const txtMST = screen.getByTestId("txtMST");
    expect(txtMST.tagName).toBe("INPUT");
    expect(txtMST.type).toBe("text");
    expect(txtMST.classList).toContain("textboxWidgetClass");
    expect(txtMST.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtMST")
    );
    await act(async () => {
      userEvent.type(txtMST, "123");
    });
    expect(txtMST.getAttribute("value")).toBe("");
    expect(txtMST.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtMST", () => {
    // START_USER_CODE-USER_txtMST_TEST
    // END_USER_CODE-USER_txtMST_TEST
  });
  test("txtNetValPerTonExclSLK(Textbox Widget) Test Cases", async () => {
    const txtNetValPerTonExclSLK = screen.getByTestId("txtNetValPerTonExclSLK");
    expect(txtNetValPerTonExclSLK.tagName).toBe("INPUT");
    expect(txtNetValPerTonExclSLK.type).toBe("text");
    expect(txtNetValPerTonExclSLK.classList).toContain("textboxWidgetClass");
    expect(txtNetValPerTonExclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtNetValPerTonExclSLK")
    );
    await act(async () => {
      userEvent.type(txtNetValPerTonExclSLK, "123");
    });
    expect(txtNetValPerTonExclSLK.getAttribute("value")).toBe("");
    expect(txtNetValPerTonExclSLK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetValPerTonExclSLK", () => {
    // START_USER_CODE-USER_txtNetValPerTonExclSLK_TEST
    // END_USER_CODE-USER_txtNetValPerTonExclSLK_TEST
  });
  test("txtNetWeight(Textbox Widget) Test Cases", async () => {
    const txtNetWeight = screen.getByTestId("txtNetWeight");
    expect(txtNetWeight.tagName).toBe("INPUT");
    expect(txtNetWeight.type).toBe("text");
    expect(txtNetWeight.classList).toContain("textboxWidgetClass");
    expect(txtNetWeight.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtNetWeight")
    );
    await act(async () => {
      userEvent.type(txtNetWeight, "123");
    });
    expect(txtNetWeight.getAttribute("value")).toBe("");
    expect(txtNetWeight.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetWeight", () => {
    // START_USER_CODE-USER_txtNetWeight_TEST
    // END_USER_CODE-USER_txtNetWeight_TEST
  });
  test("txtNetWeightPrchs(Textbox Widget) Test Cases", async () => {
    const txtNetWeightPrchs = screen.getByTestId("txtNetWeightPrchs");
    expect(txtNetWeightPrchs.tagName).toBe("INPUT");
    expect(txtNetWeightPrchs.type).toBe("text");
    expect(txtNetWeightPrchs.classList).toContain("textboxWidgetClass");
    expect(txtNetWeightPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtNetWeightPrchs")
    );
    await act(async () => {
      userEvent.type(txtNetWeightPrchs, "123");
    });
    expect(txtNetWeightPrchs.getAttribute("value")).toBe("");
    expect(txtNetWeightPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetWeightPrchs", () => {
    // START_USER_CODE-USER_txtNetWeightPrchs_TEST
    // END_USER_CODE-USER_txtNetWeightPrchs_TEST
  });
  test("txtNetWeightStrg(Textbox Widget) Test Cases", async () => {
    const txtNetWeightStrg = screen.getByTestId("txtNetWeightStrg");
    expect(txtNetWeightStrg.tagName).toBe("INPUT");
    expect(txtNetWeightStrg.type).toBe("text");
    expect(txtNetWeightStrg.classList).toContain("textboxWidgetClass");
    expect(txtNetWeightStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtNetWeightStrg")
    );
    await act(async () => {
      userEvent.type(txtNetWeightStrg, "123");
    });
    expect(txtNetWeightStrg.getAttribute("value")).toBe("");
    expect(txtNetWeightStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetWeightStrg", () => {
    // START_USER_CODE-USER_txtNetWeightStrg_TEST
    // END_USER_CODE-USER_txtNetWeightStrg_TEST
  });
  test("txtNetWtExclLSK(Textbox Widget) Test Cases", async () => {
    const txtNetWtExclLSK = screen.getByTestId("txtNetWtExclLSK");
    expect(txtNetWtExclLSK.tagName).toBe("INPUT");
    expect(txtNetWtExclLSK.type).toBe("text");
    expect(txtNetWtExclLSK.classList).toContain("textboxWidgetClass");
    expect(txtNetWtExclLSK.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtNetWtExclLSK")
    );
    await act(async () => {
      userEvent.type(txtNetWtExclLSK, "123");
    });
    expect(txtNetWtExclLSK.getAttribute("value")).toBe("");
    expect(txtNetWtExclLSK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetWtExclLSK", () => {
    // START_USER_CODE-USER_txtNetWtExclLSK_TEST
    // END_USER_CODE-USER_txtNetWtExclLSK_TEST
  });
  test("txtOI(Textbox Widget) Test Cases", async () => {
    const txtOI = screen.getByTestId("txtOI");
    expect(txtOI.tagName).toBe("INPUT");
    expect(txtOI.type).toBe("text");
    expect(txtOI.classList).toContain("textboxWidgetClass");
    expect(txtOI.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtOI")
    );
    await act(async () => {
      userEvent.type(txtOI, "123");
    });
    expect(txtOI.getAttribute("value")).toBe("");
    expect(txtOI.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOI", () => {
    // START_USER_CODE-USER_txtOI_TEST
    // END_USER_CODE-USER_txtOI_TEST
  });
  test("txtOK(Textbox Widget) Test Cases", async () => {
    const txtOK = screen.getByTestId("txtOK");
    expect(txtOK.tagName).toBe("INPUT");
    expect(txtOK.type).toBe("text");
    expect(txtOK.classList).toContain("textboxWidgetClass");
    expect(txtOK.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtOK")
    );
    await act(async () => {
      userEvent.type(txtOK, "123");
    });
    expect(txtOK.getAttribute("value")).toBe("");
    expect(txtOK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOK", () => {
    // START_USER_CODE-USER_txtOK_TEST
    // END_USER_CODE-USER_txtOK_TEST
  });
  test("txtOptionPayment(Textbox Widget) Test Cases", async () => {
    const txtOptionPayment = screen.getByTestId("txtOptionPayment");
    expect(txtOptionPayment.tagName).toBe("INPUT");
    expect(txtOptionPayment.type).toBe("text");
    expect(txtOptionPayment.classList).toContain("textboxWidgetClass");
    expect(txtOptionPayment.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtOptionPayment")
    );
    await act(async () => {
      userEvent.type(txtOptionPayment, "123");
    });
    expect(txtOptionPayment.getAttribute("value")).toBe("");
    expect(txtOptionPayment.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOptionPayment", () => {
    // START_USER_CODE-USER_txtOptionPayment_TEST
    // END_USER_CODE-USER_txtOptionPayment_TEST
  });
  test("txtOptionPriceStrg(Textbox Widget) Test Cases", async () => {
    const txtOptionPriceStrg = screen.getByTestId("txtOptionPriceStrg");
    expect(txtOptionPriceStrg.tagName).toBe("INPUT");
    expect(txtOptionPriceStrg.type).toBe("text");
    expect(txtOptionPriceStrg.classList).toContain("textboxWidgetClass");
    expect(txtOptionPriceStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtOptionPriceStrg")
    );
    await act(async () => {
      userEvent.type(txtOptionPriceStrg, "123");
    });
    expect(txtOptionPriceStrg.getAttribute("value")).toBe("");
    expect(txtOptionPriceStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOptionPriceStrg", () => {
    // START_USER_CODE-USER_txtOptionPriceStrg_TEST
    // END_USER_CODE-USER_txtOptionPriceStrg_TEST
  });
  test("txtOtherForeignMaterials(Textbox Widget) Test Cases", async () => {
    const txtOtherForeignMaterials = screen.getByTestId(
      "txtOtherForeignMaterials"
    );
    expect(txtOtherForeignMaterials.tagName).toBe("INPUT");
    expect(txtOtherForeignMaterials.type).toBe("text");
    expect(txtOtherForeignMaterials.classList).toContain("textboxWidgetClass");
    expect(txtOtherForeignMaterials.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtOtherForeignMaterials")
    );
    await act(async () => {
      userEvent.type(txtOtherForeignMaterials, "123");
    });
    expect(txtOtherForeignMaterials.getAttribute("value")).toBe("");
    expect(txtOtherForeignMaterials.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOtherForeignMaterials", () => {
    // START_USER_CODE-USER_txtOtherForeignMaterials_TEST
    // END_USER_CODE-USER_txtOtherForeignMaterials_TEST
  });
  test("txtOtherKernelsGr(Textbox Widget) Test Cases", async () => {
    const txtOtherKernelsGr = screen.getByTestId("txtOtherKernelsGr");
    expect(txtOtherKernelsGr.tagName).toBe("INPUT");
    expect(txtOtherKernelsGr.type).toBe("text");
    expect(txtOtherKernelsGr.classList).toContain("textboxWidgetClass");
    expect(txtOtherKernelsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtOtherKernelsGr")
    );
    await act(async () => {
      userEvent.type(txtOtherKernelsGr, "123");
    });
    expect(txtOtherKernelsGr.getAttribute("value")).toBe("");
    expect(txtOtherKernelsGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOtherKernelsGr", () => {
    // START_USER_CODE-USER_txtOtherKernelsGr_TEST
    // END_USER_CODE-USER_txtOtherKernelsGr_TEST
  });
  test("txtOtherKernelsPct(Textbox Widget) Test Cases", async () => {
    const txtOtherKernelsPct = screen.getByTestId("txtOtherKernelsPct");
    expect(txtOtherKernelsPct.tagName).toBe("INPUT");
    expect(txtOtherKernelsPct.type).toBe("text");
    expect(txtOtherKernelsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOtherKernelsPct, "123");
    });
    expect(txtOtherKernelsPct.getAttribute("value")).toBe("");
    expect(txtOtherKernelsPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOtherKernelsPct", () => {
    // START_USER_CODE-USER_txtOtherKernelsPct_TEST
    // END_USER_CODE-USER_txtOtherKernelsPct_TEST
  });
  test("txtPctOfSupportPrchs(Textbox Widget) Test Cases", async () => {
    const txtPctOfSupportPrchs = screen.getByTestId("txtPctOfSupportPrchs");
    expect(txtPctOfSupportPrchs.tagName).toBe("INPUT");
    expect(txtPctOfSupportPrchs.type).toBe("text");
    expect(txtPctOfSupportPrchs.classList).toContain("textboxWidgetClass");
    expect(txtPctOfSupportPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtPctOfSupportPrchs")
    );
    await act(async () => {
      userEvent.type(txtPctOfSupportPrchs, "123");
    });
    expect(txtPctOfSupportPrchs.getAttribute("value")).toBe("");
    expect(txtPctOfSupportPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctOfSupportPrchs", () => {
    // START_USER_CODE-USER_txtPctOfSupportPrchs_TEST
    // END_USER_CODE-USER_txtPctOfSupportPrchs_TEST
  });
  test("txtPctOfSupportStrg(Textbox Widget) Test Cases", async () => {
    const txtPctOfSupportStrg = screen.getByTestId("txtPctOfSupportStrg");
    expect(txtPctOfSupportStrg.tagName).toBe("INPUT");
    expect(txtPctOfSupportStrg.type).toBe("text");
    expect(txtPctOfSupportStrg.classList).toContain("textboxWidgetClass");
    expect(txtPctOfSupportStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtPctOfSupportStrg")
    );
    await act(async () => {
      userEvent.type(txtPctOfSupportStrg, "123");
    });
    expect(txtPctOfSupportStrg.getAttribute("value")).toBe("");
    expect(txtPctOfSupportStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPctOfSupportStrg", () => {
    // START_USER_CODE-USER_txtPctOfSupportStrg_TEST
    // END_USER_CODE-USER_txtPctOfSupportStrg_TEST
  });
  test("txtPorS0(Textbox Widget) Test Cases", async () => {
    const txtPorS0 = screen.getByTestId("txtPorS0");
    expect(txtPorS0.tagName).toBe("INPUT");
    expect(txtPorS0.type).toBe("text");
    expect(txtPorS0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPorS0, "1");
    });
  });
  test("Custom Test Cases for txtPorS0", () => {
    // START_USER_CODE-USER_txtPorS0_TEST
    // END_USER_CODE-USER_txtPorS0_TEST
  });
  test("txtPorS1(Textbox Widget) Test Cases", async () => {
    const txtPorS1 = screen.getByTestId("txtPorS1");
    expect(txtPorS1.tagName).toBe("INPUT");
    expect(txtPorS1.type).toBe("text");
    expect(txtPorS1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPorS1, "1");
    });
  });
  test("Custom Test Cases for txtPorS1", () => {
    // START_USER_CODE-USER_txtPorS1_TEST
    // END_USER_CODE-USER_txtPorS1_TEST
  });
  test("txtPorS2(Textbox Widget) Test Cases", async () => {
    const txtPorS2 = screen.getByTestId("txtPorS2");
    expect(txtPorS2.tagName).toBe("INPUT");
    expect(txtPorS2.type).toBe("text");
    expect(txtPorS2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPorS2, "1");
    });
  });
  test("Custom Test Cases for txtPorS2", () => {
    // START_USER_CODE-USER_txtPorS2_TEST
    // END_USER_CODE-USER_txtPorS2_TEST
  });
  test("txtPorS3(Textbox Widget) Test Cases", async () => {
    const txtPorS3 = screen.getByTestId("txtPorS3");
    expect(txtPorS3.tagName).toBe("INPUT");
    expect(txtPorS3.type).toBe("text");
    expect(txtPorS3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPorS3, "1");
    });
  });
  test("Custom Test Cases for txtPorS3", () => {
    // START_USER_CODE-USER_txtPorS3_TEST
    // END_USER_CODE-USER_txtPorS3_TEST
  });
  test("txtPounds0(Textbox Widget) Test Cases", async () => {
    const txtPounds0 = screen.getByTestId("txtPounds0");
    expect(txtPounds0.tagName).toBe("INPUT");
    expect(txtPounds0.type).toBe("text");
    expect(txtPounds0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPounds0, "1");
    });
  });
  test("Custom Test Cases for txtPounds0", () => {
    // START_USER_CODE-USER_txtPounds0_TEST
    // END_USER_CODE-USER_txtPounds0_TEST
  });
  test("txtPounds1(Textbox Widget) Test Cases", async () => {
    const txtPounds1 = screen.getByTestId("txtPounds1");
    expect(txtPounds1.tagName).toBe("INPUT");
    expect(txtPounds1.type).toBe("text");
    expect(txtPounds1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPounds1, "1");
    });
  });
  test("Custom Test Cases for txtPounds1", () => {
    // START_USER_CODE-USER_txtPounds1_TEST
    // END_USER_CODE-USER_txtPounds1_TEST
  });
  test("txtPounds2(Textbox Widget) Test Cases", async () => {
    const txtPounds2 = screen.getByTestId("txtPounds2");
    expect(txtPounds2.tagName).toBe("INPUT");
    expect(txtPounds2.type).toBe("text");
    expect(txtPounds2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPounds2, "1");
    });
  });
  test("Custom Test Cases for txtPounds2", () => {
    // START_USER_CODE-USER_txtPounds2_TEST
    // END_USER_CODE-USER_txtPounds2_TEST
  });
  test("txtPounds3(Textbox Widget) Test Cases", async () => {
    const txtPounds3 = screen.getByTestId("txtPounds3");
    expect(txtPounds3.tagName).toBe("INPUT");
    expect(txtPounds3.type).toBe("text");
    expect(txtPounds3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPounds3, "1");
    });
  });
  test("Custom Test Cases for txtPounds3", () => {
    // START_USER_CODE-USER_txtPounds3_TEST
    // END_USER_CODE-USER_txtPounds3_TEST
  });
  test("txtPremiumsPrchs(Textbox Widget) Test Cases", async () => {
    const txtPremiumsPrchs = screen.getByTestId("txtPremiumsPrchs");
    expect(txtPremiumsPrchs.tagName).toBe("INPUT");
    expect(txtPremiumsPrchs.type).toBe("text");
    expect(txtPremiumsPrchs.classList).toContain("textboxWidgetClass");
    expect(txtPremiumsPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtPremiumsPrchs")
    );
    await act(async () => {
      userEvent.type(txtPremiumsPrchs, "123");
    });
    expect(txtPremiumsPrchs.getAttribute("value")).toBe("");
    expect(txtPremiumsPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPremiumsPrchs", () => {
    // START_USER_CODE-USER_txtPremiumsPrchs_TEST
    // END_USER_CODE-USER_txtPremiumsPrchs_TEST
  });
  test("txtProbingPattern(Textbox Widget) Test Cases", async () => {
    const txtProbingPattern = screen.getByTestId("txtProbingPattern");
    expect(txtProbingPattern.tagName).toBe("INPUT");
    expect(txtProbingPattern.type).toBe("text");
    expect(txtProbingPattern.classList).toContain("textboxWidgetClass");
    expect(txtProbingPattern.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtProbingPattern")
    );
    await act(async () => {
      userEvent.type(txtProbingPattern, "123");
    });
    expect(txtProbingPattern.getAttribute("value")).toBe("");
    expect(txtProbingPattern.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtProbingPattern", () => {
    // START_USER_CODE-USER_txtProbingPattern_TEST
    // END_USER_CODE-USER_txtProbingPattern_TEST
  });
  test("txtProceedsPrchs(Textbox Widget) Test Cases", async () => {
    const txtProceedsPrchs = screen.getByTestId("txtProceedsPrchs");
    expect(txtProceedsPrchs.tagName).toBe("INPUT");
    expect(txtProceedsPrchs.type).toBe("text");
    expect(txtProceedsPrchs.classList).toContain("textboxWidgetClass");
    expect(txtProceedsPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtProceedsPrchs")
    );
    await act(async () => {
      userEvent.type(txtProceedsPrchs, "123");
    });
    expect(txtProceedsPrchs.getAttribute("value")).toBe("");
    expect(txtProceedsPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtProceedsPrchs", () => {
    // START_USER_CODE-USER_txtProceedsPrchs_TEST
    // END_USER_CODE-USER_txtProceedsPrchs_TEST
  });
  test("txtProceedsStrg(Textbox Widget) Test Cases", async () => {
    const txtProceedsStrg = screen.getByTestId("txtProceedsStrg");
    expect(txtProceedsStrg.tagName).toBe("INPUT");
    expect(txtProceedsStrg.type).toBe("text");
    expect(txtProceedsStrg.classList).toContain("textboxWidgetClass");
    expect(txtProceedsStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtProceedsStrg")
    );
    await act(async () => {
      userEvent.type(txtProceedsStrg, "123");
    });
    expect(txtProceedsStrg.getAttribute("value")).toBe("");
    expect(txtProceedsStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtProceedsStrg", () => {
    // START_USER_CODE-USER_txtProceedsStrg_TEST
    // END_USER_CODE-USER_txtProceedsStrg_TEST
  });
  test("txtReading(Textbox Widget) Test Cases", async () => {
    const txtReading = screen.getByTestId("txtReading");
    expect(txtReading.tagName).toBe("INPUT");
    expect(txtReading.type).toBe("text");
    expect(txtReading.classList).toContain("textboxWidgetClass");
    expect(txtReading.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtReading")
    );
    await act(async () => {
      userEvent.type(txtReading, "1");
    });
  });
  test("Custom Test Cases for txtReading", () => {
    // START_USER_CODE-USER_txtReading_TEST
    // END_USER_CODE-USER_txtReading_TEST
  });
  test("txtRemitTo0(Textbox Widget) Test Cases", async () => {
    const txtRemitTo0 = screen.getByTestId("txtRemitTo0");
    expect(txtRemitTo0.tagName).toBe("INPUT");
    expect(txtRemitTo0.type).toBe("text");
    expect(txtRemitTo0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRemitTo0, "1");
    });
  });
  test("Custom Test Cases for txtRemitTo0", () => {
    // START_USER_CODE-USER_txtRemitTo0_TEST
    // END_USER_CODE-USER_txtRemitTo0_TEST
  });
  test("txtRemitTo1(Textbox Widget) Test Cases", async () => {
    const txtRemitTo1 = screen.getByTestId("txtRemitTo1");
    expect(txtRemitTo1.tagName).toBe("INPUT");
    expect(txtRemitTo1.type).toBe("text");
    expect(txtRemitTo1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRemitTo1, "1");
    });
  });
  test("Custom Test Cases for txtRemitTo1", () => {
    // START_USER_CODE-USER_txtRemitTo1_TEST
    // END_USER_CODE-USER_txtRemitTo1_TEST
  });
  test("txtRemitTo2(Textbox Widget) Test Cases", async () => {
    const txtRemitTo2 = screen.getByTestId("txtRemitTo2");
    expect(txtRemitTo2.tagName).toBe("INPUT");
    expect(txtRemitTo2.type).toBe("text");
    expect(txtRemitTo2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRemitTo2, "1");
    });
  });
  test("Custom Test Cases for txtRemitTo2", () => {
    // START_USER_CODE-USER_txtRemitTo2_TEST
    // END_USER_CODE-USER_txtRemitTo2_TEST
  });
  test("txtRemitTo3(Textbox Widget) Test Cases", async () => {
    const txtRemitTo3 = screen.getByTestId("txtRemitTo3");
    expect(txtRemitTo3.tagName).toBe("INPUT");
    expect(txtRemitTo3.type).toBe("text");
    expect(txtRemitTo3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRemitTo3, "1");
    });
  });
  test("Custom Test Cases for txtRemitTo3", () => {
    // START_USER_CODE-USER_txtRemitTo3_TEST
    // END_USER_CODE-USER_txtRemitTo3_TEST
  });
  test("txtRevision(Textbox Widget) Test Cases", async () => {
    const txtRevision = screen.getByTestId("txtRevision");
    expect(txtRevision.tagName).toBe("INPUT");
    expect(txtRevision.type).toBe("text");
    expect(txtRevision.classList).toContain("textboxWidgetClass");
    expect(txtRevision.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtRevision")
    );
    await act(async () => {
      userEvent.type(txtRevision, "1");
    });
  });
  test("Custom Test Cases for txtRevision", () => {
    // START_USER_CODE-USER_txtRevision_TEST
    // END_USER_CODE-USER_txtRevision_TEST
  });
  test("txtSC95(Textbox Widget) Test Cases", async () => {
    const txtSC95 = screen.getByTestId("txtSC95");
    expect(txtSC95.tagName).toBe("INPUT");
    expect(txtSC95.type).toBe("text");
    expect(txtSC95.classList).toContain("textboxWidgetClass");
    expect(txtSC95.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSC95")
    );
    await act(async () => {
      userEvent.type(txtSC95, "1");
    });
  });
  test("Custom Test Cases for txtSC95", () => {
    // START_USER_CODE-USER_txtSC95_TEST
    // END_USER_CODE-USER_txtSC95_TEST
  });
  test("txtSeedMeetingSpec(Textbox Widget) Test Cases", async () => {
    const txtSeedMeetingSpec = screen.getByTestId("txtSeedMeetingSpec");
    expect(txtSeedMeetingSpec.tagName).toBe("INPUT");
    expect(txtSeedMeetingSpec.type).toBe("text");
    expect(txtSeedMeetingSpec.classList).toContain("textboxWidgetClass");
    expect(txtSeedMeetingSpec.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSeedMeetingSpec")
    );
    await act(async () => {
      userEvent.type(txtSeedMeetingSpec, "1");
    });
  });
  test("Custom Test Cases for txtSeedMeetingSpec", () => {
    // START_USER_CODE-USER_txtSeedMeetingSpec_TEST
    // END_USER_CODE-USER_txtSeedMeetingSpec_TEST
  });
  test("txtSeedPremiumStrg(Textbox Widget) Test Cases", async () => {
    const txtSeedPremiumStrg = screen.getByTestId("txtSeedPremiumStrg");
    expect(txtSeedPremiumStrg.tagName).toBe("INPUT");
    expect(txtSeedPremiumStrg.type).toBe("text");
    expect(txtSeedPremiumStrg.classList).toContain("textboxWidgetClass");
    expect(txtSeedPremiumStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSeedPremiumStrg")
    );
    await act(async () => {
      userEvent.type(txtSeedPremiumStrg, "123");
    });
    expect(txtSeedPremiumStrg.getAttribute("value")).toBe("");
    expect(txtSeedPremiumStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeedPremiumStrg", () => {
    // START_USER_CODE-USER_txtSeedPremiumStrg_TEST
    // END_USER_CODE-USER_txtSeedPremiumStrg_TEST
  });
  test("txtSeg(Textbox Widget) Test Cases", async () => {
    const txtSeg = screen.getByTestId("txtSeg");
    expect(txtSeg.tagName).toBe("INPUT");
    expect(txtSeg.type).toBe("text");
    expect(txtSeg.classList).toContain("textboxWidgetClass");
    expect(txtSeg.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSeg")
    );
    await act(async () => {
      userEvent.type(txtSeg, "123");
    });
    expect(txtSeg.getAttribute("value")).toBe("");
    expect(txtSeg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeg", () => {
    // START_USER_CODE-USER_txtSeg_TEST
    // END_USER_CODE-USER_txtSeg_TEST
  });
  test("txtSettleDate(Date Widget) Test Cases", async () => {
    const txtSettleDate = screen.getByTestId("txtSettleDate");
    expect(txtSettleDate.tagName).toBe("INPUT");
    expect(txtSettleDate.type).toBe("text");
    expect(txtSettleDate.classList).toContain("datetimepicker-input");
    expect(
      txtSettleDate.parentElement.previousElementSibling.textContent
    ).toEqual(t("Settlements:ViewSettlement_txtSettleDate"));
    await act(async () => {
      userEvent.click(txtSettleDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtSettleDate", () => {
    // START_USER_CODE-USER_txtSettleDate_TEST
    // END_USER_CODE-USER_txtSettleDate_TEST
  });
  test("txtSharePct0(Textbox Widget) Test Cases", async () => {
    const txtSharePct0 = screen.getByTestId("txtSharePct0");
    expect(txtSharePct0.tagName).toBe("INPUT");
    expect(txtSharePct0.type).toBe("text");
    expect(txtSharePct0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct0", () => {
    // START_USER_CODE-USER_txtSharePct0_TEST
    // END_USER_CODE-USER_txtSharePct0_TEST
  });
  test("txtSharePct1(Textbox Widget) Test Cases", async () => {
    const txtSharePct1 = screen.getByTestId("txtSharePct1");
    expect(txtSharePct1.tagName).toBe("INPUT");
    expect(txtSharePct1.type).toBe("text");
    expect(txtSharePct1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct1, "1");
    });
  });
  test("Custom Test Cases for txtSharePct1", () => {
    // START_USER_CODE-USER_txtSharePct1_TEST
    // END_USER_CODE-USER_txtSharePct1_TEST
  });
  test("txtSharePct2(Textbox Widget) Test Cases", async () => {
    const txtSharePct2 = screen.getByTestId("txtSharePct2");
    expect(txtSharePct2.tagName).toBe("INPUT");
    expect(txtSharePct2.type).toBe("text");
    expect(txtSharePct2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct2, "1");
    });
  });
  test("Custom Test Cases for txtSharePct2", () => {
    // START_USER_CODE-USER_txtSharePct2_TEST
    // END_USER_CODE-USER_txtSharePct2_TEST
  });
  test("txtSharePct3(Textbox Widget) Test Cases", async () => {
    const txtSharePct3 = screen.getByTestId("txtSharePct3");
    expect(txtSharePct3.tagName).toBe("INPUT");
    expect(txtSharePct3.type).toBe("text");
    expect(txtSharePct3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct3, "1");
    });
  });
  test("Custom Test Cases for txtSharePct3", () => {
    // START_USER_CODE-USER_txtSharePct3_TEST
    // END_USER_CODE-USER_txtSharePct3_TEST
  });
  test("txtSMK(Textbox Widget) Test Cases", async () => {
    const txtSMK = screen.getByTestId("txtSMK");
    expect(txtSMK.tagName).toBe("INPUT");
    expect(txtSMK.type).toBe("text");
    expect(txtSMK.classList).toContain("textboxWidgetClass");
    expect(txtSMK.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSMK")
    );
    await act(async () => {
      userEvent.type(txtSMK, "123");
    });
    expect(txtSMK.getAttribute("value")).toBe("");
    expect(txtSMK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMK", () => {
    // START_USER_CODE-USER_txtSMK_TEST
    // END_USER_CODE-USER_txtSMK_TEST
  });
  test("txtSMK1Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK1Gr = screen.getByTestId("txtSMK1Gr");
    expect(txtSMK1Gr.tagName).toBe("INPUT");
    expect(txtSMK1Gr.type).toBe("text");
    expect(txtSMK1Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK1Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSMK1Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK1Gr, "123");
    });
    expect(txtSMK1Gr.getAttribute("value")).toBe("");
    expect(txtSMK1Gr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMK1Gr", () => {
    // START_USER_CODE-USER_txtSMK1Gr_TEST
    // END_USER_CODE-USER_txtSMK1Gr_TEST
  });
  test("txtSMK1Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK1Pct = screen.getByTestId("txtSMK1Pct");
    expect(txtSMK1Pct.tagName).toBe("INPUT");
    expect(txtSMK1Pct.type).toBe("text");
    expect(txtSMK1Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK1Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK1Pct", () => {
    // START_USER_CODE-USER_txtSMK1Pct_TEST
    // END_USER_CODE-USER_txtSMK1Pct_TEST
  });
  test("txtSMK2Gruic0(Textbox Widget) Test Cases", async () => {
    const txtSMK2Gruic0 = screen.getByTestId("txtSMK2Gruic0");
    expect(txtSMK2Gruic0.tagName).toBe("INPUT");
    expect(txtSMK2Gruic0.type).toBe("text");
    expect(txtSMK2Gruic0.classList).toContain("textboxWidgetClass");
    expect(txtSMK2Gruic0.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSMK2Gruic0")
    );
    await act(async () => {
      userEvent.type(txtSMK2Gruic0, "123");
    });
    expect(txtSMK2Gruic0.getAttribute("value")).toBe("");
    expect(txtSMK2Gruic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMK2Gruic0", () => {
    // START_USER_CODE-USER_txtSMK2Gruic0_TEST
    // END_USER_CODE-USER_txtSMK2Gruic0_TEST
  });
  test("txtSMK2Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK2Pct = screen.getByTestId("txtSMK2Pct");
    expect(txtSMK2Pct.tagName).toBe("INPUT");
    expect(txtSMK2Pct.type).toBe("text");
    expect(txtSMK2Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK2Pct, "123");
    });
    expect(txtSMK2Pct.getAttribute("value")).toBe("");
    expect(txtSMK2Pct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMK2Pct", () => {
    // START_USER_CODE-USER_txtSMK2Pct_TEST
    // END_USER_CODE-USER_txtSMK2Pct_TEST
  });
  test("txtSMK3Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK3Gr = screen.getByTestId("txtSMK3Gr");
    expect(txtSMK3Gr.tagName).toBe("INPUT");
    expect(txtSMK3Gr.type).toBe("text");
    expect(txtSMK3Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK3Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSMK3Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK3Gr, "123");
    });
    expect(txtSMK3Gr.getAttribute("value")).toBe("");
    expect(txtSMK3Gr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMK3Gr", () => {
    // START_USER_CODE-USER_txtSMK3Gr_TEST
    // END_USER_CODE-USER_txtSMK3Gr_TEST
  });
  test("txtSMK3Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK3Pct = screen.getByTestId("txtSMK3Pct");
    expect(txtSMK3Pct.tagName).toBe("INPUT");
    expect(txtSMK3Pct.type).toBe("text");
    expect(txtSMK3Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK3Pct, "123");
    });
    expect(txtSMK3Pct.getAttribute("value")).toBe("");
    expect(txtSMK3Pct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMK3Pct", () => {
    // START_USER_CODE-USER_txtSMK3Pct_TEST
    // END_USER_CODE-USER_txtSMK3Pct_TEST
  });
  test("txtSMK4Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK4Gr = screen.getByTestId("txtSMK4Gr");
    expect(txtSMK4Gr.tagName).toBe("INPUT");
    expect(txtSMK4Gr.type).toBe("text");
    expect(txtSMK4Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK4Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSMK4Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK4Gr, "123");
    });
    expect(txtSMK4Gr.getAttribute("value")).toBe("");
    expect(txtSMK4Gr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMK4Gr", () => {
    // START_USER_CODE-USER_txtSMK4Gr_TEST
    // END_USER_CODE-USER_txtSMK4Gr_TEST
  });
  test("txtSMK4Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK4Pct = screen.getByTestId("txtSMK4Pct");
    expect(txtSMK4Pct.tagName).toBe("INPUT");
    expect(txtSMK4Pct.type).toBe("text");
    expect(txtSMK4Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK4Pct, "123");
    });
    expect(txtSMK4Pct.getAttribute("value")).toBe("");
    expect(txtSMK4Pct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMK4Pct", () => {
    // START_USER_CODE-USER_txtSMK4Pct_TEST
    // END_USER_CODE-USER_txtSMK4Pct_TEST
  });
  test("txtSMKRS(Textbox Widget) Test Cases", async () => {
    const txtSMKRS = screen.getByTestId("txtSMKRS");
    expect(txtSMKRS.tagName).toBe("INPUT");
    expect(txtSMKRS.type).toBe("text");
    expect(txtSMKRS.classList).toContain("textboxWidgetClass");
    expect(txtSMKRS.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSMKRS")
    );
    await act(async () => {
      userEvent.type(txtSMKRS, "123");
    });
    expect(txtSMKRS.getAttribute("value")).toBe("");
    expect(txtSMKRS.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMKRS", () => {
    // START_USER_CODE-USER_txtSMKRS_TEST
    // END_USER_CODE-USER_txtSMKRS_TEST
  });
  test("txtSMKRSGr(Textbox Widget) Test Cases", async () => {
    const txtSMKRSGr = screen.getByTestId("txtSMKRSGr");
    expect(txtSMKRSGr.tagName).toBe("INPUT");
    expect(txtSMKRSGr.type).toBe("text");
    expect(txtSMKRSGr.classList).toContain("textboxWidgetClass");
    expect(txtSMKRSGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSMKRSGr")
    );
    await act(async () => {
      userEvent.type(txtSMKRSGr, "123");
    });
    expect(txtSMKRSGr.getAttribute("value")).toBe("");
    expect(txtSMKRSGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMKRSGr", () => {
    // START_USER_CODE-USER_txtSMKRSGr_TEST
    // END_USER_CODE-USER_txtSMKRSGr_TEST
  });
  test("txtSMKRSPct(Textbox Widget) Test Cases", async () => {
    const txtSMKRSPct = screen.getByTestId("txtSMKRSPct");
    expect(txtSMKRSPct.tagName).toBe("INPUT");
    expect(txtSMKRSPct.type).toBe("text");
    expect(txtSMKRSPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMKRSPct, "123");
    });
    expect(txtSMKRSPct.getAttribute("value")).toBe("");
    expect(txtSMKRSPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMKRSPct", () => {
    // START_USER_CODE-USER_txtSMKRSPct_TEST
    // END_USER_CODE-USER_txtSMKRSPct_TEST
  });
  test("txtSoundSplitsGr(Textbox Widget) Test Cases", async () => {
    const txtSoundSplitsGr = screen.getByTestId("txtSoundSplitsGr");
    expect(txtSoundSplitsGr.tagName).toBe("INPUT");
    expect(txtSoundSplitsGr.type).toBe("text");
    expect(txtSoundSplitsGr.classList).toContain("textboxWidgetClass");
    expect(txtSoundSplitsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSoundSplitsGr")
    );
    await act(async () => {
      userEvent.type(txtSoundSplitsGr, "123");
    });
    expect(txtSoundSplitsGr.getAttribute("value")).toBe("");
    expect(txtSoundSplitsGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSoundSplitsGr", () => {
    // START_USER_CODE-USER_txtSoundSplitsGr_TEST
    // END_USER_CODE-USER_txtSoundSplitsGr_TEST
  });
  test("txtSoundSplitsPct(Textbox Widget) Test Cases", async () => {
    const txtSoundSplitsPct = screen.getByTestId("txtSoundSplitsPct");
    expect(txtSoundSplitsPct.tagName).toBe("INPUT");
    expect(txtSoundSplitsPct.type).toBe("text");
    expect(txtSoundSplitsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSoundSplitsPct, "123");
    });
    expect(txtSoundSplitsPct.getAttribute("value")).toBe("");
    expect(txtSoundSplitsPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSoundSplitsPct", () => {
    // START_USER_CODE-USER_txtSoundSplitsPct_TEST
    // END_USER_CODE-USER_txtSoundSplitsPct_TEST
  });
  test("txtSS(Textbox Widget) Test Cases", async () => {
    const txtSS = screen.getByTestId("txtSS");
    expect(txtSS.tagName).toBe("INPUT");
    expect(txtSS.type).toBe("text");
    expect(txtSS.classList).toContain("textboxWidgetClass");
    expect(txtSS.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSS")
    );
    await act(async () => {
      userEvent.type(txtSS, "123");
    });
    expect(txtSS.getAttribute("value")).toBe("");
    expect(txtSS.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSS", () => {
    // START_USER_CODE-USER_txtSS_TEST
    // END_USER_CODE-USER_txtSS_TEST
  });
  test("txtState(Textbox Widget) Test Cases", async () => {
    const txtState = screen.getByTestId("txtState");
    expect(txtState.tagName).toBe("INPUT");
    expect(txtState.type).toBe("text");
    expect(txtState.classList).toContain("textboxWidgetClass");
    expect(txtState.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtState")
    );
    await act(async () => {
      userEvent.type(txtState, "1");
    });
  });
  test("Custom Test Cases for txtState", () => {
    // START_USER_CODE-USER_txtState_TEST
    // END_USER_CODE-USER_txtState_TEST
  });
  test("txtStatus(Textbox Widget) Test Cases", async () => {
    const txtStatus = screen.getByTestId("txtStatus");
    expect(txtStatus.tagName).toBe("INPUT");
    expect(txtStatus.type).toBe("text");
    expect(txtStatus.classList).toContain("textboxWidgetClass");
    expect(txtStatus.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtStatus")
    );
    await act(async () => {
      userEvent.type(txtStatus, "1");
    });
  });
  test("Custom Test Cases for txtStatus", () => {
    // START_USER_CODE-USER_txtStatus_TEST
    // END_USER_CODE-USER_txtStatus_TEST
  });
  test("txtSuperceded1007(Textbox Widget) Test Cases", async () => {
    const txtSuperceded1007 = screen.getByTestId("txtSuperceded1007");
    expect(txtSuperceded1007.tagName).toBe("INPUT");
    expect(txtSuperceded1007.type).toBe("text");
    expect(txtSuperceded1007.classList).toContain("textboxWidgetClass");
    expect(txtSuperceded1007.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSuperceded1007")
    );
    await act(async () => {
      userEvent.type(txtSuperceded1007, "1");
    });
  });
  test("Custom Test Cases for txtSuperceded1007", () => {
    // START_USER_CODE-USER_txtSuperceded1007_TEST
    // END_USER_CODE-USER_txtSuperceded1007_TEST
  });
  test("txtSupercededSC95(Textbox Widget) Test Cases", async () => {
    const txtSupercededSC95 = screen.getByTestId("txtSupercededSC95");
    expect(txtSupercededSC95.tagName).toBe("INPUT");
    expect(txtSupercededSC95.type).toBe("text");
    expect(txtSupercededSC95.classList).toContain("textboxWidgetClass");
    expect(txtSupercededSC95.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtSupercededSC95")
    );
    await act(async () => {
      userEvent.type(txtSupercededSC95, "1");
    });
  });
  test("Custom Test Cases for txtSupercededSC95", () => {
    // START_USER_CODE-USER_txtSupercededSC95_TEST
    // END_USER_CODE-USER_txtSupercededSC95_TEST
  });
  test("txtTickets(Textbox Widget) Test Cases", async () => {
    const txtTickets = screen.getByTestId("txtTickets");
    expect(txtTickets.tagName).toBe("INPUT");
    expect(txtTickets.type).toBe("text");
    expect(txtTickets.classList).toContain("textboxWidgetClass");
    expect(txtTickets.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTickets")
    );
    await act(async () => {
      userEvent.type(txtTickets, "123");
    });
    expect(txtTickets.getAttribute("value")).toBe("");
    expect(txtTickets.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTickets", () => {
    // START_USER_CODE-USER_txtTickets_TEST
    // END_USER_CODE-USER_txtTickets_TEST
  });
  test("txtTotalDamageGr(Textbox Widget) Test Cases", async () => {
    const txtTotalDamageGr = screen.getByTestId("txtTotalDamageGr");
    expect(txtTotalDamageGr.tagName).toBe("INPUT");
    expect(txtTotalDamageGr.type).toBe("text");
    expect(txtTotalDamageGr.classList).toContain("textboxWidgetClass");
    expect(txtTotalDamageGr.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTotalDamageGr")
    );
    await act(async () => {
      userEvent.type(txtTotalDamageGr, "123");
    });
    expect(txtTotalDamageGr.getAttribute("value")).toBe("");
    expect(txtTotalDamageGr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalDamageGr", () => {
    // START_USER_CODE-USER_txtTotalDamageGr_TEST
    // END_USER_CODE-USER_txtTotalDamageGr_TEST
  });
  test("txtTotalDamagePct(Textbox Widget) Test Cases", async () => {
    const txtTotalDamagePct = screen.getByTestId("txtTotalDamagePct");
    expect(txtTotalDamagePct.tagName).toBe("INPUT");
    expect(txtTotalDamagePct.type).toBe("text");
    expect(txtTotalDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalDamagePct, "123");
    });
    expect(txtTotalDamagePct.getAttribute("value")).toBe("");
    expect(txtTotalDamagePct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalDamagePct", () => {
    // START_USER_CODE-USER_txtTotalDamagePct_TEST
    // END_USER_CODE-USER_txtTotalDamagePct_TEST
  });
  test("txtTotalDiscountsTon(Textbox Widget) Test Cases", async () => {
    const txtTotalDiscountsTon = screen.getByTestId("txtTotalDiscountsTon");
    expect(txtTotalDiscountsTon.tagName).toBe("INPUT");
    expect(txtTotalDiscountsTon.type).toBe("text");
    expect(txtTotalDiscountsTon.classList).toContain("textboxWidgetClass");
    expect(txtTotalDiscountsTon.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTotalDiscountsTon")
    );
    await act(async () => {
      userEvent.type(txtTotalDiscountsTon, "123");
    });
    expect(txtTotalDiscountsTon.getAttribute("value")).toBe("");
    expect(txtTotalDiscountsTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalDiscountsTon", () => {
    // START_USER_CODE-USER_txtTotalDiscountsTon_TEST
    // END_USER_CODE-USER_txtTotalDiscountsTon_TEST
  });
  test("txtTotalDollar(Textbox Widget) Test Cases", async () => {
    const txtTotalDollar = screen.getByTestId("txtTotalDollar");
    expect(txtTotalDollar.tagName).toBe("INPUT");
    expect(txtTotalDollar.type).toBe("text");
    expect(txtTotalDollar.classList).toContain("textboxWidgetClass");
    expect(txtTotalDollar.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTotalDollar")
    );
    await act(async () => {
      userEvent.type(txtTotalDollar, "123");
    });
    expect(txtTotalDollar.getAttribute("value")).toBe("");
    expect(txtTotalDollar.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalDollar", () => {
    // START_USER_CODE-USER_txtTotalDollar_TEST
    // END_USER_CODE-USER_txtTotalDollar_TEST
  });
  test("txtTotalKernels(Textbox Widget) Test Cases", async () => {
    const txtTotalKernels = screen.getByTestId("txtTotalKernels");
    expect(txtTotalKernels.tagName).toBe("INPUT");
    expect(txtTotalKernels.type).toBe("text");
    expect(txtTotalKernels.classList).toContain("textboxWidgetClass");
    expect(txtTotalKernels.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTotalKernels")
    );
    await act(async () => {
      userEvent.type(txtTotalKernels, "123");
    });
    expect(txtTotalKernels.getAttribute("value")).toBe("");
    expect(txtTotalKernels.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalKernels", () => {
    // START_USER_CODE-USER_txtTotalKernels_TEST
    // END_USER_CODE-USER_txtTotalKernels_TEST
  });
  test("txtTotalKernelsHulls(Textbox Widget) Test Cases", async () => {
    const txtTotalKernelsHulls = screen.getByTestId("txtTotalKernelsHulls");
    expect(txtTotalKernelsHulls.tagName).toBe("INPUT");
    expect(txtTotalKernelsHulls.type).toBe("text");
    expect(txtTotalKernelsHulls.classList).toContain("textboxWidgetClass");
    expect(txtTotalKernelsHulls.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTotalKernelsHulls")
    );
    await act(async () => {
      userEvent.type(txtTotalKernelsHulls, "123");
    });
    expect(txtTotalKernelsHulls.getAttribute("value")).toBe("");
    expect(txtTotalKernelsHulls.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalKernelsHulls", () => {
    // START_USER_CODE-USER_txtTotalKernelsHulls_TEST
    // END_USER_CODE-USER_txtTotalKernelsHulls_TEST
  });
  test("txtTotalKrnls(Textbox Widget) Test Cases", async () => {
    const txtTotalKrnls = screen.getByTestId("txtTotalKrnls");
    expect(txtTotalKrnls.tagName).toBe("INPUT");
    expect(txtTotalKrnls.type).toBe("text");
    expect(txtTotalKrnls.classList).toContain("textboxWidgetClass");
    expect(txtTotalKrnls.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTotalKrnls")
    );
    await act(async () => {
      userEvent.type(txtTotalKrnls, "123");
    });
    expect(txtTotalKrnls.getAttribute("value")).toBe("");
    expect(txtTotalKrnls.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalKrnls", () => {
    // START_USER_CODE-USER_txtTotalKrnls_TEST
    // END_USER_CODE-USER_txtTotalKrnls_TEST
  });
  test("txtTotalLb(Textbox Widget) Test Cases", async () => {
    const txtTotalLb = screen.getByTestId("txtTotalLb");
    expect(txtTotalLb.tagName).toBe("INPUT");
    expect(txtTotalLb.type).toBe("text");
    expect(txtTotalLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalLb, "123");
    });
    expect(txtTotalLb.getAttribute("value")).toBe("");
    expect(txtTotalLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLb", () => {
    // START_USER_CODE-USER_txtTotalLb_TEST
    // END_USER_CODE-USER_txtTotalLb_TEST
  });
  test("txtTotalLBs(Textbox Widget) Test Cases", async () => {
    const txtTotalLBs = screen.getByTestId("txtTotalLBs");
    expect(txtTotalLBs.tagName).toBe("INPUT");
    expect(txtTotalLBs.type).toBe("text");
    expect(txtTotalLBs.classList).toContain("textboxWidgetClass");
    expect(txtTotalLBs.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTotalLBs")
    );
    await act(async () => {
      userEvent.type(txtTotalLBs, "123");
    });
    expect(txtTotalLBs.getAttribute("value")).toBe("");
    expect(txtTotalLBs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLBs", () => {
    // START_USER_CODE-USER_txtTotalLBs_TEST
    // END_USER_CODE-USER_txtTotalLBs_TEST
  });
  test("txtTotalLoadVal(Textbox Widget) Test Cases", async () => {
    const txtTotalLoadVal = screen.getByTestId("txtTotalLoadVal");
    expect(txtTotalLoadVal.tagName).toBe("INPUT");
    expect(txtTotalLoadVal.type).toBe("text");
    expect(txtTotalLoadVal.classList).toContain("textboxWidgetClass");
    expect(txtTotalLoadVal.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTotalLoadVal")
    );
    await act(async () => {
      userEvent.type(txtTotalLoadVal, "123");
    });
    expect(txtTotalLoadVal.getAttribute("value")).toBe("");
    expect(txtTotalLoadVal.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLoadVal", () => {
    // START_USER_CODE-USER_txtTotalLoadVal_TEST
    // END_USER_CODE-USER_txtTotalLoadVal_TEST
  });
  test("txtTotalLoadValuePrchs(Textbox Widget) Test Cases", async () => {
    const txtTotalLoadValuePrchs = screen.getByTestId("txtTotalLoadValuePrchs");
    expect(txtTotalLoadValuePrchs.tagName).toBe("INPUT");
    expect(txtTotalLoadValuePrchs.type).toBe("text");
    expect(txtTotalLoadValuePrchs.classList).toContain("textboxWidgetClass");
    expect(txtTotalLoadValuePrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTotalLoadValuePrchs")
    );
    await act(async () => {
      userEvent.type(txtTotalLoadValuePrchs, "123");
    });
    expect(txtTotalLoadValuePrchs.getAttribute("value")).toBe("");
    expect(txtTotalLoadValuePrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLoadValuePrchs", () => {
    // START_USER_CODE-USER_txtTotalLoadValuePrchs_TEST
    // END_USER_CODE-USER_txtTotalLoadValuePrchs_TEST
  });
  test("txtTotalLoadValueStrg(Textbox Widget) Test Cases", async () => {
    const txtTotalLoadValueStrg = screen.getByTestId("txtTotalLoadValueStrg");
    expect(txtTotalLoadValueStrg.tagName).toBe("INPUT");
    expect(txtTotalLoadValueStrg.type).toBe("text");
    expect(txtTotalLoadValueStrg.classList).toContain("textboxWidgetClass");
    expect(txtTotalLoadValueStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTotalLoadValueStrg")
    );
    await act(async () => {
      userEvent.type(txtTotalLoadValueStrg, "123");
    });
    expect(txtTotalLoadValueStrg.getAttribute("value")).toBe("");
    expect(txtTotalLoadValueStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLoadValueStrg", () => {
    // START_USER_CODE-USER_txtTotalLoadValueStrg_TEST
    // END_USER_CODE-USER_txtTotalLoadValueStrg_TEST
  });
  test("txtTotalSMK(Textbox Widget) Test Cases", async () => {
    const txtTotalSMK = screen.getByTestId("txtTotalSMK");
    expect(txtTotalSMK.tagName).toBe("INPUT");
    expect(txtTotalSMK.type).toBe("text");
    expect(txtTotalSMK.classList).toContain("textboxWidgetClass");
    expect(txtTotalSMK.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTotalSMK")
    );
    await act(async () => {
      userEvent.type(txtTotalSMK, "123");
    });
    expect(txtTotalSMK.getAttribute("value")).toBe("");
    expect(txtTotalSMK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalSMK", () => {
    // START_USER_CODE-USER_txtTotalSMK_TEST
    // END_USER_CODE-USER_txtTotalSMK_TEST
  });
  test("txtTotalTon(Textbox Widget) Test Cases", async () => {
    const txtTotalTon = screen.getByTestId("txtTotalTon");
    expect(txtTotalTon.tagName).toBe("INPUT");
    expect(txtTotalTon.type).toBe("text");
    expect(txtTotalTon.classList).toContain("textboxWidgetClass");
    expect(txtTotalTon.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTotalTon")
    );
    await act(async () => {
      userEvent.type(txtTotalTon, "123");
    });
    expect(txtTotalTon.getAttribute("value")).toBe("");
    expect(txtTotalTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalTon", () => {
    // START_USER_CODE-USER_txtTotalTon_TEST
    // END_USER_CODE-USER_txtTotalTon_TEST
  });
  test("txtTotKrnlsHulls(Textbox Widget) Test Cases", async () => {
    const txtTotKrnlsHulls = screen.getByTestId("txtTotKrnlsHulls");
    expect(txtTotKrnlsHulls.tagName).toBe("INPUT");
    expect(txtTotKrnlsHulls.type).toBe("text");
    expect(txtTotKrnlsHulls.classList).toContain("textboxWidgetClass");
    expect(txtTotKrnlsHulls.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtTotKrnlsHulls")
    );
    await act(async () => {
      userEvent.type(txtTotKrnlsHulls, "123");
    });
    expect(txtTotKrnlsHulls.getAttribute("value")).toBe("");
    expect(txtTotKrnlsHulls.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotKrnlsHulls", () => {
    // START_USER_CODE-USER_txtTotKrnlsHulls_TEST
    // END_USER_CODE-USER_txtTotKrnlsHulls_TEST
  });
  test("txtValenciaCleanSampleWt(Textbox Widget) Test Cases", async () => {
    const txtValenciaCleanSampleWt = screen.getByTestId(
      "txtValenciaCleanSampleWt"
    );
    expect(txtValenciaCleanSampleWt.tagName).toBe("INPUT");
    expect(txtValenciaCleanSampleWt.type).toBe("text");
    expect(txtValenciaCleanSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtValenciaCleanSampleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtValenciaCleanSampleWt")
    );
    await act(async () => {
      userEvent.type(txtValenciaCleanSampleWt, "123");
    });
    expect(txtValenciaCleanSampleWt.getAttribute("value")).toBe("");
    expect(txtValenciaCleanSampleWt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValenciaCleanSampleWt", () => {
    // START_USER_CODE-USER_txtValenciaCleanSampleWt_TEST
    // END_USER_CODE-USER_txtValenciaCleanSampleWt_TEST
  });
  test("txtValPerPoundExclSLK(Textbox Widget) Test Cases", async () => {
    const txtValPerPoundExclSLK = screen.getByTestId("txtValPerPoundExclSLK");
    expect(txtValPerPoundExclSLK.tagName).toBe("INPUT");
    expect(txtValPerPoundExclSLK.type).toBe("text");
    expect(txtValPerPoundExclSLK.classList).toContain("textboxWidgetClass");
    expect(txtValPerPoundExclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtValPerPoundExclSLK")
    );
    await act(async () => {
      userEvent.type(txtValPerPoundExclSLK, "123");
    });
    expect(txtValPerPoundExclSLK.getAttribute("value")).toBe("");
    expect(txtValPerPoundExclSLK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValPerPoundExclSLK", () => {
    // START_USER_CODE-USER_txtValPerPoundExclSLK_TEST
    // END_USER_CODE-USER_txtValPerPoundExclSLK_TEST
  });
  test("txtValPerPoundInclSLK(Textbox Widget) Test Cases", async () => {
    const txtValPerPoundInclSLK = screen.getByTestId("txtValPerPoundInclSLK");
    expect(txtValPerPoundInclSLK.tagName).toBe("INPUT");
    expect(txtValPerPoundInclSLK.type).toBe("text");
    expect(txtValPerPoundInclSLK.classList).toContain("textboxWidgetClass");
    expect(txtValPerPoundInclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtValPerPoundInclSLK")
    );
    await act(async () => {
      userEvent.type(txtValPerPoundInclSLK, "123");
    });
    expect(txtValPerPoundInclSLK.getAttribute("value")).toBe("");
    expect(txtValPerPoundInclSLK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValPerPoundInclSLK", () => {
    // START_USER_CODE-USER_txtValPerPoundInclSLK_TEST
    // END_USER_CODE-USER_txtValPerPoundInclSLK_TEST
  });
  test("txtValue0(Textbox Widget) Test Cases", async () => {
    const txtValue0 = screen.getByTestId("txtValue0");
    expect(txtValue0.tagName).toBe("INPUT");
    expect(txtValue0.type).toBe("text");
    expect(txtValue0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtValue0, "1");
    });
  });
  test("Custom Test Cases for txtValue0", () => {
    // START_USER_CODE-USER_txtValue0_TEST
    // END_USER_CODE-USER_txtValue0_TEST
  });
  test("txtValue1(Textbox Widget) Test Cases", async () => {
    const txtValue1 = screen.getByTestId("txtValue1");
    expect(txtValue1.tagName).toBe("INPUT");
    expect(txtValue1.type).toBe("text");
    expect(txtValue1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtValue1, "1");
    });
  });
  test("Custom Test Cases for txtValue1", () => {
    // START_USER_CODE-USER_txtValue1_TEST
    // END_USER_CODE-USER_txtValue1_TEST
  });
  test("txtValue2(Textbox Widget) Test Cases", async () => {
    const txtValue2 = screen.getByTestId("txtValue2");
    expect(txtValue2.tagName).toBe("INPUT");
    expect(txtValue2.type).toBe("text");
    expect(txtValue2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtValue2, "1");
    });
  });
  test("Custom Test Cases for txtValue2", () => {
    // START_USER_CODE-USER_txtValue2_TEST
    // END_USER_CODE-USER_txtValue2_TEST
  });
  test("txtValue3(Textbox Widget) Test Cases", async () => {
    const txtValue3 = screen.getByTestId("txtValue3");
    expect(txtValue3.tagName).toBe("INPUT");
    expect(txtValue3.type).toBe("text");
    expect(txtValue3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtValue3, "1");
    });
  });
  test("Custom Test Cases for txtValue3", () => {
    // START_USER_CODE-USER_txtValue3_TEST
    // END_USER_CODE-USER_txtValue3_TEST
  });
  test("txtValueOfOptionStrg(Textbox Widget) Test Cases", async () => {
    const txtValueOfOptionStrg = screen.getByTestId("txtValueOfOptionStrg");
    expect(txtValueOfOptionStrg.tagName).toBe("INPUT");
    expect(txtValueOfOptionStrg.type).toBe("text");
    expect(txtValueOfOptionStrg.classList).toContain("textboxWidgetClass");
    expect(txtValueOfOptionStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtValueOfOptionStrg")
    );
    await act(async () => {
      userEvent.type(txtValueOfOptionStrg, "123");
    });
    expect(txtValueOfOptionStrg.getAttribute("value")).toBe("");
    expect(txtValueOfOptionStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValueOfOptionStrg", () => {
    // START_USER_CODE-USER_txtValueOfOptionStrg_TEST
    // END_USER_CODE-USER_txtValueOfOptionStrg_TEST
  });
  test("txtValueOfSegPrchs(Textbox Widget) Test Cases", async () => {
    const txtValueOfSegPrchs = screen.getByTestId("txtValueOfSegPrchs");
    expect(txtValueOfSegPrchs.tagName).toBe("INPUT");
    expect(txtValueOfSegPrchs.type).toBe("text");
    expect(txtValueOfSegPrchs.classList).toContain("textboxWidgetClass");
    expect(txtValueOfSegPrchs.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtValueOfSegPrchs")
    );
    await act(async () => {
      userEvent.type(txtValueOfSegPrchs, "123");
    });
    expect(txtValueOfSegPrchs.getAttribute("value")).toBe("");
    expect(txtValueOfSegPrchs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValueOfSegPrchs", () => {
    // START_USER_CODE-USER_txtValueOfSegPrchs_TEST
    // END_USER_CODE-USER_txtValueOfSegPrchs_TEST
  });
  test("txtVendor0(Textbox Widget) Test Cases", async () => {
    const txtVendor0 = screen.getByTestId("txtVendor0");
    expect(txtVendor0.tagName).toBe("INPUT");
    expect(txtVendor0.type).toBe("text");
    expect(txtVendor0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor0, "1");
    });
  });
  test("Custom Test Cases for txtVendor0", () => {
    // START_USER_CODE-USER_txtVendor0_TEST
    // END_USER_CODE-USER_txtVendor0_TEST
  });
  test("txtVendor1(Textbox Widget) Test Cases", async () => {
    const txtVendor1 = screen.getByTestId("txtVendor1");
    expect(txtVendor1.tagName).toBe("INPUT");
    expect(txtVendor1.type).toBe("text");
    expect(txtVendor1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor1, "1");
    });
  });
  test("Custom Test Cases for txtVendor1", () => {
    // START_USER_CODE-USER_txtVendor1_TEST
    // END_USER_CODE-USER_txtVendor1_TEST
  });
  test("txtVendor2(Textbox Widget) Test Cases", async () => {
    const txtVendor2 = screen.getByTestId("txtVendor2");
    expect(txtVendor2.tagName).toBe("INPUT");
    expect(txtVendor2.type).toBe("text");
    expect(txtVendor2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor2, "1");
    });
  });
  test("Custom Test Cases for txtVendor2", () => {
    // START_USER_CODE-USER_txtVendor2_TEST
    // END_USER_CODE-USER_txtVendor2_TEST
  });
  test("txtVendor3(Textbox Widget) Test Cases", async () => {
    const txtVendor3 = screen.getByTestId("txtVendor3");
    expect(txtVendor3.tagName).toBe("INPUT");
    expect(txtVendor3.type).toBe("text");
    expect(txtVendor3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor3, "1");
    });
  });
  test("Custom Test Cases for txtVendor3", () => {
    // START_USER_CODE-USER_txtVendor3_TEST
    // END_USER_CODE-USER_txtVendor3_TEST
  });
  test("txtWtIncludingVehicle(Textbox Widget) Test Cases", async () => {
    const txtWtIncludingVehicle = screen.getByTestId("txtWtIncludingVehicle");
    expect(txtWtIncludingVehicle.tagName).toBe("INPUT");
    expect(txtWtIncludingVehicle.type).toBe("text");
    expect(txtWtIncludingVehicle.classList).toContain("textboxWidgetClass");
    expect(txtWtIncludingVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtWtIncludingVehicle")
    );
    await act(async () => {
      userEvent.type(txtWtIncludingVehicle, "123");
    });
    expect(txtWtIncludingVehicle.getAttribute("value")).toBe("");
    expect(txtWtIncludingVehicle.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWtIncludingVehicle", () => {
    // START_USER_CODE-USER_txtWtIncludingVehicle_TEST
    // END_USER_CODE-USER_txtWtIncludingVehicle_TEST
  });
  test("txtWtlessFM(Textbox Widget) Test Cases", async () => {
    const txtWtlessFM = screen.getByTestId("txtWtlessFM");
    expect(txtWtlessFM.tagName).toBe("INPUT");
    expect(txtWtlessFM.type).toBe("text");
    expect(txtWtlessFM.classList).toContain("textboxWidgetClass");
    expect(txtWtlessFM.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtWtlessFM")
    );
    await act(async () => {
      userEvent.type(txtWtlessFM, "123");
    });
    expect(txtWtlessFM.getAttribute("value")).toBe("");
    expect(txtWtlessFM.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWtlessFM", () => {
    // START_USER_CODE-USER_txtWtlessFM_TEST
    // END_USER_CODE-USER_txtWtlessFM_TEST
  });
  test("txtWtOfVehicle(Textbox Widget) Test Cases", async () => {
    const txtWtOfVehicle = screen.getByTestId("txtWtOfVehicle");
    expect(txtWtOfVehicle.tagName).toBe("INPUT");
    expect(txtWtOfVehicle.type).toBe("text");
    expect(txtWtOfVehicle.classList).toContain("textboxWidgetClass");
    expect(txtWtOfVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewSettlement_txtWtOfVehicle")
    );
    await act(async () => {
      userEvent.type(txtWtOfVehicle, "123");
    });
    expect(txtWtOfVehicle.getAttribute("value")).toBe("");
    expect(txtWtOfVehicle.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWtOfVehicle", () => {
    // START_USER_CODE-USER_txtWtOfVehicle_TEST
    // END_USER_CODE-USER_txtWtOfVehicle_TEST
  });
});
