/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
  goTo,
  hide,
  show
} from "../../shared/WindowImports";

import "./DelvSettlePaymentView.scss";
import * as XLSX from 'xlsx/xlsx.mjs';
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions";
import moment from "moment";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService"
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_DelvSettlePaymentView(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DelvSettlePaymentView",
    windowName: "DelvSettlePaymentView",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.DelvSettlePaymentView",
    // START_USER_CODE-USER_DelvSettlePaymentView_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Payment History",
      scrCode: "PN1180H",
    },
    // END_USER_CODE-USER_DelvSettlePaymentView_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    colCheckNum: {
      name: "colCheckNum",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Check Num",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCheckNum_PROPERTIES

      // END_USER_CODE-USER_colCheckNum_PROPERTIES
    },
    colClearedDate: {
      name: "colClearedDate",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Cleared Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colClearedDate_PROPERTIES

      // END_USER_CODE-USER_colClearedDate_PROPERTIES
    },
    colIntrestAgreement: {
      name: "colIntrestAgreement",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Intrest Agreement",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIntrestAgreement_PROPERTIES

      // END_USER_CODE-USER_colIntrestAgreement_PROPERTIES
    },
    colIssuedDate: {
      name: "colIssuedDate",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Issued Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIssuedDate_PROPERTIES

      // END_USER_CODE-USER_colIssuedDate_PROPERTIES
    },
    colProceeds: {
      name: "colProceeds",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Proceeds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProceeds_PROPERTIES

      // END_USER_CODE-USER_colProceeds_PROPERTIES
    },
    colSpecialType: {
      name: "colSpecialType",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Special Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSpecialType_PROPERTIES

      // END_USER_CODE-USER_colSpecialType_PROPERTIES
    },
    colStatus: {
      name: "colStatus",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStatus_PROPERTIES

      // END_USER_CODE-USER_colStatus_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    colVoidDate: {
      name: "colVoidDate",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Void Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVoidDate_PROPERTIES

      // END_USER_CODE-USER_colVoidDate_PROPERTIES
    },
    colByngPt: {
      name: "colByngPt",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colByngPt_PROPERTIES

      // END_USER_CODE-USER_colByngPt_PROPERTIES
    },
    colAgree: {
      name: "colAgree",
      type: "GridColumnWidget",
      parent: "gridPayments",
      Label: "Agreement #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgree_PROPERTIES

      // END_USER_CODE-USER_colAgree_PROPER5TIES
    },
    gridPayments: {
      name: "gridPayments",
      type: "GridWidget",
      parent: "grpbxDelvSettlePaymentView",
      gridCellsOrder:
        "txtcolCheckNum,txtcolVendor,txtcolStatus,txtcolProceeds,txtcolIntrestAgreement,txtcolSpecialType,txtcolIssuedDate,txtcolClearedDate,txtcolVoidDate,txtcolByngPt,txtcolAgree",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridPayments_PROPERTIES

      // END_USER_CODE-USER_gridPayments_PROPERTIES
    },
    lblPayments: {
      name: "lblPayments",
      type: "LabelWidget",
      parent: "grpbxDelvSettlePaymentView",
      Label: "Payments:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPayments_PROPERTIES

      // END_USER_CODE-USER_lblPayments_PROPERTIES
    },
    txtcolCheckNum: {
      name: "txtcolCheckNum",
      type: "TextBoxWidget",
      colName: "colCheckNum",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCheckNum_PROPERTIES

      // END_USER_CODE-USER_txtcolCheckNum_PROPERTIES
    },
    txtcolClearedDate: {
      name: "txtcolClearedDate",
      type: "TextBoxWidget",
      colName: "colClearedDate",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolClearedDate_PROPERTIES

      // END_USER_CODE-USER_txtcolClearedDate_PROPERTIES
    },
    txtcolIntrestAgreement: {
      name: "txtcolIntrestAgreement",
      type: "TextBoxWidget",
      colName: "colIntrestAgreement",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolIntrestAgreement_PROPERTIES

      // END_USER_CODE-USER_txtcolIntrestAgreement_PROPERTIES
    },
    txtcolIssuedDate: {
      name: "txtcolIssuedDate",
      type: "TextBoxWidget",
      colName: "colIssuedDate",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolIssuedDate_PROPERTIES

      // END_USER_CODE-USER_txtcolIssuedDate_PROPERTIES
    },
    txtcolProceeds: {
      name: "txtcolProceeds",
      type: "TextBoxWidget",
      colName: "colProceeds",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProceeds_PROPERTIES

      // END_USER_CODE-USER_txtcolProceeds_PROPERTIES
    },
    txtcolSpecialType: {
      name: "txtcolSpecialType",
      type: "TextBoxWidget",
      colName: "colSpecialType",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSpecialType_PROPERTIES

      // END_USER_CODE-USER_txtcolSpecialType_PROPERTIES
    },
    txtcolStatus: {
      name: "txtcolStatus",
      type: "TextBoxWidget",
      colName: "colStatus",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolStatus_PROPERTIES
    },
    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtcolVoidDate: {
      name: "txtcolVoidDate",
      type: "TextBoxWidget",
      colName: "colVoidDate",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVoidDate_PROPERTIES

      // END_USER_CODE-USER_txtcolVoidDate_PROPERTIES
    },
    txtcolByngPt: {
      name: "txtcolByngPt",
      type: "TextBoxWidget",
      colName: "colByngPt",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolByngPt_PROPERTIES

      // END_USER_CODE-USER_txtcolByngPt_PROPERTIES
    },
    txtcolAgree: {
      name: "txtcolAgree",
      type: "TextBoxWidget",
      colName: "colAgree",
      parent: "gridPayments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgree_PROPERTIES

      // END_USER_CODE-USER_txtcolAgree_PROPERTIES
    },
    grpbxDelvSettlePaymentView: {
      name: "grpbxDelvSettlePaymentView",
      type: "GroupBoxWidget",
      parent: "DelvSettlePaymentView",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDelvSettlePaymentView_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvSettlePaymentView_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "DelvSettlePaymentView",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  const [loading, setLoading] = useState(true);
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    FormLoad()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  function FormLoad() {
    GetDelvSettlePaymentView();
  }
  const GetDelvSettlePaymentView = async () => {
    let PaymentsData = getData(thisObj, "DataForDelvSettlePaymentView");
    let buy_pt_id = PaymentsData.buy_pt_id
    let agreeNumber = PaymentsData.agreeNumber
    let tmpdata = []
    let tmpdata1 = []
    try {
      setLoading(true);
      if (buy_pt_id !== '' && buy_pt_id !== undefined && agreeNumber !== '' && agreeNumber !== undefined) {
        let readyToPay = await SettlementService.RetrieveTradeSettlementPaymentDetails(buy_pt_id, null, agreeNumber)

        if (readyToPay.length > 0 || readyToPay !== undefined) {
          for (let j = 0; j < readyToPay.length; j++) {
            let Obj = {}
            Obj.txtcolCheckNum = "R-T-P"
            Obj.txtcolVendor = readyToPay[j].payee_vendor
            Obj.txtcolStatus = "Unpaid"
            Obj.txtcolProceeds = readyToPay[j].rtp_proceeds
            Obj.txtcolIntrestAgreement = readyToPay[j].intr_agree_num
            Obj.txtcolSpecialType = readyToPay[j].special_type
            Obj.txtcolIssuedDate = ""
            Obj.txtcolClearedDate = ""
            Obj.txtcolVoidDate = ""
            Obj.txtcolByngPt = buy_pt_id
            Obj.txtcolAgree = agreeNumber
            Obj.rowID$ = j;
            tmpdata.push(Obj);
          }
        }
        setValue(thisObj, "gridPayments", tmpdata)
        let paymentActivity = await ContractManagementService.RetrievePaymentActivityDetails(buy_pt_id, null, null, null, agreeNumber, null, null, null, null, null, null, null)
        if (paymentActivity.length > 0) {
          for (let i = 0; i < paymentActivity.length; i++) {
            let Obj1 = {}
            Obj1.txtcolCheckNum = formatCheckNumber(paymentActivity[i].check_num)
            Obj1.txtcolVendor = paymentActivity[i].payeeVendor
            switch (paymentActivity[i].checkStatus.trim()) {
              case "I":
                Obj1.txtcolStatus = "Issued"
                break;
              case "R":
                Obj1.txtcolStatus = "Issued"
                break;
              case "V":
                Obj1.txtcolStatus = "Voided"
                break;
              case "C":
                Obj1.txtcolStatus = "Cleared"
                break;

              default: null
                break;
            }
            Obj1.txtcolProceeds = paymentActivity[i].checkProceeds
            Obj1.txtcolIntrestAgreement = paymentActivity[i].intrAgreeNum
            Obj1.txtcolSpecialType = paymentActivity[i].specialType
            Obj1.txtcolIssuedDate = (paymentActivity[i].issuedDate == null || paymentActivity[i].issuedDate == undefined) ? "" : moment(paymentActivity[i].issuedDate).format('M/D/YYYY')
            Obj1.txtcolClearedDate = (paymentActivity[i].clearDate == null || paymentActivity[i].clearDate == undefined) ? "" : moment(paymentActivity[i].clearDate).format('M/D/YYYY')
            Obj1.txtcolVoidDate = (paymentActivity[i].voidDate == null || paymentActivity[i].voidDate == undefined) ? "" : moment(paymentActivity[i].voidDate).format('M/D/YYYY')
            Obj1.txtcolByngPt = buy_pt_id
            Obj1.txtcolAgree = agreeNumber
            Obj1.rowID$ = i;
            tmpdata1.push(Obj1)
          }
          setValue(thisObj, "gridPayments", tmpdata1)
        }
        setLoading(false);
        // thisObj.setState(current => {
        //   return {
        //     ...current,
        //     rows:
        //       tmpdata
        //   }
        // });
      }
      else {
        showMessage(thisObj, "Data not found")
      }
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for GetDelvSettlePaymentView event:Formload"
        );
      }
      return false;
    }
    return true;
  };
  // ExportToExcel Functionality
  const onbtnExportToExcelClick = async () => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let PaymentsData = getData(thisObj, "DataForDelvSettlePaymentView");
      let excelData = getValue(thisObj, "gridPayments")
      let buy_pt_id = PaymentsData.buy_pt_id
      let agreeNumber = PaymentsData.agreeNumber
      if (excelData !== null || excelData !== undefined || excelData !== '') {
        var fileName = "Payment History DeliveryAgreement " + buy_pt_id + agreeNumber
        const arrayData = []
        for (var i = 0; i < excelData.length; i++) {
          var obj = {
            "Chek Num": excelData[i].txtcolCheckNum,
            "Vendor": excelData[i].txtcolVendor,
            "Status": excelData[i].txtcolStatus,
            "Proceeds": ExcelNumericDataConvert(excelData[i].txtcolProceeds),
            "Interest Agreement": excelData[i].txtcolIntrestAgreement,
            "Special Type": excelData[i].txtcolSpecialType,
            "Issued Date": excelData[i].txtcolIssuedDate,
            "Cleared Date": excelData[i].txtcolClearedDate,
            "Void Date": excelData[i].txtcolVoidDate,
            "Buying Point": excelData[i].txtcolByngPt,
            "Agreement # ": excelData[i].txtcolAgree
          }
          arrayData.push(obj);
        }
        const ws = XLSX.utils.json_to_sheet(arrayData)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var FileSaver = require('file-saver');
        FileSaver.saveAs(data, fileName + fileExtension);
      }
      else { showMessage(thisObj, "No Data to Export") }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnExportToExcelClick event:Click"
        );
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;
  // Print Functionality
  const onbtnPrintClick = () => {
    try {
      let PaymentsData = getData(thisObj, "DataForDelvSettlePaymentView");
      let buy_pt_id = PaymentsData.buy_pt_id
      let agreeNumber = PaymentsData.agreeNumber
      var data = getValue(thisObj, "gridPayments")
      let sDocName = "Payment History DeliveryAgreement" + " " + buy_pt_id + agreeNumber
      if (data != null && data != undefined) {
        var res = []
        for (var i = 0; i < data.length; i++) {

          res.push([
            data[i].txtcolCheckNum, data[i].txtcolVendor, data[i].txtcolStatus, data[i].txtcolProceeds, data[i].txtcolIntrestAgreement, data[i].txtcolSpecialType, data[i].txtcolIssuedDate, data[i].txtcolClearedDate, data[i].txtcolVoidDate, data[i].txtcolByngPt, data[i].txtcolAgree
          ])
        }
        var obj = new jsPDF();
        obj.autoTable({
          startY: 5,
          startX: 20,
          styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
          headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Check Num', 'Vendor', 'Status', 'Proceeds', 'Interest Agreement', 'Special Type', 'Issued Date', 'Cleared Date', 'Void Date', 'Buying Point', 'Agreement #'
            ]
          ]
        });
        var pageCount = obj.internal.getNumberOfPages();
        for (let w = 0; w <= pageCount; w++) {
          obj.setPage(w);
          let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
          obj.setFontSize(8);
          obj.text(sDocName, 10, obj.internal.pageSize.height - 10);
        }
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Enter Filename")
          }
        }
        else {
          alert("Enter Filename")
        }
      } else {
        showMessage(thisObj, "No Data to Print")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;
  function formatCheckNumber(LstrCheckNumber) {
    try {
      if (LstrCheckNumber.includes("S")) {
        LstrCheckNumber = LstrCheckNumber.trim().substring(1);
        return "S" + "0".repeat(5 - LstrCheckNumber.length) + LstrCheckNumber.trim();
      } else {
        return "0".repeat(6 - LstrCheckNumber.trim().length) + LstrCheckNumber.trim();
      }
    } catch (error) {
      // Handle error if needed
      return null;
    }
  }
  // START_USER_CODE-USER_METHODS
  const onbtnCloseClick = () => {
    try {
      document.getElementById("WarehouseReceipts_DelvSettlePaymentViewPopUp").childNodes[0].click()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DelvSettlePaymentView*/}

              {/* END_USER_CODE-USER_BEFORE_DelvSettlePaymentView*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDelvSettlePaymentView*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDelvSettlePaymentView*/}

              <GroupBoxWidget
                conf={state.grpbxDelvSettlePaymentView}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblPayments*/}

                {/* END_USER_CODE-USER_BEFORE_lblPayments*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPayments}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPayments*/}

                {/* END_USER_CODE-USER_AFTER_lblPayments*/}
                {/* START_USER_CODE-USER_BEFORE_gridPayments*/}

                {/* END_USER_CODE-USER_BEFORE_gridPayments*/}

                <GridWidget
                  conf={state.gridPayments}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPayments}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPayments*/}

                {/* END_USER_CODE-USER_AFTER_gridPayments*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDelvSettlePaymentView*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDelvSettlePaymentView*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_DelvSettlePaymentView*/}

              {/* END_USER_CODE-USER_AFTER_DelvSettlePaymentView*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_DelvSettlePaymentView);
