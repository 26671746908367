/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FuelSurchargeInquiry from "./FuelSurchargeInquiry";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FuelSurchargeInquiry Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FuelSurchargeInquiry />
      );
    });
  });
  afterEach(cleanup);
  test("is FuelSurchargeInquiry Loads Successfully", () => {
    expect(screen.getByText("FuelSurchargeInquiry")).toBeInTheDocument;
  });
  test("Custom Test Cases for FuelSurchargeInquiry", () => {
    // START_USER_CODE-USER_FuelSurchargeInquiry_Custom_Test_Case
    // END_USER_CODE-USER_FuelSurchargeInquiry_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FuelSurchargeInquiry />
      );
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FuelSurchargeInquiry_btnExportToExcel"
      )
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("gridFarmList(Grid Widget) Test Cases", async () => {
    let gridFarmList = screen.getByTestId("gridFarmList");
    let gridFarmListbtn = gridFarmList.nextElementSibling.firstElementChild;
    gridFarmList = gridFarmList.parentElement.parentElement.parentElement;
    expect(gridFarmList.tagName).toBe("DIV");
    expect(gridFarmList.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridFarmList", () => {
    // START_USER_CODE-USER_gridFarmList_TEST
    // END_USER_CODE-USER_gridFarmList_TEST
  });
  test("grpbxFuelSurchargeInquiry(GroupBox Widget) Test Cases", async () => {
    const grpbxFuelSurchargeInquiry = screen.getByTestId(
      "grpbxFuelSurchargeInquiry"
    );
    expect(grpbxFuelSurchargeInquiry.tagName).toBe("BUTTON");
    expect(grpbxFuelSurchargeInquiry.type).toBe("button");
    expect(grpbxFuelSurchargeInquiry.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFuelSurchargeInquiry", () => {
    // START_USER_CODE-USER_grpbxFuelSurchargeInquiry_TEST
    // END_USER_CODE-USER_grpbxFuelSurchargeInquiry_TEST
  });
  test("gridFarmList_txtAddDate(Grid Widget) Test Cases", async () => {
    let gridFarmList_txtAddDate = screen.getByTestId("gridFarmList");
    let gridFarmList_txtAddDatebtn =
      gridFarmList_txtAddDate.nextElementSibling.firstElementChild;
    gridFarmList_txtAddDate =
      gridFarmList_txtAddDate.parentElement.parentElement.parentElement;
    expect(gridFarmList_txtAddDate.tagName).toBe("DIV");
    expect(gridFarmList_txtAddDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtAddDate", () => {
    // START_USER_CODE-USER_txtAddDate_TEST
    // END_USER_CODE-USER_txtAddDate_TEST
  });
  test("txtCarrierAbbriviation(Textbox Widget) Test Cases", async () => {
    const txtCarrierAbbriviation = screen.getByTestId("txtCarrierAbbriviation");
    expect(txtCarrierAbbriviation.tagName).toBe("INPUT");
    expect(txtCarrierAbbriviation.type).toBe("text");
    expect(txtCarrierAbbriviation.classList).toContain("textboxWidgetClass");
    expect(txtCarrierAbbriviation.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FuelSurchargeInquiry_txtCarrierAbbriviation"
      )
    );
    await act(async () => {
      userEvent.type(txtCarrierAbbriviation, "1");
    });
  });
  test("Custom Test Cases for txtCarrierAbbriviation", () => {
    // START_USER_CODE-USER_txtCarrierAbbriviation_TEST
    // END_USER_CODE-USER_txtCarrierAbbriviation_TEST
  });
  test("gridFarmList_txtCarrierName(Grid Widget) Test Cases", async () => {
    let gridFarmList_txtCarrierName = screen.getByTestId("gridFarmList");
    let gridFarmList_txtCarrierNamebtn =
      gridFarmList_txtCarrierName.nextElementSibling.firstElementChild;
    gridFarmList_txtCarrierName =
      gridFarmList_txtCarrierName.parentElement.parentElement.parentElement;
    expect(gridFarmList_txtCarrierName.tagName).toBe("DIV");
    expect(gridFarmList_txtCarrierName.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtCarrierName", () => {
    // START_USER_CODE-USER_txtCarrierName_TEST
    // END_USER_CODE-USER_txtCarrierName_TEST
  });
  test("gridFarmList_txtCompanyName(Grid Widget) Test Cases", async () => {
    let gridFarmList_txtCompanyName = screen.getByTestId("gridFarmList");
    let gridFarmList_txtCompanyNamebtn =
      gridFarmList_txtCompanyName.nextElementSibling.firstElementChild;
    gridFarmList_txtCompanyName =
      gridFarmList_txtCompanyName.parentElement.parentElement.parentElement;
    expect(gridFarmList_txtCompanyName.tagName).toBe("DIV");
    expect(gridFarmList_txtCompanyName.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtCompanyName", () => {
    // START_USER_CODE-USER_txtCompanyName_TEST
    // END_USER_CODE-USER_txtCompanyName_TEST
  });
  test("txtEffectiveDate(Date Widget) Test Cases", async () => {
    const txtEffectiveDate = screen.getByTestId("txtEffectiveDate");
    expect(txtEffectiveDate.tagName).toBe("INPUT");
    expect(txtEffectiveDate.type).toBe("text");
    expect(txtEffectiveDate.classList).toContain("datetimepicker-input");
    expect(
      txtEffectiveDate.parentElement.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FuelSurchargeInquiry_txtEffectiveDate"
      )
    );
    await act(async () => {
      userEvent.click(txtEffectiveDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtEffectiveDate", () => {
    // START_USER_CODE-USER_txtEffectiveDate_TEST
    // END_USER_CODE-USER_txtEffectiveDate_TEST
  });
  test("gridFarmList_txtEffectiveDateC(Grid Widget) Test Cases", async () => {
    let gridFarmList_txtEffectiveDateC = screen.getByTestId("gridFarmList");
    let gridFarmList_txtEffectiveDateCbtn =
      gridFarmList_txtEffectiveDateC.nextElementSibling.firstElementChild;
    gridFarmList_txtEffectiveDateC =
      gridFarmList_txtEffectiveDateC.parentElement.parentElement.parentElement;
    expect(gridFarmList_txtEffectiveDateC.tagName).toBe("DIV");
    expect(gridFarmList_txtEffectiveDateC.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtEffectiveDateC", () => {
    // START_USER_CODE-USER_txtEffectiveDateC_TEST
    // END_USER_CODE-USER_txtEffectiveDateC_TEST
  });
  test("gridFarmList_txtExpirationDate(Grid Widget) Test Cases", async () => {
    let gridFarmList_txtExpirationDate = screen.getByTestId("gridFarmList");
    let gridFarmList_txtExpirationDatebtn =
      gridFarmList_txtExpirationDate.nextElementSibling.firstElementChild;
    gridFarmList_txtExpirationDate =
      gridFarmList_txtExpirationDate.parentElement.parentElement.parentElement;
    expect(gridFarmList_txtExpirationDate.tagName).toBe("DIV");
    expect(gridFarmList_txtExpirationDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtExpirationDate", () => {
    // START_USER_CODE-USER_txtExpirationDate_TEST
    // END_USER_CODE-USER_txtExpirationDate_TEST
  });
  test("gridFarmList_txtLocation(Grid Widget) Test Cases", async () => {
    let gridFarmList_txtLocation = screen.getByTestId("gridFarmList");
    let gridFarmList_txtLocationbtn =
      gridFarmList_txtLocation.nextElementSibling.firstElementChild;
    gridFarmList_txtLocation =
      gridFarmList_txtLocation.parentElement.parentElement.parentElement;
    expect(gridFarmList_txtLocation.tagName).toBe("DIV");
    expect(gridFarmList_txtLocation.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtLocation", () => {
    // START_USER_CODE-USER_txtLocation_TEST
    // END_USER_CODE-USER_txtLocation_TEST
  });
  test("gridFarmList_txtPerMileRate(Grid Widget) Test Cases", async () => {
    let gridFarmList_txtPerMileRate = screen.getByTestId("gridFarmList");
    let gridFarmList_txtPerMileRatebtn =
      gridFarmList_txtPerMileRate.nextElementSibling.firstElementChild;
    gridFarmList_txtPerMileRate =
      gridFarmList_txtPerMileRate.parentElement.parentElement.parentElement;
    expect(gridFarmList_txtPerMileRate.tagName).toBe("DIV");
    expect(gridFarmList_txtPerMileRate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPerMileRate", () => {
    // START_USER_CODE-USER_txtPerMileRate_TEST
    // END_USER_CODE-USER_txtPerMileRate_TEST
  });
  test("gridFarmList_txtProfitCenter(Grid Widget) Test Cases", async () => {
    let gridFarmList_txtProfitCenter = screen.getByTestId("gridFarmList");
    let gridFarmList_txtProfitCenterbtn =
      gridFarmList_txtProfitCenter.nextElementSibling.firstElementChild;
    gridFarmList_txtProfitCenter =
      gridFarmList_txtProfitCenter.parentElement.parentElement.parentElement;
    expect(gridFarmList_txtProfitCenter.tagName).toBe("DIV");
    expect(gridFarmList_txtProfitCenter.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtProfitCenter", () => {
    // START_USER_CODE-USER_txtProfitCenter_TEST
    // END_USER_CODE-USER_txtProfitCenter_TEST
  });
  test("gridFarmList_txtSCACCode(Grid Widget) Test Cases", async () => {
    let gridFarmList_txtSCACCode = screen.getByTestId("gridFarmList");
    let gridFarmList_txtSCACCodebtn =
      gridFarmList_txtSCACCode.nextElementSibling.firstElementChild;
    gridFarmList_txtSCACCode =
      gridFarmList_txtSCACCode.parentElement.parentElement.parentElement;
    expect(gridFarmList_txtSCACCode.tagName).toBe("DIV");
    expect(gridFarmList_txtSCACCode.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtSCACCode", () => {
    // START_USER_CODE-USER_txtSCACCode_TEST
    // END_USER_CODE-USER_txtSCACCode_TEST
  });
  test("gridFarmList_txtSurchargePercent(Grid Widget) Test Cases", async () => {
    let gridFarmList_txtSurchargePercent = screen.getByTestId("gridFarmList");
    let gridFarmList_txtSurchargePercentbtn =
      gridFarmList_txtSurchargePercent.nextElementSibling.firstElementChild;
    gridFarmList_txtSurchargePercent =
      gridFarmList_txtSurchargePercent.parentElement.parentElement
        .parentElement;
    expect(gridFarmList_txtSurchargePercent.tagName).toBe("DIV");
    expect(gridFarmList_txtSurchargePercent.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FuelSurchargeInquiry_gridFarmList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtSurchargePercent", () => {
    // START_USER_CODE-USER_txtSurchargePercent_TEST
    // END_USER_CODE-USER_txtSurchargePercent_TEST
  });
});
