/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import moment from 'moment'
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  getValue,
  setData,
  setValue,
  getData,
  hideColumn
} from "../../shared/WindowImports";

import "./OpenAccountAdjustments.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import Loading from "../../../Loader/Loading";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_OpenAccountAdjustments(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let buyingPointId = JSON.parse(sessionStorage.getItem('lstBuyingPointID'))
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "OpenAccountAdjustments",
    windowName: "OpenAccountAdjustments",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.OpenAccountAdjustments",
    // START_USER_CODE-USER_OpenAccountAdjustments_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Open Account Adjustments",
      scrCode: "PN0160C",
    },
    // END_USER_CODE-USER_OpenAccountAdjustments_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    colARBalAdj: {
      name: "colARBalAdj",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "AR Bal Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colARBalAdj_PROPERTIES

      // END_USER_CODE-USER_colARBalAdj_PROPERTIES
    },
    colCheck: {
      name: "colCheck",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Check#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCheck_PROPERTIES

      // END_USER_CODE-USER_colCheck_PROPERTIES
    },
    colComments: {
      name: "colComments",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Comments",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colComments_PROPERTIES

      // END_USER_CODE-USER_colComments_PROPERTIES
    },
    colContract: {
      name: "colContract",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Contract#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContract_PROPERTIES

      // END_USER_CODE-USER_colContract_PROPERTIES
    },
    colCRBalAdj: {
      name: "colCRBalAdj",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "CR Bal Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCRBalAdj_PROPERTIES

      // END_USER_CODE-USER_colCRBalAdj_PROPERTIES
    },
    colEffectiveDate: {
      name: "colEffectiveDate",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Effective Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_colEffectiveDate_PROPERTIES
    },
    colEntryDate: {
      name: "colEntryDate",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Entry Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEntryDate_PROPERTIES

      // END_USER_CODE-USER_colEntryDate_PROPERTIES
    },
    colInspection: {
      name: "colInspection",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Inspection#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInspection_PROPERTIES

      // END_USER_CODE-USER_colInspection_PROPERTIES
    },
    colOilStock: {
      name: "colOilStock",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Oil Stock",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOilStock_PROPERTIES

      // END_USER_CODE-USER_colOilStock_PROPERTIES
    },
    colOPBalAdj: {
      name: "colOPBalAdj",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "OP Bal Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOPBalAdj_PROPERTIES

      // END_USER_CODE-USER_colOPBalAdj_PROPERTIES
    },
    colSettlement: {
      name: "colSettlement",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Settlement",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSettlement_PROPERTIES

      // END_USER_CODE-USER_colSettlement_PROPERTIES
    },
    colSRBalAdj: {
      name: "colSRBalAdj",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "SR Bal Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSRBalAdj_PROPERTIES

      // END_USER_CODE-USER_colSRBalAdj_PROPERTIES
    },
    colUser: {
      name: "colUser",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "User",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUser_PROPERTIES

      // END_USER_CODE-USER_colUser_PROPERTIES
    },
    colWhseRcpt: {
      name: "colWhseRcpt",
      type: "GridColumnWidget",
      parent: "gridAdjustments",
      Label: "Whse Rcpt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhseRcpt_PROPERTIES

      // END_USER_CODE-USER_colWhseRcpt_PROPERTIES
    },
    gridAdjustments: {
      name: "gridAdjustments",
      type: "GridWidget",
      parent: "grpbxOpenAccountAdjustments",
      gridCellsOrder:
        "txtcolEntryDate,txtcolEffectiveDate,txtcolOPBalAdj,txtcolSRBalAdj,txtcolARBalAdj,txtcolCRBalAdj,txtcolContract,txtcolInspection,txtcolSettlement,txtcolCheck,txtcolWhseRcpt,txtcolOilStock,txtcolUser,txtcolComments",
      ColSpan: "5",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridAdjustments_PROPERTIES

      // END_USER_CODE-USER_gridAdjustments_PROPERTIES
    },
    lblAdjustments: {
      name: "lblAdjustments",
      type: "LabelWidget",
      parent: "grpbxOpenAccountAdjustments",
      Label: "Adjustments",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAdjustments_PROPERTIES

      // END_USER_CODE-USER_lblAdjustments_PROPERTIES
    },
    lblARBalAdj: {
      name: "lblARBalAdj",
      type: "LabelWidget",
      parent: "grpbxOpenAccountAdjustments",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblARBalAdj_PROPERTIES

      // END_USER_CODE-USER_lblARBalAdj_PROPERTIES
    },
    lblCRBalAdj: {
      name: "lblCRBalAdj",
      type: "LabelWidget",
      parent: "grpbxOpenAccountAdjustments",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCRBalAdj_PROPERTIES

      // END_USER_CODE-USER_lblCRBalAdj_PROPERTIES
    },
    lblOPBalAdj: {
      name: "lblOPBalAdj",
      type: "LabelWidget",
      parent: "grpbxOpenAccountAdjustments",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOPBalAdj_PROPERTIES

      // END_USER_CODE-USER_lblOPBalAdj_PROPERTIES
    },
    lblSRBalAdj: {
      name: "lblSRBalAdj",
      type: "LabelWidget",
      parent: "grpbxOpenAccountAdjustments",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSRBalAdj_PROPERTIES

      // END_USER_CODE-USER_lblSRBalAdj_PROPERTIES
    },
    lblTotals: {
      name: "lblTotals",
      type: "LabelWidget",
      parent: "grpbxOpenAccountAdjustments",
      Label: "Totals",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTotals_PROPERTIES

      // END_USER_CODE-USER_lblTotals_PROPERTIES
    },
    lblViewOpenAdjustmentsDetail: {
      name: "lblViewOpenAdjustmentsDetail",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "View Open Adjustments Detail",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblViewOpenAdjustmentsDetail_PROPERTIES

      // END_USER_CODE-USER_lblViewOpenAdjustmentsDetail_PROPERTIES
    },
    txtcolARBalAdj: {
      name: "txtcolARBalAdj",
      type: "TextBoxWidget",
      colName: "colARBalAdj",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolARBalAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolARBalAdj_PROPERTIES
    },
    txtcolCheck: {
      name: "txtcolCheck",
      type: "TextBoxWidget",
      colName: "colCheck",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCheck_PROPERTIES

      // END_USER_CODE-USER_txtcolCheck_PROPERTIES
    },
    txtcolComments: {
      name: "txtcolComments",
      type: "TextBoxWidget",
      colName: "colComments",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolComments_PROPERTIES

      // END_USER_CODE-USER_txtcolComments_PROPERTIES
    },
    txtcolContract: {
      name: "txtcolContract",
      type: "TextBoxWidget",
      colName: "colContract",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContract_PROPERTIES

      // END_USER_CODE-USER_txtcolContract_PROPERTIES
    },
    txtcolCRBalAdj: {
      name: "txtcolCRBalAdj",
      type: "TextBoxWidget",
      colName: "colCRBalAdj",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCRBalAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolCRBalAdj_PROPERTIES
    },
    txtcolEffectiveDate: {
      name: "txtcolEffectiveDate",
      type: "TextBoxWidget",
      colName: "colEffectiveDate",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_txtcolEffectiveDate_PROPERTIES
    },
    txtcolEntryDate: {
      name: "txtcolEntryDate",
      type: "TextBoxWidget",
      colName: "colEntryDate",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolEntryDate_PROPERTIES

      // END_USER_CODE-USER_txtcolEntryDate_PROPERTIES
    },
    txtcolInspection: {
      name: "txtcolInspection",
      type: "TextBoxWidget",
      colName: "colInspection",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInspection_PROPERTIES

      // END_USER_CODE-USER_txtcolInspection_PROPERTIES
    },
    txtcolOilStock: {
      name: "txtcolOilStock",
      type: "TextBoxWidget",
      colName: "colOilStock",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOilStock_PROPERTIES

      // END_USER_CODE-USER_txtcolOilStock_PROPERTIES
    },
    txtcolOPBalAdj: {
      name: "txtcolOPBalAdj",
      type: "TextBoxWidget",
      colName: "colOPBalAdj",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOPBalAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolOPBalAdj_PROPERTIES
    },
    txtcolSettlement: {
      name: "txtcolSettlement",
      type: "TextBoxWidget",
      colName: "colSettlement",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSettlement_PROPERTIES

      // END_USER_CODE-USER_txtcolSettlement_PROPERTIES
    },
    txtcolSRBalAdj: {
      name: "txtcolSRBalAdj",
      type: "TextBoxWidget",
      colName: "colSRBalAdj",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSRBalAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolSRBalAdj_PROPERTIES
    },
    txtcolUser: {
      name: "txtcolUser",
      type: "TextBoxWidget",
      colName: "colUser",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUser_PROPERTIES

      // END_USER_CODE-USER_txtcolUser_PROPERTIES
    },
    txtcolWhseRcpt: {
      name: "txtcolWhseRcpt",
      type: "TextBoxWidget",
      colName: "colWhseRcpt",
      parent: "gridAdjustments",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWhseRcpt_PROPERTIES

      // END_USER_CODE-USER_txtcolWhseRcpt_PROPERTIES
    },
    grpbxOpenAccountAdjustments: {
      name: "grpbxOpenAccountAdjustments",
      type: "GroupBoxWidget",
      parent: "OpenAccountAdjustments",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxOpenAccountAdjustments_PROPERTIES

      // END_USER_CODE-USER_grpbxOpenAccountAdjustments_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "OpenAccountAdjustments",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  const [loading, setLoading] = useState(true);
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {

  }, [getData(thisObj, 'openaccountprofiledetails')]);
  const FormLoad = () => {
    
    let openaccount_profile_details = getData(thisObj, 'openaccountprofiledetails')
    if (openaccount_profile_details !== null && openaccount_profile_details !== undefined && openaccount_profile_details !== '') {
      openGrid(openaccount_profile_details.lstBuyingPointID, openaccount_profile_details.txtAccountVendor);

    }
  };

  function isNumeric(n) {  // isNumeric function
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  function FormatCheckNumber(number) {
    number = number.toString()
    let returnVal = "";
    let index = number.trim().indexOf('S');
    if (index != -1) {
      let subNumber = number.trim().substring(index + 1);
      if (subNumber.length > 0) {
        returnVal = "S" + ("0".repeat(5 - subNumber.length)) + subNumber;
      }
    }
    else {
      if (number.length > 0) {
        returnVal = ("0".repeat(6 - number.length)) + number;
      }
    }
    return returnVal;
  }
  // Grid Functionality

  const openGrid = async (buy_pt_id, acct_vendor) => {
    try {

      setLoading(true);
      let TransformedRowsArray = [];
      let obj = {};
      let response = await SystemMaintenanceApplicationSupportService.RetrieveOpenAccountAdjustmentDetails(buy_pt_id, acct_vendor)
      let data = response;
      let nCheck = 0;
      let nRecv = 0;
      let nBatch = 0;
      let totalOPB = 0;
      let totalSRB = 0;
      let totalARB = 0;
      let totalCRB = 0;

      let js = []
      obj = {}
      for (var i = 0; i < data.length; i++) {
        if(data[i].strFieldName == "TRANSACTION_NUM"){
          obj = {}
          obj.transactionNum = data[i].strFieldData
        }
        if(data[i].strFieldName == "COMP_ID"){
          obj.compId = data[i].strFieldData
        }
        if(data[i].strFieldName == "BUY_PT_ID"){
          obj.buyPtId = data[i].strFieldData
        }
        if(data[i].strFieldName == "ACCT_VENDOR"){
          obj.vendor = data[i].strFieldData
        }
        if(data[i].strFieldName == "ACCT_REMIT"){
          obj.remit = data[i].strFieldData
        }
        if(data[i].strFieldName == "OPEN_BAL_ADJ"){
          obj.openBalAdj = data[i].strFieldData
        }
        if(data[i].strFieldName == "SR_BAL_ADJ"){
          obj.srBalAdj = data[i].strFieldData
        }
        if(data[i].strFieldName == "CR_BAL_ADJ"){
          obj.crBalAdj = data[i].strFieldData
        }
        if(data[i].strFieldName == "AR_BAL_ADJ"){
          obj.arBalAdj = data[i].strFieldData
        }
      }
      for (var i = 0; i < data.length; i++) {
        let existrow = 0
        hideColumn(thisObj, "gridAdjustments", 'txtcolOilStock')

        if (data[i].strFieldName.trim() == "TRANSACTION_NUM") {
          obj = {}
        }
        if (data[i].strFieldName.trim() == 'ADD_DATE_TIME') {
          TransformedRowsArray.push(obj)
          existrow = 1
          obj.txtcolEntryDate = new Date(data[i].strFieldData.trim()).toLocaleDateString();
        }
        if (data[i].strFieldName.trim() == 'AUDIT_DATE_TIME') {
          existrow = 1
          obj.txtcolEffectiveDate = new Date(data[i].strFieldData.trim()).toLocaleDateString();
        }
        if (data[i].strFieldName.trim() == 'OPEN_BAL_ADJ') {
          existrow = 1
          obj.txtcolOPBalAdj = data[i].strFieldData.trim()
          totalOPB += Number(data[i].strFieldData);

        }
        if (data[i].strFieldName.trim() == 'SR_BAL_ADJ') {
          existrow = 1
          obj.txtcolSRBalAdj = data[i].strFieldData.trim()
          totalSRB += Number(data[i].strFieldData);

        }
        if (data[i].strFieldName.trim() == 'AR_BAL_ADJ') {
          existrow = 1
          obj.txtcolARBalAdj = data[i].strFieldData.trim()
          totalARB += Number(data[i].strFieldData);

        }
        if (data[i].strFieldName.trim() == 'CR_BAL_ADJ') {
          existrow = 1
          obj.txtcolCRBalAdj = data[i].strFieldData.trim()
          totalCRB += Number(data[i].strFieldData);

        }
        if (data[i].strFieldName.trim() == 'AUDIT_CONT_NUM') {
          existrow = 1
          obj.txtcolContract = data[i].strFieldData.trim()
        }
        if (data[i].strFieldName.trim() == 'AUDIT_INSP_NUM') {
          existrow = 1
          obj.txtcolInspection = data[i].strFieldData.trim()
        }
        if (data[i].strFieldName.trim() == 'AUDIT_SETTLE_NUM') {
          existrow = 1
          obj.txtcolSettlement = data[i].strFieldData.trim()
        }
        
        if(data[i].strFieldName == "AUDIT_CHECK_NUM"){
          if(!isNaN(data[i].strFieldData)){
            nCheck = data[i].strFieldData
          }
        }
        if(data[i].strFieldName == "AUDIT_RECEIVABLE_NUM"){
          if(!isNaN(data[i].strFieldData)){
            nRecv = data[i].strFieldData
          }
        }
        if(data[i].strFieldName == "AUDIT_BATCH_NUMBER"){
          if(!isNaN(data[i].strFieldData)){
            nBatch = data[i].strFieldData
          }
        }
        if (nCheck > 0) {
          if (data[i].strFieldName.trim() == 'AUDIT_CHECK_NUM') {
            existrow = 1
            obj.txtcolCheck = data[i].strFieldData.trim()
          }
        }
        else if (nRecv > 0) {
          if (data[i].strFieldName.trim() == 'AUDIT_RECEIVABLE_NUM') {
            existrow = 1
            obj.txtcolCheck =FormatCheckNumber('S'+ data[i].strFieldData.trim())
          }
        }
        else if (nBatch > 0) {
          obj.txtcolCheck = "Batched";
        }
        else {
          obj.txtcolCheck = "";
        }
        if (data[i].strFieldName.trim() == 'AUDIT_WHSE_RCPT') {
          existrow = 1
          obj.txtcolWhseRcpt = data[i].strFieldData.trim()
        }
        if (data[i].strFieldName.trim() == 'audit_os_settle_load') {
          existrow = 1
          obj.txtcolOilStock = data[i].strFieldData.trim()
        }
        if (data[i].strFieldName.trim() == 'AUDIT_USER') {
          existrow = 1
          obj.txtcolUser = data[i].strFieldData.trim()
        }
        if (data[i].strFieldName.trim() == 'AUDIT_REASON') {
          existrow = 1
          obj.txtcolComments = data[i].strFieldData.trim()
        }
      }
      setValue(thisObj, "gridAdjustments", TransformedRowsArray);
      setData(thisObj, "lblOPBalAdj", Number(totalOPB).toFixed(2));
      setData(thisObj, "lblSRBalAdj", Number(totalSRB).toFixed(2));
      setData(thisObj, "lblARBalAdj",  Number(totalARB).toFixed(2));
      setData(thisObj, "lblCRBalAdj",  Number(totalCRB).toFixed(2));
      setValue(thisObj, "lblOPBalAdj", Number(totalOPB).toFixed(2));
      setValue(thisObj, "lblSRBalAdj",Number(totalSRB).toFixed(2));
      setValue(thisObj, "lblARBalAdj", Number(totalARB).toFixed(2));
      setValue(thisObj, "lblCRBalAdj", Number(totalCRB).toFixed(2));
      setLoading(false);
    }
  
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click")
      }

      return false
    }
    return true
  }

  //ExportToExcelClick button

  const onbtnExportToExcelClick = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let datatable = getValue(thisObj, 'gridAdjustments');
    var fileName = "Open Account Adjustment";
    let excelData = [];
    let totalData = {}
    if (datatable != undefined) {
      for (let i = 0; i < datatable.length; i++) {
        let rowdata = {};
        if (thisObj.state.gridAdjustments.columns[0].Visible) {
          rowdata["Entry Date"] = datatable[i].txtcolEntryDate;
          totalData["Entry Date"] = 'Totals';
        }
        if (thisObj.state.gridAdjustments.columns[1].Visible) {
          rowdata["Effective Date"] = datatable[i].txtcolEffectiveDate;
          totalData["Effective Date"] = '';
        }
        if (thisObj.state.gridAdjustments.columns[2].Visible) {
          rowdata["OP Bal Adj"] = datatable[i].txtcolOPBalAdj;
          totalData["OP Bal Adj"] = getData(thisObj, "lblOPBalAdj");
        }
        if (thisObj.state.gridAdjustments.columns[3].Visible) {
          rowdata["SR Bal Adj"] = datatable[i].txtcolSRBalAdj;
          totalData["SR Bal Adj"] = getData(thisObj, "lblSRBalAdj");
        }
        if (thisObj.state.gridAdjustments.columns[4].Visible) {
          rowdata["AR Bal Adj"] = datatable[i].txtcolARBalAdj;
          totalData["AR Bal Adj"] = getData(thisObj, "lblARBalAdj");
        }
        if (thisObj.state.gridAdjustments.columns[5].Visible) {
          rowdata["CR Bal Adj"] = datatable[i].txtcolCRBalAdj;
          totalData["CR Bal Adj"] = getData(thisObj, "lblCRBalAdj");
        }
        if (thisObj.state.gridAdjustments.columns[6].Visible) {
          rowdata["Contract#"] = datatable[i].txtcolContract;
        }
        if (thisObj.state.gridAdjustments.columns[7].Visible) {
          rowdata["Inspection#"] = datatable[i].txtcolInspection;
        }
        if (thisObj.state.gridAdjustments.columns[8].Visible) {
          rowdata["Settlment#"] = datatable[i].txtcolSettlement;
        }
        if (thisObj.state.gridAdjustments.columns[9].Visible) {
          rowdata["Check#"] = datatable[i].txtcolCheck;
        }
        if (thisObj.state.gridAdjustments.columns[10].Visible) {
          rowdata["WhseRcpt#"] = datatable[i].txtcolWhseRcpt;
        }
        if (thisObj.state.gridAdjustments.columns[11].Visible) {
          rowdata["OilStock#"] = datatable[i].txtcolOilStock;
        }
        if (thisObj.state.gridAdjustments.columns[12].Visible) {
          rowdata["User"] = datatable[i].txtcolUser;
        }
        if (thisObj.state.gridAdjustments.columns[13].Visible) {
          rowdata["Comments"] = datatable[i].txtcolComments;
        }
         
        excelData.push(rowdata);
      }
      excelData.push(totalData)
    }
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    var FileSaver = require('file-saver');
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  //print button

  const onbtnPrintClick = () => {
    try {
      var data = getValue(thisObj, "gridAdjustments")
      let screendata= getData(thisObj ,"openaccountprofiledetails")


      let sDocName = cropYear +" " + screendata.lstBuyingPointID + " - " + screendata.txtAccountVendor + " "+ screendata.vendor_sort_name +" - "
      sDocName += moment(new Date()).format("MM/DD/YYYY h:mm:ss A");
      var res = []
      let headerArray = []

      if (thisObj.state.gridAdjustments.columns[0].Visible) {
        headerArray.push("Entry Date");
      }
      if (thisObj.state.gridAdjustments.columns[1].Visible) {
        headerArray.push("Effective Date");
      }
      if (thisObj.state.gridAdjustments.columns[2].Visible) {
        headerArray.push("OP Bal Adj");
      }
      if (thisObj.state.gridAdjustments.columns[3].Visible) {
        headerArray.push("SR Bal Adj");
      }
      if (thisObj.state.gridAdjustments.columns[4].Visible) {
        headerArray.push("AR Bal Adj");
      }
      if (thisObj.state.gridAdjustments.columns[5].Visible) {
        headerArray.push("CR Bal Adj");
      }
      if (thisObj.state.gridAdjustments.columns[6].Visible) {
        headerArray.push("Contract#");
      }
      if (thisObj.state.gridAdjustments.columns[7].Visible) {
        headerArray.push("Inspection#");
      }
      if (thisObj.state.gridAdjustments.columns[8].Visible) {
        headerArray.push("Settlment#");
      }
      if (thisObj.state.gridAdjustments.columns[9].Visible) {
        headerArray.push("Check#");
      }
      if (thisObj.state.gridAdjustments.columns[10].Visible) {
        headerArray.push("WhseRcpt#");
      }
      if (thisObj.state.gridAdjustments.columns[11].Visible) {
        headerArray.push("OilStock#");
      }
      if (thisObj.state.gridAdjustments.columns[12].Visible) {
        headerArray.push("User");
      }
      if (thisObj.state.gridAdjustments.columns[13].Visible) {
        headerArray.push("Comments");
      }
      
      for (var i = 0; i < data.length; i++) {
        let NewArray = []
        if (thisObj.state.gridAdjustments.columns[0].Visible) {
          NewArray.push(data[i].txtcolEntryDate);
        }
        if (thisObj.state.gridAdjustments.columns[1].Visible) {
          NewArray.push(data[i].txtcolEffectiveDate);
        }
        if (thisObj.state.gridAdjustments.columns[2].Visible) {
          NewArray.push(data[i].txtcolOPBalAdj);
        }
        if (thisObj.state.gridAdjustments.columns[3].Visible) {
          NewArray.push(data[i].txtcolSRBalAdj);
        }
        if (thisObj.state.gridAdjustments.columns[4].Visible) {
          NewArray.push(data[i].txtcolARBalAdj);
        }
        if (thisObj.state.gridAdjustments.columns[5].Visible) {
          NewArray.push(data[i].txtcolCRBalAdj);
        }
        if (thisObj.state.gridAdjustments.columns[6].Visible) {
          NewArray.push(data[i].txtcolContract);
        }
        if (thisObj.state.gridAdjustments.columns[7].Visible) {
          NewArray.push(data[i].txtcolInspection);
        }
        if (thisObj.state.gridAdjustments.columns[8].Visible) {
          NewArray.push(data[i].txtcolSettlement);
        }
        if (thisObj.state.gridAdjustments.columns[9].Visible) {
          NewArray.push(data[i].txtcolCheck);
        }
        if (thisObj.state.gridAdjustments.columns[10].Visible) {
          NewArray.push(data[i].txtcolWhseRcpt);
        }
        if (thisObj.state.gridAdjustments.columns[11].Visible) {
          NewArray.push(data[i].txtcolOilStock);
        }
        if (thisObj.state.gridAdjustments.columns[12].Visible) {
          NewArray.push(data[i].txtcolUser);
        }
        if (thisObj.state.gridAdjustments.columns[13].Visible) {
          NewArray.push(data[i].txtcolComments);
        }
        res.push(NewArray)
      }
      // Total count 
      res.push([
        'Total',
        '',
        getData(thisObj, 'lblOPBalAdj'),
        getData(thisObj, 'lblSRBalAdj'),
        getData(thisObj, 'lblARBalAdj'),
        getData(thisObj, 'lblCRBalAdj'),
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      ])
      var obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [headerArray]
      });
      var pageCount = obj.internal.getNumberOfPages();
      
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(8);
       obj.text( sDocName, 10, obj.internal.pageSize.height - 10);
      }
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:OpenAccountAdjustments.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:OpenAccountAdjustments.cmdPrintSearch_Click")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;
  //close button

  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceApplicationSupport_OpenAccountAdjustmentsPopUp").childNodes[0].click()

      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_OpenAccountAdjustments*/}

              {/* END_USER_CODE-USER_BEFORE_OpenAccountAdjustments*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxOpenAccountAdjustments*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxOpenAccountAdjustments*/}

              <GroupBoxWidget
                conf={state.grpbxOpenAccountAdjustments}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblAdjustments*/}

                {/* END_USER_CODE-USER_BEFORE_lblAdjustments*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAdjustments}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAdjustments*/}

                {/* END_USER_CODE-USER_AFTER_lblAdjustments*/}
                {/* START_USER_CODE-USER_BEFORE_gridAdjustments*/}

                {/* END_USER_CODE-USER_BEFORE_gridAdjustments*/}

                <GridWidget
                  conf={state.gridAdjustments}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridAdjustments}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridAdjustments*/}

                {/* END_USER_CODE-USER_AFTER_gridAdjustments*/}
                {/* START_USER_CODE-USER_BEFORE_lblTotals*/}

                {/* END_USER_CODE-USER_BEFORE_lblTotals*/}

                <LabelWidget
                  values={values}
                  conf={state.lblTotals}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblTotals*/}

                {/* END_USER_CODE-USER_AFTER_lblTotals*/}
                {/* START_USER_CODE-USER_BEFORE_lblOPBalAdj*/}

                {/* END_USER_CODE-USER_BEFORE_lblOPBalAdj*/}

                <LabelWidget
                  values={values}
                  conf={state.lblOPBalAdj}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblOPBalAdj*/}

                {/* END_USER_CODE-USER_AFTER_lblOPBalAdj*/}
                {/* START_USER_CODE-USER_BEFORE_lblSRBalAdj*/}

                {/* END_USER_CODE-USER_BEFORE_lblSRBalAdj*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSRBalAdj}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSRBalAdj*/}

                {/* END_USER_CODE-USER_AFTER_lblSRBalAdj*/}
                {/* START_USER_CODE-USER_BEFORE_lblARBalAdj*/}

                {/* END_USER_CODE-USER_BEFORE_lblARBalAdj*/}

                <LabelWidget
                  values={values}
                  conf={state.lblARBalAdj}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblARBalAdj*/}

                {/* END_USER_CODE-USER_AFTER_lblARBalAdj*/}
                {/* START_USER_CODE-USER_BEFORE_lblCRBalAdj*/}

                {/* END_USER_CODE-USER_BEFORE_lblCRBalAdj*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCRBalAdj}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCRBalAdj*/}

                {/* END_USER_CODE-USER_AFTER_lblCRBalAdj*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxOpenAccountAdjustments*/}

              {/* END_USER_CODE-USER_AFTER_grpbxOpenAccountAdjustments*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblViewOpenAdjustmentsDetail*/}

                {/* END_USER_CODE-USER_BEFORE_lblViewOpenAdjustmentsDetail*/}

                <LabelWidget
                  values={values}
                  conf={state.lblViewOpenAdjustmentsDetail}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblViewOpenAdjustmentsDetail*/}

                {/* END_USER_CODE-USER_AFTER_lblViewOpenAdjustmentsDetail*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btn1*/}

                {/* END_USER_CODE-USER_BEFORE_btn1*/}

                <ButtonWidget
                  conf={state.btn1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn1*/}

                {/* END_USER_CODE-USER_AFTER_btn1*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_OpenAccountAdjustments*/}

              {/* END_USER_CODE-USER_AFTER_OpenAccountAdjustments*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_OpenAccountAdjustments
);
