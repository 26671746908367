/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  getData,
  setData,
  getValue,
  setValue,
  goTo,
  getSelectedRowNumber,
  enable,
  disable,
  getRowCount,
  getGridValue,
} from "../../shared/WindowImports";

import "./OpenAccountsSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import Loading from "../../../Loader/Loading";
import { SystemMaintenanceMasterManagementService } from "../../SystemMaintenanceMasterManagement/Service/SystemMaintenanceMasterManagementServices"
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_OpenAccountsSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "OpenAccountsSetup",
    windowName: "OpenAccountsSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.OpenAccountsSetup",
    // START_USER_CODE-USER_OpenAccountsSetup_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Open Accounts Setup",
      scrCode: "PN0160A",
    },
    // END_USER_CODE-USER_OpenAccountsSetup_PROPERTIES
    brnSearch: {
      name: "brnSearch",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchActions",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_brnSearch_PROPERTIES

      // END_USER_CODE-USER_brnSearch_PROPERTIES
    },
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchActions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnDown1: {
      name: "btnDown1",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchActions",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDown1_PROPERTIES
      iconName: 'fa-arrow-down'
      // END_USER_CODE-USER_btnDown1_PROPERTIES
    },
    btnDown2: {
      name: "btnDown2",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchActions",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDown2_PROPERTIES
      iconName: 'fa-arrow-down'
      // END_USER_CODE-USER_btnDown2_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchActions",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnUp1: {
      name: "btnUp1",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchActions",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUp1_PROPERTIES
      iconName: 'fa-arrow-up'
      // END_USER_CODE-USER_btnUp1_PROPERTIES
    },
    btnUp2: {
      name: "btnUp2",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchActions",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUp2_PROPERTIES
      iconName: 'fa-arrow-up'
      // END_USER_CODE-USER_btnUp2_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxOpenAccountsSetup",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    cmmndCntnrSearchActions: {
      name: "cmmndCntnrSearchActions",
      type: "CommandContainerWidget",
      parent: "grpbxOpenAccountsSetup",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "8",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_cmmndCntnrSearchActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrSearchActions_PROPERTIES
    },
    colAcctVendor: {
      name: "colAcctVendor",
      type: "GridColumnWidget",
      parent: "gridOpenAccount",
      Label: "Acct Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAcctVendor_PROPERTIES

      // END_USER_CODE-USER_colAcctVendor_PROPERTIES
    },
    colAR: {
      name: "colAR",
      type: "GridColumnWidget",
      parent: "gridOpenAccount",
      Label: "A/R",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAR_PROPERTIES

      // END_USER_CODE-USER_colAR_PROPERTIES
    },
    colCR: {
      name: "colCR",
      type: "GridColumnWidget",
      parent: "gridOpenAccount",
      Label: "C/R",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCR_PROPERTIES

      // END_USER_CODE-USER_colCR_PROPERTIES
    },
    colOpenAcct: {
      name: "colOpenAcct",
      type: "GridColumnWidget",
      parent: "gridOpenAccount",
      Label: "Open Acct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenAcct_PROPERTIES

      // END_USER_CODE-USER_colOpenAcct_PROPERTIES
    },
    colSR: {
      name: "colSR",
      type: "GridColumnWidget",
      parent: "gridOpenAccount",
      Label: "S/R",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSR_PROPERTIES

      // END_USER_CODE-USER_colSR_PROPERTIES
    },
    colVendorSortName: {
      name: "colVendorSortName",
      type: "GridColumnWidget",
      parent: "gridOpenAccount",
      Label: "Vendor Sort Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorSortName_PROPERTIES

      // END_USER_CODE-USER_colVendorSortName_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxOpenAccountsSetup",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    gridOpenAccount: {
      name: "gridOpenAccount",
      type: "GridWidget",
      parent: "grpbxOpenAccountsSetup",
      gridCellsOrder:
        "txtcolAcctVendor,txtcolVendorSortName,txtcolOpenAcct,txtcolAR,txtcolSR,txtcolCR",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridOpenAccount_PROPERTIES

      // END_USER_CODE-USER_gridOpenAccount_PROPERTIES
    },
    txtAcctVendor: {
      name: "txtAcctVendor",
      type: "TextBoxWidget",
      parent: "grpbxOpenAccountsSetup",
      Label: "Acct Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAcctVendor_PROPERTIES

      // END_USER_CODE-USER_txtAcctVendor_PROPERTIES
    },
    txtAcctVndr: {
      name: "txtAcctVndr",
      type: "TextBoxWidget",
      parent: "grpbxOpenAccountsSetup",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAcctVndr_PROPERTIES

      // END_USER_CODE-USER_txtAcctVndr_PROPERTIES
    },
    txtcolAcctVendor: {
      name: "txtcolAcctVendor",
      type: "TextBoxWidget",
      colName: "colAcctVendor",
      parent: "gridOpenAccount",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAcctVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolAcctVendor_PROPERTIES
    },
    txtcolAR: {
      name: "txtcolAR",
      type: "TextBoxWidget",
      colName: "colAR",
      parent: "gridOpenAccount",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAR_PROPERTIES

      // END_USER_CODE-USER_txtcolAR_PROPERTIES
    },
    txtcolCR: {
      name: "txtcolCR",
      type: "TextBoxWidget",
      colName: "colCR",
      parent: "gridOpenAccount",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCR_PROPERTIES

      // END_USER_CODE-USER_txtcolCR_PROPERTIES
    },
    txtcolOpenAcct: {
      name: "txtcolOpenAcct",
      type: "TextBoxWidget",
      colName: "colOpenAcct",
      parent: "gridOpenAccount",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpenAcct_PROPERTIES

      // END_USER_CODE-USER_txtcolOpenAcct_PROPERTIES
    },
    txtcolSR: {
      name: "txtcolSR",
      type: "TextBoxWidget",
      colName: "colSR",
      parent: "gridOpenAccount",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSR_PROPERTIES

      // END_USER_CODE-USER_txtcolSR_PROPERTIES
    },
    txtcolVendorSortName: {
      name: "txtcolVendorSortName",
      type: "TextBoxWidget",
      colName: "colVendorSortName",
      parent: "gridOpenAccount",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendorSortName_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorSortName_PROPERTIES
    },
    txtAcctVendorName: {
      name: "txtAcctVendorName",
      type: "TextBoxWidget",
      parent: "grpbxOpenAccountsSetup",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAcctVendorName_PROPERTIES

      // END_USER_CODE-USER_txtAcctVendorName_PROPERTIES
    },
    grpbxOpenAccountsSetup: {
      name: "grpbxOpenAccountsSetup",
      type: "GroupBoxWidget",
      parent: "OpenAccountsSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxOpenAccountsSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxOpenAccountsSetup_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "OpenAccountsSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceApplicationSupport#OpenAccountsProfile":{},
       },
      REVERSE:{
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceApplicationSupport#OpenAccountsProfile":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnClose:{
      DEFAULT:["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
    // btnAdd:{
    //   DEFAULT:[
    //   "SystemMaintenanceApplicationSupport#OpenAccountsProfile#DEFAULT#true#Click",]
    // },
  //   btnEdit:{
  //     DEFAULT:[
  //     "SystemMaintenanceApplicationSupport#OpenAccountsProfile#DEFAULT#true#Click",]
  //   },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  const[loading,setLoading] = useState(false)

  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
 
  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
      if (vendor_lookup !== null && vendor_lookup !== undefined && vendor_lookup !== '') {
        thisObj.setFieldValue("txtAcctVendor", vendor_lookup.VendorNumber);
        let VenNumber = vendor_lookup.VendorNumber
        bFillAccountRemitToList(vendor_lookup.VendorNumber)
        thisObj.setFieldValue("txtAcctVndr", ConvertToPascalCase(vendor_lookup.vendorName))
        setData(thisObj, "vendorDetails", null)
      }
    },[getData(thisObj, 'vendorDetails')]);
  
  useEffect(()=>{
    let childData = getData(thisObj, 'frmOpenAccountsSetup')
    if (childData !== null) {
      onbrnSearchClick()
    }
    setData(thisObj, 'frmOpenAccountsSetup', null)
   
  },[getData(thisObj, 'frmOpenAccountsSetup')])

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const [selectedRowNum, setSelectedRowNum] = useState(-1);

  //FormLoad Function
  let FormLoad = async () => {
    setData(thisObj,'gridOpenAccount',null)
    await bFillBuyingPointList();
    EnableDisableControls()
 }

//Account Vendor Textbox
const ontxtAcctVendorBlur = () => {
  try{
  let txtVendorNumber = txtAcctVendor.value
  if (txtVendorNumber.length == 6) {
    bFillAccountRemitToList(txtVendorNumber)
  } else {
    setValue(thisObj,'txtAcctVndr','')
    disable(thisObj,'txtAcctVndr')
    document.getElementsByClassName("txtAcctVndr")[0].style.visibility = "hidden";
  }
  
}catch(err){	
  if(err instanceof EvalError){	
    showMessage(thisObj, err.message)	
  }else{	
    showMessage(thisObj, "Error on txtVendor change event"	)	
  }
  return false
}
return true;
}
thisObj.ontxtAcctVendorBlur = ontxtAcctVendorBlur;  
  
//seaching vendorname based on vendorname  
const bFillAccountRemitToList = async(txtVendorNumber) => {
  let venname = null
  let vendornamevisible = false
  let response=await ContractManagementService.RetrieveVendorByNumber(txtVendorNumber)
    let data = response
    if (data !== null && data !== undefined && data.length > 0) {
      venname = data[0].name
      vendornamevisible = true
    }
    if (vendornamevisible == true) {
      document.getElementsByClassName("txtAcctVndr")[0].style.visibility = "visible";
      setValue(thisObj, "txtAcctVndr", ConvertToPascalCase(venname))
      disable(thisObj,'txtAcctVndr')
    } else {
      document.getElementsByClassName("txtAcctVndr")[0].style.visibility = "hidden";
      setValue(thisObj, "txtAcctVndr", '')
  }
};

 //Dropdown for Location List
 const bFillBuyingPointList = async () => {
   let response =  await ContractManagementService.RetrieveBuyingPointControlDetails('PN0160', null, null, null, null);
   let data = response;
   let obj = {};
   let js = [];
   if (data !== null && data !== undefined && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      let dataExist = js.find(elem => elem.key == data[i].buy_pt_id)
      if (dataExist == undefined) {
       obj={
         key: data[i].buy_pt_id, 
         description: data[i].buy_pt_id + " - " + ConvertToPascalCase(data[i].buy_pt_name),
         index: i
       }
       js.push(obj);
       obj={}
      }
    }
   }
   setData(thisObj, 'locationLength', js.length);
   setData(thisObj, 'LocationListData', js)
   setValue(thisObj, "ddBuyingPoint", js.at(0).key)
  
   thisObj.setState(current => {
    return {
      ...current,
      ddBuyingPoint: {
        ...state["ddBuyingPoint"],
        valueList: js,
      }
    }
  })
 }

 //Click event of Add Vendor button
 const onbtnVendorClick = async () => {
  try{
   goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click");
  }
  catch(err){
   errorHandler(err)
  }
 };
 thisObj.onbtnVendorClick = onbtnVendorClick;
 
//Click event of Location Add button
const onbtnAddClick = () => {
  try {
    let locationList = thisObj.state['ddBuyingPoint'].valueList
    let buy_pt_id = getValue(thisObj, 'ddBuyingPoint');
    let locationName = ''
    if (buy_pt_id !== undefined && buy_pt_id !== null && buy_pt_id !== '') {
      locationName = locationList.find(elem => elem.key == buy_pt_id).description
    }
    let frmOpenAccountsProfile = {}
    frmOpenAccountsProfile.cmdOk_Caption = 'Add';
    frmOpenAccountsProfile.lblBuyingPoint_Caption = locationName;
    frmOpenAccountsProfile.lblBuyingPoint_Tag = buy_pt_id;
    frmOpenAccountsProfile.txtAccountVendor_enabled = 'True';
    setData(thisObj, 'frmOpenAccountsProfile', frmOpenAccountsProfile)
    goTo(thisObj,"SystemMaintenanceApplicationSupport#OpenAccountsProfile#DEFAULT#true#Click")
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred during Location Add Button Click"
      );
    }
    return false;
  }
}
thisObj.onbtnAddClick = onbtnAddClick

  //Search button
  const onbrnSearchClick = async() => {
    try{
     let TotalOpen = 0
     let TotalAR = 0
     let TotalSR = 0
     let TotalCR = 0
     let vendorName=''
     setLoading(true)
     setValue(thisObj,'txtAcctVendorName','')
     let buy_pt_id = getValue(thisObj, 'ddBuyingPoint');
     let acct_vendor = txtAcctVendor.value;
     let response = await AccountPayment.RetrieveOpenAccountControlDetails(buy_pt_id,acct_vendor)
     let js = [];
       if (response !== null && response !== undefined && response.length > 0) {
         let data1 = response;
         let obj = {};
         let data=data1[0].open_acct_control
         for (var i = 0; i < data.length; i++) {
           obj.txtcolAcctVendor = data[i].acct_vendor;
           obj.txtcolVendorSortName = data[i].vendor_sort_name == null ? 'KLINE JOHNNY' :data[i].vendor_sort_name
           obj.txtcolOpenAcct = data[i].open_end_balance == 0 ? '0.00': Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(data[i].open_end_balance))
           obj.txtcolAR = data[i].ar_end_balance == 0 ? '0.00' : Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(data[i].ar_end_balance))
           obj.txtcolSR = data[i].sr_end_balance == 0 ? '0.00' : Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(data[i].sr_end_balance))
           obj.txtcolCR = data[i].cr_end_balance == 0 ? '0.00' : Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(data[i].cr_end_balance))
           TotalOpen+= Number(data[i].open_end_balance);
           TotalAR+= Number(data[i].ar_end_balance);
           TotalSR+= Number(data[i].sr_end_balance);
           TotalCR+= Number(data[i].cr_end_balance)
           obj.txtcolAcctVendorname=data[i].vendor_name
           
           js.push(obj)
           obj = {}
         }
         if(data.length > 1){
         js.push({
          txtcolAcctVendor : "",
          txtcolVendorSortName : "Total:",
          txtcolOpenAcct : TotalOpen == 0 ? '0.00' : Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(TotalOpen)),
          txtcolAR : TotalAR == 0 ? '0.00' : Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(TotalAR)),
          txtcolSR : TotalSR == 0 ? '0.00' : Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(TotalSR)),
          txtcolCR : TotalCR == 0 ? '0.00' : Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(TotalCR)),
         })
        }
       }
       setValue(thisObj, 'gridOpenAccount', js);
       
       if (js.length == 1) {
         setSelectedRowNum(0);
         js[0].isSelected = true;
       }
       else if(js.length >0){
         setSelectedRowNum(0)
       }
       setData(thisObj, 'gridOpenAccount', js)
       setRowColor()
       
       thisObj.setState((current) => {
         return {
           ...current,
           gridVoidReasonData: {
             ...current["gridOpenAccount"],
             valueList: js
           },
         };
       });
       EnableDisableControls()
     }
    
    catch (err) {
     if (err instanceof EvalError) {
       showMessage(thisObj, err.message);
     } else {
       showMessage(
         thisObj,
         "Error occurred during Search Button Click"
       );
     }
     return false;
    }
    finally{
     setLoading(false)
    }
   };
   thisObj.onbrnSearchClick = onbrnSearchClick;
  
  const selectRow = (newRowNum) => {
    const gridData = getValue(thisObj, 'gridOpenAccount');
      let rowNum = getSelectedRowNumber(thisObj, 'gridOpenAccount');
      if (rowNum == undefined) {
        rowNum = 0;
      }
      let acct_vendorname = gridData[rowNum].txtcolAcctVendorname
      setValue(thisObj,'txtAcctVendorName',acct_vendorname)
      disable(thisObj,'txtAcctVendorName')
      
    if (newRowNum !== undefined && selectedRowNum !== undefined && gridData?.length) {
      gridData[selectedRowNum].isSelected = false;
      setSelectedRowNum(newRowNum);
      return(newRowNum)
    }
  }
 
  const setRowColor = () => {
    let n = getRowCount(_kaledo.thisObj,'gridOpenAccount');
    for(let i=0; i<n; i++){
      let val = getGridValue(_kaledo.thisObj,'gridOpenAccount','txtcolVendorSortName',i);``
      if(val == "Total:"){
        document.getElementById(`row-${i}`).style.background="yellow"
      }
      else{
        if(document.getElementById(`row-${i}`).style.background=="yellow"){
          document.getElementById(`row-${i}`).style.background="";
        }
      }
    }
    }

  const ongridOpenAccountRowSelect = (rowItems) => {
    const rowNum = getSelectedRowNumber(thisObj, 'gridOpenAccount');
    selectRow(rowNum);
  }
  thisObj.ongridOpenAccountRowSelect = ongridOpenAccountRowSelect

  //Click event of Delete button
  const onbtnDeleteClick = async() => {
    try {
      setLoading(true)
      let gridData = getValue(thisObj, 'gridOpenAccount');
      let rowNum = getSelectedRowNumber(thisObj, 'gridOpenAccount');
      if (rowNum == undefined) {
        rowNum = 0;
      }
      let acct_vendor = gridData[rowNum].txtcolAcctVendor
      let buy_pt_id= getValue(thisObj, 'ddBuyingPoint');
 
      let res = confirm(`Are you sure you wish to delete this Open Account '${acct_vendor}'`)
      if (res == true) {
        let response = await SystemMaintenanceMasterManagementService.RemoveOpenAccountControlDetails(buy_pt_id,acct_vendor)
        if (![200, 400, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.' + response.message)
          return
        }
        else {
          // showMessage(thisObj, response.message, true)
        }
        await onbrnSearchClick()
        EnableDisableControls()
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Delete Button Click"
        );
      }
      return false;
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick

   // Button Export To Excel Functionality
   const onbtnExportToExcelClick = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let datatable = getValue(thisObj, 'gridOpenAccount');
    var fileName = "Open Account Search";
    let excelData = [];
    let totalData = {}
    if (datatable != undefined) {
      for (let i = 0; i < datatable.length; i++) {
        let rowdata = {};
        if (thisObj.state.gridOpenAccount.columns[0].Visible) {
          rowdata["Acct Vendor"] = datatable[i].txtcolAcctVendor;
        }
        if (thisObj.state.gridOpenAccount.columns[1].Visible) {
          rowdata["Vendor Sort Name"] = datatable[i].txtcolVendorSortName;
        }
        if (thisObj.state.gridOpenAccount.columns[2].Visible) {
          rowdata["Open Acct"] = ExcelNumericDataConvert(datatable[i].txtcolOpenAcct);
        }
        if (thisObj.state.gridOpenAccount.columns[3].Visible) {
          rowdata["A/R"] = ExcelNumericDataConvert(datatable[i].txtcolAR);
        }
        if (thisObj.state.gridOpenAccount.columns[3].Visible) {
          rowdata["S/R"] = ExcelNumericDataConvert(datatable[i].txtcolSR);
        }
        if (thisObj.state.gridOpenAccount.columns[3].Visible) {
          rowdata["C/R"] = ExcelNumericDataConvert(datatable[i].txtcolCR);
        }
        excelData.push(rowdata);
      }
      excelData.push(totalData)
    }
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    var FileSaver = require('file-saver');
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  // Print Button Functionality
  const onbtnPrintClick = () => {
    try {
      let sDocName;
      let locationList = thisObj.state['ddBuyingPoint'].valueList
      var data = getValue(thisObj, "gridOpenAccount")
      let buy_pt_id= getValue(thisObj ,"ddBuyingPoint")
      let locationName = ''
      if (buy_pt_id !== undefined && buy_pt_id !== null && buy_pt_id !== '') {
      locationName = locationList.find(elem => elem.key == buy_pt_id).description
      }
      let rowNum = getSelectedRowNumber(thisObj, 'gridOpenAccount');
      if (rowNum == undefined) {
        rowNum = 0;
      }
      let txtVendorNumber = txtAcctVendor.value
      if(txtVendorNumber != ''){
        sDocName = cropYear +" " + "Open Acct. "+ " - " + locationName + " - " + txtVendorNumber + " - "
        sDocName += moment(new Date()).format("MM/DD/YYYY h:mm:ss A");
      }
      else if(txtVendorNumber == ''){
        sDocName = cropYear +" " + "Open Acct. "+ " - " + locationName +  " - "
        sDocName += moment(new Date()).format("MM/DD/YYYY h:mm:ss A");
      }
      var res = []
      let headerArray = []

      if (thisObj.state.gridOpenAccount.columns[0].Visible) {
        headerArray.push("Acct Vendor");
      }
      if (thisObj.state.gridOpenAccount.columns[1].Visible) {
        headerArray.push("Vendor Sort Name");
      }
      if (thisObj.state.gridOpenAccount.columns[2].Visible) {
        headerArray.push("Open Acct");
      }
      if (thisObj.state.gridOpenAccount.columns[3].Visible) {
        headerArray.push("A/R");
      }
      if (thisObj.state.gridOpenAccount.columns[4].Visible) {
        headerArray.push("S/R");
      }
      if (thisObj.state.gridOpenAccount.columns[5].Visible) {
        headerArray.push("C/R");
      }
     
      for (var i = 0; i < data.length; i++) {
        let NewArray = []
        if (thisObj.state.gridOpenAccount.columns[0].Visible) {
          NewArray.push(data[i].txtcolAcctVendor);
        }
        if (thisObj.state.gridOpenAccount.columns[1].Visible) {
          NewArray.push(data[i].txtcolVendorSortName);
        }
        if (thisObj.state.gridOpenAccount.columns[2].Visible) {
          NewArray.push(data[i].txtcolOpenAcct);
        }
        if (thisObj.state.gridOpenAccount.columns[3].Visible) {
          NewArray.push(data[i].txtcolAR);
        }
        if (thisObj.state.gridOpenAccount.columns[4].Visible) {
          NewArray.push(data[i].txtcolSR);
        }
        if (thisObj.state.gridOpenAccount.columns[5].Visible) {
          NewArray.push(data[i].txtcolCR);
        }
        res.push(NewArray)
      }
      
      var obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [headerArray],
        didParseCell: function(res){
          if(res?.cell?.text?.includes("Total:")){
            res.row.cells[0].styles.fillColor = [ 255, 255, 0 ]
            res.row.cells[1].styles.fillColor = [ 255, 255, 0 ]
            res.row.cells[2].styles.fillColor = [ 255, 255, 0 ]
            res.row.cells[3].styles.fillColor = [ 255, 255, 0 ]
            res.row.cells[4].styles.fillColor = [ 255, 255, 0 ]
            res.row.cells[5].styles.fillColor = [ 255, 255, 0 ]
          }
        }
      });
      var pageCount = obj.internal.getNumberOfPages();
      
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(8);
       obj.text( sDocName, 10, obj.internal.pageSize.height - 10);
      }
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:OpenAccountsSetup.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:OpenAccountsSetup.cmdPrintSearch_Click")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;

  //Click event of Edit button
  const onbtnEditClick = async() => {
    try{
      setLoading(true)
     
      let gridData = getValue(thisObj, 'gridOpenAccount');
      let rowNum = getSelectedRowNumber(thisObj, 'gridOpenAccount')
      if(gridData.length == 1){
        rowNum=0;
      }
      else{
        rowNum = getSelectedRowNumber(thisObj, 'gridOpenAccount');
      }
     
      if (rowNum == undefined) {
        showMessage(thisObj, 'Please select a row to edit.');
        rowNum = 0;
        return;
      }
      let acct_vendor = gridData[rowNum].txtcolAcctVendor
      let js = [];
    js.push({ key: '', description: '' }) //Set default value
    if (acct_vendor.length == 6) {
      let response= await ContractManagementService.RetieveRemitDetails(acct_vendor)
        if (response != undefined && response.length > 0) {
          let data = response
          for (var i = 0; i < data.length; i++) {
            var vendorName = data[i].name
            let obj = { key: data[i].remittoid, description: data[i].remittoid + " - " +  ConvertToPascalCase(data[i].name) + " - " +  ConvertToPascalCase(data[i].city) + ", " +  ConvertToPascalCase(data[i].state) }
            js.push(obj)
          }
          setData(thisObj, "ddAccountRemitTo", js);
          setData(thisObj, "remitToLength", js.length);
        }
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddAccountRemitTo: {
          ...state["ddAccountRemitTo"],
          valueList: js,
        }
      }
    })
      let acct_remitto=getData(thisObj,"ddAccountRemitTo")
      let acct_vendorname=vendorName;
      let buy_pt_id= getValue(thisObj, 'ddBuyingPoint');
      let locationList = thisObj.state['ddBuyingPoint'].valueList
      let locationName = ''
       if (buy_pt_id !== undefined && buy_pt_id !== null && buy_pt_id !== '') {
         locationName = locationList.find(elem => elem.key == buy_pt_id).description
       }
      let response = await AccountPayment.RetrieveOpenAccountControlDetails(buy_pt_id,acct_vendor);
      let data1 = response
      let data=data1[0].open_acct_control
      let frmOpenAccountsProfile = {}
      frmOpenAccountsProfile.cmdOk_Caption = "Update"
      frmOpenAccountsProfile.lblBuyingPoint_Caption= locationName
      frmOpenAccountsProfile.txtAccountVendor = acct_vendor
      frmOpenAccountsProfile.txtAcctVndr = acct_vendorname
      frmOpenAccountsProfile.ddAccountRemitTo=acct_remitto
      frmOpenAccountsProfile.chkboxCollectFormSettlement=data[0].collect_ind
      frmOpenAccountsProfile.txtCollectionAmount=data[0].collect_amt
      frmOpenAccountsProfile.txtCollectionPercent=data[0].collect_pct
      frmOpenAccountsProfile.lblOpenBalanceBB=data[0].open_beg_balance
      frmOpenAccountsProfile.lblOpenBalanceAdj=data[0].open_adjustment
      frmOpenAccountsProfile.lblOpenBalanceCB=data[0].open_end_balance
      frmOpenAccountsProfile.lblSeedReceivableBB=data[0].sr_beg_balance
      frmOpenAccountsProfile.lblSeedReceivableAdj=data[0].sr_adjustment
      frmOpenAccountsProfile.lblSeedReceivableCB=data[0].sr_end_balance
      frmOpenAccountsProfile.lblAcctReceivableBB=data[0].ar_beg_balance
      frmOpenAccountsProfile.lblAcctReceivableAdj=data[0].ar_adjustment
      frmOpenAccountsProfile.lblAcctReceivableCB=data[0].ar_end_balance
      frmOpenAccountsProfile.lblCommReceivableBB=data[0].cr_beg_balance
      frmOpenAccountsProfile.lblCommReceivableAdj=data[0].cr_adjustment
      frmOpenAccountsProfile.lblCommReceivableCB=data[0].cr_end_balance
      let add_date_time= moment(data[0].add_date_time).format("MM/DD/YYYY");
      frmOpenAccountsProfile.lblAddedBy = data[0].add_user + " " + add_date_time
      let chg_date_time = moment(data[0].chg_date_time).format("MM/DD/YYYY");
      frmOpenAccountsProfile.lblChangedBy = data[0].chg_user + " " + chg_date_time
      setData(thisObj, 'frmOpenAccountsProfile', frmOpenAccountsProfile)
      goTo(thisObj,"SystemMaintenanceApplicationSupport#OpenAccountsProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Edit Button Click"
        );
      }
      return false;
    }
    finally{
      setLoading(false)
     }
  }
  thisObj.onbtnEditClick = onbtnEditClick

  const ongridOpenAccountDblclick = () => {//Implimented the Double Click functionality as part of UAT Bug-538706
    try{
      onbtnEditClick()
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during ongridOpenAccountDblclick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.ongridOpenAccountDblclick = ongridOpenAccountDblclick;

  //Function to convert to Pascal Case
  function ConvertToPascalCase(word){
    return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  }
  
  //Enable Disable Controls
  const EnableDisableControls = () => {
    let gridList = getData(thisObj, 'gridOpenAccount')
    let gridLength = ''
    if (gridList !== null && gridList !== undefined) {
      gridLength = gridList.length
    }
    enable(thisObj, 'btnAdd')
    enable(thisObj, 'btnEdit')
    enable(thisObj, 'btnDelete')

    if (gridLength <= 0) {
      enable(thisObj, 'btnAdd')
      disable(thisObj, 'btnEdit')
      disable(thisObj, 'btnDelete')
    }
  }

  //Down1 Arrow function
  const onbtnDown1Click = async() => {
    try{
      let dataListJS = getData(thisObj, 'gridOpenAccount')
      if(dataListJS == null){
        return;
      }
      if(dataListJS.length>1){
      let dataobject1=[]
      let newArray=[]
      let len=dataListJS.length;
      let js1 = sortJSON(dataListJS, 'txtcolAcctVendor', false)      
      setValue(thisObj, "gridOpenAccount", js1)
      setData(thisObj, "gridOpenAccount", js1)
      setRowColor1()
      let acct_vendorname = dataListJS[0].txtcolAcctVendor
      if(acct_vendorname == ''){
        acct_vendorname = dataListJS[len-3].txtcolAcctVendor
      }
      let js2=[]
      let response= await ContractManagementService.RetieveRemitDetails(acct_vendorname)
        if (response != undefined && response.length > 0) {
          let data = response
          for (var i = 0; i < data.length; i++) {
            var vendorName = data[i].name
            let obj = { key: data[i].remittoid, description: data[i].remittoid + " - " + data[i].name + " - " + data[i].city + " - " + data[i].state }
            js2.push(obj)
          }
          setValue(thisObj,'txtAcctVendorName',vendorName)
        }
      }
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnDown1Click = onbtnDown1Click;

  //Up1 Arrow function
  const onbtnUp1Click = async() => {
    try{
      let dataListJS = getData(thisObj, 'gridOpenAccount')
      if(dataListJS == null){
        return;
      }
      if(dataListJS.length>1){
      let js3 = sortJSON(dataListJS, 'txtcolAcctVendor', true)
      let len=js3.length;
      
      setData(thisObj, "gridOpenAccount", js3)
      setRowColor()
      let acct_vendorname = dataListJS[0].txtcolVendorSortName
      if(acct_vendorname == 'KLINE JOHNNY'){
      setValue(thisObj,'txtAcctVendorName','JOHNNY KLINE')
      }
      else{
        let acct_vendor_name = dataListJS[1].txtcolAcctVendorname
        setValue(thisObj,'txtAcctVendorName',acct_vendor_name)
      }
      } 
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnUp1Click = onbtnUp1Click;

  //Set Row Color
  const setRowColor1 = () => {
    let n = getData(_kaledo.thisObj,'gridOpenAccount');
    let rowNum
    for(let i=0;i<n.length;i++){
      document.getElementById(`row-${i}`).style.background="";
    }
     for(let i=0;i<n.length;i++){
      if(n[i].txtcolVendorSortName === 'Total:'){
        rowNum=i;
        document.getElementById(`row-${rowNum}`).style.background="yellow"
        break;
      }
    }
    document.getElementById(`row-${rowNum}`).style.background="yellow"
  }

  //Down2 Arrow Function
  const onbtnDown2Click = async() => {
    try{
      let dataListJS = getData(thisObj, 'gridOpenAccount')
      if(dataListJS == null){
        return;
      }
      if(dataListJS.length>1){
      let dataobject=[]
      let dataobject1=[]
      let js = sortJSON1(dataListJS, 'txtcolVendorSortName', false)
      const extractObject = (txtcolVendorSortName) => {
        return js.filter(row => row.txtcolVendorSortName === txtcolVendorSortName).pop();
      };
      dataobject=extractObject('Total:')
      dataobject1=(js.filter(
        (element) => element.txtcolVendorSortName !== "Total:")
      );
      dataobject1.push(dataobject)
      setValue(thisObj, "gridOpenAccount", dataobject1)
      setData(thisObj, "gridOpenAccount", dataobject1)
      setRowColor1()
      let len=dataobject1.length;
      const extractObject1 = (txtcolVendorSortName) => {
        return dataobject1.filter(row => row.txtcolVendorSortName === txtcolVendorSortName).pop();
      };
     
      let dataobject2=extractObject('KLINE JOHNNY')
      if(dataobject2 == 'KLINE JOHNNY'){
        setValue(thisObj,'txtAcctVendorName','JOHNNY KLINE')
      }
      else{
        let acct_vendorname = dataobject1[0].txtcolAcctVendor
        let js5=[]
        let response= await ContractManagementService.RetieveRemitDetails(acct_vendorname)
          if (response != undefined && response.length > 0) {
            let data = response
            for (var i = 0; i < data.length; i++) {
              var vendorName = data[i].name
              let obj = { key: data[i].remittoid, description: data[i].remittoid + " - " + data[i].name + " - " + data[i].city + " - " + data[i].state }
              js5.push(obj)
            }
            setValue(thisObj,'txtAcctVendorName',vendorName)
          }
      }
    }
  }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnDown2Click = onbtnDown2Click;
  
  //Up2 Arrow function
  const onbtnUp2Click = async() => {
    try{
      let acct_vendorname;
      let acct_vendorname1=getData(thisObj,'gridOpenAccount')
      if(acct_vendorname1 == null || acct_vendorname1.length == 0){
        return;
      }
      else if(acct_vendorname1.length >= 1){
      let js7 = sortJSON(acct_vendorname1, 'txtcolAcctVendor', true)
      if(js7.length == 1){
        acct_vendorname=js7[0].txtcolAcctVendor
      }
      else{
        acct_vendorname=js7[1].txtcolAcctVendor
      }
      let js6=[]
      let response= await ContractManagementService.RetieveRemitDetails(acct_vendorname)
        if (response != undefined && response.length > 0) {
          let data = response
          for (var i = 0; i < data.length; i++) {
            var vendorName = data[0].name
            let obj = { key: data[i].remittoid, description: data[i].remittoid + " - " + data[i].name + " - " + data[i].city + " - " + data[i].state }
            js6.push(obj)
          }
          setValue(thisObj,'txtAcctVendorName',vendorName)
        }
      }
      let dataListJS = getData(thisObj, 'gridOpenAccount')
      if(dataListJS == null){
        return;
      }
      if(dataListJS.length>1){
      let dataobject2=[]
      let dataobject1=[]
      let js = sortJSON1(dataListJS, 'txtcolVendorSortName', true)
      const extractObject = (txtcolVendorSortName) => {
        return js.filter(row => row.txtcolVendorSortName === txtcolVendorSortName).pop();
      };
      let dataobject=extractObject('Total:')
      dataobject2.push(dataobject)
      dataobject1=(js.filter(
        (element) => element.txtcolVendorSortName !== "Total:")
      );
      for(let i=0;i<dataobject1.length;i++){
        dataobject2.push(dataobject1[i])
      }
      setValue(thisObj, "gridOpenAccount", dataobject2)
      setData(thisObj, "gridOpenAccount", dataobject2)
      setRowColor1()
      }
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnUp2Click = onbtnUp2Click;

  //Sort Function
  function sortJSON(arr, key, asc=true) {   
    return arr.sort((a, b) => {     
      let x = a[key];     
      let y = b[key];     
      if (asc) { return ((x < y) ? -1 : ((x > y) ? 1 : 0)); }     
      else { return ((x > y) ? -1 : ((x < y) ? 1 : 0)); }   
    }); 
  }

  function sortJSON1(arr, key, asc=true) {   
    return arr.sort((a, b) => {     
      let x = a[key];     
      let y = b[key];
      if (asc) { return ((x < y) ? -1 : ((x > y) ? 1 : 0)); }     
      else { return ((x > y) ? -1 : ((x < y) ? 1 : 0)); }   
    }); 
  }

  //Function Errorhandler
  function errorHandler(err){
    showMessage(thisObj, err.message)
  } 
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
             <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_OpenAccountsSetup*/}

              {/* END_USER_CODE-USER_BEFORE_OpenAccountsSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxOpenAccountsSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxOpenAccountsSetup*/}

              <GroupBoxWidget
                conf={state.grpbxOpenAccountsSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txtAcctVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtAcctVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAcctVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAcctVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtAcctVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txtAcctVndr*/}

                {/* END_USER_CODE-USER_BEFORE_txtAcctVndr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAcctVndr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAcctVndr*/}

                {/* END_USER_CODE-USER_AFTER_txtAcctVndr*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrSearchActions*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrSearchActions*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrSearchActions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnUp1*/}

                  {/* END_USER_CODE-USER_BEFORE_btnUp1*/}

                  <ButtonWidget
                    conf={state.btnUp1}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnUp1*/}

                  {/* END_USER_CODE-USER_AFTER_btnUp1*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDown1*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDown1*/}

                  <ButtonWidget
                    conf={state.btnDown1}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDown1*/}

                  {/* END_USER_CODE-USER_AFTER_btnDown1*/}
                  {/* START_USER_CODE-USER_BEFORE_btnUp2*/}

                  {/* END_USER_CODE-USER_BEFORE_btnUp2*/}

                  <ButtonWidget
                    conf={state.btnUp2}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnUp2*/}

                  {/* END_USER_CODE-USER_AFTER_btnUp2*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDown2*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDown2*/}

                  <ButtonWidget
                    conf={state.btnDown2}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDown2*/}

                  {/* END_USER_CODE-USER_AFTER_btnDown2*/}
                  {/* START_USER_CODE-USER_BEFORE_brnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_brnSearch*/}

                  <ButtonWidget
                    conf={state.brnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_brnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_brnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                  <ButtonWidget
                    conf={state.btnEdit}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                  {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                  {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrSearchActions*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrSearchActions*/}
                {/* START_USER_CODE-USER_BEFORE_gridOpenAccount*/}

                {/* END_USER_CODE-USER_BEFORE_gridOpenAccount*/}

                <GridWidget
                  conf={state.gridOpenAccount}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridOpenAccount}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAcctVendorName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_gridOpenAccount*/}

                {/* END_USER_CODE-USER_AFTER_gridOpenAccount*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxOpenAccountsSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxOpenAccountsSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_OpenAccountsSetup*/}

              {/* END_USER_CODE-USER_AFTER_OpenAccountsSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_OpenAccountsSetup
);
