/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  TextAreaWidget,
  DateWidget,
  setValue,
  hide,
  show,
  goTo,
  setData,
  getValue,
  getData,
  enable,
  disable,
  hideColumn,
  setFieldValues,
} from "../../shared/WindowImports";

import "./DelvAgreeProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import CommonContext from '../../Store/CommonContext';
import Loading from "../../../Loader/Loading";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_DelvAgreeProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const commonContext = useContext(CommonContext);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DelvAgreeProfile",
    windowName: "DelvAgreeProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.DelvAgreeProfile",
    // START_USER_CODE-USER_DelvAgreeProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Delivery Agreement Profile",
      scrCode: "PN1180B",
    },
    // END_USER_CODE-USER_DelvAgreeProfile_PROPERTIES
    btn0: {
      name: "btn0",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow0",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn0_PROPERTIES

      // END_USER_CODE-USER_btn0_PROPERTIES
    },
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow1",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btn10: {
      name: "btn10",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow10",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn10_PROPERTIES

      // END_USER_CODE-USER_btn10_PROPERTIES
    },
    btn11: {
      name: "btn11",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow11",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn11_PROPERTIES

      // END_USER_CODE-USER_btn11_PROPERTIES
    },
    btn12: {
      name: "btn12",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow12",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn12_PROPERTIES

      // END_USER_CODE-USER_btn12_PROPERTIES
    },
    btn13: {
      name: "btn13",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow13",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn13_PROPERTIES

      // END_USER_CODE-USER_btn13_PROPERTIES
    },
    btn14: {
      name: "btn14",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow14",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn14_PROPERTIES

      // END_USER_CODE-USER_btn14_PROPERTIES
    },
    btn15: {
      name: "btn15",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow15",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn15_PROPERTIES

      // END_USER_CODE-USER_btn15_PROPERTIES
    },
    btn16: {
      name: "btn16",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow16",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn16_PROPERTIES

      // END_USER_CODE-USER_btn16_PROPERTIES
    },
    btn17: {
      name: "btn17",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow17",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn17_PROPERTIES

      // END_USER_CODE-USER_btn17_PROPERTIES
    },
    btn18: {
      name: "btn18",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow18",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn18_PROPERTIES

      // END_USER_CODE-USER_btn18_PROPERTIES
    },
    btn19: {
      name: "btn19",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow19",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn19_PROPERTIES

      // END_USER_CODE-USER_btn19_PROPERTIES
    },
    btn2: {
      name: "btn2",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow2",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn2_PROPERTIES

      // END_USER_CODE-USER_btn2_PROPERTIES
    },
    btn3: {
      name: "btn3",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow3",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn3_PROPERTIES

      // END_USER_CODE-USER_btn3_PROPERTIES
    },
    btn4: {
      name: "btn4",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow4",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn5: {
      name: "btn5",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow5",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn5_PROPERTIES

      // END_USER_CODE-USER_btn5_PROPERTIES
    },
    btn6: {
      name: "btn6",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow6",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn6_PROPERTIES

      // END_USER_CODE-USER_btn6_PROPERTIES
    },
    btn7: {
      name: "btn7",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow7",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn7_PROPERTIES

      // END_USER_CODE-USER_btn7_PROPERTIES
    },
    btn8: {
      name: "btn8",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow8",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn8_PROPERTIES

      // END_USER_CODE-USER_btn8_PROPERTIES
    },
    btn9: {
      name: "btn9",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow9",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn9_PROPERTIES

      // END_USER_CODE-USER_btn9_PROPERTIES
    },
    btnAcctDist: {
      name: "btnAcctDist",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Acct Dist",
      CharWidth: "21",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAcctDist_PROPERTIES

      // END_USER_CODE-USER_btnAcctDist_PROPERTIES
    },
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btncancel: {
      name: "btncancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncancel_PROPERTIES

      // END_USER_CODE-USER_btncancel_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnPayments: {
      name: "btnPayments",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Payments",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPayments_PROPERTIES

      // END_USER_CODE-USER_btnPayments_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    chckBoxOverrideValue: {
      name: "chckBoxOverrideValue",
      type: "CheckBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Override Value",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chckBoxOverrideValue_PROPERTIES

      // END_USER_CODE-USER_chckBoxOverrideValue_PROPERTIES
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },
    col100Adjustment: {
      name: "col100Adjustment",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "100% Adjustment $",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col100Adjustment_PROPERTIES

      // END_USER_CODE-USER_col100Adjustment_PROPERTIES
    },
    col100Applied: {
      name: "col100Applied",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "100% Applied $",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col100Applied_PROPERTIES

      // END_USER_CODE-USER_col100Applied_PROPERTIES
    },
    colAppliedLbs: {
      name: "colAppliedLbs",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Applied Lbs.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAppliedLbs_PROPERTIES

      // END_USER_CODE-USER_colAppliedLbs_PROPERTIES
    },
    colConvApplied: {
      name: "colConvApplied",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Conv. Applied $",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colConvApplied_PROPERTIES

      // END_USER_CODE-USER_colConvApplied_PROPERTIES
    },
    colConvert: {
      name: "colConvert",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Convert",
      Height: "",
      Width: "",
    },
    colDelvInsp: {
      name: "colDelvInsp",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Delv Insp#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDelvInsp_PROPERTIES

      // END_USER_CODE-USER_colDelvInsp_PROPERTIES
    },
    colLocation: {
      name: "colLocation",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLocation_PROPERTIES

      // END_USER_CODE-USER_colLocation_PROPERTIES
    },
    colDelvSettle: {
      name: "colDelvSettle",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDelvSettle_PROPERTIES

      // END_USER_CODE-USER_colDelvSettle_PROPERTIES
    },
    colReceivedDate: {
      name: "colReceivedDate",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Received Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReceivedDate_PROPERTIES

      // END_USER_CODE-USER_colReceivedDate_PROPERTIES
    },
    colShippeddate: {
      name: "colShippeddate",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      Label: "Shipped Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShippeddate_PROPERTIES

      // END_USER_CODE-USER_colShippeddate_PROPERTIES
    },
    ddAgreementStatus: {
      name: "ddAgreementStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxDelieveryInOut",
      Label: "Agreement Status:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAgreementStatus_PROPERTIES

      // END_USER_CODE-USER_ddAgreementStatus_PROPERTIES
    },
    ddEdibleOil: {
      name: "ddEdibleOil",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "EdibleOil:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEdibleOil_PROPERTIES

      // END_USER_CODE-USER_ddEdibleOil_PROPERTIES
    },
    ddFirmBasic: {
      name: "ddFirmBasic",
      type: "DropDownFieldWidget",
      parent: "grpbxPricingInfo",
      Label: "Firm/Basic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String,d_null",
      // START_USER_CODE-USER_ddFirmBasic_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_ddFirmBasic_PROPERTIES
    },
    ddGeneration: {
      name: "ddGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGeneration_PROPERTIES

      // END_USER_CODE-USER_ddGeneration_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDelieveryInOut",
      Label: "Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String,d_null",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String,d_null",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddRemitTo0: {
      name: "ddRemitTo0",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow0",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo0_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo0_PROPERTIES
    },
    ddRemitTo1: {
      name: "ddRemitTo1",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow1",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo1_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo1_PROPERTIES
    },
    ddRemitTo10: {
      name: "ddRemitTo10",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow10",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo10_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo10_PROPERTIES
    },
    ddRemitTo11: {
      name: "ddRemitTo11",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow11",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo11_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo11_PROPERTIES
    },
    ddRemitTo12: {
      name: "ddRemitTo12",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow12",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo12_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo12_PROPERTIES
    },
    ddRemitTo13: {
      name: "ddRemitTo13",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow13",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo13_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo13_PROPERTIES
    },
    ddRemitTo14: {
      name: "ddRemitTo14",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow14",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo14_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo14_PROPERTIES
    },
    ddRemitTo15: {
      name: "ddRemitTo15",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow15",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo15_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo15_PROPERTIES
    },
    ddRemitTo16: {
      name: "ddRemitTo16",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow16",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo16_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo16_PROPERTIES
    },
    ddRemitTo17: {
      name: "ddRemitTo17",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow17",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo17_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo17_PROPERTIES
    },
    ddRemitTo18: {
      name: "ddRemitTo18",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow18",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo18_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo18_PROPERTIES
    },
    ddRemitTo19: {
      name: "ddRemitTo19",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow19",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo19_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo19_PROPERTIES
    },
    ddRemitTo2: {
      name: "ddRemitTo2",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo2_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo2_PROPERTIES
    },
    ddRemitTo3: {
      name: "ddRemitTo3",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow3",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo3_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo3_PROPERTIES
    },
    ddRemitTo4: {
      name: "ddRemitTo4",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow4",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo5: {
      name: "ddRemitTo5",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow5",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo5_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo5_PROPERTIES
    },
    ddRemitTo6: {
      name: "ddRemitTo6",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow6",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo6_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo6_PROPERTIES
    },
    ddRemitTo7: {
      name: "ddRemitTo7",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow7",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo7_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo7_PROPERTIES
    },
    ddRemitTo8: {
      name: "ddRemitTo8",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow8",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo8_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo8_PROPERTIES
    },
    ddRemitTo9: {
      name: "ddRemitTo9",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow9",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String,d_null",
      SpecialFirstOption: "",
      // START_USER_CODE-USER_ddRemitTo9_PROPERTIES
      optionMaxLength: 33,
      // END_USER_CODE-USER_ddRemitTo9_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Seg #:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    ddWhseReceipt: {
      name: "ddWhseReceipt",
      type: "DropDownFieldWidget",
      parent: "grpbxDelieveryInOut",
      Label: "Whse Receipt #:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String,d_null",
      // START_USER_CODE-USER_ddWhseReceipt_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_ddWhseReceipt_PROPERTIES
    },
    gridApplicationInfo: {
      name: "gridApplicationInfo",
      type: "GridWidget",
      parent: "grpbxDelvAgree",
      gridCellsOrder:
        "btnCol,txtcolLocation,txtcolDelvInsp,txtcolDelvSettle,txtcolShippedDate,txtcolReceivedDate,txtcolAppliedLbs,txtcol100Applied,txtcolConvApplied,txtcol100Adjustment,txtcolConvert",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridApplicationInfo_PROPERTIES
      clientPagination: true,
      DisplaySize: 10,
      // END_USER_CODE-USER_gridApplicationInfo_PROPERTIES
    },
    grpbxDelieveryInOut: {
      name: "grpbxDelieveryInOut",
      type: "GroupBoxWidget",
      parent: "grpbxDelvAgree",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDelieveryInOut_PROPERTIES

      // END_USER_CODE-USER_grpbxDelieveryInOut_PROPERTIES
    },
    grpbxLeft: {
      name: "grpbxLeft",
      type: "GroupBoxWidget",
      parent: "grpbxDelvAgree",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxLeft_PROPERTIES

      // END_USER_CODE-USER_grpbxLeft_PROPERTIES
    },
    grpbxRight: {
      name: "grpbxRight",
      type: "GroupBoxWidget",
      parent: "grpbxDelvAgree",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxRight_PROPERTIES

      // END_USER_CODE-USER_grpbxRight_PROPERTIES
    },
    grpbxLabels: {
      name: "grpbxLabels",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLabels_PROPERTIES

      // END_USER_CODE-USER_grpbxLabels_PROPERTIES
    },
    grpbxPeanutInfo: {
      name: "grpbxPeanutInfo",
      type: "GroupBoxWidget",
      parent: "grpbxDelvAgree",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPeanutInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxPeanutInfo_PROPERTIES
    },
    grpbxPricingInfo: {
      name: "grpbxPricingInfo",
      type: "GroupBoxWidget",
      parent: "grpbxDelvAgree",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPricingInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxPricingInfo_PROPERTIES
    },
    grpbxVendorSplitRow0: {
      name: "grpbxVendorSplitRow0",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      clonedExtId: "59875",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow1: {
      name: "grpbxVendorSplitRow1",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow1_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow1_PROPERTIES
    },
    grpbxVendorSplitRow10: {
      name: "grpbxVendorSplitRow10",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow10_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow10_PROPERTIES
    },
    grpbxVendorSplitRow11: {
      name: "grpbxVendorSplitRow11",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow11_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow11_PROPERTIES
    },
    grpbxVendorSplitRow12: {
      name: "grpbxVendorSplitRow12",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow12_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow12_PROPERTIES
    },
    grpbxVendorSplitRow13: {
      name: "grpbxVendorSplitRow13",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow13_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow13_PROPERTIES
    },
    grpbxVendorSplitRow14: {
      name: "grpbxVendorSplitRow14",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow14_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow14_PROPERTIES
    },
    grpbxVendorSplitRow15: {
      name: "grpbxVendorSplitRow15",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow15_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow15_PROPERTIES
    },
    grpbxVendorSplitRow16: {
      name: "grpbxVendorSplitRow16",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow16_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow16_PROPERTIES
    },
    grpbxVendorSplitRow17: {
      name: "grpbxVendorSplitRow17",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow17_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow17_PROPERTIES
    },
    grpbxVendorSplitRow18: {
      name: "grpbxVendorSplitRow18",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow18_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow18_PROPERTIES
    },
    grpbxVendorSplitRow19: {
      name: "grpbxVendorSplitRow19",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow19_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow19_PROPERTIES
    },
    grpbxVendorSplitRow2: {
      name: "grpbxVendorSplitRow2",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow2_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow2_PROPERTIES
    },
    grpbxVendorSplitRow3: {
      name: "grpbxVendorSplitRow3",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow3_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow3_PROPERTIES
    },
    grpbxVendorSplitRow4: {
      name: "grpbxVendorSplitRow4",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow4_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow4_PROPERTIES
    },
    grpbxVendorSplitRow5: {
      name: "grpbxVendorSplitRow5",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow5_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow5_PROPERTIES
    },
    grpbxVendorSplitRow6: {
      name: "grpbxVendorSplitRow6",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow6u_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow6u_PROPERTIES
    },
    grpbxVendorSplitRow7: {
      name: "grpbxVendorSplitRow7",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow7_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow7_PROPERTIES
    },
    grpbxVendorSplitRow8: {
      name: "grpbxVendorSplitRow8",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow8_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow8_PROPERTIES
    },
    grpbxVendorSplitRow9: {
      name: "grpbxVendorSplitRow9",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitsRows",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow9_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow9_PROPERTIES
    },
    grpbxVendorSplits: {
      name: "grpbxVendorSplits",
      type: "GroupBoxWidget",
      parent: "grpbxDelvAgree",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxVendorSplits_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplits_PROPERTIES
    },
    grpbxVendorSplitsRows: {
      name: "grpbxVendorSplitsRows",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "4",
      Height: "",
      Width: "",
      clonedExtId: "28454",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitsRows_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitsRows_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblApplicationInfo: {
      name: "lblApplicationInfo",
      type: "LabelWidget",
      parent: "grpbxDelvAgree",
      Label: "Application Information",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblApplicationInfo_PROPERTIES

      // END_USER_CODE-USER_lblApplicationInfo_PROPERTIES
    },
    lblNote: {
      name: "lblNote",
      type: "LabelWidget",
      parent: "grpbxLeft",
      Label: "Delivery Agreement Status must be changed to 'Complete' to create RTPs for this Vendor.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblApplicationInfo_PROPERTIES

      // END_USER_CODE-USER_lblApplicationInfo_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblCropYear: {
      name: "lblCropYear",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Crop Year:",
      ColSpan: "6",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear_PROPERTIES

      // END_USER_CODE-USER_lblCropYear_PROPERTIES
    },
    lblPeanutInfo: {
      name: "lblPeanutInfo",
      type: "LabelWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Information",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPeanutInfo_PROPERTIES

      // END_USER_CODE-USER_lblPeanutInfo_PROPERTIES
    },
    lblPricingInfo: {
      name: "lblPricingInfo",
      type: "LabelWidget",
      parent: "grpbxPricingInfo",
      Label: "Pricing Information",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPricingInfo_PROPERTIES

      // END_USER_CODE-USER_lblPricingInfo_PROPERTIES
    },
    lblRemitTo: {
      name: "lblRemitTo",
      type: "LabelWidget",
      parent: "grpbxLabels",
      Label: "Remit To",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo_PROPERTIES
    },
    lblSharePercentage: {
      name: "lblSharePercentage",
      type: "LabelWidget",
      parent: "grpbxLabels",
      Label: "Share %",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSharePercentage_PROPERTIES

      // END_USER_CODE-USER_lblSharePercentage_PROPERTIES
    },
    lblVendor: {
      name: "lblVendor",
      type: "LabelWidget",
      parent: "grpbxLabels",
      Label: "Vendor",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor_PROPERTIES

      // END_USER_CODE-USER_lblVendor_PROPERTIES
    },
    lblVendorSplits: {
      name: "lblVendorSplits",
      type: "LabelWidget",
      parent: "grpbxLabels",
      Label: "Vendor Splits",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendorSplits_PROPERTIES

      // END_USER_CODE-USER_lblVendorSplits_PROPERTIES
    },
    lblXX0: {
      name: "lblXX0",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow0",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX0_PROPERTIES

      // END_USER_CODE-USER_lblXX0_PROPERTIES
    },
    lblXX1: {
      name: "lblXX1",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow1",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX10: {
      name: "lblXX10",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow10",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX10_PROPERTIES

      // END_USER_CODE-USER_lblXX10_PROPERTIES
    },
    lblXX11: {
      name: "lblXX11",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow11",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX11_PROPERTIES

      // END_USER_CODE-USER_lblXX11_PROPERTIES
    },
    lblXX12: {
      name: "lblXX12",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow12",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX12_PROPERTIES

      // END_USER_CODE-USER_lblXX12_PROPERTIES
    },
    lblXX13: {
      name: "lblXX13",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow13",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX13_PROPERTIES

      // END_USER_CODE-USER_lblXX13_PROPERTIES
    },
    lblXX14: {
      name: "lblXX14",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow14",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX14_PROPERTIES

      // END_USER_CODE-USER_lblXX14_PROPERTIES
    },
    lblXX15: {
      name: "lblXX15",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow15",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX15_PROPERTIES

      // END_USER_CODE-USER_lblXX15_PROPERTIES
    },
    lblXX16: {
      name: "lblXX16",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow16",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX16_PROPERTIES

      // END_USER_CODE-USER_lblXX16_PROPERTIES
    },
    lblXX17: {
      name: "lblXX17",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow17",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX17_PROPERTIES

      // END_USER_CODE-USER_lblXX17_PROPERTIES
    },
    lblXX18: {
      name: "lblXX18",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow18",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX18_PROPERTIES

      // END_USER_CODE-USER_lblXX18_PROPERTIES
    },
    lblXX19: {
      name: "lblXX19",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow19",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX19_PROPERTIES

      // END_USER_CODE-USER_lblXX19_PROPERTIES
    },
    lblXX2: {
      name: "lblXX2",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow2",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX2_PROPERTIES

      // END_USER_CODE-USER_lblXX2_PROPERTIES
    },
    lblXX3: {
      name: "lblXX3",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow3",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX3_PROPERTIES

      // END_USER_CODE-USER_lblXX3_PROPERTIES
    },
    lblXX4: {
      name: "lblXX4",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow4",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX4_PROPERTIES

      // END_USER_CODE-USER_lblXX4_PROPERTIES
    },
    lblXX5: {
      name: "lblXX5",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow5",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX5_PROPERTIES

      // END_USER_CODE-USER_lblXX5_PROPERTIES
    },
    lblXX6: {
      name: "lblXX6",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow6",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX6_PROPERTIES

      // END_USER_CODE-USER_lblXX6_PROPERTIES
    },
    lblXX7: {
      name: "lblXX7",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow7",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX7_PROPERTIES

      // END_USER_CODE-USER_lblXX7_PROPERTIES
    },
    lblXX8: {
      name: "lblXX8",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow8",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX8_PROPERTIES

      // END_USER_CODE-USER_lblXX8_PROPERTIES
    },
    lblXX9: {
      name: "lblXX9",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow9",
      Label: "Xxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX9_PROPERTIES

      // END_USER_CODE-USER_lblXX9_PROPERTIES
    },
    radioDeliveryINout: {
      name: "radioDeliveryINout",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxDelieveryInOut",
      Options: "Delivery Out:1,Delivery In:2",
      ColSpan: "2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioDeliveryINout_PROPERTIES

      // END_USER_CODE-USER_radioDeliveryINout_PROPERTIES
    },
    txt100Adjust: {
      name: "txt100Adjust",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "100% Adjust.$:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt100Adjust_PROPERTIES

      // END_USER_CODE-USER_txt100Adjust_PROPERTIES
    },
    txt100AdjustLbs: {
      name: "txt100AdjustLbs",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "100% Adjust Lbs:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt100AdjustLbs_PROPERTIES

      // END_USER_CODE-USER_txt100AdjustLbs_PROPERTIES
    },
    txt100open: {
      name: "txt100open",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "100% Open $:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt100open_PROPERTIES

      // END_USER_CODE-USER_txt100open_PROPERTIES
    },
    txt100PctApplied: {
      name: "txt100PctApplied",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "100% Applied:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt100PctApplied_PROPERTIES

      // END_USER_CODE-USER_txt100PctApplied_PROPERTIES
    },
    txtAdjustmentLbs: {
      name: "txtAdjustmentLbs",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Adjustment Lbs:",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAdjustmentLbs_PROPERTIES

      // END_USER_CODE-USER_txtAdjustmentLbs_PROPERTIES
    },
    txtAgreeDate: {
      name: "txtAgreeDate",
      type: "DateWidget",
      parent: "grpbxDelieveryInOut",
      Label: "Agreement Date:",
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtAgreeDate_PROPERTIES

      // END_USER_CODE-USER_txtAgreeDate_PROPERTIES
    },
    txtAgreement: {
      name: "txtAgreement",
      type: "TextBoxWidget",
      parent: "grpbxDelieveryInOut",
      Label: "Agreement #:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAgreement_PROPERTIES

      // END_USER_CODE-USER_txtAgreement_PROPERTIES
    },
    txtAgreementDate: {
      name: "txtAgreementDate",
      type: "DateTimeWidget",
      parent: "grpbxDelieveryInOut",
      Label: "Agreement Date:",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtAgreementDate_PROPERTIES

      // END_USER_CODE-USER_txtAgreementDate_PROPERTIES
    },
    txtAppliedLbs: {
      name: "txtAppliedLbs",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Applied Lbs:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAppliedLbs_PROPERTIES

      // END_USER_CODE-USER_txtAppliedLbs_PROPERTIES
    },
    txtAreaComments: {
      name: "txtAreaComments",
      type: "TextAreaWidget",
      parent: "grpbxDelvAgree",
      Label: "Comments:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAreaComments_PROPERTIES

      // END_USER_CODE-USER_txtAreaComments_PROPERTIES
    },
    colButton: {
      name: "colButton",
      type: "GridColumnWidget",
      parent: "gridApplicationInfo",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colButton_PROPERTIES

      // END_USER_CODE-USER_colButton_PROPERTIES
    },
    btnCol: {
      name: "btnCol",
      type: "ButtonWidget",
      colName: "colButton",
      parent: "gridApplicationInfo",
      CharWidth: "15",
      //Height: "",
      //Width: "",
      //HasLabel: false,
      Label: "",
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_txtcol1_PROPERTIES

      // END_USER_CODE-USER_txtcol1_PROPERTIES
    },
    txtcol100Adjustment: {
      name: "txtcol100Adjustment",
      type: "TextBoxWidget",
      colName: "col100Adjustment",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol100Adjustment_PROPERTIES

      // END_USER_CODE-USER_txtcol100Adjustment_PROPERTIES
    },
    txtcol100Applied: {
      name: "txtcol100Applied",
      type: "TextBoxWidget",
      colName: "col100Applied",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol100Applied_PROPERTIES

      // END_USER_CODE-USER_txtcol100Applied_PROPERTIES
    },
    txtcolAppliedLbs: {
      name: "txtcolAppliedLbs",
      type: "TextBoxWidget",
      colName: "colAppliedLbs",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAppliedLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolAppliedLbs_PROPERTIES
    },
    txtcolConvApplied: {
      name: "txtcolConvApplied",
      type: "TextBoxWidget",
      colName: "colConvApplied",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolConvApplied_PROPERTIES

      // END_USER_CODE-USER_txtcolConvApplied_PROPERTIES
    },
    txtcolConvert: {
      name: "txtcolConvert",
      type: "TextBoxWidget",
      colName: "colConvert",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolDelvInsp: {
      name: "txtcolDelvInsp",
      type: "TextBoxWidget",
      colName: "colDelvInsp",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDelvInsp_PROPERTIES

      // END_USER_CODE-USER_txtcolDelvInsp_PROPERTIES
    },
    txtcolDelvSettle: {
      name: "txtcolDelvSettle",
      type: "TextBoxWidget",
      colName: "colDelvSettle",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDelvSettle_PROPERTIES

      // END_USER_CODE-USER_txtcolDelvSettle_PROPERTIES
    },

    txtcolLocation: {
      name: "txtcolLocation",
      type: "TextBoxWidget",
      colName: "colLocation",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLocation_PROPERTIES

      // END_USER_CODE-USER_txtcolLocation_PROPERTIES
    },
    txtcolReceivedDate: {
      name: "txtcolReceivedDate",
      type: "TextBoxWidget",
      colName: "colReceivedDate",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolReceivedDate_PROPERTIES

      // END_USER_CODE-USER_txtcolReceivedDate_PROPERTIES
    },
    txtcolShippedDate: {
      name: "txtcolShippedDate",
      type: "TextBoxWidget",
      colName: "colShippeddate",
      parent: "gridApplicationInfo",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShippedDate_PROPERTIES

      // END_USER_CODE-USER_txtcolShippedDate_PROPERTIES
    },
    txtConvApplied: {
      name: "txtConvApplied",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Conv. Applied $:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConvApplied_PROPERTIES

      // END_USER_CODE-USER_txtConvApplied_PROPERTIES
    },
    txtConversion: {
      name: "txtConversion",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Conversion %:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConversion_PROPERTIES

      // END_USER_CODE-USER_txtConversion_PROPERTIES
    },
    txtConvopen: {
      name: "txtConvopen",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Conv. Open$:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConvopen_PROPERTIES

      // END_USER_CODE-USER_txtConvopen_PROPERTIES
    },
    txtOpenLbs: {
      name: "txtOpenLbs",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Open Lbs:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOpenLbs_PROPERTIES

      // END_USER_CODE-USER_txtOpenLbs_PROPERTIES
    },
    txtpaidDollars: {
      name: "txtpaidDollars",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Paid Dollars:",
      LengthRange: { MinLength: 0, MaxLength: 12 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtpaidDollars_PROPERTIES

      // END_USER_CODE-USER_txtpaidDollars_PROPERTIES
    },
    txtPricePerTon: {
      name: "txtPricePerTon",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Price ($ per ton):",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPricePerTon_PROPERTIES

      // END_USER_CODE-USER_txtPricePerTon_PROPERTIES
    },
    txtReceiptLbs: {
      name: "txtReceiptLbs",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Receipt Lbs:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReceiptLbs_PROPERTIES

      // END_USER_CODE-USER_txtReceiptLbs_PROPERTIES
    },
    txtReceiptValue: {
      name: "txtReceiptValue",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Receipt Value:",
      LengthRange: { MinLength: 0, MaxLength: 12 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReceiptValue_PROPERTIES

      // END_USER_CODE-USER_txtReceiptValue_PROPERTIES
    },
    txtSharePercentage0: {
      name: "txtSharePercentage0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage0_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage0_PROPERTIES
    },
    txtSharePercentage1: {
      name: "txtSharePercentage1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage10: {
      name: "txtSharePercentage10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage10_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage10_PROPERTIES
    },
    txtSharePercentage11: {
      name: "txtSharePercentage11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage11_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage11_PROPERTIES
    },
    txtSharePercentage12: {
      name: "txtSharePercentage12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage12_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage12_PROPERTIES
    },
    txtSharePercentage13: {
      name: "txtSharePercentage13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage13_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage13_PROPERTIES
    },
    txtSharePercentage14: {
      name: "txtSharePercentage14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage14_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage14_PROPERTIES
    },
    txtSharePercentage15: {
      name: "txtSharePercentage15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage15_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage15_PROPERTIES
    },
    txtSharePercentage16: {
      name: "txtSharePercentage16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage16_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage16_PROPERTIES
    },
    txtSharePercentage17: {
      name: "txtSharePercentage17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage17_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage17_PROPERTIES
    },
    txtSharePercentage18: {
      name: "txtSharePercentage18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage18_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage18_PROPERTIES
    },
    txtSharePercentage19: {
      name: "txtSharePercentage19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage19_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage19_PROPERTIES
    },
    txtSharePercentage2: {
      name: "txtSharePercentage2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage2_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage2_PROPERTIES
    },
    txtSharePercentage3: {
      name: "txtSharePercentage3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage3_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage3_PROPERTIES
    },
    txtSharePercentage4: {
      name: "txtSharePercentage4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage4_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage4_PROPERTIES
    },
    txtSharePercentage5: {
      name: "txtSharePercentage5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage5_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage5_PROPERTIES
    },
    txtSharePercentage6: {
      name: "txtSharePercentage6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage6_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage6_PROPERTIES
    },
    txtSharePercentage7: {
      name: "txtSharePercentage7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage7_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage7_PROPERTIES
    },
    txtSharePercentage8: {
      name: "txtSharePercentage8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage8_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage8_PROPERTIES
    },
    txtSharePercentage9: {
      name: "txtSharePercentage9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage9_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage9_PROPERTIES
    },
    txtTotalAdjustLbs: {
      name: "txtTotalAdjustLbs",
      type: "TextBoxWidget",
      parent: "grpbxPricingInfo",
      Label: "Total Adjust Lbs:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalAdjustLbs_PROPERTIES

      // END_USER_CODE-USER_txtTotalAdjustLbs_PROPERTIES
    },
    txtVendor0: {
      name: "txtVendor0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor0_PROPERTIES

      // END_USER_CODE-USER_txtVendor0_PROPERTIES
    },
    txtVendor1: {
      name: "txtVendor1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor10: {
      name: "txtVendor10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor10_PROPERTIES

      // END_USER_CODE-USER_txtVendor10_PROPERTIES
    },
    txtVendor11: {
      name: "txtVendor11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor11_PROPERTIES

      // END_USER_CODE-USER_txtVendor11_PROPERTIES
    },
    txtVendor12: {
      name: "txtVendor12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor12_PROPERTIES

      // END_USER_CODE-USER_txtVendor12_PROPERTIES
    },
    txtVendor13: {
      name: "txtVendor13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor13_PROPERTIES

      // END_USER_CODE-USER_txtVendor13_PROPERTIES
    },
    txtVendor14: {
      name: "txtVendor14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor14_PROPERTIES

      // END_USER_CODE-USER_txtVendor14_PROPERTIES
    },
    txtVendor15: {
      name: "txtVendor15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor15_PROPERTIES

      // END_USER_CODE-USER_txtVendor15_PROPERTIES
    },
    txtVendor16: {
      name: "txtVendor16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor16_PROPERTIES

      // END_USER_CODE-USER_txtVendor16_PROPERTIES
    },
    txtVendor17: {
      name: "txtVendor17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor17_PROPERTIES

      // END_USER_CODE-USER_txtVendor17_PROPERTIES
    },
    txtVendor18: {
      name: "txtVendor18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor18_PROPERTIES

      // END_USER_CODE-USER_txtVendor18_PROPERTIES
    },
    txtVendor19: {
      name: "txtVendor19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor19_PROPERTIES

      // END_USER_CODE-USER_txtVendor19_PROPERTIES
    },
    txtVendor2: {
      name: "txtVendor2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor2_PROPERTIES

      // END_USER_CODE-USER_txtVendor2_PROPERTIES
    },
    txtVendor3: {
      name: "txtVendor3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor3_PROPERTIES

      // END_USER_CODE-USER_txtVendor3_PROPERTIES
    },
    txtVendor4: {
      name: "txtVendor4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor4_PROPERTIES

      // END_USER_CODE-USER_txtVendor4_PROPERTIES
    },
    txtVendor5: {
      name: "txtVendor5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor5_PROPERTIES

      // END_USER_CODE-USER_txtVendor5_PROPERTIES
    },
    txtVendor6: {
      name: "txtVendor6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor6_PROPERTIES

      // END_USER_CODE-USER_txtVendor6_PROPERTIES
    },
    txtVendor7: {
      name: "txtVendor7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor7_PROPERTIES

      // END_USER_CODE-USER_txtVendor7_PROPERTIES
    },
    txtVendor8: {
      name: "txtVendor8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor8_PROPERTIES

      // END_USER_CODE-USER_txtVendor8_PROPERTIES
    },
    txtVendor9: {
      name: "txtVendor9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor9_PROPERTIES

      // END_USER_CODE-USER_txtVendor9_PROPERTIES
    },
    grpbxDelvAgree: {
      name: "grpbxDelvAgree",
      type: "GroupBoxWidget",
      parent: "DelvAgreeProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDelvAgree_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvAgree_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "DelvAgreeProfile",
      Height: "",
      Width: "",
      ColsForMobile: "8",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "WarehouseReceipts#DelvSettlePaymentView": {},
      },
      REVERSE: {
        "WarehouseReceipts#DelvSettlePaymentView": {},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnPayments: {
      DEFAULT: ["WarehouseReceipts#DelvSettlePaymentView#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);

  const [loading, setLoading] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    setTabIndex();
  },[]);
  useEffect(() => {
    let afterFormLoad = getData(thisObj, "FormLoadComplete")

    if (afterFormLoad) {

      let vendor_lookup = getData(thisObj, 'vendorDetails')
      if (vendor_lookup !== null) {
        setFormDirty(true);

        let indexNumber = getData(thisObj, "VendorIndex_delvAgreeProfile")
        setValue(thisObj, ["txtVendor" + indexNumber], vendor_lookup.VendorNumber);
        setValue(thisObj, ["lblXX" + indexNumber], toPascalCase(vendor_lookup.key));

        bFillRemitToList(indexNumber, vendor_lookup.VendorNumber);
      }

      setData(thisObj, "FormLoadComplete", false)
    }
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    //returning data to DelvAgreeSearch
    let loadvalue = getData(thisObj, "lbl_firstload")
    if (loadvalue !== 'D' && loadvalue !== 'F') {
      setData(thisObj, "lbl_firstload", 'F')
    }
    setData(thisObj, "closingofDelvAgreeProfile", true);

  }, [getData(thisObj, "lbl_firstload")])

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    //loadAgreement()
    formLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  //function blocks

  async function loadAgreement() {
    try {

      // let obj = {
      //   AgreementNum: "",//value , keep it "", if add mode
      //   mode: "" //("Add" or "Update")
      // }
      // setData(thisObj, "dataForDelvAgreeProfile", obj)

      let dataFromParent = getData(thisObj, "dataForDelvAgreeProfile");
      if (dataFromParent.mode == 'Update') {
        enable(thisObj, "ddLocation");
        setValue(thisObj, "txtAgreement", dataFromParent?.AgreementNum);
        //setValue(thisObj, "ddLocation", dataFromParent?.buy_pt_id);
        setData(thisObj, "txtAgreement_DelvAgreeProfile", dataFromParent?.AgreementNum);
        //setData(thisObj, "ddLocation_DelvAgreeProfile", dataFromParent?.buy_pt_id);
        document.getElementById("btnAdd").innerText = "Update";
        setFormDirty(false);
      }


    } catch (error) {
      errorHandler(error)
    }
  }

  async function formLoad() {
    try {
      //console.time("myFunction");
      setLoading(true);

      const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
      setValue(thisObj, "lblCropYear", cropYearFromLS())

      setValue(thisObj, "lblAddedByValue", "")
      setValue(thisObj, "lblChangedByValue", "")

      //binding cboAgreeStatus dropdown, (this dropdown binding is not available in old code)
      bFillAgreeStatus();


      //clear vendorSplit
      clearVendorSplit();

      //varibles for page
      let obj = {}
      obj.sPermission = "";
      obj.Agree_total = 0;
      obj.status_flag = "";
      obj.mdblRcptLbs = 0;
      obj.mdblRcptValueOverride = 0;
      obj.mdblRcptValue = 0;
      obj.mbCalcing = false;
      setData(thisObj, "DelvAgreeprofile_PageData", obj);
      setData(thisObj, "gridApplicationInfo", [])

      //setting values on page
      setValue(thisObj, "txtpaidDollars", "");
      setValue(thisObj, "txtConvApplied", "");
      setValue(thisObj, "txtConvopen", "");

      setValue(thisObj, "radioDeliveryINout", "1");

      hideColumn(thisObj, "gridApplicationInfo", "txtcolDelvSettle")
      hideColumn(thisObj, "gridApplicationInfo", "txtcolConvert")

      //dropdown binding

      let generationValue = await bFillGeneration();
      let typeValue = await bFillTypeList();
      let peanutTypevalue = await bFillPeanutTypeList();
      let buyingPtValue = await bFillBuyingPointList();
      let edibleOilValue = await bFillEdibleOilList();
      let segmentValue = await bFillSegmentList();
      let oleicValue = await bFillOleicList();

      if (!(generationValue && typeValue && peanutTypevalue && buyingPtValue && edibleOilValue && segmentValue && oleicValue)) {
        showMessage("dropdown binding failed");
        setLoading(false);
        document.getElementById("WarehouseReceipts_DelvAgreeProfilePopUp").childNodes[0].click();
      }

      setFormDirty(false);

      loadAgreement()



      //setData(thisObj, "FormLoadComplete", true)

      let dataFromParent = getData(thisObj, "dataForDelvAgreeProfile");
      if (dataFromParent.mode == 'Update') {
        await bFillForm();
      } else {
        await EnableDisableControls()  //add mode, not data is there to pass
        setLoading(false);
      }
      //console.timeEnd("myFunction");
    } catch (error) {
      errorHandler(error)
    }
  }

  async function bFillAgreeStatus() {
    try {
      let js = [
        { key: 'OPEN', description: 'Open' },
        { key: 'COMPLETE', description: 'Complete' },
      ];

      thisObj.setState(current => {
        return {
          ...current,
          ddAgreementStatus: {
            ...state["ddAgreementStatus"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddAgreementStatus', js.at(0).key);
      return true
    }
    catch (err) {
      errorHandler(err);
      return false;
    }
  }

  async function bFillBuyingPointList() {
    try {
      let js = [{ key: "", description: ">>> All Buying Points <<<" }]

      let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1180', null, null, null, null)
      if (response !== undefined && response !== null && response.length !== 0) {
        for (var i = 0; i < response.length; i++) {
          js.push({
            key: response[i].buy_pt_id,
            description: response[i].buy_pt_id + ' - ' + toPascalCase(response[i].buy_pt_name)
          })
        }
      }

      if (js.length == 2) {
        js = js.filter(item => item.key !== "");
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddLocation', js.at(0).key);
      return true;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  }

  async function bFillGeneration() {
    try {
      let js = [
        { key: '', description: '' },
        { key: 'F', description: 'Foundation' },
        { key: 'R', description: 'Registered' },
        { key: 'C', description: 'Certified' }
      ];

      thisObj.setState(current => {
        return {
          ...current,
          ddGeneration: {
            ...state["ddGeneration"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddGeneration', js.at(0).key);
      return true
    }
    catch (err) {
      errorHandler(err);
      return false;
    }
  }

  async function bFillTypeList() {
    try {
      let js = [
        { key: 'F', description: 'Firm' },
        { key: 'B', description: 'Basis' }
      ];

      thisObj.setState(current => {
        return {
          ...current,
          ddFirmBasic: {
            ...state["ddFirmBasic"],
            valueList: js
          }
        }
      })
      return true
    } catch (error) {
      errorHandler(error);
      return false;
    }
  }

  async function bFillOleicList() {
    try {
      let js = [
        { key: 'H', description: 'High' },
        { key: 'M', description: 'Mid' },
        { key: '', description: 'None' }
      ];

      thisObj.setState(current => {
        return {
          ...current,
          ddOleic: {
            ...state["ddOleic"],
            valueList: js
          }
        }
      })
      setValue(thisObj, "ddOleic", js.at(2).key)
      return true
    } catch (error) {
      errorHandler(error);
      return false;
    }
  }

  async function bFillSegmentList() {
    try {
      let js = [
        { key: '1', description: '1' },
        { key: '2', description: '2' },
        { key: '3', description: '3' }
      ];

      thisObj.setState(current => {
        return {
          ...current,
          ddSeg: {
            ...state["ddSeg"],
            valueList: js
          }
        }
      })
      setValue(thisObj, "ddSeg", js.at(0).key)
      setData(thisObj, "delvAgreeprofile_ddSeg", js.at(0).key)
      return true
    } catch (error) {
      errorHandler(error);
      return false;
    }
  }

  async function bFillEdibleOilList() {
    try {
      let js = [
        { key: 'E', description: 'Edible' },
        { key: 'O', description: 'Oil' }
      ];

      thisObj.setState(current => {
        return {
          ...current,
          ddEdibleOil: {
            ...state["ddEdibleOil"],
            valueList: js
          }
        }
      })

      let ddSeg = getValue(thisObj, "ddSeg")
      ddSeg = ddSeg == undefined ? getData(thisObj, "delvAgreeprofile_ddSeg") : ddSeg;

      if (ddSeg !== undefined) {
        if (ddSeg == '1') {
          setValue(thisObj, "ddEdibleOil", js.at(0).key)
        } else {
          setValue(thisObj, "ddEdibleOil", js.at(1).key)
        }
      } else {
        setValue(thisObj, "ddEdibleOil", js.at(1).key)
      }

      return true
    } catch (error) {
      errorHandler(error);
      return false;
    }
  }

  async function bFillPeanutTypeList() {
    try {
      let js = [];

      let LstrTypeList = await ContractManagementService.RetrievePeanutTypeControls(null)
      if (LstrTypeList.length <= 0)
        return null;
      for (var i = 0; i < LstrTypeList.length; i++) {
        js.push({
          key: LstrTypeList[i].pnutTypeId,
          description: LstrTypeList[i].pnutTypeName
        })
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js
          }
        }
      })
      //setValue(thisObj, 'ddPeanutType', js.at(0).key);
      //return js.at(0).key;
      return true;
    }
    catch (err) {
      errorHandler(err);
      return null;
    }
  }

  async function bFillPeanutVarietyList(buyingPt_parameter = thisObj.values['ddLocation'], peanut_type_parameter = thisObj.values['ddPeanutType']) {
    try {
      setLoading(true);
      let js = [];
      js.push({ key: '', description: 'All Varieties' })

      let buyingPt = buyingPt_parameter
      let peanut_type = peanut_type_parameter

      if (peanut_type !== null && peanut_type !== "" && peanut_type !== undefined) {
        let response = await ContractManagementService.RetrievePeanutVarietyControls(buyingPt, null, null, peanut_type, null)
        if (response === undefined || response == null || response.length === 0) {
          thisObj.setState(current => {
            return {
              ...current,
              ddPeanutVariety: {
                ...state["ddPeanutVariety"],
                valueList: js
              }
            }
          })
          setLoading(false);
          return false;
        } else {
          let data = response;
          let count = 0;
          data.forEach(element => {
            count++;
            let newVar = true;
            for (let i = 0; i < count - 1; i++) {
              if ((element.pnut_variety_id).toUpperCase() === (data[i].pnut_variety_id).toUpperCase()) {
                newVar = false;
                break;
              }
            }
            let symbol;
            if (newVar) {
              switch (element.symbol_ind.trim()) {
                case "TRADEMARK":
                  symbol = " \u2122"
                  break;
                case "REGISTERED TRADEMARK":
                  symbol = " \xAE"
                  break;
                case "COPYRIGHT":
                  symbol = " \xA9"
                  break;
                default:
                  symbol = "";
              }
              let obj = { key: element.pnut_variety_id, description: element.pnut_variety_name + symbol }
              js.push(obj)
            }
          });
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutVariety: {
            ...state["ddPeanutVariety"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddPeanutVariety', js[0].key);
      setLoading(false);
      return true;
    } catch (error) {
      errorHandler(error)
      return false;
    }
  }

  async function EnableDisableControls(setFieldValueData) {
    try {
      let obj = getData(thisObj, "DelvAgreeprofile_PageData");
      let buy_pt_id = setFieldValueData !== undefined && setFieldValueData.ddLocation !== undefined ? setFieldValueData.ddLocation : getValue(thisObj, "ddLocation")  //check if need to pass through props

      if (await GetAccessLevel(buy_pt_id, null, "D")) {
        obj.sPermission = "D"
      } else if (await GetAccessLevel(buy_pt_id, null, "U")) {
        obj.sPermission = "U"
      } else if (await GetAccessLevel(buy_pt_id, null, "I")) {
        obj.sPermission = "I"
      } else {
        showMessage(thisObj, "Invalid Permissions")
        setLoading(false)
        document.getElementById("WarehouseReceipts_DelvAgreeProfilePopUp").childNodes[0].click();
        return;
      }

      let agree_status = setFieldValueData !== undefined && setFieldValueData.ddAgreementStatus !== undefined ? setFieldValueData.ddAgreementStatus : (getValue(thisObj, "ddAgreementStatus") == undefined ? "OPEN" : getValue(thisObj, "ddAgreementStatus"));

      let widgetType = ["TextBoxWidget", "DropDownWidget", "DropDownFieldWidget", "ButtonWidget", "RadioButtonGroupWidget", "TextAreaWidget", "CheckboxWidget", "DateWidget"];

      if (agree_status == "OPEN") {

        for (let key in thisObj.state) {
          if (typeof thisObj.state[key] === 'object' && thisObj.state[key] !== null) {
            // Check if the current property is an object
            if ('type' in thisObj.state[key] &&
              (widgetType.includes(thisObj.state[key]['type']))
              && 'Enabled' in thisObj.state[key]) {
              // Check if the object has a 'Enabled' property
              //thisObj[key].Enabled = false;  // this line will not work to update state
              enable(thisObj, key)
            }
          }
        }

        disable(thisObj, "btnDelete");
        disable(thisObj, "txtAgreement");
        EnableDisableBySeg()
      }

      let checkBoxValue = setFieldValueData !== undefined && setFieldValueData.chckBoxOverrideValue !== undefined ? setFieldValueData.chckBoxOverrideValue : getValue(thisObj, "chckBoxOverrideValue")
      if (checkBoxValue) {
        enable(thisObj, "txtReceiptValue")
      } else {
        disable(thisObj, "txtReceiptValue")
      }

      disable(thisObj, "txtAgreement");
      let gridData = setFieldValueData !== undefined && setFieldValueData.gridApplicationInfo !== undefined ? setFieldValueData.gridApplicationInfo : getValue(thisObj, "gridApplicationInfo")

      if (obj.lblStatus == "Update" || obj.lblStatus == "UPDATE") {
        if (gridData?.length > 0) {

          for (let key in thisObj.state) {
            if (typeof thisObj.state[key] === 'object' && thisObj.state[key] !== null) {
              // Check if the current property is an object
              if ('type' in thisObj.state[key] &&
                (widgetType.includes(thisObj.state[key]['type']))
                && 'Enabled' in thisObj.state[key]) {
                // Check if the object has a 'Enabled' property
                //thisObj[key].Enabled = false;  // this line will not work to update state
                disable(thisObj, key)
              }
            }
          }

          for (let i = 0; i < 20; i++) {
            enable(thisObj, ["ddRemitTo" + i])
          }
          enable(thisObj, "ddPeanutVariety")
          enable(thisObj, "txtReceiptLbs")
          enable(thisObj, "chckBoxOverrideValue")
          if (checkBoxValue) {
            enable(thisObj, "txtReceiptValue")
          } else {
            disable(thisObj, "txtReceiptValue")
          }

          enable(thisObj, "txtAdjustmentLbs")
          enable(thisObj, "txtpaidDollars")
          enable(thisObj, "txtPricePerTon")
          enable(thisObj, "txtAreaComments")
          enable(thisObj, "btncancel")
          enable(thisObj, "btnPrint")
          enable(thisObj, "btnAdd")
        } else {
          disable(thisObj, "ddLocation")
          disable(thisObj, "txtAgreement")
        }
      }

      disable(thisObj, "btnPayments")
      disable(thisObj, "btnAcctDist")

      if (agree_status == "COMPLETE") {
        for (let key in thisObj.state) {
          if (typeof thisObj.state[key] === 'object' && thisObj.state[key] !== null) {
            // Check if the current property is an object
            if ('type' in thisObj.state[key] &&
              (widgetType.includes(thisObj.state[key]['type']))
              && 'Enabled' in thisObj.state[key]) {
              // Check if the object has a 'Enabled' property
              //thisObj[key].Enabled = false;  // this line will not work to update state
              disable(thisObj, key)
            }
          }
        }

        enable(thisObj, "btncancel")
        enable(thisObj, "btnPrint")
        enable(thisObj, "btnAdd")
        enable(thisObj, "btnPayments")
        enable(thisObj, "btnAcctDist")

        //added newly - not in old app
        disable(thisObj, "chckBoxOverrideValue")
        disable(thisObj, "radioDeliveryINout")
      }

      if (obj.sPermission == "I") {
        disable(thisObj, "btnDelete")
        disable(thisObj, "btnPrint")
        disable(thisObj, "btnAdd")
      }

      if (obj.sPermission == "D") {
        if (gridData?.length > 0) {
          disable(thisObj, "btnDelete")
        } else {
          enable(thisObj, "btnDelete")
        }
      }

      enable(thisObj, "ddWhseReceipt")
      enable(thisObj, "ddAgreementStatus")

      if(setFieldValueData !== undefined){
        disable(thisObj, "ddLocation")
      }

      //following are additional lines as readonly prop is not working
      disable(thisObj, "txtReceiptLbs")
      disable(thisObj, "txtTotalAdjustLbs")
      disable(thisObj, "txtConvApplied")
      disable(thisObj, "txtConversion")
      disable(thisObj, "txtConvopen")
      disable(thisObj, "txtAppliedLbs")
      disable(thisObj, "txt100PctApplied")
      disable(thisObj, "txt100AdjustLbs")
      disable(thisObj, "txt100Adjust")
      disable(thisObj, "txtOpenLbs")
      disable(thisObj, "txt100open")

      setData(thisObj, "DelvAgreeprofile_PageData", obj)
    } catch (error) {
      errorHandler(error);
    }
  }

  function EnableDisableBySeg() {
    try {
      let segValue = getValue(thisObj, "ddSeg") == undefined ? "1" : getValue(thisObj, "ddSeg")
      let generationValue = getValue(thisObj, "ddGeneration") == undefined ? "" : getValue(thisObj, "ddGeneration")

      if (segValue !== "1") {

        setValue(thisObj, "ddGeneration", "");
        disable(thisObj, "ddGeneration")

        setValue(thisObj, "ddEdibleOil", "O");
        enable(thisObj, "ddEdibleOil")

      } else {
        if (generationValue == "") {
          setValue(thisObj, "ddGeneration", "")
          enable(thisObj, "ddGeneration")
        }

        setValue(thisObj, "ddEdibleOil", "E");
        disable(thisObj, "ddEdibleOil")

      }

    } catch (error) {
      errorHandler(error);
    }
  }

  async function bFillDelvOutWhseNum() {
    try {
      let obj = getData(thisObj, "DelvAgreeprofile_PageData");

      let buy_pt_id = "" //getValue(thisObj, "ddLocation");
      let whse_rcpt_num = ""; //no value passing
      let agreement_num = getData(thisObj, "txtAgreement_DelvAgreeProfile");

      let response = await WarehouseReceiptService.RetrieveDeliveryOutAgreementWareHouseDetails(buy_pt_id, whse_rcpt_num, agreement_num)
      if (response !== undefined && response !== null && response.length > 0) {

        obj.mdblRcptLbs = 0;
        obj.mdblRcptValue = 0;

        let js = []
        for (let i = 0; i < response.length; i++) {
          let shrinkWeight = 0;
          if (!isNaN(response[i].shrink_net_wt) && response[i].shrink_net_wt !== "" && response[i].shrink_net_wt !== null) {
            shrinkWeight = Number(response[i].shrink_net_wt)
          }
          let object = { key: response[i].whse_rcpt_num.toString(), description: response[i].whse_rcpt_num + " - Rcpt Lbs = " + shrinkWeight }
          js.push(object);
          obj.mdblRcptLbs += shrinkWeight;

          if (!isNaN(response[i].rcpt_value) && response[i].rcpt_value !== "" && response[i].rcpt_value !== null) {
            obj.mdblRcptValue += Number(response[i].rcpt_value);
          }
        }

        thisObj.setState(current => {
          return {
            ...current,
            ddWhseReceipt: {
              ...state["ddWhseReceipt"],
              valueList: js
            }
          }
        })

        setValue(thisObj, "ddWhseReceipt", js?.at(0)?.key);

      }
      if (thisObj.state.ddWhseReceipt.valueList !== undefined && thisObj.state.ddWhseReceipt.valueList[0].key !== undefined && thisObj.state.ddWhseReceipt.valueList.length > 0) {
        setValue(thisObj, "ddWhseReceipt", thisObj.state.ddWhseReceipt.valueList[0].key);
      }

      setData(thisObj, "DelvAgreeprofile_PageData", obj);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function bFillDelvInWhseNum() {
    try {
      let obj = getData(thisObj, "DelvAgreeprofile_PageData");

      let buy_pt_id = "" //getValue(thisObj, "ddLocation");
      let whse_rcpt_num = ""; //no value passing
      let agreement_num = getData(thisObj, "txtAgreement_DelvAgreeProfile");

      let response = await WarehouseReceiptService.RetrieveDeliveryInAgreementWareHouseDetails(whse_rcpt_num, agreement_num)
      if (response !== undefined && response !== null && response.length > 0) {


        obj.mdblRcptLbs = 0;
        obj.mdblRcptValue = 0;

        let js = []
        for (let i = 0; i < response.length; i++) {
          let netWt = 0;
          if (!isNaN(response[i].net_wt) && response[i].net_wt !== "" && response[i].net_wt !== null) {
            netWt = Number(response[i].net_wt)
          }
          let object = { key: response[i].whse_rcpt_num.toString(), description: response[i].whse_rcpt_num + " - Receipt Lbs = " + netWt } //customFormat(netWt)
          js.push(object);
          obj.mdblRcptLbs += netWt;

          if (!isNaN(response[i].rcpt_value) && response[i].rcpt_value !== "" && response[i].rcpt_value !== null) {
            obj.mdblRcptValue += Number(response[i].rcpt_value);
          }
        }

        thisObj.setState(current => {
          return {
            ...current,
            ddWhseReceipt: {
              ...state["ddWhseReceipt"],
              valueList: js
            }
          }
        })

        setValue(thisObj, "ddWhseReceipt", js?.at(0)?.key);

      }
      if (thisObj.state.ddWhseReceipt.valueList !== undefined && thisObj.state.ddWhseReceipt.valueList[0].key !== undefined && thisObj.state.ddWhseReceipt.valueList.length > 0) {
        setValue(thisObj, "ddWhseReceipt", thisObj.state.ddWhseReceipt.valueList[0].key);
      }

      setData(thisObj, "DelvAgreeprofile_PageData", obj);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function GetAccessLevel(buy_pt, sub_id, accessLevel) {
    try {
      let func_id = "PN1180";
      let func_sub_id = sub_id;
      let access = accessLevel;
      let buy_pt_id = buy_pt;
      let response = await ContractManagementService.RetrieveAccessPermissionDetails(func_id, func_sub_id, access, buy_pt_id)
      if (response.length > 0 && response[0]?.permission !== undefined && response[0]?.permission == "Y") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      errorHandler(error);
      return false;
    }
  }

  async function bFillRemitToList(index, vendorNumber) {
    try {
      let vendorNum = vendorNumber !== undefined || vendorNumber !== null ? vendorNumber : getValue(thisObj, ["txtVendor" + index]);
      
      //if(vendorNum !== undefined && vendorNum !== null) {
        setValue(thisObj, ["txtSharePercentage" + index] , "")
      //}
      
      let response = await ContractManagementService.RetrieveVendorByNumber(vendorNum)
      if (response.length > 0) {
        let vendor_name = toPascalCase(response[0].name)
        setValue(thisObj, ["lblXX" + index], vendor_name)
        if (index >= 3 && index < 19) {
          show(thisObj, ["grpbxVendorSplitRow" + (index + 1)])
        }
      }
      else {
        setValue(thisObj, ["lblXX" + index], "")
      }


      let secondResponse = await ContractManagementService.RetieveRemitDetails(vendorNum)
      let js = []
      if (secondResponse.length > 0) {
        let data = secondResponse
        for (var i = 0; i < data.length; i++) {
          //let obj = { key: data[i].number.toString(), description: data[i].number + " - " + data[i].name + " - " + data[i].city + ", " + data[i].state }
          let obj = { key: data[i].remittoid.toString(), description: data[i].remittoid + " - " + data[i].name + " - " + data[i].city + ", " + data[i].state }
          js.push(obj)
        }
      }

      thisObj.setState(current => {
        return {
          ...current,
          ["ddRemitTo" + index]: {
            ...state["ddRemitTo" + index],
            valueList: js
          }
        }
      })

      if (js.length > 0) {
        setValue(thisObj, ["ddRemitTo" + index], js[0].key)
      }


    } catch (error) {
      errorHandler(error)
    }
  }

  async function bFillForm() {
    try {
      //setLoading(true);
      let obj = getData(thisObj, "DelvAgreeprofile_PageData");
      let bFillFormValue = true
      let setFieldValueData = {}

      let func_id = "PN1180",
        func_sub_id = "",
        buy_pt_id = getValue(thisObj, "ddLocation"),
        agree_num = getData(thisObj, "txtAgreement_DelvAgreeProfile"),
        agree_type = "",
        agree_status = "",
        pnut_type_id = "",////////////////////////
        pnut_variety_id = "",
        seed_gen = "",
        seg_type = "",
        oleic_ind = "",
        edible_oil = "",
        delv_ind = "Y",
        split_vendor = "";

      let data = await SettlementService.RetrieveTradeAgreementDetails(func_id, func_sub_id, buy_pt_id, agree_num, agree_type, agree_status, pnut_type_id, pnut_variety_id, seed_gen, seg_type, oleic_ind, edible_oil, delv_ind, split_vendor);

      if (data !== undefined && data !== null && data.length > 0) {
        data = data[0]


        setFieldValueData["ddLocation"] = data.buy_pt_id
        setFieldValueData["txtAgreement"] = data.agreement_num
        setFieldValueData["ddAgreementStatus"] = data.agree_status == "C" ? "COMPLETE" : "OPEN"

        obj.status_flag = data.agree_status == "C" ? "COMPLETE" : "OPEN";

        if (data.agree_date_time !== undefined && data.agree_date_time !== "") {
          setFieldValueData["txtAgreeDate"] = moment(data.agree_date_time).format("MM/DD/YYYY")
        }
        setFieldValueData["ddPeanutType"] = data.pnut_type_id
        await bFillPeanutVarietyList(setFieldValueData["ddLocation"], setFieldValueData["ddPeanutType"])
        setLoading(true)
        setFieldValueData["ddPeanutVariety"] = data.pnut_variety_id
        setFieldValueData["ddGeneration"] = data.seed_gen
        setFieldValueData["ddSeg"] = data.seg_type
        setFieldValueData["ddOleic"] = data.oleic_ind
        setFieldValueData["ddEdibleOil"] = data.edible_oil_ind
        setFieldValueData["radioDeliveryINout"] = data.purch_sale_ind == "P" ? "2" : "1"
        setFieldValueData["ddFirmBasic"] = data.firm_basis_opt

        //textbox binding
        setFieldValueData["txtPricePerTon"] = isNumeric(data.price_per_ton) ? Number(data.price_per_ton).toFixed(2) : "0.00"
        setFieldValueData["txtAppliedLbs"] = isNumeric(data.delivered_lbs) ? Number(data.delivered_lbs).toFixed(2) : "0"
        setFieldValueData["txtAreaComments"] = data.remarks
        setFieldValueData["lblAddedByValue"] = data.add_user + " " + data.add_date_time
        setFieldValueData["lblChangedByValue"] = data.chg_user + " " + data.chg_date_time


        if (data.delv_agree_dol !== undefined && data.delv_agree_dol !== "") {
          setFieldValueData["txtpaidDollars"] = isNumeric(data.delv_agree_dol) ? Number(data.delv_agree_dol).toFixed(2) : "0.00"
        }

        setFieldValueData["txt100Adjust"] = isNumeric(data.delv_unapp_dol) ? Number(data.delv_unapp_dol).toFixed(2) : "0.00"
        setFieldValueData["txtAdjustmentLbs"] = Number(data.adjust_lbs).toFixed(0)
        setFieldValueData["txtReceiptValue"] = Number(data.rcpt_value).toFixed(2)
        //setFieldValueData["txtConversion"] = data.conv_pct

        if (isNumeric(data.rcpt_value)) {
          obj.mdblRcptValueOverride = Number(data.rcpt_value).toFixed(2)
        }
        if (data.rcpt_value_override_ind == "Y") {
          setFieldValueData["chckBoxOverrideValue"] = true
        } else {
          setFieldValueData["chckBoxOverrideValue"] = false
        }

        clearVendorSplit()

        if (data.split_vendor.includes("|")) {

          let allVendorNumbers = data.split_vendor.split("|")
          let allVendorNames = data.vendor_name.split("|")
          let allRemitNumbers = data.split_remit.split("|")
          let allSharePercentage = data.split_share_pct.split("|")

          for (let i = 0; i < allVendorNumbers.length; i++) {
            setFieldValueData["txtVendor" + i] = allVendorNumbers[i]
            setFieldValueData["lblXX" + i] = allVendorNames[i]
            await bFillRemitToList(i, allVendorNumbers[i])

            setFieldValueData["ddRemitTo" + i] = allRemitNumbers[i]
            setFieldValueData["txtSharePercentage" + i] = allSharePercentage[i]
          }

        } else {
          setFieldValueData["txtVendor0"] = data.split_vendor
          setFieldValueData["lblXX0"] = data.vendor_name
          await bFillRemitToList(0, data.split_vendor)
          setFieldValueData["ddRemitTo0"] = data.split_remit
          setFieldValueData["txtSharePercentage0"] = data.split_share_pct
        }

        obj.mdblNetWt = 0
        obj.mdblBGA = 0

        let gridData = [];
        setFieldValueData["gridApplicationInfo"] = gridData;
        setData(thisObj, "gridApplicationInfo", gridData);
        obj.Agree_total = 0

        let gridApi = await SettlementService.RetrieveTradeAgreementApplicationDetails(data.agreement_num, delv_ind, data.buy_pt_id)
        if (gridApi !== undefined && gridApi !== null && gridApi.length > 0) {
          obj.lblLastSettleDate = "";
          for (let i = 0; i < gridApi.length; i++) {
            if (isNumeric(gridApi[i].delivered_lbs_adj) && Number(gridApi[i].delivered_lbs_adj) > 0) {
              let rowObj = {};
              let segValue = data.seg_type;
              rowObj.txtcolConvert = gridApi[i].seg_type == segValue ? "TRUE" : "FALSE"
              rowObj.txtcolLocation = gridApi[i].buy_pt_id
              rowObj.txtcolDelvInsp = gridApi[i].audit_trade_insp
              rowObj.txtcolDelvSettle = gridApi[i].audit_trade_settle
              rowObj.txtcolShippedDate = moment(gridApi[i].shp_date).format("MM/DD/YYYY") == "Invalid date" ? "" : moment(gridApi[i].shp_date).format("MM/DD/YYYY")
              rowObj.txtcolReceivedDate = moment(gridApi[i].rec_date).format("MM/DD/YYYY") == "Invalid date" ? "" : moment(gridApi[i].rec_date).format("MM/DD/YYYY")
              rowObj.txtcolAppliedLbs = gridApi[i].delivered_lbs_adj
              rowObj.txtcol100Applied = isNumeric(gridApi[i].settle_dol_value) ? Number(gridApi[i].settle_dol_value).toFixed(2) : "0"


              if (rowObj.txtcolConvert == "TRUE") {
                let conversionPctValue = getValue(thisObj, "txtConversion")
                rowObj.txtcolConvApplied = ((isNumeric(gridApi[i].settle_dol_value) ? Number(gridApi[i].settle_dol_value) : 0) * (isNumeric(conversionPctValue) ? Number(conversionPctValue) : 1)).toFixed(2)
              } else {
                rowObj.txtcolConvApplied = Number(gridApi[i].settle_dol_value).toFixed(2)
              }
              rowObj.txtcol100Adjustment = Number(gridApi[i].unapp_dol_value).toFixed(2)

              if (isNumeric(gridApi[i].unapp_dol_value) && Number(gridApi[i].unapp_dol_value) > 0) {
                obj.mdblNetWt = obj.mdblNetWt + Number(gridApi[i].net_wt)
                obj.mdblBGA = obj.mdblBGA + Number(gridApi[i].basis_grade_amt)
              }

              obj.Agree_total = obj.Agree_total + Number(rowObj.txtcol100Applied)

              //checking and picking most recent settlement date
              if (obj.lblLastSettleDate !== "") {
                if (Date(gridApi[i].settle_date_time) > obj.lblLastSettleDate) {
                  obj.lblLastSettleDate = Date(gridApi[i].settle_date_time);
                }
              } else {
                obj.lblLastSettleDate = Date(gridApi[i].settle_date_time);
              }

              gridData.push(rowObj)
            }
          }

          setFieldValueData["gridApplicationInfo"] = gridData;
          setData(thisObj, "gridApplicationInfo", gridData);

          if (Number(data.delv_unapp_dol).toFixed(2) != 0) {
            setFieldValueData["txt100AdjustLbs"] = (obj.mdblNetWt * (Number(Number(data.delv_unapp_dol).toFixed(2)) / obj.mdblBGA)).toFixed(0)
          }
        }

        obj.lblStatus = "Update"

        setData(thisObj, "DelvAgreeprofile_PageData", obj);
        await EnableDisableControls(setFieldValueData)
        obj = getData(thisObj, "DelvAgreeprofile_PageData")

        bFillFormValue = true

      } else {
        bFillFormValue = false
      }


      if (data.purch_sale_ind !== "P") {
        await bFillDelvOutWhseNum()
      } else {
        await bFillDelvInWhseNum()
      }

      setFieldValueData["txtReceiptLbs"] = isNumeric(obj.mdblRcptLbs) ? obj.mdblRcptLbs.toString() : "0";

      if (data.rcpt_value_override_ind !== "Y") {
        setFieldValueData["txtReceiptValue"] = isNumeric(obj.mdblRcptValue) ? Number(obj.mdblRcptValue).toFixed(2) : "0.00"
      } else {
        setFieldValueData["txtReceiptValue"] = isNumeric(obj.mdblRcptValueOverride) ? obj.mdblRcptValueOverride.toString() : "0.00"
      }

      setFieldValueData["txt100PctApplied"] = (obj.Agree_total).toFixed(2)

      //setFieldValues(thisObj, setFieldValueData , true)

      setFieldValueData = CalcConvPct(setFieldValueData["txtpaidDollars"], setFieldValueData["txtReceiptValue"], setFieldValueData)
      setFieldValueData = CalcTotAdjLbs(setFieldValueData["txtReceiptLbs"], setFieldValueData["txtAdjustmentLbs"], setFieldValueData)
      setFieldValueData = CalcOpenLbs(setFieldValueData["txtTotalAdjustLbs"], setFieldValueData["txtAppliedLbs"], setFieldValueData["txt100AdjustLbs"], setFieldValueData)
      setFieldValueData = CalcConvOpenDollar(setFieldValueData["txtpaidDollars"], setFieldValueData["txtConvApplied"], setFieldValueData)
      setFieldValueData = Calc100OpenDollar(setFieldValueData["txtReceiptValue"], setFieldValueData["txt100PctApplied"], setFieldValueData["txt100Adjust"], setFieldValueData)

      //setFieldValues(thisObj, setFieldValueData , true)
      for (const key in setFieldValueData) {
        if (setFieldValueData.hasOwnProperty(key)) {
          setValue(thisObj, key, setFieldValueData[key])
        }
      }
      setData(thisObj, "DelvAgreeprofile_PageData", obj);
      setLoading(false);
    } catch (error) {
      errorHandler(error)
    }
  }

  function clearVendorSplit() {
    try {

      let DataObj = {}
      for (let i = 0; i < 20; i++) {
        //setValue(thisObj, ["txtVendor" + i], "")
        //setValue(thisObj, ["lblXX" + i], "")

        DataObj["txtVendor" + i] = "";
        DataObj["lblXX" + i] = ""

        thisObj.setState(current => {
          return {
            ...current,
            ["ddRemitTo" + i]: {
              ...state["ddRemitTo" + i],
              valueList: []
            }
          }
        })

        //setValue(thisObj, ["ddRemitTo" + i], "")  //not needed

        //setValue(thisObj, ["txtSharePercentage" + i], "")
        DataObj["txtSharePercentage" + i] = ""

        if (i > 3) {
          hide(thisObj, ['grpbxVendorSplitRow' + i])
        }

      }
      //setFieldValues(thisObj, DataObj, true)
      for (const key in DataObj) {
        if (DataObj.hasOwnProperty(key)) {
          setValue(thisObj, key, DataObj[key])
        }
      }

    } catch (error) {
      errorHandler(error)
    }
  }

  function bFormValid() {
    try {
      //    getValue(thisObj, )
      //    showMessage(thisObj, "")
      let obj = getData(thisObj, "DelvAgreeprofile_PageData")

      let ddWhseReceiptValue = getValue(thisObj, "ddWhseReceipt");
      let ddAgreementStatusValue = getValue(thisObj, "ddAgreementStatus");
      let buy_pt_id = getValue(thisObj, "ddLocation");
      let radioButtonValue = getValue(thisObj, "radioDeliveryINout")
      let inputDate = getValue(thisObj, "txtAgreeDate")
      let peanutTypevalue = getValue(thisObj, "ddPeanutType")
      let peanutVarietyvalue = getValue(thisObj, "ddPeanutVariety")
      let segValue = getValue(thisObj, "ddSeg")
      let ddOleicValue = getValue(thisObj, "ddOleic")
      let ddFirmBasicValue = getValue(thisObj, "ddFirmBasic")
      let receiptValue = getValue(thisObj, "txtReceiptValue")
      let txtAdjustmentLbsValue = getValue(thisObj, "txtAdjustmentLbs")
      let txtPaidDollar = getValue(thisObj, "txtpaidDollars")
      let txtPrice = getValue(thisObj, "txtPricePerTon")

      let vendorSplit = {}

      for (let i = 0; i < 20; i++) {
        vendorSplit["vendorNumber" + i] = getValue(thisObj, ["txtVendor" + i])
        vendorSplit["remitValue" + i] = getValue(thisObj, ["ddRemitTo" + i])
        vendorSplit["shareValue" + i] = getValue(thisObj, ["txtSharePercentage" + i])
      }

      if ((ddWhseReceiptValue == undefined || ddWhseReceiptValue == null || ddWhseReceiptValue == "") && ddAgreementStatusValue == "COMPLETE") {
        showMessage(thisObj, "Warehouse receipts have to be attached to the agreement in order to close it")
        return false;
      }

      if (buy_pt_id == undefined || buy_pt_id == null || buy_pt_id == "") {
        showMessage(thisObj, "Delivery Agreement Location must be selected")
        return false;
      }

      if (radioButtonValue !== "1" && radioButtonValue !== "2") {
        showMessage(thisObj, "Incorrect Data. Delivery Sale or Delivery Purchase option must be selected!")
        return false;
      }

      if (inputDate == undefined || inputDate == null || inputDate == '' || !moment(inputDate).isValid()) {
        showMessage(thisObj, "Invalid Date!!!")
        return false;
      } else {
        if (moment(inputDate).isBefore(moment('01/06/' + (JSON.parse(sessionStorage.getItem('year')))))) {
          showMessage(thisObj, "Date cannot be less than June 1 of current crop year!!!")
          return false;
        }
      }

      if (peanutTypevalue == undefined || peanutTypevalue == null || peanutTypevalue == "") {
        showMessage(thisObj, "Please Select a Peanut Type!!!")
        return false;
      }

      if (peanutVarietyvalue == undefined || peanutVarietyvalue == null) {
        showMessage(thisObj, "Please Select a Peanut Variety!!!")
        return false;
      }

      if (segValue == undefined || segValue == null || segValue == "") {
        showMessage(thisObj, "Please Select a Segment!!!")
        return false;
      }

      if (ddFirmBasicValue == undefined || ddFirmBasicValue == null || ddFirmBasicValue == "") {
        showMessage(thisObj, "A Pricing Type must be selected!!!")
        return false;
      }

      for (let i = 0; i < 20; i++) {
        if (vendorSplit["vendorNumber" + i] !== undefined && vendorSplit["vendorNumber" + i] !== null && vendorSplit["vendorNumber" + i] !== "") {
          break;
        }
        showMessage(thisObj, "At least one valid vendor is required!!!")
        return false;
      }

      let totalSharePercentage = 0
      for (let i = 0; i < 20; i++) {
        if (vendorSplit["vendorNumber" + i] !== undefined && vendorSplit["vendorNumber" + i] !== null && vendorSplit["vendorNumber" + i] !== "") {

          if (vendorSplit["remitValue" + i] == undefined || vendorSplit["remitValue" + i] == null || vendorSplit["remitValue" + i] == "") {
            showMessage(thisObj, "Vendor " + (i + 1) + " is not valid!!!")
            return false;
          }

          if ((vendorSplit["shareValue" + i] == undefined || vendorSplit["shareValue" + i] == null || vendorSplit["shareValue" + i] == "") || (vendorSplit["remitValue" + i] == undefined || vendorSplit["remitValue" + i] == null || vendorSplit["remitValue" + i] == "")) {
            showMessage(thisObj, "Vendor share " + (i + 1) + " is not valid without a vendor!!!")
            return false;
          }

          if (!isNumeric(vendorSplit["shareValue" + i])) {
            showMessage(thisObj, "Vendor share must be of format ###.##!!!")
            let txtbox = document.getElementById(["txtSharePercentage" + i])
            if (txtbox !== undefined) {
              txtbox.focus()
              txtbox.select()
            }
            return false;
          }

          if (Number(vendorSplit["shareValue" + i]) <= 0) {
            showMessage(thisObj, "Vendor share must be greater than 0!!!")
            let txtbox = document.getElementById(["txtSharePercentage" + i])
            if (txtbox !== undefined) {
              txtbox.focus()
              txtbox.select()
            }
            return false;
          }

          if (Number(vendorSplit["shareValue" + i]) > 100) {
            showMessage(thisObj, "Vendor share must be less than 100!!!")
            let txtbox = document.getElementById(["txtSharePercentage" + i])
            if (txtbox !== undefined) {
              txtbox.focus()
              txtbox.select()
            }
            return false;
          }

          

        }

        if(!isNumeric(vendorSplit["shareValue" + i])){
          vendorSplit["shareValue" + i] = 0
        }
        totalSharePercentage += Number(vendorSplit["shareValue" + i])

      }

      if (totalSharePercentage != 100) {
        showMessage(thisObj, "Total of vendor shares " + totalSharePercentage + " does not equal 100!!!")
        let txtbox = document.getElementById(["txtSharePercentage0"])
        if (txtbox !== undefined) {
          txtbox.focus()
          txtbox.select()
        }
        return false;
      }

      if (isNumeric(receiptValue) && receiptValue > 999999999.99) {
        showMessage(thisObj, "The Receipt Value cannot exceed $999,999,999.99.")
        return false;
      }

      if (isNumeric(txtAdjustmentLbsValue) && txtAdjustmentLbsValue > 999999999) {
        showMessage(thisObj, "The Pounds Adjustment cannot exceed 999,999,999 pounds.")
        return false;
      } else if (!isNumeric(txtAdjustmentLbsValue)) {
        setValue(thisObj, "txtAdjustmentLbs", "0")
      }

      if (isNumeric(txtPaidDollar) && txtPaidDollar > 999999999.99) {
        showMessage(thisObj, "The Paid Dollars cannot exceed $999,999,999.99.")
        return false;
      } else if (!isNumeric(txtPaidDollar)) {
        setValue(thisObj, "txtpaidDollars", "0.00")

        let tempObj
        if (obj.mbCalcing == false) {
          tempObj = CalcConvPct(isNumeric(txtPaidDollar) ? txtPaidDollar : 0.00) //second prop is default
        }

        obj = getData(thisObj, "DelvAgreeprofile_PageData")
        if (obj.mbCalcing == false) {
          CalcConvOpenDollar(tempObj !== undefined ? tempObj?.txtPaidDollar : (isNumeric(txtPaidDollar) ? txtPaidDollar : 0.00), tempObj?.txtConvApplied) //second prop is default
        }

      }

      if (isNumeric(txtPrice) && txtPrice > 9999.99) {
        showMessage(thisObj, "An Agreement Price cannot exceed $9,999.99.")
        return false;
      } else if (!isNumeric(txtPrice)) {
        setValue(thisObj, "txtPricePerTon", "0.00")
      }

      return true;
    } catch (error) {
      errorHandler(error)
    }
  }

  //calculations

  function CalcConvPct(txtpaidDollar_parameter = getValue(thisObj, "txtpaidDollars"), txtReceiptValue_parameter = getValue(thisObj, "txtReceiptValue"), setFieldValueData) {
    let obj = getData(thisObj, "DelvAgreeprofile_PageData")

    try {
      obj.mbCalcing = true
      let objectForsetValue = {};
      let temp = 0
      if (setFieldValueData == undefined) {
        objectForsetValue = {}
        temp = 1
      } else {
        objectForsetValue = setFieldValueData
      }

      let txtpaidDollars = txtpaidDollar_parameter//getValue(thisObj, "txtpaidDollars");
      let txtReceiptValue = txtReceiptValue_parameter//getValue(thisObj, "txtReceiptValue");

      if (!isNumeric(txtpaidDollars)) {
        objectForsetValue["txtpaidDollars"] = "0"
        txtpaidDollars = 0
      }
      if (!isNumeric(txtReceiptValue)) {
        objectForsetValue["txtReceiptValue"] = "0"
        txtReceiptValue = 0
      }

      let txtConversion = 0;

      if (Number(txtReceiptValue) !== NaN && Number(txtReceiptValue) > 0) {
        txtConversion = Number(txtpaidDollars) / Number(txtReceiptValue)
      } else {
        txtConversion = 1;
      }

      objectForsetValue["txtConversion"] = Number(txtConversion).toFixed(7)
      txtConversion = Number(txtConversion).toFixed(7)

      let ConvApplDollar = 0;

      let gridData = getData(thisObj, "gridApplicationInfo")

      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].txtcolConvert == "TRUE") {
          gridData[i].txtcolConvApplied = (isNumeric(gridData[i].txtcol100Applied) ? Number(gridData[i].txtcol100Applied) : 0) * Number(txtConversion)
        } else {
          gridData[i].txtcolConvApplied = (isNumeric(gridData[i].txtcol100Applied) ? Number(gridData[i].txtcol100Applied) : 0)
        }
        gridData[i].txtcolConvApplied = Number(gridData[i].txtcolConvApplied).toFixed(2)
        ConvApplDollar += Number(gridData[i].txtcolConvApplied);
      }

      objectForsetValue["gridApplicationInfo"] = gridData
      objectForsetValue["txtConvApplied"] = Number(ConvApplDollar).toFixed(2)

      obj.mbCalcing = false

      if (temp == 1) {
        //setFieldValues(thisObj, objectForsetValue, true)
        for (const key in objectForsetValue) {
          if (objectForsetValue.hasOwnProperty(key)) {
            setValue(thisObj, key, objectForsetValue[key])
          }
        }
      }
      setData(thisObj, "DelvAgreeprofile_PageData", obj)
      return objectForsetValue;

    } catch (error) {
      errorHandler(error);
      obj.mbCalcing = false
    }

    setData(thisObj, "DelvAgreeprofile_PageData", obj)
  }

  function CalcTotAdjLbs(txtReceiptLbs_parameter = getValue(thisObj, "txtReceiptLbs"), txtAdjustmentLbs_parameter = getValue(thisObj, "txtAdjustmentLbs"), setFieldValueData) {
    let obj = getData(thisObj, "DelvAgreeprofile_PageData")

    try {
      obj.mbCalcing = true
      let objectForsetValue = {};
      let temp = 0
      if (setFieldValueData == undefined) {
        objectForsetValue = {}
        temp = 1
      } else {
        objectForsetValue = setFieldValueData
      }

      let txtReceiptLbs = txtReceiptLbs_parameter;
      let txtAdjustmentLbs = txtAdjustmentLbs_parameter;

      if (!isNumeric(txtReceiptLbs)) {
        objectForsetValue["txtReceiptLbs"] = "0";
        txtReceiptLbs = 0
      }
      if (!isNumeric(txtAdjustmentLbs)) {
        objectForsetValue["txtAdjustmentLbs"] = "0";
        txtAdjustmentLbs = 0
      }

      let lblTotAdjLbs = Number(txtReceiptLbs) + Number(txtAdjustmentLbs);
      //lblTotAdjLbs = customFormat(lblTotAdjLbs)
      lblTotAdjLbs = Number(lblTotAdjLbs).toFixed(0)
      objectForsetValue["txtTotalAdjustLbs"] = lblTotAdjLbs

      obj.mbCalcing = false

      if (temp == 1) {
        //setFieldValues(thisObj, objectForsetValue, true)
        for (const key in objectForsetValue) {
          if (objectForsetValue.hasOwnProperty(key)) {
            setValue(thisObj, key, objectForsetValue[key])
          }
        }
      }
      setData(thisObj, "DelvAgreeprofile_PageData", obj)
      return objectForsetValue;

    } catch (error) {
      errorHandler(error);
      obj.mbCalcing = false
    }

    setData(thisObj, "DelvAgreeprofile_PageData", obj)
  }

  function CalcOpenLbs(txtTotalAdjustLbs_parameter = getValue(thisObj, "txtTotalAdjustLbs"), txtAppliedLbs_parameter = getValue(thisObj, "txtAppliedLbs"), txt100AdjustLbs_parameter = getValue(thisObj, "txt100AdjustLbs"), setFieldValueData) {
    let obj = getData(thisObj, "DelvAgreeprofile_PageData")

    try {
      obj.mbCalcing = true
      let objectForsetValue = {};
      let temp = 0
      if (setFieldValueData == undefined) {
        objectForsetValue = {}
        temp = 1
      } else {
        objectForsetValue = setFieldValueData
      }

      let txtTotalAdjustLbs = txtTotalAdjustLbs_parameter;
      let txtAppliedLbs = txtAppliedLbs_parameter;
      let txt100AdjustLbs = txt100AdjustLbs_parameter;

      if (!isNumeric(txtTotalAdjustLbs)) {
        objectForsetValue["txtTotalAdjustLbs"] = "0";
        txtTotalAdjustLbs = 0
      }
      if (!isNumeric(txtAppliedLbs)) {
        objectForsetValue["txtAppliedLbs"] = "0";
        txtAppliedLbs = 0
      }

      if (!isNumeric(txt100AdjustLbs)) {
        objectForsetValue["txt100AdjustLbs"] = "0";
        txt100AdjustLbs = 0
      }

      let txtOpenLbs = Number(txtTotalAdjustLbs) - Number(txtAppliedLbs) - Number(txt100AdjustLbs);
      txtOpenLbs = (txtOpenLbs).toFixed(0)
      objectForsetValue["txtOpenLbs"] = txtOpenLbs

      obj.mbCalcing = false

      if (temp == 1) {
        //setFieldValues(thisObj, objectForsetValue, true)
        for (const key in objectForsetValue) {
          if (objectForsetValue.hasOwnProperty(key)) {
            setValue(thisObj, key, objectForsetValue[key])
          }
        }
      }
      setData(thisObj, "DelvAgreeprofile_PageData", obj)
      return objectForsetValue;

    } catch (error) {
      errorHandler(error)
      obj.mbCalcing = false
    }

    setData(thisObj, "DelvAgreeprofile_PageData", obj)
  }

  function CalcConvOpenDollar(txtpaidDollars_parameter = getValue(thisObj, "txtpaidDollars"), txtConvApplied_parameter = getValue(thisObj, "txtConvApplied"), setFieldValueData) {
    let obj = getData(thisObj, "DelvAgreeprofile_PageData")

    try {
      obj.mbCalcing = true
      let objectForsetValue = {};
      let temp = 0
      if (setFieldValueData == undefined) {
        objectForsetValue = {}
        temp = 1
      } else {
        objectForsetValue = setFieldValueData
      }

      let txtpaidDollars = txtpaidDollars_parameter;
      let txtConvApplied = txtConvApplied_parameter;

      if (!isNumeric(txtpaidDollars)) {
        objectForsetValue["txtpaidDollars"] = "0";
        txtpaidDollars = 0
      }
      if (!isNumeric(txtConvApplied)) {
        objectForsetValue["txtConvApplied"] = "0";
        txtConvApplied = 0
      }


      let txtConvopen = Number(txtpaidDollars) - Number(txtConvApplied);
      txtConvopen = (txtConvopen).toFixed(2)
      objectForsetValue["txtConvopen"] = txtConvopen

      obj.mbCalcing = false

      if (temp == 1) {
        //setFieldValues(thisObj, objectForsetValue, true)
        for (const key in objectForsetValue) {
          if (objectForsetValue.hasOwnProperty(key)) {
            setValue(thisObj, key, objectForsetValue[key])
          }
        }
      }
      setData(thisObj, "DelvAgreeprofile_PageData", obj)
      return objectForsetValue;

    } catch (error) {
      errorHandler(error)
      obj.mbCalcing = false
    }

    setData(thisObj, "DelvAgreeprofile_PageData", obj)
  }

  function Calc100OpenDollar(txtReceiptValue_parameter = getValue(thisObj, "txtReceiptValue"), txt100PctApplied_parameter = getValue(thisObj, "txt100PctApplied"), txt100Adjust_parameter = getValue(thisObj, "txt100Adjust"), setFieldValueData) {
    let obj = getData(thisObj, "DelvAgreeprofile_PageData")

    try {
      obj.mbCalcing = true
      let objectForsetValue = {};
      let temp = 0
      if (setFieldValueData == undefined) {
        objectForsetValue = {}
        temp = 1
      } else {
        objectForsetValue = setFieldValueData
      }

      let txtReceiptValue = txtReceiptValue_parameter;
      let txt100PctApplied = txt100PctApplied_parameter;
      let txt100Adjust = txt100Adjust_parameter;

      if (!isNumeric(txtReceiptValue)) {
        objectForsetValue["txtReceiptValue"] = "0";
        txtReceiptValue = 0
      }
      if (!isNumeric(txt100PctApplied)) {
        objectForsetValue["txt100PctApplied"] = "0";
        txt100PctApplied = 0
      }

      if (!isNumeric(txt100Adjust)) {
        objectForsetValue["txt100Adjust"] = "0";
        txt100Adjust = 0
      }

      let txt100open = Number(txtReceiptValue) - Number(txt100PctApplied) - Number(txt100Adjust);
      txt100open = (txt100open).toFixed(2)
      objectForsetValue["txt100open"] = txt100open

      obj.mbCalcing = false

      if (temp == 1) {
        //setFieldValues(thisObj, objectForsetValue, true)
        for (const key in objectForsetValue) {
          if (objectForsetValue.hasOwnProperty(key)) {
            setValue(thisObj, key, objectForsetValue[key])
          }
        }
      }
      setData(thisObj, "DelvAgreeprofile_PageData", obj)
      return objectForsetValue;

    } catch (error) {
      errorHandler(error)
      obj.mbCalcing = false
    }

    setData(thisObj, "DelvAgreeprofile_PageData", obj)
  }


  //change events

  //dropdown changes
  const onddLocationChange = async () => {
    try {
      setFormDirty(true);
      let location = getValue(thisObj, "ddLocation")
      let varietyfound = await bFillPeanutVarietyList(location);

      if (!varietyfound) {
        setValue(thisObj, "ddLocation", "");
      }

    } catch (error) {
      errorHandler(error);
      return false;
    }
  }
  thisObj.onddLocationChange = onddLocationChange;

  const onddPeanutTypeChange = async () => {
    try {
      setFormDirty(true);
      let location = getValue(thisObj, "ddLocation")
      let peanut_type = getValue(thisObj, "ddPeanutType")
      let varietyfound = await bFillPeanutVarietyList(location, peanut_type);

      if (!varietyfound) {
        setValue(thisObj, "ddPeanutType", "");
      }

    } catch (error) {
      errorHandler(error);
      return false;
    }
  }
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange;

  const onddOleicChange = () => {
    try {
      setFormDirty(true);
    } catch (error) {
      errorHandler(error);
    }
  }
  thisObj.onddOleicChange = onddOleicChange;

  const onddPeanutVarietyChange = () => {
    try {
      setFormDirty(true);
    } catch (error) {
      errorHandler(error);
    }
  }
  thisObj.onddPeanutVarietyChange = onddPeanutVarietyChange;

  const onddSegChange = () => {
    try {
      setFormDirty(true);
      EnableDisableBySeg()
    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.onddSegChange = onddSegChange;

  const remitDropdownsChange = () => {
    try {
      setFormDirty(true);
    } catch (error) {
      errorHandler(error)
    }
  }

  //textbox changes
  const vendorNumberChange = () => {
    try {
      setFormDirty(true)
    } catch (error) {
      errorHandler(error)
    }
  }

  const ontxtpaidDollarsChange = () => {
    try {
      let value = getValue(thisObj, "txtpaidDollars");
      value = validateTextBox(value)
      setValue(thisObj, "txtpaidDollars", value)
    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.ontxtpaidDollarsChange = ontxtpaidDollarsChange;

  const ontxtPricePerTonChange = () => {
    try {
      let value = getValue(thisObj, "txtPricePerTon");
      value = validateTextBox(value)
      setValue(thisObj, "txtPricePerTon", value)
    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.ontxtPricePerTonChange = ontxtPricePerTonChange;


  //checkbox changes
  const onchckBoxOverrideValueChange = () => {
    try {
      setFormDirty(true);

      let obj = getData(thisObj, "DelvAgreeprofile_PageData")

      let value = getValue(thisObj, "chckBoxOverrideValue");

      if (value) {
        enable(thisObj, "txtReceiptValue")
        setValue(thisObj, "txtReceiptValue", obj.mdblRcptValueOverride !== undefined ? obj.mdblRcptValueOverride.toString() : "")
        // txtRcptValue.ForeColor = &H80000012
        // txtRcptValue.BackColor = &H80000005
      } else {
        disable(thisObj, "txtReceiptValue")
        setValue(thisObj, "txtReceiptValue", obj.mdblRcptValue !== undefined ? obj.mdblRcptValue.toString() : "")
        // txtRcptValue.ForeColor = &H80000012
        // txtRcptValue.BackColor = &H8000000F
      }

    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.onchckBoxOverrideValueChange = onchckBoxOverrideValueChange;


  //blur events
  const vendorNumberBlur = (index) => {  //vendersplit textbox
    try {
      let vendorNum = getValue(thisObj, ["txtVendor" + index]);
      if (vendorNum !== undefined && vendorNum.length > 5) {
        bFillRemitToList(index, vendorNum);
      } else {
        setValue(thisObj, ["lblXX" + index], "")
        let js = []

        thisObj.setState(current => {
          return {
            ...current,
            ["ddRemitTo" + index]: {
              ...state["ddRemitTo" + index],
              valueList: js
            }
          }
        })
      }

    } catch (error) {
      errorHandler(error);
    }
  }

  const txtSharePctChange = (index) => {
    try {

      let value = getValue(thisObj, ["txtSharePercentage" + index]);

      let newAValue = FormatNumber(value, 2, 6)
      if (value !== newAValue && !(value == undefined && newAValue == "")) {
        setFormDirty(true);
      }
      setValue(thisObj, ["txtSharePercentage" + index], newAValue)

    } catch (error) {
      errorHandler(error)
    }
  }

  const ontxtAdjustmentLbsBlur = () => {
    try {
      let value = getValue(thisObj, "txtAdjustmentLbs");

      if (isNumeric(value)) {
        value = Number(value).toFixed(0)
      } else {
        value = "0"
      }
      setValue(thisObj, "txtAdjustmentLbs", FormatNumber(value, 0, 9))

      let obj = getData(thisObj, "DelvAgreeprofile_PageData")

      if (obj.mbCalcing == false) {
        CalcTotAdjLbs(undefined, FormatNumber(value, 0, 9)) //first parameter is default
      }

    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.ontxtAdjustmentLbsBlur = ontxtAdjustmentLbsBlur;

  const ontxtReceiptValueBlur = () => {
    try {
      let value = getValue(thisObj, "txtReceiptValue");

      if (isNumeric(value)) {
        value = Number(value).toFixed(2)
      } else {
        value = "0.00"
      }
      setValue(thisObj, "txtReceiptValue", FormatNumber(value, 2, 12))

      let obj = getData(thisObj, "DelvAgreeprofile_PageData")
      let tempObj
      if (obj.mbCalcing == false) {
        tempObj = CalcConvPct(undefined, FormatNumber(value, 2, 12))
      }

      obj = getData(thisObj, "DelvAgreeprofile_PageData")

      if (obj.mbCalcing == false) {
        Calc100OpenDollar(tempObj !== undefined ? (tempObj.txtReceiptValue == undefined ? FormatNumber(value, 2, 12) : tempObj.txtReceiptValue) : (FormatNumber(value, 2, 12)))
      }


    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.ontxtReceiptValueBlur = ontxtReceiptValueBlur;

  const ontxtpaidDollarsBlur = () => {
    try {
      let value = getValue(thisObj, "txtpaidDollars");

      if (isNumeric(value)) {
        value = Number(value).toFixed(2)
      } else {
        value = "0.00"
      }
      setValue(thisObj, "txtpaidDollars", FormatNumber(value, 2, 12))

      let obj = getData(thisObj, "DelvAgreeprofile_PageData")
      let tempObj
      if (obj.mbCalcing == false) {
        tempObj = CalcConvPct(FormatNumber(value, 2, 12))  //second prop is default
      }

      obj = getData(thisObj, "DelvAgreeprofile_PageData")

      if (obj.mbCalcing == false) {
        CalcConvOpenDollar(tempObj !== undefined ? tempObj?.txtpaidDollars : FormatNumber(value, 2, 12), tempObj?.txtConvApplied)  //second prop is default
      }


    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.ontxtpaidDollarsBlur = ontxtpaidDollarsBlur;

  const ontxtPricePerTonBlur = () => {
    try {
      let value = getValue(thisObj, "txtPricePerTon");

      if (isNumeric(value)) {
        value = Number(value).toFixed(2)
      } else {
        value = "0.00"
      }
      setValue(thisObj, "txtPricePerTon", FormatNumber(value, 2, 7))
    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.ontxtPricePerTonBlur = ontxtPricePerTonBlur;

  //click events 

  const onbtnClick = (index) => {  //buttons in vendorSplit
    try {
      setData(thisObj, "FormLoadComplete", true)
      setData(thisObj, "VendorIndex_delvAgreeProfile", index)
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click");
    } catch (error) {
      errorHandler(error)
    }
  }

  const onbtnColClick = (event) => {  //buttons inside grid
    try {
      let highlight = document.getElementsByClassName("activeRow")
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow")
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement
      let rowID = rowSelected.id.split('-')[1];
      //let gridData = getValue(thisObj, 'gridApplicationInfo')
      let gridData = getData(thisObj, "gridApplicationInfo");
      if (gridData !== undefined && gridData !== null && gridData?.length > 0) {
        let txtTradeInspNum = gridData[rowID].txtcolDelvInsp;
        let cboLocation = gridData[rowID].txtcolLocation;

        let obj = getData(thisObj, "DelvAgreeprofile_PageData")

        let viewSettlementScreenData = [{
          permission: obj.sPermission,
          Inspnum: txtTradeInspNum,
          Locnum: cboLocation,
          LoadTypeBtnCaption: 'Edit'
        }]
        setData(thisObj, 'CreateValue', viewSettlementScreenData);
        goTo(thisObj, "WarehouseReceipts#DelvSettleProfile#DEFAULT#true#Click");
      }

    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.onbtnColClick = onbtnColClick;

  const onbtnAcctDistClick = () => {
    try {
      let buy_pt_id = getValue(thisObj, "ddLocation");
      let agreeNumber = getValue(thisObj, "txtAgreement");
      //let data = getData(thisObj, 'StockTransferData')
      setData(thisObj, 'type', 'DELVAGREE')
      commonContext.setDelvAgree_BuyingPointId(buy_pt_id);
      commonContext.setDelvAgree_Number(agreeNumber);
      goTo(thisObj, "ContractManagement#AccountDistributionActivity#DEFAULT#true#Click")
    }
    catch (err) {
      errorHandler(err)
    }
  }
  thisObj.onbtnAcctDistClick = onbtnAcctDistClick

  const onbtnPaymentsClick = () => {
    try {
      let obj = {
        buy_pt_id: getValue(thisObj, "ddLocation"),
        agreeNumber: getValue(thisObj, "txtAgreement")
      }
      setData(thisObj, 'DataForDelvSettlePaymentView', obj)
      goTo(thisObj, "WarehouseReceipts#DelvSettlePaymentView#DEFAULT#true#Click")
    } catch (error) {
      errorHandler(error);
    }
  }
  thisObj.onbtnPaymentsClick = onbtnPaymentsClick;

  const onbtnPrintClick = () => {
    try {
      let txtAgreementValue = getValue(thisObj, "txtAgreement")

      if (formDirty) {
        let confirm = window.confirm("Changes have not been saved. Changes will not be reflected in print out. Do you wish to contine printing?")

        if (!confirm) {
          return;
        }
      }

      if(txtAgreementValue == undefined || txtAgreementValue == null || txtAgreementValue == ""){
        showMessage(thisObj, "Error: 13 \n Description: type Mismatch \n Routine:frmReportPreviewTrade.rptTradeAgreeWorksheet")
        return
      }
      
      let js = [{
        lstBuyingPointID : getValue(thisObj, "ddLocation"),
        Txt_Agree        : getValue(thisObj, "txtAgreement") == undefined ? "" : getValue(thisObj, "txtAgreement"),
        ReportType       : 'DELV_AGREE_WORKSHEET'
      }]

      setData(thisObj, 'ReportPreviewTradedata', js)
      goTo(thisObj, "Settlements#ReportPreviewTrade#DEFAULT#true#Click");

    } catch (error) {
      errorHandler(error);
    }
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const onbtnDeleteClick = async () => {
    try {
      let modify_tag = "DELETE";
      let agreement_num = getValue(thisObj, "txtAgreement") == undefined ? "" : getValue(thisObj, "txtAgreement");
      let delv_ind = "Y";


      //data collection for delete api

      let ddAgreementStatusValue = getValue(thisObj, "ddAgreementStatus")
      if (ddAgreementStatusValue == "OPEN") {
        ddAgreementStatusValue = "O"
      } else {
        ddAgreementStatusValue = "C"
      }

      let purcH_SALE_IND = getValue(thisObj, "radioDeliveryINout");
      let deL_IN_OUT_IND

      if (purcH_SALE_IND == "2") {
        deL_IN_OUT_IND = "P"
      } else if (purcH_SALE_IND == "1") {
        deL_IN_OUT_IND = "S"
      } else {
        deL_IN_OUT_IND = ""
      }

      if (purcH_SALE_IND == "2") {
        purcH_SALE_IND = "P"
      } else {
        purcH_SALE_IND = "S"
      }

      let agree_date_time = getValue(thisObj, "txtAgreeDate")
      if(agree_date_time == undefined || agree_date_time == null || agree_date_time == ""){
        agree_date_time = (new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000))).toISOString()
      } else {
        agree_date_time = (new Date(new Date(agree_date_time).getTime() - (new Date(agree_date_time).getTimezoneOffset() * 60000))).toISOString()
      }

      let price_per_ton = getValue(thisObj, "txtPricePerTon")
      if (isNumeric(price_per_ton)) {
        price_per_ton = Number(price_per_ton)
      } else {
        price_per_ton = 0
      }
      //data collection for delete api completed

      let objSave = { //if editing here , edit on add button and bformvalid
        "agree_status": ddAgreementStatusValue,
        "purch_sale_ind": purcH_SALE_IND,
        "agree_date_time": agree_date_time,
        "buy_pt_id": getValue(thisObj, "ddLocation"),
        "pnut_type_id": getValue(thisObj, "ddPeanutType") == undefined ? "" : getValue(thisObj, "ddPeanutType"),
        "pnut_variety_id": getValue(thisObj, "ddPeanutVariety") == undefined ? "" : getValue(thisObj, "ddPeanutVariety"),
        "seed_gen": getValue(thisObj, "ddGeneration") == undefined? "" : getValue(thisObj, "ddGeneration"),
        "seg_type": getValue(thisObj, "ddSeg"),
        "oleic_ind": getValue(thisObj, "ddOleic"),
        "edible_oil_ind": getValue(thisObj, "ddEdibleOil") == undefined ? "" : getValue(thisObj, "ddEdibleOil"),
        "grade_pricing_method": "FULL",
        "firm_basis_opt": getValue(thisObj, "ddFirmBasic") == undefined ? "" : getValue(thisObj, "ddFirmBasic"),
        "agreed_lbs": getValue(thisObj, "txtReceiptLbs") == undefined || getValue(thisObj, "txtReceiptLbs") == "" ? 0 : getValue(thisObj, "txtReceiptLbs"),
        "delivered_lbs": getValue(thisObj, "txtAppliedLbs") == undefined || getValue(thisObj, "txtAppliedLbs") == "" ? 0 : getValue(thisObj, "txtAppliedLbs"),
        "price_per_ton": price_per_ton,
        "price_per_lbs": price_per_ton / 2000,
        "agree_remark": getValue(thisObj, "txtAreaComments") == undefined ? "" : getValue(thisObj, "txtAreaComments"),
        "whse_rcpt_num": getValue(thisObj, "ddWhseReceipt") == undefined ? "" : getValue(thisObj, "ddWhseReceipt"),

        "user_id": (sessionStorage.getItem('userid') || ''),
        "modify_tag": modify_tag,
        "delv_agree_dol": getValue(thisObj, "txtpaidDollars") == undefined || getValue(thisObj, "txtpaidDollars") == "" ? 0 : getValue(thisObj, "txtpaidDollars"),
        "delv_app_dol": getValue(thisObj, "txt100PctApplied") == undefined || getValue(thisObj, "txt100PctApplied") == "" ? 0 : getValue(thisObj, "txt100PctApplied"),
        "adjust_lbs": getValue(thisObj, "txtAdjustmentLbs") == undefined || getValue(thisObj, "txtAdjustmentLbs") == "" ? 0 : getValue(thisObj, "txtAdjustmentLbs"),
        "rcpt_value": getValue(thisObj, "txtReceiptValue") == undefined ? 0 : getValue(thisObj, "txtReceiptValue"),
        "rcpt_value_override_ind": getValue(thisObj, "chckBoxOverrideValue") == true ? "Y" : "N",
      }

      for (let i = 1; i <= 20; i++) {
        objSave["spliT_VENDOR" + i] = getValue(thisObj, ["txtVendor" + (i - 1)])
        objSave["spliT_REMIT" + i] = getValue(thisObj, ["ddRemitTo" + (i - 1)]) == undefined ? "" : getValue(thisObj, ["ddRemitTo" + (i - 1)])
        objSave["spliT_SHARE_PCT" + i] = getValue(thisObj, ["txtSharePercentage" + (i - 1)]) == "" || getValue(thisObj, ["txtSharePercentage" + (i - 1)]) == undefined ? 0 : getValue(thisObj, ["txtSharePercentage" + (i - 1)])
      }

      let confirm = window.confirm("Are you sure you wish to delete the Agreement number " + agreement_num + " ?");
      if (!confirm) {
        return;
      }

      setLoading(true);
      let response = await SettlementService.UpdateTradeAgreement(modify_tag, agreement_num, delv_ind, objSave);
      if (response == undefined || response == [] || response.length <= 0 || response.status !== 200) {
        setLoading(false);
        showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")

      } else {
        setData(thisObj, "lbl_firstload", 'D');
        setLoading(false);
        showMessage(thisObj, "Delivery Agreement delete successful!", true);

        document.getElementById("WarehouseReceipts_DelvAgreeProfilePopUp").childNodes[0].click();
      }
    } catch (error) {
      errorHandler(error);
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  const onbtnAddClick = async () => {
    try {
      setLoading(true)
      let obj = getData(thisObj, "DelvAgreeprofile_PageData")
      let App_Flag;

      let validation = await bFormValid()

      if (!validation) {
        setLoading(false)
        return;
      }

      let modify_tag = document.getElementById("btnAdd").innerText;

      if (modify_tag == "add" || modify_tag == "Add" || modify_tag == "ADD") {
        modify_tag = "ADD"
      } else {
        modify_tag = "UPDATE"
      }

      let gridData = getValue(thisObj, "gridApplicationInfo");
      if (gridData !== undefined && gridData !== null && gridData?.length > 0) {
        App_Flag = "Y"
      } else {
        App_Flag = "N"
      }

      let agreement_num = getValue(thisObj, "txtAgreement");
      let purcH_SALE_IND = getValue(thisObj, "radioDeliveryINout");
      let deL_IN_OUT_IND

      if (purcH_SALE_IND == "2") {
        deL_IN_OUT_IND = "P"
      } else if (purcH_SALE_IND == "1") {
        deL_IN_OUT_IND = "S"
      } else {
        deL_IN_OUT_IND = ""
      }

      if (purcH_SALE_IND == "2") {
        purcH_SALE_IND = "P"
      } else {
        purcH_SALE_IND = "S"
      }


      let price_per_ton = getValue(thisObj, "txtPricePerTon")
      if (isNumeric(price_per_ton)) {
        price_per_ton = Number(price_per_ton)
      } else {
        price_per_ton = 0
      }

      let status_flag = obj.status_flag;
      if (status_flag == "Open"  || status_flag == "OPEN") {
        status_flag = "O"
      } else {
        status_flag = "C"
      }

      let ddAgreementStatusValue = getValue(thisObj, "ddAgreementStatus")
      if (ddAgreementStatusValue == "Open" || ddAgreementStatusValue == "OPEN") {
        ddAgreementStatusValue = "O"
      } else {
        ddAgreementStatusValue = "C"
      }

      let agree_date_time = getValue(thisObj, "txtAgreeDate")
      if(agree_date_time == undefined || agree_date_time == null || agree_date_time == ""){
        agree_date_time = (new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000))).toISOString()
      } else {
        agree_date_time = (new Date(new Date(agree_date_time).getTime() - (new Date(agree_date_time).getTimezoneOffset() * 60000))).toISOString()
      }

      let dataObj = {  //if editing here , edit on delete button too
        "agreE_STATUS": ddAgreementStatusValue, //agreement_num,
        "purcH_SALE_IND": purcH_SALE_IND,
        "agreE_DATE_TIME": agree_date_time,
        "buY_PT_ID": getValue(thisObj, "ddLocation"),
        "pnuT_TYPE_ID": getValue(thisObj, "ddPeanutType"),
        "pnuT_VARIETY_ID": getValue(thisObj, "ddPeanutVariety"),
        "seeD_GEN": getValue(thisObj, "ddGeneration"),
        "seG_TYPE": getValue(thisObj, "ddSeg"),
        "oleiC_IND": getValue(thisObj, "ddOleic"),
        "ediblE_OIL_IND": getValue(thisObj, "ddEdibleOil"),
        "gradE_PRICING_METHOD": "FULL",
        "firM_BASIS_OPT": getValue(thisObj, "ddFirmBasic"),
        "agreeD_LBS": getValue(thisObj, "txtReceiptLbs") == undefined || getValue(thisObj, "txtReceiptLbs") == "" ? 0 : getValue(thisObj, "txtReceiptLbs"),
        "delivereD_LBS": getValue(thisObj, "txtAppliedLbs") == undefined || getValue(thisObj, "txtAppliedLbs") == "" ? 0 : getValue(thisObj, "txtAppliedLbs"),
        "pricE_PER_TON": price_per_ton,
        "pricE_PER_LBS": price_per_ton / 2000,
        "agreE_REMARK": getValue(thisObj, "txtAreaComments") == undefined ? "" : getValue(thisObj, "txtAreaComments"),
        "whsE_RCPT_NUM": getValue(thisObj, "ddWhseReceipt") == undefined ? "" : getValue(thisObj, "ddWhseReceipt"),
        "useR_ID": (sessionStorage.getItem('userid') || ''),
        "modifY_TAG": modify_tag,
        "delV_AGREE_DOL": getValue(thisObj, "txtpaidDollars") == undefined || getValue(thisObj, "txtpaidDollars") == "" ? 0 : getValue(thisObj, "txtpaidDollars"),
        "delV_APP_DOL": getValue(thisObj, "txt100PctApplied") == undefined || getValue(thisObj, "txt100PctApplied") == "" ? 0 : getValue(thisObj, "txt100PctApplied"),
        "adjusT_LBS": getValue(thisObj, "txtAdjustmentLbs") == undefined || getValue(thisObj, "txtAdjustmentLbs") == "" ? 0 : getValue(thisObj, "txtAdjustmentLbs"),
        "rcpT_VALUE": getValue(thisObj, "txtReceiptValue") == undefined ? 0 : getValue(thisObj, "txtReceiptValue"),
        "rcpT_VALUE_OVERRIDE_IND": getValue(thisObj, "chckBoxOverrideValue") == true ? "Y" : "N",
        "statuS_FLAG": status_flag,
        "uid": (sessionStorage.getItem('userid') || ''),
        "rtP_PROCEEDS":  getValue(thisObj, "txt100open") == undefined || getValue(thisObj, "txt100open") == "" ? 0 : getValue(thisObj, "txt100open"),
        "deL_IN_OUT_IND": deL_IN_OUT_IND
      }

      for (let i = 1; i <= 20; i++) {
        dataObj["spliT_VENDOR" + i] = getValue(thisObj, ["txtVendor" + (i - 1)])
        dataObj["spliT_REMIT" + i] = getValue(thisObj, ["ddRemitTo" + (i - 1)]) == undefined ? "" : getValue(thisObj, ["ddRemitTo" + (i - 1)])
        dataObj["spliT_SHARE_PCT" + i] = getValue(thisObj, ["txtSharePercentage" + (i - 1)]) == "" || getValue(thisObj, ["txtSharePercentage" + (i - 1)]) == undefined ? 0 : getValue(thisObj, ["txtSharePercentage" + (i - 1)])
      }

      let response = await WarehouseReceiptService.UpdateDeliveryAgreement(modify_tag, agreement_num, "Y", dataObj);

      let newAgreeNumber;

      if (response.status !== undefined && response.status == 200) {
        alert("Delivery Agreement " + document.getElementById("btnAdd").innerText + " successful!")
        obj.lblStatus = document.getElementById("btnAdd").innerText
        newAgreeNumber = response.result
        setData(thisObj, "txtAgreement_DelvAgreeProfile", newAgreeNumber)
        //alert(response.message);
      } else {
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists." + response.message !== undefined ? response.message : "")
        setLoading(false)
        return;
      }



      //api for open and complete status
      if (getValue(thisObj, "ddAgreementStatus") == "COMPLETE" && obj.status_flag !== getValue(thisObj, "ddAgreementStatus")) {
        let buy_pt_id = ""
        let whse_rcpt_num = ""
        let agreement_num = newAgreeNumber !== undefined ? newAgreeNumber : getValue(thisObj, "txtAgreement");
        let response2 = await WarehouseReceiptService.RetrieveDeliveryOutAgreementWareHouseDetails(buy_pt_id, whse_rcpt_num, agreement_num)

        if (response2 !== undefined && response2.length > 0) {
          for (let i = 0; i < response2.length; i++) {
            obj.lblWhseNum = response2[i].whse_rcpt_num

            let dataObj2 = {
              "user_id": (sessionStorage.getItem('userid') || ''),
              "delv_out_complete_date": (new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000))).toISOString()
            }

            buy_pt_id = getValue(thisObj, "ddLocation")
            let response3 = await WarehouseReceiptService.UpdateLoadOutDeliveryComplete(buy_pt_id, obj.lblWhseNum, dataObj2)

            if (response3 == undefined || response3.status !== 200) {
              alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists." + response3.message !== undefined ? response3.message : "")
              setLoading(false)
              return;
            }

          }

        }


      }


      if (getValue(thisObj, "ddAgreementStatus") == "OPEN" && obj.status_flag !== getValue(thisObj, "ddAgreementStatus")) {
        let buy_pt_id = ""
        let whse_rcpt_num = ""
        let agreement_num = newAgreeNumber !== undefined ? newAgreeNumber : getValue(thisObj, "txtAgreement");
        let response2 = await WarehouseReceiptService.RetrieveDeliveryOutAgreementWareHouseDetails(buy_pt_id, whse_rcpt_num, agreement_num)

        if (response2 !== undefined && response2.length > 0) {
          for (let i = 0; i < response2.length; i++) {
            obj.lblWhseNum = response2[i].whse_rcpt_num

            let dataObj2 = {
              "user_id": (sessionStorage.getItem('userid') || '')
            }

            buy_pt_id = getValue(thisObj, "ddLocation")
            let response3 = await WarehouseReceiptService.UpdateLoadOutDeliveryCompleteUndo(buy_pt_id, obj.lblWhseNum, dataObj2)

            if (response3 == undefined || response3.status !== 200) {
              alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists." + response3.message !== undefined ? response3.message : "")
              setLoading(false)
              return;
            }

          }

        }


      }


      if (getValue(thisObj, "ddAgreementStatus") == "COMPLETE") {
        obj.status_flag = "COMPLETE"
      } else {
        obj.status_flag = "OPEN"
      }

      obj.lblStatus = "Update"
      document.getElementById("btnAdd").innerText = "Update"

      setData(thisObj, "DelvAgreeprofile_PageData", obj)


      // EnableDisableControls() //not needed as already inside bfillform
      bFillForm()

      setFormDirty(false)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick;

  const onbtncancelClick = () => {
    try {
      document.getElementById("WarehouseReceipts_DelvAgreeProfilePopUp").childNodes[0].click();
    } catch (error) {
      errorHandler(error);
    }
  }
  thisObj.onbtncancelClick = onbtncancelClick;


  //vendor Split for loop events
  for (let i = 0; i < 20; i++) {
    thisObj["onbtn" + i + "Click"] = () => { onbtnClick(i) };
    thisObj["ontxtVendor" + i + "Change"] = () => { vendorNumberChange() };
    thisObj["ontxtVendor" + i + "Blur"] = () => { vendorNumberBlur(i) };
    thisObj["onddRemitTo" + i + "Change"] = () => { remitDropdownsChange() };
    thisObj["ontxtSharePercentage" + i + "Blur"] = () => { txtSharePctChange(i) };
  }

  //additional functions

  function validateTextBox(data) {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }

  function customFormat(number) {
    // Convert the number to a string
    let formattedValue = number.toString();

    // Split the string into integer and decimal parts
    let parts = formattedValue.split(".");

    // Format the integer part
    parts[0] = parts[0].replace(/\d(?=(\d{3})+$)/g, "$& ");

    // Join the integer and decimal parts back together
    formattedValue = parts.join(".");

    return formattedValue;
  }

  function FormatNumber(number, toFixedValue = 2, lengthofNumber = Infinity) {
    if (number == undefined || number == null || number == "" || isNaN(number)) {
      return '';
    }
    if (number)
      number = Number(number);

    if (lengthofNumber !== Infinity) {
      let modifiedNumber = number.toFixed(toFixedValue)
      if (modifiedNumber.length <= lengthofNumber) {
        return modifiedNumber;
      }
      return modifiedNumber.slice(0, lengthofNumber)
    }

    return (number.toFixed(toFixedValue)).toString()

  }

  function isNumeric(value) {
    // Use the isNaN function to check if the value is a valid number
    // Also, use the typeof operator to ensure the value is not a non-numeric string
    return !isNaN(parseFloat(value)) && isFinite(value) && typeof value !== 'boolean';
  }

  function toPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  function errorHandler(err) {
    setLoading(false);
    showMessage(thisObj, err instanceof EvalError ? err.message : 'Something went wrong. Please try again.')
  }

  const setTabIndex = () => {
    let elements = [
      'radioDeliveryINout0',
      'ddLocation',
      'txtAgreeDateParent',
      'ddWhseReceipt',
      'ddAgreementStatus',
      'ddPeanutType',
      'ddPeanutVariety',
      'ddGeneration',
      'ddSeg',
      'ddOleic',
      'txtVendor0',
      'btn0',
      'ddRemitTo0',
      'txtSharePercentage0',
      'txtVendor1',
      'btn1',
      'ddRemitTo1',
      'txtSharePercentage1',
      'txtVendor2',
      'btn2',
      'ddRemitTo2',
      'txtSharePercentage2',
      'txtVendor3',
      'btn3',
      'ddRemitTo3',
      'txtSharePercentage3',
      'ddFirmBasic',
      'chckBoxOverrideValue',
      'txtAdjustmentLbs',
      'txtpaidDollars',
      'txtPricePerTon',
      'btnPrint',
      'btnAdd',
      'btnDelete',
      'btnCancel',
      'txtAreaComments'
    ]
    elements.map((element, index) => {
      let tabElement = document.querySelectorAll(".modal")[1].querySelector(`#${element}`);
      if(tabElement){
        tabElement.tabIndex = index + 1;
      }
    })
  }
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        //setValues,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        //thisObj.setValues = setValues = setValues;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DelvAgreeProfile*/}

              {/* END_USER_CODE-USER_BEFORE_DelvAgreeProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDelvAgree*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDelvAgree*/}

              <GroupBoxWidget conf={state.grpbxDelvAgree} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxDelieveryInOut*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDelieveryInOut*/}
                <GroupBoxWidget conf={state.grpbxLeft} screenConf={state}>
                  <GroupBoxWidget
                    conf={state.grpbxDelieveryInOut}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_txtAgreementDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtAgreementDate*/}

                    {/* <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAgreementDate}
                    screenConf={state}
                  ></DateWidget> */}
                    {/* START_USER_CODE-USER_AFTER_txtAgreementDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtAgreementDate*/}
                    {/* START_USER_CODE-USER_BEFORE_radioDeliveryINout*/}

                    {/* END_USER_CODE-USER_BEFORE_radioDeliveryINout*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioDeliveryINout}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioDeliveryINout*/}

                    {/* END_USER_CODE-USER_AFTER_radioDeliveryINout*/}
                    {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                    {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddLocation}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                    {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                    {/* START_USER_CODE-USER_BEFORE_txtAgreement*/}

                    {/* END_USER_CODE-USER_BEFORE_txtAgreement*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtAgreement}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtAgreement*/}

                    {/* END_USER_CODE-USER_AFTER_txtAgreement*/}
                    {/* START_USER_CODE-USER_BEFORE_txtAgreeDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtAgreeDate*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtAgreeDate}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtAgreeDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtAgreeDate*/}
                    {/* START_USER_CODE-USER_BEFORE_ddWhseReceipt*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseReceipt*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseReceipt}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseReceipt*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseReceipt*/}
                    {/* START_USER_CODE-USER_BEFORE_ddAgreementStatus*/}

                    {/* END_USER_CODE-USER_BEFORE_ddAgreementStatus*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddAgreementStatus}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddAgreementStatus*/}

                    {/* END_USER_CODE-USER_AFTER_ddAgreementStatus*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxDelieveryInOut*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxDelieveryInOut*/}
                  <LabelWidget
                    values={values}
                    conf={state.lblNote}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplits*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplits*/}
                  <GroupBoxWidget
                    conf={state.grpbxVendorSplits}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_grpbxLabels*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxLabels*/}

                    <GroupBoxWidget conf={state.grpbxLabels} screenConf={state}>
                      {/* START_USER_CODE-USER_BEFORE_lblVendorSplits*/}

                      {/* END_USER_CODE-USER_BEFORE_lblVendorSplits*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblVendorSplits}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblVendorSplits*/}

                      {/* END_USER_CODE-USER_AFTER_lblVendorSplits*/}
                      {/* START_USER_CODE-USER_BEFORE_lblVendor*/}

                      {/* END_USER_CODE-USER_BEFORE_lblVendor*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblVendor}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblVendor*/}

                      {/* END_USER_CODE-USER_AFTER_lblVendor*/}
                      {/* START_USER_CODE-USER_BEFORE_lblRemitTo*/}

                      {/* END_USER_CODE-USER_BEFORE_lblRemitTo*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblRemitTo}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblRemitTo*/}

                      {/* END_USER_CODE-USER_AFTER_lblRemitTo*/}
                      {/* START_USER_CODE-USER_BEFORE_lblSharePercentage*/}

                      {/* END_USER_CODE-USER_BEFORE_lblSharePercentage*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblSharePercentage}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblSharePercentage*/}

                      {/* END_USER_CODE-USER_AFTER_lblSharePercentage*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxLabels*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxLabels*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitsRows*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitsRows*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitsRows}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow0*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow0*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow0}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor0*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor0*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor0}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor0*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor0*/}
                        {/* START_USER_CODE-USER_BEFORE_btn0*/}

                        {/* END_USER_CODE-USER_BEFORE_btn0*/}

                        <ButtonWidget
                          conf={state.btn0}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn0*/}

                        {/* END_USER_CODE-USER_AFTER_btn0*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo0*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo0*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo0}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo0*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo0*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage0*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage0*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage0}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage0*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage0*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX0*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX0*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX0}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX0*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX0*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow0*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow0*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow1*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow1*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow1}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor1*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor1*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor1}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor1*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor1*/}
                        {/* START_USER_CODE-USER_BEFORE_btn1*/}

                        {/* END_USER_CODE-USER_BEFORE_btn1*/}

                        <ButtonWidget
                          conf={state.btn1}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn1*/}

                        {/* END_USER_CODE-USER_AFTER_btn1*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo1*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo1*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo1}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo1*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo1*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage1*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage1*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage1}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage1*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage1*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX1*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX1*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX1}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX1*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX1*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow1*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow1*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow2*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow2*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow2}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor2*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor2*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor2}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor2*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor2*/}
                        {/* START_USER_CODE-USER_BEFORE_btn2*/}

                        {/* END_USER_CODE-USER_BEFORE_btn2*/}

                        <ButtonWidget
                          conf={state.btn2}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn2*/}

                        {/* END_USER_CODE-USER_AFTER_btn2*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo2*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo2*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo2}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo2*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo2*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage2*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage2*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage2}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage2*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage2*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX2*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX2*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX2}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX2*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX2*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow2*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow2*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow3*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow3*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow3}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor3*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor3*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor3}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor3*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor3*/}
                        {/* START_USER_CODE-USER_BEFORE_btn3*/}

                        {/* END_USER_CODE-USER_BEFORE_btn3*/}

                        <ButtonWidget
                          conf={state.btn3}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn3*/}

                        {/* END_USER_CODE-USER_AFTER_btn3*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo3*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo3*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo3}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo3*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo3*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage3*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage3*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage3}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage3*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage3*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX3*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX3*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX3}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX3*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX3*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow3*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow3*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow4*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow4*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow4}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor4*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor4*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor4}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor4*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor4*/}
                        {/* START_USER_CODE-USER_BEFORE_btn4*/}

                        {/* END_USER_CODE-USER_BEFORE_btn4*/}

                        <ButtonWidget
                          conf={state.btn4}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn4*/}

                        {/* END_USER_CODE-USER_AFTER_btn4*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo4*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo4*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo4}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo4*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo4*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage4*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage4*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage4}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage4*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage4*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX4*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX4*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX4}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX4*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX4*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow4*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow4*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow5*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow5*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow5}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor5*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor5*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor5}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor5*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor5*/}
                        {/* START_USER_CODE-USER_BEFORE_btn5*/}

                        {/* END_USER_CODE-USER_BEFORE_btn5*/}

                        <ButtonWidget
                          conf={state.btn5}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn5*/}

                        {/* END_USER_CODE-USER_AFTER_btn5*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo5*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo5*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo5}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo5*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo5*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage5*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage5*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage5}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage5*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage5*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX5*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX5*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX5}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX5*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX5*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow5*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow5*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow6u*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow6u*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow6}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor6*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor6*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor6}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor6*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor6*/}
                        {/* START_USER_CODE-USER_BEFORE_btn6*/}

                        {/* END_USER_CODE-USER_BEFORE_btn6*/}

                        <ButtonWidget
                          conf={state.btn6}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn6*/}

                        {/* END_USER_CODE-USER_AFTER_btn6*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo6*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo6*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo6}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo6*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo6*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage6*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage6*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage6}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage6*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage6*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX6*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX6*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX6}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX6*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX6*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow6u*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow6u*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow7*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow7*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow7}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor7*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor7*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor7}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor7*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor7*/}
                        {/* START_USER_CODE-USER_BEFORE_btn7*/}

                        {/* END_USER_CODE-USER_BEFORE_btn7*/}

                        <ButtonWidget
                          conf={state.btn7}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn7*/}

                        {/* END_USER_CODE-USER_AFTER_btn7*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo7*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo7*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo7}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo7*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo7*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage7*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage7*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage7}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage7*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage7*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX7*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX7*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX7}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX7*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX7*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow7*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow7*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow8*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow8*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow8}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor8*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor8*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor8}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor8*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor8*/}
                        {/* START_USER_CODE-USER_BEFORE_btn8*/}

                        {/* END_USER_CODE-USER_BEFORE_btn8*/}

                        <ButtonWidget
                          conf={state.btn8}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn8*/}

                        {/* END_USER_CODE-USER_AFTER_btn8*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo8*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo8*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo8}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo8*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo8*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage8*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage8*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage8}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage8*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage8*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX8*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX8*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX8}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX8*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX8*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow8*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow8*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow9*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow9*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow9}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor9*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor9*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor9}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor9*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor9*/}
                        {/* START_USER_CODE-USER_BEFORE_btn9*/}

                        {/* END_USER_CODE-USER_BEFORE_btn9*/}

                        <ButtonWidget
                          conf={state.btn9}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn9*/}

                        {/* END_USER_CODE-USER_AFTER_btn9*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo9*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo9*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo9}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo9*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo9*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage9*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage9*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage9}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage9*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage9*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX9*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX9*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX9}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX9*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX9*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow9*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow9*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow10*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow10*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow10}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor10*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor10*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor10}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor10*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor10*/}
                        {/* START_USER_CODE-USER_BEFORE_btn10*/}

                        {/* END_USER_CODE-USER_BEFORE_btn10*/}

                        <ButtonWidget
                          conf={state.btn10}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn10*/}

                        {/* END_USER_CODE-USER_AFTER_btn10*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo10*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo10*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo10}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo10*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo10*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage10*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage10*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage10}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage10*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage10*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX10*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX10*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX10}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX10*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX10*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow10*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow10*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow11*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow11*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow11}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor11*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor11*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor11}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor11*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor11*/}
                        {/* START_USER_CODE-USER_BEFORE_btn11*/}

                        {/* END_USER_CODE-USER_BEFORE_btn11*/}

                        <ButtonWidget
                          conf={state.btn11}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn11*/}

                        {/* END_USER_CODE-USER_AFTER_btn11*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo11*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo11*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo11}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo11*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo11*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage11*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage11*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage11}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage11*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage11*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX11*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX11*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX11}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX11*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX11*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow11*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow11*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow12*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow12*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow12}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor12*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor12*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor12}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor12*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor12*/}
                        {/* START_USER_CODE-USER_BEFORE_btn12*/}

                        {/* END_USER_CODE-USER_BEFORE_btn12*/}

                        <ButtonWidget
                          conf={state.btn12}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn12*/}

                        {/* END_USER_CODE-USER_AFTER_btn12*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo12*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo12*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo12}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo12*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo12*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage12*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage12*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage12}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage12*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage12*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX12*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX12*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX12}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX12*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX12*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow12*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow12*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow13*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow13*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow13}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor13*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor13*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor13}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor13*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor13*/}
                        {/* START_USER_CODE-USER_BEFORE_btn13*/}

                        {/* END_USER_CODE-USER_BEFORE_btn13*/}

                        <ButtonWidget
                          conf={state.btn13}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn13*/}

                        {/* END_USER_CODE-USER_AFTER_btn13*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo13*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo13*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo13}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo13*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo13*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage13*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage13*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage13}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage13*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage13*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX13*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX13*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX13}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX13*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX13*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow13*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow13*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow14*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow14*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow14}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor14*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor14*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor14}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor14*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor14*/}
                        {/* START_USER_CODE-USER_BEFORE_btn14*/}

                        {/* END_USER_CODE-USER_BEFORE_btn14*/}

                        <ButtonWidget
                          conf={state.btn14}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn14*/}

                        {/* END_USER_CODE-USER_AFTER_btn14*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo14*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo14*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo14}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo14*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo14*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage14*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage14*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage14}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage14*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage14*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX14*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX14*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX14}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX14*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX14*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow14*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow14*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow15*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow15*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow15}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor15*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor15*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor15}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor15*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor15*/}
                        {/* START_USER_CODE-USER_BEFORE_btn15*/}

                        {/* END_USER_CODE-USER_BEFORE_btn15*/}

                        <ButtonWidget
                          conf={state.btn15}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn15*/}

                        {/* END_USER_CODE-USER_AFTER_btn15*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo15*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo15*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo15}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo15*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo15*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage15*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage15*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage15}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage15*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage15*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX15*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX15*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX15}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX15*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX15*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow15*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow15*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow16*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow16*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow16}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor16*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor16*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor16}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor16*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor16*/}
                        {/* START_USER_CODE-USER_BEFORE_btn16*/}

                        {/* END_USER_CODE-USER_BEFORE_btn16*/}

                        <ButtonWidget
                          conf={state.btn16}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn16*/}

                        {/* END_USER_CODE-USER_AFTER_btn16*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo16*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo16*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo16}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo16*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo16*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage16*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage16*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage16}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage16*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage16*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX16*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX16*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX16}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX16*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX16*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow16*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow16*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow17*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow17*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow17}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor17*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor17*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor17}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor17*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor17*/}
                        {/* START_USER_CODE-USER_BEFORE_btn17*/}

                        {/* END_USER_CODE-USER_BEFORE_btn17*/}

                        <ButtonWidget
                          conf={state.btn17}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn17*/}

                        {/* END_USER_CODE-USER_AFTER_btn17*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo17*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo17*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo17}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo17*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo17*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage17*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage17*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage17}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage17*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage17*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX17*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX17*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX17}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX17*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX17*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow17*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow17*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow18*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow18*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow18}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor18*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor18*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor18}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor18*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor18*/}
                        {/* START_USER_CODE-USER_BEFORE_btn18*/}

                        {/* END_USER_CODE-USER_BEFORE_btn18*/}

                        <ButtonWidget
                          conf={state.btn18}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn18*/}

                        {/* END_USER_CODE-USER_AFTER_btn18*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo18*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo18*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo18}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo18*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo18*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage18*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage18*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage18}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage18*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage18*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX18*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX18*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX18}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX18*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX18*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow18*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow18*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow19*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow19*/}

                      <GroupBoxWidget
                        conf={state.grpbxVendorSplitRow19}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtVendor19*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVendor19*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVendor19}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVendor19*/}

                        {/* END_USER_CODE-USER_AFTER_txtVendor19*/}
                        {/* START_USER_CODE-USER_BEFORE_btn19*/}

                        {/* END_USER_CODE-USER_BEFORE_btn19*/}

                        <ButtonWidget
                          conf={state.btn19}
                          screenConf={state}
                          onClick={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></ButtonWidget>
                        {/* START_USER_CODE-USER_AFTER_btn19*/}

                        {/* END_USER_CODE-USER_AFTER_btn19*/}
                        {/* START_USER_CODE-USER_BEFORE_ddRemitTo19*/}

                        {/* END_USER_CODE-USER_BEFORE_ddRemitTo19*/}

                        <DropDownWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.ddRemitTo19}
                          screenConf={state}
                        ></DropDownWidget>
                        {/* START_USER_CODE-USER_AFTER_ddRemitTo19*/}

                        {/* END_USER_CODE-USER_AFTER_ddRemitTo19*/}
                        {/* START_USER_CODE-USER_BEFORE_txtSharePercentage19*/}

                        {/* END_USER_CODE-USER_BEFORE_txtSharePercentage19*/}

                        <TextBoxWidget
                          onChange={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtSharePercentage19}
                          screenConf={state}
                          onPaste={event =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtSharePercentage19*/}

                        {/* END_USER_CODE-USER_AFTER_txtSharePercentage19*/}
                        {/* START_USER_CODE-USER_BEFORE_lblXX19*/}

                        {/* END_USER_CODE-USER_BEFORE_lblXX19*/}

                        <LabelWidget
                          values={values}
                          conf={state.lblXX19}
                          screenConf={state}
                        ></LabelWidget>
                        {/* START_USER_CODE-USER_AFTER_lblXX19*/}

                        {/* END_USER_CODE-USER_AFTER_lblXX19*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow19*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow19*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitsRows*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitsRows*/}
                  </GroupBoxWidget>

                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplits*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplits*/}
                </GroupBoxWidget>
                <GroupBoxWidget conf={state.grpbxRight} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_grpbxPeanutInfo*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPeanutInfo*/}

                  <GroupBoxWidget conf={state.grpbxPeanutInfo} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_lblPeanutInfo*/}

                    {/* END_USER_CODE-USER_BEFORE_lblPeanutInfo*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblPeanutInfo}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblPeanutInfo*/}

                    {/* END_USER_CODE-USER_AFTER_lblPeanutInfo*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPeanutType}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                    {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                    {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                    {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddSeg}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                    {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPeanutVariety}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                    {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                    {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                    {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddOleic}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                    {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                    {/* START_USER_CODE-USER_BEFORE_ddGeneration*/}

                    {/* END_USER_CODE-USER_BEFORE_ddGeneration*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddGeneration}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddGeneration*/}

                    {/* END_USER_CODE-USER_AFTER_ddGeneration*/}
                    {/* START_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                    {/* END_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddEdibleOil}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddEdibleOil*/}

                    {/* END_USER_CODE-USER_AFTER_ddEdibleOil*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPeanutInfo*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPeanutInfo*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPricingInfo*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPricingInfo*/}

                  <GroupBoxWidget
                    conf={state.grpbxPricingInfo}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblPricingInfo*/}

                    {/* END_USER_CODE-USER_BEFORE_lblPricingInfo*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblPricingInfo}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblPricingInfo*/}

                    {/* END_USER_CODE-USER_AFTER_lblPricingInfo*/}
                    {/* START_USER_CODE-USER_BEFORE_chckBoxOverrideValue*/}

                    {/* END_USER_CODE-USER_BEFORE_chckBoxOverrideValue*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chckBoxOverrideValue}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chckBoxOverrideValue*/}

                    {/* END_USER_CODE-USER_AFTER_chckBoxOverrideValue*/}
                    {/* START_USER_CODE-USER_BEFORE_ddFirmBasic*/}

                    {/* END_USER_CODE-USER_BEFORE_ddFirmBasic*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddFirmBasic}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddFirmBasic*/}

                    {/* END_USER_CODE-USER_AFTER_ddFirmBasic*/}
                    {/* START_USER_CODE-USER_BEFORE_txtReceiptValue*/}

                    {/* END_USER_CODE-USER_BEFORE_txtReceiptValue*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtReceiptValue}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtReceiptValue*/}

                    {/* END_USER_CODE-USER_AFTER_txtReceiptValue*/}
                    {/* START_USER_CODE-USER_BEFORE_txtReceiptLbs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtReceiptLbs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtReceiptLbs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtReceiptLbs*/}

                    {/* END_USER_CODE-USER_AFTER_txtReceiptLbs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtpaidDollars*/}

                    {/* END_USER_CODE-USER_BEFORE_txtpaidDollars*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtpaidDollars}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtpaidDollars*/}

                    {/* END_USER_CODE-USER_AFTER_txtpaidDollars*/}
                    {/* START_USER_CODE-USER_BEFORE_txtAdjustmentLbs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtAdjustmentLbs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtAdjustmentLbs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtAdjustmentLbs*/}

                    {/* END_USER_CODE-USER_AFTER_txtAdjustmentLbs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPricePerTon*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPricePerTon*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPricePerTon}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPricePerTon*/}

                    {/* END_USER_CODE-USER_AFTER_txtPricePerTon*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotalAdjustLbs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalAdjustLbs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalAdjustLbs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalAdjustLbs*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalAdjustLbs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtConvApplied*/}

                    {/* END_USER_CODE-USER_BEFORE_txtConvApplied*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtConvApplied}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtConvApplied*/}

                    {/* END_USER_CODE-USER_AFTER_txtConvApplied*/}
                    {/* START_USER_CODE-USER_BEFORE_txtConversion*/}

                    {/* END_USER_CODE-USER_BEFORE_txtConversion*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtConversion}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtConversion*/}

                    {/* END_USER_CODE-USER_AFTER_txtConversion*/}
                    {/* START_USER_CODE-USER_BEFORE_txtConvopen*/}

                    {/* END_USER_CODE-USER_BEFORE_txtConvopen*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtConvopen}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtConvopen*/}

                    {/* END_USER_CODE-USER_AFTER_txtConvopen*/}
                    {/* START_USER_CODE-USER_BEFORE_txtAppliedLbs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtAppliedLbs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtAppliedLbs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtAppliedLbs*/}

                    {/* END_USER_CODE-USER_AFTER_txtAppliedLbs*/}
                    {/* START_USER_CODE-USER_BEFORE_txt100PctApplied*/}

                    {/* END_USER_CODE-USER_BEFORE_txt100PctApplied*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txt100PctApplied}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txt100PctApplied*/}

                    {/* END_USER_CODE-USER_AFTER_txt100PctApplied*/}
                    {/* START_USER_CODE-USER_BEFORE_txt100AdjustLbs*/}

                    {/* END_USER_CODE-USER_BEFORE_txt100AdjustLbs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txt100AdjustLbs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txt100AdjustLbs*/}

                    {/* END_USER_CODE-USER_AFTER_txt100AdjustLbs*/}
                    {/* START_USER_CODE-USER_BEFORE_txt100Adjust*/}

                    {/* END_USER_CODE-USER_BEFORE_txt100Adjust*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txt100Adjust}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txt100Adjust*/}

                    {/* END_USER_CODE-USER_AFTER_txt100Adjust*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOpenLbs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOpenLbs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOpenLbs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOpenLbs*/}

                    {/* END_USER_CODE-USER_AFTER_txtOpenLbs*/}
                    {/* START_USER_CODE-USER_BEFORE_txt100open*/}

                    {/* END_USER_CODE-USER_BEFORE_txt100open*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txt100open}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txt100open*/}

                    {/* END_USER_CODE-USER_AFTER_txt100open*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPricingInfo*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPricingInfo*/}
                </GroupBoxWidget>

                {/* START_USER_CODE-USER_BEFORE_lblApplicationInfo*/}

                {/* END_USER_CODE-USER_BEFORE_lblApplicationInfo*/}

                <LabelWidget
                  values={values}
                  conf={state.lblApplicationInfo}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblApplicationInfo*/}

                {/* END_USER_CODE-USER_AFTER_lblApplicationInfo*/}
                {/* START_USER_CODE-USER_BEFORE_gridApplicationInfo*/}

                {/* END_USER_CODE-USER_BEFORE_gridApplicationInfo*/}

                <GridWidget
                  conf={state.gridApplicationInfo}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridApplicationInfo}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridApplicationInfo*/}

                {/* END_USER_CODE-USER_AFTER_gridApplicationInfo*/}
                {/* START_USER_CODE-USER_BEFORE_txtAreaComments*/}

                {/* END_USER_CODE-USER_BEFORE_txtAreaComments*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAreaComments}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtAreaComments*/}

                {/* END_USER_CODE-USER_AFTER_txtAreaComments*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDelvAgree*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDelvAgree*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblCropYear*/}

                {/* END_USER_CODE-USER_BEFORE_lblCropYear*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCropYear}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCropYear*/}

                {/* END_USER_CODE-USER_AFTER_lblCropYear*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_btnPayments*/}

                {/* END_USER_CODE-USER_BEFORE_btnPayments*/}

                <ButtonWidget
                  conf={state.btnPayments}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPayments*/}

                {/* END_USER_CODE-USER_AFTER_btnPayments*/}
                {/* START_USER_CODE-USER_BEFORE_btnAcctDist*/}

                {/* END_USER_CODE-USER_BEFORE_btnAcctDist*/}

                <ButtonWidget
                  conf={state.btnAcctDist}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAcctDist*/}

                {/* END_USER_CODE-USER_AFTER_btnAcctDist*/}
                {/* START_USER_CODE-USER_BEFORE_btncancel*/}

                {/* END_USER_CODE-USER_BEFORE_btncancel*/}

                <ButtonWidget
                  conf={state.btncancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btncancel*/}

                {/* END_USER_CODE-USER_AFTER_btncancel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_DelvAgreeProfile*/}

              {/* END_USER_CODE-USER_AFTER_DelvAgreeProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_DelvAgreeProfile);
