/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  LabelWidget,
  CheckboxWidget,
  setValue,
  getValue,
  getData,
  setData,
  enable,
  disable,
  goTo,
} from "../../shared/WindowImports";

import "./FreightRateProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { toPascalCase } from "../../../modules/Common/Constants"
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FreightRateProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  // START_USER_CODE-USER_PROPERTIES
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  
  let strflg = "";  
  let vsfrates_Flag = false;
  let lblOrgBPT = ""
  let lblEffectiveDate = ""
  let buyingPtText = ""
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FreightRateProfile",
    windowName: "FreightRateProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FreightRateProfile",
    // START_USER_CODE-USER_FreightRateProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Freight Rate Profile",
      scrCode: "PN0250E",
    },
    // END_USER_CODE-USER_FreightRateProfile_PROPERTIES
    btnOK: {
      name: "btnOK",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",
      // START_USER_CODE-USER_btnOK_PROPERTIES

      // END_USER_CODE-USER_btnOK_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCopyRate: {
      name: "btnCopyRate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Copy Rate",
      CharWidth: "21",
      // START_USER_CODE-USER_btnCopyRate_PROPERTIES

      // END_USER_CODE-USER_btnCopyRate_PROPERTIES
    },
    btnCpyCarr: {
      name: "btnCpyCarr",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Copy Carrier",
      CharWidth: "26",
      // START_USER_CODE-USER_btnCpyCarr_PROPERTIES

      // END_USER_CODE-USER_btnCpyCarr_PROPERTIES
    },
    btnCpyLoc: {
      name: "btnCpyLoc",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Copy Location",
      CharWidth: "28",
      // START_USER_CODE-USER_btnCpyLoc_PROPERTIES

      // END_USER_CODE-USER_btnCpyLoc_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxFreightProf",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    chkbxNoLoad: {
      name: "chkbxNoLoad",
      type: "CheckBoxWidget",
      parent: "grpbxFreightProf",
      Label: "No Load",
      ColSpan: "2",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxNoLoad_PROPERTIES

      // END_USER_CODE-USER_chkbxNoLoad_PROPERTIES
    },
    colFrghtRate: {
      name: "colFrghtRate",
      type: "GridColumnWidget",
      parent: "gridFrghtRate",
      Label: "Freight Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFrghtRate_PROPERTIES

      // END_USER_CODE-USER_colFrghtRate_PROPERTIES
    },
    colMaxMile: {
      name: "colMaxMile",
      type: "GridColumnWidget",
      parent: "gridFrghtRate",
      Label: "Max. Mile",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMaxMile_PROPERTIES

      // END_USER_CODE-USER_colMaxMile_PROPERTIES
    },
    colMinMile: {
      name: "colMinMile",
      type: "GridColumnWidget",
      parent: "gridFrghtRate",
      Label: "Min. Mile",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMinMile_PROPERTIES

      // END_USER_CODE-USER_colMinMile_PROPERTIES
    },
    ddDestByngPt: {
      name: "ddDestByngPt",
      type: "DropDownFieldWidget",
      parent: "grpbxFreightProf",
      Label: "Dest. Buying Point",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestByngPt_PROPERTIES

      // END_USER_CODE-USER_ddDestByngPt_PROPERTIES
    },
    ddRateBs: {
      name: "ddRateBs",
      type: "DropDownFieldWidget",
      parent: "grpbxFreightProf",
      Label: "Rate Basis:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRateBs_PROPERTIES

      // END_USER_CODE-USER_ddRateBs_PROPERTIES
    },
    dtEffective: {
      name: "dtEffective",
      type: "DateWidget",
      parent: "grpbxFreightProf",
      Label: "Effective Date:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_dateCalender",
      // START_USER_CODE-USER_dtEffective_PROPERTIES

      // END_USER_CODE-USER_dtEffective_PROPERTIES
    },
    gridFrghtRate: {
      name: "gridFrghtRate",
      type: "GridWidget",
      parent: "grpbxFreightProf",
      gridCellsOrder: "txtColMinMile,txtColMaxMile,txtColFrghtRate",
      ColSpan: "1",
      Pagination: false,
      HasLabel: false,
      Cols: "2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridFrghtRate_PROPERTIES
      isEditable: true,
      // END_USER_CODE-USER_gridFrghtRate_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByVal: {
      name: "lblAddedByVal",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByVal_PROPERTIES

      // END_USER_CODE-USER_lblAddedByVal_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByVal: {
      name: "lblChangedByVal",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByVal_PROPERTIES

      // END_USER_CODE-USER_lblChangedByVal_PROPERTIES
    },
    lblPounds: {
      name: "lblPounds",
      type: "LabelWidget",
      parent: "grpbxFreightProf",
      Label: "Pounds",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPounds_PROPERTIES

      // END_USER_CODE-USER_lblPounds_PROPERTIES
    },
    txtCarrAbbr: {
      name: "txtCarrAbbr",
      type: "TextBoxWidget",
      parent: "grpbxFreightProf",
      Label: "Carrier Abbreviation:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrAbbr_PROPERTIES

      // END_USER_CODE-USER_txtCarrAbbr_PROPERTIES
    },
    txtColFrghtRate: {
      name: "txtColFrghtRate",
      type: "TextBoxWidget",
      colName: "colFrghtRate",
      parent: "gridFrghtRate",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColFrghtRate_PROPERTIES

      // END_USER_CODE-USER_txtColFrghtRate_PROPERTIES
    },
    txtColMaxMile: {
      name: "txtColMaxMile",
      type: "TextBoxWidget",
      colName: "colMaxMile",
      parent: "gridFrghtRate",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColMaxMile_PROPERTIES

      // END_USER_CODE-USER_txtColMaxMile_PROPERTIES
    },
    txtColMinMile: {
      name: "txtColMinMile",
      type: "TextBoxWidget",
      colName: "colMinMile",
      parent: "gridFrghtRate",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColMinMile_PROPERTIES

      // END_USER_CODE-USER_txtColMinMile_PROPERTIES
    },
    txtMinWght: {
      name: "txtMinWght",
      type: "TextBoxWidget",
      parent: "grpbxFreightProf",
      Label: "Minimum Weight:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMinWght_PROPERTIES

      // END_USER_CODE-USER_txtMinWght_PROPERTIES
    },
    grpbxFreightProf: {
      name: "grpbxFreightProf",
      type: "GroupBoxWidget",
      parent: "FreightRateProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxFreightProf_PROPERTIES

      // END_USER_CODE-USER_grpbxFreightProf_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "FreightRateProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceMasterManagement#FreightRateCopy": {}
      },
      REVERSE: {
        "SystemMaintenanceMasterManagement#FreightRateCopy": {}
      }
    }
  };

  let _buttonServices = {};

  let _buttonNavigation = {};
  let _winServices = {};

  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormActive();

    setEventHandlers();
    return () => {
      removeEventHandlers();
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  // START_USER_CODE-USER_METHODS
    
  const ongridFrghtRateRowSelect = (rowSelected) => {
    try {
      setData(thisObj, 'Row$ID_FR', rowSelected.selectedRows)
      return true;
    }
    catch (err) {
      errorHandler(err)
      return false;
    }
  };
  thisObj.ongridFrghtRateRowSelect = ongridFrghtRateRowSelect

  // Implement bFormValidation functionality
  const bFormValid = async() => {
    try {
      let objData = getData(thisObj, "frmFreightRateProfile");
      if(objData?.cmdOkCaption?.toLocaleUpperCase() == "ADD"){
        let cmdLocation = getValue(thisObj, 'ddDestByngPt');
        if (cmdLocation == undefined || cmdLocation?.trim() == "" || cmdLocation == null) {
          alert("Location is required!")
          document.getElementById('ddDestByngPt')?.focus()
          return false;
        }
      }

      let txtEffectiveDate = ![undefined, null].includes(objData?.effectiveDate) ? objData?.effectiveDate : thisObj.values.dtEffective == "" ? "" : moment(new Date(thisObj.values.dtEffective)).format("mm/dd/yyyy")
      if ((txtEffectiveDate == undefined || txtEffectiveDate?.trim() == "" || txtEffectiveDate == null) && objData?.cmdOkCaption !== 'Save') {
        alert("Enter valid date")
        document.getElementById("dtEffective")?.focus()
        return false;
      }

      let gridData = thisObj.values['gridFrghtRate']
      if(gridData?.length <= 0){
        alert("The Mileage and Rate is required.")
        return false;
      }

      for(var i=0; i<gridData?.length; i++){
        if(gridData[i]?.txtColMinMile?.toString()?.trim() !== "" || gridData[i]?.txtColMaxMile?.toString()?.trim() !== "" || gridData[i]?.txtColFrghtRate?.toString()?.trim() !== ""){
          for(var j=0; j<gridData?.length; j++){
            if(i !== j){
              if(gridData[j]?.txtColMinMile?.toString()?.trim() !== ""){
                if(Number(gridData[i]?.txtColMinMile?.toString()?.trim()) >= Number(gridData[j]?.txtColMinMile?.toString()?.trim()) && Number(gridData[i]?.txtColMinMile?.toString()?.trim()) <= Number(gridData[j]?.txtColMaxMile?.toString()?.trim())){
                  alert("Overlap exists in Mile range.") 
                  document.getElementById('gridFrghtRate')?.focus()
                  return
                }
                if(Number(gridData[i]?.txtColMaxMile?.toString()?.trim()) >= Number(gridData[j]?.txtColMinMile?.toString()?.trim()) && Number(gridData[i]?.txtColMaxMile?.toString()?.trim()) <= Number(gridData[j]?.txtColMaxMile?.toString()?.trim())){
                  alert("Overlap exists in Mile range.") 
                  document.getElementById('gridFrghtRate')?.focus()
                  return
                }
              }
            }
          }
        }        
      }
      
      return true;
    }
    catch (err) {
      errorHandler(err)
      return false
    }
  }

  // Impliment Rate Basis
  const bFillRateBasis = () => {  
    try {
      let rateBasis = thisObj.values.ddRateBs
      let js = [
        { key: 'FLAT',  description: 'FLAT'  },
        { key: 'MILE',  description: 'MILE'  },
        { key: '100WT', description: '100WT' },
        { key: 'TON',   description: 'TON'   }
      ]

      thisObj.setState(current => {
        return {
          ...current,
          ddRateBs: {
            ...state["ddRateBs"],
            valueList: js
          }
        }
      })

      let cmdOK = getData(thisObj, "frmFreightRateProfile")?.cmdOkCaption
      if (cmdOK == "Save") {
        let indexVal = js.find(elem => elem.key == rateBasis);
        thisObj.values.ddRateBs = indexVal?.key;
      }
      else {
        thisObj.values.ddRateBs = js.at(0).key
      }
      return true;
    }
    catch (err) {
      errorHandler(err)
      return false;
    }
  }

  //Binding data to the Dest. Buying Point drop down
  const bFillReceivingLocationList = async () => {
    try {
      let js = {}
      let buyingPt = thisObj.values.ddDestByngPt
      let objData = getData(thisObj, 'frmFreightRateProfile')
      
      if(objData.cmdOkCaption == 'Save'){
        js = [{ 
          key         : buyingPt, 
          description : objData.lblOrgBPTCaption
        }]
      }
      else{
        js = [{ 
          key: '', 
          description: '>>> All Locations <<<' 
        }]
  
        let response = await SettlementService.RetrieveTransferBuyingPointDetails(buyingPt, null);
        response?.forEach(item => {
          js.push({
            key         : item?.xfer_buy_pt_id,
            description : item?.xfer_buy_pt_id + " - " + toPascalCase(item?.xfer_buy_pt_name)
          })
        })

        if(js.length == 2){
          js = js[1]
        }
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddDestByngPt: {
            ...state["ddDestByngPt"],
            valueList: js
          }
        }
      })

      thisObj.values.ddDestByngPt = js.at(0).key
      setData(thisObj, 'buyingPtText', js.at(0).description)
    }
    catch (err) {
      errorHandler(err)
      return false
    }
  }

  // Implement logic to bind chart grid
  const bFillMileageChart = async (xfer_buy_point_id, nl_ind, frt_scac_id, effct_date) => {
    try {
      let gridData = [];
      let effctdate = moment(effct_date).format('YYYY-MM-DD HH:mm:ss.SSS')
      const gridResponse = await SystemMaintenanceMasterManagementService.RetrieveFreightMileageChartDetails(xfer_buy_point_id, nl_ind, frt_scac_id, effctdate);
      if (gridResponse != undefined && gridResponse != null && gridResponse != "") {
        for (let i = 0; i < gridResponse.length; i++) {
          gridData.push({
            txtColMinMile   : gridResponse[i].min_trip_mileage,
            txtColMaxMile   : gridResponse[i].max_trip_mileage,
            txtColFrghtRate : Number(gridResponse[i].freight_rate).toFixed(2)
          });
        }
      }
      gridData = sortJSON(gridData, 'txtColMinMile', true)
      setValue(thisObj, 'gridFrghtRate', gridData);
    } 
    catch (error) {
      errorHandler(error) 
    }
  };

  //Implement Form Active functionality
  const FormActive = async () => {
    try {
      
      let objData = getData(thisObj, "frmFreightRateProfile");
      let cmdOK = objData?.cmdOkCaption
      let xfer_buy_point_id = objData?.lblOrgBPTCaption.split("-")[0].trim()
      let rateBasis = objData?.cmbRateBasis
      let nl_ind = objData?.chkNoLoadenabled;
      let Minwght = objData?.txtDeadFreight;
      let frt_scac_id = [undefined, null, ""].includes(objData?.txtScac_id) ? "" :  objData?.txtScac_id;
      let effct_date = [undefined, null, ""].includes(objData?.effectiveDate) ? "" :  objData?.effectiveDate;
      let effective_date = moment(objData?.lblEffectiveDate).format('MM/DD/YYYY');
      
      document.getElementById("btnOK").innerHTML = cmdOK

      if (cmdOK == "Save") {
        disable(thisObj, 'dtEffective')
        disable(thisObj, 'txtCarrAbbr')
        disable(thisObj, 'chkbxNoLoad')

        enable(thisObj, 'btnCpyLoc')
        enable(thisObj, 'btnCpyCarr')
        enable(thisObj, 'btnCopyRate')

        thisObj.values.ddDestByngPt = xfer_buy_point_id
        thisObj.values.dtEffective = new Date(effective_date).toLocaleDateString()
        thisObj.values.txtCarrAbbr = frt_scac_id
        thisObj.values.ddRateBs = rateBasis
        thisObj.values.txtMinWght = Minwght
        thisObj.values.chkbxNoLoad = objData?.chkNoLoadenabled == "N" ? 0 : 1
        setData(thisObj, 'strflg', "U")
        
        await bFillMileageChart(xfer_buy_point_id, nl_ind, frt_scac_id, effct_date)
        await bFillReceivingLocationList()
        await bFillRateBasis()
        await bFillFRTRate()
        disable(thisObj, 'ddDestByngPt')
      }
      else {
        await bFillReceivingLocationList();
        
        enable(thisObj, 'ddDestByngPt');
        enable(thisObj, 'dtEffective');
        enable(thisObj, 'txtCarrAbbr')
        enable(thisObj, 'chkbxNoLoad')

        
        thisObj.values.dtEffective = ""
        thisObj.values.txtCarrAbbr = ""
        thisObj.values.ddRateBs = ""
        thisObj.values.txtMinWght = ""
        thisObj.values.chkbxNoLoad = objData?.chkNoLoadenabled == "N"
        setData(thisObj, 'buyingPtText', "")

        disable(thisObj, 'btnCpyLoc')
        disable(thisObj, 'btnCpyCarr')
        disable(thisObj, 'btnCopyRate')

        setData(thisObj, 'strflg', "A")

        if(! await bFillRateBasis()){
          return
        }
      }
    }
    catch (err) {
      errorHandler(err) 
      return false;
    }
    finally{
      setLoading(false)
    }
  };

  // Implement Add button functionality
  const onbtnOKClick = async() => {
    try {
      if(await bFormValid()){
        let strflg = getData(thisObj, "frmFreightRateProfile")?.cmdOkCaption == "Add" ? 'A' : 'U';
        if(!await bSaveData(strflg)){
          return
        }
        if(strflg == 'A'){
          onbtnCloseClick() //back to parent screen
          return
        }
      }      
    }
    catch (err) {
      errorHandler(err) 
      return false;
    }
    return true;
  }
  thisObj.onbtnOKClick = onbtnOKClick;

  // Implement BSaveData functionality
  const bSaveData = async (strflg) => {
    try {
      let lstrxml = []
      let strGrid = ""
      let no_load_ind = ""

      let objData = getData(thisObj, "frmFreightRateProfile");

      let cmdLocation = getValue(thisObj, 'ddDestByngPt');
      if(getData(thisObj, 'lblOrgBPT') == ""){
        if (cmdLocation == undefined || cmdLocation?.trim() == "" || cmdLocation == null) {
          alert("Location is required!")
          document.getElementById('ddDestByngPt').focus();
          return false;
        }
      }
      
      let txtEffectiveDate = ![undefined, null].includes(objData?.effectiveDate) ? objData?.effectiveDate : thisObj.values.dtEffective
      if(getData(thisObj, 'lblEffectiveDate') == ""){
        if ((txtEffectiveDate == undefined || txtEffectiveDate?.toString()?.trim() == "" || txtEffectiveDate == null) || !(new Date(txtEffectiveDate) instanceof Date)) {
          showMessage(thisObj, "The Effective date is required!")
          document.getElementById("dtEffective")?.focus()
          return false;
        }
      }
      
      
      let gridData = thisObj.values['gridFrghtRate']
      for(var i=0; i<gridData?.length; i++){
        if(isNaN(gridData[i]?.txtColMinMile) || isNaN(gridData[i]?.txtColMaxMile) || isNaN(gridData[i]?.txtColFrghtRate)){
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\nError: \n\n" + "Error converting data type nvarchar to numeric.")
          return
        }
        strGrid += ((i+1)?.toString() + ", ")
        strGrid += (gridData[i]?.txtColMinMile  + ", ")
        strGrid += (gridData[i]?.txtColMaxMile  + ", ")
        strGrid += (gridData[i]?.txtColFrghtRate  + "| ")
      }
      
      no_load_ind = getValue(thisObj, 'chkbxNoLoad') ? 'Y' : 'N'

      let dataObj = {
        "effective_date" : moment(txtEffectiveDate).format('YYYY-MM-DD'), //  HH:mm:ss.SSS
        "mileage"        : strGrid,
        "rate_basis"     : thisObj.values['ddRateBs'], //getValue(thisObj, 'ddRateBs'),
        "vendor"         : "",
        "user_id"        : useridFromLS(),
        "frt_scac_id"    : thisObj.values.txtCarrAbbr,
        "dead_freight"   : thisObj.values.txtMinWght
      }
      
      let flag = strflg?.toLocaleUpperCase()?.trim()
      lstrxml = await SystemMaintenanceMasterManagementService.UpdateFreightRate(flag, cmdLocation, no_load_ind, dataObj)
      if(lstrxml?.status == 200){
          alert(`Data ${flag == "A" ? 'Added' : 'Saved'} Successfully`)
          setData(thisObj, 'frmFreightRateSetupRefresh', true)
      }
      else{
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\nError: \n\n" + lstrxml?.message?.split('Error Meesage :')[1])
        return
      }      
      await bFillMileageChart(cmdLocation, no_load_ind, thisObj.values.txtCarrAbbr, dataObj?.effective_date);
      await bFillFRTRate()
      
      return true
    }
    catch (err) {
      errorHandler(err) 
      return false;
    }
  }

  const bFillFRTRate = async() => {
    try{
      let buyingPt  = thisObj.values.ddDestByngPt
      let carrAbbr  = thisObj.values.txtCarrAbbr
      let effctdate =  moment(getData(thisObj, "frmFreightRateProfile")?.lblEffectiveDate).format('MM/DD/YYYY');

      let LstrList = await SystemMaintenanceMasterManagementService.RetrieveFreightRateDetails(buyingPt, carrAbbr, effctdate)
      if(LstrList?.length > 0){
        if(['FLAT', 'MILE', '100WT', 'TON'].includes(LstrList[0]?.rate_basis?.trim()?.toLocaleUpperCase())){
          thisObj.values.ddRateBs = LstrList[0]?.rate_basis?.trim()?.toLocaleUpperCase()
        }
        
        thisObj.values.txtMinWght = LstrList[0].dead_freight?.trim()
        setValue(thisObj, 'txtMinWght', LstrList[0].dead_freight?.trim())
        setValue(thisObj, 'lblChangedByVal', LstrList[0].chg_user?.trim() + " - " + LstrList[0].chg_date_time?.trim())
        setValue(thisObj, 'lblAddedByVal',   LstrList[0].add_user?.trim() + " - " + LstrList[0].add_date_time?.trim())
        
        return true
      }      
      return false
    }
    catch(err){
      errorHandler(err)
      return false
    }
  }

  //Implement copy Location functionality  
  const onbtnCpyLocClick = () => {
    try {
      let frmFreightRateCopy = {
        lblFrmBuyPt_Caption          : getData(thisObj, 'buyingPtText'), //frmBuyPt,
        frameCopyFromCarrier_Visible : 'False',
        frameCopyToCarrier_Visible   : 'False',
        CopyMode                     :  1,
        Action                       : "locationCopy"
      }
      setData(thisObj, "cmdCopyLocation", frmFreightRateCopy)
      goTo(thisObj, "SystemMaintenanceMasterManagement#FreightRateCopy#DEFAULT#true#Click")
      return true
    }
    catch (err) {
      errorHandler(err) 
      return false;
    }
  };
  thisObj.onbtnCpyLocClick = onbtnCpyLocClick;

  //Implement copy Carrier functionality 
  const onbtnCpyCarrClick = () => {
    try {
      let frmFreightRateCopy = {
        lblFrmBuyPt_Caption          : getData(thisObj, 'buyingPtText'), // frmBuyPt,
        lblCarrier                   : thisObj.values.txtCarrAbbr, //CarrAbbr,
        frameCopyFromCarrier_Visible : 'True',
        frameCopyToCarrier_Visible   : 'True',
        chkNoLoad_Value              : thisObj.values.chkbxNoLoad, // nl_ind,
        CopyMode                     : 2,
        Action                       : "CarrierCopy"
      }
      setData(thisObj, "cmdCopyCarrier", frmFreightRateCopy)
      goTo(thisObj, "SystemMaintenanceMasterManagement#FreightRateCopy#DEFAULT#true#Click")
      return true;
    }
    catch (err) {
      errorHandler(err) 
      return false;
    }   
  };
  thisObj.onbtnCpyCarrClick = onbtnCpyCarrClick;

  //Implement copy Rate functionality   
  const onbtnCopyRateClick = () => {
    try {
      let frmFreightRateCopy = {
        lblFrmBuyPt_Caption          : getData(thisObj, 'buyingPtText'),
        lblCarrier                   : thisObj.values.txtCarrAbbr,
        frameCopyFromCarrier_Visible : 'True',
        frameCopyToCarrier_Visible   : 'False',
        chkNoLoad_Value              : thisObj.values.chkbxNoLoad,
        CopyMode                     : 3,
        Action                       : "RateCopy",
        eff_date                     : getData(thisObj, "frmFreightRateProfile")?.effectiveDate,
        lblFrtVendor                 : getData(thisObj, "frmFreightRateProfile")?.lblFrtVendor,
        cmbRateBasis                 : thisObj.values.ddRateBs, // rate_basis,
        txtDeadFreight               : thisObj.values.txtMinWght
      }
      setData(thisObj, "cmdCopyRate", frmFreightRateCopy)
      goTo(thisObj, "SystemMaintenanceMasterManagement#FreightRateCopy#DEFAULT#true#Click")
      return true;
    }
    catch (err) {
      errorHandler(err) 
      return false;
    }
    
  };
  thisObj.onbtnCopyRateClick = onbtnCopyRateClick;

  // Implemet Print button
  const onbtnPrintClick = () => {
    try {
      let data = getValue(thisObj, 'gridFrghtRate');
      let ddBuyPTId = getValue(thisObj, 'ddDestByngPt');
      let CarrAbbr = thisObj.values.txtCarrAbbr;
      let effectiveDate = getValue(thisObj, 'dtEffective')?.trim() == "" ? "" : moment(getValue(thisObj, 'dtEffective')).format('MM/DD/YYYY');
      let nl_ind = getValue(thisObj, 'chkbxNoLoad') ? "Y" : "N";
      let rate_basis = getValue(thisObj, 'ddRateBs');
      let txtMinWght = getValue(thisObj, 'txtMinWght');
      
      var obj = new jsPDF();
  
      let res = [
        [ ddBuyPTId, CarrAbbr, effectiveDate, nl_ind, rate_basis, txtMinWght ],
        [ '', '', '', '', '', '' ], [ '', '', '', '', '', '' ]
      ]
      data?.forEach(item => {
        res.push([
          item?.txtColMinMile, item?.txtColMaxMile, item?.txtColFrghtRate, '', '', ''
        ])
      })
      let footerDetail = getData(thisObj, 'buyingPtText')?.trim()
      footerDetail = footerDetail?.split("-")[0]?.trim() + " - " + toPascalCase(footerDetail?.split("-")[1])

      if(res.length > 43){
        
        obj.autoTable({
          startY : 2,
          startX : 20,
          styles : { fontSize : 6.5, lineWidth : 0.2, color : 10 },
          body : res.slice(0, 43),
          theme : 'plain',
          fontStyle : 'normal',
          head : [ [ 'Dest BP', 'Carrier', 'Effective Date', 'No Load', 'Rate Basis', 'Min.Wt' ] ],
          didParseCell: function(res){
            if(res?.cell?.text?.includes("Min. Mile")){
              res.row.cells[0].styles.fontStyle = 'bold'
              res.row.cells[1].styles.fontStyle = 'bold'
              res.row.cells[2].styles.fontStyle = 'bold'
              res.row.cells[3].styles.fontStyle = 'bold'
              res.row.cells[4].styles.fontStyle = 'bold'
              res.row.cells[5].styles.fontStyle = 'bold'
            }
          }
        })
        obj.autoTable({
          startY : 275,
          startX : 20,
          styles : { fontSize : 10, lineWidth : 0, border : 0 },
          body : [ [ `Freight Rate Report  BuyPt ${footerDetail}` ] ],
          theme : 'plain',
          fontStyle : 'normal',
          head : []
        })

        obj.addPage();
        
        obj.autoTable({
          startY : 2,
          startX : 20,
          styles : { fontSize : 6.5, lineWidth : 0.2, color : 10 },
          body : res.slice(43, 86),
          theme : 'plain',
          fontStyle : 'normal',
          head : [ [ 'Min. Mile', 'Max. Mile', 'Freight Rate', '', '', '' ] ]
        })
        obj.autoTable({
          startY : 275,
          startX : 20,
          styles : { fontSize : 10, lineWidth : 0, border : 0 },
          body : [ [ `Freight Rate Report  BuyPt ${footerDetail}` ] ],
          theme : 'plain',
          fontStyle : 'normal',
          head : []
        })
      }
      else{
        obj.autoTable({
          startY : 2,
          startX : 20,
          styles : { fontSize : 6.5, lineWidth : 0.2, color : 10 },
          body : res.slice(0, 43),
          theme : 'plain',
          fontStyle : 'normal',
          head : [ [ 'Dest BP', 'Carrier', 'Effective Date', 'No Load', 'Rate Basis', 'Min.Wt' ] ],
          didParseCell: function(res){
            if(res?.cell?.text?.includes("Min. Mile")){
              res.row.cells[0].styles.fontStyle = 'bold'
              res.row.cells[1].styles.fontStyle = 'bold'
              res.row.cells[2].styles.fontStyle = 'bold'
              res.row.cells[3].styles.fontStyle = 'bold'
              res.row.cells[4].styles.fontStyle = 'bold'
              res.row.cells[5].styles.fontStyle = 'bold'
            }
          }
        })
        obj.autoTable({
          startY : 275,
          startX : 20,
          styles : { fontSize : 10, lineWidth : 0, border : 0 },
          body : [ [ `Freight Rate Report  BuyPt ${footerDetail}` ] ],
          theme : 'plain',
          fontStyle : 'normal',
          head : []
        })
      }
      
      let fileName = prompt("Please enter the file name :");
      if(fileName !== null && fileName !== undefined){
        if(fileName?.trim() !== ""){
          obj.save(fileName + '.pdf')
        }
      }
    }
    catch (err) {
      errorHandler(err)
    }  
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;

  // Implement Close button function
  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_FreightRateProfilePopUp").childNodes[0].click()
      return true
    } 
    catch (err) {
      errorHandler(err) 
      return false;
    }
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const ontxtMinWghtChange = () => {
    try{
      let value = getValue(thisObj, 'txtMinWght')
      setValue(thisObj, 'txtMinWght', validatetxtMinWght(value))
    }
    catch(err){
      errorHandler(err) 
    }
  }
  thisObj.ontxtMinWghtChange = ontxtMinWghtChange

  // Implement Dead Frt text validation
  const validatetxtMinWght = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) != 3) && (data[i].charCodeAt(0) != 24) && (data[i].charCodeAt(0) != 22) &&
        (data[i].charCodeAt(0) != 8) && (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57)) {
        res += data[i]
      }
    }
    return res;
  }

  const setEventHandlers = () => {
    document.getElementById('txtMinWght').addEventListener('keypress', allowOnlyNumbers);
  };

  const removeEventHandlers = () => {
    document.getElementById('txtMinWght').removeEventListener('keypress', allowOnlyNumbers);
  };

  const allowOnlyNumbers = (event) => {
    try {
      if (!((event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode === 8)) {
        event.preventDefault();
      }
    } 
    catch (err) {
      errorHandler(err) 
    }
  };

  const ontxtCarrAbbrBlur = (event) => {
    try{
      thisObj.values.txtCarrAbbr = event?.target?.value?.trim()?.toLocaleUpperCase()
      setValue(thisObj, 'txtCarrAbbr', event?.target?.value?.trim()?.toLocaleUpperCase())
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtCarrAbbrBlur = ontxtCarrAbbrBlur;

  function toPascalCase(word){
    try{
      return word.replace(/\w+/g, 
        function(w){
          return w[0].toUpperCase() + w.slice(1).toLowerCase();
        });
    }
    catch(err){
      errorHandler(err)
    }
  }

  function errorHandler(err){
    showMessage(thisObj, err.message)
  }

  function sortJSON(arr, key, asc=true) {   
    return arr.sort((a, b) => {     
      let x = a[key];     
      let y = b[key];     
      if (asc) { return ((x < y) ? -1 : ((x > y) ? 1 : 0)); }     
      else { return ((x > y) ? -1 : ((x < y) ? 1 : 0)); }   
    }); 
  }
  
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FreightRateProfile*/}

              {/* END_USER_CODE-USER_BEFORE_FreightRateProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFreightProf*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFreightProf*/}

              <GroupBoxWidget conf={state.grpbxFreightProf} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_chkbxNoLoad*/}

                {/* END_USER_CODE-USER_BEFORE_chkbxNoLoad*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkbxNoLoad}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkbxNoLoad*/}

                {/* END_USER_CODE-USER_AFTER_chkbxNoLoad*/}
                {/* START_USER_CODE-USER_BEFORE_ddDestByngPt*/}

                {/* END_USER_CODE-USER_BEFORE_ddDestByngPt*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddDestByngPt}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddDestByngPt*/}

                {/* END_USER_CODE-USER_AFTER_ddDestByngPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtCarrAbbr*/}

                {/* END_USER_CODE-USER_BEFORE_txtCarrAbbr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCarrAbbr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCarrAbbr*/}

                {/* END_USER_CODE-USER_AFTER_txtCarrAbbr*/}
                {/* START_USER_CODE-USER_BEFORE_dtEffective*/}

                {/* END_USER_CODE-USER_BEFORE_dtEffective*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtEffective}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_dtEffective*/}

                {/* END_USER_CODE-USER_AFTER_dtEffective*/}
                {/* START_USER_CODE-USER_BEFORE_ddRateBs*/}

                {/* END_USER_CODE-USER_BEFORE_ddRateBs*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRateBs}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRateBs*/}

                {/* END_USER_CODE-USER_AFTER_ddRateBs*/}
                {/* START_USER_CODE-USER_BEFORE_txtMinWght*/}

                {/* END_USER_CODE-USER_BEFORE_txtMinWght*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMinWght}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtMinWght*/}

                {/* END_USER_CODE-USER_AFTER_txtMinWght*/}
                {/* START_USER_CODE-USER_BEFORE_lblPounds*/}

                {/* END_USER_CODE-USER_BEFORE_lblPounds*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPounds}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPounds*/}

                {/* END_USER_CODE-USER_AFTER_lblPounds*/}
                {/* START_USER_CODE-USER_BEFORE_gridFrghtRate*/}

                {/* END_USER_CODE-USER_BEFORE_gridFrghtRate*/}

                <GridWidget
                  conf={state.gridFrghtRate}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridFrghtRate}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridFrghtRate*/}

                {/* END_USER_CODE-USER_AFTER_gridFrghtRate*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFreightProf*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFreightProf*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByVal*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByVal*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByVal}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByVal*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByVal*/}
                {/* START_USER_CODE-USER_BEFORE_btnCpyLoc*/}

                {/* END_USER_CODE-USER_BEFORE_btnCpyLoc*/}

                <ButtonWidget
                  conf={state.btnCpyLoc}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCpyLoc*/}

                {/* END_USER_CODE-USER_AFTER_btnCpyLoc*/}
                {/* START_USER_CODE-USER_BEFORE_btnCpyCarr*/}

                {/* END_USER_CODE-USER_BEFORE_btnCpyCarr*/}

                <ButtonWidget
                  conf={state.btnCpyCarr}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCpyCarr*/}

                {/* END_USER_CODE-USER_AFTER_btnCpyCarr*/}
                {/* START_USER_CODE-USER_BEFORE_btnCopyRate*/}

                {/* END_USER_CODE-USER_BEFORE_btnCopyRate*/}

                <ButtonWidget
                  conf={state.btnCopyRate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCopyRate*/}

                {/* END_USER_CODE-USER_AFTER_btnCopyRate*/}
                {/* START_USER_CODE-USER_BEFORE_btnOK*/}

                {/* END_USER_CODE-USER_BEFORE_btnOK*/}

                <ButtonWidget
                  conf={state.btnOK}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOK*/}

                {/* END_USER_CODE-USER_AFTER_btnOK*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByVal*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByVal*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByVal}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByVal*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByVal*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_FreightRateProfile*/}

              {/* END_USER_CODE-USER_AFTER_FreightRateProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_FreightRateProfile);
