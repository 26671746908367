/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_Filters from "./Filters";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("Filters Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_Filters />);
    });
  });
  afterEach(cleanup);
  test("is Filters Loads Successfully", () => {
    expect(screen.getByText("Filters")).toBeInTheDocument;
  });
  test("Custom Test Cases for Filters", () => {
    // START_USER_CODE-USER_Filters_Custom_Test_Case
    // END_USER_CODE-USER_Filters_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_Filters />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:Filters_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:Filters_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("gridStates_btncolEditCounty(Grid Widget) Test Cases", async () => {
    let gridStates_btncolEditCounty = screen.getByTestId("gridStates");
    let gridStates_btncolEditCountybtn =
      gridStates_btncolEditCounty.nextElementSibling.firstElementChild;
    gridStates_btncolEditCounty =
      gridStates_btncolEditCounty.parentElement.parentElement.parentElement;
    expect(gridStates_btncolEditCounty.tagName).toBe("DIV");
    expect(gridStates_btncolEditCounty.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:Filters_gridStates")
      )
    ).toBeInTheDocument;
    const gridStates_btncolEditCountydata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridStates_btncolEditCountydata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridStates"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridStates_btncolEditCountybtn, { button: 0 });
      });
      const gridStates_btncolEditCountyadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridStates_btncolEditCountyadd, { button: 0 });
      });
    }
    const btncolEditCounty = screen.getByTestId("btncolEditCounty");
    expect(btncolEditCounty).toBeInTheDocument;
  });
  test("Custom Test Cases for btncolEditCounty", () => {
    // START_USER_CODE-USER_btncolEditCounty_TEST
    // END_USER_CODE-USER_btncolEditCounty_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:Filters_btnSave")
    );
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("btnSaveCounty(Button Widget) Test Cases", async () => {
    const btnSaveCounty = screen.getByTestId("btnSaveCounty");
    expect(btnSaveCounty).toBeInTheDocument;
    expect(btnSaveCounty.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:Filters_btnSaveCounty")
    );
  });
  test("Custom Test Cases for btnSaveCounty", () => {
    // START_USER_CODE-USER_btnSaveCounty_TEST
    // END_USER_CODE-USER_btnSaveCounty_TEST
  });
  test("btnSaveStates(Button Widget) Test Cases", async () => {
    const btnSaveStates = screen.getByTestId("btnSaveStates");
    expect(btnSaveStates).toBeInTheDocument;
    expect(btnSaveStates.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:Filters_btnSaveStates")
    );
  });
  test("Custom Test Cases for btnSaveStates", () => {
    // START_USER_CODE-USER_btnSaveStates_TEST
    // END_USER_CODE-USER_btnSaveStates_TEST
  });
  test("btnSrch(Button Widget) Test Cases", async () => {
    const btnSrch = screen.getByTestId("btnSrch");
    expect(btnSrch).toBeInTheDocument;
    expect(btnSrch.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:Filters_btnSrch")
    );
  });
  test("Custom Test Cases for btnSrch", () => {
    // START_USER_CODE-USER_btnSrch_TEST
    // END_USER_CODE-USER_btnSrch_TEST
  });
  test("gridCounties(Grid Widget) Test Cases", async () => {
    let gridCounties = screen.getByTestId("gridCounties");
    let gridCountiesbtn = gridCounties.nextElementSibling.firstElementChild;
    gridCounties = gridCounties.parentElement.parentElement.parentElement;
    expect(gridCounties.tagName).toBe("DIV");
    expect(gridCounties.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:Filters_gridCounties")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridCounties", () => {
    // START_USER_CODE-USER_gridCounties_TEST
    // END_USER_CODE-USER_gridCounties_TEST
  });
  test("gridPnutFilter(Grid Widget) Test Cases", async () => {
    let gridPnutFilter = screen.getByTestId("gridPnutFilter");
    let gridPnutFilterbtn = gridPnutFilter.nextElementSibling.firstElementChild;
    gridPnutFilter = gridPnutFilter.parentElement.parentElement.parentElement;
    expect(gridPnutFilter.tagName).toBe("DIV");
    expect(gridPnutFilter.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:Filters_gridPnutFilter")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPnutFilter", () => {
    // START_USER_CODE-USER_gridPnutFilter_TEST
    // END_USER_CODE-USER_gridPnutFilter_TEST
  });
  test("gridStates(Grid Widget) Test Cases", async () => {
    let gridStates = screen.getByTestId("gridStates");
    let gridStatesbtn = gridStates.nextElementSibling.firstElementChild;
    gridStates = gridStates.parentElement.parentElement.parentElement;
    expect(gridStates.tagName).toBe("DIV");
    expect(gridStates.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:Filters_gridStates")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridStates", () => {
    // START_USER_CODE-USER_gridStates_TEST
    // END_USER_CODE-USER_gridStates_TEST
  });
  test("grpbxCounty(GroupBox Widget) Test Cases", async () => {
    const grpbxCounty = screen.getByTestId("grpbxCounty");
    expect(grpbxCounty.tagName).toBe("BUTTON");
    expect(grpbxCounty.type).toBe("button");
    expect(grpbxCounty.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCounty", () => {
    // START_USER_CODE-USER_grpbxCounty_TEST
    // END_USER_CODE-USER_grpbxCounty_TEST
  });
  test("grpbxFilter(GroupBox Widget) Test Cases", async () => {
    const grpbxFilter = screen.getByTestId("grpbxFilter");
    expect(grpbxFilter.tagName).toBe("BUTTON");
    expect(grpbxFilter.type).toBe("button");
    expect(grpbxFilter.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFilter", () => {
    // START_USER_CODE-USER_grpbxFilter_TEST
    // END_USER_CODE-USER_grpbxFilter_TEST
  });
  test("grpbxPnutVrty(GroupBox Widget) Test Cases", async () => {
    const grpbxPnutVrty = screen.getByTestId("grpbxPnutVrty");
    expect(grpbxPnutVrty.tagName).toBe("BUTTON");
    expect(grpbxPnutVrty.type).toBe("button");
    expect(grpbxPnutVrty.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPnutVrty", () => {
    // START_USER_CODE-USER_grpbxPnutVrty_TEST
    // END_USER_CODE-USER_grpbxPnutVrty_TEST
  });
  test("grpbxState(GroupBox Widget) Test Cases", async () => {
    const grpbxState = screen.getByTestId("grpbxState");
    expect(grpbxState.tagName).toBe("BUTTON");
    expect(grpbxState.type).toBe("button");
    expect(grpbxState.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxState", () => {
    // START_USER_CODE-USER_grpbxState_TEST
    // END_USER_CODE-USER_grpbxState_TEST
  });
  test("grpbxStateCounty(GroupBox Widget) Test Cases", async () => {
    const grpbxStateCounty = screen.getByTestId("grpbxStateCounty");
    expect(grpbxStateCounty.tagName).toBe("BUTTON");
    expect(grpbxStateCounty.type).toBe("button");
    expect(grpbxStateCounty.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStateCounty", () => {
    // START_USER_CODE-USER_grpbxStateCounty_TEST
    // END_USER_CODE-USER_grpbxStateCounty_TEST
  });
  test("lblAkkCounties(Label Widget) Test Cases", async () => {
    const lblAkkCounties = screen.getByTestId("lblAkkCounties");
    expect(lblAkkCounties.tagName).toBe("LABEL");
    expect(lblAkkCounties.classList).toContain("form-label");
    expect(lblAkkCounties.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:Filters_lblAkkCounties")
    );
  });
  test("Custom Test Cases for lblAkkCounties", () => {
    // START_USER_CODE-USER_lblAkkCounties_TEST
    // END_USER_CODE-USER_lblAkkCounties_TEST
  });
  test("lblFilterSel(Label Widget) Test Cases", async () => {
    const lblFilterSel = screen.getByTestId("lblFilterSel");
    expect(lblFilterSel.tagName).toBe("LABEL");
    expect(lblFilterSel.classList).toContain("form-label");
    expect(lblFilterSel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:Filters_lblFilterSel")
    );
  });
  test("Custom Test Cases for lblFilterSel", () => {
    // START_USER_CODE-USER_lblFilterSel_TEST
    // END_USER_CODE-USER_lblFilterSel_TEST
  });
  test("lblPnutVrtyFiltr(Label Widget) Test Cases", async () => {
    const lblPnutVrtyFiltr = screen.getByTestId("lblPnutVrtyFiltr");
    expect(lblPnutVrtyFiltr.tagName).toBe("LABEL");
    expect(lblPnutVrtyFiltr.classList).toContain("form-label");
    expect(lblPnutVrtyFiltr.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:Filters_lblPnutVrtyFiltr")
    );
  });
  test("Custom Test Cases for lblPnutVrtyFiltr", () => {
    // START_USER_CODE-USER_lblPnutVrtyFiltr_TEST
    // END_USER_CODE-USER_lblPnutVrtyFiltr_TEST
  });
  test("lblSelect(Label Widget) Test Cases", async () => {
    const lblSelect = screen.getByTestId("lblSelect");
    expect(lblSelect.tagName).toBe("LABEL");
    expect(lblSelect.classList).toContain("form-label");
    expect(lblSelect.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:Filters_lblSelect")
    );
  });
  test("Custom Test Cases for lblSelect", () => {
    // START_USER_CODE-USER_lblSelect_TEST
    // END_USER_CODE-USER_lblSelect_TEST
  });
  test("lblStateCounty(Label Widget) Test Cases", async () => {
    const lblStateCounty = screen.getByTestId("lblStateCounty");
    expect(lblStateCounty.tagName).toBe("LABEL");
    expect(lblStateCounty.classList).toContain("form-label");
    expect(lblStateCounty.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:Filters_lblStateCounty")
    );
  });
  test("Custom Test Cases for lblStateCounty", () => {
    // START_USER_CODE-USER_lblStateCounty_TEST
    // END_USER_CODE-USER_lblStateCounty_TEST
  });
  test("lblStates(Label Widget) Test Cases", async () => {
    const lblStates = screen.getByTestId("lblStates");
    expect(lblStates.tagName).toBe("LABEL");
    expect(lblStates.classList).toContain("form-label");
    expect(lblStates.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:Filters_lblStates")
    );
  });
  test("Custom Test Cases for lblStates", () => {
    // START_USER_CODE-USER_lblStates_TEST
    // END_USER_CODE-USER_lblStates_TEST
  });
  test("gridCounties_txtcolCountyNm(Grid Widget) Test Cases", async () => {
    let gridCounties_txtcolCountyNm = screen.getByTestId("gridCounties");
    let gridCounties_txtcolCountyNmbtn =
      gridCounties_txtcolCountyNm.nextElementSibling.firstElementChild;
    gridCounties_txtcolCountyNm =
      gridCounties_txtcolCountyNm.parentElement.parentElement.parentElement;
    expect(gridCounties_txtcolCountyNm.tagName).toBe("DIV");
    expect(gridCounties_txtcolCountyNm.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:Filters_gridCounties")
      )
    ).toBeInTheDocument;
    const gridCounties_txtcolCountyNmdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridCounties_txtcolCountyNmdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridCounties"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridCounties_txtcolCountyNmbtn, { button: 0 });
      });
      const gridCounties_txtcolCountyNmadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridCounties_txtcolCountyNmadd, { button: 0 });
      });
    }
    const txtcolCountyNm = screen.getByTestId("txtcolCountyNm");
    expect(txtcolCountyNm.tagName).toBe("INPUT");
    expect(txtcolCountyNm.type).toBe("text");
    expect(txtcolCountyNm.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtcolCountyNm, "1");
    });
  });
  test("Custom Test Cases for txtcolCountyNm", () => {
    // START_USER_CODE-USER_txtcolCountyNm_TEST
    // END_USER_CODE-USER_txtcolCountyNm_TEST
  });
  test("gridPnutFilter_txtColIsStsPnut(Grid Widget) Test Cases", async () => {
    let gridPnutFilter_txtColIsStsPnut = screen.getByTestId("gridPnutFilter");
    let gridPnutFilter_txtColIsStsPnutbtn =
      gridPnutFilter_txtColIsStsPnut.nextElementSibling.firstElementChild;
    gridPnutFilter_txtColIsStsPnut =
      gridPnutFilter_txtColIsStsPnut.parentElement.parentElement.parentElement;
    expect(gridPnutFilter_txtColIsStsPnut.tagName).toBe("DIV");
    expect(gridPnutFilter_txtColIsStsPnut.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:Filters_gridPnutFilter")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtColIsStsPnut", () => {
    // START_USER_CODE-USER_txtColIsStsPnut_TEST
    // END_USER_CODE-USER_txtColIsStsPnut_TEST
  });
  test("gridPnutFilter_txtcolPnutVrtyNm(Grid Widget) Test Cases", async () => {
    let gridPnutFilter_txtcolPnutVrtyNm = screen.getByTestId("gridPnutFilter");
    let gridPnutFilter_txtcolPnutVrtyNmbtn =
      gridPnutFilter_txtcolPnutVrtyNm.nextElementSibling.firstElementChild;
    gridPnutFilter_txtcolPnutVrtyNm =
      gridPnutFilter_txtcolPnutVrtyNm.parentElement.parentElement.parentElement;
    expect(gridPnutFilter_txtcolPnutVrtyNm.tagName).toBe("DIV");
    expect(gridPnutFilter_txtcolPnutVrtyNm.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:Filters_gridPnutFilter")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutVrtyNm", () => {
    // START_USER_CODE-USER_txtcolPnutVrtyNm_TEST
    // END_USER_CODE-USER_txtcolPnutVrtyNm_TEST
  });
  test("gridStates_txtcolStateNm(Grid Widget) Test Cases", async () => {
    let gridStates_txtcolStateNm = screen.getByTestId("gridStates");
    let gridStates_txtcolStateNmbtn =
      gridStates_txtcolStateNm.nextElementSibling.firstElementChild;
    gridStates_txtcolStateNm =
      gridStates_txtcolStateNm.parentElement.parentElement.parentElement;
    expect(gridStates_txtcolStateNm.tagName).toBe("DIV");
    expect(gridStates_txtcolStateNm.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:Filters_gridStates")
      )
    ).toBeInTheDocument;
    const gridStates_txtcolStateNmdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridStates_txtcolStateNmdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridStates"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridStates_txtcolStateNmbtn, { button: 0 });
      });
      const gridStates_txtcolStateNmadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridStates_txtcolStateNmadd, { button: 0 });
      });
    }
    const txtcolStateNm = screen.getByTestId("txtcolStateNm");
    expect(txtcolStateNm.tagName).toBe("INPUT");
    expect(txtcolStateNm.type).toBe("text");
    expect(txtcolStateNm.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtcolStateNm, "1");
    });
  });
  test("Custom Test Cases for txtcolStateNm", () => {
    // START_USER_CODE-USER_txtcolStateNm_TEST
    // END_USER_CODE-USER_txtcolStateNm_TEST
  });
});
