/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_InventoryInquiry from "./InventoryInquiry";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("InventoryInquiry Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_InventoryInquiry />
      );
    });
  });
  afterEach(cleanup);
  test("is InventoryInquiry Loads Successfully", () => {
    expect(screen.getByText("InventoryInquiry")).toBeInTheDocument;
  });
  test("Custom Test Cases for InventoryInquiry", () => {
    // START_USER_CODE-USER_InventoryInquiry_Custom_Test_Case
    // END_USER_CODE-USER_InventoryInquiry_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_InventoryInquiry />
      );
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:InventoryInquiry_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:InventoryInquiry_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnMakeAdjustments(Button Widget) Test Cases", async () => {
    const btnMakeAdjustments = screen.getByTestId("btnMakeAdjustments");
    expect(btnMakeAdjustments).toBeInTheDocument;
    expect(btnMakeAdjustments.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:InventoryInquiry_btnMakeAdjustments"
      )
    );
  });
  test("Custom Test Cases for btnMakeAdjustments", () => {
    // START_USER_CODE-USER_btnMakeAdjustments_TEST
    // END_USER_CODE-USER_btnMakeAdjustments_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:InventoryInquiry_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:InventoryInquiry_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("gridSearchResults(Grid Widget) Test Cases", async () => {
    let gridSearchResults = screen.getByTestId("gridSearchResults");
    let gridSearchResultsbtn =
      gridSearchResults.nextElementSibling.firstElementChild;
    gridSearchResults =
      gridSearchResults.parentElement.parentElement.parentElement;
    expect(gridSearchResults.tagName).toBe("DIV");
    expect(gridSearchResults.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridSearchResults", () => {
    // START_USER_CODE-USER_gridSearchResults_TEST
    // END_USER_CODE-USER_gridSearchResults_TEST
  });
  test("grpbxGradingCriteria(GroupBox Widget) Test Cases", async () => {
    const grpbxGradingCriteria = screen.getByTestId("grpbxGradingCriteria");
    expect(grpbxGradingCriteria.tagName).toBe("BUTTON");
    expect(grpbxGradingCriteria.type).toBe("button");
    expect(grpbxGradingCriteria.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGradingCriteria", () => {
    // START_USER_CODE-USER_grpbxGradingCriteria_TEST
    // END_USER_CODE-USER_grpbxGradingCriteria_TEST
  });
  test("grpbxInventoryInquiry(GroupBox Widget) Test Cases", async () => {
    const grpbxInventoryInquiry = screen.getByTestId("grpbxInventoryInquiry");
    expect(grpbxInventoryInquiry.tagName).toBe("BUTTON");
    expect(grpbxInventoryInquiry.type).toBe("button");
    expect(grpbxInventoryInquiry.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxInventoryInquiry", () => {
    // START_USER_CODE-USER_grpbxInventoryInquiry_TEST
    // END_USER_CODE-USER_grpbxInventoryInquiry_TEST
  });
  test("grpbxInventryCriteria(GroupBox Widget) Test Cases", async () => {
    const grpbxInventryCriteria = screen.getByTestId("grpbxInventryCriteria");
    expect(grpbxInventryCriteria.tagName).toBe("BUTTON");
    expect(grpbxInventryCriteria.type).toBe("button");
    expect(grpbxInventryCriteria.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxInventryCriteria", () => {
    // START_USER_CODE-USER_grpbxInventryCriteria_TEST
    // END_USER_CODE-USER_grpbxInventryCriteria_TEST
  });
  test("grpbxLocation(GroupBox Widget) Test Cases", async () => {
    const grpbxLocation = screen.getByTestId("grpbxLocation");
    expect(grpbxLocation.tagName).toBe("BUTTON");
    expect(grpbxLocation.type).toBe("button");
    expect(grpbxLocation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLocation", () => {
    // START_USER_CODE-USER_grpbxLocation_TEST
    // END_USER_CODE-USER_grpbxLocation_TEST
  });
  test("grpbxPeanutCriteria(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanutCriteria = screen.getByTestId("grpbxPeanutCriteria");
    expect(grpbxPeanutCriteria.tagName).toBe("BUTTON");
    expect(grpbxPeanutCriteria.type).toBe("button");
    expect(grpbxPeanutCriteria.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPeanutCriteria", () => {
    // START_USER_CODE-USER_grpbxPeanutCriteria_TEST
    // END_USER_CODE-USER_grpbxPeanutCriteria_TEST
  });
  test("grpbxSearchResults(GroupBox Widget) Test Cases", async () => {
    const grpbxSearchResults = screen.getByTestId("grpbxSearchResults");
    expect(grpbxSearchResults.tagName).toBe("BUTTON");
    expect(grpbxSearchResults.type).toBe("button");
    expect(grpbxSearchResults.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSearchResults", () => {
    // START_USER_CODE-USER_grpbxSearchResults_TEST
    // END_USER_CODE-USER_grpbxSearchResults_TEST
  });
  test("grpbxStorageCriteria(GroupBox Widget) Test Cases", async () => {
    const grpbxStorageCriteria = screen.getByTestId("grpbxStorageCriteria");
    expect(grpbxStorageCriteria.tagName).toBe("BUTTON");
    expect(grpbxStorageCriteria.type).toBe("button");
    expect(grpbxStorageCriteria.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStorageCriteria", () => {
    // START_USER_CODE-USER_grpbxStorageCriteria_TEST
    // END_USER_CODE-USER_grpbxStorageCriteria_TEST
  });
  test("lblGradingCriteria(Label Widget) Test Cases", async () => {
    const lblGradingCriteria = screen.getByTestId("lblGradingCriteria");
    expect(lblGradingCriteria.tagName).toBe("LABEL");
    expect(lblGradingCriteria.classList).toContain("form-label");
    expect(lblGradingCriteria.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:InventoryInquiry_lblGradingCriteria"
      )
    );
  });
  test("Custom Test Cases for lblGradingCriteria", () => {
    // START_USER_CODE-USER_lblGradingCriteria_TEST
    // END_USER_CODE-USER_lblGradingCriteria_TEST
  });
  test("lblInventryCriteria(Label Widget) Test Cases", async () => {
    const lblInventryCriteria = screen.getByTestId("lblInventryCriteria");
    expect(lblInventryCriteria.tagName).toBe("LABEL");
    expect(lblInventryCriteria.classList).toContain("form-label");
    expect(lblInventryCriteria.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:InventoryInquiry_lblInventryCriteria"
      )
    );
  });
  test("Custom Test Cases for lblInventryCriteria", () => {
    // START_USER_CODE-USER_lblInventryCriteria_TEST
    // END_USER_CODE-USER_lblInventryCriteria_TEST
  });
  test("lblLocation(Label Widget) Test Cases", async () => {
    const lblLocation = screen.getByTestId("lblLocation");
    expect(lblLocation.tagName).toBe("LABEL");
    expect(lblLocation.classList).toContain("form-label");
    expect(lblLocation.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:InventoryInquiry_lblLocation")
    );
  });
  test("Custom Test Cases for lblLocation", () => {
    // START_USER_CODE-USER_lblLocation_TEST
    // END_USER_CODE-USER_lblLocation_TEST
  });
  test("lblPeanutCriteria(Label Widget) Test Cases", async () => {
    const lblPeanutCriteria = screen.getByTestId("lblPeanutCriteria");
    expect(lblPeanutCriteria.tagName).toBe("LABEL");
    expect(lblPeanutCriteria.classList).toContain("form-label");
    expect(lblPeanutCriteria.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:InventoryInquiry_lblPeanutCriteria"
      )
    );
  });
  test("Custom Test Cases for lblPeanutCriteria", () => {
    // START_USER_CODE-USER_lblPeanutCriteria_TEST
    // END_USER_CODE-USER_lblPeanutCriteria_TEST
  });
  test("lblSearchResults(Label Widget) Test Cases", async () => {
    const lblSearchResults = screen.getByTestId("lblSearchResults");
    expect(lblSearchResults.tagName).toBe("LABEL");
    expect(lblSearchResults.classList).toContain("form-label");
    expect(lblSearchResults.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:InventoryInquiry_lblSearchResults")
    );
  });
  test("Custom Test Cases for lblSearchResults", () => {
    // START_USER_CODE-USER_lblSearchResults_TEST
    // END_USER_CODE-USER_lblSearchResults_TEST
  });
  test("lblSort(Label Widget) Test Cases", async () => {
    const lblSort = screen.getByTestId("lblSort");
    expect(lblSort.tagName).toBe("LABEL");
    expect(lblSort.classList).toContain("form-label");
    expect(lblSort.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:InventoryInquiry_lblSort")
    );
  });
  test("Custom Test Cases for lblSort", () => {
    // START_USER_CODE-USER_lblSort_TEST
    // END_USER_CODE-USER_lblSort_TEST
  });
  test("lblSortOrderInventory(Label Widget) Test Cases", async () => {
    const lblSortOrderInventory = screen.getByTestId("lblSortOrderInventory");
    expect(lblSortOrderInventory.tagName).toBe("LABEL");
    expect(lblSortOrderInventory.classList).toContain("form-label");
    expect(lblSortOrderInventory.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:InventoryInquiry_lblSortOrderInventory"
      )
    );
  });
  test("Custom Test Cases for lblSortOrderInventory", () => {
    // START_USER_CODE-USER_lblSortOrderInventory_TEST
    // END_USER_CODE-USER_lblSortOrderInventory_TEST
  });
  test("lblSortOrderStorage(Label Widget) Test Cases", async () => {
    const lblSortOrderStorage = screen.getByTestId("lblSortOrderStorage");
    expect(lblSortOrderStorage.tagName).toBe("LABEL");
    expect(lblSortOrderStorage.classList).toContain("form-label");
    expect(lblSortOrderStorage.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:InventoryInquiry_lblSortOrderStorage"
      )
    );
  });
  test("Custom Test Cases for lblSortOrderStorage", () => {
    // START_USER_CODE-USER_lblSortOrderStorage_TEST
    // END_USER_CODE-USER_lblSortOrderStorage_TEST
  });
  test("lblStorageCriteria(Label Widget) Test Cases", async () => {
    const lblStorageCriteria = screen.getByTestId("lblStorageCriteria");
    expect(lblStorageCriteria.tagName).toBe("LABEL");
    expect(lblStorageCriteria.classList).toContain("form-label");
    expect(lblStorageCriteria.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:InventoryInquiry_lblStorageCriteria"
      )
    );
  });
  test("Custom Test Cases for lblStorageCriteria", () => {
    // START_USER_CODE-USER_lblStorageCriteria_TEST
    // END_USER_CODE-USER_lblStorageCriteria_TEST
  });
  test("txtBin(Textbox Widget) Test Cases", async () => {
    const txtBin = screen.getByTestId("txtBin");
    expect(txtBin.tagName).toBe("INPUT");
    expect(txtBin.type).toBe("text");
    expect(txtBin.classList).toContain("textboxWidgetClass");
    expect(txtBin.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:InventoryInquiry_txtBin")
    );
    await act(async () => {
      userEvent.type(txtBin, "1");
    });
  });
  test("Custom Test Cases for txtBin", () => {
    // START_USER_CODE-USER_txtBin_TEST
    // END_USER_CODE-USER_txtBin_TEST
  });
  test("txtCancelRMD(Textbox Widget) Test Cases", async () => {
    const txtCancelRMD = screen.getByTestId("txtCancelRMD");
    expect(txtCancelRMD.tagName).toBe("INPUT");
    expect(txtCancelRMD.type).toBe("text");
    expect(txtCancelRMD.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCancelRMD, "1");
    });
  });
  test("Custom Test Cases for txtCancelRMD", () => {
    // START_USER_CODE-USER_txtCancelRMD_TEST
    // END_USER_CODE-USER_txtCancelRMD_TEST
  });
  test("gridSearchResults_txtcolBin(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolBin = screen.getByTestId("gridSearchResults");
    let gridSearchResults_txtcolBinbtn =
      gridSearchResults_txtcolBin.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolBin =
      gridSearchResults_txtcolBin.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolBin.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolBin.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBin", () => {
    // START_USER_CODE-USER_txtcolBin_TEST
    // END_USER_CODE-USER_txtcolBin_TEST
  });
  test("gridSearchResults_txtcolCompanyOwned(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolCompanyOwned = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolCompanyOwnedbtn =
      gridSearchResults_txtcolCompanyOwned.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolCompanyOwned =
      gridSearchResults_txtcolCompanyOwned.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolCompanyOwned.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolCompanyOwned.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCompanyOwned", () => {
    // START_USER_CODE-USER_txtcolCompanyOwned_TEST
    // END_USER_CODE-USER_txtcolCompanyOwned_TEST
  });
  test("gridSearchResults_txtcolCrush(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolCrush = screen.getByTestId("gridSearchResults");
    let gridSearchResults_txtcolCrushbtn =
      gridSearchResults_txtcolCrush.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolCrush =
      gridSearchResults_txtcolCrush.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolCrush.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolCrush.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCrush", () => {
    // START_USER_CODE-USER_txtcolCrush_TEST
    // END_USER_CODE-USER_txtcolCrush_TEST
  });
  test("gridSearchResults_txtcolEdible(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolEdible = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolEdiblebtn =
      gridSearchResults_txtcolEdible.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolEdible =
      gridSearchResults_txtcolEdible.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolEdible.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolEdible.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolEdible", () => {
    // START_USER_CODE-USER_txtcolEdible_TEST
    // END_USER_CODE-USER_txtcolEdible_TEST
  });
  test("gridSearchResults_txtcolGen(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolGen = screen.getByTestId("gridSearchResults");
    let gridSearchResults_txtcolGenbtn =
      gridSearchResults_txtcolGen.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolGen =
      gridSearchResults_txtcolGen.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolGen.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolGen.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGen", () => {
    // START_USER_CODE-USER_txtcolGen_TEST
    // END_USER_CODE-USER_txtcolGen_TEST
  });
  test("gridSearchResults_txtcolGovCapacity(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolGovCapacity = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolGovCapacitybtn =
      gridSearchResults_txtcolGovCapacity.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolGovCapacity =
      gridSearchResults_txtcolGovCapacity.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolGovCapacity.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolGovCapacity.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGovCapacity", () => {
    // START_USER_CODE-USER_txtcolGovCapacity_TEST
    // END_USER_CODE-USER_txtcolGovCapacity_TEST
  });
  test("gridSearchResults_txtcolInTransit(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolInTransit = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolInTransitbtn =
      gridSearchResults_txtcolInTransit.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolInTransit =
      gridSearchResults_txtcolInTransit.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolInTransit.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolInTransit.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInTransit", () => {
    // START_USER_CODE-USER_txtcolInTransit_TEST
    // END_USER_CODE-USER_txtcolInTransit_TEST
  });
  test("gridSearchResults_txtcolLocation(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolLocation = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolLocationbtn =
      gridSearchResults_txtcolLocation.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolLocation =
      gridSearchResults_txtcolLocation.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolLocation.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolLocation.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLocation", () => {
    // START_USER_CODE-USER_txtcolLocation_TEST
    // END_USER_CODE-USER_txtcolLocation_TEST
  });
  test("gridSearchResults_txtcolOleic(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolOleic = screen.getByTestId("gridSearchResults");
    let gridSearchResults_txtcolOleicbtn =
      gridSearchResults_txtcolOleic.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolOleic =
      gridSearchResults_txtcolOleic.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolOleic.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolOleic.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOleic", () => {
    // START_USER_CODE-USER_txtcolOleic_TEST
    // END_USER_CODE-USER_txtcolOleic_TEST
  });
  test("gridSearchResults_txtcolOrganic(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolOrganic = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolOrganicbtn =
      gridSearchResults_txtcolOrganic.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolOrganic =
      gridSearchResults_txtcolOrganic.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolOrganic.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolOrganic.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOrganic", () => {
    // START_USER_CODE-USER_txtcolOrganic_TEST
    // END_USER_CODE-USER_txtcolOrganic_TEST
  });
  test("gridSearchResults_txtcolPeanutType(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolPeanutType = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolPeanutTypebtn =
      gridSearchResults_txtcolPeanutType.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolPeanutType =
      gridSearchResults_txtcolPeanutType.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolPeanutType.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolPeanutType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutType", () => {
    // START_USER_CODE-USER_txtcolPeanutType_TEST
    // END_USER_CODE-USER_txtcolPeanutType_TEST
  });
  test("gridSearchResults_txtcolPhysicalInventory(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolPhysicalInventory = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolPhysicalInventorybtn =
      gridSearchResults_txtcolPhysicalInventory.nextElementSibling
        .firstElementChild;
    gridSearchResults_txtcolPhysicalInventory =
      gridSearchResults_txtcolPhysicalInventory.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolPhysicalInventory.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolPhysicalInventory.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPhysicalInventory", () => {
    // START_USER_CODE-USER_txtcolPhysicalInventory_TEST
    // END_USER_CODE-USER_txtcolPhysicalInventory_TEST
  });
  test("gridSearchResults_txtcolSeg(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolSeg = screen.getByTestId("gridSearchResults");
    let gridSearchResults_txtcolSegbtn =
      gridSearchResults_txtcolSeg.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolSeg =
      gridSearchResults_txtcolSeg.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolSeg.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolSeg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg", () => {
    // START_USER_CODE-USER_txtcolSeg_TEST
    // END_USER_CODE-USER_txtcolSeg_TEST
  });
  test("gridSearchResults_txtcolTotalOpenStorage(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolTotalOpenStorage = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolTotalOpenStoragebtn =
      gridSearchResults_txtcolTotalOpenStorage.nextElementSibling
        .firstElementChild;
    gridSearchResults_txtcolTotalOpenStorage =
      gridSearchResults_txtcolTotalOpenStorage.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolTotalOpenStorage.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolTotalOpenStorage.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotalOpenStorage", () => {
    // START_USER_CODE-USER_txtcolTotalOpenStorage_TEST
    // END_USER_CODE-USER_txtcolTotalOpenStorage_TEST
  });
  test("gridSearchResults_txtcolUnDeterminedObligated(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolUnDeterminedObligated = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolUnDeterminedObligatedbtn =
      gridSearchResults_txtcolUnDeterminedObligated.nextElementSibling
        .firstElementChild;
    gridSearchResults_txtcolUnDeterminedObligated =
      gridSearchResults_txtcolUnDeterminedObligated.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolUnDeterminedObligated.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolUnDeterminedObligated.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUnDeterminedObligated", () => {
    // START_USER_CODE-USER_txtcolUnDeterminedObligated_TEST
    // END_USER_CODE-USER_txtcolUnDeterminedObligated_TEST
  });
  test("gridSearchResults_txtcolUnReceiptedObligated(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolUnReceiptedObligated = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolUnReceiptedObligatedbtn =
      gridSearchResults_txtcolUnReceiptedObligated.nextElementSibling
        .firstElementChild;
    gridSearchResults_txtcolUnReceiptedObligated =
      gridSearchResults_txtcolUnReceiptedObligated.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolUnReceiptedObligated.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolUnReceiptedObligated.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUnReceiptedObligated", () => {
    // START_USER_CODE-USER_txtcolUnReceiptedObligated_TEST
    // END_USER_CODE-USER_txtcolUnReceiptedObligated_TEST
  });
  test("gridSearchResults_txtcolVariety(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolVariety = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolVarietybtn =
      gridSearchResults_txtcolVariety.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolVariety =
      gridSearchResults_txtcolVariety.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolVariety.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolVariety.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVariety", () => {
    // START_USER_CODE-USER_txtcolVariety_TEST
    // END_USER_CODE-USER_txtcolVariety_TEST
  });
  test("gridSearchResults_txtcolWhse(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolWhse = screen.getByTestId("gridSearchResults");
    let gridSearchResults_txtcolWhsebtn =
      gridSearchResults_txtcolWhse.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolWhse =
      gridSearchResults_txtcolWhse.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolWhse.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolWhse.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWhse", () => {
    // START_USER_CODE-USER_txtcolWhse_TEST
    // END_USER_CODE-USER_txtcolWhse_TEST
  });
  test("gridSearchResults_txtcolWhsReceiptObligated(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolWhsReceiptObligated = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolWhsReceiptObligatedbtn =
      gridSearchResults_txtcolWhsReceiptObligated.nextElementSibling
        .firstElementChild;
    gridSearchResults_txtcolWhsReceiptObligated =
      gridSearchResults_txtcolWhsReceiptObligated.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolWhsReceiptObligated.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolWhsReceiptObligated.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryInquiry_gridSearchResults"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWhsReceiptObligated", () => {
    // START_USER_CODE-USER_txtcolWhsReceiptObligated_TEST
    // END_USER_CODE-USER_txtcolWhsReceiptObligated_TEST
  });
  test("txtCompanyOwned(Textbox Widget) Test Cases", async () => {
    const txtCompanyOwned = screen.getByTestId("txtCompanyOwned");
    expect(txtCompanyOwned.tagName).toBe("INPUT");
    expect(txtCompanyOwned.type).toBe("text");
    expect(txtCompanyOwned.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCompanyOwned, "1");
    });
  });
  test("Custom Test Cases for txtCompanyOwned", () => {
    // START_USER_CODE-USER_txtCompanyOwned_TEST
    // END_USER_CODE-USER_txtCompanyOwned_TEST
  });
  test("txtCrackedBrokenPer(Textbox Widget) Test Cases", async () => {
    const txtCrackedBrokenPer = screen.getByTestId("txtCrackedBrokenPer");
    expect(txtCrackedBrokenPer.tagName).toBe("INPUT");
    expect(txtCrackedBrokenPer.type).toBe("text");
    expect(txtCrackedBrokenPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCrackedBrokenPer, "1");
    });
  });
  test("Custom Test Cases for txtCrackedBrokenPer", () => {
    // START_USER_CODE-USER_txtCrackedBrokenPer_TEST
    // END_USER_CODE-USER_txtCrackedBrokenPer_TEST
  });
  test("txtDamagePer(Textbox Widget) Test Cases", async () => {
    const txtDamagePer = screen.getByTestId("txtDamagePer");
    expect(txtDamagePer.tagName).toBe("INPUT");
    expect(txtDamagePer.type).toBe("text");
    expect(txtDamagePer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDamagePer, "1");
    });
  });
  test("Custom Test Cases for txtDamagePer", () => {
    // START_USER_CODE-USER_txtDamagePer_TEST
    // END_USER_CODE-USER_txtDamagePer_TEST
  });
  test("txtDiscoloredPer(Textbox Widget) Test Cases", async () => {
    const txtDiscoloredPer = screen.getByTestId("txtDiscoloredPer");
    expect(txtDiscoloredPer.tagName).toBe("INPUT");
    expect(txtDiscoloredPer.type).toBe("text");
    expect(txtDiscoloredPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDiscoloredPer, "1");
    });
  });
  test("Custom Test Cases for txtDiscoloredPer", () => {
    // START_USER_CODE-USER_txtDiscoloredPer_TEST
    // END_USER_CODE-USER_txtDiscoloredPer_TEST
  });
  test("txtELKPer(Textbox Widget) Test Cases", async () => {
    const txtELKPer = screen.getByTestId("txtELKPer");
    expect(txtELKPer.tagName).toBe("INPUT");
    expect(txtELKPer.type).toBe("text");
    expect(txtELKPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtELKPer, "1");
    });
  });
  test("Custom Test Cases for txtELKPer", () => {
    // START_USER_CODE-USER_txtELKPer_TEST
    // END_USER_CODE-USER_txtELKPer_TEST
  });
  test("txtFancyPer(Textbox Widget) Test Cases", async () => {
    const txtFancyPer = screen.getByTestId("txtFancyPer");
    expect(txtFancyPer.tagName).toBe("INPUT");
    expect(txtFancyPer.type).toBe("text");
    expect(txtFancyPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFancyPer, "1");
    });
  });
  test("Custom Test Cases for txtFancyPer", () => {
    // START_USER_CODE-USER_txtFancyPer_TEST
    // END_USER_CODE-USER_txtFancyPer_TEST
  });
  test("txtFMPer(Textbox Widget) Test Cases", async () => {
    const txtFMPer = screen.getByTestId("txtFMPer");
    expect(txtFMPer.tagName).toBe("INPUT");
    expect(txtFMPer.type).toBe("text");
    expect(txtFMPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFMPer, "1");
    });
  });
  test("Custom Test Cases for txtFMPer", () => {
    // START_USER_CODE-USER_txtFMPer_TEST
    // END_USER_CODE-USER_txtFMPer_TEST
  });
  test("txtFreezeDamagePer(Textbox Widget) Test Cases", async () => {
    const txtFreezeDamagePer = screen.getByTestId("txtFreezeDamagePer");
    expect(txtFreezeDamagePer.tagName).toBe("INPUT");
    expect(txtFreezeDamagePer.type).toBe("text");
    expect(txtFreezeDamagePer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFreezeDamagePer, "1");
    });
  });
  test("Custom Test Cases for txtFreezeDamagePer", () => {
    // START_USER_CODE-USER_txtFreezeDamagePer_TEST
    // END_USER_CODE-USER_txtFreezeDamagePer_TEST
  });
  test("txtGovCapacity(Textbox Widget) Test Cases", async () => {
    const txtGovCapacity = screen.getByTestId("txtGovCapacity");
    expect(txtGovCapacity.tagName).toBe("INPUT");
    expect(txtGovCapacity.type).toBe("text");
    expect(txtGovCapacity.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtGovCapacity, "1");
    });
  });
  test("Custom Test Cases for txtGovCapacity", () => {
    // START_USER_CODE-USER_txtGovCapacity_TEST
    // END_USER_CODE-USER_txtGovCapacity_TEST
  });
  test("txtHullBrightnessPer(Textbox Widget) Test Cases", async () => {
    const txtHullBrightnessPer = screen.getByTestId("txtHullBrightnessPer");
    expect(txtHullBrightnessPer.tagName).toBe("INPUT");
    expect(txtHullBrightnessPer.type).toBe("text");
    expect(txtHullBrightnessPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullBrightnessPer, "1");
    });
  });
  test("Custom Test Cases for txtHullBrightnessPer", () => {
    // START_USER_CODE-USER_txtHullBrightnessPer_TEST
    // END_USER_CODE-USER_txtHullBrightnessPer_TEST
  });
  test("txtHullPer(Textbox Widget) Test Cases", async () => {
    const txtHullPer = screen.getByTestId("txtHullPer");
    expect(txtHullPer.tagName).toBe("INPUT");
    expect(txtHullPer.type).toBe("text");
    expect(txtHullPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullPer, "1");
    });
  });
  test("Custom Test Cases for txtHullPer", () => {
    // START_USER_CODE-USER_txtHullPer_TEST
    // END_USER_CODE-USER_txtHullPer_TEST
  });
  test("txtInTransitTon(Textbox Widget) Test Cases", async () => {
    const txtInTransitTon = screen.getByTestId("txtInTransitTon");
    expect(txtInTransitTon.tagName).toBe("INPUT");
    expect(txtInTransitTon.type).toBe("text");
    expect(txtInTransitTon.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtInTransitTon, "1");
    });
  });
  test("Custom Test Cases for txtInTransitTon", () => {
    // START_USER_CODE-USER_txtInTransitTon_TEST
    // END_USER_CODE-USER_txtInTransitTon_TEST
  });
  test("txtJumboPer(Textbox Widget) Test Cases", async () => {
    const txtJumboPer = screen.getByTestId("txtJumboPer");
    expect(txtJumboPer.tagName).toBe("INPUT");
    expect(txtJumboPer.type).toBe("text");
    expect(txtJumboPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtJumboPer, "1");
    });
  });
  test("Custom Test Cases for txtJumboPer", () => {
    // START_USER_CODE-USER_txtJumboPer_TEST
    // END_USER_CODE-USER_txtJumboPer_TEST
  });
  test("txtLSKPer(Textbox Widget) Test Cases", async () => {
    const txtLSKPer = screen.getByTestId("txtLSKPer");
    expect(txtLSKPer.tagName).toBe("INPUT");
    expect(txtLSKPer.type).toBe("text");
    expect(txtLSKPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLSKPer, "1");
    });
  });
  test("Custom Test Cases for txtLSKPer", () => {
    // START_USER_CODE-USER_txtLSKPer_TEST
    // END_USER_CODE-USER_txtLSKPer_TEST
  });
  test("txtMoistPer(Textbox Widget) Test Cases", async () => {
    const txtMoistPer = screen.getByTestId("txtMoistPer");
    expect(txtMoistPer.tagName).toBe("INPUT");
    expect(txtMoistPer.type).toBe("text");
    expect(txtMoistPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMoistPer, "1");
    });
  });
  test("Custom Test Cases for txtMoistPer", () => {
    // START_USER_CODE-USER_txtMoistPer_TEST
    // END_USER_CODE-USER_txtMoistPer_TEST
  });
  test("txtOKPer(Textbox Widget) Test Cases", async () => {
    const txtOKPer = screen.getByTestId("txtOKPer");
    expect(txtOKPer.tagName).toBe("INPUT");
    expect(txtOKPer.type).toBe("text");
    expect(txtOKPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOKPer, "1");
    });
  });
  test("Custom Test Cases for txtOKPer", () => {
    // START_USER_CODE-USER_txtOKPer_TEST
    // END_USER_CODE-USER_txtOKPer_TEST
  });
  test("txtPhysical(Textbox Widget) Test Cases", async () => {
    const txtPhysical = screen.getByTestId("txtPhysical");
    expect(txtPhysical.tagName).toBe("INPUT");
    expect(txtPhysical.type).toBe("text");
    expect(txtPhysical.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPhysical, "1");
    });
  });
  test("Custom Test Cases for txtPhysical", () => {
    // START_USER_CODE-USER_txtPhysical_TEST
    // END_USER_CODE-USER_txtPhysical_TEST
  });
  test("txtPitDamagePer(Textbox Widget) Test Cases", async () => {
    const txtPitDamagePer = screen.getByTestId("txtPitDamagePer");
    expect(txtPitDamagePer.tagName).toBe("INPUT");
    expect(txtPitDamagePer.type).toBe("text");
    expect(txtPitDamagePer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPitDamagePer, "1");
    });
  });
  test("Custom Test Cases for txtPitDamagePer", () => {
    // START_USER_CODE-USER_txtPitDamagePer_TEST
    // END_USER_CODE-USER_txtPitDamagePer_TEST
  });
  test("txtSMKPer(Textbox Widget) Test Cases", async () => {
    const txtSMKPer = screen.getByTestId("txtSMKPer");
    expect(txtSMKPer.tagName).toBe("INPUT");
    expect(txtSMKPer.type).toBe("text");
    expect(txtSMKPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMKPer, "1");
    });
  });
  test("Custom Test Cases for txtSMKPer", () => {
    // START_USER_CODE-USER_txtSMKPer_TEST
    // END_USER_CODE-USER_txtSMKPer_TEST
  });
  test("txtSSPer(Textbox Widget) Test Cases", async () => {
    const txtSSPer = screen.getByTestId("txtSSPer");
    expect(txtSSPer.tagName).toBe("INPUT");
    expect(txtSSPer.type).toBe("text");
    expect(txtSSPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSSPer, "1");
    });
  });
  test("Custom Test Cases for txtSSPer", () => {
    // START_USER_CODE-USER_txtSSPer_TEST
    // END_USER_CODE-USER_txtSSPer_TEST
  });
  test("txtTKCPer(Textbox Widget) Test Cases", async () => {
    const txtTKCPer = screen.getByTestId("txtTKCPer");
    expect(txtTKCPer.tagName).toBe("INPUT");
    expect(txtTKCPer.type).toBe("text");
    expect(txtTKCPer.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTKCPer, "1");
    });
  });
  test("Custom Test Cases for txtTKCPer", () => {
    // START_USER_CODE-USER_txtTKCPer_TEST
    // END_USER_CODE-USER_txtTKCPer_TEST
  });
  test("txtTotalOpenStorage(Textbox Widget) Test Cases", async () => {
    const txtTotalOpenStorage = screen.getByTestId(
      "txtTotalOpenStorage"
    );
    expect(txtTotalOpenStorage.tagName).toBe("INPUT");
    expect(txtTotalOpenStorage.type).toBe("text");
    expect(txtTotalOpenStorage.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalOpenStorage, "1");
    });
  });
  test("Custom Test Cases for txtTotalOpenStorage", () => {
    // START_USER_CODE-USER_txtTotalOpenStorage_TEST
    // END_USER_CODE-USER_txtTotalOpenStorage_TEST
  });
  test("txtUnDetermined(Textbox Widget) Test Cases", async () => {
    const txtUnDetermined = screen.getByTestId("txtUnDetermined");
    expect(txtUnDetermined.tagName).toBe("INPUT");
    expect(txtUnDetermined.type).toBe("text");
    expect(txtUnDetermined.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtUnDetermined, "1");
    });
  });
  test("Custom Test Cases for txtUnDetermined", () => {
    // START_USER_CODE-USER_txtUnDetermined_TEST
    // END_USER_CODE-USER_txtUnDetermined_TEST
  });
  test("txtUnReceipted(Textbox Widget) Test Cases", async () => {
    const txtUnReceipted = screen.getByTestId("txtUnReceipted");
    expect(txtUnReceipted.tagName).toBe("INPUT");
    expect(txtUnReceipted.type).toBe("text");
    expect(txtUnReceipted.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtUnReceipted, "1");
    });
  });
  test("Custom Test Cases for txtUnReceipted", () => {
    // START_USER_CODE-USER_txtUnReceipted_TEST
    // END_USER_CODE-USER_txtUnReceipted_TEST
  });
  test("txtVicamPPB(Textbox Widget) Test Cases", async () => {
    const txtVicamPPB = screen.getByTestId("txtVicamPPB");
    expect(txtVicamPPB.tagName).toBe("INPUT");
    expect(txtVicamPPB.type).toBe("text");
    expect(txtVicamPPB.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVicamPPB, "1");
    });
  });
  test("Custom Test Cases for txtVicamPPB", () => {
    // START_USER_CODE-USER_txtVicamPPB_TEST
    // END_USER_CODE-USER_txtVicamPPB_TEST
  });
  test("txtWarehouse(Textbox Widget) Test Cases", async () => {
    const txtWarehouse = screen.getByTestId("txtWarehouse");
    expect(txtWarehouse.tagName).toBe("INPUT");
    expect(txtWarehouse.type).toBe("text");
    expect(txtWarehouse.classList).toContain("textboxWidgetClass");
    expect(txtWarehouse.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:InventoryInquiry_txtWarehouse")
    );
    await act(async () => {
      userEvent.type(txtWarehouse, "1");
    });
  });
  test("Custom Test Cases for txtWarehouse", () => {
    // START_USER_CODE-USER_txtWarehouse_TEST
    // END_USER_CODE-USER_txtWarehouse_TEST
  });
  test("txtWarehouseReceipt(Textbox Widget) Test Cases", async () => {
    const txtWarehouseReceipt = screen.getByTestId(
      "txtWarehouseReceipt"
    );
    expect(txtWarehouseReceipt.tagName).toBe("INPUT");
    expect(txtWarehouseReceipt.type).toBe("text");
    expect(txtWarehouseReceipt.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtWarehouseReceipt, "1");
    });
  });
  test("Custom Test Cases for txtWarehouseReceipt", () => {
    // START_USER_CODE-USER_txtWarehouseReceipt_TEST
    // END_USER_CODE-USER_txtWarehouseReceipt_TEST
  });
});
