/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DateWidget,
  ButtonWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  setValue,
  getValue,
  setData,
  getData,
  enable,
  disable,
  goTo
} from "../../shared/WindowImports";

import "./TradeSettleScaleTicket.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { number } from "yup";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function Settlements_TradeSettleScaleTicket(props) {
  const contextType = useAppContext();
  const screenRef = useRef();

  let states = {
    Label: "TradeSettleScaleTicket",
    windowName: "TradeSettleScaleTicket",
    template: "Standard Screen",
    Cols: "4",
    ColsForMobile: "4",
    ColsForTabLandscape: "4",
    ColsForTabPotrait: "4",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.TradeSettleScaleTicket",
    // START_USER_CODE-USER_TradeSettleScaleTicket_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Trade Settle Scale Ticket",
      scrCode: "PN1140C",
    },
    // END_USER_CODE-USER_TradeSettleScaleTicket_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxUpdate",
      Label: "Cancel",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxUpdate",
      Label: "Update",
      CharWidth: "15",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    chkBoxRecdSplit: {
      name: "chkBoxRecdSplit",
      type: "CheckBoxWidget",
      parent: "grpbxRecdWeight",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkBoxRecdSplit_PROPERTIES

      // END_USER_CODE-USER_chkBoxRecdSplit_PROPERTIES
    },
    chkBoxSplitWeight: {
      name: "chkBoxSplitWeight",
      type: "CheckBoxWidget",
      parent: "grpbxShippedWeight",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkBoxSplitWeight_PROPERTIES

      // END_USER_CODE-USER_chkBoxSplitWeight_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxUpdate",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByVal: {
      name: "lblAddedByVal",
      type: "LabelWidget",
      parent: "grpbxUpdate",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByVal_PROPERTIES

      // END_USER_CODE-USER_lblAddedByVal_PROPERTIES
    },
    lblChngdBy: {
      name: "lblChngdBy",
      type: "LabelWidget",
      parent: "grpbxUpdate",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChngdBy_PROPERTIES

      // END_USER_CODE-USER_lblChngdBy_PROPERTIES
    },
    lblChngdByValue: {
      name: "lblChngdByValue",
      type: "LabelWidget",
      parent: "grpbxUpdate",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChngdByValue_PROPERTIES

      // END_USER_CODE-USER_lblChngdByValue_PROPERTIES
    },
    lblDateFormat: {
      name: "lblDateFormat",
      type: "LabelWidget",
      parent: "grpbxShippedWeight",
      Label: "mm/dd/yyyy",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDateFormat_PROPERTIES

      // END_USER_CODE-USER_lblDateFormat_PROPERTIES
    },
    lblLbs: {
      name: "lblLbs",
      type: "LabelWidget",
      parent: "grpbxWeightVariance",
      Label: "Lbs.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLbs_PROPERTIES

      // END_USER_CODE-USER_lblLbs_PROPERTIES
    },
    lblLoadInfo: {
      name: "lblLoadInfo",
      type: "LabelWidget",
      parent: "grpbxLoadInfo",
      Label: "Load Information",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLoadInfo_PROPERTIES

      // END_USER_CODE-USER_lblLoadInfo_PROPERTIES
    },
    lblPeanutVal: {
      name: "lblPeanutVal",
      type: "LabelWidget",
      parent: "grpbxRecdWeight",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPeanutVal_PROPERTIES

      // END_USER_CODE-USER_lblPeanutVal_PROPERTIES
    },
    lblPnut: {
      name: "lblPnut",
      type: "LabelWidget",
      parent: "grpbxRecdWeight",
      Label: "Lbs. Of Peanuts:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPnut_PROPERTIES

      // END_USER_CODE-USER_lblPnut_PROPERTIES
    },
    lblPnuts: {
      name: "lblPnuts",
      type: "LabelWidget",
      parent: "grpbxShippedWeight",
      Label: "Lbs. Of Peanuts:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPnuts_PROPERTIES

      // END_USER_CODE-USER_lblPnuts_PROPERTIES
    },
    lblPnutVal: {
      name: "lblPnutVal",
      type: "LabelWidget",
      parent: "grpbxShippedWeight",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPnutVal_PROPERTIES

      // END_USER_CODE-USER_lblPnutVal_PROPERTIES
    },
    lblRecdDateFormat: {
      name: "lblRecdDateFormat",
      type: "LabelWidget",
      parent: "grpbxRecdWeight",
      Label: "mm/dd/yyyy",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRecdDateFormat_PROPERTIES

      // END_USER_CODE-USER_lblRecdDateFormat_PROPERTIES
    },
    lblRecdSplitWeight: {
      name: "lblRecdSplitWeight",
      type: "LabelWidget",
      parent: "grpbxRecdWeight",
      Label: "Split Weight:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRecdSplitWeight_PROPERTIES

      // END_USER_CODE-USER_lblRecdSplitWeight_PROPERTIES
    },
    lblRecdWeight: {
      name: "lblRecdWeight",
      type: "LabelWidget",
      parent: "grpbxRecdWeight",
      Label: "Received Weight",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRecdWeight_PROPERTIES

      // END_USER_CODE-USER_lblRecdWeight_PROPERTIES
    },
    lblShippedWight: {
      name: "lblShippedWight",
      type: "LabelWidget",
      parent: "grpbxShippedWeight",
      Label: "Shipped Weight",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblShippedWight_PROPERTIES

      // END_USER_CODE-USER_lblShippedWight_PROPERTIES
    },
    lblSpltWght: {
      name: "lblSpltWght",
      type: "LabelWidget",
      parent: "grpbxShippedWeight",
      Label: "Split Weight:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpltWght_PROPERTIES

      // END_USER_CODE-USER_lblSpltWght_PROPERTIES
    },
    lblUpdateInventory: {
      name: "lblUpdateInventory",
      type: "LabelWidget",
      parent: "grpbxUpdateInventory",
      Label: "Update Inventory With:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblUpdateInventory_PROPERTIES

      // END_USER_CODE-USER_lblUpdateInventory_PROPERTIES
    },
    radioWeight: {
      name: "radioWeight",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxUpdateInventory",
      Options: "Shipped Weight:1,Received Weight:2,Other Weight:3",
      HasLabel: false,
      RowSpan: "3",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioWeight_PROPERTIES

      // END_USER_CODE-USER_radioWeight_PROPERTIES
    },
    txtDriver: {
      name: "txtDriver",
      type: "TextBoxWidget",
      parent: "grpbxLoadInfo",
      Label: "Driver:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 36 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDriver_PROPERTIES

      // END_USER_CODE-USER_txtDriver_PROPERTIES
    },
    txtIncludingVehicle: {
      name: "txtIncludingVehicle",
      type: "TextBoxWidget",
      parent: "grpbxShippedWeight",
      Label: "Wt. Including Vehicle:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      formattingReqd: true,
      // regExp: {
      //   RegularExpression: "^[1-9]\d*$",
      //   ValidatorMessage: ""
      // },
      // START_USER_CODE-USER_txtIncludingVehicle_PROPERTIES

      // END_USER_CODE-USER_txtIncludingVehicle_PROPERTIES
    },
    txtLoad: {
      name: "txtLoad",
      type: "TextBoxWidget",
      parent: "grpbxLoadInfo",
      Label: "Load #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      formattingReqd: true,
      // regExp: {
      //   RegularExpression: "^[1-9]\d*$",
      //   ValidatorMessage: ""
      // },
      // START_USER_CODE-USER_txtLoad_PROPERTIES

      // END_USER_CODE-USER_txtLoad_PROPERTIES
    },
    txtOtherWt: {
      name: "txtOtherWt",
      type: "TextBoxWidget",
      parent: "grpbxUpdateInventory",
      Label: ":",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      formattingReqd:true,
      // regExp: {
      //   RegularExpression: "^[1-9]\d*$",
      //   ValidatorMessage: ""
      // },
      // START_USER_CODE-USER_txtOtherWt_PROPERTIES

      // END_USER_CODE-USER_txtOtherWt_PROPERTIES
    },
   
    txtRecdDate: {
      name: "txtRecdDate",
      type: "DateWidget",
      parent: "grpbxRecdWeight",
      Label: "Received Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtInspDateTime_PROPERTIES

      // END_USER_CODE-USER_txtInspDateTime_PROPERTIES
    },
    txtRecdScaleTicket: {
      name: "txtRecdScaleTicket",
      type: "TextBoxWidget",
      parent: "grpbxRecdWeight",
      Label: "Scale Ticket #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRecdScaleTicket_PROPERTIES

      // END_USER_CODE-USER_txtRecdScaleTicket_PROPERTIES
    },
    txtRecdWtIncVehicle: {
      name: "txtRecdWtIncVehicle",
      type: "TextBoxWidget",
      parent: "grpbxRecdWeight",
      Label: "Wt. Including Vehicle:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      formattingReqd: true,
      // regExp: {
      //   RegularExpression: "^[1-9]\d*$",
      //   ValidatorMessage: ""
      // },
      // START_USER_CODE-USER_txtRecdWtIncVehicle_PROPERTIES

      // END_USER_CODE-USER_txtRecdWtIncVehicle_PROPERTIES
    },
    txtRecdWtVehicle: {
      name: "txtRecdWtVehicle",
      type: "TextBoxWidget",
      parent: "grpbxRecdWeight",
      Label: "Wt. Of vehicle:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      formattingReqd: true,
      // regExp: {
      //   RegularExpression: "^[1-9]\d*$",
      //   ValidatorMessage: ""
      // },
      // START_USER_CODE-USER_txtRecdWtVehicle_PROPERTIES

      // END_USER_CODE-USER_txtRecdWtVehicle_PROPERTIES
    },
    txtScaleTicket: {
      name: "txtScaleTicket",
      type: "TextBoxWidget",
      parent: "grpbxShippedWeight",
      Label: "Scale Ticket #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtScaleTicket_PROPERTIES

      // END_USER_CODE-USER_txtScaleTicket_PROPERTIES
    },   
    txtShippingDate: {
      name: "txtShippingDate",
      type: "DateWidget",
      parent: "grpbxShippedWeight",
      Label: "Shipped Date :",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtInspDateTime_PROPERTIES

      // END_USER_CODE-USER_txtInspDateTime_PROPERTIES
    },
    txtTruckLine: {
      name: "txtTruckLine",
      type: "TextBoxWidget",
      parent: "grpbxLoadInfo",
      Label: "Truck Line:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 36 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTruckLine_PROPERTIES

      // END_USER_CODE-USER_txtTruckLine_PROPERTIES
    },
    txtVehicle: {
      name: "txtVehicle",
      type: "TextBoxWidget",
      parent: "grpbxLoadInfo",
      Label: "Vehicle #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 21 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVehicle_PROPERTIES

      // END_USER_CODE-USER_txtVehicle_PROPERTIES
    },
    txtWtOfVehicle: {
      name: "txtWtOfVehicle",
      type: "TextBoxWidget",
      parent: "grpbxShippedWeight",
      Label: "Wt. Of vehicle:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      formattingReqd: true,
      // regExp: {
      //   RegularExpression: "^[1-9]\d*$",
      //   ValidatorMessage: ""
      // },
      // START_USER_CODE-USER_txtWtOfVehicle_PROPERTIES

      // END_USER_CODE-USER_txtWtOfVehicle_PROPERTIES
    },
    txtWtVariance: {
      name: "txtWtVariance",
      type: "TextBoxWidget",
      parent: "grpbxWeightVariance",
      Label: "Weight Variance:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 25 },
      ofTypeDomain: "d_String",
      formattingReqd: true,
      // regExp: {
      //   RegularExpression: "^[1-9]\d*$",
      //   ValidatorMessage: ""
      // },
      // START_USER_CODE-USER_txtWtVariance_PROPERTIES

      // END_USER_CODE-USER_txtWtVariance_PROPERTIES
    },
    grpbxLoadInfo: {
      name: "grpbxLoadInfo",
      type: "GroupBoxWidget",
      parent: "TradeSettleScaleTicket",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLoadInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxLoadInfo_PROPERTIES
    },
    grpbxShippedWeight: {
      name: "grpbxShippedWeight",
      type: "GroupBoxWidget",
      parent: "TradeSettleScaleTicket",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxShippedWeight_PROPERTIES

      // END_USER_CODE-USER_grpbxShippedWeight_PROPERTIES
    },
    grpbxRecdWeight: {
      name: "grpbxRecdWeight",
      type: "GroupBoxWidget",
      parent: "TradeSettleScaleTicket",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxRecdWeight_PROPERTIES

      // END_USER_CODE-USER_grpbxRecdWeight_PROPERTIES
    },
    grpbxUpdateInventory: {
      name: "grpbxUpdateInventory",
      type: "GroupBoxWidget",
      parent: "TradeSettleScaleTicket",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxUpdateInventory_PROPERTIES

      // END_USER_CODE-USER_grpbxUpdateInventory_PROPERTIES
    },
    grpbxWeightVariance: {
      name: "grpbxWeightVariance",
      type: "GroupBoxWidget",
      parent: "TradeSettleScaleTicket",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxWeightVariance_PROPERTIES

      // END_USER_CODE-USER_grpbxWeightVariance_PROPERTIES
    },
    grpbxUpdate: {
      name: "grpbxUpdate",
      type: "GroupBoxWidget",
      parent: "TradeSettleScaleTicket",
      ColSpan: "4",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxUpdate_PROPERTIES

      // END_USER_CODE-USER_grpbxUpdate_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {},
      "ContractManagement#MainMenu": {},
      REVERSE: {},
      "ContractManagement#MainMenu": {},
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {    
  };
  let caption = '';
  var isFormValid = true;
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    FormLoad();
  }, []);  

  //Start method for Form load function callled in use effect
  function FormLoad() {
    caption = getData(thisObj, "TradeSettleScaleTicketdata");
    if(caption.cmdok == 'Add'){
      document.getElementsByClassName('btnUpdate')[0].childNodes[0].childNodes[0].innerText = "Add";
    }
    else{
      if ((caption.status) !== 'Pending') {
        disable(thisObj, "btnUpdate");
      }
      else {
        enable(thisObj, "btnUpdate");
      }
      InventoryUpdate();
    }  
    EnableDisableControls();
    bindvariance();
  }
  //End method for Form load function callled in use effect

// Start Method for Enable and Disable Controls  
  const EnableDisableControls = () => {
    if(document.getElementById("radioWeight2").checked == true){
      enable(thisObj,"txtOtherWt");
    }
    else{
      disable(thisObj, "txtOtherWt");
    }
    disable(thisObj,"txtWtVariance");
  }
  // End Method for Enable and Disable Controls 

 
  let sWeightInd = "";
  let shippedLbsOfPnut = "";
  let receivedLbsOfPnut = "";
  let shippedchkBoxSplitWeight = "";
  let receivedchkBoxRecdSplit = "";
  let txtOtherWt = "";
  let variance = "";
  let data = getData(thisObj, "TradeSettleScaleTicketdata"); 
  setValue(thisObj, "txtLoad",data.txtcolLoad);
  setValue(thisObj, "txtVehicle",data.txtcolVehicle);  
  setValue(thisObj, "txtTruckLine",data.truck_line);
  setValue(thisObj, "txtDriver",data.driver_name);  
  setValue(thisObj, "txtShippingDate",ConvertToDate(data.txtcolShipDate));
  setValue(thisObj, "txtScaleTicket",data.txtcolShipTicket);
  setValue(thisObj, "txtRecdDate",ConvertToDate(data.txtcolRecvDate));
  setValue(thisObj, "txtRecdScaleTicket",data.txtcolRecvTicket);
  setValue(thisObj, "lblPeanutVal",data.lblRLbsOfPeanuts);
  setValue(thisObj, "lblPnutVal",data.lblSLbsOfPeanuts);  
  setValue(thisObj, "txtOtherWt", data.txtOtherWeight);
  setValue(thisObj, "txtIncludingVehicle",data.txtSWeightIncludingVehicle);
  setValue(thisObj, "txtWtOfVehicle",data.txtSWeightOfVehicle);
  setValue(thisObj, "txtRecdWtIncVehicle",data.txtRWeightIncludingVehicle);
  setValue(thisObj, "txtRecdWtVehicle",data.txtRWeightOfVehicle); 
  
  // Start method to get the Inventory Updates
  function InventoryUpdate(){
    if(data.arrTicketInfo.inventory_with_ind == "S"){
      setValue(thisObj,"radioWeight","1");
    }
    else if(data.arrTicketInfo.inventory_with_ind == "R"){
      setValue(thisObj,"radioWeight","2");
    }
    else if (data.arrTicketInfo.inventory_with_ind == "O"){
      setValue(thisObj,"radioWeight","3");
    }
    else{
      setValue(thisObj,"radioWeight","");
    }

    if(data.chkSSplitWeight == true){
      setValue(thisObj,"chkBoxSplitWeight","Y");
    }
    else{
      setValue(thisObj,"chkBoxSplitWeight","");
    }

    if(data.chkRSplitWeight == true){
      setValue(thisObj,"chkBoxSplitWeight","Y");
    }
    else{
      setValue(thisObj,"chkBoxSplitWeight","");
    }
  }
  // End method to get the Inventory Updates

  //Start Method to slice the date time
  function ConvertToDate(str) {
    try {
      if (str !== undefined && str !== '') {
        let date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
      } else {
        return '';
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ConvertToDate event:Click");
      }
      return false;
    }
    return true;
  }
  //End Method to slice the date time

  // Start method for Add and Update the record
 // PRB0054059 - Validate Load Number correctly

  const onbtnUpdateClick = () => {
    let Load = getValue(thisObj, "txtLoad");
    if (!Load || isNaN(Load)) {
      Load = 1;
    }
    setData(thisObj, "txtLoad", Load);
    let Vehical = getValue(thisObj, "txtVehicle");
    let truckLine = getValue(thisObj, "txtTruckLine");
    let driver = getValue(thisObj, "txtDriver");
    let shippedDate = getValue(thisObj, "txtShippingDate");
    if(shippedDate !== undefined && shippedDate !== null && shippedDate !== "" && shippedDate !== "aN/aN/NaN"){
      shippedDate = getValue(thisObj, "txtShippingDate");
    }
    else{
      shippedDate = "";
    }
    let shippedScaleTicket = getValue(thisObj, "txtScaleTicket");
    let shippedWtIncluding = getValue(thisObj, "txtIncludingVehicle");
    let shippedWtofVehichle = getValue(thisObj, "txtWtOfVehicle");
    let receiveDate = getValue(thisObj, "txtRecdDate");
    if(receiveDate !== undefined && receiveDate !== null && receiveDate !== "" && receiveDate !== "aN/aN/NaN"){
      receiveDate = getValue(thisObj, "txtRecdDate");
    }
    else{
      receiveDate = "";
    }
    let receivedScaleticket = getValue(thisObj, "txtRecdScaleTicket");
    let receivedWtIncluding = getValue(thisObj, "txtRecdWtIncVehicle");
    let receivedWtofVehichle = getValue(thisObj, "txtRecdWtVehicle");
    shippedLbsOfPnut = getValue(thisObj,"lblPnutVal");
    receivedLbsOfPnut = getValue(thisObj,"lblPeanutVal");
    if (document.getElementById('chkBoxSplitWeight').checked == true) {
      shippedchkBoxSplitWeight = "Y"
    }
    else {
      shippedchkBoxSplitWeight = ""
    }
  
    if (document.getElementById('chkBoxRecdSplit').checked == true) {
      receivedchkBoxRecdSplit = "Y"
    }
    else {
      receivedchkBoxRecdSplit = "";
    }
    if (document.getElementById('radioWeight0').checked == true) {
      sWeightInd = "S";     
    }
    else if (document.getElementById('radioWeight1').checked == true) {
      sWeightInd = "R";      
    }
    else {
      sWeightInd = "O";      
      txtOtherWt = getValue(thisObj, "txtOtherWt");
      if(sWeightInd == "O"){
        enable(thisObj,"txtWtVariance");
      }
    }
    variance = getValue(thisObj, "txtWtVariance");
    
    let obj = {};
      // PRB0054059 - Validate Load Number correctly
      if (caption = document.getElementsByClassName('btnUpdate')[0].childNodes[0].childNodes[0].innerText == "Add")
        {
        bFormValid();
        if(isFormValid) {
          obj.load_num=Load,
        obj.vehicle_num=Vehical,
        obj.truck_line=truckLine,
        obj.driver_name=driver,
        obj.shp_date=ConvertToDate(shippedDate),
        obj.shp_scale_tick=shippedScaleTicket,
        obj.shp_tare_wt=shippedWtIncluding,
        obj.shp_vehicle_wt=shippedWtofVehichle,
        obj.rec_date=ConvertToDate(receiveDate),
        obj.rec_scale_tick=receivedScaleticket,
        obj.rec_tare_wt=receivedWtIncluding,
        obj.rec_vehicle_wt=receivedWtofVehichle,
        obj.shp_split_scale=shippedchkBoxSplitWeight,
        obj.rec_split_scale=receivedchkBoxRecdSplit,
        obj.inventory_with_ind=sWeightInd,
        obj.variance=variance,
        obj.shp_peanut_wt=shippedLbsOfPnut,
        obj.rec_peanut_wt=receivedLbsOfPnut,
        obj.inventory_other_wt=txtOtherWt
      }           
    }
    else {        
      bFormValid();
      if(isFormValid ==  true){
      obj.load_num=Load,
      obj.vehicle_num=Vehical,
      obj.truck_line=truckLine,
      obj.driver_name=driver,
      obj.shp_date=ConvertToDate(shippedDate),
      obj.shp_scale_tick=shippedScaleTicket,
      obj.shp_tare_wt=shippedWtIncluding,
      obj.shp_vehicle_wt=shippedWtofVehichle,
      obj.rec_date=ConvertToDate(receiveDate),

      obj.rec_scale_tick=receivedScaleticket,
      obj.rec_tare_wt=receivedWtIncluding,
      obj.rec_vehicle_wt=receivedWtofVehichle,
      obj.shp_split_scale=shippedchkBoxSplitWeight,
      obj.rec_split_scale=receivedchkBoxRecdSplit,
      obj.inventory_with_ind=sWeightInd,
      obj.variance=variance,
      obj.shp_peanut_wt=shippedLbsOfPnut,
      obj.rec_peanut_wt=receivedLbsOfPnut,
      obj.inventory_other_wt=txtOtherWt
    } 
  }
  if(isFormValid == true){
    setData(thisObj, "ScaleTicketData", obj);
    document.getElementById("Settlements_TradeSettleScaleTicketPopUp").childNodes[0].click();
  }

  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick
  // Start method for Add and Update the record

  // Start Method for cancel button to close the popup
  const onbtnCancelClick = () =>{
    document.getElementById("Settlements_TradeSettleScaleTicketPopUp").childNodes[0].click();
  }
  thisObj.onbtnCancelClick=onbtnCancelClick
  // End Method for cancel button to close the popup

  // Start Method to calculate the shipped total and called on shipped WtInclucing Vehichle textbox change event
  function CalculateShippedTotal() {
    let shippedWtIncluding = getValue(thisObj, "txtIncludingVehicle");
    let shippedWtofVehichle = getValue(thisObj, "txtWtOfVehicle");
    if(shippedWtIncluding == undefined){
      shippedWtIncluding = 0;
    }    
    if(shippedWtofVehichle == undefined){
      shippedWtofVehichle = 0;
    }  
    let shippedLbsOfPnut = "";
    if ((Number(shippedWtIncluding) || shippedWtIncluding == 0) && (Number(shippedWtofVehichle) || shippedWtofVehichle == 0)) {
      if (shippedWtIncluding == '' && shippedWtofVehichle == '') {
        shippedLbsOfPnut = '';
        setData(thisObj, "lblPnutVal", shippedLbsOfPnut);
        setValue(thisObj, "lblPnutVal", shippedLbsOfPnut);

      }
      else {
        if((shippedWtIncluding !== '') || (shippedWtIncluding !== 0)){
          if((shippedWtofVehichle !== '') || (shippedWtofVehichle !== 0)){
            shippedLbsOfPnut = (shippedWtIncluding - shippedWtofVehichle);
            setData(thisObj, "lblPnutVal", shippedLbsOfPnut);
            setValue(thisObj, "lblPnutVal", shippedLbsOfPnut);
          }
        }
        
        else {
          if ((shippedWtIncluding == '') || (shippedWtIncluding == 0)) {
            shippedLbsOfPnut = (0 - (shippedWtofVehichle));
            setData(thisObj, "lblPnutVal", shippedLbsOfPnut);
            setValue(thisObj, "lblPnutVal", shippedLbsOfPnut);
          }
          else {
            shippedLbsOfPnut = ((shippedWtIncluding) - 0)
            setData(thisObj, "lblPnutVal", shippedLbsOfPnut);
            setValue(thisObj, "lblPnutVal", shippedLbsOfPnut);
          }
        }
      }
    }
    else {
      shippedLbsOfPnut = "0";
    }
  }
  // End Method to calculate the shipped total and called on shipped WtInclucing Vehichle textbox change event

  // Start Method to calculate the shipped total and called on received WtInclucing Vehichle textbox change event
  function CalculateReceivedTotal() {
    let receivedWtIncluding = getValue(thisObj, "txtRecdWtIncVehicle");
    if(receivedWtIncluding == undefined){
      receivedWtIncluding = 0;
    }    
    let receivedWtofVehichle = getValue(thisObj, "txtRecdWtVehicle"); 
    if(receivedWtofVehichle == undefined){
      receivedWtofVehichle = 0;
    }  
    let receivedLbsOfPnut = "";
    if ((Number(receivedWtIncluding) || receivedWtIncluding == 0) && (Number(receivedWtofVehichle) || receivedWtofVehichle == 0)) {
      if (receivedWtIncluding == '' && receivedWtofVehichle == '') {
        receivedLbsOfPnut = '';
        setValue(thisObj, "lblPeanutVal", receivedLbsOfPnut);

      }
      else {
        if((receivedWtIncluding !== '') || (receivedWtIncluding !== 0)){
          if((receivedWtofVehichle !== '') || (receivedWtofVehichle !== 0)){
            receivedLbsOfPnut = (receivedWtIncluding - receivedWtofVehichle);
            setValue(thisObj, "lblPeanutVal", receivedLbsOfPnut);
          }
        }
        
        else {
          if ((receivedWtIncluding == '') || (receivedWtIncluding == 0)) {
            receivedLbsOfPnut = (0 - (receivedWtofVehichle));
            setValue(thisObj, "lblPeanutVal", receivedLbsOfPnut);
          }
          else {
            receivedLbsOfPnut = ((receivedWtIncluding) - 0)
            setValue(thisObj, "lblPeanutVal", receivedLbsOfPnut);
          }
        }
      }
    }
    else {
      receivedLbsOfPnut = "0";
    }
  }
  // End Method to calculate the shipped total and called on received WtInclucing Vehichle textbox change event

//Start Method for shipped LBSPeanut change event
  function bindvariance () {  
    let shippedLbsOfPnut = getValue(thisObj, "lblPnutVal");
    let receivedLbsOfPnut = getValue(thisObj, "lblPeanutVal");      
    if ((shippedLbsOfPnut > 0) && (receivedLbsOfPnut > 0)) {
      let variance = shippedLbsOfPnut - receivedLbsOfPnut;
      if(variance == 0){      
        setValue(thisObj,"txtWtVariance","0");
      }
      else{
        setValue(thisObj,"txtWtVariance",variance);
      }
    }    
  };
//End Method for shipped LBSPeanut change event


// Start Method for shipped Including vehicle textbox change event
  const ontxtIncludingVehicleChange = async () => {
    CalculateShippedTotal();
    bindvariance();
  };
  thisObj.ontxtIncludingVehicleChange = ontxtIncludingVehicleChange;
// End Method for shipped Including vehicle textbox change event

// Start Method for shipped weight of vehicle textbox change event
  const ontxtWtOfVehicleChange = () => {
    CalculateShippedTotal();
    bindvariance();
  };
  thisObj.ontxtWtOfVehicleChange = ontxtWtOfVehicleChange;
// End Method for shipped weight of vehicle textbox change event

// Start Method for received Including vehicle textbox change event
  const ontxtRecdWtIncVehicleChange = () => {
    CalculateReceivedTotal();
    bindvariance();
  };
  thisObj.ontxtRecdWtIncVehicleChange = ontxtRecdWtIncVehicleChange;
// End Method for received Including vehicle textbox change event

// Start Method for received weight of vehicle textbox change event
  const ontxtRecdWtVehicleChange = () => {
    CalculateReceivedTotal();
    bindvariance();
  };
  thisObj.ontxtRecdWtVehicleChange = ontxtRecdWtVehicleChange;
// End Method for shipped weight of vehicle textbox change event

// Start method for update Inventory radio button change event
const onradioWeightChange = () =>{
  EnableDisableControls();
}
thisObj.onradioWeightChange=onradioWeightChange
// End method for update Inventory radio button change event


//Start Method for form validation
function bFormValid() {
  let Load = getValue(thisObj, "txtLoad");
  let shippedDate = getValue(thisObj, "txtShippingDate");
  let shippedScaleTicket = getValue(thisObj, "txtScaleTicket");
  let shippedWtIncluding = getValue(thisObj, "txtIncludingVehicle");
  let shippedWtofVehichle = getValue(thisObj, "txtWtOfVehicle");
  let receiveDate = getValue(thisObj, "txtRecdDate");
  let receivedScaleticket = getValue(thisObj, "txtRecdScaleTicket");
  let receivedWtIncluding = getValue(thisObj, "txtRecdWtIncVehicle");
  let receivedWtofVehichle = getValue(thisObj, "txtRecdWtVehicle");
  shippedLbsOfPnut = getValue(thisObj, "lblPnutVal");
  receivedLbsOfPnut = getValue(thisObj, "lblPeanutVal");
  // PRB0054059 - Validate Load Number correctly
  if (!Load || isNaN(Load)) {
    Load = 1;
    setValue(thisObj, "txtLoad", Load);
    setData(thisObj, "txtLoad", Load);  // Ensure the state is updated
    //PRB0054195 - Duplicate Load Number Validation for scale tickets in settlement screen- user requested to remove the alert, as compared to the old system.
   // alert("Load number was not entered. Defaulting to 1.");
  }
  // console.log("Load value set to:", Load);
  // return isFormValid;
  if ((document.getElementById('radioWeight0').checked == false) && (document.getElementById('radioWeight1').checked == false) && (document.getElementById('radioWeight2').checked == false)) {
    alert("An Update Inventory with Weight must be selected.");
    isFormValid = false;
    return;
  }
  if (document.getElementById('radioWeight0').checked == true) {
    if ((shippedDate == "/ /   ") || (shippedDate == undefined) || (shippedDate == "")) {
      alert("Shipped Date is not a valid date value. Date '/ /  ' must be in the format of 'mm/dd/yyyy'");
      isFormValid = false;
      return;
    }
    else{
      isFormValid = true;
    }      
    if ((shippedScaleTicket == '') || (shippedScaleTicket == undefined)) {
      alert("Shipped Scale Ticket # is a required field when Update Inventory with Shipped Weight is selected.");
      isFormValid = false;
      return;
    }
    else{
      isFormValid = true;
    }
    if ((shippedWtIncluding == '') || (shippedWtIncluding == undefined)) {
      alert("Shipped Weight Including Vehicle is a required field when Update Inventory with Shipped Weight is selected.");
      isFormValid = false;
      return;
    }
    else{
      isFormValid = true;
    }
    if (shippedWtIncluding < 0 || shippedWtIncluding > 999999999) {
      alert("Shipped Weight Including Vehicle must be of format #########");
      isFormValid = false;
      return;
    }
    else{
      isFormValid = true;
    }

    if ((shippedWtofVehichle == '') || (shippedWtofVehichle == undefined)) {
      alert("Shipped Weight Of Vehicle is a required field when Update Inventory with Shipped Weight is selected.");
      isFormValid = false;
      return;
    }
    else{
      isFormValid = true;
    }

    if (shippedWtofVehichle < 0 || shippedWtofVehichle > 999999999) {
      alert("Shipped Weight Including Vehicle must be of format #########");
      isFormValid = false;
      return;
    }
    else{
      isFormValid = true;
    }
      if ((shippedLbsOfPnut !== '') || (shippedLbsOfPnut !== undefined)) {
      if (shippedLbsOfPnut <= 0 || shippedLbsOfPnut > 999999999) {
        alert("Shipped Weight Of Peanuts cannot be negative or zero");
        isFormValid = false;
        return;
      }
    }
      else{
        isFormValid = true;
      }
      if(receivedScaleticket !== '' && receivedScaleticket !== undefined && receivedScaleticket !== null){
        if ((receiveDate == "/ /   ") || (receiveDate == undefined) || (receiveDate == "")) {
          alert("Received Date is not a valid date value. Date '/ /  ' must be in the format of 'mm/dd/yyyy'");
          isFormValid = false;
           return;
        }
        else{
          isFormValid = true;
        }
      }
      else{
        isFormValid = true;
      }
    }
    else{
    isFormValid = true;
  }

  if (document.getElementById('radioWeight1').checked == true) {
    if ((receiveDate == "/ /   ") || (receiveDate == undefined) || (receiveDate == "")) {
      alert("Received Date is not a valid date value. Date '/ /  ' must be in the format of 'mm/dd/yyyy'");
      isFormValid = false;
      return;
    }
    else{
      isFormValid = true;
    }

    if ((receivedScaleticket == '') || (receivedScaleticket == undefined)) {
      alert("Received Scale Ticket # is a required field when Update Inventory with Received Weight is selected.");
      isFormValid = false;
      return;
    }
    else{
      isFormValid = true;
    }

    if ((receivedWtIncluding == '') || (receivedWtIncluding == undefined)) {
      alert("Received Weight Including Vehicle is a required field when Update Inventory with Received Weight is selected.");
      isFormValid = false;
      return;
    }
    else{
      isFormValid = true;
    }

    if (receivedWtIncluding < 0 || receivedWtIncluding > 999999999) {
      alert("Received Weight Including Vehicle must be of format #########");
      isFormValid = false;
      return;
    }
    else{
      isFormValid = true;
    }

    if ((receivedWtofVehichle == '') || (receivedWtofVehichle == undefined)) {
      alert("Received Weight Of Vehicle is a required field when Update Inventory with Received Weight is selected.");
      isFormValid = false;
      return;
    }
    else{
      isFormValid = true;
    }

    if (receivedWtofVehichle <= 0 || receivedWtofVehichle > 999999999) {
      alert("Received Weight Including Vehicle must be of format #########");
      isFormValid = false;
      return;
    }
    else{
      isFormValid = true;
    }

    if ((receivedLbsOfPnut !== '') || (receivedLbsOfPnut == undefined)) {
      if (receivedLbsOfPnut < 0 || receivedLbsOfPnut > 999999999) {
        alert("Received Weight Of Peanuts must be of format #########");
        isFormValid = false;
        return;
      }
      else{
        isFormValid = true;
      }
    }
    if(shippedScaleTicket !== '' && shippedScaleTicket !== undefined && shippedScaleTicket !== null){
      if ((shippedDate == "/ /   ") || (shippedDate == undefined) || (shippedDate == "")) {
        alert("Shipped Date is not a valid date value. Date '/ /  ' must be in the format of 'mm/dd/yyyy'");
        isFormValid = false;
        return;
      }
      else{
        isFormValid = true;
      }
    }
    else{
      isFormValid = true;
    }
  }
  else{
    isFormValid = true;
  }

  if (document.getElementById('radioWeight2').checked == true) {
    enable(thisObj, "txtOtherWt");
    if ((txtOtherWt == '') || (txtOtherWt == undefined)) {
        alert("Other Weight is required when Update Inventory with Other Weight is selected.!!!");
      isFormValid = false;
      return;
    }
    else{
      isFormValid = true;
    }

      if (txtOtherWt < 0 || txtOtherWt > 999999999) {
        alert("Other Weight must be of format #########");
        isFormValid = false;
         return;
  }
  else{
    isFormValid = true;
  }

      if (parseInt(txtOtherWt) !== receivedLbsOfPnut) {
        alert("Please change the Received Weights so that the Total Received Pounds of Peanuts equals the Other Weight.");
        isFormValid = false;
         return;
}
else{
  isFormValid = true;
}
}
else{
isFormValid = true;
} 

if(receiveDate !== undefined && receiveDate !== null && receiveDate !== "" && shippedDate !== undefined && shippedDate !== null && shippedDate !== ""){
if (ConvertToDate(receiveDate) < ConvertToDate(shippedDate)) {
  alert("The Received date cannot be less than the Shipped date");
  isFormValid = false;
  return;
}
else {
  isFormValid = true;
}
}
else{
isFormValid = true;
}   
  
}
//End Method for form validation

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_TradeSettleScaleTicket*/}

              {/* END_USER_CODE-USER_BEFORE_TradeSettleScaleTicket*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxLoadInfo*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxLoadInfo*/}

              <GroupBoxWidget conf={state.grpbxLoadInfo} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblLoadInfo*/}

                {/* END_USER_CODE-USER_BEFORE_lblLoadInfo*/}

                <LabelWidget
                  values={values}
                  conf={state.lblLoadInfo}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblLoadInfo*/}

                {/* END_USER_CODE-USER_AFTER_lblLoadInfo*/}
                {/* START_USER_CODE-USER_BEFORE_txtLoad*/}

                {/* END_USER_CODE-USER_BEFORE_txtLoad*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLoad}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLoad*/}

                {/* END_USER_CODE-USER_AFTER_txtLoad*/}
                {/* START_USER_CODE-USER_BEFORE_txtVehicle*/}

                {/* END_USER_CODE-USER_BEFORE_txtVehicle*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVehicle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVehicle*/}

                {/* END_USER_CODE-USER_AFTER_txtVehicle*/}
                {/* START_USER_CODE-USER_BEFORE_txtTruckLine*/}

                {/* END_USER_CODE-USER_BEFORE_txtTruckLine*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTruckLine}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTruckLine*/}

                {/* END_USER_CODE-USER_AFTER_txtTruckLine*/}
                {/* START_USER_CODE-USER_BEFORE_txtDriver*/}

                {/* END_USER_CODE-USER_BEFORE_txtDriver*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDriver}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDriver*/}

                {/* END_USER_CODE-USER_AFTER_txtDriver*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxLoadInfo*/}

              {/* END_USER_CODE-USER_AFTER_grpbxLoadInfo*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxShippedWeight*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxShippedWeight*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxShippedWeight}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblShippedWight*/}

                {/* END_USER_CODE-USER_BEFORE_lblShippedWight*/}

                <LabelWidget
                  values={values}
                  conf={state.lblShippedWight}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblShippedWight*/}

                {/* END_USER_CODE-USER_AFTER_lblShippedWight*/}
                {/* START_USER_CODE-USER_BEFORE_txtShippingDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtShippingDate*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtShippingDate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtShippingDate*/}

                {/* END_USER_CODE-USER_AFTER_txtShippingDate*/}
                {/* START_USER_CODE-USER_BEFORE_lblDateFormat*/}

                {/* END_USER_CODE-USER_BEFORE_lblDateFormat*/}

                <LabelWidget
                  values={values}
                  conf={state.lblDateFormat}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblDateFormat*/}

                {/* END_USER_CODE-USER_AFTER_lblDateFormat*/}
                {/* START_USER_CODE-USER_BEFORE_txtScaleTicket*/}

                {/* END_USER_CODE-USER_BEFORE_txtScaleTicket*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtScaleTicket}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtScaleTicket*/}

                {/* END_USER_CODE-USER_AFTER_txtScaleTicket*/}
                {/* START_USER_CODE-USER_BEFORE_lblSpltWght*/}

                {/* END_USER_CODE-USER_BEFORE_lblSpltWght*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSpltWght}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSpltWght*/}

                {/* END_USER_CODE-USER_AFTER_lblSpltWght*/}
                {/* START_USER_CODE-USER_BEFORE_chkBoxSplitWeight*/}

                {/* END_USER_CODE-USER_BEFORE_chkBoxSplitWeight*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkBoxSplitWeight}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkBoxSplitWeight*/}

                {/* END_USER_CODE-USER_AFTER_chkBoxSplitWeight*/}
                {/* START_USER_CODE-USER_BEFORE_txtIncludingVehicle*/}

                {/* END_USER_CODE-USER_BEFORE_txtIncludingVehicle*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtIncludingVehicle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtIncludingVehicle*/}

                {/* END_USER_CODE-USER_AFTER_txtIncludingVehicle*/}
                {/* START_USER_CODE-USER_BEFORE_txtWtOfVehicle*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtOfVehicle*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtOfVehicle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtOfVehicle*/}

                {/* END_USER_CODE-USER_AFTER_txtWtOfVehicle*/}
                {/* START_USER_CODE-USER_BEFORE_lblPnuts*/}

                {/* END_USER_CODE-USER_BEFORE_lblPnuts*/}

                <LabelWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  conf={state.lblPnuts}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPnuts*/}

                {/* END_USER_CODE-USER_AFTER_lblPnuts*/}
                {/* START_USER_CODE-USER_BEFORE_lblPnutVal*/}

                {/* END_USER_CODE-USER_BEFORE_lblPnutVal*/}

                <LabelWidget

                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lblPnutVal}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPnutVal*/}

                {/* END_USER_CODE-USER_AFTER_lblPnutVal*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxShippedWeight*/}

              {/* END_USER_CODE-USER_AFTER_grpbxShippedWeight*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxRecdWeight*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxRecdWeight*/}

              <GroupBoxWidget conf={state.grpbxRecdWeight} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblRecdWeight*/}

                {/* END_USER_CODE-USER_BEFORE_lblRecdWeight*/}

                <LabelWidget
                  values={values}
                  conf={state.lblRecdWeight}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblRecdWeight*/}

                {/* END_USER_CODE-USER_AFTER_lblRecdWeight*/}
                {/* START_USER_CODE-USER_BEFORE_txtRecdDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtRecdDate*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRecdDate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtRecdDate*/}

                {/* END_USER_CODE-USER_AFTER_txtRecdDate*/}
                {/* START_USER_CODE-USER_BEFORE_lblRecdDateFormat*/}

                {/* END_USER_CODE-USER_BEFORE_lblRecdDateFormat*/}

                <LabelWidget
                  values={values}
                  conf={state.lblRecdDateFormat}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblRecdDateFormat*/}

                {/* END_USER_CODE-USER_AFTER_lblRecdDateFormat*/}
                {/* START_USER_CODE-USER_BEFORE_txtRecdScaleTicket*/}

                {/* END_USER_CODE-USER_BEFORE_txtRecdScaleTicket*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRecdScaleTicket}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRecdScaleTicket*/}

                {/* END_USER_CODE-USER_AFTER_txtRecdScaleTicket*/}
                {/* START_USER_CODE-USER_BEFORE_lblRecdSplitWeight*/}

                {/* END_USER_CODE-USER_BEFORE_lblRecdSplitWeight*/}

                <LabelWidget
                  values={values}
                  conf={state.lblRecdSplitWeight}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblRecdSplitWeight*/}

                {/* END_USER_CODE-USER_AFTER_lblRecdSplitWeight*/}
                {/* START_USER_CODE-USER_BEFORE_chkBoxRecdSplit*/}

                {/* END_USER_CODE-USER_BEFORE_chkBoxRecdSplit*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkBoxRecdSplit}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkBoxRecdSplit*/}

                {/* END_USER_CODE-USER_AFTER_chkBoxRecdSplit*/}
                {/* START_USER_CODE-USER_BEFORE_txtRecdWtIncVehicle*/}

                {/* END_USER_CODE-USER_BEFORE_txtRecdWtIncVehicle*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRecdWtIncVehicle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRecdWtIncVehicle*/}

                {/* END_USER_CODE-USER_AFTER_txtRecdWtIncVehicle*/}
                {/* START_USER_CODE-USER_BEFORE_txtRecdWtVehicle*/}

                {/* END_USER_CODE-USER_BEFORE_txtRecdWtVehicle*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRecdWtVehicle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRecdWtVehicle*/}

                {/* END_USER_CODE-USER_AFTER_txtRecdWtVehicle*/}
                {/* START_USER_CODE-USER_BEFORE_lblPnut*/}

                {/* END_USER_CODE-USER_BEFORE_lblPnut*/}

                <LabelWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lblPnut}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPnut*/}

                {/* END_USER_CODE-USER_AFTER_lblPnut*/}
                {/* START_USER_CODE-USER_BEFORE_lblPeanutVal*/}

                {/* END_USER_CODE-USER_BEFORE_lblPeanutVal*/}

                <LabelWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lblPeanutVal}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPeanutVal*/}

                {/* END_USER_CODE-USER_AFTER_lblPeanutVal*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxRecdWeight*/}

              {/* END_USER_CODE-USER_AFTER_grpbxRecdWeight*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxUpdateInventory*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxUpdateInventory*/}

              <GroupBoxWidget
                conf={state.grpbxUpdateInventory}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblUpdateInventory*/}

                {/* END_USER_CODE-USER_BEFORE_lblUpdateInventory*/}

                <LabelWidget
                  values={values}
                  conf={state.lblUpdateInventory}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblUpdateInventory*/}

                {/* END_USER_CODE-USER_AFTER_lblUpdateInventory*/}
                {/* START_USER_CODE-USER_BEFORE_radioWeight*/}

                {/* END_USER_CODE-USER_BEFORE_radioWeight*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioWeight}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioWeight*/}

                {/* END_USER_CODE-USER_AFTER_radioWeight*/}
                {/* START_USER_CODE-USER_BEFORE_txtOtherWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtOtherWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOtherWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOtherWt*/}

                {/* END_USER_CODE-USER_AFTER_txtOtherWt*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxUpdateInventory*/}

              {/* END_USER_CODE-USER_AFTER_grpbxUpdateInventory*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxWeightVariance*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxWeightVariance*/}

              <GroupBoxWidget
                conf={state.grpbxWeightVariance}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtWtVariance*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtVariance*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtVariance}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtVariance*/}

                {/* END_USER_CODE-USER_AFTER_txtWtVariance*/}
                {/* START_USER_CODE-USER_BEFORE_lblLbs*/}

                {/* END_USER_CODE-USER_BEFORE_lblLbs*/}

                <LabelWidget
                  values={values}
                  conf={state.lblLbs}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblLbs*/}

                {/* END_USER_CODE-USER_AFTER_lblLbs*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxWeightVariance*/}

              {/* END_USER_CODE-USER_AFTER_grpbxWeightVariance*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxUpdate*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxUpdate*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxUpdate} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByVal*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByVal*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByVal}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByVal*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByVal*/}
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChngdBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChngdBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChngdBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChngdBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChngdBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChngdByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChngdByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChngdByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChngdByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChngdByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxUpdate*/}

              {/* END_USER_CODE-USER_AFTER_grpbxUpdate*/}

              {/* START_USER_CODE-USER_AFTER_TradeSettleScaleTicket*/}

              {/* END_USER_CODE-USER_AFTER_TradeSettleScaleTicket*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_TradeSettleScaleTicket);
