/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  getData,
  setData,
  getValue,
  setValue,
  disable,
  enable,
  hide,
  hideColumn,
  goTo,
} from "../../shared/WindowImports";

import "./DelvSettleApply.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import Loading from "../../../Loader/Loading";
import { preventInputBlur } from "react-bootstrap-typeahead/lib/utils";
import StripPic from "../../../../assets/img/PinStrip.png";
export const setGridValue = (thisObj, gridId, colName, rowNo, unformattedVal) => {
  if (typeof gridId === "undefined" || gridId === "") {
    throw new EvalError(`Incorrect Grid Id`);
  }

  if (typeof colName === "undefined" || colName === "") {
    throw new EvalError(`Incorrect Column Name`);
  }

  if (typeof rowNo === "undefined" || rowNo < 0) {
    throw new EvalError(`Incorrect Row Number`);
  }

  if (thisObj.values[gridId] && thisObj.values[gridId].length) {
    let rows = thisObj.values[gridId];
    let row = rows[rowNo];
    row = { ...row, [colName]: unformattedVal };
    rows[rowNo] = row;
    setValue(thisObj, gridId, [...rows]);
  }
  else {
    throw new EvalError(`No Rows in Grid ${gridId}`);
  }
};

// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_DelvSettleApply(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DelvSettleApply",
    windowName: "DelvSettleApply",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.DelvSettleApply",
    // START_USER_CODE-USER_DelvSettleApply_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Delivery Agreement Application",
      scrCode: "PN1180F",
    },
    // END_USER_CODE-USER_DelvSettleApply_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    col100PctDollarToApply: {
      name: "col100PctDollarToApply",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "100% $ To Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col100PctDollarToApply_PROPERTIES

      // END_USER_CODE-USER_col100PctDollarToApply_PROPERTIES
    },
    colAgreedPrice: {
      name: "colAgreedPrice",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Agreed Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreedPrice_PROPERTIES

      // END_USER_CODE-USER_colAgreedPrice_PROPERTIES
    },
    colAgreeDt: {
      name: "colAgreeDt",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Agree Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreeDt_PROPERTIES

      // END_USER_CODE-USER_colAgreeDt_PROPERTIES
    },
    colAgreeNum: {
      name: "colAgreeNum",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Agree #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreeNum_PROPERTIES

      // END_USER_CODE-USER_colAgreeNum_PROPERTIES
    },
    colAppliedDollar: {
      name: "colAppliedDollar",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Applied $",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAppliedDollar_PROPERTIES

      // END_USER_CODE-USER_colAppliedDollar_PROPERTIES
    },
    colApplyOrder: {
      name: "colApplyOrder",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Apply Order",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colApplyOrder_PROPERTIES

      // END_USER_CODE-USER_colApplyOrder_PROPERTIES
    },
    colFirmBasisOpt: {
      name: "colFirmBasisOpt",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Firm/Basis/Opt",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFirmBasisOpt_PROPERTIES

      // END_USER_CODE-USER_colFirmBasisOpt_PROPERTIES
    },
    colHidApply: {
      name: "colHidApply",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Hid Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHidApply_PROPERTIES

      // END_USER_CODE-USER_colHidApply_PROPERTIES
    },
    colMethod: {
      name: "colMethod",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Method",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMethod_PROPERTIES

      // END_USER_CODE-USER_colMethod_PROPERTIES
    },
    colOpenDollar: {
      name: "colOpenDollar",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Open $",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenDollar_PROPERTIES

      // END_USER_CODE-USER_colOpenDollar_PROPERTIES
    },
    colPaidDollar: {
      name: "colPaidDollar",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Paid $",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPaidDollar_PROPERTIES

      // END_USER_CODE-USER_colPaidDollar_PROPERTIES
    },
    colPeanutVariety: {
      name: "colPeanutVariety",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Pnut Var",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_colPeanutVariety_PROPERTIES
    },
    colReceiptValue: {
      name: "colReceiptValue",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Receipt Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReceiptValue_PROPERTIES

      // END_USER_CODE-USER_colReceiptValue_PROPERTIES
    },
    colSeedGen: {
      name: "colSeedGen",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Seed Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedGen_PROPERTIES

      // END_USER_CODE-USER_colSeedGen_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colSegRate: {
      name: "colSegRate",
      type: "GridColumnWidget",
      parent: "gridAgreementApplications",
      Label: "Seg Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSegRate_PROPERTIES

      // END_USER_CODE-USER_colSegRate_PROPERTIES
    },
    gridAgreementApplications: {
      name: "gridAgreementApplications",
      type: "GridWidget",
      parent: "grpbxDelvSettleApply",
      gridCellsOrder:
        "btnPopup,txtcolAgreeNum,txtcolAgreeDt,txtcolPeanutVariety,txtcolSeedGen,txtcolMethod,txtcolFirmBasisOpt,txtcolApplyOrder,txtcol100PctDollarToApply,txtcolReceiptValue,txtcolAgreedPrice,txtcolPaidDollar,txtcolAppliedDollar,txtcolOpenDollar,txtcolSeg,txtcolSegRate,txtcolHidApply",
      ColSpan: "8",
      Pagination: false,
      HasLabel: false,
      Cols: "8",
      Height: "",
      Width: "",
      isEditable: true,
      isCobolTable: true,
      // START_USER_CODE-USER_gridAgreementApplications_PROPERTIES

      // END_USER_CODE-USER_gridAgreementApplications_PROPERTIES
    },
    lblAgreementApplications: {
      name: "lblAgreementApplications",
      type: "LabelWidget",
      parent: "grpbxDelvSettleApply",
      Label: "Agreement Applications",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAgreementApplications_PROPERTIES

      // END_USER_CODE-USER_lblAgreementApplications_PROPERTIES
    },
    txt100PctAdjValue: {
      name: "txt100PctAdjValue",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApply",
      Label: "100% Adjustment Value",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt100PctAdjValue_PROPERTIES

      // END_USER_CODE-USER_txt100PctAdjValue_PROPERTIES
    },
    txtAgreementLocations: {
      name: "txtAgreementLocations",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApply",
      Label: "Agreement Location(s)",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAgreementLocations_PROPERTIES

      // END_USER_CODE-USER_txtAgreementLocations_PROPERTIES
    },
    txtcol100PctDollarToApply: {
      name: "txtcol100PctDollarToApply",
      type: "TextBoxWidget",
      colName: "col100PctDollarToApply",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol100PctDollarToApply_PROPERTIES

      // END_USER_CODE-USER_txtcol100PctDollarToApply_PROPERTIES
    },
    txtcolAgreedPrice: {
      name: "txtcolAgreedPrice",
      type: "TextBoxWidget",
      colName: "colAgreedPrice",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreedPrice_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolAgreedPrice_PROPERTIES
    },
    txtcolAgreeDt: {
      name: "txtcolAgreeDt",
      type: "TextBoxWidget",
      colName: "colAgreeDt",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreeDt_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolAgreeDt_PROPERTIES
    },
    txtcolAgreeNum: {
      name: "txtcolAgreeNum",
      type: "TextBoxWidget",
      colName: "colAgreeNum",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreeNum_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolAgreeNum_PROPERTIES
    },
    btnPopup: {
      name: "btnPopup",
      type: "ButtonWidget",
      colName: "btnColPopup",
      parent: "gridAgreementApplications",
      Label: " ",
      CharWidth: "9",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnPopup_PROPERTIES
      // END_USER_CODE-USER_btnPopup_PROPERTIES
    },
    btnColPopup: {
      name: "btnColPopup",
      type: "GridColumnWidget",
      parent: "gridAgreementApplication",
      Label: "...",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_btnColContrctPopup_PROPERTIES
      // END_USER_CODE-USER_btnColContrctPopup_PROPERTIES
    },
    txtcolAppliedDollar: {
      name: "txtcolAppliedDollar",
      type: "TextBoxWidget",
      colName: "colAppliedDollar",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAppliedDollar_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolAppliedDollar_PROPERTIES
    },
    txtcolApplyOrder: {
      name: "txtcolApplyOrder",
      type: "TextBoxWidget",
      colName: "colApplyOrder",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolApplyOrder_PROPERTIES

      // END_USER_CODE-USER_txtcolApplyOrder_PROPERTIES
    },
    txtcolFirmBasisOpt: {
      name: "txtcolFirmBasisOpt",
      type: "TextBoxWidget",
      colName: "colFirmBasisOpt",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFirmBasisOpt_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolFirmBasisOpt_PROPERTIES
    },
    txtcolHidApply: {
      name: "txtcolHidApply",
      type: "TextBoxWidget",
      colName: "colHidApply",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHidApply_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolHidApply_PROPERTIES
    },
    txtcolMethod: {
      name: "txtcolMethod",
      type: "TextBoxWidget",
      colName: "colMethod",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMethod_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      Visible: false
      // END_USER_CODE-USER_txtcolMethod_PROPERTIES
    },
    txtcolOpenDollar: {
      name: "txtcolOpenDollar",
      type: "TextBoxWidget",
      colName: "colOpenDollar",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpenDollar_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolOpenDollar_PROPERTIES
    },
    txtcolPaidDollar: {
      name: "txtcolPaidDollar",
      type: "TextBoxWidget",
      colName: "colPaidDollar",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPaidDollar_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolPaidDollar_PROPERTIES
    },
    txtcolPeanutVariety: {
      name: "txtcolPeanutVariety",
      type: "TextBoxWidget",
      colName: "colPeanutVariety",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES
    },
    txtcolReceiptValue: {
      name: "txtcolReceiptValue",
      type: "TextBoxWidget",
      colName: "colReceiptValue",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolReceiptValue_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolReceiptValue_PROPERTIES
    },
    txtcolSeedGen: {
      name: "txtcolSeedGen",
      type: "TextBoxWidget",
      colName: "colSeedGen",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeedGen_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolSeedGen_PROPERTIES
    },
    txtcolSeg: {
      name: "txtcolSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolSeg_PROPERTIES
    },
    txtcolSegRate: {
      name: "txtcolSegRate",
      type: "TextBoxWidget",
      colName: "colSegRate",
      parent: "gridAgreementApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSegRate_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtcolSegRate_PROPERTIES
    },
    txtDelvInspNum: {
      name: "txtDelvInspNum",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApply",
      Label: "Delivery Inspect #",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDelvInspNum_PROPERTIES

      // END_USER_CODE-USER_txtDelvInspNum_PROPERTIES
    },
    txtFirstVendorNumName: {
      name: "txtFirstVendorNumName",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApply",
      Label: "1st Vendor #/Name",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFirstVendorNumName_PROPERTIES

      // END_USER_CODE-USER_txtFirstVendorNumName_PROPERTIES
    },
    txtInOut: {
      name: "txtInOut",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApply",
      Label: "In/Out",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInOut_PROPERTIES

      // END_USER_CODE-USER_txtInOut_PROPERTIES
    },
    txtLoanRepaymentRate: {
      name: "txtLoanRepaymentRate",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApply",
      Label: "Loan Repayment Rate:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanRepaymentRate_PROPERTIES

      // END_USER_CODE-USER_txtLoanRepaymentRate_PROPERTIES
    },
    txtNetLbs: {
      name: "txtNetLbs",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApply",
      Label: "Net Lbs.",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetLbs_PROPERTIES

      // END_USER_CODE-USER_txtNetLbs_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApply",
      Label: "Peanut Type",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtSeedYN: {
      name: "txtSeedYN",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApply",
      Label: "Seed(Y/N)",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedYN_PROPERTIES

      // END_USER_CODE-USER_txtSeedYN_PROPERTIES
    },
    txtSeg: {
      name: "txtSeg",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApply",
      Label: "Seg",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg_PROPERTIES

      // END_USER_CODE-USER_txtSeg_PROPERTIES
    },
    txtValue: {
      name: "txtValue",
      type: "TextBoxWidget",
      parent: "grpbxDelvSettleApply",
      Label: "Value",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue_PROPERTIES

      // END_USER_CODE-USER_txtValue_PROPERTIES
    },
    grpbxDelvSettleApply: {
      name: "grpbxDelvSettleApply",
      type: "GroupBoxWidget",
      parent: "DelvSettleApply",
      Height: "",
      Width: "",
      ColsForMobile: "8",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxDelvSettleApply_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvSettleApply_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "DelvSettleApply",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };

  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};

  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    // let data = {
    //   "txtTradeInspNum"       : "B184759",
    //   "optTradeSale"          : "True",
    //   "cboVendor"             : "134969",
    //   "lblVendor"             : "Birdsong Peanuts Inc",
    //   "cboPeanutType"         : "Runner",
    //   "cboPeanutTypeId"       : "RU",
    //   "cboSeg"                : "1",
    //   "cboGeneration"         : "",
    //   "optTKCBasis"           : "False",
    //   "lblNetWeight"          : "43056",
    //   "txtTotalSMKRScreenPct" : "62.00",
    //   "lblLSK"                : "5070",
    //   "lblTotalValueOfLoad"   : "7053.98",
    //   "lblAgreeBP"            : "R29",
    //   "cboPeanutVariety"      : "RK",
    //   "optFullGrades"         : "True",
    //   "optTradeSale"          : "False",
    // }
    // setData(thisObj, "frmDelvSettleProfile", data);
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const FormLoad = async () => {
    try {
      setLoading(true);

      let ParentData = getData(thisObj, "frmDelvSettleProfile");
      if (ParentData != undefined && ParentData != "" && ParentData != null) {
        setValue(thisObj, "txtDelvInspNum", ParentData?.txtTradeInspNum)
        setValue(thisObj, "txtInOut", ParentData?.optTradeSale == "True" ? "Out" : "In")
        setValue(thisObj, "txtFirstVendorNumName", ParentData?.Vendordetails[0].split_vendor + " " + ConvertToPascalCase(ParentData?.Vendordetails[0].vendor_name));
        setValue(thisObj, "txtPeanutType", ParentData?.sHeader[0].pnut_type_name);
        setValue(thisObj, "txtSeg", ParentData?.cboSeg);
        setValue(thisObj, "txtSeedYN", ParentData?.sHeader[0].gen_produced_name);

        let nNetWt = 0;
        let nSmkPct = 0;
        let nLskWt = 0;
        if (ParentData?.optTKCBasis == "True") {
          if (isNumeric(ParentData?.lblNetWeight)) {
            nNetWt = ParentData.lblNetWeight;
          }
          if (isNumeric(ParentData?.txtTotalSMKRScreenPct)) {
            nNetWt = ParentData?.txtTotalSMKRScreenPct;
          }
          setValue(thisObj, "txtNetLbs", Math.round((nNetWt - nLskWt) * (nSmkPct / 100) + nLskWt).toFixed(0));
        }
        else {
          setValue(thisObj, "txtNetLbs", ParentData?.lblNetWeight);
        }

        setValue(thisObj, "txtValue", ParentData?.lblTotalValueOfLoad);
        setValue(thisObj, "txtAgreementLocations", ParentData?.Buying_point_id);

        if (ParentData?.optTKCBasis == "True") {
          disable(thisObj, "txtLoanRepaymentRate");
        }

        await bFillContractApplications();
      }
    }
    catch (err) {
      errorHandler(err, "Formload")
    }
    finally {
      setLoading(false);
    }
  }
//Binding the grid data
  const bFillContractApplications = async () => {
    try {
      let js = []
      let response = []
      let ParentData = getData(thisObj, "frmDelvSettleProfile");
      let buyingPt = "ALL"
      let inspNum = ParentData?.txtTradeInspNum
      let pnutTypeId = ParentData?.cboPeanutType
      let pnutVariety = ParentData?.ddPeanutVariety
      let pnutSeg = ParentData?.cboSeg
      let gradePricingMethod = ParentData?.optFullGrades == "True" ? "FULL" : "TKC"
      let purchaseSaleInd = ParentData?.optTradeSale == "True" ? "S" : "P"

      if (ParentData?.Buying_point_id.length == 3) {
        buyingPt = ParentData?.Buying_point_id;
      }
      let obj = {}
      setLoading(true);

      response = await SettlementService.RetrieveTradeSettlementAvailAgreementDetails(buyingPt, inspNum, pnutTypeId, pnutVariety, pnutSeg, gradePricingMethod, purchaseSaleInd, 'Y');
      disable(thisObj, "txtLoanRepaymentRate");
      document.getElementById('txtLoanRepaymentRate').style.backgroundColor = '00000F'


      if (response?.length > 0) {
        disable(thisObj, "txtLoanRepaymentRate");
        let data = response
        for (let i = 0; i < data.length; i++) {
          if (Number(response[i]?.delv_agree_dol) > 0) {
            if (response[i]?.firm_basis_opt?.toLocaleUpperCase() == "B") {
              enable(thisObj, 'txtLoanRepaymentRate')
              document.getElementById('txtLoanRepaymentRate').style.backgroundColor = 'FFFFFF'
            }

            let sSymbol = ''
            switch (data[i].symbol_ind) {
              case "TRADEMARK":
                sSymbol = String.fromCodePoint(0x02122)
                break;
              case "REGISTERED TRADEMARK":
                sSymbol = String.fromCodePoint(0x000AE)
                break;
              case "COPYRIGHT":
                sSymbol = String.fromCodePoint(0x00A9)
                break;
            }

            obj.txtcolAgreeNum = data[i]?.agreement_num;
            obj.txtcolAgreeDt = ConvertToDate(data[i]?.agree_date_time);
            obj.txtcolPeanutVariety = data[i]?.pnut_variety_name + " " + sSymbol
            obj.txtcolSeedGen = data[i]?.seed_gen
            obj.txtcolMethod = data[i]?.grade_pricing_method
            obj.txtcolReceiptValue = data[i]?.rcpt_value == "" ? 0 : Number(data[i]?.rcpt_value)?.toFixed(2)
            obj.txtcolAgreedPrice = data[i]?.price_per_ton == "" ? 0 : Number(data[i]?.price_per_ton)?.toFixed(2)
            obj.txtcolPaidDollar = data[i]?.delv_agree_dol?.trim() == "" ? "0.00" : parseFloat(data[i]?.delv_agree_dol)
            obj.txtcolAppliedDollar = data[i]?.delv_app_dol?.trim() == "" ? "0.00" : parseFloat(data[i]?.delv_app_dol)
            obj.txtcolOpenDollar = parseFloat(Number(data[i]?.rcpt_value == '' ? 0 : data[i]?.rcpt_value) - Number(data[i]?.delv_app_dol == '' ? 0 : data[i]?.delv_app_dol)).toFixed(2)
            obj.txtcolSeg = data[i]?.seg_type
            obj.txtcolSegRate = data[i]?.seg_rate
            obj.txtcolAgreeNum = data[i]?.agreement_num
            obj.txtcolAgreeNum = data[i]?.agreement_num
            obj.txtcolHidApply = ""
            obj.txtcol100PctDollarToApply = ""
            obj.txtcolFirmBasisOpt = data[i].firm_basis_opt
            js.push(obj)
            obj = {}
          }
        }
      }

      hideColumn(thisObj, "gridAgreementApplications", "txtcolMethod");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolSeg");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolSegRate");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolHidApply");

      setValue(thisObj, 'gridAgreementApplications', js)
      setData(thisObj, 'gridAgreementApplications_rowsData', js)
    }
    catch (err) {
      errorHandler(err, "bFillContractApplications")
    }
    finally {
      setLoading(false)
    }
  }
  //To close the popup
  const onbtnCancelClick = () => {
    try {
      setData(thisObj, 'frmDelvSettleProfile', "")
      setData(thisObj,"previousvalues","");
      document.getElementById("WarehouseReceipts_DelvSettleApplyPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnCancel click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;
  //Binding contract list
  const bFillContractList = async () => {
    try {
      let nContractCount = 0;
      let nMaxOrder = 0;
      let strLastAgree = "";
      let bFillContractListBool = false;
      var data = getValue(thisObj, "gridAgreementApplications");
      let js = [];

      setData(thisObj, "bFillContractList", "");

      if (data?.length != 0) {
        for (var i = 0; i < data?.length; i++) {
          if (isNumeric(data[i]?.txtcol100PctDollarToApply)) {
            if (nMaxOrder < (!isNumeric(data[i]?.txtcolApplyOrder) ? 0 : data[i]?.txtcolApplyOrder)) {
              nMaxOrder = !isNumeric(data[i]?.txtcolApplyOrder) ? 0 : data[i]?.txtcolApplyOrder;
              strLastAgree = data[i]?.txtcolAgreeNum;
            }

            js.push(data[i]?.txtcolAgreeNum + "|" + data[i]?.txtcolFirmBasisOpt + "|" +
                Number(getValue(thisObj, "txtNetLbs") * (data[i]?.txtcolHidApply / getValue(thisObj, "txtValue"))).toFixed(2) +
                "|A|" + data[i]?.txtcolAgreedPrice + "|C|" + data[i]?.txtcol100PctDollarToApply + "|");
          }
        }
        let obj = {
          lblUnappliedLbs: 0,
          lblUnappliedValue: 0,
          lblUnappliedAgree: 0
          //lstNewContractLine: js
        }
        if (isNumeric(getValue(thisObj, "txt100PctAdjValue"))) {
          if (getValue(thisObj, "txt100PctAdjValue") > 0) {
            obj.lblUnappliedLbs = Number(getValue(thisObj, "txtNetLbs") * (getValue(thisObj, "txt100PctAdjValue") / getValue(thisObj, "txtValue"))).toFixed(0);
            obj.lblUnappliedValue = getValue(thisObj, "txt100PctAdjValue")
            obj.lblUnappliedAgree = strLastAgree;
          }
        }
        
        setData(thisObj, "DelvSettleProfile_Unapplied_Data", obj);
        setData(thisObj, "DelvSettleProfile_lstNewContractLine_Data", js);
      }
      bFillContractListBool = true;
      return bFillContractListBool
    }
    catch (err) {
      errorHandler(err, "bFillContractList")
    }
    finally {
      setLoading(false);
    }
  }
//Checking the validation for txtcolAgreeNum in grid
  const Agree_Attach = async () => {
    try {
      let nAgreeCount = 0
      let Temp_str = ""
      let Agree_Attach = true
      var data = getValue(thisObj, "gridAgreementApplications");

      if (data?.length != 0) {
        for (var i = 0; i < data?.length; i++) {
          if (isNumeric(data[i]?.txtcolApplyOrder)) {
            Temp_str += (data[i]?.txtcolAgreeNum + ",|")
            nAgreeCount = nAgreeCount + 1
          }
        }
      }

      if (nAgreeCount < 1) {
        alert('An agreement must be selected! Please enter the order and amount to apply.')
        return false;
      }

      let response = await WarehouseReceiptService.RetrieveDeliveryAgreementAttachmentDetails(Temp_str);
      if (response.status == 500) {
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\nError: \n" + response?.message || '')
        return false;
      }

      return true
    }
    catch (err) {
      errorHandler(err, "Agree_Attach")
      return false;
    }
    finally {
      setLoading(false);
    }
  }
//validating the input values
  const bFormValid = async () => {
    try {
      let nApplValueToApply = 0
      let bFormValidBool = true
      let nRowIndex = 0
      var data = getValue(thisObj, "gridAgreementApplications");

      if (data?.length < 1) {
        alert("There is no agreement to complete the delivery settlement")
        return false;
      }
      if (!await Agree_Attach()) {
        return false;
      }

      while (nRowIndex <= data.length - 1) {
        if (data[nRowIndex].txtcolFirmBasisOpt == "B" && data[nRowIndex].txtcol100PctDollarToApply != "") {
          let txtLoanRepaymentRate = getValue(thisObj, "txtLoanRepaymentRate")
          if (txtLoanRepaymentRate == "" ||txtLoanRepaymentRate == null) {
            alert("Loan Repay Rate is required!")
            return false;
          }
        }

        if (data[nRowIndex].txtcolApplyOrder != "" && data[nRowIndex].txtcolApplyOrder != null) {
          if (!isNumeric(data[nRowIndex].txtcolApplyOrder)) {
            alert("Order must be numeric.")
            return false;
          }
        }

        if (data[nRowIndex].txtcol100PctDollarToApply != "" && data[nRowIndex].txtcol100PctDollarToApply != null) {
          if (isNumeric(data[nRowIndex].txtcol100PctDollarToApply)) {
            if (data[nRowIndex].txtcol100PctDollarToApply < 0 || data[nRowIndex].txtcol100PctDollarToApply > 999999999.99) {
              alert("Value to Apply must be of format #########.##.")
              return false;
            }
            nApplValueToApply = nApplValueToApply +parseFloat(data[nRowIndex].txtcolHidApply);
          }
          else {
            alert("Value to Apply must be of format #########.##.")
            return false;
          }
        }
        nRowIndex = nRowIndex + 1;
      }

      nApplValueToApply = parseFloat(nApplValueToApply).toFixed(2);
      setValue(thisObj, "txtValue", Number(getValue(thisObj, "txtValue")).toFixed(2));
let lblValue=getValue(thisObj, "txtValue")
let lbl100PctAdjValue=0;
if(!isNaN(getValue(thisObj, "txt100PctAdjValue")))
 lbl100PctAdjValue=getValue(thisObj, "txt100PctAdjValue");
      if (Number(nApplValueToApply) > Number(lblValue)) {
        alert("Too Much Value Applied.");
        return false;
      }

      if (Number(Number(nApplValueToApply) + Number(lbl100PctAdjValue)).toFixed(2) > Number(lblValue)) {
        alert("Too Much Value UnApplied.")
        return false;
      }

      if (Number(Number(nApplValueToApply) + Number(lbl100PctAdjValue)).toFixed(2) < Number(lblValue)) {
        let message = confirm("There is still value remaining. Do you want this value to go as unapplied?");
        if (message == true) {
          setValue(thisObj, "txt100PctAdjValue", Number(Number(lblValue) - Number(nApplValueToApply)).toFixed(2))
        }
        else {
          alert("Please apply remaining value to an agreement.");
        }
        return false;
      }

      return true
    }
    catch (err) {
      errorHandler(err, "bFormValid")
      return false;
    }
    finally {
      setLoading(false);
    }
  }
// checking validations and adding input values when clicking on Ok button 
  const onbtnOkClick = async () => {
    try {
      setLoading(true);
      if (!await bFillContractList()) {
        return false;
      }
      if (!await bFormValid()) {
        return false;
      }
      
      setData(thisObj, 'DelvSettleProfileData-LoanRepayRate', txtLoanRepaymentRate.value )
      if (getValue(thisObj, 'txtNetLbs') == (0 || '0')) {
        setData(thisObj, 'preDectScreenOPEN', false)
      } else {
        setData(thisObj, 'preDectScreenOPEN', true)
      }

      setData(thisObj, 'frmDelvSettleProfile', "")
      setData(thisObj,"previousvalues","");
      document.getElementById("WarehouseReceipts_DelvSettleApplyPopUp").childNodes[0].click();
    }
    catch (err) {
      errorHandler(err, "onbtnOkClick")
    }
    finally {
      setLoading(false);
    }
  }
  thisObj.onbtnOkClick = onbtnOkClick;
//calculating the values for the input textboxes in grid
  const ComputeOrderedValues2 = async (rowid,isproceed,type) => {
    try {
      if(isproceed==true)
      {
      let dblWorkApplAmt = 0
      let dblWorkAvailableAmt = 0
      let nRowIndex = 0
      let nRowIndex2 = 0
      let arrContracts = []
      let arrContractsobj = {};
      let arrContractemptysobj = {};
      let sContractNumTemp = ""
      let sOrderTemp = ""
      let sDollarsTemp = ""
      let sOpenTemp = ""
      let sRowTemp = ""
      let nAvailableDollars = 0
      let nContractCount = 0
      let nApplOrderCount = 0
      let nApplValueCount = 0
      nContractCount = 0
      let ParentData = getData(thisObj, "frmDelvSettleProfile");
      nAvailableDollars = getValue(thisObj, "txtValue");
      nRowIndex = 0
      let arremptyContracts=[];

      var data = getValue(thisObj, "gridAgreementApplications");
      while (nRowIndex <= data.length - 1) {
        if (data[nRowIndex].txtcolHidApply != null && data[nRowIndex].txtcolHidApply != "") {
          if (data[nRowIndex].txtcolApplyOrder != null && data[nRowIndex].txtcolApplyOrder != "") {
            arrContractsobj["txtcolAgreeNum"] = data[nRowIndex].txtcolAgreeNum;
            arrContractsobj["txtcolApplyOrder"] = data[nRowIndex].txtcolApplyOrder;
            if (isNumeric(data[nRowIndex].txtcolHidApply)) {
              arrContractsobj["txtcolHidApply"] = data[nRowIndex].txtcolHidApply;
            }
            else {
              if (nAvailableDollars < 0) {
                nAvailableDollars = 0
              }
              arrContractsobj["txtcolHidApply"] = nAvailableDollars;

            }
            arrContractsobj["txtcolOpenDollar"] = data[nRowIndex].txtcolOpenDollar;
            arrContractsobj["nRowIndex"] = nRowIndex;
            nContractCount = nContractCount + 1;

          }
          else {
            nAvailableDollars = Number(nAvailableDollars - data[nRowIndex].txtcolHidApply).toFixed(2);
            if (nAvailableDollars < 0)
              nAvailableDollars = 0
          }
        }
        else {
          if (data[nRowIndex].txtcolApplyOrder != null && data[nRowIndex].txtcolApplyOrder != "") {
            arrContractsobj["txtcolAgreeNum"] = data[nRowIndex].txtcolAgreeNum;
            arrContractsobj["txtcolApplyOrder"] = data[nRowIndex].txtcolApplyOrder;
            if (isNumeric(data[nRowIndex].txtcolHidApply)) {
              arrContractsobj["txtcolHidApply"] = data[nRowIndex].txtcolHidApply;
            }
            else {
              if (nAvailableDollars < 0)
                nAvailableDollars = 0
              arrContractsobj["txtcolHidApply"] = nAvailableDollars
            }
            arrContractsobj["txtcolOpenDollar"] = data[nRowIndex].txtcolOpenDollar;
            arrContractsobj["nRowIndex"] = nRowIndex;
            nContractCount = nContractCount + 1
          }
        }
        nRowIndex = nRowIndex + 1
        if(JSON.stringify(arrContractsobj) === '{}')
        {
          arrContractsobj["txtcolAgreeNum"]="";
          arrContractsobj["txtcolApplyOrder"]="";
          arrContractsobj["txtcolHidApply"]="";
          arrContractsobj["txtcolOpenDollar"]="";
          arrContractsobj["nRowIndex"]="";
          arremptyContracts.push(arrContractemptysobj);
        }
        else
        {
          arrContracts.push(arrContractsobj);
        }
        arrContractsobj = {};
        arrContractemptysobj={};
      }
      if(arremptyContracts.length>0)
      {
        for(let i=0;i<=arremptyContracts.length-1;i++)
        {
          arrContracts.push(arremptyContracts[i])
        }
      }
     if(rowid!=0)
     {
      for (let nRowIndexi = rowid; nRowIndexi >= 0; nRowIndexi--) {
        for (let nRowIndex2 = 0; nRowIndex2 <= nRowIndexi-1; nRowIndex2++) {
          if (arrContracts[nRowIndex2].txtcolApplyOrder > arrContracts[nRowIndex2 + 1].txtcolApplyOrder) {
            sContractNumTemp = arrContracts[nRowIndex2 + 1].txtcolAgreeNum;
            sOrderTemp = arrContracts[nRowIndex2 + 1].txtcolApplyOrder;
            sDollarsTemp = arrContracts[nRowIndex2 + 1].txtcolHidApply;
            sOpenTemp = arrContracts[nRowIndex2 + 1].txtcolOpenDollar;
            sRowTemp = arrContracts[nRowIndex2 + 1].nRowIndex;
            arrContracts[nRowIndex2 + 1].txtcolAgreeNum = arrContracts[nRowIndex2].txtcolAgreeNum
            arrContracts[nRowIndex2 + 1].txtcolApplyOrder = arrContracts[nRowIndex2].txtcolApplyOrder
            arrContracts[nRowIndex2 + 1].txtcolHidApply = arrContracts[nRowIndex2].txtcolHidApply
            arrContracts[nRowIndex2 + 1].txtcolOpenDollar = arrContracts[nRowIndex2].txtcolOpenDollar
            arrContracts[nRowIndex2 + 1].nRowIndex = arrContracts[nRowIndex2].nRowIndex
            arrContracts[nRowIndex2].txtcolAgreeNum = sContractNumTemp
            arrContracts[nRowIndex2].txtcolApplyOrder = sOrderTemp
            arrContracts[nRowIndex2].txtcolHidApply = sDollarsTemp
            arrContracts[nRowIndex2].txtcolOpenDollar = sOpenTemp
            arrContracts[nRowIndex2].nRowIndex = sRowTemp
          }
        }
      }
    }
      nRowIndex = 0;
      while (nRowIndex <= rowid) {

        let selvalue = data[arrContracts[nRowIndex].nRowIndex];

        if (arrContracts[nRowIndex].txtcolAgreeNum != null && arrContracts[nRowIndex].txtcolAgreeNum != "") {
          if (getValue(thisObj, "txtSeg") == "1" || getValue(thisObj, "txtSeg") == selvalue.txtcolSeg) {
            dblWorkApplAmt = arrContracts[nRowIndex].txtcolHidApply;
            dblWorkAvailableAmt = nAvailableDollars
          }
          else {
            dblWorkApplAmt = Number(((getValue(thisObj, "txtNetLbs") * selvalue.txtcolSegRate / 2000) * (arrContracts[nRowIndex].txtcolHidApply / getValue(thisObj, "txtValue")))).toFixed(2);
            dblWorkAvailableAmt = Number(((getValue(thisObj, "txtNetLbs") * selvalue.txtcolSegRate / 2000) * (nAvailableDollars / getValue(thisObj, "txtValue")))).toFixed(2)
          }
          let txtcolOpenDollar = arrContracts[nRowIndex].txtcolOpenDollar?.toString()?.replace(",", "");
          if ((Number(arrContracts[nRowIndex].txtcolHidApply) <= Number(txtcolOpenDollar)) || (dblWorkApplAmt != Number(arrContracts[nRowIndex].txtcolHidApply))) {
            if (parseFloat(dblWorkApplAmt) <=parseFloat(dblWorkAvailableAmt)) {
              if (selvalue.txtcolHidApply != arrContracts[nRowIndex].txtcolHidApply) {
                if (dblWorkApplAmt == 0) {
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcol100PctDollarToApply", arrContracts[nRowIndex].nRowIndex, '')
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcolHidApply", arrContracts[nRowIndex].nRowIndex, '')
                }
                else {
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcol100PctDollarToApply", arrContracts[nRowIndex].nRowIndex, dblWorkApplAmt)
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcolHidApply", arrContracts[nRowIndex].nRowIndex, arrContracts[nRowIndex].txtcolHidApply)
                }
              }
            }
            else {
              if (Number(selvalue.txtcolHidApply) != nAvailableDollars) {
                if (dblWorkAvailableAmt <= 0) {
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcol100PctDollarToApply", arrContracts[nRowIndex].nRowIndex, '')
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcolHidApply", arrContracts[nRowIndex].nRowIndex, '')
                }
                else {
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcol100PctDollarToApply", arrContracts[nRowIndex].nRowIndex, dblWorkAvailableAmt)
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcolHidApply", arrContracts[nRowIndex].nRowIndex, nAvailableDollars)
                }
              }
            }
          }
          else {
            if (arrContracts[nRowIndex].txtcolOpenDollar <= nAvailableDollars) {
              if (selvalue.txtcolHidApply != arrContracts[nRowIndex].txtcolOpenDollar) {
                if (arrContracts[nRowIndex].txtcolOpenDollar == 0) {
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcol100PctDollarToApply", arrContracts[nRowIndex].nRowIndex, '')
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcolHidApply", arrContracts[nRowIndex].nRowIndex, '')
                }
                else {
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcol100PctDollarToApply", arrContracts[nRowIndex].nRowIndex, arrContracts[nRowIndex].txtcolOpenDollar)
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcolHidApply", arrContracts[nRowIndex].nRowIndex, arrContracts[nRowIndex].txtcolOpenDollar)
                }
              }
            }
            else {
              if (selvalue.txtcolHidApply != nAvailableDollars) {
                if (nAvailableDollars <= 0) {
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcol100PctDollarToApply", arrContracts[nRowIndex].nRowIndex, '')
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcolHidApply", arrContracts[nRowIndex].nRowIndex, '')
                }
                else {
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcol100PctDollarToApply", arrContracts[nRowIndex].nRowIndex, nAvailableDollars)
                  setGridValue(thisObj, 'gridAgreementApplications', "txtcolHidApply", arrContracts[nRowIndex].nRowIndex, nAvailableDollars)
                }
              }
            }
          }

          data = getValue(thisObj, "gridAgreementApplications");
          selvalue = data[arrContracts[nRowIndex].nRowIndex];;
          if (selvalue.txtcolHidApply == "" || selvalue.txtcolHidApply == null) {

            setGridValue(thisObj, 'gridAgreementApplications', "txtcolHidApply", arrContracts[nRowIndex].nRowIndex, '0')
            if (nAvailableDollars == 0) {
              alert("Agreement has been fully applied. You will be over applying the agreement");
              return false;
            }
          }
          thisObj.setState({
            ...thisObj.state,
            gridAgreementApplications: {
              ...state.gridAgreementApplications,
              toggleRender: !state.gridAgreementApplications.toggleRender,
            }

          })
          hideColumn(thisObj, "gridAgreementApplications", "txtcolMethod");
          hideColumn(thisObj, "gridAgreementApplications", "txtcolSeg");
          hideColumn(thisObj, "gridAgreementApplications", "txtcolSegRate");
          hideColumn(thisObj, "gridAgreementApplications", "txtcolHidApply");
          try {
            nAvailableDollars = Number(nAvailableDollars - selvalue.txtcolHidApply).toFixed(2)
            if (nAvailableDollars < 0)
              nAvailableDollars = 0;
          }
          catch (err) {
            if (err instanceof EvalError) {
              alert("Agreement has been fully applied. You will be over applying the agreement")
              return false;
            } else {
              showMessage(
                thisObj,
                "Error occurred during nAvailableDollars variable"
              );
            }
          }

          hideColumn(thisObj, "gridAgreementApplications", "txtcolMethod");
          hideColumn(thisObj, "gridAgreementApplications", "txtcolSeg");
          hideColumn(thisObj, "gridAgreementApplications", "txtcolSegRate");
          hideColumn(thisObj, "gridAgreementApplications", "txtcolHidApply");


        }
        nRowIndex++;
      }
    }
    else
    {
      switch (type) {
        case "txtcolApplyOrder":
          alert("Value in the Apply Order is invalid!  Enter 1 to the 1st agreements to be applied to then 2 to the 2nd agreements to be applied and so on")
          return;
          break;
          case "txtcol100PctDollarToApply":
            alert("You must first specify the order.")
            return;
            break;
      }
      
    }
    }
    catch (err) {
      errorHandler(err, "ComputeOrderedValues2")
      return false;
    }
    finally {
    }
  }
//this is for opening popup from grid
  const onbtnPopupClick = async (event) => {
    try {
      setLoading(true);

      let rowSelected = event?.target?.parentElement.parentElement.parentElement
      let rowID = rowSelected?.id?.substring(4, 5);
      var data = getData(thisObj, "gridAgreementApplications_rowsData");

      let obj = {
        mode: "Update",
        AgreementNum: data[rowID]?.txtcolAgreeNum
      }

      setData(thisObj, "dataForDelvAgreeProfile", obj)
      goTo(thisObj, "WarehouseReceipts#DelvAgreeProfile#DEFAULT#true#Click");
    }
    catch (err) {
      errorHandler(err, "onbtnPopupClick")
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnPopupClick = onbtnPopupClick;
//onblur event for txtcolApplyOrder from grid
  const ontxtcolApplyOrderBlur = async (event) => {
    let rowSelected = event?.target?.parentElement.parentElement.parentElement
      let rowID = rowSelected?.id?.substring(4, 5);
    try {
      setLoading(true);

      let nRowIndex = 0;
      
      var data = getValue(thisObj, "gridAgreementApplications");
      var previousdata = getData(thisObj, "previousvalues");
      if((previousdata!=null && previousdata!="" && previousdata!=undefined) )
      {
        if(previousdata[rowID].txtcolApplyOrder==data[rowID]?.txtcolApplyOrder)
        return;
      }
      if (!isNumeric(event.target.value)) {
        setGridValue(thisObj, 'gridAgreementApplications', "txtcolApplyOrder", rowID, '')
        setGridValue(thisObj, 'gridAgreementApplications', "txtcol100PctDollarToApply", rowID, '')
        setGridValue(thisObj, 'gridAgreementApplications', "txtcolHidApply", rowID, '')
      }
      else {
        if (data[rowID]?.txtcolApplyOrder > data?.length) {
          await ComputeOrderedValues2(rowID,false,"txtcolApplyOrder");
          return ;
        }
        else {
          nRowIndex = 0;

          while (nRowIndex <= data?.length - 1) {
            if (rowID != nRowIndex) {
              if (data[rowID]?.txtcolApplyOrder == data[nRowIndex]?.txtcolApplyOrder) {
              await ComputeOrderedValues2(rowID,false,"txtcolApplyOrder");
                return ;
              }
            }
            nRowIndex = nRowIndex + 1;
          }

          nRowIndex = 0
          setGridValue(thisObj, 'gridAgreementApplications', "txtcolApplyOrder", rowID, event?.target?.value)
        }
      }
      thisObj.setState({
        ...thisObj.state,
        gridAgreementApplications: {
          ...state.gridAgreementApplications,
          toggleRender: !state.gridAgreementApplications.toggleRender,
        }
      })

      hideColumn(thisObj, "gridAgreementApplications", "txtcolMethod");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolSeg");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolSegRate");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolHidApply");
      
        await ComputeOrderedValues2(rowID,true,"");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolMethod");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolSeg");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolSegRate");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolHidApply");
      
      
    }
    catch (err) {
      errorHandler(err, "ontxtcolApplyOrderBlur")
      return false;
    }
    finally {
      
      setData(thisObj,"previousvalues",getValue(thisObj, "gridAgreementApplications"));
      setLoading(false);
    }
  }
  thisObj.ontxtcolApplyOrderBlur = ontxtcolApplyOrderBlur;
//onblur event for txtcol100PctDollarToApply from grid
  const ontxtcol100PctDollarToApplyBlur = async (event) => {
    let rowSelected = event?.target?.parentElement.parentElement.parentElement
      let rowID = rowSelected?.id?.substring(4, 5);
    try {
      setLoading(true);
      
      var data = getValue(thisObj, "gridAgreementApplications");
      var previousdata = getData(thisObj, "previousvalues");
      if((previousdata!=null && previousdata!="" && previousdata!=undefined) )
      {
        if(previousdata[rowID].txtcol100PctDollarToApply==data[rowID]?.txtcol100PctDollarToApply && previousdata[rowID].txtcolApplyOrder==data[rowID]?.txtcolApplyOrder)
        return;
      }
      if (!isNumeric(event?.target?.value)) {
        setGridValue(thisObj, 'gridAgreementApplications', "txtcol100PctDollarToApply", rowID, '')
      }
      else {
        if (!isNumeric(data[rowID]?.txtcolApplyOrder)) {
          setGridValue(thisObj, 'gridAgreementApplications', "txtcol100PctDollarToApply", rowID, '')
          thisObj.setState({
            ...thisObj.state,
            gridAgreementApplications: {
              ...state.gridAgreementApplications,
              toggleRender: !state.gridAgreementApplications.toggleRender,
            }
          })
    
          hideColumn(thisObj, "gridAgreementApplications", "txtcolMethod");
          hideColumn(thisObj, "gridAgreementApplications", "txtcolSeg");
          hideColumn(thisObj, "gridAgreementApplications", "txtcolSegRate");
          hideColumn(thisObj, "gridAgreementApplications", "txtcolHidApply");
         await ComputeOrderedValues2(rowID,false,"txtcol100PctDollarToApply");
         hideColumn(thisObj, "gridAgreementApplications", "txtcolMethod");
          hideColumn(thisObj, "gridAgreementApplications", "txtcolSeg");
          hideColumn(thisObj, "gridAgreementApplications", "txtcolSegRate");
          hideColumn(thisObj, "gridAgreementApplications", "txtcolHidApply");
          return;
        }
      }
      thisObj.setState({
        ...thisObj.state,
        gridAgreementApplications: {
          ...state.gridAgreementApplications,
          toggleRender: !state.gridAgreementApplications.toggleRender,
        }
      })

      hideColumn(thisObj, "gridAgreementApplications", "txtcolMethod");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolSeg");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolSegRate");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolHidApply");

      await ComputeOrderedValues2(rowID,true,"");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolMethod");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolSeg");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolSegRate");
      hideColumn(thisObj, "gridAgreementApplications", "txtcolHidApply");
      
      
    }
    catch (err) {
      errorHandler(err, "ontxtcol100PctDollarToApplyBlur")
    }
    finally {
      
      setData(thisObj,"previousvalues",getValue(thisObj, "gridAgreementApplications"));
      setLoading(false);
    }
  }
  thisObj.ontxtcol100PctDollarToApplyBlur = ontxtcol100PctDollarToApplyBlur;
//Formating datetime text
  function ConvertToDate(dateTime) {
    try {
      if (dateTime !== undefined && dateTime !== '') {
        let date = new Date(dateTime),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
      } else {
        return '';
      }
    }
    catch (err) {
      errorHandler(err, "ConvertToDate")
    }
  }
//Checking whether the given value is numeric or not
  function isNumeric(num) {
    try {
      return !isNaN(parseFloat(num)) && isFinite(num);
    }
    catch (err) {
      errorHandler(err, "isNumeric")
    }
  }

  function errorHandler(err, action = "some control") {
    showMessage(thisObj,
      err instanceof EvalError
        ? err.message
        : "Something went wrong for " + action + ". Please try again later.")
  }
  function ConvertToPascalCase(word){
    return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DelvSettleApply*/}

              {/* END_USER_CODE-USER_BEFORE_DelvSettleApply*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDelvSettleApply*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDelvSettleApply*/}

              <GroupBoxWidget
                conf={state.grpbxDelvSettleApply}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtDelvInspNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtDelvInspNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDelvInspNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDelvInspNum*/}

                {/* END_USER_CODE-USER_AFTER_txtDelvInspNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtInOut*/}

                {/* END_USER_CODE-USER_BEFORE_txtInOut*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInOut}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtInOut*/}

                {/* END_USER_CODE-USER_AFTER_txtInOut*/}
                {/* START_USER_CODE-USER_BEFORE_txtFirstVendorNumName*/}

                {/* END_USER_CODE-USER_BEFORE_txtFirstVendorNumName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFirstVendorNumName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFirstVendorNumName*/}

                {/* END_USER_CODE-USER_AFTER_txtFirstVendorNumName*/}
                {/* START_USER_CODE-USER_BEFORE_txtPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_txtPeanutType*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutType}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_txtPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeg*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeg*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeg*/}

                {/* END_USER_CODE-USER_AFTER_txtSeg*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeedYN*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeedYN*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeedYN}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeedYN*/}

                {/* END_USER_CODE-USER_AFTER_txtSeedYN*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtNetLbs*/}
                {/* START_USER_CODE-USER_BEFORE_txtValue*/}

                {/* END_USER_CODE-USER_BEFORE_txtValue*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValue}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtValue*/}

                {/* END_USER_CODE-USER_AFTER_txtValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblAgreementApplications*/}

                {/* END_USER_CODE-USER_BEFORE_lblAgreementApplications*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAgreementApplications}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAgreementApplications*/}

                {/* END_USER_CODE-USER_AFTER_lblAgreementApplications*/}
                {/* START_USER_CODE-USER_BEFORE_txtAgreementLocations*/}

                {/* END_USER_CODE-USER_BEFORE_txtAgreementLocations*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAgreementLocations}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAgreementLocations*/}

                {/* END_USER_CODE-USER_AFTER_txtAgreementLocations*/}
                {/* START_USER_CODE-USER_BEFORE_gridAgreementApplications*/}

                {/* END_USER_CODE-USER_BEFORE_gridAgreementApplications*/}

                <GridWidget
                  conf={state.gridAgreementApplications}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridAgreementApplications}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                  key={state.gridAgreementApplications.toggleRender}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridAgreementApplications*/}

                {/* END_USER_CODE-USER_AFTER_gridAgreementApplications*/}
                {/* START_USER_CODE-USER_BEFORE_txtLoanRepaymentRate*/}

                {/* END_USER_CODE-USER_BEFORE_txtLoanRepaymentRate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLoanRepaymentRate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLoanRepaymentRate*/}

                {/* END_USER_CODE-USER_AFTER_txtLoanRepaymentRate*/}
                {/* START_USER_CODE-USER_BEFORE_txt100PctAdjValue*/}

                {/* END_USER_CODE-USER_BEFORE_txt100PctAdjValue*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt100PctAdjValue}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt100PctAdjValue*/}

                {/* END_USER_CODE-USER_AFTER_txt100PctAdjValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDelvSettleApply*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDelvSettleApply*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_DelvSettleApply*/}

              {/* END_USER_CODE-USER_AFTER_DelvSettleApply*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_DelvSettleApply);
