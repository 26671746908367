/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  setValue,
  getValue,
  getData,
  setData,
  enable,
  disable
} from "../../shared/WindowImports";

import "./LienHolderProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_LienHolderProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "LienHolderProfile",
    windowName: "LienHolderProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.LienHolderProfile",
    // START_USER_CODE-USER_LienHolderProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Lienholder Profile",
      scrCode: "PN0350C",
    },
    // END_USER_CODE-USER_LienHolderProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxLienHolderDetails",
      Label: "Remit To:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitTo_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxLienHolderDetails",
      Label: "State:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES

      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    txtAddedBy: {
      name: "txtAddedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddedBy_PROPERTIES

      // END_USER_CODE-USER_txtAddedBy_PROPERTIES
    },
    txtAddress: {
      name: "txtAddress",
      type: "TextBoxWidget",
      parent: "grpbxLienHolderDetails",
      Label: "Address:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 50 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddress_PROPERTIES

      // END_USER_CODE-USER_txtAddress_PROPERTIES
    },
    txtChangedBy: {
      name: "txtChangedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangedBy_PROPERTIES

      // END_USER_CODE-USER_txtChangedBy_PROPERTIES
    },
    txtCity: {
      name: "txtCity",
      type: "TextBoxWidget",
      parent: "grpbxLienHolderDetails",
      Label: "City:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCity_PROPERTIES

      // END_USER_CODE-USER_txtCity_PROPERTIES
    },
    txtLienOrder: {
      name: "txtLienOrder",
      type: "TextBoxWidget",
      parent: "grpbxLienHolderDetails",
      Label: "Lien Order:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLienOrder_PROPERTIES

      // END_USER_CODE-USER_txtLienOrder_PROPERTIES
    },
    txtName: {
      name: "txtName",
      type: "TextBoxWidget",
      parent: "grpbxLienHolderDetails",
      Label: "Name:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtName_PROPERTIES

      // END_USER_CODE-USER_txtName_PROPERTIES
    },
    txtNatureOfLien: {
      name: "txtNatureOfLien",
      type: "TextBoxWidget",
      parent: "grpbxLienHolderDetails",
      Label: "Nature of Lien:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNatureOfLien_PROPERTIES

      // END_USER_CODE-USER_txtNatureOfLien_PROPERTIES
    },
    txtRemitTo: {
      name: "txtRemitTo",
      type: "TextBoxWidget",
      parent: "grpbxLienHolderDetails",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo_PROPERTIES
    },
    txtStateID1: {
      name: "txtStateID1",
      type: "TextBoxWidget",
      parent: "grpbxLienHolderDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStateID1_PROPERTIES

      // END_USER_CODE-USER_txtStateID1_PROPERTIES
    },
    txtStateID: {
      name: "txtStateID",
      type: "TextBoxWidget",
      parent: "grpbxLienHolderDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStateID_PROPERTIES

      // END_USER_CODE-USER_txtStateID_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxLienHolderDetails",
      Label: "Vendor:",
      ColSpan: "3",
      ReadOnly: true,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtZip: {
      name: "txtZip",
      type: "TextBoxWidget",
      parent: "grpbxLienHolderDetails",
      Label: "Zip:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtZip_PROPERTIES

      // END_USER_CODE-USER_txtZip_PROPERTIES
    },
    grpbxLienHolderDetails: {
      name: "grpbxLienHolderDetails",
      type: "GroupBoxWidget",
      parent: "LienHolderProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLienHolderDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxLienHolderDetails_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "LienHolderProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  // Implement functionality for Form Load
  let FormLoad = async () => {
    try{
      let parentdata = getData(thisObj, 'LienHolderProfileData')
      setData(thisObj, 'mbSuccessfulLoad', true)
      if (parentdata.cmdOk_Caption == 'ADD') {
        document.getElementById("btnOk").innerText = "Add";
        setValue(thisObj, "txtVendor", parentdata.VendorNumber + "-" + parentdata.VendorName);
        if (!await FillStateList()) {
          setData(thisObj, 'mbSuccessfulLoad', false)
        }
        if (!await FillRemitTodd()) {
          setData(thisObj, 'mbSuccessfulLoad', false)
        }
      }
      if (parentdata.cmdOk_Caption == 'UPDATE') {
        document.getElementById("btnOk").innerText = "Update";
        let State = parentdata.cboState
        let remitto = parentdata.cboRemitTo
        let AddedBy = parentdata.lblAddedBy.trim() + " " + moment(parentdata.lblAddedByDate).format("MM/DD/YYYY");
        let ChangedBy = parentdata.lblChangedBy.trim() + " " + moment(parentdata.lblChangedByDate).format("MM/DD/YYYY");
        await FillStateList(State)
        await FillRemitTodd(remitto)
        setValue(thisObj, "txtVendor", parentdata.lblVendor + "-" + parentdata.lblVendorName);
        setValue(thisObj, 'txtLienOrder', parentdata.txtLine)
        disable(thisObj, 'txtLienOrder')
        setValue(thisObj, 'txtName', parentdata.txtName)
        setValue(thisObj, 'txtAddress', parentdata.txtAddress)
        setValue(thisObj, 'txtCity', parentdata.txtCity)
        setValue(thisObj, 'txtZip', parentdata.txtZip)
        setValue(thisObj, 'txtNatureOfLien', parentdata.txtNature)
        setValue(thisObj, 'txtAddedBy', AddedBy)
        setValue(thisObj, 'txtChangedBy', ChangedBy)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
    }    
  }
  // Inmplement functionality for 
  const bFormValid = () => {
    try{
      let formValid = true;
      let txtLienOrder = getValue(thisObj, 'txtLienOrder');
      if (txtLienOrder == '' || txtLienOrder == undefined || txtLienOrder == null) {
        showMessage(thisObj, "Lien is a required field")
        formValid = false;
        return formValid;
      }
  
      let txtName = getValue(thisObj, 'txtName');
      if (txtName == '' || txtName == undefined || txtName == null) {
        showMessage(thisObj, "Name is a required field")
        formValid = false;
        return formValid;
      }
      return formValid;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
    }      
  }
  //Method to bind State dropdown
  const FillStateList = async (State) => {
    let dataObj = getData(thisObj, 'LienHolderProfileData')
    try {
      setLoading(true)
      let response = await SystemMaintenanceApplicationSupportService.RetrieveStateControlDetails(null);
      let data = response;
      let js = [];
      if (data !== null && data !== undefined && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let obj = {
            key: data[i].state_abbr.toString(),
            description: data[i].state_name.toString(),
            stateId: data[i].state_id,
            index: i
          }
          js.push(obj)
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddState: {
            ...state["ddState"],
            valueList: js,
          }
        }
      })
      if (dataObj.cmdOk_Caption == "UPDATE") {
        let stateIndex = 0;
        stateIndex = js.find(elem => elem.key == State).index;
        setValue(thisObj, "ddState", js.at(stateIndex).key);
        setLoading(false)
      }
      else {
        setValue(thisObj, 'ddState', js.at(0).key);
        setLoading(false)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
        setLoading(false)
      }
    }
  }
  // Method for bind Remitto dropdown
  const FillRemitTodd = async (remitto) => {
    let js = [];
    let Vendornum = "";
    let dataObj = getData(thisObj, 'LienHolderProfileData');
    if (dataObj.cmdOk_Caption == "ADD") {
      Vendornum = dataObj.VendorNumber
    } else {
      Vendornum = dataObj.lblVendor
    }
    try {
      setLoading(true)
      ContractManagementService.RetieveRemitDetails(Vendornum).then(response => {
        let data = response
        if (data != null && data.length > 0 && data != undefined) {
          for (let i = 0; i < data.length; i++) {
            let obj = {
              key: data[i].remittoid.toString(),
              description: data[i].remittoid.toString() + '-' + data[i].name.toString().trim() + '-' + data[i].city.toString().trim() + ',' + data[i].state.toString().trim()
            }
            js.push(obj)
          }
          thisObj.setState(current => {
            return {
              ...current,
              ddRemitTo: {
                ...state["ddRemitTo"],
                valueList: js
              }
            }
          })
          if (dataObj.cmdOk_Caption == "UPDATE") {
            let indexVal = js.find(elem => elem.key == remitto)            
            setValue(thisObj, "ddRemitTo", indexVal.key);
            setLoading(false)
          }
          else {
            setValue(thisObj, 'ddRemitTo', js.at(0).key);
            setLoading(false)
          }
        }
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
        setLoading(false)
      }
    }
  }
  // Below code is to Add and update button implemention    
  const onbtnOkClick = async () => {
    try {
      if (bFormValid() == true) {
        let Parentdata = getData(thisObj, 'LienHolderProfileData');
        let cmdOk_Caption = Parentdata.cmdOk_Caption;
        let txtLienOrder = getValue(thisObj, 'txtLienOrder')
        let txtName = getValue(thisObj, 'txtName')
        let txtVendor = getValue(thisObj, 'txtVendor')        
        let txtAddress = getValue(thisObj, 'txtAddress')
        let txtCity = getValue(thisObj, 'txtCity')
        let ddState = getValue(thisObj, 'ddState')
        let txtZip = getValue(thisObj, 'txtZip')
        let txtNatureOfLien = getValue(thisObj, 'txtNatureOfLien')
        let ddRemitTo = getValue(thisObj, 'ddRemitTo')
        let buy_pt_id = null;
        if(txtVendor == "" || txtVendor  == undefined || txtVendor == null){
          txtVendor ="";
        }
        if(txtAddress == "" || txtAddress  == undefined || txtAddress == null){
          txtAddress ="";
        }
        if(txtCity == "" || txtCity  == undefined || txtCity == null){
          txtCity ="";
        }
        if(ddState == "" || ddState  == undefined || ddState == null){
          ddState ="";
        }
        if(txtZip  == undefined || txtZip == null){
          txtZip ="";
        }
        if(txtNatureOfLien == "" || txtNatureOfLien  == undefined || txtNatureOfLien == null){
          txtNatureOfLien = "";
        }
        if(ddRemitTo == "" || ddRemitTo  == undefined || ddRemitTo == null){
          ddRemitTo ="";
        }
        let dataObj = {
          "vendor": txtVendor.split("-")[0],
          "lienholder_line": txtLienOrder,
          "lienholder_name": txtName,
          "lienholder_address": txtAddress,
          "lienholder_city": txtCity,
          "lienholder_state": ddState,
          "lienholder_zip": txtZip,
          "nature_of_lien": txtNatureOfLien,
          "vend_remit": ddRemitTo
        };
        if (cmdOk_Caption == 'ADD') {
          setLoading(true)
          let response = await SystemMaintenanceApplicationSupportService.CreateLienHolderControl(null, dataObj);
          if (![200].includes(response.status)) {
            showMessage(thisObj, 'An error occured while creating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.' + response.message)
            setLoading(false)
          }
          else {
            if (response.status == 200) {
              setData(thisObj, 'txtarLienHolder', true);
              document.getElementById("SystemMaintenanceApplicationSupport_LienHolderProfilePopUp").childNodes[0].click();
              setLoading(false)
            }
            else {
              showMessage(thisObj, response.message);
              setLoading(false)
            }
          }
          setLoading(false)
        }
        else if (cmdOk_Caption == 'UPDATE') {
          setLoading(true)
          let response = await SystemMaintenanceApplicationSupportService.UpdateLienHolderControl(buy_pt_id, dataObj.vendor, dataObj.lienholder_line, dataObj);
          if (![200].includes(response.status)) {
            showMessage(thisObj, 'An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
            setLoading(false)
          }
          else {
            if (response.status == 200) {
              setData(thisObj, 'txtarLienHolder', true);
              document.getElementById("SystemMaintenanceApplicationSupport_LienHolderProfilePopUp").childNodes[0].click();
              setLoading(false)
            }
            else {
              showMessage(thisObj, response.message)
              setLoading(false)
            }
          }
        }
      }
      setLoading(false)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
        setLoading(false)
      }
      else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnOK event:Click"
        );
      }
      //setLoading(false)
      return false;
    }
    return true;
  }
  thisObj.onbtnOkClick = onbtnOkClick
  //Click event for Cancel button
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceApplicationSupport_LienHolderProfilePopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_LienHolderProfile*/}

              {/* END_USER_CODE-USER_BEFORE_LienHolderProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxLienHolderDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxLienHolderDetails*/}

              <GroupBoxWidget
                conf={state.grpbxLienHolderDetails}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_ddRemitTo*/}

                {/* END_USER_CODE-USER_BEFORE_ddRemitTo*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRemitTo}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRemitTo*/}

                {/* END_USER_CODE-USER_AFTER_ddRemitTo*/}
                {/* START_USER_CODE-USER_BEFORE_txtLienOrder*/}

                {/* END_USER_CODE-USER_BEFORE_txtLienOrder*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLienOrder}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLienOrder*/}

                {/* END_USER_CODE-USER_AFTER_txtLienOrder*/}
                {/* START_USER_CODE-USER_BEFORE_txtRemitTo*/}

                {/* END_USER_CODE-USER_BEFORE_txtRemitTo*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRemitTo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRemitTo*/}

                {/* END_USER_CODE-USER_AFTER_txtRemitTo*/}
                {/* START_USER_CODE-USER_BEFORE_txtName*/}

                {/* END_USER_CODE-USER_BEFORE_txtName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtName*/}

                {/* END_USER_CODE-USER_AFTER_txtName*/}
                {/* START_USER_CODE-USER_BEFORE_txtAddress*/}

                {/* END_USER_CODE-USER_BEFORE_txtAddress*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAddress}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAddress*/}

                {/* END_USER_CODE-USER_AFTER_txtAddress*/}
                {/* START_USER_CODE-USER_BEFORE_txtCity*/}

                {/* END_USER_CODE-USER_BEFORE_txtCity*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCity}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCity*/}

                {/* END_USER_CODE-USER_AFTER_txtCity*/}
                {/* START_USER_CODE-USER_BEFORE_ddState*/}

                {/* END_USER_CODE-USER_BEFORE_ddState*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddState}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddState*/}

                {/* END_USER_CODE-USER_AFTER_ddState*/}
                {/* START_USER_CODE-USER_BEFORE_txtStateID*/}

                {/* END_USER_CODE-USER_BEFORE_txtStateID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStateID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStateID*/}

                {/* END_USER_CODE-USER_AFTER_txtStateID*/}
                {/* START_USER_CODE-USER_BEFORE_txtStateID1*/}

                {/* END_USER_CODE-USER_BEFORE_txtStateID1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStateID1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStateID1*/}

                {/* END_USER_CODE-USER_AFTER_txtStateID1*/}
                {/* START_USER_CODE-USER_BEFORE_txtZip*/}

                {/* END_USER_CODE-USER_BEFORE_txtZip*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtZip}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtZip*/}

                {/* END_USER_CODE-USER_AFTER_txtZip*/}
                {/* START_USER_CODE-USER_BEFORE_txtNatureOfLien*/}

                {/* END_USER_CODE-USER_BEFORE_txtNatureOfLien*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNatureOfLien}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNatureOfLien*/}

                {/* END_USER_CODE-USER_AFTER_txtNatureOfLien*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxLienHolderDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxLienHolderDetails*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtAddedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAddedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_txtChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtChangedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtChangedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtChangedBy*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_LienHolderProfile*/}

              {/* END_USER_CODE-USER_AFTER_LienHolderProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_LienHolderProfile
);
