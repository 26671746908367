/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  getData,
  setData,
  setValue,
} from "../../shared/WindowImports";

import "./DelvInAvailAgreement.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_DelvInAvailAgreement(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const comp_id = sessionStorage.getItem('compId')
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DelvInAvailAgreement",
    windowName: "DelvInAvailAgreement",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.DelvInAvailAgreement",
    // START_USER_CODE-USER_DelvInAvailAgreement_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Delivery In Agreement",
      scrCode: "",
    },
    // END_USER_CODE-USER_DelvInAvailAgreement_PROPERTIES
    btnAttach: {
      name: "btnAttach",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Attach",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAttach_PROPERTIES

      // END_USER_CODE-USER_btnAttach_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnRemove: {
      name: "btnRemove",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Remove",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnRemove_PROPERTIES

      // END_USER_CODE-USER_btnRemove_PROPERTIES
    },
    ddAgreement: {
      name: "ddAgreement",
      type: "DropDownFieldWidget",
      parent: "grpbxDelvInAvailAgreement",
      Label: "Agreements:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAgreement_PROPERTIES

      // END_USER_CODE-USER_ddAgreement_PROPERTIES
    },
    lbl4: {
      name: "lbl4",
      type: "LabelWidget",
      parent: "grpbxDelvInAvailAgreement",
      Label: "Label4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl4_PROPERTIES

      // END_USER_CODE-USER_lbl4_PROPERTIES
    },
    lblAgreeNumBuyPtId: {
      name: "lblAgreeNumBuyPtId",
      type: "LabelWidget",
      parent: "grpbxDelvInAvailAgreement",
      Label: "Agree Num - Buy Pt Id",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAgreeNumBuyPtId_PROPERTIES

      // END_USER_CODE-USER_lblAgreeNumBuyPtId_PROPERTIES
    },
    lblCropYear: {
      name: "lblCropYear",
      type: "LabelWidget",
      parent: "grpbxDelvInAvailAgreement",
      Label: "Crop Year",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear_PROPERTIES

      // END_USER_CODE-USER_lblCropYear_PROPERTIES
    },
    grpbxDelvInAvailAgreement: {
      name: "grpbxDelvInAvailAgreement",
      type: "GroupBoxWidget",
      parent: "DelvInAvailAgreement",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDelvInAvailAgreement_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvInAvailAgreement_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "DelvInAvailAgreement",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [lblAgreePresent, setLblAgreePresent] = useState("")
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  // START_USER_CODE-USER_METHODS

  // formLoad function
  async function FormLoad() {
    try {
      setLoading(true)
      // let dataJs ={
      //   whseRcptNum :'0302285',
      // }
      setValue(thisObj, "lbl4", cropYear)
      let dataJs = getData(thisObj, "DelvInAvailAgreementData");
      if (dataJs != null && dataJs != undefined && dataJs.lblERcptNum != "" && dataJs.lblERcptNum != undefined && dataJs.lblERcptNum != null) {
        let response = await WarehouseReceiptService.RetrieveDeliveryInAgreementWareHouseDetails(dataJs.lblERcptNum, null) //add data from parent screen once available
        let data = response

        if (data) {
          // let agreement_num = data[0].agree_num
          await bfillagreement(data[0]?.agree_num)
        }
        //  else {
        //   setLoading(false)
        //   return;
        // }
        let dropDownData = getData(thisObj, 'ddagreeData')
        if (dropDownData == "" || dropDownData == undefined || dropDownData == null) {
          setLoading(false)
          return;
        }
        if (data.length != 0) {
          for (let i = 0; i < dropDownData.length; i++) {
            let dropDownAgreeNum = parseInt(dropDownData[i].key, 10).toString()
            if (dropDownAgreeNum == data[0].agree_num) {
              setValue(thisObj, 'ddAgreement', dropDownData[i].key);
              setLblAgreePresent("Y")
            }
          }
        }

      }
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occured in formLoad"
        );
      }
      return false;
    }
  }

  //Function to convert to Pascal Case
  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  // Dropdown binding---------------
  const bfillagreement = async (agreement_num) => {
    try {
      // let dataJs = {
      //   buy_pt_id : 'R29',
      //   peanutType : 'RUNNER',
      // }
      let agreementNum = ''
      let dataJs = getData(thisObj, "DelvInAvailAgreementData");
      let Buy_Pt_Id = ''
      if (dataJs != null && dataJs != undefined && dataJs.lblPeanutType != "" && dataJs.lblPeanutType != undefined) {
        let js = []
        let strPNUT = ""
        if (dataJs.lblPeanutType == "RUN") {
          strPNUT = "RU";
        }
        else if (dataJs.lblPeanutType == "SP") {
          strPNUT = "SP";
        }
        else if (dataJs.lblPeanutType == "VIR") {
          strPNUT = "VA";
        }
        else if (dataJs.lblPeanutType == "VAL") {
          strPNUT = "VL";
        }
        if (agreement_num == "") {
          agreement_num = null
        }
        Buy_Pt_Id = dataJs.cmbBuyPtId == "" ? "All locations": dataJs.cmbBuyPtId
        
        let response = await WarehouseReceiptService.RetrieveDeliveryReceiptDetails(agreement_num, strPNUT, Buy_Pt_Id)
        let data = response
        if (data.length > 0) {
          for (var i = 0; i < data.length; i++) {
            agreementNum = data[i].agreement_num
            let paddedNum = data[i].agreement_num.toString().padStart(7, '0');
            // setData(thisObj, 'agreementNum', paddedNum)
            let obj = {
              key: paddedNum,
              description: `${paddedNum} - ${ConvertToPascalCase(data[i].buy_pt_id).toString()} - ${ConvertToPascalCase(data[i].buy_pt_name).toString()}`,
              index: i
            }
            js.push(obj)
            obj
          }
          thisObj.setState(current => {
            return {
              ...current,
              ddAgreement: {
                ...state["ddAgreement"],
                valueList: js
              }
            }
          });
          setData(thisObj, 'ddagreeData', js)
        }
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occured in Agreements dropdown"
        );
      }
      return false;
    }
  }
  // Attach button functionality

  const onbtnAttachClick = async () => {
    try {
      // let parentData ={
      //   whse_rcpt_num : '0302285',
      // }
      let parentData = getData(thisObj, "DelvInAvailAgreementData");
      let obj = {}
      let agreeList = thisObj.state['ddAgreement'].valueList;
      let SelectedAgreeListValue = thisObj.values['ddAgreement'];
      if (agreeList[0].key == undefined || agreeList[0].key == '' || agreeList[0].key == null) {
        return;
      }
      if (SelectedAgreeListValue !== "" && SelectedAgreeListValue !== undefined) {
        obj.agreement_num = agreeList.find(elem => elem.key === SelectedAgreeListValue).key;
        obj.agreeName = agreeList.find(elem => elem.key === SelectedAgreeListValue).description;
        obj.indexNum = agreeList.find(elem => elem.key === SelectedAgreeListValue).index;
      }
      if (obj.agreement_num == null || obj.agreement_num == undefined || obj.agreement_num == '') {
        return;
      }
      if (lblAgreePresent == 'Y') {
        return;
      }
      let agreement_num = parseInt(obj.agreement_num, 10).toString();
      let prev_agree = "NONE";

      let response = await WarehouseReceiptService.UpdateDeliveryWareHouseAgreement(agreement_num, parentData.lblERcptNum, prev_agree) //add data from parent screen once available
      if (response.status == 200) {
        alert("Agreement tied Successfully");
        if (obj.agreeName !== "") {
          setLblAgreePresent("Y")
        }
        document.getElementById("WarehouseReceipts_DelvInAvailAgreementPopUp").childNodes[0].click()
      }
      else {
        alert("An error occured while Adding the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persist.\n\nError: \n\n" + (response?.message || ''))
        return;
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnAttach event:Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAttachClick = onbtnAttachClick;

  // Remove button functionality
  const onbtnRemoveClick = async () => {
    try {
      // let parentData ={
      //   lblAgreePresent : '',
      //   whse_rcpt_num : '0302285',
      // }
      let parentData = getData(thisObj, "DelvInAvailAgreementData");
      let obj = {}
      let agreeList = thisObj.state['ddAgreement'].valueList;
      let SelectedAgreeListValue = thisObj.values['ddAgreement'];
      if (agreeList[0].key == undefined || agreeList[0].key == '' || agreeList[0].key == null) {
        return;
      }
      if (SelectedAgreeListValue !== "" && SelectedAgreeListValue !== undefined) {
        obj.agreement_num = agreeList.find(elem => elem.key === SelectedAgreeListValue).key;
        obj.agreeName = agreeList.find(elem => elem.key === SelectedAgreeListValue).description;
        obj.indexNum = agreeList.find(elem => elem.key === SelectedAgreeListValue).index;
      }
      if (obj.agreement_num == null || obj.agreement_num == undefined || obj.agreement_num == '') {
        return;
      }
      if (lblAgreePresent !== 'Y') {
        return;
      }
      let agreement_num = null;
      let prev_agree = parseInt(obj.agreement_num, 10).toString();

      let response = await WarehouseReceiptService.UpdateDeliveryWareHouseAgreement(agreement_num, parentData.lblERcptNum, prev_agree) //add data from parent screen once available
      if (response.status == 200) {
        alert("Agreement Removed Successfully")
        if (obj.agreeName !== "") {
          setLblAgreePresent("N")
        }
        document.getElementById("WarehouseReceipts_DelvInAvailAgreementPopUp").childNodes[0].click()
      }
      else {
        alert("An error occured while Adding the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persist.\n\nError: \n\n" + (response?.message || ''))
        return;
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnAttach event:Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnRemoveClick = onbtnRemoveClick;

  // Close button functionality

  const onbtnCloseClick = () => {
    try {
      let obj = {}
      let agreeList = thisObj.state['ddAgreement'].valueList;
      let SelectedAgreeListValue = thisObj.values['ddAgreement'];
      if (agreeList[0].key == undefined || agreeList[0].key == '' || agreeList[0].key == null) {
        document.getElementById("WarehouseReceipts_DelvInAvailAgreementPopUp").childNodes[0].click()
        return;
      }
      if (SelectedAgreeListValue !== "" && SelectedAgreeListValue !== undefined) {
        obj.agreeName = agreeList.find(elem => elem.key === SelectedAgreeListValue).description;
      }
      if (obj.agreeName == '') {
        // setData(thisObj, "lblAgreePresent", "Y");
        setLblAgreePresent("Y")
      }
      else {
        // setData(thisObj, "lblAgreePresent", "N");
        setLblAgreePresent("N")
      }
      document.getElementById("WarehouseReceipts_DelvInAvailAgreementPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DelvInAvailAgreement*/}

              {/* END_USER_CODE-USER_BEFORE_DelvInAvailAgreement*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDelvInAvailAgreement*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDelvInAvailAgreement*/}

              <GroupBoxWidget
                conf={state.grpbxDelvInAvailAgreement}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblCropYear*/}

                {/* END_USER_CODE-USER_BEFORE_lblCropYear*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCropYear}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCropYear*/}

                {/* END_USER_CODE-USER_AFTER_lblCropYear*/}
                {/* START_USER_CODE-USER_BEFORE_lbl4*/}

                {/* END_USER_CODE-USER_BEFORE_lbl4*/}

                <LabelWidget
                  values={values}
                  conf={state.lbl4}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lbl4*/}

                {/* END_USER_CODE-USER_AFTER_lbl4*/}
                {/* START_USER_CODE-USER_BEFORE_ddAgreement*/}

                {/* END_USER_CODE-USER_BEFORE_ddAgreement*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAgreement}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAgreement*/}

                {/* END_USER_CODE-USER_AFTER_ddAgreement*/}
                {/* START_USER_CODE-USER_BEFORE_lblAgreeNumBuyPtId*/}

                {/* END_USER_CODE-USER_BEFORE_lblAgreeNumBuyPtId*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAgreeNumBuyPtId}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAgreeNumBuyPtId*/}

                {/* END_USER_CODE-USER_AFTER_lblAgreeNumBuyPtId*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDelvInAvailAgreement*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDelvInAvailAgreement*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnRemove*/}

                {/* END_USER_CODE-USER_BEFORE_btnRemove*/}

                <ButtonWidget
                  conf={state.btnRemove}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnRemove*/}

                {/* END_USER_CODE-USER_AFTER_btnRemove*/}
                {/* START_USER_CODE-USER_BEFORE_btnAttach*/}

                {/* END_USER_CODE-USER_BEFORE_btnAttach*/}

                <ButtonWidget
                  conf={state.btnAttach}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAttach*/}

                {/* END_USER_CODE-USER_AFTER_btnAttach*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_DelvInAvailAgreement*/}

              {/* END_USER_CODE-USER_AFTER_DelvInAvailAgreement*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_DelvInAvailAgreement);