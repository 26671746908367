/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  getData,
  setValue,
  disable,
  getValue,
  setData,
  hide
} from "../../shared/WindowImports";

import "./PeanutVarietyProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_PeanutVarietyProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PeanutVarietyProfile",
    windowName: "PeanutVarietyProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.PeanutVarietyProfile",
    // START_USER_CODE-USER_PeanutVarietyProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Peanut Variety Profile",
      scrCode: "PN0040C",
    },
    // END_USER_CODE-USER_PeanutVarietyProfile_PROPERTIES
    btnAddVP: {
      name: "btnAddVP",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddVP_PROPERTIES

      // END_USER_CODE-USER_btnAddVP_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    chkboxOleicAddendumAllowed: {
      name: "chkboxOleicAddendumAllowed",
      type: "CheckBoxWidget",
      parent: "grpbxPeanutVariety",
      Label: "Oleic Addendum Allowed",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxOleicAddendumAllowed_PROPERTIES

      // END_USER_CODE-USER_chkboxOleicAddendumAllowed_PROPERTIES
    },
    ddDefaultOleic: {
      name: "ddDefaultOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutVariety",
      Label: "Default Oleic:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
       ofTypeDomain: "d_String",
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDefaultOleic_PROPERTIES
      caretIcon:true
      // END_USER_CODE-USER_ddDefaultOleic_PROPERTIES
    },
    ddOptionalSymbol: {
      name: "ddOptionalSymbol",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutVariety",
      Label: "Optional Symbol:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOptionalSymbol_PROPERTIES
      caretIcon:true
      // END_USER_CODE-USER_ddOptionalSymbol_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblArea: {
      name: "lblArea",
      type: "LabelWidget",
      parent: "grpbxPeanutVariety",
      Label: "Area:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblArea_PROPERTIES

      // END_USER_CODE-USER_lblArea_PROPERTIES
    },
    lblAreaValue: {
      name: "lblAreaValue",
      type: "LabelWidget",
      parent: "grpbxPeanutVariety",
      Label: ".",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAreaValue_PROPERTIES

      // END_USER_CODE-USER_lblAreaValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblPeanutType: {
      name: "lblPeanutType",
      type: "LabelWidget",
      parent: "grpbxPeanutVariety",
      Label: "Peanut Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPeanutType_PROPERTIES

      // END_USER_CODE-USER_lblPeanutType_PROPERTIES
    },
    lblPeanutTypeValue: {
      name: "lblPeanutTypeValue",
      type: "LabelWidget",
      parent: "grpbxPeanutVariety",
      Label: ".",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPeanutTypeValue_PROPERTIES

      // END_USER_CODE-USER_lblPeanutTypeValue_PROPERTIES
    },
    txtID: {
      name: "txtID",
      type: "TextBoxWidget",
      parent: "grpbxPeanutVariety",
      Label: "ID:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtID_PROPERTIES

      // END_USER_CODE-USER_txtID_PROPERTIES
    },
    txtName: {
      name: "txtName",
      type: "TextBoxWidget",
      parent: "grpbxPeanutVariety",
      Label: "Name:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtName_PROPERTIES

      // END_USER_CODE-USER_txtName_PROPERTIES
    },
    grpbxPeanutVariety: {
      name: "grpbxPeanutVariety",
      type: "GroupBoxWidget",
      parent: "PeanutVarietyProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_grpbxPeanutVariety_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "PeanutVarietyProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, "ddOptionalSymbol", ''),
      setValue(thisObj, "ddDefaultOleic", '')
    FormLoad()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  const FormLoad = () => {
    hide(thisObj,'chkboxOleicAddendumAllowed')
    let dataObj = getData(thisObj, 'frmPeanutVarietyProfile');
    if (dataObj.cmdOk_Caption == "Add") {
      document.getElementById('btnAddVP').innerText = "Add"
      setValue(thisObj, 'lblPeanutTypeValue', dataObj.lblPeanutType_Caption)
      setValue(thisObj, 'lblAreaValue', dataObj.lblArea_Caption)
      bFillSymbolIndList();
      bFillOleicIndList();
    }
    if (dataObj.cmdOk_Caption == 'Update') {
      document.getElementById('btnAddVP').innerText = "Update"
      disable(thisObj, 'txtID')
      setValue(thisObj, 'txtID', dataObj.txtID)
      setValue(thisObj, 'txtName', dataObj.txtName)
      setValue(thisObj, 'lblPeanutTypeValue', dataObj.lblPeanutType_Caption)
      setValue(thisObj, 'lblAreaValue', dataObj.lblArea_Caption)
      setValue(thisObj, 'chkboxOleicAddendumAllowed', dataObj.chkboxOleicAddendumAllowed)
      setValue(thisObj, 'lblAddedByValue', dataObj.lblAddedBy)
      setValue(thisObj, 'lblChangedByValue', dataObj.lblChangedBy)
      bFillSymbolIndList(dataObj.symbol_ind);
      bFillOleicIndList(dataObj.oleic_ind);
    }
    if (dataObj.cmdOk_Caption == 'View') {
      hide(thisObj,'btnAddVP',false)
      disable(thisObj, 'txtID')
      setValue(thisObj, 'txtID', dataObj.txtID)
      setValue(thisObj, 'txtName', dataObj.txtName)
      setValue(thisObj, 'lblPeanutTypeValue', dataObj.lblPeanutType_Caption)
      setValue(thisObj, 'lblAreaValue', dataObj.lblArea_Caption)
      setValue(thisObj, 'chkboxOleicAddendumAllowed', dataObj.chkboxOleicAddendumAllowed)
      setValue(thisObj, 'lblAddedByValue', dataObj.lblAddedBy)
      setValue(thisObj, 'lblChangedByValue', dataObj.lblChangedBy)
      bFillSymbolIndList(dataObj.symbol_ind);
      bFillOleicIndList(dataObj.oleic_ind);
  }
}
  // formvalid function
  const FormValid = () => {
    var bFormValid = false
    let Id = getValue(thisObj, 'txtID')
    let name = getValue(thisObj, 'txtName')
    if (Id == null || Id == '' || Id == undefined) {
      showMessage(thisObj, "Id is a required field")
      return bFormValid;
    }
    if (name == null || name == '' || name == undefined) {
      showMessage(thisObj, "Name is a required field")
      return bFormValid;
    }
    bFormValid = true
  }
  //Dropddown for bFillOleicIndList List
  const bFillOleicIndList = (oleic_ind) => {
    let js = []
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: '', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddDefaultOleic: {
          ...state["ddDefaultOleic"],
          valueList: js
        }
      }
    })
    let dataObj = getData(thisObj, 'frmPeanutVarietyProfile')
    if (dataObj.cmdOk_Caption == "Update") {
      setValue(thisObj, "ddDefaultOleic", oleic_ind)
    }
  }
  //Dropddown for bFillSymbolIndList List
  const bFillSymbolIndList = (symbol_ind) => {
    let js = []
    js.push({ key: '', description: 'No Symbol' })
    js.push({ key: 'TRADEMARK', description: 'Trademark ᵀᴹ' })
    js.push({ key: 'REGISTERED TRADEMARK', description: 'Registered Trademark ®' })
    js.push({ key: 'COPYRIGHT', description: 'Copyright ©' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOptionalSymbol: {
          ...state["ddOptionalSymbol"],
          valueList: js
        }
      }
    })
    let dataObj = getData(thisObj, 'frmPeanutVarietyProfile')
    if (dataObj.cmdOk_Caption == "Update") {
      setValue(thisObj, "ddOptionalSymbol", symbol_ind);
    }
  }
  const onbtnAddVPClick = async () => {
    try {
      if (FormValid() == false) {
        return;
      }
      let dataObj = getData(thisObj, "frmPeanutVarietyProfile");
      let area_id = dataObj.lblArea_Caption;
      area_id = area_id.split("-")[0]
      let pnut_type_id = dataObj.lblPeanutType_Caption;
      pnut_type_id = pnut_type_id.split("-")[0]
      let pnut_variety_id = getValue(thisObj, 'txtID').toLocaleUpperCase()
      let pnut_variety_name = getValue(thisObj, 'txtName')
      let symbol_ind = getValue(thisObj, 'ddOptionalSymbol')
      let oleic_ind = getValue(thisObj, 'ddDefaultOleic')
      let oleic_addem_ind = ''
      if (thisObj.values.chkboxOleicAddendumAllowed == true) {
        oleic_addem_ind = "Y"
      }
      else { oleic_addem_ind = "N" }
       // Add button functionality
      if (dataObj.cmdOk_Caption == "Add") {
        let temp = {
          "area_id": dataObj.lblArea_Tag,
          "pnut_type_id": dataObj.lblPeanutType_Tag,
          "pnut_variety_id": pnut_variety_id,
          "pnut_variety_name": pnut_variety_name,
          "symbol_ind": symbol_ind,
          "oleic_ind":  oleic_ind,
          "oleic_addem_ind": oleic_addem_ind,
        }
        let response = await SystemMaintenanceMasterManagementService.CreatePeanutVarietyControl(null, temp)
        let data = response
        if (data.status == 404 || data.status == 500 || data.status == null || data.status == '') {
          showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists."+ response.message)
          return;
        };
          let Array = []
          let Obj = {}
          Obj.lblAreaValue = area_id
          Obj.lblPeanutTypeValue = pnut_type_id
          Obj.txtID = pnut_variety_id
          Obj.txtName = pnut_variety_name
          Obj.ddOptionalSymbol = symbol_ind
          Obj.ddDefaultOleic = oleic_ind
          Obj.chkboxOleicAddendumAllowed = oleic_addem_ind
          Array.push(Obj)
          setData(thisObj, 'frmPeanutVarietyProfileSetup', Obj)
          document.getElementById("SystemMaintenanceMasterManagement_PeanutVarietyProfilePopUp").childNodes[0].click()
      }
      //update button functionality
      if (dataObj.cmdOk_Caption == "Update") {
        disable(thisObj, 'txtID')
        let temp = {
          "pnut_variety_name": pnut_variety_name,
          "symbol_ind": symbol_ind,
          "oleic_ind":  oleic_ind,
          "oleic_addem_ind": oleic_addem_ind,
        }
        let response = await SystemMaintenanceMasterManagementService.UpdatePeanutVarietyControl(null, area_id, pnut_type_id, pnut_variety_id, temp)
        let data = response
        if (data.status == 404 || data.status == 500 || data.status == null || data.status == '') {
          showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists."+ response.message)
          return;
        };
          let Array = []
          let Obj = {}
          Obj.lblAreaValue = area_id
          Obj.lblPeanutTypeValue = pnut_type_id
          Obj.txtID = pnut_variety_id
          Obj.txtName = pnut_variety_name
          Obj.ddOptionalSymbol = symbol_ind
          Obj.ddDefaultOleic = oleic_ind
          Obj.chkboxOleicAddendumAllowed = oleic_addem_ind
          Array.push(Obj)
          setData(thisObj, 'frmPeanutVarietyProfileSetup', Obj)
          document.getElementById("SystemMaintenanceMasterManagement_PeanutVarietyProfilePopUp").childNodes[0].click()
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnAddVPClick = onbtnAddVPClick;
  //cancel click
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_PeanutVarietyProfilePopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;


  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PeanutVarietyProfile*/}

              {/* END_USER_CODE-USER_BEFORE_PeanutVarietyProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPeanutVariety*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPeanutVariety*/}

              <GroupBoxWidget
                conf={state.grpbxPeanutVariety}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_lblPeanutType*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPeanutType}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_lblPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_lblPeanutTypeValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblPeanutTypeValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPeanutTypeValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPeanutTypeValue*/}

                {/* END_USER_CODE-USER_AFTER_lblPeanutTypeValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblArea*/}

                {/* END_USER_CODE-USER_BEFORE_lblArea*/}

                <LabelWidget
                  values={values}
                  conf={state.lblArea}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblArea*/}

                {/* END_USER_CODE-USER_AFTER_lblArea*/}
                {/* START_USER_CODE-USER_BEFORE_lblAreaValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAreaValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAreaValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAreaValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAreaValue*/}
                {/* START_USER_CODE-USER_BEFORE_txtID*/}

                {/* END_USER_CODE-USER_BEFORE_txtID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtID*/}

                {/* END_USER_CODE-USER_AFTER_txtID*/}
                {/* START_USER_CODE-USER_BEFORE_txtName*/}

                {/* END_USER_CODE-USER_BEFORE_txtName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtName*/}

                {/* END_USER_CODE-USER_AFTER_txtName*/}
                {/* START_USER_CODE-USER_BEFORE_ddDefaultOleic*/}

                {/* END_USER_CODE-USER_BEFORE_ddDefaultOleic*/}
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOptionalSymbol}
                  screenConf={state}
                ></DropDownWidget>
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddDefaultOleic}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddDefaultOleic*/}

                {/* END_USER_CODE-USER_AFTER_ddDefaultOleic*/}
                {/* START_USER_CODE-USER_BEFORE_ddOptionalSymbol*/}

                {/* END_USER_CODE-USER_BEFORE_ddOptionalSymbol*/}

              
                 
                {/* START_USER_CODE-USER_AFTER_ddOptionalSymbol*/}

                {/* END_USER_CODE-USER_AFTER_ddOptionalSymbol*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxOleicAddendumAllowed*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxOleicAddendumAllowed*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxOleicAddendumAllowed}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxOleicAddendumAllowed*/}

                {/* END_USER_CODE-USER_AFTER_chkboxOleicAddendumAllowed*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPeanutVariety*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPeanutVariety*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnAddVP*/}

                {/* END_USER_CODE-USER_BEFORE_btnAddVP*/}

                <ButtonWidget
                  conf={state.btnAddVP}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAddVP*/}

                {/* END_USER_CODE-USER_AFTER_btnAddVP*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_PeanutVarietyProfile*/}

              {/* END_USER_CODE-USER_AFTER_PeanutVarietyProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_PeanutVarietyProfile
);
