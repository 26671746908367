/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  TextAreaWidget,
  setValue,
  getValue,
  getData,
  disable,
  enable,
  hide,
  show,
  setData,
  goTo
} from "../../shared/WindowImports";

import "./ReviewCheck.scss";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment"

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import CheckInquiry from "../CheckInquiry/CheckInquiry";
import Loading from "../../../Loader/Loading";
import CommonContext from '../../Store/CommonContext';//commonContext to set Data
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function AccountPayment_ReviewCheck(props) {
  const commonContext = useContext(CommonContext)
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let userid = sessionStorage.getItem("userid");
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ReviewCheck",
    windowName: "ReviewCheck",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "AccountPayment.ReviewCheck",
    // START_USER_CODE-USER_ReviewCheck_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Review Check",
      scrCode: "PN1100E",
    },
    // END_USER_CODE-USER_ReviewCheck_PROPERTIES
    btnAcctDist: {
      name: "btnAcctDist",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Acct. Dist.",
      CharWidth: "24",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAcctDist_PROPERTIES

      // END_USER_CODE-USER_btnAcctDist_PROPERTIES
    },
    btnAuditHistory: {
      name: "btnAuditHistory",
      type: "ButtonWidget",
      parent: "grpbxdetails4",
      Label: "Audit History",
      CharWidth: "28",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAuditHistory_PROPERTIES

      // END_USER_CODE-USER_btnAuditHistory_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCorrectCheck: {
      name: "btnCorrectCheck",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Correct Check #",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCorrectCheck_PROPERTIES

      // END_USER_CODE-USER_btnCorrectCheck_PROPERTIES
    },
    btnDeductuionTracking: {
      name: "btnDeductuionTracking",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Deduction Tracking",
      CharWidth: "37",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeductuionTracking_PROPERTIES

      // END_USER_CODE-USER_btnDeductuionTracking_PROPERTIES
    },
    // btnPrinter: {
    //   name: "btnPrinter",
    //   type: "ButtonWidget",
    //   parent: "cmmndCntnrActions",
    //   Label: ".....",
    //   CharWidth: "13",

    //   ofTypeDomain: "d_boolean",
    //   // START_USER_CODE-USER_btnPrinter_PROPERTIES

    //   // END_USER_CODE-USER_btnPrinter_PROPERTIES
    // },
    btnPrintStub: {
      name: "btnPrintStub",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print Stub",
      CharWidth: "23",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintStub_PROPERTIES

      // END_USER_CODE-USER_btnPrintStub_PROPERTIES
    },
    btnResendWireConfirmation: {
      name: "btnResendWireConfirmation",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Resend Wire Confirmation",
      CharWidth: "48",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnResendWireConfirmation_PROPERTIES

      // END_USER_CODE-USER_btnResendWireConfirmation_PROPERTIES
    },
    btnVoid: {
      name: "btnVoid",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Void",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVoid_PROPERTIES

      // END_USER_CODE-USER_btnVoid_PROPERTIES
    },
    colBuyPt: {
      name: "colBuyPt",
      type: "GridColumnWidget",
      parent: "gridPaymentActivity",
      Label: "Buy Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPt_PROPERTIES

      // END_USER_CODE-USER_colBuyPt_PROPERTIES
    },
    colNumber1: {
      name: "colNumber1",
      type: "GridColumnWidget",
      parent: "gridPaymentActivity",
      Label: "Number 1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNumber1_PROPERTIES

      // END_USER_CODE-USER_colNumber1_PROPERTIES
    },
    colNumber2: {
      name: "colNumber2",
      type: "GridColumnWidget",
      parent: "gridPaymentActivity",
      Label: "Number 2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNumber2_PROPERTIES

      // END_USER_CODE-USER_colNumber2_PROPERTIES
    },
    colOpt: {
      name: "colOpt",
      type: "GridColumnWidget",
      parent: "gridPaymentActivity",
      Label: "Opt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpt_PROPERTIES

      // END_USER_CODE-USER_colOpt_PROPERTIES
    },
    colProceeds: {
      name: "colProceeds",
      type: "GridColumnWidget",
      parent: "gridPaymentActivity",
      Label: "Proceeds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProceeds_PROPERTIES

      // END_USER_CODE-USER_colProceeds_PROPERTIES
    },
    colType1: {
      name: "colType1",
      type: "GridColumnWidget",
      parent: "gridPaymentActivity",
      Label: "Type 1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType1_PROPERTIES

      // END_USER_CODE-USER_colType1_PROPERTIES
    },
    colType2: {
      name: "colType2",
      type: "GridColumnWidget",
      parent: "gridPaymentActivity",
      Label: "Type 2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType2_PROPERTIES

      // END_USER_CODE-USER_colType2_PROPERTIES
    },
    gridPaymentActivity: {
      name: "gridPaymentActivity",
      type: "GridWidget",
      parent: "grpbxPaymentActivityRemarks",
      gridCellsOrder:
        "txtcolBuyPt,txtcolType1,txtcolNumber1,txtcolType2,txtcolNumber2,txtcolProceeds,txtcolOpt",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridPaymentActivity_PROPERTIES

      // END_USER_CODE-USER_gridPaymentActivity_PROPERTIES
    },
    grpbxDetails1: {
      name: "grpbxDetails1",
      type: "GroupBoxWidget",
      parent: "grpbxCheckDetails",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDetails1_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails1_PROPERTIES
    },
    grpbxDetails2: {
      name: "grpbxDetails2",
      type: "GroupBoxWidget",
      parent: "grpbxCheckDetails",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDetails2_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails2_PROPERTIES
    },
    grpbxDetails3: {
      name: "grpbxDetails3",
      type: "GroupBoxWidget",
      parent: "grpbxCheckDetails",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDetails3_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails3_PROPERTIES
    },
    grpbxdetails4: {
      name: "grpbxdetails4",
      type: "GroupBoxWidget",
      parent: "grpbxCheckDetails",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxdetails4_PROPERTIES

      // END_USER_CODE-USER_grpbxdetails4_PROPERTIES
    },
    grpbxDetails5: {
      name: "grpbxDetails5",
      type: "GroupBoxWidget",
      parent: "grpbxCheckDetails",
      ColSpan: "4",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDetails5_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails5_PROPERTIES
    },
    lblAcknowledgement: {
      name: "lblAcknowledgement",
      type: "LabelWidget",
      parent: "grpbxdetails4",
      Label: "Acknowledgement:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAcknowledgement_PROPERTIES

      // END_USER_CODE-USER_lblAcknowledgement_PROPERTIES
    },
    lblAcknowledgementValue: {
      name: "lblAcknowledgementValue",
      type: "LabelWidget",
      parent: "grpbxdetails4",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAcknowledgementValue_PROPERTIES

      // END_USER_CODE-USER_lblAcknowledgementValue_PROPERTIES
    },
    lblCheck: {
      name: "lblCheck",
      type: "LabelWidget",
      parent: "grpbxDetails1",
      Label: "Check #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCheck_PROPERTIES

      // END_USER_CODE-USER_lblCheck_PROPERTIES
    },
    lblCheckValue: {
      name: "lblCheckValue",
      type: "LabelWidget",
      parent: "grpbxDetails1",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCheckValue_PROPERTIES

      // END_USER_CODE-USER_lblCheckValue_PROPERTIES
    },
    lblClearDate: {
      name: "lblClearDate",
      type: "LabelWidget",
      parent: "grpbxDetails3",
      Label: "Clear Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblClearDate_PROPERTIES

      // END_USER_CODE-USER_lblClearDate_PROPERTIES
    },
    lblClearDateValue: {
      name: "lblClearDateValue",
      type: "LabelWidget",
      parent: "grpbxDetails3",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblClearDateValue_PROPERTIES

      // END_USER_CODE-USER_lblClearDateValue_PROPERTIES
    },
    lblFederalReserveRef: {
      name: "lblFederalReserveRef",
      type: "LabelWidget",
      parent: "grpbxDetails5",
      Label: "Federal Reserve Ref#:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFederalReserveRef_PROPERTIES

      // END_USER_CODE-USER_lblFederalReserveRef_PROPERTIES
    },
    lblFederalReserveRefValue: {
      name: "lblFederalReserveRefValue",
      type: "LabelWidget",
      parent: "grpbxDetails5",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFederalReserveRefValue_PROPERTIES

      // END_USER_CODE-USER_lblFederalReserveRefValue_PROPERTIES
    },
    lblInterestAgree: {
      name: "lblInterestAgree",
      type: "LabelWidget",
      parent: "grpbxDetails2",
      Label: "Interest Agree #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblInterestAgree_PROPERTIES

      // END_USER_CODE-USER_lblInterestAgree_PROPERTIES
    },
    lblInterestAgreeValue: {
      name: "lblInterestAgreeValue",
      type: "LabelWidget",
      parent: "grpbxDetails2",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblInterestAgreeValue_PROPERTIES

      // END_USER_CODE-USER_lblInterestAgreeValue_PROPERTIES
    },
    lblIssueDate: {
      name: "lblIssueDate",
      type: "LabelWidget",
      parent: "grpbxDetails3",
      Label: "Issue Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblIssueDate_PROPERTIES

      // END_USER_CODE-USER_lblIssueDate_PROPERTIES
    },
    lblIssueDateValue: {
      name: "lblIssueDateValue",
      type: "LabelWidget",
      parent: "grpbxDetails3",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblIssueDateValue_PROPERTIES

      // END_USER_CODE-USER_lblIssueDateValue_PROPERTIES
    },
    lblIssueUser: {
      name: "lblIssueUser",
      type: "LabelWidget",
      parent: "grpbxDetails3",
      Label: "Issue User:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblIssueUser_PROPERTIES

      // END_USER_CODE-USER_lblIssueUser_PROPERTIES
    },
    lblIssueUserValue: {
      name: "lblIssueUserValue",
      type: "LabelWidget",
      parent: "grpbxDetails3",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblIssueUserValue_PROPERTIES

      // END_USER_CODE-USER_lblIssueUserValue_PROPERTIES
    },
    lbllblReconDateValue: {
      name: "lbllblReconDateValue",
      type: "LabelWidget",
      parent: "grpbxDetails3",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbllblReconDateValue_PROPERTIES

      // END_USER_CODE-USER_lbllblReconDateValue_PROPERTIES
    },
    txtLienHolder: {
      name: "txtLienHolder",
      type: "TextAreaWidget",
      parent: "grpbxDetails1",
      Label: "Lien Holder:",
      ColSpan: "2",
      ColSpanForLargeDesktop: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled :false,
    },

    lblLocation: {
      name: "lblLocation",
      type: "LabelWidget",
      parent: "grpbxDetails1",
      Label: "Location:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLocation_PROPERTIES

      // END_USER_CODE-USER_lblLocation_PROPERTIES
    },
    lblLocationValue: {
      name: "lblLocationValue",
      type: "LabelWidget",
      parent: "grpbxDetails1",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLocationValue_PROPERTIES

      // END_USER_CODE-USER_lblLocationValue_PROPERTIES
    },
    lblPaymentActivity: {
      name: "lblPaymentActivity",
      type: "LabelWidget",
      parent: "grpbxPaymentActivityRemarks",
      Label: "Payment Activity:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPaymentActivity_PROPERTIES

      // END_USER_CODE-USER_lblPaymentActivity_PROPERTIES
    },
    lblProceeds: {
      name: "lblProceeds",
      type: "LabelWidget",
      parent: "grpbxDetails2",
      Label: "Proceeds:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblProceeds_PROPERTIES

      // END_USER_CODE-USER_lblProceeds_PROPERTIES
    },
    lblProceedsValue: {
      name: "lblProceedsValue",
      type: "LabelWidget",
      parent: "grpbxDetails2",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblProceedsValue_PROPERTIES

      // END_USER_CODE-USER_lblProceedsValue_PROPERTIES
    },
    lblReconDate: {
      name: "lblReconDate",
      type: "LabelWidget",
      parent: "grpbxDetails3",
      Label: "Recon Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblReconDate_PROPERTIES

      // END_USER_CODE-USER_lblReconDate_PROPERTIES
    },
    lblRemitTo: {
      name: "lblRemitTo",
      type: "LabelWidget",
      parent: "grpbxDetails1",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo_PROPERTIES
    },
    lblRemitToValue: {
      name: "lblRemitToValue",
      type: "LabelWidget",
      parent: "grpbxDetails1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitToValue_PROPERTIES

      // END_USER_CODE-USER_lblRemitToValue_PROPERTIES
    },

    lblRemitToValue2: {
      name: "lblRemitToValue2",
      type: "LabelWidget",
      parent: "grpbxDetails1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitToValue_PROPERTIES

      // END_USER_CODE-USER_lblRemitToValue_PROPERTIES
    },
    lblRemitToValue3: {
      name: "lblRemitToValue3",
      type: "LabelWidget",
      parent: "grpbxDetails1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitToValue_PROPERTIES

      // END_USER_CODE-USER_lblRemitToValue_PROPERTIES
    },
    lblRemitToValue4: {
      name: "lblRemitToValue4",
      type: "LabelWidget",
      parent: "grpbxDetails1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitToValue_PROPERTIES

      // END_USER_CODE-USER_lblRemitToValue_PROPERTIES
    },
    lblSpecialType: {
      name: "lblSpecialType",
      type: "LabelWidget",
      parent: "grpbxDetails2",
      Label: "Special Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpecialType_PROPERTIES

      // END_USER_CODE-USER_lblSpecialType_PROPERTIES
    },
    lblSpecialTypeValue: {
      name: "lblSpecialTypeValue",
      type: "LabelWidget",
      parent: "grpbxDetails2",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpecialTypeValue_PROPERTIES

      // END_USER_CODE-USER_lblSpecialTypeValue_PROPERTIES
    },
    lblStatus: {
      name: "lblStatus",
      type: "LabelWidget",
      parent: "grpbxDetails2",
      Label: "Status:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblStatus_PROPERTIES

      // END_USER_CODE-USER_lblStatus_PROPERTIES
    },
    lblStatusValue: {
      name: "lblStatusValue",
      type: "LabelWidget",
      parent: "grpbxDetails2",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblStatusValue_PROPERTIES

      // END_USER_CODE-USER_lblStatusValue_PROPERTIES
    },
    lblVendor: {
      name: "lblVendor",
      type: "LabelWidget",
      parent: "grpbxDetails1",
      Label: "Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor_PROPERTIES

      // END_USER_CODE-USER_lblVendor_PROPERTIES
    },
    lblVendorValue: {
      name: "lblVendorValue",
      type: "LabelWidget",
      parent: "grpbxDetails1",
      // Label: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendorValue_PROPERTIES

      // END_USER_CODE-USER_lblVendorValue_PROPERTIES
    },
    lblVoidDate: {
      name: "lblVoidDate",
      type: "LabelWidget",
      parent: "grpbxDetails3",
      Label: "Void Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVoidDate_PROPERTIES

      // END_USER_CODE-USER_lblVoidDate_PROPERTIES
    },
    lblVoidDateValue: {
      name: "lblVoidDateValue",
      type: "LabelWidget",
      parent: "grpbxDetails3",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVoidDateValue_PROPERTIES

      // END_USER_CODE-USER_lblVoidDateValue_PROPERTIES
    },
    lblVoidUser: {
      name: "lblVoidUser",
      type: "LabelWidget",
      parent: "grpbxDetails3",
      Label: "Void User:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVoidUser_PROPERTIES

      // END_USER_CODE-USER_lblVoidUser_PROPERTIES
    },
    lblVoidUserValue: {
      name: "lblVoidUserValue",
      type: "LabelWidget",
      parent: "grpbxDetails3",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVoidUserValue_PROPERTIES

      // END_USER_CODE-USER_lblVoidUserValue_PROPERTIES
    },
    lblWellsFargoRef: {
      name: "lblWellsFargoRef",
      type: "LabelWidget",
      parent: "grpbxDetails5",
      Label: "Wells Fargo Ref#:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWellsFargoRef_PROPERTIES

      // END_USER_CODE-USER_lblWellsFargoRef_PROPERTIES
    },
    lblWellsFargoRefValue: {
      name: "lblWellsFargoRefValue",
      type: "LabelWidget",
      parent: "grpbxDetails5",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWellsFargoRefValue_PROPERTIES

      // END_USER_CODE-USER_lblWellsFargoRefValue_PROPERTIES
    },
    lblWireData: {
      name: "lblWireData",
      type: "LabelWidget",
      parent: "grpbxdetails4",
      Label: "Wire Data:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWireData_PROPERTIES

      // END_USER_CODE-USER_lblWireData_PROPERTIES
    },
    lblWireDataValue: {
      name: "lblWireDataValue",
      type: "LabelWidget",
      parent: "grpbxdetails4",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWireDataValue_PROPERTIES

      // END_USER_CODE-USER_lblWireDataValue_PROPERTIES
    },
    lblWireErrorMsg: {
      name: "lblWireErrorMsg",
      type: "LabelWidget",
      parent: "grpbxDetails5",
      Label: "Wire Error Msg:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWireErrorMsg_PROPERTIES

      // END_USER_CODE-USER_lblWireErrorMsg_PROPERTIES
    },
    lblWireErrorMsgValue: {
      name: "lblWireErrorMsgValue",
      type: "LabelWidget",
      parent: "grpbxDetails5",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWireErrorMsgValue_PROPERTIES

      // END_USER_CODE-USER_lblWireErrorMsgValue_PROPERTIES
    },
    lblWireFileID: {
      name: "lblWireFileID",
      type: "LabelWidget",
      parent: "grpbxdetails4",
      Label: "Wire File ID:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWireFileID_PROPERTIES

      // END_USER_CODE-USER_lblWireFileID_PROPERTIES
    },
    lblWireFileIDValue: {
      name: "lblWireFileIDValue",
      type: "LabelWidget",
      parent: "grpbxdetails4",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWireFileIDValue_PROPERTIES

      // END_USER_CODE-USER_lblWireFileIDValue_PROPERTIES
    },
    lblWirePaymentID: {
      name: "lblWirePaymentID",
      type: "LabelWidget",
      parent: "grpbxdetails4",
      Label: "Wire Payment ID:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWirePaymentID_PROPERTIES

      // END_USER_CODE-USER_lblWirePaymentID_PROPERTIES
    },
    lblWirePaymentIDValue: {
      name: "lblWirePaymentIDValue",
      type: "LabelWidget",
      parent: "grpbxdetails4",
      // Label: ".",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWirePaymentIDValue_PROPERTIES

      // END_USER_CODE-USER_lblWirePaymentIDValue_PROPERTIES
    },
    txtarRemarks: {
      name: "txtarRemarks",
      type: "TextAreaWidget",
      parent: "grpbxPaymentActivityRemarks",
      Label: "Remarks:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarRemarks_PROPERTIES

      // END_USER_CODE-USER_txtarRemarks_PROPERTIES
    },
    txtcolBuyPt: {
      name: "txtcolBuyPt",
      type: "TextBoxWidget",
      colName: "colBuyPt",
      parent: "gridPaymentActivity",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyPt_PROPERTIES
    },
    txtcolNumber1: {
      name: "txtcolNumber1",
      type: "TextBoxWidget",
      colName: "colNumber1",
      parent: "gridPaymentActivity",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNumber1_PROPERTIES

      // END_USER_CODE-USER_txtcolNumber1_PROPERTIES
    },
    txtcolNumber2: {
      name: "txtcolNumber2",
      type: "TextBoxWidget",
      colName: "colNumber2",
      parent: "gridPaymentActivity",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNumber2_PROPERTIES

      // END_USER_CODE-USER_txtcolNumber2_PROPERTIES
    },
    txtcolOpt: {
      name: "txtcolOpt",
      type: "TextBoxWidget",
      colName: "colOpt",
      parent: "gridPaymentActivity",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpt_PROPERTIES

      // END_USER_CODE-USER_txtcolOpt_PROPERTIES
    },
    txtcolProceeds: {
      name: "txtcolProceeds",
      type: "TextBoxWidget",
      colName: "colProceeds",
      parent: "gridPaymentActivity",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProceeds_PROPERTIES

      // END_USER_CODE-USER_txtcolProceeds_PROPERTIES
    },
    txtcolType1: {
      name: "txtcolType1",
      type: "TextBoxWidget",
      colName: "colType1",
      parent: "gridPaymentActivity",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType1_PROPERTIES

      // END_USER_CODE-USER_txtcolType1_PROPERTIES
    },
    txtcolType2: {
      name: "txtcolType2",
      type: "TextBoxWidget",
      colName: "colType2",
      parent: "gridPaymentActivity",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType2_PROPERTIES

      // END_USER_CODE-USER_txtcolType2_PROPERTIES
    },
    grpbxCheckDetails: {
      name: "grpbxCheckDetails",
      type: "GroupBoxWidget",
      parent: "ReviewCheck",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxCheckDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxCheckDetails_PROPERTIES
    },
    grpbxPaymentActivityRemarks: {
      name: "grpbxPaymentActivityRemarks",
      type: "GroupBoxWidget",
      parent: "ReviewCheck",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPaymentActivityRemarks_PROPERTIES

      // END_USER_CODE-USER_grpbxPaymentActivityRemarks_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "ReviewCheck",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "AccountPayment#CheckInquiry": {},
        // "AccountPayment#ReportPreviewCheck": {},
        // "AccountPayment#ReviewCheckAuditDetail": {},
        // "AccountPayment#CorrectCheck": {},
      },
      REVERSE: {
        "AccountPayment#CheckInquiry": {},
        // "AccountPayment#ReportPreviewCheck": {},
        // "AccountPayment#ReviewCheckAuditDetail": {},
        // "AccountPayment#CorrectCheck": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnCorrectCheck:{
    //   DEFAULT: [
    //     "AccountPayment#CorrectCheck#DEFAULT#true#Click",
    //   ],
    // },
    // btnPrintStub: {
    //   DEFAULT: [
    //     "AccountPayment#ReportPreviewCheck#DEFAULT#true#Click",
    //   ],
    // },
    // btnAuditHistory: {
    //   DEFAULT: [
    //     "AccountPayment#ReviewCheckAuditDetail#DEFAULT#true#Click",
    //   ],
    // },
    btnClose: {
      DEFAULT: [
        "AccountPayment#CheckInquiry#DEFAULT#false#Click",
      ],
    },
  };
  let _winServices = {};
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    ReadCheckInfo()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  //Implimented the Correct check Integration
  useEffect(() => {
    setValue(thisObj, "lblCheckValue", getData(thisObj, "dataForReviewCheck"))
  }, [getData(thisObj, "dataForReviewCheck")])

  let lblStatusValue = ''
  let lblWellsFargoConfirmValue = ''
  let lblFedReserveConfirmValue = ''
  let lblFaPrompt = ''
  let lblWireXferData = ''
  let receivableNumber = ''
  let buyingPointlabelId

  let checkInquiryData = getData(thisObj, "frmreviewcheckReadCheckInfo")
  let check_num = checkInquiryData.txtcolCheck
  let buying_Point = checkInquiryData.LstrBuyPtID
  let payee_vendor = checkInquiryData.LstrPayeeVendor

  // START_USER_CODE-USER_METHODS

  function toUpper(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.substr(1);
      })
      .join(' ');
  }

  async function ReadCheckInfo() {
    setLoading(true)
    const numberFormat = (value) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value);
    let batch_number = null
    let ldblcheckproceeds = 0
    // hide(thisObj, 'btnResendWireConfirmation')
    document.getElementsByClassName("btnResendWireConfirmation")[0].style.visibility = "hidden";

    // PAYMENT_ACTIVITY_LIST9 API Integration
    let lstrxml = await AccountPayment.RetrievePaymentActivityListDetails(buying_Point, check_num, batch_number, payee_vendor)
    if (lstrxml.length !== 0) {

      // BATCH_CHECK_GET API Integration
      let LstrXMLOrigCheck = await AccountPayment.RetrieveBatchCheckDetails(check_num)
      setValue(thisObj, "lblRemitToValue", "")
      setValue(thisObj, "lblRemitToValue2", "")
      setValue(thisObj, "lblRemitToValue3", "")
      setValue(thisObj, "lblRemitToValue4", "")
      setValue(thisObj, "txtLienHolder", "")
      if (lstrxml[0].remarks === null) {
        setValue(thisObj, "txtarRemarks", "")
      }
      else {
        setValue(thisObj, "txtarRemarks", lstrxml[0].remarks)
      }
      let localtionName
      let payeeRemit
      let payeeRemitNameAddress1
      let payeeRemitNameAddress2
      let payeeRemitCity 
      let vendorPayee
      if (LstrXMLOrigCheck.length != 0 ) {
        //Due to some data Exception the need to add 3 conditions.
        let LstrXMLOrigCheckse = LstrXMLOrigCheck.check.header.payee_remits
        let payremits = LstrXMLOrigCheck.check.header.payee_remit_name_address1
        let LstrXMLOrigChecks = LstrXMLOrigCheck.check.header
        if(payremits != undefined){
          localtionName = LstrXMLOrigChecks.buy_pt_id + '-' + LstrXMLOrigChecks.buy_pt_name
           vendorPayee = LstrXMLOrigChecks.payee_vendor + '-' + toUpper(LstrXMLOrigChecks.vendor_name)
           payeeRemit = LstrXMLOrigChecks.payee_remit[0] + '-' + toUpper(LstrXMLOrigChecks.payee_remit_name)
           payeeRemitNameAddress1 = toUpper(LstrXMLOrigChecks.payee_remit_name_address1)
           payeeRemitNameAddress2 = toUpper(LstrXMLOrigChecks.payee_remit_name_address2)
           payeeRemitCity = toUpper(LstrXMLOrigChecks.payee_remit_city) + ", " + LstrXMLOrigChecks.payee_remit_state + " " + (LstrXMLOrigChecks.payee_remit_zipcode).slice(0, 5)
        }
        else if(LstrXMLOrigCheckse === undefined){
           localtionName = LstrXMLOrigChecks.buy_pt_id + '-' + LstrXMLOrigChecks.buy_pt_name
           vendorPayee = LstrXMLOrigChecks.payee_vendor + '-' + toUpper(LstrXMLOrigChecks.vendor_name)
           payeeRemit = LstrXMLOrigChecks.payee_remit + '-' //+ toUpper(LstrXMLOrigChecks.payee_remit_name)
           payeeRemitNameAddress1 = "" //toUpper(LstrXMLOrigChecks.payee_remit_name_address1)
           payeeRemitNameAddress2 = //toUpper(LstrXMLOrigChecks.payee_remit_name_address2)
           payeeRemitCity = ""//toUpper(LstrXMLOrigChecks.payee_remit_city) + ", " + LstrXMLOrigChecks.payee_remit_state + " " + (LstrXMLOrigChecks.payee_remit_zipcode).slice(0, 5)
        }
        else{
           localtionName = LstrXMLOrigChecks.buy_pt_id + '-' + LstrXMLOrigChecks.buy_pt_name
           vendorPayee = LstrXMLOrigChecks.payee_vendor + '-' + toUpper(LstrXMLOrigChecks.vendor_name)
           payeeRemit = LstrXMLOrigChecks.payee_remit + '-' + toUpper(LstrXMLOrigCheckse.payee_remit_name)
           payeeRemitNameAddress1 = toUpper(LstrXMLOrigCheckse.payee_remit_name_address1)
           payeeRemitNameAddress2 = toUpper(LstrXMLOrigCheckse.payee_remit_name_address2)
           payeeRemitCity = toUpper(LstrXMLOrigCheckse.payee_remit_city) + ", " + LstrXMLOrigCheckse.payee_remit_state + " " + (LstrXMLOrigCheckse.payee_remit_zipcode).slice(0, 5)
        }
        setData(thisObj,"buyingPointlabelId",LstrXMLOrigChecks.buy_pt_id)
        setValue(thisObj, "lblLocationValue", localtionName)
        if (LstrXMLOrigCheck.check.header.payee_vendor === "") {
          setValue(thisObj, "lblVendorValue", "No Payee")
        }
        else {
          setValue(thisObj, "lblVendorValue", vendorPayee)
          setValue(thisObj, "lblRemitToValue", payeeRemit)
          setValue(thisObj, "lblRemitToValue2", payeeRemitNameAddress1)
          setValue(thisObj, "lblRemitToValue3", payeeRemitNameAddress2)
          if (thisObj.values.lblRemitToValue3 == "") {
            setValue(thisObj, "lblRemitToValue3", payeeRemitCity)
          }
          else {
            setValue(thisObj, "lblRemitToValue4", payeeRemitCity)
          }
        }
        if(LstrXMLOrigChecks.lienholders != undefined){
          setValue(thisObj, "txtLienHolder", LstrXMLOrigChecks.lienholders)
        }
        else{
          setValue(thisObj, "txtLienHolder", "")
        }
        
        setData(thisObj, "labelVendorNumber", LstrXMLOrigChecks.payee_vendor)
      }
      else {
        let LocaltionName = buying_Point + '-' + lstrxml[0].buy_pt_name
        setData(thisObj,"buyingPointlabelId",buying_Point)
        setValue(thisObj, "lblLocationValue", LocaltionName)
        if (lstrxml[0].vendor.length === 0) {
          setValue(thisObj, "lblVendorValue", "No Payee")
        }
        else {
          setValue(thisObj, "lblVendorValue", lstrxml[0].vendor[0].payee_vendor + "-" + toUpper(lstrxml[0].vendor[0].vendor_name))
          setValue(thisObj, "lblRemitToValue", lstrxml[0].vendor[0].payee_remit + "-" + toUpper(lstrxml[0].vendor[0].payee_name))
          setValue(thisObj, "lblRemitToValue2", toUpper(lstrxml[0].vendor[0].payee_address1))
          setValue(thisObj, "lblRemitToValue3", toUpper(lstrxml[0].vendor[0].payee_address2))
          if (thisObj.values.lblRemitToValue3 == "") {
            setValue(thisObj, "lblRemitToValue3", (lstrxml[0].vendor[0].payee_city) + ", " + lstrxml[0].vendor[0].payee_state + " " + (lstrxml[0].vendor[0].payee_zipcode).slice(0, 5))
          }
          else {
            setValue(thisObj, "lblRemitToValue4", toUpper(lstrxml[0].vendor[0].payee_city) + ", " + lstrxml[0].vendor[0].payee_state + " " + (lstrxml[0].vendor[0].payee_zipcode).slice(0, 5))
          }
        setData(thisObj, "labelVendorNumber", lstrxml[0].vendor[0].payee_vendor)
        }
        let LstrLienHolders = lstrxml[0].lienholders
        for (var i = 0; i < LstrLienHolders.length; i++) {
          if (LstrLienHolders !== 0) {
            setValue(thisObj, "txtLienHolder", LstrLienHolders[i].lienholder_name)
          }
        }
      }

      lblFaPrompt = "NO"
      let js = [];
      let obj = {}
      let array = {}
      let paymentActivity = lstrxml[0].payment_activity
      for (var i = 0; i < paymentActivity.length; i++) {
        // obj.transactionNum = paymentActivity[i].transaction_num;
        obj.txtcolBuyPt = paymentActivity[i].buy_pt_id;
        obj.txtcolType1 = paymentActivity[i].pay_type_1;
        obj.txtcolNumber1 = paymentActivity[i].pay_num_1;
        obj.txtcolType2 = paymentActivity[i].pay_type_2;
        obj.txtcolNumber2 = paymentActivity[i].pay_num_2;
        if ((paymentActivity[i].check_proceeds).slice(0, 1) === "-") {
          var ldblcheckproceed = (paymentActivity[i].check_proceeds).slice(1, (paymentActivity[i].check_proceeds).length)
          obj.txtcolProceeds = "(" + numberFormat(ldblcheckproceed) + ")"
        }
        else {
          obj.txtcolProceeds = numberFormat(paymentActivity[i].check_proceeds)
        }
        obj.txtcolOpt = paymentActivity[i].option_pay;
        js.push(obj)
        obj = {}

        array.check_num = check_num
        array.check_status = paymentActivity[i].check_status

        setData(thisObj, "lblCheckValue", check_num)
        setData(thisObj, "LstrStatus", paymentActivity[i].check_status)

        array.issued_date = paymentActivity[i].issued_date

        setData(thisObj, "LstrIssuedDate", paymentActivity[i].issued_date)
        array.issued_user = paymentActivity[i].issued_user
        array.audit_user = paymentActivity[i].audit_user

        array.recon_date = paymentActivity[i].recon_date
        array.clear_date = paymentActivity[i].clear_date
        array.void_date = paymentActivity[i].void_date
        array.void_user = paymentActivity[i].void_user

        array.intr_agree_num = paymentActivity[i].intr_agree_num
        array.special_type = paymentActivity[i].special_type
        array.wire_xfer_data = paymentActivity[i].wire_xfer_data
        array.wire_file_id = paymentActivity[i].wire_file_id
        array.wire_pay_id = paymentActivity[i].wire_pay_id
        array.wire_ack_ind = paymentActivity[i].wire_ack_ind
        array.wire_ack_error = paymentActivity[i].wire_ack_error
        array.wire_confirm_wf_num = paymentActivity[i].wire_confirm_wf_num
        array.wire_confirm_fed_num = paymentActivity[i].wire_confirm_fed_num

        //setValue(thisObj, "lclcheckNum",paymentActivity[i].check_num) //Missing 2 boxes 
        let checkNum = paymentActivity[i].check_num
        setData(thisObj, "checkNum", checkNum)//DATA PASSING TO ReviewCheckAuditDetails
        //setValue(thisObj, "lclRecevablenum",paymentActivity[i].receivable_num)//Missing 2 boxes
        let receivable_num = paymentActivity[i].receivable_num
        setData(thisObj, "receivable_num", receivable_num)//DATA PASSING TO ReviewCheckAuditDetails

        ldblcheckproceeds = ldblcheckproceeds + Number(paymentActivity[i].check_proceeds)

        if (paymentActivity[i].void_chk_fa_prompt === 'Y') {
          lblFaPrompt = 'YES'
          setData(thisObj, "lblFaPrompt", lblFaPrompt)
        }
      }
      setData(thisObj, "ldblcheckproceeds", ldblcheckproceeds)

      setValue(thisObj, "gridPaymentActivity", js)

      setValue(thisObj, "lblCheckValue", array.check_num)
      setValue(thisObj, "lblIssueDateValue", array.issued_date)

      switch (array.check_status) {
        case "I":
          setValue(thisObj, "lblStatusValue", "Issued")
          lblStatusValue = "Issued"
          break;
        case "R":
          setValue(thisObj, "lblStatusValue", "Sent To Recon")
          lblStatusValue = "Sent To Recon"
          break;
        case "C":
          setValue(thisObj, "lblStatusValue", "Cleared")
          lblStatusValue = "Cleared"
          break;
        case "V":
          setValue(thisObj, "lblStatusValue", "Void")
          lblStatusValue = "Void"
          break;
        case "S":
          if (array.wire_xfer_data === '') {
            setValue(thisObj, "lblStatusValue", "System Void")
            lblStatusValue = "System Void"
          }
          else {
            setValue(thisObj, "lblStatusValue", "Wire Transfer")
            lblStatusValue = "Wire Transfer"
          }
          break;
      }

      if (array.issued_user != null) {
        setValue(thisObj, "lblIssueUserValue", array.issued_user)
      }
      else {
        setValue(thisObj, "lblIssueUserValue", array.audit_user)
      }

      setValue(thisObj, "lbllblReconDateValue", array.recon_date)
      setValue(thisObj, "lblClearDateValue", array.clear_date)
      setValue(thisObj, "lblVoidDateValue", array.void_date)
      setValue(thisObj, "lblVoidUserValue", array.void_user)

      //some labels hidden condition
      hide(thisObj, "lblInterestAgree")
      setValue(thisObj, "lblInterestAgreeValue", "")
      hide(thisObj, "lblSpecialType")
      setValue(thisObj, "lblSpecialTypeValue", "")
      hide(thisObj, "lblWireData")
      setValue(thisObj, "lblWireDataValue", "")
      lblWireXferData = ""
      hide(thisObj, "lblWireFileID")
      setValue(thisObj, "lblWireFileIDValue", "")
      hide(thisObj, "lblWirePaymentID")
      setValue(thisObj, "lblWirePaymentIDValue", "")
      hide(thisObj, "lblAcknowledgement")
      setValue(thisObj, "lblAcknowledgementValue", "")
      hide(thisObj, "lblWireErrorMsg")
      hide(thisObj, "lblWireErrorMsgValue")
      setValue(thisObj, "lblWireErrorMsg", "")
      hide(thisObj, "lblWellsFargoRef")
      setValue(thisObj, "lblWellsFargoRefValue", "")
      lblWellsFargoConfirmValue = ""
      hide(thisObj, "lblFederalReserveRef")
      setValue(thisObj, "lblFederalReserveRefValue", "")
      lblFedReserveConfirmValue = ""


      //Some Labels Visible Condition
      if (array.intr_agree_num != '') {
        show(thisObj, "lblInterestAgree")
        setValue(thisObj, "lblInterestAgreeValue", array.intr_agree_num)
      }
      if (array.special_type === 'INT') {
        show(thisObj, "lblSpecialType")
        setValue(thisObj, "lblSpecialTypeValue", array.special_type)
      }
      if (array.wire_xfer_data != '') {
        show(thisObj, "lblWireData")
        setValue(thisObj, "lblWireDataValue", array.wire_xfer_data)
        lblWireXferData = array.wire_xfer_data
      }
      if (array.wire_file_id != '') {
        show(thisObj, "lblWireFileID")
        setValue(thisObj, "lblWireFileIDValue", array.wire_file_id)
      }
      if (array.wire_pay_id != '') {
        show(thisObj, "lblWirePaymentID")
        setValue(thisObj, "lblWirePaymentIDValue", array.wire_pay_id)
      }
      if (array.wire_ack_ind != '') {
        show(thisObj, "lblAcknowledgement")
        setValue(thisObj, "lblAcknowledgementValue", array.wire_ack_ind)
      }
      if (array.wire_ack_error != '') {
        show(thisObj, "lblWireErrorMsg")
        setValue(thisObj, "lblWireErrorMsgValue", array.wire_ack_error)
      }
      if (array.wire_confirm_wf_num != '') {
        show(thisObj, "lblWellsFargoRef")
        setValue(thisObj, "lblWellsFargoRefValue", array.wire_confirm_wf_num)
        lblWellsFargoConfirmValue = array.wire_confirm_wf_num
      }
      if (array.wire_confirm_fed_num != '') {
        show(thisObj, "lblFederalReserveRef")
        setValue(thisObj, "lblFederalReserveRefValue", array.wire_confirm_fed_num)
        lblFedReserveConfirmValue = array.wire_confirm_fed_num
      }

      if (array.check_status != 'I' && array.check_status != 'R' && array.check_status != 'S') {
        disable(thisObj, "btnVoid")
      }

    }
    else {
      showMessage(thisObj, "An error has occured while reading check information from the system!!!", false)
    }
    var ldblcheckproceed = ldblcheckproceeds
    var ldblcheckproceeds1 = ldblcheckproceed.toString()

    if ((ldblcheckproceeds1).slice(0, 1) === "-") {
      var ldblcheckproceeds2 = (ldblcheckproceeds1).slice(1, (ldblcheckproceeds1).length)
      setValue(thisObj, "lblProceedsValue", "(" + numberFormat(ldblcheckproceeds2) + ")")
    }
    else {
      ldblcheckproceeds1 = ldblcheckproceeds
      setValue(thisObj, "lblProceedsValue", numberFormat(ldblcheckproceeds))
    }

    let resp = await ContractManagementService.RetrieveAccessPermissionDetails('PN1100', '002', 'U', buying_Point)

    if (resp[0].permission == 'N') {
      disable(thisObj, "btnVoid")
    }
    else {
      enable(thisObj, "btnVoid")
    }

    let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN1100', '003', 'U', buying_Point)

    if (response[0].permission == 'N') {
      disable(thisObj, "btnCorrectCheck")
    }
    else {
      enable(thisObj, "btnCorrectCheck")
    }

    let LstrStatus = getData(thisObj, "LstrStatus")
    let lblCheckValue = getData(thisObj, "lblCheckValue")
    let lblStatus = lblStatusValue
    let lblWellsFargoConfirm = lblWellsFargoConfirmValue
    let lblFedReserveConfirm = lblFedReserveConfirmValue
    let LstrIssuedDate = getData(thisObj, "LstrIssuedDate")

    if (LstrStatus != 'I' && LstrStatus != 'R' && LstrStatus != 'S') {
      disable(thisObj, "btnVoid")
    }
    if (LstrStatus != 'I' && LstrStatus != 'R' && LstrStatus != 'S' && LstrStatus != 'V') {
      disable(thisObj, "btnCorrectCheck")
    }
    if (LstrStatus === 'S') {
      disable(thisObj, "btnCorrectCheck")
    }
    if (isNaN(lblCheckValue)) {
      disable(thisObj, "btnCorrectCheck")
    }

    if (lblStatus === "Wire Transfer" && lblWellsFargoConfirm != '' && lblFedReserveConfirm != '') {
      let response1 = await ContractManagementService.RetrieveAccessPermissionDetails('PN1100', '006', 'U', buying_Point)
      if (response1[0].permission == 'Y') {
        // show(thisObj, "btnResendWireConfirmation")
        document.getElementsByClassName("btnResendWireConfirmation")[0].style.visibility = "visible";
      }
    }
    setData(thisObj, "lblVoidDate.Tag", LstrIssuedDate)
    setLoading(false)
  }

  async function GetLocation() {
    let strLocation = ''
    let sUBuyPtId = ''
    let booLocationMatch = ''
    let sFA_VoidNewLoc = ''

    let mLoc = await prompt("Enter a Location ID.");
    strLocation = await mLoc.toUpperCase();
    if (strLocation === "") {
      return;
    }
    else {

      let LstrList = await ContractManagementService.RetrieveUserControlDetails(userid)
      if (LstrList.length != 0) {
        let js = [];
        for (var i = 0; i < LstrList.length; i++) {
          js.push(LstrList[i].buyPtId);
        }
        sUBuyPtId = js
      }

      LstrList = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1100', null, null, null, sUBuyPtId)
      if (LstrList.length != 0) {
        let js1 = [];
        for (var i = 0; i < LstrList.length; i++) {
          js1.push(LstrList[i].buy_pt_id)
        }
        sUBuyPtId = js1
      }
      booLocationMatch = false

      LstrList = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1100', null, null, null, null)
      if (LstrList.length != 0) {
        let js2 = [];
        for (var i = 0; i < LstrList.length; i++) {
          let obj = { key: LstrList[i].buy_pt_id }
          js2.push(obj)
          if (LstrList[i].buy_pt_id == strLocation) {
            booLocationMatch = true
          }
          LstrList = LstrList
        }
      }
      promptMessage(booLocationMatch, mLoc)
      sFA_VoidNewLoc = strLocation
      setData(thisObj, "sFA_VoidNewLoc", sFA_VoidNewLoc)
    }
  }

  function promptMessage(booLocationMatch, mLoc) {
    if (booLocationMatch === false) {
      alert(mLoc + " is not a valid location. Please enter another Location ID.")
      GetLocation();
    }
  }

  const onbtnVoidClick = async () => {
    try {
      let TNUM = ''
      let sFA_VoidType = ''
      let lblFaPrompt1 = getData(thisObj, "lblFaPrompt")
      const response = confirm("Are You Sure You want To Void The Selected Check?");
      if (response) {
        setLoading(true)
        if (lblFaPrompt1 === "YES") {
          const response = confirm("Are you voiding because you need to change the spool location?");
          if (response) {
            sFA_VoidType = '2'
            await GetLocation();
          }
          else {
            var resp = confirm("Are you voiding only because you need to reprint?")
            if (resp) {
              sFA_VoidType = "2"
            }
            else {
              sFA_VoidType = "1"
            }
          }
        }
        else {
          sFA_VoidType = "0"
        }

        //VOID_CHECK Update Api Integration
        let CHECKDT = new Date(new Date(getData(thisObj, "lblVoidDate.Tag")).getTime() - (new Date(getData(thisObj, "lblVoidDate.Tag")).getTimezoneOffset() * 60000)).toISOString().slice(0, 23).replace('T', ' ')
        let wirexferdata = lblWireXferData
        let fa_void_type = sFA_VoidType
        let sFA_VoidNewLoc = getData(thisObj, "sFA_VoidNewLoc")
        let fa_void_new_loc
        if (sFA_VoidNewLoc != undefined && sFA_VoidNewLoc != null) {
          fa_void_new_loc = sFA_VoidNewLoc
        }
        else {
          fa_void_new_loc = ""
        }

        let dataObj = {
          fa_void_new_loc: fa_void_new_loc,
          wirexferdata: wirexferdata
        }
        if (isNaN(check_num)) {
          let cnum = 0
          let rnum = check_num.slice(-5)
          let result = await AccountPayment.UpdateVoidCheckDetails(buying_Point, CHECKDT, cnum, fa_void_type, rnum, dataObj)
          if (result.status === 200) {
            setValue(thisObj, "lblStatusValue", "Void")
            lblStatusValue = "Void"
            disable(thisObj, "btnVoid")
            alert("Check# " + check_num + " has been successfully voided!!!")
          }
          else {
            showMessage(thisObj, "An error occured while voiding selected checks!!!", false)
          }
          disable(thisObj, "btnCorrectCheck")
        }
        else {
          var cnum = check_num
          var rnum = 0
          var result = await AccountPayment.UpdateVoidCheckDetails(buying_Point, CHECKDT, cnum, fa_void_type, rnum, dataObj)

          if (result.status === 200) {
            setValue(thisObj, "lblStatusValue", "Void")
            lblStatusValue = "Void"
            disable(thisObj, "btnVoid")
            alert("Check# " + check_num + " has been successfully voided!!!")
          }
          else {
            showMessage(thisObj, "An error occured while voiding selected checks!!!", false)
          }
          disable(thisObj, "btnCorrectCheck")
        }
        setLoading(false)
      }
      else {
        setLoading(false)
        return;
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on onbtnVoidClick"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnVoidClick = onbtnVoidClick;

  const onbtnAcctDistClick = async () => {
    try {
      buyingPointlabelId = getData(thisObj,"buyingPointlabelId")
      if (isNaN(check_num)) {
        commonContext.setReviewCheck_BuyingPointid(buyingPointlabelId)
        commonContext.setReviewCheck_sReceivableNum(check_num.slice(-5))
        commonContext.setReviewCheck_sCheckNum('0')
        setData(thisObj, 'type', "CHECK")
        goTo(thisObj, "ContractManagement#AccountDistributionActivity#DEFAULT#true#Click")
      }
      else {
        commonContext.setReviewCheck_BuyingPointid(buyingPointlabelId)
        commonContext.setReviewCheck_sReceivableNum('0')
        commonContext.setReviewCheck_sCheckNum(check_num)
        setData(thisObj, 'type', "CHECK")
        goTo(thisObj, "ContractManagement#AccountDistributionActivity#DEFAULT#true#Click")
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on onbtnAcctDistClick Button"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnAcctDistClick = onbtnAcctDistClick;

  //screen Data Passing to ReviewCheckAuditDetail screen
  const onbtnAuditHistoryClick = async () => {
    try {
      let receivableNum = getData(thisObj, "receivable_num")
      let checkNum = getData(thisObj, "checkNum")
      var ReviewCheckAuditDetailFormLoadDataObj = {}
      ReviewCheckAuditDetailFormLoadDataObj.check_num = checkNum
      ReviewCheckAuditDetailFormLoadDataObj.receivable_num = receivableNum

      setData(thisObj, "ReviewCheckAuditDetailFormLoadDataObj", ReviewCheckAuditDetailFormLoadDataObj);
      goTo(thisObj, 'AccountPayment#ReviewCheckAuditDetail#DEFAULT#true#Click');

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on onbtnAuditHistoryClick Button"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnAuditHistoryClick = onbtnAuditHistoryClick;

  //screen Data Passing to CorrectCheck screen
  const onbtnCorrectCheckClick = async () => {
    try {
      setData(thisObj, "frmCorrectCheck", check_num)
      goTo(thisObj, 'AccountPayment#CorrectCheck#DEFAULT#true#Click');
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on onbtnCorrectCheckClick Button"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCorrectCheckClick = onbtnCorrectCheckClick;

  //screen Data Passing to ReviewDeductionTracking screen
  const onbtnDeductuionTrackingClick = async () => {
    try {
      buyingPointlabelId = getData(thisObj,"buyingPointlabelId")
      if (isNaN(check_num)) {
        let ReviewDeductionTrackingFormLoadDataObj = {}
        ReviewDeductionTrackingFormLoadDataObj.buy_point_id = buyingPointlabelId
        ReviewDeductionTrackingFormLoadDataObj.source_type = 'CHECK'
        ReviewDeductionTrackingFormLoadDataObj.id_number_1 = "0"
        ReviewDeductionTrackingFormLoadDataObj.id_number_2 = check_num.slice(-5)

        setData(thisObj, "ReviewDeductionTrackingFormLoadData", ReviewDeductionTrackingFormLoadDataObj);
        goTo(thisObj, "Settlements#ReviewDeductionTracking#DEFAULT#true#Click")
      }
      else {
        var ReviewDeductionTrackingFormLoadDataObj = {}
        ReviewDeductionTrackingFormLoadDataObj.buy_point_id = buyingPointlabelId
        ReviewDeductionTrackingFormLoadDataObj.source_type = 'CHECK'
        ReviewDeductionTrackingFormLoadDataObj.id_number_1 = "0"
        ReviewDeductionTrackingFormLoadDataObj.id_number_2 = check_num

        setData(thisObj, "ReviewDeductionTrackingFormLoadData", ReviewDeductionTrackingFormLoadDataObj);
        goTo(thisObj, "Settlements#ReviewDeductionTracking#DEFAULT#true#Click")
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on onbtnDeductuionTrackingClick Button"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnDeductuionTrackingClick = onbtnDeductuionTrackingClick;

  //screen Data Passing to ReportPreviewCheck screen 
  const onbtnPrintStubClick = async () => {
    try {
      buyingPointlabelId = getData(thisObj,"buyingPointlabelId")
      var vendorNumber = getData(thisObj, "labelVendorNumber")
      var resp = await AccountPayment.RetrieveBatchCheckPrintDetails(buyingPointlabelId, check_num, vendorNumber)
      var LstrXMLOrigcheck = await AccountPayment.RetrieveBatchCheckDetails(check_num)
      resp = LstrXMLOrigcheck
      var Proceds = getData(thisObj, "ldblcheckproceeds")
      var reportPreviewCheckFormLoadDataArray = []
      var reportPreviewCheckFormLoadDataObj = {
        check_num: check_num,
        buying_Point: buyingPointlabelId,
        vendor: vendorNumber,
        Title: "REPRINT",
        Proceeds: Proceds,
        ScreenName: "ReviewCheck",
        IssuedDate:getValue(thisObj,"lblIssueDateValue"),
        LstrXMLOrigcheck :resp
      }
      reportPreviewCheckFormLoadDataArray.push(reportPreviewCheckFormLoadDataObj)      
      setData(thisObj, "ReportPreviewCheck", reportPreviewCheckFormLoadDataArray)
      goTo(thisObj, 'AccountPayment#ReportPreviewCheck#DEFAULT#true#Click');
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on onbtnPrintStubClick Button"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintStubClick = onbtnPrintStubClick;

  const onbtnResendWireConfirmationClick = async () => {
    try {
      const response = confirm("Are you sure you want resend the confirmation for this wire transfer?");
      if (response) {
        let job_id = "ResendWireConfirmation"
        let dataObj = {
          user_id: userid,
          comp_id: compId,
          crop_year: cropYear.toString(),
          parm_name_01: "",
          parm_value_01: "",
          parm_name_02: "",
          parm_value_02: "",
          parm_name_03: "WIRENUMBER",
          parm_value_03: check_num,
          parm_name_04: "RESEND_DATETIME",
          parm_value_04: "",
          parm_name_05: "",
          parm_value_05: "",
          parm_name_06: "",
          parm_value_06: "",
          parm_name_07: "",
          parm_value_07: "",
          parm_name_08: "",
          parm_value_08: "",
          parm_name_09: "",
          parm_value_09: "",
          parm_name_10: "",
          parm_value_10: "",
          parm_name_11: "",
          parm_value_11: "",
          parm_name_12: "",
          parm_value_12: "",
          parm_name_13: "",
          parm_value_13: "",
          parm_name_14: "",
          parm_value_14: "",
          parm_name_15: "",
          parm_value_15: "",
          parm_name_16: "",
          parm_value_16: "",
          parm_name_17: "",
          parm_value_17: "",
          parm_name_18: "",
          parm_value_18: "",
          parm_name_19: "",
          parm_value_19: "",
          parm_name_20: "",
          parm_value_20: ""
        }
        let response = await AccountPayment.CreateBatchJobRequestDetails(job_id, dataObj)
        if (response.status === 200) {
          alert("Confirmation will be resent for wire# " + check_num + ".")
        }
        else {
          alert("A pending request to resend confirmation for Wire Transfer number already exists.")
        }
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on onbtnResendWireConfirmationClick Button"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnResendWireConfirmationClick = onbtnResendWireConfirmationClick;

  const onbtnCloseClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      document.getElementById("AccountPayment_ReviewCheckPopUp").childNodes[0].click()
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ReviewCheck*/}
              <Loading loading={loading}></Loading>
              {/* END_USER_CODE-USER_BEFORE_ReviewCheck*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCheckDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCheckDetails*/}

              <GroupBoxWidget conf={state.grpbxCheckDetails} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails1*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails1*/}

                <GroupBoxWidget conf={state.grpbxDetails1} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblCheck*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCheck*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCheck}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCheck*/}

                  {/* END_USER_CODE-USER_AFTER_lblCheck*/}
                  {/* START_USER_CODE-USER_BEFORE_lblCheckValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCheckValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCheckValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCheckValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblCheckValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLocation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblLocation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLocation*/}

                  {/* END_USER_CODE-USER_AFTER_lblLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_lblLocationValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLocationValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblLocationValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLocationValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblLocationValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVendor*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVendor}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVendor*/}

                  {/* END_USER_CODE-USER_AFTER_lblVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_lblVendorValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVendorValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVendorValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVendorValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblVendorValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblLienHolder*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLienHolder*/}

                  {/* <LabelWidget
                    values={values}
                    conf={state.lblLienHolder}
                    screenConf={state}
                  ></LabelWidget> */}
                  {/* START_USER_CODE-USER_AFTER_lblLienHolder*/}

                  {/* END_USER_CODE-USER_AFTER_lblLienHolder*/}
                  {/* START_USER_CODE-USER_BEFORE_lblLienHolderValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLienHolderValue*/}

                  {/* <LabelWidget
                    values={values}
                    conf={state.lblLienHolderValue}
                    screenConf={state}
                  ></LabelWidget> */}
                  <TextAreaWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLienHolder}
                    screenConf={state}
                  ></TextAreaWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLienHolderValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblLienHolderValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblRemitTo*/}

                  {/* END_USER_CODE-USER_BEFORE_lblRemitTo*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblRemitTo}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblRemitTo*/}

                  {/* END_USER_CODE-USER_AFTER_lblRemitTo*/}
                  {/* START_USER_CODE-USER_BEFORE_lblRemitToValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblRemitToValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblRemitToValue}
                    screenConf={state}
                  ></LabelWidget>
                  <LabelWidget
                    values={values}
                    conf={state.lblRemitToValue2}
                    screenConf={state}
                  ></LabelWidget>
                  <LabelWidget
                    values={values}
                    conf={state.lblRemitToValue3}
                    screenConf={state}
                  ></LabelWidget>
                  <LabelWidget
                    values={values}
                    conf={state.lblRemitToValue4}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblRemitToValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblRemitToValue*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails1*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails1*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails2*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails2*/}

                <GroupBoxWidget conf={state.grpbxDetails2} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblProceeds*/}

                  {/* END_USER_CODE-USER_BEFORE_lblProceeds*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblProceeds}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblProceeds*/}

                  {/* END_USER_CODE-USER_AFTER_lblProceeds*/}
                  {/* START_USER_CODE-USER_BEFORE_lblProceedsValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblProceedsValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblProceedsValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblProceedsValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblProceedsValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblStatus*/}

                  {/* END_USER_CODE-USER_BEFORE_lblStatus*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblStatus}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblStatus*/}

                  {/* END_USER_CODE-USER_AFTER_lblStatus*/}
                  {/* START_USER_CODE-USER_BEFORE_lblStatusValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblStatusValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblStatusValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblStatusValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblStatusValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblInterestAgree*/}

                  {/* END_USER_CODE-USER_BEFORE_lblInterestAgree*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblInterestAgree}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblInterestAgree*/}

                  {/* END_USER_CODE-USER_AFTER_lblInterestAgree*/}
                  {/* START_USER_CODE-USER_BEFORE_lblInterestAgreeValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblInterestAgreeValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblInterestAgreeValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblInterestAgreeValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblInterestAgreeValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblSpecialType*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSpecialType*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSpecialType}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSpecialType*/}

                  {/* END_USER_CODE-USER_AFTER_lblSpecialType*/}
                  {/* START_USER_CODE-USER_BEFORE_lblSpecialTypeValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSpecialTypeValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSpecialTypeValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSpecialTypeValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblSpecialTypeValue*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails2*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails2*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxdetails4*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxdetails4*/}
                <GroupBoxWidget conf={state.grpbxDetails3} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblIssueDate*/}

                  {/* END_USER_CODE-USER_BEFORE_lblIssueDate*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblIssueDate}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblIssueDate*/}

                  {/* END_USER_CODE-USER_AFTER_lblIssueDate*/}
                  {/* START_USER_CODE-USER_BEFORE_lblIssueDateValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblIssueDateValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblIssueDateValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblIssueDateValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblIssueDateValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblIssueUser*/}

                  {/* END_USER_CODE-USER_BEFORE_lblIssueUser*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblIssueUser}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblIssueUser*/}

                  {/* END_USER_CODE-USER_AFTER_lblIssueUser*/}
                  {/* START_USER_CODE-USER_BEFORE_lblIssueUserValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblIssueUserValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblIssueUserValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblIssueUserValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblIssueUserValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblReconDate*/}

                  {/* END_USER_CODE-USER_BEFORE_lblReconDate*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblReconDate}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblReconDate*/}

                  {/* END_USER_CODE-USER_AFTER_lblReconDate*/}
                  {/* START_USER_CODE-USER_BEFORE_lbllblReconDateValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lbllblReconDateValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lbllblReconDateValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lbllblReconDateValue*/}

                  {/* END_USER_CODE-USER_AFTER_lbllblReconDateValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblClearDate*/}

                  {/* END_USER_CODE-USER_BEFORE_lblClearDate*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblClearDate}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblClearDate*/}

                  {/* END_USER_CODE-USER_AFTER_lblClearDate*/}
                  {/* START_USER_CODE-USER_BEFORE_lblClearDateValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblClearDateValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblClearDateValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblClearDateValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblClearDateValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblVoidDate*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVoidDate*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVoidDate}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVoidDate*/}

                  {/* END_USER_CODE-USER_AFTER_lblVoidDate*/}
                  {/* START_USER_CODE-USER_BEFORE_lblVoidDateValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVoidDateValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVoidDateValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVoidDateValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblVoidDateValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblVoidUser*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVoidUser*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVoidUser}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVoidUser*/}

                  {/* END_USER_CODE-USER_AFTER_lblVoidUser*/}
                  {/* START_USER_CODE-USER_BEFORE_lblVoidUserValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVoidUserValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVoidUserValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVoidUserValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblVoidUserValue*/}
                </GroupBoxWidget>
                <GroupBoxWidget conf={state.grpbxdetails4} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblWireData*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWireData*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWireData}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWireData*/}

                  {/* END_USER_CODE-USER_AFTER_lblWireData*/}
                  {/* START_USER_CODE-USER_BEFORE_lblWireDataValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWireDataValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWireDataValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWireDataValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblWireDataValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblWireFileID*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWireFileID*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWireFileID}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWireFileID*/}

                  {/* END_USER_CODE-USER_AFTER_lblWireFileID*/}
                  {/* START_USER_CODE-USER_BEFORE_lblWireFileIDValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWireFileIDValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWireFileIDValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWireFileIDValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblWireFileIDValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblWirePaymentID*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWirePaymentID*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWirePaymentID}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWirePaymentID*/}

                  {/* END_USER_CODE-USER_AFTER_lblWirePaymentID*/}
                  {/* START_USER_CODE-USER_BEFORE_lblWirePaymentIDValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWirePaymentIDValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWirePaymentIDValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWirePaymentIDValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblWirePaymentIDValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblAcknowledgement*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAcknowledgement*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAcknowledgement}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAcknowledgement*/}

                  {/* END_USER_CODE-USER_AFTER_lblAcknowledgement*/}
                  {/* START_USER_CODE-USER_BEFORE_lblAcknowledgementValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAcknowledgementValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAcknowledgementValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAcknowledgementValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblAcknowledgementValue*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAuditHistory*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAuditHistory*/}

                  <ButtonWidget
                    conf={state.btnAuditHistory}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAuditHistory*/}

                  {/* END_USER_CODE-USER_AFTER_btnAuditHistory*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxdetails4*/}

                {/* END_USER_CODE-USER_AFTER_grpbxdetails4*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails3*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails3*/}


                {/* START_USER_CODE-USER_AFTER_grpbxDetails3*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails3*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails5*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails5*/}

                <GroupBoxWidget conf={state.grpbxDetails5} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblWireErrorMsg*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWireErrorMsg*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWireErrorMsg}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWireErrorMsg*/}

                  {/* END_USER_CODE-USER_AFTER_lblWireErrorMsg*/}
                  {/* START_USER_CODE-USER_BEFORE_lblWireErrorMsgValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWireErrorMsgValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWireErrorMsgValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWireErrorMsgValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblWireErrorMsgValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblWellsFargoRef*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWellsFargoRef*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWellsFargoRef}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWellsFargoRef*/}

                  {/* END_USER_CODE-USER_AFTER_lblWellsFargoRef*/}
                  {/* START_USER_CODE-USER_BEFORE_lblWellsFargoRefValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWellsFargoRefValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWellsFargoRefValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWellsFargoRefValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblWellsFargoRefValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblFederalReserveRef*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFederalReserveRef*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFederalReserveRef}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFederalReserveRef*/}

                  {/* END_USER_CODE-USER_AFTER_lblFederalReserveRef*/}
                  {/* START_USER_CODE-USER_BEFORE_lblFederalReserveRefValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFederalReserveRefValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFederalReserveRefValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFederalReserveRefValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblFederalReserveRefValue*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails5*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails5*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCheckDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCheckDetails*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxPaymentActivityRemarks*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPaymentActivityRemarks*/}

              <GroupBoxWidget
                conf={state.grpbxPaymentActivityRemarks}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblPaymentActivity*/}

                {/* END_USER_CODE-USER_BEFORE_lblPaymentActivity*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPaymentActivity}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPaymentActivity*/}

                {/* END_USER_CODE-USER_AFTER_lblPaymentActivity*/}
                {/* START_USER_CODE-USER_BEFORE_gridPaymentActivity*/}

                {/* END_USER_CODE-USER_BEFORE_gridPaymentActivity*/}

                <GridWidget
                  conf={state.gridPaymentActivity}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPaymentActivity}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPaymentActivity*/}

                {/* END_USER_CODE-USER_AFTER_gridPaymentActivity*/}
                {/* START_USER_CODE-USER_BEFORE_txtarRemarks*/}

                {/* END_USER_CODE-USER_BEFORE_txtarRemarks*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarRemarks}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarRemarks*/}

                {/* END_USER_CODE-USER_AFTER_txtarRemarks*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPaymentActivityRemarks*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPaymentActivityRemarks*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnCorrectCheck*/}

                {/* END_USER_CODE-USER_BEFORE_btnCorrectCheck*/}

                <ButtonWidget
                  conf={state.btnCorrectCheck}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCorrectCheck*/}

                {/* END_USER_CODE-USER_AFTER_btnCorrectCheck*/}
                {/* START_USER_CODE-USER_BEFORE_btnVoid*/}

                {/* END_USER_CODE-USER_BEFORE_btnVoid*/}

                <ButtonWidget
                  conf={state.btnVoid}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVoid*/}

                {/* END_USER_CODE-USER_AFTER_btnVoid*/}
                {/* START_USER_CODE-USER_BEFORE_btnAcctDist*/}

                {/* END_USER_CODE-USER_BEFORE_btnAcctDist*/}

                <ButtonWidget
                  conf={state.btnAcctDist}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAcctDist*/}

                {/* END_USER_CODE-USER_AFTER_btnAcctDist*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeductuionTracking*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeductuionTracking*/}

                <ButtonWidget
                  conf={state.btnDeductuionTracking}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeductuionTracking*/}

                {/* END_USER_CODE-USER_AFTER_btnDeductuionTracking*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintStub*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintStub*/}

                <ButtonWidget
                  conf={state.btnPrintStub}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintStub*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintStub*/}
                {/* START_USER_CODE-USER_BEFORE_btnResendWireConfirmation*/}

                {/* END_USER_CODE-USER_BEFORE_btnResendWireConfirmation*/}

                <ButtonWidget
                  conf={state.btnResendWireConfirmation}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnResendWireConfirmation*/}

                {/* END_USER_CODE-USER_AFTER_btnResendWireConfirmation*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrinter*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrinter*/}

                {/* <ButtonWidget
                  conf={state.btnPrinter}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget> */}
                {/* START_USER_CODE-USER_AFTER_btnPrinter*/}

                {/* END_USER_CODE-USER_AFTER_btnPrinter*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_ReviewCheck*/}

              {/* END_USER_CODE-USER_AFTER_ReviewCheck*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(AccountPayment_ReviewCheck);
