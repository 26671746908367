/* eslint-disable*/
import React from "react";
import LinkWidget from "./LinkWidget";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup, screen } from "@testing-library/react";

describe("LinkWidget Test Cases-1", () => {
  beforeEach(async () => {
    act(() => {
      let linkwidget1 = {
        name: "linkwidget1",
        type: "LinkWidget",
        parent: "groupboxwidget",
        Label: "Link Widget",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let onCLick = jest.fn();
      renderTestScreen(
        <LinkWidget
          conf={linkwidget1}
          screenConf={stateScreenConf}
          onClick={onCLick}
        ></LinkWidget>
      );
    });
  });
  afterEach(cleanup);
  test("Is Link Widget Loaded Successfullt", async () => {
    const linkwidget1 = screen.getByTestId("linkwidget1");
    expect(false).toBe(false);
  });
});
describe("LinkWidget Test Cases-2", () => {
  beforeEach(async () => {
    act(() => {
      let linkwidget1 = {
        name: "linkwidget1",
        type: "LinkWidget",
        parent: "groupboxwidget",
        Label: "Link Widget",
        Mandatory: true
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let onCLick = jest.fn();
      renderTestScreen(
        <LinkWidget
          conf={linkwidget1}
          screenConf={stateScreenConf}
          onClick={onCLick}
        ></LinkWidget>
      );
    });
  });
  afterEach(cleanup);
  test("Is Link Widget Loaded Successfullt", async () => {
    const linkwidget1 = screen.getByTestId("linkwidget1");
    expect(false).toBe(false);
  });
});
