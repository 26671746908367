/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  setValue,
  getValue,
  hide,
  setData,
} from "../../shared/WindowImports";

import "./ExceptPremiumAdjustments.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../Service/ContractManagementService";
import CommonContext from "../../Store/CommonContext";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ExceptPremiumAdjustments(props) {
  const commonContext = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  // START_USER_CODE-USER_PROPERTIES
  
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ExceptPremiumAdjustments",
    windowName: "ExceptPremiumAdjustments",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ExceptPremiumAdjustments",
    // START_USER_CODE-USER_ExceptPremiumAdjustments_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Exception Premium Adjustments",
      scrCode: "PN1190D"
    },
    // END_USER_CODE-USER_ExceptPremiumAdjustments_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Delete",
      CharWidth: "15",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    ddRateBase: {
      name: "ddRateBase",
      type: "DropDownFieldWidget",
      parent: "grpbxRateRatebase",
      Label: "Rate Base:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRateBase_PROPERTIES

      // END_USER_CODE-USER_ddRateBase_PROPERTIES
    },
    grpbxLabels: {
      name: "grpbxLabels",
      type: "GroupBoxWidget",
      parent: "grpbxReqDetails",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLabels_PROPERTIES

      // END_USER_CODE-USER_grpbxLabels_PROPERTIES
    },
    txtLocation: {
      name: "txtLocation",
      type: "TextBoxWidget",
      parent: "grpbxReqDetails",
      Label: "Location: ",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocation_PROPERTIES

      // END_USER_CODE-USER_txtLocation_PROPERTIES
    },
    txtLocationID: {
      name: "txtLocationID",
      type: "TextBoxWidget",
      parent: "grpbxLabels",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocationID_PROPERTIES

      // END_USER_CODE-USER_txtLocationID_PROPERTIES
    },
    txtPayRequest: {
      name: "txtPayRequest",
      type: "TextBoxWidget",
      parent: "grpbxReqDetails",
      Label: "Pay Request #:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayRequest_PROPERTIES

      // END_USER_CODE-USER_txtPayRequest_PROPERTIES
    },
    txtPremiumCode: {
      name: "txtPremiumCode",
      type: "TextBoxWidget",
      parent: "grpbxReqDetails",
      Label: "Premium Code:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremiumCode_PROPERTIES

      // END_USER_CODE-USER_txtPremiumCode_PROPERTIES
    },
    txtPremiumCodeID: {
      name: "txtPremiumCodeID",
      type: "TextBoxWidget",
      parent: "grpbxLabels",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremiumCodeID_PROPERTIES

      // END_USER_CODE-USER_txtPremiumCodeID_PROPERTIES
    },
    txtRate: {
      name: "txtRate",
      type: "TextBoxWidget",
      parent: "grpbxRateRatebase",
      Label: "Rate:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRate_PROPERTIES

      // END_USER_CODE-USER_txtRate_PROPERTIES
    },
    txtRatebaseID: {
      name: "txtRatebaseID",
      type: "TextBoxWidget",
      parent: "grpbxRateRatebase",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRatebaseID_PROPERTIES

      // END_USER_CODE-USER_txtRatebaseID_PROPERTIES
    },
    txtStatus: {
      name: "txtStatus",
      type: "TextBoxWidget",
      parent: "grpbxReqDetails",
      Label: "Status:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatus_PROPERTIES

      // END_USER_CODE-USER_txtStatus_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxReqDetails",
      Label: "Vendor:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVendorID: {
      name: "txtVendorID",
      type: "TextBoxWidget",
      parent: "grpbxLabels",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorID_PROPERTIES

      // END_USER_CODE-USER_txtVendorID_PROPERTIES
    },
    grpbxReqDetails: {
      name: "grpbxReqDetails",
      type: "GroupBoxWidget",
      parent: "ExceptPremiumAdjustments",
      Height: "",
      Width: "",
      clonedExtId: "21423",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxReqDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxReqDetails_PROPERTIES
    },
    grpbxRateRatebase: {
      name: "grpbxRateRatebase",
      type: "GroupBoxWidget",
      parent: "ExceptPremiumAdjustments",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxRateRatebase_PROPERTIES

      // END_USER_CODE-USER_grpbxRateRatebase_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ExceptPremiumAdjustments",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#ExceptPremRequestProfile": {},
      },
      REVERSE: {
        "ContractManagement#ExceptPremRequestProfile": {},
      },
    },
  };
 

  let _buttonServices = {ExceptPremRequestProfile: {}};
  let _buttonNavigation = {
    btnUpdate: {
      DEFAULT: [
        "ContractManagement#ExceptPremRequestProfile#DEFAULT#true#Click",
      ],
    },
    btnDelete: {
      DEFAULT: [
        "ContractManagement#ExceptPremRequestProfile#DEFAULT#true#Click",
      ],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    FormLoad();
  }, []);
  
  function FormLoad() {
    let js = []
    js= commonContext.getDataForExceptPremiumAdjustment;
    let PayRequest=js[0].lblPayRequestNumber;
    let Status = js[0].lblStatus;
    let Location1 =  js[0].lblLocation;
    let Vendor =  js[0].lblVendor;
    //let PremiumCode =js[0].lblPremiumCodeID+'-'+ js[0].lblPremiumCode;
    let PremiumCode = js[0].lblPremiumCode;
    
    let transaction_all=[]
    let transactions=''
    let obj={}
    for(var i=0;i<js[0].UpdatedExcepPremList[0].length;i++){
    obj = js[0].UpdatedExcepPremList[0][i].transaction_num;
    transaction_all.push(obj)
    obj={}
    }
    for(var i=0;i<transaction_all.length;i++){
      transactions = transactions+','+ transaction_all[i]
    }
    transactions = transactions.substring(1)
    setValue(thisObj,"txtLocationID",transactions) // unnessesay txtbox but now using it to store trans-num
    
    setValue(thisObj, "txtPayRequest", PayRequest);  // Will come from another screen   
    setValue(thisObj, "txtStatus", Status); // Will come from another screen
    setValue(thisObj, "txtLocation", Location1); // Will come from another screen
    setValue(thisObj, "txtVendor", Vendor); // Will come from another screen
    setValue(thisObj, "txtPremiumCode", PremiumCode); // Will come from another screen

    hide(thisObj,"txtLocationID")
    hide(thisObj,"txtVendorID")
    hide(thisObj,"txtPremiumCodeID")
    hide(thisObj,"txtRatebaseID")
    GetRateBaseList();
  }
  

  // Method for geting RateBase dorpdown data
  const GetRateBaseList =() =>{
    let js =[];
    js.push({key: 'G', description: 'per Gross Ton'});
    js.push({key: 'N', description: 'per Net Ton'});
    js.push({key: 'K', description: 'per Net less LSK Ton'});
    js.push({key: 'B', description: 'per Net Ton Basis Grade'});
    js.push({key: 'L', description: 'per Load'});

    thisObj.setState(current =>{
      return{
        ...current,
        ddRateBase: {
          ...state["ddRateBase"],
          valueList: js
        }
      }
    });
  };

  // Update button implemention
  const onbtnUpdateClick = () => {
    try {
      setData(thisObj,"ExceptPremRequestProfile_reload",true);
      let txtratevalue = getValue(thisObj,"txtRate");
      if (txtratevalue == "" || isNaN(txtratevalue)) {
        showMessage(thisObj, "Rate is invalid!!!")
        return false;
      }
      if (Number(txtratevalue)) {
        if (txtratevalue < 0 || txtratevalue > 9999.99999) {
          showMessage(thisObj, "Rate Must be of format ####.#####!!!")
          return false;
        }
      }
      let txtRates = getValue(thisObj, "txtRate");
      let ddRateBase = getValue(thisObj, "ddRateBase");
      if (ddRateBase == null || ddRateBase == "" || ddRateBase == "undefined") {
        showMessage(thisObj, "No Rate Base has been selected!!")
        return false;
      }
      let useridFromLS = sessionStorage.getItem('userid')
      let transaction1 = getValue(thisObj,"txtLocationID")
      let data = {
        prem_rate: txtRates,
        prem_rate_base: ddRateBase,
        uid: useridFromLS,
        transaction_numbers : transaction1
      }
      
      
      // Integrtat API for update exception premium
      ContractManagementService.UpdateExceptionPremiumDetails(null, data) //transaction number is being sent throught data object
        .then(response => {
          showMessage(thisObj, response.message, true);
        })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "error on update button")
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick;

  // Delete API Contract_Except_Prem_Delete
  const onbtnDeleteClick = () => {
    try {
      setData(thisObj,"ExceptPremRequestProfile_reload",true);
      let trans_num = getValue(thisObj,"txtLocationID")
      if (trans_num == "") {
        return false;
      }
      else {
        const confirmBox = window.confirm(
          "Are you sure you want to delete these exception Premium?"
        )
        if (confirmBox == true) {
          ContractManagementService.RemoveExceptionPremium(trans_num)
            .then(response => {
              showMessage(thisObj, response.message, true);
            })
        }
        else {
          showMessage(thisObj, "Exception premium delete failed!", false)
          return false;
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "error on excel to export button")
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  // START_USER_CODE-USER_METHODS
  const onbtnCancelClick = () => {
    try {
      //document.getElementsByClassName("close")[0].click()
      document.getElementById("ContractManagement_ExceptPremiumAdjustmentsPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ExceptPremiumAdjustments*/}

              {/* END_USER_CODE-USER_BEFORE_ExceptPremiumAdjustments*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxReqDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxReqDetails*/}

              <GroupBoxWidget conf={state.grpbxReqDetails} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtPayRequest*/}

                {/* END_USER_CODE-USER_BEFORE_txtPayRequest*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPayRequest}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPayRequest*/}

                {/* END_USER_CODE-USER_AFTER_txtPayRequest*/}
                {/* START_USER_CODE-USER_BEFORE_txtStatus*/}

                {/* END_USER_CODE-USER_BEFORE_txtStatus*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStatus}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStatus*/}

                {/* END_USER_CODE-USER_AFTER_txtStatus*/}
                {/* START_USER_CODE-USER_BEFORE_txtLocation*/}

                {/* END_USER_CODE-USER_BEFORE_txtLocation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLocation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLocation*/}

                {/* END_USER_CODE-USER_AFTER_txtLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txtPremiumCode*/}

                {/* END_USER_CODE-USER_BEFORE_txtPremiumCode*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPremiumCode}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPremiumCode*/}

                {/* END_USER_CODE-USER_AFTER_txtPremiumCode*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxLabels*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxLabels*/}

                <GroupBoxWidget conf={state.grpbxLabels} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtLocationID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLocationID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLocationID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLocationID*/}

                  {/* END_USER_CODE-USER_AFTER_txtLocationID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendorID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendorID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendorID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendorID*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendorID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPremiumCodeID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPremiumCodeID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPremiumCodeID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPremiumCodeID*/}

                  {/* END_USER_CODE-USER_AFTER_txtPremiumCodeID*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxLabels*/}

                {/* END_USER_CODE-USER_AFTER_grpbxLabels*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxReqDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxReqDetails*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxRateRatebase*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxRateRatebase*/}

              <GroupBoxWidget conf={state.grpbxRateRatebase} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtRatebaseID*/}

                {/* END_USER_CODE-USER_BEFORE_txtRatebaseID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRatebaseID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRatebaseID*/}

                {/* END_USER_CODE-USER_AFTER_txtRatebaseID*/}
                {/* START_USER_CODE-USER_BEFORE_txtRate*/}

                {/* END_USER_CODE-USER_BEFORE_txtRate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRate*/}

                {/* END_USER_CODE-USER_AFTER_txtRate*/}
                {/* START_USER_CODE-USER_BEFORE_ddRateBase*/}

                {/* END_USER_CODE-USER_BEFORE_ddRateBase*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRateBase}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRateBase*/}

                {/* END_USER_CODE-USER_AFTER_ddRateBase*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxRateRatebase*/}

              {/* END_USER_CODE-USER_AFTER_grpbxRateRatebase*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}

              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ExceptPremiumAdjustments*/}

              {/* END_USER_CODE-USER_AFTER_ExceptPremiumAdjustments*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ExceptPremiumAdjustments);