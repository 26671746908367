/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  DropDownWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  getValue,
  setValue,
  goTo,
  getData,
  setData,
  enable,
  disable,
} from "../../shared/WindowImports";

import "./InterestAgreementSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices"
import Loading from "../../../Loader/Loading";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_InterestAgreementSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const compIdFromLS = sessionStorage.getItem('compId');
  const cropYearFromLS = JSON.parse(sessionStorage.getItem('year'))
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "InterestAgreementSetup",
    windowName: "InterestAgreementSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.InterestAgreementSetup",
    // START_USER_CODE-USER_InterestAgreementSetup_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Interest Agreement Setup",
      scrCode: "PN0130A",
    },
    // END_USER_CODE-USER_InterestAgreementSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxIntrstAgreeSetup",
      Label: "Add",
      CharWidth: "10",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxIntrstAgreeSetup",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxIntrstAgreeSetup",
      Label: "Delete",
      CharWidth: "15",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxIntrstAgreeSetup",
      Label: "Edit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxIntrstAgreeSetup",
      Label: "Location:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    lblIntrstAgree: {
      name: "lblIntrstAgree",
      type: "LabelWidget",
      parent: "grpbxIntrstAgreeSetup",
      Label: "Interest Agreements:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblIntrstAgree_PROPERTIES

      // END_USER_CODE-USER_lblIntrstAgree_PROPERTIES
    },
    lstIntrstAgree: {
      name: "lstIntrstAgree",
      type: "ListBoxFieldWidget",
      parent: "grpbxIntrstAgreeSetup",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstIntrstAgree_PROPERTIES

      // END_USER_CODE-USER_lstIntrstAgree_PROPERTIES
    },
    grpbxIntrstAgreeSetup: {
      name: "grpbxIntrstAgreeSetup",
      type: "GroupBoxWidget",
      parent: "InterestAgreementSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxIntrstAgreeSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxIntrstAgreeSetup_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceMasterManagement#InterestAgreementProfile":{}
       },
      REVERSE:{
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceMasterManagement#InterestAgreementProfile":{}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnClose: {
      DEFAULT:["ContractManagement#MainMenu#DEFAULT#false#Click"],
    }, 
    // btnAdd:{
    //   DEFAULT:[
    //   "SystemMaintenanceMasterManagement#InterestAgreementProfile#DEFAULT#true#Click",]
    // },
    // btnEdit:{
    //   DEFAULT:[
    //   "SystemMaintenanceMasterManagement#InterestAgreementProfile#DEFAULT#true#Click",]
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    let dataobj = getData(thisObj, 'InterestAgreementSetupData');
    if(dataobj != "" && dataobj != null && dataobj != undefined){
    let buy_pt_id = dataobj.buyingPt;
    if (buy_pt_id != undefined && buy_pt_id != "" && buy_pt_id != null)
      bFillAgreementList(buy_pt_id)
    }
    setData(thisObj, 'InterestAgreementSetupData', '')
  }, [getData(thisObj, 'InterestAgreementSetupData')])
  

  // START_USER_CODE-USER_METHODS
  //FormLoad Function
  let FormLoad = async () => {
   try{
    await bFillBuyingPointList();
    let buy_pt_id = getValue(thisObj, 'ddLocation')
    await bFillAgreementList(buy_pt_id);
    EnableDisableControls()
    setLoading(false)
   }
   catch(err){
    errorHandler(err)
  }
 }

 //Dropdown for Location List
const bFillBuyingPointList = async () => {
  try{
   let response =  await ContractManagementService.RetrieveBuyingPointControlDetails('PN1030', null, null, null, null);
   let data = response;
   let obj = {};
   let js = [];
   if (data !== null && data !== undefined && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      let dataExist = js.find(elem => elem.key == data[i].buy_pt_id)
      if (dataExist == undefined) {
       obj={
         key: data[i].buy_pt_id, 
         description: data[i].buy_pt_id + " - " + data[i].buy_pt_name,
         index: i
       }
       js.push(obj);
       obj={}
      }
    }
   }
   setData(thisObj, 'locationLength', js.length);
   setData(thisObj, 'LocationListData', js)
   setValue(thisObj, 'ddLocation', js.at(0).key);

   thisObj.setState(current => {
    return {
      ...current,
      ddLocation: {
        ...state["ddLocation"],
        valueList: js,
      }
    }
  })
  }
  catch(err){
    errorHandler(err)
  }
}
  // Function for Listbox
  const bFillAgreementList = async (buy_pt_id) => {
   try{
    let response = await SystemMaintenanceMasterManagementService.RetrieveIntrestAgreementDetails(compIdFromLS, cropYearFromLS, buy_pt_id)
    let data = response;
    let obj = {};
    let js = [];
    if (data !== null && data !== undefined && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        obj={
         key: data[i].agreeNum, 
         description:data[i].agreeNum + " - " + data[i].agreeVendor + " - " + data[i].vendorName, 
         index: i
        }
       js.push(obj);
       obj={}
      }
    }
    setData(thisObj, 'varietyLength', js.length);
    setData(thisObj, 'lstIntrstAgree', js)
    if (js[0] != undefined) {
      setValue(thisObj, "lstIntrstAgree", [js[0].key])
    }
    EnableDisableControls()
    thisObj.setState(current => {
      return {
        ...current,
        lstIntrstAgree: {
          ...state["lstIntrstAgree"],
          valueList: js
        }
      }
    });
   }
   catch(err){
    errorHandler(err)
   }
  }

  //Code for change and click event of Location and IntrestAgreement List 
  const onddLocationChange = async () => {
   try{
    let buy_pt_id = getValue(thisObj, 'ddLocation')
    await bFillAgreementList(buy_pt_id)
    EnableDisableControls()
   }
   catch(err){
    errorHandler(err)
   }
  }
  thisObj.onddLocationChange = onddLocationChange

  //Click event of Location Add button
  const onbtnAddClick = () => {
    try {
      let locationList = thisObj.state['ddLocation'].valueList
      let buy_pt_id = getValue(thisObj, 'ddLocation');
      let locationName = ''
      if (buy_pt_id !== undefined && buy_pt_id !== null && buy_pt_id !== '') {
        locationName = locationList.find(elem => elem.key == buy_pt_id).description
      }
      let frmInterestAgreementProfile = {}
      frmInterestAgreementProfile.cmdOk_Caption = 'Add';
      frmInterestAgreementProfile.lblLocation_Caption = locationName;
      frmInterestAgreementProfile.lblLocation_Tag = buy_pt_id;
      frmInterestAgreementProfile.txtAgreementNumber_enabled = 'True';
      setData(thisObj, 'frmInterestAgreementProfile', frmInterestAgreementProfile)
      goTo(thisObj,"SystemMaintenanceMasterManagement#InterestAgreementProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Add Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick

  //Click event of Location Edit button
  const onbtnEditClick = async () => {
    try {
     let buy_pt_id = thisObj.values['ddLocation'];
     let agree_num = getValue(thisObj, 'lstIntrstAgree')[0]
     let response = await SystemMaintenanceMasterManagementService.RetrieveIntrestAgreementDetails(compIdFromLS, cropYearFromLS, buy_pt_id, agree_num);
     let data = response;
     let frmInterestAgreementProfile = {}
     frmInterestAgreementProfile.cmdOk_Caption = 'Update'
     let locationList = thisObj.state['ddLocation'].valueList
     let locationName = ''
      if (buy_pt_id !== undefined && buy_pt_id !== null && buy_pt_id !== '') {
        locationName = locationList.find(elem => elem.key == buy_pt_id).description
      }
     frmInterestAgreementProfile.lblLocation_Caption = locationName;
     frmInterestAgreementProfile.lblLocation_Tag = buy_pt_id
     frmInterestAgreementProfile.lblAddedBy=data[0].addUser + " " + moment(data[0].addDateTime).format("MM/DD/YYYY")
     frmInterestAgreementProfile.lblChangedBy=data[0].chgUser + " " + moment(data[0].chgDateTime).format("MM/DD/YYYY")
     frmInterestAgreementProfile.txtAgreementNumber = data[0].agreeNum;
     frmInterestAgreementProfile.txtVendorNumber = data[0].agreeVendor;
     frmInterestAgreementProfile.txtRate = data[0].agreeRate;
     if(data[0].beginDate !=null){
      frmInterestAgreementProfile.txtBeginDateTime=moment(data[0].beginDate).format("MM/DD/YYYY");
     }
     else{
      let begin_date1=(data[0].beginDate)
      if(begin_date1 === null || begin_date1 === undefined || begin_date1 === ''){
        frmInterestAgreementProfile.txtBeginDateTime=''
      }
     }
     setData(thisObj, 'frmInterestAgreementProfile', frmInterestAgreementProfile)
     goTo(thisObj, "SystemMaintenanceMasterManagement#InterestAgreementProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during edit button"
        );
      }
      return false;
    }
  }
  thisObj.onbtnEditClick = onbtnEditClick

  //Function for Location Delete button
  const onbtnDeleteClick = async () => {
    try{
     let agree_num = getValue(thisObj, 'lstIntrstAgree')[0]
     let buy_pt_id = thisObj.values['ddLocation'];
     let intrstAgreeList = thisObj.state['lstIntrstAgree'].valueList
     let intrestAgreementName = ''
     let IntrestIndex;
     let agree_vendor1=''
     let agree_vendor;
     if (agree_num !== undefined && agree_num!== null && agree_num!== '') {
      intrestAgreementName = intrstAgreeList.find(elem => elem.key == agree_num).description;
      agree_vendor1=intrestAgreementName.split('-');
      agree_vendor=agree_vendor1[1].trim();
      IntrestIndex = intrstAgreeList.find(elem => elem.key == agree_num).index;
     }
     let res = confirm(`Are you sure you wish to delete this Interest Agreement \n'${intrestAgreementName}'`)
     if (res == true) {
      let response = await SystemMaintenanceMasterManagementService.RemoveIntrestAgreement(buy_pt_id,agree_num,agree_vendor)
      if (![200, 400, 404].includes(response.status)) {
       showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
       return
      }
      await bFillAgreementList(buy_pt_id)
      EnableDisableControls()
     }
    }
    catch (err) {
        if (err instanceof EvalError) {
          showMessage(thisObj, err.message);
        } else {
          showMessage(
            thisObj,
            "Exception in PreSubmit Event code for widget:btnDelete event:Click"
          );
         }
         return false;
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick

   // Enable Disable function
   const EnableDisableControls = () => {
    try {
      let VarietyList = getData(thisObj, 'varietyLength');
      if (VarietyList <= 0) {
        disable(thisObj, 'btnEdit')
        disable(thisObj, 'btnDelete')
      }
      else {
        enable(thisObj, 'btnEdit')
        enable(thisObj, 'btnDelete')
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred EnableDisable Function"
        );
      }
      return false;
    }
  }

  function errorHandler(err){
    showMessage(thisObj, err.message)
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_InterestAgreementSetup*/}

              {/* END_USER_CODE-USER_BEFORE_InterestAgreementSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxIntrstAgreeSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxIntrstAgreeSetup*/}

              <GroupBoxWidget
                conf={state.grpbxIntrstAgreeSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_lblIntrstAgree*/}

                {/* END_USER_CODE-USER_BEFORE_lblIntrstAgree*/}

                <LabelWidget
                  values={values}
                  conf={state.lblIntrstAgree}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblIntrstAgree*/}

                {/* END_USER_CODE-USER_AFTER_lblIntrstAgree*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_lstIntrstAgree*/}

                {/* END_USER_CODE-USER_BEFORE_lstIntrstAgree*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstIntrstAgree}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstIntrstAgree*/}

                {/* END_USER_CODE-USER_AFTER_lstIntrstAgree*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxIntrstAgreeSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxIntrstAgreeSetup*/}

              {/* START_USER_CODE-USER_AFTER_InterestAgreementSetup*/}

              {/* END_USER_CODE-USER_AFTER_InterestAgreementSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_InterestAgreementSetup
);
