/* eslint-disable*/
import React from "react";
import ContractManagement_ContractExceptionPremiumSetup from "./ContractExceptionPremiumSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ContractExceptionPremiumSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractExceptionPremiumSetup />);
    });
  });
  afterEach(cleanup);
  test("is ContractExceptionPremiumSetup Loads Successfully", () => {
    expect(screen.getByText("ContractExceptionPremiumSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for ContractExceptionPremiumSetup", () => {
    // START_USER_CODE-USER_ContractExceptionPremiumSetup_Custom_Test_Case
    // END_USER_CODE-USER_ContractExceptionPremiumSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractExceptionPremiumSetup />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("ContractManagement:ContractExceptionPremiumSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("ContractManagement:ContractExceptionPremiumSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDel(Button Widget) Test Cases", async () => {
    const btnDel = screen.getByTestId("btnDel");
    expect(btnDel).toBeInTheDocument;
    expect(btnDel.textContent).toEqual(
      t("ContractManagement:ContractExceptionPremiumSetup_btnDel")
    );
  });
  test("Custom Test Cases for btnDel", () => {
    // START_USER_CODE-USER_btnDel_TEST
    // END_USER_CODE-USER_btnDel_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("ContractManagement:ContractExceptionPremiumSetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnRescan(Button Widget) Test Cases", async () => {
    const btnRescan = screen.getByTestId("btnRescan");
    expect(btnRescan).toBeInTheDocument;
    expect(btnRescan.textContent).toEqual(
      t("ContractManagement:ContractExceptionPremiumSetup_btnRescan")
    );
  });
  test("Custom Test Cases for btnRescan", () => {
    // START_USER_CODE-USER_btnRescan_TEST
    // END_USER_CODE-USER_btnRescan_TEST
  });
  test("grpbcContrctExcPremSet(GroupBox Widget) Test Cases", async () => {
    const grpbcContrctExcPremSet = screen.getByTestId("grpbcContrctExcPremSet");
    expect(grpbcContrctExcPremSet.tagName).toBe("BUTTON");
    expect(grpbcContrctExcPremSet.type).toBe("button");
    expect(grpbcContrctExcPremSet.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbcContrctExcPremSet", () => {
    // START_USER_CODE-USER_grpbcContrctExcPremSet_TEST
    // END_USER_CODE-USER_grpbcContrctExcPremSet_TEST
  });
  test("grpbxAddDel(GroupBox Widget) Test Cases", async () => {
    const grpbxAddDel = screen.getByTestId("grpbxAddDel");
    expect(grpbxAddDel.tagName).toBe("BUTTON");
    expect(grpbxAddDel.type).toBe("button");
    expect(grpbxAddDel.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAddDel", () => {
    // START_USER_CODE-USER_grpbxAddDel_TEST
    // END_USER_CODE-USER_grpbxAddDel_TEST
  });
  test("txtContrctNm(Textbox Widget) Test Cases", async () => {
    const txtContrctNm = screen.getByTestId("txtContrctNm");
    expect(txtContrctNm.tagName).toBe("INPUT");
    expect(txtContrctNm.type).toBe("text");
    expect(txtContrctNm.classList).toContain("textboxWidgetClass");
    expect(txtContrctNm.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractExceptionPremiumSetup_txtContrctNm")
    );
    await act(async () => {
      userEvent.type(txtContrctNm, "1");
    });
  });
  test("Custom Test Cases for txtContrctNm", () => {
    // START_USER_CODE-USER_txtContrctNm_TEST
    // END_USER_CODE-USER_txtContrctNm_TEST
  });
});
