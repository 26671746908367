/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  getValue,
  getData,
  setData,
  getSelectedGridValue,
  getSelectedRowNumber,
  goTo
} from "../../shared/WindowImports";

import "./ContractGroupingSearch.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import {ContractManagementService} from"../Service/ContractManagementService";
import { returnNumericValues } from './../../Common/Constants';
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ContractGroupingSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "Contract Grouping Search",
    windowName: "ContractGroupingSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ContractGroupingSearch",
    // START_USER_CODE-USER_ContractGroupingSearch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Contract Grouping Search",
      scrCode: "PN1150A"
    },
    // END_USER_CODE-USER_ContractGroupingSearch_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxClose",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCrtContrctGrpSrch: {
      name: "btnCrtContrctGrpSrch",
      type: "ButtonWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "Create Contract Grouping",
      CharWidth: "61",
      // START_USER_CODE-USER_btnCrtContrctGrpSrch_PROPERTIES

      // END_USER_CODE-USER_btnCrtContrctGrpSrch_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "Open",
      CharWidth: "11",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnPopUp: {
      name: "btnPopUp",
      type: "ButtonWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "...",
      CharWidth: "9",
      // START_USER_CODE-USER_btnPopUp_PROPERTIES

      // END_USER_CODE-USER_btnPopUp_PROPERTIES
    },
    txtVndor: {
      name: "txtVndor",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    btnSrch: {
      name: "btnSrch",
      type: "ButtonWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "Search",
      CharWidth: "15",
      // START_USER_CODE-USER_btnSrch_PROPERTIES

      // END_USER_CODE-USER_btnSrch_PROPERTIES
    },
    ddByngPt: {
      name: "ddByngPt",
      type: "DropDownFieldWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "Buying Point",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddByngPt_PROPERTIES
      //optionMaxLength: 55,
      // END_USER_CODE-USER_ddByngPt_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddOrgnc: {
      name: "ddOrgnc",
      type: "DropDownFieldWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "Organic:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOrgnc_PROPERTIES

      // END_USER_CODE-USER_ddOrgnc_PROPERTIES
    },
    ddPenutType: {
      name: "ddPenutType",
      type: "DropDownFieldWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "Peanut Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPenutType_PROPERTIES

      // END_USER_CODE-USER_ddPenutType_PROPERTIES
    },
    ddPnutVarty: {
      name: "ddPnutVarty",
      type: "DropDownFieldWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "Peanut Variety:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPnutVarty_PROPERTIES

      // END_USER_CODE-USER_ddPnutVarty_PROPERTIES
    },
    ddSeedGrwr: {
      name: "ddSeedGrwr",
      type: "DropDownFieldWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "Seed Grower:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeedGrwr_PROPERTIES

      // END_USER_CODE-USER_ddSeedGrwr_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "Seg #:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },

    ddbutton: {
      name: "ddbutton",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "ddbutton:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },

    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      colName: "txtColBuyingPoint",
      parent: "gridContractGroupingSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    txtColBuyingPoint: {
      name: "txtColBuyingPoint",
      type: "GridColumnWidget",
      parent: "gridContractGroupingSearchData",
      Label: "Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtColBuyingPoint_PROPERTIES
    },
    txtColContract: {
      name: "txtColContract",
      type: "GridColumnWidget",
      parent: "gridContractGroupingSearchData",
      Label: "Contract #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColContract_PROPERTIES

      // END_USER_CODE-USER_txtColContract_PROPERTIES
    },
    txtColGrouping: {
      name: "txtColGrouping",
      type: "GridColumnWidget",
      parent: "gridContractGroupingSearchData",
      Label: "Grouping #",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColGrouping_PROPERTIES

      // END_USER_CODE-USER_txtColGrouping_PROPERTIES
    },
    txtColGroupingDate: {
      name: "txtColGroupingDate",
      type: "GridColumnWidget",
      parent: "gridContractGroupingSearchData",
      Label: "Grouping Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColGroupingDate_PROPERTIES

      // END_USER_CODE-USER_txtColGroupingDate_PROPERTIES
    },
    txtColOleic: {
      name: "txtColOleic",
      type: "GridColumnWidget",
      parent: "gridContractGroupingSearchData",
      Label: "Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColOleic_PROPERTIES

      // END_USER_CODE-USER_txtColOleic_PROPERTIES
    },
    txtColOrganic: {
      name: "txtColOrganic",
      type: "GridColumnWidget",
      parent: "gridContractGroupingSearchData",
      Label: "Organic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColOrganic_PROPERTIES

      // END_USER_CODE-USER_txtColOrganic_PROPERTIES
    },
    txtColPeanutType: {
      name: "txtColPeanutType",
      type: "GridColumnWidget",
      parent: "gridContractGroupingSearchData",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtColPeanutType_PROPERTIES
    },
    txtColPeanutVariety: {
      name: "txtColPeanutVariety",
      type: "GridColumnWidget",
      parent: "gridContractGroupingSearchData",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_txtColPeanutVariety_PROPERTIES
    },
    txtColSeedGrower: {
      name: "txtColSeedGrower",
      type: "GridColumnWidget",
      parent: "gridContractGroupingSearchData",
      Label: "Seed Grower",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSeedGrower_PROPERTIES

      // END_USER_CODE-USER_txtColSeedGrower_PROPERTIES
    },
    txtColSegType: {
      name: "txtColSegType",
      type: "GridColumnWidget",
      parent: "gridContractGroupingSearchData",
      Label: "Seg Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSegType_PROPERTIES

      // END_USER_CODE-USER_txtColSegType_PROPERTIES
    },
    txtColVendor: {
      name: "txtColVendor",
      type: "GridColumnWidget",
      parent: "gridContractGroupingSearchData",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColVendor_PROPERTIES

      // END_USER_CODE-USER_txtColVendor_PROPERTIES
    },
    txtContractC: {
      name: "txtContractC",
      type: "TextBoxWidget",
      colName: "txtColContract",
      parent: "gridContractGroupingSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContractC_PROPERTIES

      // END_USER_CODE-USER_txtContractC_PROPERTIES
    },
    txtGroupingC: {
      name: "txtGroupingC",
      type: "TextBoxWidget",
      colName: "txtColGrouping",
      parent: "gridContractGroupingSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGroupingC_PROPERTIES

      // END_USER_CODE-USER_txtGroupingC_PROPERTIES
    },
    txtGroupingDate: {
      name: "txtGroupingDate",
      type: "TextBoxWidget",
      colName: "txtColGroupingDate",
      parent: "gridContractGroupingSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGroupingDate_PROPERTIES

      // END_USER_CODE-USER_txtGroupingDate_PROPERTIES
    },
    txtOleic: {
      name: "txtOleic",
      type: "TextBoxWidget",
      colName: "txtColOleic",
      parent: "gridContractGroupingSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOleic_PROPERTIES

      // END_USER_CODE-USER_txtOleic_PROPERTIES
    },
    txtOrganic: {
      name: "txtOrganic",
      type: "TextBoxWidget",
      colName: "txtColOrganic",
      parent: "gridContractGroupingSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOrganic_PROPERTIES

      // END_USER_CODE-USER_txtOrganic_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      colName: "txtColPeanutType",
      parent: "gridContractGroupingSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtPeanutVariety: {
      name: "txtPeanutVariety",
      type: "TextBoxWidget",
      colName: "txtColPeanutVariety",
      parent: "gridContractGroupingSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_txtPeanutVariety_PROPERTIES
    },
    txtSeedGrower: {
      name: "txtSeedGrower",
      type: "TextBoxWidget",
      colName: "txtColSeedGrower",
      parent: "gridContractGroupingSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedGrower_PROPERTIES

      // END_USER_CODE-USER_txtSeedGrower_PROPERTIES
    },
    txtSegType: {
      name: "txtSegType",
      type: "TextBoxWidget",
      colName: "txtColSegType",
      parent: "gridContractGroupingSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegType_PROPERTIES

      // END_USER_CODE-USER_txtSegType_PROPERTIES
    },
    txtVendorC: {
      name: "txtVendorC",
      type: "TextBoxWidget",
      colName: "txtColVendor",
      parent: "gridContractGroupingSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorC_PROPERTIES

      // END_USER_CODE-USER_txtVendorC_PROPERTIES
    },
    gridContractGroupingSearchData: {
      name: "gridContractGroupingSearchData",
      type: "GridWidget",
      parent: "ContractGroupingSearch",
      gridCellsOrder:
        "txtGroupingC,txtGroupingDate,txtContractC,txtBuyingPoint,txtVendorC,txtPeanutType,txtPeanutVariety,txtSegType,txtOleic,txtSeedGrower,txtOrganic",
      Pagination: false,
      HasLabel: false,
      Cols: "5",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridContractGroupingSearchData_PROPERTIES
      isExpandable: true,
      defaultExpand: false,
      expandedCompTemplate: ({ data }) => (
        // <pre>{JSON.stringify(data, null, 2)}</pre>
        <div>
        {data.children ? (
        <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
        {data.children.map((elem, i) => 
          <div className={"row ExpandRow" + " rdt_ExpanderRow"+i} key={"expandRow"+i} onClick={expandRowClicked}>
          <div className="extraRowCell_2 ch-3 TableCell" key="expandCell1">
            {elem.txtGroupingC}
          </div>
          <div className="extraRowCell_3 ch-3 TableCell" key="expandCell2">
            {elem.txtGroupingDate}
          </div>
          <div className="extraRowCell_4 ch-3 TableCell" key="expandCell3">
            {elem.txtContractC}
          </div>
          <div className="extraRowCell_5 ch-3 TableCell" key="expandCell4">
            {elem.txtBuyingPoint}
          </div>
          <div className="extraRowCell_6 ch-3 TableCell" key="expandCell5">
            {elem.txtVendorC}
          </div>
          <div className="extraRowCell_7 ch-3 TableCell" key="expandCell6">
            {elem.txtPeanutType}
          </div>
          <div className="extraRowCell_8 ch-3 TableCell" key="expandCell7">
            {elem.txtPeanutVariety}
          </div>
          <div className="extraRowCell_9 ch-3 TableCell" key="expandCell8">
            {elem.txtSegType}
          </div>
          <div className="extraRowCell_10 ch-3 TableCell" key="expandCell9">
            {elem.txtOleic}
          </div>
          <div className="extraRowCell_11 ch-3 TableCell" key="expandCell10">
            {elem.txtSeedGrower}
          </div>
          <div className="extraRowCell_12 ch-3 TableCell" key="expandCell11">
            {elem.txtOrganic}
          </div>
          </div>
          )}
          </div>
        ): null}
          </div>
      )
      // END_USER_CODE-USER_gridContractGroupingSearchData_PROPERTIES
    },
    txtContrct: {
      name: "txtContrct",
      type: "TextBoxWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContrct_PROPERTIES

      // END_USER_CODE-USER_txtContrct_PROPERTIES
    },
    txtGrpng: {
      name: "txtGrpng",
      type: "TextBoxWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "Grouping #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGrpng_PROPERTIES

      // END_USER_CODE-USER_txtGrpng_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxCntctGrpSrch",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    grpbxCntctGrpSrch: {
      name: "grpbxCntctGrpSrch",
      type: "GroupBoxWidget",
      parent: "ContractGroupingSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCntctGrpSrch_PROPERTIES

      // END_USER_CODE-USER_grpbxCntctGrpSrch_PROPERTIES
    },
    grpbxClose: {
      name: "grpbxClose",
      type: "GroupBoxWidget",
      parent: "ContractGroupingSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxClose_PROPERTIES

      // END_USER_CODE-USER_grpbxClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#ContractGroupingProfile": {},

        "ContractManagement#VendorLookup": {},
      },
      REVERSE: {
        "ContractManagement#ContractGroupingProfile": {},

        "ContractManagement#VendorLookup": {},
      },
    },
  };
  //setting local storage parameters
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let comp_id = sessionStorage.getItem('compId');
  const [loading, setLoading] = useState(false);

  let _buttonServices = { ContractGroupingProfile: {}, VendorLookup: {}, MainMenu: {} };
  let _buttonNavigation = {
    btnPopUp: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btnCrtContrctGrpSrch: {
      DEFAULT: [
        "ContractManagement#ContractGroupingProfile#DEFAULT#true#Click",
      ],
    },
    btnClose : {
      DEFAULT: [
        "ContractManagement#MainMenu#DEFAULT#false#Click",
      ],
    },
    btnOpen: {
      DEFAULT: [
        "ContractManagement#ContractGroupingProfile#DEFAULT#true#Click",
      ],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  //Reload ContractGroupingSearch Grid When Contract Grouping Profile is updated / deleted
  useEffect(() => {
    let ReloadContractGroupingSearchGrid = getData(thisObj, "ReloadContractGroupingSearchGrid");
    if (ReloadContractGroupingSearchGrid !== null && ReloadContractGroupingSearchGrid == true) {
      onbtnSearchClick()
    }
  }, [getData(thisObj, "ReloadContractGroupingSearchGrid")]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS 
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    
    setValue(thisObj,"ddPenutType",''),
      setValue(thisObj, "ddPnutVarty", ''),
      setValue(thisObj, "ddSeg", ''),
      setValue(thisObj, "ddOleic", ''),
      setValue(thisObj, "ddSeedGrwr", ''),
      setValue(thisObj, "ddByngPt", ''),
      setValue(thisObj, "ddOrgnc", ''),
      setValue(thisObj,"txtVndr",''),
      setValue(thisObj,"txtVndor",'')
      FormLoad()
  }, []);

  useEffect(() => {
    
    setVendorNameBasedOnVendorNumber()
    
  }, [getData(thisObj, 'vendorDetails')]);

  function addMultiOccurredRowId(data) {
    let map = new Map();
    let newJs = [];
    let obj = {};
    for (let i = 0; i < data.length; i++) {
      if (map.has(data[i].txtGroupingC)) {
        obj = { ...data[i] };
        obj.multiOccurredRowId = map.get(data[i].txtGroupingC);
      }
      else {
        map.set(data[i].txtGroupingC, data[i].RowId);
        obj = { ...data[i] };
        obj.multiOccurredRowId = null;
      }
      newJs.push(obj);
    }
    return newJs;
  }

  function groupMultiOccurredRows(data, multi = null) {
    return data.reduce((r, e) => {
      if (e.multiOccurredRowId == multi) {
        const obj = { ...e };
        const children = groupMultiOccurredRows(data, e.RowId);
        if (children.length) {
          obj.children = children;
        }
        r.push(obj);
      }
      return r;
    }, [])
  }

  function expandRowClicked(event) {
    var elems = document.querySelectorAll(".activeExpandRow");
    elems.forEach.call(elems, function (el) {
      el.classList.remove("activeExpandRow");
    });
    var elem = document.querySelectorAll(".icTWDw");
    thisObj.state.gridContractGroupingSearchData.selected = [];
    elem.forEach.call(elem, function (el) {
      el.classList.remove("icTWDw");
      el.classList.add("eaQpfw");
    });
    if (event.target.classList.contains("TableCell")) {
      event.target.parentElement.classList.value += " activeExpandRow";
    }
    else {
      event.target.classList.value += " activeExpandRow";
    }
  }

  //Peanut Type dropdown change event
  const onPeanutTypeChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPenutType'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPenutType'];
      let pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;


      let BuyingPointList = thisObj.state['ddByngPt'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddByngPt'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== null && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety based on Buying Point and Peanut Type
      bFillVarietyList(buyingPointId, pnut_type_id)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Peanut Type dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddPenutTypeChange = onPeanutTypeChange;

  //Buying Point dropdown change event
  const onBuyingPointChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPenutType'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPenutType'];
      let pnut_type_id = null
      if (SelectedPeanutTypeValue !== "" && SelectedPeanutTypeValue !== undefined) {
        pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      }

      let BuyingPointList = thisObj.state['ddByngPt'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddByngPt'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety based on Buying Point and Peanut Type
      bFillVarietyList(buyingPointId, pnut_type_id)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Buying Point dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddByngPtChange = onBuyingPointChange;

  // ontxtVndrChange Event Set Vendor name based on entered vendor name
  const ontxtVndrChange = () => {
    try {
      let vendorNumber = txtVndr.value
      if (vendorNumber.length == 6) {
        thisObj.setFieldValue('ddbutton', 'txtvndrchange')
        setVendorNameBasedOnVendorNumber(vendorNumber)
      } else {
        document.getElementsByClassName("txtVndor")[0].style.visibility = "hidden";
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtVndrChange event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtVndrChange = ontxtVndrChange;

  //Event Set Vendor name based on entered vendor name
  const setVendorNameBasedOnVendorNumber = () => {
    let vendorValueFromTextBox = getValue(thisObj, 'txtVndr')
    let vendorname = null
    let vendornamevisible = false
    let staticButtonValue = getValue(thisObj, 'ddbutton')
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    let vendorNumber = ''
    if (vendorValueFromTextBox !== undefined && staticButtonValue == 'txtvndrchange') {
      vendorNumber = vendorValueFromTextBox
    } else if (vendor_lookup !== null && staticButtonValue == 'btnPopupVendr') {
      vendorNumber = vendor_lookup.VendorNumber
    }
    if(vendorNumber!=""){
    ContractManagementService.RetrieveVendorByNumber(vendorNumber).then(response => {
      let data = response
      if (response !== undefined && response.length> 0) {
        vendorname = data[0].name
        vendornamevisible = true
        
      }
      if (vendornamevisible == true) {
        document.getElementsByClassName("txtVndor")[0].style.visibility = "visible";
        thisObj.setFieldValue("txtVndor", vendorname);
        thisObj.setFieldValue("txtVndr", vendorNumber);
      }
      else {
        document.getElementsByClassName("txtVndor")[0].style.visibility = "hidden";
        thisObj.setFieldValue("txtVndor", '');
      }
    })
  }
  }
  // Vendor Popup button Click
  const onbtnPopUpClick = () => {
    try {
      thisObj.setFieldValue('ddbutton', 'btnPopupVendr')
      setVendorNameBasedOnVendorNumber()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnPopupVendr: Click"
        )
      }
      return false
    }
    return true
  };
  thisObj.onbtnPopUpClick = onbtnPopUpClick;


  const onbtnOpenClick = () => {
    try {
      let groupingNumberValue = null;
      let groupingDateValue = null;
      let selectedRow = getSelectedRowNumber(thisObj, "gridContractGroupingSearchData")
      if (selectedRow !== undefined) {
        groupingNumberValue = getSelectedGridValue(thisObj, "gridContractGroupingSearchData", "txtGroupingC");
        groupingDateValue = getSelectedGridValue(thisObj, "gridContractGroupingSearchData", "txtGroupingDate");
        setData(thisObj, "GroupingNumber", groupingNumberValue);
        setData(thisObj, "GroupingDate", groupingDateValue);
      }
      else if (document.getElementsByClassName("activeExpandRow")[0] !== undefined) {
        groupingNumberValue = document.getElementsByClassName("activeExpandRow")[0].firstChild.innerText;
        groupingDateValue = document.getElementsByClassName("activeExpandRow")[0].children[1].innerText;
        setData(thisObj, "GroupingNumber", groupingNumberValue);
        setData(thisObj, "GroupingDate", groupingDateValue);
      }
      else {
        showMessage(thisObj, "Contract Grouping row must be selected to Open Contract Grouping Page");
        return false
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred while getting selected row object on Open button click event"
        );
      }
    }
    return true
  }
  thisObj.onbtnOpenClick = onbtnOpenClick;

  // On Grid Row Double Click Event - Open ContractGroupingProfile Page
  const ongridContractGroupingSearchDataDblclick = () => {
    try {
      let groupingNumberValue = null;
      let groupingDateValue = null;
      let selectedRow = getSelectedRowNumber(thisObj, "gridContractGroupingSearchData")
      if (selectedRow !== undefined) {
        groupingNumberValue = getSelectedGridValue(thisObj, "gridContractGroupingSearchData", "txtGroupingC");
        groupingDateValue = getSelectedGridValue(thisObj, "gridContractGroupingSearchData", "txtGroupingDate");
        setData(thisObj, "GroupingNumber", groupingNumberValue);
        setData(thisObj, "GroupingDate", groupingDateValue);
        goTo(thisObj, "ContractManagement#ContractGroupingProfile#DEFAULT#true#Click");
      }
      else if (document.getElementsByClassName("activeExpandRow")[0] !== undefined) {
        groupingNumberValue = document.getElementsByClassName("activeExpandRow")[0].firstChild.innerText;
        groupingDateValue = document.getElementsByClassName("activeExpandRow")[0].children[1].innerText;
        setData(thisObj, "GroupingNumber", groupingNumberValue);
        setData(thisObj, "GroupingDate", groupingDateValue);
        goTo(thisObj, "ContractManagement#ContractGroupingProfile#DEFAULT#true#Click");
      }
      else {
        showMessage(thisObj, "Contract Grouping row must be selected to Open Contract Grouping Page");
        return false
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred while getting selected row object on Grid Row Double click event"
        );
      }
    }
    return true
  }
  thisObj.ongridContractGroupingSearchDataDblclick = ongridContractGroupingSearchDataDblclick;



  const onbtnCrtContrctGrpSrchClick = () => {
    try {
      setData(thisObj, "GroupingNumber", '');
      setData(thisObj, "GroupingDate", '');
      thisObj.setFieldValue('ddbutton', 'btnCrtContrctGrpSrch')
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred while opening create contract grouping page"
        );
      }
    }
    return true
  }
  thisObj.onbtnCrtContrctGrpSrchClick = onbtnCrtContrctGrpSrchClick;

  const onbtnSearchClick = () => {
    try {
      let func_id = 'PN1150'
      let func_sub_id = null
      let grouping_num = txtGrpng.value
      let contract_num = txtContrct.value
      let vendor_number = txtVndr.value
      let buy_pt_id = thisObj.values['ddByngPt']
      let pnut_type_id = thisObj.values['ddPenutType']
      let pnut_variety_id = thisObj.values['ddPnutVarty']
      let seg_type = thisObj.values['ddSeg']
      let oleic_ind = thisObj.values['ddOleic']
      let seed_grower = thisObj.values['ddSeedGrwr']
      let organic_ind = thisObj.values['ddOrgnc']
      let reload = getData(thisObj, "ReloadContractGroupingSearchGrid");

      if (buy_pt_id == '' && grouping_num == '' && contract_num == '' && vendor_number == '') { 
        if(reload !== true){
       alert("Grouping#, Contract#, or Vendor# must be specified for searches across all buying points", false);
        }}
      else {
        setLoading(true);
        // Function for getting Contract Grouping list based on given criteria
        ContractManagementService.RetrieveContractGroupDetails(func_id, func_sub_id, grouping_num, contract_num, buy_pt_id, vendor_number, pnut_type_id, pnut_variety_id, seg_type, oleic_ind, seed_grower, organic_ind)
          .then(response => {     
            let TransformedRowsArray = [];       
            if (response != undefined && response.length > 0) {
              let data = response;
              let obj = {}
              let VendorNumber = "";
              let VendorName = "";
              let peanutVarietyName = "";
              let symbolInd = "";
              let RowId = 0;
              for (var i = 0; i < data.length; i++) {
                if (data[i].strFieldName.trim() == 'COMP_ID') {
                  obj = {}
                  VendorNumber = ""
                  VendorName = ""
                  RowId = RowId + 1;
                  obj.RowId = RowId
                }
                if (data[i].strFieldName.trim() == 'GROUPING_NUM') {
                  obj.txtGroupingC = data[i].strFieldData.trim()
                }
                if (data[i].strFieldName.trim() == 'GROUPING_DATE_TIME') {
                  obj.txtGroupingDate = new Date(data[i].strFieldData.trim()).toLocaleDateString();
                }
                if (data[i].strFieldName.trim() == 'CONTRACT_NUM') {
                  obj.txtContractC = data[i].strFieldData.trim()
                }
                if (data[i].strFieldName.trim() == 'BUY_PT_ID') {
                  obj.txtBuyingPoint = data[i].strFieldData.trim()
                }
                if (data[i].strFieldName.trim() == 'VENDOR_NUMBER') {
                  VendorNumber = data[i].strFieldData.trim()
                }
                if (data[i].strFieldName.trim() == 'VENDOR_NAME') {
                  VendorName = data[i].strFieldData.trim()
                }
                if (data[i].strFieldName.trim() == 'PNUT_TYPE_ID') {
                  obj.txtPeanutType = data[i].strFieldData.trim()
                }
                if (data[i].strFieldName.trim() == 'PNUT_VARIETY_NAME') {
                  peanutVarietyName = data[i].strFieldData.trim()
                }
                if (data[i].strFieldName.trim() == 'SYMBOL_IND') {
                  symbolInd = data[i].strFieldData.trim()
                }
                if (data[i].strFieldName.trim() == 'SEG_TYPE') {
                  obj.txtSegType = data[i].strFieldData.trim()
                }
                if (data[i].strFieldName.trim() == 'OLEIC_IND') {
                  switch (data[i].strFieldData.trim()) {
                    case "H":
                      obj.txtOleic = "High";
                      break;
                    case "M":
                      obj.txtOleic = "Mid";
                      break;
                    default:
                      obj.txtOleic = "";
                  }
                }
                if (data[i].strFieldName.trim() == 'SEED_GROWER') {
                  switch (data[i].strFieldData.trim()) {
                    case "Y":
                      obj.txtSeedGrower = "Yes";
                      break;
                    case "N":
                      obj.txtSeedGrower = "No";
                      break;
                    default:
                      obj.txtSeedGrower = "";
                  }
                }
                if (data[i].strFieldName.trim() == 'ORGANIC_IND') {
                  obj.txtVendorC = VendorNumber + " - " + VendorName

                  switch (symbolInd) {
                    case "TRADEMARK":
                      obj.txtPeanutVariety = peanutVarietyName + ' ᵀᴹ';
                      break;
                    case "REGISTERED TRADEMARK":
                      obj.txtPeanutVariety = peanutVarietyName + ' ®';
                      break;
                    case "COPYRIGHT":
                      obj.txtPeanutVariety = peanutVarietyName + ' ©';
                      break;
                    default:
                      obj.txtPeanutVariety = peanutVarietyName
                  }
                  switch (data[i].strFieldData.trim()) {
                    case "Y":
                      obj.txtOrganic = "Yes";
                      break;
                    case "N":
                      obj.txtOrganic = "No";
                      break;
                    default:
                      obj.txtOrganic = "";
                  }
                  TransformedRowsArray.push(obj)
                }
              }
              let MultiOccurredRowArray = addMultiOccurredRowId(TransformedRowsArray);
              const groupedRowsArray = groupMultiOccurredRows(MultiOccurredRowArray);
              setValue(thisObj, "gridContractGroupingSearchData", groupedRowsArray);
              setLoading(false);
            } else {
              setLoading(false);
              setValue(thisObj, "gridContractGroupingSearchData", TransformedRowsArray);
              showMessage(thisObj, "No Groupings Found!!!");
              return false;
            }
          })
      }
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
        )
      }
      return false
    }
    setData(thisObj, "ReloadContractGroupingSearchGrid", false);
    return true
  };
  thisObj.onbtnSrchClick = onbtnSearchClick;

  //textbox validations
  const ontxtGrpngChange = () => {
    setValue(thisObj, "txtGrpng", returnNumericValues(txtGrpng.value))
  };
  thisObj.ontxtGrpngChange = ontxtGrpngChange;

  const ontxtContrctChange = () => {
    setValue(thisObj, "txtContrct", returnNumericValues(txtContrct.value))
  };
  thisObj.ontxtContrctChange = ontxtContrctChange;

//Method for binding Peanut Variety based on Buying Point and Peanut Type
const bFillVarietyList =(buyingPointId,pnut_type_id) =>
{
  let js = [];
  let obj = { key: '', description: ">>> All Varieties <<<" }
  js.push(obj)
  if(pnut_type_id !== null && pnut_type_id !== '')
  {
    ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null)
    .then(response => 
      {
        let data = response;
        if(data!==null&&data!==undefined)
        {let js1=[];
            for (var i = 0; i < data.length; i++) 
            {
              if (!(js1.includes(data[i].pnut_variety_id)))
              {
                obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name }
                if (data[i].symbol_ind == "TRADEMARK") {
                  obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name + 'ᵀᴹ' }
                }
                if (data[i].symbol_ind == "REGISTERED TRADEMARK") {
                  obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name + '®' }
                }
                if (data[i].symbol_ind == "COPYRIGHT") {
                  obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name + '©' }
                }
                js.push(obj);
                js1.push(data[i].pnut_variety_id)	;
              }
            }
        }  
      })
  }
    thisObj.setState(current => {
      return {
        ...current,
        ddPnutVarty: {
          ...state["ddPnutVarty"],
          valueList: js
        }
      }
    });
}

  function FormLoad() {
    bFillTypeList(); // Method for binding Peanut Type dropdown
    bFillBuyingPointList(); // Method for binding Buying Points
    bFillVarietyList(null,null);
    bFillSegmentList(); // Method for binding Segments
    bFillOleicList(); // Method for binding Oleic values
    bFillSeedGrowerList(); // Method for binding Seed Grower List
    bFillOrganicList(); // Method for binding Organic List
    document.getElementsByClassName("txtVndor")[0].style.visibility = "hidden"; //Hide Vendor Name on Form Load
  }
  // START_USER_CODE-USER_METHODS

  //Method for binding Peanut Type dropdown
  const bFillTypeList=()=> {
    let js=[];
    js.push({key:'',description:'>>> All Types <<<'}) //Set All as default value
    ContractManagementService.RetrievePeanutTypeControls(null).then(response=>{
      let data = response
      for(var i=0;i<data.length;i++){
        let obj = {key:data[i].pnutTypeId , description:data[i].pnutTypeName}
        js.push(obj)      
      }
      thisObj.setState( current => {
        return {
          ...current,
          ddPenutType: {
            ...state["ddPenutType"],
            valueList : js,
          }
        }
      })
    })
  }

    // Method for binding Segment list  
    const bFillSegmentList=()=> {
      let js =[]
      js.push({key:'',description:'>>> All Segments <<<'})
      js.push({key:'1',description:'1'})
      js.push({key:'2',description:'2'})
      js.push({key:'3',description:'3'})
    
      thisObj.setState(current=>{
        return{
          ...current,
          ddSeg:{
            ...state["ddSeg"],
            valueList : js
          }
        }
      })
  }

 // Method for binding Oleic values
  const bFillOleicList=()=> {
    let js =[]
    js.push({key:'',description:'>>> All Oleic <<<'})
    js.push({key:'H',description:'High'})
    js.push({key:'M',description:'Mid'})
    js.push({key:'N',description:'None'})
    thisObj.setState(current=>{
      return{
        ...current,
        ddOleic:{
          ...state["ddOleic"],
          valueList : js
        }
      }
    })
  }

  // Method for binding Seed Grower List
  const bFillSeedGrowerList=()=> {
    let js =[]
    js.push({key:'',description:'>>> Both <<<'})
    js.push({key:'Y',description:'Yes'})
    js.push({key:'N',description:'No'})
    thisObj.setState(current=>{
      return{
        ...current,
        ddSeedGrwr:{
          ...state["ddSeedGrwr"],
          valueList : js
        }
      }
    })
}

// Method for binding Organic List
const bFillOrganicList=()=> {
  let js =[]
  js.push({key:'',description:'>>> Both <<<'})
  js.push({key:'Y',description:'Yes'})
  js.push({key:'N',description:'No'})
  thisObj.setState(current=>{
    return{
      ...current,
      ddOrgnc:{
        ...state["ddOrgnc"],
        valueList : js
      }
    }
  })
}

// Method for binding Buying Points
const bFillBuyingPointList=()=>{
  let js = []
  js.push({ key: '', description: '>>>All Buying Points<<<' });
  ContractManagementService.RetrieveBuyingPointControlDetails('PN1150',null,null,null,null).then(response=>{
    let data = response
    for(var i=0; i < data.length; i++)
    {
      let obj = {key : data[i].buy_pt_id , description:data[i].buy_pt_id.trim()+'-'+data[i].buy_pt_name.trim()}
      js.push(obj)
    }
    thisObj.setState(current=>{
      return{
        ...current,
        ddByngPt:{
          ...state["ddByngPt"],
          valueList : js
        }
      }
    })
    //setValue(thisObj, 'drpBuyingPoint', js.at(0).key);
  })
}

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData = {state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ContractGroupingSearch*/}

              {/* END_USER_CODE-USER_BEFORE_ContractGroupingSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCntctGrpSrch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCntctGrpSrch*/}

              <GroupBoxWidget conf={state.grpbxCntctGrpSrch} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_ddByngPt*/}

                {/* END_USER_CODE-USER_BEFORE_ddByngPt*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddByngPt}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddByngPt*/}

                {/* END_USER_CODE-USER_AFTER_ddByngPt*/}
                {/* START_USER_CODE-USER_BEFORE_ddPenutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPenutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPenutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPenutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPenutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtGrpng*/}

                {/* END_USER_CODE-USER_BEFORE_txtGrpng*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtGrpng}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtGrpng*/}

                {/* END_USER_CODE-USER_AFTER_txtGrpng*/}
                {/* START_USER_CODE-USER_BEFORE_ddPnutVarty*/}

                {/* END_USER_CODE-USER_BEFORE_ddPnutVarty*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPnutVarty}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPnutVarty*/}

                {/* END_USER_CODE-USER_AFTER_ddPnutVarty*/}
                {/* START_USER_CODE-USER_BEFORE_txtContrct*/}

                {/* END_USER_CODE-USER_BEFORE_txtContrct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContrct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContrct*/}

                {/* END_USER_CODE-USER_AFTER_txtContrct*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeg}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                {/* START_USER_CODE-USER_BEFORE_btnPopUp*/}

                {/* END_USER_CODE-USER_BEFORE_btnPopUp*/}

                <ButtonWidget
                  conf={state.btnPopUp}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                  <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_btnPopUp*/}

                {/* END_USER_CODE-USER_AFTER_btnPopUp*/}
                {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleic}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeedGrwr*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeedGrwr*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeedGrwr}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeedGrwr*/}

                {/* END_USER_CODE-USER_AFTER_ddSeedGrwr*/}
                {/* START_USER_CODE-USER_BEFORE_btnSrch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSrch*/}

                <ButtonWidget
                  conf={state.btnSrch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSrch*/}

                {/* END_USER_CODE-USER_AFTER_btnSrch*/}
                {/* START_USER_CODE-USER_BEFORE_ddOrgnc*/}

                {/* END_USER_CODE-USER_BEFORE_ddOrgnc*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOrgnc}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOrgnc*/}

                {/* END_USER_CODE-USER_AFTER_ddOrgnc*/}
                {/* START_USER_CODE-USER_BEFORE_btnCrtContrctGrpSrch*/}

                {/* END_USER_CODE-USER_BEFORE_btnCrtContrctGrpSrch*/}

                <ButtonWidget
                  conf={state.btnCrtContrctGrpSrch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCrtContrctGrpSrch*/}

                {/* END_USER_CODE-USER_AFTER_btnCrtContrctGrpSrch*/}
                {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                <ButtonWidget
                  conf={state.btnOpen}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOpen*/}

                {/* END_USER_CODE-USER_AFTER_btnOpen*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCntctGrpSrch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCntctGrpSrch*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxClose*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxClose*/}
              <GridWidget
                conf={state.gridContractGroupingSearchData}
                screenConf={state}
                linkClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                errors={errors}
                touched={touched}
                rows={values.gridContractGroupingSearchData}
                onEvent={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                setFieldValue={setFieldValue} 
              ></GridWidget>
               <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxClose} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}
               
                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxClose*/}

              {/* END_USER_CODE-USER_AFTER_grpbxClose*/}

              {/* START_USER_CODE-USER_AFTER_ContractGroupingSearch*/}

              {/* END_USER_CODE-USER_AFTER_ContractGroupingSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ContractGroupingSearch);
