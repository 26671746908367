/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_LoanRepaySetup from "./LoanRepaySetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("LoanRepaySetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_LoanRepaySetup />);
    });
  });
  afterEach(cleanup);
  test("is LoanRepaySetup Loads Successfully", () => {
    expect(screen.getByText("LoanRepaySetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for LoanRepaySetup", () => {
    // START_USER_CODE-USER_LoanRepaySetup_Custom_Test_Case
    // END_USER_CODE-USER_LoanRepaySetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_LoanRepaySetup />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:LoanRepaySetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:LoanRepaySetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:LoanRepaySetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:LoanRepaySetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("grpbxLoanRepaySetup(GroupBox Widget) Test Cases", async () => {
    const grpbxLoanRepaySetup = screen.getByTestId("grpbxLoanRepaySetup");
    expect(grpbxLoanRepaySetup.tagName).toBe("BUTTON");
    expect(grpbxLoanRepaySetup.type).toBe("button");
    expect(grpbxLoanRepaySetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLoanRepaySetup", () => {
    // START_USER_CODE-USER_grpbxLoanRepaySetup_TEST
    // END_USER_CODE-USER_grpbxLoanRepaySetup_TEST
  });
  test("lblLoanRepayment(Label Widget) Test Cases", async () => {
    const lblLoanRepayment = screen.getByTestId("lblLoanRepayment");
    expect(lblLoanRepayment.tagName).toBe("LABEL");
    expect(lblLoanRepayment.classList).toContain("form-label");
    expect(lblLoanRepayment.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:LoanRepaySetup_lblLoanRepayment")
    );
  });
  test("Custom Test Cases for lblLoanRepayment", () => {
    // START_USER_CODE-USER_lblLoanRepayment_TEST
    // END_USER_CODE-USER_lblLoanRepayment_TEST
  });
});
