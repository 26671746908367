/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_VendorWireTransfer from "./VendorWireTransfer";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("VendorWireTransfer Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_VendorWireTransfer />
      );
    });
  });
  afterEach(cleanup);
  test("is VendorWireTransfer Loads Successfully", () => {
    expect(screen.getByText("VendorWireTransfer")).toBeInTheDocument;
  });
  test("Custom Test Cases for VendorWireTransfer", () => {
    // START_USER_CODE-USER_VendorWireTransfer_Custom_Test_Case
    // END_USER_CODE-USER_VendorWireTransfer_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_VendorWireTransfer />
      );
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransfer_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnSelect(Button Widget) Test Cases", async () => {
    const btnSelect = screen.getByTestId("btnSelect");
    expect(btnSelect).toBeInTheDocument;
    expect(btnSelect.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransfer_btnSelect")
    );
  });
  test("Custom Test Cases for btnSelect", () => {
    // START_USER_CODE-USER_btnSelect_TEST
    // END_USER_CODE-USER_btnSelect_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransfer_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("grpbxVendorWireTransfer(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorWireTransfer = screen.getByTestId(
      "grpbxVendorWireTransfer"
    );
    expect(grpbxVendorWireTransfer.tagName).toBe("BUTTON");
    expect(grpbxVendorWireTransfer.type).toBe("button");
    expect(grpbxVendorWireTransfer.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorWireTransfer", () => {
    // START_USER_CODE-USER_grpbxVendorWireTransfer_TEST
    // END_USER_CODE-USER_grpbxVendorWireTransfer_TEST
  });
  test("txtName(Textbox Widget) Test Cases", async () => {
    const txtName = screen.getByTestId("txtName");
    expect(txtName.tagName).toBe("INPUT");
    expect(txtName.type).toBe("text");
    expect(txtName.classList).toContain("textboxWidgetClass");
    expect(txtName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransfer_txtName")
    );
    await act(async () => {
      userEvent.type(txtName, "1");
    });
  });
  test("Custom Test Cases for txtName", () => {
    // START_USER_CODE-USER_txtName_TEST
    // END_USER_CODE-USER_txtName_TEST
  });
  test("txtVendorNum(Textbox Widget) Test Cases", async () => {
    const txtVendorNum = screen.getByTestId("txtVendorNum");
    expect(txtVendorNum.tagName).toBe("INPUT");
    expect(txtVendorNum.type).toBe("text");
    expect(txtVendorNum.classList).toContain("textboxWidgetClass");
    expect(txtVendorNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransfer_txtVendorNum")
    );
    await act(async () => {
      userEvent.type(txtVendorNum, "1");
    });
  });
  test("Custom Test Cases for txtVendorNum", () => {
    // START_USER_CODE-USER_txtVendorNum_TEST
    // END_USER_CODE-USER_txtVendorNum_TEST
  });
  test("txtVndr(Textbox Widget) Test Cases", async () => {
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVndr, "1");
    });
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
});
