/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  LabelWidget,
  getValue,
  getSelectedRowNumber,
  setData,
  getData
} from "../../shared/WindowImports";

import "./SelectFromList.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { StockTransferService } from "../Service/StockTransferService";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function StockTransfer_SelectFromList(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES
  let comp_id = sessionStorage.getItem('compId')
  let crop_year = sessionStorage.getItem('year')
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SelectFromList",
    windowName: "SelectFromList",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "StockTransfer.SelectFromList",
    // START_USER_CODE-USER_SelectFromList_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Select From List",
      scrCode: "PN1080B",
    },
    // END_USER_CODE-USER_SelectFromList_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    colBinName: {
      name: "colBinName",
      type: "GridColumnWidget",
      parent: "gridList",
      Label: "Bin Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBinName_PROPERTIES

      // END_USER_CODE-USER_colBinName_PROPERTIES
    },
    colBinNum: {
      name: "colBinNum",
      type: "GridColumnWidget",
      parent: "gridList",
      Label: "Bin#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBinNum_PROPERTIES

      // END_USER_CODE-USER_colBinNum_PROPERTIES
    },
    colCapTon: {
      name: "colCapTon",
      type: "GridColumnWidget",
      parent: "gridList",
      Label: "Cap.[Ton]",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCapTon_PROPERTIES

      // END_USER_CODE-USER_colCapTon_PROPERTIES
    },
    colEdible: {
      name: "colEdible",
      type: "GridColumnWidget",
      parent: "gridList",
      Label: "Edible",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEdible_PROPERTIES

      // END_USER_CODE-USER_colEdible_PROPERTIES
    },
    colGeneration: {
      name: "colGeneration",
      type: "GridColumnWidget",
      parent: "gridList",
      Label: "Generation",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGeneration_PROPERTIES

      // END_USER_CODE-USER_colGeneration_PROPERTIES
    },
    colInvTon: {
      name: "colInvTon",
      type: "GridColumnWidget",
      parent: "gridList",
      Label: "Inv.[Ton]",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInvTon_PROPERTIES

      // END_USER_CODE-USER_colInvTon_PROPERTIES
    },
    colOleic: {
      name: "colOleic",
      type: "GridColumnWidget",
      parent: "gridList",
      Label: "Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOleic_PROPERTIES

      // END_USER_CODE-USER_colOleic_PROPERTIES
    },
    colOrganic: {
      name: "colOrganic",
      type: "GridColumnWidget",
      parent: "gridList",
      Label: "Organic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOrganic_PROPERTIES

      // END_USER_CODE-USER_colOrganic_PROPERTIES
    },
    colSegNum: {
      name: "colSegNum",
      type: "GridColumnWidget",
      parent: "gridList",
      Label: "Seg#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSegNum_PROPERTIES

      // END_USER_CODE-USER_colSegNum_PROPERTIES
    },
    colTypeNum: {
      name: "colTypeNum",
      type: "GridColumnWidget",
      parent: "gridList",
      Label: "Type#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTypeNum_PROPERTIES

      // END_USER_CODE-USER_colTypeNum_PROPERTIES
    },
    colVariety: {
      name: "colVariety",
      type: "GridColumnWidget",
      parent: "gridList",
      Label: "Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVariety_PROPERTIES

      // END_USER_CODE-USER_colVariety_PROPERTIES
    },
    colWhseNum: {
      name: "colWhseNum",
      type: "GridColumnWidget",
      parent: "gridList",
      Label: "Whse#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhseNum_PROPERTIES

      // END_USER_CODE-USER_colWhseNum_PROPERTIES
    },
    gridList: {
      name: "gridList",
      type: "GridWidget",
      parent: "grpbxSelectFromList",
      gridCellsOrder:
        "txtcolTypeNum,txtcolSegNum,txtcolWhseNum,txtcolBinNum,txtcolBinName,txtcolVariety,txtcolGeneration,txtcolOleic,txtcolOrganic,txtcolEdible,txtcolInvTon,txtcolCapTon",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridList_PROPERTIES

      // END_USER_CODE-USER_gridList_PROPERTIES
    },
    lblLoad: {
      name: "lblLoad",
      type: "LabelWidget",
      parent: "grpbxSelectFromList",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLoad_PROPERTIES

      // END_USER_CODE-USER_lblLoad_PROPERTIES
    },
    txtcolBinName: {
      name: "txtcolBinName",
      type: "TextBoxWidget",
      colName: "colBinName",
      parent: "gridList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBinName_PROPERTIES

      // END_USER_CODE-USER_txtcolBinName_PROPERTIES
    },
    txtcolBinNum: {
      name: "txtcolBinNum",
      type: "TextBoxWidget",
      colName: "colBinNum",
      parent: "gridList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBinNum_PROPERTIES

      // END_USER_CODE-USER_txtcolBinNum_PROPERTIES
    },
    txtcolCapTon: {
      name: "txtcolCapTon",
      type: "TextBoxWidget",
      colName: "colCapTon",
      parent: "gridList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCapTon_PROPERTIES

      // END_USER_CODE-USER_txtcolCapTon_PROPERTIES
    },
    txtcolEdible: {
      name: "txtcolEdible",
      type: "TextBoxWidget",
      colName: "colEdible",
      parent: "gridList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolEdible_PROPERTIES

      // END_USER_CODE-USER_txtcolEdible_PROPERTIES
    },
    txtcolGeneration: {
      name: "txtcolGeneration",
      type: "TextBoxWidget",
      colName: "colGeneration",
      parent: "gridList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGeneration_PROPERTIES

      // END_USER_CODE-USER_txtcolGeneration_PROPERTIES
    },
    txtcolInvTon: {
      name: "txtcolInvTon",
      type: "TextBoxWidget",
      colName: "colInvTon",
      parent: "gridList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInvTon_PROPERTIES

      // END_USER_CODE-USER_txtcolInvTon_PROPERTIES
    },
    txtcolOleic: {
      name: "txtcolOleic",
      type: "TextBoxWidget",
      colName: "colOleic",
      parent: "gridList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOleic_PROPERTIES

      // END_USER_CODE-USER_txtcolOleic_PROPERTIES
    },
    txtcolOrganic: {
      name: "txtcolOrganic",
      type: "TextBoxWidget",
      colName: "colOrganic",
      parent: "gridList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOrganic_PROPERTIES

      // END_USER_CODE-USER_txtcolOrganic_PROPERTIES
    },
    txtcolSegNum: {
      name: "txtcolSegNum",
      type: "TextBoxWidget",
      colName: "colSegNum",
      parent: "gridList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSegNum_PROPERTIES

      // END_USER_CODE-USER_txtcolSegNum_PROPERTIES
    },
    txtcolTypeNum: {
      name: "txtcolTypeNum",
      type: "TextBoxWidget",
      colName: "colTypeNum",
      parent: "gridList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTypeNum_PROPERTIES

      // END_USER_CODE-USER_txtcolTypeNum_PROPERTIES
    },
    txtcolVariety: {
      name: "txtcolVariety",
      type: "TextBoxWidget",
      colName: "colVariety",
      parent: "gridList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVariety_PROPERTIES

      // END_USER_CODE-USER_txtcolVariety_PROPERTIES
    },
    txtcolWhseNum: {
      name: "txtcolWhseNum",
      type: "TextBoxWidget",
      colName: "colWhseNum",
      parent: "gridList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWhseNum_PROPERTIES

      // END_USER_CODE-USER_txtcolWhseNum_PROPERTIES
    },
    grpbxSelectFromList: {
      name: "grpbxSelectFromList",
      type: "GroupBoxWidget",
      parent: "SelectFromList",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSelectFromList_PROPERTIES

      // END_USER_CODE-USER_grpbxSelectFromList_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "SelectFromList",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  //formLoad function
  const formLoad = async() => {
    try {
      let dataJs = getData(thisObj, 'dataForSelectFromList')

      //setting the screen header on formLoad
      if (dataJs.buttonName == 'From Bin') {
        let fromBinlbl = `${dataJs.buy_pt_id}: List of Warehouse-Bins`
        setValue(thisObj, 'lblLoad', fromBinlbl)
      }
      else if (dataJs.buttonName == 'To Bin') {
        if (dataJs.peanutType == 'RU') {
          dataJs.peanutType = 'Runner';
        }
        else if (dataJs.peanutType == 'SP') {
          dataJs.peanutType = 'Spanish';
        }
        else if (dataJs.peanutType == 'VL') {
          dataJs.peanutType = 'Valencia';
        }
        else if (dataJs.peanutType == 'VA') {
          dataJs.peanutType = 'Virginia';
        }

        if (dataJs.segNum == '1') {
          dataJs.segNum = 'Seg 1';
        }
        else if (dataJs.segNum == '2') {
          dataJs.segNum = 'Seg 2';
        }
        else if (dataJs.segNum == '3') {
          dataJs.segNum = 'Seg 3';
        }

        if (dataJs.organic == 'Y') {
          dataJs.organic = 'Organic';
        }
        else {
          dataJs.organic = 'Non Organic';
        }

        let toBinlbl = `${dataJs.buy_pt_id}: List of Warehouse-Bins [${dataJs.peanutType}, ${dataJs.segNum}, ${dataJs.organic}]`
        setValue(thisObj, 'lblLoad', toBinlbl)
      }

      let response = await StockTransferService.RetrieveWhouseBinInventoryList(dataJs.buy_pt_id)
      let js = [];
      if (response !== null && response !== undefined && response.length > 0) {
        let filterPeanutType = dataJs.mFilter?.split(",")[0]?.trim()?.slice(1)
        let filterSegType = dataJs.mFilter?.split(",")[1]?.trim()[4]
        let filterOrganic = dataJs.mFilter?.split(",")[2]?.trim()[0] == "O" ? "Y" : "N"
        
        let data = response;
        let obj = {};
        for (var i = 0; i < data.length; i++) {
          if (data[i].pnut_type_id == 'RU') {
            obj.txtcolTypeNum = 'Runner';
          }
          else if (data[i].pnut_type_id == 'SP') {
            obj.txtcolTypeNum = 'Spanish';
          }
          else if (data[i].pnut_type_id == 'VL') {
            obj.txtcolTypeNum = 'Valencia';
          }
          else if (data[i].pnut_type_id == 'VA') {
            obj.txtcolTypeNum = 'Virginia';
          }

          if (data[i].seg_type == '1') {
            obj.txtcolSegNum = 'Seg#1';
          }
          else if (data[i].seg_type == '2') {
            obj.txtcolSegNum = 'Seg#2';
          }
          else if (data[i].seg_type == '3') {
            obj.txtcolSegNum = 'Seg#3';
          }
          
          obj.txtcolWhseNum = data[i].whouse_num;
          obj.txtcolBinNum = data[i].bin_num;
          if (data[i].whouse_bin_id.includes('>=') && data[i].whouse_bin_id.includes('Reds/Fails')) {
            obj.txtcolBinName = data[i].whouse_bin_id.replace('>=', '&gt;=')
          }
          else if (data[i].whouse_bin_id.includes('<=')) {
            obj.txtcolBinName = data[i].whouse_bin_id.replace('<=', '&lt;=')
          }
          else if (data[i].whouse_bin_id.includes('In|Outâ€”')) {
            obj.txtcolBinName = data[i].whouse_bin_id.replace('â€”', '-')
          }
          else {
            obj.txtcolBinName = data[i].whouse_bin_id;
          }
        
          if(data[i].pnut_variety_id == '') {
            obj.txtcolVariety = 'Not Specified';
          }
          else {
            obj.txtcolVariety = data[i].pnut_variety_id
          }
           
          if(data[i].seed_gen == '') {
            obj.txtcolGeneration = 'Not Specified';
          }
          else if(data[i].seed_gen == 'F') {
            obj.txtcolGeneration = 'Foundation';
          }
          else if(data[i].seed_gen == 'R') {
            obj.txtcolGeneration = 'Registered';
          }
          else if(data[i].seed_gen == 'C') {
            obj.txtcolGeneration = 'Certified';
          }
          else {
            obj.txtcolGeneration = data[i].seed_gen;
          }

          if(data[i].oleic_ind == '') {
            obj.txtcolOleic = 'Not Specified';
          }
          else if(data[i].oleic_ind == 'H') {
            obj.txtcolOleic = 'High';
          }
          else if(data[i].oleic_ind == 'L') {
            obj.txtcolOleic = 'Low';
          }
           
          if(data[i].edible_oil_ind == '') {
            obj.txtcolEdible = 'Not Specified';
          }
          else if(data[i].edible_oil_ind == 'E') {
            obj.txtcolEdible = 'Edible';
          }
          else if(data[i].edible_oil_ind == 'O' || data[i].edible_oil_ind == 'C') {
            obj.txtcolEdible = 'Crushing';
          }
          else {
            obj.txtcolEdible = data[i].edible_oil_ind;
          }
          obj.txtcolOrganic = data[i].organic_ind;
          obj.txtcolInvTon = Number(data[i].physical_inv).toFixed(1);
          if (Number(data[i].physical_inv) == '' || Number(data[i].physical_inv) == '0') {
            obj.txtcolInvTon = '0.0';
          }
          obj.txtcolCapTon = Number(data[i].capacity_tons).toFixed(1);
          if (Number(data[i].capacity_tons) == '' || Number(data[i].capacity_tons) == '0') {
            obj.txtcolCapTon = '0.0';
          }
          
          if (dataJs.buttonName == 'To Bin'){
            if(data[i].seg_type == filterSegType && obj.txtcolTypeNum == filterPeanutType && (obj.txtcolOrganic == filterOrganic || filterOrganic=="Y")){
              js.push(obj)
            }
          }
          else{
            js.push(obj)
          }
          obj = {}
        }
      }
      setValue(thisObj, 'gridList', js);
      setLoading(false)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in Form Load"
        );
      }
      return false;
    }
  }

  //OK button functionality
  const onbtnOkClick = () => {
    try {
      let gridData = getValue(thisObj, 'gridList');
      let rowNum = getSelectedRowNumber(thisObj, 'gridList');
      let dataJs = getData(thisObj, 'dataForSelectFromList')
      if (rowNum == undefined) {
        return;
      }
      else {
        setData(thisObj, 'frmStockTransferInq', gridData[rowNum])
        setData(thisObj, "SelectedItem", dataJs.listData[rowNum])
        setData(thisObj, "closingOfSelectFromList", true)
        document.getElementById("StockTransfer_SelectFromListPopUp").childNodes[0].click()
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in OK button"
        );
      }
      return false;
    }
  }
  thisObj.onbtnOkClick = onbtnOkClick

  //Double click functionality
  const ongridListDblclick = () => {
    try{
      onbtnOkClick()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in ongridListDblclick"
        );
      }
      return false;
    }
  }
  thisObj.ongridListDblclick = ongridListDblclick

  // Close button functionality
  const onbtnCancelClick = () => {
    try {
      document.getElementById("StockTransfer_SelectFromListPopUp").childNodes[0].click()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SelectFromList*/}

              {/* END_USER_CODE-USER_BEFORE_SelectFromList*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSelectFromList*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSelectFromList*/}

              <GroupBoxWidget
                conf={state.grpbxSelectFromList}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_gridList*/}

                {/* END_USER_CODE-USER_BEFORE_gridList*/}
                <LabelWidget
                        values={values}
                        conf={state.lblLoad}
                        screenConf={state}
                      ></LabelWidget>
                <GridWidget
                  conf={state.gridList}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridList}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridList*/}

                {/* END_USER_CODE-USER_AFTER_gridList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSelectFromList*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSelectFromList*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_SelectFromList*/}

              {/* END_USER_CODE-USER_AFTER_SelectFromList*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(StockTransfer_SelectFromList);
