import React, { Component } from "react";
import Overlay from "react-bootstrap/Overlay";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Popover from "react-bootstrap/Popover";
import "./PopoverActions.scss";

class PopoverActions extends Component {
  ref = React.createRef();

  state = {
    activeMenu: this.props.editable ? "actionList" : "columnContext",
    show: false,
    target: null,
  };

  setActiveMenu = (activeMenu) => {
    this.setState({ activeMenu });
  };

  togglePopover = (e) => {
    this.setState({ show: !this.state.show, target: e.target });
  };

  render() {
    let actionList;
    let columnContext;
    let activeMenu = this.state.activeMenu;
    this.actionList = this.props.actionList;
    this.okCancelButtons = this.props.okCancelButtons;
    this.columns = this.props.columns.filter((col) => !col.isStatusColumn);

    if (activeMenu === "actionList") {
      let reqActionList = this.actionList.filter((action) => action.required);
      actionList = reqActionList.map((action) => {
        return (
          <div
            key={action.actionName}
            className="action-item"
          >
            <Button
              variant="link"
              size="sm"
              onClick={() => {
                this.setState({ show: false });
                action.clickEvent();
              }}
            >
              <i
                className={"fa fa-" + action.iconName}
                aria-hidden="true"
              ></i>
              {action.actionName}
            </Button>
          </div>
        );
      });
    } else if (activeMenu === "columnContext") {
      columnContext = (
        <div className="columns-parent">
          {this.columns.map((column) => {
            return (
              <div
                key={column.name}
                className="checkbox"
              >
                <label>
                  <input
                    type="checkbox"
                    checked={column.Visible}
                    onChange={() => this.props.setColVisibility(column)}
                  />
                  <span>{column.Label}</span>
                </label>
              </div>
            );
          })}
        </div>
      );
    }

    const popover = (
      <Popover className="popover-actions">
        <Popover.Content>
          <ButtonGroup
            size="sm"
            aria-label="..."
          >
            {this.props.editable && (
              <Button
                variant="secondary"
                onClick={() => {
                  this.setActiveMenu("actionList");
                }}
                className={
                  this.state.activeMenu === "actionList" ? "active" : ""
                }
              >
                <i
                  className="fa fa-bars"
                  aria-hidden="true"
                ></i>
              </Button>
            )}

            <Button
              variant="secondary"
              onClick={() => {
                this.setActiveMenu("columnContext");
              }}
              className={
                this.state.activeMenu === "columnContext" ? "active" : ""
              }
            >
              <i
                className="fa fa-th-list"
                aria-hidden="true"
              ></i>
            </Button>
          </ButtonGroup>
          <hr />
          {this.props.editable && actionList}
          {columnContext}
        </Popover.Content>
      </Popover>
    );

    return (
      <React.Fragment>
        {this.okCancelButtons.length > 0 && (
          <ButtonGroup
            size="sm"
            className="ok-cancel"
            aria-label="..."
          >
            {this.okCancelButtons.map((button) => (
              <Button
                key={button.icon}
                variant="default"
                onClick={() => button.clickEvent(this.isSubDetails)}
              >
                <i
                  className={"fa fa-" + button.icon}
                  aria-hidden="true"
                ></i>
              </Button>
            ))}
          </ButtonGroup>
        )}
        <div ref={this.ref}>
          <Button
            variant="link"
            className="action-list"
            onClick={this.togglePopover}
          >
            <i
              className="fa fa-bars"
              aria-hidden="true"
            ></i>
          </Button>

          <Overlay
            show={this.state.show}
            target={this.state.target}
            placement="left"
            container={this.ref.current}
            containerPadding={20}
          >
            {popover}
          </Overlay>
        </div>
      </React.Fragment>
    );
  }
}

export default PopoverActions;
