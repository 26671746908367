/* eslint-disable*/
import React from "react";
import StockTransfer_FrtAccrlSettlement from "./FrtAccrlSettlement";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FrtAccrlSettlement Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_FrtAccrlSettlement />);
    });
  });
  afterEach(cleanup);
  test("is FrtAccrlSettlement Loads Successfully", () => {
    expect(screen.getByText("FrtAccrlSettlement")).toBeInTheDocument;
  });
  test("Custom Test Cases for FrtAccrlSettlement", () => {
    // START_USER_CODE-USER_FrtAccrlSettlement_Custom_Test_Case
    // END_USER_CODE-USER_FrtAccrlSettlement_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_FrtAccrlSettlement />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnPrintChecks(Button Widget) Test Cases", async () => {
    const btnPrintChecks = screen.getByTestId("btnPrintChecks");
    expect(btnPrintChecks).toBeInTheDocument;
    expect(btnPrintChecks.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_btnPrintChecks")
    );
  });
  test("Custom Test Cases for btnPrintChecks", () => {
    // START_USER_CODE-USER_btnPrintChecks_TEST
    // END_USER_CODE-USER_btnPrintChecks_TEST
  });
  test("btnReleasetoRTP(Button Widget) Test Cases", async () => {
    const btnReleasetoRTP = screen.getByTestId("btnReleasetoRTP");
    expect(btnReleasetoRTP).toBeInTheDocument;
    expect(btnReleasetoRTP.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_btnReleasetoRTP")
    );
  });
  test("Custom Test Cases for btnReleasetoRTP", () => {
    // START_USER_CODE-USER_btnReleasetoRTP_TEST
    // END_USER_CODE-USER_btnReleasetoRTP_TEST
  });
  test("btnRemove(Button Widget) Test Cases", async () => {
    const btnRemove = screen.getByTestId("btnRemove");
    expect(btnRemove).toBeInTheDocument;
    expect(btnRemove.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_btnRemove")
    );
  });
  test("Custom Test Cases for btnRemove", () => {
    // START_USER_CODE-USER_btnRemove_TEST
    // END_USER_CODE-USER_btnRemove_TEST
  });
  test("btnSavePreviewSettlement(Button Widget) Test Cases", async () => {
    const btnSavePreviewSettlement = screen.getByTestId(
      "btnSavePreviewSettlement"
    );
    expect(btnSavePreviewSettlement).toBeInTheDocument;
    expect(btnSavePreviewSettlement.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_btnSavePreviewSettlement")
    );
  });
  test("Custom Test Cases for btnSavePreviewSettlement", () => {
    // START_USER_CODE-USER_btnSavePreviewSettlement_TEST
    // END_USER_CODE-USER_btnSavePreviewSettlement_TEST
  });
  test("btnSaveToBatch(Button Widget) Test Cases", async () => {
    const btnSaveToBatch = screen.getByTestId("btnSaveToBatch");
    expect(btnSaveToBatch).toBeInTheDocument;
    expect(btnSaveToBatch.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_btnSaveToBatch")
    );
  });
  test("Custom Test Cases for btnSaveToBatch", () => {
    // START_USER_CODE-USER_btnSaveToBatch_TEST
    // END_USER_CODE-USER_btnSaveToBatch_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridHoldFA(Grid Widget) Test Cases", async () => {
    let gridHoldFA = screen.getByTestId("gridHoldFA");
    let gridHoldFAbtn = gridHoldFA.nextElementSibling.firstElementChild;
    gridHoldFA = gridHoldFA.parentElement.parentElement.parentElement;
    expect(gridHoldFA.tagName).toBe("DIV");
    expect(gridHoldFA.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridHoldFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridHoldFA", () => {
    // START_USER_CODE-USER_gridHoldFA_TEST
    // END_USER_CODE-USER_gridHoldFA_TEST
  });
  test("gridReleaseFA(Grid Widget) Test Cases", async () => {
    let gridReleaseFA = screen.getByTestId("gridReleaseFA");
    let gridReleaseFAbtn = gridReleaseFA.nextElementSibling.firstElementChild;
    gridReleaseFA = gridReleaseFA.parentElement.parentElement.parentElement;
    expect(gridReleaseFA.tagName).toBe("DIV");
    expect(gridReleaseFA.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridReleaseFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridReleaseFA", () => {
    // START_USER_CODE-USER_gridReleaseFA_TEST
    // END_USER_CODE-USER_gridReleaseFA_TEST
  });
  test("grpbxAction(GroupBox Widget) Test Cases", async () => {
    const grpbxAction = screen.getByTestId("grpbxAction");
    expect(grpbxAction.tagName).toBe("BUTTON");
    expect(grpbxAction.type).toBe("button");
    expect(grpbxAction.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAction", () => {
    // START_USER_CODE-USER_grpbxAction_TEST
    // END_USER_CODE-USER_grpbxAction_TEST
  });
  test("grpbxCheckInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxCheckInfo = screen.getByTestId("grpbxCheckInfo");
    expect(grpbxCheckInfo.tagName).toBe("BUTTON");
    expect(grpbxCheckInfo.type).toBe("button");
    expect(grpbxCheckInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCheckInfo", () => {
    // START_USER_CODE-USER_grpbxCheckInfo_TEST
    // END_USER_CODE-USER_grpbxCheckInfo_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("grpbxFrtAccrlSettlement(GroupBox Widget) Test Cases", async () => {
    const grpbxFrtAccrlSettlement = screen.getByTestId(
      "grpbxFrtAccrlSettlement"
    );
    expect(grpbxFrtAccrlSettlement.tagName).toBe("BUTTON");
    expect(grpbxFrtAccrlSettlement.type).toBe("button");
    expect(grpbxFrtAccrlSettlement.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFrtAccrlSettlement", () => {
    // START_USER_CODE-USER_grpbxFrtAccrlSettlement_TEST
    // END_USER_CODE-USER_grpbxFrtAccrlSettlement_TEST
  });
  test("grpbxInvoiceInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxInvoiceInfo = screen.getByTestId("grpbxInvoiceInfo");
    expect(grpbxInvoiceInfo.tagName).toBe("BUTTON");
    expect(grpbxInvoiceInfo.type).toBe("button");
    expect(grpbxInvoiceInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxInvoiceInfo", () => {
    // START_USER_CODE-USER_grpbxInvoiceInfo_TEST
    // END_USER_CODE-USER_grpbxInvoiceInfo_TEST
  });
  test("grpbxSearchResults(GroupBox Widget) Test Cases", async () => {
    const grpbxSearchResults = screen.getByTestId("grpbxSearchResults");
    expect(grpbxSearchResults.tagName).toBe("BUTTON");
    expect(grpbxSearchResults.type).toBe("button");
    expect(grpbxSearchResults.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSearchResults", () => {
    // START_USER_CODE-USER_grpbxSearchResults_TEST
    // END_USER_CODE-USER_grpbxSearchResults_TEST
  });
  test("labelwidget7(Label Widget) Test Cases", async () => {
    const labelwidget7 = screen.getByTestId("labelwidget7");
    expect(labelwidget7.tagName).toBe("LABEL");
    expect(labelwidget7.classList).toContain("form-label");
    expect(labelwidget7.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_labelwidget7")
    );
  });
  test("Custom Test Cases for labelwidget7", () => {
    // START_USER_CODE-USER_labelwidget7_TEST
    // END_USER_CODE-USER_labelwidget7_TEST
  });
  test("lblBalance(Label Widget) Test Cases", async () => {
    const lblBalance = screen.getByTestId("lblBalance");
    expect(lblBalance.tagName).toBe("LABEL");
    expect(lblBalance.classList).toContain("form-label");
    expect(lblBalance.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_lblBalance")
    );
  });
  test("Custom Test Cases for lblBalance", () => {
    // START_USER_CODE-USER_lblBalance_TEST
    // END_USER_CODE-USER_lblBalance_TEST
  });
  test("lblCheckInformation(Label Widget) Test Cases", async () => {
    const lblCheckInformation = screen.getByTestId("lblCheckInformation");
    expect(lblCheckInformation.tagName).toBe("LABEL");
    expect(lblCheckInformation.classList).toContain("form-label");
    expect(lblCheckInformation.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_lblCheckInformation")
    );
  });
  test("Custom Test Cases for lblCheckInformation", () => {
    // START_USER_CODE-USER_lblCheckInformation_TEST
    // END_USER_CODE-USER_lblCheckInformation_TEST
  });
  test("lblNoteRedPay(Label Widget) Test Cases", async () => {
    const lblNoteRedPay = screen.getByTestId("lblNoteRedPay");
    expect(lblNoteRedPay.tagName).toBe("LABEL");
    expect(lblNoteRedPay.classList).toContain("form-label");
    expect(lblNoteRedPay.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_lblNoteRedPay")
    );
  });
  test("Custom Test Cases for lblNoteRedPay", () => {
    // START_USER_CODE-USER_lblNoteRedPay_TEST
    // END_USER_CODE-USER_lblNoteRedPay_TEST
  });
  test("txtARBalance(Textbox Widget) Test Cases", async () => {
    const txtARBalance = screen.getByTestId("txtARBalance");
    expect(txtARBalance.tagName).toBe("INPUT");
    expect(txtARBalance.type).toBe("text");
    expect(txtARBalance.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtARBalance, "123");
    });
    expect(txtARBalance.getAttribute("value")).toBe("");
    expect(txtARBalance.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtARBalance", () => {
    // START_USER_CODE-USER_txtARBalance_TEST
    // END_USER_CODE-USER_txtARBalance_TEST
  });
  test("txtCarrierAbbrNum(Textbox Widget) Test Cases", async () => {
    const txtCarrierAbbrNum = screen.getByTestId("txtCarrierAbbrNum");
    expect(txtCarrierAbbrNum.tagName).toBe("INPUT");
    expect(txtCarrierAbbrNum.type).toBe("text");
    expect(txtCarrierAbbrNum.classList).toContain("textboxWidgetClass");
    expect(txtCarrierAbbrNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_txtCarrierAbbrNum")
    );
    await act(async () => {
      userEvent.type(txtCarrierAbbrNum, "1");
    });
  });
  test("Custom Test Cases for txtCarrierAbbrNum", () => {
    // START_USER_CODE-USER_txtCarrierAbbrNum_TEST
    // END_USER_CODE-USER_txtCarrierAbbrNum_TEST
  });
  test("txtCheckNumber(Textbox Widget) Test Cases", async () => {
    const txtCheckNumber = screen.getByTestId("txtCheckNumber");
    expect(txtCheckNumber.tagName).toBe("INPUT");
    expect(txtCheckNumber.type).toBe("text");
    expect(txtCheckNumber.classList).toContain("textboxWidgetClass");
    expect(txtCheckNumber.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_txtCheckNumber")
    );
    await act(async () => {
      userEvent.type(txtCheckNumber, "1");
    });
  });
  test("Custom Test Cases for txtCheckNumber", () => {
    // START_USER_CODE-USER_txtCheckNumber_TEST
    // END_USER_CODE-USER_txtCheckNumber_TEST
  });
  test("gridHoldFA_txtcolCropYearH(Grid Widget) Test Cases", async () => {
    let gridHoldFA_txtcolCropYearH = screen.getByTestId("gridHoldFA");
    let gridHoldFA_txtcolCropYearHbtn =
      gridHoldFA_txtcolCropYearH.nextElementSibling.firstElementChild;
    gridHoldFA_txtcolCropYearH =
      gridHoldFA_txtcolCropYearH.parentElement.parentElement.parentElement;
    expect(gridHoldFA_txtcolCropYearH.tagName).toBe("DIV");
    expect(gridHoldFA_txtcolCropYearH.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridHoldFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCropYearH", () => {
    // START_USER_CODE-USER_txtcolCropYearH_TEST
    // END_USER_CODE-USER_txtcolCropYearH_TEST
  });
  test("gridReleaseFA_txtcolCropYearR(Grid Widget) Test Cases", async () => {
    let gridReleaseFA_txtcolCropYearR = screen.getByTestId("gridReleaseFA");
    let gridReleaseFA_txtcolCropYearRbtn =
      gridReleaseFA_txtcolCropYearR.nextElementSibling.firstElementChild;
    gridReleaseFA_txtcolCropYearR =
      gridReleaseFA_txtcolCropYearR.parentElement.parentElement.parentElement;
    expect(gridReleaseFA_txtcolCropYearR.tagName).toBe("DIV");
    expect(gridReleaseFA_txtcolCropYearR.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridReleaseFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCropYearR", () => {
    // START_USER_CODE-USER_txtcolCropYearR_TEST
    // END_USER_CODE-USER_txtcolCropYearR_TEST
  });
  test("txtCollectAR(Textbox Widget) Test Cases", async () => {
    const txtCollectAR = screen.getByTestId("txtCollectAR");
    expect(txtCollectAR.tagName).toBe("INPUT");
    expect(txtCollectAR.type).toBe("text");
    expect(txtCollectAR.classList).toContain("textboxWidgetClass");
    expect(txtCollectAR.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_txtCollectAR")
    );
    await act(async () => {
      userEvent.type(txtCollectAR, "1");
    });
  });
  test("Custom Test Cases for txtCollectAR", () => {
    // START_USER_CODE-USER_txtCollectAR_TEST
    // END_USER_CODE-USER_txtCollectAR_TEST
  });
  test("txtCollectCR(Textbox Widget) Test Cases", async () => {
    const txtCollectCR = screen.getByTestId("txtCollectCR");
    expect(txtCollectCR.tagName).toBe("INPUT");
    expect(txtCollectCR.type).toBe("text");
    expect(txtCollectCR.classList).toContain("textboxWidgetClass");
    expect(txtCollectCR.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_txtCollectCR")
    );
    await act(async () => {
      userEvent.type(txtCollectCR, "1");
    });
  });
  test("Custom Test Cases for txtCollectCR", () => {
    // START_USER_CODE-USER_txtCollectCR_TEST
    // END_USER_CODE-USER_txtCollectCR_TEST
  });
  test("txtCollectSR(Textbox Widget) Test Cases", async () => {
    const txtCollectSR = screen.getByTestId("txtCollectSR");
    expect(txtCollectSR.tagName).toBe("INPUT");
    expect(txtCollectSR.type).toBe("text");
    expect(txtCollectSR.classList).toContain("textboxWidgetClass");
    expect(txtCollectSR.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_txtCollectSR")
    );
    await act(async () => {
      userEvent.type(txtCollectSR, "1");
    });
  });
  test("Custom Test Cases for txtCollectSR", () => {
    // START_USER_CODE-USER_txtCollectSR_TEST
    // END_USER_CODE-USER_txtCollectSR_TEST
  });
  test("gridHoldFA_txtcolPayType(Grid Widget) Test Cases", async () => {
    let gridHoldFA_txtcolPayType = screen.getByTestId("gridHoldFA");
    let gridHoldFA_txtcolPayTypebtn =
      gridHoldFA_txtcolPayType.nextElementSibling.firstElementChild;
    gridHoldFA_txtcolPayType =
      gridHoldFA_txtcolPayType.parentElement.parentElement.parentElement;
    expect(gridHoldFA_txtcolPayType.tagName).toBe("DIV");
    expect(gridHoldFA_txtcolPayType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridHoldFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPayType", () => {
    // START_USER_CODE-USER_txtcolPayType_TEST
    // END_USER_CODE-USER_txtcolPayType_TEST
  });
  test("gridReleaseFA_txtcolPayTypeR(Grid Widget) Test Cases", async () => {
    let gridReleaseFA_txtcolPayTypeR = screen.getByTestId("gridReleaseFA");
    let gridReleaseFA_txtcolPayTypeRbtn =
      gridReleaseFA_txtcolPayTypeR.nextElementSibling.firstElementChild;
    gridReleaseFA_txtcolPayTypeR =
      gridReleaseFA_txtcolPayTypeR.parentElement.parentElement.parentElement;
    expect(gridReleaseFA_txtcolPayTypeR.tagName).toBe("DIV");
    expect(gridReleaseFA_txtcolPayTypeR.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridReleaseFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPayTypeR", () => {
    // START_USER_CODE-USER_txtcolPayTypeR_TEST
    // END_USER_CODE-USER_txtcolPayTypeR_TEST
  });
  test("gridHoldFA_txtcolProceedH(Grid Widget) Test Cases", async () => {
    let gridHoldFA_txtcolProceedH = screen.getByTestId("gridHoldFA");
    let gridHoldFA_txtcolProceedHbtn =
      gridHoldFA_txtcolProceedH.nextElementSibling.firstElementChild;
    gridHoldFA_txtcolProceedH =
      gridHoldFA_txtcolProceedH.parentElement.parentElement.parentElement;
    expect(gridHoldFA_txtcolProceedH.tagName).toBe("DIV");
    expect(gridHoldFA_txtcolProceedH.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridHoldFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProceedH", () => {
    // START_USER_CODE-USER_txtcolProceedH_TEST
    // END_USER_CODE-USER_txtcolProceedH_TEST
  });
  test("gridReleaseFA_txtcolProceedR(Grid Widget) Test Cases", async () => {
    let gridReleaseFA_txtcolProceedR = screen.getByTestId("gridReleaseFA");
    let gridReleaseFA_txtcolProceedRbtn =
      gridReleaseFA_txtcolProceedR.nextElementSibling.firstElementChild;
    gridReleaseFA_txtcolProceedR =
      gridReleaseFA_txtcolProceedR.parentElement.parentElement.parentElement;
    expect(gridReleaseFA_txtcolProceedR.tagName).toBe("DIV");
    expect(gridReleaseFA_txtcolProceedR.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridReleaseFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProceedR", () => {
    // START_USER_CODE-USER_txtcolProceedR_TEST
    // END_USER_CODE-USER_txtcolProceedR_TEST
  });
  test("gridHoldFA_txtcolRecBuyPtH(Grid Widget) Test Cases", async () => {
    let gridHoldFA_txtcolRecBuyPtH = screen.getByTestId("gridHoldFA");
    let gridHoldFA_txtcolRecBuyPtHbtn =
      gridHoldFA_txtcolRecBuyPtH.nextElementSibling.firstElementChild;
    gridHoldFA_txtcolRecBuyPtH =
      gridHoldFA_txtcolRecBuyPtH.parentElement.parentElement.parentElement;
    expect(gridHoldFA_txtcolRecBuyPtH.tagName).toBe("DIV");
    expect(gridHoldFA_txtcolRecBuyPtH.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridHoldFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecBuyPtH", () => {
    // START_USER_CODE-USER_txtcolRecBuyPtH_TEST
    // END_USER_CODE-USER_txtcolRecBuyPtH_TEST
  });
  test("gridReleaseFA_txtcolRecBuyPtR(Grid Widget) Test Cases", async () => {
    let gridReleaseFA_txtcolRecBuyPtR = screen.getByTestId("gridReleaseFA");
    let gridReleaseFA_txtcolRecBuyPtRbtn =
      gridReleaseFA_txtcolRecBuyPtR.nextElementSibling.firstElementChild;
    gridReleaseFA_txtcolRecBuyPtR =
      gridReleaseFA_txtcolRecBuyPtR.parentElement.parentElement.parentElement;
    expect(gridReleaseFA_txtcolRecBuyPtR.tagName).toBe("DIV");
    expect(gridReleaseFA_txtcolRecBuyPtR.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridReleaseFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecBuyPtR", () => {
    // START_USER_CODE-USER_txtcolRecBuyPtR_TEST
    // END_USER_CODE-USER_txtcolRecBuyPtR_TEST
  });
  test("gridHoldFA_txtcolShpBuyPtH(Grid Widget) Test Cases", async () => {
    let gridHoldFA_txtcolShpBuyPtH = screen.getByTestId("gridHoldFA");
    let gridHoldFA_txtcolShpBuyPtHbtn =
      gridHoldFA_txtcolShpBuyPtH.nextElementSibling.firstElementChild;
    gridHoldFA_txtcolShpBuyPtH =
      gridHoldFA_txtcolShpBuyPtH.parentElement.parentElement.parentElement;
    expect(gridHoldFA_txtcolShpBuyPtH.tagName).toBe("DIV");
    expect(gridHoldFA_txtcolShpBuyPtH.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridHoldFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShpBuyPtH", () => {
    // START_USER_CODE-USER_txtcolShpBuyPtH_TEST
    // END_USER_CODE-USER_txtcolShpBuyPtH_TEST
  });
  test("gridReleaseFA_txtcolShpBuyPtR(Grid Widget) Test Cases", async () => {
    let gridReleaseFA_txtcolShpBuyPtR = screen.getByTestId("gridReleaseFA");
    let gridReleaseFA_txtcolShpBuyPtRbtn =
      gridReleaseFA_txtcolShpBuyPtR.nextElementSibling.firstElementChild;
    gridReleaseFA_txtcolShpBuyPtR =
      gridReleaseFA_txtcolShpBuyPtR.parentElement.parentElement.parentElement;
    expect(gridReleaseFA_txtcolShpBuyPtR.tagName).toBe("DIV");
    expect(gridReleaseFA_txtcolShpBuyPtR.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridReleaseFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShpBuyPtR", () => {
    // START_USER_CODE-USER_txtcolShpBuyPtR_TEST
    // END_USER_CODE-USER_txtcolShpBuyPtR_TEST
  });
  test("gridHoldFA_txtcolTransferNumH(Grid Widget) Test Cases", async () => {
    let gridHoldFA_txtcolTransferNumH = screen.getByTestId("gridHoldFA");
    let gridHoldFA_txtcolTransferNumHbtn =
      gridHoldFA_txtcolTransferNumH.nextElementSibling.firstElementChild;
    gridHoldFA_txtcolTransferNumH =
      gridHoldFA_txtcolTransferNumH.parentElement.parentElement.parentElement;
    expect(gridHoldFA_txtcolTransferNumH.tagName).toBe("DIV");
    expect(gridHoldFA_txtcolTransferNumH.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridHoldFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTransferNumH", () => {
    // START_USER_CODE-USER_txtcolTransferNumH_TEST
    // END_USER_CODE-USER_txtcolTransferNumH_TEST
  });
  test("gridReleaseFA_txtcolTransferNumR(Grid Widget) Test Cases", async () => {
    let gridReleaseFA_txtcolTransferNumR = screen.getByTestId("gridReleaseFA");
    let gridReleaseFA_txtcolTransferNumRbtn =
      gridReleaseFA_txtcolTransferNumR.nextElementSibling.firstElementChild;
    gridReleaseFA_txtcolTransferNumR =
      gridReleaseFA_txtcolTransferNumR.parentElement.parentElement
        .parentElement;
    expect(gridReleaseFA_txtcolTransferNumR.tagName).toBe("DIV");
    expect(gridReleaseFA_txtcolTransferNumR.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSettlement_gridReleaseFA"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTransferNumR", () => {
    // START_USER_CODE-USER_txtcolTransferNumR_TEST
    // END_USER_CODE-USER_txtcolTransferNumR_TEST
  });
  test("txtCRBalance(Textbox Widget) Test Cases", async () => {
    const txtCRBalance = screen.getByTestId("txtCRBalance");
    expect(txtCRBalance.tagName).toBe("INPUT");
    expect(txtCRBalance.type).toBe("text");
    expect(txtCRBalance.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCRBalance, "123");
    });
    expect(txtCRBalance.getAttribute("value")).toBe("");
    expect(txtCRBalance.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCRBalance", () => {
    // START_USER_CODE-USER_txtCRBalance_TEST
    // END_USER_CODE-USER_txtCRBalance_TEST
  });
  test("txtEndCheckNumber(Textbox Widget) Test Cases", async () => {
    const txtEndCheckNumber = screen.getByTestId("txtEndCheckNumber");
    expect(txtEndCheckNumber.tagName).toBe("INPUT");
    expect(txtEndCheckNumber.type).toBe("text");
    expect(txtEndCheckNumber.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtEndCheckNumber, "1");
    });
  });
  test("Custom Test Cases for txtEndCheckNumber", () => {
    // START_USER_CODE-USER_txtEndCheckNumber_TEST
    // END_USER_CODE-USER_txtEndCheckNumber_TEST
  });
  test("txtFAOnHold(Textbox Widget) Test Cases", async () => {
    const txtFAOnHold = screen.getByTestId("txtFAOnHold");
    expect(txtFAOnHold.tagName).toBe("INPUT");
    expect(txtFAOnHold.type).toBe("text");
    expect(txtFAOnHold.classList).toContain("textboxWidgetClass");
    expect(txtFAOnHold.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_txtFAOnHold")
    );
    await act(async () => {
      userEvent.type(txtFAOnHold, "123");
    });
    expect(txtFAOnHold.getAttribute("value")).toBe("");
    expect(txtFAOnHold.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFAOnHold", () => {
    // START_USER_CODE-USER_txtFAOnHold_TEST
    // END_USER_CODE-USER_txtFAOnHold_TEST
  });
  test("txtFAToPays(Textbox Widget) Test Cases", async () => {
    const txtFAToPays = screen.getByTestId("txtFAToPays");
    expect(txtFAToPays.tagName).toBe("INPUT");
    expect(txtFAToPays.type).toBe("text");
    expect(txtFAToPays.classList).toContain("textboxWidgetClass");
    expect(txtFAToPays.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_txtFAToPays")
    );
    await act(async () => {
      userEvent.type(txtFAToPays, "123");
    });
    expect(txtFAToPays.getAttribute("value")).toBe("");
    expect(txtFAToPays.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFAToPays", () => {
    // START_USER_CODE-USER_txtFAToPays_TEST
    // END_USER_CODE-USER_txtFAToPays_TEST
  });
  test("txtInvoiceNum(Textbox Widget) Test Cases", async () => {
    const txtInvoiceNum = screen.getByTestId("txtInvoiceNum");
    expect(txtInvoiceNum.tagName).toBe("INPUT");
    expect(txtInvoiceNum.type).toBe("text");
    expect(txtInvoiceNum.classList).toContain("textboxWidgetClass");
    expect(txtInvoiceNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_txtInvoiceNum")
    );
    await act(async () => {
      userEvent.type(txtInvoiceNum, "1");
    });
  });
  test("Custom Test Cases for txtInvoiceNum", () => {
    // START_USER_CODE-USER_txtInvoiceNum_TEST
    // END_USER_CODE-USER_txtInvoiceNum_TEST
  });
  test("txtSRBalance(Textbox Widget) Test Cases", async () => {
    const txtSRBalance = screen.getByTestId("txtSRBalance");
    expect(txtSRBalance.tagName).toBe("INPUT");
    expect(txtSRBalance.type).toBe("text");
    expect(txtSRBalance.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSRBalance, "123");
    });
    expect(txtSRBalance.getAttribute("value")).toBe("");
    expect(txtSRBalance.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSRBalance", () => {
    // START_USER_CODE-USER_txtSRBalance_TEST
    // END_USER_CODE-USER_txtSRBalance_TEST
  });
  test("txtValueOfCheck(Textbox Widget) Test Cases", async () => {
    const txtValueOfCheck = screen.getByTestId("txtValueOfCheck");
    expect(txtValueOfCheck.tagName).toBe("INPUT");
    expect(txtValueOfCheck.type).toBe("text");
    expect(txtValueOfCheck.classList).toContain("textboxWidgetClass");
    expect(txtValueOfCheck.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_txtValueOfCheck")
    );
    await act(async () => {
      userEvent.type(txtValueOfCheck, "123");
    });
    expect(txtValueOfCheck.getAttribute("value")).toBe("");
    expect(txtValueOfCheck.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValueOfCheck", () => {
    // START_USER_CODE-USER_txtValueOfCheck_TEST
    // END_USER_CODE-USER_txtValueOfCheck_TEST
  });
  test("txtValueOfRTPs(Textbox Widget) Test Cases", async () => {
    const txtValueOfRTPs = screen.getByTestId("txtValueOfRTPs");
    expect(txtValueOfRTPs.tagName).toBe("INPUT");
    expect(txtValueOfRTPs.type).toBe("text");
    expect(txtValueOfRTPs.classList).toContain("textboxWidgetClass");
    expect(txtValueOfRTPs.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_txtValueOfRTPs")
    );
    await act(async () => {
      userEvent.type(txtValueOfRTPs, "123");
    });
    expect(txtValueOfRTPs.getAttribute("value")).toBe("");
    expect(txtValueOfRTPs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValueOfRTPs", () => {
    // START_USER_CODE-USER_txtValueOfRTPs_TEST
    // END_USER_CODE-USER_txtValueOfRTPs_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor, "123");
    });
    expect(txtVendor.getAttribute("value")).toBe("");
    expect(txtVendor.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVendorNum(Textbox Widget) Test Cases", async () => {
    const txtVendorNum = screen.getByTestId("txtVendorNum");
    expect(txtVendorNum.tagName).toBe("INPUT");
    expect(txtVendorNum.type).toBe("text");
    expect(txtVendorNum.classList).toContain("textboxWidgetClass");
    expect(txtVendorNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSettlement_txtVendorNum")
    );
    await act(async () => {
      userEvent.type(txtVendorNum, "1");
    });
  });
  test("Custom Test Cases for txtVendorNum", () => {
    // START_USER_CODE-USER_txtVendorNum_TEST
    // END_USER_CODE-USER_txtVendorNum_TEST
  });
});
