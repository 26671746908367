/* eslint-disable*/
import React from "react";
import ContractManagement_FlexMktVoid from "./FlexMktVoid";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FlexMktVoid Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_FlexMktVoid />);
    });
  });
  afterEach(cleanup);
  test("is FlexMktVoid Loads Successfully", () => {
    expect(screen.getByText("VoidPriceElection")).toBeInTheDocument;
  });
  test("Custom Test Cases for FlexMktVoid", () => {
    // START_USER_CODE-USER_FlexMktVoid_Custom_Test_Case
    // END_USER_CODE-USER_FlexMktVoid_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_FlexMktVoid />);
    });
  });
  afterEach(cleanup);
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("ContractManagement:FlexMktVoid_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnVoid(Button Widget) Test Cases", async () => {
    const btnVoid = screen.getByTestId("btnVoid");
    expect(btnVoid).toBeInTheDocument;
    expect(btnVoid.textContent).toEqual(
      t("ContractManagement:FlexMktVoid_btnVoid")
    );
  });
  test("Custom Test Cases for btnVoid", () => {
    // START_USER_CODE-USER_btnVoid_TEST
    // END_USER_CODE-USER_btnVoid_TEST
  });
  test("grpbxCntrctPrc(GroupBox Widget) Test Cases", async () => {
    const grpbxCntrctPrc = screen.getByTestId("grpbxCntrctPrc");
    expect(grpbxCntrctPrc.tagName).toBe("BUTTON");
    expect(grpbxCntrctPrc.type).toBe("button");
    expect(grpbxCntrctPrc.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCntrctPrc", () => {
    // START_USER_CODE-USER_grpbxCntrctPrc_TEST
    // END_USER_CODE-USER_grpbxCntrctPrc_TEST
  });
  test("lblContractPricing(Textbox Widget) Test Cases", async () => {
    const lblContractPricing = screen.getByTestId("lblContractPricing");
    expect(lblContractPricing.tagName).toBe("INPUT");
    expect(lblContractPricing.type).toBe("text");
    expect(lblContractPricing.classList).toContain("textboxWidgetClass");
    expect(lblContractPricing.previousElementSibling.textContent).toEqual(
      t("ContractManagement:FlexMktVoid_lblContractPricing")
    );
    await act(async () => {
      userEvent.type(lblContractPricing, "1");
    });
  });
  test("Custom Test Cases for lblContractPricing", () => {
    // START_USER_CODE-USER_lblContractPricing_TEST
    // END_USER_CODE-USER_lblContractPricing_TEST
  });
  test("lblEnterPricingCntrct(Label Widget) Test Cases", async () => {
    const lblEnterPricingCntrct = screen.getByTestId("lblEnterPricingCntrct");
    expect(lblEnterPricingCntrct.tagName).toBe("LABEL");
    expect(lblEnterPricingCntrct.classList).toContain("form-label");
    expect(lblEnterPricingCntrct.textContent).toEqual(
      t("ContractManagement:FlexMktVoid_lblEnterPricingCntrct")
    );
  });
  test("Custom Test Cases for lblEnterPricingCntrct", () => {
    // START_USER_CODE-USER_lblEnterPricingCntrct_TEST
    // END_USER_CODE-USER_lblEnterPricingCntrct_TEST
  });
  test("txtCntrctPrc(Textbox Widget) Test Cases", async () => {
    const txtCntrctPrc = screen.getByTestId("txtCntrctPrc");
    expect(txtCntrctPrc.tagName).toBe("INPUT");
    expect(txtCntrctPrc.type).toBe("text");
    expect(txtCntrctPrc.classList).toContain("textboxWidgetClass");
    expect(txtCntrctPrc.previousElementSibling.textContent).toEqual(
      t("ContractManagement:FlexMktVoid_txtCntrctPrc")
    );
    await act(async () => {
      userEvent.type(txtCntrctPrc, "1");
    });
  });
  test("Custom Test Cases for txtCntrctPrc", () => {
    // START_USER_CODE-USER_txtCntrctPrc_TEST
    // END_USER_CODE-USER_txtCntrctPrc_TEST
  });
});
