/* eslint-disable*/
import React from "react";
import AccountPayment_CheckDetails from "./CheckDetails";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("CheckDetails Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_CheckDetails />);
    });
  });
  afterEach(cleanup);
  test("is CheckDetails Loads Successfully", () => {
    expect(screen.getByText("CheckDetails")).toBeInTheDocument;
  });
  test("Custom Test Cases for CheckDetails", () => {
    // START_USER_CODE-USER_CheckDetails_Custom_Test_Case
    // END_USER_CODE-USER_CheckDetails_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_CheckDetails />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("AccountPayment:CheckDetails_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(t("AccountPayment:CheckDetails_btnOk"));
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("btnReturnToHoldPay(Button Widget) Test Cases", async () => {
    const btnReturnToHoldPay = screen.getByTestId("btnReturnToHoldPay");
    expect(btnReturnToHoldPay).toBeInTheDocument;
    expect(btnReturnToHoldPay.textContent).toEqual(
      t("AccountPayment:CheckDetails_btnReturnToHoldPay")
    );
  });
  test("Custom Test Cases for btnReturnToHoldPay", () => {
    // START_USER_CODE-USER_btnReturnToHoldPay_TEST
    // END_USER_CODE-USER_btnReturnToHoldPay_TEST
  });
  test("btnUpdateRemarks(Button Widget) Test Cases", async () => {
    const btnUpdateRemarks = screen.getByTestId("btnUpdateRemarks");
    expect(btnUpdateRemarks).toBeInTheDocument;
    expect(btnUpdateRemarks.textContent).toEqual(
      t("AccountPayment:CheckDetails_btnUpdateRemarks")
    );
  });
  test("Custom Test Cases for btnUpdateRemarks", () => {
    // START_USER_CODE-USER_btnUpdateRemarks_TEST
    // END_USER_CODE-USER_btnUpdateRemarks_TEST
  });
  test("gridReadyToPays(Grid Widget) Test Cases", async () => {
    let gridReadyToPays = screen.getByTestId("gridReadyToPays");
    let gridReadyToPaysbtn =
      gridReadyToPays.nextElementSibling.firstElementChild;
    gridReadyToPays = gridReadyToPays.parentElement.parentElement.parentElement;
    expect(gridReadyToPays.tagName).toBe("DIV");
    expect(gridReadyToPays.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckDetails_gridReadyToPays"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridReadyToPays", () => {
    // START_USER_CODE-USER_gridReadyToPays_TEST
    // END_USER_CODE-USER_gridReadyToPays_TEST
  });
  test("grpbxCheckDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxCheckDetails = screen.getByTestId("grpbxCheckDetails");
    expect(grpbxCheckDetails.tagName).toBe("BUTTON");
    expect(grpbxCheckDetails.type).toBe("button");
    expect(grpbxCheckDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCheckDetails", () => {
    // START_USER_CODE-USER_grpbxCheckDetails_TEST
    // END_USER_CODE-USER_grpbxCheckDetails_TEST
  });
  test("lblBatchedReadyToPays(Label Widget) Test Cases", async () => {
    const lblBatchedReadyToPays = screen.getByTestId("lblBatchedReadyToPays");
    expect(lblBatchedReadyToPays.tagName).toBe("LABEL");
    expect(lblBatchedReadyToPays.classList).toContain("form-label");
    expect(lblBatchedReadyToPays.textContent).toEqual(
      t("AccountPayment:CheckDetails_lblBatchedReadyToPays")
    );
  });
  test("Custom Test Cases for lblBatchedReadyToPays", () => {
    // START_USER_CODE-USER_lblBatchedReadyToPays_TEST
    // END_USER_CODE-USER_lblBatchedReadyToPays_TEST
  });
  test("lblCheckInformation(Label Widget) Test Cases", async () => {
    const lblCheckInformation = screen.getByTestId("lblCheckInformation");
    expect(lblCheckInformation.tagName).toBe("LABEL");
    expect(lblCheckInformation.classList).toContain("form-label");
    expect(lblCheckInformation.textContent).toEqual(
      t("AccountPayment:CheckDetails_lblCheckInformation")
    );
  });
  test("Custom Test Cases for lblCheckInformation", () => {
    // START_USER_CODE-USER_lblCheckInformation_TEST
    // END_USER_CODE-USER_lblCheckInformation_TEST
  });
  test("lblRemarks(Label Widget) Test Cases", async () => {
    const lblRemarks = screen.getByTestId("lblRemarks");
    expect(lblRemarks.tagName).toBe("LABEL");
    expect(lblRemarks.classList).toContain("form-label");
    expect(lblRemarks.textContent).toEqual(
      t("AccountPayment:CheckDetails_lblRemarks")
    );
  });
  test("Custom Test Cases for lblRemarks", () => {
    // START_USER_CODE-USER_lblRemarks_TEST
    // END_USER_CODE-USER_lblRemarks_TEST
  });
  test("gridReadyToPays_txtcolDate(Grid Widget) Test Cases", async () => {
    let gridReadyToPays_txtcolDate = screen.getByTestId("gridReadyToPays");
    let gridReadyToPays_txtcolDatebtn =
      gridReadyToPays_txtcolDate.nextElementSibling.firstElementChild;
    gridReadyToPays_txtcolDate =
      gridReadyToPays_txtcolDate.parentElement.parentElement.parentElement;
    expect(gridReadyToPays_txtcolDate.tagName).toBe("DIV");
    expect(gridReadyToPays_txtcolDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckDetails_gridReadyToPays"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDate", () => {
    // START_USER_CODE-USER_txtcolDate_TEST
    // END_USER_CODE-USER_txtcolDate_TEST
  });
  test("gridReadyToPays_txtcolNumber1(Grid Widget) Test Cases", async () => {
    let gridReadyToPays_txtcolNumber1 = screen.getByTestId("gridReadyToPays");
    let gridReadyToPays_txtcolNumber1btn =
      gridReadyToPays_txtcolNumber1.nextElementSibling.firstElementChild;
    gridReadyToPays_txtcolNumber1 =
      gridReadyToPays_txtcolNumber1.parentElement.parentElement.parentElement;
    expect(gridReadyToPays_txtcolNumber1.tagName).toBe("DIV");
    expect(gridReadyToPays_txtcolNumber1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckDetails_gridReadyToPays"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber1", () => {
    // START_USER_CODE-USER_txtcolNumber1_TEST
    // END_USER_CODE-USER_txtcolNumber1_TEST
  });
  test("gridReadyToPays_txtcolNumber2(Grid Widget) Test Cases", async () => {
    let gridReadyToPays_txtcolNumber2 = screen.getByTestId("gridReadyToPays");
    let gridReadyToPays_txtcolNumber2btn =
      gridReadyToPays_txtcolNumber2.nextElementSibling.firstElementChild;
    gridReadyToPays_txtcolNumber2 =
      gridReadyToPays_txtcolNumber2.parentElement.parentElement.parentElement;
    expect(gridReadyToPays_txtcolNumber2.tagName).toBe("DIV");
    expect(gridReadyToPays_txtcolNumber2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckDetails_gridReadyToPays"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber2", () => {
    // START_USER_CODE-USER_txtcolNumber2_TEST
    // END_USER_CODE-USER_txtcolNumber2_TEST
  });
  test("gridReadyToPays_txtcolOpt(Grid Widget) Test Cases", async () => {
    let gridReadyToPays_txtcolOpt = screen.getByTestId("gridReadyToPays");
    let gridReadyToPays_txtcolOptbtn =
      gridReadyToPays_txtcolOpt.nextElementSibling.firstElementChild;
    gridReadyToPays_txtcolOpt =
      gridReadyToPays_txtcolOpt.parentElement.parentElement.parentElement;
    expect(gridReadyToPays_txtcolOpt.tagName).toBe("DIV");
    expect(gridReadyToPays_txtcolOpt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckDetails_gridReadyToPays"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpt", () => {
    // START_USER_CODE-USER_txtcolOpt_TEST
    // END_USER_CODE-USER_txtcolOpt_TEST
  });
  test("gridReadyToPays_txtcolProceeds(Grid Widget) Test Cases", async () => {
    let gridReadyToPays_txtcolProceeds = screen.getByTestId("gridReadyToPays");
    let gridReadyToPays_txtcolProceedsbtn =
      gridReadyToPays_txtcolProceeds.nextElementSibling.firstElementChild;
    gridReadyToPays_txtcolProceeds =
      gridReadyToPays_txtcolProceeds.parentElement.parentElement.parentElement;
    expect(gridReadyToPays_txtcolProceeds.tagName).toBe("DIV");
    expect(gridReadyToPays_txtcolProceeds.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckDetails_gridReadyToPays"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProceeds", () => {
    // START_USER_CODE-USER_txtcolProceeds_TEST
    // END_USER_CODE-USER_txtcolProceeds_TEST
  });
  test("gridReadyToPays_txtcolSetlDate(Grid Widget) Test Cases", async () => {
    let gridReadyToPays_txtcolSetlDate = screen.getByTestId("gridReadyToPays");
    let gridReadyToPays_txtcolSetlDatebtn =
      gridReadyToPays_txtcolSetlDate.nextElementSibling.firstElementChild;
    gridReadyToPays_txtcolSetlDate =
      gridReadyToPays_txtcolSetlDate.parentElement.parentElement.parentElement;
    expect(gridReadyToPays_txtcolSetlDate.tagName).toBe("DIV");
    expect(gridReadyToPays_txtcolSetlDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckDetails_gridReadyToPays"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSetlDate", () => {
    // START_USER_CODE-USER_txtcolSetlDate_TEST
    // END_USER_CODE-USER_txtcolSetlDate_TEST
  });
  test("gridReadyToPays_txtcolType1(Grid Widget) Test Cases", async () => {
    let gridReadyToPays_txtcolType1 = screen.getByTestId("gridReadyToPays");
    let gridReadyToPays_txtcolType1btn =
      gridReadyToPays_txtcolType1.nextElementSibling.firstElementChild;
    gridReadyToPays_txtcolType1 =
      gridReadyToPays_txtcolType1.parentElement.parentElement.parentElement;
    expect(gridReadyToPays_txtcolType1.tagName).toBe("DIV");
    expect(gridReadyToPays_txtcolType1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckDetails_gridReadyToPays"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType1", () => {
    // START_USER_CODE-USER_txtcolType1_TEST
    // END_USER_CODE-USER_txtcolType1_TEST
  });
  test("gridReadyToPays_txtcolType2(Grid Widget) Test Cases", async () => {
    let gridReadyToPays_txtcolType2 = screen.getByTestId("gridReadyToPays");
    let gridReadyToPays_txtcolType2btn =
      gridReadyToPays_txtcolType2.nextElementSibling.firstElementChild;
    gridReadyToPays_txtcolType2 =
      gridReadyToPays_txtcolType2.parentElement.parentElement.parentElement;
    expect(gridReadyToPays_txtcolType2.tagName).toBe("DIV");
    expect(gridReadyToPays_txtcolType2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("AccountPayment:CheckDetails_gridReadyToPays"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType2", () => {
    // START_USER_CODE-USER_txtcolType2_TEST
    // END_USER_CODE-USER_txtcolType2_TEST
  });
  test("txtLienholders(Textbox Widget) Test Cases", async () => {
    const txtLienholders = screen.getByTestId("txtLienholders");
    expect(txtLienholders.tagName).toBe("INPUT");
    expect(txtLienholders.type).toBe("text");
    expect(txtLienholders.classList).toContain("textboxWidgetClass");
    expect(txtLienholders.previousElementSibling.textContent).toEqual(
      t("AccountPayment:CheckDetails_txtLienholders")
    );
    await act(async () => {
      userEvent.type(txtLienholders, "123");
    });
    expect(txtLienholders.getAttribute("value")).toBe("");
    expect(txtLienholders.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLienholders", () => {
    // START_USER_CODE-USER_txtLienholders_TEST
    // END_USER_CODE-USER_txtLienholders_TEST
  });
});
