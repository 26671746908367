/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  getSelectedRowNumber,
  setData,
  getValue,
  getData,
  getRowCount,
  getGridValue,
} from "../../shared/WindowImports";

import "./DiscountScheduleAuditDetail.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_DiscountScheduleAuditDetail(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DiscountScheduleAuditDetail",
    windowName: "DiscountScheduleAuditDetail",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.DiscountScheduleAuditDetail",
    // START_USER_CODE-USER_DiscountScheduleAuditDetail_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Discount Schedule Audit Details",
      scrCode: "PN0070E",
    },
    // END_USER_CODE-USER_DiscountScheduleAuditDetail_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    colNewDiscountPerTon: {
      name: "colNewDiscountPerTon",
      type: "GridColumnWidget",
      parent: "gridDiscountScheduleAudit",
      Label: "New Discount Per Ton",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewDiscountPerTon_PROPERTIES

      // END_USER_CODE-USER_colNewDiscountPerTon_PROPERTIES
    },
    colNewReadingFrom: {
      name: "colNewReadingFrom",
      type: "GridColumnWidget",
      parent: "gridDiscountScheduleAudit",
      Label: "New Reading From",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewReadingFrom_PROPERTIES

      // END_USER_CODE-USER_colNewReadingFrom_PROPERTIES
    },
    colNewReadingTo: {
      name: "colNewReadingTo",
      type: "GridColumnWidget",
      parent: "gridDiscountScheduleAudit",
      Label: "New Reading To",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewReadingTo_PROPERTIES

      // END_USER_CODE-USER_colNewReadingTo_PROPERTIES
    },
    colNewType: {
      name: "colNewType",
      type: "GridColumnWidget",
      parent: "gridDiscountScheduleAudit",
      Label: "New Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewType_PROPERTIES

      // END_USER_CODE-USER_colNewType_PROPERTIES
    },
    colOldDiscountPerTon: {
      name: "colOldDiscountPerTon",
      type: "GridColumnWidget",
      parent: "gridDiscountScheduleAudit",
      Label: "Old Discount Per Ton",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldDiscountPerTon_PROPERTIES

      // END_USER_CODE-USER_colOldDiscountPerTon_PROPERTIES
    },
    colOldReadingFrom: {
      name: "colOldReadingFrom",
      type: "GridColumnWidget",
      parent: "gridDiscountScheduleAudit",
      Label: "Old Reading From",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldReadingFrom_PROPERTIES

      // END_USER_CODE-USER_colOldReadingFrom_PROPERTIES
    },
    colOldReadingTo: {
      name: "colOldReadingTo",
      type: "GridColumnWidget",
      parent: "gridDiscountScheduleAudit",
      Label: "Old Reading To",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldReadingTo_PROPERTIES

      // END_USER_CODE-USER_colOldReadingTo_PROPERTIES
    },
    colOldType: {
      name: "colOldType",
      type: "GridColumnWidget",
      parent: "gridDiscountScheduleAudit",
      Label: "Old Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldType_PROPERTIES

      // END_USER_CODE-USER_colOldType_PROPERTIES
    },
    gridDiscountScheduleAudit: {
      name: "gridDiscountScheduleAudit",
      type: "GridWidget",
      parent: "grpbxDiscountScheduleAuditDetail",
      gridCellsOrder:
        "txtColOldType,txtColOldReadingFrom,txtColOldReadingTo,txtColOldDiscountPerTon,txtColNewType,txtColNewReadingFrom,txtColNewReadingTo,txtColnewDiscountPerTon",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridDiscountScheduleAudit_PROPERTIES

      // END_USER_CODE-USER_gridDiscountScheduleAudit_PROPERTIES
    },
    txtColnewDiscountPerTon: {
      name: "txtColnewDiscountPerTon",
      type: "TextBoxWidget",
      colName: "colNewDiscountPerTon",
      parent: "gridDiscountScheduleAudit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_float",
      // START_USER_CODE-USER_txtColnewDiscountPerTon_PROPERTIES

      // END_USER_CODE-USER_txtColnewDiscountPerTon_PROPERTIES
    },
    txtColNewReadingFrom: {
      name: "txtColNewReadingFrom",
      type: "TextBoxWidget",
      colName: "colNewReadingFrom",
      parent: "gridDiscountScheduleAudit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_float",
      // START_USER_CODE-USER_txtColNewReadingFrom_PROPERTIES

      // END_USER_CODE-USER_txtColNewReadingFrom_PROPERTIES
    },
    txtColNewReadingTo: {
      name: "txtColNewReadingTo",
      type: "TextBoxWidget",
      colName: "colNewReadingTo",
      parent: "gridDiscountScheduleAudit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_float",
      // START_USER_CODE-USER_txtColNewReadingTo_PROPERTIES

      // END_USER_CODE-USER_txtColNewReadingTo_PROPERTIES
    },
    txtColNewType: {
      name: "txtColNewType",
      type: "TextBoxWidget",
      colName: "colNewType",
      parent: "gridDiscountScheduleAudit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_float",
      // START_USER_CODE-USER_txtColNewType_PROPERTIES

      // END_USER_CODE-USER_txtColNewType_PROPERTIES
    },
    txtColOldDiscountPerTon: {
      name: "txtColOldDiscountPerTon",
      type: "TextBoxWidget",
      colName: "colOldDiscountPerTon",
      parent: "gridDiscountScheduleAudit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_float",
      // START_USER_CODE-USER_txtColOldDiscountPerTon_PROPERTIES

      // END_USER_CODE-USER_txtColOldDiscountPerTon_PROPERTIES
    },
    txtColOldReadingFrom: {
      name: "txtColOldReadingFrom",
      type: "TextBoxWidget",
      colName: "colOldReadingFrom",
      parent: "gridDiscountScheduleAudit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_float",
      // START_USER_CODE-USER_txtColOldReadingFrom_PROPERTIES

      // END_USER_CODE-USER_txtColOldReadingFrom_PROPERTIES
    },
    txtColOldReadingTo: {
      name: "txtColOldReadingTo",
      type: "TextBoxWidget",
      colName: "colOldReadingTo",
      parent: "gridDiscountScheduleAudit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_float",
      // START_USER_CODE-USER_txtColOldReadingTo_PROPERTIES

      // END_USER_CODE-USER_txtColOldReadingTo_PROPERTIES
    },
    txtColOldType: {
      name: "txtColOldType",
      type: "TextBoxWidget",
      colName: "colOldType",
      parent: "gridDiscountScheduleAudit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      DecimalPlaces: 5,
      ofTypeDomain: "d_float",
      // START_USER_CODE-USER_txtColOldType_PROPERTIES

      // END_USER_CODE-USER_txtColOldType_PROPERTIES
    },
    grpbxDiscountScheduleAuditDetail: {
      name: "grpbxDiscountScheduleAuditDetail",
      type: "GroupBoxWidget",
      parent: "DiscountScheduleAuditDetail",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDiscountScheduleAuditDetail_PROPERTIES

      // END_USER_CODE-USER_grpbxDiscountScheduleAuditDetail_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "DiscountScheduleAuditDetail",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [selectedRowNum, setSelectedRowNum] = useState(-1);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj;
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  //FormLoad Function
  const FormLoad = () => {
    FillForm();
  };

  //FillForm Function
  const FillForm = async () => {
    try {
      let js = [];
      let response = getData(thisObj, "frmDiscountScheduleAuditDetail");
      let data = response.discountschedulehist;
      let obj = {};
      for (var i = 0; i < data.length; i++) {
        obj.txtColOldType = data[i].old_discount_type;
        obj.txtColOldReadingFrom = data[i].old_reading_from;
        obj.txtColOldReadingTo = data[i].old_reading_to;
        obj.txtColOldDiscountPerTon = data[i].old_discount_per_ton;
        obj.txtColNewType = data[i].new_discount_type;
        obj.txtColNewReadingFrom = data[i].new_reading_from;
        obj.txtColNewReadingTo = data[i].new_reading_to;
        obj.txtColnewDiscountPerTon = data[i].new_discount_per_ton;
        js.push(obj);
        obj = {};
      }
      setValue(thisObj, "gridDiscountScheduleAudit", js);
      setRowColor();
      if (js.length == 1) {
        setSelectedRowNum(0);
        js[0].isSelected = true;
      } else if (js.length > 0) {
        setSelectedRowNum(0);
      }
      setData(thisObj, "gridDiscountScheduleAudit", js);

      thisObj.setState((current) => {
        return {
          ...current,
          gridDiscountScheduleAudit: {
            ...current["gridDiscountScheduleAudit"],
            valueList: js,
          },
        };
      });
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during Grid Binding");
      }
      return false;
    }
  };
  const setRowColor = () => {
    let n = getRowCount(_kaledo.thisObj, "gridDiscountScheduleAudit");
    for (let i = 0; i < n; i++) {
      let val = getGridValue(
        _kaledo.thisObj,
        "gridDiscountScheduleAudit",
        "txtColNewType",
        i
      );
      ``;
      if (val == "") {
        document.getElementById(`row-${i}`).style.fontWeight = "700";
        document.getElementById(`row-${i}`).style.color = "red";
      }
    }
  };
  const selectRow = (newRowNum) => {
    const gridData = getValue(thisObj, "gridDiscountScheduleAudit");
    let rowNum = getSelectedRowNumber(thisObj, "gridDiscountScheduleAudit");
    if (rowNum == undefined) {
      rowNum = 0;
    }
    if (
      newRowNum !== undefined &&
      selectedRowNum !== undefined &&
      gridData?.length
    ) {
      gridData[selectedRowNum].isSelected = false;
      setSelectedRowNum(newRowNum);
      return newRowNum;
    }
  };
  const ongridDiscountScheduleAuditRowSelect = (rowItems) => {
    const rowNum = getSelectedRowNumber(thisObj, "gridDiscountScheduleAudit");
    selectRow(rowNum);
  };
  thisObj.ongridDiscountScheduleAuditRowSelect = ongridDiscountScheduleAuditRowSelect;

  //Close button function
  const onbtnCloseClick = () => {
    try {
      document
        .getElementById(
          "SystemMaintenanceSpecialFunctions_DiscountScheduleAuditDetailPopUp"
        )
        .childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName +
              "-" +
              state.windowName +
              " col-lg-12" +
              " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={(vlaues) => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DiscountScheduleAuditDetail*/}

              {/* END_USER_CODE-USER_BEFORE_DiscountScheduleAuditDetail*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDiscountScheduleAuditDetail*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDiscountScheduleAuditDetail*/}

              <GroupBoxWidget
                conf={state.grpbxDiscountScheduleAuditDetail}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_gridDiscountScheduleAudit*/}

                {/* END_USER_CODE-USER_BEFORE_gridDiscountScheduleAudit*/}

                <GridWidget
                  conf={state.gridDiscountScheduleAudit}
                  screenConf={state}
                  linkClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridDiscountScheduleAudit}
                  onEvent={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridDiscountScheduleAudit*/}

                {/* END_USER_CODE-USER_AFTER_gridDiscountScheduleAudit*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDiscountScheduleAuditDetail*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDiscountScheduleAuditDetail*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_DiscountScheduleAuditDetail*/}

              {/* END_USER_CODE-USER_AFTER_DiscountScheduleAuditDetail*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_DiscountScheduleAuditDetail
);
