/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_InterestAgreementSetup from "./InterestAgreementSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("InterestAgreementSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_InterestAgreementSetup />
      );
    });
  });
  afterEach(cleanup);
  test("is InterestAgreementSetup Loads Successfully", () => {
    expect(screen.getByText("InterestAgreementSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for InterestAgreementSetup", () => {
    // START_USER_CODE-USER_InterestAgreementSetup_Custom_Test_Case
    // END_USER_CODE-USER_InterestAgreementSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_InterestAgreementSetup />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:InterestAgreementSetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("grpbxIntrstAgreeSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxIntrstAgreeSetup = screen.getByTestId("grpbxIntrstAgreeSetup");
    expect(grpbxIntrstAgreeSetup.tagName).toBe("BUTTON");
    expect(grpbxIntrstAgreeSetup.type).toBe("button");
    expect(grpbxIntrstAgreeSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxIntrstAgreeSetup", () => {
    // START_USER_CODE-USER_grpbxIntrstAgreeSetup_TEST
    // END_USER_CODE-USER_grpbxIntrstAgreeSetup_TEST
  });
  test("lblIntrstAgree(Label Widget) Test Cases", async () => {
    const lblIntrstAgree = screen.getByTestId("lblIntrstAgree");
    expect(lblIntrstAgree.tagName).toBe("LABEL");
    expect(lblIntrstAgree.classList).toContain("form-label");
    expect(lblIntrstAgree.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:InterestAgreementSetup_lblIntrstAgree"
      )
    );
  });
  test("Custom Test Cases for lblIntrstAgree", () => {
    // START_USER_CODE-USER_lblIntrstAgree_TEST
    // END_USER_CODE-USER_lblIntrstAgree_TEST
  });
});
