/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_ManualRTPSearch from "./ManualRTPSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ManualRTPSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_ManualRTPSearch />);
    });
  });
  afterEach(cleanup);
  test("is ManualRTPSearch Loads Successfully", () => {
    expect(screen.getByText("ManualRTPSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for ManualRTPSearch", () => {
    // START_USER_CODE-USER_ManualRTPSearch_Custom_Test_Case
    // END_USER_CODE-USER_ManualRTPSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_ManualRTPSearch />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnAuditHistory(Button Widget) Test Cases", async () => {
    const btnAuditHistory = screen.getByTestId("btnAuditHistory");
    expect(btnAuditHistory).toBeInTheDocument;
    expect(btnAuditHistory.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_btnAuditHistory")
    );
  });
  test("Custom Test Cases for btnAuditHistory", () => {
    // START_USER_CODE-USER_btnAuditHistory_TEST
    // END_USER_CODE-USER_btnAuditHistory_TEST
  });
  test("btnChange(Button Widget) Test Cases", async () => {
    const btnChange = screen.getByTestId("btnChange");
    expect(btnChange).toBeInTheDocument;
    expect(btnChange.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_btnChange")
    );
  });
  test("Custom Test Cases for btnChange", () => {
    // START_USER_CODE-USER_btnChange_TEST
    // END_USER_CODE-USER_btnChange_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridManualRTP(Grid Widget) Test Cases", async () => {
    let gridManualRTP = screen.getByTestId("gridManualRTP");
    let gridManualRTPbtn = gridManualRTP.nextElementSibling.firstElementChild;
    gridManualRTP = gridManualRTP.parentElement.parentElement.parentElement;
    expect(gridManualRTP.tagName).toBe("DIV");
    expect(gridManualRTP.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:ManualRTPSearch_gridManualRTP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridManualRTP", () => {
    // START_USER_CODE-USER_gridManualRTP_TEST
    // END_USER_CODE-USER_gridManualRTP_TEST
  });
  test("grpbxManualRTPSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxManualRTPSearch = screen.getByTestId("grpbxManualRTPSearch");
    expect(grpbxManualRTPSearch.tagName).toBe("BUTTON");
    expect(grpbxManualRTPSearch.type).toBe("button");
    expect(grpbxManualRTPSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxManualRTPSearch", () => {
    // START_USER_CODE-USER_grpbxManualRTPSearch_TEST
    // END_USER_CODE-USER_grpbxManualRTPSearch_TEST
  });
  test("gridManualRTP_txtcolBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridManualRTP_txtcolBuyingPoint = screen.getByTestId("gridManualRTP");
    let gridManualRTP_txtcolBuyingPointbtn =
      gridManualRTP_txtcolBuyingPoint.nextElementSibling.firstElementChild;
    gridManualRTP_txtcolBuyingPoint =
      gridManualRTP_txtcolBuyingPoint.parentElement.parentElement.parentElement;
    expect(gridManualRTP_txtcolBuyingPoint.tagName).toBe("DIV");
    expect(gridManualRTP_txtcolBuyingPoint.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:ManualRTPSearch_gridManualRTP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyingPoint", () => {
    // START_USER_CODE-USER_txtcolBuyingPoint_TEST
    // END_USER_CODE-USER_txtcolBuyingPoint_TEST
  });
  test("gridManualRTP_txtcolCounty(Grid Widget) Test Cases", async () => {
    let gridManualRTP_txtcolCounty = screen.getByTestId("gridManualRTP");
    let gridManualRTP_txtcolCountybtn =
      gridManualRTP_txtcolCounty.nextElementSibling.firstElementChild;
    gridManualRTP_txtcolCounty =
      gridManualRTP_txtcolCounty.parentElement.parentElement.parentElement;
    expect(gridManualRTP_txtcolCounty.tagName).toBe("DIV");
    expect(gridManualRTP_txtcolCounty.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:ManualRTPSearch_gridManualRTP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCounty", () => {
    // START_USER_CODE-USER_txtcolCounty_TEST
    // END_USER_CODE-USER_txtcolCounty_TEST
  });
  test("gridManualRTP_txtcolFarm(Grid Widget) Test Cases", async () => {
    let gridManualRTP_txtcolFarm = screen.getByTestId("gridManualRTP");
    let gridManualRTP_txtcolFarmbtn =
      gridManualRTP_txtcolFarm.nextElementSibling.firstElementChild;
    gridManualRTP_txtcolFarm =
      gridManualRTP_txtcolFarm.parentElement.parentElement.parentElement;
    expect(gridManualRTP_txtcolFarm.tagName).toBe("DIV");
    expect(gridManualRTP_txtcolFarm.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:ManualRTPSearch_gridManualRTP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFarm", () => {
    // START_USER_CODE-USER_txtcolFarm_TEST
    // END_USER_CODE-USER_txtcolFarm_TEST
  });
  test("gridManualRTP_txtcolNumber1(Grid Widget) Test Cases", async () => {
    let gridManualRTP_txtcolNumber1 = screen.getByTestId("gridManualRTP");
    let gridManualRTP_txtcolNumber1btn =
      gridManualRTP_txtcolNumber1.nextElementSibling.firstElementChild;
    gridManualRTP_txtcolNumber1 =
      gridManualRTP_txtcolNumber1.parentElement.parentElement.parentElement;
    expect(gridManualRTP_txtcolNumber1.tagName).toBe("DIV");
    expect(gridManualRTP_txtcolNumber1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:ManualRTPSearch_gridManualRTP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber1", () => {
    // START_USER_CODE-USER_txtcolNumber1_TEST
    // END_USER_CODE-USER_txtcolNumber1_TEST
  });
  test("gridManualRTP_txtcolNumber2(Grid Widget) Test Cases", async () => {
    let gridManualRTP_txtcolNumber2 = screen.getByTestId("gridManualRTP");
    let gridManualRTP_txtcolNumber2btn =
      gridManualRTP_txtcolNumber2.nextElementSibling.firstElementChild;
    gridManualRTP_txtcolNumber2 =
      gridManualRTP_txtcolNumber2.parentElement.parentElement.parentElement;
    expect(gridManualRTP_txtcolNumber2.tagName).toBe("DIV");
    expect(gridManualRTP_txtcolNumber2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:ManualRTPSearch_gridManualRTP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber2", () => {
    // START_USER_CODE-USER_txtcolNumber2_TEST
    // END_USER_CODE-USER_txtcolNumber2_TEST
  });
  test("gridManualRTP_txtcolOptions(Grid Widget) Test Cases", async () => {
    let gridManualRTP_txtcolOptions = screen.getByTestId("gridManualRTP");
    let gridManualRTP_txtcolOptionsbtn =
      gridManualRTP_txtcolOptions.nextElementSibling.firstElementChild;
    gridManualRTP_txtcolOptions =
      gridManualRTP_txtcolOptions.parentElement.parentElement.parentElement;
    expect(gridManualRTP_txtcolOptions.tagName).toBe("DIV");
    expect(gridManualRTP_txtcolOptions.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:ManualRTPSearch_gridManualRTP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOptions", () => {
    // START_USER_CODE-USER_txtcolOptions_TEST
    // END_USER_CODE-USER_txtcolOptions_TEST
  });
  test("gridManualRTP_txtcolPayAmount(Grid Widget) Test Cases", async () => {
    let gridManualRTP_txtcolPayAmount = screen.getByTestId("gridManualRTP");
    let gridManualRTP_txtcolPayAmountbtn =
      gridManualRTP_txtcolPayAmount.nextElementSibling.firstElementChild;
    gridManualRTP_txtcolPayAmount =
      gridManualRTP_txtcolPayAmount.parentElement.parentElement.parentElement;
    expect(gridManualRTP_txtcolPayAmount.tagName).toBe("DIV");
    expect(gridManualRTP_txtcolPayAmount.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:ManualRTPSearch_gridManualRTP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPayAmount", () => {
    // START_USER_CODE-USER_txtcolPayAmount_TEST
    // END_USER_CODE-USER_txtcolPayAmount_TEST
  });
  test("gridManualRTP_txtcolState(Grid Widget) Test Cases", async () => {
    let gridManualRTP_txtcolState = screen.getByTestId("gridManualRTP");
    let gridManualRTP_txtcolStatebtn =
      gridManualRTP_txtcolState.nextElementSibling.firstElementChild;
    gridManualRTP_txtcolState =
      gridManualRTP_txtcolState.parentElement.parentElement.parentElement;
    expect(gridManualRTP_txtcolState.tagName).toBe("DIV");
    expect(gridManualRTP_txtcolState.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:ManualRTPSearch_gridManualRTP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolState", () => {
    // START_USER_CODE-USER_txtcolState_TEST
    // END_USER_CODE-USER_txtcolState_TEST
  });
  test("gridManualRTP_txtcolType1(Grid Widget) Test Cases", async () => {
    let gridManualRTP_txtcolType1 = screen.getByTestId("gridManualRTP");
    let gridManualRTP_txtcolType1btn =
      gridManualRTP_txtcolType1.nextElementSibling.firstElementChild;
    gridManualRTP_txtcolType1 =
      gridManualRTP_txtcolType1.parentElement.parentElement.parentElement;
    expect(gridManualRTP_txtcolType1.tagName).toBe("DIV");
    expect(gridManualRTP_txtcolType1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:ManualRTPSearch_gridManualRTP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType1", () => {
    // START_USER_CODE-USER_txtcolType1_TEST
    // END_USER_CODE-USER_txtcolType1_TEST
  });
  test("gridManualRTP_txtcolType2(Grid Widget) Test Cases", async () => {
    let gridManualRTP_txtcolType2 = screen.getByTestId("gridManualRTP");
    let gridManualRTP_txtcolType2btn =
      gridManualRTP_txtcolType2.nextElementSibling.firstElementChild;
    gridManualRTP_txtcolType2 =
      gridManualRTP_txtcolType2.parentElement.parentElement.parentElement;
    expect(gridManualRTP_txtcolType2.tagName).toBe("DIV");
    expect(gridManualRTP_txtcolType2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:ManualRTPSearch_gridManualRTP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType2", () => {
    // START_USER_CODE-USER_txtcolType2_TEST
    // END_USER_CODE-USER_txtcolType2_TEST
  });
  test("gridManualRTP_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridManualRTP_txtcolVendor = screen.getByTestId("gridManualRTP");
    let gridManualRTP_txtcolVendorbtn =
      gridManualRTP_txtcolVendor.nextElementSibling.firstElementChild;
    gridManualRTP_txtcolVendor =
      gridManualRTP_txtcolVendor.parentElement.parentElement.parentElement;
    expect(gridManualRTP_txtcolVendor.tagName).toBe("DIV");
    expect(gridManualRTP_txtcolVendor.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:ManualRTPSearch_gridManualRTP")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
  test("txtContract(Textbox Widget) Test Cases", async () => {
    const txtContract = screen.getByTestId("txtContract");
    expect(txtContract.tagName).toBe("INPUT");
    expect(txtContract.type).toBe("text");
    expect(txtContract.classList).toContain("textboxWidgetClass");
    expect(txtContract.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_txtContract")
    );
    await act(async () => {
      userEvent.type(txtContract, "1");
    });
  });
  test("Custom Test Cases for txtContract", () => {
    // START_USER_CODE-USER_txtContract_TEST
    // END_USER_CODE-USER_txtContract_TEST
  });
  test("txtDeliveryAgreement(Textbox Widget) Test Cases", async () => {
    const txtDeliveryAgreement = screen.getByTestId("txtDeliveryAgreement");
    expect(txtDeliveryAgreement.tagName).toBe("INPUT");
    expect(txtDeliveryAgreement.type).toBe("text");
    expect(txtDeliveryAgreement.classList).toContain("textboxWidgetClass");
    expect(txtDeliveryAgreement.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPSearch_txtDeliveryAgreement"
      )
    );
    await act(async () => {
      userEvent.type(txtDeliveryAgreement, "1");
    });
  });
  test("Custom Test Cases for txtDeliveryAgreement", () => {
    // START_USER_CODE-USER_txtDeliveryAgreement_TEST
    // END_USER_CODE-USER_txtDeliveryAgreement_TEST
  });
  test("txtFarm(Textbox Widget) Test Cases", async () => {
    const txtFarm = screen.getByTestId("txtFarm");
    expect(txtFarm.tagName).toBe("INPUT");
    expect(txtFarm.type).toBe("text");
    expect(txtFarm.classList).toContain("textboxWidgetClass");
    expect(txtFarm.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_txtFarm")
    );
    await act(async () => {
      userEvent.type(txtFarm, "1");
    });
  });
  test("Custom Test Cases for txtFarm", () => {
    // START_USER_CODE-USER_txtFarm_TEST
    // END_USER_CODE-USER_txtFarm_TEST
  });
  test("txtFarmSuffix(Textbox Widget) Test Cases", async () => {
    const txtFarmSuffix = screen.getByTestId("txtFarmSuffix");
    expect(txtFarmSuffix.tagName).toBe("INPUT");
    expect(txtFarmSuffix.type).toBe("text");
    expect(txtFarmSuffix.classList).toContain("textboxWidgetClass");
    expect(txtFarmSuffix.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_txtFarmSuffix")
    );
    await act(async () => {
      userEvent.type(txtFarmSuffix, "1");
    });
  });
  test("Custom Test Cases for txtFarmSuffix", () => {
    // START_USER_CODE-USER_txtFarmSuffix_TEST
    // END_USER_CODE-USER_txtFarmSuffix_TEST
  });
  test("txtFreightMemo(Textbox Widget) Test Cases", async () => {
    const txtFreightMemo = screen.getByTestId("txtFreightMemo");
    expect(txtFreightMemo.tagName).toBe("INPUT");
    expect(txtFreightMemo.type).toBe("text");
    expect(txtFreightMemo.classList).toContain("textboxWidgetClass");
    expect(txtFreightMemo.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_txtFreightMemo")
    );
    await act(async () => {
      userEvent.type(txtFreightMemo, "1");
    });
  });
  test("Custom Test Cases for txtFreightMemo", () => {
    // START_USER_CODE-USER_txtFreightMemo_TEST
    // END_USER_CODE-USER_txtFreightMemo_TEST
  });
  test("txtProceeds(Textbox Widget) Test Cases", async () => {
    const txtProceeds = screen.getByTestId("txtProceeds");
    expect(txtProceeds.tagName).toBe("INPUT");
    expect(txtProceeds.type).toBe("text");
    expect(txtProceeds.classList).toContain("textboxWidgetClass");
    expect(txtProceeds.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_txtProceeds")
    );
    await act(async () => {
      userEvent.type(txtProceeds, "1");
    });
  });
  test("Custom Test Cases for txtProceeds", () => {
    // START_USER_CODE-USER_txtProceeds_TEST
    // END_USER_CODE-USER_txtProceeds_TEST
  });
  test("txtSC95(Textbox Widget) Test Cases", async () => {
    const txtSC95 = screen.getByTestId("txtSC95");
    expect(txtSC95.tagName).toBe("INPUT");
    expect(txtSC95.type).toBe("text");
    expect(txtSC95.classList).toContain("textboxWidgetClass");
    expect(txtSC95.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_txtSC95")
    );
    await act(async () => {
      userEvent.type(txtSC95, "1");
    });
  });
  test("Custom Test Cases for txtSC95", () => {
    // START_USER_CODE-USER_txtSC95_TEST
    // END_USER_CODE-USER_txtSC95_TEST
  });
  test("txtSettlement1007(Textbox Widget) Test Cases", async () => {
    const txtSettlement1007 = screen.getByTestId("txtSettlement1007");
    expect(txtSettlement1007.tagName).toBe("INPUT");
    expect(txtSettlement1007.type).toBe("text");
    expect(txtSettlement1007.classList).toContain("textboxWidgetClass");
    expect(txtSettlement1007.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_txtSettlement1007")
    );
    await act(async () => {
      userEvent.type(txtSettlement1007, "1");
    });
  });
  test("Custom Test Cases for txtSettlement1007", () => {
    // START_USER_CODE-USER_txtSettlement1007_TEST
    // END_USER_CODE-USER_txtSettlement1007_TEST
  });
  test("txtTotalProceeds(Textbox Widget) Test Cases", async () => {
    const txtTotalProceeds = screen.getByTestId("txtTotalProceeds");
    expect(txtTotalProceeds.tagName).toBe("INPUT");
    expect(txtTotalProceeds.type).toBe("text");
    expect(txtTotalProceeds.classList).toContain("textboxWidgetClass");
    expect(txtTotalProceeds.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_txtTotalProceeds")
    );
    await act(async () => {
      userEvent.type(txtTotalProceeds, "1");
    });
  });
  test("Custom Test Cases for txtTotalProceeds", () => {
    // START_USER_CODE-USER_txtTotalProceeds_TEST
    // END_USER_CODE-USER_txtTotalProceeds_TEST
  });
  test("txtTradeInspection(Textbox Widget) Test Cases", async () => {
    const txtTradeInspection = screen.getByTestId("txtTradeInspection");
    expect(txtTradeInspection.tagName).toBe("INPUT");
    expect(txtTradeInspection.type).toBe("text");
    expect(txtTradeInspection.classList).toContain("textboxWidgetClass");
    expect(txtTradeInspection.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPSearch_txtTradeInspection"
      )
    );
    await act(async () => {
      userEvent.type(txtTradeInspection, "1");
    });
  });
  test("Custom Test Cases for txtTradeInspection", () => {
    // START_USER_CODE-USER_txtTradeInspection_TEST
    // END_USER_CODE-USER_txtTradeInspection_TEST
  });
  test("txtTradeSettlement(Textbox Widget) Test Cases", async () => {
    const txtTradeSettlement = screen.getByTestId("txtTradeSettlement");
    expect(txtTradeSettlement.tagName).toBe("INPUT");
    expect(txtTradeSettlement.type).toBe("text");
    expect(txtTradeSettlement.classList).toContain("textboxWidgetClass");
    expect(txtTradeSettlement.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPSearch_txtTradeSettlement"
      )
    );
    await act(async () => {
      userEvent.type(txtTradeSettlement, "1");
    });
  });
  test("Custom Test Cases for txtTradeSettlement", () => {
    // START_USER_CODE-USER_txtTradeSettlement_TEST
    // END_USER_CODE-USER_txtTradeSettlement_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPSearch_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVndr(Textbox Widget) Test Cases", async () => {
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVndr, "1");
    });
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
  test("txtWarehouseReceipt(Textbox Widget) Test Cases", async () => {
    const txtWarehouseReceipt = screen.getByTestId("txtWarehouseReceipt");
    expect(txtWarehouseReceipt.tagName).toBe("INPUT");
    expect(txtWarehouseReceipt.type).toBe("text");
    expect(txtWarehouseReceipt.classList).toContain("textboxWidgetClass");
    expect(txtWarehouseReceipt.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPSearch_txtWarehouseReceipt"
      )
    );
    await act(async () => {
      userEvent.type(txtWarehouseReceipt, "1");
    });
  });
  test("Custom Test Cases for txtWarehouseReceipt", () => {
    // START_USER_CODE-USER_txtWarehouseReceipt_TEST
    // END_USER_CODE-USER_txtWarehouseReceipt_TEST
  });
});
