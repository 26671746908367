/* eslint-disable*/
import React, { useRef } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import ParentWidget from "../ParentWidget";
import { convertStringToJsonObject } from "../../../../common/ScreenInitialization";
import "./RadioButtonGroupWidget.scss";
import {getPlaceholder,checkInvalid,getTextBoxClasses,getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility,
  getTooltip} from "../ParentWidgetFunc";

function RadioButtonGroupWidget(props) {
  let labelWidth = props.conf.labelWidth || 4;
  let conf = props.conf;
  let screenConf = props.screenConf;
    if (!conf.valueList || !conf.valueList.length) {
      conf.valueList = convertStringToJsonObject(conf.Options);
    }
    const value = props.values[conf.name];
  const singleClickTimer = useRef(null)
  const handleClick = (e, element) => {
    e.persist()
    if (singleClickTimer.current === null) {
      singleClickTimer.current = setTimeout(() => {
        let value;
        if (e.target.checked) {
          value = element.key;
        }
        let event = {
          type: "click",
          target: {
            name: conf.name,
            id: conf.name,
            value: value,
          },
        };
        props.onClick(event);
        singleClickTimer.current = null;
      }, 400); // Set double click interval
    } else {
      clearTimeout(singleClickTimer.current);
      let value;
      if (e.target.checked) {
        value = element.key;
      }
      let event = {
        type: "Dblclick",
        target: {
          name: conf.name,
          id: conf.name,
          value: value,
        },
      };
      props.onClick(event);
      singleClickTimer.current = null;
      return;
    }
  };
    let radioButtonGroup = (
      <div className="mb-3 radiobuttongroupwidget-container">
        {conf.valueList.map((element, index) => {
          return (
            <Form.Check
              key={index}
              inline={conf.inline}
              disabled={!conf.Enabled}
              label={element.description}
              type="radio"
              value={value}
              checked={value === element.key}
              name={conf.name}
              id={conf.name + index}
              onChange={e => {
                let value;
                if (e.target.checked) {
                  value = element.key;
                }
                let event = {
                  type: "change",
                  target: {
                    name: conf.name,
                    id: conf.name,
                    value: value
                  }
                };
                props.onChange(event);
              }}
              onBlur={props.onBlur}
          {...(props.onClick && {onClick: (e)=>handleClick(e, element)})}
              isInvalid={checkInvalid(props,conf)}
            />
          );
        })}
        <Form.Control.Feedback
          className={checkInvalid(props,conf) ? "d-block" : "d-none"}
          type="invalid"
          aria-live="polite" role="alert"
        >
          {props.errors[conf.name]}
        </Form.Control.Feedback>
      </div>
    );
    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
        data-testid={conf.name}
		title={getTooltip(conf)}
		role="tooltip"
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
          >
            {conf.Label}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>{radioButtonGroup}</Col>
          ) : (
            <React.Fragment> {radioButtonGroup}</React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default RadioButtonGroupWidget;
