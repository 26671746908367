/* eslint-disable*/
import React from "react";
import WarehouseReceipts_WarehouseReceiptRedeemByLoan from "./WarehouseReceiptRedeemByLoan";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("WarehouseReceiptRedeemByLoan Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WarehouseReceiptRedeemByLoan />);
    });
  });
  afterEach(cleanup);
  test("is WarehouseReceiptRedeemByLoan Loads Successfully", () => {
    expect(screen.getByText("WarehouseReceiptRedeemByLoan")).toBeInTheDocument;
  });
  test("Custom Test Cases for WarehouseReceiptRedeemByLoan", () => {
    // START_USER_CODE-USER_WarehouseReceiptRedeemByLoan_Custom_Test_Case
    // END_USER_CODE-USER_WarehouseReceiptRedeemByLoan_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WarehouseReceiptRedeemByLoan />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnPurchase(Button Widget) Test Cases", async () => {
    const btnPurchase = screen.getByTestId("btnPurchase");
    expect(btnPurchase).toBeInTheDocument;
    expect(btnPurchase.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_btnPurchase")
    );
  });
  test("Custom Test Cases for btnPurchase", () => {
    // START_USER_CODE-USER_btnPurchase_TEST
    // END_USER_CODE-USER_btnPurchase_TEST
  });
  test("btnRepayVendor(Button Widget) Test Cases", async () => {
    const btnRepayVendor = screen.getByTestId("btnRepayVendor");
    expect(btnRepayVendor).toBeInTheDocument;
    expect(btnRepayVendor.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_btnRepayVendor")
    );
  });
  test("Custom Test Cases for btnRepayVendor", () => {
    // START_USER_CODE-USER_btnRepayVendor_TEST
    // END_USER_CODE-USER_btnRepayVendor_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("gridWarehouseReceiptRedeembyLoan(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptRedeembyLoan = screen.getByTestId(
      "gridWarehouseReceiptRedeembyLoan"
    );
    let gridWarehouseReceiptRedeembyLoanbtn =
      gridWarehouseReceiptRedeembyLoan.nextElementSibling.firstElementChild;
    gridWarehouseReceiptRedeembyLoan =
      gridWarehouseReceiptRedeembyLoan.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptRedeembyLoan.tagName).toBe("DIV");
    expect(gridWarehouseReceiptRedeembyLoan.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:WarehouseReceiptRedeemByLoan_gridWarehouseReceiptRedeembyLoan"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridWarehouseReceiptRedeembyLoan", () => {
    // START_USER_CODE-USER_gridWarehouseReceiptRedeembyLoan_TEST
    // END_USER_CODE-USER_gridWarehouseReceiptRedeembyLoan_TEST
  });
  test("grpbxRepayment(GroupBox Widget) Test Cases", async () => {
    const grpbxRepayment = screen.getByTestId("grpbxRepayment");
    expect(grpbxRepayment.tagName).toBe("BUTTON");
    expect(grpbxRepayment.type).toBe("button");
    expect(grpbxRepayment.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRepayment", () => {
    // START_USER_CODE-USER_grpbxRepayment_TEST
    // END_USER_CODE-USER_grpbxRepayment_TEST
  });
  test("grpbxTotal(GroupBox Widget) Test Cases", async () => {
    const grpbxTotal = screen.getByTestId("grpbxTotal");
    expect(grpbxTotal.tagName).toBe("BUTTON");
    expect(grpbxTotal.type).toBe("button");
    expect(grpbxTotal.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTotal", () => {
    // START_USER_CODE-USER_grpbxTotal_TEST
    // END_USER_CODE-USER_grpbxTotal_TEST
  });
  test("grpbxwarehouseReceipt(GroupBox Widget) Test Cases", async () => {
    const grpbxwarehouseReceipt = screen.getByTestId("grpbxwarehouseReceipt");
    expect(grpbxwarehouseReceipt.tagName).toBe("BUTTON");
    expect(grpbxwarehouseReceipt.type).toBe("button");
    expect(grpbxwarehouseReceipt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxwarehouseReceipt", () => {
    // START_USER_CODE-USER_grpbxwarehouseReceipt_TEST
    // END_USER_CODE-USER_grpbxwarehouseReceipt_TEST
  });
  test("grpbxWarehousereceipt(GroupBox Widget) Test Cases", async () => {
    const grpbxWarehousereceipt = screen.getByTestId("grpbxWarehousereceipt");
    expect(grpbxWarehousereceipt.tagName).toBe("BUTTON");
    expect(grpbxWarehousereceipt.type).toBe("button");
    expect(grpbxWarehousereceipt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWarehousereceipt", () => {
    // START_USER_CODE-USER_grpbxWarehousereceipt_TEST
    // END_USER_CODE-USER_grpbxWarehousereceipt_TEST
  });
  test("grpbxWarehouseReceiptReedembyyLoanNum(GroupBox Widget) Test Cases", async () => {
    const grpbxWarehouseReceiptReedembyyLoanNum = screen.getByTestId(
      "grpbxWarehouseReceiptReedembyyLoanNum"
    );
    expect(grpbxWarehouseReceiptReedembyyLoanNum.tagName).toBe("BUTTON");
    expect(grpbxWarehouseReceiptReedembyyLoanNum.type).toBe("button");
    expect(grpbxWarehouseReceiptReedembyyLoanNum.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWarehouseReceiptReedembyyLoanNum", () => {
    // START_USER_CODE-USER_grpbxWarehouseReceiptReedembyyLoanNum_TEST
    // END_USER_CODE-USER_grpbxWarehouseReceiptReedembyyLoanNum_TEST
  });
  test("lblRepayment(Label Widget) Test Cases", async () => {
    const lblRepayment = screen.getByTestId("lblRepayment");
    expect(lblRepayment.tagName).toBe("LABEL");
    expect(lblRepayment.classList).toContain("form-label");
    expect(lblRepayment.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_lblRepayment")
    );
  });
  test("Custom Test Cases for lblRepayment", () => {
    // START_USER_CODE-USER_lblRepayment_TEST
    // END_USER_CODE-USER_lblRepayment_TEST
  });
  test("gridWarehouseReceiptRedeembyLoan_txtcolBuyPt(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptRedeembyLoan_txtcolBuyPt = screen.getByTestId(
      "gridWarehouseReceiptRedeembyLoan"
    );
    let gridWarehouseReceiptRedeembyLoan_txtcolBuyPtbtn =
      gridWarehouseReceiptRedeembyLoan_txtcolBuyPt.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptRedeembyLoan_txtcolBuyPt =
      gridWarehouseReceiptRedeembyLoan_txtcolBuyPt.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptRedeembyLoan_txtcolBuyPt.tagName).toBe("DIV");
    expect(gridWarehouseReceiptRedeembyLoan_txtcolBuyPt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:WarehouseReceiptRedeemByLoan_gridWarehouseReceiptRedeembyLoan"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyPt", () => {
    // START_USER_CODE-USER_txtcolBuyPt_TEST
    // END_USER_CODE-USER_txtcolBuyPt_TEST
  });
  test("gridWarehouseReceiptRedeembyLoan_txtcolCCC500(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptRedeembyLoan_txtcolCCC500 = screen.getByTestId(
      "gridWarehouseReceiptRedeembyLoan"
    );
    let gridWarehouseReceiptRedeembyLoan_txtcolCCC500btn =
      gridWarehouseReceiptRedeembyLoan_txtcolCCC500.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptRedeembyLoan_txtcolCCC500 =
      gridWarehouseReceiptRedeembyLoan_txtcolCCC500.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptRedeembyLoan_txtcolCCC500.tagName).toBe("DIV");
    expect(gridWarehouseReceiptRedeembyLoan_txtcolCCC500.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:WarehouseReceiptRedeemByLoan_gridWarehouseReceiptRedeembyLoan"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCCC500", () => {
    // START_USER_CODE-USER_txtcolCCC500_TEST
    // END_USER_CODE-USER_txtcolCCC500_TEST
  });
  test("gridWarehouseReceiptRedeembyLoan_txtcolHandlingAmount(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptRedeembyLoan_txtcolHandlingAmount = screen.getByTestId(
      "gridWarehouseReceiptRedeembyLoan"
    );
    let gridWarehouseReceiptRedeembyLoan_txtcolHandlingAmountbtn =
      gridWarehouseReceiptRedeembyLoan_txtcolHandlingAmount.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptRedeembyLoan_txtcolHandlingAmount =
      gridWarehouseReceiptRedeembyLoan_txtcolHandlingAmount.parentElement
        .parentElement.parentElement;
    expect(gridWarehouseReceiptRedeembyLoan_txtcolHandlingAmount.tagName).toBe(
      "DIV"
    );
    expect(
      gridWarehouseReceiptRedeembyLoan_txtcolHandlingAmount.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:WarehouseReceiptRedeemByLoan_gridWarehouseReceiptRedeembyLoan"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolHandlingAmount", () => {
    // START_USER_CODE-USER_txtcolHandlingAmount_TEST
    // END_USER_CODE-USER_txtcolHandlingAmount_TEST
  });
  test("gridWarehouseReceiptRedeembyLoan_txtcolInterestAmount(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptRedeembyLoan_txtcolInterestAmount = screen.getByTestId(
      "gridWarehouseReceiptRedeembyLoan"
    );
    let gridWarehouseReceiptRedeembyLoan_txtcolInterestAmountbtn =
      gridWarehouseReceiptRedeembyLoan_txtcolInterestAmount.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptRedeembyLoan_txtcolInterestAmount =
      gridWarehouseReceiptRedeembyLoan_txtcolInterestAmount.parentElement
        .parentElement.parentElement;
    expect(gridWarehouseReceiptRedeembyLoan_txtcolInterestAmount.tagName).toBe(
      "DIV"
    );
    expect(
      gridWarehouseReceiptRedeembyLoan_txtcolInterestAmount.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:WarehouseReceiptRedeemByLoan_gridWarehouseReceiptRedeembyLoan"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInterestAmount", () => {
    // START_USER_CODE-USER_txtcolInterestAmount_TEST
    // END_USER_CODE-USER_txtcolInterestAmount_TEST
  });
  test("gridWarehouseReceiptRedeembyLoan_txtcolLoanRepayAmount(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptRedeembyLoan_txtcolLoanRepayAmount = screen.getByTestId(
      "gridWarehouseReceiptRedeembyLoan"
    );
    let gridWarehouseReceiptRedeembyLoan_txtcolLoanRepayAmountbtn =
      gridWarehouseReceiptRedeembyLoan_txtcolLoanRepayAmount.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptRedeembyLoan_txtcolLoanRepayAmount =
      gridWarehouseReceiptRedeembyLoan_txtcolLoanRepayAmount.parentElement
        .parentElement.parentElement;
    expect(gridWarehouseReceiptRedeembyLoan_txtcolLoanRepayAmount.tagName).toBe(
      "DIV"
    );
    expect(
      gridWarehouseReceiptRedeembyLoan_txtcolLoanRepayAmount.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:WarehouseReceiptRedeemByLoan_gridWarehouseReceiptRedeembyLoan"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoanRepayAmount", () => {
    // START_USER_CODE-USER_txtcolLoanRepayAmount_TEST
    // END_USER_CODE-USER_txtcolLoanRepayAmount_TEST
  });
  test("gridWarehouseReceiptRedeembyLoan_txtcolMarketGain(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptRedeembyLoan_txtcolMarketGain = screen.getByTestId(
      "gridWarehouseReceiptRedeembyLoan"
    );
    let gridWarehouseReceiptRedeembyLoan_txtcolMarketGainbtn =
      gridWarehouseReceiptRedeembyLoan_txtcolMarketGain.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptRedeembyLoan_txtcolMarketGain =
      gridWarehouseReceiptRedeembyLoan_txtcolMarketGain.parentElement
        .parentElement.parentElement;
    expect(gridWarehouseReceiptRedeembyLoan_txtcolMarketGain.tagName).toBe(
      "DIV"
    );
    expect(
      gridWarehouseReceiptRedeembyLoan_txtcolMarketGain.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:WarehouseReceiptRedeemByLoan_gridWarehouseReceiptRedeembyLoan"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMarketGain", () => {
    // START_USER_CODE-USER_txtcolMarketGain_TEST
    // END_USER_CODE-USER_txtcolMarketGain_TEST
  });
  test("gridWarehouseReceiptRedeembyLoan_txtcolNetRepayAmount(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptRedeembyLoan_txtcolNetRepayAmount = screen.getByTestId(
      "gridWarehouseReceiptRedeembyLoan"
    );
    let gridWarehouseReceiptRedeembyLoan_txtcolNetRepayAmountbtn =
      gridWarehouseReceiptRedeembyLoan_txtcolNetRepayAmount.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptRedeembyLoan_txtcolNetRepayAmount =
      gridWarehouseReceiptRedeembyLoan_txtcolNetRepayAmount.parentElement
        .parentElement.parentElement;
    expect(gridWarehouseReceiptRedeembyLoan_txtcolNetRepayAmount.tagName).toBe(
      "DIV"
    );
    expect(
      gridWarehouseReceiptRedeembyLoan_txtcolNetRepayAmount.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:WarehouseReceiptRedeemByLoan_gridWarehouseReceiptRedeembyLoan"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNetRepayAmount", () => {
    // START_USER_CODE-USER_txtcolNetRepayAmount_TEST
    // END_USER_CODE-USER_txtcolNetRepayAmount_TEST
  });
  test("gridWarehouseReceiptRedeembyLoan_txtcolPrincipalLoaned(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptRedeembyLoan_txtcolPrincipalLoaned = screen.getByTestId(
      "gridWarehouseReceiptRedeembyLoan"
    );
    let gridWarehouseReceiptRedeembyLoan_txtcolPrincipalLoanedbtn =
      gridWarehouseReceiptRedeembyLoan_txtcolPrincipalLoaned.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptRedeembyLoan_txtcolPrincipalLoaned =
      gridWarehouseReceiptRedeembyLoan_txtcolPrincipalLoaned.parentElement
        .parentElement.parentElement;
    expect(gridWarehouseReceiptRedeembyLoan_txtcolPrincipalLoaned.tagName).toBe(
      "DIV"
    );
    expect(
      gridWarehouseReceiptRedeembyLoan_txtcolPrincipalLoaned.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:WarehouseReceiptRedeemByLoan_gridWarehouseReceiptRedeembyLoan"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPrincipalLoaned", () => {
    // START_USER_CODE-USER_txtcolPrincipalLoaned_TEST
    // END_USER_CODE-USER_txtcolPrincipalLoaned_TEST
  });
  test("gridWarehouseReceiptRedeembyLoan_txtcolReceipt(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptRedeembyLoan_txtcolReceipt = screen.getByTestId(
      "gridWarehouseReceiptRedeembyLoan"
    );
    let gridWarehouseReceiptRedeembyLoan_txtcolReceiptbtn =
      gridWarehouseReceiptRedeembyLoan_txtcolReceipt.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptRedeembyLoan_txtcolReceipt =
      gridWarehouseReceiptRedeembyLoan_txtcolReceipt.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptRedeembyLoan_txtcolReceipt.tagName).toBe("DIV");
    expect(gridWarehouseReceiptRedeembyLoan_txtcolReceipt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:WarehouseReceiptRedeemByLoan_gridWarehouseReceiptRedeembyLoan"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReceipt", () => {
    // START_USER_CODE-USER_txtcolReceipt_TEST
    // END_USER_CODE-USER_txtcolReceipt_TEST
  });
  test("gridWarehouseReceiptRedeembyLoan_txtcolReceiptValue(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptRedeembyLoan_txtcolReceiptValue = screen.getByTestId(
      "gridWarehouseReceiptRedeembyLoan"
    );
    let gridWarehouseReceiptRedeembyLoan_txtcolReceiptValuebtn =
      gridWarehouseReceiptRedeembyLoan_txtcolReceiptValue.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptRedeembyLoan_txtcolReceiptValue =
      gridWarehouseReceiptRedeembyLoan_txtcolReceiptValue.parentElement
        .parentElement.parentElement;
    expect(gridWarehouseReceiptRedeembyLoan_txtcolReceiptValue.tagName).toBe(
      "DIV"
    );
    expect(
      gridWarehouseReceiptRedeembyLoan_txtcolReceiptValue.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:WarehouseReceiptRedeemByLoan_gridWarehouseReceiptRedeembyLoan"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReceiptValue", () => {
    // START_USER_CODE-USER_txtcolReceiptValue_TEST
    // END_USER_CODE-USER_txtcolReceiptValue_TEST
  });
  test("gridWarehouseReceiptRedeembyLoan_txtcolSel(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptRedeembyLoan_txtcolSel = screen.getByTestId(
      "gridWarehouseReceiptRedeembyLoan"
    );
    let gridWarehouseReceiptRedeembyLoan_txtcolSelbtn =
      gridWarehouseReceiptRedeembyLoan_txtcolSel.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptRedeembyLoan_txtcolSel =
      gridWarehouseReceiptRedeembyLoan_txtcolSel.parentElement.parentElement
        .parentElement;
    expect(gridWarehouseReceiptRedeembyLoan_txtcolSel.tagName).toBe("DIV");
    expect(gridWarehouseReceiptRedeembyLoan_txtcolSel.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:WarehouseReceiptRedeemByLoan_gridWarehouseReceiptRedeembyLoan"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSel", () => {
    // START_USER_CODE-USER_txtcolSel_TEST
    // END_USER_CODE-USER_txtcolSel_TEST
  });
  test("gridWarehouseReceiptRedeembyLoan_txtcolTotalTons(Grid Widget) Test Cases", async () => {
    let gridWarehouseReceiptRedeembyLoan_txtcolTotalTons = screen.getByTestId(
      "gridWarehouseReceiptRedeembyLoan"
    );
    let gridWarehouseReceiptRedeembyLoan_txtcolTotalTonsbtn =
      gridWarehouseReceiptRedeembyLoan_txtcolTotalTons.nextElementSibling
        .firstElementChild;
    gridWarehouseReceiptRedeembyLoan_txtcolTotalTons =
      gridWarehouseReceiptRedeembyLoan_txtcolTotalTons.parentElement
        .parentElement.parentElement;
    expect(gridWarehouseReceiptRedeembyLoan_txtcolTotalTons.tagName).toBe(
      "DIV"
    );
    expect(
      gridWarehouseReceiptRedeembyLoan_txtcolTotalTons.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "WarehouseReceipts:WarehouseReceiptRedeemByLoan_gridWarehouseReceiptRedeembyLoan"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotalTons", () => {
    // START_USER_CODE-USER_txtcolTotalTons_TEST
    // END_USER_CODE-USER_txtcolTotalTons_TEST
  });
  test("txtLoanNum1(Textbox Widget) Test Cases", async () => {
    const txtLoanNum1 = screen.getByTestId("txtLoanNum1");
    expect(txtLoanNum1.tagName).toBe("INPUT");
    expect(txtLoanNum1.type).toBe("text");
    expect(txtLoanNum1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLoanNum1, "1");
    });
  });
  test("Custom Test Cases for txtLoanNum1", () => {
    // START_USER_CODE-USER_txtLoanNum1_TEST
    // END_USER_CODE-USER_txtLoanNum1_TEST
  });
  test("txtLoanNum2(Textbox Widget) Test Cases", async () => {
    const txtLoanNum2 = screen.getByTestId("txtLoanNum2");
    expect(txtLoanNum2.tagName).toBe("INPUT");
    expect(txtLoanNum2.type).toBe("text");
    expect(txtLoanNum2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLoanNum2, "1");
    });
  });
  test("Custom Test Cases for txtLoanNum2", () => {
    // START_USER_CODE-USER_txtLoanNum2_TEST
    // END_USER_CODE-USER_txtLoanNum2_TEST
  });
  test("txtLoanNumber(Textbox Widget) Test Cases", async () => {
    const txtLoanNumber = screen.getByTestId("txtLoanNumber");
    expect(txtLoanNumber.tagName).toBe("INPUT");
    expect(txtLoanNumber.type).toBe("text");
    expect(txtLoanNumber.classList).toContain("textboxWidgetClass");
    expect(txtLoanNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_txtLoanNumber")
    );
    await act(async () => {
      userEvent.type(txtLoanNumber, "1");
    });
  });
  test("Custom Test Cases for txtLoanNumber", () => {
    // START_USER_CODE-USER_txtLoanNumber_TEST
    // END_USER_CODE-USER_txtLoanNumber_TEST
  });
  test("txtRedemptionEndDate(Date Widget) Test Cases", async () => {
    const txtRedemptionEndDate = screen.getByTestId("txtRedemptionEndDate");
    expect(txtRedemptionEndDate.tagName).toBe("INPUT");
    expect(txtRedemptionEndDate.type).toBe("text");
    expect(txtRedemptionEndDate.classList).toContain("datetimepicker-input");
    expect(
      txtRedemptionEndDate.parentElement.previousElementSibling.textContent
    ).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_txtRedemptionEndDate")
    );
    await act(async () => {
      userEvent.click(txtRedemptionEndDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtRedemptionEndDate", () => {
    // START_USER_CODE-USER_txtRedemptionEndDate_TEST
    // END_USER_CODE-USER_txtRedemptionEndDate_TEST
  });
  test("txtRepayVendor(Textbox Widget) Test Cases", async () => {
    const txtRepayVendor = screen.getByTestId("txtRepayVendor");
    expect(txtRepayVendor.tagName).toBe("INPUT");
    expect(txtRepayVendor.type).toBe("text");
    expect(txtRepayVendor.classList).toContain("textboxWidgetClass");
    expect(txtRepayVendor.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_txtRepayVendor")
    );
    await act(async () => {
      userEvent.type(txtRepayVendor, "1");
    });
  });
  test("Custom Test Cases for txtRepayVendor", () => {
    // START_USER_CODE-USER_txtRepayVendor_TEST
    // END_USER_CODE-USER_txtRepayVendor_TEST
  });
  test("txtTotal1(Textbox Widget) Test Cases", async () => {
    const txtTotal1 = screen.getByTestId("txtTotal1");
    expect(txtTotal1.tagName).toBe("INPUT");
    expect(txtTotal1.type).toBe("text");
    expect(txtTotal1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotal1, "123");
    });
    expect(txtTotal1.getAttribute("value")).toBe("");
    expect(txtTotal1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotal1", () => {
    // START_USER_CODE-USER_txtTotal1_TEST
    // END_USER_CODE-USER_txtTotal1_TEST
  });
  test("txtTotal2(Textbox Widget) Test Cases", async () => {
    const txtTotal2 = screen.getByTestId("txtTotal2");
    expect(txtTotal2.tagName).toBe("INPUT");
    expect(txtTotal2.type).toBe("text");
    expect(txtTotal2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotal2, "123");
    });
    expect(txtTotal2.getAttribute("value")).toBe("");
    expect(txtTotal2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotal2", () => {
    // START_USER_CODE-USER_txtTotal2_TEST
    // END_USER_CODE-USER_txtTotal2_TEST
  });
  test("txtTotal3(Textbox Widget) Test Cases", async () => {
    const txtTotal3 = screen.getByTestId("txtTotal3");
    expect(txtTotal3.tagName).toBe("INPUT");
    expect(txtTotal3.type).toBe("text");
    expect(txtTotal3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotal3, "123");
    });
    expect(txtTotal3.getAttribute("value")).toBe("");
    expect(txtTotal3.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotal3", () => {
    // START_USER_CODE-USER_txtTotal3_TEST
    // END_USER_CODE-USER_txtTotal3_TEST
  });
  test("txtTotal4(Textbox Widget) Test Cases", async () => {
    const txtTotal4 = screen.getByTestId("txtTotal4");
    expect(txtTotal4.tagName).toBe("INPUT");
    expect(txtTotal4.type).toBe("text");
    expect(txtTotal4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotal4, "123");
    });
    expect(txtTotal4.getAttribute("value")).toBe("");
    expect(txtTotal4.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotal4", () => {
    // START_USER_CODE-USER_txtTotal4_TEST
    // END_USER_CODE-USER_txtTotal4_TEST
  });
  test("txtTotal5(Textbox Widget) Test Cases", async () => {
    const txtTotal5 = screen.getByTestId("txtTotal5");
    expect(txtTotal5.tagName).toBe("INPUT");
    expect(txtTotal5.type).toBe("text");
    expect(txtTotal5.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotal5, "123");
    });
    expect(txtTotal5.getAttribute("value")).toBe("");
    expect(txtTotal5.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotal5", () => {
    // START_USER_CODE-USER_txtTotal5_TEST
    // END_USER_CODE-USER_txtTotal5_TEST
  });
  test("txtTotal6(Textbox Widget) Test Cases", async () => {
    const txtTotal6 = screen.getByTestId("txtTotal6");
    expect(txtTotal6.tagName).toBe("INPUT");
    expect(txtTotal6.type).toBe("text");
    expect(txtTotal6.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotal6, "123");
    });
    expect(txtTotal6.getAttribute("value")).toBe("");
    expect(txtTotal6.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotal6", () => {
    // START_USER_CODE-USER_txtTotal6_TEST
    // END_USER_CODE-USER_txtTotal6_TEST
  });
  test("txttotal7(Textbox Widget) Test Cases", async () => {
    const txttotal7 = screen.getByTestId("txttotal7");
    expect(txttotal7.tagName).toBe("INPUT");
    expect(txttotal7.type).toBe("text");
    expect(txttotal7.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txttotal7, "123");
    });
    expect(txttotal7.getAttribute("value")).toBe("");
    expect(txttotal7.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txttotal7", () => {
    // START_USER_CODE-USER_txttotal7_TEST
    // END_USER_CODE-USER_txttotal7_TEST
  });
  test("txtTotalAmountDue(Textbox Widget) Test Cases", async () => {
    const txtTotalAmountDue = screen.getByTestId("txtTotalAmountDue");
    expect(txtTotalAmountDue.tagName).toBe("INPUT");
    expect(txtTotalAmountDue.type).toBe("text");
    expect(txtTotalAmountDue.classList).toContain("textboxWidgetClass");
    expect(txtTotalAmountDue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_txtTotalAmountDue")
    );
    await act(async () => {
      userEvent.type(txtTotalAmountDue, "123");
    });
    expect(txtTotalAmountDue.getAttribute("value")).toBe("");
    expect(txtTotalAmountDue.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalAmountDue", () => {
    // START_USER_CODE-USER_txtTotalAmountDue_TEST
    // END_USER_CODE-USER_txtTotalAmountDue_TEST
  });
  test("txtTotalHandling(Textbox Widget) Test Cases", async () => {
    const txtTotalHandling = screen.getByTestId("txtTotalHandling");
    expect(txtTotalHandling.tagName).toBe("INPUT");
    expect(txtTotalHandling.type).toBe("text");
    expect(txtTotalHandling.classList).toContain("textboxWidgetClass");
    expect(txtTotalHandling.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_txtTotalHandling")
    );
    await act(async () => {
      userEvent.type(txtTotalHandling, "1");
    });
  });
  test("Custom Test Cases for txtTotalHandling", () => {
    // START_USER_CODE-USER_txtTotalHandling_TEST
    // END_USER_CODE-USER_txtTotalHandling_TEST
  });
  test("txtTotalInterestDue(Textbox Widget) Test Cases", async () => {
    const txtTotalInterestDue = screen.getByTestId("txtTotalInterestDue");
    expect(txtTotalInterestDue.tagName).toBe("INPUT");
    expect(txtTotalInterestDue.type).toBe("text");
    expect(txtTotalInterestDue.classList).toContain("textboxWidgetClass");
    expect(txtTotalInterestDue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_txtTotalInterestDue")
    );
    await act(async () => {
      userEvent.type(txtTotalInterestDue, "1");
    });
  });
  test("Custom Test Cases for txtTotalInterestDue", () => {
    // START_USER_CODE-USER_txtTotalInterestDue_TEST
    // END_USER_CODE-USER_txtTotalInterestDue_TEST
  });
  test("txtTotalMrktGain(Textbox Widget) Test Cases", async () => {
    const txtTotalMrktGain = screen.getByTestId("txtTotalMrktGain");
    expect(txtTotalMrktGain.tagName).toBe("INPUT");
    expect(txtTotalMrktGain.type).toBe("text");
    expect(txtTotalMrktGain.classList).toContain("textboxWidgetClass");
    expect(txtTotalMrktGain.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_txtTotalMrktGain")
    );
    await act(async () => {
      userEvent.type(txtTotalMrktGain, "1");
    });
  });
  test("Custom Test Cases for txtTotalMrktGain", () => {
    // START_USER_CODE-USER_txtTotalMrktGain_TEST
    // END_USER_CODE-USER_txtTotalMrktGain_TEST
  });
  test("txtTotalprincipalLiquidated(Textbox Widget) Test Cases", async () => {
    const txtTotalprincipalLiquidated = screen.getByTestId(
      "txtTotalprincipalLiquidated"
    );
    expect(txtTotalprincipalLiquidated.tagName).toBe("INPUT");
    expect(txtTotalprincipalLiquidated.type).toBe("text");
    expect(txtTotalprincipalLiquidated.classList).toContain(
      "textboxWidgetClass"
    );
    expect(
      txtTotalprincipalLiquidated.previousElementSibling.textContent
    ).toEqual(
      t(
        "WarehouseReceipts:WarehouseReceiptRedeemByLoan_txtTotalprincipalLiquidated"
      )
    );
    await act(async () => {
      userEvent.type(txtTotalprincipalLiquidated, "1");
    });
  });
  test("Custom Test Cases for txtTotalprincipalLiquidated", () => {
    // START_USER_CODE-USER_txtTotalprincipalLiquidated_TEST
    // END_USER_CODE-USER_txtTotalprincipalLiquidated_TEST
  });
  test("txtTotalTons(Textbox Widget) Test Cases", async () => {
    const txtTotalTons = screen.getByTestId("txtTotalTons");
    expect(txtTotalTons.tagName).toBe("INPUT");
    expect(txtTotalTons.type).toBe("text");
    expect(txtTotalTons.classList).toContain("textboxWidgetClass");
    expect(txtTotalTons.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptRedeemByLoan_txtTotalTons")
    );
    await act(async () => {
      userEvent.type(txtTotalTons, "123");
    });
    expect(txtTotalTons.getAttribute("value")).toBe("");
    expect(txtTotalTons.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalTons", () => {
    // START_USER_CODE-USER_txtTotalTons_TEST
    // END_USER_CODE-USER_txtTotalTons_TEST
  });
  test("txtWireTransferData(Textbox Widget) Test Cases", async () => {
    const txtWireTransferData = screen.getByTestId("txtWireTransferData");
    expect(txtWireTransferData.tagName).toBe("INPUT");
    expect(txtWireTransferData.type).toBe("text");
    expect(txtWireTransferData.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtWireTransferData, "1");
    });
  });
  test("Custom Test Cases for txtWireTransferData", () => {
    // START_USER_CODE-USER_txtWireTransferData_TEST
    // END_USER_CODE-USER_txtWireTransferData_TEST
  });
});
