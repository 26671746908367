/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  CheckboxWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  DateWidget,
  setValue,
  getValue,
  disable,
  hide,
  show,
  getData,
  setData,
  enable,
  goTo,
  hideColumn,
  showColumn,
} from "../../shared/WindowImports";

import "./ContractLimitSearch.scss";

// START_USER_CODE-USER_IMPORTS
import * as XLSX from 'xlsx/xlsx.mjs';
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import jsPDF from 'jspdf';
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices"
import { SettlementService } from "../../Settlements/Service/SettlementService";
import ModalPopUp from "react-bootstrap/Modal";
import Loading from "../../../Loader/Loading";

// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_ContractLimitSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ContractLimitSearch",
    windowName: "ContractLimitSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.ContractLimitSearch",
    // START_USER_CODE-USER_ContractLimitSearch_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Contract Price Limit Search",
      scrCode: "PN0260A",
    },
    // END_USER_CODE-USER_ContractLimitSearch_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxContractsSearch",
      Label: ".",
      ColSpan: "10",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "cmmndCntnrContractActions",
      Label: "Delete",
      CharWidth: "15",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "cmmndCntnrContractActions",
      Label: "Edit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxContractsSearch",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "grpbxContractsSearch",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnNewOffer: {
      name: "btnNewOffer",
      type: "ButtonWidget",
      parent: "cmmndCntnrContractActions",
      Label: "New Offer ",
      CharWidth: "23",
      // START_USER_CODE-USER_btnNewOffer_PROPERTIES

      // END_USER_CODE-USER_btnNewOffer_PROPERTIES
    },
    btnPrintGrid: {
      name: "btnPrintGrid",
      type: "ButtonWidget",
      parent: "grpbxContractsSearch",
      Label: "Print Grid",
      CharWidth: "23",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnPrintGrid_PROPERTIES

      // END_USER_CODE-USER_btnPrintGrid_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxContractDetails",
      Label: "Search",
      ColSpan: "4",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxContractDetails",
      Label: "...",
      CharWidth: "9",
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    chkboxBasis: {
      name: "chkboxBasis",
      type: "CheckBoxWidget",
      parent: "grpbxContractsSearch",
      Label: "C-Basis",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxBasis_PROPERTIES

      // END_USER_CODE-USER_chkboxBasis_PROPERTIES
    },
    chkboxFlex: {
      name: "chkboxFlex",
      type: "CheckBoxWidget",
      parent: "grpbxContractsSearch",
      Label: "FlexMkt",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxFlex_PROPERTIES

      // END_USER_CODE-USER_chkboxFlex_PROPERTIES
    },
    chkboxFirm: {
      name: "chkboxFirm",
      type: "CheckBoxWidget",
      parent: "grpbxContractsSearch",
      Label: "C-Firm",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxFirm_PROPERTIES

      // END_USER_CODE-USER_chkboxFirm_PROPERTIES
    },
    chkboxSeed: {
      name: "chkboxSeed",
      type: "CheckBoxWidget",
      parent: "grpbxContractsSearch",
      Label: "Seed",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxSeed_PROPERTIES

      // END_USER_CODE-USER_chkboxSeed_PROPERTIES
    },
    chkboxOption: {
      name: "chkboxOption",
      type: "CheckBoxWidget",
      parent: "grpbxContractsSearch",
      Label: "Option",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxOption_PROPERTIES

      // END_USER_CODE-USER_chkboxOption_PROPERTIES
    },
    chkboxFloor: {
      name: "chkboxFloor",
      type: "CheckBoxWidget",
      parent: "grpbxContractsSearch",
      Label: "Floor",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxFloor_PROPERTIES

      // END_USER_CODE-USER_chkboxFloor_PROPERTIES
    },
    cmmndCntnrContractActions: {
      name: "cmmndCntnrContractActions",
      type: "CommandContainerWidget",
      parent: "grpbxContractsSearch",
      ColSpan: "10",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrContractActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrContractActions_PROPERTIES
    },
    ddArea: {
      name: "ddArea",
      type: "DropDownFieldWidget",
      parent: "grpbxContractDetails",
      Label: "Area:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddArea_PROPERTIES

      // END_USER_CODE-USER_ddArea_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxContractDetails",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    ddCollectionPoint: {
      name: "ddCollectionPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxContractDetails",
      Label: "Collection Point:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCollectionPoint_PROPERTIES

      // END_USER_CODE-USER_ddCollectionPoint_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxContractDetails",
      Label: "Oleic:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddOrganic: {
      name: "ddOrganic",
      type: "DropDownFieldWidget",
      parent: "grpbxContractDetails",
      Label: "Organic:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOrganic_PROPERTIES

      // END_USER_CODE-USER_ddOrganic_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxContractDetails",
      Label: "Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxContractDetails",
      Label: "Variety:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddPricingType: {
      name: "ddPricingType",
      type: "DropDownFieldWidget",
      parent: "grpbxContractDetails",
      Label: "Pricing Type:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPricingType_PROPERTIES

      // END_USER_CODE-USER_ddPricingType_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxContractDetails",
      Label: "Seg:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    gridContractLimitSearchData: {
      name: "gridContractLimitSearchData",
      type: "GridWidget",
      parent: "grpbxContractsSearch",
      gridCellsOrder:
        "txtAreaC,btncol1,txtrecordkey,txtPricingType,txtPricingTypeDesc,txtPeanut,txtVariety,txtSegC,txtOleicC,txtOrganicC,txtPricePerTon,txtTonsAllocatedC,txtTonsContractedC,txtTonsOpen,txtIrrigatedAcresContractedC,txtcolDryLandAcresC,txtTotalAcresContractedC,txtMarketFloor,txtMarketCeiling,txtRebateRate,txtMktGainSharePercent,txtLoanRepayMethod,txtMultiPricing,txtWeeksPriorMaturity,txtOptionPriceCap,txtPayRebatAt,txtPriceWatchEndDate,txtLockInDate1,txtLockInDate2,txtLockInDate3,txtLockInDate4,txtLockInDate5,txtLockInDate6,txtLockInDate7,txtLockInDate8,txtLockInDate9,txtLockInDate10",
      ColSpan: "10",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      clonedExtId: "27085",
      // START_USER_CODE-USER_gridContractLimitSearchData_PROPERTIES

      // END_USER_CODE-USER_gridContractLimitSearchData_PROPERTIES
    },
    grpbxContractDetails: {
      name: "grpbxContractDetails",
      type: "GroupBoxWidget",
      parent: "grpbxContractLimitSearch",
      Height: "",
      Width: "",
      ColsForMobile: "5",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxContractDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxContractDetails_PROPERTIES
    },
    grpbxContractsSearch: {
      name: "grpbxContractsSearch",
      type: "GroupBoxWidget",
      parent: "grpbxContractLimitSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "10",
      HasLabel: false,
      Cols: "10",
      ColsForTabPotrait: "10",
      ColsForLargeDesktop: "10",
      // START_USER_CODE-USER_grpbxContractsSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxContractsSearch_PROPERTIES
    },
    grpbxExclude: {
      name: "grpbxExclude",
      type: "GroupBoxWidget",
      parent: "grpbxContractsSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "10",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxContractsSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxContractsSearch_PROPERTIES
    },
    lblPeanut: {
      name: "lblPeanut",
      type: "LabelWidget",
      parent: "grpbxContractDetails",
      Label: "Peanut:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPeanut_PROPERTIES

      // END_USER_CODE-USER_lblPeanut_PROPERTIES
    },
    lblTotals: {
      name: "lblTotals",
      type: "LabelWidget",
      parent: "grpbxContractsSearch",
      Label: "Totals:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_lblTotals_PROPERTIES

      // END_USER_CODE-USER_lblTotals_PROPERTIES
    },
    lblExcludeGrid: {
      name: "lblExcludeGrid",
      type: "LabelWidget",
      parent: "grpbxContractsSearch",
      Label: "Exclude From Grid:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_lblExcludeGrid_PROPERTIES

      // END_USER_CODE-USER_lblExcludeGrid_PROPERTIES
    },
    btncol1: {
      name: "btncol1",
      type: "ButtonWidget",
      colName: "col1",
      parent: "gridContractLimitSearchData",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncol1_PROPERTIES

      // END_USER_CODE-USER_btncol1_PROPERTIES
    },
    txtAreaC: {
      name: "txtAreaC",
      type: "TextBoxWidget",
      colName: "txtColArea",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAreaC_PROPERTIES

      // END_USER_CODE-USER_txtAreaC_PROPERTIES
    },
    txtrecordkey: {
      name: "txtrecordkey",
      type: "TextBoxWidget",
      colName: "txtcolrecordkey",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRecordKey_PROPERTIES

      // END_USER_CODE-USER_txtRecordKey_PROPERTIES
    },
    txtcolrecordkey: {
      name: "txtcolrecordkey",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Record Key",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColArea_PROPERTIES

      // END_USER_CODE-USER_txtColArea_PROPERTIES
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },
    txtColArea: {
      name: "txtColArea",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Area",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColArea_PROPERTIES

      // END_USER_CODE-USER_txtColArea_PROPERTIES
    },
    txtColDryLandAcres: {
      name: "txtColDryLandAcres",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Dry Land Acres",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColDryLandAcres_PROPERTIES

      // END_USER_CODE-USER_txtColDryLandAcres_PROPERTIES
    },
    txtcolLockInDate1: {
      name: "txtcolLockInDate1",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Lock In Date1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate2: {
      name: "txtcolLockInDate2",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Lock In Date2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolLockInDate2_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate2_PROPERTIES
    },
    txtcolLockInDate3: {
      name: "txtcolLockInDate3",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Lock In Date3",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolLockInDate3_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate3_PROPERTIES
    },
    txtcolLockInDate4: {
      name: "txtcolLockInDate4",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Lock In Date4",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolLockInDate4_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate4_PROPERTIES
    },
    txtcolLockInDate5: {
      name: "txtcolLockInDate5",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Lock In Date5",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolLockInDate5_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate5_PROPERTIES
    },
    txtcolLockInDate6: {
      name: "txtcolLockInDate6",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Lock In Date6",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolLockInDate6_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate6_PROPERTIES
    },
    txtcolLockInDate7: {
      name: "txtcolLockInDate7",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Lock In Date7",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolLockInDate7_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate7_PROPERTIES
    },
    txtcolLockInDate8: {
      name: "txtcolLockInDate8",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Lock In Date8",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolLockInDate8_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate8_PROPERTIES
    },
    txtcolLockInDate9: {
      name: "txtcolLockInDate9",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Lock In Date9",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolLockInDate9_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate9_PROPERTIES
    },
    txtcolLockInDate10: {
      name: "txtcolLockInDate10",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Lock In Date10",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolLockInDate10_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate10_PROPERTIES
    },
    txtcolDryLandAcresC: {
      name: "txtcolDryLandAcresC",
      type: "TextBoxWidget",
      colName: "txtColDryLandAcres",
      parent: "gridContractLimitSearchData",
      Label: "textboxwidget72",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDryLandAcresC_PROPERTIES

      // END_USER_CODE-USER_txtcolDryLandAcresC_PROPERTIES
    },
    txtLockInDate1: {
      name: "txtLockInDate1",
      type: "TextBoxWidget",
      colName: "txtcolLockInDate1",
      parent: "gridContractLimitSearchData",
      Label: "textboxwidget72",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtLockInDate1_PROPERTIES
    },
    txtLockInDate2: {
      name: "txtLockInDate2",
      type: "TextBoxWidget",
      colName: "txtcolLockInDate2",
      parent: "gridContractLimitSearchData",
      Label: "textboxwidget72",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLockInDate2_PROPERTIES

      // END_USER_CODE-USER_txtLockInDate2_PROPERTIES
    },
    txtLockInDate3: {
      name: "txtLockInDate3",
      type: "TextBoxWidget",
      colName: "txtcolLockInDate3",
      parent: "gridContractLimitSearchData",
      Label: "textboxwidget72",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLockInDate3_PROPERTIES

      // END_USER_CODE-USER_txtLockInDate3_PROPERTIES
    },
    txtLockInDate4: {
      name: "txtLockInDate4",
      type: "TextBoxWidget",
      colName: "txtcolLockInDate4",
      parent: "gridContractLimitSearchData",
      Label: "textboxwidget72",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLockInDate4_PROPERTIES

      // END_USER_CODE-USER_txtLockInDate4_PROPERTIES
    },
    txtLockInDate5: {
      name: "txtLockInDate5",
      type: "TextBoxWidget",
      colName: "txtcolLockInDate5",
      parent: "gridContractLimitSearchData",
      Label: "textboxwidget72",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLockInDate5_PROPERTIES

      // END_USER_CODE-USER_txtLockInDate5_PROPERTIES
    },
    txtLockInDate6: {
      name: "txtLockInDate6",
      type: "TextBoxWidget",
      colName: "txtcolLockInDate6",
      parent: "gridContractLimitSearchData",
      Label: "textboxwidget72",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLockInDate6_PROPERTIES

      // END_USER_CODE-USER_txtLockInDate6_PROPERTIES
    },
    txtLockInDate7: {
      name: "txtLockInDate7",
      type: "TextBoxWidget",
      colName: "txtcolLockInDate7",
      parent: "gridContractLimitSearchData",
      Label: "textboxwidget72",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLockInDate7_PROPERTIES

      // END_USER_CODE-USER_txtLockInDate7_PROPERTIES
    },
    txtLockInDate8: {
      name: "txtLockInDate8",
      type: "TextBoxWidget",
      colName: "txtcolLockInDate8",
      parent: "gridContractLimitSearchData",
      Label: "textboxwidget72",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLockInDate8_PROPERTIES

      // END_USER_CODE-USER_txtLockInDate8_PROPERTIES
    },
    txtLockInDate9: {
      name: "txtLockInDate9",
      type: "TextBoxWidget",
      colName: "txtcolLockInDate9",
      parent: "gridContractLimitSearchData",
      Label: "textboxwidget72",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLockInDate9_PROPERTIES

      // END_USER_CODE-USER_txtLockInDate9_PROPERTIES
    },
    txtLockInDate10: {
      name: "txtLockInDate10",
      type: "TextBoxWidget",
      colName: "txtcolLockInDate10",
      parent: "gridContractLimitSearchData",
      Label: "textboxwidget72",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLockInDate10_PROPERTIES

      // END_USER_CODE-USER_txtLockInDate10_PROPERTIES
    },
    txtColIrrigatedAcresContracted: {
      name: "txtColIrrigatedAcresContracted",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Irrigated Acres Contracted",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColIrrigatedAcresContracted_PROPERTIES

      // END_USER_CODE-USER_txtColIrrigatedAcresContracted_PROPERTIES
    },
    txtColLoanRepayMethod: {
      name: "txtColLoanRepayMethod",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Loan Repay Method",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColLoanRepayMethod_PROPERTIES

      // END_USER_CODE-USER_txtColLoanRepayMethod_PROPERTIES
    },
    txtColMktGainSharePercent: {
      name: "txtColMktGainSharePercent",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Mkt Gain Share%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColMktGainSharePercent_PROPERTIES

      // END_USER_CODE-USER_txtColMktGainSharePercent_PROPERTIES
    },
    txtColMultiPricing: {
      name: "txtColMultiPricing",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Multi Pricing",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColMultiPricing_PROPERTIES

      // END_USER_CODE-USER_txtColMultiPricing_PROPERTIES
    },
    txtColOleic: {
      name: "txtColOleic",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColOleic_PROPERTIES

      // END_USER_CODE-USER_txtColOleic_PROPERTIES
    },
    txtColOptionPriceCap: {
      name: "txtColOptionPriceCap",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Option Price Cap",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColOptionPriceCap_PROPERTIES

      // END_USER_CODE-USER_txtColOptionPriceCap_PROPERTIES
    },
    txtColOrganic: {
      name: "txtColOrganic",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Organic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColOrganic_PROPERTIES

      // END_USER_CODE-USER_txtColOrganic_PROPERTIES
    },
    txtColPayRebatAt: {
      name: "txtColPayRebatAt",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Pay Rebat At",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPayRebatAt_PROPERTIES

      // END_USER_CODE-USER_txtColPayRebatAt_PROPERTIES
    },
    txtColPeanut: {
      name: "txtColPeanut",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Peanut",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPeanut_PROPERTIES

      // END_USER_CODE-USER_txtColPeanut_PROPERTIES
    },
    txtColPricePerTon: {
      name: "txtColPricePerTon",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Price Per Ton",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPricePerTon_PROPERTIES

      // END_USER_CODE-USER_txtColPricePerTon_PROPERTIES
    },
    txtColPriceWatchEndDate: {
      name: "txtColPriceWatchEndDate",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Price Watch End Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPriceWatchEndDate_PROPERTIES

      // END_USER_CODE-USER_txtColPriceWatchEndDate_PROPERTIES
    },
    txtColPricingTypeDesc: {
      name: "txtColPricingTypeDesc",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Pricing Type Desc",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPricingTypeDesc_PROPERTIES

      // END_USER_CODE-USER_txtColPricingTypeDesc_PROPERTIES
    },
    txtColPricingType: {
      name: "txtColPricingType",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Pricing Type ",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPricingType_PROPERTIES

      // END_USER_CODE-USER_txtColPricingType_PROPERTIES
    },
    txtColSeg: {
      name: "txtColSeg",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSeg_PROPERTIES

      // END_USER_CODE-USER_txtColSeg_PROPERTIES
    },
    txtColTonsAllocated: {
      name: "txtColTonsAllocated",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Tons Allocated",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColTonsAllocated_PROPERTIES

      // END_USER_CODE-USER_txtColTonsAllocated_PROPERTIES
    },
    txtColTonsContracted: {
      name: "txtColTonsContracted",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Tons Contracted",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColTonsContracted_PROPERTIES

      // END_USER_CODE-USER_txtColTonsContracted_PROPERTIES
    },
    txtColTonsOpen: {
      name: "txtColTonsOpen",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Tons Open",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColTonsOpen_PROPERTIES

      // END_USER_CODE-USER_txtColTonsOpen_PROPERTIES
    },
    txtColTotalAcresContracted: {
      name: "txtColTotalAcresContracted",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Total Acres Contracted",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColTotalAcresContracted_PROPERTIES

      // END_USER_CODE-USER_txtColTotalAcresContracted_PROPERTIES
    },
    txtcolRebateRate: {
      name: "txtcolRebateRate",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Rebate Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolRebateRate_PROPERTIES

      // END_USER_CODE-USER_txtcolRebateRate_PROPERTIES
    },
    txtcolMarketCeiling: {
      name: "txtcolMarketCeiling",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Market Ceiling",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolMarketCeiling_PROPERTIES

      // END_USER_CODE-USER_txtcolMarketCeiling_PROPERTIES
    },
    txtcolMarketFloor: {
      name: "txtcolMarketFloor",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Market Floor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtcolMarketFloor_PROPERTIES

      // END_USER_CODE-USER_txtcolMarketFloor_PROPERTIES
    },
    txtColVariety: {
      name: "txtColVariety",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColVariety_PROPERTIES

      // END_USER_CODE-USER_txtColVariety_PROPERTIES
    },
    txtColWeeksPriorMaturity: {
      name: "txtColWeeksPriorMaturity",
      type: "GridColumnWidget",
      parent: "gridContractLimitSearchData",
      Label: "Mkt Weeks Prior Maturity",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColWeeksPriorMaturity_PROPERTIES

      // END_USER_CODE-USER_txtColWeeksPriorMaturity_PROPERTIES
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpbxContractDetails",
      Label: "Contract #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES

      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    txtDryLandAcresContracted: {
      name: "txtDryLandAcresContracted",
      type: "TextBoxWidget",
      parent: "grpbxContractsSearch",
      Label: "Dry Land Acres Contracted",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDryLandAcresContracted_PROPERTIES

      // END_USER_CODE-USER_txtDryLandAcresContracted_PROPERTIES
    },
    txtIrrigatedAcresContracted: {
      name: "txtIrrigatedAcresContracted",
      type: "TextBoxWidget",
      parent: "grpbxContractsSearch",
      Label: "Irrigated Acres Contracted",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIrrigatedAcresContracted_PROPERTIES

      // END_USER_CODE-USER_txtIrrigatedAcresContracted_PROPERTIES
    },
    txtIrrigatedAcresContractedC: {
      name: "txtIrrigatedAcresContractedC",
      type: "TextBoxWidget",
      colName: "txtColIrrigatedAcresContracted",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIrrigatedAcresContractedC_PROPERTIES

      // END_USER_CODE-USER_txtIrrigatedAcresContractedC_PROPERTIES
    },
    txtLoanRepayMethod: {
      name: "txtLoanRepayMethod",
      type: "TextBoxWidget",
      colName: "txtColLoanRepayMethod",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanRepayMethod_PROPERTIES

      // END_USER_CODE-USER_txtLoanRepayMethod_PROPERTIES
    },
    txtMktGainSharePercent: {
      name: "txtMktGainSharePercent",
      type: "TextBoxWidget",
      colName: "txtColMktGainSharePercent",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMktGainSharePercent_PROPERTIES

      // END_USER_CODE-USER_txtMktGainSharePercent_PROPERTIES
    },
    txtMultiPricing: {
      name: "txtMultiPricing",
      type: "TextBoxWidget",
      colName: "txtColMultiPricing",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMultiPricing_PROPERTIES

      // END_USER_CODE-USER_txtMultiPricing_PROPERTIES
    },
    txtOleicC: {
      name: "txtOleicC",
      type: "TextBoxWidget",
      colName: "txtColOleic",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOleicC_PROPERTIES

      // END_USER_CODE-USER_txtOleicC_PROPERTIES
    },
    txtOpenTons: {
      name: "txtOpenTons",
      type: "TextBoxWidget",
      parent: "grpbxContractsSearch",
      Label: "Open Tons",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOpenTons_PROPERTIES

      // END_USER_CODE-USER_txtOpenTons_PROPERTIES
    },
    txtOptionPriceCap: {
      name: "txtOptionPriceCap",
      type: "TextBoxWidget",
      colName: "txtColOptionPriceCap",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOptionPriceCap_PROPERTIES

      // END_USER_CODE-USER_txtOptionPriceCap_PROPERTIES
    },
    txtOrganicC: {
      name: "txtOrganicC",
      type: "TextBoxWidget",
      colName: "txtColOrganic",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOrganicC_PROPERTIES

      // END_USER_CODE-USER_txtOrganicC_PROPERTIES
    },
    txtPayRebatAt: {
      name: "txtPayRebatAt",
      type: "TextBoxWidget",
      colName: "txtColPayRebatAt",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayRebatAt_PROPERTIES

      // END_USER_CODE-USER_txtPayRebatAt_PROPERTIES
    },
    txtPeanut: {
      name: "txtPeanut",
      type: "TextBoxWidget",
      colName: "txtColPeanut",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanut_PROPERTIES

      // END_USER_CODE-USER_txtPeanut_PROPERTIES
    },
    txtPricePerTon: {
      name: "txtPricePerTon",
      type: "TextBoxWidget",
      colName: "txtColPricePerTon",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPricePerTon_PROPERTIES

      // END_USER_CODE-USER_txtPricePerTon_PROPERTIES
    },
    txtPriceWatchEndDate: {
      name: "txtPriceWatchEndDate",
      type: "TextBoxWidget",
      colName: "txtColPriceWatchEndDate",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPriceWatchEndDate_PROPERTIES

      // END_USER_CODE-USER_txtPriceWatchEndDate_PROPERTIES
    },
    txtPricingTypeDesc: {
      name: "txtPricingTypeDesc",
      type: "TextBoxWidget",
      colName: "txtColPricingTypeDesc",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPricingTypeDesc_PROPERTIES

      // END_USER_CODE-USER_txtPricingTypeDesc_PROPERTIES
    },
    txtPricingType: {
      name: "txtPricingType",
      type: "TextBoxWidget",
      colName: "txtColPricingType",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPricingType_PROPERTIES

      // END_USER_CODE-USER_txtPricingType_PROPERTIES
    },
    txtSegC: {
      name: "txtSegC",
      type: "TextBoxWidget",
      colName: "txtColSeg",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegC_PROPERTIES

      // END_USER_CODE-USER_txtSegC_PROPERTIES
    },
    txtTonsAllocated: {
      name: "txtTonsAllocated",
      type: "TextBoxWidget",
      parent: "grpbxContractsSearch",
      Label: "Tons Allocated",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTonsAllocated_PROPERTIES

      // END_USER_CODE-USER_txtTonsAllocated_PROPERTIES
    },
    txtTonsAllocatedC: {
      name: "txtTonsAllocatedC",
      type: "TextBoxWidget",
      colName: "txtColTonsAllocated",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTonsAllocatedC_PROPERTIES

      // END_USER_CODE-USER_txtTonsAllocatedC_PROPERTIES
    },
    txtTonsContracted: {
      name: "txtTonsContracted",
      type: "TextBoxWidget",
      parent: "grpbxContractsSearch",
      Label: "Tons Contracted",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTonsContracted_PROPERTIES

      // END_USER_CODE-USER_txtTonsContracted_PROPERTIES
    },
    txtTonsContractedC: {
      name: "txtTonsContractedC",
      type: "TextBoxWidget",
      colName: "txtColTonsContracted",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTonsContractedC_PROPERTIES

      // END_USER_CODE-USER_txtTonsContractedC_PROPERTIES
    },
    txtTonsOpen: {
      name: "txtTonsOpen",
      type: "TextBoxWidget",
      colName: "txtColTonsOpen",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTonsOpen_PROPERTIES

      // END_USER_CODE-USER_txtTonsOpen_PROPERTIES
    },
    txtTotalAcresContracted: {
      name: "txtTotalAcresContracted",
      type: "TextBoxWidget",
      parent: "grpbxContractsSearch",
      Label: "Total Acres Contracted",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalAcresContracted_PROPERTIES

      // END_USER_CODE-USER_txtTotalAcresContracted_PROPERTIES
    },
    txtTotalAcresContractedC: {
      name: "txtTotalAcresContractedC",
      type: "TextBoxWidget",
      colName: "txtColTotalAcresContracted",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalAcresContractedC_PROPERTIES

      // END_USER_CODE-USER_txtTotalAcresContractedC_PROPERTIES
    },
    txtMarketFloor: {
      name: "txtMarketFloor",
      type: "TextBoxWidget",
      colName: "txtcolMarketFloor",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMarketFloor_PROPERTIES

      // END_USER_CODE-USER_txtMarketFloor_PROPERTIES
    },
    txtMarketCeiling: {
      name: "txtMarketCeiling",
      type: "TextBoxWidget",
      colName: "txtcolMarketCeiling",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMarketCeiling_PROPERTIES

      // END_USER_CODE-USER_txtMarketCeiling_PROPERTIES
    },
    txtRebateRate: {
      name: "txtRebateRate",
      type: "TextBoxWidget",
      colName: "txtcolRebateRate",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRebateRate_PROPERTIES

      // END_USER_CODE-USER_txtRebateRate_PROPERTIES
    },
    txtVariety: {
      name: "txtVariety",
      type: "TextBoxWidget",
      colName: "txtColVariety",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVariety_PROPERTIES

      // END_USER_CODE-USER_txtVariety_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxContractDetails",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxContractDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    txtWeeksPriorMaturity: {
      name: "txtWeeksPriorMaturity",
      type: "TextBoxWidget",
      colName: "txtColWeeksPriorMaturity",
      parent: "gridContractLimitSearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWeeksPriorMaturity_PROPERTIES

      // END_USER_CODE-USER_txtWeeksPriorMaturity_PROPERTIES
    },
    grpbxContractLimitSearch: {
      name: "grpbxContractLimitSearch",
      type: "GroupBoxWidget",
      parent: "ContractLimitSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxContractLimitSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxContractLimitSearch_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceMasterManagement#ContractLimitCollPt":{},
        // "SystemMaintenanceMasterManagement#CollectionPointProfile":{},
        // "SystemMaintenanceMasterManagement#BuyingPointTransfer":{},
        // "SystemMaintenanceMasterManagement#WeigherProfile":{},
        // "SystemMaintenanceMasterManagement#BuyingPointProfile":{},
       },
      REVERSE:{
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceMasterManagement#ContractLimitCollPt":{},
        // "SystemMaintenanceMasterManagement#CollectionPointProfile":{},
        // "SystemMaintenanceMasterManagement#BuyingPointTransfer":{},
        // "SystemMaintenanceMasterManagement#WeigherProfile":{},
        // "SystemMaintenanceMasterManagement#BuyingPointProfile":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnExit:{
      DEFAULT:["ContractManagement#MainMenu#DEFAULT#false#Click"]
    },
    btnExportToExcel:{
      DEFAULT:["SystemMaintenanceMasterManagement#ContractLimitCollPt#DEFAULT#true#Click"]
    },
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    _kaledo.thisObj = thisObj
    parentWindow(thisObj);
  });

  useEffect(() => {
    try {
      if (getData(thisObj, "closingOfContractLimitArea") == true) {
        Search();
      }
      setData(thisObj, "closingOfContractLimitArea", false)
    } catch (error) {
      showMessage(thisObj, "error on functions after Contract Limit Area closing")
    }
  }, [getData(thisObj, "closingOfContractLimitArea")]);

  useEffect(() => {
    try {
      if (getData(thisObj, "closingOfContractLimitCollPt") == true) {
        Search();
      }
      setData(thisObj, "closingOfContractLimitCollPt", false)
    } catch (error) {
      showMessage(thisObj, "error on functions after Contract Limit Collection Point closing")
    }
  }, [getData(thisObj, "closingOfContractLimitCollPt")]);

  useEffect(() => {
    try {
      if (getData(thisObj, "closingOfContractLimitBuyPt") == true) {
        Search();
      }
      setData(thisObj, "closingOfContractLimitBuyPt", false)
    } catch (error) {
      showMessage(thisObj, "error on functions after Contract Limit Buying Point closing")
    }
  }, [getData(thisObj, "closingOfContractLimitBuyPt")]);

  useEffect(() => {
    if (getData(thisObj, "closingOfContractLimitAreaExit") != true) {
      let vendor_lookup = getData(thisObj, 'vendorDetails')
      if (vendor_lookup !== null) {
        show(thisObj,'txtVndr');
        setValue(thisObj, "txtVndr", vendor_lookup.key);
        setValue(thisObj, "txtVendor", vendor_lookup.VendorNumber);
        
        // Clear other fields
        let arealist = thisObj.state['ddArea'].valueList;
        let collPtlist = thisObj.state['ddCollectionPoint'].valueList;
        let buyPtlist = thisObj.state['ddBuyingPoint'].valueList;
        if (arealist.length > 0){
          setValue(thisObj, 'ddArea', arealist[0].key);
        }
        if (collPtlist.length > 0){
          setValue(thisObj, 'ddCollectionPoint', collPtlist[0].key);
        }
        if (buyPtlist.length > 0){
          setValue(thisObj,'ddBuyingPoint',buyPtlist[0].key);
        }
        setValue(thisObj, 'txtContract', '');
        clearGridData();
      }
    }
    setData(thisObj, "closingOfContractLimitAreaExit", false)
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const CompId = (sessionStorage.getItem('compId'));
  const CropYear = (JSON.parse(sessionStorage.getItem('year')));
  const companyName = (sessionStorage.getItem('Companyname'));
  const UserId = (sessionStorage.getItem('userid'));
  let accessAreaDelete = false;
  let accessAreaUpdate = false;
  const [loading, setLoading] = useState(false);
  let buyCollectionPtId = []; // Fixed as Part of Prod Issue For collection Point Dropdown Value sorting.
  let buyAreaId = []; // Fixed as Part of Prod Issue For Area Dropdown Value sorting.

  async function FormLoad() {
    try {
      setLoading(true)//Added Loader for better convience Prod Issue INC5626542
      await accessPermissions(); // As part of Prod Issue INC5626542 made Synchronous calls.
      bFillPricingTypeList();
      bFillTypeList();
      await bFillVarietyList();
      bFillSegmentList();
      bFillOleicList();
      bFillOrganicList();
      setValue(thisObj, "txtVendor", "");
      setValue(thisObj, "txtVndr", '');
      hide(thisObj, "txtVndr", false);
      disable(thisObj, 'txtTonsAllocated');
      disable(thisObj, 'txtTonsContracted');
      disable(thisObj, 'txtOpenTons');
      disable(thisObj, 'txtIrrigatedAcresContracted');
      disable(thisObj, 'txtDryLandAcresContracted');
      disable(thisObj, 'txtTotalAcresContracted');
      setData(thisObj, 'FullgridData', '');
      setLoading(false)
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }finally{
      setLoading(false)
    }
  }

  const clearGridData = () => {
    try {
      let gridData = [];
      setValue(thisObj, 'gridContractLimitSearchData', gridData);
      setValue(thisObj, 'txtTonsAllocated', '0');
      setValue(thisObj, 'txtTonsContracted', '0');
      setValue(thisObj, 'txtOpenTons', '0');
      setValue(thisObj, 'txtIrrigatedAcresContracted', '0');
      setValue(thisObj, 'txtDryLandAcresContracted', '0');
      setValue(thisObj, 'txtTotalAcresContracted', '0');
      hide(thisObj, 'btnEdit', false);
      hide(thisObj, 'btnDelete', false);
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const accessPermissions = async () => { // As part of Prod Issue INC5626542 made Synchronous calls.
    try {
      hide(thisObj, 'btnNewOffer', false)
      hide(thisObj, 'btnEdit', false);
      hide(thisObj, 'btnDelete', false);
      disable(thisObj, 'btnExportToExcel');
      disable(thisObj, 'btnPrintGrid');

      let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN0260', null, 'I') // As part of Prod Issue INC5626542 made Asynchronous calls.
        if (response[0].permission == 'Y') {//Made Function Asynchronous As part of Prod Issue.
          await bFillBuyingPointList()
          await bFillAreaList()
          await bFillCollectionPointList()
        }
        else {
          disable(thisObj, 'btnSearch');
        }

      let responseforNewOffer = await ContractManagementService.RetrieveAccessPermissionDetails('PN0260', '001', 'U')// As part of Prod Issue INC5626542 made Asynchronous calls.
        if (responseforNewOffer[0].permission == 'Y') {
          setData(thisObj, 'accessAreaUpdate', true)
          show(thisObj, 'btnNewOffer');
        }

      let response2 = await ContractManagementService.RetrieveAccessPermissionDetails('PN0260', '001', 'D')// As part of Prod Issue INC5626542 made Asynchronous calls.
        if (response2[0].permission == 'Y') {
          setData(thisObj, 'accessAreaDelete', true)
        }

      //areaId permissions
      let responseForAreaId = await ContractManagementService.RetrieveAccessPermissionDetails('PN0260', '001', 'I')// As part of Prod Issue INC5626542 made Asynchronous calls.
        if (responseForAreaId[0].permission == 'Y') {
          show(thisObj, 'ddArea');
          show(thisObj, 'txtVendor');  
          show(thisObj, 'btnVendor'); 
          show(thisObj, "txtVndr");
          show(thisObj, "txtContract"); 
        }
        else {
          hide(thisObj, 'ddArea', false);
          hide(thisObj, 'txtVendor', false);  
          hide(thisObj, 'btnVendor', false); 
          hide(thisObj, "txtVndr", false);
          hide(thisObj, "txtContract", false); 
        }
      //collPt permissions
      let responseForCollPt = await ContractManagementService.RetrieveAccessPermissionDetails('PN0260', '002', 'I')// As part of Prod Issue INC5626542 made Asynchronous calls.
        if (responseForCollPt[0].permission == 'Y') {
          show(thisObj, 'ddCollectionPoint');
        }
        else {
          hide(thisObj, 'ddCollectionPoint', false);
          hide(thisObj, 'txtVendor', false);  
          hide(thisObj, 'btnVendor', false); 
          hide(thisObj, "txtVndr", false);
          hide(thisObj, "txtContract", false); 
        }
      //BuyPt permissions
      let responseForBuyPt = await ContractManagementService.RetrieveAccessPermissionDetails('PN0260', '003', 'I')// As part of Prod Issue INC5626542 made Asynchronous calls.
        if (responseForBuyPt[0].permission == 'Y') {
          show(thisObj, 'ddBuyingPoint'); 
        }
        else {
          hide(thisObj, 'ddBuyingPoint', false);
          hide(thisObj, 'txtVendor', false);  
          hide(thisObj, 'btnVendor', false); 
          hide(thisObj, "txtVndr", false);
          hide(thisObj, "txtContract", false); 
        }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const bFillAreaList = async () => {
    try {
      let js = [];
      js.push({ key: '', description: '>>> Select Area <<<' })
      let data = await SettlementService.RetrieveAreaControlDetails(null)
        if (data !== null && data !== undefined && buyAreaId !== null && buyAreaId !== undefined) {
          for (var i = 0; i < data.length; i++) {
            for (let j = 0 ; j < buyAreaId?.length ; j++){// Fixed as Part of Prod Issue For Area Dropdown Value sorting.
              if(data[i]?.areaId === buyAreaId[j]){
                let obj = { key: data[i].areaId, description: data[i].areaId.trim() + ' - ' + data[i].areaName.trim(), index: i, handlerLocNum: data[i].handlerLocNum }
                js.push(obj)
                break;
              }
            }
          }
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddArea: {
              ...state["ddArea"],
              valueList: js,
            }
          }
        })
        setValue(thisObj, 'ddArea', js[0].key);
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const bFillCollectionPointList = async () => {
    try {
      let js = [];
      js.push({ key: '', description: '>>> Select Collection Point <<<' })
      let data = await SystemMaintenanceMasterManagementService.RetrieveCollectionPointControlDetails(CompId, CropYear, null, null)
      if (data !== null && data !== undefined && buyCollectionPtId !== null && buyCollectionPtId !== undefined) {
        for (var i = 0; i < data.length; i++) {
          for(let j = 0 ; j < buyCollectionPtId?.length; j++){// Fixed as Part of Prod Issue For collection Point Dropdown Value sorting.
            if(data[i]?.coll_pt_id === buyCollectionPtId[j]){
              let obj = { key: data[i].coll_pt_id, description: data[i].coll_pt_id.trim() + ' - ' + data[i].buy_pt_name.trim() }
              js.push(obj)
              break;
            }
          }
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddCollectionPoint: {
            ...state["ddCollectionPoint"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddCollectionPoint', js[0].key);
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }  

  const bFillBuyingPointList = async () => {
    try {
      let js = []; let initialValue;
      js.push({ key: '', description: '>>> Select Buying Point <<<' });
      let data = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0260', null, null, null, null)
      if(data !== null && data !== undefined){
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + ' - ' + data[i].buy_pt_name.trim() }
          js.push(obj)
          buyCollectionPtId.push(data[i].coll_pt_id)//For Collection Point Dropdown Values Sorting.
          buyAreaId.push(data[i].area_id)//For AreaDropdown Values Sorting.
        }
        if (js.length == 2) {
          delete js[0];
          initialValue = js[1].key;
        }
        else {
          initialValue = js[0].key;
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddBuyingPoint: {
              ...state["ddBuyingPoint"],
              valueList: js
            }
          }
        })
        setValue(thisObj, 'ddBuyingPoint', initialValue);
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const bFillPricingTypeList = () => {
    try {
      let js = []
      js.push({ key: '', description: '>>> All Pricings <<<' })
      js.push({ key: 'C-BASIS', description: 'Basis Contract' })
      js.push({ key: 'C-FIRM', description: 'Firm Contract' })
      js.push({ key: 'OPTION', description: 'Option Pricings' })
      js.push({ key: 'FLEX MKT', description: 'Flex Market Pricings' })
      js.push({ key: 'SEED', description: 'Seed Pricings' })
      js.push({ key: 'FLOOR', description: 'Floor Pricings' })
      thisObj.setState(current => {
        return {
          ...current,
          ddPricingType: {
            ...state["ddPricingType"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddPricingType', js[0].key);
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const bFillTypeList = () => {
    try {
      let js = [];
      js.push({ key: '', description: '>>> All Types <<<' })
      ContractManagementService.RetrievePeanutTypeControls().then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeId.trim() + ' - ' + data[i].pnutTypeName.trim() }
          js.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddPeanutType: {
              ...state["ddPeanutType"],
              valueList: js,
            }
          }
        })
      })
      setValue(thisObj, 'ddPeanutType', js[0].key);
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const bFillSegmentList = () => {
    try {
      let js = []
      js.push({ key: '', description: '>>> All Segments <<<' })
      js.push({ key: '1', description: 'Seg1' })
      js.push({ key: '2', description: 'Seg2' })
      js.push({ key: '3', description: 'Seg3' })
      thisObj.setState(current => {
        return {
          ...current,
          ddSeg: {
            ...state["ddSeg"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddSeg', js[0].key);
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const bFillOleicList = () => {
    try {
      let js = []
      js.push({ key: '', description: '>>> All Oleic <<<' })
      js.push({ key: 'None', description: 'None' })  // fixes for oliec filter issue
      js.push({ key: 'Mid', description: 'Mid' })
      js.push({ key: 'High', description: 'High' })
      thisObj.setState(current => {
        return {
          ...current,
          ddOleic: {
            ...state["ddOleic"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddOleic', js[0].key);
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const bFillOrganicList = () => {
    try {
      let js = []
      let peanut_type = getValue(thisObj, 'ddPeanutType');
      js.push({ key: '', description: '>>> All Organic <<<' })
      //if (peanut_type == 'SP' || peanut_type == 'VL' || peanut_type == undefined){
      js.push({ key: 'N', description: 'Not Organic' })
      js.push({ key: 'Y', description: 'Organic' })
      //}
      thisObj.setState(current => {
        return {
          ...current,
          ddOrganic: {
            ...state["ddOrganic"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddOrganic', js[0].key);
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const bFillVarietyList = async() => {
    try {
      let js = []; let buy_pt_id;
      js.push({ key: '', description: '>>> All Varieties <<<' })
      let buying_pt = getValue(thisObj, 'ddBuyingPoint');
      let coll_pt = getValue(thisObj, 'ddCollectionPoint');

      if (buying_pt != null && buying_pt != "" && buying_pt != undefined) {
        buy_pt_id = buying_pt;
      }
      else if (coll_pt != null && coll_pt != "" && coll_pt != undefined) {
        buy_pt_id = coll_pt;
      }
      let peanut_type = getValue(thisObj, 'ddPeanutType');
      let areaId = getValue(thisObj, 'ddArea')
      if (peanut_type != null && peanut_type != "" && peanut_type != undefined) {
        let data = await ContractManagementService.RetrievePeanutVarietyControls(buy_pt_id, null, areaId, peanut_type, null)
        //ContractManagementService.RetrievePeanutVarietyControls(buy_pt_id, null, areaId, peanut_type, null).then(response => {
          //let data = response;
          let count = 0;
          data.forEach(element => {
            count++;
            let newVar = true;
            for (let i = 0; i < count - 1; i++) {
              if ((element.pnut_variety_id).toUpperCase() == (data[i].pnut_variety_id).toUpperCase()) {
                newVar = false;
                break;
              }
            }
            let symbol;
            if (newVar) {
              switch (element.symbol_ind.trim()) {
                case "TRADEMARK":
                  symbol = " \u2122"
                  break;
                case "REGISTERED TRADEMARK":
                  symbol = " \xAE"
                  break;
                case "COPYRIGHT":
                  symbol = " \xA9"
                  break;
                default:
                  symbol = "";
              }
              let obj = { key: element.pnut_variety_id, description: element.pnut_variety_name + symbol }
              js.push(obj)
            }
          });
        //})
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutVariety: {
            ...state["ddPeanutVariety"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddPeanutVariety', js[0].key);
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const onddPeanutTypeChange = async() => {
    try {
      setLoading(true)
      await bFillVarietyList();
      bFillSegmentList();
      bFillOleicList();
      bFillOrganicList();
      setLoading(false)
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange;

  const onddAreaChange = () => {
    try {
      let collPtlist = thisObj.state['ddCollectionPoint'].valueList;
      let buyPtlist = thisObj.state['ddBuyingPoint'].valueList;
      if (collPtlist.length > 0){
        setValue(thisObj, 'ddCollectionPoint', collPtlist[0].key);
      }
      if (buyPtlist.length > 0){
        setValue(thisObj,'ddBuyingPoint',buyPtlist[0].key);
      }
      setValue(thisObj, 'txtContract', '');
      setValue(thisObj, 'txtVendor', '');
      setValue(thisObj, "txtVndr", '');
      hide(thisObj, "txtVndr", false);
      clearGridData();
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onddAreaChange = onddAreaChange;

  const onddCollectionPointChange = () => {
    try {
      let arealist = thisObj.state['ddArea'].valueList;
      let buyPtlist = thisObj.state['ddBuyingPoint'].valueList;
      if (arealist.length > 0){
        setValue(thisObj, 'ddArea', arealist[0].key);
      }
      if (buyPtlist.length > 0){
        setValue(thisObj,'ddBuyingPoint',buyPtlist[0].key);
      }
      setValue(thisObj, 'txtContract', '');
      setValue(thisObj, 'txtVendor', '');
      setValue(thisObj, "txtVndr", '');
      hide(thisObj, "txtVndr", false);
      clearGridData();
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onddCollectionPointChange = onddCollectionPointChange;

  const onddBuyingPointChange = () => {
    try {
      let arealist = thisObj.state['ddArea'].valueList;
      let collPtlist = thisObj.state['ddCollectionPoint'].valueList;
      if (arealist.length > 0){
        setValue(thisObj, 'ddArea', arealist[0].key);
      }
      if (collPtlist.length > 0){
        setValue(thisObj, 'ddCollectionPoint', collPtlist[0].key);
      }
      setValue(thisObj, 'txtContract', '');
      setValue(thisObj, 'txtVendor', '');
      setValue(thisObj, "txtVndr", '');
      hide(thisObj, "txtVndr", false);
      clearGridData();
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onddBuyingPointChange = onddBuyingPointChange;

  const ontxtVendorChange = () => {
    try {
//PRB0054498 - Searching the vendors with vendor number from vendor textbox and convert to uppercase  
    let vendorValue = txtVendor.value.toUpperCase();
      setValue(thisObj, "txtVendor", validateNoSpecialChars(vendorValue))      
      let vendor1 = txtVendor.value;
      if (vendor1.length == 6) {
        ContractManagementService.RetrieveVendorByNumber(vendor1).then(response => {
          let data = response
          if (data !== null && data !== undefined && data.length > 0) {
            show(thisObj, "txtVndr");
            setValue(thisObj, "txtVndr", data[0].name);
          } else {
            setValue(thisObj, "txtVndr", '');
            hide(thisObj, "txtVndr", false);
          }
        })
      }
      else {
        setValue(thisObj, "txtVndr", '');
        hide(thisObj, "txtVndr", false);
      }
  
      // Clear other fields
      let arealist = thisObj.state['ddArea'].valueList;
      let collPtlist = thisObj.state['ddCollectionPoint'].valueList;
      let buyPtlist = thisObj.state['ddBuyingPoint'].valueList;
      if (arealist.length > 0){
        setValue(thisObj, 'ddArea', arealist[0].key);
      }
      if (collPtlist.length > 0){
        setValue(thisObj, 'ddCollectionPoint', collPtlist[0].key);
      }
      if (buyPtlist.length > 0){
        setValue(thisObj,'ddBuyingPoint',buyPtlist[0].key);
      }
      setValue(thisObj, 'txtContract', '');
      clearGridData();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj, 
          "Error on vendor textbox change"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtVendorChange = ontxtVendorChange;  

  const ontxtContractChange = () => {
    try {
      setValue(thisObj, "txtContract", validateNumbersOnly(txtContract.value))

      // Clear other fields
      let arealist = thisObj.state['ddArea'].valueList;
      let collPtlist = thisObj.state['ddCollectionPoint'].valueList;
      let buyPtlist = thisObj.state['ddBuyingPoint'].valueList;
      if (arealist.length > 0){
        setValue(thisObj, 'ddArea', arealist[0].key);
      }
      if (collPtlist.length > 0){
        setValue(thisObj, 'ddCollectionPoint', collPtlist[0].key);
      }
      if (buyPtlist.length > 0){
        setValue(thisObj,'ddBuyingPoint',buyPtlist[0].key);
      }
      setValue(thisObj, 'txtVendor', '');
      setValue(thisObj, "txtVndr", '');
      hide(thisObj, "txtVndr", false);
      clearGridData();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtContract change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtContractChange = ontxtContractChange;

  const validateNumbersOnly = (data) => {
    try {
      var res = ''
      for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
          res += data[i]
        }
      }
      return res;
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const validateNoSpecialChars = (data) => {
    try {
      var res = ''
      for (var i = 0; i < data.length; i++) {
        if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) >= 65 && data[i].charCodeAt(0) <= 90) || (data[i].charCodeAt(0) >= 97 && data[i].charCodeAt(0) <= 112)) {
          res += data[i]
        }
      }
      return res;
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const onbtnSearchClick = () => {
    Search();
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const Search = async () => {
    try {
      let maxcol_lockdate = 0;
      let objinputs = [];
      objinputs.area_id = getValue(thisObj, 'ddArea');
      objinputs.coll_pt_id = getValue(thisObj, 'ddCollectionPoint');
      objinputs.buy_pt_id = getValue(thisObj, 'ddBuyingPoint');
      objinputs.contract_num = getValue(thisObj, 'txtContract');
      objinputs.vendor_num = getValue(thisObj, 'txtVendor');
      objinputs.pricing_type = getValue(thisObj, 'ddPricingType');
      objinputs.pnut_type_id = getValue(thisObj, 'ddPeanutType');
      objinputs.pnut_variety_id = getValue(thisObj, 'ddPeanutVariety');
      objinputs.seg_type = getValue(thisObj, 'ddSeg');
      objinputs.oleic_ind = getValue(thisObj, 'ddOleic');
      objinputs.organic_ind = getValue(thisObj, 'ddOrganic');
      objinputs.record_key = '';

      if (objinputs.contract_num == undefined)
        objinputs.contract_num = ''

      if (objinputs.area_id !== ''){
        objinputs.controlLevel = 'AR'
      }else if (objinputs.coll_pt_id !== ''){
        objinputs.controlLevel = 'CP'
      }else if (objinputs.buy_pt_id !== ''){
        objinputs.controlLevel = 'BP'
      }else if (objinputs.contract_num !== ''){
        objinputs.controlLevel = 'SC'
      }else if (objinputs.vendor_num !== ''){
        objinputs.controlLevel = 'SV'
      }else {
        objinputs.controlLevel = ''
      }
      setData(thisObj,'controlLevel', objinputs.controlLevel);

      if (objinputs.controlLevel == ''){
        if (thisObj.state.ddArea.Visible == true){
          showMessage(thisObj, "Must select to search by an Area, Coll Pt, Buy Pt, Contract#, or Vendor#!");
        } else if (thisObj.state.ddCollectionPoint.Visible == true){
          showMessage(thisObj, "Must select to search by Coll Pt or Buy Pt!");
        } else if (thisObj.state.ddBuyingPoint.Visible == true){
          showMessage(thisObj, "Must select to search by Buy Pt!");
        } else {
          showMessage(thisObj, "No search is allowed!");
        }
        return true;
      }

      hide(thisObj, 'btnEdit', false);
      hide(thisObj, 'btnDelete', false);
      if (objinputs.controlLevel == 'AR' || objinputs.controlLevel == 'SC' || objinputs.controlLevel == 'SV'){
        if (getData(thisObj, 'accessAreaDelete')) {
          show(thisObj, 'btnEdit');
        }
        if (getData(thisObj, 'accessAreaUpdate')) {
          show(thisObj, 'btnDelete');
        }
      }

      let excludedList = [];
      let j = 0;
      if(getValue(thisObj, 'chkboxBasis') == true){
        excludedList[j] = 'C-BASIS';
        j = j + 1;
      }
      if(getValue(thisObj, 'chkboxFirm') == true){ 
        excludedList[j] = 'C-FIRM';
        j = j + 1;
      }
      if(getValue(thisObj, 'chkboxOption') == true){ 
        excludedList[j] = 'OPTION';
        j = j + 1;
      }    
      if(getValue(thisObj, 'chkboxFlex') == true){
        excludedList[j] = 'FLEX MKT';
        j = j + 1;
      }    
      if(getValue(thisObj, 'chkboxSeed') == true){ 
        excludedList[j] = 'SEED';
        j = j + 1;
      }    
      if(getValue(thisObj, 'chkboxFloor') == true){ 
        excludedList[j] = 'FLOOR';
      }

      let tonsAllocated = 0, tonsContracted = 0, openTons = 0, irrigatedAcresContracted = 0, dryLandAcresContracted = 0, totalAcresContracted = 0;
      let gridData = [];
      setValue(thisObj, 'gridContractLimitSearchData', gridData);
      setValue(thisObj, 'txtTonsAllocated', '');
      setValue(thisObj, 'txtTonsContracted', '');
      setValue(thisObj, 'txtOpenTons', '');
      setValue(thisObj, 'txtIrrigatedAcresContracted', '');
      setValue(thisObj, 'txtDryLandAcresContracted', '');
      setValue(thisObj, 'txtTotalAcresContracted', '');

      hideColumn(thisObj,"gridContractLimitSearchData","txtrecordkey");
      //hideColumn(thisObj,"gridContractLimitSearchData","txtVariety");
      //hideColumn(thisObj,"gridContractLimitSearchData","txtMktGainSharePercent");
      //hideColumn(thisObj,"gridContractLimitSearchData","txtOptionPriceCap");

      //enable button in grid for Area and CollPt only
      hideColumn(thisObj,"gridContractLimitSearchData","btncol1");
      if (objinputs.controlLevel == 'AR' || objinputs.controlLevel == 'CP'){
        showColumn(thisObj,"gridContractLimitSearchData","btncol1");
      }

      setLoading(true);
      let data = await SystemMaintenanceMasterManagementService.RetrieveContractPriceLimitDetails(objinputs, 'SEARCH')
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (excludedList.includes(data[i].pricing_type) == false ) { // exclude from grid if any exclude check box selected
            let obj = {};
            if (objinputs.controlLevel == 'AR')
              obj.txtAreaC = data[i].area_id;
            else if (objinputs.controlLevel == 'CP')
              obj.txtAreaC = data[i].coll_pt_id;
            else if (objinputs.controlLevel == 'BP')
              obj.txtAreaC = data[i].buy_pt_id;
            else if (objinputs.controlLevel == 'SV')
              obj.txtAreaC = data[i].vendor_num;
            else if (objinputs.controlLevel == 'SC')
              obj.txtAreaC = data[i].contract_num;
            //obj.btncol1 = '';
            obj.txtrecordkey = data[i].record_key; // hide
            obj.txtPricingType = data[i].pricing_type;
            obj.txtPricingTypeDesc = data[i].pricing_type_desc;
            obj.txtPeanut = data[i].pnut_type_id;
            obj.txtVariety = data[i].pnut_variety_id;
            obj.txtSegC = data[i].seg_type;
            obj.txtOleicC = data[i].oleic_ind;
            obj.txtOrganicC = data[i].organic_ind;
            obj.txtPricePerTon = FormatNumber(data[i].price_per_ton, 2); 
            obj.txtTonsAllocatedC = FormatNumber(data[i].tons_allocated, 4);
            obj.txtTonsContractedC = FormatNumber(data[i].tons_contracted, 4);
            obj.txtTonsOpen = FormatNumber(Number(data[i].tons_allocated) - Number(data[i].tons_contracted),4);
            let tonsOpenPerRow = Number(data[i].tons_allocated) - Number(data[i].tons_contracted);
            obj.txtIrrigatedAcresContractedC =FormatNumber(data[i].irrigated_acre,0);
            obj.txtcolDryLandAcresC = FormatNumber(data[i].dryland_acre,0);
            obj.txtTotalAcresContractedC = FormatNumber(Number(data[i].irrigated_acre) + Number(data[i].dryland_acre),0);
            let totalAcresContractedPerRow = Number(data[i].irrigated_acre) + Number(data[i].dryland_acre)
            if (data[i].market_floor != '') {
              obj.txtMarketFloor = FormatNumber(data[i].market_floor,4);
            } else {
              obj.txtMarketFloor = '';
            }
            if (data[i].market_ceiling != ''){
              obj.txtMarketCeiling = FormatNumber(data[i].market_ceiling,4);
            } else {
              obj.txtMarketCeiling = '';
            }
            if (data[i].rebate_rate != ''){
              obj.txtRebateRate = FormatNumber(data[i].rebate_rate,2);
            } else {
              obj.txtRebateRate = '';
            }
            if (data[i].mkt_gain_share != '') {
              obj.txtMktGainSharePercent = FormatNumber(data[i].mkt_gain_share,2);
            } else {
              obj.txtMktGainSharePercent = '';
            }
            obj.txtLoanRepayMethod = data[i].loan_repay_ind;
            obj.txtMultiPricing = data[i].multi_pricing_ind;
            obj.txtWeeksPriorMaturity = data[i].mkt_wk_prior_mat;
            if (data[i].max_option_price != '') {
              obj.txtOptionPriceCap = FormatNumber(data[i].max_option_price,2);
            } else {
              obj.txtOptionPriceCap = '';
            }
            obj.txtPayRebatAt = data[i].pay_rebate_at;
            if(data[i].price_match_end_date != ''){
              obj.txtPriceWatchEndDate = moment(data[i].price_match_end_date.trim()).format('MM/DD/YYYY');
            }
            let lock_in_data = data[i].lock_in_data;
            if (maxcol_lockdate < lock_in_data.length) {
              maxcol_lockdate = lock_in_data.length;
            }
            for (let j = 0; j < lock_in_data.length; j++){
              if (j == 0){
                obj.txtLockInDate1 = lock_in_data[j].lock_in_pct + "% by " + moment(lock_in_data[j].lock_in_date.trim()).format('MM/DD/YYYY')
              } else if (j == 1){
                obj.txtLockInDate2 = lock_in_data[j].lock_in_pct + "% by " + moment(lock_in_data[j].lock_in_date.trim()).format('MM/DD/YYYY')
              } else if (j == 2){
                obj.txtLockInDate3 = lock_in_data[j].lock_in_pct + "% by " + moment(lock_in_data[j].lock_in_date.trim()).format('MM/DD/YYYY')
              } else if (j == 3){
                obj.txtLockInDate4 = lock_in_data[j].lock_in_pct + "% by " + moment(lock_in_data[j].lock_in_date.trim()).format('MM/DD/YYYY')
              } else if (j == 4){
                obj.txtLockInDate5 = lock_in_data[j].lock_in_pct + "% by " + moment(lock_in_data[j].lock_in_date.trim()).format('MM/DD/YYYY')
              } else if (j == 5){
                obj.txtLockInDate6 = lock_in_data[j].lock_in_pct + "% by " + moment(lock_in_data[j].lock_in_date.trim()).format('MM/DD/YYYY')
              } else if (j == 6){
                obj.txtLockInDate7 = lock_in_data[j].lock_in_pct + "% by " + moment(lock_in_data[j].lock_in_date.trim()).format('MM/DD/YYYY')
              } else if (j == 7){
                obj.txtLockInDate8 = lock_in_data[j].lock_in_pct + "% by " + moment(lock_in_data[j].lock_in_date.trim()).format('MM/DD/YYYY')
              } else if (j == 8){
                obj.txtLockInDate9 = lock_in_data[j].lock_in_pct + "% by " + moment(lock_in_data[j].lock_in_date.trim()).format('MM/DD/YYYY')
              } else if (j == 9){
                obj.txtLockInDate10 = lock_in_data[j].lock_in_pct + "% by " + moment(lock_in_data[j].lock_in_date.trim()).format('MM/DD/YYYY')
              }
            }
            //calc Totals
            tonsAllocated = Number(tonsAllocated) + Number(data[i].tons_allocated);
            tonsContracted = Number(tonsContracted) + Number(data[i].tons_contracted);
            openTons = Number(openTons) + Number(tonsOpenPerRow);
            irrigatedAcresContracted = Number(irrigatedAcresContracted) + Number(data[i].irrigated_acre);
            dryLandAcresContracted = Number(dryLandAcresContracted) + Number(data[i].dryland_acre);
            totalAcresContracted = Number(totalAcresContracted) + Number(totalAcresContractedPerRow);

            gridData.push(obj);
          }
        }

        setValue(thisObj, 'txtTonsAllocated', FormatNumber(tonsAllocated,4));
        setValue(thisObj, 'txtTonsContracted', FormatNumber(tonsContracted,4));
        setValue(thisObj, 'txtOpenTons', FormatNumber(openTons,4));
        setValue(thisObj, 'txtIrrigatedAcresContracted', FormatNumber(irrigatedAcresContracted,0));
        setValue(thisObj, 'txtDryLandAcresContracted', FormatNumber(dryLandAcresContracted,0));
        setValue(thisObj, 'txtTotalAcresContracted', FormatNumber(totalAcresContracted,0));
        setValue(thisObj, 'gridContractLimitSearchData', gridData);
        setData(thisObj, 'FullgridData', gridData);
        if(gridData.length>0){
          enable(thisObj, 'btnExportToExcel');
          enable(thisObj, 'btnPrintGrid');
        } else {
          disable(thisObj, 'btnExportToExcel');
          disable(thisObj, 'btnPrintGrid');
        }
        //Disbale empty lock In date columns
        // for (let x = 1; x <= 10; x++){
        //   if (x <= maxcol_lockdate){
        //     showColumn(thisObj,"gridContractLimitSearchData","txtLockInDate" + x);
        //   } else {
        //     hideColumn(thisObj,"gridContractLimitSearchData","txtLockInDate" + x);
        //   }
        // }
        
        // changing header value based on user selected control value
        if(gridData.length > 0)
        {
          if (document.getElementById('column-txtAreaC') != null) {
            if (objinputs.controlLevel == 'AR') {
              setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Area"}, 2000);
            } else if (objinputs.controlLevel == 'CP') {
              setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Coll Pt."}, 2000);
            } else if (objinputs.controlLevel == 'BP') {
              setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Buy Pt."}, 2000);
            } else if (objinputs.controlLevel == 'SV') {
              setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Vendor#"}, 2000);
            } else if (objinputs.controlLevel == 'SC') {
              setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Contract#"}, 2000);
            }
          }
        }
      }
      else {
        showMessage(thisObj, "No pricing offers found.");
        disable(thisObj, 'btnExportToExcel');
        disable(thisObj, 'btnPrintGrid');
      }
      //Hide columns after Total Acres Contracted column
      hideColumn(thisObj,"gridContractLimitSearchData","txtMarketFloor");
      hideColumn(thisObj,"gridContractLimitSearchData","txtMarketCeiling");
      hideColumn(thisObj,"gridContractLimitSearchData","txtRebateRate");
      hideColumn(thisObj,"gridContractLimitSearchData","txtMktGainSharePercent");
      hideColumn(thisObj,"gridContractLimitSearchData","txtLoanRepayMethod");
      hideColumn(thisObj,"gridContractLimitSearchData","txtMultiPricing");
      hideColumn(thisObj,"gridContractLimitSearchData","txtWeeksPriorMaturity");
      hideColumn(thisObj,"gridContractLimitSearchData","txtOptionPriceCap");
      hideColumn(thisObj,"gridContractLimitSearchData","txtPayRebatAt");
      hideColumn(thisObj,"gridContractLimitSearchData","txtPriceWatchEndDate");
      for (let x = 1; x <= 10; x++){
        hideColumn(thisObj,"gridContractLimitSearchData","txtLockInDate" + x);
      }
      setLoading(false);
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  function FormatNumber(value, fraction = 2) {
    try {
      let formattedNo;
      if (value != null && value != undefined) {
        if (value.toString().indexOf(",") == -1) {
          formattedNo = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: fraction,
            maximumFractionDigits: fraction,
          }).format(value);
        } else {
          formattedNo = value.toString();
        }
      } else {
        formattedNo = "";
      }
      return formattedNo;
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const calGridTotals = () => {
    try {
      let tonsAllocated = 0, tonsContracted = 0, openTons = 0, irrigatedAcresContracted = 0, dryLandAcresContracted = 0, totalAcresContracted = 0;
      let gridFullData = getValue(thisObj, 'gridContractLimitSearchData');
      let controlLeveldata = getData(thisObj,'controlLevel');

      for (let i = 0; i < gridFullData.length; i++) {
        tonsAllocated = Number(tonsAllocated) + Number(gridFullData[i].txtTonsAllocatedC.replace(',',''));
        tonsContracted = Number(tonsContracted) + Number(gridFullData[i].txtTonsContractedC.replace(',',''));
        let tonsOpenPerRow = Number(gridFullData[i].txtTonsAllocatedC.replace(',','')) - Number(gridFullData[i].txtTonsContractedC.replace(',',''));
        openTons = Number(openTons) + Number(tonsOpenPerRow);
        irrigatedAcresContracted = Number(irrigatedAcresContracted) + Number(gridFullData[i].txtIrrigatedAcresContractedC.replace(',',''));
        dryLandAcresContracted = Number(dryLandAcresContracted) + Number(gridFullData[i].txtcolDryLandAcresC.replace(',',''));
        let totalAcresContractedPerRow = Number(gridFullData[i].txtIrrigatedAcresContractedC.replace(',','')) + Number(gridFullData[i].txtcolDryLandAcresC.replace(',',''))
        totalAcresContracted = Number(totalAcresContracted) + Number(totalAcresContractedPerRow);
      }
      setValue(thisObj, 'txtTonsAllocated', FormatNumber(tonsAllocated,4));
      setValue(thisObj, 'txtTonsContracted', FormatNumber(tonsContracted,4));
      setValue(thisObj, 'txtOpenTons', FormatNumber(openTons,4));
      setValue(thisObj, 'txtIrrigatedAcresContracted', FormatNumber(irrigatedAcresContracted,0));
      setValue(thisObj, 'txtDryLandAcresContracted', FormatNumber(dryLandAcresContracted,0));
      setValue(thisObj, 'txtTotalAcresContracted', FormatNumber(totalAcresContracted,0));

      if(gridFullData.length>0){
        enable(thisObj, 'btnExportToExcel');
        enable(thisObj, 'btnPrintGrid');
        // change the Grid header name
        if (document.getElementById('column-txtAreaC') != null) {
          if (controlLeveldata == 'AR') {
            setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Area"}, 2000);
          } else if (controlLeveldata == 'CP') {
            setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Coll Pt."}, 2000);
          } else if (controlLeveldata == 'BP') {
            setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Buy Pt."}, 2000);
          } else if (controlLeveldata == 'SV') {
            setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Vendor#"}, 2000);
          } else if (controlLeveldata == 'SC') {
            setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Contract#"}, 2000);
          }
        }
      } else {
        showMessage(thisObj, "You have excluded all the pricing offers that were found.");
        disable(thisObj, 'btnExportToExcel');
        disable(thisObj, 'btnPrintGrid');
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const excludeGridRows2 = () => {
    try {
      let excludedList = [];
      let gridFullData = getData(thisObj, 'FullgridData');
      let j = 0;
      let gridData = '';
      if (gridFullData.length > 0) {
        setLoading(true);
        if(getValue(thisObj, 'chkboxBasis') == true){
          excludedList[j] = 'C-BASIS';
          j = j + 1;
        }
        if(getValue(thisObj, 'chkboxFirm') == true){ 
          excludedList[j] = 'C-FIRM';
          j = j + 1;
        }
        if(getValue(thisObj, 'chkboxOption') == true){ 
          excludedList[j] = 'OPTION';
          j = j + 1;
        }    
        if(getValue(thisObj, 'chkboxFlex') == true){
          excludedList[j] = 'FLEX MKT';
          j = j + 1;
        }    
        if(getValue(thisObj, 'chkboxSeed') == true){ 
          excludedList[j] = 'SEED';
          j = j + 1;
        }    
        if(getValue(thisObj, 'chkboxFloor') == true){ 
          excludedList[j] = 'FLOOR';
        }

        if(excludedList.length > 0){
          let excludedGridData = [];
          let objList = [];
          for (let i = 0; i < excludedList.length; i++) {
            if (i == 0){
              excludedGridData = gridFullData.filter(gridRow => gridRow['txtPricingType'] !== excludedList[i]);
            }else {
              objList = excludedGridData.filter(gridRow => gridRow['txtPricingType'] !== excludedList[i]);
              excludedGridData = [];
              excludedGridData = objList;
            }
            objList = [];
          }
          setValue(thisObj, 'gridContractLimitSearchData', excludedGridData);
          gridData = excludedGridData;
        } else{
          setValue(thisObj, 'gridContractLimitSearchData', gridFullData);
          gridData = gridFullData;
        }
        
        //To calculate total values
        //calGridTotals();

        let tonsAllocated = 0, tonsContracted = 0, openTons = 0, irrigatedAcresContracted = 0, dryLandAcresContracted = 0, totalAcresContracted = 0;
        //let gridData = getValue(thisObj, 'gridContractLimitSearchData');
        let controlLeveldata = getData(thisObj,'controlLevel');

        for (let i = 0; i < gridData.length; i++) {
          tonsAllocated = Number(tonsAllocated) + Number(gridData[i].txtTonsAllocatedC.replace(',',''));
          tonsContracted = Number(tonsContracted) + Number(gridData[i].txtTonsContractedC.replace(',',''));
          let tonsOpenPerRow = Number(gridData[i].txtTonsAllocatedC.replace(',','')) - Number(gridData[i].txtTonsContractedC.replace(',',''));
          openTons = Number(openTons) + Number(tonsOpenPerRow);
          irrigatedAcresContracted = Number(irrigatedAcresContracted) + Number(gridData[i].txtIrrigatedAcresContractedC.replace(',',''));
          dryLandAcresContracted = Number(dryLandAcresContracted) + Number(gridData[i].txtcolDryLandAcresC.replace(',',''));
          let totalAcresContractedPerRow = Number(gridData[i].txtIrrigatedAcresContractedC.replace(',','')) + Number(gridData[i].txtcolDryLandAcresC.replace(',',''))
          totalAcresContracted = Number(totalAcresContracted) + Number(totalAcresContractedPerRow);
        }
        setValue(thisObj, 'txtTonsAllocated', FormatNumber(tonsAllocated,4));
        setValue(thisObj, 'txtTonsContracted', FormatNumber(tonsContracted,4));
        setValue(thisObj, 'txtOpenTons', FormatNumber(openTons,4));
        setValue(thisObj, 'txtIrrigatedAcresContracted', FormatNumber(irrigatedAcresContracted,0));
        setValue(thisObj, 'txtDryLandAcresContracted', FormatNumber(dryLandAcresContracted,0));
        setValue(thisObj, 'txtTotalAcresContracted', FormatNumber(totalAcresContracted,0));
  
        if(gridData.length>0){
          enable(thisObj, 'btnExportToExcel');
          enable(thisObj, 'btnPrintGrid');
          // change the Grid header name
          if (document.getElementById('column-txtAreaC') != null) {
            if (controlLeveldata == 'AR') {
              setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Area"}, 2000);
            } else if (controlLeveldata == 'CP') {
              setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Coll Pt."}, 2000);
            } else if (controlLeveldata == 'BP') {
              setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Buy Pt."}, 2000);
            } else if (controlLeveldata == 'SV') {
              setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Vendor#"}, 2000);
            } else if (controlLeveldata == 'SC') {
              setTimeout(() => {document.getElementById('column-txtAreaC').firstElementChild.innerHTML = "Contract#"}, 2000);
            }
          }
        } else {
          showMessage(thisObj, "You have excluded all the pricing offers that were found.");
          disable(thisObj, 'btnExportToExcel');
          disable(thisObj, 'btnPrintGrid');
        }
        setLoading(false);
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const onchkboxBasisChange = () => {
    excludeGridRows2();
  }
  thisObj.onchkboxBasisChange = onchkboxBasisChange;

  const onchkboxFirmChange = () => { 
    excludeGridRows2();
  }
  thisObj.onchkboxFirmChange = onchkboxFirmChange;

  const onchkboxOptionChange = () => {  
    excludeGridRows2();
  }
  thisObj.onchkboxOptionChange = onchkboxOptionChange;

  const onchkboxFlexChange = () => { 
    excludeGridRows2();
  }
  thisObj.onchkboxFlexChange = onchkboxFlexChange;

  const onchkboxSeedChange = () => { 
    excludeGridRows2();
  }
  thisObj.onchkboxSeedChange = onchkboxSeedChange;

  const onchkboxFloorChange = () => { 
    excludeGridRows2();
  }
  thisObj.onchkboxFloorChange = onchkboxFloorChange;


  const onbtnNewOfferClick = () => {
    goTo(thisObj, "SystemMaintenanceMasterManagement#ContractLimitArea#DEFAULT#true#Click");
  };
  thisObj.onbtnNewOfferClick = onbtnNewOfferClick;

  const onbtnEditClick = () => {
    try {
      let value = thisObj.state.gridContractLimitSearchData.selected[0];
      if (value !== undefined) {
        let data = {
          "recordKey": value.txtrecordkey,
          "editMode": true
        }
        setData(thisObj, 'ContractLimitSearchData', data); 
        goTo(thisObj, "SystemMaintenanceMasterManagement#ContractLimitArea#DEFAULT#true#Click");
      }
      else {
        showMessage(thisObj, "Invalid record selected. Select a valid row and try again!");
        return false;
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  };
  thisObj.onbtnEditClick = onbtnEditClick;
  
  const onbtnDeleteClick = () => {
    try {
      let objdata = {};
      let value = thisObj.state.gridContractLimitSearchData.selected[0];
      if (value !== undefined) {
        if(value.txtrecordkey == 0){
          showMessage(thisObj, "Invalid record selected. Select a valid row and try again!");
          return false;
        }
        if(value.txtTonsContractedC > 0){
          showMessage(thisObj, "There are active Contract using this pricing offer. Delete not allowed!");
          return false;
        }

        const confirmBox = confirm("Are you sure you wish to delete this Pricing Offer")
        if (confirmBox == true) {
          SystemMaintenanceMasterManagementService.UpdateContractPriceLimit('DELETE', value.txtrecordkey, objdata).then(response => {
            let data = response;
            if (data.status == 200) {
              showMessage(thisObj, data.message, true);
              Search();
            }else{
              showMessage(thisObj, data.message, "An error occured while updating the database.");
            }
          })
        }
        
      }
      else {
        showMessage(thisObj, "Invalid record selected. Select a valid row and try again!");
        return false;
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  };
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  const onbtncol1Click = () => {
    try {
      let highlight = document.getElementsByClassName("activeRow")
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow")
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement
      rowSelected.classList.add("activeRow")
      let rowID = rowSelected.id.substring(4);
      let NewGridValue = getValue(thisObj, 'gridContractLimitSearchData')
      if (NewGridValue[rowID].txtrecordkey != null && NewGridValue[rowID].txtrecordkey != undefined && NewGridValue[rowID].txtrecordkey != "") {
        let recordKey = NewGridValue[rowID].txtrecordkey;
        let data = {
          "recordKey": recordKey
        }
        setData(thisObj, 'ContractLimitSearchData', data); 

        let controlLeveldata = getData(thisObj,'controlLevel');
        if ( controlLeveldata == 'AR'){
          goTo(thisObj, "SystemMaintenanceMasterManagement#ContractLimitCollPt#DEFAULT#true#Click");
        } else if ( controlLeveldata == 'CP'){
          goTo(thisObj, "SystemMaintenanceMasterManagement#ContractLimitBuyPt#DEFAULT#true#Click");
        }
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  };
  thisObj.onbtncol1Click = onbtncol1Click;

  
  const onbtnExportToExcelClick = () => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let datatable = getValue(thisObj, 'gridContractLimitSearchData');
      var fileName = "Contract Limit Search";
      let excelData = [];
      let controlLeveldata = getData(thisObj,'controlLevel');
      if (datatable != undefined) {
        for (let i = 0; i < datatable.length; i++) {
          let rowdata = {};
          if (thisObj.state.gridContractLimitSearchData.columns[0].Visible) {
            if (controlLeveldata == 'AR') {
              rowdata["Area"] = datatable[i].txtAreaC;
            } else if (controlLeveldata == 'CP') {
              rowdata["Coll Pt."] = datatable[i].txtAreaC;
            } else if (controlLeveldata == 'BP') {
              rowdata["Buy Pt."] = datatable[i].txtAreaC;
            } else if (controlLeveldata == 'SV') {
              rowdata["Vendor#"] = datatable[i].txtAreaC;
            } else if (controlLeveldata == 'SC') {
              rowdata["Contract#"] = datatable[i].txtAreaC;
            }
          }
          
          if (thisObj.state.gridContractLimitSearchData.columns[2].Visible) {
            rowdata["Record Key"] = datatable[i].txtrecordkey;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[3].Visible) {
            rowdata["Pricing Type"] = datatable[i].txtPricingType;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[4].Visible) {
            rowdata["Pricing Type Desc"] = datatable[i].txtPricingTypeDesc;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[5].Visible) {
            rowdata["Peanut"] = datatable[i].txtPeanut;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[6].Visible) {
            rowdata["Variety"] = datatable[i].txtVariety;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[7].Visible) {
            rowdata["Seg"] = datatable[i].txtSegC;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[8].Visible) {
            rowdata["Oleic"] = datatable[i].txtOleicC;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[9].Visible) {
            rowdata["Organic"] = datatable[i].txtOrganicC;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[10].Visible) {
            rowdata["Price Per Ton"] = ExcelNumericDataConvert(datatable[i].txtPricePerTon,[',']);
          }
          if (thisObj.state.gridContractLimitSearchData.columns[11].Visible) {
            rowdata["Tons Allocated"] = ExcelNumericDataConvert(datatable[i].txtTonsAllocatedC,[',']);
          }
          if (thisObj.state.gridContractLimitSearchData.columns[12].Visible) {
            rowdata["Tons Contracted"] = ExcelNumericDataConvert(datatable[i].txtTonsContractedC,[',']);
          }
          if (thisObj.state.gridContractLimitSearchData.columns[13].Visible) {
            rowdata["Tons Open"] = ExcelNumericDataConvert(datatable[i].txtTonsOpen,[',']);
          }
          if (thisObj.state.gridContractLimitSearchData.columns[14].Visible) {
            rowdata["Irrigated Acres Contracted"] = ExcelNumericDataConvert(datatable[i].txtIrrigatedAcresContractedC,[',']);
          }
          if (thisObj.state.gridContractLimitSearchData.columns[15].Visible) {
            rowdata["Dry Land Acres Contracted"] = ExcelNumericDataConvert(datatable[i].txtcolDryLandAcresC,[',']);
          }
          if (thisObj.state.gridContractLimitSearchData.columns[16].Visible) {
            rowdata["Total Acres Contracted"] = ExcelNumericDataConvert(datatable[i].txtTotalAcresContractedC,[',']);
          }
          if (thisObj.state.gridContractLimitSearchData.columns[17].Visible) {
            rowdata["Market Floor"] = ExcelNumericDataConvert(datatable[i].txtMarketFloor,[',']);
          }
          if (thisObj.state.gridContractLimitSearchData.columns[18].Visible) {
            rowdata["Market Ceiling"] = ExcelNumericDataConvert(datatable[i].txtMarketCeiling,[',']);
          }
          if (thisObj.state.gridContractLimitSearchData.columns[19].Visible) {
            rowdata["Rebate Rate"] = ExcelNumericDataConvert(datatable[i].txtRebateRate,[',']);
          }
          if (thisObj.state.gridContractLimitSearchData.columns[20].Visible) {
            rowdata["Mkt Gain Share%"] = ExcelNumericDataConvert(datatable[i].txtMktGainSharePercent,[',']);
          }
          if (thisObj.state.gridContractLimitSearchData.columns[21].Visible) {
            rowdata["Loan Repay Method"] = datatable[i].txtLoanRepayMethod;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[22].Visible) {
            rowdata["Multi Pricing"] = datatable[i].txtMultiPricing;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[23].Visible) {
            rowdata["Mkt Weeks Prior Maturity"] = datatable[i].txtWeeksPriorMaturity;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[24].Visible) {
            rowdata["Option Price Cap"] = datatable[i].txtOptionPriceCap;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[25].Visible) {
            rowdata["Pay Rebat At"] = datatable[i].txtPayRebatAt;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[26].Visible) {
            rowdata["Price Watch End Date"] = datatable[i].txtPriceWatchEndDate;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[27].Visible) {
            rowdata["Lock In Date1"] = datatable[i].txtLockInDate1;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[28].Visible) {
            rowdata["Lock In Date2"] = datatable[i].txtLockInDate2;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[29].Visible) {
            rowdata["Lock In Date3"] = datatable[i].txtLockInDate3;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[30].Visible) {
            rowdata["Lock In Date4"] = datatable[i].txtLockInDate4;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[31].Visible) {
            rowdata["Lock In Date5"] = datatable[i].txtLockInDate5;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[32].Visible) {
            rowdata["Lock In Date6"] = datatable[i].txtLockInDate6;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[33].Visible) {
            rowdata["Lock In Date7"] = datatable[i].txtLockInDate7;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[34].Visible) {
            rowdata["Lock In Date8"] = datatable[i].txtLockInDate8;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[35].Visible) {
            rowdata["Lock In Date9"] = datatable[i].txtLockInDate9;
          }
          if (thisObj.state.gridContractLimitSearchData.columns[36].Visible) {
            rowdata["Lock In Date10"] = datatable[i].txtLockInDate10;
          }
          excelData.push(rowdata);
        }

        excelData.push("");
        let dataLength = excelData.length;
        for (let k = dataLength; k < dataLength + 4; k++) {
          let totals = {};
          if (k == dataLength) {
            if (controlLeveldata == 'AR') {
              totals["Area"] = "Totals:";
            } else if (controlLeveldata == 'CP') {
              totals["Coll Pt."] = "Totals:";
            } else if (controlLeveldata == 'BP') {
              totals["Buy Pt."] = "Totals:";
            } else if (controlLeveldata == 'SV') {
              totals["Vendor#"] = "Totals:";
            } else if (controlLeveldata == 'SC') {
              totals["Contract#"] = "Totals:";
            }
            totals["Tons Allocated"] = ExcelNumericDataConvert(getValue(thisObj, 'txtTonsAllocated'), [',']);
            totals["Tons Contracted"] = ExcelNumericDataConvert(getValue(thisObj, 'txtTonsContracted'), [',']);
            totals["Tons Open"] = ExcelNumericDataConvert(getValue(thisObj, 'txtOpenTons'), [',']);
            totals["Irrigated Acres Contracted"] = ExcelNumericDataConvert(getValue(thisObj, 'txtIrrigatedAcresContracted'), [',']);
            totals["Dry Land Acres Contracted"] = ExcelNumericDataConvert(getValue(thisObj, 'txtDryLandAcresContracted'), [',']);
            totals["Total Acres Contracted"] = ExcelNumericDataConvert(getValue(thisObj, 'txtTotalAcresContracted'), [',']);
          }
          excelData.push(totals);
        }
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var FileSaver = require('file-saver');
        FileSaver.saveAs(data, fileName + fileExtension);
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  const onbtnPrintGridClick = () => {
    try {
      var obj = new jsPDF('landscape', 'mm', 'a4');
      let datatable = getValue(thisObj, 'gridContractLimitSearchData');
      let controlLeveldata = getData(thisObj,'controlLevel');
      if (datatable != undefined && datatable != null && datatable != "") {
        let data = []
        let headerarray = []
        if (thisObj.state.gridContractLimitSearchData.columns[0].Visible) {
          if (controlLeveldata == 'AR') {
            headerarray.push("Area")
          } else if (controlLeveldata == 'CP') {
            headerarray.push("Coll Pt.")
          } else if (controlLeveldata == 'BP') {
            headerarray.push("Buy Pt.")
          } else if (controlLeveldata == 'SV') {
            headerarray.push("Vendor#")
          } else if (controlLeveldata == 'SC') {
            headerarray.push("Contract#")
          }
        }
        if (thisObj.state.gridContractLimitSearchData.columns[2].Visible) {
          headerarray.push("Record Key")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[3].Visible) {
          headerarray.push("Pricing Type")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[4].Visible) {
          headerarray.push("Pricing Type Desc")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[5].Visible) {
          headerarray.push("Peanut")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[6].Visible) {
          headerarray.push("Variety")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[7].Visible) {
          headerarray.push("Seg")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[8].Visible) {
          headerarray.push("Oleic")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[9].Visible) {
          headerarray.push("Organic")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[10].Visible) {
          headerarray.push("Price Per Ton")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[11].Visible) {
          headerarray.push("Tons Allocated")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[12].Visible) {
          headerarray.push("Tons Contracted")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[13].Visible) {
          headerarray.push("Tons Open")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[14].Visible) {
          headerarray.push("Irrigated Acres Contracted")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[15].Visible) {
          headerarray.push("Dry Land Acres Contracted")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[16].Visible) {
          headerarray.push("Total Acres Contracted")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[17].Visible) {
          headerarray.push("Market Floor")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[18].Visible) {
          headerarray.push("Market Ceiling")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[19].Visible) {
          headerarray.push("Rebate Rate")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[20].Visible) {
          headerarray.push("Mkt Gain Share%")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[21].Visible) {
          headerarray.push("Loan Repay Method")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[22].Visible) {
          headerarray.push("Multi Pricing")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[23].Visible) {
          headerarray.push("Mkt Weeks Prior Maturity")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[24].Visible) {
          headerarray.push("Option Price Cap")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[25].Visible) {
          headerarray.push("Pay Rebat At")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[26].Visible) {
          headerarray.push("Price Watch End Date")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[27].Visible) {
          headerarray.push("Lock In Date1")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[28].Visible) {
          headerarray.push("Lock In Date2")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[29].Visible) {
          headerarray.push("Lock In Date3")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[30].Visible) {
          headerarray.push("Lock In Date4")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[31].Visible) {
          headerarray.push("Lock In Date5")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[32].Visible) {
          headerarray.push("Lock In Date6")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[33].Visible) {
          headerarray.push("Lock In Date7")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[34].Visible) {
          headerarray.push("Lock In Date8")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[35].Visible) {
          headerarray.push("Lock In Date9")
        }
        if (thisObj.state.gridContractLimitSearchData.columns[36].Visible) {
          headerarray.push("Lock In Date10")
        }

        for (let i = 0; i < datatable.length; i++) {
          let bodyarray = []
          if (thisObj.state.gridContractLimitSearchData.columns[0].Visible) {
            bodyarray.push(datatable[i].txtAreaC)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[2].Visible) {
            bodyarray.push(datatable[i].txtrecordkey)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[3].Visible) {
            bodyarray.push(datatable[i].txtPricingType)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[4].Visible) {
            bodyarray.push(datatable[i].txtPricingTypeDesc)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[5].Visible) {
            bodyarray.push(datatable[i].txtPeanut)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[6].Visible) {
            bodyarray.push(datatable[i].txtVariety)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[7].Visible) {
            bodyarray.push(datatable[i].txtSegC)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[8].Visible) {
            bodyarray.push(datatable[i].txtOleicC)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[9].Visible) {
            bodyarray.push(datatable[i].txtOrganicC)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[10].Visible) {
            bodyarray.push(datatable[i].txtPricePerTon)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[11].Visible) {
            bodyarray.push(datatable[i].txtTonsAllocatedC)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[12].Visible) {
            bodyarray.push(datatable[i].txtTonsContractedC)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[13].Visible) {
            bodyarray.push(datatable[i].txtTonsOpen)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[14].Visible) {
            bodyarray.push(datatable[i].txtIrrigatedAcresContractedC)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[15].Visible) {
            bodyarray.push(datatable[i].txtcolDryLandAcresC)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[16].Visible) {
            bodyarray.push(datatable[i].txtTotalAcresContractedC)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[17].Visible) {
            bodyarray.push(datatable[i].txtMarketFloor)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[18].Visible) {
            bodyarray.push(datatable[i].txtMarketCeiling)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[19].Visible) {
            bodyarray.push(datatable[i].txtRebateRate)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[20].Visible) {
            bodyarray.push(datatable[i].txtMktGainSharePercent)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[21].Visible) {
            bodyarray.push(datatable[i].txtLoanRepayMethod)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[22].Visible) {
            bodyarray.push(datatable[i].txtMultiPricing)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[23].Visible) {
            bodyarray.push(datatable[i].txtWeeksPriorMaturity)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[24].Visible) {
            bodyarray.push(datatable[i].txtOptionPriceCap)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[25].Visible) {
            bodyarray.push(datatable[i].txtPayRebatAt)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[26].Visible) {
            bodyarray.push(datatable[i].txtPriceWatchEndDate)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[27].Visible) {
            bodyarray.push(datatable[i].txtLockInDate1)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[28].Visible) {
            bodyarray.push(datatable[i].txtLockInDate2)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[29].Visible) {
            bodyarray.push(datatable[i].txtLockInDate3)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[30].Visible) {
            bodyarray.push(datatable[i].txtLockInDate4)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[31].Visible) {
            bodyarray.push(datatable[i].txtLockInDate5)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[32].Visible) {
            bodyarray.push(datatable[i].txtLockInDate6)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[33].Visible) {
            bodyarray.push(datatable[i].txtLockInDate7)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[34].Visible) {
            bodyarray.push(datatable[i].txtLockInDate8)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[35].Visible) {
            bodyarray.push(datatable[i].txtLockInDate9)
          }
          if (thisObj.state.gridContractLimitSearchData.columns[36].Visible) {
            bodyarray.push(datatable[i].txtLockInDate10)
          }
          data.push(bodyarray)
        }

        data.push("");
        let dataLength = data.length;
        for (let k = dataLength; k < dataLength + 1; k++) {
          let rowdata = [];
          if (k == dataLength) {
            if (thisObj.state.gridContractLimitSearchData.columns[0].Visible) {
              rowdata.push('Totals:')
            }
            if (thisObj.state.gridContractLimitSearchData.columns[2].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[3].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[4].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[5].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[6].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[7].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[8].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[9].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[10].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[11].Visible) {
              rowdata.push(getValue(thisObj, 'txtTonsAllocated'))
            }
            if (thisObj.state.gridContractLimitSearchData.columns[12].Visible) {
              rowdata.push(getValue(thisObj, 'txtTonsContracted'))
            }
            if (thisObj.state.gridContractLimitSearchData.columns[13].Visible) {
              rowdata.push(getValue(thisObj, 'txtOpenTons'))
            }
            if (thisObj.state.gridContractLimitSearchData.columns[14].Visible) {
              rowdata.push(getValue(thisObj, 'txtIrrigatedAcresContracted'))
            }
            if (thisObj.state.gridContractLimitSearchData.columns[15].Visible) {
              rowdata.push(getValue(thisObj, 'txtDryLandAcresContracted'))
            }
            if (thisObj.state.gridContractLimitSearchData.columns[16].Visible) {
              rowdata.push(getValue(thisObj, 'txtTotalAcresContracted'))
            }
            if (thisObj.state.gridContractLimitSearchData.columns[17].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[18].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[19].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[20].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[21].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[22].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[23].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[24].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[25].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[26].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[27].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[28].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[29].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[30].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[31].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[32].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[33].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[34].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[35].Visible) {
              rowdata.push("")
            }
            if (thisObj.state.gridContractLimitSearchData.columns[36].Visible) {
              rowdata.push("")
            }
          }
          data.push(rowdata);
        }

        obj.autoTable({
          startY: 10,
          startX: 10,
          headStyles: { fontSize: 6 },
          styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
          theme: 'plain',
          fontStyle: 'normal',
          head: [headerarray],
          body: data,
          columnStyles: {
            0: { halign: 'center' },
            1: { halign: 'center' },
            2: { halign: 'left' },
            3: { halign: 'center' },
            4: { halign: 'center' },
            5: { halign: 'center' },
            6: { halign: 'center' },
            7: { halign: 'center' },
            8: { halign: 'right' },
            9: { halign: 'right' },
            10: { halign: 'right' },
            11: { halign: 'right' },
            12: { halign: 'right' },
            13: { halign: 'right' },
            14: { halign: 'right' },
            15: { halign: 'right' },
            16: { halign: 'right' },
            17: { halign: 'right' },
            18: { halign: 'right' },
            19: { halign: 'center' },
            20: { halign: 'center' },
            21: { halign: 'center' },
            22: { halign: 'right' }
          },
        });
      
        var pageCount = obj.internal.getNumberOfPages();
        for (let w = 0; w <= pageCount; w++) {
          obj.setPage(w);
          let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
          obj.setFontSize(7);
          obj.text(companyName.trim() + ' - ' + Number(sessionStorage.getItem('year')) + ' - Contract Price Control - Page : ' + pageCurrent + '/' + pageCount, 10, obj.internal.pageSize.height - 10);
        }
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Failed to print , please provide proper name to file")
          }
        }
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnPrintGridClick = onbtnPrintGridClick;


  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ContractLimitSearch*/}

              {/* END_USER_CODE-USER_BEFORE_ContractLimitSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxContractLimitSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxContractLimitSearch*/}

              <GroupBoxWidget
                conf={state.grpbxContractLimitSearch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxContractDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxContractDetails*/}

                <GroupBoxWidget
                  conf={state.grpbxContractDetails}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_ddArea*/}

                  {/* END_USER_CODE-USER_BEFORE_ddArea*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddArea}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddArea*/}

                  {/* END_USER_CODE-USER_AFTER_ddArea*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPeanut*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPeanut*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPeanut}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPeanut*/}

                  {/* END_USER_CODE-USER_AFTER_lblPeanut*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCollectionPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCollectionPoint*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCollectionPoint}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCollectionPoint*/}

                  {/* END_USER_CODE-USER_AFTER_ddCollectionPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                   {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBuyingPoint}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                  {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutVariety}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                                   {/* START_USER_CODE-USER_BEFORE_txtContract*/}

                  {/* END_USER_CODE-USER_BEFORE_txtContract*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtContract}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtContract*/}

                  {/* END_USER_CODE-USER_AFTER_txtContract*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeg}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                  <ButtonWidget
                    conf={state.btnVendor}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                  {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVndr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                  {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOleic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPricingType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPricingType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPricingType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPricingType*/}

                  {/* END_USER_CODE-USER_AFTER_ddPricingType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOrganic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOrganic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOrganic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOrganic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOrganic*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxContractDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxContractDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxContractsSearch*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxContractsSearch*/}

                <GroupBoxWidget
                  conf={state.grpbxContractsSearch}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_cmmndCntnrContractActions*/}

                  {/* END_USER_CODE-USER_BEFORE_cmmndCntnrContractActions*/}

                  <CommandContainerWidget
                    conf={state.cmmndCntnrContractActions}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_btnNewOffer*/}

                    {/* END_USER_CODE-USER_BEFORE_btnNewOffer*/}

                    <ButtonWidget
                      conf={state.btnNewOffer}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnNewOffer*/}

                    {/* END_USER_CODE-USER_AFTER_btnNewOffer*/}
                    {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                    {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                    <ButtonWidget
                      conf={state.btnEdit}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                    {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                    {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                    {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                    <ButtonWidget
                      conf={state.btnDelete}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                    {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                  </CommandContainerWidget>
                  {/* START_USER_CODE-USER_AFTER_cmmndCntnrContractActions*/}

                  {/* END_USER_CODE-USER_AFTER_cmmndCntnrContractActions*/}
                  {/* START_USER_CODE-USER_BEFORE_gridContractLimitSearchData*/}

                  {/* END_USER_CODE-USER_BEFORE_gridContractLimitSearchData*/}

                  <GridWidget
                    conf={state.gridContractLimitSearchData}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridContractLimitSearchData}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridContractLimitSearchData*/}

                  {/* END_USER_CODE-USER_AFTER_gridContractLimitSearchData*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPrintGrid*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPrintGrid*/}

                  <ButtonWidget
                    conf={state.btnPrintGrid}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPrintGrid*/}

                  {/* END_USER_CODE-USER_AFTER_btnPrintGrid*/}

                  <GroupBoxWidget
                conf={state.grpbxExclude}
                screenConf={state}
                >
                  <LabelWidget
                    values={values}
                    conf={state.lblExcludeGrid}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_BEFORE_chkboxBasis*/}
                  <div className="chkbxGroup">
                {/* END_USER_CODE-USER_BEFORE_chkboxBasis*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxBasis}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxBasis*/}

                {/* END_USER_CODE-USER_AFTER_chkboxBasis*/}
                 {/* START_USER_CODE-USER_BEFORE_chkboxFlex*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxFlex*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxFlex}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxFlex*/}

                {/* END_USER_CODE-USER_AFTER_chkboxFlex*/}
                 {/* START_USER_CODE-USER_BEFORE_chkboxFirm*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxFirm*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxFirm}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxFirm*/}

                {/* END_USER_CODE-USER_AFTER_chkboxFirm*/}
                 {/* START_USER_CODE-USER_BEFORE_chkboxSeed*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxSeed*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxSeed}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxSeed*/}

                {/* END_USER_CODE-USER_AFTER_chkboxSeed*/}
                 {/* START_USER_CODE-USER_BEFORE_chkboxOption*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxOption*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxOption}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxOption*/}

                {/* END_USER_CODE-USER_AFTER_chkboxOption*/}
                 {/* START_USER_CODE-USER_BEFORE_chkboxFloor*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxFloor*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxFloor}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxFloor*/}
 </div>
                {/* END_USER_CODE-USER_AFTER_chkboxFloor*/}
                </GroupBoxWidget>
                  {/* START_USER_CODE-USER_BEFORE_lblTotals*/}

                  {/* END_USER_CODE-USER_BEFORE_lblTotals*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblTotals}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblTotals*/}

                  {/* END_USER_CODE-USER_AFTER_lblTotals*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTonsAllocated*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTonsAllocated*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTonsAllocated}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTonsAllocated*/}

                  {/* END_USER_CODE-USER_AFTER_txtTonsAllocated*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTonsContracted*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTonsContracted*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTonsContracted}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTonsContracted*/}

                  {/* END_USER_CODE-USER_AFTER_txtTonsContracted*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOpenTons*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOpenTons*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOpenTons}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOpenTons*/}

                  {/* END_USER_CODE-USER_AFTER_txtOpenTons*/}
                  {/* START_USER_CODE-USER_BEFORE_txtIrrigatedAcresContracted*/}

                  {/* END_USER_CODE-USER_BEFORE_txtIrrigatedAcresContracted*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtIrrigatedAcresContracted}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtIrrigatedAcresContracted*/}

                  {/* END_USER_CODE-USER_AFTER_txtIrrigatedAcresContracted*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDryLandAcresContracted*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDryLandAcresContracted*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDryLandAcresContracted}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDryLandAcresContracted*/}

                  {/* END_USER_CODE-USER_AFTER_txtDryLandAcresContracted*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalAcresContracted*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalAcresContracted*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalAcresContracted}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalAcresContracted*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalAcresContracted*/}
                  {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                  {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                  <ButtonWidget
                    conf={state.btnExit}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnExit*/}

                  {/* END_USER_CODE-USER_AFTER_btnExit*/}
                  {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  <ButtonWidget
                    conf={state.btnExportToExcel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                  {/* START_USER_CODE-USER_BEFORE_btn1*/}

                  {/* END_USER_CODE-USER_BEFORE_btn1*/}

                  <ButtonWidget
                    conf={state.btn1}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btn1*/}

                  {/* END_USER_CODE-USER_AFTER_btn1*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxContractsSearch*/}

                {/* END_USER_CODE-USER_AFTER_grpbxContractsSearch*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxContractLimitSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxContractLimitSearch*/}

              {/* START_USER_CODE-USER_AFTER_ContractLimitSearch*/}

              {/* END_USER_CODE-USER_AFTER_ContractLimitSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_ContractLimitSearch
);
