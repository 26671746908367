/* eslint-disable*/
import React from "react";
import StockTransfer_StockTransferNewNumber from "./StockTransferNewNumber";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("StockTransferNewNumber Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_StockTransferNewNumber />);
    });
  });
  afterEach(cleanup);
  test("is StockTransferNewNumber Loads Successfully", () => {
    expect(screen.getByText("StockTransferNewNumber")).toBeInTheDocument;
  });
  test("Custom Test Cases for StockTransferNewNumber", () => {
    // START_USER_CODE-USER_StockTransferNewNumber_Custom_Test_Case
    // END_USER_CODE-USER_StockTransferNewNumber_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_StockTransferNewNumber />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("StockTransfer:StockTransferNewNumber_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("StockTransfer:StockTransferNewNumber_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxStockTransferNewNumber(GroupBox Widget) Test Cases", async () => {
    const grpbxStockTransferNewNumber = screen.getByTestId(
      "grpbxStockTransferNewNumber"
    );
    expect(grpbxStockTransferNewNumber.tagName).toBe("BUTTON");
    expect(grpbxStockTransferNewNumber.type).toBe("button");
    expect(grpbxStockTransferNewNumber.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStockTransferNewNumber", () => {
    // START_USER_CODE-USER_grpbxStockTransferNewNumber_TEST
    // END_USER_CODE-USER_grpbxStockTransferNewNumber_TEST
  });
  test("txtTransferNum(Textbox Widget) Test Cases", async () => {
    const txtTransferNum = screen.getByTestId("txtTransferNum");
    expect(txtTransferNum.tagName).toBe("INPUT");
    expect(txtTransferNum.type).toBe("text");
    expect(txtTransferNum.classList).toContain("textboxWidgetClass");
    expect(txtTransferNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransferNewNumber_txtTransferNum")
    );
    await act(async () => {
      userEvent.type(txtTransferNum, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum", () => {
    // START_USER_CODE-USER_txtTransferNum_TEST
    // END_USER_CODE-USER_txtTransferNum_TEST
  });
});
