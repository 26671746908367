/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  CheckboxWidget,
  setData,
  getData,
  setValue,
  getValue,
  enable,
  disable,
  goTo,
  hide,
  show,
  clearValue,
  getSelectedGridValue,
  setSelectedGridValue,
} from "../../shared/WindowImports";

import "./CmaDmaControlProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import { toPascalCase } from "../../Common/Constants";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_CmaDmaControlProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "CmaDmaControlProfile",
    windowName: "CmaDmaControlProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.CmaDmaControlProfile",
    // START_USER_CODE-USER_CmaDmaControlProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "CMA DMA Control Profile",
      scrCode: "PN1120B",
    },
    // END_USER_CODE-USER_CmaDmaControlProfile_PROPERTIES
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxCmaDmaControlProfile",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    chkboxApplyWRDeduc: {
      name: "chkboxApplyWRDeduc",
      type: "CheckBoxWidget",
      parent: "grpbxCmaDmaControlProfile",
      Label: "Apply WR Deductions",
      ColSpan: "3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxApplyWRDeduc_PROPERTIES

      // END_USER_CODE-USER_chkboxApplyWRDeduc_PROPERTIES
    },
    chkboxCMA: {
      name: "chkboxCMA",
      type: "CheckBoxWidget",
      parent: "grpbxCmaDmaControlProfile",
      Label: "CMA",
      ColSpan: "3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA_PROPERTIES
    },
    chkboxDMA: {
      name: "chkboxDMA",
      type: "CheckBoxWidget",
      parent: "grpbxCmaDmaControlProfile",
      Label: "DMA",
      ColSpan: "3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxDMA_PROPERTIES

      // END_USER_CODE-USER_chkboxDMA_PROPERTIES
    },
    chkboxUnappliedLbs: {
      name: "chkboxUnappliedLbs",
      type: "CheckBoxWidget",
      parent: "grpbxCmaDmaControlProfile",
      Label: "Unapplied Lbs Allowed",
      ColSpan: "3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxUnappliedLbs_PROPERTIES

      // END_USER_CODE-USER_chkboxUnappliedLbs_PROPERTIES
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridCMADMAControlProfile",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },
    colCMADMA: {
      name: "colCMADMA",
      type: "GridColumnWidget",
      parent: "gridCMADMAControlProfile",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCMADMA_PROPERTIES

      // END_USER_CODE-USER_colCMADMA_PROPERTIES
    },
    colProducer: {
      name: "colProducer",
      type: "GridColumnWidget",
      parent: "gridCMADMAControlProfile",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProducer_PROPERTIES

      // END_USER_CODE-USER_colProducer_PROPERTIES
    },
    ddStateAbbr: {
      name: "ddStateAbbr",
      type: "DropDownFieldWidget",
      parent: "grpbxCmaDmaControlProfile",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddStateAbbr_PROPERTIES
      SpecialFirstOption:""
      // END_USER_CODE-USER_ddStateAbbr_PROPERTIES
    },
    gridCMADMAControlProfile: {
      name: "gridCMADMAControlProfile",
      type: "GridWidget",
      parent: "grpbxCmaDmaControlProfile",
      gridCellsOrder: "txtcol1,chkbxCol1,txtcolCMADMA,chkbxCol2,txtcolProducer",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isEditable: true,
      isCobolTable: true,
      // START_USER_CODE-USER_gridCMADMAControlProfile_PROPERTIES

      // END_USER_CODE-USER_gridCMADMAControlProfile_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblPDExceptionList: {
      name: "lblPDExceptionList",
      type: "LabelWidget",
      parent: "grpbxCmaDmaControlProfile",
      Label: "Premium / Deduction Exception List:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPDExceptionList_PROPERTIES

      // END_USER_CODE-USER_lblPDExceptionList_PROPERTIES
    },
    txtcol1: {
      name: "txtcol1",
      type: "TextBoxWidget",
      colName: "col1",
      parent: "gridCMADMAControlProfile",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      Enabled: false
      // START_USER_CODE-USER_txtcol1_PROPERTIES

      // END_USER_CODE-USER_txtcol1_PROPERTIES
    },
    txtcolCMADMA: {
      name: "txtcolCMADMA",
      type: "TextBoxWidget",
      colName: "colCMADMA",
      parent: "gridCMADMAControlProfile",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCMADMA_PROPERTIES
      ReadOnly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolCMADMA_PROPERTIES
    },
    chkbxCol1: {
      name: "chkbxCol1",
      type: "CheckBoxWidget",
      colName: "col1Chckbx",
      parent: "gridCMADMAControlProfile",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    col1Chckbx: {
      name: "col1Chckbx",
      type: "GridColumnWidget",
      parent: "gridCMADMAControlProfile",
      HasLabel: false,
      Height: "",
      Width: "",
    },
    chkbxCol2: {
      name: "chkbxCol2",
      type: "CheckBoxWidget",
      colName: "col2Chckbx",
      parent: "gridCMADMAControlProfile",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    col2Chckbx: {
      name: "col2Chckbx",
      type: "GridColumnWidget",
      parent: "gridCMADMAControlProfile",
      HasLabel: false,
      Height: "",
      Width: "",
    },
    txtcolProducer: {
      name: "txtcolProducer",
      type: "TextBoxWidget",
      colName: "colProducer",
      parent: "gridCMADMAControlProfile",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProducer_PROPERTIES
      ReadOnly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolProducer_PROPERTIES
    },
    txtCountry: {
      name: "txtCountry",
      type: "TextBoxWidget",
      parent: "grpbxCmaDmaControlProfile",
      Label: "Country:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCountry_PROPERTIES

      // END_USER_CODE-USER_txtCountry_PROPERTIES
    },
    txtCountryName: {
      name: "txtCountryName",
      type: "TextBoxWidget",
      parent: "grpbxCmaDmaControlProfile",
      Label: "",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCountryName_PROPERTIES

      // END_USER_CODE-USER_txtCountryName_PROPERTIES
    },
    txtState: {
      name: "txtState",
      type: "TextBoxWidget",
      parent: "grpbxCmaDmaControlProfile",
      Label: "State:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtState_PROPERTIES

      // END_USER_CODE-USER_txtState_PROPERTIES
    },
    txtStateName: {
      name: "txtStateName",
      type: "TextBoxWidget",
      parent: "grpbxCmaDmaControlProfile",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStateName_PROPERTIES

      // END_USER_CODE-USER_txtStateName_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxCmaDmaControlProfile",
      Label: "Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxCmaDmaControlProfile",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    grpbxCmaDmaControlProfile: {
      name: "grpbxCmaDmaControlProfile",
      type: "GroupBoxWidget",
      parent: "CmaDmaControlProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCmaDmaControlProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxCmaDmaControlProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "CmaDmaControlProfile",
      Height: "",
      Width: "",
      clonedExtId: "44193",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#VendorLookup": {},
      },
      REVERSE: {
        "ContractManagement#VendorLookup": {},
      }
    }
  };

  let _buttonServices = {};
  let _buttonNavigation = {
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};

  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  });

  //Custom useEffect
  useEffect(() => {
    try {
      clearValue(thisObj, 'txtVndr');
      hide(thisObj, 'txtVndr', false);
      let vendor_lookup = getData(thisObj, 'vendorDetails');
      if (vendor_lookup !== null && vendor_lookup !== undefined && vendor_lookup !== '') {
        thisObj.setFieldValue("txtVendor", vendor_lookup.VendorNumber);
        thisObj.setFieldValue("txtVndr", toPascalCase(vendor_lookup.vendorName));
        show(thisObj, 'txtVndr');
      }
    } catch (err) {
      showMessage(err.message);
    }
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();

    setEventHandlers()
    return () => {
      removeEventHandlers();
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  const setEventHandlers = () => {
    document.getElementById('txtCountry').addEventListener('keypress', allowOnlyNumbers);
  };

  const removeEventHandlers = () => {
    document.getElementById('txtCountry').removeEventListener('keypress', allowOnlyNumbers);
  };

  const allowOnlyNumbers = (e) => {
    try {
      if (!((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8)) {
        e.preventDefault();
      }
    } catch (error) {
      showMessage(thisObj, error?.message);
    }
  };

  // Logic for Form Load
  const FormLoad = async () => {
    try {
      setLoading(true)

      let objData = getData(thisObj, 'cmaDmaControlProfileData');
      let cmdOk = objData.cmdOkCaption;
      document.getElementById('btnOk').innerText = cmdOk;
      if (cmdOk == "Add") {
        disable(thisObj, "txtState");
        setValue(thisObj, "txtCountry", '');
        setValue(thisObj, "txtVendor", '');
        hide(thisObj, "txtCountryName", false);
        hide(thisObj, "txtVndr", false);
        await bFillStateList();
      }
      else {
        let stateAbb = objData.cboStateAbbrText.trim();
        await ontxtVendorChange({
          target: {
            value: objData.txtVendorText
          }
        })

        await ontxtCountryBlur({
          target: {
            value: objData.txtCountyText
          }
        }, objData.txtStateIDText);

        await bFillStateList();
        await bFillState(stateAbb);

        setValue(thisObj, 'txtState', objData?.txtStateIDText)
        setValue(thisObj, 'txtCountry', objData?.txtCountyText)
        setValue(thisObj, 'txtVendor', objData?.txtVendorText)

        setValue(thisObj, "lblAddedByValue", objData?.lblAddedByCaption?.split(" ")[0] + " " + ConvertDateTime(objData?.lblAddedByCaption?.split(" ")[1]));
        setValue(thisObj, "lblChangedByValue", objData?.lblChangedByCaption?.split(" ")[0] + " " + ConvertDateTime(objData?.lblChangedByCaption?.split(" ")[1]));

        show(thisObj, "txtCountryName");
        show(thisObj, 'txtVndr');

        disable(thisObj, "txtState");
        disable(thisObj, "txtStateName");
        disable(thisObj, "txtCountry");
        disable(thisObj, "txtCountryName");
        disable(thisObj, "txtVendor");
        disable(thisObj, "txtVndr");
        disable(thisObj, "btnVendor");

        setValue(thisObj, "chkboxCMA", objData.chkCMAValue);
        setValue(thisObj, "chkboxDMA", objData.chkDMAValue);
        setValue(thisObj, "chkboxApplyWRDeduc", objData.chkApplyDeductionsValue);
        setValue(thisObj, "chkboxUnappliedLbs", objData.chkAllowUnapplyValue);
      }
      disable(thisObj, 'txtCountryName');
      await fillGrid();
    }
    catch (err) {
      thisObj.showMessage = err.message;
    }
    finally {
      setLoading(false)
    }
  }

  // Logic for bFormValid
  async function bFormValid() {
    try {
      let textState = getValue(thisObj, "txtState");
      let stateAbbr = getValue(thisObj, "ddStateAbbr");
      let textCountry = getValue(thisObj, "txtCountry");
      let textVendor = getValue(thisObj, "txtVendor");
      let textVndr = getValue(thisObj, "txtVndr");
      let chkbxCMA = getValue(thisObj, "chkboxCMA");
      let chkbxDMA = getValue(thisObj, "chkboxDMA");
      let chkbxApplyWRDeduc = getValue(thisObj, "chkboxApplyWRDeduc");
      let chkbxUnappliedLbs = getValue(thisObj, "chkboxUnappliedLbs");

      chkbxCMA = chkbxCMA == true ? 'Y' : 'N'
      chkbxDMA = chkbxDMA == true ? 'Y' : 'N'
      chkbxApplyWRDeduc = chkbxApplyWRDeduc == true ? 'Y' : 'N'
      chkbxUnappliedLbs = chkbxUnappliedLbs == true ? 'Y' : 'N'

      if (textState == undefined || textState == "" || textState == null) {
        showMessage(thisObj, "Must select a State!");
        return false;
      }

      if (textCountry == undefined || textCountry == "" || textCountry.length < 3) {
        showMessage(thisObj, "Must enter a 3 digit County code!");
        return false;
      }

      if (textVendor == undefined || textVendor == "" || textVendor == null) {
        showMessage(thisObj, "Must enter a valid Vendor number.");
        return false;
      }
      else {
        const lstrvendor = await ContractManagementService.RetrieveVendorByNumber(textVendor)
        if (lstrvendor.length <= 0) {
          showMessage(thisObj, "Please enter an existing Vendor number.");
          return false;
        }
      }

      if (chkbxCMA == "N" && chkbxDMA == "N") {
        showMessage(thisObj, "Must select either the CMA and/or DMA indicator(s)!");
        return false;
      }

      return true;
    }
    catch (err) {
      showMessage(thisObj, err.message);
      return false
    }
  }

  // Logic for change event of State dropdown..
  const onddStateAbbrChange = async () => {
    try {
      let objData = getData(thisObj, 'cmaDmaControlProfileData');
      let cmdOk = objData.cmdOkCaption;
      if (cmdOk?.trim()?.toLocaleUpperCase() == "ADD") {
        await bFillState();
      }
    }
    catch (err) {
      showMessage(err.message);
    }
  };
  thisObj.onddStateAbbrChange = onddStateAbbrChange;

  // Logic bFill State
  async function bFillState(stateAbb) {
    let stateId = ""
    let stateName = ""
    let objData = getData(thisObj, 'cmaDmaControlProfileData');
    let cmdOk = objData.cmdOkCaption;
    let stateAbbrevations = null;

    const buyPtId = null;
    stateAbbrevations = cmdOk == "Update" ? stateAbb : getValue(thisObj, "ddStateAbbr");

    let value = "false&stateAbbr=" + stateAbbrevations;
    await ContractManagementService.RetrievePeanutStateControlDetails(buyPtId, stateAbbrevations, value).then(response => {
      for (var i = 0; i < response?.length; i++) {
        stateId = response[i]?.stateId
        stateName = response[i]?.stateName?.trim()
      }
    })

    setValue(thisObj, "txtStateName", stateName);

    if (stateId?.length > 0) {
      setValue(thisObj, "txtState", stateId);
      disable(thisObj, 'txtState')
    }

    if (stateName?.length > 0) {
      show(thisObj, 'txtStateName');
      disable(thisObj, 'txtStateName');
    }
    else {
      hide(thisObj, "txtStateName", false);
    }
  }

  // Logic for vendor change event
  const ontxtVendorChange = async (event) => {
    try {
      let venname = null;
//PRB0054498 - Searching the vendors with vendor number from vendor textbox and convert to uppercase  
    let VendorNum = event?.target?.value?.toUpperCase();
  
      if (VendorNum?.length >= 6) {
        let response = await ContractManagementService.RetrieveVendorByNumber(VendorNum);
        if (response?.length > 0) {
          venname = toPascalCase(response[0]?.name);
        } else {
          return;
        }
  
        if (venname != null && venname != undefined && venname?.length > 0) {
          setValue(thisObj, "txtVndr", venname)
          show(thisObj, 'txtVndr');
          disable(thisObj, 'txtVndr');
        }
        else {
          hide(thisObj, 'txtVndr', false);
        }
      }
      else {
        hide(thisObj, 'txtVndr', false);
      }
    } catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtVendorChange = ontxtVendorChange  

  // Logic for County change event
  const ontxtCountryBlur = async (event, stateValue) => {
    try {
      // For Country text box input validation
      let countyNum = event?.target?.value
      let stateAbbr = getValue(thisObj, 'ddStateAbbr') || stateValue;

      let objData = getData(thisObj, 'cmaDmaControlProfileData');
      let cmdOk = objData.cmdOkCaption;
      if (countyNum.length == 3) {
        let response = await ContractManagementService.RetrieveCountyControlDetails(null, null, stateAbbr, countyNum);
        if (response?.length > 0) {
          setValue(thisObj, 'txtCountryName', response[0]?.countyName)
          show(thisObj, 'txtCountryName');
        }
        else if (cmdOk == "Update") {
          setValue(thisObj, 'txtCountryName', objData?.lblCountyNameCaption.trim())
          show(thisObj, 'txtCountryName');
        }
        else {
          hide(thisObj, 'txtCountryName', false);
        }
      }
      else {
        hide(thisObj, 'txtCountryName', false);
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.ontxtCountryBlur = ontxtCountryBlur;

  // Logic for bind State records
  const bFillStateList = async () => {
    let jsState = []
    await ContractManagementService.RetrievePeanutStateControlDetails(null, null, false).then(response => {
      if (response != undefined && response != null && response?.length != 0) {
        for (var i = 0; i < response.length; i++) {
          jsState.push({
            key: response[i].stateAbbr,
            description: response[i].stateAbbr
          })
        }
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddStateAbbr: {
            ...state["ddStateAbbr"],
            valueList: jsState,
          }
        }
      })
    })

    let objData = getData(thisObj, 'cmaDmaControlProfileData');
    let cmdOk = objData.cmdOkCaption;
    if (cmdOk == "Update") {
      let stateAbb = objData.cboStateAbbrText.trim();
      setValue(thisObj, "ddStateAbbr", stateAbb);
      disable(thisObj, "ddStateAbbr");
    }
    else {
      hide(thisObj, 'txtStateName', false);
      disable(thisObj, "txtStateName");
    }
  }

  // Logic for grid bind
  const fillGrid = async () => {
    try {
      let objData = getData(thisObj, 'cmaDmaControlProfileData');
      let cmdOk = objData.cmdOkCaption;
      let data = objData.cmaDmaDeductExceptControl;
      const gridData = [];
      let js = []
      const gridList = await AccountPayment.RetrievePremiumDeductionMasterDetails(null, null);
      if (gridList != undefined && gridList != null && gridList != "") {
        for (let i = 0; i < gridList.length; i++) {
          if (gridList[i].cma_ind_flag == "Y") {
            js.push(gridList[i])
            gridData.push({
              txtcol1: gridList[i].pd_code,
              txtcolCMADMA: "CMA/DMA",
              txtcolProducer: "Producer",
              chkbxCol1: true,
              chkbxCol2: false,
            });
          }
        }
      }
      if (cmdOk == "Update") {
        for (let i = 0; i < gridData.length; i++) {
          for (let j = 0; j < data.length; j++) {
            if (data[j].pd_code == gridData[i].txtcol1) {
              gridData[i].chkbxCol1 = false;
              gridData[i].chkbxCol2 = true;
            }
          }
        }
      }
      setValue(thisObj, 'gridCMADMAControlProfile', gridData);
    }
    catch (err) {
      showMessage(thisObj, err.message);
      setLoading(false);
      return false;
    }
  };

  // Logic for grid Row Select
  const ongridCMADMAControlProfileRowSelect = async (rowSelected) => {
    try {
      let data = rowSelected.selectedRows[0];
      let rowChecked = false;
      let gridData = getValue(thisObj, 'gridCMADMAControlProfile');
      if (data.chkbxCol2 == true) {
        data.chkbxCol1 = false;
        setSelectedGridValue(thisObj, "gridCMADMAControlProfile", "chkbxCol1", false)
      }

      if (data.chkbxCol1 == true) {
        data.chkbxCol2 = false;
        setSelectedGridValue(thisObj, "gridCMADMAControlProfile", "chkbxCol2", false)
      }
    }
    catch (err) {
      showMessage(thisObj, err?.message)
    }
  }
  thisObj.ongridCMADMAControlProfileRowSelect = ongridCMADMAControlProfileRowSelect;

  // Implement Add and Update button functionality
  const onbtnOkClick = async () => {
    try {
      if (await bFormValid()) {
        let LstrHTTP;
        let lstrxml = "";
        let strPDExcept = "";
        let buy_pt_id = '2CY';

        const gridData = getValue(thisObj, 'gridCMADMAControlProfile');
        gridData.forEach(rowItem => {
          if (rowItem.chkbxCol2) {
            if (strPDExcept !== "") {
              strPDExcept += "|";
            }
            strPDExcept += rowItem.txtcol1?.toString()?.trim();
          }

        });

        let objData = getData(thisObj, 'cmaDmaControlProfileData');
        let cmdOk = objData.cmdOkCaption;
        let textState = getValue(thisObj, "txtState");
        let cboStateAbbr = getValue(thisObj, "ddStateAbbr");
        let textCountry = getValue(thisObj, "txtCountry");
        let textVendor = getValue(thisObj, "txtVendor");
        let chkbxCMA = getValue(thisObj, "chkboxCMA");
        let chkbxDMA = getValue(thisObj, "chkboxDMA");
        let chkbxApplyWRDeduc = getValue(thisObj, "chkboxApplyWRDeduc");
        let chkbxUnappliedLbs = getValue(thisObj, "chkboxUnappliedLbs");

        chkbxCMA = chkbxCMA == true ? 'Y' : 'N'
        chkbxDMA = chkbxDMA == true ? 'Y' : 'N'
        chkbxApplyWRDeduc = chkbxApplyWRDeduc == true ? 'Y' : 'N'
        chkbxUnappliedLbs = chkbxUnappliedLbs == true ? 'Y' : 'N'

        if (cmdOk == "Add") {
          LstrHTTP = {
            "county_id": textCountry,
            "state_id": textState,
            "state_abbr": cboStateAbbr,
            "cma_ind": chkbxCMA,
            "dma_ind": chkbxDMA,
            "apply_deduct_ind": chkbxApplyWRDeduc,
            "allow_unappl_lbs": chkbxUnappliedLbs,
            "vendor_num": textVendor,
            "p_d_except": strPDExcept
          }
          lstrxml = await SystemMaintenanceSpecialFunctionsService.CreateCmaDmaControls(buy_pt_id, LstrHTTP);
        }
        else if (cmdOk == "Update") {
          LstrHTTP = {
            "cma_ind": chkbxCMA,
            "dma_ind": chkbxDMA,
            "apply_deduct_ind": chkbxApplyWRDeduc,
            "allow_unappl_lbs": chkbxUnappliedLbs,
            "p_d_except": strPDExcept
          }
          lstrxml = await SystemMaintenanceSpecialFunctionsService.UpdateCmaDmaControls(buy_pt_id, textState, textCountry, textVendor, LstrHTTP);
        }

        if (lstrxml.status == '200') {
          if (cmdOk == "Add") {
            alert("Vendor information added to CMA DMA Control table.")
          } else {
            alert("Vendor information updated on CMA DMA Control table.")
          }
          setData(thisObj, 'CmaDmaControlSetupReload', true)
        }
        else {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. " + "\n\n" + lstrxml.message.trim());
          return
        }

        await onbtnCancelClick()
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
      return false;
    }
  }
  thisObj.onbtnOkClick = onbtnOkClick;

  // Implement functionality for Cancel button___
  const onbtnCancelClick = async () => {
    try {
      clearValue(thisObj, 'txtVendorNum');
      clearValue(thisObj, 'txtVndr');
      document.getElementById("SystemMaintenanceSpecialFunctions_CmaDmaControlProfilePopUp").childNodes[0].click();
      return true;
    }
    catch (err) {
      showMessage(thisObj, err.message);
      return false;
    }
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
// Logic for change cma dma
  const onchkboxDMAChange = (event) => {
    try {
      if (event?.target?.value) {
        setValue(thisObj, 'chkboxApplyWRDeduc', true);
      }
    }
    catch (err) {
      showMessage(thisObj, err.message)
    }
  }
  thisObj.onchkboxDMAChange = onchkboxDMAChange;
// Logic for check box change of cma dma exception
  const onchkbxCol2Change = (event) => {
    try {
      let index = event?.target?.name?.split(".")[1]
      let gridData = getValue(thisObj, 'gridCMADMAControlProfile');
      for (var i = 0; i < gridData?.length; i++) {
        if (i == index) {
          gridData[i].chkbxCol1 = false;
          gridData[i].chkbxCol2 = true;
          break;
        }
      }
      setValue(thisObj, 'gridCMADMAControlProfile', gridData)
    }
    catch (err) {
      showMessage(thisObj, err.message)
    }
  }
  thisObj.onchkbxCol2Change = onchkbxCol2Change
// Logic for check box change for column 1 of cma dma exception
  const onchkbxCol1Change = (event) => {
    try {
      let index = event?.target?.name?.split(".")[1]
      let gridData = getValue(thisObj, 'gridCMADMAControlProfile');
      for (var i = 0; i < gridData?.length; i++) {
        if (i == index) {
          gridData[i].chkbxCol1 = true;
          gridData[i].chkbxCol2 = false;
          break;
        }
      }
      setValue(thisObj, 'gridCMADMAControlProfile', gridData)
    }
    catch (err) {
      showMessage(thisObj, err.message)
    }
  }
  thisObj.onchkbxCol1Change = onchkbxCol1Change
// Logic for date format
  function ConvertDateTime(dateTime) {
    try {
      const d = new Date(dateTime)
      return [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/')
    }
    catch (err) {
      errorHandler(err)
    }
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_CmaDmaControlProfile*/}

              {/* END_USER_CODE-USER_BEFORE_CmaDmaControlProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCmaDmaControlProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCmaDmaControlProfile*/}

              <GroupBoxWidget
                conf={state.grpbxCmaDmaControlProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtState*/}

                {/* END_USER_CODE-USER_BEFORE_txtState*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtState}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtState*/}

                {/* END_USER_CODE-USER_AFTER_txtState*/}
                {/* START_USER_CODE-USER_BEFORE_ddStateAbbr*/}

                {/* END_USER_CODE-USER_BEFORE_ddStateAbbr*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddStateAbbr}
                  screenConf={state}
                ></DropDownWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStateName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_ddStateAbbr*/}

                {/* END_USER_CODE-USER_AFTER_ddStateAbbr*/}
                {/* START_USER_CODE-USER_BEFORE_txtCountry*/}

                {/* END_USER_CODE-USER_BEFORE_txtCountry*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCountry}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCountryName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCountry*/}

                {/* END_USER_CODE-USER_AFTER_txtCountry*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxCMA*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxCMA*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxCMA}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxCMA*/}

                {/* END_USER_CODE-USER_AFTER_chkboxCMA*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxDMA*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxDMA*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxDMA}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxDMA*/}

                {/* END_USER_CODE-USER_AFTER_chkboxDMA*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxApplyWRDeduc*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxApplyWRDeduc*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxApplyWRDeduc}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxApplyWRDeduc*/}

                {/* END_USER_CODE-USER_AFTER_chkboxApplyWRDeduc*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxUnappliedLbs*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxUnappliedLbs*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxUnappliedLbs}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxUnappliedLbs*/}

                {/* END_USER_CODE-USER_AFTER_chkboxUnappliedLbs*/}
                {/* START_USER_CODE-USER_BEFORE_lblPDExceptionList*/}

                {/* END_USER_CODE-USER_BEFORE_lblPDExceptionList*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPDExceptionList}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPDExceptionList*/}

                {/* END_USER_CODE-USER_AFTER_lblPDExceptionList*/}
                {/* START_USER_CODE-USER_BEFORE_gridCMADMAControlProfile*/}

                {/* END_USER_CODE-USER_BEFORE_gridCMADMAControlProfile*/}

                <GridWidget
                  conf={state.gridCMADMAControlProfile}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridCMADMAControlProfile}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridCMADMAControlProfile*/}

                {/* END_USER_CODE-USER_AFTER_gridCMADMAControlProfile*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCmaDmaControlProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCmaDmaControlProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_CmaDmaControlProfile*/}

              {/* END_USER_CODE-USER_AFTER_CmaDmaControlProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_CmaDmaControlProfile
);
