/* eslint-disable*/
import React, { useState } from "react";
import AppContext from "../../../context/AppContext";
import "./SideMenu.scss";
import { Link } from "react-router-dom";
import {useAppContext} from "../../App";

function SideMenu(props) {
  const [state,setState] = useState({
    isCollapsed: true
  });
  const context = useAppContext();
  let _scrnList = context.screenList;
  let activeTab = context.activeTab;

  const toggleSideMenuState = menu => {
    let isCollapsed = state.isCollapsed;
    setState(current => {
      return {
        ...current,
        isCollapsed: !isCollapsed
      };
    })
  };
  let t = context.t;
    return (
      <ul className="nav nav-tabs nav-stacked col-lg-2 eamSideMenu mainmenu">
        {_scrnList.map((menus, i) => (
          <li
            key={"menu" + i}
            className="uib-tab nav-item"
            onMouseOver={toggleSideMenuState}
            onMouseOut={toggleSideMenuState}
            data-testid="scrnDispName"
          >
            <a href="#/">
              <span>{t("translate:" + menus.menuLabel)}</span>
              <i
                className={
                  "fa " + (state.isCollapsed ? "fa-plus" : "fa-minus")
                }
                aria-hidden="true"
              ></i>
            </a>
            <ul className="submenu">
              {menus.submenus.map((srcn, i) => (
                <li
                  key={srcn.screenName}
                  className={activeTab === i ? "active" : ""}
                >
                  <Link to={srcn.URIPath} className="appMenuItem">
                    {t("translate:" + srcn.displayName)}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    );
  
}

export default SideMenu;
