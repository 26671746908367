/* eslint-disable*/
import React from "react";
import ParentWidgetFunc from "../ParentWidgetFunc";
import Button from "react-bootstrap/Button";
import {getVisibleResponsiveClasses,getWidgetClasses,getWidgetLabel,getTooltip} from "../ParentWidgetFunc";

function ButtonWidget(props){
  let conf = props.conf;
  let screenConf =props.screenConf;
 //const parentWidgetObj = new ParentWidgetFunc(props);
  const getButtonVariant = () => {
    if (conf.variant) {
      return conf.variant;
    }
    switch (conf.Category) {
      case "SubmitPage":
        return "primary";
      case "Cancel":
        return "outline-danger";
      default:
        return "secondary";
    }
  };
  const getButtonType = () => {
    switch (conf.type) {
      case "Submit":
        return "submit";
      case "Reset":
        return "reset";
      default:
        return "button";
    }
  };
  const getButtonCSS=() =>{
    let buttonCSS = [
      conf.name + "Button",
      "buttonWidgetClass",
      ...getVisibleResponsiveClasses(conf),
      conf.Mandatory ? "requiredBtn" : ""
    ];
    return buttonCSS.join(" ");
  };
    
    return (
      <div
        className={getWidgetClasses(conf,screenConf).join(" ") + " mb-2 align-self-end"}
      >
        <div className="row">
          <Button
            title={getTooltip(conf)}
            data-testid={conf.name}
            type={getButtonType()}
            id={conf.name}
            variant={getButtonVariant()}
            disabled={!conf.Enabled}
            className={getButtonCSS()}
            onClick={props.onClick}
            onMouseOver={props.onHover}    //change
            onMouseDown={props.onMouseDown}
          >
           {conf.iconName ? <i className={`fa ${conf.iconName}`} onClick= {e => {
               e = {
                ...e,
                target:{...e.target, id:conf.name}
              } 
              props.onClick(e)
              }} /> :null}
            {getWidgetLabel(conf)}
          </Button>
        </div>
      </div>
    );
  
}

export default ButtonWidget;
