/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FarmerStockPriceSetup from "./FarmerStockPriceSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FarmerStockPriceSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FarmerStockPriceSetup />
      );
    });
  });
  afterEach(cleanup);
  test("is FarmerStockPriceSetup Loads Successfully", () => {
    expect(screen.getByText("FarmerStockPriceSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for FarmerStockPriceSetup", () => {
    // START_USER_CODE-USER_FarmerStockPriceSetup_Custom_Test_Case
    // END_USER_CODE-USER_FarmerStockPriceSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FarmerStockPriceSetup />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmerStockPriceSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmerStockPriceSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmerStockPriceSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmerStockPriceSetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("grpbxFrmrStockPrice(GroupBox Widget) Test Cases", async () => {
    const grpbxFrmrStockPrice = screen.getByTestId("grpbxFrmrStockPrice");
    expect(grpbxFrmrStockPrice.tagName).toBe("BUTTON");
    expect(grpbxFrmrStockPrice.type).toBe("button");
    expect(grpbxFrmrStockPrice.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFrmrStockPrice", () => {
    // START_USER_CODE-USER_grpbxFrmrStockPrice_TEST
    // END_USER_CODE-USER_grpbxFrmrStockPrice_TEST
  });
  test("lblFrmrStckPric(Label Widget) Test Cases", async () => {
    const lblFrmrStckPric = screen.getByTestId("lblFrmrStckPric");
    expect(lblFrmrStckPric.tagName).toBe("LABEL");
    expect(lblFrmrStckPric.classList).toContain("form-label");
    expect(lblFrmrStckPric.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FarmerStockPriceSetup_lblFrmrStckPric"
      )
    );
  });
  test("Custom Test Cases for lblFrmrStckPric", () => {
    // START_USER_CODE-USER_lblFrmrStckPric_TEST
    // END_USER_CODE-USER_lblFrmrStckPric_TEST
  });
});
