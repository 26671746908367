/* eslint-disable*/
import React from "react";
import WarehouseReceipts_DelvSettleScaleTicket from "./DelvSettleScaleTicket";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DelvSettleScaleTicket Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvSettleScaleTicket />);
    });
  });
  afterEach(cleanup);
  test("is DelvSettleScaleTicket Loads Successfully", () => {
    expect(screen.getByText("DelvSettleScaleTicket")).toBeInTheDocument;
  });
  test("Custom Test Cases for DelvSettleScaleTicket", () => {
    // START_USER_CODE-USER_DelvSettleScaleTicket_Custom_Test_Case
    // END_USER_CODE-USER_DelvSettleScaleTicket_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvSettleScaleTicket />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxDelvSettleScaleTicket(GroupBox Widget) Test Cases", async () => {
    const grpbxDelvSettleScaleTicket = screen.getByTestId(
      "grpbxDelvSettleScaleTicket"
    );
    expect(grpbxDelvSettleScaleTicket.tagName).toBe("BUTTON");
    expect(grpbxDelvSettleScaleTicket.type).toBe("button");
    expect(grpbxDelvSettleScaleTicket.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelvSettleScaleTicket", () => {
    // START_USER_CODE-USER_grpbxDelvSettleScaleTicket_TEST
    // END_USER_CODE-USER_grpbxDelvSettleScaleTicket_TEST
  });
  test("grpbxLoadInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxLoadInfo = screen.getByTestId("grpbxLoadInfo");
    expect(grpbxLoadInfo.tagName).toBe("BUTTON");
    expect(grpbxLoadInfo.type).toBe("button");
    expect(grpbxLoadInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLoadInfo", () => {
    // START_USER_CODE-USER_grpbxLoadInfo_TEST
    // END_USER_CODE-USER_grpbxLoadInfo_TEST
  });
  test("grpbxReceivedWt(GroupBox Widget) Test Cases", async () => {
    const grpbxReceivedWt = screen.getByTestId("grpbxReceivedWt");
    expect(grpbxReceivedWt.tagName).toBe("BUTTON");
    expect(grpbxReceivedWt.type).toBe("button");
    expect(grpbxReceivedWt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReceivedWt", () => {
    // START_USER_CODE-USER_grpbxReceivedWt_TEST
    // END_USER_CODE-USER_grpbxReceivedWt_TEST
  });
  test("grpbxShippedReceivedWt(GroupBox Widget) Test Cases", async () => {
    const grpbxShippedReceivedWt = screen.getByTestId("grpbxShippedReceivedWt");
    expect(grpbxShippedReceivedWt.tagName).toBe("BUTTON");
    expect(grpbxShippedReceivedWt.type).toBe("button");
    expect(grpbxShippedReceivedWt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxShippedReceivedWt", () => {
    // START_USER_CODE-USER_grpbxShippedReceivedWt_TEST
    // END_USER_CODE-USER_grpbxShippedReceivedWt_TEST
  });
  test("grpbxShippedWt(GroupBox Widget) Test Cases", async () => {
    const grpbxShippedWt = screen.getByTestId("grpbxShippedWt");
    expect(grpbxShippedWt.tagName).toBe("BUTTON");
    expect(grpbxShippedWt.type).toBe("button");
    expect(grpbxShippedWt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxShippedWt", () => {
    // START_USER_CODE-USER_grpbxShippedWt_TEST
    // END_USER_CODE-USER_grpbxShippedWt_TEST
  });
  test("grpbxUpdateInventory(GroupBox Widget) Test Cases", async () => {
    const grpbxUpdateInventory = screen.getByTestId("grpbxUpdateInventory");
    expect(grpbxUpdateInventory.tagName).toBe("BUTTON");
    expect(grpbxUpdateInventory.type).toBe("button");
    expect(grpbxUpdateInventory.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxUpdateInventory", () => {
    // START_USER_CODE-USER_grpbxUpdateInventory_TEST
    // END_USER_CODE-USER_grpbxUpdateInventory_TEST
  });
  test("grpbxWtVariance(GroupBox Widget) Test Cases", async () => {
    const grpbxWtVariance = screen.getByTestId("grpbxWtVariance");
    expect(grpbxWtVariance.tagName).toBe("BUTTON");
    expect(grpbxWtVariance.type).toBe("button");
    expect(grpbxWtVariance.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWtVariance", () => {
    // START_USER_CODE-USER_grpbxWtVariance_TEST
    // END_USER_CODE-USER_grpbxWtVariance_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblDateFormatRW(Label Widget) Test Cases", async () => {
    const lblDateFormatRW = screen.getByTestId("lblDateFormatRW");
    expect(lblDateFormatRW.tagName).toBe("LABEL");
    expect(lblDateFormatRW.classList).toContain("form-label");
    expect(lblDateFormatRW.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_lblDateFormatRW")
    );
  });
  test("Custom Test Cases for lblDateFormatRW", () => {
    // START_USER_CODE-USER_lblDateFormatRW_TEST
    // END_USER_CODE-USER_lblDateFormatRW_TEST
  });
  test("lblDateFormatSW(Label Widget) Test Cases", async () => {
    const lblDateFormatSW = screen.getByTestId("lblDateFormatSW");
    expect(lblDateFormatSW.tagName).toBe("LABEL");
    expect(lblDateFormatSW.classList).toContain("form-label");
    expect(lblDateFormatSW.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_lblDateFormatSW")
    );
  });
  test("Custom Test Cases for lblDateFormatSW", () => {
    // START_USER_CODE-USER_lblDateFormatSW_TEST
    // END_USER_CODE-USER_lblDateFormatSW_TEST
  });
  test("lblLbs(Label Widget) Test Cases", async () => {
    const lblLbs = screen.getByTestId("lblLbs");
    expect(lblLbs.tagName).toBe("LABEL");
    expect(lblLbs.classList).toContain("form-label");
    expect(lblLbs.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_lblLbs")
    );
  });
  test("Custom Test Cases for lblLbs", () => {
    // START_USER_CODE-USER_lblLbs_TEST
    // END_USER_CODE-USER_lblLbs_TEST
  });
  test("lblLoadInfo(Label Widget) Test Cases", async () => {
    const lblLoadInfo = screen.getByTestId("lblLoadInfo");
    expect(lblLoadInfo.tagName).toBe("LABEL");
    expect(lblLoadInfo.classList).toContain("form-label");
    expect(lblLoadInfo.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_lblLoadInfo")
    );
  });
  test("Custom Test Cases for lblLoadInfo", () => {
    // START_USER_CODE-USER_lblLoadInfo_TEST
    // END_USER_CODE-USER_lblLoadInfo_TEST
  });
  test("lblReceivedWt(Label Widget) Test Cases", async () => {
    const lblReceivedWt = screen.getByTestId("lblReceivedWt");
    expect(lblReceivedWt.tagName).toBe("LABEL");
    expect(lblReceivedWt.classList).toContain("form-label");
    expect(lblReceivedWt.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_lblReceivedWt")
    );
  });
  test("Custom Test Cases for lblReceivedWt", () => {
    // START_USER_CODE-USER_lblReceivedWt_TEST
    // END_USER_CODE-USER_lblReceivedWt_TEST
  });
  test("lblShippedWeight(Label Widget) Test Cases", async () => {
    const lblShippedWeight = screen.getByTestId("lblShippedWeight");
    expect(lblShippedWeight.tagName).toBe("LABEL");
    expect(lblShippedWeight.classList).toContain("form-label");
    expect(lblShippedWeight.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_lblShippedWeight")
    );
  });
  test("Custom Test Cases for lblShippedWeight", () => {
    // START_USER_CODE-USER_lblShippedWeight_TEST
    // END_USER_CODE-USER_lblShippedWeight_TEST
  });
  test("txtDriver(Textbox Widget) Test Cases", async () => {
    const txtDriver = screen.getByTestId("txtDriver");
    expect(txtDriver.tagName).toBe("INPUT");
    expect(txtDriver.type).toBe("text");
    expect(txtDriver.classList).toContain("textboxWidgetClass");
    expect(txtDriver.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_txtDriver")
    );
    await act(async () => {
      userEvent.type(txtDriver, "1");
    });
  });
  test("Custom Test Cases for txtDriver", () => {
    // START_USER_CODE-USER_txtDriver_TEST
    // END_USER_CODE-USER_txtDriver_TEST
  });
  test("txtLbsOfPeanutsRW(Textbox Widget) Test Cases", async () => {
    const txtLbsOfPeanutsRW = screen.getByTestId("txtLbsOfPeanutsRW");
    expect(txtLbsOfPeanutsRW.tagName).toBe("INPUT");
    expect(txtLbsOfPeanutsRW.type).toBe("text");
    expect(txtLbsOfPeanutsRW.classList).toContain("textboxWidgetClass");
    expect(txtLbsOfPeanutsRW.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_txtLbsOfPeanutsRW")
    );
    await act(async () => {
      userEvent.type(txtLbsOfPeanutsRW, "1");
    });
  });
  test("Custom Test Cases for txtLbsOfPeanutsRW", () => {
    // START_USER_CODE-USER_txtLbsOfPeanutsRW_TEST
    // END_USER_CODE-USER_txtLbsOfPeanutsRW_TEST
  });
  test("txtLbsOfPeanutsSW(Textbox Widget) Test Cases", async () => {
    const txtLbsOfPeanutsSW = screen.getByTestId("txtLbsOfPeanutsSW");
    expect(txtLbsOfPeanutsSW.tagName).toBe("INPUT");
    expect(txtLbsOfPeanutsSW.type).toBe("text");
    expect(txtLbsOfPeanutsSW.classList).toContain("textboxWidgetClass");
    expect(txtLbsOfPeanutsSW.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_txtLbsOfPeanutsSW")
    );
    await act(async () => {
      userEvent.type(txtLbsOfPeanutsSW, "1");
    });
  });
  test("Custom Test Cases for txtLbsOfPeanutsSW", () => {
    // START_USER_CODE-USER_txtLbsOfPeanutsSW_TEST
    // END_USER_CODE-USER_txtLbsOfPeanutsSW_TEST
  });
  test("txtLoadNum(Textbox Widget) Test Cases", async () => {
    const txtLoadNum = screen.getByTestId("txtLoadNum");
    expect(txtLoadNum.tagName).toBe("INPUT");
    expect(txtLoadNum.type).toBe("text");
    expect(txtLoadNum.classList).toContain("textboxWidgetClass");
    expect(txtLoadNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_txtLoadNum")
    );
    await act(async () => {
      userEvent.type(txtLoadNum, "1");
    });
  });
  test("Custom Test Cases for txtLoadNum", () => {
    // START_USER_CODE-USER_txtLoadNum_TEST
    // END_USER_CODE-USER_txtLoadNum_TEST
  });
  test("txtOtherWt(Textbox Widget) Test Cases", async () => {
    const txtOtherWt = screen.getByTestId("txtOtherWt");
    expect(txtOtherWt.tagName).toBe("INPUT");
    expect(txtOtherWt.type).toBe("text");
    expect(txtOtherWt.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOtherWt, "1");
    });
  });
  test("Custom Test Cases for txtOtherWt", () => {
    // START_USER_CODE-USER_txtOtherWt_TEST
    // END_USER_CODE-USER_txtOtherWt_TEST
  });
  test("txtReceivedDate(Date Widget) Test Cases", async () => {
    const txtReceivedDate = screen.getByTestId("txtReceivedDate");
    expect(txtReceivedDate.tagName).toBe("INPUT");
    expect(txtReceivedDate.type).toBe("text");
    expect(txtReceivedDate.classList).toContain("datetimepicker-input");
    expect(
      txtReceivedDate.parentElement.previousElementSibling.textContent
    ).toEqual(t("WarehouseReceipts:DelvSettleScaleTicket_txtReceivedDate"));
    await act(async () => {
      userEvent.click(txtReceivedDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtReceivedDate", () => {
    // START_USER_CODE-USER_txtReceivedDate_TEST
    // END_USER_CODE-USER_txtReceivedDate_TEST
  });
  test("txtScaleTicketNumRW(Textbox Widget) Test Cases", async () => {
    const txtScaleTicketNumRW = screen.getByTestId("txtScaleTicketNumRW");
    expect(txtScaleTicketNumRW.tagName).toBe("INPUT");
    expect(txtScaleTicketNumRW.type).toBe("text");
    expect(txtScaleTicketNumRW.classList).toContain("textboxWidgetClass");
    expect(txtScaleTicketNumRW.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_txtScaleTicketNumRW")
    );
    await act(async () => {
      userEvent.type(txtScaleTicketNumRW, "1");
    });
  });
  test("Custom Test Cases for txtScaleTicketNumRW", () => {
    // START_USER_CODE-USER_txtScaleTicketNumRW_TEST
    // END_USER_CODE-USER_txtScaleTicketNumRW_TEST
  });
  test("txtScaleTicketNumSW(Textbox Widget) Test Cases", async () => {
    const txtScaleTicketNumSW = screen.getByTestId("txtScaleTicketNumSW");
    expect(txtScaleTicketNumSW.tagName).toBe("INPUT");
    expect(txtScaleTicketNumSW.type).toBe("text");
    expect(txtScaleTicketNumSW.classList).toContain("textboxWidgetClass");
    expect(txtScaleTicketNumSW.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_txtScaleTicketNumSW")
    );
    await act(async () => {
      userEvent.type(txtScaleTicketNumSW, "1");
    });
  });
  test("Custom Test Cases for txtScaleTicketNumSW", () => {
    // START_USER_CODE-USER_txtScaleTicketNumSW_TEST
    // END_USER_CODE-USER_txtScaleTicketNumSW_TEST
  });
  test("txtShippedDate(Date Widget) Test Cases", async () => {
    const txtShippedDate = screen.getByTestId("txtShippedDate");
    expect(txtShippedDate.tagName).toBe("INPUT");
    expect(txtShippedDate.type).toBe("text");
    expect(txtShippedDate.classList).toContain("datetimepicker-input");
    expect(
      txtShippedDate.parentElement.previousElementSibling.textContent
    ).toEqual(t("WarehouseReceipts:DelvSettleScaleTicket_txtShippedDate"));
    await act(async () => {
      userEvent.click(txtShippedDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtShippedDate", () => {
    // START_USER_CODE-USER_txtShippedDate_TEST
    // END_USER_CODE-USER_txtShippedDate_TEST
  });
  test("txtTruckLine(Textbox Widget) Test Cases", async () => {
    const txtTruckLine = screen.getByTestId("txtTruckLine");
    expect(txtTruckLine.tagName).toBe("INPUT");
    expect(txtTruckLine.type).toBe("text");
    expect(txtTruckLine.classList).toContain("textboxWidgetClass");
    expect(txtTruckLine.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_txtTruckLine")
    );
    await act(async () => {
      userEvent.type(txtTruckLine, "1");
    });
  });
  test("Custom Test Cases for txtTruckLine", () => {
    // START_USER_CODE-USER_txtTruckLine_TEST
    // END_USER_CODE-USER_txtTruckLine_TEST
  });
  test("txtVehicleNum(Textbox Widget) Test Cases", async () => {
    const txtVehicleNum = screen.getByTestId("txtVehicleNum");
    expect(txtVehicleNum.tagName).toBe("INPUT");
    expect(txtVehicleNum.type).toBe("text");
    expect(txtVehicleNum.classList).toContain("textboxWidgetClass");
    expect(txtVehicleNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_txtVehicleNum")
    );
    await act(async () => {
      userEvent.type(txtVehicleNum, "1");
    });
  });
  test("Custom Test Cases for txtVehicleNum", () => {
    // START_USER_CODE-USER_txtVehicleNum_TEST
    // END_USER_CODE-USER_txtVehicleNum_TEST
  });
  test("txtWtIncldVehicleRW(Textbox Widget) Test Cases", async () => {
    const txtWtIncldVehicleRW = screen.getByTestId("txtWtIncldVehicleRW");
    expect(txtWtIncldVehicleRW.tagName).toBe("INPUT");
    expect(txtWtIncldVehicleRW.type).toBe("text");
    expect(txtWtIncldVehicleRW.classList).toContain("textboxWidgetClass");
    expect(txtWtIncldVehicleRW.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_txtWtIncldVehicleRW")
    );
    await act(async () => {
      userEvent.type(txtWtIncldVehicleRW, "1");
    });
  });
  test("Custom Test Cases for txtWtIncldVehicleRW", () => {
    // START_USER_CODE-USER_txtWtIncldVehicleRW_TEST
    // END_USER_CODE-USER_txtWtIncldVehicleRW_TEST
  });
  test("txtWtIncldVehicleSW(Textbox Widget) Test Cases", async () => {
    const txtWtIncldVehicleSW = screen.getByTestId("txtWtIncldVehicleSW");
    expect(txtWtIncldVehicleSW.tagName).toBe("INPUT");
    expect(txtWtIncldVehicleSW.type).toBe("text");
    expect(txtWtIncldVehicleSW.classList).toContain("textboxWidgetClass");
    expect(txtWtIncldVehicleSW.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_txtWtIncldVehicleSW")
    );
    await act(async () => {
      userEvent.type(txtWtIncldVehicleSW, "1");
    });
  });
  test("Custom Test Cases for txtWtIncldVehicleSW", () => {
    // START_USER_CODE-USER_txtWtIncldVehicleSW_TEST
    // END_USER_CODE-USER_txtWtIncldVehicleSW_TEST
  });
  test("txtWtOfVehicleRW(Textbox Widget) Test Cases", async () => {
    const txtWtOfVehicleRW = screen.getByTestId("txtWtOfVehicleRW");
    expect(txtWtOfVehicleRW.tagName).toBe("INPUT");
    expect(txtWtOfVehicleRW.type).toBe("text");
    expect(txtWtOfVehicleRW.classList).toContain("textboxWidgetClass");
    expect(txtWtOfVehicleRW.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_txtWtOfVehicleRW")
    );
    await act(async () => {
      userEvent.type(txtWtOfVehicleRW, "1");
    });
  });
  test("Custom Test Cases for txtWtOfVehicleRW", () => {
    // START_USER_CODE-USER_txtWtOfVehicleRW_TEST
    // END_USER_CODE-USER_txtWtOfVehicleRW_TEST
  });
  test("txtWtOfVehicleSW(Textbox Widget) Test Cases", async () => {
    const txtWtOfVehicleSW = screen.getByTestId("txtWtOfVehicleSW");
    expect(txtWtOfVehicleSW.tagName).toBe("INPUT");
    expect(txtWtOfVehicleSW.type).toBe("text");
    expect(txtWtOfVehicleSW.classList).toContain("textboxWidgetClass");
    expect(txtWtOfVehicleSW.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_txtWtOfVehicleSW")
    );
    await act(async () => {
      userEvent.type(txtWtOfVehicleSW, "1");
    });
  });
  test("Custom Test Cases for txtWtOfVehicleSW", () => {
    // START_USER_CODE-USER_txtWtOfVehicleSW_TEST
    // END_USER_CODE-USER_txtWtOfVehicleSW_TEST
  });
  test("txtWtVariance(Textbox Widget) Test Cases", async () => {
    const txtWtVariance = screen.getByTestId("txtWtVariance");
    expect(txtWtVariance.tagName).toBe("INPUT");
    expect(txtWtVariance.type).toBe("text");
    expect(txtWtVariance.classList).toContain("textboxWidgetClass");
    expect(txtWtVariance.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleScaleTicket_txtWtVariance")
    );
    await act(async () => {
      userEvent.type(txtWtVariance, "1");
    });
  });
  test("Custom Test Cases for txtWtVariance", () => {
    // START_USER_CODE-USER_txtWtVariance_TEST
    // END_USER_CODE-USER_txtWtVariance_TEST
  });
});
