/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  getData,
  setValue,
  getValue,
  disable,
  setData
} from "../../shared/WindowImports";

import "./FlexMktVoid.scss";
import { ContractManagementService } from "../Service/ContractManagementService";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_FlexMktVoid(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "VoidPriceElection",
    windowName: "FlexMktVoid",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.FlexMktVoid",
    // START_USER_CODE-USER_FlexMktVoid_PROPERTIES
    headerData: {
      scrName: "Void Price Election",
      scrCode: "PN1010J"
    },
    horizontalForm: true,
    // END_USER_CODE-USER_FlexMktVoid_PROPERTIES
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxCntrctPrc",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnVoid: {
      name: "btnVoid",
      type: "ButtonWidget",
      parent: "grpbxCntrctPrc",
      Label: "Void",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVoid_PROPERTIES

      // END_USER_CODE-USER_btnVoid_PROPERTIES
    },
    lblContractPricing: {
      name: "lblContractPricing",
      type: "TextBoxWidget",
      parent: "grpbxCntrctPrc",
      Label: "Contract Pricing: ",
      ColSpan: "2",
      
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblContractPricing_PROPERTIES

      // END_USER_CODE-USER_lblContractPricing_PROPERTIES
    },
    lblEnterPricingCntrct: {
      name: "lblEnterPricingCntrct",
      type: "LabelWidget",
      parent: "grpbxCntrctPrc",
      Label: "Enter Pricing Contract # to Void",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblEnterPricingCntrct_PROPERTIES

      // END_USER_CODE-USER_lblEnterPricingCntrct_PROPERTIES
    },
    txtCntrctPrc: {
      name: "txtCntrctPrc",
      type: "TextBoxWidget",
      parent: "grpbxCntrctPrc",
      Label: "Contract Pricing: ",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCntrctPrc_PROPERTIES

      // END_USER_CODE-USER_txtCntrctPrc_PROPERTIES
    },
    grpbxCntrctPrc: {
      name: "grpbxCntrctPrc",
      type: "GroupBoxWidget",
      parent: "FlexMktVoid",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCntrctPrc_PROPERTIES

      // END_USER_CODE-USER_grpbxCntrctPrc_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#FlexibleMarketPricings": {},
      },
      REVERSE: {
        "ContractManagement#FlexibleMarketPricings": {},
      },
    },
  };
  let _buttonServices = {FlexibleMarketPricings:{}};
  let _buttonNavigation = {
    btnVoid: {
      DEFAULT: [
        "ContractManagement#FlexibleMarketPricings#DEFAULT#true#Click",
      ],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    disable(thisObj,'lblContractPricing')
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    frmFlexMktPricingDetailsData()
  }, [getData(thisObj,'frmFlexMktPricingDetailsData')]);

  const frmFlexMktPricingDetailsData = () => {
    let frmFlexMktPricingDetailsData_ = getData(thisObj, 'frmFlexMktPricingDetailsData');
    if (frmFlexMktPricingDetailsData_ !== null) {
      setValue(thisObj, "lblContractPricing",frmFlexMktPricingDetailsData_.colTxtPricingNum); }
  };
  const onbtnVoidClick = () => {
    try {
      setData(thisObj,"flexpricing",true);
      let txtCntrctPrc_ = getValue(thisObj, "txtCntrctPrc");
      let lblContractPricing_=getValue(thisObj, "lblContractPricing")
      let value = getData(thisObj, 'frmFlexMktPricingDetailsData');
      let pricesuffix_=value.pricing_suffix
      let contractnumber_=value.contract_num
      if(txtCntrctPrc_ !==undefined && txtCntrctPrc_.toUpperCase()==lblContractPricing_ && txtCntrctPrc_ !==''){
        ContractManagementService.RetrieveFlexMarketPriceElectionVoidDetails(contractnumber_,pricesuffix_).then(response => {
              let data = response;
              document.getElementById("ContractManagement_FlexMktVoidPopUp").childNodes[0].click();
              if (data.length > 0) {
               
              }
            });
      }else{
        showMessage(thisObj, 'Price Election entered is not the same as Price Election selected.');
      }
      
    } catch (err) {
    }
  };
  thisObj.onbtnVoidClick = onbtnVoidClick;
  // START_USER_CODE-USER_METHODS
  const onbtnExitClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      // Modal.close();
      //document.getElementsByClassName("close")[0].click()
      document.getElementById("ContractManagement_FlexMktVoidPopUp").childNodes[0].click();
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnExit event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnExitClick = onbtnExitClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FlexMktVoid*/}

              {/* END_USER_CODE-USER_BEFORE_FlexMktVoid*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCntrctPrc*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCntrctPrc*/}

              <GroupBoxWidget conf={state.grpbxCntrctPrc} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblEnterPricingCntrct*/}

                {/* END_USER_CODE-USER_BEFORE_lblEnterPricingCntrct*/}

                <LabelWidget
                  values={values}
                  conf={state.lblEnterPricingCntrct}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblEnterPricingCntrct*/}

                {/* END_USER_CODE-USER_AFTER_lblEnterPricingCntrct*/}
                {/* START_USER_CODE-USER_BEFORE_lblContractPricing*/}

                {/* END_USER_CODE-USER_BEFORE_lblContractPricing*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lblContractPricing}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_BEFORE_txtCntrctPrc*/}

                {/* END_USER_CODE-USER_BEFORE_txtCntrctPrc*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCntrctPrc}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCntrctPrc*/}

                {/* END_USER_CODE-USER_AFTER_txtCntrctPrc*/}
                
                {/* START_USER_CODE-USER_AFTER_lblContractPricing*/}

                {/* END_USER_CODE-USER_AFTER_lblContractPricing*/}
                {/* START_USER_CODE-USER_BEFORE_btnVoid*/}

                {/* END_USER_CODE-USER_BEFORE_btnVoid*/}

                <ButtonWidget
                  conf={state.btnVoid}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVoid*/}

                {/* END_USER_CODE-USER_AFTER_btnVoid*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCntrctPrc*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCntrctPrc*/}

              {/* START_USER_CODE-USER_AFTER_FlexMktVoid*/}

              {/* END_USER_CODE-USER_AFTER_FlexMktVoid*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_FlexMktVoid);