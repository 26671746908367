/* eslint-disable*/
import React from "react";
import Settlements_ViewUploads from "./ViewUploads";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ViewUploads Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ViewUploads />);
    });
  });
  afterEach(cleanup);
  test("is ViewUploads Loads Successfully", () => {
    expect(screen.getByText("ViewUploads")).toBeInTheDocument;
  });
  test("Custom Test Cases for ViewUploads", () => {
    // START_USER_CODE-USER_ViewUploads_Custom_Test_Case
    // END_USER_CODE-USER_ViewUploads_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ViewUploads />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(t("Settlements:ViewUploads_btnClose"));
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnHide(Button Widget) Test Cases", async () => {
    const btnHide = screen.getByTestId("btnHide");
    expect(btnHide).toBeInTheDocument;
    expect(btnHide.textContent).toEqual(t("Settlements:ViewUploads_btnHide"));
  });
  test("Custom Test Cases for btnHide", () => {
    // START_USER_CODE-USER_btnHide_TEST
    // END_USER_CODE-USER_btnHide_TEST
  });
  test("btnResubmit(Button Widget) Test Cases", async () => {
    const btnResubmit = screen.getByTestId("btnResubmit");
    expect(btnResubmit).toBeInTheDocument;
    expect(btnResubmit.textContent).toEqual(
      t("Settlements:ViewUploads_btnResubmit")
    );
  });
  test("Custom Test Cases for btnResubmit", () => {
    // START_USER_CODE-USER_btnResubmit_TEST
    // END_USER_CODE-USER_btnResubmit_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("Settlements:ViewUploads_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("gridSearchResults(Grid Widget) Test Cases", async () => {
    let gridSearchResults = screen.getByTestId("gridSearchResults");
    let gridSearchResultsbtn =
      gridSearchResults.nextElementSibling.firstElementChild;
    gridSearchResults =
      gridSearchResults.parentElement.parentElement.parentElement;
    expect(gridSearchResults.tagName).toBe("DIV");
    expect(gridSearchResults.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:ViewUploads_gridSearchResults")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for gridSearchResults", () => {
    // START_USER_CODE-USER_gridSearchResults_TEST
    // END_USER_CODE-USER_gridSearchResults_TEST
  });
  test("grpbxSearchData(GroupBox Widget) Test Cases", async () => {
    const grpbxSearchData = screen.getByTestId("grpbxSearchData");
    expect(grpbxSearchData.tagName).toBe("BUTTON");
    expect(grpbxSearchData.type).toBe("button");
    expect(grpbxSearchData.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSearchData", () => {
    // START_USER_CODE-USER_grpbxSearchData_TEST
    // END_USER_CODE-USER_grpbxSearchData_TEST
  });
  test("grpbxViewUploads(GroupBox Widget) Test Cases", async () => {
    const grpbxViewUploads = screen.getByTestId("grpbxViewUploads");
    expect(grpbxViewUploads.tagName).toBe("BUTTON");
    expect(grpbxViewUploads.type).toBe("button");
    expect(grpbxViewUploads.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxViewUploads", () => {
    // START_USER_CODE-USER_grpbxViewUploads_TEST
    // END_USER_CODE-USER_grpbxViewUploads_TEST
  });
  test("gridSearchResults_txtcolBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolBuyingPoint = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolBuyingPointbtn =
      gridSearchResults_txtcolBuyingPoint.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolBuyingPoint =
      gridSearchResults_txtcolBuyingPoint.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolBuyingPoint.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolBuyingPoint.classList).toContain(
      "GridWidget"
    );
    expect(screen.getAllByText(t("Settlements:ViewUploads_gridSearchResults")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyingPoint", () => {
    // START_USER_CODE-USER_txtcolBuyingPoint_TEST
    // END_USER_CODE-USER_txtcolBuyingPoint_TEST
  });
  test("gridSearchResults_txtcolCompID(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolCompID = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolCompIDbtn =
      gridSearchResults_txtcolCompID.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolCompID =
      gridSearchResults_txtcolCompID.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolCompID.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolCompID.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:ViewUploads_gridSearchResults")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCompID", () => {
    // START_USER_CODE-USER_txtcolCompID_TEST
    // END_USER_CODE-USER_txtcolCompID_TEST
  });
  test("gridSearchResults_txtcolCropYear(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolCropYear = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolCropYearbtn =
      gridSearchResults_txtcolCropYear.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolCropYear =
      gridSearchResults_txtcolCropYear.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolCropYear.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolCropYear.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:ViewUploads_gridSearchResults")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCropYear", () => {
    // START_USER_CODE-USER_txtcolCropYear_TEST
    // END_USER_CODE-USER_txtcolCropYear_TEST
  });
  test("gridSearchResults_txtcolErrorMessage(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolErrorMessage = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolErrorMessagebtn =
      gridSearchResults_txtcolErrorMessage.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolErrorMessage =
      gridSearchResults_txtcolErrorMessage.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolErrorMessage.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolErrorMessage.classList).toContain(
      "GridWidget"
    );
    expect(screen.getAllByText(t("Settlements:ViewUploads_gridSearchResults")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolErrorMessage", () => {
    // START_USER_CODE-USER_txtcolErrorMessage_TEST
    // END_USER_CODE-USER_txtcolErrorMessage_TEST
  });
  test("gridSearchResults_txtcolInspectionNum(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolInspectionNum = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolInspectionNumbtn =
      gridSearchResults_txtcolInspectionNum.nextElementSibling
        .firstElementChild;
    gridSearchResults_txtcolInspectionNum =
      gridSearchResults_txtcolInspectionNum.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolInspectionNum.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolInspectionNum.classList).toContain(
      "GridWidget"
    );
    expect(screen.getAllByText(t("Settlements:ViewUploads_gridSearchResults")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInspectionNum", () => {
    // START_USER_CODE-USER_txtcolInspectionNum_TEST
    // END_USER_CODE-USER_txtcolInspectionNum_TEST
  });
  test("gridSearchResults_txtcolTranNum(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolTranNum = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolTranNumbtn =
      gridSearchResults_txtcolTranNum.nextElementSibling.firstElementChild;
    gridSearchResults_txtcolTranNum =
      gridSearchResults_txtcolTranNum.parentElement.parentElement.parentElement;
    expect(gridSearchResults_txtcolTranNum.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolTranNum.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:ViewUploads_gridSearchResults")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTranNum", () => {
    // START_USER_CODE-USER_txtcolTranNum_TEST
    // END_USER_CODE-USER_txtcolTranNum_TEST
  });
  test("gridSearchResults_txtcolTransferFileName(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolTransferFileName = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolTransferFileNamebtn =
      gridSearchResults_txtcolTransferFileName.nextElementSibling
        .firstElementChild;
    gridSearchResults_txtcolTransferFileName =
      gridSearchResults_txtcolTransferFileName.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolTransferFileName.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolTransferFileName.classList).toContain(
      "GridWidget"
    );
    expect(screen.getAllByText(t("Settlements:ViewUploads_gridSearchResults")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTransferFileName", () => {
    // START_USER_CODE-USER_txtcolTransferFileName_TEST
    // END_USER_CODE-USER_txtcolTransferFileName_TEST
  });
  test("gridSearchResults_txtcolTransferredRecord(Grid Widget) Test Cases", async () => {
    let gridSearchResults_txtcolTransferredRecord = screen.getByTestId(
      "gridSearchResults"
    );
    let gridSearchResults_txtcolTransferredRecordbtn =
      gridSearchResults_txtcolTransferredRecord.nextElementSibling
        .firstElementChild;
    gridSearchResults_txtcolTransferredRecord =
      gridSearchResults_txtcolTransferredRecord.parentElement.parentElement
        .parentElement;
    expect(gridSearchResults_txtcolTransferredRecord.tagName).toBe("DIV");
    expect(gridSearchResults_txtcolTransferredRecord.classList).toContain(
      "GridWidget"
    );
    expect(screen.getAllByText(t("Settlements:ViewUploads_gridSearchResults")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTransferredRecord", () => {
    // START_USER_CODE-USER_txtcolTransferredRecord_TEST
    // END_USER_CODE-USER_txtcolTransferredRecord_TEST
  });
  test("txtCounty(Textbox Widget) Test Cases", async () => {
    const txtCounty = screen.getByTestId("txtCounty");
    expect(txtCounty.tagName).toBe("INPUT");
    expect(txtCounty.type).toBe("text");
    expect(txtCounty.classList).toContain("textboxWidgetClass");
    expect(txtCounty.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewUploads_txtCounty")
    );
    await act(async () => {
      userEvent.type(txtCounty, "1");
    });
  });
  test("Custom Test Cases for txtCounty", () => {
    // START_USER_CODE-USER_txtCounty_TEST
    // END_USER_CODE-USER_txtCounty_TEST
  });
  test("txtCountyVal(Textbox Widget) Test Cases", async () => {
    const txtCountyVal = screen.getByTestId("txtCountyVal");
    expect(txtCountyVal.tagName).toBe("INPUT");
    expect(txtCountyVal.type).toBe("text");
    expect(txtCountyVal.classList).toContain("textboxWidgetClass");
    expect(txtCountyVal.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewUploads_txtCountyVal")
    );
    await act(async () => {
      userEvent.type(txtCountyVal, "123");
    });
    expect(txtCountyVal.getAttribute("value")).toBe("");
    expect(txtCountyVal.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCountyVal", () => {
    // START_USER_CODE-USER_txtCountyVal_TEST
    // END_USER_CODE-USER_txtCountyVal_TEST
  });
  test("txtFarm(Textbox Widget) Test Cases", async () => {
    const txtFarm = screen.getByTestId("txtFarm");
    expect(txtFarm.tagName).toBe("INPUT");
    expect(txtFarm.type).toBe("text");
    expect(txtFarm.classList).toContain("textboxWidgetClass");
    expect(txtFarm.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewUploads_txtFarm")
    );
    await act(async () => {
      userEvent.type(txtFarm, "1");
    });
  });
  test("Custom Test Cases for txtFarm", () => {
    // START_USER_CODE-USER_txtFarm_TEST
    // END_USER_CODE-USER_txtFarm_TEST
  });
  test("txtFarmSuffix(Textbox Widget) Test Cases", async () => {
    const txtFarmSuffix = screen.getByTestId("txtFarmSuffix");
    expect(txtFarmSuffix.tagName).toBe("INPUT");
    expect(txtFarmSuffix.type).toBe("text");
    expect(txtFarmSuffix.classList).toContain("textboxWidgetClass");
    expect(txtFarmSuffix.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewUploads_txtFarmSuffix")
    );
    await act(async () => {
      userEvent.type(txtFarmSuffix, "1");
    });
  });
  test("Custom Test Cases for txtFarmSuffix", () => {
    // START_USER_CODE-USER_txtFarmSuffix_TEST
    // END_USER_CODE-USER_txtFarmSuffix_TEST
  });
  test("txtFarmSuffixVal(Textbox Widget) Test Cases", async () => {
    const txtFarmSuffixVal = screen.getByTestId("txtFarmSuffixVal");
    expect(txtFarmSuffixVal.tagName).toBe("INPUT");
    expect(txtFarmSuffixVal.type).toBe("text");
    expect(txtFarmSuffixVal.classList).toContain("textboxWidgetClass");
    expect(txtFarmSuffixVal.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewUploads_txtFarmSuffixVal")
    );
    await act(async () => {
      userEvent.type(txtFarmSuffixVal, "123");
    });
    expect(txtFarmSuffixVal.getAttribute("value")).toBe("");
    expect(txtFarmSuffixVal.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFarmSuffixVal", () => {
    // START_USER_CODE-USER_txtFarmSuffixVal_TEST
    // END_USER_CODE-USER_txtFarmSuffixVal_TEST
  });
  test("txtFarmVal(Textbox Widget) Test Cases", async () => {
    const txtFarmVal = screen.getByTestId("txtFarmVal");
    expect(txtFarmVal.tagName).toBe("INPUT");
    expect(txtFarmVal.type).toBe("text");
    expect(txtFarmVal.classList).toContain("textboxWidgetClass");
    expect(txtFarmVal.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewUploads_txtFarmVal")
    );
    await act(async () => {
      userEvent.type(txtFarmVal, "123");
    });
    expect(txtFarmVal.getAttribute("value")).toBe("");
    expect(txtFarmVal.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFarmVal", () => {
    // START_USER_CODE-USER_txtFarmVal_TEST
    // END_USER_CODE-USER_txtFarmVal_TEST
  });
  test("txtInspectionNum(Textbox Widget) Test Cases", async () => {
    const txtInspectionNum = screen.getByTestId("txtInspectionNum");
    expect(txtInspectionNum.tagName).toBe("INPUT");
    expect(txtInspectionNum.type).toBe("text");
    expect(txtInspectionNum.classList).toContain("textboxWidgetClass");
    expect(txtInspectionNum.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewUploads_txtInspectionNum")
    );
    await act(async () => {
      userEvent.type(txtInspectionNum, "1");
    });
  });
  test("Custom Test Cases for txtInspectionNum", () => {
    // START_USER_CODE-USER_txtInspectionNum_TEST
    // END_USER_CODE-USER_txtInspectionNum_TEST
  });
  test("txtState(Textbox Widget) Test Cases", async () => {
    const txtState = screen.getByTestId("txtState");
    expect(txtState.tagName).toBe("INPUT");
    expect(txtState.type).toBe("text");
    expect(txtState.classList).toContain("textboxWidgetClass");
    expect(txtState.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewUploads_txtState")
    );
    await act(async () => {
      userEvent.type(txtState, "1");
    });
  });
  test("Custom Test Cases for txtState", () => {
    // START_USER_CODE-USER_txtState_TEST
    // END_USER_CODE-USER_txtState_TEST
  });
  test("txtStateVal(Textbox Widget) Test Cases", async () => {
    const txtStateVal = screen.getByTestId("txtStateVal");
    expect(txtStateVal.tagName).toBe("INPUT");
    expect(txtStateVal.type).toBe("text");
    expect(txtStateVal.classList).toContain("textboxWidgetClass");
    expect(txtStateVal.previousElementSibling.textContent).toEqual(
      t("Settlements:ViewUploads_txtStateVal")
    );
    await act(async () => {
      userEvent.type(txtStateVal, "123");
    });
    expect(txtStateVal.getAttribute("value")).toBe("");
    expect(txtStateVal.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtStateVal", () => {
    // START_USER_CODE-USER_txtStateVal_TEST
    // END_USER_CODE-USER_txtStateVal_TEST
  });
});
