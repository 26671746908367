/* eslint-disable*/
import React from "react";
import StockTransfer_FrtAccrlSearch from "./FrtAccrlSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FrtAccrlSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_FrtAccrlSearch />);
    });
  });
  afterEach(cleanup);
  test("is FrtAccrlSearch Loads Successfully", () => {
    expect(screen.getByText("FrtAccrlSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for FrtAccrlSearch", () => {
    // START_USER_CODE-USER_FrtAccrlSearch_Custom_Test_Case
    // END_USER_CODE-USER_FrtAccrlSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_FrtAccrlSearch />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnPrintSearch(Button Widget) Test Cases", async () => {
    const btnPrintSearch = screen.getByTestId("btnPrintSearch");
    expect(btnPrintSearch).toBeInTheDocument;
    expect(btnPrintSearch.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_btnPrintSearch")
    );
  });
  test("Custom Test Cases for btnPrintSearch", () => {
    // START_USER_CODE-USER_btnPrintSearch_TEST
    // END_USER_CODE-USER_btnPrintSearch_TEST
  });
  test("btnReversePayments(Button Widget) Test Cases", async () => {
    const btnReversePayments = screen.getByTestId("btnReversePayments");
    expect(btnReversePayments).toBeInTheDocument;
    expect(btnReversePayments.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_btnReversePayments")
    );
  });
  test("Custom Test Cases for btnReversePayments", () => {
    // START_USER_CODE-USER_btnReversePayments_TEST
    // END_USER_CODE-USER_btnReversePayments_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnUpdateInvoice(Button Widget) Test Cases", async () => {
    const btnUpdateInvoice = screen.getByTestId("btnUpdateInvoice");
    expect(btnUpdateInvoice).toBeInTheDocument;
    expect(btnUpdateInvoice.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_btnUpdateInvoice")
    );
  });
  test("Custom Test Cases for btnUpdateInvoice", () => {
    // START_USER_CODE-USER_btnUpdateInvoice_TEST
    // END_USER_CODE-USER_btnUpdateInvoice_TEST
  });
  test("gridFreightAccural(Grid Widget) Test Cases", async () => {
    let gridFreightAccural = screen.getByTestId("gridFreightAccural");
    let gridFreightAccuralbtn =
      gridFreightAccural.nextElementSibling.firstElementChild;
    gridFreightAccural =
      gridFreightAccural.parentElement.parentElement.parentElement;
    expect(gridFreightAccural.tagName).toBe("DIV");
    expect(gridFreightAccural.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridFreightAccural", () => {
    // START_USER_CODE-USER_gridFreightAccural_TEST
    // END_USER_CODE-USER_gridFreightAccural_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("grpbxFrtAccrlSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxFrtAccrlSearch = screen.getByTestId("grpbxFrtAccrlSearch");
    expect(grpbxFrtAccrlSearch.tagName).toBe("BUTTON");
    expect(grpbxFrtAccrlSearch.type).toBe("button");
    expect(grpbxFrtAccrlSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFrtAccrlSearch", () => {
    // START_USER_CODE-USER_grpbxFrtAccrlSearch_TEST
    // END_USER_CODE-USER_grpbxFrtAccrlSearch_TEST
  });
  test("tctScaleTktNum(Textbox Widget) Test Cases", async () => {
    const tctScaleTktNum = screen.getByTestId("tctScaleTktNum");
    expect(tctScaleTktNum.tagName).toBe("INPUT");
    expect(tctScaleTktNum.type).toBe("text");
    expect(tctScaleTktNum.classList).toContain("textboxWidgetClass");
    expect(tctScaleTktNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_tctScaleTktNum")
    );
    await act(async () => {
      userEvent.type(tctScaleTktNum, "1");
    });
  });
  test("Custom Test Cases for tctScaleTktNum", () => {
    // START_USER_CODE-USER_tctScaleTktNum_TEST
    // END_USER_CODE-USER_tctScaleTktNum_TEST
  });
  test("txtCarrierAbbrNum(Textbox Widget) Test Cases", async () => {
    const txtCarrierAbbrNum = screen.getByTestId("txtCarrierAbbrNum");
    expect(txtCarrierAbbrNum.tagName).toBe("INPUT");
    expect(txtCarrierAbbrNum.type).toBe("text");
    expect(txtCarrierAbbrNum.classList).toContain("textboxWidgetClass");
    expect(txtCarrierAbbrNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_txtCarrierAbbrNum")
    );
    await act(async () => {
      userEvent.type(txtCarrierAbbrNum, "1");
    });
  });
  test("Custom Test Cases for txtCarrierAbbrNum", () => {
    // START_USER_CODE-USER_txtCarrierAbbrNum_TEST
    // END_USER_CODE-USER_txtCarrierAbbrNum_TEST
  });
  test("txtCheckNum(Textbox Widget) Test Cases", async () => {
    const txtCheckNum = screen.getByTestId("txtCheckNum");
    expect(txtCheckNum.tagName).toBe("INPUT");
    expect(txtCheckNum.type).toBe("text");
    expect(txtCheckNum.classList).toContain("textboxWidgetClass");
    expect(txtCheckNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_txtCheckNum")
    );
    await act(async () => {
      userEvent.type(txtCheckNum, "1");
    });
  });
  test("Custom Test Cases for txtCheckNum", () => {
    // START_USER_CODE-USER_txtCheckNum_TEST
    // END_USER_CODE-USER_txtCheckNum_TEST
  });
  test("gridFreightAccural_txtcolCarrierAbbr(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolCarrierAbbr = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolCarrierAbbrbtn =
      gridFreightAccural_txtcolCarrierAbbr.nextElementSibling.firstElementChild;
    gridFreightAccural_txtcolCarrierAbbr =
      gridFreightAccural_txtcolCarrierAbbr.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolCarrierAbbr.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolCarrierAbbr.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCarrierAbbr", () => {
    // START_USER_CODE-USER_txtcolCarrierAbbr_TEST
    // END_USER_CODE-USER_txtcolCarrierAbbr_TEST
  });
  test("gridFreightAccural_txtcolCheckNum(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolCheckNum = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolCheckNumbtn =
      gridFreightAccural_txtcolCheckNum.nextElementSibling.firstElementChild;
    gridFreightAccural_txtcolCheckNum =
      gridFreightAccural_txtcolCheckNum.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolCheckNum.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolCheckNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCheckNum", () => {
    // START_USER_CODE-USER_txtcolCheckNum_TEST
    // END_USER_CODE-USER_txtcolCheckNum_TEST
  });
  test("gridFreightAccural_txtcolCropYear(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolCropYear = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolCropYearbtn =
      gridFreightAccural_txtcolCropYear.nextElementSibling.firstElementChild;
    gridFreightAccural_txtcolCropYear =
      gridFreightAccural_txtcolCropYear.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolCropYear.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolCropYear.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCropYear", () => {
    // START_USER_CODE-USER_txtcolCropYear_TEST
    // END_USER_CODE-USER_txtcolCropYear_TEST
  });
  test("gridFreightAccural_txtcolDestinationLocation(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolDestinationLocation = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolDestinationLocationbtn =
      gridFreightAccural_txtcolDestinationLocation.nextElementSibling
        .firstElementChild;
    gridFreightAccural_txtcolDestinationLocation =
      gridFreightAccural_txtcolDestinationLocation.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolDestinationLocation.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolDestinationLocation.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDestinationLocation", () => {
    // START_USER_CODE-USER_txtcolDestinationLocation_TEST
    // END_USER_CODE-USER_txtcolDestinationLocation_TEST
  });
  test("gridFreightAccural_txtcolDispatchNum(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolDispatchNum = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolDispatchNumbtn =
      gridFreightAccural_txtcolDispatchNum.nextElementSibling.firstElementChild;
    gridFreightAccural_txtcolDispatchNum =
      gridFreightAccural_txtcolDispatchNum.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolDispatchNum.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolDispatchNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDispatchNum", () => {
    // START_USER_CODE-USER_txtcolDispatchNum_TEST
    // END_USER_CODE-USER_txtcolDispatchNum_TEST
  });
  test("gridFreightAccural_txtcolInvoiceNum(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolInvoiceNum = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolInvoiceNumbtn =
      gridFreightAccural_txtcolInvoiceNum.nextElementSibling.firstElementChild;
    gridFreightAccural_txtcolInvoiceNum =
      gridFreightAccural_txtcolInvoiceNum.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolInvoiceNum.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolInvoiceNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInvoiceNum", () => {
    // START_USER_CODE-USER_txtcolInvoiceNum_TEST
    // END_USER_CODE-USER_txtcolInvoiceNum_TEST
  });
  test("gridFreightAccural_txtcolIssuedDate(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolIssuedDate = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolIssuedDatebtn =
      gridFreightAccural_txtcolIssuedDate.nextElementSibling.firstElementChild;
    gridFreightAccural_txtcolIssuedDate =
      gridFreightAccural_txtcolIssuedDate.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolIssuedDate.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolIssuedDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolIssuedDate", () => {
    // START_USER_CODE-USER_txtcolIssuedDate_TEST
    // END_USER_CODE-USER_txtcolIssuedDate_TEST
  });
  test("gridFreightAccural_txtcolMemoNum(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolMemoNum = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolMemoNumbtn =
      gridFreightAccural_txtcolMemoNum.nextElementSibling.firstElementChild;
    gridFreightAccural_txtcolMemoNum =
      gridFreightAccural_txtcolMemoNum.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolMemoNum.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolMemoNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMemoNum", () => {
    // START_USER_CODE-USER_txtcolMemoNum_TEST
    // END_USER_CODE-USER_txtcolMemoNum_TEST
  });
  test("gridFreightAccural_txtcolPayableCropYear(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolPayableCropYear = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolPayableCropYearbtn =
      gridFreightAccural_txtcolPayableCropYear.nextElementSibling
        .firstElementChild;
    gridFreightAccural_txtcolPayableCropYear =
      gridFreightAccural_txtcolPayableCropYear.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolPayableCropYear.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolPayableCropYear.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPayableCropYear", () => {
    // START_USER_CODE-USER_txtcolPayableCropYear_TEST
    // END_USER_CODE-USER_txtcolPayableCropYear_TEST
  });
  test("gridFreightAccural_txtcolProceedAmount(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolProceedAmount = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolProceedAmountbtn =
      gridFreightAccural_txtcolProceedAmount.nextElementSibling
        .firstElementChild;
    gridFreightAccural_txtcolProceedAmount =
      gridFreightAccural_txtcolProceedAmount.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolProceedAmount.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolProceedAmount.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProceedAmount", () => {
    // START_USER_CODE-USER_txtcolProceedAmount_TEST
    // END_USER_CODE-USER_txtcolProceedAmount_TEST
  });
  test("gridFreightAccural_txtcolShippingLocation(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolShippingLocation = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolShippingLocationbtn =
      gridFreightAccural_txtcolShippingLocation.nextElementSibling
        .firstElementChild;
    gridFreightAccural_txtcolShippingLocation =
      gridFreightAccural_txtcolShippingLocation.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolShippingLocation.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolShippingLocation.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShippingLocation", () => {
    // START_USER_CODE-USER_txtcolShippingLocation_TEST
    // END_USER_CODE-USER_txtcolShippingLocation_TEST
  });
  test("gridFreightAccural_txtcolTicketNum(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolTicketNum = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolTicketNumbtn =
      gridFreightAccural_txtcolTicketNum.nextElementSibling.firstElementChild;
    gridFreightAccural_txtcolTicketNum =
      gridFreightAccural_txtcolTicketNum.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolTicketNum.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolTicketNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTicketNum", () => {
    // START_USER_CODE-USER_txtcolTicketNum_TEST
    // END_USER_CODE-USER_txtcolTicketNum_TEST
  });
  test("gridFreightAccural_txtcolTransferNum(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolTransferNum = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolTransferNumbtn =
      gridFreightAccural_txtcolTransferNum.nextElementSibling.firstElementChild;
    gridFreightAccural_txtcolTransferNum =
      gridFreightAccural_txtcolTransferNum.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolTransferNum.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolTransferNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTransferNum", () => {
    // START_USER_CODE-USER_txtcolTransferNum_TEST
    // END_USER_CODE-USER_txtcolTransferNum_TEST
  });
  test("gridFreightAccural_txtcolVendorNum(Grid Widget) Test Cases", async () => {
    let gridFreightAccural_txtcolVendorNum = screen.getByTestId(
      "gridFreightAccural"
    );
    let gridFreightAccural_txtcolVendorNumbtn =
      gridFreightAccural_txtcolVendorNum.nextElementSibling.firstElementChild;
    gridFreightAccural_txtcolVendorNum =
      gridFreightAccural_txtcolVendorNum.parentElement.parentElement
        .parentElement;
    expect(gridFreightAccural_txtcolVendorNum.tagName).toBe("DIV");
    expect(gridFreightAccural_txtcolVendorNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:FrtAccrlSearch_gridFreightAccural"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorNum", () => {
    // START_USER_CODE-USER_txtcolVendorNum_TEST
    // END_USER_CODE-USER_txtcolVendorNum_TEST
  });
  test("txtCropYear(Textbox Widget) Test Cases", async () => {
    const txtCropYear = screen.getByTestId("txtCropYear");
    expect(txtCropYear.tagName).toBe("INPUT");
    expect(txtCropYear.type).toBe("text");
    expect(txtCropYear.classList).toContain("textboxWidgetClass");
    expect(txtCropYear.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_txtCropYear")
    );
    await act(async () => {
      userEvent.type(txtCropYear, "1");
    });
  });
  test("Custom Test Cases for txtCropYear", () => {
    // START_USER_CODE-USER_txtCropYear_TEST
    // END_USER_CODE-USER_txtCropYear_TEST
  });
  test("txtDispatchNum(Textbox Widget) Test Cases", async () => {
    const txtDispatchNum = screen.getByTestId("txtDispatchNum");
    expect(txtDispatchNum.tagName).toBe("INPUT");
    expect(txtDispatchNum.type).toBe("text");
    expect(txtDispatchNum.classList).toContain("textboxWidgetClass");
    expect(txtDispatchNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_txtDispatchNum")
    );
    await act(async () => {
      userEvent.type(txtDispatchNum, "1");
    });
  });
  test("Custom Test Cases for txtDispatchNum", () => {
    // START_USER_CODE-USER_txtDispatchNum_TEST
    // END_USER_CODE-USER_txtDispatchNum_TEST
  });
  test("txtInvoiceNum(Textbox Widget) Test Cases", async () => {
    const txtInvoiceNum = screen.getByTestId("txtInvoiceNum");
    expect(txtInvoiceNum.tagName).toBe("INPUT");
    expect(txtInvoiceNum.type).toBe("text");
    expect(txtInvoiceNum.classList).toContain("textboxWidgetClass");
    expect(txtInvoiceNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_txtInvoiceNum")
    );
    await act(async () => {
      userEvent.type(txtInvoiceNum, "1");
    });
  });
  test("Custom Test Cases for txtInvoiceNum", () => {
    // START_USER_CODE-USER_txtInvoiceNum_TEST
    // END_USER_CODE-USER_txtInvoiceNum_TEST
  });
  test("txtMemoNum(Textbox Widget) Test Cases", async () => {
    const txtMemoNum = screen.getByTestId("txtMemoNum");
    expect(txtMemoNum.tagName).toBe("INPUT");
    expect(txtMemoNum.type).toBe("text");
    expect(txtMemoNum.classList).toContain("textboxWidgetClass");
    expect(txtMemoNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_txtMemoNum")
    );
    await act(async () => {
      userEvent.type(txtMemoNum, "1");
    });
  });
  test("Custom Test Cases for txtMemoNum", () => {
    // START_USER_CODE-USER_txtMemoNum_TEST
    // END_USER_CODE-USER_txtMemoNum_TEST
  });
  test("txtPayableCropYear(Textbox Widget) Test Cases", async () => {
    const txtPayableCropYear = screen.getByTestId("txtPayableCropYear");
    expect(txtPayableCropYear.tagName).toBe("INPUT");
    expect(txtPayableCropYear.type).toBe("text");
    expect(txtPayableCropYear.classList).toContain("textboxWidgetClass");
    expect(txtPayableCropYear.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_txtPayableCropYear")
    );
    await act(async () => {
      userEvent.type(txtPayableCropYear, "1");
    });
  });
  test("Custom Test Cases for txtPayableCropYear", () => {
    // START_USER_CODE-USER_txtPayableCropYear_TEST
    // END_USER_CODE-USER_txtPayableCropYear_TEST
  });
  test("txtTotals(Textbox Widget) Test Cases", async () => {
    const txtTotals = screen.getByTestId("txtTotals");
    expect(txtTotals.tagName).toBe("INPUT");
    expect(txtTotals.type).toBe("text");
    expect(txtTotals.classList).toContain("textboxWidgetClass");
    expect(txtTotals.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_txtTotals")
    );
    await act(async () => {
      userEvent.type(txtTotals, "123");
    });
    expect(txtTotals.getAttribute("value")).toBe("");
    expect(txtTotals.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotals", () => {
    // START_USER_CODE-USER_txtTotals_TEST
    // END_USER_CODE-USER_txtTotals_TEST
  });
  test("txtTransferNum(Textbox Widget) Test Cases", async () => {
    const txtTransferNum = screen.getByTestId("txtTransferNum");
    expect(txtTransferNum.tagName).toBe("INPUT");
    expect(txtTransferNum.type).toBe("text");
    expect(txtTransferNum.classList).toContain("textboxWidgetClass");
    expect(txtTransferNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_txtTransferNum")
    );
    await act(async () => {
      userEvent.type(txtTransferNum, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum", () => {
    // START_USER_CODE-USER_txtTransferNum_TEST
    // END_USER_CODE-USER_txtTransferNum_TEST
  });
  test("txtVendorNum(Textbox Widget) Test Cases", async () => {
    const txtVendorNum = screen.getByTestId("txtVendorNum");
    expect(txtVendorNum.tagName).toBe("INPUT");
    expect(txtVendorNum.type).toBe("text");
    expect(txtVendorNum.classList).toContain("textboxWidgetClass");
    expect(txtVendorNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:FrtAccrlSearch_txtVendorNum")
    );
    await act(async () => {
      userEvent.type(txtVendorNum, "1");
    });
  });
  test("Custom Test Cases for txtVendorNum", () => {
    // START_USER_CODE-USER_txtVendorNum_TEST
    // END_USER_CODE-USER_txtVendorNum_TEST
  });
});
