/* eslint-disable*/
import React from "react";
import ReportUsermanual_FarmSummaryReportRequest from "./FarmSummaryReportRequest";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FarmSummaryReportRequest Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ReportUsermanual_FarmSummaryReportRequest />);
    });
  });
  afterEach(cleanup);
  test("is FarmSummaryReportRequest Loads Successfully", () => {
    expect(screen.getByText("FarmSummaryReportRequest")).toBeInTheDocument;
  });
  test("Custom Test Cases for FarmSummaryReportRequest", () => {
    // START_USER_CODE-USER_FarmSummaryReportRequest_Custom_Test_Case
    // END_USER_CODE-USER_FarmSummaryReportRequest_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ReportUsermanual_FarmSummaryReportRequest />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("ReportUsermanual:FarmSummaryReportRequest_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnSubmit(Button Widget) Test Cases", async () => {
    const btnSubmit = screen.getByTestId("btnSubmit");
    expect(btnSubmit).toBeInTheDocument;
    expect(btnSubmit.textContent).toEqual(
      t("ReportUsermanual:FarmSummaryReportRequest_btnSubmit")
    );
  });
  test("Custom Test Cases for btnSubmit", () => {
    // START_USER_CODE-USER_btnSubmit_TEST
    // END_USER_CODE-USER_btnSubmit_TEST
  });
  test("grpbxFarmSummary(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmSummary = screen.getByTestId("grpbxFarmSummary");
    expect(grpbxFarmSummary.tagName).toBe("BUTTON");
    expect(grpbxFarmSummary.type).toBe("button");
    expect(grpbxFarmSummary.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmSummary", () => {
    // START_USER_CODE-USER_grpbxFarmSummary_TEST
    // END_USER_CODE-USER_grpbxFarmSummary_TEST
  });
  test("grpbxRadioFarmSummary(GroupBox Widget) Test Cases", async () => {
    const grpbxRadioFarmSummary = screen.getByTestId("grpbxRadioFarmSummary");
    expect(grpbxRadioFarmSummary.tagName).toBe("BUTTON");
    expect(grpbxRadioFarmSummary.type).toBe("button");
    expect(grpbxRadioFarmSummary.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRadioFarmSummary", () => {
    // START_USER_CODE-USER_grpbxRadioFarmSummary_TEST
    // END_USER_CODE-USER_grpbxRadioFarmSummary_TEST
  });
  test("lblAllLocFarmSummaryReport(Label Widget) Test Cases", async () => {
    const lblAllLocFarmSummaryReport = screen.getByTestId(
      "lblAllLocFarmSummaryReport"
    );
    expect(lblAllLocFarmSummaryReport.tagName).toBe("LABEL");
    expect(lblAllLocFarmSummaryReport.classList).toContain("form-label");
    expect(lblAllLocFarmSummaryReport.textContent).toEqual(
      t("ReportUsermanual:FarmSummaryReportRequest_lblAllLocFarmSummaryReport")
    );
  });
  test("Custom Test Cases for lblAllLocFarmSummaryReport", () => {
    // START_USER_CODE-USER_lblAllLocFarmSummaryReport_TEST
    // END_USER_CODE-USER_lblAllLocFarmSummaryReport_TEST
  });
  test("txtByngPt(Textbox Widget) Test Cases", async () => {
    const txtByngPt = screen.getByTestId("txtByngPt");
    expect(txtByngPt.tagName).toBe("INPUT");
    expect(txtByngPt.type).toBe("text");
    expect(txtByngPt.classList).toContain("textboxWidgetClass");
    expect(txtByngPt.previousElementSibling.textContent).toEqual(
      t("ReportUsermanual:FarmSummaryReportRequest_txtByngPt")
    );
    await act(async () => {
      userEvent.type(txtByngPt, "1");
    });
  });
  test("Custom Test Cases for txtByngPt", () => {
    // START_USER_CODE-USER_txtByngPt_TEST
    // END_USER_CODE-USER_txtByngPt_TEST
  });
  test("txtCountyID(Textbox Widget) Test Cases", async () => {
    const txtCountyID = screen.getByTestId("txtCountyID");
    expect(txtCountyID.tagName).toBe("INPUT");
    expect(txtCountyID.type).toBe("text");
    expect(txtCountyID.classList).toContain("textboxWidgetClass");
    expect(txtCountyID.previousElementSibling.textContent).toEqual(
      t("ReportUsermanual:FarmSummaryReportRequest_txtCountyID")
    );
    await act(async () => {
      userEvent.type(txtCountyID, "1");
    });
  });
  test("Custom Test Cases for txtCountyID", () => {
    // START_USER_CODE-USER_txtCountyID_TEST
    // END_USER_CODE-USER_txtCountyID_TEST
  });
  test("txtFarmNumber(Textbox Widget) Test Cases", async () => {
    const txtFarmNumber = screen.getByTestId("txtFarmNumber");
    expect(txtFarmNumber.tagName).toBe("INPUT");
    expect(txtFarmNumber.type).toBe("text");
    expect(txtFarmNumber.classList).toContain("textboxWidgetClass");
    expect(txtFarmNumber.previousElementSibling.textContent).toEqual(
      t("ReportUsermanual:FarmSummaryReportRequest_txtFarmNumber")
    );
    await act(async () => {
      userEvent.type(txtFarmNumber, "1");
    });
  });
  test("Custom Test Cases for txtFarmNumber", () => {
    // START_USER_CODE-USER_txtFarmNumber_TEST
    // END_USER_CODE-USER_txtFarmNumber_TEST
  });
  test("txtFarmSuffix(Textbox Widget) Test Cases", async () => {
    const txtFarmSuffix = screen.getByTestId("txtFarmSuffix");
    expect(txtFarmSuffix.tagName).toBe("INPUT");
    expect(txtFarmSuffix.type).toBe("text");
    expect(txtFarmSuffix.classList).toContain("textboxWidgetClass");
    expect(txtFarmSuffix.previousElementSibling.textContent).toEqual(
      t("ReportUsermanual:FarmSummaryReportRequest_txtFarmSuffix")
    );
    await act(async () => {
      userEvent.type(txtFarmSuffix, "1");
    });
  });
  test("Custom Test Cases for txtFarmSuffix", () => {
    // START_USER_CODE-USER_txtFarmSuffix_TEST
    // END_USER_CODE-USER_txtFarmSuffix_TEST
  });
  test("txtRequestedVendor(Textbox Widget) Test Cases", async () => {
    const txtRequestedVendor = screen.getByTestId("txtRequestedVendor");
    expect(txtRequestedVendor.tagName).toBe("INPUT");
    expect(txtRequestedVendor.type).toBe("text");
    expect(txtRequestedVendor.classList).toContain("textboxWidgetClass");
    expect(txtRequestedVendor.previousElementSibling.textContent).toEqual(
      t("ReportUsermanual:FarmSummaryReportRequest_txtRequestedVendor")
    );
    await act(async () => {
      userEvent.type(txtRequestedVendor, "1");
    });
  });
  test("Custom Test Cases for txtRequestedVendor", () => {
    // START_USER_CODE-USER_txtRequestedVendor_TEST
    // END_USER_CODE-USER_txtRequestedVendor_TEST
  });
  test("txtSpecificContrct(Textbox Widget) Test Cases", async () => {
    const txtSpecificContrct = screen.getByTestId("txtSpecificContrct");
    expect(txtSpecificContrct.tagName).toBe("INPUT");
    expect(txtSpecificContrct.type).toBe("text");
    expect(txtSpecificContrct.classList).toContain("textboxWidgetClass");
    expect(txtSpecificContrct.previousElementSibling.textContent).toEqual(
      t("ReportUsermanual:FarmSummaryReportRequest_txtSpecificContrct")
    );
    await act(async () => {
      userEvent.type(txtSpecificContrct, "1");
    });
  });
  test("Custom Test Cases for txtSpecificContrct", () => {
    // START_USER_CODE-USER_txtSpecificContrct_TEST
    // END_USER_CODE-USER_txtSpecificContrct_TEST
  });
  test("txtStateAbbr(Textbox Widget) Test Cases", async () => {
    const txtStateAbbr = screen.getByTestId("txtStateAbbr");
    expect(txtStateAbbr.tagName).toBe("INPUT");
    expect(txtStateAbbr.type).toBe("text");
    expect(txtStateAbbr.classList).toContain("textboxWidgetClass");
    expect(txtStateAbbr.previousElementSibling.textContent).toEqual(
      t("ReportUsermanual:FarmSummaryReportRequest_txtStateAbbr")
    );
    await act(async () => {
      userEvent.type(txtStateAbbr, "1");
    });
  });
  test("Custom Test Cases for txtStateAbbr", () => {
    // START_USER_CODE-USER_txtStateAbbr_TEST
    // END_USER_CODE-USER_txtStateAbbr_TEST
  });
});
