/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import Loading from "../../../Loader/Loading";

import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  setValue,
  setData,
  getData,
  getValue,
  hide,
  show,
  disable,
  enable,
  hideColumn,
  goTo,
  setGridValue,
  getGridValue,
  isVisible,
} from "../../shared/WindowImports";

import "./WarehouseReceiptRedeemByLoan.scss";
import { WarehouseReceiptService } from "../../WarehouseReceipts/Service/WarehouseReceiptService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"


// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { response } from "msw";
import { FormatNumber } from "../../Common/CommonFunctions"

// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_WarehouseReceiptRedeemByLoan(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const [selectedRows, setSelectedRows] = useState([])
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "WarehouseReceiptRedeemByLoan",
    windowName: "WarehouseReceiptRedeemByLoan",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.WarehouseReceiptRedeemByLoan",
    // START_USER_CODE-USER_WarehouseReceiptRedeemByLoan_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Warehouse Receipt Redeem by Loan Number",
      scrCode: "PN1090R",
    },
    // END_USER_CODE-USER_WarehouseReceiptRedeemByLoan_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxRepayment",
      Label: "Close",
      CharWidth: "13",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnPurchase: {
      name: "btnPurchase",
      type: "ButtonWidget",
      parent: "grpbxRepayment",
      Label: "Purchase",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPurchase_PROPERTIES

      // END_USER_CODE-USER_btnPurchase_PROPERTIES
    },
    btnRepayVendor: {
      name: "btnRepayVendor",
      type: "ButtonWidget",
      parent: "grpbxRepayment",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnRepayVendor_PROPERTIES

      // END_USER_CODE-USER_btnRepayVendor_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxSearch",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxSearch",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    btncolReceipt: {
      name: "btncolReceipt",
      type: "ButtonWidget",
      colName: "colWReceipt",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "...",
      CharWidth: "9",
      Height: "",
      Width: "",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncol1_PROPERTIES

      // END_USER_CODE-USER_btncol1_PROPERTIES
    },

    colWReceipt: {
      name: "colWReceipt",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },
    chkboxAll: {
      name: "chkboxAll",
      type: "CheckBoxWidget",
      parent: "grpbxSearch",
      Label: "ALL",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxAll_PROPERTIES

      // END_USER_CODE-USER_chkboxAll_PROPERTIES
    },
    chkboxCCC500: {
      name: "chkboxCCC500",
      type: "CheckBoxWidget",
      parent: "grpbxSearch",
      Label: "CCC 500?",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCCC500_PROPERTIES

      // END_USER_CODE-USER_chkboxCCC500_PROPERTIES
    },
    chkboxWireTransfer: {
      name: "chkboxWireTransfer",
      type: "CheckBoxWidget",
      parent: "grpbxRepayment",
      Label: "Wire Transfer",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxWireTransfer_PROPERTIES

      // END_USER_CODE-USER_chkboxWireTransfer_PROPERTIES
    },
    // chkboxcolSel: {
    //   name: "chkboxcolSel",
    //   type: "CheckBoxWidget",
    //   colName: "colSel",
    //   parent: "gridWarehouseReceiptRedeembyLoan",
    //   CharWidth: "48",
    //   Height: "",
    //   Width: "",
    //   HasLabel: false,
    //   ofTypeDomain: "d_boolean",
    //   // START_USER_CODE-USER_chkboxcol_PROPERTIES
    //   isMultiSelectAlternate: true
    //   // END_USER_CODE-USER_chkboxcol_PROPERTIES
    // },
    // colSel: {
    //   name: "colSel",
    //   type: "GridColumnWidget",
    //   parent: "gridWarehouseReceiptRedeembyLoan",
    //   HasLabel: false,
    //   Height: "",
    //   Width: "",
    //   // START_USER_CODE-USER_colCheckbox_PROPERTIES

    //   // END_USER_CODE-USER_colCheckbox_PROPERTIES
    // },
    colRevision: {
      name: "colRevision",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "Revision Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPt_PROPERTIES
      Visible: false
      // END_USER_CODE-USER_colBuyPt_PROPERTIES
    },
    colBuyPt: {
      name: "colBuyPt",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "Buy Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPt_PROPERTIES

      // END_USER_CODE-USER_colBuyPt_PROPERTIES
    },
    colCCC500: {
      name: "colCCC500",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "CCC500",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCCC500_PROPERTIES

      // END_USER_CODE-USER_colCCC500_PROPERTIES
    },
    colHandlingAmount: {
      name: "colHandlingAmount",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "Handling Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHandlingAmount_PROPERTIES

      // END_USER_CODE-USER_colHandlingAmount_PROPERTIES
    },
    colInterestAmount: {
      name: "colInterestAmount",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "Interest Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInterestAmount_PROPERTIES

      // END_USER_CODE-USER_colInterestAmount_PROPERTIES
    },
    colLoanRepayAmount: {
      name: "colLoanRepayAmount",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "Loan Repay Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoanRepayAmount_PROPERTIES

      // END_USER_CODE-USER_colLoanRepayAmount_PROPERTIES
    },
    colMarketGain: {
      name: "colMarketGain",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "Market Gain",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMarketGain_PROPERTIES

      // END_USER_CODE-USER_colMarketGain_PROPERTIES
    },
    colNetRepayAmount: {
      name: "colNetRepayAmount",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "Net Repay Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetRepayAmount_PROPERTIES

      // END_USER_CODE-USER_colNetRepayAmount_PROPERTIES
    },
    colPrincpalLoaned: {
      name: "colPrincpalLoaned",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "Prinicipal Loaned",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrincpalLoaned_PROPERTIES

      // END_USER_CODE-USER_colPrincpalLoaned_PROPERTIES
    },
    colReceipt: {
      name: "colReceipt",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "Receipt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReceipt_PROPERTIES

      // END_USER_CODE-USER_colReceipt_PROPERTIES
    },
    colReceiptValue: {
      name: "colReceiptValue",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "Receipt Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReceiptValue_PROPERTIES

      // END_USER_CODE-USER_colReceiptValue_PROPERTIES
    },
    // colSel: {
    //   name: "colSel",
    //   type: "GridColumnWidget",
    //   parent: "gridWarehouseReceiptRedeembyLoan",
    //   Label: "Sel",
    //   Height: "",
    //   Width: "",
    //   // START_USER_CODE-USER_colSel_PROPERTIES

    //   // END_USER_CODE-USER_colSel_PROPERTIES
    // },
    colTotalTons: {
      name: "colTotalTons",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "Total Tons",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotalTons_PROPERTIES

      // END_USER_CODE-USER_colTotalTons_PROPERTIES
    },
    ddRepayRemit: {
      name: "ddRepayRemit",
      type: "DropDownFieldWidget",
      parent: "grpbxRepayment",
      Label: "Repay Remit",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRepayRemit_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_ddRepayRemit_PROPERTIES
    },
    chkbxCol: {
      name: "chkbxCol",
      type: "CheckBoxWidget",
      colName: "colChckbx",
      parent: "gridWarehouseReceiptRedeembyLoan",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    colChckbx: {
      name: "colChckbx",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChckbx_PROPERTIES

      // END_USER_CODE-USER_colChckbx_PROPERTIES
    },
    gridWarehouseReceiptRedeembyLoan: {
      name: "gridWarehouseReceiptRedeembyLoan",
      type: "GridWidget",
      parent: "grpbxWarehouseReceiptReedembyyLoanNum",
      gridCellsOrder:
        "chkbxCol,txtcolRevision,txtcolBuyPt,txtcolReceipt,btncolReceipt,txtcolTotalTons,txtcolReceiptValue,txtcolPrincipalLoaned,txtcolMarketGain,txtcolLoanRepayAmount,txtcolHandlingAmount,txtcolInterestAmount,txtcolNetRepayAmount,txtcolCCC500,txtcolHolder,txtcolRcptTyp,txtcolDelvInd",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isEditable: true,
      toggleRender: true,
      // isMultiSelect: true,
      // START_USER_CODE-USER_gridWarehouseReceiptRedeembyLoan_PROPERTIES
      isCobolTable: true,
      conditionalRowStyles: [
        {
          when: (row) => { return true },
          style: {
            '.textboxWidgetClass': {
              pointerEvents: "none",
              border: 'none',
              boxShadow: 'none',
              background: 'transparent',
            }
          }
        }
      ]
      // END_USER_CODE-USER_gridWarehouseReceiptRedeembyLoan_PROPERTIES
    },
    grpbxRepayment: {
      name: "grpbxRepayment",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceiptReedembyyLoanNum",
      Label: "groupboxwidget4",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxRepayment_PROPERTIES

      // END_USER_CODE-USER_grpbxRepayment_PROPERTIES
    },
    grpbxSearch: {
      name: "grpbxSearch",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceiptReedembyyLoanNum",
      Height: "",
      Width: "",
      ColsForMobile: "8",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxSearch_PROPERTIES
    },
    grpbxTotal: {
      name: "grpbxTotal",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceiptReedembyyLoanNum",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxTotal_PROPERTIES

      // END_USER_CODE-USER_grpbxTotal_PROPERTIES
    },
    grpbxTotalC: {
      name: "grpbxTotalC",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceiptReedembyyLoanNum",
      Height: "",
      Width: "",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxTotalC_PROPERTIES

      // END_USER_CODE-USER_grpbxTotalC_PROPERTIES
    },
    lblRepayment: {
      name: "lblRepayment",
      type: "LabelWidget",
      parent: "grpbxRepayment",
      Label: "[Repayment]",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRepayment_PROPERTIES

      // END_USER_CODE-USER_lblRepayment_PROPERTIES
    },
    radioScreenmode: {
      name: "radioScreenmode",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxSearch",
      Options: "Purchase:1,After Purchase:2",
      Label: "Screen Mode",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioScreenmode_PROPERTIES

      // END_USER_CODE-USER_radioScreenmode_PROPERTIES
    },
    txtcolRevision: {
      name: "txtcolRevision",
      type: "TextBoxWidget",
      colName: "colRevision",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget19",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyPt_PROPERTIES
    },
    txtcolBuyPt: {
      name: "txtcolBuyPt",
      type: "TextBoxWidget",
      colName: "colBuyPt",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget19",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyPt_PROPERTIES
    },
    txtcolCCC500: {
      name: "txtcolCCC500",
      type: "TextBoxWidget",
      colName: "colCCC500",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget13",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCCC500_PROPERTIES

      // END_USER_CODE-USER_txtcolCCC500_PROPERTIES
    },
    txtcolHandlingAmount: {
      name: "txtcolHandlingAmount",
      type: "TextBoxWidget",
      colName: "colHandlingAmount",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget29",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHandlingAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolHandlingAmount_PROPERTIES
    },
    txtcolInterestAmount: {
      name: "txtcolInterestAmount",
      type: "TextBoxWidget",
      colName: "colInterestAmount",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget27",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInterestAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolInterestAmount_PROPERTIES
    },
    txtcolLoanRepayAmount: {
      name: "txtcolLoanRepayAmount",
      type: "TextBoxWidget",
      colName: "colLoanRepayAmount",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget31",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoanRepayAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolLoanRepayAmount_PROPERTIES
    },
    txtcolMarketGain: {
      name: "txtcolMarketGain",
      type: "TextBoxWidget",
      colName: "colMarketGain",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget33",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMarketGain_PROPERTIES

      // END_USER_CODE-USER_txtcolMarketGain_PROPERTIES
    },
    txtcolNetRepayAmount: {
      name: "txtcolNetRepayAmount",
      type: "TextBoxWidget",
      colName: "colNetRepayAmount",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget15",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNetRepayAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolNetRepayAmount_PROPERTIES
    },
    txtcolPrincipalLoaned: {
      name: "txtcolPrincipalLoaned",
      type: "TextBoxWidget",
      colName: "colPrincpalLoaned",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget35",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrincipalLoaned_PROPERTIES

      // END_USER_CODE-USER_txtcolPrincipalLoaned_PROPERTIES
    },
    txtcolReceipt: {
      name: "txtcolReceipt",
      type: "TextBoxWidget",
      colName: "colReceipt",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget21",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolReceipt_PROPERTIES

      // END_USER_CODE-USER_txtcolReceipt_PROPERTIES
    },
    txtcolReceiptValue: {
      name: "txtcolReceiptValue",
      type: "TextBoxWidget",
      colName: "colReceiptValue",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget25",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolReceiptValue_PROPERTIES

      // END_USER_CODE-USER_txtcolReceiptValue_PROPERTIES
    },
    txtcolHolder: {
      name: "txtcolHolder",
      type: "TextBoxWidget",
      colName: "colHolder",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget19",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolRcptTyp: {
      name: "txtcolRcptTyp",
      type: "TextBoxWidget",
      colName: "colRcptTyp",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget19",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolDelvInd: {
      name: "txtcolDelvInd",
      type: "TextBoxWidget",
      colName: "colDelvInd",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget19",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    // txtcolSel: {
    //   name: "txtcolSel",
    //   type: "TextBoxWidget",
    //   colName: "colSel",
    //   parent: "gridWarehouseReceiptRedeembyLoan",
    //   Label: "textboxwidget17",
    //   CharWidth: "48",
    //   Height: "",
    //   Width: "",
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtcolSel_PROPERTIES

    //   // END_USER_CODE-USER_txtcolSel_PROPERTIES
    // },
    txtcolTotalTons: {
      name: "txtcolTotalTons",
      type: "TextBoxWidget",
      colName: "colTotalTons",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "textboxwidget23",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotalTons_PROPERTIES

      // END_USER_CODE-USER_txtcolTotalTons_PROPERTIES
    },
    colHolder: {
      name: "colHolder",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "Holder Number",
      Height: "",
      Width: "",
      Visible: false,
    },
    colRcptTyp: {
      name: "colRcptTyp",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "EWR Receipt Type",
      Height: "",
      Width: "",
      Visible: false,
    },
    colDelvInd: {
      name: "colDelvInd",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptRedeembyLoan",
      Label: "Delv Ind",
      Height: "",
      Width: "",
      Visible: false,
    },
    txtLoanNum1: {
      name: "txtLoanNum1",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Loan Number:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanNum1_PROPERTIES

      // END_USER_CODE-USER_txtLoanNum1_PROPERTIES
    },
    txtLoanNum2: {
      name: "txtLoanNum2",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanNum2_PROPERTIES

      // END_USER_CODE-USER_txtLoanNum2_PROPERTIES
    },
    txtLoanNum3: {
      name: "txtLoanNum3",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanNum3_PROPERTIES

      // END_USER_CODE-USER_txtLoanNum3_PROPERTIES
    },
    txtRedemptionEndDate: {
      name: "txtRedemptionEndDate",
      type: "DateWidget",
      parent: "grpbxTotal",
      Label: "Redemption End Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtRedemptionEndDate_PROPERTIES

      // END_USER_CODE-USER_txtRedemptionEndDate_PROPERTIES
    },
    txtRepayVendor: {
      name: "txtRepayVendor",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      Label: "Repay Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRepayVendor_PROPERTIES

      // END_USER_CODE-USER_txtRepayVendor_PROPERTIES
    },
    txtRepayVndr: {
      name: "txtRepayVndr",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRepayVendor_PROPERTIES
      ReadOnly: true,
      Enabled: false
      // END_USER_CODE-USER_txtRepayVendor_PROPERTIES
    },
    txtTotalAmountDue: {
      name: "txtTotalAmountDue",
      type: "TextBoxWidget",
      parent: "grpbxTotal",
      Label: "Total Amount Due:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalAmountDue_PROPERTIES

      // END_USER_CODE-USER_txtTotalAmountDue_PROPERTIES
    },
    txtTotalHandling: {
      name: "txtTotalHandling",
      type: "TextBoxWidget",
      parent: "grpbxTotal",
      Label: "Total Handling:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalHandling_PROPERTIES

      // END_USER_CODE-USER_txtTotalHandling_PROPERTIES
    },
    txtTotalHandlingAmount: {
      name: "txtTotalHandlingAmount",
      type: "TextBoxWidget",
      parent: "grpbxTotalC",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalHandlingAmount_PROPERTIES

      // END_USER_CODE-USER_txtTotalHandlingAmount_PROPERTIES
    },
    txtTotalInterestAmount: {
      name: "txtTotalInterestAmount",
      type: "TextBoxWidget",
      parent: "grpbxTotalC",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalInterestAmount_PROPERTIES

      // END_USER_CODE-USER_txtTotalInterestAmount_PROPERTIES
    },
    txtTotalInterestDue: {
      name: "txtTotalInterestDue",
      type: "TextBoxWidget",
      parent: "grpbxTotal",
      Label: "Total Interest Due:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalInterestDue_PROPERTIES

      // END_USER_CODE-USER_txtTotalInterestDue_PROPERTIES
    },
    txtTotalLoanRepayAmount: {
      name: "txtTotalLoanRepayAmount",
      type: "TextBoxWidget",
      parent: "grpbxTotalC",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLoanRepayAmount_PROPERTIES

      // END_USER_CODE-USER_txtTotalLoanRepayAmount_PROPERTIES
    },
    txtTotalMarketGain: {
      name: "txtTotalMarketGain",
      type: "TextBoxWidget",
      parent: "grpbxTotalC",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalMarketGain_PROPERTIES

      // END_USER_CODE-USER_txtTotalMarketGain_PROPERTIES
    },
    txtTotalMrktGain: {
      name: "txtTotalMrktGain",
      type: "TextBoxWidget",
      parent: "grpbxTotal",
      Label: "Total Market Gain:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalMrktGain_PROPERTIES

      // END_USER_CODE-USER_txtTotalMrktGain_PROPERTIES
    },
    txtTotalNetRepayAmount: {
      name: "txtTotalNetRepayAmount",
      type: "TextBoxWidget",
      parent: "grpbxTotalC",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalNetRepayAmount_PROPERTIES

      // END_USER_CODE-USER_txtTotalNetRepayAmount_PROPERTIES
    },
    txtTotalprincipalLiquidated: {
      name: "txtTotalprincipalLiquidated",
      type: "TextBoxWidget",
      parent: "grpbxTotal",
      Label: "Total Principal Liquidated:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalprincipalLiquidated_PROPERTIES

      // END_USER_CODE-USER_txtTotalprincipalLiquidated_PROPERTIES
    },
    txtTotalPrincipalLoaned: {
      name: "txtTotalPrincipalLoaned",
      type: "TextBoxWidget",
      parent: "grpbxTotalC",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalPrincipalLoaned_PROPERTIES

      // END_USER_CODE-USER_txtTotalPrincipalLoaned_PROPERTIES
    },
    txtTotalReceiptValue: {
      name: "txtTotalReceiptValue",
      type: "TextBoxWidget",
      parent: "grpbxTotalC",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalReceiptValue_PROPERTIES

      // END_USER_CODE-USER_txtTotalReceiptValue_PROPERTIES
    },
    txtTotalTons: {
      name: "txtTotalTons",
      type: "TextBoxWidget",
      parent: "grpbxTotalC",
      Label: "Totals:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalTons_PROPERTIES

      // END_USER_CODE-USER_txtTotalTons_PROPERTIES
    },
    txtWireTransferData: {
      name: "txtWireTransferData",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData_PROPERTIES
    },
    txtWireTransferData2: {
      name: "txtWireTransferData2",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData2_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData2_PROPERTIES
    },
    txtWireTransferData3: {
      name: "txtWireTransferData3",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData3_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData3_PROPERTIES
    },
    txtWireTransferData4: {
      name: "txtWireTransferData4",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData4_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData4_PROPERTIES
    },
    txtWireTransferData5: {
      name: "txtWireTransferData5",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData5_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData5_PROPERTIES
    },
    txtWireTransferData6: {
      name: "txtWireTransferData6",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData6_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData6_PROPERTIES
    },
    txtConcordia1: {
      name: "txtConcordia1",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData6_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData6_PROPERTIES
    },
    txtConcordia2: {
      name: "txtConcordia2",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData6_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData6_PROPERTIES
    },
    txtConcordia3: {
      name: "txtConcordia3",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData6_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData6_PROPERTIES
    },

    txtWireXferData: {
      name: "txtWireXferData",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData6_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData6_PROPERTIES
    },

    grpbxWarehouseReceiptReedembyyLoanNum: {
      name: "grpbxWarehouseReceiptReedembyyLoanNum",
      type: "GroupBoxWidget",
      parent: "WarehouseReceiptRedeemByLoan",
      Label: "groupboxwidget58",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxWarehouseReceiptReedembyyLoanNum_PROPERTIES

      // END_USER_CODE-USER_grpbxWarehouseReceiptReedembyyLoanNum_PROPERTIES
    },
  };
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  let userid = useridFromLS();
  let loan_number = 0;
  let mlRowCount = 0;
  let rcpt_status = "";
  let stat_type = "";
  let msWireData = "";
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [Data, setDatas] = useState("");
  const [loading, setLoading] = useState(false);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    _kaledo.thisObj = thisObj
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, "radioScreenmode", "1");
    setValue(thisObj, "txtTotalTons", "0.00");
    setValue(thisObj, "txtTotalReceiptValue", "0.00");
    setValue(thisObj, "txtTotalPrincipalLoaned", "0.00");
    setValue(thisObj, "txtTotalMarketGain", "0.00");
    setValue(thisObj, "txtTotalLoanRepayAmount", "0.00");
    setValue(thisObj, "txtTotalHandlingAmount", "0.00");
    setValue(thisObj, "txtTotalInterestAmount", "0.00");
    setValue(thisObj, "txtTotalNetRepayAmount", "0.00");
    disable(thisObj, "btnPurchase");
    hide(thisObj, 'txtRepayVndr', false)
    hide(thisObj, 'txtConcordia1', false)
    hide(thisObj, 'txtConcordia2', false)
    hide(thisObj, 'txtConcordia3', false)
    hide(thisObj, 'txtConcordia4', false)
    hide(thisObj, 'txtWireXferData');
    hide(thisObj, "txtWireTransferData");
    hide(thisObj, "txtWireTransferData2");
    hide(thisObj, "txtWireTransferData3");
    hide(thisObj, "txtWireTransferData4");
    hide(thisObj, "txtWireTransferData5");
    hide(thisObj, "txtWireTransferData6");
  }, []);

  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if ((vendor_lookup !== null) && (vendor_lookup !== "") && (vendor_lookup !== undefined)) {
      setValue(thisObj,"txtRepayVendor", vendor_lookup.VendorNumber);
      setValue(thisObj,"txtRepayVndr", vendor_lookup.key);
      document.getElementsByClassName("txtRepayVndr")[0].style.visibility = "visible";

      VendorDetails(vendor_lookup.VendorNumber);
    }
    else {
      document.getElementsByClassName("txtRepayVndr")[0].style.visibility = "hidden";
    }
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {

    if (getValue(thisObj, 'gridWarehouseReceiptRedeembyLoan').length > 0) {

    let gridStyle = [
      {
        when: (row) => { return selectedRows.includes(row.rowID$) === true },
        style: {
          pointerEvents: "auto",
          border: 'auto',
          boxShadow: 'auto',
          'input[id$="txtcolBuyPt"]': {
            pointerEvents: "none",
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
            fontWeight:"700"
          },
          'input[id$="txtcolReceipt"]': {
            pointerEvents: "none",
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
            fontWeight:"700"
          },
          'input[id$="txtcolTotalTons"]': {
            pointerEvents: "none",
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
            fontWeight:"700"
          },
          'input[id$="txtcolReceiptValue"]': {
            pointerEvents: "none",
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
            fontWeight:"700"
          },
          'input[id$="txtcolPrincipalLoaned"]': {
            pointerEvents: "none",
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
            fontWeight:"700"
          },
          'input[id$="txtcolNetRepayAmount"]': {
            pointerEvents: "none",
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
            fontWeight:"700"
          },
          'input[id$="txtcolCCC500"]': {
            pointerEvents: "none",
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
            fontWeight:"700"
          }

          }
        },
        {
          when: (row) => { return selectedRows.includes(row.rowID$) === false },
          style: {
            '.textboxWidgetClass': {
              pointerEvents: "none",
              border: 'none',
              boxShadow: 'none',
              background: 'transparent',
            }
          }
        }]
      if (getValue(thisObj, 'radioScreenmode') == 2) {
        let selectedRowsArr = []

        gridStyle = [
          {
          when: (row) => { return selectedRows.includes(row.rowID$)  === true},
          style: {
            backgroundColor: '#c0bcbc',
            '.textboxWidgetClass': {
              pointerEvents: "none",
              border: 'none',
              boxShadow: 'none',
              background: 'transparent',
              fontWeight:'700'
            },
            '.rdt_TableCell':{
              backgroundColor: '#c0bcbc',
            }
          }
        },
        {
          when: (row) => { return selectedRows.includes(row.rowID$)  === false},
          style: {
           
            backgroundColor: '#c0bcbc',
            '.rdt_TableCell':{
              backgroundColor: '#c0bcbc',
            },
            '.textboxWidgetClass': {
              pointerEvents: "none",
              border: 'none',
              boxShadow: 'none',
              background: 'transparent',
             
            }
          }
        }
      ]
      let rowData = getValue(thisObj, 'gridWarehouseReceiptRedeembyLoan');

        for (let i = 0; i < selectedRows.length; i++) {
          let value = getValue(thisObj, 'gridWarehouseReceiptRedeembyLoan')[selectedRows[i]];
          selectedRowsArr.push(value);
        }
        if (selectedRowsArr.length !== rowData.length) {
          setValue(thisObj, 'chkboxAll', false)
        }
        else {
          setValue(thisObj, 'chkboxAll', true)

        }
      }
      else {
        let totalTons = 0;
        let totalReceiptValue = 0;
        let totalPrincipalLoaned = 0;
        let totalMarketGain = 0;
        let totalLoanRepayAmount = 0;
        let totalHandlingAmount = 0;
        let totalInterestAmount = 0;
        let totalNetRepayAmount = 0;
        let marketGain = 0;
        let handleAmount = 0;
        let intersetAmount = 0;
        let rcptStatus = "I";
        let statType = "Y";
        let loanNum1 = thisObj.values.txtLoanNum1;
        let loanNum2 = thisObj.values.txtLoanNum2;
        let LoanNum3 = thisObj.values.txtLoanNum3;
        let loaNumber = loanNum1 + loanNum2 + LoanNum3;
        let selectedRowsArr = []

        let rowData = getValue(thisObj, 'gridWarehouseReceiptRedeembyLoan');

        for (let i = 0; i < selectedRows.length; i++) {
          let value = getValue(thisObj, 'gridWarehouseReceiptRedeembyLoan')[selectedRows[i]];
          selectedRowsArr.push(value);
        }
        if (selectedRowsArr.length !== rowData.length) {
          setValue(thisObj, 'chkboxAll', false)
        }
        else {
          setValue(thisObj, 'chkboxAll', true)

        }
        if (selectedRowsArr !== undefined && selectedRowsArr !== null) {
          chkAllRowsToPurchase(selectedRowsArr);

        }

        let clearControl = 1;
        // let rowData = getValue(thisObj, 'gridWarehouseReceiptRedeembyLoan');
        if (rowData !== undefined && rowData !== null && rowData !== "" && rowData.length > 0) {
          for (let i = 0; i < rowData.length; i++) {
            if (rowData[i].isSelected == true) {
              clearControl = 2;
              totalTons = Number(totalTons) + Number((rowData[i].txtcolTotalTons)?.toString()?.replaceAll(",", ""));
              totalReceiptValue = Number(totalReceiptValue) + Number((rowData[i].txtcolReceiptValue)?.toString()?.replaceAll(",", ""));
              totalPrincipalLoaned = Number(totalPrincipalLoaned) + Number((rowData[i].txtcolPrincipalLoaned)?.toString()?.replaceAll(",", ""));
              totalMarketGain = Number(totalMarketGain) + Number((rowData[i].txtcolMarketGain)?.toString()?.replaceAll(",", ""));
              totalLoanRepayAmount = Number(totalLoanRepayAmount) + Number((rowData[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", ""));
              totalHandlingAmount = Number(totalHandlingAmount) + Number((rowData[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", ""));
              totalInterestAmount = Number(totalInterestAmount) + Number((rowData[i].txtcolInterestAmount)?.toString()?.replaceAll(",", ""));
              totalNetRepayAmount = Number(totalNetRepayAmount) + Number((rowData[i].txtcolNetRepayAmount)?.toString()?.replaceAll(",", ""));
              setValue(thisObj, "txtTotalTons", FormatNumber(totalTons, 2));
              setValue(thisObj, "txtTotalReceiptValue", FormatNumber(totalReceiptValue, 2));
              setValue(thisObj, "txtTotalPrincipalLoaned", FormatNumber(totalPrincipalLoaned, 2));
              setValue(thisObj, "txtTotalMarketGain", FormatNumber(totalMarketGain, 2));
              setValue(thisObj, "txtTotalLoanRepayAmount", FormatNumber(totalLoanRepayAmount, 2));
              setValue(thisObj, "txtTotalHandlingAmount", FormatNumber(totalHandlingAmount, 2));
              setValue(thisObj, "txtTotalInterestAmount", FormatNumber(totalInterestAmount, 2));
              setValue(thisObj, "txtTotalNetRepayAmount", FormatNumber(totalNetRepayAmount, 2));
            }
          }
          _kaledo.thisObj.setState(current => ({
            ...current,
            gridWarehouseReceiptRedeembyLoan: {
              ...current.gridWarehouseReceiptRedeembyLoan,
              toggleRender: !state.gridWarehouseReceiptRedeembyLoan.toggleRender
            }
          }))
        }
        if (clearControl == 1) {
          setValue(thisObj, "txtTotalTons", "0.00");
          setValue(thisObj, "txtTotalReceiptValue", "0.00");
          setValue(thisObj, "txtTotalPrincipalLoaned", "0.00");
          setValue(thisObj, "txtTotalMarketGain", "0.00");
          setValue(thisObj, "txtTotalLoanRepayAmount", "0.00");
          setValue(thisObj, "txtTotalHandlingAmount", "0.00");
          setValue(thisObj, "txtTotalInterestAmount", "0.00");
          setValue(thisObj, "txtTotalNetRepayAmount", "0.00");
        }

      }

      setState(current => {
        return {
          ...current,
          gridWarehouseReceiptRedeembyLoan: {
            ...current.gridWarehouseReceiptRedeembyLoan,
            conditionalRowStyles: gridStyle
          }
        }
      })
    }

  }, [selectedRows])

   function ClearControls() {
    try{
    setValue(thisObj, "txtTotalprincipalLiquidated", "");
    setValue(thisObj, "txtTotalMrktGain", "");
    setValue(thisObj, "txtTotalHandling", "");
    setValue(thisObj, "txtTotalInterestDue", "");
    setValue(thisObj, "txtTotalAmountDue", "");
    setValue(thisObj, "txtWireTransferData2", "XX");
    setValue(thisObj, "txtWireTransferData3", "XXX");
    setValue(thisObj, "txtWireTransferData4", "X");
    setValue(thisObj, "txtWireTransferData6", "XXXX");
    }
    catch(err){
      errorHandler(err)
    }
  }
  const onbtncolReceiptClick = async (event) => {
    try {
      let rowSelected = event.target.parentElement.parentElement.parentElement
      let rowID = rowSelected.id.substring(4, 5);
      let valueGrid = getValue(thisObj, "gridWarehouseReceiptRedeembyLoan")[rowID]

      let js = {
        buyingPtId: valueGrid.txtcolBuyPt, // BuyPt
        unlBuyingPtId: valueGrid.txtcolBuyPt, //BuyPt
        wrNum: valueGrid.txtcolReceipt,// '048872', // Receipt #
        erHolder: valueGrid.txtcolHolder, // H_ID  C000001
        eWRRcptType: valueGrid.txtcolRcptTyp,
        delv_ind: valueGrid.txtcolDelvInd,
      }
      setData(thisObj, 'ViewWarehouseRecieptData', js)
      goTo(thisObj, "WarehouseReceipts#ViewWarehouseReceipt#DEFAULT#true#Click")
    } catch (err) {
        errorHandler(err)
    }
  }
  thisObj.onbtncolReceiptClick = onbtncolReceiptClick

  const onbtnCloseClick = () => {
    try {
      document.getElementById("WarehouseReceipts_WarehouseReceiptRedeemByLoanPopUp").childNodes[0].click();
    }
    catch (err) {
      errorHandler(err)
      return false;
    }
    return true;
  }
  thisObj.onbtnCloseClick = onbtnCloseClick

  function vsfData_AfterEdit(col) {
    try {
      // 22,192.01   dt  --> Number(dt?.toString()?.replace(",", "")).toFixed(2)
      let dblLoanValue = 0;
      let dblMarketGain = 0;
      let dblLoanRepay = 0;
      let dblHandling = 0;
      let dblInterest = 0;
      let dblNetRepay = 0;
      let totalMarketGain = 0;
      let TotalLoanRepay = 0;
      let TotalHandling = 0;
      let TotalInterest = 0;
      let TotalNetRepay = 0;
      if (col == "txtcolMarketGain") {
        let data = getValue(thisObj, 'gridWarehouseReceiptRedeembyLoan')
        if (data !== undefined && data !== null && data !== "") {
          for (let i = 0; i < data.length; i++) {
            if (data[i].isSelected == true) {
              if (!isNaN(Number((data[i].txtcolMarketGain)?.toString()?.replaceAll(",", "")))) {
                dblMarketGain = Number((data[i].txtcolMarketGain)?.toString()?.replaceAll(",", ""))
                totalMarketGain = Number(totalMarketGain) + Number((data[i].txtcolMarketGain)?.toString()?.replaceAll(",", ""))
              }
              if (!isNaN(Number((data[i].txtcolPrincipalLoaned)?.toString()?.replaceAll(",", "")))) {
                dblLoanValue = Number((data[i].txtcolPrincipalLoaned)?.toString()?.replaceAll(",", "")).toFixed(2)

              }
              dblLoanRepay = Number(dblLoanValue) - Number(dblMarketGain)
              if (dblLoanRepay < 0) dblLoanRepay = 0;

              data[i].txtcolMarketGain = FormatNumber(dblMarketGain, 2);
              data[i].txtcolLoanRepayAmount = FormatNumber(dblLoanRepay, 2);

              dblNetRepay = Number((data[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", "")) + Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", "")) + Number((data[i].txtcolInterestAmount)?.toString()?.replaceAll(",", ""));
              data[i].txtcolNetRepayAmount = FormatNumber(dblNetRepay, 2);

              TotalLoanRepay = Number(TotalLoanRepay) + Number((data[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", ""))
              TotalNetRepay = Number(TotalNetRepay) + Number((data[i].txtcolNetRepayAmount)?.toString()?.replaceAll(",", ""));
            }
          }
          setValue(thisObj, "txtTotalMarketGain", FormatNumber(totalMarketGain, 2));
          setValue(thisObj, "txtTotalLoanRepayAmount", FormatNumber(TotalLoanRepay, 2));
          setValue(thisObj, "txtTotalNetRepayAmount", FormatNumber(TotalNetRepay, 2));
          setValue(thisObj, 'gridWarehouseReceiptRedeembyLoan', data)
          _kaledo.thisObj.setState(current => ({
            ...current,
            gridWarehouseReceiptRedeembyLoan: {
              ...current.gridWarehouseReceiptRedeembyLoan,
              toggleRender: !state.gridWarehouseReceiptRedeembyLoan.toggleRender
            }
          }))
        }
      }

      if (col == "txtcolLoanRepayAmount" || col == "txtcolHandlingAmount" || col == "txtcolInterestAmount") {
        let data = getValue(thisObj, 'gridWarehouseReceiptRedeembyLoan')
        if (data !== undefined && data !== null && data !== "") {
          for (let i = 0; i < data.length; i++) {
            if (data[i].isSelected == true) {
              if (!isNaN(Number((data[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", ""))
              )) {
                dblLoanRepay = Number((data[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", ""))
                TotalLoanRepay = Number(TotalLoanRepay) + Number((data[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", ""))
              }
              if (!isNaN(Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", ""))
              )) {
                dblHandling = Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", ""))
                TotalHandling = Number(TotalHandling) + Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", ""))

              }
              if (!isNaN(Number((data[i].txtcolInterestAmount)?.toString()?.replaceAll(",", ""))
              )) {
                dblInterest = Number((data[i].txtcolInterestAmount)?.toString()?.replaceAll(",", ""))
                TotalInterest = Number(TotalInterest) + Number((data[i].txtcolInterestAmount)?.toString()?.replaceAll(",", ""))

              }

              dblNetRepay = Number(dblLoanRepay) + Number(dblHandling) + Number(dblInterest);
              data[i].txtcolLoanRepayAmount = FormatNumber(dblLoanRepay, 2);
              data[i].txtcolHandlingAmount = FormatNumber(dblHandling, 2);
              data[i].txtcolInterestAmount = FormatNumber(dblInterest, 2);
              data[i].txtcolNetRepayAmount = FormatNumber(dblNetRepay, 2);

              TotalNetRepay = Number(TotalNetRepay) + Number((data[i].txtcolNetRepayAmount)?.toString()?.replaceAll(",", ""));
            }
          }
          setValue(thisObj, "txtTotalLoanRepayAmount", FormatNumber(TotalLoanRepay, 2));
          setValue(thisObj, "txtTotalHandlingAmount", FormatNumber(TotalHandling, 2));
          setValue(thisObj, "txtTotalInterestAmount", FormatNumber(TotalInterest, 2));
          setValue(thisObj, "txtTotalNetRepayAmount", FormatNumber(TotalNetRepay, 2));

          setValue(thisObj, 'gridWarehouseReceiptRedeembyLoan', data)
          _kaledo.thisObj.setState(current => ({
            ...current,
            gridWarehouseReceiptRedeembyLoan: {
              ...current.gridWarehouseReceiptRedeembyLoan,
              toggleRender: !state.gridWarehouseReceiptRedeembyLoan.toggleRender
            }
          }))
        }
      }
    }
    catch (err) {
      errorHandler(err)
      return false;
    }
    return true;
  }

  function chkAllRowsToPurchase(selectedRowsArr) {
    try {
      let dblTons = 0;
      let dblRcptValue = 0;
      let dblLoanValue = 0;
      let dblMarketGain = 0;
      let dblLoanRepay = 0;
      let dblHandling = 0;
      let dblInterest = 0;
      let dblNetRepay = 0;
      let dblTotalTons = 0;
      let dblTotalRcptValue = 0;
      let dblTotalLoanValue = 0;
      let dblTotalMarketGain = 0;
      let dblTotalLoanRepay = 0;
      let dblTotalHandling = 0;
      let dblTotalInterest = 0;
      let dblTotalNetRepay = 0;

      let data = getValue(thisObj, "gridWarehouseReceiptRedeembyLoan");
      if (data !== undefined && data !== null && data !== "") {

        for (let i = 0; i < data.length; i++) {
          if (data[i].isSelected == true) {
            //totals 
            if (!isNaN(Number((data[i].txtcolTotalTons)?.toString()?.replaceAll(",", "")))) {
              dblTotalTons = Number(dblTotalTons) + Number((data[i].txtcolTotalTons)?.toString()?.replaceAll(",", ""));
            }
            if (!isNaN(Number((data[i].txtcolReceiptValue)?.toString()?.replaceAll(",", "")))) {
              dblTotalRcptValue = (Number(dblTotalRcptValue) + Number((data[i].txtcolReceiptValue)?.toString()?.replaceAll(",", "")));
            }
            if (!isNaN(Number((data[i].txtcolPrincipalLoaned)?.toString()?.replaceAll(",", "")))) {
              dblTotalLoanValue = (Number(dblTotalLoanValue) + Number((data[i].txtcolPrincipalLoaned)?.toString()?.replaceAll(",", "")));
            }
          }
        }

        for (let i = 0; i < data.length; i++) {
          if (data[i].isSelected == true) {
            // row calculation
            if (!isNaN(Number((data[i].txtcolTotalTons)?.toString()?.replaceAll(",", "")))) {
              dblTons = (Number((data[i].txtcolTotalTons)?.toString()?.replaceAll(",", "")));
            }
            if (!isNaN(Number((data[i].txtcolReceiptValue)?.toString()?.replaceAll(",", "")))) {
              dblRcptValue = Number((data[i].txtcolReceiptValue)?.toString()?.replaceAll(",", ""));
            }
            if (!isNaN(Number((data[i].txtcolPrincipalLoaned)?.toString()?.replaceAll(",", "")))) {
              dblLoanValue = (Number((data[i].txtcolPrincipalLoaned)?.toString()?.replaceAll(",", "")));
            }

            if (!isNaN(Number((thisObj.values.txtTotalMrktGain)?.toString()?.replaceAll(",", ""))) && thisObj.values.txtTotalMrktGain!="") {
              if (thisObj.values.txtTotalMrktGain !== "") {
                dblMarketGain = (Number((thisObj.values.txtTotalMrktGain)?.toString()?.replaceAll(",", "")) * Number(dblTons) / Number(dblTotalTons));
              }
            }
            else {
              if (!isNaN(Number((data[i].txtcolMarketGain)?.toString()?.replaceAll(",", "")))) {
                dblMarketGain = Number((data[i].txtcolMarketGain)?.toString()?.replaceAll(",", ""))
              }
            }

            if (dblMarketGain < 0) {
              dblMarketGain = 0;
            }

            if (data[i].txtcolLoanRepayAmount !== undefined && data[i].txtcolLoanRepayAmount !== null && data[i].txtcolLoanRepayAmount !== "") {
              if (!isNaN(Number((data[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", "")))) {
                dblLoanRepay = Number((data[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", ""));
              }
            }
            else {
              dblLoanRepay = (Number(dblLoanValue) - Number(dblMarketGain));
            }


            if (dblLoanRepay < 0) {
              dblLoanRepay = 0;
            }

            if (!isNaN(Number((thisObj.values.txtTotalHandling)?.toString()?.replaceAll(",", ""))) && thisObj.values.txtTotalHandling != "") {
              if (thisObj.values.txtTotalMrktGain !== "") {
                dblHandling = (Number((thisObj.values.txtTotalHandling)?.toString()?.replaceAll(",", "")) * Number(dblTons) / Number(dblTotalTons));
              }
            }
            else {
              if (!isNaN(Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", "")))) {
                dblHandling = Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", ""));
              }
            }

            if (dblHandling < 0) {
              dblHandling = 0;
            }

            if (!isNaN(Number((thisObj.values.txtTotalInterestDue)?.toString()?.replaceAll(",", ""))) &&  thisObj.values.txtTotalInterestDue!="") {
              if (thisObj.values.txtTotalMrktGain !== "") {
                dblInterest = (Number((thisObj.values.txtTotalInterestDue)?.toString()?.replaceAll(",", "")) * Number(dblLoanValue) / Number(dblTotalLoanValue));
              }
            }
            else {
              if (!isNaN(Number((data[i].txtcolInterestAmount)?.toString()?.replaceAll(",", "")))) {
                dblInterest = Number((data[i].txtcolInterestAmount)?.toString()?.replaceAll(",", ""));
              }
            }

            if (dblInterest < 0) {
              dblInterest = 0;
            }

            dblNetRepay = (Number(dblLoanRepay) + Number(dblHandling) + Number(dblInterest));
            dblTotalMarketGain = Number(dblTotalMarketGain) + Number(dblMarketGain)
            dblTotalLoanRepay = Number(dblTotalLoanRepay) + Number(dblLoanRepay)
            dblTotalHandling = Number(dblTotalHandling) + Number(dblHandling)
            dblTotalInterest = Number(dblTotalInterest) + Number(dblInterest)
            dblTotalNetRepay = Number(dblTotalNetRepay) + Number(dblNetRepay)

            data[i].txtcolLoanRepayAmount = FormatNumber(dblLoanRepay, 2);
            data[i].txtcolMarketGain = FormatNumber(dblMarketGain, 2);
            data[i].txtcolHandlingAmount = FormatNumber(dblHandling, 2);
            data[i].txtcolInterestAmount = FormatNumber(dblInterest, 2);
            data[i].txtcolNetRepayAmount = FormatNumber(dblNetRepay, 2);

          }
          else {
            let response1 = getData(thisObj, 'gridWarehouseReceiptRedeembyLoan')
            let js = [];
            let obj = {};
            if (response1[i]?.market_gain > 0) {
              obj.txtcolMarketGain = FormatNumber(response1[i]?.market_gain, 2);
            }
            else {
              obj.txtcolMarketGain = "";
            }
            if (response1[i]?.loan_repay_amt > 0) {
              obj.txtcolLoanRepayAmount = FormatNumber(response1[i]?.loan_repay_amt, 2);
            }
            else {
              obj.txtcolLoanRepayAmount = "";
            }
            if (response1[i]?.handling_repay_amt > 0) {
              obj.txtcolHandlingAmount = FormatNumber(response1[i]?.handling_repay_amt, 2);
            }
            else {
              obj.txtcolHandlingAmount = "";
            }
            if (response1[i]?.interest_amt > 0) {
              obj.txtcolInterestAmount = FormatNumber(response1[i]?.interest_amt, 2);
            }
            else {
              obj.txtcolInterestAmount = "";
            }
            let netRepayAmount = Number(response1[i]?.loan_repay_amt) + Number(response1[i]?.handling_repay_amt) + Number(response1[i]?.interest_amt)
            if (netRepayAmount > 0) {
              obj.txtcolNetRepayAmount = FormatNumber(netRepayAmount, 2);
            }
            else {
              obj.txtcolNetRepayAmount = "";
            }
            data[i].txtcolLoanRepayAmount = obj.txtcolLoanRepayAmount;
            data[i].txtcolMarketGain = obj.txtcolMarketGain;
            data[i].txtcolHandlingAmount = obj.txtcolHandlingAmount;
            data[i].txtcolInterestAmount = obj.txtcolInterestAmount;
            data[i].txtcolNetRepayAmount = obj.txtcolNetRepayAmount;

          }
        }
      }
      setValue(thisObj, 'gridWarehouseReceiptRedeembyLoan', data);

    }
    catch (err) {
      errorHandler(err)
      return false;
    }
    return true;

  }
  const ontxtcolMarketGainBlur = () => {
    let col = "txtcolMarketGain"
    vsfData_AfterEdit(col);
  }
  thisObj.ontxtcolMarketGainBlur = ontxtcolMarketGainBlur
  const ontxtcolLoanRepayAmountBlur = () => {
    let col = "txtcolLoanRepayAmount"
    vsfData_AfterEdit(col);
  }
  thisObj.ontxtcolLoanRepayAmountBlur = ontxtcolLoanRepayAmountBlur

  const ontxtcolHandlingAmountBlur = () => {
    let col = "txtcolHandlingAmount"
    vsfData_AfterEdit(col);
  }
  thisObj.ontxtcolHandlingAmountBlur = ontxtcolHandlingAmountBlur

  const ontxtcolInterestAmountBlur = () => {
    let col = "txtcolInterestAmount"
    vsfData_AfterEdit(col);
  }
  thisObj.ontxtcolInterestAmountBlur = ontxtcolInterestAmountBlur


  const ontxtTotalHandlingBlur = () => {
    try {
      setLoading(true);
      let dblTons = 0;
      let dblMarketGain = 0;
      let dblLoanRepay = 0;
      let dblHandling = 0;
      let dblInterest = 0;
      let dblNetRepay = 0;
      let dblTotalTons = 0;
      let dblTotalHandling = 0;
      let dblTotalNetRepay = 0;
      let dblTotal = 0;
      if (!isNaN(Number((thisObj.values.txtTotalTons)?.toString()?.replaceAll(",", "")))) {
        dblTotalTons = Number((thisObj.values.txtTotalTons)?.toString()?.replaceAll(",", ""));
      }
      if (!isNaN(Number((thisObj.values.txtTotalHandling)?.toString()?.replaceAll(",", "")))) {
        dblTotalHandling = Number((thisObj.values.txtTotalHandling)?.toString()?.replaceAll(",", ""));
      }
      setValue(thisObj, "txtTotalHandling", FormatNumber(dblTotalHandling, 2));

      if (thisObj.values.txtTotalprincipalLiquidated !== undefined && thisObj.values.txtTotalprincipalLiquidated !== null && thisObj.values.txtTotalprincipalLiquidated !== "") {
        dblTotal = (Number(dblTotal) + Number((thisObj.values.txtTotalprincipalLiquidated)?.toString()?.replaceAll(",", "")));
      }
      if (thisObj.values.txtTotalHandling !== undefined && thisObj.values.txtTotalHandling !== null && thisObj.values.txtTotalHandling !== "") {
        dblTotal = (Number(dblTotal) + Number((thisObj.values.txtTotalHandling)?.toString()?.replaceAll(",", "")));
      }
      if (thisObj.values.txtTotalInterestDue !== undefined && thisObj.values.txtTotalInterestDue !== null && thisObj.values.txtTotalInterestDue !== "") {
        dblTotal = (Number(dblTotal) + Number((thisObj.values.txtTotalInterestDue)?.toString()?.replaceAll(",", "")));
      }
      if (thisObj.values.txtTotalMrktGain !== undefined && thisObj.values.txtTotalMrktGain !== null && thisObj.values.txtTotalMrktGain !== "") {
        dblTotal = (Number(dblTotal) - Number((thisObj.values.txtTotalMrktGain)?.toString()?.replaceAll(",", "")));
      }
      setValue(thisObj, "txtTotalAmountDue", FormatNumber(dblTotal, 2));

      let data = getValue(thisObj, "gridWarehouseReceiptRedeembyLoan");
      if (data !== undefined && data !== null && data !== "") {
        for (let i = 0; i < data.length; i++) {
          if (data[i].isSelected == true) {
            dblTons = 0
            dblMarketGain = 0
            dblLoanRepay = 0
            dblInterest = 0
            dblHandling = 0

            if (!isNaN(Number((data[i].txtcolTotalTons)?.toString()?.replaceAll(",", "")))) {
              dblTons = Number((data[i].txtcolTotalTons)?.toString()?.replaceAll(",", ""));
            }
            if (!isNaN(Number((data[i].txtcolMarketGain)?.toString()?.replaceAll(",", "")))) {
              dblMarketGain = Number((data[i].txtcolMarketGain)?.toString()?.replaceAll(",", ""));
            }
            if (!isNaN(Number((data[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", "")))) {
              dblLoanRepay = Number((data[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", ""));
            }

            if (!isNaN(Number((thisObj.values.txtTotalHandling)?.toString()?.replaceAll(",", "")))) {
              if (dblTotalTons !== 0) {
                dblHandling = (Number(dblTotalHandling) * Number(dblTons) / Number(dblTotalTons));
              }
            }
            else {
              if (!isNaN(Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", "")))) {
                dblHandling = Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", ""));
              }
            }

            if (dblHandling < 0) {
              dblHandling = 0;
            }

            if (!isNaN(Number((data[i].txtcolInterestAmount)?.toString()?.replaceAll(",", "")))) {
              dblInterest = Number((data[i].txtcolInterestAmount)?.toString()?.replaceAll(",", ""));
            }

            data[i].txtcolHandlingAmount = FormatNumber(dblHandling, 2);
            dblNetRepay = Number(dblLoanRepay) + Number(dblHandling) + Number(dblInterest);
            data[i].txtcolNetRepayAmount = FormatNumber(dblNetRepay, 2);
          }
        }

        dblTotalHandling = 0
        dblTotalNetRepay = 0

        for (let j = 0; j < data.length; j++) {
          if (data[j].isSelected == true) {
            if (!isNaN(Number((data[j].txtcolHandlingAmount)?.toString()?.replaceAll(",", "")))) {
              dblTotalHandling = (Number(dblTotalHandling) + Number((data[j].txtcolHandlingAmount)?.toString()?.replaceAll(",", "")));
            }
            if (!isNaN(Number((data[j].txtcolNetRepayAmount)?.toString()?.replaceAll(",", "")))) {
              dblTotalNetRepay = (Number(dblTotalNetRepay) + Number((data[j].txtcolNetRepayAmount)?.toString()?.replaceAll(",", "")));
            }
          }
        }
      }
      setValue(thisObj, "txtTotalHandlingAmount", FormatNumber(dblTotalHandling, 2));
      setValue(thisObj, "txtTotalNetRepayAmount", FormatNumber(dblTotalNetRepay, 2));
      setValue(thisObj, 'gridWarehouseReceiptRedeembyLoan', data);
      _kaledo.thisObj.setState(current => ({
        ...current,
        gridWarehouseReceiptRedeembyLoan: {
          ...current.gridWarehouseReceiptRedeembyLoan,
          toggleRender: !state.gridWarehouseReceiptRedeembyLoan.toggleRender
        }
      }))
      setLoading(false);
    }
    catch (err) {
      errorHandler(err)
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtTotalHandlingBlur = ontxtTotalHandlingBlur

  const ontxtTotalInterestDueBlur = () => {
    try {
      setLoading(true);
      let dblTons = 0;
      let dblMarketGain = 0;
      let dblLoanRepay = 0;
      let dblHandling = 0;
      let dblInterest = 0;
      let dblNetRepay = 0;
      let dblTotalTons = 0;
      let dblTotalInterest = 0;
      let dblTotalNetRepay = 0;
      let dblTotal = 0;
      let dblTotalLoanRepay = 0;
      let dblTotalLoanValue = 0 // Fixed as part of UAT Bug_605957 Interest Amount Not getting Correct as per old.

      if (!isNaN(Number((thisObj.values.txtTotalTons)?.toString()?.replaceAll(",", "")))) {
        dblTotalTons = Number((thisObj.values.txtTotalTons)?.toString()?.replaceAll(",", ""));
      }
      if (!isNaN(Number((thisObj.values.txtTotalInterestDue)?.toString()?.replaceAll(",", "")))) {
        dblTotalInterest = Number((thisObj.values.txtTotalInterestDue)?.toString()?.replaceAll(",", "")).toFixed(2);
      }

      if (!isNaN(Number((thisObj.values.txtTotalPrincipalLoaned)?.toString()?.replaceAll(",", "")))) {// Fixed as part of UAT Bug_605957 Interest Amount Not getting Correct as per old.
        dblTotalLoanValue = Number((thisObj.values.txtTotalPrincipalLoaned)?.toString()?.replaceAll(",", "")).toFixed(2);
      }

      setValue(thisObj, "txtTotalInterestDue", FormatNumber(dblTotalInterest, 2));

      if (thisObj.values.txtTotalprincipalLiquidated !== undefined && thisObj.values.txtTotalprincipalLiquidated !== null && thisObj.values.txtTotalprincipalLiquidated !== "") {
        dblTotal = (Number(dblTotal) + Number((thisObj.values.txtTotalprincipalLiquidated)?.toString()?.replaceAll(",", "")));
      }
      if (thisObj.values.txtTotalHandling !== undefined && thisObj.values.txtTotalHandling !== null && thisObj.values.txtTotalHandling !== "") {
        dblTotal = (Number(dblTotal) + Number((thisObj.values.txtTotalHandling)?.toString()?.replaceAll(",", "")));
      }
      if (thisObj.values.txtTotalInterestDue !== undefined && thisObj.values.txtTotalInterestDue !== null && thisObj.values.txtTotalInterestDue !== "") {
        dblTotal = (Number(dblTotal) + Number((thisObj.values.txtTotalInterestDue)?.toString()?.replaceAll(",", "")));
      }
      if (thisObj.values.txtTotalMrktGain !== undefined && thisObj.values.txtTotalMrktGain !== null && thisObj.values.txtTotalMrktGain !== "") {
        dblTotal = (Number(dblTotal) - Number((thisObj.values.txtTotalMrktGain)?.toString()?.replaceAll(",", "")));
      }
      setValue(thisObj, "txtTotalAmountDue", FormatNumber(dblTotal, 2));

      let data = getValue(thisObj, "gridWarehouseReceiptRedeembyLoan");
      if (data !== undefined && data !== null && data !== "") {
        for (let i = 0; i < data.length; i++) {
          if (data[i].isSelected == true) {
            dblTons = 0
            dblMarketGain = 0
            dblLoanRepay = 0
            dblInterest = 0
            dblHandling = 0

            if (!isNaN(Number((data[i].txtcolTotalTons)?.toString()?.replaceAll(",", "")))) {
              dblTons = Number((data[i].txtcolTotalTons)?.toString()?.replaceAll(",", ""));
            }
            if (!isNaN(Number((data[i].txtcolMarketGain)?.toString()?.replaceAll(",", "")))) {
              dblMarketGain = Number((data[i].txtcolMarketGain)?.toString()?.replaceAll(",", ""));
            }
            if (!isNaN(Number((data[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", "")))) {
              dblLoanRepay = Number((data[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", ""));
            }
            if (!isNaN(Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", "")))) {

              dblHandling = Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", ""));
            }

            if (!isNaN(Number((thisObj.values.txtTotalInterestDue)?.toString()?.replaceAll(",", "")))) {
              if (dblTotalLoanValue !== 0) {// Fixed as part of UAT Bug_605957 Interest Amount Not getting Correct as per old.
                dblInterest = (Number(dblTotalInterest) * Number(dblLoanRepay) / Number(dblTotalLoanValue));// Fixed as part of UAT Bug_605957 Interest Amount Not getting Correct as per old.
              }
            }
            else {
              if (!isNaN(data[i].txtcolInterestAmount)) {
                dblInterest = Number((data[i].txtcolInterestAmount)?.toString()?.replaceAll(",", ""));
              }
            }
            if (dblInterest < 0) {
              dblInterest = 0;
            }

            data[i].txtcolInterestAmount = FormatNumber(dblInterest, 2);
            dblNetRepay = Number(dblLoanRepay) + Number(dblHandling) + Number(dblInterest);
            data[i].txtcolNetRepayAmount = FormatNumber(dblNetRepay, 2);
          }
        }

        dblTotalInterest = 0
        dblTotalNetRepay = 0

        for (let j = 0; j < data.length; j++) {
          if (data[j].isSelected == true) {
            if (!isNaN(Number((data[j].txtcolInterestAmount)?.toString()?.replaceAll(",", "")))) {
              dblTotalInterest = (Number(dblTotalInterest) + Number((data[j].txtcolInterestAmount)?.toString()?.replaceAll(",", "")));
            }
            if (!isNaN(Number((data[j].txtcolNetRepayAmount)?.toString()?.replaceAll(",", "")))) {
              dblTotalNetRepay = (Number(dblTotalNetRepay) + Number((data[j].txtcolNetRepayAmount)?.toString()?.replaceAll(",", "")));
            }
          }
        }
      }
      setValue(thisObj, "txtTotalInterestAmount", FormatNumber(dblTotalInterest, 2));
      setValue(thisObj, "txtTotalNetRepayAmount", FormatNumber(dblTotalNetRepay, 2));
      setValue(thisObj, 'gridWarehouseReceiptRedeembyLoan', data);
      _kaledo.thisObj.setState(current => ({
        ...current,
        gridWarehouseReceiptRedeembyLoan: {
          ...current.gridWarehouseReceiptRedeembyLoan,
          toggleRender: !state.gridWarehouseReceiptRedeembyLoan.toggleRender
        }
      }))
      setLoading(false);
    }
    catch (err) {
      errorHandler(err)
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtTotalInterestDueBlur = ontxtTotalInterestDueBlur

  const ontxtTotalMrktGainBlur = () => {
    try {
      setLoading(true);
      let dblTotalTons = 0
      let dblTotalMarketGain = 0
      let dblTotal = 0
      let dblTons = 0
      let dblLoanValue = 0
      let dblMarketGain = 0
      let dblLoanRepay = 0
      let dblHandling = 0
      let dblInterest = 0
      let dblNetRepay = 0;
      let dblTotalLoanRepay = 0;
      let dblTotalNetRepay = 0;

      if (!isNaN(Number((thisObj.values.txtTotalTons)?.toString()?.replaceAll(",", "")))) {
        dblTotalTons = Number((thisObj.values.txtTotalTons)?.toString()?.replaceAll(",", ""));
      }
      if (!isNaN(Number((thisObj.values.txtTotalMrktGain)?.toString()?.replaceAll(",", "")))) {
        if (thisObj.values.txtTotalMrktGain == '') {
          dblTotalMarketGain = thisObj.values.txtTotalMrktGain;

          setValue(thisObj, "txtTotalMrktGain", thisObj.values.txtTotalMrktGain);
        } else {
          dblTotalMarketGain = Number((thisObj.values.txtTotalMrktGain)?.toString()?.replaceAll(",", ""));
          setValue(thisObj, "txtTotalMrktGain", FormatNumber(dblTotalMarketGain, 2));
        }
      }
      if (dblTotalMarketGain != '') {
        if (thisObj.values.txtTotalprincipalLiquidated !== undefined && thisObj.values.txtTotalprincipalLiquidated !== null && thisObj.values.txtTotalprincipalLiquidated !== "") {
          dblTotal = (Number(dblTotal) + Number((thisObj.values.txtTotalprincipalLiquidated)?.toString()?.replaceAll(",", "")));
        }
        if (thisObj.values.txtTotalHandling !== undefined && thisObj.values.txtTotalHandling !== null && thisObj.values.txtTotalHandling !== "") {
          dblTotal = (Number(dblTotal) + Number((thisObj.values.txtTotalHandling)?.toString()?.replaceAll(",", "")));
        }
        if (thisObj.values.txtTotalInterestDue !== undefined && thisObj.values.txtTotalInterestDue !== null && thisObj.values.txtTotalInterestDue !== "") {
          dblTotal = (Number(dblTotal) + Number((thisObj.values.txtTotalInterestDue)?.toString()?.replaceAll(",", "")));
        }
        if (thisObj.values.txtTotalMrktGain !== undefined && thisObj.values.txtTotalMrktGain !== null && thisObj.values.txtTotalMrktGain !== "") {
          dblTotal = (Number(dblTotal) - Number((thisObj.values.txtTotalMrktGain)?.toString()?.replaceAll(",", "")));
        }
        setValue(thisObj, "txtTotalAmountDue", FormatNumber(dblTotal, 2));
      }


      let data = getValue(thisObj, "gridWarehouseReceiptRedeembyLoan");
      if (data !== undefined && data !== null && data !== "") {
        for (let i = 0; i < data.length; i++) {
          if (data[i].isSelected == true) {
            if (!isNaN(Number((data[i].txtcolTotalTons)?.toString()?.replaceAll(",", "")))) {
              dblTons = Number((data[i].txtcolTotalTons)?.toString()?.replaceAll(",", ""));
            }
            if (!isNaN(Number((data[i].txtcolPrincipalLoaned)?.toString()?.replaceAll(",", "")))) {
              dblLoanValue = Number((data[i].txtcolPrincipalLoaned)?.toString()?.replaceAll(",", ""));
            }

            if (!isNaN(Number((thisObj.values.txtTotalMrktGain)?.toString()?.replaceAll(",", "")))) {
              if (dblTotalTons !== 0) {
                dblMarketGain = (Number(dblTotalMarketGain) * Number(dblTons) / Number(dblTotalTons));
              }
            }
            else {
              if (!isNaN(Number((data[i].txtcolMarketGain)?.toString()?.replaceAll(",", "")))) {
                dblMarketGain = Number((data[i].txtcolMarketGain)?.toString()?.replaceAll(",", ""))
              }
            }

            if (dblMarketGain < 0) {
              dblMarketGain = 0;
            }

            dblLoanRepay = Number(dblLoanValue) - Number(dblMarketGain);

            if (dblLoanRepay < 0) {
              dblLoanRepay = 0;
            }

            if (!isNaN(Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", "")))) {
              dblHandling = Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", ""));
            }
            if (!isNaN(Number((data[i].txtcolInterestAmount)?.toString()?.replaceAll(",", "")))) {
              dblInterest = Number((data[i].txtcolInterestAmount)?.toString()?.replaceAll(",", ""));
            }

            data[i].txtcolMarketGain = FormatNumber(dblMarketGain, 2);
            data[i].txtcolLoanRepayAmount = FormatNumber(dblLoanRepay, 2);

            dblNetRepay = Number(dblLoanRepay) + Number(dblHandling) + Number(dblInterest);
            data[i].txtcolNetRepayAmount = FormatNumber(dblNetRepay, 2);
          }
        }

        dblTotalMarketGain = 0;
        dblTotalLoanRepay = 0;
        dblTotalNetRepay = 0;

        for (let j = 0; j < data.length; j++) {
          if (data[j].isSelected == true) {
            if (!isNaN(Number((data[j].txtcolMarketGain)?.toString()?.replaceAll(",", "")))) {
              dblTotalMarketGain = Number(dblTotalMarketGain) + Number((data[j].txtcolMarketGain)?.toString()?.replaceAll(",", ""));
            }
            if (!isNaN(Number((data[j].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", "")))) {
              dblTotalLoanRepay = Number(dblTotalLoanRepay) + Number((data[j].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", ""));
            }
            if (!isNaN(Number((data[j].txtcolNetRepayAmount)?.toString()?.replaceAll(",", "")))) {
              dblTotalNetRepay = (Number(dblTotalNetRepay) + Number((data[j].txtcolNetRepayAmount)?.toString()?.replaceAll(",", "")));
            }
          }
        }
      }
      setValue(thisObj, "txtTotalMarketGain", FormatNumber(dblTotalMarketGain, 2));
      setValue(thisObj, "txtTotalLoanRepayAmount", FormatNumber(dblTotalLoanRepay, 2));
      setValue(thisObj, "txtTotalNetRepayAmount", FormatNumber(dblTotalNetRepay, 2));
      setValue(thisObj, 'gridWarehouseReceiptRedeembyLoan', data);
      _kaledo.thisObj.setState(current => ({
        ...current,
        gridWarehouseReceiptRedeembyLoan: {
          ...current.gridWarehouseReceiptRedeembyLoan,
          toggleRender: !state.gridWarehouseReceiptRedeembyLoan.toggleRender
        }
      }))
    }
    catch (err) {
      errorHandler(err)
    }
    finally {
      setLoading(false)
    }

  }
  thisObj.ontxtTotalMrktGainBlur = ontxtTotalMrktGainBlur

  const ontxtTotalprincipalLiquidatedBlur = () => {
    try {
      let dblTotal = 0;
      let dbltxtTotalprincipalLiquidated = 0;

      if (!isNaN(Number((thisObj.values.txtTotalprincipalLiquidated)?.toString()?.replaceAll(",", "")))) {
        if (thisObj.values.txtTotalprincipalLiquidated == '') {
          dbltxtTotalprincipalLiquidated = thisObj.values.txtTotalprincipalLiquidated
          setValue(thisObj, "txtTotalprincipalLiquidated", dbltxtTotalprincipalLiquidated);
        } else {
          dbltxtTotalprincipalLiquidated = Number((thisObj.values.txtTotalprincipalLiquidated)?.toString()?.replaceAll(",", ""));
          setValue(thisObj, "txtTotalprincipalLiquidated", FormatNumber(dbltxtTotalprincipalLiquidated, 2));
        }
      }

      if(dbltxtTotalprincipalLiquidated != ''){
              if (thisObj.values.txtTotalprincipalLiquidated !== undefined && thisObj.values.txtTotalprincipalLiquidated !== null && thisObj.values.txtTotalprincipalLiquidated !== "") {
        dblTotal = (Number(dblTotal) + Number((thisObj.values.txtTotalprincipalLiquidated)?.toString()?.replaceAll(",", "")));
      }
      if (thisObj.values.txtTotalHandling !== undefined && thisObj.values.txtTotalHandling !== null && thisObj.values.txtTotalHandling !== "") {
        dblTotal = (Number(dblTotal) + Number((thisObj.values.txtTotalHandling)?.toString()?.replaceAll(",", "")));
      }
      if (thisObj.values.txtTotalInterestDue !== undefined && thisObj.values.txtTotalInterestDue !== null && thisObj.values.txtTotalInterestDue !== "") {
        dblTotal = (Number(dblTotal) + Number((thisObj.values.txtTotalInterestDue)?.toString()?.replaceAll(",", "")));
      }
      if (thisObj.values.txtTotalMrktGain !== undefined && thisObj.values.txtTotalMrktGain !== null && thisObj.values.txtTotalMrktGain !== "") {
        dblTotal = (Number(dblTotal) - Number((thisObj.values.txtTotalMrktGain)?.toString()?.replaceAll(",", "")));
      }
      setValue(thisObj, "txtTotalAmountDue", FormatNumber(dblTotal, 2));

      }


    }
    catch (err) {
      errorHandler(err)
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtTotalprincipalLiquidatedBlur = ontxtTotalprincipalLiquidatedBlur

  //Start Method to slice the date time
  function ConvertToDate(str) {
    try {
      if (str !== undefined && str !== '') {
        let date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
      } else {
        return '';
      }
    }
    catch (err) {
      errorHandler(err)
      return false;
    }
    return true;
  }
  //End Method to slice the date time

  const onbtnPurchaseClick = async () => {
    try {
      setLoading(true)
      if (document.getElementsByClassName('btnPurchase')[0].childNodes[0].childNodes[0].innerText == "Purchase") {
        if (bFormValid() == false) return

        let trasnobj = {};
        let trasnArr = [];
        let js = [];

        let msWireData = ""
        msWireData = thisObj.values.txtRepayVendor == '134969'
          ? (thisObj.values.txtWireTransferData?.trim() + thisObj.values.txtWireTransferData2?.trim() + thisObj.values.txtWireTransferData3?.trim() +
             thisObj.values.txtWireTransferData4?.trim() + thisObj.values.txtWireTransferData5?.trim() + thisObj.values.txtWireTransferData6?.trim())
          : thisObj.values.txtRepayVendor == '745134' 
             ? (thisObj.values.txtConcordia1?.trim() + thisObj.values.txtConcordia2?.trim() + thisObj.values.txtConcordia3?.trim())
             : ''

        let rcpt_count = 0;
        let chrg_end_date = thisObj.values.txtRedemptionEndDate;
        let repay_vendor = thisObj.values.txtRepayVendor;
        let repay_remit = thisObj.values.ddRepayRemit;
        let wire_xfer_ind = "";
        let wire_xfer_data = "";

        mlRowCount = 0;

        let data = getValue(thisObj, "gridWarehouseReceiptRedeembyLoan");
        if (data !== undefined && data !== null && data !== "") {
          for (let i = 0; i < data.length; i++) {
            if (data[i].isSelected == true) {
              mlRowCount = mlRowCount + 1;
              if (!isNaN(Number((data[i].txtcolMarketGain)?.toString()?.replaceAll(",", "")))) {
                trasnobj.dblMarketGain = (Number((data[i].txtcolMarketGain)?.toString()?.replaceAll(",", "")));
              }
              if (!isNaN(Number((data[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", "")))) {
                trasnobj.dblLoanRepay = (Number((data[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", "")));
              }
              if (!isNaN(Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", "")))) {
                trasnobj.dblHandling = (Number((data[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", "")));
              }
              if (!isNaN(Number((data[i].txtcolInterestAmount)?.toString()?.replaceAll(",", "")))) {
                trasnobj.dblInterest = (Number((data[i].txtcolInterestAmount)?.toString()?.replaceAll(",", "")));
              }
              if (!isNaN(Number((data[i].txtcolNetRepayAmount)?.toString()?.replaceAll(",", "")))) {
                trasnobj.dblNetRepay = (Number((data[i].txtcolNetRepayAmount)?.toString()?.replaceAll(",", "")));
              }
              trasnobj.buy_pt_id = data[i].txtcolBuyPt;
              trasnobj.whse_rcpt_num = data[i].txtcolReceipt;
              trasnobj.revision_num = data[i].txtcolRevision;
              trasnobj.total_tons = Number((data[i].txtcolTotalTons)?.toString()?.replaceAll(",", ""));
              trasnArr.push(trasnobj);
              trasnobj = {};
            }
          }
        }
        rcpt_count = mlRowCount;
        if (document.getElementById("chkboxWireTransfer").checked == true) {
          wire_xfer_ind = "Y";
          wire_xfer_data = msWireData;
        }
        else {
          wire_xfer_ind = "N";
          wire_xfer_data = "";
        }

        for (let i = 0; i < trasnArr.length; i++) {
          js.push({
            "buy_pt_id": trasnArr[i].buy_pt_id,
            "whse_rcpt_num": trasnArr[i].whse_rcpt_num,
            "revision_num": trasnArr[i].revision_num,
            "total_tons": trasnArr[i].total_tons,
            "market_gain": trasnArr[i].dblMarketGain,
            "loan_repay_amt": trasnArr[i].dblLoanRepay,
            "handling_amt": trasnArr[i].dblHandling,
            "interest_amt": trasnArr[i].dblInterest,
            "net_repay": trasnArr[i].dblNetRepay
          })
        }
        let obj = {
          rcpt_count: rcpt_count,
          userid: userid,
          chrg_end_date: ConvertToDate(chrg_end_date),
          repay_vendor: repay_vendor,
          repay_remit: repay_remit,
          wire_xfer_ind: wire_xfer_ind,
          wire_xfer_data: wire_xfer_data,
          receipts_list: js
        }
        let response = await WarehouseReceiptService.CreateWareHouseReceiptRedeemByLoan(obj)//Fixed as part of UAT Bug_610809 wait loader added for purchase and unpurchase condition.
          if (response.status == 200) {
            alert("The selected Warehouse Receipts have been redeemed.");
            await onbtnSearchClick();//Fixed as part of UAT Bug_610809 wait loader added for purchase and unpurchase condition.
            ClearControls();
          }
      }
      else {
        mlRowCount = 0;
        let trasnobj = {};
        let trasnArr = [];
        let js = [];
        let data = getValue(thisObj, "gridWarehouseReceiptRedeembyLoan");
        if (data !== undefined && data !== null && data !== "") {
          for (let i = 0; i < data.length; i++) {
            if (data[i].isSelected == true) {
              mlRowCount = mlRowCount + 1;
              trasnobj.buy_pt_id = data[i].txtcolBuyPt;
              trasnobj.whse_rcpt_num = data[i].txtcolReceipt;
              trasnobj.revision_num = data[i].txtcolRevision;
              trasnArr.push(trasnobj);
              trasnobj = {};
            }
          }
        }
        if(mlRowCount == 0) {
          alert("Must select some Warehouse Receipts to be unpurchased.");
          return
        }
        for (let i = 0; i < trasnArr.length; i++) {
          js.push({
            "buy_pt_id": trasnArr[i].buy_pt_id,
            "whse_rcpt_num": trasnArr[i].whse_rcpt_num,
            "revision_num": trasnArr[i].revision_num,
          })
        }
        let dataObj = {
          rcpt_count: mlRowCount,
          userid: userid,
          wr_Redeem_By_Loan_list: js
        }

        let message = confirm("Are you sure you want to unpurchase selected receipts for this loan?  If you click OK, it will be automatically Unpurchased.");
        if(!message){
          return
        }
        let response = await WarehouseReceiptService.UpdateWareHouseReceiptRedeemByLoanReverse(dataObj) //Fixed as part of UAT Bug_610809 wait loader added for purchase and unpurchase condition.
          if (response.status == 200) {
            alert("The redemptions for the selected Warehouse Receipts have been reversed.");
          }
          await onbtnSearchClick();    
      }
    }
    catch (err) {
      errorHandler(err)
      return false;
    }
    finally{
      setLoading(false)
    }
    return true;
  }
  thisObj.onbtnPurchaseClick = onbtnPurchaseClick

  const onbtnUpdateClick = async () => {
    try{
    setLoading(true)
    let ccc_500_ind = "";
    let trasnobj = {};
    let trasnArr = [];
    let js = [];
    mlRowCount = 0;
    let data = getValue(thisObj, "gridWarehouseReceiptRedeembyLoan");

    if (document.getElementById("chkboxCCC500").checked == true) {
      ccc_500_ind = "Y";
    }
    else {
      ccc_500_ind = "N";
    }

    if (data !== undefined && data !== null && data !== "") {
      for (let i = 0; i < data.length; i++) {
        if (data[i].isSelected == true) {
          if (document.getElementById("chkboxCCC500").checked == true) {
            if (data[i].txtcolCCC500 !== "Y") {
              trasnobj.buyptId = data[i].txtcolBuyPt;
              trasnobj.whseRrcptNum = data[i].txtcolReceipt;
              trasnArr.push(trasnobj);
              trasnobj = {};
              mlRowCount = mlRowCount + 1;

            }
          }
          else {
            if (data[i].txtcolCCC500 == "Y") {
              trasnobj.buyptId = data[i].txtcolBuyPt;
              trasnobj.whseRrcptNum = data[i].txtcolReceipt;
              trasnArr.push(trasnobj);
              trasnobj = {};
              mlRowCount = mlRowCount + 1;

            }
          }
        }
      }
    }
    if (mlRowCount <= 0) {
      alert("Must select some Warehouse Receipts to be updated.");
      return false;
    }

    for (let i = 0; i < trasnArr.length; i++) {
      js.push({
        "buy_pt_id": trasnArr[i].buyptId,
        "whse_rcpt_num": trasnArr[i].whseRrcptNum
      })
    }
    let dataObj = {
      rcpt_count: mlRowCount,
      userid: userid,
      ccc_500_ind: ccc_500_ind,
      wr_Redeem_By_Loan_list: js
    }
    await WarehouseReceiptService.UpdateWareHouseReceiptRedeemByLoan(dataObj).then(response => {
      if (response.status == 200) {
        onbtnSearchClick();
        alert("The selected Warehouse Receipts have been updated.");
      }
    })
    }
    catch(err){
      errorHandler(err)
    }
    finally{
      setLoading(false)
    }

  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick

  const onbtnSearchClick = async () => {
    try {
      setLoading(true);//Fixed as part of UAT Bug_610809 wait loader added for purchase and unpurchase condition.
   //05Jul'2024 - Commented - PRB0053834
      //  alert(`Searched data may be too large for the grid to load. Please wait for a while till the data gets completely loaded in the grid.`)
      //end of changes
      setSelectedRows([]);
      setState(current => {
        return {
          ...current,
          gridWarehouseReceiptRedeembyLoan: {
            ...current.gridWarehouseReceiptRedeembyLoan,
            conditionalRowStyles: [
              {
                when: (row) => { return true },
                style: {
                  '.textboxWidgetClass': {
                    pointerEvents: "none",
                    border: 'none',
                    boxShadow: 'none',
                    background: 'transparent',
                  }
                }
              }
            ]
          }
        }
      })
      let mode = "";

      if (document.getElementById('radioScreenmode0').checked == true) {
        mode = "Purchase";
      }
      else {
        mode = "After Purchase";
      }

      if (mode == "Purchase") {
        await DoSearch();//Fixed as part of UAT Bug_610809 wait loader added for purchase and unpurchase condition.
        document.getElementsByClassName('btnPurchase')[0].childNodes[0].childNodes[0].innerText = "Purchase";
      }
      else {
        await DoSearchPurchased();//Fixed as part of UAT Bug_610809 wait loader added for purchase and unpurchase condition.
        document.getElementsByClassName('btnPurchase')[0].childNodes[0].childNodes[0].innerText = "Unpurchase";
        show(thisObj, "chkboxCCC500");
        show(thisObj, "btnUpdate");
      }
      setValue(thisObj, 'chkboxAll', false)
      
      if (loading) {//Fixed as part of UAT Bug_610809 wait loader added for purchase and unpurchase condition.
          setLoading(false)
      }
    }
    catch (err) {
      errorHandler(err)
    }
    finally {
      // setLoading(false)
    }

  }
  thisObj.onbtnSearchClick = onbtnSearchClick

  const DoSearch = async () => {
    try {
      setLoading(true);
      let loanNum1 = thisObj.values.txtLoanNum1;
      let loanNum2 = thisObj.values.txtLoanNum2;
      let LoanNum3 = thisObj.values.txtLoanNum3;

      if (loanNum1 !== undefined && loanNum1 !== null && loanNum1 !== "") {
        if (loanNum1.length !== 2) {
          alert("The Length of the Loan State field must be 2 digits.");
          document.getElementById('txtLoanNum1').focus();
          return;
        }
        else {
          loanNum1 = thisObj.values.txtLoanNum1;
        }

      } else {
        alert("The Length of the Loan State field must be 2 digits.");
        document.getElementById('txtLoanNum1').focus();
        return;
      }

      if (loanNum2 !== undefined && loanNum2 !== null && loanNum2 !== "") {
        if (loanNum2.length !== 3) {
          alert("The Length of the Loan County field must be 3 digits.");
          document.getElementById('txtLoanNum2').focus();
          return;
        }
        else {
          loanNum2 = thisObj.values.txtLoanNum2;
        }
      } else {
        alert("The Length of the Loan County field must be 3 digits.");
        document.getElementById('txtLoanNum2').focus();
        return;
      }

      if (LoanNum3 !== undefined && LoanNum3 !== null && LoanNum3 !== "") {
        if (LoanNum3.length !== 5) {
          alert("The Length of the Loan Number field must be 5 digits.");
          document.getElementById('txtLoanNum3').focus();
          return;
        }
        else {
          LoanNum3 = thisObj.values.txtLoanNum3;
        }
      } else {
        alert("The Length of the Loan Number field must be 5 digits.");
        document.getElementById('txtLoanNum3').focus();
        return;
      }
      rcpt_status = "I";
      stat_type = "Y";
      loan_number = loanNum1 + loanNum2 + LoanNum3

      let data =  await WarehouseReceiptService.RetrieveWareHouseReceiptList(rcpt_status, stat_type, loan_number, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)//Fixed as part of UAT Bug_610809 wait loader added for purchase and unpurchase condition.
        let js = [];
        let obj = {};
        for (var i = 0; i < data.length; i++) {
          obj.txtcolRevision = data[i].revision_num;
          obj.txtcolBuyPt = data[i].buy_pt_id;
          obj.txtcolReceipt = data[i].e_whse_rcpt_num;
          obj.txtcolTotalTons = data[i].total_tons;
          obj.txtcolReceiptValue = FormatNumber(data[i].rcpt_value, 2);
          obj.txtcolPrincipalLoaned = FormatNumber(data[i].rcpt_value, 2);
          obj.txtcolHolder = data[i].current_holder;
          obj.txtcolRcptTyp = data[i].ewr_receipt_type;
          obj.txtcolDelvInd = data[i].delv_ind;
          if (data[i].market_gain > 0) {
            obj.txtcolMarketGain = FormatNumber(data[i].market_gain, 2);
          }
          else {
            obj.txtcolMarketGain = "";
          }
          if (data[i].loan_repay_amt > 0) {
            obj.txtcolLoanRepayAmount = FormatNumber(data[i].loan_repay_amt, 2);
          }
          else {
            obj.txtcolLoanRepayAmount = "";
          }
          if (data[i].handling_repay_amt > 0) {
            obj.txtcolHandlingAmount = FormatNumber(data[i].handling_repay_amt, 2);
          }
          else {
            obj.txtcolHandlingAmount = "";
          }
          if (data[i].interest_amt > 0) {
            obj.txtcolInterestAmount = FormatNumber(data[i].interest_amt, 2);
          }
          else {
            obj.txtcolInterestAmount = "";
          }
          let netRepayAmount = Number(data[i].loan_repay_amt) + Number(data[i].handling_repay_amt) + Number(data[i].interest_amt)
          if (netRepayAmount > 0) {
            obj.txtcolNetRepayAmount = FormatNumber(netRepayAmount, 2);
          }
          else {
            obj.txtcolNetRepayAmount = "";
          }
          obj.txtcolCCC500 = data[i].ccc_500_ind;
          js.push(obj);
          obj = {};
        }
        setValue(thisObj, 'gridWarehouseReceiptRedeembyLoan', js);
        setData(thisObj, 'gridWarehouseReceiptRedeembyLoan', data)
        if (js.length > 0) {
          hide(thisObj, "chkboxCCC500", false);
          hide(thisObj, "btnUpdate", false);
          enable(thisObj, "btnPurchase");
        }
        setValue(thisObj, "txtTotalTons", "0.00");
        setValue(thisObj, "txtTotalReceiptValue", "0.00");
        setValue(thisObj, "txtTotalPrincipalLoaned", "0.00");
        setValue(thisObj, "txtTotalMarketGain", "0.00");
        setValue(thisObj, "txtTotalLoanRepayAmount", "0.00");
        setValue(thisObj, "txtTotalHandlingAmount", "0.00");
        setValue(thisObj, "txtTotalInterestAmount", "0.00");
        setValue(thisObj, "txtTotalNetRepayAmount", "0.00");
        ClearControls();
        if (loading) {//Fixed as part of UAT Bug_610809 wait loader added for purchase and unpurchase condition.
          setLoading(false)
        }
    }
    catch (err) {
      errorHandler(err)
    }
    finally {
      setLoading(false)
    }
  }

  const DoSearchPurchased = async () => {
    try {
      setLoading(true);
      let loanNum1 = thisObj.values.txtLoanNum1;
      let loanNum2 = thisObj.values.txtLoanNum2;
      let LoanNum3 = thisObj.values.txtLoanNum3;

      if (loanNum1 !== undefined && loanNum1 !== null && loanNum1 !== "") {
        if (loanNum1.length !== 2) {
          alert("The Length of the Loan State field must be 2 digits.");
          document.getElementById('txtLoanNum1').focus();
          return;
        }
        else {
          loanNum1 = thisObj.values.txtLoanNum1;
        }

      } else {
        alert("The Length of the Loan State field must be 2 digits.");
        document.getElementById('txtLoanNum1').focus();
        return;
      }

      if (loanNum2 !== undefined && loanNum2 !== null && loanNum2 !== "") {
        if (loanNum2.length !== 3) {
          alert("The Length of the Loan County field must be 3 digits.");
          document.getElementById('txtLoanNum2').focus();
          return;
        }
        else {
          loanNum2 = thisObj.values.txtLoanNum2;
        }
      } else {
        alert("The Length of the Loan County field must be 3 digits.");
        document.getElementById('txtLoanNum2').focus();
        return;
      }

      if (LoanNum3 !== undefined && LoanNum3 !== null && LoanNum3 !== "") {
        if (LoanNum3.length !== 5) {
          alert("The Length of the Loan Number field must be 5 digits.");
          document.getElementById('txtLoanNum3').focus();
          return;
        }
        else {
          LoanNum3 = thisObj.values.txtLoanNum3;
        }
      } else {
        alert("The Length of the Loan Number field must be 5 digits.");
        document.getElementById('txtLoanNum3').focus();
        return;
      }

      let rcpt_status = "P";
      let stat_type = "Y";
      loan_number = loanNum1 + loanNum2 + LoanNum3  //1308710045
      let js = [];
       await WarehouseReceiptService.RetrieveWareHouseReceiptList(rcpt_status, stat_type, loan_number, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null).then(response => {
       let data = response;
        setValue[response];

        let obj = {};
        for (var i = 0; i < data.length; i++) {
          obj.txtcolRevision = data[i].revision_num;
          obj.txtcolBuyPt = data[i].buy_pt_id;
          obj.txtcolReceipt = data[i].e_whse_rcpt_num;
          obj.txtcolTotalTons = FormatNumber(data[i].total_tons, 2);
          obj.txtcolReceiptValue = FormatNumber(data[i].rcpt_value, 2);
          obj.txtcolPrincipalLoaned = FormatNumber(data[i].rcpt_value, 2);
          obj.txtcolHolder = data[i].current_holder;
          obj.txtcolRcptTyp = data[i].ewr_receipt_type;
          obj.txtcolDelvInd = data[i].delv_ind;
          if (data[i].market_gain > 0) {
            obj.txtcolMarketGain = FormatNumber(data[i].market_gain, 2);
          }
          else {
            obj.txtcolMarketGain = "";
          }
          if (data[i].loan_repay_amt > 0) {
            obj.txtcolLoanRepayAmount = FormatNumber(data[i].loan_repay_amt, 2);
          }
          else {
            obj.txtcolLoanRepayAmount = "";
          }

          if (data[i].handling_repay_amt > 0) {
            obj.txtcolHandlingAmount = FormatNumber(data[i].handling_repay_amt, 2);
          }
          else {
            obj.txtcolHandlingAmount = "";
          }
          if (data[i].interest_amt > 0) {
            obj.txtcolInterestAmount = FormatNumber(data[i].interest_amt, 2);
          }
          else {
            obj.txtcolInterestAmount = "";
          }
          let netRepayAmount = Number(data[i].loan_repay_amt) + Number(data[i].handling_repay_amt) + Number(data[i].interest_amt)
          if (netRepayAmount > 0) {
            obj.txtcolNetRepayAmount = FormatNumber(netRepayAmount, 2);
          }
          else {
            obj.txtcolNetRepayAmount = "";
          }
          obj.txtcolCCC500 = data[i].ccc_500_ind;
          js.push(obj);
          obj = {};
        }
        setValue(thisObj, 'gridWarehouseReceiptRedeembyLoan', js);

        if (js.length > 0) {
          enable(thisObj, "btnPurchase");
        }
      })

      // _kaledo.thisObj.setState(current => ({
      //   ...current,
      //   gridWarehouseReceiptRedeembyLoan: {
      //     ...current.gridWarehouseReceiptRedeembyLoan,
      //     toggleRender: !state.gridWarehouseReceiptRedeembyLoan.toggleRender
      //   }
      // }))
      //totals row calculations after purchase 
      let totalTons = 0;
      let totalReceiptValue = 0;
      let totalPrincipalLoaned = 0;
      let totalMarketGain = 0;
      let totalLoanRepayAmount = 0;
      let totalHandlingAmount = 0;
      let totalInterestAmount = 0;
      let totalNetRepayAmount = 0;
      let rowData = js;// getValue(thisObj, 'gridWarehouseReceiptRedeembyLoan');
      if (rowData !== undefined && rowData !== null && rowData !== "" && rowData.length > 0) {
        for (let i = 0; i < rowData.length; i++) {
          totalTons = Number(totalTons) + Number(rowData[i].txtcolTotalTons?.toString()?.replaceAll(",", ""));
          totalReceiptValue = Number(totalReceiptValue) + Number(rowData[i].txtcolReceiptValue?.toString()?.replaceAll(",", ""));
          totalPrincipalLoaned = Number(totalPrincipalLoaned) + Number(rowData[i].txtcolPrincipalLoaned?.toString()?.replaceAll(",", ""));
          totalMarketGain = Number(totalMarketGain) + Number(rowData[i].txtcolMarketGain?.toString()?.replaceAll(",", ""));
          totalLoanRepayAmount = Number(totalLoanRepayAmount) + Number(rowData[i].txtcolLoanRepayAmount?.toString()?.replaceAll(",", ""));
          totalHandlingAmount = Number(totalHandlingAmount) + Number(rowData[i].txtcolHandlingAmount?.toString()?.replaceAll(",", ""));
          totalInterestAmount = Number(totalInterestAmount) + Number(rowData[i].txtcolInterestAmount?.toString()?.replaceAll(",", ""));
          totalNetRepayAmount = Number(totalNetRepayAmount) + Number(rowData[i].txtcolNetRepayAmount?.toString()?.replaceAll(",", ""));

          setValue(thisObj, "txtTotalTons", FormatNumber(totalTons, 2));
          setValue(thisObj, "txtTotalReceiptValue", FormatNumber(totalReceiptValue, 2));
          setValue(thisObj, "txtTotalPrincipalLoaned", FormatNumber(totalPrincipalLoaned, 2));
          setValue(thisObj, "txtTotalMarketGain", FormatNumber(totalMarketGain, 2));
          setValue(thisObj, "txtTotalLoanRepayAmount", FormatNumber(totalLoanRepayAmount, 2));
          setValue(thisObj, "txtTotalHandlingAmount", FormatNumber(totalHandlingAmount, 2));
          setValue(thisObj, "txtTotalInterestAmount", FormatNumber(totalInterestAmount, 2));
          setValue(thisObj, "txtTotalNetRepayAmount", FormatNumber(totalNetRepayAmount, 2));
        }
      }
      else {
        setValue(thisObj, "txtTotalTons", "0.00");
        setValue(thisObj, "txtTotalReceiptValue", "0.00");
        setValue(thisObj, "txtTotalPrincipalLoaned", "0.00");
        setValue(thisObj, "txtTotalMarketGain", "0.00");
        setValue(thisObj, "txtTotalLoanRepayAmount", "0.00");
        setValue(thisObj, "txtTotalHandlingAmount", "0.00");
        setValue(thisObj, "txtTotalInterestAmount", "0.00");
        setValue(thisObj, "txtTotalNetRepayAmount", "0.00");
      }
      // setLoading(false);
      ClearControls();

    }
    catch (err) {
      errorHandler(err)
    }
    finally {
      setLoading(false);

    }
  }

  function bFormValid() {
    try{
    let valid = false;
    let sCMD = "";
    let sXML = "";
    let lRow = 0;
    let lDays = 0;
    let mlRowCount = 0;
    let isSelected = thisObj.state.gridWarehouseReceiptRedeembyLoan.selected;
    if (isSelected !== undefined && isSelected !== null && isSelected !== "" && isSelected?.length !== 0) {
      for (let i = 0; i < isSelected.length; i++) {
        mlRowCount = mlRowCount + 1;
        if (isSelected[i].txtcolMarketGain !== undefined && isSelected[i].txtcolMarketGain !== null && isSelected[i].txtcolMarketGain !== "") {
          if (isNaN(Number((isSelected[i].txtcolMarketGain)?.toString()?.replaceAll(",", "")))) {
            alert("The Market Gain Amount is not valid on RCPT#");
            return valid
          }
        }

        if (isSelected[i].txtcolLoanRepayAmount !== undefined && isSelected[i].txtcolLoanRepayAmount !== null && isSelected[i].txtcolLoanRepayAmount !== "") {
          if (isNaN(Number((isSelected[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", "")))) {
            alert("The Loan Repay Amount is not valid on RCPT#");
            return valid
          }
          if ((Number((isSelected[i].txtcolLoanRepayAmount)?.toString()?.replaceAll(",", ""))) == 0) {
            alert("The Loan Repay Amount must be greater than zero on RCPT#");
            return valid
          }
        }
        else {
          alert("The Loan Repay Amount must be greater than zero on RCPT#");
          return valid
        }

        if (isSelected[i].txtcolHandlingAmount !== undefined && isSelected[i].txtcolHandlingAmount !== null && isSelected[i].txtcolHandlingAmount !== "") {
          if (isNaN(Number((isSelected[i].txtcolHandlingAmount)?.toString()?.replaceAll(",", "")))) {
            alert("The Handling Amount is not valid on RCPT#");
            return valid
          }
        }

        if (isSelected[i].txtcolInterestAmount !== undefined && isSelected[i].txtcolInterestAmount !== null && isSelected[i].txtcolInterestAmount !== "") {
          if (isNaN(Number((isSelected[i].txtcolInterestAmount)?.toString()?.replaceAll(",", "")))) {
            alert("The Interest Amount is not valid on RCPT#");
            return valid
          }
        }

        if ((thisObj.values.txtRedemptionEndDate) == undefined || (thisObj.values.txtRedemptionEndDate) == null || (thisObj.values.txtRedemptionEndDate) == "") {
          alert("The Redemption End Date is required.");
          return valid
        }

        if (new Date(thisObj.values.txtRedemptionEndDate) == undefined || new Date(thisObj.values.txtRedemptionEndDate) == null || new Date(thisObj.values.txtRedemptionEndDate) == "") {
          alert("The Redemption End Date is not a valid date.");
          return valid
        }
        let d1 = moment(thisObj.values.txtRedemptionEndDate._d).format('MM/DD/YYYY')
        let d2 = moment(new Date()).format('MM/DD/YYYY')
        if(new Date(d1).getTime() < new Date(d2).getTime()){
          alert("The Redemption End Date cannot be in the past.");
          return valid
        }

        ContractManagementService.RetrievePeanutStaticValues().then(response => {
          for (let i = 0; i < response.length; i++) {
            if (Number(response[i].wr_max_end_days)) {
              lDays = response[i].wr_max_end_days
            }
            else {
              lDays = 5
            }
          }
        });

        if ((thisObj.values.txtRedemptionEndDate) > new Date() + lDays) {
          alert("The Redemption End Date cannot be more than " + lDays + " days in future.");
          return valid
        }

        if ((thisObj.values.txtTotalMrktGain) == undefined && (thisObj.values.txtTotalMrktGain) == null && (thisObj.values.txtTotalMrktGain) == "") {
          if (Number((thisObj.values.txtTotalMarketGain)?.toString()?.replaceAll(",", "")) > 0) {
            alert("The Total Market Gain is not equal to the total from the Grid.");
            return valid

          }
        }
        else {
          if (isNaN(Number((thisObj.values.txtTotalMrktGain)?.toString()?.replaceAll(",", "")))) {
            alert("The Total Market Gain is not a valid amount.");
            return valid
          }
          if (Number((thisObj.values.txtTotalMrktGain)?.toString()?.replaceAll(",", "")) !== Number((thisObj.values.txtTotalMarketGain)?.toString()?.replaceAll(",", ""))) {
            alert("The Total Market Gain is not equal to the total from the Grid.");
            return valid
          }
        }

        if ((thisObj.values.txtTotalprincipalLiquidated) == undefined && (thisObj.values.txtTotalprincipalLiquidated) == null && (thisObj.values.txtTotalprincipalLiquidated) == "") {
          if (Number((thisObj.values.txtTotalPrincipalLoaned)?.toString()?.replaceAll(",", "")) > 0) {
            alert("The Total Principal is not equal to the total from the Grid.");
            return valid

          }
        }
        else {
          if (isNaN(Number((thisObj.values.txtTotalprincipalLiquidated)?.toString()?.replaceAll(",", "")))) {
            alert("The Total Principal is not a valid amount.");
            return valid
          }
          if (Number((thisObj.values.txtTotalprincipalLiquidated)?.toString()?.replaceAll(",", "")) !== Number((thisObj.values.txtTotalPrincipalLoaned)?.toString()?.replaceAll(",", ""))) {
            alert("The Total Principal is not equal to the total from the Grid.");
            return valid
          }
        }

        if ((thisObj.values.txtTotalHandling) == undefined && (thisObj.values.txtTotalHandling) == null && (thisObj.values.txtTotalHandling) == "") {
          if (Number((thisObj.values.txtTotalHandlingAmount)?.toString()?.replaceAll(",", "")) > 0) {
            alert("The Total Handling Charge is not equal to the total from the Grid.");
            return valid
          }
        }
        else {
          if (isNaN(Number((thisObj.values.txtTotalHandling)?.toString()?.replaceAll(",", "")))) {
            alert("The Total Handling Charge is not a valid amount.");
            return valid
          }
          if (Number((thisObj.values.txtTotalHandling)?.toString()?.replaceAll(",", "")) !== Number((thisObj.values.txtTotalHandlingAmount)?.toString()?.replaceAll(",", ""))) {
            alert("The Total Handling Charge is not equal to the total from the Grid.");
            return valid
          }
        }

        if ((thisObj.values.txtTotalInterestDue) == undefined && (thisObj.values.txtTotalInterestDue) == null && (thisObj.values.txtTotalInterestDue) == "") {
          if (Number((thisObj.values.txtTotalInterestAmount)?.toString()?.replaceAll(",", "")) > 0) {
            alert("The Total Interest Due is not equal to the total from the Grid.");
            return valid
          }
        }
        else {
          if (isNaN(Number((thisObj.values.txtTotalInterestDue)?.toString()?.replaceAll(",", "")))) {
            alert("The Total Interest Due is not a valid amount.");
            return valid
          }
          if (Number((thisObj.values.txtTotalInterestDue)?.toString()?.replaceAll(",", "")) !== Number((thisObj.values.txtTotalInterestAmount)?.toString()?.replaceAll(",", ""))) {
            alert("The Total Interest Due is not equal to the total from the Grid.");
            return valid
          }
        }

        if ((thisObj.values.txtRepayVendor) == undefined || (thisObj.values.txtRepayVendor) == null || (thisObj.values.txtRepayVendor) == "") {
          alert("The Repay Vendor is not a valid.");
          return valid
        }

        let wireCheck = getValue(thisObj, 'chkboxWireTransfer')
        if (wireCheck == true) {
          if (isVisible(thisObj, 'txtWireTransferData')) {
            let transferData2 = getValue(thisObj, 'txtWireTransferData2')
            let transferData3 = getValue(thisObj, 'txtWireTransferData3')
            let transferData4 = getValue(thisObj, 'txtWireTransferData4')
            let transferData6 = getValue(thisObj, 'txtWireTransferData6')

            if ((transferData2 == 'XX') || (transferData3 == 'XXX') || (transferData4 == 'X') || (transferData6 == 'XXXX')) {
              alert("The Wire Transfer Data is not a valid")
              return valid
            }
          }

          if (isVisible(thisObj, 'txtConcordia1')) {
            let concordia1 = getValue(thisObj, 'txtConcordia1')
            let concordia3 = getValue(thisObj, 'txtConcordia1')

            if ((concordia1 == 'XXXXX') || (concordia3 == 'XXXXX')) {
              alert(thisObj, 'The Wire Transfer Data is not a valid.')
              return valid
            }
          }

          // if(isVisible(thisObj,'txtWireXferData')){
          //   let xferData=getValue(thisObj,'txtWireXferData')
          //   if(xferData==''){
          //     alert("The Wire Transfer Data is not a valid.")
          //     return valid
          //   }
          // }
        }

      }
      valid = true;
      return valid
    }
    else {

      alert("Must select some Warehouse Receipts to be redeemed.");
      return valid
    }
  }
  catch(err){
    errorHandler(err)
  }
  }

  // Start method for update Inventory radio button change event
  const onradioScreenmodeChange = () => {
    onbtnSearchClick();
    setValue(thisObj, 'chkboxAll', false)
  }
  thisObj.onradioScreenmodeChange = onradioScreenmodeChange
  // End method for update Inventory radio button change event

  //Start method for vendor textbox change event to bind the vendor name
  const ontxtRepayVendorBlur = async () => {
    try {
      let VendorNumber = thisObj.values.txtRepayVendor;
      if (VendorNumber !== undefined && VendorNumber !== null && VendorNumber !== "") {
        if (VendorNumber.length == 6) {
          setVendorNameBasedOnVendorNumber(VendorNumber)

          VendorDetails(VendorNumber)
        }
       
      } else {
          hide(thisObj, "txtRepayVndr", false);
          setData(thisObj, "ddRemitTo", "");
        }
    } catch (err) {
      errorHandler(err)
      return false;
    }
    return true;
  };
  thisObj.ontxtRepayVendorBlur = ontxtRepayVendorBlur;
  //End method for vendor textbox change event to bind the vendor name

  // on btnRepayVendor click
  const onbtnRepayVendorClick = async () => {
    try {
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click")
    }
    catch {
      errorHandler(err)
    }
  }
  thisObj.onbtnRepayVendorClick = onbtnRepayVendorClick

  // Start Method to get Vendor details
  const VendorDetails = (VendorNumber) => {
    try{
    if (VendorNumber !== null) {
      ContractManagementService.RetieveRemitDetails(VendorNumber).then(response => {
        let data = response;
        if (data.length > 0) {
          setValue(thisObj, "chkboxWireTransfer", document.getElementById("chkboxWireTransfer").checked = true);
          onchkboxWireTransferChange(VendorNumber);
          let js = [];
          // js.push({ key: '', description: '' });
          for (var i = 0; i < data.length; i++) {
            let description = data[i].remittoid + '-' + data[i].name + '-' + data[i].city + ',' + data[i].state;
            let obj = { key: data[i].remittoid, description: description };
            js.push(obj);
          }
          thisObj.setState(current => {
            return {
              ...current,
              ddRepayRemit: {
                ...state["ddRepayRemit"],
                valueList: js
              },
            }
          });
          setValue(thisObj, 'ddRepayRemit', js.at(0).key);
          setVendorNameBasedOnVendorNumber(VendorNumber)

        }
        else {
          setValue(thisObj, "txtWireTransferData", "");
        }
      });
    }
    }
    catch(err){
      errorHandler(err)
    }
  };
  // End Method to get Vendor details

  //Start method to bind the vendor name and called vendor textbox change event
  const setVendorNameBasedOnVendorNumber = async (VendorNumber) => {
    try{
    let venname = null
    let vendornamevisible = false
    await ContractManagementService.RetrieveVendorByNumber(VendorNumber).then(response => {
      let data = response
      if (data !== null && data !== undefined) {
        if (data.length == 0) {
          return;
        }
        else {
          venname = data[0].name
          vendornamevisible = true
        }
      }
      if (vendornamevisible == true) {
        // show(thisObj, "txtRepayVndr")
      document.getElementsByClassName("txtRepayVndr")[0].style.visibility = "visible";

        setValue(thisObj, "txtRepayVndr", venname)
      }
      else {
        // hide(thisObj, "txtRepayVndr", false);
      document.getElementsByClassName("txtRepayVndr")[0].style.visibility = "hidden";

      }
    })
   }
   catch(err){
    errorHandler(err)
   }
  }
  //End method to bind the vendor name and called vendor textbox change event

  const onchkboxWireTransferChange = (VendorNumber) => {
  try{
    setLoading(true)
    hide(thisObj, "txtWireTransferData");
    hide(thisObj, "txtWireTransferData2");
    hide(thisObj, "txtWireTransferData3");
    hide(thisObj, "txtWireTransferData4");
    hide(thisObj, "txtWireTransferData5");
    hide(thisObj, "txtWireTransferData6");

    hide(thisObj, 'txtConcordia1', false)
    hide(thisObj, 'txtConcordia2', false)
    hide(thisObj, 'txtConcordia3', false)

    hide(thisObj, 'txtWireXferData')

    if (document.getElementById("chkboxWireTransfer").checked == false) return

    if (thisObj.values.txtRepayVendor == "134969" || (VendorNumber != undefined && VendorNumber == "134969")) {
      show(thisObj, "txtWireTransferData");
      show(thisObj, "txtWireTransferData2");
      show(thisObj, "txtWireTransferData3");
      show(thisObj, "txtWireTransferData4");
      show(thisObj, "txtWireTransferData5");
      show(thisObj, "txtWireTransferData6");
      setValue(thisObj, "txtWireTransferData", "BNF=/AC-4992OBI=CCC/1/");
      setValue(thisObj, "txtWireTransferData2", "XX");
      setValue(thisObj, "txtWireTransferData3", "XXX");
      setValue(thisObj, "txtWireTransferData4", "X");
      setValue(thisObj, "txtWireTransferData5", "/SCH#");
      setValue(thisObj, "txtWireTransferData6", "XXXX");
      disable(thisObj, "txtWireTransferData");
      disable(thisObj, "txtWireTransferData5");
      return
    }
    else if (thisObj.values.txtRepayVendor == "745134"  || (VendorNumber != undefined && VendorNumber == "745134")) {
      show(thisObj, 'txtConcordia1')
      show(thisObj, 'txtConcordia2')
      show(thisObj, 'txtConcordia3')

      setValue(thisObj, 'txtConcordia1', 'XXXXX')
      setValue(thisObj, "txtConcordia2", '/');
      setValue(thisObj, 'txtConcordia3', 'XXXXX')
    } else {
      show(thisObj, "txtWireTransferData");
      setValue(thisObj, "txtWireTransferData", "");

    }

    // show(thisObj, 'txtWireXferData');
    // setValue(thisObj,'txtWireXferData',"");
  }
  catch(err){
    errorHandler(err)
  }
  finally{
    setLoading(false)
  }
  }
  thisObj.onchkboxWireTransferChange = onchkboxWireTransferChange

  const onchkbxColChange = (e) => {
    try{
    const rowNo = +e.target.name.split(".")[1]
    if (e.target.value === true) {
      (getValue(thisObj, 'gridWarehouseReceiptRedeembyLoan')[rowNo]).isSelected = true
      setSelectedRows((prevState) => [
        ...prevState,
        rowNo,
      ]);
    }
    else
      setSelectedRows((prevState) => {
        (getValue(thisObj, 'gridWarehouseReceiptRedeembyLoan')[rowNo]).isSelected = false
        const index = prevState.indexOf(rowNo);
        const newState = prevState.slice()
        if (index > -1) {
          newState.splice(index, 1);
        }
        return newState
      });
    }
    catch(err){
      errorHandler(err)
    }
  };
  thisObj.onchkbxColChange = onchkbxColChange;

  const onchkboxAllChange = (e) => {//for all FSA CheckBOx
    try{
    let gridVal = getValue(thisObj, 'gridWarehouseReceiptRedeembyLoan')
    let selectedRowsArr = []
    for (let i = 0; i < selectedRows.length; i++) {
      let value = getValue(thisObj, 'gridWarehouseReceiptRedeembyLoan')[selectedRows[i]];
      selectedRowsArr.push(value);
    }

    if (selectedRows.length > 0 && selectedRows.length < gridVal.length) {
      onbtnSearchClick();
      setValue(thisObj, 'chkboxAll', false)
      return;
    }
    
    if(gridVal?.length > 15){
      alert(`Total ${gridVal?.length} rows are been selected together. Please wait for a while till the selection gets completed.`)
    }

    if (e.target.value == true) {
      let jsObj = gridVal.map(row => ({
        ...row, chkbxCol: true, isSelected: true
      }))
      setValue(thisObj, 'gridWarehouseReceiptRedeembyLoan', jsObj)
      setSelectedRows([...Array(gridVal.length).keys()])
    }
    else {
      let jsObj = gridVal.map(row => ({
        ...row, chkbxCol: false, isSelected: false
      }))
      setValue(thisObj, 'gridWarehouseReceiptRedeembyLoan', jsObj)
      setSelectedRows([])
    }
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onchkboxAllChange = onchkboxAllChange;

  function errorHandler(err){
    console.log(err)
    showMessage(thisObj, err?.message)
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>

            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">

              {/* START_USER_CODE-USER_BEFORE_WarehouseReceiptRedeemByLoan*/}

              {/* END_USER_CODE-USER_BEFORE_WarehouseReceiptRedeemByLoan*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxWarehouseReceiptReedembyyLoanNum*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxWarehouseReceiptReedembyyLoanNum*/}

              <GroupBoxWidget
                conf={state.grpbxWarehouseReceiptReedembyyLoanNum}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxSearch*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSearch*/}

                <GroupBoxWidget conf={state.grpbxSearch} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_chkboxAll*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxAll*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxAll}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxAll*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxAll*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLoanNum1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLoanNum1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLoanNum1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLoanNum1*/}

                  {/* END_USER_CODE-USER_AFTER_txtLoanNum1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLoanNum2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLoanNum2*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLoanNum2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLoanNum2*/}

                  {/* END_USER_CODE-USER_AFTER_txtLoanNum2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLoanNum3*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLoanNum3*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLoanNum3}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLoanNum3*/}

                  {/* END_USER_CODE-USER_AFTER_txtLoanNum3*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_radioScreenmode*/}

                  {/* END_USER_CODE-USER_BEFORE_radioScreenmode*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioScreenmode}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioScreenmode*/}

                  {/* END_USER_CODE-USER_AFTER_radioScreenmode*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxCCC500*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxCCC500*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxCCC500}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxCCC500*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxCCC500*/}
                  {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                  {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                  <ButtonWidget
                    conf={state.btnUpdate}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                  {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSearch*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSearch*/}
                {/* START_USER_CODE-USER_BEFORE_gridWarehouseReceiptRedeembyLoan*/}

                {/* END_USER_CODE-USER_BEFORE_gridWarehouseReceiptRedeembyLoan*/}

                <GridWidget
                  conf={state.gridWarehouseReceiptRedeembyLoan}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridWarehouseReceiptRedeembyLoan}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  refObject={thisObj}
                  key={state.gridWarehouseReceiptRedeembyLoan.toggleRender}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridWarehouseReceiptRedeembyLoan*/}

                {/* END_USER_CODE-USER_AFTER_gridWarehouseReceiptRedeembyLoan*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxTotalC*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxTotalC*/}

                <GroupBoxWidget conf={state.grpbxTotalC} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtTotalTons*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalTons*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalTons}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalTons*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalTons*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalReceiptValue*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalReceiptValue*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalReceiptValue}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalReceiptValue*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalReceiptValue*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalPrincipalLoaned*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalPrincipalLoaned*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalPrincipalLoaned}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalPrincipalLoaned*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalPrincipalLoaned*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalMarketGain*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalMarketGain*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalMarketGain}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalMarketGain*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalMarketGain*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalLoanRepayAmount*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalLoanRepayAmount*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalLoanRepayAmount}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalLoanRepayAmount*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalLoanRepayAmount*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalHandlingAmount*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalHandlingAmount*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalHandlingAmount}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalHandlingAmount*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalHandlingAmount*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalInterestAmount*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalInterestAmount*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalInterestAmount}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalInterestAmount*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalInterestAmount*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalNetRepayAmount*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalNetRepayAmount*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalNetRepayAmount}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalNetRepayAmount*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalNetRepayAmount*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxTotalC*/}

                {/* END_USER_CODE-USER_AFTER_grpbxTotalC*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxTotal*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxTotal*/}

                <GroupBoxWidget conf={state.grpbxTotal} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtRedemptionEndDate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtRedemptionEndDate*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtRedemptionEndDate}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtRedemptionEndDate*/}

                  {/* END_USER_CODE-USER_AFTER_txtRedemptionEndDate*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalprincipalLiquidated*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalprincipalLiquidated*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalprincipalLiquidated}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalprincipalLiquidated*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalprincipalLiquidated*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalMrktGain*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalMrktGain*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalMrktGain}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalMrktGain*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalMrktGain*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalHandling*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalHandling*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalHandling}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalHandling*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalHandling*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalInterestDue*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalInterestDue*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalInterestDue}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalInterestDue*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalInterestDue*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalAmountDue*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalAmountDue*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalAmountDue}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalAmountDue*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalAmountDue*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxTotal*/}

                {/* END_USER_CODE-USER_AFTER_grpbxTotal*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxRepayment*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxRepayment*/}

                <GroupBoxWidget conf={state.grpbxRepayment} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblRepayment*/}

                  {/* END_USER_CODE-USER_BEFORE_lblRepayment*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblRepayment}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblRepayment*/}

                  {/* END_USER_CODE-USER_AFTER_lblRepayment*/}
                  {/* START_USER_CODE-USER_BEFORE_txtRepayVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtRepayVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtRepayVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtRepayVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtRepayVendor*/}


                  {/* START_USER_CODE-USER_BEFORE_btnRepayVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_btnRepayVendor*/}

                  <ButtonWidget
                    conf={state.btnRepayVendor}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnRepayVendor*/}

                  {/* END_USER_CODE-USER_AFTER_btnRepayVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtRepayVndr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtRepayVndr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtRepayVndr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtRepayVndr*/}

                  {/* END_USER_CODE-USER_AFTER_txtRepayVndr*/}
                  {/* START_USER_CODE-USER_BEFORE_ddRepayRemit*/}

                  {/* END_USER_CODE-USER_BEFORE_ddRepayRemit*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddRepayRemit}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddRepayRemit*/}

                  {/* END_USER_CODE-USER_AFTER_ddRepayRemit*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxWireTransfer*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxWireTransfer*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxWireTransfer}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxWireTransfer*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxWireTransfer*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWireTransferData*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWireTransferData*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireTransferData}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWireTransferData*/}

                  {/* END_USER_CODE-USER_AFTER_txtWireTransferData*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWireTransferData2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWireTransferData2*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireTransferData2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWireTransferData2*/}

                  {/* END_USER_CODE-USER_AFTER_txtWireTransferData2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWireTransferData3*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWireTransferData3*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireTransferData3}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWireTransferData3*/}

                  {/* END_USER_CODE-USER_AFTER_txtWireTransferData3*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWireTransferData4*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWireTransferData4*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireTransferData4}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWireTransferData4*/}

                  {/* END_USER_CODE-USER_AFTER_txtWireTransferData4*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWireTransferData5*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWireTransferData5*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireTransferData5}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWireTransferData5*/}

                  {/* END_USER_CODE-USER_AFTER_txtWireTransferData5*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWireTransferData6*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWireTransferData6*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireTransferData6}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtConcordia1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtConcordia2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtConcordia3}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireXferData}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWireTransferData6*/}

                  {/* END_USER_CODE-USER_AFTER_txtWireTransferData6*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPurchase*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPurchase*/}

                  <ButtonWidget
                    conf={state.btnPurchase}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPurchase*/}

                  {/* END_USER_CODE-USER_AFTER_btnPurchase*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                  <ButtonWidget
                    conf={state.btnClose}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClose*/}

                  {/* END_USER_CODE-USER_AFTER_btnClose*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxRepayment*/}

                {/* END_USER_CODE-USER_AFTER_grpbxRepayment*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxWarehouseReceiptReedembyyLoanNum*/}

              {/* END_USER_CODE-USER_AFTER_grpbxWarehouseReceiptReedembyyLoanNum*/}

              {/* START_USER_CODE-USER_AFTER_WarehouseReceiptRedeemByLoan*/}

              {/* END_USER_CODE-USER_AFTER_WarehouseReceiptRedeemByLoan*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_WarehouseReceiptRedeemByLoan);
