/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  getData,
  setData,
  getValue,
  goTo
} from "../../shared/WindowImports";
import "./ContractGrpSelect.scss";

import ContractManagement_header from "../../Header/Header";
import {ContractManagementService} from"../Service/ContractManagementService";
import { returnNumericValues } from './../../Common/Constants';
import StripPic from "../../../../assets/img/PinStrip.png";

function ContractManagement_ContractGrpSelect(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
 
  const js = [ { txtcol1: "123", txt2: "890", txt3: "9090" } ]

  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let contractGroupingList;
  
  let states = {
    Label: "ContractGrpSelect",
    windowName: "ContractGrpSelect",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ContractGrpSelect",
    
    horizontalForm: true,
    headerData: {
      scrName: "Group Contract Select",
      scrCode: "PN1150C",
    },
    headerDetails: {
      year: cropYear, // "2005",
      companyName: "Golden Peanut Compnay"
    },
    
    gridSelectCntrcts: {
      name: "gridSelectCntrcts",
      type: "GridWidget",
      parent: "ContractGrpSelect",
      gridCellsOrder:
        "txtcolContrct,txtcolRev,txtcolCntrctType,txtcolDate,txtcolByngPt,txtcolVndr,txtcolPnutType,txtcolPnutVrty,txtcolSegTyp,txtcolOleic,txtcolSeedGrwr,txtcolOrgnc,txtcolOpen,txtcolTntvOpen",
      Label: "gridCntrctgrp",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isMultiSelect: true,
      hideAllCheckBox:false,
      needGridChange: true,//Change for Grid change
      isExpandable: true,
      defaultExpand: false,
      expandedCompTemplate: ({ data }) => (
        <div>
        <div className="py-3 expandable-area col-sm-12 rdt_TableRow selectable">
          <div className={"row" + " rdt_ExpanderRow"} >
          <div className="extraRowCell_1 ch-3" key="expandCell1">
            {data.txtcolVendorName}
          </div>
          <div className="extraRowCell_2 ch-3" key="expandCell1">
            {data.txtcolPnutName}
          </div>
          </div>
          </div>
          </div>
      )
    },
    colByngPt: {
      name: "colByngPt",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Buying Point",
      Height: "",
      Width: "",
    },
    colChckbx: {
      name: "colChckbx",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      HasLabel: false,
      Height: "",
      Width: "",
    },
    colCntrctType: {
      name: "colCntrctType",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Contract Type",
      Height: "",
      Width: "",
    },
    colContrct: {
      name: "colContrct",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Contract #",
      Height: "",
      Width: "",
    },
    colDate: {
      name: "colDate",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Date",
      Height: "",
      Width: "",
    },
    colOleic: {
      name: "colOleic",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Oleic",
      Height: "",
      Width: "",
    },
    colOpen: {
      name: "colOpen",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Open",
      Height: "",
      Width: "",
    },
    colOrgnc: {
      name: "colOrgnc",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Organic",
      Height: "",
      Width: "",
    },
    colPnutType: {
      name: "colPnutType",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Peanut Type",
      Height: "",
      Width: "",
    },
    colPnutVrty: {
      name: "colPnutVrty",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
    },
    colRev: {
      name: "colRev",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Rev",
      Height: "",
      Width: "10",
    },
    colSeedGrwr: {
      name: "colSeedGrwr",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Seed Grower",
      Height: "",
      Width: "",
    },
    colSegTyp: {
      name: "colSegTyp",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Seg Type",
      Height: "",
      Width: "",
    },
    colTntvOpen: {
      name: "colTntvOpen",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Tentative Open",
      Height: "",
      Width: "",
    },
    colVndr: {
      name: "colVndr",
      type: "GridColumnWidget",
      parent: "gridSelectCntrcts",
      Label: "Vendor",
      Height: "",
      Width: "",
    },
    txtcolByngPt: {
      name: "txtcolByngPt",
      type: "TextBoxWidget",
      colName: "colByngPt",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolCntrctType: {
      name: "txtcolCntrctType",
      type: "TextBoxWidget",
      colName: "colCntrctType",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolContrct: {
      name: "txtcolContrct",
      type: "TextBoxWidget",
      colName: "colContrct",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolDate: {
      name: "txtcolDate",
      type: "TextBoxWidget",
      colName: "colDate",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolOleic: {
      name: "txtcolOleic",
      type: "TextBoxWidget",
      colName: "colOleic",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolOpen: {
      name: "txtcolOpen",
      type: "TextBoxWidget",
      colName: "colOpen",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolOrgnc: {
      name: "txtcolOrgnc",
      type: "TextBoxWidget",
      colName: "colOrgnc",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolPnutType: {
      name: "txtcolPnutType",
      type: "TextBoxWidget",
      colName: "colPnutType",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolPnutVrty: {
      name: "txtcolPnutVrty",
      type: "TextBoxWidget",
      colName: "colPnutVrty",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolRev: {
      name: "txtcolRev",
      type: "TextBoxWidget",
      colName: "colRev",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolSeedGrwr: {
      name: "txtcolSeedGrwr",
      type: "TextBoxWidget",
      colName: "colSeedGrwr",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolSegTyp: {
      name: "txtcolSegTyp",
      type: "TextBoxWidget",
      colName: "colSegTyp",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolTntvOpen: {
      name: "txtcolTntvOpen",
      type: "TextBoxWidget",
      colName: "colTntvOpen",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolVndr: {
      name: "txtcolVndr",
      type: "TextBoxWidget",
      colName: "colVndr",
      parent: "gridSelectCntrcts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxClose",
      Label: "Close",
      CharWidth: "13",
      ofTypeDomain: "d_boolean",
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxSrch",
      Label: "Search",
      CharWidth: "15",
      ofTypeDomain: "d_boolean",
    },
    btnSelect: {
      name: "btnSelect",
      type: "ButtonWidget",
      parent: "grpbxSelectCntrct",
      Label: "Select",
      CharWidth: "15",
      ofTypeDomain: "d_boolean",
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxSrch",
      Label: "...",
      CharWidth: "9",
      ofTypeDomain: "d_boolean",
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxSrch",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxSrch",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
    },
    ddOrganic: {
      name: "ddOrganic",
      type: "DropDownFieldWidget",
      parent: "grpbxSrch",
      Label: "Organic:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxSrch",
      Label: "Peanut Type:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxSrch",
      Label: "Peanut Variety:",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
    },
    ddSeedGrower: {
      name: "ddSeedGrower",
      type: "DropDownFieldWidget",
      parent: "grpbxSrch",
      Label: "Seed Grower:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxSrch",
      Label: "Seg #:",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpbxSrch",
      Label: "Contract #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxSrch",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
    },
    txtXx: {
      name: "txtXx",
      type: "TextBoxWidget",
      parent: "grpbxSrch",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    grpbxSrch: {
      name: "grpbxSrch",
      type: "GroupBoxWidget",
      parent: "ContractGrpSelect",
      Height: "",
      Width: "",
      clonedExtId: "21918",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
    },
    grpbxSelectCntrct: {
      name: "grpbxSelectCntrct",
      type: "GroupBoxWidget",
      parent: "ContractGrpSelect",
      Height: "",
      Width: "",
      HasLabel: false,
    },
    grpbxClose: {
      name: "grpbxClose",
      type: "GroupBoxWidget",
      parent: "ContractGrpSelect",
      Height: "",
      Width: "",
      HasLabel: false,
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#VendorLookup": {},
      },
      REVERSE: {
        "ContractManagement#VendorLookup": {},
      },
    },
  };
  let _buttonServices = {
    ContractGrpSelect:{}, ContractGroupingProfile: {}, VendorLookup: {}, MainMenu: {}
  };
  let _buttonNavigation = {
    btnVendor:{
      DEFAULT:[ "ContractManagement#VendorLookup#DEFAULT#true#Click", ]
    }
  };

  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null) {
      thisObj.setFieldValue("txtVendor", vendor_lookup.VendorNumber);
      document.getElementsByClassName("txtXx")[0].style.visibility = "visible";
      thisObj.setFieldValue("txtXx", vendor_lookup.key);
      // VendorDetails(vendor_lookup.VendorNumber)
    }
    else{
      document.getElementsByClassName("txtXx")[0].style.visibility = "hidden";
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj,"ddPeanutType",'')
    setValue(thisObj,"ddPeanutVariety",'')
    setValue(thisObj,"ddSeg",'')
    setValue(thisObj,"ddOleic",'')
    setValue(thisObj,"ddSeedGrower",'')
    setValue(thisObj,"ddBuyingPoint",'')
    setValue(thisObj,"ddOrganic",'')
    FormLoad();
  }, []);

  useEffect(() => {
    let data = getData(thisObj, 'contractGroupingList')
    if(data == null || data == undefined){
      setData(thisObj, "contractGroupingList" , [])    
    }
    else{
      setData(thisObj, "contractGroupingList", data)    
    }
    data = getData(thisObj, 'contractGroupingList')
  }, []);


  function FormLoad(){
    bFillTypeList();             // Method for binding Peanut Type dropdown
    bFillBuyingPointList()       // Method for binding Buying Points
    bFillVarietyList(null,null)  //
    bFillSegmentList()           // Method for binding Segments
    bFillOleicList()             // Method for binding Oleic values
    bFillSeedGrowerList()        // Method for binding Seed Grower List
    bFillOrganicList()           // Method for binding Organic List
    document.getElementsByClassName("txtXx")[0].style.visibility = "hidden";
    setValue(thisObj,"txtVendor","")
    setValue(thisObj,"txtXx","")
  }

  const bFillTypeList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Types <<<' }) //Set All as default value
    ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
        js.push(obj)
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })
    })
  }

  const bFillBuyingPointList = () => {
    let js = []
    js.push({ key: '', description: '>>>All Buying Points<<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1150', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
    
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })
    })
  }

  const bFillVarietyList = (buyingPointId, pnut_type_id) => {
    let js = [];
    let obj = { key: '', description: ">>> All Types <<<" }
    js.push(obj)
    if (pnut_type_id !== null && pnut_type_id !== '' && pnut_type_id !== undefined) {
      ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null)
        .then(response => {
          let data = response;
          if (data !== null && data !== undefined && data.length>0) {
            let js1 = [];		
      for (var i = 0; i < data.length; i++) {	
        if (!(js1.includes(data[i].pnut_variety_id))) {
        obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name }	
        if (data[i].symbol_ind == "TRADEMARK") {	
          obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name + 'ᵀᴹ' }	
        }	
        if (data[i].symbol_ind == "REGISTERED TRADEMARK") {	
          obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name + '®' }	
        }	
        if (data[i].symbol_ind == "COPYRIGHT") {	
          obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name + '©' }	
        }	
        js.push(obj);	
        js1.push(data[i].pnut_variety_id)	
      }	
      	
      }	}})} 
    
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutVariety: {
          ...state["ddPeanutVariety"],
          valueList: js
        }
      }
    });
  }

  const bFillSegmentList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Segments <<<' })
    js.push({ key: '1', description: '1' })
    js.push({ key: '2', description: '2' })
    js.push({ key: '3', description: '3' })
    
    thisObj.setState(current => {
      return {
        ...current,
        ddSeg: {
          ...state["ddSeg"],
          valueList: js
        }
      }
    })
  }

  const bFillOleicList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Oleic <<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
  
    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })
  }

  const bFillSeedGrowerList = () => {
    let js = []
    js.push({ key: '', description: '>>> Both <<<' })
    js.push({ key: 'Y', description: 'Yes' })
    js.push({ key: 'N', description: 'No' })

    thisObj.setState(current => {
      return {
        ...current,
        ddSeedGrower: {
          ...state["ddSeedGrower"],
          valueList: js
        }
      }
    })
  }

  const bFillOrganicList = () => {
    let js = []
    js.push({ key: '', description: '>>> Both <<<' })
    js.push({ key: 'Y', description: 'Yes' })
    js.push({ key: 'N', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOrganic: {
          ...state["ddOrganic"],
          valueList: js
        }
      }
    })
  }

  const onPeanutTypeChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPeanutType'];
      let pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;

      let BuyingPointList = thisObj.state['ddBuyingPoint'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddBuyingPoint'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== null && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      bFillVarietyList(buyingPointId, pnut_type_id)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Peanut Type dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddPeanutTypeChange = onPeanutTypeChange;

  const onBuyingPointChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPeanutType'];
      let pnut_type_id = null
      if (SelectedPeanutTypeValue !== "" && SelectedPeanutTypeValue !== undefined) {
        pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      }

      let BuyingPointList = thisObj.state['ddBuyingPoint'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddBuyingPoint'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      bFillVarietyList(buyingPointId, pnut_type_id)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Buying Point dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddBuyingPointChange = onBuyingPointChange;
//PRB0054498 - Searching the vendors with vendor number from vendor textbox and convert to uppercase  
    const ontxtVendorChange = () => {
    try {
      let vendorNumber = txtVendor.value
      if(txtVendor.value != undefined && txtVendor.value != null){
        vendorNumber = vendorNumber.toUpperCase() 
        if (vendorNumber.length == 6) {
          setVendorNameBasedOnVendorNumber(vendorNumber)
        }
        else {
          document.getElementsByClassName("txtXx")[0].style.visibility = "hidden";
          // setValue(thisObj, "txtXx", '')
        }
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:txtVondor event:Change"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtVendorChange = ontxtVendorChange;  

  const ontxtContractChange = () => {
    setValue(thisObj, "txtContract", returnNumericValues(txtContract.value))
  };
  thisObj.ontxtContractChange = ontxtContractChange;

  const setVendorNameBasedOnVendorNumber = (vendorNumber) => {
    let venname = null
    let vendornamevisible = false
    ContractManagementService.RetrieveVendorByNumber(vendorNumber).then(response => {
      let data = response
      if (data !== null && data !== undefined) {
        if (data.length == 0) {
          return;
        }
        else {
          venname = data[0].name
          vendornamevisible = true
        }
      }

      if (vendornamevisible == true) {
        document.getElementsByClassName("txtXx")[0].style.visibility = "visible";
        setValue(thisObj, "txtXx", venname)
      }
      else {
        document.getElementsByClassName("txtXx")[0].style.visibility = "hidden";
        setValue(thisObj, "txtXx", '')
      }
    })
  }
  
  const onbtnSearchClick = async() => {
    try {
      let func_id = 'PN1150'
      let cont_status='A'
      let cont_type=null
      let func_sub_id = null
      let grouping_num =null
      let gen_produced=null
      let state_abbr=null
      let county_id=null
      let farm_id=null
      let beg_cont_date=null
      let end_cont_date=null
      let disclosure=null
      let contract_num = txtContract.value
      let split_vendor = txtVendor.value
      let buy_pt_id = thisObj.values['ddBuyingPoint']
      let pnut_type_id = thisObj.values['ddPeanutType']
      let pnut_variety_id = thisObj.values['ddPeanutVariety']
      let seg_type = thisObj.values['ddSeg']
      let oleic_ind = thisObj.values['ddOleic']
      let seed_grower = thisObj.values['ddSeedGrower']
      let organic_ind = thisObj.values['ddOrganic']
      
      if (buy_pt_id == '' && contract_num == '' && split_vendor == '') {
        showMessage(thisObj,"Contract#, or Vendor# must be specified for searches across all buying points", false);
      }
      else {
        setValue(thisObj, "gridSelectCntrcts", [])
        let response = await ContractManagementService.RetriveContractList(func_id, func_sub_id, contract_num, gen_produced, split_vendor, cont_status, cont_type, buy_pt_id, state_abbr, county_id, farm_id, pnut_type_id, pnut_variety_id, seg_type, seed_grower, oleic_ind, organic_ind, beg_cont_date, end_cont_date, grouping_num, disclosure)
        
        if (response != null && response != undefined) {
          if (response.length != 0) {
            let data = response;
            let res = []
            var dict = { 'H': "High", 'M': "Mid", 'N': 'No', 'Y': 'Yes' }
            for (var i = 0; i < data.length; i++) {
              res.push({
                txtcolContrct: data[i].contract_num,
                txtcolRev: data[i].revision_num,
                txtcolCntrctType: data[i].cont_type,
                txtcolDate: data[i].cont_date.split(' ')[0],
                txtcolByngPt: data[i].buy_pt_id,
                txtcolVndr: data[i].split_vendor + " - " + data[i].split_vendor_name,
                txtcolPnutType: data[i].pnut_type_id,
                txtcolPnutVrty: data[i].pnut_variety_id,
                txtcolSegTyp: data[i].seg_type,
                txtcolOleic: data[i].oleic_ind == '' ? '' : dict[data[i].oleic_ind],
                txtcolSeedGrwr: data[i].seed_grower == '' ? '' : dict[data[i].seed_grower],
                txtcolOrgnc: data[i].organic_ind == '' ? '' : dict[data[i].organic_ind],
                txtcolOpen: data[i].open_lbs,
                txtcolTntvOpen: data[i].tentative_open_lbs,
                txtcolVendorName: data[i].buy_pt_name + " - " + data[i].physical_city + " - " + data[i].physical_state,
                txtcolPnutName: data[i].pnut_type_name 
              })
            }            
            setValue(thisObj, "gridSelectCntrcts", res);
          }
          else {
            showMessage(thisObj, "No Contract Found!!!")
            return;
          }
        }
        else{
          showMessage(thisObj, "Error : Please try again.")
          return;
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click")
      }
      return false
    }
    return true
  };
  thisObj.onbtnSearchClick = onbtnSearchClick;
 
  const ongridSelectCntrctsRowSelect = (rowSelected) =>{
    setData(thisObj, 'rowSelected', rowSelected.selectedRows);
  }
  thisObj.ongridSelectCntrctsRowSelect = ongridSelectCntrctsRowSelect;
  
  const onbtnSelectClick = async() => {
    try {
      let searchData = getValue(thisObj, "gridSelectCntrcts");
      let rowData = getData(thisObj, 'rowSelected')    
      let data = getData(thisObj, "contractGroupingList")    
      if(data.length > 10){
        alert("Maximum 10 contracts can be added to a grouping. Maximum limit reached.")
        setValue(thisObj, "gridSelectCntrcts", searchData)
        // goTo(thisObj, "ContractManagement#ContractGroupingProfile#DEFAULT#true#Click")
        // document.getElementById("ContractManagement_ContractGrpSelectPopUp").childNodes[0].click();
        return;
      }
        
      if(rowData == null && rowData == undefined){
        showMessage(thisObj, "Please select the contracts again...")
        setValue(thisObj, "gridSelectCntrcts", searchData)
        return;
      }
      if(rowData.length == 0){
        showMessage(thisObj, "At least one contract must be checkmarked for selection...")
        return;
      }
      
      data = data.sort()
      rowData = [...rowData].sort((a, b) => a.txtcolContrct - b.txtcolContrct)

      let selectedRow = rowData;
      let res = []
      for(var i=0; i<selectedRow.length; i++){
        let response = await ContractManagementService.RetrieveContractGroupDetails('PN1150', '', null, selectedRow[i].txtcolContrct, null, null, null, null, null, null, null,null) 
        if(response != undefined && response != null){
          if(response.length != 0){
            alert("Contract "+selectedRow[i].txtcolContrct + " already belong to grouping " + response[2].strFieldData + ".")
            return;
          }
          else{
            res.push(selectedRow[i].txtcolContrct)
          }
        }
      }
      data = getData(thisObj, "contractGroupingList").sort()
      
      for(var i=0; i<res.length;i++){
        if(data.length == 10){
          alert("Grouping can only contain upto 10 contracts. Contract " + res[i] + " cannot be added to the grouping.")
          return;
        }
        else{
          data.push(res[i])
        }          
      }
      res = []
      for(var i =0; i<data.length;i++){
        if(data[i] != ''){
          res.push(data[i])
        }
      }
      setData(thisObj, "contractGroupingList", res)
      let d = getData(thisObj, "contractGroupingList")
      
      if(d != null && d != undefined){
        goTo(thisObj, "ContractManagement#ContractGroupingProfile#DEFAULT#true#Click")
        document.getElementById("ContractManagement_ContractGrpSelectPopUp").childNodes[0].click();
        return;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred while getting selected row object on Select button click event" );
      }
    }
    return true;
  }
  thisObj.onbtnSelectClick = onbtnSelectClick;

  const onbtnCloseClick = () => {
    try {
      document.getElementById("ContractManagement_ContractGrpSelectPopUp").childNodes[0].click();
    } 
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnClose event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            
            <ContractManagement_header headerData={state.headerData} />            
            <h1 className="pageHeader">{state.Label}</h1>            
            <Form noValidate className="row">            
              <GroupBoxWidget conf={state.grpbxSrch} screenConf={state}>
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>                
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutVariety}
                  screenConf={state}
                ></DropDownWidget>                
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>                
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeg}
                  screenConf={state}
                ></DropDownWidget>                
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>                
                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>                
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtXx}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>                
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleic}
                  screenConf={state}
                ></DropDownWidget>                
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeedGrower}
                  screenConf={state}
                ></DropDownWidget>                
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOrganic}
                  screenConf={state}
                ></DropDownWidget>
                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>              
              </GroupBoxWidget>
              
              <GroupBoxWidget conf={state.grpbxSelectCntrct} screenConf={state}>                
                <ButtonWidget
                  conf={state.btnSelect}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <GridWidget
                conf={state.gridSelectCntrcts}
                screenConf={state}
                linkClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                onRowChange={selectedRows=> onGridRowChange(selectedRows)}
                errors={errors}
                touched={touched}
                rows={values.gridSelectCntrcts}
                onEvent={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                setFieldValue={setFieldValue} 
                refObject={thisObj}
              ></GridWidget>
              </GroupBoxWidget>
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxClose} screenConf={state}>
                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>              
              </GroupBoxWidget>              
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ContractGrpSelect); 