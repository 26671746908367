import React from "react";
import { string, number, array, object, mixed } from "yup";
import Button from "react-bootstrap/Button";
import { DefaultPropsData } from "../app/modules/widgets/common/DefaultPropsData";
const _kaledo = window._kaledo;

function createValidationSchema(widget, state, thisContext) {
  let valSchema;
  let requiredMsg = thisContext.context.t(
    `AppJSMessage:VALIDATIONMSG_REQUIRED`
  );
  if (widget.validationMessage && widget.validationMessage.required) {
    requiredMsg = thisContext.context.t(widget.validationMessage.required);
  }

  let minMaxLengthMsg = thisContext.context.t(
    `AppJSMessage:VALIDATIONMSG_LENGTHRANGE`
  );
  if (widget.validationMessage && widget.validationMessage.minMaxLength) {
    minMaxLengthMsg = thisContext.context.t(
      widget.validationMessage.minMaxLength
    );
  }

  let minMaxValueMsg = thisContext.context.t(
    `AppJSMessage:VALIDATIONMSG_VALUERANGE`
  );
  if (widget.validationMessage && widget.validationMessage.minMaxValue) {
    minMaxValueMsg = thisContext.context.t(
      widget.validationMessage.minMaxValue
    );
  }

  let fileTypeMsg = thisContext.context.t(`AppJSMessage:VALIDATIONMSG_FILE`);
  if (widget.validationMessage && widget.validationMessage.fileType) {
    fileTypeMsg = thisContext.context.t(widget.validationMessage.fileType);
  }

  switch (widget.ofTypeDomain) {
    case "d_String":
      valSchema = string();
      if (widget.Mandatory) {
        valSchema = valSchema.required(requiredMsg);
      }
      if (widget.LengthRange) {
        let lengthRange = widget.LengthRange;
        minMaxLengthMsg = minMaxLengthMsg
          .replace("{min-length-val}", lengthRange.MinLength)
          .replace("{max-length-val}", lengthRange.MaxLength);
        if (lengthRange.MinLength) {
          valSchema = valSchema.min(lengthRange.MinLength, minMaxLengthMsg);
        }
        if (lengthRange.MaxLength) {
          valSchema = valSchema.max(lengthRange.MaxLength, minMaxLengthMsg);
        }
      }
      if (widget.regExp) {
        let regExp = widget.regExp;
        if (regExp.RegularExpression) {
          let valMsgKey = regExp.ValidatorMessage;
          valMsgKey = valMsgKey.replace(/[.]/g, '_');
          let patternMsg = thisContext.context.t(`${_kaledo.guiModuleName}:${valMsgKey}`);
          valSchema = valSchema.matches(regExp.RegularExpression, patternMsg);
        }
      }
      break;
    case "d_int":
      valSchema = number();
      if (widget.Mandatory) {
        valSchema = valSchema.required(requiredMsg);
      }
      if (widget.ValueRange) {
        let valueRange = widget.ValueRange;
        minMaxValueMsg = minMaxValueMsg
          .replace("{min-val}", valueRange.MinValue)
          .replace("{max-val}", valueRange.MaxValue);
        if (valueRange.MinValue) {
          valSchema = valSchema.min(valueRange.MinValue, minMaxValueMsg);
        }
        if (valueRange.MaxValue) {
          valSchema = valSchema.max(valueRange.MaxValue, minMaxValueMsg);
        }
      }
      if (widget.LengthRange) {
        let LengthRange = {
          MaxLength : null
         };
        let x ="9";
        let pattern = "";
        for(let i =1; i <= widget.LengthRange.MaxLength; i++){
          pattern += x;
        }
        LengthRange.MaxLength = pattern;
        minMaxLengthMsg = minMaxLengthMsg
          .replace("{min-length-val}", widget.LengthRange.MinLength)
          .replace("{max-length-val}", LengthRange.MaxLength);
        if (widget.LengthRange.MinLength) {
          valSchema = valSchema.min(widget.LengthRange.MinLength, minMaxLengthMsg);
        }
        if (LengthRange.MaxLength) {
          valSchema = valSchema.max(LengthRange.MaxLength, minMaxLengthMsg);
        }
      }
      break;
    case "d_date":
    case "d_datetime":
    case "d_time":
      valSchema = mixed();
      if (widget.Mandatory) {
        valSchema = valSchema.required(requiredMsg);
      }
      break;
    default:
      if (widget.type === "FileWidget" || widget.type === "ImageWidget") {
        valSchema = array();
        if (widget.Mandatory) {
          valSchema = valSchema.required(requiredMsg);
        }
        if (widget.SizeLimit) {
          valSchema = valSchema.test(
            "fileSize",
            thisContext.context.t(
              "Total file size exceed allowed file size limit"
            ),
            fileObj => validateFileSize(widget, fileObj)
          );
        }
		if (widget.type === "ImageWidget")
          fileTypeMsg = fileTypeMsg.replace("{0}", "png,jpeg,jpg,gif,svg");
        else fileTypeMsg = fileTypeMsg.replace("{0}", widget.AllowedExtensions);
        valSchema = valSchema.of(
          mixed().test("fileFormat", fileTypeMsg, fileObj =>
            validateFileType(widget, fileObj)
          )
        );
      } else if (
        widget.type === "CheckBoxGroupFieldWidget" ||
        widget.type === "ListBoxFieldWidget" ||
        widget.type === "AutocompleteWidget"
      ) {
        valSchema = array();
        if (widget.Mandatory) {
          valSchema = valSchema.required(requiredMsg);
        }
      }
  }
  if (valSchema) {
    let gridWidgetName = isPartOfGridWidget(widget, state);
    if (gridWidgetName) {
      let gridValSchema = _kaledo.gridValidationSchema;
      if (gridValSchema[gridWidgetName]) {
        let gridWidgetValSchema = gridValSchema[gridWidgetName];
        gridWidgetValSchema[widget.name] = valSchema;
      } else {
        gridValSchema[gridWidgetName] = { [widget.name]: valSchema };
      }
    } else {
      _kaledo.validationSchema[widget.name] = valSchema;
    }
  }
}

const isPartOfGridWidget = (widget, state) => {
  if (widget.parent) {
    if (state[widget.parent]) {
      let type = state[widget.parent].type;
      if (type === "GridWidget") {
        return widget.parent;
      }
    }
  }
  return null;
};

const validateFileSize = (widget, valueList) => {
  if (widget.SizeLimit) {
    if (!valueList) {
      return true;
    }
    let limit = widget.SizeLimit;
    let unit = limit.slice(limit.length - 1);
    let fileSizeNumber = limit.slice(0, limit.length - 1);
    let reducer = (size, fileObj) => size + fileObj.currentFileSize;
    let fileSize = valueList.reduce(reducer, 0);
    let allowedFileSize;
    if (unit === "k" || unit === "K") {
      allowedFileSize = parseInt(fileSizeNumber) * 1024;
    } else if (unit === "m" || unit === "M") {
      allowedFileSize = parseInt(fileSizeNumber) * 1024 * 1024;
    }
    return allowedFileSize > fileSize;
  }
  return true;
};

const validateFileType = (widget, fileObj) => {
  let allowedExtensions = [];
  let fileName = fileObj.value;
  let fileExtension = fileName.split(".").pop();
  if (widget.type === "ImageWidget")
    allowedExtensions = ["png", "jpeg", "jpg", "gif", "svg"];
  else allowedExtensions = widget.AllowedExtensions.split(",");
  return allowedExtensions.includes(fileExtension);
};

export const isEmptyObject = obj => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

function dataCopyHandler(thisContext) {
  let props = thisContext.props;
  if (props.location.data || props.data) {
    let data;
    if (props.location.data) data = props.location.data;
    if (props.data) data = props.data;
    for (let key in data) {
      if (key !== "reverseMappingInfo")
        thisContext.state[key].value = data[key];
    }
  }
  if (props.location.reverseData) {
    let { reverseData } = props.location;
    for (let key in reverseData.values) {
      thisContext.state[key].value = reverseData.values[key];
    }
    setTimeout(() => {
      delete props.location.reverseData;
    }, 0);
  }
}

export function initForm(thisContext) {
  populateDefConfProps(thisContext.state);
  _kaledo.validationSchema = {};
  _kaledo.gridValidationSchema = {};
  _kaledo.initialValues = {};
  _kaledo.t = thisContext.context.t;
  _kaledo.guiModuleName = thisContext.state.i18Key.split(".")[0];
  dataCopyHandler(thisContext);
  let state = thisContext.state;
  let m = _kaledo.guiModuleName;
  state.Label = thisContext.context.t(`${m}:${state.windowName}_title`);

  for (let key in state) {
    let widget = state[key];
    if (widget && typeof widget === "object") {
      createValidationSchema(widget, state, thisContext);
      switch (widget.type) {
        case "GridWidget":
          _kaledo.initialValues[widget.name] = [];
          if (widget.Pagination && widget.paginationService) {
            configurePaginationService.call(thisContext, widget);
          }
          break;
        case "AutocompleteWidget":
        case "FileWidget":
		case "ImageWidget":
        case "CheckBoxGroupFieldWidget":
        case "ChartWidget":
          if (Array.isArray(widget.value)) {
            _kaledo.initialValues[widget.name] = widget.value;
          } else {
            _kaledo.initialValues[widget.name] = [];
          }
          break;
        case "CheckBoxWidget":
          if (widget.value) {
            _kaledo.initialValues[widget.name] = true;
          } else {
            _kaledo.initialValues[widget.name] = false;
          }
          break;
        case "ListSelectDeselectFieldWidget":
        case "ListBoxFieldWidget":
        case "DropDownFieldWidget":
          if (
            widget.type === "ListSelectDeselectFieldWidget" ||
            widget.type === "ListBoxFieldWidget"
          ) {
            if (Array.isArray(widget.value)) {
              _kaledo.initialValues[widget.name] = widget.value;
            } else {
              _kaledo.initialValues[widget.name] = [];
            }
          } else {
            if (widget.value) {
              _kaledo.initialValues[widget.name] = widget.value;
            }
          }
          if (
            widget.DataProviderForDropDown === "ReferenceMetaData" ||
            widget.DataProviderForListBox === "ReferenceMetaData"
          ) {
            if (widget.driver && widget.driver !== "") {
              var driver = widget.driver;
              let serviceObj = {};
			  if (thisContext._buttonServices) {
                  let btnServiceobj = thisContext._buttonServices[driver];
                  if (btnServiceobj === undefined) {
                    thisContext._buttonServices[driver] = {
                      DEFAULT : []
                    }
                  }
              }
              serviceObj["IN"] = {};
              serviceObj["OUT"] = {};
              serviceObj["DESC"] = {
                ownerComp: Object.keys(_kaledo["compRoot"])[0],
                isSpringBoot: true,
                produces: "application/json",
                consumes: "text/plain",
                type: "GET",
                path: "getDependentOptions",
                versioningRequired: false,
                paginationRequired: false,
                controller: "reference-meta-data",
                domainName: "RequestParam",
                domainName__Multiplicity: false,
                languageId: "RequestParam",
                languageId__Multiplicity: false,
                driverDomainName: "RequestParam",
                driverDomainName__Multiplicity: false,
                driverValue: "RequestParam",
                driverValue__Multiplicity: false
              };
              thisContext._winServices[
                thisContext.state.windowName + "#getDependentOptions"
              ] = serviceObj;
              thisContext._buttonServices[driver]["DEFAULT"].push(
                thisContext.state.windowName +
                  "#getDependentOptions#" +
                  "Change@@@" +
                  widget.key +
                  ":" +
                  widget.name
              );
            } else {
              let serviceObj = {};
              serviceObj["IN"] = {};
              serviceObj["OUT"] = {};
              serviceObj["DESC"] = {
                ownerComp: Object.keys(_kaledo["compRoot"])[0],
                isSpringBoot: true,
                produces: "application/json",
                consumes: "text/plain",
                type: "GET",
                path: "getAllOptions",
                versioningRequired: false,
                paginationRequired: false,
                controller: "reference-meta-data",
                domainName: "RequestParam",
                domainName__Multiplicity: false,
                languageId: "RequestParam",
                languageId__Multiplicity: false
              };
              thisContext._winServices[
                thisContext.state.windowName + "#getAllOptions"
              ] = serviceObj;
              thisContext._buttonServices[thisContext.state.windowName][
                "DEFAULT"
              ].push(
                thisContext.state.windowName +
                  "#getAllOptions#" +
                  "Open@@@" +
                  widget.key +
                  ":" +
                  widget.name
              );
            }
          }
          break;
        case "TabWidget":
          let tabContainer = thisContext.state[widget.parent];
          if (!tabContainer.activeTab) {
            tabContainer.activeTab = widget.name;
          }
          break;
        default:
          _kaledo.initialValues[widget.name] = widget.value;
      }
      widget.Label = thisContext.context.t(
        `${m}:${state.windowName}_${widget.name}`
      );
    }
  }
  if (!isEmptyObject(_kaledo.gridValidationSchema)) {
    let gridValSchemas = _kaledo.gridValidationSchema;
    for (let gridName in gridValSchemas) {
      _kaledo.validationSchema[gridName] = array().of(
        object(gridValSchemas[gridName])
      );
    }
  }
}

function configurePaginationService(gridWidget) {
  if (this._buttonServices && this._buttonServices[gridWidget.name]) {
    let gridServiceObj = this._buttonServices[gridWidget.name];
    if (!gridServiceObj.DEFAULT) {
      gridServiceObj.DEFAULT = [];
    }
    gridServiceObj.DEFAULT.push(gridWidget.paginationService);
  }
}

function populateDefConfProps(state){
  for (let key in state) {
    let widget = state[key];
    if (widget && typeof widget === "object") {
        let defaultConfProp = {};
        let widgetObjWithDefVal = DefaultPropsData[widget.type];
        for(let prop in widgetObjWithDefVal){
          if(!defaultConfProp.hasOwnProperty(prop))
              defaultConfProp[prop] = widgetObjWithDefVal[prop];
        }
        if(defaultConfProp.Height === "")
              defaultConfProp.Height = "20";
        if(defaultConfProp.Width === "")
              defaultConfProp.Width = "60";
       for(let prop in defaultConfProp){
         if(!state[key].hasOwnProperty(prop))
            state[key][prop] = defaultConfProp[prop];
        }
      }
    }
}
export const convertStringToJsonObject = valueListString => {
  valueListString = valueListString.replace("{", "");
  valueListString = valueListString.replace("}", "");
  var valueListObj = {};
  var optionList = valueListString.split(",");
  for (var optionObjKey in optionList) {
    var optionObj = optionList[optionObjKey];
    var optionKey = optionObj.split(":")[0];
    var optionVal = optionObj.split(":")[1];
    valueListObj[optionKey] = optionVal;
  }

  var tempValueList = [];
  for (let desc in valueListObj) {
    let key = valueListObj[desc];
    let tempValueListObj = {
      key,
      description: desc
    };
    tempValueList.push(tempValueListObj);
  }
  return tempValueList;
};

export const getBase64FileType = fileExtension => { 
	var createHashMap={ 
		 ".323":"text/h323", 
		 ".3g2":"video/3gpp2", 
		 ".3gp":"video/3gpp", 
		 ".3gp2":"video/3gpp2", 
		 ".3gpp":"video/3gpp", 
		 ".7z":"application/x-7z-compressed", 
		 ".aa":"audio/audible", 
		 ".AAC": "audio/aac", 
		 ".aaf":"application/octet-stream", 
		 ".aax":"audio/vnd.audible.aax", 
		 ".ac3": "audio/ac3", 
		 ".aca":"application/octet-stream", 
		 ".accda":"application/msaccess.addin", 
		 ".accdb":"application/msaccess", 
		 ".accdc":"application/msaccess.cab", 
		 ".accde": "application/msaccess", 
		 ".accdr":"application/msaccess.runtime", 
		 ".accdt":"application/msaccess", 
		 ".accdw":"application/msaccess.webapplication", 
		 ".accft": "application/msaccess.ftemplate", 
		 ".acx":"application/internet-property-stream", 
		 ".AddIn":"text/xml", 
		 ".ade":"application/msaccess", 
		 ".adobebridge":"application/x-bridge-url", 
		 ".adp":"application/msaccess", 
		 ".ADT":"audio/vnd.dlna.adts", 
		 ".ADTS":"audio/aac", 
		 ".afm":"application/octet-stream", 
		 ".ai":"application/postscript", 
		 ".aif":"audio/x-aiff", 
		 ".aifc":"audio/aiff", 
		 ".aiff":"audio/aiff", 
		 ".air":"application/vnd.adobe.air-application-installer-package+zip", 
		 ".amc":"application/x-mpeg", 
		 ".application":"application/x-ms-application", 
		 ".art":"image/x-jg", 
		 ".asa":"application/xml", 
		 ".asax":"application/xml", 
		 ".ascx":"application/xml", 
		 ".asd":"application/octet-stream", 
		 ".asf":"video/x-ms-asf", 
		 ".ashx":"application/xml", 
		 ".asi":"application/octet-stream", 
		 ".asm":"text/plain", 
		 ".asmx":"application/xml", 
		 ".aspx":"application/xml", 
		 ".asr":"video/x-ms-asf", 
		 ".asx":"video/x-ms-asf", 
		 ".atom":"application/atom+xml", 
		 ".au":"audio/basic", 
		 ".avi":"video/x-msvideo", 
		 ".axs":"application/olescript", 
		 ".bas":"text/plain", 
		 ".bcpio":"application/x-bcpio", 
		 ".bin":"application/octet-stream", 
		 ".bmp":"image/bmp", 
		 ".c":"text/plain", 
		 ".cab":"application/octet-stream", 
		 ".caf":"audio/x-caf", 
		 ".calx":"application/vnd.ms-office.calx", 
		 ".cat":"application/vnd.ms-pki.seccat", 
		 ".cc":"text/plain", 
		 ".cd":"text/plain", 
		 ".cdda":"audio/aiff", 
		 ".cdf":"application/x-cdf", 
		 ".cer":"application/x-x509-ca-cert", 
		 ".chm":"application/octet-stream", 
		 ".class":"application/x-java-applet", 
		 ".clp":"application/x-msclip", 
		 ".cmx":"image/x-cmx", 
		 ".cnf":"text/plain", 
		 ".cod":"image/cis-cod", 
		 ".config":"application/xml", 
		 ".contact":"text/x-ms-contact", 
		 ".coverage":"application/xml", 
		 ".cpio":"application/x-cpio", 
		 ".cpp":"text/plain", 
		 ".crd":"application/x-mscardfile", 
		 ".crl":"application/pkix-crl", 
		 ".crt":"application/x-x509-ca-cert", 
		 ".cs":"text/plain", 
		 ".csdproj":"text/plain", 
		 ".csh":"application/x-csh", 
		 ".csproj":"text/plain", 
		 ".css":"text/css", 
		 ".csv":"text/csv", 
		 ".cur":"application/octet-stream", 
		 ".cxx":"text/plain", 
		 ".dat":"application/octet-stream", 
		 ".datasource":"application/xml", 
		 ".dbproj":"text/plain", 
		 ".dcr":"application/x-director", 
		 ".def":"text/plain", 
		 ".deploy":"application/octet-stream", 
		 ".der":"application/x-x509-ca-cert", 
		 ".dgml":"application/xml", 
		 ".dib":"image/bmp", 
		 ".dif":"video/x-dv", 
		 ".dir":"application/x-director", 
		 ".disco":"text/xml", 
		 ".dll":"application/x-msdownload", 
		 ".dll.config":"text/xml", 
		 ".dlm":"text/dlm", 
		 ".doc":"application/msword", 
		 ".docm":"application/vnd.ms-word.document.macroEnabled.12", 
		 ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
		 ".dot":"application/msword", 
		 ".dotm":"application/vnd.ms-word.template.macroEnabled.12", 
		 ".dotx": "application/vnd.openxmlformats-officedocument.wordprocessingml.template", 
		 ".dsp":"application/octet-stream", 
		 ".dsw":"text/plain", 
		 ".dtd":"text/xml", 
		 ".dtsConfig":"text/xml", 
		 ".dv":"video/x-dv", 
		 ".dvi":"application/x-dvi", 
		 ".dwf":"drawing/x-dwf", 
		 ".dwp":"application/octet-stream", 
		 ".dxr":"application/x-director", 
		 ".eml":"message/rfc822", 
		 ".emz":"application/octet-stream", 
		 ".eot":"application/octet-stream", 
		 ".eps":"application/postscript", 
		 ".etl":"application/etl", 
		 ".etx":"text/x-setext", 
		 ".evy":"application/envoy", 
		 ".exe":"application/octet-stream", 
		 ".exe.config":"text/xml", 
		 ".fdf":"application/vnd.fdf", 
		 ".fif":"application/fractals", 
		 ".filters":"Application/xml", 
		 ".fla":"application/octet-stream", 
		 ".flr":"x-world/x-vrml", 
		 ".flv":"video/x-flv", 
		 ".fsscript":"application/fsharp-script", 
		 ".fsx":"application/fsharp-script", 
		 ".generictest":"application/xml", 
		 ".gif":"image/gif", 
		 ".group":"text/x-ms-group", 
		 ".gsm":"audio/x-gsm", 
		 ".gtar":"application/x-gtar", 
		 ".gz":"application/x-gzip", 
		 ".h":"text/plain", 
		 ".hdf":"application/x-hdf", 
		 ".hdml":"text/x-hdml", 
		 ".hhc":"application/x-oleobject", 
		 ".hhk":"application/octet-stream", 
		 ".hhp":"application/octet-stream", 
		 ".hlp":"application/winhlp", 
		 ".hpp":"text/plain", 
		 ".hqx":"application/mac-binhex40", 
		 ".hta":"application/hta", 
		 ".htc":"text/x-component", 
		 ".htm":"text/html", 
		 ".html":"text/html", 
		 ".htt":"text/webviewhtml", 
		 ".hxa":"application/xml", 
		 ".hxc":"application/xml", 
		 ".hxd":"application/octet-stream", 
		 ".hxe":"application/xml", 
		 ".hxf":"application/xml", 
		 ".hxh":"application/octet-stream", 
		 ".hxi":"application/octet-stream", 
		 ".hxk":"application/xml", 
		 ".hxq":"application/octet-stream", 
		 ".hxr":"application/octet-stream", 
		 ".hxs":"application/octet-stream", 
		 ".hxt":"text/html", 
		 ".hxv":"application/xml", 
		 ".hxw":"application/octet-stream", 
		 ".hxx":"text/plain", 
		 ".i":"text/plain", 
		 ".ico":"image/x-icon", 
		 ".ics":"application/octet-stream", 
		 ".idl":"text/plain", 
		 ".ief":"image/ief", 
		 ".iii":"application/x-iphone", 
		 ".inc":"text/plain", 
		 ".inf":"application/octet-stream", 
		 ".inl":"text/plain", 
		 ".ins":"application/x-internet-signup", 
		 ".ipa":"application/x-itunes-ipa", 
		 ".ipg":"application/x-itunes-ipg", 
		 ".ipproj":"text/plain", 
		 ".ipsw":"application/x-itunes-ipsw", 
		 ".iqy":"text/x-ms-iqy", 
		 ".isp":"application/x-internet-signup", 
		 ".ite":"application/x-itunes-ite", 
		 ".itlp":"application/x-itunes-itlp", 
		 ".itms":"application/x-itunes-itms", 
		 ".itpc":"application/x-itunes-itpc", 
		 ".IVF":"video/x-ivf", 
		 ".jar":"application/java-archive", 
		 ".java":"application/octet-stream", 
		 ".jck":"application/liquidmotion", 
		 ".jcz":"application/liquidmotion", 
		 ".jfif":"image/pjpeg", 
		 ".jnlp":"application/x-java-jnlp-file", 
		 "b":"application/octet-stream", 
		 ".jpe":"image/jpeg", 
		 ".jpeg":"image/jpeg", 
		 ".jpg":"image/jpeg", 
		 ".js":"application/x-javascript", 
		 ".json":"application/json", 
		 ".jsx":"text/jscript", 
		 ".jsxbin":"text/plain", 
		 ".latex":"application/x-latex", 
		 ".library-ms":"application/windows-library+xml", 
		 ".lit":"application/x-ms-reader", 
		 ".loadtest":"application/xml", 
		 ".lpk":"application/octet-stream", 
		 ".lsf":"video/x-la-asf", 
		 ".lst":"text/plain", 
		 ".lsx":"video/x-la-asf", 
		 ".lzh":"application/octet-stream", 
		 ".m13":"application/x-msmediaview", 
		 ".m14":"application/x-msmediaview", 
		 ".m1v":"video/mpeg", 
		 ".m2t":"video/vnd.dlna.mpeg-tts", 
		 ".m2ts":"video/vnd.dlna.mpeg-tts", 
		 ".m2v":"video/mpeg", 
		 ".m3u":"audio/x-mpegurl", 
		 ".m3u8":"audio/x-mpegurl", 
		 ".m4a":"audio/m4a", 
		 ".m4b":"audio/m4b", 
		 ".m4p":"audio/m4p", 
		 ".m4r":"audio/x-m4r", 
		 ".m4v":"video/x-m4v", 
		 ".mac":"image/x-macpaint", 
		 ".mak":"text/plain", 
		 ".man":"application/x-troff-man", 
		 ".manifest":"application/x-ms-manifest", 
		 ".map":"text/plain", 
		 ".master":"application/xml", 
		 ".mda":"application/msaccess", 
		 ".mdb":"application/x-msaccess", 
		 ".mde":"application/msaccess", 
		 ".mdp":"application/octet-stream", 
		 ".me":"application/x-troff-me", 
		 ".mfp":"application/x-shockwave-flash", 
		 ".mht":"message/rfc822", 
		 ".mhtml":"message/rfc822", 
		 ".mid":"audio/mid", 
		 ".midi":"audio/mid", 
		 ".mix":"application/octet-stream", 
		 ".mk":"text/plain", 
		 ".mmf":"application/x-smaf", 
		 ".mno":"text/xml", 
		 ".mny":"application/x-msmoney", 
		 ".mod":"video/mpeg", 
		 ".mov":"video/quicktime", 
		 ".movie":"video/x-sgi-movie", 
		 ".mp2":"video/mpeg", 
		 ".mp2v":"video/mpeg", 
		 ".mp3":"audio/mpeg", 
		 ".mp4":"video/mp4", 
		 ".mp4v":"video/mp4", 
		 ".mpa":"video/mpeg", 
		 ".mpe":"video/mpeg", 
		 ".mpeg":"video/mpeg", 
		 ".mpf":"application/vnd.ms-mediapackage", 
		 ".mpg":"video/mpeg", 
		 ".mpp":"application/vnd.ms-project", 
		 ".mpv2":"video/mpeg", 
		 ".mqv":"video/quicktime", 
		 ".ms":"application/x-troff-ms", 
		 ".msi":"application/octet-stream", 
		 ".mso":"application/octet-stream", 
		 ".mts":"video/vnd.dlna.mpeg-tts", 
		 ".mtx":"application/xml", 
		 ".mvb":"application/x-msmediaview", 
		 ".mvc":"application/x-miva-compiled", 
		 ".mxp":"application/x-mmxp", 
		 ".nc":"application/x-netcdf", 
		 ".nsc":"video/x-ms-asf", 
		 ".nws":"message/rfc822", 
		 ".ocx":"application/octet-stream", 
		 ".oda":"application/oda", 
		 ".odc":"text/x-ms-odc", 
		 ".odh":"text/plain", 
		 ".odl":"text/plain", 
		 ".odp":"application/vnd.oasis.opendocument.presentation", 
		 ".ods":"application/oleobject", 
		 ".odt":"application/vnd.oasis.opendocument.text", 
		 ".one":"application/onenote", 
		 ".onea":"application/onenote", 
		 ".onepkg":"application/onenote", 
		 ".onetmp":"application/onenote", 
		 ".onetoc":"application/onenote", 
		 ".onetoc2":"application/onenote", 
		 ".orderedtest":"application/xml", 
		 ".osdx":"application/opensearchdescription+xml", 
		 ".p10":"application/pkcs10", 
		 ".p12":"application/x-pkcs12", 
		 ".p7b":"application/x-pkcs7-certificates", 
		 ".p7c":"application/pkcs7-mime", 
		 ".p7m":"application/pkcs7-mime", 
		 ".p7r":"application/x-pkcs7-certreqresp", 
		 ".p7s":"application/pkcs7-signature", 
		 ".pbm":"image/x-portable-bitmap", 
		 ".pcast":"application/x-podcast", 
		 ".pct":"image/pict", 
		 ".pcx":"application/octet-stream", 
		 ".pcz":"application/octet-stream", 
		 ".pdf":"application/pdf", 
		 ".pfb":"application/octet-stream", 
		 ".pfm":"application/octet-stream", 
		 ".pfx":"application/x-pkcs12", 
		 ".pgm":"image/x-portable-graymap", 
		 ".pic":"image/pict", 
		 ".pict":"image/pict", 
		 ".pkgdef":"text/plain", 
		 ".pkgundef":"text/plain", 
		 ".pko":"application/vnd.ms-pki.pko", 
		 ".pls":"audio/scpls", 
		 ".pma":"application/x-perfmon", 
		 ".pmc":"application/x-perfmon", 
		 ".pml":"application/x-perfmon", 
		 ".pmr":"application/x-perfmon", 
		 ".pmw":"application/x-perfmon", 
		 ".png":"image/png", 
		 ".pnm":"image/x-portable-anymap", 
		 ".pnt":"image/x-macpaint", 
		 ".pntg":"image/x-macpaint", 
		 ".pnz":"image/png", 
		 ".pot":"application/vnd.ms-powerpoint", 
		 ".potm": "application/vnd.ms-powerpoint.template.macroEnabled.12", 
		 ".potx": "application/vnd.openxmlformats-officedocument.presentationml.template", 
		 ".ppa":"application/vnd.ms-powerpoint", 
		 ".ppam":"application/vnd.ms-powerpoint.addin.macroEnabled.12", 
		 ".ppm":"image/x-portable-pixmap", 
		 ".pps":"application/vnd.ms-powerpoint", 
		 ".ppsm": "application/vnd.ms-powerpoint.slideshow.macroEnabled.12", 
		 ".ppsx": "application/vnd.openxmlformats-officedocument.presentationml.slideshow", 
		 ".ppt":"application/vnd.ms-powerpoint", 
		 ".pptm": "application/vnd.ms-powerpoint.presentation.macroEnabled.12", 
		 ".pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation", 
		 ".prf":"application/pics-rules", 
		 ".prm":"application/octet-stream", 
		 ".prx":"application/octet-stream", 
		 ".ps":"application/postscript", 
		 ".psc1":"application/PowerShell", 
		 ".psd":"application/octet-stream", 
		 ".psess":"application/xml", 
		 ".psm":"application/octet-stream", 
		 ".psp":"application/octet-stream", 
		 ".pub":"application/x-mspublisher", 
		 ".pwz":"application/vnd.ms-powerpoint", 
		 ".qht":"text/x-html-insertion", 
		 ".qhtm":"text/x-html-insertion", 
		 ".qt":"video/quicktime", 
		 ".qti":"image/x-quicktime", 
		 ".qtif":"image/x-quicktime", 
		 ".qtl":"application/x-quicktimeplayer", 
		 ".qxd":"application/octet-stream", 
		 ".ra":"audio/x-pn-realaudio", 
		 ".ram":"audio/x-pn-realaudio", 
		 ".rar":"application/octet-stream", 
		 ".ras":"image/x-cmu-raster", 
		 ".rat":"application/rat-file", 
		 ".rc":"text/plain", 
		 ".rc2":"text/plain", 
		 ".rct":"text/plain", 
		 ".rdlc":"application/xml", 
		 ".resx":"application/xml", 
		 ".rf":"image/vnd.rn-realflash", 
		 ".rgb":"image/x-rgb", 
		 ".rgs":"text/plain", 
		 ".rm":"application/vnd.rn-realmedia", 
		 ".rmi":"audio/mid", 
		 ".rmp":"application/vnd.rn-rn_music_package", 
		 ".roff":"application/x-troff", 
		 ".rpm":"audio/x-pn-realaudio-plugin", 
		 ".rqy":"text/x-ms-rqy", 
		 ".rtf":"application/rtf", 
		 ".rtx":"text/richtext", 
		 ".ruleset":"application/xml", 
		 ".s":"text/plain", 
		 ".safariextz":"application/x-safari-safariextz", 
		 ".scd":"application/x-msschedule", 
		 ".sct":"text/scriptlet", 
		 ".sd2":"audio/x-sd2", 
		 ".sdp":"application/sdp", 
		 ".sea":"application/octet-stream", 
		 ".searchConnector-ms":"application/windows-search-connector+xml", 
		 ".setpay":"application/set-payment-initiation", 
		 ".setreg":"application/set-registration-initiation", 
		 ".settings":"application/xml", 
		 ".sgimb":"application/x-sgimb", 
		 ".sgml":"text/sgml", 
		 ".sh":"application/x-sh", 
		 ".shar":"application/x-shar", 
		 ".shtml":"text/html", 
		 ".sit":"application/x-stuffit", 
		 ".sitemap":"application/xml", 
		 ".skin":"application/xml", 
		 ".sldm":"application/vnd.ms-powerpoint.slide.macroEnabled.12", 
		 ".sldx": "application/vnd.openxmlformats-officedocument.presentationml.slide", 
		 ".slk":"application/vnd.ms-excel", 
		 ".sln":"text/plain", 
		 ".slupkg-ms":"application/x-ms-license", 
		 ".smd":"audio/x-smd", 
		 ".smi":"application/octet-stream", 
		 ".smx":"audio/x-smd", 
		 ".smz":"audio/x-smd", 
		 ".snd":"audio/basic", 
		 ".snippet":"application/xml", 
		 ".snp":"application/octet-stream", 
		 ".sol":"text/plain", 
		 ".sor":"text/plain", 
		 ".spc":"application/x-pkcs7-certificates", 
		 ".spl":"application/futuresplash", 
		 ".src":"application/x-wais-source", 
		 ".srf":"text/plain", 
		 ".SSISDeploymentManifest":"text/xml", 
		 ".ssm":"application/streamingmedia", 
		 ".sst":"application/vnd.ms-pki.certstore", 
		 ".stl":"application/vnd.ms-pki.stl", 
		 ".sv4cpio":"application/x-sv4cpio", 
		 ".sv4crc":"application/x-sv4crc", 
		 ".svc":"application/xml", 
		 ".swf":"application/x-shockwave-flash", 
		 ".t":"application/x-troff", 
		 ".tar":"application/x-tar", 
		 ".tcl":"application/x-tcl", 
		 ".testrunconfig":"application/xml", 
		 ".testsettings":"application/xml", 
		 ".tex":"application/x-tex", 
		 ".texi":"application/x-texinfo", 
		 ".texinfo":"application/x-texinfo", 
		 ".tgz":"application/x-compressed", 
		 ".thmx":"application/vnd.ms-officetheme", 
		 ".thn":"application/octet-stream", 
		 ".tif":"image/tiff", 
		 ".tiff":"image/tiff", 
		 ".tlh":"text/plain", 
		 ".tli":"text/plain", 
		 ".toc":"application/octet-stream", 
		 ".tr":"application/x-troff", 
		 ".trm":"application/x-msterminal", 
		 ".trx":"application/xml", 
		 ".ts":"video/vnd.dlna.mpeg-tts", 
		 ".tsv":"text/tab-separated-values", 
		 ".ttf":"application/octet-stream", 
		 ".tts":"video/vnd.dlna.mpeg-tts", 
		 ".txt":"text/plain", 
		 ".u32":"application/octet-stream", 
		 ".uls":"text/iuls", 
		 ".user":"text/plain", 
		 ".ustar":"application/x-ustar", 
		 ".vb":"text/plain", 
		 ".vbdproj":"text/plain", 
		 ".vbk":"video/mpeg", 
		 ".vbproj":"text/plain", 
		 ".vbs":"text/vbscript", 
		 ".vcf":"text/x-vcard", 
		 ".vcproj":"Application/xml", 
		 ".vcs":"text/plain", 
		 ".vcxproj":"Application/xml", 
		 ".vddproj":"text/plain", 
		 ".vdp":"text/plain", 
		 ".vdproj":"text/plain", 
		 ".vdx":"application/vnd.ms-visio.viewer", 
		 ".vml":"text/xml", 
		 ".vscontent":"application/xml", 
		 ".vsct":"text/xml", 
		 ".vsd":"application/vnd.visio", 
		 ".vsi":"application/ms-vsi", 
		 ".vsix":"application/vsix", 
		 ".vsixlangpack":"text/xml", 
		 ".vsixmanifest":"text/xml", 
		 ".vsmdi":"application/xml", 
		 ".vspscc":"text/plain", 
		 ".vss":"application/vnd.visio", 
		 ".vsscc":"text/plain", 
		 ".vssettings":"text/xml", 
		 ".vssscc":"text/plain", 
		 ".vst":"application/vnd.visio", 
		 ".vstemplate":"text/xml", 
		 ".vsto":"application/x-ms-vsto", 
		 ".vsw":"application/vnd.visio", 
		 ".vsx":"application/vnd.visio", 
		 ".vtx":"application/vnd.visio", 
		 ".wav":"audio/wav", 
		 ".wave":"audio/wav", 
		 ".wax":"audio/x-ms-wax", 
		 ".wbk":"application/msword", 
		 ".wbmp":"image/vnd.wap.wbmp", 
		 ".wcm":"application/vnd.ms-works", 
		 ".wdb":"application/vnd.ms-works", 
		 ".wdp":"image/vnd.ms-photo", 
		 ".webarchive":"application/x-safari-webarchive", 
		 ".webtest":"application/xml", 
		 ".wiq":"application/xml", 
		 ".wiz":"application/msword", 
		 ".wks":"application/vnd.ms-works", 
		 ".WLMP":"application/wlmoviemaker", 
		 ".wlpginstall":"application/x-wlpg-detect", 
		 ".wlpginstall3":"application/x-wlpg3-detect", 
		 ".wm":"video/x-ms-wm", 
		 ".wma":"audio/x-ms-wma", 
		 ".wmd":"application/x-ms-wmd", 
		 ".wmf":"application/x-msmetafile", 
		 ".wml":"text/vnd.wap.wml", 
		 ".wmlc":"application/vnd.wap.wmlc", 
		 ".wmls":"text/vnd.wap.wmlscript", 
		 ".wmlsc":"application/vnd.wap.wmlscriptc", 
		 ".wmp":"video/x-ms-wmp", 
		 ".wmv":"video/x-ms-asf", 
		 ".wmx":"video/x-ms-wmx", 
		 ".wmz":"application/x-ms-wmz", 
		 ".wpl":"application/vnd.ms-wpl", 
		 ".wps":"application/vnd.ms-works", 
		 ".wri":"application/x-mswrite", 
		 ".wrl":"x-world/x-vrml", 
		 ".wrz":"x-world/x-vrml", 
		 ".wsc":"text/scriptlet", 
		 ".wsdl":"text/xml", 
		 ".wvx":"video/x-ms-wvx", 
		 ".x":"application/directx", 
		 ".xaf":"x-world/x-vrml", 
		 ".xaml":"application/xaml+xml", 
		 ".xap":"application/x-silverlight-app", 
		 ".xbap":"application/x-ms-xbap", 
		 ".xbm":"image/x-xbitmap", 
		 ".xdr":"text/plain", 
		 ".xht":"application/xhtml+xml", 
		 ".xhtml":"application/xhtml+xml", 
		 ".xla":"application/vnd.ms-excel", 
		 ".xlam":"application/vnd.ms-excel.addin.macroEnabled.12", 
		 ".xlc":"application/vnd.ms-excel", 
		 ".xld":"application/vnd.ms-excel", 
		 ".xlk":"application/vnd.ms-excel", 
		 ".xll":"application/vnd.ms-excel", 
		 ".xlm":"application/vnd.ms-excel", 
		 ".xls":"application/vnd.ms-excel", 
		 ".xlsb": "application/vnd.ms-excel.sheet.binary.macroEnabled.12", 
		 ".xlsm":"application/vnd.ms-excel.sheet.macroEnabled.12", 
		 ".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
		 ".xlt":"application/vnd.ms-excel", 
		 ".xltm":"application/vnd.ms-excel.template.macroEnabled.12", 
		 ".xltx": "application/vnd.openxmlformats-officedocument.spreadsheetml.template", 
		 ".xlw":"application/vnd.ms-excel", 
		 ".xml":"text/xml", 
		 ".xmta":"application/xml", 
		 ".xof":"x-world/x-vrml", 
		 ".XOML":"text/plain", 
		 ".xpm":"image/x-xpixmap", 
		 ".xps":"application/vnd.ms-xpsdocument", 
		 ".xrm-ms":"text/xml", 
		 ".xsc":"application/xml", 
		 ".xsd":"text/xml", 
		 ".xsf":"text/xml", 
		 ".xsl":"text/xml", 
		 ".xslt":"text/xml", 
		 ".xsn":"application/octet-stream", 
		 ".xss":"application/xml" ,".xtp":"application/octet-stream" ,".xwd":"image/x-xwindowdump", 
		 ".z":"application/x-compress" ,".zip":"application/x-zip-compressed" 
	};
	if(createHashMap[fileExtension]){ 
		return createHashMap[fileExtension]; 
	} else {
		return ""; 
	}
}; 

export const downloadFile = fileObj => {
  var _fileData = "";
  var _fileName = "";

  _fileData = fileObj.data;
  _fileName = fileObj.value;

  var selectedFileType = "text/plain";
  var indexOfDot = _fileName.lastIndexOf(".");
  if (indexOfDot !== -1 && indexOfDot < _fileName.length) {
    var selectedFileExtension = _fileName.substring(indexOfDot);
    selectedFileType = getBase64FileType(selectedFileExtension);
  }

  _fileData = "data:" + selectedFileType + ";base64," + _fileData;
  var BASE64_MARKER = ";base64,";
  var base64Index = _fileData.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = _fileData.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  let array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  var blob = new Blob([array], { type: selectedFileType });

  var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
  //--for IE Compatibility check
  // var objappVersion = navigator.appVersion;
  var objAgent = navigator.userAgent;
  var objbrowserName = navigator.appName;
  if (objAgent.indexOf("Trident") !== -1 && objAgent.indexOf("MSIE") !== -1) {
    objbrowserName = "Microsoft Internet Explorer";
  }
  var link = document.createElement("a");
  if (link.download !== undefined) {
    // will work for Browsers that support HTML5 download attribute
    link.setAttribute("href", objectUrl);
    link.setAttribute("download", _fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    //if browser is IE--
    if (objbrowserName === "Microsoft Internet Explorer") {
      if (_fileData != null && navigator.msSaveBlob)
        return navigator.msSaveBlob(blob, _fileName);
    }
  }
};

export const onFileChange = (event, value, widgetObj, onEvent) => {
  if (widgetObj.type === "FileWidget" && !widgetObj.Multiplicity && value.length >= 1) {
    return;
  }
  if (widgetObj.type === "ImageWidget" && value.length >= 1) {
    value.splice(0, 1);
    value = [...value];
  }
  let e = {
    type: "change",
    target: {
      name: event.target.name,
      id: event.target.id
    }
  };
  let fileList = event.target.files;
  if (!fileList.length) {
    e.target.value = value;
    onEvent(e);
    return;
  }
  let file = fileList[0];
  let fileName = file.name;
  let fileExtension = fileName.split(".").pop();
  let b64fileType = getBase64FileType("." + fileExtension);
  let fileType = "";
  if (b64fileType.indexOf("image") !== -1) {
    fileType = "image";
  } else if (b64fileType.indexOf("audio") !== -1) {
    fileType = "audio";
  } else if (b64fileType.indexOf("video") !== -1) {
    fileType = "video";
  } else {
    fileType = "other";
  }
  let fileReader = new FileReader();

  fileReader.onloadend = () => {
    let result = fileReader.result;
    let fileData = result.substring(result.indexOf(",") + 1);
    let fileObj = {
      data: fileData,
      b64data: result,
      value: file.name,
      currentFileSize: file.size,
      b64fileType: b64fileType,
      fileType: fileType
    };
    e.target.value = [...value, fileObj];
    onEvent(e);
  };
  fileReader.readAsDataURL(file);
};

export const removeFile = (index, value, col, name, onEvent, fileUploadRef) => {
  value.splice(index, 1);
  value = [...value];
  let e = {
    type: "change",
    target: {
      name: name,
      id: col.name,
      value
    }
  };
  onEvent(e);
  fileUploadRef.current.value = "";
  fileUploadRef.current.dispatchEvent(new Event("change"));
};

export const getFileRenderTemplate = (
  value,
  widgetObj,
  rowID$,
  nameAttr,
  onEvent,
  fileUploadRef,
  isValueTemplate,
  state,
  radioOnChange
) => {
  if (!rowID$) {
    rowID$ = "";
  }
  let getRenderOnScreenTemplate = (fileObj, index, radioVal) => {
    if (
      widgetObj.ShowAs === "RenderOnScreen" &&
      state.selectedRadio === radioVal
    ) {
      switch (fileObj.fileType) {
        case "other":
          return (
            <div
              id={widgetObj.name + "_" + rowID$ + index}
              className={widgetObj.name + "_RenderOnScreen"}
            >
              <object height="100%" width="100%">
                <source type={fileObj.b64fileType} src={fileObj.b64data} />
              </object>
            </div>
          );
        case "audio":
          return (
            <div
              id={widgetObj.name + "_" + rowID$ + index}
              className={widgetObj.name + "_RenderOnScreen"}
            >
              <audio
                height="100%"
                width="100%"
                src={fileObj.b64data}
                type={fileObj.b64fileType}
                controls
              ></audio>
            </div>
          );
        case "video":
          return (
            <div
              id={widgetObj.name + "_" + rowID$ + index}
              className={widgetObj.name + "_RenderOnScreen"}
            >
              <video
                height="100%"
                width="100%"
                src={fileObj.b64data}
                type={fileObj.b64fileType}
                controls
              ></video>
            </div>
          );
        case "image":
          return (
            <div
              id={widgetObj.name + "_" + rowID$ + index}
              className={widgetObj.name + "_RenderOnScreen"}
            >
              <img
                alt={widgetObj.AltText}
                height="100%"
                width="100%"
                src={fileObj.b64data}
                type={fileObj.b64fileType}
                tabIndex="0"
              ></img>
            </div>
          );
        default:
          return <div></div>;
      }
    }
    return "";
  };

  return value.map((fileObj, index) => (
    <div key={"file" + index}>
      <div className="bg-secondary p-1 d-flex align-items-center">
        <span
          className={
            widgetObj.ShowAs === "RenderOnScreen" && value.length > 1
              ? "d-block"
              : "d-none"
          }
        >
          <input
            className="fileRadioClass"
            type="radio"
            name={widgetObj.name + "_fileRadio" + rowID$ + index}
            value={widgetObj.name + "_" + rowID$ + index}
            checked={
              state.selectedRadio === widgetObj.name + "_" + rowID$ + index
            }
            onChange={radioOnChange}
          />
        </span>
        {(widgetObj.ShowAs === "Attachment" ||
          (widgetObj.ShowAs === "RenderOnScreen" &&
            (!isValueTemplate || (isValueTemplate && value.length > 1)))) && (
          <Button
            variant="link"
            className="fileLinkClass text-decoration-none px-0"
            data-testid="downoadBtn"
            onClick={e => {
              e.stopPropagation();
              downloadFile(fileObj);
            }}
          >
            {fileObj.value}
          </Button>
        )}
        {!isValueTemplate && (
          <Button
            variant="link"
            className="text-danger text-decoration-none remove-file"
            aria-label="Please click here to remove the image"
            data-testid="removeFile"
            onClick={e => {
              e.stopPropagation();
              removeFile(
                index,
                value,
                widgetObj,
                nameAttr,
                onEvent,
                fileUploadRef
              );
            }}
          >
            <i className="fa fa-times-circle"></i>
          </Button>
        )}
      </div>
      {getRenderOnScreenTemplate(
        fileObj,
        index,
        widgetObj.name + "_" + rowID$ + index
      )}
    </div>
  ));
};

export function prepareUIComponentObj(compObj, screenObj) {
  let compName = compObj.name;
  modifyCompObj(compName, compObj, screenObj);
}

function modifyCompObj(parentName, compObj, screenObj) {
  for (let key in screenObj) {
    let value = screenObj[key];
    if (value && value.parent && value.parent === parentName) {
      let widgetKey = key.substring(0, key.indexOf("uic"));
      compObj[widgetKey] = value;
      modifyCompObj(value.name, compObj, screenObj);
    }
  }
}
