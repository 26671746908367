/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  getData,
  setData,
  getValue,
  setValue,
  enable,
  disable,
  goTo
} from "../../shared/WindowImports";

import "./SecurityMenu.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_SecurityMenu(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  let userid = sessionStorage.getItem("userid");
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SecurityMenu",
    windowName: "SecurityMenu",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.SecurityMenu",
    // START_USER_CODE-USER_SecurityMenu_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Security Menu",
      scrCode: "PN0060A",
    },
    // END_USER_CODE-USER_SecurityMenu_PROPERTIES
    btnAddFunction: {
      name: "btnAddFunction",
      type: "ButtonWidget",
      parent: "grpbxFunctions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddFunction_PROPERTIES

      // END_USER_CODE-USER_btnAddFunction_PROPERTIES
    },
    btnAddGroup: {
      name: "btnAddGroup",
      type: "ButtonWidget",
      parent: "grpbxGroups",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddGroup_PROPERTIES

      // END_USER_CODE-USER_btnAddGroup_PROPERTIES
    },
    btnAddUser: {
      name: "btnAddUser",
      type: "ButtonWidget",
      parent: "grpbxUsers",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddUser_PROPERTIES

      // END_USER_CODE-USER_btnAddUser_PROPERTIES
    },
    btnClear: {
      name: "btnClear",
      type: "ButtonWidget",
      parent: "grpbxUserSearch",
      Label: "Clear",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClear_PROPERTIES

      // END_USER_CODE-USER_btnClear_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDeleteFunction: {
      name: "btnDeleteFunction",
      type: "ButtonWidget",
      parent: "grpbxFunctions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteFunction_PROPERTIES

      // END_USER_CODE-USER_btnDeleteFunction_PROPERTIES
    },
    btnDeleteGroup: {
      name: "btnDeleteGroup",
      type: "ButtonWidget",
      parent: "grpbxGroups",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteGroup_PROPERTIES

      // END_USER_CODE-USER_btnDeleteGroup_PROPERTIES
    },
    btnDeleteUser: {
      name: "btnDeleteUser",
      type: "ButtonWidget",
      parent: "grpbxUsers",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteUser_PROPERTIES

      // END_USER_CODE-USER_btnDeleteUser_PROPERTIES
    },
    btnEditFunction: {
      name: "btnEditFunction",
      type: "ButtonWidget",
      parent: "grpbxFunctions",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditFunction_PROPERTIES

      // END_USER_CODE-USER_btnEditFunction_PROPERTIES
    },
    btnEditGroup: {
      name: "btnEditGroup",
      type: "ButtonWidget",
      parent: "grpbxGroups",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditGroup_PROPERTIES

      // END_USER_CODE-USER_btnEditGroup_PROPERTIES
    },
    btnEditUser: {
      name: "btnEditUser",
      type: "ButtonWidget",
      parent: "grpbxUsers",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditUser_PROPERTIES

      // END_USER_CODE-USER_btnEditUser_PROPERTIES
    },
    btnReports: {
      name: "btnReports",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Reports",
      CharWidth: "17",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnReports_PROPERTIES

      // END_USER_CODE-USER_btnReports_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxUserSearch",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxUserSearch",
      Label: "Buying Point:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    grpbxFunctions: {
      name: "grpbxFunctions",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityMenu",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxFunctions_PROPERTIES

      // END_USER_CODE-USER_grpbxFunctions_PROPERTIES
    },
    grpbxGroups: {
      name: "grpbxGroups",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityMenu",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxGroups_PROPERTIES

      // END_USER_CODE-USER_grpbxGroups_PROPERTIES
    },
    grpbxUsers: {
      name: "grpbxUsers",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityMenu",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxUsers_PROPERTIES

      // END_USER_CODE-USER_grpbxUsers_PROPERTIES
    },
    grpbxUserSearch: {
      name: "grpbxUserSearch",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityMenu",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxUserSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxUserSearch_PROPERTIES
    },
    lblFunctions: {
      name: "lblFunctions",
      type: "LabelWidget",
      parent: "grpbxFunctions",
      Label: "Functions:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFunctions_PROPERTIES

      // END_USER_CODE-USER_lblFunctions_PROPERTIES
    },
    lblGroups: {
      name: "lblGroups",
      type: "LabelWidget",
      parent: "grpbxGroups",
      Label: "Groups",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGroups_PROPERTIES

      // END_USER_CODE-USER_lblGroups_PROPERTIES
    },
    lblUsers: {
      name: "lblUsers",
      type: "LabelWidget",
      parent: "grpbxUsers",
      Label: "Users:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblUsers_PROPERTIES

      // END_USER_CODE-USER_lblUsers_PROPERTIES
    },
    lblUserSearch: {
      name: "lblUserSearch",
      type: "LabelWidget",
      parent: "grpbxUserSearch",
      Label: "User Search",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblUserSearch_PROPERTIES

      // END_USER_CODE-USER_lblUserSearch_PROPERTIES
    },
    lstFunctions: {
      name: "lstFunctions",
      type: "ListBoxFieldWidget",
      parent: "grpbxFunctions",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstFunctions_PROPERTIES

      // END_USER_CODE-USER_lstFunctions_PROPERTIES
    },
    lstGroups: {
      name: "lstGroups",
      type: "ListBoxFieldWidget",
      parent: "grpbxGroups",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstGroups_PROPERTIES

      // END_USER_CODE-USER_lstGroups_PROPERTIES
    },
    lstUsers: {
      name: "lstUsers",
      type: "ListBoxFieldWidget",
      parent: "grpbxUsers",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstUsers_PROPERTIES
      // END_USER_CODE-USER_lstUsers_PROPERTIES
    },
    txtUserID: {
      name: "txtUserID",
      type: "TextBoxWidget",
      parent: "grpbxUserSearch",
      Label: "User ID:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUserID_PROPERTIES

      // END_USER_CODE-USER_txtUserID_PROPERTIES
    },
    txtUserName: {
      name: "txtUserName",
      type: "TextBoxWidget",
      parent: "grpbxUserSearch",
      Label: "User Name:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUserName_PROPERTIES

      // END_USER_CODE-USER_txtUserName_PROPERTIES
    },
    grpbxSecurityMenu: {
      name: "grpbxSecurityMenu",
      type: "GroupBoxWidget",
      parent: "SecurityMenu",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSecurityMenu_PROPERTIES

      // END_USER_CODE-USER_grpbxSecurityMenu_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "SecurityMenu",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#SecurityFunctionInquiry": {}
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#SecurityFunctionInquiry": {}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
  
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    const DatafromChildScreens = getData(thisObj, "DatafromChildScreensToSecurityMenu")
    if (DatafromChildScreens !== undefined && DatafromChildScreens !== null) {
      let valueList = thisObj.state.lstUsers.valueList;
      let obj = {};
      if (DatafromChildScreens != null && DatafromChildScreens?.screen != "SecurityGroup" && DatafromChildScreens?.screen != "FunctionInquiry") {// for frmSecurityUser
        if (DatafromChildScreens.condition == "Add") {
          obj = { key: DatafromChildScreens.lstUserId, description: DatafromChildScreens.lstUsers , index: (valueList.length - 1)};
          if (valueList !== undefined && valueList.length > 0) {
            valueList.push(obj);
          } else {
            valueList = [obj];
          }
        } else if (DatafromChildScreens.condition == "Update") {
          for (let i = 0; i < valueList.length; i++) {
            if (valueList[i].key === DatafromChildScreens.lstUserId) {
              valueList[i].description = DatafromChildScreens.lstUsers;
              break; // Exit the loop once the object is updated
            }
          }
        }
        thisObj.setState(current => {
          return {
            ...current,
            lstUsers: {
              ...state["lstUsers"],
              valueList: valueList
            }
          }
        })
        if (DatafromChildScreens.condition == "Add") {
          setValue(thisObj, 'lstUsers', [valueList.at(valueList.length - 1).key]);
          setTimeout(() => {
            var lstUsers = document.getElementById('lstUsers')
            lstUsers.scrollTop = lstUsers.scrollHeight;
            }, 200);
        }
      }

      //for frmSecurityFunctionInquiry
       if (DatafromChildScreens?.screen == "FunctionInquiry") {
        let valuelistforFunctions = thisObj.state.lstFunctions.valueList;
        if (DatafromChildScreens != null) {
          if (DatafromChildScreens.condition == "Add") {
            obj = { key: DatafromChildScreens.func_id + "!" + DatafromChildScreens.func_sub_id + "!" + DatafromChildScreens.sAccessLevel + "!" + DatafromChildScreens.func_name,
            description: DatafromChildScreens.func_id + "." + DatafromChildScreens.func_sub_id + " (" + DatafromChildScreens.sAccessLevel + ")" + "- " + DatafromChildScreens.func_name, index: (valuelistforFunctions.length - 1)}
            if (valuelistforFunctions !== undefined && valuelistforFunctions.length > 0) {
              valuelistforFunctions.push(obj);
            } else {
              valuelistforFunctions = [obj];
            }
          }else if (DatafromChildScreens.condition == "Update") {
            for (let i = 0; i < valuelistforFunctions.length; i++) {
              let arrayapliting = valuelistforFunctions[i].key.split("!")
              let aftersplit = arrayapliting[0] + "!" + arrayapliting[1]
              if ((aftersplit) === DatafromChildScreens.func_id + "!" + DatafromChildScreens.func_sub_id ) {
                valuelistforFunctions[i].description = DatafromChildScreens.func_id + "." + DatafromChildScreens.func_sub_id + " (" + DatafromChildScreens.sAccessLevel + ")" + "- " + DatafromChildScreens.func_name
                break; // Exit the loop once the object is updated
              }
            }
          }
          thisObj.setState(current => {
            return {
              ...current,
              lstFunctions: {
                ...state["lstFunctions"],
                valuelistforFunctions: valuelistforFunctions
              }
            }
          })
          if (DatafromChildScreens.condition == "Add") {
            setValue(thisObj, 'lstFunctions', [valuelistforFunctions.at(valuelistforFunctions.length - 1).key]);
            setData(thisObj,"addednewfuncc",DatafromChildScreens.discription)
            setTimeout(() => {
              var lstFunctions = document.getElementById('lstFunctions')
              lstFunctions.scrollTop = lstFunctions.scrollHeight;
              }, 200);
          }
        }
      }

      //for frmSecurityGroup screen
      if (DatafromChildScreens?.screen == "SecurityGroup") {
      let valuelistforGroups = thisObj.state.lstGroups.valueList;
        if (DatafromChildScreens != null) {
          if (DatafromChildScreens.condition == "Add") {
            obj = { key: DatafromChildScreens.security_group, description: DatafromChildScreens.security_group, index: (valuelistforGroups.length - 1) };
            if (valuelistforGroups !== undefined && valuelistforGroups.length > 0) {
              valuelistforGroups.push(obj);
            } else {
              valuelistforGroups = [obj];
            }
          thisObj.setState(current => {
            return {
              ...current,
              lstGroups: {
                ...state["lstGroups"],
                valuelistforGroups: valuelistforGroups
              }
            }
          })
          if (DatafromChildScreens.condition == "Add") {
            setValue(thisObj, 'lstGroups', [valuelistforGroups.at(valuelistforGroups.length - 1).key]);
            setTimeout(() => {
              var lstGroups = document.getElementById('lstGroups')
              lstGroups.scrollTop = lstGroups.scrollHeight;
              }, 200);
          }
        }
        }
      }
      setData(thisObj, "DatafromChildScreensToSecurityMenu", null)
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj, "DatafromChildScreensToSecurityMenu")]);
  // START_USER_CODE-USER_METHODS

  //Form Load Functionality
  const formLoad = async () => {
    try {
      bFillBuyingPoints()
      await bFillFunctionsList();
      await bFillUsersList()
      await bFillGroupsList();
      await EnableDisableControls()
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during formLoad: ${error?.toString()}`
        );
      }
    }
  };

  function ConvertToPascalCase(word){
    return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  }

  //Function List Box Binding
  const bFillFunctionsList = async () => {
    try {
      setLoading(true);
      let func_id = ""//form load its getting ""
      let func_sub_id = ""//form load its getting "" 
      let datadeletefuncc = getData(thisObj, 'FunccListDATA')
      document.getElementById("txtUserName").focus();
      let LstrFunctionsList = await SystemMaintenanceSpecialFunctionsService.RetrieveFunctionControlDetails(func_id, func_sub_id);
      let js = []
      let obj = {}
      let obj2 = {}
      if (LstrFunctionsList?.length) {
        let j = 0
        for (var i = 0; i < LstrFunctionsList.length; i++) {
          if (LstrFunctionsList[i].strFieldName?.trim() == "FUNC_ID") {
            obj.FUNC_ID = LstrFunctionsList[i].strFieldData
          }
          if (LstrFunctionsList[i].strFieldName?.trim() == "FUNC_SUB_ID") {
            obj.FUNC_SUB_ID = LstrFunctionsList[i].strFieldData
          }
          if (LstrFunctionsList[i].strFieldName?.trim() == "FUNC_NAME") {
            obj.FUNC_NAME = LstrFunctionsList[i].strFieldData
          }
          if (LstrFunctionsList[i].strFieldName?.trim() == "ACCESS_LEVEL") {
            obj.ACCESS_LEVEL = LstrFunctionsList[i].strFieldData
            obj2 = {
            key: obj.FUNC_ID + "!" + obj.FUNC_SUB_ID + "!" + obj.ACCESS_LEVEL + "!" + obj.FUNC_NAME,
            description: obj.FUNC_ID + "." + obj.FUNC_SUB_ID + " (" + obj.ACCESS_LEVEL + ")" + "- " + obj.FUNC_NAME,
            index : j++
          }
            js.push(obj2)
            obj = {}
          }
        }
        setData(thisObj, "lstfunctionsLength", js)
        thisObj.setState(current => {
          return {
            ...current,
            lstFunctions: {
              ...state['lstFunctions'],
              valueList: js
            }
          }
        });
        if(LstrFunctionsList?.length){
          if (js.length > 0 && (datadeletefuncc == null || datadeletefuncc == undefined || datadeletefuncc.meAction == null || datadeletefuncc.funcIndexNew == null )) {
            setValue(thisObj, 'lstFunctions', [js.at(0).key]);
            setTimeout(() => {
              document.getElementById('lstFunctions').scrollTop = 0;
              }, 200);
          }
          else if (datadeletefuncc !== null && js.length > datadeletefuncc.funcIndexNew && datadeletefuncc.meAction == true) {
            setValue(thisObj, 'lstFunctions', [js.at(datadeletefuncc.funcIndexNew).key]);
            datadeletefuncc.meAction = null
            datadeletefuncc.funcIndexNew = null
          }
          else if (datadeletefuncc !== null && js.length == datadeletefuncc.funcIndexNew && datadeletefuncc.meAction == true) {
            setValue(thisObj, 'lstFunctions', [js.at(js.length-1).key]);
            datadeletefuncc.meAction = null
            datadeletefuncc.funcIndexNew = null
          }
        }
      }
      document.getElementById("txtUserID").focus();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillFunctionsList: ${error?.toString()}`
        );
      }
      return false;
    }
    finally {
      setLoading(false);
    }
  };

  //GroupListBox Binding
  const bFillGroupsList = async () => {
    try {
      setLoading(true);
      let security_group = ""
      let datadeleteGroup = getData(thisObj, 'grpListDATA')
      document.getElementById("txtUserName").focus();
      let LstrGroupsList = await SystemMaintenanceSpecialFunctionsService.RetrieveSecurityGroupControlDetails(compId, security_group);
      let js = []
      if (LstrGroupsList?.length) {
        for (var i = 0; i < LstrGroupsList.length; i++) {
          let obj = { key: LstrGroupsList[i].securityGroup, description: LstrGroupsList[i].securityGroup , index : i}
          js.push(obj);
        }
      }
      setData(thisObj, "lstgroupsLength", js)
      setData(thisObj, "userList", LstrGroupsList)
      thisObj.setState(current => {
        return {
          ...current,
          lstGroups: {
            ...state['lstGroups'],
            valueList: js
          }
        }
      });
      if(LstrGroupsList?.length){
      if (js.length > 0 && (datadeleteGroup == null || datadeleteGroup == undefined || datadeleteGroup.meAction == null || datadeleteGroup.funcIndex == null )) {
        setValue(thisObj, 'lstGroups', [js.at(0).key]);
        setTimeout(() => {
          document.getElementById('lstGroups').scrollTop = 0;
          }, 200);
      }
      else if (datadeleteGroup !== null && js.length > datadeleteGroup.funcIndex && datadeleteGroup.meAction == true) {
        setValue(thisObj, 'lstGroups', [js.at(datadeleteGroup.funcIndex).key]);
        datadeleteGroup.meAction = null
        datadeleteGroup.funcIndex = null
      }
      else if (datadeleteGroup !== null && js.length == datadeleteGroup.funcIndex && datadeleteGroup.meAction == true) {
        setValue(thisObj, 'lstGroups', [js.at(js.length-1).key]);
        datadeleteGroup.meAction = null
        datadeleteGroup.funcIndex = null
      }
    }
    document.getElementById("txtUserID").focus();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillGroupsList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  //User Listbox Binding
  const bFillUsersList = async () => {
    try {
      setLoading(true);
      let userId = getValue(thisObj, "txtUserID")?.trim() || ''
      let UserName = getValue(thisObj, "txtUserName")?.trim() || ''
      let sBuyPtId = getValue(thisObj, "ddBuyingPoint")?.trim() || ''
      let datadeleteUser = getData(thisObj, 'UserListDATA')
      document.getElementById("txtUserName").focus();
      const LstrList = await ContractManagementService.RetrieveUserControlDetailsImproved(null, sBuyPtId, UserName, userId)
      let js = []
      if (LstrList?.length) {
        for (var i = 0; i < LstrList.length; i++) {
          let obj = { key: LstrList[i].userId, description: LstrList[i].userId + " - " + LstrList[i].userName , index : i}
          js.push(obj);
        }
        setData(thisObj, "Userdetails", js)
      }
      thisObj.setState(current => {
        return {
          ...current,
          lstUsers: {
            ...state['lstUsers'],
            valueList: js
          }
        }
      });
      if(LstrList?.length){
      if (js.length > 0 && (datadeleteUser == null || datadeleteUser == undefined || datadeleteUser.meAction == null || datadeleteUser.userIndex == null )) {
        setValue(thisObj, 'lstUsers', [js.at(0).key]);
        setTimeout(() => {
        document.getElementById('lstUsers').scrollTop = 0;
        }, 200);
      }
      else if (datadeleteUser !== null && js.length > datadeleteUser.userIndex && datadeleteUser.meAction == true) {
        setValue(thisObj, 'lstUsers', [js.at(datadeleteUser.userIndex).key]);
        datadeleteUser.meAction = null
        datadeleteUser.userIndex = null
      }
      else if (datadeleteUser !== null && js.length == datadeleteUser.userIndex && datadeleteUser.meAction == true) {
        setValue(thisObj, 'lstUsers', [js.at(js.length-1).key]);
        datadeleteUser.meAction = null
        datadeleteUser.userIndex = null
      }
    }
    document.getElementById("txtUserID").focus();
    document.getElementById("txtUserID").blur()
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillUsersList: ${error?.toString()}`
        );
      }
      return false;
    } finally {
      setLoading(false);
    }
  };

  //Search Btn Functionality
  const onbtnSearchClick = async () => {
    try {
       bFillUsersList()
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnSearchClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  //Buying Point Dropdown Binding
  const bFillBuyingPoints = async () => {
    try {
      const originBuyingPointList = [];
      originBuyingPointList.push({ key: '', description: '>>> All Buying Points <<<' });
      const LstrList = await ContractManagementService.RetrieveBuyingPointControlDetails("PN0060", null, null, null, null);
      if (LstrList?.length) {
        LstrList.forEach(location => {
          originBuyingPointList.push({
            key: location.buy_pt_id.toString(),
            description: location.buy_pt_id.toString() + ' - ' + ConvertToPascalCase(location.buy_pt_name.toString())
          });
        });

        thisObj.setState(current => {
          return {
            ...current,
            ddBuyingPoint: {
              ...state['ddBuyingPoint'],
              valueList: originBuyingPointList,
            }
          }
        });
      }
      setData(thisObj, "cboBuyingPointList", originBuyingPointList)
      setValue(thisObj, 'ddBuyingPoint', originBuyingPointList[0].key);
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillBuyingPoints: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  //Enable Disable Condition
  const EnableDisableControls = async () => {
    try {
      enable(thisObj, "btnAddFunction")
      enable(thisObj, "btnEditFunction")
      enable(thisObj, "btnDeleteFunction")
      enable(thisObj, "btnAddUser")
      enable(thisObj, "btnEditUser")
      enable(thisObj, "btnDeleteUser")
      enable(thisObj, "btnAddGroup")
      enable(thisObj, "btnEditGroup")
      enable(thisObj, "btnDeleteGroup")

      let listFunction = getData(thisObj, "lstfunctionsLength")
      if (listFunction?.length == 0) {
        disable(thisObj, "btnEditFunction")
        disable(thisObj, "btnDeleteFunction")
      }
      let lstGroups = getData(thisObj, "lstgroupsLength")
      if (lstGroups?.length == 0) {
        disable(thisObj, "btnEditGroup")
        disable(thisObj, "btnDeleteGroup")
      }

    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during EnableDisableControls: ${error?.toString()}`
        );
      }
    }
  };

  //Clear Functionality function
  const clearingUserSearchValue = async () => {
    try {
      setValue(thisObj, "ddBuyingPoint", "")
      setValue(thisObj, "txtUserID", "")
      setValue(thisObj, "txtUserName", "")
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during clearingUserSearchValue: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  //Clear Btn Functionality 
  const onbtnClearClick = async () => {
    try {
      let cboBuyingPoint = getData(thisObj, "cboBuyingPointList")
      if (cboBuyingPoint?.length > 0) {
        await clearingUserSearchValue()
        await bFillUsersList()
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnClearClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnClearClick = onbtnClearClick;

  // Add btn for frmSecurityFunctionInquiry screen
  const onbtnAddFunctionClick = async () => {
    try {
      var frmSecurityFunctionInquiryArray = []
      var frmSecurityFunctionInquiryDataObj = {
        cmdOkCaption: "Add",
        txtFunctionEnabled: true,
        lblDestBPTCaption: ""
      }
      frmSecurityFunctionInquiryArray.push(frmSecurityFunctionInquiryDataObj)
      setData(thisObj, "dataSendingtoSecurityFunctionInquiry", frmSecurityFunctionInquiryArray)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityFunctionInquiry#DEFAULT#true#Click");//not yet Ready
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnAddFunctionClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddFunctionClick = onbtnAddFunctionClick;

  //Edit btn for frmSecurityFunctionInquiry screen
  const onbtnEditFunctionClick = async () => {
    try {
      let func_id = getValue(thisObj, "lstFunctions")[0]?.split("!")?.[0] || ""
      let func_sub_id = getValue(thisObj, "lstFunctions")[0]?.split("!")?.[1] || ""
      let sAccessLevel, SAccess, chkExtendBPValue, chkExtendCPValue, chkExtendARValue
      let LstrFunctionsList = await SystemMaintenanceSpecialFunctionsService.RetrieveFunctionControlDetails(func_id, func_sub_id);
      let obj = {}
      let count = 0 
      for (var i = 0; i < LstrFunctionsList?.length; i++) {
        if (LstrFunctionsList[i].strFieldName?.trim() == "FUNC_ID" ) {
          obj.FUNC_ID = LstrFunctionsList[i].strFieldData
        }
        if (LstrFunctionsList[i].strFieldName?.trim() == "FUNC_SUB_ID") {
          obj.FUNC_SUB_ID = LstrFunctionsList[i].strFieldData
        }
        if (LstrFunctionsList[i].strFieldName?.trim() == "FUNC_NAME") {
          obj.FUNC_NAME = LstrFunctionsList[i].strFieldData
        }
        if (LstrFunctionsList[i].strFieldName?.trim() == "ACCESS_LEVEL") {
          obj.ACCESS_LEVEL = LstrFunctionsList[i].strFieldData
        }
        if (LstrFunctionsList[i].strFieldName?.trim() == "ADD_USER") {
          obj.ADD_USER = LstrFunctionsList[i].strFieldData
        }
        if (LstrFunctionsList[i].strFieldName?.trim() == "ADD_DATE_TIME") {
          obj.ADD_DATE_TIME = LstrFunctionsList[i].strFieldData
        }
        if (LstrFunctionsList[i].strFieldName?.trim() == "CHG_USER") {
          obj.CHG_USER = LstrFunctionsList[i].strFieldData
        }
        if (LstrFunctionsList[i].strFieldName?.trim() == "CHG_DATE_TIME") {
          obj.CHG_DATE_TIME = LstrFunctionsList[i].strFieldData
        }
        if (LstrFunctionsList[i].strFieldName?.trim() == "ACCESS_LEVEL") {
          obj.ACCESS_LEVEL = LstrFunctionsList[i].strFieldData
        }
        if (LstrFunctionsList[i].strFieldName?.trim() == "EXTEND_BP_AUTH_LEVEL_TO") {
          obj.EXTEND_BP_AUTH_LEVEL_TO = LstrFunctionsList[i].strFieldData
        }
        if (LstrFunctionsList[i].strFieldName?.trim() == "EXTEND_CP_AUTH_LEVEL_TO") {
          obj.EXTEND_CP_AUTH_LEVEL_TO = LstrFunctionsList[i].strFieldData
        }
        if (LstrFunctionsList[i].strFieldName?.trim() == "EXTEND_AR_AUTH_LEVEL_TO") {
          obj.EXTEND_AR_AUTH_LEVEL_TO = LstrFunctionsList[i].strFieldData
        }
        if(obj.FUNC_SUB_ID == func_sub_id){
          count ++ 
        }
        if(count == 10){
          break;
        }
      }

      sAccessLevel = (obj.ACCESS_LEVEL)?.toUpperCase()
      if (sAccessLevel === "I") {
        SAccess = "Inquiry"
      }
      else if (sAccessLevel === "U") {
        SAccess = "Update"
      }
      else if (sAccessLevel === "D") {
        SAccess = "Delete"
      }
      else if (sAccessLevel === "X") {
        SAccess = "Revoke"
      }

      if (obj.EXTEND_BP_AUTH_LEVEL_TO != "") {
        chkExtendBPValue = true
      } else {
        chkExtendBPValue = false
      }

      if (obj.EXTEND_CP_AUTH_LEVEL_TO != "") {
        chkExtendCPValue = true
      } else {
        chkExtendCPValue = false
      }

      if (obj.EXTEND_AR_AUTH_LEVEL_TO != "") {
        chkExtendARValue = true
      } else {
        chkExtendARValue = false
      }

      var frmSecurityFunctionInquiryArray1 = []
      var frmSecurityFunctionInquiryDataObj1 = {
        cmdOkCaption: "Update",
        txtFunctionText: func_id,
        txtDescriptionText: obj?.FUNC_NAME,
        lblAddedByCaption: obj?.ADD_USER + " " + moment(obj?.ADD_DATE_TIME).format("M/D/YYYY"),
        lblChangedByCaption: obj?.CHG_USER + " " + moment(obj?.CHG_DATE_TIME).format("M/D/YYYY"),
        txtSubFunctionText: obj?.FUNC_SUB_ID,
        cboAccessLevelText: SAccess,
        cboExtendBPTag: obj?.EXTEND_BP_AUTH_LEVEL_TO,
        chkExtendBPValue: chkExtendBPValue,
        cboExtendCPTag: obj?.EXTEND_CP_AUTH_LEVEL_TO,
        chkExtendCPValue: chkExtendCPValue,
        cboExtendARTag: obj?.EXTEND_AR_AUTH_LEVEL_TO,
        chkExtendARValue: chkExtendARValue,
        txtFunctionEnabled: false,
        txtSubFunctionEnabled: false
      }
      frmSecurityFunctionInquiryArray1?.push(frmSecurityFunctionInquiryDataObj1)
      setData(thisObj, "dataSendingtoSecurityFunctionInquiry1", frmSecurityFunctionInquiryArray1)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityFunctionInquiry#DEFAULT#true#Click");//not yet Ready
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnEditFunctionClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnEditFunctionClick = onbtnEditFunctionClick;

  // DeLETE btn for Functions screen
  const onbtnDeleteFunctionClick = async () => {
    try {
      let functIdforConfirm = getValue(thisObj, "lstFunctions")?.[0].split("!")
      let functIdforConfirmCombine = functIdforConfirm[0] + "." + functIdforConfirm[1] + " (" + functIdforConfirm[2] + ")" + "- " + functIdforConfirm[3]
      let funcIndexNew = ''
      let funcLists = thisObj.state['lstFunctions'].valueList;
      let selectedfuncListValues = thisObj.values['lstFunctions'];
      funcIndexNew = funcLists.find(elem => elem.key == selectedfuncListValues[0]).index;
      let func_id = getValue(thisObj, "lstFunctions")[0]?.split("!")?.[0] || ""
      let func_sub_id = getValue(thisObj, "lstFunctions")[0]?.split("!")?.[1] || ""
      let n = 0
      let dataChild = getData(thisObj, "addednewfuncc")
      if(dataChild != undefined || dataChild != null){
        functIdforConfirmCombine = dataChild
        setData(thisObj,"addednewfuncc",null)
      }
      const response = confirm(`Are you sure you wish to delete this function '${functIdforConfirmCombine}'`);
      if (response) {
        let lstrxml = await SystemMaintenanceSpecialFunctionsService.RemoveFunctionControl(func_id, func_sub_id)
        if (lstrxml.status != 200) {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
          return
        }
        else {
          let obj = {
          meAction : true,
          funcIndexNew : funcIndexNew
        }
        setData(thisObj, 'FunccListDATA', obj)
          await bFillFunctionsList()
          await EnableDisableControls()
        }
        let listFunction = getData(thisObj, "lstfunctionsLength")
        if (n > (listFunction?.length - 1)) {
          await EnableDisableControls()
        }
        else {
          return
        }
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnDeleteFunctionClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnDeleteFunctionClick = onbtnDeleteFunctionClick;

  // Add Btn functionality for frmSecurityUser screen
  const onbtnAddUserClick = async () => {
    try {
      var frmSecurityUserDataObj = {
        cmdOkCaption: "Add",
        txtUserIdEnabled: true,
        cmdEnableDisableControlsValue: true,
        editing: false
      }
      setData(thisObj, "dataSendingtoSecurityUser", frmSecurityUserDataObj)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityUser#DEFAULT#true#Click");
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnAddUserClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddUserClick = onbtnAddUserClick;

  //Edit Btn functionality for frmSecurityUser screen
  const onbtnEditUserClick = async () => {
    try {
      let UserId = getValue(thisObj, "lstUsers")?.[0] || getData(thisObj, "Userdetails")?.[0].key || ""
      var frmSecurityUserDataObj = {
        cmdOkCaption: "Update",
        editing: true,
        txtUserIdEnabled: false,
        cmdEnableDisableControlsValue: false,
        UserId: UserId
      }
      setData(thisObj, "dataSendingtoSecurityUser", frmSecurityUserDataObj)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityUser#DEFAULT#true#Click");
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnEditUserClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnEditUserClick = onbtnEditUserClick;

  //Delete Btn functionality Users
  const onbtnDeleteUserClick = async () => {
    try {
      let UserId = getValue(thisObj, "lstUsers")?.[0] || ""
      let userDetails = getData(thisObj, "Userdetails")
      let findData = userDetails.find(x => x.key == UserId)
      let userIndex = ''
      let userList = thisObj.state['lstUsers'].valueList;
      let selectedUserListValue = thisObj.values['lstUsers'];
      userIndex = userList.find(elem => elem.key == selectedUserListValue[0]).index;
      const response = confirm(`Are you sure you wish to delete this User'${findData.description}'`);
      if (response) {
        let lstrxml = await SystemMaintenanceSpecialFunctionsService.RemoveUserControl(UserId)
        if (lstrxml.status != 200) {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
          return
        }
        else { 
          let obj = {
            meAction : true,
            userIndex : userIndex
          }
          setData(thisObj, 'UserListDATA', obj)
          await bFillUsersList()
          await EnableDisableControls()
        }
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnDeleteUserClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnDeleteUserClick = onbtnDeleteUserClick;

  //Add Btn functionality for frmSecurityGroup screen
  const onbtnAddGroupClick = async () => {
    try {
      var frmSecurityGroupArray = []
      var frmSecurityGroupDataObj = {
        cmdOkCaption: "Add",
        txtGroupEnabled: true,
        cmdEnableDisableControlsValue: true
      }
      frmSecurityGroupArray.push(frmSecurityGroupDataObj)
      setData(thisObj, "dataSendingtoSecurityGroup", frmSecurityGroupArray)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityGroup#DEFAULT#true#Click");
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnAddGroupClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddGroupClick = onbtnAddGroupClick;

  //Edit Btn functionality for frmSecurityGroup screen
  const onbtnEditGroupClick = async () => {
    try {
      let security_group = getValue(thisObj, "lstGroups")?.[0]
      let SAuthLevel

      let LstrGroupsList = await SystemMaintenanceSpecialFunctionsService.RetrieveSecurityGroupControlDetails(compId, security_group);
      if (LstrGroupsList[0]?.authLevel == "AR") {
        SAuthLevel = "Area"
      }
      else if (LstrGroupsList[0]?.authLevel == "CP") {
        SAuthLevel = "Collection Point"
      }
      else if (LstrGroupsList[0]?.authLevel == "BP") {
        SAuthLevel = "Buying Point"
      }
      else {
        SAuthLevel = "None"
      }

      var frmSecurityGroupArray = []
      var frmSecurityGroupDataObj = {
        cmdOkCaption: "Update",
        txtGroupEnabled: false,
        cmdEnableDisableControlsValue: true,
        txtGroupText: security_group,
        lblAddedByCaption: LstrGroupsList[0]?.addUser + " " + moment(LstrGroupsList[0]?.addDateTime).format("M/D/YYYY"),
        lblChangedByCaption: LstrGroupsList[0]?.chgUser + " " + moment(LstrGroupsList[0]?.chgDateTime).format("M/D/YYYY"),
        cboAuthorityLevelText: SAuthLevel
      }
      frmSecurityGroupArray.push(frmSecurityGroupDataObj)
      setData(thisObj, "dataSendingtoSecurityGroup", frmSecurityGroupArray)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityGroup#DEFAULT#true#Click");
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnEditGroupClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnEditGroupClick = onbtnEditGroupClick;

  //Deleet Functionality for Groups
  const onbtnDeleteGroupClick = async () => {
    try {
      let groupNum = getValue(thisObj, "lstGroups")?.[0] || ""
      let n = 0
      let funcIndex = ''
      let funcList = thisObj.state['lstGroups'].valueList;
      let selectedfuncListValue = thisObj.values['lstGroups'];
      funcIndex = funcList.find(elem => elem.key == selectedfuncListValue[0]).index;
      const response = confirm(`Are you sure you wish to delete this Group'${groupNum}'`);
      if (response) {
        let lstrxml = await SystemMaintenanceSpecialFunctionsService.RemoveSecurityGroupControl(groupNum, compId)
        if (lstrxml.status != 200) {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
          return
        }
        else {
          let obj = {
            meAction : true,
            funcIndex : funcIndex
          }
          setData(thisObj, 'grpListDATA', obj)
          await bFillGroupsList()
          await EnableDisableControls()
        }
        let lstGroups = getData(thisObj, "lstgroupsLength")
        if (n > (lstGroups?.length - 1)) {
          await EnableDisableControls()
        }
        else {
          return
        }
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnDeleteGroupClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnDeleteGroupClick = onbtnDeleteGroupClick;

  // Report Btn click even for frmSecurityReport screen
  const onbtnReportsClick = async () => {
    try {
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityReport#DEFAULT#true#Click");
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnReportsClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnReportsClick = onbtnReportsClick;

  
  //Close Btn Functionality
  const onbtnCloseClick = async () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_SecurityMenuPopUp").childNodes[0].click()
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnCloseClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SecurityMenu*/}

              {/* END_USER_CODE-USER_BEFORE_SecurityMenu*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSecurityMenu*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSecurityMenu*/}

              <GroupBoxWidget conf={state.grpbxSecurityMenu} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxUserSearch*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxUserSearch*/}

                <GroupBoxWidget conf={state.grpbxUserSearch} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblUserSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_lblUserSearch*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblUserSearch}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblUserSearch*/}

                  {/* END_USER_CODE-USER_AFTER_lblUserSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBuyingPoint}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_txtUserID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtUserID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtUserID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtUserID*/}

                  {/* END_USER_CODE-USER_AFTER_txtUserID*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_txtUserName*/}

                  {/* END_USER_CODE-USER_BEFORE_txtUserName*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtUserName}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtUserName*/}

                  {/* END_USER_CODE-USER_AFTER_txtUserName*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClear*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClear*/}

                  <ButtonWidget
                    conf={state.btnClear}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClear*/}

                  {/* END_USER_CODE-USER_AFTER_btnClear*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxUserSearch*/}

                {/* END_USER_CODE-USER_AFTER_grpbxUserSearch*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxFunctions*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFunctions*/}

                <GroupBoxWidget conf={state.grpbxFunctions} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblFunctions*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFunctions*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFunctions}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFunctions*/}

                  {/* END_USER_CODE-USER_AFTER_lblFunctions*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAddFunction*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddFunction*/}

                  <ButtonWidget
                    conf={state.btnAddFunction}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddFunction*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddFunction*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEditFunction*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEditFunction*/}

                  <ButtonWidget
                    conf={state.btnEditFunction}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEditFunction*/}

                  {/* END_USER_CODE-USER_AFTER_btnEditFunction*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteFunction*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteFunction*/}

                  <ButtonWidget
                    conf={state.btnDeleteFunction}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteFunction*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteFunction*/}
                  {/* START_USER_CODE-USER_BEFORE_lstFunctions*/}

                  {/* END_USER_CODE-USER_BEFORE_lstFunctions*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstFunctions}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstFunctions*/}

                  {/* END_USER_CODE-USER_AFTER_lstFunctions*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFunctions*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFunctions*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxUsers*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxUsers*/}

                <GroupBoxWidget conf={state.grpbxUsers} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblUsers*/}

                  {/* END_USER_CODE-USER_BEFORE_lblUsers*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblUsers}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblUsers*/}

                  {/* END_USER_CODE-USER_AFTER_lblUsers*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAddUser*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddUser*/}

                  <ButtonWidget
                    conf={state.btnAddUser}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddUser*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddUser*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEditUser*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEditUser*/}

                  <ButtonWidget
                    conf={state.btnEditUser}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEditUser*/}

                  {/* END_USER_CODE-USER_AFTER_btnEditUser*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteUser*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteUser*/}

                  <ButtonWidget
                    conf={state.btnDeleteUser}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteUser*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteUser*/}
                  {/* START_USER_CODE-USER_BEFORE_lstUsers*/}

                  {/* END_USER_CODE-USER_BEFORE_lstUsers*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstUsers}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstUsers*/}

                  {/* END_USER_CODE-USER_AFTER_lstUsers*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxUsers*/}

                {/* END_USER_CODE-USER_AFTER_grpbxUsers*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxGroups*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxGroups*/}

                <GroupBoxWidget conf={state.grpbxGroups} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblGroups*/}

                  {/* END_USER_CODE-USER_BEFORE_lblGroups*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblGroups}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblGroups*/}

                  {/* END_USER_CODE-USER_AFTER_lblGroups*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAddGroup*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddGroup*/}

                  <ButtonWidget
                    conf={state.btnAddGroup}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddGroup*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddGroup*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEditGroup*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEditGroup*/}

                  <ButtonWidget
                    conf={state.btnEditGroup}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEditGroup*/}

                  {/* END_USER_CODE-USER_AFTER_btnEditGroup*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteGroup*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteGroup*/}

                  <ButtonWidget
                    conf={state.btnDeleteGroup}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteGroup*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteGroup*/}
                  {/* START_USER_CODE-USER_BEFORE_lstGroups*/}

                  {/* END_USER_CODE-USER_BEFORE_lstGroups*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstGroups}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstGroups*/}

                  {/* END_USER_CODE-USER_AFTER_lstGroups*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxGroups*/}

                {/* END_USER_CODE-USER_AFTER_grpbxGroups*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSecurityMenu*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSecurityMenu*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnReports*/}

                {/* END_USER_CODE-USER_BEFORE_btnReports*/}

                <ButtonWidget
                  conf={state.btnReports}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReports*/}

                {/* END_USER_CODE-USER_AFTER_btnReports*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_SecurityMenu*/}

              {/* END_USER_CODE-USER_AFTER_SecurityMenu*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_SecurityMenu);
