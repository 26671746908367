/* eslint-disable*/
import React from "react";
import WarehouseReceipts_1007GrpSearch from "./1007GrpSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("1007GrpSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_1007GrpSearch />);
    });
  });
  afterEach(cleanup);
  test("is 1007GrpSearch Loads Successfully", () => {
    expect(screen.getByText("1007GrpSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for 1007GrpSearch", () => {
    // START_USER_CODE-USER_1007GrpSearch_Custom_Test_Case
    // END_USER_CODE-USER_1007GrpSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_1007GrpSearch />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSearch_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnCreate1007Grouping(Button Widget) Test Cases", async () => {
    const btnCreate1007Grouping = screen.getByTestId("btnCreate1007Grouping");
    expect(btnCreate1007Grouping).toBeInTheDocument;
    expect(btnCreate1007Grouping.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSearch_btnCreate1007Grouping")
    );
  });
  test("Custom Test Cases for btnCreate1007Grouping", () => {
    // START_USER_CODE-USER_btnCreate1007Grouping_TEST
    // END_USER_CODE-USER_btnCreate1007Grouping_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSearch_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSearch_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnPrintSearch(Button Widget) Test Cases", async () => {
    const btnPrintSearch = screen.getByTestId("btnPrintSearch");
    expect(btnPrintSearch).toBeInTheDocument;
    expect(btnPrintSearch.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSearch_btnPrintSearch")
    );
  });
  test("Custom Test Cases for btnPrintSearch", () => {
    // START_USER_CODE-USER_btnPrintSearch_TEST
    // END_USER_CODE-USER_btnPrintSearch_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSearch_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gpxbxGrpSearch4(GroupBox Widget) Test Cases", async () => {
    const gpxbxGrpSearch4 = screen.getByTestId("gpxbxGrpSearch4");
    expect(gpxbxGrpSearch4.tagName).toBe("BUTTON");
    expect(gpxbxGrpSearch4.type).toBe("button");
    expect(gpxbxGrpSearch4.classList).toContain("btn");
  });
  test("Custom Test Cases for gpxbxGrpSearch4", () => {
    // START_USER_CODE-USER_gpxbxGrpSearch4_TEST
    // END_USER_CODE-USER_gpxbxGrpSearch4_TEST
  });
  test("gridGrpSearch(Grid Widget) Test Cases", async () => {
    let gridGrpSearch = screen.getByTestId("gridGrpSearch");
    let gridGrpSearchbtn = gridGrpSearch.nextElementSibling.firstElementChild;
    gridGrpSearch = gridGrpSearch.parentElement.parentElement.parentElement;
    expect(gridGrpSearch.tagName).toBe("DIV");
    expect(gridGrpSearch.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:1007GrpSearch_gridGrpSearch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridGrpSearch", () => {
    // START_USER_CODE-USER_gridGrpSearch_TEST
    // END_USER_CODE-USER_gridGrpSearch_TEST
  });
  test("grpbx1007GrpSearch(GroupBox Widget) Test Cases", async () => {
    const grpbx1007GrpSearch = screen.getByTestId("grpbx1007GrpSearch");
    expect(grpbx1007GrpSearch.tagName).toBe("BUTTON");
    expect(grpbx1007GrpSearch.type).toBe("button");
    expect(grpbx1007GrpSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx1007GrpSearch", () => {
    // START_USER_CODE-USER_grpbx1007GrpSearch_TEST
    // END_USER_CODE-USER_grpbx1007GrpSearch_TEST
  });
  test("grpbxgGrpSearch3(GroupBox Widget) Test Cases", async () => {
    const grpbxgGrpSearch3 = screen.getByTestId("grpbxgGrpSearch3");
    expect(grpbxgGrpSearch3.tagName).toBe("BUTTON");
    expect(grpbxgGrpSearch3.type).toBe("button");
    expect(grpbxgGrpSearch3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxgGrpSearch3", () => {
    // START_USER_CODE-USER_grpbxgGrpSearch3_TEST
    // END_USER_CODE-USER_grpbxgGrpSearch3_TEST
  });
  test("grpbxGrpSearch1(GroupBox Widget) Test Cases", async () => {
    const grpbxGrpSearch1 = screen.getByTestId("grpbxGrpSearch1");
    expect(grpbxGrpSearch1.tagName).toBe("BUTTON");
    expect(grpbxGrpSearch1.type).toBe("button");
    expect(grpbxGrpSearch1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGrpSearch1", () => {
    // START_USER_CODE-USER_grpbxGrpSearch1_TEST
    // END_USER_CODE-USER_grpbxGrpSearch1_TEST
  });
  test("grpbxGrpSearch2(GroupBox Widget) Test Cases", async () => {
    const grpbxGrpSearch2 = screen.getByTestId("grpbxGrpSearch2");
    expect(grpbxGrpSearch2.tagName).toBe("BUTTON");
    expect(grpbxGrpSearch2.type).toBe("button");
    expect(grpbxGrpSearch2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGrpSearch2", () => {
    // START_USER_CODE-USER_grpbxGrpSearch2_TEST
    // END_USER_CODE-USER_grpbxGrpSearch2_TEST
  });
  test("txt1007(Textbox Widget) Test Cases", async () => {
    const txt1007 = screen.getByTestId("txt1007");
    expect(txt1007.tagName).toBe("INPUT");
    expect(txt1007.type).toBe("text");
    expect(txt1007.classList).toContain("textboxWidgetClass");
    expect(txt1007.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSearch_txt1007")
    );
    await act(async () => {
      userEvent.type(txt1007, "1");
    });
  });
  test("Custom Test Cases for txt1007", () => {
    // START_USER_CODE-USER_txt1007_TEST
    // END_USER_CODE-USER_txt1007_TEST
  });
  test("gridGrpSearch_txtcol1007(Grid Widget) Test Cases", async () => {
    let gridGrpSearch_txtcol1007 = screen.getByTestId("gridGrpSearch");
    let gridGrpSearch_txtcol1007btn =
      gridGrpSearch_txtcol1007.nextElementSibling.firstElementChild;
    gridGrpSearch_txtcol1007 =
      gridGrpSearch_txtcol1007.parentElement.parentElement.parentElement;
    expect(gridGrpSearch_txtcol1007.tagName).toBe("DIV");
    expect(gridGrpSearch_txtcol1007.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:1007GrpSearch_gridGrpSearch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol1007", () => {
    // START_USER_CODE-USER_txtcol1007_TEST
    // END_USER_CODE-USER_txtcol1007_TEST
  });
  test("gridGrpSearch_txtcolBuyPt(Grid Widget) Test Cases", async () => {
    let gridGrpSearch_txtcolBuyPt = screen.getByTestId("gridGrpSearch");
    let gridGrpSearch_txtcolBuyPtbtn =
      gridGrpSearch_txtcolBuyPt.nextElementSibling.firstElementChild;
    gridGrpSearch_txtcolBuyPt =
      gridGrpSearch_txtcolBuyPt.parentElement.parentElement.parentElement;
    expect(gridGrpSearch_txtcolBuyPt.tagName).toBe("DIV");
    expect(gridGrpSearch_txtcolBuyPt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:1007GrpSearch_gridGrpSearch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyPt", () => {
    // START_USER_CODE-USER_txtcolBuyPt_TEST
    // END_USER_CODE-USER_txtcolBuyPt_TEST
  });
  test("gridGrpSearch_txtcolCmaDma(Grid Widget) Test Cases", async () => {
    let gridGrpSearch_txtcolCmaDma = screen.getByTestId("gridGrpSearch");
    let gridGrpSearch_txtcolCmaDmabtn =
      gridGrpSearch_txtcolCmaDma.nextElementSibling.firstElementChild;
    gridGrpSearch_txtcolCmaDma =
      gridGrpSearch_txtcolCmaDma.parentElement.parentElement.parentElement;
    expect(gridGrpSearch_txtcolCmaDma.tagName).toBe("DIV");
    expect(gridGrpSearch_txtcolCmaDma.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:1007GrpSearch_gridGrpSearch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCmaDma", () => {
    // START_USER_CODE-USER_txtcolCmaDma_TEST
    // END_USER_CODE-USER_txtcolCmaDma_TEST
  });
  test("gridGrpSearch_txtcolContract(Grid Widget) Test Cases", async () => {
    let gridGrpSearch_txtcolContract = screen.getByTestId("gridGrpSearch");
    let gridGrpSearch_txtcolContractbtn =
      gridGrpSearch_txtcolContract.nextElementSibling.firstElementChild;
    gridGrpSearch_txtcolContract =
      gridGrpSearch_txtcolContract.parentElement.parentElement.parentElement;
    expect(gridGrpSearch_txtcolContract.tagName).toBe("DIV");
    expect(gridGrpSearch_txtcolContract.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:1007GrpSearch_gridGrpSearch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContract", () => {
    // START_USER_CODE-USER_txtcolContract_TEST
    // END_USER_CODE-USER_txtcolContract_TEST
  });
  test("gridGrpSearch_txtcolDeferred(Grid Widget) Test Cases", async () => {
    let gridGrpSearch_txtcolDeferred = screen.getByTestId("gridGrpSearch");
    let gridGrpSearch_txtcolDeferredbtn =
      gridGrpSearch_txtcolDeferred.nextElementSibling.firstElementChild;
    gridGrpSearch_txtcolDeferred =
      gridGrpSearch_txtcolDeferred.parentElement.parentElement.parentElement;
    expect(gridGrpSearch_txtcolDeferred.tagName).toBe("DIV");
    expect(gridGrpSearch_txtcolDeferred.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:1007GrpSearch_gridGrpSearch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDeferred", () => {
    // START_USER_CODE-USER_txtcolDeferred_TEST
    // END_USER_CODE-USER_txtcolDeferred_TEST
  });
  test("gridGrpSearch_txtcolFarm(Grid Widget) Test Cases", async () => {
    let gridGrpSearch_txtcolFarm = screen.getByTestId("gridGrpSearch");
    let gridGrpSearch_txtcolFarmbtn =
      gridGrpSearch_txtcolFarm.nextElementSibling.firstElementChild;
    gridGrpSearch_txtcolFarm =
      gridGrpSearch_txtcolFarm.parentElement.parentElement.parentElement;
    expect(gridGrpSearch_txtcolFarm.tagName).toBe("DIV");
    expect(gridGrpSearch_txtcolFarm.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:1007GrpSearch_gridGrpSearch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFarm", () => {
    // START_USER_CODE-USER_txtcolFarm_TEST
    // END_USER_CODE-USER_txtcolFarm_TEST
  });
  test("gridGrpSearch_txtcolGRP(Grid Widget) Test Cases", async () => {
    let gridGrpSearch_txtcolGRP = screen.getByTestId("gridGrpSearch");
    let gridGrpSearch_txtcolGRPbtn =
      gridGrpSearch_txtcolGRP.nextElementSibling.firstElementChild;
    gridGrpSearch_txtcolGRP =
      gridGrpSearch_txtcolGRP.parentElement.parentElement.parentElement;
    expect(gridGrpSearch_txtcolGRP.tagName).toBe("DIV");
    expect(gridGrpSearch_txtcolGRP.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:1007GrpSearch_gridGrpSearch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGRP", () => {
    // START_USER_CODE-USER_txtcolGRP_TEST
    // END_USER_CODE-USER_txtcolGRP_TEST
  });
  test("gridGrpSearch_txtcolGrpDate(Grid Widget) Test Cases", async () => {
    let gridGrpSearch_txtcolGrpDate = screen.getByTestId("gridGrpSearch");
    let gridGrpSearch_txtcolGrpDatebtn =
      gridGrpSearch_txtcolGrpDate.nextElementSibling.firstElementChild;
    gridGrpSearch_txtcolGrpDate =
      gridGrpSearch_txtcolGrpDate.parentElement.parentElement.parentElement;
    expect(gridGrpSearch_txtcolGrpDate.tagName).toBe("DIV");
    expect(gridGrpSearch_txtcolGrpDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:1007GrpSearch_gridGrpSearch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGrpDate", () => {
    // START_USER_CODE-USER_txtcolGrpDate_TEST
    // END_USER_CODE-USER_txtcolGrpDate_TEST
  });
  test("gridGrpSearch_txtcolInspDate(Grid Widget) Test Cases", async () => {
    let gridGrpSearch_txtcolInspDate = screen.getByTestId("gridGrpSearch");
    let gridGrpSearch_txtcolInspDatebtn =
      gridGrpSearch_txtcolInspDate.nextElementSibling.firstElementChild;
    gridGrpSearch_txtcolInspDate =
      gridGrpSearch_txtcolInspDate.parentElement.parentElement.parentElement;
    expect(gridGrpSearch_txtcolInspDate.tagName).toBe("DIV");
    expect(gridGrpSearch_txtcolInspDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:1007GrpSearch_gridGrpSearch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInspDate", () => {
    // START_USER_CODE-USER_txtcolInspDate_TEST
    // END_USER_CODE-USER_txtcolInspDate_TEST
  });
  test("gridGrpSearch_txtcolObligationpointuic0(Grid Widget) Test Cases", async () => {
    let gridGrpSearch_txtcolObligationpointuic0 = screen.getByTestId(
      "gridGrpSearch"
    );
    let gridGrpSearch_txtcolObligationpointuic0btn =
      gridGrpSearch_txtcolObligationpointuic0.nextElementSibling
        .firstElementChild;
    gridGrpSearch_txtcolObligationpointuic0 =
      gridGrpSearch_txtcolObligationpointuic0.parentElement.parentElement
        .parentElement;
    expect(gridGrpSearch_txtcolObligationpointuic0.tagName).toBe("DIV");
    expect(gridGrpSearch_txtcolObligationpointuic0.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:1007GrpSearch_gridGrpSearch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolObligationpointuic0", () => {
    // START_USER_CODE-USER_txtcolObligationpointuic0_TEST
    // END_USER_CODE-USER_txtcolObligationpointuic0_TEST
  });
  test("gridGrpSearch_txtcolSC95(Grid Widget) Test Cases", async () => {
    let gridGrpSearch_txtcolSC95 = screen.getByTestId("gridGrpSearch");
    let gridGrpSearch_txtcolSC95btn =
      gridGrpSearch_txtcolSC95.nextElementSibling.firstElementChild;
    gridGrpSearch_txtcolSC95 =
      gridGrpSearch_txtcolSC95.parentElement.parentElement.parentElement;
    expect(gridGrpSearch_txtcolSC95.tagName).toBe("DIV");
    expect(gridGrpSearch_txtcolSC95.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:1007GrpSearch_gridGrpSearch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSC95", () => {
    // START_USER_CODE-USER_txtcolSC95_TEST
    // END_USER_CODE-USER_txtcolSC95_TEST
  });
  test("txtFarm(Textbox Widget) Test Cases", async () => {
    const txtFarm = screen.getByTestId("txtFarm");
    expect(txtFarm.tagName).toBe("INPUT");
    expect(txtFarm.type).toBe("text");
    expect(txtFarm.classList).toContain("textboxWidgetClass");
    expect(txtFarm.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSearch_txtFarm")
    );
    await act(async () => {
      userEvent.type(txtFarm, "1");
    });
  });
  test("Custom Test Cases for txtFarm", () => {
    // START_USER_CODE-USER_txtFarm_TEST
    // END_USER_CODE-USER_txtFarm_TEST
  });
  test("txtFarmSuffix(Textbox Widget) Test Cases", async () => {
    const txtFarmSuffix = screen.getByTestId("txtFarmSuffix");
    expect(txtFarmSuffix.tagName).toBe("INPUT");
    expect(txtFarmSuffix.type).toBe("text");
    expect(txtFarmSuffix.classList).toContain("textboxWidgetClass");
    expect(txtFarmSuffix.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSearch_txtFarmSuffix")
    );
    await act(async () => {
      userEvent.type(txtFarmSuffix, "1");
    });
  });
  test("Custom Test Cases for txtFarmSuffix", () => {
    // START_USER_CODE-USER_txtFarmSuffix_TEST
    // END_USER_CODE-USER_txtFarmSuffix_TEST
  });
  test("txtGrouping(Textbox Widget) Test Cases", async () => {
    const txtGrouping = screen.getByTestId("txtGrouping");
    expect(txtGrouping.tagName).toBe("INPUT");
    expect(txtGrouping.type).toBe("text");
    expect(txtGrouping.classList).toContain("textboxWidgetClass");
    expect(txtGrouping.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSearch_txtGrouping")
    );
    await act(async () => {
      userEvent.type(txtGrouping, "1");
    });
  });
  test("Custom Test Cases for txtGrouping", () => {
    // START_USER_CODE-USER_txtGrouping_TEST
    // END_USER_CODE-USER_txtGrouping_TEST
  });
  test("txtSC95(Textbox Widget) Test Cases", async () => {
    const txtSC95 = screen.getByTestId("txtSC95");
    expect(txtSC95.tagName).toBe("INPUT");
    expect(txtSC95.type).toBe("text");
    expect(txtSC95.classList).toContain("textboxWidgetClass");
    expect(txtSC95.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSearch_txtSC95")
    );
    await act(async () => {
      userEvent.type(txtSC95, "1");
    });
  });
  test("Custom Test Cases for txtSC95", () => {
    // START_USER_CODE-USER_txtSC95_TEST
    // END_USER_CODE-USER_txtSC95_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:1007GrpSearch_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVndr(Textbox Widget) Test Cases", async () => {
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVndr, "123");
    });
    expect(txtVndr.getAttribute("value")).toBe("");
    expect(txtVndr.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
});
