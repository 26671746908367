/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_ManualRTPMaint from "./ManualRTPMaint";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ManualRTPMaint Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_ManualRTPMaint />);
    });
  });
  afterEach(cleanup);
  test("is ManualRTPMaint Loads Successfully", () => {
    expect(screen.getByText("ManualRTPMaint")).toBeInTheDocument;
  });
  test("Custom Test Cases for ManualRTPMaint", () => {
    // START_USER_CODE-USER_ManualRTPMaint_Custom_Test_Case
    // END_USER_CODE-USER_ManualRTPMaint_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_ManualRTPMaint />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxManualRTPMaint(GroupBox Widget) Test Cases", async () => {
    const grpbxManualRTPMaint = screen.getByTestId("grpbxManualRTPMaint");
    expect(grpbxManualRTPMaint.tagName).toBe("BUTTON");
    expect(grpbxManualRTPMaint.type).toBe("button");
    expect(grpbxManualRTPMaint.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxManualRTPMaint", () => {
    // START_USER_CODE-USER_grpbxManualRTPMaint_TEST
    // END_USER_CODE-USER_grpbxManualRTPMaint_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("txtContract(Textbox Widget) Test Cases", async () => {
    const txtContract = screen.getByTestId("txtContract");
    expect(txtContract.tagName).toBe("INPUT");
    expect(txtContract.type).toBe("text");
    expect(txtContract.classList).toContain("textboxWidgetClass");
    expect(txtContract.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_txtContract")
    );
    await act(async () => {
      userEvent.type(txtContract, "1");
    });
  });
  test("Custom Test Cases for txtContract", () => {
    // START_USER_CODE-USER_txtContract_TEST
    // END_USER_CODE-USER_txtContract_TEST
  });
  test("txtCounty(Textbox Widget) Test Cases", async () => {
    const txtCounty = screen.getByTestId("txtCounty");
    expect(txtCounty.tagName).toBe("INPUT");
    expect(txtCounty.type).toBe("text");
    expect(txtCounty.classList).toContain("textboxWidgetClass");
    expect(txtCounty.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_txtCounty")
    );
    await act(async () => {
      userEvent.type(txtCounty, "1");
    });
  });
  test("Custom Test Cases for txtCounty", () => {
    // START_USER_CODE-USER_txtCounty_TEST
    // END_USER_CODE-USER_txtCounty_TEST
  });
  test("txtDeliveryAgreement(Textbox Widget) Test Cases", async () => {
    const txtDeliveryAgreement = screen.getByTestId("txtDeliveryAgreement");
    expect(txtDeliveryAgreement.tagName).toBe("INPUT");
    expect(txtDeliveryAgreement.type).toBe("text");
    expect(txtDeliveryAgreement.classList).toContain("textboxWidgetClass");
    expect(txtDeliveryAgreement.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPMaint_txtDeliveryAgreement"
      )
    );
    await act(async () => {
      userEvent.type(txtDeliveryAgreement, "1");
    });
  });
  test("Custom Test Cases for txtDeliveryAgreement", () => {
    // START_USER_CODE-USER_txtDeliveryAgreement_TEST
    // END_USER_CODE-USER_txtDeliveryAgreement_TEST
  });
  test("txtFarm(Textbox Widget) Test Cases", async () => {
    const txtFarm = screen.getByTestId("txtFarm");
    expect(txtFarm.tagName).toBe("INPUT");
    expect(txtFarm.type).toBe("text");
    expect(txtFarm.classList).toContain("textboxWidgetClass");
    expect(txtFarm.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_txtFarm")
    );
    await act(async () => {
      userEvent.type(txtFarm, "1");
    });
  });
  test("Custom Test Cases for txtFarm", () => {
    // START_USER_CODE-USER_txtFarm_TEST
    // END_USER_CODE-USER_txtFarm_TEST
  });
  test("txtFarmSuffix(Textbox Widget) Test Cases", async () => {
    const txtFarmSuffix = screen.getByTestId("txtFarmSuffix");
    expect(txtFarmSuffix.tagName).toBe("INPUT");
    expect(txtFarmSuffix.type).toBe("text");
    expect(txtFarmSuffix.classList).toContain("textboxWidgetClass");
    expect(txtFarmSuffix.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_txtFarmSuffix")
    );
    await act(async () => {
      userEvent.type(txtFarmSuffix, "1");
    });
  });
  test("Custom Test Cases for txtFarmSuffix", () => {
    // START_USER_CODE-USER_txtFarmSuffix_TEST
    // END_USER_CODE-USER_txtFarmSuffix_TEST
  });
  test("txtFreightMemo(Textbox Widget) Test Cases", async () => {
    const txtFreightMemo = screen.getByTestId("txtFreightMemo");
    expect(txtFreightMemo.tagName).toBe("INPUT");
    expect(txtFreightMemo.type).toBe("text");
    expect(txtFreightMemo.classList).toContain("textboxWidgetClass");
    expect(txtFreightMemo.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_txtFreightMemo")
    );
    await act(async () => {
      userEvent.type(txtFreightMemo, "1");
    });
  });
  test("Custom Test Cases for txtFreightMemo", () => {
    // START_USER_CODE-USER_txtFreightMemo_TEST
    // END_USER_CODE-USER_txtFreightMemo_TEST
  });
  test("txtInspectionSC95(Textbox Widget) Test Cases", async () => {
    const txtInspectionSC95 = screen.getByTestId("txtInspectionSC95");
    expect(txtInspectionSC95.tagName).toBe("INPUT");
    expect(txtInspectionSC95.type).toBe("text");
    expect(txtInspectionSC95.classList).toContain("textboxWidgetClass");
    expect(txtInspectionSC95.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_txtInspectionSC95")
    );
    await act(async () => {
      userEvent.type(txtInspectionSC95, "1");
    });
  });
  test("Custom Test Cases for txtInspectionSC95", () => {
    // START_USER_CODE-USER_txtInspectionSC95_TEST
    // END_USER_CODE-USER_txtInspectionSC95_TEST
  });
  test("txtProceeds(Textbox Widget) Test Cases", async () => {
    const txtProceeds = screen.getByTestId("txtProceeds");
    expect(txtProceeds.tagName).toBe("INPUT");
    expect(txtProceeds.type).toBe("text");
    expect(txtProceeds.classList).toContain("textboxWidgetClass");
    expect(txtProceeds.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_txtProceeds")
    );
    await act(async () => {
      userEvent.type(txtProceeds, "1");
    });
  });
  test("Custom Test Cases for txtProceeds", () => {
    // START_USER_CODE-USER_txtProceeds_TEST
    // END_USER_CODE-USER_txtProceeds_TEST
  });
  test("txtReason(Textbox Widget) Test Cases", async () => {
    const txtReason = screen.getByTestId("txtReason");
    expect(txtReason.tagName).toBe("INPUT");
    expect(txtReason.type).toBe("text");
    expect(txtReason.classList).toContain("textboxWidgetClass");
    expect(txtReason.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_txtReason")
    );
    await act(async () => {
      userEvent.type(txtReason, "1");
    });
  });
  test("Custom Test Cases for txtReason", () => {
    // START_USER_CODE-USER_txtReason_TEST
    // END_USER_CODE-USER_txtReason_TEST
  });
  test("txtSettlement1007(Textbox Widget) Test Cases", async () => {
    const txtSettlement1007 = screen.getByTestId("txtSettlement1007");
    expect(txtSettlement1007.tagName).toBe("INPUT");
    expect(txtSettlement1007.type).toBe("text");
    expect(txtSettlement1007.classList).toContain("textboxWidgetClass");
    expect(txtSettlement1007.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_txtSettlement1007")
    );
    await act(async () => {
      userEvent.type(txtSettlement1007, "1");
    });
  });
  test("Custom Test Cases for txtSettlement1007", () => {
    // START_USER_CODE-USER_txtSettlement1007_TEST
    // END_USER_CODE-USER_txtSettlement1007_TEST
  });
  test("txtState(Textbox Widget) Test Cases", async () => {
    const txtState = screen.getByTestId("txtState");
    expect(txtState.tagName).toBe("INPUT");
    expect(txtState.type).toBe("text");
    expect(txtState.classList).toContain("textboxWidgetClass");
    expect(txtState.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_txtState")
    );
    await act(async () => {
      userEvent.type(txtState, "1");
    });
  });
  test("Custom Test Cases for txtState", () => {
    // START_USER_CODE-USER_txtState_TEST
    // END_USER_CODE-USER_txtState_TEST
  });
  test("txtTradeInspection(Textbox Widget) Test Cases", async () => {
    const txtTradeInspection = screen.getByTestId("txtTradeInspection");
    expect(txtTradeInspection.tagName).toBe("INPUT");
    expect(txtTradeInspection.type).toBe("text");
    expect(txtTradeInspection.classList).toContain("textboxWidgetClass");
    expect(txtTradeInspection.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_txtTradeInspection")
    );
    await act(async () => {
      userEvent.type(txtTradeInspection, "1");
    });
  });
  test("Custom Test Cases for txtTradeInspection", () => {
    // START_USER_CODE-USER_txtTradeInspection_TEST
    // END_USER_CODE-USER_txtTradeInspection_TEST
  });
  test("txtTradeSettlement(Textbox Widget) Test Cases", async () => {
    const txtTradeSettlement = screen.getByTestId("txtTradeSettlement");
    expect(txtTradeSettlement.tagName).toBe("INPUT");
    expect(txtTradeSettlement.type).toBe("text");
    expect(txtTradeSettlement.classList).toContain("textboxWidgetClass");
    expect(txtTradeSettlement.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_txtTradeSettlement")
    );
    await act(async () => {
      userEvent.type(txtTradeSettlement, "1");
    });
  });
  test("Custom Test Cases for txtTradeSettlement", () => {
    // START_USER_CODE-USER_txtTradeSettlement_TEST
    // END_USER_CODE-USER_txtTradeSettlement_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPMaint_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVndr(Textbox Widget) Test Cases", async () => {
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVndr, "1");
    });
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
  test("txtWarehouseReceipt(Textbox Widget) Test Cases", async () => {
    const txtWarehouseReceipt = screen.getByTestId("txtWarehouseReceipt");
    expect(txtWarehouseReceipt.tagName).toBe("INPUT");
    expect(txtWarehouseReceipt.type).toBe("text");
    expect(txtWarehouseReceipt.classList).toContain("textboxWidgetClass");
    expect(txtWarehouseReceipt.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPMaint_txtWarehouseReceipt"
      )
    );
    await act(async () => {
      userEvent.type(txtWarehouseReceipt, "1");
    });
  });
  test("Custom Test Cases for txtWarehouseReceipt", () => {
    // START_USER_CODE-USER_txtWarehouseReceipt_TEST
    // END_USER_CODE-USER_txtWarehouseReceipt_TEST
  });
});
