/* eslint-disable*/
import React from "react";
import LabelWidget from "./LabelWidget";
import { screen } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { useTranslation } from "../../../../__mocks__/react-i18next";
import { cleanup } from "@testing-library/react";

describe("DateWidget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
     let labelwidget1= {
      name: "labelwidget1",
      type: "LabelWidget",
      parent: "groupboxwidget",
      Label: "Label Widget",
      ShortcutKey: "x",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String"
    };
	 let stateScreenConf={
		Label: "TestScreen",
		windowName: "TestScreen",
		template: "Standard Screen",
		Cols: "3",
		ColsForMobile: "3",
		ColsForTabLandscape: "3",
		ColsForTabPotrait: "3",
		isResponsiveLayout: false,
		Height: "",
		Left: "",
		Top: "",
		Width: "",
		i18Key: "TestGUI.TestScreen"
	 };
	 let values={};
    renderTestScreen(<LabelWidget values={values} conf={labelwidget1} screenConf={stateScreenConf}/>);
    });
  });
  afterEach(cleanup);
  test("Label Widget Test Cases", async () => {
    const labelwidget1 = screen.getByTestId("labelwidget1");
    expect(labelwidget1.tagName).toBe("LABEL");
    expect(labelwidget1.classList).toContain("form-label");
    expect(labelwidget1.textContent).toEqual("Label Widget");
  });
})
describe("DateWidget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
     let labelwidget1= {
      name: "labelwidget1",
      type: "LabelWidget",
      parent: "groupboxwidget",
      Label: "Label Widget",
      ShortcutKey: "x",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String"
    };
	 let stateScreenConf={
		Label: "TestScreen",
		windowName: "TestScreen",
		template: "Standard Screen",
		Cols: "3",
		ColsForMobile: "3",
		ColsForTabLandscape: "3",
		ColsForTabPotrait: "3",
		isResponsiveLayout: false,
		Height: "",
		Left: "",
		Top: "",
		Width: "",
		i18Key: "TestGUI.TestScreen",
    horizontalForm: true
	 };
	 let values={};
    renderTestScreen(<LabelWidget values={values} conf={labelwidget1} screenConf={stateScreenConf}/>);
    });
  });
  afterEach(cleanup);
  test("Label Widget Test Cases for Horizontal Form", async () => {
    const labelwidget1 = screen.getByTestId("labelwidget1");
    expect(labelwidget1.tagName).toBe("LABEL");
    expect(labelwidget1.classList).toContain("form-label");
    expect(labelwidget1.textContent).toEqual("Label Widget");
  });
})