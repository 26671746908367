/* eslint-disable*/
import React, { createContext, useState } from 'react';

const CommonContext = createContext({
    locationList: [],
    addLocation: (locationList) => {},

    getContractProfile_ContractNumber: '',
    setContractProfile_ContractNumber: (contractNumber) => {},

    getWR_WRNumber: '',
    setWR_WRNumber: (WRNumber) => {},

    getStockTransfer_Number: '',
    setStockTransfer_Number: (StockTransNumber) => {},

    getDelvAgree_Number: '',
    setDelvAgree_Number: (DelvAgreeNumber) => {},

    getContractProfile_ButtonEventName: '',
    setContractProfile_ButtonEventName: (eventName) => {},

    getContractProfile_ReportRequest: '',
    setContractProfile_ReportRequest: (rptRequest) => {},

    getContractProfile_ContractGroupingNumber: '',
    setContractProfile_ContractGroupingNumber: (contractGroupingNumber) => {},

    getContractProfile_MinorityStatus: '',
    setContractProfile_MinorityStatus: (minorityStatus) => {},

    getContractProfile_VendorId: '',
    setContractProfile_VendorId: (VendorId) => {},

    getContractProfile_VendorName: '',
    setContractProfile_VendorName: (VendorName) => {},

    getContractProfile_BtnRescanStatus: '',
    setContractProfile_BtnRescanStatus: (btnRescanStatus) => {},

    getContractProfile_BuyingPointId: '',
    setContractProfile_BuyingPointId: (buyingPointId) => {},

    getWR_BuyingPointId: '',
    setWR_BuyingPointId: (buyingPointId) => {},

    getStockTrans_BuyingPointId: '',
    setStockTrans_BuyingPointId: (buyingPointId) => {},

    getDelvAgree_BuyingPointId: '',
    setDelvAgree_BuyingPointId: (buyingPointId) => {},

    getContractProfile_FormState: 'add',
    setContractProfile_FormState: (formState) => {},

    getTradeSettleSearch_InspNumber: '',
    setTradeSettleSearch_InspNumber: (InspNumber) => {},

    bFillPounds:[],
    setbFillPounds: (bFillPounds) =>{},

    //ExceptPremCreate
    getExceptPremCreate_MemoNum:'',
    setExceptPremCreate_MemoNum:(memoNum)=>{},

    getReviewCheck_BuyingPointid :'',
    setReviewCheck_BuyingPointid : (buying_Point)=>{},

    getViewWarehouseReceipt_BuyingPointId :'',
    setViewWarehouseReceipt_BuyingPointId : (buying_Point)=>{},
    
    getViewWarehouseReceipt_AuditWhseRcpt :'',
    setViewWarehouseReceipt_AuditWhseRcpt : (AuditWhseRcpt)=>{},

    getReviewCheck_sReceivableNum :'',
    setReviewCheck_sReceivableNum : (sReceivableNum)=>{},

    getReviewCheck_sCheckNum :'',
    setReviewCheck_sCheckNum : (sCheckNum)=>{},

    getDataForExceptPremiumAdjustment:[],
    setDataForExceptPremiumAdjustment: (data) => {},

    getContractProfile_ContractType: '',
    setContractProfile_ContractType: (contractType) => {},

    getContractProfile_HarvestDate: '',
    setContractProfile_HarvestDate: (harvestDate) => {},

    getFlexMktPrint_PrintType: '',
    setFlexMktPrint_PrintType: (printType) => {},

    getFlexMktPrint_PriceSuffix : '',
    setFlexMktPrint_PriceSuffix : (price_suffix) => {},

    getFlexMktPrint_PriceLine : '',
    setFlexMktPrint_PriceLine : (price_line) =>  {},

    getFlexMktPrint_ContractPricingList : '',
    setFlexMktPrint_ContractPricingList : (pricingList) => {},

    getFarmInquiry_InquiryEdit :'',
    setFarmInquiry_InquiryEdit : (editBtnData)=>{},

    getFormdirty_ContractProfile:'',
    setFormDirty_Contractprofile: (formDirty)=>{},
    getDataForReviewAcctDist:[],
    setDataForReviewAcctDist: (data) => {},

    getTradeSettleProfile_BuyingPointId:'',
    setTradeSettleProfile_BuyingPointId:(buyingPoint) => {},

    getTradeSettleProfile_sInspNum:'',
    setTradeSettleProfile_sInspNum:(inspNumber) => {},

    getViewSettlement_BuyingPointId : '',
    setViewSettlement_BuyingPointId : (buyingPt) => {},

    getViewSettlement_AuditInspNum : '',
    setViewSettlement_AuditInspNum : (inspNumber) => {},

    getStockTransfer_crossClick : '',
    setStockTransfer_crossClick : (flag) => {},
});


export function CommonContextProvider(props) {
    const [useLocation, setLocation] = useState([]);
    const [useContractNumber, setContractNumber] = useState();
    const [useWRNumber, setWRNumber] = useState();
    const [useStockTransNumber, setStockTransferNumber] = useState();
    const [useDelvAgreeNumber, setDelvAgreeNumber] = useState();
    const [useEventName, setEventName] = useState();
    const [useContractGroupingNumber, setContractGroupingNumber] = useState();
    const [useBuyingPointId, setBuyingPointId] = useState();
    const [useFormState, setFormState] = useState('');
    const [usebFillPounds, setbFillPounds] = useState([]);
    const[useInsp,setInsp]=useState([]);
    // ExceptPremCreate
    const [useMemoNum, setMemoNum] = useState();
    const [useBuyingPointid, setBuyingPointid] = useState();
    const [usesReceivableNum, setsReceivableNum] = useState();
    const [usesCheckNum, setsCheckNum] = useState();
    const [useAuditWhseRcpt, setAuditWhseRcpt] = useState();
    const [useDataForExceptPremiumAdjustment, setDataForExceptPremiumAdjustment] = useState([]);
    const[useDataForReviewAcctDist, setDataForReviewAcctDist] =useState([])
    const [useMinorityStatus, setMinorityStatus] = useState();
    const [useVendorId, setVendorId] = useState();
    const [useVendorName, setVendorName] = useState();
    const [useBtnRescanStatus, setBtnRescanStatus] = useState();
    const [useContractType, setContractType] = useState();
    const [useHarvestDate, setHarvestDate] = useState();
    const [usePrintType, setPrintType ] = useState();
    const [usePriceSuffix, setPriceSuffix] = useState();
    const [usePriceLine, setPriceLine] = useState();
    const [useRptRequest, setRptRequest] = useState();
    const [useContractPricingList, setContractPricingList] = useState();
    const [useInquiryEdit,setInquiryEdit] = useState();
    const [useFormDirty,setFormDirty1] = useState();
    const [useStockTransferCrossClick,setuseStockTransferCrossClick] = useState();

    function addLocationHandler(locationList) {
        setLocation(locationList);
    }

    function setContractProfile_ContractNumberHandler(contractNumber) {
        setContractNumber(contractNumber);
    }

    function setWR_WRNumberHandler(WRNumber) {
        setWRNumber(WRNumber);
    }

    function setStockTransfer_NumberHandler(STNumber) {
        setStockTransferNumber(STNumber);
    }

    function setDelvAgree_NumberHandler(DelvAgreeNumber) {
        setDelvAgreeNumber(DelvAgreeNumber);
    }

    function setContractProfile_EventNameHandler(eventName) {
        setEventName(eventName);
    }

    function setContractProfile_ReportRequestHandler(rptRequest) {
        setRptRequest(rptRequest);
    }

    function setContractProfile_ContractGroupingNumberHandler(contractGroupingNumber) {
        setContractGroupingNumber(contractGroupingNumber);
    }

    function setContractProfile_MinorityStatusHandler(minorityStatus) {
        setMinorityStatus(minorityStatus);
    }

    function setContractProfile_VendorIdHandler(vendorId) {
        setVendorId(vendorId);
    }

    function setContractProfile_VendorNameHandler(vendorName) {
        setVendorName(vendorName);
    }

    function setContractProfile_BtnRescanStatusHandler(btnRescanStatus) {
        setBtnRescanStatus(btnRescanStatus);
    }

    function setContractProfile_BuyingPointIdHandler(buyingPointId) {
        setBuyingPointId(buyingPointId);
    }

    function setWR_BuyingPointIdHandler(buyingPointId) {
        setBuyingPointId(buyingPointId);
    }

    function setStockTrans_BuyingPointIdHandler(buyingPointId) {
        setBuyingPointId(buyingPointId);
    }

    function setDelvAgree_BuyingPointIdHandler(buyingPointId) {
        setBuyingPointId(buyingPointId);
    }

    function setTradeSettleProfile_BuyingPointIdHandler(buyingPointId) {
        setBuyingPointId(buyingPointId);
    }
    function setContractProfile_FormStateHandler(ContractProfile_FormState) {
        setFormState(ContractProfile_FormState);
    }

    function setbFillPoundsHandler(bFillPounds) {
        setbFillPounds(bFillPounds);
    }
    //ExceptPremCreate
    function settingExceptPremCreate_MemoNum(memoNum){
        setMemoNum(memoNum)
    }

    function setReviewCheck_BuyingPointid(buying_Point){
        setBuyingPointid(buying_Point)
    }
    function setReviewCheck_sReceivableNum(sReceivableNum){
        setsReceivableNum(sReceivableNum)
    }
    function setReviewCheck_sCheckNum(sCheckNum){
        setsCheckNum(sCheckNum)
    }
    function setViewWarehouseReceipt_BuyingPointId(buying_Point){
        setBuyingPointId(buying_Point)
    }
    function setViewWarehouseReceipt_AuditWhseRcpt(AuditWhseRcpt){
        setAuditWhseRcpt(AuditWhseRcpt)
    }

    function setDataForExceptPremiumAdjustmentHandler(data){
        setDataForExceptPremiumAdjustment(data)
    }

    function setContractProfile_ContractTypeHandler(contractType) {
        setContractType(contractType);
    }

    function setContractProfile_HarvestDateHandler(harvestDate) {
        setHarvestDate(harvestDate);
    }

    function setFlexMkt_PrintTypeHandler(printType){
        setPrintType(printType);
    }

    function setFlexMktPrint_PriceSuffixHandler(price_suffix){
        setPriceSuffix(price_suffix);
    }

    function setFlexMktPrint_PriceLineHandler(price_line){
        setPriceLine(price_line);
    }

    function setFlexMktPrint_ContractPricingListHandler(pricing_list){
        setContractPricingList(pricing_list);
    }

    function setInsp_InspHandler(Insp_num){
        setInsp(Insp_num);
    }

        function setFarmInquiry_InquiryEdit(editBtnData){
        setInquiryEdit(editBtnData)
    }
  
    function setFormDirty1_handler(formDirty){
        setFormDirty1(formDirty)
    }
    function setDataForReviewAcctDistHandler(data){
        setDataForReviewAcctDist(data)
    }
    function setTradeSettleProfile_BuyingPointIdHandler(buyingPoint){
        setBuyingPointId(buyingPoint);
    }
    function setTradeSettleProfile_sInspNum(inspNumber){
        setInsp(inspNumber)
    }
    function setViewSettlement_BuyingPointId(buyingPt){
        setBuyingPointId(buyingPt)
    }
    function setViewSettlement_AuditInspNum(inspNum){
        setInsp(inspNum)
    }

    function setStockTransfer_crossClick(flag){
        setuseStockTransferCrossClick(flag)
    }

    const context = {
        locationList: useLocation,
        addLocation: addLocationHandler,

        getContractProfile_ContractNumber: useContractNumber,
        setContractProfile_ContractNumber: setContractProfile_ContractNumberHandler,

        getWR_WRNumber: useWRNumber,
        setWR_WRNumber: setWR_WRNumberHandler,

        getStockTransfer_Number: useStockTransNumber,
        setStockTransfer_Number: setStockTransfer_NumberHandler,

        getDelvAgree_Number: useDelvAgreeNumber,
        setDelvAgree_Number: setDelvAgree_NumberHandler,

        getContractProfile_ButtonEventName: useEventName,
        setContractProfile_ButtonEventName: setContractProfile_EventNameHandler,

        getContractProfile_ReportRequest: useRptRequest,
        setContractProfile_ReportRequest: setContractProfile_ReportRequestHandler,

        setContractProfile_ContractGroupingNumber: setContractProfile_ContractGroupingNumberHandler,

        getContractProfile_MinorityStatus: useMinorityStatus,
        setContractProfile_MinorityStatus: setContractProfile_MinorityStatusHandler,

        getContractProfile_VendorId: useVendorId,
        setContractProfile_VendorId: setContractProfile_VendorIdHandler,

        getContractProfile_VendorName: useVendorName,
        setContractProfile_VendorName: setContractProfile_VendorNameHandler,

        getContractProfile_BtnRescanStatus: useBtnRescanStatus,
        setContractProfile_BtnRescanStatus: setContractProfile_BtnRescanStatusHandler,

        getContractProfile_BuyingPointId: useBuyingPointId,
        setContractProfile_BuyingPointId: setContractProfile_BuyingPointIdHandler,

        getWR_BuyingPointId: useBuyingPointId,
        setWR_BuyingPointId: setWR_BuyingPointIdHandler,

        getStockTrans_BuyingPointId: useBuyingPointId,
        setStockTrans_BuyingPointId: setStockTrans_BuyingPointIdHandler,

        getDelvAgree_BuyingPointId: useBuyingPointId,
        setDelvAgree_BuyingPointId: setDelvAgree_BuyingPointIdHandler,

        getTradeSettleProfile_BuyingPointId: useBuyingPointId,
        setTradeSettleProfile_BuyingPointId: setTradeSettleProfile_BuyingPointIdHandler,

        getContractProfile_FormState: useFormState,
        setContractProfile_FormState: setContractProfile_FormStateHandler,

        getTradeSettleSearch_FormSate: useInsp,
        setTradesettelesearch_FormState: setInsp_InspHandler,

      
        bFillPounds: usebFillPounds,
        setbFillPounds : setbFillPoundsHandler,

        //ExceptPremCreate
        getExceptPremCreate_MemoNum:useMemoNum,
        setExceptPremCreate_MemoNum:settingExceptPremCreate_MemoNum,

        getReviewCheck_BuyingPointid :useBuyingPointid,
        setReviewCheck_BuyingPointid :setReviewCheck_BuyingPointid ,

        getReviewCheck_sReceivableNum :usesReceivableNum,
        setReviewCheck_sReceivableNum :setReviewCheck_sReceivableNum,

        getReviewCheck_sCheckNum :usesCheckNum,
        setReviewCheck_sCheckNum : setReviewCheck_sCheckNum,

        getViewWarehouseReceipt_BuyingPointId :useBuyingPointId,
        setViewWarehouseReceipt_BuyingPointId :setViewWarehouseReceipt_BuyingPointId,

        getViewWarehouseReceipt_AuditWhseRcpt :useAuditWhseRcpt,
        setViewWarehouseReceipt_AuditWhseRcpt : setViewWarehouseReceipt_AuditWhseRcpt,

        getDataForExceptPremiumAdjustment:useDataForExceptPremiumAdjustment,
        setDataForExceptPremiumAdjustment:setDataForExceptPremiumAdjustmentHandler,

        getContractProfile_ContractType: useContractType,
        setContractProfile_ContractType: setContractProfile_ContractTypeHandler,

        getContractProfile_HarvestDate: useHarvestDate,
        setContractProfile_HarvestDate: setContractProfile_HarvestDateHandler,

        getFlexMktPrint_PrintType : usePrintType,
        setFlexMktPrint_PrintType : setFlexMkt_PrintTypeHandler,

        getFlexMktPrint_PriceSuffix : usePriceSuffix,
        setFlexMktPrint_PriceSuffix : setFlexMktPrint_PriceSuffixHandler, 

        getFlexMktPrint_PriceLine : usePriceLine,
        setFlexMktPrint_PriceLine : setFlexMktPrint_PriceLineHandler,

        getFlexMktPrint_ContractPricingList : useContractPricingList,
        setFlexMktPrint_ContractPricingList : setFlexMktPrint_ContractPricingListHandler,

        getFarmInquiry_InquiryEdit : useInquiryEdit,
        setFarmInquiry_InquiryEdit : setFarmInquiry_InquiryEdit,

        getFormdirty_ContractProfile: useFormDirty,
        setFormDirty_Contractprofile: setFormDirty1_handler,
        getDataForReviewAcctDist:useDataForReviewAcctDist,
        setDataForReviewAcctDist:setDataForReviewAcctDistHandler,

        getTradeSettleProfile_sInspNum: useInsp,
        setTradeSettleProfile_sInspNum: setTradeSettleProfile_sInspNum,        

        getTradeSettleProfile_BuyingPointId: useBuyingPointId,
        setTradeSettleProfile_BuyingPointId: setTradeSettleProfile_BuyingPointIdHandler,

        getViewSettlement_BuyingPointId : useBuyingPointId,
        setViewSettlement_BuyingPointId : setViewSettlement_BuyingPointId,

        getViewSettlement_AuditInspNum : useInsp,
        setViewSettlement_AuditInspNum : setViewSettlement_AuditInspNum,

        getStockTransfer_crossClick : useStockTransferCrossClick,
        setStockTransfer_crossClick : setuseStockTransferCrossClick,
    };

    return (
    <CommonContext.Provider value={context}>
        {props.children}
    </CommonContext.Provider>
    );
}

export default CommonContext;