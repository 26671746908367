/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
} from "../../shared/WindowImports";

import "./FreightMenu.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FreightMenu(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FreightMenu",
    windowName: "FreightMenu",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FreightMenu",
    // START_USER_CODE-USER_FreightMenu_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Freight Menu",
      scrCode: "PN0250",
    },
    // END_USER_CODE-USER_FreightMenu_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnFreightLocToLocMileage: {
      name: "btnFreightLocToLocMileage",
      type: "ButtonWidget",
      parent: "grpbxFreightMenu",
      Label: "Freight Loc to Loc Mileage",
      CharWidth: "52",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnFreightLocToLocMileage_PROPERTIES

      // END_USER_CODE-USER_btnFreightLocToLocMileage_PROPERTIES
    },
    btnFreightRate: {
      name: "btnFreightRate",
      type: "ButtonWidget",
      parent: "grpbxFreightMenu",
      Label: "Freight Rate",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnFreightRate_PROPERTIES

      // END_USER_CODE-USER_btnFreightRate_PROPERTIES
    },
    btnFuelSurchargeInquiry: {
      name: "btnFuelSurchargeInquiry",
      type: "ButtonWidget",
      parent: "grpbxFreightMenu",
      Label: "Fuel Surcharge Inquiry",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnFuelSurchargeInquiry_PROPERTIES

      // END_USER_CODE-USER_btnFuelSurchargeInquiry_PROPERTIES
    },
    btnLeasedTrailerControl: {
      name: "btnLeasedTrailerControl",
      type: "ButtonWidget",
      parent: "grpbxFreightMenu",
      Label: "Leased Trailer Control",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnLeasedTrailerControl_PROPERTIES

      // END_USER_CODE-USER_btnLeasedTrailerControl_PROPERTIES
    },
    grpbxFreightMenu: {
      name: "grpbxFreightMenu",
      type: "GroupBoxWidget",
      parent: "FreightMenu",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxFreightMenu_PROPERTIES

      // END_USER_CODE-USER_grpbxFreightMenu_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "FreightMenu",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceMasterManagement#FreightRateSetup": {},
        "SystemMaintenanceMasterManagement#FuelSurchargeInquiry":{},
        "SystemMaintenanceMasterManagement#FreightLocToLocSetup":{},
        "SystemMaintenanceMasterManagement#LeasedTrailerProfile":{}
      },
      REVERSE: {
        "SystemMaintenanceMasterManagement#FreightRateSetup": {},
        "SystemMaintenanceMasterManagement#FuelSurchargeInquiry":{},
        "SystemMaintenanceMasterManagement#FreightLocToLocSetup":{},
        "SystemMaintenanceMasterManagement#LeasedTrailerProfile":{}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
    btnFreightRate:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#FreightRateSetup#DEFAULT#true#Click",]
    },
    btnFuelSurchargeInquiry:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#FuelSurchargeInquiry#DEFAULT#true#Click",]
    },
    btnFreightLocToLocMileage:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#FreightLocToLocSetup#DEFAULT#true#Click",]
    },
    btnLeasedTrailerControl:{
      DEFAULT:[
      "SystemMaintenanceMasterManagement#LeasedTrailerProfile#DEFAULT#true#Click",]
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FreightMenu*/}

              {/* END_USER_CODE-USER_BEFORE_FreightMenu*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFreightMenu*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFreightMenu*/}

              <GroupBoxWidget conf={state.grpbxFreightMenu} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnFreightLocToLocMileage*/}

                {/* END_USER_CODE-USER_BEFORE_btnFreightLocToLocMileage*/}

                <ButtonWidget
                  conf={state.btnFreightLocToLocMileage}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnFreightLocToLocMileage*/}

                {/* END_USER_CODE-USER_AFTER_btnFreightLocToLocMileage*/}
                {/* START_USER_CODE-USER_BEFORE_btnLeasedTrailerControl*/}

                {/* END_USER_CODE-USER_BEFORE_btnLeasedTrailerControl*/}

                <ButtonWidget
                  conf={state.btnLeasedTrailerControl}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnLeasedTrailerControl*/}

                {/* END_USER_CODE-USER_AFTER_btnLeasedTrailerControl*/}
                {/* START_USER_CODE-USER_BEFORE_btnFreightRate*/}

                {/* END_USER_CODE-USER_BEFORE_btnFreightRate*/}

                <ButtonWidget
                  conf={state.btnFreightRate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnFreightRate*/}

                {/* END_USER_CODE-USER_AFTER_btnFreightRate*/}
                {/* START_USER_CODE-USER_BEFORE_btnFuelSurchargeInquiry*/}

                {/* END_USER_CODE-USER_BEFORE_btnFuelSurchargeInquiry*/}

                <ButtonWidget
                  conf={state.btnFuelSurchargeInquiry}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnFuelSurchargeInquiry*/}

                {/* END_USER_CODE-USER_AFTER_btnFuelSurchargeInquiry*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFreightMenu*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFreightMenu*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_FreightMenu*/}

              {/* END_USER_CODE-USER_AFTER_FreightMenu*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_FreightMenu);
