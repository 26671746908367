/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_PremiumDeductionContractProfile from "./PremiumDeductionContractProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PremiumDeductionContractProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PremiumDeductionContractProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is PremiumDeductionContractProfile Loads Successfully", () => {
    expect(screen.getByText("PremiumDeductionContractProfile"))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for PremiumDeductionContractProfile", () => {
    // START_USER_CODE-USER_PremiumDeductionContractProfile_Custom_Test_Case
    // END_USER_CODE-USER_PremiumDeductionContractProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PremiumDeductionContractProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionContractProfile_btnCancel"
      )
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionContractProfile_btnOk"
      )
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxPremiumDeductionContractProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxPremiumDeductionContractProfile = screen.getByTestId(
      "grpbxPremiumDeductionContractProfile"
    );
    expect(grpbxPremiumDeductionContractProfile.tagName).toBe("BUTTON");
    expect(grpbxPremiumDeductionContractProfile.type).toBe("button");
    expect(grpbxPremiumDeductionContractProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremiumDeductionContractProfile", () => {
    // START_USER_CODE-USER_grpbxPremiumDeductionContractProfile_TEST
    // END_USER_CODE-USER_grpbxPremiumDeductionContractProfile_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionContractProfile_lblAddedBy"
      )
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionContractProfile_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionContractProfile_lblChangedBy"
      )
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionContractProfile_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("txtBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtBuyingPoint = screen.getByTestId("txtBuyingPoint");
    expect(txtBuyingPoint.tagName).toBe("INPUT");
    expect(txtBuyingPoint.type).toBe("text");
    expect(txtBuyingPoint.classList).toContain("textboxWidgetClass");
    expect(txtBuyingPoint.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionContractProfile_txtBuyingPoint"
      )
    );
    await act(async () => {
      userEvent.type(txtBuyingPoint, "1");
    });
  });
  test("Custom Test Cases for txtBuyingPoint", () => {
    // START_USER_CODE-USER_txtBuyingPoint_TEST
    // END_USER_CODE-USER_txtBuyingPoint_TEST
  });
  test("txtContract(Textbox Widget) Test Cases", async () => {
    const txtContract = screen.getByTestId("txtContract");
    expect(txtContract.tagName).toBe("INPUT");
    expect(txtContract.type).toBe("text");
    expect(txtContract.classList).toContain("textboxWidgetClass");
    expect(txtContract.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionContractProfile_txtContract"
      )
    );
    await act(async () => {
      userEvent.type(txtContract, "1");
    });
  });
  test("Custom Test Cases for txtContract", () => {
    // START_USER_CODE-USER_txtContract_TEST
    // END_USER_CODE-USER_txtContract_TEST
  });
  test("txtPremDeductCode(Textbox Widget) Test Cases", async () => {
    const txtPremDeductCode = screen.getByTestId("txtPremDeductCode");
    expect(txtPremDeductCode.tagName).toBe("INPUT");
    expect(txtPremDeductCode.type).toBe("text");
    expect(txtPremDeductCode.classList).toContain("textboxWidgetClass");
    expect(txtPremDeductCode.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionContractProfile_txtPremDeductCode"
      )
    );
    await act(async () => {
      userEvent.type(txtPremDeductCode, "1");
    });
  });
  test("Custom Test Cases for txtPremDeductCode", () => {
    // START_USER_CODE-USER_txtPremDeductCode_TEST
    // END_USER_CODE-USER_txtPremDeductCode_TEST
  });
});
