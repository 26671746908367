/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_DiscountScheduleSetup from "./DiscountScheduleSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DiscountScheduleSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_DiscountScheduleSetup />
      );
    });
  });
  afterEach(cleanup);
  test("is DiscountScheduleSetup Loads Successfully", () => {
    expect(screen.getByText("Discount Schedule Setup")).toBeInTheDocument;
  });
  test("Custom Test Cases for DiscountScheduleSetup", () => {
    // START_USER_CODE-USER_DiscountScheduleSetup_Custom_Test_Case
    // END_USER_CODE-USER_DiscountScheduleSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_DiscountScheduleSetup />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:DiscountScheduleSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:DiscountScheduleSetup_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:DiscountScheduleSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:DiscountScheduleSetup_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("gridDiscountScheduleSetup(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleSetup = screen.getByTestId(
      "gridDiscountScheduleSetup"
    );
    let gridDiscountScheduleSetupbtn =
      gridDiscountScheduleSetup.nextElementSibling.firstElementChild;
    gridDiscountScheduleSetup =
      gridDiscountScheduleSetup.parentElement.parentElement.parentElement;
    expect(gridDiscountScheduleSetup.tagName).toBe("DIV");
    expect(gridDiscountScheduleSetup.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleSetup_gridDiscountScheduleSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridDiscountScheduleSetup", () => {
    // START_USER_CODE-USER_gridDiscountScheduleSetup_TEST
    // END_USER_CODE-USER_gridDiscountScheduleSetup_TEST
  });
  test("grpbxDiscountSchedule(GroupBox Widget) Test Cases", async () => {
    const grpbxDiscountSchedule = screen.getByTestId("grpbxDiscountSchedule");
    expect(grpbxDiscountSchedule.tagName).toBe("BUTTON");
    expect(grpbxDiscountSchedule.type).toBe("button");
    expect(grpbxDiscountSchedule.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDiscountSchedule", () => {
    // START_USER_CODE-USER_grpbxDiscountSchedule_TEST
    // END_USER_CODE-USER_grpbxDiscountSchedule_TEST
  });
  test("lblDiscountSchedule(Label Widget) Test Cases", async () => {
    const lblDiscountSchedule = screen.getByTestId("lblDiscountSchedule");
    expect(lblDiscountSchedule.tagName).toBe("LABEL");
    expect(lblDiscountSchedule.classList).toContain("form-label");
    expect(lblDiscountSchedule.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:DiscountScheduleSetup_lblDiscountSchedule"
      )
    );
  });
  test("Custom Test Cases for lblDiscountSchedule", () => {
    // START_USER_CODE-USER_lblDiscountSchedule_TEST
    // END_USER_CODE-USER_lblDiscountSchedule_TEST
  });
  test("gridDiscountScheduleSetup_txtcolDiscountPerTon(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleSetup_txtcolDiscountPerTon = screen.getByTestId(
      "gridDiscountScheduleSetup"
    );
    let gridDiscountScheduleSetup_txtcolDiscountPerTonbtn =
      gridDiscountScheduleSetup_txtcolDiscountPerTon.nextElementSibling
        .firstElementChild;
    gridDiscountScheduleSetup_txtcolDiscountPerTon =
      gridDiscountScheduleSetup_txtcolDiscountPerTon.parentElement.parentElement
        .parentElement;
    expect(gridDiscountScheduleSetup_txtcolDiscountPerTon.tagName).toBe("DIV");
    expect(gridDiscountScheduleSetup_txtcolDiscountPerTon.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleSetup_gridDiscountScheduleSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDiscountPerTon", () => {
    // START_USER_CODE-USER_txtcolDiscountPerTon_TEST
    // END_USER_CODE-USER_txtcolDiscountPerTon_TEST
  });
  test("gridDiscountScheduleSetup_txtcolReadingFrom(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleSetup_txtcolReadingFrom = screen.getByTestId(
      "gridDiscountScheduleSetup"
    );
    let gridDiscountScheduleSetup_txtcolReadingFrombtn =
      gridDiscountScheduleSetup_txtcolReadingFrom.nextElementSibling
        .firstElementChild;
    gridDiscountScheduleSetup_txtcolReadingFrom =
      gridDiscountScheduleSetup_txtcolReadingFrom.parentElement.parentElement
        .parentElement;
    expect(gridDiscountScheduleSetup_txtcolReadingFrom.tagName).toBe("DIV");
    expect(gridDiscountScheduleSetup_txtcolReadingFrom.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleSetup_gridDiscountScheduleSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReadingFrom", () => {
    // START_USER_CODE-USER_txtcolReadingFrom_TEST
    // END_USER_CODE-USER_txtcolReadingFrom_TEST
  });
  test("gridDiscountScheduleSetup_txtcolReadingTo(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleSetup_txtcolReadingTo = screen.getByTestId(
      "gridDiscountScheduleSetup"
    );
    let gridDiscountScheduleSetup_txtcolReadingTobtn =
      gridDiscountScheduleSetup_txtcolReadingTo.nextElementSibling
        .firstElementChild;
    gridDiscountScheduleSetup_txtcolReadingTo =
      gridDiscountScheduleSetup_txtcolReadingTo.parentElement.parentElement
        .parentElement;
    expect(gridDiscountScheduleSetup_txtcolReadingTo.tagName).toBe("DIV");
    expect(gridDiscountScheduleSetup_txtcolReadingTo.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleSetup_gridDiscountScheduleSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReadingTo", () => {
    // START_USER_CODE-USER_txtcolReadingTo_TEST
    // END_USER_CODE-USER_txtcolReadingTo_TEST
  });
  test("gridDiscountScheduleSetup_txtcolType(Grid Widget) Test Cases", async () => {
    let gridDiscountScheduleSetup_txtcolType = screen.getByTestId(
      "gridDiscountScheduleSetup"
    );
    let gridDiscountScheduleSetup_txtcolTypebtn =
      gridDiscountScheduleSetup_txtcolType.nextElementSibling.firstElementChild;
    gridDiscountScheduleSetup_txtcolType =
      gridDiscountScheduleSetup_txtcolType.parentElement.parentElement
        .parentElement;
    expect(gridDiscountScheduleSetup_txtcolType.tagName).toBe("DIV");
    expect(gridDiscountScheduleSetup_txtcolType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:DiscountScheduleSetup_gridDiscountScheduleSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType", () => {
    // START_USER_CODE-USER_txtcolType_TEST
    // END_USER_CODE-USER_txtcolType_TEST
  });
});
