/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  CheckboxWidget,
  setValue,
  getValue,
  setData,
  getData,
  goTo,
  hide,
  show,
  enable,
  disable,
  getSelectedRowNumber,
  hideColumn,
  showColumn
} from "../../shared/WindowImports";

import "./DeductionTrackingSummary.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_DeductionTrackingSummary(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let cropyear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  let buyingPointId = JSON.parse(sessionStorage.getItem('lstBuyingPointID'))
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DeductionTrackingSummary",
    windowName: "DeductionTrackingSummary",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.DeductionTrackingSummary",
    // START_USER_CODE-USER_DeductionTrackingSummary_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Deduction Tracking Summary",
      scrCode: "PN0160E",
    },
    // END_USER_CODE-USER_DeductionTrackingSummary_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btnClearDeduction: {
      name: "btnClearDeduction",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Clear Deduction",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClearDeduction_PROPERTIES

      // END_USER_CODE-USER_btnClearDeduction_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnViewDetails: {
      name: "btnViewDetails",
      type: "ButtonWidget",
      parent: "grpbxDeductionTrackingSummary",
      Label: "View Details",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnViewDetails_PROPERTIES

      // END_USER_CODE-USER_btnViewDetails_PROPERTIES
    },
    chkbox: {
      name: "chkbox",
      type: "CheckBoxWidget",
      parent: "grpbxDeductionTrackingSummary",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbox_PROPERTIES

      // END_USER_CODE-USER_chkbox_PROPERTIES
    },
    colAmountCharged: {
      name: "colAmountCharged",
      type: "GridColumnWidget",
      parent: "gridDeductionData",
      Label: "Amount Charged",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmountCharged_PROPERTIES

      // END_USER_CODE-USER_colAmountCharged_PROPERTIES
    },
    colAmountCollected: {
      name: "colAmountCollected",
      type: "GridColumnWidget",
      parent: "gridDeductionData",
      Label: "Amount Collected",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmountCollected_PROPERTIES

      // END_USER_CODE-USER_colAmountCollected_PROPERTIES
    },
    colAmountDue: {
      name: "colAmountDue",
      type: "GridColumnWidget",
      parent: "gridDeductionData",
      Label: "Amount Due",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmountDue_PROPERTIES

      // END_USER_CODE-USER_colAmountDue_PROPERTIES
    },
    colDeductionData: {
      name: "colDeductionData",
      type: "GridColumnWidget",
      parent: "gridDeductionData",
      Label: "Deduction Data",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDeductionData_PROPERTIES

      // END_USER_CODE-USER_colDeductionData_PROPERTIES
    },
    gridDeductionData: {
      name: "gridDeductionData",
      type: "GridWidget",
      parent: "grpbxDeductionTrackingSummary",
      gridCellsOrder:
        "chkbxCol,txtcolDeductionData,txtcolAmountCharged,txtcolAmountCollected,txtcolAmountDue",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isEditable: true,
      isCobolTable: true,
      // START_USER_CODE-USER_gridDeductionData_PROPERTIES
      conditionalRowStyles: [{
        when: (row) => { return !(row.chkbxCol !== undefined) },
        style: {
          input: {
            display: 'none'
          }
        }
      }],
      // END_USER_CODE-USER_gridDeductionData_PROPERTIES
    },
    chkbxCol: {
      name: "chkbxCol",
      type: "CheckBoxWidget",
      colName: "colChckbx",
      parent: "gridDeductionData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    colChckbx: {
      name: "colChckbx",
      type: "GridColumnWidget",
      parent: "gridDeductionData",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChckbx_PROPERTIES

      // END_USER_CODE-USER_colChckbx_PROPERTIES
    },
    colIsSts: {
      name: "colIsSts",
      type: "TextBoxWidget",
      colName: "colSts",
      parent: "gridAssocReady",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colIsSts_PROPERTIES

      // END_USER_CODE-USER_colIsSts_PROPERTIES
    },
    colSts: {
      name: "colSts",
      type: "GridColumnWidget",
      parent: "gridAssocReady",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSts_PROPERTIES

      // END_USER_CODE-USER_colSts_PROPERTIES
    },
    txtAccountVendor: {
      name: "txtAccountVendor",
      type: "TextBoxWidget",
      parent: "grpbxDeductionTrackingSummary",
      Label: "Account Vendor:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccountVendor_PROPERTIES
      ReadOnly: true,
      Enabled: false
      // END_USER_CODE-USER_txtAccountVendor_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxDeductionTrackingSummary",
      Label: "Buying Point:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES
      ReadOnly: true,
      Enabled: false
      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    chkboxSelectAll: {
      name: "chkboxSelectAll",
      type: "CheckBoxWidget",
      parent: "grpbxDeductionTrackingSummary",
      Label: "",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxSelectAll_PROPERTIES

      // END_USER_CODE-USER_chkboxSelectAll_PROPERTIES
    },
    txtcolAmountCharged: {
      name: "txtcolAmountCharged",
      type: "TextBoxWidget",
      colName: "colAmountCharged",
      parent: "gridDeductionData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmountCharged_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolAmountCharged_PROPERTIES
    },
    txtcolAmountCollected: {
      name: "txtcolAmountCollected",
      type: "TextBoxWidget",
      colName: "colAmountCollected",
      parent: "gridDeductionData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmountCollected_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolAmountCollected_PROPERTIES
    },
    txtcolAmountDue: {
      name: "txtcolAmountDue",
      type: "TextBoxWidget",
      colName: "colAmountDue",
      parent: "gridDeductionData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmountDue_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolAmountDue_PROPERTIES
    },
    txtcolDeductionData: {
      name: "txtcolDeductionData",
      type: "TextBoxWidget",
      colName: "colDeductionData",
      parent: "gridDeductionData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDeductionData_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolDeductionData_PROPERTIES
    },
    grpbxDeductionTrackingSummary: {
      name: "grpbxDeductionTrackingSummary",
      type: "GroupBoxWidget",
      parent: "DeductionTrackingSummary",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDeductionTrackingSummary_PROPERTIES

      // END_USER_CODE-USER_grpbxDeductionTrackingSummary_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "DeductionTrackingSummary",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      AlignChildren: "Center",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceApplicationSupport#DeductionTrackingDetail": {}
      },
      REVERSE: {
        "SystemMaintenanceApplicationSupport#DeductionTrackingDetail": {}
      }
    }
  };
  const [loading, setLoading] = useState(true);
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnViewDetails: {
    //   DEFAULT: [
    //     "SystemMaintenanceApplicationSupport#DeductionTrackingDetail#DEFAULT#true#Click",]
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
  }, [getData(thisObj, "openaccountprofiledata")]);
  // START_USER_CODE-USER_METHODS
  function FormLoad() {
    try{
    let openaccountprofiledatadetails = getData(thisObj, "openaccountprofiledata");
    setValue(thisObj, "txtBuyingPoint", openaccountprofiledatadetails.buyingpointname);
    setValue(thisObj, "txtAccountVendor", openaccountprofiledatadetails.acctvendor);
  ReadDeductionTrackingSummary(openaccountprofiledatadetails.lstBuyingPointID, openaccountprofiledatadetails.acctvendor);
    hide(thisObj, "btnClearDeduction")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during executing delete button"
        );
      }
    }
  }
  function toPascalCase(word) {
    try {
      return word.replace(/\w+/g,
        function (w) {
          return w[0].toUpperCase() + w.slice(1).toLowerCase();
        });
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during executing delete button"
        );
      }
    }
  }
  let mintCheckCount = 0
  if (mintCheckCount = 0) {
    hide(thisObj, "btnClearDeduction")
  }
  const ReadDeductionTrackingSummary = async (buyingPt, vendorNumber) => {
  try{
    setLoading(true);
    if (vendorNumber != "" && vendorNumber != undefined) {
      await ContractManagementService.RetrieveVendorByNumber(vendorNumber).then(response => {
        let data = response;
        if (response !== undefined && response.length > 0) {
          setValue(thisObj, "txtAccountVendor", vendorNumber + " - " + toPascalCase(data[0].name));
          setData(thisObj,"vendorname",toPascalCase(data[0].name));
        }
      })
    }
    let mbCheckAccess = false;
    let response1 = await ContractManagementService.RetrieveAccessPermissionDetails('PN0160', '001', 'U', buyingPt)
    if (response1[0].permission == 'Y') {
      mbCheckAccess = true;
    }
    hide(thisObj, "btnExportToExcel")
    hide(thisObj, "btnPrint")
    hide(thisObj, "btnClearDeduction")
    disable(thisObj, "btnClearDeduction")
    hide(thisObj ,"chkboxSelectAll")
    setValue(thisObj, "chkboxSelectAll", false)
    let mintCheckMax = 0

    let TransformedRowsArray = [];
    await SystemMaintenanceApplicationSupportService.RetrieveDeductionTrackingSummaryDetails(compId, cropyear, buyingPt, vendorNumber)
      .then(response => {
        if (response != undefined && response.length > 0) {
          let data = response;
          let obj = {};
          for (let i = 0; i < data.length; i++) {
            if (mbCheckAccess) {
              if (response[i].allow_clearing == "Y" && Number(response[i].due_amount) > 0) {
                obj.chkbxCol = false,
                  mintCheckMax++;
              }
            }
            obj.txtcolDeductionData = data[i].deduct_code
            obj.txtcolAmountCharged = Number(data[i].charge_amount).toFixed(2)
            obj.txtcolAmountCollected = Number(data[i].collect_amount).toFixed(2)
            obj.txtcolAmountDue = Number(data[i].due_amount).toFixed(2)
            TransformedRowsArray.push(obj)
            obj = {}
          }
        }
      })
    setValue(thisObj, "gridDeductionData", TransformedRowsArray)
    setLoading(false);
    if (TransformedRowsArray.length > 0) {
      show(thisObj, "btnExportToExcel") 
      show(thisObj, "btnPrint") 
    }
    if (TransformedRowsArray.length > 0) {
      for (let i = 0; i < TransformedRowsArray.length; i++) {
        if (TransformedRowsArray[i].chkbxCol !== undefined) {
          show(thisObj, "btnClearDeduction") 
          show(thisObj,"chkboxSelectAll")
          showColumn(thisObj ,"gridDeductionData","chkbxCol")
          break;
        }
        else{
          hide(thisObj, "btnClearDeduction") 
          hide(thisObj,"chkboxSelectAll")
          hideColumn(thisObj ,"gridDeductionData","chkbxCol")
        }
      }  
    }
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred during executing delete button"
      );
    }
  }
  };
  thisObj.ReadDeductionTrackingSummary = ReadDeductionTrackingSummary;
  
  const onchkboxSelectAllChange = () => {
    try {
      let checkboxValue = getValue(thisObj, "chkboxSelectAll")
      let gridData = getValue(thisObj, "gridDeductionData")
      if (gridData.length > 0) {
        for (let i = 0; i < gridData.length; i++) {
          if (checkboxValue == true) {
            enable(thisObj, "btnClearDeduction")
            if (gridData[i].chkbxCol !== undefined) {
              gridData[i].chkbxCol = true;
            }
          }
          else {
            if (gridData[i].chkbxCol !== undefined) {
              gridData[i].chkbxCol = false;
            }
            disable(thisObj, "btnClearDeduction")
          }
        }
      }
      else {
        setValue(thisObj, 'chkboxSelectAll', false);
      }
      setValue(thisObj, "gridDeductionData", gridData)
    } catch (error) {
      showMessage(thisObj, "Somethingwent wrong on chkbox click")
    }
  }
  thisObj.onchkboxSelectAllChange = onchkboxSelectAllChange;

  const onchkbxColChange = () => {
    try {
      let checkboxValue = getValue(thisObj, "chkboxSelectAll")
      let gridData = getValue(thisObj, "gridDeductionData")
      if (gridData.length > 0) {
        for (let i = 0; i < gridData.length; i++) {
          if (gridData[i].chkbxCol == true || checkboxValue) {
            enable(thisObj, "btnClearDeduction")
          }
          else {
            if (gridData[i].chkbxCol == false || checkboxValue) {
              disable(thisObj, "btnClearDeduction")
            }
          }
        }
      }
    } catch (error) {
      showMessage(thisObj, "Somethingwent wrong on chkbox click")
    }
  }
  thisObj.onchkbxColChange = onchkbxColChange;
  // Clear Deduction Button Functionality
  const onbtnClearDeductionClick = async () => {
    try {
      let gridData = getValue(thisObj, 'gridDeductionData');
      let rowNum = getSelectedRowNumber(thisObj, 'gridDeductionData');
      if (rowNum == undefined) {
        rowNum = 0;
      }
      const response = window.confirm("Does our system now need to generate a payable to reimburse the 3rd party vendor?");
      if (response) {
        for (let i = 0; i < gridData.length; i++) {
          if (gridData[i].chkbxCol == true) {
            let action_type = "PAID"
            let deduct_code = gridData[i].txtcolDeductionData
            let buy_pt_id = getValue(thisObj,"txtBuyingPoint").slice(0,3)
            let acct_vendor = getValue(thisObj,"txtAccountVendor").slice(0,6)
            let response1 = await SystemMaintenanceApplicationSupportService.UpdateDeductionTrackingClearBalancedue(buy_pt_id, acct_vendor, deduct_code, action_type)
            if (![200, 400, 404].includes(response1.status)) {
              showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.' + response.message)
              return
            }
            else {
              if (gridData.length > 0) {
                for (let i = 0; i < gridData.length; i++) {
                  if (gridData[i].chkbxCol == true) {
                    gridData[i].chkbxCol = false
                  }
                  disable(thisObj,"btnClearDeduction")
                  setValue(thisObj,"gridDeductionData",gridData)
                  setValue(thisObj,"chkboxSelectAll",false)
                  let openaccountprofiledatadetails = getData(thisObj, "openaccountprofiledata");
                ReadDeductionTrackingSummary(openaccountprofiledatadetails.lstBuyingPointID, openaccountprofiledatadetails.acctvendor);
                }  
              }
            }
          }
        }
      }
      else{
        for (let i = 0; i < gridData.length; i++) {
          if (gridData[i].chkbxCol == true) {
            let action_type = "CLEAR"
            let deduct_code = gridData[i].txtcolDeductionData
            let buy_pt_id = getValue(thisObj,"txtBuyingPoint").slice(0,3)
            let acct_vendor = getValue(thisObj,"txtAccountVendor").slice(0,6)
            let response2 = await SystemMaintenanceApplicationSupportService.UpdateDeductionTrackingClearBalancedue(buy_pt_id, acct_vendor, deduct_code, action_type)
            if (![200, 400, 404].includes(response2.status)){
              showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.' + response.message)
              return
            }
            else {
              if (gridData.length > 0) {
                for (let i = 0; i < gridData.length; i++) {
                  if (gridData[i].chkbxCol == true) {
                    gridData[i].chkbxCol = false
                  }
                  disable(thisObj,"btnClearDeduction")
                  setValue(thisObj,"gridDeductionData",gridData)
                  setValue(thisObj,"chkboxSelectAll",false)
                  let openaccountprofiledatadetails = getData(thisObj, "openaccountprofiledata");
                   ReadDeductionTrackingSummary(openaccountprofiledatadetails.lstBuyingPointID, openaccountprofiledatadetails.acctvendor);
                }  
              }
            }
          }
        }
      }
    }
    catch (error){
   showMessage (thisObj,"getting error on btnClear Deduction")
    }
  };
  thisObj.onbtnClearDeductionClick = onbtnClearDeductionClick;
  // Print Button Functionality
  const onbtnPrintClick = () => {
    try {
      var data = getValue(thisObj, "gridDeductionData")
      let screendata = getData(thisObj, "openaccountprofiledata")

      let sDocName = cropyear + " " + " Deduction Tracking" + " - " + screendata.lstBuyingPointID + " - Vendor: " + screendata.acctvendor + " - " + getData(thisObj,"vendorname") + " - "
      sDocName += moment(new Date()).format("MM/DD/YYYY h:mm:ss A");
      var res = []
      let headerArray = []

      if (thisObj.state.gridDeductionData.columns[1].Visible) {
        headerArray.push("Deduction Code");
      }
      if (thisObj.state.gridDeductionData.columns[2].Visible) {
        headerArray.push("Amount Charged");
      }
      if (thisObj.state.gridDeductionData.columns[3].Visible) {
        headerArray.push("Amount Collected");
      }
      if (thisObj.state.gridDeductionData.columns[4].Visible) {
        headerArray.push("Amount Due");
      }
      for (var i = 0; i < data.length; i++) {
        let NewArray = []
        if (thisObj.state.gridDeductionData.columns[1].Visible) {
          NewArray.push(data[i].txtcolDeductionData);
        }
        if (thisObj.state.gridDeductionData.columns[2].Visible) {
          NewArray.push(data[i].txtcolAmountCharged);
        }
        if (thisObj.state.gridDeductionData.columns[3].Visible) {
          NewArray.push(data[i].txtcolAmountCollected);
        }
        if (thisObj.state.gridDeductionData.columns[4].Visible) {
          NewArray.push(data[i].txtcolAmountDue);
        }
        res.push(NewArray)
      }
      var obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [headerArray]
      });
      var pageCount = obj.internal.getNumberOfPages();
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(8);
        obj.text(sDocName, 10, obj.internal.pageSize.height - 10);
      }
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:DeductionTrackingSummary.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:DeductionTrackingSummary.cmdPrintSearch_Click")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;
  // Button Export To Excel Functionality
  const onbtnExportToExcelClick = () => {
    try{
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let datatable = getValue(thisObj, 'gridDeductionData');
    var fileName = "Deduction Tracking Summary";
    let excelData = [];
    let totalData = {}
    if (datatable != undefined) {
      for (let i = 0; i < datatable.length; i++) {
        let rowdata = {};
        if (thisObj.state.gridDeductionData.columns[1].Visible) {
          rowdata["Deduction Code"] = datatable[i].txtcolDeductionData;
        }
        if (thisObj.state.gridDeductionData.columns[2].Visible) {
          rowdata["Amount Charged"] = ExcelNumericDataConvert(datatable[i].txtcolAmountCharged);
        }
        if (thisObj.state.gridDeductionData.columns[3].Visible) {
          rowdata["Amount Collected"] = ExcelNumericDataConvert(datatable[i].txtcolAmountCollected);
        }
        if (thisObj.state.gridDeductionData.columns[4].Visible) {
          rowdata["Amount Due"] = ExcelNumericDataConvert(datatable[i].txtcolAmountDue);
        }
        excelData.push(rowdata);
      }
      excelData.push(totalData)
    }
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    var FileSaver = require('file-saver');
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred during executing delete button"
      );
    }
  }
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;
  // View Details Button Click
  const onbtnViewDetailsClick = async () => {
    try {
      let premCode = ""
      let SelectedRow = thisObj.state.gridDeductionData.selected[0]
      if (SelectedRow == null || SelectedRow == undefined) {
        showMessage(thisObj, "Please select a row to view.")
        return
      }
      else {
        premCode = thisObj.state.gridDeductionData.selected[0].txtcolDeductionData
      }
      let deductiontrackingsummarydata = {}
      let buyptvalue = getValue(thisObj, 'txtBuyingPoint')
      let buy_pt_id = thisObj.values.txtBuyingPoint
      let txtAccountVendor = getValue(thisObj, 'txtAccountVendor')
      deductiontrackingsummarydata.buyingpointname = buyptvalue;
      deductiontrackingsummarydata.lstBuyingPointID = buy_pt_id
      deductiontrackingsummarydata.acctvendor = thisObj.values.txtAccountVendor.split("-")[0].trim()
      deductiontrackingsummarydata.deductioncode = premCode
      setData(thisObj, 'deductiontrackingsummarydata', deductiontrackingsummarydata)

      goTo(thisObj, "SystemMaintenanceApplicationSupport#DeductionTrackingDetail#DEFAULT#true#Click");
    }
    catch (error) {
    }
  }
  thisObj.onbtnViewDetailsClick = onbtnViewDetailsClick;
   
  // Close Button
  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceApplicationSupport_DeductionTrackingSummaryPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnClose event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DeductionTrackingSummary*/}

              {/* END_USER_CODE-USER_BEFORE_DeductionTrackingSummary*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDeductionTrackingSummary*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDeductionTrackingSummary*/}

              <GroupBoxWidget
                conf={state.grpbxDeductionTrackingSummary}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBuyingPoint}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_txtBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txtAccountVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtAccountVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccountVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAccountVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtAccountVendor*/}
                {/* START_USER_CODE-USER_BEFORE_chkbox*/}

                {/* END_USER_CODE-USER_BEFORE_chkbox*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkbox}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkbox*/}

                {/* END_USER_CODE-USER_AFTER_chkbox*/}
                {/* START_USER_CODE-USER_BEFORE_btnViewDetails*/}

                {/* END_USER_CODE-USER_BEFORE_btnViewDetails*/}
                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxSelectAll}
                  screenConf={state}
                ></CheckboxWidget>
                <ButtonWidget
                  conf={state.btnViewDetails}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnViewDetails*/}

                {/* END_USER_CODE-USER_AFTER_btnViewDetails*/}
                {/* START_USER_CODE-USER_BEFORE_gridDeductionData*/}

                {/* END_USER_CODE-USER_BEFORE_gridDeductionData*/}

                <GridWidget
                  conf={state.gridDeductionData}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridDeductionData}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  refObject={thisObj}
                  setFieldValue={setFieldValue}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridDeductionData*/}

                {/* END_USER_CODE-USER_AFTER_gridDeductionData*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDeductionTrackingSummary*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDeductionTrackingSummary*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClearDeduction*/}

                {/* END_USER_CODE-USER_BEFORE_btnClearDeduction*/}

                <ButtonWidget
                  conf={state.btnClearDeduction}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClearDeduction*/}

                {/* END_USER_CODE-USER_AFTER_btnClearDeduction*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btn1*/}

                {/* END_USER_CODE-USER_BEFORE_btn1*/}

                <ButtonWidget
                  conf={state.btn1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn1*/}

                {/* END_USER_CODE-USER_AFTER_btn1*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_DeductionTrackingSummary*/}

              {/* END_USER_CODE-USER_AFTER_DeductionTrackingSummary*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_DeductionTrackingSummary
);
