/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  goTo,
  setData,
  getData,
  disable,
  enable,
  setValue
} from "../../shared/WindowImports";

import "./SecurityUserFunctionSelect.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import { SystemMaintenanceMasterManagementService } from "../../SystemMaintenanceMasterManagement/Service/SystemMaintenanceMasterManagementServices";
import { toPascalCase } from "../../Common/Constants";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_SecurityUserFunctionSelect(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');

  let lstFunctionID = []
  let lstFunctionList = []
  let lstFuncName = []
  let lstLocation = []
  let lstLocationIds = []
  let locationIndex = 0
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SecurityUserFunctionSelect",
    windowName: "SecurityUserFunctionSelect",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.SecurityUserFunctionSelect",
    // START_USER_CODE-USER_SecurityUserFunctionSelect_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Security User Function Select",
      scrCode: "PN0060E",
    },
    // END_USER_CODE-USER_SecurityUserFunctionSelect_PROPERTIES
    btnSUFS_OK: {
      name: "btnSUFS_OK",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSUFS_OK_PROPERTIES

      // END_USER_CODE-USER_btnSUFS_OK_PROPERTIES
    },
    btnAddLocation: {
      name: "btnAddLocation",
      type: "ButtonWidget",
      parent: "grpbxSecurityUserFunctionSelect",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddLocation_PROPERTIES

      // END_USER_CODE-USER_btnAddLocation_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnCopy: {
      name: "btnCopy",
      type: "ButtonWidget",
      parent: "grpbxCopyToUserID",
      Label: "Copy",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCopy_PROPERTIES

      // END_USER_CODE-USER_btnCopy_PROPERTIES
    },
    btnDeleteLocation: {
      name: "btnDeleteLocation",
      type: "ButtonWidget",
      parent: "grpbxSecurityUserFunctionSelect",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteLocation_PROPERTIES

      // END_USER_CODE-USER_btnDeleteLocation_PROPERTIES
    },
    btnEditLocation: {
      name: "btnEditLocation",
      type: "ButtonWidget",
      parent: "grpbxSecurityUserFunctionSelect",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditLocation_PROPERTIES

      // END_USER_CODE-USER_btnEditLocation_PROPERTIES
    },
    ddAccessLevel: {
      name: "ddAccessLevel",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityUserFunctionSelect",
      Label: "Access Level:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAccessLevel_PROPERTIES

      // END_USER_CODE-USER_ddAccessLevel_PROPERTIES
    },
    ddCopyToUserID: {
      name: "ddCopyToUserID",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyToUserID",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCopyToUserID_PROPERTIES

      // END_USER_CODE-USER_ddCopyToUserID_PROPERTIES
    },
    ddFunction: {
      name: "ddFunction",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityUserFunctionSelect",
      Label: "Function:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFunction_PROPERTIES

      // END_USER_CODE-USER_ddFunction_PROPERTIES
    },
    grpbxCopyToUserID: {
      name: "grpbxCopyToUserID",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityUserFunctionSelect",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCopyToUserID_PROPERTIES

      // END_USER_CODE-USER_grpbxCopyToUserID_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblCopyToUser: {
      name: "lblCopyToUser",
      type: "LabelWidget",
      parent: "grpbxCopyToUserID",
      Label: "Copy function and location(s) to another user.",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCopyToUser_PROPERTIES

      // END_USER_CODE-USER_lblCopyToUser_PROPERTIES
    },
    lblCopyToUserID: {
      name: "lblCopyToUserID",
      type: "LabelWidget",
      parent: "grpbxCopyToUserID",
      Label: "Copy To User ID",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCopyToUserID_PROPERTIES

      // END_USER_CODE-USER_lblCopyToUserID_PROPERTIES
    },
    lstLocations: {
      name: "lstLocations",
      type: "ListBoxFieldWidget",
      parent: "grpbxSecurityUserFunctionSelect",
      Label: "Locations:",
      ColSpan: "3",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstLocations_PROPERTIES

      // END_USER_CODE-USER_lstLocations_PROPERTIES
    },
    txtKeyInformation: {
      name: "txtKeyInformation",
      type: "TextBoxWidget",
      parent: "grpbxSecurityUserFunctionSelect",
      Label: "Key Information:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKeyInformation_PROPERTIES

      // END_USER_CODE-USER_txtKeyInformation_PROPERTIES
    },
    grpbxSecurityUserFunctionSelect: {
      name: "grpbxSecurityUserFunctionSelect",
      type: "GroupBoxWidget",
      parent: "SecurityUserFunctionSelect",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSecurityUserFunctionSelect_PROPERTIES

      // END_USER_CODE-USER_grpbxSecurityUserFunctionSelect_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "SecurityUserFunctionSelect",
      Height: "",
      Width: "",
      clonedExtId: "44193",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#SecurityUserFunctionLocationSelect": {},
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#SecurityUserFunctionLocationSelect": {},
      }
    }
  };

  let _buttonServices = {};
  let _buttonNavigation = {};

  let _winServices = {};

  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    FormRefresh()
  },[getData(thisObj, 'frmSecurtyUserFunctionSelectrefresh')])

  // START_USER_CODE-USER_METHODS
  async function FormRefresh(){
    if(getData(thisObj, 'frmSecurtyUserFunctionSelectrefresh')){
      await bFillLocationList_Update()
      await EnableDisableControls()
    }
    setData(thisObj, 'frmSecurtyUserFunctionSelectrefresh', false)
  }

  async function FormLoad(){
    try{
      let screenData = getData(thisObj, 'SecurityUserFunctionSelectData')
      thisObj.values.lstLocations = []
      await bFillFunctionList()
      await bFillAccessLevelList()
      await bFillExportToUsers()
      disable(thisObj, 'txtKeyInformation')
      
      if(screenData?.condition == "Update"){
        disable(thisObj, 'ddFunction')
        thisObj.values.txtKeyInformation = screenData?.userId + " - " + screenData?.userName
        thisObj.values.ddFunction = `${screenData?.funcId}/${screenData?.funcSubId}`
        thisObj.values.ddAccessLevel = screenData?.access
        thisObj.values.lblAddedByValue = "  " + screenData?.addedBy?.split(" ")[0] + " "  + ConvertDateTime(screenData?.addedBy?.split(" ")[1])//screenData?.addedBy
        thisObj.values.lblChangedByValue = "  " + screenData?.changedBy?.split(" ")[0] + " "  + ConvertDateTime(screenData?.changedBy?.split(" ")[1])//screenData?.changedBy
        document.getElementById('btnSUFS_OK').innerHTML = "Update"
        await bFillLocationList_Update()
      }
      else if(screenData?.condition == "Add"){
        enable(thisObj, 'ddFunction')
        thisObj.values.txtKeyInformation = screenData?.userId + " - " + screenData?.userName
        thisObj.values.lblAddedByValue = ""
        thisObj.values.lblChangedByValue = ""
        document.getElementById('btnSUFS_OK').innerHTML = "Add"
      }
      
      await EnableDisableControls()
    }
    catch(err){
      errorHandler(err)
    }
    finally{
      setLoading(false)
    }
  }

  async function bFillExportToUsers(){
    try{
      let js = []
      let LstrList = await ContractManagementService.RetrieveUserControlDetails(null, null, null, null)
      if(LstrList?.status !== 500){
        js = [{
          key : "",
          description : ""
        }]

        LstrList?.forEach(user => {
          js.push({
            key : user?.userId,
            description : user?.userId + " - " + user?.userName
          })
        })    
      }
      
      thisObj.setState(current => {
        return {
          ...current,
          ddCopyToUserID: {
            ...state["ddCopyToUserID"],
            valueList : js
          }
        }
      })
      thisObj.values.ddCopyToUserID = ''
    }
    catch(err){
      errorHandler(err)
    }
  }

  async function bFillFunctionList(){
    try{
      let js = []
      let obj = {}
      let funcIdList = []
      let funcSubList = []
      let funcNameList = []
      let funcDetailList = []
      let bFillFunctionListBool = false

      let LstrList = await SystemMaintenanceSpecialFunctionsService.RetrieveFunctionControlDetails(null, null)
      if(LstrList?.status !== 500){
        LstrList?.forEach(item => {
          if(item.strFieldName == "FUNC_ID"){
            obj = {}
            obj.func_id = item.strFieldData
            funcIdList.push(obj?.func_id)
          }

          if(item.strFieldName == "FUNC_SUB_ID"){
            obj.func_sub_id = item.strFieldData
            funcSubList.push(obj?.func_sub_id)
          }

          if(item.strFieldName == "FUNC_NAME"){
            obj.func_name = item.strFieldData
            funcNameList.push(obj?.func_name)
            js.push({
              key         : obj?.func_id + "/" + obj?.func_sub_id,
              description : obj?.func_id + "." + obj?.func_sub_id + " - " + obj?.func_name
            })
            funcDetailList.push(obj?.func_id + "." + obj?.func_sub_id + " - " + obj?.func_name)
          }
        })        

        bFillFunctionListBool = true
      }
      
      setData(thisObj, 'lstFunctionID', funcIdList)
      setData(thisObj, 'lstFuncName', funcNameList)
      setData(thisObj, 'lstFunctionList', funcDetailList)

      thisObj.setState(current => {
        return {
          ...current,
          ddFunction: {
            ...state["ddFunction"],
            valueList : js
          }
        }
      })
      thisObj.values.ddFunction = js.length > 0 ? js[0].key : ''

      return bFillFunctionListBool;
    }
    catch(err){
      errorHandler(err)
      return false
    }
  }

  async function bFillAccessLevelList(){
    try{
      let js = [ 
        { key : "I" , description : "Inquiry" },
        { key : "U" , description : "Update" },
        { key : "D" , description : "Delete" },
        { key : "X" , description : "Revoke" }
      ]

      thisObj.setState(current => {
        return {
          ...current,
          ddAccessLevel: {
            ...state["ddAccessLevel"],
            valueList : js
          }
        }
      })
      thisObj.values.ddAccessLevel = js[0].key

      return true
    } 
    catch(err){
      errorHandler(err)
      return false
    }
  }

  async function bFillLocationList_Update(){
    try{
      let js = []
      let LstrList = []
      let locationList = []
      let locationIdList = []
      
      let funcDetails = thisObj.values.ddFunction
      let selectedFuncId = funcDetails?.split("/")[0]?.trim()
      let selectedFuncSubId = funcDetails?.split("/")[1]?.trim()
      let userId = thisObj.values.txtKeyInformation?.split("-")[0]?.trim()
      
      LstrList = await SystemMaintenanceSpecialFunctionsService.RetrieveUserFunctionLocationNameDetails(selectedFuncId, 'PN0060', userId, selectedFuncSubId, null)
      if(LstrList.status !== 500){
        LstrList?.forEach(item => {
          js.push({
            key         : item?.buy_pt_id + "/" + item?.access_level,
            description : item?.buy_pt_id + " (" + item?.access_level + ") - " + toPascalCase(item?.buy_pt_name)
          })
          locationList.push(item?.buy_pt_id + " (" + item?.access_level + ") - " + toPascalCase(item?.buy_pt_name))
          locationIdList.push(item?.buy_pt_id + "/" + item?.access_level)
        })
      }

      setData(thisObj, 'lstLocation', locationList)
      setData(thisObj, 'lstLocationIds', locationIdList)

      thisObj.setState(current => {
        return {
          ...current,
          lstLocations: {
            ...state["lstLocations"],
            valueList: js
          }
        }
      })

      let index  = Number(getData(thisObj, 'locationIndex')) > 0 ? getData(thisObj, 'locationIndex') : 0
      
      if(![undefined, null].includes(getData(thisObj, 'highlightAddData'))){
        let key = getData(thisObj, 'highlightAddData')?.lstLocationCaption
        key = key.split("(")[0].trim() + '/' + key.split("(")[1]?.trim()[0]
        
        for(var i=0; i<js?.length; i++){
          if(js[i].key == key){
            index = i
            break
          }
        }
        
        setData(thisObj, 'highlightAddData', null)
      }

      if(js.length > 0){
        setValue(thisObj, 'lstLocations',[js[index].key]);
      }      
      thisObj.values.lstLocations = js.length > 0 ? js[index].key : ''
    }
    catch(err){
      errorHandler(err)
      return false
    }
  }

  async function EnableDisableControls(){
    try{
      if(getData(thisObj, 'SecurityUserFunctionSelectData')?.condition == "Add"){
        disable(thisObj, 'lstLocations')
        disable(thisObj, 'btnAddLocation')
        disable(thisObj, 'btnDeleteLocation')
        disable(thisObj, 'btnEditLocation')
      }
      else{
        enable(thisObj, 'btnAddLocation')
        if(thisObj.values.lstLocations?.length > 0){
          enable(thisObj, 'lstLocations')
          enable(thisObj, 'btnDeleteLocation')
          enable(thisObj, 'btnEditLocation')
        }
        else{
          disable(thisObj, 'lstLocations')
          disable(thisObj, 'btnDeleteLocation')
          disable(thisObj, 'btnEditLocation')
        }
      }
    }
    catch(err){
      errorHandler(err)
    }
  }

  const ontxtKeyInformationChange = async() => {
    try{
      if(! await bFillFunctionList()){
        return
      }

      if(! await bFillAccessLevelList()){
        return
      }
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtKeyInformationChange = ontxtKeyInformationChange;

  const onddFunctionChange = async() => {
    try{
      await bFillLocationList_Update()
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onddFunctionChange = onddFunctionChange;

  const onbtnAddLocationClick = async() => {
    try{
      let userId = thisObj.values.txtKeyInformation?.split(" - ")[0]      
      let funcId = thisObj.values.ddFunction?.split("/")[0]?.trim() 
      let funcSubId = thisObj.values.ddFunction?.split("/")[1]?.trim()

      let obj = {
        "lblKeyInformationTag"     : `${userId}|${funcId}|${funcSubId}`,
        "lblKeyInformationCaption" : thisObj.values.txtKeyInformation + ` - ${funcId}.${funcSubId} - ${getData(thisObj, 'lstFuncName')[getData(thisObj, 'lstFunctionID')?.indexOf(funcId)]}`,
        "cmdOkCaption"             : "Add"
      }
      
      setData(thisObj, "frmSecurityUserFunctionLocationSelect", obj);
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityUserFunctionLocationSelect#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnAddLocationClick = onbtnAddLocationClick

  const onbtnEditLocationClick = async() => {
    try{      
      let locationDetail = getData(thisObj, 'lstLocation')[getData(thisObj, 'lstLocationIds')?.indexOf(thisObj.values.lstLocations[0])]
      
      let userId = thisObj.values.txtKeyInformation?.split("-")[0]?.trim()
      let funcId = thisObj.values.ddFunction?.split("/")[0]?.trim()
      let funcSubId = thisObj.values.ddFunction?.split("/")[1]?.trim()
      let buyingPt = locationDetail?.split("(")[0]?.trim()

      let LstrList = await SystemMaintenanceMasterManagementService.RetrieveUserFunctionLocationTransferDetails(userId, funcId, funcSubId, buyingPt)
      if(LstrList.status == 500){
        return
      }      

      let index = getData(thisObj, 'lstLocation')?.indexOf(locationDetail)
      setData(thisObj, 'locationIndex', index)

      let obj = {
        "lblKeyInformationTag"     : `${LstrList[0]?.user_id}|${LstrList[0]?.func_id}|${LstrList[0]?.func_sub_id}`,
        "lblKeyInformationCaption" : thisObj.values.txtKeyInformation + ` - ${LstrList[0]?.user_id}.${LstrList[0]?.func_sub_id} - ${getData(thisObj, 'lstFuncName')[getData(thisObj, 'lstFunctionID')?.indexOf(funcId)]}`, 
        "cmdOkCaption"             : "Edit",
        "lstBuyingPointID"         : LstrList[0]?.buy_pt_id,
        "lstAccessLevelId"         : LstrList[0]?.access_level,
        "lblAddedByCaption"        : "  " + LstrList[0]?.add_user + " " + ConvertDateTime(LstrList[0]?.add_date_time),
        "lblChangedByCaption"      : "  " + LstrList[0]?.chg_user + " " + ConvertDateTime(LstrList[0]?.chg_date_time)
      }
      
      setData(thisObj, "frmSecurityUserFunctionLocationSelect", obj);
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#SecurityUserFunctionLocationSelect#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnEditLocationClick = onbtnEditLocationClick

  const onbtnDeleteLocationClick = async() => {
    try{
      let js = []
      let js1 = []
      let js2 = []
      let lstrxml = []
      
      let locationDetail = getData(thisObj, 'lstLocation')[getData(thisObj, 'lstLocationIds')?.indexOf(thisObj.values.lstLocations[0])]
      if(!confirm(`Are you sure you wish to delete this location '${locationDetail}'`)){
        return
      }

      let funcDetails = thisObj.values.ddFunction
      let funcId = funcDetails?.split("/")[0]?.trim()
      let funcSubId = funcDetails?.split("/")[1]?.trim()
      let userId = thisObj.values.txtKeyInformation?.split("-")[0]?.trim()
      let buyingPt = locationDetail?.split("(")[0]?.trim()
      
      lstrxml = await SystemMaintenanceSpecialFunctionsService.RemoveUserFunctionLocationTransfer(userId, funcId, buyingPt, funcSubId)
      if(lstrxml.status == 500){
        return
      }
      if(lstrxml.status !== 200){
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\n" + lstrxml.message)
        return
      }

      let locationList = getData(thisObj, 'lstLocation')
      let index = locationList.indexOf(locationDetail)
      
      // splice will remove the element from the 'index'
      locationList.splice(index, 1)    
      locationList.forEach(location => {        
        js.push({
          key : location?.slice(0,3) + "/"+ location?.slice(5,6),
          description : location 
        })

        js1.push(location?.slice(0,3) + "/"+ location?.slice(5,6))
        js2.push(location)
      })

      thisObj.setState(current => {
        return {
          ...current,
          lstLocations: {
            ...state["lstLocations"],
            valueList: js
          }
        }
      })

      setData(thisObj, 'lstLocation', js2)
      setData(thisObj, 'lstLocationIds', js1)
      
      if(js.length > 0){
        setValue(thisObj, 'lstLocations', [js[index >= 1 ? index -1 : 0].key])
      }
      thisObj.values.lstLocations = js.length > 0 ? js[index >= 1 ? index -1 : 0].key : ''

      await EnableDisableControls();
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnDeleteLocationClick = onbtnDeleteLocationClick

  const onbtnCopyClick = async() => {
    try{
      let lstrxml = []
      let funcDetails = thisObj.values.ddFunction
      let funcId = funcDetails?.split("/")[0]?.trim()
      let funcSubId = funcDetails?.split("/")[1]?.trim()
      let userId = thisObj.values.txtKeyInformation?.split("-")[0]?.trim()
      
      let userDetail = thisObj.values.ddCopyToUserID
      userDetail = userDetail?.includes("-") ? userDetail?.split("-")[0]?.trim() : userDetail
      
      let dataObj = {
        to_user_id : userDetail?.trim(),
        user_id    : userId
      }

      lstrxml = await SystemMaintenanceSpecialFunctionsService.UpdateUserSecurityExport(userId, funcId, funcSubId, dataObj)
      if(lstrxml.status == 500){
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\n" + lstrxml.message)
      }
      else{
        alert("The Security Function has been copied over!")
      }
      await onbtnCancelClick()
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnCopyClick = onbtnCopyClick

  const onbtnCancelClick = async() => {
    try{
      setData(thisObj, 'lstFunctionID', null)
      setData(thisObj, 'lstFunctionList', null)
      setData(thisObj, 'lstFuncName', null)
      setData(thisObj, 'lstLocation', null)
      setData(thisObj, 'lstLocationIds', null)
      setData(thisObj, 'locationIndex', null)
      document.getElementById("SystemMaintenanceSpecialFunctions_SecurityUserFunctionSelectPopUp").childNodes[0].click()
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onbtnSUFS_OKClick = async() => {
    try{
      let funcId = thisObj.values.ddFunction?.split("/")[0]?.trim()
      let funcSubId = thisObj.values.ddFunction?.split("/")[1]?.trim()
      
      let valueList = thisObj.state.ddFunction.valueList;
      let funcName = ''
      if (valueList !== undefined && valueList !== null) {
        funcName = valueList?.find(elem => elem.key == `${funcId}/${funcSubId}`)?.description;
      }

      let lstrxml = []
      let dataObj = {
        access_level : thisObj.values.ddAccessLevel
      }

      let cmdOk = document.getElementById("btnSUFS_OK").innerText
      if(cmdOk?.toLocaleUpperCase() == 'ADD'){
        dataObj.func_id     = funcId
        dataObj.func_sub_id = funcSubId
        dataObj.user_id     = thisObj.values.txtKeyInformation?.split("-")[0]?.trim(),

        lstrxml = await SystemMaintenanceSpecialFunctionsService.CreateUserFunctionTransfer(dataObj)
        if(lstrxml?.status !== 200){
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\n Error: \n\n" + lstrxml?.message)
          return
        }
      }
      else{
        let userId = thisObj.values.txtKeyInformation?.split("-")[0]?.trim()
        dataObj.uid = useridFromLS()
        
        lstrxml = await SystemMaintenanceSpecialFunctionsService.UpdateUserFunctionTransfer(userId, funcId, funcSubId, dataObj)
        if(lstrxml?.status !== 200){
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\n Error: \n\n" + lstrxml?.message)
          return
        }
      }
      
      let obj = {
        key         : `${funcId}.${funcSubId}`,
        description : `${funcId}.${funcSubId} (${thisObj.values.ddAccessLevel}) - ${funcName?.split(" - ")[1]?.trim()}`,
        condition   : cmdOk
      }
      
      setData(thisObj , "dataForSecurityUser", obj);
      setData(thisObj , "closingOfSecurityUserFunctionSelect", true);

      await onbtnCancelClick()
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnSUFS_OKClick = onbtnSUFS_OKClick;

  function ConvertDateTime(dateTime){
    try{
      const d = new Date(dateTime)
      return  [ d.getMonth()+1, d.getDate(), d.getFullYear() ].join('/') 
    }
    catch(err){
      errorHandler(err)
    }
  }

  function errorHandler(err){
    showMessage(thisObj, err.message)
  }
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SecurityUserFunctionSelect*/}

              {/* END_USER_CODE-USER_BEFORE_SecurityUserFunctionSelect*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSecurityUserFunctionSelect*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSecurityUserFunctionSelect*/}

              <GroupBoxWidget
                conf={state.grpbxSecurityUserFunctionSelect}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtKeyInformation*/}

                {/* END_USER_CODE-USER_BEFORE_txtKeyInformation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKeyInformation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKeyInformation*/}

                {/* END_USER_CODE-USER_AFTER_txtKeyInformation*/}
                {/* START_USER_CODE-USER_BEFORE_ddFunction*/}

                {/* END_USER_CODE-USER_BEFORE_ddFunction*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddFunction}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddFunction*/}

                {/* END_USER_CODE-USER_AFTER_ddFunction*/}
                {/* START_USER_CODE-USER_BEFORE_ddAccessLevel*/}

                {/* END_USER_CODE-USER_BEFORE_ddAccessLevel*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAccessLevel}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAccessLevel*/}

                {/* END_USER_CODE-USER_AFTER_ddAccessLevel*/}
                {/* START_USER_CODE-USER_BEFORE_btnAddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_btnAddLocation*/}

                <ButtonWidget
                  conf={state.btnAddLocation}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAddLocation*/}

                {/* END_USER_CODE-USER_AFTER_btnAddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_btnEditLocation*/}

                {/* END_USER_CODE-USER_BEFORE_btnEditLocation*/}

                <ButtonWidget
                  conf={state.btnEditLocation}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEditLocation*/}

                {/* END_USER_CODE-USER_AFTER_btnEditLocation*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeleteLocation*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeleteLocation*/}

                <ButtonWidget
                  conf={state.btnDeleteLocation}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeleteLocation*/}

                {/* END_USER_CODE-USER_AFTER_btnDeleteLocation*/}
                {/* START_USER_CODE-USER_BEFORE_lstLocations*/}

                {/* END_USER_CODE-USER_BEFORE_lstLocations*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstLocations}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstLocations*/}

                {/* END_USER_CODE-USER_AFTER_lstLocations*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxCopyToUserID*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCopyToUserID*/}

                <GroupBoxWidget
                  conf={state.grpbxCopyToUserID}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblCopyToUserID*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCopyToUserID*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCopyToUserID}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCopyToUserID*/}

                  {/* END_USER_CODE-USER_AFTER_lblCopyToUserID*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCopyToUserID*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCopyToUserID*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCopyToUserID}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCopyToUserID*/}

                  {/* END_USER_CODE-USER_AFTER_ddCopyToUserID*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCopy*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCopy*/}

                  <ButtonWidget
                    conf={state.btnCopy}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCopy*/}

                  {/* END_USER_CODE-USER_AFTER_btnCopy*/}
                  {/* START_USER_CODE-USER_BEFORE_lblCopyToUser*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCopyToUser*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCopyToUser}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCopyToUser*/}

                  {/* END_USER_CODE-USER_AFTER_lblCopyToUser*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCopyToUserID*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCopyToUserID*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSecurityUserFunctionSelect*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSecurityUserFunctionSelect*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnSUFS_OK*/}

                {/* END_USER_CODE-USER_BEFORE_btnSUFS_OK*/}

                <ButtonWidget
                  conf={state.btnSUFS_OK}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSUFS_OK*/}

                {/* END_USER_CODE-USER_AFTER_btnSUFS_OK*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_SecurityUserFunctionSelect*/}

              {/* END_USER_CODE-USER_AFTER_SecurityUserFunctionSelect*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_SecurityUserFunctionSelect
);
