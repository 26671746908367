/* istanbul ignore file */
/* eslint-disable*/
import React,{ useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,useHistory,
  useLocation,
  useRouteMatch,
} from "../../shared/WindowImports";



function TestModule_LOVChildPage(props) {
const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  let states = {
    Label: "LOVChildPage",
    windowName: "LOVChildPage",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "translate.LOVChildPage",

    buttonwidget3: {
      name: "buttonwidget3",
      type: "ButtonWidget",
      parent: "commandcontainerwidget2",
      Label: "Ok",
      CharWidth: "8"

    },
    buttonwidget4: {
      name: "buttonwidget4",
      type: "ButtonWidget",
      parent: "commandcontainerwidget2",
      Category: "Close",
      Label: "Close",
      CharWidth: "13"

    },
    buttonwidget5: {
      name: "buttonwidget5",
      type: "ButtonWidget",
      parent: "commandcontainerwidget2",
      Category: "Cancel",
      Label: "Cancel",
      CharWidth: "15"

    },
    textboxwidget1: {
      name: "textboxwidget1",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Text Box Widget",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String"

    },
    textboxwidget6: {
      name: "textboxwidget6",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "textboxwidget6",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String"

    },
    groupboxwidget0: {
      name: "groupboxwidget0",
      type: "GroupBoxWidget",
      parent: "LOVChildPage",
      Label: "Group Box Widget",
      Height: "",
      Width: ""

    },
    commandcontainerwidget2: {
      name: "commandcontainerwidget2",
      type: "CommandContainerWidget",
      parent: "LOVChildPage",
      Label: "commandcontainerwidget2",
      Height: "",
      Width: ""

    },
    _dataCopy: {
      FORWARD: {},
      REVERSE: {}
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj,state.windowName);
    callOnOpenService(thisObj, screenRef);
  }, []);

   initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
    return (
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue
        }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
          return (
            <div
              className={
                _kaledo.guiModuleName +
                "-" +
                state.windowName +
                " col-lg-12"
              }
              data-testid={state.windowName}
              ref={screenRef}
            >
              <Modal
                show={state.showPopup}
                onHide={handleModalHide}
                data={dataCopyObj.dataToCopy}
                component={state.popupComponent}
              />

              <h1 className="pageHeader">{state.Label}</h1>

              <Form noValidate className="row">


                <GroupBoxWidget
                  conf={state.groupboxwidget0}
                  screenConf={state}
                >


                  <TextBoxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget6}
                    screenConf={state}
                  ></TextBoxWidget>


                  <TextBoxWidget
                    onChange={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    onBlur={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget1}
                    screenConf={state}
                  ></TextBoxWidget>

                </GroupBoxWidget>


                <CommandContainerWidget
                  conf={state.commandcontainerwidget2}
                  screenConf={state}
                >


                  <ButtonWidget
                    conf={state.buttonwidget3}
                    screenConf={state}
                    onClick={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                  ></ButtonWidget>

                  <ButtonWidget
                    conf={state.buttonwidget4}
                    screenConf={state}
                    onClick={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                  ></ButtonWidget>


                  <ButtonWidget
                    conf={state.buttonwidget5}
                    screenConf={state}
                    onClick={(event=>invokeEventHandler(event,handleChange,handleBlur,thisObj))}
                  ></ButtonWidget>

                </CommandContainerWidget>

              </Form>
            </div>
          );
        }}
      </Formik>
    );
  
}
export default withRouter(TestModule_LOVChildPage);
