/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setData,
  setValue,
  getValue,
  getData,
  goTo,
  enable,
  disable,
  getSelectedRowNumber
} from "../../shared/WindowImports";

import "./PeanutSpecificationSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices"
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { js2xml } from "xml-js";
import ExternalReceiptForfeituresSearch from "../../WarehouseReceipts/ExternalReceiptForfeituresSearch/ExternalReceiptForfeituresSearch";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { response } from "msw";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_PeanutSpecificationSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let bFillBuyingPointListBool = false;
  let LoadIndex = 0;
  let mbSuccessfulLoad = false;
  let userid = sessionStorage.getItem("userid");
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PeanutSpecificationSetup",
    windowName: "PeanutSpecificationSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.PeanutSpecificationSetup",
    // START_USER_CODE-USER_PeanutSpecificationSetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Peanut Specifications Setup",
      scrCode: "PN0080A",
    },
    // END_USER_CODE-USER_PeanutSpecificationSetup_PROPERTIES
    btnAddS: {
      name: "btnAddS",
      type: "ButtonWidget",
      parent: "grpbxPeanutSpecificationSetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddS_PROPERTIES

      // END_USER_CODE-USER_btnAddS_PROPERTIES
    },
    btnAddSE: {
      name: "btnAddSE",
      type: "ButtonWidget",
      parent: "grpbxPeanutSpecificationSetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddSE_PROPERTIES

      // END_USER_CODE-USER_btnAddSE_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btncmdEnableDisableControls: {
      name: "btncmdEnableDisableControls",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "cmdEnableDisableControls",
      CharWidth: "48",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncmdEnableDisableControls_PROPERTIES

      // END_USER_CODE-USER_btncmdEnableDisableControls_PROPERTIES
    },
    btnDeleteS: {
      name: "btnDeleteS",
      type: "ButtonWidget",
      parent: "grpbxPeanutSpecificationSetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteS_PROPERTIES

      // END_USER_CODE-USER_btnDeleteS_PROPERTIES
    },
    btnDeleteSE: {
      name: "btnDeleteSE",
      type: "ButtonWidget",
      parent: "grpbxPeanutSpecificationSetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteSE_PROPERTIES

      // END_USER_CODE-USER_btnDeleteSE_PROPERTIES
    },
    btnEditS: {
      name: "btnEditS",
      type: "ButtonWidget",
      parent: "grpbxPeanutSpecificationSetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditS_PROPERTIES

      // END_USER_CODE-USER_btnEditS_PROPERTIES
    },
    btnEditSE: {
      name: "btnEditSE",
      type: "ButtonWidget",
      parent: "grpbxPeanutSpecificationSetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditSE_PROPERTIES

      // END_USER_CODE-USER_btnEditSE_PROPERTIES
    },
    ddArea: {
      name: "ddArea",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutSpecificationSetup",
      Label: "Area:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddArea_PROPERTIES

      // END_USER_CODE-USER_ddArea_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutSpecificationSetup",
      Label: "Buying Point:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    gridSpecificationExpectations: {
      name: "gridSpecificationExpectations",
      type: "GridWidget",
      parent: "grpbxPeanutSpecificationSetup",
      gridCellsOrder:
        "txtPeanutTypeSE,txtPeanutVarietySE,txtSeedSE,txtSegSE,txtEdibleOilSE",
      ColSpan: "4",
      Pagination: false,
      HasLabel: false,
      Cols: "8",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridSpecificationExpectations_PROPERTIES

      // END_USER_CODE-USER_gridSpecificationExpectations_PROPERTIES
    },
    gridSpecifications: {
      name: "gridSpecifications",
      type: "GridWidget",
      parent: "grpbxPeanutSpecificationSetup",
      gridCellsOrder: "txtPeanutTypeS,txtSeedS,txtSegS,txtEdibleOilS",
      ColSpan: "4",
      Pagination: false,
      HasLabel: false,
      Cols: "8",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridSpecifications_PROPERTIES

      // END_USER_CODE-USER_gridSpecifications_PROPERTIES
    },
    lblSpecificationExpectations: {
      name: "lblSpecificationExpectations",
      type: "LabelWidget",
      parent: "grpbxPeanutSpecificationSetup",
      Label: "Specification Expectations:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpecificationExpectations_PROPERTIES

      // END_USER_CODE-USER_lblSpecificationExpectations_PROPERTIES
    },
    lblSpecifications: {
      name: "lblSpecifications",
      type: "LabelWidget",
      parent: "grpbxPeanutSpecificationSetup",
      Label: "Specifications:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpecifications_PROPERTIES

      // END_USER_CODE-USER_lblSpecifications_PROPERTIES
    },
    txtColEdibleOilS: {
      name: "txtColEdibleOilS",
      type: "GridColumnWidget",
      parent: "gridSpecifications",
      Label: "Edible/Oil",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColEdibleOilS_PROPERTIES

      // END_USER_CODE-USER_txtColEdibleOilS_PROPERTIES
    },
    txtColEdibleOilSE: {
      name: "txtColEdibleOilSE",
      type: "GridColumnWidget",
      parent: "gridSpecificationExpectations",
      Label: "Edible/Oil",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColEdibleOilSE_PROPERTIES

      // END_USER_CODE-USER_txtColEdibleOilSE_PROPERTIES
    },
    txtColPeanutTypeS: {
      name: "txtColPeanutTypeS",
      type: "GridColumnWidget",
      parent: "gridSpecifications",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPeanutTypeS_PROPERTIES

      // END_USER_CODE-USER_txtColPeanutTypeS_PROPERTIES
    },
    txtColPeanutTypeSE: {
      name: "txtColPeanutTypeSE",
      type: "GridColumnWidget",
      parent: "gridSpecificationExpectations",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPeanutTypeSE_PROPERTIES

      // END_USER_CODE-USER_txtColPeanutTypeSE_PROPERTIES
    },
    txtColPeanutVarietySE: {
      name: "txtColPeanutVarietySE",
      type: "GridColumnWidget",
      parent: "gridSpecificationExpectations",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPeanutVarietySE_PROPERTIES

      // END_USER_CODE-USER_txtColPeanutVarietySE_PROPERTIES
    },
    txtColSeedS: {
      name: "txtColSeedS",
      type: "GridColumnWidget",
      parent: "gridSpecifications",
      Label: "Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSeedS_PROPERTIES

      // END_USER_CODE-USER_txtColSeedS_PROPERTIES
    },
    txtColSeedSE: {
      name: "txtColSeedSE",
      type: "GridColumnWidget",
      parent: "gridSpecificationExpectations",
      Label: "Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSeedSE_PROPERTIES

      // END_USER_CODE-USER_txtColSeedSE_PROPERTIES
    },
    txtColSegS: {
      name: "txtColSegS",
      type: "GridColumnWidget",
      parent: "gridSpecifications",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSegS_PROPERTIES

      // END_USER_CODE-USER_txtColSegS_PROPERTIES
    },
    txtColSegSE: {
      name: "txtColSegSE",
      type: "GridColumnWidget",
      parent: "gridSpecificationExpectations",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSegSE_PROPERTIES

      // END_USER_CODE-USER_txtColSegSE_PROPERTIES
    },
    txtEdibleOilS: {
      name: "txtEdibleOilS",
      type: "TextBoxWidget",
      colName: "txtColEdibleOilS",
      parent: "gridSpecifications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEdibleOilS_PROPERTIES

      // END_USER_CODE-USER_txtEdibleOilS_PROPERTIES
    },
    txtEdibleOilSE: {
      name: "txtEdibleOilSE",
      type: "TextBoxWidget",
      colName: "txtColEdibleOilSE",
      parent: "gridSpecificationExpectations",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEdibleOilSE_PROPERTIES

      // END_USER_CODE-USER_txtEdibleOilSE_PROPERTIES
    },
    txtPeanutTypeS: {
      name: "txtPeanutTypeS",
      type: "TextBoxWidget",
      colName: "txtColPeanutTypeS",
      parent: "gridSpecifications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutTypeS_PROPERTIES

      // END_USER_CODE-USER_txtPeanutTypeS_PROPERTIES
    },
    txtPeanutTypeSE: {
      name: "txtPeanutTypeSE",
      type: "TextBoxWidget",
      colName: "txtColPeanutTypeSE",
      parent: "gridSpecificationExpectations",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutTypeSE_PROPERTIES

      // END_USER_CODE-USER_txtPeanutTypeSE_PROPERTIES
    },
    txtPeanutVarietySE: {
      name: "txtPeanutVarietySE",
      type: "TextBoxWidget",
      colName: "txtColPeanutVarietySE",
      parent: "gridSpecificationExpectations",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutVarietySE_PROPERTIES

      // END_USER_CODE-USER_txtPeanutVarietySE_PROPERTIES
    },
    txtSeedS: {
      name: "txtSeedS",
      type: "TextBoxWidget",
      colName: "txtColSeedS",
      parent: "gridSpecifications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedS_PROPERTIES

      // END_USER_CODE-USER_txtSeedS_PROPERTIES
    },
    txtSeedSE: {
      name: "txtSeedSE",
      type: "TextBoxWidget",
      colName: "txtColSeedSE",
      parent: "gridSpecificationExpectations",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedSE_PROPERTIES

      // END_USER_CODE-USER_txtSeedSE_PROPERTIES
    },
    txtSegS: {
      name: "txtSegS",
      type: "TextBoxWidget",
      colName: "txtColSegS",
      parent: "gridSpecifications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegS_PROPERTIES

      // END_USER_CODE-USER_txtSegS_PROPERTIES
    },
    txtSegSE: {
      name: "txtSegSE",
      type: "TextBoxWidget",
      colName: "txtColSegSE",
      parent: "gridSpecificationExpectations",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegSE_PROPERTIES

      // END_USER_CODE-USER_txtSegSE_PROPERTIES
    },
    grpbxPeanutSpecificationSetup: {
      name: "grpbxPeanutSpecificationSetup",
      type: "GroupBoxWidget",
      parent: "PeanutSpecificationSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxPeanutSpecificationSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxPeanutSpecificationSetup_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "PeanutSpecificationSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceMasterManagement#PeanutSpecificationProfile": {},
        "SystemMaintenanceMasterManagement#PeanutSpecificationExceptionProfile": {},
      },
      REVERSE: {
        "SystemMaintenanceMasterManagement#PeanutSpecificationProfile": {},
        "SystemMaintenanceMasterManagement#PeanutSpecificationExceptionProfile": {},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnClose: {
      DEFAULT:["ContractManagement#MainMenu#DEFAULT#false#Click"],
    }, 
    btnAddS: {
      DEFAULT: [
        "SystemMaintenanceMasterManagement#PeanutSpecificationProfile#DEFAULT#true#Click",]
    },
    // btnEditS: {
    //   DEFAULT: [
    //     "SystemMaintenanceMasterManagement#PeanutSpecificationProfile#DEFAULT#true#Click",]
    // },
    // btnAddSE: {
    //   DEFAULT: [
    //     "SystemMaintenanceMasterManagement#PeanutSpecificationExceptionProfile#DEFAULT#true#Click",]
    // },
    // btnEditSE: {
    //   DEFAULT: [
    //     "SystemMaintenanceMasterManagement#PeanutSpecificationExceptionProfile#DEFAULT#true#Click",]
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const [selectedRowNum, setSelectedRowNum] = useState(-1);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    if(getData(thisObj, 'RefreshgridSpecification') == true){
      bFillAreaListGrid()
      setData(thisObj, 'RefreshgridSpecification', false)
    }
  }, [getData(thisObj, 'RefreshgridSpecification')]);

  useEffect(() => {
    if(getData(thisObj, 'RefreshgridSpecificationExpectations') == true){
      bFillBuyingPointListGrid()
      setData(thisObj, 'RefreshgridSpecificationExpectations', false)
    }
  }, [getData(thisObj, 'RefreshgridSpecificationExpectations')]);

  let areaId = ''
  let buyingPointId = null
  // START_USER_CODE-USER_METHODS
  async function FormLoad() {
    try {
      await bFillAreaList(); //Method for binding Area List dropdown
      await bFillBuyingPointList(); //Method for binding Buying Point List dropdown
      await bFillAreaListGrid();
      await bFillBuyingPointListGrid();
      await EnableDisableControls()
      await CheckAccessLevel()
      await CheckExceptionAccessLevel()
    }
    catch (err) {
      errorHandler(err)
    }
  }
  const EnableDisableControls = async () =>{
    try{
    let gridSpecificationsLength = getData(thisObj,'gridSpecificationsLength');
    let gridExpectationsLength = getData(thisObj,'gridExpectationsLength');

    if (gridSpecificationsLength <= 0) {
      disable(thisObj, 'btnEditS')
      disable(thisObj, 'btnDeleteS')
    }
  
      if (gridExpectationsLength <= 0) {
        disable(thisObj, 'btnEditSE')
        disable(thisObj, 'btnDeleteSE')
      }
  }
  catch (err) {
    errorHandler(err)
  }
}
  
  //Method to bind the Area dropdown list
  const bFillAreaList = async () => {
    let js = [];
    let data = await SettlementService.RetrieveAreaControlDetails(null)//.then(response => {
    for (var i = 0; i < data.length; i++) {
      js.push({
        key: data[i].areaId,
        description: data[i].areaId + " - " + data[i].areaName
      })
    }
    
    thisObj.setState(current => {
      return {
        ...current,
        ddArea: {
          ...state["ddArea"],
          valueList: js,
        }
      }
    })
    setValue(thisObj, 'ddArea', js.at(0).key)
    setData(thisObj, 'ddArea', js.at(0).key)
  }
 
  //Method to bind the Buying point dropdown list
  const bFillBuyingPointList = async () => {
    let js = [];
    let data = await SettlementService.RetrieveBuyingPointControlDetails('PN0080', null, null, null, null)
    for (var i = 0; i < data.length; i++) {
      js.push({
        key: data[i].buyPtId,
        description: data[i].buyPtId + " - " + data[i].buyPtName
      })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddBuyingPoint: {
          ...state["ddBuyingPoint"],
          valueList: js
        }
      }
    })
    if(js != undefined && js != null && js.length>0){
      setValue(thisObj, 'ddBuyingPoint', js.at(0).key)
      setData(thisObj, 'ddBuyingPoint', js.at(0).key)
    }
  }
  //Method to bind the Area grid
  const bFillAreaListGrid = async () => {
    try {
      setLoading(true)
      let js = []
      let areaId = getValue(_kaledo.thisObj, 'ddArea')      
      let LstrList = await SettlementService.RetrievePeanutSpecificationDetails(areaId, null, null, null, null)
      for (var i = 0; i < LstrList.length; i++) {
        js.push({
          "txtAreaId": LstrList[i].area_id,
          "txtPeanutTypeIdS": LstrList[i].pnut_type_id,
          "txtPeanutTypeS": LstrList[i].pnut_type_name,
          "txtSeedS": LstrList[i].seed_ind == 'Y' ? 'Yes' : 'No',
          "txtSegS": "Seg " + LstrList[i].seg_type.toUpperCase().trim(),
          "txtEdibleOilS": LstrList[i].edible_oil_ind == 'E' ? 'Edible' : LstrList[i].edible_oil_ind == 'O' ? 'Crushing' : '',
          penutTypeId : LstrList[i].pnut_type_id,
          seedInd : LstrList[i].seed_ind,
          segType:LstrList[i].seg_type,
          edible_oil_ind:LstrList[i].edible_oil_ind
        })
      }
      setValue(thisObj, 'gridSpecifications', js)
      setData(thisObj,'gridSpecifications',js)
      setData(thisObj,'gridSpecificationsLength',js.length)
      CheckAccessLevel()
      EnableDisableControls();

    }
    catch (err) {
      errorHandler(err)
    }
    finally {
      setLoading(false)
    }
  }

  //Method to bind the Buying point grid
  const bFillBuyingPointListGrid = async () => {
    try {
      setLoading(true) 
      let js = []
      let LstrList = "";
      let buyingPointId = getValue(_kaledo.thisObj, 'ddBuyingPoint')
      
      LstrList = await SettlementService.RetrievePeanutSpecificationExceptionDetails(buyingPointId, null, null, null, null, null)
      if(LstrList != undefined && LstrList.length > 0){
      for (var i = 0; i < LstrList.length; i++) {
        let PeanutVarietyName = LstrList[i].pnut_variety_name == '' || LstrList[i].pnut_variety_name == null ? ">>> All Varieties <<<" : LstrList[i].pnut_variety_name
        js.push({
          // "txtBuyingPointIdSE":LstrList[i].buy_pt_id,
          'PeanutTypeID':LstrList[i]?.pnut_type_id,
          "PeanutVarietyID": LstrList[i]?.pnut_variety_id,
          "txtPeanutTypeSE": LstrList[i]?.pnut_type_name,
          "txtPeanutVarietySE": PeanutVarietyName,
          "txtSeedSE": LstrList[i]?.seed_ind == 'Y' ? 'Yes' : 'No',
          "txtSegSE": "Seg " + LstrList[i].seg_type?.toUpperCase()?.trim(),
          "txtEdibleOilSE": LstrList[i].edible_oil_ind == 'E' ? 'Edible' : LstrList[i].edible_oil_ind == 'O' ? 'Crushing' : '',
          peanutTypeName:LstrList[i]?.pnut_type_name,
          peanutTypeId: LstrList[i]?.pnut_type_id,
          peanutVarietyName:LstrList[i]?.PeanutVarietyName,
          peanutVarietyId:LstrList[i]?.pnut_variety_id,
          seedind: LstrList[i]?.seed_ind,
          segType:LstrList[i]?.seg_type,
          edible_oil_ind:LstrList[i]?.edible_oil_ind
        })
      }
      }
      setValue(thisObj, 'gridSpecificationExpectations', js)
      setData(thisObj,'gridExpectationsLength',js.length)
      CheckExceptionAccessLevel()
      EnableDisableControls()
    }
    catch (err) {
      errorHandler(err)
    }
    finally {
      setLoading(false)
    }
  }
  // On change method for Area grid 
  const onddAreaChange = async () => {
    await bFillAreaListGrid(getValue(thisObj, 'ddArea'));
    CheckAccessLevel()
  }
  thisObj.onddAreaChange = onddAreaChange

  // On change method for Buying Point grid 
  const onddBuyingPointChange = async () => {

    await bFillBuyingPointListGrid()
    CheckExceptionAccessLevel()

  }
  thisObj.onddBuyingPointChange = onddBuyingPointChange

  //Click event of Specification Add button
  const onbtnAddSClick = () => {
    try {
      let areaId = ""
      let areaName = ""
      let AreaList = thisObj.state['ddArea'].valueList;
      let SelectedAreaListValue = thisObj.values['ddArea'];
      if (SelectedAreaListValue !== "" && SelectedAreaListValue !== undefined) {
        areaId = AreaList.find(elem => elem.key === SelectedAreaListValue).key;
        areaName = AreaList.find(elem => elem.key === SelectedAreaListValue).description;
      }
      let data = {

        "cmdOk_Caption": "Add",
        "area_id": areaId,
        "area_name": areaName,
        "chkSeed_enabled": true,
        "cmbSegmentType_enabled": true,
        "cmbEdibleOilIndicator_enabled": true

      }
      setData(thisObj, 'frmPeanutSpecificationProfile', data);
      goTo(thisObj, "SystemMaintenanceMasterManagement#PeanutSpecificationProfile#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Add Button Click"
        );
      }
      return false;
    }
  };
  thisObj.onbtnAddSClick = onbtnAddSClick;

  //Click event of Specification Edit button
  const onbtnEditSClick = async () => {
    try {
      let Data = thisObj.state.gridSpecifications.selected[0];
      if(Data == undefined || Data == null){
        return;
      }
      let gridData = getValue(thisObj, 'gridSpecifications');
      let rowNum = getSelectedRowNumber(thisObj, 'gridSpecifications');
      if (rowNum == undefined) {
        rowNum = 0;
      }
      let areaId = gridData[rowNum].txtAreaId;
      let pnut_type_id = gridData[rowNum].txtPeanutTypeIdS;
      let seed_ind = gridData[rowNum].txtSeedS == 'Yes' ? 'Y' : 'N';
      let seg_type = gridData[rowNum].txtSegS.substr(4);
      let edible_oil_ind = gridData[rowNum].txtEdibleOilS == 'Edible' ? 'E' : gridData[rowNum].txtEdibleOilS == 'Crushing' ? 'O' : ''
      let LstrList = await SettlementService.RetrievePeanutSpecificationDetails(areaId, pnut_type_id, seed_ind, seg_type, edible_oil_ind)
      let areaName = ""
      let AreaList = thisObj.state['ddArea'].valueList;
      let SelectedAreaListValue = thisObj.values['ddArea'];
      if (SelectedAreaListValue !== "" && SelectedAreaListValue !== undefined) {
        areaId = AreaList.find(elem => elem.key === SelectedAreaListValue).key;
        areaName = AreaList.find(elem => elem.key === SelectedAreaListValue).description;
      }
      let data = {
        "cmdOk_Caption": "Update",
        "area_id": areaId,
        "area_name": areaName,
        "cmbPeanutType": false,
        "chkSeed_enabled": false,
        "cmbSegmentType_enabled": false,
        "cmbEdibleOilIndicator_enabled": false,
        LstrList
      }
      setData(thisObj, 'frmPeanutSpecificationProfile', data);
      goTo(thisObj, "SystemMaintenanceMasterManagement#PeanutSpecificationProfile#DEFAULT#true#Click");
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Edit Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnEditSClick = onbtnEditSClick;
  //Click event of Specification delete button
  const onbtnDeleteSClick = async () => {
    try {
      let gridData = getValue(thisObj, 'gridSpecifications');
      let area_id = getValue(thisObj, "ddArea")
      if (gridData != undefined && gridData != null && gridData != '') {

        let selectedRowData = thisObj.state.gridSpecifications.selected[0];
        if (selectedRowData == undefined) {
          showMessage(thisObj, 'Please select a row from Specificatio Grid to Delete');
          return;
        }
        else {
          let pnut_type_id = thisObj.state.gridSpecifications.selected[0].penutTypeId
          let seed_ind = thisObj.state.gridSpecifications.selected[0].seedInd
          let seg_type = thisObj.state.gridSpecifications.selected[0].segType
          let edible_oil_ind = thisObj.state.gridSpecifications.selected[0].edible_oil_ind
          const Con = confirm("Are you sure you wish to delete this Peanut Specification " + thisObj.state.gridSpecifications.selected[0].txtPeanutTypeS + " - " + thisObj.state.gridSpecifications.selected[0].txtSeedS + " - " + thisObj.state.gridSpecifications.selected[0].txtSegS + " - " + thisObj.state.gridSpecifications.selected[0].txtEdibleOilS);
          if (Con) {
            let lstrxml = await SystemMaintenanceMasterManagementService.RemovePeanutSpecification(null, area_id, pnut_type_id, seed_ind, seg_type, edible_oil_ind)
            if (lstrxml.status != 200) {
              alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
              return;
            }
            else {
              await bFillAreaListGrid()
              await EnableDisableControls()
            }
          }
          else {
            return;
          }
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Delete Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnDeleteSClick = onbtnDeleteSClick
  //Click event of SE Add button
  const onbtnAddSEClick = async() => {
    try {
      let gridData = getValue(thisObj, 'gridSpecifications');
      let rowNum = getSelectedRowNumber(thisObj, 'gridSpecifications');
      if (rowNum == undefined) {
        rowNum = 0;
      }
      let areaId = gridData[rowNum].txtAreaId;
      let pnut_type_id = gridData[rowNum].txtPeanutTypeIdS;
      let seed_ind = gridData[rowNum].txtSeedS == 'Yes' ? 'Y' : 'N';
      let seg_type = gridData[rowNum].txtSegS.substr(4);
      let edible_oil_ind = gridData[rowNum].txtEdibleOilS == 'Edible' ? 'E' : gridData[rowNum].txtEdibleOilS == 'Crushing' ? 'O' : ''
      let LstrList = await SettlementService.RetrievePeanutSpecificationDetails(areaId, pnut_type_id, seed_ind, seg_type, edible_oil_ind)
      let areaName = ""
      let AreaList = thisObj.state['ddArea'].valueList;
      let SelectedAreaListValue = thisObj.values['ddArea'];
      if (SelectedAreaListValue !== "" && SelectedAreaListValue !== undefined) {
        areaId = AreaList.find(elem => elem.key === SelectedAreaListValue).key;
        areaName = AreaList.find(elem => elem.key === SelectedAreaListValue).description;
      }
      let buyingPointId = ''
      let buyingPointName = ''
      let buyingPointList = thisObj.state['ddBuyingPoint'].valueList;
      let SelectedbuyingPointListValue = thisObj.values['ddBuyingPoint'];
      if (SelectedbuyingPointListValue !== "" && SelectedbuyingPointListValue !== undefined) {
        buyingPointId = buyingPointList.find(elem => elem.key === SelectedbuyingPointListValue).key;
        buyingPointName = buyingPointList.find(elem => elem.key === SelectedbuyingPointListValue).description;
      }
      
      let data = {
        "cmdOk_Caption": "Add",
        "areaId" : areaId,
        "areaName" : areaName,
        "buyingPoint_id": buyingPointId,
        "buyingPoint_name": buyingPointName,
        "chkSeed_enabled": true,
        "cmbSegmentType_enabled": true,
        "cmbEdibleOilIndicator_enabled": true,
        LstrList
      }
      setData(thisObj, 'frmPeanutSpecificationExceptionProfile', data);
      goTo(thisObj,"SystemMaintenanceMasterManagement#PeanutSpecificationExceptionProfile#DEFAULT#true#Click");

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Add Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddSEClick = onbtnAddSEClick;
  //Click event of SE edit button
  const onbtnEditSEClick = async () => {
    try {
      let Data = thisObj.state.gridSpecificationExpectations.selected[0];
      if(Data == undefined || Data == null){
        return;
      }
      let gridData = getValue(thisObj, 'gridSpecificationExpectations');
      let rowNum = getSelectedRowNumber(thisObj, 'gridSpecificationExpectations');
      if (rowNum == undefined) {
        rowNum = 0;
      }
      let buy_pt_id =  getValue(_kaledo.thisObj, 'ddBuyingPoint') //gridData[rowNum].txtBuyingPointIdSE;
      let pnut_type_id = gridData[rowNum].PeanutTypeID;
      let pnut_variety_id = gridData[rowNum].PeanutVarietyID;
      let seed_ind = gridData[rowNum].txtSeedSE == 'Yes' ? 'Y' : 'N';
      let seg_type = gridData[rowNum].txtSegSE.substr(4);
      let edible_oil_ind = gridData[rowNum].txtEdibleOilSE == 'Edible' ? 'E' : gridData[rowNum].txtEdibleOilS == 'Crushing' ? 'O' : ''
      let LstrList = await SettlementService.RetrievePeanutSpecificationExceptionDetails(buy_pt_id, pnut_type_id, pnut_variety_id, seed_ind, seg_type, edible_oil_ind)
      let buyingPointId = ""
      let buyingPointName = ""
      let buyingPointList = thisObj.state['ddBuyingPoint'].valueList;
      let SelectedBuyingPointListValue = thisObj.values['ddBuyingPoint'];
      if (SelectedBuyingPointListValue !== "" && SelectedBuyingPointListValue !== undefined) {
        buyingPointId = buyingPointList.find(elem => elem.key === SelectedBuyingPointListValue).key;
        buyingPointName = buyingPointList.find(elem => elem.key === SelectedBuyingPointListValue).description;
      }
      let data = {
        "cmdOk_Caption": "Update",
        "buyingPoint_id": buyingPointId,
        "buyingPoint_name": buyingPointName,
        "cmbPeanutType": false,
        "cmbPeanutVariety": false,
        "chkSeed_enabled": false,
        "cmbSegmentType_enabled": false,
        "cmbEdibleOilIndicator_enabled": false,
        LstrList
      }
      setData(thisObj, 'frmPeanutSpecificationExceptionProfile', data);
      goTo(thisObj,"SystemMaintenanceMasterManagement#PeanutSpecificationExceptionProfile#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Edit Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnEditSEClick = onbtnEditSEClick;

  const onbtnDeleteSEClick = async () => {
    try {
      let gridData = getValue(thisObj, 'gridSpecificationExpectations');
      let buy_pt_id = getValue(thisObj, "ddBuyingPoint")
      if (gridData != undefined && gridData != null && gridData != '') {

        let selectedRowData = thisObj.state.gridSpecificationExpectations.selected[0];
        if (selectedRowData == undefined) {
          showMessage(thisObj, 'Please select a row from Specification Expectations Grid to Delete');
          return;
        }
        else {
          let pnut_type_id = thisObj.state.gridSpecificationExpectations.selected[0].peanutTypeId
          let pnut_variety_id = thisObj.state.gridSpecificationExpectations.selected[0].peanutVarietyId
          let seed_ind = thisObj.state.gridSpecificationExpectations.selected[0].seedind
          let seg_type = thisObj.state.gridSpecificationExpectations.selected[0].segType
          let edible_oil_ind = thisObj.state.gridSpecificationExpectations.selected[0].edible_oil_ind
          const Conf = confirm("Are you sure you wish to delete this Peanut Specification Exception " + thisObj.state.gridSpecificationExpectations.selected[0].txtPeanutTypeSE + " - " + thisObj.state.gridSpecificationExpectations.selected[0].txtPeanutVarietySE + " - " + thisObj.state.gridSpecificationExpectations.selected[0].txtSeedSE + " - " + thisObj.state.gridSpecificationExpectations.selected[0].txtSegSE + " - " + thisObj.state.gridSpecificationExpectations.selected[0].txtEdibleOilSE);
          if (Conf) {
            let lstrxml = await SystemMaintenanceMasterManagementService.RemovePeanutSpecificationException(buy_pt_id,userid,pnut_variety_id,pnut_type_id,seed_ind,seg_type,edible_oil_ind)
            if (lstrxml.status != 200) {
              alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
              return;
            }
            else {
              await bFillBuyingPointListGrid()
              await EnableDisableControls()
            }
          }
          else {
            return;
          }
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Delete Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnDeleteSEClick = onbtnDeleteSEClick

  function errorHandler(err) {
    // if()
    // showMessage(thisObj, err.message)
  }

  const CheckAccessLevel = async () =>{
    try{
    let lstrxml = []
    let strBuyPtId = ''
    let blnRowsExist = false
    
    strBuyPtId = getValue(_kaledo.thisObj, 'ddBuyingPoint')
    let gridSpecificationsValue = getValue(_kaledo.thisObj, "gridSpecifications")
    if(gridSpecificationsValue != undefined && gridSpecificationsValue.length > 0){
      blnRowsExist = true
    }
    
    await ContractManagementService.RetrieveAccessPermissionDetails('PN0080', '001', 'D', strBuyPtId).then(response =>{
      lstrxml = response
    })
    if(lstrxml != undefined && lstrxml.length > 0){
      if(lstrxml[0].permission == 'Y'){
        EnableTrueFalse(true,blnRowsExist)
        return
      }
      else{
        disable(thisObj, 'btnEditS')
      }
    }
    

    await ContractManagementService.RetrieveAccessPermissionDetails('PN0080', '001', 'U', strBuyPtId).then(response =>{
      lstrxml = response
    })
    if(lstrxml != undefined && lstrxml.length > 0){
      if(lstrxml[0].permission == 'Y'){
        enable(thisObj,'btnAddS')
        if(blnRowsExist){
          enable(thisObj,'btnEditS')
        }else{
          disable(thisObj,'btnEditS')
        }
        disable(thisObj, 'btnDeleteS')
        return
      }
      else{
        disable(thisObj,'btnAddS')
      } 
    }

    await ContractManagementService.RetrieveAccessPermissionDetails('PN0080', '001', 'I', strBuyPtId).then(response =>{
      lstrxml = response
    })
    if(lstrxml != undefined && lstrxml.length > 0){
      if(lstrxml[0].permission == 'Y'){
        disable(thisObj,'btnAddS')
        if(blnRowsExist){
          enable(thisObj,'btnEditS')
        }else{
          disable(thisObj,'btnEditS')
        }
        disable(thisObj, 'btnDeleteS')
        return
      }
      else{
        EnableTrueFalse(false,blnRowsExist)
      } 
    }
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred during EnableTrueFalseExcep"
      );
    }
    return false;
  }
  finally{
  } 

  }

  const CheckExceptionAccessLevel = async () =>{
    try{
    let lstrxml = []
    let blnRowsExist = false
    let strBuyPtId = ''
    
    strBuyPtId = getValue(thisObj, 'ddBuyingPoint')
    let gridSpecificationExpectationsValue = getValue(_kaledo.thisObj, "gridSpecificationExpectations")
    if(gridSpecificationExpectationsValue != undefined && gridSpecificationExpectationsValue.length > 0){
      blnRowsExist = true
    }
    
    await ContractManagementService.RetrieveAccessPermissionDetails('PN0080', '002', 'D', strBuyPtId).then(response =>{
      lstrxml = response
    })
    if(lstrxml != undefined && lstrxml.length > 0){
      if(lstrxml[0].permission == 'Y'){
        EnableTrueFalseExcep(true, blnRowsExist)
        return;
      }
      else{
        disable(thisObj, 'btnEditSE')
      } 
    }

    await ContractManagementService.RetrieveAccessPermissionDetails('PN0080', '002', 'U', strBuyPtId).then(response =>{
      lstrxml = response
    })
    if(lstrxml != undefined && lstrxml.length > 0){
      if(lstrxml[0].permission == 'Y'){
        enable(thisObj, 'btnAddSE')
        if(blnRowsExist){
          enable(thisObj,'btnEditSE')
        }else{
          disable(thisObj,'btnEditSE')
        }
        disable(thisObj, 'btnDeleteSE')
        return;
      }
      else{
        disable(thisObj, 'btnAddSE')
      } 
    }

    await ContractManagementService.RetrieveAccessPermissionDetails('PN0080', '002', 'I', strBuyPtId).then(response =>{
      lstrxml = response
    })
    if(lstrxml != undefined && lstrxml.length > 0){
      if(lstrxml[0].permission == 'Y'){
        disable(thisObj, 'btnAddSE')
        if(blnRowsExist){
          enable(thisObj,'btnEditSE')
        }else{
          disable(thisObj,'btnEditSE')
        }
        disable(thisObj, 'btnDeleteSE')
        return;
      }
      else{
        EnableTrueFalseExcep(false, blnRowsExist)
      } 
    }
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred during EnableTrueFalseExcep"
      );
    }
    return false;
  }
  finally{
  } 
  }

  const EnableTrueFalse = async (indBol,blnRowsExist) => {
    try {
      if(indBol == true){
        enable(thisObj, 'btnAddS')
      }else{
        disable(thisObj, 'btnAddS')
      }

      if(indBol == true && blnRowsExist == true ){
        enable(thisObj, 'btnEditS')
        enable(thisObj, 'btnDeleteS')
      }else{
        disable(thisObj, 'btnEditS')
        disable(thisObj, 'btnDeleteS')
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during EnableTrueFalseExcep"
        );
      }
      return false;
    }
  }

  const EnableTrueFalseExcep= async (indBol,blnRowsExist) => {
    try {
      if(indBol == true){
        enable(thisObj, 'btnAddSE')
      }else{
        disable(thisObj, 'btnAddSE')
      }

      if(indBol == true && blnRowsExist == true ){
        enable(thisObj, 'btnEditSE')
        enable(thisObj, 'btnDeleteSE')
      }else{
        disable(thisObj, 'btnEditSE')
        disable(thisObj, 'btnDeleteSE')
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during EnableTrueFalse"
        );
      }
      return false;
    }
  }
	  

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PeanutSpecificationSetup*/}

              {/* END_USER_CODE-USER_BEFORE_PeanutSpecificationSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPeanutSpecificationSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPeanutSpecificationSetup*/}

              <GroupBoxWidget
                conf={state.grpbxPeanutSpecificationSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddArea*/}

                {/* END_USER_CODE-USER_BEFORE_ddArea*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddArea}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddArea*/}

                {/* END_USER_CODE-USER_AFTER_ddArea*/}
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_lblSpecifications*/}

                {/* END_USER_CODE-USER_BEFORE_lblSpecifications*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSpecifications}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSpecifications*/}

                {/* END_USER_CODE-USER_AFTER_lblSpecifications*/}
                {/* START_USER_CODE-USER_BEFORE_btnAddS*/}

                {/* END_USER_CODE-USER_BEFORE_btnAddS*/}

                <ButtonWidget
                  conf={state.btnAddS}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAddS*/}

                {/* END_USER_CODE-USER_AFTER_btnAddS*/}
                {/* START_USER_CODE-USER_BEFORE_btnEditS*/}

                {/* END_USER_CODE-USER_BEFORE_btnEditS*/}

                <ButtonWidget
                  conf={state.btnEditS}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEditS*/}

                {/* END_USER_CODE-USER_AFTER_btnEditS*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeleteS*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeleteS*/}

                <ButtonWidget
                  conf={state.btnDeleteS}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeleteS*/}

                {/* END_USER_CODE-USER_AFTER_btnDeleteS*/}
                {/* START_USER_CODE-USER_BEFORE_lblSpecificationExpectations*/}

                {/* END_USER_CODE-USER_BEFORE_lblSpecificationExpectations*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSpecificationExpectations}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSpecificationExpectations*/}

                {/* END_USER_CODE-USER_AFTER_lblSpecificationExpectations*/}
                {/* START_USER_CODE-USER_BEFORE_btnAddSE*/}

                {/* END_USER_CODE-USER_BEFORE_btnAddSE*/}

                <ButtonWidget
                  conf={state.btnAddSE}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAddSE*/}

                {/* END_USER_CODE-USER_AFTER_btnAddSE*/}
                {/* START_USER_CODE-USER_BEFORE_btnEditSE*/}

                {/* END_USER_CODE-USER_BEFORE_btnEditSE*/}

                <ButtonWidget
                  conf={state.btnEditSE}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEditSE*/}

                {/* END_USER_CODE-USER_AFTER_btnEditSE*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeleteSE*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeleteSE*/}

                <ButtonWidget
                  conf={state.btnDeleteSE}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeleteSE*/}

                {/* END_USER_CODE-USER_AFTER_btnDeleteSE*/}
                {/* START_USER_CODE-USER_BEFORE_gridSpecifications*/}

                {/* END_USER_CODE-USER_BEFORE_gridSpecifications*/}

                <GridWidget
                  conf={state.gridSpecifications}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridSpecifications}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridSpecifications*/}

                {/* END_USER_CODE-USER_AFTER_gridSpecifications*/}
                {/* START_USER_CODE-USER_BEFORE_gridSpecificationExpectations*/}

                {/* END_USER_CODE-USER_BEFORE_gridSpecificationExpectations*/}

                <GridWidget
                  conf={state.gridSpecificationExpectations}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridSpecificationExpectations}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridSpecificationExpectations*/}

                {/* END_USER_CODE-USER_AFTER_gridSpecificationExpectations*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPeanutSpecificationSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPeanutSpecificationSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btncmdEnableDisableControls*/}

                {/* END_USER_CODE-USER_BEFORE_btncmdEnableDisableControls*/}

                <ButtonWidget
                  conf={state.btncmdEnableDisableControls}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btncmdEnableDisableControls*/}

                {/* END_USER_CODE-USER_AFTER_btncmdEnableDisableControls*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_PeanutSpecificationSetup*/}

              {/* END_USER_CODE-USER_AFTER_PeanutSpecificationSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_PeanutSpecificationSetup
);
