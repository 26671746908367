/* eslint-disable*/
import React from "react";
import CheckboxGroupWidget from "./CheckboxGroupWidget";
import { screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("CheckboxGroupWidget Test Cases-1", () => {
  beforeEach(async () => {
    act(() => {
      let checkboxGroupWidget1 = {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
        OptionsAlignment: "Horizontal",
        name: "checkboxGroupWidget1",
        type: "CheckboxGroupWidget",
        parent: "groupboxwidget0",
        Label: "checkboxGroupWidget1",
        ofTypeDomain: "d_boolean",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = { checkboxGroupWidget1: [] };
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      let onBlur = jest.fn();
      renderTestScreen(
        <CheckboxGroupWidget
          onBlur={onBlur}
          onChange={onChange}
          values={values}
          conf={checkboxGroupWidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("CheckboxGroupWidget Widget Test Cases Basic", async () => {
    const checkboxGroupWidget1 = screen.getByTestId("checkboxGroupWidget1")
      .firstChild.firstChild;
    expect(checkboxGroupWidget1.tagName).toBe("INPUT");
    expect(checkboxGroupWidget1.type).toBe("checkbox");
    fireEvent.blur(checkboxGroupWidget1, {
      target: { id: "checkboxGroupWidget1" },
    });
    expect(checkboxGroupWidget1.id).toBe("checkboxGroupWidget1");
  });
});
describe("CheckboxGroupWidget Test Cases-2", () => {
  beforeEach(async () => {
    act(() => {
      let checkboxGroupWidget1 = {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
        OptionsAlignment: "Horizontal",
        name: "checkboxGroupWidget1",
        type: "CheckboxGroupWidget",
        parent: "groupboxwidget0",
        Label: "checkboxGroupWidget1",
        ofTypeDomain: "d_boolean",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true,
      };
      let values = { checkboxGroupWidget1: [] };
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();

      renderTestScreen(
        <CheckboxGroupWidget
          onChange={onChange}
          values={values}
          conf={checkboxGroupWidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("CheckboxGroupWidget Widget Event onChange test Cases", async () => {
    const checkboxGroupWidget1 = screen.getByTestId("checkboxGroupWidget1")
      .firstChild.firstChild;
    fireEvent.click(checkboxGroupWidget1, {
      target: { checked: false },
    });
    expect(checkboxGroupWidget1.checked).toEqual(false);
    fireEvent.click(checkboxGroupWidget1, {
      target: { checked: true },
    });
    expect(checkboxGroupWidget1).checked;
    fireEvent.change(checkboxGroupWidget1, { target: { value: "1" } });
    expect(checkboxGroupWidget1.value).toBe("1");
    fireEvent.change(checkboxGroupWidget1, { target: { value: "2" } });
    expect(checkboxGroupWidget1.value).toBe("2");
  });
});
