/* eslint-disable*/
import React from "react";
import StockTransfer_TransferSearch from "./TransferSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("TransferSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_TransferSearch />);
    });
  });
  afterEach(cleanup);
  test("is TransferSearch Loads Successfully", () => {
    expect(screen.getByText("TransferSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for TransferSearch", () => {
    // START_USER_CODE-USER_TransferSearch_Custom_Test_Case
    // END_USER_CODE-USER_TransferSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_TransferSearch />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("StockTransfer:TransferSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCrtNewTrnsfr(Button Widget) Test Cases", async () => {
    const btnCrtNewTrnsfr = screen.getByTestId("btnCrtNewTrnsfr");
    expect(btnCrtNewTrnsfr).toBeInTheDocument;
    expect(btnCrtNewTrnsfr.textContent).toEqual(
      t("StockTransfer:TransferSearch_btnCrtNewTrnsfr")
    );
  });
  test("Custom Test Cases for btnCrtNewTrnsfr", () => {
    // START_USER_CODE-USER_btnCrtNewTrnsfr_TEST
    // END_USER_CODE-USER_btnCrtNewTrnsfr_TEST
  });
  test("btnExport(Button Widget) Test Cases", async () => {
    const btnExport = screen.getByTestId("btnExport");
    expect(btnExport).toBeInTheDocument;
    expect(btnExport.textContent).toEqual(
      t("StockTransfer:TransferSearch_btnExport")
    );
  });
  test("Custom Test Cases for btnExport", () => {
    // START_USER_CODE-USER_btnExport_TEST
    // END_USER_CODE-USER_btnExport_TEST
  });
  test("btnFrtAccr(Button Widget) Test Cases", async () => {
    const btnFrtAccr = screen.getByTestId("btnFrtAccr");
    expect(btnFrtAccr).toBeInTheDocument;
    expect(btnFrtAccr.textContent).toEqual(
      t("StockTransfer:TransferSearch_btnFrtAccr")
    );
  });
  test("Custom Test Cases for btnFrtAccr", () => {
    // START_USER_CODE-USER_btnFrtAccr_TEST
    // END_USER_CODE-USER_btnFrtAccr_TEST
  });
  test("btnLookup(Button Widget) Test Cases", async () => {
    const btnLookup = screen.getByTestId("btnLookup");
    expect(btnLookup).toBeInTheDocument;
    expect(btnLookup.textContent).toEqual(
      t("StockTransfer:TransferSearch_btnLookup")
    );
  });
  test("Custom Test Cases for btnLookup", () => {
    // START_USER_CODE-USER_btnLookup_TEST
    // END_USER_CODE-USER_btnLookup_TEST
  });
  test("btnMltiTrnsfr(Button Widget) Test Cases", async () => {
    const btnMltiTrnsfr = screen.getByTestId("btnMltiTrnsfr");
    expect(btnMltiTrnsfr).toBeInTheDocument;
    expect(btnMltiTrnsfr.textContent).toEqual(
      t("StockTransfer:TransferSearch_btnMltiTrnsfr")
    );
  });
  test("Custom Test Cases for btnMltiTrnsfr", () => {
    // START_USER_CODE-USER_btnMltiTrnsfr_TEST
    // END_USER_CODE-USER_btnMltiTrnsfr_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("StockTransfer:TransferSearch_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("StockTransfer:TransferSearch_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnPrntTcktDlvry(Button Widget) Test Cases", async () => {
    const btnPrntTcktDlvry = screen.getByTestId("btnPrntTcktDlvry");
    expect(btnPrntTcktDlvry).toBeInTheDocument;
    expect(btnPrntTcktDlvry.textContent).toEqual(
      t("StockTransfer:TransferSearch_btnPrntTcktDlvry")
    );
  });
  test("Custom Test Cases for btnPrntTcktDlvry", () => {
    // START_USER_CODE-USER_btnPrntTcktDlvry_TEST
    // END_USER_CODE-USER_btnPrntTcktDlvry_TEST
  });
  test("btnSrch(Button Widget) Test Cases", async () => {
    const btnSrch = screen.getByTestId("btnSrch");
    expect(btnSrch).toBeInTheDocument;
    expect(btnSrch.textContent).toEqual(
      t("StockTransfer:TransferSearch_btnSrch")
    );
  });
  test("Custom Test Cases for btnSrch", () => {
    // START_USER_CODE-USER_btnSrch_TEST
    // END_USER_CODE-USER_btnSrch_TEST
  });
  test("btnViewRelAccr(Button Widget) Test Cases", async () => {
    const btnViewRelAccr = screen.getByTestId("btnViewRelAccr");
    expect(btnViewRelAccr).toBeInTheDocument;
    expect(btnViewRelAccr.textContent).toEqual(
      t("StockTransfer:TransferSearch_btnViewRelAccr")
    );
  });
  test("Custom Test Cases for btnViewRelAccr", () => {
    // START_USER_CODE-USER_btnViewRelAccr_TEST
    // END_USER_CODE-USER_btnViewRelAccr_TEST
  });
  test("dtRcvdFrm(Date Widget) Test Cases", async () => {
    const dtRcvdFrm = screen.getByTestId("dtRcvdFrm");
    expect(dtRcvdFrm.tagName).toBe("INPUT");
    expect(dtRcvdFrm.type).toBe("text");
    expect(dtRcvdFrm.classList).toContain("datetimepicker-input");
    expect(dtRcvdFrm.parentElement.previousElementSibling.textContent).toEqual(
      t("StockTransfer:TransferSearch_dtRcvdFrm")
    );
    await act(async () => {
      userEvent.click(dtRcvdFrm.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtRcvdFrm", () => {
    // START_USER_CODE-USER_dtRcvdFrm_TEST
    // END_USER_CODE-USER_dtRcvdFrm_TEST
  });
  test("dtRcvdTo(Date Widget) Test Cases", async () => {
    const dtRcvdTo = screen.getByTestId("dtRcvdTo");
    expect(dtRcvdTo.tagName).toBe("INPUT");
    expect(dtRcvdTo.type).toBe("text");
    expect(dtRcvdTo.classList).toContain("datetimepicker-input");
    expect(dtRcvdTo.parentElement.previousElementSibling.textContent).toEqual(
      t("StockTransfer:TransferSearch_dtRcvdTo")
    );
    await act(async () => {
      userEvent.click(dtRcvdTo.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtRcvdTo", () => {
    // START_USER_CODE-USER_dtRcvdTo_TEST
    // END_USER_CODE-USER_dtRcvdTo_TEST
  });
  test("dtShippdDtFrm(Date Widget) Test Cases", async () => {
    const dtShippdDtFrm = screen.getByTestId("dtShippdDtFrm");
    expect(dtShippdDtFrm.tagName).toBe("INPUT");
    expect(dtShippdDtFrm.type).toBe("text");
    expect(dtShippdDtFrm.classList).toContain("datetimepicker-input");
    expect(
      dtShippdDtFrm.parentElement.previousElementSibling.textContent
    ).toEqual(t("StockTransfer:TransferSearch_dtShippdDtFrm"));
    await act(async () => {
      userEvent.click(dtShippdDtFrm.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtShippdDtFrm", () => {
    // START_USER_CODE-USER_dtShippdDtFrm_TEST
    // END_USER_CODE-USER_dtShippdDtFrm_TEST
  });
  test("dtShippdTo(Date Widget) Test Cases", async () => {
    const dtShippdTo = screen.getByTestId("dtShippdTo");
    expect(dtShippdTo.tagName).toBe("INPUT");
    expect(dtShippdTo.type).toBe("text");
    expect(dtShippdTo.classList).toContain("datetimepicker-input");
    expect(dtShippdTo.parentElement.previousElementSibling.textContent).toEqual(
      t("StockTransfer:TransferSearch_dtShippdTo")
    );
    await act(async () => {
      userEvent.click(dtShippdTo.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtShippdTo", () => {
    // START_USER_CODE-USER_dtShippdTo_TEST
    // END_USER_CODE-USER_dtShippdTo_TEST
  });
  test("gridTrnsfrSrch(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrchbtn = gridTrnsfrSrch.nextElementSibling.firstElementChild;
    gridTrnsfrSrch = gridTrnsfrSrch.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch.tagName).toBe("DIV");
    expect(gridTrnsfrSrch.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridTrnsfrSrch", () => {
    // START_USER_CODE-USER_gridTrnsfrSrch_TEST
    // END_USER_CODE-USER_gridTrnsfrSrch_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxGrid(GroupBox Widget) Test Cases", async () => {
    const grpbxGrid = screen.getByTestId("grpbxGrid");
    expect(grpbxGrid.tagName).toBe("BUTTON");
    expect(grpbxGrid.type).toBe("button");
    expect(grpbxGrid.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGrid", () => {
    // START_USER_CODE-USER_grpbxGrid_TEST
    // END_USER_CODE-USER_grpbxGrid_TEST
  });
  test("grpbxTrnsfr(GroupBox Widget) Test Cases", async () => {
    const grpbxTrnsfr = screen.getByTestId("grpbxTrnsfr");
    expect(grpbxTrnsfr.tagName).toBe("BUTTON");
    expect(grpbxTrnsfr.type).toBe("button");
    expect(grpbxTrnsfr.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTrnsfr", () => {
    // START_USER_CODE-USER_grpbxTrnsfr_TEST
    // END_USER_CODE-USER_grpbxTrnsfr_TEST
  });
  test("lblCropYr(Label Widget) Test Cases", async () => {
    const lblCropYr = screen.getByTestId("lblCropYr");
    expect(lblCropYr.tagName).toBe("LABEL");
    expect(lblCropYr.classList).toContain("form-label");
    expect(lblCropYr.textContent).toEqual(
      t("StockTransfer:TransferSearch_lblCropYr")
    );
  });
  test("Custom Test Cases for lblCropYr", () => {
    // START_USER_CODE-USER_lblCropYr_TEST
    // END_USER_CODE-USER_lblCropYr_TEST
  });
  test("lblTotal(Label Widget) Test Cases", async () => {
    const lblTotal = screen.getByTestId("lblTotal");
    expect(lblTotal.tagName).toBe("LABEL");
    expect(lblTotal.classList).toContain("form-label");
    expect(lblTotal.textContent).toEqual(
      t("StockTransfer:TransferSearch_lblTotal")
    );
  });
  test("Custom Test Cases for lblTotal", () => {
    // START_USER_CODE-USER_lblTotal_TEST
    // END_USER_CODE-USER_lblTotal_TEST
  });
  test("lblTrnsfrList(Label Widget) Test Cases", async () => {
    const lblTrnsfrList = screen.getByTestId("lblTrnsfrList");
    expect(lblTrnsfrList.tagName).toBe("LABEL");
    expect(lblTrnsfrList.classList).toContain("form-label");
    expect(lblTrnsfrList.textContent).toEqual(
      t("StockTransfer:TransferSearch_lblTrnsfrList")
    );
  });
  test("Custom Test Cases for lblTrnsfrList", () => {
    // START_USER_CODE-USER_lblTrnsfrList_TEST
    // END_USER_CODE-USER_lblTrnsfrList_TEST
  });
  test("textboxwidget89(Textbox Widget) Test Cases", async () => {
    const textboxwidget89 = screen.getByTestId("textboxwidget89");
    expect(textboxwidget89.tagName).toBe("INPUT");
    expect(textboxwidget89.type).toBe("text");
    expect(textboxwidget89.classList).toContain("textboxWidgetClass");
    expect(textboxwidget89.previousElementSibling.textContent).toEqual(
      t("StockTransfer:TransferSearch_textboxwidget89")
    );
    await act(async () => {
      userEvent.type(textboxwidget89, "1");
    });
  });
  test("Custom Test Cases for textboxwidget89", () => {
    // START_USER_CODE-USER_textboxwidget89_TEST
    // END_USER_CODE-USER_textboxwidget89_TEST
  });
  test("txtCarrAbbrv(Textbox Widget) Test Cases", async () => {
    const txtCarrAbbrv = screen.getByTestId("txtCarrAbbrv");
    expect(txtCarrAbbrv.tagName).toBe("INPUT");
    expect(txtCarrAbbrv.type).toBe("text");
    expect(txtCarrAbbrv.classList).toContain("textboxWidgetClass");
    expect(txtCarrAbbrv.previousElementSibling.textContent).toEqual(
      t("StockTransfer:TransferSearch_txtCarrAbbrv")
    );
    await act(async () => {
      userEvent.type(txtCarrAbbrv, "1");
    });
  });
  test("Custom Test Cases for txtCarrAbbrv", () => {
    // START_USER_CODE-USER_txtCarrAbbrv_TEST
    // END_USER_CODE-USER_txtCarrAbbrv_TEST
  });
  test("gridTrnsfrSrch_txtcolCarrId(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolCarrId = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolCarrIdbtn =
      gridTrnsfrSrch_txtcolCarrId.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolCarrId =
      gridTrnsfrSrch_txtcolCarrId.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolCarrId.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolCarrId.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCarrId", () => {
    // START_USER_CODE-USER_txtcolCarrId_TEST
    // END_USER_CODE-USER_txtcolCarrId_TEST
  });
  test("gridTrnsfrSrch_txtcolFrmGen(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolFrmGen = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolFrmGenbtn =
      gridTrnsfrSrch_txtcolFrmGen.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolFrmGen =
      gridTrnsfrSrch_txtcolFrmGen.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolFrmGen.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolFrmGen.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFrmGen", () => {
    // START_USER_CODE-USER_txtcolFrmGen_TEST
    // END_USER_CODE-USER_txtcolFrmGen_TEST
  });
  test("gridTrnsfrSrch_txtcolFrmOlec(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolFrmOlec = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolFrmOlecbtn =
      gridTrnsfrSrch_txtcolFrmOlec.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolFrmOlec =
      gridTrnsfrSrch_txtcolFrmOlec.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolFrmOlec.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolFrmOlec.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFrmOlec", () => {
    // START_USER_CODE-USER_txtcolFrmOlec_TEST
    // END_USER_CODE-USER_txtcolFrmOlec_TEST
  });
  test("gridTrnsfrSrch_txtcolFrmOrgnc(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolFrmOrgnc = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolFrmOrgncbtn =
      gridTrnsfrSrch_txtcolFrmOrgnc.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolFrmOrgnc =
      gridTrnsfrSrch_txtcolFrmOrgnc.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolFrmOrgnc.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolFrmOrgnc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFrmOrgnc", () => {
    // START_USER_CODE-USER_txtcolFrmOrgnc_TEST
    // END_USER_CODE-USER_txtcolFrmOrgnc_TEST
  });
  test("gridTrnsfrSrch_txtcolFrmVar(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolFrmVar = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolFrmVarbtn =
      gridTrnsfrSrch_txtcolFrmVar.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolFrmVar =
      gridTrnsfrSrch_txtcolFrmVar.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolFrmVar.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolFrmVar.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFrmVar", () => {
    // START_USER_CODE-USER_txtcolFrmVar_TEST
    // END_USER_CODE-USER_txtcolFrmVar_TEST
  });
  test("gridTrnsfrSrch_txtcolFrmWhse(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolFrmWhse = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolFrmWhsebtn =
      gridTrnsfrSrch_txtcolFrmWhse.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolFrmWhse =
      gridTrnsfrSrch_txtcolFrmWhse.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolFrmWhse.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolFrmWhse.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFrmWhse", () => {
    // START_USER_CODE-USER_txtcolFrmWhse_TEST
    // END_USER_CODE-USER_txtcolFrmWhse_TEST
  });
  test("gridTrnsfrSrch_txtcolFrtAccrCmplt(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolFrtAccrCmplt = screen.getByTestId(
      "gridTrnsfrSrch"
    );
    let gridTrnsfrSrch_txtcolFrtAccrCmpltbtn =
      gridTrnsfrSrch_txtcolFrtAccrCmplt.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolFrtAccrCmplt =
      gridTrnsfrSrch_txtcolFrtAccrCmplt.parentElement.parentElement
        .parentElement;
    expect(gridTrnsfrSrch_txtcolFrtAccrCmplt.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolFrtAccrCmplt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFrtAccrCmplt", () => {
    // START_USER_CODE-USER_txtcolFrtAccrCmplt_TEST
    // END_USER_CODE-USER_txtcolFrtAccrCmplt_TEST
  });
  test("gridTrnsfrSrch_txtcolNtWt(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolNtWt = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolNtWtbtn =
      gridTrnsfrSrch_txtcolNtWt.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolNtWt =
      gridTrnsfrSrch_txtcolNtWt.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolNtWt.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolNtWt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNtWt", () => {
    // START_USER_CODE-USER_txtcolNtWt_TEST
    // END_USER_CODE-USER_txtcolNtWt_TEST
  });
  test("gridTrnsfrSrch_txtcolPayFrgt(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolPayFrgt = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolPayFrgtbtn =
      gridTrnsfrSrch_txtcolPayFrgt.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolPayFrgt =
      gridTrnsfrSrch_txtcolPayFrgt.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolPayFrgt.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolPayFrgt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPayFrgt", () => {
    // START_USER_CODE-USER_txtcolPayFrgt_TEST
    // END_USER_CODE-USER_txtcolPayFrgt_TEST
  });
  test("gridTrnsfrSrch_txtcolRcvLoc(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolRcvLoc = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolRcvLocbtn =
      gridTrnsfrSrch_txtcolRcvLoc.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolRcvLoc =
      gridTrnsfrSrch_txtcolRcvLoc.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolRcvLoc.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolRcvLoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRcvLoc", () => {
    // START_USER_CODE-USER_txtcolRcvLoc_TEST
    // END_USER_CODE-USER_txtcolRcvLoc_TEST
  });
  test("gridTrnsfrSrch_txtcolRcvWt(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolRcvWt = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolRcvWtbtn =
      gridTrnsfrSrch_txtcolRcvWt.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolRcvWt =
      gridTrnsfrSrch_txtcolRcvWt.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolRcvWt.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolRcvWt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRcvWt", () => {
    // START_USER_CODE-USER_txtcolRcvWt_TEST
    // END_USER_CODE-USER_txtcolRcvWt_TEST
  });
  test("gridTrnsfrSrch_txtcolShppngDate(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolShppngDate = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolShppngDatebtn =
      gridTrnsfrSrch_txtcolShppngDate.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolShppngDate =
      gridTrnsfrSrch_txtcolShppngDate.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolShppngDate.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolShppngDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShppngDate", () => {
    // START_USER_CODE-USER_txtcolShppngDate_TEST
    // END_USER_CODE-USER_txtcolShppngDate_TEST
  });
  test("gridTrnsfrSrch_txtcolShppngWt(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolShppngWt = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolShppngWtbtn =
      gridTrnsfrSrch_txtcolShppngWt.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolShppngWt =
      gridTrnsfrSrch_txtcolShppngWt.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolShppngWt.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolShppngWt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShppngWt", () => {
    // START_USER_CODE-USER_txtcolShppngWt_TEST
    // END_USER_CODE-USER_txtcolShppngWt_TEST
  });
  test("gridTrnsfrSrch_txtcolSts(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolSts = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolStsbtn =
      gridTrnsfrSrch_txtcolSts.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolSts =
      gridTrnsfrSrch_txtcolSts.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolSts.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolSts.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSts", () => {
    // START_USER_CODE-USER_txtcolSts_TEST
    // END_USER_CODE-USER_txtcolSts_TEST
  });
  test("gridTrnsfrSrch_txtcolToGen(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolToGen = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolToGenbtn =
      gridTrnsfrSrch_txtcolToGen.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolToGen =
      gridTrnsfrSrch_txtcolToGen.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolToGen.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolToGen.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolToGen", () => {
    // START_USER_CODE-USER_txtcolToGen_TEST
    // END_USER_CODE-USER_txtcolToGen_TEST
  });
  test("gridTrnsfrSrch_txtcolToOleic(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolToOleic = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolToOleicbtn =
      gridTrnsfrSrch_txtcolToOleic.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolToOleic =
      gridTrnsfrSrch_txtcolToOleic.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolToOleic.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolToOleic.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolToOleic", () => {
    // START_USER_CODE-USER_txtcolToOleic_TEST
    // END_USER_CODE-USER_txtcolToOleic_TEST
  });
  test("gridTrnsfrSrch_txtcolToOrgnc(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolToOrgnc = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolToOrgncbtn =
      gridTrnsfrSrch_txtcolToOrgnc.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolToOrgnc =
      gridTrnsfrSrch_txtcolToOrgnc.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolToOrgnc.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolToOrgnc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolToOrgnc", () => {
    // START_USER_CODE-USER_txtcolToOrgnc_TEST
    // END_USER_CODE-USER_txtcolToOrgnc_TEST
  });
  test("gridTrnsfrSrch_txtcolToVar(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolToVar = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolToVarbtn =
      gridTrnsfrSrch_txtcolToVar.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolToVar =
      gridTrnsfrSrch_txtcolToVar.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolToVar.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolToVar.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolToVar", () => {
    // START_USER_CODE-USER_txtcolToVar_TEST
    // END_USER_CODE-USER_txtcolToVar_TEST
  });
  test("gridTrnsfrSrch_txtcolToWhse(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolToWhse = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolToWhsebtn =
      gridTrnsfrSrch_txtcolToWhse.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolToWhse =
      gridTrnsfrSrch_txtcolToWhse.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolToWhse.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolToWhse.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolToWhse", () => {
    // START_USER_CODE-USER_txtcolToWhse_TEST
    // END_USER_CODE-USER_txtcolToWhse_TEST
  });
  test("gridTrnsfrSrch_txtcolTrnsfr(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolTrnsfr = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolTrnsfrbtn =
      gridTrnsfrSrch_txtcolTrnsfr.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolTrnsfr =
      gridTrnsfrSrch_txtcolTrnsfr.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolTrnsfr.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolTrnsfr.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTrnsfr", () => {
    // START_USER_CODE-USER_txtcolTrnsfr_TEST
    // END_USER_CODE-USER_txtcolTrnsfr_TEST
  });
  test("gridTrnsfrSrch_txtcolType(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolType = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolTypebtn =
      gridTrnsfrSrch_txtcolType.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolType =
      gridTrnsfrSrch_txtcolType.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolType.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType", () => {
    // START_USER_CODE-USER_txtcolType_TEST
    // END_USER_CODE-USER_txtcolType_TEST
  });
  test("gridTrnsfrSrch_txtcolUpdtInvWt(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolUpdtInvWt = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolUpdtInvWtbtn =
      gridTrnsfrSrch_txtcolUpdtInvWt.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolUpdtInvWt =
      gridTrnsfrSrch_txtcolUpdtInvWt.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolUpdtInvWt.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolUpdtInvWt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUpdtInvWt", () => {
    // START_USER_CODE-USER_txtcolUpdtInvWt_TEST
    // END_USER_CODE-USER_txtcolUpdtInvWt_TEST
  });
  test("gridTrnsfrSrch_txtcolWtVarnc(Grid Widget) Test Cases", async () => {
    let gridTrnsfrSrch_txtcolWtVarnc = screen.getByTestId("gridTrnsfrSrch");
    let gridTrnsfrSrch_txtcolWtVarncbtn =
      gridTrnsfrSrch_txtcolWtVarnc.nextElementSibling.firstElementChild;
    gridTrnsfrSrch_txtcolWtVarnc =
      gridTrnsfrSrch_txtcolWtVarnc.parentElement.parentElement.parentElement;
    expect(gridTrnsfrSrch_txtcolWtVarnc.tagName).toBe("DIV");
    expect(gridTrnsfrSrch_txtcolWtVarnc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:TransferSearch_gridTrnsfrSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWtVarnc", () => {
    // START_USER_CODE-USER_txtcolWtVarnc_TEST
    // END_USER_CODE-USER_txtcolWtVarnc_TEST
  });
  test("txtNetWt(Textbox Widget) Test Cases", async () => {
    const txtNetWt = screen.getByTestId("txtNetWt");
    expect(txtNetWt.tagName).toBe("INPUT");
    expect(txtNetWt.type).toBe("text");
    expect(txtNetWt.classList).toContain("textboxWidgetClass");
    expect(txtNetWt.previousElementSibling.textContent).toEqual(
      t("StockTransfer:TransferSearch_txtNetWt")
    );
    await act(async () => {
      userEvent.type(txtNetWt, "1");
    });
  });
  test("Custom Test Cases for txtNetWt", () => {
    // START_USER_CODE-USER_txtNetWt_TEST
    // END_USER_CODE-USER_txtNetWt_TEST
  });
  test("txtRcvWt(Textbox Widget) Test Cases", async () => {
    const txtRcvWt = screen.getByTestId("txtRcvWt");
    expect(txtRcvWt.tagName).toBe("INPUT");
    expect(txtRcvWt.type).toBe("text");
    expect(txtRcvWt.classList).toContain("textboxWidgetClass");
    expect(txtRcvWt.previousElementSibling.textContent).toEqual(
      t("StockTransfer:TransferSearch_txtRcvWt")
    );
    await act(async () => {
      userEvent.type(txtRcvWt, "1");
    });
  });
  test("Custom Test Cases for txtRcvWt", () => {
    // START_USER_CODE-USER_txtRcvWt_TEST
    // END_USER_CODE-USER_txtRcvWt_TEST
  });
  test("txtShippdWt(Textbox Widget) Test Cases", async () => {
    const txtShippdWt = screen.getByTestId("txtShippdWt");
    expect(txtShippdWt.tagName).toBe("INPUT");
    expect(txtShippdWt.type).toBe("text");
    expect(txtShippdWt.classList).toContain("textboxWidgetClass");
    expect(txtShippdWt.previousElementSibling.textContent).toEqual(
      t("StockTransfer:TransferSearch_txtShippdWt")
    );
    await act(async () => {
      userEvent.type(txtShippdWt, "1");
    });
  });
  test("Custom Test Cases for txtShippdWt", () => {
    // START_USER_CODE-USER_txtShippdWt_TEST
    // END_USER_CODE-USER_txtShippdWt_TEST
  });
  test("txtTrnsfr(Textbox Widget) Test Cases", async () => {
    const txtTrnsfr = screen.getByTestId("txtTrnsfr");
    expect(txtTrnsfr.tagName).toBe("INPUT");
    expect(txtTrnsfr.type).toBe("text");
    expect(txtTrnsfr.classList).toContain("textboxWidgetClass");
    expect(txtTrnsfr.previousElementSibling.textContent).toEqual(
      t("StockTransfer:TransferSearch_txtTrnsfr")
    );
    await act(async () => {
      userEvent.type(txtTrnsfr, "1");
    });
  });
  test("Custom Test Cases for txtTrnsfr", () => {
    // START_USER_CODE-USER_txtTrnsfr_TEST
    // END_USER_CODE-USER_txtTrnsfr_TEST
  });
  test("txtUpdtInvWt(Textbox Widget) Test Cases", async () => {
    const txtUpdtInvWt = screen.getByTestId("txtUpdtInvWt");
    expect(txtUpdtInvWt.tagName).toBe("INPUT");
    expect(txtUpdtInvWt.type).toBe("text");
    expect(txtUpdtInvWt.classList).toContain("textboxWidgetClass");
    expect(txtUpdtInvWt.previousElementSibling.textContent).toEqual(
      t("StockTransfer:TransferSearch_txtUpdtInvWt")
    );
    await act(async () => {
      userEvent.type(txtUpdtInvWt, "1");
    });
  });
  test("Custom Test Cases for txtUpdtInvWt", () => {
    // START_USER_CODE-USER_txtUpdtInvWt_TEST
    // END_USER_CODE-USER_txtUpdtInvWt_TEST
  });
  test("txtVndr(Textbox Widget) Test Cases", async () => {
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    expect(txtVndr.previousElementSibling.textContent).toEqual(
      t("StockTransfer:TransferSearch_txtVndr")
    );
    await act(async () => {
      userEvent.type(txtVndr, "1");
    });
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
});
