/* eslint-disable*/
import { ApiService } from "../../Common/ApiService";
export const SettlementService = {
  RetrieveTradeAgreementDetails,
  RetrieveTradeSettlementPremiumDeductionDetails,
  RetrieveDeductionTrackingDetail,
  RetrieveTradeSettlementAvailAgreementDetails,
  RetrieveTradeSettlementList,
  RetrieveTradeAgreementApplicationDetails,
  UpdateTradeAgreement,
  RetrieveTradeSettlementPaymentDetails,
  RetrievePeanutSpecificationExceptionDetails,
  RetrievePeanutSpecificationDetails,
  RetrieveTradeSettlementDetails,
  RetrieveTradeSettlementApplicationDetails,
  RetrieveTradeCategoryDetails,
  RetrieveWhouseBinInventoryDetails,
  RetrieveAreaControlDetails,
  RetrieveBuyingPointControlDetails,
  RetrieveValueCalculationList,
  RetrieveValueCalculationDetails,
  UpdateTradeSettlement,
  UpdateTradeAgreementIdt,
  UpdateTradeSettlementComplete,
  UpdateTradeSettlementReverseDetails,
  UpdateTradeSettlementUnvoidDetails,
  UpdateTradeSettlementVoidDetails,
  RetrieveCheckIdtExistList,
  CreateContractGrantDetails,
  RetrieveTransferBuyingPointDetails,
  RetrieveInspectCorrectionLogDetails,
  RetrieveInspectionHeaderList,
  UpdateVoidNosellInspectionDetails,
  RetrieveVoidInspectionDetails,
  UpdateMarkVoidTableDetails,
  RetrieveContractDetails,
  RetrieveInspectUploadGradeDetails,
  RetrieveInspectionHeaderDetails,
  RemoveInspection,
  UpdateSettlementVoidDetails,
  UpdateSettlementCorrectionDetails,
  UpdateSettlementSupersedeDetails,
  UpdateUploadResubmitDetails,
  UpdateUploadHiddenIndicatorDetails,
  RetrieveKCMODataDetails,
  UpdateChangeInspectionNumber,
  UpdateResidueInspectionDetails,
  RetrieveInspectHeaderResidueDetails,
  UpdateConvertHighMoistureToCGrade,
  RetrieveCuringCleaningCostsDetails,
  RetrieveCureCleanScheduleDetails,
  UpdateSettlementSignDetails,
  UpdateUnSignSettlement,
  CreatePremiumDeductionDetails,
  RetrievePremiumDeductionScheduleList,
  CreateInspection,
  UpdateInspection,
  RetrieveTradePremiumDeductionScheduleDetails,
  RetrieveSpotPriceControlList,
  RetrieveContractGrantList,
  RetrieveCheckWareHouseStatusDetails,
  RetrieveCheckWareHouserInIvsDetails,
  updateFree1007Flag,
  UpdateSettlementInspection,
  RetrieveFedWareHouseLicenseNameDetails,
  RetrieveInspectHeaderPrint1007Details,
  RetrieveInspectionHeaderVoidReprintList,
  CreateInspectCorrectionLogDetails,
  UpdateScaleTicketUploadStatus,
  RetrieveInspectHeaderList,
  RetrieveInspectHeaderOneDocumentDetail,
  RetrieveWhouseBinInventoryDetailsForTradeSettleProfile,
  RetrieveInspectHeaderOneDocumentDetails,
  RetrieveCheck1007OneDocumentDetails,
  RetrieveWeigherControlList,
  RetrieveLoanRePaymentControlDetails,
  RetrieveVoidReasonDetails,
};

const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
const compIdFromLS = () => (sessionStorage.getItem('compId'));
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
const useridFromLS = () => (sessionStorage.getItem('userid') || '');

const settlement_url_api = '/settlement/v1/';

export function CreateContractGrantDetails(user_id, buy_pt_id, insp_num) {
  return ApiService.postData(isLocal() ? `/Settlement/CreateContractGrantDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${user_id}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/contractgrants` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${user_id}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/contractgrants`);
}


export function RetrieveTradeAgreementDetails(func_id, func_sub_id, buy_pt_id, agree_num, agree_type, agree_status, pnut_type_id, pnut_variety_id, seed_gen, seg_type, oleic_ind, edible_oil, delv_ind, split_vendor) {
  let optionalParamsUrl = '';
  if (func_sub_id !== null && func_sub_id !== undefined && func_sub_id != "") {
    optionalParamsUrl += `func_sub_id=${func_sub_id}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (agree_num !== null && agree_num !== undefined && agree_num !== "") {
    optionalParamsUrl += `agree_num=${agree_num}&`
  }
  if (agree_type !== null && agree_type !== undefined && agree_type !== "") {
    optionalParamsUrl += `agree_type=${agree_type}&`
  }
  if (agree_status !== null && agree_status !== undefined && agree_status !== "") {
    optionalParamsUrl += `agree_status=${agree_status}&`
  }
  if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id !== "") {
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
  }
  if (pnut_variety_id !== null && pnut_variety_id !== undefined && pnut_variety_id !== "") {
    optionalParamsUrl += `pnut_variety_id=${pnut_variety_id}&`
  }
  if (seed_gen !== null && seed_gen !== undefined && seed_gen !== "") {
    optionalParamsUrl += `seed_gen=${seed_gen}&`
  }
  if (seg_type !== null && seg_type !== undefined && seg_type !== "") {
    optionalParamsUrl += `seg_type=${seg_type}&`
  }
  if (oleic_ind !== null && oleic_ind !== undefined && oleic_ind !== "") {
    optionalParamsUrl += `oleic_ind=${oleic_ind}&`
  }
  if (edible_oil !== null && edible_oil !== undefined && edible_oil !== "") {
    optionalParamsUrl += `edible_oil=${edible_oil}&`
  }
  if (split_vendor !== null && split_vendor !== undefined && split_vendor !== "") {
    optionalParamsUrl += `split_vendor=${split_vendor}&`
  }
  if (delv_ind !== null && delv_ind !== undefined && delv_ind !== "") {
    optionalParamsUrl += `delv_ind=${delv_ind}&`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveTradeAgreementDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/agreements?${optionalParamsUrl}` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/agreements?${optionalParamsUrl}`);
}

export function RetrieveTradeSettlementAvailAgreementDetails(buy_pt_id, trade_insp_num, pnut_type_id, pnut_variety_id, seg_type, grade_pricing_method, purch_sale_ind, delv_ind) {
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveTradeSettlementAvailAgreementDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspections/${trade_insp_num}/peanut-types/${pnut_type_id}/peanut-varieties/${pnut_variety_id}/segment-types/${seg_type}/pricings/${grade_pricing_method}/purches-sales/${purch_sale_ind}/indicators/${delv_ind}/settlements` :
    `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspections/${trade_insp_num}/peanut-types/${pnut_type_id}/peanut-varieties/${pnut_variety_id}/segment-types/${seg_type}/pricings/${grade_pricing_method}/purches-sales/${purch_sale_ind}/indicators/${delv_ind}/settlements`)
}

export function RetrieveTradeSettlementPremiumDeductionDetails(buy_point_id, trade_insp_num) {
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveTradeSettlementPremiumDeductionDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_point_id}/trade-inspections/${trade_insp_num}/deductions` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_point_id}/trade-inspections/${trade_insp_num}/deductions`);
}

export function RetrieveDeductionTrackingDetail(buy_point_id, source_type, id_number_1, id_number_2) {
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveDeductionTrackingDetail/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_point_id}/sources/${source_type}/charge-inspections/${id_number_1}/collect-inspections/${id_number_2}/deductions` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_point_id}/sources/${source_type}/charge-inspections/${id_number_1}/collect-inspections/${id_number_2}/deductions`);
}
export function RetrieveTradeSettlementList(func_id, func_sub_id, buy_pt_id, trade_insp_num, trade_settle_num, trade_status, purch_sale_ind, pnut_type_id, pnut_variety_id, seed_gen, seg_type, oleic_ind, delv_ind, grade_pricing_method, edible_oil_ind, agree_num, split_vendor) {
  let optionalParamsUrl = '';
  if (func_sub_id !== null && func_sub_id !== undefined && func_sub_id != "") {
    optionalParamsUrl += `func_sub_id=${func_sub_id}&`
  }

  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (trade_insp_num !== null && trade_insp_num !== undefined && trade_insp_num !== "") {
    optionalParamsUrl += `trade_insp_num=${trade_insp_num}&`
  }
  if (trade_settle_num !== null && trade_settle_num !== undefined && trade_settle_num !== "") {
    optionalParamsUrl += `trade_settle_num=${trade_settle_num}&`
  }
  if (trade_status !== null && trade_status !== undefined && trade_status !== "") {
    optionalParamsUrl += `trade_status=${trade_status}&`
  }
  if (purch_sale_ind !== null && purch_sale_ind !== undefined && purch_sale_ind !== "") {
    optionalParamsUrl += `purch_sale_ind=${purch_sale_ind}&`
  }
  if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id !== "") {
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
  }
  if (pnut_variety_id !== null && pnut_variety_id !== undefined && pnut_variety_id !== "") {
    optionalParamsUrl += `pnut_variety_id=${pnut_variety_id}&`
  }
  if (seed_gen !== null && seed_gen !== undefined && seed_gen !== "") {
    optionalParamsUrl += `seed_gen=${seed_gen}&`
  }
  if (seg_type !== null && seg_type !== undefined && seg_type !== "") {
    optionalParamsUrl += `seg_type=${seg_type}&`
  }
  if (oleic_ind !== null && oleic_ind !== undefined && oleic_ind !== "") {
    optionalParamsUrl += `oleic_ind=${oleic_ind}&`
  }
  if (edible_oil_ind !== null && edible_oil_ind !== undefined && edible_oil_ind !== "") {
    optionalParamsUrl += `edible_oil_ind=${edible_oil_ind}&`
  }
  if (split_vendor !== null && split_vendor !== undefined && split_vendor !== "") {
    optionalParamsUrl += `split_vendor=${split_vendor}&`
  }
  if (delv_ind !== null && delv_ind !== undefined && delv_ind !== "") {
    optionalParamsUrl += `delv_ind=${delv_ind}&`
  }
  if (grade_pricing_method !== null && grade_pricing_method !== undefined && grade_pricing_method !== "") {
    optionalParamsUrl += `grade_pricing_method=${grade_pricing_method}&`
  }
  if (agree_num !== null && agree_num !== undefined && agree_num !== "") {
    optionalParamsUrl += `agree_num=${agree_num}&`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveTradeSettlementList/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/function/${func_id}/users/${useridFromLS()}/settlements?${optionalParamsUrl}` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/function/${func_id}/users/${useridFromLS()}/settlements?${optionalParamsUrl}`);
}
export function RetrieveTradeAgreementApplicationDetails(agree_num, delv_ind, buy_pt_id) {
  let optionalParamsUrl = '';

  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveTradeAgreementApplicationDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/agreements/${agree_num}/delivery-indicators/${delv_ind}/applications?${optionalParamsUrl}` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/agreements/${agree_num}/delivery-indicators/${delv_ind}/applications?${optionalParamsUrl}`);
}
export function UpdateTradeAgreement(modify_tag, agreement_num, delv_ind, objSave) {
  let optionalParamsUrl = '';

  if (agreement_num !== null && agreement_num !== undefined && agreement_num !== "") {
    optionalParamsUrl += `agreement_num=${agreement_num}&`
  }
  if (delv_ind !== null && delv_ind !== undefined && delv_ind !== "") {
    optionalParamsUrl += `delv_ind=${delv_ind}
    `
  }
  return ApiService.updateData(isLocal() ? `/Settlement/UpdateTradeAgreement/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/tags/${modify_tag}/agreements?${optionalParamsUrl}` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/tags/${modify_tag}/agreements?${optionalParamsUrl}`, objSave);
}

export function RetrieveTradeSettlementPaymentDetails(buy_pt_id, audit_trade_insp, audit_delv_agree_num) {
  let optionalParamsUrl = '';

  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (audit_trade_insp !== null && audit_trade_insp !== undefined && audit_trade_insp != "") {
    optionalParamsUrl += `audit_trade_insp=${audit_trade_insp}&`
  }
  if (audit_delv_agree_num !== null && audit_delv_agree_num !== undefined && audit_delv_agree_num != "") {
    optionalParamsUrl += `audit_delv_agree_num=${audit_delv_agree_num}&`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveTradeSettlementPaymentDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/payments?${optionalParamsUrl}` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/payments?${optionalParamsUrl}`);
}



// PEANUT_SPEC_EXCEPTION_LIST 
export function RetrievePeanutSpecificationExceptionDetails(buy_pt_id, pnut_type_id, pnut_variety_id, seed_ind, seg_type, edible_oil_ind) {
  let optionalParameters = ``
  if (pnut_type_id != null && pnut_type_id != undefined && pnut_type_id != '') {
    optionalParameters += `&pnut_type_id=${pnut_type_id}`
  }
  if (pnut_variety_id != null && pnut_variety_id != undefined && pnut_variety_id != '') {
    optionalParameters += `&pnut_variety_id=${pnut_variety_id}`
  }
  if (seed_ind != null && seed_ind != undefined && seed_ind != '') {
    optionalParameters += `&seed_ind=${seed_ind}`
  }
  if (seg_type != null && seg_type != undefined && seg_type != '') {
    optionalParameters += `&seg_type=${seg_type}`
  }
  if (edible_oil_ind != null && edible_oil_ind != undefined && edible_oil_ind != '') {
    optionalParameters += `&edible_oil_ind=${edible_oil_ind}`
  }

  return ApiService.getData(
    isLocal()
      ? `/Settlement/RetrievePeanutSpecificationExceptionDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/exceptions?buy_pt_id=${buy_pt_id}${optionalParameters}`
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/exceptions?buy_pt_id=${buy_pt_id}${optionalParameters}`
  )
}
// PEANUT_SPECIFICATION_LIST 
export function RetrievePeanutSpecificationDetails(area_id, pnut_type_id, seed_ind, seg_type, edible_oil_ind) {
  let optionalParameters = ``
  if (area_id != null && area_id != undefined && area_id != '') {
    optionalParameters += `&area_id=${area_id}`
  }
  if (pnut_type_id != null && pnut_type_id != undefined && pnut_type_id != '') {
    optionalParameters += `&pnut_type_id=${pnut_type_id}`
  }
  if (seed_ind != null && seed_ind != undefined && seed_ind != '') {
    optionalParameters += `&seed_ind=${seed_ind}`
  }
  if (seg_type != null && seg_type != undefined && seg_type != '') {
    optionalParameters += `&seg_type=${seg_type}`
  }
  if (edible_oil_ind != null && edible_oil_ind != undefined && edible_oil_ind != '') {
    optionalParameters += `&edible_oil_ind=${edible_oil_ind}`
  }

  return ApiService.getData(
    isLocal()
      ? `/Settlement/RetrievePeanutSpecificationDetails/specifications?comp_id=${compIdFromLS()}&crop_year=${cropYearFromLS()}${optionalParameters}`
      : `${settlement_url_api}specifications?comp_id=${compIdFromLS()}&crop_year=${cropYearFromLS()}${optionalParameters}`
  )
}
// TRADE_SETTLE_GET 
export function RetrieveTradeSettlementDetails(buy_pt_id, trade_insp_num, getvendorinfo, action) {
  let optionalParameters = ``
  if (getvendorinfo != null && getvendorinfo != undefined && getvendorinfo != "") {
    optionalParameters += `getvendorinfo=${getvendorinfo}&`
  }
  if (action != null && action != undefined && action != "") {
    optionalParameters += `action=${action}`
  }

  return ApiService.getData(
    isLocal()
      ? `/Settlement/RetrieveTradeSettlementDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/trade-inpects/${trade_insp_num}/settlements?${optionalParameters}`
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/trade-inpects/${trade_insp_num}/settlements?` + optionalParameters
  )
}
// TRADE_SETTLE_APPLICATION_LIST 
export function RetrieveTradeSettlementApplicationDetails(trade_insp_num, delv_ind) {
  return ApiService.getData(
    isLocal()
      ? `/Settlement/RetrieveTradeSettlementApplicationDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/trade-inspections/${trade_insp_num}/indicators/${delv_ind}/applications`
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/trade-inspections/${trade_insp_num}/indicators/${delv_ind}/applications`
  )
}
// TRADE_CATEGORY_LIST 
export function RetrieveTradeCategoryDetails(trade_category, purch_sale_ind) {
  let optionalParameters = ``
  if (trade_category != null && trade_category != undefined && trade_category !== "") {
    optionalParameters += `trade_category=${trade_category}&`
  }
  if (purch_sale_ind != null && purch_sale_ind != undefined && purch_sale_ind !== "") {
    optionalParameters += `purch_sale_ind=${purch_sale_ind}`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveTradeCategoryDetails/categories?${optionalParameters}` : `${settlement_url_api}categories?${optionalParameters}`)
}
// WHOUSE_BIN_INVENTORY_LIST *
export function RetrieveWhouseBinInventoryDetails(buy_pt_id, whouse_num, bin_num, seed_gen, organic_ind) {
  let optionalParameters = ``
  if (whouse_num != null && whouse_num != undefined && whouse_num != "") {
    optionalParameters += `whouse_num=${whouse_num}&`
  }
  if (bin_num != null && bin_num != undefined && bin_num != "") {
    optionalParameters += `bin_num=${bin_num}&`
  }
  if (seed_gen != null && seed_gen != undefined && seed_gen != "") {
    optionalParameters += `seed_gen=${seed_gen}&`
  }
  if (organic_ind != null && organic_ind != undefined && organic_ind != "") {
    optionalParameters += `organic_ind=${organic_ind}&`
  }
  return ApiService.getData(
    isLocal()

      ? `/Settlement/RetrieveWhouseBinInventoryDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inventories?${optionalParameters}`
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inventories?` + optionalParameters
  )
}
// AREA_CONTROL_LIST 
export function RetrieveAreaControlDetails(area_id) {
  let optionalParameters = ``
  if (compIdFromLS() != null && compIdFromLS() != undefined && compIdFromLS() != "")
    optionalParameters += `comp_id=${compIdFromLS()}&`
  if (cropYearFromLS() != null && cropYearFromLS() != undefined && cropYearFromLS() != "")
    optionalParameters += `crop_year=${cropYearFromLS()}&`
  if (area_id != null && area_id != undefined && area_id != "") 
    optionalParameters += `area_id=${area_id}`  
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveAreaControlDetails/areas?` + optionalParameters : `${settlement_url_api}areas?` + optionalParameters )
}
// BUYING_POINT_CONTROL_LIST *
export function RetrieveBuyingPointControlDetails(func_id, func_sub_id, area_id, coll_pt_id, buy_pt_id) {
  let optionalParameters = ``
  if (func_sub_id != null && func_sub_id != undefined && func_sub_id != "") {
    optionalParameters += `func_sub_id=${func_sub_id}&`
  }
  if (area_id != null && area_id != undefined && area_id != "") {
    optionalParameters += `area_id=${area_id}&`
  }
  if (coll_pt_id != null && coll_pt_id != undefined && coll_pt_id != "") {
    optionalParameters += `coll_pt_id=${coll_pt_id}&`
  }
  if (buy_pt_id != null && buy_pt_id != undefined && buy_pt_id != "") {
    optionalParameters += `buy_pt_id=${buy_pt_id}&`
  }
  return ApiService.getData(
    isLocal()
      ? `/Settlement/RetrieveBuyingPointControlDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/locations?` + optionalParameters
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/locations?` + optionalParameters
  )
}

// VALUE_CALCULATION
export function RetrieveValueCalculationList(area_id, pnut_type_id, cr_br_pct, dcol_shel_pct, fan_pct, dam_pct, ss_pct, vicam_pct, fm_pct, smk, sspct, okpct, elkpct, lskpct, moisturepct, weightincludingvehicle, weightofvehicle, foreignmaterialpct) {
  let optionalParameters = ``
  if (cr_br_pct != null && cr_br_pct != undefined && cr_br_pct != '' && !isNaN(cr_br_pct)) {
    optionalParameters += `cr_br_pct=${cr_br_pct}&`
  }
  if (dcol_shel_pct != null && dcol_shel_pct != undefined && dcol_shel_pct != '' && !isNaN(dcol_shel_pct)) {
    optionalParameters += `dcol_shel_pct=${dcol_shel_pct}&`
  }
  if (fan_pct != null && fan_pct != undefined && fan_pct != '' && !isNaN(fan_pct)) {
    optionalParameters += `fan_pct=${fan_pct}&`
  }
  if (dam_pct != null && dam_pct != undefined && dam_pct != '' && !isNaN(dam_pct)) {
    optionalParameters += `dam_pct=${dam_pct}&`
  }
  if (ss_pct != null && ss_pct != undefined && ss_pct != '' && !isNaN(ss_pct)) {
    optionalParameters += `ss_pct=${ss_pct}&`
  }
  if (vicam_pct != null && vicam_pct != undefined && vicam_pct != '' && !isNaN(vicam_pct)) {
    optionalParameters += `vicam_pct=${vicam_pct}&`
  }
  if (fm_pct != null && fm_pct != undefined && fm_pct != '' && !isNaN(fm_pct)) {
    optionalParameters += `fm_pct=${fm_pct}&`
  }
  if (smk != null && smk != undefined && smk != '' && !isNaN(smk)) {
    optionalParameters += `smk=${smk}&`
  }
  if (sspct != null && sspct != undefined && sspct != '' && !isNaN(sspct)) {
    optionalParameters += `sspct=${sspct}&`
  }
  if (okpct != null && okpct != undefined && okpct != '' && !isNaN(okpct)) {
    optionalParameters += `okpct=${okpct}&`
  }
  if (elkpct != null && elkpct != undefined && elkpct != '' && !isNaN(elkpct)) {
    optionalParameters += `elkpct=${elkpct}&`
  }
  if (lskpct != null && lskpct != undefined && lskpct != '' && !isNaN(lskpct)) {
    optionalParameters += `lskpct=${lskpct}&`
  }
  if (moisturepct != null && moisturepct != undefined && moisturepct != '' && !isNaN(moisturepct)) {
    optionalParameters += `moisturepct=${moisturepct}&`
  }
  if (weightincludingvehicle != null && weightincludingvehicle != undefined && weightincludingvehicle != '' && !isNaN(weightincludingvehicle)) {
    optionalParameters += `weightincludingvehicle=${weightincludingvehicle}&`
  }
  if (weightofvehicle != null && weightofvehicle != undefined && weightofvehicle != '' && !isNaN(weightofvehicle)) {
    optionalParameters += `weightofvehicle=${weightofvehicle}&`
  }
  if (foreignmaterialpct != null && foreignmaterialpct != undefined && foreignmaterialpct != '' && !isNaN(foreignmaterialpct)) {
    optionalParameters += `foreignmaterialpct=${foreignmaterialpct}&`
  }

  return ApiService.getData(
    isLocal()
      ? `/Settlement/RetrieveValueCalculationList/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/valuecalculations?` + optionalParameters
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/valuecalculations?` + optionalParameters
  )
}
// VALUE_CALCULATION2 
export function RetrieveValueCalculationDetails(area_id, pnut_type_id) {
  return ApiService.getData(
    isLocal()
      ? `/Settlement/RetrieveValueCalculationDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/calculations`
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/calculations`
  )
}
// TRADE_SETTLE_MODIFY 
export function UpdateTradeSettlement(com_id,modify_tag, buy_pt_id, trade_insp_num, dataObj) {
  return ApiService.updateData(
    isLocal()
      ? `/Settlement/UpdateTradeSettlement/companies/${com_id}/crop-years/${cropYearFromLS()}/tags/${modify_tag}/buying-points/${buy_pt_id}/inspections/${trade_insp_num}/settlements`
      : `${settlement_url_api}companies/${com_id}/crop-years/${cropYearFromLS()}/tags/${modify_tag}/buying-points/${buy_pt_id}/inspections/${trade_insp_num}/settlements`
    , dataObj
  )

}
// trade_agree_idt_save 
export function UpdateTradeAgreementIdt(new_comp_id, new_crop_year, agreement_num, modify_tag, dataObj) {
  return ApiService.updateData(
    isLocal()
      ? `/Settlement/UpdateTradeAgreementIdt/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/new-companies/${new_comp_id}/new-crop-years/${new_crop_year}/agreements/${agreement_num}/tags/${modify_tag}/agreementidts`
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/new-companies/${new_comp_id}/new-crop-years/${new_crop_year}/agreements/${agreement_num}/tags/${modify_tag}/agreementidts`
    , dataObj
  )
}
// TRADE_SETTLE_COMPLETE 
export function UpdateTradeSettlementComplete(buy_pt_id, trade_insp_num, trade_settle_num, cid, dataObj) {
  let optionalParameters = ''
  if (cid != null && cid !== undefined && cid !== "") {
    optionalParameters += `cid=${cid}&`
  }
  if (trade_settle_num != null && trade_settle_num !== undefined && trade_settle_num !== "") {
    optionalParameters += `trade_settle_num=${trade_settle_num}&`
  }
  return ApiService.updateData(
    isLocal()
      ? `/Settlement/UpdateTradeSettlementComplete/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspections/${trade_insp_num}/completes?` + optionalParameters
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspections/${trade_insp_num}/completes?` + optionalParameters
    , dataObj
  )
}
// TRADE_SETTLE_REVERSE  
export function UpdateTradeSettlementReverseDetails(buy_pt_id, trade_insp_num, cid,dataObj) {
  let optionalParameters = ''
  if (cid != null) {
    optionalParameters += `&cid=${cid}`
  }
  return ApiService.updateData(
    isLocal()
      ? `/Settlement/UpdateTradeSettlementReverseDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspections/${trade_insp_num}/reverses?` + optionalParameters
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspections/${trade_insp_num}/reverses?` + optionalParameters
    ,dataObj
  )
}
// TRADE_SETTLE_UNVOID 
export function UpdateTradeSettlementUnvoidDetails(buy_pt_id, trade_insp_num, cid, dataObj) {
  let optionalParameters = ''
  if (cid != null && cid !== undefined && cid !== "") {
    optionalParameters += `cid=${cid}&`
  }
  return ApiService.updateData(
    isLocal()
      ? `/Settlement/UpdateTradeSettlementUnvoidDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspections/${trade_insp_num}/unvoids?` + optionalParameters
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspections/${trade_insp_num}/unvoids?` + optionalParameters
    , dataObj
  )
}
// TRADE_SETTLE_VOID 
export function UpdateTradeSettlementVoidDetails(buy_pt_id, trade_insp_num, cid, dataObj) {
  let optionalParameters = ''
  if (cid != null && cid !== undefined && cid !== "") {
    optionalParameters += `cid=${cid}&`
  }
  return ApiService.updateData(
    isLocal()
      ? `/Settlement/UpdateTradeSettlementVoidDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspections/${trade_insp_num}/voids?` + optionalParameters
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspections/${trade_insp_num}/voids?` + optionalParameters
    , dataObj
  )
}

export function RetrieveCheckIdtExistList(action, trade_insp_num, agree_num, idt_comp_id, buy_pt_id) {
  let optionalParamsUrl = '';
  if (action !== null && action !== undefined && action !== "") {
    optionalParamsUrl += `action=${action}&`
  }
  if (trade_insp_num !== null && trade_insp_num !== undefined && trade_insp_num !== "") {
    optionalParamsUrl += `trade_insp_num=${trade_insp_num}&`
  }
  if (agree_num !== null && agree_num !== undefined && agree_num !== "") {
    optionalParamsUrl += `agree_num=${agree_num}&`
  }
  if (idt_comp_id !== null && idt_comp_id !== undefined && idt_comp_id !== "") {
    optionalParamsUrl += `idt_comp_id=${idt_comp_id}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }

  return ApiService.getData(isLocal() ? `/Settlement/RetrieveCheckIdtExistList/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/idtexists?${optionalParamsUrl}` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/idtexists?${optionalParamsUrl}`);
}

export function RetrieveTransferBuyingPointDetails(buy_point_id, xfer_buy_point_id) {
  let optionalParamsUrl = '';
  if (compIdFromLS !== null && compIdFromLS !== undefined && compIdFromLS !== "") {
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`
  }
  if (cropYearFromLS !== null && cropYearFromLS !== undefined && cropYearFromLS !== "") {
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`
  }
  if (buy_point_id !== null && buy_point_id !== undefined && buy_point_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_point_id}&`
  }
  if (xfer_buy_point_id !== null && xfer_buy_point_id !== undefined && xfer_buy_point_id !== "") {
    optionalParamsUrl += `xfer_buy_pt_id=${xfer_buy_point_id}&`
  }

  return ApiService.getData(isLocal() ? `/Settlement/RetrieveTransferBuyingPointDetails/transferbuyingpoints?${optionalParamsUrl}` : `${settlement_url_api}transferbuyingpoints?${optionalParamsUrl}`)
}
export function RetrieveInspectCorrectionLogDetails(buy_pt_id, insp_num) {
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveInspectCorrectionLogDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbres/${insp_num}/correctionlogs` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbres/${insp_num}/correctionlogs`);
}
export function RetrieveCureCleanScheduleDetails(buy_pt_id, cure_clean_ind, dry_green_ind, schedule_id) {
  let optionalParamsUrl = '';
  optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
  optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;

  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
  }
  if (cure_clean_ind !== null && cure_clean_ind !== undefined && cure_clean_ind !== "") {
    optionalParamsUrl += `cure_clean_ind=${cure_clean_ind}&`;
  }
  if (dry_green_ind !== null && dry_green_ind !== undefined && dry_green_ind !== "") {
    optionalParamsUrl += `dry_green_ind=${dry_green_ind}&`;
  }
  if (schedule_id !== null && schedule_id !== undefined && schedule_id !== "") {
    optionalParamsUrl += `schedule_id=${schedule_id}&`;
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveCureCleanScheduleDetails/cleanschedules?${optionalParamsUrl}` :`${settlement_url_api}cleanschedules?${optionalParamsUrl}`);
}
export function RetrieveCuringCleaningCostsDetails(buy_pt_id, insp_num, correction) {
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveCuringCleaningCostsDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/cleaningcosts?correction=${correction}`:`${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/cleaningcosts?correction=${correction}`);
}

export function RetrieveInspectionHeaderList(insp_num, buy_pt_id, action, getvendorinfo) {
  let optionalParamsUrl = '';
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (action !== null && action !== undefined && action !== "") {
    optionalParamsUrl += `action=${action}&`
  }
  if (getvendorinfo !== null && getvendorinfo !== undefined && getvendorinfo !== "") {
    optionalParamsUrl += `getvendorinfo=${getvendorinfo}&`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveInspectionHeaderList/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inspection-numbers/${insp_num}/inspectionheaders?${optionalParamsUrl}` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inspection-numbers/${insp_num}/inspectionheaders?${optionalParamsUrl}`)
}

export function UpdateVoidNosellInspectionDetails(buy_pt_id, insp_num, void_reason_code, dataObj) {
  return ApiService.updateData(isLocal() ? `/Settlement/UpdateVoidNosellInspectionDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/codes/${void_reason_code}/users/${useridFromLS()}/nosellinspections` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/codes/${void_reason_code}/users/${useridFromLS()}/nosellinspections`, dataObj)
}

export function RetrieveVoidInspectionDetails(comp_id, crop_year, buy_pt_id, insp_num) {
  let optionalParamsUrl = '';
  if (comp_id !== null && comp_id !== '' && comp_id !== undefined) {
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`
  }
  if (crop_year !== null && crop_year !== '' && crop_year !== undefined) {
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (insp_num !== null && insp_num !== '' && insp_num !== undefined) {
    optionalParamsUrl += `insp_num=${insp_num}&`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveVoidInspectionDetails/voidinspections?${optionalParamsUrl}` : `${settlement_url_api}voidinspections?${optionalParamsUrl}`)
}

export function UpdateMarkVoidTableDetails(buy_pt_id, inspection) {
  return ApiService.updateData(isLocal() ? `/Settlement/UpdateMarkVoidTableDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspections/${inspection}/markvoidtables` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspections/${inspection}/markvoidtables`)
}

export function RetrieveContractDetails(pnut_type_id, pnut_variety_id, seg_type, seed_grower, cma_vendor, state_abbr, county_id, farm_id, buy_pt_id, group_id, func_id, func_sub_id, user_id, fv95_insp, free1007, insp_num, seed_gen_produced, vendor_details) {
  let optionalParamsUrl = '';
  if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id !== "") {
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
  }
  if (pnut_variety_id !== null && pnut_variety_id !== undefined && pnut_variety_id !== "") {
    optionalParamsUrl += `pnut_variety_id=${pnut_variety_id}&`
  }
  if (seg_type !== null && seg_type !== undefined && seg_type !== "") {
    optionalParamsUrl += `seg_type=${seg_type}&`
  }
  if (seed_grower !== null && seed_grower !== undefined && seed_grower !== "") {
    optionalParamsUrl += `seed_grower=${seed_grower}&`
  }
  if (cma_vendor !== null && cma_vendor !== undefined && cma_vendor !== "") {
    optionalParamsUrl += `cma_vendor=${cma_vendor}&`
  }
  if (state_abbr !== null && state_abbr !== undefined && state_abbr !== "") {
    optionalParamsUrl += `state_abbr=${state_abbr}&`
  }
  if (county_id !== null && county_id !== undefined && county_id !== "") {
    optionalParamsUrl += `county_id=${county_id}&`
  }
  if (farm_id !== null && farm_id !== undefined && farm_id !== "") {
    optionalParamsUrl += `farm_id=${farm_id}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (group_id !== null && group_id !== undefined && group_id !== "") {
    optionalParamsUrl += `group_id=${group_id}&`
  }
  if (func_id !== null && func_id !== undefined && func_id !== "") {
    optionalParamsUrl += `func_id=${func_id}&`
  }
  if (func_sub_id !== null && func_sub_id !== undefined && func_sub_id !== "") {
    optionalParamsUrl += `func_sub_id=${func_sub_id}&`
  }
  if (user_id !== null && user_id !== undefined && user_id !== "") {
    optionalParamsUrl += `user_id=${user_id}&`
  }
  if (fv95_insp !== null && fv95_insp !== undefined && fv95_insp !== "") {
    optionalParamsUrl += `fv95_insp=${fv95_insp}&`
  }
  if (free1007 !== null && free1007 !== undefined && free1007 !== "") {
    optionalParamsUrl += `free1007=${free1007}&`
  }
  if (insp_num !== null && insp_num !== undefined && insp_num !== "") {
    optionalParamsUrl += `insp_num=${insp_num}&`
  }
  if (seed_gen_produced !== null && seed_gen_produced !== undefined && seed_gen_produced !== "") {
    optionalParamsUrl += `seed_gen_produced=${seed_gen_produced}&`
  }
  if (vendor_details !== null && vendor_details !== undefined && vendor_details !== "") {
    optionalParamsUrl += `vendor_details=${vendor_details}&`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveContractDetails/companies/${compIdFromLS()}/crop_years/${cropYearFromLS()}/contracts?${optionalParamsUrl}` : `${settlement_url_api}companies/${compIdFromLS()}/crop_years/${cropYearFromLS()}/contracts?${optionalParamsUrl}`)
}

export function UpdateConvertHighMoistureToCGrade(area_id,pnut_type_id,seg_type,dataObj){
  return ApiService.updateData(isLocal()?`/Settlement/UpdateConvertHighMoistureToCGrade/crop-years/${cropYearFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/seg-types/${seg_type}/grades`
  :`${settlement_url_api}crop-years/${cropYearFromLS()}/areas/${area_id}/peanut-types/${pnut_type_id}/seg-types/${seg_type}/grades`,dataObj)
}

export function RetrieveInspectUploadGradeDetails(status, func_code, hidden, exclude_flag, buy_pt_id, inspect_num, state_id, county_id, farm_id, farm_suffix, residue_ind) {
  let optionalParamsUrl = '';
  if (status !== null && status !== undefined && status !== "") {
    optionalParamsUrl += `status=${status}&`
  }
  if (func_code !== null && func_code !== undefined && func_code !== "") {
    optionalParamsUrl += `func_code=${func_code}&`
  }
  if (hidden !== null && hidden !== undefined && hidden !== "") {
    optionalParamsUrl += `hidden=${hidden}&`
  }
  if (exclude_flag !== null && exclude_flag !== undefined && exclude_flag !== "") {
    optionalParamsUrl += `exclude_flag=${exclude_flag}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (inspect_num !== null && inspect_num !== undefined && inspect_num !== "") {
    optionalParamsUrl += `inspect_num=${inspect_num}&`
  }
  if (state_id !== null && state_id !== undefined && state_id !== "") {
    optionalParamsUrl += `state_id=${state_id}&`
  }
  if (county_id !== null && county_id !== undefined && county_id !== "") {
    optionalParamsUrl += `county_id=${county_id}&`
  }
  if (farm_id !== null && farm_id !== undefined && farm_id !== "") {
    optionalParamsUrl += `farm_id=${farm_id}&`
  }
  if (farm_suffix !== null && farm_suffix !== undefined && farm_suffix !== "") {
    optionalParamsUrl += `farm_suffix=${farm_suffix}&`
  }
  if (residue_ind !== null && residue_ind !== undefined && residue_ind !== "") {
    optionalParamsUrl += `residue_ind=${residue_ind}&`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveInspectUploadGradeDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/uploadgrades?${optionalParamsUrl}` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/uploadgrades?${optionalParamsUrl}`);
}

export function RetrieveInspectionHeaderDetails(data) {
  let optionalParamsUrl = '';
  if (data.split_vendor !== null && data.split_vendor !== undefined && data.split_vendor !== "") {
    optionalParamsUrl += `split_vendor=${data.split_vendor}&`
  }
  if (data.split_share_pct !== null && data.split_share_pct !== undefined && data.split_share_pct !== "") {
    optionalParamsUrl += `split_share_pct=${data.split_share_pct}&`
  }
  if (data.only_share_pct !== null && data.only_share_pct !== undefined && data.only_share_pct !== "") {
    optionalParamsUrl += `only_share_pct=${data.only_share_pct}&`
  }
  if (data.buy_pt_id !== null && data.buy_pt_id !== undefined && data.buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${data.buy_pt_id}&`
  }
  if (data.func_sub_id !== null && data.func_sub_id !== undefined && data.func_sub_id !== "") {
    optionalParamsUrl += `func_sub_id=${data.func_sub_id}&`
  }
  if (data.area_id !== null && data.area_id !== undefined && data.area_id !== "") {
    optionalParamsUrl += `area_id=${data.area_id}&`
  }
  if (data.coll_pt_id !== null && data.coll_pt_id !== undefined && data.coll_pt_id !== "") {
    optionalParamsUrl += `coll_pt_id=${data.coll_pt_id}&`
  }
  if (data.insp_num !== null && data.insp_num !== undefined && data.insp_num !== "") {
    optionalParamsUrl += `insp_num=${data.insp_num}&`
  }
  if (data.settlement_num !== null && data.settlement_num !== undefined && data.settlement_num !== "") {
    optionalParamsUrl += `settlement_num=${data.settlement_num}&`
  }
  if (data.weight_cert_num !== null && data.weight_cert_num !== undefined && data.weight_cert_num !== "") {
    optionalParamsUrl += `weight_cert_num=${data.weight_cert_num}&`
  }
  if (data.pnut_type_id !== null && data.pnut_type_id !== undefined && data.pnut_type_id !== "") {
    optionalParamsUrl += `pnut_type_id=${data.pnut_type_id}&`
  }
  if (data.pnut_variety_id !== null && data.pnut_variety_id !== undefined && data.pnut_variety_id !== "") {
    optionalParamsUrl += `pnut_variety_id=${data.pnut_variety_id}&`
  }
  if (data.farm_id !== null && data.farm_id !== undefined && data.farm_id !== "") {
    optionalParamsUrl += `farm_id=${data.farm_id}&`
  }
  if (data.farm_suffix !== null && data.farm_suffix !== undefined && data.farm_suffix !== "") {
    optionalParamsUrl += `farm_suffix=${data.farm_suffix}&`
  }
  if (data.insp_status !== null && data.insp_status !== undefined && data.insp_status !== "") {
    optionalParamsUrl += `insp_status=${data.insp_status}&`
  }
  if (data.seg_type !== null && data.seg_type !== undefined && data.seg_type !== "") {
    optionalParamsUrl += `seg_type=${data.seg_type}&`
  }
  if (data.oleic_ind !== null && data.oleic_ind !== undefined && data.oleic_ind !== "") {
    optionalParamsUrl += `oleic_ind=${data.oleic_ind}&`
  }
  if (data.state_abbr !== null && data.state_abbr !== undefined && data.state_abbr !== "") {
    optionalParamsUrl += `state_abbr=${data.state_abbr}&`
  }
  if (data.county_id !== null && data.county_id !== undefined && data.county_id !== "") {
    optionalParamsUrl += `county_id=${data.county_id}&`
  }
  if (data.purch_store_ind !== null && data.purch_store_ind !== undefined && data.purch_store_ind !== "") {
    optionalParamsUrl += `purch_store_ind=${data.purch_store_ind}&`
  }
  if (data.obl_buy_id !== null && data.obl_buy_id !== undefined && data.obl_buy_id !== "") {
    optionalParamsUrl += `obl_buy_id=${data.obl_buy_id}&`
  }
  if (data.insp_date_time_start !== null && data.insp_date_time_start !== undefined && data.insp_date_time_start !== "") {
    optionalParamsUrl += `insp_date_time_start=${data.insp_date_time_start}&`
  }
  if (data.contract_num !== null && data.contract_num !== undefined && data.contract_num !== "") {
    optionalParamsUrl += `contract_num=${data.contract_num}&`
  }
  if (data.seed_gen !== null && data.seed_gen !== undefined && data.seed_gen !== "") {
    optionalParamsUrl += `seed_gen=${data.seed_gen}&`
  }
  if (data.insp_date_time_end !== null && data.insp_date_time_end !== undefined && data.insp_date_time_end !== "") {
    optionalParamsUrl += `insp_date_time_end=${data.insp_date_time_end}&`
  }
  if (data.cont_type !== null && data.cont_type !== undefined && data.cont_type !== "") {
    optionalParamsUrl += `cont_type=${data.cont_type}&`
  }
  if (data.whse_rcpt_num !== null && data.whse_rcpt_num !== undefined && data.whse_rcpt_num !== "") {
    optionalParamsUrl += `whse_rcpt_num=${data.whse_rcpt_num}&`
  }
  if (data.unld_whse_id !== null && data.unld_whse_id !== undefined && data.unld_whse_id !== "") {
    optionalParamsUrl += `unld_whse_id=${data.unld_whse_id}&`
  }
  if (data.unld_bin_id !== null && data.unld_bin_id !== undefined && data.unld_bin_id !== "") {
    optionalParamsUrl += `unld_bin_id=${data.unld_bin_id}&`
  }
  if (data.obl_whse_id !== null && data.obl_whse_id !== undefined && data.obl_whse_id !== "") {
    optionalParamsUrl += `obl_whse_id=${data.obl_whse_id}&`
  }
  if (data.obl_bin_id !== null && data.obl_bin_id !== undefined && data.obl_bin_id !== "") {
    optionalParamsUrl += `obl_bin_id=${data.obl_bin_id}&`
  }
  if (data.organic_ind !== null && data.organic_ind !== undefined && data.organic_ind !== "") {
    optionalParamsUrl += `organic_ind=${data.organic_ind}&`
  }
  if (data.high_moist !== null && data.high_moist !== undefined && data.high_moist !== "") {
    optionalParamsUrl += `high_moist=${data.high_moist}&`
  }
  if (data.seed_ind !== null && data.seed_ind !== undefined && data.seed_ind !== "") {
    optionalParamsUrl += `seed_ind=${data.seed_ind}&`
  }
  if (data.receiptedstatus !== null && data.receiptedstatus !== undefined && data.receiptedstatus !== "") {
    optionalParamsUrl += `receiptedstatus=${data.receiptedstatus}&`
  }
  if (data.residue_load !== null && data.residue_load !== undefined && data.residue_load !== "") {
    optionalParamsUrl += `residue_load=${data.residue_load}&`
  }

  return ApiService.getData(isLocal() ? `/Settlement/RetrieveInspectionHeaderDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/functions/${data.func_id}/inspectionheaders?${optionalParamsUrl}` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/functions/${data.func_id}/inspectionheaders?${optionalParamsUrl}`)
}

export function RemoveInspection(buy_pt_id, inspection) {
  return ApiService.removeData(isLocal() ? `/Settlement/RemoveInspection/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${inspection}` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${inspection}`)
}

export function UpdateSettlementSignDetails(buyingPoint, inspectionNumber) {
  return ApiService.updateData(
    isLocal()
      ? `/Settlement/UpdateSettlementSignDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPoint}/inspection-numbers/${inspectionNumber}/users/${useridFromLS()}/signs`
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPoint}/inspection-numbers/${inspectionNumber}/users/${useridFromLS()}/signs`
  )
}

export function UpdateUnSignSettlement(buyingPoint, inspectionNumber) {
  return ApiService.updateData(
    isLocal()
      ? `/Settlement/UpdateUnSignSettlement/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPoint}/inspection-numbers/${inspectionNumber}/users/${useridFromLS()}/unsignsettlements`
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPoint}/inspection-numbers/${inspectionNumber}/users/${useridFromLS()}/unsignsettlements`
  )
}

export function UpdateSettlementVoidDetails(buyingPoint, inspectionNumber, dataObj) {
  return ApiService.updateData(
    isLocal()
      ? `/Settlement/UpdateSettlementVoidDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPoint}/inspection-numbres/${inspectionNumber}/settlementvoids`
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPoint}/inspection-numbres/${inspectionNumber}/settlementvoids`
    , dataObj
  )
}

export function UpdateSettlementCorrectionDetails(buyingPoint, inspectionNumber, dataObj) {
  return ApiService.updateData(
    isLocal()
      ? `/Settlement/UpdateSettlementCorrectionDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPoint}/inspection-numbres/${inspectionNumber}/settlementcorrections`
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPoint}/inspection-numbres/${inspectionNumber}/settlementcorrections`
    , dataObj
  )
}

export function UpdateSettlementSupersedeDetails(buyingPoint, inspectionNumber, dataObj) {
  return ApiService.updateData(
    isLocal()
      ? `/Settlement/UpdateSettlementSupersedeDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPoint}/inspection-numbres/${inspectionNumber}/settlementsupersedes`
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPoint}/inspection-numbres/${inspectionNumber}/settlementsupersedes`
    , dataObj
  )
}

export function UpdateUploadHiddenIndicatorDetails(transaction_number,indicator){
  return ApiService.updateData(isLocal()? `/Settlement/UpdateUploadHiddenIndicatorDetails/transaction-numbers/${transaction_number}/indicators/${indicator}/hiddenindicators`: `${settlement_url_api}transaction-numbers/${transaction_number}/indicators/${indicator}/hiddenindicators`)
}

export function UpdateUploadResubmitDetails(transaction_number, dataObj){
  return ApiService.updateData(isLocal()? `/Settlement/UpdateUploadResubmitDetails/transaction-numbers/${transaction_number}/resubmits`: `${settlement_url_api}transaction-numbers/${transaction_number}/resubmits`, dataObj)
}
export function RetrieveKCMODataDetails(buy_pt_id, fed_buy_pt_id, insp_num, settlement_num,beg_trans_date,end_trans_date, beg_settle_num,end_settle_num,filenumber){
  let optionalParamsUrl = '';
  if(buy_pt_id !== null && buy_pt_id !==undefined && buy_pt_id !==""){
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if(fed_buy_pt_id !== null && fed_buy_pt_id !==undefined && fed_buy_pt_id !==""){
    optionalParamsUrl += `fed_buy_pt_id=${fed_buy_pt_id}&`
  }
  if(insp_num!== null && insp_num !==undefined && insp_num !==""){
    optionalParamsUrl += `insp_num=${insp_num}&`
  }
  if(settlement_num!== null && settlement_num !==undefined && settlement_num !==""){
    optionalParamsUrl += `settlement_num=${settlement_num}&`
  }
  if(beg_trans_date!== null &&beg_trans_date !==undefined && beg_trans_date !==""){
    optionalParamsUrl += `beg_trans_date=${beg_trans_date}&`
  }
  if(end_trans_date!== null && end_trans_date !==undefined && end_trans_date !==""){
    optionalParamsUrl += `end_trans_date=${end_trans_date}&`
  }
  if(beg_settle_num!== null && beg_settle_num !==undefined && beg_settle_num !==""){
    optionalParamsUrl += `beg_settle_num=${beg_settle_num}&`
  }
  if(end_settle_num!== null && end_settle_num !==undefined && end_settle_num!==""){
    optionalParamsUrl += `end_settle_num=${end_settle_num}&`
  }
  if(filenumber!== null && filenumber !==undefined && filenumber !==""){
    optionalParamsUrl += `filenumber=${filenumber}&`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveKCMODataDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/kctransmissions?${optionalParamsUrl}` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/kctransmissions?${optionalParamsUrl}`);
}

export function RetrieveInspectHeaderResidueDetails(buy_pt_id, insp_num) {
  let optionalParamsUrl = ''; 
  if(buy_pt_id !== null && buy_pt_id !==undefined && buy_pt_id !==""){
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveInspectHeaderResidueDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inspection-numbres/${insp_num}/headerresidues?${optionalParamsUrl}`:`${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inspection-numbres/${insp_num}/headerresidues?${optionalParamsUrl}`);
}


export function UpdateResidueInspectionDetails(buy_pt_id, insp_num,request_action, residueInspectiondataObj){
  return ApiService.updateData(isLocal() ? `/Settlement/UpdateResidueInspectionDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbres/${insp_num}/actions/${request_action}/residueinspections` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbres/${insp_num}/actions/${request_action}/residueinspections`, residueInspectiondataObj);
}

export function UpdateChangeInspectionNumber(compId, cropYear, buyingPt, oldInspNum, newInspNum, dataObj){
  return ApiService.updateData(isLocal()
    ? `/Settlement/UpdateChangeInspectionNumber/companies/${compId}/crop-years/${cropYear}/buying-points/${buyingPt}/old-inspections/${oldInspNum}/new-inspections/${newInspNum}/inspections`
    : `${settlement_url_api}companies/${compId}/crop-years/${cropYear}/buying-points/${buyingPt}/old-inspections/${oldInspNum}/new-inspections/${newInspNum}/inspections`
    , dataObj
  )
}

//Premium Deductions


export function CreatePremiumDeductionDetails(buy_pt_id, insp_num,ObjPremiumDeduction) {
  return ApiService.postData(isLocal() ? `/Settlement/CreatePremiumDeductionDetails/companies/${compIdFromLS}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/premiumdeductions`
  : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/premiumdeductions`,ObjPremiumDeduction)
}
export function RetrievePremiumDeductionScheduleList(inspNum,
  buy_pt_id,inspect_ind,segvalue_p,contlist_p,appllbs_p,vendlist_p,netwt_p,lskwt_p,grosswt_p,segvalue_s,
  contlist_s,appllbs_s,vendlist_s,netwt_s,lskwt_s,grosswt_s,basis_grade_amt_p,basis_grade_amt_s,pdcodes_cure_clean_costs,seglessoptpurch,
  contlist_seed,appllbs_seed,segvalue_p1,segvalue_p2,segvalue_p3,segvalue_p4, segvalue_p5,  segvalue_p6,segvalue_p7, segvalue_p8, segvalue_p9,
  segvalue_p10, segvalue_p11,segvalue_p12,segvalue_p13,segvalue_p14, segvalue_p15,segvalue_p16, segvalue_p17, segvalue_p18, segvalue_p19,
  segvalue_p20,  segvalue_s1,segvalue_s2,segvalue_s3, segvalue_s4, segvalue_s5, segvalue_s6, segvalue_s7, segvalue_s8, segvalue_s9, 
  segvalue_s10,segvalue_s11, segvalue_s12, segvalue_s13, segvalue_s14, segvalue_s15,segvalue_s16,segvalue_s17,segvalue_s18,segvalue_s19,
  segvalue_s20,correction) {
  let optionalParamsUrl = ``
  if(buy_pt_id !== null && buy_pt_id !==undefined && buy_pt_id !==""){
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if(inspect_ind !== null && inspect_ind !==undefined && inspect_ind !==""){
    optionalParamsUrl += `inspect_ind=${inspect_ind}&`
  }
  if(segvalue_p !== null && segvalue_p !==undefined && segvalue_p !==""){
    optionalParamsUrl += `segvalue_p=${segvalue_p}&`
  }
  if(contlist_p !== null && contlist_p !==undefined && contlist_p !==""){
    optionalParamsUrl += `contlist_p=${contlist_p}&`
  }
  if(appllbs_p !== null && appllbs_p !==undefined && appllbs_p !==""){
    optionalParamsUrl += `appllbs_p=${appllbs_p}&`
  }
  if(vendlist_p !== null && vendlist_p !==undefined && vendlist_p !==""){
    optionalParamsUrl += `vendlist_p=${vendlist_p}&`
  }
  if(netwt_p !== null && netwt_p !==undefined && netwt_p !==""){
    optionalParamsUrl += `netwt_p=${netwt_p}&`
  }
  if(lskwt_p !== null && lskwt_p !==undefined && lskwt_p !==""){
    optionalParamsUrl += `lskwt_p=${lskwt_p}&`
  }
  if(grosswt_p !== null && grosswt_p !==undefined && grosswt_p !==""){
    optionalParamsUrl += `grosswt_p=${grosswt_p}&`
  }
  if(segvalue_s !== null && segvalue_s !==undefined && segvalue_s !==""){
    optionalParamsUrl += `segvalue_s=${segvalue_s}&`
  }
  if(contlist_s !== null && contlist_s !==undefined && contlist_s !==""){
    optionalParamsUrl += `contlist_s=${contlist_s}&`
  }
  if(appllbs_s !== null && appllbs_s !==undefined && appllbs_s !==""){
    optionalParamsUrl += `appllbs_s=${appllbs_s}&`
  }
  if(vendlist_s !== null && vendlist_s !==undefined && vendlist_s !==""){
    optionalParamsUrl += `vendlist_s=${vendlist_s}&`
  }
  if(netwt_s !== null && netwt_s !==undefined && netwt_s !==""){
    optionalParamsUrl += `netwt_s=${netwt_s}&`
  }
  if(lskwt_s !== null && lskwt_s !==undefined && lskwt_s !==""){
    optionalParamsUrl += `lskwt_s=${lskwt_s}&`
  }
  if(grosswt_s !== null && grosswt_s !==undefined && grosswt_s !==""){
    optionalParamsUrl += `grosswt_s=${grosswt_s}&`
  }
  if(basis_grade_amt_p !== null && basis_grade_amt_p !==undefined && basis_grade_amt_p !==""){
    optionalParamsUrl += `basis_grade_amt_p=${basis_grade_amt_p}&`
  }
  if(basis_grade_amt_s !== null && basis_grade_amt_s !==undefined && basis_grade_amt_s !==""){
    optionalParamsUrl += `basis_grade_amt_s=${basis_grade_amt_s}&`
  }
  if(pdcodes_cure_clean_costs !== null && pdcodes_cure_clean_costs !==undefined && pdcodes_cure_clean_costs !==""){
    optionalParamsUrl += `pdcodes_cure_clean_costs=${pdcodes_cure_clean_costs}&`
  }
  if(seglessoptpurch !== null && seglessoptpurch !==undefined && seglessoptpurch !==""){
    optionalParamsUrl += `seglessoptpurch=${seglessoptpurch}&`
  }
  if(contlist_seed !== null && contlist_seed !==undefined && contlist_seed !==""){
    optionalParamsUrl += `contlist_seed=${contlist_seed}&`
  }
  if(appllbs_seed !== null && appllbs_seed !==undefined && appllbs_seed !==""){
    optionalParamsUrl += `appllbs_seed=${appllbs_seed}&`
  }
  if(segvalue_p1 !== null && segvalue_p1 !==undefined && segvalue_p1 !==""){
    optionalParamsUrl += `segvalue_p1=${segvalue_p1}&`
  }
  if(segvalue_p2 !== null && segvalue_p2 !==undefined && segvalue_p2 !==""){
    optionalParamsUrl += `segvalue_p2=${segvalue_p2}&`
  }
  if(segvalue_p3 !== null && segvalue_p3 !==undefined && segvalue_p3 !==""){
    optionalParamsUrl += `segvalue_p3=${segvalue_p3}&`
  }
  if(segvalue_p4 !== null && segvalue_p4 !==undefined && segvalue_p4 !==""){
    optionalParamsUrl += `segvalue_p4=${segvalue_p4}&`
  }
  if(segvalue_p5 !== null && segvalue_p5 !==undefined && segvalue_p5 !==""){
    optionalParamsUrl += `segvalue_p5=${segvalue_p5}&`
  }
  if(segvalue_p6 !== null && segvalue_p6 !==undefined && segvalue_p6 !==""){
    optionalParamsUrl += `segvalue_p6=${segvalue_p6}&`
  }
  if(segvalue_p7 !== null && segvalue_p7 !==undefined && segvalue_p7 !==""){
    optionalParamsUrl += `segvalue_p7=${segvalue_p7}&`
  }
  if(segvalue_p8 !== null && segvalue_p8 !==undefined && segvalue_p8 !==""){
    optionalParamsUrl += `segvalue_p8=${segvalue_p8}&`
  }
  if(segvalue_p9 !== null && segvalue_p9 !==undefined && segvalue_p9 !==""){
    optionalParamsUrl += `segvalue_p9=${segvalue_p9}&`
  }
  if(segvalue_p10 !== null && segvalue_p10 !==undefined && segvalue_p10 !==""){
    optionalParamsUrl += `segvalue_p10=${segvalue_p10}&`
  }if(segvalue_p11 !== null && segvalue_p11 !==undefined && segvalue_p11 !==""){
    optionalParamsUrl += `segvalue_p11=${segvalue_p11}&`
  }
  
  if(segvalue_p12 !== null && segvalue_p12 !==undefined && segvalue_p12 !==""){
    optionalParamsUrl += `segvalue_p12=${segvalue_p12}&`
  }
  if(segvalue_p13 !== null && segvalue_p13 !==undefined && segvalue_p13 !==""){
    optionalParamsUrl += `segvalue_p13=${segvalue_p13}&`
  }
  if(segvalue_p14 !== null && segvalue_p14 !==undefined && segvalue_p14 !==""){
    optionalParamsUrl += `segvalue_p14=${segvalue_p14}&`
  }
  if(segvalue_p15 !== null && segvalue_p15 !==undefined && segvalue_p15 !==""){
    optionalParamsUrl += `segvalue_p15=${segvalue_p15}&`
  }
  if(segvalue_p16 !== null && segvalue_p16 !==undefined && segvalue_p16 !==""){
    optionalParamsUrl += `segvalue_p16=${segvalue_p16}&`
  }
  if(segvalue_p17 !== null && segvalue_p17 !==undefined && segvalue_p17 !==""){
    optionalParamsUrl += `segvalue_p17=${segvalue_p17}&`
  }
  if(segvalue_p18 !== null && segvalue_p18 !==undefined && segvalue_p18 !==""){
    optionalParamsUrl += `segvalue_p18=${segvalue_p18}&`
  }
  if(segvalue_p19 !== null && segvalue_p19 !==undefined && segvalue_p19 !==""){
    optionalParamsUrl += `segvalue_p19=${segvalue_p19}&`
  }
  if(segvalue_p20 !== null && segvalue_p20 !==undefined && segvalue_p20 !==""){
    optionalParamsUrl += `segvalue_p20=${segvalue_p20}&`
  }
  if(segvalue_s1 !== null && segvalue_s1 !==undefined && segvalue_s1 !==""){
    optionalParamsUrl += `segvalue_s1=${segvalue_s1}&`
  } 
  if(segvalue_s2 !== null && segvalue_s2 !==undefined && segvalue_s2 !==""){
    optionalParamsUrl += `segvalue_s2=${segvalue_s2}&`
  }
  if(segvalue_s3 !== null && segvalue_s3 !==undefined && segvalue_s3 !==""){
    optionalParamsUrl += `segvalue_s3=${segvalue_s3}&`
  }
  if(segvalue_s4 !== null && segvalue_s4 !==undefined && segvalue_s4 !==""){
    optionalParamsUrl += `segvalue_s4=${segvalue_s4}&`
  }
  if(segvalue_s5 !== null && segvalue_s5 !==undefined && segvalue_s5 !==""){
    optionalParamsUrl += `segvalue_s5=${segvalue_s5}&`
  }
  if(segvalue_s6 !== null && segvalue_s6 !==undefined && segvalue_s6 !==""){
    optionalParamsUrl += `segvalue_s6=${segvalue_s6}&`
  }
  if(segvalue_s7 !== null && segvalue_s7 !==undefined && segvalue_s7 !==""){
    optionalParamsUrl += `segvalue_s7=${segvalue_s7}&`
  }
  if(segvalue_s8 !== null && segvalue_s8 !==undefined && segvalue_s8 !==""){
    optionalParamsUrl += `segvalue_s8=${segvalue_s8}&`
  }
  if(segvalue_s9 !== null && segvalue_s9 !==undefined && segvalue_s9 !==""){
    optionalParamsUrl += `segvalue_s9=${segvalue_s9}&`
  }
  if(segvalue_s10 !== null && segvalue_s10 !==undefined && segvalue_s10 !==""){
    optionalParamsUrl += `segvalue_s10=${segvalue_s10}&`
  }
  if(segvalue_s11 !== null && segvalue_s11 !==undefined && segvalue_s11 !==""){
    optionalParamsUrl += `segvalue_s11=${segvalue_s11}&`
  }
  if(segvalue_s12 !== null && segvalue_s12 !==undefined && segvalue_s12 !==""){
    optionalParamsUrl += `segvalue_s12=${segvalue_s12}&`
  }
  if(segvalue_s13 !== null && segvalue_s13 !==undefined && segvalue_s13 !==""){
    optionalParamsUrl += `segvalue_s13=${segvalue_s13}&`
  }
  if(segvalue_s14 !== null && segvalue_s14 !==undefined && segvalue_s14 !==""){
    optionalParamsUrl += `segvalue_s14=${segvalue_s14}&`
  }
  if(segvalue_s15 !== null && segvalue_s15 !==undefined && segvalue_s15 !==""){
    optionalParamsUrl += `segvalue_s15=${segvalue_s15}&`
  }
  if(segvalue_s16 !== null && segvalue_s16 !==undefined && segvalue_s16 !==""){
    optionalParamsUrl += `segvalue_s16=${segvalue_s16}&`
  }
  if(segvalue_s17 !== null && segvalue_s17 !==undefined && segvalue_s17 !==""){
    optionalParamsUrl += `segvalue_s17=${segvalue_s17}&`
  }
  if(segvalue_s18 !== null && segvalue_s18 !==undefined && segvalue_s18 !==""){
    optionalParamsUrl += `segvalue_s18=${segvalue_s18}&`
  }
  if(segvalue_s19 !== null && segvalue_s19 !==undefined && segvalue_s19 !==""){
    optionalParamsUrl += `segvalue_s19=${segvalue_s19}&`
  }
  if(segvalue_s20 !== null && segvalue_s20 !==undefined && segvalue_s20 !==""){
    optionalParamsUrl += `segvalue_s20=${segvalue_s20}&`
  }
  if(correction !== null && correction !==undefined && correction !==""){
    optionalParamsUrl += `correction=${correction}&`
  }

  return ApiService.getData(
    isLocal()
      ? `/Settlement/RetrievePremiumDeductionScheduleList/inspection-numbers/${inspNum}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/deductionschedules?${optionalParamsUrl}`
      : `${settlement_url_api}inspection-numbers/${inspNum}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/deductionschedules?${optionalParamsUrl}`
  )
}

export function CreateInspection(buyingPointId, inspectionNumber, data) {
  return ApiService.postData(isLocal() ?
    `/Settlement/CreateInspection/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPointId}/inspection-numbers/${inspectionNumber}/inspections` :
    `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPointId}/inspection-numbers/${inspectionNumber}/inspections`,
    data);
}

export function UpdateInspection(buyingPointId, inspectionNumber, data) {
  return ApiService.updateData(isLocal() ?
    `/Settlement/UpdateInspection/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPointId}/inspection-numbers/${inspectionNumber}/inspectionheaders` :
    `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPointId}/inspection-numbers/${inspectionNumber}/inspectionheaders`,
    data);
}

export function RetrieveTradePremiumDeductionScheduleDetails(
  insp_num, buy_pt_id, inspect_ind, segvalue_p,
  contlist_p, appllbs_p, vendlist_p,
  netwt_p, lskwt_p, grosswt_p, segvalue_s, contlist_s,
  appllbs_s, vendlist_s, netwt_s, lskwt_s,
  grosswt_s, basis_grade_amt_p, basis_grade_amt_s,
  pdcodes_cure_clean_costs, seglessoptpurch, segvalp1,
  segvalp2, segvalp3, segvalp4, segvalp5, segvalp6, segvalp7,
  segvalp8, segvalp9, segvalp10, segvalp11, segvalp12, segvalp13,
  segvalp14, segvalp15, segvalp16, segvalp17, segvalp18, segvalp19, segvalp20) {

  let optionalParamsUrl = '';
  if (segvalue_p !== null && segvalue_p !== undefined && segvalue_p != "") {
    optionalParamsUrl += `segvalue_p=${segvalue_p}&`
  }
  if (contlist_p !== null && contlist_p !== undefined && contlist_p != "") {
    optionalParamsUrl += `contlist_p=${contlist_p}&`
  }
  if (appllbs_p !== null && appllbs_p !== undefined && appllbs_p != "") {
    optionalParamsUrl += `appllbs_p=${appllbs_p}&`
  }
  if (vendlist_p !== null && vendlist_p !== undefined && vendlist_p != "") {
    optionalParamsUrl += `vendlist_p=${vendlist_p}&`
  }
  if (netwt_p !== null && netwt_p !== undefined && netwt_p != "") {
    optionalParamsUrl += `netwt_p=${netwt_p}&`
  }
  if (lskwt_p !== null && lskwt_p !== undefined && lskwt_p != "") {
    optionalParamsUrl += `lskwt_p=${lskwt_p}&`
  }
  if (grosswt_p !== null && grosswt_p !== undefined && grosswt_p != "") {
    optionalParamsUrl += `grosswt_p=${grosswt_p}&`
  }
  if (segvalue_s !== null && segvalue_s !== undefined && segvalue_s != "") {
    optionalParamsUrl += `segvalue_s=${segvalue_s}&`
  }
  if (contlist_s !== null && contlist_s !== undefined && contlist_s != "") {
    optionalParamsUrl += `contlist_s=${contlist_s}&`
  }
  if (appllbs_s !== null && appllbs_s !== undefined && appllbs_s != "") {
    optionalParamsUrl += `appllbs_s=${appllbs_s}&`
  }
  if (vendlist_s !== null && vendlist_s !== undefined && vendlist_s != "") {
    optionalParamsUrl += `vendlist_s=${vendlist_s}&`
  }
  if (netwt_s !== null && netwt_s !== undefined && netwt_s != "") {
    optionalParamsUrl += `netwt_s=${netwt_s}&`
  }
  if (lskwt_s !== null && lskwt_s !== undefined && lskwt_s != "") {
    optionalParamsUrl += `lskwt_s=${lskwt_s}&`
  }
  if (grosswt_s !== null && grosswt_s !== undefined && grosswt_s != "") {
    optionalParamsUrl += `grosswt_s=${grosswt_s}&`
  }
  if (basis_grade_amt_p !== null && basis_grade_amt_p !== undefined && basis_grade_amt_p != "") {
    optionalParamsUrl += `basis_grade_amt_p=${basis_grade_amt_p}&`
  }
  if (basis_grade_amt_s !== null && basis_grade_amt_s !== undefined && basis_grade_amt_s != "") {
    optionalParamsUrl += `basis_grade_amt_s=${basis_grade_amt_s}&`
  }
  if (pdcodes_cure_clean_costs !== null && pdcodes_cure_clean_costs !== undefined && pdcodes_cure_clean_costs != "") {
    optionalParamsUrl += `pdcodes_cure_clean_costs=${pdcodes_cure_clean_costs}&`
  }
  if (seglessoptpurch !== null && seglessoptpurch !== undefined && seglessoptpurch != "") {
    optionalParamsUrl += `seglessoptpurch=${seglessoptpurch}&`
  }
  if (segvalp1 !== null && segvalp1 !== undefined && segvalp1 != "") {
    optionalParamsUrl += `segvalp1=${segvalp1}&`
  }
  if (segvalp2 !== null && segvalp2 !== undefined && segvalp2 != "") {
    optionalParamsUrl += `segvalp2=${segvalp2}&`
  }
  if (segvalp3 !== null && segvalp3 !== undefined && segvalp3 != "") {
    optionalParamsUrl += `segvalp3=${segvalp3}&`
  }
  if (segvalp4 !== null && segvalp4 !== undefined && segvalp4 != "") {
    optionalParamsUrl += `segvalp4=${segvalp4}&`
  }
  if (segvalp5 !== null && segvalp5 !== undefined && segvalp5 != "") {
    optionalParamsUrl += `segvalp5=${segvalp5}&`
  }
  if (segvalp6 !== null && segvalp6 !== undefined && segvalp6 != "") {
    optionalParamsUrl += `segvalp6=${segvalp6}&`
  }
  if (segvalp7 !== null && segvalp7 !== undefined && segvalp7 != "") {
    optionalParamsUrl += `segvalp7=${segvalp7}&`
  }
  if (segvalp8 !== null && segvalp8 !== undefined && segvalp8 != "") {
    optionalParamsUrl += `segvalp8=${segvalp8}&`
  }
  if (segvalp9 !== null && segvalp9 !== undefined && segvalp9 != "") {
    optionalParamsUrl += `segvalp9=${segvalp9}&`
  }
  if (segvalp10 !== null && segvalp10 !== undefined && segvalp10 != "") {
    optionalParamsUrl += `segvalp10=${segvalp10}&`
  }
  if (segvalp11 !== null && segvalp11 !== undefined && segvalp11 != "") {
    optionalParamsUrl += `segvalp11=${segvalp11}&`
  }
  if (segvalp12 !== null && segvalp12 !== undefined && segvalp12 != "") {
    optionalParamsUrl += `segvalp12=${segvalp12}&`
  }
  if (segvalp13 !== null && segvalp13 !== undefined && segvalp13 != "") {
    optionalParamsUrl += `segvalp13=${segvalp13}&`
  }
  if (segvalp14 !== null && segvalp14 !== undefined && segvalp14 != "") {
    optionalParamsUrl += `segvalp14=${segvalp14}&`
  }
  if (segvalp15 !== null && segvalp15 !== undefined && segvalp15 != "") {
    optionalParamsUrl += `segvalp15=${segvalp15}&`
  }
  if (segvalp16 !== null && segvalp16 !== undefined && segvalp16 != "") {
    optionalParamsUrl += `segvalp16=${segvalp16}&`
  }
  if (segvalp17 !== null && segvalp17 !== undefined && segvalp17 != "") {
    optionalParamsUrl += `segvalp17=${segvalp17}&`
  }
  if (segvalp18 !== null && segvalp18 !== undefined && segvalp18 != "") {
    optionalParamsUrl += `segvalp18=${segvalp18}&`
  }
  if (segvalp19 !== null && segvalp19 !== undefined && segvalp19 != "") {
    optionalParamsUrl += `segvalp19=${segvalp19}&`
  }
  if (segvalp20 !== null && segvalp20 !== undefined && segvalp20 != "") {
    optionalParamsUrl += `segvalp20=${segvalp20}&`
  }
  return ApiService.getData(isLocal() ?
    `/Settlement/RetrieveTradePremiumDeductionScheduleDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inspections/${insp_num}/buying-points/${buy_pt_id}/indicators/${inspect_ind}/schedules?${optionalParamsUrl}`
    :
    `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inspections/${insp_num}/buying-points/${buy_pt_id}/indicators/${inspect_ind}/schedules?${optionalParamsUrl}`);
}

export function RetrieveSpotPriceControlList(comp_id, crop_year, buy_pt_id, pnut_type_id, effect_date_time) {
  let optionalParamsUrl = ``
  if (comp_id !== null && comp_id !== undefined && comp_id !== "") {
    optionalParamsUrl += `comp_id=${comp_id}&`
  }
  if (crop_year !== null && crop_year !== undefined && crop_year !== "") {
    optionalParamsUrl += `crop_year=${crop_year}&`
  }
  if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id !== "") {
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if (pnut_type_id !== null && pnut_type_id !== undefined && pnut_type_id !== "") {
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
  }
  if (effect_date_time !== null && effect_date_time !== undefined && effect_date_time !== "") {
    optionalParamsUrl += `effect_date_time=${effect_date_time}&`
  }
  return ApiService.getData(
    isLocal()
      ? `/Settlement/RetrieveSpotPriceControlList/spotpricecontrols?${optionalParamsUrl}`
      : `${settlement_url_api}spotpricecontrols?${optionalParamsUrl}`
  )
}

export function RetrieveContractGrantList(buy_pt_id, insp_num) {
  return ApiService.getData(
    isLocal()
      ? `/Settlement/RetrieveContractGrantList/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/inspection-numbers/${insp_num}/grants`
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/inspection-numbers/${insp_num}/grants`
  )
}

export function RetrieveCheckWareHouseStatusDetails(insp_num) {
  return ApiService.getData(
    isLocal()
      ? `/Settlement/RetrieveCheckWareHouseStatusDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inspection-numbres/${insp_num}/warehousestatus`
      : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inspection-numbres/${insp_num}/warehousestatus`
  )
}

export function RetrieveCheckWareHouserInIvsDetails(buy_pt_id,insp_num){
  return ApiService.getData(
    isLocal()
    ?`/Settlement/RetrieveCheckWareHouserInIvsDetails/crop-years/${cropYearFromLS()}/companies/${compIdFromLS()}/buying-points/${buy_pt_id}/inspections/${insp_num}/checks`
    :`${settlement_url_api}crop-years/${cropYearFromLS()}/companies/${compIdFromLS()}/buying-points/${buy_pt_id}/inspections/${insp_num}/checks`
  )
}

export function updateFree1007Flag(buy_pt_id,insp_num,data){
  return ApiService.updateData(
    isLocal()
    ?`/Settlement/updateFree1007Flag/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/1007flags`
    :`${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/1007flags`,data
  )
}

export function UpdateSettlementInspection(buyingPointId, inspectionNumber, settlementnumber, data) {
  return ApiService.updateData(isLocal() ?
    `/Settlement/UpdateSettlementInspection/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPointId}/inspection-numbers/${inspectionNumber}/settlement_numbers/${settlementnumber}/settlementinspections` :
    `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buyingPointId}/inspection-numbers/${inspectionNumber}/settlement_numbers/${settlementnumber}/settlementinspections`,
    data);

}

export function RetrieveFedWareHouseLicenseNameDetails(fed_whouse_license, insp_num) {
  let optionalParamsUrl = '';
  if(compIdFromLS() !== null && compIdFromLS() !==undefined && compIdFromLS() !==""){
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`
  }
  if(cropYearFromLS() !== null && cropYearFromLS() !==undefined && cropYearFromLS() !==""){
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`
  }
  if(fed_whouse_license !== null && fed_whouse_license !==undefined && fed_whouse_license !==""){
    optionalParamsUrl += `fed_whouse_license=${fed_whouse_license}&`
  }
  if(insp_num !== null && insp_num !==undefined && insp_num !==""){
    optionalParamsUrl += `insp_num=${insp_num}&`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveFedWareHouseLicenseNameDetails/warehouselicensenames?${optionalParamsUrl}`:`${settlement_url_api}/warehouselicensenames?${optionalParamsUrl}`);
}

export function RetrieveInspectHeaderPrint1007Details(insp_num) {
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveInspectHeaderPrint1007Details/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inspection-numbers/${insp_num}/headerprints`:`${settlement_url_api}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inspection-numbers/${insp_num}/headerprints`);
}

export function RetrieveInspectionHeaderVoidReprintList(insp_num,settlement_num,action,buy_pt_id,getvendorinfo) {
  let optionalParamsUrl = '';
  
  if(action !== null && action !==undefined && action !==""){
    optionalParamsUrl += `action=${action}&`
  }
  if(buy_pt_id !== null && buy_pt_id !==undefined && buy_pt_id !==""){
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if(getvendorinfo !== null && getvendorinfo !==undefined && getvendorinfo !==""){
    optionalParamsUrl += `getvendorinfo=${getvendorinfo}&`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveInspectionHeaderVoidReprintList/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inspection-numbers/${insp_num}/settlement_numbers/${settlement_num}/voidreprints?${optionalParamsUrl}`:`${settlement_url_api}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inspection-numbers/${insp_num}/settlement_numbers/${settlement_num}/voidreprints?${optionalParamsUrl}`);
}

export function CreateInspectCorrectionLogDetails(data) {
  return ApiService.postData(isLocal() ?
    `/Settlement/CreateInspectCorrectionLogDetails/correctionlogs` :`${settlement_url_api}correctionlogs`, data);
}
export function UpdateScaleTicketUploadStatus(recType, buyingPointId, users, data) {
  return ApiService.updateData(isLocal() ?
    `/Settlement/UpdateScaleTicketUploadStatus/rec-type/${recType}/buying-points/${buyingPointId}/users/${users}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/tickets` :
    `${settlement_url_api}rec-type/${recType}/buying-points/${buyingPointId}/users/${users}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/tickets`,
    data);
}
export function RetrieveInspectHeaderList(compId, cropYear, insp_num, buy_pt_id, getvendorinfo) {
  let optionalParamsUrl = ''
  if (compId != '' && compId !== null && compId !== undefined)
    optionalParamsUrl += `comp_id=${compId}&`
  if (cropYear != '' && cropYear !== null && cropYear !== undefined)
    optionalParamsUrl += `crop_year=${cropYear}&`
  if (buy_pt_id != '' && buy_pt_id !== null && buy_pt_id !== undefined)
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  if (getvendorinfo != '' && getvendorinfo !== null && getvendorinfo !== undefined)
    optionalParamsUrl += `getvendorinfo=${getvendorinfo}&`
  return ApiService.getData(isLocal()
    ? `/Settlement/RetrieveInspectHeaderList/inspection-numbers/${insp_num}/inspectheaders?` + optionalParamsUrl
    : `${settlement_url_api}inspection-numbers/${insp_num}/inspectheaders?` + optionalParamsUrl
  )
}

export function RetrieveInspectHeaderOneDocumentDetail(func_id,buy_pt_id,insp_num,settlement_num,weight_cert_num,pnut_type_id,pnut_variety_id,farm_id,insp_status,seed_gen,seg_type,oleic_ind,state_abbr,county_id,purch_store_ind,split_vendor,whse_rcpt_num,obl_buy_id,area_id,coll_pt_id,  insp_date_time_start,insp_date_time_end,contract_num,cont_type,func_sub_id
  ) {
  let optionalParamsUrl = '';
  
  if(buy_pt_id !== null && buy_pt_id !==undefined && buy_pt_id !==""){
    optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
  }
  if(insp_num !== null && insp_num !==undefined && insp_num !==""){
    optionalParamsUrl += `insp_num=${insp_num}&`
  }
  if(settlement_num !== null && settlement_num !==undefined && settlement_num !==""){
    optionalParamsUrl += `settlement_num=${settlement_num}&`
  }
  if(weight_cert_num !== null && weight_cert_num !==undefined && weight_cert_num !==""){
    optionalParamsUrl += `weight_cert_num=${weight_cert_num}&`
  }
  if(pnut_type_id !== null && pnut_type_id !==undefined && pnut_type_id !==""){
    optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`
  }
  if(pnut_variety_id !== null && pnut_variety_id !==undefined && pnut_variety_id !==""){
    optionalParamsUrl += `pnut_variety_id=${pnut_variety_id}&`
  }
  if(farm_id !== null && farm_id !==undefined && farm_id !==""){
    optionalParamsUrl += `farm_id=${farm_id}&`
  }
  if(insp_status !== null && insp_status !==undefined && insp_status !==""){
    optionalParamsUrl += `insp_status=${insp_status}&`
  }
  if(seed_gen !== null && seed_gen !==undefined && seed_gen !==""){
    optionalParamsUrl += `seed_gen=${seed_gen}&`
  }
  if(seg_type !== null && seg_type !==undefined && seg_type !==""){
    optionalParamsUrl += `seg_type=${seg_type}&`
  }
  if(oleic_ind !== null && oleic_ind !==undefined && oleic_ind !==""){
    optionalParamsUrl += `oleic_ind=${oleic_ind}&`
  }
  if(state_abbr !== null && state_abbr !==undefined && state_abbr !==""){
    optionalParamsUrl += `state_abbr=${state_abbr}&`
  }
  if(county_id !== null && county_id !==undefined && county_id !==""){
    optionalParamsUrl += `county_id=${county_id}&`
  }
  if(purch_store_ind !== null && purch_store_ind !==undefined && purch_store_ind !==""){
    optionalParamsUrl += `purch_store_ind=${purch_store_ind}&`
  }
  if(split_vendor !== null && split_vendor !==undefined && split_vendor !==""){
    optionalParamsUrl += `split_vendor=${split_vendor}&`
  }
  if(whse_rcpt_num !== null && whse_rcpt_num !==undefined && whse_rcpt_num !==""){
    optionalParamsUrl += `whse_rcpt_num=${whse_rcpt_num}&`
  }
  if(obl_buy_id !== null && obl_buy_id !==undefined && obl_buy_id !==""){
    optionalParamsUrl += `obl_buy_id=${obl_buy_id}&`
  } 
  if(area_id !== null && area_id !==undefined && area_id !==""){
    optionalParamsUrl += `area_id=${area_id}&`
  }
  if(coll_pt_id !== null && coll_pt_id !==undefined && coll_pt_id !==""){
    optionalParamsUrl += `coll_pt_id=${coll_pt_id}&`
  }
  if(insp_date_time_start !== null && insp_date_time_start !==undefined && insp_date_time_start !==""){
    optionalParamsUrl += `insp_date_time_start=${insp_date_time_start}&`
  }
  if(insp_date_time_end !== null && insp_date_time_end !==undefined && insp_date_time_end !==""){
    optionalParamsUrl += `insp_date_time_end=${insp_date_time_end}&`
  }
  if(contract_num !== null && contract_num !==undefined && contract_num !==""){
    optionalParamsUrl += `contract_num=${contract_num}&`
  }
  if(cont_type !== null && cont_type !==undefined && cont_type !==""){
    optionalParamsUrl += `cont_type=${cont_type}&`
  }
  if(func_sub_id !== null && func_sub_id !==undefined && func_sub_id !==""){
    optionalParamsUrl += `func_sub_id=${func_sub_id}`
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveInspectHeaderOneDocumentDetail/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/OneDocuments?${optionalParamsUrl}`:`${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/OneDocuments?${optionalParamsUrl}`);
}

// WHOUSE_BIN_INVENTORY_LIST *
export function RetrieveWhouseBinInventoryDetailsForTradeSettleProfile(compId,buy_pt_id, whouse_num, bin_num, seed_gen, organic_ind) {
  let optionalParameters = ``
  if (whouse_num != null && whouse_num != undefined && whouse_num != "") {
    optionalParameters += `whouse_num=${whouse_num}&`
  }
  if (bin_num != null && bin_num != undefined && bin_num != "") {
    optionalParameters += `bin_num=${bin_num}&`
  }
  if (seed_gen != null && seed_gen != undefined && seed_gen != "") {
    optionalParameters += `seed_gen=${seed_gen}&`
  }
  if (organic_ind != null && organic_ind != undefined && organic_ind != "") {
    optionalParameters += `organic_ind=${organic_ind}&`
  }
  return ApiService.getData(
    isLocal()

      ? `/Settlement/RetrieveWhouseBinInventoryDetails/companies/${compId}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inventories?${optionalParameters}`
      : `${settlement_url_api}companies/${compId}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inventories?` + optionalParameters
  )
}

export function RetrieveInspectHeaderOneDocumentDetails(func_id,buy_pt_id,insp_num,settlement_num,weight_cert_num,pnut_type_id,pnut_variety_id,farm_id,insp_status,seed_gen,seg_type,oleic_ind,state_abbr,county_id,purch_store_ind,split_vendor,whse_rcpt_num,obl_buy_id,area_id,coll_pt_id,insp_date_time_start,insp_date_time_end,contract_num,cont_type,func_sub_id){

  let optionalParameters = ``
  if(buy_pt_id != undefined && buy_pt_id != null && buy_pt_id != ''){
    optionalParameters += `buy_pt_id=${buy_pt_id}&`
  }
  if(insp_num != undefined && insp_num != null && insp_num != ''){
    optionalParameters += `insp_num=${insp_num}&`
  }
  if(settlement_num != undefined && settlement_num != null && settlement_num != ''){
    optionalParameters += `settlement_num=${settlement_num}&`
  }
  if(weight_cert_num != undefined && weight_cert_num != null && weight_cert_num != ''){
    optionalParameters += `weight_cert_num=${weight_cert_num}&`
  }
  if(pnut_type_id != undefined && pnut_type_id != null && pnut_type_id != ''){
    optionalParameters += `pnut_type_id=${pnut_type_id}&`
  }
  if(pnut_variety_id != undefined && pnut_variety_id != null && pnut_variety_id != ''){
    optionalParameters += `pnut_variety_id=${pnut_variety_id}&`
  }
  if(farm_id != undefined && farm_id != null && farm_id != ''){
    optionalParameters += `farm_id=${farm_id}&`
  }
  if(insp_status != undefined && insp_status != null && insp_status != ''){
    optionalParameters += `insp_status=${insp_status}&`
  }
  if(seed_gen != undefined && seed_gen != null && seed_gen != ''){
    optionalParameters += `seed_gen=${seed_gen}&`
  }
  if(seg_type != undefined && seg_type != null && seg_type != ''){
    optionalParameters += `seg_type=${seg_type}&`
  }
  if(oleic_ind != undefined && oleic_ind != null && oleic_ind != ''){
    optionalParameters += `oleic_ind=${oleic_ind}&`
  }
  if(state_abbr != undefined && state_abbr != null && state_abbr != ''){
    optionalParameters += `state_abbr=${state_abbr}&`
  }
  if(county_id != undefined && county_id != null && county_id != ''){
    optionalParameters += `county_id=${county_id}&`
  }
  if(purch_store_ind != undefined && purch_store_ind != null && purch_store_ind != ''){
    optionalParameters += `purch_store_ind=${purch_store_ind}&`
  }
  if(split_vendor != undefined && split_vendor != null && split_vendor != ''){
    optionalParameters += `split_vendor=${split_vendor}&`
  }
  if(whse_rcpt_num != undefined && whse_rcpt_num != null && whse_rcpt_num != ''){
    optionalParameters += `whse_rcpt_num=${whse_rcpt_num}&`
  }
  if(obl_buy_id != undefined && obl_buy_id != null && obl_buy_id != ''){
    optionalParameters += `obl_buy_id=${obl_buy_id}&`
  }
  if(area_id != undefined && area_id != null && area_id != ''){
    optionalParameters += `area_id=${area_id}&`
  }
  if(coll_pt_id != undefined && coll_pt_id != null && coll_pt_id != ''){
    optionalParameters += `coll_pt_id=${coll_pt_id}&`
  }
  if(insp_date_time_start != undefined && insp_date_time_start != null && insp_date_time_start != ''){
    optionalParameters += `insp_date_time_start=${insp_date_time_start}&`
  }
  if(insp_date_time_end != undefined && insp_date_time_end != null && insp_date_time_end != ''){
    optionalParameters += `insp_date_time_end=${insp_date_time_end}&`
  }
  if(contract_num != undefined && contract_num != null && contract_num != ''){
    optionalParameters += `contract_num=${contract_num}&`
  }
  if(cont_type != undefined && cont_type != null && cont_type != ''){
    optionalParameters += `cont_type=${cont_type}&`
  }
  if(func_sub_id != undefined && func_sub_id != null && func_sub_id != ''){
    optionalParameters += `func_sub_id=${func_sub_id}`
  }

  return ApiService.getData(isLocal()?`/Settlement/RetrieveInspectHeaderOneDocumentDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/OneDocuments?${optionalParameters}` :
                                      `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/OneDocuments?${optionalParameters}`)
}
export function RetrieveCheck1007OneDocumentDetails(buy_pt_id) {
  return ApiService.getData(
      isLocal()
          ? `/Settlement/RetrieveCheck1007OneDocumentDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/documents`
          : `${settlement_url_api}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/documents`
  )
}
export function RetrieveWeigherControlList(buy_pt_id, insp_num, dry_ticket) {
  let optionalParamsUrl = '';

  if (compIdFromLS() != null && compIdFromLS() !== '' && compIdFromLS() !== undefined) {
      optionalParamsUrl += `comp_id=${compIdFromLS()}&`;
  }
  if (cropYearFromLS() != null && cropYearFromLS() !== '' && cropYearFromLS() !== undefined) {
      optionalParamsUrl += `crop_year=${cropYearFromLS()}&`;
  }
  if (buy_pt_id != null && buy_pt_id !== '' && buy_pt_id !== undefined) {
      optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`;
  }
  if (insp_num != null && insp_num !== '' && insp_num !== undefined) {
      optionalParamsUrl += `insp_num=${insp_num}&`;
  }
  if (dry_ticket != null && dry_ticket !== '' && dry_ticket !== undefined) {
      optionalParamsUrl += `dry_ticket=${dry_ticket}&`;
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveWeigherControlList/weighercontrols?${optionalParamsUrl}` : `${settlement_url_api}/weighercontrols?${optionalParamsUrl}`);

}
export function RetrieveLoanRePaymentControlDetails(pnut_type_id, effect_date_time, cdate) {
  let optionalParamsUrl = '';
  if (pnut_type_id != null && pnut_type_id !== '' && pnut_type_id !== undefined) {
      optionalParamsUrl += `pnut_type_id=${pnut_type_id}&`;
  }
  if (effect_date_time != null && effect_date_time !== '' && effect_date_time !== undefined) {
    optionalParamsUrl += `effect_date_time=${effect_date_time}&`;
  }
  if (cdate != null && cdate !== '' && cdate !== undefined) {
      optionalParamsUrl += `cdate=${cdate}&`;
  }

  return ApiService.getData(
      isLocal() ? `/Settlement/RetrieveLoanRePaymentControlDetails/repayments?comp_id=${compIdFromLS()}&crop_year=${cropYearFromLS()}&${optionalParamsUrl}`
          : `${settlement_url_api}repayments?comp_id=${compIdFromLS()}&crop_year=${cropYearFromLS()}&${optionalParamsUrl}`
  )
}
export function RetrieveVoidReasonDetails(void_reason_code, nosellonly, verifyaccess) {
  let optionalParamsUrl = '';
  if (void_reason_code != null && void_reason_code !== '' && void_reason_code !== undefined) {
      optionalParamsUrl += `void_reason_code=${void_reason_code}&`;
  }
  if (nosellonly != null && nosellonly !== '' && nosellonly !== undefined) {
      optionalParamsUrl += `nosellonly=${nosellonly}&`;
  }
  if (verifyaccess != null && verifyaccess !== '' && verifyaccess !== undefined) {
      optionalParamsUrl += `verifyaccess=${verifyaccess}&`;
  }
  return ApiService.getData(isLocal() ? `/Settlement/RetrieveVoidReasonDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/voidreasons?${optionalParamsUrl}` : `${settlement_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/voidreasons?${optionalParamsUrl}`)
}
