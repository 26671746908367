/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  hide,
  show,
  getValue,
  getData,
  setValue,
  setData,
  goTo,
  enable,
  disable,
} from "../../shared/WindowImports";

import "./ManualAcctDistMaint.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import { returnNumericValues } from './../../Common/Constants';
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import Loading from "../../../Loader/Loading";
import moment from "moment";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_ManualAcctDistMaint(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  let userid = sessionStorage.getItem("userid");
  let lblScreenNumber = "PN0240";
  let lblScreenNumberTag = "";

  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    txtShipLocationVal: {
      name: "txtShipLocationVal",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShipLocationVal_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtShipLocationVal_PROPERTIES
    },
    txtLocationVal: {
      name: "txtLocationVal",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocationVal_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtLocationVal_PROPERTIES
    },
    txtChargeLocationVal: {
      name: "txtChargeLocationVal",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChargeLocationVal_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtChargeLocationVal_PROPERTIES
    },
    lblReason: {
      name: "lblReason",
      type: "LabelWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "In order to reverse this accounting record you must first enter an explanation in the Reason field below:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblReason_PROPERTIES

      // END_USER_CODE-USER_lblReason_PROPERTIES
    },
    Label: "ManualAcctDistMaint",
    windowName: "ManualAcctDistMaint",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.ManualAcctDistMaint",
    // START_USER_CODE-USER_ManualAcctDistMaint_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Account Distribution Maintenance",
      scrCode: "PN0240B",
    },
    // END_USER_CODE-USER_ManualAcctDistMaint_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    ddTransferType: {
      name: "ddTransferType",
      type: "DropDownFieldWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Transfer Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddTransferType_PROPERTIES

      // END_USER_CODE-USER_ddTransferType_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblPostedOn: {
      name: "lblPostedOn",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Posted to IBM General Ledger on:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPostedOn_PROPERTIES

      // END_USER_CODE-USER_lblPostedOn_PROPERTIES
    },
    lblPostedOnValue: {
      name: "lblPostedOnValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPostedOnValue_PROPERTIES

      // END_USER_CODE-USER_lblPostedOnValue_PROPERTIES
    },
    radioSourceType: {
      name: "radioSourceType",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxManualAcctDistMaint",
      Options:
        "(1007) Settlement:1,Transfer:2,Contract:3,Delivery Agreement:4,Warehouse Receipt:5,Trade Settlement:6,Check/Special Pay:7",
      Label: "Source Type:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioSourceType_PROPERTIES

      // END_USER_CODE-USER_radioSourceType_PROPERTIES
    },
    txt1007SettleNum: {
      name: "txt1007SettleNum",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "(1007) Settlement #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007SettleNum_PROPERTIES

      // END_USER_CODE-USER_txt1007SettleNum_PROPERTIES
    },
    txtAccountNum: {
      name: "txtAccountNum",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Account #:",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccountNum_PROPERTIES

      // END_USER_CODE-USER_txtAccountNum_PROPERTIES
    },
    txtAmount: {
      name: "txtAmount",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Amount $:",
      LengthRange: { MinLength: 0, MaxLength: 12 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAmount_PROPERTIES

      // END_USER_CODE-USER_txtAmount_PROPERTIES
    },
    txtChargeLocation: {
      name: "txtChargeLocation",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Charge Location:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChargeLocation_PROPERTIES

      // END_USER_CODE-USER_txtChargeLocation_PROPERTIES
    },
    txtCheckNum: {
      name: "txtCheckNum",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Check #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheckNum_PROPERTIES

      // END_USER_CODE-USER_txtCheckNum_PROPERTIES
    },
    txtContractNum: {
      name: "txtContractNum",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContractNum_PROPERTIES

      // END_USER_CODE-USER_txtContractNum_PROPERTIES
    },
    txtDeliveryAgree: {
      name: "txtDeliveryAgree",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Delivery Agree #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeliveryAgree_PROPERTIES

      // END_USER_CODE-USER_txtDeliveryAgree_PROPERTIES
    },
    txtDepartment: {
      name: "txtDepartment",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Department:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDepartment_PROPERTIES

      // END_USER_CODE-USER_txtDepartment_PROPERTIES
    },
    txtLocation: {
      name: "txtLocation",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Location:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocation_PROPERTIES

      // END_USER_CODE-USER_txtLocation_PROPERTIES
    },
    txtQuantity: {
      name: "txtQuantity",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Quantity #:",
      LengthRange: { MinLength: 0, MaxLength: 12 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtQuantity_PROPERTIES

      // END_USER_CODE-USER_txtQuantity_PROPERTIES
    },
    txtReason: {
      name: "txtReason",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Reason:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReason_PROPERTIES

      // END_USER_CODE-USER_txtReason_PROPERTIES
    },
    txtResponsibility: {
      name: "txtResponsibility",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Responsibility:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtResponsibility_PROPERTIES

      // END_USER_CODE-USER_txtResponsibility_PROPERTIES
    },
    txtSC95InspNum: {
      name: "txtSC95InspNum",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "(SC95) Inspection #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95InspNum_PROPERTIES

      // END_USER_CODE-USER_txtSC95InspNum_PROPERTIES
    },
    txtShipLocation: {
      name: "txtShipLocation",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Ship Location:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShipLocation_PROPERTIES

      // END_USER_CODE-USER_txtShipLocation_PROPERTIES
    },
    txtSubDepartment: {
      name: "txtSubDepartment",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Sub-Deprtment:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSubDepartment_PROPERTIES

      // END_USER_CODE-USER_txtSubDepartment_PROPERTIES
    },
    txtTradeInspNum: {
      name: "txtTradeInspNum",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Trade Inspection #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeInspNum_PROPERTIES

      // END_USER_CODE-USER_txtTradeInspNum_PROPERTIES
    },
    txtTradeSettleNum: {
      name: "txtTradeSettleNum",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Trade Settlement #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeSettleNum_PROPERTIES

      // END_USER_CODE-USER_txtTradeSettleNum_PROPERTIES
    },
    txtTransferNum: {
      name: "txtTransferNum",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Transfer #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum_PROPERTIES
    },
    txtWhseRcptNum: {
      name: "txtWhseRcptNum",
      type: "TextBoxWidget",
      parent: "grpbxManualAcctDistMaint",
      Label: "Whse Receipt #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhseRcptNum_PROPERTIES

      // END_USER_CODE-USER_txtWhseRcptNum_PROPERTIES
    },
    grpbxManualAcctDistMaint: {
      name: "grpbxManualAcctDistMaint",
      type: "GroupBoxWidget",
      parent: "ManualAcctDistMaint",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxManualAcctDistMaint_PROPERTIES

      // END_USER_CODE-USER_grpbxManualAcctDistMaint_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ManualAcctDistMaint",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    formLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    (async () => {

      setLoading(true);
      hide(thisObj, "txtLocationVal");
      hide(thisObj, "txtChargeLocationVal");
      hide(thisObj, "txtShipLocationVal", false);
      hide(thisObj, "lblReason");
      let ManualAcctDistMaint = getData(thisObj, 'manualAcctDistMaint');
      document.getElementsByClassName("btnAddButton")[1].innerHTML = ManualAcctDistMaint.cmdOkCaption;
      if (ManualAcctDistMaint.cmdOkCaption == "Reversal" || ManualAcctDistMaint.cmdOkCaption == "View") {
        await bFillForm();
        EnableDisableControls();
        show(thisObj, "lblReason");
        if (ManualAcctDistMaint.cmdOkCaption == "View") {
          disable(thisObj, "txtReason");
          hide(thisObj, "lblReason");
        }
      }
      hide(thisObj, "btnAdd", false)
      await bFillTransferTypeList(getData(thisObj, "ddTransferType"));
      if (ManualAcctDistMaint.cmdOkCaption == "Reversal" || ManualAcctDistMaint.cmdOkCaption == "View") {
        disable(thisObj, "ddTransferType");

      }
      await hidshowcontrols(getData(thisObj, "radioSourceType"));
      setLoading(false);
    })();
  }, [getData(thisObj, 'manualAcctDistMaint')]);
  // START_USER_CODE-USER_METHODS

  const formLoad = async () => {

    // setLoading(true);
    // hide(thisObj, "txtLocationVal");
    // hide(thisObj, "txtChargeLocationVal");
    // hide(thisObj, "txtShipLocationVal");
    // hide(thisObj, "lblReason");
    // // let maintdetailsData = {
    // //   cmdOkCaption: "Add",
    // //   lblTransactionNumCaption: "23962596"
    // // }

    // // setData(thisObj, "manualAcctDistMaint", maintdetailsData)
    // let ManualAcctDistMaint = getData(thisObj, 'manualAcctDistMaint');
    // document.getElementsByClassName("btnAddButton")[1].innerHTML = ManualAcctDistMaint.cmdOkCaption;
    // if (ManualAcctDistMaint.cmdOkCaption == "Reversal" || ManualAcctDistMaint.cmdOkCaption == "View") {
    //   await bFillForm();
    //   EnableDisableControls();
    //   show(thisObj, "lblReason");
    //   if (ManualAcctDistMaint.cmdOkCaption == "View") {
    //     disable(thisObj, "txtReason");
    //     hide(thisObj, "lblReason");
    //   }
    // }
    // hide(thisObj, "btnAdd", false)
    // await bFillTransferTypeList(getData(thisObj, "ddTransferType"));
    // if (ManualAcctDistMaint.cmdOkCaption == "Reversal" || ManualAcctDistMaint.cmdOkCaption == "View") {
    //   disable(thisObj, "ddTransferType");

    // }
    // await hidshowcontrols(getData(thisObj, "radioSourceType"));
    // setLoading(false);
  }
  //bind transfer type data to the drop down
  const bFillTransferTypeList = async (transfertype) => {
    let js = [];
    js.push({ key: '', description: '' });
    js.push({ key: 'ST', description: 'Stock Transfer' });
    js.push({ key: 'FF', description: 'Farm Freight' });
    js.push({ key: 'NL', description: 'No Load' });
    thisObj.setState(current => {
      return {
        ...current,
        ddTransferType: {
          ...state["ddTransferType"],
          valueList: js
        },
      }
    });
    if (transfertype == undefined || transfertype == null || transfertype == "")
      setValue(thisObj, 'ddTransferType', js.at(0).key);
    else
      setValue(thisObj, 'ddTransferType', transfertype);
  }
  //Hide and show the controls
  const hidshowcontrols = async (sourcetype) => {
    let ManualAcctDistMaint = getData(thisObj, 'manualAcctDistMaint');
    hide(thisObj, 'txtSC95InspNum');
    hide(thisObj, 'txt1007SettleNum');
    hide(thisObj, 'txtShipLocation');
    hide(thisObj, 'txtTransferNum');
    hide(thisObj, 'ddTransferType');
    hide(thisObj, 'txtContractNum');
    hide(thisObj, 'txtDeliveryAgree');
    hide(thisObj, 'txtWhseRcptNum');
    hide(thisObj, 'txtTradeInspNum');
    hide(thisObj, 'txtTradeSettleNum');
    hide(thisObj, 'txtCheckNum');
    hide(thisObj, "txtShipLocationVal");
    if (sourcetype > 0 && ManualAcctDistMaint.cmdOkCaption == "Add")
      show(thisObj, "btnAdd")
    if (sourcetype == "1") {
      show(thisObj, 'txtSC95InspNum');
      show(thisObj, 'txt1007SettleNum');
    }
    else if (sourcetype == "2") {
      show(thisObj, 'txtShipLocation');
      if ((getValue(thisObj, "txtLocation") != undefined && getValue(thisObj, "txtLocation") != "") || (getValue(thisObj, "txtShipLocation") != undefined && getValue(thisObj, "txtShipLocation") != ""))
        show(thisObj, "txtShipLocationVal");
      show(thisObj, 'txtTransferNum');
      show(thisObj, 'ddTransferType');
    }
    else if (sourcetype == "3") {
      show(thisObj, 'txtContractNum');
    }
    else if (sourcetype == "4") {
      show(thisObj, 'txtDeliveryAgree');
    }
    else if (sourcetype == "5") {
      show(thisObj, 'txtWhseRcptNum');
    }
    else if (sourcetype == "6") {
      show(thisObj, 'txtTradeInspNum');
      show(thisObj, 'txtTradeSettleNum');
    }
    else if (sourcetype == "7") {
      show(thisObj, 'txtCheckNum');
    }
  }
  //Hide and showing controls while clicking the radio buttons
  const onradioSourceTypeChange = () => {
    hidshowcontrols(getValue(thisObj, 'radioSourceType'));
  };
  thisObj.onradioSourceTypeChange = onradioSourceTypeChange;
  //Pre populating data to the controls while clicking on View and Reversal buttons on page load
  const bFillForm = async () => {
    let ManualAcctDistMaint = getData(thisObj, 'manualAcctDistMaint');

    let response = await SystemMaintenanceApplicationSupportService.RetrieveManualAccountingDistribution("GET", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ManualAcctDistMaint.lblTransactionNumCaption);
    if (response != null && response != undefined) {
      let data = response[0].acct_dist_activities[0]
      if (data.comp_id != "" && data.comp_id != null) {
        setValue(thisObj, "txtShipLocation", "")
        setData(thisObj, "txtShipLocation", "")
        setValue(thisObj, "txtLocation", data.buy_pt_id)
        setData(thisObj, "txtLocation", data.buy_pt_id)
        setValue(thisObj, "txtAccountNum", data.dist_account)
        setValue(thisObj, "txtResponsibility", data.dist_resp)
        setValue(thisObj, "txtDepartment", data.dist_dept)
        setValue(thisObj, "txtSubDepartment", data.dist_sub_dept)
        setValue(thisObj, "txtChargeLocation", data.dist_loc)
        setData(thisObj, "txtChargeLocation", data.dist_loc)
        setValue(thisObj, "txtAmount", Number(data.dist_amount).toFixed(2))
        setValue(thisObj, "txtQuantity", data.dist_quantity)

        if (data.audit_insp_num != undefined && data.audit_insp_num != "") {
          setValue(thisObj, "radioSourceType", "1")
          setData(thisObj, "radioSourceType", "1")
          setValue(thisObj, "txtSC95InspNum", data.audit_insp_num)
          setValue(thisObj, "txt1007SettleNum", zeroPad(data.audit_settle_num, 7))
        }
        if (data.audit_whse_rcpt != undefined && data.audit_whse_rcpt != "") {
          setValue(thisObj, "radioSourceType", "5")
          setData(thisObj, "radioSourceType", "5")
          setValue(thisObj, "txtWhseRcptNum", zeroPad(data.audit_whse_rcpt, 6))
        }
        if (data.audit_xfer_num != undefined && data.audit_xfer_num != "") {
          setValue(thisObj, "radioSourceType", "2")
          setData(thisObj, "radioSourceType", "2")
          setValue(thisObj, "txtTransferNum", zeroPad(data.audit_xfer_num, 6))
          setData(thisObj, "ddTransferType", data.audit_xfer_type)
          setValue(thisObj, "txtShipLocation", data.audit_buy_pt)
          setData(thisObj, "txtShipLocation", data.audit_buy_pt)
        }
        if (data.audit_trade_insp != undefined && data.audit_trade_insp != "") {
          setValue(thisObj, "radioSourceType", "6")
          setData(thisObj, "radioSourceType", "6")
          setValue(thisObj, "txtTradeInspNum", data.audit_trade_insp)
          setValue(thisObj, "txtTradeSettleNum", zeroPad(data.audit_trade_settle, 7))
        }
        if (data.audit_cont_num != undefined && data.audit_cont_num != "") {
          setValue(thisObj, "radioSourceType", "3")
          setData(thisObj, "radioSourceType", "3")
          setValue(thisObj, "txtContractNum", zeroPad(data.audit_cont_num, 6))
        }
        if (data.audit_check_num != undefined && data.audit_check_num != "") {
          setValue(thisObj, "radioSourceType", "7")
          setData(thisObj, "radioSourceType", "7")
          if (data.audit_check_num == "0")
            setValue(thisObj, "txtCheckNum", "S" + zeroPad(data.audit_receivable_num, 5))
          else
            setValue(thisObj, "txtCheckNum", zeroPad(data.audit_check_num, 6))
        }
        if (data.audit_delv_agree_num != undefined && data.audit_delv_agree_num != "") {
          setValue(thisObj, "radioSourceType", "4")
          setData(thisObj, "radioSourceType", "4")
          setValue(thisObj, "txtDeliveryAgree", zeroPad(data.audit_delv_agree_num, 7))
        }
        setValue(thisObj, "lblAddedByValue", data.audit_user + " -- " + moment(data.audit_date_time).format("MM/DD/YYYY"))
        //setValue(thisObj, "lblPostedOnValue", data.audit_user + " -- " + data.audit_date_time)
        //audit_os_settle_load missed
        if (ManualAcctDistMaint.cmdOkCaption == "View")
          setValue(thisObj, "txtReason", data.audit_reason)
      }

      if (getData(thisObj, "txtLocation") != undefined && getData(thisObj, "txtLocation") != null && getData(thisObj, "txtLocation") != "") {
        ContractManagementService.RetrieveBuyingPointControlDetails(lblScreenNumber, lblScreenNumberTag, null, null, getData(thisObj, "txtLocation")).then(response => {
          let data = response
          if (data == null || data == undefined || data.length <= 0) {
            showMessage(thisObj, "Location not found!");
          }
          else {
            show(thisObj, "txtLocationVal");
            setValue(thisObj, "txtLocationVal", data[0].buy_pt_name);
          }
        })
      }
      if (getData(thisObj, "txtChargeLocation") != undefined && getData(thisObj, "txtChargeLocation") != null && getData(thisObj, "txtChargeLocation") != "") {
        ContractManagementService.RetrieveBuyingPointControlDetails(lblScreenNumber, lblScreenNumberTag, null, null, getData(thisObj, "txtChargeLocation")).then(response => {
          let data = response
          if (data == null || data == undefined || data.length <= 0) {
            showMessage(thisObj, "Charge Location not found!");
          }
          else {
            show(thisObj, "txtChargeLocationVal");
            setValue(thisObj, "txtChargeLocationVal", data[0].buy_pt_name);
          }
        })
      }
      if (getData(thisObj, "txtShipLocation") != undefined && getData(thisObj, "txtShipLocation") != null && getData(thisObj, "txtShipLocation") != "") {
        ContractManagementService.RetrieveBuyingPointControlDetails(lblScreenNumber, lblScreenNumberTag, null, null, getData(thisObj, "txtChargeLocation")).then(response => {
          let data = response
          if (data == null || data == undefined || data.length <= 0) {
            showMessage(thisObj, "Shipping Location not found!");
          }
          else {
            show(thisObj, "txtShipLocationVal");
            setValue(thisObj, "txtShipLocationVal", data[0].buy_pt_name);
          }
        })
      }

    }
  }
  //enable disable controls while clicking on View and Reversal buttons on page load
  const EnableDisableControls = () => {
    disable(thisObj, "txtLocation");
    disable(thisObj, "txtAccountNum");
    disable(thisObj, "txtResponsibility");
    disable(thisObj, "txtDepartment");
    disable(thisObj, "txtSubDepartment");
    disable(thisObj, "txtChargeLocation");
    disable(thisObj, "txtAmount");
    disable(thisObj, "txtQuantity");
    disable(thisObj, "txtSC95InspNum");
    disable(thisObj, "txt1007SettleNum");
    disable(thisObj, "txtShipLocation");
    disable(thisObj, "txtTransferNum");
    disable(thisObj, "txtContractNum");
    disable(thisObj, "txtDeliveryAgree");
    disable(thisObj, "txtWhseRcptNum");
    disable(thisObj, "txtTradeInspNum");
    disable(thisObj, "txtTradeSettleNum");
    disable(thisObj, "txtCheckNum");
    disable(thisObj, "radioSourceType");

  }
  //validate the controls while entering data
  const bFormValid = async () => {
    let bFormValid = true;
    let ManualAcctDistMaint = getData(thisObj, 'manualAcctDistMaint');
    if (getValue(thisObj, "txtLocation") == null || getValue(thisObj, "txtLocation") == undefined || getValue(thisObj, "txtLocation") == "") {
      showMessage(thisObj, "Location must be specified!");
      return false
      bFormValid = false;
    }
    if (getData(thisObj, "chklocation") == "not_found") {
      showMessage(thisObj, "Location not found!");
      return false
      bFormValid = false;

    }

    if (getValue(thisObj, "txtAccountNum") == null || getValue(thisObj, "txtAccountNum") == undefined || getValue(thisObj, "txtAccountNum") == "") {
      showMessage(thisObj, "Account Number must be specified!");
      return false
      bFormValid = false;
    }
    if (getData(thisObj, "checkvalidaccountnumber") == "Invalid") {
      showMessage(thisObj, "Please enter a valid Account Number.");
      return false
      bFormValid = false;
    }
    if (getData(thisObj, "checkvalidaccountnumber") == "12210") {
      showMessage(thisObj, "Manual entries to account 12210 are not allowed.  Use account 12230.");
      return false
      bFormValid = false;
    }
    if (getValue(thisObj, "txtResponsibility") == null || getValue(thisObj, "txtResponsibility") == undefined || getValue(thisObj, "txtResponsibility") == "") {
      showMessage(thisObj, "Account Responsibility must be specified!");
      return false
      bFormValid = false;
    }
    if (getData(thisObj, "checktxtResponsibility") == "Invalid") {
      showMessage(thisObj, "Please enter a valid Account Responsibility.");
      return false
      bFormValid = false;
    }
    if (getValue(thisObj, "txtDepartment") == null || getValue(thisObj, "txtDepartment") == undefined || getValue(thisObj, "txtDepartment") == "") {
      showMessage(thisObj, "Account Department must be specified!");
      return false
      bFormValid = false;
    }
    if (getData(thisObj, "checktxtDepartment") == "Invalid") {
      showMessage(thisObj, "Please enter a valid Account Department.");
      return false
      bFormValid = false;
    }
    if (getValue(thisObj, "txtSubDepartment") == null || getValue(thisObj, "txtSubDepartment") == undefined || getValue(thisObj, "txtSubDepartment") == "") {
      showMessage(thisObj, "Account Sub-Department must be specified!");
      return false
      bFormValid = false;
    }
    if (getData(thisObj, "checktxtSubDepartment") == "Invalid") {
      showMessage(thisObj, "Please enter a valid Account Sub-Department.");
      return false
      bFormValid = false;
    }

    if (getValue(thisObj, "txtChargeLocation") == null || getValue(thisObj, "txtChargeLocation") == undefined || getValue(thisObj, "txtChargeLocation") == "") {
      showMessage(thisObj, "Account Charge Location must be specified!");
      return false
      bFormValid = false;
    }
    if (getData(thisObj, "checktxtChargeLocation") == "not_found") {
      showMessage(thisObj, "Charge Location not found!");
      return false
      bFormValid = false;
    }

    if (getValue(thisObj, "txtAmount") == null || getValue(thisObj, "txtAmount") == undefined || getValue(thisObj, "txtAmount") == "") {
      showMessage(thisObj, "Account Amount must be specified!");
      return false
      bFormValid = false;
    }
    if (getData(thisObj, "checktxtAmount") == "Invalid") {
      showMessage(thisObj, "Invalid Pay Amount");
      return false
      bFormValid = false;
    }

    if (getValue(thisObj, "txtQuantity") == null || getValue(thisObj, "txtQuantity") == undefined || getValue(thisObj, "txtQuantity") == "") {
      setValue(thisObj, "txtQuantity", "0");
    }

    if (getValue(thisObj, 'radioSourceType') == "1") {
      setValue(thisObj, 'txtShipLocation', "");
      setValue(thisObj, 'txtTransferNum', "");
      setValue(thisObj, 'ddTransferType', "");
      setValue(thisObj, 'txtContractNum', "");
      setValue(thisObj, 'txtDeliveryAgree', "");
      setValue(thisObj, 'txtWhseRcptNum', "");
      setValue(thisObj, 'txtTradeInspNum', "");
      setValue(thisObj, 'txtTradeSettleNum', "");
      setValue(thisObj, 'txtCheckNum', "");
      if (getValue(thisObj, "txtSC95InspNum") == null || getValue(thisObj, "txtSC95InspNum") == undefined || getValue(thisObj, "txtSC95InspNum") == "") {
        showMessage(thisObj, "The (SC95) Inspection Number must be specified!");
        return false
        bFormValid = false;
      }
      if (getData(thisObj, "checktxtSC95InspNum") == "Invalid") {
        showMessage(thisObj, "Please enter a valid SC95 Number.");
        return false
        bFormValid = false;
      }
      if (getValue(thisObj, "txt1007SettleNum") == null || getValue(thisObj, "txt1007SettleNum") == undefined || getValue(thisObj, "txt1007SettleNum") == "") {
        showMessage(thisObj, "The (1007) Settlement Number must be specified!");
        return false
        bFormValid = false;
      }
    }
    else if (getValue(thisObj, 'radioSourceType') == "2") {
      setValue(thisObj, 'txtSC95InspNum', "");
      setValue(thisObj, 'txt1007SettleNum', "");
      setValue(thisObj, 'txtContractNum', "");
      setValue(thisObj, 'txtDeliveryAgree', "");
      setValue(thisObj, 'txtWhseRcptNum', "");
      setValue(thisObj, 'txtTradeInspNum', "");
      setValue(thisObj, 'txtTradeSettleNum', "");
      setValue(thisObj, 'txtCheckNum', "");

      if (getValue(thisObj, "txtShipLocation") == null || getValue(thisObj, "txtShipLocation") == undefined || getValue(thisObj, "txtShipLocation") == "") {
        showMessage(thisObj, "The Shipping Location must be specified!");
        return false
        bFormValid = false;
      }
      if (getData(thisObj, "checktxtShipLocation") == "not_found") {
        showMessage(thisObj, "Shipping Location not found!");
        return false
        bFormValid = false;
      }

      if (getValue(thisObj, "txtTransferNum") == null || getValue(thisObj, "txtTransferNum") == undefined || getValue(thisObj, "txtTransferNum") == "") {
        showMessage(thisObj, "The Transfer Number must be specified!");
        return false
        bFormValid = false;
      }
      if (getValue(thisObj, "ddTransferType") == null || getValue(thisObj, "ddTransferType") == undefined || getValue(thisObj, "ddTransferType") == "") {
        showMessage(thisObj, "Transfer Type must be specified!");
        return false
        bFormValid = false;
      }

    }
    else if (getValue(thisObj, 'radioSourceType') == "3") {
      setValue(thisObj, 'txtSC95InspNum', "");
      setValue(thisObj, 'txt1007SettleNum', "");
      setValue(thisObj, 'txtShipLocation', "");
      setValue(thisObj, 'txtTransferNum', "");
      setValue(thisObj, 'ddTransferType', "");
      setValue(thisObj, 'txtDeliveryAgree', "");
      setValue(thisObj, 'txtWhseRcptNum', "");
      setValue(thisObj, 'txtTradeInspNum', "");
      setValue(thisObj, 'txtTradeSettleNum', "");
      setValue(thisObj, 'txtCheckNum', "");

      if (getValue(thisObj, "txtContractNum") == null || getValue(thisObj, "txtContractNum") == undefined || getValue(thisObj, "txtContractNum") == "") {
        showMessage(thisObj, "The Contract Number must be specified!");
        return false
        bFormValid = false;
      }

    }
    else if (getValue(thisObj, 'radioSourceType') == "4") {
      setValue(thisObj, 'txtSC95InspNum', "");
      setValue(thisObj, 'txt1007SettleNum', "");
      setValue(thisObj, 'txtShipLocation', "");
      setValue(thisObj, 'txtTransferNum', "");
      setValue(thisObj, 'ddTransferType', "");
      setValue(thisObj, 'txtContractNum', "");
      setValue(thisObj, 'txtWhseRcptNum', "");
      setValue(thisObj, 'txtTradeInspNum', "");
      setValue(thisObj, 'txtTradeSettleNum', "");
      setValue(thisObj, 'txtCheckNum', "");
      if (getValue(thisObj, "txtDeliveryAgree") == null || getValue(thisObj, "txtDeliveryAgree") == undefined || getValue(thisObj, "txtDeliveryAgree") == "") {
        showMessage(thisObj, "The Delivery Agreement Number must be specified!");
        return false
        bFormValid = false;
      }
    }
    else if (getValue(thisObj, 'radioSourceType') == "5") {
      setValue(thisObj, 'txtSC95InspNum', "");
      setValue(thisObj, 'txt1007SettleNum', "");
      setValue(thisObj, 'txtShipLocation', "");
      setValue(thisObj, 'txtTransferNum', "");
      setValue(thisObj, 'ddTransferType', "");
      setValue(thisObj, 'txtContractNum', "");
      setValue(thisObj, 'txtDeliveryAgree', "");
      setValue(thisObj, 'txtTradeInspNum', "");
      setValue(thisObj, 'txtTradeSettleNum', "");
      setValue(thisObj, 'txtCheckNum', "");
      if (getValue(thisObj, "txtWhseRcptNum") == null || getValue(thisObj, "txtWhseRcptNum") == undefined || getValue(thisObj, "txtWhseRcptNum") == "") {
        showMessage(thisObj, "The Warehouse Receipt Number must be specified!");
        return false
        bFormValid = false;
      }
    }
    if (getValue(thisObj, 'radioSourceType') == "6") {
      setValue(thisObj, 'txtSC95InspNum', "");
      setValue(thisObj, 'txt1007SettleNum', "");
      setValue(thisObj, 'txtShipLocation', "");
      setValue(thisObj, 'txtTransferNum', "");
      setValue(thisObj, 'ddTransferType', "");
      setValue(thisObj, 'txtContractNum', "");
      setValue(thisObj, 'txtDeliveryAgree', "");
      setValue(thisObj, 'txtWhseRcptNum', "");
      setValue(thisObj, 'txtTradeSettleNum', "");
      setValue(thisObj, 'txtCheckNum', "");
      if (getValue(thisObj, "txtTradeInspNum") == null || getValue(thisObj, "txtTradeInspNum") == undefined || getValue(thisObj, "txtTradeInspNum") == "") {
        showMessage(thisObj, "The Trade Inspection Number must be specified!");
        return false
        bFormValid = false;
      }
      if (getData(thisObj, "checktxtTradeInspNum") == "Invalid") {
        showMessage(thisObj, "Please enter a valid Trade Inspection Number.");
        return false
        bFormValid = false;
      }

    }
    if (getValue(thisObj, 'radioSourceType') == "7") {
      setValue(thisObj, 'txtSC95InspNum', "");
      setValue(thisObj, 'txt1007SettleNum', "");
      setValue(thisObj, 'txtShipLocation', "");
      setValue(thisObj, 'txtTransferNum', "");
      setValue(thisObj, 'ddTransferType', "");
      setValue(thisObj, 'txtContractNum', "");
      setValue(thisObj, 'txtDeliveryAgree', "");
      setValue(thisObj, 'txtWhseRcptNum', "");
      setValue(thisObj, 'txtTradeInspNum', "");
      setValue(thisObj, 'txtTradeSettleNum', "");
      if (getValue(thisObj, "txtCheckNum") == null || getValue(thisObj, "txtCheckNum") == undefined || getValue(thisObj, "txtCheckNum") == "") {
        showMessage(thisObj, "The Check Number must be specified!");
        return false
        bFormValid = false;
      }
      if (getData(thisObj, "checktxtCheckNum") == "Invalid") {
        showMessage(thisObj, "Please enter a valid Check Number.");
        return false
        bFormValid = false;
      }

    }

    if (getValue(thisObj, "txtReason") == null || getValue(thisObj, "txtReason") == undefined || getValue(thisObj, "txtReason") == "") {
      showMessage(thisObj, "The Audit Reason must be specified!");
      return false
      bFormValid = false;
    }
    return bFormValid;
  }
  //implementing add and reversal functionality
  const onbtnAddClick = async () => {
    try {
      if (!await bFormValid()) {
        return false;
      }
      setLoading(true);
      let reason = getValue(thisObj, "txtReason")
      if (reason == undefined)
        reason = ""
      let ManualAcctDistMaint = getData(thisObj, 'manualAcctDistMaint');
      let ACCESS = "U";
      if (ManualAcctDistMaint.cmdOkCaption == "Reversal")
        ACCESS = "D"
      let response = await ContractManagementService.RetrieveAccessPermissionDetails(lblScreenNumber, 'null', ACCESS)
      if (response[0].permission != 'Y') {
        showMessage(thisObj, "You do not have security permissions for this function!");
        return false;
      }
      if (ManualAcctDistMaint.cmdOkCaption == "Reversal") {
        let data = {
          "audit_reason": reason,
          "audit_user": userid,
        }
        response = await SystemMaintenanceApplicationSupportService.UpdateManualAccountingDistribution("REV", '', ManualAcctDistMaint.lblTransactionNumCaption, data)
        if (response.status == '200') {
          showMessage(thisObj, "Accounting record was Successfully Reversed!", true);
          setData(thisObj, "frmManualAcctDistSearchrefresh", "true");
          document.getElementById("SystemMaintenanceApplicationSupport_ManualAcctDistMaintPopUp").childNodes[0].click();
        }
        else {
          showMessage(thisObj, "Accounting record was not Reversed!");
          return false;
        }
      }
      else {
        let audit_buy_pt = getValue(thisObj, "txtLocation")
        if (getValue(thisObj, "radioSourceType") == "2")
          audit_buy_pt = getValue(thisObj, "txtShipLocation")
        let AUDIT_CHECK_NUM = "", AUDIT_RECEIVABLE_NUM = "", CheckNum = getValue(thisObj, "txtCheckNum")
        if (CheckNum != undefined && CheckNum != null && CheckNum != "") {
          if (isNaN(CheckNum) == false) {
            AUDIT_CHECK_NUM = CheckNum
            AUDIT_RECEIVABLE_NUM = 0
          }
          else {
            AUDIT_CHECK_NUM = 0
            AUDIT_RECEIVABLE_NUM = CheckNum.slice(1, 5)
          }
        }

        let data1 = {
          "dist_account": getValue(thisObj, "txtAccountNum"),
          "dist_resp": getValue(thisObj, "txtResponsibility"),
          "dist_dept": getValue(thisObj, "txtDepartment"),
          "dist_sub_dept": getValue(thisObj, "txtSubDepartment"),
          "dist_loc": getValue(thisObj, "txtChargeLocation"),
          "dist_amount": getValue(thisObj, "txtAmount"),
          "dist_quantity": getValue(thisObj, "txtQuantity"),
          "audit_buy_pt": audit_buy_pt,
          "audit_check_num": AUDIT_CHECK_NUM,
          "audit_receivable_num": AUDIT_RECEIVABLE_NUM,
          "audit_cont_num": getValue(thisObj, "txtContractNum"),
          "audit_insp_num": getValue(thisObj, "txtSC95InspNum"),
          "audit_settle_num": getValue(thisObj, "txt1007SettleNum"),
          "audit_whse_rcpt": getValue(thisObj, "txtWhseRcptNum"),
          "audit_xfer_num": getValue(thisObj, "txtTransferNum"),
          "audit_trade_insp": getValue(thisObj, "txtTradeInspNum"),
          "audit_trade_settle": getValue(thisObj, "txtTradeSettleNum"),
          "audit_user": userid,
          "audit_reason": reason,
          "audit_xfer_type": getValue(thisObj, "ddTransferType"),
          "audit_delv_agree": getValue(thisObj, "txtDeliveryAgree")
        }
        response = await SystemMaintenanceApplicationSupportService.UpdateManualAccountingDistribution("ADD", getValue(thisObj, "txtLocation"), '', data1)
       
         if (response.status == '200') {
          let frmManualAcctDistSearch = {
            "TransactionNum": response.result,
            "Location": getValue(thisObj, "txtLocation"),
            "AccountNum": getValue(thisObj, "txtAccountNum"),
            "AcctResp": getValue(thisObj, "txtResponsibility"),
            "AcctDept": getValue(thisObj, "txtDepartment"),
            "AcctSubDept": getValue(thisObj, "txtSubDepartment"),
            "AcctChargeLoc": getValue(thisObj, "txtChargeLocation"),
            "Amount": Number(getValue(thisObj, "txtAmount")).toFixed(2),
            "AcctQty": getValue(thisObj, "txtQuantity"),
            "CheckNum": getValue(thisObj, "txtCheckNum"),
            "IssueLoc": getValue(thisObj, "txtLocation"),
            "Cont": zeroPad(getValue(thisObj, "txtContractNum"), 6),
            "Cont": zeroPad(getValue(thisObj, "txtContractNum"), 6),
            "1007Num": zeroPad(getValue(thisObj, "txt1007SettleNum"), 7),
            "SC95Num": getValue(thisObj, "txtSC95InspNum"),
            "WhseRcptNum": zeroPad(getValue(thisObj, "txtWhseRcptNum"), 6),
            "OblLoc": getValue(thisObj, "txtLocation"),
            "TransferNum": zeroPad(getValue(thisObj, "txtTransferNum"), 6),
            "XferShpLoc": getValue(thisObj, "txtShipLocation"),
            "TradeSettleNum": zeroPad(getValue(thisObj, "txtTradeSettleNum"), 7),
            "TradeInspNum": getValue(thisObj, "txtTradeInspNum"),
            "DelvAgree": zeroPad(getValue(thisObj, "txtDeliveryAgree"), 7)
          }
          setData(thisObj, "frmManualAcctDistSearch", frmManualAcctDistSearch);
          showMessage(thisObj, "Accounting record was Successfully Added!", true);
          document.getElementById("SystemMaintenanceApplicationSupport_ManualAcctDistMaintPopUp").childNodes[0].click();

        }
        else {
          showMessage(thisObj, response.message+" Accounting record was not Created! ");
          return false;
        }
        
      }
      setData(thisObj, "radioSourceType", "")
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnAddClick click event"
        );
      }
      return false;

    }
    finally {
      setLoading(false);
    }
  };
  thisObj.onbtnAddClick = onbtnAddClick;
  //implemented closing popup
  const onbtnCancelClick = () => {
    setData(thisObj, "radioSourceType", "");
    document.getElementById("SystemMaintenanceApplicationSupport_ManualAcctDistMaintPopUp").childNodes[0].click();
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;
  //assigning data to the lable and some other controls while entering data into the loaction control
  const ontxtLocationBlur = () => {
    try {
      setData(thisObj, "chklocation", "");
      let Location = getValue(thisObj, "txtLocation")
      if (Location != undefined && Location != "" && Location != null) {
        Location = Location.toUpperCase()
        setValue(thisObj, "txtLocation", Location.toUpperCase())
        ContractManagementService.RetrieveBuyingPointControlDetails(lblScreenNumber, lblScreenNumberTag, null, null, Location).then(response => {
          let data = response
          if (data == null || data == undefined || data.length <= 0) {
            setValue(thisObj, "txtChargeLocation", Location);
            setValue(thisObj, "txtShipLocation", Location);
            setData(thisObj, "chklocation", "not_found");
          }
          else {
            setData(thisObj, "chklocation", "found");
            show(thisObj, "txtLocationVal");
            setValue(thisObj, "txtChargeLocation", Location);
            setValue(thisObj, "txtLocationVal", data[0].buy_pt_name);
            let ManualAcctDistMaint = getData(thisObj, 'manualAcctDistMaint');
            if (ManualAcctDistMaint.cmdOkCaption == "Add") {
              show(thisObj, "txtChargeLocationVal");
              setValue(thisObj, "txtChargeLocation", Location);
              setValue(thisObj, "txtChargeLocationVal", data[0].buy_pt_name);
              setValue(thisObj, "txtShipLocation", Location);
              setValue(thisObj, "txtShipLocationVal", data[0].buy_pt_name);
            }
          }
        })

      }
      else {
        hide(thisObj, "txtChargeLocationVal");
        hide(thisObj, "txtShipLocationVal");
        hide(thisObj, "txtLocationVal");
        setValue(thisObj, "txtLocationVal", "");
        setValue(thisObj, "txtChargeLocationVal", "");
        setValue(thisObj, "txtShipLocationVal", "");
        setValue(thisObj, "txtChargeLocation", "");
        setValue(thisObj, "txtShipLocation", "");
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtLocationChange  event"
        );
      }
      // return false;
    }
  }
  thisObj.ontxtLocationBlur = ontxtLocationBlur;

  const ontxtAccountNumBlur = () => {
    setData(thisObj, "checkvalidaccountnumber", "")
    let AccountNumber = getValue(thisObj, "txtAccountNum")
    if (AccountNumber != undefined && AccountNumber != "" && AccountNumber != null) {
      AccountNumber = AccountNumber.toUpperCase()
      setValue(thisObj, "txtAccountNum", AccountNumber)
      if (AccountNumber.length != 5) {
        setData(thisObj, "checkvalidaccountnumber", "Invalid")
      }
      if (AccountNumber == "12210") {
        setData(thisObj, "checkvalidaccountnumber", "12210")
      }
    }
  }
  thisObj.ontxtAccountNumBlur = ontxtAccountNumBlur;

  const ontxtResponsibilityBlur = () => {
    setData(thisObj, "checktxtResponsibility", "")
    let AccountResponsibility = getValue(thisObj, "txtResponsibility")
    if (AccountResponsibility != undefined && AccountResponsibility != "" && AccountResponsibility != null) {
      AccountResponsibility = AccountResponsibility.toUpperCase()
      setValue(thisObj, "txtResponsibility", AccountResponsibility)
      if (AccountResponsibility.length != 3) {
        setData(thisObj, "checktxtResponsibility", "Invalid")
      }
    }
  }
  thisObj.ontxtResponsibilityBlur = ontxtResponsibilityBlur;

  const ontxtDepartmentBlur = () => {
    setData(thisObj, "checktxtDepartment", "")
    let Department = getValue(thisObj, "txtDepartment")
    if (Department != undefined && Department != "" && Department != null) {
      Department = Department.toUpperCase()
      setValue(thisObj, "txtDepartment", Department)
      if (Department.length != 2) {
        setData(thisObj, "checktxtDepartment", "Invalid")
        // showMessage(thisObj, "Please enter a valid Account Responsibility.");
        //document.getElementById("txtDepartment").focus();
      }
    }
  }
  thisObj.ontxtDepartmentBlur = ontxtDepartmentBlur;

  const ontxtSubDepartmentBlur = () => {
    setData(thisObj, "checktxtSubDepartment", "")
    let SubDepartment = getValue(thisObj, "txtSubDepartment")
    if (SubDepartment != undefined && SubDepartment != "" && SubDepartment != null) {
      SubDepartment = SubDepartment.toUpperCase()
      setValue(thisObj, "txtSubDepartment", SubDepartment)
      if (SubDepartment.length != 3) {
        setData(thisObj, "checktxtSubDepartment", "Invalid")
        //showMessage(thisObj, "Please enter a valid Account Sub-Department.");
        // document.getElementById("txtSubDepartment").focus();
      }
    }
  }
  thisObj.ontxtSubDepartmentBlur = ontxtSubDepartmentBlur;

  const ontxtChargeLocationBlur = () => {
    try {
      setData(thisObj, "checktxtChargeLocation", "")
      let ChargeLocation = getValue(thisObj, "txtChargeLocation")
      if (ChargeLocation != undefined && ChargeLocation != "" && ChargeLocation != null) {
        ChargeLocation = ChargeLocation.toUpperCase()
        setValue(thisObj, "txtChargeLocation", ChargeLocation.toUpperCase())
        ContractManagementService.RetrieveBuyingPointControlDetails(lblScreenNumber, lblScreenNumberTag, null, null, ChargeLocation).then(response => {
          let data = response
          if (data == null || data == undefined || data.length <= 0) {
            setData(thisObj, "checktxtChargeLocation", "not_found")
            //showMessage(thisObj, "Charge Location not found!");
            //document.getElementById("txtChargeLocation").focus();
          }
          else {
            show(thisObj, "txtChargeLocationVal");
            setValue(thisObj, "txtChargeLocationVal", data[0].buy_pt_name);
          }
        })

      }
      else {
        hide(thisObj, "txtChargeLocationVal");
        setValue(thisObj, "txtChargeLocationVal", "");
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtLocationChange  event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtChargeLocationBlur = ontxtChargeLocationBlur;
  const ontxtAmountBlur = () => {
    try {
      setData(thisObj, "checktxtAmount", "")
      let PayAmount = getValue(thisObj, 'txtAmount');
      let PayAmountFixedInteger;
      if (PayAmount != null && PayAmount != undefined && PayAmount != '') {
        if (PayAmount.length >= 12) {
          PayAmountFixedInteger = PayAmount;
        }
        else if(PayAmount.length==10)
        {
          PayAmountFixedInteger = PayAmount+".0"
        }
        else if(PayAmount.length==11)
        {
          PayAmountFixedInteger = PayAmount+"."
        }
        else {

          PayAmountFixedInteger = Number(PayAmount).toFixed(2)
        }
        setValue(thisObj, 'txtAmount', PayAmountFixedInteger)
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on txtAmount Blur event")
      }
      return false
    }
    return true;
  }
  thisObj.ontxtAmountBlur = ontxtAmountBlur;

  const ontxtAmountChange = () => {
    let PayAmountValue = getValue(thisObj, 'txtAmount')
    setValue(thisObj, "txtAmount", validateTextBoxforamount(PayAmountValue))
  };
  thisObj.ontxtAmountChange = ontxtAmountChange
  const ontxtQuantityChange = () => {
    let Amount = getValue(thisObj, 'txtQuantity')
    setValue(thisObj, "txtQuantity", validateTextBoxforQuantity(Amount))
  };
  thisObj.ontxtQuantityChange = ontxtQuantityChange

  const ontxtSC95InspNumBlur = () => {
    setData(thisObj, "checktxtSC95InspNum", "")
    let SC95InspNum = getValue(thisObj, "txtSC95InspNum")
    if (SC95InspNum != undefined && SC95InspNum != "" && SC95InspNum != null) {
      SC95InspNum = SC95InspNum.toUpperCase()
      setValue(thisObj, "txtSC95InspNum", SC95InspNum)
      if (SC95InspNum.length != 7) {
        setData(thisObj, "checktxtSC95InspNum", "Invalid")
      }
    }
  }
  thisObj.ontxtSC95InspNumBlur = ontxtSC95InspNumBlur;
  const ontxtSC95InspNumChange = () => {
    let SC95InspNum = getValue(thisObj, 'txtSC95InspNum')
    setValue(thisObj, "txtSC95InspNum", validateTextBox2(SC95InspNum))
  };
  thisObj.ontxtSC95InspNumChange = ontxtSC95InspNumChange

  const ontxt1007SettleNumBlur = () => {
    let SettleNum = getValue(thisObj, "txt1007SettleNum")
    if (SettleNum != undefined && SettleNum != "" && SettleNum != null) {
      setValue(thisObj, "txt1007SettleNum", zeroPad(SettleNum, 7))
    }
  }
  thisObj.ontxt1007SettleNumBlur = ontxt1007SettleNumBlur

  const ontxt1007SettleNumChange = () => {
    let SettleNum = getValue(thisObj, "txt1007SettleNum")
    if (SettleNum != undefined && SettleNum != "" && SettleNum != null) {
      setValue(thisObj, "txt1007SettleNum", validateTextBox1(SettleNum))
    }
  }
  thisObj.ontxt1007SettleNumChange = ontxt1007SettleNumChange

  const ontxtWhseRcptNumBlur = () => {
    let WhseRcptNum = getValue(thisObj, "txtWhseRcptNum")
    if (WhseRcptNum != undefined && WhseRcptNum != "" && WhseRcptNum != null) {
      setValue(thisObj, "txtWhseRcptNum", zeroPad(WhseRcptNum, 6))
    }
  }
  thisObj.ontxtWhseRcptNumBlur = ontxtWhseRcptNumBlur

  const ontxtWhseRcptNumChange = () => {
    let WhseRcptNum = getValue(thisObj, "txtWhseRcptNum")
    if (WhseRcptNum != undefined && WhseRcptNum != "" && WhseRcptNum != null) {
      setValue(thisObj, "txtWhseRcptNum", validateTextBox3(WhseRcptNum))
    }
  }
  thisObj.ontxtWhseRcptNumChange = ontxtWhseRcptNumChange

  const ontxtShipLocationBlur = () => {
    try {
      setData(thisObj, "checktxtShipLocation", "")
      let ShipLocation = getValue(thisObj, "txtShipLocation")
      if (ShipLocation != undefined && ShipLocation != "" && ShipLocation != null) {
        ShipLocation = ShipLocation.toUpperCase()
        setValue(thisObj, "txtShipLocation", ShipLocation.toUpperCase())
        ContractManagementService.RetrieveBuyingPointControlDetails(lblScreenNumber, lblScreenNumberTag, null, null, ShipLocation).then(response => {
          let data = response
          if (data == null || data == undefined || data.length <= 0) {
            setData(thisObj, "checktxtShipLocation", "not_found")
          }
          else {
            show(thisObj, "txtShipLocationVal");
            setValue(thisObj, "txtShipLocationVal", data[0].buy_pt_name);
          }
        })

      }
      else {
        hide(thisObj, "txtShipLocationVal");
        setValue(thisObj, "txtShipLocationVal", "");
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtShipLocationBlur  event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtShipLocationBlur = ontxtShipLocationBlur;

  const ontxtShipLocationChange = () => {
    let WhseRcptNum = getValue(thisObj, "txtShipLocation")
    if (WhseRcptNum != undefined && WhseRcptNum != "" && WhseRcptNum != null) {
      setValue(thisObj, "txtShipLocation", validateTextBox2(WhseRcptNum))
    }
  }
  thisObj.ontxtShipLocationChange = ontxtShipLocationChange

  const ontxtTransferNumBlur = () => {
    let TransferNum = getValue(thisObj, "txtTransferNum")
    if (TransferNum != undefined && TransferNum != "" && TransferNum != null) {
      setValue(thisObj, "txtTransferNum", zeroPad(TransferNum, 6))
    }
  }
  thisObj.ontxtTransferNumBlur = ontxtTransferNumBlur

  const ontxtTransferNumChange = () => {
    let TransferNum = getValue(thisObj, "txtTransferNum")
    if (TransferNum != undefined && TransferNum != "" && TransferNum != null) {
      setValue(thisObj, "txtTransferNum", validateTextBox2(TransferNum))
    }
  }
  thisObj.ontxtTransferNumChange = ontxtTransferNumChange
  const ontxtTradeInspNumBlur = () => {
    setData(thisObj, "checktxtTradeInspNum", "");
    let TradeInspNum = getValue(thisObj, "txtTradeInspNum")
    if (TradeInspNum != undefined && TradeInspNum != "" && TradeInspNum != null) {
      TradeInspNum = TradeInspNum.toUpperCase()
      setValue(thisObj, "txtTradeInspNum", TradeInspNum)
      if (TradeInspNum.length != 7) {
        setData(thisObj, "checktxtTradeInspNum", "Invalid");
      }
    }
  }
  thisObj.ontxtTradeInspNumBlur = ontxtTradeInspNumBlur;
  const ontxtTradeInspNumChange = () => {
    let TradeInspNum = getValue(thisObj, 'txtTradeInspNum')
    setValue(thisObj, "txtTradeInspNum", validateTextBox2(TradeInspNum))
  };
  thisObj.ontxtTradeInspNumChange = ontxtTradeInspNumChange

  const ontxtTradeSettleNumBlur = () => {
    let TradeSettleNum = getValue(thisObj, "txtTradeSettleNum")
    if (TradeSettleNum != undefined && TradeSettleNum != "" && TradeSettleNum != null) {
      setValue(thisObj, "txtTradeSettleNum", zeroPad(TradeSettleNum, 7))
    }
  }
  thisObj.ontxtTradeSettleNumBlur = ontxtTradeSettleNumBlur

  const ontxtTradeSettleNumChange = () => {
    let TradeSettleNum = getValue(thisObj, 'txtTradeSettleNum')
    setValue(thisObj, "txtTradeSettleNum", validateTextBox3(TradeSettleNum))
  };
  thisObj.ontxtTradeSettleNumChange = ontxtTradeSettleNumChange

  const ontxtContractNumBlur = () => {
    let ContractNum = getValue(thisObj, "txtContractNum")
    if (ContractNum != undefined && ContractNum != "" && ContractNum != null) {
      setValue(thisObj, "txtContractNum", zeroPad(ContractNum, 6))
    }
  }
  thisObj.ontxtContractNumBlur = ontxtContractNumBlur

  const ontxtContractNumChange = () => {
    let ContractNum = getValue(thisObj, 'txtContractNum')
    setValue(thisObj, "txtContractNum", validateTextBox3(ContractNum))
  };
  thisObj.ontxtContractNumChange = ontxtContractNumChange

  const ontxtCheckNumBlur = () => {
    setData(thisObj, "checktxtCheckNum", "");
    let CheckNum = getValue(thisObj, "txtCheckNum")
    if (CheckNum != undefined && CheckNum != "" && CheckNum != null) {
      if (isNaN(CheckNum) == false) {
        setValue(thisObj, "txtCheckNum", zeroPad(CheckNum, 6))
      }
      else {
        if (CheckNum.slice(0, 1).toUpperCase() == 'S' && (isNaN(CheckNum.slice(1, 5)) == false && CheckNum.slice(1, 5).length > 0)) {
          setValue(thisObj, "txtCheckNum", "S" + zeroPad(CheckNum.slice(1, 5), 5))
        }
        else {
          setData(thisObj, "checktxtCheckNum", "Invalid");
        }
      }
    }
  }
  thisObj.ontxtCheckNumBlur = ontxtCheckNumBlur
  const ontxtCheckNumChange = () => {
    let CheckNum = getValue(thisObj, 'txtCheckNum')
    setValue(thisObj, "txtCheckNum", validateTextBoxforchecknumber(CheckNum))
  };
  thisObj.ontxtCheckNumChange = ontxtCheckNumChange

  const ontxtDeliveryAgreeBlur = () => {
    let DeliveryAgree = getValue(thisObj, "txtDeliveryAgree")
    if (DeliveryAgree != undefined && DeliveryAgree != "" && DeliveryAgree != null) {
      setValue(thisObj, "txtDeliveryAgree", zeroPad(DeliveryAgree, 7))
    }
  }
  thisObj.ontxtDeliveryAgreeBlur = ontxtDeliveryAgreeBlur

  const ontxtDeliveryAgreeChange = () => {
    let DeliveryAgree = getValue(thisObj, 'txtDeliveryAgree')
    setValue(thisObj, "txtDeliveryAgree", validateTextBox3(DeliveryAgree))
  };
  thisObj.ontxtDeliveryAgreeChange = ontxtDeliveryAgreeChange

  const ontxtReasonChange = () => {
    let ManualAcctDistMaint = getData(thisObj, 'manualAcctDistMaint');
    if (ManualAcctDistMaint.cmdOkCaption == "Reversal") {
      let Reason = getValue(thisObj, 'txtReason')
      setValue(thisObj, "txtReason", validateTextBox2(Reason))
      if (getValue(thisObj, 'txtReason') != "" && getValue(thisObj, 'txtReason') != null && getValue(thisObj, 'txtReason') != " ") {
        show(thisObj, "btnAdd");
        hide(thisObj, "lblReason");

      }
      else {
        hide(thisObj, "btnAdd",false);
        show(thisObj, "lblReason");
      }
    }
  };
  thisObj.ontxtReasonChange = ontxtReasonChange

  const zeroPad = (num, places) => String(num).padStart(places, '0')
  //validating controls
  const validateTextBox2 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      let test = data[i].charCodeAt(0)
      if ((data[i].charCodeAt(0) != 38 && data[i].charCodeAt(0) != 39)) {
        res += data[i]
      }
    }
    return res;
  }
  const validateTextBox1 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || data[i].charCodeAt(0) == 8) && (data[i].charCodeAt(0) != 22)) {
        res += data[i]
      }
    }
    return res;
  }
  const validateTextBox3 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 8) || (data[i].charCodeAt(0) == 3) || (data[i].charCodeAt(0) == 22) || (data[i].charCodeAt(0) == 24)) {
        res += data[i]
      }
    }
    return res;
  }
  const validateTextBoxforchecknumber = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 8) || ((res.match(/S/g) || []).length <= 0 && (data[i].charCodeAt(0) == 83 || data[i].charCodeAt(0) == 115))) || data[i].charCodeAt(0) == 3 && data[i].charCodeAt(0) != 22) {
        res += data[i]
      }
    }
    return res;
  }

  const validateTextBoxforamount = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 8) || (res.indexOf(".") == -1 && data[i].charCodeAt(0) == 46) || (res.indexOf("-") == -1 && data[i].charCodeAt(0) == 45)) && data[i].charCodeAt(0) != 22) {
        res += data[i]
      }
    }
    return res;
  }
  const validateTextBoxforQuantity = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 8) || (res.indexOf("-") == -1 && data[i].charCodeAt(0) == 45)) && data[i].charCodeAt(0) != 22) {
        res += data[i]
      }
    }
    return res;
  }
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ManualAcctDistMaint*/}

              {/* END_USER_CODE-USER_BEFORE_ManualAcctDistMaint*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxManualAcctDistMaint*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxManualAcctDistMaint*/}

              <GroupBoxWidget
                conf={state.grpbxManualAcctDistMaint}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtLocation*/}

                {/* END_USER_CODE-USER_BEFORE_txtLocation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLocation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLocationVal}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLocation*/}

                {/* END_USER_CODE-USER_AFTER_txtLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtAccountNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtAccountNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccountNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAccountNum*/}

                {/* END_USER_CODE-USER_AFTER_txtAccountNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtResponsibility*/}

                {/* END_USER_CODE-USER_BEFORE_txtResponsibility*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtResponsibility}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtResponsibility*/}

                {/* END_USER_CODE-USER_AFTER_txtResponsibility*/}
                {/* START_USER_CODE-USER_BEFORE_txtDepartment*/}

                {/* END_USER_CODE-USER_BEFORE_txtDepartment*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDepartment}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDepartment*/}

                {/* END_USER_CODE-USER_AFTER_txtDepartment*/}
                {/* START_USER_CODE-USER_BEFORE_txtSubDepartment*/}

                {/* END_USER_CODE-USER_BEFORE_txtSubDepartment*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSubDepartment}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSubDepartment*/}

                {/* END_USER_CODE-USER_AFTER_txtSubDepartment*/}
                {/* START_USER_CODE-USER_BEFORE_txtChargeLocation*/}

                {/* END_USER_CODE-USER_BEFORE_txtChargeLocation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtChargeLocation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtChargeLocationVal}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtChargeLocation*/}

                {/* END_USER_CODE-USER_AFTER_txtChargeLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtAmount*/}

                {/* END_USER_CODE-USER_BEFORE_txtAmount*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAmount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAmount*/}

                {/* END_USER_CODE-USER_AFTER_txtAmount*/}
                {/* START_USER_CODE-USER_BEFORE_txtQuantity*/}

                {/* END_USER_CODE-USER_BEFORE_txtQuantity*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtQuantity}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtQuantity*/}

                {/* END_USER_CODE-USER_AFTER_txtQuantity*/}
                {/* START_USER_CODE-USER_BEFORE_radioSourceType*/}

                {/* END_USER_CODE-USER_BEFORE_radioSourceType*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioSourceType}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioSourceType*/}

                {/* END_USER_CODE-USER_AFTER_radioSourceType*/}
                {/* START_USER_CODE-USER_BEFORE_txtSC95InspNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtSC95InspNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSC95InspNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSC95InspNum*/}

                {/* END_USER_CODE-USER_AFTER_txtSC95InspNum*/}
                {/* START_USER_CODE-USER_BEFORE_txt1007SettleNum*/}

                {/* END_USER_CODE-USER_BEFORE_txt1007SettleNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007SettleNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1007SettleNum*/}

                {/* END_USER_CODE-USER_AFTER_txt1007SettleNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtShipLocation*/}

                {/* END_USER_CODE-USER_BEFORE_txtShipLocation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtShipLocation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtShipLocationVal}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtShipLocation*/}

                {/* END_USER_CODE-USER_AFTER_txtShipLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtTransferNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtTransferNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTransferNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTransferNum*/}

                {/* END_USER_CODE-USER_AFTER_txtTransferNum*/}
                {/* START_USER_CODE-USER_BEFORE_ddTransferType*/}

                {/* END_USER_CODE-USER_BEFORE_ddTransferType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddTransferType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddTransferType*/}

                {/* END_USER_CODE-USER_AFTER_ddTransferType*/}
                {/* START_USER_CODE-USER_BEFORE_txtContractNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtContractNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContractNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContractNum*/}

                {/* END_USER_CODE-USER_AFTER_txtContractNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtDeliveryAgree*/}

                {/* END_USER_CODE-USER_BEFORE_txtDeliveryAgree*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDeliveryAgree}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDeliveryAgree*/}

                {/* END_USER_CODE-USER_AFTER_txtDeliveryAgree*/}
                {/* START_USER_CODE-USER_BEFORE_txtWhseRcptNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtWhseRcptNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWhseRcptNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWhseRcptNum*/}

                {/* END_USER_CODE-USER_AFTER_txtWhseRcptNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeInspNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeInspNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeInspNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeInspNum*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeInspNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeSettleNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeSettleNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeSettleNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeSettleNum*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeSettleNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtCheckNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtCheckNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheckNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCheckNum*/}

                {/* END_USER_CODE-USER_AFTER_txtCheckNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtReason*/}

                {/* END_USER_CODE-USER_BEFORE_txtReason*/}
                <LabelWidget
                  values={values}
                  conf={state.lblReason}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtReason}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtReason*/}

                {/* END_USER_CODE-USER_AFTER_txtReason*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxManualAcctDistMaint*/}

              {/* END_USER_CODE-USER_AFTER_grpbxManualAcctDistMaint*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblPostedOn*/}

                {/* END_USER_CODE-USER_BEFORE_lblPostedOn*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPostedOn}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPostedOn*/}

                {/* END_USER_CODE-USER_AFTER_lblPostedOn*/}
                {/* START_USER_CODE-USER_BEFORE_lblPostedOnValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblPostedOnValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPostedOnValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPostedOnValue*/}

                {/* END_USER_CODE-USER_AFTER_lblPostedOnValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ManualAcctDistMaint*/}

              {/* END_USER_CODE-USER_AFTER_ManualAcctDistMaint*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_ManualAcctDistMaint
);
