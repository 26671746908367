/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
  goTo,
  isEnabled, disable
} from "../../shared/WindowImports";

import "./PremiumDeductionVendorProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_PremiumDeductionVendorProfile(
  props
) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PremiumDeductionVendorProfile",
    windowName: "PremiumDeductionVendorProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.PremiumDeductionVendorProfile",
    // START_USER_CODE-USER_PremiumDeductionVendorProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Premium Deduction Vendor Profile",
      scrCode: "PN0120C",
    },
    // END_USER_CODE-USER_PremiumDeductionVendorProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxPremiumDeductionVendorProfile",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxPremiumDeductionVendorProfile",
      Label: "Remit To:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitTo_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxPremiumDeductionVendorProfile",
      Label: "Buying Point:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES
ReaOnly:true,
Enabled:false,
      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    txtPremDeductCode: {
      name: "txtPremDeductCode",
      type: "TextBoxWidget",
      parent: "grpbxPremiumDeductionVendorProfile",
      Label: "Premium Deduction Code:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremDeductCode_PROPERTIES
      ReaOnly:true,
      Enabled:false,
      // END_USER_CODE-USER_txtPremDeductCode_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxPremiumDeductionVendorProfile",
      Label: "Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxPremiumDeductionVendorProfile",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    grpbxPremiumDeductionVendorProfile: {
      name: "grpbxPremiumDeductionVendorProfile",
      type: "GroupBoxWidget",
      parent: "PremiumDeductionVendorProfile",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPremiumDeductionVendorProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxPremiumDeductionVendorProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "PremiumDeductionVendorProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    const parentFormData = getData(thisObj, 'frmPremiumDeductionVendorProfile_PDSP');
    if (parentFormData) {
      if (parentFormData.cmdOk_Caption) {
        setLabelText('btnOk', parentFormData.cmdOk_Caption);
      }
      if (parentFormData.txtVendor_enabled === false) {
        disable(thisObj, 'txtVendor');
      }
      if (parentFormData.lblBuyingPoint_describtion) {
        setValue(thisObj, 'txtBuyingPoint', parentFormData.lblBuyingPoint_describtion);
      }
      if (parentFormData.lblPDCode_describtion) {
        setValue(thisObj, 'txtPremDeductCode', parentFormData.lblPDCode_describtion);
      }
    }
  }, [getData(thisObj, 'frmPremiumDeductionVendorProfile_PDSP')]);

  useEffect(() => {
    const vendorDetails = getData(thisObj, 'vendorDetails');
    if (vendorDetails) {
      setValue(thisObj, 'txtVendor', vendorDetails?.VendorNumber || '');
      fillRemitListForVendor(vendorDetails?.VendorNumber);
      setData(thisObj, 'vendorDetails', null)
    }
  }, [getData(thisObj, 'vendorDetails')]);

  // START_USER_CODE-USER_METHODS
  const setLabelText = (widgetId, value) => {
    const elementMainParent = document.getElementsByClassName(widgetId);
    elementMainParent[elementMainParent.length - 1].firstChild.firstChild.innerHTML = value;
  };

  const getLabelText = (widgetId) => {
    const elementMainParent = document.getElementsByClassName(widgetId);
    return elementMainParent[elementMainParent.length - 1]
      .firstChild.firstChild.innerHTML;
  };

  const formLoad = () => {
    fillRemitToList();
  };

  const fillRemitToList = async (vendorValue = '') => {
    try {
      const vendorId = vendorValue || getValue(thisObj, 'txtVendor') || '';
      if (!vendorId) {
        return true;
      }
      const vendorResponse = await ContractManagementService.RetrieveVendorByNumber(vendorId);
      if (!vendorResponse?.length) {
        return false;
      }

      const vendorName = vendorResponse[0]?.name;
      setValue(thisObj, 'txtVndr', vendorName || '');
      const remitResponse = await ContractManagementService.RetieveRemitDetails(vendorId);

      const remitDetailsList = [];
      remitResponse.forEach(remit => {
        const remitOptionItem = {
          key: remit.number.toString(),
          description: remit.number + ' - ' + remit.name + ' - ' + remit.city + ', ' + remit.state
        };
        remitDetailsList.push(remitOptionItem);
      });

      thisObj.setState(current => {
        return {
          ...current,
          ddRemitTo: {
            ...state['ddRemitTo'],
            valueList: remitDetailsList
          }
        }
      });

      if (remitDetailsList.length) {
        setValue(thisObj, 'ddRemitTo', remitDetailsList[0].key);
      }
      return true;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillRemitToList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const fillRemitListForVendor = async (vendorTxtValue) => {
    const remitListLoaded = await fillRemitToList(vendorTxtValue);
    if (!remitListLoaded) {
      const initialVendorTxt = getValue(thisObj, 'txtVendor');
      setValue(thisObj, 'txtVendor', initialVendorTxt);
    }
  };

  const formValid = () => {
    try {
      let isFormValid = true;
      if (isEnabled(thisObj, 'txtVendor')) {
        const remitList = thisObj.state['ddRemitTo'].valueList.filter(item => Boolean(item.key));
        if (!remitList.length) {
          showMessage(thisObj, 'A valid Vendor is required.');
          document.getElementById('txtVendor').focus();
          document.getElementById('txtVendor').select();
          isFormValid = false;
        }
      }
      return isFormValid;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during formValid: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const onbtnVendorClick = () => {
    try {
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click");
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnVendorClick: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  thisObj.onbtnVendorClick = onbtnVendorClick;

  const ontxtVendorBlur = () => {
    fillRemitListForVendor();
  };

  thisObj.ontxtVendorBlur = ontxtVendorBlur;

  const onbtnOkClick = async () => {
    if (!formValid()) {
      return;
    }
    try {
      const parentFormData = getData(thisObj, 'frmPremiumDeductionVendorProfile_PDSP');
      const buyPtId = parentFormData?.lblBuyingPoint_Id;
      const pdCode = parentFormData?.lblPDCode_Id;
      const pdVendor = getValue(thisObj, 'txtVendor');
      const pdRemit = getValue(thisObj, 'ddRemitTo');

      const body = {
        buy_pt_id: buyPtId,
        pd_code: pdCode,
        pd_vendor: pdVendor,
        pd_remit: pdRemit
      }

      const okButtonText = getLabelText('btnOk');
      if (okButtonText?.toUpperCase() === 'ADD') {
        body.add_user = sessionStorage.getItem('userid');
        body.add_date_time = (new Date(new Date - (new Date().getTimezoneOffset() * 60000))).toISOString();
        const res = await SystemMaintenanceMasterManagementService.CreatePremiumDeductionVendor(body);
        if (res.status !== 200) {
          const errorMessageSplitted = res?.message?.split('Error Meesage : ')?.length === 2 ? res.message.split('Error Meesage : ')[1] : '';
          const errorMessage = `An error occured while Adding the database.
            Please verify that your data is correct and retry.
            Contact your system administrator if this problem persist.
            ${errorMessageSplitted ? 'Error: ' : ''}
            ${errorMessageSplitted}`;
          showMessage(thisObj, errorMessage);
          return;
        }
        const vendorName = getValue(thisObj, 'txtVndr') || '';
        const newVendor = {
          key: pdVendor,
          description: pdVendor + ' - ' + vendorName.toUpperCase()
        };

        setData(thisObj, "PremiumDeductionNewVendor", newVendor);
        setData(thisObj, "frmPremiumDeductionScheduleSetupEnableDisableControls", true);
        document.getElementById("SystemMaintenanceMasterManagement_PremiumDeductionVendorProfilePopUp").childNodes[0].click();
      } else {
        showMessage(thisObj, 'Update functionality not implemented yet!');
      }
    }
    catch (err) {
      if (err instanceof EvalError)
        showMessage(thisObj, err.message);
      else
        showMessage(thisObj, 'Exception in Ok button Click');
    }
  };

  thisObj.onbtnOkClick = onbtnOkClick;

  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_PremiumDeductionVendorProfilePopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError)
        showMessage(thisObj, err.message);
      else
        showMessage(thisObj, 'Exception in Cancel button Click');
    }
  };

  thisObj.onbtnCancelClick = onbtnCancelClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PremiumDeductionVendorProfile*/}

              {/* END_USER_CODE-USER_BEFORE_PremiumDeductionVendorProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPremiumDeductionVendorProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPremiumDeductionVendorProfile*/}

              <GroupBoxWidget
                conf={state.grpbxPremiumDeductionVendorProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBuyingPoint}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_txtBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txtPremDeductCode*/}

                {/* END_USER_CODE-USER_BEFORE_txtPremDeductCode*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPremDeductCode}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPremDeductCode*/}

                {/* END_USER_CODE-USER_AFTER_txtPremDeductCode*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                {/* START_USER_CODE-USER_BEFORE_ddRemitTo*/}

                {/* END_USER_CODE-USER_BEFORE_ddRemitTo*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRemitTo}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRemitTo*/}

                {/* END_USER_CODE-USER_AFTER_ddRemitTo*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPremiumDeductionVendorProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPremiumDeductionVendorProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_PremiumDeductionVendorProfile*/}

              {/* END_USER_CODE-USER_AFTER_PremiumDeductionVendorProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_PremiumDeductionVendorProfile
);
