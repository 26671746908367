/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_HighMoistureSetup from "./HighMoistureSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("HighMoistureSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_HighMoistureSetup />);
    });
  });
  afterEach(cleanup);
  test("is HighMoistureSetup Loads Successfully", () => {
    expect(screen.getByText("HighMoistureSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for HighMoistureSetup", () => {
    // START_USER_CODE-USER_HighMoistureSetup_Custom_Test_Case
    // END_USER_CODE-USER_HighMoistureSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_HighMoistureSetup />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:HighMoistureSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClear(Button Widget) Test Cases", async () => {
    const btnClear = screen.getByTestId("btnClear");
    expect(btnClear).toBeInTheDocument;
    expect(btnClear.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:HighMoistureSetup_btnClear")
    );
  });
  test("Custom Test Cases for btnClear", () => {
    // START_USER_CODE-USER_btnClear_TEST
    // END_USER_CODE-USER_btnClear_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:HighMoistureSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:HighMoistureSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:HighMoistureSetup_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:HighMoistureSetup_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("gridHighMoistureSetup(Grid Widget) Test Cases", async () => {
    let gridHighMoistureSetup = screen.getByTestId(
      "gridHighMoistureSetup"
    );
    let gridHighMoistureSetupbtn =
      gridHighMoistureSetup.nextElementSibling.firstElementChild;
    gridHighMoistureSetup =
      gridHighMoistureSetup.parentElement.parentElement.parentElement;
    expect(gridHighMoistureSetup.tagName).toBe("DIV");
    expect(gridHighMoistureSetup.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:HighMoistureSetup_gridHighMoistureSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridHighMoistureSetup", () => {
    // START_USER_CODE-USER_gridHighMoistureSetup_TEST
    // END_USER_CODE-USER_gridHighMoistureSetup_TEST
  });
  test("grpbxHighMoisturSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxHighMoisturSetup = screen.getByTestId(
      "grpbxHighMoisturSetup"
    );
    expect(grpbxHighMoisturSetup.tagName).toBe("BUTTON");
    expect(grpbxHighMoisturSetup.type).toBe("button");
    expect(grpbxHighMoisturSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxHighMoisturSetup", () => {
    // START_USER_CODE-USER_grpbxHighMoisturSetup_TEST
    // END_USER_CODE-USER_grpbxHighMoisturSetup_TEST
  });
  test("gridHighMoistureSetup_txtcolGradeType(Grid Widget) Test Cases", async () => {
    let gridHighMoistureSetup_txtcolGradeType = screen.getByTestId(
      "gridHighMoistureSetup"
    );
    let gridHighMoistureSetup_txtcolGradeTypebtn =
      gridHighMoistureSetup_txtcolGradeType.nextElementSibling
        .firstElementChild;
    gridHighMoistureSetup_txtcolGradeType =
      gridHighMoistureSetup_txtcolGradeType.parentElement.parentElement
        .parentElement;
    expect(gridHighMoistureSetup_txtcolGradeType.tagName).toBe("DIV");
    expect(gridHighMoistureSetup_txtcolGradeType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:HighMoistureSetup_gridHighMoistureSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGradeType", () => {
    // START_USER_CODE-USER_txtcolGradeType_TEST
    // END_USER_CODE-USER_txtcolGradeType_TEST
  });
  test("gridHighMoistureSetup_txtcolHighGrade(Grid Widget) Test Cases", async () => {
    let gridHighMoistureSetup_txtcolHighGrade = screen.getByTestId(
      "gridHighMoistureSetup"
    );
    let gridHighMoistureSetup_txtcolHighGradebtn =
      gridHighMoistureSetup_txtcolHighGrade.nextElementSibling
        .firstElementChild;
    gridHighMoistureSetup_txtcolHighGrade =
      gridHighMoistureSetup_txtcolHighGrade.parentElement.parentElement
        .parentElement;
    expect(gridHighMoistureSetup_txtcolHighGrade.tagName).toBe("DIV");
    expect(gridHighMoistureSetup_txtcolHighGrade.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:HighMoistureSetup_gridHighMoistureSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolHighGrade", () => {
    // START_USER_CODE-USER_txtcolHighGrade_TEST
    // END_USER_CODE-USER_txtcolHighGrade_TEST
  });
  test("gridHighMoistureSetup_txtcolHighMoist(Grid Widget) Test Cases", async () => {
    let gridHighMoistureSetup_txtcolHighMoist = screen.getByTestId(
      "gridHighMoistureSetup"
    );
    let gridHighMoistureSetup_txtcolHighMoistbtn =
      gridHighMoistureSetup_txtcolHighMoist.nextElementSibling
        .firstElementChild;
    gridHighMoistureSetup_txtcolHighMoist =
      gridHighMoistureSetup_txtcolHighMoist.parentElement.parentElement
        .parentElement;
    expect(gridHighMoistureSetup_txtcolHighMoist.tagName).toBe("DIV");
    expect(gridHighMoistureSetup_txtcolHighMoist.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:HighMoistureSetup_gridHighMoistureSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolHighMoist", () => {
    // START_USER_CODE-USER_txtcolHighMoist_TEST
    // END_USER_CODE-USER_txtcolHighMoist_TEST
  });
  test("gridHighMoistureSetup_txtcolPeanutType(Grid Widget) Test Cases", async () => {
    let gridHighMoistureSetup_txtcolPeanutType = screen.getByTestId(
      "gridHighMoistureSetup"
    );
    let gridHighMoistureSetup_txtcolPeanutTypebtn =
      gridHighMoistureSetup_txtcolPeanutType.nextElementSibling
        .firstElementChild;
    gridHighMoistureSetup_txtcolPeanutType =
      gridHighMoistureSetup_txtcolPeanutType.parentElement.parentElement
        .parentElement;
    expect(gridHighMoistureSetup_txtcolPeanutType.tagName).toBe("DIV");
    expect(gridHighMoistureSetup_txtcolPeanutType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:HighMoistureSetup_gridHighMoistureSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutType", () => {
    // START_USER_CODE-USER_txtcolPeanutType_TEST
    // END_USER_CODE-USER_txtcolPeanutType_TEST
  });
  test("gridHighMoistureSetup_txtcolResult(Grid Widget) Test Cases", async () => {
    let gridHighMoistureSetup_txtcolResult = screen.getByTestId(
      "gridHighMoistureSetup"
    );
    let gridHighMoistureSetup_txtcolResultbtn =
      gridHighMoistureSetup_txtcolResult.nextElementSibling
        .firstElementChild;
    gridHighMoistureSetup_txtcolResult =
      gridHighMoistureSetup_txtcolResult.parentElement.parentElement
        .parentElement;
    expect(gridHighMoistureSetup_txtcolResult.tagName).toBe("DIV");
    expect(gridHighMoistureSetup_txtcolResult.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:HighMoistureSetup_gridHighMoistureSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolResult", () => {
    // START_USER_CODE-USER_txtcolResult_TEST
    // END_USER_CODE-USER_txtcolResult_TEST
  });
  test("txtHighGrade(Textbox Widget) Test Cases", async () => {
    const txtHighGrade = screen.getByTestId("txtHighGrade");
    expect(txtHighGrade.tagName).toBe("INPUT");
    expect(txtHighGrade.type).toBe("text");
    expect(txtHighGrade.classList).toContain("textboxWidgetClass");
    expect(txtHighGrade.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:HighMoistureSetup_txtHighGrade")
    );
    await act(async () => {
      userEvent.type(txtHighGrade, "1");
    });
  });
  test("Custom Test Cases for txtHighGrade", () => {
    // START_USER_CODE-USER_txtHighGrade_TEST
    // END_USER_CODE-USER_txtHighGrade_TEST
  });
  test("txtHighMoist(Textbox Widget) Test Cases", async () => {
    const txtHighMoist = screen.getByTestId("txtHighMoist");
    expect(txtHighMoist.tagName).toBe("INPUT");
    expect(txtHighMoist.type).toBe("text");
    expect(txtHighMoist.classList).toContain("textboxWidgetClass");
    expect(txtHighMoist.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:HighMoistureSetup_txtHighMoist")
    );
    await act(async () => {
      userEvent.type(txtHighMoist, "1");
    });
  });
  test("Custom Test Cases for txtHighMoist", () => {
    // START_USER_CODE-USER_txtHighMoist_TEST
    // END_USER_CODE-USER_txtHighMoist_TEST
  });
  test("txtResult(Textbox Widget) Test Cases", async () => {
    const txtResult = screen.getByTestId("txtResult");
    expect(txtResult.tagName).toBe("INPUT");
    expect(txtResult.type).toBe("text");
    expect(txtResult.classList).toContain("textboxWidgetClass");
    expect(txtResult.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:HighMoistureSetup_txtResult")
    );
    await act(async () => {
      userEvent.type(txtResult, "1");
    });
  });
  test("Custom Test Cases for txtResult", () => {
    // START_USER_CODE-USER_txtResult_TEST
    // END_USER_CODE-USER_txtResult_TEST
  });
});
