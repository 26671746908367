import React,{useState,useCallback} from "react";
import Form from "react-bootstrap/Form";
import FormFile from "react-bootstrap/FormFile";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { onFileChange } from "../../../../common/ScreenInitialization";
import "./ImageWidget.scss";
import {checkInvalid,getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility,
  getTooltip} from "../ParentWidgetFunc";

function ImageWidget(props) {
  let conf = props.conf;
  let screenConf = props.screenConf;
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //method to remove dynamic image
 const removeImage = (e, value) => {
    let event = {
      type: "change",
      target: {
        name: e.target.name,
        id: e.target.id,
        value: [...value.splice(0, 1)]
      }
    };
    props.onChange(event);
    forceUpdate();
  };

  //method to convert static image into b64data string and update widget value
 const handleOnLoad = e => {
    let base64String;
    let img = e.target;
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    ctx.drawImage(img, 0, 0);
    base64String = canvas.toDataURL();
    let fileObj = {
      base64data: base64String,
      data: base64String.split("base64,")[1],
      fileType: "image",
      value: conf.value.source.split("/")[1]
    };
    props.values[conf.name] = [fileObj];
  };

    const value = props.values[conf.name];
    let imageWidget;

    if (conf.widgetState === "Static") {
      imageWidget = (
        <div className="imgPreview" id={conf.name}>
          <img
          	alt={conf.AltText}
            src={require(`../../../../${conf.value.source}`)}
            onLoad={handleOnLoad}
            tabIndex="0"
          ></img>
        </div>
      );
    }

    if (conf.widgetState === "Dynamic") {
      if (
        props.values[conf.name].length === 1 &&
        !props.errors[conf.name]
      ) {
        imageWidget = (
          <div className="imgPreview">
            <img
              alt={conf.AltText}
              src={props.values[conf.name][0].b64data}
              type={props.values[conf.name][0].b64fileType}
              tabIndex="0"
            ></img>
            <Button
              variant="link"
              className="text-danger text-decoration-none remove-file eamimgRemoveIcon"
              onClick={e => removeImage(e, value)}
              tabIndex="0"
              aria-label="Please click here to remove the image"
            >
              <i className="fa fa-times-circle"></i>
            </Button>
          </div>
        );
      } else {
        imageWidget = (
          <React.Fragment>
            <FormFile.Input
              id={conf.name}
              name={conf.name}
              disabled={!conf.Enabled}
              required={conf.Mandatory}
              accept=".png,.jpeg,.jpg,.gif,.svg"
              onChange={e =>
                onFileChange(e, value, conf, props.onChange)
              }
              onBlur={props.onBlur}
            />
            <Form.Control.Feedback
              className={checkInvalid(props,conf) ? "d-block" : "d-none"}
              type="invalid"
              aria-live="polite" role="alert"
            >
              {props.errors[conf.name]}
            </Form.Control.Feedback>
          </React.Fragment>
        );
      }
    }

    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
		title={getTooltip(conf)}
		role="tooltip"
    data-testid={conf.name} 
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
          >
            {conf.Label}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>{imageWidget}</Col>
          ) : (
            <React.Fragment> {imageWidget}</React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default ImageWidget;
