/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  hide,
  setData,
  setValue,
  enable,
  disable,
  getData,
  getValue,
  show,
  goTo,
  getSelectedRowNumber,
} from "../../shared/WindowImports";

import "./DelvSettleSearch.scss";

// START_USER_CODE-USER_IMPORTS
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_DelvSettleSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DelvSettleSearch",
    windowName: "DelvSettleSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.DelvSettleSearch",
    // START_USER_CODE-USER_DelvSettleSearch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Delivery Settle Search",
      scrCode: "PN1180C",
    },
    // END_USER_CODE-USER_DelvSettleSearch_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Search",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCreateDelvSettlement: {
      name: "btnCreateDelvSettlement",
      type: "ButtonWidget",
      parent: "grpbxDelvSettlementList",
      Label: "Create Delivery Settlement",
      CharWidth: "52",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreateDelvSettlement_PROPERTIES

      // END_USER_CODE-USER_btnCreateDelvSettlement_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "grpbxDelvSettlementList",
      Label: "Open",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxDetails1",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    col100PctValue: {
      name: "col100PctValue",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "100% Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col100PctValue_PROPERTIES

      // END_USER_CODE-USER_col100PctValue_PROPERTIES
    },
    colAgreeNum: {
      name: "colAgreeNum",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "Agree #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreeNum_PROPERTIES

      // END_USER_CODE-USER_colAgreeNum_PROPERTIES
    },
    colAgreeType: {
      name: "colAgreeType",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "Agree",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreeType_PROPERTIES

      // END_USER_CODE-USER_colAgreeType_PROPERTIES
    },
    colConvValue: {
      name: "colConvValue",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "Conv. Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colConvValue_PROPERTIES

      // END_USER_CODE-USER_colConvValue_PROPERTIES
    },
    colGen: {
      name: "colGen",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGen_PROPERTIES

      // END_USER_CODE-USER_colGen_PROPERTIES
    },
    colInspNum: {
      name: "colInspNum",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "Insp #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInspNum_PROPERTIES

      // END_USER_CODE-USER_colInspNum_PROPERTIES
    },
    colLoc: {
      name: "colLoc",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "Loc",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoc_PROPERTIES

      // END_USER_CODE-USER_colLoc_PROPERTIES
    },
    colNetWeight: {
      name: "colNetWeight",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "Net Weight",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetWeight_PROPERTIES

      // END_USER_CODE-USER_colNetWeight_PROPERTIES
    },
    colPnutType: {
      name: "colPnutType",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "Pnut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutType_PROPERTIES

      // END_USER_CODE-USER_colPnutType_PROPERTIES
    },
    colPnutVariety: {
      name: "colPnutVariety",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "Pnut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutVariety_PROPERTIES

      // END_USER_CODE-USER_colPnutVariety_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colSettleNum: {
      name: "colSettleNum",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "Settle #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSettleNum_PROPERTIES

      // END_USER_CODE-USER_colSettleNum_PROPERTIES
    },
    colStatus: {
      name: "colStatus",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStatus_PROPERTIES

      // END_USER_CODE-USER_colStatus_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridDelvSettlementList",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    ddEdibleOil: {
      name: "ddEdibleOil",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "EdibleOil:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEdibleOil_PROPERTIES

      // END_USER_CODE-USER_ddEdibleOil_PROPERTIES
    },
    ddGeneration: {
      name: "ddGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGeneration_PROPERTIES

      // END_USER_CODE-USER_ddGeneration_PROPERTIES
    },
    ddGradePricingMethod: {
      name: "ddGradePricingMethod",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Grade Pricing Method:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGradePricingMethod_PROPERTIES

      // END_USER_CODE-USER_ddGradePricingMethod_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Peanut Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddSegType: {
      name: "ddSegType",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Seg Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegType_PROPERTIES

      // END_USER_CODE-USER_ddSegType_PROPERTIES
    },
    ddStatus: {
      name: "ddStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "Status:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddStatus_PROPERTIES

      // END_USER_CODE-USER_ddStatus_PROPERTIES
    },
    ddVendorNum: {
      name: "ddVendorNum",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "Vendor #:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVendorNum_PROPERTIES

      // END_USER_CODE-USER_ddVendorNum_PROPERTIES
    },
    gridDelvSettlementList: {
      name: "gridDelvSettlementList",
      type: "GridWidget",
      parent: "grpbxDelvSettlementList",
      gridCellsOrder:
        "txtcolLoc,txtcolInspNum,txtcolSettleNum,txtcolStatus,txtcolAgreeNum,txtcolAgreeType,txtcolVendor,txtcolPnutType,txtcolPnutVariety,txtcolGen,txtcolSeg,txtcolNetWeight,txtcol100PctValue,txtcolConvValue",
      Pagination: false,
      HasLabel: false,
      ColsForTabLandscape: "13",
      HasLabel: false,
      Cols: "13",
      ColsForTabPotrait: "13",
      ColsForLargeDesktop: "13",
      Height: "",
      Width: "",
      ProcessingText: "griddeliverySettlements",
      needGridChange: true,
      isExpandable: true,
      defaultExpand: false,
      isMultiSelect: false,
      expandedRows: [],
      pushExpandedRows: (isExpanded, row) => {
        if (isExpanded) {
          states.gridDelvSettlementList.expandedRows.push(row)
        }
        else {
          states.gridDelvSettlementList.expandedRows = states.gridDelvSettlementList.expandedRows.filter(rows => rows.rowID$ !== row.rowID$)
        }
      },
      expandedCompTemplate: ({ data }) => (
        <div>
          {data.expArray ? (
            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
              {data.expArray.map((elem, i) =>
                <div className="py-3 expandable-area col-sm-12 rdt_TableRow selectable" key={"Row" + i}>
                  <div className={"row" + " rdt_ExpanderRow ExpandRow"} key={"ExpanderRow" + i} >
                    <div className="extraRowCell_0 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_1 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_2 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_3 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_4 ch-3" key="expandCell1">
                      <label> {elem.AgreeDetails}</label>
                    </div>
                    <div className="extraRowCell_5 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_6 ch-3" key="expandCell1">
                      <label> {elem.VendorDetails}</label>
                    </div>
                    <div className="extraRowCell_7 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_8 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_9 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_10 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_11 ch-3" key="expandCell2">
                    </div>
                    <div className="extraRowCell_12 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_13 ch-3" key="expandCell1">
                    </div>

                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
      // START_USER_CODE-USER_gridDelvSettlementList_PROPERTIES

      // END_USER_CODE-USER_gridDelvSettlementList_PROPERTIES
    },
    grpbxDelvSettlementList: {
      name: "grpbxDelvSettlementList",
      type: "GroupBoxWidget",
      parent: "grpbxDelvSettleSearch",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDelvSettlementList_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvSettlementList_PROPERTIES
    },
    grpbxDetails1: {
      name: "grpbxDetails1",
      type: "GroupBoxWidget",
      parent: "grpbxDelvSettleSearch",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDetails1_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails1_PROPERTIES
    },
    grpbxDetails2: {
      name: "grpbxDetails2",
      type: "GroupBoxWidget",
      parent: "grpbxDelvSettleSearch",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxDetails2_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails2_PROPERTIES
    },
    lblCropYear: {
      name: "lblCropYear",
      type: "LabelWidget",
      parent: "grpbxDelvSettlementList",
      Label: "Crop Year:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear_PROPERTIES

      // END_USER_CODE-USER_lblCropYear_PROPERTIES
    },
    txt100PctValue: {
      name: "txt100PctValue",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "100% Value",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt100PctValue_PROPERTIES

      // END_USER_CODE-USER_txt100PctValue_PROPERTIES
    },
    txtAgreementNum: {
      name: "txtAgreementNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Agreement #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAgreementNum_PROPERTIES

      // END_USER_CODE-USER_txtAgreementNum_PROPERTIES
    },
    txtcol100PctValue: {
      name: "txtcol100PctValue",
      type: "TextBoxWidget",
      colName: "col100PctValue",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol100PctValue_PROPERTIES

      // END_USER_CODE-USER_txtcol100PctValue_PROPERTIES
    },
    txtcolAgreeNum: {
      name: "txtcolAgreeNum",
      type: "TextBoxWidget",
      colName: "colAgreeNum",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreeNum_PROPERTIES

      // END_USER_CODE-USER_txtcolAgreeNum_PROPERTIES
    },
    txtcolAgreeType: {
      name: "txtcolAgreeType",
      type: "TextBoxWidget",
      colName: "colAgreeType",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreeType_PROPERTIES

      // END_USER_CODE-USER_txtcolAgreeType_PROPERTIES
    },
    txtcolConvValue: {
      name: "txtcolConvValue",
      type: "TextBoxWidget",
      colName: "colConvValue",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolConvValue_PROPERTIES

      // END_USER_CODE-USER_txtcolConvValue_PROPERTIES
    },
    txtcolGen: {
      name: "txtcolGen",
      type: "TextBoxWidget",
      colName: "colGen",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGen_PROPERTIES

      // END_USER_CODE-USER_txtcolGen_PROPERTIES
    },
    txtcolInspNum: {
      name: "txtcolInspNum",
      type: "TextBoxWidget",
      colName: "colInspNum",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInspNum_PROPERTIES

      // END_USER_CODE-USER_txtcolInspNum_PROPERTIES
    },
    txtcolLoc: {
      name: "txtcolLoc",
      type: "TextBoxWidget",
      colName: "colLoc",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoc_PROPERTIES

      // END_USER_CODE-USER_txtcolLoc_PROPERTIES
    },
    txtcolNetWeight: {
      name: "txtcolNetWeight",
      type: "TextBoxWidget",
      colName: "colNetWeight",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNetWeight_PROPERTIES

      // END_USER_CODE-USER_txtcolNetWeight_PROPERTIES
    },
    txtcolPnutType: {
      name: "txtcolPnutType",
      type: "TextBoxWidget",
      colName: "colPnutType",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutType_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutType_PROPERTIES
    },
    txtcolPnutVariety: {
      name: "txtcolPnutVariety",
      type: "TextBoxWidget",
      colName: "colPnutVariety",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutVariety_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutVariety_PROPERTIES
    },
    txtcolSeg: {
      name: "txtcolSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg_PROPERTIES
    },
    txtcolSettleNum: {
      name: "txtcolSettleNum",
      type: "TextBoxWidget",
      colName: "colSettleNum",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSettleNum_PROPERTIES

      // END_USER_CODE-USER_txtcolSettleNum_PROPERTIES
    },
    txtcolStatus: {
      name: "txtcolStatus",
      type: "TextBoxWidget",
      colName: "colStatus",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolStatus_PROPERTIES
    },
    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridDelvSettlementList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtConvValue: {
      name: "txtConvValue",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Conv. Value",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConvValue_PROPERTIES

      // END_USER_CODE-USER_txtConvValue_PROPERTIES
    },
    txtDelvInspectNum: {
      name: "txtDelvInspectNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Delivery Inspect #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDelvInspectNum_PROPERTIES

      // END_USER_CODE-USER_txtDelvInspectNum_PROPERTIES
    },
    txtDelvSettleNum: {
      name: "txtDelvSettleNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Delivery Settle #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDelvSettleNum_PROPERTIES

      // END_USER_CODE-USER_txtDelvSettleNum_PROPERTIES
    },
    txtNetWeight: {
      name: "txtNetWeight",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Net Weight",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWeight_PROPERTIES

      // END_USER_CODE-USER_txtNetWeight_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVendorNum: {
      name: "txtVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtVendorNum_PROPERTIES
    },
    grpbxDelvSettleSearch: {
      name: "grpbxDelvSettleSearch",
      type: "GroupBoxWidget",
      parent: "DelvSettleSearch",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDelvSettleSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvSettleSearch_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "DelvSettleSearch",
      Height: "",
      Width: "",
      ColsForMobile: "6",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "WarehouseReceipts#DelvSettleProfile": {},
      },
      REVERSE: {
        "WarehouseReceipts#DelvSettleProfile": {},
      },
    },
  };
  const [loading, setLoading] = useState(true);
  let _buttonServices = {};
  let _buttonNavigation = {
    btnCreateDelvSettlement: {
      DEFAULT: ["WarehouseReceipts#DelvSettleProfile#DEFAULT#true#Click"],
    },
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    // btnClose: {
    //   DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    // },
    // btnOpen: {
    //   DEFAULT: ["WarehouseReceipts#DelvSettleProfile#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    //onreversalclick in profile 
    let Status = getData(thisObj, 'ClosingofDelvSettleProfile')
    if (Status == true) {
      onbtnSearchClick()
      setData(thisObj,"vendorelookupsettleprofile",'1')
    }
    setData(thisObj, 'ClosingofDelvSettleProfile', false);
    
    setValue(thisObj, "txtVendorNum", '')
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj, 'ClosingofDelvSettleProfile')]);

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let closingOfdeliverysettlementprofile = getData(thisObj, "deliverysettlementvendorclose")
    if (closingOfdeliverysettlementprofile === null || closingOfdeliverysettlementprofile != true) {
      let vendorOpen=getData(thisObj,"vendorelookupsettleprofile")
      if(vendorOpen!='1'){
        bFillRemitToList()
      }
      
    }
    setData(thisObj, "deliverysettlementvendorclose", false)
    setData(thisObj, 'gridDelvSettlementList', '')
    
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj, 'vendorDetails')]);
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, "ddLocation", '');
    setValue(thisObj, "ddVendorNum", '');
    setValue(thisObj, "ddPeanutType", '');
    setValue(thisObj, "ddSegType", '');
    setValue(thisObj, "ddPeanutVariety", '');
    setValue(thisObj, "ddGeneration", '');
    setValue(thisObj, "ddOleic", '');
    setValue(thisObj, "ddEdibleOil", '');
    setValue(thisObj, "ddStatus", '');
    setValue(thisObj, "ddGradePricingMethod", '');
    setValue(thisObj, 'lblCropYear', cropYearFromLS());
    setValue(thisObj,"txtVendorNum",'')
    // setData(thisObj, "txtNetWeight",'');
    // setData(thisObj, "txtConvValue",'');
    // setData(thisObj, "txt100PctValue",'');
    setData(thisObj, 'vendorDetails', null);
    Formload();
  }, []);


  let accessPermission = ''
  let GetAccessLevel = false;
  async function Formload() {
    bFillBuyingPointList(); // Method for binding Buying Points
    bFillAgreementType();//Method for binding Agreement Type
    bFillTypeList(); // Method for binding Peanut Type dropdown
    bFillSegmentList(); // Method for binding Segments
    bFillVarietyList(null, null);//Method for binding Peanut
    bFillGeneration();//Method for binding generation
    bFillOleicList(); // Method for binding Oleic values
    bFillEdibleOil();//Method for binding Edible Oil
    bFillStatus();//Method for binding Status
    bFillGradePricingMehtod();//Method for binding GradePricingMehtod
    if (await GetSecurityRoles('', 'D') == true) {
      accessPermission = 'D'
    } else if (await GetSecurityRoles('', 'U') == true) {
      accessPermission = 'U'
    } else if (await GetSecurityRoles('', 'I') == true) {
      accessPermission = 'I'
    }
    else {
      showMessage(thisObj, 'Invalid permission')
    }
    if (accessPermission === 'I') {
      disable(thisObj, 'btnCreateDelvSettlement')
    }
    setData(thisObj, 'accessPermission', accessPermission)
    setValue(thisObj, "txtVendorNum", '')
    setValue(thisObj, "txtVendor", '')
    hide(thisObj, "txtVendor", false)
    disable(thisObj, "txtVendor")
    disable(thisObj, 'btnOpen')
    disable(thisObj, 'txtNetWeight')
    disable(thisObj, 'txtConvValue')
    disable(thisObj, 'txt100PctValue')
    setData(thisObj, "closingOfdeliverySettleProfile", false)
    setLoading(false);
  }

  const GetSecurityRoles = async (sub_func_id, accessLevel) => {
    GetAccessLevel = false
    let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN1180', null, accessLevel, null)
    if (response != null && response != undefined) {
      if (response[0].permission == 'Y') {
        return true;
      }
    }
    return false;
  }
  const onBuyingPointChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = getValue(thisObj, "ddPeanutType")

      let pnut_type_id = null
      if (SelectedPeanutTypeValue !== "" && SelectedPeanutTypeValue !== undefined) {
        pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      }

      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = getValue(thisObj, "ddLocation")

      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety based on Buying Point and Peanut Type
      bFillVarietyList(buyingPointId, pnut_type_id)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Buying Point dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddLocationChange = onBuyingPointChange;
  const onbtnVendorClick = async () => {
    try{
     goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on Vendor click event")
      }
      return false
    }
  };
   thisObj.onbtnVendorClick = onbtnVendorClick;

  const onPeanutTypeChange = async () => {
    try {
      setLoading(true);
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = getValue(thisObj, "ddPeanutType", '')
      let pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = getValue(thisObj, "ddLocation", '')
      let buyingPointId = null
      if (SelectedBuyingPointValue !== null && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety based on Buying Point and Peanut Type
      setValue(thisObj, 'ddPeanutVariety', '')
      await bFillVarietyList(buyingPointId, pnut_type_id)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Peanut Type dropdown change event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  };
  thisObj.onddPeanutTypeChange = onPeanutTypeChange;

  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }
  // START_USER_CODE-USER_METHODS
  const bFillBuyingPointList = () => {
    let js = []
    js.push({ key: '', description: '>>>All Buying Points<<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1180', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + ConvertToPascalCase(data[i].buy_pt_name.trim()) }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js
          }
        }
      })
    })
  }
  //Method for binding Peanut Variety based on Buying Point and Peanut Type
  const bFillVarietyList = async(buyingPointId, pnut_type_id) => {
    let js = [];
    let obj = ({ key: '', description: '>>> All Varieties <<<' });
    js.push(obj)
    if (pnut_type_id !== null && pnut_type_id !== '') {
      ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null)
        .then(response => {
          let data = response;
          if (data !== null && data !== undefined && data.length > 0) {
            let js1 = [];
            for (var i = 0; i < data.length; i++) {
              if (!(js1.includes(data[i].pnut_variety_id))) {
                obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name }
                if (data[i].symbol_ind == "TRADEMARK") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '\u2122' }
                }
                if (data[i].symbol_ind == "REGISTERED TRADEMARK") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '\xAE' }
                }
                if (data[i].symbol_ind == "COPYRIGHT") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '\xA9' }
                }
                js.push(obj);
                js1.push(data[i].pnut_variety_id)
              }
            }
          }
        })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutVariety: {
          ...state["ddPeanutVariety"],
          valueList: js
        }
      }
    })
  }

  //Method for binding Edible Oil
  const bFillEdibleOil = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'E', description: 'Edible' })
    js.push({ key: 'O', description: 'Oil' })
    thisObj.setState(current => {
      return {
        ...current,
        ddEdibleOil: {
          ...state["ddEdibleOil"],
          valueList: js
        }
      }
    })
  }
  const bFillGradePricingMehtod = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'FULL', description: 'FULL' })
    js.push({ key: 'TKC', description: 'TKC' })
    thisObj.setState(current => {
      return {
        ...current,
        ddGradePricingMethod: {
          ...state["ddGradePricingMethod"],
          valueList: js
        }
      }
    })
  }

  // Method for Oleic values 
  const bFillOleicList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Oleic <<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })
  }

  // Method for Status values 
  const bFillStatus = () => {
    let js = []
    js.push({ key: '', description: '>>> All Status <<<' })
    js.push({ key: 'P', description: 'Pending' })
    js.push({ key: 'C', description: 'Complete' })
    js.push({ key: 'V', description: 'Void' })
    thisObj.setState(current => {
      return {
        ...current,
        ddStatus: {
          ...state["ddStatus"],
          valueList: js
        }
      }
    })
  }

  // Method for binding Seed Genration
  const bFillGeneration = () => {
    let js = []
    js.push({ key: '', description: '>>> All Generations <<<' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'C', description: 'Certified' })
    js.push({ key: 'R', description: 'Registered' })
    thisObj.setState(current => {
      return {
        ...current,
        ddGeneration: {
          ...state["ddGeneration"],
          valueList: js
        }
      }
    })
  }

  //Method for binding Agreement Type
  const bFillAgreementType = () => {
    let js = []
    js.push({ key: '', description: '>>> All Types <<<' })
    js.push({ key: 'P', description: 'Delv In' })
    js.push({ key: 'S', description: 'Delv Out' })
    thisObj.setState(current => {
      return {
        ...current,
        ddVendorNum: {
          ...state["ddVendorNum"],
          valueList: js
        }
      }
    })
  }

  //Method for binding Peanut Type dropdown
  const bFillTypeList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Types <<<' }) //Set All as default value
    ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      let data = response
      if (response !== undefined && response.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
          js.push(obj)
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })
    })
  }

  // Method for binding Segment list  
  const bFillSegmentList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Segs <<<' })
    js.push({ key: '1', description: '1' })
    js.push({ key: '2', description: '2' })
    js.push({ key: '3', description: '3' })

    thisObj.setState(current => {
      return {
        ...current,
        ddSegType: {
          ...state["ddSegType"],
          valueList: js
        }
      }
    })
  }

  const ontxtVendorNumChange = () => {
    try {
//PRB0054498 - Searching the vendors with vendor number from vendor textbox and convert to uppercase  
  let vendorNumber = txtVendorNum.value.toUpperCase()  
      if (vendorNumber.length == 6) {
        bFillRemitToList()
      } 
      else {
        setValue(thisObj, "txtVendor", '');
        hide(thisObj, "txtVendor", false);
      }
    } 
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } 
      else {
        showMessage(thisObj, "Error occurred during ontxtVendorNum change event");
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtVendorNumChange = ontxtVendorNumChange;  


  //seaching vendorname based on vendorname  
  const bFillRemitToList = () => {
    try {
      let isVendorLookup = false;
      let vendor_lookup = getData(thisObj, 'vendorDetails');
      let vendorNumber, vendorName;
      if (vendor_lookup !== null) {
        vendorNumber = vendor_lookup.VendorNumber;
        vendorName = vendor_lookup.vendorName;
        setValue(thisObj, "txtVendor", vendorName);
        setValue(thisObj, "txtVendorNum", vendorNumber);
        isVendorLookup = true;
        setData(thisObj, 'vendorDetails', null);
        hide(thisObj, 'txtVendor')
        return;
      }

      let vendorValueFromTextBox = getValue(thisObj, 'txtVendorNum');
      if (vendorValueFromTextBox !== undefined && isVendorLookup == false) {
        vendorNumber = vendorValueFromTextBox;
      }

      if (vendorNumber != "" && vendorNumber != undefined && isVendorLookup == false) {
        ContractManagementService.RetrieveVendorByNumber(vendorNumber).then(response => {
          let data = response;
          if (response !== undefined && response.length > 0) {
            vendorName = data[0].name;

            setValue(thisObj, "txtVendor", vendorName);
            setValue(thisObj, "txtVendorNum", vendorNumber);
            hide(thisObj, "txtVendor")
          }
        })
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
        )
      }
      return false
    }
    return true
  }
  function formatString(str) {
    // Check if string contains a number
    if (!isNaN(str)) {
      // If yes, format string with commas
      return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      // If no, leave string as it is
      return str;
    }
  }
  const onbtnSearchClick = async () => {
    try {
      let func_id = 'PN1180'
      let func_sub_id = null
      let buy_pt_id = getValue(thisObj, "ddLocation")
      let trade_insp_num = getValue(thisObj, "txtDelvInspectNum")
      let trade_settle_num = getValue(thisObj, "txtDelvSettleNum")
      let trade_status = getValue(thisObj, "ddStatus")
      let purch_sale_ind = getValue(thisObj, "ddVendorNum")
      let pnut_type_id = getValue(thisObj, "ddPeanutType")
      let pnut_variety_id = getValue(thisObj, "ddPeanutVariety")
      let seed_gen = getValue(thisObj, "ddGeneration")
      let seg_type = getValue(thisObj, "ddSegType")
      let oleic_ind = getValue(thisObj, "ddOleic")
      let delv_ind = 'Y'
      let grade_pricing_method = getValue(thisObj, "ddGradePricingMethod")
      let edible_oil_ind = getValue(thisObj, "ddEdibleOil")
      let agree_num = getValue(thisObj, "txtAgreementNum")
      let split_vendor = getValue(thisObj, "txtVendorNum")
      const numberFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'USD',
          currencySign: 'accounting',
        }).format(value);

      setLoading(true);

      let response = await SettlementService.RetrieveTradeSettlementList(func_id, func_sub_id, buy_pt_id, trade_insp_num, trade_settle_num, trade_status, purch_sale_ind, pnut_type_id, pnut_variety_id, seed_gen, seg_type, oleic_ind, delv_ind, grade_pricing_method, edible_oil_ind, agree_num, split_vendor)
      if (response.length > 0) {
        enable(thisObj, 'btnOpen')
        let TransformedRowsArray = [];
        let obj = {};
        let data = response[0].trade_settle_headers;
        let totalNetWeight = 0;
        let total100Value = 0;
        let totalConvValue = 0;
        if (response != undefined && response.length > 0) {

          for (let i = 0; i < data.length; i++) {
            obj.txtcolLoc = data[i].buy_pt_id
            let trade_insp_num = data[i].trade_insp_num
            obj.txtcolInspNum = trade_insp_num
            if (data[i].trade_settle_num == "0000000") {
              obj.txtcolSettleNum = ""
            }
            else if (data[i].trade_settle_num == "0") {
              obj.txtcolSettleNum = ""
            }
            else {
              obj.txtcolSettleNum = data[i].trade_settle_num
            }
            obj.txtcolStatus = data[i].trade_status
            switch (data[i].trade_status) {
              case "P":
                obj.txtcolStatus = "Pending";
                break;
              case "C":
                obj.txtcolStatus = "Complete";
                break;
              case "V":
                obj.txtcolStatus = "Void";
                break;
            }
            let AgreeInfo = data[i].agree_info
            if (AgreeInfo.includes("|")) {
              obj.txtcolAgreeNum = "Multi ..."
              let AgreeInfoArr = AgreeInfo.split("|")
              let AgreeArr = [];
              for (let i = 0; i < AgreeInfoArr.length; i++) {
                let object = {};
                object.AgreeDetails = AgreeInfoArr[i];
                AgreeArr.push(object)
                obj.expArray = AgreeArr;
              }
            }
            else {
              obj.txtcolAgreeNum = data[i].agree_info
            }
            obj.txtcolAgreeType = data[i].purch_sale_ind
            switch (data[i].purch_sale_ind) {
              case "P":
                obj.txtcolAgreeType = "Delv In";
                break;
              case "S":
                obj.txtcolAgreeType = "Delv Out";
                break;
            }
            let VendorInfo = data[i].vendor_info
            if (VendorInfo.includes("|")) {
              obj.txtcolVendor = "Multi ..."
              let VendorInfoArr = VendorInfo.split("|")
              let VendorArr = [];
              for (let i = 0; i < VendorInfoArr.length; i++) {
                let object = {};
                object.VendorDetails = VendorInfoArr[i];
                VendorArr.push(object)
                obj.expArray = VendorArr;
              }
            }
            else {
              obj.txtcolVendor = data[i].vendor_info
            }
            obj.txtcolPnutType = data[i].pnut_type_name
            obj.txtcolPnutVariety = data[i].pnut_variety_name
            switch (data[i].symbol_ind) {
              case "TRADEMARK":
                obj.txtcolPnutVariety = data[i].pnut_variety_name + '\u2122';
                break;

              case "REGISTERED TRADEMARK":
                obj.txtcolPnutVariety = data[i].pnut_variety_name + '\xAE';
                break;

              case "COPYRIGHT":
                obj.txtcolPnutVariety = data[i].pnut_variety_name + '\xA9';
                break;

              default:
                obj.txtcolPnutVariety = data[i].pnut_variety_name
            }
            obj.txtcolGen = data[i].seed_gen
            obj.txtcolSeg = data[i].seg_type
            obj.txtcolNetWeight = data[i].net_wt == null || data[i].net_wt == '' ? '' : Intl.NumberFormat('en-US').format(data[i].net_wt)
            totalNetWeight += Number(data[i].net_wt)
            if (data[i].grade_pricing_method == "TKC") {
              obj.txtcolNetWeight = "";
              if (!isNaN(data[i].net_wt) && !isNaN(data[i].lsk_wt) && !isNaN(data[i].smk_pct))
                obj.txtTKClbs = Math.round((((parseFloat(data[i].net_wt - data[i].lsk_wt)) * (parseFloat(data[i].smk_pct))) / 100) + parseFloat(data[i].lsk_wt));
              totalTkcLbs += Math.round(Number(obj.txtTKClbs));
            }
            obj.txtcol100PctValue = data[i].basis_grade_amt == null || data[i].basis_grade_amt == '' ? '' : numberFormat(data[i].basis_grade_amt);
            total100Value += Number(data[i].basis_grade_amt)

            let dblPctSumAgreeValue = 0
            let dblTotalSumAgreeValue = 0
            let dblTotalSum100PctAdjValue = 0

            let dblPaidDollar = 0
            let dblRcptValue = 0
            let dblConvPct = 0
            let dblTotalSumAdjValue = 0;
            let trade_insp_number = trade_insp_num
            let response2 = await SettlementService.RetrieveTradeSettlementApplicationDetails(trade_insp_number, 'Y')
            if (response2.length != 0) {
              let data1 = response2;
              // let dblTotalSumAdjValue = 0;

              for (let j = 0; j < data1.length; j++) {
                if (Number(data1[j].delivered_lbs_adj) > 0) {
                  dblTotalSumAgreeValue = Number(dblTotalSumAgreeValue) + Number(data1[j].agree_value);

                }
                if (Number(data1[j].unapp_dol_value) != "") {
                  dblTotalSumAdjValue = Number(dblTotalSumAdjValue) + Number(data1[j].unapp_dol_value);
                }
              }

              for (let k = 0; k < data1.length; k++) {
                if (Number(data1[k].delivered_lbs_adj) > 0) {
                  dblPctSumAgreeValue = (Number(data1[k].agree_value) / (dblTotalSumAgreeValue > 0 ? dblTotalSumAgreeValue : 1))
                  dblPaidDollar = data1[k].delv_agree_dol
                  dblRcptValue = data1[k].rcpt_value
                  if (Number(dblRcptValue) != 0) {
                    if (obj.txtcolSeg = data1[k].seg_type) {
                      dblConvPct = Number(dblConvPct) + ((Number(data1[k].delv_agree_dol) / Number(data1[k].rcpt_value)) * dblPctSumAgreeValue);
                    }
                    else {
                      dblConvPct = dblConvPct + 1
                    }
                  }

                }
              }
            }
            obj.txtcolConvValue = numberFormat(((data[i].basis_grade_amt) - dblTotalSumAdjValue) * dblConvPct)
            totalConvValue = (Number(totalConvValue) + Number((Number(data[i].basis_grade_amt) - dblTotalSumAdjValue) * dblConvPct)).toFixed(2)
            TransformedRowsArray.push(obj)
            obj = {}
          }
          setValue(thisObj, "txtcolInspNum")
          setData(thisObj, "txtNetWeight", totalNetWeight);
          setData(thisObj, "txt100PctValue", total100Value);
          setData(thisObj, "txtConvValue", totalConvValue);
          setValue(thisObj, "gridDelvSettlementList", TransformedRowsArray);
          setValue(thisObj, "txtNetWeight", formatString(totalNetWeight));
          setValue(thisObj, "txt100PctValue", numberFormat(total100Value));
          setValue(thisObj, "txtConvValue", numberFormat(totalConvValue));
          setLoading(false);

        }
      }
      else {
        setValue(thisObj, 'gridDelvSettlementList', [])
        setValue(thisObj, "txtNetWeight", formatString('0'));

        setValue(thisObj, "txt100PctValue", numberFormat('0'));
        setValue(thisObj, "txtConvValue", numberFormat('0'));

        disable(thisObj, 'btnOpen')
        setLoading(false);
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
        )
      }
      return false
    }
    return true
  };
  thisObj.onbtnSearchClick = onbtnSearchClick;

  //create delivery settlement button functionality

  const onbtnCreateDelvSettlementClick = () => {
    try {
      let Obj = {}
      Obj.LoadTypeBtnCaption = "Add";
      let js = [];
      js.push(Obj);
      setData(thisObj, "CreateValue", js)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "error on Create Tradesettelement Btn")
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnCreateDelvSettlementClick = onbtnCreateDelvSettlementClick;
  ////open btn click
  const onbtnOpenClick = () => {//Fixed as part of SIT Issue.
    try {
      let selectedRows = getValue(thisObj, "gridDelvSettlementList")//thisObj.state.gridDelvSettlementList.selected;

      let selectedRowData
      let rowNum = getSelectedRowNumber(thisObj, 'gridDelvSettlementList');
      selectedRowData = selectedRows[rowNum]
      if (selectedRowData?.txtcolLoc != undefined && selectedRowData?.txtcolLoc != null && selectedRowData?.txtcolLoc != '') {
        if (selectedRowData?.txtcolInspNum != undefined && selectedRowData?.txtcolInspNum != null && selectedRowData?.txtcolInspNum != '') {

          let Inspnum = selectedRowData?.txtcolInspNum
          let Locnum = selectedRowData?.txtcolLoc
          let js = [];
          js.push({
            permission: getData(thisObj, 'accessPermission'),
            Inspnum: Inspnum,
            Locnum: Locnum,
            LoadTypeBtnCaption: 'Edit'
          })
          setData(thisObj, "CreateValue", js)

          goTo(thisObj, "WarehouseReceipts#DelvSettleProfile#DEFAULT#true#Click")
          return true;
        }
      }
      else {
        showMessage(thisObj, "A insp must be selected to open");
        return false
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "No Insp Selected");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnOpenClick = onbtnOpenClick;


  ///grid double click event

  const ongridDelvSettlementListDblclick = () => {
    try {
      let selectedRows = thisObj.state.gridDelvSettlementList.selected;
      if (selectedRows[0].txtcolLoc != undefined && selectedRows[0].txtcolLoc != null && selectedRows[0].txtcolLoc != '')
        if (selectedRows[0].txtcolInspNum != undefined && selectedRows[0].txtcolInspNum != null && selectedRows[0].txtcolInspNum != '') {
          let Inspnum = selectedRows[0].txtcolInspNum
          let Locnum = selectedRows[0].txtcolLoc
          let js = [];
          js.push({
            permission: getData(thisObj, 'accessPermission'),
            Inspnum: Inspnum,
            Locnum: Locnum,
            LoadTypeBtnCaption: 'Edit'
          })
          setData(thisObj, "CreateValue", js)
          goTo(thisObj, "WarehouseReceipts#DelvSettleProfile#DEFAULT#true#Click")
          return true;
        }
        else {
          showMessage(thisObj, "A insp must be selected to open");
          return false
        }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "No Insp Selected");
      }
      return false;
    }
    return true;
  };
  thisObj.ongridDelvSettlementListDblclick = ongridDelvSettlementListDblclick;



  //export to excel button 
  const onbtnExportToExcelClick = () => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let datatable = getValue(thisObj, 'gridDelvSettlementList');
    let secondaryRows = thisObj.state.gridDelvSettlementList.expandedRows;
    var fileName = "Delivery Settle Search";
    let excelData = [];
    if (datatable != undefined && datatable != null && datatable.length >0) {
      for (let i = 0; i < datatable.length; i++) {
        let rowdata = {};
        if (thisObj.state.gridDelvSettlementList.columns[0].Visible) {
          rowdata["Loc"] = datatable[i].txtcolLoc;
        }
        if (thisObj.state.gridDelvSettlementList.columns[1].Visible) {
          rowdata["Insp #"] = datatable[i].txtcolInspNum
        }
        if (thisObj.state.gridDelvSettlementList.columns[2].Visible) {
          if (datatable[i].txtcolSettleNum == '') {
            rowdata["Settle #"] = null;
          }
          else {
            rowdata["Settle #"] = ExcelNumericDataConvert(datatable[i].txtcolSettleNum);
          }

        }
        if (thisObj.state.gridDelvSettlementList.columns[3].Visible) {
          rowdata["Status"] = datatable[i].txtcolStatus;
        }
        if (thisObj.state.gridDelvSettlementList.columns[4].Visible) {
          if (datatable[i].txtcolAgreeNum == '') {
            rowdata["Agree #"] = null;
          }
          else {
            rowdata["Agree #"] = ExcelNumericDataConvert(datatable[i].txtcolAgreeNum);
          }
        }
        if (thisObj.state.gridDelvSettlementList.columns[5].Visible) {
          rowdata["Agree Type"] = datatable[i].txtcolAgreeType;
        }
        if (thisObj.state.gridDelvSettlementList.columns[6].Visible) {
          rowdata["Vendor"] = datatable[i].txtcolVendor;
        }
        if (thisObj.state.gridDelvSettlementList.columns[7].Visible) {
          rowdata["Pnut Type"] = datatable[i].txtcolPnutType;
        }
        if (thisObj.state.gridDelvSettlementList.columns[8].Visible) {
          rowdata["Pnut Variety"] = datatable[i].txtcolPnutVariety;
        }
        if (thisObj.state.gridDelvSettlementList.columns[9].Visible) {
          if (datatable[i].txtcolGen == '') {
            rowdata["Gen"] = null;
          }
          else {
            rowdata["Gen"] = datatable[i].txtcolGen;
          }
        }
        if (thisObj.state.gridDelvSettlementList.columns[10].Visible) {
          rowdata["Seg"] = ExcelNumericDataConvert(datatable[i].txtcolSeg);
        }
        if (thisObj.state.gridDelvSettlementList.columns[11].Visible) {
          if (datatable[i].txtcolNetWeight == '') {
            rowdata["Net Weight"] = null;
          }
          else {
            rowdata["Net Weight"] = ExcelNumericDataConvert(datatable[i].txtcolNetWeight, [',']);
          }
        }

        if (thisObj.state.gridDelvSettlementList.columns[12].Visible) {
          if (datatable[i].txtcol100PctValue == '') {
            rowdata["100% Value"] = null;
          }
          else {
            rowdata["100% Value"] = ExcelNumericDataConvert(datatable[i].txtcol100PctValue, [',', '$']);
          }
        }
        if (thisObj.state.gridDelvSettlementList.columns[13].Visible) {
          if (datatable[i].txtcolConvValue == '') {
            rowdata["Conv. Value"] = null;
          }
          else {
            rowdata["Conv. Value"] = ExcelNumericDataConvert(datatable[i].txtcolConvValue, [',', '$']);
          }
        }
        excelData.push(rowdata);
        let extendedData = {};
        if (thisObj.state.gridDelvSettlementList.columns[4].Visible || thisObj.state.gridDelvSettlementList.columns[6].Visible) {
          if (secondaryRows != undefined && secondaryRows.length >0) {
            for(let j = 0; j < secondaryRows?.length; j++){
              if(secondaryRows[j].rowID$ == i){
                if(secondaryRows[j].txtcolVendor == "Multi ..."){
                  for(let k =0; k < secondaryRows[j]?.expArray?.length; k++){
                    extendedData = {}
                    extendedData["Vendor"] = secondaryRows[j].expArray[k].VendorDetails;
                    excelData.push(extendedData);
                  }
                }
                else if(secondaryRows[j].txtcolAgreeNum == "Multi ..."){
                  for(let k =0; k < secondaryRows[j]?.expArray?.length; k++){
                    extendedData = {}
                    extendedData["Agree #"] = ExcelNumericDataConvert(secondaryRows[j].expArray[k].AgreeDetails);
                    excelData.push(extendedData);
                  }
                }
              }
            }
          }
        }
      }
      let totals = {};
      let trigger = 0;
      for (let i = 0; i < 11; i++) {
        if (thisObj.state.gridDelvSettlementList.columns[i].Visible) {
          totals[thisObj.state.gridDelvSettlementList.columns[i].Label] = "Totals";
          trigger = 1;
          break;
        }
      }
      if (trigger !== 1) {
        excelData.push('')
      }
      if (thisObj.state.gridDelvSettlementList.columns[11].Visible) {
        totals["Net Weight"] = ExcelNumericDataConvert(getData(thisObj, 'txtNetWeight',))
      }
      if (thisObj.state.gridDelvSettlementList.columns[12].Visible) {
        totals["100% Value"] = ExcelNumericDataConvert(getData(thisObj, 'txt100PctValue'))
      }
      if (thisObj.state.gridDelvSettlementList.columns[13].Visible) {
        totals["Conv. Value"] = ExcelNumericDataConvert(getData(thisObj, 'txtConvValue'));
      }

      excelData.push(totals);

      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;


  //Method for Print button
  const onbtnPrintClick = () => {
    try {
      var datatable = getValue(thisObj, "gridDelvSettlementList")
      let secondaryRows = thisObj.state.gridDelvSettlementList.expandedRows;
      var res = [];
      let headerarray = [];

      if(datatable != undefined && datatable != null && datatable.length >0){
      if (thisObj.state.gridDelvSettlementList.columns[0].Visible) {
        headerarray.push("Loc")
      }
      if (thisObj.state.gridDelvSettlementList.columns[1].Visible) {
        headerarray.push("Insp#")
      }
      if (thisObj.state.gridDelvSettlementList.columns[2].Visible) {
        headerarray.push("Settle#")
      }
      if (thisObj.state.gridDelvSettlementList.columns[3].Visible) {
        headerarray.push("Status")
      }
      if (thisObj.state.gridDelvSettlementList.columns[4].Visible) {
        headerarray.push("Agree#")
      }
      if (thisObj.state.gridDelvSettlementList.columns[5].Visible) {
        headerarray.push("Agree Type")
      }
      if (thisObj.state.gridDelvSettlementList.columns[6].Visible) {
        headerarray.push("Vendor")
      }
      if (thisObj.state.gridDelvSettlementList.columns[7].Visible) {
        headerarray.push("Pnut Type")
      }
      if (thisObj.state.gridDelvSettlementList.columns[8].Visible) {
        headerarray.push("Pnut Variety")
      }
      if (thisObj.state.gridDelvSettlementList.columns[9].Visible) {
        headerarray.push("Gen")
      }
      if (thisObj.state.gridDelvSettlementList.columns[10].Visible) {
        headerarray.push("Seg")
      }
      if (thisObj.state.gridDelvSettlementList.columns[11].Visible) {
        headerarray.push("Net Weight")
      }

      if (thisObj.state.gridDelvSettlementList.columns[12].Visible) {
        headerarray.push("100% Value")
      }
      if (thisObj.state.gridDelvSettlementList.columns[13].Visible) {
        headerarray.push("Conv. Value")
      }

      for (var i = 0; i < datatable.length; i++) {
        let rowdata = []
        if (thisObj.state.gridDelvSettlementList.columns[0].Visible) {
          rowdata.push(datatable[i].txtcolLoc)
        }
        if (thisObj.state.gridDelvSettlementList.columns[1].Visible) {
          rowdata.push(datatable[i].txtcolInspNum)
        }
        if (thisObj.state.gridDelvSettlementList.columns[2].Visible) {
          rowdata.push(datatable[i].txtcolSettleNum)
        }
        if (thisObj.state.gridDelvSettlementList.columns[3].Visible) {
          rowdata.push(datatable[i].txtcolStatus)
        }
        if (thisObj.state.gridDelvSettlementList.columns[4].Visible) {
          rowdata.push(datatable[i].txtcolAgreeNum)
        }
        if (thisObj.state.gridDelvSettlementList.columns[5].Visible) {
          rowdata.push(datatable[i].txtcolAgreeType)
        }
        if (thisObj.state.gridDelvSettlementList.columns[6].Visible) {
          rowdata.push(datatable[i].txtcolVendor)
        }
        if (thisObj.state.gridDelvSettlementList.columns[7].Visible) {
          rowdata.push(datatable[i].txtcolPnutType)
        }
        if (thisObj.state.gridDelvSettlementList.columns[8].Visible) {
          rowdata.push(datatable[i].txtcolPnutVariety)
        }
        if (thisObj.state.gridDelvSettlementList.columns[9].Visible) {
          rowdata.push(datatable[i].txtcolGen)
        }
        if (thisObj.state.gridDelvSettlementList.columns[10].Visible) {
          rowdata.push(datatable[i].txtcolSeg)
        }
        if (thisObj.state.gridDelvSettlementList.columns[11].Visible) {
          rowdata.push(datatable[i].txtcolNetWeight)
        }
        if (thisObj.state.gridDelvSettlementList.columns[12].Visible) {
          rowdata.push(datatable[i].txtcol100PctValue)
        }
        if (thisObj.state.gridDelvSettlementList.columns[13].Visible) {
          rowdata.push(datatable[i].txtcolConvValue)
        }
        res.push(rowdata)
        if(secondaryRows.length > 0){
          for(let j = 0; j < secondaryRows?.length; j++){
            if(secondaryRows[j].rowID$ == i){
              if(secondaryRows[j].txtcolVendor == "Multi ..."){
                for(let k =0; k < secondaryRows[j]?.expArray?.length; k++){
                  rowdata = []
                  if (thisObj.state.gridDelvSettlementList.columns[0].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[1].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[2].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[3].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[4].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[5].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[6].Visible) {
                    rowdata.push(secondaryRows[j].expArray[k].VendorDetails)
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[7].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[8].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[9].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[10].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[11].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[12].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[13].Visible) {
                    rowdata.push('')
                  }
                  res.push(rowdata)
                }
              }
              else if(secondaryRows[j].txtcolAgreeNum == "Multi ..."){
                for(let k =0; k < secondaryRows[j]?.expArray?.length; k++){
                  rowdata = []
                  if (thisObj.state.gridDelvSettlementList.columns[0].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[1].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[2].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[3].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[4].Visible) {
                    rowdata.push(secondaryRows[j].expArray[k].AgreeDetails)
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[5].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[6].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[7].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[8].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[9].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[10].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[11].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[12].Visible) {
                    rowdata.push('')
                  }
                  if (thisObj.state.gridDelvSettlementList.columns[13].Visible) {
                    rowdata.push('')
                  }
                  res.push(rowdata)
                }
              }
            }
          }
        }
      }

      var obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [
          headerarray
        ]
      });

      var pageCount = obj.internal.getNumberOfPages();
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(8);
        obj.text('   Delivery Settle Search - Crop Year' + sessionStorage.getItem('year') + " -    Net Weight:" + getValue(thisObj, 'txtNetWeight') + ' 100% Value:' + getValue(thisObj, 'txt100PctValue') + "   Conv. Value:" + getValue(thisObj, 'txtConvValue'), 10, obj.internal.pageSize.height - 10);
      }


      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:DeliverySettleSearch.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:DeliverySettleSearch.cmdPrintSearch_Click")
      }
    }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;

  //text box validation and chnage events

  const ontxtDelvSettleNumChange = () => {
    let SettlementValue = getValue(thisObj, 'txtDelvSettleNum')
    setValue(thisObj, "txtDelvSettleNum", validateTextBox(SettlementValue))
  };
  thisObj.ontxtDelvSettleNumChange = ontxtDelvSettleNumChange

  const validateTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }
  const ontxtDelvInspectNumChange = () => {
    try {
      let value = getValue(thisObj, 'txtDelvInspectNum')
      let strRegex = new RegExp(/^[A-Za-z]\d*$/)
      const reg = new RegExp('^[0-9]+$');
      if (strRegex.test(value))
        setValue(thisObj, 'txtDelvInspectNum', value.toUpperCase())
      else
        if (reg.test(value)) {
          setValue(thisObj, 'txtDelvInspectNum', value)
        }
        else {
          setValue(thisObj, 'txtDelvInspectNum', '')
        }
    }
    catch (err) {
      showMessage(thisObj, err.message)
    }
  }
  thisObj.ontxtDelvInspectNumChange = ontxtDelvInspectNumChange
  const onGridRowChange = (rowSelected) => {
    let data3 = rowSelected;
    if (data3.selectedRows) {
      data3 = data3.selectedRows[0];
      disable(thisObj, 'btnOpen')
    }
  };
  thisObj.onGridRowChange = onGridRowChange;

  const onbtnCloseClick = () => {
    try {
      document.getElementById("WarehouseReceipts_DelvSettleSearchPopUp").childNodes[0].click()

      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DelvSettleSearch*/}

              {/* END_USER_CODE-USER_BEFORE_DelvSettleSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDelvSettleSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDelvSettleSearch*/}

              <GroupBoxWidget
                conf={state.grpbxDelvSettleSearch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails1*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails1*/}

                <GroupBoxWidget conf={state.grpbxDetails1} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDelvInspectNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDelvInspectNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDelvInspectNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDelvInspectNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtDelvInspectNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDelvSettleNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDelvSettleNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDelvSettleNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDelvSettleNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtDelvSettleNum*/}
                  {/* START_USER_CODE-USER_BEFORE_ddStatus*/}

                  {/* END_USER_CODE-USER_BEFORE_ddStatus*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddStatus}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddStatus*/}

                  {/* END_USER_CODE-USER_AFTER_ddStatus*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAgreementNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAgreementNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAgreementNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAgreementNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtAgreementNum*/}
                  {/* START_USER_CODE-USER_BEFORE_ddVendorNum*/}

                  {/* END_USER_CODE-USER_BEFORE_ddVendorNum*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddVendorNum}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddVendorNum*/}

                  {/* END_USER_CODE-USER_AFTER_ddVendorNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendorNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendorNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendorNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendorNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendorNum*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                  <ButtonWidget
                    conf={state.btnVendor}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                  {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails1*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails1*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails2*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails2*/}

                <GroupBoxWidget conf={state.grpbxDetails2} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutVariety}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                  {/* START_USER_CODE-USER_BEFORE_ddGeneration*/}

                  {/* END_USER_CODE-USER_BEFORE_ddGeneration*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddGeneration}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddGeneration*/}

                  {/* END_USER_CODE-USER_AFTER_ddGeneration*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSegType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSegType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSegType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSegType*/}

                  {/* END_USER_CODE-USER_AFTER_ddSegType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOleic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                  {/* START_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                  {/* END_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddEdibleOil}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddEdibleOil*/}

                  {/* END_USER_CODE-USER_AFTER_ddEdibleOil*/}
                  {/* START_USER_CODE-USER_BEFORE_ddGradePricingMethod*/}

                  {/* END_USER_CODE-USER_BEFORE_ddGradePricingMethod*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddGradePricingMethod}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddGradePricingMethod*/}

                  {/* END_USER_CODE-USER_AFTER_ddGradePricingMethod*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails2*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails2*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDelvSettlementList*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDelvSettlementList*/}

                <GroupBoxWidget
                  conf={state.grpbxDelvSettlementList}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_lblCropYear*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCropYear*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCropYear}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCropYear*/}

                  {/* END_USER_CODE-USER_AFTER_lblCropYear*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCreateDelvSettlement*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCreateDelvSettlement*/}

                  <ButtonWidget
                    conf={state.btnCreateDelvSettlement}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCreateDelvSettlement*/}

                  {/* END_USER_CODE-USER_AFTER_btnCreateDelvSettlement*/}
                  {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                  {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                  <ButtonWidget
                    conf={state.btnOpen}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnOpen*/}

                  {/* END_USER_CODE-USER_AFTER_btnOpen*/}
                  {/* START_USER_CODE-USER_BEFORE_gridDelvSettlementList*/}

                  {/* END_USER_CODE-USER_BEFORE_gridDelvSettlementList*/}

                  <GridWidget
                    conf={state.gridDelvSettlementList}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridDelvSettlementList}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridDelvSettlementList*/}

                  {/* END_USER_CODE-USER_AFTER_gridDelvSettlementList*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDelvSettlementList*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDelvSettlementList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDelvSettleSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDelvSettleSearch*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetWeight*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetWeight*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetWeight}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetWeight*/}

                {/* END_USER_CODE-USER_AFTER_txtNetWeight*/}
                {/* START_USER_CODE-USER_BEFORE_txt100PctValue*/}

                {/* END_USER_CODE-USER_BEFORE_txt100PctValue*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt100PctValue}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt100PctValue*/}

                {/* END_USER_CODE-USER_AFTER_txt100PctValue*/}
                {/* START_USER_CODE-USER_BEFORE_txtConvValue*/}

                {/* END_USER_CODE-USER_BEFORE_txtConvValue*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtConvValue}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtConvValue*/}

                {/* END_USER_CODE-USER_AFTER_txtConvValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_DelvSettleSearch*/}

              {/* END_USER_CODE-USER_AFTER_DelvSettleSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_DelvSettleSearch);
