/* eslint-disable*/
import React from "react";
import TextBoxWidget from "./TextBoxWidget";
import { screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";
import ParentWindow from "../../shared/ParentWindow";

describe("TextBoxWidget1 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let textboxwidget1 = {
        name: "textboxwidget1",
        type: "TextBoxWidget",
        parent: "groupboxwidget0",
        Label: "Text Box Widget (Basic)",
        ShortcutKey: "a",
        DefaultValue: "Test Data",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };
      let stateScreenConf = {
        Label: "ChildScreen",
        windowName: "ChildScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestABSGUI.ChildScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      renderTestScreen(
        <TextBoxWidget
          conf={textboxwidget1}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Is TextBoxWidget1 Loads Succesfully", async () => {
    const textboxwidget1 = screen.getByTestId("textboxwidget1");
    expect(screen.getByText("Text Box Widget (Basic)")).toBeInTheDocument;
    expect(textboxwidget1.tagName).toBe("INPUT");
    expect(textboxwidget1.type).toBe("text");
    expect(textboxwidget1.classList).toContain("textboxWidgetClass");
  });
});
describe("TextBoxWidget2 Test Cases", () => {
  afterEach(cleanup);
  test("Is TextBoxWidget2A Loads Succesfully", () => {
    let textboxwidget1 = {
      name: "textboxwidget1",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Text Box Widget (Basic)",
      ShortcutKey: "a",
      DefaultValue: "Test Data",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    };
    let stateScreenConf = {
      Label: "ChildScreen",
      windowName: "ChildScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestABSGUI.ChildScreen",
    };
    let values = {};
    let errors = jest.fn();
    let touched = jest.fn();
    window._kaledo["dispFormatReqd"] = true;
    const testScreen = renderTestScreen(
      <TextBoxWidget
        conf={textboxwidget1}
        screenConf={stateScreenConf}
        values={values}
        touched={touched}
        errors={errors}
      />
    );
    expect(testScreen.getByText("Text Box Widget (Basic)")).toBeInTheDocument;
    const textboxwidgett1 = testScreen.getByTestId("textboxwidget1");
    expect(textboxwidgett1.tagName).toBe("INPUT");
    expect(textboxwidgett1.type).toBe("text");
    expect(textboxwidgett1.classList).toContain("textboxWidgetClass");
    expect(textboxwidgett1.previousElementSibling.textContent).toEqual(
      "Text Box Widget (Basic)"
    );
  });
  test("Is TextBoxWidget2B Loads Succesfully", async () => {
    let textboxwidget1 = {
      name: "textboxwidget1",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Text Box Widget (Basic)",
      ShortcutKey: "a",
      DefaultValue: "Test Data",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    };
    let stateScreenConf = {
      Label: "ChildScreen",
      windowName: "ChildScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestABSGUI.ChildScreen",
      horizontalForm: true,
    };
    let values = {};
    let errors = jest.fn();
    let touched = jest.fn();
    const testScreen = renderTestScreen(
      <TextBoxWidget
        conf={textboxwidget1}
        screenConf={stateScreenConf}
        values={values}
        touched={touched}
        errors={errors}
      />
    );
    expect(testScreen.getByText("Text Box Widget (Basic)")).toBeInTheDocument;
    const textboxwidgett1 = testScreen.getByTestId("textboxwidget1");
    expect(textboxwidgett1.tagName).toBe("INPUT");
    expect(textboxwidgett1.type).toBe("text");
    expect(textboxwidgett1.classList).toContain("textboxWidgetClass");
  });
});
describe("TextBoxWidget3 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let textboxwidget1 = {
        name: "textboxwidget1",
        type: "TextBoxWidget",
        parent: "groupboxwidget0",
        Label: "Text Box Widget (Basic)",
        ShortcutKey: "a",
        DefaultValue: "Test Data",
        LengthRange: { MinLength: 0, MaxLength: 9 },
        ofTypeDomain: "d_int",
        value: "1",
      };
      let stateScreenConf = {
        Label: "ChildScreen",
        windowName: "ChildScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestABSGUI.ChildScreen",
      };

      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      window._kaledo["dispFormatReqd"] = true;
      renderTestScreen(
        <TextBoxWidget
          conf={textboxwidget1}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Is TextBoxWidget3 Loads Succesfully", () => {
    expect(screen.getByText("Text Box Widget (Basic)")).toBeInTheDocument;
    const textboxwidgett1 = screen.getByTestId("textboxwidget1");
    expect(textboxwidgett1.tagName).toBe("INPUT");
    expect(textboxwidgett1.type).toBe("text");
    expect(textboxwidgett1.classList).toContain("textboxWidgetClass");
    expect(textboxwidgett1.previousElementSibling.textContent).toEqual(
      "Text Box Widget (Basic)(999999999)"
    );
  });
});
describe("TextBoxWidget4 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let textboxwidget1 = {
        name: "textboxwidget1",
        type: "TextBoxWidget",
        parent: "groupboxwidget0",
        Label: "Text Box Widget (Basic)",
        ShortcutKey: "a",
        DefaultValue: "Test Data",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
        formattingReqd: true,

      };
      let stateScreenConf = {
        Label: "ChildScreen",
        windowName: "ChildScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestABSGUI.ChildScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      renderTestScreen(
        <TextBoxWidget
          conf={textboxwidget1}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Is TextBoxWidget4A Loads Succesfully", () => {
    expect(screen.getByText("Text Box Widget (Basic)")).toBeInTheDocument;
  });
  test("Is TextBoxWidget4B Loads Succesfully", async () => {
    const textboxwidget1 = screen.getByTestId("textboxwidget1");
    expect(textboxwidget1.tagName).toBe("INPUT");
    expect(textboxwidget1.type).toBe("text");
    expect(textboxwidget1.classList).toContain("textboxWidgetClass");
  });
});
describe("TextBoxWidget5 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let textboxwidget1 = {
        name: "textboxwidget1",
        type: "TextBoxWidget",
        parent: "groupboxwidget0",
        Label: "Text Box Widget (Basic)",
        ShortcutKey: "a",
        DefaultValue: "Test Data",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
        formattingReqd: true,
      };
      let textboxwidget2 = {
        name: "textboxwidget2",
        type: "TextBoxWidget",
        parent: "groupboxwidget0",
        Label: "Text Box Widget (Basic)",
        ShortcutKey: "a",
        DefaultValue: "Test Data",
        ofTypeDomain: "d_String",
        formattingReqd: true,
      };
      let textboxwidget3 = {
        name: "textboxwidget3",
        type: "TextBoxWidget",
        parent: "groupboxwidget0",
        Label: "Text Box Widget (Basic)",
        ShortcutKey: "a",
        DefaultValue: "Test Data",
        ofTypeDomain: "d_int",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        formattingReqd: false,
      };
      let textboxwidget4 = {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Center",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget4",
        type: "TextBoxWidget",
        parent: "groupboxwidget0",
        Label: "Text Box Widget (Basic)",
        ShortcutKey: "a",
        DefaultValue: "Test Data",
        ofTypeDomain: "d_float",
        formattingReqd: true,
      };
      let textboxwidget5 = {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Right",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None",
        name: "textboxwidget5",
        type: "TextBoxWidget",
        parent: "groupboxwidget0",
        Label: "Text Box Widget (Basic)",
        ShortcutKey: "a",
        DefaultValue: "Test Data",
        ofTypeDomain: "d_double",
        formattingReqd: true,
      };
      let stateScreenConf = {
        Label: "ChildScreen",
        windowName: "ChildScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestABSGUI.ChildScreen",
        horizontalForm: true,
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      renderTestScreen(
        <>
        <TextBoxWidget
          onChange={onChange}
          conf={textboxwidget1}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
        <TextBoxWidget
          onChange={onChange}
          conf={textboxwidget2}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
        <TextBoxWidget
          onChange={onChange}
          conf={textboxwidget3}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
        <TextBoxWidget
          onChange={onChange}
          conf={textboxwidget4}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
        <TextBoxWidget
          onChange={onChange}
          conf={textboxwidget5}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
        </>
      );
    });
  });
  afterEach(cleanup);
  test("textboxwidget1(Textbox Widget) Test Cases", async () => {
    const textboxwidget1 = screen.getByTestId("textboxwidget1");
    expect(textboxwidget1.tagName).toBe("INPUT");
    expect(textboxwidget1.type).toBe("text");
    expect(textboxwidget1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      fireEvent.change(textboxwidget1, { target: { value: "1234555" } });
    });
  });
});
