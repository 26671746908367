/* eslint-disable*/
import React from "react";
import ContractManagement_ViewPayments from "./ViewPayments";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ViewPayments Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ViewPayments />);
    });
  });
  afterEach(cleanup);
  test("is ViewPayments Loads Successfully", () => {
    expect(screen.getByText("View Payments")).toBeInTheDocument;
  });
  test("Custom Test Cases for ViewPayments", () => {
    // START_USER_CODE-USER_ViewPayments_Custom_Test_Case
    // END_USER_CODE-USER_ViewPayments_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ViewPayments />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("ContractManagement:ViewPayments_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExport(Button Widget) Test Cases", async () => {
    const btnExport = screen.getByTestId("btnExport");
    expect(btnExport).toBeInTheDocument;
    expect(btnExport.textContent).toEqual(
      t("ContractManagement:ViewPayments_btnExport")
    );
  });
  test("Custom Test Cases for btnExport", () => {
    // START_USER_CODE-USER_btnExport_TEST
    // END_USER_CODE-USER_btnExport_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("ContractManagement:ViewPayments_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("gridContrctExcPremProf(Grid Widget) Test Cases", async () => {
    let gridContrctExcPremProf = screen.getByTestId("gridContrctExcPremProf");
    let gridContrctExcPremProfbtn =
      gridContrctExcPremProf.nextElementSibling.firstElementChild;
    gridContrctExcPremProf =
      gridContrctExcPremProf.parentElement.parentElement.parentElement;
    expect(gridContrctExcPremProf.tagName).toBe("DIV");
    expect(gridContrctExcPremProf.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ViewPayments_gridContrctExcPremProf")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridContrctExcPremProf", () => {
    // START_USER_CODE-USER_gridContrctExcPremProf_TEST
    // END_USER_CODE-USER_gridContrctExcPremProf_TEST
  });
  test("gridContrctExcPremProf_txtByngPt(Grid Widget) Test Cases", async () => {
    let gridContrctExcPremProf_txtByngPt = screen.getByTestId(
      "gridContrctExcPremProf"
    );
    let gridContrctExcPremProf_txtByngPtbtn =
      gridContrctExcPremProf_txtByngPt.nextElementSibling.firstElementChild;
    gridContrctExcPremProf_txtByngPt =
      gridContrctExcPremProf_txtByngPt.parentElement.parentElement
        .parentElement;
    expect(gridContrctExcPremProf_txtByngPt.tagName).toBe("DIV");
    expect(gridContrctExcPremProf_txtByngPt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ViewPayments_gridContrctExcPremProf")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtByngPt", () => {
    // START_USER_CODE-USER_txtByngPt_TEST
    // END_USER_CODE-USER_txtByngPt_TEST
  });
  test("gridContrctExcPremProf_txtChckNum(Grid Widget) Test Cases", async () => {
    let gridContrctExcPremProf_txtChckNum = screen.getByTestId(
      "gridContrctExcPremProf"
    );
    let gridContrctExcPremProf_txtChckNumbtn =
      gridContrctExcPremProf_txtChckNum.nextElementSibling.firstElementChild;
    gridContrctExcPremProf_txtChckNum =
      gridContrctExcPremProf_txtChckNum.parentElement.parentElement
        .parentElement;
    expect(gridContrctExcPremProf_txtChckNum.tagName).toBe("DIV");
    expect(gridContrctExcPremProf_txtChckNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ViewPayments_gridContrctExcPremProf")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtChckNum", () => {
    // START_USER_CODE-USER_txtChckNum_TEST
    // END_USER_CODE-USER_txtChckNum_TEST
  });
  test("gridContrctExcPremProf_txtClrdDt(Grid Widget) Test Cases", async () => {
    let gridContrctExcPremProf_txtClrdDt = screen.getByTestId(
      "gridContrctExcPremProf"
    );
    let gridContrctExcPremProf_txtClrdDtbtn =
      gridContrctExcPremProf_txtClrdDt.nextElementSibling.firstElementChild;
    gridContrctExcPremProf_txtClrdDt =
      gridContrctExcPremProf_txtClrdDt.parentElement.parentElement
        .parentElement;
    expect(gridContrctExcPremProf_txtClrdDt.tagName).toBe("DIV");
    expect(gridContrctExcPremProf_txtClrdDt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ViewPayments_gridContrctExcPremProf")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtClrdDt", () => {
    // START_USER_CODE-USER_txtClrdDt_TEST
    // END_USER_CODE-USER_txtClrdDt_TEST
  });
  test("gridContrctExcPremProf_txtContrct(Grid Widget) Test Cases", async () => {
    let gridContrctExcPremProf_txtContrct = screen.getByTestId(
      "gridContrctExcPremProf"
    );
    let gridContrctExcPremProf_txtContrctbtn =
      gridContrctExcPremProf_txtContrct.nextElementSibling.firstElementChild;
    gridContrctExcPremProf_txtContrct =
      gridContrctExcPremProf_txtContrct.parentElement.parentElement
        .parentElement;
    expect(gridContrctExcPremProf_txtContrct.tagName).toBe("DIV");
    expect(gridContrctExcPremProf_txtContrct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ViewPayments_gridContrctExcPremProf")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtContrct", () => {
    // START_USER_CODE-USER_txtContrct_TEST
    // END_USER_CODE-USER_txtContrct_TEST
  });
  test("gridContrctExcPremProf_txtIssueDate(Grid Widget) Test Cases", async () => {
    let gridContrctExcPremProf_txtIssueDate = screen.getByTestId(
      "gridContrctExcPremProf"
    );
    let gridContrctExcPremProf_txtIssueDatebtn =
      gridContrctExcPremProf_txtIssueDate.nextElementSibling.firstElementChild;
    gridContrctExcPremProf_txtIssueDate =
      gridContrctExcPremProf_txtIssueDate.parentElement.parentElement
        .parentElement;
    expect(gridContrctExcPremProf_txtIssueDate.tagName).toBe("DIV");
    expect(gridContrctExcPremProf_txtIssueDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("ContractManagement:ViewPayments_gridContrctExcPremProf")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtIssueDate", () => {
    // START_USER_CODE-USER_txtIssueDate_TEST
    // END_USER_CODE-USER_txtIssueDate_TEST
  });
  test("gridContrctExcPremProf_txtMemo(Grid Widget) Test Cases", async () => {
    let gridContrctExcPremProf_txtMemo = screen.getByTestId(
      "gridContrctExcPremProf"
    );
    let gridContrctExcPremProf_txtMemobtn =
      gridContrctExcPremProf_txtMemo.nextElementSibling.firstElementChild;
    gridContrctExcPremProf_txtMemo =
      gridContrctExcPremProf_txtMemo.parentElement.parentElement.parentElement;
    expect(gridContrctExcPremProf_txtMemo.tagName).toBe("DIV");
    expect(gridContrctExcPremProf_txtMemo.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ViewPayments_gridContrctExcPremProf")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtMemo", () => {
    // START_USER_CODE-USER_txtMemo_TEST
    // END_USER_CODE-USER_txtMemo_TEST
  });
  test("gridContrctExcPremProf_txtProceeds(Grid Widget) Test Cases", async () => {
    let gridContrctExcPremProf_txtProceeds = screen.getByTestId(
      "gridContrctExcPremProf"
    );
    let gridContrctExcPremProf_txtProceedsbtn =
      gridContrctExcPremProf_txtProceeds.nextElementSibling.firstElementChild;
    gridContrctExcPremProf_txtProceeds =
      gridContrctExcPremProf_txtProceeds.parentElement.parentElement
        .parentElement;
    expect(gridContrctExcPremProf_txtProceeds.tagName).toBe("DIV");
    expect(gridContrctExcPremProf_txtProceeds.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("ContractManagement:ViewPayments_gridContrctExcPremProf")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtProceeds", () => {
    // START_USER_CODE-USER_txtProceeds_TEST
    // END_USER_CODE-USER_txtProceeds_TEST
  });
  test("gridContrctExcPremProf_txtSpclTyp(Grid Widget) Test Cases", async () => {
    let gridContrctExcPremProf_txtSpclTyp = screen.getByTestId(
      "gridContrctExcPremProf"
    );
    let gridContrctExcPremProf_txtSpclTypbtn =
      gridContrctExcPremProf_txtSpclTyp.nextElementSibling.firstElementChild;
    gridContrctExcPremProf_txtSpclTyp =
      gridContrctExcPremProf_txtSpclTyp.parentElement.parentElement
        .parentElement;
    expect(gridContrctExcPremProf_txtSpclTyp.tagName).toBe("DIV");
    expect(gridContrctExcPremProf_txtSpclTyp.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ViewPayments_gridContrctExcPremProf")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtSpclTyp", () => {
    // START_USER_CODE-USER_txtSpclTyp_TEST
    // END_USER_CODE-USER_txtSpclTyp_TEST
  });
  test("gridContrctExcPremProf_txtStatus(Grid Widget) Test Cases", async () => {
    let gridContrctExcPremProf_txtStatus = screen.getByTestId(
      "gridContrctExcPremProf"
    );
    let gridContrctExcPremProf_txtStatusbtn =
      gridContrctExcPremProf_txtStatus.nextElementSibling.firstElementChild;
    gridContrctExcPremProf_txtStatus =
      gridContrctExcPremProf_txtStatus.parentElement.parentElement
        .parentElement;
    expect(gridContrctExcPremProf_txtStatus.tagName).toBe("DIV");
    expect(gridContrctExcPremProf_txtStatus.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ViewPayments_gridContrctExcPremProf")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtStatus", () => {
    // START_USER_CODE-USER_txtStatus_TEST
    // END_USER_CODE-USER_txtStatus_TEST
  });
  test("gridContrctExcPremProf_txtVendr(Grid Widget) Test Cases", async () => {
    let gridContrctExcPremProf_txtVendr = screen.getByTestId(
      "gridContrctExcPremProf"
    );
    let gridContrctExcPremProf_txtVendrbtn =
      gridContrctExcPremProf_txtVendr.nextElementSibling.firstElementChild;
    gridContrctExcPremProf_txtVendr =
      gridContrctExcPremProf_txtVendr.parentElement.parentElement.parentElement;
    expect(gridContrctExcPremProf_txtVendr.tagName).toBe("DIV");
    expect(gridContrctExcPremProf_txtVendr.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ViewPayments_gridContrctExcPremProf")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtVendr", () => {
    // START_USER_CODE-USER_txtVendr_TEST
    // END_USER_CODE-USER_txtVendr_TEST
  });
  test("gridContrctExcPremProf_txtVoidDate(Grid Widget) Test Cases", async () => {
    let gridContrctExcPremProf_txtVoidDate = screen.getByTestId(
      "gridContrctExcPremProf"
    );
    let gridContrctExcPremProf_txtVoidDatebtn =
      gridContrctExcPremProf_txtVoidDate.nextElementSibling.firstElementChild;
    gridContrctExcPremProf_txtVoidDate =
      gridContrctExcPremProf_txtVoidDate.parentElement.parentElement
        .parentElement;
    expect(gridContrctExcPremProf_txtVoidDate.tagName).toBe("DIV");
    expect(gridContrctExcPremProf_txtVoidDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("ContractManagement:ViewPayments_gridContrctExcPremProf")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtVoidDate", () => {
    // START_USER_CODE-USER_txtVoidDate_TEST
    // END_USER_CODE-USER_txtVoidDate_TEST
  });
});
