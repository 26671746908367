/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_Scan211Document from "./Scan211Document";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("Scan211Document Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_Scan211Document />);
    });
  });
  afterEach(cleanup);
  test("is Scan211Document Loads Successfully", () => {
    expect(screen.getByText("Scan211Document")).toBeInTheDocument;
  });
  test("Custom Test Cases for Scan211Document", () => {
    // START_USER_CODE-USER_Scan211Document_Custom_Test_Case
    // END_USER_CODE-USER_Scan211Document_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_Scan211Document />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:Scan211Document_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btn2(Button Widget) Test Cases", async () => {
    const btn2 = screen.getByTestId("btn2");
    expect(btn2).toBeInTheDocument;
    expect(btn2.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:Scan211Document_btn2")
    );
  });
  test("Custom Test Cases for btn2", () => {
    // START_USER_CODE-USER_btn2_TEST
    // END_USER_CODE-USER_btn2_TEST
  });
  test("btn3(Button Widget) Test Cases", async () => {
    const btn3 = screen.getByTestId("btn3");
    expect(btn3).toBeInTheDocument;
    expect(btn3.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:Scan211Document_btn3")
    );
  });
  test("Custom Test Cases for btn3", () => {
    // START_USER_CODE-USER_btn3_TEST
    // END_USER_CODE-USER_btn3_TEST
  });
  test("btnCancelSave(Button Widget) Test Cases", async () => {
    const btnCancelSave = screen.getByTestId("btnCancelSave");
    expect(btnCancelSave).toBeInTheDocument;
    expect(btnCancelSave.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:Scan211Document_btnCancelSave")
    );
  });
  test("Custom Test Cases for btnCancelSave", () => {
    // START_USER_CODE-USER_btnCancelSave_TEST
    // END_USER_CODE-USER_btnCancelSave_TEST
  });
  test("btnCancelScan(Button Widget) Test Cases", async () => {
    const btnCancelScan = screen.getByTestId("btnCancelScan");
    expect(btnCancelScan).toBeInTheDocument;
    expect(btnCancelScan.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:Scan211Document_btnCancelScan")
    );
  });
  test("Custom Test Cases for btnCancelScan", () => {
    // START_USER_CODE-USER_btnCancelScan_TEST
    // END_USER_CODE-USER_btnCancelScan_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:Scan211Document_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnSaveFromExistingFile(Button Widget) Test Cases", async () => {
    const btnSaveFromExistingFile = screen.getByTestId(
      "btnSaveFromExistingFile"
    );
    expect(btnSaveFromExistingFile).toBeInTheDocument;
    expect(btnSaveFromExistingFile.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:Scan211Document_btnSaveFromExistingFile"
      )
    );
  });
  test("Custom Test Cases for btnSaveFromExistingFile", () => {
    // START_USER_CODE-USER_btnSaveFromExistingFile_TEST
    // END_USER_CODE-USER_btnSaveFromExistingFile_TEST
  });
  test("btnScan211(Button Widget) Test Cases", async () => {
    const btnScan211 = screen.getByTestId("btnScan211");
    expect(btnScan211).toBeInTheDocument;
    expect(btnScan211.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:Scan211Document_btnScan211")
    );
  });
  test("Custom Test Cases for btnScan211", () => {
    // START_USER_CODE-USER_btnScan211_TEST
    // END_USER_CODE-USER_btnScan211_TEST
  });
  test("btnScanUsingDocFeeder(Button Widget) Test Cases", async () => {
    const btnScanUsingDocFeeder = screen.getByTestId(
      "btnScanUsingDocFeeder"
    );
    expect(btnScanUsingDocFeeder).toBeInTheDocument;
    expect(btnScanUsingDocFeeder.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:Scan211Document_btnScanUsingDocFeeder"
      )
    );
  });
  test("Custom Test Cases for btnScanUsingDocFeeder", () => {
    // START_USER_CODE-USER_btnScanUsingDocFeeder_TEST
    // END_USER_CODE-USER_btnScanUsingDocFeeder_TEST
  });
  test("btnScanUsingFlatbed(Button Widget) Test Cases", async () => {
    const btnScanUsingFlatbed = screen.getByTestId(
      "btnScanUsingFlatbed"
    );
    expect(btnScanUsingFlatbed).toBeInTheDocument;
    expect(btnScanUsingFlatbed.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:Scan211Document_btnScanUsingFlatbed"
      )
    );
  });
  test("Custom Test Cases for btnScanUsingFlatbed", () => {
    // START_USER_CODE-USER_btnScanUsingFlatbed_TEST
    // END_USER_CODE-USER_btnScanUsingFlatbed_TEST
  });
  test("btnSignContractWithoutScanning(Button Widget) Test Cases", async () => {
    const btnSignContractWithoutScanning = screen.getByTestId(
      "btnSignContractWithoutScanning"
    );
    expect(btnSignContractWithoutScanning).toBeInTheDocument;
    expect(btnSignContractWithoutScanning.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:Scan211Document_btnSignContractWithoutScanning"
      )
    );
  });
  test("Custom Test Cases for btnSignContractWithoutScanning", () => {
    // START_USER_CODE-USER_btnSignContractWithoutScanning_TEST
    // END_USER_CODE-USER_btnSignContractWithoutScanning_TEST
  });
  test("btnVerifyAndSave(Button Widget) Test Cases", async () => {
    const btnVerifyAndSave = screen.getByTestId("btnVerifyAndSave");
    expect(btnVerifyAndSave).toBeInTheDocument;
    expect(btnVerifyAndSave.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:Scan211Document_btnVerifyAndSave"
      )
    );
  });
  test("Custom Test Cases for btnVerifyAndSave", () => {
    // START_USER_CODE-USER_btnVerifyAndSave_TEST
    // END_USER_CODE-USER_btnVerifyAndSave_TEST
  });
  test("grpbx211DocumentInformation(GroupBox Widget) Test Cases", async () => {
    const grpbx211DocumentInformation = screen.getByTestId(
      "grpbx211DocumentInformation"
    );
    expect(grpbx211DocumentInformation.tagName).toBe("BUTTON");
    expect(grpbx211DocumentInformation.type).toBe("button");
    expect(grpbx211DocumentInformation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx211DocumentInformation", () => {
    // START_USER_CODE-USER_grpbx211DocumentInformation_TEST
    // END_USER_CODE-USER_grpbx211DocumentInformation_TEST
  });
  test("grpbxSavingOptions(GroupBox Widget) Test Cases", async () => {
    const grpbxSavingOptions = screen.getByTestId("grpbxSavingOptions");
    expect(grpbxSavingOptions.tagName).toBe("BUTTON");
    expect(grpbxSavingOptions.type).toBe("button");
    expect(grpbxSavingOptions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSavingOptions", () => {
    // START_USER_CODE-USER_grpbxSavingOptions_TEST
    // END_USER_CODE-USER_grpbxSavingOptions_TEST
  });
  test("grpbxScan211Documents(GroupBox Widget) Test Cases", async () => {
    const grpbxScan211Documents = screen.getByTestId(
      "grpbxScan211Documents"
    );
    expect(grpbxScan211Documents.tagName).toBe("BUTTON");
    expect(grpbxScan211Documents.type).toBe("button");
    expect(grpbxScan211Documents.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxScan211Documents", () => {
    // START_USER_CODE-USER_grpbxScan211Documents_TEST
    // END_USER_CODE-USER_grpbxScan211Documents_TEST
  });
  test("grpbxScanningOptions(GroupBox Widget) Test Cases", async () => {
    const grpbxScanningOptions = screen.getByTestId(
      "grpbxScanningOptions"
    );
    expect(grpbxScanningOptions.tagName).toBe("BUTTON");
    expect(grpbxScanningOptions.type).toBe("button");
    expect(grpbxScanningOptions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxScanningOptions", () => {
    // START_USER_CODE-USER_grpbxScanningOptions_TEST
    // END_USER_CODE-USER_grpbxScanningOptions_TEST
  });
  test("grpbxVerificationResult(GroupBox Widget) Test Cases", async () => {
    const grpbxVerificationResult = screen.getByTestId(
      "grpbxVerificationResult"
    );
    expect(grpbxVerificationResult.tagName).toBe("BUTTON");
    expect(grpbxVerificationResult.type).toBe("button");
    expect(grpbxVerificationResult.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVerificationResult", () => {
    // START_USER_CODE-USER_grpbxVerificationResult_TEST
    // END_USER_CODE-USER_grpbxVerificationResult_TEST
  });
  test("lbl211DocumentInformation(Label Widget) Test Cases", async () => {
    const lbl211DocumentInformation = screen.getByTestId(
      "lbl211DocumentInformation"
    );
    expect(lbl211DocumentInformation.tagName).toBe("LABEL");
    expect(lbl211DocumentInformation.classList).toContain("form-label");
    expect(lbl211DocumentInformation.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:Scan211Document_lbl211DocumentInformation"
      )
    );
  });
  test("Custom Test Cases for lbl211DocumentInformation", () => {
    // START_USER_CODE-USER_lbl211DocumentInformation_TEST
    // END_USER_CODE-USER_lbl211DocumentInformation_TEST
  });
  test("lblSaving211Options(Label Widget) Test Cases", async () => {
    const lblSaving211Options = screen.getByTestId(
      "lblSaving211Options"
    );
    expect(lblSaving211Options.tagName).toBe("LABEL");
    expect(lblSaving211Options.classList).toContain("form-label");
    expect(lblSaving211Options.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:Scan211Document_lblSaving211Options"
      )
    );
  });
  test("Custom Test Cases for lblSaving211Options", () => {
    // START_USER_CODE-USER_lblSaving211Options_TEST
    // END_USER_CODE-USER_lblSaving211Options_TEST
  });
  test("lblScanningOptions(Label Widget) Test Cases", async () => {
    const lblScanningOptions = screen.getByTestId("lblScanningOptions");
    expect(lblScanningOptions.tagName).toBe("LABEL");
    expect(lblScanningOptions.classList).toContain("form-label");
    expect(lblScanningOptions.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:Scan211Document_lblScanningOptions"
      )
    );
  });
  test("Custom Test Cases for lblScanningOptions", () => {
    // START_USER_CODE-USER_lblScanningOptions_TEST
    // END_USER_CODE-USER_lblScanningOptions_TEST
  });
  test("txtActionType(Textbox Widget) Test Cases", async () => {
    const txtActionType = screen.getByTestId("txtActionType");
    expect(txtActionType.tagName).toBe("INPUT");
    expect(txtActionType.type).toBe("text");
    expect(txtActionType.classList).toContain("textboxWidgetClass");
    expect(txtActionType.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:Scan211Document_txtActionType")
    );
    await act(async () => {
      userEvent.type(txtActionType, "1");
    });
  });
  test("Custom Test Cases for txtActionType", () => {
    // START_USER_CODE-USER_txtActionType_TEST
    // END_USER_CODE-USER_txtActionType_TEST
  });
  test("txtDocumentType(Textbox Widget) Test Cases", async () => {
    const txtDocumentType = screen.getByTestId("txtDocumentType");
    expect(txtDocumentType.tagName).toBe("INPUT");
    expect(txtDocumentType.type).toBe("text");
    expect(txtDocumentType.classList).toContain("textboxWidgetClass");
    expect(txtDocumentType.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:Scan211Document_txtDocumentType"
      )
    );
    await act(async () => {
      userEvent.type(txtDocumentType, "1");
    });
  });
  test("Custom Test Cases for txtDocumentType", () => {
    // START_USER_CODE-USER_txtDocumentType_TEST
    // END_USER_CODE-USER_txtDocumentType_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:Scan211Document_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
});
