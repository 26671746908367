
/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  setValue,
  setData,
  getData,
  getValue,
  goTo,
  enable,
  disable
} from "../../shared/WindowImports";

import "./PremiumDeductionScheduleSetup.scss";
import Loading from "../../../Loader/Loading";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import {SystemMaintenanceMasterManagementService} from "../Service/SystemMaintenanceMasterManagementServices"
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_PremiumDeductionScheduleSetup(
  props
) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PremiumDeductionScheduleSetup",
    windowName: "PremiumDeductionScheduleSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.PremiumDeductionScheduleSetup",
    // START_USER_CODE-USER_PremiumDeductionScheduleSetup_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Premium Deduction Schedule Setup",
      scrCode: "PN0120A",
    },
    // END_USER_CODE-USER_PremiumDeductionScheduleSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxPremiumDeductionScheduleSetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btncmdEnableDisableControls: {
      name: "btncmdEnableDisableControls",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "cmdEnableDisableControls",
      CharWidth: "48",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncmdEnableDisableControls_PROPERTIES

      // END_USER_CODE-USER_btncmdEnableDisableControls_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxPremiumDeductionScheduleSetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxPremiumDeductionScheduleSetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxPremiumDeductionScheduleSetup",
      Label: "Buying Point:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    lblPDCode: {
      name: "lblPDCode",
      type: "LabelWidget",
      parent: "grpbxPremiumDeductionScheduleSetup",
      Label: "Premium Deduction Code:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPDCode_PROPERTIES

      // END_USER_CODE-USER_lblPDCode_PROPERTIES
    },
    lstPDCode: {
      name: "lstPDCode",
      type: "ListBoxFieldWidget",
      parent: "grpbxPremiumDeductionScheduleSetup",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstPDCode_PROPERTIES

      // END_USER_CODE-USER_lstPDCode_PROPERTIES
    },
    grpbxPremiumDeductionScheduleSetup: {
      name: "grpbxPremiumDeductionScheduleSetup",
      type: "GroupBoxWidget",
      parent: "PremiumDeductionScheduleSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForLargeDesktop: "44",
      // START_USER_CODE-USER_grpbxPremiumDeductionScheduleSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxPremiumDeductionScheduleSetup_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "PremiumDeductionScheduleSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
    FORWARD:{
      "SystemMaintenanceMasterManagement#PremiumDeductionScheduleProfile": {},
      "ContractManagement#MainMenu":{},
    },
    REVERSE:{
      "SystemMaintenanceMasterManagement#PremiumDeductionScheduleProfile": {},
      "ContractManagement#MainMenu":{},
    }
  }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAdd:{
    //   DEFAULT:[
    //   "SystemMaintenanceMasterManagement#PremiumDeductionScheduleProfile#DEFAULT#true#Click",]
    // },
    // btnEdit:{
    //   DEFAULT:[
    //   "SystemMaintenanceMasterManagement#PremiumDeductionScheduleProfile#DEFAULT#true#Click",]
    // },
    btnClose:{
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    }
  };
  let _winServices = {};
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, "ddBuyingPoint", ''),
    setValue(thisObj, "lstPDCode", '')
    Formload();
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
 
  useEffect(() => {
   if( getData(thisObj, 'AddUpdateData'))
   {
    bFillPDCodeList(getValue(thisObj,'ddBuyingPoint'))
    setData(thisObj, 'AddUpdateData', false)
   }
  }, [getData(thisObj, 'AddUpdateData')])
  
  let Formload = async () => {
    await bFillBuyingPointList(); // Method for binding Buying Points
     let buy_pt_id = getValue(thisObj, 'ddBuyingPoint')
     await bFillPDCodeList(buy_pt_id);// method for binding PD code List
     EnableDisableControls()
  }
    //Code for on change  click event 
  let onddBuyingPointChange = async() => {
      let buy_pt_id = getValue(thisObj,'ddBuyingPoint')
      await bFillPDCodeList(buy_pt_id)
        EnableDisableControls()
    }
  thisObj.onddBuyingPointChange = onddBuyingPointChange;
   // Enable Disable function
  const EnableDisableControls = () => {
    try{
      enable(thisObj, 'btnAdd')
      enable(thisObj, 'btnEdit')
      enable(thisObj, 'btnDelete')
      let buyingList =  getData(thisObj, 'pointLength');
      let codeList =  getData(thisObj, 'codeLength');
      if (buyingList <= 0) {
        disable(thisObj, "btnAdd")
        disable(thisObj, "btnAdd")
        disable(thisObj, "btnAdd")
      }
      else {
        enable(thisObj, "btnAdd")
      }
      if (codeList <= 0) {
        disable(thisObj, "btnEdit")
        disable(thisObj, "btnDelete")
      }
      else {
        enable(thisObj, "btnEdit")
        enable(thisObj, "btnDelete")
    }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred EnableDisable Function"
        );
      }
      return false;
    }
  }

  //Dropdown for Location List
 const bFillBuyingPointList = async () => {
  let response =  await ContractManagementService.RetrieveBuyingPointControlDetails('PN0120', null, null, null, null);
  let data = response;
  let obj = {};
  let js = [];
  if (data !== null && data !== undefined && data.length > 0) {
   for (let i = 0; i < data.length; i++) {
     let dataExist = js.find(elem => elem.key == data[i].buy_pt_id)
     if (dataExist == undefined) {
      obj={
        key: data[i].buy_pt_id, 
        description: data[i].buy_pt_id + " - " + data[i].buy_pt_name,
        index: i
      }
      js.push(obj);
      obj={}
     }
   }
  }
  setData(thisObj, 'pointLength', js.length);
  setValue(thisObj, 'ddBuyingPoint', js.at(0).key);
  thisObj.setState(current => {
   return {
     ...current,
     ddBuyingPoint: {
       ...state["ddBuyingPoint"],
       valueList: js,
     }
   }
 })
}
   //Dropddown for pd code List
  const bFillPDCodeList = async (buy_pt_id) => {
    setLoading(true)
        let js = [];
        let obj = {};
        let pd_code = null
        let inspect_ind = null
        let rate_type = null
        let check_enable_ind = null
        let rcpt_enable_ind = null
        let rpt_1099_ind = null
        let except_prem_ind = null
    let response = await  ContractManagementService.RetrivePremiumDeductionScheduleDetails(buy_pt_id,pd_code , inspect_ind, rate_type, check_enable_ind, rcpt_enable_ind, rpt_1099_ind, except_prem_ind)
    let data = response;
    if (data !== null && data !== undefined && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let dataExist = js.find(elem => elem.key == response[i].pdCode )
        if (dataExist == undefined) {
          obj =  { key: response[i].pdCode,
          description: '[' + response[i].applyOrder + ']' + ' ' + response[i].pdCode + ' - ' + response[i].pdDesc 
          }
          js.push(obj);
          obj = {};
        }
      }
    }
     setData(thisObj, 'codeLength', js.length);
     setData(thisObj, 'lstPDCode', js)
   if (js[0] != undefined) {
    setValue(thisObj, "lstPDCode", [js[0].key])
  }
    thisObj.setState(current => {
      return {
        ...current,
        lstPDCode: {
          ...state["lstPDCode"],
          valueList: js,
        }
      }
    })
    setLoading(false)
}

 //Click event of peanut type Add button
 const onbtnAddClick = () => {
   try {
    let buyingList = thisObj.state['ddBuyingPoint'].valueList
     let buy_pt_id = getValue(thisObj, "ddBuyingPoint");
    let buyingName = ''
    if (buy_pt_id !== undefined && buy_pt_id !== null && buy_pt_id !== '') {
      buyingName = buyingList.find(elem => elem.key == buy_pt_id).description
    }
    let frmPremiumDeductionScheduleProfile = {}
    frmPremiumDeductionScheduleProfile.cmdOk_Caption = 'Add'
    frmPremiumDeductionScheduleProfile.lblPayeeVendor_Caption = '';
    frmPremiumDeductionScheduleProfile.lblBuyingPoint_Tag = buy_pt_id;
    frmPremiumDeductionScheduleProfile.cmbPDCode_enabled = 'True'
    frmPremiumDeductionScheduleProfile.lblBuyingPoint_Caption = buyingName;
    frmPremiumDeductionScheduleProfile.EditMode = 'Add';
    setData(thisObj, 'frmPremiumDeductionScheduleProfile', frmPremiumDeductionScheduleProfile)
    goTo(thisObj, "SystemMaintenanceMasterManagement#PremiumDeductionScheduleProfile#DEFAULT#true#Click")
 }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred during Add button"
      );
    }
    return false;
  }
  EnableDisableControls()
  return true;
};
thisObj.onbtnAddClick = onbtnAddClick;

 //Click event of peanut type Edit button
const onbtnEditClick = async () => {
  let buyingList = thisObj.state['ddBuyingPoint'].valueList
    let buy_pt_id = getValue(thisObj, "ddBuyingPoint");
    let buyingName = ''
    if (buy_pt_id !== undefined && buy_pt_id !== null && buy_pt_id !== '') {
      buyingName = buyingList.find(elem => elem.key == buy_pt_id).description
    }
      let codeList = thisObj.state['lstPDCode'].valueList
      let pd_code = getValue(thisObj, "lstPDCode")
      let codeName = ''
      let codeIndex;
      if (pd_code !== undefined && pd_code !== null && pd_code !== '') {
        codeName = codeList.find(elem => elem.key == pd_code).description;
        codeIndex = codeList.find(elem => elem.key == pd_code).key;
      }
  let cmdEditType = "VIEW";
  let cmdOk_Caption = "Update"
  if (cmdEditType == "VIEW") {
    cmdOk_Caption = "false"
  }
  let response = await  ContractManagementService.RetrivePremiumDeductionScheduleDetails(buy_pt_id, pd_code, null, null, null, null, null, null)
  let data = response;
 try {
    let frmPremiumDeductionScheduleProfile = {}
    frmPremiumDeductionScheduleProfile.lstPDCodeDes = codeName
    frmPremiumDeductionScheduleProfile.lstPDCodeID = codeIndex
    frmPremiumDeductionScheduleProfile.cmdOk_Caption = "Update"
    frmPremiumDeductionScheduleProfile.lblPayeeVendor_Caption = ""
    frmPremiumDeductionScheduleProfile.lblBuyingPoint_Tag = buy_pt_id;
    frmPremiumDeductionScheduleProfile.lblBuyingPoint_Caption = buyingName;
    frmPremiumDeductionScheduleProfile.EditMode = "EDIT"
    frmPremiumDeductionScheduleProfile.cmbPDCode_enabled = false;
    frmPremiumDeductionScheduleProfile.lstPDcode = data[0].pdCode;
    frmPremiumDeductionScheduleProfile.lstInspectID = data[0].inspectInd;
    frmPremiumDeductionScheduleProfile.lstAutoInd = data[0].autoInd;
    frmPremiumDeductionScheduleProfile.lstRequiredInd = data[0].requiredInd;
    frmPremiumDeductionScheduleProfile.lstBeforeRecvInd = data[0].beforeRecvInd;
    frmPremiumDeductionScheduleProfile.txtApplyOrder = data[0].applyOrder;
  
    if (data[0].seg1Enable == 'Y') {
      frmPremiumDeductionScheduleProfile.chkSeg1Enable = '1'
    }
    else {
      frmPremiumDeductionScheduleProfile.chkSeg1Enable = ''
    }
    if (data[0].seg2Enable == 'Y') {
      frmPremiumDeductionScheduleProfile.chkSeg2Enable = '1'
    }
    else {
      frmPremiumDeductionScheduleProfile.chkSeg2Enable = ''
    }
    if (data[0].seg3Enable == 'Y') {
      frmPremiumDeductionScheduleProfile.chkSeg3Enable = '1'
    }
    else {
      frmPremiumDeductionScheduleProfile.chkSeg3Enable = ''
    }
   
    frmPremiumDeductionScheduleProfile.lstVendorSpecInd = data[0].vendSpecInd;
    frmPremiumDeductionScheduleProfile.lstContSpecInd = data[0].contSpecInd;
    frmPremiumDeductionScheduleProfile.lstRateTypeID = data[0].rateType;
    frmPremiumDeductionScheduleProfile.lstBaseWtID = data[0].baseWt;
    frmPremiumDeductionScheduleProfile.lstApplyType = data[0].applyType;
    frmPremiumDeductionScheduleProfile.txtBaseRate = data[0].baseRate;
    frmPremiumDeductionScheduleProfile.txtBasePercentage = data[0].basePct;
    frmPremiumDeductionScheduleProfile.txtPayeeVendor = data[0].payeeVendor;
    frmPremiumDeductionScheduleProfile.lstPayeeRemitTo = data[0].payeeRemit;
    let addDateTime = moment(data[0].addDateTime).format("MM/DD/YYYY");
    frmPremiumDeductionScheduleProfile.lblAddedBy = data[0].addUser + " " + addDateTime
    let chgDateTime = moment(data[0].chgDateTime).format("MM/DD/YYYY");
    frmPremiumDeductionScheduleProfile.lblChangedBy = data[0].chgUser.trim() + " " + chgDateTime
    setData(thisObj, 'frmPremiumDeductionScheduleProfile', frmPremiumDeductionScheduleProfile)
    goTo(thisObj, "SystemMaintenanceMasterManagement#PremiumDeductionScheduleProfile#DEFAULT#true#Click")
 }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred during Edit button"
      );
    }
    return false;
  }
  EnableDisableControls()
  return true;
};
thisObj.onbtnEditClick = onbtnEditClick;
//Click event of peanut type Delete button
  const onbtnDeleteClick = async () => {
    try {
      let buy_pt_id = getValue(thisObj, "ddBuyingPoint")
      let pdCode = getValue(thisObj, "lstPDCode")
      let TypeList = thisObj.state['lstPDCode'].valueList
      let codeName = ''
      let codeIndex;
      if (pdCode !== undefined && pdCode !== null && pdCode !== '') {
        codeName = TypeList.find(elem => elem.key == pdCode).description;
        codeIndex = TypeList.find(elem => elem.key == pdCode).index;
      }
      if (pdCode == "") {
        return false;
      }
      else {
        const confirmBox = window.confirm(`Are you sure you wish to delete this PD Code '${codeName}'`)
        if (confirmBox == true) {
          let response = await SystemMaintenanceMasterManagementService.RemovePremiumDeductionScheduleDetails(buy_pt_id,pdCode);
          if (![200, 400, 404].includes(response.status)) {
            showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
          }
          else {
            showMessage(thisObj, response.message);
          }
         await  bFillPDCodeList( buy_pt_id)
          EnableDisableControls()
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during executing delete button"
        );
      }
    }
  };
  thisObj.onbtnDeleteClick = onbtnDeleteClick;
     
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
             <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PremiumDeductionScheduleSetup*/}

              {/* END_USER_CODE-USER_BEFORE_PremiumDeductionScheduleSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPremiumDeductionScheduleSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPremiumDeductionScheduleSetup*/}

              <GroupBoxWidget
                conf={state.grpbxPremiumDeductionScheduleSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_lblPDCode*/}

                {/* END_USER_CODE-USER_BEFORE_lblPDCode*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPDCode}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPDCode*/}

                {/* END_USER_CODE-USER_AFTER_lblPDCode*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_lstPDCode*/}

                {/* END_USER_CODE-USER_BEFORE_lstPDCode*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstPDCode}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstPDCode*/}

                {/* END_USER_CODE-USER_AFTER_lstPDCode*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPremiumDeductionScheduleSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPremiumDeductionScheduleSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btncmdEnableDisableControls*/}

                {/* END_USER_CODE-USER_BEFORE_btncmdEnableDisableControls*/}

                <ButtonWidget
                  conf={state.btncmdEnableDisableControls}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btncmdEnableDisableControls*/}

                {/* END_USER_CODE-USER_AFTER_btncmdEnableDisableControls*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_PremiumDeductionScheduleSetup*/}

              {/* END_USER_CODE-USER_AFTER_PremiumDeductionScheduleSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_PremiumDeductionScheduleSetup
);
