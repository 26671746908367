/* eslint-disable*/
import React from "react";
import TreeWidget from "./TreeWidget";
import { screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("TreeWidget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
    let treewidget8= {
        name: "treewidget8",
        type: "TreeWidget",
        parent: "groupboxwidget7",
        Label: "treewidget8",
        Height: "",
        Width: "",
        TreeMetaData: [
          {
            showCheckbox: true,
            value: "P1",
            label: "P1",
            children: [
              {
                showCheckbox: true,
                value: "C1",
                label: "c1",
                children: [
                  {
                    showCheckbox: true,
                    value: "GC1",
                    label: "Gc1",
                  },
                  {
                    showCheckbox: true,
                    value: { GC2: 1 },
                    label: "Gc2",
                  },
                ],
              },
            ],
          },
          {
            showCheckbox: true,
            value: "P2",
            label: "P2",
          },
        ],
      };
      let stateScreenConf = {
        Label: "ChildScreen",
        windowName: "ChildScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestABSGUI.ChildScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      let thisObj={};
      renderTestScreen(
        <TreeWidget
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={treewidget8}
                  screenConf={stateScreenConf}
                ></TreeWidget>
      );
    });
  });
  afterEach(cleanup);
  test("Is Treewidget Loads Succesfully", async () => {
    expect(screen.getByText("P1")).toBeInTheDocument;
  });
});

