/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  hideWidgets,
  setValue,
  getData,
  getValue,
  disable,
  setData,
  show
} from "../../shared/WindowImports";
import Loading from "../../../Loader/Loading";

import "./WarehouseBinCopy.scss";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_WarehouseBinCopy(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "WarehouseBinCopy",
    windowName: "WarehouseBinCopy",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.WarehouseBinCopy",
    // START_USER_CODE-USER_WarehouseBinCopy_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Warehouse And Bin",
      scrCode: "PN0090C",
    },
    // END_USER_CODE-USER_WarehouseBinCopy_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCopy: {
      name: "btnCopy",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Copy",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCopy_PROPERTIES

      // END_USER_CODE-USER_btnCopy_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "grpbxWarehouseBinCopy",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    ddLocation1: {
      name: "ddLocation1",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation1_PROPERTIES

      // END_USER_CODE-USER_ddLocation1_PROPERTIES
    },
    ddLocation2: {
      name: "ddLocation2",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation2_PROPERTIES

      // END_USER_CODE-USER_ddLocation2_PROPERTIES
    },
    ddLocation3: {
      name: "ddLocation3",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation3_PROPERTIES

      // END_USER_CODE-USER_ddLocation3_PROPERTIES
    },
    ddLocation4: {
      name: "ddLocation4",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation4_PROPERTIES

      // END_USER_CODE-USER_ddLocation4_PROPERTIES
    },
    ddLocation5: {
      name: "ddLocation5",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation5_PROPERTIES

      // END_USER_CODE-USER_ddLocation5_PROPERTIES
    },
    ddLocation6: {
      name: "ddLocation6",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation6_PROPERTIES

      // END_USER_CODE-USER_ddLocation6_PROPERTIES
    },
    ddLocation7: {
      name: "ddLocation7",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation7_PROPERTIES

      // END_USER_CODE-USER_ddLocation7_PROPERTIES
    },
    ddLocation8: {
      name: "ddLocation8",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation8_PROPERTIES

      // END_USER_CODE-USER_ddLocation8_PROPERTIES
    },
    ddLocation9: {
      name: "ddLocation9",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation9_PROPERTIES

      // END_USER_CODE-USER_ddLocation9_PROPERTIES
    },
    ddLocation10: {
      name: "ddLocation10",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation10_PROPERTIES

      // END_USER_CODE-USER_ddLocation10_PROPERTIES
    },
    grpbxBuyingPoint: {
      name: "grpbxBuyingPoint",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseBinCopy",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_grpbxBuyingPoint_PROPERTIES
    },
    grpbxCopyTo: {
      name: "grpbxCopyTo",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseBinCopy",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxCopyTo_PROPERTIES

      // END_USER_CODE-USER_grpbxCopyTo_PROPERTIES
    },
    lblCopyTo: {
      name: "lblCopyTo",
      type: "LabelWidget",
      parent: "grpbxWarehouseBinCopy",
      Label: "Copy To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCopyTo_PROPERTIES

      // END_USER_CODE-USER_lblCopyTo_PROPERTIES
    },
    lstWarehouse: {
      name: "lstWarehouse",
      type: "ListBoxFieldWidget",
      parent: "grpbxBuyingPoint",
      Label: "Warehouse #:",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstWarehouse_PROPERTIES

      // END_USER_CODE-USER_lstWarehouse_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxBuyingPoint",
      Label: "Buying Pt.:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    grpbxWarehouseBinCopy: {
      name: "grpbxWarehouseBinCopy",
      type: "GroupBoxWidget",
      parent: "WarehouseBinCopy",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxWarehouseBinCopy_PROPERTIES

      // END_USER_CODE-USER_grpbxWarehouseBinCopy_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    initialVendorlocationide()
    Form_Load()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const initialVendorlocationide = () => {
    setLoading(true)
    let VendorSplitsHideArray=[]
    for(let i=4;i<=10;i++){
      VendorSplitsHideArray.push(`ddLocation${i}`)
    }
    hideWidgets(thisObj,VendorSplitsHideArray)
    setLoading(false)
  }
  const Form_Load = async () => {
    let data = getData(thisObj,'frmWarehouseBincopydata')
    setValue(thisObj,'txtBuyingPoint',data.cmbBuyingPoint)
    let js = [];
    let obj={key:data[0].txtWhs+'-'+data[0].txtBin+'-'+data[0].txtDescription, description:data[0].txtWhs+'-'+data[0].txtBin+'-'+data[0].txtDescription}
    js.push(obj)
    thisObj.setState(current => {
      return {
        ...current,
        lstWarehouse: {
          ...state["lstWarehouse"],
          valueList: js
        }
      }

    })
    setValue(thisObj,'lstWarehouse',[js.at(0).key])
    disable(thisObj,'txtBuyingPoint')
    disable(thisObj,'lstWarehouse')
    setData(thisObj, "arrRow", [])
    bFillLocationList()
  }
  const bFillLocationList = async () => {
    let js = []
    let obj = { key: '', description: ">>> All Locations <<<" }
    js.push(obj)
    let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0030', null, null, null, null)
    let data = response
    for (var i = 0; i < data.length; i++) {
      let obj = {
        key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim()
      }
      js.push(obj)
    }
    for (let j = 0; j < 10; j++) { 
      let setlocation = "ddLocation" + (j + 1)
      thisObj.state[setlocation].valueList = js;
      setValue(thisObj,setlocation,js.at(0).key)
    }
    
  }
  const onbtnCloseClick =()=>{ 
    document.getElementById("SystemMaintenanceMasterManagement_WarehouseBinCopyPopUp").childNodes[0].click();
  }
  thisObj.onbtnCloseClick =onbtnCloseClick
  
  const onbtnCopyClick = async()=>{
    let data = getData(thisObj,'frmWarehouseBincopydata')
    let txtBuyingPoint =data.lblBuyingPoint
    let from_warehouse =data[0].txtWhs+';'+data[0].txtBin+'|'
    let new_buy_pts=[]
    let msFromBP=data.lblBuyingPoint
    let msFromWB =from_warehouse
    if(msFromBP ==''){
      alert('Buying Point for copy-from warehouse is not set.')
    }
    if(msFromWB ==''){
      alert('Warehouse for copy-from warehouse is not set.')
    }
    for (let j = 0; j < 10; j++) { 
      let setlocation = "ddLocation" + (j + 1)
      let getlocation=getValue(thisObj,setlocation)
      if(getlocation !=='' && getlocation !==undefined && getlocation !==''){
        new_buy_pts.push(getlocation)
      }
    }
    let buyind_data=new_buy_pts.join('|')+"|"
    if(new_buy_pts.length>0){
    let response = await SystemMaintenanceMasterManagementService.RetrieveWareHouseBinInventoryCopyDetails(txtBuyingPoint,from_warehouse,buyind_data)
    if(response.length>0){
      showMessage(thisObj,response[0].exist_warehouse,true)

    }else{
      showMessage(thisObj,'Problem with copying warehouse and bin.')
    }
  }
  }
  thisObj.onbtnCopyClick =onbtnCopyClick

  const getnewlocation = async () => {
        let vndrRowNo = getData(thisObj, "vndrRowNo")
        let showset=false
        for (let j = 0; j < 10; j++) { 
          let setlocation = "ddLocation" + (j + 1)
          let getlocation=getValue(thisObj,setlocation)
          if(getlocation !=='' && getlocation !==undefined && getlocation !==''){
            showset =true
          }
        }
        if (vndrRowNo >= 3 && showset ==true) {
          let newGrpbx = `ddLocation` + (Number(vndrRowNo) + 1) 
          show(thisObj, newGrpbx)
        }
  }

  const ddLocationBlur = (event) => { 
    let cntrlId = event.target.name
    let newCntrl = cntrlId.match(/(\d+)/)[0]
    setData(thisObj, "vndrRowNo", newCntrl)
    getnewlocation()
  
  }
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
            <Loading loading={loading}></Loading>
              {/* START_USER_CODE-USER_BEFORE_WarehouseBinCopy*/}

              {/* END_USER_CODE-USER_BEFORE_WarehouseBinCopy*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxWarehouseBinCopy*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxWarehouseBinCopy*/}

              <GroupBoxWidget
                conf={state.grpbxWarehouseBinCopy}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxBuyingPoint*/}

                <GroupBoxWidget
                  conf={state.grpbxBuyingPoint}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyingPoint}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyingPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_lstWarehouse*/}

                  {/* END_USER_CODE-USER_BEFORE_lstWarehouse*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstWarehouse}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstWarehouse*/}

                  {/* END_USER_CODE-USER_AFTER_lstWarehouse*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_grpbxBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_lblCopyTo*/}

                {/* END_USER_CODE-USER_BEFORE_lblCopyTo*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCopyTo}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCopyTo*/}

                {/* END_USER_CODE-USER_AFTER_lblCopyTo*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxCopyTo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCopyTo*/}

                <GroupBoxWidget conf={state.grpbxCopyTo} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddLocation1*/}

                  {/* END_USER_CODE-USER_BEFORE_ddLocation1*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation1}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation1*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation1*/}
                  {/* START_USER_CODE-USER_BEFORE_ddLocation2*/}

                  {/* END_USER_CODE-USER_BEFORE_ddLocation2*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation2}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation2*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation2*/}
                  {/* START_USER_CODE-USER_BEFORE_ddLocation3*/}

                  {/* END_USER_CODE-USER_BEFORE_ddLocation3*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation3}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation3*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation3*/}
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation4}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation4*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation4*/}
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation5}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation5*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation5*/}
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation6}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation6*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation6*/}
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation7}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation7*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation7*/}
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation8}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation8*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation8*/}
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation9}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation10}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation10*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation10*/}
                  {/* START_USER_CODE-USER_AFTER_ddLocation9*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation9*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCopyTo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCopyTo*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnCopy*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCopy*/}

                  <ButtonWidget
                    conf={state.btnCopy}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCopy*/}

                  {/* END_USER_CODE-USER_AFTER_btnCopy*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                  <ButtonWidget
                    conf={state.btnClose}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClose*/}

                  {/* END_USER_CODE-USER_AFTER_btnClose*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxWarehouseBinCopy*/}

              {/* END_USER_CODE-USER_AFTER_grpbxWarehouseBinCopy*/}

              {/* START_USER_CODE-USER_AFTER_WarehouseBinCopy*/}

              {/* END_USER_CODE-USER_AFTER_WarehouseBinCopy*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_WarehouseBinCopy);
