/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  DateWidget,
  setData,
  getData,
  setValue,
  disable,
  getValue,
} from "../../shared/WindowImports";

import "./SpotControlProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_SpotControlProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SpotControlProfile",
    windowName: "SpotControlProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.SpotControlProfile",
    // START_USER_CODE-USER_SpotControlProfile_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Spot Price Profile",
      scrCode: "PN0110B",
    },
    // END_USER_CODE-USER_SpotControlProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxSpotControlProfile",
      Label: "Buying Point:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES
      Readonly:true,

      Enabled:false,
      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    txtEffectiveDate: {
      name: "txtEffectiveDate",
      type: "DateTimeWidget",
      parent: "grpbxSpotControlProfile",
      Label: "Effective Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_txtEffectiveDate_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpbxSpotControlProfile",
      Label: "Peanut Type:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES
      Readonly:true,

      Enabled:false,
      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtSeg1Spot: {
      name: "txtSeg1Spot",
      type: "TextBoxWidget",
      parent: "grpbxSpotControlProfile",
      Label: "Seg1 Spot:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg1Spot_PROPERTIES

      // END_USER_CODE-USER_txtSeg1Spot_PROPERTIES
    },
    txtSeg2EditableSpot: {
      name: "txtSeg2EditableSpot",
      type: "TextBoxWidget",
      parent: "grpbxSpotControlProfile",
      Label: "Seg2 Editable Spot:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg2EditableSpot_PROPERTIES

      // END_USER_CODE-USER_txtSeg2EditableSpot_PROPERTIES
    },
    txtSeg2OilSpot: {
      name: "txtSeg2OilSpot",
      type: "TextBoxWidget",
      parent: "grpbxSpotControlProfile",
      Label: "Seg2 Oil Spot:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg2OilSpot_PROPERTIES

      // END_USER_CODE-USER_txtSeg2OilSpot_PROPERTIES
    },
    txtSeg2Spot: {
      name: "txtSeg2Spot",
      type: "TextBoxWidget",
      parent: "grpbxSpotControlProfile",
      Label: "Seg2 Spot:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg2Spot_PROPERTIES

      // END_USER_CODE-USER_txtSeg2Spot_PROPERTIES
    },
    txtSeg3EdibleSpot: {
      name: "txtSeg3EdibleSpot",
      type: "TextBoxWidget",
      parent: "grpbxSpotControlProfile",
      Label: "Seg3 Edible Spot:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg3EdibleSpot_PROPERTIES

      // END_USER_CODE-USER_txtSeg3EdibleSpot_PROPERTIES
    },
    txtSeg3OilSpot: {
      name: "txtSeg3OilSpot",
      type: "TextBoxWidget",
      parent: "grpbxSpotControlProfile",
      Label: "Seg3 Oil Spot:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg3OilSpot_PROPERTIES

      // END_USER_CODE-USER_txtSeg3OilSpot_PROPERTIES
    },
    txtSeg3Spot: {
      name: "txtSeg3Spot",
      type: "TextBoxWidget",
      parent: "grpbxSpotControlProfile",
      Label: "Seg3 Spot:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg3Spot_PROPERTIES

      // END_USER_CODE-USER_txtSeg3Spot_PROPERTIES
    },
    grpbxSpotControlProfile: {
      name: "grpbxSpotControlProfile",
      type: "GroupBoxWidget",
      parent: "SpotControlProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSpotControlProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxSpotControlProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "SpotControlProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formload();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  function formload() {
    try {
      let dataFrmParent = getData(thisObj,"SpotControlSetupData");
      if(dataFrmParent.btnOk=="Add" ){
        setValue(thisObj,"txtBuyingPoint",dataFrmParent.buy_pt_name)
        setValue(thisObj,"txtPeanutType",dataFrmParent.Pnut_type_name)
        document.getElementById('btnOk').innerText = "Add";
      }
      else if(dataFrmParent.btnOk=="Update"){
        disable(thisObj,"txtEffectiveDate")
        setValue(thisObj,"txtEffectiveDate",dataFrmParent.txtEffectiveDateTime)
        setValue(thisObj,"txtBuyingPoint",dataFrmParent.buy_pt_name)
        setValue(thisObj,"txtPeanutType",dataFrmParent.Pnut_type_name)
        setValue(thisObj,"txtSeg1Spot",dataFrmParent.txtSeg1Spot)
        setValue(thisObj,"txtSeg2Spot",dataFrmParent.txtSeg2Spot)
        setValue(thisObj,"txtSeg2EditableSpot",dataFrmParent.txtSeg2EditableSpot)
        setValue(thisObj,"txtSeg2OilSpot",dataFrmParent.txtSeg2OilSpot)
        setValue(thisObj,"txtSeg3Spot",dataFrmParent.txtSeg3Spot)
        setValue(thisObj,"txtSeg3EdibleSpot",dataFrmParent.txtSeg3EditableSpot)
        setValue(thisObj,"txtSeg3OilSpot",dataFrmParent.txtSeg3OilSpot)
        setValue(thisObj,"lblAddedByValue",dataFrmParent.lblAddedBy + ' ' + moment(dataFrmParent.lblAddedDate).format('MM/DD/YYYY'))
        setValue(thisObj,"lblChangedByValue",dataFrmParent.lblChangedBy + ' ' + moment(dataFrmParent.lblChangedDate).format('MM/DD/YYYY'))
        document.getElementById('btnOk').innerText = "Update";
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const FormValid = () => {
    try {
      var bFormValid = true;
      let effectiveDate = getValue(thisObj, "txtEffectiveDate")
      let txtSeg1Spot = getValue(thisObj, 'txtSeg1Spot')
      let txtSeg2Spot = getValue(thisObj, 'txtSeg2Spot')
      let txtSeg2EditableSpot = getValue(thisObj, 'txtSeg2EditableSpot')
      let txtSeg2OilSpot = getValue(thisObj, 'txtSeg2OilSpot')
      let txtSeg3Spot = getValue(thisObj, 'txtSeg3Spot')
      let txtSeg3EdibleSpot = getValue(thisObj, 'txtSeg3EdibleSpot')
      let txtSeg3OilSpot = getValue(thisObj, 'txtSeg3OilSpot')
      
      if (effectiveDate == null || effectiveDate == "" ) {
        showMessage(thisObj,'Invalid Effective Date Time')
        bFormValid = false;
        return bFormValid;
      }
      
      // validation for seg1 spot
      if (txtSeg1Spot == null || txtSeg1Spot == "" || (txtSeg1Spot == undefined)) {
        showMessage(thisObj,'Seg1 Spot is a required field')
        bFormValid = false;
        return bFormValid;
      }

      if (!isNaN(txtSeg1Spot)) {
        if (Number(txtSeg1Spot) < 0 || Number(txtSeg1Spot) > 9999.99) {
          showMessage(thisObj, "Seg1 Spot must be of format ####.##")
          bFormValid = false;
          return bFormValid;
        }
      } else {
        if (Number(txtSeg1Spot) != 0) {
          showMessage(thisObj, "Seg1 Spot must be of format ####.##")
          bFormValid = false;
          return bFormValid;
        }
      }
                
      //validation for seg2 spot
      if (txtSeg2Spot == '' || txtSeg2Spot == null || txtSeg2Spot == undefined){
        setValue(thisObj, 'txtSeg2Spot', "0")
      } else {
        if (!isNaN(txtSeg2Spot)) {
          if (Number(txtSeg2Spot) < 0 || Number(txtSeg2Spot) > 9999.99 ) {
            showMessage(thisObj, "seg2 Spot must be of format ####.##")
            bFormValid = false;
            return bFormValid;
          }
        } else {
          if (Number(txtSeg2Spot) != 0) {
            showMessage(thisObj, "seg2 Spot must be of format ####.##")
            bFormValid = false;
            return bFormValid;
          }
        }  
      }

      //validation for seg2 Editable spot
      if (txtSeg2EditableSpot == '' || txtSeg2EditableSpot == null || txtSeg2EditableSpot == undefined){
        setValue(thisObj, 'txtSeg2EditableSpot', "0")
      } else {
        if (!isNaN(txtSeg2EditableSpot)) {
          if (Number(txtSeg2EditableSpot) < 0 || Number(txtSeg2EditableSpot) > 9999.99 ) {
            showMessage(thisObj, "seg2 Editable Spot must be of format ####.##")
            bFormValid = false;
            return bFormValid;
          }
        } else {
          if (Number(txtSeg2EditableSpot) != 0) {
            showMessage(thisObj, "seg2 Editable Spot must be of format ####.##")
            bFormValid = false;
            return bFormValid;
          }
        }  
      }

      //validation for Seg2OilSpot spot
      if (txtSeg2OilSpot == '' || txtSeg2OilSpot == undefined || txtSeg2OilSpot == null){
        setValue(thisObj, 'txtSeg2OilSpot', "0")
      } else {
        if (!isNaN(txtSeg2OilSpot)) {
          if (Number(txtSeg2OilSpot) < 0 || Number(txtSeg2OilSpot) > 9999.99 ) {
            showMessage(thisObj, "seg2 Oil Spot must be of format ####.##")
            bFormValid = false;
            return bFormValid;
          }
        } else {
          if (Number(txtSeg2OilSpot) != 0) {
            showMessage(thisObj, "seg2 Oil Spot must be of format ####.##")
            bFormValid = false;
            return bFormValid;
          }
        }  
      }

      //validation for seg3 spot
      if (txtSeg3Spot == '' || txtSeg3Spot == undefined || txtSeg3Spot == null){
        setValue(thisObj, 'txtSeg3Spot', "0")
      } else {
        if (!isNaN(txtSeg3Spot)) {
          if (Number(txtSeg3Spot) < 0 || Number(txtSeg3Spot) > 9999.99 ) {
            showMessage(thisObj, "seg3 Spot must be of format ####.##")
            bFormValid = false;
            return bFormValid;
          }
        } else {
          if (Number(txtSeg3Spot) != 0) {
            showMessage(thisObj, "seg3 Spot must be of format ####.##")
            bFormValid = false;
            return bFormValid;
          }
        }  
      }

      //validation for Seg3 Editable Spot
      if (txtSeg3EdibleSpot == '' || txtSeg3EdibleSpot == undefined || txtSeg3EdibleSpot == null){
        setValue(thisObj, 'txtSeg3EdibleSpot', "0")
      } else {
        if (!isNaN(txtSeg3EdibleSpot)) {
          if (Number(txtSeg3EdibleSpot) < 0 || Number(txtSeg3EdibleSpot) > 9999.99 ) {
            showMessage(thisObj, "Seg3 Editable Spot must be of format ####.##")
            bFormValid = false;
            return bFormValid;
          }
        } else {
          if (Number(txtSeg3EdibleSpot) != 0) {
            showMessage(thisObj, "Seg3 Editable Spot must be of format ####.##")
            bFormValid = false;
            return bFormValid;
          }
        }  
      }

      //validation for seg3 Oil spot
      if (txtSeg3OilSpot == '' || txtSeg3OilSpot == undefined || txtSeg3OilSpot == null){
        setValue(thisObj, 'txtSeg3OilSpot', "0")
      } else {
        if (!isNaN(txtSeg3OilSpot)) {
          if (Number(txtSeg3OilSpot) < 0 || Number(txtSeg3OilSpot) > 9999.99 ) {
            showMessage(thisObj, "seg3 Oil Spot must be of format ####.##")
            bFormValid = false;
            return bFormValid;
          }
        } else {
          if (Number(txtSeg3OilSpot) != 0) {
            showMessage(thisObj, "seg3 Oil Spot must be of format ####.##")
            bFormValid = false;
            return bFormValid;
          }
        }  
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }

  const onbtnOkClick = async () => {
    try {
      if (FormValid() == false) {
        return;
      } else {
        let dataFrmParent= getData(thisObj,"SpotControlSetupData");
        let buyingPoint = dataFrmParent.buy_pt_id;
        let peanutType_id = dataFrmParent.Pnut_type_id;
        let cmdOk_Caption = dataFrmParent.btnOk;
        let seg1spot = getValue(thisObj,"txtSeg1Spot");
        let seg2spot = getValue(thisObj,"txtSeg2Spot");
        let seg2ediblespot = getValue(thisObj,"txtSeg2EditableSpot");
        let seg2oilspot = getValue(thisObj,"txtSeg2OilSpot")
        let seg3spot= getValue(thisObj,"txtSeg3Spot")
        let seg3ediblespot = getValue(thisObj,"txtSeg3EdibleSpot")
        let seg3oilspot = getValue(thisObj,"txtSeg3OilSpot")
        let profile_effective_date1 = getValue(thisObj,'txtEffectiveDate') 
        const dateTime = new Date(profile_effective_date1)
        const effect_date_time = moment(dateTime).format("yyyy-MM-DD HH:mm")
        
        if(cmdOk_Caption=='Add'){
          let temp = {
            "pnuttypeid":peanutType_id,
            "effectdatetime": effect_date_time,
            "seg1spot": seg1spot == (undefined || null || "" ) ? "0" : seg1spot,
            "seg2spot": seg2spot == (undefined || null || "" ) ? "0" : seg2spot,
            "seg2ediblespot": seg2ediblespot == (undefined || null || "" ) ? "0" : seg2ediblespot,
            "seg2oilspot": seg2oilspot == (undefined || null || "" ) ? "0" : seg2oilspot,
            "seg3spot": seg3spot == (undefined || null || "" ) ? "0" : seg3spot,
            "seg3ediblespot": seg3ediblespot == (undefined || null || "" ) ? "0" : seg3ediblespot,
            "seg3oilspot": seg3oilspot == (undefined || null || "" ) ? "0" : seg3oilspot
          }
          let response = await SystemMaintenanceSpecialFunctionsService.CreateSpotPriceControl(buyingPoint, temp)
          let data = response
          if (data.status == 404 || data.status == 500 || data.status == null || data.status == '') {
            showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists."+ response.message)
            return;
          } else {
            if(response.status==200){
              showMessage(thisObj,response.message,true)
            }
            else{
              showMessage(thisObj,response.message)
            }
          }
        } else if(cmdOk_Caption=='Update' ){
          let temp={
            "seg1spot": seg1spot == (undefined || null || "" )? "0" : seg1spot,
            "seg2spot": seg2spot ==(undefined || null || "" )? "0" : seg2spot,
            "seg2ediblespot": seg2ediblespot == (undefined || null || "" ) ? "0" : seg2ediblespot,
            "seg2oilspot": seg2oilspot == (undefined || null || "" ) ? "0" : seg2oilspot,
            "seg3spot": seg3spot == (undefined || null || "" ) ? "0" : seg3spot,
            "seg3ediblespot": seg3ediblespot == (undefined || null || "" ) ? "0" : seg3ediblespot,
            "seg3oilspot": seg3oilspot == (undefined || null || "" ) ? "0" : seg3oilspot
          };
          let response= await SystemMaintenanceSpecialFunctionsService.UpdateSpotPriceControl(buyingPoint,peanutType_id,profile_effective_date1,temp);
          if (![200, 400, 404].includes(response.status)) {
            showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
          } else {
            if(response.status==200){
              showMessage(thisObj,response.message,true)
            }
            else{
              showMessage(thisObj,response.message)
            }
          }
        }
        setData(thisObj, 'closingOfSpotControlProfile', true)
        document.getElementById("SystemMaintenanceSpecialFunctions_SpotControlProfilePopUp").childNodes[0].click();
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnOkClick= onbtnOkClick;

  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_SpotControlProfilePopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
    
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SpotControlProfile*/}

              {/* END_USER_CODE-USER_BEFORE_SpotControlProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSpotControlProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSpotControlProfile*/}

              <GroupBoxWidget
                conf={state.grpbxSpotControlProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBuyingPoint}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_txtBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txtPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_txtPeanutType*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutType}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_txtPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEffectiveDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_AFTER_txtEffectiveDate*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeg1Spot*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeg1Spot*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg1Spot}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeg1Spot*/}

                {/* END_USER_CODE-USER_AFTER_txtSeg1Spot*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeg2Spot*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeg2Spot*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg2Spot}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeg2Spot*/}

                {/* END_USER_CODE-USER_AFTER_txtSeg2Spot*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeg2EditableSpot*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeg2EditableSpot*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg2EditableSpot}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeg2EditableSpot*/}

                {/* END_USER_CODE-USER_AFTER_txtSeg2EditableSpot*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeg2OilSpot*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeg2OilSpot*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg2OilSpot}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeg2OilSpot*/}

                {/* END_USER_CODE-USER_AFTER_txtSeg2OilSpot*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeg3Spot*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeg3Spot*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg3Spot}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeg3Spot*/}

                {/* END_USER_CODE-USER_AFTER_txtSeg3Spot*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeg3EdibleSpot*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeg3EdibleSpot*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg3EdibleSpot}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeg3EdibleSpot*/}

                {/* END_USER_CODE-USER_AFTER_txtSeg3EdibleSpot*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeg3OilSpot*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeg3OilSpot*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg3OilSpot}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeg3OilSpot*/}

                {/* END_USER_CODE-USER_AFTER_txtSeg3OilSpot*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSpotControlProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSpotControlProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_SpotControlProfile*/}

              {/* END_USER_CODE-USER_AFTER_SpotControlProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_SpotControlProfile);
