/* eslint-disable*/
export const ExcelNumericDataConvert = (number, specialCharacters, fraction) => {
  let formattedValue;
  if (number != "" && number != undefined && number != null) {
    let orgValue = number;
    if (specialCharacters != "" && specialCharacters != undefined && specialCharacters != null) {
      if (specialCharacters.length > 0) {
        for (let i = 0; i < specialCharacters.length; i++) {
          number = number.replaceAll(specialCharacters[i], '');
        }
      }
    }

    if (!isNaN(parseFloat(number)) && isFinite(number)) {
      if (number.toString().indexOf('.') != -1) {
        if (fraction != "" && fraction != undefined && fraction != null) {
          formattedValue = parseFloat(number).toFixed(fraction);
          formattedValue = parseFloat(formattedValue);
        }
        else {
          formattedValue = parseFloat(number);
        }
      }
      else {
        formattedValue = parseInt(number);
      }
    }
    else {
      formattedValue = orgValue;
    }
  }
  else {
    formattedValue = number;
  }
  return formattedValue;
}
export const Searchspecial = (value) => {
  const search = '#';
  const replaceWith = '~';
  let replaceWithvalue = ''
  if( value !==''&& value !==null && value !==undefined && search == '#'){
    replaceWithvalue = value.split(search).join(replaceWith);
  }else{
    replaceWithvalue = value
  }
  return replaceWithvalue;
}
export const SearchspecialLess = (value) => {
  const searchgreater = '%3C%3D';
  const replaceWithgreater = '%3C~%3D';
  // const searchless = '%3E%3C';
  // const replaceWithless = '%3E~%3C';
  const searchgreaterequal = '%3E%3D';
  const replacegreaterequal = '%3E~%3D';
  let replaceWithvalue = value
  let replacewithgreater=value.split(searchgreater).join(replaceWithgreater);
  // let replacewithless=value.split(searchless).join(replaceWithless);
  let replacegreate_requal=value.split(searchgreaterequal).join(replacegreaterequal);

  if( replacewithgreater !==value){
    replaceWithvalue = replacewithgreater;
  }
  // if( replacewithless !==value){
  //   replaceWithvalue =replacewithless;
  // }
  if( replacegreate_requal !==value){
    replaceWithvalue =replacegreate_requal;
  }
  return replaceWithvalue;
}
export const Formatthevalue = (value) => {
  var cont = ''
  let formatvalue = ''
  if (value > 0) {
    if (value.length <= 6) {
      for (var i = 0; i < (6 - value.length); i++) {
        cont += '0'
      }
    }
    formatvalue = cont + value;
  } 
  return formatvalue;
}
export const Alertmessage = (alertmessage,item) => {
  for (var i = 0; i < item?.length; i++) {
    alertmessage += (i + 1) + '. ' + item[i] + "\n";
  }
  return alertmessage;
}
export const PopupBreak = (alertmessage,item) => {
  for (var i = 0; i < item?.length; i++) {
    alertmessage += item[i] + "\n";
  }
  return alertmessage;
}

export function FormatNumber(value, fraction = 2) {
  let formattedNo;
  if (value != null && value != undefined) {
    if (value.toString().indexOf(",") == -1) {
      formattedNo = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: fraction,
        maximumFractionDigits: fraction,
      }).format(value);
    } else {
      formattedNo = value.toString();
    }
  } else {
    formattedNo = "";
  }
  return formattedNo;
}
//Function to convert to Pascal Case

export function ConvertToPascalCase(word){
  return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});

}
export const CommonFunctions = {
  ExcelNumericDataConvert,
  Searchspecial,
  SearchspecialLess,
  Formatthevalue,
  Alertmessage,
  PopupBreak,
  FormatNumber,
  ConvertToPascalCase
}