/* eslint-disable*/
import React from "react";
import ListboxWidget from "./ListboxWidget";
import { screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("ListboxWidget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let listboxwidget1 = {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
        DataProviderForListBox: "ReferenceMetaData",
        listSize: "4",
        name: "listboxwidget1",
        type: "ListboxWidget",
        parent: "groupboxwidget0",
        Label: "listboxwidget1",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };

      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let touched = jest.fn();
      let errors = jest.fn();
      renderTestScreen(
        <ListboxWidget
          values={values}
          conf={listboxwidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("ListboxWidget Basic Test Cases", async () => {
    const listboxwidget1 = screen.getByTestId("listboxwidget1");
    expect(listboxwidget1.tagName).toBe("SELECT");
    expect(listboxwidget1.type).toBe("select-multiple");
  });
});
describe("ListboxWidget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let listboxwidget1 = {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
        DataProviderForListBox: "ReferenceMetaData",
        listSize: "4",
        name: "listboxwidget1",
        type: "ListboxWidget",
        parent: "groupboxwidget0",
        Label: "listboxwidget1",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };

      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true
      };
      let values = {};
      let touched = jest.fn();
      let errors = jest.fn();
      renderTestScreen(
        <ListboxWidget
          values={values}
          conf={listboxwidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("ListboxWidget Basic Test Cases for Horizontal Form", async () => {
    const listboxwidget1 = screen.getByTestId("listboxwidget1");
    expect(listboxwidget1.tagName).toBe("SELECT");
    expect(listboxwidget1.type).toBe("select-multiple");
  });
});