/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  getValue,
  enable,
  disable,
  getSelectedRowNumber
} from "../../shared/WindowImports";

import "./LeasedTrailerProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_LeasedTrailerProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "LeasedTrailerProfile",
    windowName: "LeasedTrailerProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.LeasedTrailerProfile",
    // START_USER_CODE-USER_LeasedTrailerProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Leased Trailer Profile",
      scrCode: "PN0250C",
    },
    // END_USER_CODE-USER_LeasedTrailerProfile_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxLeasedTrailerList",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxLeasedTrailerList",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxLeaseTrailerProfile",
      Label: "Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddTrailerType: {
      name: "ddTrailerType",
      type: "DropDownFieldWidget",
      parent: "grpbxLeaseTrailerProfile",
      Label: "Trailer Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddTrailerType_PROPERTIES

      // END_USER_CODE-USER_ddTrailerType_PROPERTIES
    },
    gridLeasedTrailerListData: {
      name: "gridLeasedTrailerListData",
      type: "GridWidget",
      parent: "grpbxLeasedTrailerList",
      gridCellsOrder:
        "txtLocation,txtTrailerType,txtVehicleNum,txtPercentageC,txtAddedBy",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridLeasedTrailerListData_PROPERTIES

      // END_USER_CODE-USER_gridLeasedTrailerListData_PROPERTIES
    },
    grpbxLeasedTrailerList: {
      name: "grpbxLeasedTrailerList",
      type: "GroupBoxWidget",
      parent: "grpbxLeaseTrailerProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLeasedTrailerList_PROPERTIES

      // END_USER_CODE-USER_grpbxLeasedTrailerList_PROPERTIES
    },
    lblLeasedTrailerList: {
      name: "lblLeasedTrailerList",
      type: "LabelWidget",
      parent: "grpbxLeasedTrailerList",
      Label: "Leased Trailer List:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLeasedTrailerList_PROPERTIES

      // END_USER_CODE-USER_lblLeasedTrailerList_PROPERTIES
    },
    lblPercent: {
      name: "lblPercent",
      type: "LabelWidget",
      parent: "grpbxLeaseTrailerProfile",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPercent_PROPERTIES

      // END_USER_CODE-USER_lblPercent_PROPERTIES
    },
    txtAddedBy: {
      name: "txtAddedBy",
      type: "TextBoxWidget",
      colName: "txtColAddedBy",
      parent: "gridLeasedTrailerListData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddedBy_PROPERTIES

      // END_USER_CODE-USER_txtAddedBy_PROPERTIES
    },
    txtColAddedBy: {
      name: "txtColAddedBy",
      type: "GridColumnWidget",
      parent: "gridLeasedTrailerListData",
      Label: "Added By",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAddedBy_PROPERTIES

      // END_USER_CODE-USER_txtColAddedBy_PROPERTIES
    },
    txtColLocation: {
      name: "txtColLocation",
      type: "GridColumnWidget",
      parent: "gridLeasedTrailerListData",
      Label: "Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColLocation_PROPERTIES

      // END_USER_CODE-USER_txtColLocation_PROPERTIES
    },
    txtColPercentage: {
      name: "txtColPercentage",
      type: "GridColumnWidget",
      parent: "gridLeasedTrailerListData",
      Label: "Percentage",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPercentage_PROPERTIES

      // END_USER_CODE-USER_txtColPercentage_PROPERTIES
    },
    txtColTrailerType: {
      name: "txtColTrailerType",
      type: "GridColumnWidget",
      parent: "gridLeasedTrailerListData",
      Label: "Trailer Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColTrailerType_PROPERTIES

      // END_USER_CODE-USER_txtColTrailerType_PROPERTIES
    },
    txtColVehicle: {
      name: "txtColVehicle",
      type: "GridColumnWidget",
      parent: "gridLeasedTrailerListData",
      Label: "Vehicle#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColVehicle_PROPERTIES

      // END_USER_CODE-USER_txtColVehicle_PROPERTIES
    },
    txtLocation: {
      name: "txtLocation",
      type: "TextBoxWidget",
      colName: "txtColLocation",
      parent: "gridLeasedTrailerListData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocation_PROPERTIES

      // END_USER_CODE-USER_txtLocation_PROPERTIES
    },
    txtPercentage: {
      name: "txtPercentage",
      type: "TextBoxWidget",
      parent: "grpbxLeaseTrailerProfile",
      Label: "Percentage:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPercentage_PROPERTIES

      // END_USER_CODE-USER_txtPercentage_PROPERTIES
    },
    txtPercentageC: {
      name: "txtPercentageC",
      type: "TextBoxWidget",
      colName: "txtColPercentage",
      parent: "gridLeasedTrailerListData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPercentageC_PROPERTIES

      // END_USER_CODE-USER_txtPercentageC_PROPERTIES
    },
    txtTrailerType: {
      name: "txtTrailerType",
      type: "TextBoxWidget",
      colName: "txtColTrailerType",
      parent: "gridLeasedTrailerListData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTrailerType_PROPERTIES

      // END_USER_CODE-USER_txtTrailerType_PROPERTIES
    },
    txtVehicleNum: {
      name: "txtVehicleNum",
      type: "TextBoxWidget",
      colName: "txtColVehicle",
      parent: "gridLeasedTrailerListData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVehicleNum_PROPERTIES

      // END_USER_CODE-USER_txtVehicleNum_PROPERTIES
    },
    txtVehicleNumber: {
      name: "txtVehicleNumber",
      type: "TextBoxWidget",
      parent: "grpbxLeaseTrailerProfile",
      Label: "Vehicle Number:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 20 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVehicleNumber_PROPERTIES

      // END_USER_CODE-USER_txtVehicleNumber_PROPERTIES
    },
    grpbxLeaseTrailerProfile: {
      name: "grpbxLeaseTrailerProfile",
      type: "GroupBoxWidget",
      parent: "LeasedTrailerProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxLeaseTrailerProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxLeaseTrailerProfile_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "LeasedTrailerProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  const fillReceivingLocationList = async () => {
    try {
      const locationList = [];
      locationList.push({
        key: '',
        description: '>>> All Locations <<<'
      });

      const transferBuyingPointRes = await SettlementService.RetrieveTransferBuyingPointDetails();
      if (transferBuyingPointRes && transferBuyingPointRes.length) {
        transferBuyingPointRes.forEach(buyingPoint => {
          locationList.push({
            key: buyingPoint.xfer_buy_pt_id,
            description: buyingPoint.xfer_buy_pt_id + ' - ' + buyingPoint.xfer_buy_pt_name
          });
        });
      }

      if (locationList.length === 2) {
        locationList.splice(0, 1);
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state['ddLocation'],
            valueList: locationList
          }
        }
      });

      if (locationList.length) {
        setValue(thisObj, 'ddLocation', locationList[0].key);
      }
      return true;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillReceivingLocationList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const fillTrailerTypeList = () => {
    try {
      const trailerTypeList = [];
      trailerTypeList.push({
        key: '',
        description: '>>> All Segments <<<'
      });
      trailerTypeList.push({
        key: 'H',
        description: 'Hopper Bottom'
      });
      trailerTypeList.push({
        key: 'D',
        description: 'Dryer'
      });
      thisObj.setState(current => {
        return {
          ...current,
          ddTrailerType: {
            ...state['ddTrailerType'],
            valueList: trailerTypeList
          }
        }
      });

      setValue(thisObj, 'ddTrailerType', trailerTypeList[0].key);
      return true;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillTrailerTypeList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const getAccessLevel = async (strFuncSubId, strAccessLevel) => {
    try {
      let getAccessLevelBool = false;
      const functionId = 'PN0250';
      const accessResponse = await ContractManagementService
        .RetrieveAccessPermissionDetails(functionId, strFuncSubId, strAccessLevel);
      if (accessResponse?.[0]?.permission?.toString()?.toUpperCase() === 'Y') {
        getAccessLevelBool = true;
      }
      return getAccessLevelBool;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during getAccessLevel: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  function formatDate(dateVal) {
    var newDate = new Date(dateVal);
    var sMonth = (newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = newDate.getHours();
    var sMinute = padValue(newDate.getMinutes());
    var sSecond = padValue(newDate.getSeconds());
    var sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = iHourCheck - 12;
    }
    else if (iHourCheck === 0) {
      sHour = "12";
    }
    //sHour = padValue(sHour);
    return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + ":" + sSecond + " " + sAMPM;
  }

  function padValue(value) {
    return (value < 10) ? "0" + value : value;
  }

  const fillGrid = async () => {
    try {
      const gridData = [];
      const gridResponse = await SystemMaintenanceMasterManagementService.RetrieveLeasedTrailerDetails();
      if (gridResponse?.length) {
        gridResponse.forEach(gridValue => {
          const buyPtId = gridValue?.buy_pt_id;
          const trailerType = gridValue?.trailer_type === 'H' ? 'Hopper Bottom' : 'Dryer';
          const vehicleNum = gridValue?.vehicle_num;
          const percentage = parseFloat(gridValue?.percentage?.toString() || '0');
          const addedBy = gridValue?.add_user + ' - '
            + formatDate(gridValue?.add_date_time).split(' ')[0];

          const gridRowObj = {
            txtLocation: buyPtId,
            txtTrailerType: trailerType,
            txtVehicleNum: vehicleNum,
            txtPercentageC: percentage,
            txtAddedBy: addedBy
          };
          gridData.push(gridRowObj);
        });
        setValue(thisObj, 'gridLeasedTrailerListData', gridData);
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillGrid: ${error?.toString()}`
        );
      }
    }
  };

  const enableDisableControls = async () => {
    try {
      let permission = '';
      disable(thisObj, 'btnAdd');
      disable(thisObj, 'btnDelete');

      if (await getAccessLevel(null, 'D') === true) {
        permission = 'D';
      } else if (await getAccessLevel(null, 'U') === true) {
        permission = 'U';
      } else if (await getAccessLevel(null, 'I') === true) {
        permission = 'I';
      } else {
        showMessage(thisObj, 'Invalid Permissions');
        document.getElementById("SystemMaintenanceMasterManagement_LeasedTrailerProfilePopUp").childNodes[0].click();
        return;
      }

      if (permission === 'I') {
        disable(thisObj, 'btnAdd');
        disable(thisObj, 'btnDelete');
      }

      if (permission === 'U') {
        enable(thisObj, 'btnAdd');
        disable(thisObj, 'btnDelete');
      }

      if (permission === 'D') {
        enable(thisObj, 'btnAdd');
        enable(thisObj, 'btnDelete');
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during enableDisableControls: ${error?.toString()}`
        );
      }
    }
  };

  const formLoad = () => {
    try {
      fillReceivingLocationList();
      fillTrailerTypeList();
      fillGrid();
      enableDisableControls();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during formLoad: ${error?.toString()}`
        );
      }
    }
  };

  const onbtnAddClick = async () => {
    try {
      const selectedLocation = getValue(thisObj, 'ddLocation');
      const selectedTrailerType = getValue(thisObj, 'ddTrailerType');
      const vehicleNum = getValue(thisObj, 'txtVehicleNumber')?.trim();
      const percentage = getValue(thisObj, 'txtPercentage');

      if (!selectedLocation) {
        showMessage(thisObj, 'A Location must be selected.');
        document.getElementById('ddLocation').focus();
        return;
      }

      if (!selectedTrailerType) {
        showMessage(thisObj, 'A Trailer Type must be selected.');
        document.getElementById('ddTrailerType').focus();
        return;
      }

      if (!vehicleNum) {
        showMessage(thisObj, 'A vehicle number must be entered.');
        document.getElementById('txtVehicleNumber').focus();
        return;
      }

      if (!percentage || isNaN(percentage)) {
        showMessage(thisObj, 'The Percentage must be entered');
        document.getElementById('txtPercentage').focus();
        return;
      }

      const checkForExisting = await SystemMaintenanceMasterManagementService.RetrieveLeasedTrailerDetails(vehicleNum);
      if (checkForExisting.length && checkForExisting[0].vehicle_num) {
        showMessage(thisObj, 'The Data already exists!');
        return;
      }

      const actionType = 'INSERT';
      const dataToInsert = {
        vehicle_num: vehicleNum.trim().toUpperCase(),
        buy_pt_id: selectedLocation.trim(),
        trailer_type: selectedTrailerType.trim(),
        percentage: parseFloat(percentage.trim()).toFixed(3),
        user_id: (sessionStorage.getItem('userid') || '')
      }

      if (Math.abs(parseFloat(percentage.trim())) >= 100) {
        showMessage(thisObj, 'Error: 13 \nDescription: Type mismatch \nRoutine: frmLeasedTrailerProfile.cmdAdd_Click');
        return;
      }

      const addLeasedTrailerResponse = await SystemMaintenanceMasterManagementService.UpdateLeasedTrailer(actionType, dataToInsert);

      if (addLeasedTrailerResponse.status !== 200) {
        const errorMessage = addLeasedTrailerResponse?.message?.split('Error Meesage : ')?.length === 2 ? addLeasedTrailerResponse.message.split('Error Meesage : ')[1] : '';
        showMessage(thisObj, 'System Error: ' + errorMessage);
        return;
      }

      enableDisableControls();
      fillGrid();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnAddClick: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnAddClick = onbtnAddClick;

  const onbtnDeleteClick = async () => {
    try {
      const selectedRow = getSelectedRowNumber(thisObj, 'gridLeasedTrailerListData');
      const gridList = getValue(thisObj, 'gridLeasedTrailerListData') || [];

      if (gridList.length < 0 || selectedRow === undefined || selectedRow === null || selectedRow < 0) {
        showMessage(thisObj, 'A row must be selected to delete.');
        return;
      }

      const confirmBox = window.confirm(
        "Are you sure you wish to delete the highlighted row from the Leased Trailer control table?"
      );

      if (!confirmBox) {
        return;
      }

      const actionType = 'DELETE';
      const dataToDelete = {
        vehicle_num: gridList[selectedRow].txtVehicleNum?.toUpperCase(),
        buy_pt_id: gridList[selectedRow].txtLocation?.trim(),
        trailer_type: gridList[selectedRow].txtTrailerType?.trim() === 'Hopper Bottom'
          ? 'H' : 'D',
        percentage: parseFloat(gridList[selectedRow].txtPercentageC?.toString()?.trim()).toFixed(3),
        user_id: (sessionStorage.getItem('userid') || '')
      };

      const deleteLeasedTrailerResponse = await SystemMaintenanceMasterManagementService.UpdateLeasedTrailer(actionType, dataToDelete);

      if (deleteLeasedTrailerResponse.status !== 200) {
        const errorMessage = addLeasedTrailerResponse?.message?.split('Error Meesage : ')?.length === 2 ? addLeasedTrailerResponse.message.split('Error Meesage : ')[1] : '';
        showMessage(thisObj, 'An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.' + '\nError:\n' + errorMessage);
        return;
      }

      enableDisableControls();
      gridList.splice(selectedRow, 1);

      setValue(thisObj, 'gridLeasedTrailerListData', gridList);
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnDeleteClick: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_LeasedTrailerProfilePopUp").childNodes[0].click();
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnCloseClick: ${error?.toString()}`
        );
      }
    }
  };

  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_LeasedTrailerProfile*/}

              {/* END_USER_CODE-USER_BEFORE_LeasedTrailerProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxLeaseTrailerProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxLeaseTrailerProfile*/}

              <GroupBoxWidget
                conf={state.grpbxLeaseTrailerProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_ddTrailerType*/}

                {/* END_USER_CODE-USER_BEFORE_ddTrailerType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddTrailerType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddTrailerType*/}

                {/* END_USER_CODE-USER_AFTER_ddTrailerType*/}
                {/* START_USER_CODE-USER_BEFORE_txtVehicleNumber*/}

                {/* END_USER_CODE-USER_BEFORE_txtVehicleNumber*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVehicleNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVehicleNumber*/}

                {/* END_USER_CODE-USER_AFTER_txtVehicleNumber*/}
                {/* START_USER_CODE-USER_BEFORE_txtPercentage*/}

                {/* END_USER_CODE-USER_BEFORE_txtPercentage*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPercentage}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPercentage*/}

                {/* END_USER_CODE-USER_AFTER_txtPercentage*/}
                {/* START_USER_CODE-USER_BEFORE_lblPercent*/}

                {/* END_USER_CODE-USER_BEFORE_lblPercent*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPercent}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPercent*/}

                {/* END_USER_CODE-USER_AFTER_lblPercent*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxLeasedTrailerList*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxLeasedTrailerList*/}

                <GroupBoxWidget
                  conf={state.grpbxLeasedTrailerList}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblLeasedTrailerList*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLeasedTrailerList*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblLeasedTrailerList}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLeasedTrailerList*/}

                  {/* END_USER_CODE-USER_AFTER_lblLeasedTrailerList*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                  {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                  {/* START_USER_CODE-USER_BEFORE_gridLeasedTrailerListData*/}

                  {/* END_USER_CODE-USER_BEFORE_gridLeasedTrailerListData*/}

                  <GridWidget
                    conf={state.gridLeasedTrailerListData}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridLeasedTrailerListData}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridLeasedTrailerListData*/}

                  {/* END_USER_CODE-USER_AFTER_gridLeasedTrailerListData*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxLeasedTrailerList*/}

                {/* END_USER_CODE-USER_AFTER_grpbxLeasedTrailerList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxLeaseTrailerProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxLeaseTrailerProfile*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_LeasedTrailerProfile*/}

              {/* END_USER_CODE-USER_AFTER_LeasedTrailerProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_LeasedTrailerProfile
);
