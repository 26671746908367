/* eslint-disable*/
import React from "react";
import AccountPayment_ReviewCheckAuditDetail from "./ReviewCheckAuditDetail";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ReviewCheckAuditDetail Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_ReviewCheckAuditDetail />);
    });
  });
  afterEach(cleanup);
  test("is ReviewCheckAuditDetail Loads Successfully", () => {
    expect(screen.getByText("ReviewCheckAuditDatail")).toBeInTheDocument;
  });
  test("Custom Test Cases for ReviewCheckAuditDetail", () => {
    // START_USER_CODE-USER_ReviewCheckAuditDetail_Custom_Test_Case
    // END_USER_CODE-USER_ReviewCheckAuditDetail_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_ReviewCheckAuditDetail />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("AccountPayment:ReviewCheckAuditDetail_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("gridCheckAuditDetails(Grid Widget) Test Cases", async () => {
    let gridCheckAuditDetails = screen.getByTestId("gridCheckAuditDetails");
    let gridCheckAuditDetailsbtn =
      gridCheckAuditDetails.nextElementSibling.firstElementChild;
    gridCheckAuditDetails =
      gridCheckAuditDetails.parentElement.parentElement.parentElement;
    expect(gridCheckAuditDetails.tagName).toBe("DIV");
    expect(gridCheckAuditDetails.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:ReviewCheckAuditDetail_gridCheckAuditDetails")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridCheckAuditDetails", () => {
    // START_USER_CODE-USER_gridCheckAuditDetails_TEST
    // END_USER_CODE-USER_gridCheckAuditDetails_TEST
  });
  test("gridCheckAuditDetails_txtcolAction(Grid Widget) Test Cases", async () => {
    let gridCheckAuditDetails_txtcolAction = screen.getByTestId(
      "gridCheckAuditDetails"
    );
    let gridCheckAuditDetails_txtcolActionbtn =
      gridCheckAuditDetails_txtcolAction.nextElementSibling.firstElementChild;
    gridCheckAuditDetails_txtcolAction =
      gridCheckAuditDetails_txtcolAction.parentElement.parentElement
        .parentElement;
    expect(gridCheckAuditDetails_txtcolAction.tagName).toBe("DIV");
    expect(gridCheckAuditDetails_txtcolAction.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReviewCheckAuditDetail_gridCheckAuditDetails")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAction", () => {
    // START_USER_CODE-USER_txtcolAction_TEST
    // END_USER_CODE-USER_txtcolAction_TEST
  });
  test("gridCheckAuditDetails_txtcolAuditDate(Grid Widget) Test Cases", async () => {
    let gridCheckAuditDetails_txtcolAuditDate = screen.getByTestId(
      "gridCheckAuditDetails"
    );
    let gridCheckAuditDetails_txtcolAuditDatebtn =
      gridCheckAuditDetails_txtcolAuditDate.nextElementSibling
        .firstElementChild;
    gridCheckAuditDetails_txtcolAuditDate =
      gridCheckAuditDetails_txtcolAuditDate.parentElement.parentElement
        .parentElement;
    expect(gridCheckAuditDetails_txtcolAuditDate.tagName).toBe("DIV");
    expect(gridCheckAuditDetails_txtcolAuditDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReviewCheckAuditDetail_gridCheckAuditDetails")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAuditDate", () => {
    // START_USER_CODE-USER_txtcolAuditDate_TEST
    // END_USER_CODE-USER_txtcolAuditDate_TEST
  });
  test("gridCheckAuditDetails_txtcolAuditUser(Grid Widget) Test Cases", async () => {
    let gridCheckAuditDetails_txtcolAuditUser = screen.getByTestId(
      "gridCheckAuditDetails"
    );
    let gridCheckAuditDetails_txtcolAuditUserbtn =
      gridCheckAuditDetails_txtcolAuditUser.nextElementSibling
        .firstElementChild;
    gridCheckAuditDetails_txtcolAuditUser =
      gridCheckAuditDetails_txtcolAuditUser.parentElement.parentElement
        .parentElement;
    expect(gridCheckAuditDetails_txtcolAuditUser.tagName).toBe("DIV");
    expect(gridCheckAuditDetails_txtcolAuditUser.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReviewCheckAuditDetail_gridCheckAuditDetails")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAuditUser", () => {
    // START_USER_CODE-USER_txtcolAuditUser_TEST
    // END_USER_CODE-USER_txtcolAuditUser_TEST
  });
  test("gridCheckAuditDetails_txtcolStatus(Grid Widget) Test Cases", async () => {
    let gridCheckAuditDetails_txtcolStatus = screen.getByTestId(
      "gridCheckAuditDetails"
    );
    let gridCheckAuditDetails_txtcolStatusbtn =
      gridCheckAuditDetails_txtcolStatus.nextElementSibling.firstElementChild;
    gridCheckAuditDetails_txtcolStatus =
      gridCheckAuditDetails_txtcolStatus.parentElement.parentElement
        .parentElement;
    expect(gridCheckAuditDetails_txtcolStatus.tagName).toBe("DIV");
    expect(gridCheckAuditDetails_txtcolStatus.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:ReviewCheckAuditDetail_gridCheckAuditDetails")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStatus", () => {
    // START_USER_CODE-USER_txtcolStatus_TEST
    // END_USER_CODE-USER_txtcolStatus_TEST
  });
});
