/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
  hide,
  show,
  goTo,
  hideColumn
} from "../../shared/WindowImports";

import "./ViewContractApplication.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../Service/SettlementService";
import CommonContext from "../../Store/CommonContext";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function Settlements_ViewContractApplication(props) {
  const commonContext1 = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ViewContractApplication",
    windowName: "ViewContractApplication",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.ViewContractApplication",
    // START_USER_CODE-USER_ViewContractApplication_PROPERTIES
    headerData: {
      scrName: "Contract Applications",
      scrCode: "PN1060D",
    },
    // END_USER_CODE-USER_ViewContractApplication_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btncol1: {
      name: "btncol1",
      type: "ButtonWidget",
      colName: "col1",
      parent: "gridViewContractApplication",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncol1_PROPERTIES

      // END_USER_CODE-USER_btncol1_PROPERTIES
    },
    btnPurchase: {
      name: "btnPurchase",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Purchase",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPurchase_PROPERTIES

      // END_USER_CODE-USER_btnPurchase_PROPERTIES
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },
    colApplied: {
      name: "colApplied",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Applied",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colApplied_PROPERTIES

      // END_USER_CODE-USER_colApplied_PROPERTIES
    },
    colAppliedSG: {
      name: "colAppliedSG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Applied",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAppliedSG_PROPERTIES

      // END_USER_CODE-USER_colAppliedSG_PROPERTIES
    },
    colCont: {
      name: "colCont",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Cont.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCont_PROPERTIES

      // END_USER_CODE-USER_colCont_PROPERTIES
    },
    colContDate: {
      name: "colContDate",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Cont. Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContDate_PROPERTIES

      // END_USER_CODE-USER_colContDate_PROPERTIES
    },
    colContDateSG: {
      name: "colContDateSG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Cont. Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContDateSG_PROPERTIES

      // END_USER_CODE-USER_colContDateSG_PROPERTIES
    },
    colContGroup: {
      name: "colContGroup",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Cont. Group",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContGroup_PROPERTIES

      // END_USER_CODE-USER_colContGroup_PROPERTIES
    },
    colContractsPounds: {
      name: "colContractsPounds",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Contract Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractsPounds_PROPERTIES

      // END_USER_CODE-USER_colContractsPounds_PROPERTIES
    },
    colContractsPoundsSG: {
      name: "colContractsPoundsSG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Contract Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractsPoundsSG_PROPERTIES

      // END_USER_CODE-USER_colContractsPoundsSG_PROPERTIES
    },
    colDeliveryEndDate: {
      name: "colDeliveryEndDate",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Delivery End Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDeliveryEndDate_PROPERTIES

      // END_USER_CODE-USER_colDeliveryEndDate_PROPERTIES
    },
    colDeliveryStartDate: {
      name: "colDeliveryStartDate",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Delivery Start Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDeliveryStartDate_PROPERTIES

      // END_USER_CODE-USER_colDeliveryStartDate_PROPERTIES
    },
    colFOrB: {
      name: "colFOrB",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "F/B",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFOrB_PROPERTIES

      // END_USER_CODE-USER_colFOrB_PROPERTIES
    },
    colOpenBalance: {
      name: "colOpenBalance",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Open Balance",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenBalance_PROPERTIES

      // END_USER_CODE-USER_colOpenBalance_PROPERTIES
    },
    colOpenBalanceSG: {
      name: "colOpenBalanceSG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Open Balance",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenBalanceSG_PROPERTIES

      // END_USER_CODE-USER_colOpenBalanceSG_PROPERTIES
    },
    colPeanutVariety: {
      name: "colPeanutVariety",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_colPeanutVariety_PROPERTIES
    },
    colPoundsToApply: {
      name: "colPoundsToApply",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Pounds to Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPoundsToApply_PROPERTIES

      // END_USER_CODE-USER_colPoundsToApply_PROPERTIES
    },
    colPoundsToApplySG: {
      name: "colPoundsToApplySG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Pounds to Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPoundsToApplySG_PROPERTIES

      // END_USER_CODE-USER_colPoundsToApplySG_PROPERTIES
    },
    colPrice: {
      name: "colPrice",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrice_PROPERTIES

      // END_USER_CODE-USER_colPrice_PROPERTIES
    },
    colContNum: {
      name: "colContNum",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Contract",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrice_PROPERTIES

      // END_USER_CODE-USER_colPrice_PROPERTIES
    },
    colPriceSG: {
      name: "colPriceSG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPriceSG_PROPERTIES

      // END_USER_CODE-USER_colPriceSG_PROPERTIES
    },
    colContNumSG: {
      name: "colContNumSG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Contract",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPriceSG_PROPERTIES

      // END_USER_CODE-USER_colPriceSG_PROPERTIES
    },
    colpnutVarietySG: {
      name: "colpnutVarietySG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPriceSG_PROPERTIES

      // END_USER_CODE-USER_colPriceSG_PROPERTIES
    },
    colSeed: {
      name: "colSeed",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeed_PROPERTIES

      // END_USER_CODE-USER_colSeed_PROPERTIES
    },
    colTentativeApplied: {
      name: "colTentativeApplied",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Tentative Applied",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTentativeApplied_PROPERTIES

      // END_USER_CODE-USER_colTentativeApplied_PROPERTIES
    },
    colTotalApplicaion: {
      name: "colTotalApplicaion",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Total Application",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotalApplicaion_PROPERTIES

      // END_USER_CODE-USER_colTotalApplicaion_PROPERTIES
    },
    colVendorContNum: {
      name: "colVendorContNum",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Vendor - Cont. Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorContNum_PROPERTIES

      // END_USER_CODE-USER_colVendorContNum_PROPERTIES
    },
    colVendorContNumsSG: {
      name: "colVendorContNumsSG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Vendor - Cont. Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorContNumsSG_PROPERTIES

      // END_USER_CODE-USER_colVendorContNumsSG_PROPERTIES
    },
    gridSeedGroverApp: {
      name: "gridSeedGroverApp",
      type: "GridWidget",
      parent: "grpbxSeedGroverApp",
      gridCellsOrder:
        "btnCol2,txtcolVendorContNumsSG,txtcolContDateSG,txtcolpnutVarietySG,txtcolPoundsToApplySG,txtcolContractsPoundsSG,txtcolAppliedSG,txtcolOpenBalanceSG,txtcolPriceSG,txtcolContNumSG",
      ColSpan: "1",
      Pagination: false,
      HasLabel: false,
      Cols: "1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridSeedGroverApp_PROPERTIES

      // END_USER_CODE-USER_gridSeedGroverApp_PROPERTIES
    },
    gridViewContractApplication: {
      name: "gridViewContractApplication",
      type: "GridWidget",
      parent: "grpbxViewContractApplication",
      gridCellsOrder:
        "btncol1,txtcolContGroup,txtcolVendorContNum,txtcolContDate,txtcolPeanutVariety,txtcolSeed,txtcolFOrB,txtcolPoundsToApply,txtcolCont,txtcolDeliveryStartDate,txtcolDeliveryEndDate,txtcolContractsPounds,textboxwidget31,txtcolTentativeApplied,textboxwidget27,txtcolOpenBalance,txtcolPrice,txtcolContNum",
      ColSpan: "8",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridViewContractApplication_PROPERTIES

      // END_USER_CODE-USER_gridViewContractApplication_PROPERTIES
    },
    grpbxSeedGroverApp: {
      name: "grpbxSeedGroverApp",
      type: "GroupBoxWidget",
      parent: "grpbxViewContractApplication",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxSeedGroverApp_PROPERTIES

      // END_USER_CODE-USER_grpbxSeedGroverApp_PROPERTIES
    },
    grpbxSpotApplication: {
      name: "grpbxSpotApplication",
      type: "GroupBoxWidget",
      parent: "grpbxViewContractApplication",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSpotApplication_PROPERTIES

      // END_USER_CODE-USER_grpbxSpotApplication_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblContractApplications: {
      name: "lblContractApplications",
      type: "LabelWidget",
      parent: "grpbxViewContractApplication",
      Label: "Contract Applications",
      ColSpan: "8",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblContractApplications_PROPERTIES

      // END_USER_CODE-USER_lblContractApplications_PROPERTIES
    },
    lblSeedGroverApp: {
      name: "lblSeedGroverApp",
      type: "LabelWidget",
      parent: "grpbxSeedGroverApp",
      Label: "Seed Grover Application",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSeedGroverApp_PROPERTIES

      // END_USER_CODE-USER_lblSeedGroverApp_PROPERTIES
    },
    lblSpotApplications: {
      name: "lblSpotApplications",
      type: "LabelWidget",
      parent: "grpbxSpotApplication",
      Label: "Spot Applications",
      ColSpan: "8",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpotApplications_PROPERTIES

      // END_USER_CODE-USER_lblSpotApplications_PROPERTIES
    },
    textboxwidget27: {
      name: "textboxwidget27",
      type: "TextBoxWidget",
      colName: "colTotalApplicaion",
      parent: "gridViewContractApplication",
      Label: "textboxwidget27",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_textboxwidget27_PROPERTIES

      // END_USER_CODE-USER_textboxwidget27_PROPERTIES
    },
    textboxwidget31: {
      name: "textboxwidget31",
      type: "TextBoxWidget",
      colName: "colApplied",
      parent: "gridViewContractApplication",
      Label: "textboxwidget31",
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_textboxwidget31_PROPERTIES

      // END_USER_CODE-USER_textboxwidget31_PROPERTIES
    },
    txt1007Num: {
      name: "txt1007Num",
      type: "TextBoxWidget",
      parent: "grpbxViewContractApplication",
      Label: "1007 #",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007Num_PROPERTIES

      // END_USER_CODE-USER_txt1007Num_PROPERTIES
    },
    txtcolAppliedSG: {
      name: "txtcolAppliedSG",
      type: "TextBoxWidget",
      colName: "colAppliedSG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAppliedSG_PROPERTIES

      // END_USER_CODE-USER_txtcolAppliedSG_PROPERTIES
    },
    txtcolCont: {
      name: "txtcolCont",
      type: "TextBoxWidget",
      colName: "colCont",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCont_PROPERTIES

      // END_USER_CODE-USER_txtcolCont_PROPERTIES
    },
    txtcolContDate: {
      name: "txtcolContDate",
      type: "TextBoxWidget",
      colName: "colContDate",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContDate_PROPERTIES

      // END_USER_CODE-USER_txtcolContDate_PROPERTIES
    },
    txtcolContDateSG: {
      name: "txtcolContDateSG",
      type: "TextBoxWidget",
      colName: "colContDateSG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContDateSG_PROPERTIES

      // END_USER_CODE-USER_txtcolContDateSG_PROPERTIES
    },
    txtcolContGroup: {
      name: "txtcolContGroup",
      type: "TextBoxWidget",
      colName: "colContGroup",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContGroup_PROPERTIES

      // END_USER_CODE-USER_txtcolContGroup_PROPERTIES
    },
    txtcolContractsPounds: {
      name: "txtcolContractsPounds",
      type: "TextBoxWidget",
      colName: "colContractsPounds",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContractsPounds_PROPERTIES

      // END_USER_CODE-USER_txtcolContractsPounds_PROPERTIES
    },
    txtcolContractsPoundsSG: {
      name: "txtcolContractsPoundsSG",
      type: "TextBoxWidget",
      colName: "colContractsPoundsSG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContractsPoundsSG_PROPERTIES

      // END_USER_CODE-USER_txtcolContractsPoundsSG_PROPERTIES
    },
    txtcolDeliveryEndDate: {
      name: "txtcolDeliveryEndDate",
      type: "TextBoxWidget",
      colName: "colDeliveryEndDate",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDeliveryEndDate_PROPERTIES

      // END_USER_CODE-USER_txtcolDeliveryEndDate_PROPERTIES
    },
    txtcolDeliveryStartDate: {
      name: "txtcolDeliveryStartDate",
      type: "TextBoxWidget",
      colName: "colDeliveryStartDate",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDeliveryStartDate_PROPERTIES

      // END_USER_CODE-USER_txtcolDeliveryStartDate_PROPERTIES
    },
    txtcolFOrB: {
      name: "txtcolFOrB",
      type: "TextBoxWidget",
      colName: "colFOrB",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFOrB_PROPERTIES

      // END_USER_CODE-USER_txtcolFOrB_PROPERTIES
    },
    txtcolOpenBalance: {
      name: "txtcolOpenBalance",
      type: "TextBoxWidget",
      colName: "colOpenBalance",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpenBalance_PROPERTIES

      // END_USER_CODE-USER_txtcolOpenBalance_PROPERTIES
    },
    txtcolOpenBalanceSG: {
      name: "txtcolOpenBalanceSG",
      type: "TextBoxWidget",
      colName: "colOpenBalanceSG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpenBalanceSG_PROPERTIES

      // END_USER_CODE-USER_txtcolOpenBalanceSG_PROPERTIES
    },
    txtcolPeanutVariety: {
      name: "txtcolPeanutVariety",
      type: "TextBoxWidget",
      colName: "colPeanutVariety",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES
    },
    txtcolPoundsToApply: {
      name: "txtcolPoundsToApply",
      type: "TextBoxWidget",
      colName: "colPoundsToApply",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPoundsToApply_PROPERTIES

      // END_USER_CODE-USER_txtcolPoundsToApply_PROPERTIES
    },
    txtcolPoundsToApplySG: {
      name: "txtcolPoundsToApplySG",
      type: "TextBoxWidget",
      colName: "colPoundsToApplySG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPoundsToApplySG_PROPERTIES

      // END_USER_CODE-USER_txtcolPoundsToApplySG_PROPERTIES
    },
    txtcolPrice: {
      name: "txtcolPrice",
      type: "TextBoxWidget",
      colName: "colPrice",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrice_PROPERTIES

      // END_USER_CODE-USER_txtcolPrice_PROPERTIES
    },
    txtcolContNum: {
      name: "txtcolContNum",
      type: "TextBoxWidget",
      colName: "colContNum",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrice_PROPERTIES

      // END_USER_CODE-USER_txtcolPrice_PROPERTIES
    },
    txtcolPriceSG: {
      name: "txtcolPriceSG",
      type: "TextBoxWidget",
      colName: "colPriceSG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPriceSG_PROPERTIES

      // END_USER_CODE-USER_txtcolPriceSG_PROPERTIES
    },
    txtcolContNumSG: {
      name: "txtcolContNumSG",
      type: "TextBoxWidget",
      colName: "colContNumSG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPriceSG_PROPERTIES

      // END_USER_CODE-USER_txtcolPriceSG_PROPERTIES
    },
    txtcolSeed: {
      name: "txtcolSeed",
      type: "TextBoxWidget",
      colName: "colSeed",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeed_PROPERTIES

      // END_USER_CODE-USER_txtcolSeed_PROPERTIES
    },
    txtcolTentativeApplied: {
      name: "txtcolTentativeApplied",
      type: "TextBoxWidget",
      colName: "colTentativeApplied",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTentativeApplied_PROPERTIES

      // END_USER_CODE-USER_txtcolTentativeApplied_PROPERTIES
    },
    txtcolVendorContNum: {
      name: "txtcolVendorContNum",
      type: "TextBoxWidget",
      colName: "colVendorContNum",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendorContNum_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorContNum_PROPERTIES
    },
    txtcolVendorContNumsSG: {
      name: "txtcolVendorContNumsSG",
      type: "TextBoxWidget",
      colName: "colVendorContNumsSG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendorContNumsSG_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorContNumsSG_PROPERTIES
    },
    btnCol2: {
      name: "btnCol2",
      type: "ButtonWidget",
      colName: "col2",
      parent: "gridSeedGroverApp",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCol2_PROPERTIES

      // END_USER_CODE-USER_btnCol2_PROPERTIES
    },
    col2: {
      name: "col2",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col2_PROPERTIES

      // END_USER_CODE-USER_col2_PROPERTIES
    },





    txtcolpnutVarietySG: {
      name: "txtcolpnutVarietySG",
      type: "TextBoxWidget",
      colName: "colpnutVarietySG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendorContNumsSG_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorContNumsSG_PROPERTIES
    },
    txtFarm: {
      name: "txtFarm",
      type: "TextBoxWidget",
      parent: "grpbxViewContractApplication",
      Label: "Farm",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarm_PROPERTIES

      // END_USER_CODE-USER_txtFarm_PROPERTIES
    },
    txtLbsToApply: {
      name: "txtLbsToApply",
      type: "TextBoxWidget",
      parent: "grpbxSpotApplication",
      Label: "Lbs to Apply:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLbsToApply_PROPERTIES

      // END_USER_CODE-USER_txtLbsToApply_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpbxViewContractApplication",
      Label: "Peanut Type",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtPounds: {
      name: "txtPounds",
      type: "TextBoxWidget",
      parent: "grpbxViewContractApplication",
      Label: "Pounds",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds_PROPERTIES

      // END_USER_CODE-USER_txtPounds_PROPERTIES
    },
    txtPrchsStrg: {
      name: "txtPrchsStrg",
      type: "TextBoxWidget",
      parent: "grpbxViewContractApplication",
      Label: "Purchase / Storage",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrchsStrg_PROPERTIES

      // END_USER_CODE-USER_txtPrchsStrg_PROPERTIES
    },
    txtPrice: {
      name: "txtPrice",
      type: "TextBoxWidget",
      parent: "grpbxSpotApplication",
      Label: "Price:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrice_PROPERTIES

      // END_USER_CODE-USER_txtPrice_PROPERTIES
    },
    txtSC95Num: {
      name: "txtSC95Num",
      type: "TextBoxWidget",
      parent: "grpbxViewContractApplication",
      Label: "SC95 #",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95Num_PROPERTIES

      // END_USER_CODE-USER_txtSC95Num_PROPERTIES
    },
    txtSeed: {
      name: "txtSeed",
      type: "TextBoxWidget",
      parent: "grpbxViewContractApplication",
      Label: "Seed",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeed_PROPERTIES

      // END_USER_CODE-USER_txtSeed_PROPERTIES
    },
    txtSeedSpec: {
      name: "txtSeedSpec",
      type: "TextBoxWidget",
      parent: "grpbxViewContractApplication",
      Label: "Seed Spec.",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedSpec_PROPERTIES

      // END_USER_CODE-USER_txtSeedSpec_PROPERTIES
    },
    grpbxViewContractApplication: {
      name: "grpbxViewContractApplication",
      type: "GroupBoxWidget",
      parent: "ViewContractApplication",
      Height: "",
      Width: "",
      ColsForMobile: "8",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxViewContractApplication_PROPERTIES

      // END_USER_CODE-USER_grpbxViewContractApplication_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ViewContractApplication",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    _kaledo.thisObj = thisObj
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    LoadContractApplicationData();
    
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  const LoadContractApplicationData = () => {
    let userid = sessionStorage.getItem("userid");
    let formData = getData(thisObj, 'ViewContractApplicationData')
    let SC95_num = formData.SC95 //'G700770' //'2632604'
    let Insp1007_num = formData.Insp1007 // '2588775' 
    setValue(thisObj, 'txtSC95Num', SC95_num)
    setValue(thisObj, 'txt1007Num', Insp1007_num)
    hideColumn(thisObj,"gridViewContractApplication","txtcolContNum")
    hideColumn(thisObj,"gridSeedGroverApp","txtcolContNumSG")
    if (SC95_num != null && SC95_num != undefined) {
      SettlementService.RetrieveInspectionHeaderList(SC95_num, null, null, null)
        .then(response => {
          if (response.length != 0) {
            let inspectvendorsplits = response[0].inspect_vendor_splits

            let stateAbbr = response[0].state_abbr
            let countyId = response[0].county_id
            let farmname = response[0].farm_id + " - " + response[0].farm_suffix + " - " + response[0].farm_name
            setValue(thisObj, 'txtFarm', farmname)
            let farmNumberTag =  response[0].farm_id
            setValue(thisObj, 'txtPeanutType', response[0].pnut_type_name)
            let peanutTypeTag =  response[0].pnut_type_id
            let varietyidTag =  response[0].pnut_variety_id
            let buyingPointID = response[0].buy_pt_id
            let segType = response[0].seg_type
            let edibleOilInd = response[0].edible_oil_ind
            let inspDate = response[0].insp_date_time
            let cmaVendorNum = response[0].cma_vendor_num

            let lbsToApply = response[0].spot_pounds
            let priceval = response[0].spot_price
            setValue(thisObj, 'txtPrice', priceval)

            hide(thisObj, 'lblSeedGroverApp');
            hide(thisObj, 'gridSeedGroverApp');
            if(response[0].gen_produced_name != null){
                setValue(thisObj, 'txtSeed', 'Yes')
                if(response[0].seed_meeting_spec_ind == 'Y')
                {
                  setValue(thisObj, 'txtSeedSpec', 'Yes')
                  show(thisObj, 'lblSeedGroverApp');
                  show(thisObj, 'gridSeedGroverApp');
                }
                else{
                  setValue(thisObj, 'txtSeedSpec', 'No')
                }
            }
            else{
              setValue(thisObj, 'txtSeed', 'No')
            }

            let purchaseStoragevalue = ''
            let applyPounds = 0
            let tentPounds = 0
            let vendorSplitslist = []
            let objVendorSplit = {}
            for (var i = 0; i < inspectvendorsplits.length; i++) {
              objVendorSplit.vendornum = inspectvendorsplits[i].split_vendor
              objVendorSplit.pct = inspectvendorsplits[i].split_share_pct
              objVendorSplit.pur_ind = inspectvendorsplits[i].purh_stor_ind
              objVendorSplit.netwt = inspectvendorsplits[i].net_wt
              vendorSplitslist.push(objVendorSplit)
              if(objVendorSplit.pur_ind == 'P'){
                applyPounds = applyPounds + Number(objVendorSplit.netwt)
              } else if(objVendorSplit.pur_ind == 'S'){
                tentPounds = tentPounds + Number(objVendorSplit.netwt)
              }
              objVendorSplit = {}
            }
            
            if(applyPounds > 0){
              purchaseStoragevalue = 'P'
            }else if(tentPounds > 0){
              purchaseStoragevalue = 'S'
            }
           
            let totalAppliedpounds = 0
            let totalTentpounds = 0
            SettlementService.RetrieveContractDetails(null, varietyidTag, null, null, null, null, null, null, buyingPointID, null, 'PN1050', null, userid, SC95_num, null, SC95_num, null, null)
            .then(response1 => {
              let contractData = response1

              let contPricinglist = []
              let seedConApplist = []
              for (var i = 0; i < contractData.length; i++) {
                let cont_pricing_data = contractData[i].contract_pricing
                let contractDate = moment(contractData[i].cont_date).format('MM/DD/YYYY')
                let contractNum = contractData[i].contract_num
                let contractVendor = contractData[i].split_vendor
                let groupingNum = contractData[i].grouping_num
                let groupingDatetime = moment(contractData[i].grouping_date_time).format('MM/DD/YYYY')
                let contractType = contractData[i].cont_type
                let seedGrower = contractData[i].seed_grower

                let symbolind = contractData[i].symbol_ind
                let symbolmark = ''
                if(symbolind == "TRADEMARK")
                {
                  symbolmark = " " + Chr(153)
                } else if(symbolind == "REGISTERED TRADEMARK")
                {
                  symbolmark = " " + Chr(174)
                } else if(symbolind == "COPYRIGHT")
                {
                  symbolmark = " " + Chr(169)
                }else
                {
                  symbolmark = ""
                }
                let pnutVarietyName = contractData[i].pnut_variety_name

                
                let contPricingobj = {}
                for (var j = 0; j < cont_pricing_data.length; j++) {
                  if (cont_pricing_data[j].cont_appl_lbs > 0 || cont_pricing_data[j].cont_tent_lbs > 0){
                    contPricingobj.txtcolContGroup = groupingNum
                    contPricingobj.txtcolVendorContNum = contractVendor + ' - ' + contractNum
                    if(groupingDatetime == null)
                    {
                      contPricingobj.txtcolContDate = contractDate
                    } else {
                      contPricingobj.txtcolContDate = groupingDatetime
                    }
                    
                    contPricingobj.txtcolPeanutVariety = pnutVarietyName + symbolmark
                    contPricingobj.txtcolSeed = seedGrower
  
                    if(cont_pricing_data[j].firm_basis == "F")
                    {
                      contPricingobj.txtcolFOrB = "Firm"
                    } else
                    {
                      contPricingobj.txtcolFOrB = "Basis"
                    }
                    if(purchaseStoragevalue == 'P'){
                      contPricingobj.txtcolPoundsToApply = cont_pricing_data[j].cont_appl_lbs
                      totalAppliedpounds = Number(totalAppliedpounds) + Number(contPricingobj.txtcolPoundsToApply)
                    }else if(purchaseStoragevalue == 'S'){
                      contPricingobj.txtcolPoundsToApply = cont_pricing_data[j].cont_tent_lbs
                      totalTentpounds = Number(totalTentpounds) + Number(contPricingobj.txtcolPoundsToApply)
                    }
                    contPricingobj.txtcolCont = contractType
                    contPricingobj.txtcolDeliveryStartDate = moment(cont_pricing_data[j].delv_beg_date).format('MM/DD/YYYY')
                    contPricingobj.txtcolDeliveryEndDate = moment(cont_pricing_data[j].delv_end_date).format('MM/DD/YYYY')
                    contPricingobj.txtcolContractsPounds = cont_pricing_data[j].cont_lbs
                    contPricingobj.textboxwidget31 = cont_pricing_data[j].appl_lbs
                    contPricingobj.txtcolTentativeApplied = cont_pricing_data[j].tentative_lbs
                    contPricingobj.textboxwidget27 = Number(contPricingobj.textboxwidget31) + Number(contPricingobj.txtcolTentativeApplied)
                    contPricingobj.txtcolOpenBalance = Number(contPricingobj.txtcolContractsPounds) - Number(contPricingobj.textboxwidget27)
                    contPricingobj.txtcolPrice = cont_pricing_data[j].price_per_ton
                    contPricingobj.txtcolContNum = contractNum
                    
                    contPricinglist.push(contPricingobj)
                    contPricingobj = {}
                  }
                }
                setValue(thisObj, 'gridViewContractApplication', contPricinglist)
              

                let seedConAppobj = {}
                let seed_conapplication_data = contractData[i].seed_contract_application
                for (var j = 0; j < seed_conapplication_data.length; j++) {
                  if (seed_conapplication_data[j].seed_contract_insp_lbs > 0)
                  {
                    seedConAppobj.txtcolVendorContNumsSG = contractVendor + ' - ' + contractNum
                    seedConAppobj.txtcolContDateSG = contractDate
                    seedConAppobj.txtcolpnutVarietySG = pnutVarietyName + symbolmark
                    seedConAppobj.txtcolPoundsToApplySG = seed_conapplication_data[j].seed_contract_insp_lbs
                    seedConAppobj.txtcolContractsPoundsSG = seed_conapplication_data[j].seed_contract_cont_lbs
                    seedConAppobj.txtcolAppliedSG = seed_conapplication_data[j].seed_contract_appl_lbs
                    seedConAppobj.txtcolOpenBalanceSG = Number(seedConAppobj.txtcolContractsPoundsSG) - Number(seedConAppobj.txtcolAppliedSG)
                    seedConAppobj.txtcolPriceSG = seed_conapplication_data[j].seed_contract_prem_per_ton
                    seedConAppobj.txtcolContNumSG = contractNum
                    seedConApplist.push(seedConAppobj)
                    seedConAppobj = {}
                  }
                }
                setValue(thisObj, 'gridSeedGroverApp', seedConApplist)
              }

              if(purchaseStoragevalue == 'P'){
                if(Number(applyPounds) - Number(totalAppliedpounds) > 0)
                {
                  setValue(thisObj, 'txtLbsToApply', Number(applyPounds) - Number(totalAppliedpounds))
                }else{
                  setValue(thisObj, 'txtLbsToApply', '0.00')
                }
              }else if(purchaseStoragevalue == 'S'){
                if(Number(tentPounds) - Number(totalTentpounds) > 0){
                  setValue(thisObj, 'txtLbsToApply', Number(tentPounds) - Number(totalTentpounds))
                }else{
                  setValue(thisObj, 'txtLbsToApply', '0.00')
                }
              }
             
              //EnableDiable Controls
              hide(thisObj, 'btnPurchase')
              if(purchaseStoragevalue == 'P')
              {
                setValue(thisObj, 'txtPounds', applyPounds)
                setValue(thisObj, 'txtPrchsStrg', 'Purchase')
                setValue(thisObj, 'btnPurchase', 'Storage')
                setValue(thisObj, 'lblSpotApplications', 'Spot Applications')
                show(thisObj, 'txtPrice')
                //price label show
  
              }else if(purchaseStoragevalue == 'S')
              {
                setValue(thisObj, 'txtPounds', tentPounds)
                setValue(thisObj, 'txtPrchsStrg', 'Storage')
                setValue(thisObj, 'btnPurchase', 'Purchase')
                setValue(thisObj, 'lblSpotApplications', 'Unapplied Lbs.')
                hide(thisObj, 'txtPrice')
                //price label hide
              }

            });
          }
        });
    }
    return true;
  }

  const onbtnCancelClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      // Modal.close();
      // document.getElementsByClassName("close")[0].click();
      document.getElementById("Settlements_ViewContractApplicationPopUp").childNodes[0].click();
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;


  const onbtncol1Click = async (event) => {
     try {
      let highlight = document.getElementsByClassName("activeRow")
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow")
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement
      rowSelected.classList.add("activeRow")
      let rowID = rowSelected.id.substring(4, 5);
      let NewGridValue = getValue(thisObj, 'gridViewContractApplication')
      if (NewGridValue[rowID].txtcolContNum != null && NewGridValue[rowID].txtcolContNum != undefined && NewGridValue[rowID].txtcolContNum != "") {
        let contractnum = NewGridValue[rowID].txtcolContNum;
        //let APIresponse = await ContractManagementService.RetrieveContractDetails(contractnum, 'PN1010')
        if(contractnum.length >0){
          commonContext1.setContractProfile_ContractNumber(contractnum);
          commonContext1.setContractProfile_FormState("edit");
          goTo(thisObj, "ContractManagement#ContractProfile#DEFAULT#true#Click");
        } else {
          showMessage(thisObj, "Contract does not exist")
        }
      } else {
        showMessage(thisObj, "Contract number cannot be null !!")
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error while selecting contract");
      }
    }
  };
  thisObj.onbtncol1Click = onbtncol1Click;

  const onbtnCol2Click = async (event) => {
    try {
      let highlight = document.getElementsByClassName("activeRow")
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow")
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement
      rowSelected.classList.add("activeRow")
      let rowID = rowSelected.id.substring(4, 5);
      let NewGridValue = getValue(thisObj, 'gridSeedGroverApp')
      if (NewGridValue[rowID].txtcolContNumSG != null && NewGridValue[rowID].txtcolContNumSG != undefined && NewGridValue[rowID].txtcolContNumSG != "") {
        let contractnum = NewGridValue[rowID].txtcolContNumSG;
        //let APIresponse = await ContractManagementService.RetrieveContractDetails(contractnum, 'PN1010')
        if(contractnum.length >0){
          commonContext1.setContractProfile_ContractNumber(contractnum);
          commonContext1.setContractProfile_FormState("edit");
          goTo(thisObj, "ContractManagement#ContractProfile#DEFAULT#true#Click");
        } else {
          showMessage(thisObj, "Contract does not exist")
        }
      } else {
        showMessage(thisObj, "Contract number cannot be null !!")
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error while selecting contract");
      }
    }
  };
  thisObj.onbtnCol2Click = onbtnCol2Click;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ViewContractApplication*/}

              {/* END_USER_CODE-USER_BEFORE_ViewContractApplication*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxViewContractApplication*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxViewContractApplication*/}

              <GroupBoxWidget
                conf={state.grpbxViewContractApplication}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtSC95Num*/}

                {/* END_USER_CODE-USER_BEFORE_txtSC95Num*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSC95Num}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSC95Num*/}

                {/* END_USER_CODE-USER_AFTER_txtSC95Num*/}
                {/* START_USER_CODE-USER_BEFORE_txt1007Num*/}

                {/* END_USER_CODE-USER_BEFORE_txt1007Num*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007Num}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1007Num*/}

                {/* END_USER_CODE-USER_AFTER_txt1007Num*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarm*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarm*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarm*/}

                {/* END_USER_CODE-USER_AFTER_txtFarm*/}
                {/* START_USER_CODE-USER_BEFORE_txtPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_txtPeanutType*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutType}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_txtPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeed*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeed*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeed}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeed*/}

                {/* END_USER_CODE-USER_AFTER_txtSeed*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeedSpec*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeedSpec*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeedSpec}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeedSpec*/}

                {/* END_USER_CODE-USER_AFTER_txtSeedSpec*/}
                {/* START_USER_CODE-USER_BEFORE_txtPounds*/}

                {/* END_USER_CODE-USER_BEFORE_txtPounds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPounds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPounds*/}

                {/* END_USER_CODE-USER_AFTER_txtPounds*/}
                {/* START_USER_CODE-USER_BEFORE_txtPrchsStrg*/}

                {/* END_USER_CODE-USER_BEFORE_txtPrchsStrg*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPrchsStrg}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPrchsStrg*/}

                {/* END_USER_CODE-USER_AFTER_txtPrchsStrg*/}
                {/* START_USER_CODE-USER_BEFORE_lblContractApplications*/}

                {/* END_USER_CODE-USER_BEFORE_lblContractApplications*/}

                <LabelWidget
                  values={values}
                  conf={state.lblContractApplications}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblContractApplications*/}

                {/* END_USER_CODE-USER_AFTER_lblContractApplications*/}
                {/* START_USER_CODE-USER_BEFORE_gridViewContractApplication*/}

                {/* END_USER_CODE-USER_BEFORE_gridViewContractApplication*/}

                <GridWidget
                  conf={state.gridViewContractApplication}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridViewContractApplication}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridViewContractApplication*/}

                {/* END_USER_CODE-USER_AFTER_gridViewContractApplication*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSpotApplication*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSpotApplication*/}

                <GroupBoxWidget
                  conf={state.grpbxSpotApplication}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblSpotApplications*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSpotApplications*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSpotApplications}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSpotApplications*/}

                  {/* END_USER_CODE-USER_AFTER_lblSpotApplications*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLbsToApply*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLbsToApply*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLbsToApply}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLbsToApply*/}

                  {/* END_USER_CODE-USER_AFTER_txtLbsToApply*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPrice*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPrice*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPrice}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPrice*/}

                  {/* END_USER_CODE-USER_AFTER_txtPrice*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSpotApplication*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSpotApplication*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSeedGroverApp*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSeedGroverApp*/}

                <GroupBoxWidget
                  conf={state.grpbxSeedGroverApp}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblSeedGroverApp*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSeedGroverApp*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSeedGroverApp}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSeedGroverApp*/}

                  {/* END_USER_CODE-USER_AFTER_lblSeedGroverApp*/}
                  {/* START_USER_CODE-USER_BEFORE_gridSeedGroverApp*/}

                  {/* END_USER_CODE-USER_BEFORE_gridSeedGroverApp*/}

                  <GridWidget
                    conf={state.gridSeedGroverApp}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridSeedGroverApp}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridSeedGroverApp*/}

                  {/* END_USER_CODE-USER_AFTER_gridSeedGroverApp*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSeedGroverApp*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSeedGroverApp*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxViewContractApplication*/}

              {/* END_USER_CODE-USER_AFTER_grpbxViewContractApplication*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnPurchase*/}

                {/* END_USER_CODE-USER_BEFORE_btnPurchase*/}

                <ButtonWidget
                  conf={state.btnPurchase}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPurchase*/}

                {/* END_USER_CODE-USER_AFTER_btnPurchase*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ViewContractApplication*/}

              {/* END_USER_CODE-USER_AFTER_ViewContractApplication*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_ViewContractApplication);