/* eslint-disable*/
import React from "react";
import ImageWidget from "./ImageWidget";
import { screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("ImageWidget Test Cases :1", () => {
  beforeEach(async () => {
    act(() => {
      let imageWidget1 = {
        name: "imageWidget1",
        type: "ImageWidget",
        parent: "groupboxwidget0",
        Label: "imageWidget1",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AltText: "",
        value: {
          source: "TestImage.PNG",
        },
        widgetState: "Static",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = { imageWidget1: ["TestImage.PNG"] };
      let errors = jest.fn();
      let touched = jest.fn();
      let onBlur = jest.fn();
      renderTestScreen(
        <ImageWidget
          values={values}
          onBlur={onBlur}
          conf={imageWidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Image Widget Testing  Static Image", async () => {
    act(() => {
      const ImageWidget1 = screen.getByTestId("imageWidget1").firstChild
        .lastChild.firstChild;
      fireEvent.load(ImageWidget1);
    });
  });
});
describe("ImageWidget Test Cases :2", () => {
  beforeEach(async () => {
    act(() => {
      let imageWidget1 = {
        name: "imageWidget1",
        type: "ImageWidget",
        parent: "groupboxwidget0",
        Label: "imageWidget1",
        AltText: "",
        value: {
          source: "",
        },
        widgetState: "Dynamic",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = { imageWidget1: ["TestImage.PNG"] };
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      let onBlur = jest.fn();
      renderTestScreen(
        <ImageWidget
          onBlur={onBlur}
          onChange={onChange}
          values={values}
          conf={imageWidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Image Widget Testing  Dynamic Image", async () => {
    act(() => {
      const btn = screen.getByTestId("imageWidget1").firstChild.lastChild
        .lastChild;
      expect(btn.tagName).toBe("BUTTON");
      fireEvent.click(btn);
    });
  });
});

describe("ImageWidget Test Cases :3", () => {
  beforeEach(async () => {
    act(() => {
      let imageWidget2 = {
        name: "imageWidget2",
        type: "ImageWidget",
        parent: "groupboxwidget0",
        Label: "imageWidget2",
        AltText: "",
        value: {
          source: "",
        },
        widgetState: "",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true,
      };
      let values = { imageWidget1: ["TestImage.PNG"] };
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      let onBlur = jest.fn();
      renderTestScreen(
        <ImageWidget
          onBlur={onBlur}
          onChange={onChange}
          values={values}
          conf={imageWidget2}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Image Widget Testing without Dynamic Image", async () => {
    act(() => {
      const defImg = screen.getByTestId("imageWidget2");
      expect(defImg.tagName).toBe("DIV");
      fireEvent.click(defImg);
    });
  });
});
