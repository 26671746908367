/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  DateWidget,
  CheckboxGroupWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  GridWidget,
  setValue,
  getValue,
  disable,
  enable,
  hide,
  show,
  setData,
  getData,
  goTo,
  setFieldValues,
  setLabel,
  hideColumn,
  showColumn,
  readonlyGridColumn,
  readonly
} from "../../shared/WindowImports";

import "./ContractLimitArea.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { returnNumericValues } from './../../Common/Constants';
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import moment from "moment";
import Loading from "../../../Loader/Loading"
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_ContractLimitArea(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ContractLimitArea",
    windowName: "ContractLimitArea",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.ContractLimitArea",
    // START_USER_CODE-USER_ContractLimitArea_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Contract Price Limit Profile",
      scrCode: "PN0260B",
    },
    // END_USER_CODE-USER_ContractLimitArea_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnImage1: {
      name: "btnImage1",
      type: "ButtonWidget",
      parent: "grpbxTonsDetails",
      Label: "Image1",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnImage1_PROPERTIES
      iconName: "fa-bars",
      // END_USER_CODE-USER_btnImage1_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxContractLimitArea",
      Label: "...",
      CharWidth: "9",
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    chkboxGainOffer: {
      name: "chkboxGainOffer",
      type: "CheckBoxWidget",
      parent: "grpbxFlexMarketControls",
      Label: "Market Loan Gain Offer",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxGainOffer_PROPERTIES

      // END_USER_CODE-USER_chkboxGainOffer_PROPERTIES
    },
    chkboxMultiplePricings: {
      name: "chkboxMultiplePricings",
      type: "CheckBoxWidget",
      parent: "grpbxFlexOffers",
      Label: "Multiple Pricings:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxMultiplePricings_PROPERTIES

      // END_USER_CODE-USER_chkboxMultiplePricings_PROPERTIES
    },
    chkboxOrganicInd: {
      name: "chkboxOrganicInd",
      type: "CheckBoxWidget",
      parent: "grpbxContractLimitArea",
      Label: "Organic Ind",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxOrganicInd_PROPERTIES

      // END_USER_CODE-USER_chkboxOrganicInd_PROPERTIES
    },
    chkboxRebateOffer: {
      name: "chkboxRebateOffer",
      type: "CheckBoxWidget",
      parent: "grpbxFlexMarketControls",
      Label: "Flex Market Rebate Offer",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxRebateOffer_PROPERTIES

      // END_USER_CODE-USER_chkboxRebateOffer_PROPERTIES
    },
    // chkboxSpecifyContract: {
    //   name: "chkboxSpecifyContract",
    //   type: "CheckBoxGroupFieldWidget",
    //   parent: "grpbxContractLimitArea",
    //   Options:
    //     "This control is for a specific Contract:1,This control is for a specific Vendor:2",
    //   ColSpanForLargeDesktop: "2",
    //   ColSpanForTabLandscape: "2",
    //   ColSpanForTabPotrait: "2",
    //   HasLabel: false,

    //   ofTypeDomain: "d_boolean",
    //   // START_USER_CODE-USER_chkboxSpecifyContract_PROPERTIES

    //   // END_USER_CODE-USER_chkboxSpecifyContract_PROPERTIES
    // },
    chkboxSpecifyContract: {
      name: "chkboxSpecifyContract",
      type: "CheckBoxWidget",
      parent: "grpbxContractLimitArea",
      Label: "This control is for a specific Contract",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxSpecifyContract_PROPERTIES

      // END_USER_CODE-USER_chkboxSpecifyContract_PROPERTIES
    },
    chkboxSpecifyVendor: {
      name: "chkboxSpecifyVendor",
      type: "CheckBoxWidget",
      parent: "grpbxContractLimitArea",
      Label: "This control is for a specific Vendor",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxSpecifyVendor_PROPERTIES

      // END_USER_CODE-USER_chkboxSpecifyVendor_PROPERTIES
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridLockInDates",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },
    col2: {
      name: "col2",
      type: "GridColumnWidget",
      parent: "gridLockInDates",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col2_PROPERTIES

      // END_USER_CODE-USER_col2_PROPERTIES
    },
    col3: {
      name: "col3",
      type: "GridColumnWidget",
      parent: "gridLockInDates",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col3_PROPERTIES

      // END_USER_CODE-USER_col3_PROPERTIES
    },
    colLockInDate: {
      name: "colLockInDate",
      type: "GridColumnWidget",
      parent: "gridLockInDates",
      Label: "Lock in Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate_PROPERTIES

      // END_USER_CODE-USER_colLockInDate_PROPERTIES
    },
    colMinPctTotalTons: {
      name: "colMinPctTotalTons",
      type: "GridColumnWidget",
      parent: "gridLockInDates",
      Label: "Min % Total",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMinPctTotalTons_PROPERTIES

      // END_USER_CODE-USER_colMinPctTotalTons_PROPERTIES
    },
    colSeqNum: {
      name: "colSeqNum",
      type: "GridColumnWidget",
      parent: "gridLockInDates",
      Label: "Seq#",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeqNum_PROPERTIES

      // END_USER_CODE-USER_colSeqNum_PROPERTIES
    },
    ddArea: {
      name: "ddArea",
      type: "DropDownFieldWidget",
      parent: "grpbxContractLimitArea",
      Label: "Area:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddArea_PROPERTIES

      // END_USER_CODE-USER_ddArea_PROPERTIES
    },
    ddLoanRepayMethod: {
      name: "ddLoanRepayMethod",
      type: "DropDownFieldWidget",
      parent: "grpbxFlexOffers",
      Label: "Loan Repay Method:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLoanRepayMethod_PROPERTIES

      // END_USER_CODE-USER_ddLoanRepayMethod_PROPERTIES
    },
    ddOleicType: {
      name: "ddOleicType",
      type: "DropDownFieldWidget",
      parent: "grpbxContractLimitArea",
      Label: "Oleic Ind:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleicType_PROPERTIES

      // END_USER_CODE-USER_ddOleicType_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxContractLimitArea",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxContractLimitArea",
      Label: "Peanut Variety:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddPricingType: {
      name: "ddPricingType",
      type: "DropDownFieldWidget",
      parent: "grpbxContractLimitArea",
      Label: "Pricing Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPricingType_PROPERTIES

      // END_USER_CODE-USER_ddPricingType_PROPERTIES
    },
    ddSegType: {
      name: "ddSegType",
      type: "DropDownFieldWidget",
      parent: "grpbxContractLimitArea",
      Label: "Seg Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegType_PROPERTIES

      // END_USER_CODE-USER_ddSegType_PROPERTIES
    },
    gridLockInDates: {
      name: "gridLockInDates",
      type: "GridWidget",
      parent: "grpbxDates",
      gridCellsOrder:
        "txtcol1,txtcol2,txtcol3,txtcolSeqNum,txtcolLockInDate,txtcolMinPctTotalTons",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Cols: "2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridLockInDates_PROPERTIES
      isAddRowRequired: true,
      isDeleteRowRequired: true,
      isEditable: true,
      isCobolTable: true,
      deleteRowFunction: () => {
        calcTotal()
      }
      // END_USER_CODE-USER_gridLockInDates_PROPERTIES
    },
    grpbxDates: {
      name: "grpbxDates",
      type: "GroupBoxWidget",
      parent: "grpbxFlexMarketControls",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxDates_PROPERTIES

      // END_USER_CODE-USER_grpbxDates_PROPERTIES
    },
    grpbxFlexMarketControls: {
      name: "grpbxFlexMarketControls",
      type: "GroupBoxWidget",
      parent: "grpbxContractLimitArea",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxFlexMarketControls_PROPERTIES

      // END_USER_CODE-USER_grpbxFlexMarketControls_PROPERTIES
    },
    grpbxFlexOffers: {
      name: "grpbxFlexOffers",
      type: "GroupBoxWidget",
      parent: "grpbxFlexMarketControls",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxFlexOffers_PROPERTIES

      // END_USER_CODE-USER_grpbxFlexOffers_PROPERTIES
    },
    grpbxFloorControls: {
      name: "grpbxFloorControls",
      type: "GroupBoxWidget",
      parent: "grpbxContractLimitArea",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxFloorControls_PROPERTIES

      // END_USER_CODE-USER_grpbxFloorControls_PROPERTIES
    },
    grpbxGainOffer: {
      name: "grpbxGainOffer",
      type: "GroupBoxWidget",
      parent: "grpbxFlexOffers",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxGainOffer_PROPERTIES

      // END_USER_CODE-USER_grpbxGainOffer_PROPERTIES
    },
    grpbxRebateOffer: {
      name: "grpbxRebateOffer",
      type: "GroupBoxWidget",
      parent: "grpbxFlexOffers",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxRebateOffer_PROPERTIES

      // END_USER_CODE-USER_grpbxRebateOffer_PROPERTIES
    },
    grpbxTonsDetails: {
      name: "grpbxTonsDetails",
      type: "GroupBoxWidget",
      parent: "grpbxContractLimitArea",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxTonsDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxTonsDetails_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblDateFormat: {
      name: "lblDateFormat",
      type: "LabelWidget",
      parent: "grpbxFloorControls",
      Label: "mm/dd/yyyy",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDateFormat_PROPERTIES

      // END_USER_CODE-USER_lblDateFormat_PROPERTIES
    },
    lblFloorControls: {
      name: "lblFloorControls",
      type: "LabelWidget",
      parent: "grpbxFloorControls",
      Label: "Floor Controls",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFloorControls_PROPERTIES

      // END_USER_CODE-USER_lblFloorControls_PROPERTIES
    },
    lblFlexMarketControls: {
      name: "lblFlexMarketControls",
      type: "LabelWidget",
      parent: "grpbxFloorControls",
      Label: "Flex Market Controls",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFloorControls_PROPERTIES

      // END_USER_CODE-USER_lblFloorControls_PROPERTIES
    },
    lblPrcLockInDts: {
      name: "lblPrcLockInDts",
      type: "LabelWidget",
      parent: "grpbxDates",
      Label: "Pricing Lock-In Dates",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPrcLockInDts_PROPERTIES

      // END_USER_CODE-USER_lblPrcLockInDts_PROPERTIES
    },
    lblWeeks: {
      name: "lblWeeks",
      type: "LabelWidget",
      parent: "grpbxFlexOffers",
      Label: "weeks prior to maturity date.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWeeks_PROPERTIES

      // END_USER_CODE-USER_lblWeeks_PROPERTIES
    },
    radioFloorControlsPriced: {
      name: "radioFloorControlsPriced",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxFloorControls",
      Options:
        "on or before Price Match End Date :1,on or before inspection of any peanuts:2",
      Label: "Floor Contracts must be fully priced:",
      ColSpan: "2",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioFloorControlsPriced_PROPERTIES

      // END_USER_CODE-USER_radioFloorControlsPriced_PROPERTIES
    },
    radioPayRebate: {
      name: "radioPayRebate",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxFlexOffers",
      Options: "at end of the Marketing Period:1,at Pricing Election:2",
      Label: "Pay Rebate:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioPayRebate_PROPERTIES

      // END_USER_CODE-USER_radioPayRebate_PROPERTIES
    },
    txtAvailableTon: {
      name: "txtAvailableTon",
      type: "TextBoxWidget",
      parent: "grpbxTonsDetails",
      Label: "Available Tons:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAvailableTon_PROPERTIES

      // END_USER_CODE-USER_txtAvailableTon_PROPERTIES
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpbxContractLimitArea",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES

      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    txtcol1: {
      name: "txtcol1",
      type: "TextBoxWidget",
      colName: "col1",
      parent: "gridLockInDates",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol1_PROPERTIES

      // END_USER_CODE-USER_txtcol1_PROPERTIES
    },
    txtcol2: {
      name: "txtcol2",
      type: "TextBoxWidget",
      colName: "col2",
      parent: "gridLockInDates",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol2_PROPERTIES

      // END_USER_CODE-USER_txtcol2_PROPERTIES
    },
    txtcol3: {
      name: "txtcol3",
      type: "TextBoxWidget",
      colName: "col3",
      parent: "gridLockInDates",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol3_PROPERTIES

      // END_USER_CODE-USER_txtcol3_PROPERTIES
    },
    txtcolLockInDate: {
      name: "txtcolLockInDate",
      type: "TextBoxWidget",
      colName: "colLockInDate",
      parent: "gridLockInDates",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtcolLockInDate_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate_PROPERTIES
    },
    txtcolMinPctTotalTons: {
      name: "txtcolMinPctTotalTons",
      type: "TextBoxWidget",
      colName: "colMinPctTotalTons",
      parent: "gridLockInDates",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMinPctTotalTons_PROPERTIES

      // END_USER_CODE-USER_txtcolMinPctTotalTons_PROPERTIES
    },
    txtcolSeqNum: {
      name: "txtcolSeqNum",
      type: "TextBoxWidget",
      colName: "colSeqNum",
      parent: "gridLockInDates",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeqNum_PROPERTIES
      readOnly: true,
      // END_USER_CODE-USER_txtcolSeqNum_PROPERTIES
    },
    txtDescription: {
      name: "txtDescription",
      type: "TextBoxWidget",
      parent: "grpbxContractLimitArea",
      Label: "Description:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 20 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDescription_PROPERTIES

      // END_USER_CODE-USER_txtDescription_PROPERTIES
    },
    txtDryLandAcresContracted: {
      name: "txtDryLandAcresContracted",
      type: "TextBoxWidget",
      parent: "grpbxTonsDetails",
      Label: "Dry Land Acres Contracted:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDryLandAcresContracted_PROPERTIES

      // END_USER_CODE-USER_txtDryLandAcresContracted_PROPERTIES
    },
    txtEndMarketPeriod: {
      name: "txtEndMarketPeriod",
      type: "TextBoxWidget",
      parent: "grpbxFlexOffers",
      Label: "End market period:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEndMarketPeriod_PROPERTIES

      // END_USER_CODE-USER_txtEndMarketPeriod_PROPERTIES
    },
    txtGainSharePct: {
      name: "txtGainSharePct",
      type: "TextBoxWidget",
      parent: "grpbxGainOffer",
      Label: "Producer's Market Gain Share%",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGainSharePct_PROPERTIES

      // END_USER_CODE-USER_txtGainSharePct_PROPERTIES
    },
    txtIrrigatedAcresContracted: {
      name: "txtIrrigatedAcresContracted",
      type: "TextBoxWidget",
      parent: "grpbxTonsDetails",
      Label: "Irrigated Acres Contracted:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIrrigatedAcresContracted_PROPERTIES

      // END_USER_CODE-USER_txtIrrigatedAcresContracted_PROPERTIES
    },
    txtMarketCeiling: {
      name: "txtMarketCeiling",
      type: "TextBoxWidget",
      parent: "grpbxRebateOffer",
      Label: "Market Ceiling:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMarketCeiling_PROPERTIES

      // END_USER_CODE-USER_txtMarketCeiling_PROPERTIES
    },
    txtMarketFloor: {
      name: "txtMarketFloor",
      type: "TextBoxWidget",
      parent: "grpbxRebateOffer",
      Label: "Market Floor:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMarketFloor_PROPERTIES

      // END_USER_CODE-USER_txtMarketFloor_PROPERTIES
    },
    txtOpenTons: {
      name: "txtOpenTons",
      type: "TextBoxWidget",
      parent: "grpbxTonsDetails",
      Label: "Open Tons:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOpenTons_PROPERTIES

      // END_USER_CODE-USER_txtOpenTons_PROPERTIES
    },
    txtOptionPriceCap: {
      name: "txtOptionPriceCap",
      type: "TextBoxWidget",
      parent: "grpbxFloorControls",
      Label: "Option Price Cap:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOptionPriceCap_PROPERTIES

      // END_USER_CODE-USER_txtOptionPriceCap_PROPERTIES
    },
    txtPriceMatchEndDate: {
      name: "txtPriceMatchEndDate",
      type: "DateWidget",
      parent: "grpbxFloorControls",
      Label: "Price Match End Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtPriceMatchEndDate_PROPERTIES

      // END_USER_CODE-USER_txtPriceMatchEndDate_PROPERTIES
    },
    txtPricePerTon: {
      name: "txtPricePerTon",
      type: "TextBoxWidget",
      parent: "grpbxContractLimitArea",
      Label: "Price/Ton:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPricePerTon_PROPERTIES

      // END_USER_CODE-USER_txtPricePerTon_PROPERTIES
    },
    txtRebaseRate: {
      name: "txtRebaseRate",
      type: "TextBoxWidget",
      parent: "grpbxRebateOffer",
      Label: "Rebate Rate:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRebaseRate_PROPERTIES

      // END_USER_CODE-USER_txtRebaseRate_PROPERTIES
    },
    txtTonsAllowed: {
      name: "txtTonsAllowed",
      type: "TextBoxWidget",
      parent: "grpbxContractLimitArea",
      Label: "Tons Allowed:",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTonsAllowed_PROPERTIES

      // END_USER_CODE-USER_txtTonsAllowed_PROPERTIES
    },
    txtTonsAssigned: {
      name: "txtTonsAssigned",
      type: "TextBoxWidget",
      parent: "grpbxTonsDetails",
      Label: "Tons Assigned:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTonsAssigned_PROPERTIES

      // END_USER_CODE-USER_txtTonsAssigned_PROPERTIES
    },
    txtTonsContracted: {
      name: "txtTonsContracted",
      type: "TextBoxWidget",
      parent: "grpbxTonsDetails",
      Label: "Tons Contracted:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTonsContracted_PROPERTIES

      // END_USER_CODE-USER_txtTonsContracted_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxContractLimitArea",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVendorName: {
      name: "txtVendorName",
      type: "TextBoxWidget",
      parent: "grpbxContractLimitArea",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES
      ReasOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtTotal: {
      name: "txtTotal",
      type: "TextBoxWidget",
      parent: "grpbxDates",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotal_PROPERTIES
      readOnly: true,
      // END_USER_CODE-USER_txtTotal_PROPERTIES
    },
    grpbxContractLimitArea: {
      name: "grpbxContractLimitArea",
      type: "GroupBoxWidget",
      parent: "ContractLimitArea",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxContractLimitArea_PROPERTIES

      // END_USER_CODE-USER_grpbxContractLimitArea_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ContractLimitArea",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null) {
      setValue(thisObj, "txtVendor", vendor_lookup.VendorNumber);
      show(thisObj, "txtVendorName")
      setValue(thisObj, "txtVendorName", vendor_lookup.key);
    }
  }, [getData(thisObj, 'vendorDetails')]);


  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    // formload()
    // dataFromParentPage()
    customFormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  async function customFormLoad() {
    setLoading(true)
    await formload()
    await dataFromParentPage()
    setLoading(false)
  }

  const [loading, setLoading] = useState(false);
  const [gridValues, setGridValues] = useState(true);

  
  useEffect(() => {
    let value = getData(thisObj, "closingOfContractLimitCollPt");
    if (value === true) {
      bFillForm();
      let ParentData = getData(thisObj, "ContractLimitSearchData");
      if (ParentData !== undefined && ParentData !== null) {
        ParentData.editMode = true;
        setData(thisObj, "ContractLimitSearchData", ParentData);
      }
      setData(thisObj, "closingOfContractLimitCollPt", false);
    }
  }, [getData(thisObj, "closingOfContractLimitCollPt")])

  useEffect(() => {
    return () => {
      let ParentData = getData(thisObj, "ContractLimitSearchData");
      if (ParentData !== undefined && ParentData !== null) {
        ParentData.editMode = false;
        setData(thisObj, "ContractLimitSearchData", ParentData);
      }
    }
  }, [])

  async function dataFromParentPage() {
    let ParentData = getData(thisObj, "ContractLimitSearchData");
    if (ParentData !== null) {
      let bFillform_Boolean = ParentData.editMode !== undefined ? ParentData.editMode : false;
      if (bFillform_Boolean) {
        await bFillForm();
      }
    }
  }

  function gridChange(event) {
    if (event.type === 'blur') {
      //let gridData = getValue(thisObj, "gridLockInDates");
      let gridData = thisObj.values.gridLockInDates;
      if (gridData !== undefined && gridData !== null && gridData !== []) {

        //gridData = gridData.reverse();
        let total = 0;
        let date;
        for (let i = 0, j = gridData.length - 1; i < gridData.length; i++, j--) {
          //if(!(gridData[i].editing !== undefined && gridData[i].editing)){
          gridData[i].txtcolSeqNum = i + 1;  //consider rowId here
          gridData[i].txtcolMinPctTotalTons = isNaN(gridData[i].txtcolMinPctTotalTons) ? '0' : gridData[i].txtcolMinPctTotalTons;
          gridData[i].txtcolMinPctTotalTons = FormatNumber(gridData[i].txtcolMinPctTotalTons, 2) == 0 ? '' : FormatNumber(gridData[i].txtcolMinPctTotalTons, 2);
          //}
          total += Number(gridData[i].txtcolMinPctTotalTons);
          date = gridData[i].txtcolLockInDate;
          if (!moment(date, "MM/DD/YYYY").isValid()) {
            showMessage(thisObj, "Date is not valid!");
            setGridValues(false);
          }
        }
        setValue(thisObj, "txtTotal", total);
        if (total > 100) {
          showMessage(thisObj, "Percentage is out of range!")
          setGridValues(false);
        }
        setValue(thisObj, "gridLockInDates", gridData);
        setGridValues(true);
      } else {
        setGridValues(true);
      }
    }
  }


  // let pageConstants = {
  //   mStrDesc: String,
  //   mStrPricePerTon: String,
  //   mStrMaxOptionPrice: String,
  //   mStrMktFloor: String,
  //   mStrMktCeiling: String,
  //   mStrRebateRate: String,
  //   mStrMktGainShare: String,
  //   mStrLoanRepayMethod: String,
  //   mStrMktWkPriorMat: String,
  //   mStrPriceMatchEndDate: String,

  //   mbBeforeEndDate: Boolean,
  //   mbBeforeDelivery: Boolean,
  //   mbMultiPricing: Boolean,
  //   mbEndMarket: Boolean,
  //   mbElection: Boolean,

  //   mbLockInDateChg: Boolean
  // }

  async function formload() {
    try {
      disable(thisObj, "txtTotal");

      let bFillAreaList_value = await bFillAreaList()
      let bFillPriceTypeList_value = bFillPriceTypeList()
      let bFillTypeList_value = await bFillTypeList()
      let bFillLoanRepayMethodList_value = bFillLoanRepayMethodList()


      if (!(bFillAreaList_value && bFillPriceTypeList_value && bFillTypeList_value && bFillLoanRepayMethodList_value)) {
        showMessage(thisObj, "formLoad event failed");
      };

      setValue(thisObj, "gridLockInDates", [])
      setData(thisObj, "ContractLimitArea_gridLockINDates", [])

      hide(thisObj, "txtContract")
      hide(thisObj, "txtVendor")
      hide(thisObj, "btnVendor")
      hide(thisObj, "txtVendorName")
      //document.getElementById('txtVendorName').style.visibility = "hidden";

      hideColumn(thisObj, "gridLockInDates", "txtcol1")
      hideColumn(thisObj, "gridLockInDates", "txtcol2")
      hideColumn(thisObj, "gridLockInDates", "txtcol3");

      readonlyGridColumn(thisObj, "gridLockInDates", "txtcolSeqNum", true);
      readonlyGridColumn(thisObj, "gridLockInDates", "txtcol1", true);
      readonlyGridColumn(thisObj, "gridLockInDates", "txtcol2", true);
      readonlyGridColumn(thisObj, "gridLockInDates", "txtcol3", true);

      hide(thisObj, "grpbxTonsDetails")

      //extra code , not in vb but written to keep page logic same
      let js = [];
      js.push({ key: '', description: '>>> All Varieties <<<' })
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutVariety: {
            ...state["ddPeanutVariety"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddPeanutVariety', js[0].key);

      bFillSegmentList();
      bFillOleicList();

      disable(thisObj, "txtTonsAssigned");
      disable(thisObj, "txtAvailableTon");
      disable(thisObj, "txtTonsContracted");
      disable(thisObj, "txtOpenTons");
      disable(thisObj, "txtIrrigatedAcresContracted");
      disable(thisObj, "txtDryLandAcresContracted");

      //uncomment when code writing is done
      setValue(thisObj, "radioPayRebate", "1");
      setValue(thisObj, "radioFloorControlsPriced", "1");
      setValue(thisObj, "chkboxMultiplePricings", true);
      // hide(thisObj, "chkboxOrganicInd");
      hide(thisObj, "grpbxFloorControls");
      document.getElementsByClassName("grpbxGainOffer")[0].style.visibility = "hidden";
      document.getElementsByClassName("grpbxRebateOffer")[0].style.visibility = "hidden";
      hide(thisObj, "grpbxFlexMarketControls");

    } catch (error) {
      errorHandler(error, 'FormLoad Event');
      document.getElementById("SystemMaintenanceMasterManagement_ContractLimitAreaPopUp").childNodes[0].click()
    }
  }

  async function bFillAreaList() {
    try {
      let js = [];
      js.push({ key: '', description: '>>> Select Area <<<' })

      let data = await SettlementService.RetrieveAreaControlDetails(null);
      if (data === [] || data === undefined || data.length === 0 || data == null) {
        return false;
      } else {
        for (let i = 0; i < data.length; i++) {
          js.push({ key: data[i].areaId, description: data[i].areaId + ' - ' + data[i].areaName })
        }

        thisObj.setState(current => {
          return {
            ...current,
            ddArea: {
              ...state['ddArea'],
              valueList: js
            }
          }
        })

        setValue(thisObj, 'ddArea', js.at(0).key);
        return true;
      }

    } catch (error) {
      errorHandler(error, "Area dropdown")
      return false;
    }
  }

  function bFillPriceTypeList() {
    try {
      let js = [];

      js.push({ key: "", description: ">>> Select Pricing <<<" })
      js.push({ key: "C-BASIS", description: "Basis Contract" })
      js.push({ key: "C-FIRM", description: "Firm Contract" })
      js.push({ key: "OPTION", description: "Option Pricings" })
      js.push({ key: "FLEX MKT", description: "Flex Market Pricings" })
      js.push({ key: "SEED", description: "Seed Pricings" })
      js.push({ key: "FLOOR", description: "Floor Pricings" })

      thisObj.setState(current => {
        return {
          ...current,
          ddPricingType: {
            ...state['ddPricingType'],
            valueList: js
          }
        }
      })

      setValue(thisObj, "ddPricingType", js.at(0).key)
      return true;

    } catch (error) {
      errorHandler(error, "Pricing type dropdown")
      return false;
    }
  }

  async function bFillTypeList() {
    try {

      let js = [];
      js.push({ key: '', description: '>>> Select Type <<<' })

      let data = await ContractManagementService.RetrievePeanutTypeControls(null);
      if (data === [] || data === undefined || data.length === 0 || data == null) {
        return false;
      } else {
        for (let i = 0; i < data.length; i++) {
          js.push({ key: data[i].pnutTypeId, description: data[i].pnutTypeId + ' - ' + data[i].pnutTypeName })
        }

        thisObj.setState(current => {
          return {
            ...current,
            ddPeanutType: {
              ...state['ddPeanutType'],
              valueList: js
            }
          }
        })

        setValue(thisObj, 'ddPeanutType', js.at(0).key);
        return true;
      }


    } catch (error) {
      errorHandler(error, "Peanut type dropdown")
    }
  }

  function bFillLoanRepayMethodList() {
    try {
      let js = [];

      js.push({ key: "G", description: "Golden", selected: false })
      js.push({ key: "U", description: "USDA", selected: false })

      thisObj.setState(current => {
        return {
          ...current,
          ddLoanRepayMethod: {
            ...state["ddLoanRepayMethod"],
            valueList: js
          }
        }
      });

      setValue(thisObj, "ddLoanRepayMethod", js.at(0).key)

      return true
    } catch (error) {
      errorHandler(error, "Loan Repay Method List function")
      return false;
    }
  }

  async function bFillVarietyList() {
    try {
      setLoading(true);
      let js = [];
      js.push({ key: '', description: '>>> All Varieties <<<' })
      let areaId = getValue(thisObj, 'ddArea');

      let peanut_type = getValue(thisObj, 'ddPeanutType');

      if (peanut_type !== null && peanut_type !== "" && peanut_type !== undefined) {
        let response = await ContractManagementService.RetrievePeanutVarietyControls(null, null, areaId, peanut_type, null)
        if (response === undefined || response == null || response.length === 0) {
          return false;
        } else {
          let data = response;
          let count = 0;
          data.forEach(element => {
            count++;
            let newVar = true;
            for (let i = 0; i < count - 1; i++) {
              if ((element.pnut_variety_id).toUpperCase() === (data[i].pnut_variety_id).toUpperCase()) {
                newVar = false;
                break;
              }
            }
            let symbol;
            if (newVar) {
              switch (element.symbol_ind.trim()) {
                case "TRADEMARK":
                  symbol = " \u2122"
                  break;
                case "REGISTERED TRADEMARK":
                  symbol = " \xAE"
                  break;
                case "COPYRIGHT":
                  symbol = " \xA9"
                  break;
                default:
                  symbol = "";
              }
              let obj = { key: element.pnut_variety_id, description: element.pnut_variety_name + symbol }
              js.push(obj)
            }
          });
        }
        //})
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutVariety: {
            ...state["ddPeanutVariety"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddPeanutVariety', js[0].key);
      setLoading(false);
      return true;
    } catch (error) {
      errorHandler(error, "Peanut Variety dropdown")
      setLoading(false);
      return false;
    }
  }

  function bFillSegmentList() {
    try {
      let js = [];

      js.push({ key: "", description: ">>> Select Segment <<<" })
      js.push({ key: "1", description: "Seg1" })
      js.push({ key: "2", description: "Seg2" })
      js.push({ key: "3", description: "Seg3" })

      thisObj.setState(current => {
        return {
          ...current,
          ddSegType: {
            ...state['ddSegType'],
            valueList: js
          }
        }
      })

      setValue(thisObj, "ddSegType", js.at(0).key)
      return true;
    } catch (error) {
      errorHandler(error, "Segment Dropdown")
      return false;
    }
  }

  function bFillOleicList() {
    try {
      let js = [];

      js.push({ key: "", description: ">>> Any Oleic <<<" })
      js.push({ key: "NONE", description: "None" })
      js.push({ key: "MID", description: "Mid" })
      js.push({ key: "HIGH", description: "High" })

      thisObj.setState(current => {
        return {
          ...current,
          ddOleicType: {
            ...state['ddOleicType'],
            valueList: js
          }
        }
      })

      setValue(thisObj, "ddOleicType", js.at(0).key)
      return true;
    } catch (error) {
      errorHandler(error, "Oleic Dropdown")
      return false;
    }
  }

  async function bFillForm() {
    try {
      setLoading(true);
      setValue(thisObj, "gridLockInDates", [])
      setData(thisObj, "ContractLimitArea_gridLockINDates", [])
      show(thisObj, "grpbxTonsDetails")

      //check this portion if it is actually needed or not
      hide(thisObj, "btnImage1", false)
      hide(thisObj, "txtAvailableTon", false)
      hide(thisObj, "txtDryLandAcresContracted", false)
      hide(thisObj, "txtIrrigatedAcresContracted", false)
      hide(thisObj, "txtOpenTons", false)
      hide(thisObj, "txtTonsAssigned", false)
      hide(thisObj, "txtTonsContracted", false)

      let param = {};
      let tags = 'DETAIL';
      let recordKey = getData(thisObj, "ContractLimitSearchData")
      param.record_key = recordKey.recordKey;
      //param.record_key = '12858';//remove this hardcoded value later
      //param.record_key = "12798"

      let data = await SystemMaintenanceMasterManagementService.RetrieveContractPriceLimitDetails(param, tags)
      if (data !== undefined && data !== null && data.length !== 0) {

        show(thisObj, "txtTonsContracted")
        show(thisObj, "txtOpenTons")
        show(thisObj, "txtIrrigatedAcresContracted")
        show(thisObj, "txtDryLandAcresContracted")

        let formLoadValues = {};
        formLoadValues.ddPricingType = data[0].pricing_type;
        formLoadValues.txtDescription = data[0].pricing_type_desc;

        switch (data[0].control_level) {
          case 'AR':
            show(thisObj, "txtAvailableTon")
            show(thisObj, "txtTonsAssigned")
            show(thisObj, "btnImage1");
            formLoadValues.ddArea = data[0].area_id;

            break;

          case 'SC':
            formLoadValues.chkboxSpecifyContract = true;
            formLoadValues.txtContract = data[0].contract_num;

            break;

          case 'SV':
            formLoadValues.chkboxSpecifyVendor = true;
            formLoadValues.txtVendor = data[0].vendor_num;

            break;

          default:
            break;
        }

        // if contract checkbox is unchecked and peanut-type and peanut-variety is visible on screen
        if (formLoadValues.chkboxSpecifyContract === undefined) {
          formLoadValues.ddPeanutType = data[0].pnut_type_id;

          // if (data[0].pnut_type_id === 'SP' || data[0].pnut_type_id === 'VL') {// Commented as part of UAT Fix as the Organic Ind is available for all peanut type now.
            if (data[0].organic_ind === 'Y') {
              formLoadValues.chkboxOrganicInd = true;
            }
          // }

          formLoadValues.ddPeanutVariety = data[0].pnut_variety_id;
          formLoadValues.ddOleicType = data[0].oleic_ind;
          formLoadValues.ddSegType = data[0].seg_type;
        }

        formLoadValues.txtPricePerTon = data[0].price_per_ton;
        formLoadValues.txtOptionPriceCap = data[0].max_option_price.length >= 5 ? (data[0].max_option_price.length == 5 ? FormatNumber(data[0].max_option_price, 1).replace(/,/g, '') : data[0].max_option_price) : FormatNumber(data[0].max_option_price, 2).replace(/,/g, '');

        if (data[0].rebate_rate !== undefined && data[0].rebate_rate !== '') {
          formLoadValues.chkboxRebateOffer = true;
          formLoadValues.txtMarketCeiling = FormatNumber(data[0].market_ceiling);
          formLoadValues.txtMarketFloor = FormatNumber(data[0].market_floor);
          formLoadValues.txtRebaseRate = data[0].rebate_rate.length >= 4 ? (data[0].rebate_rate.length == 4 ? FormatNumber(data[0].rebate_rate, 1).replace(/,/g, '') : data[0].rebate_rate) : FormatNumber(data[0].rebate_rate, 2).replace(/,/g, '');
        }

        if (data[0].mkt_gain_share !== undefined && data[0].mkt_gain_share !== '') {
          formLoadValues.chkboxGainOffer = true;
          //formLoadValues.txtGainSharePct = FormatNumber(data[0].mkt_gain_share, 2);
          formLoadValues.txtGainSharePct = data[0].mkt_gain_share.length >= 4 ? (data[0].mkt_gain_share.length == 4 ? FormatNumber(data[0].mkt_gain_share, 1).replace(/,/g, '') : data[0].mkt_gain_share) : FormatNumber(data[0].mkt_gain_share, 2).replace(/,/g, '');
        }

        formLoadValues.ddLoanRepayMethod = data[0].loan_repay_ind;
        formLoadValues.txtEndMarketPeriod = data[0].mkt_wk_prior_mat;
        formLoadValues.chkboxMultiplePricings = data[0].multi_pricing_ind === 'Y' ? true : false;

        if (data[0].pay_rebate_at === "DELIVERY") {
          formLoadValues.radioFloorControlsPriced = '2';
        }
        else {
          formLoadValues.radioFloorControlsPriced = '1';
        }

        if (data[0].pay_rebate_at === "END_PERIOD") {
          formLoadValues.radioPayRebate = '1';
        }
        else {
          formLoadValues.radioPayRebate = '2';
        }

        formLoadValues.txtPriceMatchEndDate = data[0].price_match_end_date;

        formLoadValues.txtTonsAllowed = FormatNumber(data[0].tons_allocated);
        formLoadValues.txtTonsAssigned = FormatNumber(data[0].tons_assigned);
        formLoadValues.txtAvailableTon = FormatNumber(data[0].tons_allocated - data[0].tons_assigned);
        formLoadValues.txtTonsContracted = FormatNumber(data[0].tons_contracted);
        formLoadValues.txtOpenTons = FormatNumber(data[0].tons_allocated - data[0].tons_contracted);
        formLoadValues.txtIrrigatedAcresContracted = data[0].irrigated_acre;
        formLoadValues.txtDryLandAcresContracted = data[0].dryland_acre;

        //setLabel(thisObj , "lblAddedByValue", data[0].add_date_time);
        document.getElementsByClassName('lblAddedByValue')[0].innerText = data[0].add_user + " - " + data[0].add_date_time;
        //setLabel(thisObj , "lblChangedByValue", data[0].chg_date_time);
        document.getElementsByClassName('lblChangedByValue')[0].innerText = data[0].add_user + " - " + data[0].chg_date_time;

        if (data[0].lock_in_data !== undefined && data[0].lock_in_data.length !== 0) {
          let tableObj = [];
          let percentTotal = 0;
          for (let i = 0; i < data[0].lock_in_data.length; i++) {
            let tempObj = {};
            let apiTable = data[0].lock_in_data[i];
            tempObj.txtcol1 = apiTable.lock_in_key;
            tempObj.txtcol2 = apiTable.lock_in_date;
            tempObj.txtcol3 = apiTable.lock_in_pct;
            tempObj.txtcolSeqNum = i + 1;
            tempObj.txtcolLockInDate = moment(apiTable.lock_in_date).format('MM/DD/YYYY');
            tempObj.txtcolMinPctTotalTons = FormatNumber(apiTable.lock_in_pct, 2).replace(/,/g, '');
            percentTotal += Number(apiTable.lock_in_pct);
            tableObj.push(tempObj);
          }

          hideColumn(thisObj, "gridLockInDates", "txtcol1")
          hideColumn(thisObj, "gridLockInDates", "txtcol2")
          hideColumn(thisObj, "gridLockInDates", "txtcol3");
          setValue(thisObj, "gridLockInDates", tableObj);
          setData(thisObj, "ContractLimitArea_gridLockINDates", tableObj);
          formLoadValues.txtTotal = percentTotal;
        }

        //setFieldValues(thisObj, formLoadValues, true);
        for (const key in formLoadValues) {
          if (formLoadValues.hasOwnProperty(key)) {
            setValue(thisObj, key, formLoadValues[key])
          }
        }

        document.getElementById("btnAdd").innerHTML = 'Update';
        disable(thisObj, "chkboxSpecifyContract");
        disable(thisObj, "chkboxSpecifyVendor");
        disable(thisObj, "ddPeanutType");
        disable(thisObj, "ddArea");
        disable(thisObj, "txtContract");
        disable(thisObj, "txtVendor");
        disable(thisObj, "btnVendor");
        disable(thisObj, "ddPricingType");
        disable(thisObj, "chkboxOrganicInd");
        disable(thisObj, "ddPeanutVariety");
        disable(thisObj, "ddOleicType");
        disable(thisObj, "ddSegType");
        let pageConstants = {}

        pageConstants.mStrDesc = formLoadValues.txtDescription;
        pageConstants.mStrLoanRepayMethod = formLoadValues.ddLoanRepayMethod;
        pageConstants.mStrMaxOptionPrice = formLoadValues.txtOptionPriceCap;
        pageConstants.mStrMktCeiling = formLoadValues.txtMarketCeiling !== undefined ? formLoadValues.txtMarketCeiling : "";
        pageConstants.mStrMktFloor = formLoadValues.txtMarketFloor !== undefined ? formLoadValues.txtMarketFloor : "";
        pageConstants.mStrMktGainShare = formLoadValues.txtGainSharePct !== undefined ? formLoadValues.txtGainSharePct : "";
        pageConstants.mStrMktWkPriorMat = formLoadValues.txtEndMarketPeriod;
        pageConstants.mStrPriceMatchEndDate = formLoadValues.txtPriceMatchEndDate;
        pageConstants.mStrPricePerTon = formLoadValues.txtPricePerTon;
        pageConstants.mStrRebateRate = formLoadValues.txtRebaseRate !== undefined ? formLoadValues.txtRebaseRate : "";

        pageConstants.mbBeforeEndDate = formLoadValues.radioFloorControlsPriced === '1' ? true : false;
        pageConstants.mbBeforeDelivery = formLoadValues.radioFloorControlsPriced === '2' ? true : false;
        pageConstants.mbMultiPricing = formLoadValues.chkboxMultiplePricings;
        pageConstants.mbEndMarket = formLoadValues.radioPayRebate === '1' ? true : false;
        pageConstants.mbElection = formLoadValues.radioPayRebate === '2' ? true : false;

        pageConstants.mbLockInDateChg = false
        setData(thisObj, "ContractlimitArea_pageConstants", pageConstants)

        if (formLoadValues.chkboxSpecifyVendor !== undefined) {
          hideShowFor_chkboxSpecifyVendor(formLoadValues.chkboxSpecifyVendor);
        }
        if (formLoadValues.chkboxSpecifyContract !== undefined) {
          hideShowFor_chkboxSpecifyContract(formLoadValues.chkboxSpecifyContract);
        }
        if (formLoadValues.chkboxRebateOffer !== undefined) {
          hideshowFor_chkboxRebateOffer(formLoadValues.chkboxRebateOffer)
        }
        if (formLoadValues.chkboxGainOffer !== undefined) {
          hideshowFor_chkboxGainOffer(formLoadValues.chkboxGainOffer);
        }
        if (formLoadValues.txtVendor !== undefined && formLoadValues.txtVendor !== '') {
          ontxtVendorBlur(formLoadValues.txtVendor);
        }
        hideShowFor_ddPricingType(formLoadValues.ddPricingType, formLoadValues.chkboxGainOffer, formLoadValues.chkboxRebateOffer);

        if (formLoadValues.chkboxOrganicInd !== undefined) {
          formLoadValues.chkboxOrganicInd ? show(thisObj, "chkboxOrganicInd") : hide(thisObj, "chkboxOrganicInd");
        }
        setLoading(false);
        return true;
      } else {
        //not sure if api failed warning needed or not yet
        showMessage(thisObj, 'formload api failed. Please close and try again later!!');
        setLoading(false);
        return false;
      }

    } catch (error) {
      errorHandler(error, 'Form filing method');
      setLoading(false);
      return false;
    }
  }

  async function bFormValid() {
    try {
      //let Value = (thisObj, );
      //collecting values from page
      let ddPricingTypeValue = getValue(thisObj, "ddPricingType");
      let chkboxSpecifyContractValue = getValue(thisObj, "chkboxSpecifyContract");
      let chkboxSpecifyVendorValue = getValue(thisObj, "chkboxSpecifyVendor");
      let chkboxMultiplePricingsValue = getValue(thisObj, "chkboxMultiplePricings");
      let ddAreaValue = getValue(thisObj, "ddArea");
      let txtVendorValue = getValue(thisObj, "txtVendor");
      let txtVendorNameValue = getValue(thisObj, "txtVendorName");
      let ddPeanutTypeValue = getValue(thisObj, "ddPeanutType");
      let ddSegTypeValue = getValue(thisObj, "ddSegType");
      let ddLoanRepayMethodValue = getValue(thisObj, "ddLoanRepayMethod");
      let ddPeanutVarietyValue = getValue(thisObj, "ddPeanutVariety");
      let ddOleicTypeValue = getValue(thisObj, "ddOleicType");
      let chkboxGainOfferValue = getValue(thisObj, "chkboxGainOffer");                    //chkMktGain
      let chkboxRebateOfferValue = getValue(thisObj, "chkboxRebateOffer");                //chkMktRebate
      let chkboxOrganicIndValue = getValue(thisObj, "chkboxOrganicInd");
      let txtMarketFloorValue = getValue(thisObj, "txtMarketFloor");
      let txtMarketCeilingValue = getValue(thisObj, "txtMarketCeiling");
      let txtRebaseRateValue = getValue(thisObj, "txtRebaseRate");                         //txtRebateRate
      let txtGainSharePctValue = getValue(thisObj, "txtGainSharePct");                    //txtMktGainShare
      let txtEndMarketPeriodValue = getValue(thisObj, "txtEndMarketPeriod");              //txtMktWkPriorMat
      let txtTotalValue = getValue(thisObj, "txtTotal") == "" ? 0 : getValue(thisObj, "txtTotal");                                  //lblLockInTotal
      let txtOptionPriceCapValue = getValue(thisObj, "txtOptionPriceCap")                 //txtMaxoptionPrice
      let txtPricePerTonValue = getValue(thisObj, "txtPricePerTon");
      let radioFloorControlsPricedValue = getValue(thisObj, "radioFloorControlsPriced");  //optBeforeEndDate
      let radioPayRebateValue = getValue(thisObj, "radioPayRebate");
      let txtPriceMatchEndDateValue = getValue(thisObj, "txtPriceMatchEndDate");          //txtPriceMatchEndDate
      let txtTonsAllowedValue = getValue(thisObj, "txtTonsAllowed");                      //txtTonsAllocated
      let txtTonsAssignedValue = getValue(thisObj, "txtTonsAssigned");
      let txtTonsContractedValue = getValue(thisObj, "txtTonsContracted");
      let txtDescriptionValue = getValue(thisObj, "txtDescription");
      let txtContractValue = getValue(thisObj, "txtContract");

      if (ddPricingTypeValue === "" || ddPricingTypeValue === undefined) {
        showMessage(thisObj, "Must select a Pricing Type!");
        return false;
      }

      // if(){
      //   showMessage(thisObj , "");
      //   //document.getElementById("").focus();
      //   return false;
      // }

      if (chkboxSpecifyContractValue === false && chkboxSpecifyVendorValue === false && (ddAreaValue === "" || ddAreaValue === undefined)) {
        showMessage(thisObj, "Must select an Area!");
        //document.getElementById("").focus();
        return false;
      }

      if (chkboxSpecifyContractValue === true && (txtContractValue === "" || txtContractValue == undefined)) {
        showMessage(thisObj, "Must enter a Contract Number!");
        //document.getElementById("").focus();
        return false;
      }

      if (chkboxSpecifyVendorValue === true && (txtVendorValue === "" || txtVendorValue == undefined)) {
        showMessage(thisObj, "Must enter a Vendor Number!");
        //document.getElementById("").focus();
        return false;
      }

      if ((txtVendorValue !== "" && txtVendorValue !== undefined) && (txtVendorNameValue == "" || txtVendorNameValue == undefined)) {
        showMessage(thisObj, "Vendor Number not found!");
        setValue(thisObj, "txtContract", "");
        //document.getElementById("").focus();
        return false;
      }

      if (chkboxSpecifyContractValue === false && (ddPeanutTypeValue == "" || ddPeanutTypeValue == undefined)) {
        showMessage(thisObj, "Must select a Peanut Type!");
        //document.getElementById("").focus();
        return false;
      }

      if (chkboxSpecifyContractValue === false && ddPricingTypeValue == "FLEX MKT") {
        let response = await ContractManagementService.RetrievePeanutTypeControls(ddPeanutTypeValue);
        if (response[0].flexMktAllow !== undefined && response[0].flexMktAllow !== "Y") {
          showMessage(thisObj, "Flex Market not allowed for this Peanut Type!");
          //document.getElementById("").focus();
          return false;
        }
      }

      if (chkboxSpecifyContractValue === false && (ddSegTypeValue == "" || ddSegTypeValue == undefined)) {
        showMessage(thisObj, "Must select a Seg Type!");
        //document.getElementById("").focus();
        return false;
      }

      if (ddPricingTypeValue == "FLEX MKT") {
        if (!chkboxRebateOfferValue && !chkboxGainOfferValue) {
          showMessage(thisObj, "Flex Market Pricings must have either a Rebate Offer and/or a Market Gain Offer!");
          //document.getElementById("").focus();
          return false;
        }

        if (chkboxRebateOfferValue && (txtMarketFloorValue == "" || txtMarketFloorValue == undefined)) {
          showMessage(thisObj, "Flex Market Pricing with a Rebate Offer must have a Market Floor Price!");
          //document.getElementById("").focus();
          return false;
        }

        if ((txtMarketFloorValue !== undefined && txtMarketFloorValue !== "") && (txtMarketCeilingValue !== undefined && txtMarketCeilingValue !== "") && commaRemover(txtMarketFloorValue) > commaRemover(txtMarketCeilingValue)) {
          showMessage(thisObj, "The Market Ceiling Price can not be less than the Market Floor Price!");
          //document.getElementById("").focus();
          return false;
        }

        if (chkboxRebateOfferValue && (txtRebaseRateValue == undefined || txtRebaseRateValue == "")) {
          showMessage(thisObj, "Flex Market Pricing with a Rebate Offer must have a Rebate Rate per ton!");
          //document.getElementById("").focus();
          return false;
        }

        if (chkboxGainOfferValue && (txtGainSharePctValue == undefined || txtGainSharePctValue == "")) {
          showMessage(thisObj, "Flex Market Pricing with a Market Gain Offer must have a Market Loan Gain Share %!");
          //document.getElementById("").focus();
          return false;
        }

        if ((txtEndMarketPeriodValue == undefined || txtEndMarketPeriodValue == "") && (txtTotalValue == undefined || txtTotalValue == 0 || txtTotalValue == "0")) {
          showMessage(thisObj, "Flex Market Pricing must have either a value for Market Weeks Prior to Maturity or Pricing Lock-In Dates!");
          //document.getElementById("").focus();
          return false;
        }

        if ((txtEndMarketPeriodValue !== undefined && txtEndMarketPeriodValue !== "") && (txtTotalValue !== undefined && txtTotalValue !== 0 && txtTotalValue !== "0")) {
          showMessage(thisObj, "Flex Market Pricing can't have both a value for Market Weeks Prior to Maturity and Pricing Lock-In Dates!");
          //document.getElementById("").focus();
          return false;
        }

        if ((txtEndMarketPeriodValue !== undefined && txtEndMarketPeriodValue !== "") && (isNaN(txtEndMarketPeriodValue))) {
          showMessage(thisObj, "Flex Market Pricing must have a value for Market Weeks Prior to Maturity!");
          //document.getElementById("").focus();
          return false;
        }

        if (!chkboxMultiplePricingsValue && (txtTotalValue !== undefined && txtTotalValue !== 0 && txtTotalValue !== "0")) {
          showMessage(thisObj, "Must allow Multiple Pricings when using Flex Market Pricing Lock-In dates!");
          //document.getElementById("").focus();
          return false;
        }

        if (txtTotalValue !== undefined && (txtTotalValue !== 100 && txtTotalValue !== '100')) {
          showMessage(thisObj, "Flex Market Pricing Lock-In dates must total to 100%!");
          //document.getElementById("").focus();
          return false;
        }


      }

      if (ddPricingTypeValue == "FLOOR") {
        if (txtOptionPriceCapValue !== undefined && txtOptionPriceCapValue !== "" && (commaRemover(txtOptionPriceCapValue) <= commaRemover(txtPricePerTonValue))) {
          showMessage(thisObj, "Option Price Cap must be greater than $" + txtOptionPriceCapValue);
          //document.getElementById("").focus();
          return false;
        }

        if (radioFloorControlsPricedValue == "1" && (txtPriceMatchEndDateValue == undefined || txtPriceMatchEndDateValue == "")) {
          showMessage(thisObj, "Floor Pricings must have a valid Price Match End Date!");
          //document.getElementById("").focus();
          return false;
        }

        if (txtPriceMatchEndDateValue !== undefined && txtPriceMatchEndDateValue !== "" && !moment(txtPriceMatchEndDateValue, "MM/DD/YYYY").isValid()) {
          showMessage(thisObj, "Price Match End Date must be a valid date in MM/DD/YYYY format.");
          //document.getElementById("").focus();
          return false;
        }
      }

      if (commaRemover(txtTonsAllowedValue) < commaRemover(txtTonsAssignedValue)) {
        showMessage(thisObj, "Tons Allocated can not be less that the total Tons Assigned to the Collection Points!");
        //document.getElementById("").focus();
        return false;
      }

      if (commaRemover(txtTonsAllowedValue) < commaRemover(txtTonsContractedValue)) {
        showMessage(thisObj, "Tons Allocated can not be less that the total Tons Contracted!");
        //document.getElementById("").focus();
        return false;
      }

      if (ddPricingTypeValue == "FLEX MKT" && !gridValues) {
        showMessage(thisObj, "Flex Market Table data is not valid, please change and try again");
        return false;
      }

      return true;

    } catch (error) {
      errorHandler(error, 'form validation');
      return false;
    }
  }

  //change events

  const onddAreaChange = () => {
    try {
      let found_new_Varieties = bFillVarietyList()
      if (found_new_Varieties) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      errorHandler(error, "area change")
    }
  }
  thisObj.onddAreaChange = onddAreaChange;

  const onddPeanutTypeChange = () => {
    try {
      setLoading(true);
      let found_new_Varieties = bFillVarietyList()

      setValue(thisObj, "ddSegType", "")
      setValue(thisObj, "ddOleicType", "")

      setValue(thisObj, "chkboxOrganicInd", false)
      // if (getValue(thisObj, "ddPeanutType") === "SP" || getValue(thisObj, "ddPeanutType") === "VL") {
      show(thisObj, "chkboxOrganicInd")
      // } else {
      //   hide(thisObj, "chkboxOrganicInd")
      // }

      if (found_new_Varieties) {
        setLoading(false);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    } catch (error) {
      setLoading(false);
      errorHandler(error, 'peanut type change')
      return false;
    }
  }
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange;

  const onddPricingTypeChange = () => {
    try {
      setValue(thisObj, "chkboxRebateOffer", false)
      setValue(thisObj, "chkboxGainOffer", false)
      setValue(thisObj, "txtMarketFloor", "")
      setValue(thisObj, "txtMarketCeiling", "")
      setValue(thisObj, "txtRebaseRate", "")
      setValue(thisObj, "txtGainSharePct", "")
      setValue(thisObj, "txtEndMarketPeriod", "");

      hideShowFor_ddPricingType();

      return true;
    } catch (error) {
      errorHandler(error, 'price type change')
      return false;
    }
  }
  thisObj.onddPricingTypeChange = onddPricingTypeChange;

  const onchkboxGainOfferChange = () => {    //checkbox inside flexMarket group
    try {
      setValue(thisObj, "txtGainSharePct", "");
      hideshowFor_chkboxGainOffer();
      return true;
    } catch (error) {
      errorHandler(error, 'market load gain offer checkBox')
      return false;
    }
  }
  thisObj.onchkboxGainOfferChange = onchkboxGainOfferChange;

  const onchkboxRebateOfferChange = () => {  //checkbox inside flexMarket group
    try {
      setValue(thisObj, "txtMarketFloor", "");
      setValue(thisObj, "txtMarketCeiling", "");
      setValue(thisObj, "txtRebaseRate", "");
      hideshowFor_chkboxRebateOffer();
      return true;
    } catch (error) {
      errorHandler(error, 'market load gain offer checkBox')
      return false;
    }
  }
  thisObj.onchkboxRebateOfferChange = onchkboxRebateOfferChange;

  const onchkboxSpecifyContractChange = () => {  //first 2 checkboxes on page
    try {

      setValue(thisObj, "chkboxSpecifyVendor", false)
      setValue(thisObj, "ddArea", "");
      setValue(thisObj, "txtContract", "")
      setValue(thisObj, "txtVendor", "")
      setValue(thisObj, "txtVendorName", "")
      setValue(thisObj, "ddPeanutType", "")
      setValue(thisObj, "ddPeanutVariety", "")
      setValue(thisObj, "ddOleicType", "")
      setValue(thisObj, "ddSegType", "");

      hideShowFor_chkboxSpecifyContract();


    } catch (error) {
      errorHandler(error, 'Contract control checkBox')
    }
  }
  thisObj.onchkboxSpecifyContractChange = onchkboxSpecifyContractChange;

  const onchkboxSpecifyVendorChange = () => {   //first 2 checkboxes on page
    try {

      setValue(thisObj, "chkboxSpecifyContract", false);
      setValue(thisObj, "ddArea", "");
      setValue(thisObj, "txtContract", "")
      setValue(thisObj, "txtVendor", "")
      setValue(thisObj, "txtVendorName", "")

      hideShowFor_chkboxSpecifyVendor();

    } catch (error) {
      errorHandler(error, 'Vendor control checkBox')
    }
  }
  thisObj.onchkboxSpecifyVendorChange = onchkboxSpecifyVendorChange;

  //textbox validations
  const ontxtContractChange = () => {
    try {
      setValue(thisObj, "txtContract", returnNumericValues(thisObj.values.txtContract));
    } catch (error) {
      errorHandler(error, 'contract textbox change')
    }
  }
  thisObj.ontxtContractChange = ontxtContractChange;

  const ontxtDescriptionChange = () => {
    let data = getValue(thisObj, "txtDescription");
    let res = '';
    for (var i = 0; i < data.length; i++) {
      if (data[i].charCodeAt(0) !== 38 && data[i].charCodeAt(0) !== 39) {
        res += data[i]
      }
    }
    setValue(thisObj, "txtDescription", res);
  }
  thisObj.ontxtDescriptionChange = ontxtDescriptionChange;

  const ontxtEndMarketPeriodChange = () => {
    try {
      setValue(thisObj, "txtEndMarketPeriod", returnNumericValues(thisObj.values.txtEndMarketPeriod));
    } catch (error) {
      errorHandler(error, 'End market period value')
    }
  }
  thisObj.ontxtEndMarketPeriodChange = ontxtEndMarketPeriodChange;

  // blur events
  const ontxtVendorBlur = async (value) => {
    try {
      let input, checkboxValue;
      if (value !== undefined && value !== null && value.type == undefined) {
        input = value;
        checkboxValue = true;
      } else {
        input = getValue(thisObj, "txtVendor")
        checkboxValue = getValue(thisObj, "chkboxSpecifyVendor");
      }
      if (input !== undefined && input !== null && input !== '') {
        if (input.length === 6) {
          let data = await ContractManagementService.RetrieveVendorByNumber(input)
          if (data !== undefined && data !== null && data.length !== 0) {
            show(thisObj, "txtVendorName")
            setValue(thisObj, "txtVendorName", data[0].name)
          }
        } else {
          setValue(thisObj, "txtVendorName", "")
          hide(thisObj, "txtVendorName", false)
          //document.getElementById('txtVendorName').style.visibility = "hidden";
        }
      } else {
        setValue(thisObj, "txtVendorName", "")
        hide(thisObj, "txtVendorName", false)
        //document.getElementById('txtVendorName').style.visibility = "hidden";
      }
      // if (!checkboxValue) {
      //   hide(thisObj, "txtVendorName")
      // }
    } catch (error) {
      errorHandler(error, 'on vendor number blur')
    }
  }
  thisObj.ontxtVendorBlur = ontxtVendorBlur;

  const ontxtOptionPriceCapBlur = () => {
    try {
      let value = getValue(thisObj, "txtOptionPriceCap");

      if (value == undefined || value == '') {
        return true;
      } else {
        value = value.replace(/,/g, '')
      }

      if (isNaN(value)) {
        showMessage(thisObj, "Option Price Cap is not numeric!");
        document.getElementById("txtOptionPriceCap").focus();
        return true
      }
      if (value.includes("-")) {
        showMessage(thisObj, "Option Price Cap can not be a negative number!");
        document.getElementById("txtOptionPriceCap").focus();
        value = value.replace(/-/g, '');
      }

      value = Number(value).toFixed(2);

      if (value.length == 8) {
        value = value.slice(0, -1)
      } else if (value.length == 9) {
        value = value.slice(0, -2)
      } else if (value.length == 10) {
        value = value.slice(0,-3)
      }
      setValue(thisObj, "txtOptionPriceCap", value)
      return true;

    } catch (error) {
      errorHandler(error, 'OptionPrice textbox blur event')
      return false;
    }
  }
  thisObj.ontxtOptionPriceCapBlur = ontxtOptionPriceCapBlur;

  const ontxtPricePerTonBlur = () => {
    try {
      let value = getValue(thisObj, "txtPricePerTon");

      if (value == undefined || value == '') {
        return true;
      } else {
        value = value.replace(/,/g, '')
      }

      if (isNaN(value)) {
        showMessage(thisObj, "Price per Ton is not numeric!");
        document.getElementById("txtPricePerTon").focus();
        return true
      }
      if (value.includes("-")) {
        showMessage(thisObj, "Price per Ton can not be a negative number!");
        document.getElementById("txtPricePerTon").focus();
        value = value.replace(/-/g, '');
      }
      
      value = Number(value).toFixed(2);

      if (value.length == 8) {
        value = value.slice(0, -1)
      } else if (value.length == 9) {
        value = value.slice(0, -2)
      } else if (value.length == 10) {
        value = value.slice(0,-3)
      }
      setValue(thisObj, "txtPricePerTon", value)
      return true;

    } catch (error) {
      errorHandler(error, 'Price per ton textbox blur event')
      return false;
    }
  }
  thisObj.ontxtPricePerTonBlur = ontxtPricePerTonBlur;

  const ontxtMarketCeilingBlur = () => {
    try {
      let value = getValue(thisObj, "txtMarketCeiling");

      if (value == undefined || value == '') {
        return true;
      } else {
        value = value.replace(/,/g, '')
      }

      if (isNaN(value)) {
        showMessage(thisObj, "Market Ceiling Price is not numeric!");
        document.getElementById("txtMarketCeiling").focus();
        return true
      }
      if (value.includes("-")) {
        showMessage(thisObj, "Market Ceiling Price can not be a negative number!");
        document.getElementById("txtMarketCeiling").focus();
        value = value.replace(/-/g, '');
        setValue(thisObj, "txtMarketCeiling", value)
        return true
      }
      if (value > 1) {
        showMessage(thisObj, "Market Ceiling Price to high! Should be cents per Lbs.");
        document.getElementById("txtMarketCeiling").focus();
        return true
      }
      setValue(thisObj, "txtMarketCeiling", FormatNumber(value, 5))
      return true;

    } catch (error) {
      errorHandler(error, 'Ceiling Price textbox blur event')
      return false;
    }
  }
  thisObj.ontxtMarketCeilingBlur = ontxtMarketCeilingBlur;

  const ontxtMarketFloorBlur = () => {
    try {
      let value = getValue(thisObj, "txtMarketFloor");

      if (value == undefined || value == '') {
        return true;
      } else {
        value = value.replace(/,/g, '')
      }

      if (isNaN(value)) {
        showMessage(thisObj, "Market Floor Price is not numeric!");
        document.getElementById("txtMarketFloor").focus();
        return true
      }
      if (value.includes("-")) {
        showMessage(thisObj, "Market Floor Price can not be a negative number!");
        document.getElementById("txtMarketFloor").focus();
        value = value.replace(/-/g, '');
        setValue(thisObj, "txtMarketFloor", value)
        return true
      }
      if (value > 1) {
        showMessage(thisObj, "Market Floor Price to high! Should be cents per Lbs.");
        document.getElementById("txtMarketFloor").focus();
        return true
      }
      setValue(thisObj, "txtMarketFloor", FormatNumber(value, 5))
      return true;

    } catch (error) {
      errorHandler(error, 'Floor Price textbox blur event')
      return false;
    }
  }
  thisObj.ontxtMarketFloorBlur = ontxtMarketFloorBlur;

  const ontxtRebaseRateBlur = () => {
    try {
      let value = getValue(thisObj, "txtRebaseRate");

      if (value == undefined || value == '') {
        return true;
      } else {
        value = value.replace(/,/g, '')
      }

      if (isNaN(value)) {
        showMessage(thisObj, "Rebate rate per Ton is not numeric!");
        document.getElementById("txtRebaseRate").focus();
        return true
      }
      if (value.includes("-")) {
        showMessage(thisObj, "Rebate rate per Ton can not be a negative number!");
        document.getElementById("txtRebaseRate").focus();
        value = value.replace(/-/g, '');
        value = Number(value).toFixed(2);

      if (value.length == 7) {
        value = value.slice(0, -1)
      } else if (value.length == 8) {
        value = value.slice(0, -2)
      } else if (value.length == 9) {
        value = value.slice(0,-3)
      }
        setValue(thisObj, "txtRebaseRate", value)
        return true
      }
      if (value == 0) {
        showMessage(thisObj, "Rebate rate per Ton can not zero!");
        document.getElementById("txtRebaseRate").focus();
        return true
      }
      value = Number(value).toFixed(2);
      if (value.length == 7) {
        value = value.slice(0, -1)
      } else if (value.length == 8) {
        value = value.slice(0, -2)
      } else if (value.length == 9) {
        value = value.slice(0,-3)
      }
      setValue(thisObj, "txtRebaseRate", value)
      return true;

    } catch (error) {
      errorHandler(error, 'Rebate rate textbox blur event')
      return false;
    }
  }
  thisObj.ontxtRebaseRateBlur = ontxtRebaseRateBlur;

  const ontxtGainSharePctBlur = () => {
    try {
      let value = getValue(thisObj, "txtGainSharePct");

      if (value == undefined || value == '') {
        return true;
      } else {
        value = value.replace(/,%/g, '')
      }

      if (isNaN(value)) {
        showMessage(thisObj, "Market Load Gain Share % is not numeric!");
        document.getElementById("txtGainSharePct").focus();
        return true
      }
      if (value.includes("-")) {
        showMessage(thisObj, "Market Load Gain Share % can not be a negative number!");
        document.getElementById("txtGainSharePct").focus();
        value = value.replace(/-/g, '');
        setValue(thisObj, "txtGainSharePct", value)
        return true
      }
      if (value == 0) {
        showMessage(thisObj, "Market Load Gain Share % can not be zero!");
        document.getElementById("txtGainSharePct").focus();
        return true
      }
      if (value > 100) {
        showMessage(thisObj, "Market Load Gain Share % can not be greater that 100%!");
        document.getElementById("txtGainSharePct").focus();
        return true
      }
      setValue(thisObj, "txtGainSharePct", FormatNumber(value, 2))
      return true;

    } catch (error) {
      errorHandler(error, 'Market Load Gain Share % textbox blur event')
      return false;
    }
  }
  thisObj.ontxtGainSharePctBlur = ontxtGainSharePctBlur;

  const ontxtTonsAllowedBlur = () => {
    try {
      let value = getValue(thisObj, "txtTonsAllowed");

      if (value == undefined || value == '') {
        return true;
      } else {
        value = value.replace(/,/g, '');
      }

      if (isNaN(value)) {
        showMessage(thisObj, "Tons Allowed is not numeric!");
        document.getElementById("txtTonsAllowed").focus();
        return true
      }
      if (value.includes("-")) {
        showMessage(thisObj, "Tons Allowed can not be a negative number!");
        document.getElementById("txtTonsAllowed").focus();
        value = value.replace(/-/g, '');
        setValue(thisObj, "txtTonsAllowed", value)
        return true
      }
      setValue(thisObj, "txtTonsAllowed", FormatNumber(value, 4))
      return true;

    } catch (error) {
      errorHandler(error, 'Tons Allowed textbox blur event')
      return false;
    }
  }
  thisObj.ontxtTonsAllowedBlur = ontxtTonsAllowedBlur;


  //click events

  const onbtnAddClick = async () => {
    try {
      setLoading(true);

      let valid = await bFormValid();

      if (!valid) {
        setLoading(false);
        return false;
      }

      let tag, record_key;
      let recordKey = getData(thisObj, "ContractLimitSearchData")
      if (recordKey !== null && recordKey.editMode) {
        record_key = recordKey.recordKey;
      }

      //collecting values from page
      let pricingTypeValue = getValue(thisObj, "ddPricingType");
      let chkboxSpecifyContractValue = getValue(thisObj, "chkboxSpecifyContract");
      let chkboxSpecifyVendorValue = getValue(thisObj, "chkboxSpecifyVendor");
      let chkboxMultiplePricingsValue = getValue(thisObj, "chkboxMultiplePricings");
      let ddAreaValue = getValue(thisObj, "ddArea");
      let txtVendorValue = getValue(thisObj, "txtVendor") == undefined ? "" : getValue(thisObj, "txtVendor");
      let txtVendorNameValue = getValue(thisObj, "txtVendorName") == undefined ? "" : getValue(thisObj, "txtVendorName");
      let ddPeanutTypeValue = getValue(thisObj, "ddPeanutType");
      let ddSegTypeValue = getValue(thisObj, "ddSegType");
      let ddLoanRepayMethodValue = getValue(thisObj, "ddLoanRepayMethod");
      let ddPeanutVarietyValue = getValue(thisObj, "ddPeanutVariety");
      let ddOleicTypeValue = getValue(thisObj, "ddOleicType");
      let chkboxGainOfferValue = getValue(thisObj, "chkboxGainOffer");                    //chkMktGain
      let chkboxRebateOfferValue = getValue(thisObj, "chkboxRebateOffer");                //chkMktRebate
      let chkboxOrganicIndValue = getValue(thisObj, "chkboxOrganicInd");
      let txtMarketFloorValue = getValue(thisObj, "txtMarketFloor") == undefined ? "" : getValue(thisObj, "txtMarketFloor");
      let txtMarketCeilingValue = getValue(thisObj, "txtMarketCeiling") == undefined ? "" : getValue(thisObj, "txtMarketCeiling");
      let txtRebaseRateValue = getValue(thisObj, "txtRebaseRate") == undefined ? "" : getValue(thisObj, "txtRebaseRate");                         //txtRebateRate
      let txtGainSharePctValue = getValue(thisObj, "txtGainSharePct") == undefined ? "" :  getValue(thisObj, "txtGainSharePct");                    //txtMktGainShare
      let txtEndMarketPeriodValue = getValue(thisObj, "txtEndMarketPeriod");              //txtMktWkPriorMat
      let txtTotalValue = getValue(thisObj, "txtTotal") == undefined ? "" : getValue(thisObj, "txtTotal");                                  //lblLockInTotal
      let txtOptionPriceCapValue = getValue(thisObj, "txtOptionPriceCap")                 //txtMaxoptionPrice
      let txtPricePerTonValue = getValue(thisObj, "txtPricePerTon");
      let radioFloorControlsPricedValue = getValue(thisObj, "radioFloorControlsPriced");  //optBeforeEndDate
      let radioPayRebateValue = getValue(thisObj, "radioPayRebate");
      let txtPriceMatchEndDateValue = getValue(thisObj, "txtPriceMatchEndDate");          //txtPriceMatchEndDate
      let txtTonsAllowedValue = getValue(thisObj, "txtTonsAllowed");                      //txtTonsAllocated
      let txtTonsAssignedValue = getValue(thisObj, "txtTonsAssigned");
      let txtTonsContractedValue = getValue(thisObj, "txtTonsContracted");
      let txtDescriptionValue = getValue(thisObj, "txtDescription");
      let txtContractValue = getValue(thisObj, "txtContract") == undefined ? "" : getValue(thisObj, "txtContract");

      let buttonName = document.getElementById("btnAdd").innerText;

      if (buttonName === "Add") {
        tag = 'ADD'
      } else {
        let pageConstants = getData(thisObj, "ContractlimitArea_pageConstants")
        if ((commaRemover(FormatNumber(txtTonsAssignedValue)) != 0 || commaRemover(FormatNumber(txtTonsContractedValue)) != 0)
            &&
            (
              pageConstants.mStrDesc != txtDescriptionValue ||
              FormatNumber(pageConstants.mStrPricePerTon) != FormatNumber(txtPricePerTonValue) ||
              FormatNumber(pageConstants.mStrMaxOptionPrice) != FormatNumber(txtOptionPriceCapValue) ||
              FormatNumber(pageConstants.mbBeforeEndDate) != (radioFloorControlsPricedValue == '1') ||
              FormatNumber(pageConstants.mbBeforeDelivery) != (radioFloorControlsPricedValue == '2') ||
              (pageConstants.mStrPriceMatchEndDate == "" || txtPriceMatchEndDateValue == "" ? pageConstants.mStrPriceMatchEndDate != txtPriceMatchEndDateValue :
              pageConstants.mStrPriceMatchEndDate.split(" ")[0] != moment(txtPriceMatchEndDateValue).format("M/D/YYYY")  //this provision is made to handle moment comparision
              ) ||
              FormatNumber(pageConstants.mStrMktFloor) != FormatNumber(txtMarketFloorValue) ||
              FormatNumber(pageConstants.mStrMktCeiling) != FormatNumber(txtMarketCeilingValue) ||
              FormatNumber(pageConstants.mStrRebateRate) != FormatNumber(txtRebaseRateValue) ||
              FormatNumber(pageConstants.mStrMktGainShare) != FormatNumber(txtGainSharePctValue) ||
              pageConstants.mStrLoanRepayMethod != ddLoanRepayMethodValue ||
              FormatNumber(pageConstants.mStrMktWkPriorMat) != FormatNumber(txtEndMarketPeriodValue) ||
              pageConstants.mbMultiPricing != chkboxMultiplePricingsValue ||
              pageConstants.mbEndMarket != (radioPayRebateValue == "1") ||
              pageConstants.mbElection != (radioPayRebateValue == "2")
            )
            || (pageConstants.mbLockInDateChg )) 
          {
            if (commaRemover(FormatNumber(txtTonsContractedValue)) != 0) {
              let boolean = window.confirm("The change you are requesting will caues Pricing information \n to be changed on Contracts already issued in the the system!!! \n Are you sure that you want to continue with this mass update?");
              if (!boolean) {
                setLoading(false);
                return false;
              }
            }
            tag = 'MASUPD'
          } else {
            tag = 'UPDATE'
          }
      }

      let data = {};
      data.user_id = (sessionStorage.getItem('userid') || '');
      data.parent_key = record_key;
      data.pricing_type = pricingTypeValue;
      data.pricing_type_desc = txtDescriptionValue;
      data.price_match_end_date = (txtPriceMatchEndDateValue !== undefined && txtPriceMatchEndDateValue !== "") ? (new Date(new Date(txtPriceMatchEndDateValue).getTime() - (new Date().getTimezoneOffset() * 60000))).toISOString()
      : "";

      if (chkboxSpecifyContractValue) {
        data.control_level = "SC";
        data.contract_num = txtContractValue;
      } else if (chkboxSpecifyVendorValue) {
        data.control_level = "SV";
        data.vendor_num = txtVendorValue;
      } else {
        data.control_level = "AR";
        data.area_id = ddAreaValue;
      }

      data.pnut_type_id = ddPeanutTypeValue;
      data.pnut_variety_id = ddPeanutVarietyValue;
      data.seg_type = ddSegTypeValue;
      data.oleic_ind = ddOleicTypeValue;

      if (chkboxOrganicIndValue) { // removed (ddPeanutTypeValue === "SP" || ddPeanutTypeValue == "VL") Condition as part of UAT Bug_546538 as organic checkbox is mandory shown to all peanut types now.
        data.organic_ind = "Y";
      } else {
        chkboxOrganicIndValue ? data.organic_ind = "" : data.organic_ind = "N";
      }

      data.price_per_ton = txtPricePerTonValue !== undefined ? txtPricePerTonValue.replace(/,/g, "") : "0.00";
      if (data.price_per_ton == "") {
        data.price_per_ton = "0.00"
      }
      data.max_option_price = txtOptionPriceCapValue !== undefined ? txtOptionPriceCapValue.replace(/,/g, "") : "";

      if (pricingTypeValue === "FLOOR") {
        if (radioFloorControlsPricedValue === "1") {
          data.pay_rebate_at = "PRICING";
        }
        if (radioFloorControlsPricedValue === "2") {
          data.pay_rebate_at = "DELIVERY";
        }
      }

      if (pricingTypeValue === "FLEX MKT") {
        if (chkboxRebateOfferValue) {
          data.market_floor = txtMarketFloorValue !== undefined ? txtMarketFloorValue.replace(/,/g, "") : "";
          data.market_ceiling = txtMarketCeilingValue !== undefined ? txtMarketCeilingValue.replace(/,/g, "") : "";
          data.rebate_rate = txtRebaseRateValue !== undefined ? txtRebaseRateValue.replace(/,/g, "") : "";
        }
        if (chkboxGainOfferValue) {
          data.mkt_gain_share = txtGainSharePctValue !== undefined ? txtGainSharePctValue.replace(/,/g, "") : "";
        }

        data.loan_repay_ind = ddLoanRepayMethodValue;
        data.mkt_wk_prior_mat = txtEndMarketPeriodValue !== undefined ? txtEndMarketPeriodValue.replace(/,/g, "") : "";

        if (chkboxMultiplePricingsValue) {
          data.multi_pricing_ind = "Y";
        } else {
          data.multi_pricing_ind = "N";
        }

        if (radioPayRebateValue === "1") {
          data.pay_rebate_at = "END_PERIOD";
        }
        if (radioPayRebateValue === "2") {
          data.pay_rebate_at = "PRICING";
        }
      }

      data.tons_allocated = txtTonsAllowedValue !== undefined ? txtTonsAllowedValue.replace(/,/g, "") : "0.0000";
      if (data.tons_allocated == "") {
        data.tons_allocated = "0.0000"
      }
      let gridData = getValue(thisObj, "gridLockInDates");
      //let gridData = grid_data;
      data.lock_in_cnt = gridData.length;  //check after all grid code

      data.lock_in_data = [];
      if (gridData !== undefined && gridData !== null && gridData !== []) {
        for (let i = 0; i < gridData.length; i++) {
          let tempObj = {};
          tempObj.lock_in_key = gridData[i].txtcol1;
          tempObj.lock_in_date = gridData[i].txtcolLockInDate;
          tempObj.lock_in_pct = gridData[i].txtcolMinPctTotalTons;
          data.lock_in_data.push(tempObj);
        }
      }

      let response = await SystemMaintenanceMasterManagementService.UpdateContractPriceLimit(tag, record_key, data);
      if (response !== undefined && response !== null && response.length !== 0) {

        if (response.status == 200) {
          let data = {
            "recordKey": response.result,
            "editMode": true
          }
          setData(thisObj, "ContractLimitSearchData", data);
          setLoading(false);
          showMessage(thisObj, response.message, true);
          bFillForm();
          setData(thisObj, "closingOfContractLimitArea", true);
        } else {
          showMessage(thisObj, (response.message || response.title));
          setLoading(false);
        }

      }
    } catch (error) {
      errorHandler(error, 'Add Button Click')
      return false;
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick;


  const onbtnImage1Click = () => {
    try {
      goTo(thisObj, "SystemMaintenanceMasterManagement#ContractLimitCollPt#DEFAULT#true#Click");  //uncomment this when code is done
      //bFillForm()  //might throw error , check and if required write on child page closing
    } catch (error) {
      errorHandler(error, 'Image button click')
    }
  }
  thisObj.onbtnImage1Click = onbtnImage1Click;

  const onbtnVendorClick = () => {
    try {
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click")
    } catch (error) {
      errorHandler(error, 'vendor button click')
    }
  }
  thisObj.onbtnVendorClick = onbtnVendorClick;

  const onbtnExitClick = () => {
    try {
      setData(thisObj, "closingOfContractLimitAreaExit", true);
      document.getElementById("SystemMaintenanceMasterManagement_ContractLimitAreaPopUp").childNodes[0].click()
    } catch (error) {
      errorHandler(error, 'Exit button')
    }
  }
  thisObj.onbtnExitClick = onbtnExitClick;

  //extra functions

  function hideShowFor_ddPricingType(value, GainOfferValue, RebateOfferValue) {
    document.getElementsByClassName("grpbxGainOffer")[0].style.visibility = "hidden";
    document.getElementsByClassName("grpbxRebateOffer")[0].style.visibility = "hidden";

    let dropdownValue
    if (value !== undefined && value !== null) {
      dropdownValue = value
    } else {
      dropdownValue = getValue(thisObj, "ddPricingType");
    }

    if (dropdownValue === "FLOOR") {
      show(thisObj, "grpbxFloorControls")
    } else {
      hide(thisObj, "grpbxFloorControls")
    };

    if (dropdownValue === "FLEX MKT") {
      show(thisObj, "grpbxFlexMarketControls")
      document.getElementsByClassName("grpbxGainOffer")[0].style.visibility = "hidden";
      document.getElementsByClassName("grpbxRebateOffer")[0].style.visibility = "hidden";

      let chkboxRebateOfferValue, chkboxGainOfferValue;
      if (GainOfferValue !== undefined) {
        chkboxGainOfferValue = GainOfferValue;
      } else {
        chkboxGainOfferValue = getValue(thisObj, "chkboxGainOffer")
      }

      if (RebateOfferValue !== undefined) {
        chkboxRebateOfferValue = RebateOfferValue;
      } else {
        chkboxRebateOfferValue = getValue(thisObj, "chkboxRebateOffer")
      }


      if (chkboxGainOfferValue) {
        document.getElementsByClassName("grpbxGainOffer")[0].style.visibility = "visible";
      }
      if (chkboxRebateOfferValue) {
        document.getElementsByClassName("grpbxRebateOffer")[0].style.visibility = "visible";
      }
    } else {
      hide(thisObj, "grpbxFlexMarketControls")
    };

  }

  function hideshowFor_chkboxGainOffer(value) {
    let checkboxValue;

    if (value !== undefined && value !== null) {
      checkboxValue = value
    } else {
      checkboxValue = getValue(thisObj, "chkboxGainOffer");
    }

    if (checkboxValue) {
      //show(thisObj, "grpbxGainOffer")
      document.getElementsByClassName("grpbxGainOffer")[0].style.visibility = "visible";
    } else {
      //hide(thisObj, "grpbxGainOffer")
      document.getElementsByClassName("grpbxGainOffer")[0].style.visibility = "hidden";
    }
  }

  function hideshowFor_chkboxRebateOffer(value) {
    let checkboxValue;

    if (value !== undefined && value !== null) {
      checkboxValue = value
    } else {
      checkboxValue = getValue(thisObj, "chkboxRebateOffer");
    }
    if (checkboxValue) {
      //show(thisObj, "grpbxRebateOffer")
      document.getElementsByClassName("grpbxRebateOffer")[0].style.visibility = "visible";
    } else {
      //hide(thisObj, "grpbxRebateOffer")
      document.getElementsByClassName("grpbxRebateOffer")[0].style.visibility = "hidden";
    }
  }

  function hideShowFor_chkboxSpecifyContract(value) {

    hide(thisObj, "txtVendor")
    hide(thisObj, "btnVendor")
    hide(thisObj, "txtVendorName")
    //document.getElementById('txtVendorName').style.visibility = "hidden";

    let input;
    if (value !== undefined && value !== null) {
      input = value
    } else {
      input = getValue(thisObj, "chkboxSpecifyContract");
    }
    if (input === false) {
      show(thisObj, "chkboxSpecifyVendor");
      show(thisObj, "ddArea");
      show(thisObj, "ddPeanutType");
      show(thisObj, "ddPeanutVariety");
      show(thisObj, "ddSegType");
      show(thisObj, "ddOleicType");

      hide(thisObj, "txtContract");
    } else {
      hide(thisObj, "chkboxSpecifyVendor");
      hide(thisObj, "ddArea");
      hide(thisObj, "ddPeanutType");
      hide(thisObj, "ddPeanutVariety");
      hide(thisObj, "ddSegType");
      hide(thisObj, "ddOleicType");

      show(thisObj, "txtContract");
    }
  }

  function hideShowFor_chkboxSpecifyVendor(value) {
    hide(thisObj, "txtContract");
    let input;
    if (value !== undefined && value !== null) {
      input = value
    } else {
      input = getValue(thisObj, "chkboxSpecifyVendor");
    }
    if (input === false) {
      hide(thisObj, "txtVendor")
      hide(thisObj, "txtVendorName")
      //document.getElementById('txtVendorName').style.visibility = "hidden";
      hide(thisObj, "btnVendor")
      //show(thisObj, "chkboxSpecifyContract");
      document.getElementsByClassName("chkboxSpecifyContract")[0].style.visibility = "visible";

      show(thisObj, "ddArea")

    } else {
      show(thisObj, "txtVendor")
      show(thisObj, "txtVendorName")
      show(thisObj, "btnVendor")
      //hide(thisObj, "chkboxSpecifyContract");
      document.getElementsByClassName("chkboxSpecifyContract")[0].style.visibility = "hidden";

      hide(thisObj, "ddArea")
    }
  }

  function calcTotal() {
    try {
      let grid_data = getValue(thisObj, "gridLockInDates");
      if (grid_data !== undefined && grid_data !== [] && grid_data.length !== 0) {
        let newTotal = 0;
        for (let i = 0; i < grid_data.length; i++) {
          newTotal += Number(grid_data[i].txtcolMinPctTotalTons);
          grid_data[i].txtcolSeqNum = i + 1;
        }
        setValue(thisObj, "txtTotal", newTotal);
        setValue(thisObj, "gridLockInDates", grid_data);
        //thisObj.values.txtTotal = newTotal;
        //thisObj.values.gridLockInDates = grid_data;
      } else {
        //thisObj.values.txtTotal = "";
        setValue(thisObj, "txtTotal", "");
      }
    } catch (error) {
      errorHandler(error, "calculating totals")
    }
  }

  //common error handler

  // const ontxtContractChange = () =>{
  //   try {

  //   } catch (error) {
  // 
  //     errorHandler(thisObj,'')
  //   }
  // }
  // thisObj.ontxtContractChange = ontxtContractChange;

  function FormatNumber(value, fraction = 4) {
    let formattedNo;
    if (value != null && value != undefined) {
      if (value.toString().indexOf(",") == -1) {
        formattedNo = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: fraction,
          maximumFractionDigits: fraction,
        }).format(value);
      } else {
        formattedNo = value.toString();
      }
    } else {
      formattedNo = "";
    }
    return formattedNo;
  }

  function commaRemover(value) {
    if (value === undefined || value === null) {
      return 0
    }
    const cleanedValue = value.replace(/,/g, "");
    if (cleanedValue === '') {
      return 0;
    } else {
      return Number(cleanedValue);
    }
  }

  function errorHandler(err, funcName) {
    setLoading(false);
    showMessage(thisObj,
      err instanceof EvalError
        ? err.message
        : 'Something went wrong on ' + funcName + '. Please try again later.'
    )
    //uncomment following line at time of debugging only while pushing code kept this one commented and console.log uncommented to not break page
    //throw Error(err);
  }
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={values => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ContractLimitArea*/}

              {/* END_USER_CODE-USER_BEFORE_ContractLimitArea*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxContractLimitArea*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxContractLimitArea*/}

              <GroupBoxWidget
                conf={state.grpbxContractLimitArea}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_chkboxSpecifyContract*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxSpecifyContract*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxSpecifyContract}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxSpecifyContract*/}

                {/* END_USER_CODE-USER_AFTER_chkboxSpecifyContract*/}

                {/* START_USER_CODE-USER_BEFORE_chkboxSpecifyVendor*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxSpecifyVendor*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxSpecifyVendor}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxSpecifyVendor*/}

                {/* END_USER_CODE-USER_AFTER_chkboxSpecifyVendor*/}
                {/* START_USER_CODE-USER_BEFORE_ddPricingType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPricingType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPricingType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPricingType*/}

                {/* END_USER_CODE-USER_AFTER_ddPricingType*/}
                {/* START_USER_CODE-USER_BEFORE_txtDescription*/}

                {/* END_USER_CODE-USER_BEFORE_txtDescription*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDescription}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDescription*/}

                {/* END_USER_CODE-USER_AFTER_txtDescription*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendorName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }></TextBoxWidget>
                {/* START_USER_CODE-USER_BEFORE_ddArea*/}

                {/* END_USER_CODE-USER_BEFORE_ddArea*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddArea}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddArea*/}

                {/* END_USER_CODE-USER_AFTER_ddArea*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxOrganicInd*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxOrganicInd*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxOrganicInd}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxOrganicInd*/}

                {/* END_USER_CODE-USER_AFTER_chkboxOrganicInd*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutVariety}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                {/* START_USER_CODE-USER_BEFORE_ddSegType*/}

                {/* END_USER_CODE-USER_BEFORE_ddSegType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSegType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSegType*/}

                {/* END_USER_CODE-USER_AFTER_ddSegType*/}
                {/* START_USER_CODE-USER_BEFORE_ddOleicType*/}

                {/* END_USER_CODE-USER_BEFORE_ddOleicType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleicType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOleicType*/}

                {/* END_USER_CODE-USER_AFTER_ddOleicType*/}
                {/* START_USER_CODE-USER_BEFORE_txtPricePerTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtPricePerTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPricePerTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPricePerTon*/}

                {/* END_USER_CODE-USER_AFTER_txtPricePerTon*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxFloorControls*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFloorControls*/}

                <GroupBoxWidget
                  conf={state.grpbxFloorControls}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblFloorControls*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFloorControls*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFloorControls}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFloorControls*/}

                  {/* END_USER_CODE-USER_AFTER_lblFloorControls*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOptionPriceCap*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOptionPriceCap*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOptionPriceCap}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOptionPriceCap*/}

                  {/* END_USER_CODE-USER_AFTER_txtOptionPriceCap*/}
                  {/* START_USER_CODE-USER_BEFORE_radioFloorControlsPriced*/}

                  {/* END_USER_CODE-USER_BEFORE_radioFloorControlsPriced*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioFloorControlsPriced}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioFloorControlsPriced*/}

                  {/* END_USER_CODE-USER_AFTER_radioFloorControlsPriced*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPriceMatchEndDate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPriceMatchEndDate*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPriceMatchEndDate}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPriceMatchEndDate*/}

                  {/* END_USER_CODE-USER_AFTER_txtPriceMatchEndDate*/}
                  {/* START_USER_CODE-USER_BEFORE_lblDateFormat*/}

                  {/* END_USER_CODE-USER_BEFORE_lblDateFormat*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblDateFormat}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblDateFormat*/}

                  {/* END_USER_CODE-USER_AFTER_lblDateFormat*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFloorControls*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFloorControls*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxFlexMarketControls*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFlexMarketControls*/}

                <GroupBoxWidget
                  conf={state.grpbxFlexMarketControls}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblFloorControls*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFloorControls*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFlexMarketControls}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFloorControls*/}

                  {/* END_USER_CODE-USER_AFTER_lblFloorControls*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxFlexOffers*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxFlexOffers*/}

                  {/* START_USER_CODE-USER_BEFORE_chkboxRebateOffer*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxRebateOffer*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxRebateOffer}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxRebateOffer*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxRebateOffer*/}

                  {/* START_USER_CODE-USER_BEFORE_chkboxGainOffer*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxGainOffer*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxGainOffer}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxGainOffer*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxGainOffer*/}


                  <GroupBoxWidget
                    conf={state.grpbxFlexOffers}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_grpbxRebateOffer*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxRebateOffer*/}

                    <GroupBoxWidget
                      conf={state.grpbxRebateOffer}
                      screenConf={state}
                    >

                      {/* START_USER_CODE-USER_BEFORE_txtMarketFloor*/}

                      {/* END_USER_CODE-USER_BEFORE_txtMarketFloor*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtMarketFloor}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtMarketFloor*/}

                      {/* END_USER_CODE-USER_AFTER_txtMarketFloor*/}
                      {/* START_USER_CODE-USER_BEFORE_txtMarketCeiling*/}

                      {/* END_USER_CODE-USER_BEFORE_txtMarketCeiling*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtMarketCeiling}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtMarketCeiling*/}

                      {/* END_USER_CODE-USER_AFTER_txtMarketCeiling*/}
                      {/* START_USER_CODE-USER_BEFORE_txtRebaseRate*/}

                      {/* END_USER_CODE-USER_BEFORE_txtRebaseRate*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtRebaseRate}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtRebaseRate*/}

                      {/* END_USER_CODE-USER_AFTER_txtRebaseRate*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxRebateOffer*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxRebateOffer*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxGainOffer*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxGainOffer*/}


                    <GroupBoxWidget
                      conf={state.grpbxGainOffer}
                      screenConf={state}
                    >

                      {/* START_USER_CODE-USER_BEFORE_txtGainSharePct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtGainSharePct*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtGainSharePct}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtGainSharePct*/}

                      {/* END_USER_CODE-USER_AFTER_txtGainSharePct*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxGainOffer*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxGainOffer*/}
                    {/* START_USER_CODE-USER_BEFORE_ddLoanRepayMethod*/}

                    {/* END_USER_CODE-USER_BEFORE_ddLoanRepayMethod*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddLoanRepayMethod}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddLoanRepayMethod*/}

                    {/* END_USER_CODE-USER_AFTER_ddLoanRepayMethod*/}
                    {/* START_USER_CODE-USER_BEFORE_txtEndMarketPeriod*/}

                    {/* END_USER_CODE-USER_BEFORE_txtEndMarketPeriod*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtEndMarketPeriod}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtEndMarketPeriod*/}

                    {/* END_USER_CODE-USER_AFTER_txtEndMarketPeriod*/}
                    {/* START_USER_CODE-USER_BEFORE_lblWeeks*/}

                    {/* END_USER_CODE-USER_BEFORE_lblWeeks*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblWeeks}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblWeeks*/}

                    {/* END_USER_CODE-USER_AFTER_lblWeeks*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxMultiplePricings*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxMultiplePricings*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxMultiplePricings}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxMultiplePricings*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxMultiplePricings*/}
                    {/* START_USER_CODE-USER_BEFORE_radioPayRebate*/}

                    {/* END_USER_CODE-USER_BEFORE_radioPayRebate*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioPayRebate}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioPayRebate*/}

                    {/* END_USER_CODE-USER_AFTER_radioPayRebate*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxFlexOffers*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxFlexOffers*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxDates*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxDates*/}

                  <GroupBoxWidget conf={state.grpbxDates} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_lblPrcLockInDts*/}

                    {/* END_USER_CODE-USER_BEFORE_lblPrcLockInDts*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblPrcLockInDts}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblPrcLockInDts*/}

                    {/* END_USER_CODE-USER_AFTER_lblPrcLockInDts*/}
                    {/* START_USER_CODE-USER_BEFORE_gridLockInDates*/}

                    {/* END_USER_CODE-USER_BEFORE_gridLockInDates*/}

                    <GridWidget
                      conf={state.gridLockInDates}
                      screenConf={state}
                      linkClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      errors={errors}
                      touched={touched}
                      rows={values.gridLockInDates}
                      onEvent={event => {
                        gridChange(event),
                          invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }}
                    ></GridWidget>
                    {/* START_USER_CODE-USER_AFTER_gridLockInDates*/}

                    {/* END_USER_CODE-USER_AFTER_gridLockInDates*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotal*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotal*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotal}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotal*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotal*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxDates*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxDates*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFlexMarketControls*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFlexMarketControls*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxTonsDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxTonsDetails*/}

                {/* START_USER_CODE-USER_BEFORE_txtTonsAllowed*/}

                {/* END_USER_CODE-USER_BEFORE_txtTonsAllowed*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(
                      event,
                      handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                  onBlur={event =>
                    invokeEventHandler(
                      event,
                      handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTonsAllowed}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(
                      event,
                      handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTonsAllowed*/}

                {/* END_USER_CODE-USER_AFTER_txtTonsAllowed*/}

                <GroupBoxWidget
                  conf={state.grpbxTonsDetails}
                  screenConf={state}
                >

                  {/* START_USER_CODE-USER_BEFORE_txtTonsAssigned*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTonsAssigned*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTonsAssigned}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTonsAssigned*/}

                  {/* END_USER_CODE-USER_AFTER_txtTonsAssigned*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTonsContracted*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTonsContracted*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTonsContracted}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTonsContracted*/}

                  {/* END_USER_CODE-USER_AFTER_txtTonsContracted*/}
                  {/* START_USER_CODE-USER_BEFORE_txtIrrigatedAcresContracted*/}

                  {/* END_USER_CODE-USER_BEFORE_txtIrrigatedAcresContracted*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtIrrigatedAcresContracted}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtIrrigatedAcresContracted*/}

                  {/* END_USER_CODE-USER_AFTER_txtIrrigatedAcresContracted*/}
                  {/* START_USER_CODE-USER_BEFORE_btnImage1*/}

                  {/* END_USER_CODE-USER_BEFORE_btnImage1*/}

                  <ButtonWidget
                    conf={state.btnImage1}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnImage1*/}

                  {/* END_USER_CODE-USER_AFTER_btnImage1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAvailableTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAvailableTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAvailableTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAvailableTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtAvailableTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOpenTons*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOpenTons*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOpenTons}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOpenTons*/}

                  {/* END_USER_CODE-USER_AFTER_txtOpenTons*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDryLandAcresContracted*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDryLandAcresContracted*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDryLandAcresContracted}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDryLandAcresContracted*/}

                  {/* END_USER_CODE-USER_AFTER_txtDryLandAcresContracted*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxTonsDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxTonsDetails*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxContractLimitArea*/}

              {/* END_USER_CODE-USER_AFTER_grpbxContractLimitArea*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ContractLimitArea*/}

              {/* END_USER_CODE-USER_AFTER_ContractLimitArea*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_ContractLimitArea);
