/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import jsPDF from "jspdf";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  Tab,
  Col,
  Row,
  Nav,
  ListboxWidget,
  CommandContainerWidget,
  ButtonWidget,
  getValue,
  setData,
  getData,
  setValue
} from "../../shared/WindowImports";

import "./UserManual.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import StripPic from "../../../../assets/img/PinStrip.png";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
// END_USER_CODE-USER_IMPORTS
function ReportUsermanual_UserManual(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "UserManual",
    windowName: "UserManual",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ReportUsermanual.UserManual",
    // START_USER_CODE-USER_UserManual_PROPERTIES
    headerData: {
      scrName: "User Manuals",
      scrCode: "PN1200A",
    },
    // END_USER_CODE-USER_UserManual_PROPERTIES
    grpbxCollectionPointManuals: {
      name: "grpbxCollectionPointManuals",
      type: "GroupBoxWidget",
      parent: "tabCollectionPointManuals",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxCollectionPointManuals_PROPERTIES

      // END_USER_CODE-USER_grpbxCollectionPointManuals_PROPERTIES
    },
    lstCllctnManuals: {
      name: "lstCllctnManuals",
      type: "ListBoxFieldWidget",
      parent: "grpbxCollectionPointManuals",
      HasLabel:false,
      ColSpan: "3",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstCllctnManuals_PROPERTIES

      // END_USER_CODE-USER_lstCllctnManuals_PROPERTIES
    },
    lstGeneralManual: {
      name: "lstGeneralManual",
      type: "ListBoxFieldWidget",
      parent: "grpbxGeneralManuals",
      HasLabel:false,
      ColSpan: "3",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstGeneralManual_PROPERTIES

      // END_USER_CODE-USER_lstGeneralManual_PROPERTIES
    },
    grpbxGeneralManuals: {
      name: "grpbxGeneralManuals",
      type: "GroupBoxWidget",
      parent: "tabGeneralManuals",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxGeneralManuals_PROPERTIES

      // END_USER_CODE-USER_grpbxGeneralManuals_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDownload: {
      name: "btnDownload",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Download",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDownload_PROPERTIES

      // END_USER_CODE-USER_btnDownload_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "UserManual",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    tabCollectionPointManuals: {
      name: "tabCollectionPointManuals",
      type: "TabWidget",
      parent: "tabsUserManuals",
      Label: "Collection Point Manuals",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_tabCollectionPointManuals_PROPERTIES

      // END_USER_CODE-USER_tabCollectionPointManuals_PROPERTIES
    },
    tabGeneralManuals: {
      name: "tabGeneralManuals",
      type: "TabWidget",
      parent: "tabsUserManuals",
      Label: "General Manuals",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_tabGeneralManuals_PROPERTIES

      // END_USER_CODE-USER_tabGeneralManuals_PROPERTIES
    },
    tabsUserManuals: {
      name: "tabsUserManuals",
      type: "TabContainerWidget",
      parent: "UserManual",
      Horizontal: true,
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_tabsUserManuals_PROPERTIES

      // END_USER_CODE-USER_tabsUserManuals_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu":{}
      },
      REVERSE:{
        "ContractManagement#MainMenu":{}
      }
    }
  };
  let _buttonServices = {
    tabGeneralManuals: {
      DEFAULT: [],
      CREATE: [],
      MODIFY: [],
      VIEW: [],
      DELETE: [],
    },

    tabCollectionPointManuals: {
      DEFAULT: [],
      CREATE: [],
      MODIFY: [],
      VIEW: [],
      DELETE: [],
    },
  };
  let _buttonNavigation = {
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
    tabGeneralManuals: {
      DEFAULT: [],
      CREATE: [],
      MODIFY: [],
      VIEW: [],
      DELETE: [],
    },

    tabCollectionPointManuals: {
      DEFAULT: [],
      CREATE: [],
      MODIFY: [],
      VIEW: [],
      DELETE: [],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    FormLoad()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    switchToTab(thisObj,'tabGeneralManuals')
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const FormLoad = async () => {
    let Data1 = await ContractManagementService.RetrieveAccessPermissionDetails('PN1200', '001', 'I', null);
    if (Data1[0].permission == 'N') {
      disable(thisObj,"tabGeneralManuals");
    }
    GetDocuments()
  }
  //GetDocuments
  const GetDocuments = async () => {
    try {
      let response = await ContractManagementService.RetrievePeanutStaticValues()
      let js = []
      if (response !== undefined && response !== null && response !== '') {
        for (var i = 0; i < response[0].pps_defaults.coll_pt_manuals?.length; i++) {
          js.push({
            key: i,
            description: response[0].pps_defaults.coll_pt_manuals[0].coll_pt_manual
          })
        }
        thisObj.setState(current => {
          return {
            ...current,
            lstCllctnManuals: {
              ...state["lstCllctnManuals"],
              valueList: js
            }
          }
        })

        js = []
        for (var i = 0; i < response[0].pps_defaults.user_manuals?.length; i++) {
          js.push({
            key: i,
            description: response[0].pps_defaults.user_manuals[0].user_manual
          })
        }
        thisObj.setState(current => {
          return {
            ...current,
            lstGeneralManual: {
              ...state["lstGeneralManual"],
              valueList: js
            }
          }
        })
      }
    }
    catch (err) {
      errorHandler(err)
    }
  }

  const onlstCllctnManualsChange = () => {
    try {
      let valOption = getValue(thisObj, 'lstCllctnManuals');
      setData(thisObj, 'downLoadFileType', 'ColPtManual')
      setData(thisObj, 'RowSelected', valOption)
    }
    catch (err) {
      errorHandler(err)
    }
  }
  thisObj.onlstCllctnManualsChange = onlstCllctnManualsChange

  const onlstGeneralManualChange = () => {
    try {
      let valOption = getValue(thisObj, 'lstGeneralManual')
      setData(thisObj, 'downLoadFileType', 'GenManual')
      setData(thisObj, 'RowSelected', valOption)
    }
    catch (err) {
      errorHandler(err)
    }
  }
  thisObj.onlstGeneralManualChange = onlstGeneralManualChange

  const onbtnDownloadClick = () => {
    try {
      let fileName = getData(thisObj, 'downLoadFileType')
      let SelectedRow = getData(thisObj, 'RowSelected')
      if(!SelectedRow){
        showMessage(  thisObj, "A PDF must be selected to Download. \n No PDF Selected")
        return;
      }
      let createurl = ''
      // Files are not uploaded in PROD & UAT URL (update dated: 09/04/2024)
      if(fileName === 'ColPtManual'){
        if(process.env.REACT_APP_ENVIR == 'PROD'){
          createurl = 'https://stormaznappsprod01.blob.core.windows.net/usermanuals/Collection%20Point%20Training%20Manual.pdf'
        }
        else if(process.env.REACT_APP_ENVIR == 'UAT'){
          createurl = 'https://stormaznappsuat01.blob.core.windows.net/usermanuals/Collection%20Point%20Training%20Manual.pdf'
        }
        else if(process.env.REACT_APP_ENVIR == 'TEST'){
          createurl = 'https://stormaznappstst01.blob.core.windows.net/usermanuals/Collection%20Point%20Training%20Manual.pdf'
        }
        else if(process.env.REACT_APP_ENVIR == 'LOCAL' || process.env.REACT_APP_ENVIR == 'DEV'){
          createurl = 'https://stormaznappsdev01.blob.core.windows.net/usermanuals/Collection%20Point%20Training%20Manual.pdf'
        }
        else{
          return;
        }
      }
      else if(fileName === 'GenManual'){
        if(process.env.REACT_APP_ENVIR == 'PROD'){
          createurl = 'https://stormaznappsprod01.blob.core.windows.net/usermanuals/Main%20Training%20Manual.pdf'
        }
        else if(process.env.REACT_APP_ENVIR == 'UAT'){
          createurl = 'https://stormaznappsuat01.blob.core.windows.net/usermanuals/Main%20Training%20Manual.pdf'
        }
        else if(process.env.REACT_APP_ENVIR == 'TEST'){
          createurl = 'https://stormaznappstst01.blob.core.windows.net/usermanuals/Main%20Training%20Manual.pdf'
        }
        else if(process.env.REACT_APP_ENVIR == 'LOCAL' || process.env.REACT_APP_ENVIR == 'DEV'){
          createurl = 'https://stormaznappsdev01.blob.core.windows.net/usermanuals/Main%20Training%20Manual.pdf'
        }
        else{
          return;
        }
      }

      window.open(createurl)
      setData(thisObj, 'downLoadFileType', '')
      setData(thisObj, 'RowSelected', '')
    }
    catch (err) {
      errorHandler(err)
    }
  }
  thisObj.onbtnDownloadClick = onbtnDownloadClick;

  function errorHandler(err) {
    showMessage(thisObj, 'Something went wrong: ' + err?.message)
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_UserManual*/}

              {/* END_USER_CODE-USER_BEFORE_UserManual*/}

              {/* START_USER_CODE-USER_BEFORE_tabsUserManuals*/}

              {/* END_USER_CODE-USER_BEFORE_tabsUserManuals*/}

              <Tab.Container
                activeKey={state.tabsUserManuals.activeTab}
                onSelect={key => {
                  switchToTab(thisObj, key);
                  setValue(thisObj,'lstCllctnManuals',[])
                  setValue(thisObj,'lstGeneralManual',[])
                  setData(thisObj, 'downLoadFileType', '')
                  setData(thisObj, 'RowSelected', '')
                }}
              >
                <Col sm={state.tabsUserManuals.Horizontal ? "12" : "3"}>
                  <Nav
                    variant={
                      state.tabsUserManuals.Horizontal &&
                        state.tabsUserManuals.variant === "tabs"
                        ? "tabs"
                        : "pills"
                    }
                    className={
                      state.tabsUserManuals.Horizontal ? "" : "flex-column"
                    }
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="tabGeneralManuals">
                        {state.tabGeneralManuals.Label}
                      </Nav.Link>
                    </Nav.Item>{" "}
                    <Nav.Item>
                      <Nav.Link eventKey="tabCollectionPointManuals">
                        {state.tabCollectionPointManuals.Label}
                      </Nav.Link>
                    </Nav.Item>{" "}
                  </Nav>
                </Col>
                <Col
                  sm={state.tabsUserManuals.Horizontal ? "12" : "9"}
                  className={state.tabsUserManuals.Horizontal ? "pt-2" : ""}
                >
                  <Row>
                    <Tab.Content className="w-100">
                      <Tab.Pane eventKey="tabGeneralManuals">
                        {/* START_USER_CODE-USER_BEFORE_grpbxGeneralManuals*/}

                        {/* END_USER_CODE-USER_BEFORE_grpbxGeneralManuals*/}

                        <GroupBoxWidget
                          conf={state.grpbxGeneralManuals}
                          screenConf={state}
                        >
                          <ListboxWidget
                            onChange={event =>
                              invokeEventHandler(event, handleChange, handleBlur, thisObj)
                            }
                            onBlur={handleBlur}
                            values={values}
                            touched={touched}
                            errors={errors}
                            conf={state.lstGeneralManual}
                            screenConf={state}
                          ></ListboxWidget>

                        </GroupBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_grpbxGeneralManuals*/}

                        {/* END_USER_CODE-USER_AFTER_grpbxGeneralManuals*/}
                      </Tab.Pane>

                      <Tab.Pane eventKey="tabCollectionPointManuals">
                        {/* START_USER_CODE-USER_BEFORE_grpbxCollectionPointManuals*/}

                        {/* END_USER_CODE-USER_BEFORE_grpbxCollectionPointManuals*/}
                        <GroupBoxWidget
                          conf={state.grpbxCollectionPointManuals}
                          screenConf={state}
                        >
                          <ListboxWidget
                            onChange={event =>
                              invokeEventHandler(event, handleChange, handleBlur, thisObj)
                            }
                            onClick={event =>
                              invokeEventHandler(event, handleChange, handleBlur, thisObj)
                            }
                            onBlur={handleBlur}
                            values={values}
                            touched={touched}
                            errors={errors}
                            conf={state.lstCllctnManuals}
                            screenConf={state}
                          ></ListboxWidget>
                        </GroupBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_grpbxCollectionPointManuals*/}

                        {/* END_USER_CODE-USER_AFTER_grpbxCollectionPointManuals*/}
                      </Tab.Pane>
                    </Tab.Content>
                  </Row>
                </Col>
              </Tab.Container>

              {/* START_USER_CODE-USER_AFTER_tabsUserManuals*/}

              {/* END_USER_CODE-USER_AFTER_tabsUserManuals*/}

              {/* START_USER_CODE-USER_AFTER_UserManual*/}

              {/* END_USER_CODE-USER_AFTER_UserManual*/}
              <img
                src={StripPic}
                className="stripDesign"
              />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}
                <ButtonWidget
                  conf={state.btnDownload}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ReportUsermanual_UserManual);
