/* eslint-disable*/
import React from "react";
import Settlements_FV95 from "./FV95";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FV95 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_FV95 />);
    });
  });
  afterEach(cleanup);
  test("is FV95 Loads Successfully", () => {
    expect(screen.getByText("FV95")).toBeInTheDocument;
  });
  test("Custom Test Cases for FV95", () => {
    // START_USER_CODE-USER_FV95_Custom_Test_Case
    // END_USER_CODE-USER_FV95_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_FV95 />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(t("Settlements:FV95_btnAdd"));
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnCalculate(Button Widget) Test Cases", async () => {
    const btnCalculate = screen.getByTestId("btnCalculate");
    expect(btnCalculate).toBeInTheDocument;
    expect(btnCalculate.textContent).toEqual(
      t("Settlements:FV95_btnCalculate")
    );
  });
  test("Custom Test Cases for btnCalculate", () => {
    // START_USER_CODE-USER_btnCalculate_TEST
    // END_USER_CODE-USER_btnCalculate_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(t("Settlements:FV95_btnCancel"));
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnChange(Button Widget) Test Cases", async () => {
    const btnChange = screen.getByTestId("btnChange");
    expect(btnChange).toBeInTheDocument;
    expect(btnChange.textContent).toEqual(t("Settlements:FV95_btnChange"));
  });
  test("Custom Test Cases for btnChange", () => {
    // START_USER_CODE-USER_btnChange_TEST
    // END_USER_CODE-USER_btnChange_TEST
  });
  test("btnConvertHMC(Button Widget) Test Cases", async () => {
    const btnConvertHMC = screen.getByTestId("btnConvertHMC");
    expect(btnConvertHMC).toBeInTheDocument;
    expect(btnConvertHMC.textContent).toEqual(
      t("Settlements:FV95_btnConvertHMC")
    );
  });
  test("Custom Test Cases for btnConvertHMC", () => {
    // START_USER_CODE-USER_btnConvertHMC_TEST
    // END_USER_CODE-USER_btnConvertHMC_TEST
  });
  test("btnCreate(Button Widget) Test Cases", async () => {
    const btnCreate = screen.getByTestId("btnCreate");
    expect(btnCreate).toBeInTheDocument;
    expect(btnCreate.textContent).toEqual(t("Settlements:FV95_btnCreate"));
  });
  test("Custom Test Cases for btnCreate", () => {
    // START_USER_CODE-USER_btnCreate_TEST
    // END_USER_CODE-USER_btnCreate_TEST
  });
  test("btnCreate1007(Button Widget) Test Cases", async () => {
    const btnCreate1007 = screen.getByTestId("btnCreate1007");
    expect(btnCreate1007).toBeInTheDocument;
    expect(btnCreate1007.textContent).toEqual(
      t("Settlements:FV95_btnCreate1007")
    );
  });
  test("Custom Test Cases for btnCreate1007", () => {
    // START_USER_CODE-USER_btnCreate1007_TEST
    // END_USER_CODE-USER_btnCreate1007_TEST
  });
  test("btnCreateFarm(Button Widget) Test Cases", async () => {
    const btnCreateFarm = screen.getByTestId("btnCreateFarm");
    expect(btnCreateFarm).toBeInTheDocument;
    expect(btnCreateFarm.textContent).toEqual(
      t("Settlements:FV95_btnCreateFarm")
    );
  });
  test("Custom Test Cases for btnCreateFarm", () => {
    // START_USER_CODE-USER_btnCreateFarm_TEST
    // END_USER_CODE-USER_btnCreateFarm_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(t("Settlements:FV95_btnDelete"));
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(t("Settlements:FV95_btnEdit"));
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnReprint(Button Widget) Test Cases", async () => {
    const btnReprint = screen.getByTestId("btnReprint");
    expect(btnReprint).toBeInTheDocument;
    expect(btnReprint.textContent).toEqual(t("Settlements:FV95_btnReprint"));
  });
  test("Custom Test Cases for btnReprint", () => {
    // START_USER_CODE-USER_btnReprint_TEST
    // END_USER_CODE-USER_btnReprint_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(t("Settlements:FV95_btnSave"));
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("btnVendor0(Button Widget) Test Cases", async () => {
    const btnVendor0 = screen.getByTestId("btnVendor0");
    expect(btnVendor0).toBeInTheDocument;
    expect(btnVendor0.textContent).toEqual(t("Settlements:FV95_btnVendor0"));
  });
  test("Custom Test Cases for btnVendor0", () => {
    // START_USER_CODE-USER_btnVendor0_TEST
    // END_USER_CODE-USER_btnVendor0_TEST
  });
  test("btnVendor1(Button Widget) Test Cases", async () => {
    const btnVendor1 = screen.getByTestId("btnVendor1");
    expect(btnVendor1).toBeInTheDocument;
    expect(btnVendor1.textContent).toEqual(t("Settlements:FV95_btnVendor1"));
  });
  test("Custom Test Cases for btnVendor1", () => {
    // START_USER_CODE-USER_btnVendor1_TEST
    // END_USER_CODE-USER_btnVendor1_TEST
  });
  test("btnVendor2(Button Widget) Test Cases", async () => {
    const btnVendor2 = screen.getByTestId("btnVendor2");
    expect(btnVendor2).toBeInTheDocument;
    expect(btnVendor2.textContent).toEqual(t("Settlements:FV95_btnVendor2"));
  });
  test("Custom Test Cases for btnVendor2", () => {
    // START_USER_CODE-USER_btnVendor2_TEST
    // END_USER_CODE-USER_btnVendor2_TEST
  });
  test("btnVendor3(Button Widget) Test Cases", async () => {
    const btnVendor3 = screen.getByTestId("btnVendor3");
    expect(btnVendor3).toBeInTheDocument;
    expect(btnVendor3.textContent).toEqual(t("Settlements:FV95_btnVendor3"));
  });
  test("Custom Test Cases for btnVendor3", () => {
    // START_USER_CODE-USER_btnVendor3_TEST
    // END_USER_CODE-USER_btnVendor3_TEST
  });
  test("gridWeightTickets(Grid Widget) Test Cases", async () => {
    let gridWeightTickets = screen.getByTestId("gridWeightTickets");
    let gridWeightTicketsbtn =
      gridWeightTickets.nextElementSibling.firstElementChild;
    gridWeightTickets =
      gridWeightTickets.parentElement.parentElement.parentElement;
    expect(gridWeightTickets.tagName).toBe("DIV");
    expect(gridWeightTickets.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95_gridWeightTickets")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for gridWeightTickets", () => {
    // START_USER_CODE-USER_gridWeightTickets_TEST
    // END_USER_CODE-USER_gridWeightTickets_TEST
  });
  test("grpbx1007Control(GroupBox Widget) Test Cases", async () => {
    const grpbx1007Control = screen.getByTestId("grpbx1007Control");
    expect(grpbx1007Control.tagName).toBe("BUTTON");
    expect(grpbx1007Control.type).toBe("button");
    expect(grpbx1007Control.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx1007Control", () => {
    // START_USER_CODE-USER_grpbx1007Control_TEST
    // END_USER_CODE-USER_grpbx1007Control_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCMAInformationuic0(GroupBox Widget) Test Cases", async () => {
    const grpbxCMAInformationuic0 = screen.getByTestId(
      "grpbxCMAInformationuic0"
    );
    expect(grpbxCMAInformationuic0.tagName).toBe("BUTTON");
    expect(grpbxCMAInformationuic0.type).toBe("button");
    expect(grpbxCMAInformationuic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCMAInformationuic0", () => {
    // START_USER_CODE-USER_grpbxCMAInformationuic0_TEST
    // END_USER_CODE-USER_grpbxCMAInformationuic0_TEST
  });
  test("grpbxFarmInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmInfo = screen.getByTestId("grpbxFarmInfo");
    expect(grpbxFarmInfo.tagName).toBe("BUTTON");
    expect(grpbxFarmInfo.type).toBe("button");
    expect(grpbxFarmInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmInfo", () => {
    // START_USER_CODE-USER_grpbxFarmInfo_TEST
    // END_USER_CODE-USER_grpbxFarmInfo_TEST
  });
  test("grpbxFarmInformation(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmInformation = screen.getByTestId("grpbxFarmInformation");
    expect(grpbxFarmInformation.tagName).toBe("BUTTON");
    expect(grpbxFarmInformation.type).toBe("button");
    expect(grpbxFarmInformation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmInformation", () => {
    // START_USER_CODE-USER_grpbxFarmInformation_TEST
    // END_USER_CODE-USER_grpbxFarmInformation_TEST
  });
  test("grpbxGradeCalculation(GroupBox Widget) Test Cases", async () => {
    const grpbxGradeCalculation = screen.getByTestId("grpbxGradeCalculation");
    expect(grpbxGradeCalculation.tagName).toBe("BUTTON");
    expect(grpbxGradeCalculation.type).toBe("button");
    expect(grpbxGradeCalculation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGradeCalculation", () => {
    // START_USER_CODE-USER_grpbxGradeCalculation_TEST
    // END_USER_CODE-USER_grpbxGradeCalculation_TEST
  });
  test("grpbxHMC(GroupBox Widget) Test Cases", async () => {
    const grpbxHMC = screen.getByTestId("grpbxHMC");
    expect(grpbxHMC.tagName).toBe("BUTTON");
    expect(grpbxHMC.type).toBe("button");
    expect(grpbxHMC.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxHMC", () => {
    // START_USER_CODE-USER_grpbxHMC_TEST
    // END_USER_CODE-USER_grpbxHMC_TEST
  });
  test("grpbxInspectorRemarks(GroupBox Widget) Test Cases", async () => {
    const grpbxInspectorRemarks = screen.getByTestId("grpbxInspectorRemarks");
    expect(grpbxInspectorRemarks.tagName).toBe("BUTTON");
    expect(grpbxInspectorRemarks.type).toBe("button");
    expect(grpbxInspectorRemarks.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxInspectorRemarks", () => {
    // START_USER_CODE-USER_grpbxInspectorRemarks_TEST
    // END_USER_CODE-USER_grpbxInspectorRemarks_TEST
  });
  test("grpbxPeanutInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanutInfo = screen.getByTestId("grpbxPeanutInfo");
    expect(grpbxPeanutInfo.tagName).toBe("BUTTON");
    expect(grpbxPeanutInfo.type).toBe("button");
    expect(grpbxPeanutInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPeanutInfo", () => {
    // START_USER_CODE-USER_grpbxPeanutInfo_TEST
    // END_USER_CODE-USER_grpbxPeanutInfo_TEST
  });
  test("grpbxValueCalculation(GroupBox Widget) Test Cases", async () => {
    const grpbxValueCalculation = screen.getByTestId("grpbxValueCalculation");
    expect(grpbxValueCalculation.tagName).toBe("BUTTON");
    expect(grpbxValueCalculation.type).toBe("button");
    expect(grpbxValueCalculation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxValueCalculation", () => {
    // START_USER_CODE-USER_grpbxValueCalculation_TEST
    // END_USER_CODE-USER_grpbxValueCalculation_TEST
  });
  test("grpbxVendorSplitRow0(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow0 = screen.getByTestId("grpbxVendorSplitRow0");
    expect(grpbxVendorSplitRow0.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow0.type).toBe("button");
    expect(grpbxVendorSplitRow0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow0", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow0_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow0_TEST
  });
  test("grpbxVendorSplitRow1(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow1 = screen.getByTestId("grpbxVendorSplitRow1");
    expect(grpbxVendorSplitRow1.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow1.type).toBe("button");
    expect(grpbxVendorSplitRow1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow1", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow1_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow1_TEST
  });
  test("grpbxVendorSplitRow2(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow2 = screen.getByTestId("grpbxVendorSplitRow2");
    expect(grpbxVendorSplitRow2.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow2.type).toBe("button");
    expect(grpbxVendorSplitRow2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow2", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow2_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow2_TEST
  });
  test("grpbxVendorSplitRow3(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow3 = screen.getByTestId("grpbxVendorSplitRow3");
    expect(grpbxVendorSplitRow3.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow3.type).toBe("button");
    expect(grpbxVendorSplitRow3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow3", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow3_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow3_TEST
  });
  test("grpbxVendorSplits(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplits = screen.getByTestId("grpbxVendorSplits");
    expect(grpbxVendorSplits.tagName).toBe("BUTTON");
    expect(grpbxVendorSplits.type).toBe("button");
    expect(grpbxVendorSplits.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplits", () => {
    // START_USER_CODE-USER_grpbxVendorSplits_TEST
    // END_USER_CODE-USER_grpbxVendorSplits_TEST
  });
  test("grpbxVirginiaGrading(GroupBox Widget) Test Cases", async () => {
    const grpbxVirginiaGrading = screen.getByTestId("grpbxVirginiaGrading");
    expect(grpbxVirginiaGrading.tagName).toBe("BUTTON");
    expect(grpbxVirginiaGrading.type).toBe("button");
    expect(grpbxVirginiaGrading.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVirginiaGrading", () => {
    // START_USER_CODE-USER_grpbxVirginiaGrading_TEST
    // END_USER_CODE-USER_grpbxVirginiaGrading_TEST
  });
  test("grpbxWeightTickets(GroupBox Widget) Test Cases", async () => {
    const grpbxWeightTickets = screen.getByTestId("grpbxWeightTickets");
    expect(grpbxWeightTickets.tagName).toBe("BUTTON");
    expect(grpbxWeightTickets.type).toBe("button");
    expect(grpbxWeightTickets.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWeightTickets", () => {
    // START_USER_CODE-USER_grpbxWeightTickets_TEST
    // END_USER_CODE-USER_grpbxWeightTickets_TEST
  });
  test("lbl1007Control(Label Widget) Test Cases", async () => {
    const lbl1007Control = screen.getByTestId("lbl1007Control");
    expect(lbl1007Control.tagName).toBe("LABEL");
    expect(lbl1007Control.classList).toContain("form-label");
    expect(lbl1007Control.textContent).toEqual(
      t("Settlements:FV95_lbl1007Control")
    );
  });
  test("Custom Test Cases for lbl1007Control", () => {
    // START_USER_CODE-USER_lbl1007Control_TEST
    // END_USER_CODE-USER_lbl1007Control_TEST
  });
  test("lblAdd1007Aplcnt(Label Widget) Test Cases", async () => {
    const lblAdd1007Aplcnt = screen.getByTestId("lblAdd1007Aplcnt");
    expect(lblAdd1007Aplcnt.tagName).toBe("LABEL");
    expect(lblAdd1007Aplcnt.classList).toContain("form-label");
    expect(lblAdd1007Aplcnt.textContent).toEqual(
      t("Settlements:FV95_lblAdd1007Aplcnt")
    );
  });
  test("Custom Test Cases for lblAdd1007Aplcnt", () => {
    // START_USER_CODE-USER_lblAdd1007Aplcnt_TEST
    // END_USER_CODE-USER_lblAdd1007Aplcnt_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(t("Settlements:FV95_lblAddedBy"));
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("Settlements:FV95_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblAuditCount(Label Widget) Test Cases", async () => {
    const lblAuditCount = screen.getByTestId("lblAuditCount");
    expect(lblAuditCount.tagName).toBe("LABEL");
    expect(lblAuditCount.classList).toContain("form-label");
    expect(lblAuditCount.textContent).toEqual(
      t("Settlements:FV95_lblAuditCount")
    );
  });
  test("Custom Test Cases for lblAuditCount", () => {
    // START_USER_CODE-USER_lblAuditCount_TEST
    // END_USER_CODE-USER_lblAuditCount_TEST
  });
  test("lblAuditCountValue(Label Widget) Test Cases", async () => {
    const lblAuditCountValue = screen.getByTestId("lblAuditCountValue");
    expect(lblAuditCountValue.tagName).toBe("LABEL");
    expect(lblAuditCountValue.classList).toContain("form-label");
    expect(lblAuditCountValue.textContent).toEqual(
      t("Settlements:FV95_lblAuditCountValue")
    );
  });
  test("Custom Test Cases for lblAuditCountValue", () => {
    // START_USER_CODE-USER_lblAuditCountValue_TEST
    // END_USER_CODE-USER_lblAuditCountValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("Settlements:FV95_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("Settlements:FV95_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblCMAInformation(Label Widget) Test Cases", async () => {
    const lblCMAInformation = screen.getByTestId("lblCMAInformation");
    expect(lblCMAInformation.tagName).toBe("LABEL");
    expect(lblCMAInformation.classList).toContain("form-label");
    expect(lblCMAInformation.textContent).toEqual(
      t("Settlements:FV95_lblCMAInformation")
    );
  });
  test("Custom Test Cases for lblCMAInformation", () => {
    // START_USER_CODE-USER_lblCMAInformation_TEST
    // END_USER_CODE-USER_lblCMAInformation_TEST
  });
  test("lblCMAParticipant(Label Widget) Test Cases", async () => {
    const lblCMAParticipant = screen.getByTestId("lblCMAParticipant");
    expect(lblCMAParticipant.tagName).toBe("LABEL");
    expect(lblCMAParticipant.classList).toContain("form-label");
    expect(lblCMAParticipant.textContent).toEqual(
      t("Settlements:FV95_lblCMAParticipant")
    );
  });
  test("Custom Test Cases for lblCMAParticipant", () => {
    // START_USER_CODE-USER_lblCMAParticipant_TEST
    // END_USER_CODE-USER_lblCMAParticipant_TEST
  });
  test("lblCornFound(Label Widget) Test Cases", async () => {
    const lblCornFound = screen.getByTestId("lblCornFound");
    expect(lblCornFound.tagName).toBe("LABEL");
    expect(lblCornFound.classList).toContain("form-label");
    expect(lblCornFound.textContent).toEqual(
      t("Settlements:FV95_lblCornFound")
    );
  });
  test("Custom Test Cases for lblCornFound", () => {
    // START_USER_CODE-USER_lblCornFound_TEST
    // END_USER_CODE-USER_lblCornFound_TEST
  });
  test("lblDateTimeFormat(Label Widget) Test Cases", async () => {
    const lblDateTimeFormat = screen.getByTestId("lblDateTimeFormat");
    expect(lblDateTimeFormat.tagName).toBe("LABEL");
    expect(lblDateTimeFormat.classList).toContain("form-label");
    expect(lblDateTimeFormat.textContent).toEqual(
      t("Settlements:FV95_lblDateTimeFormat")
    );
  });
  test("Custom Test Cases for lblDateTimeFormat", () => {
    // START_USER_CODE-USER_lblDateTimeFormat_TEST
    // END_USER_CODE-USER_lblDateTimeFormat_TEST
  });
  test("lblDelPtObligation(Label Widget) Test Cases", async () => {
    const lblDelPtObligation = screen.getByTestId("lblDelPtObligation");
    expect(lblDelPtObligation.tagName).toBe("LABEL");
    expect(lblDelPtObligation.classList).toContain("form-label");
    expect(lblDelPtObligation.textContent).toEqual(
      t("Settlements:FV95_lblDelPtObligation")
    );
  });
  test("Custom Test Cases for lblDelPtObligation", () => {
    // START_USER_CODE-USER_lblDelPtObligation_TEST
    // END_USER_CODE-USER_lblDelPtObligation_TEST
  });
  test("lblFarmInformation(Label Widget) Test Cases", async () => {
    const lblFarmInformation = screen.getByTestId("lblFarmInformation");
    expect(lblFarmInformation.tagName).toBe("LABEL");
    expect(lblFarmInformation.classList).toContain("form-label");
    expect(lblFarmInformation.textContent).toEqual(
      t("Settlements:FV95_lblFarmInformation")
    );
  });
  test("Custom Test Cases for lblFarmInformation", () => {
    // START_USER_CODE-USER_lblFarmInformation_TEST
    // END_USER_CODE-USER_lblFarmInformation_TEST
  });
  test("lblFlavusFound(Label Widget) Test Cases", async () => {
    const lblFlavusFound = screen.getByTestId("lblFlavusFound");
    expect(lblFlavusFound.tagName).toBe("LABEL");
    expect(lblFlavusFound.classList).toContain("form-label");
    expect(lblFlavusFound.textContent).toEqual(
      t("Settlements:FV95_lblFlavusFound")
    );
  });
  test("Custom Test Cases for lblFlavusFound", () => {
    // START_USER_CODE-USER_lblFlavusFound_TEST
    // END_USER_CODE-USER_lblFlavusFound_TEST
  });
  test("lblfreefv95(Label Widget) Test Cases", async () => {
    const lblfreefv95 = screen.getByTestId("lblfreefv95");
    expect(lblfreefv95.tagName).toBe("LABEL");
    expect(lblfreefv95.classList).toContain("form-label");
    expect(lblfreefv95.textContent).toEqual(t("Settlements:FV95_lblfreefv95"));
  });
  test("Custom Test Cases for lblfreefv95", () => {
    // START_USER_CODE-USER_lblfreefv95_TEST
    // END_USER_CODE-USER_lblfreefv95_TEST
  });
  test("lblGradeCalculation(Label Widget) Test Cases", async () => {
    const lblGradeCalculation = screen.getByTestId("lblGradeCalculation");
    expect(lblGradeCalculation.tagName).toBe("LABEL");
    expect(lblGradeCalculation.classList).toContain("form-label");
    expect(lblGradeCalculation.textContent).toEqual(
      t("Settlements:FV95_lblGradeCalculation")
    );
  });
  test("Custom Test Cases for lblGradeCalculation", () => {
    // START_USER_CODE-USER_lblGradeCalculation_TEST
    // END_USER_CODE-USER_lblGradeCalculation_TEST
  });
  test("lblGrams1(Label Widget) Test Cases", async () => {
    const lblGrams1 = screen.getByTestId("lblGrams1");
    expect(lblGrams1.tagName).toBe("LABEL");
    expect(lblGrams1.classList).toContain("form-label");
    expect(lblGrams1.textContent).toEqual(t("Settlements:FV95_lblGrams1"));
  });
  test("Custom Test Cases for lblGrams1", () => {
    // START_USER_CODE-USER_lblGrams1_TEST
    // END_USER_CODE-USER_lblGrams1_TEST
  });
  test("lblGrams2(Label Widget) Test Cases", async () => {
    const lblGrams2 = screen.getByTestId("lblGrams2");
    expect(lblGrams2.tagName).toBe("LABEL");
    expect(lblGrams2.classList).toContain("form-label");
    expect(lblGrams2.textContent).toEqual(t("Settlements:FV95_lblGrams2"));
  });
  test("Custom Test Cases for lblGrams2", () => {
    // START_USER_CODE-USER_lblGrams2_TEST
    // END_USER_CODE-USER_lblGrams2_TEST
  });
  test("lblHMCConvertedGrades(Label Widget) Test Cases", async () => {
    const lblHMCConvertedGrades = screen.getByTestId("lblHMCConvertedGrades");
    expect(lblHMCConvertedGrades.tagName).toBe("LABEL");
    expect(lblHMCConvertedGrades.classList).toContain("form-label");
    expect(lblHMCConvertedGrades.textContent).toEqual(
      t("Settlements:FV95_lblHMCConvertedGrades")
    );
  });
  test("Custom Test Cases for lblHMCConvertedGrades", () => {
    // START_USER_CODE-USER_lblHMCConvertedGrades_TEST
    // END_USER_CODE-USER_lblHMCConvertedGrades_TEST
  });
  test("lblInspectorRemarks(Label Widget) Test Cases", async () => {
    const lblInspectorRemarks = screen.getByTestId("lblInspectorRemarks");
    expect(lblInspectorRemarks.tagName).toBe("LABEL");
    expect(lblInspectorRemarks.classList).toContain("form-label");
    expect(lblInspectorRemarks.textContent).toEqual(
      t("Settlements:FV95_lblInspectorRemarks")
    );
  });
  test("Custom Test Cases for lblInspectorRemarks", () => {
    // START_USER_CODE-USER_lblInspectorRemarks_TEST
    // END_USER_CODE-USER_lblInspectorRemarks_TEST
  });
  test("lblLoadType1(Label Widget) Test Cases", async () => {
    const lblLoadType1 = screen.getByTestId("lblLoadType1");
    expect(lblLoadType1.tagName).toBe("LABEL");
    expect(lblLoadType1.classList).toContain("form-label");
    expect(lblLoadType1.textContent).toEqual(
      t("Settlements:FV95_lblLoadType1")
    );
  });
  test("Custom Test Cases for lblLoadType1", () => {
    // START_USER_CODE-USER_lblLoadType1_TEST
    // END_USER_CODE-USER_lblLoadType1_TEST
  });
  test("lblLoadType2(Label Widget) Test Cases", async () => {
    const lblLoadType2 = screen.getByTestId("lblLoadType2");
    expect(lblLoadType2.tagName).toBe("LABEL");
    expect(lblLoadType2.classList).toContain("form-label");
    expect(lblLoadType2.textContent).toEqual(
      t("Settlements:FV95_lblLoadType2")
    );
  });
  test("Custom Test Cases for lblLoadType2", () => {
    // START_USER_CODE-USER_lblLoadType2_TEST
    // END_USER_CODE-USER_lblLoadType2_TEST
  });
  test("lblN(Label Widget) Test Cases", async () => {
    const lblN = screen.getByTestId("lblN");
    expect(lblN.tagName).toBe("LABEL");
    expect(lblN.classList).toContain("form-label");
    expect(lblN.textContent).toEqual(t("Settlements:FV95_lblN"));
  });
  test("Custom Test Cases for lblN", () => {
    // START_USER_CODE-USER_lblN_TEST
    // END_USER_CODE-USER_lblN_TEST
  });
  test("lblOrganic(Label Widget) Test Cases", async () => {
    const lblOrganic = screen.getByTestId("lblOrganic");
    expect(lblOrganic.tagName).toBe("LABEL");
    expect(lblOrganic.classList).toContain("form-label");
    expect(lblOrganic.textContent).toEqual(t("Settlements:FV95_lblOrganic"));
  });
  test("Custom Test Cases for lblOrganic", () => {
    // START_USER_CODE-USER_lblOrganic_TEST
    // END_USER_CODE-USER_lblOrganic_TEST
  });
  test("lblPct1(Label Widget) Test Cases", async () => {
    const lblPct1 = screen.getByTestId("lblPct1");
    expect(lblPct1.tagName).toBe("LABEL");
    expect(lblPct1.classList).toContain("form-label");
    expect(lblPct1.textContent).toEqual(t("Settlements:FV95_lblPct1"));
  });
  test("Custom Test Cases for lblPct1", () => {
    // START_USER_CODE-USER_lblPct1_TEST
    // END_USER_CODE-USER_lblPct1_TEST
  });
  test("lblPct2(Label Widget) Test Cases", async () => {
    const lblPct2 = screen.getByTestId("lblPct2");
    expect(lblPct2.tagName).toBe("LABEL");
    expect(lblPct2.classList).toContain("form-label");
    expect(lblPct2.textContent).toEqual(t("Settlements:FV95_lblPct2"));
  });
  test("Custom Test Cases for lblPct2", () => {
    // START_USER_CODE-USER_lblPct2_TEST
    // END_USER_CODE-USER_lblPct2_TEST
  });
  test("lblPeanutInformation(Label Widget) Test Cases", async () => {
    const lblPeanutInformation = screen.getByTestId("lblPeanutInformation");
    expect(lblPeanutInformation.tagName).toBe("LABEL");
    expect(lblPeanutInformation.classList).toContain("form-label");
    expect(lblPeanutInformation.textContent).toEqual(
      t("Settlements:FV95_lblPeanutInformation")
    );
  });
  test("Custom Test Cases for lblPeanutInformation", () => {
    // START_USER_CODE-USER_lblPeanutInformation_TEST
    // END_USER_CODE-USER_lblPeanutInformation_TEST
  });
  test("lblPerLb(Label Widget) Test Cases", async () => {
    const lblPerLb = screen.getByTestId("lblPerLb");
    expect(lblPerLb.tagName).toBe("LABEL");
    expect(lblPerLb.classList).toContain("form-label");
    expect(lblPerLb.textContent).toEqual(t("Settlements:FV95_lblPerLb"));
  });
  test("Custom Test Cases for lblPerLb", () => {
    // START_USER_CODE-USER_lblPerLb_TEST
    // END_USER_CODE-USER_lblPerLb_TEST
  });
  test("lblPerLb2(Label Widget) Test Cases", async () => {
    const lblPerLb2 = screen.getByTestId("lblPerLb2");
    expect(lblPerLb2.tagName).toBe("LABEL");
    expect(lblPerLb2.classList).toContain("form-label");
    expect(lblPerLb2.textContent).toEqual(t("Settlements:FV95_lblPerLb2"));
  });
  test("Custom Test Cases for lblPerLb2", () => {
    // START_USER_CODE-USER_lblPerLb2_TEST
    // END_USER_CODE-USER_lblPerLb2_TEST
  });
  test("lblPerLb3(Label Widget) Test Cases", async () => {
    const lblPerLb3 = screen.getByTestId("lblPerLb3");
    expect(lblPerLb3.tagName).toBe("LABEL");
    expect(lblPerLb3.classList).toContain("form-label");
    expect(lblPerLb3.textContent).toEqual(t("Settlements:FV95_lblPerLb3"));
  });
  test("Custom Test Cases for lblPerLb3", () => {
    // START_USER_CODE-USER_lblPerLb3_TEST
    // END_USER_CODE-USER_lblPerLb3_TEST
  });
  test("lblPerTon(Label Widget) Test Cases", async () => {
    const lblPerTon = screen.getByTestId("lblPerTon");
    expect(lblPerTon.tagName).toBe("LABEL");
    expect(lblPerTon.classList).toContain("form-label");
    expect(lblPerTon.textContent).toEqual(t("Settlements:FV95_lblPerTon"));
  });
  test("Custom Test Cases for lblPerTon", () => {
    // START_USER_CODE-USER_lblPerTon_TEST
    // END_USER_CODE-USER_lblPerTon_TEST
  });
  test("lblRemitTo(Label Widget) Test Cases", async () => {
    const lblRemitTo = screen.getByTestId("lblRemitTo");
    expect(lblRemitTo.tagName).toBe("LABEL");
    expect(lblRemitTo.classList).toContain("form-label");
    expect(lblRemitTo.textContent).toEqual(t("Settlements:FV95_lblRemitTo"));
  });
  test("Custom Test Cases for lblRemitTo", () => {
    // START_USER_CODE-USER_lblRemitTo_TEST
    // END_USER_CODE-USER_lblRemitTo_TEST
  });
  test("lblSC95(Label Widget) Test Cases", async () => {
    const lblSC95 = screen.getByTestId("lblSC95");
    expect(lblSC95.tagName).toBe("LABEL");
    expect(lblSC95.classList).toContain("form-label");
    expect(lblSC95.textContent).toEqual(t("Settlements:FV95_lblSC95"));
  });
  test("Custom Test Cases for lblSC95", () => {
    // START_USER_CODE-USER_lblSC95_TEST
    // END_USER_CODE-USER_lblSC95_TEST
  });
  test("lblSettleDate(Label Widget) Test Cases", async () => {
    const lblSettleDate = screen.getByTestId("lblSettleDate");
    expect(lblSettleDate.tagName).toBe("LABEL");
    expect(lblSettleDate.classList).toContain("form-label");
    expect(lblSettleDate.textContent).toEqual(
      t("Settlements:FV95_lblSettleDate")
    );
  });
  test("Custom Test Cases for lblSettleDate", () => {
    // START_USER_CODE-USER_lblSettleDate_TEST
    // END_USER_CODE-USER_lblSettleDate_TEST
  });
  test("lblSharePct(Label Widget) Test Cases", async () => {
    const lblSharePct = screen.getByTestId("lblSharePct");
    expect(lblSharePct.tagName).toBe("LABEL");
    expect(lblSharePct.classList).toContain("form-label");
    expect(lblSharePct.textContent).toEqual(t("Settlements:FV95_lblSharePct"));
  });
  test("Custom Test Cases for lblSharePct", () => {
    // START_USER_CODE-USER_lblSharePct_TEST
    // END_USER_CODE-USER_lblSharePct_TEST
  });
  test("lblTicketUploadMod(Label Widget) Test Cases", async () => {
    const lblTicketUploadMod = screen.getByTestId("lblTicketUploadMod");
    expect(lblTicketUploadMod.tagName).toBe("LABEL");
    expect(lblTicketUploadMod.classList).toContain("form-label");
    expect(lblTicketUploadMod.textContent).toEqual(
      t("Settlements:FV95_lblTicketUploadMod")
    );
  });
  test("Custom Test Cases for lblTicketUploadMod", () => {
    // START_USER_CODE-USER_lblTicketUploadMod_TEST
    // END_USER_CODE-USER_lblTicketUploadMod_TEST
  });
  test("lblUnloadedInfo(Label Widget) Test Cases", async () => {
    const lblUnloadedInfo = screen.getByTestId("lblUnloadedInfo");
    expect(lblUnloadedInfo.tagName).toBe("LABEL");
    expect(lblUnloadedInfo.classList).toContain("form-label");
    expect(lblUnloadedInfo.textContent).toEqual(
      t("Settlements:FV95_lblUnloadedInfo")
    );
  });
  test("Custom Test Cases for lblUnloadedInfo", () => {
    // START_USER_CODE-USER_lblUnloadedInfo_TEST
    // END_USER_CODE-USER_lblUnloadedInfo_TEST
  });
  test("lblValueCalculation(Label Widget) Test Cases", async () => {
    const lblValueCalculation = screen.getByTestId("lblValueCalculation");
    expect(lblValueCalculation.tagName).toBe("LABEL");
    expect(lblValueCalculation.classList).toContain("form-label");
    expect(lblValueCalculation.textContent).toEqual(
      t("Settlements:FV95_lblValueCalculation")
    );
  });
  test("Custom Test Cases for lblValueCalculation", () => {
    // START_USER_CODE-USER_lblValueCalculation_TEST
    // END_USER_CODE-USER_lblValueCalculation_TEST
  });
  test("lblVendor(Label Widget) Test Cases", async () => {
    const lblVendor = screen.getByTestId("lblVendor");
    expect(lblVendor.tagName).toBe("LABEL");
    expect(lblVendor.classList).toContain("form-label");
    expect(lblVendor.textContent).toEqual(t("Settlements:FV95_lblVendor"));
  });
  test("Custom Test Cases for lblVendor", () => {
    // START_USER_CODE-USER_lblVendor_TEST
    // END_USER_CODE-USER_lblVendor_TEST
  });
  test("lblVendor0(Label Widget) Test Cases", async () => {
    const lblVendor0 = screen.getByTestId("lblVendor0");
    expect(lblVendor0.tagName).toBe("LABEL");
    expect(lblVendor0.classList).toContain("form-label");
    expect(lblVendor0.textContent).toEqual(t("Settlements:FV95_lblVendor0"));
  });
  test("Custom Test Cases for lblVendor0", () => {
    // START_USER_CODE-USER_lblVendor0_TEST
    // END_USER_CODE-USER_lblVendor0_TEST
  });
  test("lblVendor1(Label Widget) Test Cases", async () => {
    const lblVendor1 = screen.getByTestId("lblVendor1");
    expect(lblVendor1.tagName).toBe("LABEL");
    expect(lblVendor1.classList).toContain("form-label");
    expect(lblVendor1.textContent).toEqual(t("Settlements:FV95_lblVendor1"));
  });
  test("Custom Test Cases for lblVendor1", () => {
    // START_USER_CODE-USER_lblVendor1_TEST
    // END_USER_CODE-USER_lblVendor1_TEST
  });
  test("lblVendor2(Label Widget) Test Cases", async () => {
    const lblVendor2 = screen.getByTestId("lblVendor2");
    expect(lblVendor2.tagName).toBe("LABEL");
    expect(lblVendor2.classList).toContain("form-label");
    expect(lblVendor2.textContent).toEqual(t("Settlements:FV95_lblVendor2"));
  });
  test("Custom Test Cases for lblVendor2", () => {
    // START_USER_CODE-USER_lblVendor2_TEST
    // END_USER_CODE-USER_lblVendor2_TEST
  });
  test("lblVendor3(Label Widget) Test Cases", async () => {
    const lblVendor3 = screen.getByTestId("lblVendor3");
    expect(lblVendor3.tagName).toBe("LABEL");
    expect(lblVendor3.classList).toContain("form-label");
    expect(lblVendor3.textContent).toEqual(t("Settlements:FV95_lblVendor3"));
  });
  test("Custom Test Cases for lblVendor3", () => {
    // START_USER_CODE-USER_lblVendor3_TEST
    // END_USER_CODE-USER_lblVendor3_TEST
  });
  test("lblVendorSplits(Label Widget) Test Cases", async () => {
    const lblVendorSplits = screen.getByTestId("lblVendorSplits");
    expect(lblVendorSplits.tagName).toBe("LABEL");
    expect(lblVendorSplits.classList).toContain("form-label");
    expect(lblVendorSplits.textContent).toEqual(
      t("Settlements:FV95_lblVendorSplits")
    );
  });
  test("Custom Test Cases for lblVendorSplits", () => {
    // START_USER_CODE-USER_lblVendorSplits_TEST
    // END_USER_CODE-USER_lblVendorSplits_TEST
  });
  test("lblVirginiaGrading(Label Widget) Test Cases", async () => {
    const lblVirginiaGrading = screen.getByTestId("lblVirginiaGrading");
    expect(lblVirginiaGrading.tagName).toBe("LABEL");
    expect(lblVirginiaGrading.classList).toContain("form-label");
    expect(lblVirginiaGrading.textContent).toEqual(
      t("Settlements:FV95_lblVirginiaGrading")
    );
  });
  test("Custom Test Cases for lblVirginiaGrading", () => {
    // START_USER_CODE-USER_lblVirginiaGrading_TEST
    // END_USER_CODE-USER_lblVirginiaGrading_TEST
  });
  test("lblWeightTickets(Label Widget) Test Cases", async () => {
    const lblWeightTickets = screen.getByTestId("lblWeightTickets");
    expect(lblWeightTickets.tagName).toBe("LABEL");
    expect(lblWeightTickets.classList).toContain("form-label");
    expect(lblWeightTickets.textContent).toEqual(
      t("Settlements:FV95_lblWeightTickets")
    );
  });
  test("Custom Test Cases for lblWeightTickets", () => {
    // START_USER_CODE-USER_lblWeightTickets_TEST
    // END_USER_CODE-USER_lblWeightTickets_TEST
  });
  test("txt1007(Textbox Widget) Test Cases", async () => {
    const txt1007 = screen.getByTestId("txt1007");
    expect(txt1007.tagName).toBe("INPUT");
    expect(txt1007.type).toBe("text");
    expect(txt1007.classList).toContain("textboxWidgetClass");
    expect(txt1007.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txt1007")
    );
    await act(async () => {
      userEvent.type(txt1007, "1");
    });
  });
  test("Custom Test Cases for txt1007", () => {
    // START_USER_CODE-USER_txt1007_TEST
    // END_USER_CODE-USER_txt1007_TEST
  });
  test("txtAccessFMLb(Textbox Widget) Test Cases", async () => {
    const txtAccessFMLb = screen.getByTestId("txtAccessFMLb");
    expect(txtAccessFMLb.tagName).toBe("INPUT");
    expect(txtAccessFMLb.type).toBe("text");
    expect(txtAccessFMLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAccessFMLb, "123");
    });
    expect(txtAccessFMLb.getAttribute("value")).toBe("");
    expect(txtAccessFMLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAccessFMLb", () => {
    // START_USER_CODE-USER_txtAccessFMLb_TEST
    // END_USER_CODE-USER_txtAccessFMLb_TEST
  });
  test("txtAccessFMTon(Textbox Widget) Test Cases", async () => {
    const txtAccessFMTon = screen.getByTestId("txtAccessFMTon");
    expect(txtAccessFMTon.tagName).toBe("INPUT");
    expect(txtAccessFMTon.type).toBe("text");
    expect(txtAccessFMTon.classList).toContain("textboxWidgetClass");
    expect(txtAccessFMTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtAccessFMTon")
    );
    await act(async () => {
      userEvent.type(txtAccessFMTon, "123");
    });
    expect(txtAccessFMTon.getAttribute("value")).toBe("");
    expect(txtAccessFMTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAccessFMTon", () => {
    // START_USER_CODE-USER_txtAccessFMTon_TEST
    // END_USER_CODE-USER_txtAccessFMTon_TEST
  });
  test("txtAFlavusLb(Textbox Widget) Test Cases", async () => {
    const txtAFlavusLb = screen.getByTestId("txtAFlavusLb");
    expect(txtAFlavusLb.tagName).toBe("INPUT");
    expect(txtAFlavusLb.type).toBe("text");
    expect(txtAFlavusLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAFlavusLb, "123");
    });
    expect(txtAFlavusLb.getAttribute("value")).toBe("");
    expect(txtAFlavusLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAFlavusLb", () => {
    // START_USER_CODE-USER_txtAFlavusLb_TEST
    // END_USER_CODE-USER_txtAFlavusLb_TEST
  });
  test("txtAFlavusTon(Textbox Widget) Test Cases", async () => {
    const txtAFlavusTon = screen.getByTestId("txtAFlavusTon");
    expect(txtAFlavusTon.tagName).toBe("INPUT");
    expect(txtAFlavusTon.type).toBe("text");
    expect(txtAFlavusTon.classList).toContain("textboxWidgetClass");
    expect(txtAFlavusTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtAFlavusTon")
    );
    await act(async () => {
      userEvent.type(txtAFlavusTon, "123");
    });
    expect(txtAFlavusTon.getAttribute("value")).toBe("");
    expect(txtAFlavusTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAFlavusTon", () => {
    // START_USER_CODE-USER_txtAFlavusTon_TEST
    // END_USER_CODE-USER_txtAFlavusTon_TEST
  });
  test("txtBluePanWt(Textbox Widget) Test Cases", async () => {
    const txtBluePanWt = screen.getByTestId("txtBluePanWt");
    expect(txtBluePanWt.tagName).toBe("INPUT");
    expect(txtBluePanWt.type).toBe("text");
    expect(txtBluePanWt.classList).toContain("textboxWidgetClass");
    expect(txtBluePanWt.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtBluePanWt")
    );
    await act(async () => {
      userEvent.type(txtBluePanWt, "1");
    });
  });
  test("Custom Test Cases for txtBluePanWt", () => {
    // START_USER_CODE-USER_txtBluePanWt_TEST
    // END_USER_CODE-USER_txtBluePanWt_TEST
  });
  test("txtCleanSampleWt(Textbox Widget) Test Cases", async () => {
    const txtCleanSampleWt = screen.getByTestId("txtCleanSampleWt");
    expect(txtCleanSampleWt.tagName).toBe("INPUT");
    expect(txtCleanSampleWt.type).toBe("text");
    expect(txtCleanSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtCleanSampleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtCleanSampleWt")
    );
    await act(async () => {
      userEvent.type(txtCleanSampleWt, "1");
    });
  });
  test("Custom Test Cases for txtCleanSampleWt", () => {
    // START_USER_CODE-USER_txtCleanSampleWt_TEST
    // END_USER_CODE-USER_txtCleanSampleWt_TEST
  });
  test("txtCMAVendor(Textbox Widget) Test Cases", async () => {
    const txtCMAVendor = screen.getByTestId("txtCMAVendor");
    expect(txtCMAVendor.tagName).toBe("INPUT");
    expect(txtCMAVendor.type).toBe("text");
    expect(txtCMAVendor.classList).toContain("textboxWidgetClass");
    expect(txtCMAVendor.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtCMAVendor")
    );
    await act(async () => {
      userEvent.type(txtCMAVendor, "1");
    });
  });
  test("Custom Test Cases for txtCMAVendor", () => {
    // START_USER_CODE-USER_txtCMAVendor_TEST
    // END_USER_CODE-USER_txtCMAVendor_TEST
  });
  test("gridWeightTickets_txtcolCCTicket(Grid Widget) Test Cases", async () => {
    let gridWeightTickets_txtcolCCTicket = screen.getByTestId(
      "gridWeightTickets"
    );
    let gridWeightTickets_txtcolCCTicketbtn =
      gridWeightTickets_txtcolCCTicket.nextElementSibling.firstElementChild;
    gridWeightTickets_txtcolCCTicket =
      gridWeightTickets_txtcolCCTicket.parentElement.parentElement
        .parentElement;
    expect(gridWeightTickets_txtcolCCTicket.tagName).toBe("DIV");
    expect(gridWeightTickets_txtcolCCTicket.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95_gridWeightTickets")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCCTicket", () => {
    // START_USER_CODE-USER_txtcolCCTicket_TEST
    // END_USER_CODE-USER_txtcolCCTicket_TEST
  });
  test("gridWeightTickets_txtcolDate(Grid Widget) Test Cases", async () => {
    let gridWeightTickets_txtcolDate = screen.getByTestId("gridWeightTickets");
    let gridWeightTickets_txtcolDatebtn =
      gridWeightTickets_txtcolDate.nextElementSibling.firstElementChild;
    gridWeightTickets_txtcolDate =
      gridWeightTickets_txtcolDate.parentElement.parentElement.parentElement;
    expect(gridWeightTickets_txtcolDate.tagName).toBe("DIV");
    expect(gridWeightTickets_txtcolDate.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("Settlements:FV95_gridWeightTickets")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDate", () => {
    // START_USER_CODE-USER_txtcolDate_TEST
    // END_USER_CODE-USER_txtcolDate_TEST
  });
  test("gridWeightTickets_txtcolWeightTicket(Grid Widget) Test Cases", async () => {
    let gridWeightTickets_txtcolWeightTicket = screen.getByTestId(
      "gridWeightTickets"
    );
    let gridWeightTickets_txtcolWeightTicketbtn =
      gridWeightTickets_txtcolWeightTicket.nextElementSibling.firstElementChild;
    gridWeightTickets_txtcolWeightTicket =
      gridWeightTickets_txtcolWeightTicket.parentElement.parentElement
        .parentElement;
    expect(gridWeightTickets_txtcolWeightTicket.tagName).toBe("DIV");
    expect(gridWeightTickets_txtcolWeightTicket.classList).toContain(
      "GridWidget"
    );
    expect(screen.getAllByText(t("Settlements:FV95_gridWeightTickets")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWeightTicket", () => {
    // START_USER_CODE-USER_txtcolWeightTicket_TEST
    // END_USER_CODE-USER_txtcolWeightTicket_TEST
  });
  test("gridWeightTickets_txtcolWtOfPeanuts(Grid Widget) Test Cases", async () => {
    let gridWeightTickets_txtcolWtOfPeanuts = screen.getByTestId(
      "gridWeightTickets"
    );
    let gridWeightTickets_txtcolWtOfPeanutsbtn =
      gridWeightTickets_txtcolWtOfPeanuts.nextElementSibling.firstElementChild;
    gridWeightTickets_txtcolWtOfPeanuts =
      gridWeightTickets_txtcolWtOfPeanuts.parentElement.parentElement
        .parentElement;
    expect(gridWeightTickets_txtcolWtOfPeanuts.tagName).toBe("DIV");
    expect(gridWeightTickets_txtcolWtOfPeanuts.classList).toContain(
      "GridWidget"
    );
    expect(screen.getAllByText(t("Settlements:FV95_gridWeightTickets")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWtOfPeanuts", () => {
    // START_USER_CODE-USER_txtcolWtOfPeanuts_TEST
    // END_USER_CODE-USER_txtcolWtOfPeanuts_TEST
  });
  test("txtConcealedRMDGr(Textbox Widget) Test Cases", async () => {
    const txtConcealedRMDGr = screen.getByTestId("txtConcealedRMDGr");
    expect(txtConcealedRMDGr.tagName).toBe("INPUT");
    expect(txtConcealedRMDGr.type).toBe("text");
    expect(txtConcealedRMDGr.classList).toContain("textboxWidgetClass");
    expect(txtConcealedRMDGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtConcealedRMDGr")
    );
    await act(async () => {
      userEvent.type(txtConcealedRMDGr, "1");
    });
  });
  test("Custom Test Cases for txtConcealedRMDGr", () => {
    // START_USER_CODE-USER_txtConcealedRMDGr_TEST
    // END_USER_CODE-USER_txtConcealedRMDGr_TEST
  });
  test("txtConcealedRMDPct(Textbox Widget) Test Cases", async () => {
    const txtConcealedRMDPct = screen.getByTestId("txtConcealedRMDPct");
    expect(txtConcealedRMDPct.tagName).toBe("INPUT");
    expect(txtConcealedRMDPct.type).toBe("text");
    expect(txtConcealedRMDPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtConcealedRMDPct, "1");
    });
  });
  test("Custom Test Cases for txtConcealedRMDPct", () => {
    // START_USER_CODE-USER_txtConcealedRMDPct_TEST
    // END_USER_CODE-USER_txtConcealedRMDPct_TEST
  });
  test("txtCrkOrBrkShellsGr(Textbox Widget) Test Cases", async () => {
    const txtCrkOrBrkShellsGr = screen.getByTestId("txtCrkOrBrkShellsGr");
    expect(txtCrkOrBrkShellsGr.tagName).toBe("INPUT");
    expect(txtCrkOrBrkShellsGr.type).toBe("text");
    expect(txtCrkOrBrkShellsGr.classList).toContain("textboxWidgetClass");
    expect(txtCrkOrBrkShellsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtCrkOrBrkShellsGr")
    );
    await act(async () => {
      userEvent.type(txtCrkOrBrkShellsGr, "1");
    });
  });
  test("Custom Test Cases for txtCrkOrBrkShellsGr", () => {
    // START_USER_CODE-USER_txtCrkOrBrkShellsGr_TEST
    // END_USER_CODE-USER_txtCrkOrBrkShellsGr_TEST
  });
  test("txtCrkOrBrkShellsPct(Textbox Widget) Test Cases", async () => {
    const txtCrkOrBrkShellsPct = screen.getByTestId("txtCrkOrBrkShellsPct");
    expect(txtCrkOrBrkShellsPct.tagName).toBe("INPUT");
    expect(txtCrkOrBrkShellsPct.type).toBe("text");
    expect(txtCrkOrBrkShellsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCrkOrBrkShellsPct, "1");
    });
  });
  test("Custom Test Cases for txtCrkOrBrkShellsPct", () => {
    // START_USER_CODE-USER_txtCrkOrBrkShellsPct_TEST
    // END_USER_CODE-USER_txtCrkOrBrkShellsPct_TEST
  });
  test("txtCRMD(Textbox Widget) Test Cases", async () => {
    const txtCRMD = screen.getByTestId("txtCRMD");
    expect(txtCRMD.tagName).toBe("INPUT");
    expect(txtCRMD.type).toBe("text");
    expect(txtCRMD.classList).toContain("textboxWidgetClass");
    expect(txtCRMD.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtCRMD")
    );
    await act(async () => {
      userEvent.type(txtCRMD, "1");
    });
  });
  test("Custom Test Cases for txtCRMD", () => {
    // START_USER_CODE-USER_txtCRMD_TEST
    // END_USER_CODE-USER_txtCRMD_TEST
  });
  test("txtDAM(Textbox Widget) Test Cases", async () => {
    const txtDAM = screen.getByTestId("txtDAM");
    expect(txtDAM.tagName).toBe("INPUT");
    expect(txtDAM.type).toBe("text");
    expect(txtDAM.classList).toContain("textboxWidgetClass");
    expect(txtDAM.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtDAM")
    );
    await act(async () => {
      userEvent.type(txtDAM, "1");
    });
  });
  test("Custom Test Cases for txtDAM", () => {
    // START_USER_CODE-USER_txtDAM_TEST
    // END_USER_CODE-USER_txtDAM_TEST
  });
  test("txtDamagedKRS(Textbox Widget) Test Cases", async () => {
    const txtDamagedKRS = screen.getByTestId("txtDamagedKRS");
    expect(txtDamagedKRS.tagName).toBe("INPUT");
    expect(txtDamagedKRS.type).toBe("text");
    expect(txtDamagedKRS.classList).toContain("textboxWidgetClass");
    expect(txtDamagedKRS.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtDamagedKRS")
    );
    await act(async () => {
      userEvent.type(txtDamagedKRS, "1");
    });
  });
  test("Custom Test Cases for txtDamagedKRS", () => {
    // START_USER_CODE-USER_txtDamagedKRS_TEST
    // END_USER_CODE-USER_txtDamagedKRS_TEST
  });
  test("txtDamageLbs(Textbox Widget) Test Cases", async () => {
    const txtDamageLbs = screen.getByTestId("txtDamageLbs");
    expect(txtDamageLbs.tagName).toBe("INPUT");
    expect(txtDamageLbs.type).toBe("text");
    expect(txtDamageLbs.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDamageLbs, "123");
    });
    expect(txtDamageLbs.getAttribute("value")).toBe("");
    expect(txtDamageLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDamageLbs", () => {
    // START_USER_CODE-USER_txtDamageLbs_TEST
    // END_USER_CODE-USER_txtDamageLbs_TEST
  });
  test("txtDamageSplits(Textbox Widget) Test Cases", async () => {
    const txtDamageSplits = screen.getByTestId("txtDamageSplits");
    expect(txtDamageSplits.tagName).toBe("INPUT");
    expect(txtDamageSplits.type).toBe("text");
    expect(txtDamageSplits.classList).toContain("textboxWidgetClass");
    expect(txtDamageSplits.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtDamageSplits")
    );
    await act(async () => {
      userEvent.type(txtDamageSplits, "1");
    });
  });
  test("Custom Test Cases for txtDamageSplits", () => {
    // START_USER_CODE-USER_txtDamageSplits_TEST
    // END_USER_CODE-USER_txtDamageSplits_TEST
  });
  test("txtDamageTon(Textbox Widget) Test Cases", async () => {
    const txtDamageTon = screen.getByTestId("txtDamageTon");
    expect(txtDamageTon.tagName).toBe("INPUT");
    expect(txtDamageTon.type).toBe("text");
    expect(txtDamageTon.classList).toContain("textboxWidgetClass");
    expect(txtDamageTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtDamageTon")
    );
    await act(async () => {
      userEvent.type(txtDamageTon, "123");
    });
    expect(txtDamageTon.getAttribute("value")).toBe("");
    expect(txtDamageTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDamageTon", () => {
    // START_USER_CODE-USER_txtDamageTon_TEST
    // END_USER_CODE-USER_txtDamageTon_TEST
  });
  test("txtDiscoloredShellsGr(Textbox Widget) Test Cases", async () => {
    const txtDiscoloredShellsGr = screen.getByTestId("txtDiscoloredShellsGr");
    expect(txtDiscoloredShellsGr.tagName).toBe("INPUT");
    expect(txtDiscoloredShellsGr.type).toBe("text");
    expect(txtDiscoloredShellsGr.classList).toContain("textboxWidgetClass");
    expect(txtDiscoloredShellsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtDiscoloredShellsGr")
    );
    await act(async () => {
      userEvent.type(txtDiscoloredShellsGr, "1");
    });
  });
  test("Custom Test Cases for txtDiscoloredShellsGr", () => {
    // START_USER_CODE-USER_txtDiscoloredShellsGr_TEST
    // END_USER_CODE-USER_txtDiscoloredShellsGr_TEST
  });
  test("txtDiscoloredShellsPct(Textbox Widget) Test Cases", async () => {
    const txtDiscoloredShellsPct = screen.getByTestId("txtDiscoloredShellsPct");
    expect(txtDiscoloredShellsPct.tagName).toBe("INPUT");
    expect(txtDiscoloredShellsPct.type).toBe("text");
    expect(txtDiscoloredShellsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDiscoloredShellsPct, "1");
    });
  });
  test("Custom Test Cases for txtDiscoloredShellsPct", () => {
    // START_USER_CODE-USER_txtDiscoloredShellsPct_TEST
    // END_USER_CODE-USER_txtDiscoloredShellsPct_TEST
  });
  test("txtELK(Textbox Widget) Test Cases", async () => {
    const txtELK = screen.getByTestId("txtELK");
    expect(txtELK.tagName).toBe("INPUT");
    expect(txtELK.type).toBe("text");
    expect(txtELK.classList).toContain("textboxWidgetClass");
    expect(txtELK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtELK")
    );
    await act(async () => {
      userEvent.type(txtELK, "1");
    });
  });
  test("Custom Test Cases for txtELK", () => {
    // START_USER_CODE-USER_txtELK_TEST
    // END_USER_CODE-USER_txtELK_TEST
  });
  test("txtELKDamage(Textbox Widget) Test Cases", async () => {
    const txtELKDamage = screen.getByTestId("txtELKDamage");
    expect(txtELKDamage.tagName).toBe("INPUT");
    expect(txtELKDamage.type).toBe("text");
    expect(txtELKDamage.classList).toContain("textboxWidgetClass");
    expect(txtELKDamage.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtELKDamage")
    );
    await act(async () => {
      userEvent.type(txtELKDamage, "1");
    });
  });
  test("Custom Test Cases for txtELKDamage", () => {
    // START_USER_CODE-USER_txtELKDamage_TEST
    // END_USER_CODE-USER_txtELKDamage_TEST
  });
  test("txtELKGr(Textbox Widget) Test Cases", async () => {
    const txtELKGr = screen.getByTestId("txtELKGr");
    expect(txtELKGr.tagName).toBe("INPUT");
    expect(txtELKGr.type).toBe("text");
    expect(txtELKGr.classList).toContain("textboxWidgetClass");
    expect(txtELKGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtELKGr")
    );
    await act(async () => {
      userEvent.type(txtELKGr, "1");
    });
  });
  test("Custom Test Cases for txtELKGr", () => {
    // START_USER_CODE-USER_txtELKGr_TEST
    // END_USER_CODE-USER_txtELKGr_TEST
  });
  test("txtELKPct(Textbox Widget) Test Cases", async () => {
    const txtELKPct = screen.getByTestId("txtELKPct");
    expect(txtELKPct.tagName).toBe("INPUT");
    expect(txtELKPct.type).toBe("text");
    expect(txtELKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtELKPct, "1");
    });
  });
  test("Custom Test Cases for txtELKPct", () => {
    // START_USER_CODE-USER_txtELKPct_TEST
    // END_USER_CODE-USER_txtELKPct_TEST
  });
  test("txtELKPremiumLb(Textbox Widget) Test Cases", async () => {
    const txtELKPremiumLb = screen.getByTestId("txtELKPremiumLb");
    expect(txtELKPremiumLb.tagName).toBe("INPUT");
    expect(txtELKPremiumLb.type).toBe("text");
    expect(txtELKPremiumLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtELKPremiumLb, "123");
    });
    expect(txtELKPremiumLb.getAttribute("value")).toBe("");
    expect(txtELKPremiumLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtELKPremiumLb", () => {
    // START_USER_CODE-USER_txtELKPremiumLb_TEST
    // END_USER_CODE-USER_txtELKPremiumLb_TEST
  });
  test("txtELKPremiumTon(Textbox Widget) Test Cases", async () => {
    const txtELKPremiumTon = screen.getByTestId("txtELKPremiumTon");
    expect(txtELKPremiumTon.tagName).toBe("INPUT");
    expect(txtELKPremiumTon.type).toBe("text");
    expect(txtELKPremiumTon.classList).toContain("textboxWidgetClass");
    expect(txtELKPremiumTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtELKPremiumTon")
    );
    await act(async () => {
      userEvent.type(txtELKPremiumTon, "123");
    });
    expect(txtELKPremiumTon.getAttribute("value")).toBe("");
    expect(txtELKPremiumTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtELKPremiumTon", () => {
    // START_USER_CODE-USER_txtELKPremiumTon_TEST
    // END_USER_CODE-USER_txtELKPremiumTon_TEST
  });
  test("txtEVehicleNum(Textbox Widget) Test Cases", async () => {
    const txtEVehicleNum = screen.getByTestId("txtEVehicleNum");
    expect(txtEVehicleNum.tagName).toBe("INPUT");
    expect(txtEVehicleNum.type).toBe("text");
    expect(txtEVehicleNum.classList).toContain("textboxWidgetClass");
    expect(txtEVehicleNum.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtEVehicleNum")
    );
    await act(async () => {
      userEvent.type(txtEVehicleNum, "123");
    });
    expect(txtEVehicleNum.getAttribute("value")).toBe("");
    expect(txtEVehicleNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEVehicleNum", () => {
    // START_USER_CODE-USER_txtEVehicleNum_TEST
    // END_USER_CODE-USER_txtEVehicleNum_TEST
  });
  test("txtEWeightTkt(Textbox Widget) Test Cases", async () => {
    const txtEWeightTkt = screen.getByTestId("txtEWeightTkt");
    expect(txtEWeightTkt.tagName).toBe("INPUT");
    expect(txtEWeightTkt.type).toBe("text");
    expect(txtEWeightTkt.classList).toContain("textboxWidgetClass");
    expect(txtEWeightTkt.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtEWeightTkt")
    );
    await act(async () => {
      userEvent.type(txtEWeightTkt, "123");
    });
    expect(txtEWeightTkt.getAttribute("value")).toBe("");
    expect(txtEWeightTkt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEWeightTkt", () => {
    // START_USER_CODE-USER_txtEWeightTkt_TEST
    // END_USER_CODE-USER_txtEWeightTkt_TEST
  });
  test("txtEWtInclVeh(Textbox Widget) Test Cases", async () => {
    const txtEWtInclVeh = screen.getByTestId("txtEWtInclVeh");
    expect(txtEWtInclVeh.tagName).toBe("INPUT");
    expect(txtEWtInclVeh.type).toBe("text");
    expect(txtEWtInclVeh.classList).toContain("textboxWidgetClass");
    expect(txtEWtInclVeh.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtEWtInclVeh")
    );
    await act(async () => {
      userEvent.type(txtEWtInclVeh, "123");
    });
    expect(txtEWtInclVeh.getAttribute("value")).toBe("");
    expect(txtEWtInclVeh.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEWtInclVeh", () => {
    // START_USER_CODE-USER_txtEWtInclVeh_TEST
    // END_USER_CODE-USER_txtEWtInclVeh_TEST
  });
  test("txtEWtOfPnut(Textbox Widget) Test Cases", async () => {
    const txtEWtOfPnut = screen.getByTestId("txtEWtOfPnut");
    expect(txtEWtOfPnut.tagName).toBe("INPUT");
    expect(txtEWtOfPnut.type).toBe("text");
    expect(txtEWtOfPnut.classList).toContain("textboxWidgetClass");
    expect(txtEWtOfPnut.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtEWtOfPnut")
    );
    await act(async () => {
      userEvent.type(txtEWtOfPnut, "123");
    });
    expect(txtEWtOfPnut.getAttribute("value")).toBe("");
    expect(txtEWtOfPnut.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEWtOfPnut", () => {
    // START_USER_CODE-USER_txtEWtOfPnut_TEST
    // END_USER_CODE-USER_txtEWtOfPnut_TEST
  });
  test("txtEWtOfVeh(Textbox Widget) Test Cases", async () => {
    const txtEWtOfVeh = screen.getByTestId("txtEWtOfVeh");
    expect(txtEWtOfVeh.tagName).toBe("INPUT");
    expect(txtEWtOfVeh.type).toBe("text");
    expect(txtEWtOfVeh.classList).toContain("textboxWidgetClass");
    expect(txtEWtOfVeh.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtEWtOfVeh")
    );
    await act(async () => {
      userEvent.type(txtEWtOfVeh, "123");
    });
    expect(txtEWtOfVeh.getAttribute("value")).toBe("");
    expect(txtEWtOfVeh.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEWtOfVeh", () => {
    // START_USER_CODE-USER_txtEWtOfVeh_TEST
    // END_USER_CODE-USER_txtEWtOfVeh_TEST
  });
  test("txtExcessMoisture(Textbox Widget) Test Cases", async () => {
    const txtExcessMoisture = screen.getByTestId("txtExcessMoisture");
    expect(txtExcessMoisture.tagName).toBe("INPUT");
    expect(txtExcessMoisture.type).toBe("text");
    expect(txtExcessMoisture.classList).toContain("textboxWidgetClass");
    expect(txtExcessMoisture.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtExcessMoisture")
    );
    await act(async () => {
      userEvent.type(txtExcessMoisture, "123");
    });
    expect(txtExcessMoisture.getAttribute("value")).toBe("");
    expect(txtExcessMoisture.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtExcessMoisture", () => {
    // START_USER_CODE-USER_txtExcessMoisture_TEST
    // END_USER_CODE-USER_txtExcessMoisture_TEST
  });
  test("txtExcessSplitsLb(Textbox Widget) Test Cases", async () => {
    const txtExcessSplitsLb = screen.getByTestId("txtExcessSplitsLb");
    expect(txtExcessSplitsLb.tagName).toBe("INPUT");
    expect(txtExcessSplitsLb.type).toBe("text");
    expect(txtExcessSplitsLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtExcessSplitsLb, "123");
    });
    expect(txtExcessSplitsLb.getAttribute("value")).toBe("");
    expect(txtExcessSplitsLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtExcessSplitsLb", () => {
    // START_USER_CODE-USER_txtExcessSplitsLb_TEST
    // END_USER_CODE-USER_txtExcessSplitsLb_TEST
  });
  test("txtExcessSplitsTon(Textbox Widget) Test Cases", async () => {
    const txtExcessSplitsTon = screen.getByTestId("txtExcessSplitsTon");
    expect(txtExcessSplitsTon.tagName).toBe("INPUT");
    expect(txtExcessSplitsTon.type).toBe("text");
    expect(txtExcessSplitsTon.classList).toContain("textboxWidgetClass");
    expect(txtExcessSplitsTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtExcessSplitsTon")
    );
    await act(async () => {
      userEvent.type(txtExcessSplitsTon, "123");
    });
    expect(txtExcessSplitsTon.getAttribute("value")).toBe("");
    expect(txtExcessSplitsTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtExcessSplitsTon", () => {
    // START_USER_CODE-USER_txtExcessSplitsTon_TEST
    // END_USER_CODE-USER_txtExcessSplitsTon_TEST
  });
  test("txtFANCY(Textbox Widget) Test Cases", async () => {
    const txtFANCY = screen.getByTestId("txtFANCY");
    expect(txtFANCY.tagName).toBe("INPUT");
    expect(txtFANCY.type).toBe("text");
    expect(txtFANCY.classList).toContain("textboxWidgetClass");
    expect(txtFANCY.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtFANCY")
    );
    await act(async () => {
      userEvent.type(txtFANCY, "1");
    });
  });
  test("Custom Test Cases for txtFANCY", () => {
    // START_USER_CODE-USER_txtFANCY_TEST
    // END_USER_CODE-USER_txtFANCY_TEST
  });
  test("txtFancyGr(Textbox Widget) Test Cases", async () => {
    const txtFancyGr = screen.getByTestId("txtFancyGr");
    expect(txtFancyGr.tagName).toBe("INPUT");
    expect(txtFancyGr.type).toBe("text");
    expect(txtFancyGr.classList).toContain("textboxWidgetClass");
    expect(txtFancyGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtFancyGr")
    );
    await act(async () => {
      userEvent.type(txtFancyGr, "1");
    });
  });
  test("Custom Test Cases for txtFancyGr", () => {
    // START_USER_CODE-USER_txtFancyGr_TEST
    // END_USER_CODE-USER_txtFancyGr_TEST
  });
  test("txtFancyPct(Textbox Widget) Test Cases", async () => {
    const txtFancyPct = screen.getByTestId("txtFancyPct");
    expect(txtFancyPct.tagName).toBe("INPUT");
    expect(txtFancyPct.type).toBe("text");
    expect(txtFancyPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFancyPct, "1");
    });
  });
  test("Custom Test Cases for txtFancyPct", () => {
    // START_USER_CODE-USER_txtFancyPct_TEST
    // END_USER_CODE-USER_txtFancyPct_TEST
  });
  test("txtFM(Textbox Widget) Test Cases", async () => {
    const txtFM = screen.getByTestId("txtFM");
    expect(txtFM.tagName).toBe("INPUT");
    expect(txtFM.type).toBe("text");
    expect(txtFM.classList).toContain("textboxWidgetClass");
    expect(txtFM.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtFM")
    );
    await act(async () => {
      userEvent.type(txtFM, "1");
    });
  });
  test("Custom Test Cases for txtFM", () => {
    // START_USER_CODE-USER_txtFM_TEST
    // END_USER_CODE-USER_txtFM_TEST
  });
  test("txtFMSampleWt(Textbox Widget) Test Cases", async () => {
    const txtFMSampleWt = screen.getByTestId("txtFMSampleWt");
    expect(txtFMSampleWt.tagName).toBe("INPUT");
    expect(txtFMSampleWt.type).toBe("text");
    expect(txtFMSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtFMSampleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtFMSampleWt")
    );
    await act(async () => {
      userEvent.type(txtFMSampleWt, "1");
    });
  });
  test("Custom Test Cases for txtFMSampleWt", () => {
    // START_USER_CODE-USER_txtFMSampleWt_TEST
    // END_USER_CODE-USER_txtFMSampleWt_TEST
  });
  test("txtForeignMaterial(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterial = screen.getByTestId("txtForeignMaterial");
    expect(txtForeignMaterial.tagName).toBe("INPUT");
    expect(txtForeignMaterial.type).toBe("text");
    expect(txtForeignMaterial.classList).toContain("textboxWidgetClass");
    expect(txtForeignMaterial.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtForeignMaterial")
    );
    await act(async () => {
      userEvent.type(txtForeignMaterial, "123");
    });
    expect(txtForeignMaterial.getAttribute("value")).toBe("");
    expect(txtForeignMaterial.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtForeignMaterial", () => {
    // START_USER_CODE-USER_txtForeignMaterial_TEST
    // END_USER_CODE-USER_txtForeignMaterial_TEST
  });
  test("txtForeignMaterialGr(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterialGr = screen.getByTestId("txtForeignMaterialGr");
    expect(txtForeignMaterialGr.tagName).toBe("INPUT");
    expect(txtForeignMaterialGr.type).toBe("text");
    expect(txtForeignMaterialGr.classList).toContain("textboxWidgetClass");
    expect(txtForeignMaterialGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtForeignMaterialGr")
    );
    await act(async () => {
      userEvent.type(txtForeignMaterialGr, "1");
    });
  });
  test("Custom Test Cases for txtForeignMaterialGr", () => {
    // START_USER_CODE-USER_txtForeignMaterialGr_TEST
    // END_USER_CODE-USER_txtForeignMaterialGr_TEST
  });
  test("txtForeignMaterialPct(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterialPct = screen.getByTestId("txtForeignMaterialPct");
    expect(txtForeignMaterialPct.tagName).toBe("INPUT");
    expect(txtForeignMaterialPct.type).toBe("text");
    expect(txtForeignMaterialPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtForeignMaterialPct, "1");
    });
  });
  test("Custom Test Cases for txtForeignMaterialPct", () => {
    // START_USER_CODE-USER_txtForeignMaterialPct_TEST
    // END_USER_CODE-USER_txtForeignMaterialPct_TEST
  });
  test("txtFreezeDamageGr(Textbox Widget) Test Cases", async () => {
    const txtFreezeDamageGr = screen.getByTestId("txtFreezeDamageGr");
    expect(txtFreezeDamageGr.tagName).toBe("INPUT");
    expect(txtFreezeDamageGr.type).toBe("text");
    expect(txtFreezeDamageGr.classList).toContain("textboxWidgetClass");
    expect(txtFreezeDamageGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtFreezeDamageGr")
    );
    await act(async () => {
      userEvent.type(txtFreezeDamageGr, "1");
    });
  });
  test("Custom Test Cases for txtFreezeDamageGr", () => {
    // START_USER_CODE-USER_txtFreezeDamageGr_TEST
    // END_USER_CODE-USER_txtFreezeDamageGr_TEST
  });
  test("txtFreezeDamagePct(Textbox Widget) Test Cases", async () => {
    const txtFreezeDamagePct = screen.getByTestId("txtFreezeDamagePct");
    expect(txtFreezeDamagePct.tagName).toBe("INPUT");
    expect(txtFreezeDamagePct.type).toBe("text");
    expect(txtFreezeDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFreezeDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtFreezeDamagePct", () => {
    // START_USER_CODE-USER_txtFreezeDamagePct_TEST
    // END_USER_CODE-USER_txtFreezeDamagePct_TEST
  });
  test("txtFRZ(Textbox Widget) Test Cases", async () => {
    const txtFRZ = screen.getByTestId("txtFRZ");
    expect(txtFRZ.tagName).toBe("INPUT");
    expect(txtFRZ.type).toBe("text");
    expect(txtFRZ.classList).toContain("textboxWidgetClass");
    expect(txtFRZ.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtFRZ")
    );
    await act(async () => {
      userEvent.type(txtFRZ, "1");
    });
  });
  test("Custom Test Cases for txtFRZ", () => {
    // START_USER_CODE-USER_txtFRZ_TEST
    // END_USER_CODE-USER_txtFRZ_TEST
  });
  test("txtG(Textbox Widget) Test Cases", async () => {
    const txtG = screen.getByTestId("txtG");
    expect(txtG.tagName).toBe("INPUT");
    expect(txtG.type).toBe("text");
    expect(txtG.classList).toContain("textboxWidgetClass");
    expect(txtG.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtG")
    );
    await act(async () => {
      userEvent.type(txtG, "123");
    });
    expect(txtG.getAttribute("value")).toBe("");
    expect(txtG.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtG", () => {
    // START_USER_CODE-USER_txtG_TEST
    // END_USER_CODE-USER_txtG_TEST
  });
  test("txtGrams(Textbox Widget) Test Cases", async () => {
    const txtGrams = screen.getByTestId("txtGrams");
    expect(txtGrams.tagName).toBe("INPUT");
    expect(txtGrams.type).toBe("text");
    expect(txtGrams.classList).toContain("textboxWidgetClass");
    expect(txtGrams.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtGrams")
    );
    await act(async () => {
      userEvent.type(txtGrams, "1");
    });
  });
  test("Custom Test Cases for txtGrams", () => {
    // START_USER_CODE-USER_txtGrams_TEST
    // END_USER_CODE-USER_txtGrams_TEST
  });
  test("txtGrossWeight(Textbox Widget) Test Cases", async () => {
    const txtGrossWeight = screen.getByTestId("txtGrossWeight");
    expect(txtGrossWeight.tagName).toBe("INPUT");
    expect(txtGrossWeight.type).toBe("text");
    expect(txtGrossWeight.classList).toContain("textboxWidgetClass");
    expect(txtGrossWeight.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtGrossWeight")
    );
    await act(async () => {
      userEvent.type(txtGrossWeight, "123");
    });
    expect(txtGrossWeight.getAttribute("value")).toBe("");
    expect(txtGrossWeight.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtGrossWeight", () => {
    // START_USER_CODE-USER_txtGrossWeight_TEST
    // END_USER_CODE-USER_txtGrossWeight_TEST
  });
  test("txtH(Textbox Widget) Test Cases", async () => {
    const txtH = screen.getByTestId("txtH");
    expect(txtH.tagName).toBe("INPUT");
    expect(txtH.type).toBe("text");
    expect(txtH.classList).toContain("textboxWidgetClass");
    expect(txtH.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtH")
    );
    await act(async () => {
      userEvent.type(txtH, "123");
    });
    expect(txtH.getAttribute("value")).toBe("");
    expect(txtH.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtH", () => {
    // START_USER_CODE-USER_txtH_TEST
    // END_USER_CODE-USER_txtH_TEST
  });
  test("txtHullBrightness(Textbox Widget) Test Cases", async () => {
    const txtHullBrightness = screen.getByTestId("txtHullBrightness");
    expect(txtHullBrightness.tagName).toBe("INPUT");
    expect(txtHullBrightness.type).toBe("text");
    expect(txtHullBrightness.classList).toContain("textboxWidgetClass");
    expect(txtHullBrightness.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtHullBrightness")
    );
    await act(async () => {
      userEvent.type(txtHullBrightness, "1");
    });
  });
  test("Custom Test Cases for txtHullBrightness", () => {
    // START_USER_CODE-USER_txtHullBrightness_TEST
    // END_USER_CODE-USER_txtHullBrightness_TEST
  });
  test("txtHullBrightnessPct(Textbox Widget) Test Cases", async () => {
    const txtHullBrightnessPct = screen.getByTestId("txtHullBrightnessPct");
    expect(txtHullBrightnessPct.tagName).toBe("INPUT");
    expect(txtHullBrightnessPct.type).toBe("text");
    expect(txtHullBrightnessPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullBrightnessPct, "1");
    });
  });
  test("Custom Test Cases for txtHullBrightnessPct", () => {
    // START_USER_CODE-USER_txtHullBrightnessPct_TEST
    // END_USER_CODE-USER_txtHullBrightnessPct_TEST
  });
  test("txtHULLS(Textbox Widget) Test Cases", async () => {
    const txtHULLS = screen.getByTestId("txtHULLS");
    expect(txtHULLS.tagName).toBe("INPUT");
    expect(txtHULLS.type).toBe("text");
    expect(txtHULLS.classList).toContain("textboxWidgetClass");
    expect(txtHULLS.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtHULLS")
    );
    await act(async () => {
      userEvent.type(txtHULLS, "1");
    });
  });
  test("Custom Test Cases for txtHULLS", () => {
    // START_USER_CODE-USER_txtHULLS_TEST
    // END_USER_CODE-USER_txtHULLS_TEST
  });
  test("txtHullsGr(Textbox Widget) Test Cases", async () => {
    const txtHullsGr = screen.getByTestId("txtHullsGr");
    expect(txtHullsGr.tagName).toBe("INPUT");
    expect(txtHullsGr.type).toBe("text");
    expect(txtHullsGr.classList).toContain("textboxWidgetClass");
    expect(txtHullsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtHullsGr")
    );
    await act(async () => {
      userEvent.type(txtHullsGr, "1");
    });
  });
  test("Custom Test Cases for txtHullsGr", () => {
    // START_USER_CODE-USER_txtHullsGr_TEST
    // END_USER_CODE-USER_txtHullsGr_TEST
  });
  test("txtHullsPct(Textbox Widget) Test Cases", async () => {
    const txtHullsPct = screen.getByTestId("txtHullsPct");
    expect(txtHullsPct.tagName).toBe("INPUT");
    expect(txtHullsPct.type).toBe("text");
    expect(txtHullsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullsPct, "1");
    });
  });
  test("Custom Test Cases for txtHullsPct", () => {
    // START_USER_CODE-USER_txtHullsPct_TEST
    // END_USER_CODE-USER_txtHullsPct_TEST
  });
  test("txtInspectionDateTime(Textbox Widget) Test Cases", async () => {
    const txtInspectionDateTime = screen.getByTestId("txtInspectionDateTime");
    expect(txtInspectionDateTime.tagName).toBe("INPUT");
    expect(txtInspectionDateTime.type).toBe("text");
    expect(txtInspectionDateTime.classList).toContain("textboxWidgetClass");
    expect(txtInspectionDateTime.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtInspectionDateTime")
    );
    await act(async () => {
      userEvent.type(txtInspectionDateTime, "1");
    });
  });
  test("Custom Test Cases for txtInspectionDateTime", () => {
    // START_USER_CODE-USER_txtInspectionDateTime_TEST
    // END_USER_CODE-USER_txtInspectionDateTime_TEST
  });
  test("txtJumboGr(Textbox Widget) Test Cases", async () => {
    const txtJumboGr = screen.getByTestId("txtJumboGr");
    expect(txtJumboGr.tagName).toBe("INPUT");
    expect(txtJumboGr.type).toBe("text");
    expect(txtJumboGr.classList).toContain("textboxWidgetClass");
    expect(txtJumboGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtJumboGr")
    );
    await act(async () => {
      userEvent.type(txtJumboGr, "1");
    });
  });
  test("Custom Test Cases for txtJumboGr", () => {
    // START_USER_CODE-USER_txtJumboGr_TEST
    // END_USER_CODE-USER_txtJumboGr_TEST
  });
  test("txtJumboPct(Textbox Widget) Test Cases", async () => {
    const txtJumboPct = screen.getByTestId("txtJumboPct");
    expect(txtJumboPct.tagName).toBe("INPUT");
    expect(txtJumboPct.type).toBe("text");
    expect(txtJumboPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtJumboPct, "1");
    });
  });
  test("Custom Test Cases for txtJumboPct", () => {
    // START_USER_CODE-USER_txtJumboPct_TEST
    // END_USER_CODE-USER_txtJumboPct_TEST
  });
  test("txtKernelsREL(Textbox Widget) Test Cases", async () => {
    const txtKernelsREL = screen.getByTestId("txtKernelsREL");
    expect(txtKernelsREL.tagName).toBe("INPUT");
    expect(txtKernelsREL.type).toBe("text");
    expect(txtKernelsREL.classList).toContain("textboxWidgetClass");
    expect(txtKernelsREL.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtKernelsREL")
    );
    await act(async () => {
      userEvent.type(txtKernelsREL, "1");
    });
  });
  test("Custom Test Cases for txtKernelsREL", () => {
    // START_USER_CODE-USER_txtKernelsREL_TEST
    // END_USER_CODE-USER_txtKernelsREL_TEST
  });
  test("txtKernelsRPS(Textbox Widget) Test Cases", async () => {
    const txtKernelsRPS = screen.getByTestId("txtKernelsRPS");
    expect(txtKernelsRPS.tagName).toBe("INPUT");
    expect(txtKernelsRPS.type).toBe("text");
    expect(txtKernelsRPS.classList).toContain("textboxWidgetClass");
    expect(txtKernelsRPS.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtKernelsRPS")
    );
    await act(async () => {
      userEvent.type(txtKernelsRPS, "1");
    });
  });
  test("Custom Test Cases for txtKernelsRPS", () => {
    // START_USER_CODE-USER_txtKernelsRPS_TEST
    // END_USER_CODE-USER_txtKernelsRPS_TEST
  });
  test("txtKernelValueLb(Textbox Widget) Test Cases", async () => {
    const txtKernelValueLb = screen.getByTestId("txtKernelValueLb");
    expect(txtKernelValueLb.tagName).toBe("INPUT");
    expect(txtKernelValueLb.type).toBe("text");
    expect(txtKernelValueLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtKernelValueLb, "123");
    });
    expect(txtKernelValueLb.getAttribute("value")).toBe("");
    expect(txtKernelValueLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtKernelValueLb", () => {
    // START_USER_CODE-USER_txtKernelValueLb_TEST
    // END_USER_CODE-USER_txtKernelValueLb_TEST
  });
  test("txtKernelValueTon(Textbox Widget) Test Cases", async () => {
    const txtKernelValueTon = screen.getByTestId("txtKernelValueTon");
    expect(txtKernelValueTon.tagName).toBe("INPUT");
    expect(txtKernelValueTon.type).toBe("text");
    expect(txtKernelValueTon.classList).toContain("textboxWidgetClass");
    expect(txtKernelValueTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtKernelValueTon")
    );
    await act(async () => {
      userEvent.type(txtKernelValueTon, "123");
    });
    expect(txtKernelValueTon.getAttribute("value")).toBe("");
    expect(txtKernelValueTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtKernelValueTon", () => {
    // START_USER_CODE-USER_txtKernelValueTon_TEST
    // END_USER_CODE-USER_txtKernelValueTon_TEST
  });
  test("txtlblTotalDiscountsLb(Textbox Widget) Test Cases", async () => {
    const txtlblTotalDiscountsLb = screen.getByTestId("txtlblTotalDiscountsLb");
    expect(txtlblTotalDiscountsLb.tagName).toBe("INPUT");
    expect(txtlblTotalDiscountsLb.type).toBe("text");
    expect(txtlblTotalDiscountsLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtlblTotalDiscountsLb, "123");
    });
    expect(txtlblTotalDiscountsLb.getAttribute("value")).toBe("");
    expect(txtlblTotalDiscountsLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtlblTotalDiscountsLb", () => {
    // START_USER_CODE-USER_txtlblTotalDiscountsLb_TEST
    // END_USER_CODE-USER_txtlblTotalDiscountsLb_TEST
  });
  test("txtLSK(Textbox Widget) Test Cases", async () => {
    const txtLSK = screen.getByTestId("txtLSK");
    expect(txtLSK.tagName).toBe("INPUT");
    expect(txtLSK.type).toBe("text");
    expect(txtLSK.classList).toContain("textboxWidgetClass");
    expect(txtLSK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtLSK")
    );
    await act(async () => {
      userEvent.type(txtLSK, "1");
    });
  });
  test("Custom Test Cases for txtLSK", () => {
    // START_USER_CODE-USER_txtLSK_TEST
    // END_USER_CODE-USER_txtLSK_TEST
  });
  test("txtLSKGr(Textbox Widget) Test Cases", async () => {
    const txtLSKGr = screen.getByTestId("txtLSKGr");
    expect(txtLSKGr.tagName).toBe("INPUT");
    expect(txtLSKGr.type).toBe("text");
    expect(txtLSKGr.classList).toContain("textboxWidgetClass");
    expect(txtLSKGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtLSKGr")
    );
    await act(async () => {
      userEvent.type(txtLSKGr, "1");
    });
  });
  test("Custom Test Cases for txtLSKGr", () => {
    // START_USER_CODE-USER_txtLSKGr_TEST
    // END_USER_CODE-USER_txtLSKGr_TEST
  });
  test("txtLSKPct(Textbox Widget) Test Cases", async () => {
    const txtLSKPct = screen.getByTestId("txtLSKPct");
    expect(txtLSKPct.tagName).toBe("INPUT");
    expect(txtLSKPct.type).toBe("text");
    expect(txtLSKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLSKPct, "1");
    });
  });
  test("Custom Test Cases for txtLSKPct", () => {
    // START_USER_CODE-USER_txtLSKPct_TEST
    // END_USER_CODE-USER_txtLSKPct_TEST
  });
  test("txtLSKvc(Textbox Widget) Test Cases", async () => {
    const txtLSKvc = screen.getByTestId("txtLSKvc");
    expect(txtLSKvc.tagName).toBe("INPUT");
    expect(txtLSKvc.type).toBe("text");
    expect(txtLSKvc.classList).toContain("textboxWidgetClass");
    expect(txtLSKvc.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtLSKvc")
    );
    await act(async () => {
      userEvent.type(txtLSKvc, "123");
    });
    expect(txtLSKvc.getAttribute("value")).toBe("");
    expect(txtLSKvc.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLSKvc", () => {
    // START_USER_CODE-USER_txtLSKvc_TEST
    // END_USER_CODE-USER_txtLSKvc_TEST
  });
  test("txtMeterReading(Textbox Widget) Test Cases", async () => {
    const txtMeterReading = screen.getByTestId("txtMeterReading");
    expect(txtMeterReading.tagName).toBe("INPUT");
    expect(txtMeterReading.type).toBe("text");
    expect(txtMeterReading.classList).toContain("textboxWidgetClass");
    expect(txtMeterReading.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtMeterReading")
    );
    await act(async () => {
      userEvent.type(txtMeterReading, "1");
    });
  });
  test("Custom Test Cases for txtMeterReading", () => {
    // START_USER_CODE-USER_txtMeterReading_TEST
    // END_USER_CODE-USER_txtMeterReading_TEST
  });
  test("txtMoisture(Textbox Widget) Test Cases", async () => {
    const txtMoisture = screen.getByTestId("txtMoisture");
    expect(txtMoisture.tagName).toBe("INPUT");
    expect(txtMoisture.type).toBe("text");
    expect(txtMoisture.classList).toContain("textboxWidgetClass");
    expect(txtMoisture.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtMoisture")
    );
    await act(async () => {
      userEvent.type(txtMoisture, "1");
    });
  });
  test("Custom Test Cases for txtMoisture", () => {
    // START_USER_CODE-USER_txtMoisture_TEST
    // END_USER_CODE-USER_txtMoisture_TEST
  });
  test("txtMST(Textbox Widget) Test Cases", async () => {
    const txtMST = screen.getByTestId("txtMST");
    expect(txtMST.tagName).toBe("INPUT");
    expect(txtMST.type).toBe("text");
    expect(txtMST.classList).toContain("textboxWidgetClass");
    expect(txtMST.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtMST")
    );
    await act(async () => {
      userEvent.type(txtMST, "1");
    });
  });
  test("Custom Test Cases for txtMST", () => {
    // START_USER_CODE-USER_txtMST_TEST
    // END_USER_CODE-USER_txtMST_TEST
  });
  test("txtName(Textbox Widget) Test Cases", async () => {
    const txtName = screen.getByTestId("txtName");
    expect(txtName.tagName).toBe("INPUT");
    expect(txtName.type).toBe("text");
    expect(txtName.classList).toContain("textboxWidgetClass");
    expect(txtName.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtName")
    );
    await act(async () => {
      userEvent.type(txtName, "1");
    });
  });
  test("Custom Test Cases for txtName", () => {
    // START_USER_CODE-USER_txtName_TEST
    // END_USER_CODE-USER_txtName_TEST
  });
  test("txtNetValPerTonExclSLK(Textbox Widget) Test Cases", async () => {
    const txtNetValPerTonExclSLK = screen.getByTestId("txtNetValPerTonExclSLK");
    expect(txtNetValPerTonExclSLK.tagName).toBe("INPUT");
    expect(txtNetValPerTonExclSLK.type).toBe("text");
    expect(txtNetValPerTonExclSLK.classList).toContain("textboxWidgetClass");
    expect(txtNetValPerTonExclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtNetValPerTonExclSLK")
    );
    await act(async () => {
      userEvent.type(txtNetValPerTonExclSLK, "123");
    });
    expect(txtNetValPerTonExclSLK.getAttribute("value")).toBe("");
    expect(txtNetValPerTonExclSLK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetValPerTonExclSLK", () => {
    // START_USER_CODE-USER_txtNetValPerTonExclSLK_TEST
    // END_USER_CODE-USER_txtNetValPerTonExclSLK_TEST
  });
  test("txtNetWeight(Textbox Widget) Test Cases", async () => {
    const txtNetWeight = screen.getByTestId("txtNetWeight");
    expect(txtNetWeight.tagName).toBe("INPUT");
    expect(txtNetWeight.type).toBe("text");
    expect(txtNetWeight.classList).toContain("textboxWidgetClass");
    expect(txtNetWeight.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtNetWeight")
    );
    await act(async () => {
      userEvent.type(txtNetWeight, "123");
    });
    expect(txtNetWeight.getAttribute("value")).toBe("");
    expect(txtNetWeight.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetWeight", () => {
    // START_USER_CODE-USER_txtNetWeight_TEST
    // END_USER_CODE-USER_txtNetWeight_TEST
  });
  test("txtNetWtExclLSK(Textbox Widget) Test Cases", async () => {
    const txtNetWtExclLSK = screen.getByTestId("txtNetWtExclLSK");
    expect(txtNetWtExclLSK.tagName).toBe("INPUT");
    expect(txtNetWtExclLSK.type).toBe("text");
    expect(txtNetWtExclLSK.classList).toContain("textboxWidgetClass");
    expect(txtNetWtExclLSK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtNetWtExclLSK")
    );
    await act(async () => {
      userEvent.type(txtNetWtExclLSK, "123");
    });
    expect(txtNetWtExclLSK.getAttribute("value")).toBe("");
    expect(txtNetWtExclLSK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetWtExclLSK", () => {
    // START_USER_CODE-USER_txtNetWtExclLSK_TEST
    // END_USER_CODE-USER_txtNetWtExclLSK_TEST
  });
  test("txtNumber(Textbox Widget) Test Cases", async () => {
    const txtNumber = screen.getByTestId("txtNumber");
    expect(txtNumber.tagName).toBe("INPUT");
    expect(txtNumber.type).toBe("text");
    expect(txtNumber.classList).toContain("textboxWidgetClass");
    expect(txtNumber.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtNumber")
    );
    await act(async () => {
      userEvent.type(txtNumber, "1");
    });
  });
  test("Custom Test Cases for txtNumber", () => {
    // START_USER_CODE-USER_txtNumber_TEST
    // END_USER_CODE-USER_txtNumber_TEST
  });
  test("txtOI(Textbox Widget) Test Cases", async () => {
    const txtOI = screen.getByTestId("txtOI");
    expect(txtOI.tagName).toBe("INPUT");
    expect(txtOI.type).toBe("text");
    expect(txtOI.classList).toContain("textboxWidgetClass");
    expect(txtOI.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtOI")
    );
    await act(async () => {
      userEvent.type(txtOI, "123");
    });
    expect(txtOI.getAttribute("value")).toBe("");
    expect(txtOI.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOI", () => {
    // START_USER_CODE-USER_txtOI_TEST
    // END_USER_CODE-USER_txtOI_TEST
  });
  test("txtOK(Textbox Widget) Test Cases", async () => {
    const txtOK = screen.getByTestId("txtOK");
    expect(txtOK.tagName).toBe("INPUT");
    expect(txtOK.type).toBe("text");
    expect(txtOK.classList).toContain("textboxWidgetClass");
    expect(txtOK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtOK")
    );
    await act(async () => {
      userEvent.type(txtOK, "1");
    });
  });
  test("Custom Test Cases for txtOK", () => {
    // START_USER_CODE-USER_txtOK_TEST
    // END_USER_CODE-USER_txtOK_TEST
  });
  test("txtOtherForeignMaterials(Textbox Widget) Test Cases", async () => {
    const txtOtherForeignMaterials = screen.getByTestId(
      "txtOtherForeignMaterials"
    );
    expect(txtOtherForeignMaterials.tagName).toBe("INPUT");
    expect(txtOtherForeignMaterials.type).toBe("text");
    expect(txtOtherForeignMaterials.classList).toContain("textboxWidgetClass");
    expect(txtOtherForeignMaterials.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtOtherForeignMaterials")
    );
    await act(async () => {
      userEvent.type(txtOtherForeignMaterials, "1");
    });
  });
  test("Custom Test Cases for txtOtherForeignMaterials", () => {
    // START_USER_CODE-USER_txtOtherForeignMaterials_TEST
    // END_USER_CODE-USER_txtOtherForeignMaterials_TEST
  });
  test("txtOtherKernelsGr(Textbox Widget) Test Cases", async () => {
    const txtOtherKernelsGr = screen.getByTestId("txtOtherKernelsGr");
    expect(txtOtherKernelsGr.tagName).toBe("INPUT");
    expect(txtOtherKernelsGr.type).toBe("text");
    expect(txtOtherKernelsGr.classList).toContain("textboxWidgetClass");
    expect(txtOtherKernelsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtOtherKernelsGr")
    );
    await act(async () => {
      userEvent.type(txtOtherKernelsGr, "1");
    });
  });
  test("Custom Test Cases for txtOtherKernelsGr", () => {
    // START_USER_CODE-USER_txtOtherKernelsGr_TEST
    // END_USER_CODE-USER_txtOtherKernelsGr_TEST
  });
  test("txtOtherKernelsPct(Textbox Widget) Test Cases", async () => {
    const txtOtherKernelsPct = screen.getByTestId("txtOtherKernelsPct");
    expect(txtOtherKernelsPct.tagName).toBe("INPUT");
    expect(txtOtherKernelsPct.type).toBe("text");
    expect(txtOtherKernelsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOtherKernelsPct, "1");
    });
  });
  test("Custom Test Cases for txtOtherKernelsPct", () => {
    // START_USER_CODE-USER_txtOtherKernelsPct_TEST
    // END_USER_CODE-USER_txtOtherKernelsPct_TEST
  });
  test("txtPercent(Textbox Widget) Test Cases", async () => {
    const txtPercent = screen.getByTestId("txtPercent");
    expect(txtPercent.tagName).toBe("INPUT");
    expect(txtPercent.type).toBe("text");
    expect(txtPercent.classList).toContain("textboxWidgetClass");
    expect(txtPercent.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtPercent")
    );
    await act(async () => {
      userEvent.type(txtPercent, "1");
    });
  });
  test("Custom Test Cases for txtPercent", () => {
    // START_USER_CODE-USER_txtPercent_TEST
    // END_USER_CODE-USER_txtPercent_TEST
  });
  test("txtProbingPattern(Textbox Widget) Test Cases", async () => {
    const txtProbingPattern = screen.getByTestId("txtProbingPattern");
    expect(txtProbingPattern.tagName).toBe("INPUT");
    expect(txtProbingPattern.type).toBe("text");
    expect(txtProbingPattern.classList).toContain("textboxWidgetClass");
    expect(txtProbingPattern.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtProbingPattern")
    );
    await act(async () => {
      userEvent.type(txtProbingPattern, "1");
    });
  });
  test("Custom Test Cases for txtProbingPattern", () => {
    // START_USER_CODE-USER_txtProbingPattern_TEST
    // END_USER_CODE-USER_txtProbingPattern_TEST
  });
  test("txtReading(Textbox Widget) Test Cases", async () => {
    const txtReading = screen.getByTestId("txtReading");
    expect(txtReading.tagName).toBe("INPUT");
    expect(txtReading.type).toBe("text");
    expect(txtReading.classList).toContain("textboxWidgetClass");
    expect(txtReading.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtReading")
    );
    await act(async () => {
      userEvent.type(txtReading, "1");
    });
  });
  test("Custom Test Cases for txtReading", () => {
    // START_USER_CODE-USER_txtReading_TEST
    // END_USER_CODE-USER_txtReading_TEST
  });
  test("txtRevision(Textbox Widget) Test Cases", async () => {
    const txtRevision = screen.getByTestId("txtRevision");
    expect(txtRevision.tagName).toBe("INPUT");
    expect(txtRevision.type).toBe("text");
    expect(txtRevision.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRevision, "1");
    });
  });
  test("Custom Test Cases for txtRevision", () => {
    // START_USER_CODE-USER_txtRevision_TEST
    // END_USER_CODE-USER_txtRevision_TEST
  });
  test("txtSampleWt(Textbox Widget) Test Cases", async () => {
    const txtSampleWt = screen.getByTestId("txtSampleWt");
    expect(txtSampleWt.tagName).toBe("INPUT");
    expect(txtSampleWt.type).toBe("text");
    expect(txtSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtSampleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtSampleWt")
    );
    await act(async () => {
      userEvent.type(txtSampleWt, "1");
    });
  });
  test("Custom Test Cases for txtSampleWt", () => {
    // START_USER_CODE-USER_txtSampleWt_TEST
    // END_USER_CODE-USER_txtSampleWt_TEST
  });
  test("txtSC95(Textbox Widget) Test Cases", async () => {
    const txtSC95 = screen.getByTestId("txtSC95");
    expect(txtSC95.tagName).toBe("INPUT");
    expect(txtSC95.type).toBe("text");
    expect(txtSC95.classList).toContain("textboxWidgetClass");
    expect(txtSC95.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtSC95")
    );
    await act(async () => {
      userEvent.type(txtSC95, "1");
    });
  });
  test("Custom Test Cases for txtSC95", () => {
    // START_USER_CODE-USER_txtSC95_TEST
    // END_USER_CODE-USER_txtSC95_TEST
  });
  test("txtSeedMeetingSpec(Textbox Widget) Test Cases", async () => {
    const txtSeedMeetingSpec = screen.getByTestId("txtSeedMeetingSpec");
    expect(txtSeedMeetingSpec.tagName).toBe("INPUT");
    expect(txtSeedMeetingSpec.type).toBe("text");
    expect(txtSeedMeetingSpec.classList).toContain("textboxWidgetClass");
    expect(txtSeedMeetingSpec.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtSeedMeetingSpec")
    );
    await act(async () => {
      userEvent.type(txtSeedMeetingSpec, "1");
    });
  });
  test("Custom Test Cases for txtSeedMeetingSpec", () => {
    // START_USER_CODE-USER_txtSeedMeetingSpec_TEST
    // END_USER_CODE-USER_txtSeedMeetingSpec_TEST
  });
  test("txtSeg(Textbox Widget) Test Cases", async () => {
    const txtSeg = screen.getByTestId("txtSeg");
    expect(txtSeg.tagName).toBe("INPUT");
    expect(txtSeg.type).toBe("text");
    expect(txtSeg.classList).toContain("textboxWidgetClass");
    expect(txtSeg.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtSeg")
    );
    await act(async () => {
      userEvent.type(txtSeg, "1");
    });
  });
  test("Custom Test Cases for txtSeg", () => {
    // START_USER_CODE-USER_txtSeg_TEST
    // END_USER_CODE-USER_txtSeg_TEST
  });
  test("txtSharePct0(Textbox Widget) Test Cases", async () => {
    const txtSharePct0 = screen.getByTestId("txtSharePct0");
    expect(txtSharePct0.tagName).toBe("INPUT");
    expect(txtSharePct0.type).toBe("text");
    expect(txtSharePct0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct0, "1");
    });
  });
  test("Custom Test Cases for txtSharePct0", () => {
    // START_USER_CODE-USER_txtSharePct0_TEST
    // END_USER_CODE-USER_txtSharePct0_TEST
  });
  test("txtSharePct1(Textbox Widget) Test Cases", async () => {
    const txtSharePct1 = screen.getByTestId("txtSharePct1");
    expect(txtSharePct1.tagName).toBe("INPUT");
    expect(txtSharePct1.type).toBe("text");
    expect(txtSharePct1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct1, "1");
    });
  });
  test("Custom Test Cases for txtSharePct1", () => {
    // START_USER_CODE-USER_txtSharePct1_TEST
    // END_USER_CODE-USER_txtSharePct1_TEST
  });
  test("txtSharePct2(Textbox Widget) Test Cases", async () => {
    const txtSharePct2 = screen.getByTestId("txtSharePct2");
    expect(txtSharePct2.tagName).toBe("INPUT");
    expect(txtSharePct2.type).toBe("text");
    expect(txtSharePct2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct2, "1");
    });
  });
  test("Custom Test Cases for txtSharePct2", () => {
    // START_USER_CODE-USER_txtSharePct2_TEST
    // END_USER_CODE-USER_txtSharePct2_TEST
  });
  test("txtSharePct3(Textbox Widget) Test Cases", async () => {
    const txtSharePct3 = screen.getByTestId("txtSharePct3");
    expect(txtSharePct3.tagName).toBe("INPUT");
    expect(txtSharePct3.type).toBe("text");
    expect(txtSharePct3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct3, "1");
    });
  });
  test("Custom Test Cases for txtSharePct3", () => {
    // START_USER_CODE-USER_txtSharePct3_TEST
    // END_USER_CODE-USER_txtSharePct3_TEST
  });
  test("txtSMK(Textbox Widget) Test Cases", async () => {
    const txtSMK = screen.getByTestId("txtSMK");
    expect(txtSMK.tagName).toBe("INPUT");
    expect(txtSMK.type).toBe("text");
    expect(txtSMK.classList).toContain("textboxWidgetClass");
    expect(txtSMK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtSMK")
    );
    await act(async () => {
      userEvent.type(txtSMK, "1");
    });
  });
  test("Custom Test Cases for txtSMK", () => {
    // START_USER_CODE-USER_txtSMK_TEST
    // END_USER_CODE-USER_txtSMK_TEST
  });
  test("txtSMK1Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK1Gr = screen.getByTestId("txtSMK1Gr");
    expect(txtSMK1Gr.tagName).toBe("INPUT");
    expect(txtSMK1Gr.type).toBe("text");
    expect(txtSMK1Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK1Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtSMK1Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK1Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK1Gr", () => {
    // START_USER_CODE-USER_txtSMK1Gr_TEST
    // END_USER_CODE-USER_txtSMK1Gr_TEST
  });
  test("txtSMK1Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK1Pct = screen.getByTestId("txtSMK1Pct");
    expect(txtSMK1Pct.tagName).toBe("INPUT");
    expect(txtSMK1Pct.type).toBe("text");
    expect(txtSMK1Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK1Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK1Pct", () => {
    // START_USER_CODE-USER_txtSMK1Pct_TEST
    // END_USER_CODE-USER_txtSMK1Pct_TEST
  });
  test("txtSMK2Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK2Gr = screen.getByTestId("txtSMK2Gr");
    expect(txtSMK2Gr.tagName).toBe("INPUT");
    expect(txtSMK2Gr.type).toBe("text");
    expect(txtSMK2Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK2Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtSMK2Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK2Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK2Gr", () => {
    // START_USER_CODE-USER_txtSMK2Gr_TEST
    // END_USER_CODE-USER_txtSMK2Gr_TEST
  });
  test("txtSMK2Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK2Pct = screen.getByTestId("txtSMK2Pct");
    expect(txtSMK2Pct.tagName).toBe("INPUT");
    expect(txtSMK2Pct.type).toBe("text");
    expect(txtSMK2Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK2Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK2Pct", () => {
    // START_USER_CODE-USER_txtSMK2Pct_TEST
    // END_USER_CODE-USER_txtSMK2Pct_TEST
  });
  test("txtSMK3Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK3Gr = screen.getByTestId("txtSMK3Gr");
    expect(txtSMK3Gr.tagName).toBe("INPUT");
    expect(txtSMK3Gr.type).toBe("text");
    expect(txtSMK3Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK3Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtSMK3Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK3Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK3Gr", () => {
    // START_USER_CODE-USER_txtSMK3Gr_TEST
    // END_USER_CODE-USER_txtSMK3Gr_TEST
  });
  test("txtSMK3Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK3Pct = screen.getByTestId("txtSMK3Pct");
    expect(txtSMK3Pct.tagName).toBe("INPUT");
    expect(txtSMK3Pct.type).toBe("text");
    expect(txtSMK3Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK3Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK3Pct", () => {
    // START_USER_CODE-USER_txtSMK3Pct_TEST
    // END_USER_CODE-USER_txtSMK3Pct_TEST
  });
  test("txtSMK4Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK4Gr = screen.getByTestId("txtSMK4Gr");
    expect(txtSMK4Gr.tagName).toBe("INPUT");
    expect(txtSMK4Gr.type).toBe("text");
    expect(txtSMK4Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK4Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtSMK4Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK4Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK4Gr", () => {
    // START_USER_CODE-USER_txtSMK4Gr_TEST
    // END_USER_CODE-USER_txtSMK4Gr_TEST
  });
  test("txtSMK4Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK4Pct = screen.getByTestId("txtSMK4Pct");
    expect(txtSMK4Pct.tagName).toBe("INPUT");
    expect(txtSMK4Pct.type).toBe("text");
    expect(txtSMK4Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK4Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK4Pct", () => {
    // START_USER_CODE-USER_txtSMK4Pct_TEST
    // END_USER_CODE-USER_txtSMK4Pct_TEST
  });
  test("txtSMKRS(Textbox Widget) Test Cases", async () => {
    const txtSMKRS = screen.getByTestId("txtSMKRS");
    expect(txtSMKRS.tagName).toBe("INPUT");
    expect(txtSMKRS.type).toBe("text");
    expect(txtSMKRS.classList).toContain("textboxWidgetClass");
    expect(txtSMKRS.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtSMKRS")
    );
    await act(async () => {
      userEvent.type(txtSMKRS, "1");
    });
  });
  test("Custom Test Cases for txtSMKRS", () => {
    // START_USER_CODE-USER_txtSMKRS_TEST
    // END_USER_CODE-USER_txtSMKRS_TEST
  });
  test("txtSMKRSGr(Textbox Widget) Test Cases", async () => {
    const txtSMKRSGr = screen.getByTestId("txtSMKRSGr");
    expect(txtSMKRSGr.tagName).toBe("INPUT");
    expect(txtSMKRSGr.type).toBe("text");
    expect(txtSMKRSGr.classList).toContain("textboxWidgetClass");
    expect(txtSMKRSGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtSMKRSGr")
    );
    await act(async () => {
      userEvent.type(txtSMKRSGr, "1");
    });
  });
  test("Custom Test Cases for txtSMKRSGr", () => {
    // START_USER_CODE-USER_txtSMKRSGr_TEST
    // END_USER_CODE-USER_txtSMKRSGr_TEST
  });
  test("txtSMKRSPct(Textbox Widget) Test Cases", async () => {
    const txtSMKRSPct = screen.getByTestId("txtSMKRSPct");
    expect(txtSMKRSPct.tagName).toBe("INPUT");
    expect(txtSMKRSPct.type).toBe("text");
    expect(txtSMKRSPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMKRSPct, "1");
    });
  });
  test("Custom Test Cases for txtSMKRSPct", () => {
    // START_USER_CODE-USER_txtSMKRSPct_TEST
    // END_USER_CODE-USER_txtSMKRSPct_TEST
  });
  test("txtSoundSplitsGr(Textbox Widget) Test Cases", async () => {
    const txtSoundSplitsGr = screen.getByTestId("txtSoundSplitsGr");
    expect(txtSoundSplitsGr.tagName).toBe("INPUT");
    expect(txtSoundSplitsGr.type).toBe("text");
    expect(txtSoundSplitsGr.classList).toContain("textboxWidgetClass");
    expect(txtSoundSplitsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtSoundSplitsGr")
    );
    await act(async () => {
      userEvent.type(txtSoundSplitsGr, "1");
    });
  });
  test("Custom Test Cases for txtSoundSplitsGr", () => {
    // START_USER_CODE-USER_txtSoundSplitsGr_TEST
    // END_USER_CODE-USER_txtSoundSplitsGr_TEST
  });
  test("txtSoundSplitsPct(Textbox Widget) Test Cases", async () => {
    const txtSoundSplitsPct = screen.getByTestId("txtSoundSplitsPct");
    expect(txtSoundSplitsPct.tagName).toBe("INPUT");
    expect(txtSoundSplitsPct.type).toBe("text");
    expect(txtSoundSplitsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSoundSplitsPct, "1");
    });
  });
  test("Custom Test Cases for txtSoundSplitsPct", () => {
    // START_USER_CODE-USER_txtSoundSplitsPct_TEST
    // END_USER_CODE-USER_txtSoundSplitsPct_TEST
  });
  test("txtSS(Textbox Widget) Test Cases", async () => {
    const txtSS = screen.getByTestId("txtSS");
    expect(txtSS.tagName).toBe("INPUT");
    expect(txtSS.type).toBe("text");
    expect(txtSS.classList).toContain("textboxWidgetClass");
    expect(txtSS.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtSS")
    );
    await act(async () => {
      userEvent.type(txtSS, "1");
    });
  });
  test("Custom Test Cases for txtSS", () => {
    // START_USER_CODE-USER_txtSS_TEST
    // END_USER_CODE-USER_txtSS_TEST
  });
  test("txtStatus(Textbox Widget) Test Cases", async () => {
    const txtStatus = screen.getByTestId("txtStatus");
    expect(txtStatus.tagName).toBe("INPUT");
    expect(txtStatus.type).toBe("text");
    expect(txtStatus.classList).toContain("textboxWidgetClass");
    expect(txtStatus.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtStatus")
    );
    await act(async () => {
      userEvent.type(txtStatus, "1");
    });
  });
  test("Custom Test Cases for txtStatus", () => {
    // START_USER_CODE-USER_txtStatus_TEST
    // END_USER_CODE-USER_txtStatus_TEST
  });
  test("txtTickets(Textbox Widget) Test Cases", async () => {
    const txtTickets = screen.getByTestId("txtTickets");
    expect(txtTickets.tagName).toBe("INPUT");
    expect(txtTickets.type).toBe("text");
    expect(txtTickets.classList).toContain("textboxWidgetClass");
    expect(txtTickets.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtTickets")
    );
    await act(async () => {
      userEvent.type(txtTickets, "123");
    });
    expect(txtTickets.getAttribute("value")).toBe("");
    expect(txtTickets.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTickets", () => {
    // START_USER_CODE-USER_txtTickets_TEST
    // END_USER_CODE-USER_txtTickets_TEST
  });
  test("txtTotalDamageGr(Textbox Widget) Test Cases", async () => {
    const txtTotalDamageGr = screen.getByTestId("txtTotalDamageGr");
    expect(txtTotalDamageGr.tagName).toBe("INPUT");
    expect(txtTotalDamageGr.type).toBe("text");
    expect(txtTotalDamageGr.classList).toContain("textboxWidgetClass");
    expect(txtTotalDamageGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtTotalDamageGr")
    );
    await act(async () => {
      userEvent.type(txtTotalDamageGr, "1");
    });
  });
  test("Custom Test Cases for txtTotalDamageGr", () => {
    // START_USER_CODE-USER_txtTotalDamageGr_TEST
    // END_USER_CODE-USER_txtTotalDamageGr_TEST
  });
  test("txtTotalDamagePct(Textbox Widget) Test Cases", async () => {
    const txtTotalDamagePct = screen.getByTestId("txtTotalDamagePct");
    expect(txtTotalDamagePct.tagName).toBe("INPUT");
    expect(txtTotalDamagePct.type).toBe("text");
    expect(txtTotalDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtTotalDamagePct", () => {
    // START_USER_CODE-USER_txtTotalDamagePct_TEST
    // END_USER_CODE-USER_txtTotalDamagePct_TEST
  });
  test("txtTotalDiscountsTon(Textbox Widget) Test Cases", async () => {
    const txtTotalDiscountsTon = screen.getByTestId("txtTotalDiscountsTon");
    expect(txtTotalDiscountsTon.tagName).toBe("INPUT");
    expect(txtTotalDiscountsTon.type).toBe("text");
    expect(txtTotalDiscountsTon.classList).toContain("textboxWidgetClass");
    expect(txtTotalDiscountsTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtTotalDiscountsTon")
    );
    await act(async () => {
      userEvent.type(txtTotalDiscountsTon, "123");
    });
    expect(txtTotalDiscountsTon.getAttribute("value")).toBe("");
    expect(txtTotalDiscountsTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalDiscountsTon", () => {
    // START_USER_CODE-USER_txtTotalDiscountsTon_TEST
    // END_USER_CODE-USER_txtTotalDiscountsTon_TEST
  });
  test("txtTotalDollar(Textbox Widget) Test Cases", async () => {
    const txtTotalDollar = screen.getByTestId("txtTotalDollar");
    expect(txtTotalDollar.tagName).toBe("INPUT");
    expect(txtTotalDollar.type).toBe("text");
    expect(txtTotalDollar.classList).toContain("textboxWidgetClass");
    expect(txtTotalDollar.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtTotalDollar")
    );
    await act(async () => {
      userEvent.type(txtTotalDollar, "123");
    });
    expect(txtTotalDollar.getAttribute("value")).toBe("");
    expect(txtTotalDollar.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalDollar", () => {
    // START_USER_CODE-USER_txtTotalDollar_TEST
    // END_USER_CODE-USER_txtTotalDollar_TEST
  });
  test("txtTotalKernels(Textbox Widget) Test Cases", async () => {
    const txtTotalKernels = screen.getByTestId("txtTotalKernels");
    expect(txtTotalKernels.tagName).toBe("INPUT");
    expect(txtTotalKernels.type).toBe("text");
    expect(txtTotalKernels.classList).toContain("textboxWidgetClass");
    expect(txtTotalKernels.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtTotalKernels")
    );
    await act(async () => {
      userEvent.type(txtTotalKernels, "1");
    });
  });
  test("Custom Test Cases for txtTotalKernels", () => {
    // START_USER_CODE-USER_txtTotalKernels_TEST
    // END_USER_CODE-USER_txtTotalKernels_TEST
  });
  test("txtTotalKernelsHulls(Textbox Widget) Test Cases", async () => {
    const txtTotalKernelsHulls = screen.getByTestId("txtTotalKernelsHulls");
    expect(txtTotalKernelsHulls.tagName).toBe("INPUT");
    expect(txtTotalKernelsHulls.type).toBe("text");
    expect(txtTotalKernelsHulls.classList).toContain("textboxWidgetClass");
    expect(txtTotalKernelsHulls.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtTotalKernelsHulls")
    );
    await act(async () => {
      userEvent.type(txtTotalKernelsHulls, "123");
    });
    expect(txtTotalKernelsHulls.getAttribute("value")).toBe("");
    expect(txtTotalKernelsHulls.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalKernelsHulls", () => {
    // START_USER_CODE-USER_txtTotalKernelsHulls_TEST
    // END_USER_CODE-USER_txtTotalKernelsHulls_TEST
  });
  test("txtTotalKrnls(Textbox Widget) Test Cases", async () => {
    const txtTotalKrnls = screen.getByTestId("txtTotalKrnls");
    expect(txtTotalKrnls.tagName).toBe("INPUT");
    expect(txtTotalKrnls.type).toBe("text");
    expect(txtTotalKrnls.classList).toContain("textboxWidgetClass");
    expect(txtTotalKrnls.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtTotalKrnls")
    );
    await act(async () => {
      userEvent.type(txtTotalKrnls, "123");
    });
    expect(txtTotalKrnls.getAttribute("value")).toBe("");
    expect(txtTotalKrnls.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalKrnls", () => {
    // START_USER_CODE-USER_txtTotalKrnls_TEST
    // END_USER_CODE-USER_txtTotalKrnls_TEST
  });
  test("txtTotalLb(Textbox Widget) Test Cases", async () => {
    const txtTotalLb = screen.getByTestId("txtTotalLb");
    expect(txtTotalLb.tagName).toBe("INPUT");
    expect(txtTotalLb.type).toBe("text");
    expect(txtTotalLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalLb, "123");
    });
    expect(txtTotalLb.getAttribute("value")).toBe("");
    expect(txtTotalLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLb", () => {
    // START_USER_CODE-USER_txtTotalLb_TEST
    // END_USER_CODE-USER_txtTotalLb_TEST
  });
  test("txtTotalLBs(Textbox Widget) Test Cases", async () => {
    const txtTotalLBs = screen.getByTestId("txtTotalLBs");
    expect(txtTotalLBs.tagName).toBe("INPUT");
    expect(txtTotalLBs.type).toBe("text");
    expect(txtTotalLBs.classList).toContain("textboxWidgetClass");
    expect(txtTotalLBs.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtTotalLBs")
    );
    await act(async () => {
      userEvent.type(txtTotalLBs, "123");
    });
    expect(txtTotalLBs.getAttribute("value")).toBe("");
    expect(txtTotalLBs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLBs", () => {
    // START_USER_CODE-USER_txtTotalLBs_TEST
    // END_USER_CODE-USER_txtTotalLBs_TEST
  });
  test("txtTotalLoadVal(Textbox Widget) Test Cases", async () => {
    const txtTotalLoadVal = screen.getByTestId("txtTotalLoadVal");
    expect(txtTotalLoadVal.tagName).toBe("INPUT");
    expect(txtTotalLoadVal.type).toBe("text");
    expect(txtTotalLoadVal.classList).toContain("textboxWidgetClass");
    expect(txtTotalLoadVal.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtTotalLoadVal")
    );
    await act(async () => {
      userEvent.type(txtTotalLoadVal, "123");
    });
    expect(txtTotalLoadVal.getAttribute("value")).toBe("");
    expect(txtTotalLoadVal.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLoadVal", () => {
    // START_USER_CODE-USER_txtTotalLoadVal_TEST
    // END_USER_CODE-USER_txtTotalLoadVal_TEST
  });
  test("txtTotalSMK(Textbox Widget) Test Cases", async () => {
    const txtTotalSMK = screen.getByTestId("txtTotalSMK");
    expect(txtTotalSMK.tagName).toBe("INPUT");
    expect(txtTotalSMK.type).toBe("text");
    expect(txtTotalSMK.classList).toContain("textboxWidgetClass");
    expect(txtTotalSMK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtTotalSMK")
    );
    await act(async () => {
      userEvent.type(txtTotalSMK, "123");
    });
    expect(txtTotalSMK.getAttribute("value")).toBe("");
    expect(txtTotalSMK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalSMK", () => {
    // START_USER_CODE-USER_txtTotalSMK_TEST
    // END_USER_CODE-USER_txtTotalSMK_TEST
  });
  test("txtTotalTon(Textbox Widget) Test Cases", async () => {
    const txtTotalTon = screen.getByTestId("txtTotalTon");
    expect(txtTotalTon.tagName).toBe("INPUT");
    expect(txtTotalTon.type).toBe("text");
    expect(txtTotalTon.classList).toContain("textboxWidgetClass");
    expect(txtTotalTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtTotalTon")
    );
    await act(async () => {
      userEvent.type(txtTotalTon, "123");
    });
    expect(txtTotalTon.getAttribute("value")).toBe("");
    expect(txtTotalTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalTon", () => {
    // START_USER_CODE-USER_txtTotalTon_TEST
    // END_USER_CODE-USER_txtTotalTon_TEST
  });
  test("txtTotKrnlsHulls(Textbox Widget) Test Cases", async () => {
    const txtTotKrnlsHulls = screen.getByTestId("txtTotKrnlsHulls");
    expect(txtTotKrnlsHulls.tagName).toBe("INPUT");
    expect(txtTotKrnlsHulls.type).toBe("text");
    expect(txtTotKrnlsHulls.classList).toContain("textboxWidgetClass");
    expect(txtTotKrnlsHulls.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtTotKrnlsHulls")
    );
    await act(async () => {
      userEvent.type(txtTotKrnlsHulls, "1");
    });
  });
  test("Custom Test Cases for txtTotKrnlsHulls", () => {
    // START_USER_CODE-USER_txtTotKrnlsHulls_TEST
    // END_USER_CODE-USER_txtTotKrnlsHulls_TEST
  });
  test("txtValenciaCleanSampleWt(Textbox Widget) Test Cases", async () => {
    const txtValenciaCleanSampleWt = screen.getByTestId(
      "txtValenciaCleanSampleWt"
    );
    expect(txtValenciaCleanSampleWt.tagName).toBe("INPUT");
    expect(txtValenciaCleanSampleWt.type).toBe("text");
    expect(txtValenciaCleanSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtValenciaCleanSampleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtValenciaCleanSampleWt")
    );
    await act(async () => {
      userEvent.type(txtValenciaCleanSampleWt, "1");
    });
  });
  test("Custom Test Cases for txtValenciaCleanSampleWt", () => {
    // START_USER_CODE-USER_txtValenciaCleanSampleWt_TEST
    // END_USER_CODE-USER_txtValenciaCleanSampleWt_TEST
  });
  test("txtValPerPoundExclSLK(Textbox Widget) Test Cases", async () => {
    const txtValPerPoundExclSLK = screen.getByTestId("txtValPerPoundExclSLK");
    expect(txtValPerPoundExclSLK.tagName).toBe("INPUT");
    expect(txtValPerPoundExclSLK.type).toBe("text");
    expect(txtValPerPoundExclSLK.classList).toContain("textboxWidgetClass");
    expect(txtValPerPoundExclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtValPerPoundExclSLK")
    );
    await act(async () => {
      userEvent.type(txtValPerPoundExclSLK, "123");
    });
    expect(txtValPerPoundExclSLK.getAttribute("value")).toBe("");
    expect(txtValPerPoundExclSLK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValPerPoundExclSLK", () => {
    // START_USER_CODE-USER_txtValPerPoundExclSLK_TEST
    // END_USER_CODE-USER_txtValPerPoundExclSLK_TEST
  });
  test("txtValPerPoundInclSLK(Textbox Widget) Test Cases", async () => {
    const txtValPerPoundInclSLK = screen.getByTestId("txtValPerPoundInclSLK");
    expect(txtValPerPoundInclSLK.tagName).toBe("INPUT");
    expect(txtValPerPoundInclSLK.type).toBe("text");
    expect(txtValPerPoundInclSLK.classList).toContain("textboxWidgetClass");
    expect(txtValPerPoundInclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtValPerPoundInclSLK")
    );
    await act(async () => {
      userEvent.type(txtValPerPoundInclSLK, "123");
    });
    expect(txtValPerPoundInclSLK.getAttribute("value")).toBe("");
    expect(txtValPerPoundInclSLK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValPerPoundInclSLK", () => {
    // START_USER_CODE-USER_txtValPerPoundInclSLK_TEST
    // END_USER_CODE-USER_txtValPerPoundInclSLK_TEST
  });
  test("txtVoidReason(Textbox Widget) Test Cases", async () => {
    const txtVoidReason = screen.getByTestId("txtVoidReason");
    expect(txtVoidReason.tagName).toBe("INPUT");
    expect(txtVoidReason.type).toBe("text");
    expect(txtVoidReason.classList).toContain("textboxWidgetClass");
    expect(txtVoidReason.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtVoidReason")
    );
    await act(async () => {
      userEvent.type(txtVoidReason, "1");
    });
  });
  test("Custom Test Cases for txtVoidReason", () => {
    // START_USER_CODE-USER_txtVoidReason_TEST
    // END_USER_CODE-USER_txtVoidReason_TEST
  });
  test("txtWtIncludingVehicle(Textbox Widget) Test Cases", async () => {
    const txtWtIncludingVehicle = screen.getByTestId("txtWtIncludingVehicle");
    expect(txtWtIncludingVehicle.tagName).toBe("INPUT");
    expect(txtWtIncludingVehicle.type).toBe("text");
    expect(txtWtIncludingVehicle.classList).toContain("textboxWidgetClass");
    expect(txtWtIncludingVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtWtIncludingVehicle")
    );
    await act(async () => {
      userEvent.type(txtWtIncludingVehicle, "123");
    });
    expect(txtWtIncludingVehicle.getAttribute("value")).toBe("");
    expect(txtWtIncludingVehicle.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWtIncludingVehicle", () => {
    // START_USER_CODE-USER_txtWtIncludingVehicle_TEST
    // END_USER_CODE-USER_txtWtIncludingVehicle_TEST
  });
  test("txtWtlessFM(Textbox Widget) Test Cases", async () => {
    const txtWtlessFM = screen.getByTestId("txtWtlessFM");
    expect(txtWtlessFM.tagName).toBe("INPUT");
    expect(txtWtlessFM.type).toBe("text");
    expect(txtWtlessFM.classList).toContain("textboxWidgetClass");
    expect(txtWtlessFM.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtWtlessFM")
    );
    await act(async () => {
      userEvent.type(txtWtlessFM, "123");
    });
    expect(txtWtlessFM.getAttribute("value")).toBe("");
    expect(txtWtlessFM.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWtlessFM", () => {
    // START_USER_CODE-USER_txtWtlessFM_TEST
    // END_USER_CODE-USER_txtWtlessFM_TEST
  });
  test("txtWtOfVehicle(Textbox Widget) Test Cases", async () => {
    const txtWtOfVehicle = screen.getByTestId("txtWtOfVehicle");
    expect(txtWtOfVehicle.tagName).toBe("INPUT");
    expect(txtWtOfVehicle.type).toBe("text");
    expect(txtWtOfVehicle.classList).toContain("textboxWidgetClass");
    expect(txtWtOfVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95_txtWtOfVehicle")
    );
    await act(async () => {
      userEvent.type(txtWtOfVehicle, "123");
    });
    expect(txtWtOfVehicle.getAttribute("value")).toBe("");
    expect(txtWtOfVehicle.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWtOfVehicle", () => {
    // START_USER_CODE-USER_txtWtOfVehicle_TEST
    // END_USER_CODE-USER_txtWtOfVehicle_TEST
  });
});
