/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_CmaDmaControlSetup from "./CmaDmaControlSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("CmaDmaControlSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_CmaDmaControlSetup />
      );
    });
  });
  afterEach(cleanup);
  test("is CmaDmaControlSetup Loads Successfully", () => {
    expect(screen.getByText("CmaDmaControlSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for CmaDmaControlSetup", () => {
    // START_USER_CODE-USER_CmaDmaControlSetup_Custom_Test_Case
    // END_USER_CODE-USER_CmaDmaControlSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_CmaDmaControlSetup />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("gridCmaDmaControlSetup(Grid Widget) Test Cases", async () => {
    let gridCmaDmaControlSetup = screen.getByTestId("gridCmaDmaControlSetup");
    let gridCmaDmaControlSetupbtn =
      gridCmaDmaControlSetup.nextElementSibling.firstElementChild;
    gridCmaDmaControlSetup =
      gridCmaDmaControlSetup.parentElement.parentElement.parentElement;
    expect(gridCmaDmaControlSetup.tagName).toBe("DIV");
    expect(gridCmaDmaControlSetup.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_gridCmaDmaControlSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridCmaDmaControlSetup", () => {
    // START_USER_CODE-USER_gridCmaDmaControlSetup_TEST
    // END_USER_CODE-USER_gridCmaDmaControlSetup_TEST
  });
  test("grpbxCmaDmaControlSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxCmaDmaControlSetup = screen.getByTestId(
      "grpbxCmaDmaControlSetup"
    );
    expect(grpbxCmaDmaControlSetup.tagName).toBe("BUTTON");
    expect(grpbxCmaDmaControlSetup.type).toBe("button");
    expect(grpbxCmaDmaControlSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCmaDmaControlSetup", () => {
    // START_USER_CODE-USER_grpbxCmaDmaControlSetup_TEST
    // END_USER_CODE-USER_grpbxCmaDmaControlSetup_TEST
  });
  test("gridCmaDmaControlSetup_txtcolApplyWRDeductions(Grid Widget) Test Cases", async () => {
    let gridCmaDmaControlSetup_txtcolApplyWRDeductions = screen.getByTestId(
      "gridCmaDmaControlSetup"
    );
    let gridCmaDmaControlSetup_txtcolApplyWRDeductionsbtn =
      gridCmaDmaControlSetup_txtcolApplyWRDeductions.nextElementSibling
        .firstElementChild;
    gridCmaDmaControlSetup_txtcolApplyWRDeductions =
      gridCmaDmaControlSetup_txtcolApplyWRDeductions.parentElement.parentElement
        .parentElement;
    expect(gridCmaDmaControlSetup_txtcolApplyWRDeductions.tagName).toBe("DIV");
    expect(gridCmaDmaControlSetup_txtcolApplyWRDeductions.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_gridCmaDmaControlSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolApplyWRDeductions", () => {
    // START_USER_CODE-USER_txtcolApplyWRDeductions_TEST
    // END_USER_CODE-USER_txtcolApplyWRDeductions_TEST
  });
  test("gridCmaDmaControlSetup_txtcolCMA(Grid Widget) Test Cases", async () => {
    let gridCmaDmaControlSetup_txtcolCMA = screen.getByTestId(
      "gridCmaDmaControlSetup"
    );
    let gridCmaDmaControlSetup_txtcolCMAbtn =
      gridCmaDmaControlSetup_txtcolCMA.nextElementSibling.firstElementChild;
    gridCmaDmaControlSetup_txtcolCMA =
      gridCmaDmaControlSetup_txtcolCMA.parentElement.parentElement
        .parentElement;
    expect(gridCmaDmaControlSetup_txtcolCMA.tagName).toBe("DIV");
    expect(gridCmaDmaControlSetup_txtcolCMA.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_gridCmaDmaControlSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCMA", () => {
    // START_USER_CODE-USER_txtcolCMA_TEST
    // END_USER_CODE-USER_txtcolCMA_TEST
  });
  test("gridCmaDmaControlSetup_txtcolCountryID(Grid Widget) Test Cases", async () => {
    let gridCmaDmaControlSetup_txtcolCountryID = screen.getByTestId(
      "gridCmaDmaControlSetup"
    );
    let gridCmaDmaControlSetup_txtcolCountryIDbtn =
      gridCmaDmaControlSetup_txtcolCountryID.nextElementSibling
        .firstElementChild;
    gridCmaDmaControlSetup_txtcolCountryID =
      gridCmaDmaControlSetup_txtcolCountryID.parentElement.parentElement
        .parentElement;
    expect(gridCmaDmaControlSetup_txtcolCountryID.tagName).toBe("DIV");
    expect(gridCmaDmaControlSetup_txtcolCountryID.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_gridCmaDmaControlSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCountryID", () => {
    // START_USER_CODE-USER_txtcolCountryID_TEST
    // END_USER_CODE-USER_txtcolCountryID_TEST
  });
  test("gridCmaDmaControlSetup_txtcolDMA(Grid Widget) Test Cases", async () => {
    let gridCmaDmaControlSetup_txtcolDMA = screen.getByTestId(
      "gridCmaDmaControlSetup"
    );
    let gridCmaDmaControlSetup_txtcolDMAbtn =
      gridCmaDmaControlSetup_txtcolDMA.nextElementSibling.firstElementChild;
    gridCmaDmaControlSetup_txtcolDMA =
      gridCmaDmaControlSetup_txtcolDMA.parentElement.parentElement
        .parentElement;
    expect(gridCmaDmaControlSetup_txtcolDMA.tagName).toBe("DIV");
    expect(gridCmaDmaControlSetup_txtcolDMA.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_gridCmaDmaControlSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDMA", () => {
    // START_USER_CODE-USER_txtcolDMA_TEST
    // END_USER_CODE-USER_txtcolDMA_TEST
  });
  test("gridCmaDmaControlSetup_txtcolStateAbbr(Grid Widget) Test Cases", async () => {
    let gridCmaDmaControlSetup_txtcolStateAbbr = screen.getByTestId(
      "gridCmaDmaControlSetup"
    );
    let gridCmaDmaControlSetup_txtcolStateAbbrbtn =
      gridCmaDmaControlSetup_txtcolStateAbbr.nextElementSibling
        .firstElementChild;
    gridCmaDmaControlSetup_txtcolStateAbbr =
      gridCmaDmaControlSetup_txtcolStateAbbr.parentElement.parentElement
        .parentElement;
    expect(gridCmaDmaControlSetup_txtcolStateAbbr.tagName).toBe("DIV");
    expect(gridCmaDmaControlSetup_txtcolStateAbbr.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_gridCmaDmaControlSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStateAbbr", () => {
    // START_USER_CODE-USER_txtcolStateAbbr_TEST
    // END_USER_CODE-USER_txtcolStateAbbr_TEST
  });
  test("gridCmaDmaControlSetup_txtcolStateID(Grid Widget) Test Cases", async () => {
    let gridCmaDmaControlSetup_txtcolStateID = screen.getByTestId(
      "gridCmaDmaControlSetup"
    );
    let gridCmaDmaControlSetup_txtcolStateIDbtn =
      gridCmaDmaControlSetup_txtcolStateID.nextElementSibling.firstElementChild;
    gridCmaDmaControlSetup_txtcolStateID =
      gridCmaDmaControlSetup_txtcolStateID.parentElement.parentElement
        .parentElement;
    expect(gridCmaDmaControlSetup_txtcolStateID.tagName).toBe("DIV");
    expect(gridCmaDmaControlSetup_txtcolStateID.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_gridCmaDmaControlSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStateID", () => {
    // START_USER_CODE-USER_txtcolStateID_TEST
    // END_USER_CODE-USER_txtcolStateID_TEST
  });
  test("gridCmaDmaControlSetup_txtcolUnappliedLbsAllowed(Grid Widget) Test Cases", async () => {
    let gridCmaDmaControlSetup_txtcolUnappliedLbsAllowed = screen.getByTestId(
      "gridCmaDmaControlSetup"
    );
    let gridCmaDmaControlSetup_txtcolUnappliedLbsAllowedbtn =
      gridCmaDmaControlSetup_txtcolUnappliedLbsAllowed.nextElementSibling
        .firstElementChild;
    gridCmaDmaControlSetup_txtcolUnappliedLbsAllowed =
      gridCmaDmaControlSetup_txtcolUnappliedLbsAllowed.parentElement
        .parentElement.parentElement;
    expect(gridCmaDmaControlSetup_txtcolUnappliedLbsAllowed.tagName).toBe(
      "DIV"
    );
    expect(
      gridCmaDmaControlSetup_txtcolUnappliedLbsAllowed.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_gridCmaDmaControlSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUnappliedLbsAllowed", () => {
    // START_USER_CODE-USER_txtcolUnappliedLbsAllowed_TEST
    // END_USER_CODE-USER_txtcolUnappliedLbsAllowed_TEST
  });
  test("gridCmaDmaControlSetup_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridCmaDmaControlSetup_txtcolVendor = screen.getByTestId(
      "gridCmaDmaControlSetup"
    );
    let gridCmaDmaControlSetup_txtcolVendorbtn =
      gridCmaDmaControlSetup_txtcolVendor.nextElementSibling.firstElementChild;
    gridCmaDmaControlSetup_txtcolVendor =
      gridCmaDmaControlSetup_txtcolVendor.parentElement.parentElement
        .parentElement;
    expect(gridCmaDmaControlSetup_txtcolVendor.tagName).toBe("DIV");
    expect(gridCmaDmaControlSetup_txtcolVendor.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_gridCmaDmaControlSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
  test("gridCmaDmaControlSetup_txtcolVendorName(Grid Widget) Test Cases", async () => {
    let gridCmaDmaControlSetup_txtcolVendorName = screen.getByTestId(
      "gridCmaDmaControlSetup"
    );
    let gridCmaDmaControlSetup_txtcolVendorNamebtn =
      gridCmaDmaControlSetup_txtcolVendorName.nextElementSibling
        .firstElementChild;
    gridCmaDmaControlSetup_txtcolVendorName =
      gridCmaDmaControlSetup_txtcolVendorName.parentElement.parentElement
        .parentElement;
    expect(gridCmaDmaControlSetup_txtcolVendorName.tagName).toBe("DIV");
    expect(gridCmaDmaControlSetup_txtcolVendorName.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:CmaDmaControlSetup_gridCmaDmaControlSetup"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorName", () => {
    // START_USER_CODE-USER_txtcolVendorName_TEST
    // END_USER_CODE-USER_txtcolVendorName_TEST
  });
});
