/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  setValue,
  setData,
  getValue,
  getData,
  disable,
  enable,
  show,
  goTo,
} from "../../shared/WindowImports";

import "./BuyingPointSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import {SystemMaintenanceMasterManagementService} from "../Service/SystemMaintenanceMasterManagementServices"
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { Buffer } from 'buffer';
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_BuyingPointSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let cropyear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  let userid = sessionStorage.getItem("userid"); 
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "BuyingPointSetup",
    windowName: "BuyingPointSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.BuyingPointSetup",
    // START_USER_CODE-USER_BuyingPointSetup_PROPERTIES
    headerData: {
      scrName: "Buying Point Setup",
      scrCode: "PN0020A",
    },
    // END_USER_CODE-USER_BuyingPointSetup_PROPERTIES
    btnAddArea: {
      name: "btnAddArea",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions1",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddArea_PROPERTIES

      // END_USER_CODE-USER_btnAddArea_PROPERTIES
    },
    btnAddBuyingPoint: {
      name: "btnAddBuyingPoint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions3",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_btnAddBuyingPoint_PROPERTIES
    },
    btnAddCollectionPoint: {
      name: "btnAddCollectionPoint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions2",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddCollectionPoint_PROPERTIES

      // END_USER_CODE-USER_btnAddCollectionPoint_PROPERTIES
    },
    btnAddTransferBuyingPoint: {
      name: "btnAddTransferBuyingPoint",
      type: "ButtonWidget",
      parent: "grpbxTransfers",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddTransferBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_btnAddTransferBuyingPoint_PROPERTIES
    },
    btnAddWeighers: {
      name: "btnAddWeighers",
      type: "ButtonWidget",
      parent: "grpbxWeighers",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddWeighers_PROPERTIES

      // END_USER_CODE-USER_btnAddWeighers_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions4",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    // btncmdEnableDisableControls1: {
    //   name: "btncmdEnableDisableControls1",
    //   type: "ButtonWidget",
    //   parent: "cmmndCntnrActions4",
    //   Label: "cmdEnableDisableControls",
    //   CharWidth: "48",

    //   ofTypeDomain: "d_boolean",
    //   // START_USER_CODE-USER_btncmdEnableDisableControls1_PROPERTIES

    //   // END_USER_CODE-USER_btncmdEnableDisableControls1_PROPERTIES
    // },
    // btncmdEnableDisableControls2: {
    //   name: "btncmdEnableDisableControls2",
    //   type: "ButtonWidget",
    //   parent: "cmmndCntnrActions4",
    //   Label: "cmdEnableDisableControls",
    //   CharWidth: "48",

    //   ofTypeDomain: "d_boolean",
    //   // START_USER_CODE-USER_btncmdEnableDisableControls2_PROPERTIES

    //   // END_USER_CODE-USER_btncmdEnableDisableControls2_PROPERTIES
    // },
    btnDeleteArea: {
      name: "btnDeleteArea",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions1",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteArea_PROPERTIES

      // END_USER_CODE-USER_btnDeleteArea_PROPERTIES
    },
    btnDeleteBuyingPoint: {
      name: "btnDeleteBuyingPoint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions3",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_btnDeleteBuyingPoint_PROPERTIES
    },
    btnDeleteCollectionPoint: {
      name: "btnDeleteCollectionPoint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions2",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteCollectionPoint_PROPERTIES

      // END_USER_CODE-USER_btnDeleteCollectionPoint_PROPERTIES
    },
    btnDeleteTransferBuyingPoint: {
      name: "btnDeleteTransferBuyingPoint",
      type: "ButtonWidget",
      parent: "grpbxTransfers",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteTransferBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_btnDeleteTransferBuyingPoint_PROPERTIES
    },
    btnDeleteWeighers: {
      name: "btnDeleteWeighers",
      type: "ButtonWidget",
      parent: "grpbxWeighers",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteWeighers_PROPERTIES

      // END_USER_CODE-USER_btnDeleteWeighers_PROPERTIES
    },
    btnEditArea: {
      name: "btnEditArea",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions1",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditArea_PROPERTIES

      // END_USER_CODE-USER_btnEditArea_PROPERTIES
    },
    btnEditBuyingPoint: {
      name: "btnEditBuyingPoint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions3",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_btnEditBuyingPoint_PROPERTIES
    },
    btnEditCollectionPoint: {
      name: "btnEditCollectionPoint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions2",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditCollectionPoint_PROPERTIES

      // END_USER_CODE-USER_btnEditCollectionPoint_PROPERTIES
    },
    btnEditWeighers: {
      name: "btnEditWeighers",
      type: "ButtonWidget",
      parent: "grpbxWeighers",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditWeighers_PROPERTIES

      // END_USER_CODE-USER_btnEditWeighers_PROPERTIES
    },
    cmmndCntnrActions1: {
      name: "cmmndCntnrActions1",
      type: "CommandContainerWidget",
      parent: "grpbxBuyingPointDetails",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      // START_USER_CODE-USER_cmmndCntnrActions1_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions1_PROPERTIES
    },
    cmmndCntnrActions2: {
      name: "cmmndCntnrActions2",
      type: "CommandContainerWidget",
      parent: "grpbxBuyingPointDetails",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      // START_USER_CODE-USER_cmmndCntnrActions2_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions2_PROPERTIES
    },
    cmmndCntnrActions3: {
      name: "cmmndCntnrActions3",
      type: "CommandContainerWidget",
      parent: "grpbxBuyingPointDetails",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      // START_USER_CODE-USER_cmmndCntnrActions3_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions3_PROPERTIES
    },
    ddArea: {
      name: "ddArea",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyingPointDetails",
      Label: "Area:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddArea_PROPERTIES

      // END_USER_CODE-USER_ddArea_PROPERTIES
    },
    ddCollectionPoint: {
      name: "ddCollectionPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyingPointDetails",
      Label: "Collection Point:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCollectionPoint_PROPERTIES

      // END_USER_CODE-USER_ddCollectionPoint_PROPERTIES
    },
    grpbxTransfers: {
      name: "grpbxTransfers",
      type: "GroupBoxWidget",
      parent: "grpbxBuyingPointDetails",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxTransfers_PROPERTIES

      // END_USER_CODE-USER_grpbxTransfers_PROPERTIES
    },
    grpbxWeighers: {
      name: "grpbxWeighers",
      type: "GroupBoxWidget",
      parent: "grpbxBuyingPointDetails",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxWeighers_PROPERTIES

      // END_USER_CODE-USER_grpbxWeighers_PROPERTIES
    },
    lblTransfersOrObligationLocations: {
      name: "lblTransfersOrObligationLocations",
      type: "LabelWidget",
      parent: "grpbxTransfers",
      Label: "Transfers or Obligation Locations:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTransfersOrObligationLocations_PROPERTIES

      // END_USER_CODE-USER_lblTransfersOrObligationLocations_PROPERTIES
    },
    lblWeighers: {
      name: "lblWeighers",
      type: "LabelWidget",
      parent: "grpbxWeighers",
      Label: "Weighers:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWeighers_PROPERTIES

      // END_USER_CODE-USER_lblWeighers_PROPERTIES
    },
    lstBuyingPoint: {
      name: "lstBuyingPoint",
      type: "ListBoxFieldWidget",
      parent: "grpbxBuyingPointDetails",
      Label: "Buying Point:",
      ColSpan: "4",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_lstBuyingPoint_PROPERTIES
    },
    lstTransferBuyingPoint: {
      name: "lstTransferBuyingPoint",
      type: "ListBoxFieldWidget",
      parent: "grpbxTransfers",
      ColSpan: "3",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstTransferBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_lstTransferBuyingPoint_PROPERTIES
    },
    lstWeighers: {
      name: "lstWeighers",
      type: "ListBoxFieldWidget",
      parent: "grpbxWeighers",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstWeighers_PROPERTIES

      // END_USER_CODE-USER_lstWeighers_PROPERTIES
    },
    grpbxBuyingPointDetails: {
      name: "grpbxBuyingPointDetails",
      type: "GroupBoxWidget",
      parent: "BuyingPointSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxBuyingPointDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxBuyingPointDetails_PROPERTIES
    },
    cmmndCntnrActions4: {
      name: "cmmndCntnrActions4",
      type: "CommandContainerWidget",
      parent: "BuyingPointSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions4_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions4_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceMasterManagement#AreaProfile":{},
        "SystemMaintenanceMasterManagement#CollectionPointProfile":{},
        "SystemMaintenanceMasterManagement#BuyingPointTransfer":{},
        "SystemMaintenanceMasterManagement#WeigherProfile":{},
        "SystemMaintenanceMasterManagement#BuyingPointProfile":{},
       },
      REVERSE:{
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceMasterManagement#AreaProfile":{},
        "SystemMaintenanceMasterManagement#CollectionPointProfile":{},
        "SystemMaintenanceMasterManagement#BuyingPointTransfer":{},
        "SystemMaintenanceMasterManagement#WeigherProfile":{},
        "SystemMaintenanceMasterManagement#BuyingPointProfile":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAddArea: {
    //   DEFAULT:["SystemMaintenanceMasterManagement#AreaProfile#DEFAULT#true#Click"]
    // },
    // btnAddCollectionPoint: {
    //   DEFAULT:["SystemMaintenanceMasterManagement#CollectionPointProfile#DEFAULT#true#Click"]
    // },
    // btnAddTransferBuyingPoint: {
    //   DEFAULT:["SystemMaintenanceMasterManagement#BuyingPointTransfer#DEFAULT#true#Click"]
    // },
    // btnAddWeighers: {
    //   DEFAULT:["SystemMaintenanceMasterManagement#WeigherProfile#DEFAULT#true#Click"]
    // },
    // btnAddBuyingPoint: {
    //   DEFAULT:["SystemMaintenanceMasterManagement#BuyingPointProfile#DEFAULT#true#Click"]
    // },
    btnClose:{
      DEFAULT:["ContractManagement#MainMenu#DEFAULT#false#Click"]
    }, 
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    try {
      if (getData(thisObj, "closingOfWeigherProfile") == true) {
        let buyingPointId = thisObj.values['lstBuyingPoint'];
        bFillWeigherList(buyingPointId)
      }
      setData(thisObj, "closingOfWeigherProfile", false)
    } catch (error) {
      showMessage(thisObj, "error on functions after Weigher Profile closing")
    }
  }, [getData(thisObj, "closingOfWeigherProfile")])

  useEffect(() => {
    try {
      if (getData(thisObj, "closingOfBuyingPointTransferProfile") == true) {
        let buyingPointId = thisObj.values['lstBuyingPoint'];
        bFillBuyingPointTransferList(buyingPointId)
      }
      setData(thisObj, "closingOfBuyingPointTransferProfile", false)
    } catch (error) {
      showMessage(thisObj, "error on functions after Buying Point Transfer profile closing")
    }
  }, [getData(thisObj, "closingOfBuyingPointTransferProfile")])

  useEffect(() => {
    try {
      if (getData(thisObj, "closingOfBuyingPointProfile") == true) {
        let areaId = thisObj.values['ddArea'];
        let collectionPointId = thisObj.values['ddCollectionPoint'];
        bFillBuyingPointList(areaId, collectionPointId);
      }
      setData(thisObj, "closingOfBuyingPointProfile", false)
    } catch (error) {
      showMessage(thisObj, "error on functions after Buying Point Profile closing")
    }
  }, [getData(thisObj, "closingOfBuyingPointProfile")])

  // useEffect(() => {
  //   try {
  //     if (getData(thisObj, "closingOfBuyingPointProfileFromCancel") == true) {
  //       let areaId = thisObj.values['ddArea'];
  //       let collectionPointId = thisObj.values['ddCollectionPoint'];
  //       if(getData(thisObj, 'CollectionPointId') != collectionPointId){
  //         setValue(thisObj, 'ddCollectionPoint', getData(thisObj, 'CollectionPointId'))
  //       }
  //       bFillBuyingPointList(areaId, getData(thisObj, 'CollectionPointId'))
  //     }
  //     setData(thisObj, "closingOfBuyingPointProfileFromCancel", false)
  //   } catch (error) {
  //     showMessage(thisObj, "error on functions after Buying Point Profile closing")
  //   }
  // }, [getData(thisObj, "closingOfBuyingPointProfileFromCancel")])

  useEffect(() => {
    try {
      if (getData(thisObj, "closingOfCollectionPointProfile") == true) {
        let areaId = thisObj.values['ddArea'];
        bFillCollectionPointList(areaId)
      }
      setData(thisObj, "closingOfCollectionPointProfile", false)
    } catch (error) {
      showMessage(thisObj, "error on functions after Collection Point Profile closing")
    }
  }, [getData(thisObj, "closingOfCollectionPointProfile")])

  useEffect(() => {
    try {
      if (getData(thisObj, "closingOfAreaProfile") == true) {
        bFillAreaList()
      }
      setData(thisObj, "closingOfAreaProfile", false)
    } catch (error) {
      showMessage(thisObj, "error on functions after Area Profile closing")
    }
  }, [getData(thisObj, "closingOfAreaProfile")])

  let areaId = ''
  let buyingPointId = null
  
  function FormLoad() {
    setData(thisObj, 'UserAccessValue', '');
    setData(thisObj, 'userSubAccessValue', '');
    enable(thisObj, "btnAddArea")
    enable(thisObj, "btnEditArea")
    enable(thisObj, "btnDeleteArea")
    enable(thisObj, "btnAddCollectionPoint")
    enable(thisObj, "btnEditCollectionPoint")
    enable(thisObj, "btnDeleteCollectionPoint")
    enable(thisObj, "btnAddBuyingPoint")
    enable(thisObj, "btnEditBuyingPoint")
    enable(thisObj, "btnDeleteBuyingPoint")
    enable(thisObj, "btnAddTransferBuyingPoint")
    enable(thisObj, "btnDeleteTransferBuyingPoint")
    enable(thisObj, "btnAddWeighers")
    enable(thisObj, "btnEditWeighers")
    enable(thisObj, "btnDeleteWeighers")

    EnableDisableControlsApiResponses();
    bFillAreaList(); //Method for binding Area List dropdown
    EnableDisableControlsI();
  }
  
  const EnableDisableControlsApiResponses = () => {
    SystemMaintenanceMasterManagementService.RetrieveBuyingPointControlAccessM('U').then(response => {
      if (response <= 0) {
        return;
      }
      if (response[0].permission == "N") {  
        setData(thisObj, 'UserAccessValue', 'N');
        SystemMaintenanceMasterManagementService.RetrieveBuyingPointControlAccess(null, 'D').then(response1 => {
          if (response1[0].permission == "N") {
            setData(thisObj, 'userSubAccessValue', 'N');
            SystemMaintenanceMasterManagementService.RetrieveBuyingPointControlAccess(null, 'U').then(response2 => {
              if (response2[0].permission == "N") {
                setData(thisObj, 'userSubAccessValue', 'I');
              }
              else {
                setData(thisObj, 'userSubAccessValue', 'U');
              }
            })
          }
          else {
            setData(thisObj, 'userSubAccessValue', 'D');
          }
        })
      }
      else {
        SystemMaintenanceMasterManagementService.RetrieveBuyingPointControlAccessM('D').then(response3 => {
          if (response3[0].permission == "N") {
            setData(thisObj, 'UserAccessValue', 'U');
          } else {
            setData(thisObj, 'UserAccessValue', 'D');
          }
        })
      }
    })
  }

  const EnableDisableControlsI = () => {
    let userAccess = getData(thisObj, 'UserAccessValue');
    let userSubAccess = getData(thisObj, 'userSubAccessValue');

    if (userAccess == "N") {  
      if (userSubAccess == "I") {
        disable(thisObj, "btnAddArea")
        disable(thisObj, "btnAddBuyingPoint")
        disable(thisObj, "btnAddCollectionPoint")
        disable(thisObj, "btnAddTransferBuyingPoint")
        disable(thisObj, "btnAddWeighers")
        disable(thisObj, "btnDeleteArea")
        disable(thisObj, "btnDeleteBuyingPoint")
        disable(thisObj, "btnDeleteCollectionPoint")
        disable(thisObj, "btnDeleteTransferBuyingPoint")
        disable(thisObj, "btnDeleteWeighers")
      } else if (userSubAccess == "U") {
        disable(thisObj, "btnAddArea")
        disable(thisObj, "btnAddBuyingPoint")
        disable(thisObj, "btnAddCollectionPoint")
        disable(thisObj, "btnDeleteArea")
        disable(thisObj, "btnDeleteBuyingPoint")
        disable(thisObj, "btnDeleteCollectionPoint")
        disable(thisObj, "btnDeleteTransferBuyingPoint")
        disable(thisObj, "btnDeleteWeighers")
      } else if (userSubAccess == "D") {
        disable(thisObj, "btnAddArea")
        disable(thisObj, "btnAddBuyingPoint")
        disable(thisObj, "btnAddCollectionPoint")
        disable(thisObj, "btnDeleteArea")
        disable(thisObj, "btnDeleteBuyingPoint")
        disable(thisObj, "btnDeleteCollectionPoint")
      }
    } else if (userAccess == "U"){
        disable(thisObj, "btnDeleteArea")
        disable(thisObj, "btnDeleteBuyingPoint")
        disable(thisObj, "btnDeleteCollectionPoint")
        disable(thisObj, "btnDeleteTransferBuyingPoint")
        disable(thisObj, "btnDeleteWeighers")
    }
  }

  const clearAreaList = () => {
    let js = [];
    thisObj.setState(current => {
      return {
        ...current,
        ddArea: {
          ...state["ddArea"],
          valueList: js,
        }
      }
    })
  }

  const clearCollectionPointList = () => {
    disable(thisObj, "btnAddCollectionPoint")
    disable(thisObj, "btnEditCollectionPoint")
    disable(thisObj, "btnDeleteCollectionPoint")
    let js = [];
    thisObj.setState(current => {
      return {
        ...current,
        ddCollectionPoint: {
          ...state["ddCollectionPoint"],
          valueList: js
        }
      }
    })
  }

  const clearBuyingPointList = () => {
    disable(thisObj, "btnAddBuyingPoint")
    disable(thisObj, "btnEditBuyingPoint")
    disable(thisObj, "btnDeleteBuyingPoint")
    let js = [];
    thisObj.setState(current => {
      return {
        ...current,
        lstBuyingPoint: {
          ...state["lstBuyingPoint"],
          valueList: js
        }
      }
    })
  }

  const clearBuyingPointTransferList = () => {
    disable(thisObj, "btnAddTransferBuyingPoint")
    disable(thisObj, "btnDeleteTransferBuyingPoint")
    let js = [];
    thisObj.setState(current => {
      return {
        ...current,
        lstTransferBuyingPoint: {
          ...state["lstTransferBuyingPoint"],
          valueList: js
        }
      }
    });
  }

  const clearWeigherList = () => {
    disable(thisObj, "btnAddWeighers")
    disable(thisObj, "btnEditWeighers")
    disable(thisObj, "btnDeleteWeighers")
    let js = [];
    thisObj.setState(current => {
      return {
          ...current,
          lstWeighers: {
            ...state["lstWeighers"],
          valueList: js
          }
        }
    });
  }

  //Method for binding Area List dropdown
  const bFillAreaList = async () => {
    let js = [];
    let response = await SettlementService.RetrieveAreaControlDetails(null)
    let data = response
    if (data !== null && data !== undefined) {
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].areaId, description: data[i].areaName, index: i, handlerLocNum: data[i].handlerLocNum }
        js.push(obj)
      }
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddArea: {
          ...state["ddArea"],
          valueList: js,
        }
      }
    })
    if(js.length > 0){
      enable(thisObj, "btnAddArea")
      enable(thisObj, "btnEditArea")
      enable(thisObj, "btnDeleteArea")
      setValue(thisObj, 'ddArea', js[0].key);
      bFillCollectionPointList(js[0].key);
    } else {
      enable(thisObj, "btnAddArea")
      disable(thisObj, "btnEditArea")
      disable(thisObj, "btnDeleteArea")
      clearCollectionPointList();
      clearBuyingPointList();
      clearBuyingPointTransferList();
      clearWeigherList();
    }
    EnableDisableControlsI();
  }

  //Method for binding Collection Point List
  const bFillCollectionPointList = async (areaId) => {
    let js = [];
    let response = await SystemMaintenanceMasterManagementService.RetrieveCollectionPointControlDetails(compId, cropyear, null, areaId)
    let data = response
    if (data !== null && data !== undefined) {
      for (var i = 0; i < data.length; i++) {
        let obj = { key:data[i].coll_pt_id, description: data[i].coll_pt_id, index: i }
        js.push(obj)
      }
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddCollectionPoint: {
          ...state["ddCollectionPoint"],
          valueList: js
        }
      }
    })
    if(js.length > 0){
      enable(thisObj, "btnAddCollectionPoint")
      enable(thisObj, "btnEditCollectionPoint")
      enable(thisObj, "btnDeleteCollectionPoint")
      setValue(thisObj, 'ddCollectionPoint', js[0].key);
      bFillBuyingPointList(getValue(thisObj, 'ddArea'), js[0].key);
    } else {
      enable(thisObj, "btnAddCollectionPoint")
      disable(thisObj, "btnEditCollectionPoint")
      disable(thisObj, "btnDeleteCollectionPoint")
      clearBuyingPointList();
      clearBuyingPointTransferList();
      clearWeigherList();
    }
    EnableDisableControlsI();
  }

  //Method for binding Buying Point
  const bFillBuyingPointList = async (areaId, collectionPointId) => {
    let js = [];
    let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0020', null, areaId, collectionPointId, null)
    let data = response
    if (data !== null && data !== undefined) {
      for (var i = 0; i < data.length; i++) {
        let obj = {}
        if (data[i].status_ind === 'I') {
          obj = {key: data[i].buy_pt_id, description: data[i].buy_pt_id + " - [ INACTIVE ] - " + data[i].buy_pt_name, index: i}
        }
        else  {
          obj = {key: data[i].buy_pt_id, description: data[i].buy_pt_id + " - " + data[i].buy_pt_name, index: i}
        }         
        js.push(obj)
      }
    }
    thisObj.setState(current => {
      return {
        ...current,
        lstBuyingPoint: {
          ...state["lstBuyingPoint"],
          valueList: js
        }
      }
    })
    if(js.length > 0){
      enable(thisObj, "btnAddBuyingPoint")
      enable(thisObj, "btnEditBuyingPoint")
      enable(thisObj, "btnDeleteBuyingPoint")
      setValue(thisObj, 'lstBuyingPoint', js[0].key);
      bFillBuyingPointTransferList(js[0].key);
      bFillWeigherList(js[0].key);
    } else {
      enable(thisObj, "btnAddBuyingPoint")
      disable(thisObj, "btnEditBuyingPoint")
      disable(thisObj, "btnDeleteBuyingPoint")
      clearBuyingPointTransferList();
      clearWeigherList();
    }
    EnableDisableControlsI();
  }

  //Method for binding Buying Point Tranfer List -- PN0020
  const bFillBuyingPointTransferList = async (buyingPointId) => {
    // clearBuyingPointTransferList();
    let js = [];
    let response = await SettlementService.RetrieveTransferBuyingPointDetails(buyingPointId)
    let data = response
    if (data !== null && data !== undefined) {
      for (var i = 0; i < data.length; i++) {
        js.push({
          key: data[i].xfer_buy_pt_id, 
          description: data[i].xfer_buy_pt_id + " - " + data[i].xfer_buy_pt_name,
          index: i
        })
      }
      thisObj.setState(current => {
        return {
          ...current,
          lstTransferBuyingPoint: {
            ...state["lstTransferBuyingPoint"],
            valueList: js
          }
        }
      })
    }
    if(js.length>0){
      enable(thisObj, "btnAddTransferBuyingPoint")
      enable(thisObj, "btnDeleteTransferBuyingPoint")
      setValue(thisObj, 'lstTransferBuyingPoint', js[0].key);
    }else {
      enable(thisObj, "btnAddTransferBuyingPoint")
      disable(thisObj, "btnDeleteTransferBuyingPoint")
    }
    EnableDisableControlsI();
  }

  //Method for binding Weigher List
  const bFillWeigherList = async (buyingPointId) => {
    clearWeigherList();
    let js = []
    let response = await SystemMaintenanceMasterManagementService.RetrieveWeigherControlDetails(buyingPointId)
    let data = response
    if (data !== null && data !== undefined) {
      for (var i = 0; i < data.length; i++) {
        let strDflt = ''
        if(data[i].dflt_weigher_ind === 'Y') {
          strDflt = " (default)"
        }
        js.push({
          key: data[i].weigher_name,
          description: data[i].weigher_name + strDflt,
          index: i
        })
      }
      thisObj.setState(current => {
        return {
        ...current,
        lstWeighers: {
            ...state["lstWeighers"],
            valueList: js
        }
        }
      });
      if(js.length>0){
        enable(thisObj, "btnAddWeighers")
        enable(thisObj, "btnEditWeighers")
        enable(thisObj, "btnDeleteWeighers")
        setValue(thisObj, 'lstWeighers', js[0].key);
      } else {
        enable(thisObj, "btnAddWeighers")
        disable(thisObj, "btnEditWeighers")
        disable(thisObj, "btnDeleteWeighers")
      }
      EnableDisableControlsI();
    }
  }

  // Change events
  const onAreaChange = async () => {
    try {  
      let AreaList = thisObj.state['ddArea'].valueList;
      let SelectedAreaListValue = thisObj.values['ddArea'];
      if (SelectedAreaListValue !== "" && SelectedAreaListValue !== undefined) {
        areaId = AreaList.find(elem => elem.key === SelectedAreaListValue).key;
      }
      await bFillCollectionPointList(areaId)
      //EnableDisableControlsI();
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Area List dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddAreaChange = onAreaChange;

  const onCollectionPointChange = async () => {
    try {
      let AreaList = thisObj.state['ddArea'].valueList;
      let SelectedAreaListValue = thisObj.values['ddArea'];
      let areaId = ''
      if (SelectedAreaListValue !== "" && SelectedAreaListValue !== undefined) {
        areaId = AreaList.find(elem => elem.key === SelectedAreaListValue).key;
      }

      let CollectionPointList = thisObj.state['ddCollectionPoint'].valueList;
      let SelectedCollectionPointListValue = thisObj.values['ddCollectionPoint'];
      let collectionPointId = ''
      if (SelectedCollectionPointListValue !== "" && SelectedCollectionPointListValue !== undefined) {
        collectionPointId = CollectionPointList.find(elem => elem.key === SelectedCollectionPointListValue).key;
      }

      await bFillBuyingPointList(areaId, collectionPointId);
      //EnableDisableControlsI();
    }

    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Collection Point dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddCollectionPointChange = onCollectionPointChange;

  const onBuyingPointChange = async () => {
    try {
      let BuyingPointList = thisObj.state['lstBuyingPoint'].valueList;
      let SelectedBuyingPointValue = thisObj.values['lstBuyingPoint'];
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key == SelectedBuyingPointValue).key;
      }
      await bFillBuyingPointTransferList(buyingPointId)
      await bFillWeigherList(buyingPointId)
      //EnableDisableControlsI();
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Buying Point List change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onlstBuyingPointChange = onBuyingPointChange;

  //Button click events
  const onbtnAddAreaClick = () => {
    try {
      let data = {
        "txtID_enabled": true,
        "btnOk": "Add"
      }
      setData(thisObj, 'BPSetupAreaData', data);
      goTo(thisObj, "SystemMaintenanceMasterManagement#AreaProfile#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Area Profile Screen has loaded with errors."
        );
      }
    return false;
    }
  };
  thisObj.onbtnAddAreaClick = onbtnAddAreaClick;

  const onbtnEditAreaClick = () => {
    try {
      let areaId = '';
      let areaname='';
      let areaList = thisObj.state['ddArea'].valueList;
      let selectedAreaListValue = thisObj.values['ddArea'];
      if (selectedAreaListValue !== "" && selectedAreaListValue !== undefined) {
        areaId = areaList.find(elem => elem.key === selectedAreaListValue).key;
        areaname = areaList.find(elem => elem.key === selectedAreaListValue).description;
      }
      SettlementService.RetrieveAreaControlDetails(areaId).then(response => {
        let data = response;
        if (data !== null && data !== undefined) {
          let temp1 = [];
          let temp2 = [];
          let tempObj1 = {};
          let tempObj2 = {};

          let chklandplaster = '';
          if(data[0].landpAddemInd == 'Y') {
            chklandplaster = '1'
          }

          let chkFmAgreement = '';
          if(data[0].fmAgreeInd == 'Y') {
            chkFmAgreement = '1'
          }

          tempObj1.lblAddedById = data[0].addUser;
          tempObj1.txtDate=moment(data[0].addDateTime).format("MM/DD/YYYY");
          temp1.push(tempObj1);

          tempObj2.lblChangedById = data[0].chgUser;
          tempObj2.txtDate=moment(data[0].chgDateTime).format("MM/DD/YYYY"); //it should be change datetime
          temp2.push(tempObj2);

          let data2 = {
            "btnOk": "Update",
            "txtID_enabled": false,
            "txtID": areaId,
            "ddArea": areaname,
            "txtHandlerLocNumber": data[0].handlerLocNum,
            "txtCCCHandler": data[0].cccHandlerNum,
            "chkLandplasterAddAllowed": chklandplaster,
            "chkFmAgreementAddAllowed": chkFmAgreement,
            "txtMinKernHull": data[0].minKernHullPct,
            "txtMaxKernHull": data[0].maxKernHullPct,
            "txtClearingAccount": data[0].clearAcct,
            "lstLbsTons": data[0].displayLbsTon,
            "lblAddedBy": temp1,
            "lblChangedBy": temp2,
            "txtContractMaxDay": data[0].pendingContractMaxDays
          }
          setData(thisObj, 'BPSetupAreaData', data2)
          goTo(thisObj, "SystemMaintenanceMasterManagement#AreaProfile#DEFAULT#true#Click")
        }
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnEdit event:Click"
        );
      }
      return false;
    }
  };
  thisObj.onbtnEditAreaClick = onbtnEditAreaClick;

  const onbtnDeleteAreaClick = () => {
    try {
      let obj = {}
      let AreaList = thisObj.state['ddArea'].valueList;
      let SelectedAreaListValue = thisObj.values['ddArea'];
      if (SelectedAreaListValue !== "" && SelectedAreaListValue !== undefined) {
        obj.areaId = AreaList.find(elem => elem.key === SelectedAreaListValue).key;
        obj.areaName = AreaList.find(elem => elem.key === SelectedAreaListValue).description;
      }
      
      const confirmBox = confirm(`Are you sure you wish to delete this area '${obj.areaName}'`)
      if (confirmBox == true) {
        SystemMaintenanceMasterManagementService.RemoveAreaControl(null, obj.areaId).then(response => {
          let data = response;
          if (data.status == 200) {
            showMessage(thisObj, data.message, true);
            bFillAreaList();
          }else{
            showMessage(thisObj, data.message, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
          }
        })
      }
    }  
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnDelete event:Click"
        );
      }
      return false;
    }
  };
  thisObj.onbtnDeleteAreaClick = onbtnDeleteAreaClick;
  
  const onbtnAddCollectionPointClick = () => {
    try {
      let obj = {}
      let AreaList = thisObj.state['ddArea'].valueList;
      let SelectedAreaListValue = thisObj.values['ddArea'];
      if (SelectedAreaListValue !== "" && SelectedAreaListValue !== undefined) {
        obj.areaId = AreaList.find(elem => elem.key === SelectedAreaListValue).key;
        obj.areaName = AreaList.find(elem => elem.key === SelectedAreaListValue).description;
      }
      let data = {
        "txtID_enabled": true,
        "btnOk": "Add",
        "lblArea": obj.areaName,
        "AreaId" : obj.areaId
      }
      setData(thisObj, 'BPSetupCollectionPointData', data);
      goTo(thisObj, "SystemMaintenanceMasterManagement#CollectionPointProfile#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj,"Collection Point Profile Screen has loaded with errors.");
      }
      return false;
    }
  };
  thisObj.onbtnAddCollectionPointClick = onbtnAddCollectionPointClick;
    
  const onbtnEditCollectionPointClick = () => {
    try {
      let obj = {}
      let AreaList = thisObj.state['ddArea'].valueList;
      let SelectedAreaListValue = thisObj.values['ddArea'];
      if (SelectedAreaListValue !== "" && SelectedAreaListValue !== undefined) {
        obj.areaId = AreaList.find(elem => elem.key === SelectedAreaListValue).key;
        obj.areaName = AreaList.find(elem => elem.key === SelectedAreaListValue).description;
      }

      let collectionPointList = thisObj.state['ddCollectionPoint'].valueList;
      let selectedCollectionPointListValue = thisObj.values['ddCollectionPoint'];
      if (selectedCollectionPointListValue !== "" && selectedCollectionPointListValue !== undefined) {
        obj.collectionPointId = collectionPointList.find(elem => elem.key === selectedCollectionPointListValue).key;
      }

      SystemMaintenanceMasterManagementService.RetrieveCollectionPointControlDetails(compId, cropyear, obj.collectionPointId, obj.areaId).then(response => {
        let data = response;
        if (data !== null && data !== undefined) {
          let temp1 = [];
          let temp2 = [];
          let tempObj1 = {};
          let tempObj2 = {};

          tempObj1.lblAddedById = data[0].add_user;
          tempObj1.txtDate=moment(data[0].add_date_time).format("MM/DD/YYYY");
          temp1.push(tempObj1);

          tempObj2.lblChangedById = data[0].chg_user;
          tempObj2.txtDate=moment(data[0].chg_date_time).format("MM/DD/YYYY"); //it should be change datetime
          temp2.push(tempObj2);

          let data2 = {
            "btnOk": "Update",
            "txtID_enabled": false,
            "txtID": obj.collectionPointId,
            "lblArea": obj.areaName,
            "AreaId" : obj.areaId,
            "lblAddedBy": temp1,
            "lblChangedBy": temp2,
          }
          setData(thisObj, 'BPSetupCollectionPointData', data2)
          goTo(thisObj, "SystemMaintenanceMasterManagement#CollectionPointProfile#DEFAULT#true#Click")
        }
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnEdit event:Click");
      }
      return false;
    }
  };
  thisObj.onbtnEditCollectionPointClick = onbtnEditCollectionPointClick;

  const onbtnDeleteCollectionPointClick = () => {
    try {
        let obj = {}
        let AreaList = thisObj.state['ddArea'].valueList;
        let SelectedAreaListValue = thisObj.values['ddArea'];
        if (SelectedAreaListValue !== "" && SelectedAreaListValue !== undefined) {
          obj.areaId = AreaList.find(elem => elem.key === SelectedAreaListValue).key;
          obj.areaName = AreaList.find(elem => elem.key === SelectedAreaListValue).description;
        }
        let collectionPointList = thisObj.state['ddCollectionPoint'].valueList;
        let selectedCollectionPointListValue = thisObj.values['ddCollectionPoint'];
        if (selectedCollectionPointListValue !== "" && selectedCollectionPointListValue !== undefined) {
          obj.collectionPointId = collectionPointList.find(elem => elem.key === selectedCollectionPointListValue).key;
          obj.collectionPointName = collectionPointList.find(elem => elem.key === selectedCollectionPointListValue).description;
        }

        const confirmBox = confirm(`Are you sure you wish to delete this collection point '${obj.collectionPointName}'`)
        if (confirmBox == true) {
          SystemMaintenanceMasterManagementService.RemoveCollectionPointControldetails(null, obj.collectionPointId).then(response => {
            let data = response;
            if (data.status == 200) {
              showMessage(thisObj, data.message, true);
              bFillCollectionPointList(obj.areaId);
            }else{
              showMessage(thisObj, data.message, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
            }  
          })
        }  
    } 
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnDelete event:Click"
        );
      }
      return false;
    }
  };
  thisObj.onbtnDeleteCollectionPointClick = onbtnDeleteCollectionPointClick;

  const onbtnAddBuyingPointClick = () => {
    try {
      let obj = {}
      let AreaList = thisObj.state['ddArea'].valueList;
      let SelectedAreaListValue = thisObj.values['ddArea'];
      if (SelectedAreaListValue !== "" && SelectedAreaListValue !== undefined) {
        obj.areaId = AreaList.find(elem => elem.key === SelectedAreaListValue).key;
        obj.areaName = AreaList.find(elem => elem.key === SelectedAreaListValue).description;
      }
      let CollectionPointList = thisObj.state['ddCollectionPoint'].valueList;
      let SelectedCollectionPointListValue = thisObj.values['ddCollectionPoint'];
      if (SelectedCollectionPointListValue !== "" && SelectedCollectionPointListValue !== undefined) {
        obj.collectionPointId = CollectionPointList.find(elem => elem.key === SelectedCollectionPointListValue).key;
        obj.collectionPointName = CollectionPointList.find(elem => elem.key === SelectedCollectionPointListValue).description;
      }

      let data = {
        "txtID_enabled": true,
        "btnOk": "Add",
        "AreaId" : obj.areaId,
        "lblArea": obj.areaName,
        "lblInactive_Visible": false,
        "btnActive_Visible": false,
        "cmdActive": "Make Inactive",
        "CollectionPointId": obj.collectionPointId,
        "CollectionPointName" : obj.collectionPointName
      }
      setData(thisObj, 'BPSetupBuyingPointData', data);
      goTo(thisObj, "SystemMaintenanceMasterManagement#BuyingPointProfile#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Buying Point Profile Screen has loaded with errors.");
      }
      return false;
    }
  };
  thisObj.onbtnAddBuyingPointClick = onbtnAddBuyingPointClick;

  const onbtnEditBuyingPointClick = () => {
    try {
      let obj = {}
      let userAccess = getData(thisObj, 'UserAccessValue');//Fixed As part of UAT BUG_543829
      let areaList = thisObj.state['ddArea'].valueList;
      let selectedAreaListValue = thisObj.values['ddArea'];
      if (selectedAreaListValue !== "" && selectedAreaListValue !== undefined) {
        obj.areaId = areaList.find(elem => elem.key === selectedAreaListValue).key;
        obj.areaName = areaList.find(elem => elem.key === selectedAreaListValue).description;
      }
      let collectionPointList = thisObj.state['ddCollectionPoint'].valueList;
      let selectedCollectionPointListValue = thisObj.values['ddCollectionPoint'];
      if (selectedCollectionPointListValue !== "" && selectedCollectionPointListValue !== undefined) {
        obj.collectionPointId = collectionPointList.find(elem => elem.key === selectedCollectionPointListValue).key;
        obj.collectionPointName = collectionPointList.find(elem => elem.key === selectedCollectionPointListValue).description;
      }
      let buyingPointList = thisObj.state['lstBuyingPoint'].valueList;
      let selectedBuyingPointValue = thisObj.values['lstBuyingPoint'];
      if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
        obj.buyingPointId = buyingPointList.find(elem => elem.key == selectedBuyingPointValue).key;
      }
      let userAccessData = getData(thisObj, 'UserAccessValue')

      SettlementService.RetrieveBuyingPointControlDetails('PN0020', null, obj.areaId, obj.collectionPointId, obj.buyingPointId).then(response => {
        let data = response;
        if (data !== null && data !== undefined) {
          let temp1 = [];
          let temp2 = [];
          let tempObj1 = {};
          let tempObj2 = {};
          let lblInactive_Visible = false
          let cmdActive = ''
          let chkAutoTransferNum = ''
          let chkNonGPCStorage = ''
          let chkGPCStorage = ''
          let chkGPCOwned = ''
          let chkShelling = ''
          let chkInOut = ''
          let chkLeased = ''
          let chkDPRExclusion = ''
          let chkEFV95UploadSelect = ''
          let Check_KCTRANS = ''
          let Check_EWRTRANS = ''
          let ddVicamLab = "No"
          let ddIrrigatedDryland = "No"
          let ddGramEntry = "No"
          let chkCMAInd = ''
          let txtDefaultCMAVendor = '' 
          let ddDefaultCMARemitTo = ''
          let chkOneDoc = ''
          let ddTransferGramEntry_index = 0
          let ddTransferMoistEntry_index = 0
          let txtNext1007 = ''
          let txtBeg1007 = ''
          let txtEnd1007 = ''
          let chkAdditionalRange = ''
          let txtBeg1_1007 = ''
          let txtEnd1_1007 = ''
          let txtNextWhouseCntl = ''
          let txtBegCheck = ''
          let txtEndCheck = ''
          let txtNextCheck = ''
          let optFTPLoggingOn = false
          let optFTPLoggingOff = false
          let optFTP0 = false
          let optFTP1 = false
          let txtTimerInterval = ''
          let txtRetryLimit = ''

          tempObj1.lblAddedById = data[0].addUser;
          tempObj1.txtDate=moment(data[0].addDateTime).format("MM/DD/YYYY");
          temp1.push(tempObj1);

          tempObj2.lblChangedById = data[0].chgUser;
          tempObj2.txtDate=moment(data[0].chgDateTime).format("MM/DD/YYYY"); //it should be change datetime
          temp2.push(tempObj2);

          if(data[0].statusInd == 'I') {
            lblInactive_Visible = true
            cmdActive = 'RE-Activate'
          }
          else {
            lblInactive_Visible = false
            cmdActive = 'Make Inactive'
          }
          if(data[0].xferAutoNumInd == 'Y') {
            chkAutoTransferNum = '1'
          }
          if(data[0].nonGpcStorage == 'Y') {
            chkNonGPCStorage = '1'
          }
          if(data[0].gpcStorage == 'Y') {
            chkGPCStorage = '1'
          }
          if(data[0].gpcOwned == 'Y') {
            chkGPCOwned = '1'
          }
          if(data[0].shellingPlant == 'Y') {
            chkShelling = '1'
          }
          if(data[0].noStorage == 'Y') {
            chkInOut = '1'
          }
          if(data[0].leasedLocation == 'Y') {
            chkLeased = '1'
          }
          if(data[0].dprExclusionInd == 'Y') {
            chkDPRExclusion = '1'
          }
          if(data[0].eFv95UploadSelectInd == 'Y') {
            chkEFV95UploadSelect = '1'
          }
          if(data[0].excludeFromKcTrans == 'Y') {
            Check_KCTRANS = '1'
          }
          if(data[0].excludeFromEwrTrans == 'Y') {
            Check_EWRTRANS = '1'
          }
          if(data[0].vicamLab == 'Y') {
            ddVicamLab = "Yes"
          }
          if(data[0].irrDryOn == 'Y') {
            ddIrrigatedDryland = "Yes"
          }
          if(data[0].gramEntryInd == 'Y') {
            ddGramEntry = "Yes"
          }
          if(data[0].cmaInd == 'Y') {
            chkCMAInd = '1'
            txtDefaultCMAVendor = data[0].defaultCmaVendorNum
            ddDefaultCMARemitTo = data[0].defaultCmaRemitTo
          }
          if(data[0].onedocInd == 'Y') {
            chkOneDoc = '1'
          }
          if(data[0].xferGramEntryInd == 'Y') {
            ddTransferGramEntry_index = 0
          }
          else {
            ddTransferGramEntry_index = 1
          }
          if(data[0].xferEditMoistPct == 'Y') {
            ddTransferMoistEntry_index = 0
          }
          else {
            ddTransferMoistEntry_index = 1
          }
          if(data[0].cmaInd == 'Y') {
            chkCMAInd = '1'
            txtDefaultCMAVendor = data[0].defaultCmaVendorNum
            ddDefaultCMARemitTo = data[0].defaultCmaRemitTo
          }
          if(data[0].onedocInd == 'Y') {
            chkOneDoc = '1'
          }
          
          txtNext1007 = (data[0].next1007).toString().padStart(7, '0')
          txtBeg1007 = (data[0].beg1007).toString().padStart(7, '0')
          txtEnd1007 = (data[0].end1007).toString().padStart(7, '0')
          if(data[0].additional1007 == 'Y') {            
            chkAdditionalRange = '1'
            txtBeg1_1007 = (data[0].beg11007).toString().padStart(7, '0')
            txtEnd1_1007 = (data[0].end11007).toString().padStart(7, '0')
          }
          txtNextWhouseCntl = (data[0].nextWhseCntl).toString().padStart(6, '0')
          txtBegCheck = (data[0].begCheck).toString().padStart(6, '0')
          txtEndCheck = (data[0].endCheck).toString().padStart(6, '0')
          txtNextCheck = (data[0].nextCheck).toString().padStart(6, '0')

          if(data[0].ftpEnableLog == 'Y') {
            optFTPLoggingOn = true 
          }
          else {
            optFTPLoggingOff = true 
          }

          if(typeof(data[0].ftpTimerInterval) == 'number') {
            txtTimerInterval = data[0].ftpTimerInterval
          }
          else {
            txtTimerInterval = '18000'
          }

          if(typeof(data[0].ftpRetryLimit) == 'number') {
            txtRetryLimit = data[0].ftpRetryLimit
          }
          else {
            txtRetryLimit = '10' 
          }
    
          if(data[0].ftpMethod == 'S') {
            optFTP1 = true 
          }
          else {
            optFTP0 = true
          }

          let datauat = false
          if(userAccess == "N"){//Fixed As part of UAT BUG_543829
            datauat = true
          }
          let data1 = {
            "txtID_enabled": false,
            "btnOk": "Update",
            "AreaId" : obj.areaId,
            "lblArea": obj.areaName,
            "CollectionPointId": obj.collectionPointId,
            "CollectionPointName" : obj.collectionPointName,
            "txtID": data[0].buyPtId,
            "lblInactive_Visible": lblInactive_Visible,
            "cmdActive": cmdActive,
            "txtGroupID": data[0].groupId,
            "profit_center": data[0].profitCenter,
            "txtBuyingPointName": data[0].buyPtName,
            "txtContactName": data[0].contactName,
            "txtContactPhone": data[0].buyPtPhone,
            "txtPAAddress": data[0].physicalAddress,
            "txtPACity": data[0].physicalCity,
            "lstPhyStateAbbr": data[0].physicalState,
            "lstCountyID": data[0].physicalCountyId,
            "txtPAZipCode": data[0].physicalZip,
            "txtMAAddress": data[0].mailingAddress,
            "txtMACity": data[0].mailingCity,
            "lstMailStateAbbr": data[0].mailingState,
            "txtMAZipCode": data[0].mailingZip,
            "txtMAFedBuyPoint": data[0].fedBuyPtId,
            "txtVendor": data[0].curedCleanVendor,
            "lstRemitTo": data[0].curedCleanRemit,
            "ddFedLic": data[0].fedWhouseLicense,
            "txtCCCLocNumber": data[0].cccLocationId,
            'chkAutoTransferNum': chkAutoTransferNum,
            'chkNonGPCStorage': chkNonGPCStorage,
            'chkGPCStorage': chkGPCStorage,
            'chkGPCOwned':chkGPCOwned,
            'chkShelling':chkShelling,
            'chkInOut':chkInOut,
            'chkLeased': chkLeased,
            'chkDPRExclusion': chkDPRExclusion,
            'chkEFV95UploadSelect': chkEFV95UploadSelect,
            'Check_KCTRANS': Check_KCTRANS,
            'Check_EWRTRANS': Check_EWRTRANS,
            'ddVicamLab': ddVicamLab,
            'ddIrrigatedDryland': ddIrrigatedDryland,
            'ddGramEntry': ddGramEntry,
            'chkCMAInd': chkCMAInd,
            'txtDefaultCMAVendor': txtDefaultCMAVendor, 
            'ddDefaultCMARemitTo': ddDefaultCMARemitTo,
            'chkOneDoc': chkOneDoc,
            'ddTransferGramEntry_index': ddTransferGramEntry_index,
            'ddTransferMoistEntry_index': ddTransferMoistEntry_index,
            'txtBalAcctResp': data[0].balAcctResp,
            'txtBalAcctDept': data[0].balAcctDept,
            'txtPlAcctResp': data[0].plAcctResp,
            'txtPlAcctDept': data[0].plAcctDept,
            'txtNext1007': txtNext1007,
            'txtBeg1007': txtBeg1007,
            'txtEnd1007': txtEnd1007,
            'chkAdditionalRange': chkAdditionalRange,
            'txtBeg1_1007': txtBeg1_1007,
            'txtEnd1_1007': txtEnd1_1007,
            'txtNextWhouseCntl': txtNextWhouseCntl,
            'txtBegCheck': txtBegCheck,
            'txtEndCheck': txtEndCheck,
            'txtNextCheck': txtNextCheck,
            "lblAddedBy": temp1,
            "lblChangedBy": temp2,
            'ddInternetTypeName': data[0].internetTypeFormatId, 
            'txtServiceProvider': data[0].serviceProvider, 
            'optFTPLoggingOn': optFTPLoggingOn,
            'optFTPLoggingOff': optFTPLoggingOff,
            'optFTP0': optFTP0, 
            'optFTP1': optFTP1,
            'txtTimerInterval': txtTimerInterval,
            'txtRetryLimit': txtRetryLimit,
            'userAccess': userAccess,
            "disableOld" : datauat//Fixed As part of UAT BUG_543829
          }
          setData(thisObj, 'BPSetupBuyingPointData', data1);
          goTo(thisObj, "SystemMaintenanceMasterManagement#BuyingPointProfile#DEFAULT#true#Click")
        }
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnEdit event:Click");
      }
      return false;
    }
  };
  thisObj.onbtnEditBuyingPointClick = onbtnEditBuyingPointClick;

  const onbtnDeleteBuyingPointClick = () => {
    try {
      let obj = {}
      let areaList = thisObj.state['ddArea'].valueList;
      let selectedAreaListValue = thisObj.values['ddArea'];
      if (selectedAreaListValue !== "" && selectedAreaListValue !== undefined) {
        obj.areaId = areaList.find(elem => elem.key === selectedAreaListValue).key;
        obj.areaName = areaList.find(elem => elem.key === selectedAreaListValue).description;
      }
      let collectionPointList = thisObj.state['ddCollectionPoint'].valueList;
      let selectedCollectionPointListValue = thisObj.values['ddCollectionPoint'];
      if (selectedCollectionPointListValue !== "" && selectedCollectionPointListValue !== undefined) {
        obj.collectionPointId = collectionPointList.find(elem => elem.key === selectedCollectionPointListValue).key;
        obj.collectionPointName = collectionPointList.find(elem => elem.key === selectedCollectionPointListValue).description;
      }
      let buyingPointList = thisObj.state['lstBuyingPoint'].valueList;
      let selectedBuyingPointValue = thisObj.values['lstBuyingPoint'];
      if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
        obj.buyingPointId = buyingPointList.find(elem => elem.key == selectedBuyingPointValue).key;
        obj.buyingPointName = buyingPointList.find(elem => elem.key == selectedBuyingPointValue).description;
      }
      const confirmBox = confirm(`Are you sure you wish to delete this buying point '${obj.buyingPointName}'`)
      if (confirmBox == true) {
        SystemMaintenanceMasterManagementService.RemoveBuyingPointControldetails(obj.buyingPointId, null).then(response => {
          let data = response;
          if (data.status == 200) {
            showMessage(thisObj, data.message, true);
            bFillBuyingPointList(obj.areaId, obj.collectionPointId);
          }else{
            showMessage(thisObj, response.message, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
          }
        })
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnDelete event:Click");
      }
      return false;
    }
  };
  thisObj.onbtnDeleteBuyingPointClick = onbtnDeleteBuyingPointClick;

  const onbtnAddTransferBuyingPointClick = () => {
    try {
      let obj = {}
      let buyingPointList = thisObj.state['lstBuyingPoint'].valueList
      let selectedBuyingPointValue = thisObj.values['lstBuyingPoint']
      if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
        obj.buyingPointId = buyingPointList.find(elem => elem.key == selectedBuyingPointValue).key;
        obj.buyingPointName = buyingPointList.find(elem => elem.key == selectedBuyingPointValue).description;
      }
      let data = {
        "btnOk": "Add",
        "lblBuyingPoint": obj.buyingPointName,
        "BuyingPointId" : obj.buyingPointId
      }
      setData(thisObj, 'BPSetupTransferBPData', data)
      goTo(thisObj, "SystemMaintenanceMasterManagement#BuyingPointTransfer#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Transfer Buying Point Profile Screen has loaded with errors.");
      }
      return false;
    }
  };
  thisObj.onbtnAddTransferBuyingPointClick = onbtnAddTransferBuyingPointClick;

  const onbtnDeleteTransferBuyingPointClick = () => {
    try {
      let obj = {}
      let buyingPointList = thisObj.state['lstBuyingPoint'].valueList
      let selectedBuyingPointValue = thisObj.values['lstBuyingPoint']
      if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
        obj.buyingPointId = buyingPointList.find(elem => elem.key == selectedBuyingPointValue).key;
        obj.buyingPointName = buyingPointList.find(elem => elem.key == selectedBuyingPointValue).description;
      }
  
      let transferBuyingPointList = thisObj.state['lstTransferBuyingPoint'].valueList;
      let selectedTransferBuyingPointValue = thisObj.values['lstTransferBuyingPoint'];
      if (selectedTransferBuyingPointValue !== "" && selectedTransferBuyingPointValue !== undefined) {
        obj.transferBuyingPointId = transferBuyingPointList.find(elem => elem.key == selectedTransferBuyingPointValue).key;
        obj.transferBuyingPointName = transferBuyingPointList.find(elem => elem.key == selectedTransferBuyingPointValue).description;
      }

      const confirmBox = confirm(`Are you sure you wish to delete this transfer buying point '${obj.transferBuyingPointName}'`)
      if (confirmBox == true) {
        SystemMaintenanceMasterManagementService.RemoveXferBuyingPointControldetails(obj.buyingPointId, null, obj.transferBuyingPointId).then(response => {
          let data = response;
          if (data.status == 200) {
            showMessage(thisObj, data.message, true);
            bFillBuyingPointTransferList(obj.buyingPointId);
          }else{
            showMessage(thisObj, response.message, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
          }
        })
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnDelete event:Click");
      }
      return false;
    }
  };
  thisObj.onbtnDeleteTransferBuyingPointClick = onbtnDeleteTransferBuyingPointClick;
  
  const onbtnAddWeighersClick = () => {
    try {
      let obj = {}
      let buyingPointList = thisObj.state['lstBuyingPoint'].valueList
      let selectedBuyingPointValue = thisObj.values['lstBuyingPoint']
      if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
        obj.buyingPointId = buyingPointList.find(elem => elem.key == selectedBuyingPointValue).key;
        obj.buyingPointName = buyingPointList.find(elem => elem.key == selectedBuyingPointValue).description;
      }
      let data = {
        "btnOk": "Add",
        "lblBuyingPoint": obj.buyingPointName,
        "BuyingPointId" : obj.buyingPointId
      }
      setData(thisObj, 'BPSetupWeighersData', data)
      goTo(thisObj, "SystemMaintenanceMasterManagement#WeigherProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Weigher Profile Screen has loaded with errors.");
      }
      return false;
    }
  };
  thisObj.onbtnAddWeighersClick = onbtnAddWeighersClick;

  const onbtnEditWeighersClick = async () => {
    try {
      let obj = {}
      let buyingPointList = thisObj.state['lstBuyingPoint'].valueList
      let selectedBuyingPointValue = thisObj.values['lstBuyingPoint']
      if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
        obj.buyingPointId = buyingPointList.find(elem => elem.key == selectedBuyingPointValue).key;
        obj.buyingPointName = buyingPointList.find(elem => elem.key == selectedBuyingPointValue).description;
      }

      let weighersList = thisObj.state['lstWeighers'].valueList
      let selectedWeighersValue = thisObj.values['lstWeighers']
      if (selectedWeighersValue !== "" && selectedWeighersValue !== undefined) {
        obj.weighersId = weighersList.find(elem => elem.key == selectedWeighersValue).key;
        obj.weighersName = weighersList.find(elem => elem.key == selectedWeighersValue).description;
      }
      
      let chkDefaultWeigher = ''
      if(obj.weighersName.includes("(default)")) {
        chkDefaultWeigher = '1'
      }
      let weigherSubstr = obj.weighersName.replace("(default)", '');

      let data = {
        'btnOk': 'Update',
        'lblBuyingPoint': obj.buyingPointName,
        'BuyingPointId' : obj.buyingPointId,
        'chkDefaultWeigher': chkDefaultWeigher,
        'lblWeigher': weigherSubstr,
        'txtWeigher': weigherSubstr
      }
      setData(thisObj, 'BPSetupWeighersData', data)
      goTo(thisObj, "SystemMaintenanceMasterManagement#WeigherProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnEdit event:Click");
      }
      return false;
    }
  
  };
  thisObj.onbtnEditWeighersClick = onbtnEditWeighersClick;

  const onbtnDeleteWeighersClick = () => {
    try {
      let obj = {}
      let buyingPointList = thisObj.state['lstBuyingPoint'].valueList
      let selectedBuyingPointValue = thisObj.values['lstBuyingPoint']
      if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
        obj.buyingPointId = buyingPointList.find(elem => elem.key == selectedBuyingPointValue).key;
        obj.buyingPointName = buyingPointList.find(elem => elem.key == selectedBuyingPointValue).description;
      }

      let weighersList = thisObj.state['lstWeighers'].valueList;
      let selectedWeighersValue = thisObj.values['lstWeighers'];
      if (selectedWeighersValue !== "" && selectedWeighersValue !== undefined) {
        obj.weighersId = weighersList.find(elem => elem.key == selectedWeighersValue).key;
        obj.weighersName = weighersList.find(elem => elem.key == selectedWeighersValue).description;
      }
      
      const confirmBox = confirm(`Are you sure you wish to delete this weigher '${obj.weighersName}'`)
      if (confirmBox == true) {
        obj.weighersId = Buffer.from(obj.weighersId).toString('base64');
        SystemMaintenanceMasterManagementService.RemoveWeigherControlDetails(obj.buyingPointId, null, obj.weighersId).then(response => {
          let data = response;
          if (data.status == 200) {
            showMessage(thisObj, data.message, true);
            bFillWeigherList(obj.buyingPointId);
          }else{
            showMessage(thisObj, response.message);
          }
        })
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnDelete event:Click");
      }
      return false;
    }
  };
  thisObj.onbtnDeleteWeighersClick = onbtnDeleteWeighersClick;

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_BuyingPointSetup*/}

              {/* END_USER_CODE-USER_BEFORE_BuyingPointSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxBuyingPointDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxBuyingPointDetails*/}

              <GroupBoxWidget
                conf={state.grpbxBuyingPointDetails}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions1*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions1*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions1}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnAddArea*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddArea*/}

                  <ButtonWidget
                    conf={state.btnAddArea}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddArea*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddArea*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEditArea*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEditArea*/}

                  <ButtonWidget
                    conf={state.btnEditArea}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEditArea*/}

                  {/* END_USER_CODE-USER_AFTER_btnEditArea*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteArea*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteArea*/}

                  <ButtonWidget
                    conf={state.btnDeleteArea}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteArea*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteArea*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions1*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions1*/}
                {/* START_USER_CODE-USER_BEFORE_ddArea*/}

                {/* END_USER_CODE-USER_BEFORE_ddArea*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddArea}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddArea*/}

                {/* END_USER_CODE-USER_AFTER_ddArea*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions2*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions2*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions2}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnAddCollectionPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddCollectionPoint*/}

                  <ButtonWidget
                    conf={state.btnAddCollectionPoint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddCollectionPoint*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddCollectionPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEditCollectionPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEditCollectionPoint*/}

                  <ButtonWidget
                    conf={state.btnEditCollectionPoint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEditCollectionPoint*/}

                  {/* END_USER_CODE-USER_AFTER_btnEditCollectionPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteCollectionPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteCollectionPoint*/}

                  <ButtonWidget
                    conf={state.btnDeleteCollectionPoint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteCollectionPoint*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteCollectionPoint*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions2*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions2*/}
                {/* START_USER_CODE-USER_BEFORE_ddCollectionPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddCollectionPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddCollectionPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddCollectionPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddCollectionPoint*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions3*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions3*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions3}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnAddBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddBuyingPoint*/}

                  <ButtonWidget
                    conf={state.btnAddBuyingPoint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddBuyingPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEditBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEditBuyingPoint*/}

                  <ButtonWidget
                    conf={state.btnEditBuyingPoint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEditBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_btnEditBuyingPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteBuyingPoint*/}

                  <ButtonWidget
                    conf={state.btnDeleteBuyingPoint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteBuyingPoint*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions3*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions3*/}
                {/* START_USER_CODE-USER_BEFORE_lstBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_lstBuyingPoint*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstBuyingPoint}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_lstBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxTransfers*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxTransfers*/}

                <GroupBoxWidget conf={state.grpbxTransfers} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblTransfersOrObligationLocations*/}

                  {/* END_USER_CODE-USER_BEFORE_lblTransfersOrObligationLocations*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblTransfersOrObligationLocations}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblTransfersOrObligationLocations*/}

                  {/* END_USER_CODE-USER_AFTER_lblTransfersOrObligationLocations*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAddTransferBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddTransferBuyingPoint*/}

                  <ButtonWidget
                    conf={state.btnAddTransferBuyingPoint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddTransferBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddTransferBuyingPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteTransferBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteTransferBuyingPoint*/}

                  <ButtonWidget
                    conf={state.btnDeleteTransferBuyingPoint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteTransferBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteTransferBuyingPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_lstTransferBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_lstTransferBuyingPoint*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstTransferBuyingPoint}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstTransferBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_lstTransferBuyingPoint*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxTransfers*/}

                {/* END_USER_CODE-USER_AFTER_grpbxTransfers*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxWeighers*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxWeighers*/}

                <GroupBoxWidget conf={state.grpbxWeighers} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblWeighers*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWeighers*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWeighers}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWeighers*/}

                  {/* END_USER_CODE-USER_AFTER_lblWeighers*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAddWeighers*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAddWeighers*/}

                  <ButtonWidget
                    conf={state.btnAddWeighers}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAddWeighers*/}

                  {/* END_USER_CODE-USER_AFTER_btnAddWeighers*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEditWeighers*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEditWeighers*/}

                  <ButtonWidget
                    conf={state.btnEditWeighers}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEditWeighers*/}

                  {/* END_USER_CODE-USER_AFTER_btnEditWeighers*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeleteWeighers*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeleteWeighers*/}

                  <ButtonWidget
                    conf={state.btnDeleteWeighers}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeleteWeighers*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeleteWeighers*/}
                  {/* START_USER_CODE-USER_BEFORE_lstWeighers*/}

                  {/* END_USER_CODE-USER_BEFORE_lstWeighers*/}

                  <ListboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lstWeighers}
                    screenConf={state}
                  ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lstWeighers*/}

                  {/* END_USER_CODE-USER_AFTER_lstWeighers*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxWeighers*/}

                {/* END_USER_CODE-USER_AFTER_grpbxWeighers*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxBuyingPointDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxBuyingPointDetails*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions4*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions4*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions4}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btncmdEnableDisableControls1*/}

                {/* END_USER_CODE-USER_BEFORE_btncmdEnableDisableControls1*/}

                {/* <ButtonWidget
                  conf={state.btncmdEnableDisableControls1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget> */}
                {/* START_USER_CODE-USER_AFTER_btncmdEnableDisableControls1*/}

                {/* END_USER_CODE-USER_AFTER_btncmdEnableDisableControls1*/}
                {/* START_USER_CODE-USER_BEFORE_btncmdEnableDisableControls2*/}

                {/* END_USER_CODE-USER_BEFORE_btncmdEnableDisableControls2*/}

                {/* <ButtonWidget
                  conf={state.btncmdEnableDisableControls2}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget> */}
                {/* START_USER_CODE-USER_AFTER_btncmdEnableDisableControls2*/}

                {/* END_USER_CODE-USER_AFTER_btncmdEnableDisableControls2*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions4*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions4*/}

              {/* START_USER_CODE-USER_AFTER_BuyingPointSetup*/}

              {/* END_USER_CODE-USER_AFTER_BuyingPointSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_BuyingPointSetup);
