import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import NumberFormat from "react-number-format";
import {getPlaceholder,getMaxlength,checkInvalid,getInputType,getSize,getTextBoxClasses,getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility,
  getTooltip,getWidgetLabel,isDispFormatReqd,getDispFormat} from "../ParentWidgetFunc";

function TextBoxWidget(props){
  //let labelWidth = props.conf.labelWidth || 4;
  let conf = props.conf;
  let screenConf = props.screenConf;
  let textControl = (
      <Form.Control
        placeholder={getPlaceholder(conf)}
        size={screenConf.horizontalForm ? "" : getSize(conf)}
        autoComplete={conf.AutoCompleteForTextBox}
        disabled={!conf.Enabled}
        readOnly={conf.ReadOnly}
        type={getInputType(conf)}
        className={getTextBoxClasses(conf)}
        name={conf.name}
        value={props.values[conf.name] ? props.values[conf.name] : ""}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        onBlur={props.onBlur}
        isInvalid={checkInvalid(props,conf)}
	    maxLength={getMaxlength(conf)}
	    data-testid={conf.name}
      id={conf.name}
      onPaste={props.onPaste}
      onFocus = {props.onFocus}
      />
    );

    if (conf.formattingReqd) {
      textControl = (
        <Form.Control
          isNumericString={true}
          thousandSeparator={false}
          placeholder={getPlaceholder(conf)}
          size={screenConf.horizontalForm ? "" : getSize(conf)}
          autoComplete={conf.AutoCompleteForTextBox}
          disabled={!conf.Enabled}
          readOnly={conf.ReadOnly}
          type={getInputType(conf)}
          className={getTextBoxClasses(conf)}
          name={conf.name}
          value={props.values[conf.name]}
          onChange={e => {
            let value = e.target.value;
            e.target.value = value.replace(new RegExp(",", "g"), "");
            props.onChange(e);
          }}
          onBlur={props.onBlur}
          isInvalid={checkInvalid(props,conf)}
          customInput={Form.Control}
          as={NumberFormat}
          data-testid={conf.name}
          id={conf.name}
          onPaste={props.onPaste}
          onFocus = {props.onFocus}
          maxLength={getMaxlength(conf)}
        />
      );
    }

    return (
      <Form.Group
        className={getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"}
		title={getTooltip(conf)}
		role="tooltip"
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
            onClick={event => event.preventDefault()}
            htmlFor={conf.name}
          >
            {getWidgetLabel(conf)}
            {isDispFormatReqd() ? <div>{getDispFormat(conf)}</div> : ""}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>
              {textControl}
              <Form.Control.Feedback type="invalid" aria-live="polite" role="alert">
                {conf.errorPosition==="field" ? props.errors[conf.name] : null}
              </Form.Control.Feedback>
            </Col>
          ) : (
            <React.Fragment>
              {textControl}
              <Form.Control.Feedback type="invalid" aria-live="polite" role="alert">
                {conf.errorPosition==="field" ? props.errors[conf.name] : null}
              </Form.Control.Feedback>
            </React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default TextBoxWidget;
