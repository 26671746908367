/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
} from "../../shared/WindowImports";

import "./DelvPremiumDeductions.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_DelvPremiumDeductions(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DelvPremiumDeductions",
    windowName: "DelvPremiumDeductions",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.DelvPremiumDeductions",
    // START_USER_CODE-USER_DelvPremiumDeductions_PROPERTIES
    horizontalForm : true,
    headerData: {
      scrName: "Premiums/Deductions",
      scrCode: "PN1180E",
    },
    // END_USER_CODE-USER_DelvPremiumDeductions_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btncol1: {
      name: "btncol1",
      type: "ButtonWidget",
      colName: "col1",
      parent: "gridPremiumDeductions",
      Label: "...",
      CharWidth: "9",
      Height: "",
      Width: "",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncol1_PROPERTIES

      // END_USER_CODE-USER_btncol1_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },
    col2: {
      name: "col2",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col2_PROPERTIES

      // END_USER_CODE-USER_col2_PROPERTIES
    },
    col3: {
      name: "col3",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col3_PROPERTIES

      // END_USER_CODE-USER_col3_PROPERTIES
    },
    colPremDeduct: {
      name: "colPremDeduct",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      Label: "Premium/Deduction",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPremDeduct_PROPERTIES

      // END_USER_CODE-USER_colPremDeduct_PROPERTIES
    },
    colPurchaseAmount: {
      name: "colPurchaseAmount",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      Label: "Purchase Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPurchaseAmount_PROPERTIES

      // END_USER_CODE-USER_colPurchaseAmount_PROPERTIES
    },
    colStorageAmount: {
      name: "colStorageAmount",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      Label: "Storage Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStorageAmount_PROPERTIES

      // END_USER_CODE-USER_colStorageAmount_PROPERTIES
    },
    gridPremiumDeductions: {
      name: "gridPremiumDeductions",
      type: "GridWidget",
      parent: "grpbxDelvPremiumDeductions",
      gridCellsOrder:
        "btncol1,txtcol2,txtcol3,txtcolPremDeduct,txtcolPurchaseAmount,txtcolStorageAmount",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridPremiumDeductions_PROPERTIES

      // END_USER_CODE-USER_gridPremiumDeductions_PROPERTIES
    },
    lblPremDeduct: {
      name: "lblPremDeduct",
      type: "LabelWidget",
      parent: "grpbxDelvPremiumDeductions",
      Label: "Premiums/Deductions:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPremDeduct_PROPERTIES

      // END_USER_CODE-USER_lblPremDeduct_PROPERTIES
    },
    txtcol2: {
      name: "txtcol2",
      type: "TextBoxWidget",
      colName: "col2",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol2_PROPERTIES

      // END_USER_CODE-USER_txtcol2_PROPERTIES
    },
    txtcol3: {
      name: "txtcol3",
      type: "TextBoxWidget",
      colName: "col3",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol3_PROPERTIES

      // END_USER_CODE-USER_txtcol3_PROPERTIES
    },
    txtcolPremDeduct: {
      name: "txtcolPremDeduct",
      type: "TextBoxWidget",
      colName: "colPremDeduct",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPremDeduct_PROPERTIES

      // END_USER_CODE-USER_txtcolPremDeduct_PROPERTIES
    },
    txtcolPurchaseAmount: {
      name: "txtcolPurchaseAmount",
      type: "TextBoxWidget",
      colName: "colPurchaseAmount",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPurchaseAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolPurchaseAmount_PROPERTIES
    },
    txtcolStorageAmount: {
      name: "txtcolStorageAmount",
      type: "TextBoxWidget",
      colName: "colStorageAmount",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStorageAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolStorageAmount_PROPERTIES
    },
    txtConvertedValue: {
      name: "txtConvertedValue",
      type: "TextBoxWidget",
      parent: "grpbxDelvPremiumDeductions",
      Label: "Converted Value:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConvertedValue_PROPERTIES

      // END_USER_CODE-USER_txtConvertedValue_PROPERTIES
    },
    txtNetProceeds: {
      name: "txtNetProceeds",
      type: "TextBoxWidget",
      parent: "grpbxDelvPremiumDeductions",
      Label: "Net Proceeds:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetProceeds_PROPERTIES

      // END_USER_CODE-USER_txtNetProceeds_PROPERTIES
    },
    grpbxDelvPremiumDeductions: {
      name: "grpbxDelvPremiumDeductions",
      type: "GroupBoxWidget",
      parent: "DelvPremiumDeductions",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDelvPremiumDeductions_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvPremiumDeductions_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "DelvPremiumDeductions",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DelvPremiumDeductions*/}

              {/* END_USER_CODE-USER_BEFORE_DelvPremiumDeductions*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDelvPremiumDeductions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDelvPremiumDeductions*/}

              <GroupBoxWidget
                conf={state.grpbxDelvPremiumDeductions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblPremDeduct*/}

                {/* END_USER_CODE-USER_BEFORE_lblPremDeduct*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPremDeduct}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPremDeduct*/}

                {/* END_USER_CODE-USER_AFTER_lblPremDeduct*/}
                {/* START_USER_CODE-USER_BEFORE_txtConvertedValue*/}

                {/* END_USER_CODE-USER_BEFORE_txtConvertedValue*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtConvertedValue}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtConvertedValue*/}

                {/* END_USER_CODE-USER_AFTER_txtConvertedValue*/}
                {/* START_USER_CODE-USER_BEFORE_gridPremiumDeductions*/}

                {/* END_USER_CODE-USER_BEFORE_gridPremiumDeductions*/}

                <GridWidget
                  conf={state.gridPremiumDeductions}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPremiumDeductions}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPremiumDeductions*/}

                {/* END_USER_CODE-USER_AFTER_gridPremiumDeductions*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetProceeds*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetProceeds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetProceeds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetProceeds*/}

                {/* END_USER_CODE-USER_AFTER_txtNetProceeds*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDelvPremiumDeductions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDelvPremiumDeductions*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_DelvPremiumDeductions*/}

              {/* END_USER_CODE-USER_AFTER_DelvPremiumDeductions*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_DelvPremiumDeductions);
