/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_VendorSearch from "./VendorSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("VendorSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_VendorSearch />);
    });
  });
  afterEach(cleanup);
  test("is VendorSearch Loads Successfully", () => {
    expect(screen.getByText("VendorSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for VendorSearch", () => {
    // START_USER_CODE-USER_VendorSearch_Custom_Test_Case
    // END_USER_CODE-USER_VendorSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_VendorSearch />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorSearch_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorSearch_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorSearch_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnPrintSearch(Button Widget) Test Cases", async () => {
    const btnPrintSearch = screen.getByTestId("btnPrintSearch");
    expect(btnPrintSearch).toBeInTheDocument;
    expect(btnPrintSearch.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorSearch_btnPrintSearch")
    );
  });
  test("Custom Test Cases for btnPrintSearch", () => {
    // START_USER_CODE-USER_btnPrintSearch_TEST
    // END_USER_CODE-USER_btnPrintSearch_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("gridVendorList(Grid Widget) Test Cases", async () => {
    let gridVendorList = screen.getByTestId("gridVendorList");
    let gridVendorListbtn = gridVendorList.nextElementSibling.firstElementChild;
    gridVendorList = gridVendorList.parentElement.parentElement.parentElement;
    expect(gridVendorList.tagName).toBe("DIV");
    expect(gridVendorList.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridVendorList", () => {
    // START_USER_CODE-USER_gridVendorList_TEST
    // END_USER_CODE-USER_gridVendorList_TEST
  });
  test("grpbxVendorDetailsuic0(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorDetailsuic0 = screen.getByTestId("grpbxVendorDetailsuic0");
    expect(grpbxVendorDetailsuic0.tagName).toBe("BUTTON");
    expect(grpbxVendorDetailsuic0.type).toBe("button");
    expect(grpbxVendorDetailsuic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorDetailsuic0", () => {
    // START_USER_CODE-USER_grpbxVendorDetailsuic0_TEST
    // END_USER_CODE-USER_grpbxVendorDetailsuic0_TEST
  });
  test("grpbxVendorList(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorList = screen.getByTestId("grpbxVendorList");
    expect(grpbxVendorList.tagName).toBe("BUTTON");
    expect(grpbxVendorList.type).toBe("button");
    expect(grpbxVendorList.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorList", () => {
    // START_USER_CODE-USER_grpbxVendorList_TEST
    // END_USER_CODE-USER_grpbxVendorList_TEST
  });
  test("lblMinOf3Chars1(Label Widget) Test Cases", async () => {
    const lblMinOf3Chars1 = screen.getByTestId("lblMinOf3Chars1");
    expect(lblMinOf3Chars1.tagName).toBe("LABEL");
    expect(lblMinOf3Chars1.classList).toContain("form-label");
    expect(lblMinOf3Chars1.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorSearch_lblMinOf3Chars1")
    );
  });
  test("Custom Test Cases for lblMinOf3Chars1", () => {
    // START_USER_CODE-USER_lblMinOf3Chars1_TEST
    // END_USER_CODE-USER_lblMinOf3Chars1_TEST
  });
  test("lblMinOf3Chars2(Label Widget) Test Cases", async () => {
    const lblMinOf3Chars2 = screen.getByTestId("lblMinOf3Chars2");
    expect(lblMinOf3Chars2.tagName).toBe("LABEL");
    expect(lblMinOf3Chars2.classList).toContain("form-label");
    expect(lblMinOf3Chars2.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorSearch_lblMinOf3Chars2")
    );
  });
  test("Custom Test Cases for lblMinOf3Chars2", () => {
    // START_USER_CODE-USER_lblMinOf3Chars2_TEST
    // END_USER_CODE-USER_lblMinOf3Chars2_TEST
  });
  test("lblVendorList(Label Widget) Test Cases", async () => {
    const lblVendorList = screen.getByTestId("lblVendorList");
    expect(lblVendorList.tagName).toBe("LABEL");
    expect(lblVendorList.classList).toContain("form-label");
    expect(lblVendorList.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorSearch_lblVendorList")
    );
  });
  test("Custom Test Cases for lblVendorList", () => {
    // START_USER_CODE-USER_lblVendorList_TEST
    // END_USER_CODE-USER_lblVendorList_TEST
  });
  test("gridVendorList_txt211Concordia(Grid Widget) Test Cases", async () => {
    let gridVendorList_txt211Concordia = screen.getByTestId("gridVendorList");
    let gridVendorList_txt211Concordiabtn =
      gridVendorList_txt211Concordia.nextElementSibling.firstElementChild;
    gridVendorList_txt211Concordia =
      gridVendorList_txt211Concordia.parentElement.parentElement.parentElement;
    expect(gridVendorList_txt211Concordia.tagName).toBe("DIV");
    expect(gridVendorList_txt211Concordia.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt211Concordia", () => {
    // START_USER_CODE-USER_txt211Concordia_TEST
    // END_USER_CODE-USER_txt211Concordia_TEST
  });
  test("gridVendorList_txt211GPC(Grid Widget) Test Cases", async () => {
    let gridVendorList_txt211GPC = screen.getByTestId("gridVendorList");
    let gridVendorList_txt211GPCbtn =
      gridVendorList_txt211GPC.nextElementSibling.firstElementChild;
    gridVendorList_txt211GPC =
      gridVendorList_txt211GPC.parentElement.parentElement.parentElement;
    expect(gridVendorList_txt211GPC.tagName).toBe("DIV");
    expect(gridVendorList_txt211GPC.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt211GPC", () => {
    // START_USER_CODE-USER_txt211GPC_TEST
    // END_USER_CODE-USER_txt211GPC_TEST
  });
  test("gridVendorList_txtCustomerNumber(Grid Widget) Test Cases", async () => {
    let gridVendorList_txtCustomerNumber = screen.getByTestId("gridVendorList");
    let gridVendorList_txtCustomerNumberbtn =
      gridVendorList_txtCustomerNumber.nextElementSibling.firstElementChild;
    gridVendorList_txtCustomerNumber =
      gridVendorList_txtCustomerNumber.parentElement.parentElement
        .parentElement;
    expect(gridVendorList_txtCustomerNumber.tagName).toBe("DIV");
    expect(gridVendorList_txtCustomerNumber.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtCustomerNumber", () => {
    // START_USER_CODE-USER_txtCustomerNumber_TEST
    // END_USER_CODE-USER_txtCustomerNumber_TEST
  });
  test("gridVendorList_txtEmail1(Grid Widget) Test Cases", async () => {
    let gridVendorList_txtEmail1 = screen.getByTestId("gridVendorList");
    let gridVendorList_txtEmail1btn =
      gridVendorList_txtEmail1.nextElementSibling.firstElementChild;
    gridVendorList_txtEmail1 =
      gridVendorList_txtEmail1.parentElement.parentElement.parentElement;
    expect(gridVendorList_txtEmail1.tagName).toBe("DIV");
    expect(gridVendorList_txtEmail1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtEmail1", () => {
    // START_USER_CODE-USER_txtEmail1_TEST
    // END_USER_CODE-USER_txtEmail1_TEST
  });
  test("gridVendorList_txtFax(Grid Widget) Test Cases", async () => {
    let gridVendorList_txtFax = screen.getByTestId("gridVendorList");
    let gridVendorList_txtFaxbtn =
      gridVendorList_txtFax.nextElementSibling.firstElementChild;
    gridVendorList_txtFax =
      gridVendorList_txtFax.parentElement.parentElement.parentElement;
    expect(gridVendorList_txtFax.tagName).toBe("DIV");
    expect(gridVendorList_txtFax.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtFax", () => {
    // START_USER_CODE-USER_txtFax_TEST
    // END_USER_CODE-USER_txtFax_TEST
  });
  test("gridVendorList_txtLienHolders(Grid Widget) Test Cases", async () => {
    let gridVendorList_txtLienHolders = screen.getByTestId("gridVendorList");
    let gridVendorList_txtLienHoldersbtn =
      gridVendorList_txtLienHolders.nextElementSibling.firstElementChild;
    gridVendorList_txtLienHolders =
      gridVendorList_txtLienHolders.parentElement.parentElement.parentElement;
    expect(gridVendorList_txtLienHolders.tagName).toBe("DIV");
    expect(gridVendorList_txtLienHolders.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtLienHolders", () => {
    // START_USER_CODE-USER_txtLienHolders_TEST
    // END_USER_CODE-USER_txtLienHolders_TEST
  });
  test("gridVendorList_txtMailingAddress(Grid Widget) Test Cases", async () => {
    let gridVendorList_txtMailingAddress = screen.getByTestId("gridVendorList");
    let gridVendorList_txtMailingAddressbtn =
      gridVendorList_txtMailingAddress.nextElementSibling.firstElementChild;
    gridVendorList_txtMailingAddress =
      gridVendorList_txtMailingAddress.parentElement.parentElement
        .parentElement;
    expect(gridVendorList_txtMailingAddress.tagName).toBe("DIV");
    expect(gridVendorList_txtMailingAddress.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtMailingAddress", () => {
    // START_USER_CODE-USER_txtMailingAddress_TEST
    // END_USER_CODE-USER_txtMailingAddress_TEST
  });
  test("gridVendorList_txtMinorityOwned(Grid Widget) Test Cases", async () => {
    let gridVendorList_txtMinorityOwned = screen.getByTestId("gridVendorList");
    let gridVendorList_txtMinorityOwnedbtn =
      gridVendorList_txtMinorityOwned.nextElementSibling.firstElementChild;
    gridVendorList_txtMinorityOwned =
      gridVendorList_txtMinorityOwned.parentElement.parentElement.parentElement;
    expect(gridVendorList_txtMinorityOwned.tagName).toBe("DIV");
    expect(gridVendorList_txtMinorityOwned.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtMinorityOwned", () => {
    // START_USER_CODE-USER_txtMinorityOwned_TEST
    // END_USER_CODE-USER_txtMinorityOwned_TEST
  });
  test("gridVendorList_txtPhone1(Grid Widget) Test Cases", async () => {
    let gridVendorList_txtPhone1 = screen.getByTestId("gridVendorList");
    let gridVendorList_txtPhone1btn =
      gridVendorList_txtPhone1.nextElementSibling.firstElementChild;
    gridVendorList_txtPhone1 =
      gridVendorList_txtPhone1.parentElement.parentElement.parentElement;
    expect(gridVendorList_txtPhone1.tagName).toBe("DIV");
    expect(gridVendorList_txtPhone1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPhone1", () => {
    // START_USER_CODE-USER_txtPhone1_TEST
    // END_USER_CODE-USER_txtPhone1_TEST
  });
  test("gridVendorList_txtPhone2(Grid Widget) Test Cases", async () => {
    let gridVendorList_txtPhone2 = screen.getByTestId("gridVendorList");
    let gridVendorList_txtPhone2btn =
      gridVendorList_txtPhone2.nextElementSibling.firstElementChild;
    gridVendorList_txtPhone2 =
      gridVendorList_txtPhone2.parentElement.parentElement.parentElement;
    expect(gridVendorList_txtPhone2.tagName).toBe("DIV");
    expect(gridVendorList_txtPhone2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPhone2", () => {
    // START_USER_CODE-USER_txtPhone2_TEST
    // END_USER_CODE-USER_txtPhone2_TEST
  });
  test("gridVendorList_txtPhysicalAddress(Grid Widget) Test Cases", async () => {
    let gridVendorList_txtPhysicalAddress = screen.getByTestId(
      "gridVendorList"
    );
    let gridVendorList_txtPhysicalAddressbtn =
      gridVendorList_txtPhysicalAddress.nextElementSibling.firstElementChild;
    gridVendorList_txtPhysicalAddress =
      gridVendorList_txtPhysicalAddress.parentElement.parentElement
        .parentElement;
    expect(gridVendorList_txtPhysicalAddress.tagName).toBe("DIV");
    expect(gridVendorList_txtPhysicalAddress.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPhysicalAddress", () => {
    // START_USER_CODE-USER_txtPhysicalAddress_TEST
    // END_USER_CODE-USER_txtPhysicalAddress_TEST
  });
  test("txtVendorName(Textbox Widget) Test Cases", async () => {
    const txtVendorName = screen.getByTestId("txtVendorName");
    expect(txtVendorName.tagName).toBe("INPUT");
    expect(txtVendorName.type).toBe("text");
    expect(txtVendorName.classList).toContain("textboxWidgetClass");
    expect(txtVendorName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorSearch_txtVendorName")
    );
    await act(async () => {
      userEvent.type(txtVendorName, "1");
    });
  });
  test("Custom Test Cases for txtVendorName", () => {
    // START_USER_CODE-USER_txtVendorName_TEST
    // END_USER_CODE-USER_txtVendorName_TEST
  });
  test("gridVendorList_txtVendorNameC(Grid Widget) Test Cases", async () => {
    let gridVendorList_txtVendorNameC = screen.getByTestId("gridVendorList");
    let gridVendorList_txtVendorNameCbtn =
      gridVendorList_txtVendorNameC.nextElementSibling.firstElementChild;
    gridVendorList_txtVendorNameC =
      gridVendorList_txtVendorNameC.parentElement.parentElement.parentElement;
    expect(gridVendorList_txtVendorNameC.tagName).toBe("DIV");
    expect(gridVendorList_txtVendorNameC.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtVendorNameC", () => {
    // START_USER_CODE-USER_txtVendorNameC_TEST
    // END_USER_CODE-USER_txtVendorNameC_TEST
  });
  test("txtVendorNumber(Textbox Widget) Test Cases", async () => {
    const txtVendorNumber = screen.getByTestId("txtVendorNumber");
    expect(txtVendorNumber.tagName).toBe("INPUT");
    expect(txtVendorNumber.type).toBe("text");
    expect(txtVendorNumber.classList).toContain("textboxWidgetClass");
    expect(txtVendorNumber.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorSearch_txtVendorNumber")
    );
    await act(async () => {
      userEvent.type(txtVendorNumber, "1");
    });
  });
  test("Custom Test Cases for txtVendorNumber", () => {
    // START_USER_CODE-USER_txtVendorNumber_TEST
    // END_USER_CODE-USER_txtVendorNumber_TEST
  });
  test("gridVendorList_txtVendorNumberC(Grid Widget) Test Cases", async () => {
    let gridVendorList_txtVendorNumberC = screen.getByTestId("gridVendorList");
    let gridVendorList_txtVendorNumberCbtn =
      gridVendorList_txtVendorNumberC.nextElementSibling.firstElementChild;
    gridVendorList_txtVendorNumberC =
      gridVendorList_txtVendorNumberC.parentElement.parentElement.parentElement;
    expect(gridVendorList_txtVendorNumberC.tagName).toBe("DIV");
    expect(gridVendorList_txtVendorNumberC.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtVendorNumberC", () => {
    // START_USER_CODE-USER_txtVendorNumberC_TEST
    // END_USER_CODE-USER_txtVendorNumberC_TEST
  });
  test("gridVendorList_txtVeteranOwned(Grid Widget) Test Cases", async () => {
    let gridVendorList_txtVeteranOwned = screen.getByTestId("gridVendorList");
    let gridVendorList_txtVeteranOwnedbtn =
      gridVendorList_txtVeteranOwned.nextElementSibling.firstElementChild;
    gridVendorList_txtVeteranOwned =
      gridVendorList_txtVeteranOwned.parentElement.parentElement.parentElement;
    expect(gridVendorList_txtVeteranOwned.tagName).toBe("DIV");
    expect(gridVendorList_txtVeteranOwned.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtVeteranOwned", () => {
    // START_USER_CODE-USER_txtVeteranOwned_TEST
    // END_USER_CODE-USER_txtVeteranOwned_TEST
  });
  test("gridVendorList_txtWomenOwned(Grid Widget) Test Cases", async () => {
    let gridVendorList_txtWomenOwned = screen.getByTestId("gridVendorList");
    let gridVendorList_txtWomenOwnedbtn =
      gridVendorList_txtWomenOwned.nextElementSibling.firstElementChild;
    gridVendorList_txtWomenOwned =
      gridVendorList_txtWomenOwned.parentElement.parentElement.parentElement;
    expect(gridVendorList_txtWomenOwned.tagName).toBe("DIV");
    expect(gridVendorList_txtWomenOwned.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceApplicationSupport:VendorSearch_gridVendorList")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtWomenOwned", () => {
    // START_USER_CODE-USER_txtWomenOwned_TEST
    // END_USER_CODE-USER_txtWomenOwned_TEST
  });
});
