/* eslint-disable*/
import React from "react";
import ContractManagement_MainMenu from "./MainMenu";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("MainMenu Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_MainMenu />);
    });
  });
  afterEach(cleanup);
  test("is MainMenu Loads Successfully", () => {
    expect(screen.getByText("Main Menu")).toBeInTheDocument;
  });
  test("Custom Test Cases for MainMenu", () => {
    // START_USER_CODE-USER_MainMenu_Custom_Test_Case
    // END_USER_CODE-USER_MainMenu_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_MainMenu />);
    });
  });
  afterEach(cleanup);
  test("applicationSection(GroupBox Widget) Test Cases", async () => {
    const applicationSection = screen.getByTestId("applicationSection");
    expect(applicationSection.tagName).toBe("BUTTON");
    expect(applicationSection.type).toBe("button");
    expect(applicationSection.classList).toContain("btn");
    expect(applicationSection.textContent).toEqual(
      t("ContractManagement:MainMenu_applicationSection")
    );
  });
  test("Custom Test Cases for applicationSection", () => {
    // START_USER_CODE-USER_applicationSection_TEST
    // END_USER_CODE-USER_applicationSection_TEST
  });
  test("btnApplicationLable(Label Widget) Test Cases", async () => {
    const btnApplicationLable = screen.getByTestId("btnApplicationLable");
    expect(btnApplicationLable.tagName).toBe("LABEL");
    expect(btnApplicationLable.classList).toContain("form-label");
    expect(btnApplicationLable.textContent).toEqual(
      t("ContractManagement:MainMenu_btnApplicationLable")
    );
  });
  test("Custom Test Cases for btnApplicationLable", () => {
    // START_USER_CODE-USER_btnApplicationLable_TEST
    // END_USER_CODE-USER_btnApplicationLable_TEST
  });
  test("btnBuyingPoints(Button Widget) Test Cases", async () => {
    const btnBuyingPoints = screen.getByTestId("btnBuyingPoints");
    expect(btnBuyingPoints).toBeInTheDocument;
    expect(btnBuyingPoints.textContent).toEqual(
      t("ContractManagement:MainMenu_btnBuyingPoints")
    );
  });
  test("Custom Test Cases for btnBuyingPoints", () => {
    // START_USER_CODE-USER_btnBuyingPoints_TEST
    // END_USER_CODE-USER_btnBuyingPoints_TEST
  });
  test("btnContractGroupings(Button Widget) Test Cases", async () => {
    const btnContractGroupings = screen.getByTestId("btnContractGroupings");
    expect(btnContractGroupings).toBeInTheDocument;
    expect(btnContractGroupings.textContent).toEqual(
      t("ContractManagement:MainMenu_btnContractGroupings")
    );
  });
  test("Custom Test Cases for btnContractGroupings", () => {
    // START_USER_CODE-USER_btnContractGroupings_TEST
    // END_USER_CODE-USER_btnContractGroupings_TEST
  });
  test("btnContractLimitControl(Button Widget) Test Cases", async () => {
    const btnContractLimitControl = screen.getByTestId(
      "btnContractLimitControl"
    );
    expect(btnContractLimitControl).toBeInTheDocument;
    expect(btnContractLimitControl.textContent).toEqual(
      t("ContractManagement:MainMenu_btnContractLimitControl")
    );
  });
  test("Custom Test Cases for btnContractLimitControl", () => {
    // START_USER_CODE-USER_btnContractLimitControl_TEST
    // END_USER_CODE-USER_btnContractLimitControl_TEST
  });
  test("btnCureCleanSchedule(Button Widget) Test Cases", async () => {
    const btnCureCleanSchedule = screen.getByTestId("btnCureCleanSchedule");
    expect(btnCureCleanSchedule).toBeInTheDocument;
    expect(btnCureCleanSchedule.textContent).toEqual(
      t("ContractManagement:MainMenu_btnCureCleanSchedule")
    );
  });
  test("Custom Test Cases for btnCureCleanSchedule", () => {
    // START_USER_CODE-USER_btnCureCleanSchedule_TEST
    // END_USER_CODE-USER_btnCureCleanSchedule_TEST
  });
  test("btnDeliveryInOut(Button Widget) Test Cases", async () => {
    const btnDeliveryInOut = screen.getByTestId("btnDeliveryInOut");
    expect(btnDeliveryInOut).toBeInTheDocument;
    expect(btnDeliveryInOut.textContent).toEqual(
      t("ContractManagement:MainMenu_btnDeliveryInOut")
    );
  });
  test("Custom Test Cases for btnDeliveryInOut", () => {
    // START_USER_CODE-USER_btnDeliveryInOut_TEST
    // END_USER_CODE-USER_btnDeliveryInOut_TEST
  });
  test("btnExceptionPremiums(Button Widget) Test Cases", async () => {
    const btnExceptionPremiums = screen.getByTestId("btnExceptionPremiums");
    expect(btnExceptionPremiums).toBeInTheDocument;
    expect(btnExceptionPremiums.textContent).toEqual(
      t("ContractManagement:MainMenu_btnExceptionPremiums")
    );
  });
  test("Custom Test Cases for btnExceptionPremiums", () => {
    // START_USER_CODE-USER_btnExceptionPremiums_TEST
    // END_USER_CODE-USER_btnExceptionPremiums_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("ContractManagement:MainMenu_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnFarmerStockPriceSetup(Button Widget) Test Cases", async () => {
    const btnFarmerStockPriceSetup = screen.getByTestId(
      "btnFarmerStockPriceSetup"
    );
    expect(btnFarmerStockPriceSetup).toBeInTheDocument;
    expect(btnFarmerStockPriceSetup.textContent).toEqual(
      t("ContractManagement:MainMenu_btnFarmerStockPriceSetup")
    );
  });
  test("Custom Test Cases for btnFarmerStockPriceSetup", () => {
    // START_USER_CODE-USER_btnFarmerStockPriceSetup_TEST
    // END_USER_CODE-USER_btnFarmerStockPriceSetup_TEST
  });
  test("btnFarms(Button Widget) Test Cases", async () => {
    const btnFarms = screen.getByTestId("btnFarms");
    expect(btnFarms).toBeInTheDocument;
    expect(btnFarms.textContent).toEqual(
      t("ContractManagement:MainMenu_btnFarms")
    );
  });
  test("Custom Test Cases for btnFarms", () => {
    // START_USER_CODE-USER_btnFarms_TEST
    // END_USER_CODE-USER_btnFarms_TEST
  });
  test("btnFederalWhseLic(Button Widget) Test Cases", async () => {
    const btnFederalWhseLic = screen.getByTestId("btnFederalWhseLic");
    expect(btnFederalWhseLic).toBeInTheDocument;
    expect(btnFederalWhseLic.textContent).toEqual(
      t("ContractManagement:MainMenu_btnFederalWhseLic")
    );
  });
  test("Custom Test Cases for btnFederalWhseLic", () => {
    // START_USER_CODE-USER_btnFederalWhseLic_TEST
    // END_USER_CODE-USER_btnFederalWhseLic_TEST
  });
  test("btnFilters(Button Widget) Test Cases", async () => {
    const btnFilters = screen.getByTestId("btnFilters");
    expect(btnFilters).toBeInTheDocument;
    expect(btnFilters.textContent).toEqual(
      t("ContractManagement:MainMenu_btnFilters")
    );
  });
  test("Custom Test Cases for btnFilters", () => {
    // START_USER_CODE-USER_btnFilters_TEST
    // END_USER_CODE-USER_btnFilters_TEST
  });
  test("btnFreightControl(Button Widget) Test Cases", async () => {
    const btnFreightControl = screen.getByTestId("btnFreightControl");
    expect(btnFreightControl).toBeInTheDocument;
    expect(btnFreightControl.textContent).toEqual(
      t("ContractManagement:MainMenu_btnFreightControl")
    );
  });
  test("Custom Test Cases for btnFreightControl", () => {
    // START_USER_CODE-USER_btnFreightControl_TEST
    // END_USER_CODE-USER_btnFreightControl_TEST
  });
  test("btnGroupings1007(Button Widget) Test Cases", async () => {
    const btnGroupings1007 = screen.getByTestId("btnGroupings1007");
    expect(btnGroupings1007).toBeInTheDocument;
    expect(btnGroupings1007.textContent).toEqual(
      t("ContractManagement:MainMenu_btnGroupings1007")
    );
  });
  test("Custom Test Cases for btnGroupings1007", () => {
    // START_USER_CODE-USER_btnGroupings1007_TEST
    // END_USER_CODE-USER_btnGroupings1007_TEST
  });
  test("btnInterestAgreement(Button Widget) Test Cases", async () => {
    const btnInterestAgreement = screen.getByTestId("btnInterestAgreement");
    expect(btnInterestAgreement).toBeInTheDocument;
    expect(btnInterestAgreement.textContent).toEqual(
      t("ContractManagement:MainMenu_btnInterestAgreement")
    );
  });
  test("Custom Test Cases for btnInterestAgreement", () => {
    // START_USER_CODE-USER_btnInterestAgreement_TEST
    // END_USER_CODE-USER_btnInterestAgreement_TEST
  });
  test("btnInventory(Button Widget) Test Cases", async () => {
    const btnInventory = screen.getByTestId("btnInventory");
    expect(btnInventory).toBeInTheDocument;
    expect(btnInventory.textContent).toEqual(
      t("ContractManagement:MainMenu_btnInventory")
    );
  });
  test("Custom Test Cases for btnInventory", () => {
    // START_USER_CODE-USER_btnInventory_TEST
    // END_USER_CODE-USER_btnInventory_TEST
  });
  test("btnNewContract(Button Widget) Test Cases", async () => {
    const btnNewContract = screen.getByTestId("btnNewContract");
    expect(btnNewContract).toBeInTheDocument;
    expect(btnNewContract.textContent).toEqual(
      t("ContractManagement:MainMenu_btnNewContract")
    );
  });
  test("Custom Test Cases for btnNewContract", () => {
    // START_USER_CODE-USER_btnNewContract_TEST
    // END_USER_CODE-USER_btnNewContract_TEST
  });
  test("btnOpenAccounts(Button Widget) Test Cases", async () => {
    const btnOpenAccounts = screen.getByTestId("btnOpenAccounts");
    expect(btnOpenAccounts).toBeInTheDocument;
    expect(btnOpenAccounts.textContent).toEqual(
      t("ContractManagement:MainMenu_btnOpenAccounts")
    );
  });
  test("Custom Test Cases for btnOpenAccounts", () => {
    // START_USER_CODE-USER_btnOpenAccounts_TEST
    // END_USER_CODE-USER_btnOpenAccounts_TEST
  });
  test("btnPaymentProcessing(Button Widget) Test Cases", async () => {
    const btnPaymentProcessing = screen.getByTestId("btnPaymentProcessing");
    expect(btnPaymentProcessing).toBeInTheDocument;
    expect(btnPaymentProcessing.textContent).toEqual(
      t("ContractManagement:MainMenu_btnPaymentProcessing")
    );
  });
  test("Custom Test Cases for btnPaymentProcessing", () => {
    // START_USER_CODE-USER_btnPaymentProcessing_TEST
    // END_USER_CODE-USER_btnPaymentProcessing_TEST
  });
  test("btnPeanuts(Button Widget) Test Cases", async () => {
    const btnPeanuts = screen.getByTestId("btnPeanuts");
    expect(btnPeanuts).toBeInTheDocument;
    expect(btnPeanuts.textContent).toEqual(
      t("ContractManagement:MainMenu_btnPeanuts")
    );
  });
  test("Custom Test Cases for btnPeanuts", () => {
    // START_USER_CODE-USER_btnPeanuts_TEST
    // END_USER_CODE-USER_btnPeanuts_TEST
  });
  test("btnPeanutSpecifications(Button Widget) Test Cases", async () => {
    const btnPeanutSpecifications = screen.getByTestId(
      "btnPeanutSpecifications"
    );
    expect(btnPeanutSpecifications).toBeInTheDocument;
    expect(btnPeanutSpecifications.textContent).toEqual(
      t("ContractManagement:MainMenu_btnPeanutSpecifications")
    );
  });
  test("Custom Test Cases for btnPeanutSpecifications", () => {
    // START_USER_CODE-USER_btnPeanutSpecifications_TEST
    // END_USER_CODE-USER_btnPeanutSpecifications_TEST
  });
  test("btnPremDeducsMaster(Button Widget) Test Cases", async () => {
    const btnPremDeducsMaster = screen.getByTestId("btnPremDeducsMaster");
    expect(btnPremDeducsMaster).toBeInTheDocument;
    expect(btnPremDeducsMaster.textContent).toEqual(
      t("ContractManagement:MainMenu_btnPremDeducsMaster")
    );
  });
  test("Custom Test Cases for btnPremDeducsMaster", () => {
    // START_USER_CODE-USER_btnPremDeducsMaster_TEST
    // END_USER_CODE-USER_btnPremDeducsMaster_TEST
  });
  test("btnPremDeducsRates(Button Widget) Test Cases", async () => {
    const btnPremDeducsRates = screen.getByTestId("btnPremDeducsRates");
    expect(btnPremDeducsRates).toBeInTheDocument;
    expect(btnPremDeducsRates.textContent).toEqual(
      t("ContractManagement:MainMenu_btnPremDeducsRates")
    );
  });
  test("Custom Test Cases for btnPremDeducsRates", () => {
    // START_USER_CODE-USER_btnPremDeducsRates_TEST
    // END_USER_CODE-USER_btnPremDeducsRates_TEST
  });
  test("btnReports(Button Widget) Test Cases", async () => {
    const btnReports = screen.getByTestId("btnReports");
    expect(btnReports).toBeInTheDocument;
    expect(btnReports.textContent).toEqual(
      t("ContractManagement:MainMenu_btnReports")
    );
  });
  test("Custom Test Cases for btnReports", () => {
    // START_USER_CODE-USER_btnReports_TEST
    // END_USER_CODE-USER_btnReports_TEST
  });
  test("btnScaleTicket(Button Widget) Test Cases", async () => {
    const btnScaleTicket = screen.getByTestId("btnScaleTicket");
    expect(btnScaleTicket).toBeInTheDocument;
    expect(btnScaleTicket.textContent).toEqual(
      t("ContractManagement:MainMenu_btnScaleTicket")
    );
  });
  test("Custom Test Cases for btnScaleTicket", () => {
    // START_USER_CODE-USER_btnScaleTicket_TEST
    // END_USER_CODE-USER_btnScaleTicket_TEST
  });
  test("btnSearchFor(Button Widget) Test Cases", async () => {
    const btnSearchFor = screen.getByTestId("btnSearchFor");
    expect(btnSearchFor).toBeInTheDocument;
    expect(btnSearchFor.textContent).toEqual(
      t("ContractManagement:MainMenu_btnSearchFor")
    );
  });
  test("Custom Test Cases for btnSearchFor", () => {
    // START_USER_CODE-USER_btnSearchFor_TEST
    // END_USER_CODE-USER_btnSearchFor_TEST
  });
  test("btnSearchForContracts(Button Widget) Test Cases", async () => {
    const btnSearchForContracts = screen.getByTestId("btnSearchForContracts");
    expect(btnSearchForContracts).toBeInTheDocument;
    expect(btnSearchForContracts.textContent).toEqual(
      t("ContractManagement:MainMenu_btnSearchForContracts")
    );
  });
  test("Custom Test Cases for btnSearchForContracts", () => {
    // START_USER_CODE-USER_btnSearchForContracts_TEST
    // END_USER_CODE-USER_btnSearchForContracts_TEST
  });
  test("btnSignatureSetup(Button Widget) Test Cases", async () => {
    const btnSignatureSetup = screen.getByTestId("btnSignatureSetup");
    expect(btnSignatureSetup).toBeInTheDocument;
    expect(btnSignatureSetup.textContent).toEqual(
      t("ContractManagement:MainMenu_btnSignatureSetup")
    );
  });
  test("Custom Test Cases for btnSignatureSetup", () => {
    // START_USER_CODE-USER_btnSignatureSetup_TEST
    // END_USER_CODE-USER_btnSignatureSetup_TEST
  });
  test("btnSpecialFunction(Button Widget) Test Cases", async () => {
    const btnSpecialFunction = screen.getByTestId("btnSpecialFunction");
    expect(btnSpecialFunction).toBeInTheDocument;
    expect(btnSpecialFunction.textContent).toEqual(
      t("ContractManagement:MainMenu_btnSpecialFunction")
    );
  });
  test("Custom Test Cases for btnSpecialFunction", () => {
    // START_USER_CODE-USER_btnSpecialFunction_TEST
    // END_USER_CODE-USER_btnSpecialFunction_TEST
  });
  test("btnSpotPrice(Button Widget) Test Cases", async () => {
    const btnSpotPrice = screen.getByTestId("btnSpotPrice");
    expect(btnSpotPrice).toBeInTheDocument;
    expect(btnSpotPrice.textContent).toEqual(
      t("ContractManagement:MainMenu_btnSpotPrice")
    );
  });
  test("Custom Test Cases for btnSpotPrice", () => {
    // START_USER_CODE-USER_btnSpotPrice_TEST
    // END_USER_CODE-USER_btnSpotPrice_TEST
  });
  test("btnStateCountries(Button Widget) Test Cases", async () => {
    const btnStateCountries = screen.getByTestId("btnStateCountries");
    expect(btnStateCountries).toBeInTheDocument;
    expect(btnStateCountries.textContent).toEqual(
      t("ContractManagement:MainMenu_btnStateCountries")
    );
  });
  test("Custom Test Cases for btnStateCountries", () => {
    // START_USER_CODE-USER_btnStateCountries_TEST
    // END_USER_CODE-USER_btnStateCountries_TEST
  });
  test("btnStockTransfers(Button Widget) Test Cases", async () => {
    const btnStockTransfers = screen.getByTestId("btnStockTransfers");
    expect(btnStockTransfers).toBeInTheDocument;
    expect(btnStockTransfers.textContent).toEqual(
      t("ContractManagement:MainMenu_btnStockTransfers")
    );
  });
  test("Custom Test Cases for btnStockTransfers", () => {
    // START_USER_CODE-USER_btnStockTransfers_TEST
    // END_USER_CODE-USER_btnStockTransfers_TEST
  });
  test("btnStorageSchedule(Button Widget) Test Cases", async () => {
    const btnStorageSchedule = screen.getByTestId("btnStorageSchedule");
    expect(btnStorageSchedule).toBeInTheDocument;
    expect(btnStorageSchedule.textContent).toEqual(
      t("ContractManagement:MainMenu_btnStorageSchedule")
    );
  });
  test("Custom Test Cases for btnStorageSchedule", () => {
    // START_USER_CODE-USER_btnStorageSchedule_TEST
    // END_USER_CODE-USER_btnStorageSchedule_TEST
  });
  test("btnTradeAgreements(Button Widget) Test Cases", async () => {
    const btnTradeAgreements = screen.getByTestId("btnTradeAgreements");
    expect(btnTradeAgreements).toBeInTheDocument;
    expect(btnTradeAgreements.textContent).toEqual(
      t("ContractManagement:MainMenu_btnTradeAgreements")
    );
  });
  test("Custom Test Cases for btnTradeAgreements", () => {
    // START_USER_CODE-USER_btnTradeAgreements_TEST
    // END_USER_CODE-USER_btnTradeAgreements_TEST
  });
  test("btnTradeSettlements(Button Widget) Test Cases", async () => {
    const btnTradeSettlements = screen.getByTestId("btnTradeSettlements");
    expect(btnTradeSettlements).toBeInTheDocument;
    expect(btnTradeSettlements.textContent).toEqual(
      t("ContractManagement:MainMenu_btnTradeSettlements")
    );
  });
  test("Custom Test Cases for btnTradeSettlements", () => {
    // START_USER_CODE-USER_btnTradeSettlements_TEST
    // END_USER_CODE-USER_btnTradeSettlements_TEST
  });
  test("btnUserManuals(Button Widget) Test Cases", async () => {
    const btnUserManuals = screen.getByTestId("btnUserManuals");
    expect(btnUserManuals).toBeInTheDocument;
    expect(btnUserManuals.textContent).toEqual(
      t("ContractManagement:MainMenu_btnUserManuals")
    );
  });
  test("Custom Test Cases for btnUserManuals", () => {
    // START_USER_CODE-USER_btnUserManuals_TEST
    // END_USER_CODE-USER_btnUserManuals_TEST
  });
  test("btnVendoeMaintenance(Button Widget) Test Cases", async () => {
    const btnVendoeMaintenance = screen.getByTestId("btnVendoeMaintenance");
    expect(btnVendoeMaintenance).toBeInTheDocument;
    expect(btnVendoeMaintenance.textContent).toEqual(
      t("ContractManagement:MainMenu_btnVendoeMaintenance")
    );
  });
  test("Custom Test Cases for btnVendoeMaintenance", () => {
    // START_USER_CODE-USER_btnVendoeMaintenance_TEST
    // END_USER_CODE-USER_btnVendoeMaintenance_TEST
  });
  test("btnVoidNoSaleSC95(Button Widget) Test Cases", async () => {
    const btnVoidNoSaleSC95 = screen.getByTestId("btnVoidNoSaleSC95");
    expect(btnVoidNoSaleSC95).toBeInTheDocument;
    expect(btnVoidNoSaleSC95.textContent).toEqual(
      t("ContractManagement:MainMenu_btnVoidNoSaleSC95")
    );
  });
  test("Custom Test Cases for btnVoidNoSaleSC95", () => {
    // START_USER_CODE-USER_btnVoidNoSaleSC95_TEST
    // END_USER_CODE-USER_btnVoidNoSaleSC95_TEST
  });
  test("btnVoidNoSaleSetup(Button Widget) Test Cases", async () => {
    const btnVoidNoSaleSetup = screen.getByTestId("btnVoidNoSaleSetup");
    expect(btnVoidNoSaleSetup).toBeInTheDocument;
    expect(btnVoidNoSaleSetup.textContent).toEqual(
      t("ContractManagement:MainMenu_btnVoidNoSaleSetup")
    );
  });
  test("Custom Test Cases for btnVoidNoSaleSetup", () => {
    // START_USER_CODE-USER_btnVoidNoSaleSetup_TEST
    // END_USER_CODE-USER_btnVoidNoSaleSetup_TEST
  });
  test("btnVoidWRcontrolNumber(Button Widget) Test Cases", async () => {
    const btnVoidWRcontrolNumber = screen.getByTestId("btnVoidWRcontrolNumber");
    expect(btnVoidWRcontrolNumber).toBeInTheDocument;
    expect(btnVoidWRcontrolNumber.textContent).toEqual(
      t("ContractManagement:MainMenu_btnVoidWRcontrolNumber")
    );
  });
  test("Custom Test Cases for btnVoidWRcontrolNumber", () => {
    // START_USER_CODE-USER_btnVoidWRcontrolNumber_TEST
    // END_USER_CODE-USER_btnVoidWRcontrolNumber_TEST
  });
  test("btnWarehouseRecipts(Button Widget) Test Cases", async () => {
    const btnWarehouseRecipts = screen.getByTestId("btnWarehouseRecipts");
    expect(btnWarehouseRecipts).toBeInTheDocument;
    expect(btnWarehouseRecipts.textContent).toEqual(
      t("ContractManagement:MainMenu_btnWarehouseRecipts")
    );
  });
  test("Custom Test Cases for btnWarehouseRecipts", () => {
    // START_USER_CODE-USER_btnWarehouseRecipts_TEST
    // END_USER_CODE-USER_btnWarehouseRecipts_TEST
  });
  test("grpbxAppSysMaint(GroupBox Widget) Test Cases", async () => {
    const grpbxAppSysMaint = screen.getByTestId("grpbxAppSysMaint");
    expect(grpbxAppSysMaint.tagName).toBe("BUTTON");
    expect(grpbxAppSysMaint.type).toBe("button");
    expect(grpbxAppSysMaint.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAppSysMaint", () => {
    // START_USER_CODE-USER_grpbxAppSysMaint_TEST
    // END_USER_CODE-USER_grpbxAppSysMaint_TEST
  });
  test("lblSystemMaintenanceSetupLable(Label Widget) Test Cases", async () => {
    const lblSystemMaintenanceSetupLable = screen.getByTestId(
      "lblSystemMaintenanceSetupLable"
    );
    expect(lblSystemMaintenanceSetupLable.tagName).toBe("LABEL");
    expect(lblSystemMaintenanceSetupLable.classList).toContain("form-label");
    expect(lblSystemMaintenanceSetupLable.textContent).toEqual(
      t("ContractManagement:MainMenu_lblSystemMaintenanceSetupLable")
    );
  });
  test("Custom Test Cases for lblSystemMaintenanceSetupLable", () => {
    // START_USER_CODE-USER_lblSystemMaintenanceSetupLable_TEST
    // END_USER_CODE-USER_lblSystemMaintenanceSetupLable_TEST
  });
  test("systemMaintenanceSetupSection(GroupBox Widget) Test Cases", async () => {
    const systemMaintenanceSetupSection = screen.getByTestId(
      "systemMaintenanceSetupSection"
    );
    expect(systemMaintenanceSetupSection.tagName).toBe("BUTTON");
    expect(systemMaintenanceSetupSection.type).toBe("button");
    expect(systemMaintenanceSetupSection.classList).toContain("btn");
    expect(systemMaintenanceSetupSection.textContent).toEqual(
      t("ContractManagement:MainMenu_systemMaintenanceSetupSection")
    );
  });
  test("Custom Test Cases for systemMaintenanceSetupSection", () => {
    // START_USER_CODE-USER_systemMaintenanceSetupSection_TEST
    // END_USER_CODE-USER_systemMaintenanceSetupSection_TEST
  });
});
