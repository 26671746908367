/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  setValue,
  getValue,
  getData,
  setData,
} from "../../shared/WindowImports";

import "./StockTransferOldNumber.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { StockTransferService } from "../Service/StockTransferService";
import { SystemMaintenanceApplicationSupportService } from "../../SystemMaintenanceApplicationSupport/Service/SystemMaintenanceApplicationSupportService"
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function StockTransfer_StockTransferOldNumber(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  let cropyear = cropYearFromLS();
  let compId = compIdFromLS();
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "StockTransferOldNumber",
    windowName: "StockTransferOldNumber",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "StockTransfer.StockTransferOldNumber",
    // START_USER_CODE-USER_StockTransferOldNumber_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Stock Transfer Old Number",
      scrCode: "PN1080C",
    },
    // END_USER_CODE-USER_StockTransferOldNumber_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndcntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndcntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    ddNewShippingLocation: {
      name: "ddNewShippingLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxStockTransferOldNumber",
      Label: "Select New Shipping Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddNewShippingLocation_PROPERTIES
      SpecialFirstOption: "",
      // END_USER_CODE-USER_ddNewShippingLocation_PROPERTIES
    },
    lblNote1: {
      name: "lblNote1",
      type: "LabelWidget",
      parent: "grpbxStockTransferOldNumber",
      Label:
        "System will delete the current transfer. New transfer can be created using the same transfer number.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNote1_PROPERTIES

      // END_USER_CODE-USER_lblNote1_PROPERTIES
    },
    lblNote2: {
      name: "lblNote2",
      type: "LabelWidget",
      parent: "grpbxStockTransferOldNumber",
      Label: "Deleting this transfer is IRREVERSIBLE.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNote2_PROPERTIES

      // END_USER_CODE-USER_lblNote2_PROPERTIES
    },
    txtCurrentShippingLocation: {
      name: "txtCurrentShippingLocation",
      type: "TextBoxWidget",
      parent: "grpbxStockTransferOldNumber",
      Label: "Current Shipping Location:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCurrentShippingLocation_PROPERTIES

      // END_USER_CODE-USER_txtCurrentShippingLocation_PROPERTIES
    },
    grpbxStockTransferOldNumber: {
      name: "grpbxStockTransferOldNumber",
      type: "GroupBoxWidget",
      parent: "StockTransferOldNumber",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxStockTransferOldNumber_PROPERTIES

      // END_USER_CODE-USER_grpbxStockTransferOldNumber_PROPERTIES
    },
    cmmndcntnrActions: {
      name: "cmmndcntnrActions",
      type: "CommandContainerWidget",
      parent: "StockTransferOldNumber",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndcntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndcntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  //Logic for Form Load
  const FormLoad = async () => {
    try {
      let StockTransfer = getData(thisObj, "frmStockTransferOldNumber");
      setData(thisObj, "msNewShipLoc", null) //data for Stocktransfer screen
      //setValue(thisObj, "txtCurrentShippingLocation", "2CY - Gpc- Claredon");  // added hard code value
      let buyingpoint = StockTransfer.mTransfer.mudtProps.shp_buy_pt +'-'+StockTransfer.lblCurrentLocation;
      setValue(thisObj, "txtCurrentShippingLocation", buyingpoint);
      await cboShipLocation();
    }
    catch (err) {
      thisObj.showMessage = err.message;
    }
    finally {
      //setLoading(false)
    }
  }
  //Function to convert to Pascal Case
  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }
  // Method to bind Shiping Location dropdown  
  const cboShipLocation = async () => {    
    try {
      setLoading(true)
      let js = [];
      js.push({ key: '' });
      let response = await ContractManagementService.RetrieveBuyingPointControlList(compId, cropyear)
      if (response !== undefined && response !== null) {
        for (var i = 0; i < response.length; i++) {
          js.push({
            key: response[i].buy_pt_id,
            description: response[i].buy_pt_id + ' - ' + ConvertToPascalCase(response[i].buy_pt_name).toString()
          })
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddNewShippingLocation: {
            ...state["ddNewShippingLocation"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddNewShippingLocation', js.at(0).key);
      setLoading(false);
    } catch (err) {
      thisObj.showMessage = err.message;
      setLoading(false);
    }
  }

  // Implement Add and Update button functionality
  const onbtnOkClick = async () => {
    try {
      setLoading(true);
      //let lblshipingLoc = getValue(thisObj, "txtCurrentShippingLocation");
      let StockTransfer = getData(thisObj, "frmStockTransferOldNumber");
      let lblshipingLoc = StockTransfer.mTransfer.mudtProps.shp_buy_pt;//"2CY"; // added hard code value
      let ddshipLocation = getValue(thisObj, "ddNewShippingLocation");
      let shp_buy_pt = "";
      let transfer_type = StockTransfer.transfer_type//"ST"; // added hard code value
      let transfer_num = StockTransfer.mTransfer.mudtProps.transfer_num;//"007051"; // Added hard code value
      let mbSuccess = false;
      let msNewShipLoc = StockTransfer.mTransfer.mudtProps.shp_buy_pt;//"2CY";// Added hard code value
      const confirmBox = "";
      if (ddshipLocation == null || ddshipLocation == undefined || ddshipLocation == "") {
        alert("Select a new shipping location");
        setLoading(false);
        return mbSuccess;
      }
      if (lblshipingLoc == ddshipLocation) {
        alert("New shipping location cannot be the same as current shipping location");
        setLoading(false);
        return mbSuccess;
      }
      // verify if old number exist
      let response = await StockTransferService.RetrieveTransferHeaderDetails(transfer_num,transfer_type,ddshipLocation); // added hard code value in transfer num, transfer type
      if (response !== null && response !== undefined && response.length > 0) {
        transfer_num = response[0].transfer_num;

        if (transfer_num !== null || transfer_num !== undefined || transfer_num !== "") {
          window.confirm(`Transfer # ${transfer_num} already exists at new shipping location ${lblshipingLoc}. You will have to provide another transfer number. Deleting this transfer is IRREVERSABLE! Do you want to use continue?`);
          setLoading(false);
          return mbSuccess;
        }     
      }
      //delete transfer 
      let lstrxml = await SystemMaintenanceApplicationSupportService.RemoveTransferHeader(msNewShipLoc,transfer_num,transfer_type);
      if (lstrxml?.status == 200) {
        mbSuccess = true;
        msNewShipLoc = ddshipLocation
       // setData(thisObj, "msNewShipLoc", mbSuccess) //data for Stocktransfer screen
        let js = {
          ShipLocList: StockTransfer.mTransfer,
          Success: mbSuccess,
          newShpLocation: msNewShipLoc,
        };
        setData(thisObj, "frmStockTransfer", js);
      } 
      else {
        // alert(lstrxml.message);
        alert('An error occured while communicating or updating with the database. Please contact your system administrator if the problem persist.\n\nError:\n\n' +
        lstrxml.message.slice(69))
        let js = {
          ShipLocList: StockTransfer.mTransfer,
          Success: false,
          newShpLocation: msNewShipLoc,
        };
        setData(thisObj, "frmStockTransfer", js);
      }
      setLoading(false);
      setData(thisObj, "ClosingOfStockTransferOldNumber", true)
      document.getElementById("StockTransfer_StockTransferOldNumberPopUp").childNodes[0].click();
    }
    catch (err) {
      showMessage(thisObj, err.message);
      return false;
    }    
  }
  thisObj.onbtnOkClick = onbtnOkClick;
  // Implement functionality for Cancel button___
  const onbtnCancelClick = () => {
    try {
     document.getElementById("StockTransfer_StockTransferOldNumberPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_StockTransferOldNumber*/}

              {/* END_USER_CODE-USER_BEFORE_StockTransferOldNumber*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxStockTransferOldNumber*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxStockTransferOldNumber*/}

              <GroupBoxWidget
                conf={state.grpbxStockTransferOldNumber}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblNote1*/}

                {/* END_USER_CODE-USER_BEFORE_lblNote1*/}

                <LabelWidget
                  values={values}
                  conf={state.lblNote1}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblNote1*/}

                {/* END_USER_CODE-USER_AFTER_lblNote1*/}
                {/* START_USER_CODE-USER_BEFORE_lblNote2*/}

                {/* END_USER_CODE-USER_BEFORE_lblNote2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblNote2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblNote2*/}

                {/* END_USER_CODE-USER_AFTER_lblNote2*/}
                {/* START_USER_CODE-USER_BEFORE_txtCurrentShippingLocation*/}

                {/* END_USER_CODE-USER_BEFORE_txtCurrentShippingLocation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCurrentShippingLocation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCurrentShippingLocation*/}

                {/* END_USER_CODE-USER_AFTER_txtCurrentShippingLocation*/}
                {/* START_USER_CODE-USER_BEFORE_ddNewShippingLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddNewShippingLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddNewShippingLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddNewShippingLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddNewShippingLocation*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxStockTransferOldNumber*/}

              {/* END_USER_CODE-USER_AFTER_grpbxStockTransferOldNumber*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndcntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndcntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndcntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndcntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndcntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_StockTransferOldNumber*/}

              {/* END_USER_CODE-USER_AFTER_StockTransferOldNumber*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(StockTransfer_StockTransferOldNumber);
