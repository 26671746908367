/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  setData,
  setValue,
  goTo
} from "../../shared/WindowImports";

import "./ContApplEditAuthorize.scss";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../Service/SettlementService";
import StripPic from "../../../../assets/img/PinStrip.png";


// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { response } from "msw";
// END_USER_CODE-USER_IMPORTS
function Settlements_ContApplEditAuthorize(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
let comp_id = compIdFromLS();
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ContApplEditAuthorize",
    windowName: "ContApplEditAuthorize",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.ContApplEditAuthorize",
    // START_USER_CODE-USER_ContApplEditAuthorize_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Authorize Contract Application Edit",
      scrCode: "PN1060J",
    },
    // END_USER_CODE-USER_ContApplEditAuthorize_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnCreateAuthorization: {
      name: "btnCreateAuthorization",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Create Authorization",
      CharWidth: "41",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreateAuthorization_PROPERTIES

      // END_USER_CODE-USER_btnCreateAuthorization_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxContApplEditAuthorize",
      Label: "Buying Point:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    ddUserID: {
      name: "ddUserID",
      type: "DropDownFieldWidget",
      parent: "grpbxContApplEditAuthorize",
      Label: "User ID:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddUserID_PROPERTIES

      // END_USER_CODE-USER_ddUserID_PROPERTIES
    },
    txtSC95: {
      name: "txtSC95",
      type: "TextBoxWidget",
      parent: "grpbxContApplEditAuthorize",
      Label: "SC95#:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95_PROPERTIES

      // END_USER_CODE-USER_txtSC95_PROPERTIES
    },
    grpbxContApplEditAuthorize: {
      name: "grpbxContApplEditAuthorize",
      type: "GroupBoxWidget",
      parent: "ContApplEditAuthorize",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxContApplEditAuthorize_PROPERTIES

      // END_USER_CODE-USER_grpbxContApplEditAuthorize_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "ContApplEditAuthorize",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

   function FormLoad (){
    bFillUserId();
    bFillBuyingPointList();
   }

  // START_USER_CODE-USER_METHODS
   // Start Method for userId
   const bFillUserId = () => {
    let js = []
    //js.push({ key: '', description: '>>> All Userid <<<' });
    ContractManagementService.RetrieveUserControlDetails(null,null,null,null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].userId, description: data[i].userId.trim() + '-' + data[i].userName.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddUserID: {
            ...state["ddUserID"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddUserID', js.at(0).key);
      setData(thisObj, 'ddUserID', js.at(0).key);
    })
  }
// End Method for UserId

  // Start Method for binding Buying Points
  const bFillBuyingPointList = () => {
    let js = []
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1060', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddBuyingPoint', js.at(0).key);
      setData(thisObj, 'ddBuyingPoint', js.at(0).key);
    })
  }
// End Method for binding Buying Points

   //Start Method for Create Authorization button
   const onbtnCreateAuthorizationClick = async () => {
     let userId = thisObj.values.ddUserID;
     let buyPtId = thisObj.values.ddBuyingPoint;
     let sc95 = thisObj.values.txtSC95;
     if(sc95 == undefined || sc95 == null || sc95 == ""){
       alert("SC95 number is required");
       return false
     }
     SettlementService.CreateContractGrantDetails(userId,buyPtId,sc95).then(response => {
       if(response.status !== 200){
         alert("An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.");
       }
       else{
        document.getElementsByClassName("close")[0].click();
      }
     });    
  }
  thisObj.onbtnCreateAuthorizationClick = onbtnCreateAuthorizationClick;
   //End Method for Create Authorization button

   // Start method to close the poup
   const onbtnCancelClick = async () => {
    goTo(thisObj, 'Settlements#FV95Search#DEFAULT#false#Click');    
   }
   thisObj.onbtnCancelClick=onbtnCancelClick
    // End method to close the poup

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ContApplEditAuthorize*/}

              {/* END_USER_CODE-USER_BEFORE_ContApplEditAuthorize*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxContApplEditAuthorize*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxContApplEditAuthorize*/}

              <GroupBoxWidget
                conf={state.grpbxContApplEditAuthorize}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddUserID*/}

                {/* END_USER_CODE-USER_BEFORE_ddUserID*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddUserID}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddUserID*/}

                {/* END_USER_CODE-USER_AFTER_ddUserID*/}
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txtSC95*/}

                {/* END_USER_CODE-USER_BEFORE_txtSC95*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSC95}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSC95*/}

                {/* END_USER_CODE-USER_AFTER_txtSC95*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxContApplEditAuthorize*/}

              {/* END_USER_CODE-USER_AFTER_grpbxContApplEditAuthorize*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnCreateAuthorization*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreateAuthorization*/}

                <ButtonWidget
                  conf={state.btnCreateAuthorization}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreateAuthorization*/}

                {/* END_USER_CODE-USER_AFTER_btnCreateAuthorization*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_ContApplEditAuthorize*/}

              {/* END_USER_CODE-USER_AFTER_ContApplEditAuthorize*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_ContApplEditAuthorize);
