/* eslint-disable*/
import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin
}

const config = {
  auth: {
    authority: process.env.REACT_APP_TT_AUTH_AUTHORITY,
    passwordreset: process.env.REACT_APP_PPS_PASSWORD_RESET,
    clientId: process.env.REACT_APP_B2C_CLIENT_ID,
    redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI,
    validateAuthority: false,
    postLogoutRedirectUri: process.env.REACT_APP_B2C_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
};

/*
 * 
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters, 
 * https://graph.windows.net/Directory.Read.All"
 */
export const loginRequest = {
  scopes: [process.env.REACT_APP_B2C_SCOPE],
  forceRefresh: false
};


// Forgot Password Handler
function authErrorHandler(error) {
  if (error && error?.errorMessage?.indexOf('AADB2C90118') > -1) {
      authProvider.setAuthenticationParameters({
          authority: process.env.REACT_APP_PPS_PASSWORD_RESET,
      })
      authProvider.login();
    }

    if (error && error?.errorMessage?.indexOf('AADB2C99002') != -1) {
      authProvider.setAuthenticationParameters({
          authority: process.env.REACT_APP_OIS_AUTH_AUTHORITY,
      })
      authProvider.login();
    }

  if (error && error?.errorCode === 'token_renewal_error') {
      authProvider.loginRedirect(config);
  }

  if (error && error?.errorCode === 'access_denied') {
      authProvider.loginRedirect(config);
  }
}

export const authProvider = new MsalAuthProvider(config, loginRequest, options)
authProvider.registerErrorHandler(authErrorHandler)