/* eslint-disable*/
import React from "react";
import Settlements_FV95Residue from "./FV95Residue";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FV95Residue Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_FV95Residue />);
    });
  });
  afterEach(cleanup);
  test("is FV95Residue Loads Successfully", () => {
    expect(screen.getByText("FV95Residue")).toBeInTheDocument;
  });
  test("Custom Test Cases for FV95Residue", () => {
    // START_USER_CODE-USER_FV95Residue_Custom_Test_Case
    // END_USER_CODE-USER_FV95Residue_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_FV95Residue />);
    });
  });
  afterEach(cleanup);
  test("btnCalculate(Button Widget) Test Cases", async () => {
    const btnCalculate = screen.getByTestId("btnCalculate");
    expect(btnCalculate).toBeInTheDocument;
    expect(btnCalculate.textContent).toEqual(
      t("Settlements:FV95Residue_btnCalculate")
    );
  });
  test("Custom Test Cases for btnCalculate", () => {
    // START_USER_CODE-USER_btnCalculate_TEST
    // END_USER_CODE-USER_btnCalculate_TEST
  });
  test("btnConvertHMC(Button Widget) Test Cases", async () => {
    const btnConvertHMC = screen.getByTestId("btnConvertHMC");
    expect(btnConvertHMC).toBeInTheDocument;
    expect(btnConvertHMC.textContent).toEqual(
      t("Settlements:FV95Residue_btnConvertHMC")
    );
  });
  test("Custom Test Cases for btnConvertHMC", () => {
    // START_USER_CODE-USER_btnConvertHMC_TEST
    // END_USER_CODE-USER_btnConvertHMC_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("Settlements:FV95Residue_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(t("Settlements:FV95Residue_btnExit"));
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(t("Settlements:FV95Residue_btnOk"));
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(t("Settlements:FV95Residue_btnPrint"));
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCMAInformation(GroupBox Widget) Test Cases", async () => {
    const grpbxCMAInformation = screen.getByTestId("grpbxCMAInformation");
    expect(grpbxCMAInformation.tagName).toBe("BUTTON");
    expect(grpbxCMAInformation.type).toBe("button");
    expect(grpbxCMAInformation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCMAInformation", () => {
    // START_USER_CODE-USER_grpbxCMAInformation_TEST
    // END_USER_CODE-USER_grpbxCMAInformation_TEST
  });
  test("grpbxFarmInformation(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmInformation = screen.getByTestId("grpbxFarmInformation");
    expect(grpbxFarmInformation.tagName).toBe("BUTTON");
    expect(grpbxFarmInformation.type).toBe("button");
    expect(grpbxFarmInformation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmInformation", () => {
    // START_USER_CODE-USER_grpbxFarmInformation_TEST
    // END_USER_CODE-USER_grpbxFarmInformation_TEST
  });
  test("grpbxGradeCalculation(GroupBox Widget) Test Cases", async () => {
    const grpbxGradeCalculation = screen.getByTestId("grpbxGradeCalculation");
    expect(grpbxGradeCalculation.tagName).toBe("BUTTON");
    expect(grpbxGradeCalculation.type).toBe("button");
    expect(grpbxGradeCalculation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGradeCalculation", () => {
    // START_USER_CODE-USER_grpbxGradeCalculation_TEST
    // END_USER_CODE-USER_grpbxGradeCalculation_TEST
  });
  test("grpbxHMC(GroupBox Widget) Test Cases", async () => {
    const grpbxHMC = screen.getByTestId("grpbxHMC");
    expect(grpbxHMC.tagName).toBe("BUTTON");
    expect(grpbxHMC.type).toBe("button");
    expect(grpbxHMC.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxHMC", () => {
    // START_USER_CODE-USER_grpbxHMC_TEST
    // END_USER_CODE-USER_grpbxHMC_TEST
  });
  test("grpbxInspectorRemarks(GroupBox Widget) Test Cases", async () => {
    const grpbxInspectorRemarks = screen.getByTestId("grpbxInspectorRemarks");
    expect(grpbxInspectorRemarks.tagName).toBe("BUTTON");
    expect(grpbxInspectorRemarks.type).toBe("button");
    expect(grpbxInspectorRemarks.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxInspectorRemarks", () => {
    // START_USER_CODE-USER_grpbxInspectorRemarks_TEST
    // END_USER_CODE-USER_grpbxInspectorRemarks_TEST
  });
  test("grpbxPeanutInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanutInfo = screen.getByTestId("grpbxPeanutInfo");
    expect(grpbxPeanutInfo.tagName).toBe("BUTTON");
    expect(grpbxPeanutInfo.type).toBe("button");
    expect(grpbxPeanutInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPeanutInfo", () => {
    // START_USER_CODE-USER_grpbxPeanutInfo_TEST
    // END_USER_CODE-USER_grpbxPeanutInfo_TEST
  });
  test("grpbxSignature(GroupBox Widget) Test Cases", async () => {
    const grpbxSignature = screen.getByTestId("grpbxSignature");
    expect(grpbxSignature.tagName).toBe("BUTTON");
    expect(grpbxSignature.type).toBe("button");
    expect(grpbxSignature.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSignature", () => {
    // START_USER_CODE-USER_grpbxSignature_TEST
    // END_USER_CODE-USER_grpbxSignature_TEST
  });
  test("grpbxValueCalculation(GroupBox Widget) Test Cases", async () => {
    const grpbxValueCalculation = screen.getByTestId("grpbxValueCalculation");
    expect(grpbxValueCalculation.tagName).toBe("BUTTON");
    expect(grpbxValueCalculation.type).toBe("button");
    expect(grpbxValueCalculation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxValueCalculation", () => {
    // START_USER_CODE-USER_grpbxValueCalculation_TEST
    // END_USER_CODE-USER_grpbxValueCalculation_TEST
  });
  test("grpbxWeightTickets(GroupBox Widget) Test Cases", async () => {
    const grpbxWeightTickets = screen.getByTestId("grpbxWeightTickets");
    expect(grpbxWeightTickets.tagName).toBe("BUTTON");
    expect(grpbxWeightTickets.type).toBe("button");
    expect(grpbxWeightTickets.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWeightTickets", () => {
    // START_USER_CODE-USER_grpbxWeightTickets_TEST
    // END_USER_CODE-USER_grpbxWeightTickets_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("Settlements:FV95Residue_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("Settlements:FV95Residue_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("Settlements:FV95Residue_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("Settlements:FV95Residue_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblCornFound(Label Widget) Test Cases", async () => {
    const lblCornFound = screen.getByTestId("lblCornFound");
    expect(lblCornFound.tagName).toBe("LABEL");
    expect(lblCornFound.classList).toContain("form-label");
    expect(lblCornFound.textContent).toEqual(
      t("Settlements:FV95Residue_lblCornFound")
    );
  });
  test("Custom Test Cases for lblCornFound", () => {
    // START_USER_CODE-USER_lblCornFound_TEST
    // END_USER_CODE-USER_lblCornFound_TEST
  });
  test("lblDateTimeFormat(Label Widget) Test Cases", async () => {
    const lblDateTimeFormat = screen.getByTestId("lblDateTimeFormat");
    expect(lblDateTimeFormat.tagName).toBe("LABEL");
    expect(lblDateTimeFormat.classList).toContain("form-label");
    expect(lblDateTimeFormat.textContent).toEqual(
      t("Settlements:FV95Residue_lblDateTimeFormat")
    );
  });
  test("Custom Test Cases for lblDateTimeFormat", () => {
    // START_USER_CODE-USER_lblDateTimeFormat_TEST
    // END_USER_CODE-USER_lblDateTimeFormat_TEST
  });
  test("lblFarmInformation(Label Widget) Test Cases", async () => {
    const lblFarmInformation = screen.getByTestId("lblFarmInformation");
    expect(lblFarmInformation.tagName).toBe("LABEL");
    expect(lblFarmInformation.classList).toContain("form-label");
    expect(lblFarmInformation.textContent).toEqual(
      t("Settlements:FV95Residue_lblFarmInformation")
    );
  });
  test("Custom Test Cases for lblFarmInformation", () => {
    // START_USER_CODE-USER_lblFarmInformation_TEST
    // END_USER_CODE-USER_lblFarmInformation_TEST
  });
  test("lblFlavusFound(Label Widget) Test Cases", async () => {
    const lblFlavusFound = screen.getByTestId("lblFlavusFound");
    expect(lblFlavusFound.tagName).toBe("LABEL");
    expect(lblFlavusFound.classList).toContain("form-label");
    expect(lblFlavusFound.textContent).toEqual(
      t("Settlements:FV95Residue_lblFlavusFound")
    );
  });
  test("Custom Test Cases for lblFlavusFound", () => {
    // START_USER_CODE-USER_lblFlavusFound_TEST
    // END_USER_CODE-USER_lblFlavusFound_TEST
  });
  test("lblGradeCalculation(Label Widget) Test Cases", async () => {
    const lblGradeCalculation = screen.getByTestId("lblGradeCalculation");
    expect(lblGradeCalculation.tagName).toBe("LABEL");
    expect(lblGradeCalculation.classList).toContain("form-label");
    expect(lblGradeCalculation.textContent).toEqual(
      t("Settlements:FV95Residue_lblGradeCalculation")
    );
  });
  test("Custom Test Cases for lblGradeCalculation", () => {
    // START_USER_CODE-USER_lblGradeCalculation_TEST
    // END_USER_CODE-USER_lblGradeCalculation_TEST
  });
  test("lblGrams1(Label Widget) Test Cases", async () => {
    const lblGrams1 = screen.getByTestId("lblGrams1");
    expect(lblGrams1.tagName).toBe("LABEL");
    expect(lblGrams1.classList).toContain("form-label");
    expect(lblGrams1.textContent).toEqual(
      t("Settlements:FV95Residue_lblGrams1")
    );
  });
  test("Custom Test Cases for lblGrams1", () => {
    // START_USER_CODE-USER_lblGrams1_TEST
    // END_USER_CODE-USER_lblGrams1_TEST
  });
  test("lblGrams2(Label Widget) Test Cases", async () => {
    const lblGrams2 = screen.getByTestId("lblGrams2");
    expect(lblGrams2.tagName).toBe("LABEL");
    expect(lblGrams2.classList).toContain("form-label");
    expect(lblGrams2.textContent).toEqual(
      t("Settlements:FV95Residue_lblGrams2")
    );
  });
  test("Custom Test Cases for lblGrams2", () => {
    // START_USER_CODE-USER_lblGrams2_TEST
    // END_USER_CODE-USER_lblGrams2_TEST
  });
  test("lblHMCConvertedGrades(Label Widget) Test Cases", async () => {
    const lblHMCConvertedGrades = screen.getByTestId("lblHMCConvertedGrades");
    expect(lblHMCConvertedGrades.tagName).toBe("LABEL");
    expect(lblHMCConvertedGrades.classList).toContain("form-label");
    expect(lblHMCConvertedGrades.textContent).toEqual(
      t("Settlements:FV95Residue_lblHMCConvertedGrades")
    );
  });
  test("Custom Test Cases for lblHMCConvertedGrades", () => {
    // START_USER_CODE-USER_lblHMCConvertedGrades_TEST
    // END_USER_CODE-USER_lblHMCConvertedGrades_TEST
  });
  test("lblInspectorRemarks(Label Widget) Test Cases", async () => {
    const lblInspectorRemarks = screen.getByTestId("lblInspectorRemarks");
    expect(lblInspectorRemarks.tagName).toBe("LABEL");
    expect(lblInspectorRemarks.classList).toContain("form-label");
    expect(lblInspectorRemarks.textContent).toEqual(
      t("Settlements:FV95Residue_lblInspectorRemarks")
    );
  });
  test("Custom Test Cases for lblInspectorRemarks", () => {
    // START_USER_CODE-USER_lblInspectorRemarks_TEST
    // END_USER_CODE-USER_lblInspectorRemarks_TEST
  });
  test("lblName(Label Widget) Test Cases", async () => {
    const lblName = screen.getByTestId("lblName");
    expect(lblName.tagName).toBe("LABEL");
    expect(lblName.classList).toContain("form-label");
    expect(lblName.textContent).toEqual(t("Settlements:FV95Residue_lblName"));
  });
  test("Custom Test Cases for lblName", () => {
    // START_USER_CODE-USER_lblName_TEST
    // END_USER_CODE-USER_lblName_TEST
  });
  test("lblNumber(Label Widget) Test Cases", async () => {
    const lblNumber = screen.getByTestId("lblNumber");
    expect(lblNumber.tagName).toBe("LABEL");
    expect(lblNumber.classList).toContain("form-label");
    expect(lblNumber.textContent).toEqual(
      t("Settlements:FV95Residue_lblNumber")
    );
  });
  test("Custom Test Cases for lblNumber", () => {
    // START_USER_CODE-USER_lblNumber_TEST
    // END_USER_CODE-USER_lblNumber_TEST
  });
  test("lblPct1(Label Widget) Test Cases", async () => {
    const lblPct1 = screen.getByTestId("lblPct1");
    expect(lblPct1.tagName).toBe("LABEL");
    expect(lblPct1.classList).toContain("form-label");
    expect(lblPct1.textContent).toEqual(t("Settlements:FV95Residue_lblPct1"));
  });
  test("Custom Test Cases for lblPct1", () => {
    // START_USER_CODE-USER_lblPct1_TEST
    // END_USER_CODE-USER_lblPct1_TEST
  });
  test("lblPct2(Label Widget) Test Cases", async () => {
    const lblPct2 = screen.getByTestId("lblPct2");
    expect(lblPct2.tagName).toBe("LABEL");
    expect(lblPct2.classList).toContain("form-label");
    expect(lblPct2.textContent).toEqual(t("Settlements:FV95Residue_lblPct2"));
  });
  test("Custom Test Cases for lblPct2", () => {
    // START_USER_CODE-USER_lblPct2_TEST
    // END_USER_CODE-USER_lblPct2_TEST
  });
  test("lblPeanutInformation(Label Widget) Test Cases", async () => {
    const lblPeanutInformation = screen.getByTestId("lblPeanutInformation");
    expect(lblPeanutInformation.tagName).toBe("LABEL");
    expect(lblPeanutInformation.classList).toContain("form-label");
    expect(lblPeanutInformation.textContent).toEqual(
      t("Settlements:FV95Residue_lblPeanutInformation")
    );
  });
  test("Custom Test Cases for lblPeanutInformation", () => {
    // START_USER_CODE-USER_lblPeanutInformation_TEST
    // END_USER_CODE-USER_lblPeanutInformation_TEST
  });
  test("lblPerLb(Label Widget) Test Cases", async () => {
    const lblPerLb = screen.getByTestId("lblPerLb");
    expect(lblPerLb.tagName).toBe("LABEL");
    expect(lblPerLb.classList).toContain("form-label");
    expect(lblPerLb.textContent).toEqual(t("Settlements:FV95Residue_lblPerLb"));
  });
  test("Custom Test Cases for lblPerLb", () => {
    // START_USER_CODE-USER_lblPerLb_TEST
    // END_USER_CODE-USER_lblPerLb_TEST
  });
  test("lblPerLb2(Label Widget) Test Cases", async () => {
    const lblPerLb2 = screen.getByTestId("lblPerLb2");
    expect(lblPerLb2.tagName).toBe("LABEL");
    expect(lblPerLb2.classList).toContain("form-label");
    expect(lblPerLb2.textContent).toEqual(
      t("Settlements:FV95Residue_lblPerLb2")
    );
  });
  test("Custom Test Cases for lblPerLb2", () => {
    // START_USER_CODE-USER_lblPerLb2_TEST
    // END_USER_CODE-USER_lblPerLb2_TEST
  });
  test("lblPerLb3(Label Widget) Test Cases", async () => {
    const lblPerLb3 = screen.getByTestId("lblPerLb3");
    expect(lblPerLb3.tagName).toBe("LABEL");
    expect(lblPerLb3.classList).toContain("form-label");
    expect(lblPerLb3.textContent).toEqual(
      t("Settlements:FV95Residue_lblPerLb3")
    );
  });
  test("Custom Test Cases for lblPerLb3", () => {
    // START_USER_CODE-USER_lblPerLb3_TEST
    // END_USER_CODE-USER_lblPerLb3_TEST
  });
  test("lblPerTon(Label Widget) Test Cases", async () => {
    const lblPerTon = screen.getByTestId("lblPerTon");
    expect(lblPerTon.tagName).toBe("LABEL");
    expect(lblPerTon.classList).toContain("form-label");
    expect(lblPerTon.textContent).toEqual(
      t("Settlements:FV95Residue_lblPerTon")
    );
  });
  test("Custom Test Cases for lblPerTon", () => {
    // START_USER_CODE-USER_lblPerTon_TEST
    // END_USER_CODE-USER_lblPerTon_TEST
  });
  test("lblValueCalculation(Label Widget) Test Cases", async () => {
    const lblValueCalculation = screen.getByTestId("lblValueCalculation");
    expect(lblValueCalculation.tagName).toBe("LABEL");
    expect(lblValueCalculation.classList).toContain("form-label");
    expect(lblValueCalculation.textContent).toEqual(
      t("Settlements:FV95Residue_lblValueCalculation")
    );
  });
  test("Custom Test Cases for lblValueCalculation", () => {
    // START_USER_CODE-USER_lblValueCalculation_TEST
    // END_USER_CODE-USER_lblValueCalculation_TEST
  });
  test("txt1007(Textbox Widget) Test Cases", async () => {
    const txt1007 = screen.getByTestId("txt1007");
    expect(txt1007.tagName).toBe("INPUT");
    expect(txt1007.type).toBe("text");
    expect(txt1007.classList).toContain("textboxWidgetClass");
    expect(txt1007.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txt1007")
    );
    await act(async () => {
      userEvent.type(txt1007, "1");
    });
  });
  test("Custom Test Cases for txt1007", () => {
    // START_USER_CODE-USER_txt1007_TEST
    // END_USER_CODE-USER_txt1007_TEST
  });
  test("txtAccessFMLb(Textbox Widget) Test Cases", async () => {
    const txtAccessFMLb = screen.getByTestId("txtAccessFMLb");
    expect(txtAccessFMLb.tagName).toBe("INPUT");
    expect(txtAccessFMLb.type).toBe("text");
    expect(txtAccessFMLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAccessFMLb, "123");
    });
    expect(txtAccessFMLb.getAttribute("value")).toBe("");
    expect(txtAccessFMLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAccessFMLb", () => {
    // START_USER_CODE-USER_txtAccessFMLb_TEST
    // END_USER_CODE-USER_txtAccessFMLb_TEST
  });
  test("txtAccessFMTon(Textbox Widget) Test Cases", async () => {
    const txtAccessFMTon = screen.getByTestId("txtAccessFMTon");
    expect(txtAccessFMTon.tagName).toBe("INPUT");
    expect(txtAccessFMTon.type).toBe("text");
    expect(txtAccessFMTon.classList).toContain("textboxWidgetClass");
    expect(txtAccessFMTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtAccessFMTon")
    );
    await act(async () => {
      userEvent.type(txtAccessFMTon, "123");
    });
    expect(txtAccessFMTon.getAttribute("value")).toBe("");
    expect(txtAccessFMTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAccessFMTon", () => {
    // START_USER_CODE-USER_txtAccessFMTon_TEST
    // END_USER_CODE-USER_txtAccessFMTon_TEST
  });
  test("txtAFlavusLb(Textbox Widget) Test Cases", async () => {
    const txtAFlavusLb = screen.getByTestId("txtAFlavusLb");
    expect(txtAFlavusLb.tagName).toBe("INPUT");
    expect(txtAFlavusLb.type).toBe("text");
    expect(txtAFlavusLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAFlavusLb, "123");
    });
    expect(txtAFlavusLb.getAttribute("value")).toBe("");
    expect(txtAFlavusLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAFlavusLb", () => {
    // START_USER_CODE-USER_txtAFlavusLb_TEST
    // END_USER_CODE-USER_txtAFlavusLb_TEST
  });
  test("txtAFlavusTon(Textbox Widget) Test Cases", async () => {
    const txtAFlavusTon = screen.getByTestId("txtAFlavusTon");
    expect(txtAFlavusTon.tagName).toBe("INPUT");
    expect(txtAFlavusTon.type).toBe("text");
    expect(txtAFlavusTon.classList).toContain("textboxWidgetClass");
    expect(txtAFlavusTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtAFlavusTon")
    );
    await act(async () => {
      userEvent.type(txtAFlavusTon, "123");
    });
    expect(txtAFlavusTon.getAttribute("value")).toBe("");
    expect(txtAFlavusTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAFlavusTon", () => {
    // START_USER_CODE-USER_txtAFlavusTon_TEST
    // END_USER_CODE-USER_txtAFlavusTon_TEST
  });
  test("txtBluePanWt(Textbox Widget) Test Cases", async () => {
    const txtBluePanWt = screen.getByTestId("txtBluePanWt");
    expect(txtBluePanWt.tagName).toBe("INPUT");
    expect(txtBluePanWt.type).toBe("text");
    expect(txtBluePanWt.classList).toContain("textboxWidgetClass");
    expect(txtBluePanWt.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtBluePanWt")
    );
    await act(async () => {
      userEvent.type(txtBluePanWt, "1");
    });
  });
  test("Custom Test Cases for txtBluePanWt", () => {
    // START_USER_CODE-USER_txtBluePanWt_TEST
    // END_USER_CODE-USER_txtBluePanWt_TEST
  });
  test("txtBuyerName(Textbox Widget) Test Cases", async () => {
    const txtBuyerName = screen.getByTestId("txtBuyerName");
    expect(txtBuyerName.tagName).toBe("INPUT");
    expect(txtBuyerName.type).toBe("text");
    expect(txtBuyerName.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtBuyerName, "1");
    });
  });
  test("Custom Test Cases for txtBuyerName", () => {
    // START_USER_CODE-USER_txtBuyerName_TEST
    // END_USER_CODE-USER_txtBuyerName_TEST
  });
  test("txtBuyerNumber(Textbox Widget) Test Cases", async () => {
    const txtBuyerNumber = screen.getByTestId("txtBuyerNumber");
    expect(txtBuyerNumber.tagName).toBe("INPUT");
    expect(txtBuyerNumber.type).toBe("text");
    expect(txtBuyerNumber.classList).toContain("textboxWidgetClass");
    expect(txtBuyerNumber.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtBuyerNumber")
    );
    await act(async () => {
      userEvent.type(txtBuyerNumber, "1");
    });
  });
  test("Custom Test Cases for txtBuyerNumber", () => {
    // START_USER_CODE-USER_txtBuyerNumber_TEST
    // END_USER_CODE-USER_txtBuyerNumber_TEST
  });
  test("txtCleanSampleWt(Textbox Widget) Test Cases", async () => {
    const txtCleanSampleWt = screen.getByTestId("txtCleanSampleWt");
    expect(txtCleanSampleWt.tagName).toBe("INPUT");
    expect(txtCleanSampleWt.type).toBe("text");
    expect(txtCleanSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtCleanSampleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtCleanSampleWt")
    );
    await act(async () => {
      userEvent.type(txtCleanSampleWt, "1");
    });
  });
  test("Custom Test Cases for txtCleanSampleWt", () => {
    // START_USER_CODE-USER_txtCleanSampleWt_TEST
    // END_USER_CODE-USER_txtCleanSampleWt_TEST
  });
  test("txtConcealedRMDGr(Textbox Widget) Test Cases", async () => {
    const txtConcealedRMDGr = screen.getByTestId("txtConcealedRMDGr");
    expect(txtConcealedRMDGr.tagName).toBe("INPUT");
    expect(txtConcealedRMDGr.type).toBe("text");
    expect(txtConcealedRMDGr.classList).toContain("textboxWidgetClass");
    expect(txtConcealedRMDGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtConcealedRMDGr")
    );
    await act(async () => {
      userEvent.type(txtConcealedRMDGr, "1");
    });
  });
  test("Custom Test Cases for txtConcealedRMDGr", () => {
    // START_USER_CODE-USER_txtConcealedRMDGr_TEST
    // END_USER_CODE-USER_txtConcealedRMDGr_TEST
  });
  test("txtConcealedRMDPct(Textbox Widget) Test Cases", async () => {
    const txtConcealedRMDPct = screen.getByTestId("txtConcealedRMDPct");
    expect(txtConcealedRMDPct.tagName).toBe("INPUT");
    expect(txtConcealedRMDPct.type).toBe("text");
    expect(txtConcealedRMDPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtConcealedRMDPct, "1");
    });
  });
  test("Custom Test Cases for txtConcealedRMDPct", () => {
    // START_USER_CODE-USER_txtConcealedRMDPct_TEST
    // END_USER_CODE-USER_txtConcealedRMDPct_TEST
  });
  test("txtCrkOrBrkShellsGr(Textbox Widget) Test Cases", async () => {
    const txtCrkOrBrkShellsGr = screen.getByTestId("txtCrkOrBrkShellsGr");
    expect(txtCrkOrBrkShellsGr.tagName).toBe("INPUT");
    expect(txtCrkOrBrkShellsGr.type).toBe("text");
    expect(txtCrkOrBrkShellsGr.classList).toContain("textboxWidgetClass");
    expect(txtCrkOrBrkShellsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtCrkOrBrkShellsGr")
    );
    await act(async () => {
      userEvent.type(txtCrkOrBrkShellsGr, "1");
    });
  });
  test("Custom Test Cases for txtCrkOrBrkShellsGr", () => {
    // START_USER_CODE-USER_txtCrkOrBrkShellsGr_TEST
    // END_USER_CODE-USER_txtCrkOrBrkShellsGr_TEST
  });
  test("txtCrkOrBrkShellsPct(Textbox Widget) Test Cases", async () => {
    const txtCrkOrBrkShellsPct = screen.getByTestId("txtCrkOrBrkShellsPct");
    expect(txtCrkOrBrkShellsPct.tagName).toBe("INPUT");
    expect(txtCrkOrBrkShellsPct.type).toBe("text");
    expect(txtCrkOrBrkShellsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCrkOrBrkShellsPct, "1");
    });
  });
  test("Custom Test Cases for txtCrkOrBrkShellsPct", () => {
    // START_USER_CODE-USER_txtCrkOrBrkShellsPct_TEST
    // END_USER_CODE-USER_txtCrkOrBrkShellsPct_TEST
  });
  test("txtCRMD(Textbox Widget) Test Cases", async () => {
    const txtCRMD = screen.getByTestId("txtCRMD");
    expect(txtCRMD.tagName).toBe("INPUT");
    expect(txtCRMD.type).toBe("text");
    expect(txtCRMD.classList).toContain("textboxWidgetClass");
    expect(txtCRMD.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtCRMD")
    );
    await act(async () => {
      userEvent.type(txtCRMD, "1");
    });
  });
  test("Custom Test Cases for txtCRMD", () => {
    // START_USER_CODE-USER_txtCRMD_TEST
    // END_USER_CODE-USER_txtCRMD_TEST
  });
  test("txtDAM(Textbox Widget) Test Cases", async () => {
    const txtDAM = screen.getByTestId("txtDAM");
    expect(txtDAM.tagName).toBe("INPUT");
    expect(txtDAM.type).toBe("text");
    expect(txtDAM.classList).toContain("textboxWidgetClass");
    expect(txtDAM.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtDAM")
    );
    await act(async () => {
      userEvent.type(txtDAM, "123");
    });
    expect(txtDAM.getAttribute("value")).toBe("");
    expect(txtDAM.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDAM", () => {
    // START_USER_CODE-USER_txtDAM_TEST
    // END_USER_CODE-USER_txtDAM_TEST
  });
  test("txtDamagedKRS(Textbox Widget) Test Cases", async () => {
    const txtDamagedKRS = screen.getByTestId("txtDamagedKRS");
    expect(txtDamagedKRS.tagName).toBe("INPUT");
    expect(txtDamagedKRS.type).toBe("text");
    expect(txtDamagedKRS.classList).toContain("textboxWidgetClass");
    expect(txtDamagedKRS.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtDamagedKRS")
    );
    await act(async () => {
      userEvent.type(txtDamagedKRS, "1");
    });
  });
  test("Custom Test Cases for txtDamagedKRS", () => {
    // START_USER_CODE-USER_txtDamagedKRS_TEST
    // END_USER_CODE-USER_txtDamagedKRS_TEST
  });
  test("txtDamageLbs(Textbox Widget) Test Cases", async () => {
    const txtDamageLbs = screen.getByTestId("txtDamageLbs");
    expect(txtDamageLbs.tagName).toBe("INPUT");
    expect(txtDamageLbs.type).toBe("text");
    expect(txtDamageLbs.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDamageLbs, "123");
    });
    expect(txtDamageLbs.getAttribute("value")).toBe("");
    expect(txtDamageLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDamageLbs", () => {
    // START_USER_CODE-USER_txtDamageLbs_TEST
    // END_USER_CODE-USER_txtDamageLbs_TEST
  });
  test("txtDamageSplits(Textbox Widget) Test Cases", async () => {
    const txtDamageSplits = screen.getByTestId("txtDamageSplits");
    expect(txtDamageSplits.tagName).toBe("INPUT");
    expect(txtDamageSplits.type).toBe("text");
    expect(txtDamageSplits.classList).toContain("textboxWidgetClass");
    expect(txtDamageSplits.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtDamageSplits")
    );
    await act(async () => {
      userEvent.type(txtDamageSplits, "1");
    });
  });
  test("Custom Test Cases for txtDamageSplits", () => {
    // START_USER_CODE-USER_txtDamageSplits_TEST
    // END_USER_CODE-USER_txtDamageSplits_TEST
  });
  test("txtDamageTon(Textbox Widget) Test Cases", async () => {
    const txtDamageTon = screen.getByTestId("txtDamageTon");
    expect(txtDamageTon.tagName).toBe("INPUT");
    expect(txtDamageTon.type).toBe("text");
    expect(txtDamageTon.classList).toContain("textboxWidgetClass");
    expect(txtDamageTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtDamageTon")
    );
    await act(async () => {
      userEvent.type(txtDamageTon, "123");
    });
    expect(txtDamageTon.getAttribute("value")).toBe("");
    expect(txtDamageTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDamageTon", () => {
    // START_USER_CODE-USER_txtDamageTon_TEST
    // END_USER_CODE-USER_txtDamageTon_TEST
  });
  test("txtDiscoloredShellsGr(Textbox Widget) Test Cases", async () => {
    const txtDiscoloredShellsGr = screen.getByTestId("txtDiscoloredShellsGr");
    expect(txtDiscoloredShellsGr.tagName).toBe("INPUT");
    expect(txtDiscoloredShellsGr.type).toBe("text");
    expect(txtDiscoloredShellsGr.classList).toContain("textboxWidgetClass");
    expect(txtDiscoloredShellsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtDiscoloredShellsGr")
    );
    await act(async () => {
      userEvent.type(txtDiscoloredShellsGr, "1");
    });
  });
  test("Custom Test Cases for txtDiscoloredShellsGr", () => {
    // START_USER_CODE-USER_txtDiscoloredShellsGr_TEST
    // END_USER_CODE-USER_txtDiscoloredShellsGr_TEST
  });
  test("txtDiscoloredShellsPct(Textbox Widget) Test Cases", async () => {
    const txtDiscoloredShellsPct = screen.getByTestId("txtDiscoloredShellsPct");
    expect(txtDiscoloredShellsPct.tagName).toBe("INPUT");
    expect(txtDiscoloredShellsPct.type).toBe("text");
    expect(txtDiscoloredShellsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDiscoloredShellsPct, "1");
    });
  });
  test("Custom Test Cases for txtDiscoloredShellsPct", () => {
    // START_USER_CODE-USER_txtDiscoloredShellsPct_TEST
    // END_USER_CODE-USER_txtDiscoloredShellsPct_TEST
  });
  test("txtELK(Textbox Widget) Test Cases", async () => {
    const txtELK = screen.getByTestId("txtELK");
    expect(txtELK.tagName).toBe("INPUT");
    expect(txtELK.type).toBe("text");
    expect(txtELK.classList).toContain("textboxWidgetClass");
    expect(txtELK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtELK")
    );
    await act(async () => {
      userEvent.type(txtELK, "123");
    });
    expect(txtELK.getAttribute("value")).toBe("");
    expect(txtELK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtELK", () => {
    // START_USER_CODE-USER_txtELK_TEST
    // END_USER_CODE-USER_txtELK_TEST
  });
  test("txtELKDamage(Textbox Widget) Test Cases", async () => {
    const txtELKDamage = screen.getByTestId("txtELKDamage");
    expect(txtELKDamage.tagName).toBe("INPUT");
    expect(txtELKDamage.type).toBe("text");
    expect(txtELKDamage.classList).toContain("textboxWidgetClass");
    expect(txtELKDamage.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtELKDamage")
    );
    await act(async () => {
      userEvent.type(txtELKDamage, "1");
    });
  });
  test("Custom Test Cases for txtELKDamage", () => {
    // START_USER_CODE-USER_txtELKDamage_TEST
    // END_USER_CODE-USER_txtELKDamage_TEST
  });
  test("txtELKGr(Textbox Widget) Test Cases", async () => {
    const txtELKGr = screen.getByTestId("txtELKGr");
    expect(txtELKGr.tagName).toBe("INPUT");
    expect(txtELKGr.type).toBe("text");
    expect(txtELKGr.classList).toContain("textboxWidgetClass");
    expect(txtELKGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtELKGr")
    );
    await act(async () => {
      userEvent.type(txtELKGr, "1");
    });
  });
  test("Custom Test Cases for txtELKGr", () => {
    // START_USER_CODE-USER_txtELKGr_TEST
    // END_USER_CODE-USER_txtELKGr_TEST
  });
  test("txtELKPct(Textbox Widget) Test Cases", async () => {
    const txtELKPct = screen.getByTestId("txtELKPct");
    expect(txtELKPct.tagName).toBe("INPUT");
    expect(txtELKPct.type).toBe("text");
    expect(txtELKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtELKPct, "1");
    });
  });
  test("Custom Test Cases for txtELKPct", () => {
    // START_USER_CODE-USER_txtELKPct_TEST
    // END_USER_CODE-USER_txtELKPct_TEST
  });
  test("txtELKPremiumLb(Textbox Widget) Test Cases", async () => {
    const txtELKPremiumLb = screen.getByTestId("txtELKPremiumLb");
    expect(txtELKPremiumLb.tagName).toBe("INPUT");
    expect(txtELKPremiumLb.type).toBe("text");
    expect(txtELKPremiumLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtELKPremiumLb, "123");
    });
    expect(txtELKPremiumLb.getAttribute("value")).toBe("");
    expect(txtELKPremiumLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtELKPremiumLb", () => {
    // START_USER_CODE-USER_txtELKPremiumLb_TEST
    // END_USER_CODE-USER_txtELKPremiumLb_TEST
  });
  test("txtELKPremiumTon(Textbox Widget) Test Cases", async () => {
    const txtELKPremiumTon = screen.getByTestId("txtELKPremiumTon");
    expect(txtELKPremiumTon.tagName).toBe("INPUT");
    expect(txtELKPremiumTon.type).toBe("text");
    expect(txtELKPremiumTon.classList).toContain("textboxWidgetClass");
    expect(txtELKPremiumTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtELKPremiumTon")
    );
    await act(async () => {
      userEvent.type(txtELKPremiumTon, "123");
    });
    expect(txtELKPremiumTon.getAttribute("value")).toBe("");
    expect(txtELKPremiumTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtELKPremiumTon", () => {
    // START_USER_CODE-USER_txtELKPremiumTon_TEST
    // END_USER_CODE-USER_txtELKPremiumTon_TEST
  });
  test("txtEVehicleNum(Textbox Widget) Test Cases", async () => {
    const txtEVehicleNum = screen.getByTestId("txtEVehicleNum");
    expect(txtEVehicleNum.tagName).toBe("INPUT");
    expect(txtEVehicleNum.type).toBe("text");
    expect(txtEVehicleNum.classList).toContain("textboxWidgetClass");
    expect(txtEVehicleNum.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtEVehicleNum")
    );
    await act(async () => {
      userEvent.type(txtEVehicleNum, "123");
    });
    expect(txtEVehicleNum.getAttribute("value")).toBe("");
    expect(txtEVehicleNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEVehicleNum", () => {
    // START_USER_CODE-USER_txtEVehicleNum_TEST
    // END_USER_CODE-USER_txtEVehicleNum_TEST
  });
  test("txtEWeightTkt(Textbox Widget) Test Cases", async () => {
    const txtEWeightTkt = screen.getByTestId("txtEWeightTkt");
    expect(txtEWeightTkt.tagName).toBe("INPUT");
    expect(txtEWeightTkt.type).toBe("text");
    expect(txtEWeightTkt.classList).toContain("textboxWidgetClass");
    expect(txtEWeightTkt.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtEWeightTkt")
    );
    await act(async () => {
      userEvent.type(txtEWeightTkt, "123");
    });
    expect(txtEWeightTkt.getAttribute("value")).toBe("");
    expect(txtEWeightTkt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEWeightTkt", () => {
    // START_USER_CODE-USER_txtEWeightTkt_TEST
    // END_USER_CODE-USER_txtEWeightTkt_TEST
  });
  test("txtEWtInclVeh(Textbox Widget) Test Cases", async () => {
    const txtEWtInclVeh = screen.getByTestId("txtEWtInclVeh");
    expect(txtEWtInclVeh.tagName).toBe("INPUT");
    expect(txtEWtInclVeh.type).toBe("text");
    expect(txtEWtInclVeh.classList).toContain("textboxWidgetClass");
    expect(txtEWtInclVeh.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtEWtInclVeh")
    );
    await act(async () => {
      userEvent.type(txtEWtInclVeh, "123");
    });
    expect(txtEWtInclVeh.getAttribute("value")).toBe("");
    expect(txtEWtInclVeh.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEWtInclVeh", () => {
    // START_USER_CODE-USER_txtEWtInclVeh_TEST
    // END_USER_CODE-USER_txtEWtInclVeh_TEST
  });
  test("txtEWtOfPnut(Textbox Widget) Test Cases", async () => {
    const txtEWtOfPnut = screen.getByTestId("txtEWtOfPnut");
    expect(txtEWtOfPnut.tagName).toBe("INPUT");
    expect(txtEWtOfPnut.type).toBe("text");
    expect(txtEWtOfPnut.classList).toContain("textboxWidgetClass");
    expect(txtEWtOfPnut.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtEWtOfPnut")
    );
    await act(async () => {
      userEvent.type(txtEWtOfPnut, "123");
    });
    expect(txtEWtOfPnut.getAttribute("value")).toBe("");
    expect(txtEWtOfPnut.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEWtOfPnut", () => {
    // START_USER_CODE-USER_txtEWtOfPnut_TEST
    // END_USER_CODE-USER_txtEWtOfPnut_TEST
  });
  test("txtEWtOfVeh(Textbox Widget) Test Cases", async () => {
    const txtEWtOfVeh = screen.getByTestId("txtEWtOfVeh");
    expect(txtEWtOfVeh.tagName).toBe("INPUT");
    expect(txtEWtOfVeh.type).toBe("text");
    expect(txtEWtOfVeh.classList).toContain("textboxWidgetClass");
    expect(txtEWtOfVeh.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtEWtOfVeh")
    );
    await act(async () => {
      userEvent.type(txtEWtOfVeh, "123");
    });
    expect(txtEWtOfVeh.getAttribute("value")).toBe("");
    expect(txtEWtOfVeh.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtEWtOfVeh", () => {
    // START_USER_CODE-USER_txtEWtOfVeh_TEST
    // END_USER_CODE-USER_txtEWtOfVeh_TEST
  });
  test("txtExcessMoisture(Textbox Widget) Test Cases", async () => {
    const txtExcessMoisture = screen.getByTestId("txtExcessMoisture");
    expect(txtExcessMoisture.tagName).toBe("INPUT");
    expect(txtExcessMoisture.type).toBe("text");
    expect(txtExcessMoisture.classList).toContain("textboxWidgetClass");
    expect(txtExcessMoisture.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtExcessMoisture")
    );
    await act(async () => {
      userEvent.type(txtExcessMoisture, "123");
    });
    expect(txtExcessMoisture.getAttribute("value")).toBe("");
    expect(txtExcessMoisture.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtExcessMoisture", () => {
    // START_USER_CODE-USER_txtExcessMoisture_TEST
    // END_USER_CODE-USER_txtExcessMoisture_TEST
  });
  test("txtExcessSplitsLb(Textbox Widget) Test Cases", async () => {
    const txtExcessSplitsLb = screen.getByTestId("txtExcessSplitsLb");
    expect(txtExcessSplitsLb.tagName).toBe("INPUT");
    expect(txtExcessSplitsLb.type).toBe("text");
    expect(txtExcessSplitsLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtExcessSplitsLb, "123");
    });
    expect(txtExcessSplitsLb.getAttribute("value")).toBe("");
    expect(txtExcessSplitsLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtExcessSplitsLb", () => {
    // START_USER_CODE-USER_txtExcessSplitsLb_TEST
    // END_USER_CODE-USER_txtExcessSplitsLb_TEST
  });
  test("txtExcessSplitsTon(Textbox Widget) Test Cases", async () => {
    const txtExcessSplitsTon = screen.getByTestId("txtExcessSplitsTon");
    expect(txtExcessSplitsTon.tagName).toBe("INPUT");
    expect(txtExcessSplitsTon.type).toBe("text");
    expect(txtExcessSplitsTon.classList).toContain("textboxWidgetClass");
    expect(txtExcessSplitsTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtExcessSplitsTon")
    );
    await act(async () => {
      userEvent.type(txtExcessSplitsTon, "123");
    });
    expect(txtExcessSplitsTon.getAttribute("value")).toBe("");
    expect(txtExcessSplitsTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtExcessSplitsTon", () => {
    // START_USER_CODE-USER_txtExcessSplitsTon_TEST
    // END_USER_CODE-USER_txtExcessSplitsTon_TEST
  });
  test("txtFANCY(Textbox Widget) Test Cases", async () => {
    const txtFANCY = screen.getByTestId("txtFANCY");
    expect(txtFANCY.tagName).toBe("INPUT");
    expect(txtFANCY.type).toBe("text");
    expect(txtFANCY.classList).toContain("textboxWidgetClass");
    expect(txtFANCY.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtFANCY")
    );
    await act(async () => {
      userEvent.type(txtFANCY, "123");
    });
    expect(txtFANCY.getAttribute("value")).toBe("");
    expect(txtFANCY.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFANCY", () => {
    // START_USER_CODE-USER_txtFANCY_TEST
    // END_USER_CODE-USER_txtFANCY_TEST
  });
  test("txtFancyGr(Textbox Widget) Test Cases", async () => {
    const txtFancyGr = screen.getByTestId("txtFancyGr");
    expect(txtFancyGr.tagName).toBe("INPUT");
    expect(txtFancyGr.type).toBe("text");
    expect(txtFancyGr.classList).toContain("textboxWidgetClass");
    expect(txtFancyGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtFancyGr")
    );
    await act(async () => {
      userEvent.type(txtFancyGr, "1");
    });
  });
  test("Custom Test Cases for txtFancyGr", () => {
    // START_USER_CODE-USER_txtFancyGr_TEST
    // END_USER_CODE-USER_txtFancyGr_TEST
  });
  test("txtFancyPct(Textbox Widget) Test Cases", async () => {
    const txtFancyPct = screen.getByTestId("txtFancyPct");
    expect(txtFancyPct.tagName).toBe("INPUT");
    expect(txtFancyPct.type).toBe("text");
    expect(txtFancyPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFancyPct, "1");
    });
  });
  test("Custom Test Cases for txtFancyPct", () => {
    // START_USER_CODE-USER_txtFancyPct_TEST
    // END_USER_CODE-USER_txtFancyPct_TEST
  });
  test("txtFM(Textbox Widget) Test Cases", async () => {
    const txtFM = screen.getByTestId("txtFM");
    expect(txtFM.tagName).toBe("INPUT");
    expect(txtFM.type).toBe("text");
    expect(txtFM.classList).toContain("textboxWidgetClass");
    expect(txtFM.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtFM")
    );
    await act(async () => {
      userEvent.type(txtFM, "123");
    });
    expect(txtFM.getAttribute("value")).toBe("");
    expect(txtFM.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFM", () => {
    // START_USER_CODE-USER_txtFM_TEST
    // END_USER_CODE-USER_txtFM_TEST
  });
  test("txtFMSampleWt(Textbox Widget) Test Cases", async () => {
    const txtFMSampleWt = screen.getByTestId("txtFMSampleWt");
    expect(txtFMSampleWt.tagName).toBe("INPUT");
    expect(txtFMSampleWt.type).toBe("text");
    expect(txtFMSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtFMSampleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtFMSampleWt")
    );
    await act(async () => {
      userEvent.type(txtFMSampleWt, "1");
    });
  });
  test("Custom Test Cases for txtFMSampleWt", () => {
    // START_USER_CODE-USER_txtFMSampleWt_TEST
    // END_USER_CODE-USER_txtFMSampleWt_TEST
  });
  test("txtForeignMaterial(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterial = screen.getByTestId("txtForeignMaterial");
    expect(txtForeignMaterial.tagName).toBe("INPUT");
    expect(txtForeignMaterial.type).toBe("text");
    expect(txtForeignMaterial.classList).toContain("textboxWidgetClass");
    expect(txtForeignMaterial.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtForeignMaterial")
    );
    await act(async () => {
      userEvent.type(txtForeignMaterial, "123");
    });
    expect(txtForeignMaterial.getAttribute("value")).toBe("");
    expect(txtForeignMaterial.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtForeignMaterial", () => {
    // START_USER_CODE-USER_txtForeignMaterial_TEST
    // END_USER_CODE-USER_txtForeignMaterial_TEST
  });
  test("txtForeignMaterialGr(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterialGr = screen.getByTestId("txtForeignMaterialGr");
    expect(txtForeignMaterialGr.tagName).toBe("INPUT");
    expect(txtForeignMaterialGr.type).toBe("text");
    expect(txtForeignMaterialGr.classList).toContain("textboxWidgetClass");
    expect(txtForeignMaterialGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtForeignMaterialGr")
    );
    await act(async () => {
      userEvent.type(txtForeignMaterialGr, "1");
    });
  });
  test("Custom Test Cases for txtForeignMaterialGr", () => {
    // START_USER_CODE-USER_txtForeignMaterialGr_TEST
    // END_USER_CODE-USER_txtForeignMaterialGr_TEST
  });
  test("txtForeignMaterialPct(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterialPct = screen.getByTestId("txtForeignMaterialPct");
    expect(txtForeignMaterialPct.tagName).toBe("INPUT");
    expect(txtForeignMaterialPct.type).toBe("text");
    expect(txtForeignMaterialPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtForeignMaterialPct, "123");
    });
    expect(txtForeignMaterialPct.getAttribute("value")).toBe("");
    expect(txtForeignMaterialPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtForeignMaterialPct", () => {
    // START_USER_CODE-USER_txtForeignMaterialPct_TEST
    // END_USER_CODE-USER_txtForeignMaterialPct_TEST
  });
  test("txtFreezeDamageGr(Textbox Widget) Test Cases", async () => {
    const txtFreezeDamageGr = screen.getByTestId("txtFreezeDamageGr");
    expect(txtFreezeDamageGr.tagName).toBe("INPUT");
    expect(txtFreezeDamageGr.type).toBe("text");
    expect(txtFreezeDamageGr.classList).toContain("textboxWidgetClass");
    expect(txtFreezeDamageGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtFreezeDamageGr")
    );
    await act(async () => {
      userEvent.type(txtFreezeDamageGr, "1");
    });
  });
  test("Custom Test Cases for txtFreezeDamageGr", () => {
    // START_USER_CODE-USER_txtFreezeDamageGr_TEST
    // END_USER_CODE-USER_txtFreezeDamageGr_TEST
  });
  test("txtFreezeDamagePct(Textbox Widget) Test Cases", async () => {
    const txtFreezeDamagePct = screen.getByTestId("txtFreezeDamagePct");
    expect(txtFreezeDamagePct.tagName).toBe("INPUT");
    expect(txtFreezeDamagePct.type).toBe("text");
    expect(txtFreezeDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFreezeDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtFreezeDamagePct", () => {
    // START_USER_CODE-USER_txtFreezeDamagePct_TEST
    // END_USER_CODE-USER_txtFreezeDamagePct_TEST
  });
  test("txtFRZ(Textbox Widget) Test Cases", async () => {
    const txtFRZ = screen.getByTestId("txtFRZ");
    expect(txtFRZ.tagName).toBe("INPUT");
    expect(txtFRZ.type).toBe("text");
    expect(txtFRZ.classList).toContain("textboxWidgetClass");
    expect(txtFRZ.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtFRZ")
    );
    await act(async () => {
      userEvent.type(txtFRZ, "123");
    });
    expect(txtFRZ.getAttribute("value")).toBe("");
    expect(txtFRZ.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFRZ", () => {
    // START_USER_CODE-USER_txtFRZ_TEST
    // END_USER_CODE-USER_txtFRZ_TEST
  });
  test("txtG(Textbox Widget) Test Cases", async () => {
    const txtG = screen.getByTestId("txtG");
    expect(txtG.tagName).toBe("INPUT");
    expect(txtG.type).toBe("text");
    expect(txtG.classList).toContain("textboxWidgetClass");
    expect(txtG.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtG")
    );
    await act(async () => {
      userEvent.type(txtG, "123");
    });
    expect(txtG.getAttribute("value")).toBe("");
    expect(txtG.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtG", () => {
    // START_USER_CODE-USER_txtG_TEST
    // END_USER_CODE-USER_txtG_TEST
  });
  test("txtGrossWeight(Textbox Widget) Test Cases", async () => {
    const txtGrossWeight = screen.getByTestId("txtGrossWeight");
    expect(txtGrossWeight.tagName).toBe("INPUT");
    expect(txtGrossWeight.type).toBe("text");
    expect(txtGrossWeight.classList).toContain("textboxWidgetClass");
    expect(txtGrossWeight.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtGrossWeight")
    );
    await act(async () => {
      userEvent.type(txtGrossWeight, "123");
    });
    expect(txtGrossWeight.getAttribute("value")).toBe("");
    expect(txtGrossWeight.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtGrossWeight", () => {
    // START_USER_CODE-USER_txtGrossWeight_TEST
    // END_USER_CODE-USER_txtGrossWeight_TEST
  });
  test("txtH(Textbox Widget) Test Cases", async () => {
    const txtH = screen.getByTestId("txtH");
    expect(txtH.tagName).toBe("INPUT");
    expect(txtH.type).toBe("text");
    expect(txtH.classList).toContain("textboxWidgetClass");
    expect(txtH.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtH")
    );
    await act(async () => {
      userEvent.type(txtH, "123");
    });
    expect(txtH.getAttribute("value")).toBe("");
    expect(txtH.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtH", () => {
    // START_USER_CODE-USER_txtH_TEST
    // END_USER_CODE-USER_txtH_TEST
  });
  test("txtHullBrightness(Textbox Widget) Test Cases", async () => {
    const txtHullBrightness = screen.getByTestId("txtHullBrightness");
    expect(txtHullBrightness.tagName).toBe("INPUT");
    expect(txtHullBrightness.type).toBe("text");
    expect(txtHullBrightness.classList).toContain("textboxWidgetClass");
    expect(txtHullBrightness.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtHullBrightness")
    );
    await act(async () => {
      userEvent.type(txtHullBrightness, "1");
    });
  });
  test("Custom Test Cases for txtHullBrightness", () => {
    // START_USER_CODE-USER_txtHullBrightness_TEST
    // END_USER_CODE-USER_txtHullBrightness_TEST
  });
  test("txtHullBrightnessPct(Textbox Widget) Test Cases", async () => {
    const txtHullBrightnessPct = screen.getByTestId("txtHullBrightnessPct");
    expect(txtHullBrightnessPct.tagName).toBe("INPUT");
    expect(txtHullBrightnessPct.type).toBe("text");
    expect(txtHullBrightnessPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullBrightnessPct, "1");
    });
  });
  test("Custom Test Cases for txtHullBrightnessPct", () => {
    // START_USER_CODE-USER_txtHullBrightnessPct_TEST
    // END_USER_CODE-USER_txtHullBrightnessPct_TEST
  });
  test("txtHULLS(Textbox Widget) Test Cases", async () => {
    const txtHULLS = screen.getByTestId("txtHULLS");
    expect(txtHULLS.tagName).toBe("INPUT");
    expect(txtHULLS.type).toBe("text");
    expect(txtHULLS.classList).toContain("textboxWidgetClass");
    expect(txtHULLS.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtHULLS")
    );
    await act(async () => {
      userEvent.type(txtHULLS, "123");
    });
    expect(txtHULLS.getAttribute("value")).toBe("");
    expect(txtHULLS.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHULLS", () => {
    // START_USER_CODE-USER_txtHULLS_TEST
    // END_USER_CODE-USER_txtHULLS_TEST
  });
  test("txtHullsGr(Textbox Widget) Test Cases", async () => {
    const txtHullsGr = screen.getByTestId("txtHullsGr");
    expect(txtHullsGr.tagName).toBe("INPUT");
    expect(txtHullsGr.type).toBe("text");
    expect(txtHullsGr.classList).toContain("textboxWidgetClass");
    expect(txtHullsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtHullsGr")
    );
    await act(async () => {
      userEvent.type(txtHullsGr, "1");
    });
  });
  test("Custom Test Cases for txtHullsGr", () => {
    // START_USER_CODE-USER_txtHullsGr_TEST
    // END_USER_CODE-USER_txtHullsGr_TEST
  });
  test("txtHullsPct(Textbox Widget) Test Cases", async () => {
    const txtHullsPct = screen.getByTestId("txtHullsPct");
    expect(txtHullsPct.tagName).toBe("INPUT");
    expect(txtHullsPct.type).toBe("text");
    expect(txtHullsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullsPct, "1");
    });
  });
  test("Custom Test Cases for txtHullsPct", () => {
    // START_USER_CODE-USER_txtHullsPct_TEST
    // END_USER_CODE-USER_txtHullsPct_TEST
  });
  test("txtJumboGr(Textbox Widget) Test Cases", async () => {
    const txtJumboGr = screen.getByTestId("txtJumboGr");
    expect(txtJumboGr.tagName).toBe("INPUT");
    expect(txtJumboGr.type).toBe("text");
    expect(txtJumboGr.classList).toContain("textboxWidgetClass");
    expect(txtJumboGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtJumboGr")
    );
    await act(async () => {
      userEvent.type(txtJumboGr, "1");
    });
  });
  test("Custom Test Cases for txtJumboGr", () => {
    // START_USER_CODE-USER_txtJumboGr_TEST
    // END_USER_CODE-USER_txtJumboGr_TEST
  });
  test("txtJumboPct(Textbox Widget) Test Cases", async () => {
    const txtJumboPct = screen.getByTestId("txtJumboPct");
    expect(txtJumboPct.tagName).toBe("INPUT");
    expect(txtJumboPct.type).toBe("text");
    expect(txtJumboPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtJumboPct, "1");
    });
  });
  test("Custom Test Cases for txtJumboPct", () => {
    // START_USER_CODE-USER_txtJumboPct_TEST
    // END_USER_CODE-USER_txtJumboPct_TEST
  });
  test("txtKernelsREL(Textbox Widget) Test Cases", async () => {
    const txtKernelsREL = screen.getByTestId("txtKernelsREL");
    expect(txtKernelsREL.tagName).toBe("INPUT");
    expect(txtKernelsREL.type).toBe("text");
    expect(txtKernelsREL.classList).toContain("textboxWidgetClass");
    expect(txtKernelsREL.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtKernelsREL")
    );
    await act(async () => {
      userEvent.type(txtKernelsREL, "1");
    });
  });
  test("Custom Test Cases for txtKernelsREL", () => {
    // START_USER_CODE-USER_txtKernelsREL_TEST
    // END_USER_CODE-USER_txtKernelsREL_TEST
  });
  test("txtKernelsRPS(Textbox Widget) Test Cases", async () => {
    const txtKernelsRPS = screen.getByTestId("txtKernelsRPS");
    expect(txtKernelsRPS.tagName).toBe("INPUT");
    expect(txtKernelsRPS.type).toBe("text");
    expect(txtKernelsRPS.classList).toContain("textboxWidgetClass");
    expect(txtKernelsRPS.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtKernelsRPS")
    );
    await act(async () => {
      userEvent.type(txtKernelsRPS, "1");
    });
  });
  test("Custom Test Cases for txtKernelsRPS", () => {
    // START_USER_CODE-USER_txtKernelsRPS_TEST
    // END_USER_CODE-USER_txtKernelsRPS_TEST
  });
  test("txtKernelValueLb(Textbox Widget) Test Cases", async () => {
    const txtKernelValueLb = screen.getByTestId("txtKernelValueLb");
    expect(txtKernelValueLb.tagName).toBe("INPUT");
    expect(txtKernelValueLb.type).toBe("text");
    expect(txtKernelValueLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtKernelValueLb, "123");
    });
    expect(txtKernelValueLb.getAttribute("value")).toBe("");
    expect(txtKernelValueLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtKernelValueLb", () => {
    // START_USER_CODE-USER_txtKernelValueLb_TEST
    // END_USER_CODE-USER_txtKernelValueLb_TEST
  });
  test("txtKernelValueTon(Textbox Widget) Test Cases", async () => {
    const txtKernelValueTon = screen.getByTestId("txtKernelValueTon");
    expect(txtKernelValueTon.tagName).toBe("INPUT");
    expect(txtKernelValueTon.type).toBe("text");
    expect(txtKernelValueTon.classList).toContain("textboxWidgetClass");
    expect(txtKernelValueTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtKernelValueTon")
    );
    await act(async () => {
      userEvent.type(txtKernelValueTon, "123");
    });
    expect(txtKernelValueTon.getAttribute("value")).toBe("");
    expect(txtKernelValueTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtKernelValueTon", () => {
    // START_USER_CODE-USER_txtKernelValueTon_TEST
    // END_USER_CODE-USER_txtKernelValueTon_TEST
  });
  test("txtlblTotalDiscountsLb(Textbox Widget) Test Cases", async () => {
    const txtlblTotalDiscountsLb = screen.getByTestId("txtlblTotalDiscountsLb");
    expect(txtlblTotalDiscountsLb.tagName).toBe("INPUT");
    expect(txtlblTotalDiscountsLb.type).toBe("text");
    expect(txtlblTotalDiscountsLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtlblTotalDiscountsLb, "123");
    });
    expect(txtlblTotalDiscountsLb.getAttribute("value")).toBe("");
    expect(txtlblTotalDiscountsLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtlblTotalDiscountsLb", () => {
    // START_USER_CODE-USER_txtlblTotalDiscountsLb_TEST
    // END_USER_CODE-USER_txtlblTotalDiscountsLb_TEST
  });
  test("txtLSK(Textbox Widget) Test Cases", async () => {
    const txtLSK = screen.getByTestId("txtLSK");
    expect(txtLSK.tagName).toBe("INPUT");
    expect(txtLSK.type).toBe("text");
    expect(txtLSK.classList).toContain("textboxWidgetClass");
    expect(txtLSK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtLSK")
    );
    await act(async () => {
      userEvent.type(txtLSK, "123");
    });
    expect(txtLSK.getAttribute("value")).toBe("");
    expect(txtLSK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLSK", () => {
    // START_USER_CODE-USER_txtLSK_TEST
    // END_USER_CODE-USER_txtLSK_TEST
  });
  test("txtLSKGr(Textbox Widget) Test Cases", async () => {
    const txtLSKGr = screen.getByTestId("txtLSKGr");
    expect(txtLSKGr.tagName).toBe("INPUT");
    expect(txtLSKGr.type).toBe("text");
    expect(txtLSKGr.classList).toContain("textboxWidgetClass");
    expect(txtLSKGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtLSKGr")
    );
    await act(async () => {
      userEvent.type(txtLSKGr, "1");
    });
  });
  test("Custom Test Cases for txtLSKGr", () => {
    // START_USER_CODE-USER_txtLSKGr_TEST
    // END_USER_CODE-USER_txtLSKGr_TEST
  });
  test("txtLSKPct(Textbox Widget) Test Cases", async () => {
    const txtLSKPct = screen.getByTestId("txtLSKPct");
    expect(txtLSKPct.tagName).toBe("INPUT");
    expect(txtLSKPct.type).toBe("text");
    expect(txtLSKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLSKPct, "1");
    });
  });
  test("Custom Test Cases for txtLSKPct", () => {
    // START_USER_CODE-USER_txtLSKPct_TEST
    // END_USER_CODE-USER_txtLSKPct_TEST
  });
  test("txtLSKvc(Textbox Widget) Test Cases", async () => {
    const txtLSKvc = screen.getByTestId("txtLSKvc");
    expect(txtLSKvc.tagName).toBe("INPUT");
    expect(txtLSKvc.type).toBe("text");
    expect(txtLSKvc.classList).toContain("textboxWidgetClass");
    expect(txtLSKvc.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtLSKvc")
    );
    await act(async () => {
      userEvent.type(txtLSKvc, "123");
    });
    expect(txtLSKvc.getAttribute("value")).toBe("");
    expect(txtLSKvc.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLSKvc", () => {
    // START_USER_CODE-USER_txtLSKvc_TEST
    // END_USER_CODE-USER_txtLSKvc_TEST
  });
  test("txtMeterReading(Textbox Widget) Test Cases", async () => {
    const txtMeterReading = screen.getByTestId("txtMeterReading");
    expect(txtMeterReading.tagName).toBe("INPUT");
    expect(txtMeterReading.type).toBe("text");
    expect(txtMeterReading.classList).toContain("textboxWidgetClass");
    expect(txtMeterReading.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtMeterReading")
    );
    await act(async () => {
      userEvent.type(txtMeterReading, "1");
    });
  });
  test("Custom Test Cases for txtMeterReading", () => {
    // START_USER_CODE-USER_txtMeterReading_TEST
    // END_USER_CODE-USER_txtMeterReading_TEST
  });
  test("txtMoisture(Textbox Widget) Test Cases", async () => {
    const txtMoisture = screen.getByTestId("txtMoisture");
    expect(txtMoisture.tagName).toBe("INPUT");
    expect(txtMoisture.type).toBe("text");
    expect(txtMoisture.classList).toContain("textboxWidgetClass");
    expect(txtMoisture.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtMoisture")
    );
    await act(async () => {
      userEvent.type(txtMoisture, "1");
    });
  });
  test("Custom Test Cases for txtMoisture", () => {
    // START_USER_CODE-USER_txtMoisture_TEST
    // END_USER_CODE-USER_txtMoisture_TEST
  });
  test("txtMST(Textbox Widget) Test Cases", async () => {
    const txtMST = screen.getByTestId("txtMST");
    expect(txtMST.tagName).toBe("INPUT");
    expect(txtMST.type).toBe("text");
    expect(txtMST.classList).toContain("textboxWidgetClass");
    expect(txtMST.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtMST")
    );
    await act(async () => {
      userEvent.type(txtMST, "123");
    });
    expect(txtMST.getAttribute("value")).toBe("");
    expect(txtMST.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtMST", () => {
    // START_USER_CODE-USER_txtMST_TEST
    // END_USER_CODE-USER_txtMST_TEST
  });
  test("txtNetValPerTonExclSLK(Textbox Widget) Test Cases", async () => {
    const txtNetValPerTonExclSLK = screen.getByTestId("txtNetValPerTonExclSLK");
    expect(txtNetValPerTonExclSLK.tagName).toBe("INPUT");
    expect(txtNetValPerTonExclSLK.type).toBe("text");
    expect(txtNetValPerTonExclSLK.classList).toContain("textboxWidgetClass");
    expect(txtNetValPerTonExclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtNetValPerTonExclSLK")
    );
    await act(async () => {
      userEvent.type(txtNetValPerTonExclSLK, "123");
    });
    expect(txtNetValPerTonExclSLK.getAttribute("value")).toBe("");
    expect(txtNetValPerTonExclSLK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetValPerTonExclSLK", () => {
    // START_USER_CODE-USER_txtNetValPerTonExclSLK_TEST
    // END_USER_CODE-USER_txtNetValPerTonExclSLK_TEST
  });
  test("txtNetWeight(Textbox Widget) Test Cases", async () => {
    const txtNetWeight = screen.getByTestId("txtNetWeight");
    expect(txtNetWeight.tagName).toBe("INPUT");
    expect(txtNetWeight.type).toBe("text");
    expect(txtNetWeight.classList).toContain("textboxWidgetClass");
    expect(txtNetWeight.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtNetWeight")
    );
    await act(async () => {
      userEvent.type(txtNetWeight, "123");
    });
    expect(txtNetWeight.getAttribute("value")).toBe("");
    expect(txtNetWeight.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetWeight", () => {
    // START_USER_CODE-USER_txtNetWeight_TEST
    // END_USER_CODE-USER_txtNetWeight_TEST
  });
  test("txtNetWtExclLSK(Textbox Widget) Test Cases", async () => {
    const txtNetWtExclLSK = screen.getByTestId("txtNetWtExclLSK");
    expect(txtNetWtExclLSK.tagName).toBe("INPUT");
    expect(txtNetWtExclLSK.type).toBe("text");
    expect(txtNetWtExclLSK.classList).toContain("textboxWidgetClass");
    expect(txtNetWtExclLSK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtNetWtExclLSK")
    );
    await act(async () => {
      userEvent.type(txtNetWtExclLSK, "123");
    });
    expect(txtNetWtExclLSK.getAttribute("value")).toBe("");
    expect(txtNetWtExclLSK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetWtExclLSK", () => {
    // START_USER_CODE-USER_txtNetWtExclLSK_TEST
    // END_USER_CODE-USER_txtNetWtExclLSK_TEST
  });
  test("txtOI(Textbox Widget) Test Cases", async () => {
    const txtOI = screen.getByTestId("txtOI");
    expect(txtOI.tagName).toBe("INPUT");
    expect(txtOI.type).toBe("text");
    expect(txtOI.classList).toContain("textboxWidgetClass");
    expect(txtOI.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtOI")
    );
    await act(async () => {
      userEvent.type(txtOI, "123");
    });
    expect(txtOI.getAttribute("value")).toBe("");
    expect(txtOI.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOI", () => {
    // START_USER_CODE-USER_txtOI_TEST
    // END_USER_CODE-USER_txtOI_TEST
  });
  test("txtOK(Textbox Widget) Test Cases", async () => {
    const txtOK = screen.getByTestId("txtOK");
    expect(txtOK.tagName).toBe("INPUT");
    expect(txtOK.type).toBe("text");
    expect(txtOK.classList).toContain("textboxWidgetClass");
    expect(txtOK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtOK")
    );
    await act(async () => {
      userEvent.type(txtOK, "123");
    });
    expect(txtOK.getAttribute("value")).toBe("");
    expect(txtOK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOK", () => {
    // START_USER_CODE-USER_txtOK_TEST
    // END_USER_CODE-USER_txtOK_TEST
  });
  test("txtOtherForeignMaterials(Textbox Widget) Test Cases", async () => {
    const txtOtherForeignMaterials = screen.getByTestId(
      "txtOtherForeignMaterials"
    );
    expect(txtOtherForeignMaterials.tagName).toBe("INPUT");
    expect(txtOtherForeignMaterials.type).toBe("text");
    expect(txtOtherForeignMaterials.classList).toContain("textboxWidgetClass");
    expect(txtOtherForeignMaterials.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtOtherForeignMaterials")
    );
    await act(async () => {
      userEvent.type(txtOtherForeignMaterials, "1");
    });
  });
  test("Custom Test Cases for txtOtherForeignMaterials", () => {
    // START_USER_CODE-USER_txtOtherForeignMaterials_TEST
    // END_USER_CODE-USER_txtOtherForeignMaterials_TEST
  });
  test("txtOtherKernelsGr(Textbox Widget) Test Cases", async () => {
    const txtOtherKernelsGr = screen.getByTestId("txtOtherKernelsGr");
    expect(txtOtherKernelsGr.tagName).toBe("INPUT");
    expect(txtOtherKernelsGr.type).toBe("text");
    expect(txtOtherKernelsGr.classList).toContain("textboxWidgetClass");
    expect(txtOtherKernelsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtOtherKernelsGr")
    );
    await act(async () => {
      userEvent.type(txtOtherKernelsGr, "1");
    });
  });
  test("Custom Test Cases for txtOtherKernelsGr", () => {
    // START_USER_CODE-USER_txtOtherKernelsGr_TEST
    // END_USER_CODE-USER_txtOtherKernelsGr_TEST
  });
  test("txtOtherKernelsPct(Textbox Widget) Test Cases", async () => {
    const txtOtherKernelsPct = screen.getByTestId("txtOtherKernelsPct");
    expect(txtOtherKernelsPct.tagName).toBe("INPUT");
    expect(txtOtherKernelsPct.type).toBe("text");
    expect(txtOtherKernelsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOtherKernelsPct, "1");
    });
  });
  test("Custom Test Cases for txtOtherKernelsPct", () => {
    // START_USER_CODE-USER_txtOtherKernelsPct_TEST
    // END_USER_CODE-USER_txtOtherKernelsPct_TEST
  });
  test("txtProbingPattern(Textbox Widget) Test Cases", async () => {
    const txtProbingPattern = screen.getByTestId("txtProbingPattern");
    expect(txtProbingPattern.tagName).toBe("INPUT");
    expect(txtProbingPattern.type).toBe("text");
    expect(txtProbingPattern.classList).toContain("textboxWidgetClass");
    expect(txtProbingPattern.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtProbingPattern")
    );
    await act(async () => {
      userEvent.type(txtProbingPattern, "1");
    });
  });
  test("Custom Test Cases for txtProbingPattern", () => {
    // START_USER_CODE-USER_txtProbingPattern_TEST
    // END_USER_CODE-USER_txtProbingPattern_TEST
  });
  test("txtSC95(Textbox Widget) Test Cases", async () => {
    const txtSC95 = screen.getByTestId("txtSC95");
    expect(txtSC95.tagName).toBe("INPUT");
    expect(txtSC95.type).toBe("text");
    expect(txtSC95.classList).toContain("textboxWidgetClass");
    expect(txtSC95.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtSC95")
    );
    await act(async () => {
      userEvent.type(txtSC95, "1");
    });
  });
  test("Custom Test Cases for txtSC95", () => {
    // START_USER_CODE-USER_txtSC95_TEST
    // END_USER_CODE-USER_txtSC95_TEST
  });
  test("txtSeg(Textbox Widget) Test Cases", async () => {
    const txtSeg = screen.getByTestId("txtSeg");
    expect(txtSeg.tagName).toBe("INPUT");
    expect(txtSeg.type).toBe("text");
    expect(txtSeg.classList).toContain("textboxWidgetClass");
    expect(txtSeg.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtSeg")
    );
    await act(async () => {
      userEvent.type(txtSeg, "123");
    });
    expect(txtSeg.getAttribute("value")).toBe("");
    expect(txtSeg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeg", () => {
    // START_USER_CODE-USER_txtSeg_TEST
    // END_USER_CODE-USER_txtSeg_TEST
  });
  test("txtSellerName(Textbox Widget) Test Cases", async () => {
    const txtSellerName = screen.getByTestId("txtSellerName");
    expect(txtSellerName.tagName).toBe("INPUT");
    expect(txtSellerName.type).toBe("text");
    expect(txtSellerName.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSellerName, "1");
    });
  });
  test("Custom Test Cases for txtSellerName", () => {
    // START_USER_CODE-USER_txtSellerName_TEST
    // END_USER_CODE-USER_txtSellerName_TEST
  });
  test("txtSellerNumber(Textbox Widget) Test Cases", async () => {
    const txtSellerNumber = screen.getByTestId("txtSellerNumber");
    expect(txtSellerNumber.tagName).toBe("INPUT");
    expect(txtSellerNumber.type).toBe("text");
    expect(txtSellerNumber.classList).toContain("textboxWidgetClass");
    expect(txtSellerNumber.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtSellerNumber")
    );
    await act(async () => {
      userEvent.type(txtSellerNumber, "1");
    });
  });
  test("Custom Test Cases for txtSellerNumber", () => {
    // START_USER_CODE-USER_txtSellerNumber_TEST
    // END_USER_CODE-USER_txtSellerNumber_TEST
  });
  test("txtSettlementDate(Date Widget) Test Cases", async () => {
    const txtSettlementDate = screen.getByTestId("txtSettlementDate");
    expect(txtSettlementDate.tagName).toBe("INPUT");
    expect(txtSettlementDate.type).toBe("text");
    expect(txtSettlementDate.classList).toContain("datetimepicker-input");
    expect(
      txtSettlementDate.parentElement.previousElementSibling.textContent
    ).toEqual(t("Settlements:FV95Residue_txtSettlementDate"));
    await act(async () => {
      userEvent.click(txtSettlementDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtSettlementDate", () => {
    // START_USER_CODE-USER_txtSettlementDate_TEST
    // END_USER_CODE-USER_txtSettlementDate_TEST
  });
  test("txtSignOfInspector(Textbox Widget) Test Cases", async () => {
    const txtSignOfInspector = screen.getByTestId("txtSignOfInspector");
    expect(txtSignOfInspector.tagName).toBe("INPUT");
    expect(txtSignOfInspector.type).toBe("text");
    expect(txtSignOfInspector.classList).toContain("textboxWidgetClass");
    expect(txtSignOfInspector.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtSignOfInspector")
    );
    await act(async () => {
      userEvent.type(txtSignOfInspector, "1");
    });
  });
  test("Custom Test Cases for txtSignOfInspector", () => {
    // START_USER_CODE-USER_txtSignOfInspector_TEST
    // END_USER_CODE-USER_txtSignOfInspector_TEST
  });
  test("txtSMK(Textbox Widget) Test Cases", async () => {
    const txtSMK = screen.getByTestId("txtSMK");
    expect(txtSMK.tagName).toBe("INPUT");
    expect(txtSMK.type).toBe("text");
    expect(txtSMK.classList).toContain("textboxWidgetClass");
    expect(txtSMK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtSMK")
    );
    await act(async () => {
      userEvent.type(txtSMK, "123");
    });
    expect(txtSMK.getAttribute("value")).toBe("");
    expect(txtSMK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMK", () => {
    // START_USER_CODE-USER_txtSMK_TEST
    // END_USER_CODE-USER_txtSMK_TEST
  });
  test("txtSMK1Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK1Gr = screen.getByTestId("txtSMK1Gr");
    expect(txtSMK1Gr.tagName).toBe("INPUT");
    expect(txtSMK1Gr.type).toBe("text");
    expect(txtSMK1Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK1Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtSMK1Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK1Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK1Gr", () => {
    // START_USER_CODE-USER_txtSMK1Gr_TEST
    // END_USER_CODE-USER_txtSMK1Gr_TEST
  });
  test("txtSMK1Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK1Pct = screen.getByTestId("txtSMK1Pct");
    expect(txtSMK1Pct.tagName).toBe("INPUT");
    expect(txtSMK1Pct.type).toBe("text");
    expect(txtSMK1Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK1Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK1Pct", () => {
    // START_USER_CODE-USER_txtSMK1Pct_TEST
    // END_USER_CODE-USER_txtSMK1Pct_TEST
  });
  test("txtSMK2Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK2Gr = screen.getByTestId("txtSMK2Gr");
    expect(txtSMK2Gr.tagName).toBe("INPUT");
    expect(txtSMK2Gr.type).toBe("text");
    expect(txtSMK2Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK2Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtSMK2Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK2Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK2Gr", () => {
    // START_USER_CODE-USER_txtSMK2Gr_TEST
    // END_USER_CODE-USER_txtSMK2Gr_TEST
  });
  test("txtSMK2Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK2Pct = screen.getByTestId("txtSMK2Pct");
    expect(txtSMK2Pct.tagName).toBe("INPUT");
    expect(txtSMK2Pct.type).toBe("text");
    expect(txtSMK2Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK2Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK2Pct", () => {
    // START_USER_CODE-USER_txtSMK2Pct_TEST
    // END_USER_CODE-USER_txtSMK2Pct_TEST
  });
  test("txtSMK3Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK3Gr = screen.getByTestId("txtSMK3Gr");
    expect(txtSMK3Gr.tagName).toBe("INPUT");
    expect(txtSMK3Gr.type).toBe("text");
    expect(txtSMK3Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK3Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtSMK3Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK3Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK3Gr", () => {
    // START_USER_CODE-USER_txtSMK3Gr_TEST
    // END_USER_CODE-USER_txtSMK3Gr_TEST
  });
  test("txtSMK3Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK3Pct = screen.getByTestId("txtSMK3Pct");
    expect(txtSMK3Pct.tagName).toBe("INPUT");
    expect(txtSMK3Pct.type).toBe("text");
    expect(txtSMK3Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK3Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK3Pct", () => {
    // START_USER_CODE-USER_txtSMK3Pct_TEST
    // END_USER_CODE-USER_txtSMK3Pct_TEST
  });
  test("txtSMK4Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK4Gr = screen.getByTestId("txtSMK4Gr");
    expect(txtSMK4Gr.tagName).toBe("INPUT");
    expect(txtSMK4Gr.type).toBe("text");
    expect(txtSMK4Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK4Gr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtSMK4Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK4Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK4Gr", () => {
    // START_USER_CODE-USER_txtSMK4Gr_TEST
    // END_USER_CODE-USER_txtSMK4Gr_TEST
  });
  test("txtSMK4Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK4Pct = screen.getByTestId("txtSMK4Pct");
    expect(txtSMK4Pct.tagName).toBe("INPUT");
    expect(txtSMK4Pct.type).toBe("text");
    expect(txtSMK4Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK4Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK4Pct", () => {
    // START_USER_CODE-USER_txtSMK4Pct_TEST
    // END_USER_CODE-USER_txtSMK4Pct_TEST
  });
  test("txtSMKRS(Textbox Widget) Test Cases", async () => {
    const txtSMKRS = screen.getByTestId("txtSMKRS");
    expect(txtSMKRS.tagName).toBe("INPUT");
    expect(txtSMKRS.type).toBe("text");
    expect(txtSMKRS.classList).toContain("textboxWidgetClass");
    expect(txtSMKRS.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtSMKRS")
    );
    await act(async () => {
      userEvent.type(txtSMKRS, "123");
    });
    expect(txtSMKRS.getAttribute("value")).toBe("");
    expect(txtSMKRS.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMKRS", () => {
    // START_USER_CODE-USER_txtSMKRS_TEST
    // END_USER_CODE-USER_txtSMKRS_TEST
  });
  test("txtSMKRSGr(Textbox Widget) Test Cases", async () => {
    const txtSMKRSGr = screen.getByTestId("txtSMKRSGr");
    expect(txtSMKRSGr.tagName).toBe("INPUT");
    expect(txtSMKRSGr.type).toBe("text");
    expect(txtSMKRSGr.classList).toContain("textboxWidgetClass");
    expect(txtSMKRSGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtSMKRSGr")
    );
    await act(async () => {
      userEvent.type(txtSMKRSGr, "1");
    });
  });
  test("Custom Test Cases for txtSMKRSGr", () => {
    // START_USER_CODE-USER_txtSMKRSGr_TEST
    // END_USER_CODE-USER_txtSMKRSGr_TEST
  });
  test("txtSMKRSPct(Textbox Widget) Test Cases", async () => {
    const txtSMKRSPct = screen.getByTestId("txtSMKRSPct");
    expect(txtSMKRSPct.tagName).toBe("INPUT");
    expect(txtSMKRSPct.type).toBe("text");
    expect(txtSMKRSPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMKRSPct, "1");
    });
  });
  test("Custom Test Cases for txtSMKRSPct", () => {
    // START_USER_CODE-USER_txtSMKRSPct_TEST
    // END_USER_CODE-USER_txtSMKRSPct_TEST
  });
  test("txtSoundSplitsGr(Textbox Widget) Test Cases", async () => {
    const txtSoundSplitsGr = screen.getByTestId("txtSoundSplitsGr");
    expect(txtSoundSplitsGr.tagName).toBe("INPUT");
    expect(txtSoundSplitsGr.type).toBe("text");
    expect(txtSoundSplitsGr.classList).toContain("textboxWidgetClass");
    expect(txtSoundSplitsGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtSoundSplitsGr")
    );
    await act(async () => {
      userEvent.type(txtSoundSplitsGr, "1");
    });
  });
  test("Custom Test Cases for txtSoundSplitsGr", () => {
    // START_USER_CODE-USER_txtSoundSplitsGr_TEST
    // END_USER_CODE-USER_txtSoundSplitsGr_TEST
  });
  test("txtSoundSplitsPct(Textbox Widget) Test Cases", async () => {
    const txtSoundSplitsPct = screen.getByTestId("txtSoundSplitsPct");
    expect(txtSoundSplitsPct.tagName).toBe("INPUT");
    expect(txtSoundSplitsPct.type).toBe("text");
    expect(txtSoundSplitsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSoundSplitsPct, "1");
    });
  });
  test("Custom Test Cases for txtSoundSplitsPct", () => {
    // START_USER_CODE-USER_txtSoundSplitsPct_TEST
    // END_USER_CODE-USER_txtSoundSplitsPct_TEST
  });
  test("txtSS(Textbox Widget) Test Cases", async () => {
    const txtSS = screen.getByTestId("txtSS");
    expect(txtSS.tagName).toBe("INPUT");
    expect(txtSS.type).toBe("text");
    expect(txtSS.classList).toContain("textboxWidgetClass");
    expect(txtSS.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtSS")
    );
    await act(async () => {
      userEvent.type(txtSS, "123");
    });
    expect(txtSS.getAttribute("value")).toBe("");
    expect(txtSS.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSS", () => {
    // START_USER_CODE-USER_txtSS_TEST
    // END_USER_CODE-USER_txtSS_TEST
  });
  test("txtTotalDamageGr(Textbox Widget) Test Cases", async () => {
    const txtTotalDamageGr = screen.getByTestId("txtTotalDamageGr");
    expect(txtTotalDamageGr.tagName).toBe("INPUT");
    expect(txtTotalDamageGr.type).toBe("text");
    expect(txtTotalDamageGr.classList).toContain("textboxWidgetClass");
    expect(txtTotalDamageGr.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtTotalDamageGr")
    );
    await act(async () => {
      userEvent.type(txtTotalDamageGr, "1");
    });
  });
  test("Custom Test Cases for txtTotalDamageGr", () => {
    // START_USER_CODE-USER_txtTotalDamageGr_TEST
    // END_USER_CODE-USER_txtTotalDamageGr_TEST
  });
  test("txtTotalDamagePct(Textbox Widget) Test Cases", async () => {
    const txtTotalDamagePct = screen.getByTestId("txtTotalDamagePct");
    expect(txtTotalDamagePct.tagName).toBe("INPUT");
    expect(txtTotalDamagePct.type).toBe("text");
    expect(txtTotalDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtTotalDamagePct", () => {
    // START_USER_CODE-USER_txtTotalDamagePct_TEST
    // END_USER_CODE-USER_txtTotalDamagePct_TEST
  });
  test("txtTotalDiscountsTon(Textbox Widget) Test Cases", async () => {
    const txtTotalDiscountsTon = screen.getByTestId("txtTotalDiscountsTon");
    expect(txtTotalDiscountsTon.tagName).toBe("INPUT");
    expect(txtTotalDiscountsTon.type).toBe("text");
    expect(txtTotalDiscountsTon.classList).toContain("textboxWidgetClass");
    expect(txtTotalDiscountsTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtTotalDiscountsTon")
    );
    await act(async () => {
      userEvent.type(txtTotalDiscountsTon, "123");
    });
    expect(txtTotalDiscountsTon.getAttribute("value")).toBe("");
    expect(txtTotalDiscountsTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalDiscountsTon", () => {
    // START_USER_CODE-USER_txtTotalDiscountsTon_TEST
    // END_USER_CODE-USER_txtTotalDiscountsTon_TEST
  });
  test("txtTotalKernels(Textbox Widget) Test Cases", async () => {
    const txtTotalKernels = screen.getByTestId("txtTotalKernels");
    expect(txtTotalKernels.tagName).toBe("INPUT");
    expect(txtTotalKernels.type).toBe("text");
    expect(txtTotalKernels.classList).toContain("textboxWidgetClass");
    expect(txtTotalKernels.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtTotalKernels")
    );
    await act(async () => {
      userEvent.type(txtTotalKernels, "123");
    });
    expect(txtTotalKernels.getAttribute("value")).toBe("");
    expect(txtTotalKernels.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalKernels", () => {
    // START_USER_CODE-USER_txtTotalKernels_TEST
    // END_USER_CODE-USER_txtTotalKernels_TEST
  });
  test("txtTotalKernelsHulls(Textbox Widget) Test Cases", async () => {
    const txtTotalKernelsHulls = screen.getByTestId("txtTotalKernelsHulls");
    expect(txtTotalKernelsHulls.tagName).toBe("INPUT");
    expect(txtTotalKernelsHulls.type).toBe("text");
    expect(txtTotalKernelsHulls.classList).toContain("textboxWidgetClass");
    expect(txtTotalKernelsHulls.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtTotalKernelsHulls")
    );
    await act(async () => {
      userEvent.type(txtTotalKernelsHulls, "123");
    });
    expect(txtTotalKernelsHulls.getAttribute("value")).toBe("");
    expect(txtTotalKernelsHulls.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalKernelsHulls", () => {
    // START_USER_CODE-USER_txtTotalKernelsHulls_TEST
    // END_USER_CODE-USER_txtTotalKernelsHulls_TEST
  });
  test("txtTotalKrnls(Textbox Widget) Test Cases", async () => {
    const txtTotalKrnls = screen.getByTestId("txtTotalKrnls");
    expect(txtTotalKrnls.tagName).toBe("INPUT");
    expect(txtTotalKrnls.type).toBe("text");
    expect(txtTotalKrnls.classList).toContain("textboxWidgetClass");
    expect(txtTotalKrnls.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtTotalKrnls")
    );
    await act(async () => {
      userEvent.type(txtTotalKrnls, "123");
    });
    expect(txtTotalKrnls.getAttribute("value")).toBe("");
    expect(txtTotalKrnls.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalKrnls", () => {
    // START_USER_CODE-USER_txtTotalKrnls_TEST
    // END_USER_CODE-USER_txtTotalKrnls_TEST
  });
  test("txtTotalLb(Textbox Widget) Test Cases", async () => {
    const txtTotalLb = screen.getByTestId("txtTotalLb");
    expect(txtTotalLb.tagName).toBe("INPUT");
    expect(txtTotalLb.type).toBe("text");
    expect(txtTotalLb.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalLb, "123");
    });
    expect(txtTotalLb.getAttribute("value")).toBe("");
    expect(txtTotalLb.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLb", () => {
    // START_USER_CODE-USER_txtTotalLb_TEST
    // END_USER_CODE-USER_txtTotalLb_TEST
  });
  test("txtTotalLoadVal(Textbox Widget) Test Cases", async () => {
    const txtTotalLoadVal = screen.getByTestId("txtTotalLoadVal");
    expect(txtTotalLoadVal.tagName).toBe("INPUT");
    expect(txtTotalLoadVal.type).toBe("text");
    expect(txtTotalLoadVal.classList).toContain("textboxWidgetClass");
    expect(txtTotalLoadVal.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtTotalLoadVal")
    );
    await act(async () => {
      userEvent.type(txtTotalLoadVal, "123");
    });
    expect(txtTotalLoadVal.getAttribute("value")).toBe("");
    expect(txtTotalLoadVal.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalLoadVal", () => {
    // START_USER_CODE-USER_txtTotalLoadVal_TEST
    // END_USER_CODE-USER_txtTotalLoadVal_TEST
  });
  test("txtTotalSMK(Textbox Widget) Test Cases", async () => {
    const txtTotalSMK = screen.getByTestId("txtTotalSMK");
    expect(txtTotalSMK.tagName).toBe("INPUT");
    expect(txtTotalSMK.type).toBe("text");
    expect(txtTotalSMK.classList).toContain("textboxWidgetClass");
    expect(txtTotalSMK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtTotalSMK")
    );
    await act(async () => {
      userEvent.type(txtTotalSMK, "123");
    });
    expect(txtTotalSMK.getAttribute("value")).toBe("");
    expect(txtTotalSMK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalSMK", () => {
    // START_USER_CODE-USER_txtTotalSMK_TEST
    // END_USER_CODE-USER_txtTotalSMK_TEST
  });
  test("txtTotalTon(Textbox Widget) Test Cases", async () => {
    const txtTotalTon = screen.getByTestId("txtTotalTon");
    expect(txtTotalTon.tagName).toBe("INPUT");
    expect(txtTotalTon.type).toBe("text");
    expect(txtTotalTon.classList).toContain("textboxWidgetClass");
    expect(txtTotalTon.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtTotalTon")
    );
    await act(async () => {
      userEvent.type(txtTotalTon, "123");
    });
    expect(txtTotalTon.getAttribute("value")).toBe("");
    expect(txtTotalTon.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalTon", () => {
    // START_USER_CODE-USER_txtTotalTon_TEST
    // END_USER_CODE-USER_txtTotalTon_TEST
  });
  test("txtTotKrnlsHulls(Textbox Widget) Test Cases", async () => {
    const txtTotKrnlsHulls = screen.getByTestId("txtTotKrnlsHulls");
    expect(txtTotKrnlsHulls.tagName).toBe("INPUT");
    expect(txtTotKrnlsHulls.type).toBe("text");
    expect(txtTotKrnlsHulls.classList).toContain("textboxWidgetClass");
    expect(txtTotKrnlsHulls.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtTotKrnlsHulls")
    );
    await act(async () => {
      userEvent.type(txtTotKrnlsHulls, "123");
    });
    expect(txtTotKrnlsHulls.getAttribute("value")).toBe("");
    expect(txtTotKrnlsHulls.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotKrnlsHulls", () => {
    // START_USER_CODE-USER_txtTotKrnlsHulls_TEST
    // END_USER_CODE-USER_txtTotKrnlsHulls_TEST
  });
  test("txtValenciaCleanSampleWt(Textbox Widget) Test Cases", async () => {
    const txtValenciaCleanSampleWt = screen.getByTestId(
      "txtValenciaCleanSampleWt"
    );
    expect(txtValenciaCleanSampleWt.tagName).toBe("INPUT");
    expect(txtValenciaCleanSampleWt.type).toBe("text");
    expect(txtValenciaCleanSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtValenciaCleanSampleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtValenciaCleanSampleWt")
    );
    await act(async () => {
      userEvent.type(txtValenciaCleanSampleWt, "123");
    });
    expect(txtValenciaCleanSampleWt.getAttribute("value")).toBe("");
    expect(txtValenciaCleanSampleWt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValenciaCleanSampleWt", () => {
    // START_USER_CODE-USER_txtValenciaCleanSampleWt_TEST
    // END_USER_CODE-USER_txtValenciaCleanSampleWt_TEST
  });
  test("txtValPerPoundExclSLK(Textbox Widget) Test Cases", async () => {
    const txtValPerPoundExclSLK = screen.getByTestId("txtValPerPoundExclSLK");
    expect(txtValPerPoundExclSLK.tagName).toBe("INPUT");
    expect(txtValPerPoundExclSLK.type).toBe("text");
    expect(txtValPerPoundExclSLK.classList).toContain("textboxWidgetClass");
    expect(txtValPerPoundExclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtValPerPoundExclSLK")
    );
    await act(async () => {
      userEvent.type(txtValPerPoundExclSLK, "123");
    });
    expect(txtValPerPoundExclSLK.getAttribute("value")).toBe("");
    expect(txtValPerPoundExclSLK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValPerPoundExclSLK", () => {
    // START_USER_CODE-USER_txtValPerPoundExclSLK_TEST
    // END_USER_CODE-USER_txtValPerPoundExclSLK_TEST
  });
  test("txtValPerPoundInclSLK(Textbox Widget) Test Cases", async () => {
    const txtValPerPoundInclSLK = screen.getByTestId("txtValPerPoundInclSLK");
    expect(txtValPerPoundInclSLK.tagName).toBe("INPUT");
    expect(txtValPerPoundInclSLK.type).toBe("text");
    expect(txtValPerPoundInclSLK.classList).toContain("textboxWidgetClass");
    expect(txtValPerPoundInclSLK.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtValPerPoundInclSLK")
    );
    await act(async () => {
      userEvent.type(txtValPerPoundInclSLK, "123");
    });
    expect(txtValPerPoundInclSLK.getAttribute("value")).toBe("");
    expect(txtValPerPoundInclSLK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValPerPoundInclSLK", () => {
    // START_USER_CODE-USER_txtValPerPoundInclSLK_TEST
    // END_USER_CODE-USER_txtValPerPoundInclSLK_TEST
  });
  test("txtWtIncludingVehicle(Textbox Widget) Test Cases", async () => {
    const txtWtIncludingVehicle = screen.getByTestId("txtWtIncludingVehicle");
    expect(txtWtIncludingVehicle.tagName).toBe("INPUT");
    expect(txtWtIncludingVehicle.type).toBe("text");
    expect(txtWtIncludingVehicle.classList).toContain("textboxWidgetClass");
    expect(txtWtIncludingVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtWtIncludingVehicle")
    );
    await act(async () => {
      userEvent.type(txtWtIncludingVehicle, "123");
    });
    expect(txtWtIncludingVehicle.getAttribute("value")).toBe("");
    expect(txtWtIncludingVehicle.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWtIncludingVehicle", () => {
    // START_USER_CODE-USER_txtWtIncludingVehicle_TEST
    // END_USER_CODE-USER_txtWtIncludingVehicle_TEST
  });
  test("txtWtlessFM(Textbox Widget) Test Cases", async () => {
    const txtWtlessFM = screen.getByTestId("txtWtlessFM");
    expect(txtWtlessFM.tagName).toBe("INPUT");
    expect(txtWtlessFM.type).toBe("text");
    expect(txtWtlessFM.classList).toContain("textboxWidgetClass");
    expect(txtWtlessFM.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtWtlessFM")
    );
    await act(async () => {
      userEvent.type(txtWtlessFM, "123");
    });
    expect(txtWtlessFM.getAttribute("value")).toBe("");
    expect(txtWtlessFM.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWtlessFM", () => {
    // START_USER_CODE-USER_txtWtlessFM_TEST
    // END_USER_CODE-USER_txtWtlessFM_TEST
  });
  test("txtWtOfVehicle(Textbox Widget) Test Cases", async () => {
    const txtWtOfVehicle = screen.getByTestId("txtWtOfVehicle");
    expect(txtWtOfVehicle.tagName).toBe("INPUT");
    expect(txtWtOfVehicle.type).toBe("text");
    expect(txtWtOfVehicle.classList).toContain("textboxWidgetClass");
    expect(txtWtOfVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:FV95Residue_txtWtOfVehicle")
    );
    await act(async () => {
      userEvent.type(txtWtOfVehicle, "123");
    });
    expect(txtWtOfVehicle.getAttribute("value")).toBe("");
    expect(txtWtOfVehicle.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWtOfVehicle", () => {
    // START_USER_CODE-USER_txtWtOfVehicle_TEST
    // END_USER_CODE-USER_txtWtOfVehicle_TEST
  });
});
