/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  DateWidget,
  getData,
  setValue,
  setData
} from "../../shared/WindowImports";

import "./KCMOdataProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function Settlements_KCMOdataProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "KCMOdataProfile",
    windowName: "KCMOdataProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.KCMOdataProfile",
    // START_USER_CODE-USER_KCMOdataProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "KCMO Transmitted data Profile",
      scrCode: "PN1060L",
    },
    // END_USER_CODE-USER_KCMOdataProfile_PROPERTIES
    buttonwidget5: {
      name: "buttonwidget5",
      type: "ButtonWidget",
      parent: "commandcontainerwidget4",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_buttonwidget5_PROPERTIES

      // END_USER_CODE-USER_buttonwidget5_PROPERTIES
    },
    groupboxwidget1: {
      name: "groupboxwidget1",
      type: "GroupBoxWidget",
      parent: "grpbxKCMOdataProfile",
      Label: "groupboxwidget1",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_groupboxwidget1_PROPERTIES

      // END_USER_CODE-USER_groupboxwidget1_PROPERTIES
    },
    grpbxGrades: {
      name: "grpbxGrades",
      type: "GroupBoxWidget",
      parent: "grpbxKCMOdataProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxGrades_PROPERTIES

      // END_USER_CODE-USER_grpbxGrades_PROPERTIES
    },
    grpbxValues: {
      name: "grpbxValues",
      type: "GroupBoxWidget",
      parent: "grpbxKCMOdataProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxValues_PROPERTIES

      // END_USER_CODE-USER_grpbxValues_PROPERTIES
    },
    lblGrades: {
      name: "lblGrades",
      type: "LabelWidget",
      parent: "grpbxGrades",
      Label: "Grades :",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGrades_PROPERTIES

      // END_USER_CODE-USER_lblGrades_PROPERTIES
    },
    lblPct1: {
      name: "lblPct1",
      type: "LabelWidget",
      parent: "grpbxGrades",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct1_PROPERTIES

      // END_USER_CODE-USER_lblPct1_PROPERTIES
    },
    lblPct2: {
      name: "lblPct2",
      type: "LabelWidget",
      parent: "grpbxGrades",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct2_PROPERTIES

      // END_USER_CODE-USER_lblPct2_PROPERTIES
    },
    lblValues: {
      name: "lblValues",
      type: "LabelWidget",
      parent: "grpbxValues",
      Label: "Values:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValues_PROPERTIES

      // END_USER_CODE-USER_lblValues_PROPERTIES
    },
    txt1007Num: {
      name: "txt1007Num",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "1007 #:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007Num_PROPERTIES

      // END_USER_CODE-USER_txt1007Num_PROPERTIES
    },
    txtAuthCode: {
      name: "txtAuthCode",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "Authentication Code:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAuthCode_PROPERTIES

      // END_USER_CODE-USER_txtAuthCode_PROPERTIES
    },
    txtConcealedRMD: {
      name: "txtConcealedRMD",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Concealed RMD:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConcealedRMD_PROPERTIES

      // END_USER_CODE-USER_txtConcealedRMD_PROPERTIES
    },
    txtCountyID: {
      name: "txtCountyID",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "County ID:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCountyID_PROPERTIES

      // END_USER_CODE-USER_txtCountyID_PROPERTIES
    },
    txtCrkOrBrkShells: {
      name: "txtCrkOrBrkShells",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Crk, or Brk Shells:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCrkOrBrkShells_PROPERTIES

      // END_USER_CODE-USER_txtCrkOrBrkShells_PROPERTIES
    },
    txtDamage: {
      name: "txtDamage",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Damage:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamage_PROPERTIES

      // END_USER_CODE-USER_txtDamage_PROPERTIES
    },
    txtDateTransmitted: {
      name: "txtDateTransmitted",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "Date Transmitted:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 22 },
      ofTypeDomain: "d_string",
      // START_USER_CODE-USER_txtDateTransmitted_PROPERTIES

      // END_USER_CODE-USER_txtDateTransmitted_PROPERTIES
    },
    txtDiscoloredShells: {
      name: "txtDiscoloredShells",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Discolored Shells:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDiscoloredShells_PROPERTIES

      // END_USER_CODE-USER_txtDiscoloredShells_PROPERTIES
    },
    txtELK: {
      name: "txtELK",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "ELK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELK_PROPERTIES

      // END_USER_CODE-USER_txtELK_PROPERTIES
    },
    txtFancy: {
      name: "txtFancy",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Fancy:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFancy_PROPERTIES

      // END_USER_CODE-USER_txtFancy_PROPERTIES
    },
    txtFarmID: {
      name: "txtFarmID",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "Farm ID:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmID_PROPERTIES

      // END_USER_CODE-USER_txtFarmID_PROPERTIES
    },
    txtFarmSuffixNum: {
      name: "txtFarmSuffixNum",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "Farm Suffix #:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmSuffixNum_PROPERTIES

      // END_USER_CODE-USER_txtFarmSuffixNum_PROPERTIES
    },
    txtFederalBuyingPoint: {
      name: "txtFederalBuyingPoint",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "Federal Buying Point:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFederalBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtFederalBuyingPoint_PROPERTIES
    },
    txtFileNum: {
      name: "txtFileNum",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "File #:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFileNum_PROPERTIES

      // END_USER_CODE-USER_txtFileNum_PROPERTIES
    },
    txtFlavusDiscount: {
      name: "txtFlavusDiscount",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Flavus Discount:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFlavusDiscount_PROPERTIES

      // END_USER_CODE-USER_txtFlavusDiscount_PROPERTIES
    },
    txtForeignMaterial: {
      name: "txtForeignMaterial",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Foreign Material:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtForeignMaterial_PROPERTIES

      // END_USER_CODE-USER_txtForeignMaterial_PROPERTIES
    },
    txtFreezeDamage: {
      name: "txtFreezeDamage",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Freeze Damage:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreezeDamage_PROPERTIES

      // END_USER_CODE-USER_txtFreezeDamage_PROPERTIES
    },
    txtGMAAmount: {
      name: "txtGMAAmount",
      type: "TextBoxWidget",
      parent: "grpbxValues",
      Label: "GMA Amount:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGMAAmount_PROPERTIES

      // END_USER_CODE-USER_txtGMAAmount_PROPERTIES
    },
    txtHandlerNum1: {
      name: "txtHandlerNum1",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "Handler #:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHandlerNum1_PROPERTIES

      // END_USER_CODE-USER_txtHandlerNum1_PROPERTIES
    },
    txtHandlerNum2: {
      name: "txtHandlerNum2",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHandlerNum2_PROPERTIES

      // END_USER_CODE-USER_txtHandlerNum2_PROPERTIES
    },
    txtHandlerNum3: {
      name: "txtHandlerNum3",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHandlerNum3_PROPERTIES

      // END_USER_CODE-USER_txtHandlerNum3_PROPERTIES
    },
    txtHulls: {
      name: "txtHulls",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Hulls:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHulls_PROPERTIES

      // END_USER_CODE-USER_txtHulls_PROPERTIES
    },
    txtInspectionDate: {
      name: "txtInspectionDate",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "Inspection Date:",
      ColSpan: "4",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_string",
      // START_USER_CODE-USER_txtInspectionDate_PROPERTIES

      // END_USER_CODE-USER_txtInspectionDate_PROPERTIES
    },
    txtKernelValue: {
      name: "txtKernelValue",
      type: "TextBoxWidget",
      parent: "grpbxValues",
      Label: "Kernel Value:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernelValue_PROPERTIES

      // END_USER_CODE-USER_txtKernelValue_PROPERTIES
    },
    txtLocation: {
      name: "txtLocation",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "Location:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocation_PROPERTIES

      // END_USER_CODE-USER_txtLocation_PROPERTIES
    },
    txtLSK: {
      name: "txtLSK",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "LSK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSK_PROPERTIES

      // END_USER_CODE-USER_txtLSK_PROPERTIES
    },
    txtMoisture: {
      name: "txtMoisture",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Moisture:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMoisture_PROPERTIES

      // END_USER_CODE-USER_txtMoisture_PROPERTIES
    },
    txtNetValue: {
      name: "txtNetValue",
      type: "TextBoxWidget",
      parent: "grpbxValues",
      Label: "Net Value:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetValue_PROPERTIES

      // END_USER_CODE-USER_txtNetValue_PROPERTIES
    },
    txtNetWt: {
      name: "txtNetWt",
      type: "TextBoxWidget",
      parent: "grpbxValues",
      Label: "Net WT:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWt_PROPERTIES

      // END_USER_CODE-USER_txtNetWt_PROPERTIES
    },
    txtOtherKernels: {
      name: "txtOtherKernels",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Other Kernels:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherKernels_PROPERTIES

      // END_USER_CODE-USER_txtOtherKernels_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "Peanut Type:",
      ColSpan: "4",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtPurchaseWt: {
      name: "txtPurchaseWt",
      type: "TextBoxWidget",
      parent: "grpbxValues",
      Label: "Purchase WT:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPurchaseWt_PROPERTIES

      // END_USER_CODE-USER_txtPurchaseWt_PROPERTIES
    },
    txtSC95Num: {
      name: "txtSC95Num",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "SC95 #:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95Num_PROPERTIES

      // END_USER_CODE-USER_txtSC95Num_PROPERTIES
    },
    txtSegType: {
      name: "txtSegType",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "Seg Type:",
      ColSpan: "4",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegType_PROPERTIES

      // END_USER_CODE-USER_txtSegType_PROPERTIES
    },
    txtSegValue: {
      name: "txtSegValue",
      type: "TextBoxWidget",
      parent: "grpbxValues",
      Label: "Seg Value:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegValue_PROPERTIES

      // END_USER_CODE-USER_txtSegValue_PROPERTIES
    },
    txtSeperseded1007Num: {
      name: "txtSeperseded1007Num",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "Superseded 1007 #:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeperseded1007Num_PROPERTIES

      // END_USER_CODE-USER_txtSeperseded1007Num_PROPERTIES
    },
    txtSMK: {
      name: "txtSMK",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "SMK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK_PROPERTIES

      // END_USER_CODE-USER_txtSMK_PROPERTIES
    },
    txtSoundSplits: {
      name: "txtSoundSplits",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Sound Splits:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSoundSplits_PROPERTIES

      // END_USER_CODE-USER_txtSoundSplits_PROPERTIES
    },
    txtStateID: {
      name: "txtStateID",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "State ID:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStateID_PROPERTIES

      // END_USER_CODE-USER_txtStateID_PROPERTIES
    },
    txtStorageWt: {
      name: "txtStorageWt",
      type: "TextBoxWidget",
      parent: "grpbxValues",
      Label: "Storage WT:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStorageWt_PROPERTIES

      // END_USER_CODE-USER_txtStorageWt_PROPERTIES
    },
    txtSupportPct: {
      name: "txtSupportPct",
      type: "TextBoxWidget",
      parent: "grpbxValues",
      Label: "Support %:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSupportPct_PROPERTIES

      // END_USER_CODE-USER_txtSupportPct_PROPERTIES
    },
    txtTareWt: {
      name: "txtTareWt",
      type: "TextBoxWidget",
      parent: "grpbxValues",
      Label: "Tare WT:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTareWt_PROPERTIES

      // END_USER_CODE-USER_txtTareWt_PROPERTIES
    },
    txtTotalKernelsAndHulls: {
      name: "txtTotalKernelsAndHulls",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Total Kernels a & Hulls:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernelsAndHulls_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernelsAndHulls_PROPERTIES
    },
    txtTotalSMKandSS: {
      name: "txtTotalSMKandSS",
      type: "TextBoxWidget",
      parent: "grpbxGrades",
      Label: "Total SMK & SS:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSMKandSS_PROPERTIES

      // END_USER_CODE-USER_txtTotalSMKandSS_PROPERTIES
    },
    txtVehicleWt: {
      name: "txtVehicleWt",
      type: "TextBoxWidget",
      parent: "grpbxValues",
      Label: "Vehicle WT:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVehicleWt_PROPERTIES

      // END_USER_CODE-USER_txtVehicleWt_PROPERTIES
    },
    txtVoidReasonCode: {
      name: "txtVoidReasonCode",
      type: "TextBoxWidget",
      parent: "groupboxwidget1",
      Label: "Void Reason Code:",
      ColSpan: "4",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVoidReasonCode_PROPERTIES

      // END_USER_CODE-USER_txtVoidReasonCode_PROPERTIES
    },
    grpbxKCMOdataProfile: {
      name: "grpbxKCMOdataProfile",
      type: "GroupBoxWidget",
      parent: "KCMOdataProfile",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxKCMOdataProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxKCMOdataProfile_PROPERTIES
    },
    commandcontainerwidget4: {
      name: "commandcontainerwidget4",
      type: "CommandContainerWidget",
      parent: "KCMOdataProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_commandcontainerwidget4_PROPERTIES

      // END_USER_CODE-USER_commandcontainerwidget4_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS


    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    formload()
  }, [getData(thisObj, "frmKCMOdataProfileFomLoadData")]);

  const formload = () => {
    let data = getData(thisObj, "frmKCMOdataProfileFomLoadData")
    let data1 = getData(thisObj, "KCMOdataProfileFomLoadData");
    if (data !== undefined && data !== null && data !== "") {
      if (data1 == '>>>All Buying Points<<<')
        data1 = ''
      setValue(thisObj, 'txtLocation', data1);//2- "strFieldName": "BUY_PT_ID"
    }
    if (data !== undefined && data !== null && data !== "") {
      setValue(thisObj, 'txtFileNum', data[0].kcmo_records[46].strFieldData);//46- "strFieldName": "FILENUMBER"
      setValue(thisObj, 'txtFederalBuyingPoint', data[0].kcmo_records[8].strFieldData);//8- "strFieldName": "FED_BUY_PT_ID";
      setValue(thisObj, 'txt1007Num', data[0].kcmo_records[4].strFieldData);//4-  "strFieldName": "SETTLEMENT_NUM"
      setValue(thisObj, 'txtDateTransmitted', data[0].kcmo_records[45].strFieldData);//45- "strFieldName": "KC_TRANS_DATE"
      setValue(thisObj, 'txtSC95Num', data[0].kcmo_records[3].strFieldData);//3-  "strFieldName": "INSP_NUM"
      setValue(thisObj, 'txtInspectionDate', data[0].kcmo_records[32].strFieldData);//32-  "strFieldName": "INSP_DATE_TIME"
      setValue(thisObj, 'txtVoidReasonCode', data[0].kcmo_records[11].strFieldData);//11- "strFieldName": "VOID_REASON_CODE"
      setValue(thisObj, 'txtStateID', data[0].kcmo_records[5].strFieldData);//5-  "strFieldName": "STATE_ID"
      setValue(thisObj, 'txtHandlerNum1', data[0].kcmo_records[9].strFieldData);//9- "strFieldName": "CCC_HANDLER_NUM"
      setValue(thisObj, 'txtHandlerNum2', data[0].kcmo_records[10].strFieldData);//10-  "strFieldName": "HANDLER_LOC_NUM"
      setValue(thisObj, 'txtHandlerNum3', data[0].kcmo_records[49].strFieldData);//49-  "strFieldName": "HANDLER_LOC_NUM_2"
      setValue(thisObj, 'txtPeanutType', data[0].kcmo_records[12].strFieldData);//12- "strFieldName": "PNUT_TYPE_ID"
      setValue(thisObj, 'txtSegType', data[0].kcmo_records[14].strFieldData);//14- "strFieldName": "SEG_TYPE"
      setValue(thisObj, 'txtCountyID', data[0].kcmo_records[6].strFieldData);//6-  "strFieldName": "COUNTY_ID"
      setValue(thisObj, 'txtFarmID', data[0].kcmo_records[7].strFieldData);//7- "strFieldName": "FARM_ID"
      setValue(thisObj, 'txtSeperseded1007Num', data[0].kcmo_records[39].strFieldData);//39-  "strFieldName": "SUPERSEDED_SETTLE"
      setValue(thisObj, 'txtAuthCode', data[0].kcmo_records[48].strFieldData);//48-  "strFieldName": "AUTHENTICATION_CODE"
      setValue(thisObj, 'txtFarmSuffixNum', data[0].kcmo_records[50].strFieldData);//50-  "strFieldName": "FARM_SUFFIX"
      setValue(thisObj, 'txtSMK', data[0].kcmo_records[15].strFieldData);//15-  "strFieldName": "SMK_PCT"
      setValue(thisObj, 'txtSoundSplits', data[0].kcmo_records[16].strFieldData);//16-  "strFieldName": "SS_PCT"
      setValue(thisObj, 'txtTotalSMKandSS', data[0].kcmo_records[17].strFieldData);//17- "strFieldName": "SMK_SS_PCT"
      setValue(thisObj, 'txtOtherKernels', data[0].kcmo_records[18].strFieldData);//18-  "strFieldName": "OK_PCT"
      setValue(thisObj, 'txtDamage', data[0].kcmo_records[19].strFieldData);//19- "strFieldName": "DAM_PCT"
      setValue(thisObj, 'txtHulls', data[0].kcmo_records[20].strFieldData);//20-  "strFieldName": "HULL_PCT"
      setValue(thisObj, 'txtTotalKernelsAndHulls', data[0].kcmo_records[23].strFieldData);//23-  "strFieldName": "TKH_PCT"
      setValue(thisObj, 'txtForeignMaterial', data[0].kcmo_records[24].strFieldData);//24- "strFieldName": "FM_PCT"
      setValue(thisObj, 'txtFlavusDiscount', data[0].kcmo_records[31].strFieldData);//31-  "strFieldName": "FLAVUS_DISCOUNT"
      setValue(thisObj, 'txtLSK', data[0].kcmo_records[25].strFieldData);//25- "strFieldName": "LSK_PCT"
      setValue(thisObj, 'txtMoisture', data[0].kcmo_records[26].strFieldData);//26- "strFieldName": "MOIST_PCT"
      setValue(thisObj, 'txtFancy', data[0].kcmo_records[27].strFieldData);//27-  "strFieldName": "FAN_PCT"
      setValue(thisObj, 'txtELK', data[0].kcmo_records[28].strFieldData);//28- "strFieldName": "ELK_PCT"
      setValue(thisObj, 'txtConcealedRMD', data[0].kcmo_records[21].strFieldData);//21-  "strFieldName": "CNCL_RMD_PCT"
      setValue(thisObj, 'txtFreezeDamage', data[0].kcmo_records[22].strFieldData);//22-  "strFieldName": "FREZ_DAM_PCT"
      setValue(thisObj, 'txtDiscoloredShells', data[0].kcmo_records[29].strFieldData);//29-  "strFieldName": "DCOL_SHEL_PCT"
      setValue(thisObj, 'txtCrkOrBrkShells', data[0].kcmo_records[30].strFieldData);//30- "strFieldName": "CR_BR_PCT"
      setValue(thisObj, 'txtTareWt', data[0].kcmo_records[35].strFieldData);//35- "strFieldName": "TARE_WT"
      setValue(thisObj, 'txtVehicleWt', data[0].kcmo_records[34].strFieldData);//34-  "strFieldName": "VEHICLE_WT"
      setValue(thisObj, 'txtNetWt', data[0].kcmo_records[36].strFieldData);//36- "strFieldName": "NET_WT"
      setValue(thisObj, 'txtStorageWt', data[0].kcmo_records[40].strFieldData);//40- "strFieldName": "STORAGE_WT"
      setValue(thisObj, 'txtPurchaseWt', data[0].kcmo_records[41].strFieldData);//41- "strFieldName": "PURCHASE_WT"
      setValue(thisObj, 'txtKernelValue', data[0].kcmo_records[37].strFieldData);//37- "strFieldName": "KERN_VALUE"
      setValue(thisObj, 'txtNetValue', data[0].kcmo_records[47].strFieldData);//47- "strFieldName": "NET_VALUE"
      setValue(thisObj, 'txtSupportPct', data[0].kcmo_records[42].strFieldData);//42-  "strFieldName": "SUPPORT_PCT"
      setValue(thisObj, 'txtSegValue', data[0].kcmo_records[43].strFieldData);//43-  "strFieldName": "VALUE_OF_SEG"
      setValue(thisObj, 'txtGMAAmount', data[0].kcmo_records[44].strFieldData);//44- "strFieldName": "TOTAL_GMA"

    }
  }
  const onbuttonwidget5Click = () => {
    try {
      setData(thisObj, 'screen_reload_print', true)
      document.getElementById("Settlements_KCMOdataProfilePopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError) {
        alert(err.message);
      } else {
        alert("Exception in PreSubmit Event code for widget:buttonwidget5 event:Click");
      }
    }
  };
  thisObj.onbuttonwidget5Click = onbuttonwidget5Click;
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_KCMOdataProfile*/}

              {/* END_USER_CODE-USER_BEFORE_KCMOdataProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxKCMOdataProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxKCMOdataProfile*/}

              <GroupBoxWidget
                conf={state.grpbxKCMOdataProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_groupboxwidget1*/}

                {/* END_USER_CODE-USER_BEFORE_groupboxwidget1*/}

                <GroupBoxWidget conf={state.groupboxwidget1} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtFileNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFileNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFileNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFileNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtFileNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDateTransmitted*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDateTransmitted*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDateTransmitted}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDateTransmitted*/}

                  {/* END_USER_CODE-USER_AFTER_txtDateTransmitted*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLocation*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLocation}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLocation*/}

                  {/* END_USER_CODE-USER_AFTER_txtLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFederalBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFederalBuyingPoint*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFederalBuyingPoint}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFederalBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_txtFederalBuyingPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_txt1007Num*/}

                  {/* END_USER_CODE-USER_BEFORE_txt1007Num*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txt1007Num}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txt1007Num*/}

                  {/* END_USER_CODE-USER_AFTER_txt1007Num*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSeperseded1007Num*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSeperseded1007Num*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSeperseded1007Num}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSeperseded1007Num*/}

                  {/* END_USER_CODE-USER_AFTER_txtSeperseded1007Num*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSC95Num*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSC95Num*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSC95Num}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSC95Num*/}

                  {/* END_USER_CODE-USER_AFTER_txtSC95Num*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAuthCode*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAuthCode*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAuthCode}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAuthCode*/}

                  {/* END_USER_CODE-USER_AFTER_txtAuthCode*/}
                  {/* START_USER_CODE-USER_BEFORE_txtInspectionDate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtInspectionDate*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtInspectionDate}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtInspectionDate*/}

                  {/* END_USER_CODE-USER_AFTER_txtInspectionDate*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVoidReasonCode*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVoidReasonCode*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVoidReasonCode}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVoidReasonCode*/}

                  {/* END_USER_CODE-USER_AFTER_txtVoidReasonCode*/}
                  {/* START_USER_CODE-USER_BEFORE_txtStateID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtStateID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtStateID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtStateID*/}

                  {/* END_USER_CODE-USER_AFTER_txtStateID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCountyID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCountyID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCountyID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCountyID*/}

                  {/* END_USER_CODE-USER_AFTER_txtCountyID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFarmID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFarmID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFarmID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFarmID*/}

                  {/* END_USER_CODE-USER_AFTER_txtFarmID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFarmSuffixNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFarmSuffixNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFarmSuffixNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFarmSuffixNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtFarmSuffixNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHandlerNum1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHandlerNum1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHandlerNum1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHandlerNum1*/}

                  {/* END_USER_CODE-USER_AFTER_txtHandlerNum1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHandlerNum2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHandlerNum2*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHandlerNum2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHandlerNum2*/}

                  {/* END_USER_CODE-USER_AFTER_txtHandlerNum2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHandlerNum3*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHandlerNum3*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHandlerNum3}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHandlerNum3*/}

                  {/* END_USER_CODE-USER_AFTER_txtHandlerNum3*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPeanutType*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPeanutType*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPeanutType}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPeanutType*/}

                  {/* END_USER_CODE-USER_AFTER_txtPeanutType*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSegType*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSegType*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSegType}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSegType*/}

                  {/* END_USER_CODE-USER_AFTER_txtSegType*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_groupboxwidget1*/}

                {/* END_USER_CODE-USER_AFTER_groupboxwidget1*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxGrades*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxGrades*/}

                <GroupBoxWidget conf={state.grpbxGrades} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblGrades*/}

                  {/* END_USER_CODE-USER_BEFORE_lblGrades*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblGrades}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblGrades*/}

                  {/* END_USER_CODE-USER_AFTER_lblGrades*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct1*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct1*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct1}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct1*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct1*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct2*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct2*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct2}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct2*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSMK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMK*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLSK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSK*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSoundSplits*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSoundSplits*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSoundSplits}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSoundSplits*/}

                  {/* END_USER_CODE-USER_AFTER_txtSoundSplits*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMoisture*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMoisture*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMoisture}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMoisture*/}

                  {/* END_USER_CODE-USER_AFTER_txtMoisture*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalSMKandSS*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalSMKandSS*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalSMKandSS}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalSMKandSS*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalSMKandSS*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFancy*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFancy*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFancy}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFancy*/}

                  {/* END_USER_CODE-USER_AFTER_txtFancy*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOtherKernels*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOtherKernels*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOtherKernels}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOtherKernels*/}

                  {/* END_USER_CODE-USER_AFTER_txtOtherKernels*/}
                  {/* START_USER_CODE-USER_BEFORE_txtELK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtELK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtELK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtELK*/}

                  {/* END_USER_CODE-USER_AFTER_txtELK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDamage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDamage*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDamage}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDamage*/}

                  {/* END_USER_CODE-USER_AFTER_txtDamage*/}
                  {/* START_USER_CODE-USER_BEFORE_txtConcealedRMD*/}

                  {/* END_USER_CODE-USER_BEFORE_txtConcealedRMD*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtConcealedRMD}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtConcealedRMD*/}

                  {/* END_USER_CODE-USER_AFTER_txtConcealedRMD*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHulls*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHulls*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHulls}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHulls*/}

                  {/* END_USER_CODE-USER_AFTER_txtHulls*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFreezeDamage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFreezeDamage*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFreezeDamage}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFreezeDamage*/}

                  {/* END_USER_CODE-USER_AFTER_txtFreezeDamage*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalKernelsAndHulls*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalKernelsAndHulls*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalKernelsAndHulls}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalKernelsAndHulls*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalKernelsAndHulls*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDiscoloredShells*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDiscoloredShells*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDiscoloredShells}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDiscoloredShells*/}

                  {/* END_USER_CODE-USER_AFTER_txtDiscoloredShells*/}
                  {/* START_USER_CODE-USER_BEFORE_txtForeignMaterial*/}

                  {/* END_USER_CODE-USER_BEFORE_txtForeignMaterial*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtForeignMaterial}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtForeignMaterial*/}

                  {/* END_USER_CODE-USER_AFTER_txtForeignMaterial*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCrkOrBrkShells*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCrkOrBrkShells*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCrkOrBrkShells}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCrkOrBrkShells*/}

                  {/* END_USER_CODE-USER_AFTER_txtCrkOrBrkShells*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFlavusDiscount*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFlavusDiscount*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFlavusDiscount}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFlavusDiscount*/}

                  {/* END_USER_CODE-USER_AFTER_txtFlavusDiscount*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxGrades*/}

                {/* END_USER_CODE-USER_AFTER_grpbxGrades*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxValues*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxValues*/}

                <GroupBoxWidget conf={state.grpbxValues} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblValues*/}

                  {/* END_USER_CODE-USER_BEFORE_lblValues*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblValues}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblValues*/}

                  {/* END_USER_CODE-USER_AFTER_lblValues*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTareWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTareWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTareWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTareWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtTareWt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVehicleWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVehicleWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVehicleWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVehicleWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtVehicleWt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNetWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNetWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNetWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtNetWt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtStorageWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtStorageWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtStorageWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtStorageWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtStorageWt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPurchaseWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPurchaseWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPurchaseWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPurchaseWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtPurchaseWt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtKernelValue*/}

                  {/* END_USER_CODE-USER_BEFORE_txtKernelValue*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtKernelValue}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtKernelValue*/}

                  {/* END_USER_CODE-USER_AFTER_txtKernelValue*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNetValue*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNetValue*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetValue}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNetValue*/}

                  {/* END_USER_CODE-USER_AFTER_txtNetValue*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSupportPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSupportPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSupportPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSupportPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtSupportPct*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSegValue*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSegValue*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSegValue}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSegValue*/}

                  {/* END_USER_CODE-USER_AFTER_txtSegValue*/}
                  {/* START_USER_CODE-USER_BEFORE_txtGMAAmount*/}

                  {/* END_USER_CODE-USER_BEFORE_txtGMAAmount*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtGMAAmount}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtGMAAmount*/}

                  {/* END_USER_CODE-USER_AFTER_txtGMAAmount*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxValues*/}

                {/* END_USER_CODE-USER_AFTER_grpbxValues*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxKCMOdataProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxKCMOdataProfile*/}
              {/* START_USER_CODE-USER_BEFORE_commandcontainerwidget4*/}

              {/* END_USER_CODE-USER_BEFORE_commandcontainerwidget4*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.commandcontainerwidget4}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_buttonwidget5*/}

                {/* END_USER_CODE-USER_BEFORE_buttonwidget5*/}

                <ButtonWidget
                  conf={state.buttonwidget5}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_buttonwidget5*/}

                {/* END_USER_CODE-USER_AFTER_buttonwidget5*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_commandcontainerwidget4*/}

              {/* END_USER_CODE-USER_AFTER_commandcontainerwidget4*/}

              {/* START_USER_CODE-USER_AFTER_KCMOdataProfile*/}

              {/* END_USER_CODE-USER_AFTER_KCMOdataProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_KCMOdataProfile);
