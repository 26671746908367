/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_SpecFuncChgNum from "./SpecFuncChgNum";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SpecFuncChgNum Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_SpecFuncChgNum />);
    });
  });
  afterEach(cleanup);
  test("is SpecFuncChgNum Loads Successfully", () => {
    expect(screen.getByText("SpecFuncChgNum")).toBeInTheDocument;
  });
  test("Custom Test Cases for SpecFuncChgNum", () => {
    // START_USER_CODE-USER_SpecFuncChgNum_Custom_Test_Case
    // END_USER_CODE-USER_SpecFuncChgNum_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_SpecFuncChgNum />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOldInspection(Textbox Widget) Test Cases", async () => {
    const btnOldInspection = screen.getByTestId("btnOldInspection");
    expect(btnOldInspection.tagName).toBe("INPUT");
    expect(btnOldInspection.type).toBe("text");
    expect(btnOldInspection.classList).toContain("textboxWidgetClass");
    expect(btnOldInspection.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncChgNum_btnOldInspection"
      )
    );
    await act(async () => {
      userEvent.type(btnOldInspection, "1");
    });
  });
  test("Custom Test Cases for btnOldInspection", () => {
    // START_USER_CODE-USER_btnOldInspection_TEST
    // END_USER_CODE-USER_btnOldInspection_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("Chng1007VndrNum(GroupBox Widget) Test Cases", async () => {
    const Chng1007VndrNum = screen.getByTestId("Chng1007VndrNum");
    expect(Chng1007VndrNum.tagName).toBe("BUTTON");
    expect(Chng1007VndrNum.type).toBe("button");
    expect(Chng1007VndrNum.classList).toContain("btn");
  });
  test("Custom Test Cases for Chng1007VndrNum", () => {
    // START_USER_CODE-USER_Chng1007VndrNum_TEST
    // END_USER_CODE-USER_Chng1007VndrNum_TEST
  });
  test("grpbxChangeNumbers(GroupBox Widget) Test Cases", async () => {
    const grpbxChangeNumbers = screen.getByTestId("grpbxChangeNumbers");
    expect(grpbxChangeNumbers.tagName).toBe("BUTTON");
    expect(grpbxChangeNumbers.type).toBe("button");
    expect(grpbxChangeNumbers.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxChangeNumbers", () => {
    // START_USER_CODE-USER_grpbxChangeNumbers_TEST
    // END_USER_CODE-USER_grpbxChangeNumbers_TEST
  });
  test("grpbxChangeTransferNum(GroupBox Widget) Test Cases", async () => {
    const grpbxChangeTransferNum = screen.getByTestId("grpbxChangeTransferNum");
    expect(grpbxChangeTransferNum.tagName).toBe("BUTTON");
    expect(grpbxChangeTransferNum.type).toBe("button");
    expect(grpbxChangeTransferNum.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxChangeTransferNum", () => {
    // START_USER_CODE-USER_grpbxChangeTransferNum_TEST
    // END_USER_CODE-USER_grpbxChangeTransferNum_TEST
  });
  test("grpbxChngInsNum(GroupBox Widget) Test Cases", async () => {
    const grpbxChngInsNum = screen.getByTestId("grpbxChngInsNum");
    expect(grpbxChngInsNum.tagName).toBe("BUTTON");
    expect(grpbxChngInsNum.type).toBe("button");
    expect(grpbxChngInsNum.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxChngInsNum", () => {
    // START_USER_CODE-USER_grpbxChngInsNum_TEST
    // END_USER_CODE-USER_grpbxChngInsNum_TEST
  });
  test("grpbxChngSettle1007Num(GroupBox Widget) Test Cases", async () => {
    const grpbxChngSettle1007Num = screen.getByTestId("grpbxChngSettle1007Num");
    expect(grpbxChngSettle1007Num.tagName).toBe("BUTTON");
    expect(grpbxChngSettle1007Num.type).toBe("button");
    expect(grpbxChngSettle1007Num.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxChngSettle1007Num", () => {
    // START_USER_CODE-USER_grpbxChngSettle1007Num_TEST
    // END_USER_CODE-USER_grpbxChngSettle1007Num_TEST
  });
  test("grpbxChngWRNum(GroupBox Widget) Test Cases", async () => {
    const grpbxChngWRNum = screen.getByTestId("grpbxChngWRNum");
    expect(grpbxChngWRNum.tagName).toBe("BUTTON");
    expect(grpbxChngWRNum.type).toBe("button");
    expect(grpbxChngWRNum.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxChngWRNum", () => {
    // START_USER_CODE-USER_grpbxChngWRNum_TEST
    // END_USER_CODE-USER_grpbxChngWRNum_TEST
  });
  test("grpbxTransferNum(GroupBox Widget) Test Cases", async () => {
    const grpbxTransferNum = screen.getByTestId("grpbxTransferNum");
    expect(grpbxTransferNum.tagName).toBe("BUTTON");
    expect(grpbxTransferNum.type).toBe("button");
    expect(grpbxTransferNum.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTransferNum", () => {
    // START_USER_CODE-USER_grpbxTransferNum_TEST
    // END_USER_CODE-USER_grpbxTransferNum_TEST
  });
  test("grpbxTransferType(GroupBox Widget) Test Cases", async () => {
    const grpbxTransferType = screen.getByTestId("grpbxTransferType");
    expect(grpbxTransferType.tagName).toBe("BUTTON");
    expect(grpbxTransferType.type).toBe("button");
    expect(grpbxTransferType.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTransferType", () => {
    // START_USER_CODE-USER_grpbxTransferType_TEST
    // END_USER_CODE-USER_grpbxTransferType_TEST
  });
  test("lblChangeNumbers(Label Widget) Test Cases", async () => {
    const lblChangeNumbers = screen.getByTestId("lblChangeNumbers");
    expect(lblChangeNumbers.tagName).toBe("LABEL");
    expect(lblChangeNumbers.classList).toContain("form-label");
    expect(lblChangeNumbers.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_lblChangeNumbers")
    );
  });
  test("Custom Test Cases for lblChangeNumbers", () => {
    // START_USER_CODE-USER_lblChangeNumbers_TEST
    // END_USER_CODE-USER_lblChangeNumbers_TEST
  });
  test("lblChangeTransferNum(Label Widget) Test Cases", async () => {
    const lblChangeTransferNum = screen.getByTestId("lblChangeTransferNum");
    expect(lblChangeTransferNum.tagName).toBe("LABEL");
    expect(lblChangeTransferNum.classList).toContain("form-label");
    expect(lblChangeTransferNum.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncChgNum_lblChangeTransferNum"
      )
    );
  });
  test("Custom Test Cases for lblChangeTransferNum", () => {
    // START_USER_CODE-USER_lblChangeTransferNum_TEST
    // END_USER_CODE-USER_lblChangeTransferNum_TEST
  });
  test("lblChng1007VndrNum(Label Widget) Test Cases", async () => {
    const lblChng1007VndrNum = screen.getByTestId("lblChng1007VndrNum");
    expect(lblChng1007VndrNum.tagName).toBe("LABEL");
    expect(lblChng1007VndrNum.classList).toContain("form-label");
    expect(lblChng1007VndrNum.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_lblChng1007VndrNum")
    );
  });
  test("Custom Test Cases for lblChng1007VndrNum", () => {
    // START_USER_CODE-USER_lblChng1007VndrNum_TEST
    // END_USER_CODE-USER_lblChng1007VndrNum_TEST
  });
  test("lblChngInsNum(Label Widget) Test Cases", async () => {
    const lblChngInsNum = screen.getByTestId("lblChngInsNum");
    expect(lblChngInsNum.tagName).toBe("LABEL");
    expect(lblChngInsNum.classList).toContain("form-label");
    expect(lblChngInsNum.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_lblChngInsNum")
    );
  });
  test("Custom Test Cases for lblChngInsNum", () => {
    // START_USER_CODE-USER_lblChngInsNum_TEST
    // END_USER_CODE-USER_lblChngInsNum_TEST
  });
  test("lblChngSettle1007Num(Label Widget) Test Cases", async () => {
    const lblChngSettle1007Num = screen.getByTestId("lblChngSettle1007Num");
    expect(lblChngSettle1007Num.tagName).toBe("LABEL");
    expect(lblChngSettle1007Num.classList).toContain("form-label");
    expect(lblChngSettle1007Num.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncChgNum_lblChngSettle1007Num"
      )
    );
  });
  test("Custom Test Cases for lblChngSettle1007Num", () => {
    // START_USER_CODE-USER_lblChngSettle1007Num_TEST
    // END_USER_CODE-USER_lblChngSettle1007Num_TEST
  });
  test("lblChngWRNum(Label Widget) Test Cases", async () => {
    const lblChngWRNum = screen.getByTestId("lblChngWRNum");
    expect(lblChngWRNum.tagName).toBe("LABEL");
    expect(lblChngWRNum.classList).toContain("form-label");
    expect(lblChngWRNum.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_lblChngWRNum")
    );
  });
  test("Custom Test Cases for lblChngWRNum", () => {
    // START_USER_CODE-USER_lblChngWRNum_TEST
    // END_USER_CODE-USER_lblChngWRNum_TEST
  });
  test("txtBuyPt(Textbox Widget) Test Cases", async () => {
    const txtBuyPt = screen.getByTestId("txtBuyPt");
    expect(txtBuyPt.tagName).toBe("INPUT");
    expect(txtBuyPt.type).toBe("text");
    expect(txtBuyPt.classList).toContain("textboxWidgetClass");
    expect(txtBuyPt.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtBuyPt")
    );
    await act(async () => {
      userEvent.type(txtBuyPt, "1");
    });
  });
  test("Custom Test Cases for txtBuyPt", () => {
    // START_USER_CODE-USER_txtBuyPt_TEST
    // END_USER_CODE-USER_txtBuyPt_TEST
  });
  test("txtBuyPtCSN(Textbox Widget) Test Cases", async () => {
    const txtBuyPtCSN = screen.getByTestId("txtBuyPtCSN");
    expect(txtBuyPtCSN.tagName).toBe("INPUT");
    expect(txtBuyPtCSN.type).toBe("text");
    expect(txtBuyPtCSN.classList).toContain("textboxWidgetClass");
    expect(txtBuyPtCSN.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtBuyPtCSN")
    );
    await act(async () => {
      userEvent.type(txtBuyPtCSN, "1");
    });
  });
  test("Custom Test Cases for txtBuyPtCSN", () => {
    // START_USER_CODE-USER_txtBuyPtCSN_TEST
    // END_USER_CODE-USER_txtBuyPtCSN_TEST
  });
  test("txtBuyPtCVN(Textbox Widget) Test Cases", async () => {
    const txtBuyPtCVN = screen.getByTestId("txtBuyPtCVN");
    expect(txtBuyPtCVN.tagName).toBe("INPUT");
    expect(txtBuyPtCVN.type).toBe("text");
    expect(txtBuyPtCVN.classList).toContain("textboxWidgetClass");
    expect(txtBuyPtCVN.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtBuyPtCVN")
    );
    await act(async () => {
      userEvent.type(txtBuyPtCVN, "1");
    });
  });
  test("Custom Test Cases for txtBuyPtCVN", () => {
    // START_USER_CODE-USER_txtBuyPtCVN_TEST
    // END_USER_CODE-USER_txtBuyPtCVN_TEST
  });
  test("txtBuyPtWR(Textbox Widget) Test Cases", async () => {
    const txtBuyPtWR = screen.getByTestId("txtBuyPtWR");
    expect(txtBuyPtWR.tagName).toBe("INPUT");
    expect(txtBuyPtWR.type).toBe("text");
    expect(txtBuyPtWR.classList).toContain("textboxWidgetClass");
    expect(txtBuyPtWR.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtBuyPtWR")
    );
    await act(async () => {
      userEvent.type(txtBuyPtWR, "1");
    });
  });
  test("Custom Test Cases for txtBuyPtWR", () => {
    // START_USER_CODE-USER_txtBuyPtWR_TEST
    // END_USER_CODE-USER_txtBuyPtWR_TEST
  });
  test("txtInspectionNum(Textbox Widget) Test Cases", async () => {
    const txtInspectionNum = screen.getByTestId("txtInspectionNum");
    expect(txtInspectionNum.tagName).toBe("INPUT");
    expect(txtInspectionNum.type).toBe("text");
    expect(txtInspectionNum.classList).toContain("textboxWidgetClass");
    expect(txtInspectionNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtInspectionNum")
    );
    await act(async () => {
      userEvent.type(txtInspectionNum, "1");
    });
  });
  test("Custom Test Cases for txtInspectionNum", () => {
    // START_USER_CODE-USER_txtInspectionNum_TEST
    // END_USER_CODE-USER_txtInspectionNum_TEST
  });
  test("txtInspNumCSN(Textbox Widget) Test Cases", async () => {
    const txtInspNumCSN = screen.getByTestId("txtInspNumCSN");
    expect(txtInspNumCSN.tagName).toBe("INPUT");
    expect(txtInspNumCSN.type).toBe("text");
    expect(txtInspNumCSN.classList).toContain("textboxWidgetClass");
    expect(txtInspNumCSN.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtInspNumCSN")
    );
    await act(async () => {
      userEvent.type(txtInspNumCSN, "1");
    });
  });
  test("Custom Test Cases for txtInspNumCSN", () => {
    // START_USER_CODE-USER_txtInspNumCSN_TEST
    // END_USER_CODE-USER_txtInspNumCSN_TEST
  });
  test("txtNewInspection(Textbox Widget) Test Cases", async () => {
    const txtNewInspection = screen.getByTestId("txtNewInspection");
    expect(txtNewInspection.tagName).toBe("INPUT");
    expect(txtNewInspection.type).toBe("text");
    expect(txtNewInspection.classList).toContain("textboxWidgetClass");
    expect(txtNewInspection.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtNewInspection"
      )
    );
    await act(async () => {
      userEvent.type(txtNewInspection, "1");
    });
  });
  test("Custom Test Cases for txtNewInspection", () => {
    // START_USER_CODE-USER_txtNewInspection_TEST
    // END_USER_CODE-USER_txtNewInspection_TEST
  });
  test("txtNewReceiptNum(Textbox Widget) Test Cases", async () => {
    const txtNewReceiptNum = screen.getByTestId("txtNewReceiptNum");
    expect(txtNewReceiptNum.tagName).toBe("INPUT");
    expect(txtNewReceiptNum.type).toBe("text");
    expect(txtNewReceiptNum.classList).toContain("textboxWidgetClass");
    expect(txtNewReceiptNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtNewReceiptNum")
    );
    await act(async () => {
      userEvent.type(txtNewReceiptNum, "1");
    });
  });
  test("Custom Test Cases for txtNewReceiptNum", () => {
    // START_USER_CODE-USER_txtNewReceiptNum_TEST
    // END_USER_CODE-USER_txtNewReceiptNum_TEST
  });
  test("txtNewSettleNum(Textbox Widget) Test Cases", async () => {
    const txtNewSettleNum = screen.getByTestId("txtNewSettleNum");
    expect(txtNewSettleNum.tagName).toBe("INPUT");
    expect(txtNewSettleNum.type).toBe("text");
    expect(txtNewSettleNum.classList).toContain("textboxWidgetClass");
    expect(txtNewSettleNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtNewSettleNum")
    );
    await act(async () => {
      userEvent.type(txtNewSettleNum, "1");
    });
  });
  test("Custom Test Cases for txtNewSettleNum", () => {
    // START_USER_CODE-USER_txtNewSettleNum_TEST
    // END_USER_CODE-USER_txtNewSettleNum_TEST
  });
  test("txtNewTransferNum(Textbox Widget) Test Cases", async () => {
    const txtNewTransferNum = screen.getByTestId("txtNewTransferNum");
    expect(txtNewTransferNum.tagName).toBe("INPUT");
    expect(txtNewTransferNum.type).toBe("text");
    expect(txtNewTransferNum.classList).toContain("textboxWidgetClass");
    expect(txtNewTransferNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtNewTransferNum")
    );
    await act(async () => {
      userEvent.type(txtNewTransferNum, "1");
    });
  });
  test("Custom Test Cases for txtNewTransferNum", () => {
    // START_USER_CODE-USER_txtNewTransferNum_TEST
    // END_USER_CODE-USER_txtNewTransferNum_TEST
  });
  test("txtNewVendorNum(Textbox Widget) Test Cases", async () => {
    const txtNewVendorNum = screen.getByTestId("txtNewVendorNum");
    expect(txtNewVendorNum.tagName).toBe("INPUT");
    expect(txtNewVendorNum.type).toBe("text");
    expect(txtNewVendorNum.classList).toContain("textboxWidgetClass");
    expect(txtNewVendorNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtNewVendorNum")
    );
    await act(async () => {
      userEvent.type(txtNewVendorNum, "1");
    });
  });
  test("Custom Test Cases for txtNewVendorNum", () => {
    // START_USER_CODE-USER_txtNewVendorNum_TEST
    // END_USER_CODE-USER_txtNewVendorNum_TEST
  });
  test("txtOldReceiptNum(Textbox Widget) Test Cases", async () => {
    const txtOldReceiptNum = screen.getByTestId("txtOldReceiptNum");
    expect(txtOldReceiptNum.tagName).toBe("INPUT");
    expect(txtOldReceiptNum.type).toBe("text");
    expect(txtOldReceiptNum.classList).toContain("textboxWidgetClass");
    expect(txtOldReceiptNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtOldReceiptNum")
    );
    await act(async () => {
      userEvent.type(txtOldReceiptNum, "1");
    });
  });
  test("Custom Test Cases for txtOldReceiptNum", () => {
    // START_USER_CODE-USER_txtOldReceiptNum_TEST
    // END_USER_CODE-USER_txtOldReceiptNum_TEST
  });
  test("txtOldSettleNum(Textbox Widget) Test Cases", async () => {
    const txtOldSettleNum = screen.getByTestId("txtOldSettleNum");
    expect(txtOldSettleNum.tagName).toBe("INPUT");
    expect(txtOldSettleNum.type).toBe("text");
    expect(txtOldSettleNum.classList).toContain("textboxWidgetClass");
    expect(txtOldSettleNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtOldSettleNum")
    );
    await act(async () => {
      userEvent.type(txtOldSettleNum, "1");
    });
  });
  test("Custom Test Cases for txtOldSettleNum", () => {
    // START_USER_CODE-USER_txtOldSettleNum_TEST
    // END_USER_CODE-USER_txtOldSettleNum_TEST
  });
  test("txtOldTransferNum(Textbox Widget) Test Cases", async () => {
    const txtOldTransferNum = screen.getByTestId("txtOldTransferNum");
    expect(txtOldTransferNum.tagName).toBe("INPUT");
    expect(txtOldTransferNum.type).toBe("text");
    expect(txtOldTransferNum.classList).toContain("textboxWidgetClass");
    expect(txtOldTransferNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtOldTransferNum")
    );
    await act(async () => {
      userEvent.type(txtOldTransferNum, "1");
    });
  });
  test("Custom Test Cases for txtOldTransferNum", () => {
    // START_USER_CODE-USER_txtOldTransferNum_TEST
    // END_USER_CODE-USER_txtOldTransferNum_TEST
  });
  test("txtOldVendorNum(Textbox Widget) Test Cases", async () => {
    const txtOldVendorNum = screen.getByTestId("txtOldVendorNum");
    expect(txtOldVendorNum.tagName).toBe("INPUT");
    expect(txtOldVendorNum.type).toBe("text");
    expect(txtOldVendorNum.classList).toContain("textboxWidgetClass");
    expect(txtOldVendorNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtOldVendorNum")
    );
    await act(async () => {
      userEvent.type(txtOldVendorNum, "1");
    });
  });
  test("Custom Test Cases for txtOldVendorNum", () => {
    // START_USER_CODE-USER_txtOldVendorNum_TEST
    // END_USER_CODE-USER_txtOldVendorNum_TEST
  });
  test("txtShippingBuyPt(Textbox Widget) Test Cases", async () => {
    const txtShippingBuyPt = screen.getByTestId("txtShippingBuyPt");
    expect(txtShippingBuyPt.tagName).toBe("INPUT");
    expect(txtShippingBuyPt.type).toBe("text");
    expect(txtShippingBuyPt.classList).toContain("textboxWidgetClass");
    expect(txtShippingBuyPt.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncChgNum_txtShippingBuyPt")
    );
    await act(async () => {
      userEvent.type(txtShippingBuyPt, "1");
    });
  });
  test("Custom Test Cases for txtShippingBuyPt", () => {
    // START_USER_CODE-USER_txtShippingBuyPt_TEST
    // END_USER_CODE-USER_txtShippingBuyPt_TEST
  });
});
