/* eslint-disable*/
import React, { useState, useEffect, useRef,useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  TextAreaWidget,
  GridWidget,
  setValue,
  setData,
  getValue,
  getData,
  DateWidget,
  goTo,
} from "../../shared/WindowImports";

import "./ExceptPremCreate.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../Service/ContractManagementService"
import moment from "moment";//For date time
import { returnNumericValues } from './../../Common/Constants';

import CommonContext from '../../Store/CommonContext';//commonContext to set Data
import Loading from "../../../Loader/Loading";//Added Loader in Screen
import StripPic from "../../../../assets/img/PinStrip.png";
import { set } from "dot-object";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ExceptPremCreate(props) {
  const commonContext = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const js =[
   { "txtCol1007":"123",
    "txtColInsp":"3456"}
  ]

  let cropyear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  let userid = sessionStorage.getItem("userid");  


  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ExceptPremCreate",
    windowName: "ExceptPremCreate",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ExceptPremCreate",
    // START_USER_CODE-USER_ExceptPremCreate_PROPERTIES
    lstPremiumDeductionSchDetail : [],
    rowSelected : [],//selected row
    horizontalForm:true,
    headerData: {
      scrName: "Exception Premium Create",
      scrCode: "PN1190B"
    },
    // END_USER_CODE-USER_ExceptPremCreate_PROPERTIES
    txtVendr: {
      name: "txtVendr",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "",
      HasLabel:false,
      ColSpan: "1",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendr_PROPERTIES
      ReadOnly: true,
      Enabled:false,
      // END_USER_CODE-USER_txtVendr_PROPERTIES
    },
    btnCrtPrem: {
      name: "btnCrtPrem",
      type: "ButtonWidget",
      parent: "grpbxRate",
      Label: "Create Premium (s)",
      CharWidth: "37",
      // START_USER_CODE-USER_btnCrtPrem_PROPERTIES

      // END_USER_CODE-USER_btnCrtPrem_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxPayReq",
      Label: "Exit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnCreatePayReq: {
      name: "btnCreatePayReq",
      type: "ButtonWidget",
      parent: "grpbxPayReq",
      Label: "Create Pay Request",
      CharWidth: "36",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreatePayReq_PROPERTIES

      // END_USER_CODE-USER_btnCreatePayReq_PROPERTIES
    },
    btnRejectPrem: {
      name: "btnRejectPrem",
      type: "ButtonWidget",
      parent: "grpbxPayReq",
      Label: "Reject Premium(s)",
      CharWidth: "35",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnRejectPrem_PROPERTIES

      // END_USER_CODE-USER_btnRejectPrem_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxSearch",
      Label: "Search",
      ColSpan: "2",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES
      type: "Submit",
      Enabled: "true"
      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxSearch",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    lblAvailble: { 
      name: "lblAvailble", 
      type: "LabelWidget",
      parent: "grpbx1007s", 
      Label: "Available 1007s", 
      LengthRange: { MinLength: 0, MaxLength: 256 }, 
      ofTypeDomain: "d_String", 
      // START_USER_CODE-USER_lblBuyPtId_PROPERTIES   
       
      // END_USER_CODE-USER_lblBuyPtId_PROPERTIES    
      },
    chkboxAll1007s: {
      name: "chkboxAll1007s",
      type: "CheckBoxWidget",
      parent: "grpbx1007s",
      Label: "All 1007s",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxAll1007s_PROPERTIES

      // END_USER_CODE-USER_chkboxAll1007s_PROPERTIES
    },
    ddGeneration: {
      name: "ddGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGeneration_PROPERTIES

      // END_USER_CODE-USER_ddGeneration_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES
      // Mandatory:true,
      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Peanut Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddPremiumCode: {
      name: "ddPremiumCode",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Premium Code:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPremiumCode_PROPERTIES
      // Mandatory:true,
      // END_USER_CODE-USER_ddPremiumCode_PROPERTIES
    },
    ddRateBase: {
      name: "ddRateBase",
      type: "DropDownFieldWidget",
      parent: "grpbxRate",
      Label: "Rate Base:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRateBase_PROPERTIES

      // END_USER_CODE-USER_ddRateBase_PROPERTIES
    },
    ddSeed: {
      name: "ddSeed",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Seed:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeed_PROPERTIES

      // END_USER_CODE-USER_ddSeed_PROPERTIES
    },
    ddSegType: {
      name: "ddSegType",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Seg Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegType_PROPERTIES

      // END_USER_CODE-USER_ddSegType_PROPERTIES
    },
    lblTotal: {
      name: "lblTotal",
      type: "LabelWidget",
      parent: "grpbx1007s",
      Label: "Total:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTotal_PROPERTIES

      // END_USER_CODE-USER_lblTotal_PROPERTIES
    },
    
    txtarExplanation: {
      name: "txtarExplanation",
      type: "TextAreaWidget",
      parent: "grpbxPayReq",
      Label: "Explanation:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarExplanation_PROPERTIES

      // END_USER_CODE-USER_txtarExplanation_PROPERTIES
    },
    lblBuyPtId: {
      name: "lblBuyPtId",
      type: "LabelWidget",
      parent: "grpbx1007s",
      Label: "lblBuyPtId",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyPtId_PROPERTIES

      // END_USER_CODE-USER_lblBuyPtId_PROPERTIES
    },
    lblVendrNm: {
      name: "lblVendrNm",
      type: "LabelWidget",
      parent: "grpbx1007s",
      Label: "lblVendorName",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendrNm_PROPERTIES

      // END_USER_CODE-USER_lblVendrNm_PROPERTIES
    },
    lblPremiumCode: {
      name: "lblPremiumCode",
      type: "LabelWidget",
      parent: "grpbx1007s",
      Label: "lblPRemiumCode",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPremiumCode_PROPERTIES

      // END_USER_CODE-USER_lblPremiumCode_PROPERTIES
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Contract #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES

      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    
    txtDateReq: {
      name: "txtDateReq",
      type: "DateTimeWidget",
      parent: "grpbxPayReq",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtDateReq_PROPERTIES

      // END_USER_CODE-USER_txtDateReq_PROPERTIES
    },

    txtFarm: {
      name: "txtFarm",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Farm #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarm_PROPERTIES

      // END_USER_CODE-USER_txtFarm_PROPERTIES
    },
    txtFarmSuffix: {
      name: "txtFarmSuffix",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Farm Suffix #:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmSuffix_PROPERTIES

      // END_USER_CODE-USER_txtFarmSuffix_PROPERTIES
    },
    txtGeneration: {
      name: "txtGeneration",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGeneration_PROPERTIES

      // END_USER_CODE-USER_txtGeneration_PROPERTIES
    },
    txtGrossWT: {
      name: "txtGrossWT",
      type: "TextBoxWidget",
      parent: "grpbx1007s",
      Label: "Gross WT",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGrossWT_PROPERTIES
      ReadOnly: true,
      Enabled:false,
      // END_USER_CODE-USER_txtGrossWT_PROPERTIES
    },
    txtIsRateBase: {
      name: "txtIsRateBase",
      type: "TextBoxWidget",
      parent: "grpbxRate",
      ColSpan: "3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIsRateBase_PROPERTIES

      // END_USER_CODE-USER_txtIsRateBase_PROPERTIES
    },
    txtLocation: {
      name: "txtLocation",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocation_PROPERTIES

      // END_USER_CODE-USER_txtLocation_PROPERTIES
    },
    txtLSKWT: {
      name: "txtLSKWT",
      type: "TextBoxWidget",
      parent: "grpbx1007s",
      Label: "LSK WT",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKWT_PROPERTIES
      ReadOnly: true,
      Enabled:false,
      // END_USER_CODE-USER_txtLSKWT_PROPERTIES
    },
    txtNetWT: {
      name: "txtNetWT",
      type: "TextBoxWidget",
      parent: "grpbx1007s",
      Label: "Net WT",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWT_PROPERTIES
      ReadOnly: true,
      Enabled:false,
      // END_USER_CODE-USER_txtNetWT_PROPERTIES
    },
    txtOleic: {
      name: "txtOleic",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOleic_PROPERTIES

      // END_USER_CODE-USER_txtOleic_PROPERTIES
    },
    txtPeanuttype: {
      name: "txtPeanuttype",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanuttype_PROPERTIES

      // END_USER_CODE-USER_txtPeanuttype_PROPERTIES
    },
    txtPeanutVariety: {
      name: "txtPeanutVariety",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_txtPeanutVariety_PROPERTIES
    },
    txtPremiumAmount: {
      name: "txtPremiumAmount",
      type: "TextBoxWidget",
      parent: "grpbx1007s",
      Label: "Premium Amount",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremiumAmount_PROPERTIES
      ReadOnly: true,
      Enabled:false,
      // END_USER_CODE-USER_txtPremiumAmount_PROPERTIES
    },
    txtPremiumRate: {
      name: "txtPremiumRate",
      type: "TextBoxWidget",
      parent: "grpbx1007s",
      Label: "Premium Rate",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremiumAmount_PROPERTIES
      
      // END_USER_CODE-USER_txtPremiumAmount_PROPERTIES
    },
    txtPremiumCode: {
      name: "txtPremiumCode",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremiumCode_PROPERTIES

      // END_USER_CODE-USER_txtPremiumCode_PROPERTIES
    },
    txtRate: {
      name: "txtRate",
      type: "TextBoxWidget",
      parent: "grpbxRate",
      Label: "Rate:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRate_PROPERTIES

      // END_USER_CODE-USER_txtRate_PROPERTIES
    },
    txtReqBy: {
      name: "txtReqBy",
      type: "TextBoxWidget",
      parent: "grpbxPayReq",
      Label: "Requested By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReqBy_PROPERTIES

      // END_USER_CODE-USER_txtReqBy_PROPERTIES
    },
    txtSeed: {
      name: "txtSeed",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeed_PROPERTIES

      // END_USER_CODE-USER_txtSeed_PROPERTIES
    },
    txtSegType: {
      name: "txtSegType",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegType_PROPERTIES

      // END_USER_CODE-USER_txtSegType_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength:6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES
      
      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    grpbxSearch: {
      name: "grpbxSearch",
      type: "GroupBoxWidget",
      parent: "ExceptPremCreate",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxSearch_PROPERTIES
    },
    grpbx1007s: {
      name: "grpbx1007s",
      type: "GroupBoxWidget",
      parent: "ExceptPremCreate",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbx1007s_PROPERTIES

      // END_USER_CODE-USER_grpbx1007s_PROPERTIES
    },
    grpbxRate: {
      name: "grpbxRate",
      type: "GroupBoxWidget",
      parent: "ExceptPremCreate",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxRate_PROPERTIES

      // END_USER_CODE-USER_grpbxRate_PROPERTIES
    },
    grpbxPayReq: {
      name: "grpbxPayReq",
      type: "GroupBoxWidget",
      parent: "ExceptPremCreate",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPayReq_PROPERTIES

      // END_USER_CODE-USER_grpbxPayReq_PROPERTIES
    },
    col1007: {
      name: "col1007",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "1007#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1007_PROPERTIES

      // END_USER_CODE-USER_col1007_PROPERTIES
    },
    colBasisGrd: {
      name: "colBasisGrd",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Basis Grade",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBasisGrd_PROPERTIES

      // END_USER_CODE-USER_colBasisGrd_PROPERTIES
    },
    colContrct: {
      name: "colContrct",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Contract",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContrct_PROPERTIES

      // END_USER_CODE-USER_colContrct_PROPERTIES
    },
    colFarm: {
      name: "colFarm",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Farm",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarm_PROPERTIES

      // END_USER_CODE-USER_colFarm_PROPERTIES
    },
    colGen: {
      name: "colGen",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Generation",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGen_PROPERTIES

      // END_USER_CODE-USER_colGen_PROPERTIES
    },
    colGross: {
      name: "colGross",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Gross Wt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGross_PROPERTIES

      // END_USER_CODE-USER_colGross_PROPERTIES
    },
    colInsp: {
      name: "colInsp",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Insp #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInsp_PROPERTIES

      // END_USER_CODE-USER_colInsp_PROPERTIES
    },
    colLskWt: {
      name: "colLskWt",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "LSK Wt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLskWt_PROPERTIES

      // END_USER_CODE-USER_colLskWt_PROPERTIES
    },
    colNetWt: {
      name: "colNetWt",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Net Wt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetWt_PROPERTIES

      // END_USER_CODE-USER_colNetWt_PROPERTIES
    },
    colOleic: {
      name: "colOleic",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOleic_PROPERTIES

      // END_USER_CODE-USER_colOleic_PROPERTIES
    },
    colPnutTyp: {
      name: "colPnutTyp",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Pnut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutTyp_PROPERTIES

      // END_USER_CODE-USER_colPnutTyp_PROPERTIES
    },
    colPnutVrty: {
      name: "colPnutVrty",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Pnut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutVrty_PROPERTIES

      // END_USER_CODE-USER_colPnutVrty_PROPERTIES
    },
    colPremAmnt: {
      name: "colPremAmnt",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Premium Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPremAmnt_PROPERTIES

      // END_USER_CODE-USER_colPremAmnt_PROPERTIES
    },
    colPremRate: {
      name: "colPremRate",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Premium Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPremRate_PROPERTIES

      // END_USER_CODE-USER_colPremRate_PROPERTIES
    },
    colRevInd: {
      name: "colRevInd",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Rev Ind",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRevInd_PROPERTIES

      // END_USER_CODE-USER_colRevInd_PROPERTIES
    },
    colSeedSpec: {
      name: "colSeedSpec",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Seed Spec",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedSpec_PROPERTIES

      // END_USER_CODE-USER_colSeedSpec_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colWr: {
      name: "colWr",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      Label: "WR#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWr_PROPERTIES

      // END_USER_CODE-USER_colWr_PROPERTIES
    },
    txtCol1007: {
      name: "txtCol1007",
      type: "TextBoxWidget",
      colName: "col1007",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCol1007_PROPERTIES

      // END_USER_CODE-USER_txtCol1007_PROPERTIES
    },
    chkbxCol: {
      name: "chkbxCol",
      type: "CheckBoxWidget",
      colName: "colChckbx",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    colChckbx: {
      name: "colChckbx",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChckbx_PROPERTIES

      // END_USER_CODE-USER_colChckbx_PROPERTIES
    },
    colIsSts: {
      name: "colIsSts",
      type: "TextBoxWidget",
      colName: "colSts",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colIsSts_PROPERTIES

      // END_USER_CODE-USER_colIsSts_PROPERTIES
    },
    colSts: {
      name: "colSts",
      type: "GridColumnWidget",
      parent: "gridAvail1007",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSts_PROPERTIES

      // END_USER_CODE-USER_colSts_PROPERTIES
    },
    
    txtColBasisGrd: {
      name: "txtColBasisGrd",
      type: "TextBoxWidget",
      colName: "colBasisGrd",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColBasisGrd_PROPERTIES

      // END_USER_CODE-USER_txtColBasisGrd_PROPERTIES
    },
    txtColContrct: {
      name: "txtColContrct",
      type: "TextBoxWidget",
      colName: "colContrct",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColContrct_PROPERTIES

      // END_USER_CODE-USER_txtColContrct_PROPERTIES
    },
    txtColFarm: {
      name: "txtColFarm",
      type: "TextBoxWidget",
      colName: "colFarm",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColFarm_PROPERTIES

      // END_USER_CODE-USER_txtColFarm_PROPERTIES
    },
    txtColGen: {
      name: "txtColGen",
      type: "TextBoxWidget",
      colName: "colGen",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColGen_PROPERTIES

      // END_USER_CODE-USER_txtColGen_PROPERTIES
    },
    txtColGross: {
      name: "txtColGross",
      type: "TextBoxWidget",
      colName: "colGross",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColGross_PROPERTIES

      // END_USER_CODE-USER_txtColGross_PROPERTIES
    },
    txtColInsp: {
      name: "txtColInsp",
      type: "TextBoxWidget",
      colName: "colInsp",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColInsp_PROPERTIES

      // END_USER_CODE-USER_txtColInsp_PROPERTIES
    },
    txtColLskWt: {
      name: "txtColLskWt",
      type: "TextBoxWidget",
      colName: "colLskWt",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColLskWt_PROPERTIES

      // END_USER_CODE-USER_txtColLskWt_PROPERTIES
    },
    txtColNetWt: {
      name: "txtColNetWt",
      type: "TextBoxWidget",
      colName: "colNetWt",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColNetWt_PROPERTIES

      // END_USER_CODE-USER_txtColNetWt_PROPERTIES
    },
    txtColOleic: {
      name: "txtColOleic",
      type: "TextBoxWidget",
      colName: "colOleic",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColOleic_PROPERTIES

      // END_USER_CODE-USER_txtColOleic_PROPERTIES
    },
    txtColPnutTyp: {
      name: "txtColPnutTyp",
      type: "TextBoxWidget",
      colName: "colPnutTyp",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColPnutTyp_PROPERTIES

      // END_USER_CODE-USER_txtColPnutTyp_PROPERTIES
    },
    txtColPnutVrty: {
      name: "txtColPnutVrty",
      type: "TextBoxWidget",
      colName: "colPnutVrty",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColPnutVrty_PROPERTIES

      // END_USER_CODE-USER_txtColPnutVrty_PROPERTIES
    },
    txtColPremAmnt: {
      name: "txtColPremAmnt",
      type: "TextBoxWidget",
      colName: "colPremAmnt",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColPremAmnt_PROPERTIES

      // END_USER_CODE-USER_txtColPremAmnt_PROPERTIES
    },
    txtColPremRate: {
      name: "txtColPremRate",
      type: "TextBoxWidget",
      colName: "colPremRate",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColPremRate_PROPERTIES

      // END_USER_CODE-USER_txtColPremRate_PROPERTIES
    },
    txtColRevInd: {
      name: "txtColRevInd",
      type: "TextBoxWidget",
      colName: "colRevInd",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColRevInd_PROPERTIES

      // END_USER_CODE-USER_txtColRevInd_PROPERTIES
    },
    txtColSeedSpec: {
      name: "txtColSeedSpec",
      type: "TextBoxWidget",
      colName: "colSeedSpec",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColSeedSpec_PROPERTIES

      // END_USER_CODE-USER_txtColSeedSpec_PROPERTIES
    },
    txtColSeg: {
      name: "txtColSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColSeg_PROPERTIES

      // END_USER_CODE-USER_txtColSeg_PROPERTIES
    },
    txtColWr: {
      name: "txtColWr",
      type: "TextBoxWidget",
      colName: "colWr",
      parent: "gridAvail1007",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtColWr_PROPERTIES

      // END_USER_CODE-USER_txtColWr_PROPERTIES
    },
    gridAvail1007: {
      name: "gridAvail1007",
      type: "GridWidget",
      parent: "ExceptPremCreate",
      gridCellsOrder:
        "colIsSts,txtCol1007,txtColInsp,txtColWr,txtColPremAmnt,txtColPremRate,txtColContrct,txtColFarm,txtColPnutTyp,txtColPnutVrty,txtColSeedSpec,txtColGen,txtColSeg,txtColOleic,txtColGross,txtColNetWt,txtColLskWt,txtColBasisGrd,txtColRevInd",
      Label: "Available 1007s:",
      Pagination: false,
      HasLabel: true,
      Height: "",
      Width: "",
      isEditable:true,
      // START_USER_CODE-USER_gridAvail1007_PROPERTIES
      isMultiSelect: true,
      hideAllCheckBox:false,
      needGridChange: true//Change for Grid change
      // END_USER_CODE-USER_gridAvail1007_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#VendorLookup":{},
        // "ContractManagement#ReportPreviewExcPrem":{},
        
      },
      REVERSE: {
        "ContractManagement#VendorLookup":{},
        // "ContractManagement#ReportPreviewExcPrem":{},
      },
    },
  };
  let _buttonServices = {VendorLookup:{},ReportPreviewExcPrem:{}};
  let _buttonNavigation = {
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    // btnCreatePayReq:{
    //   DEFAULT: ["ContractManagement#ReportPreviewExcPrem#DEFAULT#true#Click"],
    // }
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  const [loading, setLoading] = useState(true);//Loader Functionality
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let vendor_lookup=getData(thisObj,'vendorDetails')
    if(vendor_lookup !==null){
      thisObj.setFieldValue("txtVendor",vendor_lookup.VendorNumber);
      document.getElementsByClassName("txtVendr")[0].style.visibility = "visible";
      thisObj.setFieldValue("txtVendr",vendor_lookup.key);
    }
    else{
      document.getElementsByClassName("txtVendr")[0].style.visibility = "hidden";
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj,'vendorDetails')])

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    setValue(thisObj, "ddLocation", ''),
    setValue(thisObj, "ddPeanutType", ''),
    setValue(thisObj, "ddPeanutVariety", ''),
    setValue(thisObj, "ddPremiumCode", ''),
    setValue(thisObj, "ddSegType", ''),
    setValue(thisObj, "ddOleic", ''),
    setValue(thisObj, "ddSeed", ''),
    setValue(thisObj, "ddGeneration", '')
    setValue(thisObj, "ddRateBase", '')
    
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  //only numeric value from 0 to 9 allowed functionality(Ascii Functionality)
  const ontxtContractChange = () => {
    setValue(thisObj, "txtContract", returnNumericValues(txtContract.value))
  };
  thisObj.ontxtContractChange = ontxtContractChange;

  // Rate Formating
  const ontxtRateBlur = (value) => {
    try {
      let rate = getValue(thisObj,"txtRate") //for Formatting the Rate to 5 digits.
      let rateBase = getValue(thisObj,"ddRateBase")
      var rowSelected = getData(thisObj, 'rowSelected')
      if(!isNaN(rate) && rate != null && rate != undefined && rate !=''){
        rate = Number(rate).toFixed(5)
      }
      setValue(thisObj, 'txtRate', rate);
      if((rowSelected != null && rowSelected['length']!=0) && (rateBase != null && rateBase != undefined && rateBase != '')){
      PremiumAmtChange(rowSelected) //Calling this Function on txtRate Blur to get the Value Inserted in Grid.
      }

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        // showMessage(
        //   thisObj,
        //   "Exception in PreSubmit Event code for widget:btnClose event:Click"
        // );
      }
      return false;
    }
  };
  thisObj.ontxtRateBlur = ontxtRateBlur;

  // Formating for the FarmBlur and Farm suffix Blur TextBox
  const ontxtFarmBlur = () => {
    let FarmNumber = getValue(thisObj, 'txtFarm')
    if (FarmNumber != null && FarmNumber != undefined) {
      let FarmNumLength = FarmNumber.length
      if (FarmNumLength < 7 && FarmNumLength > 0) {
        let NewFrmNum = '0'.repeat((7 - FarmNumLength)) + FarmNumber
        setValue(thisObj, 'txtFarm', NewFrmNum)
      }
    }
  }
  thisObj.ontxtFarmBlur = ontxtFarmBlur

  const ontxtFarmSuffixBlur = () => {
    let FarmNumber = getValue(thisObj, 'txtFarmSuffix')
    if (FarmNumber != null && FarmNumber != undefined) {
      let FarmNumLength = FarmNumber.length
      if (FarmNumLength < 3 && FarmNumLength > 0) {
        let NewFrmNum = '0'.repeat((3 - FarmNumLength)) + FarmNumber
        setValue(thisObj, 'txtFarmSuffix', NewFrmNum)
      }
    }
  }
  thisObj.ontxtFarmSuffixBlur = ontxtFarmSuffixBlur


  function FormLoad() {
    bFillTypeList();
    bFillLocationList();
    bFillPeanutVarietyList(null, null);
    bFillPremiumCodeList();
    PremiumCodeList();
    bFillSegmentList();
    bFillOleicList();
    bFillSeedList();
    bFillRateBaseList();
    bFillGenList();
    document.getElementsByClassName("txtVendr")[0].style.visibility = "hidden";
    setValue(thisObj, "txtVendor", "")
    setValue(thisObj, "txtVendr", "")
    setData(thisObj, 'rowSelected', [])
    setValue(thisObj, "txtPremiumAmount", "0"),
      setValue(thisObj, "txtGrossWT", "0"),
      setValue(thisObj, "txtNetWT", "0"),
      setValue(thisObj, "txtLSKWT", "0")
      setLoading(false);
  }
  //Static parameters
  const bFillSegmentList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Segments <<<' })
    js.push({ key: '1', description: '1' })
    js.push({ key: '2', description: '2' })
    js.push({ key: '3', description: '3' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSegType: {
          ...state["ddSegType"],
          valueList: js
        }
      }
    })
  }

  const bFillOleicList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Oleic <<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })
  }

  const bFillSeedList = () => {
    let js = [];
    js.push({ key: '', description: '>>> Both Seeds <<<' })
    js.push({ key: 'Y', description: 'Seed' })
    js.push({ key: 'N', description: 'Non-Seed' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSeed: {
          ...state["ddSeed"],
          valueList: js
        }
      }
    })
  }

  const bFillGenList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Generation <<<' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    thisObj.setState(current => {
      return {
        ...current,
        ddGeneration: {
          ...state["ddGeneration"],
          valueList: js
        }
      }
    })
  }

  const bFillRateBaseList = () => {
    let js = [];
    js.push({ key: '', description: ' ' })
    js.push({ key: 'G', description: 'per Gross Ton' })
    js.push({ key: 'N', description: 'per Net Ton' })
    js.push({ key: 'K', description: 'per Net less LSK Ton' })
    js.push({ key: 'B', description: 'per Net Ton Basis Grade' })
    js.push({ key: 'L', description: 'per Load' })
    thisObj.setState(current => {
      return {
        ...current,
        ddRateBase: {
          ...state["ddRateBase"],
          valueList: js
        }
      }
    })
  }

  // START_USER_CODE-USER_METHODS
  const bFillLocationList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Buying Points <<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1190','001', null, null, null).then(response=>{
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js
          }
        }
      })
    })
  }

  const bFillTypeList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Types <<<' }) //Set All as default value
    ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
        js.push(obj)

      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })
    })
  }

  //Peanut Type dropdown change event
  const onPeanutTypeChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPeanutType'];
      let pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddLocation'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== null && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety based on Buying Point and Peanut Type
      bFillPeanutVarietyList(buyingPointId, pnut_type_id)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        // showMessage(
        //   thisObj,
        //   "Error occurred during Peanut Type dropdown change event"
        // );
      }
      return false;
    }
    return true;
  };
  thisObj.onddPeanutTypeChange = onPeanutTypeChange;

  const onBuyingPointChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPeanutType'];
      let pnut_type_id = null
      if (SelectedPeanutTypeValue !== "" && SelectedPeanutTypeValue !== undefined) {
        pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      }

      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddLocation'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety and  based on Buying Point and Peanut Type
      bFillPeanutVarietyList(buyingPointId, pnut_type_id)
      bFillPremiumCodeList()
      filterPremiumCodeList(buyingPointId)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        // showMessage(
        //   thisObj,
        //   "Error occurred during Buying Point dropdown change event"
        // );
      }
      return false;
    }
    return true;
  };
  thisObj.onddLocationChange = onBuyingPointChange;

  //Method for binding Peanut Variety based on Buying Point and Peanut Type	
  const bFillPeanutVarietyList = (buyingPointId, pnut_type_id) => {
    let js = []
    let obj = { key: '', description: ">>> All Varieties <<<" }
    js.push(obj)

    if (pnut_type_id !== null && pnut_type_id !== '' && pnut_type_id !== undefined) {
      ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null)
        .then(response => {
          let data = response;
          if (data !== null && data !== undefined) {
            let js1 = [];		
      for (var i = 0; i < data.length; i++) {	
        if (!(js1.includes(data[i].pnut_variety_id))){	
        obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name }	
        if (data[i].symbol_ind == "TRADEMARK") {	
          obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name + 'ᵀᴹ' }	
        }	
        if (data[i].symbol_ind == "REGISTERED TRADEMARK") {	
          obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name + '®' }	
        }	
        if (data[i].symbol_ind == "COPYRIGHT") {	
          obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name + '©' }	
        }	
        js.push(obj);	
        js1.push(data[i].pnut_variety_id)	
      }	
      	
      }	}})}
      thisObj.setState(current => {	
        return {	
          ...current,	
          ddPeanutVariety: {	
            ...state["ddPeanutVariety"],	
            valueList: js	
          }	
        }	
      });	
  }

   // Premium Code change according to buying pt
  function filterPremiumCodeList(buyingPointId) {
    let dataList = getValue(thisObj, "lstPremiumDeductionSchDetail")
    var res = []
    res.push({ key: '', description: '>>> All Premium Codes <<<' })
    for (var i = 0; i < dataList.length; i++) {
      if (dataList[i].buyPtId == buyingPointId) {
        var obj = { key: dataList[i].pdCode, description: dataList[i].pdCode.trim() + '-' + dataList[i].pdDesc.trim() }
        res.push(obj)
      }
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPremiumCode: {
          ...state["ddPremiumCode"],
          valueList: res,
        }
      }
    })
  }

  const bFillPremiumCodeList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Premium Codes <<<' })
    thisObj.setState(current => {
      return {
        ...current,
        ddPremiumCode: {
          ...state["ddPremiumCode"],
          valueList: js
        }
      }
    })
  }
  // Premcode Api calling
  const PremiumCodeList = (buyingPointId) => {
    var js = [];
    var res = [];
    let pd_code = null
    let inspect_ind = null
    let rate_type = null
    let check_enable_ind = null
    let rcpt_enable_ind = null
    let rpt_1099_ind = null
    let except_prem_ind = 'Y'
    ContractManagementService.RetrivePremiumDeductionScheduleDetails(buyingPointId, pd_code, inspect_ind, rate_type, check_enable_ind, rcpt_enable_ind, rpt_1099_ind, except_prem_ind).then(response => {
      let data = response
      setValue(thisObj, "lstPremiumDeductionSchDetail", response)
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pdCode, description: data[i].pdCode.trim() + '-' + data[i].pdDesc.trim() }
        if (!res.includes(data[i].pdCode)) {
          js.push(obj)
          res.push(data[i].pdCode)
        }
      }
    })
  }

//showing vendorname txtbox
const ontxtVendorChange = () => {
  try {
//PRB0054498 - Searching the vendors with vendor number from vendor textbox and convert to uppercase
  let vendorNumber = txtVendor.value.toUpperCase()
    txtVendor.value = vendorNumber
    if (vendorNumber.length == 6) {
      setVendorNameBasedOnVendorNumber(vendorNumber)
    }
    else {
      // setValue(thisObj,"txtVendr","")
      document.getElementsByClassName("txtVendr")[0].style.visibility = "hidden";
    }

  } catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      // showMessage(
      //   thisObj,
      //   "Exception in PreSubmit Event code for widget:txtVondor event:Change"
      // );
    }
    return false;
  }
  return true;
};
thisObj.ontxtVendorChange = ontxtVendorChange;

  const setVendorNameBasedOnVendorNumber = (vendorNumber) => {

    let venname = null
    let vendornamevisible = false
    ContractManagementService.RetrieveVendorByNumber(vendorNumber).then(response => {
      let data = response
      if (response !== undefined && response.length> 0) {
        venname = data[0].name
        vendornamevisible = true
      }
      if (vendornamevisible == true) {
        document.getElementsByClassName("txtVendr")[0].style.visibility = "visible";
        setValue(thisObj, "txtVendr", venname)
      }
      else {
        document.getElementsByClassName("txtVendr")[0].style.visibility = "hidden";
      }
    })
  }

  // Search Button Functionality
  const onbtnSearchClick = async () => {
    try {
      setLoading(true);
      var buy_pt_id = thisObj.values['ddLocation']
      var prem_vendor = txtVendor.value
      var prem_code = thisObj.values['ddPremiumCode']
      var farm_id = txtFarm.value
      var farm_suffix = txtFarmSuffix.value
      var contract_num = txtContract.value
      var insp_num = null
      var pnut_type_id = thisObj.values['ddPeanutType']
      var pnut_variety_id = thisObj.values['ddPeanutVariety']
      var seed_ind = thisObj.values['ddSeed']
      var seed_gen = thisObj.values['ddGeneration']
      var seg_type = thisObj.values['ddSegType']
      var oleic_ind = thisObj.values['ddOleic']
      var support_per_ton=support_per_ton

      if (txtVendor.value == '' || thisObj.values['ddLocation'] == '' || thisObj.values['ddPremiumCode'] == '') {
        alert("Location, Vendor, and Premium Code are required search fields.", false)
      }
      else {
       let response = await ContractManagementService.RetrieveExceptionPremiumAvailable1007Details(buy_pt_id, prem_vendor, prem_code, farm_id, farm_suffix, contract_num, insp_num, pnut_type_id, pnut_variety_id, seed_ind, seed_gen, seg_type, oleic_ind)
          // .then(response => {  // Due to this .then method call it is not synchronized so loader not able to load.
            let js = [];
            let TransformedRowsArray = [];
            if(response != undefined && response.length > 0) {
              let data = response;
            let obj = {};
            for (var i = 0; i < data.length; i++) {
              obj.txtCol1007 = data[i].settlement_num;
              obj.txtColInsp = data[i].insp_num;
              obj.txtColWr = data[i].whse_rcpt_num;
              obj.txtColPremAmnt = data[i].prem_amount;
              obj.txtColPremRate = data[i].prem_rate;
              obj.txtColContrct = data[i].contract_num;
              obj.txtColFarm = data[i].farm_id;
              obj.txtColPnutTyp = data[i].pnut_type_id;
              obj.txtColPnutVrty = data[i].pnut_variety_id;
              obj.txtColSeedSpec = data[i].seed_meeting_spec_ind;
              obj.txtColGen = data[i].seed_gen;
              obj.txtColSeg = data[i].seg_type;
              obj.txtColOleic = data[i].oleic_ind;
              obj.txtColGross = parseFloat(data[i].gross_wt);
              obj.txtColNetWt = parseFloat(data[i].net_wt);
              obj.txtColLskWt = parseFloat(data[i].lsk_wt);
              obj.txtColBasisGrd = parseFloat(data[i].basis_grade_amt);
              obj.txtColRevInd = data[i].rev_ind;
              obj.support_per_ton = parseFloat(data[i].support_per_ton);
              js.push(obj)
              obj = {};
            }
              setValue(thisObj, "gridAvail1007", js);
              setData(thisObj, 'gridAvail1007', js)
            } else {
              setValue(thisObj, "gridAvail1007", TransformedRowsArray);
              setLoading(false);
              return;
              // showMessage(thisObj,"No Exception Premium Founds!!!",false);//Commented Because as there is no data in grid showing message in the Child screen as per old there is no Message Populating.
            }
      }
      setLoading(false);
  }
  catch (err) {
    setLoading(false);
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message)
    }
    else {
      showMessage(
        thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
      )
    }
    return false
  }
};
thisObj.onbtnSearchClick = onbtnSearchClick


  // CheckBox All Selection On 1007s Click
  const onchkboxAll1007sChange = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      const chk = getValue(thisObj, "chkboxAll1007s");

      const target = document.getElementsByClassName("rdt_TableHeadRow")[0].firstChild.firstChild;

      if (chk) {
        target.checked = true
        target.click();
      }
      else {
       target.checked = false; 
       target.click();
      }
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        // showMessage(
        //   thisObj,
        //   "Exception in PreSubmit Event code for widget:btnExit event:Click"
        // );
      }
      return false;
    }
    return true;
  };
  thisObj.onchkboxAll1007sChange = onchkboxAll1007sChange;
  
  // Selected Grid value changing 
  const ongridAvail1007RowSelect = (rowSelected) => {
   
    let data1 = rowSelected.selectedRows
    let total = 0
    let total1 = 0
    let total2 = 0
       
    if (!data1) {
      return;
    }

    setData(thisObj, 'rowSelected', data1);
    if (data1 !== undefined) {
      let gridSelectedValue = [];
      for (var i = 0; i < data1.length; i++) {
        if (data1[i].support_per_ton !== undefined && data1[i].support_per_ton !== null) {
          let obj = data1[i].support_per_ton
          gridSelectedValue.push(obj)
        }
      }
      setData(thisObj, 'support_per_ton', gridSelectedValue)
    }
    if (data1.length!=0) {
      for (var i = 0; i < data1.length; i++) {
        total = total + parseFloat(data1[i].txtColGross)
        total1 = total1 + parseFloat(data1[i].txtColNetWt)
        total2 = total2 + parseFloat(data1[i].txtColLskWt)
      }
      setValue(thisObj,"txtGrossWT",total)
      setValue(thisObj,"txtNetWT",total1)
      setValue(thisObj,"txtLSKWT",total2)
    }
    else{
        setValue(thisObj, "txtPremiumAmount", "0")
        setValue(thisObj, "txtGrossWT", "0")
        setValue(thisObj, "txtNetWT", "0")
        setValue(thisObj, "txtLSKWT", "0")
    }
    PremiumAmtChange(rowSelected.selectedRows)
  };
  thisObj.ongridAvail1007RowSelect = ongridAvail1007RowSelect;
  // Premium Amount Change Calculation and setting selected Grid Value 

  function PremiumAmtChange(rowSelected) {
    var rowSelected = [...rowSelected].sort((a,b) => a.txtCol1007 - b.txtCol1007)
    if (!rowSelected) {
      return;
    }
// if we make rate text empty and rate base as null then it should remove values in grid columns.
    else
     if (!thisObj.values.ddRateBase || txtRate.value==='' || isNaN(txtRate.value) || txtRate.value === undefined || thisObj.values.ddRateBase =="") {//Adding Additional Condition for checking the rate and rate base dropdown.
      var js = getData(thisObj, 'gridAvail1007')
      var b = 0
      for (var i = 0; i < rowSelected.length; i++) {
        for (var j = b; j < js.length; j++) {
          if ((js[j].txtColInsp == rowSelected[i].txtColInsp) && (js[j].txtCol1007 == rowSelected[i].txtCol1007) && (js[j].txtColContrct == rowSelected[i].txtColContrct)) {
              js[j].txtColPremAmnt = ""
              js[j].txtColPremRate = ""
              js[j].isSelected = true
              b = j + 1
      }
    }
    }
    setValue(thisObj,"gridAvail1007",js)
    setValue(thisObj,"txtPremiumAmount","0")
  }
//Main else condition for setting the Values of Premium Amt column in Grid
    else {
      var total1 = 0;
      var total2 = 0;
      var total3 = 0;
      var total4 = 0;
      var total5=0;
      var support_per_ton=0;
      var js = getData(thisObj, 'gridAvail1007')
      var b = 0
      for (var i = 0; i < rowSelected.length; i++) {
        for (var j = b; j < js.length; j++) {
                   //commented and added Jun28 -PRB0053493
        //  if (js[j].txtCol1007 == rowSelected[i].txtCol1007)
          if ((js[j].txtCol1007 == rowSelected[i].txtCol1007)  && (js[j].txtColContrct==rowSelected[i].txtColContrct) ) {
           //end of changes
            if (thisObj.values.ddRateBase == 'G') {
              js[j].txtColPremAmnt = (Math.round((((txtRate.value * rowSelected[i].txtColGross) / 2000) + Number.EPSILON)*100)/100).toFixed(2) 
              js[j].txtColPremRate = (txtRate.value + "/per Net")
              js[j].isSelected = true
              b = j + 1
              break;
            }
            else if (thisObj.values.ddRateBase == 'N') {
              js[j].txtColPremAmnt = (Math.round((((txtRate.value * rowSelected[i].txtColNetWt) / 2000) + Number.EPSILON)*100)/100).toFixed(2) 
              js[j].txtColPremRate = (txtRate.value + "/per Net")
              js[j].isSelected = true
              b = j + 1
              break;
            }
            else if (thisObj.values.ddRateBase == 'K') {
              js[j].txtColPremAmnt =(Math.round((((txtRate.value * ((rowSelected[i].txtColNetWt) - (rowSelected[i].txtColLskWt))) / 2000) + Number.EPSILON)*100)/100).toFixed(2)  
              js[j].txtColPremRate = (txtRate.value + "/per Net")
              js[j].isSelected = true
              b = j + 1
              break;
            }
            else if (thisObj.values.ddRateBase == 'B') {
              js[j].txtColPremAmnt =((Number(txtRate.value) / Number(rowSelected[i].support_per_ton)).toFixed(4) * Number(rowSelected[i].txtColBasisGrd)).toFixed(2)//For the Basic Grade Drop down Issue in calculating the premiums.
              js[j].txtColPremRate = (txtRate.value + "/per Net")
              js[j].isSelected = true
              b = j + 1
              break;
            }
            else if (thisObj.values.ddRateBase == 'L') {
              js[j].txtColPremAmnt =(Math.round(((Number(txtRate.value))+ Number.EPSILON)*100)/100).toFixed(2) 
              js[j].txtColPremRate = (txtRate.value + "/per Load")
              js[j].isSelected = true
              b = j + 1
              break;
            }
          }
            else {
              js[j].txtColPremAmnt == ""
            }
        }
        total1 += Number(js[j].txtColPremAmnt)
        total2 += Number(js[j].txtColPremAmnt)
        total3 += Number(js[j].txtColPremAmnt)
        total4 += Number(js[j].txtColPremAmnt)
        total5 = js['length'] * (Number(txtRate.value))
        support_per_ton = rowSelected[i].support_per_ton
      }
      setValue(thisObj, "gridAvail1007", js);
      setData(thisObj, 'gridAvail1007', js);//setting Data to pass to next screen
// Premium Amount Textbox value setting in TxtPremium Amount Textbox
      var premiumAmount = 0

      if (thisObj.values.ddRateBase == 'G') {
        let Gross_Ton =total1
        premiumAmount = Gross_Ton.toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2})
      }
      else if (thisObj.values.ddRateBase == 'N') {
        let Net_Ton = total2
        premiumAmount = Net_Ton.toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2})
      }
      else if (thisObj.values.ddRateBase == 'K') {
        let Lsk_Ton = total3
        premiumAmount = Lsk_Ton.toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2})
      }
      else if (thisObj.values.ddRateBase == 'B') {
        let Basic_Grade = total4
        premiumAmount = Basic_Grade.toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2})
      }
      else if (thisObj.values.ddRateBase == 'L') {
        let premiumAmountRate = (total5)
        premiumAmount= (premiumAmountRate).toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2})
      }
      setData(thisObj, 'txtPrem', premiumAmount)
      setValue(thisObj,"txtPremiumAmount",premiumAmount)
    }
  }

  // on Rate base change Premium Amount Changing
  const onddRateBaseChange = () => {
    try {
      var rowSelected = getData(thisObj, 'rowSelected')
      let rate = getValue(thisObj,"txtRate")
      if((rowSelected!=null&&rowSelected['length']!=0) && (!isNaN(rate) && rate != null && rate != undefined && rate !='')){//added the Additional Validation for the Rate textbox.
      PremiumAmtChange(rowSelected)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        // showMessage(
        //   thisObj,
        //   "Error occurred during Rate Base Change dropdown change event"
        // );
      }
      return false;
    }
    return true;
  };
  thisObj.onddRateBaseChange = onddRateBaseChange;


  //Transactrion_Num and Remove and Create ExceptionPremiumRequestDetails Api
  async function Transaction_Number(transaction_number) {
    setLoading(true);
    if (transaction_number != null) {
      let flag = getData(thisObj, "flag");
      var transaction_number = transaction_number
      var type = flag
      var user_id =userid
      var requested_by = txtReqBy.value
      var request_date_time;
      if(thisObj.values.txtDateReq==undefined){
        request_date_time =new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString()
      }
      else{
        request_date_time = new Date(new Date(thisObj.values.txtDateReq).getTime() - (new Date(thisObj.values.txtDateReq).getTimezoneOffset() * 60000)).toISOString()
      }
      var request_explanation = txtarExplanation.value

      let dataObj = {
        compId: compId,
        cropyear: parseInt(cropyear),
        transaction_number: transaction_number,
        type: type,
        user_id: user_id,
        requested_by: requested_by,
        request_date_time: request_date_time,
        request_explanation: request_explanation
      }
      let response = await ContractManagementService.CreateExceptionPremiumRequestDetails(dataObj)
    
      if(flag == "REJECT"){
        showMessage(thisObj,response.message)
        thisObj.values.gridAvail1007.forEach(row=> {row.isSelected =false;});
        onbtnSearchClick();
        setValue(thisObj,"txtRate",'')
        setValue(thisObj,"ddRateBase",'')
        setValue(thisObj,"txtarExplanation",'')
      }
      else{
        showMessage(thisObj, response.message, true)
        commonContext.setExceptPremCreate_MemoNum(response.result) 
        thisObj.values.gridAvail1007.forEach(row=> {row.isSelected =false;}); 
        onbtnSearchClick();
        setValue(thisObj,"txtRate",'')
        setValue(thisObj,"ddRateBase",'')
        setValue(thisObj,"txtarExplanation",'')
        goTo(thisObj, "ContractManagement#ReportPreviewExcPrem#DEFAULT#true#Click") // to restrict the navigation without creating the Memo Number to next screen.
      }
    }
    else {
      showMessage(thisObj, "Create request has failed!!! Please retry!!!", false)
    }
    setLoading(false);
  }
// Create ExceptionPremium Details Api and Getting Transaction Num
  async function CreateExcPrem(){
    try{
      setLoading(true);
      var data2 = getData(thisObj, 'frmReportPreviewExceptPrem')
      var trasnArr = []
    for( let i = 0; i < data2.length; i++){
      var buy_pt_id = getValue(thisObj, "ddLocation");
      var insp_num = data2[i].txtColInsp
      var settle_num = data2[i].txtCol1007;
      var prem_vendor =  txtVendor.value;
      var prem_code = getValue(thisObj, "ddPremiumCode");
      var prem_rate = Number(txtRate.value).toFixed(5);//Formatting the Rate to .00000 condition sending data to api.
      var prem_rate_base = getValue(thisObj, "ddRateBase");
      var contract_num = data2[i].txtColContrct
      var prem_remit = ""

      let dataObj = {
        buY_PT_ID: buy_pt_id,
        insp_num: insp_num,
        settle_num:settle_num ,
        prem_remit:prem_remit,
        prem_vendor:prem_vendor,
        prem_code:prem_code ,
        prem_rate:Number(prem_rate),//Not Needed if user is giving the float value getting issue to resolve that handled above.
        prem_rate_base: prem_rate_base ,
        contract_num: contract_num ,
        user_id: userid
      }
      var resp = await ContractManagementService.CreateExceptionPremium(dataObj);
      trasnArr.push(resp.result)
    }
    if(resp.status == 200){
      Transaction_Number((trasnArr)?.toString()) //Transaction_Number(resp.result) // Passing Transaction Numbers in String Format fixed
      return true;
    }
    setLoading(false);
  }
    catch(err){
      setLoading(false);
      return false;
    }
  }

  // User Details at Requested by TextBox and Requested Date
  const requestedBy = () => {
    var dateTime = moment(new Date()).format('MM/DD/YYYY hh:mm A');
    ContractManagementService.RetrieveUserControlDetails(userid)
      .then(response => {
      let data = response;
      let js = [];
      for (var i = 0; i < data.length; i++) {
        js.push(data[i].userName);
      }
      if(txtReqBy.value==''){
        setValue(thisObj, "txtReqBy", js[0]);
        }
        else{
          setValue(thisObj,"txtReqBy",txtReqBy.value);
        }
      if(thisObj.values.txtDateReq==='' || thisObj.values.txtDateReq=== undefined){
      setValue(thisObj, 'txtDateReq', dateTime)}
    })
  };

  // Button CreatePayReq functionality
  const onbtnCreatePayReqClick = async () => {
    try {
      var rowSelected = getData(thisObj, 'rowSelected')
      var data1 = getData(thisObj, 'gridAvail1007')
      if (txtRate.value == '' || txtRate.value == null || txtRate.value!=Number(txtRate.value)) {
        showMessage(thisObj, "Rate is invalid!!!")
      }
      else if (getValue(thisObj, "txtRate") > 9999.99999) {
        showMessage(thisObj, "Rate must be of format ####.#####")
      }
      else if (getValue(thisObj, "ddRateBase") == '') {
        showMessage(thisObj, "No Rate Base has been selected!!!")
      }
      else if (txtarExplanation.value == '') {
        requestedBy()
        showMessage(thisObj, "Some Explanation is required!!!")
      }
      else if (rowSelected === null || rowSelected['length']===0) {
        showMessage(thisObj, "At least one row must be checked for selection")
      }
      if (data1 !== null && rowSelected!=null && rowSelected['length']!=0 && txtRate.value!=''&& getValue(thisObj, "ddRateBase") != '' && txtarExplanation.value!='') {
        var js1 = []
        for (var i = 0; i < data1.length; i++) {
          if (data1[i].isSelected) {
            js1.push(data1[i])
          }
        }
        setData(thisObj, 'frmReportPreviewExceptPrem', js1) //Data pass to ReportPreviewExcPrem Screen
        requestedBy()
        CreateExcPrem();
        setData(thisObj, "flag", "CREATE");
        // goTo(thisObj, "ContractManagement#ReportPreviewExcPrem#DEFAULT#true#Click") //For Restricting it from navigation to next screen.
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCreatePayReq event:Click"
        );
      }
      return false;
    }
    //return true;
  }
  thisObj.onbtnCreatePayReqClick = onbtnCreatePayReqClick;

  // Button RejectPrem Functionality
  const onbtnRejectPremClick = async() => {
    try {
      var rowSelected = getData(thisObj, 'rowSelected')
      var data1 = getData(thisObj, 'gridAvail1007')
      if (txtRate.value == '' || txtRate.value == null || txtRate.value!=Number(txtRate.value)) {
        showMessage(thisObj, "Rate is invalid!!!")
      }
      else if (getValue(thisObj, "txtRate") > 9999.99999) {
        showMessage(thisObj, "Rate must be of format ####.#####")
      }
      else if (getValue(thisObj, "ddRateBase") == '') {
        showMessage(thisObj, "No Rate Base has been selected!!!")
      }
      else if (txtarExplanation.value == '') {
        requestedBy()
        showMessage(thisObj, "Some Explanation is required!!!")
      }
      else if (rowSelected === null || rowSelected['length']===0) {
        showMessage(thisObj, "At least one row must be checked for selection")
      }
      if (data1 !== null && rowSelected!=null && rowSelected['length']!=0 && txtRate.value!=''&& getValue(thisObj, "ddRateBase") != '' && txtarExplanation.value!='') {
        var js1 = []
        for (var i = 0; i < data1.length; i++) {
          if (data1[i].isSelected) {
            js1.push(data1[i])
          }
        }
        setData(thisObj, 'frmReportPreviewExceptPrem', js1) //Data pass to ReportPreviewExcPrem Screen
        requestedBy()
        CreateExcPrem();
        setData(thisObj, "flag", "REJECT");
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnRejectPrem event:Click"
        );
      }
      return false;
    }
    return true;

  }
  thisObj.onbtnRejectPremClick = onbtnRejectPremClick;

  const onbtnVendorClick = () => {
    try {
      if(thisObj.isValid!==true){
        goTo(thisObj,"ContractManagement#VendorLookup#DEFAULT#true#Click")

      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        // showMessage(
        //   thisObj,
        //   "Exception in PreSubmit Event code for widget:btnVendor event:Click"
        // );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnVendorClick = onbtnVendorClick;


  const onbtnExitClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      document.getElementById("ContractManagement_ExceptPremCreatePopUp").childNodes[0].click();
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        // showMessage(
        //   thisObj,
        //   "Exception in PreSubmit Event code for widget:btnExit event:Click"
        // );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnExitClick = onbtnExitClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} headerDetails={state.headerDetails} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row" onSubmit={handleSubmit}>
              {/* START_USER_CODE-USER_BEFORE_ExceptPremCreate*/}

              {/* END_USER_CODE-USER_BEFORE_ExceptPremCreate*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSearch*/}

              <GroupBoxWidget conf={state.grpbxSearch} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtLocation*/}

                {/* END_USER_CODE-USER_BEFORE_txtLocation*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLocation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_txtLocation*/}

                {/* END_USER_CODE-USER_AFTER_txtLocation*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtPeanuttype*/}

                {/* END_USER_CODE-USER_BEFORE_txtPeanuttype*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanuttype}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_txtPeanuttype*/}

                {/* END_USER_CODE-USER_AFTER_txtPeanuttype*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutVariety}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                {/* START_USER_CODE-USER_BEFORE_txtPeanutVariety*/}

                {/* END_USER_CODE-USER_BEFORE_txtPeanutVariety*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutVariety}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_txtPeanutVariety*/}

                {/* END_USER_CODE-USER_AFTER_txtPeanutVariety*/}
                {/* START_USER_CODE-USER_BEFORE_ddPremiumCode*/}

                {/* END_USER_CODE-USER_BEFORE_ddPremiumCode*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPremiumCode}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPremiumCode*/}

                {/* END_USER_CODE-USER_AFTER_ddPremiumCode*/}
                {/* START_USER_CODE-USER_BEFORE_txtPremiumCode*/}

                {/* END_USER_CODE-USER_BEFORE_txtPremiumCode*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPremiumCode}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_txtPremiumCode*/}

                {/* END_USER_CODE-USER_AFTER_txtPremiumCode*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeed*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeed*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeed}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeed*/}

                {/* END_USER_CODE-USER_AFTER_ddSeed*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeed*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeed*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeed}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_txtSeed*/}

                {/* END_USER_CODE-USER_AFTER_txtSeed*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarm*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarm*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarm*/}

                {/* END_USER_CODE-USER_AFTER_txtFarm*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarmSuffix}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarmSuffix*/}

                {/* END_USER_CODE-USER_AFTER_txtFarmSuffix*/}
                {/* START_USER_CODE-USER_BEFORE_ddGeneration*/}

                {/* END_USER_CODE-USER_BEFORE_ddGeneration*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddGeneration}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddGeneration*/}

                {/* END_USER_CODE-USER_AFTER_ddGeneration*/}
                {/* START_USER_CODE-USER_BEFORE_txtGeneration*/}

                {/* END_USER_CODE-USER_BEFORE_txtGeneration*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtGeneration}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_txtGeneration*/}

                {/* END_USER_CODE-USER_AFTER_txtGeneration*/}
                {/* START_USER_CODE-USER_BEFORE_txtContract*/}

                {/* END_USER_CODE-USER_BEFORE_txtContract*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContract*/}

                {/* END_USER_CODE-USER_AFTER_txtContract*/}
                {/* START_USER_CODE-USER_BEFORE_ddSegType*/}

                {/* END_USER_CODE-USER_BEFORE_ddSegType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSegType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSegType*/}

                {/* END_USER_CODE-USER_AFTER_ddSegType*/}
                {/* START_USER_CODE-USER_BEFORE_txtSegType*/}

                {/* END_USER_CODE-USER_BEFORE_txtSegType*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSegType}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_txtSegType*/}

                {/* END_USER_CODE-USER_AFTER_txtSegType*/}
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                {/* END_USER_CODE-USER_BEFORE_ddOleic*/}
                
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleic}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                {/* START_USER_CODE-USER_BEFORE_txtOleic*/}

                {/* END_USER_CODE-USER_BEFORE_txtOleic*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOleic}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_txtOleic*/}

                {/* END_USER_CODE-USER_AFTER_txtOleic*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSearch*/}
              {/* START_USER_CODE-USER_BEFORE_grpbx1007s*/}

              {/* END_USER_CODE-USER_BEFORE_grpbx1007s*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbx1007s} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_chkboxAll1007s*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxAll1007s*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAvailble}
                  screenConf={state}
                ></LabelWidget>

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxAll1007s}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxAll1007s*/}

                {/* END_USER_CODE-USER_AFTER_chkboxAll1007s*/}
                {/* <LabelWidget
                  values={values}
                  conf={state.lblBuyPtId}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblVendrNm}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblPremiumCode}
                  screenConf={state}
                ></LabelWidget> */}
                {/* START_USER_CODE-USER_BEFORE_txtarAvailable1007s*/}

                {/* END_USER_CODE-USER_BEFORE_txtarAvailable1007s*/}

                {/* <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarAvailable1007s}
                  screenConf={state}
                ></TextAreaWidget> */}
                <GridWidget
                conf={state.gridAvail1007}
                screenConf={state}
                linkClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                onRowChange={ selectedRows=> onGridRowChange(selectedRows)}
                errors={errors}
                touched={touched}
                rows={values.gridAvail1007}
                onEvent={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                // GridRowDoubleClicked={row=>rowDoubleClick(row)}
                setFieldValue={setFieldValue}
                // onRowChange={row=>rowChange(row)}
                refObject={thisObj}
              ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_txtarAvailable1007s*/}

                {/* END_USER_CODE-USER_AFTER_txtarAvailable1007s*/}
                
                {/* START_USER_CODE-USER_BEFORE_lblTotal*/}

                {/* END_USER_CODE-USER_BEFORE_lblTotal*/}

                <LabelWidget
                  values={values}
                  conf={state.lblTotal}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblTotal*/}

                {/* END_USER_CODE-USER_AFTER_lblTotal*/}
                {/* START_USER_CODE-USER_BEFORE_txtPremiumAmount*/}

                {/* END_USER_CODE-USER_BEFORE_txtPremiumAmount*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPremiumAmount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPremiumAmount*/}

                {/* END_USER_CODE-USER_AFTER_txtPremiumAmount*/}
                {/* START_USER_CODE-USER_BEFORE_txtGrossWT*/}

                {/* END_USER_CODE-USER_BEFORE_txtGrossWT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtGrossWT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtGrossWT*/}

                {/* END_USER_CODE-USER_AFTER_txtGrossWT*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetWT*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetWT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetWT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetWT*/}

                {/* END_USER_CODE-USER_AFTER_txtNetWT*/}
                {/* START_USER_CODE-USER_BEFORE_txtLSKWT*/}

                {/* END_USER_CODE-USER_BEFORE_txtLSKWT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSKWT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLSKWT*/}

                {/* END_USER_CODE-USER_AFTER_txtLSKWT*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbx1007s*/}

              {/* END_USER_CODE-USER_AFTER_grpbx1007s*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxRate*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxRate*/}

              <GroupBoxWidget conf={state.grpbxRate} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtIsRateBase*/}

                {/* END_USER_CODE-USER_BEFORE_txtIsRateBase*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtIsRateBase}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_txtIsRateBase*/}

                {/* END_USER_CODE-USER_AFTER_txtIsRateBase*/}
                {/* START_USER_CODE-USER_BEFORE_txtRate*/}

                {/* END_USER_CODE-USER_BEFORE_txtRate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj,ontxtRateBlur)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }

                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRate*/}

                {/* END_USER_CODE-USER_AFTER_txtRate*/}
                {/* START_USER_CODE-USER_BEFORE_ddRateBase*/}

                {/* END_USER_CODE-USER_BEFORE_ddRateBase*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRateBase}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRateBase*/}

                {/* END_USER_CODE-USER_AFTER_ddRateBase*/}
                {/* START_USER_CODE-USER_BEFORE_btnCrtPrem*/}

                {/* END_USER_CODE-USER_BEFORE_btnCrtPrem*/}

                {/* <ButtonWidget
                  conf={state.btnCrtPrem}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget> */}
                {/* START_USER_CODE-USER_AFTER_btnCrtPrem*/}

                {/* END_USER_CODE-USER_AFTER_btnCrtPrem*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxRate*/}

              {/* END_USER_CODE-USER_AFTER_grpbxRate*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxPayReq*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPayReq*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxPayReq} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtReqBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtReqBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtReqBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtReqBy*/}

                {/* END_USER_CODE-USER_AFTER_txtReqBy*/}
                {/* START_USER_CODE-USER_BEFORE_txtDateReq*/}

                {/* END_USER_CODE-USER_BEFORE_txtDateReq*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDateReq}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDateReq}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtDateReq*/}

                {/* END_USER_CODE-USER_AFTER_txtDateReq*/}
                {/* START_USER_CODE-USER_BEFORE_txtarExplanation*/}

                {/* END_USER_CODE-USER_BEFORE_txtarExplanation*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarExplanation}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarExplanation*/}

                {/* END_USER_CODE-USER_AFTER_txtarExplanation*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreatePayReq*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreatePayReq*/}

                <ButtonWidget
                  conf={state.btnCreatePayReq}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreatePayReq*/}

                {/* END_USER_CODE-USER_AFTER_btnCreatePayReq*/}
                {/* START_USER_CODE-USER_BEFORE_btnRejectPrem*/}

                {/* END_USER_CODE-USER_BEFORE_btnRejectPrem*/}

                <ButtonWidget
                  conf={state.btnRejectPrem}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnRejectPrem*/}

                {/* END_USER_CODE-USER_AFTER_btnRejectPrem*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPayReq*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPayReq*/}

              {/* START_USER_CODE-USER_AFTER_ExceptPremCreate*/}

              {/* END_USER_CODE-USER_AFTER_ExceptPremCreate*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ExceptPremCreate);
