/* eslint-disable*/
import React from "react";
import Settlements_NoSaleVoidFV95 from "./NoSaleVoidFV95";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("NoSaleVoidFV95 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_NoSaleVoidFV95 />);
    });
  });
  afterEach(cleanup);
  test("is NoSaleVoidFV95 Loads Successfully", () => {
    expect(screen.getByText("NoSaleVoidFV95")).toBeInTheDocument;
  });
  test("Custom Test Cases for NoSaleVoidFV95", () => {
    // START_USER_CODE-USER_NoSaleVoidFV95_Custom_Test_Case
    // END_USER_CODE-USER_NoSaleVoidFV95_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_NoSaleVoidFV95 />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("Settlements:NoSaleVoidFV95_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("Settlements:NoSaleVoidFV95_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("grpbxNoSaleVoidFV95(GroupBox Widget) Test Cases", async () => {
    const grpbxNoSaleVoidFV95 = screen.getByTestId("grpbxNoSaleVoidFV95");
    expect(grpbxNoSaleVoidFV95.tagName).toBe("BUTTON");
    expect(grpbxNoSaleVoidFV95.type).toBe("button");
    expect(grpbxNoSaleVoidFV95.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxNoSaleVoidFV95", () => {
    // START_USER_CODE-USER_grpbxNoSaleVoidFV95_TEST
    // END_USER_CODE-USER_grpbxNoSaleVoidFV95_TEST
  });
  test("txtSC95(Textbox Widget) Test Cases", async () => {
    const txtSC95 = screen.getByTestId("txtSC95");
    expect(txtSC95.tagName).toBe("INPUT");
    expect(txtSC95.type).toBe("text");
    expect(txtSC95.classList).toContain("textboxWidgetClass");
    expect(txtSC95.previousElementSibling.textContent).toEqual(
      t("Settlements:NoSaleVoidFV95_txtSC95")
    );
    await act(async () => {
      userEvent.type(txtSC95, "1");
    });
  });
  test("Custom Test Cases for txtSC95", () => {
    // START_USER_CODE-USER_txtSC95_TEST
    // END_USER_CODE-USER_txtSC95_TEST
  });
});
