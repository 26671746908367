/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_ReportUserSignatureTemplate from "./ReportUserSignatureTemplate";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ReportUserSignatureTemplate Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_ReportUserSignatureTemplate />
      );
    });
  });
  afterEach(cleanup);
  test("is ReportUserSignatureTemplate Loads Successfully", () => {
    expect(screen.getByText("ReportUserSignatureTemplate")).toBeInTheDocument;
  });
  test("Custom Test Cases for ReportUserSignatureTemplate", () => {
    // START_USER_CODE-USER_ReportUserSignatureTemplate_Custom_Test_Case
    // END_USER_CODE-USER_ReportUserSignatureTemplate_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_ReportUserSignatureTemplate />
      );
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:ReportUserSignatureTemplate_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btn2(Button Widget) Test Cases", async () => {
    const btn2 = screen.getByTestId("btn2");
    expect(btn2).toBeInTheDocument;
    expect(btn2.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:ReportUserSignatureTemplate_btn2")
    );
  });
  test("Custom Test Cases for btn2", () => {
    // START_USER_CODE-USER_btn2_TEST
    // END_USER_CODE-USER_btn2_TEST
  });
  test("btnCLose(Button Widget) Test Cases", async () => {
    const btnCLose = screen.getByTestId("btnCLose");
    expect(btnCLose).toBeInTheDocument;
    expect(btnCLose.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:ReportUserSignatureTemplate_btnCLose"
      )
    );
  });
  test("Custom Test Cases for btnCLose", () => {
    // START_USER_CODE-USER_btnCLose_TEST
    // END_USER_CODE-USER_btnCLose_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:ReportUserSignatureTemplate_btnPrint"
      )
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnPrintSetup(Button Widget) Test Cases", async () => {
    const btnPrintSetup = screen.getByTestId("btnPrintSetup");
    expect(btnPrintSetup).toBeInTheDocument;
    expect(btnPrintSetup.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:ReportUserSignatureTemplate_btnPrintSetup"
      )
    );
  });
  test("Custom Test Cases for btnPrintSetup", () => {
    // START_USER_CODE-USER_btnPrintSetup_TEST
    // END_USER_CODE-USER_btnPrintSetup_TEST
  });
  test("btnSaveAsPDF(Button Widget) Test Cases", async () => {
    const btnSaveAsPDF = screen.getByTestId("btnSaveAsPDF");
    expect(btnSaveAsPDF).toBeInTheDocument;
    expect(btnSaveAsPDF.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:ReportUserSignatureTemplate_btnSaveAsPDF"
      )
    );
  });
  test("Custom Test Cases for btnSaveAsPDF", () => {
    // START_USER_CODE-USER_btnSaveAsPDF_TEST
    // END_USER_CODE-USER_btnSaveAsPDF_TEST
  });
  test("grpbxUserSignatureTemplate(GroupBox Widget) Test Cases", async () => {
    const grpbxUserSignatureTemplate = screen.getByTestId(
      "grpbxUserSignatureTemplate"
    );
    expect(grpbxUserSignatureTemplate.tagName).toBe("BUTTON");
    expect(grpbxUserSignatureTemplate.type).toBe("button");
    expect(grpbxUserSignatureTemplate.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxUserSignatureTemplate", () => {
    // START_USER_CODE-USER_grpbxUserSignatureTemplate_TEST
    // END_USER_CODE-USER_grpbxUserSignatureTemplate_TEST
  });
});
