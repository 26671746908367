/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_Dashboard from "./Dashboard";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("Dashboard Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_Dashboard />);
    });
  });
  afterEach(cleanup);
  test("is Dashboard Loads Successfully", () => {
    expect(screen.getByText("Dashboard")).toBeInTheDocument;
  });
  test("Custom Test Cases for Dashboard", () => {
    // START_USER_CODE-USER_Dashboard_Custom_Test_Case
    // END_USER_CODE-USER_Dashboard_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_Dashboard />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:Dashboard_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:Dashboard_btnSave")
    );
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("grpbxDashboard(GroupBox Widget) Test Cases", async () => {
    const grpbxDashboard = screen.getByTestId("grpbxDashboard");
    expect(grpbxDashboard.tagName).toBe("BUTTON");
    expect(grpbxDashboard.type).toBe("button");
    expect(grpbxDashboard.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDashboard", () => {
    // START_USER_CODE-USER_grpbxDashboard_TEST
    // END_USER_CODE-USER_grpbxDashboard_TEST
  });
  test("grpbxPreview(GroupBox Widget) Test Cases", async () => {
    const grpbxPreview = screen.getByTestId("grpbxPreview");
    expect(grpbxPreview.tagName).toBe("BUTTON");
    expect(grpbxPreview.type).toBe("button");
    expect(grpbxPreview.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPreview", () => {
    // START_USER_CODE-USER_grpbxPreview_TEST
    // END_USER_CODE-USER_grpbxPreview_TEST
  });
});
