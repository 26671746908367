import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {getWidgetClasses, getFieldContentClasses, getFieldContentStyle, getTooltip, getVisibility, checkInvalid} from "../ParentWidgetFunc";
import { convertStringToJsonObject } from "../../../../common/ScreenInitialization";

function ListboxWidget (props) {
  let conf = props.conf;
  let screenConf = props.screenConf;

    if (!conf.valueList || !conf.valueList.length) {
      conf.valueList = convertStringToJsonObject(conf.Options);
    }
    let listboxControl = (
      <React.Fragment>
        <Form.Control
          as="select"
          multiple
          disabled={!conf.Enabled}
          readOnly={conf.ReadOnly}
          data-testid={conf.name}
          value={props.values[conf.name]}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onMouseMove={(e)=>e.preventDefault()}
          isInvalid={checkInvalid(props,conf)}
          style={{ backgroundPosition: "right calc(.85em + .1875rem) center" }}
        >
          {conf.valueList.map(option => (
            <option key={`${conf.name}${option.key}`} value={option.key}>
              {option.description}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid" aria-live="polite" role="alert">
          {props.errors[conf.name]}
        </Form.Control.Feedback>
      </React.Fragment>
    );

    return (
      
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
        title={getTooltip(conf)}
        role="tooltip"
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
          >
            {conf.Label}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>{listboxControl}</Col> 
            
          ) : ( 
            <React.Fragment> {listboxControl}</React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default ListboxWidget;
