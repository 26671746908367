import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {checkInvalid,getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility,getTooltip,getWidgetLabel} from "../ParentWidgetFunc";

function CheckboxWidget(props){
    let conf = props.conf;
    let screenConf = props.screenConf;
    let value = props.values[conf.name];

    let checkboxControl = (
      <div className="mb-3">
        <Form.Check
          disabled={!conf.Enabled}
          label={getWidgetLabel(conf)}
          type="checkbox"
          checked={value}
          name={conf.name}
          onChange={e => {
            let event = {
              type: "change",
              target: {
                name: conf.name,
                id: conf.name,
                value: !value
              }
            };
            props.onChange(event);
          }}
          onBlur={props.onBlur}
          isInvalid={checkInvalid(props,conf)}
          data-testid={conf.name}
        />
        <Form.Control.Feedback type="invalid" aria-live="polite" role="alert">
          {props.errors[conf.name]}
        </Form.Control.Feedback>
      </div>
    );
    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
		title={getTooltip(conf)}
		role="tooltip"
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
          >
            {conf.Label}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>{checkboxControl}</Col>
          ) : (
            <React.Fragment> {checkboxControl}</React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default CheckboxWidget;
