/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_DeliveryPeriodProfile from "./DeliveryPeriodProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DeliveryPeriodProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_DeliveryPeriodProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is DeliveryPeriodProfile Loads Successfully", () => {
    expect(screen.getByText("DeliveryPeriodProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for DeliveryPeriodProfile", () => {
    // START_USER_CODE-USER_DeliveryPeriodProfile_Custom_Test_Case
    // END_USER_CODE-USER_DeliveryPeriodProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_DeliveryPeriodProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:DeliveryPeriodProfile_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:DeliveryPeriodProfile_btnSave")
    );
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("grpbxDeliveryPeriodProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxDeliveryPeriodProfile = screen.getByTestId(
      "grpbxDeliveryPeriodProfile"
    );
    expect(grpbxDeliveryPeriodProfile.tagName).toBe("BUTTON");
    expect(grpbxDeliveryPeriodProfile.type).toBe("button");
    expect(grpbxDeliveryPeriodProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDeliveryPeriodProfile", () => {
    // START_USER_CODE-USER_grpbxDeliveryPeriodProfile_TEST
    // END_USER_CODE-USER_grpbxDeliveryPeriodProfile_TEST
  });
  test("txtArea(Textbox Widget) Test Cases", async () => {
    const txtArea = screen.getByTestId("txtArea");
    expect(txtArea.tagName).toBe("INPUT");
    expect(txtArea.type).toBe("text");
    expect(txtArea.classList).toContain("textboxWidgetClass");
    expect(txtArea.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:DeliveryPeriodProfile_txtArea")
    );
    await act(async () => {
      userEvent.type(txtArea, "1");
    });
  });
  test("Custom Test Cases for txtArea", () => {
    // START_USER_CODE-USER_txtArea_TEST
    // END_USER_CODE-USER_txtArea_TEST
  });
  test("txtDeliveryId(Textbox Widget) Test Cases", async () => {
    const txtDeliveryId = screen.getByTestId("txtDeliveryId");
    expect(txtDeliveryId.tagName).toBe("INPUT");
    expect(txtDeliveryId.type).toBe("text");
    expect(txtDeliveryId.classList).toContain("textboxWidgetClass");
    expect(txtDeliveryId.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:DeliveryPeriodProfile_txtDeliveryId")
    );
    await act(async () => {
      userEvent.type(txtDeliveryId, "1");
    });
  });
  test("Custom Test Cases for txtDeliveryId", () => {
    // START_USER_CODE-USER_txtDeliveryId_TEST
    // END_USER_CODE-USER_txtDeliveryId_TEST
  });
});
