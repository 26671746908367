/* eslint-disable*/
import React from "react";
import Settlements_TradeSettleApply from "./TradeSettleApply";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("TradeSettleApply Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettleApply />);
    });
  });
  afterEach(cleanup);
  test("is TradeSettleApply Loads Successfully", () => {
    expect(screen.getByText("TradeSettleApply")).toBeInTheDocument;
  });
  test("Custom Test Cases for TradeSettleApply", () => {
    // START_USER_CODE-USER_TradeSettleApply_Custom_Test_Case
    // END_USER_CODE-USER_TradeSettleApply_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettleApply />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("Settlements:TradeSettleApply_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(t("Settlements:TradeSettleApply_btnOk"));
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("gridAgreementApplication(Grid Widget) Test Cases", async () => {
    let gridAgreementApplication = screen.getByTestId(
      "gridAgreementApplication"
    );
    let gridAgreementApplicationbtn =
      gridAgreementApplication.nextElementSibling.firstElementChild;
    gridAgreementApplication =
      gridAgreementApplication.parentElement.parentElement.parentElement;
    expect(gridAgreementApplication.tagName).toBe("DIV");
    expect(gridAgreementApplication.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApply_gridAgreementApplication")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAgreementApplication", () => {
    // START_USER_CODE-USER_gridAgreementApplication_TEST
    // END_USER_CODE-USER_gridAgreementApplication_TEST
  });
  test("grpbxTradeAgrmntApp(GroupBox Widget) Test Cases", async () => {
    const grpbxTradeAgrmntApp = screen.getByTestId("grpbxTradeAgrmntApp");
    expect(grpbxTradeAgrmntApp.tagName).toBe("BUTTON");
    expect(grpbxTradeAgrmntApp.type).toBe("button");
    expect(grpbxTradeAgrmntApp.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTradeAgrmntApp", () => {
    // START_USER_CODE-USER_grpbxTradeAgrmntApp_TEST
    // END_USER_CODE-USER_grpbxTradeAgrmntApp_TEST
  });
  test("lblAgreementApplications(Label Widget) Test Cases", async () => {
    const lblAgreementApplications = screen.getByTestId(
      "lblAgreementApplications"
    );
    expect(lblAgreementApplications.tagName).toBe("LABEL");
    expect(lblAgreementApplications.classList).toContain("form-label");
    expect(lblAgreementApplications.textContent).toEqual(
      t("Settlements:TradeSettleApply_lblAgreementApplications")
    );
  });
  test("Custom Test Cases for lblAgreementApplications", () => {
    // START_USER_CODE-USER_lblAgreementApplications_TEST
    // END_USER_CODE-USER_lblAgreementApplications_TEST
  });
  test("txt1stVendorName(Textbox Widget) Test Cases", async () => {
    const txt1stVendorName = screen.getByTestId("txt1stVendorName");
    expect(txt1stVendorName.tagName).toBe("INPUT");
    expect(txt1stVendorName.type).toBe("text");
    expect(txt1stVendorName.classList).toContain("textboxWidgetClass");
    expect(txt1stVendorName.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApply_txt1stVendorName")
    );
    await act(async () => {
      userEvent.type(txt1stVendorName, "123");
    });
    expect(txt1stVendorName.getAttribute("value")).toBe("");
    expect(txt1stVendorName.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txt1stVendorName", () => {
    // START_USER_CODE-USER_txt1stVendorName_TEST
    // END_USER_CODE-USER_txt1stVendorName_TEST
  });
  test("gridAgreementApplication_txtcolAgree(Grid Widget) Test Cases", async () => {
    let gridAgreementApplication_txtcolAgree = screen.getByTestId(
      "gridAgreementApplication"
    );
    let gridAgreementApplication_txtcolAgreebtn =
      gridAgreementApplication_txtcolAgree.nextElementSibling.firstElementChild;
    gridAgreementApplication_txtcolAgree =
      gridAgreementApplication_txtcolAgree.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplication_txtcolAgree.tagName).toBe("DIV");
    expect(gridAgreementApplication_txtcolAgree.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApply_gridAgreementApplication")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgree", () => {
    // START_USER_CODE-USER_txtcolAgree_TEST
    // END_USER_CODE-USER_txtcolAgree_TEST
  });
  test("gridAgreementApplication_txtcolAgreeDate(Grid Widget) Test Cases", async () => {
    let gridAgreementApplication_txtcolAgreeDate = screen.getByTestId(
      "gridAgreementApplication"
    );
    let gridAgreementApplication_txtcolAgreeDatebtn =
      gridAgreementApplication_txtcolAgreeDate.nextElementSibling
        .firstElementChild;
    gridAgreementApplication_txtcolAgreeDate =
      gridAgreementApplication_txtcolAgreeDate.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplication_txtcolAgreeDate.tagName).toBe("DIV");
    expect(gridAgreementApplication_txtcolAgreeDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApply_gridAgreementApplication")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreeDate", () => {
    // START_USER_CODE-USER_txtcolAgreeDate_TEST
    // END_USER_CODE-USER_txtcolAgreeDate_TEST
  });
  test("gridAgreementApplication_txtcolAgreedLbs(Grid Widget) Test Cases", async () => {
    let gridAgreementApplication_txtcolAgreedLbs = screen.getByTestId(
      "gridAgreementApplication"
    );
    let gridAgreementApplication_txtcolAgreedLbsbtn =
      gridAgreementApplication_txtcolAgreedLbs.nextElementSibling
        .firstElementChild;
    gridAgreementApplication_txtcolAgreedLbs =
      gridAgreementApplication_txtcolAgreedLbs.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplication_txtcolAgreedLbs.tagName).toBe("DIV");
    expect(gridAgreementApplication_txtcolAgreedLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApply_gridAgreementApplication")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreedLbs", () => {
    // START_USER_CODE-USER_txtcolAgreedLbs_TEST
    // END_USER_CODE-USER_txtcolAgreedLbs_TEST
  });
  test("gridAgreementApplication_txtcolAgreedPrice(Grid Widget) Test Cases", async () => {
    let gridAgreementApplication_txtcolAgreedPrice = screen.getByTestId(
      "gridAgreementApplication"
    );
    let gridAgreementApplication_txtcolAgreedPricebtn =
      gridAgreementApplication_txtcolAgreedPrice.nextElementSibling
        .firstElementChild;
    gridAgreementApplication_txtcolAgreedPrice =
      gridAgreementApplication_txtcolAgreedPrice.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplication_txtcolAgreedPrice.tagName).toBe("DIV");
    expect(gridAgreementApplication_txtcolAgreedPrice.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApply_gridAgreementApplication")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreedPrice", () => {
    // START_USER_CODE-USER_txtcolAgreedPrice_TEST
    // END_USER_CODE-USER_txtcolAgreedPrice_TEST
  });
  test("gridAgreementApplication_txtcolAppliedLbs(Grid Widget) Test Cases", async () => {
    let gridAgreementApplication_txtcolAppliedLbs = screen.getByTestId(
      "gridAgreementApplication"
    );
    let gridAgreementApplication_txtcolAppliedLbsbtn =
      gridAgreementApplication_txtcolAppliedLbs.nextElementSibling
        .firstElementChild;
    gridAgreementApplication_txtcolAppliedLbs =
      gridAgreementApplication_txtcolAppliedLbs.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplication_txtcolAppliedLbs.tagName).toBe("DIV");
    expect(gridAgreementApplication_txtcolAppliedLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApply_gridAgreementApplication")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAppliedLbs", () => {
    // START_USER_CODE-USER_txtcolAppliedLbs_TEST
    // END_USER_CODE-USER_txtcolAppliedLbs_TEST
  });
  test("gridAgreementApplication_txtcolFirmBasisFlat(Grid Widget) Test Cases", async () => {
    let gridAgreementApplication_txtcolFirmBasisFlat = screen.getByTestId(
      "gridAgreementApplication"
    );
    let gridAgreementApplication_txtcolFirmBasisFlatbtn =
      gridAgreementApplication_txtcolFirmBasisFlat.nextElementSibling
        .firstElementChild;
    gridAgreementApplication_txtcolFirmBasisFlat =
      gridAgreementApplication_txtcolFirmBasisFlat.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplication_txtcolFirmBasisFlat.tagName).toBe("DIV");
    expect(gridAgreementApplication_txtcolFirmBasisFlat.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApply_gridAgreementApplication")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFirmBasisFlat", () => {
    // START_USER_CODE-USER_txtcolFirmBasisFlat_TEST
    // END_USER_CODE-USER_txtcolFirmBasisFlat_TEST
  });
  test("gridAgreementApplication_txtcolLbsToApply(Grid Widget) Test Cases", async () => {
    let gridAgreementApplication_txtcolLbsToApply = screen.getByTestId(
      "gridAgreementApplication"
    );
    let gridAgreementApplication_txtcolLbsToApplybtn =
      gridAgreementApplication_txtcolLbsToApply.nextElementSibling
        .firstElementChild;
    gridAgreementApplication_txtcolLbsToApply =
      gridAgreementApplication_txtcolLbsToApply.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplication_txtcolLbsToApply.tagName).toBe("DIV");
    expect(gridAgreementApplication_txtcolLbsToApply.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApply_gridAgreementApplication")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLbsToApply", () => {
    // START_USER_CODE-USER_txtcolLbsToApply_TEST
    // END_USER_CODE-USER_txtcolLbsToApply_TEST
  });
  test("gridAgreementApplication_txtcolMethod(Grid Widget) Test Cases", async () => {
    let gridAgreementApplication_txtcolMethod = screen.getByTestId(
      "gridAgreementApplication"
    );
    let gridAgreementApplication_txtcolMethodbtn =
      gridAgreementApplication_txtcolMethod.nextElementSibling
        .firstElementChild;
    gridAgreementApplication_txtcolMethod =
      gridAgreementApplication_txtcolMethod.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplication_txtcolMethod.tagName).toBe("DIV");
    expect(gridAgreementApplication_txtcolMethod.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApply_gridAgreementApplication")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMethod", () => {
    // START_USER_CODE-USER_txtcolMethod_TEST
    // END_USER_CODE-USER_txtcolMethod_TEST
  });
  test("gridAgreementApplication_txtcolOpenLbs(Grid Widget) Test Cases", async () => {
    let gridAgreementApplication_txtcolOpenLbs = screen.getByTestId(
      "gridAgreementApplication"
    );
    let gridAgreementApplication_txtcolOpenLbsbtn =
      gridAgreementApplication_txtcolOpenLbs.nextElementSibling
        .firstElementChild;
    gridAgreementApplication_txtcolOpenLbs =
      gridAgreementApplication_txtcolOpenLbs.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplication_txtcolOpenLbs.tagName).toBe("DIV");
    expect(gridAgreementApplication_txtcolOpenLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApply_gridAgreementApplication")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpenLbs", () => {
    // START_USER_CODE-USER_txtcolOpenLbs_TEST
    // END_USER_CODE-USER_txtcolOpenLbs_TEST
  });
  test("gridAgreementApplication_txtcolOrder(Grid Widget) Test Cases", async () => {
    let gridAgreementApplication_txtcolOrder = screen.getByTestId(
      "gridAgreementApplication"
    );
    let gridAgreementApplication_txtcolOrderbtn =
      gridAgreementApplication_txtcolOrder.nextElementSibling.firstElementChild;
    gridAgreementApplication_txtcolOrder =
      gridAgreementApplication_txtcolOrder.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplication_txtcolOrder.tagName).toBe("DIV");
    expect(gridAgreementApplication_txtcolOrder.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApply_gridAgreementApplication")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOrder", () => {
    // START_USER_CODE-USER_txtcolOrder_TEST
    // END_USER_CODE-USER_txtcolOrder_TEST
  });
  test("gridAgreementApplication_txtcolPnutVar(Grid Widget) Test Cases", async () => {
    let gridAgreementApplication_txtcolPnutVar = screen.getByTestId(
      "gridAgreementApplication"
    );
    let gridAgreementApplication_txtcolPnutVarbtn =
      gridAgreementApplication_txtcolPnutVar.nextElementSibling
        .firstElementChild;
    gridAgreementApplication_txtcolPnutVar =
      gridAgreementApplication_txtcolPnutVar.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplication_txtcolPnutVar.tagName).toBe("DIV");
    expect(gridAgreementApplication_txtcolPnutVar.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApply_gridAgreementApplication")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutVar", () => {
    // START_USER_CODE-USER_txtcolPnutVar_TEST
    // END_USER_CODE-USER_txtcolPnutVar_TEST
  });
  test("gridAgreementApplication_txtcolSeedGen(Grid Widget) Test Cases", async () => {
    let gridAgreementApplication_txtcolSeedGen = screen.getByTestId(
      "gridAgreementApplication"
    );
    let gridAgreementApplication_txtcolSeedGenbtn =
      gridAgreementApplication_txtcolSeedGen.nextElementSibling
        .firstElementChild;
    gridAgreementApplication_txtcolSeedGen =
      gridAgreementApplication_txtcolSeedGen.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplication_txtcolSeedGen.tagName).toBe("DIV");
    expect(gridAgreementApplication_txtcolSeedGen.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApply_gridAgreementApplication")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedGen", () => {
    // START_USER_CODE-USER_txtcolSeedGen_TEST
    // END_USER_CODE-USER_txtcolSeedGen_TEST
  });
  test("txtLoanRepaymentRate(Textbox Widget) Test Cases", async () => {
    const txtLoanRepaymentRate = screen.getByTestId("txtLoanRepaymentRate");
    expect(txtLoanRepaymentRate.tagName).toBe("INPUT");
    expect(txtLoanRepaymentRate.type).toBe("text");
    expect(txtLoanRepaymentRate.classList).toContain("textboxWidgetClass");
    expect(txtLoanRepaymentRate.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApply_txtLoanRepaymentRate")
    );
    await act(async () => {
      userEvent.type(txtLoanRepaymentRate, "1");
    });
  });
  test("Custom Test Cases for txtLoanRepaymentRate", () => {
    // START_USER_CODE-USER_txtLoanRepaymentRate_TEST
    // END_USER_CODE-USER_txtLoanRepaymentRate_TEST
  });
  test("txtNetWeight(Textbox Widget) Test Cases", async () => {
    const txtNetWeight = screen.getByTestId("txtNetWeight");
    expect(txtNetWeight.tagName).toBe("INPUT");
    expect(txtNetWeight.type).toBe("text");
    expect(txtNetWeight.classList).toContain("textboxWidgetClass");
    expect(txtNetWeight.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApply_txtNetWeight")
    );
    await act(async () => {
      userEvent.type(txtNetWeight, "123");
    });
    expect(txtNetWeight.getAttribute("value")).toBe("");
    expect(txtNetWeight.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetWeight", () => {
    // START_USER_CODE-USER_txtNetWeight_TEST
    // END_USER_CODE-USER_txtNetWeight_TEST
  });
  test("txtPeanutType(Textbox Widget) Test Cases", async () => {
    const txtPeanutType = screen.getByTestId("txtPeanutType");
    expect(txtPeanutType.tagName).toBe("INPUT");
    expect(txtPeanutType.type).toBe("text");
    expect(txtPeanutType.classList).toContain("textboxWidgetClass");
    expect(txtPeanutType.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApply_txtPeanutType")
    );
    await act(async () => {
      userEvent.type(txtPeanutType, "123");
    });
    expect(txtPeanutType.getAttribute("value")).toBe("");
    expect(txtPeanutType.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
  test("txtPurchaseSale(Textbox Widget) Test Cases", async () => {
    const txtPurchaseSale = screen.getByTestId("txtPurchaseSale");
    expect(txtPurchaseSale.tagName).toBe("INPUT");
    expect(txtPurchaseSale.type).toBe("text");
    expect(txtPurchaseSale.classList).toContain("textboxWidgetClass");
    expect(txtPurchaseSale.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApply_txtPurchaseSale")
    );
    await act(async () => {
      userEvent.type(txtPurchaseSale, "123");
    });
    expect(txtPurchaseSale.getAttribute("value")).toBe("");
    expect(txtPurchaseSale.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPurchaseSale", () => {
    // START_USER_CODE-USER_txtPurchaseSale_TEST
    // END_USER_CODE-USER_txtPurchaseSale_TEST
  });
  test("txtSeedYN(Textbox Widget) Test Cases", async () => {
    const txtSeedYN = screen.getByTestId("txtSeedYN");
    expect(txtSeedYN.tagName).toBe("INPUT");
    expect(txtSeedYN.type).toBe("text");
    expect(txtSeedYN.classList).toContain("textboxWidgetClass");
    expect(txtSeedYN.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApply_txtSeedYN")
    );
    await act(async () => {
      userEvent.type(txtSeedYN, "123");
    });
    expect(txtSeedYN.getAttribute("value")).toBe("");
    expect(txtSeedYN.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeedYN", () => {
    // START_USER_CODE-USER_txtSeedYN_TEST
    // END_USER_CODE-USER_txtSeedYN_TEST
  });
  test("txtSeg(Textbox Widget) Test Cases", async () => {
    const txtSeg = screen.getByTestId("txtSeg");
    expect(txtSeg.tagName).toBe("INPUT");
    expect(txtSeg.type).toBe("text");
    expect(txtSeg.classList).toContain("textboxWidgetClass");
    expect(txtSeg.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApply_txtSeg")
    );
    await act(async () => {
      userEvent.type(txtSeg, "123");
    });
    expect(txtSeg.getAttribute("value")).toBe("");
    expect(txtSeg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeg", () => {
    // START_USER_CODE-USER_txtSeg_TEST
    // END_USER_CODE-USER_txtSeg_TEST
  });
  test("txtTradeInspect(Textbox Widget) Test Cases", async () => {
    const txtTradeInspect = screen.getByTestId("txtTradeInspect");
    expect(txtTradeInspect.tagName).toBe("INPUT");
    expect(txtTradeInspect.type).toBe("text");
    expect(txtTradeInspect.classList).toContain("textboxWidgetClass");
    expect(txtTradeInspect.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApply_txtTradeInspect")
    );
    await act(async () => {
      userEvent.type(txtTradeInspect, "123");
    });
    expect(txtTradeInspect.getAttribute("value")).toBe("");
    expect(txtTradeInspect.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTradeInspect", () => {
    // START_USER_CODE-USER_txtTradeInspect_TEST
    // END_USER_CODE-USER_txtTradeInspect_TEST
  });
  test("txtValue(Textbox Widget) Test Cases", async () => {
    const txtValue = screen.getByTestId("txtValue");
    expect(txtValue.tagName).toBe("INPUT");
    expect(txtValue.type).toBe("text");
    expect(txtValue.classList).toContain("textboxWidgetClass");
    expect(txtValue.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApply_txtValue")
    );
    await act(async () => {
      userEvent.type(txtValue, "123");
    });
    expect(txtValue.getAttribute("value")).toBe("");
    expect(txtValue.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtValue", () => {
    // START_USER_CODE-USER_txtValue_TEST
    // END_USER_CODE-USER_txtValue_TEST
  });
});
