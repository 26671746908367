/* eslint-disable*/
import React from "react";
import WarehouseReceipts_EWRListUpdate from "./EWRListUpdate";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("EWRListUpdate Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_EWRListUpdate />);
    });
  });
  afterEach(cleanup);
  test("is EWRListUpdate Loads Successfully", () => {
    expect(screen.getByText("EWRListUpdate")).toBeInTheDocument;
  });
  test("Custom Test Cases for EWRListUpdate", () => {
    // START_USER_CODE-USER_EWRListUpdate_Custom_Test_Case
    // END_USER_CODE-USER_EWRListUpdate_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_EWRListUpdate />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:EWRListUpdate_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("WarehouseReceipts:EWRListUpdate_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("gridEWRList(Grid Widget) Test Cases", async () => {
    let gridEWRList = screen.getByTestId("gridEWRList");
    let gridEWRListbtn = gridEWRList.nextElementSibling.firstElementChild;
    gridEWRList = gridEWRList.parentElement.parentElement.parentElement;
    expect(gridEWRList.tagName).toBe("DIV");
    expect(gridEWRList.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRListUpdate_gridEWRList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridEWRList", () => {
    // START_USER_CODE-USER_gridEWRList_TEST
    // END_USER_CODE-USER_gridEWRList_TEST
  });
  test("grpbxEWRListUpdate(GroupBox Widget) Test Cases", async () => {
    const grpbxEWRListUpdate = screen.getByTestId("grpbxEWRListUpdate");
    expect(grpbxEWRListUpdate.tagName).toBe("BUTTON");
    expect(grpbxEWRListUpdate.type).toBe("button");
    expect(grpbxEWRListUpdate.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxEWRListUpdate", () => {
    // START_USER_CODE-USER_grpbxEWRListUpdate_TEST
    // END_USER_CODE-USER_grpbxEWRListUpdate_TEST
  });
  test("grpbxSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxSearch = screen.getByTestId("grpbxSearch");
    expect(grpbxSearch.tagName).toBe("BUTTON");
    expect(grpbxSearch.type).toBe("button");
    expect(grpbxSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSearch", () => {
    // START_USER_CODE-USER_grpbxSearch_TEST
    // END_USER_CODE-USER_grpbxSearch_TEST
  });
  test("txtActionCode(Textbox Widget) Test Cases", async () => {
    const txtActionCode = screen.getByTestId("txtActionCode");
    expect(txtActionCode.tagName).toBe("INPUT");
    expect(txtActionCode.type).toBe("text");
    expect(txtActionCode.classList).toContain("textboxWidgetClass");
    expect(txtActionCode.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRListUpdate_txtActionCode")
    );
    await act(async () => {
      userEvent.type(txtActionCode, "1");
    });
  });
  test("Custom Test Cases for txtActionCode", () => {
    // START_USER_CODE-USER_txtActionCode_TEST
    // END_USER_CODE-USER_txtActionCode_TEST
  });
  test("gridEWRList_txtcolAddDate(Grid Widget) Test Cases", async () => {
    let gridEWRList_txtcolAddDate = screen.getByTestId("gridEWRList");
    let gridEWRList_txtcolAddDatebtn =
      gridEWRList_txtcolAddDate.nextElementSibling.firstElementChild;
    gridEWRList_txtcolAddDate =
      gridEWRList_txtcolAddDate.parentElement.parentElement.parentElement;
    expect(gridEWRList_txtcolAddDate.tagName).toBe("DIV");
    expect(gridEWRList_txtcolAddDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRListUpdate_gridEWRList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAddDate", () => {
    // START_USER_CODE-USER_txtcolAddDate_TEST
    // END_USER_CODE-USER_txtcolAddDate_TEST
  });
  test("gridEWRList_txtcolAddUser(Grid Widget) Test Cases", async () => {
    let gridEWRList_txtcolAddUser = screen.getByTestId("gridEWRList");
    let gridEWRList_txtcolAddUserbtn =
      gridEWRList_txtcolAddUser.nextElementSibling.firstElementChild;
    gridEWRList_txtcolAddUser =
      gridEWRList_txtcolAddUser.parentElement.parentElement.parentElement;
    expect(gridEWRList_txtcolAddUser.tagName).toBe("DIV");
    expect(gridEWRList_txtcolAddUser.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRListUpdate_gridEWRList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAddUser", () => {
    // START_USER_CODE-USER_txtcolAddUser_TEST
    // END_USER_CODE-USER_txtcolAddUser_TEST
  });
  test("gridEWRList_txtcolCode(Grid Widget) Test Cases", async () => {
    let gridEWRList_txtcolCode = screen.getByTestId("gridEWRList");
    let gridEWRList_txtcolCodebtn =
      gridEWRList_txtcolCode.nextElementSibling.firstElementChild;
    gridEWRList_txtcolCode =
      gridEWRList_txtcolCode.parentElement.parentElement.parentElement;
    expect(gridEWRList_txtcolCode.tagName).toBe("DIV");
    expect(gridEWRList_txtcolCode.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRListUpdate_gridEWRList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCode", () => {
    // START_USER_CODE-USER_txtcolCode_TEST
    // END_USER_CODE-USER_txtcolCode_TEST
  });
  test("gridEWRList_txtcolErrorMessage(Grid Widget) Test Cases", async () => {
    let gridEWRList_txtcolErrorMessage = screen.getByTestId("gridEWRList");
    let gridEWRList_txtcolErrorMessagebtn =
      gridEWRList_txtcolErrorMessage.nextElementSibling.firstElementChild;
    gridEWRList_txtcolErrorMessage =
      gridEWRList_txtcolErrorMessage.parentElement.parentElement.parentElement;
    expect(gridEWRList_txtcolErrorMessage.tagName).toBe("DIV");
    expect(gridEWRList_txtcolErrorMessage.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRListUpdate_gridEWRList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolErrorMessage", () => {
    // START_USER_CODE-USER_txtcolErrorMessage_TEST
    // END_USER_CODE-USER_txtcolErrorMessage_TEST
  });
  test("gridEWRList_txtcolErrorNum(Grid Widget) Test Cases", async () => {
    let gridEWRList_txtcolErrorNum = screen.getByTestId("gridEWRList");
    let gridEWRList_txtcolErrorNumbtn =
      gridEWRList_txtcolErrorNum.nextElementSibling.firstElementChild;
    gridEWRList_txtcolErrorNum =
      gridEWRList_txtcolErrorNum.parentElement.parentElement.parentElement;
    expect(gridEWRList_txtcolErrorNum.tagName).toBe("DIV");
    expect(gridEWRList_txtcolErrorNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRListUpdate_gridEWRList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolErrorNum", () => {
    // START_USER_CODE-USER_txtcolErrorNum_TEST
    // END_USER_CODE-USER_txtcolErrorNum_TEST
  });
  test("gridEWRList_txtcolFromHolder(Grid Widget) Test Cases", async () => {
    let gridEWRList_txtcolFromHolder = screen.getByTestId("gridEWRList");
    let gridEWRList_txtcolFromHolderbtn =
      gridEWRList_txtcolFromHolder.nextElementSibling.firstElementChild;
    gridEWRList_txtcolFromHolder =
      gridEWRList_txtcolFromHolder.parentElement.parentElement.parentElement;
    expect(gridEWRList_txtcolFromHolder.tagName).toBe("DIV");
    expect(gridEWRList_txtcolFromHolder.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRListUpdate_gridEWRList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFromHolder", () => {
    // START_USER_CODE-USER_txtcolFromHolder_TEST
    // END_USER_CODE-USER_txtcolFromHolder_TEST
  });
  test("gridEWRList_txtcolID(Grid Widget) Test Cases", async () => {
    let gridEWRList_txtcolID = screen.getByTestId("gridEWRList");
    let gridEWRList_txtcolIDbtn =
      gridEWRList_txtcolID.nextElementSibling.firstElementChild;
    gridEWRList_txtcolID =
      gridEWRList_txtcolID.parentElement.parentElement.parentElement;
    expect(gridEWRList_txtcolID.tagName).toBe("DIV");
    expect(gridEWRList_txtcolID.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRListUpdate_gridEWRList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolID", () => {
    // START_USER_CODE-USER_txtcolID_TEST
    // END_USER_CODE-USER_txtcolID_TEST
  });
  test("gridEWRList_txtcolRcptNum(Grid Widget) Test Cases", async () => {
    let gridEWRList_txtcolRcptNum = screen.getByTestId("gridEWRList");
    let gridEWRList_txtcolRcptNumbtn =
      gridEWRList_txtcolRcptNum.nextElementSibling.firstElementChild;
    gridEWRList_txtcolRcptNum =
      gridEWRList_txtcolRcptNum.parentElement.parentElement.parentElement;
    expect(gridEWRList_txtcolRcptNum.tagName).toBe("DIV");
    expect(gridEWRList_txtcolRcptNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRListUpdate_gridEWRList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRcptNum", () => {
    // START_USER_CODE-USER_txtcolRcptNum_TEST
    // END_USER_CODE-USER_txtcolRcptNum_TEST
  });
  test("gridEWRList_txtcolStatus(Grid Widget) Test Cases", async () => {
    let gridEWRList_txtcolStatus = screen.getByTestId("gridEWRList");
    let gridEWRList_txtcolStatusbtn =
      gridEWRList_txtcolStatus.nextElementSibling.firstElementChild;
    gridEWRList_txtcolStatus =
      gridEWRList_txtcolStatus.parentElement.parentElement.parentElement;
    expect(gridEWRList_txtcolStatus.tagName).toBe("DIV");
    expect(gridEWRList_txtcolStatus.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRListUpdate_gridEWRList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStatus", () => {
    // START_USER_CODE-USER_txtcolStatus_TEST
    // END_USER_CODE-USER_txtcolStatus_TEST
  });
  test("gridEWRList_txtcolToHolder(Grid Widget) Test Cases", async () => {
    let gridEWRList_txtcolToHolder = screen.getByTestId("gridEWRList");
    let gridEWRList_txtcolToHolderbtn =
      gridEWRList_txtcolToHolder.nextElementSibling.firstElementChild;
    gridEWRList_txtcolToHolder =
      gridEWRList_txtcolToHolder.parentElement.parentElement.parentElement;
    expect(gridEWRList_txtcolToHolder.tagName).toBe("DIV");
    expect(gridEWRList_txtcolToHolder.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRListUpdate_gridEWRList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolToHolder", () => {
    // START_USER_CODE-USER_txtcolToHolder_TEST
    // END_USER_CODE-USER_txtcolToHolder_TEST
  });
  test("gridEWRList_txtcolToWhse(Grid Widget) Test Cases", async () => {
    let gridEWRList_txtcolToWhse = screen.getByTestId("gridEWRList");
    let gridEWRList_txtcolToWhsebtn =
      gridEWRList_txtcolToWhse.nextElementSibling.firstElementChild;
    gridEWRList_txtcolToWhse =
      gridEWRList_txtcolToWhse.parentElement.parentElement.parentElement;
    expect(gridEWRList_txtcolToWhse.tagName).toBe("DIV");
    expect(gridEWRList_txtcolToWhse.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRListUpdate_gridEWRList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolToWhse", () => {
    // START_USER_CODE-USER_txtcolToWhse_TEST
    // END_USER_CODE-USER_txtcolToWhse_TEST
  });
  test("gridEWRList_txtcolUploadFile(Grid Widget) Test Cases", async () => {
    let gridEWRList_txtcolUploadFile = screen.getByTestId("gridEWRList");
    let gridEWRList_txtcolUploadFilebtn =
      gridEWRList_txtcolUploadFile.nextElementSibling.firstElementChild;
    gridEWRList_txtcolUploadFile =
      gridEWRList_txtcolUploadFile.parentElement.parentElement.parentElement;
    expect(gridEWRList_txtcolUploadFile.tagName).toBe("DIV");
    expect(gridEWRList_txtcolUploadFile.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRListUpdate_gridEWRList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUploadFile", () => {
    // START_USER_CODE-USER_txtcolUploadFile_TEST
    // END_USER_CODE-USER_txtcolUploadFile_TEST
  });
  test("txtFromHolderID(Textbox Widget) Test Cases", async () => {
    const txtFromHolderID = screen.getByTestId("txtFromHolderID");
    expect(txtFromHolderID.tagName).toBe("INPUT");
    expect(txtFromHolderID.type).toBe("text");
    expect(txtFromHolderID.classList).toContain("textboxWidgetClass");
    expect(txtFromHolderID.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRListUpdate_txtFromHolderID")
    );
    await act(async () => {
      userEvent.type(txtFromHolderID, "1");
    });
  });
  test("Custom Test Cases for txtFromHolderID", () => {
    // START_USER_CODE-USER_txtFromHolderID_TEST
    // END_USER_CODE-USER_txtFromHolderID_TEST
  });
  test("txtReceiptNumber(Textbox Widget) Test Cases", async () => {
    const txtReceiptNumber = screen.getByTestId("txtReceiptNumber");
    expect(txtReceiptNumber.tagName).toBe("INPUT");
    expect(txtReceiptNumber.type).toBe("text");
    expect(txtReceiptNumber.classList).toContain("textboxWidgetClass");
    expect(txtReceiptNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRListUpdate_txtReceiptNumber")
    );
    await act(async () => {
      userEvent.type(txtReceiptNumber, "1");
    });
  });
  test("Custom Test Cases for txtReceiptNumber", () => {
    // START_USER_CODE-USER_txtReceiptNumber_TEST
    // END_USER_CODE-USER_txtReceiptNumber_TEST
  });
  test("txtToHolderID(Textbox Widget) Test Cases", async () => {
    const txtToHolderID = screen.getByTestId("txtToHolderID");
    expect(txtToHolderID.tagName).toBe("INPUT");
    expect(txtToHolderID.type).toBe("text");
    expect(txtToHolderID.classList).toContain("textboxWidgetClass");
    expect(txtToHolderID.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRListUpdate_txtToHolderID")
    );
    await act(async () => {
      userEvent.type(txtToHolderID, "1");
    });
  });
  test("Custom Test Cases for txtToHolderID", () => {
    // START_USER_CODE-USER_txtToHolderID_TEST
    // END_USER_CODE-USER_txtToHolderID_TEST
  });
  test("txtToWarehouseCode(Textbox Widget) Test Cases", async () => {
    const txtToWarehouseCode = screen.getByTestId("txtToWarehouseCode");
    expect(txtToWarehouseCode.tagName).toBe("INPUT");
    expect(txtToWarehouseCode.type).toBe("text");
    expect(txtToWarehouseCode.classList).toContain("textboxWidgetClass");
    expect(txtToWarehouseCode.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRListUpdate_txtToWarehouseCode")
    );
    await act(async () => {
      userEvent.type(txtToWarehouseCode, "1");
    });
  });
  test("Custom Test Cases for txtToWarehouseCode", () => {
    // START_USER_CODE-USER_txtToWarehouseCode_TEST
    // END_USER_CODE-USER_txtToWarehouseCode_TEST
  });
});
