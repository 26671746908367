/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
  goTo,
  hide,
  show
} from "../../shared/WindowImports";

import "./DeductionTrackingDetail.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_DeductionTrackingDetail(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIE
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let buyingPointId = JSON.parse(sessionStorage.getItem('lstBuyingPointID'))
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DeductionTrackingDetail",
    windowName: "DeductionTrackingDetail",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.DeductionTrackingDetail",
    // START_USER_CODE-USER_DeductionTrackingDetail_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Deduction Tracking Detail",
      scrCode: "PN0160F",
    },
    // END_USER_CODE-USER_DeductionTrackingDetail_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    colAmountCharged: {
      name: "colAmountCharged",
      type: "GridColumnWidget",
      parent: "gridDeductionTrackingDetail",
      Label: "Amount Charged",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmountCharged_PROPERTIES

      // END_USER_CODE-USER_colAmountCharged_PROPERTIES
    },
    colAmountCollected: {
      name: "colAmountCollected",
      type: "GridColumnWidget",
      parent: "gridDeductionTrackingDetail",
      Label: "Amount Collected",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmountCollected_PROPERTIES

      // END_USER_CODE-USER_colAmountCollected_PROPERTIES
    },
    colChargeToID: {
      name: "colChargeToID",
      type: "GridColumnWidget",
      parent: "gridDeductionTrackingDetail",
      Label: "Charge to ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChargeToID_PROPERTIES

      // END_USER_CODE-USER_colChargeToID_PROPERTIES
    },
    colChargeToType: {
      name: "colChargeToType",
      type: "GridColumnWidget",
      parent: "gridDeductionTrackingDetail",
      Label: "Charge o Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChargeToType_PROPERTIES

      // END_USER_CODE-USER_colChargeToType_PROPERTIES
    },
    colCollectedForID: {
      name: "colCollectedForID",
      type: "GridColumnWidget",
      parent: "gridDeductionTrackingDetail",
      Label: "Collected for ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCollectedForID_PROPERTIES

      // END_USER_CODE-USER_colCollectedForID_PROPERTIES
    },
    colCollectedForType: {
      name: "colCollectedForType",
      type: "GridColumnWidget",
      parent: "gridDeductionTrackingDetail",
      Label: "Collected for Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCollectedForType_PROPERTIES

      // END_USER_CODE-USER_colCollectedForType_PROPERTIES
    },
    colDate: {
      name: "colDate",
      type: "GridColumnWidget",
      parent: "gridDeductionTrackingDetail",
      Label: "Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDate_PROPERTIES

      // END_USER_CODE-USER_colDate_PROPERTIES
    },
    gridDeductionTrackingDetail: {
      name: "gridDeductionTrackingDetail",
      type: "GridWidget",
      parent: "grpbxDeductionTrackingDetail",
      gridCellsOrder:
        "txtcolDate,txtcolChargeToType,txtcolChargeToID,txtcolAmountCharged,txtcolCollectedForType,txtcolCollectedForID,txtcolAmountCollected",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridDeductionTrackingDetail_PROPERTIES

      // END_USER_CODE-USER_gridDeductionTrackingDetail_PROPERTIES
    },
    lblChargedTotal: {
      name: "lblChargedTotal",
      type: "LabelWidget",
      parent: "grpbxDeductionTrackingDetail",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChargedTotal_PROPERTIES

      // END_USER_CODE-USER_lblChargedTotal_PROPERTIES
    },
    lblCollectedTotal: {
      name: "lblCollectedTotal",
      type: "LabelWidget",
      parent: "grpbxDeductionTrackingDetail",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCollectedTotal_PROPERTIES

      // END_USER_CODE-USER_lblCollectedTotal_PROPERTIES
    },
    lblTotal: {
      name: "lblTotal",
      type: "LabelWidget",
      parent: "grpbxDeductionTrackingDetail",
      Label: "Total",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTotal_PROPERTIES

      // END_USER_CODE-USER_lblTotal_PROPERTIES
    },
    txtAccountVendor: {
      name: "txtAccountVendor",
      type: "TextBoxWidget",
      parent: "grpbxDeductionTrackingDetail",
      Label: "Account Vendor:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccountVendor_PROPERTIES
      Readonly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtAccountVendor_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxDeductionTrackingDetail",
      Label: "Buying Point:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES
      Readonly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    // lblDeductionCode: {
    //   name: "lblDeductionCode",
    //   type: "TextBoxWidget",
    //   parent: "grpbxDeductionTrackingDetail",
    //   Label: "DeductionCode:",
    //   ColSpan: "2",
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_lblDeductionCode_PROPERTIES
    //   Readonly: true,
    //   Enabled: false,
    //   // END_USER_CODE-USER_lblDeductionCode_PROPERTIES
    // },
    lblDeductionCode: {
      name: "lblDeductionCode",
      type: "LabelWidget",
      parent: "grpbxDeductionTrackingDetail",
      Label: "DeductionCode",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDeductionCode_PROPERTIES

      // END_USER_CODE-USER_lblDeductionCode_PROPERTIES
    },
    txtcolAmountCharged: {
      name: "txtcolAmountCharged",
      type: "TextBoxWidget",
      colName: "colAmountCharged",
      parent: "gridDeductionTrackingDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmountCharged_PROPERTIES

      // END_USER_CODE-USER_txtcolAmountCharged_PROPERTIES
    },
    txtcolAmountCollected: {
      name: "txtcolAmountCollected",
      type: "TextBoxWidget",
      colName: "colAmountCollected",
      parent: "gridDeductionTrackingDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmountCollected_PROPERTIES

      // END_USER_CODE-USER_txtcolAmountCollected_PROPERTIES
    },
    txtcolChargeToID: {
      name: "txtcolChargeToID",
      type: "TextBoxWidget",
      colName: "colChargeToID",
      parent: "gridDeductionTrackingDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChargeToID_PROPERTIES

      // END_USER_CODE-USER_txtcolChargeToID_PROPERTIES
    },
    txtcolChargeToType: {
      name: "txtcolChargeToType",
      type: "TextBoxWidget",
      colName: "colChargeToType",
      parent: "gridDeductionTrackingDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChargeToType_PROPERTIES

      // END_USER_CODE-USER_txtcolChargeToType_PROPERTIES
    },
    txtcolCollectedForID: {
      name: "txtcolCollectedForID",
      type: "TextBoxWidget",
      colName: "colCollectedForID",
      parent: "gridDeductionTrackingDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCollectedForID_PROPERTIES

      // END_USER_CODE-USER_txtcolCollectedForID_PROPERTIES
    },
    txtcolCollectedForType: {
      name: "txtcolCollectedForType",
      type: "TextBoxWidget",
      colName: "colCollectedForType",
      parent: "gridDeductionTrackingDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCollectedForType_PROPERTIES

      // END_USER_CODE-USER_txtcolCollectedForType_PROPERTIES
    },
    txtcolDate: {
      name: "txtcolDate",
      type: "TextBoxWidget",
      colName: "colDate",
      parent: "gridDeductionTrackingDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDate_PROPERTIES

      // END_USER_CODE-USER_txtcolDate_PROPERTIES
    },
    grpbxDeductionTrackingDetail: {
      name: "grpbxDeductionTrackingDetail",
      type: "GroupBoxWidget",
      parent: "DeductionTrackingDetail",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDeductionTrackingDetail_PROPERTIES

      // END_USER_CODE-USER_grpbxDeductionTrackingDetail_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "DeductionTrackingDetail",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  const [loading, setLoading] = useState(true);
  let _buttonServices = {};
  let _buttonNavigation = {
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
  }, [getData(thisObj, "deductiontrackingsummarydata")]);
  // START_USER_CODE-USER_METHODS
  let totalAmountCharged = 0
  let totalAmountCollected = 0
  function FormLoad() {
   try{
    let deductiontrackingsummarydetails = getData(thisObj, "deductiontrackingsummarydata");
    setValue(thisObj, "txtBuyingPoint", deductiontrackingsummarydetails.buyingpointname);
    setValue(thisObj, "lblDeductionCode", deductiontrackingsummarydetails.deductioncode);
    ReadDeductionTrackingDeatail(deductiontrackingsummarydetails.buyingpointname, deductiontrackingsummarydetails.acctvendor);
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred during executing delete button"
      );
    }
  }
}

  function toPascalCase(word){
    try{
      return word.replace(/\w+/g,
        function(w){
          return w[0].toUpperCase() + w.slice(1).toLowerCase();
        });
    }
    catch(err){
    }
  }
  const ReadDeductionTrackingDeatail = (buyingPt, vendorNumber) => {
    if (vendorNumber != "" && vendorNumber != undefined) {
      ContractManagementService.RetrieveVendorByNumber(vendorNumber).then(response => {
        let data = response;
        if (response !== undefined && response.length > 0) {
          setValue(thisObj, "txtAccountVendor", vendorNumber +" - "+ toPascalCase(data[0].name));
          setData(thisObj,"vendorname",toPascalCase(data[0].name));
        }
      })
    }
    let deductiontrackingsummarydetails = getData(thisObj, "deductiontrackingsummarydata");
    try {
      setLoading(true);
    if (deductiontrackingsummarydetails != null && deductiontrackingsummarydetails != undefined &&
      buyingPt != "" && buyingPt != undefined && vendorNumber != "" && vendorNumber != undefined &&
      deductiontrackingsummarydetails.deductioncode != "" && deductiontrackingsummarydetails.deductioncode != undefined) {
      SettlementService.RetrieveDeductionTrackingDetail(buyingPt, "DEDUCTION", vendorNumber, deductiontrackingsummarydetails.deductioncode)
        .then(response => {
          let TransformedRowsArray = [];
          if (response != undefined && response.length > 0) {
            let data = response;
            let obj = {};
            for (let i = 0; i < data.length; i++) {
              obj.txtcolDate = moment(data[i].audit_date_time).format('MM/DD/YYYY')
              obj.txtcolChargeToType = data[i].charge_type
              obj.txtcolChargeToID = data[i].charge_id
              obj.txtcolAmountCharged = data[i].charge_amount
              totalAmountCharged=totalAmountCharged+Number(data[i].charge_amount)
              obj.txtcolCollectedForType = data[i].collect_type
              obj.txtcolCollectedForID = data[i].collect_id
              obj.txtcolAmountCollected = data[i].collect_amount
              totalAmountCollected = totalAmountCollected + Number(data[i].collect_amount)
              TransformedRowsArray.push(obj)
              obj = {}
            }
            let sumTotalAmountCharged = totalAmountCharged.toFixed(2);
            let sumTotalAmountCollected = totalAmountCollected.toFixed(2);
            setValue(thisObj, "gridDeductionTrackingDetail", TransformedRowsArray);
            setValue(thisObj, "lblChargedTotal", sumTotalAmountCharged);
            setValue(thisObj, "lblCollectedTotal", sumTotalAmountCollected);
            setData(thisObj, "lblChargedTotal", sumTotalAmountCharged);
            setData(thisObj, "lblCollectedTotal", sumTotalAmountCollected);
            setLoading(false);
          }
        })
     }
   }  
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during gridReviewDedTrckng change event");
      }
      return false;
    }
    return true;
  };
  thisObj.ReadDeductionTrackingDeatail = ReadDeductionTrackingDeatail;
  // Close Button
  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceApplicationSupport_DeductionTrackingDetailPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnClose event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  // Button Export To Excel Functionality
  const onbtnExportToExcelClick = () => {
    try{
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let datatable = getValue(thisObj, 'gridDeductionTrackingDetail');
    var fileName = "Deduction Tracking Detail";
    let excelData = [];
    let totalData = {}
    if (datatable != undefined) {
      for (let i = 0; i < datatable.length; i++) {
        let rowdata = {};
        if (thisObj.state.gridDeductionTrackingDetail.columns[0].Visible) {
          rowdata["Date"] =ExcelNumericDataConvert(datatable[i].txtcolDate);
          totalData["Date"] = 'Total';
        }
        if (thisObj.state.gridDeductionTrackingDetail.columns[1].Visible) {
          rowdata["Charge to Type"] = datatable[i].txtcolChargeToType;
        }
        if (thisObj.state.gridDeductionTrackingDetail.columns[2].Visible) {
          rowdata["Charge to ID"] = datatable[i].txtcolChargeToID;
        }
        if (thisObj.state.gridDeductionTrackingDetail.columns[3].Visible) {
          rowdata["Amount Charged"] = ExcelNumericDataConvert(datatable[i].txtcolAmountCharged);
          totalData["Amount Charged"] = ExcelNumericDataConvert(getData(thisObj, "lblChargedTotal"));
        }
        if (thisObj.state.gridDeductionTrackingDetail.columns[4].Visible) {
          rowdata["Collected for Type"] = datatable[i].txtcolCollectedForType;
        }
        if (thisObj.state.gridDeductionTrackingDetail.columns[5].Visible) {
          rowdata["Collected for ID"] = datatable[i].txtcolCollectedForID;
        }
        if (thisObj.state.gridDeductionTrackingDetail.columns[6].Visible) {
          rowdata["Amount Collected"] = ExcelNumericDataConvert(datatable[i].txtcolAmountCollected);
          totalData["Amount Collected"] = ExcelNumericDataConvert(getData(thisObj, "lblCollectedTotal"));
        }
        excelData.push(rowdata);
      }
      excelData.push(totalData)
    }
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    var FileSaver = require('file-saver');
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred during executing delete button"
      );
    }
  }
}
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;
  // Print Button Functionality
  const onbtnPrintClick = () => {
    try {
      var data = getValue(thisObj, "gridDeductionTrackingDetail")
      let screendata= getData(thisObj ,"deductiontrackingsummarydata")

      let sDocName = cropYear +" " + "Tracking Deduction: "+ " " + screendata.deductioncode + " - " + screendata.lstBuyingPointID + " - Vendor: " + screendata.acctvendor + " - " + getData(thisObj,"vendorname") + " - "
      sDocName += moment(new Date()).format("MM/DD/YYYY h:mm:ss A");
      var res = []
      let headerArray = []

      if (thisObj.state.gridDeductionTrackingDetail.columns[0].Visible) {
        headerArray.push("Date");
      }
      if (thisObj.state.gridDeductionTrackingDetail.columns[1].Visible) {
        headerArray.push("Charge to Type");
      }
      if (thisObj.state.gridDeductionTrackingDetail.columns[2].Visible) {
        headerArray.push("Charge to ID");
      }
      if (thisObj.state.gridDeductionTrackingDetail.columns[3].Visible) {
        headerArray.push("Amount Charged");
      }
      if (thisObj.state.gridDeductionTrackingDetail.columns[4].Visible) {
        headerArray.push("Collected for Type");
      }
      if (thisObj.state.gridDeductionTrackingDetail.columns[5].Visible) {
        headerArray.push("Collected for ID");
      }
      if (thisObj.state.gridDeductionTrackingDetail.columns[6].Visible) {
        headerArray.push("Amount Collected");
      }
      
      for (var i = 0; i < data.length; i++) {
        let NewArray = []
        if (thisObj.state.gridDeductionTrackingDetail.columns[0].Visible) {
          NewArray.push(data[i].txtcolDate);
        }
        if (thisObj.state.gridDeductionTrackingDetail.columns[1].Visible) {
          NewArray.push(data[i].txtcolChargeToType);
        }
        if (thisObj.state.gridDeductionTrackingDetail.columns[2].Visible) {
          NewArray.push(data[i].txtcolChargeToID);
        }
        if (thisObj.state.gridDeductionTrackingDetail.columns[3].Visible) {
          NewArray.push(data[i].txtcolAmountCharged);
        }
        if (thisObj.state.gridDeductionTrackingDetail.columns[4].Visible) {
          NewArray.push(data[i].txtcolCollectedForType);
        }
        if (thisObj.state.gridDeductionTrackingDetail.columns[5].Visible) {
          NewArray.push(data[i].txtcolCollectedForID);
        }
        if (thisObj.state.gridDeductionTrackingDetail.columns[6].Visible) {
          NewArray.push(data[i].txtcolAmountCollected);
        }
        res.push(NewArray)
      }
      // Total count 
      res.push([
        'Total',
        '',
        '',
        getData(thisObj, 'lblChargedTotal'),
        '',
        '',
        getData(thisObj, 'lblCollectedTotal'),
      ])
      var obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [headerArray]
      });
      var pageCount = obj.internal.getNumberOfPages();
      
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(8);
       obj.text( sDocName, 10, obj.internal.pageSize.height - 10);
      }
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:DeductionTrackingDetail.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:DeductionTrackingDetail.cmdPrintSearch_Click")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DeductionTrackingDetail*/}

              {/* END_USER_CODE-USER_BEFORE_DeductionTrackingDetail*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDeductionTrackingDetail*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDeductionTrackingDetail*/}

              <GroupBoxWidget
                conf={state.grpbxDeductionTrackingDetail}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBuyingPoint}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_txtBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txtAccountVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtAccountVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccountVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblDeductionCode}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_txtAccountVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtAccountVendor*/}
                {/* START_USER_CODE-USER_BEFORE_gridDeductionTrackingDetail*/}

                {/* END_USER_CODE-USER_BEFORE_gridDeductionTrackingDetail*/}

                <GridWidget
                  conf={state.gridDeductionTrackingDetail}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridDeductionTrackingDetail}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridDeductionTrackingDetail*/}

                {/* END_USER_CODE-USER_AFTER_gridDeductionTrackingDetail*/}
                {/* START_USER_CODE-USER_BEFORE_lblTotal*/}

                {/* END_USER_CODE-USER_BEFORE_lblTotal*/}

                <LabelWidget
                  values={values}
                  conf={state.lblTotal}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblTotal*/}

                {/* END_USER_CODE-USER_AFTER_lblTotal*/}
                {/* START_USER_CODE-USER_BEFORE_lblChargedTotal*/}

                {/* END_USER_CODE-USER_BEFORE_lblChargedTotal*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChargedTotal}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChargedTotal*/}

                {/* END_USER_CODE-USER_AFTER_lblChargedTotal*/}
                {/* START_USER_CODE-USER_BEFORE_lblCollectedTotal*/}

                {/* END_USER_CODE-USER_BEFORE_lblCollectedTotal*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCollectedTotal}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCollectedTotal*/}

                {/* END_USER_CODE-USER_AFTER_lblCollectedTotal*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDeductionTrackingDetail*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDeductionTrackingDetail*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_DeductionTrackingDetail*/}

              {/* END_USER_CODE-USER_AFTER_DeductionTrackingDetail*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_DeductionTrackingDetail
);
