/* eslint-disable*/
import React from "react";
import StockTransfer_MultiStopSearch from "./MultiStopSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("MultiStopSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_MultiStopSearch />);
    });
  });
  afterEach(cleanup);
  test("is MultiStopSearch Loads Successfully", () => {
    expect(screen.getByText("MultiStopSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for MultiStopSearch", () => {
    // START_USER_CODE-USER_MultiStopSearch_Custom_Test_Case
    // END_USER_CODE-USER_MultiStopSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_MultiStopSearch />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("StockTransfer:MultiStopSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCreateMultiTransfer(Button Widget) Test Cases", async () => {
    const btnCreateMultiTransfer = screen.getByTestId("btnCreateMultiTransfer");
    expect(btnCreateMultiTransfer).toBeInTheDocument;
    expect(btnCreateMultiTransfer.textContent).toEqual(
      t("StockTransfer:MultiStopSearch_btnCreateMultiTransfer")
    );
  });
  test("Custom Test Cases for btnCreateMultiTransfer", () => {
    // START_USER_CODE-USER_btnCreateMultiTransfer_TEST
    // END_USER_CODE-USER_btnCreateMultiTransfer_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("StockTransfer:MultiStopSearch_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("StockTransfer:MultiStopSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("gridTransferList(Grid Widget) Test Cases", async () => {
    let gridTransferList = screen.getByTestId("gridTransferList");
    let gridTransferListbtn =
      gridTransferList.nextElementSibling.firstElementChild;
    gridTransferList =
      gridTransferList.parentElement.parentElement.parentElement;
    expect(gridTransferList.tagName).toBe("DIV");
    expect(gridTransferList.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSearch_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridTransferList", () => {
    // START_USER_CODE-USER_gridTransferList_TEST
    // END_USER_CODE-USER_gridTransferList_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("grpbxMultiStopSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxMultiStopSearch = screen.getByTestId("grpbxMultiStopSearch");
    expect(grpbxMultiStopSearch.tagName).toBe("BUTTON");
    expect(grpbxMultiStopSearch.type).toBe("button");
    expect(grpbxMultiStopSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxMultiStopSearch", () => {
    // START_USER_CODE-USER_grpbxMultiStopSearch_TEST
    // END_USER_CODE-USER_grpbxMultiStopSearch_TEST
  });
  test("grpbxTransferList(GroupBox Widget) Test Cases", async () => {
    const grpbxTransferList = screen.getByTestId("grpbxTransferList");
    expect(grpbxTransferList.tagName).toBe("BUTTON");
    expect(grpbxTransferList.type).toBe("button");
    expect(grpbxTransferList.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTransferList", () => {
    // START_USER_CODE-USER_grpbxTransferList_TEST
    // END_USER_CODE-USER_grpbxTransferList_TEST
  });
  test("gridTransferList_txtcolDestBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolDestBuyingPoint = screen.getByTestId(
      "gridTransferList"
    );
    let gridTransferList_txtcolDestBuyingPointbtn =
      gridTransferList_txtcolDestBuyingPoint.nextElementSibling
        .firstElementChild;
    gridTransferList_txtcolDestBuyingPoint =
      gridTransferList_txtcolDestBuyingPoint.parentElement.parentElement
        .parentElement;
    expect(gridTransferList_txtcolDestBuyingPoint.tagName).toBe("DIV");
    expect(gridTransferList_txtcolDestBuyingPoint.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSearch_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDestBuyingPoint", () => {
    // START_USER_CODE-USER_txtcolDestBuyingPoint_TEST
    // END_USER_CODE-USER_txtcolDestBuyingPoint_TEST
  });
  test("gridTransferList_txtcolLegNum(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolLegNum = screen.getByTestId("gridTransferList");
    let gridTransferList_txtcolLegNumbtn =
      gridTransferList_txtcolLegNum.nextElementSibling.firstElementChild;
    gridTransferList_txtcolLegNum =
      gridTransferList_txtcolLegNum.parentElement.parentElement.parentElement;
    expect(gridTransferList_txtcolLegNum.tagName).toBe("DIV");
    expect(gridTransferList_txtcolLegNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSearch_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLegNum", () => {
    // START_USER_CODE-USER_txtcolLegNum_TEST
    // END_USER_CODE-USER_txtcolLegNum_TEST
  });
  test("gridTransferList_txtcolOrigBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolOrigBuyingPoint = screen.getByTestId(
      "gridTransferList"
    );
    let gridTransferList_txtcolOrigBuyingPointbtn =
      gridTransferList_txtcolOrigBuyingPoint.nextElementSibling
        .firstElementChild;
    gridTransferList_txtcolOrigBuyingPoint =
      gridTransferList_txtcolOrigBuyingPoint.parentElement.parentElement
        .parentElement;
    expect(gridTransferList_txtcolOrigBuyingPoint.tagName).toBe("DIV");
    expect(gridTransferList_txtcolOrigBuyingPoint.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSearch_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOrigBuyingPoint", () => {
    // START_USER_CODE-USER_txtcolOrigBuyingPoint_TEST
    // END_USER_CODE-USER_txtcolOrigBuyingPoint_TEST
  });
  test("gridTransferList_txtcolTransferNum(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolTransferNum = screen.getByTestId(
      "gridTransferList"
    );
    let gridTransferList_txtcolTransferNumbtn =
      gridTransferList_txtcolTransferNum.nextElementSibling.firstElementChild;
    gridTransferList_txtcolTransferNum =
      gridTransferList_txtcolTransferNum.parentElement.parentElement
        .parentElement;
    expect(gridTransferList_txtcolTransferNum.tagName).toBe("DIV");
    expect(gridTransferList_txtcolTransferNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSearch_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTransferNum", () => {
    // START_USER_CODE-USER_txtcolTransferNum_TEST
    // END_USER_CODE-USER_txtcolTransferNum_TEST
  });
  test("gridTransferList_txtcolTransferType(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolTransferType = screen.getByTestId(
      "gridTransferList"
    );
    let gridTransferList_txtcolTransferTypebtn =
      gridTransferList_txtcolTransferType.nextElementSibling.firstElementChild;
    gridTransferList_txtcolTransferType =
      gridTransferList_txtcolTransferType.parentElement.parentElement
        .parentElement;
    expect(gridTransferList_txtcolTransferType.tagName).toBe("DIV");
    expect(gridTransferList_txtcolTransferType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSearch_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTransferType", () => {
    // START_USER_CODE-USER_txtcolTransferType_TEST
    // END_USER_CODE-USER_txtcolTransferType_TEST
  });
  test("gridTransferList_txtcolTripNum(Grid Widget) Test Cases", async () => {
    let gridTransferList_txtcolTripNum = screen.getByTestId("gridTransferList");
    let gridTransferList_txtcolTripNumbtn =
      gridTransferList_txtcolTripNum.nextElementSibling.firstElementChild;
    gridTransferList_txtcolTripNum =
      gridTransferList_txtcolTripNum.parentElement.parentElement.parentElement;
    expect(gridTransferList_txtcolTripNum.tagName).toBe("DIV");
    expect(gridTransferList_txtcolTripNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("StockTransfer:MultiStopSearch_gridTransferList"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTripNum", () => {
    // START_USER_CODE-USER_txtcolTripNum_TEST
    // END_USER_CODE-USER_txtcolTripNum_TEST
  });
  test("txtTransferNum(Textbox Widget) Test Cases", async () => {
    const txtTransferNum = screen.getByTestId("txtTransferNum");
    expect(txtTransferNum.tagName).toBe("INPUT");
    expect(txtTransferNum.type).toBe("text");
    expect(txtTransferNum.classList).toContain("textboxWidgetClass");
    expect(txtTransferNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:MultiStopSearch_txtTransferNum")
    );
    await act(async () => {
      userEvent.type(txtTransferNum, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum", () => {
    // START_USER_CODE-USER_txtTransferNum_TEST
    // END_USER_CODE-USER_txtTransferNum_TEST
  });
  test("txtTripNum(Textbox Widget) Test Cases", async () => {
    const txtTripNum = screen.getByTestId("txtTripNum");
    expect(txtTripNum.tagName).toBe("INPUT");
    expect(txtTripNum.type).toBe("text");
    expect(txtTripNum.classList).toContain("textboxWidgetClass");
    expect(txtTripNum.previousElementSibling.textContent).toEqual(
      t("StockTransfer:MultiStopSearch_txtTripNum")
    );
    await act(async () => {
      userEvent.type(txtTripNum, "1");
    });
  });
  test("Custom Test Cases for txtTripNum", () => {
    // START_USER_CODE-USER_txtTripNum_TEST
    // END_USER_CODE-USER_txtTripNum_TEST
  });
});
