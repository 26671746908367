/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  ButtonWidget,
  getData,
  setData,
  enable,
  hide,
  disable
} from "../../shared/WindowImports";

import "./ReportPreviewTrade.scss";

import Loading from "../../../Loader/Loading";
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from '../Service/SettlementService';
import { ContractManagementService } from '../../ContractManagement/Service/ContractManagementService';
import { AccountPayment } from '../../AccountPayment/Service/AccountPayment';
import moment from "moment";
import { GetReportLogoImage, GetReportLogoImageDimension } from "./../../Common/Constants";

function Settlements_ReportPreviewTrade(props) {
  const contextType = useAppContext();
  const screenRef = useRef();

  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const imgUrl = GetReportLogoImage(window.location.origin)
  const imgDimension = GetReportLogoImageDimension()

  let msReport = ''
  let mbSuccessfulLoad = false;
  let MarrRemarksPremiumDeductions = ''

  let taws_lstrXML = ''
  let taws_lYPos = 0
  let taws_BPCityState = ''
  let taws_LicenseNo = ''
  let taws_CCCCodeNo = ''
  let taws_mbPurchased = ''
  let taws_LstrText = ''
  let taws_LintHeight = 0
  let taws_bOptionPayment = false
  let taws_sContractList = ''
  let LstrXMLVendors = ''
  let LstrXMLVendorNames = ''
  let LstrXMLVendorPCT = ''
  
  let msPDF_SettlementNo = ''
  let msPDF_AgreementNo = ''
  let msPDF_BP = ''
  let AgreementList = [];
  let reportType = {
    trde1007             : 'TRADE_1007',
    tradeSettleWorkSheet : 'TRADE_SETTLEMENT_WORKSHEET',
    tradeAgreeWorkSheet  : 'TRADE_AGREE_WORKSHEET',
    delvSettleWorkSheet  : 'DELV_SETTLEMENT_WORKSHEET',
    delvAgreeWorkSheet   : 'DELV_AGREE_WORKSHEET'
  }

  let states = {
    Label: "Report Preview Trade",
    windowName: "ReportPreviewTrade",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.ReportPreviewTrade",
    
    headerData: {
      scrName: "Print Preview",
      scrCode: "PN9000A",
    },
    renderUrl: "",
    renderCode: "",
    
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print",
      CharWidth: "13",
    },
    btnPrintSetup: {
      name: "btnPrintSetup",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print Setup",
      CharWidth: "24",
    },
    btnSaveAsPdf: {
      name: "btnSaveAsPdf",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Save as PDF",
      CharWidth: "25",
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ReportPreviewTrade",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
    },
    _dataCopy: {
      FORWARD: {},
      REVERSE: {},
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};

  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    let reportData = getData(thisObj, 'ReportPreviewTradedata')
    FormLoad();
  }, []);

  async function FormLoad(){
    try{
      hide(thisObj, 'btnSaveAsPdf') 
      hide(thisObj, 'btnPrintSetup')  
      disable(thisObj, 'btnPrint')
      disable(thisObj, 'btnClose')
      await GetApiData();
    }
    catch(err){
        errorHandler(err);
    }
  }

  function formatDate(dateVal) {
    var newDate = new Date(dateVal);
    var sMonth = (newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = newDate.getHours();
    var sMinute = padValue(newDate.getMinutes());
    var sSecond = padValue(newDate.getSeconds());
    var sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck >= 12) {
        sAMPM = "PM";
        sHour = iHourCheck - 12;
    }
    else if (iHourCheck === 0) {
        sHour = "12";
    }
    //sHour = padValue(sHour);
    return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + ":" + sSecond + " " + sAMPM;
  }

  function formatDate2(dateVal) {
    var newDate = new Date(dateVal);
    var sMonth = (newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = padValue(newDate.getHours());
    var sMinute = padValue(newDate.getMinutes());
    var sAMPM = "AM";
    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
        sAMPM = "PM";
        sHour = padValue(iHourCheck - 12);
    }
    else if (iHourCheck === 0) {
        sHour = "12";
    }
    return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
  }

  function padValue(value) {
    return (value < 10) ? "0" + value : value;
  }

  function trimValue(num){
    try{
        if(num == undefined)
            num = 0
        let s = Number(num).toString().split('').reverse().join("")
        return s.split('').reverse().join("")
    }
    catch(err){
        errorHandler(err)
        return num
    }
}

  function getTicketNo(astr, intCnt, strVal, bExtraWeightTicks){
    try{
      let intNum = 0;
      let intCount = 0;
      let strPrt = '';
      let intPrev = 0;
      intNum = 25;
      let strNum = '';

      if(strVal == '')
        return '';
      astr = strVal.split(',');
      for(intCount = 0; intCount < astr.length; i++)
        astr[intCount] = astr[intCount] + ',';
      
      strPrt = astr[0];
      for(intCount = 0; intCount < astr.length; i++){
        if(intCount !== 0){
          if(astr[i].length <= intNum - intPrev){
            strPrt += astr[intCount];
            intCnt = intCount;
          }
          else{
            bExtraWeightTicks = false;
            break;
          }
        }
        intPrev += astr[intCount].length;
      }
      return strPrt;
    }
    catch(err){
        errorHandler(err);
        return '';
    }
  }

  async function GetApiData() {
    try {
      enable(thisObj, "btnPrint")
      enable(thisObj, "btnClose")
      await ReadFile();
    }
    catch(err) {
        errorHandler(err);
    }
  }

  async function ReadFile() {
    try {
      var renderCode = await GetHtmlCode()
      state.renderCode = renderCode
      state.renderUrl = getBlobURL(renderCode, 'text/html')
      document.getElementById('docFrame').setAttribute("src", state.renderUrl)
      setLoading(false)
    }
    catch(err) {
        errorHandler(err);
    }
  }

  async function GetHtmlCode() {
    try {  
      let reportData = getData(thisObj, 'ReportPreviewTradedata')
      let renderCode = '';
      if(reportData[0].ReportType == reportType.trde1007){
        renderCode = await rptTrade1007Certificate(reportData[0].Txt_Agree, reportData[0].lstBuyingPointID)
      }
      else if(reportData[0].ReportType == reportType.tradeSettleWorkSheet){
        renderCode = await rptTradeSettlementWorkSheet(reportData[0].Txt_Agree, reportData[0].lstBuyingPointID)
      }
      else if(reportData[0].ReportType == reportType.tradeAgreeWorkSheet){
        renderCode = await rptTradeAgreementWorksheet(reportData[0].Txt_Agree, reportData[0].lstBuyingPointID)
      }
      else if(reportData[0].ReportType == reportType.delvSettleWorkSheet){
        renderCode = await rptDelvSettlementWorkSheet(reportData[0].Txt_Agree, reportData[0].lstBuyingPointID)
      }
      else if(reportData[0].ReportType == reportType.delvAgreeWorkSheet){
        renderCode = await rptDelvAgreementWorksheet(reportData[0].Txt_Agree, reportData[0].lstBuyingPointID)
      }

      if(renderCode.length == 0){
        showMessage(thisObj, 'Please try again from the parent screen.')
        return '';
      }

      enable(thisObj, 'btnPrint')
      enable(thisObj, 'btnClose')
      return renderCode;
    }
    catch(err) {
        errorHandler(err);
    }
  }

  async function rptTrade1007Certificate(sTradeNumber, buyingPt){
    try{
      let sArea = '';
      let lstrxml = [];
      let LstrXMLAgreements = [];
      let LstrXML_Subs = [];
      let LstrXMLRemarks = [];
      let LstrList = [];
      let LintVendorSplit_P = 0;
      let LintVendorSplit_S = 0;
      let LstrApplicantName = [];
      
      let sPrevPremCode = '';
      let nIteration = 0;
      let bDisplayTons = false;
      let sAgreement = '';
      let bNeedsAttachment = false;
      let nVendorIndex = 0;
      let sAdditionVendorInfo = '';
      let sGpcOwned = '';
      let Lstrfedname = '';
      let LicenseNo = '';
      let LblnPrint1007Storage = false;
      let dblOptionPayment = 0;
      
      msReport = "TRADE_1007";
      let obj = {}
      obj.AgreementList = ''

      let response = await SettlementService.RetrieveTradeSettlementDetails('R29', 'D000011', null, null)
      if(response != null && response != undefined){
        if(response.length > 0){
          lstrxml = response[0]
          let response2 = await SettlementService.RetrieveTradeSettlementApplicationDetails('D324498', 'N')
          
          LstrXMLAgreements = response2
          sAgreement = ''
          if(response2 != null && response2 != undefined && response2.length != 0){
            for(var i=0; i<response2.length;i++){
              if(sAgreement != response2[i].agreement_num)
              obj.AgreementList += ''
            }
          }
        }
      }

      if(obj.AgreementList.length > 0)
        obj.AgreementList = obj.AgreementList.slice(-1);
      
      obj.SettlementDate = lstrxml.trade_settle_header.length == 0 ? '' : new Date(lstrxml.trade_settle_header[0].settle_date_time).toLocaleDateString('en-GB');
      obj.BuyingPoint_Location = lstrxml.trade_settle_header.length == 0 ? '' : lstrxml.trade_settle_header[0].buy_pt_fed_id;
      obj.ComputerSerial = lstrxml.trade_settle_header.length == 0 ? '' : lstrxml.trade_settle_header[0].trade_settle_num;

      msPDF_BP = buyingPt;
      msPDF_SettlementNo = obj.ComputerSerial;

      obj.VCSerial = '';
      obj.VendorList = '';
      
      bNeedsAttachment = false;
      sAdditionVendorInfo = '';
      nVendorIndex = 1;

      LstrXML_Subs = lstrxml.trade_settle_vendor_split;
      LstrXMLRemarks = lstrxml.trade_settle_remark;
      
      obj.VendorList = ''
      obj.Purch_LBS_LineG = 0;
      obj.Purch_xLineP = 0;
      obj.Purch_ValOfSegment = 0;
      obj.Purch_NetAmount = 0;


      for(var i=0; i<LstrXML_Subs.length; i++){
        switch(nVendorIndex){
          case 1:
            obj.ProducerName1 = LstrXML_Subs[i].vendor_name;
            if(LstrXML_Subs[i].split_vendor.trim() !== '')
              obj.VendorList += LstrXML_Subs[i].split_vendor.trim();
            if(LstrXML_Subs[i].split_share_pct !== '')
              obj.Share_Percentage1 = LstrXML_Subs[i].split_share_pct;
            else
              obj.Share_Percentage1 = '';
            break;

          case 2:
            obj.ProducerName2 = LstrXML_Subs[i].vendor_name;
            if(LstrXML_Subs[i].split_vendor.trim() !== '')
              obj.VendorList += (', ' + LstrXML_Subs[i].split_vendor.trim());
            if(LstrXML_Subs[i].split_share_pct !== '')
              obj.Share_Percentage2 = LstrXML_Subs[i].split_share_pct;
            else
              obj.Share_Percentage2 = '';
            break;

          case 3:
            obj.ProducerName3 = LstrXML_Subs[i].vendor_name;
            if(LstrXML_Subs[i].split_vendor.trim() !== '')
              obj.VendorList += (', ' + LstrXML_Subs[i].split_vendor.trim());
            if(LstrXML_Subs[i].split_share_pct !== '')
              obj.Share_Percentage3 = LstrXML_Subs[i].split_share_pct;
            else
              obj.Share_Percentage3 = '';
            break;

          case 4:
            obj.ProducerName4 = LstrXML_Subs[i].vendor_name;
            if(LstrXML_Subs[i].split_vendor.trim() !== '')
              obj.VendorList += (', ' + LstrXML_Subs[i].split_vendor.trim());
            if(LstrXML_Subs[i].split_share_pct !== '')
              obj.Share_Percentage4 = LstrXML_Subs[i].split_share_pct;
            else
              obj.Share_Percentage4 = '';
            break;
          
          default:
            bNeedsAttachment = true;
            sAdditionVendorInfo += (LstrXML_Subs[i].vendor_name + ',' + LstrXML_Subs[i].split_share_pct + ',' + LstrXML_Subs[i].split_vendor + '|')
            if(LstrXML_Subs[i].split_vendor.trim() !== '')
              obj.VendorList += (', ' + LstrXML_Subs[i].split_vendor.trim());
        }
        
        obj.Purch_LBS_LineG += Number(LstrXML_Subs[i].net_wt);
        obj.Purch_xLineP += Number(LstrXML_Subs[i].basis_grade_amt);
        obj.Purch_xPercentage = LstrXML_Subs[i].support_pct;
        obj.Purch_ValOfSegment += (LstrXML_Subs[i].value_of_seg == '' ? 0 : Number(LstrXML_Subs[i].value_of_seg));
        obj.Purch_NetAmount += (LstrXML_Subs[i].proceeds_amt == '' ? 0 : Number(LstrXML_Subs[i].proceeds_amt));
        LintVendorSplit_P++;
        nVendorIndex++;
      }

      response = await SettlementService.RetrieveBuyingPointControlDetails('PN0020', null, null, null, null, lstrxml.trade_settle_header[0].buy_pt_id) 
      for(var i=0; i<response.length; i++){
        if(response[i].buyPtId == lstrxml.trade_settle_header[0].buy_pt_id){
          sArea = response[i].areaId;
          LicenseNo = response[i].fedWhouseLicense;
          LstrXML_Subs = response[i];
          break;
        }    
      }       
      
      response = await ContractManagementService.RetrievePeanutPriceScheduleDetails('SE', 'RU')
      if(response.length <= 0)
        return '';
      LstrXML_Subs = response
      obj.lsk_per_pounds = LstrXML_Subs[9].strFieldData;

      response = await SettlementService.RetrieveAreaControlDetails('SW')
      let areaDetails = response
      if(response.length <= 0)
        return '';
      
      response = await ContractManagementService.RetrieveCompanyDetails(lstrxml.trade_settle_header[0].comp_id)
      LstrApplicantName = response[0].compName;

      obj.PurchSaleInd = lstrxml.trade_settle_header[0].purch_sale_ind
      if(obj.PurchSaleInd == 'P'){
        obj.Applicant_01_Name_Number = areaDetails[0].handlerLocNum + ' ' + LstrApplicantName;
        obj.OperatorSellerName_COCode_FarmNo = lstrxml.trade_settle_header[0].handler_num.trim() + ' ' + lstrxml.trade_settle_header[0].handler_num.trim()
      }
      else{
        obj.Applicant_01_Name_Number = lstrxml.trade_settle_header[0].handler_num.trim() + ' ' + lstrxml.trade_settle_header[0].handler_num.trim()
        obj.OperatorSellerName_COCode_FarmNo = areaDetails[0].handlerLocNum + ' ' + LstrApplicantName;
      }
      obj.Applicant_03_Name_Number = ''
      
      obj.DateInspected = new Date(lstrxml.trade_settle_header[0].insp_date_time).toLocaleDateString('en-GB');
      obj.WarehouseDesc_BinNo = lstrxml.trade_settle_header[0].inventory_whouse + '-' + lstrxml.trade_settle_header[0].inventory_bin;

      obj.InspectionMemoNo_FV_95 = lstrxml.trade_settle_header[0].trade_insp_num;
      obj.TypeOfInspection = 'Resale'
      obj.TypeStorage = 'COMM.'
      obj.ConvertedPenaltyRate = ''
      obj.NoOfBags = ''
      obj.Bulk = 'X'
      obj.Type = lstrxml.trade_settle_header[0].pnut_type_id;
      obj.SMK_GR = lstrxml.trade_settle_header[0].smk_gr;
      obj.SS_GR = lstrxml.trade_settle_header[0].ss_gr;
      obj.OK_GR = lstrxml.trade_settle_header[0].ok_gr;
      obj.DAM_SPLIT_GR = lstrxml.trade_settle_header[0].dam_split_gr;
      obj.DAM_GR = lstrxml.trade_settle_header[0].dam_gr;
      obj.HULL_GR = lstrxml.trade_settle_header[0].hull_gr;
      obj.FREZ_DAM_GR = lstrxml.trade_settle_header[0].frez_dam_gr;
      obj.CNCL_RMD_GR = lstrxml.trade_settle_header[0].cncl_rmd_gr;
      obj.CR_BR_GR = lstrxml.trade_settle_header[0].cr_br_gr;
      obj.DCOL_SHEL_GR = lstrxml.trade_settle_header[0].dcol_shel_gr;
      obj.HUL_BRIT_GR = lstrxml.trade_settle_header[0].hul_brit_gr;
      obj.JUMBO_GR = lstrxml.trade_settle_header[0].jumbo_gr;
      obj.ELK_GR = lstrxml.trade_settle_header[0].elk_gr;
      obj.FM_GR = lstrxml.trade_settle_header[0].fm_gr;
      obj.LSK_GR = lstrxml.trade_settle_header[0].lsk_gr;
      obj.FAN_GR = lstrxml.trade_settle_header[0].fan_gr;
      obj.TOT_KRS_WT = lstrxml.trade_settle_header[0].tot_krs_wt;
      obj.FM_Sample_WT = lstrxml.trade_settle_header[0].fm_sample;
      obj.CLN_SAMPLE = lstrxml.trade_settle_header[0].cln_sample;
      obj.OTH_FM = lstrxml.trade_settle_header[0].oth_fm;
      obj.FLAVUS_IND = lstrxml.trade_settle_header[0].flavus_ind;

      obj.BLUEPAN_WT = lstrxml.trade_settle_header[0].bluepan_wt;
      obj.METER_READ = lstrxml.trade_settle_header[0].meter_read;
      obj.KERNEL_RPS = lstrxml.trade_settle_header[0].kernel_rps;
      obj.KERNEL_RELS = lstrxml.trade_settle_header[0].kernel_rels;
      obj.DAMAGED_KRS = lstrxml.trade_settle_header[0].damaged_krs;
      obj.ELK_DAMAGE = lstrxml.trade_settle_header[0].elk_damaged;

      if(lstrxml.trade_settle_header[0].print_seg_ind == 'Y')
        obj.Seg = lstrxml.trade_settle_header[0].seg_type
      else
        obj.Seg = 'XX'
      obj.Seal_num = lstrxml.trade_settle_header[0].seal_num;
      obj.CropYear = lstrxml.trade_settle_header[0].crop_year;
      obj.Time = new Date(lstrxml.trade_settle_header[0].insp_date_time).toLocaleDateString('en-GB');
      obj.Purch_ApplicantNo = '1'
      

      let LstrWtTicketNos = '';
      let LstrVehicleNos = '';
      let LstrWtTicketNos_XML = [];

      LstrWtTicketNos_XML = lstrxml.trade_settle_scale_tickets;
      
      LstrWtTicketNos = LstrWtTicketNos_XML[0].trade_settle_scale_ticket.length == 0 ? '' : LstrWtTicketNos_XML[0].trade_settle_scale_ticket[0].shp_scale_tick;
      LstrVehicleNos = LstrWtTicketNos_XML[0].trade_settle_scale_ticket.length == 0 ? '' : LstrWtTicketNos_XML[0].trade_settle_scale_ticket[0].vehicle_num;
      obj.Signiture = '' 

      LstrWtTicketNos_XML = lstrxml.trade_settle_scale_tickets[0].trade_settle_scale_ticket;
      
      obj.WeightTicketNo = LstrWtTicketNos;
      obj.VehicleNo = LstrVehicleNos;

      obj.ProbingPattern = lstrxml.trade_settle_header[0].probe_pattern == '' ? '' : lstrxml.trade_settle_header[0].probe_pattern;
      obj.ForiegnMaterial = lstrxml.trade_settle_header[0].fm_pct == '' ? 0 : lstrxml.trade_settle_header[0].fm_pct;
      
      obj.CrackedBrokenShells = lstrxml.trade_settle_header[0].cr_br_pct == '' ? 0 : lstrxml.trade_settle_header[0].cr_br_pct;
      
      obj.DiscoloredShells = lstrxml.trade_settle_header[0].dcol_shel_pct == '' ? 0 : lstrxml.trade_settle_header[0].dcol_shel_pct;
      obj.LSK = lstrxml.trade_settle_header[0].lsk_pct == '' ? 0 : lstrxml.trade_settle_header[0].lsk_pct;
      obj.Fancy = lstrxml.trade_settle_header[0].fan_pct == '' ? 0 : lstrxml.trade_settle_header[0].fan_pct;      
      obj.Moisture = lstrxml.trade_settle_header[0].moist_pct == '' ? 0 : lstrxml.trade_settle_header[0].moist_pct;
      obj.ELK = lstrxml.trade_settle_header[0].elk_pct == '' ? 0 : lstrxml.trade_settle_header[0].elk_pct;
      obj.SMK = lstrxml.trade_settle_header[0].smk_pct == '' ? 0 : lstrxml.trade_settle_header[0].smk_pct;
      obj.SoundSplits = lstrxml.trade_settle_header[0].ss_pct == '' ? 0 : lstrxml.trade_settle_header[0].ss_pct;
      obj.Total_SMK_SoundSplits = obj.SMK + obj.SoundSplits;

      obj.FreezeDamage = lstrxml.trade_settle_header[0].elk_pct == '' ? 0.00 : lstrxml.trade_settle_header[0].frez_dam_pct;
      obj.OtherKernals = lstrxml.trade_settle_header[0].ok_pct == '' ? 0 : lstrxml.trade_settle_header[0].ok_pct;
      obj.ConcealedRMD = lstrxml.trade_settle_header[0].cncl_rmd_pct == '' ? 0 : lstrxml.trade_settle_header[0].cncl_rmd_pct;
      obj.Damage = lstrxml.trade_settle_header[0].dam_pct == '' ? 0 : lstrxml.trade_settle_header[0].dam_pct;

      obj.RemarksDate = new Date(lstrxml.trade_settle_header[0].settle_date_time).toLocaleDateString('en-GB').slice(0,5);
      obj.Remarks = LstrXMLRemarks[0].insp_remark;

      if(lstrxml.trade_settle_header[0].seg_type == 1){
        if(lstrxml.trade_settle_header[0].seed_gen.toUpperCase() == 'C')
          obj.RemarksSeed = 'Applicant States Certified Seed'
        if(lstrxml.trade_settle_header[0].seed_gen.toUpperCase() == 'F')
          obj.RemarksSeed = 'Applicant States Foundation Seed'
        if(lstrxml.trade_settle_header[0].seed_gen.toUpperCase() == 'R')
          obj.RemarksSeed = 'Applicant States Registered Seed'
        else
          obj.RemarksSeed = ''
      }
      if(lstrxml.trade_settle_header[0].superseded_settle !== ''){
        if(lstrxml.trade_settle_header[0].superseded_settle !== 0)
          obj.RemarksSupersede = 'This 1007 Certificate Supersedes: ' + lstrxml.trade_settle_header[0].superseded_settle
        else
          obj.RemarksSupersede = ''
      }
      else
        obj.RemarksSupersede = ''
      
      obj.TotalKernals = obj.SMK + obj.SoundSplits + obj.OtherKernals + obj.Damage;
      obj.Hulls = lstrxml.trade_settle_header[0].hull_pct = '' ? 0 : lstrxml.trade_settle_header[0].hull_pct;

      obj.Total_Kernals_Hulls = obj.TotalKernals + obj.Hulls

      response = await SettlementService.RetrieveAreaControlDetails('SW')
      
      LstrList = response[0]
      bDisplayTons = LstrList.displayLbsTon == 'P' ? false : true;

      obj.A_WeightInclVehicle = lstrxml.trade_settle_header[0].tare_wt;
      obj.B_WeightOfVehicle = lstrxml.trade_settle_header[0].vehicle_wt;
      obj.C_GrossWeight_AMinusB = lstrxml.trade_settle_header[0].gross_wt;
      obj.D_ForeignMaterial_PerOfFMxC = lstrxml.trade_settle_header[0].fm_wt;
      obj.E_WeightLessFM_CMinusD = obj.C_GrossWeight_AMinusB - obj.D_ForeignMaterial_PerOfFMxC;
      obj.F_ExcessMoisture_PerOfEMxE = lstrxml.trade_settle_header[0].ex_moist_wt;
      obj.G_NetWeight_EMinusF = lstrxml.trade_settle_header[0].net_wt;
      obj.H_LSK_PerOfLSKxC = lstrxml.trade_settle_header[0].lsk_wt;
      obj.I_NetWeightExclLSK_GMinusH = obj.G_NetWeight_EMinusF - obj.H_LSK_PerOfLSKxC;

      if(bDisplayTons){
        obj.J_KernalValPerLB_ExclLSK = Number(lstrxml.trade_settle_header[0].kern_value);
        obj.K_ELKPremium = Number(lstrxml.trade_settle_header[0].elk_prem);
        obj.L_Total_JPlusK = Number(obj.J_KernalValPerLB_ExclLSK) + Number(obj.K_ELKPremium);
        obj.M_a_Damage = Number(lstrxml.trade_settle_header[0].dam_discount);
        obj.M_b_ExcessFM = Number(lstrxml.trade_settle_header[0].ex_fm_discount);
        obj.M_c_ExcessSplits = Number(lstrxml.trade_settle_header[0].ex_ss_discount);
        obj.M_d_Total = obj.M_a_Damage + obj.M_b_ExcessFM + obj.M_c_ExcessSplits;
        obj.N_NetValLB_ExclLSK_LMinusM = obj.L_Total_JPlusK - obj.M_d_Total;
        obj.o = Number(obj.N_NetValLB_ExclLSK_LMinusM) / 20,
        obj.P_ValLBInclLSK_a_NxI = Number(Number(obj.o) * Number(obj.I_NetWeightExclLSK_GMinusH)) * 0.01;
        obj.P_ValLBInclLSK_b_HxCents = Number(obj.H_LSK_PerOfLSKxC) * Number(obj.lsk_per_pounds);
        obj.P_Total = Number(obj.P_ValLBInclLSK_a_NxI) + Number(obj.P_ValLBInclLSK_b_HxCents);
        obj.P_Total_DivByG = Number(lstrxml.trade_settle_header[0].net_price) * 100;
      }
      else{
        obj.J_KernalValPerLB_ExclLSK = Number(lstrxml.trade_settle_header[0].kern_value) / 20;
        obj.K_ELKPremium = Number(lstrxml.trade_settle_header[0].elk_prem) / 20;
        obj.L_Total_JPlusK = Number(obj.J_KernalValPerLB_ExclLSK) + Number(obj.K_ELKPremium);
        obj.M_a_Damage = Number(lstrxml.trade_settle_header[0].dam_discount) / 20;
        obj.M_b_ExcessFM = Number(lstrxml.trade_settle_header[0].ex_fm_discount) / 20;
        obj.M_c_ExcessSplits = Number(lstrxml.trade_settle_header[0].ex_ss_discount) / 20;
        obj.M_d_Total = obj.M_a_Damage + obj.M_b_ExcessFM + obj.M_c_ExcessSplits;
        obj.N_NetValLB_ExclLSK_LMinusM = obj.L_Total_JPlusK - obj.M_d_Total;
        obj.o = '',
        obj.P_ValLBInclLSK_a_NxI = Number(Number(obj.o) * Number(obj.I_NetWeightExclLSK_GMinusH)) * 0.01;
        obj.P_ValLBInclLSK_b_HxCents = Number(obj.H_LSK_PerOfLSKxC) * Number(obj.lsk_per_pounds);
        obj.P_Total = Number(obj.P_ValLBInclLSK_a_NxI) + Number(obj.P_ValLBInclLSK_b_HxCents);
        obj.P_Total_DivByG = Number(lstrxml.trade_settle_header[0].net_price) * 100;
      }

      obj.Load_num = lstrxml.trade_settle_header[0].load_num;
      obj.Lot_num = lstrxml.trade_settle_header[0].lot_num;
      obj.Start_date_time = lstrxml.trade_settle_header[0].start_date_time;
      obj.Worm_dam_pct = lstrxml.trade_settle_header[0].worm_dam_pct;

      obj.RemarksGovtVerbage1 = "United States Warehouse Act Nut Weight Certificate"
      obj.RemarksGovtVerbage3 = "This Certificate is issued by a weigher licensed under the United States Warehouse Act and the regulations thereunder."

      sPrevPremCode = '';
      nIteration = 1;
      
      obj.Store_ApplicantNo = '1';
      obj.Variety_Description = lstrxml.trade_settle_header[0].pnut_variety_name;

      if(lstrxml.trade_settle_header[0].symbol_ind.toUpperCase() == 'TRADEMARK')
        obj.Variety_Description += `&reg;`
      else if(lstrxml.trade_settle_header[0].symbol_ind.toUpperCase() == 'REGISTERED TRADEMARK')
        obj.Variety_Description += `&trade;`
      else if(lstrxml.trade_settle_header[0].symbol_ind.toUpperCase() == 'COPYRIGHT')
        obj.Variety_Description += `&copy;`

      response = await SettlementService.RetrieveCheck1007OneDocumentDetails(buyingPt)
      if(response.length > 0){  
        if(response[0].onedoc_ind == 'Y'){
            await TradeCertificate_OneDoc("Applicant Original", true)
            await TradeCertificate_OneDoc("FSIS", false)
            await TradeCertificate_OneDoc("SELLER", false)
        }
        else{
            await TradeCertificate_Laser("Applicant Original", true)
            await TradeCertificate_Laser("FSIS", false)
            await TradeCertificate_Laser("SELLER", false)
        }
      }
      else{
        await TradeCertificate_Laser("Applicant Original", true)
        await TradeCertificate_Laser("FSIS", false)
        await TradeCertificate_Laser("SELLER", false)
      }
    }
    catch(err){
        errorHandler(err);
    }
  }

  async function rptTradeSettlementWorkSheet(sTradeNumber, buyingPt) {
    try {
      let lstrxml = [];
      let LstrXML_Subs = ''
      let sPrevPremCode = ''
      let dblStorPremAmt = 0;
      let dblPurhPremAmt = 0;
      let dblStorCharges = 0;
      let dblPurhCharges = 0;

      let sCropYear = '';
      let ssTradeInspNum = '';
      let sFedBPNumber = '';
      let sSettlementNumber = '';
      let sBuyingPoint = '';
      let sPricingType = '';
      let sGPM = '';
      let sShip_to_from = '';
      let sAgreement_num = '';
      let sApplied_lbs = '';
      let sFirm_basis_opt = '';

      let sEdible_ind = '';
      let sOil_ind = '';
      let nAgreement = 0;

      let sInspectionDateTime = '';
      let sState = '';
      let sStatus = '';
      let sPurch_sale_ind = '';
      let sCounty = '';
      let sFarmNumber = '';
      let sProducer = '';
      let colVendors = [];
      let colVendor = [];

      let colAgreements = [];
      let colAgreement = [];

      let sPeanutSeg = '';
      let sPeanutType = '';
      let sPeanutVariety = '';
      let sPeanutGeneration = '';
      let sProbingPattern = '';
      let sWarehouse = '';
      let sBin = '';
      let colScaleTickets = [];
      let colScaleTicket = [];
      let sMeetsSeedSpec = '';
      let sOleic = '';
      let sA_WeightInclVehicle = '';
      let sB_WeightOfVehicle = '';
      let sFM = '';
      let sC_GrossWeight_A_minus_B = '';
      let sLSK = '';
      let sD_ForiegnMaterial_FM_x_C = '';
      let sMoisture = '';
      let sE_Weight_less_FM_C_minus_D = '';
      let sSMK1 = '';
      let sF_ExcessMoisture_FM_x_E = '';
      let sSMK2 = '';
      let sG_NetWeight_E_minus_F = '';
      let sSMK3 = '';
      let sH_LSK_LSK_x_C = '';
      let sSMK4 = '';
      let sI_NetWeightExclLSK_G_minus_H = '';
      let sTotalSMK = '';
      let sJ_KernalValue_Ton_ExclLSK = '';
      let sSS = '';
      let sK_ELKPremium = '';
      let sTotal_SMK_plus_SS = '';
      let sL_Total_J_plus_k = '';
      let sFreezeDamage = '';
      let sPittingDamage = '';
      let sL_a_Damage = '';
      let sConcealedRMD = '';
      let sL_b_ExcessFM = '';
      let sTotalDamage = '';
      let sL_c_ExcessSplits = '';
      let sOtherKernals = '';
      let sAflatoxin = '';
      let sTotalKernals = '';
      let sM_TotalDiscounts = '';
      let sHULLS = '';
      let sN_Value_Ton_ExclLSK_L_minus_M = '';
      let sTotalKernal_Hulls = '';
      let sVicam = '';
      let sEstimatedValue = '';
      let sHullBrightness = '';
      let sInShellJumbo = '';
      let sFancy = '';
      let sELK = '';
      let sCrackedAndBroken = '';
      let sDiscolored = '';
      let sRemarks = '';
      let sAuditCount = '';
      let colPremDeducts = [];
      let colPremDeduct = [];

      let nVendor = 0;
      let nTicket = 0;
      let nPremDed = 0;

      let lSPounds = 0;
      let lPPounds = 0;
      let curSBasisGradeAmt = 0;
      let curPBasisGradeAmt = 0;
      let curSSupport = 0;
      let curPSupport = 0;
      let curSSeg = 0;
      let curPSeg = 0;
      let curSProceeds = 0;
      let curPProceeds = 0;
      let LstrText = '';
      let LstrXMLAgreements = [];

      let LintYPremDed = 0;

      let sLskRiding = '';
      let sLskFalling = '';
      let sLskDamaged = '';
      let sOKRiding = '';
      let sOKFalling = '';
      let sOKDamaged = '';
      let sELKRiding = '';
      let sELKDamaged = '';
      let sRidePrescribed = '';

      let sLoadNum = '';
      let sLotNum = '';

      let dblAgreePrice = 0;
      let nAgreeCnt = 0;
      let n = 0;

      const MaxVendors = 20
      const MaxTickets = 5
      const MaxPremDeds = 19

      msReport = "TRADE_SETTLEMENT_WORKSHEET"

      let response = await SettlementService.RetrieveTradeSettlementDetails(buyingPt, sTradeNumber, null, null)
      lstrxml = response[0];
      
      dblAgreePrice = 0;
      nAgreeCnt = 0;
      if(response != null && response != undefined){
        if(response[0].trade_settle_header.length == 0){
          showMessage(thisObj, 'Data not available')
          return '';
        }
          
        if(response.length > 0){
          colAgreement = [];

          let response2 = await SettlementService.RetrieveTradeSettlementApplicationDetails(sTradeNumber, 'N')
          // if(response2 == null || response2 == undefined || response2.length == 0)
          //  return '';

          LstrXMLAgreements = response2;
          for(var i=0; i<response2.length; i++){
            if(Number(response2[i].delivered_lbs_adj) > 0){
              colAgreement = [];
              colAgreement.push(response2[i].agreement_num);
              colAgreement.push(response2[i].firm_basis_opt);
              colAgreement.push(response2[i].delivered_lbs_adj);
              if(response2[i].price_per_lbs !== ''){
                dblAgreePrice += Number(response2[i].price_per_lbs);
                nAgreeCnt++;
              }
              colAgreements.push(colAgreement)
            }
          }

          sCropYear = lstrxml.trade_settle_header[0].crop_year;
          ssTradeInspNum = lstrxml.trade_settle_header[0].trade_insp_num;
          sFedBPNumber = lstrxml.trade_settle_header[0].buy_pt_fed_id;
          sSettlementNumber = lstrxml.trade_settle_header[0].trade_settle_num == '' ? '' : lstrxml.trade_settle_header[0].trade_settle_num.trim();

          msPDF_BP = buyingPt;
          msPDF_SettlementNo = ssTradeInspNum;

          sLskRiding = lstrxml.trade_settle_header[0].lsk_ride_pct == "" ? "0.00" : Number(lstrxml.trade_settle_header[0].lsk_ride_pct).toFixed(2)
          sLskFalling = lstrxml.trade_settle_header[0].lsk_fall_pct == "" ? "0.00" : Number(lstrxml.trade_settle_header[0].lsk_fall_pct).toFixed(2)
          sLskDamaged = lstrxml.trade_settle_header[0].lsk_dam_pct == "" ? "0.00" : Number(lstrxml.trade_settle_header[0].lsk_dam_pct).toFixed(2)
          sOKRiding = lstrxml.trade_settle_header[0].ok_ride_pct == "" ? "0.00" : Number(lstrxml.trade_settle_header[0].ok_ride_pct).toFixed(2)
          sOKFalling = lstrxml.trade_settle_header[0].ok_fall_pct == "" ? "0.00" : Number(lstrxml.trade_settle_header[0].ok_fall_pct).toFixed(2)
          sOKDamaged = lstrxml.trade_settle_header[0].ok_dam_pct == "" ? "0.00" : Number(lstrxml.trade_settle_header[0].ok_dam_pct).toFixed(2)
          sELKRiding = lstrxml.trade_settle_header[0].elk_ride_pct == "" ? "0.00" : Number(lstrxml.trade_settle_header[0].elk_ride_pct).toFixed(2)
          sELKDamaged = lstrxml.trade_settle_header[0].elk_dam_pct == "" ? "0.00" : Number(lstrxml.trade_settle_header[0].elk_dam_pct).toFixed(2)
          sRidePrescribed = lstrxml.trade_settle_header[0].ride_pscrib_pct == "" ? "0.00" : Number(lstrxml.trade_settle_header[0].ride_pscrib_pct).toFixed(2)

          if(lstrxml.trade_settle_header[0].seed_meeting_spec_ind.toUpperCase() == 'Y')
            sMeetsSeedSpec = 'Yes'
          else if(lstrxml.trade_settle_header[0].seed_meeting_spec_ind.toUpperCase() == 'N')
            sMeetsSeedSpec = 'No'
          else
            sMeetsSeedSpec = ''

          sLoadNum = lstrxml.trade_settle_header[0].load_num;
          sLotNum = lstrxml.trade_settle_header[0].lot_num;

          sBuyingPoint = lstrxml.trade_settle_header[0].buy_pt_name + "   (" + lstrxml.trade_settle_header[0].buy_pt_id + ")";
          sInspectionDateTime = lstrxml.trade_settle_header[0].insp_date_time == '' ? '' : moment(lstrxml.trade_settle_header[0].insp_date_time).format('MM/DD/YYYY hh:mm A')
          sStatus = lstrxml.trade_settle_header[0].trade_status;
          sPurch_sale_ind = lstrxml.trade_settle_header[0].purch_sale_ind;
          sGPM = lstrxml.trade_settle_header[0].grade_pricing_method;
          sShip_to_from = lstrxml.trade_settle_header[0].ship_to_from;
          
          if(lstrxml.trade_settle_header[0].edible_oil_ind == 'E')
            sEdible_ind = 'Yes'
          else if(lstrxml.trade_settle_header[0].edible_oil_ind == 'O')
            sOil_ind = 'Yes'
          
          LstrText = ''

          if(lstrxml.trade_settle_header[0].superseded_settle !== '')
            LstrText = 'Superceded ' + lstrxml.trade_settle_header[0].superseded_settle.trim()
          
          if(Number(lstrxml.trade_settle_header[0].settle_correct_cnt) > 0)
            if(LstrText !== '')
              LstrText += ' & '
            LstrText += ('Correction #' + lstrxml.trade_settle_header[0].settle_correct_cnt)
          
          LstrXML_Subs = lstrxml.trade_settle_vendor_split;
          sProducer = LstrXML_Subs[0].vendor_name;
          colVendors = [];
          for(var i=0; i< LstrXML_Subs.length; i++){
            colVendor = {
              split_vendor     : LstrXML_Subs[i].split_vendor,
              split_remit      : LstrXML_Subs[i].split_remit,
              vendor_name      : LstrXML_Subs[i].vendor_name,
              split_share_pct  : LstrXML_Subs[i].split_share_pct,
              gross_wt         : LstrXML_Subs[i].gross_wt,
              lsk_wt           : LstrXML_Subs[i].lsk_wt,
              net_wt           : LstrXML_Subs[i].net_wt,  
              basis_grade_amt  : LstrXML_Subs[i].basis_grade_amt,
              value_per_ton    : LstrXML_Subs[i].value_per_ton,
              support_pct      : LstrXML_Subs[i].support_pct,
              value_of_seg     : LstrXML_Subs[i].value_of_seg,
              opt_value_of_seg : '',
              premium_amt      : LstrXML_Subs[i].premium_amt,
              deduct_amt       : LstrXML_Subs[i].deduct_amt,
              proceeds_amt     : LstrXML_Subs[i].proceeds_amt,              
            }
            colVendors.push(colVendor)            
          }

          sPeanutSeg = lstrxml.trade_settle_header[0].seg_type;
          sPeanutType = '(' + lstrxml.trade_settle_header[0].pnut_type_id + ') ' + lstrxml.trade_settle_header[0].pnut_type_name;
          sPeanutVariety = '(' + lstrxml.trade_settle_header[0].pnut_variety_id + ') ' + lstrxml.trade_settle_header[0].pnut_variety_name;
          
          if(lstrxml.trade_settle_header[0].symbol_ind.toUpperCase() == 'TRADEMARK')
            sPeanutVariety += `&reg;`
          else if(lstrxml.trade_settle_header[0].symbol_ind.toUpperCase() == 'REGISTERED TRADEMARK')
            sPeanutVariety += `&trade;`
          else if(lstrxml.trade_settle_header[0].symbol_ind.toUpperCase() == 'COPYRIGHT')
            sPeanutVariety += `&copy;`

          sPeanutGeneration = lstrxml.trade_settle_header[0].gen_produced_name;
          sProbingPattern = lstrxml.trade_settle_header[0].probe_pattern;
          sWarehouse = lstrxml.trade_settle_header[0].inventory_whouse;
          sBin = lstrxml.trade_settle_header[0].inventory_bin;

          LstrXML_Subs = lstrxml.trade_settle_scale_tickets[0];
          colScaleTickets = [];
          
          if(LstrXML_Subs.length == 0){
            colScaleTickets = []
          }
          else{
            for(var i=0; i<LstrXML_Subs.trade_settle_scale_ticket.length; i++){
                colScaleTicket = {
                  load_num           : LstrXML_Subs.trade_settle_scale_ticket[i].load_num,
                  vehicle_num        : LstrXML_Subs.trade_settle_scale_ticket[i].vehicle_num,
                  truck_line         : LstrXML_Subs.trade_settle_scale_ticket[i].truck_line,
                  driver_name        : LstrXML_Subs.trade_settle_scale_ticket[i].driver_name,
                  shp_date           : LstrXML_Subs.trade_settle_scale_ticket[i].shp_date,
                  shp_scale_tick     : LstrXML_Subs.trade_settle_scale_ticket[i].shp_scale_tick,
                  shp_split_scale    : LstrXML_Subs.trade_settle_scale_ticket[i].shp_split_scale,
                  shp_tare_wt        : LstrXML_Subs.trade_settle_scale_ticket[i].shp_tare_wt,
                  shp_vehicle_wt     : LstrXML_Subs.trade_settle_scale_ticket[i].shp_vehicle_wt,
                  shp_peanut_wt      : LstrXML_Subs.trade_settle_scale_ticket[i].shp_peanut_wt,
                  rec_date           : LstrXML_Subs.trade_settle_scale_ticket[i].rec_date,
                  rec_scale_tick     : LstrXML_Subs.trade_settle_scale_ticket[i].rec_scale_tick,
                  rec_split_scale    : LstrXML_Subs.trade_settle_scale_ticket[i].rec_split_scale,
                  rec_tare_wt        : LstrXML_Subs.trade_settle_scale_ticket[i].rec_tare_wt,
                  rec_vehicle_wt     : LstrXML_Subs.trade_settle_scale_ticket[i].rec_vehicle_wt,
                  rec_peanut_wt      : LstrXML_Subs.trade_settle_scale_ticket[i].rec_peanut_wt,
                  inventory_with_ind : LstrXML_Subs.trade_settle_scale_ticket[i].inventory_with_ind,
                  inventory_other_wt : LstrXML_Subs.trade_settle_scale_ticket[i].inventory_other_wt
                }
                colScaleTickets.push(colScaleTicket)
              }
          }
          
          sOleic = lstrxml.trade_settle_header[0].oleic_ind;
          sA_WeightInclVehicle = lstrxml.trade_settle_header[0].tare_wt;
          sB_WeightOfVehicle = lstrxml.trade_settle_header[0].vehicle_wt;
          sFM = Number(lstrxml.trade_settle_header[0].fm_pct).toFixed(2);
          sC_GrossWeight_A_minus_B = lstrxml.trade_settle_header[0].gross_wt;
          sLSK = Number(lstrxml.trade_settle_header[0].lsk_pct).toFixed(2);
          sD_ForiegnMaterial_FM_x_C = lstrxml.trade_settle_header[0].fm_wt;
          sMoisture = Number(lstrxml.trade_settle_header[0].moist_pct).toFixed(2)
          sE_Weight_less_FM_C_minus_D = Number(sC_GrossWeight_A_minus_B) - Number(sD_ForiegnMaterial_FM_x_C);

          sSMK1 = lstrxml.trade_settle_header[0].smk_1_pct == "" ? "0.00 " : lstrxml.trade_settle_header[0].smk_1_pct
          sF_ExcessMoisture_FM_x_E = Number(lstrxml.trade_settle_header[0].ex_moist_wt).toFixed(2)

          sSMK2 = lstrxml.trade_settle_header[0].smk_2_pct == "" ? "0.00 " : lstrxml.trade_settle_header[0].smk_2_pct
          sG_NetWeight_E_minus_F = lstrxml.trade_settle_header[0].net_wt;

          sSMK3 = lstrxml.trade_settle_header[0].smk_3_pct == "" ? "0.00 " : lstrxml.trade_settle_header[0].smk_3_pct
          sH_LSK_LSK_x_C = lstrxml.trade_settle_header[0].lsk_wt;

          sSMK4 = lstrxml.trade_settle_header[0].smk_4_pct == "" ? "0.00 " : lstrxml.trade_settle_header[0].smk_4_pct

          sI_NetWeightExclLSK_G_minus_H = Number(sG_NetWeight_E_minus_F) - Number(sH_LSK_LSK_x_C);
          sTotalSMK = Number(lstrxml.trade_settle_header[0].smk_pct).toFixed(2)
          sJ_KernalValue_Ton_ExclLSK =   Number(lstrxml.trade_settle_header[0].kern_value).toFixed(2)
          sSS = Number(lstrxml.trade_settle_header[0].ss_pct).toFixed(2)
          sK_ELKPremium = Number(lstrxml.trade_settle_header[0].elk_prem).toFixed(2)
          sTotal_SMK_plus_SS =  Number(Number(sTotalSMK) + Number(sSS)).toFixed(2)
          sL_Total_J_plus_k = Number(Number(sJ_KernalValue_Ton_ExclLSK) + Number(sK_ELKPremium)).toFixed(2) 
          sFreezeDamage = Number(lstrxml.trade_settle_header[0].frez_dam_pct).toFixed(2)
          sL_a_Damage = Number(lstrxml.trade_settle_header[0].dam_discount).toFixed(2)
          sPittingDamage = Number(lstrxml.trade_settle_header[0].pit_dam_pct).toFixed(2)
          sConcealedRMD = Number(lstrxml.trade_settle_header[0].cncl_rmd_pct).toFixed(2)
          sL_b_ExcessFM = Number(lstrxml.trade_settle_header[0].ex_fm_discount).toFixed(2)
          sTotalDamage = Number(lstrxml.trade_settle_header[0].dam_pct).toFixed(2)
          sL_c_ExcessSplits = Number(lstrxml.trade_settle_header[0].ex_ss_discount).toFixed(2)

          sOtherKernals = Number(lstrxml.trade_settle_header[0].ok_pct).toFixed(2)
          sAflatoxin = Number(lstrxml.trade_settle_header[0].flavus_discount).toFixed(2)

          sTotalKernals =  Number(Number(sTotal_SMK_plus_SS) + Number(sTotalDamage) + Number(sOtherKernals)).toFixed(2);
          sM_TotalDiscounts = Number(Number(sL_a_Damage) + Number(sL_b_ExcessFM) + Number(sL_c_ExcessSplits) + Number(sAflatoxin)).toFixed(2);
          sHULLS = Number(lstrxml.trade_settle_header[0].hull_pct).toFixed(2)
          sN_Value_Ton_ExclLSK_L_minus_M = Number(lstrxml.trade_settle_header[0].net_les_lsk_val).toFixed(2)
          sTotalKernal_Hulls = Number(Number(sTotalKernals) + Number(sHULLS)).toFixed(2);

          sVicam =  lstrxml.trade_settle_header[0].vicam_ppb !== '' ? Number(lstrxml.trade_settle_header[0].vicam_ppb).toFixed(0) : ''

          sEstimatedValue = Number(Number(lstrxml.trade_settle_header[0].net_price) * 100).toFixed(5)
          sHullBrightness = Number(lstrxml.trade_settle_header[0].hul_brit_pct).toFixed(2)
          sInShellJumbo = Number(Number(lstrxml.trade_settle_header[0].jumbo_pct) * 100).toFixed(2)
          sFancy = Number(Number(lstrxml.trade_settle_header[0].fan_pct) * 100).toFixed(2)
          sELK = Number(Number(lstrxml.trade_settle_header[0].elk_pct) * 100).toFixed(2)
          sCrackedAndBroken = Number(Number(lstrxml.trade_settle_header[0].cr_br_pct) * 100).toFixed(2)
          sDiscolored = Number(Number(lstrxml.trade_settle_header[0].dcol_shel_pct) * 100).toFixed(2)
          sRemarks = lstrxml.trade_settle_header[0].remarks == undefined ? '' : lstrxml.trade_settle_header[0].remarks;
          
          sPrevPremCode = "";
          dblStorPremAmt = 0;
          dblStorCharges = 0;
          dblPurhPremAmt = 0;
          dblPurhCharges = 0;

          LstrXML_Subs = lstrxml.trade_settle_prem_deduct;
          colPremDeducts = [];

          for(var i=0; i<LstrXML_Subs.length; i++){
            if(sPrevPremCode !== LstrXML_Subs[i].pd_code){
              if(dblPurhPremAmt !== 0 || dblStorPremAmt !== 0){
                colPremDeduct.purchase_amt = dblPurhPremAmt;
                colPremDeduct.storage_amt = dblStorPremAmt;
                dblPurhPremAmt = 0;
                dblStorPremAmt = 0;
                colPremDeducts.push(colPremDeduct)
              }
              colPremDeduct = {};
              colPremDeduct.pd_code = LstrXML_Subs[i].pd_code;
              sPrevPremCode = LstrXML_Subs[i].pd_code;
              dblPurhPremAmt = Number(dblPurhPremAmt) + Number(LstrXML_Subs[i].pd_amount);
            }
            else{
              dblPurhPremAmt = Number(dblPurhPremAmt) + Number(LstrXML_Subs[i].pd_amount);
            }
            dblPurhCharges = Number(dblPurhCharges) + Number(LstrXML_Subs[i].pd_amount);
          }

          if(dblPurhCharges !== 0 || dblStorPremAmt !== 0){
            colPremDeduct.purchase_amt = dblPurhPremAmt;
            colPremDeduct.storage_amt = dblStorPremAmt;
            dblPurhPremAmt = 0;
            dblStorPremAmt = 0;
          }

          sAuditCount = lstrxml.trade_settle_header[0].revision_num;

          sStatus = sStatus == 'P' ? 'Pending' : sStatus == 'C' ? 'Complete' : sStatus == 'V' ? 'Void' : '';
          
        }
        else{
          return '';
        }
      }
      else
        return '';
      
      i = 0;
      let agreeNum = ``
      let priceType = ``
      let appliedPound = ``

      for(var i=0; i<colAgreements.length; i++){
        var firmOpt = colAgreements[i][1] == 'F' ? 'FIRM' : colAgreements[i][1] == 'B' ? 'BASIS' : colAgreements[i][1] == 'L' ? 'FLAT' : '';
        agreeNum += `${colAgreements[i][0]}<br/>`
        priceType += `${firmOpt}<br/>`
        appliedPound += `${colAgreements[i][2]}<br/>`
      }
      
      let d = `<tr>
            <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
                <span> <b> ${agreeNum.slice(0, agreeNum.length-5)} </b> </span>
            </td>
            <td style="width:40%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
                <span> <b> ${priceType.slice(0, priceType.length-5)} </b> </span>
            </td>
            <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
                <span> <b> ${appliedPound.slice(0, appliedPound.length-5)} </b> </span>
            </td>          
          </tr>`
      var recDel1 = '';
      var recDel2 = '';
      if(sPurch_sale_ind.toLocaleUpperCase() == 'P'){
        if(sShip_to_from.slice(0,4) == 'IDT:'){
          recDel1 = 'Received From Warehouse:  ' + sShip_to_from.slice(5,3) + '&nbsp; &nbsp; &nbsp; &nbsp;     Bin:  ' + sShip_to_from.slice(9,2);
        }
        else{
          recDel1 = 'Received From:  ' + sShip_to_from;
        }
        recDel2 = 'Received Into Warehouse:  ' + sWarehouse + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Bin:  ' + sBin;
      }
      else if(sPurch_sale_ind.toLocaleUpperCase() == 'S'){
        if(sShip_to_from.slice(0,4) == 'IDT:'){
          recDel1 = 'Shipped To Warehouse:  ' + sShip_to_from.slice(5,3) + '&nbsp; &nbsp; &nbsp; &nbsp;     Bin:  ' + sShip_to_from.slice(9,2);
        }
        else{
          recDel1 = 'Shipped To:  ' + sShip_to_from;
        }
        recDel2 = 'Shipped From Warehouse:  ' + sWarehouse + '     Bin:  ' + sBin;
      }

      let vendorData = '';
      for(var i=0; i<colVendors.length; i++){
        if(i == MaxVendors)
          break;
        vendorData += `<tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:70%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">  
            <span> (${colVendors[i].split_vendor}) ${colVendors[i].vendor_name} </span>
          </td>            
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
            <span> ${colVendors[i].split_share_pct.split('.')[0]} </span>
          </td>            
        </tr>`        
      }
      
      let scaleTicketData = '';  
      let optPSeg = 0;    
      for(var i=0; i<colScaleTickets.length; i++){
        if(i == MaxTickets)
          break;
        if(colScaleTickets[i].inventory_with_ind.toLocaleUpperCase() == 'S'){
          scaleTicketData += `<tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
            <span  style="text-align:center;"> ${colScaleTickets[i].shp_scale_tick} </span>
          </td>            
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;">
            <span  style="text-align:center;"> ${colScaleTickets[i].shp_date.split(' ')[0]} </span>
          </td> 
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
            <span  style="text-align:center;"> ${colScaleTickets[i].shp_peanut_wt} </span>
          </td>            
          <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;">
            <span  style="text-align:center;"> ${colScaleTickets[i].vehicle_num} </span>
          </td>            
        </tr>`
        }
        else{
          scaleTicketData += `<tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
            <span  style="text-align:center;"> ${colScaleTickets[i].rec_scale_tick} </span>
          </td>            
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;">
            <span  style="text-align:center;"> ${colScaleTickets[i].rec_date.split(' ')[0]} </span>
          </td> 
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;">
            <span  style="text-align:center;"> ${colScaleTickets[i].rec_peanut_wt} </span>
          </td>            
          <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;">
            <span style="text-align:center;"> ${colScaleTickets[i].vehicle_num} </span>
          </td>            
        </tr>`
        }
        
      }
      
      if(sGPM.toLocaleUpperCase().trim() == 'TKC'){
        for(var i=0; i<colVendors.length; i++){
            lPPounds += (((Number(colVendors[i].net_wt) - Number(colVendors[i].lsk_wt)) * (sTotalSMK / 100)) + Number(colVendors[i].lsk_wt));
            curPBasisGradeAmt += Number(colVendors[i].basis_grade_amt);
            curPSupport += (colVendors[i].support_pct == '' ? 0 : colVendors[i].support_pct);
            curPSeg += (Number(colVendors[i].value_of_seg) - Number(colVendors[i].opt_value_of_seg))
            optPSeg += (Number(colVendors[i].opt_value_of_seg))
            curPProceeds += Number(colVendors[i].proceeds_amt)
        }
      }
      else{
        for(var i=0; i<colVendors.length; i++){
          lPPounds += Number(colVendors[i].net_wt);
          curPBasisGradeAmt += Number(colVendors[i].basis_grade_amt);
          curPSupport += (colVendors[i].support_pct == '' ? 0 : Number(colVendors[i].support_pct));
          curPSeg += (Number(colVendors[i].value_of_seg) - Number(colVendors[i].opt_value_of_seg))
          optPSeg += (Number(colVendors[i].opt_value_of_seg))
          curPProceeds += Number(colVendors[i].proceeds_amt)
        }
      }

      if(sGPM.toLocaleUpperCase().trim() == 'TKC'){
        if(nAgreeCnt > 0 && dblAgreePrice > 0){
          curPSupport = dblAgreePrice / nAgreeCnt;
        }
        else{
          curPSupport = '0.00';
        }
      }
      else{
        curPSupport = Number(curPSupport).toFixed(2);
      }
      
      let premDeductData = ``

      var headerObj = {
        cropYear : cropYearFromLS(),
        sStatus : sStatus,        
        sPurch_sale_ind : sPurch_sale_ind.toLocaleUpperCase() == 'P'  ? 'Type: Trade Purchase' : 'Type: Trade Sale',
        sGPM : sGPM.toLocaleUpperCase() == 'FULL' ? 'Full Grades' : sGPM.toLocaleUpperCase() == 'TKC' ? 'TKC Basis' : sGPM,
        tradeNumber : sTradeNumber,
        agreementDetails : d,
        ssTradeInspNum95 : cropYearFromLS() < 2015 ? 'FV95 # : ' + ssTradeInspNum : 'SC95 # : ' + ssTradeInspNum,
        fedNumber : sFedBPNumber,
        settlementNo : sSettlementNumber,
        inspDateTime : sInspectionDateTime,
        recDel1 : recDel1,
        recDel2 : recDel2,
        vendorData : vendorData,
        seg : sPeanutSeg, 
        type : sPeanutType,
        variety : sPeanutVariety,
        generation : sPeanutGeneration == null ? '' : sPeanutGeneration,
        meetSeedSpec : sMeetsSeedSpec,
        oliec : sOleic == '' ? 'None' : sOleic,
        edible : sEdible_ind,
        oil : sOil_ind,
        scaleTicketData : scaleTicketData
      }

      var obj = {
        crop_year : cropYearFromLS(),
        sStatus : sStatus,        
        sPurch_sale_ind : sPurch_sale_ind.toLocaleUpperCase() == 'P'  ? 'Type: Trade Purchase' : 'Type: Trade Sale',
        sGPM : sGPM.toLocaleUpperCase() == 'FULL' ? 'Full Grades' : sGPM.toLocaleUpperCase() == 'TKC' ? 'TKC Basis' : sGPM,
        tradeNumber : sTradeNumber,
        agreementDetails : d,
        ssTradeInspNum95 : cropYearFromLS() < 2015 ? 'FV95 #: ' + ssTradeInspNum : 'SC95 #: ' + ssTradeInspNum,
        fedNumber : sFedBPNumber,
        settlementNo : sSettlementNumber,
        inspDateTime : sInspectionDateTime,
        recDel1 : recDel1,
        recDel2 : recDel2,
        vendorData : vendorData,
        seg : sPeanutSeg, 
        type : sPeanutType,
        variety : sPeanutVariety,
        generation : sPeanutGeneration == null ? '' : sPeanutGeneration,
        meetSeedSpec : sMeetsSeedSpec,
        oliec : sOleic == '' ? 'None' : sOleic,
        edible : sEdible_ind,
        oil : sOil_ind,
        scaleTicketData : scaleTicketData,
        sA_WeightInclVehicle : sA_WeightInclVehicle,
        sFM : sFM,
        sB_WeightOfVehicle : sB_WeightOfVehicle,
        sLSK: sLSK,
        sC_GrossWeight_A_minus_B : sC_GrossWeight_A_minus_B,
        sLskRiding : sLskRiding,
        sD_ForiegnMaterial_FM_x_C : sD_ForiegnMaterial_FM_x_C,
        sLskFalling : sLskFalling,
        sE_Weight_less_FM_C_minus_D : sE_Weight_less_FM_C_minus_D,
        sLskDamaged : sLskDamaged,
        sF_ExcessMoisture_FM_x_E : sF_ExcessMoisture_FM_x_E,
        sMoisture : sMoisture,
        sG_NetWeight_E_minus_F : sG_NetWeight_E_minus_F,
        sTotalSMK : sTotalSMK,
        sH_LSK_LSK_x_C : sH_LSK_LSK_x_C,
        SMK1 : sSMK1,
        sI_NetWeightExclLSK_G_minus_H : sI_NetWeightExclLSK_G_minus_H,
        SMK2 : sSMK2,
        sJ_KernalValue_Ton_ExclLSK : sJ_KernalValue_Ton_ExclLSK,
        SMK3 : sSMK3,
        sK_ELKPremium : sK_ELKPremium,
        SMK4 : sSMK4,
        sL_Total_J_plus_k : sL_Total_J_plus_k,
        sS : sSS,
        sL_a_Damage : sL_a_Damage,
        sTotal_SMK_plus_SS : sTotal_SMK_plus_SS,
        sL_b_ExcessFM : sL_b_ExcessFM,
        sFreezeDamage : sFreezeDamage,
        sL_c_ExcessSplits : sL_c_ExcessSplits,
        sPittingDamage: sPittingDamage,
        sAflatoxin : sAflatoxin,
        sConcealedRMD : sConcealedRMD,
        sM_TotalDiscounts : sM_TotalDiscounts,
        sTotalDamage : sTotalDamage,
        sN_Value_Ton_ExclLSK_L_minus_M : sN_Value_Ton_ExclLSK_L_minus_M,
        sOtherKernals : sOtherKernals,
        sEstimatedValue : sEstimatedValue + `&nbsp; &cent;`,
        sOKRiding : sOKRiding,
        sOKFalling : sOKFalling,
        sOKDamaged : sOKDamaged,
        sTotalKernals : sTotalKernals,
        lPPounds : lPPounds,
        sHULLS : sHULLS,
        curPBasisGradeAmt : curPBasisGradeAmt,
        sTotalKernal_Hulls : sTotalKernal_Hulls,
        curPSupport : curPSupport,
        sVicam : sVicam,
        curPSeg : Number(curPSeg).toFixed(2),
        optPSeg : Number(optPSeg).toFixed(2),
        sHullBrightness : sHullBrightness,
        sInShellJumbo : sInShellJumbo,
        sFancy : sFancy,
        sELKRiding : sELKRiding,
        sELKDamaged : sELKDamaged,
        sELK : sELK,
        sRidePrescribed : sRidePrescribed,
        sCrackedAndBroken : sCrackedAndBroken,
        sDiscolored : sDiscolored,
        sLoadNum : sLoadNum,
        sLotNum : sLotNum,
        sAuditCount : sAuditCount,
        sRemarks : sRemarks,
        curPProceeds : Number(curPProceeds).toFixed(2)
      }

      if(lstrxml.trade_settle_prem_deduct.length <= 0){
        let col1 = ''
        let col3 = ''
        premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                    <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                        <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; ELK Ride </span>
                    </td>
                    <td style="width:10%;text-align:right;">
                        <span> ${obj.sELKRiding} &nbsp; </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> &nbsp; </span>
                    </td>   
                    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                        <span> </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                        <span> </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                        <span> &nbsp; </span>
                    </td>
                </tr>`
        premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; ELK Damage </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sELKDamaged} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
        premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Net ELK </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sELK} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
        premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ride Prescribed  </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sRidePrescribed} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
        premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Cracked & Broken  </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sCrackedAndBroken} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
        premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Discolored  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sDiscolored} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
        premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span>  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
        premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; Load #: ${obj.sLoadNum}  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
        premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; Lot #: ${obj.sLotNum}  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
        premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; Audit Count: ${obj.sAuditCount}  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
        premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; Remarks: ${obj.sRemarks}  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
                </td>
            </tr>` 
      }
      else{        
        for(var i=0; i<19; i++){
            if(i == 0){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                }
                premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; ELK Ride </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sELKRiding} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
            }
            if(i == 1){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                }
                premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; ELK Damage </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sELKDamaged} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
            }
            if(i == 2){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                }
                premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Net ELK </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sELK} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
            }
            if(i == 3){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                }
                premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ride Prescribed  </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sRidePrescribed} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
            }
            if(i == 4){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                }
                premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Cracked & Broken  </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sCrackedAndBroken} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
            }
            if(i == 5){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                }
                premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Discolored  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sDiscolored} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
            }
            if(i == 6){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                }
                premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span>  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
            }
            if(i == 7){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                }
                premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; Load #: ${obj.sLoadNum}  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
            }
            if(i == 8){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                }
                premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; Lot #: ${obj.sLotNum}  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
            }
            if(i == 9){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                }
                premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; Audit Count: ${obj.sAuditCount}  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col1} </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                </td>
                </tr>`
            }
            if(i == 10){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                }
                premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                    <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                        <span> &nbsp; Remark: ${obj.sRemarks}  </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> &nbsp; </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> </span>
                    </td>   
                    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                        <span> ${col1} </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                        <span> </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col3} &nbsp; </span>
                    </td>
                </tr>`                
            }
            // blank case starts
            if(i == 11){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                    premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                    <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                        <span> </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> &nbsp; </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> </span>
                    </td>   
                    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                        <span> ${col1} </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                        <span> </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col3} &nbsp; </span>
                    </td>
                    </tr>`
                }
            }
            if(i == 12){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                    premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                    <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                        <span> </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> &nbsp; </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> </span>
                    </td>   
                    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                        <span> ${col1} </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                        <span> </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col3} &nbsp; </span>
                    </td>
                    </tr>`
                }
            }
            if(i == 13){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                    premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                    <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                        <span> </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> &nbsp; </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> </span>
                    </td>   
                    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                        <span> ${col1} </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                        <span> </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col3} &nbsp; </span>
                    </td>
                    </tr>`
                }
            }
            if(i == 14){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                    premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                    <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                        <span> </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> &nbsp; </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> </span>
                    </td>   
                    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                        <span> ${col1} </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                        <span> </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col3} &nbsp; </span>
                    </td>
                    </tr>`
                }
            }
            if(i == 15){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                    premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                    <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                        <span> </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> &nbsp; </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> </span>
                    </td>   
                    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                        <span> ${col1} </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                        <span> </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col3} &nbsp; </span>
                    </td>
                    </tr>`
                }
            }
            if(i == 16){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                    premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                    <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                        <span> </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> &nbsp; </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> </span>
                    </td>   
                    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                        <span> ${col1} </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                        <span> </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col3} &nbsp; </span>
                    </td>
                    </tr>`
                }
            }
            if(i == 17){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                    premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                    <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                        <span> </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> &nbsp; </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> </span>
                    </td>   
                    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                        <span> ${col1} </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                        <span> </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col3} &nbsp; </span>
                    </td>
                    </tr>`
                }
            }
            if(i == 18){
                let col1 = ''
                let col3 = ''
                if(i < lstrxml.trade_settle_prem_deduct.length){
                    col1 = lstrxml.trade_settle_prem_deduct[i].pd_code  + (lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == null || lstrxml.trade_settle_prem_deduct[i].pd_vendor_name == undefined ? '' : " - " + lstrxml.trade_settle_prem_deduct[i].pd_vendor_name)
                    col3 = lstrxml.trade_settle_prem_deduct[i].pd_amount
                    premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                    <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                        <span> </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> &nbsp; </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> </span>
                    </td>   
                    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                        <span> ${col1} </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                        <span> </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${col3} &nbsp; </span>
                    </td>
                    </tr>`
                }
            }
        }
      }
      premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                    <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                        <span> </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> &nbsp; </span>
                    </td>
                    <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                        <span> </span>
                    </td>   
                    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
                        <span> Net </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                        <span> </span>
                    </td>
                    <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                    <span> ${obj.curPProceeds} &nbsp; </span>
                    </td>
                    </tr>`
      
      return `<html>
      <style>
      body:before{
          content: '${process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'}';
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;  
          color: #0d745e;
          font-size: 250px;
          font-weight: 500px;
          display: grid;
          justify-content: center;
          align-content: center;
          opacity: 0.35;
      }
      @media print {
          @page :footer { display: none }    
          @page :header { display: none }    
          @page { margin-top: 0; margin-bottom: 0; }    
          html,
          body { height: 100%; margin: 0 !important; padding: 0 !important; }
      }     
      </style>
      <p style="page-break-after: always">
        ${await rptTradeSettlementWorkSheetHeader(headerObj)}
        <table style="font-size: 70%;width:100%;border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial';">      
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; <b> Grades: </b> </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span>  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; A. Weight Including Vehicle </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sA_WeightInclVehicle} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; FM </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span>  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sFM} &nbsp; </span>
                </td>
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; B. Weight of Vehicle </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">  
                  <span> ${obj.sB_WeightOfVehicle} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; LSK </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span>  </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sLSK} &nbsp; </span>
                </td>
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; C. Gross Weight (A-B) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">  
                  <span> ${obj.sC_GrossWeight_A_minus_B} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LSK Riding Screen </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sLskRiding} &nbsp; </span>
                </td>
                <td style="width:10%;text-align:left;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
                    <span> </span>
                </td>
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; D. Foreign Material (FM x C) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">  
                  <span> ${obj.sD_ForiegnMaterial_FM_x_C} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LSK Fall Through </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sLskFalling} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> </span>
                </td>
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; E. Weight Less FM (C-D) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sE_Weight_less_FM_C_minus_D} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LSK Damage Riding </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sLskDamaged} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> </span>
                </td>
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; F. Excess Moisture (EM x E) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">  
                  <span> ${obj.sF_ExcessMoisture_FM_x_E} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Moisture </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sMoisture} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; G. Net Weight (E-F) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sG_NetWeight_E_minus_F} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Total SMK </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sTotalSMK} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; H. LSK (LSK x C) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sH_LSK_LSK_x_C} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK1 </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.SMK1} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;font-size:90%;"> 
                    <span> &nbsp; I. Net Weight Excluding LSK (G - H) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sI_NetWeightExclLSK_G_minus_H} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK2 </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.SMK2} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;font-size:90%;"> 
                    <span> &nbsp; J. Kernal Value/Ton (Excluding LSK) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sJ_KernalValue_Ton_ExclLSK} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK3 </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.SMK3} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; K. ELK Premium  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sK_ELKPremium} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 0.1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK4 </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.SMK4} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; L. Total (J + K) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sL_Total_J_plus_k} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; SS </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sS} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Damage </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sL_a_Damage} &nbsp; </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> &nbsp; </span>
                </td>                
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Total (SMK + SS) </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sTotal_SMK_plus_SS} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Excess FM </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sL_b_ExcessFM} &nbsp; </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Freeze Damage </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sFreezeDamage} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Excess Splits </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sL_c_ExcessSplits} &nbsp; </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Pitting Damage </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sPittingDamage} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Flavus Splits </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sAflatoxin} &nbsp; </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Concealed RMD </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sConcealedRMD} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; M. Total Discounts </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sM_TotalDiscounts} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Total Damage </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sTotalDamage} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; N. Value/Ton Excluding LSK (L - M) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sN_Value_Ton_ExclLSK_L_minus_M} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Other Kernals </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sOtherKernals} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Estimated Value Per Pound </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sEstimatedValue} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; OK Riding Screen </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sOKRiding} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; OK Fall Through </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sOKFalling} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 0.1px;border-color: white white black white;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white white black white;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 0.1px;border-color: white white black white;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; OK Damage Riding </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sOKDamaged} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
                  <span> Purchase </span>
                </td>
            </tr> 
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Total Kernels </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sTotalKernals} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; LBS </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.lPPounds} &nbsp; </span>
                </td>
            </tr> 
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Hulls </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sHULLS} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; x Line P </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.curPBasisGradeAmt} &nbsp; </span>
                </td>
            </tr>           
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Total Kernals & Hulls </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sTotalKernal_Hulls} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; x </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.curPSupport} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Vicam </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sVicam} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Farmer Stock Cost </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.curPSeg} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hull Brightness </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sHullBrightness} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Value of Segment </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.curPSeg} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In Shell Jumbo </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sInShellJumbo} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Option Payment </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.optPSeg} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Fancy </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sFancy} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp;&nbsp;&nbsp; <b> Premium / Deductions: </b> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
            </tr>
            ${premDeductData}     
        </table>
      </p>
      </html>
      `;
    }
    catch (err) {
        errorHandler(err);
    }
  }

  async function rptDelvSettlementWorkSheet(sTradeNumber, buyingPt) {
    try {
      let lstrxml = [];
      let LstrXML_Subs = [];
      let sPrevPremCode = '';
      let dblStorPremAmt = '';
      let dblPurhPremAmt = '';
      let dblStorCharges = '';
      let dblPurhCharges = '';

      let sCropYear = '';
      let ssTradeInspNum = '';
      let sFedBPNumber = '';
      let sSettlementNumber = '';
      let sBuyingPoint = '';
      let sPricingType = '';
      let sGPM = '';
      let sShip_to_from = '';
      let sAgreement_num = '';
      let sApplied_lbs = '';
      let sFirm_basis_opt = '';

      let sEdible_ind = '';
      let sOil_ind = '';
      let nAgreement = 0;

      let sInspectionDateTime = '';
      let sState = '';
      let sStatus = '';
      let sPurch_sale_ind = '';
      let sCounty = '';
      let sFarmNumber = '';
      let sProducer = '';
      let colVendors = []; 
      let colVendor = []; 

      let colAgreements = []; 
      let colAgreement = []; 

      let sPeanutSeg = '';
      let sPeanutType = '';
      let sPeanutVariety = '';
      let sPeanutGeneration = '';
      let sProbingPattern = '';
      let sWarehouse = '';
      let sBin = '';
      let colScaleTickets = []; 
      let colScaleTicket = []; 
      let sMeetsSeedSpec = '';
      let sOleic = '';
      let sA_WeightInclVehicle = '';
      let sB_WeightOfVehicle = '';
      let sFM = '';
      let sC_GrossWeight_A_minus_B = '';
      let sLSK = '';
      let sD_ForiegnMaterial_FM_x_C = '';
      let sMoisture = '';
      let sE_Weight_less_FM_C_minus_D = '';
      let sSMK1 = '';
      let sF_ExcessMoisture_FM_x_E = '';
      let sSMK2 = '';
      let sG_NetWeight_E_minus_F = '';
      let sSMK3 = '';
      let sH_LSK_LSK_x_C = '';
      let sSMK4 = '';
      let sI_NetWeightExclLSK_G_minus_H = '';
      let sTotalSMK = '';
      let sJ_KernalValue_Ton_ExclLSK = '';
      let sSS = '';
      let sK_ELKPremium = '';
      let sTotal_SMK_plus_SS = '';
      let sL_Total_J_plus_k = '';
      let sFreezeDamage = '';
      let sPittingDamage = '';
      let sL_a_Damage = '';
      let sConcealedRMD = '';
      let sL_b_ExcessFM = '';
      let sTotalDamage = '';
      let sL_c_ExcessSplits = '';
      let sOtherKernals = '';
      let sAflatoxin = '';
      let sTotalKernals = '';
      let sM_TotalDiscounts = '';
      let sHULLS = '';
      let sN_Value_Ton_ExclLSK_L_minus_M = '';
      let sTotalKernal_Hulls = '';
      let sVicam = '';
      let sEstimatedValue = '';
      let sHullBrightness = '';
      let sInShellJumbo = '';
      let sFancy = '';
      let sELK = '';
      let sCrackedAndBroken = '';
      let sDiscolored = '';
      let sRemarks = '';
      let sAuditCount = '';
      let colPremDeducts = []; 
      let colPremDeduct = []; 

      let nVendor = 0;
      let nTicket = 0;
      let nPremDed = 0;

      let lSPounds = 0;
      let lPPounds = 0;
      let curSBasisGradeAmt = '';
      let curPBasisGradeAmt = '';
      let curSSupport = '';
      let curPSupport = '';
      let curSSeg = '';
      let curPSeg = '';
      let curSProceeds = '';
      let curPProceeds = '';
      let curPConvPct = '';
      let curPConvValue = '';
      let curPAdjValue = '';
      let curPValueLessAdjValue = '';

      let LstrText = '';
      let LstrXMLAgreements = [];

      let LintYPremDed = 0;

      let sLskRiding = '';
      let sLskFalling = '';
      let sLskDamaged = '';
      let sOKRiding = '';
      let sOKFalling = '';
      let sOKDamaged = '';
      let sELKRiding = '';
      let sELKDamaged = '';
      let sRidePrescribed = '';

      let sLoadNum = '';
      let sLotNum = '';

      let dblAgreePrice = 0;
      let nAgreeCnt = 0;
      let n = 0;

      let LstrXMLAgreementsTemp = [];
      let dblTotalSumAgreeValue = 0;
      let dblTotalSumAdjValue = 0;
      let dblPctSumAgreeValue = 0;
      let dblConvPct = 0;
      let dblOptionPayment = 0; 

      const MaxVendors = 20
      const MaxTickets = 5
      const MaxPremDeds = 19

      msReport = "DELV_SETTLEMENT_WORKSHEET";

      let response = await SettlementService.RetrieveTradeSettlementDetails(buyingPt, sTradeNumber , null, null)
      if(response?.length <= 0){
        return '';
      }
        
      lstrxml = response[0];
      
      dblAgreePrice = 0;
      nAgreeCnt = 0;

      let response2 = await SettlementService.RetrieveTradeSettlementApplicationDetails(sTradeNumber, 'Y')
      if(response2?.status == 500){
        return ''
      }
      LstrXMLAgreements = response2;
        
      LstrXMLAgreementsTemp =LstrXMLAgreements;
      dblTotalSumAgreeValue = 0;
      dblTotalSumAdjValue = 0;
      
      for(var i=0; i<LstrXMLAgreementsTemp?.length; i++){
        if(Number(response2[i]?.delivered_lbs_adj) > 0){
          dblTotalSumAgreeValue += Number(LstrXMLAgreementsTemp[i]?.agree_value);
          dblTotalSumAdjValue += Number(LstrXMLAgreementsTemp[i]?.unapp_dol_value);
        }
      }
      
      dblPctSumAgreeValue = 1;
      dblConvPct = 0;
      colAgreements = [];
      
      for(var i=0; i<LstrXMLAgreements?.length; i++){
        if(Number(LstrXMLAgreements[i]?.delivered_lbs_adj) > 0){
          colAgreement = {
            agreement_num    : LstrXMLAgreements[i].agreement_num,
            firm_basis_opt   : LstrXMLAgreements[i].firm_basis_opt,
            settle_dol_value : LstrXMLAgreements[i].settle_dol_value == "0.00" ? 0 : LstrXMLAgreements[i].settle_dol_value,
            delv_agree_dol   : LstrXMLAgreements[i].delv_agree_dol,
            rcpt_value       : LstrXMLAgreements[i].rcpt_value
          };

          if(LstrXMLAgreements[i]?.price_per_lbs?.trim() !== ''){
            dblAgreePrice += Number(LstrXMLAgreements[i].price_per_lbs).toFixed(7)
            nAgreeCnt++;
          }

          dblPctSumAgreeValue = (Number(LstrXMLAgreements[i]?.agree_value) / (Number(dblTotalSumAgreeValue) > 0 ? Number(dblTotalSumAgreeValue) : 1 ));
          
          if(lstrxml?.trade_settle_header[0]?.seg_type == LstrXMLAgreements[i]?.seg_type){
            dblConvPct += ((Number(LstrXMLAgreements[i]?.delv_agree_dol) / Number(LstrXMLAgreements[i]?.rcpt_value)) * dblPctSumAgreeValue);
          }
          else{
            dblConvPct++;
          }
          colAgreements.push(colAgreement);
        }        
      }
      
      i = 0;
      let d = ''
      while(i < colAgreements.length){
        var firmOpt = colAgreements[i]?.firm_basis_opt == 'F' ? 'FIRM' 
                                                              : colAgreements[i].firm_basis_opt == 'B' ? 'BASIS' 
                                                              : colAgreements[i].firm_basis_opt == 'L' ? 'FLAT' 
                                                              : '';
        d += `<tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
                    <span style="text-align:center"> <b> ${colAgreements[i].agreement_num} </b> </span>
                </td>
                <td style="width:40%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
                    <span style="text-align:center"> <b> ${firmOpt} </b> </span>
                </td>
                <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
                  <span style="text-align:center"> <b> ${colAgreements[i].settle_dol_value} </b> </span>
                </td>
            </tr>`
        i++;      
      }

      sCropYear = lstrxml?.trade_settle_header[0]?.crop_year;
      ssTradeInspNum = lstrxml?.trade_settle_header[0]?.trade_insp_num;
      sFedBPNumber = lstrxml?.trade_settle_header[0]?.buy_pt_fed_id;
      sSettlementNumber = lstrxml?.trade_settle_header[0]?.trade_settle_num;

      msPDF_BP = buyingPt;
      msPDF_SettlementNo = '0'.repeat(7 - ssTradeInspNum?.length) +  ssTradeInspNum?.trim() 

      sLskRiding = Number(lstrxml?.trade_settle_header[0]?.lsk_ride_pct).toFixed(2)
      sLskFalling = Number(lstrxml?.trade_settle_header[0]?.lsk_fall_pct).toFixed(2)
      sLskDamaged = Number(lstrxml?.trade_settle_header[0]?.lsk_dam_pct).toFixed(2)
      sOKRiding = Number(lstrxml?.trade_settle_header[0]?.ok_ride_pct).toFixed(2)
      sOKFalling = Number(lstrxml?.trade_settle_header[0]?.ok_fall_pct).toFixed(2)
      sOKDamaged = Number(lstrxml?.trade_settle_header[0]?.ok_dam_pct).toFixed(2)
      sELKRiding = Number(lstrxml?.trade_settle_header[0]?.elk_ride_pct).toFixed(2)
      sELKDamaged = Number(lstrxml?.trade_settle_header[0]?.elk_dam_pct).toFixed(2)
      sRidePrescribed = Number(lstrxml?.trade_settle_header[0]?.ride_pscrib_pct).toFixed(2)

      if(lstrxml?.trade_settle_header[0]?.seed_meeting_spec_ind?.trim() == 'Y'){
        sMeetsSeedSpec = 'Yes'
      }        
      else if(lstrxml?.trade_settle_header[0]?.seed_meeting_spec_ind?.trim() == 'N'){
        sMeetsSeedSpec = 'No'
      }
      else{
        sMeetsSeedSpec = ''
      }       
      
      sLoadNum = lstrxml?.trade_settle_header[0]?.load_num;
      sLotNum = lstrxml?.trade_settle_header[0]?.lot_num;

      sBuyingPoint = lstrxml?.trade_settle_header[0]?.buy_pt_name + "   (" + lstrxml?.trade_settle_header[0]?.buy_pt_id + ")";
      sInspectionDateTime = lstrxml?.trade_settle_header[0]?.insp_date_time == '' ? '' : formatDate2(lstrxml?.trade_settle_header[0]?.insp_date_time) //moment(lstrxml?.trade_settle_header[0]?.insp_date_time).format('MM/DD/YYYY HH:MM A')
      sStatus = lstrxml?.trade_settle_header[0]?.trade_status;
      sPurch_sale_ind = lstrxml?.trade_settle_header[0]?.purch_sale_ind;
      sGPM = lstrxml?.trade_settle_header[0]?.grade_pricing_method;
      sShip_to_from = lstrxml?.trade_settle_header[0]?.ship_to_from;

      if(lstrxml?.trade_settle_header[0]?.edible_oil_ind?.trim() == 'E'){
        sEdible_ind = 'Yes'
      }
      else if(lstrxml?.trade_settle_header[0]?.edible_oil_ind?.trim() == 'O'){
        sOil_ind = 'Yes'
      }        

      LstrText = ''
      if(lstrxml?.trade_settle_header[0]?.superseded_settle?.trim() !== ''){
        LstrText = 'Superceded ' + lstrxml?.trade_settle_header[0]?.superseded_settle?.trim()
      }        
        
      if(Number(lstrxml?.trade_settle_header[0]?.settle_correct_cnt) > 0){
        if(LstrText !== ''){
          LstrText += ' & '
        }
        LstrText += ('Correction #' + lstrxml?.trade_settle_header[0]?.settle_correct_cnt?.trim())
      }

      LstrXML_Subs = lstrxml?.trade_settle_vendor_split; 
      sProducer = LstrXML_Subs[0]?.vendor_name;

      colVendors = [];
      for(var i=0; i< LstrXML_Subs?.length; i++){
        colVendor = {
          split_vendor     : LstrXML_Subs[i]?.split_vendor || '',
          split_remit      : LstrXML_Subs[i]?.split_remit || '',
          vendor_name      : LstrXML_Subs[i]?.vendor_name || '',
          taxid            : LstrXML_Subs[i]?.taxid || '',
          remit_name       : LstrXML_Subs[i]?.remit_name || '',
          address1         : LstrXML_Subs[i]?.address1 || '',
          address2         : LstrXML_Subs[i]?.address2 || '',
          city             : LstrXML_Subs[i]?.city || '',
          state            : LstrXML_Subs[i]?.state || '',
          zipcode          : LstrXML_Subs[i]?.zipcode || '',
          split_share_pct  : LstrXML_Subs[i]?.split_share_pct || 0,
          whse_rcpt_bp     : LstrXML_Subs[i]?.whse_rcpt_bp || '',
          whse_rcpt_num    : LstrXML_Subs[i]?.whse_rcpt_num || '',  
          purh_stor_ind    : LstrXML_Subs[i]?.purh_stor_ind?.trim() == 'P' ? (LstrXML_Subs[i]?.whse_rcpt_num?.trim()?.toLocaleUpperCase() == "" ? 'P' : 'S') : 'S',
          gross_wt         : LstrXML_Subs[i]?.gross_wt || 0,
          lsk_wt           : LstrXML_Subs[i]?.lsk_wt || 0,
          net_wt           : LstrXML_Subs[i]?.net_wt || 0,  
          basis_grade_amt  : LstrXML_Subs[i]?.basis_grade_amt || 0,
          value_per_ton    : LstrXML_Subs[i]?.value_per_ton || 0,
          support_pct      : LstrXML_Subs[i]?.support_pct || '',
          value_of_seg     : LstrXML_Subs[i]?.value_of_seg || 0,
          opt_value_of_seg : LstrXML_Subs[i]?.opt_value_of_seg || '',
          premium_amt      : LstrXML_Subs[i]?.premium_amt || '',
          deduct_amt       : LstrXML_Subs[i]?.deduct_amt || '',
          proceeds_amt     : LstrXML_Subs[i]?.proceeds_amt || '',
        }
        colVendors.push(colVendor)        
      }

      sPeanutSeg = lstrxml?.trade_settle_header[0]?.seg_type;
      sPeanutType = '(' + lstrxml?.trade_settle_header[0]?.pnut_type_id + ') ' + lstrxml?.trade_settle_header[0]?.pnut_type_name;
      sPeanutVariety = '(' + lstrxml?.trade_settle_header[0]?.pnut_variety_id + ') ' + lstrxml?.trade_settle_header[0]?.pnut_variety_name;

      if(lstrxml?.trade_settle_header[0]?.symbol_ind?.trim()?.toLocaleUpperCase() == 'TRADEMARK'){
        sPeanutVariety += `&reg;`
      }
      else if(lstrxml?.trade_settle_header[0]?.symbol_ind?.trim().toLocaleUpperCase() == 'REGISTERED TRADEMARK'){
        sPeanutVariety += `&trade;`
      }
      else if(lstrxml?.trade_settle_header[0]?.symbol_ind?.trim().toLocaleUpperCase() == 'COPYRIGHT'){
        sPeanutVariety += `&copy;`
      }        

    sPeanutGeneration = lstrxml?.trade_settle_header[0]?.gen_produced_name;
    sProbingPattern = lstrxml?.trade_settle_header[0]?.probe_pattern;
    sWarehouse = lstrxml?.trade_settle_header[0]?.inventory_whouse;
    sBin = lstrxml?.trade_settle_header[0]?.inventory_bin;

    LstrXML_Subs = lstrxml?.trade_settle_scale_tickets[0];
    colScaleTickets = [];

    for(var i=0; i<LstrXML_Subs.trade_settle_scale_ticket?.length; i++){
      colScaleTicket = {
        load_num           : LstrXML_Subs?.trade_settle_scale_ticket[i]?.load_num,
        vehicle_num        : LstrXML_Subs?.trade_settle_scale_ticket[i]?.vehicle_num,
        truck_line         : LstrXML_Subs?.trade_settle_scale_ticket[i]?.truck_line,
        driver_name        : LstrXML_Subs?.trade_settle_scale_ticket[i]?.driver_name,
        shp_date           : LstrXML_Subs?.trade_settle_scale_ticket[i]?.shp_date,
        shp_scale_tick     : LstrXML_Subs?.trade_settle_scale_ticket[i]?.shp_scale_tick,
        shp_split_scale    : LstrXML_Subs?.trade_settle_scale_ticket[i]?.shp_split_scale,
        shp_tare_wt        : LstrXML_Subs?.trade_settle_scale_ticket[i]?.shp_tare_wt,
        shp_vehicle_wt     : LstrXML_Subs?.trade_settle_scale_ticket[i]?.shp_vehicle_wt,
        shp_peanut_wt      : LstrXML_Subs?.trade_settle_scale_ticket[i]?.shp_peanut_wt,
        rec_date           : LstrXML_Subs?.trade_settle_scale_ticket[i]?.rec_date,
        rec_scale_tick     : LstrXML_Subs?.trade_settle_scale_ticket[i]?.rec_scale_tick,
        rec_split_scale    : LstrXML_Subs?.trade_settle_scale_ticket[i]?.rec_split_scale,
        rec_tare_wt        : LstrXML_Subs?.trade_settle_scale_ticket[i]?.rec_tare_wt,
        rec_vehicle_wt     : LstrXML_Subs?.trade_settle_scale_ticket[i]?.rec_vehicle_wt,
        rec_peanut_wt      : LstrXML_Subs?.trade_settle_scale_ticket[i]?.rec_peanut_wt,
        inventory_with_ind : LstrXML_Subs?.trade_settle_scale_ticket[i]?.inventory_with_ind,
        inventory_other_wt : LstrXML_Subs?.trade_settle_scale_ticket[i]?.inventory_other_wt
      }
      colScaleTickets.push(colScaleTicket)
    }

    sOleic = lstrxml?.trade_settle_header[0]?.oleic_ind;
    sA_WeightInclVehicle = lstrxml?.trade_settle_header[0]?.tare_wt;
    sB_WeightOfVehicle = lstrxml?.trade_settle_header[0]?.vehicle_wt;
    sFM = Number(lstrxml?.trade_settle_header[0]?.fm_pct).toFixed(2)
    sC_GrossWeight_A_minus_B = lstrxml?.trade_settle_header[0]?.gross_wt;
    sLSK = Number(lstrxml?.trade_settle_header[0]?.lsk_pct).toFixed(2)
    sD_ForiegnMaterial_FM_x_C = lstrxml?.trade_settle_header[0]?.fm_wt;
    sMoisture = Number(lstrxml?.trade_settle_header[0]?.moist_pct).toFixed(2)
    sE_Weight_less_FM_C_minus_D = Number(sC_GrossWeight_A_minus_B) - Number(sD_ForiegnMaterial_FM_x_C);

    sSMK1 = Number(lstrxml?.trade_settle_header[0]?.smk_1_pct).toFixed(2)
    sF_ExcessMoisture_FM_x_E = Number(lstrxml?.trade_settle_header[0]?.ex_moist_wt).toFixed(2)

    sSMK2 = Number(lstrxml?.trade_settle_header[0]?.smk_2_pct).toFixed(2)
    sG_NetWeight_E_minus_F = lstrxml?.trade_settle_header[0]?.net_wt;

    sSMK3 = Number(lstrxml?.trade_settle_header[0]?.smk_3_pct).toFixed(2)
    sH_LSK_LSK_x_C = lstrxml?.trade_settle_header[0]?.lsk_wt;

    sSMK4 = Number(lstrxml?.trade_settle_header[0]?.smk_4_pct).toFixed(2)

    sI_NetWeightExclLSK_G_minus_H = Number(sG_NetWeight_E_minus_F) - Number(sH_LSK_LSK_x_C);
    sTotalSMK = Number(lstrxml?.trade_settle_header[0]?.smk_pct).toFixed(2)
    sJ_KernalValue_Ton_ExclLSK = Number(lstrxml?.trade_settle_header[0]?.kern_value).toFixed(2)
    sSS = Number(lstrxml?.trade_settle_header[0]?.ss_pct).toFixed(2)
    sK_ELKPremium = Number(lstrxml?.trade_settle_header[0]?.elk_prem).toFixed(2)
    sTotal_SMK_plus_SS = Number(Number(sTotalSMK) + Number(sSS)).toFixed(2)
    sL_Total_J_plus_k = Number(Number(sJ_KernalValue_Ton_ExclLSK) + Number(sK_ELKPremium)).toFixed(2)
    sFreezeDamage = Number(lstrxml?.trade_settle_header[0]?.frez_dam_pct).toFixed(2)
    sL_a_Damage = Number(lstrxml?.trade_settle_header[0]?.dam_discount).toFixed(2)
    sPittingDamage = Number(lstrxml?.trade_settle_header[0]?.pit_dam_pct).toFixed(2)
    sConcealedRMD = Number(lstrxml?.trade_settle_header[0]?.cncl_rmd_pct).toFixed(2)
    sL_b_ExcessFM = Number(lstrxml?.trade_settle_header[0]?.ex_fm_discount).toFixed(2)
    sTotalDamage = Number(lstrxml?.trade_settle_header[0]?.dam_pct).toFixed(2)
    sL_c_ExcessSplits = Number(lstrxml?.trade_settle_header[0]?.ex_ss_discount).toFixed(2)

    sOtherKernals = Number(lstrxml?.trade_settle_header[0]?.ok_pct).toFixed(2)
    sAflatoxin = Number(lstrxml?.trade_settle_header[0]?.flavus_discount).toFixed(2)

    sTotalKernals = Number(Number(sTotal_SMK_plus_SS) + Number(sTotalDamage) + Number(sOtherKernals)).toFixed(2)
    sM_TotalDiscounts = Number(Number(sL_a_Damage) + Number(sL_b_ExcessFM) + Number(sL_c_ExcessSplits) + Number(sAflatoxin)).toFixed(2)
    sHULLS = Number(lstrxml?.trade_settle_header[0]?.hull_pct).toFixed(2)
    sN_Value_Ton_ExclLSK_L_minus_M = Number(lstrxml?.trade_settle_header[0]?.net_les_lsk_val).toFixed(2)
    sTotalKernal_Hulls = Number(Number(sTotalKernals) + Number(sHULLS)).toFixed(2)

    sVicam =  lstrxml?.trade_settle_header[0]?.vicam_ppb !== '' ? Number(lstrxml?.trade_settle_header[0]?.vicam_ppb).toFixed(2) : ''

    sEstimatedValue = Number(Number(lstrxml?.trade_settle_header[0]?.net_price) * 100).toFixed(5)
    sHullBrightness = Number(lstrxml?.trade_settle_header[0]?.hul_brit_pct).toFixed(2)
    sInShellJumbo = Number(lstrxml?.trade_settle_header[0]?.jumbo_pct).toFixed(2)
    sFancy = Number(lstrxml?.trade_settle_header[0]?.fan_pct * 100).toFixed(2)
    sELK = Number(lstrxml?.trade_settle_header[0]?.elk_pct * 100).toFixed(2)
    sCrackedAndBroken = Number(lstrxml?.trade_settle_header[0]?.cr_br_pct * 100).toFixed(2)
    sDiscolored = Number(lstrxml?.trade_settle_header[0]?.dcol_shel_pct * 100).toFixed(2)
    sRemarks = (lstrxml?.trade_settle_header[0]?.remarks || '')?.trim()
    
    // If GetInside(lstrxml, "<Agreement_numbers>", "</Agreement_numbers>") <> "" Then
    //         sRemarks = IIf(sRemarks = "", vbCrLf, sRemarks & vbCrLf & vbCrLf)
    //         sRemarks = sRemarks & "Agreement Numbers: " & GetInside(lstrxml, "<Agreement_numbers>", "</Agreement_numbers>")
    // End If

    // if(lstrxml?.Agreement_numbers !== ''){
    //     if(sRemarks !== ''){
    //         sRemarks += '\n'
    //     }
        
    //     sRemarks += ("Agreement Numbers: " + lstrxml?.Agreement_numbers)
    // }

    sPrevPremCode = "";
    dblStorPremAmt = 0;
    dblStorCharges = 0;
    dblPurhPremAmt = 0;
    dblPurhCharges = 0;    

    var recDel1 = '';
    var recDel2 = '';
    if (sPurch_sale_ind?.trim()?.toLocaleUpperCase() == 'P') {
        recDel1 = 'Received From:  ' + (sPurch_sale_ind?.trim()?.toLocaleUpperCase() == 'P' ? sShip_to_from : '');
        recDel2 = 'Received Into Warehouse:  ' + (sPurch_sale_ind?.trim()?.toLocaleUpperCase() == 'P' ? sWarehouse : '');
        recDel2 += '&nbsp; &nbsp; &nbsp; &nbsp;    Bin:  ' + (sPurch_sale_ind?.trim()?.toLocaleUpperCase() == 'P' ? sBin : '');
    }
    else if (sPurch_sale_ind.toLocaleUpperCase() == 'S') {
        recDel1 = 'Shipped To:  ' + (sPurch_sale_ind?.trim()?.toLocaleUpperCase() == 'S' ? sShip_to_from : '');
        recDel2 = 'Shipped From Warehouse:  ' + (sPurch_sale_ind?.trim()?.toLocaleUpperCase() == 'S' ? sWarehouse : '');
        recDel2 += '&nbsp; &nbsp; &nbsp; &nbsp;    Bin:  ' + (sPurch_sale_ind?.trim()?.toLocaleUpperCase() == 'S' ? sBin : '');
    }
    
    let vendorData = '';
    for(var i=0; i<colVendors?.length; i++){
      if(i == MaxVendors){
        break;
      }

      vendorData += `<tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:75%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">  
            <span> (${colVendors[i].split_vendor}) ${colVendors[i].vendor_name} ${colVendors[i].city}  ${colVendors[i].state}  ${colVendors[i].zipcode}</span>
          </td>            
          <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
            <span> ${colVendors[i].split_share_pct?.split('.')[0]} </span>
          </td>            
        </tr>`      
    }

    let scaleTicketData = '';      
    for(var i=0; i<colScaleTickets?.length; i++){
        if(i == MaxTickets){
            break;
        }
          
        if(colScaleTickets[i].inventory_with_ind.toLocaleUpperCase() == 'S'){
          scaleTicketData += `<tr style="text-align:center;">
            <td style="width:15%;border: 1px solid black;" colspan="1"> 
                <span style="text-align:center"> ${colScaleTickets[i].shp_scale_tick} </span>
            </td>          
            <td style="width:25%;border: 1px solid black;" colspan="1">
                <span style="text-align:center"> ${colScaleTickets[i].shp_date?.split(' ')[0]} </span>
              </td>
            <td style="width:40%;border: 1px solid black;" colspan="1"> 
                <span style="text-align:center"> ${colScaleTickets[i].shp_peanut_wt} </span>
            </td>          
            <td style="width:20%;border: 1px solid black;" colspan="3"> 
                <span style="text-align:center"> ${colScaleTickets[i].vehicle_num} </span>
              </td>        
            </tr>`
        }
        else{
          scaleTicketData += `<tr style="text-align:center;">
          <td style="width:15%;border: 1px solid black;" colspan="1"> 
            <span style="text-align:center"> ${colScaleTickets[i].rec_scale_tick} </span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
            <span style="text-align:center"> ${colScaleTickets[i].rec_date?.split(' ')[0]} </span>
          </td> 
          <td style="width:40%;border: 1px solid black;" colspan="1"> 
            <span style="text-align:center"> ${colScaleTickets[i].rec_peanut_wt} </span>
          </td>            
          <td style="width:20%;border: 1px solid black;" colspan="3"> 
            <span style="text-align:center"> ${colScaleTickets[i].vehicle_num} </span>
          </td>            
        </tr>`
        }    
    }

    LstrXML_Subs = lstrxml.trade_settle_prem_deduct;
    colPremDeducts = [];

    for(var i=0; i<LstrXML_Subs.length; i++){
      if(sPrevPremCode !== LstrXML_Subs[i].pd_code){
        if(dblPurhPremAmt !== 0 || dblStorPremAmt !== 0){
          colPremDeduct.purchase_amt = dblPurhPremAmt;
          colPremDeduct.storage_amt = dblStorPremAmt;
          dblPurhPremAmt = 0;
          dblStorPremAmt = 0;
          colPremDeducts.push(colPremDeduct);
        }
        colPremDeduct = {};
        colPremDeduct.pd_code = LstrXML_Subs[i].pd_code;
        sPrevPremCode = LstrXML_Subs[i].pd_code;
        dblPurhPremAmt = Number(dblPurhPremAmt) + Number(LstrXML_Subs[i].pd_amount);
      }
      else{
        dblPurhPremAmt = Number(dblPurhPremAmt) + Number(LstrXML_Subs[i].pd_amount);
      }
      dblPurhCharges = Number(dblPurhCharges) + Number(LstrXML_Subs[i].pd_amount);
    }

    if(dblPurhCharges !== 0 || dblStorPremAmt !== 0){
      colPremDeduct.purchase_amt = dblPurhPremAmt;
      colPremDeduct.storage_amt = dblStorPremAmt;
      dblPurhPremAmt = 0;
      dblStorPremAmt = 0;
    }

    for(var i=0; i<colVendors?.length; i++){
      if(sGPM.trim().toLocaleUpperCase() == "TKC"){
        lPPounds += ((Number(colVendors[i].net_wt) - Number(colVendors[i].lsk_wt)) * (sTotalSMK/100)) + Number(colVendors[i].lsk_wt);
      }
      else{
        lPPounds += Number(colVendors[i].net_wt);
      }      
      curPBasisGradeAmt += Number(colVendors[i].basis_grade_amt)
      curPProceeds += (colVendors[i].proceeds_amt == '' ? 0 : Number(colVendors[i].proceeds_amt))

      curPSeg += (Number(colVendors[i].value_of_seg) - Number(colVendors[i].opt_value_of_seg))
      dblOptionPayment += Number(colVendors[i].opt_value_of_seg)
    }

    sAuditCount = lstrxml?.trade_settle_header[0]?.revision_num;
    sStatus = sStatus == 'P' ? 'Pending' 
                             : sStatus == 'C' ? 'Complete' 
                             : sStatus == 'V' ? 'Void' 
                             : '';

    var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test';

    var headerObj = {
        cropYear : cropYearFromLS(),
        sStatus : sStatus,        
        sPurch_sale_ind : sPurch_sale_ind?.trim()?.toLocaleUpperCase() == 'P' ? 'Type: Delivery In' : 'Type: Delivery Out',
        sGPM : sGPM?.trim()?.toLocaleUpperCase() == 'FULL' ? 'Full Grades' : sGPM.toLocaleUpperCase() == 'TKC' ? 'TKC Basis' : sGPM,
        tradeNumber : sTradeNumber,
        agreementDetails : d,
        ssTradeInspNum95 : cropYearFromLS() < 2015 ? 'FV95 # : ' + ssTradeInspNum : 'SC95 # : ' + ssTradeInspNum,
        fedNumber : sFedBPNumber,
        settlementNo : sSettlementNumber,
        inspDateTime : sInspectionDateTime,
        recDel1 : recDel1,
        recDel2 : recDel2,
        vendorData : vendorData,
        seg : sPeanutSeg, 
        type : sPeanutType,
        variety : sPeanutVariety,
        generation : sPeanutGeneration == null ? '' : sPeanutGeneration,
        meetSeedSpec : sMeetsSeedSpec,
        oliec : sOleic == '' ? 'None' : sOleic,
        edible : sEdible_ind,
        oil : sOil_ind,
        scaleTicketData : scaleTicketData
      }

    let premDeductData = ''

    var obj = {
      crop_year : cropYearFromLS(),
      sStatus : sStatus,        
      sPurch_sale_ind : sPurch_sale_ind.toLocaleUpperCase() == 'P'  ? 'Type: Delivery In' : 'Type: Delivery Out',
      sGPM : sGPM.toLocaleUpperCase() == 'FULL' ? 'Full Grades' : sGPM.toLocaleUpperCase() == 'TKC' ? 'TKC Basis' : sGPM,
      tradeNumber : sTradeNumber,
      agreementDetails : d,
      ssTradeInspNum95 : cropYearFromLS() < 2015 ? 'FV95 #: ' + ssTradeInspNum : 'SC95 #: ' + ssTradeInspNum,
      fedNumber : sFedBPNumber,
      settlementNo : sSettlementNumber,
      inspDateTime : sInspectionDateTime,
      recDel1 : recDel1,
      recDel2 : recDel2,
      vendorData : vendorData,
      seg : sPeanutSeg, 
      type : sPeanutType,
      variety : sPeanutVariety,
      generation : sPeanutGeneration == null ? '' : sPeanutGeneration,
      meetSeedSpec : sMeetsSeedSpec,
      oliec : sOleic == '' ? 'None' : sOleic,
      edible : sEdible_ind,
      oil : sOil_ind,
      scaleTicketData : scaleTicketData,
      sA_WeightInclVehicle : sA_WeightInclVehicle,
      sFM : sFM,
      sB_WeightOfVehicle : sB_WeightOfVehicle,
      sLSK: sLSK,
      sC_GrossWeight_A_minus_B : sC_GrossWeight_A_minus_B,
      sLskRiding : sLskRiding,
      sD_ForiegnMaterial_FM_x_C : sD_ForiegnMaterial_FM_x_C,
      sLskFalling : sLskFalling,
      sE_Weight_less_FM_C_minus_D : sE_Weight_less_FM_C_minus_D,
      sLskDamaged : sLskDamaged,
      sF_ExcessMoisture_FM_x_E : sF_ExcessMoisture_FM_x_E,
      sMoisture : sMoisture,
      sG_NetWeight_E_minus_F : sG_NetWeight_E_minus_F,
      sTotalSMK : sTotalSMK,
      sH_LSK_LSK_x_C : sH_LSK_LSK_x_C,
      SMK1 : sSMK1,
      sI_NetWeightExclLSK_G_minus_H : sI_NetWeightExclLSK_G_minus_H,
      SMK2 : sSMK2,
      sJ_KernalValue_Ton_ExclLSK : sJ_KernalValue_Ton_ExclLSK,
      SMK3 : sSMK3,
      sK_ELKPremium : sK_ELKPremium,
      SMK4 : sSMK4,
      sL_Total_J_plus_k : sL_Total_J_plus_k,
      sS : sSS,
      sL_a_Damage : sL_a_Damage,
      sTotal_SMK_plus_SS : sTotal_SMK_plus_SS,
      sL_b_ExcessFM : sL_b_ExcessFM,
      sFreezeDamage : sFreezeDamage,
      sL_c_ExcessSplits : sL_c_ExcessSplits,
      sPittingDamage: sPittingDamage,
      sAflatoxin : sAflatoxin,
      sConcealedRMD : sConcealedRMD,
      sM_TotalDiscounts : sM_TotalDiscounts,
      sTotalDamage : sTotalDamage,
      sN_Value_Ton_ExclLSK_L_minus_M : sN_Value_Ton_ExclLSK_L_minus_M,
      sOtherKernals : sOtherKernals,
      sEstimatedValue : sEstimatedValue,
      sOKRiding : sOKRiding,
      sOKFalling : sOKFalling,
      sOKDamaged : sOKDamaged,
      sTotalKernals : sTotalKernals,
      lPPounds : lPPounds,
      sHULLS : sHULLS,
      dblTotalSumAdjValue : Number(dblTotalSumAdjValue).toFixed(2),
      sTotalKernal_Hulls : sTotalKernal_Hulls,
      curPBasisGradeAmt : curPBasisGradeAmt,
      curPSAdj : curPBasisGradeAmt - curPAdjValue,
      convertVal : ((curPBasisGradeAmt - curPAdjValue) * dblConvPct).toFixed(2),
      curPValueLessAdjValue : curPBasisGradeAmt - curPAdjValue,
      curPProceeds : Number(curPProceeds)?.toFixed(2),
      sVicam : sVicam,
      curPSeg : Number(curPSeg)?.toFixed(2),
      dblOptionPayment : Number(dblOptionPayment)?.toFixed(2),
      conversionPer : Number(dblConvPct)?.toFixed(7),
      sHullBrightness : sHullBrightness,
      sInShellJumbo : sInShellJumbo,
      sFancy : sFancy,
      sELKRiding : sELKRiding,
      sELKDamaged : sELKDamaged,
      sELK : sELK,
      sRidePrescribed : sRidePrescribed,
      sCrackedAndBroken : sCrackedAndBroken,
      sDiscolored : sDiscolored,
      sLoadNum : sLoadNum,
      sLotNum : sLotNum,
      sAuditCount : sAuditCount,
      sRemarks : sRemarks
    }
    
    if(lstrxml?.trade_settle_prem_deduct?.length <= 0){
      let col1 = ''
      let col3 = ''
      // premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
      //             <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
      //                 <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; ELK Ride </span>
      //             </td>
      //             <td style="width:10%;text-align:right;">
      //                 <span> ${obj.sELKRiding} &nbsp; </span>
      //             </td>
      //             <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
      //                 <span> &nbsp; </span>
      //             </td>   
      //             <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
      //                 <span> </span>
      //             </td>
      //             <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      //                 <span> </span>
      //             </td>
      //             <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
      //                 <span> &nbsp; </span>
      //             </td>
      //         </tr>`
      // premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
      //         <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
      //             <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; ELK Damage </span>
      //         </td>
      //         <td style="width:10%;text-align:right;">
      //             <span> ${obj.sELKDamaged} &nbsp; </span>
      //         </td>
      //         <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
      //             <span> &nbsp; </span>
      //         </td>   
      //         <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
      //             <span> ${col1} </span>
      //         </td>
      //         <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      //           <span> </span>
      //         </td>
      //         <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
      //           <span> ${col3} &nbsp; </span>
      //         </td>
      //         </tr>`
      premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp;&nbsp;&nbsp; Net ELK </span>
              </td>
              <td style="width:10%;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> ${obj.sELK} &nbsp; </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
      premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ride Prescribed  </span>
              </td>
              <td style="width:10%;text-align:right;">
                  <span> ${obj.sRidePrescribed} &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
      premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp;&nbsp;&nbsp; Cracked & Broken  </span>
              </td>
              <td style="width:10%;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> ${obj.sCrackedAndBroken} &nbsp; </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
      premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp;&nbsp;&nbsp; Discolored  </span>
              </td>
              <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> ${obj.sDiscolored} &nbsp; </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
      premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span>  </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
      premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp; Load #: ${obj.sLoadNum}  </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
      premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp; Lot #: ${obj.sLotNum}  </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
      premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp; Audit Count: ${obj.sAuditCount}  </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
      premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp; Remarks: ${obj.sRemarks}  </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${col3} &nbsp; </span>
              </td>
          </tr>` 
    }
    else{        
      for(var i=0; i<19; i++){
          if(i == 0){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                  col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                  col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
              }
              premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; ELK Ride </span>
              </td>
              <td style="width:10%;text-align:right;">
                  <span> ${obj.sELKRiding} &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
          }
          if(i == 1){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
              }
              premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; ELK Damage </span>
              </td>
              <td style="width:10%;text-align:right;">
                  <span> ${obj.sELKDamaged} &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
          }
          if(i == 2){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
              }
              premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp;&nbsp;&nbsp; Net ELK </span>
              </td>
              <td style="width:10%;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> ${obj.sELK} &nbsp; </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
          }
          if(i == 3){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
              }
              premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ride Prescribed  </span>
              </td>
              <td style="width:10%;text-align:right;">
                  <span> ${obj.sRidePrescribed} &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
          }
          if(i == 4){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
              }
              premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp;&nbsp;&nbsp; Cracked & Broken  </span>
              </td>
              <td style="width:10%;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> ${obj.sCrackedAndBroken} &nbsp; </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
          }
          if(i == 5){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
              }
              premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp;&nbsp;&nbsp; Discolored  </span>
              </td>
              <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> ${obj.sDiscolored} &nbsp; </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
          }
          if(i == 6){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
              }
              premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span>  </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
          }
          if(i == 7){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
              }
              premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp; Load #: ${obj.sLoadNum}  </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
          }
          if(i == 8){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
              }
              premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp; Lot #: ${obj.sLotNum}  </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
          }
          if(i == 9){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
              }
              premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
              <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                  <span> &nbsp; Audit Count: ${obj.sAuditCount}  </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> &nbsp; </span>
              </td>
              <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                  <span> </span>
              </td>   
              <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col1} </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                <span> </span>
              </td>
              <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                <span> ${col3} &nbsp; </span>
              </td>
              </tr>`
          }
          if(i == 10){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
              }
              premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                  <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                      <span> &nbsp; Remark: ${obj.sRemarks}  </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> &nbsp; </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> </span>
                  </td>   
                  <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                      <span> ${col1} </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                      <span> </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                  </td>
              </tr>`                
          }
          // blank case starts
          if(i == 11){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
                  premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                  <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                      <span> </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> &nbsp; </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> </span>
                  </td>   
                  <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                      <span> ${col1} </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                      <span> </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                  </td>
                  </tr>`
              }
          }
          if(i == 12){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
                  premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                  <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                      <span> </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> &nbsp; </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> </span>
                  </td>   
                  <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                      <span> ${col1} </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                      <span> </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                  </td>
                  </tr>`
              }
          }
          if(i == 13){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
                  premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                  <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                      <span> </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> &nbsp; </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> </span>
                  </td>   
                  <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                      <span> ${col1} </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                      <span> </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                  </td>
                  </tr>`
              }
          }
          if(i == 14){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                  col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                  col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
                  premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                  <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                      <span> </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> &nbsp; </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> </span>
                  </td>   
                  <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                      <span> ${col1} </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                      <span> </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                  </td>
                  </tr>`
              }
          }
          if(i == 15){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                  col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                  col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
                  premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                  <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                      <span> </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> &nbsp; </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> </span>
                  </td>   
                  <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                      <span> ${col1} </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                      <span> </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                  </td>
                  </tr>`
              }
          }
          if(i == 16){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                  col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                  col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
                  premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                  <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                      <span> </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> &nbsp; </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> </span>
                  </td>   
                  <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                      <span> ${col1} </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                      <span> </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                  </td>
                  </tr>`
              }
          }
          if(i == 17){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                  col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                  col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
                  premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                  <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                      <span> </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> &nbsp; </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> </span>
                  </td>   
                  <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                      <span> ${col1} </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                      <span> </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                  </td>
                  </tr>`
              }
          }
          if(i == 18){
              let col1 = ''
              let col3 = ''
              if(i < lstrxml?.trade_settle_prem_deduct?.length){
                  col1 = lstrxml?.trade_settle_prem_deduct[i]?.pd_code  + (lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == null || lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name == undefined ? '' : " - " + lstrxml?.trade_settle_prem_deduct[i]?.pd_vendor_name)
                  col3 = lstrxml?.trade_settle_prem_deduct[i]?.pd_amount
                  premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                  <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                      <span> </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> &nbsp; </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> </span>
                  </td>   
                  <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                      <span> ${col1} </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                      <span> </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${col3} &nbsp; </span>
                  </td>
                  </tr>`
              }
          }
      }
    }
    premDeductData += `<tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                  <td style="width:30%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:left;">
                      <span> </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> &nbsp; </span>
                  </td>
                  <td style="width:10%;border: 1px;border-color: white black white white;border-style: solid;border-collapse:collapse;text-align:right;">
                      <span> </span>
                  </td>   
                  <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
                      <span> Net </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                      <span> </span>
                  </td>
                  <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.curPProceeds} &nbsp; </span>
                  </td>
                  </tr>`

    return `<html>
      <style>
      body:before{
          content: '${process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'}';
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;  
          color: #0d745e;
          font-size: 250px;
          font-weight: 500px;
          display: grid;
          justify-content: center;
          align-content: center;
          opacity: 0.35;
      }
      @media print {
          @page :footer { display: none }    
          @page :header { display: none }    
          @page { margin-top: 0; margin-bottom: 0; }    
          html,
          body { height: 100%; margin: 0 !important; padding: 0 !important; }
      }     
      </style>
      <p style="page-break-after: always">
        ${await rptDelvSettlementWorkSheetHeader(headerObj)}
        <table style="font-size: 70%;width:100%;border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial';">      
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; <b> Grades: </b> </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span>  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; A. Weight Including Vehicle </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sA_WeightInclVehicle} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; FM </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span>  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sFM} &nbsp; </span>
                </td>
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; B. Weight of Vehicle </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">  
                  <span> ${obj.sB_WeightOfVehicle} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; LSK </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span>  </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sLSK} &nbsp; </span>
                </td>
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; C. Gross Weight (A-B) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">  
                  <span> ${obj.sC_GrossWeight_A_minus_B} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LSK Riding Screen </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sLskRiding} &nbsp; </span>
                </td>
                <td style="width:10%;text-align:left;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
                    <span> </span>
                </td>
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; D. Foreign Material (FM x C) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">  
                  <span> ${obj.sD_ForiegnMaterial_FM_x_C} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LSK Fall Through </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sLskFalling} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> </span>
                </td>
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; E. Weight Less FM (C-D) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sE_Weight_less_FM_C_minus_D} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LSK Damage Riding </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sLskDamaged} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> </span>
                </td>
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; F. Excess Moisture (EM x E) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">  
                  <span> ${obj.sF_ExcessMoisture_FM_x_E} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Moisture </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sMoisture} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; G. Net Weight (E-F) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sG_NetWeight_E_minus_F} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Total SMK </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sTotalSMK} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; H. LSK (LSK x C) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sH_LSK_LSK_x_C} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK1 </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.SMK1} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;font-size:90%;"> 
                    <span> &nbsp; I. Net Weight Excluding LSK (G - H) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sI_NetWeightExclLSK_G_minus_H} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK2 </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.SMK2} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;font-size:90%;"> 
                    <span> &nbsp; J. Kernal Value/Ton (Excluding LSK) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sJ_KernalValue_Ton_ExclLSK} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK3 </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.SMK3} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; K. ELK Premium  </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sK_ELKPremium} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 0.1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK4 </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.SMK4} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; L. Total (J + K) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sL_Total_J_plus_k} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; SS </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sS} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Damage </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sL_a_Damage} &nbsp; </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> &nbsp; </span>
                </td>                
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Total (SMK + SS) </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sTotal_SMK_plus_SS} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Excess FM </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sL_b_ExcessFM} &nbsp; </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Freeze Damage </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sFreezeDamage} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Excess Splits </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sL_c_ExcessSplits} &nbsp; </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Pitting Damage </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sPittingDamage} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Flavus Splits </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sAflatoxin} &nbsp; </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Concealed RMD </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sConcealedRMD} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; M. Total Discounts </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sM_TotalDiscounts} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Total Damage </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sTotalDamage} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; N. Value/Ton Excluding LSK (L - M) </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sN_Value_Ton_ExclLSK_L_minus_M} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Other Kernals </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sOtherKernals} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Estimated Value Per Pound </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.sEstimatedValue} &#162; &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; OK Riding Screen </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sOKRiding} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; OK Fall Through </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sOKFalling} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 0.1px;border-color: white white black white;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> </span>
                </td>
                <td style="width:10%;border: 1px;border-color: white white black white;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
                <td style="width:15%;border: 0.1px;border-color: white white black white;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span>  </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; OK Damage Riding </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sOKDamaged} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
                  <span> Purchase </span>
                </td>
            </tr> 
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Total Kernels </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sTotalKernals} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; LBS </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.lPPounds} &nbsp; </span>
                </td>
            </tr> 
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Hulls </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sHULLS} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; x Line P </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.curPBasisGradeAmt} &nbsp; </span>
                </td>
            </tr>           
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Total Kernals & Hulls </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sTotalKernal_Hulls} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; 100% Adjustment Value </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.dblTotalSumAdjValue} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Vicam </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sVicam} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Line P less 100% Adj. Value </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.curPValueLessAdjValue} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hull Brightness </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sHullBrightness} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Conversion % </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.conversionPer} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In Shell Jumbo </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sInShellJumbo} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Converted Value </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${Number(obj.curPValueLessAdjValue * obj.conversionPer).toFixed(2)} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; Fancy </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> ${obj.sFancy} &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Value of Segment </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.curPSeg} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ELK Ride </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sELKRiding} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; Option Payment </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> ${obj.dblOptionPayment} &nbsp; </span>
                </td>
            </tr>
            <tr style="border: 0.5px;border-color: black;border-style: solid;border-collapse:collapse;">
                <td style="width:30%;border: 1px;border-color: black white black black;border-style: solid;border-collapse:collapse;text-align:left;">
                    <span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ELK Damage </span>
                </td>
                <td style="width:10%;text-align:right;">
                    <span> ${obj.sELKDamaged} &nbsp; </span>
                </td>
                <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;">
                    <span> &nbsp; </span>
                </td>   
                <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                    <span> &nbsp; &nbsp; <b> Premium / Deductions: </b> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
                  <span> </span>
                </td>
                <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
                  <span> </span>
                </td>
            </tr>
            ${premDeductData}     
        </table>
      </p>
      </html>` 

    }
    catch (err) {
        errorHandler(err);
    }
  }

  async function rptDelvSettlementWorkSheetHeader(obj){
    return `<table style="align-items: center;width: 100%;font-size:65%;padding-top:2%;font-family:'Arial'">
      <tr style="padding-right: 1%;">
        <td style="padding-left:0.01%; padding-right: 1%;">                	
            <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
        </td>
        <td style="padding-left:0%;text-align: center;">
            <br/>
            <span style="font-size:140%;color: black;">
                <b> <u> Delivery Settlement Worksheet - ${obj.sStatus} </u> <br/> ${obj.sPurch_sale_ind} </b>
                <b> <br/> ${formatDate(new Date())} </b>
            </span>
        </td>
        <td style="padding-left:0%;">
            <span style="text-align-last: left;color:black;font-size:130%;"> <b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Golden Peanut Company, LLC </b> <br/></span>
            <span style="text-align: right;color:black;font-size:80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; <b> 100 North Point Center East, Suite 400</b> <br/></span>
            <span style="text-align: right;color:black;font-size:80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; <b> Alpharetta, Georgia 30022-8209 </b> <br/></span>
        </td>
      </tr>
  </table>

  <table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left;"> <b> &nbsp; Crop Year: ${obj.cropYear} </b></span>              
          </td>
          <td style="width:40%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Grade Pricing Method: ${obj.sGPM} </b></span>              
          </td>
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Delivery #: &nbsp; &nbsp; ${obj.tradeNumber} </b></span>
          </td>
      </tr>
  </table>
  <table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span> <b> Delivery Agreement # </b></span>              
          </td>
          <td style="width:40%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span> <b> Pricing Type </b></span>              
          </td>
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span> <b> Applied Dollers </b></span>
          </td>
      </tr>      
      ${obj.agreementDetails}
  </table>
  <table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">      
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span> <b> ${obj.ssTradeInspNum95} </b></span>              
        </td>
        <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span> <b> Fed. BP #: &nbsp; ${obj.fedNumber} </b></span>              
        </td>
        <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span> <b> 1007 #: &nbsp; ${obj.settlementNo} </b></span>
        </td>
        <td style="width:40%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span> <b> Insp. Date & Time: &nbsp; ${obj.inspDateTime} </b></span>
        </td>
      </tr>
  </table>
  <table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:50%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span> <b> ${obj.recDel1} </b></span>              
        </td>
        <td style="width:50%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span> <b> ${obj.recDel2} </b></span>              
        </td>
      </tr>
  </table>
  <table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:75%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span> <b> Vendor </b></span>              
        </td>
        <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span> <b> Share % </b></span>              
        </td>
      </tr>
        ${obj.vendorData}
  </table>
  <table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">
    <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Seg: &nbsp; ${obj.seg} </span>              
    </td>
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Type: &nbsp; ${obj.type} </span>              
    </td>
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Variety: &nbsp; ${obj.variety} </span>              
    </td>
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Generation: &nbsp; ${obj.generation} </span>              
    </td>
  </tr>

  <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Meets Seed Spec: &nbsp; ${obj.meetSeedSpec} </span>              
    </td>
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Oleic(H-M): &nbsp; ${obj.oliec} </span>              
    </td>
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Edible: &nbsp; ${obj.edible} </span>              
    </td>
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Oil: &nbsp; ${obj.oil} </span>              
    </td>
  </tr>
    </table>

    <table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">
    <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span> <b> Weight Ticket # </b></span>              
        </td>
        <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span> <b> Date </b></span>              
        </td>
        <td style="width:40%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span> <b> &nbsp; Gross Weight of Peanuts </b></span>              
        </td>
        <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span> <b> Vehicle # </b></span>              
        </td>
    </tr>  
    ${obj.scaleTicketData}
  </table>
  `
  }

  async function rptTradeAgreementWorksheet(sAgreeNum, buyingPt) {
    try {
      let LstrXML2 = '';
      let LstrXML3 = '';
      let LstrXML4 = '';
      let LstrXML5 = '';
      let LstrXML5Hold = '';
      let LstrXML6 = '';
      let LstrXML6Hold = '';
      let nTestPrint = 0;
      let sInspections = '';
      let dblNewProceeds = 0;
      let dblPremiumTotal = 0;
      let dblDeductionsTotal = 0;
      let LstrCityState = '';
      let LstrFedWhsLic = '';
      let Lint1007_lYPos = 0;
      let Ldbl_WtAvg_OptionPerTon = 0;
      let Ldbl_WtAvg_OptionPct = 0;
      let LblnFirst = false;

      let dblBasisGradeAmount = 0;
      let lngPounds = 0;
      let dblOptionValue = 0;
      let dblBasisValue = 0;
      let dblFirmValue = 0;
      let dblpurchase_value = 0;

      let LintContractCtr = 0;
      let Ldbl100Pct1007Value = 0;
      let Ldbl1007Lbs = 0;
      let Ldbl1007OptionValue = 0;
      let Ldbl1007BasisValue = 0;
      let Ldbl1007FirmValue = 0;

      let LdblNet1007 = 0;
      let LdblNetTAP = 0;

      let LdblNet_Wt_less_Lsk_wt = 0;
      let LdblLsk_wt = 0;

      let LstrXMLsettlement = '';

      let lstr_NET_WT_LBS = 0;
      let lstr_NET_WT_TONS = 0;
      let lstr_LSK_WT = 0;
      let lstr_NET_LESS_LSK = 0;
      let lstr_BASIS_GRADE_AMT = 0;
      let lstr_SEG_VALUE = 0;
      let lstr_APPLIED_LBS = 0;
      let lstr_TKC_LBS = 0;
      let lstr_WA_TKC = 0;
      let lstr_WA_SMK = 0;
      let lstr_WA_SS = 0;
      let lstr_WA_OK = 0;
      let lstr_WA_DAM = 0;
      let lstr_WA_HULL = 0;
      let lstr_WA_FM = 0;
      let lstr_WA_LSK = 0;
      let lstr_WA_MOIST = 0;
      let lstr_WA_FAN = 0;
      let lstr_WA_ELK = 0;
      let lstr_WA_CR_BR = 0;
      let lstr_WA_FREZ_DAM = 0;
      let strOpenBal = 0;
      let sPeanutVariety = '';
      let nVendor = 0;
      let sPeanutGeneration = '';
      let LstrXMLVendors;
      let LstrXMLVendorNames;
      let LstrXMLVendorPCT;
      let colVendors = [];      
      let colVendor;
      let settlementData1 = '';
      let settlementData2 = '';
      let totalFlag = false;

      msReport = "TRADE_AGREE_WORKSHEET";
      let response = await SettlementService.RetrieveTradeAgreementDetails('PN9000', null, buyingPt, sAgreeNum, null, null, null, null, null, null, null, null, null, null)

      taws_lstrXML = response;
      if(response.length <= 0){
        showMessage('No data found for trade agreement details');
        return '';
      }
      
      response = await AccountPayment.RetrieveAgreementWorksheetDocumentDetails(sAgreeNum, 'N', buyingPt)
      
      sInspections = response;
      if(response.length <= 0){
        showMessage('No data found for agreement workhseet document details');
        return '';
      }
      
      
      msPDF_BP = buyingPt;
      msPDF_AgreementNo = sAgreeNum;

      LstrXMLsettlement = sInspections[0].settlements;
      sPeanutVariety = taws_lstrXML[0].pnut_variety_name;
      if(taws_lstrXML[0].symbol_ind.toUpperCase() == 'TRADEMARK')
        sPeanutVariety += `&reg;`
      else if(taws_lstrXML[0].symbol_ind.toUpperCase() == 'REGISTERED TRADEMARK')
        sPeanutVariety += `&trade;`
      else if(taws_lstrXML[0].symbol_ind.toUpperCase() == 'COPYRIGHT')
        sPeanutVariety += `&copy;`

      switch(taws_lstrXML[0].seed_gen.toLocaleUpperCase()){
        case 'C':
          sPeanutGeneration = 'Certified';
          break;
        case 'F':
          sPeanutGeneration = 'Foundation';
          break;
        case 'R':
          sPeanutGeneration = 'Registered';
           break;
        default:
          sPeanutGeneration = '';
      }

      LstrXMLVendors = taws_lstrXML[0].split_vendor.split('|');
      LstrXMLVendorNames = taws_lstrXML[0].vendor_name.split('|');
      LstrXMLVendorPCT = taws_lstrXML[0].split_share_pct.split('|');
      let nControlIndex = 0;
      let vendorData = '';
      for(var i=0; i< LstrXMLVendors.length; i++){
        colVendor = {
          split_vendor    : LstrXMLVendors[i],
          vendor_name     : LstrXMLVendorNames[i],
          split_share_pct : LstrXMLVendorPCT[i].split('|')[0]
        }
        colVendors.push(colVendor)
      }
      for(var i=0; i<colVendors.length; i++){
        vendorData += `<tr>
                <td style="width:35%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: left;font-size:80%;">
                  &nbsp; (${colVendors[i].split_vendor}) ${colVendors[i].vendor_name}
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;font-size:80%;">
                  ${colVendors[i].split_share_pct}
                </td>`;
        i++;
        if(i<colVendors.length){
            vendorData += `<td style="width:35%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: left;font-size:80%;">
                  &nbsp; (${colVendors[i].split_vendor}) ${colVendors[i].vendor_name}
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;font-size:80%;">
                  ${colVendors[i].split_share_pct}
                </td>
            </tr>`;
        }
      }
      if(colVendors.length % 2 == 1){
        vendorData += `<td style="width:35%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td> </tr> `;
      }

      let premiumDedHeader = ''
      for(var i=0; i< sInspections[0].trade_premium_deduction_headers.length; i++){
        premiumDedHeader += (sInspections[0].trade_premium_deduction_headers[i].pd_code + '=' + Number(sInspections[0].trade_premium_deduction_headers[i].pd_amount).toFixed(2) + ',')
      }
      
      let sObj;
        for (var i = 0; i < LstrXMLsettlement.length; i++) {
            if (i == 11)
                break;
            sObj = {
                loc: LstrXMLsettlement[i].buy_pt_id,
                inspNumber: LstrXMLsettlement[i].trade_insp_num,
                trade1007: LstrXMLsettlement[i].trade_settle_num,
                seedGen: LstrXMLsettlement[i].seed_gen,
                var: LstrXMLsettlement[i].pnut_variety_name,
                shipDate: LstrXMLsettlement[i].shp_date != '' ? formatDate(LstrXMLsettlement[i].shp_date).split(' ')[0] : '',
                revDate: LstrXMLsettlement[i].rec_date != '' ? formatDate(LstrXMLsettlement[i].rec_date).split(' ')[0] : '',
                settleDate: LstrXMLsettlement[i].settle_date_time != '' ? formatDate(LstrXMLsettlement[i].settle_date_time).split(' ')[0] : '',
                netLbs: trimValue(LstrXMLsettlement[i].net_wt_lbs),
                netTon: trimValue(LstrXMLsettlement[i].net_wt_tons),
                lskWt: trimValue(LstrXMLsettlement[i].lsk_wt),
                netLsk: trimValue(LstrXMLsettlement[i].net_less_lsk),
                tkcLBS: trimValue(LstrXMLsettlement[i].tkc_lbs),
                tckPct: trimValue(LstrXMLsettlement[i].tkc_pct),
                smk: trimValue(LstrXMLsettlement[i].smk_pct),
                ss: trimValue(LstrXMLsettlement[i].ss_pct),
                ok: trimValue(LstrXMLsettlement[i].ok_pct),
                dam: trimValue(LstrXMLsettlement[i].dam_pct),
                hul: trimValue(LstrXMLsettlement[i].hull_pct),
                fm: trimValue(LstrXMLsettlement[i].fm_pct),
                lsk: trimValue(LstrXMLsettlement[i].lsk_pct),
                moi: trimValue(LstrXMLsettlement[i].moist_pct),
                fan: trimValue(LstrXMLsettlement[i].fan_pct),
                elk: trimValue(LstrXMLsettlement[i].elk_pct),
                cr: trimValue(LstrXMLsettlement[i].cr_br_pct),
                fd: trimValue(LstrXMLsettlement[i].frez_dam_pct),
                value100: Number(LstrXMLsettlement[i].basis_grade_amt).toFixed(2),
                segValue: Number(LstrXMLsettlement[i].seg_value).toFixed(2),
                appliedLbs: Number(LstrXMLsettlement[i].applied_lbs).toFixed(2)
            }
        settlementData1 += `<tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="padding-right: 5px;"> 
            ${sObj.loc} <br/> <br/> <br/> ${premiumDedHeader.slice(0, -1)}
        </td>
        <td style="padding-right: 5px;"> 
            ${sObj.inspNumber}  <br/> ${sObj.netLbs} <br/> ${sObj.value100}
        </td>
        <td style="padding-right: 5px;"> 
          ${sObj.trade1007}  <br/> ${sObj.netTon} <br/> ${sObj.segValue}
        </td>
        <td style="padding-right: 5px;"> 
            ${sObj.seedGen}  <br/> ${sObj.lskWt} <br/> ${sObj.appliedLbs}
        </td>
        <td style="padding-right: 5px;"> 
            ${sObj.var}  <br/> ${sObj.netLsk} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.tkcLBS} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.tckPct} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.smk} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.ss} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            ${sObj.shipDate} <br/> ${sObj.ok} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.dam} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.hul} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            ${sObj.revDate} <br/> ${sObj.fm} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.lsk} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            ${sObj.settleDate} <br/> ${sObj.moi} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.fan} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.elk} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.cr} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.fd} <br/> <br/>
        </td>
        </tr>`
        
        lstr_NET_WT_LBS += Number(LstrXMLsettlement[i].net_wt_lbs);
        lstr_NET_WT_TONS += Number(LstrXMLsettlement[i].net_wt_tons);
        lstr_LSK_WT += Number(LstrXMLsettlement[i].lsk_wt);
        lstr_NET_LESS_LSK += Number(LstrXMLsettlement[i].net_less_lsk);

        lstr_BASIS_GRADE_AMT += Number(LstrXMLsettlement[i].basis_grade_amt);
        lstr_SEG_VALUE += Number(LstrXMLsettlement[i].seg_value);
        lstr_APPLIED_LBS += Number(LstrXMLsettlement[i].applied_lbs);
        lstr_TKC_LBS += Number(LstrXMLsettlement[i].tkc_lbs);

        lstr_WA_TKC += Number(Number(LstrXMLsettlement[i].wa_tkc).toFixed(2));
        lstr_WA_SMK += Number(Number(LstrXMLsettlement[i].wa_smk).toFixed(2));
        lstr_WA_SS += Number(Number(LstrXMLsettlement[i].wa_ss).toFixed(2));
        lstr_WA_OK += Number(Number(LstrXMLsettlement[i].wa_ok).toFixed(2));
        lstr_WA_DAM += Number(Number(LstrXMLsettlement[i].wa_dam).toFixed(2));
        lstr_WA_HULL += Number(Number(LstrXMLsettlement[i].wa_hull).toFixed(2));
        lstr_WA_FM += Number(Number(LstrXMLsettlement[i].wa_fm).toFixed(2));
        lstr_WA_LSK += Number(Number(LstrXMLsettlement[i].wa_lsk).toFixed(2));
        lstr_WA_MOIST += Number(Number(LstrXMLsettlement[i].wa_moist).toFixed(2));
        lstr_WA_FAN += Number(Number(LstrXMLsettlement[i].wa_fan).toFixed(2));
        lstr_WA_ELK += Number(Number(LstrXMLsettlement[i].wa_elk).toFixed(2));
        lstr_WA_CR_BR += Number(Number(LstrXMLsettlement[i].wa_cr_br).toFixed(2));
        lstr_WA_FREZ_DAM += Number(Number(LstrXMLsettlement[i].wa_frez_dam).toFixed(2));
      }

      let taws_LstrText = ''
      LstrXML6 = sInspections[0].trade_premium_deduction_headers;
      for(var i=0; i<LstrXML6.length; i++){
        taws_LstrText += (LstrXML6[i].pd_code + '=' + LstrXML6[i].pd_amount + ', ');
      }
      let totalHeading = '';
      let totalBody = '';
      
      if(LstrXMLsettlement.length > 0){
        taws_LstrText = 'Applicable Deductions:';
      }

      if(taws_LstrText == 'Applicable Deductions:'){
        totalHeading = 'Totals: ';
        taws_LstrText = ''
        for(var i=0; i<LstrXML6.length; i++){
            taws_LstrText += (LstrXML6[i].pd_code + '=' + LstrXML6[i].pd_amount + ', ');
        }
        totalBody = taws_LstrText == '' ? '' : taws_LstrText.slice(0, -2)
      }
    //   else{
    //     totalHeading = 'Applicable Deductions: '
    //     totalBody = taws_LstrText;
    //   }
    let premiumDataTable = ''
      if(totalHeading !== '' && totalBody !== ''){
        premiumDataTable = `<table style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;padding-left:1%;font-size:80%;width:100%;">
        <tr style="background-color: #c0bcbc;">
            <td style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;background-color:silver;">  ${totalHeading} </td>
        </tr>
        <tr>
            <td style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> ${totalBody} </td>
        </tr>
        </table>`
      }

      let count = 11;
      let headerObj = {
        cropYear          : cropYearFromLS(),
        typeHeader        : taws_lstrXML[0].purch_sale_ind.toLocaleUpperCase() == 'P' ? 'Trade Purchase' : 'Trade Sale',
        agreementNumber   : taws_lstrXML[0].agreement_num,
        agreementDate     : taws_lstrXML[0].agree_date_time.split(' ')[0],
        agreementStatus   : taws_lstrXML[0].agree_status == 'O' ? 'Open' : taws_lstrXML[0].agree_status == 'C' ? 'Complete' : '',
        gradePricingMethod: taws_lstrXML[0].grade_pricing_method,
        pricingType       : taws_lstrXML[0].firm_basis_opt == 'F' ? 'FIRM' : taws_lstrXML[0].firm_basis_opt == 'B' ? 'BASIS' : taws_lstrXML[0].firm_basis_opt == 'L' ? 'FLAT' : '',
        agreementLbs      : taws_lstrXML[0].agreed_lbs,
        agreementLoc      : taws_lstrXML[0].buy_pt_id + ' - ' + taws_lstrXML[0].buy_pt_name,
        peanutType        : taws_lstrXML[0].pnut_type_name,
        variety           : sPeanutVariety,
        generation        : sPeanutGeneration,
        seg               : taws_lstrXML[0].seg_type,
        oliec             : taws_lstrXML[0].oleic_ind.toLocaleUpperCase() == 'H' ? 'High' : taws_lstrXML[0].oleic_ind.toLocaleUpperCase() == 'M' ? 'Medium' : taws_lstrXML[0].oleic_ind.toLocaleUpperCase() == 'L' ? 'Low' : 'None',
        edibleOil         : taws_lstrXML[0].edible_oil_ind.toLocaleUpperCase() == 'E' ? 'Edible' : taws_lstrXML[0].edible_oil_ind.toLocaleUpperCase() == 'O' ? 'Oil' : '',
        vendorData        : vendorData,
      }

      let pageHeading = await rptTradeAgreementWorksheetHeader(headerObj);
      
      if(LstrXMLsettlement.length >= 11){
        for(var i=11; i<LstrXMLsettlement.length; i++){
            sObj = {
                loc: LstrXMLsettlement[i].buy_pt_id,
                inspNumber: LstrXMLsettlement[i].trade_insp_num,
                trade1007: LstrXMLsettlement[i].trade_settle_num,
                seedGen: LstrXMLsettlement[i].seed_gen,
                var: LstrXMLsettlement[i].pnut_variety_name,
                shipDate: LstrXMLsettlement[i].shp_date != '' ? formatDate(LstrXMLsettlement[i].shp_date).split(' ')[0] : '',
                revDate: LstrXMLsettlement[i].rec_date != '' ? formatDate(LstrXMLsettlement[i].rec_date).split(' ')[0] : '',
                settleDate: LstrXMLsettlement[i].settle_date_time != '' ? formatDate(LstrXMLsettlement[i].settle_date_time).split(' ')[0] : '',
                netLbs: trimValue(LstrXMLsettlement[i].net_wt_lbs),
                netTon: trimValue(LstrXMLsettlement[i].net_wt_tons),
                lskWt: trimValue(LstrXMLsettlement[i].lsk_wt),
                netLsk: trimValue(LstrXMLsettlement[i].net_less_lsk),
                tkcLBS: trimValue(LstrXMLsettlement[i].tkc_lbs),
                tckPct: trimValue(LstrXMLsettlement[i].tkc_pct),
                smk: trimValue(LstrXMLsettlement[i].smk_pct),
                ss: trimValue(LstrXMLsettlement[i].ss_pct),
                ok: trimValue(LstrXMLsettlement[i].ok_pct),
                dam: trimValue(LstrXMLsettlement[i].dam_pct),
                hul: trimValue(LstrXMLsettlement[i].hull_pct),
                fm: trimValue(LstrXMLsettlement[i].fm_pct),
                lsk: trimValue(LstrXMLsettlement[i].lsk_pct),
                moi: trimValue(LstrXMLsettlement[i].moist_pct),
                fan: trimValue(LstrXMLsettlement[i].fan_pct),
                elk: trimValue(LstrXMLsettlement[i].elk_pct),
                cr: trimValue(LstrXMLsettlement[i].cr_br_pct),
                fd: trimValue(LstrXMLsettlement[i].frez_dam_pct),
                value100: Number(LstrXMLsettlement[i].basis_grade_amt).toFixed(2),
                segValue: Number(LstrXMLsettlement[i].seg_value).toFixed(2),
                appliedLbs: Number(LstrXMLsettlement[i].applied_lbs).toFixed(2)
            }

          if(count%11 == 0){
            settlementData2 += `<p`
            if(count+11 < LstrXMLsettlement.length)
                settlementData2 += ` style="page-break-after: always">`
            else
                settlementData2 += `>`
            settlementData2 += pageHeading;
            settlementData2 += `<table style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;padding-left:1%;font-size:70%;width:100%;">
            <tr style="background-color: #c0bcbc;">
            <td style="padding-right: 5px;"> 
                <b> Loc <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Insp#  <br/> Net Lbs. <br/> 100% Value </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Trade/1007#  <br/> Net Tons <br/> Seg Value </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Seed Gen  <br/> LSK <br/> Applied Lbs. </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Var  <br/> Net-LSK <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> TKC LBS <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> tkc% <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> smk <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> ss <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Shipped Date <br/> ok <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> dam <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> hul <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Received Date <br/> fm <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> lsk <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Settled Date <br/> moi <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> fan <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> elk <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> cr <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> fd <br/> <br/> </b>
            </td>
            </tr>`
          }         
          
          settlementData2 += `<tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="padding-right: 5px;"> 
            ${sObj.loc} <br/> <br/> <br/> ${premiumDedHeader.slice(0, -1)}
        </td>
        <td style="padding-right: 5px;"> 
            ${sObj.inspNumber}  <br/> ${sObj.netLbs} <br/> ${sObj.value100}
        </td>
        <td style="padding-right: 5px;"> 
          ${sObj.trade1007}  <br/> ${sObj.netTon} <br/> ${sObj.segValue}
        </td>
        <td style="padding-right: 5px;"> 
        ${sObj.seedGen}  <br/> ${sObj.lskWt} <br/> ${sObj.appliedLbs}
        </td>
        <td style="padding-right: 5px;"> 
        ${sObj.var}  <br/> ${sObj.netLsk} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.tkcLBS} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.tckPct} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.smk} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.ss} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
        ${sObj.shipDate} <br/> ${sObj.ok} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.dam} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.hul} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
        ${sObj.revDate} <br/> ${sObj.fm} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.lsk} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            ${sObj.settleDate} <br/> ${sObj.moi} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.fan} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.elk} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.cr} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.fd} <br/> <br/>
        </td>
          </tr>`
          
          if(count%11 == 10){
            settlementData2 += `</table> </p>`
          }
          count++;
          lstr_NET_WT_LBS += Number(LstrXMLsettlement[i].net_wt_lbs);
          lstr_NET_WT_TONS += Number(LstrXMLsettlement[i].net_wt_tons);
          lstr_LSK_WT += Number(LstrXMLsettlement[i].lsk_wt);
          lstr_NET_LESS_LSK += Number(LstrXMLsettlement[i].net_less_lsk);

          lstr_BASIS_GRADE_AMT += Number(LstrXMLsettlement[i].basis_grade_amt);
          lstr_SEG_VALUE += Number(LstrXMLsettlement[i].seg_value);
          lstr_APPLIED_LBS += Number(LstrXMLsettlement[i].applied_lbs);
          lstr_TKC_LBS += Number(LstrXMLsettlement[i].tkc_lbs);

          lstr_WA_TKC += Number(Number(LstrXMLsettlement[i].wa_tkc).toFixed(2));
          lstr_WA_SMK += Number(Number(LstrXMLsettlement[i].wa_smk).toFixed(2));
          lstr_WA_SS += Number(Number(LstrXMLsettlement[i].wa_ss).toFixed(2));
          lstr_WA_OK += Number(Number(LstrXMLsettlement[i].wa_ok).toFixed(2));
          lstr_WA_DAM += Number(Number(LstrXMLsettlement[i].wa_dam).toFixed(2));
          lstr_WA_HULL += Number(Number(LstrXMLsettlement[i].wa_hull).toFixed(2));
          lstr_WA_FM += Number(Number(LstrXMLsettlement[i].wa_fm).toFixed(2));
          lstr_WA_LSK += Number(Number(LstrXMLsettlement[i].wa_lsk).toFixed(2));
          lstr_WA_MOIST += Number(Number(LstrXMLsettlement[i].wa_moist).toFixed(2));
          lstr_WA_FAN += Number(Number(LstrXMLsettlement[i].wa_fan).toFixed(2));
          lstr_WA_ELK += Number(Number(LstrXMLsettlement[i].wa_elk).toFixed(2));
          lstr_WA_CR_BR += Number(Number(LstrXMLsettlement[i].wa_cr_br).toFixed(2));
          lstr_WA_FREZ_DAM += Number(Number(LstrXMLsettlement[i].wa_frez_dam).toFixed(2));
        }
        settlementData2 += '</p> </table>';
      }
      
      var obj = {
        cropYear          : cropYearFromLS(),
        typeHeader        : taws_lstrXML[0].purch_sale_ind.toLocaleUpperCase() == 'P' ? 'Trade Purchase' : 'Trade Sale',
        agreementNumber   : taws_lstrXML[0].agreement_num,
        agreementDate     : moment(taws_lstrXML[0].agree_date_time).format("MM/DD/YYYY"),
        agreementStatus   : taws_lstrXML[0].agree_status == 'O' ? 'Open' : taws_lstrXML[0].agree_status == 'C' ? 'Complete' : '',
        gradePricingMethod: taws_lstrXML[0].grade_pricing_method,
        pricingType       : taws_lstrXML[0].firm_basis_opt == 'F' ? 'FIRM' : taws_lstrXML[0].firm_basis_opt == 'B' ? 'BASIS' : taws_lstrXML[0].firm_basis_opt == 'L' ? 'FLAT' : '',
        agreementLbs      : taws_lstrXML[0].agreed_lbs,
        agreementLoc      : taws_lstrXML[0].buy_pt_id + ' - ' + taws_lstrXML[0].buy_pt_name,
        peanutType        : taws_lstrXML[0].pnut_type_name,
        variety           : sPeanutVariety,
        generation        : sPeanutGeneration,
        seg               : taws_lstrXML[0].seg_type,
        oliec             : taws_lstrXML[0].oleic_ind.toLocaleUpperCase() == 'H' ? 'High' : taws_lstrXML[0].oleic_ind.toLocaleUpperCase() == 'M' ? 'Medium' : taws_lstrXML[0].oleic_ind.toLocaleUpperCase() == 'L' ? 'Low' : 'None',
        edibleOil         : taws_lstrXML[0].edible_oil_ind.toLocaleUpperCase() == 'E' ? 'Edible' : taws_lstrXML[0].edible_oil_ind.toLocaleUpperCase() == 'O' ? 'Oil' : '',
        vendorData        : vendorData,
        settlementData1   : settlementData1,
        totalHeading      : totalHeading,
        totalBody         : totalBody,
        s_APPLIED_LBS     : Number.isInteger(lstr_APPLIED_LBS)     ? lstr_APPLIED_LBS                : lstr_APPLIED_LBS.toFixed(2),
        s_BASIS_GRADE_AMT : Number.isInteger(lstr_BASIS_GRADE_AMT) ? lstr_BASIS_GRADE_AMT.toFixed(2) : lstr_BASIS_GRADE_AMT.toFixed(2),
        s_LSK_WT          : Number.isInteger(lstr_LSK_WT)          ? lstr_LSK_WT                     : lstr_LSK_WT.toFixed(2),
        s_NET_LESS_LSK    : Number.isInteger(lstr_NET_LESS_LSK)    ? lstr_NET_LESS_LSK               : lstr_NET_LESS_LSK.toFixed(2),
        s_NET_WT_LBS      : Number.isInteger(lstr_NET_WT_LBS)      ? lstr_NET_WT_LBS                 : lstr_NET_WT_LBS.toFixed(2),
        s_NET_WT_TONS     : Number.isInteger(lstr_NET_WT_TONS)     ? lstr_NET_WT_TONS.toFixed(2)     : lstr_NET_WT_TONS.toFixed(2),
        s_SEG_VALUE       : Number.isInteger(lstr_SEG_VALUE)       ? lstr_SEG_VALUE + '.00'          : lstr_SEG_VALUE.toFixed(2),
        s_TKC_LBS         : Number.isInteger(lstr_TKC_LBS)         ? lstr_TKC_LBS.toFixed(0)         : lstr_TKC_LBS.toFixed(0),
        s_WA_CR_BR        : lstr_NET_WT_LBS > 0 ? (Number(lstr_WA_CR_BR)/Number(lstr_NET_WT_LBS)).toFixed(2) : lstr_WA_CR_BR.toFixed(2),
        s_WA_DAM          : lstr_NET_WT_LBS > 0 ? (Number(lstr_WA_DAM)/Number(lstr_NET_WT_LBS)).toFixed(2) : lstr_WA_DAM.toFixed(2),
        s_WA_ELK          : lstr_NET_WT_LBS > 0 ? (Number(lstr_WA_ELK)/Number(lstr_NET_WT_LBS)).toFixed(2) : lstr_WA_ELK.toFixed(2),
        s_WA_FAN          : lstr_NET_WT_LBS > 0 ? (Number(lstr_WA_FAN)/Number(lstr_NET_WT_LBS)).toFixed(2) : lstr_WA_FAN.toFixed(2),
        s_WA_FM           : lstr_NET_WT_LBS > 0 ? (Number(lstr_WA_FM)/Number(lstr_NET_WT_LBS)).toFixed(2) : lstr_WA_FM.toFixed(2),
        s_WA_FREZ_DAM     : lstr_NET_WT_LBS > 0 ? (Number(lstr_WA_FREZ_DAM)/Number(lstr_NET_WT_LBS)).toFixed(2)  : lstr_WA_FREZ_DAM.toFixed(2),
        s_WA_HULL         : lstr_NET_WT_LBS > 0 ? (Number(lstr_WA_HULL)/Number(lstr_NET_WT_LBS)).toFixed(2) : lstr_WA_HULL.toFixed(2),
        s_WA_LSK          : lstr_NET_WT_LBS > 0 ? (Number(lstr_WA_LSK)/Number(lstr_NET_WT_LBS)).toFixed(2) : lstr_WA_LSK.toFixed(2),
        s_WA_MOIST        : lstr_NET_WT_LBS > 0 ? (Number(lstr_WA_MOIST)/Number(lstr_NET_WT_LBS)).toFixed(2) : lstr_WA_MOIST.toFixed(2),
        s_WA_OK           : lstr_NET_WT_LBS > 0 ? (Number(lstr_WA_OK)/Number(lstr_NET_WT_LBS)).toFixed(2) : lstr_WA_OK.toFixed(2),
        s_WA_SMK          : lstr_NET_WT_LBS > 0 ? (Number(lstr_WA_SMK)/Number(lstr_NET_WT_LBS)).toFixed(2) : lstr_WA_SMK.toFixed(2),
        s_WA_SS           : lstr_NET_WT_LBS > 0 ? (Number(lstr_WA_SS)/Number(lstr_NET_WT_LBS)).toFixed(2) : lstr_WA_SS.toFixed(2),
        s_WA_TKC          : lstr_NET_WT_LBS > 0 ? (Number(lstr_WA_TKC)/Number(lstr_NET_WT_LBS)).toFixed(2) : lstr_WA_TKC.toFixed(2),
        s_OpenBalance     : Number(taws_lstrXML[0].agreed_lbs) - Number(lstr_APPLIED_LBS),
        pageZeroBreak     : LstrXMLsettlement.length == 0 ? '' : `style="page-break-after: always"`
      }
      
      var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test';

      return `<html>
      <style>
      body:before{
          content: '${environmentType}';
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;  
          color: #0d745e;
          font-size: 250px;
          font-weight: 500px;
          display: grid;
          justify-content: center;
          align-content: center;
          opacity: 0.35;
      }
      @media print {
        @page :footer { display: none }
        @page :header { display: none }
        @page { margin-top : 0; margin-bottom: 0; }
        html, body {
          height:100%; 
          margin: 0 !important; 
          padding: 0 !important;
        }      
      }    
      </style>
      
      <p ${obj.pageZeroBreak}>
      ${await rptTradeAgreementWorksheetHeader(headerObj)}
    
    <table style="padding-top:2%;padding-left:2%;">
        <tr>
            <td >
                <b> Trade Settlement Application: </b>
            </td>            
        </tr>
    </table>

    <table style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;padding-left:1%;font-size:70%;width:100%;">
        <tr style="background-color: silver;">
            <td style="padding-right: 5px;"> 
                <b> Loc <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Insp#  <br/> Net Lbs. <br/> 100% Value </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Trade/1007#  <br/> Net Tons <br/> Seg Value </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Seed Gen  <br/> LSK <br/> Applied Lbs. </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Var  <br/> Net-LSK <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> TKC LBS. <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> tkc% <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> smk <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> ss <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Shipped Date <br/> ok <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> dam <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> hul <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Received Date <br/> fm <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> lsk <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> Settled Date <br/> moi <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> fan <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> elk <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> cr <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
                <b> <br/> fd <br/> <br/> </b>
            </td>
        </tr>

        ${obj.settlementData1}
    </table>
    </p>
    
    ${settlementData2} 

    <p>
        ${premiumDataTable}

        <table style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-size:75%;width:100%;">
        <tr style="background-color: #c0bcbc;">
            <td> 
                <span style="font-size:100%;"> 
                    <b> Weighted Average<br/> <br/> &nbsp;&nbsp;&nbsp; 100% Value </b>
                </span>
            </td>
            <td>
                <span style="font-size:100%;"> 
                    <b> Total Weights  <br/> Total Lbs. <br/> <br/> </b>
                </span>
            </td>
            <td> 
                <span style="font-size:100%;">
                    <b> and Total Amounts  <br/> Total Tons <br/> Seg Value </b>
                </span>
            </td>
            <td> 
                <span style="font-size:100%;">
                    <b>  <br/> LSK <br/> Applied Lbs.</b>
                </span>
            </td>
            <td> 
                <span style="font-size:100%;">
                    <b> <br/> Net-Lsk <br/> <br/> </b>
                </span>
            </td>
            <td > 
                <span style="font-size:100%;">
                    <b> <br/> TKC LBS. <br/> Agreement No. <br/> </b>
                </span>
            </td>
            <td > 
                <span style="font-size:100%;">
                    <b> <br/> tkc <br/> Open Balance <br/> </b> 
                </span>
            </td>
            <td > 
                <span style="font-size:100%;">
                    <b> <br/> smk <br/> <br/> </b>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:100%;">
                    <b> <br/> ss <br/> <br/> </b>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:100%;">
                    <b> <br/> ok <br/> <br/> </b>
                </span>
            </td>
            <td style="padding-right: 5px;">
                <span style="font-size:100%;"> 
                    <b> <br/> dam <br/> <br/> </b>
                </span>
            </td>
            <td style="padding-right: 5px;">
                <span style="font-size:100%;">  
                    <b> <br/> hul <br/>  <br/> </b>
                </span>
            </td>
            <td style="padding-right: 5px;">
                <span style="font-size:100%;">  
                    <b> <br/> fm <br/>  <br/> </b>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:100%;"> 
                    <b> <br/> lsk <br/> <br/> </b>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:100%;"> 
                    <b> <br/> moi <br/>  <br/> </b>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:100%;"> 
                    <b> <br/> fan <br/>  <br/> </b>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:100%;"> 
                    <b> <br/> elk <br/>  <br/> </b>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:100%;"> 
                    <b> <br/> cr <br/> <br/> </b>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:100%;"> 
                    <b> <br/> fd <br/> <br/> </b>
                </span>
            </td>
        </tr>

        <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;padding-left:1%;font-size:80%;">
            <td>
                <span style="font-size:130%;"> 
                    <br/> <br/> ${obj.s_BASIS_GRADE_AMT}
                </span>
            </td>
            <td> 
                <span style="font-size:130%;">
                    <br/> ${obj.s_NET_WT_LBS} <br/> <br/>
                </span>
            </td>            
            <td> 
                <span style="font-size:130%;">
                    <br/> ${obj.s_NET_WT_TONS} <br/> ${obj.s_SEG_VALUE}
                </span>
            </td>
            <td> 
                <span style="font-size:130%;">
                    <br/> ${obj.s_LSK_WT} <br/> ${obj.s_APPLIED_LBS}
                </span> 
            </td>
            <td> 
                <span style="font-size:130%;">  
                    <br/> ${obj.s_NET_LESS_LSK} <br/> <br/>
                </span>
            </td>
            <td> 
                <span style="font-size:130%;">  
                    <br/> ${obj.s_TKC_LBS} <br/> ${taws_lstrXML[0].agreed_lbs} <br/>
                </span>
            </td>
            <td> 
                <span style="font-size:130%;"> 
                    <br/> ${obj.s_WA_TKC} <br/> ${obj.s_OpenBalance} <br/>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:130%;">
                    <br/> ${obj.s_WA_SMK} <br/>  <br/>
                </span>
            </td>
            <td style="padding-right: 5px;">
                <span style="font-size:130%;"> 
                    <br/> ${obj.s_WA_SS} <br/> <br/>
                </span>
            </td>
            <td style="padding-right: 5px;">
                <span style="font-size:130%;">  
                    <br/> ${obj.s_WA_OK} <br/> <br/>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:130%;"> 
                    <br/> ${obj.s_WA_DAM} <br/> <br/>
                </span>
            </td>
            <td style="padding-right: 5px;">
                <span style="font-size:130%;"> 
                    <br/> ${obj.s_WA_HULL} <br/>  <br/>
                </span>
            </td>
            <td style="padding-right: 5px;">
                <span style="font-size:130%;"> 
                    <br/> ${obj.s_WA_FM} <br/> <br/>
                </span>
            </td>
            <td style="padding-right: 5px;">
                <span style="font-size:130%;"> 
                    <br/> ${obj.s_WA_LSK} <br/>  <br/>
                </span>
            </td>
            <td style="padding-right: 5px;">
                <span style="font-size:130%;"> 
                    <br/> ${obj.s_WA_MOIST} <br/>  <br/>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:130%;">
                    <br/> ${obj.s_WA_FAN} <br/> <br/>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:130%;">
                    <br/> ${obj.s_WA_ELK} <br/>  <br/>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:130%;">
                    <br/> ${obj.s_WA_CR_BR} <br/>  <br/>
                </span>
            </td>
            <td style="padding-right: 5px;"> 
                <span style="font-size:130%;">
                    <br/> ${obj.s_WA_FREZ_DAM} <br/>  <br/>
                </span>
            </td>
        </tr>

    </table> 
    </p> </html> `
    }
    catch (err) {
        errorHandler(err);
    }
  }

  async function rptDelvAgreementWorksheet(sAgreeNum, buyingPt) {
    try {
      let LstrXML2 = [];
      let LstrXML3 = [];
      let LstrXML4 = [];
      let LstrXML5 = [];
      let LstrXML5Hold = '';
      let LstrXML6 = '';
      let LstrXML6Hold = '';
      let nTestPrint = 0;
      let sInspections = '';
      let dblNewProceeds = 0;
      let dblPremiumTotal = 0;
      let dblDeductionsTotal = 0;
      let LstrCityState = '';
      let LstrFedWhsLic = '';
      let Lint1007_lYPos = 0;
      let Ldbl_WtAvg_OptionPerTon = 0;
      let Ldbl_WtAvg_OptionPct = 0;
      let LblnFirst = false;

      let dblBasisGradeAmount = 0;
      let lngPounds = 0;
      let dblOptionValue = 0;
      let dblBasisValue = 0;
      let dblFirmValue = 0;
      let dblpurchase_value = 0;

      let LintContractCtr = 0;
      let Ldbl100Pct1007Value = 0;
      let Ldbl1007Lbs = 0;
      let Ldbl1007OptionValue = 0;
      let Ldbl1007BasisValue = 0;
      let Ldbl1007FirmValue = 0;

      let LdblNet1007 = 0;
      let LdblNetTAP = 0;

      let LdblNet_Wt_less_Lsk_wt = 0;
      let LdblLsk_wt = 0;

      let LstrXMLsettlement = '';

      let lstr_NET_WT_LBS = 0;
      let lstr_NET_WT_TONS = 0;
      let lstr_LSK_WT = 0;
      let lstr_NET_LESS_LSK = 0;
      let lstr_BASIS_GRADE_AMT = 0;
      let lstr_SEG_VALUE = 0;
      let lstr_APPLIED_LBS = 0;
      let lstr_TKC_LBS = 0;
      let lstr_WA_TKC = 0;
      let lstr_WA_SMK = 0;
      let lstr_WA_SS = 0;
      let lstr_WA_OK = 0;
      let lstr_WA_DAM = 0;
      let lstr_WA_HULL = 0;
      let lstr_WA_FM = 0;
      let lstr_WA_LSK = 0;
      let lstr_WA_MOIST = 0;
      let lstr_WA_FAN = 0;
      let lstr_WA_ELK = 0;
      let lstr_WA_CR_BR = 0;
      let lstr_WA_FREZ_DAM = 0;

      let lstrDELV_TOTAL_LBS = 0;
      let lstrDELV_TOTAL_TONS = 0;
      let lstrDELV_TOTAL_LSK = 0;
      let lstrDELV_TOTAL_NET_LSK = 0;
      let lstrDELV_TOTAL_100_VALUE = 0;
      let lstrDELV_TOTAL_100_APPL_DOL = 0;
      let lstrDELV_TOTAL_100_ADJ_DOL = 0;
      let lstrDELV_TOTAL_APPL_LBS = 0;
      let lstrDELV_TOTAL_100_ADJ_LBS = 0;
      let sPeanutVariety = '';

      let strOpenBal = 0;

      dblBasisGradeAmount = 0;
      lngPounds = 0;
      dblOptionValue = 0;
      dblBasisValue = 0;
      dblFirmValue = 0;

      taws_lstrXML = "";
      taws_lYPos = 0;
      taws_BPCityState = "";
      taws_LicenseNo = "";
      taws_CCCCodeNo = "";
      taws_mbPurchased = "";
      taws_LstrText = "";
      taws_LintHeight = 0;
      taws_bOptionPayment = false;
      taws_sContractList = "";

      LblnFirst = true;
      Ldbl_WtAvg_OptionPerTon = 0;
      Ldbl_WtAvg_OptionPct = 0;

      lstr_NET_WT_LBS = 0;
      lstr_NET_WT_TONS = 0;
      lstr_LSK_WT = 0;
      lstr_NET_LESS_LSK = 0;
      lstr_BASIS_GRADE_AMT = 0;
      lstr_SEG_VALUE = 0;
      lstr_APPLIED_LBS = 0;
      lstr_TKC_LBS = 0;
      lstr_WA_TKC = 0;
      lstr_WA_SMK = 0;
      lstr_WA_SS = 0;
      lstr_WA_OK = 0;
      lstr_WA_DAM = 0;
      lstr_WA_HULL = 0;
      lstr_WA_FM = 0;
      lstr_WA_LSK = 0;
      lstr_WA_MOIST = 0;
      lstr_WA_FAN = 0;
      lstr_WA_ELK = 0;
      lstr_WA_CR_BR = 0;
      lstr_WA_FREZ_DAM = 0;

      lstrDELV_TOTAL_LBS = 0;
      lstrDELV_TOTAL_TONS = 0;
      lstrDELV_TOTAL_LSK = 0;
      lstrDELV_TOTAL_NET_LSK = 0;
      lstrDELV_TOTAL_100_VALUE = 0;
      lstrDELV_TOTAL_100_APPL_DOL = 0;
      lstrDELV_TOTAL_100_ADJ_DOL = 0;
      lstrDELV_TOTAL_APPL_LBS = 0;
      lstrDELV_TOTAL_100_ADJ_LBS = 0;

      msReport = 'DELV_AGREE_WORKSHEET';
      const recordsPerPage = 8

      let response = await SettlementService.RetrieveTradeAgreementDetails('PN9000', null, buyingPt, sAgreeNum, null, null, null, null, null, null, null, null, 'Y', null)
      taws_lstrXML = response;
      if(response?.status == 500){
        alert('Error Communicating with the database. Please reprint.');
        return '';
      }
      
      response = await AccountPayment.RetrieveAgreementWorksheetDocumentDetails(sAgreeNum, 'Y', buyingPt)
      if(response?.status == 500){
        alert('Error Communicating with the database. Please reprint.');
        return '';
      }

      sInspections = response;
      
      msPDF_BP = buyingPt;
      msPDF_AgreementNo = '0'.repeat(7 - sAgreeNum?.length) + sAgreeNum?.trim();

      LstrXMLsettlement = sInspections[0]?.settlements;      

      let dbl100AdjLbs = 0;
      let dblOpenLbs = 0;
      let dblNetWt = 0;
      let dblBGA = 0;
      let LstrXMLAppl = [];
      if(Number(taws_lstrXML[0]?.delv_unapp_dol) !== 0 && Number(taws_lstrXML[0]?.delv_app_dol) !== 0){
        let resp = await SettlementService.RetrieveTradeAgreementApplicationDetails(sAgreeNum, 'Y', buyingPt)
        
        LstrXMLAppl = resp;
        if(resp?.length > 0){
          for(var i=0; i<resp?.length; i++){
            if(Number(resp[i].unapp_dol_value?.trim()) > 0){
              dblNetWt += resp[i]?.net_wt;
              dblBGA += resp[i]?.basis_grade_amt;
            }
          }
        }
        dbl100AdjLbs = dblNetWt * Number(taws_lstrXML[0]?.delv_unapp_dol);
      }
      else{
        dbl100AdjLbs = 0;
      }
      dblOpenLbs = Number(taws_lstrXML[0]?.agreed_lbs) + Number(taws_lstrXML[0]?.adjust_lbs) - Number(taws_lstrXML[0]?.delivered_lbs) - dbl100AdjLbs;
      
      sPeanutVariety = taws_lstrXML[0]?.pnut_variety_name;
      if(taws_lstrXML[0]?.symbol_ind.toUpperCase() == 'TRADEMARK')
        sPeanutVariety += `&reg;`
      else if(taws_lstrXML[0]?.symbol_ind.toUpperCase() == 'REGISTERED TRADEMARK')
        sPeanutVariety += `&trade;`
      else if(taws_lstrXML[0]?.symbol_ind.toUpperCase() == 'COPYRIGHT')
        sPeanutVariety += `&copy;`

      let sPeanutGeneration = ''
      if(taws_lstrXML[0]?.seed_gen == '')
        sPeanutGeneration = '';
      else if(taws_lstrXML[0]?.seed_gen?.toLocaleUpperCase() == 'C')
        sPeanutGeneration = 'Certified';
      else if(taws_lstrXML[0]?.seed_gen?.toLocaleUpperCase() == 'F')
        sPeanutGeneration = 'Foundation';
      else if(taws_lstrXML[0]?.seed_gen?.toLocaleUpperCase() == 'R')
        sPeanutGeneration = 'Registered';
      
      LstrXMLVendors = taws_lstrXML[0]?.split_vendor?.split('|');
      LstrXMLVendorNames = taws_lstrXML[0]?.vendor_name?.split('|');
      LstrXMLVendorPCT = taws_lstrXML[0]?.split_share_pct?.split('|');

      let vendorData = '';
      let colVendors = []
      for(var i=0; i< LstrXMLVendors.length; i++){
        colVendors.push({
          split_vendor    : LstrXMLVendors[i],
          vendor_name     : LstrXMLVendorNames[i],
          split_share_pct : LstrXMLVendorPCT[i]//?.split('.')[0]
        })        
      }
      for(var i=0; i<colVendors.length; i++){
        vendorData += `<tr>
                <td style="width:35%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: left;">
                  (${colVendors[i].split_vendor}) ${colVendors[i].vendor_name}
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;">
                  ${colVendors[i].split_share_pct}
                </td>`;
        i++;
        if(i<colVendors.length){
            vendorData += `<td style="width:35%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: left;">
                  (${colVendors[i].split_vendor}) ${colVendors[i].vendor_name}
                </td>
                <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;">
                  ${colVendors[i].split_share_pct}
                </td>
            </tr>`;
        }
      
      }
      if(colVendors.length % 2 == 1){
        vendorData += '</tr>';
      }

      let sObj;
      let settlementData1 = ''
      for(var i=0; i<LstrXMLsettlement?.length; i++){
        if(i == recordsPerPage){
          break;
        }

        let sSymbol = ''
        switch(LstrXMLsettlement[i]?.symbol_ind?.trim()?.toLocaleUpperCase()){
          case "TRADEMARK":
            sSymbol = ''
            break;
          case "REGISTERED TRADEMARK":
            sSymbol = ''
            break;
          case "COPYRIGHT":
            sSymbol = ''
            break;
        }
          
        lstr_NET_WT_LBS += Number(LstrXMLsettlement[i]?.net_wt_lbs);
        lstr_NET_WT_TONS += Number(LstrXMLsettlement[i]?.net_wt_tons);
        lstr_LSK_WT += Number(LstrXMLsettlement[i]?.lsk_wt);
        lstr_NET_LESS_LSK += Number(LstrXMLsettlement[i]?.net_less_lsk);

        let dPct = 0;
        if(Number(LstrXMLsettlement[i]?.net_wt_lbs) !== 0){
          dPct = Number(LstrXMLsettlement[i]?.applied_lbs) / Number(LstrXMLsettlement[i]?.net_wt_lbs);
        }

        let dTotVal = Number(Number(LstrXMLsettlement[i]?.settle_dol_value) + Number(LstrXMLsettlement[i]?.unapp_dol_value))?.toFixed(2);

        let dTotAdjLbs = 0;
        if(Number(LstrXMLsettlement[i].unapp_dol_value) !== 0 && Number(LstrXMLsettlement[i].basis_grade_amt) !== 0){
          dTotAdjLbs = Number(LstrXMLsettlement[i].net_wt_lbs) * (Number(LstrXMLsettlement[i].unapp_dol_value) / Number(LstrXMLsettlement[i].basis_grade_amt)) * dPct
        }

        let dTotLbs = Number(LstrXMLsettlement[i]?.applied_lbs) + Number(dTotAdjLbs);
        
        let dTotAdjLskLbs = 0;
        if(Number(dTotAdjLbs) !== 0){
          dTotAdjLskLbs = ((Number(LstrXMLsettlement[i]?.lsk_wt) * Number(LstrXMLsettlement[i]?.unapp_dol_value)) / dTotVal);
        }
        
        let dTotKernPct = (Number(LstrXMLsettlement[i].smk_pct) + Number(LstrXMLsettlement[i].ss_pct) + Number(LstrXMLsettlement[i].ok_pct) + Number(LstrXMLsettlement[i].dam_pct))/100

        let dTKCApp = Number((Number(LstrXMLsettlement[i].applied_lbs) - (Number(LstrXMLsettlement[i].lsk_wt) * dPct)) * dTotKernPct) + (Number(LstrXMLsettlement[i].lsk_wt) * dPct)
        let dTKCAdj = ((dTotAdjLbs - dTotAdjLbs) * dTotKernPct) + dTotAdjLskLbs
        
        lstr_BASIS_GRADE_AMT += Number(LstrXMLsettlement[i]?.basis_grade_amt, 2)?.toFixed(2);
        lstr_SEG_VALUE += Number(LstrXMLsettlement[i]?.seg_value);
        lstr_APPLIED_LBS += Number(LstrXMLsettlement[i]?.applied_lbs);
        lstr_TKC_LBS += (dTKCApp + dTKCAdj);

        lstr_WA_TKC += Number(Number(LstrXMLsettlement[i]?.wa_tkc));
        lstr_WA_SMK += Number(Number(LstrXMLsettlement[i]?.wa_smk));
        lstr_WA_SS += Number(Number(LstrXMLsettlement[i]?.wa_ss));
        lstr_WA_OK += Number(Number(LstrXMLsettlement[i]?.wa_ok));
        lstr_WA_DAM += Number(Number(LstrXMLsettlement[i]?.wa_dam));
        lstr_WA_HULL += Number(Number(LstrXMLsettlement[i]?.wa_hull));
        lstr_WA_FM += Number(Number(LstrXMLsettlement[i]?.wa_fm));
        lstr_WA_LSK += Number(Number(LstrXMLsettlement[i]?.wa_lsk));
        lstr_WA_MOIST += Number(Number(LstrXMLsettlement[i]?.wa_moist));
        lstr_WA_FAN += Number(Number(LstrXMLsettlement[i]?.wa_fan));
        lstr_WA_ELK += Number(Number(LstrXMLsettlement[i]?.wa_elk));
        lstr_WA_CR_BR += Number(Number(LstrXMLsettlement[i]?.wa_cr_br));
        lstr_WA_FREZ_DAM += Number(Number(LstrXMLsettlement[i]?.wa_frez_dam));

        let value = Number((Number(LstrXMLsettlement[i]?.lsk_wt) * Number(dPct)) + Number(dTotAdjLskLbs))
        lstrDELV_TOTAL_LBS += Number(dTotLbs);
        lstrDELV_TOTAL_TONS += Number(Number(dTotLbs)/2000);
        lstrDELV_TOTAL_LSK +=  value;
        lstrDELV_TOTAL_NET_LSK += (Number(dTotLbs) - Number(value));
        lstrDELV_TOTAL_100_VALUE += Number(dTotVal);
        lstrDELV_TOTAL_100_ADJ_DOL += Number(LstrXMLsettlement[i]?.unapp_dol_value);
        lstrDELV_TOTAL_100_APPL_DOL += Number(LstrXMLsettlement[i]?.settle_dol_value);
        lstrDELV_TOTAL_APPL_LBS += Number(LstrXMLsettlement[i]?.applied_lbs);
        lstrDELV_TOTAL_100_ADJ_LBS += Number(dTotAdjLbs);

        taws_LstrText = 'Applicable Deductions: ';
        LstrXML6 = sInspections[0]?.trade_premium_deduction_headers;
        for(var j=0; i<LstrXML6?.length; j++){
          taws_LstrText += (LstrXML6[j]?.pd_code + '=' + LstrXML6[j]?.pd_amount + ', ');
        }

        sObj = {
          loc        : LstrXMLsettlement[i]?.buy_pt_id,
          inspNumber : LstrXMLsettlement[i]?.trade_insp_num,
          trade1007  : LstrXMLsettlement[i]?.trade_settle_num?.slice(0,7),
          seedGen    : LstrXMLsettlement[i]?.seed_gen,          
          var        : LstrXMLsettlement[i]?.pnut_variety_name + sSymbol,
          shipDate   : LstrXMLsettlement[i]?.shp_date != '' ? moment(LstrXMLsettlement[i]?.shp_date).format('MM/DD/YYYY') : '',
          revDate    : LstrXMLsettlement[i]?.rec_date != '' ? moment(LstrXMLsettlement[i]?.rec_date).format('MM/DD/YYYY') : '',
          settleDate : LstrXMLsettlement[i]?.settle_date_time != '' ? moment(LstrXMLsettlement[i]?.settle_date_time).format('MM/DD/YYYY') : '',
          netLbs     : dTotLbs,
          netTon     : Number(dTotLbs/2000).toFixed(2),
          lsk        : Number((Number(LstrXMLsettlement[i]?.lsk_wt) * Number(dPct)) + Number(dTotAdjLskLbs)).toFixed(0),
          netLsk     : Number(Number(dTotLbs) - Number(Number(LstrXMLsettlement[i]?.lsk_wt) * dPct) + dTotAdjLskLbs).toFixed(0),
          tkcLBS     : Number(Number(dTKCApp) + Number(dTKCAdj)).toFixed(0),
          tckPct     : LstrXMLsettlement[i]?.tkc_pct,
          smk        : Number(LstrXMLsettlement[i]?.smk_pct).toFixed(1),
          ss         : Number(LstrXMLsettlement[i]?.ss_pct).toFixed(1),
          ok         : Number(LstrXMLsettlement[i]?.ok_pct).toFixed(1),
          dam        : Number(LstrXMLsettlement[i]?.dam_pct).toFixed(1),
          hul        : Number(LstrXMLsettlement[i]?.hull_pct).toFixed(1),
          fm         : Number(LstrXMLsettlement[i]?.fm_pct).toFixed(1),
          moi        : Number(LstrXMLsettlement[i]?.moist_pct).toFixed(1),
          fan        : Number(LstrXMLsettlement[i]?.fan_pct).toFixed(0),
          elk        : Number(LstrXMLsettlement[i]?.elk_pct).toFixed(0),
          cr         : Number(LstrXMLsettlement[i]?.cr_br_pct).toFixed(0),
          fd         : Number(LstrXMLsettlement[i]?.frez_dam_pct).toFixed(0),
          value100   : dTotVal,
          apply100   : LstrXMLsettlement[i].settle_dol_value,
          adjust100  : LstrXMLsettlement[i].unapp_dol_value,
          appliedLbs : LstrXMLsettlement[i]?.applied_lbs,
          adjlbs100  : dTotAdjLbs
        }
        settlementData1 += `<tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="padding-right: 5px;"> 
            ${sObj.loc} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            ${sObj.inspNumber}  <br/> ${sObj.netLbs} <br/> ${sObj.value100}
        </td>
        <td style="padding-right: 5px;"> 
          ${sObj.trade1007}  <br/> ${sObj.netTon} <br/> ${sObj.apply100}
        </td>
        <td style="padding-right: 5px;"> 
        ${sObj.seedGen}  <br/> ${sObj.lsk} <br/>
        </td>
        <td style="padding-right: 5px;"> 
        ${sObj.var}  <br/> ${sObj.netLsk} <br/> ${sObj.adjust100}
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.tkcLBS} <br/> ${sObj.appliedLbs}
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.tckPct} <br/> <br>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.smk} <br/> ${sObj.adjlbs100}
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.ss} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
        ${sObj.shipDate} <br/> ${sObj.ok} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.dam} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.hul} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
        ${sObj.revDate} <br/> ${sObj.fm} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.lsk} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
        ${sObj.settleDate} <br/> ${sObj.moi} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.fan} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.elk} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.cr} <br/> <br/>
        </td>
        <td style="padding-right: 5px;"> 
            <br/> ${sObj.fd} <br/> <br/>
        </td>
        </tr>`
      }

      let totalHeading;
      let totalBody;
      if(taws_LstrText == 'Applicable Deductions: '){
        totalHeading = 'Totals: ';
        totalBody = '';
      }
      else{
        totalHeading = 'Applicable Deductions: '
        totalBody = taws_LstrText;
      }

      var obj = {
        cropYear          : cropYearFromLS(),
        typeHeader        : taws_lstrXML[0]?.purch_sale_ind?.toLocaleUpperCase() == 'P' ? 'Delivery Purchase' : 'Delivery  Sale',
        agreementNumber   : taws_lstrXML[0]?.agreement_num,
        agreementDate     : formatDate(taws_lstrXML[0]?.agree_date_time)?.split(" ")[0],
        agreementStatus   : taws_lstrXML[0]?.agree_status == 'O' ? 'Open' : taws_lstrXML[0]?.agree_status == 'C' ? 'Complete' : '',
        gradePricingMethod: taws_lstrXML[0]?.grade_pricing_method?.toLocaleUpperCase(),
        pricingType       : taws_lstrXML[0]?.firm_basis_opt?.toLocaleUpperCase() == 'F' ? 'FIRM' : taws_lstrXML[0]?.firm_basis_opt?.toLocaleUpperCase() == 'B' ? 'BASIS' : taws_lstrXML[0]?.firm_basis_opt?.toLocaleUpperCase() == 'L' ? 'FLAT' : taws_lstrXML[0]?.firm_basis_opt?.toLocaleUpperCase(),
        agreementLoc      : taws_lstrXML[0]?.buy_pt_id + ' - ' + taws_lstrXML[0]?.buy_pt_name,
        recpValue         : Number(taws_lstrXML[0]?.rcpt_value).toFixed(2),
        delApp            : Number(taws_lstrXML[0]?.delv_app_dol).toFixed(2),
        delUnApp          : Number(taws_lstrXML[0]?.delv_unapp_dol).toFixed(2),
        openPer           : Number(Number(taws_lstrXML[0]?.rcpt_value) - Number(taws_lstrXML[0]?.delv_app_dol) - Number(taws_lstrXML[0]?.delv_unapp_dol)).toFixed(2),        
        reciptLbs         : Number(taws_lstrXML[0]?.agreed_lbs),
        adjustLbs         : Number(taws_lstrXML[0]?.adjust_lbs),
        totalAdjLbs       : Number(taws_lstrXML[0]?.agreed_lbs) + Number(taws_lstrXML[0]?.adjust_lbs),
        appliedLbs        : Number(taws_lstrXML[0]?.delivered_lbs),
        adjust_lbs        : dbl100AdjLbs,
        dblOpenLbs        : dblOpenLbs,
        peanutType        : taws_lstrXML[0]?.pnut_type_name,
        variety           : sPeanutVariety,
        generation        : sPeanutGeneration,
        seg               : taws_lstrXML[0]?.seg_type,
        oliec             : taws_lstrXML[0]?.oleic_ind?.toLocaleUpperCase() == 'H' ? 'High' : taws_lstrXML[0]?.oleic_ind?.toLocaleUpperCase() == 'M' ? 'Medium' : 'None',
        edibleOil         : taws_lstrXML[0]?.edible_oil_ind?.toLocaleUpperCase() == 'E' ? 'Edible' : taws_lstrXML[0]?.edible_oil_ind?.toLocaleUpperCase() == 'O' ? 'Oil' : '',
        vendorData        : vendorData,
        settlementData1   : settlementData1,
        totalHeading      : totalHeading,
        totalBody         : totalBody
      }

      let settlementData2 = ''
      let count = recordsPerPage
      if(LstrXMLsettlement?.length){
        for(var i=recordsPerPage; i<LstrXMLsettlement?.length; i++){
          if(count%recordsPerPage == 0){
            settlementData2 += `<p`
            settlementData2 += (count + recordsPerPage < LstrXMLsettlement?.length ? 
                                  ` style="page-break-after: always">` : `>`)
            
            settlementData2 += await rptDelvAgreementWorksheetHeader(obj)

            settlementData2 += `<table style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;padding-left:1%;font-size:70%;width:100%;">
              <tr style="width=100%;">
                <td style="padding-right: 5px;"> 
                    <b> Loc <br/> <br/> </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> Insp#  <br/> Net Lbs. <br/> 100% Value </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> Trade/1007#  <br/> Net Tons <br/> 100% Applied $ </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> Seed Gen  <br/> LSK <br/> </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> Var  <br/> Net-LSK <br/> 100% Adjustment $ </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> <br/> TKC LBS. <br/> Appl. Lbs </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> <br/> tkc% <br/> </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> <br/> smk <br/> 100% Adj. Lbs </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> <br/> ss <br/> <br/> </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> Shipped Date <br/> ok <br/> <br/> </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> <br/> dam <br/> <br/> </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> <br/> hul <br/> <br/> </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> Received Date <br/> fm <br/> <br/> </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> <br/> lsk <br/> <br/> </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> Settled Date <br/> moi <br/> <br/> </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> <br/> fan <br/> <br/> </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> <br/> elk <br/> <br/> </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> <br/> cr <br/> <br/> </b>
                </td>
                <td style="padding-right: 5px;"> 
                    <b> <br/> fd <br/> <br/> </b>
                </td>
              </tr>`
          }
          
          let sSymbol = ''
          switch(LstrXMLsettlement[i]?.symbol_ind?.trim()?.toLocaleUpperCase()){
            case "TRADEMARK":
              sSymbol = ''
              break;
            case "REGISTERED TRADEMARK":
              sSymbol = ''
              break;
            case "COPYRIGHT":
              sSymbol = ''
              break;
          }
          
          lstr_NET_WT_LBS += Number(LstrXMLsettlement[i]?.net_wt_lbs);
          lstr_NET_WT_TONS += Number(LstrXMLsettlement[i]?.net_wt_tons);
          lstr_LSK_WT += Number(LstrXMLsettlement[i]?.lsk_wt);
          lstr_NET_LESS_LSK += Number(LstrXMLsettlement[i]?.net_less_lsk);

          let dPct = 0;
          if(Number(LstrXMLsettlement[i]?.net_wt_lbs) !== 0){
            dPct = Number(LstrXMLsettlement[i]?.applied_lbs) / Number(LstrXMLsettlement[i]?.net_wt_lbs);
          }

          let dTotVal = Number(Number(LstrXMLsettlement[i]?.settle_dol_value) + Number(LstrXMLsettlement[i]?.unapp_dol_value))?.toFixed(2);

          let dTotAdjLbs = 0;
          if(Number(LstrXMLsettlement[i].unapp_dol_value) !== 0 && Number(LstrXMLsettlement[i].basis_grade_amt) !== 0){
            dTotAdjLbs = Number(LstrXMLsettlement[i].net_wt_lbs) * (Number(LstrXMLsettlement[i].unapp_dol_value) / Number(LstrXMLsettlement[i].basis_grade_amt)) * dPct
          }

          let dTotLbs = Number(LstrXMLsettlement[i]?.applied_lbs) + Number(dTotAdjLbs);

          let dTotAdjLskLbs = 0;
          if(Number(dTotAdjLbs) !== 0){
            dTotAdjLskLbs = ((Number(LstrXMLsettlement[i]?.lsk_wt) * Number(LstrXMLsettlement[i]?.unapp_dol_value)) / dTotVal);
          }
          
          let dTotKernPct = (Number(LstrXMLsettlement[i].smk_pct) + Number(LstrXMLsettlement[i].ss_pct) + Number(LstrXMLsettlement[i].ok_pct) + Number(LstrXMLsettlement[i].dam_pct))/100

          let dTKCApp = Number((Number(LstrXMLsettlement[i].applied_lbs) - (Number(LstrXMLsettlement[i].lsk_wt) * dPct)) * dTotKernPct) + (Number(LstrXMLsettlement[i].lsk_wt) * dPct)
          let dTKCAdj = ((dTotAdjLbs - dTotAdjLbs) * dTotKernPct) + dTotAdjLskLbs
        
          lstr_BASIS_GRADE_AMT += Number(LstrXMLsettlement[i]?.basis_grade_amt, 2)?.toFixed(2);
          lstr_SEG_VALUE += Number(LstrXMLsettlement[i]?.seg_value);
          lstr_APPLIED_LBS += Number(LstrXMLsettlement[i]?.applied_lbs);
          lstr_TKC_LBS += (dTKCApp + dTKCAdj);

          lstr_WA_TKC += Number(Number(LstrXMLsettlement[i]?.wa_tkc));
          lstr_WA_SMK += Number(Number(LstrXMLsettlement[i]?.wa_smk));
          lstr_WA_SS += Number(Number(LstrXMLsettlement[i]?.wa_ss));
          lstr_WA_OK += Number(Number(LstrXMLsettlement[i]?.wa_ok));
          lstr_WA_DAM += Number(Number(LstrXMLsettlement[i]?.wa_dam));
          lstr_WA_HULL += Number(Number(LstrXMLsettlement[i]?.wa_hull));
          lstr_WA_FM += Number(Number(LstrXMLsettlement[i]?.wa_fm));
          lstr_WA_LSK += Number(Number(LstrXMLsettlement[i]?.wa_lsk));
          lstr_WA_MOIST += Number(Number(LstrXMLsettlement[i]?.wa_moist));
          lstr_WA_FAN += Number(Number(LstrXMLsettlement[i]?.wa_fan));
          lstr_WA_ELK += Number(Number(LstrXMLsettlement[i]?.wa_elk));
          lstr_WA_CR_BR += Number(Number(LstrXMLsettlement[i]?.wa_cr_br));
          lstr_WA_FREZ_DAM += Number(Number(LstrXMLsettlement[i]?.wa_frez_dam));

          let value = Number((Number(LstrXMLsettlement[i]?.lsk_wt) * Number(dPct)) + Number(dTotAdjLskLbs))
          lstrDELV_TOTAL_LBS += Number(dTotLbs);
          lstrDELV_TOTAL_TONS += Number(Number(dTotLbs)/2000);
          lstrDELV_TOTAL_LSK +=  value;
          lstrDELV_TOTAL_NET_LSK += (Number(dTotLbs) - Number(value));
          lstrDELV_TOTAL_100_VALUE += Number(dTotVal);
          lstrDELV_TOTAL_100_ADJ_DOL += Number(LstrXMLsettlement[i]?.unapp_dol_value);
          lstrDELV_TOTAL_100_APPL_DOL += Number(LstrXMLsettlement[i]?.settle_dol_value);
          lstrDELV_TOTAL_APPL_LBS += Number(LstrXMLsettlement[i]?.applied_lbs);
          lstrDELV_TOTAL_100_ADJ_LBS += Number(dTotAdjLbs);

          taws_LstrText = 'Applicable Deductions: ';
          LstrXML6 = sInspections[0]?.trade_premium_deduction_headers;
          for(var j=0; i<LstrXML6?.length; j++){
            taws_LstrText += (LstrXML6[j]?.pd_code + '=' + LstrXML6[j]?.pd_amount + ', ');
          }

          sObj = {
            loc        : LstrXMLsettlement[i]?.buy_pt_id,
            inspNumber : LstrXMLsettlement[i]?.trade_insp_num,
            trade1007  : LstrXMLsettlement[i]?.trade_settle_num?.slice(0,7),
            seedGen    : LstrXMLsettlement[i]?.seed_gen,          
            var        : LstrXMLsettlement[i]?.pnut_variety_name + sSymbol,
            shipDate   : LstrXMLsettlement[i]?.shp_date != '' ? moment(LstrXMLsettlement[i]?.shp_date).format('MM/DD/YYYY') : '',
            revDate    : LstrXMLsettlement[i]?.rec_date != '' ? moment(LstrXMLsettlement[i]?.rec_date).format('MM/DD/YYYY') : '',
            settleDate : LstrXMLsettlement[i]?.settle_date_time != '' ? moment(LstrXMLsettlement[i]?.settle_date_time).format('MM/DD/YYYY') : '',
            netLbs     : dTotLbs,
            netTon     : Number(dTotLbs/2000).toFixed(2),
            lsk        : Number((Number(LstrXMLsettlement[i]?.lsk_wt) * Number(dPct)) + Number(dTotAdjLskLbs)).toFixed(0),
            netLsk     : Number(Number(dTotLbs) - Number(Number(LstrXMLsettlement[i]?.lsk_wt) * dPct) + dTotAdjLskLbs).toFixed(0),
            tkcLBS     : Number(Number(dTKCApp) + Number(dTKCAdj)).toFixed(0),
            tckPct     : LstrXMLsettlement[i]?.tkc_pct,
            smk        : Number(LstrXMLsettlement[i]?.smk_pct).toFixed(1),
            ss         : Number(LstrXMLsettlement[i]?.ss_pct).toFixed(1),
            ok         : Number(LstrXMLsettlement[i]?.ok_pct).toFixed(1),
            dam        : Number(LstrXMLsettlement[i]?.dam_pct).toFixed(1),
            hul        : Number(LstrXMLsettlement[i]?.hull_pct).toFixed(1),
            fm         : Number(LstrXMLsettlement[i]?.fm_pct).toFixed(1),
            moi        : Number(LstrXMLsettlement[i]?.moist_pct).toFixed(1),
            fan        : Number(LstrXMLsettlement[i]?.fan_pct).toFixed(0),
            elk        : Number(LstrXMLsettlement[i]?.elk_pct).toFixed(0),
            cr         : Number(LstrXMLsettlement[i]?.cr_br_pct).toFixed(0),
            fd         : Number(LstrXMLsettlement[i]?.frez_dam_pct).toFixed(0),
            value100   : dTotVal,
            apply100   : LstrXMLsettlement[i].settle_dol_value,
            adjust100  : LstrXMLsettlement[i].unapp_dol_value,
            appliedLbs : LstrXMLsettlement[i]?.applied_lbs,
            adjlbs100  : dTotAdjLbs
          }
          
          settlementData2 += `<table style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;padding-left:1%;font-size:70%;width:100%;">
              <tr style="width=100%;">
                <td style="padding-right: 5px;"> 
                    ${sObj.loc} <br/> <br/>
                </td>
                <td style="padding-right: 5px;"> 
                    ${sObj.inspNumber}  <br/> ${sObj.netLbs} <br/> ${sObj.value100}
                </td>
                <td style="padding-right: 5px;"> 
                    ${sObj.trade1007} <br/> ${sObj.netTon} <br/> ${sObj.apply100}
                </td>
                <td style="padding-right: 5px;"> 
                    ${sObj.seedGen}  <br/> ${sObj.lsk} <br/>
                </td>
                <td style="padding-right: 5px;"> 
                    ${sObj.var} <br/> ${sObj.netLsk} <br/> ${sObj.adjust100}
                </td>
                <td style="padding-right: 5px;"> 
                    <br/> ${sObj.tkcLBS} <br/> ${sObj.appliedLbs}
                </td>
                <td style="padding-right: 5px;"> 
                    <br/> ${sObj.tckPct} <br/>
                </td>
                <td style="padding-right: 5px;"> 
                    <br/> ${sObj.smk} <br/> ${sObj.adjlbs100}
                </td>
                <td style="padding-right: 5px;"> 
                    <br/> ${sObj.ss} <br/> <br/>
                </td>
                <td style="padding-right: 5px;"> 
                    ${sObj.shipDate} <br/> ${sObj.ok} <br/> <br/>
                </td>
                <td style="padding-right: 5px;"> 
                    <br/> ${sObj.dam} <br/> <br/>
                </td>
                <td style="padding-right: 5px;"> 
                    <br/> ${sObj.hul} <br/> <br/>
                </td>
                <td style="padding-right: 5px;"> 
                    ${sObj.revDate} <br/> ${sObj.fm} <br/> <br/>
                </td>
                <td style="padding-right: 5px;"> 
                    <br/> ${sObj.lsk} <br/> <br/>
                </td>
                <td style="padding-right: 5px;"> 
                    ${sObj.settleDate} <br/> ${sObj.moi} <br/> <br/>
                </td>
                <td style="padding-right: 5px;"> 
                    <br/> ${sObj.fan} <br/> <br/>
                </td>
                <td style="padding-right: 5px;"> 
                    <br/> ${sObj.elk} <br/> <br/>
                </td>
                <td style="padding-right: 5px;"> 
                    <br/> ${sObj.cr} <br/> <br/>
                </td>
                <td style="padding-right: 5px;"> 
                    <br/> ${sObj.fd} <br/> <br/>
                </td>
          </tr>`

          if(count %recordsPerPage == recordsPerPage -1){
            settlementData2 += `</table> </p>`
          }
          count++;
        }

        settlementData2 += '</p> </table>';
      }

      obj.s_LSK_WT          = Number(lstrDELV_TOTAL_LSK)?.toFixed(0)
      obj.s_NET_WT_LBS      = Number(lstrDELV_TOTAL_LBS)?.toFixed(0)
      obj.s_NET_WT_TONS     = Number(lstrDELV_TOTAL_TONS)?.toFixed(2)
      obj.s_NET_TOT_NET_LSK = Number(lstrDELV_TOTAL_NET_LSK)?.toFixed(0)
      obj.s_TKC_LBS         = Number(lstr_TKC_LBS)?.toFixed(0)
      obj.s_WA_CR_BR        = lstr_NET_WT_LBS > 0 ? Number(Number(lstr_WA_CR_BR)/Number(lstr_NET_WT_LBS))?.toFixed(2)     : Number(lstr_WA_CR_BR)?.toFixed(2)
      obj.s_WA_DAM          = lstr_NET_WT_LBS > 0 ? Number(Number(lstr_WA_DAM)/Number(lstr_NET_WT_LBS))?.toFixed(2)       : Number(lstr_WA_DAM)?.toFixed(2)
      obj.s_WA_ELK          = lstr_NET_WT_LBS > 0 ? Number(Number(lstr_WA_ELK)/Number(lstr_NET_WT_LBS))?.toFixed(2)       : Number(lstr_WA_ELK)?.toFixed(2)
      obj.s_WA_FAN          = lstr_NET_WT_LBS > 0 ? Number(Number(lstr_WA_FAN)/Number(lstr_NET_WT_LBS))?.toFixed(2)       : Number(lstr_WA_FAN)?.toFixed(2)
      obj.s_WA_FM           = lstr_NET_WT_LBS > 0 ? Number(Number(lstr_WA_FM)/Number(lstr_NET_WT_LBS))?.toFixed(2)        : Number(lstr_WA_FM)?.toFixed(2)
      obj.s_WA_FREZ_DAM     = lstr_NET_WT_LBS > 0 ? Number(Number(lstr_WA_FREZ_DAM)/Number(lstr_NET_WT_LBS))?.toFixed(2)  : Number(lstr_WA_FREZ_DAM)?.toFixed(2)
      obj.s_WA_HULL         = lstr_NET_WT_LBS > 0 ? Number(Number(lstr_WA_HULL)/Number(lstr_NET_WT_LBS))?.toFixed(2)      : Number(lstr_WA_HULL)?.toFixed(2)
      obj.s_WA_LSK          = lstr_NET_WT_LBS > 0 ? Number(Number(lstr_WA_LSK)/Number(lstr_NET_WT_LBS))?.toFixed(2)       : Number(lstr_WA_LSK)?.toFixed(2)
      obj.s_WA_MOIST        = lstr_NET_WT_LBS > 0 ? Number(Number(lstr_WA_MOIST)/Number(lstr_NET_WT_LBS))?.toFixed(2)     : Number(lstr_WA_MOIST)?.toFixed(2)
      obj.s_WA_OK           = lstr_NET_WT_LBS > 0 ? Number(Number(lstr_WA_OK)/Number(lstr_NET_WT_LBS))?.toFixed(2)        : Number(lstr_WA_OK)?.toFixed(2)
      obj.s_WA_SMK          = lstr_NET_WT_LBS > 0 ? Number(Number(lstr_WA_SMK)/Number(lstr_NET_WT_LBS))?.toFixed(2)       : Number(lstr_WA_SMK)?.toFixed(2)
      obj.s_WA_SS           = lstr_NET_WT_LBS > 0 ? Number(Number(lstr_WA_SS)/Number(lstr_NET_WT_LBS))?.toFixed(2)        : Number(lstr_WA_SS)?.toFixed(2)
      obj.s_WA_TKC          = lstr_NET_WT_LBS > 0 ? Number(Number(lstr_WA_TKC)/Number(lstr_NET_WT_LBS))?.toFixed(2)       : Number(lstr_WA_TKC)?.toFixed(2)
      obj.value100          = Number(lstrDELV_TOTAL_100_VALUE?.toString()?.trim())?.toFixed(2)
      obj.apply100          = Number(lstrDELV_TOTAL_100_APPL_DOL?.toString()?.trim())?.toFixed(2)
      obj.adjust100         = Number(lstrDELV_TOTAL_100_ADJ_DOL?.toString()?.trim())?.toFixed(2)
      obj.rcptValue         = Number(taws_lstrXML[0]?.rcpt_value)
      obj.open100           = Number(Number(taws_lstrXML[0]?.rcpt_value) - Number(taws_lstrXML[0]?.delv_app_dol) - Number(lstrDELV_TOTAL_100_ADJ_DOL))?.toFixed(2)
      obj.applLbs           = lstrDELV_TOTAL_APPL_LBS?.toString()?.trim()
      obj.adjust_lbs        = lstrDELV_TOTAL_100_ADJ_LBS?.toString()?.trim()

      var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test';
    
      let totalTable = `<table style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-size:75%;width:100%;">
        <tr style="border: 1px;border-color: black;border-style: none;border-collapse:collapse;">
          <td style="padding-right:5px;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;" colspan="18"> 
            <b> Weighted Average, Total Weights and Total Amounts </b>
          </td>
        </tr>

        <tr style="border: 1px;border-color: black;border-style: none;border-collapse:collapse;width:100%;">
          <td style="padding-right: 5px;"> 
              <b> Total Lbs. <br/> 100% Value </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> Total Tons <br/> 100% Appl. $ </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> LSK <br/> </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> Net-Lsk <br/> 100% Adj. $ </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> TKC LBS. <br/> Rcpt Value </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> tkc <br/> </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> smk <br/> 100% Open $ </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> ss <br/> Appl. Lbs. </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> ok <br/> </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> dam <br/> 100% Adj. Lbs. </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> hul <br/> </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> fm <br/> </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> lsk <br/> </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> moi <br/> </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> fan <br/> </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> elk <br/> </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> cr <br/> </b>
          </td>
          <td style="padding-right: 5px;"> 
              <b> fd <br/> </b>
          </td>
      </tr>`

      totalTable += `<tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;padding-left:1%;font-size:80%;">
        <td style="padding-right: 5px;"> 
          ${obj.s_NET_WT_LBS} <br/> ${obj.value100}
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_NET_WT_TONS} <br/> ${obj.apply100}
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_LSK_WT} <br/>
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_NET_TOT_NET_LSK} <br/> ${obj.adjust100}
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_TKC_LBS} <br/> ${obj.rcptValue}
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_WA_TKC} <br/>
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_WA_SMK} <br/> ${obj.open100}
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_WA_SS} <br/> ${obj.applLbs}
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_WA_OK} <br/>
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_WA_DAM} <br/> ${obj.adjust_lbs == '0' ? '' : obj.adjust_lbs}
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_WA_HULL} <br/>
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_WA_FM} <br/>
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_WA_LSK} <br/>
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_WA_MOIST} <br/>
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_WA_FAN} <br/>
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_WA_ELK} <br/>
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_WA_CR_BR} <br/>
        </td>
        <td style="padding-right: 5px;"> 
          ${obj.s_WA_FREZ_DAM} <br/>
        </td>
      </tr>
      </table>`

      return `<html>
      <style>
      body:before{
          content: '${environmentType}';
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;  
          color: #0d745e;
          font-size: 250px;
          font-weight: 500px;
          display: grid;
          justify-content: center;
          align-content: center;
          opacity: 0.35;
      }
      @media print {
        @page :footer { display: none }
        @page :header { display: none }
        @page { margin-top : 0; margin-bottom: 0; }
        html, body {
          height:100%; 
          margin: 0 !important; 
          padding: 0 !important;
        }      
      }    
      </style>
      
      <p style="page-break-after: always">
      ${await rptDelvAgreementWorksheetHeader(obj)}
    
    <table style="padding-top:1%;padding-left:1%;font-size:85%;">
        <tr>
            <td >
                <b> Delivery Settlement Application: </b>
            </td>            
        </tr>
    </table>

    <table style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;padding-left:1%;font-size:70%;width:100%;">
        <tr style="width=100%;">
            <td style="padding-right: 5px;"> 
              <b> Loc <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> Insp#  <br/> Net Lbs. <br/> 100% Value </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> Trade/1007#  <br/> Net Tons <br/> 100% Applied $ </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> Seed Gen  <br/> LSK <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> Var  <br/> Net-LSK <br/> 100% Adjustment $ </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> <br/> TKC LBS <br/> Appl. Lbs </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> <br/> tkc% <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> <br/> smk <br/> 100% Adj. Lbs </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> <br/> ss <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> Shipped Date <br/> ok <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> <br/> dam <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> <br/> hul <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> Received Date <br/> fm <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> <br/> lsk <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> Settled Date <br/> moi <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> <br/> fan <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> <br/> elk <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> <br/> cr <br/> <br/> </b>
            </td>
            <td style="padding-right: 5px;"> 
              <b> <br/> fd <br/> <br/> </b>
            </td>
        </tr>

        ${obj.settlementData1}
    </table>
    ${settlementData2}

    <table style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-size:75%;width:100%;">
      <tr style="border: 1px;border-color: black;border-style: none;border-collapse:collapse;">
        <td style="padding-right:5px;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;" colspan="18"> 
          ${obj.totalHeading}
        </td>
      </tr>
    </table>
    
      ${totalTable}      
      </p> </html> `
    }
    catch (err) {
        errorHandler(err);
        return '';
    }
  }

  async function rptTradeAgreementWorksheetHeader(obj){
    try{
      return `<table style="align-items: center;width: 100%;font-size:80%;">
      <tr style="padding-right: 1%;">
                  <td style="padding-left:0.01%; padding-right: 1%;">                	
                  <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                  </td>
                  <td style="padding-left:11%;text-align: center;">
                      <br/>
                      <span style="font-size:140%;color: black;">
                          <b> <u> Trade Agreement Details and Application <br/> ${obj.typeHeader} </u> </b>
                          <b> <br/> ${formatDate(new Date())} </b>
                      </span>
                  </td>
                  <td style="padding-left:9%;">
                      <span style="text-align-last: left;color:black;font-size:130%;"> <b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Golden Peanut Company, LLC </b> <br/></span>
                      <span style="text-align: right;color:black;font-size:80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; <b> 100 North Point Center East, Suite 400</b> <br/></span>
                      <span style="text-align: right;color:black;font-size:80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; <b> Alpharetta, Georgia 30022-8209 </b> <br/></span>
                  </td>
      </tr>
  </table>
  <table style="font-size: 75%;padding-top: 2%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Trade Agreement #: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.agreementNumber} </span>
          </td>
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Agreement Date: </b></span>
              <br/>
              <span style="text-align:right;font-size:90%;"> &nbsp; ${obj.agreementDate} </span>
          </td>
          <td style="width:13%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Status: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.agreementStatus} </span>
          </td>
          <td style="width:13%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Grade Pricing </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; <b> Method: </b> ${obj.gradePricingMethod} </span>
          </td>
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Pricing Type: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.pricingType} </span>
          </td>
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Agreement Lbs: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.agreementLbs} </span>
          </td>
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Agreement Loc: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.agreementLoc} &nbsp; </span>
          </td>
      </tr>

      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Peanut Type: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.peanutType} </span>
          </td>
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Variety: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.variety} </span>
          </td>
          <td style="width:13%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Generation: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.generation} </span>
          </td>
          <td style="width:13%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Seg: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.seg} </span>
          </td>
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Oleic: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.oliec} </span>
          </td>
          <td style="width:15;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Edible/Oil: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.edibleOil} </span>
          </td>            
      </tr>
  </table>

  <table style='font-size: 80%;padding-top:2%;width: 100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;'>
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:35%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;">
              <b> Vendor </b>
          </td>
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;">
              <b> Share % </b>
          </td>
          <td style="width:35%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;">
              <b> Vendor </b>
          </td>
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;">
              <b> Share % </b>
          </td>
      </tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
      ${obj.vendorData}
      </table>`
    }
    catch(err){
        errorHandler(err);
    }
  }

  async function rptDelvAgreementWorksheetHeader(obj){
    try{
      return `<table style="align-items: center;width: 100%;font-size:80%;">
      <tr style="padding-right: 1%;">
                  <td style="padding-left:0.01%; padding-right: 1%;">                	
                  <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                  </td>
                  <td style="padding-left:11%;text-align: center;">
                      <br/>
                      <span style="font-size:140%;color: black;">
                          <b> <u> Delivery Agreement Details and Application <br/> ${obj.typeHeader} </u> </b>
                          <b> <br/> ${formatDate(new Date())} </b>
                      </span>
                  </td>
                  <td style="padding-left:9%;">
                      <span style="text-align-last: left;color:black;font-size:130%;"> <b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Golden Peanut Company, LLC </b> <br/></span>
                      <span style="text-align: right;color:black;font-size:80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; <b> 100 North Point Center East, Suite 400</b> <br/></span>
                      <span style="text-align: right;color:black;font-size:80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; <b> Alpharetta, Georgia 30022-8209 </b> <br/></span>
                  </td>
      </tr>
    </table>
    <table style="font-size: 75%;padding-top: 2%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <tr style="border: 1px;border-color: black;border-style: none;border-collapse:collapse;">
            <td style="width:15%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Delivery Agreement #: </b></span>                
            </td>
            <td style="width:10%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Agreement Date: </b></span>
            </td>
            <td style="width:10%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Status: </b></span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;font-size:95%;"> 
                <span style="text-align:left"> <b> &nbsp; Grade Pricing Method: </b></span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Pricing Type: </b></span>
            </td>
            <td style="width:35%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Agreement Loc: </b></span>
            </td>            
        </tr>
        <tr style="border: 1px;border-color: black;border-style: none;border-collapse:collapse;">
            <td style="width:15%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.agreementNumber} &nbsp; </span>
            </td>
            <td style="width:10%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right;font-size:90%;"> &nbsp; ${obj.agreementDate} &nbsp; </span>
            </td>
            <td style="width:10%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.agreementStatus} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.gradePricingMethod} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.pricingType} &nbsp; </span>
            </td>
            <td style="width:35%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.agreementLoc} &nbsp; </span>
            </td>            
        </tr>
      </table>
      <table style="font-size: 75%;padding-top: 2%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <tr style="border: 1px;border-color: black;border-style: none;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Receipt Value: </b></span>                
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; 100% Applied $: </b></span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; 100% Adjustment $: </b></span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; 100% Open $: </b></span>
            </td>            
        </tr>
        <tr style="border: 1px;border-color: black;border-style: none;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.recpValue} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right;font-size:90%;"> &nbsp; ${obj.delApp} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.delUnApp} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.openPer} &nbsp; </span>
            </td>            
        </tr>
      </table>

      <table style="font-size: 75%;padding-top: 2%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <tr style="border: 1px;border-color: black;border-style: none;border-collapse:collapse;">
            <td style="width:16%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Receipt Lbs: </b></span>                
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Adjustment Lbs: </b></span>
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Total Adj. Lbs: </b></span>
            </td>
            <td style="width:16%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Applied Lbs: </b></span>                
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; 100% Adj. Lbs $: </b></span>
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Open Lbs: </b></span>
            </td>
        </tr>
        <tr style="border: 1px;border-color: black;border-style: none;border-collapse:collapse;">
            <td style="width:16%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.reciptLbs} &nbsp; </span>
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right;font-size:90%;"> &nbsp; ${obj.adjustLbs} &nbsp; </span>
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.totalAdjLbs} &nbsp; </span>
            </td>
            <td style="width:16%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.appliedLbs} &nbsp; </span>
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right;font-size:90%;"> &nbsp; ${obj.adjust_lbs} &nbsp; </span>
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.dblOpenLbs} &nbsp; </span>
            </td>
        </tr>
      </table>

      <table style="font-size: 75%;padding-top: 2%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <tr style="border: 1px;border-color: black;border-style: none;border-collapse:collapse;">
            <td style="width:16%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Peanut Type: </b></span>                
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Variety: </b></span>
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Generation: </b></span>
            </td>
            <td style="width:16%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Seg: </b></span>                
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Oleic: </b></span>
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: solid solid none solid;border-collapse:collapse;"> 
                <span style="text-align:left"> <b> &nbsp; Edible/Oil: </b></span>
            </td>
        </tr>
        <tr style="border: 1px;border-color: black;border-style: none;border-collapse:collapse;">
            <td style="width:16%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.peanutType} &nbsp; </span>
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right;font-size:90%;"> &nbsp; ${obj.variety} &nbsp; </span>
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.generation} &nbsp; </span>
            </td>
            <td style="width:16%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.seg} &nbsp; </span>
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right;font-size:90%;"> &nbsp; ${obj.oliec} &nbsp; </span>
            </td>
            <td style="width:17%;border: 1px;border-color: black;border-style: none solid solid solid;border-collapse:collapse;text-align:right;"> 
                <span style="text-align:right"> &nbsp; ${obj.edibleOil} &nbsp; </span>
            </td>
        </tr>      
  </table>

  <br/>

  <table style='font-size: 80%;padding-top:1%;width: 100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;'>
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:35%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;">
              <b> Vendor </b>
          </td>
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;">
              <b> Share % </b>
          </td>
          <td style="width:35%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;">
              <b> Vendor </b>
          </td>
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align: center;">
              <b> Share % </b>
          </td>
      </tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
      ${obj.vendorData}
      </table>`
    }
    catch(err){
        errorHandler(err);
        return '';
    }
  }

  async function rptTradeSettlementWorkSheetHeader(obj){
    return `<table style="align-items: center;width: 100%;font-size:70%;padding-top:2%;font-family:'Arial'">
      <tr style="padding-right: 1%;">
        <td style="padding-left:0.01%; padding-right: 1%;">                	
            <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
        </td>
        <td style="padding-left:7%;text-align: center;">
            <br/>
            <span style="font-size:140%;color: black;">
                <b> <u> Trade Settlement Worksheet - ${obj.sStatus} </u> <br/> ${obj.sPurch_sale_ind} </b>
                <b> <br/> ${formatDate(new Date())} </b>
            </span>
        </td>
        <td style="padding-left:6%;">
            <span style="text-align-last: left;color:black;font-size:130%;"> <b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Golden Peanut Company, LLC </b> <br/></span>
            <span style="text-align: right;color:black;font-size:80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; <b> 100 North Point Center East, Suite 400</b> <br/></span>
            <span style="text-align: right;color:black;font-size:80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; <b> Alpharetta, Georgia 30022-8209 </b> <br/></span>
        </td>
      </tr>
  </table>

  <table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left;"> <b> &nbsp; Crop Year: ${obj.cropYear} </b></span>              
          </td>
          <td style="width:40%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Grade Pricing Method: ${obj.sGPM} </b></span>              
          </td>
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Trade #: &nbsp; &nbsp; ${obj.tradeNumber} </b></span>
          </td>
      </tr>
  </table>
  <table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span> <b> Trade Agreement # </b></span>              
          </td>
          <td style="width:40%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span> <b> Pricing Type </b></span>              
          </td>
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span> <b> Applied Pounds </b></span>
          </td>
      </tr>      
      ${obj.agreementDetails}
  </table>
  <table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">      
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span> <b> ${obj.ssTradeInspNum95} </b></span>              
        </td>
        <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span> <b> Fed. BP #: &nbsp; ${obj.fedNumber} </b></span>              
        </td>
        <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span> <b> 1007 #: &nbsp; ${obj.settlementNo} </b></span>
        </td>
        <td style="width:40%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span> <b> Insp. Date & Time: &nbsp; ${obj.inspDateTime} </b></span>
        </td>
      </tr>
  </table>
  <table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:50%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span> <b> ${obj.recDel1} </b></span>              
        </td>
        <td style="width:50%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span> <b> ${obj.recDel2} </b></span>              
        </td>
      </tr>
  </table>
  <table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:70%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span> <b> Vendor </b></span>              
        </td>
        <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span> <b> Share % </b></span>              
        </td>
      </tr>
        ${obj.vendorData}
  </table>
  <table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">
    <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Seg: &nbsp; ${obj.seg} </span>              
    </td>
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Type: &nbsp; ${obj.type} </span>              
    </td>
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Variety: &nbsp; ${obj.variety} </span>              
    </td>
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Generation: &nbsp; ${obj.generation} </span>              
    </td>
  </tr>

  <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Meets Seed Spec: &nbsp; ${obj.meetSeedSpec} </span>              
    </td>
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Oleic(H-M): &nbsp; ${obj.oliec} </span>              
    </td>
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Edible: &nbsp; ${obj.edible} </span>              
    </td>
    <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
      <span> &nbsp; Oil: &nbsp; ${obj.oil} </span>              
    </td>
  </tr>
</table>

<table style="font-size: 70%;width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;font-family:'Arial'">
    <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span> <b> Weight Ticket # </b></span>              
        </td>
        <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span> <b> Date </b></span>              
        </td>
        <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span> <b> Gross Weight of Peanuts </b></span>              
        </td>
        <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span> <b> Vehicle # </b></span>              
        </td>
    </tr>  
    ${obj.scaleTicketData}
  </table>
  `
  }

  async function TradeCertificate_OneDoc(strCopyName, bFirstCall){
    try{

    }
    catch(err){
        errorHandler(err);
    }
  }

  async function TradeCertificate_Laser(strCopyName, bFirstCall){
    try{

    }
    catch(err){
        errorHandler(err);
    }
  }

  async function Print() {
    try {    
      let prefix = ''    
      let trade_number = ''

        var code = await GetHtmlCode(58, 90, '<br/>')
        setLoading(false)
        var type = 'text/html'
        var msReport = ''
        msPDF_BP = ''
        if(msReport == 'TRADE_1007')
          prefix = 'TS'
        else if(msReport == 'TRADE_SETTLEMENT_WORKSHEET')
          prefix = 'TSW'
        else if(msReport == 'TRADE_AGREE_WORKSHEET')
          prefix = 'TAW'
        else if(msReport == 'DELV_SETTLEMENT_WORKSHEET')
          prefix = 'DSW'
        else if(msReport == 'DELV_AGREE_WORKSHEET')
          prefix = 'DAW'
        else 
          prefix = 'PDF'
        
        let reportData = getData(thisObj, 'ReportPreviewTradedata')
        let layoutType = ''
        if(reportData[0].ReportType == reportType.tradeAgreeWorkSheet)
            layoutType = 'LANDSCAPE'
        else if(reportData[0].ReportType == reportType.tradeSettleWorkSheet)
            layoutType = 'PORTRAIT'
        else if(reportData[0].ReportType == reportType.delvAgreeWorkSheet)
            layoutType = 'LANDSCAPE'
        else if(reportData[0].ReportType == reportType.delvSettleWorkSheet)
            layoutType = 'PORTRAIT'
        else
            layoutType = 'LANDSCAPE'
        
        alert("Suggested layout type for the document is " + layoutType + " for better visualization.")

        if(prefix == 'TAW' || prefix == 'DAW')
          trade_number = reportData[0].Txt_Agree 
        else
          trade_number = reportData[0].Txt_Agree 
        
        var fileName = prefix + '_' + cropYearFromLS() + '_' + msPDF_BP + "_" + trade_number       
        
        if(true){
        //if(reportData.ReportType == "TRADE_AGREE_WORKSHEET"){
            var css = '@page { size: landscape; }',
                head = document.head || document.getElementsByTagName('head')[0],
                style = document.createElement('style');
            style.type = 'text/css';
            style.media = 'print';

            if (style.styleSheet)
                style.styleSheet.cssText = css;
            else
                style.appendChild(document.createTextNode(css));
            head.appendChild(style);
        }
        
        const dataUrl1 = window.URL.createObjectURL(new Blob([code], { type }));
        const pdfWindow = window.open(dataUrl1, '', 'width=1000,height=850,scrollbars=1,menuBar=1,name=' + fileName)
        pdfWindow.focus()
        pdfWindow.addEventListener('beforeprint', event => {})
        pdfWindow.print() 
        pdfWindow.addEventListener('afterprint', (event) => {
            //pdfWindow.close() //Commented this line as PDF window was getting closed before print operation
        })        
    }
    catch (err) {
        errorHandler(err);
    }
}

  const getBlobURL = (code, type) => {
    try {
      const blob = new Blob([code], { type });
      return URL.createObjectURL(blob)
    }
    catch(err) {
      errorHandler(err);
    }
  }

  const onbtnCloseClick = () => {
    try {
      setData(thisObj, 'reloadPage' , true)
      let reportData = getData(thisObj, 'ReportPreviewTradedata')
      if(reportData[0].ReportType == reportType.delvSettleWorkSheet)
      {
        let printButtonFlag = getData(thisObj, 'CallingfromPrintButton')
        if (printButtonFlag == true) {
          setData(thisObj, 'premDeductTrade_close' , true)
          document.getElementById("Settlements_ReportPreviewTradePopUp").childNodes[0].click();
        } else {
          setData(thisObj, "ClosingofDelvSettleProfile", true)
          document.getElementById("WarehouseReceipts_DelvSettleProfilePopUp").childNodes[0].click();
        }
      } else {
        setData(thisObj, 'premDeductTrade_close' , true)
        document.getElementById("Settlements_ReportPreviewTradePopUp").childNodes[0].click();
      }
      return true;
    } 
    catch(err) {
      errorHandler(err);
      return false;
    }
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const onbtnPrintClick = () => {
    try{
      setLoading(true)
      Print()
    }
    catch(err){
        errorHandler(err);
    }
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;

  function errorHandler(err){
    if(err?.message == "Cannot read properties of null (reading 'focus')")
      alert('Please allow your browser to display pop up window and then click on ok to show the print window.')
    else
      showMessage(thisObj, err.message)
  }

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            
            <ContractManagement_header headerData={state.headerData} />
            
            <h1 className="pageHeader">{state.Label}</h1>
            <Loading loading={loading}></Loading>
            <Form noValidate className="row">
            
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
            
                <iframe src={state.renderUrl} id="docFrame" width="100%" height="500px" style={{background:"White"}}></iframe>
                <ButtonWidget
                  conf={state.btnPrintSetup}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
            
                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
      
                <ButtonWidget
                  conf={state.btnSaveAsPdf}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
      
                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
      
              </GroupBoxWidget>
      
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_ReportPreviewTrade);