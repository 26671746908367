/* eslint-disable*/
import React from "react";
import WarehouseReceipts_DelvAgreeProfile from "./DelvAgreeProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DelvAgreeProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvAgreeProfile />);
    });
  });
  afterEach(cleanup);
  test("is DelvAgreeProfile Loads Successfully", () => {
    expect(screen.getByText("DelvAgreeProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for DelvAgreeProfile", () => {
    // START_USER_CODE-USER_DelvAgreeProfile_Custom_Test_Case
    // END_USER_CODE-USER_DelvAgreeProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvAgreeProfile />);
    });
  });
  afterEach(cleanup);
  test("btn0(Button Widget) Test Cases", async () => {
    const btn0 = screen.getByTestId("btn0");
    expect(btn0).toBeInTheDocument;
    expect(btn0.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn0")
    );
  });
  test("Custom Test Cases for btn0", () => {
    // START_USER_CODE-USER_btn0_TEST
    // END_USER_CODE-USER_btn0_TEST
  });
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btn10(Button Widget) Test Cases", async () => {
    const btn10 = screen.getByTestId("btn10");
    expect(btn10).toBeInTheDocument;
    expect(btn10.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn10")
    );
  });
  test("Custom Test Cases for btn10", () => {
    // START_USER_CODE-USER_btn10_TEST
    // END_USER_CODE-USER_btn10_TEST
  });
  test("btn11(Button Widget) Test Cases", async () => {
    const btn11 = screen.getByTestId("btn11");
    expect(btn11).toBeInTheDocument;
    expect(btn11.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn11")
    );
  });
  test("Custom Test Cases for btn11", () => {
    // START_USER_CODE-USER_btn11_TEST
    // END_USER_CODE-USER_btn11_TEST
  });
  test("btn12(Button Widget) Test Cases", async () => {
    const btn12 = screen.getByTestId("btn12");
    expect(btn12).toBeInTheDocument;
    expect(btn12.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn12")
    );
  });
  test("Custom Test Cases for btn12", () => {
    // START_USER_CODE-USER_btn12_TEST
    // END_USER_CODE-USER_btn12_TEST
  });
  test("btn13(Button Widget) Test Cases", async () => {
    const btn13 = screen.getByTestId("btn13");
    expect(btn13).toBeInTheDocument;
    expect(btn13.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn13")
    );
  });
  test("Custom Test Cases for btn13", () => {
    // START_USER_CODE-USER_btn13_TEST
    // END_USER_CODE-USER_btn13_TEST
  });
  test("btn14(Button Widget) Test Cases", async () => {
    const btn14 = screen.getByTestId("btn14");
    expect(btn14).toBeInTheDocument;
    expect(btn14.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn14")
    );
  });
  test("Custom Test Cases for btn14", () => {
    // START_USER_CODE-USER_btn14_TEST
    // END_USER_CODE-USER_btn14_TEST
  });
  test("btn15(Button Widget) Test Cases", async () => {
    const btn15 = screen.getByTestId("btn15");
    expect(btn15).toBeInTheDocument;
    expect(btn15.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn15")
    );
  });
  test("Custom Test Cases for btn15", () => {
    // START_USER_CODE-USER_btn15_TEST
    // END_USER_CODE-USER_btn15_TEST
  });
  test("btn16(Button Widget) Test Cases", async () => {
    const btn16 = screen.getByTestId("btn16");
    expect(btn16).toBeInTheDocument;
    expect(btn16.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn16")
    );
  });
  test("Custom Test Cases for btn16", () => {
    // START_USER_CODE-USER_btn16_TEST
    // END_USER_CODE-USER_btn16_TEST
  });
  test("btn17(Button Widget) Test Cases", async () => {
    const btn17 = screen.getByTestId("btn17");
    expect(btn17).toBeInTheDocument;
    expect(btn17.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn17")
    );
  });
  test("Custom Test Cases for btn17", () => {
    // START_USER_CODE-USER_btn17_TEST
    // END_USER_CODE-USER_btn17_TEST
  });
  test("btn18(Button Widget) Test Cases", async () => {
    const btn18 = screen.getByTestId("btn18");
    expect(btn18).toBeInTheDocument;
    expect(btn18.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn18")
    );
  });
  test("Custom Test Cases for btn18", () => {
    // START_USER_CODE-USER_btn18_TEST
    // END_USER_CODE-USER_btn18_TEST
  });
  test("btn19(Button Widget) Test Cases", async () => {
    const btn19 = screen.getByTestId("btn19");
    expect(btn19).toBeInTheDocument;
    expect(btn19.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn19")
    );
  });
  test("Custom Test Cases for btn19", () => {
    // START_USER_CODE-USER_btn19_TEST
    // END_USER_CODE-USER_btn19_TEST
  });
  test("btn2(Button Widget) Test Cases", async () => {
    const btn2 = screen.getByTestId("btn2");
    expect(btn2).toBeInTheDocument;
    expect(btn2.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn2")
    );
  });
  test("Custom Test Cases for btn2", () => {
    // START_USER_CODE-USER_btn2_TEST
    // END_USER_CODE-USER_btn2_TEST
  });
  test("btn3(Button Widget) Test Cases", async () => {
    const btn3 = screen.getByTestId("btn3");
    expect(btn3).toBeInTheDocument;
    expect(btn3.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn3")
    );
  });
  test("Custom Test Cases for btn3", () => {
    // START_USER_CODE-USER_btn3_TEST
    // END_USER_CODE-USER_btn3_TEST
  });
  test("btn4(Button Widget) Test Cases", async () => {
    const btn4 = screen.getByTestId("btn4");
    expect(btn4).toBeInTheDocument;
    expect(btn4.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn4")
    );
  });
  test("Custom Test Cases for btn4", () => {
    // START_USER_CODE-USER_btn4_TEST
    // END_USER_CODE-USER_btn4_TEST
  });
  test("btn5(Button Widget) Test Cases", async () => {
    const btn5 = screen.getByTestId("btn5");
    expect(btn5).toBeInTheDocument;
    expect(btn5.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn5")
    );
  });
  test("Custom Test Cases for btn5", () => {
    // START_USER_CODE-USER_btn5_TEST
    // END_USER_CODE-USER_btn5_TEST
  });
  test("btn6(Button Widget) Test Cases", async () => {
    const btn6 = screen.getByTestId("btn6");
    expect(btn6).toBeInTheDocument;
    expect(btn6.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn6")
    );
  });
  test("Custom Test Cases for btn6", () => {
    // START_USER_CODE-USER_btn6_TEST
    // END_USER_CODE-USER_btn6_TEST
  });
  test("btn7(Button Widget) Test Cases", async () => {
    const btn7 = screen.getByTestId("btn7");
    expect(btn7).toBeInTheDocument;
    expect(btn7.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn7")
    );
  });
  test("Custom Test Cases for btn7", () => {
    // START_USER_CODE-USER_btn7_TEST
    // END_USER_CODE-USER_btn7_TEST
  });
  test("btn8(Button Widget) Test Cases", async () => {
    const btn8 = screen.getByTestId("btn8");
    expect(btn8).toBeInTheDocument;
    expect(btn8.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn8")
    );
  });
  test("Custom Test Cases for btn8", () => {
    // START_USER_CODE-USER_btn8_TEST
    // END_USER_CODE-USER_btn8_TEST
  });
  test("btn9(Button Widget) Test Cases", async () => {
    const btn9 = screen.getByTestId("btn9");
    expect(btn9).toBeInTheDocument;
    expect(btn9.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btn9")
    );
  });
  test("Custom Test Cases for btn9", () => {
    // START_USER_CODE-USER_btn9_TEST
    // END_USER_CODE-USER_btn9_TEST
  });
  test("btnAcctDist(Button Widget) Test Cases", async () => {
    const btnAcctDist = screen.getByTestId("btnAcctDist");
    expect(btnAcctDist).toBeInTheDocument;
    expect(btnAcctDist.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btnAcctDist")
    );
  });
  test("Custom Test Cases for btnAcctDist", () => {
    // START_USER_CODE-USER_btnAcctDist_TEST
    // END_USER_CODE-USER_btnAcctDist_TEST
  });
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btncancel(Button Widget) Test Cases", async () => {
    const btncancel = screen.getByTestId("btncancel");
    expect(btncancel).toBeInTheDocument;
    expect(btncancel.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btncancel")
    );
  });
  test("Custom Test Cases for btncancel", () => {
    // START_USER_CODE-USER_btncancel_TEST
    // END_USER_CODE-USER_btncancel_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnPayments(Button Widget) Test Cases", async () => {
    const btnPayments = screen.getByTestId("btnPayments");
    expect(btnPayments).toBeInTheDocument;
    expect(btnPayments.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btnPayments")
    );
  });
  test("Custom Test Cases for btnPayments", () => {
    // START_USER_CODE-USER_btnPayments_TEST
    // END_USER_CODE-USER_btnPayments_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("gridApplicationInfo(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo = screen.getByTestId("gridApplicationInfo");
    let gridApplicationInfobtn =
      gridApplicationInfo.nextElementSibling.firstElementChild;
    gridApplicationInfo =
      gridApplicationInfo.parentElement.parentElement.parentElement;
    expect(gridApplicationInfo.tagName).toBe("DIV");
    expect(gridApplicationInfo.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridApplicationInfo", () => {
    // START_USER_CODE-USER_gridApplicationInfo_TEST
    // END_USER_CODE-USER_gridApplicationInfo_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxDelieveryInOut(GroupBox Widget) Test Cases", async () => {
    const grpbxDelieveryInOut = screen.getByTestId("grpbxDelieveryInOut");
    expect(grpbxDelieveryInOut.tagName).toBe("BUTTON");
    expect(grpbxDelieveryInOut.type).toBe("button");
    expect(grpbxDelieveryInOut.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelieveryInOut", () => {
    // START_USER_CODE-USER_grpbxDelieveryInOut_TEST
    // END_USER_CODE-USER_grpbxDelieveryInOut_TEST
  });
  test("grpbxDelvAgree(GroupBox Widget) Test Cases", async () => {
    const grpbxDelvAgree = screen.getByTestId("grpbxDelvAgree");
    expect(grpbxDelvAgree.tagName).toBe("BUTTON");
    expect(grpbxDelvAgree.type).toBe("button");
    expect(grpbxDelvAgree.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelvAgree", () => {
    // START_USER_CODE-USER_grpbxDelvAgree_TEST
    // END_USER_CODE-USER_grpbxDelvAgree_TEST
  });
  test("grpbxLabels(GroupBox Widget) Test Cases", async () => {
    const grpbxLabels = screen.getByTestId("grpbxLabels");
    expect(grpbxLabels.tagName).toBe("BUTTON");
    expect(grpbxLabels.type).toBe("button");
    expect(grpbxLabels.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLabels", () => {
    // START_USER_CODE-USER_grpbxLabels_TEST
    // END_USER_CODE-USER_grpbxLabels_TEST
  });
  test("grpbxPeanutInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanutInfo = screen.getByTestId("grpbxPeanutInfo");
    expect(grpbxPeanutInfo.tagName).toBe("BUTTON");
    expect(grpbxPeanutInfo.type).toBe("button");
    expect(grpbxPeanutInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPeanutInfo", () => {
    // START_USER_CODE-USER_grpbxPeanutInfo_TEST
    // END_USER_CODE-USER_grpbxPeanutInfo_TEST
  });
  test("grpbxPricingInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxPricingInfo = screen.getByTestId("grpbxPricingInfo");
    expect(grpbxPricingInfo.tagName).toBe("BUTTON");
    expect(grpbxPricingInfo.type).toBe("button");
    expect(grpbxPricingInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPricingInfo", () => {
    // START_USER_CODE-USER_grpbxPricingInfo_TEST
    // END_USER_CODE-USER_grpbxPricingInfo_TEST
  });
  test("grpbxVendorSplitRow0(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow0 = screen.getByTestId("grpbxVendorSplitRow0");
    expect(grpbxVendorSplitRow0.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow0.type).toBe("button");
    expect(grpbxVendorSplitRow0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow0", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow0_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow0_TEST
  });
  test("grpbxVendorSplitRow1(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow1 = screen.getByTestId("grpbxVendorSplitRow1");
    expect(grpbxVendorSplitRow1.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow1.type).toBe("button");
    expect(grpbxVendorSplitRow1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow1", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow1_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow1_TEST
  });
  test("grpbxVendorSplitRow10(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow10 = screen.getByTestId("grpbxVendorSplitRow10");
    expect(grpbxVendorSplitRow10.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow10.type).toBe("button");
    expect(grpbxVendorSplitRow10.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow10", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow10_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow10_TEST
  });
  test("grpbxVendorSplitRow11(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow11 = screen.getByTestId("grpbxVendorSplitRow11");
    expect(grpbxVendorSplitRow11.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow11.type).toBe("button");
    expect(grpbxVendorSplitRow11.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow11", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow11_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow11_TEST
  });
  test("grpbxVendorSplitRow12(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow12 = screen.getByTestId("grpbxVendorSplitRow12");
    expect(grpbxVendorSplitRow12.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow12.type).toBe("button");
    expect(grpbxVendorSplitRow12.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow12", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow12_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow12_TEST
  });
  test("grpbxVendorSplitRow13(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow13 = screen.getByTestId("grpbxVendorSplitRow13");
    expect(grpbxVendorSplitRow13.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow13.type).toBe("button");
    expect(grpbxVendorSplitRow13.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow13", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow13_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow13_TEST
  });
  test("grpbxVendorSplitRow14(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow14 = screen.getByTestId("grpbxVendorSplitRow14");
    expect(grpbxVendorSplitRow14.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow14.type).toBe("button");
    expect(grpbxVendorSplitRow14.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow14", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow14_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow14_TEST
  });
  test("grpbxVendorSplitRow15(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow15 = screen.getByTestId("grpbxVendorSplitRow15");
    expect(grpbxVendorSplitRow15.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow15.type).toBe("button");
    expect(grpbxVendorSplitRow15.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow15", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow15_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow15_TEST
  });
  test("grpbxVendorSplitRow16(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow16 = screen.getByTestId("grpbxVendorSplitRow16");
    expect(grpbxVendorSplitRow16.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow16.type).toBe("button");
    expect(grpbxVendorSplitRow16.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow16", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow16_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow16_TEST
  });
  test("grpbxVendorSplitRow17(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow17 = screen.getByTestId("grpbxVendorSplitRow17");
    expect(grpbxVendorSplitRow17.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow17.type).toBe("button");
    expect(grpbxVendorSplitRow17.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow17", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow17_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow17_TEST
  });
  test("grpbxVendorSplitRow18(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow18 = screen.getByTestId("grpbxVendorSplitRow18");
    expect(grpbxVendorSplitRow18.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow18.type).toBe("button");
    expect(grpbxVendorSplitRow18.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow18", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow18_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow18_TEST
  });
  test("grpbxVendorSplitRow19(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow19 = screen.getByTestId("grpbxVendorSplitRow19");
    expect(grpbxVendorSplitRow19.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow19.type).toBe("button");
    expect(grpbxVendorSplitRow19.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow19", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow19_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow19_TEST
  });
  test("grpbxVendorSplitRow2(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow2 = screen.getByTestId("grpbxVendorSplitRow2");
    expect(grpbxVendorSplitRow2.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow2.type).toBe("button");
    expect(grpbxVendorSplitRow2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow2", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow2_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow2_TEST
  });
  test("grpbxVendorSplitRow3(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow3 = screen.getByTestId("grpbxVendorSplitRow3");
    expect(grpbxVendorSplitRow3.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow3.type).toBe("button");
    expect(grpbxVendorSplitRow3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow3", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow3_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow3_TEST
  });
  test("grpbxVendorSplitRow4(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow4 = screen.getByTestId("grpbxVendorSplitRow4");
    expect(grpbxVendorSplitRow4.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow4.type).toBe("button");
    expect(grpbxVendorSplitRow4.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow4", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow4_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow4_TEST
  });
  test("grpbxVendorSplitRow5(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow5 = screen.getByTestId("grpbxVendorSplitRow5");
    expect(grpbxVendorSplitRow5.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow5.type).toBe("button");
    expect(grpbxVendorSplitRow5.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow5", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow5_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow5_TEST
  });
  test("grpbxVendorSplitRow6u(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow6u = screen.getByTestId("grpbxVendorSplitRow6u");
    expect(grpbxVendorSplitRow6u.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow6u.type).toBe("button");
    expect(grpbxVendorSplitRow6u.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow6u", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow6u_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow6u_TEST
  });
  test("grpbxVendorSplitRow7(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow7 = screen.getByTestId("grpbxVendorSplitRow7");
    expect(grpbxVendorSplitRow7.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow7.type).toBe("button");
    expect(grpbxVendorSplitRow7.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow7", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow7_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow7_TEST
  });
  test("grpbxVendorSplitRow8(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow8 = screen.getByTestId("grpbxVendorSplitRow8");
    expect(grpbxVendorSplitRow8.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow8.type).toBe("button");
    expect(grpbxVendorSplitRow8.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow8", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow8_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow8_TEST
  });
  test("grpbxVendorSplitRow9(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow9 = screen.getByTestId("grpbxVendorSplitRow9");
    expect(grpbxVendorSplitRow9.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow9.type).toBe("button");
    expect(grpbxVendorSplitRow9.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow9", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow9_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow9_TEST
  });
  test("grpbxVendorSplits(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplits = screen.getByTestId("grpbxVendorSplits");
    expect(grpbxVendorSplits.tagName).toBe("BUTTON");
    expect(grpbxVendorSplits.type).toBe("button");
    expect(grpbxVendorSplits.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplits", () => {
    // START_USER_CODE-USER_grpbxVendorSplits_TEST
    // END_USER_CODE-USER_grpbxVendorSplits_TEST
  });
  test("grpbxVendorSplitsRows(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitsRows = screen.getByTestId("grpbxVendorSplitsRows");
    expect(grpbxVendorSplitsRows.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitsRows.type).toBe("button");
    expect(grpbxVendorSplitsRows.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitsRows", () => {
    // START_USER_CODE-USER_grpbxVendorSplitsRows_TEST
    // END_USER_CODE-USER_grpbxVendorSplitsRows_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblApplicationInfo(Label Widget) Test Cases", async () => {
    const lblApplicationInfo = screen.getByTestId("lblApplicationInfo");
    expect(lblApplicationInfo.tagName).toBe("LABEL");
    expect(lblApplicationInfo.classList).toContain("form-label");
    expect(lblApplicationInfo.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblApplicationInfo")
    );
  });
  test("Custom Test Cases for lblApplicationInfo", () => {
    // START_USER_CODE-USER_lblApplicationInfo_TEST
    // END_USER_CODE-USER_lblApplicationInfo_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblCropYear(Label Widget) Test Cases", async () => {
    const lblCropYear = screen.getByTestId("lblCropYear");
    expect(lblCropYear.tagName).toBe("LABEL");
    expect(lblCropYear.classList).toContain("form-label");
    expect(lblCropYear.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblCropYear")
    );
  });
  test("Custom Test Cases for lblCropYear", () => {
    // START_USER_CODE-USER_lblCropYear_TEST
    // END_USER_CODE-USER_lblCropYear_TEST
  });
  test("lblPeanutInfo(Label Widget) Test Cases", async () => {
    const lblPeanutInfo = screen.getByTestId("lblPeanutInfo");
    expect(lblPeanutInfo.tagName).toBe("LABEL");
    expect(lblPeanutInfo.classList).toContain("form-label");
    expect(lblPeanutInfo.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblPeanutInfo")
    );
  });
  test("Custom Test Cases for lblPeanutInfo", () => {
    // START_USER_CODE-USER_lblPeanutInfo_TEST
    // END_USER_CODE-USER_lblPeanutInfo_TEST
  });
  test("lblPricingInfo(Label Widget) Test Cases", async () => {
    const lblPricingInfo = screen.getByTestId("lblPricingInfo");
    expect(lblPricingInfo.tagName).toBe("LABEL");
    expect(lblPricingInfo.classList).toContain("form-label");
    expect(lblPricingInfo.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblPricingInfo")
    );
  });
  test("Custom Test Cases for lblPricingInfo", () => {
    // START_USER_CODE-USER_lblPricingInfo_TEST
    // END_USER_CODE-USER_lblPricingInfo_TEST
  });
  test("lblRemitTo(Label Widget) Test Cases", async () => {
    const lblRemitTo = screen.getByTestId("lblRemitTo");
    expect(lblRemitTo.tagName).toBe("LABEL");
    expect(lblRemitTo.classList).toContain("form-label");
  });
  test("Custom Test Cases for lblRemitTo", () => {
    // START_USER_CODE-USER_lblRemitTo_TEST
    // END_USER_CODE-USER_lblRemitTo_TEST
  });
  test("lblSharePercentage(Label Widget) Test Cases", async () => {
    const lblSharePercentage = screen.getByTestId("lblSharePercentage");
    expect(lblSharePercentage.tagName).toBe("LABEL");
    expect(lblSharePercentage.classList).toContain("form-label");
    expect(lblSharePercentage.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblSharePercentage")
    );
  });
  test("Custom Test Cases for lblSharePercentage", () => {
    // START_USER_CODE-USER_lblSharePercentage_TEST
    // END_USER_CODE-USER_lblSharePercentage_TEST
  });
  test("lblVendor(Label Widget) Test Cases", async () => {
    const lblVendor = screen.getByTestId("lblVendor");
    expect(lblVendor.tagName).toBe("LABEL");
    expect(lblVendor.classList).toContain("form-label");
    expect(lblVendor.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblVendor")
    );
  });
  test("Custom Test Cases for lblVendor", () => {
    // START_USER_CODE-USER_lblVendor_TEST
    // END_USER_CODE-USER_lblVendor_TEST
  });
  test("lblVendorSplits(Label Widget) Test Cases", async () => {
    const lblVendorSplits = screen.getByTestId("lblVendorSplits");
    expect(lblVendorSplits.tagName).toBe("LABEL");
    expect(lblVendorSplits.classList).toContain("form-label");
    expect(lblVendorSplits.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblVendorSplits")
    );
  });
  test("Custom Test Cases for lblVendorSplits", () => {
    // START_USER_CODE-USER_lblVendorSplits_TEST
    // END_USER_CODE-USER_lblVendorSplits_TEST
  });
  test("lblXX0(Label Widget) Test Cases", async () => {
    const lblXX0 = screen.getByTestId("lblXX0");
    expect(lblXX0.tagName).toBe("LABEL");
    expect(lblXX0.classList).toContain("form-label");
    expect(lblXX0.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX0")
    );
  });
  test("Custom Test Cases for lblXX0", () => {
    // START_USER_CODE-USER_lblXX0_TEST
    // END_USER_CODE-USER_lblXX0_TEST
  });
  test("lblXX1(Label Widget) Test Cases", async () => {
    const lblXX1 = screen.getByTestId("lblXX1");
    expect(lblXX1.tagName).toBe("LABEL");
    expect(lblXX1.classList).toContain("form-label");
    expect(lblXX1.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX1")
    );
  });
  test("Custom Test Cases for lblXX1", () => {
    // START_USER_CODE-USER_lblXX1_TEST
    // END_USER_CODE-USER_lblXX1_TEST
  });
  test("lblXX10(Label Widget) Test Cases", async () => {
    const lblXX10 = screen.getByTestId("lblXX10");
    expect(lblXX10.tagName).toBe("LABEL");
    expect(lblXX10.classList).toContain("form-label");
    expect(lblXX10.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX10")
    );
  });
  test("Custom Test Cases for lblXX10", () => {
    // START_USER_CODE-USER_lblXX10_TEST
    // END_USER_CODE-USER_lblXX10_TEST
  });
  test("lblXX11(Label Widget) Test Cases", async () => {
    const lblXX11 = screen.getByTestId("lblXX11");
    expect(lblXX11.tagName).toBe("LABEL");
    expect(lblXX11.classList).toContain("form-label");
    expect(lblXX11.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX11")
    );
  });
  test("Custom Test Cases for lblXX11", () => {
    // START_USER_CODE-USER_lblXX11_TEST
    // END_USER_CODE-USER_lblXX11_TEST
  });
  test("lblXX12(Label Widget) Test Cases", async () => {
    const lblXX12 = screen.getByTestId("lblXX12");
    expect(lblXX12.tagName).toBe("LABEL");
    expect(lblXX12.classList).toContain("form-label");
    expect(lblXX12.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX12")
    );
  });
  test("Custom Test Cases for lblXX12", () => {
    // START_USER_CODE-USER_lblXX12_TEST
    // END_USER_CODE-USER_lblXX12_TEST
  });
  test("lblXX13(Label Widget) Test Cases", async () => {
    const lblXX13 = screen.getByTestId("lblXX13");
    expect(lblXX13.tagName).toBe("LABEL");
    expect(lblXX13.classList).toContain("form-label");
    expect(lblXX13.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX13")
    );
  });
  test("Custom Test Cases for lblXX13", () => {
    // START_USER_CODE-USER_lblXX13_TEST
    // END_USER_CODE-USER_lblXX13_TEST
  });
  test("lblXX14(Label Widget) Test Cases", async () => {
    const lblXX14 = screen.getByTestId("lblXX14");
    expect(lblXX14.tagName).toBe("LABEL");
    expect(lblXX14.classList).toContain("form-label");
    expect(lblXX14.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX14")
    );
  });
  test("Custom Test Cases for lblXX14", () => {
    // START_USER_CODE-USER_lblXX14_TEST
    // END_USER_CODE-USER_lblXX14_TEST
  });
  test("lblXX15(Label Widget) Test Cases", async () => {
    const lblXX15 = screen.getByTestId("lblXX15");
    expect(lblXX15.tagName).toBe("LABEL");
    expect(lblXX15.classList).toContain("form-label");
    expect(lblXX15.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX15")
    );
  });
  test("Custom Test Cases for lblXX15", () => {
    // START_USER_CODE-USER_lblXX15_TEST
    // END_USER_CODE-USER_lblXX15_TEST
  });
  test("lblXX16(Label Widget) Test Cases", async () => {
    const lblXX16 = screen.getByTestId("lblXX16");
    expect(lblXX16.tagName).toBe("LABEL");
    expect(lblXX16.classList).toContain("form-label");
    expect(lblXX16.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX16")
    );
  });
  test("Custom Test Cases for lblXX16", () => {
    // START_USER_CODE-USER_lblXX16_TEST
    // END_USER_CODE-USER_lblXX16_TEST
  });
  test("lblXX17(Label Widget) Test Cases", async () => {
    const lblXX17 = screen.getByTestId("lblXX17");
    expect(lblXX17.tagName).toBe("LABEL");
    expect(lblXX17.classList).toContain("form-label");
    expect(lblXX17.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX17")
    );
  });
  test("Custom Test Cases for lblXX17", () => {
    // START_USER_CODE-USER_lblXX17_TEST
    // END_USER_CODE-USER_lblXX17_TEST
  });
  test("lblXX18(Label Widget) Test Cases", async () => {
    const lblXX18 = screen.getByTestId("lblXX18");
    expect(lblXX18.tagName).toBe("LABEL");
    expect(lblXX18.classList).toContain("form-label");
    expect(lblXX18.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX18")
    );
  });
  test("Custom Test Cases for lblXX18", () => {
    // START_USER_CODE-USER_lblXX18_TEST
    // END_USER_CODE-USER_lblXX18_TEST
  });
  test("lblXX19(Label Widget) Test Cases", async () => {
    const lblXX19 = screen.getByTestId("lblXX19");
    expect(lblXX19.tagName).toBe("LABEL");
    expect(lblXX19.classList).toContain("form-label");
    expect(lblXX19.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX19")
    );
  });
  test("Custom Test Cases for lblXX19", () => {
    // START_USER_CODE-USER_lblXX19_TEST
    // END_USER_CODE-USER_lblXX19_TEST
  });
  test("lblXX2(Label Widget) Test Cases", async () => {
    const lblXX2 = screen.getByTestId("lblXX2");
    expect(lblXX2.tagName).toBe("LABEL");
    expect(lblXX2.classList).toContain("form-label");
    expect(lblXX2.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX2")
    );
  });
  test("Custom Test Cases for lblXX2", () => {
    // START_USER_CODE-USER_lblXX2_TEST
    // END_USER_CODE-USER_lblXX2_TEST
  });
  test("lblXX3(Label Widget) Test Cases", async () => {
    const lblXX3 = screen.getByTestId("lblXX3");
    expect(lblXX3.tagName).toBe("LABEL");
    expect(lblXX3.classList).toContain("form-label");
    expect(lblXX3.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX3")
    );
  });
  test("Custom Test Cases for lblXX3", () => {
    // START_USER_CODE-USER_lblXX3_TEST
    // END_USER_CODE-USER_lblXX3_TEST
  });
  test("lblXX4(Label Widget) Test Cases", async () => {
    const lblXX4 = screen.getByTestId("lblXX4");
    expect(lblXX4.tagName).toBe("LABEL");
    expect(lblXX4.classList).toContain("form-label");
    expect(lblXX4.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX4")
    );
  });
  test("Custom Test Cases for lblXX4", () => {
    // START_USER_CODE-USER_lblXX4_TEST
    // END_USER_CODE-USER_lblXX4_TEST
  });
  test("lblXX5(Label Widget) Test Cases", async () => {
    const lblXX5 = screen.getByTestId("lblXX5");
    expect(lblXX5.tagName).toBe("LABEL");
    expect(lblXX5.classList).toContain("form-label");
    expect(lblXX5.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX5")
    );
  });
  test("Custom Test Cases for lblXX5", () => {
    // START_USER_CODE-USER_lblXX5_TEST
    // END_USER_CODE-USER_lblXX5_TEST
  });
  test("lblXX6(Label Widget) Test Cases", async () => {
    const lblXX6 = screen.getByTestId("lblXX6");
    expect(lblXX6.tagName).toBe("LABEL");
    expect(lblXX6.classList).toContain("form-label");
    expect(lblXX6.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX6")
    );
  });
  test("Custom Test Cases for lblXX6", () => {
    // START_USER_CODE-USER_lblXX6_TEST
    // END_USER_CODE-USER_lblXX6_TEST
  });
  test("lblXX7(Label Widget) Test Cases", async () => {
    const lblXX7 = screen.getByTestId("lblXX7");
    expect(lblXX7.tagName).toBe("LABEL");
    expect(lblXX7.classList).toContain("form-label");
    expect(lblXX7.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX7")
    );
  });
  test("Custom Test Cases for lblXX7", () => {
    // START_USER_CODE-USER_lblXX7_TEST
    // END_USER_CODE-USER_lblXX7_TEST
  });
  test("lblXX8(Label Widget) Test Cases", async () => {
    const lblXX8 = screen.getByTestId("lblXX8");
    expect(lblXX8.tagName).toBe("LABEL");
    expect(lblXX8.classList).toContain("form-label");
    expect(lblXX8.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX8")
    );
  });
  test("Custom Test Cases for lblXX8", () => {
    // START_USER_CODE-USER_lblXX8_TEST
    // END_USER_CODE-USER_lblXX8_TEST
  });
  test("lblXX9(Label Widget) Test Cases", async () => {
    const lblXX9 = screen.getByTestId("lblXX9");
    expect(lblXX9.tagName).toBe("LABEL");
    expect(lblXX9.classList).toContain("form-label");
    expect(lblXX9.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_lblXX9")
    );
  });
  test("Custom Test Cases for lblXX9", () => {
    // START_USER_CODE-USER_lblXX9_TEST
    // END_USER_CODE-USER_lblXX9_TEST
  });
  test("textboxwidget44(Textbox Widget) Test Cases", async () => {
    const textboxwidget44 = screen.getByTestId("textboxwidget44");
    expect(textboxwidget44.tagName).toBe("INPUT");
    expect(textboxwidget44.type).toBe("text");
    expect(textboxwidget44.classList).toContain("textboxWidgetClass");
    expect(textboxwidget44.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_textboxwidget44")
    );
    await act(async () => {
      userEvent.type(textboxwidget44, "123");
    });
    expect(textboxwidget44.getAttribute("value")).toBe("");
    expect(textboxwidget44.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for textboxwidget44", () => {
    // START_USER_CODE-USER_textboxwidget44_TEST
    // END_USER_CODE-USER_textboxwidget44_TEST
  });
  test("txt100Adjust(Textbox Widget) Test Cases", async () => {
    const txt100Adjust = screen.getByTestId("txt100Adjust");
    expect(txt100Adjust.tagName).toBe("INPUT");
    expect(txt100Adjust.type).toBe("text");
    expect(txt100Adjust.classList).toContain("textboxWidgetClass");
    expect(txt100Adjust.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txt100Adjust")
    );
    await act(async () => {
      userEvent.type(txt100Adjust, "123");
    });
    expect(txt100Adjust.getAttribute("value")).toBe("");
    expect(txt100Adjust.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txt100Adjust", () => {
    // START_USER_CODE-USER_txt100Adjust_TEST
    // END_USER_CODE-USER_txt100Adjust_TEST
  });
  test("txt100AdjustLbs(Textbox Widget) Test Cases", async () => {
    const txt100AdjustLbs = screen.getByTestId("txt100AdjustLbs");
    expect(txt100AdjustLbs.tagName).toBe("INPUT");
    expect(txt100AdjustLbs.type).toBe("text");
    expect(txt100AdjustLbs.classList).toContain("textboxWidgetClass");
    expect(txt100AdjustLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txt100AdjustLbs")
    );
    await act(async () => {
      userEvent.type(txt100AdjustLbs, "123");
    });
    expect(txt100AdjustLbs.getAttribute("value")).toBe("");
    expect(txt100AdjustLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txt100AdjustLbs", () => {
    // START_USER_CODE-USER_txt100AdjustLbs_TEST
    // END_USER_CODE-USER_txt100AdjustLbs_TEST
  });
  test("txt100open(Textbox Widget) Test Cases", async () => {
    const txt100open = screen.getByTestId("txt100open");
    expect(txt100open.tagName).toBe("INPUT");
    expect(txt100open.type).toBe("text");
    expect(txt100open.classList).toContain("textboxWidgetClass");
    expect(txt100open.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txt100open")
    );
    await act(async () => {
      userEvent.type(txt100open, "123");
    });
    expect(txt100open.getAttribute("value")).toBe("");
    expect(txt100open.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txt100open", () => {
    // START_USER_CODE-USER_txt100open_TEST
    // END_USER_CODE-USER_txt100open_TEST
  });
  test("txtAdjustmentLbs(Textbox Widget) Test Cases", async () => {
    const txtAdjustmentLbs = screen.getByTestId("txtAdjustmentLbs");
    expect(txtAdjustmentLbs.tagName).toBe("INPUT");
    expect(txtAdjustmentLbs.type).toBe("text");
    expect(txtAdjustmentLbs.classList).toContain("textboxWidgetClass");
    expect(txtAdjustmentLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txtAdjustmentLbs")
    );
    await act(async () => {
      userEvent.type(txtAdjustmentLbs, "1");
    });
  });
  test("Custom Test Cases for txtAdjustmentLbs", () => {
    // START_USER_CODE-USER_txtAdjustmentLbs_TEST
    // END_USER_CODE-USER_txtAdjustmentLbs_TEST
  });
  test("txtAgreement(Textbox Widget) Test Cases", async () => {
    const txtAgreement = screen.getByTestId("txtAgreement");
    expect(txtAgreement.tagName).toBe("INPUT");
    expect(txtAgreement.type).toBe("text");
    expect(txtAgreement.classList).toContain("textboxWidgetClass");
    expect(txtAgreement.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txtAgreement")
    );
    await act(async () => {
      userEvent.type(txtAgreement, "123");
    });
    expect(txtAgreement.getAttribute("value")).toBe("");
    expect(txtAgreement.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAgreement", () => {
    // START_USER_CODE-USER_txtAgreement_TEST
    // END_USER_CODE-USER_txtAgreement_TEST
  });
  test("txtAppliedLbs(Textbox Widget) Test Cases", async () => {
    const txtAppliedLbs = screen.getByTestId("txtAppliedLbs");
    expect(txtAppliedLbs.tagName).toBe("INPUT");
    expect(txtAppliedLbs.type).toBe("text");
    expect(txtAppliedLbs.classList).toContain("textboxWidgetClass");
    expect(txtAppliedLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txtAppliedLbs")
    );
    await act(async () => {
      userEvent.type(txtAppliedLbs, "123");
    });
    expect(txtAppliedLbs.getAttribute("value")).toBe("");
    expect(txtAppliedLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAppliedLbs", () => {
    // START_USER_CODE-USER_txtAppliedLbs_TEST
    // END_USER_CODE-USER_txtAppliedLbs_TEST
  });
  test("gridApplicationInfo_txtcol1(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcol1 = screen.getByTestId("gridApplicationInfo");
    let gridApplicationInfo_txtcol1btn =
      gridApplicationInfo_txtcol1.nextElementSibling.firstElementChild;
    gridApplicationInfo_txtcol1 =
      gridApplicationInfo_txtcol1.parentElement.parentElement.parentElement;
    expect(gridApplicationInfo_txtcol1.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcol1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol1", () => {
    // START_USER_CODE-USER_txtcol1_TEST
    // END_USER_CODE-USER_txtcol1_TEST
  });
  test("gridApplicationInfo_txtcol100Adjustment(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcol100Adjustment = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcol100Adjustmentbtn =
      gridApplicationInfo_txtcol100Adjustment.nextElementSibling
        .firstElementChild;
    gridApplicationInfo_txtcol100Adjustment =
      gridApplicationInfo_txtcol100Adjustment.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcol100Adjustment.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcol100Adjustment.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol100Adjustment", () => {
    // START_USER_CODE-USER_txtcol100Adjustment_TEST
    // END_USER_CODE-USER_txtcol100Adjustment_TEST
  });
  test("gridApplicationInfo_txtcol100Applied(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcol100Applied = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcol100Appliedbtn =
      gridApplicationInfo_txtcol100Applied.nextElementSibling.firstElementChild;
    gridApplicationInfo_txtcol100Applied =
      gridApplicationInfo_txtcol100Applied.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcol100Applied.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcol100Applied.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol100Applied", () => {
    // START_USER_CODE-USER_txtcol100Applied_TEST
    // END_USER_CODE-USER_txtcol100Applied_TEST
  });
  test("gridApplicationInfo_txtcolAppliedLbs(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolAppliedLbs = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolAppliedLbsbtn =
      gridApplicationInfo_txtcolAppliedLbs.nextElementSibling.firstElementChild;
    gridApplicationInfo_txtcolAppliedLbs =
      gridApplicationInfo_txtcolAppliedLbs.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcolAppliedLbs.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolAppliedLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAppliedLbs", () => {
    // START_USER_CODE-USER_txtcolAppliedLbs_TEST
    // END_USER_CODE-USER_txtcolAppliedLbs_TEST
  });
  test("gridApplicationInfo_txtcolConvApplied(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolConvApplied = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolConvAppliedbtn =
      gridApplicationInfo_txtcolConvApplied.nextElementSibling
        .firstElementChild;
    gridApplicationInfo_txtcolConvApplied =
      gridApplicationInfo_txtcolConvApplied.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcolConvApplied.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolConvApplied.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolConvApplied", () => {
    // START_USER_CODE-USER_txtcolConvApplied_TEST
    // END_USER_CODE-USER_txtcolConvApplied_TEST
  });
  test("gridApplicationInfo_txtcolDelvInsp(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolDelvInsp = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolDelvInspbtn =
      gridApplicationInfo_txtcolDelvInsp.nextElementSibling.firstElementChild;
    gridApplicationInfo_txtcolDelvInsp =
      gridApplicationInfo_txtcolDelvInsp.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcolDelvInsp.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolDelvInsp.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDelvInsp", () => {
    // START_USER_CODE-USER_txtcolDelvInsp_TEST
    // END_USER_CODE-USER_txtcolDelvInsp_TEST
  });
  test("gridApplicationInfo_txtcolLocation(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolLocation = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolLocationbtn =
      gridApplicationInfo_txtcolLocation.nextElementSibling.firstElementChild;
    gridApplicationInfo_txtcolLocation =
      gridApplicationInfo_txtcolLocation.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcolLocation.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolLocation.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLocation", () => {
    // START_USER_CODE-USER_txtcolLocation_TEST
    // END_USER_CODE-USER_txtcolLocation_TEST
  });
  test("gridApplicationInfo_txtcolReceivedDate(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolReceivedDate = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolReceivedDatebtn =
      gridApplicationInfo_txtcolReceivedDate.nextElementSibling
        .firstElementChild;
    gridApplicationInfo_txtcolReceivedDate =
      gridApplicationInfo_txtcolReceivedDate.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcolReceivedDate.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolReceivedDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolReceivedDate", () => {
    // START_USER_CODE-USER_txtcolReceivedDate_TEST
    // END_USER_CODE-USER_txtcolReceivedDate_TEST
  });
  test("gridApplicationInfo_txtcolShippedDate(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolShippedDate = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolShippedDatebtn =
      gridApplicationInfo_txtcolShippedDate.nextElementSibling
        .firstElementChild;
    gridApplicationInfo_txtcolShippedDate =
      gridApplicationInfo_txtcolShippedDate.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcolShippedDate.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolShippedDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShippedDate", () => {
    // START_USER_CODE-USER_txtcolShippedDate_TEST
    // END_USER_CODE-USER_txtcolShippedDate_TEST
  });
  test("txtConvApplied(Textbox Widget) Test Cases", async () => {
    const txtConvApplied = screen.getByTestId("txtConvApplied");
    expect(txtConvApplied.tagName).toBe("INPUT");
    expect(txtConvApplied.type).toBe("text");
    expect(txtConvApplied.classList).toContain("textboxWidgetClass");
    expect(txtConvApplied.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txtConvApplied")
    );
    await act(async () => {
      userEvent.type(txtConvApplied, "123");
    });
    expect(txtConvApplied.getAttribute("value")).toBe("");
    expect(txtConvApplied.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtConvApplied", () => {
    // START_USER_CODE-USER_txtConvApplied_TEST
    // END_USER_CODE-USER_txtConvApplied_TEST
  });
  test("txtConversion(Textbox Widget) Test Cases", async () => {
    const txtConversion = screen.getByTestId("txtConversion");
    expect(txtConversion.tagName).toBe("INPUT");
    expect(txtConversion.type).toBe("text");
    expect(txtConversion.classList).toContain("textboxWidgetClass");
    expect(txtConversion.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txtConversion")
    );
    await act(async () => {
      userEvent.type(txtConversion, "123");
    });
    expect(txtConversion.getAttribute("value")).toBe("");
    expect(txtConversion.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtConversion", () => {
    // START_USER_CODE-USER_txtConversion_TEST
    // END_USER_CODE-USER_txtConversion_TEST
  });
  test("txtConvopen(Textbox Widget) Test Cases", async () => {
    const txtConvopen = screen.getByTestId("txtConvopen");
    expect(txtConvopen.tagName).toBe("INPUT");
    expect(txtConvopen.type).toBe("text");
    expect(txtConvopen.classList).toContain("textboxWidgetClass");
    expect(txtConvopen.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txtConvopen")
    );
    await act(async () => {
      userEvent.type(txtConvopen, "123");
    });
    expect(txtConvopen.getAttribute("value")).toBe("");
    expect(txtConvopen.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtConvopen", () => {
    // START_USER_CODE-USER_txtConvopen_TEST
    // END_USER_CODE-USER_txtConvopen_TEST
  });
  test("txtOpenLbs(Textbox Widget) Test Cases", async () => {
    const txtOpenLbs = screen.getByTestId("txtOpenLbs");
    expect(txtOpenLbs.tagName).toBe("INPUT");
    expect(txtOpenLbs.type).toBe("text");
    expect(txtOpenLbs.classList).toContain("textboxWidgetClass");
    expect(txtOpenLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txtOpenLbs")
    );
    await act(async () => {
      userEvent.type(txtOpenLbs, "123");
    });
    expect(txtOpenLbs.getAttribute("value")).toBe("");
    expect(txtOpenLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOpenLbs", () => {
    // START_USER_CODE-USER_txtOpenLbs_TEST
    // END_USER_CODE-USER_txtOpenLbs_TEST
  });
  test("txtpaidDollars(Textbox Widget) Test Cases", async () => {
    const txtpaidDollars = screen.getByTestId("txtpaidDollars");
    expect(txtpaidDollars.tagName).toBe("INPUT");
    expect(txtpaidDollars.type).toBe("text");
    expect(txtpaidDollars.classList).toContain("textboxWidgetClass");
    expect(txtpaidDollars.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txtpaidDollars")
    );
    await act(async () => {
      userEvent.type(txtpaidDollars, "1");
    });
  });
  test("Custom Test Cases for txtpaidDollars", () => {
    // START_USER_CODE-USER_txtpaidDollars_TEST
    // END_USER_CODE-USER_txtpaidDollars_TEST
  });
  test("txtPricePerTon(Textbox Widget) Test Cases", async () => {
    const txtPricePerTon = screen.getByTestId("txtPricePerTon");
    expect(txtPricePerTon.tagName).toBe("INPUT");
    expect(txtPricePerTon.type).toBe("text");
    expect(txtPricePerTon.classList).toContain("textboxWidgetClass");
    expect(txtPricePerTon.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txtPricePerTon")
    );
    await act(async () => {
      userEvent.type(txtPricePerTon, "1");
    });
  });
  test("Custom Test Cases for txtPricePerTon", () => {
    // START_USER_CODE-USER_txtPricePerTon_TEST
    // END_USER_CODE-USER_txtPricePerTon_TEST
  });
  test("txtReceiptLbs(Textbox Widget) Test Cases", async () => {
    const txtReceiptLbs = screen.getByTestId("txtReceiptLbs");
    expect(txtReceiptLbs.tagName).toBe("INPUT");
    expect(txtReceiptLbs.type).toBe("text");
    expect(txtReceiptLbs.classList).toContain("textboxWidgetClass");
    expect(txtReceiptLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txtReceiptLbs")
    );
    await act(async () => {
      userEvent.type(txtReceiptLbs, "123");
    });
    expect(txtReceiptLbs.getAttribute("value")).toBe("");
    expect(txtReceiptLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtReceiptLbs", () => {
    // START_USER_CODE-USER_txtReceiptLbs_TEST
    // END_USER_CODE-USER_txtReceiptLbs_TEST
  });
  test("txtReceiptValue(Textbox Widget) Test Cases", async () => {
    const txtReceiptValue = screen.getByTestId("txtReceiptValue");
    expect(txtReceiptValue.tagName).toBe("INPUT");
    expect(txtReceiptValue.type).toBe("text");
    expect(txtReceiptValue.classList).toContain("textboxWidgetClass");
    expect(txtReceiptValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txtReceiptValue")
    );
    await act(async () => {
      userEvent.type(txtReceiptValue, "1");
    });
  });
  test("Custom Test Cases for txtReceiptValue", () => {
    // START_USER_CODE-USER_txtReceiptValue_TEST
    // END_USER_CODE-USER_txtReceiptValue_TEST
  });
  test("txtSharePercentage0(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage0 = screen.getByTestId("txtSharePercentage0");
    expect(txtSharePercentage0.tagName).toBe("INPUT");
    expect(txtSharePercentage0.type).toBe("text");
    expect(txtSharePercentage0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage0, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage0", () => {
    // START_USER_CODE-USER_txtSharePercentage0_TEST
    // END_USER_CODE-USER_txtSharePercentage0_TEST
  });
  test("txtSharePercentage1(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage1 = screen.getByTestId("txtSharePercentage1");
    expect(txtSharePercentage1.tagName).toBe("INPUT");
    expect(txtSharePercentage1.type).toBe("text");
    expect(txtSharePercentage1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage1, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage1", () => {
    // START_USER_CODE-USER_txtSharePercentage1_TEST
    // END_USER_CODE-USER_txtSharePercentage1_TEST
  });
  test("txtSharePercentage10(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage10 = screen.getByTestId("txtSharePercentage10");
    expect(txtSharePercentage10.tagName).toBe("INPUT");
    expect(txtSharePercentage10.type).toBe("text");
    expect(txtSharePercentage10.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage10, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage10", () => {
    // START_USER_CODE-USER_txtSharePercentage10_TEST
    // END_USER_CODE-USER_txtSharePercentage10_TEST
  });
  test("txtSharePercentage11(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage11 = screen.getByTestId("txtSharePercentage11");
    expect(txtSharePercentage11.tagName).toBe("INPUT");
    expect(txtSharePercentage11.type).toBe("text");
    expect(txtSharePercentage11.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage11, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage11", () => {
    // START_USER_CODE-USER_txtSharePercentage11_TEST
    // END_USER_CODE-USER_txtSharePercentage11_TEST
  });
  test("txtSharePercentage12(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage12 = screen.getByTestId("txtSharePercentage12");
    expect(txtSharePercentage12.tagName).toBe("INPUT");
    expect(txtSharePercentage12.type).toBe("text");
    expect(txtSharePercentage12.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage12, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage12", () => {
    // START_USER_CODE-USER_txtSharePercentage12_TEST
    // END_USER_CODE-USER_txtSharePercentage12_TEST
  });
  test("txtSharePercentage13(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage13 = screen.getByTestId("txtSharePercentage13");
    expect(txtSharePercentage13.tagName).toBe("INPUT");
    expect(txtSharePercentage13.type).toBe("text");
    expect(txtSharePercentage13.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage13, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage13", () => {
    // START_USER_CODE-USER_txtSharePercentage13_TEST
    // END_USER_CODE-USER_txtSharePercentage13_TEST
  });
  test("txtSharePercentage14(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage14 = screen.getByTestId("txtSharePercentage14");
    expect(txtSharePercentage14.tagName).toBe("INPUT");
    expect(txtSharePercentage14.type).toBe("text");
    expect(txtSharePercentage14.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage14, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage14", () => {
    // START_USER_CODE-USER_txtSharePercentage14_TEST
    // END_USER_CODE-USER_txtSharePercentage14_TEST
  });
  test("txtSharePercentage15(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage15 = screen.getByTestId("txtSharePercentage15");
    expect(txtSharePercentage15.tagName).toBe("INPUT");
    expect(txtSharePercentage15.type).toBe("text");
    expect(txtSharePercentage15.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage15, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage15", () => {
    // START_USER_CODE-USER_txtSharePercentage15_TEST
    // END_USER_CODE-USER_txtSharePercentage15_TEST
  });
  test("txtSharePercentage16(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage16 = screen.getByTestId("txtSharePercentage16");
    expect(txtSharePercentage16.tagName).toBe("INPUT");
    expect(txtSharePercentage16.type).toBe("text");
    expect(txtSharePercentage16.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage16, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage16", () => {
    // START_USER_CODE-USER_txtSharePercentage16_TEST
    // END_USER_CODE-USER_txtSharePercentage16_TEST
  });
  test("txtSharePercentage17(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage17 = screen.getByTestId("txtSharePercentage17");
    expect(txtSharePercentage17.tagName).toBe("INPUT");
    expect(txtSharePercentage17.type).toBe("text");
    expect(txtSharePercentage17.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage17, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage17", () => {
    // START_USER_CODE-USER_txtSharePercentage17_TEST
    // END_USER_CODE-USER_txtSharePercentage17_TEST
  });
  test("txtSharePercentage18(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage18 = screen.getByTestId("txtSharePercentage18");
    expect(txtSharePercentage18.tagName).toBe("INPUT");
    expect(txtSharePercentage18.type).toBe("text");
    expect(txtSharePercentage18.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage18, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage18", () => {
    // START_USER_CODE-USER_txtSharePercentage18_TEST
    // END_USER_CODE-USER_txtSharePercentage18_TEST
  });
  test("txtSharePercentage19(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage19 = screen.getByTestId("txtSharePercentage19");
    expect(txtSharePercentage19.tagName).toBe("INPUT");
    expect(txtSharePercentage19.type).toBe("text");
    expect(txtSharePercentage19.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage19, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage19", () => {
    // START_USER_CODE-USER_txtSharePercentage19_TEST
    // END_USER_CODE-USER_txtSharePercentage19_TEST
  });
  test("txtSharePercentage2(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage2 = screen.getByTestId("txtSharePercentage2");
    expect(txtSharePercentage2.tagName).toBe("INPUT");
    expect(txtSharePercentage2.type).toBe("text");
    expect(txtSharePercentage2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage2, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage2", () => {
    // START_USER_CODE-USER_txtSharePercentage2_TEST
    // END_USER_CODE-USER_txtSharePercentage2_TEST
  });
  test("txtSharePercentage3(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage3 = screen.getByTestId("txtSharePercentage3");
    expect(txtSharePercentage3.tagName).toBe("INPUT");
    expect(txtSharePercentage3.type).toBe("text");
    expect(txtSharePercentage3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage3, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage3", () => {
    // START_USER_CODE-USER_txtSharePercentage3_TEST
    // END_USER_CODE-USER_txtSharePercentage3_TEST
  });
  test("txtSharePercentage4(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage4 = screen.getByTestId("txtSharePercentage4");
    expect(txtSharePercentage4.tagName).toBe("INPUT");
    expect(txtSharePercentage4.type).toBe("text");
    expect(txtSharePercentage4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage4, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage4", () => {
    // START_USER_CODE-USER_txtSharePercentage4_TEST
    // END_USER_CODE-USER_txtSharePercentage4_TEST
  });
  test("txtSharePercentage5(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage5 = screen.getByTestId("txtSharePercentage5");
    expect(txtSharePercentage5.tagName).toBe("INPUT");
    expect(txtSharePercentage5.type).toBe("text");
    expect(txtSharePercentage5.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage5, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage5", () => {
    // START_USER_CODE-USER_txtSharePercentage5_TEST
    // END_USER_CODE-USER_txtSharePercentage5_TEST
  });
  test("txtSharePercentage6(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage6 = screen.getByTestId("txtSharePercentage6");
    expect(txtSharePercentage6.tagName).toBe("INPUT");
    expect(txtSharePercentage6.type).toBe("text");
    expect(txtSharePercentage6.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage6, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage6", () => {
    // START_USER_CODE-USER_txtSharePercentage6_TEST
    // END_USER_CODE-USER_txtSharePercentage6_TEST
  });
  test("txtSharePercentage7(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage7 = screen.getByTestId("txtSharePercentage7");
    expect(txtSharePercentage7.tagName).toBe("INPUT");
    expect(txtSharePercentage7.type).toBe("text");
    expect(txtSharePercentage7.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage7, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage7", () => {
    // START_USER_CODE-USER_txtSharePercentage7_TEST
    // END_USER_CODE-USER_txtSharePercentage7_TEST
  });
  test("txtSharePercentage8(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage8 = screen.getByTestId("txtSharePercentage8");
    expect(txtSharePercentage8.tagName).toBe("INPUT");
    expect(txtSharePercentage8.type).toBe("text");
    expect(txtSharePercentage8.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage8, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage8", () => {
    // START_USER_CODE-USER_txtSharePercentage8_TEST
    // END_USER_CODE-USER_txtSharePercentage8_TEST
  });
  test("txtSharePercentage9(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage9 = screen.getByTestId("txtSharePercentage9");
    expect(txtSharePercentage9.tagName).toBe("INPUT");
    expect(txtSharePercentage9.type).toBe("text");
    expect(txtSharePercentage9.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage9, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage9", () => {
    // START_USER_CODE-USER_txtSharePercentage9_TEST
    // END_USER_CODE-USER_txtSharePercentage9_TEST
  });
  test("txtTotalAdjustLbs(Textbox Widget) Test Cases", async () => {
    const txtTotalAdjustLbs = screen.getByTestId("txtTotalAdjustLbs");
    expect(txtTotalAdjustLbs.tagName).toBe("INPUT");
    expect(txtTotalAdjustLbs.type).toBe("text");
    expect(txtTotalAdjustLbs.classList).toContain("textboxWidgetClass");
    expect(txtTotalAdjustLbs.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvAgreeProfile_txtTotalAdjustLbs")
    );
    await act(async () => {
      userEvent.type(txtTotalAdjustLbs, "123");
    });
    expect(txtTotalAdjustLbs.getAttribute("value")).toBe("");
    expect(txtTotalAdjustLbs.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalAdjustLbs", () => {
    // START_USER_CODE-USER_txtTotalAdjustLbs_TEST
    // END_USER_CODE-USER_txtTotalAdjustLbs_TEST
  });
  test("txtVendor0(Textbox Widget) Test Cases", async () => {
    const txtVendor0 = screen.getByTestId("txtVendor0");
    expect(txtVendor0.tagName).toBe("INPUT");
    expect(txtVendor0.type).toBe("text");
    expect(txtVendor0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor0, "1");
    });
  });
  test("Custom Test Cases for txtVendor0", () => {
    // START_USER_CODE-USER_txtVendor0_TEST
    // END_USER_CODE-USER_txtVendor0_TEST
  });
  test("txtVendor1(Textbox Widget) Test Cases", async () => {
    const txtVendor1 = screen.getByTestId("txtVendor1");
    expect(txtVendor1.tagName).toBe("INPUT");
    expect(txtVendor1.type).toBe("text");
    expect(txtVendor1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor1, "1");
    });
  });
  test("Custom Test Cases for txtVendor1", () => {
    // START_USER_CODE-USER_txtVendor1_TEST
    // END_USER_CODE-USER_txtVendor1_TEST
  });
  test("txtVendor10(Textbox Widget) Test Cases", async () => {
    const txtVendor10 = screen.getByTestId("txtVendor10");
    expect(txtVendor10.tagName).toBe("INPUT");
    expect(txtVendor10.type).toBe("text");
    expect(txtVendor10.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor10, "1");
    });
  });
  test("Custom Test Cases for txtVendor10", () => {
    // START_USER_CODE-USER_txtVendor10_TEST
    // END_USER_CODE-USER_txtVendor10_TEST
  });
  test("txtVendor11(Textbox Widget) Test Cases", async () => {
    const txtVendor11 = screen.getByTestId("txtVendor11");
    expect(txtVendor11.tagName).toBe("INPUT");
    expect(txtVendor11.type).toBe("text");
    expect(txtVendor11.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor11, "1");
    });
  });
  test("Custom Test Cases for txtVendor11", () => {
    // START_USER_CODE-USER_txtVendor11_TEST
    // END_USER_CODE-USER_txtVendor11_TEST
  });
  test("txtVendor12(Textbox Widget) Test Cases", async () => {
    const txtVendor12 = screen.getByTestId("txtVendor12");
    expect(txtVendor12.tagName).toBe("INPUT");
    expect(txtVendor12.type).toBe("text");
    expect(txtVendor12.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor12, "1");
    });
  });
  test("Custom Test Cases for txtVendor12", () => {
    // START_USER_CODE-USER_txtVendor12_TEST
    // END_USER_CODE-USER_txtVendor12_TEST
  });
  test("txtVendor13(Textbox Widget) Test Cases", async () => {
    const txtVendor13 = screen.getByTestId("txtVendor13");
    expect(txtVendor13.tagName).toBe("INPUT");
    expect(txtVendor13.type).toBe("text");
    expect(txtVendor13.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor13, "1");
    });
  });
  test("Custom Test Cases for txtVendor13", () => {
    // START_USER_CODE-USER_txtVendor13_TEST
    // END_USER_CODE-USER_txtVendor13_TEST
  });
  test("txtVendor14(Textbox Widget) Test Cases", async () => {
    const txtVendor14 = screen.getByTestId("txtVendor14");
    expect(txtVendor14.tagName).toBe("INPUT");
    expect(txtVendor14.type).toBe("text");
    expect(txtVendor14.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor14, "1");
    });
  });
  test("Custom Test Cases for txtVendor14", () => {
    // START_USER_CODE-USER_txtVendor14_TEST
    // END_USER_CODE-USER_txtVendor14_TEST
  });
  test("txtVendor15(Textbox Widget) Test Cases", async () => {
    const txtVendor15 = screen.getByTestId("txtVendor15");
    expect(txtVendor15.tagName).toBe("INPUT");
    expect(txtVendor15.type).toBe("text");
    expect(txtVendor15.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor15, "1");
    });
  });
  test("Custom Test Cases for txtVendor15", () => {
    // START_USER_CODE-USER_txtVendor15_TEST
    // END_USER_CODE-USER_txtVendor15_TEST
  });
  test("txtVendor16(Textbox Widget) Test Cases", async () => {
    const txtVendor16 = screen.getByTestId("txtVendor16");
    expect(txtVendor16.tagName).toBe("INPUT");
    expect(txtVendor16.type).toBe("text");
    expect(txtVendor16.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor16, "1");
    });
  });
  test("Custom Test Cases for txtVendor16", () => {
    // START_USER_CODE-USER_txtVendor16_TEST
    // END_USER_CODE-USER_txtVendor16_TEST
  });
  test("txtVendor17(Textbox Widget) Test Cases", async () => {
    const txtVendor17 = screen.getByTestId("txtVendor17");
    expect(txtVendor17.tagName).toBe("INPUT");
    expect(txtVendor17.type).toBe("text");
    expect(txtVendor17.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor17, "1");
    });
  });
  test("Custom Test Cases for txtVendor17", () => {
    // START_USER_CODE-USER_txtVendor17_TEST
    // END_USER_CODE-USER_txtVendor17_TEST
  });
  test("txtVendor18(Textbox Widget) Test Cases", async () => {
    const txtVendor18 = screen.getByTestId("txtVendor18");
    expect(txtVendor18.tagName).toBe("INPUT");
    expect(txtVendor18.type).toBe("text");
    expect(txtVendor18.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor18, "1");
    });
  });
  test("Custom Test Cases for txtVendor18", () => {
    // START_USER_CODE-USER_txtVendor18_TEST
    // END_USER_CODE-USER_txtVendor18_TEST
  });
  test("txtVendor19(Textbox Widget) Test Cases", async () => {
    const txtVendor19 = screen.getByTestId("txtVendor19");
    expect(txtVendor19.tagName).toBe("INPUT");
    expect(txtVendor19.type).toBe("text");
    expect(txtVendor19.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor19, "1");
    });
  });
  test("Custom Test Cases for txtVendor19", () => {
    // START_USER_CODE-USER_txtVendor19_TEST
    // END_USER_CODE-USER_txtVendor19_TEST
  });
  test("txtVendor2(Textbox Widget) Test Cases", async () => {
    const txtVendor2 = screen.getByTestId("txtVendor2");
    expect(txtVendor2.tagName).toBe("INPUT");
    expect(txtVendor2.type).toBe("text");
    expect(txtVendor2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor2, "1");
    });
  });
  test("Custom Test Cases for txtVendor2", () => {
    // START_USER_CODE-USER_txtVendor2_TEST
    // END_USER_CODE-USER_txtVendor2_TEST
  });
  test("txtVendor3(Textbox Widget) Test Cases", async () => {
    const txtVendor3 = screen.getByTestId("txtVendor3");
    expect(txtVendor3.tagName).toBe("INPUT");
    expect(txtVendor3.type).toBe("text");
    expect(txtVendor3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor3, "1");
    });
  });
  test("Custom Test Cases for txtVendor3", () => {
    // START_USER_CODE-USER_txtVendor3_TEST
    // END_USER_CODE-USER_txtVendor3_TEST
  });
  test("txtVendor4(Textbox Widget) Test Cases", async () => {
    const txtVendor4 = screen.getByTestId("txtVendor4");
    expect(txtVendor4.tagName).toBe("INPUT");
    expect(txtVendor4.type).toBe("text");
    expect(txtVendor4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor4, "1");
    });
  });
  test("Custom Test Cases for txtVendor4", () => {
    // START_USER_CODE-USER_txtVendor4_TEST
    // END_USER_CODE-USER_txtVendor4_TEST
  });
  test("txtVendor5(Textbox Widget) Test Cases", async () => {
    const txtVendor5 = screen.getByTestId("txtVendor5");
    expect(txtVendor5.tagName).toBe("INPUT");
    expect(txtVendor5.type).toBe("text");
    expect(txtVendor5.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor5, "1");
    });
  });
  test("Custom Test Cases for txtVendor5", () => {
    // START_USER_CODE-USER_txtVendor5_TEST
    // END_USER_CODE-USER_txtVendor5_TEST
  });
  test("txtVendor6(Textbox Widget) Test Cases", async () => {
    const txtVendor6 = screen.getByTestId("txtVendor6");
    expect(txtVendor6.tagName).toBe("INPUT");
    expect(txtVendor6.type).toBe("text");
    expect(txtVendor6.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor6, "1");
    });
  });
  test("Custom Test Cases for txtVendor6", () => {
    // START_USER_CODE-USER_txtVendor6_TEST
    // END_USER_CODE-USER_txtVendor6_TEST
  });
  test("txtVendor7(Textbox Widget) Test Cases", async () => {
    const txtVendor7 = screen.getByTestId("txtVendor7");
    expect(txtVendor7.tagName).toBe("INPUT");
    expect(txtVendor7.type).toBe("text");
    expect(txtVendor7.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor7, "1");
    });
  });
  test("Custom Test Cases for txtVendor7", () => {
    // START_USER_CODE-USER_txtVendor7_TEST
    // END_USER_CODE-USER_txtVendor7_TEST
  });
  test("txtVendor8(Textbox Widget) Test Cases", async () => {
    const txtVendor8 = screen.getByTestId("txtVendor8");
    expect(txtVendor8.tagName).toBe("INPUT");
    expect(txtVendor8.type).toBe("text");
    expect(txtVendor8.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor8, "1");
    });
  });
  test("Custom Test Cases for txtVendor8", () => {
    // START_USER_CODE-USER_txtVendor8_TEST
    // END_USER_CODE-USER_txtVendor8_TEST
  });
  test("txtVendor9(Textbox Widget) Test Cases", async () => {
    const txtVendor9 = screen.getByTestId("txtVendor9");
    expect(txtVendor9.tagName).toBe("INPUT");
    expect(txtVendor9.type).toBe("text");
    expect(txtVendor9.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor9, "1");
    });
  });
  test("Custom Test Cases for txtVendor9", () => {
    // START_USER_CODE-USER_txtVendor9_TEST
    // END_USER_CODE-USER_txtVendor9_TEST
  });
});
