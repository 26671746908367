/* eslint-disable*/
import React from "react";
import TextAreaWidget from "./TextAreaWidget";
import { screen } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("TextAreaWidget Test Cases-1", () => {
  beforeEach(async () => {
    act(() => {
      let textareawidget1 = {
        name: "textareawidget1",
        type: "TextAreaWidget",
        parent: "groupboxwidget0",
        Label: "textareawidget1",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = { textareawidget1: "Testing is done" };
      let errors = jest.fn();
      let touched = jest.fn();
      renderTestScreen(
        <TextAreaWidget
          conf={textareawidget1}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("is TextAreaWidget1 Loads Successfully", () => {
    expect(screen.getByText("Testing is done")).toBeInTheDocument;
  });
  test("is TextAreaWidget2 Loads Successfully", async () => {
    const textareawidget1 = screen.getByTestId("textareawidget1");
    expect(textareawidget1.tagName).toBe("TEXTAREA");
    expect(textareawidget1.classList).toContain("textboxWidgetClass");
  });
});
describe("TextAreaWidget Test Cases-2", () => {
  beforeEach(async () => {
    act(() => {
      let textareawidget1 = {
        name: "textareawidget1",
        type: "TextAreaWidget",
        parent: "groupboxwidget0",
        Label: "textareawidget1",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true
      };
      let values = { textareawidget1: "Testing is done" };
      let errors = jest.fn();
      let touched = jest.fn();
      renderTestScreen(
        <TextAreaWidget
          conf={textareawidget1}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("is TextAreaWidget1 Loads Successfully", () => {
    expect(screen.getByText("Testing is done")).toBeInTheDocument;
  });
  test("is TextAreaWidget2 Loads Successfully", async () => {
    const textareawidget1 = screen.getByTestId("textareawidget1");
    expect(textareawidget1.tagName).toBe("TEXTAREA");
    expect(textareawidget1.classList).toContain("textboxWidgetClass");
  });
});
