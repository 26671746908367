/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  setValue,
  getValue,
  getData,
  setData
} from "../../shared/WindowImports";

import "./EWRSendUpdate.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_EWRSendUpdate(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "EWRSendUpdate",
    windowName: "EWRSendUpdate",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.EWRSendUpdate",
    // START_USER_CODE-USER_EWRSendUpdate_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "EWR Send Update ",
      scrCode: "PN1090N",
    },
    // END_USER_CODE-USER_EWRSendUpdate_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnSend: {
      name: "btnSend",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Send",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSend_PROPERTIES

      // END_USER_CODE-USER_btnSend_PROPERTIES
    },
    lblFarmName: {
      name: "lblFarmName",
      type: "LabelWidget",
      parent: "grpbxEWRSendUpdate",
      Label: "lblFarmName",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFarmName_PROPERTIES

      // END_USER_CODE-USER_lblFarmName_PROPERTIES
    },
    txtActionCode: {
      name: "txtActionCode",
      type: "TextBoxWidget",
      parent: "grpbxEWRSendUpdate",
      Label: "Action Code:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      // START_USER_CODE-USER_txtActionCode_PROPERTIES

      // END_USER_CODE-USER_txtActionCode_PROPERTIES
    },
    txtCropYear: {
      name: "txtCropYear",
      type: "TextBoxWidget",
      parent: "grpbxEWRSendUpdate",
      Label: "Crop Year:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      // START_USER_CODE-USER_txtCropYear_PROPERTIES

      // END_USER_CODE-USER_txtCropYear_PROPERTIES
    },
    txtFromHolderID: {
      name: "txtFromHolderID",
      type: "TextBoxWidget",
      parent: "grpbxEWRSendUpdate",
      Label: "From Holder ID:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      // START_USER_CODE-USER_txtFromHolderID_PROPERTIES

      // END_USER_CODE-USER_txtFromHolderID_PROPERTIES
    },
    txtReceiptNumber: {
      name: "txtReceiptNumber",
      type: "TextBoxWidget",
      parent: "grpbxEWRSendUpdate",
      Label: "Receipt Number:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReceiptNumber_PROPERTIES

      // END_USER_CODE-USER_txtReceiptNumber_PROPERTIES
    },
    txtToHolderID: {
      name: "txtToHolderID",
      type: "TextBoxWidget",
      parent: "grpbxEWRSendUpdate",
      Label: "To Holder ID:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtToHolderID_PROPERTIES

      // END_USER_CODE-USER_txtToHolderID_PROPERTIES
    },
    txtToWarehouseCode: {
      name: "txtToWarehouseCode",
      type: "TextBoxWidget",
      parent: "grpbxEWRSendUpdate",
      Label: "To Warehouse Code:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtToWarehouseCode_PROPERTIES

      // END_USER_CODE-USER_txtToWarehouseCode_PROPERTIES
    },
    grpbxEWRSendUpdate: {
      name: "grpbxEWRSendUpdate",
      type: "GroupBoxWidget",
      parent: "EWRSendUpdate",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxEWRSendUpdate_PROPERTIES

      // END_USER_CODE-USER_grpbxEWRSendUpdate_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "EWRSendUpdate",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  const [loading, setLoading] = useState(false);
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    formLoad();
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  const compIdFromLS = () => (sessionStorage.getItem('compId'));

  let cropYear = cropYearFromLS();
  let userid = useridFromLS();
  let compId = compIdFromLS();
  let isFormValid = true
  let WarehouseCode = ''
  let receiptNum = ''
  let holderId = ''

  const bFillCropYear = async() => {
    setValue(thisObj, "txtCropYear", cropYear);
  }

  const bFillActionCode = async() => {
    setValue(thisObj, "txtActionCode", 'CGH');
  }

  const bFillFromHolderID = async () => {
    let response
    response = await ContractManagementService.RetrieveCompanyDetails(compId)
    let ewr_holder_id = response[0].ewrHolderId;
    setValue(thisObj, "txtFromHolderID", ewr_holder_id);
  }
  const bFormValid = async() => {
    if (thisObj.values.txtToWarehouseCode !== undefined && thisObj.values.txtToWarehouseCode !== null && thisObj.values.txtToWarehouseCode !== "") {
      WarehouseCode = thisObj.values.txtToWarehouseCode
      if (isNaN(thisObj.values.txtToWarehouseCode)) {
        alert('Warehouse Code must be numeric.')
        isFormValid = false
        return
      }
      isFormValid = true
    } else {
      alert('All values are required.')
      isFormValid = false
      return
    }

    if (thisObj.values.txtReceiptNumber !== undefined && thisObj.values.txtReceiptNumber !== null && thisObj.values.txtReceiptNumber !== "") {
      receiptNum = thisObj.values.txtReceiptNumber
      if (isNaN(thisObj.values.txtReceiptNumber)) {
        alert('Receipt Number must be numeric.')
        isFormValid = false
        return
      }
      isFormValid = true

    } else {
      alert('All values are required.')
      isFormValid = false
      return
    }

    if (thisObj.values.txtToHolderID !== undefined && thisObj.values.txtToHolderID !== null && thisObj.values.txtToHolderID !== "") {
      holderId = thisObj.values.txtToHolderID
      isFormValid = true

    } else {
      alert('All values are required.')
      isFormValid = false
      return
    }
  }

  const onbtnSendClick = async () => {
   
    let ewr_holder_id = getValue(thisObj, 'txtFromHolderID')

    await bFormValid()
    if (isFormValid == true) {
      let body = {
        comp_id: compId,
        crop_year: cropYear,
        action_code: "CGH",
        from_holder_id: ewr_holder_id,
        receipt_num: Number(receiptNum),
        to_holder_id: holderId,
        to_whse_code: Number(WarehouseCode),
        user_id: userid
      }
      let res;
      res = await WarehouseReceiptService.CreateEwrSend(body);
      if (res.status != 200) {
        return
      } else if (res.status == 200){
        let parentData = getData(thisObj, 'EWRSendUpdateData')
       // if (parentData == "EXTRCPTFORFPROFILE") {
          let obj = {
            lblRelToHolder  : ewr_holder_id,
            lblRelToWhseCode : WarehouseCode, 
            lblRelToDateTime : new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
            lblRecStatus : 'RELEASED'
          }
          setData(thisObj, 'ERFPdata', obj)
          alert('EWR Update Send table successfully updated.');
       // }

      }
    }
  }
  thisObj.onbtnSendClick = onbtnSendClick

  const onbtnCloseClick = () => {
    try {
      
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      // Modal.close();
      // document.getElementsByClassName("close")[0].click();
      document.getElementById("WarehouseReceipts_EWRSendUpdatePopUp").childNodes[0].click();
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const formLoad = async() => { 
    setLoading(true)
    let parentData = getData(thisObj, 'EWRSendUpdateData')
    if (parentData?.lblFrmName == "EXTRCPTFORFPROFILE") {
      setValue(thisObj, 'txtReceiptNumber', parentData.txtReceiptNumber)
    }

    await bFillCropYear()
    await bFillActionCode()
    await bFillFromHolderID()
    setLoading(false)
  }

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_EWRSendUpdate*/}

              {/* END_USER_CODE-USER_BEFORE_EWRSendUpdate*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxEWRSendUpdate*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxEWRSendUpdate*/}

              <GroupBoxWidget
                conf={state.grpbxEWRSendUpdate}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtCropYear*/}

                {/* END_USER_CODE-USER_BEFORE_txtCropYear*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCropYear}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCropYear*/}

                {/* END_USER_CODE-USER_AFTER_txtCropYear*/}
                {/* START_USER_CODE-USER_BEFORE_lblFarmName*/}

                {/* END_USER_CODE-USER_BEFORE_lblFarmName*/}

                <LabelWidget
                  values={values}
                  conf={state.lblFarmName}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblFarmName*/}

                {/* END_USER_CODE-USER_AFTER_lblFarmName*/}
                {/* START_USER_CODE-USER_BEFORE_txtActionCode*/}

                {/* END_USER_CODE-USER_BEFORE_txtActionCode*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtActionCode}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtActionCode*/}

                {/* END_USER_CODE-USER_AFTER_txtActionCode*/}
                {/* START_USER_CODE-USER_BEFORE_txtFromHolderID*/}

                {/* END_USER_CODE-USER_BEFORE_txtFromHolderID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFromHolderID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFromHolderID*/}

                {/* END_USER_CODE-USER_AFTER_txtFromHolderID*/}
                {/* START_USER_CODE-USER_BEFORE_txtReceiptNumber*/}

                {/* END_USER_CODE-USER_BEFORE_txtReceiptNumber*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtReceiptNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtReceiptNumber*/}

                {/* END_USER_CODE-USER_AFTER_txtReceiptNumber*/}
                {/* START_USER_CODE-USER_BEFORE_txtToHolderID*/}

                {/* END_USER_CODE-USER_BEFORE_txtToHolderID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtToHolderID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtToHolderID*/}

                {/* END_USER_CODE-USER_AFTER_txtToHolderID*/}
                {/* START_USER_CODE-USER_BEFORE_txtToWarehouseCode*/}

                {/* END_USER_CODE-USER_BEFORE_txtToWarehouseCode*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtToWarehouseCode}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtToWarehouseCode*/}

                {/* END_USER_CODE-USER_AFTER_txtToWarehouseCode*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxEWRSendUpdate*/}

              {/* END_USER_CODE-USER_AFTER_grpbxEWRSendUpdate*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnSend*/}

                {/* END_USER_CODE-USER_BEFORE_btnSend*/}

                <ButtonWidget
                  conf={state.btnSend}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSend*/}

                {/* END_USER_CODE-USER_AFTER_btnSend*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_EWRSendUpdate*/}

              {/* END_USER_CODE-USER_AFTER_EWRSendUpdate*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_EWRSendUpdate);
