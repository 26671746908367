/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import jsPDF from 'jspdf';
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  LabelWidget,
  setValue,
  getValue, 
  disable, 
  enable, 
  show, 
  hideColumn, 
  setData, 
  goTo,
  getData
} from "../../shared/WindowImports";

import "./ManualDPRInvHistSearch.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { context, response } from "msw";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { isDate } from "moment";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import * as XLSX from 'xlsx/xlsx.mjs';
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_ManualDPRInvHistSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let CompName = sessionStorage.getItem('Companyname')
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const [loading, setLoading] = useState(true);

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ManualDPRInvHistSearch",
    windowName: "ManualDPRInvHistSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.ManualDPRInvHistSearch",
    // START_USER_CODE-USER_ManualDPRInvHistSearch_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "DPR Inventory Manual Adjustment Audit History Search",
      scrCode: "PN0320C",
    },
    // END_USER_CODE-USER_ManualDPRInvHistSearch_PROPERTIES
    btmExit: {
      name: "btmExit",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btmExit_PROPERTIES

      // END_USER_CODE-USER_btmExit_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "grpbxPrintExport",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxTransferDetails",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnView: {
      name: "btnView",
      type: "ButtonWidget",
      parent: "grpbxAuditHistory",
      Label: "View",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnView_PROPERTIES

      // END_USER_CODE-USER_btnView_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxPrintExport",
      Label: "Print",
      CharWidth: "13",
      ProcessingText: "btnPrint",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    colAction: {
      name: "colAction",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Action",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAction_PROPERTIES

      // END_USER_CODE-USER_colAction_PROPERTIES
    },
    colAdjustmentSource: {
      name: "colAdjustmentSource",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Adjustment Source",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAdjustmentSource_PROPERTIES

      // END_USER_CODE-USER_colAdjustmentSource_PROPERTIES
    },
    colBuyPt: {
      name: "colBuyPt",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: " Buy Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPt_PROPERTIES

      // END_USER_CODE-USER_colBuyPt_PROPERTIES
    },
    colChgMainComment: {
      name: "colChgMainComment",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Chg Maint Comment",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChgMainComment_PROPERTIES

      // END_USER_CODE-USER_colChgMainComment_PROPERTIES
    },
    colChgMaintDate: {
      name: "colChgMaintDate",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Chg Maint Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChgMaintDate_PROPERTIES

      // END_USER_CODE-USER_colChgMaintDate_PROPERTIES
    },
    colChgMaintUser: {
      name: "colChgMaintUser",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Chg Maint User",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChgMaintUser_PROPERTIES

      // END_USER_CODE-USER_colChgMaintUser_PROPERTIES
    },
    colNewCompOwnedAdj: {
      name: "colNewCompOwnedAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Comp Owned Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewCompOwnedAdj_PROPERTIES

      // END_USER_CODE-USER_colNewCompOwnedAdj_PROPERTIES
    },
    colNewIntransitAdj: {
      name: "colNewIntransitAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Intransit Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewIntransitAdj_PROPERTIES

      // END_USER_CODE-USER_colNewIntransitAdj_PROPERTIES
    },
    colNewNotRcptdAdj: {
      name: "colNewNotRcptdAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Not Receipted Adj",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewNotRcptdAdj_PROPERTIES

      // END_USER_CODE-USER_colNewNotRcptdAdj_PROPERTIES
    },
    colNewPhysicalAdj: {
      name: "colNewPhysicalAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Physical Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewPhysicalAdj_PROPERTIES

      // END_USER_CODE-USER_colNewPhysicalAdj_PROPERTIES
    },
    colNewPnutOleic: {
      name: "colNewPnutOleic",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Pnut Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewPnutOleic_PROPERTIES

      // END_USER_CODE-USER_colNewPnutOleic_PROPERTIES
    },
    colNewPnutOrganic: {
      name: "colNewPnutOrganic",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Pnut Orgnaic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewPnutOrganic_PROPERTIES

      // END_USER_CODE-USER_colNewPnutOrganic_PROPERTIES
    },
    colNewPnutSeed: {
      name: "colNewPnutSeed",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Pnut Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewPnutSeed_PROPERTIES

      // END_USER_CODE-USER_colNewPnutSeed_PROPERTIES
    },
    colNewPnutSeg: {
      name: "colNewPnutSeg",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Pnut Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewPnutSeg_PROPERTIES

      // END_USER_CODE-USER_colNewPnutSeg_PROPERTIES
    },
    colNewPnutType: {
      name: "colNewPnutType",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Pnut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewPnutType_PROPERTIES

      // END_USER_CODE-USER_colNewPnutType_PROPERTIES
    },
    colNewPnutVariety: {
      name: "colNewPnutVariety",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Pnut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewPnutVariety_PROPERTIES

      // END_USER_CODE-USER_colNewPnutVariety_PROPERTIES
    },
    colNewPostingDt: {
      name: "colNewPostingDt",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Posting Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewPostingDt_PROPERTIES

      // END_USER_CODE-USER_colNewPostingDt_PROPERTIES
    },
    colNewReceiptAdj: {
      name: "colNewReceiptAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Whs Receipt Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewReceiptAdj_PROPERTIES

      // END_USER_CODE-USER_colNewReceiptAdj_PROPERTIES
    },
    colNewShrinkAdj: {
      name: "colNewShrinkAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Shrink Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewShrinkAdj_PROPERTIES

      // END_USER_CODE-USER_colNewShrinkAdj_PROPERTIES
    },
    colNewUndeterminedAdj: {
      name: "colNewUndeterminedAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Undetermined Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewUndeterminedAdj_PROPERTIES

      // END_USER_CODE-USER_colNewUndeterminedAdj_PROPERTIES
    },
    colNewWhsBin: {
      name: "colNewWhsBin",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "New Whs/Bin",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewWhsBin_PROPERTIES

      // END_USER_CODE-USER_colNewWhsBin_PROPERTIES
    },
    colOldCompOwnedAdj: {
      name: "colOldCompOwnedAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Comp Owned Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldCompOwnedAdj_PROPERTIES

      // END_USER_CODE-USER_colOldCompOwnedAdj_PROPERTIES
    },
    colOldIntransitAdj: {
      name: "colOldIntransitAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Intransit Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldIntransitAdj_PROPERTIES

      // END_USER_CODE-USER_colOldIntransitAdj_PROPERTIES
    },
    colOldNotRcptdAdj: {
      name: "colOldNotRcptdAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Not Receipted Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldNotRcptdAdj_PROPERTIES

      // END_USER_CODE-USER_colOldNotRcptdAdj_PROPERTIES
    },
    colOldPhysicalAdj: {
      name: "colOldPhysicalAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Physical Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldPhysicalAdj_PROPERTIES

      // END_USER_CODE-USER_colOldPhysicalAdj_PROPERTIES
    },
    colOldPnutOleic: {
      name: "colOldPnutOleic",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Pnut Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldPnutOleic_PROPERTIES

      // END_USER_CODE-USER_colOldPnutOleic_PROPERTIES
    },
    colOldPnutOrganic: {
      name: "colOldPnutOrganic",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Pnut Organic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldPnutOrganic_PROPERTIES

      // END_USER_CODE-USER_colOldPnutOrganic_PROPERTIES
    },
    colOldPnutSeed: {
      name: "colOldPnutSeed",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Pnut Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldPnutSeed_PROPERTIES

      // END_USER_CODE-USER_colOldPnutSeed_PROPERTIES
    },
    colOldPnutSeg: {
      name: "colOldPnutSeg",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Pnut Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldPnutSeg_PROPERTIES

      // END_USER_CODE-USER_colOldPnutSeg_PROPERTIES
    },
    colOldPnutType: {
      name: "colOldPnutType",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Pnut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldPnutType_PROPERTIES

      // END_USER_CODE-USER_colOldPnutType_PROPERTIES
    },
    colOldPnutVariety: {
      name: "colOldPnutVariety",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Pnut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldPnutVariety_PROPERTIES

      // END_USER_CODE-USER_colOldPnutVariety_PROPERTIES
    },
    colOldPostingDt: {
      name: "colOldPostingDt",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Posting Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldPostingDt_PROPERTIES

      // END_USER_CODE-USER_colOldPostingDt_PROPERTIES
    },
    colOldShrinkAdj: {
      name: "colOldShrinkAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Shrink Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldShrinkAdj_PROPERTIES

      // END_USER_CODE-USER_colOldShrinkAdj_PROPERTIES
    },
    colOldUndetrminedAdj: {
      name: "colOldUndetrminedAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Undetermined Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldUndetrminedAdj_PROPERTIES

      // END_USER_CODE-USER_colOldUndetrminedAdj_PROPERTIES
    },
    colOldWhsBin: {
      name: "colOldWhsBin",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Whs/Bin",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldWhsBin_PROPERTIES

      // END_USER_CODE-USER_colOldWhsBin_PROPERTIES
    },
    colOldWhsRcptAdj: {
      name: "colOldWhsRcptAdj",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Old Whs Receipt Adj",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldWhsRcptAdj_PROPERTIES

      // END_USER_CODE-USER_colOldWhsRcptAdj_PROPERTIES
    },
    colRecordID: {
      name: "colRecordID",
      type: "GridColumnWidget",
      parent: "gridAuditHistory1",
      Label: "Record ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRecordID_PROPERTIES

      // END_USER_CODE-USER_colRecordID_PROPERTIES
    },
    ddBuyPt: {
      name: "ddBuyPt",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyPtDetails",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyPt_PROPERTIES

      // END_USER_CODE-USER_ddBuyPt_PROPERTIES
    },
    ddGeneration: {
      name: "ddGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutDetails",
      Label: "Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGeneration_PROPERTIES

      // END_USER_CODE-USER_ddGeneration_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutDetails",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddOrganic: {
      name: "ddOrganic",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutDetails",
      Label: "Organic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOrganic_PROPERTIES

      // END_USER_CODE-USER_ddOrganic_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutDetails",
      Label: "Seg:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    ddType: {
      name: "ddType",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutDetails",
      Label: "Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType_PROPERTIES

      // END_USER_CODE-USER_ddType_PROPERTIES
    },
    ddVariety: {
      name: "ddVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxPnutDetails",
      Label: "Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVariety_PROPERTIES

      // END_USER_CODE-USER_ddVariety_PROPERTIES
    },
    ddWhseBin: {
      name: "ddWhseBin",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyPtDetails",
      Label: "Whs/Bin:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseBin_PROPERTIES

      // END_USER_CODE-USER_ddWhseBin_PROPERTIES
    },
    gridAuditHistory1: {
      name: "gridAuditHistory1",
      type: "GridWidget",
      parent: "grpbxAuditHistory",
      gridCellsOrder:
        "txtcolRecordID,txtcolAction,txtcolOldPostingDt,txtcolNewPostingDt,txtcolBuyPt,txtcolOldWhsBin,txtcolNewWhsBin,txtcolOldPnutVariety,txtcolNewPnutVariety,txtcolOldPnutType,txtcolNewPnutType,txtcolOldPnutSeed,txtcolNewPnutSeed,txtcolOldPnutSeg,txtcolNewPnutSeg,txtcolOldPnutOleic,txtcolNewPnutOleic,txtcolOldPnutOrganic,txtcolNewPnutOrganic,txtcolOldPhysicalAdj,txcolNewPhysicalAdj,txtcolOldWhsRcptAdj,txtcolNewReceiptAdj,txtcolOldShrinkAdj,txtcolNewShrinkAdj,txtcolOldNotRcptdAdj,txtcolNewNotRcptdAdj,txtcolOldUndetrminedAdj,txtcolNewUndeterminedAdj,txtcolOldCompOwnedAdj,txtcolNewCompOwnedAdj,txtcolOldIntransitAdj,txtcolNewIntransitAdj,txtcolAdjustmentSource,txtcolChgMaintUser,txtcolChgMaintDate,txtcolChgMainComment",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridAuditHistory1_PROPERTIES

      // END_USER_CODE-USER_gridAuditHistory1_PROPERTIES
    },
    grpbxAuditHistory: {
      name: "grpbxAuditHistory",
      type: "GroupBoxWidget",
      parent: "grpbxManualDPRInvHistSearch",
      ColSpan: "4",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxAuditHistory_PROPERTIES

      // END_USER_CODE-USER_grpbxAuditHistory_PROPERTIES
    },
    grpbxBuyPtDetails: {
      name: "grpbxBuyPtDetails",
      type: "GroupBoxWidget",
      parent: "grpbxManualDPRInvHistSearch",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxBuyPtDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxBuyPtDetails_PROPERTIES
    },
    grpbxPnutDetails: {
      name: "grpbxPnutDetails",
      type: "GroupBoxWidget",
      parent: "grpbxManualDPRInvHistSearch",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxPnutDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxPnutDetails_PROPERTIES
    },
    grpbxPrintExport: {
      name: "grpbxPrintExport",
      type: "GroupBoxWidget",
      parent: "grpbxActions",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxPrintExport_PROPERTIES

      // END_USER_CODE-USER_grpbxPrintExport_PROPERTIES
    },
    grpbxTransferDetails: {
      name: "grpbxTransferDetails",
      type: "GroupBoxWidget",
      parent: "grpbxManualDPRInvHistSearch",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxTransferDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxTransferDetails_PROPERTIES
    },
    lblAuditHistOfInvAdj: {
      name: "lblAuditHistOfInvAdj",
      type: "LabelWidget",
      parent: "grpbxAuditHistory",
      Label: "Audit History of Inventory Adjustment:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAuditHistOfInvAdj_PROPERTIES

      // END_USER_CODE-USER_lblAuditHistOfInvAdj_PROPERTIES
    },
    lblPeanut: {
      name: "lblPeanut",
      type: "LabelWidget",
      parent: "grpbxPnutDetails",
      Label: "Peanut:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPeanut_PROPERTIES

      // END_USER_CODE-USER_lblPeanut_PROPERTIES
    },
    txcolNewPhysicalAdj: {
      name: "txcolNewPhysicalAdj",
      type: "TextBoxWidget",
      colName: "colNewPhysicalAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txcolNewPhysicalAdj_PROPERTIES

      // END_USER_CODE-USER_txcolNewPhysicalAdj_PROPERTIES
    },
    txt1007: {
      name: "txt1007",
      type: "TextBoxWidget",
      parent: "grpbxBuyPtDetails",
      Label: "1007 #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007_PROPERTIES

      // END_USER_CODE-USER_txt1007_PROPERTIES
    },
    txtAuditDateFrom: {
      name: "txtAuditDateFrom",
      type: "DateWidget",
      parent: "grpbxBuyPtDetails",
      Label: "Audit Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtAuditDateFrom_PROPERTIES

      // END_USER_CODE-USER_txtAuditDateFrom_PROPERTIES
    },
    txtAuditDateTo: {
      name: "txtAuditDateTo",
      type: "DateWidget",
      parent: "grpbxBuyPtDetails",
      Label: "-Thru-",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtAuditDateTo_PROPERTIES

      // END_USER_CODE-USER_txtAuditDateTo_PROPERTIES
    },
    txtAuditUser: {
      name: "txtAuditUser",
      type: "TextBoxWidget",
      parent: "grpbxTransferDetails",
      Label: "Audit User #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAuditUser_PROPERTIES

      // END_USER_CODE-USER_txtAuditUser_PROPERTIES
    },
    txtcolAction: {
      name: "txtcolAction",
      type: "TextBoxWidget",
      colName: "colAction",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAction_PROPERTIES

      // END_USER_CODE-USER_txtcolAction_PROPERTIES
    },
    txtcolAdjustmentSource: {
      name: "txtcolAdjustmentSource",
      type: "TextBoxWidget",
      colName: "colAdjustmentSource",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAdjustmentSource_PROPERTIES

      // END_USER_CODE-USER_txtcolAdjustmentSource_PROPERTIES
    },
    txtcolBuyPt: {
      name: "txtcolBuyPt",
      type: "TextBoxWidget",
      colName: "colBuyPt",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyPt_PROPERTIES
    },
    txtcolChgMainComment: {
      name: "txtcolChgMainComment",
      type: "TextBoxWidget",
      colName: "colChgMainComment",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChgMainComment_PROPERTIES

      // END_USER_CODE-USER_txtcolChgMainComment_PROPERTIES
    },
    txtcolChgMaintDate: {
      name: "txtcolChgMaintDate",
      type: "TextBoxWidget",
      colName: "colChgMaintDate",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChgMaintDate_PROPERTIES

      // END_USER_CODE-USER_txtcolChgMaintDate_PROPERTIES
    },
    txtcolChgMaintUser: {
      name: "txtcolChgMaintUser",
      type: "TextBoxWidget",
      colName: "colChgMaintUser",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChgMaintUser_PROPERTIES

      // END_USER_CODE-USER_txtcolChgMaintUser_PROPERTIES
    },
    txtcolNewCompOwnedAdj: {
      name: "txtcolNewCompOwnedAdj",
      type: "TextBoxWidget",
      colName: "colNewCompOwnedAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewCompOwnedAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolNewCompOwnedAdj_PROPERTIES
    },
    txtcolNewIntransitAdj: {
      name: "txtcolNewIntransitAdj",
      type: "TextBoxWidget",
      colName: "colNewIntransitAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewIntransitAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolNewIntransitAdj_PROPERTIES
    },
    txtcolNewNotRcptdAdj: {
      name: "txtcolNewNotRcptdAdj",
      type: "TextBoxWidget",
      colName: "colNewNotRcptdAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewNotRcptdAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolNewNotRcptdAdj_PROPERTIES
    },
    txtcolNewPnutOleic: {
      name: "txtcolNewPnutOleic",
      type: "TextBoxWidget",
      colName: "colNewPnutOleic",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewPnutOleic_PROPERTIES

      // END_USER_CODE-USER_txtcolNewPnutOleic_PROPERTIES
    },
    txtcolNewPnutOrganic: {
      name: "txtcolNewPnutOrganic",
      type: "TextBoxWidget",
      colName: "colNewPnutOrganic",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewPnutOrganic_PROPERTIES

      // END_USER_CODE-USER_txtcolNewPnutOrganic_PROPERTIES
    },
    txtcolNewPnutSeed: {
      name: "txtcolNewPnutSeed",
      type: "TextBoxWidget",
      colName: "colNewPnutSeed",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewPnutSeed_PROPERTIES

      // END_USER_CODE-USER_txtcolNewPnutSeed_PROPERTIES
    },
    txtcolNewPnutSeg: {
      name: "txtcolNewPnutSeg",
      type: "TextBoxWidget",
      colName: "colNewPnutSeg",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewPnutSeg_PROPERTIES

      // END_USER_CODE-USER_txtcolNewPnutSeg_PROPERTIES
    },
    txtcolNewPnutType: {
      name: "txtcolNewPnutType",
      type: "TextBoxWidget",
      colName: "colNewPnutType",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewPnutType_PROPERTIES

      // END_USER_CODE-USER_txtcolNewPnutType_PROPERTIES
    },
    txtcolNewPnutVariety: {
      name: "txtcolNewPnutVariety",
      type: "TextBoxWidget",
      colName: "colNewPnutVariety",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewPnutVariety_PROPERTIES

      // END_USER_CODE-USER_txtcolNewPnutVariety_PROPERTIES
    },
    txtcolNewPostingDt: {
      name: "txtcolNewPostingDt",
      type: "TextBoxWidget",
      colName: "colNewPostingDt",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewPostingDt_PROPERTIES

      // END_USER_CODE-USER_txtcolNewPostingDt_PROPERTIES
    },
    txtcolNewReceiptAdj: {
      name: "txtcolNewReceiptAdj",
      type: "TextBoxWidget",
      colName: "colNewReceiptAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewReceiptAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolNewReceiptAdj_PROPERTIES
    },
    txtcolNewShrinkAdj: {
      name: "txtcolNewShrinkAdj",
      type: "TextBoxWidget",
      colName: "colNewShrinkAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewShrinkAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolNewShrinkAdj_PROPERTIES
    },
    txtcolNewUndeterminedAdj: {
      name: "txtcolNewUndeterminedAdj",
      type: "TextBoxWidget",
      colName: "colNewUndeterminedAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewUndeterminedAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolNewUndeterminedAdj_PROPERTIES
    },
    txtcolNewWhsBin: {
      name: "txtcolNewWhsBin",
      type: "TextBoxWidget",
      colName: "colNewWhsBin",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewWhsBin_PROPERTIES

      // END_USER_CODE-USER_txtcolNewWhsBin_PROPERTIES
    },
    txtcolOldCompOwnedAdj: {
      name: "txtcolOldCompOwnedAdj",
      type: "TextBoxWidget",
      colName: "colOldCompOwnedAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldCompOwnedAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolOldCompOwnedAdj_PROPERTIES
    },
    txtcolOldIntransitAdj: {
      name: "txtcolOldIntransitAdj",
      type: "TextBoxWidget",
      colName: "colOldIntransitAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldIntransitAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolOldIntransitAdj_PROPERTIES
    },
    txtcolOldNotRcptdAdj: {
      name: "txtcolOldNotRcptdAdj",
      type: "TextBoxWidget",
      colName: "colOldNotRcptdAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldNotRcptdAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolOldNotRcptdAdj_PROPERTIES
    },
    txtcolOldPhysicalAdj: {
      name: "txtcolOldPhysicalAdj",
      type: "TextBoxWidget",
      colName: "colOldPhysicalAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldPhysicalAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolOldPhysicalAdj_PROPERTIES
    },
    txtcolOldPnutOleic: {
      name: "txtcolOldPnutOleic",
      type: "TextBoxWidget",
      colName: "colOldPnutOleic",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldPnutOleic_PROPERTIES

      // END_USER_CODE-USER_txtcolOldPnutOleic_PROPERTIES
    },
    txtcolOldPnutOrganic: {
      name: "txtcolOldPnutOrganic",
      type: "TextBoxWidget",
      colName: "colOldPnutOrganic",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldPnutOrganic_PROPERTIES

      // END_USER_CODE-USER_txtcolOldPnutOrganic_PROPERTIES
    },
    txtcolOldPnutSeed: {
      name: "txtcolOldPnutSeed",
      type: "TextBoxWidget",
      colName: "colOldPnutSeed",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldPnutSeed_PROPERTIES

      // END_USER_CODE-USER_txtcolOldPnutSeed_PROPERTIES
    },
    txtcolOldPnutSeg: {
      name: "txtcolOldPnutSeg",
      type: "TextBoxWidget",
      colName: "colOldPnutSeg",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldPnutSeg_PROPERTIES

      // END_USER_CODE-USER_txtcolOldPnutSeg_PROPERTIES
    },
    txtcolOldPnutType: {
      name: "txtcolOldPnutType",
      type: "TextBoxWidget",
      colName: "colOldPnutType",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldPnutType_PROPERTIES

      // END_USER_CODE-USER_txtcolOldPnutType_PROPERTIES
    },
    txtcolOldPnutVariety: {
      name: "txtcolOldPnutVariety",
      type: "TextBoxWidget",
      colName: "colOldPnutVariety",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldPnutVariety_PROPERTIES

      // END_USER_CODE-USER_txtcolOldPnutVariety_PROPERTIES
    },
    txtcolOldPostingDt: {
      name: "txtcolOldPostingDt",
      type: "TextBoxWidget",
      colName: "colOldPostingDt",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldPostingDt_PROPERTIES

      // END_USER_CODE-USER_txtcolOldPostingDt_PROPERTIES
    },
    txtcolOldShrinkAdj: {
      name: "txtcolOldShrinkAdj",
      type: "TextBoxWidget",
      colName: "colOldShrinkAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldShrinkAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolOldShrinkAdj_PROPERTIES
    },
    txtcolOldUndetrminedAdj: {
      name: "txtcolOldUndetrminedAdj",
      type: "TextBoxWidget",
      colName: "colOldUndetrminedAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldUndetrminedAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolOldUndetrminedAdj_PROPERTIES
    },
    txtcolOldWhsBin: {
      name: "txtcolOldWhsBin",
      type: "TextBoxWidget",
      colName: "colOldWhsBin",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldWhsBin_PROPERTIES

      // END_USER_CODE-USER_txtcolOldWhsBin_PROPERTIES
    },
    txtcolOldWhsRcptAdj: {
      name: "txtcolOldWhsRcptAdj",
      type: "TextBoxWidget",
      colName: "colOldWhsRcptAdj",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldWhsRcptAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolOldWhsRcptAdj_PROPERTIES
    },
    txtcolRecordID: {
      name: "txtcolRecordID",
      type: "TextBoxWidget",
      colName: "colRecordID",
      parent: "gridAuditHistory1",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRecordID_PROPERTIES

      // END_USER_CODE-USER_txtcolRecordID_PROPERTIES
    },
    txtSC95: {
      name: "txtSC95",
      type: "TextBoxWidget",
      parent: "grpbxBuyPtDetails",
      Label: "SC95 #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95_PROPERTIES

      // END_USER_CODE-USER_txtSC95_PROPERTIES
    },
    txtTradeInsp: {
      name: "txtTradeInsp",
      type: "TextBoxWidget",
      parent: "grpbxTransferDetails",
      Label: "Trade Insp #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeInsp_PROPERTIES

      // END_USER_CODE-USER_txtTradeInsp_PROPERTIES
    },
    txtTradeSettle: {
      name: "txtTradeSettle",
      type: "TextBoxWidget",
      parent: "grpbxTransferDetails",
      Label: "Trade Settle #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeSettle_PROPERTIES

      // END_USER_CODE-USER_txtTradeSettle_PROPERTIES
    },
    txtTransfer: {
      name: "txtTransfer",
      type: "TextBoxWidget",
      parent: "grpbxTransferDetails",
      Label: "Transfer #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransfer_PROPERTIES

      // END_USER_CODE-USER_txtTransfer_PROPERTIES
    },
    txtWhseRcptNum: {
      name: "txtWhseRcptNum",
      type: "TextBoxWidget",
      parent: "grpbxBuyPtDetails",
      Label: "Whs Rcpt #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhseRcptNum_PROPERTIES

      // END_USER_CODE-USER_txtWhseRcptNum_PROPERTIES
    },
    grpbxManualDPRInvHistSearch: {
      name: "grpbxManualDPRInvHistSearch",
      type: "GroupBoxWidget",
      parent: "ManualDPRInvHistSearch",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      clonedExtId: "125468",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxManualDPRInvHistSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxManualDPRInvHistSearch_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ManualDPRInvHistSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceApplicationSupport#ManualDPRInvHistView":{},
       },
      REVERSE:{
        "SystemMaintenanceApplicationSupport#ManualDPRInvHistView":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnView:{
    //   DEFAULT:[
    //   "SystemMaintenanceApplicationSupport#ManualDPRInvHistView#DEFAULT#true#Click",]
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    Form_Load()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  const bFillOrganicList = async () => {

    let isbFillOrganicList = false
    setValue(thisObj, 'ddOrganic', '')
    let js = []
    js.push({ key: "", description: ">>> All Organic <<<" })
    js.push({ key: "_", description: " " })
    js.push({ key: "N", description: "No" })
    js.push({ key: "Y", description: "Yes" })

    thisObj.setState(current => {
      return {
        ...current,
        ddOrganic: {
          ...state["ddOrganic"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddOrganic', js[0].key)
    isbFillOrganicList = true
    return isbFillOrganicList
  }

  const bFillOleicList = async () => {
    let isbFillOleicList = false

    setValue(thisObj, 'ddOleic', '')

    let js = []
    js.push({ key: '', description: ">>> All Oleic <<<" })
    js.push({ key: '_', description: "None" })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'H', description: "High" })

    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddOleic', js[0].key)

    isbFillOleicList = true
    return isbFillOleicList
  }

  const bFillSegmentList = async () => {

    let isbFillSegmentList = false
    setValue(thisObj, 'ddSeg', '')

    let js = []
    js.push({ key: '', description: ">>> All Segments <<<" })
    js.push({ key: '1', description: "Seg1" })
    js.push({ key: '2', description: 'Seg2' })
    js.push({ key: '3', description: "Seg3" })

    thisObj.setState(current => {
      return {
        ...current,
        ddSeg: {
          ...state["ddSeg"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddSeg', js[0].key)

    isbFillSegmentList = true
    return isbFillSegmentList
  }

  const bFillGenerationList = async () => {

    let isbFillGenerationList = false
    setValue(thisObj, 'ddGeneration', '')

    let js = []
    js.push({ key: '', description: ">>> All Generations <<<" })
    js.push({ key: '_', description: "Non-Seed" })
    js.push({ key: 'F', description: 'Foundation Seed' })
    js.push({ key: 'R', description: "Registered Seed" })
    js.push({ key: 'C', description: "Certified Seed" })

    thisObj.setState(current => {
      return {
        ...current,
        ddGeneration: {
          ...state["ddGeneration"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddGeneration', js[0].key)

    isbFillGenerationList = true
    return isbFillGenerationList
  }

  const bFillBuyPtList = async () => {

    let isbFillBuyPtList = false

    setValue(thisObj, 'ddBuyPt', '')
    let strXML = []

    let js = []
    let Obj = {}
    js.push({ key: '', description: ">>> Select Buying Point <<<" })

    await ContractManagementService.RetrieveBuyingPointControlDetails('PN0320', null, null, null, null).then(response => {
      strXML = response
    })

    if (strXML != undefined && strXML != null && strXML.length > 0) {
      for (let i = 0; i < strXML.length; i++) {
        Obj = {}
        Obj = { key: strXML[i].buy_pt_id, description: strXML[i].buy_pt_id + "  --  " + strXML[i].buy_pt_name }
        js.push(Obj)
      }
    }

    thisObj.setState(current => {
      return {
        ...current,
        ddBuyPt: {
          ...state["ddBuyPt"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddBuyPt', js[0].key)

    isbFillBuyPtList = true
    return isbFillBuyPtList
  }

  const bFillWhsBinList = async () => {

    let isbFillWhsBinList = false

    let strXML = []
    setValue(thisObj, 'ddWhseBin', '')

    let js = []
    let Obj = {}
    js.push({ key: '', description: ">>> All Whs/Bin <<<" })

    let buy_point_id = getValue(thisObj, 'ddBuyPt')
    await SettlementService.RetrieveWhouseBinInventoryDetails(buy_point_id, null, null, null, null).then(response => {
      strXML = response
    })

    if (strXML != undefined && strXML != null && strXML.length > 0) {
      for (let i = 0; i < strXML.length; i++) {
        Obj = {}
        Obj = { key: strXML[i].whouseNum, description: strXML[i].whouseNum + "/" + strXML[i].binNum + "  --  " + strXML[i].whouseBinId, WhouseNum: strXML[i].whouseNum, BinNum: strXML[i].binNum }
        js.push(Obj)
      }
    }

    thisObj.setState(current => {
      return {
        ...current,
        ddWhseBin: {
          ...state["ddWhseBin"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddWhseBin', js[0].key)

    isbFillWhsBinList = true
    return isbFillWhsBinList
  }

  const bFillTypeList = async () => {

    let isbFillTypeList = false
    let strXML = []
    let js = []
    let Obj = {}

    setValue(thisObj, 'ddType', '')

    js.push({ key: '', description: ">>> All Types <<<" })

    await ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      strXML = response
    })

    if (strXML != undefined && strXML != null && strXML.length > 0) {
      for (let i = 0; i < strXML.length; i++) {
        Obj = {}
        Obj = { key: strXML[i].pnutTypeId, description: strXML[i].pnutTypeId + "  --  " + strXML[i].pnutTypeName }
        js.push(Obj)
      }
    }

    thisObj.setState(current => {
      return {
        ...current,
        ddType: {
          ...state["ddType"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddType', js[0].key)

    isbFillTypeList = true
    return isbFillTypeList
  }

  const bFillVarietyList = async () => {
    let js = [];
    let isbFillVarietyList = false
    js.push({ key: '', description: '>>> All Varieties <<<' })
    js.push({ key: '_', description: ' ' })
    let buy_pt_id = getValue(thisObj, 'ddBuyPt');
    let peanut_type = getValue(thisObj, 'ddType');

    if (peanut_type != null && peanut_type != "" && peanut_type != undefined) {
      await ContractManagementService.RetrievePeanutVarietyControls(buy_pt_id, null, null, peanut_type, null).then(response => {
        let data = response;
        let count = 0;
        data.forEach(element => {
          count++;
          let newVar = true;
          for (let i = 0; i < count - 1; i++) {
            if ((element.pnut_variety_id).toUpperCase() == (data[i].pnut_variety_id).toUpperCase()) {
              newVar = false;
              break;
            }
          }
          let symbol;
          if (newVar) {
            switch (element.symbol_ind.trim()) {
              case "TRADEMARK":
                symbol = " \u2122"
                break;
              case "REGISTERED TRADEMARK":
                symbol = " \xAE"
                break;
              case "COPYRIGHT":
                symbol = " \xA9"
                break;
              default:
                symbol = "";
            }
            let obj = { key: element.pnut_variety_id, description: element.pnut_variety_name + symbol }
            js.push(obj)
          }
        });
      })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddVariety: {
          ...state["ddVariety"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddVariety', js[0].key);
    isbFillVarietyList = true
    return isbFillVarietyList
  }

  const DoSearch = async () => {
    let strCMD = {}
    let strXML = []

    await ContractManagementService.RetrieveAccessPermissionDetails('PN0320', null, 'I', null).then(response => {
      strXML = response
    })
    if (strXML != undefined && strXML != null && strXML.length > 0) {
      if (strXML[0].permission != 'Y') {
        disable(thisObj, 'btnSearch')
      }
    }

    strCMD.action_code = "HIST_INQ"
    if (getValue(thisObj, 'ddBuyPt') != undefined && getValue(thisObj, 'ddBuyPt') != null && getValue(thisObj, 'ddBuyPt') != '') { strCMD.buy_pt_id = getValue(thisObj, 'ddBuyPt') } else { strCMD.buy_pt_id = '' }
    let WhsBinList = thisObj.state['ddWhseBin'].valueList;
    let WhsBinValue = getValue(thisObj, 'ddWhseBin')
    if (getValue(thisObj, 'ddWhseBin') != undefined && getValue(thisObj, 'ddWhseBin') != null && getValue(thisObj, 'ddWhseBin') != '') {
      strCMD.whs_num = WhsBinList.find(elem => elem.key === WhsBinValue).WhouseNum
      strCMD.bin_num = WhsBinList.find(elem => elem.key === WhsBinValue).BinNum
    }
    else {
      strCMD.whs_num = ''
      strCMD.bin_num = ''
    }
    if (getValue(thisObj, 'txtAuditDateFrom') != undefined && getValue(thisObj, 'txtAuditDateFrom') != null && getValue(thisObj, 'txtAuditDateFrom') != '') { strCMD.beg_date = moment(getValue(thisObj, 'txtAuditDateFrom')).format('MM/DD/YYYY') } else { strCMD.beg_date = '' }
    if (getValue(thisObj, 'txtAuditDateTo') != undefined && getValue(thisObj, 'txtAuditDateTo') != null && getValue(thisObj, 'txtAuditDateTo') != '') { strCMD.end_date = moment(getValue(thisObj, 'txtAuditDateTo')).format('MM/DD/YYYY') } else { strCMD.end_date = '' }
    if (getValue(thisObj, 'txtSC95') != undefined && getValue(thisObj, 'txtSC95') != null && getValue(thisObj, 'txtSC95') != '') { strCMD.insp_num = getValue(thisObj, 'txtSC95') } else { strCMD.insp_num = '' }
    if (getValue(thisObj, 'txt1007') != undefined && getValue(thisObj, 'txt1007') != null && getValue(thisObj, 'txt1007') != '') { strCMD.settle_num = getValue(thisObj, 'txt1007') } else { strCMD.settle_num = '' }
    if (getValue(thisObj, 'txtWhseRcptNum') != undefined && getValue(thisObj, 'txtWhseRcptNum') != null && getValue(thisObj, 'txtWhseRcptNum') != '') { strCMD.whs_rcpt = getValue(thisObj, 'txtWhseRcptNum') } else { strCMD.whs_rcpt = '' }
    if (getValue(thisObj, 'txtTransfer') != undefined && getValue(thisObj, 'txtTransfer') != null && getValue(thisObj, 'txtTransfer') != '') { strCMD.xfer_num = getValue(thisObj, 'txtTransfer') } else { strCMD.xfer_num = '' }
    if (getValue(thisObj, 'txtTradeInsp') != undefined && getValue(thisObj, 'txtTradeInsp') != null && getValue(thisObj, 'txtTradeInsp') != '') { strCMD.trade_insp = getValue(thisObj, 'txtTradeInsp') } else { strCMD.trade_insp = '' }
    if (getValue(thisObj, 'txtTradeSettle') != undefined && getValue(thisObj, 'txtTradeSettle') != null && getValue(thisObj, 'txtTradeSettle') != '') { strCMD.trade_settle = getValue(thisObj, 'txtTradeSettle') } else { strCMD.trade_settle = '' }
    if (getValue(thisObj, 'txtAuditUser') != undefined && getValue(thisObj, 'txtAuditUser') != null && getValue(thisObj, 'txtAuditUser') != '') { strCMD.audit_user = getValue(thisObj, 'txtAuditUser') } else { strCMD.audit_user = '' }
    if (getValue(thisObj, 'ddType') != undefined && getValue(thisObj, 'ddType') != null && getValue(thisObj, 'ddType') != '') { strCMD.pnut_type = getValue(thisObj, 'ddType') } else { strCMD.pnut_type = '' }
    if (getValue(thisObj, 'ddVariety') != undefined && getValue(thisObj, 'ddVariety') != null && getValue(thisObj, 'ddVariety') != '') { strCMD.pnut_variety = getValue(thisObj, 'ddVariety') } else { strCMD.pnut_variety = '' }
    if (getValue(thisObj, 'ddGeneration') != undefined && getValue(thisObj, 'ddGeneration') != null && getValue(thisObj, 'ddGeneration') != '') { strCMD.seed_gen = getValue(thisObj, 'ddGeneration') } else { strCMD.seed_gen = '' }
    if (getValue(thisObj, 'ddSeg') != undefined && getValue(thisObj, 'ddSeg') != null && getValue(thisObj, 'ddSeg') != '') { strCMD.seg_type = getValue(thisObj, 'ddSeg') } else { strCMD.seg_type = '' }
    if (getValue(thisObj, 'ddOleic') != undefined && getValue(thisObj, 'ddOleic') != null && getValue(thisObj, 'ddOleic') != '') { strCMD.oleic_ind = getValue(thisObj, 'ddOleic') } else { strCMD.oleic_ind = '' }
    if (getValue(thisObj, 'ddOrganic') != undefined && getValue(thisObj, 'ddOrganic') != null && getValue(thisObj, 'ddOrganic') != '') { strCMD.organic_ind = getValue(thisObj, 'ddOrganic') } else { strCMD.organic_ind = '' }

    let js = []
    let Obj = {}
    let lst = []
    await SystemMaintenanceApplicationSupportService.RetrieveManualInvestmaintDetails(strCMD.action_code, null, strCMD.buy_pt_id, strCMD.whs_num, strCMD.bin_num, strCMD.beg_date, strCMD.end_date, strCMD.insp_num, strCMD.settle_num, strCMD.whs_rcpt, strCMD.xfer_num, strCMD.trade_insp, strCMD.trade_settle, strCMD.pnut_type, strCMD.pnut_variety, strCMD.seed_gen, strCMD.seg_type, strCMD.oleic_ind, strCMD.organic_ind, strCMD.audit_user).then(response => {
      lst = response;
      strXML = lst[0].inv_maint_hists
    })
    if (strXML != undefined && strXML != null && strXML.length > 0) {
      for (let i = 0; i < strXML.length; i++) {
        Obj = {}
        Obj.txtcolRecordID = strXML[i].record_key
        Obj.txtcolAction = strXML[i].chg_maint_action
        Obj.txtcolOldPostingDt = strXML[i].old_audit_date_time == undefined || strXML[i].old_audit_date_time == null || strXML[i].old_audit_date_time == '' ? '' : moment(strXML[i].old_audit_date_time).format('MM/DD/YYYY')
        Obj.txtcolNewPostingDt = strXML[i].new_audit_date_time == undefined || strXML[i].new_audit_date_time == null || strXML[i].new_audit_date_time == '' ? '' : moment(strXML[i].new_audit_date_time).format('MM/DD/YYYY')
        Obj.txtcolBuyPt = strXML[i].buy_pt_id
        Obj.txtcolOldWhsBin = strXML[i].old_whouse_num + '/' + strXML[i].old_bin_num
        Obj.txtcolNewWhsBin = strXML[i].new_whouse_num + '/' + strXML[i].new_bin_num
        Obj.txtcolOldPnutVariety = strXML[i].old_pnut_variety_id
        Obj.txtcolNewPnutVariety = strXML[i].new_pnut_variety_id
        Obj.txtcolOldPnutType = strXML[i].old_pnut_type_id
        Obj.txtcolNewPnutType = strXML[i].new_pnut_type_id
        Obj.txtcolOldPnutSeed = strXML[i].old_seed_gen
        Obj.txtcolNewPnutSeed = strXML[i].new_seed_gen
        Obj.txtcolOldPnutSeg = strXML[i].old_seg_type
        Obj.txtcolNewPnutSeg = strXML[i].new_seg_type

        switch (strXML[i].old_oleic_ind) {
          case 'H':
            Obj.txtcolOldPnutOleic = "High";
            break;
          case 'M':
            Obj.txtcolOldPnutOleic = "Mid";
            break;
          default:
            Obj.txtcolOldPnutOleic = ""
            break;
        }

        switch (strXML[i].new_oleic_ind) {
          case 'H':
            Obj.txtcolNewPnutOleic = "High";
            break;
          case 'M':
            Obj.txtcolNewPnutOleic = "Mid";
            break;
          default:
            Obj.txtcolNewPnutOleic = ""
            break;
        }

        Obj.txtcolOldPnutOrganic = strXML[i].old_organic_ind
        Obj.txtcolNewPnutOrganic = strXML[i].new_organic_ind
        Obj.txtcolOldPhysicalAdj = strXML[i].old_physical_adj
        Obj.txcolNewPhysicalAdj = strXML[i].new_physical_adj
        Obj.txtcolOldWhsRcptAdj = strXML[i].old_whouse_rcpt_adj
        Obj.txtcolNewReceiptAdj = strXML[i].new_whouse_rcpt_adj
        Obj.txtcolOldShrinkAdj = strXML[i].old_rcpt_shrink_adj
        Obj.txtcolNewShrinkAdj = strXML[i].new_rcpt_shrink_adj
        Obj.txtcolOldNotRcptdAdj = strXML[i].old_unrcpted_adj
        Obj.txtcolNewNotRcptdAdj = strXML[i].new_unrcpted_adj
        Obj.txtcolOldUndetrminedAdj = strXML[i].old_undetermined_adj
        Obj.txtcolNewUndeterminedAdj = strXML[i].new_undetermined_adj
        Obj.txtcolOldCompOwnedAdj = strXML[i].old_comp_own_adj
        Obj.txtcolNewCompOwnedAdj = strXML[i].new_comp_own_adj
        Obj.txtcolOldIntransitAdj = strXML[i].old_intransit_adj
        Obj.txtcolNewIntransitAdj = strXML[i].new_intransit_adj

        if (strXML[i].audit_insp_num != undefined && strXML[i].audit_insp_num != null && strXML[i].audit_insp_num != '') {
          if (strXML[i].audit_settle_num.length > 0 && strXML[i].audit_settle_num < 7) {
            strXML[i].audit_settle_num = '0'.repeat((7 - strXML[i].audit_settle_num.length)) + strXML[i].audit_settle_num
          }
          strXML[i].audit_settle_num = strXML[i].audit_settle_num == undefined || strXML[i].audit_settle_num == null || strXML[i].audit_settle_num == '' ? '' : "1007#:" + strXML[i].audit_settle_num
          Obj.txtcolAdjustmentSource = strXML[i].audit_settle_num + "SC95#:" + strXML[i].audit_insp_num + " @ BuyPt:" + strXML[i].audit_buy_pt
        }

        if (strXML[i].audit_whse_rcpt != undefined && strXML[i].audit_whse_rcpt != null && strXML[i].audit_whse_rcpt != '') {
          Obj.txtcolAdjustmentSource = "Whs_Rcpt#:" + strXML[i].audit_whse_rcpt + " @ BuyPt:" + strXML[i].audit_buy_pt
        }
        if (strXML[i].audit_xfer_num != undefined && strXML[i].audit_xfer_num != null && strXML[i].audit_xfer_num != '') {
          Obj.txtcolAdjustmentSource = "Xfer#:" + strXML[i].audit_xfer_num + " Shipped From:" + strXML[i].audit_buy_pt
        }

        if (strXML[i].audit_trade_insp != undefined && strXML[i].audit_trade_insp != null && strXML[i].audit_trade_insp != '') {
          strXML[i].audit_trade_settle = strXML[i].audit_trade_settle == undefined || strXML[i].audit_trade_settle == null ? '' : '/' + strXML[i].audit_trade_settle
          Obj.txtcolAdjustmentSource = "Trade#:" + strXML[i].audit_trade_insp + strXML[i].audit_trade_settle + " @ BuyPt:" + strXML[i].audit_buy_pt
        }

        if (strXML[i].audit_user != undefined && strXML[i].audit_user != null && strXML[i].audit_user != '') {
          Obj.txtcolAdjustmentSource = "Manual Inv Adj by user: " + strXML[i].audit_user
        }

        Obj.txtcolChgMaintUser = strXML[i].chg_maint_user
        Obj.txtcolChgMaintDate = strXML[i].chg_maint_date_time == undefined || strXML[i].chg_maint_date_time == null || strXML[i].chg_maint_date_time == '' ? '' : moment(strXML[i].chg_maint_date_time).format('MM/DD/YYYY')
        Obj.txtcolChgMainComment = strXML[i].chg_maint_reason

        js.push(Obj)
      }
      setValue(thisObj, 'gridAuditHistory1', js)
      enable(thisObj, 'btnView')
      enable(thisObj, 'btnExportToExcel')
      enable(thisObj, 'btnPrint')
    }
    else {
      disable(thisObj, 'btnView')
      disable(thisObj, 'btnExportToExcel')
      disable(thisObj, 'btnPrint')
      showMessage(thisObj, "No History Records found!")
      setValue(thisObj, 'gridAuditHistory1', [])
    }

    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolRecordID')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldPostingDt')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewPostingDt')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldWhsBin')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewWhsBin')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldPnutVariety')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewPnutVariety')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldPnutType')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewPnutType')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldPnutSeed')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewPnutSeed')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldPnutSeg')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewPnutSeg')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldPnutOleic')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewPnutOleic')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldPnutOrganic')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewPnutOrganic')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldPhysicalAdj')
    hideColumn(thisObj, 'gridAuditHistory1', 'txcolNewPhysicalAdj')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldWhsRcptAdj')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewReceiptAdj')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldShrinkAdj')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewShrinkAdj')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldNotRcptdAdj')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewNotRcptdAdj')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldUndetrminedAdj')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewUndeterminedAdj')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldCompOwnedAdj')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewCompOwnedAdj')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolOldIntransitAdj')
    hideColumn(thisObj, 'gridAuditHistory1', 'txtcolNewIntransitAdj')
  }
  
  const bFormValid = async () => {

    let isbFormValid = false

    let ddBuyPtValue = getValue(thisObj, 'ddBuyPt')
    let txtSC95Value = getValue(thisObj, 'txtSC95')
    let txt1007Value = getValue(thisObj, 'txt1007')
    let txtWhseRcptNumValue = getValue(thisObj, 'txtWhseRcptNum')
    let txtTransferValue = getValue(thisObj, 'txtTransfer')
    let txtTradeInspValue = getValue(thisObj, 'txtTradeInsp')
    let txtTradeSettleValue = getValue(thisObj, 'txtTradeSettle')
    let txtAuditUserValue = getValue(thisObj, 'txtAuditUser')
    let txtAuditDateFromValue = getValue(thisObj, 'txtAuditDateFrom') == undefined || getValue(thisObj, 'txtAuditDateFrom') == null || getValue(thisObj, 'txtAuditDateFrom') == '' ? '' : moment(getValue(thisObj, 'txtAuditDateFrom')).format('MM/DD/YYYY')
    let txtAuditDateToValue = getValue(thisObj, 'txtAuditDateTo') == undefined || getValue(thisObj, 'txtAuditDateTo') == null || getValue(thisObj, 'txtAuditDateTo') == '' ? '' : moment(getValue(thisObj, 'txtAuditDateTo')).format('MM/DD/YYYY')

    if (
      (ddBuyPtValue == undefined || ddBuyPtValue == null || ddBuyPtValue == '') &&
      (txtSC95Value == undefined || txtSC95Value == null || txtSC95Value == '') &&
      (txt1007Value == undefined || txt1007Value == null || txt1007Value == '') &&
      (txtWhseRcptNumValue == undefined || txtWhseRcptNumValue == null || txtWhseRcptNumValue == '') &&
      (txtTransferValue == undefined || txtTransferValue == null || txtTransferValue == '') &&
      (txtTradeInspValue == undefined || txtTradeInspValue == null || txtTradeInspValue == '') &&
      (txtTradeSettleValue == undefined || txtTradeSettleValue == null || txtTradeSettleValue == '') &&
      (txtAuditUserValue == undefined || txtAuditUserValue == null || txtAuditUserValue == '')
    ) {
      alert("Search Error!. You must specify to search by one of the following: # 1. Buying Point # 2. SC95 # 3. 1007 # 4. Whse Receipt # 5. Transfer # 6. Trade Insp # 7. Trade Settle.")
      return isbFormValid;
    }

    if (txtAuditDateFromValue !== '' && isDate(txtAuditDateFromValue) == true) {
      alert("Search Error!. Begining Audit Date is not a valid date!")
      document.getElementById("txtAuditDateFrom").focus();
      return isbFormValid;
    }

    if (txtAuditDateToValue !== '' && isDate(txtAuditDateToValue) == true) {
      alert("Search Error!. Ending Audit Date is not a valid date!")
      document.getElementById("txtAuditDateTo").focus();
      return isbFormValid;
    }

    var date1 = new Date(txtAuditDateFromValue);
    var date2 = new Date(txtAuditDateToValue);
    if (txtAuditDateFromValue != '' && txtAuditDateToValue != '') {
      if ((date2.getFullYear() - date1.getFullYear()) < 0) {
        alert("Search Error!.  The Begining Audit Date must be less than or equal to the Ending Audit Date!")
        document.getElementById('txtAuditDateToParent').firstChild.style.color = 'red'
        document.getElementById('txtAuditDateFromParent').firstChild.style.color = 'red'
        return isbFormValid;
      }
      else if ((date2.getFullYear() - date1.getFullYear()) == 0) {
        if ((date2.getMonth() - date1.getMonth()) < 0) {
          alert("Search Error!.  The Begining Audit Date must be less than or equal to the Ending Audit Date!")
          document.getElementById('txtAuditDateToParent').firstChild.style.color = 'red'
          document.getElementById('txtAuditDateFromParent').firstChild.style.color = 'red'
          return isbFormValid;
        }
        else if ((date2.getMonth() - date1.getMonth()) == 0) {
          if ((date2.getDate() - date1.getDate()) < 0) {
            alert("Search Error!.  The Begining Audit Date must be less than or equal to the Ending Audit Date!")
            document.getElementById('txtAuditDateToParent').firstChild.style.color = 'red'
            document.getElementById('txtAuditDateFromParent').firstChild.style.color = 'red'
            return isbFormValid;
          }
          else {
            document.getElementById('txtAuditDateToParent').firstChild.style.color = ''
            document.getElementById('txtAuditDateFromParent').firstChild.style.color = ''
          }
        }
        else {
          document.getElementById('txtAuditDateToParent').firstChild.style.color = ''
          document.getElementById('txtAuditDateFromParent').firstChild.style.color = ''
        }
      }
      else {
        document.getElementById('txtAuditDateToParent').firstChild.style.color = ''
        document.getElementById('txtAuditDateFromParent').firstChild.style.color = ''
      }
    }

    if (txtSC95Value !== undefined && txtSC95Value !== null && txtSC95Value !== '' && txtSC95Value.length !== 7) {
      alert("Search Error!. SC95 # must be 7 characters!")
      document.getElementById("txtSC95").focus();
      return isbFormValid;
    }

    if (txt1007Value !== undefined && txt1007Value !== null && txt1007Value !== '' && txt1007Value.length !== 7) {
      alert("Search Error!. 1007 # must be 7 characters!")
      document.getElementById("txt1007").focus();
      return isbFormValid;
    }

    if (txtWhseRcptNumValue !== undefined && txtWhseRcptNumValue !== null && txtWhseRcptNumValue !== '' && txtWhseRcptNumValue.length !== 6) {
      alert("Search Error!. Whse Receipt # must be 6 characters!")
      document.getElementById("txtWhseRcptNum").focus();
      return isbFormValid;
    }

    if (txtTransferValue !== undefined && txtTransferValue !== null && txtTransferValue !== '' && txtTransferValue.length !== 6) {
      alert("Search Error!. Transfer # must be 6 characters!")
      document.getElementById("txtTransfer").focus();
      return isbFormValid;
    }

    if (txtTradeInspValue !== undefined && txtTradeInspValue !== null && txtTradeInspValue !== '' && txtTradeInspValue.length !== 7) {
      alert("Search Error!. Trade Inspection # must be 7 characters!")
      document.getElementById("txtTradeInsp").focus();
      return isbFormValid;
    }

    if (txtTradeSettleValue !== undefined && txtTradeSettleValue !== null && txtTradeSettleValue !== '' && txtTradeSettleValue.length !== 7) {
      alert("Search Error!. Trade Settlement # must be 7 characters!")
      document.getElementById("txtTradeSettle").focus();
      return isbFormValid;
    }

    if (txtAuditUserValue !== undefined && txtAuditUserValue !== null && txtAuditUserValue !== '' && txtAuditUserValue.length !== 7) {
      alert("Search Error!. Audit User # must be 7 characters!")
      document.getElementById("txtAuditUser").focus();
      return isbFormValid;
    }

    if (((txtSC95Value !== undefined && txtSC95Value !== null && txtSC95Value !== '') || (txt1007Value !== undefined && txt1007Value !== null && txt1007Value !== '')) && (txtWhseRcptNumValue != undefined && txtWhseRcptNumValue != null && txtWhseRcptNumValue != '')) {
      alert("Search Error!. Searching by SC95/1007 and Whs Receipt will not return any results!")
      if (txtSC95Value !== undefined && txtSC95Value !== null && txtSC95Value !== '') {
        document.getElementById("txtSC95").focus();
      }
      else {
        document.getElementById("txt1007").focus();
      }
      return isbFormValid;
    }

    if (((txtSC95Value !== undefined && txtSC95Value !== null && txtSC95Value !== '') || (txt1007Value !== undefined && txt1007Value !== null && txt1007Value !== '')) && (txtTransferValue != undefined && txtTransferValue != null && txtTransferValue != '')) {
      alert("Search Error!. Searching by SC95/1007 and Transfer will not return any results!")
      if (txtSC95Value !== undefined && txtSC95Value !== null && txtSC95Value !== '') {
        document.getElementById("txtSC95").focus();
      }
      else {
        document.getElementById("txt1007").focus();
      }
      return isbFormValid;
    }

    if (((txtSC95Value !== undefined && txtSC95Value !== null && txtSC95Value !== '') || (txt1007Value !== undefined && txt1007Value !== null && txt1007Value !== '')) && ((txtTradeInspValue !== undefined && txtTradeInspValue !== null && txtTradeInspValue !== '') || (txtTradeSettleValue !== undefined && txtTradeSettleValue !== null && txtTradeSettleValue !== ''))) {
      alert("Search Error!. Searching by SC95/1007 and Trade Settlment will not return any results!")
      if (txtSC95Value !== undefined && txtSC95Value !== null && txtSC95Value !== '') {
        document.getElementById("txtSC95").focus();
      }
      else {
        document.getElementById("txt1007").focus();
      }
      return isbFormValid;
    }

    if (((txtSC95Value !== undefined && txtSC95Value !== null && txtSC95Value !== '') || (txt1007Value !== undefined && txt1007Value !== null && txt1007Value !== '')) && (txtAuditUserValue != undefined && txtAuditUserValue != null && txtAuditUserValue != '')) {
      alert("Search Error!. Searching by SC95/1007 and Audit User not return any results!")
      if (txtSC95Value !== undefined && txtSC95Value !== null && txtSC95Value !== '') {
        document.getElementById("txtSC95").focus();
      }
      else {
        document.getElementById("txt1007").focus();
      }
      return isbFormValid;
    }

    if (txtWhseRcptNumValue != undefined && txtWhseRcptNumValue != null && txtWhseRcptNumValue != '' && txtTransferValue != undefined && txtTransferValue != null && txtTransferValue != '') {
      alert("Search Error!. Searching by Whs Receipt and Transfer will not return any results!")
      document.getElementById("txtWhseRcptNum").focus();
      return isbFormValid;
    }

    if (
      txtWhseRcptNumValue != undefined && txtWhseRcptNumValue != null && txtWhseRcptNumValue != '' &&
      ((txtTradeInspValue != undefined && txtTradeInspValue != null && txtTradeInspValue != '') || (txtTradeSettleValue != undefined && txtTradeSettleValue != null && txtTradeSettleValue != ''))
    ) {
      alert("Search Error!. Searching by Whs Receipt and Trade Settlment will not return any results!")
      document.getElementById("txtWhseRcptNum").focus();
      return isbFormValid;
    }

    if (txtWhseRcptNumValue != undefined && txtWhseRcptNumValue != null && txtWhseRcptNumValue != '' && txtAuditUserValue != undefined && txtAuditUserValue != null && txtAuditUserValue != '') {
      alert("Search Error!. Searching by Whs Receipt and Audit User will not return any results!")
      document.getElementById("txtWhseRcptNum").focus();
      return isbFormValid;
    }

    if (
      (txtTransferValue != undefined && txtTransferValue != null && txtTransferValue != '') &&
      ((txtTradeInspValue != undefined && txtTradeInspValue != null && txtTradeInspValue != '') ||
        (txtTradeSettleValue != undefined && txtTradeSettleValue != null && txtTradeSettleValue != ''))
    ) {
      alert("Search Error!. Searching by Transfer and Trade Settlment will not return any results!")
      document.getElementById("txtTransfer").focus();
      return isbFormValid;
    }

    if (
      (txtTransferValue != undefined && txtTransferValue != null && txtTransferValue != '') &&
      (txtAuditUserValue != undefined && txtAuditUserValue != null && txtAuditUserValue != '')
    ) {
      alert("Search Error!. Searching by Transfer and Audit User will not return any results!")
      document.getElementById("txtTransfer").focus();
      return isbFormValid;
    }

    if (
      ((txtTradeInspValue != undefined && txtTradeInspValue != null && txtTradeInspValue != '') ||
        (txtTradeSettleValue != undefined && txtTradeSettleValue != null && txtTradeSettleValue != '')) &&
      (txtAuditUserValue != undefined && txtAuditUserValue != null && txtAuditUserValue != '')
    ) {
      if (txtTradeInspValue != undefined && txtTradeInspValue != null && txtTradeInspValue != '') {
        document.getElementById("txtTradeInsp").focus();
      } else {
        document.getElementById("txtTradeSettle").focus();
      }
      return isbFormValid;
    }

    isbFormValid = true
    return isbFormValid
  }

  const onbtnViewClick = () => {
    try {
      setLoading(true)
      let selectedRowValue = thisObj.state.gridAuditHistory1.selected[0]
      if (selectedRowValue == undefined || selectedRowValue == null || selectedRowValue == '') {
        showMessage(thisObj, "Invalid record selected. Select a valid row and try again!")
        return;
      }
      else {
        setData(thisObj, 'ManualDPRInvHistSearchData', selectedRowValue)
        goTo(thisObj, "SystemMaintenanceApplicationSupport#ManualDPRInvHistView#DEFAULT#true#Click")
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnViewClick = onbtnViewClick;

  const onbtnSearchClick = async () => {
    try {
      setLoading(true)
      let isbFormValid = false
      await bFormValid().then(response => {
        isbFormValid = response
      })
      if (isbFormValid == false) {
        return;
      }
      else {
        await DoSearch()
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const onbtmExitClick = async () => {
    document.getElementById("SystemMaintenanceApplicationSupport_ManualDPRInvHistSearchPopUp").childNodes[0].click()
  }
  thisObj.onbtmExitClick = onbtmExitClick;

  const onddBuyPtChange = async () => {
    try {
      setLoading(true)
      await bFillWhsBinList()
      await bFillVarietyList()
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddBuyPtChange = onddBuyPtChange;

  const onddTypeChange = async () => {
    try {
      setLoading(true)
      await bFillVarietyList()
      await bFillGenerationList()
      await bFillSegmentList()
      await bFillOleicList()
      await bFillOrganicList()
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddTypeChange = onddTypeChange;

  const Form_Load = async () => {
    try {
      setLoading(true)
      let strXML = []

      await bFillBuyPtList()
      await bFillTypeList()

      await bFillWhsBinList()
      await bFillVarietyList()
      await bFillGenerationList()
      await bFillSegmentList()
      await bFillOleicList()
      await bFillOrganicList()

      await ContractManagementService.RetrieveAccessPermissionDetails('PN0320', null, 'I', null).then(response => {
        strXML = response
      })
      if (strXML != undefined && strXML != null && strXML.length > 0) {
        if (strXML[0].permission != 'Y') {
          disable(thisObj, 'btnSearch')
        }
      }

      disable(thisObj, 'btnView')
      disable(thisObj, 'btnExportToExcel')
      disable(thisObj, 'btnPrint')
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally{
      setLoading(false)
    }
  }

  const ontxt1007Blur = () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txt1007')
      setData(thisObj, 'txt1007Set', Value)
      if (Value != undefined && Value != null && Value != '' && Value.length > 0 && Value.length < 7) {
        Value = '0'.repeat(7 - Value.length) + Value
        setValue(thisObj, 'txt1007', Value)
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxt1007Blur = ontxt1007Blur;

  const ontxt1007Change = async () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txt1007')
      if (Value != undefined && Value != null && Value != '') {
        setValue(thisObj, 'txt1007', validateTextBox(Value))
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxt1007Change = ontxt1007Change;

  const ontxtWhseRcptNumBlur = async () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtWhseRcptNum')
      setData(thisObj, 'txtWhseRcptNumSet', Value)
      if (Value != undefined && Value != null && Value != '' && Value.length > 0 && Value.length < 6) {
        Value = '0'.repeat(6 - Value.length) + Value
        setValue(thisObj, 'txtWhseRcptNum', Value)
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtWhseRcptNumBlur = ontxtWhseRcptNumBlur;

  const ontxtWhseRcptNumChange = async () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtWhseRcptNum')
      if (Value != undefined && Value != null && Value != '') {
        setValue(thisObj, 'txtWhseRcptNum', validateTextBox(Value))
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtWhseRcptNumChange = ontxtWhseRcptNumChange;

  const validateTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
        res += data[i]
      }
    }
    return res;
  }

  const ontxtAuditUserBlur = () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtAuditUser')
      if (Value != undefined && Value != null && Value != '' && Value.length != 7) {
        document.getElementById('txtAuditUser').style.color = "red"
      }
      else {
        document.getElementById('txtAuditUser').style.color = ""
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtAuditUserBlur = ontxtAuditUserBlur;

  const ontxtSC95Blur = () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtSC95')
      if (Value != undefined && Value != null && Value != '' && Value.length != 7) {
        document.getElementById('txtSC95').style.color = "red"
      }
      else {
        document.getElementById('txtSC95').style.color = ""
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtSC95Blur = ontxtSC95Blur;

  const ontxtTradeInspBlur = () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtTradeInsp')
      if (Value != undefined && Value != null && Value != '' && Value.length != 7) {
        document.getElementById('txtTradeInsp').style.color = "red"
      }
      else {
        document.getElementById('txtTradeInsp').style.color = ""
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtTradeInspBlur = ontxtTradeInspBlur;

  const ontxtTradeSettleBlur = () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtTradeSettle')
      if (Value != undefined && Value != null && Value != '' && Value.length != 7) {
        document.getElementById('txtTradeSettle').style.color = "red"
      }
      else {
        document.getElementById('txtTradeSettle').style.color = ""
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtTradeSettleBlur = ontxtTradeSettleBlur;

  const ontxtTransferBlur = () => {
    try {
      setLoading(true)
      let Value = getValue(thisObj, 'txtTransfer')
      if (Value != undefined && Value != null && Value != '' && Value.length != 6) {
        document.getElementById('txtTransfer').style.color = "red"
      }
      else {
        document.getElementById('txtTransfer').style.color = ""
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtTransferBlur = ontxtTransferBlur;

  const ongridAuditHistory1Dblclick = () => {
    onbtnViewClick()
  }
  thisObj.ongridAuditHistory1Dblclick = ongridAuditHistory1Dblclick;

  const ontxtAuditDateFromBlur = () => {
    try {
      setLoading(true)
      let txtAuditDateFromValue = getValue(thisObj, 'txtAuditDateFrom') == undefined || getValue(thisObj, 'txtAuditDateFrom') == null || getValue(thisObj, 'txtAuditDateFrom') == '' ? '' : moment(getValue(thisObj, 'txtAuditDateFrom')).format('MM/DD/YYYY')
      let txtAuditDateToValue = getValue(thisObj, 'txtAuditDateTo') == undefined || getValue(thisObj, 'txtAuditDateTo') == null || getValue(thisObj, 'txtAuditDateTo') == '' ? '' : moment(getValue(thisObj, 'txtAuditDateTo')).format('MM/DD/YYYY')

      if (txtAuditDateFromValue == '') {
        return;
      }

      if (isDate(txtAuditDateFromValue) == true) {
        document.getElementById('txtAuditDateFrom').style.color = "red"
        return
      }
      else {
        document.getElementById('txtAuditDateFrom').style.color = ""
      }

      if (isDate(txtAuditDateToValue) == true) {
        return
      }

      var date1 = new Date(txtAuditDateFromValue);
      var date2 = new Date(txtAuditDateToValue);
      if (txtAuditDateFromValue != '' && txtAuditDateToValue != '') {
        if ((date2.getFullYear() - date1.getFullYear()) < 0) {
          document.getElementById('txtAuditDateFrom').style.color = "red"
          document.getElementById('txtAuditDateTo').style.color = "red"
        }
        else if ((date2.getFullYear() - date1.getFullYear()) == 0) {
          if ((date2.getMonth() - date1.getMonth()) < 0) {
            document.getElementById('txtAuditDateFrom').style.color = "red"
            document.getElementById('txtAuditDateTo').style.color = "red"
          }
          else if ((date2.getMonth() - date1.getMonth()) == 0) {
            if ((date2.getDate() - date1.getDate()) < 0) {
              document.getElementById('txtAuditDateFrom').style.color = "red"
              document.getElementById('txtAuditDateTo').style.color = "red"
            }
            else {
              document.getElementById('txtAuditDateFrom').style.color = ""
              document.getElementById('txtAuditDateTo').style.color = ""
            }
          }
          else {
            document.getElementById('txtAuditDateFrom').style.color = ""
            document.getElementById('txtAuditDateTo').style.color = ""
          }
        }
        else {
          document.getElementById('txtAuditDateFrom').style.color = ""
          document.getElementById('txtAuditDateTo').style.color = ""
        }
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtAuditDateFromBlur = ontxtAuditDateFromBlur;

  const ontxtAuditDateToBlur = () => {
    try {
      setLoading(true)
      let txtAuditDateFromValue = getValue(thisObj, 'txtAuditDateFrom') == undefined || getValue(thisObj, 'txtAuditDateFrom') == null || getValue(thisObj, 'txtAuditDateFrom') == '' ? '' : moment(getValue(thisObj, 'txtAuditDateFrom')).format('MM/DD/YYYY')
      let txtAuditDateToValue = getValue(thisObj, 'txtAuditDateTo') == undefined || getValue(thisObj, 'txtAuditDateTo') == null || getValue(thisObj, 'txtAuditDateTo') == '' ? '' : moment(getValue(thisObj, 'txtAuditDateTo')).format('MM/DD/YYYY')

      if (txtAuditDateToValue == '') {
        return
      }

      if (isDate(txtAuditDateToValue) == true) {
        document.getElementById('txtAuditDateTo').style.color = "red"
        return
      }
      else {
        document.getElementById('txtAuditDateTo').style.color = ""
      }

      if (isDate(txtAuditDateFromValue) == true) {
        document.getElementById('txtAuditDateFrom').style.color = "red"
        return
      }
      else {
        document.getElementById('txtAuditDateFrom').style.color = ""
      }

      var date1 = new Date(txtAuditDateFromValue);
      var date2 = new Date(txtAuditDateToValue);
      if (txtAuditDateFromValue != '' && txtAuditDateToValue != '') {
        if ((date2.getFullYear() - date1.getFullYear()) < 0) {
          document.getElementById('txtAuditDateFrom').style.color = "red"
          document.getElementById('txtAuditDateTo').style.color = "red"
        }
        else if ((date2.getFullYear() - date1.getFullYear()) == 0) {
          if ((date2.getMonth() - date1.getMonth()) < 0) {
            document.getElementById('txtAuditDateFrom').style.color = "red"
            document.getElementById('txtAuditDateTo').style.color = "red"
          }
          else if ((date2.getMonth() - date1.getMonth()) == 0) {
            if ((date2.getDate() - date1.getDate()) < 0) {
              document.getElementById('txtAuditDateFrom').style.color = "red"
              document.getElementById('txtAuditDateTo').style.color = "red"
            }
            else {
              document.getElementById('txtAuditDateFrom').style.color = ""
              document.getElementById('txtAuditDateTo').style.color = ""
            }
          }
          else {
            document.getElementById('txtAuditDateFrom').style.color = ""
            document.getElementById('txtAuditDateTo').style.color = ""
          }
        }
        else {
          document.getElementById('txtAuditDateFrom').style.color = ""
          document.getElementById('txtAuditDateTo').style.color = ""
        }
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtAuditDateToBlur = ontxtAuditDateToBlur;

  const NumberFormatting = (Value) =>{
    let Digit = 0.0000

    if(Value != undefined && Value != ''){
      Digit = Digit + Number(Value)
      return Digit.toFixed(4)
    }
    else{
      return Value
    }
  }
  
  const onbtnExportToExcelClick = () => {
    try {
      setLoading(true)
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, "gridAuditHistory1")
      var fileName = "Audit_Hist_of_Inventory_Adjs"
      const arrayData = []


      if (excelData.length > 0) {
        for (var i = 0; i < excelData.length; i++) {
          var obj = {
            "Record ID": ExcelNumericDataConvert(excelData[i].txtcolRecordID),
            "Action": excelData[i].txtcolAction,
            "Old Posting Date": excelData[i].txtcolOldPostingDt,
            "New Posting Date": excelData[i].txtcolNewPostingDt,
            "Buy Pt": excelData[i].txtcolBuyPt,
            "Old Whs/Bin": excelData[i].txtcolOldWhsBin,
            "New Whs/Bin": excelData[i].txtcolNewWhsBin,
            "Old Pnut Type": excelData[i].txtcolOldPnutType,
            "New Pnut Type": excelData[i].txtcolNewPnutType,
            "Old Pnut Variety": excelData[i].txtcolOldPnutVariety,
            "New Pnut Variety": excelData[i].txtcolNewPnutVariety,
            "Old Seed": excelData[i].txtcolOldPnutSeed,
            "New Seed": excelData[i].txtcolNewPnutSeed,
            "Old Seg": ExcelNumericDataConvert(excelData[i].txtcolOldPnutSeg),
            "New Seg": ExcelNumericDataConvert(excelData[i].txtcolNewPnutSeg),
            "Old Oleic": excelData[i].txtcolOldPnutOleic,
            "New Oleic": excelData[i].txtcolNewPnutOleic,
            "Old Organic": excelData[i].txtcolOldPnutOrganic,
            "New Organic": excelData[i].txtcolNewPnutOrganic,
            "Old Physical Adj": NumberFormatting(excelData[i].txtcolOldPhysicalAdj),
            "New Physical Adj": NumberFormatting(excelData[i].txcolNewPhysicalAdj),
            "Old Whs Receipt Adj": NumberFormatting(excelData[i].txtcolOldWhsRcptAdj),
            "New Whs Receipt Adj": NumberFormatting(excelData[i].txtcolNewReceiptAdj),
            "Old Shrink Adj": NumberFormatting(excelData[i].txtcolOldShrinkAdj),
            "New Shrink Adj": NumberFormatting(excelData[i].txtcolNewShrinkAdj),
            "Old Not Receipted Adj": NumberFormatting(excelData[i].txtcolOldNotRcptdAdj),
            "New Not Receipted Adj": NumberFormatting(excelData[i].txtcolNewNotRcptdAdj),
            "Old Undetermined Adj": NumberFormatting(excelData[i].txtcolOldUndetrminedAdj),
            "New Undetermined Adj": NumberFormatting(excelData[i].txtcolNewUndeterminedAdj),
            "Old Comp Owned Adj": NumberFormatting(excelData[i].txtcolOldCompOwnedAdj),
            "New Comp Owned Adj": NumberFormatting(excelData[i].txtcolNewCompOwnedAdj),
            "Old Intransit Adj": NumberFormatting(excelData[i].txtcolOldIntransitAdj),
            "New Intransit Adj": NumberFormatting(excelData[i].txtcolNewIntransitAdj),
            "Adjustment Source": excelData[i].txtcolAdjustmentSource,
            "Chg Maint User": excelData[i].txtcolChgMaintUser,
            "Chg Maint Date": excelData[i].txtcolChgMaintDate,
            "Chg Maint Comment": excelData[i].txtcolChgMainComment,
          }

          arrayData.push(obj);
          obj = {}

        }
      }

      const ws = XLSX.utils.json_to_sheet(arrayData)
      var wscols = [
        { width: 0 },
        { width: 10 },
        { width: 0 },
        { width: 0 },
        { width: 10 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 0 },
        { width: 20 },
        { width: 15 },
        { width: 15 },
        { width: 20 },
      ];
      ws['!cols'] = wscols;
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });

      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;


  const onbtnPrintClick = () => {
    try {
      setLoading(true)
      var data = getValue(thisObj, "gridAuditHistory1")

      if (data != undefined && data != null && data.length > 0) {
        var res = []
        for (var i = 0; i < data.length; i++) {
          res.push([
            data[i].txtcolAction, data[i].txtcolBuyPt, data[i].txtcolAdjustmentSource, data[i].txtcolChgMaintUser, data[i].txtcolChgMaintDate, data[i].txtcolChgMainComment,
          ])
        }

        const addFooters = obj => {
          const pageCount = obj.internal.getNumberOfPages()
          let sDocName = CompName + " - " + cropYearFromLS() + " " + 'Audit History of Inventory Adjusments'
          obj.setFontSize(8)
          for (var i = 1; i <= pageCount; i++) {
            obj.setPage(i)
            obj.text(sDocName, 12, 285);
            obj.text(190, 285, String(i))
          }
        }

        var obj = new jsPDF();
        obj.autoTable({
          startY: 5,
          startX: 20,
          headStyles: { fontSize: 8, lineWidth: 0.2, backgroundcolor: 'grey' },
          styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              "Action", "Buy Pt", 'Adjustment Source', 'Chg Maint User', 'Chg Maint Date', 'Chg Maint Comment'
            ]
          ]
        });

        addFooters(obj)

        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            return;
          }
        }
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const ontxt1007Focus = () =>{
    let Value = getData(thisObj, 'txt1007Set')
    if(Value != undefined && Value != null && Value != ''){
      setValue(thisObj, 'txt1007', Value)
    }
  }
  thisObj.ontxt1007Focus = ontxt1007Focus;

  const ontxtWhseRcptNumFocus = () =>{
    let Value = getData(thisObj, 'txtWhseRcptNumSet')
    if(Value != undefined && Value != null && Value != ''){
      setValue(thisObj, 'txtWhseRcptNum', Value)
    }
  }
  thisObj.ontxtWhseRcptNumFocus = ontxtWhseRcptNumFocus;

  const ontxtTransferFocus = () =>{
    document.getElementById('txtTransfer').style.color = ''
  }
  thisObj.ontxtTransferFocus = ontxtTransferFocus;

  const ontxtSC95Focus = () =>{
    document.getElementById('txtSC95').style.color = ''
  }
  thisObj.ontxtSC95Focus = ontxtSC95Focus;

  const ontxtTradeInspFocus = () =>{
    document.getElementById('txtTradeInsp').style.color = ''
  }
  thisObj.ontxtTradeInspFocus = ontxtTradeInspFocus;

  const ontxtTradeSettleFocus = () =>{
    document.getElementById('txtTradeSettle').style.color = ''
  }
  thisObj.ontxtTradeSettleFocus = ontxtTradeSettleFocus;

  const ontxtAuditUserFocus = () =>{
    document.getElementById('txtAuditUser').style.color = ''
  }
  thisObj.ontxtAuditUserFocus = ontxtAuditUserFocus;
  
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ManualDPRInvHistSearch*/}

              {/* END_USER_CODE-USER_BEFORE_ManualDPRInvHistSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxManualDPRInvHistSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxManualDPRInvHistSearch*/}

              <GroupBoxWidget
                conf={state.grpbxManualDPRInvHistSearch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxBuyPtDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxBuyPtDetails*/}

                <GroupBoxWidget
                  conf={state.grpbxBuyPtDetails}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_ddBuyPt*/}

                  {/* END_USER_CODE-USER_BEFORE_ddBuyPt*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddBuyPt}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddBuyPt*/}

                  {/* END_USER_CODE-USER_AFTER_ddBuyPt*/}
                  {/* START_USER_CODE-USER_BEFORE_ddWhseBin*/}

                  {/* END_USER_CODE-USER_BEFORE_ddWhseBin*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddWhseBin}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddWhseBin*/}

                  {/* END_USER_CODE-USER_AFTER_ddWhseBin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAuditDateFrom*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAuditDateFrom*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAuditDateFrom}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAuditDateFrom*/}

                  {/* END_USER_CODE-USER_AFTER_txtAuditDateFrom*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAuditDateTo*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAuditDateTo*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAuditDateTo}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAuditDateTo*/}

                  {/* END_USER_CODE-USER_AFTER_txtAuditDateTo*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSC95*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSC95*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSC95}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSC95*/}

                  {/* END_USER_CODE-USER_AFTER_txtSC95*/}
                  {/* START_USER_CODE-USER_BEFORE_txt1007*/}

                  {/* END_USER_CODE-USER_BEFORE_txt1007*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txt1007}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txt1007*/}

                  {/* END_USER_CODE-USER_AFTER_txt1007*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWhseRcptNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWhseRcptNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWhseRcptNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWhseRcptNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtWhseRcptNum*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxBuyPtDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxBuyPtDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxTransferDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxTransferDetails*/}

                <GroupBoxWidget
                  conf={state.grpbxTransferDetails}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_txtTransfer*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTransfer*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTransfer}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTransfer*/}

                  {/* END_USER_CODE-USER_AFTER_txtTransfer*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTradeInsp*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTradeInsp*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTradeInsp}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTradeInsp*/}

                  {/* END_USER_CODE-USER_AFTER_txtTradeInsp*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTradeSettle*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTradeSettle*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTradeSettle}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTradeSettle*/}

                  {/* END_USER_CODE-USER_AFTER_txtTradeSettle*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAuditUser*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAuditUser*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAuditUser}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAuditUser*/}

                  {/* END_USER_CODE-USER_AFTER_txtAuditUser*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxTransferDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxTransferDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxPnutDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPnutDetails*/}

                <GroupBoxWidget
                  conf={state.grpbxPnutDetails}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblPeanut*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPeanut*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPeanut}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPeanut*/}

                  {/* END_USER_CODE-USER_AFTER_lblPeanut*/}
                  {/* START_USER_CODE-USER_BEFORE_ddType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddType*/}

                  {/* END_USER_CODE-USER_AFTER_ddType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddVariety*/}

                  {/* END_USER_CODE-USER_BEFORE_ddVariety*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddVariety}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddVariety*/}

                  {/* END_USER_CODE-USER_AFTER_ddVariety*/}
                  {/* START_USER_CODE-USER_BEFORE_ddGeneration*/}

                  {/* END_USER_CODE-USER_BEFORE_ddGeneration*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddGeneration}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddGeneration*/}

                  {/* END_USER_CODE-USER_AFTER_ddGeneration*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeg}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOleic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOrganic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOrganic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOrganic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOrganic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOrganic*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPnutDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPnutDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxAuditHistory*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxAuditHistory*/}

                <GroupBoxWidget
                  conf={state.grpbxAuditHistory}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblAuditHistOfInvAdj*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAuditHistOfInvAdj*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAuditHistOfInvAdj}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAuditHistOfInvAdj*/}

                  {/* END_USER_CODE-USER_AFTER_lblAuditHistOfInvAdj*/}
                  {/* START_USER_CODE-USER_BEFORE_btnView*/}

                  {/* END_USER_CODE-USER_BEFORE_btnView*/}

                  <ButtonWidget
                    conf={state.btnView}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnView*/}

                  {/* END_USER_CODE-USER_AFTER_btnView*/}
                  {/* START_USER_CODE-USER_BEFORE_gridAuditHistory1*/}

                  {/* END_USER_CODE-USER_BEFORE_gridAuditHistory1*/}

                  <GridWidget
                    conf={state.gridAuditHistory1}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridAuditHistory1}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridAuditHistory1*/}

                  {/* END_USER_CODE-USER_AFTER_gridAuditHistory1*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxAuditHistory*/}

                {/* END_USER_CODE-USER_AFTER_grpbxAuditHistory*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxManualDPRInvHistSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxManualDPRInvHistSearch*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxPrintExport*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPrintExport*/}

                <GroupBoxWidget
                  conf={state.grpbxPrintExport}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                  <ButtonWidget
                    conf={state.btnPrint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                  {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                  {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  <ButtonWidget
                    conf={state.btnExportToExcel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPrintExport*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPrintExport*/}
                {/* START_USER_CODE-USER_BEFORE_btmExit*/}

                {/* END_USER_CODE-USER_BEFORE_btmExit*/}

                <ButtonWidget
                  conf={state.btmExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btmExit*/}

                {/* END_USER_CODE-USER_AFTER_btmExit*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ManualDPRInvHistSearch*/}

              {/* END_USER_CODE-USER_AFTER_ManualDPRInvHistSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_ManualDPRInvHistSearch
);
