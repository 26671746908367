/* eslint-disable*/
import React from "react";
import ParentWidget from "../ParentWidget";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { convertStringToJsonObject } from "../../../../common/ScreenInitialization";
import "./DropDownWidget.scss";
import {checkInvalid,getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility,getTooltip,getWidgetLabel} from "../ParentWidgetFunc";

function DropDownWidget(props){
  let labelWidth = props.conf.labelWidth || 4;
  let conf = props.conf;
  let screenConf = props.screenConf;
    let value = props.values[conf.name];
    let title = conf.SpecialFirstOption;
    if (!conf.valueList || !conf.valueList.length) {
      conf.valueList = convertStringToJsonObject(conf.Options);
    }

    let selVal = conf.valueList.filter(
      valueObj => valueObj.key + "" === value
    );
    if (selVal.length) {
      title = selVal[0].description;
    }
    function truncate(str) {
      if(conf.optionMaxLength){
        return str.length > conf.optionMaxLength ? str.substring(0,conf.optionMaxLength-3) + "..." : str;
      }
      return str;
    }
	const onSearch = (event) =>{
    // conf.valueList.
      let arr='';
      let filteredArr=[]
      if(event.key){
        (conf.valueList).map((value)=>{
          arr = (value.description).toLowerCase();
         if( arr.startsWith(event.key)){
          let val = value.key
          filteredArr.push(val)
          let k = document.getElementById('ddItem_'+ value.key);
          if(k)
          k.selectedIndex=-1;
            // k.scrollIntoView(true);
            event = { 
              ...event,
              type: "change",
              target: {
                id: conf.name,
                name: conf.name,
                value: filteredArr[0]
              }
            }
            props.onChange(event);
            const selectedValue = document.getElementById('ddItem_'+ filteredArr[0])
            if(selectedValue) selectedValue.focus()
         } 
        })
      }
    }
    let ddControl = (
      <React.Fragment>
        <DropdownButton
          data-testid={conf.name}
          size={screenConf.horizontalForm ? "" : "sm"}
          id={conf.name}
          title={truncate(title)}
          variant="secondary"
          name={conf.name}
          disabled={!conf.Enabled}
          readOnly={conf.ReadOnly}
          onKeyDown={onSearch}
          className={checkInvalid(props,conf) ? "dd-invalid" : ""}
          onBlur={() => {
            let event = {
              type: "blur",
              target: {
                id: conf.name,
                name: conf.name
              }
            };
            props.onBlur(event);
          }}
          onClick={() => {
            const elementId = `ddItem_${value}`;
            const element = document.getElementById(elementId);
            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "nearest" });
              setTimeout(() => { element.focus() }, 0);
            }
          }}
        >
          {conf.valueList.map(dd => (
            <Dropdown.Item
              eventKey={dd.key}
			        id={'ddItem_'+ dd.key}
              onSelect={eventKey => {
                let event = {
                  type: "change",
                  target: {
                    id: conf.name,
                    name: conf.name,
                    value: eventKey
                  }
                };
                props.onChange(event);
              }}
              key={"option" + dd.key}
              active = {conf.ChangeEvent!=undefined ? conf.ChangeEvent.target.value == dd.key : false}
            >
              {dd.description}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <Form.Control.Feedback
          className={checkInvalid(props,conf) ? "d-block" : "d-none"}
          type="invalid"
          aria-live="polite" role="alert"
        >
          {props.errors[conf.name]}
        </Form.Control.Feedback>
      </React.Fragment>
    );

    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
        title={getTooltip(conf)}
        role="tooltip"
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
            onClick={event => event.preventDefault()}
          >
            {getWidgetLabel(conf)}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>{ddControl}</Col>
          ) : (
            <React.Fragment> {ddControl}</React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default DropDownWidget;
