import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import NumberFormat from "react-number-format";
import {getPlaceholder,checkInvalid,getInputType,getTextBoxClasses,getWidgetClasses,getFieldContentClasses,getFieldContentStyle,getVisibility,
  getTooltip} from "../ParentWidgetFunc";

function SpinnerWidget(props) {
  let conf = props.conf;
  let screenConf = props.screenConf;
    let textControl = (
      <React.Fragment>
        <Form.Control
          placeholder={getPlaceholder(conf)}
          size={screenConf.horizontalForm ? "" : "sm"}
          autoComplete={conf.AutoCompleteForTextBox}
          disabled={!conf.Enabled}
          readOnly={conf.ReadOnly}
          type={getInputType(conf)}
          className={getTextBoxClasses(conf)}
          name={conf.name}
          value={props.values[conf.name]}
          onChange={props.onChange}
          onBlur={props.onBlur}
          isInvalid={checkInvalid(props,conf)}
          aria-label="Please use the up and down arrow key to change the value"
          data-testid={conf.name}
        />
        <Form.Control.Feedback type="invalid" aria-live="polite" role="alert">
          {props.errors[conf.name]}
        </Form.Control.Feedback>
      </React.Fragment>
    );

    if (conf.formattingReqd) {
      textControl = (
        <React.Fragment>
          <Form.Control
            isNumericString={true}
            thousandSeparator={true}
            placeholder={getPlaceholder(conf)}
            size={screenConf.horizontalForm ? "" : "sm"}
            autoComplete={conf.AutoCompleteForTextBox}
            disabled={!conf.Enabled}
            readOnly={conf.ReadOnly}
            type={getInputType(conf)}
            className={getTextBoxClasses(conf)}
            name={conf.name}
            value={props.values[conf.name]}
            onChange={e => {
              let value = e.target.value;
              e.target.value = value.replace(new RegExp(",", "g"), "");
              props.onChange(e);
            }}
            onBlur={props.onBlur}
            isInvalid={checkInvalid(props,conf)}
            customInput={Form.Control}
            as={NumberFormat}
            data-testid={conf.name}
          />
          <Form.Control.Feedback type="invalid" aria-live="polite" role="alert">
            {props.errors[conf.name]}
          </Form.Control.Feedback>
        </React.Fragment>
      );
    }

    return (
      <Form.Group
        className={
          getWidgetClasses(conf,screenConf).join(" ") + " d-flex align-items-end"
        }
        controlId={conf.name}
		title={getTooltip(conf)}
		role="tooltip"
      >
        <div
          className={getFieldContentClasses(screenConf).join(" ")}
          style={getFieldContentStyle(conf)}
        >
          <Form.Label
            className={screenConf.horizontalForm ? "pt-1" : ""}
            style={getVisibility(conf,screenConf)}
          >
            {conf.Label}
          </Form.Label>
          {screenConf.horizontalForm ? (
            <Col>{textControl}</Col>
          ) : (
            <React.Fragment> {textControl}</React.Fragment>
          )}
        </div>
      </Form.Group>
    );
  
}

export default SpinnerWidget;
