/* eslint-disable*/
import React from "react";
import ContractManagement_ContractGroupingProfile from "./ContractGroupingProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ContractGroupingProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractGroupingProfile />);
    });
  });
  afterEach(cleanup);
  test("is ContractGroupingProfile Loads Successfully", () => {
    expect(screen.getByText("Contract Grouping Profile - Test"))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for ContractGroupingProfile", () => {
    // START_USER_CODE-USER_ContractGroupingProfile_Custom_Test_Case
    // END_USER_CODE-USER_ContractGroupingProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractGroupingProfile />);
    });
  });
  afterEach(cleanup);
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("ContractManagement:ContractGroupingProfile_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("ContractManagement:ContractGroupingProfile_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("gridCntrctShr_btnPopup(Grid Widget) Test Cases", async () => {
    let gridCntrctShr_btnPopup = screen.getByTestId("gridCntrctShr");
    let gridCntrctShr_btnPopupbtn =
      gridCntrctShr_btnPopup.nextElementSibling.firstElementChild;
    gridCntrctShr_btnPopup =
      gridCntrctShr_btnPopup.parentElement.parentElement.parentElement;
    expect(gridCntrctShr_btnPopup.tagName).toBe("DIV");
    expect(gridCntrctShr_btnPopup.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGroupingProfile_gridCntrctShr")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for btnPopup", () => {
    // START_USER_CODE-USER_btnPopup_TEST
    // END_USER_CODE-USER_btnPopup_TEST
  });
  test("btnSearchforCntrcts(Button Widget) Test Cases", async () => {
    const btnSearchforCntrcts = screen.getByTestId("btnSearchforCntrcts");
    expect(btnSearchforCntrcts).toBeInTheDocument;
    expect(btnSearchforCntrcts.textContent).toEqual(
      t("ContractManagement:ContractGroupingProfile_btnSearchforCntrcts")
    );
  });
  test("Custom Test Cases for btnSearchforCntrcts", () => {
    // START_USER_CODE-USER_btnSearchforCntrcts_TEST
    // END_USER_CODE-USER_btnSearchforCntrcts_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("ContractManagement:ContractGroupingProfile_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("date(Date Widget) Test Cases", async () => {
    const date = screen.getByTestId("date");
    expect(date.tagName).toBe("INPUT");
    expect(date.type).toBe("text");
    expect(date.classList).toContain("datetimepicker-input");
    expect(date.parentElement.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractGroupingProfile_date")
    );
    await act(async () => {
      userEvent.click(date.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for date", () => {
    // START_USER_CODE-USER_date_TEST
    // END_USER_CODE-USER_date_TEST
  });
  test("dateFormat(Label Widget) Test Cases", async () => {
    const dateFormat = screen.getByTestId("dateFormat");
    expect(dateFormat.tagName).toBe("LABEL");
    expect(dateFormat.classList).toContain("form-label");
    expect(dateFormat.textContent).toEqual(
      t("ContractManagement:ContractGroupingProfile_dateFormat")
    );
  });
  test("Custom Test Cases for dateFormat", () => {
    // START_USER_CODE-USER_dateFormat_TEST
    // END_USER_CODE-USER_dateFormat_TEST
  });
  test("gridCntrctShr(Grid Widget) Test Cases", async () => {
    let gridCntrctShr = screen.getByTestId("gridCntrctShr");
    let gridCntrctShrbtn = gridCntrctShr.nextElementSibling.firstElementChild;
    gridCntrctShr = gridCntrctShr.parentElement.parentElement.parentElement;
    expect(gridCntrctShr.tagName).toBe("DIV");
    expect(gridCntrctShr.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGroupingProfile_gridCntrctShr")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridCntrctShr", () => {
    // START_USER_CODE-USER_gridCntrctShr_TEST
    // END_USER_CODE-USER_gridCntrctShr_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCntrctGrpPrfl(GroupBox Widget) Test Cases", async () => {
    const grpbxCntrctGrpPrfl = screen.getByTestId("grpbxCntrctGrpPrfl");
    expect(grpbxCntrctGrpPrfl.tagName).toBe("BUTTON");
    expect(grpbxCntrctGrpPrfl.type).toBe("button");
    expect(grpbxCntrctGrpPrfl.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCntrctGrpPrfl", () => {
    // START_USER_CODE-USER_grpbxCntrctGrpPrfl_TEST
    // END_USER_CODE-USER_grpbxCntrctGrpPrfl_TEST
  });
  test("grpbxGrouping(GroupBox Widget) Test Cases", async () => {
    const grpbxGrouping = screen.getByTestId("grpbxGrouping");
    expect(grpbxGrouping.tagName).toBe("BUTTON");
    expect(grpbxGrouping.type).toBe("button");
    expect(grpbxGrouping.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGrouping", () => {
    // START_USER_CODE-USER_grpbxGrouping_TEST
    // END_USER_CODE-USER_grpbxGrouping_TEST
  });
  test("grpbxSearchCntrcts(GroupBox Widget) Test Cases", async () => {
    const grpbxSearchCntrcts = screen.getByTestId("grpbxSearchCntrcts");
    expect(grpbxSearchCntrcts.tagName).toBe("BUTTON");
    expect(grpbxSearchCntrcts.type).toBe("button");
    expect(grpbxSearchCntrcts.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSearchCntrcts", () => {
    // START_USER_CODE-USER_grpbxSearchCntrcts_TEST
    // END_USER_CODE-USER_grpbxSearchCntrcts_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("ContractManagement:ContractGroupingProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("ContractManagement:ContractGroupingProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("gridCntrctShr_txtContracts(Grid Widget) Test Cases", async () => {
    let gridCntrctShr_txtContracts = screen.getByTestId("gridCntrctShr");
    let gridCntrctShr_txtContractsbtn =
      gridCntrctShr_txtContracts.nextElementSibling.firstElementChild;
    gridCntrctShr_txtContracts =
      gridCntrctShr_txtContracts.parentElement.parentElement.parentElement;
    expect(gridCntrctShr_txtContracts.tagName).toBe("DIV");
    expect(gridCntrctShr_txtContracts.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGroupingProfile_gridCntrctShr")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtContracts", () => {
    // START_USER_CODE-USER_txtContracts_TEST
    // END_USER_CODE-USER_txtContracts_TEST
  });
  test("txtGroupingInput(Textbox Widget) Test Cases", async () => {
    const txtGroupingInput = screen.getByTestId("txtGroupingInput");
    expect(txtGroupingInput.tagName).toBe("INPUT");
    expect(txtGroupingInput.type).toBe("text");
    expect(txtGroupingInput.classList).toContain("textboxWidgetClass");
    expect(txtGroupingInput.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractGroupingProfile_txtGroupingInput")
    );
    await act(async () => {
      userEvent.type(txtGroupingInput, "1");
    });
  });
  test("Custom Test Cases for txtGroupingInput", () => {
    // START_USER_CODE-USER_txtGroupingInput_TEST
    // END_USER_CODE-USER_txtGroupingInput_TEST
  });
  test("gridCntrctShr_txtShare(Grid Widget) Test Cases", async () => {
    let gridCntrctShr_txtShare = screen.getByTestId("gridCntrctShr");
    let gridCntrctShr_txtSharebtn =
      gridCntrctShr_txtShare.nextElementSibling.firstElementChild;
    gridCntrctShr_txtShare =
      gridCntrctShr_txtShare.parentElement.parentElement.parentElement;
    expect(gridCntrctShr_txtShare.tagName).toBe("DIV");
    expect(gridCntrctShr_txtShare.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ContractGroupingProfile_gridCntrctShr")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtShare", () => {
    // START_USER_CODE-USER_txtShare_TEST
    // END_USER_CODE-USER_txtShare_TEST
  });
  test("txtTotal(Textbox Widget) Test Cases", async () => {
    const txtTotal = screen.getByTestId("txtTotal");
    expect(txtTotal.tagName).toBe("INPUT");
    expect(txtTotal.type).toBe("text");
    expect(txtTotal.classList).toContain("textboxWidgetClass");
    expect(txtTotal.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractGroupingProfile_txtTotal")
    );
    await act(async () => {
      userEvent.type(txtTotal, "1");
    });
  });
  test("Custom Test Cases for txtTotal", () => {
    // START_USER_CODE-USER_txtTotal_TEST
    // END_USER_CODE-USER_txtTotal_TEST
  });
});
