/* istanbul ignore file */
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import AppContext from "../context/AppContext";

function PrivateRoute(props) {
  const context = useContext(AppContext);
    const authTokens = context.authTokens;
    const { component: Component, ...rest } = props;
    return (
      <Route
        {...rest}
        render={props =>
          authTokens ? <Component {...props} /> : <Redirect to="/Login" />
        }
      />
    );
  
}

export default PrivateRoute;
