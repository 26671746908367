/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_ManualDPRInvMaint from "./ManualDPRInvMaint";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ManualDPRInvMaint Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualDPRInvMaint />
      );
    });
  });
  afterEach(cleanup);
  test("is ManualDPRInvMaint Loads Successfully", () => {
    expect(screen.getByText("ManualDPRInvMaint")).toBeInTheDocument;
  });
  test("Custom Test Cases for ManualDPRInvMaint", () => {
    // START_USER_CODE-USER_ManualDPRInvMaint_Custom_Test_Case
    // END_USER_CODE-USER_ManualDPRInvMaint_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualDPRInvMaint />
      );
    });
  });
  afterEach(cleanup);
  test("btnOK(Button Widget) Test Cases", async () => {
    const btnOK = screen.getByTestId("btnOK");
    expect(btnOK).toBeInTheDocument;
    expect(btnOK.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvMaint_btnOK")
    );
  });
  test("Custom Test Cases for btnOK", () => {
    // START_USER_CODE-USER_btnOK_TEST
    // END_USER_CODE-USER_btnOK_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvMaint_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxAdjustments(GroupBox Widget) Test Cases", async () => {
    const grpbxAdjustments = screen.getByTestId("grpbxAdjustments");
    expect(grpbxAdjustments.tagName).toBe("BUTTON");
    expect(grpbxAdjustments.type).toBe("button");
    expect(grpbxAdjustments.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAdjustments", () => {
    // START_USER_CODE-USER_grpbxAdjustments_TEST
    // END_USER_CODE-USER_grpbxAdjustments_TEST
  });
  test("grpbxManualDPRInvMaint(GroupBox Widget) Test Cases", async () => {
    const grpbxManualDPRInvMaint = screen.getByTestId("grpbxManualDPRInvMaint");
    expect(grpbxManualDPRInvMaint.tagName).toBe("BUTTON");
    expect(grpbxManualDPRInvMaint.type).toBe("button");
    expect(grpbxManualDPRInvMaint.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxManualDPRInvMaint", () => {
    // START_USER_CODE-USER_grpbxManualDPRInvMaint_TEST
    // END_USER_CODE-USER_grpbxManualDPRInvMaint_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvMaint_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvMaint_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvMaint_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvMaint_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblNote(Label Widget) Test Cases", async () => {
    const lblNote = screen.getByTestId("lblNote");
    expect(lblNote.tagName).toBe("LABEL");
    expect(lblNote.classList).toContain("form-label");
    expect(lblNote.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvMaint_lblNote")
    );
  });
  test("Custom Test Cases for lblNote", () => {
    // START_USER_CODE-USER_lblNote_TEST
    // END_USER_CODE-USER_lblNote_TEST
  });
  test("txtChangeRemarks(Textbox Widget) Test Cases", async () => {
    const txtChangeRemarks = screen.getByTestId("txtChangeRemarks");
    expect(txtChangeRemarks.tagName).toBe("INPUT");
    expect(txtChangeRemarks.type).toBe("text");
    expect(txtChangeRemarks.classList).toContain("textboxWidgetClass");
    expect(txtChangeRemarks.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvMaint_txtChangeRemarks"
      )
    );
    await act(async () => {
      userEvent.type(txtChangeRemarks, "1");
    });
  });
  test("Custom Test Cases for txtChangeRemarks", () => {
    // START_USER_CODE-USER_txtChangeRemarks_TEST
    // END_USER_CODE-USER_txtChangeRemarks_TEST
  });
  test("txtCompOwnedAdj(Textbox Widget) Test Cases", async () => {
    const txtCompOwnedAdj = screen.getByTestId("txtCompOwnedAdj");
    expect(txtCompOwnedAdj.tagName).toBe("INPUT");
    expect(txtCompOwnedAdj.type).toBe("text");
    expect(txtCompOwnedAdj.classList).toContain("textboxWidgetClass");
    expect(txtCompOwnedAdj.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvMaint_txtCompOwnedAdj")
    );
    await act(async () => {
      userEvent.type(txtCompOwnedAdj, "1");
    });
  });
  test("Custom Test Cases for txtCompOwnedAdj", () => {
    // START_USER_CODE-USER_txtCompOwnedAdj_TEST
    // END_USER_CODE-USER_txtCompOwnedAdj_TEST
  });
  test("txtIntransitAdj(Textbox Widget) Test Cases", async () => {
    const txtIntransitAdj = screen.getByTestId("txtIntransitAdj");
    expect(txtIntransitAdj.tagName).toBe("INPUT");
    expect(txtIntransitAdj.type).toBe("text");
    expect(txtIntransitAdj.classList).toContain("textboxWidgetClass");
    expect(txtIntransitAdj.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvMaint_txtIntransitAdj")
    );
    await act(async () => {
      userEvent.type(txtIntransitAdj, "1");
    });
  });
  test("Custom Test Cases for txtIntransitAdj", () => {
    // START_USER_CODE-USER_txtIntransitAdj_TEST
    // END_USER_CODE-USER_txtIntransitAdj_TEST
  });
  test("txtNotReceiptedAdj(Textbox Widget) Test Cases", async () => {
    const txtNotReceiptedAdj = screen.getByTestId("txtNotReceiptedAdj");
    expect(txtNotReceiptedAdj.tagName).toBe("INPUT");
    expect(txtNotReceiptedAdj.type).toBe("text");
    expect(txtNotReceiptedAdj.classList).toContain("textboxWidgetClass");
    expect(txtNotReceiptedAdj.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvMaint_txtNotReceiptedAdj"
      )
    );
    await act(async () => {
      userEvent.type(txtNotReceiptedAdj, "1");
    });
  });
  test("Custom Test Cases for txtNotReceiptedAdj", () => {
    // START_USER_CODE-USER_txtNotReceiptedAdj_TEST
    // END_USER_CODE-USER_txtNotReceiptedAdj_TEST
  });
  test("txtPhysicalAdj(Textbox Widget) Test Cases", async () => {
    const txtPhysicalAdj = screen.getByTestId("txtPhysicalAdj");
    expect(txtPhysicalAdj.tagName).toBe("INPUT");
    expect(txtPhysicalAdj.type).toBe("text");
    expect(txtPhysicalAdj.classList).toContain("textboxWidgetClass");
    expect(txtPhysicalAdj.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvMaint_txtPhysicalAdj")
    );
    await act(async () => {
      userEvent.type(txtPhysicalAdj, "1");
    });
  });
  test("Custom Test Cases for txtPhysicalAdj", () => {
    // START_USER_CODE-USER_txtPhysicalAdj_TEST
    // END_USER_CODE-USER_txtPhysicalAdj_TEST
  });
  test("txtShrinkAdj(Textbox Widget) Test Cases", async () => {
    const txtShrinkAdj = screen.getByTestId("txtShrinkAdj");
    expect(txtShrinkAdj.tagName).toBe("INPUT");
    expect(txtShrinkAdj.type).toBe("text");
    expect(txtShrinkAdj.classList).toContain("textboxWidgetClass");
    expect(txtShrinkAdj.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualDPRInvMaint_txtShrinkAdj")
    );
    await act(async () => {
      userEvent.type(txtShrinkAdj, "1");
    });
  });
  test("Custom Test Cases for txtShrinkAdj", () => {
    // START_USER_CODE-USER_txtShrinkAdj_TEST
    // END_USER_CODE-USER_txtShrinkAdj_TEST
  });
  test("txtUndeterminedAdj(Textbox Widget) Test Cases", async () => {
    const txtUndeterminedAdj = screen.getByTestId("txtUndeterminedAdj");
    expect(txtUndeterminedAdj.tagName).toBe("INPUT");
    expect(txtUndeterminedAdj.type).toBe("text");
    expect(txtUndeterminedAdj.classList).toContain("textboxWidgetClass");
    expect(txtUndeterminedAdj.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvMaint_txtUndeterminedAdj"
      )
    );
    await act(async () => {
      userEvent.type(txtUndeterminedAdj, "1");
    });
  });
  test("Custom Test Cases for txtUndeterminedAdj", () => {
    // START_USER_CODE-USER_txtUndeterminedAdj_TEST
    // END_USER_CODE-USER_txtUndeterminedAdj_TEST
  });
  test("txtWhsReceiptAdj(Textbox Widget) Test Cases", async () => {
    const txtWhsReceiptAdj = screen.getByTestId("txtWhsReceiptAdj");
    expect(txtWhsReceiptAdj.tagName).toBe("INPUT");
    expect(txtWhsReceiptAdj.type).toBe("text");
    expect(txtWhsReceiptAdj.classList).toContain("textboxWidgetClass");
    expect(txtWhsReceiptAdj.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualDPRInvMaint_txtWhsReceiptAdj"
      )
    );
    await act(async () => {
      userEvent.type(txtWhsReceiptAdj, "1");
    });
  });
  test("Custom Test Cases for txtWhsReceiptAdj", () => {
    // START_USER_CODE-USER_txtWhsReceiptAdj_TEST
    // END_USER_CODE-USER_txtWhsReceiptAdj_TEST
  });
});
