/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  getData,
  setValue,
  setData,
  getValue,
  disable,
  enable,
} from "../../shared/WindowImports";

import "./PeanutSpecificationExceptionProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import {ContractManagementService} from "../../ContractManagement/Service/ContractManagementService"
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import {SystemMaintenanceMasterManagementService} from "../Service/SystemMaintenanceMasterManagementServices"
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_PeanutSpecificationExceptionProfile(
  props
) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  let userId = sessionStorage.getItem("userid"); 
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PeanutSpecificationExceptionProfile",
    windowName: "PeanutSpecificationExceptionProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key:
      "SystemMaintenanceMasterManagement.PeanutSpecificationExceptionProfile",
    // START_USER_CODE-USER_PeanutSpecificationExceptionProfile_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Peanut Specification Exception Profile",
      scrCode: "PN0080C",
    },
    // END_USER_CODE-USER_PeanutSpecificationExceptionProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    chkboxSeed: {
      name: "chkboxSeed",
      type: "CheckBoxWidget",
      parent: "grpbxPeanutSpecificationExceptionProfile",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxSeed_PROPERTIES

      // END_USER_CODE-USER_chkboxSeed_PROPERTIES
    },
    ddCornIndicator: {
      name: "ddCornIndicator",
      type: "DropDownFieldWidget",
      parent: "grpbxCornIndicator",
      Label: "Corn Indicator:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCornIndicator_PROPERTIES

      // END_USER_CODE-USER_ddCornIndicator_PROPERTIES
    },
    ddEdibleOilIndicator: {
      name: "ddEdibleOilIndicator",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutSpecificationExceptionProfile",
      Label: "Edible Oil Indicator:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEdibleOilIndicator_PROPERTIES

      // END_USER_CODE-USER_ddEdibleOilIndicator_PROPERTIES
    },
    ddFlavusIndicator: {
      name: "ddFlavusIndicator",
      type: "DropDownFieldWidget",
      parent: "grpbxFlavusIndicator",
      Label: "Flavus Indicator:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFlavusIndicator_PROPERTIES
      SpecialFirstOption:""
      // END_USER_CODE-USER_ddFlavusIndicator_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutSpecificationExceptionProfile",
      Label: "Peanut Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutSpecificationExceptionProfile",
      Label: "Peanut Variety:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddSegmentType: {
      name: "ddSegmentType",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutSpecificationExceptionProfile",
      Label: "Segment Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegmentType_PROPERTIES

      // END_USER_CODE-USER_ddSegmentType_PROPERTIES
    },
    grpbxCornIndicator: {
      name: "grpbxCornIndicator",
      type: "GroupBoxWidget",
      parent: "grpbxPeanutSpecificationExceptionProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCornIndicator_PROPERTIES

      // END_USER_CODE-USER_grpbxCornIndicator_PROPERTIES
    },
    grpbxFlavusIndicator: {
      name: "grpbxFlavusIndicator",
      type: "GroupBoxWidget",
      parent: "grpbxPeanutSpecificationExceptionProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxFlavusIndicator_PROPERTIES

      // END_USER_CODE-USER_grpbxFlavusIndicator_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblMax: {
      name: "lblMax",
      type: "LabelWidget",
      parent: "grpbxCornIndicator",
      Label: "Maximum",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMax_PROPERTIES

      // END_USER_CODE-USER_lblMax_PROPERTIES
    },
    lblMaximum: {
      name: "lblMaximum",
      type: "LabelWidget",
      parent: "grpbxFlavusIndicator",
      Label: "Maximum",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMaximum_PROPERTIES

      // END_USER_CODE-USER_lblMaximum_PROPERTIES
    },
    lblMin: {
      name: "lblMin",
      type: "LabelWidget",
      parent: "grpbxCornIndicator",
      Label: "Minimum",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMin_PROPERTIES

      // END_USER_CODE-USER_lblMin_PROPERTIES
    },
    lblMinimum: {
      name: "lblMinimum",
      type: "LabelWidget",
      parent: "grpbxFlavusIndicator",
      Label: "Minimum",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMinimum_PROPERTIES

      // END_USER_CODE-USER_lblMinimum_PROPERTIES
    },
    lblSeed: {
      name: "lblSeed",
      type: "LabelWidget",
      parent: "grpbxPeanutSpecificationExceptionProfile",
      Label: "Seed:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSeed_PROPERTIES

      // END_USER_CODE-USER_lblSeed_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxPeanutSpecificationExceptionProfile",
      Label: "Buying Point:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    txtCncleRmdPercentMax: {
      name: "txtCncleRmdPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCncleRmdPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtCncleRmdPercentMax_PROPERTIES
    },
    txtCncleRmdPercentMin: {
      name: "txtCncleRmdPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      Label: "Cncl Rmd %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCncleRmdPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtCncleRmdPercentMin_PROPERTIES
    },
    txtCRBRPercentMax: {
      name: "txtCRBRPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCRBRPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtCRBRPercentMax_PROPERTIES
    },
    txtCRBRPercentMin: {
      name: "txtCRBRPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      Label: "CR BR %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCRBRPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtCRBRPercentMin_PROPERTIES
    },
    txtDamPercentMax: {
      name: "txtDamPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtDamPercentMax_PROPERTIES
    },
    txtDamPercentMin: {
      name: "txtDamPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      Label: "Dam %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtDamPercentMin_PROPERTIES
    },
    txtDCOLSHELPercentMax: {
      name: "txtDCOLSHELPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDCOLSHELPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtDCOLSHELPercentMax_PROPERTIES
    },
    txtDCOLSHELPercentMin: {
      name: "txtDCOLSHELPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      Label: "DCOL SHEL %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDCOLSHELPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtDCOLSHELPercentMin_PROPERTIES
    },
    txtELKPercentMax: {
      name: "txtELKPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtELKPercentMax_PROPERTIES
    },
    txtELKPercentMin: {
      name: "txtELKPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      Label: "ELK %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtELKPercentMin_PROPERTIES
    },
    txtFanPercentMax: {
      name: "txtFanPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFanPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtFanPercentMax_PROPERTIES
    },
    txtFanPercentMin: {
      name: "txtFanPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      Label: "Fan %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFanPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtFanPercentMin_PROPERTIES
    },
    txtFMPercentMax: {
      name: "txtFMPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFMPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtFMPercentMax_PROPERTIES
    },
    txtFMPercentMin: {
      name: "txtFMPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      Label: "FM %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFMPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtFMPercentMin_PROPERTIES
    },
    txtFrezDamPercentMax: {
      name: "txtFrezDamPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFrezDamPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtFrezDamPercentMax_PROPERTIES
    },
    txtFrezDamPercentMin: {
      name: "txtFrezDamPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      Label: "Frez Dam %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFrezDamPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtFrezDamPercentMin_PROPERTIES
    },
    txtHULBritPercentMax: {
      name: "txtHULBritPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHULBritPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtHULBritPercentMax_PROPERTIES
    },
    txtHULBritPercentMin: {
      name: "txtHULBritPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      Label: "HUL Brit %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHULBritPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtHULBritPercentMin_PROPERTIES
    },
    txtHullPercentMax: {
      name: "txtHullPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtHullPercentMax_PROPERTIES
    },
    txtHullPercentMin: {
      name: "txtHullPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      Label: "Hull %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtHullPercentMin_PROPERTIES
    },
    txtJumboPercentMax: {
      name: "txtJumboPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtJumboPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtJumboPercentMax_PROPERTIES
    },
    txtJumboPercentMin: {
      name: "txtJumboPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      Label: "Jumbo %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtJumboPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtJumboPercentMin_PROPERTIES
    },
    txtLSKPercentMax: {
      name: "txtLSKPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtLSKPercentMax_PROPERTIES
    },
    txtLSKPercentMin: {
      name: "txtLSKPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      Label: "LSK %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtLSKPercentMin_PROPERTIES
    },
    txtMoistPercentMax: {
      name: "txtMoistPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMoistPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtMoistPercentMax_PROPERTIES
    },
    txtMoistPercentMin: {
      name: "txtMoistPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxCornIndicator",
      Label: "Moist %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMoistPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtMoistPercentMin_PROPERTIES
    },
    txtOKPercentMax: {
      name: "txtOKPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOKPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtOKPercentMax_PROPERTIES
    },
    txtOKPercentMin: {
      name: "txtOKPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      Label: "OK %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOKPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtOKPercentMin_PROPERTIES
    },
    txtSMKPercentMax: {
      name: "txtSMKPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMKPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtSMKPercentMax_PROPERTIES
    },
    txtSMKPercentMin: {
      name: "txtSMKPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      Label: "SMK %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMKPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtSMKPercentMin_PROPERTIES
    },
    txtSSPercentMax: {
      name: "txtSSPercentMax",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSSPercentMax_PROPERTIES

      // END_USER_CODE-USER_txtSSPercentMax_PROPERTIES
    },
    txtSSPercentMin: {
      name: "txtSSPercentMin",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      Label: "SS %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSSPercentMin_PROPERTIES

      // END_USER_CODE-USER_txtSSPercentMin_PROPERTIES
    },
    txtVicamPPBMax: {
      name: "txtVicamPPBMax",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVicamPPBMax_PROPERTIES

      // END_USER_CODE-USER_txtVicamPPBMax_PROPERTIES
    },
    txtVicamPPBMin: {
      name: "txtVicamPPBMin",
      type: "TextBoxWidget",
      parent: "grpbxFlavusIndicator",
      Label: "Vicam PPB:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVicamPPBMin_PROPERTIES

      // END_USER_CODE-USER_txtVicamPPBMin_PROPERTIES
    },
    grpbxPeanutSpecificationExceptionProfile: {
      name: "grpbxPeanutSpecificationExceptionProfile",
      type: "GroupBoxWidget",
      parent: "PeanutSpecificationExceptionProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPeanutSpecificationExceptionProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxPeanutSpecificationExceptionProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "PeanutSpecificationExceptionProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  //FormLoad function
  let formLoad = async () => {
    try {
      let dataObj = getData(thisObj, 'frmPeanutSpecificationExceptionProfile')
      if (dataObj.cmdOk_Caption == 'Add') {
        document.getElementById('btnOk').innerText = "Add"
        setValue(thisObj, 'txtBuyingPoint', dataObj.buyingPoint_name)
        disable(thisObj, 'txtBuyingPoint')
        await bFillTypeList(dataObj.LstrList[0].pnut_type_id)
        disable(thisObj, 'ddPeanutType')
        await bFillVarietyList(dataObj.buyingPoint_id, dataObj.LstrList[0].pnut_type_id)
        let seed_ind = ''
        if (dataObj.LstrList[0].seed_ind == 'Y') {
          seed_ind = '1'
        }
        else {
          seed_ind = ''
        }
        setValue(thisObj, 'chkboxSeed', seed_ind)
        disable(thisObj, 'chkboxSeed')
        bFillSegmentTypeList(dataObj.LstrList[0].seg_type)
        disable(thisObj, 'ddSegmentType')
        bFillEdibleOilIndList(dataObj.LstrList[0].edible_oil_ind)
        disable(thisObj, 'ddEdibleOilIndicator')
        bFillFlavusIndList(dataObj.LstrList[0].flavus_ind)
        bFillCornIndList(dataObj.LstrList[0].corn_ind)

        setValue(thisObj, 'txtVicamPPBMin', dataObj.LstrList[0].min_vicam_ppb)
        setValue(thisObj, 'txtSMKPercentMin', dataObj.LstrList[0].min_smk_pct)
        setValue(thisObj, 'txtSSPercentMin', dataObj.LstrList[0].min_ss_pct)
        setValue(thisObj, 'txtOKPercentMin', dataObj.LstrList[0].min_ok_pct)
        setValue(thisObj, 'txtDamPercentMin', dataObj.LstrList[0].min_dam_pct)
        setValue(thisObj, 'txtHullPercentMin', dataObj.LstrList[0].min_hull_pct)
        setValue(thisObj, 'txtFrezDamPercentMin', dataObj.LstrList[0].min_frez_dam_pct)
        setValue(thisObj, 'txtCncleRmdPercentMin', dataObj.LstrList[0].min_cncl_rmd_pct)
        setValue(thisObj, 'txtCRBRPercentMin', dataObj.LstrList[0].min_cr_br_pct)
        setValue(thisObj, 'txtDCOLSHELPercentMin', dataObj.LstrList[0].min_dcol_shel_pct)
        setValue(thisObj, 'txtHULBritPercentMin', dataObj.LstrList[0].min_hul_brit_pct)
        setValue(thisObj, 'txtJumboPercentMin', dataObj.LstrList[0].min_jumbo_pct)
        setValue(thisObj, 'txtELKPercentMin', dataObj.LstrList[0].min_elk_pct)
        setValue(thisObj, 'txtFMPercentMin', dataObj.LstrList[0].min_fm_pct)
        setValue(thisObj, 'txtLSKPercentMin', dataObj.LstrList[0].min_lsk_pct)
        setValue(thisObj, 'txtMoistPercentMin', dataObj.LstrList[0].min_moist_pct)
        setValue(thisObj, 'txtFanPercentMin', dataObj.LstrList[0].min_fan_pct)
        setValue(thisObj, 'txtVicamPPBMax', dataObj.LstrList[0].max_vicam_ppb)
        setValue(thisObj, 'txtSMKPercentMax', dataObj.LstrList[0].max_smk_pct)
        setValue(thisObj, 'txtSSPercentMax', dataObj.LstrList[0].max_ss_pct)
        setValue(thisObj, 'txtOKPercentMax', dataObj.LstrList[0].max_ok_pct)
        setValue(thisObj, 'txtDamPercentMax', dataObj.LstrList[0].max_dam_pct)
        setValue(thisObj, 'txtHullPercentMax', dataObj.LstrList[0].max_hull_pct)
        setValue(thisObj, 'txtFrezDamPercentMax', dataObj.LstrList[0].max_frez_dam_pct)
        setValue(thisObj, 'txtCncleRmdPercentMax', dataObj.LstrList[0].max_cncl_rmd_pct)
        setValue(thisObj, 'txtCRBRPercentMax', dataObj.LstrList[0].max_cr_br_pct)
        setValue(thisObj, 'txtDCOLSHELPercentMax', dataObj.LstrList[0].max_dcol_shel_pct)
        setValue(thisObj, 'txtHULBritPercentMax', dataObj.LstrList[0].max_hul_brit_pct)
        setValue(thisObj, 'txtJumboPercentMax', dataObj.LstrList[0].max_jumbo_pct)
        setValue(thisObj, 'txtELKPercentMax', dataObj.LstrList[0].max_elk_pct)
        setValue(thisObj, 'txtFMPercentMax', dataObj.LstrList[0].max_fm_pct)
        setValue(thisObj, 'txtLSKPercentMax', dataObj.LstrList[0].max_lsk_pct)
        setValue(thisObj, 'txtMoistPercentMax', dataObj.LstrList[0].max_moist_pct)
        setValue(thisObj, 'txtFanPercentMax', dataObj.LstrList[0].max_fan_pct)
        let add_date_time = moment(dataObj.LstrList[0].add_date_time).format("MM/DD/YYYY")
        setValue(thisObj, 'lblAddedByValue', dataObj.LstrList[0].add_user + " " + add_date_time)
        let chg_date_time = moment(dataObj.LstrList[0].chg_date_time).format("MM/DD/YYYY")
        setValue(thisObj, 'lblChangedByValue', dataObj.LstrList[0].chg_user + " " + chg_date_time)
      }
      if (dataObj.cmdOk_Caption == 'Update') {
        document.getElementById('btnOk').innerText = "Update"
        setValue(thisObj, 'txtBuyingPoint', dataObj.buyingPoint_name)
        disable(thisObj, 'txtBuyingPoint')
        await bFillTypeList(dataObj.LstrList[0].pnut_type_id)
        disable(thisObj, 'ddPeanutType')
        await bFillVarietyList(dataObj.buyingPoint_id, dataObj.LstrList[0].pnut_type_id, dataObj.LstrList[0].pnut_variety_id)
        disable(thisObj, 'ddPeanutVariety')
        disable(thisObj, 'chkboxSeed')
        let seedInd = ''
        if (dataObj.LstrList[0].seed_ind == 'Y') {
          seedInd = '1'
        }
        else {
          seedInd = ''
        }
        setValue(thisObj, 'chkboxSeed', seedInd)
        bFillSegmentTypeList(dataObj.LstrList[0].seg_type)
        disable(thisObj, 'ddSegmentType')
        bFillEdibleOilIndList(dataObj.LstrList[0].edible_oil_ind)
        disable(thisObj, 'ddEdibleOilIndicator')
        bFillFlavusIndList(dataObj.LstrList[0].flavus_ind)
        bFillCornIndList(dataObj.LstrList[0].corn_ind)

        setValue(thisObj, 'txtVicamPPBMin', dataObj.LstrList[0].min_vicam_ppb.toString())
        setValue(thisObj, 'txtSMKPercentMin', dataObj.LstrList[0].min_smk_pct.toFixed(2))
        setValue(thisObj, 'txtSSPercentMin', dataObj.LstrList[0].min_ss_pct.toFixed(2))
        setValue(thisObj, 'txtOKPercentMin', dataObj.LstrList[0].min_ok_pct.toFixed(2))
        setValue(thisObj, 'txtDamPercentMin', dataObj.LstrList[0].min_dam_pct.toFixed(2))
        setValue(thisObj, 'txtHullPercentMin', dataObj.LstrList[0].min_hull_pct.toFixed(2))
        setValue(thisObj, 'txtFrezDamPercentMin', dataObj.LstrList[0].min_frez_dam_pct.toFixed(2))
        setValue(thisObj, 'txtCncleRmdPercentMin', dataObj.LstrList[0].min_cncl_rmd_pct.toFixed(2))
        setValue(thisObj, 'txtCRBRPercentMin', dataObj.LstrList[0].min_cr_br_pct.toFixed(2))
        setValue(thisObj, 'txtDCOLSHELPercentMin', dataObj.LstrList[0].min_dcol_shel_pct.toFixed(2))
        setValue(thisObj, 'txtHULBritPercentMin', dataObj.LstrList[0].min_hul_brit_pct.toFixed(2))
        setValue(thisObj, 'txtJumboPercentMin', dataObj.LstrList[0].min_jumbo_pct.toFixed(2))
        setValue(thisObj, 'txtELKPercentMin', dataObj.LstrList[0].min_elk_pct.toFixed(2))
        setValue(thisObj, 'txtFMPercentMin', dataObj.LstrList[0].min_fm_pct.toFixed(2))
        setValue(thisObj, 'txtLSKPercentMin', dataObj.LstrList[0].min_lsk_pct.toFixed(2))
        setValue(thisObj, 'txtMoistPercentMin', dataObj.LstrList[0].min_moist_pct.toFixed(2))
        setValue(thisObj, 'txtFanPercentMin', dataObj.LstrList[0].min_fan_pct.toFixed(2))
        setValue(thisObj, 'txtVicamPPBMax', dataObj.LstrList[0].max_vicam_ppb.toString())
        setValue(thisObj, 'txtSMKPercentMax', dataObj.LstrList[0].max_smk_pct.toFixed(2))
        setValue(thisObj, 'txtSSPercentMax', dataObj.LstrList[0].max_ss_pct.toFixed(2))
        setValue(thisObj, 'txtOKPercentMax', dataObj.LstrList[0].max_ok_pct.toFixed(2))
        setValue(thisObj, 'txtDamPercentMax', dataObj.LstrList[0].max_dam_pct.toFixed(2))
        setValue(thisObj, 'txtHullPercentMax', dataObj.LstrList[0].max_hull_pct.toFixed(2))
        setValue(thisObj, 'txtFrezDamPercentMax', dataObj.LstrList[0].max_frez_dam_pct.toFixed(2))
        setValue(thisObj, 'txtCncleRmdPercentMax', dataObj.LstrList[0].max_cncl_rmd_pct.toFixed(2))
        setValue(thisObj, 'txtCRBRPercentMax', dataObj.LstrList[0].max_cr_br_pct.toFixed(2))
        setValue(thisObj, 'txtDCOLSHELPercentMax', dataObj.LstrList[0].max_dcol_shel_pct.toFixed(2))
        setValue(thisObj, 'txtHULBritPercentMax', dataObj.LstrList[0].max_hul_brit_pct.toFixed(2))
        setValue(thisObj, 'txtJumboPercentMax', dataObj.LstrList[0].max_jumbo_pct.toFixed(2))
        setValue(thisObj, 'txtELKPercentMax', dataObj.LstrList[0].max_elk_pct.toFixed(2))
        setValue(thisObj, 'txtFMPercentMax', dataObj.LstrList[0].max_fm_pct.toFixed(2))
        setValue(thisObj, 'txtLSKPercentMax', dataObj.LstrList[0].max_lsk_pct.toFixed(2))
        setValue(thisObj, 'txtMoistPercentMax', dataObj.LstrList[0].max_moist_pct.toFixed(2))
        setValue(thisObj, 'txtFanPercentMax', dataObj.LstrList[0].max_fan_pct.toFixed(2))
        let add_date_time = moment(dataObj.LstrList[0].add_date_time).format("MM/DD/YYYY")
        setValue(thisObj, 'lblAddedByValue', dataObj.LstrList[0].add_user + " " + add_date_time)
        let chg_date_time = moment(dataObj.LstrList[0].chg_date_time).format("MM/DD/YYYY")
        setValue(thisObj, 'lblChangedByValue', dataObj.LstrList[0].chg_user + " " + chg_date_time)
        EnableDisableButton()
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    finally {
      setLoading(false);
      return true;
    }
  };
  
  //Enable/Disable Update button function
  const EnableDisableButton = async () => {
    try {
      let dataObj = getData(thisObj, 'frmPeanutSpecificationExceptionProfile')
      if (dataObj.cmdOk_Caption == 'Update') {
        let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN0080', '002', 'U', null);
        if (response[0].permission == 'N') {
          disable(thisObj, 'btnOk')
        }
        else {
          enable(thisObj, 'btnOk')
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  };
    
  //Binding of Peanut Type dropdown
  const bFillTypeList = async (pnut_type_id) => {
    try {
      let js = [];
      let response = await ContractManagementService.RetrievePeanutTypeControls(pnut_type_id);
      let data = response
      if (data !== null && data !== undefined) {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
          js.push(obj)
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })
      setValue(thisObj, 'ddPeanutType', js[0].key);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  };

  //Binding of Peanut Variety dropdown
  const bFillVarietyList = async (buy_pt_id, peanut_type, pnutVarietyId) => {
    try {
      let js = [];
      js.push({ key: '', description: '>>>All Varieties<<<' })
      let response = await ContractManagementService.RetrievePeanutVarietyControls(buy_pt_id, null, null, peanut_type, pnutVarietyId)
      let data = response
      if (data !== null && data !== undefined) {
        for (var i = 0; i < data.length; i++) {
          let obj = {}
          if (data[i].symbol_ind == "") {
            obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name }
          }
          else if (data[i].symbol_ind == "TRADEMARK") {
            obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + 'ᵀᴹ' }
          }
          else if (data[i].symbol_ind == "REGISTERED TRADEMARK") {
            obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '®' }
          }
          else if (data[i].symbol_ind == "COPYRIGHT") {
            obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '©' }
          }
          js.push(obj)
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutVariety: {
            ...state['ddPeanutVariety'],
            valueList: js,
          }
        }
      })

      let dataObj = getData(thisObj, 'frmPeanutSpecificationExceptionProfile')
      if (dataObj.cmdOk_Caption == 'Add') {
        setValue(thisObj, 'ddPeanutVariety', js[0].key);
      }
      if (dataObj.cmdOk_Caption == 'Update') {
        if (pnutVarietyId == '') {
          setValue(thisObj, 'ddPeanutVariety', js[0].key)
        }
        else {
          setValue(thisObj, 'ddPeanutVariety', js[1].key);
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  };

  //Binding of Segment Type Indicator dropdown
  const bFillSegmentTypeList = (seg_type) => {
    try {
      let js = []
      js.push({ key: '1', description: 'Seg1' })
      js.push({ key: '2', description: 'Seg2' })
      js.push({ key: '3', description: 'Seg3' })
      thisObj.setState(current => {
        return {
          ...current,
          ddSegmentType: {
            ...state['ddSegmentType'],
            valueList: js
          }
        }
      })
      setValue(thisObj, "ddSegmentType", seg_type)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  };

  //Binding of Edible Oil Indicator dropdown
  const bFillEdibleOilIndList = (edible_oil_ind) => {
    try {
      let js = []
      js.push({ key: 'E', description: 'Edible' })
      js.push({ key: 'O', description: 'Crushing' })
      thisObj.setState(current => {
        return {
          ...current,
          ddEdibleOilIndicator: {
            ...state['ddEdibleOilIndicator'],
            valueList: js
          }
        }
      })
      setValue(thisObj, "ddEdibleOilIndicator", edible_oil_ind)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  }

  //Binding of Flavus Indicator dropdown
  const bFillFlavusIndList = (flavus_ind) => {
    try {
      let js = []
      js.push({ key: 'Y', description: 'Yes' })
      js.push({ key: 'N', description: 'No' })
      thisObj.setState(current => {
        return {
          ...current,
          ddFlavusIndicator: {
            ...state['ddFlavusIndicator'],
            valueList: js
          }
        }
      })
      setValue(thisObj, "ddFlavusIndicator", flavus_ind)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  }

  //Binding of Corn Indicator dropdown
  const bFillCornIndList = (corn_ind) => {
    try {
      let js = []
      js.push({ key: '', description: '' })
      js.push({ key: 'Y', description: 'Yes' })
      js.push({ key: 'N', description: 'No' })
      thisObj.setState(current => {
        return {
          ...current,
          ddCornIndicator: {
            ...state['ddCornIndicator'],
            valueList: js
          }
        }
      })
      setValue(thisObj, "ddCornIndicator", corn_ind)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  }

  //FormValid function
  const FormValid = () => {
    try {
      var bformValid = false
      var bDataValid = true
      let vicamPPBMin = getValue(thisObj, 'txtVicamPPBMin')
      let SMKPercentMin = getValue(thisObj, 'txtSMKPercentMin')
      let SSPercentMin = getValue(thisObj, 'txtSSPercentMin')
      let okPercentMin = getValue(thisObj, 'txtOKPercentMin')
      let damPercentMin = getValue(thisObj, 'txtDamPercentMin')
      let hullPercentMin = getValue(thisObj, 'txtHullPercentMin')
      let frezDamPercentMin = getValue(thisObj, 'txtFrezDamPercentMin')
      let cncleRmdPercentMin = getValue(thisObj, 'txtCncleRmdPercentMin')
      let CRBRPercentMin = getValue(thisObj, 'txtCRBRPercentMin')
      let DCOLSHELPercentMin = getValue(thisObj, 'txtDCOLSHELPercentMin')
      let HULBritPercentMin = getValue(thisObj, 'txtHULBritPercentMin')
      let jumboPercentMin = getValue(thisObj, 'txtJumboPercentMin')
      let ELKPercentMin = getValue(thisObj, 'txtELKPercentMin')
      let FMPercentMin = getValue(thisObj, 'txtFMPercentMin')
      let LSKPercentMin = getValue(thisObj, 'txtLSKPercentMin')
      let moistPercentMin = getValue(thisObj, 'txtMoistPercentMin')
      let fanPercentMin = getValue(thisObj, 'txtFanPercentMin')
      let vicamPPBMax = getValue(thisObj, 'txtVicamPPBMax')
      let SMKPercentMax = getValue(thisObj, 'txtSMKPercentMax')
      let SSPercentMax = getValue(thisObj, 'txtSSPercentMax')
      let okPercentMax = getValue(thisObj, 'txtOKPercentMax')
      let damPercentMax = getValue(thisObj, 'txtDamPercentMax')
      let hullPercentMax = getValue(thisObj, 'txtHullPercentMax')
      let frezDamPercentMax = getValue(thisObj, 'txtFrezDamPercentMax')
      let cncleRmdPercentMax = getValue(thisObj, 'txtCncleRmdPercentMax')
      let CRBRPercentMax = getValue(thisObj, 'txtCRBRPercentMax')
      let DCOLSHELPercentMax = getValue(thisObj, 'txtDCOLSHELPercentMax')
      let HULBritPercentMax = getValue(thisObj, 'txtHULBritPercentMax')
      let jumboPercentMax = getValue(thisObj, 'txtJumboPercentMax')
      let ELKPercentMax = getValue(thisObj, 'txtELKPercentMax')
      let FMPercentMax = getValue(thisObj, 'txtFMPercentMax')
      let LSKPercentMax = getValue(thisObj, 'txtLSKPercentMax')
      let moistPercentMax = getValue(thisObj, 'txtMoistPercentMax')
      let fanPercentMax = getValue(thisObj, 'txtFanPercentMax')

      if (vicamPPBMin !== '' && vicamPPBMin !== undefined) {
        if (isNaN(vicamPPBMin) == false) {
          if (vicamPPBMin < 0 || vicamPPBMin > 999999) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum Vicam PPB must be of format ######")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtVicamPPBMin', '0')
      }

      if (SMKPercentMin !== '' && SMKPercentMin !== undefined) {
        if (isNaN(SMKPercentMin) == false) {
          if (SMKPercentMin < 0 || SMKPercentMin > 999999) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum SMK Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtSMKPercentMin', '0')
      }

      if (SSPercentMin !== '' && SSPercentMin !== undefined) {
        if (isNaN(SSPercentMin) == false) {
          if (SSPercentMin < 0 || SSPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum SS Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtSSPercentMin', '0')
      }

      if (okPercentMin !== '' && okPercentMin !== undefined) {
        if (isNaN(okPercentMin) == false) {
          if (okPercentMin < 0 || okPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum Ok Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtOKPercentMin', '0')
      }

      if (damPercentMin !== '' && damPercentMin !== undefined) {
        if (isNaN(damPercentMin) == false) {
          if (damPercentMin < 0 || damPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum Dam Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtDamPercentMin', '0')
      }

      if (hullPercentMin !== '' && hullPercentMin !== undefined) {
        if (isNaN(hullPercentMin) == false) {
          if (hullPercentMin < 0 || hullPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum Hull Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtHullPercentMin', '0')
      }

      if (frezDamPercentMin !== '' && frezDamPercentMin !== undefined) {
        if (isNaN(frezDamPercentMin) == false) {
          if (frezDamPercentMin < 0 || frezDamPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum Frez Dam Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtFrezDamPercentMin', '0')
      }

      if (cncleRmdPercentMin !== '' && cncleRmdPercentMin !== undefined) {
        if (isNaN(cncleRmdPercentMin) == false) {
          if (cncleRmdPercentMin < 0 || cncleRmdPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum CnclRmd Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtCncleRmdPercentMin', '0')
      }

      if (CRBRPercentMin !== '' && CRBRPercentMin !== undefined) {
        if (isNaN(CRBRPercentMin) == false) {
          if (CRBRPercentMin < 0 || CRBRPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum CRBR Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtCRBRPercentMin', '0')
      }

      if (DCOLSHELPercentMin !== '' && DCOLSHELPercentMin !== undefined) {
        if (isNaN(DCOLSHELPercentMin) == false) {
          if (DCOLSHELPercentMin < 0 || DCOLSHELPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum DCOLSHEL Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtDCOLSHELPercentMin', '0')
      }

      if (HULBritPercentMin !== '' && HULBritPercentMin !== undefined) {
        if (isNaN(HULBritPercentMin) == false) {
          if (HULBritPercentMin < 0 || HULBritPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum HULBRIT Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtHULBritPercentMin', '0')
      }

      if (jumboPercentMin !== '' && jumboPercentMin !== undefined) {
        if (isNaN(jumboPercentMin) == false) {
          if (jumboPercentMin < 0 || jumboPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum Jumbo Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtJumboPercentMin', '0')
      }

      if (ELKPercentMin !== '' && ELKPercentMin !== undefined) {
        if (isNaN(ELKPercentMin) == false) {
          if (ELKPercentMin < 0 || ELKPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum ELK Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtELKPercentMin', '0')
      }

      if (FMPercentMin !== '' && FMPercentMin !== undefined) {
        if (isNaN(FMPercentMin) == false) {
          if (FMPercentMin < 0 || FMPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum FM Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtFMPercentMin', '0')
      }

      if (LSKPercentMin !== '' && LSKPercentMin !== undefined) {
        if (isNaN(LSKPercentMin) == false) {
          if (LSKPercentMin < 0 || LSKPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum LSK Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtLSKPercentMin', '0')
      }

      if (moistPercentMin !== '' && moistPercentMin !== undefined) {
        if (isNaN(moistPercentMin) == false) {
          if (moistPercentMin < 0 || moistPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum Moist percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtMoistPercentMin', '0')
      }

      if (fanPercentMin !== '' && fanPercentMin !== undefined) {
        if (isNaN(fanPercentMin) == false) {
          if (fanPercentMin < 0 || fanPercentMin > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Minimum Fan Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtFanPercentMin', '0')
      }

      if (vicamPPBMax !== '' && vicamPPBMax !== undefined) {
        if (isNaN(vicamPPBMax) == false) {
          if (vicamPPBMax < 0 || vicamPPBMax > 999999) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum Vicam PPB must be of format ######")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtVicamPPBMax', '0')
      }

      if (SMKPercentMax !== '' && SMKPercentMax !== undefined) {
        if (isNaN(SMKPercentMax) == false) {
          if (SMKPercentMax < 0 || SMKPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum SMK Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtSMKPercentMax', '0')
      }

      if (SSPercentMax !== '' && SSPercentMax !== undefined) {
        if (isNaN(SSPercentMax) == false) {
          if (SSPercentMax < 0 || SSPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum SS Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtSSPercentMax', '0')
      }

      if (okPercentMax !== '' && okPercentMax !== undefined) {
        if (isNaN(okPercentMax) == false) {
          if (okPercentMax < 0 || okPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum Ok Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtOKPercentMax', '0')
      }

      if (damPercentMax !== '' && damPercentMax !== undefined) {
        if (isNaN(damPercentMax) == false) {
          if (damPercentMax < 0 || damPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum Dam Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtDamPercentMax', '0')
      }

      if (hullPercentMax !== '' && hullPercentMax !== undefined) {
        if (isNaN(hullPercentMax) == false) {
          if (hullPercentMax < 0 || hullPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum Hull Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtHullPercentMax', '0')
      }

      if (frezDamPercentMax !== '' && frezDamPercentMax !== undefined) {
        if (isNaN(frezDamPercentMax) == false) {
          if (frezDamPercentMax < 0 || frezDamPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum Frez Dam Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtFrezDamPercentMax', '0')
      }

      if (cncleRmdPercentMax !== '' && cncleRmdPercentMax !== undefined) {
        if (isNaN(cncleRmdPercentMax) == false) {
          if (cncleRmdPercentMax < 0 || cncleRmdPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum CnclRmd Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtCncleRmdPercentMax', '0')
      }

      if (CRBRPercentMax !== '' && CRBRPercentMax !== undefined) {
        if (isNaN(CRBRPercentMax) == false) {
          if (CRBRPercentMax < 0 || CRBRPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum CRBR Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtCRBRPercentMax', '0')
      }

      if (DCOLSHELPercentMax !== '' && DCOLSHELPercentMax !== undefined) {
        if (isNaN(DCOLSHELPercentMax) == false) {
          if (DCOLSHELPercentMax < 0 || DCOLSHELPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum DCOLSHEL Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtDCOLSHELPercentMax', '0')
      }

      if (HULBritPercentMax !== '' && HULBritPercentMax !== undefined) {
        if (isNaN(HULBritPercentMax) == false) {
          if (HULBritPercentMax < 0 || HULBritPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum HULBRIT Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtHULBritPercentMax', '0')
      }

      if (jumboPercentMax !== '' && jumboPercentMax !== undefined) {
        if (isNaN(jumboPercentMax) == false) {
          if (jumboPercentMax < 0 || jumboPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum Jumbo Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtJumboPercentMax', '0')
      }

      if (ELKPercentMax !== '' && ELKPercentMax !== undefined) {
        if (isNaN(ELKPercentMax) == false) {
          if (ELKPercentMax < 0 || ELKPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum ELK Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtELKPercentMax', '0')
      }

      if (FMPercentMax !== '' && FMPercentMax !== undefined) {
        if (isNaN(FMPercentMax) == false) {
          if (FMPercentMax < 0 || FMPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum FM Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtFMPercentMax', '0')
      }

      if (LSKPercentMax !== '' && LSKPercentMax !== undefined) {
        if (isNaN(LSKPercentMax) == false) {
          if (LSKPercentMax < 0 || LSKPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum LSK Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtLSKPercentMax', '0')
      }

      if (moistPercentMax !== '' && moistPercentMax !== undefined) {
        if (isNaN(moistPercentMax) == false) {
          if (moistPercentMax < 0 || moistPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum Moist Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtMoistPercentMax', '0')
      }

      if (fanPercentMax !== '' && fanPercentMax !== undefined) {
        if (isNaN(fanPercentMax) == false) {
          if (fanPercentMax < 0 || fanPercentMax > 999.99) {
            bDataValid = false
          }
        }
        else {
          bDataValid = false
        }
        if (bDataValid == false) {
          showMessage(thisObj, "Maximum Fan Percentage must be of format ###.##")
          return bDataValid
        }
      }
      else {
        setValue(thisObj, 'txtFanPercentMax', '0')
      }

      vicamPPBMin = vicamPPBMin == undefined ? "0" : vicamPPBMin
      SMKPercentMin = SMKPercentMin == undefined ? "0" : SMKPercentMin
      SSPercentMin = SSPercentMin == undefined ? "0" : SSPercentMin
      okPercentMin = okPercentMin == undefined ? "0" : okPercentMin
      damPercentMin = damPercentMin == undefined ? "0" : damPercentMin
      hullPercentMin = hullPercentMin == undefined ? "0" : hullPercentMin
      frezDamPercentMin = frezDamPercentMin == undefined ? "0" : frezDamPercentMin
      cncleRmdPercentMin = cncleRmdPercentMin == undefined ? "0" : cncleRmdPercentMin
      CRBRPercentMin = CRBRPercentMin == undefined ? "0" : CRBRPercentMin
      DCOLSHELPercentMin = DCOLSHELPercentMin == undefined ? "0" : DCOLSHELPercentMin
      HULBritPercentMin = HULBritPercentMin == undefined ? "0" : HULBritPercentMin
      jumboPercentMin = jumboPercentMin == undefined ? "0" : jumboPercentMin
      ELKPercentMin = ELKPercentMin == undefined ? "0" : ELKPercentMin
      FMPercentMin = FMPercentMin == undefined ? "0" : FMPercentMin
      LSKPercentMin = LSKPercentMin == undefined ? "0" : LSKPercentMin
      moistPercentMin = moistPercentMin == undefined ? "0" : moistPercentMin
      fanPercentMin = fanPercentMin == undefined ? "0" : fanPercentMin
      vicamPPBMax = vicamPPBMax == undefined ? "0" : vicamPPBMax
      SMKPercentMax = SMKPercentMax == undefined ? "0" : SMKPercentMax
      SSPercentMax = SSPercentMax == undefined ? "0" : SSPercentMax
      okPercentMax = okPercentMax == undefined ? "0" : okPercentMax
      damPercentMax = damPercentMax == undefined ? "0" : damPercentMax
      hullPercentMax = hullPercentMax == undefined ? "0" : hullPercentMax
      frezDamPercentMax = frezDamPercentMax == undefined ? "0" : frezDamPercentMax
      cncleRmdPercentMax = cncleRmdPercentMax == undefined ? "0" : cncleRmdPercentMax
      CRBRPercentMax = CRBRPercentMax == undefined ? "0" : CRBRPercentMax
      DCOLSHELPercentMax = DCOLSHELPercentMax == undefined ? "0" : DCOLSHELPercentMax
      HULBritPercentMax = HULBritPercentMax == undefined ? "0" : HULBritPercentMax
      jumboPercentMax = jumboPercentMax == undefined ? "0" : jumboPercentMax
      ELKPercentMax = ELKPercentMax == undefined ? "0" : ELKPercentMax
      FMPercentMax = FMPercentMax == undefined ? "0" : FMPercentMax
      LSKPercentMax = LSKPercentMax == undefined ? "0" : LSKPercentMax
      moistPercentMax = moistPercentMax == undefined ? "0" : moistPercentMax
      fanPercentMax = fanPercentMax == undefined ? "0" : fanPercentMax

      if (Number(vicamPPBMax) < Number(vicamPPBMin)) {
        showMessage(thisObj, "MaxVicamPPB Percentage should be greater than MinViamPPB Percentage")
        return bformValid
      }

      if (Number(SMKPercentMax) < Number(SMKPercentMin)) {
        showMessage(thisObj, "MaxSMK Percentage should be greater than MinSMK Percentage")
        return bformValid
      }

      if (Number(SSPercentMax) < Number(SSPercentMin)) {
        showMessage(thisObj, "MaxSS Percentage should be greater than MinSS Percentage")
        return bformValid
      }

      if (Number(okPercentMax) < Number(okPercentMin)) {
        showMessage(thisObj, "MaxOK Percentage should be greater than MinOK Percentage")
        return bformValid
      }

      if (Number(damPercentMax) < Number(damPercentMin)) {
        showMessage(thisObj, "MaxDam Percentage should be greater than MinDam Percentage")
        return bformValid
      }

      if (Number(hullPercentMax) < Number(hullPercentMin)) {
        showMessage(thisObj, "MaxHull Percentage should be greater than MinHull Percentage")
        return bformValid
      }

      if (Number(frezDamPercentMax) < Number(frezDamPercentMin)) {
        showMessage(thisObj, "MaxFrezDam Percentage should be greater than MinFrezDam Percentage")
        return bformValid
      }

      if (Number(cncleRmdPercentMax) < Number(cncleRmdPercentMin)) {
        showMessage(thisObj, "MaxCnclRmd Percentage should be greater than MinCnclRmd Percentage")
        return bformValid
      }

      if (Number(CRBRPercentMax) < Number(CRBRPercentMin)) {
        showMessage(thisObj, "MaxCRBR Percentage should be greater than MinCRBR Percentage")
        return bformValid
      }

      if (Number(DCOLSHELPercentMax) < Number(DCOLSHELPercentMin)) {
        showMessage(thisObj, "MaxDCOLSHEL Percentage should be greater than MinDCOLSHEL Percentage")
        return bformValid
      }

      if (Number(HULBritPercentMax) < Number(HULBritPercentMin)) {
        showMessage(thisObj, "MaxHulBrit Percentage should be greater than MinHulBrit Percentage")
        return bformValid
      }

      if (Number(jumboPercentMax) < Number(jumboPercentMin)) {
        showMessage(thisObj, "MaxJumbo Percentage should be greater than MinJumbo Percentage")
        return bformValid
      }

      if (Number(ELKPercentMax) < Number(ELKPercentMin)) {
        showMessage(thisObj, "MaxELK Percentage should be greater than MinELK Percentage")
        return bformValid
      }

      if (Number(FMPercentMax) < Number(FMPercentMin)) {
        showMessage(thisObj, "MaxFM Percentage should be greater than MinFM Percentage")
        return bformValid
      }

      if (Number(LSKPercentMax) < Number(LSKPercentMin)) {
        showMessage(thisObj, "MaxLSK Percentage should be greater than MinLSK Percentage")
        return bformValid
      }

      if (Number(moistPercentMax) < Number(moistPercentMin)) {
        showMessage(thisObj, "MaxMoist Percentage should be greater than MinMoist Percentage")
        return bformValid
      }

      if (Number(fanPercentMax) < Number(fanPercentMin)) {
        showMessage(thisObj, "MaxFan Percentage should be greater than MinFan Percentage")
        return bformValid
      }

      let data = getData(thisObj, 'data')
      let corn_ind = thisObj.values['ddCornIndicator'];
      let flavus_ind = thisObj.values['ddFlavusIndicator'];

      if (corn_ind !== data[0].corn_ind) {
        bformValid = true
      }

      if (flavus_ind !== data[0].flavus_ind) {
        bformValid = true
      }

      if (vicamPPBMin !== data[0].min_vicam_ppb) {
        bformValid = true
      }

      if (SMKPercentMin !== data[0].min_smk_pct) {
        bformValid = true
      }

      if (SSPercentMin !== data[0].min_ss_pct) {
        bformValid = true
      }

      if (okPercentMin !== data[0].min_ok_pct) {
        bformValid = true
      }

      if (damPercentMin !== data[0].min_dam_pct) {
        bformValid = true
      }

      if (hullPercentMin !== data[0].min_hull_pct) {
        bformValid = true
      }

      if (frezDamPercentMin !== data[0].min_frez_dam_pct) {
        bformValid = true
      }

      if (cncleRmdPercentMin !== data[0].min_cncl_rmd_pct) {
        bformValid = true
      }

      if (CRBRPercentMin !== data[0].min_cr_br_pct) {
        bformValid = true
      }

      if (DCOLSHELPercentMin !== data[0].min_dcol_shel_pct) {
        bformValid = true
      }

      if (HULBritPercentMin !== data[0].min_hul_brit_pct) {
        bformValid = true
      }

      if (jumboPercentMin !== data[0].min_jumbo_pct) {
        bformValid = true
      }

      if (ELKPercentMin !== data[0].min_elk_pct) {
        bformValid = true
      }

      if (FMPercentMin !== data[0].min_fm_pct) {
        bformValid = true
      }

      if (LSKPercentMin !== data[0].min_lsk_pct) {
        bformValid = true
      }

      if (moistPercentMin !== data[0].min_moist_pct) {
        bformValid = true
      }

      if (fanPercentMin !== data[0].min_fan_pct) {
        bformValid = true
      }

      if (vicamPPBMax !== data[0].max_vicam_ppb) {
        bformValid = true
      }

      if (SMKPercentMax !== data[0].max_smk_pct) {
        bformValid = true
      }

      if (SSPercentMax !== data[0].max_ss_pct) {
        bformValid = true
      }

      if (okPercentMax !== data[0].max_ok_pct) {
        bformValid = true
      }

      if (damPercentMax !== data[0].max_dam_pct) {
        bformValid = true
      }

      if (hullPercentMax !== data[0].max_hull_pct) {
        bformValid = true
      }

      if (frezDamPercentMax !== data[0].max_frez_dam_pct) {
        bformValid = true
      }

      if (cncleRmdPercentMax !== data[0].max_cncl_rmd_pct) {
        bformValid = true
      }

      if (CRBRPercentMax !== data[0].max_cr_br_pct) {
        bformValid = true
      }

      if (DCOLSHELPercentMax !== data[0].max_dcol_shel_pct) {
        bformValid = true
      }

      if (HULBritPercentMax !== data[0].max_hul_brit_pct) {
        bformValid = true
      }

      if (jumboPercentMax !== data[0].max_jumbo_pct) {
        bformValid = true
      }

      if (ELKPercentMax !== data[0].max_elk_pct) {
        bformValid = true
      }

      if (FMPercentMax !== data[0].max_fm_pct) {
        bformValid = true
      }

      if (LSKPercentMax !== data[0].max_lsk_pct) {
        bformValid = true
      }

      if (moistPercentMax !== data[0].max_moist_pct) {
        bformValid = true
      }

      if (fanPercentMax !== data[0].max_fan_pct) {
        bformValid = true
      }

      if (bformValid == false) {
        showMessage(thisObj, "Peanut Specification Exceptions must differ from the Master Peanut Specifications")
        return bformValid;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  };

  // Add/Update button functionality
  const onbtnOkClick = async() => {
    try {
      setData(thisObj, 'RefreshgridSpecificationExpectations', true)
      let dataObj = getData(thisObj, 'frmPeanutSpecificationExceptionProfile')
      let area_id = dataObj.areaId
      let buy_pt_id = dataObj.buyingPoint_id
      let pnut_type_id = ''
      let pnut_type_name = ''
      let peanutTypeList = thisObj.state['ddPeanutType'].valueList;
      let selectedPeanutTypeValue = thisObj.values['ddPeanutType'];
      if (selectedPeanutTypeValue !== "" && selectedPeanutTypeValue !== undefined) {
        pnut_type_id = peanutTypeList.find(elem => elem.key === selectedPeanutTypeValue).key;
        pnut_type_name = peanutTypeList.find(elem => elem.key === selectedPeanutTypeValue).description;
      }

      let pnut_variety_id = ''
      let pnut_variety_name = ''
      let peanutVarietyList = thisObj.state['ddPeanutVariety'].valueList;
      let selectedpeanutVarietyListValue = thisObj.values['ddPeanutVariety'];
      if (selectedpeanutVarietyListValue !== "" && selectedpeanutVarietyListValue !== undefined) {
        pnut_variety_id = peanutVarietyList.find(elem => elem.key === selectedpeanutVarietyListValue).key;
        pnut_variety_name = peanutVarietyList.find(elem => elem.key === selectedpeanutVarietyListValue).description;
      }

      if (pnut_variety_id == '') {
        pnut_variety_id = null
      }

      let seed_indicator = ''
      let seed_ind = getValue(thisObj, 'chkboxSeed')
      if (seed_ind == true) {
        seed_ind = 'Y'
        seed_indicator = 'Yes'
      }
      else if (seed_ind == false || seed_ind == '' || seed_ind == null || seed_ind == undefined){
        seed_ind = 'N'
        seed_indicator = 'No'
      }

      let seg_type = ''
      let seg_name = ''
      let segTypeList = thisObj.state['ddSegmentType'].valueList;
      let selectedSegmentTypeValue = thisObj.values['ddSegmentType'];
      if (selectedSegmentTypeValue !== "" && selectedSegmentTypeValue !== undefined) {
        seg_type = segTypeList.find(elem => elem.key === selectedSegmentTypeValue).key;
        seg_name = segTypeList.find(elem => elem.key === selectedSegmentTypeValue).description;
      }

      let edible_oil_ind = ''
      let edible_oil_ind_name = ''
      let edibleOilList = thisObj.state['ddEdibleOilIndicator'].valueList;
      let selectedEdibleOilListValue = thisObj.values['ddEdibleOilIndicator'];
      if (selectedEdibleOilListValue !== "" && selectedEdibleOilListValue !== undefined) {
        edible_oil_ind = edibleOilList.find(elem => elem.key === selectedEdibleOilListValue).key;
        edible_oil_ind_name = edibleOilList.find(elem => elem.key === selectedEdibleOilListValue).description;
      }

      let corn_ind = ''
      let cornIndList = thisObj.state['ddCornIndicator'].valueList;
      let selectedCornIndListValue = thisObj.values['ddCornIndicator'];
      if (selectedCornIndListValue !== "" && selectedCornIndListValue !== undefined) {
        corn_ind = cornIndList.find(elem => elem.key === selectedCornIndListValue).key;
      }

      let flavus_ind = ''
      let flavusIndList = thisObj.state['ddFlavusIndicator'].valueList;
      let selectedflavusIndListValue = thisObj.values['ddFlavusIndicator'];
      if (selectedflavusIndListValue !== "" && selectedflavusIndListValue !== undefined) {
        flavus_ind = flavusIndList.find(elem => elem.key === selectedflavusIndListValue).key;
      }

      let res = await SettlementService.RetrievePeanutSpecificationDetails(area_id, pnut_type_id, seed_ind, seg_type, edible_oil_ind)
      setData(thisObj, 'data', res)

      if (FormValid() == false) {
        return;
      }

      let min_vicam_ppb = getValue(thisObj, 'txtVicamPPBMin')
      let min_smk_pct = getValue(thisObj, 'txtSMKPercentMin')
      let min_ss_pct = getValue(thisObj, 'txtSSPercentMin')
      let min_ok_pct = getValue(thisObj, 'txtOKPercentMin')
      let min_dam_pct = getValue(thisObj, 'txtDamPercentMin')
      let min_hull_pct = getValue(thisObj, 'txtHullPercentMin')
      let min_frez_dam_pct = getValue(thisObj, 'txtFrezDamPercentMin')
      let min_cncl_rmd_pct = getValue(thisObj, 'txtCncleRmdPercentMin')
      let min_cr_br_pct = getValue(thisObj, 'txtCRBRPercentMin')
      let min_dcol_shel_pct = getValue(thisObj, 'txtDCOLSHELPercentMin')
      let min_hul_brit_pct = getValue(thisObj, 'txtHULBritPercentMin')
      let min_jumbo_pct = getValue(thisObj, 'txtJumboPercentMin')
      let min_elk_pct = getValue(thisObj, 'txtELKPercentMin')
      let min_fm_pct = getValue(thisObj, 'txtFMPercentMin')
      let min_lsk_pct = getValue(thisObj, 'txtLSKPercentMin')
      let min_moist_pct = getValue(thisObj, 'txtMoistPercentMin')
      let min_fan_pct = getValue(thisObj, 'txtFanPercentMin')
      let max_vicam_ppb = getValue(thisObj, 'txtVicamPPBMax')
      let max_smk_pct = getValue(thisObj, 'txtSMKPercentMax')
      let max_ss_pct = getValue(thisObj, 'txtSSPercentMax')
      let max_ok_pct = getValue(thisObj, 'txtOKPercentMax')
      let max_dam_pct = getValue(thisObj, 'txtDamPercentMax')
      let max_hull_pct = getValue(thisObj, 'txtHullPercentMax')
      let max_frez_dam_pct = getValue(thisObj, 'txtFrezDamPercentMax')
      let max_cncl_rmd_pct = getValue(thisObj, 'txtCncleRmdPercentMax')
      let max_cr_br_pct = getValue(thisObj, 'txtCRBRPercentMax')
      let max_dcol_shel_pct = getValue(thisObj, 'txtDCOLSHELPercentMax')
      let max_hul_brit_pct = getValue(thisObj, 'txtHULBritPercentMax')
      let max_jumbo_pct = getValue(thisObj, 'txtJumboPercentMax')
      let max_elk_pct = getValue(thisObj, 'txtELKPercentMax')
      let max_fm_pct = getValue(thisObj, 'txtFMPercentMax')
      let max_lsk_pct = getValue(thisObj, 'txtLSKPercentMax')
      let max_moist_pct = getValue(thisObj, 'txtMoistPercentMax')
      let max_fan_pct = getValue(thisObj, 'txtFanPercentMax')
    
      if (dataObj.cmdOk_Caption == 'Add') {
        let temp = {
          "comp_id": compId,
          "crop_year": cropYear,
          "buy_pt_id": buy_pt_id,
          "pnut_type_id": pnut_type_id,
          "pnut_variety_id": pnut_variety_id,
          "seed_ind": seed_ind,
          "seg_type": seg_type,
          "edible_oil_ind": edible_oil_ind,
          "corn_ind": corn_ind,
          "flavus_ind": flavus_ind,
          "min_vicam_ppb": min_vicam_ppb == undefined ? "0" : min_vicam_ppb,
          "min_smk_pct": min_smk_pct == undefined ? "0.00" : min_smk_pct,
          "min_ss_pct": min_ss_pct == undefined ? "0.00" : min_ss_pct,
          "min_ok_pct": min_ok_pct == undefined ? "0" : min_ok_pct,
          "min_dam_pct": min_dam_pct == undefined ? "0.00" : min_dam_pct,
          "min_hull_pct": min_hull_pct == undefined ? "0.00" : min_hull_pct,
          "min_frez_dam_pct": min_frez_dam_pct == undefined ? "0.00" : min_frez_dam_pct,
          "min_cncl_rmd_pct": min_cncl_rmd_pct == undefined ? "0.00" : min_cncl_rmd_pct,
          "min_cr_br_pct": min_cr_br_pct == undefined ? "0.00" : min_cr_br_pct,
          "min_dcol_shel_pct": min_dcol_shel_pct == undefined ? "0.00" : min_dcol_shel_pct,
          "min_hul_brit_pct": min_hul_brit_pct == undefined ? "0.00" : min_hul_brit_pct,
          "min_jumbo_pct": min_jumbo_pct == undefined ? "0.00" : min_jumbo_pct,
          "min_elk_pct": min_elk_pct == undefined ? "0.00" : min_elk_pct,
          "min_fm_pct": min_fm_pct == undefined ? "0.00" : min_fm_pct,
          "min_lsk_pct": min_lsk_pct == undefined ? "0.00" : min_lsk_pct,
          "min_moist_pct": min_moist_pct == undefined ? "0.00" : min_moist_pct,
          "min_fan_pct": min_fan_pct == undefined ? "0.00" : min_fan_pct,
          "max_vicam_ppb": max_vicam_ppb == undefined ? "0" : max_vicam_ppb,
          "max_smk_pct": max_smk_pct == undefined ? "0.00" : max_smk_pct,
          "max_ss_pct": max_ss_pct == undefined ? "0.00" : max_ss_pct,
          "max_ok_pct": max_ok_pct == undefined ? "0.00" : max_ok_pct,
          "max_dam_pct": max_dam_pct == undefined ? "0.00" : max_dam_pct,
          "max_hull_pct": max_hull_pct == undefined ? "0.00" : max_hull_pct,
          "max_frez_dam_pct": max_frez_dam_pct == undefined ? "0.00" : max_frez_dam_pct,
          "max_cncl_rmd_pct": max_cncl_rmd_pct == undefined ? "0.00" : max_cncl_rmd_pct,
          "max_cr_br_pct": max_cr_br_pct == undefined ? "0.00" : max_cr_br_pct,
          "max_dcol_shel_pct": max_dcol_shel_pct == undefined ? "0.00" : max_dcol_shel_pct,
          "max_hul_brit_pct": max_hul_brit_pct == undefined ? "0.00" : max_hul_brit_pct,
          "max_jumbo_pct": max_jumbo_pct == undefined ? "0.00" : max_jumbo_pct,
          "max_elk_pct": max_elk_pct == undefined ? "0.00" : max_elk_pct,
          "max_fm_pct": max_fm_pct == undefined ? "0.00" : max_fm_pct,
          "max_lsk_pct": max_lsk_pct == undefined ? "0.00" : max_lsk_pct,
          "max_moist_pct": max_moist_pct == undefined ? "0.00" : max_moist_pct,
          "max_fan_pct": max_fan_pct == undefined ? "0.00" : max_fan_pct,
          "user_id": userId
        }

        let response = await SystemMaintenanceMasterManagementService.CreatePeanutSpecificationException(temp)
        let data = response
        if (data.status == 404 || data.status == 500 || data.status == null || data.status == '') {
          showMessage(thisObj, "An error occured while Adding the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist.")
          showMessage(thisObj, response.message)
          return;
        }

        let Array = []
        let Obj = {}
        Obj.area_id = area_id
        Obj.pnut_type_id = pnut_type_id
        Obj.pnut_type_name = pnut_type_name
        Obj.pnut_variety_id = pnut_variety_id
        Obj.pnut_variety_name = pnut_variety_name
        Obj.seed_ind = seed_ind
        Obj.seed_indicator = seed_indicator
        Obj.seg_type =seg_type
        Obj.seg_name = seg_name
        Obj.edible_oil_ind = edible_oil_ind
        Obj.edible_oil_ind_name = edible_oil_ind_name
        Array.push(Obj)
        setData(thisObj, 'frmPeanutSpecificationSetupBuyingPoint', Obj)
        document.getElementById("SystemMaintenanceMasterManagement_PeanutSpecificationExceptionProfilePopUp").childNodes[0].click()
      }

      if (dataObj.cmdOk_Caption == 'Update') {
        let temp = {
          "corn_ind": corn_ind,
          "flavus_ind": flavus_ind,
          "min_vicam_ppb": Number(min_vicam_ppb) == undefined ? "0" : Number(min_vicam_ppb),
          "min_smk_pct": Number(min_smk_pct) == undefined ? "0.00" : Number(min_smk_pct),
          "min_ss_pct": Number(min_ss_pct) == undefined ? "0.00" : Number(min_ss_pct),
          "min_ok_pct": Number(min_ok_pct) == undefined ? "0" : Number(min_ok_pct),
          "min_dam_pct": Number(min_dam_pct) == undefined ? "0.00" : Number(min_dam_pct),
          "min_hull_pct": Number(min_hull_pct) == undefined ? "0.00" : Number(min_hull_pct),
          "min_frez_dam_pct": Number(min_frez_dam_pct) == undefined ? "0.00" : Number(min_frez_dam_pct),
          "min_cncl_rmd_pct": Number(min_cncl_rmd_pct) == undefined ? "0.00" : Number(min_cncl_rmd_pct),
          "min_cr_br_pct": Number(min_cr_br_pct) == undefined ? "0.00" : Number(min_cr_br_pct),
          "min_dcol_shel_pct": Number(min_dcol_shel_pct) == undefined ? "0.00" : Number(min_dcol_shel_pct),
          "min_hul_brit_pct": Number(min_hul_brit_pct) == undefined ? "0.00" : Number(min_hul_brit_pct),
          "min_jumbo_pct": Number(min_jumbo_pct) == undefined ? "0.00" : Number(min_jumbo_pct),
          "min_elk_pct": Number(min_elk_pct) == undefined ? "0.00" : Number(min_elk_pct),
          "min_fm_pct": Number(min_fm_pct) == undefined ? "0.00" : Number(min_fm_pct),
          "min_lsk_pct": Number(min_lsk_pct) == undefined ? "0.00" : Number(min_lsk_pct),
          "min_moist_pct": Number(min_moist_pct) == undefined ? "0.00" : Number(min_moist_pct),
          "min_fan_pct": Number(min_fan_pct) == undefined ? "0.00" : Number(min_fan_pct),
          "max_vicam_ppb": Number(max_vicam_ppb) == undefined ? "0" : Number(max_vicam_ppb),
          "max_smk_pct": Number(max_smk_pct) == undefined ? "0.00" : Number(max_smk_pct),
          "max_ss_pct": Number(max_ss_pct) == undefined ? "0.00" : Number(max_ss_pct),
          "max_ok_pct": Number(max_ok_pct) == undefined ? "0.00" : Number(max_ok_pct),
          "max_dam_pct": Number(max_dam_pct) == undefined ? "0.00" : Number(max_dam_pct),
          "max_hull_pct": Number(max_hull_pct) == undefined ? "0.00" : Number(max_hull_pct),
          "max_frez_dam_pct": Number(max_frez_dam_pct) == undefined ? "0.00" : Number(max_frez_dam_pct),
          "max_cncl_rmd_pct": Number(max_cncl_rmd_pct) == undefined ? "0.00" : Number(max_cncl_rmd_pct),
          "max_cr_br_pct": Number(max_cr_br_pct) == undefined ? "0.00" : Number(max_cr_br_pct),
          "max_dcol_shel_pct": Number(max_dcol_shel_pct) == undefined ? "0.00" : Number(max_dcol_shel_pct),
          "max_hul_brit_pct": Number(max_hul_brit_pct) == undefined ? "0.00" : Number(max_hul_brit_pct),
          "max_jumbo_pct": Number(max_jumbo_pct) == undefined ? "0.00" : Number(max_jumbo_pct),
          "max_elk_pct": Number(max_elk_pct) == undefined ? "0.00" : Number(max_elk_pct),
          "max_fm_pct": Number(max_fm_pct) == undefined ? "0.00" : Number(max_fm_pct),
          "max_lsk_pct": Number(max_lsk_pct) == undefined ? "0.00" : Number(max_lsk_pct),
          "max_moist_pct": Number(max_moist_pct) == undefined ? "0.00" : Number(max_moist_pct),
          "max_fan_pct": Number(max_fan_pct) == undefined ? "0.00" : Number(max_fan_pct),
          "user_id": userId
        }

        let response = await SystemMaintenanceMasterManagementService.UpdatePeanutSpecificationException(buy_pt_id, pnut_type_id, pnut_variety_id, seed_ind, seg_type, edible_oil_ind, temp)
        let data = response
        if (data.status == 404 || data.status == 500 || data.status == null || data.status == '') {
          showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist.")
          showMessage(thisObj, response.message)
          return;
        }
        let Array = []
        let Obj = {}
        Obj.cmdEnableDisableControls_Value = true
        Array.push(Obj)
        setData(thisObj, 'frmPeanutSpecificationSetupBuyingPoint', Obj)
        document.getElementById("SystemMaintenanceMasterManagement_PeanutSpecificationExceptionProfilePopUp").childNodes[0].click()
      }
    }
    catch(err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click" +err.message
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnOkClick = onbtnOkClick

  //Click event for Cancel button
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_PeanutSpecificationExceptionProfilePopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PeanutSpecificationExceptionProfile*/}

              {/* END_USER_CODE-USER_BEFORE_PeanutSpecificationExceptionProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPeanutSpecificationExceptionProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPeanutSpecificationExceptionProfile*/}

              <GroupBoxWidget
                conf={state.grpbxPeanutSpecificationExceptionProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBuyingPoint}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_txtBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutVariety}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                {/* START_USER_CODE-USER_BEFORE_lblSeed*/}

                {/* END_USER_CODE-USER_BEFORE_lblSeed*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSeed}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSeed*/}

                {/* END_USER_CODE-USER_AFTER_lblSeed*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxSeed*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxSeed*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxSeed}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxSeed*/}

                {/* END_USER_CODE-USER_AFTER_chkboxSeed*/}
                {/* START_USER_CODE-USER_BEFORE_ddSegmentType*/}

                {/* END_USER_CODE-USER_BEFORE_ddSegmentType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSegmentType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSegmentType*/}

                {/* END_USER_CODE-USER_AFTER_ddSegmentType*/}
                {/* START_USER_CODE-USER_BEFORE_ddEdibleOilIndicator*/}

                {/* END_USER_CODE-USER_BEFORE_ddEdibleOilIndicator*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddEdibleOilIndicator}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddEdibleOilIndicator*/}

                {/* END_USER_CODE-USER_AFTER_ddEdibleOilIndicator*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxFlavusIndicator*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFlavusIndicator*/}

                <GroupBoxWidget
                  conf={state.grpbxFlavusIndicator}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_ddFlavusIndicator*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFlavusIndicator*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFlavusIndicator}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFlavusIndicator*/}

                  {/* END_USER_CODE-USER_AFTER_ddFlavusIndicator*/}
                  {/* START_USER_CODE-USER_BEFORE_lblMinimum*/}

                  {/* END_USER_CODE-USER_BEFORE_lblMinimum*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblMinimum}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblMinimum*/}

                  {/* END_USER_CODE-USER_AFTER_lblMinimum*/}
                  {/* START_USER_CODE-USER_BEFORE_lblMaximum*/}

                  {/* END_USER_CODE-USER_BEFORE_lblMaximum*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblMaximum}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblMaximum*/}

                  {/* END_USER_CODE-USER_AFTER_lblMaximum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVicamPPBMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVicamPPBMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVicamPPBMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVicamPPBMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtVicamPPBMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVicamPPBMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVicamPPBMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVicamPPBMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVicamPPBMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtVicamPPBMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSMKPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMKPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMKPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMKPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMKPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSMKPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMKPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMKPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMKPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMKPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSSPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSSPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSSPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSSPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtSSPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSSPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSSPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSSPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSSPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtSSPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOKPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOKPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOKPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOKPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtOKPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOKPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOKPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOKPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOKPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtOKPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDamPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDamPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDamPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDamPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtDamPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDamPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDamPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDamPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDamPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtDamPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHullPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHullPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHullPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHullPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtHullPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHullPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHullPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHullPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHullPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtHullPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFrezDamPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFrezDamPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFrezDamPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFrezDamPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtFrezDamPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFrezDamPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFrezDamPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFrezDamPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFrezDamPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtFrezDamPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCncleRmdPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCncleRmdPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCncleRmdPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCncleRmdPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtCncleRmdPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCncleRmdPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCncleRmdPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCncleRmdPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCncleRmdPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtCncleRmdPercentMax*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFlavusIndicator*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFlavusIndicator*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxCornIndicator*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCornIndicator*/}

                <GroupBoxWidget
                  conf={state.grpbxCornIndicator}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_ddCornIndicator*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCornIndicator*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCornIndicator}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCornIndicator*/}

                  {/* END_USER_CODE-USER_AFTER_ddCornIndicator*/}
                  {/* START_USER_CODE-USER_BEFORE_lblMin*/}

                  {/* END_USER_CODE-USER_BEFORE_lblMin*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblMin}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblMin*/}

                  {/* END_USER_CODE-USER_AFTER_lblMin*/}
                  {/* START_USER_CODE-USER_BEFORE_lblMax*/}

                  {/* END_USER_CODE-USER_BEFORE_lblMax*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblMax}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblMax*/}

                  {/* END_USER_CODE-USER_AFTER_lblMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCRBRPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCRBRPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCRBRPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCRBRPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtCRBRPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCRBRPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCRBRPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCRBRPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCRBRPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtCRBRPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDCOLSHELPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDCOLSHELPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDCOLSHELPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDCOLSHELPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtDCOLSHELPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDCOLSHELPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDCOLSHELPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDCOLSHELPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDCOLSHELPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtDCOLSHELPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHULBritPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHULBritPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHULBritPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHULBritPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtHULBritPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHULBritPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHULBritPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHULBritPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHULBritPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtHULBritPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtJumboPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtJumboPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtJumboPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtJumboPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtJumboPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtJumboPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtJumboPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtJumboPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtJumboPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtJumboPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtELKPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtELKPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtELKPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtELKPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtELKPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtELKPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtELKPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtELKPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtELKPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtELKPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFMPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFMPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFMPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFMPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtFMPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFMPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFMPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFMPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFMPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtFMPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLSKPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSKPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSKPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSKPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSKPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLSKPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSKPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSKPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSKPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSKPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMoistPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMoistPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMoistPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMoistPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtMoistPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMoistPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMoistPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMoistPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMoistPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtMoistPercentMax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFanPercentMin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFanPercentMin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFanPercentMin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFanPercentMin*/}

                  {/* END_USER_CODE-USER_AFTER_txtFanPercentMin*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFanPercentMax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFanPercentMax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFanPercentMax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFanPercentMax*/}

                  {/* END_USER_CODE-USER_AFTER_txtFanPercentMax*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCornIndicator*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCornIndicator*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPeanutSpecificationExceptionProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPeanutSpecificationExceptionProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_PeanutSpecificationExceptionProfile*/}

              {/* END_USER_CODE-USER_AFTER_PeanutSpecificationExceptionProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_PeanutSpecificationExceptionProfile
);
