/* eslint-disable*/
import React from "react";
import axios from 'axios';

//let baseApiURI = `${process.env.REACT_APP_TT_API_ENDPOINT_BASE_URL}`;
const baseApiURI = (url) => {
    if (process.env.REACT_APP_ENVIR === 'LOCAL') {
        if (url.split('/')[1].toLowerCase() == 'settlement') {
            return `${process.env.REACT_APP_TT_API_ENDPOINT_BASE_URL.replace('apimaznappsdev01', 'apimaznappsdev02')}`;
        }
        else if (url.split('/')[1].toLowerCase() == 'accountpayment') {
            return `${process.env.REACT_APP_TT_API_ENDPOINT_BASE_URL.replace('apimaznappsdev01', 'apimaznappsdev03')}`;
        }
        else if (url.split('/')[1].toLowerCase() == 'systemmaintenance') {
            return `${process.env.REACT_APP_TT_API_ENDPOINT_BASE_URL.replace('apimaznappsdev01', 'apimaznappsdev04')}`;
        }
        else if (url.split('/')[1].toLowerCase() == 'warehousereceipts') {
            return `${process.env.REACT_APP_TT_API_ENDPOINT_BASE_URL.replace('apimaznappsdev01', 'apimaznappsdev05')}`;
        }
        else if (url.split('/')[1].toLowerCase() == 'stocktransfer') {
            return `${process.env.REACT_APP_TT_API_ENDPOINT_BASE_URL.replace('apimaznappsdev01', 'apimaznappsdev06')}`;
        }
    }
    return `${process.env.REACT_APP_TT_API_ENDPOINT_BASE_URL}`;
}

export const ApiService = {
    getData,
    postData,
    updateData,
    removeData,
    postFileUpload
};

axios.interceptors.response.use(response => {
    return response;
},
    (error) => {
        const { data, status } = error.response;
        switch (status) {
            case 400:
                return error.response;
            case 404:
                return error.response;
            case 500:
                return error.response;
            default:
                if (data != undefined && data != '' && data.message != '') {
                    alert("Error occurred during operation. Status Code : " + status + " ; Error Meesage : " + data.message)
                } else {
                    alert("Error occurred during operation. Status Code : " + status + " ; Error Meesage : " + error.response.statusText)
                }
                return Promise.reject();
        }
    });

const headerConfig = () => {
    const config = {
        headers: {
            'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_APIM_SUB_KEY}`
        },
    };
    return config;
}

export function getData(url) {
    let config = headerConfig();
    return axios.get(`${baseApiURI(url) + url}`, config)
        .then(resp => {
            if (resp !== undefined && resp.status == 200) {
                if (resp.data.result !== undefined) {
                    return resp.data.result;
                } else {
                    return resp.data;
                }
            }
            else if(resp.status == 500){
                return resp.data
            }
             else {
                return [];
            }
        })
};

export function postData(url, data) {
    let config = headerConfig()
    return axios.post(`${baseApiURI(url) + url}`, data, config)
        .then(resp => {
            if (resp != undefined && resp.status == 200) {
                return {
                    status: resp.status,
                    message: resp.data.message,
                    result: resp.data.result
                }
            }
            else if ([400, 404, 500].includes(resp.status)) {
                if (resp.data != undefined && resp.data != '' && resp.data.message != '') {
                    return {
                        status: resp.status,
                        message: "Error occurred during operation. Status Code : " + resp.status + " ; Error Meesage : " + resp.data.message,
                        result: ''
                    }
                }
            }

            return {
                status: resp.status,
                message: "Error occurred during operation. Status Code : " + resp.status + " ; Error Meesage : " + resp.statusText,
                result: ''
            }
        })
};

export function updateData(url, data) {
    let config = headerConfig();
    return axios.put(`${baseApiURI(url) + url}`, data, config)
        .then(resp => {
            if (resp !== undefined && resp.status == 200) {
                return {
                    status: resp.status,
                    message: resp.data.message,
                    result: resp.data.result
                }
            }
            else if ([400, 404, 500].includes(resp.status)) {
                if (resp.data != undefined && resp.data != '' && resp.data.message != '') {
                    return {
                        status: resp.status,
                        message: "Error occurred during operation. Status Code : " + resp.status + " ; Error Meesage : " + resp.data.message,
                        result: ''
                    }
                }
            }

            return {
                status: resp.status,
                message: "Error occurred during operation. Status Code : " + resp.status + " ; Error Meesage : " + resp.statusText,
                result: ''
            }
        })
};

export function removeData(url) {
    let config = headerConfig(null);
    return axios.delete(`${baseApiURI(url) + url}`, config)
        .then(resp => {
            if (resp !== undefined && resp.status == 200) {
                return {
                    status: resp.status,
                    message: resp.data.message,
                    result: resp.data.result
                }
            }
            else if ([400, 404, 500].includes(resp.status)) {
                if (resp.data != undefined && resp.data != '' && resp.data.message != '') {
                    return {
                        status: resp.status,
                        message: "Error occurred during operation. Status Code : " + resp.status + " ; Error Meesage : " + resp.data.message,
                        result: ''
                    }
                }
            }

            return {
                status: resp.status,
                message: "Error occurred during operation. Status Code : " + resp.status + " ; Error Meesage : " + resp.statusText,
                result: ''
            }
        })
};

export function postFileUpload(url, data) {
    let options = {
        method: 'post',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_APIM_SUB_KEY}`
        }
        , body: data
    };
    return fetch(`${baseApiURI(url)}` + url, options).then(response => response.json())
        .then(resp => {
            return resp;
        })
        .catch((error) => {
        });
};
