/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  DateWidget,
  getData,
  setValue,
  disable
} from "../../shared/WindowImports";

import "./ManualDPRInvHistView.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../../SystemMaintenanceSpecialFunctions/Service/SystemMaintenanceSpecialFunctionsService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import {FormatNumber} from "../../Common/CommonFunctions"
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_ManualDPRInvHistView(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ManualDPRInvHistView",
    windowName: "ManualDPRInvHistView",
    template: "Standard Screen",
    Cols: "4",
    ColsForMobile: "4",
    ColsForTabLandscape: "4",
    ColsForTabPotrait: "4",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.ManualDPRInvHistView",
    // START_USER_CODE-USER_ManualDPRInvHistView_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "DPR Inventory Manual Adjustment Audit History Detail View",
      scrCode: "PN0320D",
    },
    // END_USER_CODE-USER_ManualDPRInvHistView_PROPERTIES
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "cmndCntnrManDPR",
      Label: "Exit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    dtTime: {
      name: "dtTime",
      type: "TextBoxWidget",
      parent: "grpbxManualDPR",
      Label: "Date Time:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_dtTime_PROPERTIES

      // END_USER_CODE-USER_dtTime_PROPERTIES
    },
    lblAfter: {
      name: "lblAfter",
      type: "LabelWidget",
      parent: "grpbxBeforeuic0",
      Label: "After",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAfter_PROPERTIES

      // END_USER_CODE-USER_lblAfter_PROPERTIES
    },
    lblBefore: {
      name: "lblBefore",
      type: "LabelWidget",
      parent: "grpbxBefore",
      Label: "Before",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBefore_PROPERTIES

      // END_USER_CODE-USER_lblBefore_PROPERTIES
    },
    txtAction: {
      name: "txtAction",
      type: "TextBoxWidget",
      parent: "grpbxManualDPR",
      Label: "Action:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAction_PROPERTIES

      // END_USER_CODE-USER_txtAction_PROPERTIES
    },
    txtAudit: {
      name: "txtAudit",
      type: "TextBoxWidget",
      parent: "grpbxManualDPR",
      Label: "Audit BP Loc:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAudit_PROPERTIES

      // END_USER_CODE-USER_txtAudit_PROPERTIES
    },
    txtAuditDate: {
      name: "txtAuditDate",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Audit Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAuditDate_PROPERTIES

      // END_USER_CODE-USER_txtAuditDate_PROPERTIES
    },
    txtAuditDateAftr: {
      name: "txtAuditDateAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Audit Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAuditDateAftr_PROPERTIES

      // END_USER_CODE-USER_txtAuditDateAftr_PROPERTIES
    },
    txtBuyngPt: {
      name: "txtBuyngPt",
      type: "TextBoxWidget",
      parent: "grpbxManualDPR",
      Label: "Buying Point:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyngPt_PROPERTIES

      // END_USER_CODE-USER_txtBuyngPt_PROPERTIES
    },
    txtCompOwn: {
      name: "txtCompOwn",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Comp Owned Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCompOwn_PROPERTIES

      // END_USER_CODE-USER_txtCompOwn_PROPERTIES
    },
    txtCompOwnAftr: {
      name: "txtCompOwnAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Comp Owned Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCompOwnAftr_PROPERTIES

      // END_USER_CODE-USER_txtCompOwnAftr_PROPERTIES
    },
    txtIntransit: {
      name: "txtIntransit",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Intransit Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIntransit_PROPERTIES

      // END_USER_CODE-USER_txtIntransit_PROPERTIES
    },
    txtIntransitAftr: {
      name: "txtIntransitAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Intransit Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIntransitAftr_PROPERTIES

      // END_USER_CODE-USER_txtIntransitAftr_PROPERTIES
    },
    txtNotRecAdj: {
      name: "txtNotRecAdj",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Not Receipted Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNotRecAdj_PROPERTIES

      // END_USER_CODE-USER_txtNotRecAdj_PROPERTIES
    },
    txtNotReceiptedAftr: {
      name: "txtNotReceiptedAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Not Receipted Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNotReceiptedAftr_PROPERTIES

      // END_USER_CODE-USER_txtNotReceiptedAftr_PROPERTIES
    },
    txtOleicInd: {
      name: "txtOleicInd",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Oleic Ind:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOleicInd_PROPERTIES

      // END_USER_CODE-USER_txtOleicInd_PROPERTIES
    },
    txtOleicIndAftr: {
      name: "txtOleicIndAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Oleic Ind:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOleicIndAftr_PROPERTIES

      // END_USER_CODE-USER_txtOleicIndAftr_PROPERTIES
    },
    txtOrgncInd: {
      name: "txtOrgncInd",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Organic Ind:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOrgncInd_PROPERTIES

      // END_USER_CODE-USER_txtOrgncInd_PROPERTIES
    },
    txtOrgncIndAftr: {
      name: "txtOrgncIndAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Organic Ind:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOrgncIndAftr_PROPERTIES

      // END_USER_CODE-USER_txtOrgncIndAftr_PROPERTIES
    },
    txtPhysclAdj: {
      name: "txtPhysclAdj",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Physical Adj.:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhysclAdj_PROPERTIES

      // END_USER_CODE-USER_txtPhysclAdj_PROPERTIES
    },
    txtPhysclAdjAftr: {
      name: "txtPhysclAdjAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Physical Adj.:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhysclAdjAftr_PROPERTIES

      // END_USER_CODE-USER_txtPhysclAdjAftr_PROPERTIES
    },
    txtPnutTyp: {
      name: "txtPnutTyp",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Peanut Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPnutTyp_PROPERTIES

      // END_USER_CODE-USER_txtPnutTyp_PROPERTIES
    },
    txtPnutTypAftr: {
      name: "txtPnutTypAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Peanut Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPnutTypAftr_PROPERTIES

      // END_USER_CODE-USER_txtPnutTypAftr_PROPERTIES
    },
    txtPnutVrty: {
      name: "txtPnutVrty",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Peanut Variety:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPnutVrty_PROPERTIES

      // END_USER_CODE-USER_txtPnutVrty_PROPERTIES
    },
    txtPnutVrtyAftr: {
      name: "txtPnutVrtyAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Peanut Variety:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPnutVrtyAftr_PROPERTIES

      // END_USER_CODE-USER_txtPnutVrtyAftr_PROPERTIES
    },
    txtReason: {
      name: "txtReason",
      type: "TextBoxWidget",
      parent: "grpbxManualDPR",
      Label: "Reason:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReason_PROPERTIES

      // END_USER_CODE-USER_txtReason_PROPERTIES
    },
    txtSeedGen: {
      name: "txtSeedGen",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Seed Generation:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedGen_PROPERTIES

      // END_USER_CODE-USER_txtSeedGen_PROPERTIES
    },
    txtSeedGenAftr: {
      name: "txtSeedGenAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Seed Generation:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedGenAftr_PROPERTIES

      // END_USER_CODE-USER_txtSeedGenAftr_PROPERTIES
    },
    txtSegTyp: {
      name: "txtSegTyp",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Seg Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegTyp_PROPERTIES

      // END_USER_CODE-USER_txtSegTyp_PROPERTIES
    },
    txtSegTypAftr: {
      name: "txtSegTypAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Seg Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegTypAftr_PROPERTIES

      // END_USER_CODE-USER_txtSegTypAftr_PROPERTIES
    },
    txtShrinkAdj: {
      name: "txtShrinkAdj",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Shrink Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrinkAdj_PROPERTIES

      // END_USER_CODE-USER_txtShrinkAdj_PROPERTIES
    },
    txtShrinkAdjAftr: {
      name: "txtShrinkAdjAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Shrink Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrinkAdjAftr_PROPERTIES

      // END_USER_CODE-USER_txtShrinkAdjAftr_PROPERTIES
    },
    txtSourceTxt: {
      name: "txtSourceTxt",
      type: "TextBoxWidget",
      parent: "grpbxManualDPR",
      Label: "Source Text:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSourceTxt_PROPERTIES

      // END_USER_CODE-USER_txtSourceTxt_PROPERTIES
    },
    txtUndetrmndAdj: {
      name: "txtUndetrmndAdj",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Undetermined Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUndetrmndAdj_PROPERTIES

      // END_USER_CODE-USER_txtUndetrmndAdj_PROPERTIES
    },
    txtUndetrmndAdjAftr: {
      name: "txtUndetrmndAdjAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Undetermined Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUndetrmndAdjAftr_PROPERTIES

      // END_USER_CODE-USER_txtUndetrmndAdjAftr_PROPERTIES
    },
    txtUser: {
      name: "txtUser",
      type: "TextBoxWidget",
      parent: "grpbxManualDPR",
      Label: "User:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUser_PROPERTIES

      // END_USER_CODE-USER_txtUser_PROPERTIES
    },
    txtWhs: {
      name: "txtWhs",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Whs/Bin:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhs_PROPERTIES

      // END_USER_CODE-USER_txtWhs_PROPERTIES
    },
    txtWhsAftr: {
      name: "txtWhsAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Whs/Bin:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhsAftr_PROPERTIES

      // END_USER_CODE-USER_txtWhsAftr_PROPERTIES
    },
    txtWhsRec: {
      name: "txtWhsRec",
      type: "TextBoxWidget",
      parent: "grpbxBefore",
      Label: "Whs Receipt Adj.:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhsRec_PROPERTIES

      // END_USER_CODE-USER_txtWhsRec_PROPERTIES
    },
    txtWhsRecAftr: {
      name: "txtWhsRecAftr",
      type: "TextBoxWidget",
      parent: "grpbxBeforeuic0",
      Label: "Whs Receipt Adj.:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhsRecAftr_PROPERTIES

      // END_USER_CODE-USER_txtWhsRecAftr_PROPERTIES
    },
    grpbxManualDPR: {
      name: "grpbxManualDPR",
      type: "GroupBoxWidget",
      parent: "ManualDPRInvHistView",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxManualDPR_PROPERTIES

      // END_USER_CODE-USER_grpbxManualDPR_PROPERTIES
    },
    grpbxBefore: {
      name: "grpbxBefore",
      type: "GroupBoxWidget",
      parent: "ManualDPRInvHistView",
      ColSpan: "2",
      Height: "",
      Width: "",
      clonedExtId: "42066",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxBefore_PROPERTIES

      // END_USER_CODE-USER_grpbxBefore_PROPERTIES
    },
    grpbxBeforeuic0: {
      name: "grpbxBeforeuic0",
      type: "GroupBoxWidget",
      parent: "ManualDPRInvHistView",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxBeforeuic0_PROPERTIES

      // END_USER_CODE-USER_grpbxBeforeuic0_PROPERTIES
    },
    cmndCntnrManDPR: {
      name: "cmndCntnrManDPR",
      type: "CommandContainerWidget",
      parent: "ManualDPRInvHistView",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      // START_USER_CODE-USER_cmndCntnrManDPR_PROPERTIES

      // END_USER_CODE-USER_cmndCntnrManDPR_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  const [loading, setLoading] = useState(false);

  // START_USER_CODE-USER_METHODS
  let FormLoad=async ()=>{
    setLoading(true)
    await form1Disable()
    await form2Disable()
    await form3Disable()
    let parentData=getData(thisObj,'ManualDPRInvHistSearchData')
    let lstrRecordKey=parentData.txtcolRecordID
    await bfillForm(lstrRecordKey)
    setLoading(false)
  }

  //Disable the textboxes of first part of the page
  let form1Disable=async()=>{
    disable(thisObj,'txtAction')
    disable(thisObj,'txtUser')
    disable(thisObj,'dtTime')
    disable(thisObj,'txtReason')
    disable(thisObj,'txtBuyngPt')
    disable(thisObj,'txtAudit')
    disable(thisObj,'txtSourceTxt')
  }

  //Disable the textboxes of second part of the page
  let form2Disable=async()=>{
    disable(thisObj,'txtWhs')
    disable(thisObj,'txtPnutTyp')
    disable(thisObj,'txtPnutVrty')
    disable(thisObj,'txtSeedGen')
    disable(thisObj,'txtSegTyp')
    disable(thisObj,'txtOleicInd')
    disable(thisObj,'txtOrgncInd')
    disable(thisObj,'txtAuditDate')
    disable(thisObj,'txtPhysclAdj')
    disable(thisObj,'txtWhsRec')
    disable(thisObj,'txtShrinkAdj')
    disable(thisObj,'txtNotRecAdj')
    disable(thisObj,'txtUndetrmndAdj')
    disable(thisObj,'txtCompOwn')
    disable(thisObj,'txtIntransit')
  }

  //Disable the textboxes of third part of the page
  let form3Disable=async()=>{
    disable(thisObj,'txtWhsAftr')
    disable(thisObj,'txtPnutTypAftr')
    disable(thisObj,'txtPnutVrtyAftr')
    disable(thisObj,'txtSeedGenAftr')
    disable(thisObj,'txtSegTypAftr')
    disable(thisObj,'txtOleicIndAftr')
    disable(thisObj,'txtOrgncIndAftr')
    disable(thisObj,'txtAuditDateAftr')
    disable(thisObj,'txtPhysclAdjAftr')
    disable(thisObj,'txtWhsRecAftr')
    disable(thisObj,'txtShrinkAdjAftr')
    disable(thisObj,'txtNotReceiptedAftr')
    disable(thisObj,'txtUndetrmndAdjAftr')
    disable(thisObj,'txtCompOwnAftr')
    disable(thisObj,'txtIntransitAftr')
  }

  //The bfillForm function will set the values in all the the textboxes of the screen as per the record key we get from the parent screen 
  let bfillForm=async(lstrRecordKey)=>{
    try{
    if(lstrRecordKey==''){
      return
    }
    let response=await SystemMaintenanceSpecialFunctionsService.RetrieveManualInvestmaintDetails('HIST_INQ',lstrRecordKey,null)
    let data=response[0].inv_maint_hists[0];

    if(data!=null || data!=undefined || data!=''){
      setValue(thisObj,'txtAction',data.chg_maint_action.trim())
      setValue(thisObj,'txtUser',data.chg_maint_user+" -- "+data.chg_maint_user_name.trim())
      setValue(thisObj,'dtTime',moment(data.chg_maint_date_time).format('MM:DD:YYYY hh:mm:ss A'))
      setValue(thisObj,'txtReason',data.chg_maint_reason.trim())
      setValue(thisObj,'txtBuyngPt',data.buy_pt_id.trim() + " -- "+data.buy_pt_name.trim())
      setValue(thisObj,'txtAudit',data.audit_buy_pt.trim()+ " -- "+data.audit_buy_pt_name.trim())
      setValue(thisObj,'txtSourceTxt','')
      if(data.audit_insp_num.trim()!==""){
        document.getElementsByClassName('txtSourceTxt')[0].childNodes[0].childNodes[0].innerText='SC95# / 1007#:'
        setValue(thisObj,'txtSourceTxt',data.audit_insp_num + " / "+data.audit_settle_num)
      }

      if(data.audit_whse_rcpt.trim()!==""){
        setValue(thisObj,'txtSourceTxt',data.audit_whse_rcpt)
        document.getElementsByClassName('txtSourceTxt')[0].childNodes[0].childNodes[0].innerText="Whs Receipt#"
      }

      if(data.audit_xfer_num.trim()!==""){
        setValue(thisObj,'txtSourceTxt',data.audit_xfer_num)
        document.getElementsByClassName('txtSourceTxt')[0].childNodes[0].childNodes[0].innerText="Transfer#:"
      }

      if(data.audit_trade_insp.trim()!==""){
        setValue(thisObj,'txtSourceTxt',data.audit_trade_insp)
        document.getElementsByClassName('txtSourceTxt')[0].childNodes[0].childNodes[0].innerText="Trade Settle#:"
      }

      if(data.audit_user.trim()!==""){
        setValue(thisObj,'txtSourceTxt',data.audit_user)
        document.getElementsByClassName('txtSourceTxt')[0].childNodes[0].childNodes[0].innerText="Manual User#:"
      }

      if(data.old_whouse_num.trim()!=""){
        setValue(thisObj,'txtWhs',data.old_whouse_num+ "/" + data.old_bin_num+ " -- " + data.old_whs_bin_id);
      }
      else{
        setValue(thisObj,'txtWhs',"");
      }

      if(data.new_whouse_num.trim()!=""){
        setValue(thisObj,'txtWhsAftr',data.new_whouse_num+ "/" + data.new_bin_num+ " -- " + data.new_whs_bin_id);
      }
      else{
        setValue(thisObj,'txtWhsAftr',"");
      }

      if(data.old_pnut_type_id.trim()!=""){
        setValue(thisObj,'txtPnutTyp',data.old_pnut_type_id+ " -- " + data.old_pnut_type_name);
      }
      else{
        setValue(thisObj,'txtPnutTyp',"");
      }

      if(data.new_pnut_type_id.trim()!=""){
        setValue(thisObj,'txtPnutTypAftr',data.new_pnut_type_id+ " -- " + data.new_pnut_type_name);
      }
      else{
        setValue(thisObj,'txtPnutTypAftr',"");
      }

      if(data.old_pnut_variety_id.trim()!=""){
        setValue(thisObj,'txtPnutVrty',data.old_pnut_variety_id+ " -- " + data.old_pnut_variety_name);
      }
      else{
        setValue(thisObj,'txtPnutVrty',"");
      }

      if(data.new_pnut_variety_id.trim()!=""){
        setValue(thisObj,'txtPnutVrtyAftr',data.new_pnut_variety_id+ " -- " + data.new_pnut_variety_name);
      }
      else{
        setValue(thisObj,'txtPnutVrtyAftr',"");
      }

      let oldSeedGen=data.old_seed_gen.trim()
      switch(oldSeedGen){
        case 'F':
          setValue(thisObj,'txtSeedGen',"Foundation Seed")
          break;
        case 'R':
          setValue(thisObj,'txtSeedGen',"Registered Seed")
          break;
        case 'C':
          setValue(thisObj,'txtSeedGen',"Certified Seed")
          break;
        default:
          setValue(thisObj,'txtSeedGen','')
      }

      let newSeedGen=data.new_seed_gen.trim()
      switch(newSeedGen){
        case 'F':
          setValue(thisObj,'txtSeedGenAftr',"Foundation Seed")
          break;
        case 'R':
          setValue(thisObj,'txtSeedGenAftr',"Registered Seed")
          break;
        case 'C':
          setValue(thisObj,'txtSeedGenAftr',"Certified Seed")
          break;
        default:
          setValue(thisObj,'txtSeedGenAftr','')
      }

      let oldSegType=data.old_seg_type.trim()
      switch(oldSegType){
        case '1':
          setValue(thisObj,'txtSegTyp',"Seg1")
          break;
        case '2':
          setValue(thisObj,'txtSegTyp',"Seg2")
          break;
        case '3':
          setValue(thisObj,'txtSegTyp',"Seg3")
          break;
        default:
          setValue(thisObj,'txtSegTyp','')
      }

      let newSegType=data.new_seg_type.trim()
      switch(newSegType){
        case '1':
          setValue(thisObj,'txtSegTypAftr',"Seg1")
          break;
        case '2':
          setValue(thisObj,'txtSegTypAftr',"Seg2")
          break;
        case '3':
          setValue(thisObj,'txtSegTypAftr',"Seg3")
          break;
        default:
          setValue(thisObj,'txtSegTypAftr','')
      }

      let oldOleicInd=data.old_oleic_ind.trim()
      switch(oldOleicInd){
        case 'M':
          setValue(thisObj,"txtOleicInd","Mid")
          break;
        case 'H':
          setValue(thisObj,"txtOleicInd","High")
          break;
        default:
          setValue(thisObj,"txtOleicInd",'')
      }

      let newOleicInd=data.new_oleic_ind==null?"":data.new_oleic_ind.trim()
      switch(newOleicInd){
        case 'M':
          setValue(thisObj,'txtOleicIndAftr',"Mid")
          break;
        case 'H':
          setValue(thisObj,'txtOleicIndAftr',"High")
          break;
        default:
          setValue(thisObj,'txtOleicIndAftr','')
      }

      setValue(thisObj,'txtOrgncIndAftr','');
      if(data.new_organic_ind=="N") setValue(thisObj,'txtOrgncIndAftr','No')
      if(data.new_organic_ind=="Y") setValue(thisObj,'txtOrgncIndAftr','Yes')

      setValue(thisObj,'txtOrgncInd','');
      if(data.old_organic_ind=="N") setValue(thisObj,'txtOrgncInd','No')
      if(data.old_organic_ind=="Y") setValue(thisObj,'txtOrgncInd','Yes')

      if(data.old_audit_date_time.trim()!=""){
        setValue(thisObj,'txtAuditDate',moment(data.old_audit_date_time).format('MM/DD/YYYY'))
      }
      else{
        setValue(thisObj,'txtAuditDate',"")
      }

      if(data.new_audit_date_time.trim()!=""){
        setValue(thisObj,'txtAuditDateAftr',moment(data.new_audit_date_time).format('MM/DD/YYYY'))
      }
      else{
        setValue(thisObj,'txtAuditDateAftr',"")
      }

      if(data.old_physical_adj.trim()!=""){
        setValue(thisObj,'txtPhysclAdj',FormatNumber(data.old_physical_adj,4))
      }
      else{
        setValue(thisObj,'txtPhysclAdj','')
      }

      if(data.new_physical_adj.trim()!=""){
        setValue(thisObj,'txtPhysclAdjAftr',FormatNumber(data.new_physical_adj,4))
      }
      else{
        setValue(thisObj,'txtPhysclAdjAftr','')
      }

      if(data.old_whouse_rcpt_adj.trim()!=""){
        setValue(thisObj,'txtWhsRec',FormatNumber(data.old_whouse_rcpt_adj,4))
      }
      else{
        setValue(thisObj,'txtWhsRec','')
      }

      if(data.new_whouse_rcpt_adj.trim()!=""){
        setValue(thisObj,'txtWhsRecAftr',FormatNumber(data.new_whouse_rcpt_adj,4))
      }
      else{
        setValue(thisObj,'txtWhsRecAftr','')
      }

      if(data.old_rcpt_shrink_adj.trim()!=""){
        setValue(thisObj,'txtShrinkAdj',FormatNumber(data.old_rcpt_shrink_adj,4))
      }
      else{
        setValue(thisObj,'txtShrinkAdj','')
      }

      if(data.new_rcpt_shrink_adj.trim()!=""){
        setValue(thisObj,'txtShrinkAdjAftr',FormatNumber(data.new_rcpt_shrink_adj,4))
      }
      else{
        setValue(thisObj,'txtShrinkAdjAftr','')
      }

      if(data.old_unrcpted_adj.trim()!=""){
        setValue(thisObj,'txtNotRecAdj',FormatNumber(data.old_unrcpted_adj,4))
      }
      else{
        setValue(thisObj,'txtNotRecAdj','')
      }

      if(data.new_unrcpted_adj.trim()!=""){
        setValue(thisObj,'txtNotReceiptedAftr',FormatNumber(data.new_unrcpted_adj,4))
      }
      else{
        setValue(thisObj,'txtNotReceiptedAftr','')
      }

      if(data.old_undetermined_adj.trim()!=""){
        setValue(thisObj,'txtUndetrmndAdj',FormatNumber(data.old_undetermined_adj,4))
      }
      else{
        setValue(thisObj,'txtUndetrmndAdj','')
      }

      if(data.new_undetermined_adj.trim()!=""){
        setValue(thisObj,'txtUndetrmndAdjAftr',FormatNumber(data.new_undetermined_adj,4))
      }
      else{
        setValue(thisObj,'txtUndetrmndAdjAftr','')
      }

      if(data.old_comp_own_adj.trim()!=""){
        setValue(thisObj,'txtCompOwn',FormatNumber(data.old_comp_own_adj,4))
      }
      else{
        setValue(thisObj,'txtCompOwn','')
      }

      if(data.new_comp_own_adj.trim()!=""){
        setValue(thisObj,'txtCompOwnAftr',FormatNumber(data.new_comp_own_adj,4))
      }
      else{
        setValue(thisObj,'txtCompOwnAftr','')
      }

      if(data.old_intransit_adj.trim()!=""){
        setValue(thisObj,'txtIntransit',FormatNumber(data.old_intransit_adj,4))
      }
      else{
        setValue(thisObj,'txtIntransit','')
      }

      if(data.new_intransit_adj.trim()!=""){
        setValue(thisObj,'txtIntransitAftr',FormatNumber(data.new_intransit_adj,4))
      }
      else{
        setValue(thisObj,'txtIntransitAftr','')
      }
      let buy_pt_id=data.buy_pt_id
      let access=await ContractManagementService.RetrieveAccessPermissionDetails("PN0320",null,"I",buy_pt_id);
      if(access[0].permission!="Y"){
        return;
      }

      if(data.chg_maint_action=="UPDATE"){
        if(data.old_whouse_num.trim()!=data.new_whouse_num.trim()){
          document.getElementById("txtWhs").style.backgroundColor = "#F4F462";
          document.getElementById("txtWhsAftr").style.backgroundColor = "#F4F462";
        }

        if(data.old_pnut_type_id.trim()!=data.new_pnut_type_id.trim()){
          document.getElementById("txtPnutTyp").style.backgroundColor = "#F4F462";
          document.getElementById("txtPnutTypAftr").style.backgroundColor = "#F4F462";
        }

        if(data.old_pnut_variety_id.trim()!=data.new_pnut_variety_id.trim()){
          document.getElementById("txtPnutVrty").style.backgroundColor = "#F4F462";
          document.getElementById("txtPnutVrtyAftr").style.backgroundColor = "#F4F462";
        }

        if(data.old_seed_gen.trim()!=data.new_seed_gen.trim()){
          document.getElementById("txtSeedGen").style.backgroundColor = "#F4F462";
          document.getElementById("txtSeedGenAftr").style.backgroundColor = "#F4F462";
        }

        if(data.old_seg_type.trim()!=data.new_seg_type.trim()){
          document.getElementById("txtSegTyp").style.backgroundColor = "#F4F462";
          document.getElementById("txtSegTypAftr").style.backgroundColor = "#F4F462";
        }

        if(data.old_oleic_ind==null) data.old_oleic_ind='';
        if(data.new_oleic_ind==null) data.new_oleic_ind='';
        if(data.old_oleic_ind.trim()!=data.new_oleic_ind.trim()){
          document.getElementById("txtOleicInd").style.backgroundColor = "#F4F462";
          document.getElementById("txtOleicIndAftr").style.backgroundColor = "#F4F462";
        }

        if(data.old_organic_ind==null) data.old_organic_ind='';
        if(data.new_organic_ind==null) data.new_organic_ind='';
        if(data.old_organic_ind.trim()!=data.new_organic_ind.trim()){
          document.getElementById("txtOrgncInd").style.backgroundColor = "#F4F462";
          document.getElementById("txtOrgncIndAftr").style.backgroundColor = "#F4F462";
        }

        if(data.old_audit_date_time.trim()!=data.new_audit_date_time.trim()){
          document.getElementById("txtAuditDate").style.backgroundColor = "#F4F462";
          document.getElementById("txtAuditDateAftr").style.backgroundColor = "#F4F462";
        }

        if(data.old_physical_adj.trim()!=data.new_physical_adj.trim()){
          document.getElementById("txtPhysclAdj").style.backgroundColor = "#F4F462";
          document.getElementById("txtPhysclAdjAftr").style.backgroundColor = "#F4F462";
        }

        if(data.old_whouse_rcpt_adj.trim()!=data.new_whouse_rcpt_adj.trim()){
          document.getElementById("txtWhsRecAftr").style.backgroundColor = "#F4F462";
          document.getElementById("txtWhsRec").style.backgroundColor = "#F4F462";
        }

        if(data.old_rcpt_shrink_adj.trim()!=data.new_rcpt_shrink_adj.trim()){
          document.getElementById("txtShrinkAdj").style.backgroundColor = "#F4F462";
          document.getElementById("txtShrinkAdjAftr").style.backgroundColor = "#F4F462";
        }

        if(data.old_unrcpted_adj.trim()!=data.new_unrcpted_adj.trim()){
          document.getElementById("txtNotRecAdj").style.backgroundColor = "#F4F462";
          document.getElementById("txtNotReceiptedAftr").style.backgroundColor = "#F4F462";
        }

        if(data.old_undetermined_adj.trim()!=data.new_undetermined_adj.trim()){
          document.getElementById("txtUndetrmndAdj").style.backgroundColor = "#F4F462";
          document.getElementById("txtUndetrmndAdjAftr").style.backgroundColor = "#F4F462";
        }

        if(data.old_comp_own_adj.trim()!=data.new_comp_own_adj.trim()){
          document.getElementById("txtCompOwn").style.backgroundColor = "#F4F462";
          document.getElementById("txtCompOwnAftr").style.backgroundColor = "#F4F462";
        }

        if(data.old_intransit_adj.trim()!=data.new_intransit_adj.trim()){
          document.getElementById("txtIntransit").style.backgroundColor = "#F4F462";
          document.getElementById("txtIntransitAftr").style.backgroundColor = "#F4F462";
        }
      }
    }
    else{
      showMessage(thisObj,'An error occured while accessing the database.  Contact your system administrator if this problem persists.')
      setLoading(false)
    }
  }
  catch(err){
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
      setLoading(false)
    } else {
      showMessage(
        thisObj,
        "Error occurred during bfillForm Method"
      );
      setLoading(false)
    }
    return false;
  }

}

const onbtnExitClick = () => {
  try {
    document.getElementById("SystemMaintenanceApplicationSupport_ManualDPRInvHistViewPopUp").childNodes[0].click()
  } catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Exception in PreSubmit Event code for widget:btnExit event:Click"
      );
    }
    return false;
  }
  return true;
};
thisObj.onbtnExitClick = onbtnExitClick;
  
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ManualDPRInvHistView*/}

              {/* END_USER_CODE-USER_BEFORE_ManualDPRInvHistView*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxManualDPR*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxManualDPR*/}

              <GroupBoxWidget conf={state.grpbxManualDPR} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtAction*/}

                {/* END_USER_CODE-USER_BEFORE_txtAction*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAction}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAction*/}

                {/* END_USER_CODE-USER_AFTER_txtAction*/}
                {/* START_USER_CODE-USER_BEFORE_txtUser*/}

                {/* END_USER_CODE-USER_BEFORE_txtUser*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtUser}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtUser*/}

                {/* END_USER_CODE-USER_AFTER_txtUser*/}
                {/* START_USER_CODE-USER_BEFORE_dtTime*/}

                {/* END_USER_CODE-USER_BEFORE_dtTime*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtTime}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_dtTime*/}

                {/* END_USER_CODE-USER_AFTER_dtTime*/}
                {/* START_USER_CODE-USER_BEFORE_txtReason*/}

                {/* END_USER_CODE-USER_BEFORE_txtReason*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtReason}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtReason*/}

                {/* END_USER_CODE-USER_AFTER_txtReason*/}
                {/* START_USER_CODE-USER_BEFORE_txtBuyngPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtBuyngPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBuyngPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBuyngPt*/}

                {/* END_USER_CODE-USER_AFTER_txtBuyngPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtAudit*/}

                {/* END_USER_CODE-USER_BEFORE_txtAudit*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAudit}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAudit*/}

                {/* END_USER_CODE-USER_AFTER_txtAudit*/}
                {/* START_USER_CODE-USER_BEFORE_txtSourceTxt*/}

                {/* END_USER_CODE-USER_BEFORE_txtSourceTxt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSourceTxt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSourceTxt*/}

                {/* END_USER_CODE-USER_AFTER_txtSourceTxt*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxManualDPR*/}

              {/* END_USER_CODE-USER_AFTER_grpbxManualDPR*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxBefore*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxBefore*/}

              <GroupBoxWidget conf={state.grpbxBefore} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblBefore*/}

                {/* END_USER_CODE-USER_BEFORE_lblBefore*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBefore}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBefore*/}

                {/* END_USER_CODE-USER_AFTER_lblBefore*/}
                {/* START_USER_CODE-USER_BEFORE_txtWhs*/}

                {/* END_USER_CODE-USER_BEFORE_txtWhs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWhs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWhs*/}

                {/* END_USER_CODE-USER_AFTER_txtWhs*/}
                {/* START_USER_CODE-USER_BEFORE_txtPnutTyp*/}

                {/* END_USER_CODE-USER_BEFORE_txtPnutTyp*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPnutTyp}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPnutTyp*/}

                {/* END_USER_CODE-USER_AFTER_txtPnutTyp*/}
                {/* START_USER_CODE-USER_BEFORE_txtPnutVrty*/}

                {/* END_USER_CODE-USER_BEFORE_txtPnutVrty*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPnutVrty}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPnutVrty*/}

                {/* END_USER_CODE-USER_AFTER_txtPnutVrty*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeedGen*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeedGen*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeedGen}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeedGen*/}

                {/* END_USER_CODE-USER_AFTER_txtSeedGen*/}
                {/* START_USER_CODE-USER_BEFORE_txtSegTyp*/}

                {/* END_USER_CODE-USER_BEFORE_txtSegTyp*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSegTyp}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSegTyp*/}

                {/* END_USER_CODE-USER_AFTER_txtSegTyp*/}
                {/* START_USER_CODE-USER_BEFORE_txtOleicInd*/}

                {/* END_USER_CODE-USER_BEFORE_txtOleicInd*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOleicInd}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOleicInd*/}

                {/* END_USER_CODE-USER_AFTER_txtOleicInd*/}
                {/* START_USER_CODE-USER_BEFORE_txtOrgncInd*/}

                {/* END_USER_CODE-USER_BEFORE_txtOrgncInd*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOrgncInd}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOrgncInd*/}

                {/* END_USER_CODE-USER_AFTER_txtOrgncInd*/}
                {/* START_USER_CODE-USER_BEFORE_txtAuditDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtAuditDate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAuditDate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAuditDate*/}

                {/* END_USER_CODE-USER_AFTER_txtAuditDate*/}
                {/* START_USER_CODE-USER_BEFORE_txtPhysclAdj*/}

                {/* END_USER_CODE-USER_BEFORE_txtPhysclAdj*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPhysclAdj}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPhysclAdj*/}

                {/* END_USER_CODE-USER_AFTER_txtPhysclAdj*/}
                {/* START_USER_CODE-USER_BEFORE_txtWhsRec*/}

                {/* END_USER_CODE-USER_BEFORE_txtWhsRec*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWhsRec}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWhsRec*/}

                {/* END_USER_CODE-USER_AFTER_txtWhsRec*/}
                {/* START_USER_CODE-USER_BEFORE_txtShrinkAdj*/}

                {/* END_USER_CODE-USER_BEFORE_txtShrinkAdj*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtShrinkAdj}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtShrinkAdj*/}

                {/* END_USER_CODE-USER_AFTER_txtShrinkAdj*/}
                {/* START_USER_CODE-USER_BEFORE_txtNotRecAdj*/}

                {/* END_USER_CODE-USER_BEFORE_txtNotRecAdj*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNotRecAdj}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNotRecAdj*/}

                {/* END_USER_CODE-USER_AFTER_txtNotRecAdj*/}
                {/* START_USER_CODE-USER_BEFORE_txtUndetrmndAdj*/}

                {/* END_USER_CODE-USER_BEFORE_txtUndetrmndAdj*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtUndetrmndAdj}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtUndetrmndAdj*/}

                {/* END_USER_CODE-USER_AFTER_txtUndetrmndAdj*/}
                {/* START_USER_CODE-USER_BEFORE_txtCompOwn*/}

                {/* END_USER_CODE-USER_BEFORE_txtCompOwn*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCompOwn}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCompOwn*/}

                {/* END_USER_CODE-USER_AFTER_txtCompOwn*/}
                {/* START_USER_CODE-USER_BEFORE_txtIntransit*/}

                {/* END_USER_CODE-USER_BEFORE_txtIntransit*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtIntransit}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtIntransit*/}

                {/* END_USER_CODE-USER_AFTER_txtIntransit*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxBefore*/}

              {/* END_USER_CODE-USER_AFTER_grpbxBefore*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxBeforeuic0*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxBeforeuic0*/}

              <GroupBoxWidget conf={state.grpbxBeforeuic0} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAfter*/}

                {/* END_USER_CODE-USER_BEFORE_lblAfter*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAfter}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAfter*/}

                {/* END_USER_CODE-USER_AFTER_lblAfter*/}
                {/* START_USER_CODE-USER_BEFORE_txtWhsAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtWhsAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWhsAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWhsAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtWhsAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtPnutTypAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtPnutTypAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPnutTypAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPnutTypAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtPnutTypAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtPnutVrtyAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtPnutVrtyAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPnutVrtyAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPnutVrtyAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtPnutVrtyAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeedGenAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeedGenAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeedGenAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeedGenAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtSeedGenAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtSegTypAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtSegTypAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSegTypAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSegTypAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtSegTypAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtOleicIndAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtOleicIndAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOleicIndAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOleicIndAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtOleicIndAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtOrgncIndAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtOrgncIndAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOrgncIndAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOrgncIndAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtOrgncIndAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtAuditDateAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtAuditDateAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAuditDateAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAuditDateAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtAuditDateAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtPhysclAdjAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtPhysclAdjAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPhysclAdjAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPhysclAdjAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtPhysclAdjAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtWhsRecAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtWhsRecAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWhsRecAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWhsRecAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtWhsRecAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtShrinkAdjAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtShrinkAdjAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtShrinkAdjAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtShrinkAdjAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtShrinkAdjAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtNotReceiptedAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtNotReceiptedAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNotReceiptedAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNotReceiptedAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtNotReceiptedAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtUndetrmndAdjAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtUndetrmndAdjAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtUndetrmndAdjAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtUndetrmndAdjAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtUndetrmndAdjAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtCompOwnAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtCompOwnAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCompOwnAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCompOwnAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtCompOwnAftr*/}
                {/* START_USER_CODE-USER_BEFORE_txtIntransitAftr*/}

                {/* END_USER_CODE-USER_BEFORE_txtIntransitAftr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtIntransitAftr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtIntransitAftr*/}

                {/* END_USER_CODE-USER_AFTER_txtIntransitAftr*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxBeforeuic0*/}

              {/* END_USER_CODE-USER_AFTER_grpbxBeforeuic0*/}
              {/* START_USER_CODE-USER_BEFORE_cmndCntnrManDPR*/}

              {/* END_USER_CODE-USER_BEFORE_cmndCntnrManDPR*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmndCntnrManDPR}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmndCntnrManDPR*/}

              {/* END_USER_CODE-USER_AFTER_cmndCntnrManDPR*/}

              {/* START_USER_CODE-USER_AFTER_ManualDPRInvHistView*/}

              {/* END_USER_CODE-USER_AFTER_ManualDPRInvHistView*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_ManualDPRInvHistView
);
