/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  setValue,
  setData,
  getData,
  enable,
  disable,
  goTo,
  getValue,
} from "../../shared/WindowImports";

import "./PremiumDeducMasterSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_PremiumDeducMasterSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PremiumDeducMasterSetup",
    windowName: "PremiumDeducMasterSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.PremiumDeducMasterSetup",
    // START_USER_CODE-USER_PremiumDeducMasterSetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Premium Deduction Master Setup",
      scrCode: "PN0100A",
    },
    // END_USER_CODE-USER_PremiumDeducMasterSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxPremiumDeducMasterSetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxPremiumDeducMasterSetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxPremiumDeducMasterSetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    lblPDCodeList: {
      name: "lblPDCodeList",
      type: "LabelWidget",
      parent: "grpbxPremiumDeducMasterSetup",
      Label: "PD Code List:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPDCodeList_PROPERTIES

      // END_USER_CODE-USER_lblPDCodeList_PROPERTIES
    },
    lstPDCodeList: {
      name: "lstPDCodeList",
      type: "ListBoxFieldWidget",
      parent: "grpbxPremiumDeducMasterSetup",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstPDCodeList_PROPERTIES

      // END_USER_CODE-USER_lstPDCodeList_PROPERTIES
    },
    grpbxPremiumDeducMasterSetup: {
      name: "grpbxPremiumDeducMasterSetup",
      type: "GroupBoxWidget",
      parent: "PremiumDeducMasterSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPremiumDeducMasterSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxPremiumDeducMasterSetup_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "PremiumDeducMasterSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#PremiumDeducMasterProfile": {},
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#PremiumDeducMasterProfile": {},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAdd:{
    //   DEFAULT:[
    //   "SystemMaintenanceSpecialFunctions#PremiumDeducMasterProfile#DEFAULT#true#Click",]
    // },
    // btnEdit: {
    //   DEFAULT: [
    //     "SystemMaintenanceSpecialFunctions#PremiumDeducMasterProfile#DEFAULT#true#Click",]
    // },
    btnClose: {

      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],

    }
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    let refreshindicator = getData(thisObj, "refreshindicator")

    if (refreshindicator != false) {
      EnableDisableControls()
      bFillPDCodeList()
    }
    setData(thisObj, "refreshindicator", false)
  }, [getData(thisObj, 'refreshindicator')])



  // START_USER_CODE-USER_METHODS


  // START_USER_CODE-USER_METHODS

  function FormLoad() {
    bFillPDCodeList();
    EnableDisableControls();
    setLoading(false);
  }

  // Method for binding PD code list ---START

  const bFillPDCodeList = async () => {
    try{ 
    setLoading(true);
    let js = [];
    AccountPayment.RetrievePremiumDeductionMasterDetails(null, null).then(response => {
      if (response != undefined && response.length > 0) {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pd_code, description: data[i].pd_code.trim() + ' - ' + data[i].pd_desc.trim() }
          js.push(obj)
          obj = {}
        }
      }
      setData(thisObj, 'lstPDCodeList', js)
      if (js[0] != undefined) {
        setValue(thisObj, "lstPDCodeList", [js[0].key])
      }

      thisObj.setState(current => {
        return {
          ...current,
          lstPDCodeList: {
            ...state["lstPDCodeList"],
            valueList: js,
          }
        }
      })
      
    })
    setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillPDCodeList event"
        );
      }
    }
  };
  // Method for binding PD code list ---END

  // Enable disable control ---START

  const EnableDisableControls = () => {
    let PDList = getData(thisObj, 'lstPDCodeList')
    let PDLength = ''
    if (PDList !== null && PDList !== undefined) {
      PDLength = PDList.length
    }
    enable(thisObj, 'btnAdd')
    enable(thisObj, 'btnEdit')
    enable(thisObj, 'btnDelete')

    if (PDLength < 0) {
      disable(thisObj, 'btnEdit')
      disable(thisObj, 'btnDelete')
    }
  }

  // Enable disable control ---END

  //Click event of Add button
  const onbtnAddClick = async () => {
    try {
      setLoading(true);
      let frmPremiumDeducMasterProfile = {}
      frmPremiumDeducMasterProfile.cmdOk_Caption = "Add"
      frmPremiumDeducMasterProfile.txtPDCode_enabled = true
      setData(thisObj, 'frmPremiumDeducMasterProfile', frmPremiumDeducMasterProfile)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#PremiumDeducMasterProfile#DEFAULT#true#Click")
      await bFillPDCodeList()
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Add Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick

  //Click event of Location Edit button -- Start

  const onbtnEditClick = async () => {
    try {
      setLoading(true)
      let pd_code = getValue(thisObj, "lstPDCodeList")
      let response = await AccountPayment.RetrievePremiumDeductionMasterDetails(pd_code, null);
      let data = response;

      let frmPremiumDeducMasterProfile = {}
      frmPremiumDeducMasterProfile.cmdOk_Caption = 'Update'
      frmPremiumDeducMasterProfile.txtPDCode = data[0].pd_code;
      frmPremiumDeducMasterProfile.txtDiscription = data[0].pd_desc;
      frmPremiumDeducMasterProfile.lblAddedBy = data[0].add_user + " " + moment(data[0].addDateTime).format("MM/DD/YYYY")
      frmPremiumDeducMasterProfile.lblChangedBy = data[0].chg_user + " " + moment(data[0].chgDateTime).format("MM/DD/YYYY")
      frmPremiumDeducMasterProfile.txtAccount = data[0].pd_account;
      frmPremiumDeducMasterProfile.txtPDSeedAccount = data[0].pd_seed_acct;

      frmPremiumDeducMasterProfile.lstIndicator = data[0].pd_ind;
      frmPremiumDeducMasterProfile.lstAccountingIndicator = data[0].pl_bal_acct_ind;

      if (data[0].dist_qty_ind == 'Y') frmPremiumDeducMasterProfile.chkDISTQTYIND = true
      else frmPremiumDeducMasterProfile.chkDISTQTYIND = false

      if (data[0].comp_revenue_ind == 'Y') frmPremiumDeducMasterProfile.chkRevenueInd = true
      else frmPremiumDeducMasterProfile.chkRevenueInd = false

      if (data[0].insp_enable_ind == 'Y') frmPremiumDeducMasterProfile.chkInspInd = true
      else frmPremiumDeducMasterProfile.chkInspInd = false

      if (data[0].rcpt_enable_ind == 'Y') frmPremiumDeducMasterProfile.chkWarehouseInd = true
      else frmPremiumDeducMasterProfile.chkWarehouseInd = false

      if (data[0].check_enable_ind == 'Y') frmPremiumDeducMasterProfile.chkSPlCheckInd = true
      else frmPremiumDeducMasterProfile.chkSPlCheckInd = false

      if (data[0].rpt_1099_ind == 'Y') frmPremiumDeducMasterProfile.chkTax1099Ind = true
      else frmPremiumDeducMasterProfile.chkTax1099Ind = false

      if (data[0].print_1007_storage == 'Y') frmPremiumDeducMasterProfile.chkPrint1007Storage = true
      else frmPremiumDeducMasterProfile.chkPrint1007Storage = false

      if (data[0].trade_settle_enable_ind == 'Y') frmPremiumDeducMasterProfile.chkTradeSettleInd = true
      else frmPremiumDeducMasterProfile.chkTradeSettleInd = false

      if (data[0].freight_enable_ind == 'Y') frmPremiumDeducMasterProfile.chkFreightAccrualInd = true
      else frmPremiumDeducMasterProfile.chkFreightAccrualInd = false

      if (data[0].cma_ind_flag == 'Y') frmPremiumDeducMasterProfile.chkCMAInd = true
      else frmPremiumDeducMasterProfile.chkCMAInd = false

      if (data[0].except_prem_ind == 'Y') frmPremiumDeducMasterProfile.chkExceptPrem = true
      else frmPremiumDeducMasterProfile.chkExceptPrem = false

      if (data[0].contract_rpt_ind == 'Y') frmPremiumDeducMasterProfile.chkReportPrem = true
      else frmPremiumDeducMasterProfile.chkReportPrem = false

      setData(thisObj, 'frmPremiumDeducMasterProfile', frmPremiumDeducMasterProfile)
      goTo(thisObj, "SystemMaintenanceSpecialFunctions#PremiumDeducMasterProfile#DEFAULT#true#Click");
      setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during State Add Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnEditClick = onbtnEditClick

  //Click event of Location Delete button -- Start
  const onbtnDeleteClick = async () => {
    try {
      setLoading(true);
      let buy_pt_id = null;
      let pd_code = getValue(thisObj, "lstPDCodeList")
      let pd_list = thisObj.state['lstPDCodeList'].valueList
      let pd_name = ''
      let pdIndex;
      if (pd_code !== undefined && pd_code !== null && pd_code !== '') {
        pd_name = pd_list.find(elem => elem.key.trim() == pd_code).description;
        pdIndex = pd_list.find(elem => elem.key.trim() == pd_code).index;
      }
      if (pd_code == "") {
        alert("Please select at least one PD_Code");
        return;
      }
      else {
        const confirmBox = window.confirm(
          `Are you sure you wish to delete this PD Code ${pd_name}`
        )
        if (confirmBox == true) {
          let response = await SystemMaintenanceSpecialFunctionsService.RemovePremiumDeductMasterDetails(buy_pt_id, pd_code)
          if (response.status == 200) {
            showMessage(thisObj, response.message, true);
            await bFillPDCodeList();
            if (!await bFillPDCodeList()) {
              EnableDisableControls()
              return false;
            }
          }
          else {
            showMessage(thisObj, "An error occured while communicating or updating with the database. Please contact your system administrator if the problem persist.", false);
          }

        }
      }setLoading(false);
      return true;
      
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
        setLoading(false);
      }
      else {
        showMessage(thisObj, "error on delete PD_Code")
      }
      setLoading(false);
      return false;
    }
    // finally {
    //   setLoading(false);
    // }
  };
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            <Loading loading={loading}></Loading>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PremiumDeducMasterSetup*/}

              {/* END_USER_CODE-USER_BEFORE_PremiumDeducMasterSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPremiumDeducMasterSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPremiumDeducMasterSetup*/}

              <GroupBoxWidget
                conf={state.grpbxPremiumDeducMasterSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblPDCodeList*/}

                {/* END_USER_CODE-USER_BEFORE_lblPDCodeList*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPDCodeList}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPDCodeList*/}

                {/* END_USER_CODE-USER_AFTER_lblPDCodeList*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_lstPDCodeList*/}

                {/* END_USER_CODE-USER_BEFORE_lstPDCodeList*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstPDCodeList}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstPDCodeList*/}

                {/* END_USER_CODE-USER_AFTER_lstPDCodeList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPremiumDeducMasterSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPremiumDeducMasterSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_PremiumDeducMasterSetup*/}

              {/* END_USER_CODE-USER_AFTER_PremiumDeducMasterSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_PremiumDeducMasterSetup
);
