/* eslint-disable*/
import React from "react";
import ReportTool_ProcurementReporting from "./ProcurementReporting";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ProcurementReporting Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ReportTool_ProcurementReporting />);
    });
  });
  afterEach(cleanup);
  test("is ProcurementReporting Loads Successfully", () => {
    expect(screen.getByText("null")).toBeInTheDocument;
  });
  test("Custom Test Cases for ProcurementReporting", () => {
    // START_USER_CODE-USER_ProcurementReporting_Custom_Test_Case
    // END_USER_CODE-USER_ProcurementReporting_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ReportTool_ProcurementReporting />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnBase(Button Widget) Test Cases", async () => {
    const btnBase = screen.getByTestId("btnBase");
    expect(btnBase).toBeInTheDocument;
    expect(btnBase.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnBase")
    );
  });
  test("Custom Test Cases for btnBase", () => {
    // START_USER_CODE-USER_btnBase_TEST
    // END_USER_CODE-USER_btnBase_TEST
  });
  test("btnClearAll(Button Widget) Test Cases", async () => {
    const btnClearAll = screen.getByTestId("btnClearAll");
    expect(btnClearAll).toBeInTheDocument;
    expect(btnClearAll.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnClearAll")
    );
  });
  test("Custom Test Cases for btnClearAll", () => {
    // START_USER_CODE-USER_btnClearAll_TEST
    // END_USER_CODE-USER_btnClearAll_TEST
  });
  test("btnCollapseHeader(Button Widget) Test Cases", async () => {
    const btnCollapseHeader = screen.getByTestId("btnCollapseHeader");
    expect(btnCollapseHeader).toBeInTheDocument;
    expect(btnCollapseHeader.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnCollapseHeader")
    );
  });
  test("Custom Test Cases for btnCollapseHeader", () => {
    // START_USER_CODE-USER_btnCollapseHeader_TEST
    // END_USER_CODE-USER_btnCollapseHeader_TEST
  });
  test("btnCustom(Button Widget) Test Cases", async () => {
    const btnCustom = screen.getByTestId("btnCustom");
    expect(btnCustom).toBeInTheDocument;
    expect(btnCustom.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnCustom")
    );
  });
  test("Custom Test Cases for btnCustom", () => {
    // START_USER_CODE-USER_btnCustom_TEST
    // END_USER_CODE-USER_btnCustom_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnExpandHeaders(Button Widget) Test Cases", async () => {
    const btnExpandHeaders = screen.getByTestId("btnExpandHeaders");
    expect(btnExpandHeaders).toBeInTheDocument;
    expect(btnExpandHeaders.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnExpandHeaders")
    );
  });
  test("Custom Test Cases for btnExpandHeaders", () => {
    // START_USER_CODE-USER_btnExpandHeaders_TEST
    // END_USER_CODE-USER_btnExpandHeaders_TEST
  });
  test("btnFormulaEditor(Button Widget) Test Cases", async () => {
    const btnFormulaEditor = screen.getByTestId("btnFormulaEditor");
    expect(btnFormulaEditor).toBeInTheDocument;
    expect(btnFormulaEditor.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnFormulaEditor")
    );
  });
  test("Custom Test Cases for btnFormulaEditor", () => {
    // START_USER_CODE-USER_btnFormulaEditor_TEST
    // END_USER_CODE-USER_btnFormulaEditor_TEST
  });
  test("btnLeftShift(Button Widget) Test Cases", async () => {
    const btnLeftShift = screen.getByTestId("btnLeftShift");
    expect(btnLeftShift).toBeInTheDocument;
    expect(btnLeftShift.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnLeftShift")
    );
  });
  test("Custom Test Cases for btnLeftShift", () => {
    // START_USER_CODE-USER_btnLeftShift_TEST
    // END_USER_CODE-USER_btnLeftShift_TEST
  });
  test("btnOrderDown(Button Widget) Test Cases", async () => {
    const btnOrderDown = screen.getByTestId("btnOrderDown");
    expect(btnOrderDown).toBeInTheDocument;
  });
  test("Custom Test Cases for btnOrderDown", () => {
    // START_USER_CODE-USER_btnOrderDown_TEST
    // END_USER_CODE-USER_btnOrderDown_TEST
  });
  test("btnOrderDown1(Button Widget) Test Cases", async () => {
    const btnOrderDown1 = screen.getByTestId("btnOrderDown1");
    expect(btnOrderDown1).toBeInTheDocument;
  });
  test("Custom Test Cases for btnOrderDown1", () => {
    // START_USER_CODE-USER_btnOrderDown1_TEST
    // END_USER_CODE-USER_btnOrderDown1_TEST
  });
  test("btnOrderUp(Button Widget) Test Cases", async () => {
    const btnOrderUp = screen.getByTestId("btnOrderUp");
    expect(btnOrderUp).toBeInTheDocument;
    expect(btnOrderUp.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnOrderUp")
    );
  });
  test("Custom Test Cases for btnOrderUp", () => {
    // START_USER_CODE-USER_btnOrderUp_TEST
    // END_USER_CODE-USER_btnOrderUp_TEST
  });
  test("btnOrderUp1(Button Widget) Test Cases", async () => {
    const btnOrderUp1 = screen.getByTestId("btnOrderUp1");
    expect(btnOrderUp1).toBeInTheDocument;
    expect(btnOrderUp1.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnOrderUp1")
    );
  });
  test("Custom Test Cases for btnOrderUp1", () => {
    // START_USER_CODE-USER_btnOrderUp1_TEST
    // END_USER_CODE-USER_btnOrderUp1_TEST
  });
  test("btnRemove(Button Widget) Test Cases", async () => {
    const btnRemove = screen.getByTestId("btnRemove");
    expect(btnRemove).toBeInTheDocument;
    expect(btnRemove.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnRemove")
    );
  });
  test("Custom Test Cases for btnRemove", () => {
    // START_USER_CODE-USER_btnRemove_TEST
    // END_USER_CODE-USER_btnRemove_TEST
  });
  test("btnRightShift(Button Widget) Test Cases", async () => {
    const btnRightShift = screen.getByTestId("btnRightShift");
    expect(btnRightShift).toBeInTheDocument;
    expect(btnRightShift.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnRightShift")
    );
  });
  test("Custom Test Cases for btnRightShift", () => {
    // START_USER_CODE-USER_btnRightShift_TEST
    // END_USER_CODE-USER_btnRightShift_TEST
  });
  test("btnRunReport(Button Widget) Test Cases", async () => {
    const btnRunReport = screen.getByTestId("btnRunReport");
    expect(btnRunReport).toBeInTheDocument;
    expect(btnRunReport.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnRunReport")
    );
  });
  test("Custom Test Cases for btnRunReport", () => {
    // START_USER_CODE-USER_btnRunReport_TEST
    // END_USER_CODE-USER_btnRunReport_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnSave")
    );
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("btnSaveAs(Button Widget) Test Cases", async () => {
    const btnSaveAs = screen.getByTestId("btnSaveAs");
    expect(btnSaveAs).toBeInTheDocument;
    expect(btnSaveAs.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnSaveAs")
    );
  });
  test("Custom Test Cases for btnSaveAs", () => {
    // START_USER_CODE-USER_btnSaveAs_TEST
    // END_USER_CODE-USER_btnSaveAs_TEST
  });
  test("btnShare(Button Widget) Test Cases", async () => {
    const btnShare = screen.getByTestId("btnShare");
    expect(btnShare).toBeInTheDocument;
    expect(btnShare.textContent).toEqual(
      t("ReportTool:ProcurementReporting_btnShare")
    );
  });
  test("Custom Test Cases for btnShare", () => {
    // START_USER_CODE-USER_btnShare_TEST
    // END_USER_CODE-USER_btnShare_TEST
  });
  test("grpbx1(GroupBox Widget) Test Cases", async () => {
    const grpbx1 = screen.getByTestId("grpbx1");
    expect(grpbx1.tagName).toBe("BUTTON");
    expect(grpbx1.type).toBe("button");
    expect(grpbx1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx1", () => {
    // START_USER_CODE-USER_grpbx1_TEST
    // END_USER_CODE-USER_grpbx1_TEST
  });
  test("grpbx11(GroupBox Widget) Test Cases", async () => {
    const grpbx11 = screen.getByTestId("grpbx11");
    expect(grpbx11.tagName).toBe("BUTTON");
    expect(grpbx11.type).toBe("button");
    expect(grpbx11.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx11", () => {
    // START_USER_CODE-USER_grpbx11_TEST
    // END_USER_CODE-USER_grpbx11_TEST
  });
  test("grpbx12(GroupBox Widget) Test Cases", async () => {
    const grpbx12 = screen.getByTestId("grpbx12");
    expect(grpbx12.tagName).toBe("BUTTON");
    expect(grpbx12.type).toBe("button");
    expect(grpbx12.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx12", () => {
    // START_USER_CODE-USER_grpbx12_TEST
    // END_USER_CODE-USER_grpbx12_TEST
  });
  test("grpbx13(GroupBox Widget) Test Cases", async () => {
    const grpbx13 = screen.getByTestId("grpbx13");
    expect(grpbx13.tagName).toBe("BUTTON");
    expect(grpbx13.type).toBe("button");
    expect(grpbx13.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx13", () => {
    // START_USER_CODE-USER_grpbx13_TEST
    // END_USER_CODE-USER_grpbx13_TEST
  });
  test("grpbx2(GroupBox Widget) Test Cases", async () => {
    const grpbx2 = screen.getByTestId("grpbx2");
    expect(grpbx2.tagName).toBe("BUTTON");
    expect(grpbx2.type).toBe("button");
    expect(grpbx2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx2", () => {
    // START_USER_CODE-USER_grpbx2_TEST
    // END_USER_CODE-USER_grpbx2_TEST
  });
  test("grpbx21(GroupBox Widget) Test Cases", async () => {
    const grpbx21 = screen.getByTestId("grpbx21");
    expect(grpbx21.tagName).toBe("BUTTON");
    expect(grpbx21.type).toBe("button");
    expect(grpbx21.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx21", () => {
    // START_USER_CODE-USER_grpbx21_TEST
    // END_USER_CODE-USER_grpbx21_TEST
  });
  test("grpbx22(GroupBox Widget) Test Cases", async () => {
    const grpbx22 = screen.getByTestId("grpbx22");
    expect(grpbx22.tagName).toBe("BUTTON");
    expect(grpbx22.type).toBe("button");
    expect(grpbx22.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx22", () => {
    // START_USER_CODE-USER_grpbx22_TEST
    // END_USER_CODE-USER_grpbx22_TEST
  });
  test("grpbx23(GroupBox Widget) Test Cases", async () => {
    const grpbx23 = screen.getByTestId("grpbx23");
    expect(grpbx23.tagName).toBe("BUTTON");
    expect(grpbx23.type).toBe("button");
    expect(grpbx23.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx23", () => {
    // START_USER_CODE-USER_grpbx23_TEST
    // END_USER_CODE-USER_grpbx23_TEST
  });
  test("grpbx24(GroupBox Widget) Test Cases", async () => {
    const grpbx24 = screen.getByTestId("grpbx24");
    expect(grpbx24.tagName).toBe("BUTTON");
    expect(grpbx24.type).toBe("button");
    expect(grpbx24.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx24", () => {
    // START_USER_CODE-USER_grpbx24_TEST
    // END_USER_CODE-USER_grpbx24_TEST
  });
  test("grpbx25(GroupBox Widget) Test Cases", async () => {
    const grpbx25 = screen.getByTestId("grpbx25");
    expect(grpbx25.tagName).toBe("BUTTON");
    expect(grpbx25.type).toBe("button");
    expect(grpbx25.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx25", () => {
    // START_USER_CODE-USER_grpbx25_TEST
    // END_USER_CODE-USER_grpbx25_TEST
  });
  test("grpbx3(GroupBox Widget) Test Cases", async () => {
    const grpbx3 = screen.getByTestId("grpbx3");
    expect(grpbx3.tagName).toBe("BUTTON");
    expect(grpbx3.type).toBe("button");
    expect(grpbx3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx3", () => {
    // START_USER_CODE-USER_grpbx3_TEST
    // END_USER_CODE-USER_grpbx3_TEST
  });
  test("grpbx31(GroupBox Widget) Test Cases", async () => {
    const grpbx31 = screen.getByTestId("grpbx31");
    expect(grpbx31.tagName).toBe("BUTTON");
    expect(grpbx31.type).toBe("button");
    expect(grpbx31.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx31", () => {
    // START_USER_CODE-USER_grpbx31_TEST
    // END_USER_CODE-USER_grpbx31_TEST
  });
  test("grpbx32(GroupBox Widget) Test Cases", async () => {
    const grpbx32 = screen.getByTestId("grpbx32");
    expect(grpbx32.tagName).toBe("BUTTON");
    expect(grpbx32.type).toBe("button");
    expect(grpbx32.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx32", () => {
    // START_USER_CODE-USER_grpbx32_TEST
    // END_USER_CODE-USER_grpbx32_TEST
  });
  test("grpbx33(GroupBox Widget) Test Cases", async () => {
    const grpbx33 = screen.getByTestId("grpbx33");
    expect(grpbx33.tagName).toBe("BUTTON");
    expect(grpbx33.type).toBe("button");
    expect(grpbx33.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx33", () => {
    // START_USER_CODE-USER_grpbx33_TEST
    // END_USER_CODE-USER_grpbx33_TEST
  });
  test("grpbx34(GroupBox Widget) Test Cases", async () => {
    const grpbx34 = screen.getByTestId("grpbx34");
    expect(grpbx34.tagName).toBe("BUTTON");
    expect(grpbx34.type).toBe("button");
    expect(grpbx34.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx34", () => {
    // START_USER_CODE-USER_grpbx34_TEST
    // END_USER_CODE-USER_grpbx34_TEST
  });
  test("grpbx35(GroupBox Widget) Test Cases", async () => {
    const grpbx35 = screen.getByTestId("grpbx35");
    expect(grpbx35.tagName).toBe("BUTTON");
    expect(grpbx35.type).toBe("button");
    expect(grpbx35.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx35", () => {
    // START_USER_CODE-USER_grpbx35_TEST
    // END_USER_CODE-USER_grpbx35_TEST
  });
  test("lblColumnOrder(Label Widget) Test Cases", async () => {
    const lblColumnOrder = screen.getByTestId("lblColumnOrder");
    expect(lblColumnOrder.tagName).toBe("LABEL");
    expect(lblColumnOrder.classList).toContain("form-label");
    expect(lblColumnOrder.textContent).toEqual(
      t("ReportTool:ProcurementReporting_lblColumnOrder")
    );
  });
  test("Custom Test Cases for lblColumnOrder", () => {
    // START_USER_CODE-USER_lblColumnOrder_TEST
    // END_USER_CODE-USER_lblColumnOrder_TEST
  });
  test("lblError(Label Widget) Test Cases", async () => {
    const lblError = screen.getByTestId("lblError");
    expect(lblError.tagName).toBe("LABEL");
    expect(lblError.classList).toContain("form-label");
  });
  test("Custom Test Cases for lblError", () => {
    // START_USER_CODE-USER_lblError_TEST
    // END_USER_CODE-USER_lblError_TEST
  });
  test("lblReportColumns(Label Widget) Test Cases", async () => {
    const lblReportColumns = screen.getByTestId("lblReportColumns");
    expect(lblReportColumns.tagName).toBe("LABEL");
    expect(lblReportColumns.classList).toContain("form-label");
    expect(lblReportColumns.textContent).toEqual(
      t("ReportTool:ProcurementReporting_lblReportColumns")
    );
  });
  test("Custom Test Cases for lblReportColumns", () => {
    // START_USER_CODE-USER_lblReportColumns_TEST
    // END_USER_CODE-USER_lblReportColumns_TEST
  });
  test("lblSelectedColumns(Label Widget) Test Cases", async () => {
    const lblSelectedColumns = screen.getByTestId("lblSelectedColumns");
    expect(lblSelectedColumns.tagName).toBe("LABEL");
    expect(lblSelectedColumns.classList).toContain("form-label");
    expect(lblSelectedColumns.textContent).toEqual(
      t("ReportTool:ProcurementReporting_lblSelectedColumns")
    );
  });
  test("Custom Test Cases for lblSelectedColumns", () => {
    // START_USER_CODE-USER_lblSelectedColumns_TEST
    // END_USER_CODE-USER_lblSelectedColumns_TEST
  });
  test("lblValues(Label Widget) Test Cases", async () => {
    const lblValues = screen.getByTestId("lblValues");
    expect(lblValues.tagName).toBe("LABEL");
    expect(lblValues.classList).toContain("form-label");
    expect(lblValues.textContent).toEqual(
      t("ReportTool:ProcurementReporting_lblValues")
    );
  });
  test("Custom Test Cases for lblValues", () => {
    // START_USER_CODE-USER_lblValues_TEST
    // END_USER_CODE-USER_lblValues_TEST
  });
  test("txtReportDescription(Textbox Widget) Test Cases", async () => {
    const txtReportDescription = screen.getByTestId("txtReportDescription");
    expect(txtReportDescription.tagName).toBe("INPUT");
    expect(txtReportDescription.type).toBe("text");
    expect(txtReportDescription.classList).toContain("textboxWidgetClass");
    expect(txtReportDescription.previousElementSibling.textContent).toEqual(
      t("ReportTool:ProcurementReporting_txtReportDescription")
    );
    await act(async () => {
      userEvent.type(txtReportDescription, "1");
    });
  });
  test("Custom Test Cases for txtReportDescription", () => {
    // START_USER_CODE-USER_txtReportDescription_TEST
    // END_USER_CODE-USER_txtReportDescription_TEST
  });
  test("txtReportMode(Textbox Widget) Test Cases", async () => {
    const txtReportMode = screen.getByTestId("txtReportMode");
    expect(txtReportMode.tagName).toBe("INPUT");
    expect(txtReportMode.type).toBe("text");
    expect(txtReportMode.classList).toContain("textboxWidgetClass");
    expect(txtReportMode.previousElementSibling.textContent).toEqual(
      t("ReportTool:ProcurementReporting_txtReportMode")
    );
    await act(async () => {
      userEvent.type(txtReportMode, "1");
    });
  });
  test("Custom Test Cases for txtReportMode", () => {
    // START_USER_CODE-USER_txtReportMode_TEST
    // END_USER_CODE-USER_txtReportMode_TEST
  });
  test("txtValues(Textbox Widget) Test Cases", async () => {
    const txtValues = screen.getByTestId("txtValues");
    expect(txtValues.tagName).toBe("INPUT");
    expect(txtValues.type).toBe("text");
    expect(txtValues.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtValues, "1");
    });
  });
  test("Custom Test Cases for txtValues", () => {
    // START_USER_CODE-USER_txtValues_TEST
    // END_USER_CODE-USER_txtValues_TEST
  });
});
