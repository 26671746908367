/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  DateWidget,
  setData,
  getData,
  enable,
  disable,
  goTo,
  hide,
  show,
  enableWidgets,
  setValue
} from "../../shared/WindowImports";

import "./ManualDPRInvMaint.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import moment from "moment";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import { toPascalCase } from './../../Common/Constants';
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_ManualDPRInvMaint(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  // START_USER_CODE-USER_PROPERTIES

  let mbSuccessfulLoad = false;
  let mstrWBPnutTYP = "";
  let mstrWBPnutVAR = "";
  let mstrWBPnutGEN = "";
  let mstrWBPnutSEG = "";
  let mstrWBPnutOLEIC = "";
  let mstrWBPnutORGANIC = "";
  let lstBuyPtID = []
  let lstAuditLoc = []
  let lstPeanutTpyeId = []
  let lstPeanutType = []
  let lstWhs = []
  let lstBin = []
  let lstPeanutVariety = []
  let lstSeedGen = []
  let lstSegType = []
  let lstOleicInd = []
  let lstOrganic = []
  let lblSource = ""
  let lblAuditLoc = ""
  let lblGeneration = ""
  let lblOleic = ""
  let lblOrganic = ""
  let lblPeanutVariety = ""
  let lblSeg = ""
  let lblWhs = ""
  let lblBin = ""
  let labelSC95Val = ""
  let label1007Val = ""
  let labelWhsRcptVal = ""
  let labelTransferVal = ""
  let labelTradeInspVal = ""
  let labelTradeSettleVal = ""
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ManualDPRInvMaint",
    windowName: "ManualDPRInvMaint",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.ManualDPRInvMaint",
    // START_USER_CODE-USER_ManualDPRInvMaint_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "DPR Inventory Maintenance",
      scrCode: "PN0320B",
    },
    // END_USER_CODE-USER_ManualDPRInvMaint_PROPERTIES
    btnOK: {
      name: "btnOK",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOK_PROPERTIES

      // END_USER_CODE-USER_btnOK_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    ddAuditBPLoc: {
      name: "ddAuditBPLoc",
      type: "DropDownFieldWidget",
      parent: "grpbxManualDPRInvMaint",
      Label: "Audit BP Loc:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAuditBPLoc_PROPERTIES

      // END_USER_CODE-USER_ddAuditBPLoc_PROPERTIES
    },
    txtSC95: {
      name: "txtSC95",
      type: "TextBoxWidget",
      parent: "grpbxManualDPRInvMaint",
      Label: "SC95 #:",
      ColSpan: "7",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95_PROPERTIES

      // END_USER_CODE-USER_txtSC95_PROPERTIES
    },
    txt1007: {
      name: "txt1007",
      type: "TextBoxWidget",
      parent: "grpbxManualDPRInvMaint",
      Label: "1007 #:",
      ColSpan: "7",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007_PROPERTIES

      // END_USER_CODE-USER_txt1007_PROPERTIES
    },
    ddAuditDate: {
      name: "ddAuditDate",
      type: "DateWidget",
      parent: "grpbxManualDPRInvMaint",
      Label: "Audit Date:",
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_ddAuditDate_PROPERTIES

      // END_USER_CODE-USER_ddAuditDate_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxManualDPRInvMaint",
      Label: "Buying Point:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    ddOleicInd: {
      name: "ddOleicInd",
      type: "DropDownFieldWidget",
      parent: "grpbxManualDPRInvMaint",
      Label: "Oleic Ind:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleicInd_PROPERTIES

      // END_USER_CODE-USER_ddOleicInd_PROPERTIES
    },
    ddOrganicInd: {
      name: "ddOrganicInd",
      type: "DropDownFieldWidget",
      parent: "grpbxManualDPRInvMaint",
      Label: "Organic Ind:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOrganicInd_PROPERTIES

      // END_USER_CODE-USER_ddOrganicInd_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxManualDPRInvMaint",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxManualDPRInvMaint",
      Label: "Peanut Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddSeedGeneration: {
      name: "ddSeedGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxManualDPRInvMaint",
      Label: "Seed Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeedGeneration_PROPERTIES

      // END_USER_CODE-USER_ddSeedGeneration_PROPERTIES
    },
    ddSegType: {
      name: "ddSegType",
      type: "DropDownFieldWidget",
      parent: "grpbxManualDPRInvMaint",
      Label: "Seg Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegType_PROPERTIES

      // END_USER_CODE-USER_ddSegType_PROPERTIES
    },
    ddSource: {
      name: "ddSource",
      type: "DropDownFieldWidget",
      parent: "grpbxManualDPRInvMaint",
      Label: "Source:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSource_PROPERTIES

      // END_USER_CODE-USER_ddSource_PROPERTIES
    },
    ddWarehouseBin: {
      name: "ddWarehouseBin",
      type: "DropDownFieldWidget",
      parent: "grpbxManualDPRInvMaint",
      Label: "Whs/Bin:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWarehouseBin_PROPERTIES

      // END_USER_CODE-USER_ddWarehouseBin_PROPERTIES
    },
    grpbxAdjustments: {
      name: "grpbxAdjustments",
      type: "GroupBoxWidget",
      parent: "grpbxManualDPRInvMaint",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxAdjustments_PROPERTIES

      // END_USER_CODE-USER_grpbxAdjustments_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblNote: {
      name: "lblNote",
      type: "LabelWidget",
      parent: "grpbxAdjustments",
      Label: "Note: All weights are in Tons",
      ColSpan: "7",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNote_PROPERTIES

      // END_USER_CODE-USER_lblNote_PROPERTIES
    },
    txtChangeRemarks: {
      name: "txtChangeRemarks",
      type: "TextBoxWidget",
      parent: "grpbxAdjustments",
      Label: "Change Remarks:",
      ColSpan: "7",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangeRemarks_PROPERTIES

      // END_USER_CODE-USER_txtChangeRemarks_PROPERTIES
    },
    txtCompOwnedAdj: {
      name: "txtCompOwnedAdj",
      type: "TextBoxWidget",
      parent: "grpbxAdjustments",
      Label: "Comp Owned Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCompOwnedAdj_PROPERTIES

      // END_USER_CODE-USER_txtCompOwnedAdj_PROPERTIES
    },
    txtIntransitAdj: {
      name: "txtIntransitAdj",
      type: "TextBoxWidget",
      parent: "grpbxAdjustments",
      Label: "Intransit Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIntransitAdj_PROPERTIES

      // END_USER_CODE-USER_txtIntransitAdj_PROPERTIES
    },
    txtNotReceiptedAdj: {
      name: "txtNotReceiptedAdj",
      type: "TextBoxWidget",
      parent: "grpbxAdjustments",
      Label: "Not Receipted Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNotReceiptedAdj_PROPERTIES

      // END_USER_CODE-USER_txtNotReceiptedAdj_PROPERTIES
    },
    txtPhysicalAdj: {
      name: "txtPhysicalAdj",
      type: "TextBoxWidget",
      parent: "grpbxAdjustments",
      Label: "Physical Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhysicalAdj_PROPERTIES

      // END_USER_CODE-USER_txtPhysicalAdj_PROPERTIES
    },
    txtShrinkAdj: {
      name: "txtShrinkAdj",
      type: "TextBoxWidget",
      parent: "grpbxAdjustments",
      Label: "Shrink Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrinkAdj_PROPERTIES

      // END_USER_CODE-USER_txtShrinkAdj_PROPERTIES
    },
    txtUndeterminedAdj: {
      name: "txtUndeterminedAdj",
      type: "TextBoxWidget",
      parent: "grpbxAdjustments",
      Label: "Undetermined Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUndeterminedAdj_PROPERTIES

      // END_USER_CODE-USER_txtUndeterminedAdj_PROPERTIES
    },
    txtWhsReceiptAdj: {
      name: "txtWhsReceiptAdj",
      type: "TextBoxWidget",
      parent: "grpbxAdjustments",
      Label: "Whs Receipt Adj:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhsReceiptAdj_PROPERTIES

      // END_USER_CODE-USER_txtWhsReceiptAdj_PROPERTIES
    },
    grpbxManualDPRInvMaint: {
      name: "grpbxManualDPRInvMaint",
      type: "GroupBoxWidget",
      parent: "ManualDPRInvMaint",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxManualDPRInvMaint_PROPERTIES

      // END_USER_CODE-USER_grpbxManualDPRInvMaint_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ManualDPRInvMaint",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};

  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);

  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    setEventHandlers();

    return () => {
      removeEventHandlers();
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() =>{
    return () =>{
      setData(thisObj, 'ClosingOfManualDPRInvMaint', true)
    }
  })

  // START_USER_CODE-USER_METHODS

  const setEventHandlers = () => {
    document.getElementById('txtSC95').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txt1007').addEventListener('keypress', allowOnlyNumbers);
  };

  const removeEventHandlers = () => {
    document.getElementById('txtSC95').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txt1007').removeEventListener('keypress', allowOnlyNumbers);
  };

  const allowOnlyNumbers = (e) => {
    try {
      let labelSC95 = document.getElementsByClassName('txtSC95')[0].childNodes[0].childNodes[0].innerText
      let label1007 = document.getElementsByClassName('txt1007')[0].childNodes[0].childNodes[0].innerText
      
      if (!((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8)) {
        if(['Whs Rcpt #:'].includes(labelSC95) && document.activeElement?.name == 'txtSC95'){
          e.preventDefault();
        }
        if(['1007 #:'].includes(label1007) && document.activeElement?.name == 'txt1007'){
          e.preventDefault();
        }
      }
    } catch (err) {
      errorHandler(err, "allowOnlyNumbers")
    }
  };

  async function FormLoad() {
    try {
      thisObj.values.txt1007 = ''
      thisObj.values.txtSC95 = ''
      
      setData(thisObj, 'labelSC95Val', '')
      setData(thisObj, 'label1007Val', '')
      setData(thisObj, 'labelWhsRcptVal', '')
      setData(thisObj, 'labelTransferVal', '')
      setData(thisObj, 'labelTradeInspVal', '')
      setData(thisObj, 'labelTradeSettleVal', '')

      setData(thisObj, 'ManualDPRInvSearchGridReload', false)
      setData(thisObj, 'mbSuccessfulLoad', true)

      if (! await bFillBuyPtList()) {
        setData(thisObj, 'mbSuccessfulLoad', false)
      }

      if (! await bFillTypeList()) {
        setData(thisObj, 'mbSuccessfulLoad', false)
      }

      if (! await bFillSourceList()) {
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
      
      thisObj.setState(current => {
        return {
          ...current,
          ddWarehouseBin: {
            ...state["ddWarehouseBin"],
            valueList: [{ key : "", description :  ""}]
          }
        }
      })
      thisObj.values.ddWarehouseBin = ''

      thisObj.values.ddAuditDate = new Date().toLocaleDateString().padStart(10, '0')

      let screenData = getData(thisObj, 'ManualDPRInvMainData')
      let sourceValue = ""
      
      setLoading(false)
      if(screenData?.lstrAction){
        /*
          calling the bFillForm method, as in VB code, it is automatically called from the parent scren
          'onddPeanutTypeChange' and 'onddSourceChange' methods are called as on form load, these are been changed on form load
          and corresponding methods needs to be performed for other form controls.
        */
        if(['CHG', 'DEL'].includes(screenData.lstrAction)){
          await onddBuyingPointChange()
          sourceValue = getData(thisObj, 'ManualDPRInvMainDataUpdate') !== null ? getData(thisObj, 'ManualDPRInvMainDataUpdate')?.source : "" 
        }

        await onddWarehouseBinChange()
        await onddPeanutTypeChange()
        await bFillForm(screenData?.lstrAction, screenData?.lstrRecordKey)
        await onddSourceChange({ target : { 
          value : sourceValue
        } }, screenData.lstrAction)
        
        document.getElementById('txtChangeRemarks').focus();
      }       
    }
    catch (err) {
      setData(thisObj, 'mbSuccessfulLoad', false)
      errorHandler(err)
    }
  }

  async function bFillBuyPtList() {
    try {
      let js = []
      let strXML = []
      
      // START clearing the buying point and audit BP loc drop down.
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })
      thisObj.values.ddBuyingPoint = ''

      thisObj.setState(current => {
        return {
          ...current,
          ddAuditBPLoc: {
            ...state["ddAuditBPLoc"],
            valueList: js
          }
        }
      })
      thisObj.values.ddAuditBPLoc = ''

      setData(thisObj, 'lstBuyPtID', "")
      setData(thisObj, 'lstAuditLoc', [])
      // END clearing the buying point and audit BP loc drop down.

      // START preparing data for the buying point and audit BP loc drop down.
      js = [{
        key: "",
        description: "",
      }]

      strXML = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0320', null, null, null, null)
      if (strXML) {
        strXML?.forEach(item => {
          js.push({
            key: item?.buy_pt_id?.toString(),
            description: `${item?.buy_pt_id?.toString()} -- ${toPascalCase(item?.buy_pt_name?.toString())}`
          })
        })
      }
      // END preparing data for the buying point and audit BP loc drop down.

      // START entering data for the buying point and audit BP loc drop down.
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })
      
      let data = getData(thisObj, 'ManualDPRInvMainDataUpdate')
      if(![undefined, null, ""].includes(data?.buyingPt)){
        setData(thisObj, 'lstBuyPtID', data?.buyingPt)
        thisObj.values.ddBuyingPoint = data?.buyingPt        
      }
      else{
        setData(thisObj, 'lstBuyPtID', js.at(0).key)
        thisObj.values.ddBuyingPoint = js.at(0).key
      }     

      thisObj.setState(current => {
        return {
          ...current,
          ddAuditBPLoc: {
            ...state["ddAuditBPLoc"],
            valueList: js
          }
        }
      })
      thisObj.values.ddAuditBPLoc = js.at(0).key
      setData(thisObj, 'lstAuditLoc', js)
      // END entering data for the buying point and audit BP loc drop down.

      return true
    }
    catch (err) {
      errorHandler(err, "bFillBuyPtList")
      return false
    }
  }

  async function bFillTypeList() {
    try {
      let js = []
      let res = []
      let strXML = []

      // START clearing data for the peanut type drop down.
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js
          }
        }
      })
      thisObj.values.ddPeanutType = ''
      setData(thisObj, 'lstPeanutTpyeId', '')
      // END clearing data for the peanut type drop down.

      // START preparing data for the peanut type drop down.
      js = [{
        key: "",
        description: "",
      }]

      strXML = await ContractManagementService.RetrievePeanutTypeControls(null)
      if (strXML) {
        strXML?.forEach(item => {
          js.push({
            key: item?.pnutTypeId?.toString(),
            description: `${item?.pnutTypeId?.toString()} -- ${item?.pnutTypeName?.toString()}`
          })
          res.push(item?.pnutTypeId?.toString())
        })
      }
      // END preparing data for the peanut type drop down.

      // START entering data for the peanut type drop down.
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js
          }
        }
      })

      let data = getData(thisObj, 'ManualDPRInvMainDataUpdate')
      if(![undefined, null, ""].includes(data?.peanutType) && data.lstrAction !== 'ADD'){
        setData(thisObj, 'lstPeanutTpyeId', data?.peanutType)
        thisObj.values.ddPeanutType = data?.peanutType        
      }
      else{
        setData(thisObj, 'lstPeanutTpyeId', js.at(0).key)
        thisObj.values.ddPeanutType = js.at(0).key
      }
      setData(thisObj, 'lstPeanutType', res)
      // END entering data for the peanut type drop down.

      return true
    }
    catch (err) {
      errorHandler(err, "bFillTypeList")
      return false
    }
  }

  async function bFillSourceList() {
    try {
      let js = [
        { key: "", description: "" },
        { key: "1", description: "SC95 / 1007" },
        { key: "2", description: "Whs Receipt" },
        { key: "3", description: "Transfer" },
        { key: "4", description: "Trade Settle" },
      ]

      thisObj.setState(current => {
        return {
          ...current,
          ddSource: {
            ...state["ddSource"],
            valueList: js
          }
        }
      })
      thisObj.values.ddSource = js.at(0).key
      return true
    }
    catch (err) {
      errorHandler(err, "bFillSourceList")
      return false
    }
  }

  async function bFillWhsBinList() {
    try {
      let js = []
      let resWhs = []
      let resBin = []
      let strXML = []

      // START clearing data for the warehouse bin drop down.
      thisObj.setState(current => {
        return {
          ...current,
          ddWarehouseBin: {
            ...state["ddWarehouseBin"],
            valueList: js
          }
        }
      })
      thisObj.values.ddWarehouseBin = ''

      setData(thisObj, 'lstWhs', js)
      setData(thisObj, 'lstBin', js)
      // END clearing data for the warehouse bin drop down.

      // START preparing data for the warehouse bin drop down.
      js = [{
        key: "",
        description: ""
      }]
      resWhs.push("")
      resBin.push("")

      let buyingPt = thisObj.values.ddBuyingPoint
      strXML = await SettlementService.RetrieveWhouseBinInventoryDetails(buyingPt, null, null, null, null)
      strXML?.forEach(item => {
        js.push({
          key: `${item?.whouseNum?.toString()}-${item?.binNum?.toString()}`,
          description: `${item?.whouseNum?.toString()}/${item?.binNum?.toString()}  --  ${item?.whouseBinId?.toString()}`
        })

        resWhs.push(item?.whouseNum?.toString())
        resBin.push(item?.binNum?.toString())
      })
      // END preparing data for the warehouse bin drop down.

      // START entering data for the warehouse bin drop down.      
      thisObj.setState(current => {
        return {
          ...current,
          ddWarehouseBin: {
            ...state["ddWarehouseBin"],
            valueList: js
          }
        }
      })
      
      thisObj.values.ddWarehouseBin = js.at(0).key
      setData(thisObj, 'lblWhs', js.at(0).key?.toString()?.split("-")[0])
      setData(thisObj, 'lblBin', js.at(0).key?.toString()?.split("-")[1])

      setData(thisObj, 'lstWhs', resWhs)
      setData(thisObj, 'lstBin', resBin)
      // END entering data for the warehouse bin drop down.      

      return true;
    }
    catch (err) {
      errorHandler(err, "bFillWhsBinList")
      return false
    }
  }

  async function bFillGenerationList() {
    try {
      let js = [
        { key: "", description: "" },
        { key: "F", description: "Foundation Seed" },
        { key: "R", description: "Registered Seed" },
        { key: "C", description: "Certified Seed" }
      ]

      thisObj.setState(current => {
        return {
          ...current,
          ddSeedGeneration: {
            ...state["ddSeedGeneration"],
            valueList: js
          }
        }
      })

      setData(thisObj, 'lstSeedGen', js)
      thisObj.values.ddSeedGeneration = js.at(0).key
      return true
    }
    catch (err) {
      errorHandler(err, "bFillGenerationList")
      return false
    }
  }

  async function bFillSegmentList() {
    try {
      let js = [
        { key: "", description: "" },
        { key: "1", description: "Seg1" },
        { key: "2", description: "Seg2" },
        { key: "3", description: "Seg3" }
      ]

      thisObj.setState(current => {
        return {
          ...current,
          ddSegType: {
            ...state["ddSegType"],
            valueList: js
          }
        }
      })

      setData(thisObj, 'lstSegType', js)
      thisObj.values.ddSegType = js.at(0).key
      return true
    }
    catch (err) {
      errorHandler(err, "bFillSegmentList")
      return false
    }
  }

  async function bFillOleicList() {
    try {
      let js = [
        { key: "", description: "" },
        { key: "M", description: "Mid" },
        { key: "H", description: "High" }
      ]

      thisObj.setState(current => {
        return {
          ...current,
          ddOleicInd: {
            ...state["ddOleicInd"],
            valueList: js
          }
        }
      })

      setData(thisObj, 'lstOleicInd', js)
      thisObj.values.ddOleicInd = js.at(0).key
      return true
    }
    catch (err) {
      errorHandler(err, "bFillOleicList")
      return false
    }
  }

  async function bFillOrganicList() {
    try {
      let js = [
        { key: "", description: "" },
        { key: "N", description: "No" },
        { key: "Y", description: "Yes" }
      ]

      thisObj.setState(current => {
        return {
          ...current,
          ddOrganicInd: {
            ...state["ddOrganicInd"],
            valueList: js
          }
        }
      })

      setData(thisObj, 'lstOrganic', js)
      thisObj.values.ddOrganicInd = js.at(0).key
      return true
    }
    catch (err) {
      errorHandler(err, "bFillOrganicList")
      return false
    }
  }

  async function bFillVarietyList() {
    try {
      let sXML = []
      let js = []
      let sSymbol = "";
      let sSavVarID = "";
      let bNewVar = false;
      let lstPeanutVarList = []

      // setLoading(true)

      js = [{
        key: '',
        description: '>>> All Varieties <<<'
      }]

      sSavVarID = thisObj.values.ddPeanutVariety?.trim()?.toLocaleUpperCase()

      let peanutType = thisObj.values.ddPeanutType
      if (peanutType !== "" && peanutType) {
        let buyPtID = thisObj.values.ddBuyingPoint
        sXML = await ContractManagementService.RetrievePeanutVarietyControls(buyPtID, null, null, peanutType, null)
        if (sXML) {
          for (var i = 0; i < sXML?.length; i++) {
            bNewVar = true;

            for (var j = 0; j < lstPeanutVarList?.length; j++) {
              if (lstPeanutVarList[j]?.trim().toLocaleUpperCase() == sXML[i].pnut_variety_id?.trim()?.toLocaleUpperCase()) {
                bNewVar = false;
                break;
              }
            }

            if (bNewVar) {
              switch (sXML[i].symbol_ind.trim().toLocaleUpperCase()) {
                case "TRADEMARK":
                  sSymbol = " \u2122"
                  break;
                case "REGISTERED TRADEMARK":
                  sSymbol = " \xAE"
                  break;
                case "COPYRIGHT":
                  sSymbol = " \xA9"
                  break;
                default:
                  sSymbol = ""
              }

              js.push({
                key: sXML[i].pnut_variety_id,
                description: sXML[i].pnut_variety_name + sSymbol
              })

              lstPeanutVarList.push(sXML[i].pnut_variety_id)
            }
          }
        }
      }

      setData(thisObj, 'lstPeanutVarietyID', lstPeanutVarList)

      let peanutVar = thisObj.values.ddPeanutVariety      
      if(![undefined, null, ''].includes(peanutVar)){
        thisObj.values.ddPeanutVariety = peanutVar
      }
      else {
        thisObj.setState(current => {
          return {
            ...current,
            ddPeanutVariety: {
              ...state["ddPeanutVariety"],
              valueList: js
            }
          }
        })
 
        if (js?.length) {
          thisObj.values.ddPeanutVariety = js.at(0).key
        }
        else {
          thisObj.values.ddPeanutVariety = ""
        }
      }
      
      return true
    }
    catch (err) {
      errorHandler(err, "bFillVarietyList")
      return false
    }
    finally{
      // setLoading(false)
    }
  }

  async function bFormValid(lstrAction) {
    try {
      let strXML = []
      let strMsgHdr = ""

      if (lstrAction == "ADD") {
        strMsgHdr = "Create Inventory Adjustment"
      }
      else if (lstrAction == "CHG") {
        strMsgHdr = "Update Inventory Adjustment"
      }
      else if (lstrAction == "DEL") {
        strMsgHdr = "Delete Inventory Adjustment"
      }
      else {
        strMsgHdr = "Inventory Adjustment"
      }

      let buyPtID = thisObj.values.ddBuyingPoint
      let access = lstrAction == "DEL" ? "D" : "U"
      strXML = await ContractManagementService.RetrieveAccessPermissionDetails('PN0320', null, access, buyPtID)
      if (strXML?.[0]?.permission?.toLocaleUpperCase() !== "Y") {
        alert("Security Access not Authorized!")
        return
      }
      
      if ([ undefined, null, "" ].includes(thisObj.values.txtChangeRemarks)) {
        alert("Change Remarks are required!")
        document.getElementById('txtChangeRemarks')?.focus();
        return;
      }

      if (lstrAction == "DEL") {
        return true
      }

      if (buyPtID?.trim() == "") {
        alert("A valid Buying Point is required!")
        document.getElementById('ddBuyingPoint').focus();
        return;
      }

      if (thisObj.values.ddWarehouseBin?.trim() == "") {
        alert("A valid Warehouse/Bin is required!")
        document.getElementById('ddWarehouseBin').focus();
        return;
      }

      if (thisObj.values.ddPeanutType?.trim() == "") {
        alert("A valid Peanut Type is required!")
        document.getElementById('ddPeanutType').focus();
        return;
      }

      let msWBPType = getData(thisObj, "mstrWBPnutTYP")
      if (msWBPType !== "" && msWBPType !== thisObj.values.ddPeanutType) {
        alert(`Peanut Type does not match the Peanut Type on the Whs/Bin \ndefinition  --  (${getData(thisObj, 'lstPeanutTpyeId')})!`)
        document.getElementById('ddPeanutType').focus();
        return;
      }

      let msWBPVar = getData(thisObj, "mstrWBPnutVAR")
      if (msWBPVar !== ""  && msWBPVar !== thisObj.values.ddPeanutVariety) {
        if(!confirm(`Peanut Variety does not match the Peanut Variety on the Whs/Bin definition  --  (${msWBPVar})  -- Continue Anyway?`)){
          document.getElementById('ddPeanutVariety').focus();
          return;
        }        
      }

      let msWBPGen = getData(thisObj, "mstrWBPnutGEN")
      if (msWBPGen !== "" && msWBPGen !== thisObj.values.ddSeedGeneration) {
        if(!confirm(`Seed Generation does not match the Seed Generation on the Whs/Bin definition  --  (${msWBPGen})  -- Continue Anyway?`)){
          document.getElementById('ddSeedGeneration').focus();
          return;
        }
      }

      if (thisObj.values.ddSegType?.trim() == "") {
        alert("A valid Peanut Type is required!")
        document.getElementById('ddSegType').focus();
        return;
      }

      let msWBPSeg = getData(thisObj, "mstrWBPnutSEG")
      if (msWBPSeg !== "" && msWBPSeg !== thisObj.values.ddSegType) {
        alert(`Seg Type does not match the Seg Type on the Whs/Bin definition  --  ("${msWBPSeg}")!`)
        document.getElementById('ddSegType').focus();
        return;
      }

      let msWBPOliec = getData(thisObj, "mstrWBPnutOLEIC")
      if (msWBPOliec !== "" && msWBPOliec !== thisObj.values.ddOleicInd) {
        if(!confirm(`Oleic Ind does not match the Oleic Ind on the Whs/Bin definition  --  (${msWBPOliec})  -- Continue Anyway?`)){
          document.getElementById('ddOleicInd').focus();
          return;
        }        
      }

      let msWBPOrg = getData(thisObj, "mstrWBPnutORGANIC")
      if (msWBPOrg !== "" && msWBPOrg !== thisObj.values.ddOrganicInd) {
        if(!confirm(`Organic Ind does not match the Organic Ind on the Whs/Bin definition  --  (${msWBPOrg})  -- Continue Anyway?`)){
          document.getElementById('ddOrganicInd').focus();
          return;
        }        
      }

      if (!(new Date(thisObj.values.ddAuditDate) instanceof Date)) {
        alert("A valid Audit Date is required!")
        document.getElementById("ddAuditDate")?.focus();
        return;
      }

      let date1 = new Date("01/01/" + cropYearFromLS())
      let date2 = new Date(thisObj.values.ddAuditDate)
      
      let res = (date2?.getTime() - date1?.getTime()) / (1000 * 3600 * 24);
      if (Number(res) < 0) {
        alert("Audit Date cannot be earlier than start of crop year date " + cropYearFromLS())
        document.getElementById("ddAuditDate")?.focus();
        return;
      }

      date1 = new Date()
      res = (date1?.getTime() - date2?.getTime()) / (1000 * 3600 * 24);
      if (Number(res) < 0) {
        alert("Audit Date cannot be greater than current date " + moment(new Date()).format("dd/mm/yyyy"))
        document.getElementById("ddAuditDate")?.focus();
      }

      if (getData(thisObj, 'lblSource')?.trim() == "") {
        alert("A valid Source is required!")
        document.getElementById('ddSource').focus();
        return;
      }

      if (thisObj.values.ddAuditBPLoc?.trim() == "") {
        alert("A valid Audit Buying Point is required!")
        document.getElementById('ddAuditBPLoc').focus();
        return;
      }

      let label1 = document.getElementsByClassName('txtSC95')[0].childNodes[0].childNodes[0].innerText
      let label2 = document.getElementsByClassName('txt1007')[0].childNodes[0].childNodes[0].innerText
      if ((label1 == "SC95 #:") && (getData(thisObj, 'lblSource')?.trim() == "1" && ([undefined, null, ""].includes(thisObj.values.txtSC95?.trim())))) { 
        alert("An SC95 Number is required for a SC95/1007 Source!")
        document.getElementById('txtSC95')?.focus();
        return;
      }

      if ((label1 == "SC95 #:") && ((![undefined, null, ""].includes(thisObj.values.txtSC95?.trim())) && thisObj.values.txtSC95?.length !== 7)) {
        alert("SC95 Number is not Valid!")
        document.getElementById('txtSC95')?.focus();
        return;
      }

      if ((label2 == "1007 #:") && ((![undefined, null, ""].includes(thisObj.values.txt1007?.trim())) && thisObj.values.txt1007?.length !== 7)) {
        alert("1007 Number is not Valid!")
        document.getElementById('txt1007')?.focus();
        return;
      }

      if((label1 == "Whs Rcpt #:") &&  (getData(thisObj, 'lblSource')?.trim() == 2 && (thisObj.values.txtSC95?.trim() == ""))){ 
        alert("A Warehouse Receipt Number is required for a Whs Receipt Source!")
        document.getElementById('txtSC95')?.focus(); 
        return;
      }

      if((label1 == "Whs Rcpt #:") && ((thisObj.values.txtSC95?.trim() !== "") && thisObj.values.txtSC95?.length !== 6)){ 
        alert("Warehouse Receipt Number is not Valid!")
        document.getElementById('txtSC95')?.focus(); 
        return;
      }

      if((label1 == "Transfer #:") && (getData(thisObj, 'lblSource')?.trim() == "3" && (thisObj.values.txtSC95?.trim() == ""))){  
        alert("A Transfer Number is required for a Transfer Source!")
        document.getElementById('txtSC95')?.focus(); 
        return;
      }

      if((label1 == "Transfer #:") &&  ((thisObj.values.txtSC95?.trim() !== "") && thisObj.values.txtSC95?.length !== 6)){ 
        alert("Transfer Number is not Valid!")
        document.getElementById('txtSC95')?.focus(); 
        return;
      }

      if((label1 == "Trade Insp #:") &&  (getData(thisObj, 'lblSource')?.trim() == "4" && (thisObj.values.txtSC95?.trim() == ""))){
        alert("A Settlement Inspection Number is required for a Trade Settle Source!")
        document.getElementById('txtSC95')?.focus(); 
        return;
      }

      if((label1 == "Trade Insp #:") && ((thisObj.values.txtSC95?.trim() !== "") && thisObj.values.txtSC95?.length !== 7)){ 
        alert("Trade Settlement Inspection Number is not Valid!")
        document.getElementById('txtSC95')?.focus(); 
        return;
      }

      if((label2 == "Trade Settle #:") && ((thisObj.values.txt1007?.trim() !== "") && thisObj.values.txt1007?.length !== 7)){ 
        alert("Trade Settlement Number is not Valid!")
        document.getElementById('txt1007')?.focus(); 
        return;
      }

      let physicalAdj = thisObj.values.txtPhysicalAdj?.toString()?.replaceAll(",", '')
      if (physicalAdj !== "" && !isNumeric(physicalAdj)) {
        alert("Physical Adjustment is not Numeric!")
        document.getElementById("txtPhysicalAdj")?.focus();
        return;
      }

      let whsReceiptAdj = thisObj.values.txtWhsReceiptAdj?.toString()?.replaceAll(",", '')
      if (whsReceiptAdj !== "" && !isNumeric(whsReceiptAdj)) {
        alert("Warehouse Receipt Adjustment is not Numeric!")
        document.getElementById("txtWhsReceiptAdj")?.focus();
        return;
      }

      let shrinkAdj = thisObj.values.txtShrinkAdj?.toString()?.replaceAll(",", '')
      if (shrinkAdj !== "" && !isNumeric(shrinkAdj)) {
        alert("Shrink Adjustment is not Numeric!")
        document.getElementById("txtShrinkAdj")?.focus();
        return;
      }

      let notReceiptedAdj = thisObj.values.txtNotReceiptedAdj?.toString()?.replaceAll(",", '')
      if (notReceiptedAdj !== "" && !isNumeric(notReceiptedAdj)) {
        alert("Unreceipted Receipt Adjustment is not Numeric!")
        document.getElementById("txtNotReceiptedAdj")?.focus();
        return;
      }

      let undeterminedAdj = thisObj.values.txtUndeterminedAdj?.toString()?.replaceAll(",", '')
      if (undeterminedAdj !== "" && !isNumeric(undeterminedAdj)) {
        alert("Undetermined Adjustment is not Numeric!")
        document.getElementById("txtUndeterminedAdj")?.focus();
        return;
      }

      let compOwnAdj = thisObj.values.txtCompOwnedAdj?.toString()?.replaceAll(",", '')
      if (compOwnAdj !== "" && !isNumeric(compOwnAdj)) {
        alert("Company Owned Adjustment is not Numeric!")
        document.getElementById("txtCompOwnedAdj")?.focus();
        return;
      }

      let inTransAdj = thisObj.values.txtIntransitAdj?.toString()?.replaceAll(",", '')
      if (inTransAdj !== "" && !isNumeric(inTransAdj)) {
        alert("Intransit Adjustment is not Numeric!")
        document.getElementById("txtIntransitAdj")?.focus();
        return;
      }
      return true
    }
    catch (err) {
      errorHandler(err, "bFormValid")
      return false
    }
  }

  async function bFillForm(lstrAction, lstrRecordKey) {
    try {
      let bFillFormBool = false
      let strXML = []
      
      if (lstrRecordKey == "") {
        return;
      }
      
      // lblRecordKey.Caption = "" thisObj.values.lblRecordKey = ""

      disable(thisObj, 'ddBuyingPoint')
      disable(thisObj, 'ddWarehouseBin')
      disable(thisObj, 'ddPeanutType')
      disable(thisObj, 'ddPeanutVariety')
      disable(thisObj, 'ddSeedGeneration')
      disable(thisObj, 'ddSegType')
      disable(thisObj, 'ddOleicInd')
      disable(thisObj, 'ddOrganicInd')
      disable(thisObj, 'ddAuditDate')
      disable(thisObj, 'ddSource')
      disable(thisObj, 'ddAuditBPLoc')
      disable(thisObj, 'btnOK')
      hide(thisObj, 'btnOK')

      if (lstrAction == "ADD") {
        document.getElementById('btnOK').innerHTML = "Add"
        strXML = await ContractManagementService.RetrieveAccessPermissionDetails('PN0320', null, 'U', null)
        if (strXML?.[0]?.permission?.toLocaleUpperCase() == "Y") {
          enable(thisObj, 'ddBuyingPoint')
          enable(thisObj, 'ddWarehouseBin')
          enable(thisObj, 'ddPeanutType')
          enable(thisObj, 'ddPeanutVariety')
          enable(thisObj, 'ddSeedGeneration')
          enable(thisObj, 'ddSegType')
          enable(thisObj, 'ddOrganicInd')
          enable(thisObj, 'ddAuditDate')
          enable(thisObj, 'ddSource')
          enable(thisObj, 'ddAuditBPLoc')
          enable(thisObj, 'btnOK')

          show(thisObj, 'btnOK')
          hide(thisObj, 'ddOleicInd')
          bFillFormBool = true
        }
        return bFillFormBool
      }

      disable(thisObj, 'txtSC95')
      disable(thisObj, 'txt1007')

      document.getElementById('txtSC95').style.backgroundColor = "#F8F4F4" // &H8000000F
      document.getElementById('txt1007').style.backgroundColor = "#F8F4F4"

      strXML = await SystemMaintenanceApplicationSupportService.RetrieveManualInvestmaintDetails("INV_INQ", lstrRecordKey, null)
      if (strXML?.length <= 0) {
        return bFillFormBool
      }
      if (strXML.status == 500) {
        alert("An error occured while accessing the database.  Contact your system administrator if this problem persists.")
        return bFillFormBool
      }

      // thisObj.values.lblRecordKey = strXML[0]?.inventory_adjustments[0]?.transaction_num
      let cboBuying = getData(thisObj, 'lstAuditLoc')
      cboBuying?.forEach(item => {
        if (item?.key == strXML[0]?.inventory_adjustments[0]?.buy_pt_id) {
          thisObj.values.ddBuyingPoint = strXML[0]?.inventory_adjustments[0]?.buy_pt_id
        }
      })

      let cboWhs = getData(thisObj, 'lstWhs')
      let cboBin = getData(thisObj, 'lstBin')
      for (var i = 0; i < cboWhs?.length; i++) {
        if (cboWhs[i] == strXML[0]?.inventory_adjustments[0]?.whouse_num &&
          cboBin[i] == strXML[0]?.inventory_adjustments[0]?.bin_num) {
            thisObj.values.ddWarehouseBin = `${strXML[0]?.inventory_adjustments[0]?.whouse_num}-${strXML[0]?.inventory_adjustments[0]?.bin_num}`
            await onddWarehouseBinChange()
        }
      }

      let cboPeanutType = getData(thisObj, 'lstPeanutType')
      cboPeanutType?.forEach(item => {
        if (item == strXML[0]?.inventory_adjustments[0]?.pnut_type_id) {
          thisObj.values.ddPeanutType = strXML[0]?.inventory_adjustments[0]?.pnut_type_id
        }
      })

      let cboPeanutVariety = getData(thisObj, 'lstPeanutVarietyID')
      cboPeanutVariety?.forEach(item => {
        if (item == strXML[0]?.inventory_adjustments[0]?.pnut_variety_id) {
          thisObj.values.ddPeanutVariety = strXML[0]?.inventory_adjustments[0]?.pnut_variety_id
        }
      })
      
      let cboGeneration = getData(thisObj, 'lstSeedGen')
      cboGeneration?.forEach(item => {
        if (item?.key == strXML[0]?.inventory_adjustments[0]?.seed_gen) {
          thisObj.values.ddSeedGeneration = strXML[0]?.inventory_adjustments[0]?.seed_gen
        }
      })

      let cboSeg = getData(thisObj, 'lstSegType')
      cboSeg?.forEach(item => {
        if (item?.key == strXML[0]?.inventory_adjustments[0]?.seg_type) {
          thisObj.values.ddSegType = strXML[0]?.inventory_adjustments[0]?.seg_type
        }
      })

      let cboOliec = getData(thisObj, 'lstOleicInd')
      cboOliec?.forEach(item => {
        if (item?.key == strXML[0]?.inventory_adjustments[0]?.oleic_ind) {
          thisObj.values.ddOleicInd = strXML[0]?.inventory_adjustments[0]?.oleic_ind
        }
      })

      let cboOrganic = getData(thisObj, 'lstOrganic')
      cboOrganic?.forEach(item => {
        if (item?.key == strXML[0]?.inventory_adjustments[0]?.organic_ind) {
          thisObj.values.ddOrganicInd = strXML[0]?.inventory_adjustments[0]?.organic_ind
        }
      })

      thisObj.values.ddAuditDate = new Date(strXML[0]?.inventory_adjustments[0]?.audit_date_time).toLocaleDateString().padStart(10, '0')

      let cboAuditBPLoc = getData(thisObj, 'lstAuditLoc')
      if (strXML[0]?.inventory_adjustments[0]?.audit_buy_pt !== "") {
        cboAuditBPLoc?.forEach(item => {
          if (item?.key == strXML[0]?.inventory_adjustments[0]?.audit_buy_pt) {
            thisObj.values.ddAuditBPLoc = strXML[0]?.inventory_adjustments[0]?.audit_buy_pt
          }
        })
      }

      if (strXML[0]?.inventory_adjustments[0]?.audit_insp_num !== "") {
        setData(thisObj, 'lblSource', "1") 
        thisObj.values.ddSource = "1"
        thisObj.values.txtSC95 = strXML[0]?.inventory_adjustments[0]?.audit_insp_num?.trim()
        thisObj.values.txt1007 = strXML[0]?.inventory_adjustments[0]?.audit_settle_num?.trim()
        setData(thisObj, 'labelSC95Val', strXML[0]?.inventory_adjustments[0]?.audit_insp_num?.trim())
        setData(thisObj, 'label1007Val', strXML[0]?.inventory_adjustments[0]?.audit_settle_num?.trim())
      }

      if (strXML[0]?.inventory_adjustments[0]?.audit_whse_rcpt !== "") {
        setData(thisObj, 'lblSource', "2") 
        thisObj.values.ddSource = "2"
        thisObj.values.txtSC95 = strXML[0]?.inventory_adjustments[0]?.audit_whse_rcpt?.trim()
        setData(thisObj, 'labelWhsRcptVal', strXML[0]?.inventory_adjustments[0]?.audit_whse_rcpt?.trim())
      }

      if (strXML[0]?.inventory_adjustments[0]?.audit_xfer_num !== "") {
        setData(thisObj, 'lblSource', "3") 
        thisObj.values.ddSource = "3"
        thisObj.values.txtSC95 = strXML[0]?.inventory_adjustments[0]?.audit_xfer_num?.trim()
        setData(thisObj, 'labelTransferVal', strXML[0]?.inventory_adjustments[0]?.audit_xfer_num?.trim())
      }

      if (strXML[0]?.inventory_adjustments[0]?.audit_trade_insp !== "") {
        setData(thisObj, 'lblSource', "4") 
        thisObj.values.ddSource = "4"
        thisObj.values.txtSC95 = strXML[0]?.inventory_adjustments[0]?.audit_trade_insp?.trim()
        thisObj.values.txt1007 = strXML[0]?.inventory_adjustments[0]?.audit_trade_settle?.trim()
        setData(thisObj, 'labelTradeInspVal', strXML[0]?.inventory_adjustments[0]?.audit_trade_insp?.trim())
        setData(thisObj, 'labelTradeSettleVal', strXML[0]?.inventory_adjustments[0]?.audit_trade_settle?.trim())
      }

      if (strXML[0]?.inventory_adjustments[0]?.audit_user !== "") {
        setData(thisObj, 'lblSource', "5")
        thisObj.setState(current => {
          return {
            ...current,
            ddSource: {
              ...state["ddSource"],
              valueList: [{
                key : "5",
                description : "Manual Adjust"
              }]
            }
          }
        })
        thisObj.values.ddSource = "5"
        thisObj.values.txtSC95 = strXML[0]?.inventory_adjustments[0]?.audit_user?.trim()
        setData(thisObj, 'label1007Val', strXML[0]?.inventory_adjustments[0]?.audit_settle_num?.trim())
      }

      thisObj.values.txtPhysicalAdj = getCommaSeparatedTwoDecimalsNumber(strXML[0]?.inventory_adjustments[0]?.physical_adj, 4)
      thisObj.values.txtWhsReceiptAdj = getCommaSeparatedTwoDecimalsNumber(strXML[0]?.inventory_adjustments[0]?.whouse_rcpt_adj, 4)
      thisObj.values.txtShrinkAdj = getCommaSeparatedTwoDecimalsNumber(strXML[0]?.inventory_adjustments[0]?.rcpt_shrink_adj, 4)
      thisObj.values.txtNotReceiptedAdj = getCommaSeparatedTwoDecimalsNumber(strXML[0]?.inventory_adjustments[0]?.unrcpted_adj, 4)
      thisObj.values.txtUndeterminedAdj = getCommaSeparatedTwoDecimalsNumber(strXML[0]?.inventory_adjustments[0]?.undetermined_adj, 4)
      thisObj.values.txtCompOwnedAdj = getCommaSeparatedTwoDecimalsNumber(strXML[0]?.inventory_adjustments[0]?.comp_own_adj, 4)
      thisObj.values.txtIntransitAdj = getCommaSeparatedTwoDecimalsNumber(strXML[0]?.inventory_adjustments[0]?.intransit_adj, 4)

      thisObj.values.txtChangeRemarks = strXML[0]?.inventory_adjustments[0]?.chg_maint_reason?.trim()
      thisObj.values.lblAddedByValue = ConvertDateTime(strXML[0]?.inventory_adjustments[0]?.add_date_time?.trim()) + "  --  "
        + strXML[0]?.inventory_adjustments[0]?.audit_reason?.trim()

      if (strXML[0]?.inventory_adjustments[0]?.chg_maint_user?.trim() !== "") {
        thisObj.values.lblChangedByValue = strXML[0]?.inventory_adjustments[0]?.chg_maint_user?.trim() + "  --  "
          + strXML[0]?.inventory_adjustments[0]?.chg_maint_user_name?.trim() + "  -on-  "
          + ConvertDateTime(strXML[0]?.inventory_adjustments[0]?.chg_maint_date_time?.trim())
      }

      let buyingPt = thisObj.values.ddBuyingPoint
      if (lstrAction == "CHG") {
        document.getElementById('btnOK').innerHTML = 'Save'
        strXML = await ContractManagementService.RetrieveAccessPermissionDetails('PN0320', null, 'U', buyingPt)
        if (strXML?.[0]?.permission?.toLocaleUpperCase() == "Y" && getData(thisObj, 'lblSource') !== "5") {
          enable(thisObj, 'ddWarehouseBin')
          enable(thisObj, 'ddPeanutType')
          enable(thisObj, 'ddPeanutVariety')
          enable(thisObj, 'ddSeedGeneration')
          enable(thisObj, 'ddSegType')
          enable(thisObj, 'ddOleicInd')
          enable(thisObj, 'ddOrganicInd')
          enable(thisObj, 'ddAuditDate')
          enable(thisObj, 'btnOK')
          show(thisObj, 'btnOK')
        }
        else {
          disable(thisObj, 'ddBuyingPoint')
          disable(thisObj, 'ddWarehouseBin')
          disable(thisObj, 'ddPeanutType')
          disable(thisObj, 'ddPeanutVariety')
          disable(thisObj, 'ddSeedGeneration')
          disable(thisObj, 'ddSegType')
          disable(thisObj, 'ddOleicInd')
          disable(thisObj, 'ddOrganicInd')
          disable(thisObj, 'ddAuditDate')
          disable(thisObj, 'ddSource')
          disable(thisObj, 'ddAuditBPLoc')
          
          disable(thisObj, 'txtPhysicalAdj')
          disable(thisObj, 'txtWhsReceiptAdj')
          disable(thisObj, 'txtShrinkAdj')
          disable(thisObj, 'txtNotReceiptedAdj')
          disable(thisObj, 'txtUndeterminedAdj')
          disable(thisObj, 'txtCompOwnedAdj')
          disable(thisObj, 'txtIntransitAdj')
          disable(thisObj, 'txtChangeRemarks')
          
          disable(thisObj, 'btnOK')
          hide(thisObj, 'btnOK', false)
        }
      }

      if (lstrAction == "DEL") {
        document.getElementById('btnOK').innerHTML = 'Delete'
        disable(thisObj, 'ddBuyingPoint')
        disable(thisObj, 'ddWarehouseBin')
        disable(thisObj, 'ddPeanutType')
        disable(thisObj, 'ddPeanutVariety')
        disable(thisObj, 'ddSeedGeneration')
        disable(thisObj, 'ddSegType')
        disable(thisObj, 'ddOleicInd')
        disable(thisObj, 'ddOrganicInd')
        disable(thisObj, 'ddAuditDate')
        disable(thisObj, 'ddSource')
        disable(thisObj, 'ddAuditBPLoc')

        disable(thisObj, 'txtPhysicalAdj')
        disable(thisObj, 'txtWhsReceiptAdj')
        disable(thisObj, 'txtShrinkAdj')
        disable(thisObj, 'txtNotReceiptedAdj')
        disable(thisObj, 'txtUndeterminedAdj')
        disable(thisObj, 'txtCompOwnedAdj')
        disable(thisObj, 'txtIntransitAdj')

        strXML = await ContractManagementService.RetrieveAccessPermissionDetails('PN0320', null, 'D', buyingPt)
        if (strXML?.[0]?.permission?.toLocaleUpperCase() == "Y") {
          enable(thisObj, 'btnOK')
          show(thisObj, 'btnOK')
        }
        else{
          disable(thisObj, 'txtChangeRemarks')
        }
      }

      return true
    }
    catch (err) {
      errorHandler(err, "bFillForm")
      return false
    }
  }

  async function Add(){
    try{
      let strXML = []

      if (!(await bFormValid("ADD"))) {
        return
      }

      let auditBuyPt  = ""
      let inspNum     = ""
      let settleNum   = ""
      let whsRcpt     = ""
      let xferNum     = ""
      let tradeInsp   = ""
      let tradeSettle = ""
      
      if(getData(thisObj, 'lblSource') == "1"){ 
        auditBuyPt   = thisObj.values['ddAuditBPLoc']
        inspNum      = thisObj.values.txtSC95
        settleNum    = thisObj.values.txt1007
      }

      if(getData(thisObj, 'lblSource') == "2"){ 
        auditBuyPt   = thisObj.values['ddAuditBPLoc']
        whsRcpt      = thisObj.values.txtSC95
      }

      if(getData(thisObj, 'lblSource') == "3"){ 
        auditBuyPt   = thisObj.values['ddAuditBPLoc']
        xferNum      = thisObj.values.txtSC95
      }

      if(getData(thisObj, 'lblSource') == "4"){ 
        auditBuyPt   = thisObj.values['ddAuditBPLoc']
        tradeInsp    = thisObj.values.txtSC95
        tradeSettle  = thisObj.values.txt1007
      }

      let dataObj = {
        "chg_maint_reason" : thisObj.values.txtChangeRemarks,
        "key_id"           : "",
        "buy_pt_id"        : thisObj.values['ddBuyingPoint'],
        "whs_num"          : thisObj.values['ddWarehouseBin']?.toString()?.split('-')[0],
        "bin_num"          : thisObj.values['ddWarehouseBin']?.toString()?.split('-')[1],
        "pnut_type"        : thisObj.values['ddPeanutType'],
        "pnut_variety"     : thisObj.values['ddPeanutVariety'],
        "seed_gen"         : thisObj.values['ddSeedGeneration'],
        "seg_type"         : thisObj.values['ddSegType'],
        "oleic_ind"        : thisObj.values['ddOleicInd'],
        "organic_ind"      : thisObj.values['ddOrganicInd'],
        "audit_date"       : thisObj.values.ddAuditDate,
        "audit_buy_pt"     : auditBuyPt,
        "insp_num"         : inspNum,
        "settle_num"       : settleNum,
        "whs_rcpt"         : whsRcpt,
        "xfer_num"         : xferNum,
        "trade_insp"       : tradeInsp,
        "trade_settle"     : tradeSettle,
        "physical_adj"     : checkNumber(thisObj.values.txtPhysicalAdj),
        "whouse_rcpt_adj"  : checkNumber(thisObj.values.txtWhsReceiptAdj),
        "rcpt_shrink_adj"  : checkNumber(thisObj.values.txtShrinkAdj),
        "unrcpted_adj"     : checkNumber(thisObj.values.txtNotReceiptedAdj),
        "undetermined_adj" : checkNumber(thisObj.values.txtUndeterminedAdj),
        "comp_own_adj"     : checkNumber(thisObj.values.txtCompOwnedAdj),
        "intransit_adj"    : checkNumber(thisObj.values.txtIntransitAdj)
      }
      
      strXML = await SystemMaintenanceApplicationSupportService.UpdateManualInvestmaint("ADD", dataObj)
      if (strXML?.status == 200) {
        alert("Inventory Adjustment record has been added successfully!")
        if(! await bFillForm("CHG", strXML?.result)){
          await onbtnExitClick()
        }
      }
      else if ([500, 400, 404].includes(strXML?.status)) {
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n" + strXML?.message)
      }
    }
    catch(err){
      errorHandler(err, "Add")
    }
  }

  async function Save(){
    try{
        let strXML = []

        if (!(await bFormValid("CHG"))) {
            return
        }

      let dataObj = {
        "chg_maint_reason" : thisObj.values.txtChangeRemarks,
        "key_id"           : getData(thisObj, 'ManualDPRInvMainData')?.lstrRecordKey, //thisObj.values.lblRecordKey
        "buy_pt_id"        : thisObj.values['ddBuyingPoint'],
        "whs_num"          : thisObj.values['ddWarehouseBin']?.toString()?.split('-')[0],
        "bin_num"          : thisObj.values['ddWarehouseBin']?.toString()?.split('-')[1],
        "pnut_type"        : thisObj.values['ddPeanutType'],
        "pnut_variety"     : thisObj.values['ddPeanutVariety'],
        "seed_gen"         : thisObj.values['ddSeedGeneration'],
        "seg_type"         : thisObj.values['ddSegType'],
        "oleic_ind"        : thisObj.values['ddOleicInd'],
        "organic_ind"      : thisObj.values['ddOrganicInd'],
        "audit_date"       : thisObj.values.ddAuditDate,
        "audit_buy_pt"     : "",
        "insp_num"         : "",
        "settle_num"       : "",
        "whs_rcpt"         : "",
        "xfer_num"         : "",
        "trade_insp"       : "",
        "trade_settle"     : "",
        "physical_adj"     : checkNumber(thisObj.values.txtPhysicalAdj),
        "whouse_rcpt_adj"  : checkNumber(thisObj.values.txtWhsReceiptAdj),
        "rcpt_shrink_adj"  : checkNumber(thisObj.values.txtShrinkAdj),
        "unrcpted_adj"     : checkNumber(thisObj.values.txtNotReceiptedAdj),
        "undetermined_adj" : checkNumber(thisObj.values.txtUndeterminedAdj),
        "comp_own_adj"     : checkNumber(thisObj.values.txtCompOwnedAdj),
        "intransit_adj"    : checkNumber(thisObj.values.txtIntransitAdj)
      }
      
      strXML = await SystemMaintenanceApplicationSupportService.UpdateManualInvestmaint("UPDATE", dataObj)
        if (strXML?.status == 200) {
            alert("Inventory Adjustment record has been updated successfully!")
        }
        else if ([500, 400, 404].includes(strXML?.status)) {
            alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n" + strXML?.message)
        }

    }
    catch(err){
        errorHandler(err, "Save")
    }
}

  async function Delete(){
    try{
      let strXML = []

      if (!(await bFormValid("DEL"))) {
        return
      }

      let dataObj = {
        "chg_maint_reason" : thisObj.values.txtChangeRemarks,
        "key_id"           : getData(thisObj, 'ManualDPRInvMainData')?.lstrRecordKey, //thisObj.values.lblRecordKey
        "buy_pt_id"        : "",
        "whs_num"          : "",
        "bin_num"          : "",
        "pnut_type"        : "",
        "pnut_variety"     : "",
        "seed_gen"         : "",
        "seg_type"         : "",
        "oleic_ind"        : "",
        "organic_ind"      : "",
        "audit_date"       : "",
        "audit_buy_pt"     : "",
        "insp_num"         : "",
        "settle_num"       : "",
        "whs_rcpt"         : "",
        "xfer_num"         : "",
        "trade_insp"       : "",
        "trade_settle"     : "",
        "physical_adj"     : "",
        "whouse_rcpt_adj"  : "",
        "rcpt_shrink_adj"  : "",
        "unrcpted_adj"     : "",
        "undetermined_adj" : "",
        "comp_own_adj"     : "",
        "intransit_adj"    : ""
      }
      
      if (confirm("Are you sure you wish to delete this Inventory Adjustment?")) {      
        strXML = await SystemMaintenanceApplicationSupportService.UpdateManualInvestmaint("DELETE", dataObj)
        if (strXML?.status == 200) {
          alert("Inventory Adjustment record has been deleted successfully!")
          setData(thisObj, 'ManualDPRInvSearchGridReload', true)
          await onbtnExitClick()
        }
        else if ([500, 400, 404].includes(strXML?.status)) {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n" + strXML?.message)
        }
      }
    }
    catch(err){
      errorHandler(err, "Delete")
    }
  }

  const onddBuyingPointChange = async (event) => {
    try {
      if(getData(thisObj, 'lstBuyPtID') == event?.target?.value){
        return
      }

      let buyPtID = ""
      if(event?.target?.value == undefined){
        buyPtID = getData(thisObj, 'lstBuyPtID')
      }
      else{
        buyPtID = event?.target?.value
      }
      
      setData(thisObj, 'lstBuyPtID', buyPtID)
      thisObj.values.ddBuyingPoint = buyPtID
      thisObj.values.ddAuditBPLoc = buyPtID

      if (!await bFillWhsBinList()) {
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
      
      if (!await bFillVarietyList()) {
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
    }
    catch (err) {
      errorHandler(err, "onddBuyingPointChange")
    }
  }
  thisObj.onddBuyingPointChange = onddBuyingPointChange;

  const onddPeanutTypeChange = async (event) => {
    try {
      if(getData(thisObj, 'lstPeanutTpyeId') == event?.target?.value){
        return
      }
      setData(thisObj, 'lstPeanutTpyeId', event?.target?.value)
      if (!await bFillVarietyList()) {
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
      if (!await bFillGenerationList()) {
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
      if (!await bFillSegmentList()) {
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
      if (!await bFillOleicList()) {
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
      if (!await bFillOrganicList()) {
        setData(thisObj, 'mbSuccessfulLoad', false)
      }
    }
    catch (err) {
      errorHandler(err, "onddPeanutTypeChange")
    }
  }
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange;

  const onddAuditBPLocChange = async (event) => {
    try{
      setData(thisObj, "lblAuditLoc", event?.target?.value)
    }
    catch(err){
      errorHandler(err, "onddAuditBPLocBlur")
    }
  }
  thisObj.onddAuditBPLocChange = onddAuditBPLocChange;

  const onddSeedGenerationChange = async (event) => {
    try{
      setData(thisObj, "lblGeneration", event?.target?.value)
    }
    catch(err){
      errorHandler(err, "onddSeedGenerationChange")
    }
  }
  thisObj.onddSeedGenerationChange = onddSeedGenerationChange;

  const onddOleicIndChange = async (event) => {
    try{
      setData(thisObj, "lblOleic", event?.target?.value)
    }
    catch(err){
      errorHandler(err, "onddOleicIndChange")
    }
  }
  thisObj.onddOleicIndChange = onddOleicIndChange;

  const onddOrganicIndChange = async (event) => {
    try{
      setData(thisObj, "lblOrganic", event?.target?.value)
    }
    catch(err){
      errorHandler(err, "onddOleicIndChange")
    }
  }
  thisObj.onddOrganicIndChange = onddOrganicIndChange;

  const onddPeanutVarietyChange = async (event) => {
    try{
      setData(thisObj, "lblPeanutVariety", event?.target?.value)
    }
    catch(err){
      errorHandler(err, "onddPeanutVarietyChange")
    }
  }
  thisObj.onddPeanutVarietyChange = onddPeanutVarietyChange;

  const onddSegTypeChange = async (event) => {
    try{
      setData(thisObj, "lblSeg", event?.target?.value)
    }
    catch(err){
      errorHandler(err, "onddSegTypeChange")
    }
  }
  thisObj.onddSegTypeChange = onddSegTypeChange;

  const onddSourceChange = async (event, action = 'ADD') => {
    try{
      setData(thisObj, "lblSource", event?.target?.value)
      let enableDisableFields = []
      let screenData = getData(thisObj, 'ManualDPRInvMainData')
      setLoading(true)
      /*
        Based on the source type selected, enable/disable and show/hide the controls.
        Adjustment fields are enabled or disabled based on the source type selected by the user.
        SC95, reciept, user and trade settle fields are shown or made hidden based on the source type selected by the user.
      */
      switch(event?.target?.value){
        case "1":                      
          show(thisObj, 'txt1007')
          show(thisObj, 'txtSC95')
          
          thisObj.values.txtSC95 = [ undefined, null, "" ].includes(getData(thisObj, 'labelSC95Val')) ? "" : getData(thisObj, 'labelSC95Val')
          thisObj.values.txt1007 = [ undefined, null, "" ].includes(getData(thisObj, 'label1007Val')) ? "" : getData(thisObj, 'label1007Val')
          
          if(screenData?.lstrAction == 'ADD'){
            document.getElementById('txtSC95').style.color = getData(thisObj, 'labelSC95Val')?.toString()?.length == 7 ? 'black' : 'red'
            document.getElementById('txt1007').style.color = getData(thisObj, 'label1007Val')?.toString()?.length == 7 ? 'black' : 'red'
          }          

          document.getElementsByClassName('txtSC95')[0].childNodes[0].childNodes[0].innerText = "SC95 #:";
          document.getElementsByClassName('txt1007')[0].childNodes[0].childNodes[0].innerText = "1007 #:";

          enableDisableFields = [ 'txtWhsReceiptAdj', 'txtShrinkAdj', 'txtIntransitAdj' ]
          enableWidgets(thisObj, enableDisableFields, false)
          
          enableDisableFields = [ 'txtPhysicalAdj', 'txtNotReceiptedAdj', 'txtUndeterminedAdj', 'txtCompOwnedAdj' ]
          enableWidgets(thisObj, enableDisableFields)
          
          thisObj.values.txtWhsReceiptAdj = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtShrinkAdj     = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtIntransitAdj  = action == 'ADD' ? "" : Number("").toFixed(4)

          document.getElementById('txtPhysicalAdj').style.backgroundColor     = 'white'
          document.getElementById('txtWhsReceiptAdj').style.backgroundColor   = "#F8F4F4"
          document.getElementById('txtShrinkAdj').style.backgroundColor       = "#F8F4F4"
          document.getElementById('txtNotReceiptedAdj').style.backgroundColor = 'white'
          document.getElementById('txtUndeterminedAdj').style.backgroundColor = 'white'
          document.getElementById('txtCompOwnedAdj').style.backgroundColor    = 'white'
          document.getElementById('txtIntransitAdj').style.backgroundColor    = "#F8F4F4"
          break;
        case "2":                      
          hide(thisObj, 'txt1007')
          show(thisObj, 'txtSC95')
          
          if(screenData?.lstrAction == 'ADD'){
            document.getElementById('txtSC95').style.color = getData(thisObj, 'labelWhsRcptVal')?.toString()?.length !== 6 ? 'black' : 'red'
          }
          document.getElementsByClassName('txtSC95')[0].childNodes[0].childNodes[0].innerText = "Whs Rcpt #:";
          document.getElementsByClassName('txt1007')[0].childNodes[0].childNodes[0].innerText = "";
          
          thisObj.values.txtSC95 = [ undefined, null, "" ].includes(getData(thisObj, 'labelWhsRcptVal')) ? "" : getData(thisObj, 'labelWhsRcptVal')

          enableDisableFields = [ 'txtPhysicalAdj', 'txtUndeterminedAdj', 'txtIntransitAdj' ]
          enableWidgets(thisObj, enableDisableFields, false)

          enableDisableFields = [ 'txtWhsReceiptAdj', 'txtShrinkAdj', 'txtNotReceiptedAdj', 'txtCompOwnedAdj' ]
          enableWidgets(thisObj, enableDisableFields)

          thisObj.values.txtPhysicalAdj     = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtUndeterminedAdj = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtIntransitAdj    = action == 'ADD' ? "" : Number("").toFixed(4)

          document.getElementById('txtPhysicalAdj').style.backgroundColor     = "#F8F4F4"
          document.getElementById('txtWhsReceiptAdj').style.backgroundColor   = 'white'
          document.getElementById('txtShrinkAdj').style.backgroundColor       = 'white'
          document.getElementById('txtNotReceiptedAdj').style.backgroundColor = 'white'
          document.getElementById('txtUndeterminedAdj').style.backgroundColor = "#F8F4F4"
          document.getElementById('txtCompOwnedAdj').style.backgroundColor    = 'white'
          document.getElementById('txtIntransitAdj').style.backgroundColor    = "#F8F4F4"
          break;
        case "3":                      
          hide(thisObj, 'txt1007')
          show(thisObj, 'txtSC95')
          
          thisObj.values.txtSC95 = [ undefined, null, "" ].includes(getData(thisObj, 'labelTransferVal')) ? "" : getData(thisObj, 'labelTransferVal')
          
          if(screenData?.lstrAction == 'ADD'){
            document.getElementById('txtSC95').style.color = getData(thisObj, 'labelTransferVal')?.toString()?.length == 7 ? 'black' : 'red'
          }
          document.getElementsByClassName('txtSC95')[0].childNodes[0].childNodes[0].innerText = "Transfer #:";
          document.getElementsByClassName('txt1007')[0].childNodes[0].childNodes[0].innerText = "";

          enableDisableFields = [ 'txtWhsReceiptAdj', 'txtShrinkAdj', 'txtNotReceiptedAdj', 'txtUndeterminedAdj' ]
          enableWidgets(thisObj, enableDisableFields, false)

          enableDisableFields = [ 'txtPhysicalAdj', 'txtCompOwnedAdj', 'txtIntransitAdj' ]
          enableWidgets(thisObj, enableDisableFields)

          thisObj.values.txtWhsReceiptAdj   = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtShrinkAdj       = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtNotReceiptedAdj = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtUndeterminedAdj = action == 'ADD' ? "" : Number("").toFixed(4)

          document.getElementById('txtPhysicalAdj').style.backgroundColor     = 'white'
          document.getElementById('txtWhsReceiptAdj').style.backgroundColor   = "#F8F4F4"
          document.getElementById('txtShrinkAdj').style.backgroundColor       = "#F8F4F4"
          document.getElementById('txtNotReceiptedAdj').style.backgroundColor = "#F8F4F4"
          document.getElementById('txtUndeterminedAdj').style.backgroundColor = "#F8F4F4"
          document.getElementById('txtCompOwnedAdj').style.backgroundColor    = 'white'
          document.getElementById('txtIntransitAdj').style.backgroundColor    = 'white'
          break;
        case "4":                      
          show(thisObj, 'txt1007')
          show(thisObj, 'txtSC95')
          
          thisObj.values.txtSC95 = [ undefined, null, "" ].includes(getData(thisObj, 'labelTradeInspVal')) ? "" : getData(thisObj, 'labelTradeInspVal')
          thisObj.values.txt1007 = [ undefined, null, "" ].includes(getData(thisObj, 'labelTradeSettleVal')) ? "" : getData(thisObj, 'labelTradeSettleVal')
          
          if(screenData?.lstrAction == 'ADD'){
            document.getElementById('txtSC95').style.color = getData(thisObj, 'labelTradeInspVal')?.toString()?.length == 7 ? 'black' : 'red'
            document.getElementById('txt1007').style.color = getData(thisObj, 'labelTradeSettleVal')?.toString()?.length == 7 ? 'black' : 'red'
          }
          document.getElementsByClassName('txtSC95')[0].childNodes[0].childNodes[0].innerText = "Trade Insp #:";
          document.getElementsByClassName('txt1007')[0].childNodes[0].childNodes[0].innerText = "Trade Settle #:";

          enableDisableFields = ['txtWhsReceiptAdj', 'txtShrinkAdj', 'txtNotReceiptedAdj', 'txtUndeterminedAdj', 'txtIntransitAdj']
          enableWidgets(thisObj, enableDisableFields, false)

          enableDisableFields = ['txtPhysicalAdj', 'txtCompOwnedAdj' ]
          enableWidgets(thisObj, enableDisableFields)

          thisObj.values.txtWhsReceiptAdj   = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtShrinkAdj       = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtNotReceiptedAdj = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtUndeterminedAdj = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtIntransitAdj    = action == 'ADD' ? "" : Number("").toFixed(4)

          document.getElementById('txtPhysicalAdj').style.backgroundColor     = 'white'
          document.getElementById('txtWhsReceiptAdj').style.backgroundColor   = "#F8F4F4"
          document.getElementById('txtShrinkAdj').style.backgroundColor       = "#F8F4F4"
          document.getElementById('txtNotReceiptedAdj').style.backgroundColor = "#F8F4F4"
          document.getElementById('txtUndeterminedAdj').style.backgroundColor = "#F8F4F4"
          document.getElementById('txtCompOwnedAdj').style.backgroundColor    = 'white'
          document.getElementById('txtIntransitAdj').style.backgroundColor    = "#F8F4F4"
          break;
        case "5":                 
          hide(thisObj, 'txt1007')
          show(thisObj, 'txtSC95')
          
          document.getElementsByClassName('txtSC95')[0].childNodes[0].childNodes[0].innerText = "User:";
          document.getElementsByClassName('txt1007')[0].childNodes[0].childNodes[0].innerText = "";

          enableDisableFields = ['txtWhsReceiptAdj', 'txtShrinkAdj', 'txtNotReceiptedAdj', 'txtUndeterminedAdj', 'txtIntransitAdj']
          enableWidgets(thisObj, enableDisableFields, false)

          enableDisableFields = ['txtPhysicalAdj', 'txtCompOwnedAdj']
          enableWidgets(thisObj, enableDisableFields)

          thisObj.values.txtWhsReceiptAdj   = action == 'ADD' ? "" : [undefined, null, '', '0.0000'].includes(thisObj.values.txtWhsReceiptAdj)   ? Number("").toFixed(4) : thisObj.values.txtWhsReceiptAdj
          thisObj.values.txtShrinkAdj       = action == 'ADD' ? "" : [undefined, null, '', '0.0000'].includes(thisObj.values.txtShrinkAdj)       ? Number("").toFixed(4) : thisObj.values.txtShrinkAdj
          thisObj.values.txtNotReceiptedAdj = action == 'ADD' ? "" : [undefined, null, '', '0.0000'].includes(thisObj.values.txtNotReceiptedAdj) ? Number("").toFixed(4) : thisObj.values.txtNotReceiptedAdj
          thisObj.values.txtUndeterminedAdj = action == 'ADD' ? "" : [undefined, null, '', '0.0000'].includes(thisObj.values.txtUndeterminedAdj) ? Number("").toFixed(4) : thisObj.values.txtUndeterminedAdj
          thisObj.values.txtCompOwnedAdj    = action == 'ADD' ? "" : [undefined, null, '', '0.0000'].includes(thisObj.values.txtCompOwnedAdj)    ? Number("").toFixed(4) : thisObj.values.txtCompOwnedAdj
          thisObj.values.txtIntransitAdj    = action == 'ADD' ? "" : [undefined, null, '', '0.0000'].includes(thisObj.values.txtIntransitAdj)    ? Number("").toFixed(4) : thisObj.values.txtIntransitAdj

          document.getElementById('txtPhysicalAdj').style.backgroundColor     = 'white'
          document.getElementById('txtWhsReceiptAdj').style.backgroundColor   = "#F8F4F4"
          document.getElementById('txtShrinkAdj').style.backgroundColor       = "#F8F4F4"
          document.getElementById('txtNotReceiptedAdj').style.backgroundColor = "#F8F4F4"
          document.getElementById('txtUndeterminedAdj').style.backgroundColor = "#F8F4F4"
          document.getElementById('txtCompOwnedAdj').style.backgroundColor    = 'white'
          document.getElementById('txtIntransitAdj').style.backgroundColor    = "#F8F4F4"
          break;
        default:
          hide(thisObj, 'txt1007')
          hide(thisObj, 'txtSC95')
          
          enableDisableFields = ['txtPhysicalAdj', 'txtWhsReceiptAdj', 'txtShrinkAdj', 'txtNotReceiptedAdj', 'txtUndeterminedAdj', 'txtCompOwnedAdj', 'txtIntransitAdj']
          enableWidgets(thisObj, enableDisableFields, false)

          thisObj.values.txtPhysicalAdj     = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtWhsReceiptAdj   = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtShrinkAdj       = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtNotReceiptedAdj = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtUndeterminedAdj = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtCompOwnedAdj    = action == 'ADD' ? "" : Number("").toFixed(4)
          thisObj.values.txtIntransitAdj    = action == 'ADD' ? "" : Number("").toFixed(4)

          document.getElementById('txtPhysicalAdj').style.backgroundColor     = "#F8F4F4" 
          document.getElementById('txtWhsReceiptAdj').style.backgroundColor   = "#F8F4F4" 
          document.getElementById('txtShrinkAdj').style.backgroundColor       = "#F8F4F4" 
          document.getElementById('txtNotReceiptedAdj').style.backgroundColor = "#F8F4F4"
          document.getElementById('txtUndeterminedAdj').style.backgroundColor = "#F8F4F4"
          document.getElementById('txtCompOwnedAdj').style.backgroundColor    = "#F8F4F4" 
          document.getElementById('txtIntransitAdj').style.backgroundColor    = "#F8F4F4" 
          break;
      }
    }
    catch(err){
      errorHandler(err, "onddSourceChange")
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onddSourceChange = onddSourceChange;

  const onddWarehouseBinChange = async (event) => {
    /* 
      If no or empty selection is made for the warehouse-bin dropdown, then exit the funtion.
      if some valid value is selected from the warehouse-bin dropdown, then fetch the warehouse bin inventory detials by passing 
      buying point, warehouse and bin details and then fill the peanut type, peanut variety, seed gen and type, organic values.
    */
    try{
      let strXML = []
      
      setLoading(true)

      if(getData(thisObj, 'lblWhs')?.toString() + "-" + getData(thisObj, 'lblBin')?.toString() == thisObj.values['ddWarehouseBin']){
        return
      }

      setData(thisObj, 'lblWhs', thisObj.values['ddWarehouseBin']?.toString()?.split('-')[0])
      setData(thisObj, 'lblBin', thisObj.values['ddWarehouseBin']?.toString()?.split('-')[1])

      setData(thisObj, 'mstrWBPnutTYP', '')
      setData(thisObj, 'mstrWBPnutVAR', '')
      setData(thisObj, 'mstrWBPnutGEN', '')
      setData(thisObj, 'mstrWBPnutSEG', '')
      setData(thisObj, 'mstrWBPnutOLEIC', '')
      setData(thisObj, 'mstrWBPnutORGANIC', '')

      if(thisObj.values['ddWarehouseBin'] == ""){
        return
      }

      let buyingPt  = thisObj.values['ddBuyingPoint']
      let whouseNum = thisObj.values['ddWarehouseBin']?.toString()?.split('-')[0]
      let binNum    = thisObj.values['ddWarehouseBin']?.toString()?.split('-')[1]

      strXML = await SettlementService.RetrieveWhouseBinInventoryDetails(buyingPt, whouseNum, binNum, null, null)
      if(strXML.status == 500){
        return
      }

      setData(thisObj, 'mstrWBPnutTYP', strXML[0]?.pnutTypeId)
      setData(thisObj, 'mstrWBPnutVAR', strXML[0]?.pnutVarietyId)
      setData(thisObj, 'mstrWBPnutGEN', strXML[0]?.seedGen)
      setData(thisObj, 'mstrWBPnutSEG', strXML[0]?.segType)
      setData(thisObj, 'mstrWBPnutOLEIC', strXML[0]?.oleicInd)
      setData(thisObj, 'mstrWBPnutORGANIC', strXML[0]?.organicInd)

      let cboPeanutType = getData(thisObj, 'lstPeanutType')
      cboPeanutType?.forEach(item => {
        if (item == strXML[0]?.pnutTypeId) {
          thisObj.values.ddPeanutType = strXML[0]?.pnutTypeId // mstrWBPnutTYP
          bFillVarietyList()
        }
      })

      let cboPeanutVariety = getData(thisObj, 'lstPeanutVarietyID')
      cboPeanutVariety?.forEach(item => {
        if (item?.key == strXML[0]?.pnutVarietyId) {
          thisObj.values.ddPeanutVariety = strXML[0]?.pnutVarietyId // mstrWBPnutVAR
        }
      })

      let cboGeneration = getData(thisObj, 'lstSeedGen')
      cboGeneration?.forEach(item => {
        if (item?.key == strXML[0]?.seedGen) {
          thisObj.values.ddSeedGeneration = strXML[0]?.seedGen // mstrWBPnutGEN
        }
      })

      let cboSeg = getData(thisObj, 'lstSegType')
      cboSeg?.forEach(item => {
        if (item?.key == strXML[0]?.segType) {
          thisObj.values.ddSegType = strXML[0]?.segType // mstrWBPnutSEG
        }
      })

      let cboOliec = getData(thisObj, 'lstOleicInd')
      cboOliec?.forEach(item => {
        if (item?.key == strXML[0]?.oleicInd) {
          thisObj.values.ddOleicInd = strXML[0]?.oleicInd // mstrWBPnutOLEIC
        }
      })

      let cboOrganic = getData(thisObj, 'lstOrganic')
      cboOrganic?.forEach(item => {
        if (item?.key == strXML[0]?.organicInd) {
          thisObj.values.ddOrganicInd = strXML[0]?.organicInd // mstrWBPnutORGANIC
        }
      })
    }
    catch(err){
      errorHandler(err, "onddWarehouseBinChange")
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onddWarehouseBinChange = onddWarehouseBinChange;
//PRB0053457 -FIX
  const onddAuditDateChange = async(event) => {
    try {
        let selectedDate = event?.target?.value ? moment(event.target.value).format("MM/DD/YYYY") : thisObj.values.ddAuditDate;
        if (moment(selectedDate, "MM/DD/YYYY", true).isValid()) {
            thisObj.values.ddAuditDate = selectedDate;
            setState(prevState => ({
                ...prevState,
                ddAuditDate: {
                    ...prevState.ddAuditDate,
                    value: selectedDate
                }
            }));
            document.getElementById("ddAuditDate")?.focus();
        } else {
            alert("Invalid date format. Please enter a valid date in MM/DD/YYYY format.");
        }
    } 
    catch(err){
        errorHandler(err, "onddAuditDateChange")
    }
}
thisObj.onddAuditDateChange = onddAuditDateChange;

  const ontxtSC95Change = async(event) => {
    try{
      let labelName = document.getElementsByClassName('txtSC95')[0].childNodes[0].childNodes[0].innerText
      let labelValue = event?.target?.value
      if(labelName == "Whs Rcpt #:" || labelName == "Transfer #:"){
        if(labelValue?.length > 6){
          labelValue = labelValue?.slice(0,6)
        }
        // if(labelValue?.length < 6 && labelValue !== ''){
        //   labelValue = '0'.repeat(6 - labelValue?.length)?.toString() + labelValue
        // }
      }
      thisObj.values.txtSC95 = labelValue
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtSC95Change = ontxtSC95Change;

  const ontxt1007Blur = async() => {
    try{
      let labelName = document.getElementsByClassName('txt1007')[0].childNodes[0].childNodes[0].innerText
      let labelValue = thisObj.values.txt1007
      
      if([ "Trade Settle #:", "1007 #:"].includes(labelName)){
        if(labelValue?.trim() == ""){
          return
        }
        document.getElementById('txt1007').style.color = labelValue?.toString()?.length !== 7 ? 'red' : 'black'
      }
      if(labelName == "1007 #:"){
        setData(thisObj, 'label1007Val', labelValue)
      }
      else if(labelName == "Trade Settle #:"){
        setData(thisObj, 'labelTradeSettleVal', labelValue)
      }
    }
    catch(err){
      errorHandler(err, "ontxt1007Blur")
    }
  }
  thisObj.ontxt1007Blur = ontxt1007Blur;

  const ontxtChangeRemarksBlur = async() => {
    try{
      let remarks = [undefined, null].includes(thisObj.values.txtChangeRemarks) ? ""  : thisObj.values.txtChangeRemarks
      remarks = remarks?.toString()?.replaceAll("&", "+")
      remarks = remarks?.toString()?.replaceAll("'", `"`)
      thisObj.values.txtChangeRemarks = remarks
      setValue(thisObj, 'txtChangeRemarks', remarks)
    }
    catch(err){
      errorHandler(err, "ontxtChangeRemarksBlur")
    }
  }
  thisObj.ontxtChangeRemarksBlur = ontxtChangeRemarksBlur;

  const ontxtSC95Blur = async() => {
    try{
      let labelName = document.getElementsByClassName('txtSC95')[0].childNodes[0].childNodes[0].innerText
      let labelValue = thisObj.values.txtSC95
      
      if(labelValue?.trim() == ""){
        return
      }
      if(labelName == "SC95 #:"){
        document.getElementById('txtSC95').style.color = labelValue?.toString()?.length !== 7 ? 'red' : 'black'
        setData(thisObj, 'labelSC95Val', labelValue)
      }
      else if(labelName == "Trade Insp #:"){
        document.getElementById('txtSC95').style.color = labelValue?.toString()?.length !== 7 ? 'red' : 'black'
        setData(thisObj, 'labelTradeInspVal', labelValue)
      }
      else if(labelName == "Whs Rcpt #:"){
        if(thisObj.values.SC95?.length < 6 && thisObj.values.SC95?.trim() !== ''){
          thisObj.values.txtSC95 = "0".repeat(6 - labelValue?.length) + labelValue?.toString() 
        }
        document.getElementById('txtSC95').style.color = labelValue?.toString()?.length !== 6 ? 'red' : 'black'        
        setData(thisObj, 'labelWhsRcptVal', labelValue)
      }
      else if(labelName == "Transfer #:"){
        document.getElementById('txtSC95').style.color = labelValue?.toString()?.length !== 6 ? 'red' : 'black'
        setData(thisObj, 'labelTransferVal', labelValue)
      }
      document.getElementById('txt1007').style.color = 'black'
    }
    catch(err){
      errorHandler(err, "ontxtSC95Blur")
    }
  }
  thisObj.ontxtSC95Blur = ontxtSC95Blur;

  const onbtnOKClick = async () => {
    try {
      let buttonType = document.getElementById("btnOK").innerHTML
      switch(buttonType?.toLocaleUpperCase()){
        case "ADD":
          await Add();
          break;
        case "SAVE":
          await Save()
          break;
        case "DELETE":
          await Delete();
          break
      }
    }
    catch (err) {
      errorHandler(err, "onbtnOKClick")
    }
  }
  thisObj.onbtnOKClick = onbtnOKClick;

  const onbtnExitClick = async () => {
    try {
      setData(thisObj, 'ManualDPRInvMainDataUpdate', {})
      setData(thisObj, 'ManualDPRInvSearchGridReload', true)
      document.getElementById('SystemMaintenanceApplicationSupport_ManualDPRInvMaintPopUp').childNodes[0].click();
    }
    catch (err) {
      errorHandler(err, "onbtnExitClick")
    }
  }
  thisObj.onbtnExitClick = onbtnExitClick;

  const ontxtSC95Focus = async (event) => {
    try{      
      document.getElementById('txtSC95').style.color = 'black'
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtSC95Focus = ontxtSC95Focus;

  const ontxt1007Focus = async (event) => {
    try{      
      document.getElementById('txt1007').style.color = 'black'
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxt1007Focus = ontxt1007Focus;

  const ontxtPhysicalAdjFocus = async (event) => {
    try{      
      // thisObj.values.txtPhysicalAdj = Number(event?.target?.value).toFixed(0)
      document.getElementById('txtPhysicalAdj').style.color = 'black'
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtPhysicalAdjFocus = ontxtPhysicalAdjFocus;

  const ontxtWhsReceiptAdjFocus = async (event) => {
    try{      
      document.getElementById('txtWhsReceiptAdj').style.color = 'black'
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtWhsReceiptAdjFocus = ontxtWhsReceiptAdjFocus;

  const ontxtShrinkAdjFocus = async (event) => {
    try{      
      document.getElementById('txtShrinkAdj').style.color = 'black'
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtShrinkAdjFocus = ontxtShrinkAdjFocus;

  const ontxtUndeterminedAdjFocus = async (event) => {
    try{      
      document.getElementById('txtUndeterminedAdj').style.color = 'black'
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtUndeterminedAdjFocus = ontxtUndeterminedAdjFocus;

  const ontxtCompOwnedAdjFocus = async (event) => {
    try{      
      document.getElementById('txtCompOwnedAdj').style.color = 'black'
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtCompOwnedAdjFocus = ontxtCompOwnedAdjFocus;

  const ontxtIntransitAdjFocus = async (event) => {
    try{      
      document.getElementById('txtIntransitAdj').style.color = 'black'
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtIntransitAdjFocus = ontxtIntransitAdjFocus;

  const ontxtNotReceiptedAdjFocus = async (event) => {
    try{      
      document.getElementById('txtNotReceiptedAdj').style.color = 'black'
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtNotReceiptedAdjFocus = ontxtNotReceiptedAdjFocus;

  const ontxtPhysicalAdjBlur = async (event) => {
    try{
      let value = checkNumber(event?.target?.value)
      thisObj.values.txtPhysicalAdj = value == '' ? '' : isNaN(value) ? value : getCommaSeparatedTwoDecimalsNumber(value, 4)
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtPhysicalAdjBlur = ontxtPhysicalAdjBlur;

  const ontxtWhsReceiptAdjBlur = async (event) => {
    try{
      let value = checkNumber(event?.target?.value)
      thisObj.values.txtWhsReceiptAdj = value == '' ? '' : isNaN(value) ? value : getCommaSeparatedTwoDecimalsNumber(value, 4)
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtWhsReceiptAdjBlur = ontxtWhsReceiptAdjBlur;

  const ontxtShrinkAdjBlur = async (event) => {
    try{
      let value = checkNumber(event?.target?.value)
      thisObj.values.txtShrinkAdj = value == '' ? '' : isNaN(value) ? value : getCommaSeparatedTwoDecimalsNumber(value, 4)
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtShrinkAdjBlur = ontxtShrinkAdjBlur;

  const ontxtUndeterminedAdjBlur = async (event) => {
    try{
      let value = checkNumber(event?.target?.value)
      thisObj.values.txtUndeterminedAdj = value == '' ? '' : isNaN(value) ? value : getCommaSeparatedTwoDecimalsNumber(value, 4)
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtUndeterminedAdjBlur = ontxtUndeterminedAdjBlur;

  const ontxtCompOwnedAdjBlur = async (event) => {
    try{
      let value = checkNumber(event?.target?.value)
      thisObj.values.txtCompOwnedAdj = value == '' ? '' : isNaN(value) ? value : getCommaSeparatedTwoDecimalsNumber(value, 4)
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtCompOwnedAdjBlur = ontxtCompOwnedAdjBlur;

  const ontxtIntransitAdjBlur = async (event) => {
    try{
      let value = checkNumber(event?.target?.value)
      thisObj.values.txtIntransitAdj = value == '' ? '' : isNaN(value) ? value : getCommaSeparatedTwoDecimalsNumber(value, 4)
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtIntransitAdjBlur = ontxtIntransitAdjBlur;

  const ontxtNotReceiptedAdjBlur = async (event) => {
    try{
      let value = checkNumber(event?.target?.value)
      thisObj.values.txtNotReceiptedAdj = value == '' ? '' : isNaN(value) ? value : getCommaSeparatedTwoDecimalsNumber(value, 4)
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.ontxtNotReceiptedAdjBlur = ontxtNotReceiptedAdjBlur;

  // function will check if the parameter passed is numeric of not. If numeric will return true, else false
  function isNumeric(n) {
    n = n || ''
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function ConvertDateTime(dateTime){
    try{
      const d = new Date(dateTime)
      const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
    
      return  [ 
                d.getMonth()+1, d.getDate(), d.getFullYear()
              ].join('/') 
              + ' ' +
              [ 
                (d.getHours() > 12 ? d.getHours() - 12 : d.getHours()), 
                padL(d.getMinutes()), 
                padL(d.getSeconds())
              ].join(':')
              + (d.getHours() < 12 ? ' AM' : ' PM');
    }
    catch(err){
      errorHandler(err, "ConvertDateTime")
    }
  }

  function getCommaSeparatedTwoDecimalsNumber(number, fraction) {
    return Number(number).toLocaleString('en-US', { minimumFractionDigits: fraction })
  }

  function checkNumber(value){
    if(value == null || value == undefined || value == ""){
      value = ""
    }
    else if(isNaN(Number(value.replaceAll(",", "")))){
      value = value
    }
    else{
      value = Number(value.replaceAll(",", ''))
    }
    return value
  }

  // common function to handle all exception message and logs.
  function errorHandler(err, methodName) {
    showMessage(thisObj, "Error occured in " + methodName)
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ManualDPRInvMaint*/}

              {/* END_USER_CODE-USER_BEFORE_ManualDPRInvMaint*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxManualDPRInvMaint*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxManualDPRInvMaint*/}

              <GroupBoxWidget
                conf={state.grpbxManualDPRInvMaint}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_ddWarehouseBin*/}

                {/* END_USER_CODE-USER_BEFORE_ddWarehouseBin*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddWarehouseBin}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddWarehouseBin*/}

                {/* END_USER_CODE-USER_AFTER_ddWarehouseBin*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutVariety}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeedGeneration*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeedGeneration*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeedGeneration}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeedGeneration*/}

                {/* END_USER_CODE-USER_AFTER_ddSeedGeneration*/}
                {/* START_USER_CODE-USER_BEFORE_ddSegType*/}

                {/* END_USER_CODE-USER_BEFORE_ddSegType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSegType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSegType*/}

                {/* END_USER_CODE-USER_AFTER_ddSegType*/}
                {/* START_USER_CODE-USER_BEFORE_ddOleicInd*/}

                {/* END_USER_CODE-USER_BEFORE_ddOleicInd*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleicInd}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOleicInd*/}

                {/* END_USER_CODE-USER_AFTER_ddOleicInd*/}
                {/* START_USER_CODE-USER_BEFORE_ddOrganicInd*/}

                {/* END_USER_CODE-USER_BEFORE_ddOrganicInd*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOrganicInd}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOrganicInd*/}

                {/* END_USER_CODE-USER_AFTER_ddOrganicInd*/}
                {/* START_USER_CODE-USER_BEFORE_ddAuditDate*/}

                {/* END_USER_CODE-USER_BEFORE_ddAuditDate*/}
{/* //PRB0053457 */}
                <DateWidget
                 onChange={event => 
                  onddAuditDateChange(event)
                  }
                 values={values}
                 touched={touched}
                 errors={errors}
                 conf={state.ddAuditDate}
                 screenConf={state}
                 ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_ddAuditDate*/}

                {/* END_USER_CODE-USER_AFTER_ddAuditDate*/}
                {/* START_USER_CODE-USER_BEFORE_ddSource*/}

                {/* END_USER_CODE-USER_BEFORE_ddSource*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSource}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSource*/}

                {/* END_USER_CODE-USER_AFTER_ddSource*/}
                {/* START_USER_CODE-USER_BEFORE_ddAuditBPLoc*/}

                {/* END_USER_CODE-USER_BEFORE_ddAuditBPLoc*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAuditBPLoc}
                  screenConf={state}
                ></DropDownWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(
                      event,
                      handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                  onBlur={event =>
                    invokeEventHandler(
                      event,
                      handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSC95}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(
                      event,
                      handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                  onFocus={event =>
                    invokeEventHandler(
                      event,
                      handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(
                      event,
                      handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                  onBlur={event =>
                    invokeEventHandler(
                      event,
                      handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(
                      event,
                      handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                  onFocus={event =>
                    invokeEventHandler(
                      event,
                      handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_ddAuditBPLoc*/}

                {/* END_USER_CODE-USER_AFTER_ddAuditBPLoc*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxAdjustments*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxAdjustments*/}

                <GroupBoxWidget
                  conf={state.grpbxAdjustments}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_txtPhysicalAdj*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPhysicalAdj*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPhysicalAdj}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onFocus = {event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPhysicalAdj*/}

                  {/* END_USER_CODE-USER_AFTER_txtPhysicalAdj*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWhsReceiptAdj*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWhsReceiptAdj*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWhsReceiptAdj}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWhsReceiptAdj*/}

                  {/* END_USER_CODE-USER_AFTER_txtWhsReceiptAdj*/}
                  {/* START_USER_CODE-USER_BEFORE_txtShrinkAdj*/}

                  {/* END_USER_CODE-USER_BEFORE_txtShrinkAdj*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtShrinkAdj}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtShrinkAdj*/}

                  {/* END_USER_CODE-USER_AFTER_txtShrinkAdj*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNotReceiptedAdj*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNotReceiptedAdj*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNotReceiptedAdj}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNotReceiptedAdj*/}

                  {/* END_USER_CODE-USER_AFTER_txtNotReceiptedAdj*/}
                  {/* START_USER_CODE-USER_BEFORE_txtUndeterminedAdj*/}

                  {/* END_USER_CODE-USER_BEFORE_txtUndeterminedAdj*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtUndeterminedAdj}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtUndeterminedAdj*/}

                  {/* END_USER_CODE-USER_AFTER_txtUndeterminedAdj*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCompOwnedAdj*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCompOwnedAdj*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCompOwnedAdj}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCompOwnedAdj*/}

                  {/* END_USER_CODE-USER_AFTER_txtCompOwnedAdj*/}
                  {/* START_USER_CODE-USER_BEFORE_txtIntransitAdj*/}

                  {/* END_USER_CODE-USER_BEFORE_txtIntransitAdj*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtIntransitAdj}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtIntransitAdj*/}

                  {/* END_USER_CODE-USER_AFTER_txtIntransitAdj*/}
                  {/* START_USER_CODE-USER_BEFORE_lblNote*/}

                  {/* END_USER_CODE-USER_BEFORE_lblNote*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblNote}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblNote*/}

                  {/* END_USER_CODE-USER_AFTER_lblNote*/}
                  {/* START_USER_CODE-USER_BEFORE_txtChangeRemarks*/}

                  {/* END_USER_CODE-USER_BEFORE_txtChangeRemarks*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtChangeRemarks}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtChangeRemarks*/}

                  {/* END_USER_CODE-USER_AFTER_txtChangeRemarks*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxAdjustments*/}

                {/* END_USER_CODE-USER_AFTER_grpbxAdjustments*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxManualDPRInvMaint*/}

              {/* END_USER_CODE-USER_AFTER_grpbxManualDPRInvMaint*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOK*/}

                {/* END_USER_CODE-USER_BEFORE_btnOK*/}

                <ButtonWidget
                  conf={state.btnOK}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOK*/}

                {/* END_USER_CODE-USER_AFTER_btnOK*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ManualDPRInvMaint*/}

              {/* END_USER_CODE-USER_AFTER_ManualDPRInvMaint*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_ManualDPRInvMaint
);
