/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_WarehouseBinCopy from "./WarehouseBinCopy";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("WarehouseBinCopy Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_WarehouseBinCopy />);
    });
  });
  afterEach(cleanup);
  test("is WarehouseBinCopy Loads Successfully", () => {
    expect(screen.getByText("WarehouseBinCopy")).toBeInTheDocument;
  });
  test("Custom Test Cases for WarehouseBinCopy", () => {
    // START_USER_CODE-USER_WarehouseBinCopy_Custom_Test_Case
    // END_USER_CODE-USER_WarehouseBinCopy_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_WarehouseBinCopy />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinCopy_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCopy(Button Widget) Test Cases", async () => {
    const btnCopy = screen.getByTestId("btnCopy");
    expect(btnCopy).toBeInTheDocument;
    expect(btnCopy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinCopy_btnCopy")
    );
  });
  test("Custom Test Cases for btnCopy", () => {
    // START_USER_CODE-USER_btnCopy_TEST
    // END_USER_CODE-USER_btnCopy_TEST
  });
  test("grpbxBuyingPoint(GroupBox Widget) Test Cases", async () => {
    const grpbxBuyingPoint = screen.getByTestId("grpbxBuyingPoint");
    expect(grpbxBuyingPoint.tagName).toBe("BUTTON");
    expect(grpbxBuyingPoint.type).toBe("button");
    expect(grpbxBuyingPoint.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBuyingPoint", () => {
    // START_USER_CODE-USER_grpbxBuyingPoint_TEST
    // END_USER_CODE-USER_grpbxBuyingPoint_TEST
  });
  test("grpbxCopyTo(GroupBox Widget) Test Cases", async () => {
    const grpbxCopyTo = screen.getByTestId("grpbxCopyTo");
    expect(grpbxCopyTo.tagName).toBe("BUTTON");
    expect(grpbxCopyTo.type).toBe("button");
    expect(grpbxCopyTo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCopyTo", () => {
    // START_USER_CODE-USER_grpbxCopyTo_TEST
    // END_USER_CODE-USER_grpbxCopyTo_TEST
  });
  test("grpbxWarehouseBinCopy(GroupBox Widget) Test Cases", async () => {
    const grpbxWarehouseBinCopy = screen.getByTestId("grpbxWarehouseBinCopy");
    expect(grpbxWarehouseBinCopy.tagName).toBe("BUTTON");
    expect(grpbxWarehouseBinCopy.type).toBe("button");
    expect(grpbxWarehouseBinCopy.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWarehouseBinCopy", () => {
    // START_USER_CODE-USER_grpbxWarehouseBinCopy_TEST
    // END_USER_CODE-USER_grpbxWarehouseBinCopy_TEST
  });
  test("lblCopyTo(Label Widget) Test Cases", async () => {
    const lblCopyTo = screen.getByTestId("lblCopyTo");
    expect(lblCopyTo.tagName).toBe("LABEL");
    expect(lblCopyTo.classList).toContain("form-label");
    expect(lblCopyTo.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinCopy_lblCopyTo")
    );
  });
  test("Custom Test Cases for lblCopyTo", () => {
    // START_USER_CODE-USER_lblCopyTo_TEST
    // END_USER_CODE-USER_lblCopyTo_TEST
  });
  test("txtBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtBuyingPoint = screen.getByTestId("txtBuyingPoint");
    expect(txtBuyingPoint.tagName).toBe("INPUT");
    expect(txtBuyingPoint.type).toBe("text");
    expect(txtBuyingPoint.classList).toContain("textboxWidgetClass");
    expect(txtBuyingPoint.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinCopy_txtBuyingPoint")
    );
    await act(async () => {
      userEvent.type(txtBuyingPoint, "1");
    });
  });
  test("Custom Test Cases for txtBuyingPoint", () => {
    // START_USER_CODE-USER_txtBuyingPoint_TEST
    // END_USER_CODE-USER_txtBuyingPoint_TEST
  });
});
