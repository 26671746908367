/* eslint-disable*/
export class clsBrokenRules {
  constructor(thisObj) {
    this.thisObj = thisObj;
    this.BrokenRuleList = {};
  }

  async RuleBroken(rule, isBroken, singleCheck = true, additionalValues = "") {
    let data = this.BrokenRuleList;
    if (isBroken) {
      if (!data.hasOwnProperty(rule)) {
        let value =
          additionalValues != ""
            ? String(rule) + " " + String(additionalValues)
            : rule;
        this.BrokenRuleList[value] = true;
      }
    } else {
      let value = additionalValues != "" ? rule = String(rule) + " " + String(additionalValues) : rule
      if (data.hasOwnProperty(value)) {
        delete this.BrokenRuleList[value];
      }
    }
  }

  Count = async () => {
    return Object.keys(this.BrokenRuleList).length;
  };

  Errors = async () => {
    return this.BrokenRuleList;
  };
}
