/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_ContractLimitCollPt from "./ContractLimitCollPt";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ContractLimitCollPt Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_ContractLimitCollPt />
      );
    });
  });
  afterEach(cleanup);
  test("is ContractLimitCollPt Loads Successfully", () => {
    expect(screen.getByText("ContractLimitCollPt")).toBeInTheDocument;
  });
  test("Custom Test Cases for ContractLimitCollPt", () => {
    // START_USER_CODE-USER_ContractLimitCollPt_Custom_Test_Case
    // END_USER_CODE-USER_ContractLimitCollPt_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_ContractLimitCollPt />
      );
    });
  });
  afterEach(cleanup);
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnExport(Button Widget) Test Cases", async () => {
    const btnExport = screen.getByTestId("btnExport");
    expect(btnExport).toBeInTheDocument;
    expect(btnExport.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_btnExport")
    );
  });
  test("Custom Test Cases for btnExport", () => {
    // START_USER_CODE-USER_btnExport_TEST
    // END_USER_CODE-USER_btnExport_TEST
  });
  test("btnIcon(Button Widget) Test Cases", async () => {
    const btnIcon = screen.getByTestId("btnIcon");
    expect(btnIcon).toBeInTheDocument;
    expect(btnIcon.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_btnIcon")
    );
  });
  test("Custom Test Cases for btnIcon", () => {
    // START_USER_CODE-USER_btnIcon_TEST
    // END_USER_CODE-USER_btnIcon_TEST
  });
  test("btnPrintGrid(Button Widget) Test Cases", async () => {
    const btnPrintGrid = screen.getByTestId("btnPrintGrid");
    expect(btnPrintGrid).toBeInTheDocument;
    expect(btnPrintGrid.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_btnPrintGrid")
    );
  });
  test("Custom Test Cases for btnPrintGrid", () => {
    // START_USER_CODE-USER_btnPrintGrid_TEST
    // END_USER_CODE-USER_btnPrintGrid_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_btnSave")
    );
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("gridContrctLimit(Grid Widget) Test Cases", async () => {
    let gridContrctLimit = screen.getByTestId("gridContrctLimit");
    let gridContrctLimitbtn =
      gridContrctLimit.nextElementSibling.firstElementChild;
    gridContrctLimit =
      gridContrctLimit.parentElement.parentElement.parentElement;
    expect(gridContrctLimit.tagName).toBe("DIV");
    expect(gridContrctLimit.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridContrctLimit", () => {
    // START_USER_CODE-USER_gridContrctLimit_TEST
    // END_USER_CODE-USER_gridContrctLimit_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCntrctLimit(GroupBox Widget) Test Cases", async () => {
    const grpbxCntrctLimit = screen.getByTestId("grpbxCntrctLimit");
    expect(grpbxCntrctLimit.tagName).toBe("BUTTON");
    expect(grpbxCntrctLimit.type).toBe("button");
    expect(grpbxCntrctLimit.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCntrctLimit", () => {
    // START_USER_CODE-USER_grpbxCntrctLimit_TEST
    // END_USER_CODE-USER_grpbxCntrctLimit_TEST
  });
  test("grpbxMarket(GroupBox Widget) Test Cases", async () => {
    const grpbxMarket = screen.getByTestId("grpbxMarket");
    expect(grpbxMarket.tagName).toBe("BUTTON");
    expect(grpbxMarket.type).toBe("button");
    expect(grpbxMarket.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxMarket", () => {
    // START_USER_CODE-USER_grpbxMarket_TEST
    // END_USER_CODE-USER_grpbxMarket_TEST
  });
  test("txtArea(Textbox Widget) Test Cases", async () => {
    const txtArea = screen.getByTestId("txtArea");
    expect(txtArea.tagName).toBe("INPUT");
    expect(txtArea.type).toBe("text");
    expect(txtArea.classList).toContain("textboxWidgetClass");
    expect(txtArea.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtArea")
    );
    await act(async () => {
      userEvent.type(txtArea, "1");
    });
  });
  test("Custom Test Cases for txtArea", () => {
    // START_USER_CODE-USER_txtArea_TEST
    // END_USER_CODE-USER_txtArea_TEST
  });
  test("txtAvailTons(Textbox Widget) Test Cases", async () => {
    const txtAvailTons = screen.getByTestId("txtAvailTons");
    expect(txtAvailTons.tagName).toBe("INPUT");
    expect(txtAvailTons.type).toBe("text");
    expect(txtAvailTons.classList).toContain("textboxWidgetClass");
    expect(txtAvailTons.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtAvailTons")
    );
    await act(async () => {
      userEvent.type(txtAvailTons, "1");
    });
  });
  test("Custom Test Cases for txtAvailTons", () => {
    // START_USER_CODE-USER_txtAvailTons_TEST
    // END_USER_CODE-USER_txtAvailTons_TEST
  });
  test("gridContrctLimit_txtcolAllocTons(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolAllocTons = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolAllocTonsbtn =
      gridContrctLimit_txtcolAllocTons.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolAllocTons =
      gridContrctLimit_txtcolAllocTons.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolAllocTons.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolAllocTons.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAllocTons", () => {
    // START_USER_CODE-USER_txtcolAllocTons_TEST
    // END_USER_CODE-USER_txtcolAllocTons_TEST
  });
  test("gridContrctLimit_txtcolBuyPt(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolBuyPt = screen.getByTestId("gridContrctLimit");
    let gridContrctLimit_txtcolBuyPtbtn =
      gridContrctLimit_txtcolBuyPt.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolBuyPt =
      gridContrctLimit_txtcolBuyPt.parentElement.parentElement.parentElement;
    expect(gridContrctLimit_txtcolBuyPt.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolBuyPt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyPt", () => {
    // START_USER_CODE-USER_txtcolBuyPt_TEST
    // END_USER_CODE-USER_txtcolBuyPt_TEST
  });
  test("gridContrctLimit_txtcolContrctDryLand(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolContrctDryLand = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolContrctDryLandbtn =
      gridContrctLimit_txtcolContrctDryLand.nextElementSibling
        .firstElementChild;
    gridContrctLimit_txtcolContrctDryLand =
      gridContrctLimit_txtcolContrctDryLand.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolContrctDryLand.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolContrctDryLand.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContrctDryLand", () => {
    // START_USER_CODE-USER_txtcolContrctDryLand_TEST
    // END_USER_CODE-USER_txtcolContrctDryLand_TEST
  });
  test("gridContrctLimit_txtcolContrctedTons(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolContrctedTons = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolContrctedTonsbtn =
      gridContrctLimit_txtcolContrctedTons.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolContrctedTons =
      gridContrctLimit_txtcolContrctedTons.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolContrctedTons.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolContrctedTons.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContrctedTons", () => {
    // START_USER_CODE-USER_txtcolContrctedTons_TEST
    // END_USER_CODE-USER_txtcolContrctedTons_TEST
  });
  test("gridContrctLimit_txtcolContrctIrrAcre(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolContrctIrrAcre = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolContrctIrrAcrebtn =
      gridContrctLimit_txtcolContrctIrrAcre.nextElementSibling
        .firstElementChild;
    gridContrctLimit_txtcolContrctIrrAcre =
      gridContrctLimit_txtcolContrctIrrAcre.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolContrctIrrAcre.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolContrctIrrAcre.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContrctIrrAcre", () => {
    // START_USER_CODE-USER_txtcolContrctIrrAcre_TEST
    // END_USER_CODE-USER_txtcolContrctIrrAcre_TEST
  });
  test("gridContrctLimit_txtcolDesc(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolDesc = screen.getByTestId("gridContrctLimit");
    let gridContrctLimit_txtcolDescbtn =
      gridContrctLimit_txtcolDesc.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolDesc =
      gridContrctLimit_txtcolDesc.parentElement.parentElement.parentElement;
    expect(gridContrctLimit_txtcolDesc.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolDesc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDesc", () => {
    // START_USER_CODE-USER_txtcolDesc_TEST
    // END_USER_CODE-USER_txtcolDesc_TEST
  });
  test("gridContrctLimit_txtcolLoanRepay(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolLoanRepay = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolLoanRepaybtn =
      gridContrctLimit_txtcolLoanRepay.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolLoanRepay =
      gridContrctLimit_txtcolLoanRepay.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolLoanRepay.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolLoanRepay.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoanRepay", () => {
    // START_USER_CODE-USER_txtcolLoanRepay_TEST
    // END_USER_CODE-USER_txtcolLoanRepay_TEST
  });
  test("gridContrctLimit_txtcolMarketGain(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolMarketGain = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolMarketGainbtn =
      gridContrctLimit_txtcolMarketGain.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolMarketGain =
      gridContrctLimit_txtcolMarketGain.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolMarketGain.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolMarketGain.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMarketGain", () => {
    // START_USER_CODE-USER_txtcolMarketGain_TEST
    // END_USER_CODE-USER_txtcolMarketGain_TEST
  });
  test("gridContrctLimit_txtcolMarketWeeksPrioruic25(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolMarketWeeksPrioruic25 = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolMarketWeeksPrioruic25btn =
      gridContrctLimit_txtcolMarketWeeksPrioruic25.nextElementSibling
        .firstElementChild;
    gridContrctLimit_txtcolMarketWeeksPrioruic25 =
      gridContrctLimit_txtcolMarketWeeksPrioruic25.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolMarketWeeksPrioruic25.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolMarketWeeksPrioruic25.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMarketWeeksPrioruic25", () => {
    // START_USER_CODE-USER_txtcolMarketWeeksPrioruic25_TEST
    // END_USER_CODE-USER_txtcolMarketWeeksPrioruic25_TEST
  });
  test("gridContrctLimit_txtcolMrketCeil(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolMrketCeil = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolMrketCeilbtn =
      gridContrctLimit_txtcolMrketCeil.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolMrketCeil =
      gridContrctLimit_txtcolMrketCeil.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolMrketCeil.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolMrketCeil.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMrketCeil", () => {
    // START_USER_CODE-USER_txtcolMrketCeil_TEST
    // END_USER_CODE-USER_txtcolMrketCeil_TEST
  });
  test("gridContrctLimit_txtcolMrktFloor(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolMrktFloor = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolMrktFloorbtn =
      gridContrctLimit_txtcolMrktFloor.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolMrktFloor =
      gridContrctLimit_txtcolMrktFloor.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolMrktFloor.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolMrktFloor.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMrktFloor", () => {
    // START_USER_CODE-USER_txtcolMrktFloor_TEST
    // END_USER_CODE-USER_txtcolMrktFloor_TEST
  });
  test("gridContrctLimit_txtcolMultiPricings(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolMultiPricings = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolMultiPricingsbtn =
      gridContrctLimit_txtcolMultiPricings.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolMultiPricings =
      gridContrctLimit_txtcolMultiPricings.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolMultiPricings.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolMultiPricings.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMultiPricings", () => {
    // START_USER_CODE-USER_txtcolMultiPricings_TEST
    // END_USER_CODE-USER_txtcolMultiPricings_TEST
  });
  test("gridContrctLimit_txtcolOleic(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolOleic = screen.getByTestId("gridContrctLimit");
    let gridContrctLimit_txtcolOleicbtn =
      gridContrctLimit_txtcolOleic.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolOleic =
      gridContrctLimit_txtcolOleic.parentElement.parentElement.parentElement;
    expect(gridContrctLimit_txtcolOleic.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolOleic.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOleic", () => {
    // START_USER_CODE-USER_txtcolOleic_TEST
    // END_USER_CODE-USER_txtcolOleic_TEST
  });
  test("gridContrctLimit_txtcolOpenTons(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolOpenTons = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolOpenTonsbtn =
      gridContrctLimit_txtcolOpenTons.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolOpenTons =
      gridContrctLimit_txtcolOpenTons.parentElement.parentElement.parentElement;
    expect(gridContrctLimit_txtcolOpenTons.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolOpenTons.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpenTons", () => {
    // START_USER_CODE-USER_txtcolOpenTons_TEST
    // END_USER_CODE-USER_txtcolOpenTons_TEST
  });
  test("gridContrctLimit_txtcolOptPriceCap(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolOptPriceCap = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolOptPriceCapbtn =
      gridContrctLimit_txtcolOptPriceCap.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolOptPriceCap =
      gridContrctLimit_txtcolOptPriceCap.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolOptPriceCap.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolOptPriceCap.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOptPriceCap", () => {
    // START_USER_CODE-USER_txtcolOptPriceCap_TEST
    // END_USER_CODE-USER_txtcolOptPriceCap_TEST
  });
  test("gridContrctLimit_txtcolPayRebate(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolPayRebate = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolPayRebatebtn =
      gridContrctLimit_txtcolPayRebate.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolPayRebate =
      gridContrctLimit_txtcolPayRebate.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolPayRebate.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolPayRebate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPayRebate", () => {
    // START_USER_CODE-USER_txtcolPayRebate_TEST
    // END_USER_CODE-USER_txtcolPayRebate_TEST
  });
  test("gridContrctLimit_txtcolPnutTyp(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolPnutTyp = screen.getByTestId("gridContrctLimit");
    let gridContrctLimit_txtcolPnutTypbtn =
      gridContrctLimit_txtcolPnutTyp.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolPnutTyp =
      gridContrctLimit_txtcolPnutTyp.parentElement.parentElement.parentElement;
    expect(gridContrctLimit_txtcolPnutTyp.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolPnutTyp.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutTyp", () => {
    // START_USER_CODE-USER_txtcolPnutTyp_TEST
    // END_USER_CODE-USER_txtcolPnutTyp_TEST
  });
  test("gridContrctLimit_txtcolPriceMtchEnd(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolPriceMtchEnd = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolPriceMtchEndbtn =
      gridContrctLimit_txtcolPriceMtchEnd.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolPriceMtchEnd =
      gridContrctLimit_txtcolPriceMtchEnd.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolPriceMtchEnd.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolPriceMtchEnd.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPriceMtchEnd", () => {
    // START_USER_CODE-USER_txtcolPriceMtchEnd_TEST
    // END_USER_CODE-USER_txtcolPriceMtchEnd_TEST
  });
  test("gridContrctLimit_txtcolPriceTon(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolPriceTon = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolPriceTonbtn =
      gridContrctLimit_txtcolPriceTon.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolPriceTon =
      gridContrctLimit_txtcolPriceTon.parentElement.parentElement.parentElement;
    expect(gridContrctLimit_txtcolPriceTon.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolPriceTon.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPriceTon", () => {
    // START_USER_CODE-USER_txtcolPriceTon_TEST
    // END_USER_CODE-USER_txtcolPriceTon_TEST
  });
  test("gridContrctLimit_txtcolPricingTyp(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolPricingTyp = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolPricingTypbtn =
      gridContrctLimit_txtcolPricingTyp.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolPricingTyp =
      gridContrctLimit_txtcolPricingTyp.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolPricingTyp.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolPricingTyp.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPricingTyp", () => {
    // START_USER_CODE-USER_txtcolPricingTyp_TEST
    // END_USER_CODE-USER_txtcolPricingTyp_TEST
  });
  test("gridContrctLimit_txtcolPt(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolPt = screen.getByTestId("gridContrctLimit");
    let gridContrctLimit_txtcolPtbtn =
      gridContrctLimit_txtcolPt.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolPt =
      gridContrctLimit_txtcolPt.parentElement.parentElement.parentElement;
    expect(gridContrctLimit_txtcolPt.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolPt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPt", () => {
    // START_USER_CODE-USER_txtcolPt_TEST
    // END_USER_CODE-USER_txtcolPt_TEST
  });
  test("gridContrctLimit_txtcolRebateRate(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolRebateRate = screen.getByTestId(
      "gridContrctLimit"
    );
    let gridContrctLimit_txtcolRebateRatebtn =
      gridContrctLimit_txtcolRebateRate.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolRebateRate =
      gridContrctLimit_txtcolRebateRate.parentElement.parentElement
        .parentElement;
    expect(gridContrctLimit_txtcolRebateRate.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolRebateRate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRebateRate", () => {
    // START_USER_CODE-USER_txtcolRebateRate_TEST
    // END_USER_CODE-USER_txtcolRebateRate_TEST
  });
  test("gridContrctLimit_txtcolSeg(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolSeg = screen.getByTestId("gridContrctLimit");
    let gridContrctLimit_txtcolSegbtn =
      gridContrctLimit_txtcolSeg.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolSeg =
      gridContrctLimit_txtcolSeg.parentElement.parentElement.parentElement;
    expect(gridContrctLimit_txtcolSeg.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolSeg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg", () => {
    // START_USER_CODE-USER_txtcolSeg_TEST
    // END_USER_CODE-USER_txtcolSeg_TEST
  });
  test("gridContrctLimit_txtcolVariety(Grid Widget) Test Cases", async () => {
    let gridContrctLimit_txtcolVariety = screen.getByTestId("gridContrctLimit");
    let gridContrctLimit_txtcolVarietybtn =
      gridContrctLimit_txtcolVariety.nextElementSibling.firstElementChild;
    gridContrctLimit_txtcolVariety =
      gridContrctLimit_txtcolVariety.parentElement.parentElement.parentElement;
    expect(gridContrctLimit_txtcolVariety.tagName).toBe("DIV");
    expect(gridContrctLimit_txtcolVariety.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitCollPt_gridContrctLimit"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVariety", () => {
    // START_USER_CODE-USER_txtcolVariety_TEST
    // END_USER_CODE-USER_txtcolVariety_TEST
  });
  test("txtDesc(Textbox Widget) Test Cases", async () => {
    const txtDesc = screen.getByTestId("txtDesc");
    expect(txtDesc.tagName).toBe("INPUT");
    expect(txtDesc.type).toBe("text");
    expect(txtDesc.classList).toContain("textboxWidgetClass");
    expect(txtDesc.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtDesc")
    );
    await act(async () => {
      userEvent.type(txtDesc, "1");
    });
  });
  test("Custom Test Cases for txtDesc", () => {
    // START_USER_CODE-USER_txtDesc_TEST
    // END_USER_CODE-USER_txtDesc_TEST
  });
  test("txtLoanRepay(Textbox Widget) Test Cases", async () => {
    const txtLoanRepay = screen.getByTestId("txtLoanRepay");
    expect(txtLoanRepay.tagName).toBe("INPUT");
    expect(txtLoanRepay.type).toBe("text");
    expect(txtLoanRepay.classList).toContain("textboxWidgetClass");
    expect(txtLoanRepay.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtLoanRepay")
    );
    await act(async () => {
      userEvent.type(txtLoanRepay, "1");
    });
  });
  test("Custom Test Cases for txtLoanRepay", () => {
    // START_USER_CODE-USER_txtLoanRepay_TEST
    // END_USER_CODE-USER_txtLoanRepay_TEST
  });
  test("txtMrktCeiling(Textbox Widget) Test Cases", async () => {
    const txtMrktCeiling = screen.getByTestId("txtMrktCeiling");
    expect(txtMrktCeiling.tagName).toBe("INPUT");
    expect(txtMrktCeiling.type).toBe("text");
    expect(txtMrktCeiling.classList).toContain("textboxWidgetClass");
    expect(txtMrktCeiling.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtMrktCeiling")
    );
    await act(async () => {
      userEvent.type(txtMrktCeiling, "1");
    });
  });
  test("Custom Test Cases for txtMrktCeiling", () => {
    // START_USER_CODE-USER_txtMrktCeiling_TEST
    // END_USER_CODE-USER_txtMrktCeiling_TEST
  });
  test("txtMrktFloor(Textbox Widget) Test Cases", async () => {
    const txtMrktFloor = screen.getByTestId("txtMrktFloor");
    expect(txtMrktFloor.tagName).toBe("INPUT");
    expect(txtMrktFloor.type).toBe("text");
    expect(txtMrktFloor.classList).toContain("textboxWidgetClass");
    expect(txtMrktFloor.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtMrktFloor")
    );
    await act(async () => {
      userEvent.type(txtMrktFloor, "1");
    });
  });
  test("Custom Test Cases for txtMrktFloor", () => {
    // START_USER_CODE-USER_txtMrktFloor_TEST
    // END_USER_CODE-USER_txtMrktFloor_TEST
  });
  test("txtMrktGain(Textbox Widget) Test Cases", async () => {
    const txtMrktGain = screen.getByTestId("txtMrktGain");
    expect(txtMrktGain.tagName).toBe("INPUT");
    expect(txtMrktGain.type).toBe("text");
    expect(txtMrktGain.classList).toContain("textboxWidgetClass");
    expect(txtMrktGain.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtMrktGain")
    );
    await act(async () => {
      userEvent.type(txtMrktGain, "1");
    });
  });
  test("Custom Test Cases for txtMrktGain", () => {
    // START_USER_CODE-USER_txtMrktGain_TEST
    // END_USER_CODE-USER_txtMrktGain_TEST
  });
  test("txtMrktWeeksPriorMatrty(Textbox Widget) Test Cases", async () => {
    const txtMrktWeeksPriorMatrty = screen.getByTestId(
      "txtMrktWeeksPriorMatrty"
    );
    expect(txtMrktWeeksPriorMatrty.tagName).toBe("INPUT");
    expect(txtMrktWeeksPriorMatrty.type).toBe("text");
    expect(txtMrktWeeksPriorMatrty.classList).toContain("textboxWidgetClass");
    expect(txtMrktWeeksPriorMatrty.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:ContractLimitCollPt_txtMrktWeeksPriorMatrty"
      )
    );
    await act(async () => {
      userEvent.type(txtMrktWeeksPriorMatrty, "1");
    });
  });
  test("Custom Test Cases for txtMrktWeeksPriorMatrty", () => {
    // START_USER_CODE-USER_txtMrktWeeksPriorMatrty_TEST
    // END_USER_CODE-USER_txtMrktWeeksPriorMatrty_TEST
  });
  test("txtMultiPricings(Textbox Widget) Test Cases", async () => {
    const txtMultiPricings = screen.getByTestId("txtMultiPricings");
    expect(txtMultiPricings.tagName).toBe("INPUT");
    expect(txtMultiPricings.type).toBe("text");
    expect(txtMultiPricings.classList).toContain("textboxWidgetClass");
    expect(txtMultiPricings.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:ContractLimitCollPt_txtMultiPricings"
      )
    );
    await act(async () => {
      userEvent.type(txtMultiPricings, "1");
    });
  });
  test("Custom Test Cases for txtMultiPricings", () => {
    // START_USER_CODE-USER_txtMultiPricings_TEST
    // END_USER_CODE-USER_txtMultiPricings_TEST
  });
  test("txtOleic(Textbox Widget) Test Cases", async () => {
    const txtOleic = screen.getByTestId("txtOleic");
    expect(txtOleic.tagName).toBe("INPUT");
    expect(txtOleic.type).toBe("text");
    expect(txtOleic.classList).toContain("textboxWidgetClass");
    expect(txtOleic.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtOleic")
    );
    await act(async () => {
      userEvent.type(txtOleic, "1");
    });
  });
  test("Custom Test Cases for txtOleic", () => {
    // START_USER_CODE-USER_txtOleic_TEST
    // END_USER_CODE-USER_txtOleic_TEST
  });
  test("txtOptPrcCap(Textbox Widget) Test Cases", async () => {
    const txtOptPrcCap = screen.getByTestId("txtOptPrcCap");
    expect(txtOptPrcCap.tagName).toBe("INPUT");
    expect(txtOptPrcCap.type).toBe("text");
    expect(txtOptPrcCap.classList).toContain("textboxWidgetClass");
    expect(txtOptPrcCap.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtOptPrcCap")
    );
    await act(async () => {
      userEvent.type(txtOptPrcCap, "1");
    });
  });
  test("Custom Test Cases for txtOptPrcCap", () => {
    // START_USER_CODE-USER_txtOptPrcCap_TEST
    // END_USER_CODE-USER_txtOptPrcCap_TEST
  });
  test("txtPayRebt(Textbox Widget) Test Cases", async () => {
    const txtPayRebt = screen.getByTestId("txtPayRebt");
    expect(txtPayRebt.tagName).toBe("INPUT");
    expect(txtPayRebt.type).toBe("text");
    expect(txtPayRebt.classList).toContain("textboxWidgetClass");
    expect(txtPayRebt.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtPayRebt")
    );
    await act(async () => {
      userEvent.type(txtPayRebt, "1");
    });
  });
  test("Custom Test Cases for txtPayRebt", () => {
    // START_USER_CODE-USER_txtPayRebt_TEST
    // END_USER_CODE-USER_txtPayRebt_TEST
  });
  test("txtPnutTyp(Textbox Widget) Test Cases", async () => {
    const txtPnutTyp = screen.getByTestId("txtPnutTyp");
    expect(txtPnutTyp.tagName).toBe("INPUT");
    expect(txtPnutTyp.type).toBe("text");
    expect(txtPnutTyp.classList).toContain("textboxWidgetClass");
    expect(txtPnutTyp.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtPnutTyp")
    );
    await act(async () => {
      userEvent.type(txtPnutTyp, "1");
    });
  });
  test("Custom Test Cases for txtPnutTyp", () => {
    // START_USER_CODE-USER_txtPnutTyp_TEST
    // END_USER_CODE-USER_txtPnutTyp_TEST
  });
  test("txtPrcngTyp(Textbox Widget) Test Cases", async () => {
    const txtPrcngTyp = screen.getByTestId("txtPrcngTyp");
    expect(txtPrcngTyp.tagName).toBe("INPUT");
    expect(txtPrcngTyp.type).toBe("text");
    expect(txtPrcngTyp.classList).toContain("textboxWidgetClass");
    expect(txtPrcngTyp.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtPrcngTyp")
    );
    await act(async () => {
      userEvent.type(txtPrcngTyp, "1");
    });
  });
  test("Custom Test Cases for txtPrcngTyp", () => {
    // START_USER_CODE-USER_txtPrcngTyp_TEST
    // END_USER_CODE-USER_txtPrcngTyp_TEST
  });
  test("txtPriceMtchEnd(Textbox Widget) Test Cases", async () => {
    const txtPriceMtchEnd = screen.getByTestId("txtPriceMtchEnd");
    expect(txtPriceMtchEnd.tagName).toBe("INPUT");
    expect(txtPriceMtchEnd.type).toBe("text");
    expect(txtPriceMtchEnd.classList).toContain("textboxWidgetClass");
    expect(txtPriceMtchEnd.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtPriceMtchEnd")
    );
    await act(async () => {
      userEvent.type(txtPriceMtchEnd, "1");
    });
  });
  test("Custom Test Cases for txtPriceMtchEnd", () => {
    // START_USER_CODE-USER_txtPriceMtchEnd_TEST
    // END_USER_CODE-USER_txtPriceMtchEnd_TEST
  });
  test("txtPriceTon(Textbox Widget) Test Cases", async () => {
    const txtPriceTon = screen.getByTestId("txtPriceTon");
    expect(txtPriceTon.tagName).toBe("INPUT");
    expect(txtPriceTon.type).toBe("text");
    expect(txtPriceTon.classList).toContain("textboxWidgetClass");
    expect(txtPriceTon.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtPriceTon")
    );
    await act(async () => {
      userEvent.type(txtPriceTon, "1");
    });
  });
  test("Custom Test Cases for txtPriceTon", () => {
    // START_USER_CODE-USER_txtPriceTon_TEST
    // END_USER_CODE-USER_txtPriceTon_TEST
  });
  test("txtRebtRate(Textbox Widget) Test Cases", async () => {
    const txtRebtRate = screen.getByTestId("txtRebtRate");
    expect(txtRebtRate.tagName).toBe("INPUT");
    expect(txtRebtRate.type).toBe("text");
    expect(txtRebtRate.classList).toContain("textboxWidgetClass");
    expect(txtRebtRate.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtRebtRate")
    );
    await act(async () => {
      userEvent.type(txtRebtRate, "1");
    });
  });
  test("Custom Test Cases for txtRebtRate", () => {
    // START_USER_CODE-USER_txtRebtRate_TEST
    // END_USER_CODE-USER_txtRebtRate_TEST
  });
  test("txtSeg(Textbox Widget) Test Cases", async () => {
    const txtSeg = screen.getByTestId("txtSeg");
    expect(txtSeg.tagName).toBe("INPUT");
    expect(txtSeg.type).toBe("text");
    expect(txtSeg.classList).toContain("textboxWidgetClass");
    expect(txtSeg.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtSeg")
    );
    await act(async () => {
      userEvent.type(txtSeg, "1");
    });
  });
  test("Custom Test Cases for txtSeg", () => {
    // START_USER_CODE-USER_txtSeg_TEST
    // END_USER_CODE-USER_txtSeg_TEST
  });
  test("txtVrty(Textbox Widget) Test Cases", async () => {
    const txtVrty = screen.getByTestId("txtVrty");
    expect(txtVrty.tagName).toBe("INPUT");
    expect(txtVrty.type).toBe("text");
    expect(txtVrty.classList).toContain("textboxWidgetClass");
    expect(txtVrty.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitCollPt_txtVrty")
    );
    await act(async () => {
      userEvent.type(txtVrty, "1");
    });
  });
  test("Custom Test Cases for txtVrty", () => {
    // START_USER_CODE-USER_txtVrty_TEST
    // END_USER_CODE-USER_txtVrty_TEST
  });
});
