import React from "react";
import "../../../../../node_modules/nvd3/build/nv.d3.css";
import NVD3Chart from "react-nvd3";
import GroupBoxWidget from "../GroupBoxWidget/GroupBoxWidget";
import {getTooltip} from "../ParentWidgetFunc";
function ChartWidget(props) {
  let conf = props.conf;
  let screenConf = props.screenConf;
  const getDatum = () => {
    return props.values[conf.name];
  };

 const renderChart=()=> {
    var chart = { ...conf.options.chart, ...{ datum: getDatum } };
    chart.width = chart.width ? chart.width : 1275;
    return <NVD3Chart {...chart}></NVD3Chart>;
  };
    return (
      <GroupBoxWidget conf={conf} screenConf={screenConf}>
        <div style={{ overflowX: "auto" }}
          title={getTooltip(conf)}
          role="tooltip"
        >{renderChart()}</div>
      </GroupBoxWidget>
    );
  
}
export default ChartWidget;
