/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_TradeCategorySetup from "./TradeCategorySetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("TradeCategorySetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_TradeCategorySetup />
      );
    });
  });
  afterEach(cleanup);
  test("is TradeCategorySetup Loads Successfully", () => {
    expect(screen.getByText("TradeCategorySetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for TradeCategorySetup", () => {
    // START_USER_CODE-USER_TradeCategorySetup_Custom_Test_Case
    // END_USER_CODE-USER_TradeCategorySetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_TradeCategorySetup />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:TradeCategorySetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:TradeCategorySetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:TradeCategorySetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:TradeCategorySetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("gridTradeCategoryList(Grid Widget) Test Cases", async () => {
    let gridTradeCategoryList = screen.getByTestId("gridTradeCategoryList");
    let gridTradeCategoryListbtn =
      gridTradeCategoryList.nextElementSibling.firstElementChild;
    gridTradeCategoryList =
      gridTradeCategoryList.parentElement.parentElement.parentElement;
    expect(gridTradeCategoryList.tagName).toBe("DIV");
    expect(gridTradeCategoryList.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:TradeCategorySetup_gridTradeCategoryList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridTradeCategoryList", () => {
    // START_USER_CODE-USER_gridTradeCategoryList_TEST
    // END_USER_CODE-USER_gridTradeCategoryList_TEST
  });
  test("grpbxTradeCategorySetup(GroupBox Widget) Test Cases", async () => {
    const grpbxTradeCategorySetup = screen.getByTestId(
      "grpbxTradeCategorySetup"
    );
    expect(grpbxTradeCategorySetup.tagName).toBe("BUTTON");
    expect(grpbxTradeCategorySetup.type).toBe("button");
    expect(grpbxTradeCategorySetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTradeCategorySetup", () => {
    // START_USER_CODE-USER_grpbxTradeCategorySetup_TEST
    // END_USER_CODE-USER_grpbxTradeCategorySetup_TEST
  });
  test("lblTradeCategoryList(Label Widget) Test Cases", async () => {
    const lblTradeCategoryList = screen.getByTestId("lblTradeCategoryList");
    expect(lblTradeCategoryList.tagName).toBe("LABEL");
    expect(lblTradeCategoryList.classList).toContain("form-label");
    expect(lblTradeCategoryList.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:TradeCategorySetup_lblTradeCategoryList"
      )
    );
  });
  test("Custom Test Cases for lblTradeCategoryList", () => {
    // START_USER_CODE-USER_lblTradeCategoryList_TEST
    // END_USER_CODE-USER_lblTradeCategoryList_TEST
  });
  test("gridTradeCategoryList_txtcol1007(Grid Widget) Test Cases", async () => {
    let gridTradeCategoryList_txtcol1007 = screen.getByTestId(
      "gridTradeCategoryList"
    );
    let gridTradeCategoryList_txtcol1007btn =
      gridTradeCategoryList_txtcol1007.nextElementSibling.firstElementChild;
    gridTradeCategoryList_txtcol1007 =
      gridTradeCategoryList_txtcol1007.parentElement.parentElement
        .parentElement;
    expect(gridTradeCategoryList_txtcol1007.tagName).toBe("DIV");
    expect(gridTradeCategoryList_txtcol1007.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:TradeCategorySetup_gridTradeCategoryList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol1007", () => {
    // START_USER_CODE-USER_txtcol1007_TEST
    // END_USER_CODE-USER_txtcol1007_TEST
  });
  test("gridTradeCategoryList_txtcolAcct(Grid Widget) Test Cases", async () => {
    let gridTradeCategoryList_txtcolAcct = screen.getByTestId(
      "gridTradeCategoryList"
    );
    let gridTradeCategoryList_txtcolAcctbtn =
      gridTradeCategoryList_txtcolAcct.nextElementSibling.firstElementChild;
    gridTradeCategoryList_txtcolAcct =
      gridTradeCategoryList_txtcolAcct.parentElement.parentElement
        .parentElement;
    expect(gridTradeCategoryList_txtcolAcct.tagName).toBe("DIV");
    expect(gridTradeCategoryList_txtcolAcct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:TradeCategorySetup_gridTradeCategoryList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAcct", () => {
    // START_USER_CODE-USER_txtcolAcct_TEST
    // END_USER_CODE-USER_txtcolAcct_TEST
  });
  test("gridTradeCategoryList_txtcolBalPL(Grid Widget) Test Cases", async () => {
    let gridTradeCategoryList_txtcolBalPL = screen.getByTestId(
      "gridTradeCategoryList"
    );
    let gridTradeCategoryList_txtcolBalPLbtn =
      gridTradeCategoryList_txtcolBalPL.nextElementSibling.firstElementChild;
    gridTradeCategoryList_txtcolBalPL =
      gridTradeCategoryList_txtcolBalPL.parentElement.parentElement
        .parentElement;
    expect(gridTradeCategoryList_txtcolBalPL.tagName).toBe("DIV");
    expect(gridTradeCategoryList_txtcolBalPL.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:TradeCategorySetup_gridTradeCategoryList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBalPL", () => {
    // START_USER_CODE-USER_txtcolBalPL_TEST
    // END_USER_CODE-USER_txtcolBalPL_TEST
  });
  test("gridTradeCategoryList_txtcolCategory(Grid Widget) Test Cases", async () => {
    let gridTradeCategoryList_txtcolCategory = screen.getByTestId(
      "gridTradeCategoryList"
    );
    let gridTradeCategoryList_txtcolCategorybtn =
      gridTradeCategoryList_txtcolCategory.nextElementSibling.firstElementChild;
    gridTradeCategoryList_txtcolCategory =
      gridTradeCategoryList_txtcolCategory.parentElement.parentElement
        .parentElement;
    expect(gridTradeCategoryList_txtcolCategory.tagName).toBe("DIV");
    expect(gridTradeCategoryList_txtcolCategory.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:TradeCategorySetup_gridTradeCategoryList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCategory", () => {
    // START_USER_CODE-USER_txtcolCategory_TEST
    // END_USER_CODE-USER_txtcolCategory_TEST
  });
  test("gridTradeCategoryList_txtcolPS(Grid Widget) Test Cases", async () => {
    let gridTradeCategoryList_txtcolPS = screen.getByTestId(
      "gridTradeCategoryList"
    );
    let gridTradeCategoryList_txtcolPSbtn =
      gridTradeCategoryList_txtcolPS.nextElementSibling.firstElementChild;
    gridTradeCategoryList_txtcolPS =
      gridTradeCategoryList_txtcolPS.parentElement.parentElement.parentElement;
    expect(gridTradeCategoryList_txtcolPS.tagName).toBe("DIV");
    expect(gridTradeCategoryList_txtcolPS.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:TradeCategorySetup_gridTradeCategoryList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPS", () => {
    // START_USER_CODE-USER_txtcolPS_TEST
    // END_USER_CODE-USER_txtcolPS_TEST
  });
  test("gridTradeCategoryList_txtcolSubDept(Grid Widget) Test Cases", async () => {
    let gridTradeCategoryList_txtcolSubDept = screen.getByTestId(
      "gridTradeCategoryList"
    );
    let gridTradeCategoryList_txtcolSubDeptbtn =
      gridTradeCategoryList_txtcolSubDept.nextElementSibling.firstElementChild;
    gridTradeCategoryList_txtcolSubDept =
      gridTradeCategoryList_txtcolSubDept.parentElement.parentElement
        .parentElement;
    expect(gridTradeCategoryList_txtcolSubDept.tagName).toBe("DIV");
    expect(gridTradeCategoryList_txtcolSubDept.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:TradeCategorySetup_gridTradeCategoryList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSubDept", () => {
    // START_USER_CODE-USER_txtcolSubDept_TEST
    // END_USER_CODE-USER_txtcolSubDept_TEST
  });
  test("gridTradeCategoryList_txtcolWeighs(Grid Widget) Test Cases", async () => {
    let gridTradeCategoryList_txtcolWeighs = screen.getByTestId(
      "gridTradeCategoryList"
    );
    let gridTradeCategoryList_txtcolWeighsbtn =
      gridTradeCategoryList_txtcolWeighs.nextElementSibling.firstElementChild;
    gridTradeCategoryList_txtcolWeighs =
      gridTradeCategoryList_txtcolWeighs.parentElement.parentElement
        .parentElement;
    expect(gridTradeCategoryList_txtcolWeighs.tagName).toBe("DIV");
    expect(gridTradeCategoryList_txtcolWeighs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:TradeCategorySetup_gridTradeCategoryList"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWeighs", () => {
    // START_USER_CODE-USER_txtcolWeighs_TEST
    // END_USER_CODE-USER_txtcolWeighs_TEST
  });
});
