/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  setValue,
  enable,
  disable,
  getData,
  getValue,
  setData,
  goTo,
  hideColumn,
  hide
} from "../../shared/WindowImports";

import "./FuelSurchargeInquiry.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import Loading from "../../../Loader/Loading";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FuelSurchargeInquiry(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FuelSurchargeInquiry",
    windowName: "FuelSurchargeInquiry",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FuelSurchargeInquiry",
    // START_USER_CODE-USER_FuelSurchargeInquiry_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Fuel Surcharge Inquiry",
      scrCode: "PN0250G",
    },
    // END_USER_CODE-USER_FuelSurchargeInquiry_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export to Excel",
      CharWidth: "32",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxFuelSurchargeInquiry",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    gridFarmList: {
      name: "gridFarmList",
      type: "GridWidget",
      parent: "grpbxFuelSurchargeInquiry",
      gridCellsOrder:
        "txtSCACCode,txtCarrierName,txtCompanyName,txtProfitCenter,txtLocation,txtEffectiveDateC,txtExpirationDate,txtSurchargePercent,txtPerMileRate,txtAddUser,txtAddDate,txtChangeDate,txtChangeUser",
      ColSpan: "1",
      Pagination: false,
      HasLabel: false,
      Cols: "5",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridFarmList_PROPERTIES

      // END_USER_CODE-USER_gridFarmList_PROPERTIES
    },
    txtAddDate: {
      name: "txtAddDate",
      type: "TextBoxWidget",
      colName: "txtColAddDate",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddDate_PROPERTIES

      // END_USER_CODE-USER_txtAddDate_PROPERTIES
    },
    txtChangeDate: {
      name: "txtChangeDate",
      type: "TextBoxWidget",
      colName: "txtColChangeDate",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangeDate_PROPERTIES

      // END_USER_CODE-USER_txtChangeDate_PROPERTIES
    },
    txtAddUser: {
      name: "txtAddUser",
      type: "TextBoxWidget",
      colName: "txtColAddUser",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddUser_PROPERTIES

      // END_USER_CODE-USER_txtAddUser_PROPERTIES
    },
    txtChangeUser: {
      name: "txtChangeUser",
      type: "TextBoxWidget",
      colName: "txtColChangeUser",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangeUser_PROPERTIES

      // END_USER_CODE-USER_txtChangeUser_PROPERTIES
    },
    txtCarrierAbbriviation: {
      name: "txtCarrierAbbriviation",
      type: "TextBoxWidget",
      parent: "grpbxFuelSurchargeInquiry",
      Label: "Carrier Abbreviation:",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrierAbbriviation_PROPERTIES

      // END_USER_CODE-USER_txtCarrierAbbriviation_PROPERTIES
    },
    txtCarrierName: {
      name: "txtCarrierName",
      type: "TextBoxWidget",
      colName: "txtColCarrierName",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrierName_PROPERTIES

      // END_USER_CODE-USER_txtCarrierName_PROPERTIES
    },
    txtColAddDate: {
      name: "txtColAddDate",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Add Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAddDate_PROPERTIES

      // END_USER_CODE-USER_txtColAddDate_PROPERTIES
    },
    txtColChangeDate: {
      name: "txtColChangeDate",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Change Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColChangeDate_PROPERTIES

      // END_USER_CODE-USER_txtColChangeDate_PROPERTIES
    },
    txtColAddUser: {
      name: "txtColAddUser",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Add User Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAddUser_PROPERTIES

      // END_USER_CODE-USER_txtColAddUser_PROPERTIES
    },
    txtColChangeUser: {
      name: "txtColChangeUser",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Change User",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColChangeUser_PROPERTIES

      // END_USER_CODE-USER_txtColChangeUser_PROPERTIES
    },
    txtColCarrierName: {
      name: "txtColCarrierName",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Carrier Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColCarrierName_PROPERTIES

      // END_USER_CODE-USER_txtColCarrierName_PROPERTIES
    },
    txtColCompanyName: {
      name: "txtColCompanyName",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Company Code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColCompanyName_PROPERTIES

      // END_USER_CODE-USER_txtColCompanyName_PROPERTIES
    },
    txtColEffectiveDate: {
      name: "txtColEffectiveDate",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Effective Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_txtColEffectiveDate_PROPERTIES
    },
    txtColExpirationDate: {
      name: "txtColExpirationDate",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Expiration Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColExpirationDate_PROPERTIES

      // END_USER_CODE-USER_txtColExpirationDate_PROPERTIES
    },
    txtColLocation: {
      name: "txtColLocation",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColLocation_PROPERTIES

      // END_USER_CODE-USER_txtColLocation_PROPERTIES
    },
    txtColPerMileRate: {
      name: "txtColPerMileRate",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Per Mile Rate",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPerMileRate_PROPERTIES

      // END_USER_CODE-USER_txtColPerMileRate_PROPERTIES
    },
    txtColProfitCenter: {
      name: "txtColProfitCenter",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Profit Center",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColProfitCenter_PROPERTIES

      // END_USER_CODE-USER_txtColProfitCenter_PROPERTIES
    },
    txtColSCACCode: {
      name: "txtColSCACCode",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "SCAC Code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSCACCode_PROPERTIES

      // END_USER_CODE-USER_txtColSCACCode_PROPERTIES
    },
    txtColSurchargePercent: {
      name: "txtColSurchargePercent",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Surcharge Percent",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSurchargePercent_PROPERTIES

      // END_USER_CODE-USER_txtColSurchargePercent_PROPERTIES
    },
    txtCompanyName: {
      name: "txtCompanyName",
      type: "TextBoxWidget",
      colName: "txtColCompanyName",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCompanyName_PROPERTIES

      // END_USER_CODE-USER_txtCompanyName_PROPERTIES
    },
    txtEffectiveDate: {
      name: "txtEffectiveDate",
      type: "DateWidget",
      parent: "grpbxFuelSurchargeInquiry",
      Label: "Effective Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_txtEffectiveDate_PROPERTIES
    },
    txtEffectiveDateC: {
      name: "txtEffectiveDateC",
      type: "TextBoxWidget",
      colName: "txtColEffectiveDate",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEffectiveDateC_PROPERTIES

      // END_USER_CODE-USER_txtEffectiveDateC_PROPERTIES
    },
    txtExpirationDate: {
      name: "txtExpirationDate",
      type: "TextBoxWidget",
      colName: "txtColExpirationDate",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtExpirationDate_PROPERTIES

      // END_USER_CODE-USER_txtExpirationDate_PROPERTIES
    },
    txtLocation: {
      name: "txtLocation",
      type: "TextBoxWidget",
      colName: "txtColLocation",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocation_PROPERTIES

      // END_USER_CODE-USER_txtLocation_PROPERTIES
    },
    txtPerMileRate: {
      name: "txtPerMileRate",
      type: "TextBoxWidget",
      colName: "txtColPerMileRate",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPerMileRate_PROPERTIES

      // END_USER_CODE-USER_txtPerMileRate_PROPERTIES
    },
    txtProfitCenter: {
      name: "txtProfitCenter",
      type: "TextBoxWidget",
      colName: "txtColProfitCenter",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProfitCenter_PROPERTIES

      // END_USER_CODE-USER_txtProfitCenter_PROPERTIES
    },
    txtSCACCode: {
      name: "txtSCACCode",
      type: "TextBoxWidget",
      colName: "txtColSCACCode",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSCACCode_PROPERTIES

      // END_USER_CODE-USER_txtSCACCode_PROPERTIES
    },
    txtSurchargePercent: {
      name: "txtSurchargePercent",
      type: "TextBoxWidget",
      colName: "txtColSurchargePercent",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSurchargePercent_PROPERTIES

      // END_USER_CODE-USER_txtSurchargePercent_PROPERTIES
    },
    grpbxFuelSurchargeInquiry: {
      name: "grpbxFuelSurchargeInquiry",
      type: "GroupBoxWidget",
      parent: "FuelSurchargeInquiry",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxFuelSurchargeInquiry_PROPERTIES

      // END_USER_CODE-USER_grpbxFuelSurchargeInquiry_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "FuelSurchargeInquiry",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };

  const [loading, setLoading] = useState(true);
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const formLoad = () => {


    setLoading(false);

  }

  // Search Button

  const onbtnSearchClick = () => {
    try {

      openGrid()
      hideColumn(thisObj, "gridFarmList", 'txtAddUser')
      hideColumn(thisObj, "gridFarmList", 'txtChangeUser')
      hideColumn(thisObj, "gridFarmList", 'txtChangeDate')
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
        )
      }
      // setLoading(false);
      return false
    }
    return true

  };
  thisObj.onbtnSearchClick = onbtnSearchClick;

  // grid functionality

  const openGrid = async () => {
    try {

      // setLoading(true);
      let edate = "";
      let EffectiveDate = getValue(thisObj, "txtEffectiveDate");
      if (EffectiveDate != "" && EffectiveDate != undefined) {
        edate = moment(String(EffectiveDate)).format('MM/DD/YYYY')
      }
      let fsc_scac_id=getValue(thisObj, "txtCarrierAbbriviation")
      if (fsc_scac_id == '' || fsc_scac_id == undefined) {
        //if(reload !== true){
        showMessage(thisObj,"Carrier is required!!!");
        //}
        //return false;
      } else {
      setLoading(true);
      let TransformedRowsArray = [];
      let obj = {};
      let response = await SystemMaintenanceMasterManagementService.RetrieveFuelSurchargeList(fsc_scac_id, edate)
      
      let data = response;
      for (var i = 0; i < data.length; i++) {

        obj.txtSCACCode = data[i].carrier;
        obj.txtCarrierName = "";
        let resp = await AccountPayment.RetrieveScacVendorLookupDetails(null, data[i].carrier)
        if (resp.length > 0) {
          obj.txtCarrierName = resp[0].name
        }
        else {
          obj.txtCarrierName = ""
        }
        obj.txtCompanyName = data[i].company;
        obj.txtProfitCenter = data[i].profit_center;
        obj.txtLocation = data[i].location;
        obj.txtEffectiveDateC = moment(data[i].effective_date).format("MM/DD/YYYY");
        obj.txtExpirationDate = moment(data[i].expiration_date).format("MM/DD/YYYY");
        obj.txtSurchargePercent = data[i].surcharge_percent
        obj.txtPerMileRate = data[i].mileage_rate == "0.00" ? ".00" : data[i].mileage_rate;
        obj.txtAddUser = data[i].add_user;
        obj.txtAddDate = moment(data[i].add_date_time).format("M/D/YYYY h:mm:ss A");
        obj.txtChangeUser = data[i].chg_user;
        obj.txtChangeDate = moment(data[i].chg_date_time).format("M/D/YYYY h:mm:ss A");
        TransformedRowsArray.push(obj)
        obj = {}
      }
      
      setValue(thisObj, "gridFarmList", TransformedRowsArray);
      setLoading(false);
    }
  }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click")
      }

      return false
    }
    return true
  }
  
//ExportToExcelClick button

const onbtnExportToExcelClick = () => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  let datatable = getValue(thisObj, 'gridFarmList');
  var fileName = "Fuel Surcharge Inquiry";
  let excelData = [];
  if (datatable != undefined) {
    for (let i = 0; i < datatable.length; i++) {
      let rowdata = {};
      if (thisObj.state.gridFarmList.columns[0].Visible) {
        rowdata["SCAC Code"] = datatable[i].txtSCACCode;
      }
      if (thisObj.state.gridFarmList.columns[1].Visible) {
        rowdata["Carrier Name"] = datatable[i].txtCarrierName;
      }
      if (thisObj.state.gridFarmList.columns[2].Visible) {
        rowdata["Company Code"] = datatable[i].txtCompanyName;
      }
      if (thisObj.state.gridFarmList.columns[3].Visible) {
        rowdata["Profit Center"] = datatable[i].txtProfitCenter;
      }
      if (thisObj.state.gridFarmList.columns[4].Visible) {
        rowdata["Location"] = datatable[i].txtLocation;
      }
      if (thisObj.state.gridFarmList.columns[5].Visible) {
        rowdata["Effective Date"] = datatable[i].txtEffectiveDateC;
      }
      if (thisObj.state.gridFarmList.columns[6].Visible) {
        rowdata["Expiration Date"] = datatable[i].txtExpirationDate;
      }
      if (thisObj.state.gridFarmList.columns[7].Visible) {
        rowdata["Surcharge Percent"] = Number(datatable[i].txtSurchargePercent).toFixed(1);
      }
      if (thisObj.state.gridFarmList.columns[8].Visible) {
        rowdata["Per Mile Rate"] = Number(datatable[i].txtPerMileRate).toFixed(2);
      }
      if (thisObj.state.gridFarmList.columns[9].Visible) {
        rowdata["Add User"] = datatable[i].txtAddUser;
      }
      if (thisObj.state.gridFarmList.columns[10].Visible) {
        rowdata["Add Date"] = datatable[i].txtAddDate;
      }
      if (thisObj.state.gridFarmList.columns[11].Visible) {
        rowdata["Change User"] = datatable[i].txtChangeDate;
      }
      if (thisObj.state.gridFarmList.columns[12].Visible) {
        rowdata["Change Date"] = datatable[i].txtChangeUser;
      }

      excelData.push(rowdata);
    }
  }
  const ws = XLSX.utils.json_to_sheet(excelData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  var FileSaver = require('file-saver');
  FileSaver.saveAs(data, fileName + fileExtension);
}
thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  //print button

  const onbtnPrintClick = () => {
    try {
      var data = getValue(thisObj, "gridFarmList")

      var res = []
      let headerArray = []
      
      if (thisObj.state.gridFarmList.columns[0].Visible) {
        headerArray.push("SCAC Code");
      }
      if (thisObj.state.gridFarmList.columns[1].Visible) {
        headerArray.push("Carrier Name");
      }
      if (thisObj.state.gridFarmList.columns[2].Visible) {
        headerArray.push("Company Code");
      }
      if (thisObj.state.gridFarmList.columns[3].Visible) {
        headerArray.push("Profit Center");
      }
      if (thisObj.state.gridFarmList.columns[4].Visible) {
        headerArray.push("Location");
      }
      if (thisObj.state.gridFarmList.columns[5].Visible) {
        headerArray.push("Effective Date");
      }
      if (thisObj.state.gridFarmList.columns[6].Visible) {
        headerArray.push("Expiration Date");
      }
      if (thisObj.state.gridFarmList.columns[7].Visible) {
        headerArray.push("Surcharge Percent");
      }
      if (thisObj.state.gridFarmList.columns[8].Visible) {
        headerArray.push("Per Mile Rate");
      }
      if (thisObj.state.gridFarmList.columns[9].Visible) {
        headerArray.push("Add User");
      }
      if (thisObj.state.gridFarmList.columns[10].Visible) {
        headerArray.push("Add Date");
      }
      if (thisObj.state.gridFarmList.columns[11].Visible) {
        headerArray.push("Change User");
      }
      if (thisObj.state.gridFarmList.columns[12].Visible) {
        headerArray.push("Change Date");
      }


      for (var i = 0; i < data.length; i++) {
        let NewArray = []
        if (thisObj.state.gridFarmList.columns[0].Visible) {
          NewArray.push(data[i].txtSCACCode);
        }
        if (thisObj.state.gridFarmList.columns[1].Visible) {
          NewArray.push(data[i].txtCarrierName);
        }
        if (thisObj.state.gridFarmList.columns[2].Visible) {
          NewArray.push(data[i].txtCompanyName);
        }
        if (thisObj.state.gridFarmList.columns[3].Visible) {
          NewArray.push(data[i].txtProfitCenter);
        }
        if (thisObj.state.gridFarmList.columns[4].Visible) {
          NewArray.push(data[i].txtLocation);
        }
        if (thisObj.state.gridFarmList.columns[5].Visible) {
          NewArray.push(data[i].txtEffectiveDateC);
        }
        if (thisObj.state.gridFarmList.columns[6].Visible) {
          NewArray.push(data[i].txtExpirationDate);
        }
        if (thisObj.state.gridFarmList.columns[7].Visible) {
          NewArray.push(data[i].txtSurchargePercent);
        }
        if (thisObj.state.gridFarmList.columns[8].Visible) {
          NewArray.push(data[i].txtPerMileRate);
        }
        if (thisObj.state.gridFarmList.columns[9].Visible) {
          NewArray.push(data[i].txtAddUser);
        }
        if (thisObj.state.gridFarmList.columns[10].Visible) {
          NewArray.push(data[i].txtAddDate);
        }
        if (thisObj.state.gridFarmList.columns[11].Visible) {
          NewArray.push(data[i].txtChangeDate);
        }
        if (thisObj.state.gridFarmList.columns[12].Visible) {
          NewArray.push(data[i].txtChangeUser);
        }
        res.push(NewArray)
      }
      var obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [headerArray]
      });
      var pageCount = obj.internal.getNumberOfPages();
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(8);
        obj.text('Fuel Surcharge Inquiry', 10, obj.internal.pageSize.height - 10);
        obj.text(pageCurrent?.toString(), obj?.internal?.pageSize?.width - 15, obj?.internal?.pageSize?.height - 10 )
      }
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:FuelSurchargeInquiry.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:FuelSurchargeInquiry.cmdPrintSearch_Click")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;


  //close button

  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_FuelSurchargeInquiryPopUp").childNodes[0].click()

      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;


  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FuelSurchargeInquiry*/}

              {/* END_USER_CODE-USER_BEFORE_FuelSurchargeInquiry*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFuelSurchargeInquiry*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFuelSurchargeInquiry*/}

              <GroupBoxWidget
                conf={state.grpbxFuelSurchargeInquiry}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtCarrierAbbriviation*/}

                {/* END_USER_CODE-USER_BEFORE_txtCarrierAbbriviation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCarrierAbbriviation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCarrierAbbriviation*/}

                {/* END_USER_CODE-USER_AFTER_txtCarrierAbbriviation*/}
                {/* START_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEffectiveDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_AFTER_txtEffectiveDate*/}
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                {/* START_USER_CODE-USER_BEFORE_gridFarmList*/}

                {/* END_USER_CODE-USER_BEFORE_gridFarmList*/}

                <GridWidget
                  conf={state.gridFarmList}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridFarmList}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridFarmList*/}

                {/* END_USER_CODE-USER_AFTER_gridFarmList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFuelSurchargeInquiry*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFuelSurchargeInquiry*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_FuelSurchargeInquiry*/}

              {/* END_USER_CODE-USER_AFTER_FuelSurchargeInquiry*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_FuelSurchargeInquiry
);
