/* eslint-disable*/
import React from "react";
import AccountPayment_CorrectCheck from "./CorrectCheck";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("CorrectCheck Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_CorrectCheck />);
    });
  });
  afterEach(cleanup);
  test("is CorrectCheck Loads Successfully", () => {
    expect(screen.getByText("CorrectCheck")).toBeInTheDocument;
  });
  test("Custom Test Cases for CorrectCheck", () => {
    // START_USER_CODE-USER_CorrectCheck_Custom_Test_Case
    // END_USER_CODE-USER_CorrectCheck_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_CorrectCheck />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("AccountPayment:CorrectCheck_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(t("AccountPayment:CorrectCheck_btnOk"));
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxCheckNumber(GroupBox Widget) Test Cases", async () => {
    const grpbxCheckNumber = screen.getByTestId("grpbxCheckNumber");
    expect(grpbxCheckNumber.tagName).toBe("BUTTON");
    expect(grpbxCheckNumber.type).toBe("button");
    expect(grpbxCheckNumber.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCheckNumber", () => {
    // START_USER_CODE-USER_grpbxCheckNumber_TEST
    // END_USER_CODE-USER_grpbxCheckNumber_TEST
  });
  test("lblOldCheckNumber(Label Widget) Test Cases", async () => {
    const lblOldCheckNumber = screen.getByTestId("lblOldCheckNumber");
    expect(lblOldCheckNumber.tagName).toBe("LABEL");
    expect(lblOldCheckNumber.classList).toContain("form-label");
    expect(lblOldCheckNumber.textContent).toEqual(
      t("AccountPayment:CorrectCheck_lblOldCheckNumber")
    );
  });
  test("Custom Test Cases for lblOldCheckNumber", () => {
    // START_USER_CODE-USER_lblOldCheckNumber_TEST
    // END_USER_CODE-USER_lblOldCheckNumber_TEST
  });
  test("lblOldCheckNumValue(Label Widget) Test Cases", async () => {
    const lblOldCheckNumValue = screen.getByTestId("lblOldCheckNumValue");
    expect(lblOldCheckNumValue.tagName).toBe("LABEL");
    expect(lblOldCheckNumValue.classList).toContain("form-label");
    expect(lblOldCheckNumValue.textContent).toEqual(
      t("AccountPayment:CorrectCheck_lblOldCheckNumValue")
    );
  });
  test("Custom Test Cases for lblOldCheckNumValue", () => {
    // START_USER_CODE-USER_lblOldCheckNumValue_TEST
    // END_USER_CODE-USER_lblOldCheckNumValue_TEST
  });
  test("txtNewCheckNumber(Textbox Widget) Test Cases", async () => {
    const txtNewCheckNumber = screen.getByTestId("txtNewCheckNumber");
    expect(txtNewCheckNumber.tagName).toBe("INPUT");
    expect(txtNewCheckNumber.type).toBe("text");
    expect(txtNewCheckNumber.classList).toContain("textboxWidgetClass");
    expect(txtNewCheckNumber.previousElementSibling.textContent).toEqual(
      t("AccountPayment:CorrectCheck_txtNewCheckNumber")
    );
    await act(async () => {
      userEvent.type(txtNewCheckNumber, "1");
    });
  });
  test("Custom Test Cases for txtNewCheckNumber", () => {
    // START_USER_CODE-USER_txtNewCheckNumber_TEST
    // END_USER_CODE-USER_txtNewCheckNumber_TEST
  });
});
