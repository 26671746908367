/* eslint-disable*/
import React from "react";
import AccountPayment_SpecialPayDocsView from "./SpecialPayDocsView";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SpecialPayDocsView Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_SpecialPayDocsView />);
    });
  });
  afterEach(cleanup);
  test("is SpecialPayDocsView Loads Successfully", () => {
    expect(screen.getByText("SpecialPayDocsView")).toBeInTheDocument;
  });
  test("Custom Test Cases for SpecialPayDocsView", () => {
    // START_USER_CODE-USER_SpecialPayDocsView_Custom_Test_Case
    // END_USER_CODE-USER_SpecialPayDocsView_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_SpecialPayDocsView />);
    });
  });
  afterEach(cleanup);
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("AccountPayment:SpecialPayDocsView_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnReview(Button Widget) Test Cases", async () => {
    const btnReview = screen.getByTestId("btnReview");
    expect(btnReview).toBeInTheDocument;
    expect(btnReview.textContent).toEqual(
      t("AccountPayment:SpecialPayDocsView_btnReview")
    );
  });
  test("Custom Test Cases for btnReview", () => {
    // START_USER_CODE-USER_btnReview_TEST
    // END_USER_CODE-USER_btnReview_TEST
  });
  test("btnView(Button Widget) Test Cases", async () => {
    const btnView = screen.getByTestId("btnView");
    expect(btnView).toBeInTheDocument;
    expect(btnView.textContent).toEqual(
      t("AccountPayment:SpecialPayDocsView_btnView")
    );
  });
  test("Custom Test Cases for btnView", () => {
    // START_USER_CODE-USER_btnView_TEST
    // END_USER_CODE-USER_btnView_TEST
  });
  test("grpbxSpecialPayDocs(GroupBox Widget) Test Cases", async () => {
    const grpbxSpecialPayDocs = screen.getByTestId("grpbxSpecialPayDocs");
    expect(grpbxSpecialPayDocs.tagName).toBe("BUTTON");
    expect(grpbxSpecialPayDocs.type).toBe("button");
    expect(grpbxSpecialPayDocs.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSpecialPayDocs", () => {
    // START_USER_CODE-USER_grpbxSpecialPayDocs_TEST
    // END_USER_CODE-USER_grpbxSpecialPayDocs_TEST
  });
  test("lblMemo(Label Widget) Test Cases", async () => {
    const lblMemo = screen.getByTestId("lblMemo");
    expect(lblMemo.tagName).toBe("LABEL");
    expect(lblMemo.classList).toContain("form-label");
    expect(lblMemo.textContent).toEqual(
      t("AccountPayment:SpecialPayDocsView_lblMemo")
    );
  });
  test("Custom Test Cases for lblMemo", () => {
    // START_USER_CODE-USER_lblMemo_TEST
    // END_USER_CODE-USER_lblMemo_TEST
  });
  test("lblMemoValue(Label Widget) Test Cases", async () => {
    const lblMemoValue = screen.getByTestId("lblMemoValue");
    expect(lblMemoValue.tagName).toBe("LABEL");
    expect(lblMemoValue.classList).toContain("form-label");
    expect(lblMemoValue.textContent).toEqual(
      t("AccountPayment:SpecialPayDocsView_lblMemoValue")
    );
  });
  test("Custom Test Cases for lblMemoValue", () => {
    // START_USER_CODE-USER_lblMemoValue_TEST
    // END_USER_CODE-USER_lblMemoValue_TEST
  });
  test("lblSpoolLocation(Label Widget) Test Cases", async () => {
    const lblSpoolLocation = screen.getByTestId("lblSpoolLocation");
    expect(lblSpoolLocation.tagName).toBe("LABEL");
    expect(lblSpoolLocation.classList).toContain("form-label");
    expect(lblSpoolLocation.textContent).toEqual(
      t("AccountPayment:SpecialPayDocsView_lblSpoolLocation")
    );
  });
  test("Custom Test Cases for lblSpoolLocation", () => {
    // START_USER_CODE-USER_lblSpoolLocation_TEST
    // END_USER_CODE-USER_lblSpoolLocation_TEST
  });
  test("lblSpoolLocationValue(Label Widget) Test Cases", async () => {
    const lblSpoolLocationValue = screen.getByTestId("lblSpoolLocationValue");
    expect(lblSpoolLocationValue.tagName).toBe("LABEL");
    expect(lblSpoolLocationValue.classList).toContain("form-label");
    expect(lblSpoolLocationValue.textContent).toEqual(
      t("AccountPayment:SpecialPayDocsView_lblSpoolLocationValue")
    );
  });
  test("Custom Test Cases for lblSpoolLocationValue", () => {
    // START_USER_CODE-USER_lblSpoolLocationValue_TEST
    // END_USER_CODE-USER_lblSpoolLocationValue_TEST
  });
  test("lblVendor(Label Widget) Test Cases", async () => {
    const lblVendor = screen.getByTestId("lblVendor");
    expect(lblVendor.tagName).toBe("LABEL");
    expect(lblVendor.classList).toContain("form-label");
    expect(lblVendor.textContent).toEqual(
      t("AccountPayment:SpecialPayDocsView_lblVendor")
    );
  });
  test("Custom Test Cases for lblVendor", () => {
    // START_USER_CODE-USER_lblVendor_TEST
    // END_USER_CODE-USER_lblVendor_TEST
  });
  test("lblVendorValue(Label Widget) Test Cases", async () => {
    const lblVendorValue = screen.getByTestId("lblVendorValue");
    expect(lblVendorValue.tagName).toBe("LABEL");
    expect(lblVendorValue.classList).toContain("form-label");
    expect(lblVendorValue.textContent).toEqual(
      t("AccountPayment:SpecialPayDocsView_lblVendorValue")
    );
  });
  test("Custom Test Cases for lblVendorValue", () => {
    // START_USER_CODE-USER_lblVendorValue_TEST
    // END_USER_CODE-USER_lblVendorValue_TEST
  });
  test("txtSpecialPayDocuments(Label Widget) Test Cases", async () => {
    const txtSpecialPayDocuments = screen.getByTestId("txtSpecialPayDocuments");
    expect(txtSpecialPayDocuments.tagName).toBe("LABEL");
    expect(txtSpecialPayDocuments.classList).toContain("form-label");
    expect(txtSpecialPayDocuments.textContent).toEqual(
      t("AccountPayment:SpecialPayDocsView_txtSpecialPayDocuments")
    );
  });
  test("Custom Test Cases for txtSpecialPayDocuments", () => {
    // START_USER_CODE-USER_txtSpecialPayDocuments_TEST
    // END_USER_CODE-USER_txtSpecialPayDocuments_TEST
  });
});
