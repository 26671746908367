/* eslint-disable*/
import React from "react";
import WarehouseReceipts_WhouseReceiptStorage from "./WhouseReceiptStorage";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("WhouseReceiptStorage Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WhouseReceiptStorage />);
    });
  });
  afterEach(cleanup);
  test("is WhouseReceiptStorage Loads Successfully", () => {
    expect(screen.getByText("WhouseReceiptStorage")).toBeInTheDocument;
  });
  test("Custom Test Cases for WhouseReceiptStorage", () => {
    // START_USER_CODE-USER_WhouseReceiptStorage_Custom_Test_Case
    // END_USER_CODE-USER_WhouseReceiptStorage_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WhouseReceiptStorage />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("WarehouseReceipts:WhouseReceiptStorage_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:WhouseReceiptStorage_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("WarehouseReceipts:WhouseReceiptStorage_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("gridStrgDetails(Grid Widget) Test Cases", async () => {
    let gridStrgDetails = screen.getByTestId("gridStrgDetails");
    let gridStrgDetailsbtn =
      gridStrgDetails.nextElementSibling.firstElementChild;
    gridStrgDetails = gridStrgDetails.parentElement.parentElement.parentElement;
    expect(gridStrgDetails.tagName).toBe("DIV");
    expect(gridStrgDetails.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseReceiptStorage_gridStrgDetails")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridStrgDetails", () => {
    // START_USER_CODE-USER_gridStrgDetails_TEST
    // END_USER_CODE-USER_gridStrgDetails_TEST
  });
  test("grpbxAddRemoveDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxAddRemoveDetails = screen.getByTestId("grpbxAddRemoveDetails");
    expect(grpbxAddRemoveDetails.tagName).toBe("BUTTON");
    expect(grpbxAddRemoveDetails.type).toBe("button");
    expect(grpbxAddRemoveDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAddRemoveDetails", () => {
    // START_USER_CODE-USER_grpbxAddRemoveDetails_TEST
    // END_USER_CODE-USER_grpbxAddRemoveDetails_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("grpbxStrgDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgDetails = screen.getByTestId("grpbxStrgDetails");
    expect(grpbxStrgDetails.tagName).toBe("BUTTON");
    expect(grpbxStrgDetails.type).toBe("button");
    expect(grpbxStrgDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgDetails", () => {
    // START_USER_CODE-USER_grpbxStrgDetails_TEST
    // END_USER_CODE-USER_grpbxStrgDetails_TEST
  });
  test("grpbxWhouseReceiptStorage(GroupBox Widget) Test Cases", async () => {
    const grpbxWhouseReceiptStorage = screen.getByTestId(
      "grpbxWhouseReceiptStorage"
    );
    expect(grpbxWhouseReceiptStorage.tagName).toBe("BUTTON");
    expect(grpbxWhouseReceiptStorage.type).toBe("button");
    expect(grpbxWhouseReceiptStorage.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWhouseReceiptStorage", () => {
    // START_USER_CODE-USER_grpbxWhouseReceiptStorage_TEST
    // END_USER_CODE-USER_grpbxWhouseReceiptStorage_TEST
  });
  test("lblAddRemoveDetails(Label Widget) Test Cases", async () => {
    const lblAddRemoveDetails = screen.getByTestId("lblAddRemoveDetails");
    expect(lblAddRemoveDetails.tagName).toBe("LABEL");
    expect(lblAddRemoveDetails.classList).toContain("form-label");
    expect(lblAddRemoveDetails.textContent).toEqual(
      t("WarehouseReceipts:WhouseReceiptStorage_lblAddRemoveDetails")
    );
  });
  test("Custom Test Cases for lblAddRemoveDetails", () => {
    // START_USER_CODE-USER_lblAddRemoveDetails_TEST
    // END_USER_CODE-USER_lblAddRemoveDetails_TEST
  });
  test("lblDetails(Label Widget) Test Cases", async () => {
    const lblDetails = screen.getByTestId("lblDetails");
    expect(lblDetails.tagName).toBe("LABEL");
    expect(lblDetails.classList).toContain("form-label");
    expect(lblDetails.textContent).toEqual(
      t("WarehouseReceipts:WhouseReceiptStorage_lblDetails")
    );
  });
  test("Custom Test Cases for lblDetails", () => {
    // START_USER_CODE-USER_lblDetails_TEST
    // END_USER_CODE-USER_lblDetails_TEST
  });
  test("txtBuyPtID(Textbox Widget) Test Cases", async () => {
    const txtBuyPtID = screen.getByTestId("txtBuyPtID");
    expect(txtBuyPtID.tagName).toBe("INPUT");
    expect(txtBuyPtID.type).toBe("text");
    expect(txtBuyPtID.classList).toContain("textboxWidgetClass");
    expect(txtBuyPtID.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseReceiptStorage_txtBuyPtID")
    );
    await act(async () => {
      userEvent.type(txtBuyPtID, "123");
    });
    expect(txtBuyPtID.getAttribute("value")).toBe("");
    expect(txtBuyPtID.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtBuyPtID", () => {
    // START_USER_CODE-USER_txtBuyPtID_TEST
    // END_USER_CODE-USER_txtBuyPtID_TEST
  });
  test("gridStrgDetails_txtcolDaysPaidFor(Grid Widget) Test Cases", async () => {
    let gridStrgDetails_txtcolDaysPaidFor = screen.getByTestId(
      "gridStrgDetails"
    );
    let gridStrgDetails_txtcolDaysPaidForbtn =
      gridStrgDetails_txtcolDaysPaidFor.nextElementSibling.firstElementChild;
    gridStrgDetails_txtcolDaysPaidFor =
      gridStrgDetails_txtcolDaysPaidFor.parentElement.parentElement
        .parentElement;
    expect(gridStrgDetails_txtcolDaysPaidFor.tagName).toBe("DIV");
    expect(gridStrgDetails_txtcolDaysPaidFor.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseReceiptStorage_gridStrgDetails")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDaysPaidFor", () => {
    // START_USER_CODE-USER_txtcolDaysPaidFor_TEST
    // END_USER_CODE-USER_txtcolDaysPaidFor_TEST
  });
  test("gridStrgDetails_txtcolPostingDate(Grid Widget) Test Cases", async () => {
    let gridStrgDetails_txtcolPostingDate = screen.getByTestId(
      "gridStrgDetails"
    );
    let gridStrgDetails_txtcolPostingDatebtn =
      gridStrgDetails_txtcolPostingDate.nextElementSibling.firstElementChild;
    gridStrgDetails_txtcolPostingDate =
      gridStrgDetails_txtcolPostingDate.parentElement.parentElement
        .parentElement;
    expect(gridStrgDetails_txtcolPostingDate.tagName).toBe("DIV");
    expect(gridStrgDetails_txtcolPostingDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseReceiptStorage_gridStrgDetails")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPostingDate", () => {
    // START_USER_CODE-USER_txtcolPostingDate_TEST
    // END_USER_CODE-USER_txtcolPostingDate_TEST
  });
  test("gridStrgDetails_txtcolStrgPayAmt(Grid Widget) Test Cases", async () => {
    let gridStrgDetails_txtcolStrgPayAmt = screen.getByTestId(
      "gridStrgDetails"
    );
    let gridStrgDetails_txtcolStrgPayAmtbtn =
      gridStrgDetails_txtcolStrgPayAmt.nextElementSibling.firstElementChild;
    gridStrgDetails_txtcolStrgPayAmt =
      gridStrgDetails_txtcolStrgPayAmt.parentElement.parentElement
        .parentElement;
    expect(gridStrgDetails_txtcolStrgPayAmt.tagName).toBe("DIV");
    expect(gridStrgDetails_txtcolStrgPayAmt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WhouseReceiptStorage_gridStrgDetails")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStrgPayAmt", () => {
    // START_USER_CODE-USER_txtcolStrgPayAmt_TEST
    // END_USER_CODE-USER_txtcolStrgPayAmt_TEST
  });
  test("txtCompID(Textbox Widget) Test Cases", async () => {
    const txtCompID = screen.getByTestId("txtCompID");
    expect(txtCompID.tagName).toBe("INPUT");
    expect(txtCompID.type).toBe("text");
    expect(txtCompID.classList).toContain("textboxWidgetClass");
    expect(txtCompID.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseReceiptStorage_txtCompID")
    );
    await act(async () => {
      userEvent.type(txtCompID, "123");
    });
    expect(txtCompID.getAttribute("value")).toBe("");
    expect(txtCompID.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCompID", () => {
    // START_USER_CODE-USER_txtCompID_TEST
    // END_USER_CODE-USER_txtCompID_TEST
  });
  test("txtCropYear(Textbox Widget) Test Cases", async () => {
    const txtCropYear = screen.getByTestId("txtCropYear");
    expect(txtCropYear.tagName).toBe("INPUT");
    expect(txtCropYear.type).toBe("text");
    expect(txtCropYear.classList).toContain("textboxWidgetClass");
    expect(txtCropYear.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseReceiptStorage_txtCropYear")
    );
    await act(async () => {
      userEvent.type(txtCropYear, "123");
    });
    expect(txtCropYear.getAttribute("value")).toBe("");
    expect(txtCropYear.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCropYear", () => {
    // START_USER_CODE-USER_txtCropYear_TEST
    // END_USER_CODE-USER_txtCropYear_TEST
  });
  test("txtDaysPaidFor(Textbox Widget) Test Cases", async () => {
    const txtDaysPaidFor = screen.getByTestId("txtDaysPaidFor");
    expect(txtDaysPaidFor.tagName).toBe("INPUT");
    expect(txtDaysPaidFor.type).toBe("text");
    expect(txtDaysPaidFor.classList).toContain("textboxWidgetClass");
    expect(txtDaysPaidFor.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseReceiptStorage_txtDaysPaidFor")
    );
    await act(async () => {
      userEvent.type(txtDaysPaidFor, "1");
    });
  });
  test("Custom Test Cases for txtDaysPaidFor", () => {
    // START_USER_CODE-USER_txtDaysPaidFor_TEST
    // END_USER_CODE-USER_txtDaysPaidFor_TEST
  });
  test("txtPostingDate(Date Widget) Test Cases", async () => {
    const txtPostingDate = screen.getByTestId("txtPostingDate");
    expect(txtPostingDate.tagName).toBe("INPUT");
    expect(txtPostingDate.type).toBe("text");
    expect(txtPostingDate.classList).toContain("datetimepicker-input");
    expect(
      txtPostingDate.parentElement.previousElementSibling.textContent
    ).toEqual(t("WarehouseReceipts:WhouseReceiptStorage_txtPostingDate"));
    await act(async () => {
      userEvent.click(txtPostingDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtPostingDate", () => {
    // START_USER_CODE-USER_txtPostingDate_TEST
    // END_USER_CODE-USER_txtPostingDate_TEST
  });
  test("txtStoragePayment(Textbox Widget) Test Cases", async () => {
    const txtStoragePayment = screen.getByTestId("txtStoragePayment");
    expect(txtStoragePayment.tagName).toBe("INPUT");
    expect(txtStoragePayment.type).toBe("text");
    expect(txtStoragePayment.classList).toContain("textboxWidgetClass");
    expect(txtStoragePayment.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseReceiptStorage_txtStoragePayment")
    );
    await act(async () => {
      userEvent.type(txtStoragePayment, "1");
    });
  });
  test("Custom Test Cases for txtStoragePayment", () => {
    // START_USER_CODE-USER_txtStoragePayment_TEST
    // END_USER_CODE-USER_txtStoragePayment_TEST
  });
  test("txtTotalAmt(Textbox Widget) Test Cases", async () => {
    const txtTotalAmt = screen.getByTestId("txtTotalAmt");
    expect(txtTotalAmt.tagName).toBe("INPUT");
    expect(txtTotalAmt.type).toBe("text");
    expect(txtTotalAmt.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalAmt, "1");
    });
  });
  test("Custom Test Cases for txtTotalAmt", () => {
    // START_USER_CODE-USER_txtTotalAmt_TEST
    // END_USER_CODE-USER_txtTotalAmt_TEST
  });
  test("txtTotalDays(Textbox Widget) Test Cases", async () => {
    const txtTotalDays = screen.getByTestId("txtTotalDays");
    expect(txtTotalDays.tagName).toBe("INPUT");
    expect(txtTotalDays.type).toBe("text");
    expect(txtTotalDays.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalDays, "123");
    });
    expect(txtTotalDays.getAttribute("value")).toBe("");
    expect(txtTotalDays.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalDays", () => {
    // START_USER_CODE-USER_txtTotalDays_TEST
    // END_USER_CODE-USER_txtTotalDays_TEST
  });
  test("txtWhouseRcptNum(Textbox Widget) Test Cases", async () => {
    const txtWhouseRcptNum = screen.getByTestId("txtWhouseRcptNum");
    expect(txtWhouseRcptNum.tagName).toBe("INPUT");
    expect(txtWhouseRcptNum.type).toBe("text");
    expect(txtWhouseRcptNum.classList).toContain("textboxWidgetClass");
    expect(txtWhouseRcptNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:WhouseReceiptStorage_txtWhouseRcptNum")
    );
    await act(async () => {
      userEvent.type(txtWhouseRcptNum, "123");
    });
    expect(txtWhouseRcptNum.getAttribute("value")).toBe("");
    expect(txtWhouseRcptNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWhouseRcptNum", () => {
    // START_USER_CODE-USER_txtWhouseRcptNum_TEST
    // END_USER_CODE-USER_txtWhouseRcptNum_TEST
  });
});
