/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
} from "../../shared/WindowImports";

import "./SpecialFunctions.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { dictSpecialFunctionsRoles } from "../../Common/Constants";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_SpecialFunctions(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SpecialFunctions",
    windowName: "SpecialFunctions",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.SpecialFunctions",
    // START_USER_CODE-USER_SpecialFunctions_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Special Functions",
      scrCode: "PN0200A",
    },
    // END_USER_CODE-USER_SpecialFunctions_PROPERTIES
    btnAcctDistMntnce: {
      name: "btnAcctDistMntnce",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Account Dist Maintenance",
      CharWidth: "48",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAcctDistMntnce_PROPERTIES

      // END_USER_CODE-USER_btnAcctDistMntnce_PROPERTIES
    },
    btnBankingHolidays: {
      name: "btnBankingHolidays",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Banking Holidays",
      CharWidth: "33",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnBankingHolidays_PROPERTIES

      // END_USER_CODE-USER_btnBankingHolidays_PROPERTIES
    },
    btnChangeNumbers: {
      name: "btnChangeNumbers",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Change Numbers",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnChangeNumbers_PROPERTIES

      // END_USER_CODE-USER_btnChangeNumbers_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCMADMAControl: {
      name: "btnCMADMAControl",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "CMA DMA Control",
      CharWidth: "34",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCMADMAControl_PROPERTIES

      // END_USER_CODE-USER_btnCMADMAControl_PROPERTIES
    },
    btnCompanies: {
      name: "btnCompanies",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Companies",
      CharWidth: "20",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCompanies_PROPERTIES

      // END_USER_CODE-USER_btnCompanies_PROPERTIES
    },
    btnDashboard: {
      name: "btnDashboard",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Dashboard",
      CharWidth: "20",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDashboard_PROPERTIES

      // END_USER_CODE-USER_btnDashboard_PROPERTIES
    },
    btnErrorCodeFilterMntnce: {
      name: "btnErrorCodeFilterMntnce",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Error Code Filter Maintenance",
      CharWidth: "58",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnErrorCodeFilterMntnce_PROPERTIES

      // END_USER_CODE-USER_btnErrorCodeFilterMntnce_PROPERTIES
    },
    btnHighMoistureSetup: {
      name: "btnHighMoistureSetup",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "High Moisture Setup",
      CharWidth: "39",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnHighMoistureSetup_PROPERTIES

      // END_USER_CODE-USER_btnHighMoistureSetup_PROPERTIES
    },
    btnInventoryMntnce: {
      name: "btnInventoryMntnce",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Inventory Maintenance",
      CharWidth: "42",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnInventoryMntnce_PROPERTIES

      // END_USER_CODE-USER_btnInventoryMntnce_PROPERTIES
    },
    btnLoanInterest: {
      name: "btnLoanInterest",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Loan Interest",
      CharWidth: "28",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnLoanInterest_PROPERTIES

      // END_USER_CODE-USER_btnLoanInterest_PROPERTIES
    },
    btnLoanReduction: {
      name: "btnLoanReduction",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Loan Reduction",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnLoanReduction_PROPERTIES

      // END_USER_CODE-USER_btnLoanReduction_PROPERTIES
    },
    btnLoanRepayment: {
      name: "btnLoanRepayment",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Loan Repayment",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnLoanRepayment_PROPERTIES

      // END_USER_CODE-USER_btnLoanRepayment_PROPERTIES
    },
    btnMarketPriceSetup: {
      name: "btnMarketPriceSetup",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Market Price Setup",
      CharWidth: "37",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnMarketPriceSetup_PROPERTIES

      // END_USER_CODE-USER_btnMarketPriceSetup_PROPERTIES
    },
    btnMiscFunctions: {
      name: "btnMiscFunctions",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Miscellaneous Functions",
      CharWidth: "46",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnMiscFunctions_PROPERTIES

      // END_USER_CODE-USER_btnMiscFunctions_PROPERTIES
    },
    btnPricingSchedule: {
      name: "btnPricingSchedule",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Pricing Schedule",
      CharWidth: "33",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPricingSchedule_PROPERTIES

      // END_USER_CODE-USER_btnPricingSchedule_PROPERTIES
    },
    btnReadyToPayMntnce: {
      name: "btnReadyToPayMntnce",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Ready to Pay Maintenance",
      CharWidth: "48",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReadyToPayMntnce_PROPERTIES

      // END_USER_CODE-USER_btnReadyToPayMntnce_PROPERTIES
    },
    btnReprintDocuments: {
      name: "btnReprintDocuments",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Reprint Documents",
      CharWidth: "35",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReprintDocuments_PROPERTIES

      // END_USER_CODE-USER_btnReprintDocuments_PROPERTIES
    },
    btnSecurity: {
      name: "btnSecurity",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Security",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSecurity_PROPERTIES

      // END_USER_CODE-USER_btnSecurity_PROPERTIES
    },
    btnTradeCategory: {
      name: "btnTradeCategory",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Trade Category",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnTradeCategory_PROPERTIES

      // END_USER_CODE-USER_btnTradeCategory_PROPERTIES
    },
    btnWireTransferSetup: {
      name: "btnWireTransferSetup",
      type: "ButtonWidget",
      parent: "grpbxSpecialFunctions",
      Label: "Wire Transfer Setup",
      CharWidth: "39",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnWireTransferSetup_PROPERTIES

      // END_USER_CODE-USER_btnWireTransferSetup_PROPERTIES
    },
    grpbxSpecialFunctions: {
      name: "grpbxSpecialFunctions",
      type: "GroupBoxWidget",
      parent: "SpecialFunctions",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSpecialFunctions_PROPERTIES

      // END_USER_CODE-USER_grpbxSpecialFunctions_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "SpecialFunctions",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
       "SystemMaintenanceSpecialFunctions#CompanySetup":{},
       "SystemMaintenanceApplicationSupport#SpecFuncChgNum":{},
       "SystemMaintenanceApplicationSupport#SpecFuncReprint":{},
       "SystemMaintenanceApplicationSupport#SpecFuncMisc":{},
       "SystemMaintenanceApplicationSupport#ManualAcctDistSearch":{},
       "SystemMaintenanceSpecialFunctions#HighMoistureSetup":{},
       "SystemMaintenanceApplicationSupport#ManualRTPSearch":{},
       "SystemMaintenanceSpecialFunctions#LoanRepaySetup":{},
       "SystemMaintenanceSpecialFunctions#PriceScheduleSetup":{},
       "SystemMaintenanceSpecialFunctions#SecurityMenu":{},
       "SystemMaintenanceSpecialFunctions#TradeCategorySetup":{},
       "SystemMaintenanceSpecialFunctions#VendorWireTransferSetup":{},
       "SystemMaintenanceSpecialFunctions#MarketPriceSetup":{},  
       "SystemMaintenanceApplicationSupport#ManualDPRInvSearch":{},
       "SystemMaintenanceSpecialFunctions#BankHolidaySetup":{}, 
       "SystemMaintenanceSpecialFunctions#LoanInterestSetup":{}, 
       "SystemMaintenanceSpecialFunctions#LoanReductionSetup":{},
       "SystemMaintenanceSpecialFunctions#CmaDmaControlSetup":{}, 
       },
      REVERSE:{
       "SystemMaintenanceSpecialFunctions#CompanySetup":{},
       "SystemMaintenanceApplicationSupport#SpecFuncChgNum":{},
       "SystemMaintenanceApplicationSupport#SpecFuncReprint":{},
       "SystemMaintenanceApplicationSupport#SpecFuncMisc":{},
       "SystemMaintenanceApplicationSupport#ManualAcctDistSearch":{},
       "SystemMaintenanceSpecialFunctions#HighMoistureSetup":{},
       "SystemMaintenanceApplicationSupport#ManualRTPSearch":{},
       "SystemMaintenanceSpecialFunctions#LoanRepaySetup":{},
       "SystemMaintenanceSpecialFunctions#PriceScheduleSetup":{},
       "SystemMaintenanceSpecialFunctions#SecurityMenu":{},
       "SystemMaintenanceSpecialFunctions#TradeCategorySetup":{},
       "SystemMaintenanceSpecialFunctions#VendorWireTransferSetup":{},
       "SystemMaintenanceSpecialFunctions#MarketPriceSetup":{},
       "SystemMaintenanceApplicationSupport#ManualDPRInvSearch":{},
       "SystemMaintenanceSpecialFunctions#BankHolidaySetup":{}, 
       "SystemMaintenanceSpecialFunctions#LoanInterestSetup":{}, 
       "SystemMaintenanceSpecialFunctions#LoanReductionSetup":{},
       "SystemMaintenanceSpecialFunctions#CmaDmaControlSetup":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnCompanies:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#CompanySetup#DEFAULT#true#Click",]
    },
    btnHighMoistureSetup:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#HighMoistureSetup#DEFAULT#true#Click",]
    },
    btnChangeNumbers:{
      DEFAULT:[
      "SystemMaintenanceApplicationSupport#SpecFuncChgNum#DEFAULT#true#Click",]
    },
    btnReprintDocuments:{
      DEFAULT:[
      "SystemMaintenanceApplicationSupport#SpecFuncReprint#DEFAULT#true#Click",]
    },
    btnMiscFunctions:{
      DEFAULT:[
      "SystemMaintenanceApplicationSupport#SpecFuncMisc#DEFAULT#true#Click",]
    },
    btnAcctDistMntnce:{
      DEFAULT:[
      "SystemMaintenanceApplicationSupport#ManualAcctDistSearch#DEFAULT#true#Click",]
    },
    btnReadyToPayMntnce:{
      DEFAULT:[
      "SystemMaintenanceApplicationSupport#ManualRTPSearch#DEFAULT#true#Click",]
    },
    btnLoanRepayment:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#LoanRepaySetup#DEFAULT#true#Click",]
    },
    btnPricingSchedule:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#PriceScheduleSetup#DEFAULT#true#Click",]
    },
    btnSecurity:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#SecurityMenu#DEFAULT#true#Click",]
    },
    btnTradeCategory:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#TradeCategorySetup#DEFAULT#true#Click",]
    },
    btnWireTransferSetup:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#VendorWireTransferSetup#DEFAULT#true#Click",]
    },
    btnMarketPriceSetup:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#MarketPriceSetup#DEFAULT#true#Click",]
    },
    btnInventoryMntnce:{
      DEFAULT:[
      "SystemMaintenanceApplicationSupport#ManualDPRInvSearch#DEFAULT#true#Click",]
    },
    btnBankingHolidays:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#BankHolidaySetup#DEFAULT#true#Click",]
    },
    btnLoanInterest:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#LoanInterestSetup#DEFAULT#true#Click",]
    },
    btnLoanReduction:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#LoanReductionSetup#DEFAULT#true#Click",]
    },
    btnCMADMAControl:{
      DEFAULT:[
      "SystemMaintenanceSpecialFunctions#CmaDmaControlSetup#DEFAULT#true#Click",]
    },
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },  
    btnDashboard:{
      DEFAULT:[
        "SystemMaintenanceSpecialFunctions#Dashboard#DEFAULT#false#Click",]
    }
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  // btnDashboard Click URL Navigation
  // const onbtnDashboardClick= async() => {
  //   let userid = sessionStorage.getItem("userid");
  //   let compId = sessionStorage.getItem('compId');
  //   let cropyear = JSON.parse(sessionStorage.getItem('year'));
  //   let response = await ContractManagementService.RetrievePeanutStaticValues()
  //   //https://testxml.pps.e-adm.com/rpt/ppsdashboard.asp?env=T&uid=X838170&cid=B9&cyr=2023
  //   let optionalParamsUrl='';
  //   if(userid!=undefined && userid!='' && userid!=null){
  //     optionalParamsUrl+=`uid=${userid}&`
  //   }
  //   if(compId!=undefined && compId!='' && compId!=null){
  //     optionalParamsUrl+=`cid=${compId}&`
  //   }
  //   if(cropyear!=undefined && cropyear!='' && cropyear!=null){
  //     optionalParamsUrl+=`cyr=${cropyear}`
  //   }
  //   var reportURL = ''
  //   if(process.env.REACT_APP_ENVIR == 'TEST'|| process.env.REACT_APP_ENVIR == 'LOCAL'){
  //     reportURL =`${response[0].pps_defaults.pps_url.test}/rpt/ppsdashboard.asp?env=T&${optionalParamsUrl}`
  //   }
  //   else if(process.env.REACT_APP_ENVIR == 'DEV'){
  //     reportURL =`${response[0].pps_defaults.pps_url.test}/rpt/ppsdashboard.asp?env=D&${optionalParamsUrl}`
  //   }
  //   else if(process.env.REACT_APP_ENVIR == 'PROD'){
  //     reportURL =`${response[0].pps_defaults.pps_url.test}/rpt/ppsdashboard.asp?env=P&${optionalParamsUrl}`
  //   }
  //   if(reportURL == ''){
  //     showMessage(thisObj, 'cannot find the right url.')
  //   }
  //   else{
  //     window.open(reportURL)
  //   }
  // }
  // thisObj.onbtnDashboardClick = onbtnDashboardClick;

  function FormLoad() {
    disableMenuButtons() // disable all buttons before enable based on roles
    EnableDisableMenuButtons();
  }

  function disableMenuButtons() {
    for (let j = 0; j< dictSpecialFunctionsRoles.length; j++) {
      const controlname = dictSpecialFunctionsRoles[j].value;
      setState((previouState)=>({ 
        ...previouState,
        [controlname] :{
        ...state[controlname],
        Visible: false
        }
        }));
    }
  };

  function enableMenuButtons(funcid) {
    for (let j = 0; j< dictSpecialFunctionsRoles.length; j++) {
      if (funcid == dictSpecialFunctionsRoles[j].key)
      {
        const controlname = dictSpecialFunctionsRoles[j].value;
        setState((previouState)=>({ 
          ...previouState,
          [controlname] :{
          ...state[controlname],
          Visible: true
          }
          }));
      }
    }
    let posChg = state.positionChange;
    setTimeout(()=>{
      setState((previouState)=>({ 
      ...previouState,
      positionChange: !posChg
      }));
    },0)
  };

  function EnableDisableMenuButtons() {
    let userroles = sessionStorage.getItem("roles");
    const roles_array = userroles.split(',');
    for (let i = 0; i < roles_array.length; i++) {
      const role = roles_array[i];
      if (role != "") {
        let funcid = role.substring(0,6);
        if (funcid != 'PN0202'){
          enableMenuButtons(funcid);
        }
      }     
    }
  }
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SpecialFunctions*/}

              {/* END_USER_CODE-USER_BEFORE_SpecialFunctions*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSpecialFunctions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSpecialFunctions*/}

              <GroupBoxWidget
                conf={state.grpbxSpecialFunctions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnChangeNumbers*/}

                {/* END_USER_CODE-USER_BEFORE_btnChangeNumbers*/}

                <ButtonWidget
                  conf={state.btnChangeNumbers}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnChangeNumbers*/}

                {/* END_USER_CODE-USER_AFTER_btnChangeNumbers*/}
                {/* START_USER_CODE-USER_BEFORE_btnReprintDocuments*/}

                {/* END_USER_CODE-USER_BEFORE_btnReprintDocuments*/}

                <ButtonWidget
                  conf={state.btnReprintDocuments}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReprintDocuments*/}

                {/* END_USER_CODE-USER_AFTER_btnReprintDocuments*/}
                {/* START_USER_CODE-USER_BEFORE_btnMiscFunctions*/}

                {/* END_USER_CODE-USER_BEFORE_btnMiscFunctions*/}

                <ButtonWidget
                  conf={state.btnMiscFunctions}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnMiscFunctions*/}

                {/* END_USER_CODE-USER_AFTER_btnMiscFunctions*/}
                {/* START_USER_CODE-USER_BEFORE_btnCompanies*/}

                {/* END_USER_CODE-USER_BEFORE_btnCompanies*/}

                <ButtonWidget
                  conf={state.btnCompanies}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCompanies*/}

                {/* END_USER_CODE-USER_AFTER_btnCompanies*/}
                {/* START_USER_CODE-USER_BEFORE_btnReadyToPayMntnce*/}

                {/* END_USER_CODE-USER_BEFORE_btnReadyToPayMntnce*/}

                <ButtonWidget
                  conf={state.btnReadyToPayMntnce}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReadyToPayMntnce*/}

                {/* END_USER_CODE-USER_AFTER_btnReadyToPayMntnce*/}
                {/* START_USER_CODE-USER_BEFORE_btnSecurity*/}

                {/* END_USER_CODE-USER_BEFORE_btnSecurity*/}

                <ButtonWidget
                  conf={state.btnSecurity}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSecurity*/}

                {/* END_USER_CODE-USER_AFTER_btnSecurity*/}
                {/* START_USER_CODE-USER_BEFORE_btnErrorCodeFilterMntnce*/}

                {/* END_USER_CODE-USER_BEFORE_btnErrorCodeFilterMntnce*/}

                <ButtonWidget
                  conf={state.btnErrorCodeFilterMntnce}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnErrorCodeFilterMntnce*/}

                {/* END_USER_CODE-USER_AFTER_btnErrorCodeFilterMntnce*/}
                {/* START_USER_CODE-USER_BEFORE_btnAcctDistMntnce*/}

                {/* END_USER_CODE-USER_BEFORE_btnAcctDistMntnce*/}

                <ButtonWidget
                  conf={state.btnAcctDistMntnce}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAcctDistMntnce*/}

                {/* END_USER_CODE-USER_AFTER_btnAcctDistMntnce*/}
                {/* START_USER_CODE-USER_BEFORE_btnHighMoistureSetup*/}

                {/* END_USER_CODE-USER_BEFORE_btnHighMoistureSetup*/}

                <ButtonWidget
                  conf={state.btnHighMoistureSetup}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnHighMoistureSetup*/}

                {/* END_USER_CODE-USER_AFTER_btnHighMoistureSetup*/}
                {/* START_USER_CODE-USER_BEFORE_btnInventoryMntnce*/}

                {/* END_USER_CODE-USER_BEFORE_btnInventoryMntnce*/}

                <ButtonWidget
                  conf={state.btnInventoryMntnce}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnInventoryMntnce*/}

                {/* END_USER_CODE-USER_AFTER_btnInventoryMntnce*/}
                {/* START_USER_CODE-USER_BEFORE_btnWireTransferSetup*/}

                {/* END_USER_CODE-USER_BEFORE_btnWireTransferSetup*/}

                <ButtonWidget
                  conf={state.btnWireTransferSetup}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnWireTransferSetup*/}

                {/* END_USER_CODE-USER_AFTER_btnWireTransferSetup*/}
                {/* START_USER_CODE-USER_BEFORE_btnCMADMAControl*/}

                {/* END_USER_CODE-USER_BEFORE_btnCMADMAControl*/}

                <ButtonWidget
                  conf={state.btnCMADMAControl}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCMADMAControl*/}

                {/* END_USER_CODE-USER_AFTER_btnCMADMAControl*/}
                {/* START_USER_CODE-USER_BEFORE_btnTradeCategory*/}

                {/* END_USER_CODE-USER_BEFORE_btnTradeCategory*/}

                <ButtonWidget
                  conf={state.btnTradeCategory}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnTradeCategory*/}

                {/* END_USER_CODE-USER_AFTER_btnTradeCategory*/}
                {/* START_USER_CODE-USER_BEFORE_btnDashboard*/}

                {/* END_USER_CODE-USER_BEFORE_btnDashboard*/}

                <ButtonWidget
                  conf={state.btnDashboard}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDashboard*/}

                {/* END_USER_CODE-USER_AFTER_btnDashboard*/}
                {/* START_USER_CODE-USER_BEFORE_btnPricingSchedule*/}

                {/* END_USER_CODE-USER_BEFORE_btnPricingSchedule*/}

                <ButtonWidget
                  conf={state.btnPricingSchedule}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPricingSchedule*/}

                {/* END_USER_CODE-USER_AFTER_btnPricingSchedule*/}
                {/* START_USER_CODE-USER_BEFORE_btnLoanRepayment*/}

                {/* END_USER_CODE-USER_BEFORE_btnLoanRepayment*/}

                <ButtonWidget
                  conf={state.btnLoanRepayment}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnLoanRepayment*/}

                {/* END_USER_CODE-USER_AFTER_btnLoanRepayment*/}
                {/* START_USER_CODE-USER_BEFORE_btnLoanInterest*/}

                {/* END_USER_CODE-USER_BEFORE_btnLoanInterest*/}

                <ButtonWidget
                  conf={state.btnLoanInterest}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnLoanInterest*/}

                {/* END_USER_CODE-USER_AFTER_btnLoanInterest*/}
                {/* START_USER_CODE-USER_BEFORE_btnMarketPriceSetup*/}

                {/* END_USER_CODE-USER_BEFORE_btnMarketPriceSetup*/}

                <ButtonWidget
                  conf={state.btnMarketPriceSetup}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnMarketPriceSetup*/}

                {/* END_USER_CODE-USER_AFTER_btnMarketPriceSetup*/}
                {/* START_USER_CODE-USER_BEFORE_btnBankingHolidays*/}

                {/* END_USER_CODE-USER_BEFORE_btnBankingHolidays*/}

                <ButtonWidget
                  conf={state.btnBankingHolidays}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnBankingHolidays*/}

                {/* END_USER_CODE-USER_AFTER_btnBankingHolidays*/}
                {/* START_USER_CODE-USER_BEFORE_btnLoanReduction*/}

                {/* END_USER_CODE-USER_BEFORE_btnLoanReduction*/}

                <ButtonWidget
                  conf={state.btnLoanReduction}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnLoanReduction*/}

                {/* END_USER_CODE-USER_AFTER_btnLoanReduction*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSpecialFunctions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSpecialFunctions*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_SpecialFunctions*/}

              {/* END_USER_CODE-USER_AFTER_SpecialFunctions*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_SpecialFunctions);
