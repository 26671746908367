/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  getValue,
  setData,
  getData,
  enable,
  goTo,
  getSelectedRowNumber
} from "../../shared/WindowImports";

import "./CmaDmaControlSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../../SystemMaintenanceMasterManagement/Service/SystemMaintenanceMasterManagementServices";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_CmaDmaControlSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const gridId = 'gridCmaDmaControlSetup';
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "CmaDmaControlSetup",
    windowName: "CmaDmaControlSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.CmaDmaControlSetup",
    // START_USER_CODE-USER_CmaDmaControlSetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "CMA DMA Control Setup",
      scrCode: "PN1120A",
    },
    // END_USER_CODE-USER_CmaDmaControlSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "grpbxCmaDmaControlSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    colApplyWRDeductions: {
      name: "colApplyWRDeductions",
      type: "GridColumnWidget",
      parent: "gridCmaDmaControlSetup",
      Label: "Apply WR Deductions",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colApplyWRDeductions_PROPERTIES

      // END_USER_CODE-USER_colApplyWRDeductions_PROPERTIES
    },
    colCMA: {
      name: "colCMA",
      type: "GridColumnWidget",
      parent: "gridCmaDmaControlSetup",
      Label: "CMA",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCMA_PROPERTIES

      // END_USER_CODE-USER_colCMA_PROPERTIES
    },
    colCountryID: {
      name: "colCountryID",
      type: "GridColumnWidget",
      parent: "gridCmaDmaControlSetup",
      Label: "Country ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCountryID_PROPERTIES

      // END_USER_CODE-USER_colCountryID_PROPERTIES
    },
    colDMA: {
      name: "colDMA",
      type: "GridColumnWidget",
      parent: "gridCmaDmaControlSetup",
      Label: "DMA",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDMA_PROPERTIES

      // END_USER_CODE-USER_colDMA_PROPERTIES
    },
    colStateAbbr: {
      name: "colStateAbbr",
      type: "GridColumnWidget",
      parent: "gridCmaDmaControlSetup",
      Label: "State Abbr",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStateAbbr_PROPERTIES

      // END_USER_CODE-USER_colStateAbbr_PROPERTIES
    },
    colStateID: {
      name: "colStateID",
      type: "GridColumnWidget",
      parent: "gridCmaDmaControlSetup",
      Label: "State ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStateID_PROPERTIES

      // END_USER_CODE-USER_colStateID_PROPERTIES
    },
    colUnappliedLbsAllowed: {
      name: "colUnappliedLbsAllowed",
      type: "GridColumnWidget",
      parent: "gridCmaDmaControlSetup",
      Label: "Unapplied Lbs Allowed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUnappliedLbsAllowed_PROPERTIES

      // END_USER_CODE-USER_colUnappliedLbsAllowed_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridCmaDmaControlSetup",
      Label: "Vendor #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    colVendorName: {
      name: "colVendorName",
      type: "GridColumnWidget",
      parent: "gridCmaDmaControlSetup",
      Label: "Vendor Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorName_PROPERTIES

      // END_USER_CODE-USER_colVendorName_PROPERTIES
    },
    gridCmaDmaControlSetup: {
      name: "gridCmaDmaControlSetup",
      type: "GridWidget",
      parent: "grpbxCmaDmaControlSetup",
      gridCellsOrder:
        "txtcolStateID,txtcolCountryID,txtcolVendor,txtcolVendorName,txtcolStateAbbr,txtcolCMA,txtcolDMA,txtcolApplyWRDeductions,txtcolUnappliedLbsAllowed",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridCmaDmaControlSetup_PROPERTIES

      // END_USER_CODE-USER_gridCmaDmaControlSetup_PROPERTIES
    },
    txtcolApplyWRDeductions: {
      name: "txtcolApplyWRDeductions",
      type: "TextBoxWidget",
      colName: "colApplyWRDeductions",
      parent: "gridCmaDmaControlSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolApplyWRDeductions_PROPERTIES

      // END_USER_CODE-USER_txtcolApplyWRDeductions_PROPERTIES
    },
    txtcolCMA: {
      name: "txtcolCMA",
      type: "TextBoxWidget",
      colName: "colCMA",
      parent: "gridCmaDmaControlSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCMA_PROPERTIES

      // END_USER_CODE-USER_txtcolCMA_PROPERTIES
    },
    txtcolCountryID: {
      name: "txtcolCountryID",
      type: "TextBoxWidget",
      colName: "colCountryID",
      parent: "gridCmaDmaControlSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCountryID_PROPERTIES

      // END_USER_CODE-USER_txtcolCountryID_PROPERTIES
    },
    txtcolDMA: {
      name: "txtcolDMA",
      type: "TextBoxWidget",
      colName: "colDMA",
      parent: "gridCmaDmaControlSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDMA_PROPERTIES

      // END_USER_CODE-USER_txtcolDMA_PROPERTIES
    },
    txtcolStateAbbr: {
      name: "txtcolStateAbbr",
      type: "TextBoxWidget",
      colName: "colStateAbbr",
      parent: "gridCmaDmaControlSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStateAbbr_PROPERTIES

      // END_USER_CODE-USER_txtcolStateAbbr_PROPERTIES
    },
    txtcolStateID: {
      name: "txtcolStateID",
      type: "TextBoxWidget",
      colName: "colStateID",
      parent: "gridCmaDmaControlSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStateID_PROPERTIES

      // END_USER_CODE-USER_txtcolStateID_PROPERTIES
    },
    txtcolUnappliedLbsAllowed: {
      name: "txtcolUnappliedLbsAllowed",
      type: "TextBoxWidget",
      colName: "colUnappliedLbsAllowed",
      parent: "gridCmaDmaControlSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUnappliedLbsAllowed_PROPERTIES

      // END_USER_CODE-USER_txtcolUnappliedLbsAllowed_PROPERTIES
    },
    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridCmaDmaControlSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtcolVendorName: {
      name: "txtcolVendorName",
      type: "TextBoxWidget",
      colName: "colVendorName",
      parent: "gridCmaDmaControlSetup",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendorName_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorName_PROPERTIES
    },
    grpbxCmaDmaControlSetup: {
      name: "grpbxCmaDmaControlSetup",
      type: "GroupBoxWidget",
      parent: "CmaDmaControlSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCmaDmaControlSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxCmaDmaControlSetup_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "CmaDmaControlSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#CmaDmaControlProfile": {}
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#CmaDmaControlProfile": {}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(false);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const [selectedRowNum, setSelectedRowNum] = useState(-1);

  useEffect(() => {
    const isCmaDmaControleProfileClosed = getData(thisObj, 'CmaDmaControlSetupReload');
    if (isCmaDmaControleProfileClosed) {
      fillGrid();
      setData(thisObj, 'CmaDmaControlSetupReload', null);
    }
  }, [getData(thisObj, 'CmaDmaControlSetupReload')]);

  // START_USER_CODE-USER_METHODS

  function formatDate(dateVal) {
    var newDate = new Date(dateVal);
    var sMonth = padValue(newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = newDate.getHours();
    var sMinute = padValue(newDate.getMinutes());
    var sSecond = padValue(newDate.getSeconds());
    var sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = iHourCheck - 12;
    }
    else if (iHourCheck === 0) {
      sHour = "12";
    }
    return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + ":" + sSecond + " " + sAMPM;
  };

  function padValue(value) {
    return (value < 10) ? "0" + value : value;
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const fillGrid = async () => {
    try {
      setLoading(true);
      const cmaDmaControlList = await SystemMaintenanceMasterManagementService
        .RetrieveCmaDmaControlDetails();
      const gridData = [];
      for (let i = 0; i < cmaDmaControlList.length; i++) {
        const item = cmaDmaControlList[i];
        const {
          state_id,
          county_id,
          vendor_num,
          state_abbr,
          cma_ind,
          dma_ind,
          apply_deduct_ind,
          allow_unappl_lbs,
          add_user,
          add_date_time,
          chg_user,
          chg_date_time,
          cmadmadeductexceptcontrols,
        } = item;
        let vendorName = '';
        try {
          const vendorDetail = await ContractManagementService
            .RetrieveVendorByNumber(vendor_num);
          vendorName = vendorDetail?.[0]?.name;
        } catch (error) {
          console.error('Error getting vendor details for ' + vendor_num, error);
        }
        const rowItem = {
          txtcolStateID: state_id || '',
          txtcolCountryID: county_id || '',
          txtcolVendor: vendor_num || '',
          txtcolVendorName: toTitleCase(vendorName || ''),
          txtcolStateAbbr: state_abbr || '',
          txtcolCMA: cma_ind || '',
          txtcolDMA: dma_ind || '',
          txtcolApplyWRDeductions: apply_deduct_ind || '',
          txtcolUnappliedLbsAllowed: allow_unappl_lbs || '',
          txtcolAddedBy: add_user + ' ' + formatDate(add_date_time),
          txtcolChangedBy: chg_user + ' ' + formatDate(chg_date_time),
          txtcolCmaDmaDeductExceptControls: cmadmadeductexceptcontrols
        };
        gridData.push(rowItem);
      };
      setValue(thisObj, gridId, gridData);
      setLoading(false);
      return true;
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, `Exception in fillGrid: ${err.toString()}`);
      }
      setLoading(false);
      return false;
    }
  };

  const enableDisableControls = () => {
    try {
      enable(thisObj, 'btnAdd');
      enable(thisObj, 'btnEdit');
      enable(thisObj, 'btnDelete');
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, `Exception in enableDisableControls: ${err.toString()}`);
      }
    }
  };

  const formLoad = async () => {
    try {
      const gridLoaded = await fillGrid();
      enableDisableControls();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, `Exception in formLoad: ${err.toString()}`);
      }
    }
  };

  const selectRow = (newRowNum) => {
    const gridData = getValue(thisObj, gridId);
    if (newRowNum !== undefined && selectedRowNum !== undefined
      && selectedRowNum !== -1 && selectedRowNum !== newRowNum
      && gridData?.length) {
      gridData[selectedRowNum].isSelected = false;
      setSelectedRowNum(newRowNum);
      return (newRowNum);
    }
  }

  const ongridCmaDmaControlSetupRowSelect = (rowItems) => {
    const rowNum = getSelectedRowNumber(thisObj, gridId);
    selectRow(rowNum);
  }
  thisObj.ongridCmaDmaControlSetupRowSelect = ongridCmaDmaControlSetupRowSelect;

  const onbtnAddClick = () => {
    try {
      const cmaDmaControlProfileData = {
        txtStateIDText: '',
        lblStateNameCaption: '',
        txtCountyText: '',
        lblCountyNameCaption: '',
        txtVendorText: '',
        lblVendorCaption: '',
        chkCMAValue: false,
        chkDMAValue: false,
        chkApplyDeductionsValue: false,
        chkAllowUnapplyValue: false,
        lblAddedByCaption: '',
        lblChangedByCaption: '',
        cmdOkCaption: 'Add',
        txtStateIDEnabled: false,
        cboStateAbbrEnabled: true,
        txtCountyEnabled: true,
        txtVendorEnabled: true,
        cmdVendorSearchEnabled: true
      };

      setData(thisObj, 'cmaDmaControlProfileData', cmaDmaControlProfileData);
      goTo(thisObj, 'SystemMaintenanceSpecialFunctions#CmaDmaControlProfile#DEFAULT#true#Click');
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, `Exception in Add button Click: ${err.toString()}`);
      }
    }
  };

  thisObj.onbtnAddClick = onbtnAddClick;

  const onbtnEditClick = async () => {
    try {
      const gridData = getValue(thisObj, gridId);
      let rowNum = getSelectedRowNumber(thisObj, gridId);
      if (rowNum === undefined && selectedRowNum !== undefined && selectedRowNum !== -1) {
        rowNum = selectedRowNum;
      }
      if (rowNum === undefined || rowNum === null || rowNum === -1) {
        showMessage(thisObj, 'A row must be selected to open.');
      } else {
        const rowData = gridData?.[rowNum] || {};
        const buyPtId = null;

        const cmaDmaControlProfileData = {
          txtStateIDText: rowData?.txtcolStateID || '',
          cboStateAbbrText: rowData?.txtcolStateAbbr || '',
          lblStateNameCaption: '',
          txtCountyText: rowData?.txtcolCountryID || '',
          lblCountyNameCaption: '',
          txtVendorText: rowData?.txtcolVendor || '',
          lblVendorCaption: rowData?.txtcolVendorName || '',
          chkCMAValue: rowData?.txtcolCMA === 'Y',
          chkDMAValue: rowData?.txtcolDMA === 'Y',
          chkApplyDeductionsValue: rowData?.txtcolApplyWRDeductions === 'Y',
          chkAllowUnapplyValue: rowData?.txtcolUnappliedLbsAllowed === 'Y',
          lblAddedByCaption: rowData?.txtcolAddedBy || '',
          lblChangedByCaption: rowData?.txtcolChangedBy || '',
          cmaDmaDeductExceptControl: rowData?.txtcolCmaDmaDeductExceptControls || [],
          cmdOkCaption: 'Update',
          txtStateIDEnabled: false,
          cboStateAbbrEnabled: false,
          txtCountyEnabled: false,
          txtVendorEnabled: false,
          cmdVendorSearchEnabled: false
        };

        const stateNameRes = await ContractManagementService
          .RetrievePeanutStateControlDetails(buyPtId, rowData?.txtcolStateAbbr, true);
        if (stateNameRes?.length) {
          const stateIndex = stateNameRes.findIndex(item => item?.stateAbbr === rowData?.txtcolStateAbbr);
          if (stateIndex !== -1) {
            cmaDmaControlProfileData.lblStateNameCaption = stateNameRes?.[stateIndex]?.stateName?.toString()?.trim();
          } else {
            cmaDmaControlProfileData.lblStateNameCaption = stateNameRes?.[0]?.stateName?.toString()?.trim();
          }
        }

        const countyNameRes = await ContractManagementService
          .RetrieveCountyControlDetails(buyPtId, 'YES', rowData?.txtcolStateAbbr, rowData?.txtcolCountryID);
        if (countyNameRes?.length) {
          const countyIndex = countyNameRes.findIndex(item => item?.countyId === rowData?.txtcolCountryID);
          if (countyIndex !== -1) {
            cmaDmaControlProfileData.lblCountyNameCaption = countyNameRes?.[countyIndex]?.countyName?.toString()?.trim();
          } else {
            cmaDmaControlProfileData.lblCountyNameCaption = countyNameRes?.[0]?.countyName?.toString()?.trim();
          }
        }

        setData(thisObj, 'cmaDmaControlProfileData', cmaDmaControlProfileData);
        goTo(thisObj, 'SystemMaintenanceSpecialFunctions#CmaDmaControlProfile#DEFAULT#true#Click');
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, `Exception in Edit button Click: ${err.toString()}`);
      }
    }
  };

  thisObj.onbtnEditClick = onbtnEditClick;

  const onbtnDeleteClick = async () => {
    try {
      const gridData = getValue(thisObj, gridId);
      let rowNum = getSelectedRowNumber(thisObj, gridId);
      if (rowNum === undefined && selectedRowNum !== undefined && selectedRowNum !== -1) {
        rowNum = selectedRowNum;
      }
      if (rowNum === undefined || rowNum === null || rowNum === -1) {
        showMessage(thisObj, 'A row must be selected to delete.');
      } else {
        const vendorNum = gridData?.[rowNum]?.txtcolVendor || null;
        const deleteConfirm = window.confirm(`Are you sure you wish to delete vendor ${vendorNum} from the CMA DMA control table?`);
        if (!deleteConfirm) {
          return;
        }
        const buyPtId = null;
        const stateId = gridData?.[rowNum]?.txtcolStateID || null;
        const countyId = gridData?.[rowNum]?.txtcolCountryID || null;
        const cmaDmaDeleteRes = await SystemMaintenanceMasterManagementService
          .RemoveCmaDmaControlDetails(buyPtId, stateId, countyId, vendorNum);
        if (cmaDmaDeleteRes.status !== 200) {
          showMessage(thisObj,
            'An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.'
          );
          return;
        }
        gridData.splice(rowNum, 1);
        let highlightRowNum = rowNum;
        if (rowNum < gridData.length) {
          // for grid items except last item, the selected index should not change
          gridData[rowNum].isSelected = true;
          setSelectedRowNum(rowNum);
        } else if (rowNum === gridData.length && gridData.length > 0) {
          highlightRowNum = rowNum - 1;
          // for last item in the grid, the selected index should reduce by 1
          gridData[rowNum - 1].isSelected = true;
          setSelectedRowNum(rowNum - 1);
        }
        setValue(thisObj, gridId, gridData);
        enableDisableControls();
        setTimeout(() => {
          let highlight = document.getElementsByClassName("activeRow");
          if (highlight.length > 0) {
            highlight[0].classList.remove("activeRow");
          }
          if (gridData.length) {
            document.getElementById('gridCmaDmaControlSetupCollapse').firstChild.firstChild.firstChild.lastChild.childNodes[highlightRowNum].className += " activeRow";
          }
        }, 1);
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, `Exception in Delete button Click: ${err.toString()}`);
      }
    }
  };

  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_CmaDmaControlSetupPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, `Exception in Close button Click: ${err.toString()}`);
      }
    }
  };

  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_CmaDmaControlSetup*/}

              {/* END_USER_CODE-USER_BEFORE_CmaDmaControlSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCmaDmaControlSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCmaDmaControlSetup*/}

              <GroupBoxWidget
                conf={state.grpbxCmaDmaControlSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                  <ButtonWidget
                    conf={state.btnEdit}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                  {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                  {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}
                {/* START_USER_CODE-USER_BEFORE_gridCmaDmaControlSetup*/}

                {/* END_USER_CODE-USER_BEFORE_gridCmaDmaControlSetup*/}

                <GridWidget
                  conf={state.gridCmaDmaControlSetup}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridCmaDmaControlSetup}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridCmaDmaControlSetup*/}

                {/* END_USER_CODE-USER_AFTER_gridCmaDmaControlSetup*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCmaDmaControlSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCmaDmaControlSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_CmaDmaControlSetup*/}

              {/* END_USER_CODE-USER_AFTER_CmaDmaControlSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_CmaDmaControlSetup);
