/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_PremiumDeducMasterSetup from "./PremiumDeducMasterSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PremiumDeducMasterSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_PremiumDeducMasterSetup />
      );
    });
  });
  afterEach(cleanup);
  test("is PremiumDeducMasterSetup Loads Successfully", () => {
    expect(screen.getByText("PremiumDeducMasterSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for PremiumDeducMasterSetup", () => {
    // START_USER_CODE-USER_PremiumDeducMasterSetup_Custom_Test_Case
    // END_USER_CODE-USER_PremiumDeducMasterSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_PremiumDeducMasterSetup />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PremiumDeducMasterSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PremiumDeducMasterSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btncmdEnableDisableControls(Button Widget) Test Cases", async () => {
    const btncmdEnableDisableControls = screen.getByTestId(
      "btncmdEnableDisableControls"
    );
    expect(btncmdEnableDisableControls).toBeInTheDocument;
    expect(btncmdEnableDisableControls.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:PremiumDeducMasterSetup_btncmdEnableDisableControls"
      )
    );
  });
  test("Custom Test Cases for btncmdEnableDisableControls", () => {
    // START_USER_CODE-USER_btncmdEnableDisableControls_TEST
    // END_USER_CODE-USER_btncmdEnableDisableControls_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PremiumDeducMasterSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PremiumDeducMasterSetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("grpbxPremiumDeducMasterSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxPremiumDeducMasterSetup = screen.getByTestId(
      "grpbxPremiumDeducMasterSetup"
    );
    expect(grpbxPremiumDeducMasterSetup.tagName).toBe("BUTTON");
    expect(grpbxPremiumDeducMasterSetup.type).toBe("button");
    expect(grpbxPremiumDeducMasterSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremiumDeducMasterSetup", () => {
    // START_USER_CODE-USER_grpbxPremiumDeducMasterSetup_TEST
    // END_USER_CODE-USER_grpbxPremiumDeducMasterSetup_TEST
  });
  test("lblPDCodeList(Label Widget) Test Cases", async () => {
    const lblPDCodeList = screen.getByTestId("lblPDCodeList");
    expect(lblPDCodeList.tagName).toBe("LABEL");
    expect(lblPDCodeList.classList).toContain("form-label");
    expect(lblPDCodeList.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:PremiumDeducMasterSetup_lblPDCodeList"
      )
    );
  });
  test("Custom Test Cases for lblPDCodeList", () => {
    // START_USER_CODE-USER_lblPDCodeList_TEST
    // END_USER_CODE-USER_lblPDCodeList_TEST
  });
});
