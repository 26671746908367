/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  TextAreaWidget,
  getData,
  setValue,
  getValue,
  setData,
} from "../../shared/WindowImports";

import "./ContractExceptPremProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../Service/ContractManagementService";
import CommonContext from '../../Store/CommonContext';
import { number } from "yup";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ContractExceptPremProfile(props) {
  const commonContext = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let comp_id = sessionStorage.getItem('compId');
  let userid = sessionStorage.getItem("userid");  
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ContractExceptionPremiumProfile",
    windowName: "ContractExceptPremProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ContractExceptPremProfile",
    // START_USER_CODE-USER_ContractExceptPremProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Contract Exception Premium Profile",
      scrCode: "PN1020G"
    },
    // END_USER_CODE-USER_ContractExceptPremProfile_PROPERTIES
    btnAddProfile: {  //change
      name: "btnAddProfile",  //change
      type: "ButtonWidget",
      parent: "grpbxAddCancel",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES
      type: "Submit",
      Enabled: "true"
      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxAddCancel",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    ddDelLoadType: {
      name: "ddDelLoadType",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyingPoint",
      Label: "Delivery Load Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDelLoadType_PROPERTIES
      Mandatory: true
      // END_USER_CODE-USER_ddDelLoadType_PROPERTIES
    },
    ddExcPrmCd: {
      name: "ddExcPrmCd",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyingPoint",
      Label: "Exception Premium Code:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddExcPrmCd_PROPERTIES
      Options: "",
      Mandatory: true,
      Enabled: true,
      ReadOnly: false
      // END_USER_CODE-USER_ddExcPrmCd_PROPERTIES
    },
    ddRateBase: {
      name: "ddRateBase",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyingPoint",
      Label: "Rate Base:",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRateBase_PROPERTIES
      Mandatory: true
      // END_USER_CODE-USER_ddRateBase_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxAddCancel",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxAddCancel",
      Label: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxAddCancel",
      Label: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String"
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },

    lblBuyingPoint: {
      name: "lblBuyingPoint",
      type: "LabelWidget",
      parent: "grpbxBuyingPoint",
      Label: "Buying Point: ",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyingPoint_PROPERTIES
      ReadOnly: true,
      Enabled: false
      // END_USER_CODE-USER_lblBuyingPoint_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxAddCancel",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    txtarExplanation: {
      name: "txtarExplanation",
      type: "TextAreaWidget",
      parent: "grpbxBuyingPoint",
      Label: "Explanation:",
      ColSpan: "2",
      RowSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarExplanation_PROPERTIES

      // END_USER_CODE-USER_txtarExplanation_PROPERTIES
    },
    txtRate: {
      name: "txtRate",
      type: "TextBoxWidget",
      parent: "grpbxBuyingPoint",
      Label: "Rate:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 10 },

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRate_PROPERTIES
      Mandatory: true,
      // END_USER_CODE-USER_txtRate_PROPERTIES
    },
    txtRateBase: {
      name: "txtRateBase",
      type: "TextBoxWidget",
      parent: "grpbxBuyingPoint",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRateBase_PROPERTIES

      // END_USER_CODE-USER_txtRateBase_PROPERTIES
    },
    grpbxBuyingPoint: {
      name: "grpbxBuyingPoint",
      type: "GroupBoxWidget",
      parent: "ContractExceptPremProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_grpbxBuyingPoint_PROPERTIES
    },
    grpbxAddCancel: {
      name: "grpbxAddCancel",
      type: "GroupBoxWidget",
      parent: "ContractExceptPremProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxAddCancel_PROPERTIES

      // END_USER_CODE-USER_grpbxAddCancel_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {},
      REVERSE: {},
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    GetContractExceptPremMasterList();
    let flag = getData(thisObj, "flag");
    if (flag === "Add") {
      document.getElementById("btnAddProfile").innerText = "Add"
    }
    else if (flag === "Edit") {
      document.getElementById("btnAddProfile").innerText = "Update";
      document.getElementById("ddExcPrmCd").disabled = true
      editFunctioanlity()
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  function FormLoad() {
    var buyingPointDescription=getData(thisObj,"BuyingPointLabel")
    setValue(thisObj, "lblBuyingPoint",buyingPointDescription);
    bFillLoadTypeList()
    bFillRateBaseList()
  }

  const bFillLoadTypeList = () => {
    let js = [];
    js.push({key: 'COMMERCIAL',description: 'Commercial' })
    js.push({key: 'SEED',description: 'Seed' })
    js.push({key: 'BOTH',description: 'Both' })
    thisObj.setState(current => {
      return {
        ...current,
        ddDelLoadType: {
          ...state["ddDelLoadType"],
          valueList: js
        }
      }
    })
  }

  const bFillRateBaseList = () => {
    let js = [];
    js.push({ key: 'PER GROSS TON', description: 'per Gross Ton' })
    js.push({ key: 'PER NET TON', description: 'per Net Ton' })
    js.push({ key: 'PER NET LESS LSK TON', description: 'per Net less LSK Ton' })
    js.push({ key: 'PER NET TON BASIS GRADE', description: 'per Net Ton Basis Grade' })
    js.push({ key: 'PER LOAD', description: 'per Load' })
    thisObj.setState(current => {
      return {
        ...current,
        ddRateBase: {
          ...state["ddRateBase"],
          valueList: js
        }
      }
    })
  }

  const ontxtRateBlur = (value) => {
    try {
      let rate = document.getElementById("txtRate").value
      if (rate.match(/^[0-9]*$/) == null) {
        
      }
      else {
        if (rate != "") {
          rate = rate + ".00000"
          setValue(thisObj, 'txtRate', rate);
        }
      }

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
  };
  thisObj.ontxtRateBlur = ontxtRateBlur;

  // Exception Premium dropdown On Add button click of Exception Premium Setup Screen

  const GetContractExceptPremMasterList = () => {
    let pdCode = getData(thisObj, 'PdCode');
    let buY_PT_ID = commonContext.getContractProfile_BuyingPointId;
    let contract_num = commonContext.getContractProfile_ContractNumber;
    ContractManagementService.RetrieveContractExceptionPremiumDetails(buY_PT_ID, contract_num)
      .then(response => {
        let lstExceptions = response;
        if (lstExceptions == null || lstExceptions == undefined || lstExceptions == '') {
          ContractManagementService.RetrieveContractExceptionPremiumMasterDetails(buY_PT_ID)
            .then(response => {
              let js = [];
              for (var i = 0; i < response.length; i++) {
                let obj = { key: response[i].pD_Code.trim(), description: response[i].pD_Code.trim() + '-' + response[i].pD_DESC.trim() }
                js.push(obj);
              }
              thisObj.setState(current => {
                return {
                  ...current,
                  ddExcPrmCd: {
                    ...state["ddExcPrmCd"],
                    valueList: js
                  }
                }
              });
            }
            )
        }
        else {
          ContractManagementService.RetrieveContractExceptionPremiumMasterDetails(buY_PT_ID).then(response => {
            let data = response;
            let js = [];
            for (var i = 0; i < data.length; i++) {
              let obj = { key: data[i].pD_Code.trim(), description: data[i].pD_Code.trim() + '-' + data[i].pD_DESC.trim() }
              if (lstExceptions != null) {
                if (lstExceptions.find(o => o.pdCode.trim() == obj.key) == '' || lstExceptions.find(o => o.pdCode.trim() == obj.key) == undefined) {
                  js.push(obj);
                }
              }
            }
            thisObj.setState(current => {
              return {
                ...current,
                ddExcPrmCd: {
                  ...state["ddExcPrmCd"],
                  valueList: js
                }
              }
            });
          }
          )
        }
      });
  };

  //Edit button click from Except premiumSetup screen Functioanlity
  const editFunctioanlity = () => {
    var pdCode = getData(thisObj, 'PdCode');
    var buY_PT_ID = commonContext.getContractProfile_BuyingPointId;
    var contract_num = commonContext.getContractProfile_ContractNumber;
    ContractManagementService.RetrieveContractExceptionPremiumDetails(buY_PT_ID, contract_num,pdCode)
      .then(response => {
        let lstExceptions = response;
        ContractManagementService.RetrieveContractExceptionPremiumMasterDetails(buY_PT_ID).then(response => {
          let data = response;
          let js = [];
          for (var i = 0; i < data.length; i++) {
            let obj = { key: data[i].pD_Code.trim(), description: data[i].pD_Code.trim() + '-' + data[i].pD_DESC.trim() }
            if (lstExceptions != null) {
              js.push(obj);
            }
          }
          thisObj.setState(current => {
            return {
              ...current,
              ddExcPrmCd: {
                ...state["ddExcPrmCd"],
                valueList: js
              }
            }
          })
        })
        let flag = getData(thisObj, "flag");
        let premiumCode = getData(thisObj, 'PdCode');
        let j = null
        for (let i = 0; i < lstExceptions.length; i++) {
          if (premiumCode == lstExceptions[i].pdCode) {
            j = i
          }
        }

        if (pdCode !== null && flag !== "Add" && j !== null) {
          setValue(thisObj, 'ddExcPrmCd', lstExceptions[j].pdCode);
          setValue(thisObj, 'txtRate', lstExceptions[j].pdRate);
          setValue(thisObj, 'ddRateBase',lstExceptions[j].pdRateBase);
          setValue(thisObj, 'ddDelLoadType', lstExceptions[j].delvLoadType);
          setValue(thisObj, 'txtarExplanation', lstExceptions[j].explanation);
          setValue(thisObj, 'lblAddedByValue', lstExceptions[j].addUser);
          setValue(thisObj, 'lblChangedByValue', lstExceptions[j].chgUser);
        }
      })
  };

  // POST API Contract_Exception_Premium
  const onbtnAddProfileClick = () => {
    try {
      if(thisObj.isValid && (Number(txtRate.value)) < 10000){//validation Added
        let pD_CODE = getValue(thisObj, "ddExcPrmCd");
        let pD_RATE = getValue(thisObj, "txtRate");
        let pdRateBaseAllList = thisObj.state['ddRateBase'].valueList;
        let pD_RATE_BASE = '';
        let rateBaseKey = thisObj.values['ddRateBase'];
        if (rateBaseKey !== undefined && rateBaseKey !== null) {
          pD_RATE_BASE = pdRateBaseAllList.find(elem => elem.key == rateBaseKey).description.toUpperCase();
        }
        let loadtype = getValue(thisObj, "ddDelLoadType");
        let explanation =txtarExplanation.value
        let datetime = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString();

        let dataObj = {
          comp_id: comp_id,
          crop_Year: parseInt(cropYear),
          buY_PT_ID: commonContext.getContractProfile_BuyingPointId,
          contract_num: commonContext.getContractProfile_ContractNumber,
          pD_CODE: pD_CODE,
          pD_RATE: parseInt(pD_RATE),
          pD_RATE_BASE: pD_RATE_BASE,
          user_ID:userid ,
          date_Time: datetime,
          explanation: explanation,
          loadtype: loadtype.toUpperCase()
        }

        setData(thisObj, "selData", true);
        let flag = getData(thisObj, "flag");

        if (flag === "Add") {
          ContractManagementService.CreateContractExceptionPremium(dataObj)
            .then(response => {
              showMessage(thisObj, response.message, true)
            })
            document.getElementById("ContractManagement_ContractExceptPremProfilePopUp").childNodes[0].click();
        }
        else {
          // PUT API ContractExceptionPremProfile
          document.getElementById("ContractManagement_ContractExceptPremProfilePopUp").childNodes[0].click();
          let pD_CODE = getValue(thisObj, "ddExcPrmCd");
          let pD_RATE = getValue(thisObj, "txtRate");
          let pD_RATE_BASE = getValue(thisObj, "ddRateBase");
          let loadtype = getValue(thisObj, "ddDelLoadType");
          let explanation = getValue(thisObj, "txtarExplanation");

          let dataObj = {
            comp_id: comp_id,
            crop_Year: parseInt(cropYear),
            buY_PT_ID: commonContext.getContractProfile_BuyingPointId,//Come from contract profile-2009 page
            contract_num: commonContext.getContractProfile_ContractNumber,//Come from contract profile-2009 page
            pD_CODE: pD_CODE,
            pD_RATE: parseInt(pD_RATE),
            pD_RATE_BASE: pD_RATE_BASE,
            user_ID:userid ,
            date_Time: datetime,
            explanation: explanation,
            loadtype: loadtype
          }
          ContractManagementService.UpdateContractExceptionPremium(dataObj)
            .then(response => {
              // showMessage(thisObj, response.message, true);
            })
        }
      }
      {
          let pD_CODE = getValue(thisObj, "ddExcPrmCd");
          let pD_RATE = getValue(thisObj, "txtRate");
          let pD_RATE_BASE = getValue(thisObj, "ddRateBase");
          let loadtype = getValue(thisObj, "ddDelLoadType");
        if(pD_CODE===undefined){
          alert("Please Select Exception Premium From List",false)
        }
        else if((parseInt(txtRate.value)) >10000 || pD_RATE=="" || pD_RATE==undefined || txtRate.value!=Number(txtRate.value) ){
          alert("Rate is Invalid.Rate Must be Greater Than 0 and less than 10,000!",false)
        }
        else if(pD_RATE_BASE===undefined){
          alert("Please Select RateBase From List",false)
        }
        else if(loadtype===undefined){
          alert("Please select a Delivery Load Type from List",false)
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {

        showMessage(thisObj, err.message);
      } else {
        // showMessage(
        //   thisObj,
        //   "Exception in PreSubmit Event code for widget:btnClose event:Click"
        // );
      }
      return false;
    }
  };
  thisObj.onbtnAddProfileClick = onbtnAddProfileClick;


  // START_USER_CODE-USER_METHODS
  const onbtnCancelClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      document.getElementById("ContractManagement_ContractExceptPremProfilePopUp").childNodes[0].click();
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        // showMessage(
        //   thisObj,
        //   "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        // );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        // validateForm,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        // thisObj.validateForm = validateForm = validateForm;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row" onSubmit={handleSubmit}>
              {/* START_USER_CODE-USER_BEFORE_ContractExceptPremProfile*/}

              {/* END_USER_CODE-USER_BEFORE_ContractExceptPremProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxBuyingPoint*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxBuyingPoint*/}

              <GroupBoxWidget conf={state.grpbxBuyingPoint} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                {/* <LabelWidget
                  values={values}
                  conf={state.lblBuyingPoint}
                  screenConf={state}
                ></LabelWidget> */}
                {/* START_USER_CODE-USER_AFTER_lblBuyingPoint*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lblBuyingPoint}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* END_USER_CODE-USER_AFTER_lblBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_ddExcPrmCd*/}

                {/* END_USER_CODE-USER_BEFORE_ddExcPrmCd*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj, onbtnAddProfileClick)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  // errors={errors}
                  errors={{ ...errors, "ddExcPrmCd": values && values['ddExcPrmCd'] ? '' : "Please Select Exception Premium From List" }}
                  conf={state.ddExcPrmCd}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddExcPrmCd*/}

                {/* END_USER_CODE-USER_AFTER_ddExcPrmCd*/}
                {/* START_USER_CODE-USER_BEFORE_txtRate*/}

                {/* END_USER_CODE-USER_BEFORE_txtRate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj,ontxtRateBlur)
                  }

                  values={values}
                  touched={touched}
                  // errors={errors}
                  errors={{ ...errors, "txtRate": values && values['txtRate'] ? '' : "Rate is Invalid.Rate Must be Greater Than 0 and less than 10,000!" }}
                  conf={state.txtRate}
                  screenConf={state}

                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRate*/}

                {/* END_USER_CODE-USER_AFTER_txtRate*/}
                {/* START_USER_CODE-USER_BEFORE_ddRateBase*/}

                {/* END_USER_CODE-USER_BEFORE_ddRateBase*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  // errors={errors}
                  errors={{ ...errors, "ddRateBase": values && values['ddRateBase'] ? '' : "Please select a Rate Base from List" }}
                  conf={state.ddRateBase}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRateBase*/}

                {/* END_USER_CODE-USER_AFTER_ddRateBase*/}
                {/* START_USER_CODE-USER_BEFORE_txtRateBase*/}

                {/* END_USER_CODE-USER_BEFORE_txtRateBase*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRateBase}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_txtRateBase*/}

                {/* END_USER_CODE-USER_AFTER_txtRateBase*/}
                {/* START_USER_CODE-USER_BEFORE_ddDelLoadType*/}

                {/* END_USER_CODE-USER_BEFORE_ddDelLoadType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  // errors={errors}
                  errors={{ ...errors, "ddDelLoadType": values && values['ddDelLoadType'] ? '' : "Please select a Delivery Load Type from List" }}
                  conf={state.ddDelLoadType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddDelLoadType*/}

                {/* END_USER_CODE-USER_AFTER_ddDelLoadType*/}
                {/* START_USER_CODE-USER_BEFORE_txtarExplanation*/}

                {/* END_USER_CODE-USER_BEFORE_txtarExplanation*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarExplanation}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarExplanation*/}

                {/* END_USER_CODE-USER_AFTER_txtarExplanation*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxBuyingPoint*/}

              {/* END_USER_CODE-USER_AFTER_grpbxBuyingPoint*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxAddCancel*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAddCancel*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxAddCancel} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}
                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAddProfile}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}
                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAddCancel*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAddCancel*/}

              {/* START_USER_CODE-USER_AFTER_ContractExceptPremProfile*/}

              {/* END_USER_CODE-USER_AFTER_ContractExceptPremProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ContractExceptPremProfile);
