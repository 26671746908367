const defaultAddressProps = {
    StreetAddress: '',
    City: '',
    State: '',
    Zip: ''
}

export class clsAddress {

    constructor(thisObj) {
        this.thisObj = thisObj;
        this.mudtProps = Object.assign({}, defaultAddressProps);
        this.mudtSave = Object.assign({}, defaultAddressProps);
    }

    StreetAddress = (Value) => {
        this.mudtProps.StreetAddress = Value;
    }

    City = (Value) => {
        this.mudtProps.City = Value;
    }

    State = (Value) => {
        this.mudtProps.State = Value;
    }

    Zip = (Value) => {
        this.mudtProps.Zip = Value;
    }
}
