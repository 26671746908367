/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_VendorWireTransferSetup from "./VendorWireTransferSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("VendorWireTransferSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_VendorWireTransferSetup />
      );
    });
  });
  afterEach(cleanup);
  test("is VendorWireTransferSetup Loads Successfully", () => {
    expect(screen.getByText("VendorWireTransferSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for VendorWireTransferSetup", () => {
    // START_USER_CODE-USER_VendorWireTransferSetup_Custom_Test_Case
    // END_USER_CODE-USER_VendorWireTransferSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_VendorWireTransferSetup />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxIntermediaryBank(GroupBox Widget) Test Cases", async () => {
    const grpbxIntermediaryBank = screen.getByTestId("grpbxIntermediaryBank");
    expect(grpbxIntermediaryBank.tagName).toBe("BUTTON");
    expect(grpbxIntermediaryBank.type).toBe("button");
    expect(grpbxIntermediaryBank.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxIntermediaryBank", () => {
    // START_USER_CODE-USER_grpbxIntermediaryBank_TEST
    // END_USER_CODE-USER_grpbxIntermediaryBank_TEST
  });
  test("grpbxVendorWireTransferSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorWireTransferSetup = screen.getByTestId(
      "grpbxVendorWireTransferSetup"
    );
    expect(grpbxVendorWireTransferSetup.tagName).toBe("BUTTON");
    expect(grpbxVendorWireTransferSetup.type).toBe("button");
    expect(grpbxVendorWireTransferSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorWireTransferSetup", () => {
    // START_USER_CODE-USER_grpbxVendorWireTransferSetup_TEST
    // END_USER_CODE-USER_grpbxVendorWireTransferSetup_TEST
  });
  test("grpbxWireTransferInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxWireTransferInfo = screen.getByTestId("grpbxWireTransferInfo");
    expect(grpbxWireTransferInfo.tagName).toBe("BUTTON");
    expect(grpbxWireTransferInfo.type).toBe("button");
    expect(grpbxWireTransferInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWireTransferInfo", () => {
    // START_USER_CODE-USER_grpbxWireTransferInfo_TEST
    // END_USER_CODE-USER_grpbxWireTransferInfo_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_lblChangedBy"
      )
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblWireTransferInformation(Label Widget) Test Cases", async () => {
    const lblWireTransferInformation = screen.getByTestId(
      "lblWireTransferInformation"
    );
    expect(lblWireTransferInformation.tagName).toBe("LABEL");
    expect(lblWireTransferInformation.classList).toContain("form-label");
    expect(lblWireTransferInformation.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_lblWireTransferInformation"
      )
    );
  });
  test("Custom Test Cases for lblWireTransferInformation", () => {
    // START_USER_CODE-USER_lblWireTransferInformation_TEST
    // END_USER_CODE-USER_lblWireTransferInformation_TEST
  });
  test("txtBankABANum(Textbox Widget) Test Cases", async () => {
    const txtBankABANum = screen.getByTestId("txtBankABANum");
    expect(txtBankABANum.tagName).toBe("INPUT");
    expect(txtBankABANum.type).toBe("text");
    expect(txtBankABANum.classList).toContain("textboxWidgetClass");
    expect(txtBankABANum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtBankABANum"
      )
    );
    await act(async () => {
      userEvent.type(txtBankABANum, "1");
    });
  });
  test("Custom Test Cases for txtBankABANum", () => {
    // START_USER_CODE-USER_txtBankABANum_TEST
    // END_USER_CODE-USER_txtBankABANum_TEST
  });
  test("txtBankAccountNum(Textbox Widget) Test Cases", async () => {
    const txtBankAccountNum = screen.getByTestId("txtBankAccountNum");
    expect(txtBankAccountNum.tagName).toBe("INPUT");
    expect(txtBankAccountNum.type).toBe("text");
    expect(txtBankAccountNum.classList).toContain("textboxWidgetClass");
    expect(txtBankAccountNum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtBankAccountNum"
      )
    );
    await act(async () => {
      userEvent.type(txtBankAccountNum, "1");
    });
  });
  test("Custom Test Cases for txtBankAccountNum", () => {
    // START_USER_CODE-USER_txtBankAccountNum_TEST
    // END_USER_CODE-USER_txtBankAccountNum_TEST
  });
  test("txtBankAddress(Textbox Widget) Test Cases", async () => {
    const txtBankAddress = screen.getByTestId("txtBankAddress");
    expect(txtBankAddress.tagName).toBe("INPUT");
    expect(txtBankAddress.type).toBe("text");
    expect(txtBankAddress.classList).toContain("textboxWidgetClass");
    expect(txtBankAddress.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtBankAddress"
      )
    );
    await act(async () => {
      userEvent.type(txtBankAddress, "1");
    });
  });
  test("Custom Test Cases for txtBankAddress", () => {
    // START_USER_CODE-USER_txtBankAddress_TEST
    // END_USER_CODE-USER_txtBankAddress_TEST
  });
  test("txtBankCity(Textbox Widget) Test Cases", async () => {
    const txtBankCity = screen.getByTestId("txtBankCity");
    expect(txtBankCity.tagName).toBe("INPUT");
    expect(txtBankCity.type).toBe("text");
    expect(txtBankCity.classList).toContain("textboxWidgetClass");
    expect(txtBankCity.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtBankCity")
    );
    await act(async () => {
      userEvent.type(txtBankCity, "1");
    });
  });
  test("Custom Test Cases for txtBankCity", () => {
    // START_USER_CODE-USER_txtBankCity_TEST
    // END_USER_CODE-USER_txtBankCity_TEST
  });
  test("txtBankZip(Textbox Widget) Test Cases", async () => {
    const txtBankZip = screen.getByTestId("txtBankZip");
    expect(txtBankZip.tagName).toBe("INPUT");
    expect(txtBankZip.type).toBe("text");
    expect(txtBankZip.classList).toContain("textboxWidgetClass");
    expect(txtBankZip.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtBankZip")
    );
    await act(async () => {
      userEvent.type(txtBankZip, "1");
    });
  });
  test("Custom Test Cases for txtBankZip", () => {
    // START_USER_CODE-USER_txtBankZip_TEST
    // END_USER_CODE-USER_txtBankZip_TEST
  });
  test("txtEmail(Textbox Widget) Test Cases", async () => {
    const txtEmail = screen.getByTestId("txtEmail");
    expect(txtEmail.tagName).toBe("INPUT");
    expect(txtEmail.type).toBe("text");
    expect(txtEmail.classList).toContain("textboxWidgetClass");
    expect(txtEmail.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtEmail")
    );
    await act(async () => {
      userEvent.type(txtEmail, "1");
    });
  });
  test("Custom Test Cases for txtEmail", () => {
    // START_USER_CODE-USER_txtEmail_TEST
    // END_USER_CODE-USER_txtEmail_TEST
  });
  test("txtFAXContact(Textbox Widget) Test Cases", async () => {
    const txtFAXContact = screen.getByTestId("txtFAXContact");
    expect(txtFAXContact.tagName).toBe("INPUT");
    expect(txtFAXContact.type).toBe("text");
    expect(txtFAXContact.classList).toContain("textboxWidgetClass");
    expect(txtFAXContact.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtFAXContact"
      )
    );
    await act(async () => {
      userEvent.type(txtFAXContact, "1");
    });
  });
  test("Custom Test Cases for txtFAXContact", () => {
    // START_USER_CODE-USER_txtFAXContact_TEST
    // END_USER_CODE-USER_txtFAXContact_TEST
  });
  test("txtFAXNum(Textbox Widget) Test Cases", async () => {
    const txtFAXNum = screen.getByTestId("txtFAXNum");
    expect(txtFAXNum.tagName).toBe("INPUT");
    expect(txtFAXNum.type).toBe("text");
    expect(txtFAXNum.classList).toContain("textboxWidgetClass");
    expect(txtFAXNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtFAXNum")
    );
    await act(async () => {
      userEvent.type(txtFAXNum, "1");
    });
  });
  test("Custom Test Cases for txtFAXNum", () => {
    // START_USER_CODE-USER_txtFAXNum_TEST
    // END_USER_CODE-USER_txtFAXNum_TEST
  });
  test("txtIMBank(Textbox Widget) Test Cases", async () => {
    const txtIMBank = screen.getByTestId("txtIMBank");
    expect(txtIMBank.tagName).toBe("INPUT");
    expect(txtIMBank.type).toBe("text");
    expect(txtIMBank.classList).toContain("textboxWidgetClass");
    expect(txtIMBank.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtIMBank")
    );
    await act(async () => {
      userEvent.type(txtIMBank, "1");
    });
  });
  test("Custom Test Cases for txtIMBank", () => {
    // START_USER_CODE-USER_txtIMBank_TEST
    // END_USER_CODE-USER_txtIMBank_TEST
  });
  test("txtIMBankABANum(Textbox Widget) Test Cases", async () => {
    const txtIMBankABANum = screen.getByTestId("txtIMBankABANum");
    expect(txtIMBankABANum.tagName).toBe("INPUT");
    expect(txtIMBankABANum.type).toBe("text");
    expect(txtIMBankABANum.classList).toContain("textboxWidgetClass");
    expect(txtIMBankABANum.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtIMBankABANum"
      )
    );
    await act(async () => {
      userEvent.type(txtIMBankABANum, "1");
    });
  });
  test("Custom Test Cases for txtIMBankABANum", () => {
    // START_USER_CODE-USER_txtIMBankABANum_TEST
    // END_USER_CODE-USER_txtIMBankABANum_TEST
  });
  test("txtIMBankAddress(Textbox Widget) Test Cases", async () => {
    const txtIMBankAddress = screen.getByTestId("txtIMBankAddress");
    expect(txtIMBankAddress.tagName).toBe("INPUT");
    expect(txtIMBankAddress.type).toBe("text");
    expect(txtIMBankAddress.classList).toContain("textboxWidgetClass");
    expect(txtIMBankAddress.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtIMBankAddress"
      )
    );
    await act(async () => {
      userEvent.type(txtIMBankAddress, "1");
    });
  });
  test("Custom Test Cases for txtIMBankAddress", () => {
    // START_USER_CODE-USER_txtIMBankAddress_TEST
    // END_USER_CODE-USER_txtIMBankAddress_TEST
  });
  test("txtIMBankCity(Textbox Widget) Test Cases", async () => {
    const txtIMBankCity = screen.getByTestId("txtIMBankCity");
    expect(txtIMBankCity.tagName).toBe("INPUT");
    expect(txtIMBankCity.type).toBe("text");
    expect(txtIMBankCity.classList).toContain("textboxWidgetClass");
    expect(txtIMBankCity.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtIMBankCity"
      )
    );
    await act(async () => {
      userEvent.type(txtIMBankCity, "1");
    });
  });
  test("Custom Test Cases for txtIMBankCity", () => {
    // START_USER_CODE-USER_txtIMBankCity_TEST
    // END_USER_CODE-USER_txtIMBankCity_TEST
  });
  test("txtIMBankState(Textbox Widget) Test Cases", async () => {
    const txtIMBankState = screen.getByTestId("txtIMBankState");
    expect(txtIMBankState.tagName).toBe("INPUT");
    expect(txtIMBankState.type).toBe("text");
    expect(txtIMBankState.classList).toContain("textboxWidgetClass");
    expect(txtIMBankState.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtIMBankState"
      )
    );
    await act(async () => {
      userEvent.type(txtIMBankState, "1");
    });
  });
  test("Custom Test Cases for txtIMBankState", () => {
    // START_USER_CODE-USER_txtIMBankState_TEST
    // END_USER_CODE-USER_txtIMBankState_TEST
  });
  test("txtPayAddress1(Textbox Widget) Test Cases", async () => {
    const txtPayAddress1 = screen.getByTestId("txtPayAddress1");
    expect(txtPayAddress1.tagName).toBe("INPUT");
    expect(txtPayAddress1.type).toBe("text");
    expect(txtPayAddress1.classList).toContain("textboxWidgetClass");
    expect(txtPayAddress1.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtPayAddress1"
      )
    );
    await act(async () => {
      userEvent.type(txtPayAddress1, "1");
    });
  });
  test("Custom Test Cases for txtPayAddress1", () => {
    // START_USER_CODE-USER_txtPayAddress1_TEST
    // END_USER_CODE-USER_txtPayAddress1_TEST
  });
  test("txtPayAddress2(Textbox Widget) Test Cases", async () => {
    const txtPayAddress2 = screen.getByTestId("txtPayAddress2");
    expect(txtPayAddress2.tagName).toBe("INPUT");
    expect(txtPayAddress2.type).toBe("text");
    expect(txtPayAddress2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPayAddress2, "1");
    });
  });
  test("Custom Test Cases for txtPayAddress2", () => {
    // START_USER_CODE-USER_txtPayAddress2_TEST
    // END_USER_CODE-USER_txtPayAddress2_TEST
  });
  test("txtPayCity(Textbox Widget) Test Cases", async () => {
    const txtPayCity = screen.getByTestId("txtPayCity");
    expect(txtPayCity.tagName).toBe("INPUT");
    expect(txtPayCity.type).toBe("text");
    expect(txtPayCity.classList).toContain("textboxWidgetClass");
    expect(txtPayCity.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtPayCity")
    );
    await act(async () => {
      userEvent.type(txtPayCity, "1");
    });
  });
  test("Custom Test Cases for txtPayCity", () => {
    // START_USER_CODE-USER_txtPayCity_TEST
    // END_USER_CODE-USER_txtPayCity_TEST
  });
  test("txtPayeeBank(Textbox Widget) Test Cases", async () => {
    const txtPayeeBank = screen.getByTestId("txtPayeeBank");
    expect(txtPayeeBank.tagName).toBe("INPUT");
    expect(txtPayeeBank.type).toBe("text");
    expect(txtPayeeBank.classList).toContain("textboxWidgetClass");
    expect(txtPayeeBank.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtPayeeBank"
      )
    );
    await act(async () => {
      userEvent.type(txtPayeeBank, "1");
    });
  });
  test("Custom Test Cases for txtPayeeBank", () => {
    // START_USER_CODE-USER_txtPayeeBank_TEST
    // END_USER_CODE-USER_txtPayeeBank_TEST
  });
  test("txtPayeeName(Textbox Widget) Test Cases", async () => {
    const txtPayeeName = screen.getByTestId("txtPayeeName");
    expect(txtPayeeName.tagName).toBe("INPUT");
    expect(txtPayeeName.type).toBe("text");
    expect(txtPayeeName.classList).toContain("textboxWidgetClass");
    expect(txtPayeeName.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtPayeeName"
      )
    );
    await act(async () => {
      userEvent.type(txtPayeeName, "1");
    });
  });
  test("Custom Test Cases for txtPayeeName", () => {
    // START_USER_CODE-USER_txtPayeeName_TEST
    // END_USER_CODE-USER_txtPayeeName_TEST
  });
  test("txtPayZip(Textbox Widget) Test Cases", async () => {
    const txtPayZip = screen.getByTestId("txtPayZip");
    expect(txtPayZip.tagName).toBe("INPUT");
    expect(txtPayZip.type).toBe("text");
    expect(txtPayZip.classList).toContain("textboxWidgetClass");
    expect(txtPayZip.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:VendorWireTransferSetup_txtPayZip")
    );
    await act(async () => {
      userEvent.type(txtPayZip, "1");
    });
  });
  test("Custom Test Cases for txtPayZip", () => {
    // START_USER_CODE-USER_txtPayZip_TEST
    // END_USER_CODE-USER_txtPayZip_TEST
  });
});
