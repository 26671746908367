/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
  getSelectedGridValue
} from "../../shared/WindowImports";

import "./WhouseReceiptStorage.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_WhouseReceiptStorage(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES
  let userid = sessionStorage.getItem("userid");
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "WhouseReceiptStorage",
    windowName: "WhouseReceiptStorage",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.WhouseReceiptStorage",
    // START_USER_CODE-USER_WhouseReceiptStorage_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Warehouse Receipt Storage",
      scrCode: "PN1090O",
    },
    // END_USER_CODE-USER_WhouseReceiptStorage_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "cmmndCntnrEditDelete",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "cmmndCntnrEditDelete",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    cmmndCntnrEditDelete: {
      name: "cmmndCntnrEditDelete",
      type: "CommandContainerWidget",
      parent: "grpbxAddRemoveDetails",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrEditDelete_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrEditDelete_PROPERTIES
    },
    colDaysPaidFor: {
      name: "colDaysPaidFor",
      type: "GridColumnWidget",
      parent: "gridStrgDetails",
      Label: "Days Paid For",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDaysPaidFor_PROPERTIES

      // END_USER_CODE-USER_colDaysPaidFor_PROPERTIES
    },
    colPostingDate: {
      name: "colPostingDate",
      type: "GridColumnWidget",
      parent: "gridStrgDetails",
      Label: "Posting Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPostingDate_PROPERTIES

      // END_USER_CODE-USER_colPostingDate_PROPERTIES
    },
    colStrgPayAmt: {
      name: "colStrgPayAmt",
      type: "GridColumnWidget",
      parent: "gridStrgDetails",
      Label: "Storage Payment Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStrgPayAmt_PROPERTIES

      // END_USER_CODE-USER_colStrgPayAmt_PROPERTIES
    },
    gridStrgDetails: {
      name: "gridStrgDetails",
      type: "GridWidget",
      parent: "grpbxStrgDetails",
      gridCellsOrder: "txtcolPostingDate,txtcolDaysPaidFor,txtcolStrgPayAmt",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridStrgDetails_PROPERTIES

      // END_USER_CODE-USER_gridStrgDetails_PROPERTIES
    },
    grpbxAddRemoveDetails: {
      name: "grpbxAddRemoveDetails",
      type: "GroupBoxWidget",
      parent: "grpbxWhouseReceiptStorage",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxAddRemoveDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxAddRemoveDetails_PROPERTIES
    },
    grpbxDetails: {
      name: "grpbxDetails",
      type: "GroupBoxWidget",
      parent: "grpbxWhouseReceiptStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails_PROPERTIES
    },
    grpbxStrgDetails: {
      name: "grpbxStrgDetails",
      type: "GroupBoxWidget",
      parent: "grpbxWhouseReceiptStorage",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxStrgDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgDetails_PROPERTIES
    },
    lblAddRemoveDetails: {
      name: "lblAddRemoveDetails",
      type: "LabelWidget",
      parent: "grpbxAddRemoveDetails",
      Label: "Add / Remove Details",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddRemoveDetails_PROPERTIES

      // END_USER_CODE-USER_lblAddRemoveDetails_PROPERTIES
    },
    lblDetails: {
      name: "lblDetails",
      type: "LabelWidget",
      parent: "grpbxDetails",
      Label: "Details",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDetails_PROPERTIES

      // END_USER_CODE-USER_lblDetails_PROPERTIES
    },
    txtBuyPtID: {
      name: "txtBuyPtID",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Buy Pt ID",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyPtID_PROPERTIES

      // END_USER_CODE-USER_txtBuyPtID_PROPERTIES
    },
    txtcolDaysPaidFor: {
      name: "txtcolDaysPaidFor",
      type: "TextBoxWidget",
      colName: "colDaysPaidFor",
      parent: "gridStrgDetails",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDaysPaidFor_PROPERTIES

      // END_USER_CODE-USER_txtcolDaysPaidFor_PROPERTIES
    },
    txtcolPostingDate: {
      name: "txtcolPostingDate",
      type: "TextBoxWidget",
      colName: "colPostingDate",
      parent: "gridStrgDetails",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPostingDate_PROPERTIES

      // END_USER_CODE-USER_txtcolPostingDate_PROPERTIES
    },
    txtcolStrgPayAmt: {
      name: "txtcolStrgPayAmt",
      type: "TextBoxWidget",
      colName: "colStrgPayAmt",
      parent: "gridStrgDetails",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStrgPayAmt_PROPERTIES

      // END_USER_CODE-USER_txtcolStrgPayAmt_PROPERTIES
    },
    txtCompID: {
      name: "txtCompID",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Comp ID",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCompID_PROPERTIES

      // END_USER_CODE-USER_txtCompID_PROPERTIES
    },
    txtCropYear: {
      name: "txtCropYear",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Crop Year",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCropYear_PROPERTIES

      // END_USER_CODE-USER_txtCropYear_PROPERTIES
    },
    txtDaysPaidFor: {
      name: "txtDaysPaidFor",
      type: "TextBoxWidget",
      parent: "grpbxAddRemoveDetails",
      Label: "Days Paid For:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDaysPaidFor_PROPERTIES

      // END_USER_CODE-USER_txtDaysPaidFor_PROPERTIES
    },
    txtPostingDate: {
      name: "txtPostingDate",
      type: "DateWidget",
      parent: "grpbxAddRemoveDetails",
      Label: "Posting Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtPostingDate_PROPERTIES

      // END_USER_CODE-USER_txtPostingDate_PROPERTIES
    },
    txtStoragePayment: {
      name: "txtStoragePayment",
      type: "TextBoxWidget",
      parent: "grpbxAddRemoveDetails",
      Label: "Storage Payment:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStoragePayment_PROPERTIES

      // END_USER_CODE-USER_txtStoragePayment_PROPERTIES
    },
    txtTotalAmt: {
      name: "txtTotalAmt",
      type: "TextBoxWidget",
      parent: "grpbxStrgDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalAmt_PROPERTIES
      Enabled: false
      // END_USER_CODE-USER_txtTotalAmt_PROPERTIES
    },
    txtTotalDays: {
      name: "txtTotalDays",
      type: "TextBoxWidget",
      parent: "grpbxStrgDetails",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDays_PROPERTIES

      // END_USER_CODE-USER_txtTotalDays_PROPERTIES
    },
    txtWhouseRcptNum: {
      name: "txtWhouseRcptNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Whouse Rcpt Num:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhouseRcptNum_PROPERTIES

      // END_USER_CODE-USER_txtWhouseRcptNum_PROPERTIES
    },
    grpbxWhouseReceiptStorage: {
      name: "grpbxWhouseReceiptStorage",
      type: "GroupBoxWidget",
      parent: "WhouseReceiptStorage",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxWhouseReceiptStorage_PROPERTIES

      // END_USER_CODE-USER_grpbxWhouseReceiptStorage_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "WhouseReceiptStorage",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad()
    setData(thisObj,"WhouseReceiptStorageForViewScreen",true)//need flag to ViewWhouseReceipt screen
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // Form Load functionality
  const formLoad = () => {
    bFillFormFunction()
    setLoading(false);
  }
  
  let frmWhouseRceiptStorageLoadData = getData(thisObj, 'frmWhouseRceiptStorageLoadData')// Getting data from parent screen.

  // START_USER_CODE-USER_METHODS
  let bFillForm = false
  let bFormValidate = false

  function trimValue(num) {//Used trim Function to make values with decimal.
    try {
      if (num == undefined)
        num = 0
      let s = Number(num).toString().split('').reverse().join("")
      return s.split('').reverse().join("")
    }
    catch (err) {
      errorHandler(err)
      return num
    }
  }

  const bFillFormFunction = async () => {// Form Load Grid Binding Functionality.
    try {
      setLoading(true)
      setValue(thisObj, "txtCompID", frmWhouseRceiptStorageLoadData?.companyId)
      setValue(thisObj, "txtCropYear", frmWhouseRceiptStorageLoadData?.cropyear)
      setValue(thisObj, "txtBuyPtID", frmWhouseRceiptStorageLoadData?.lblBuyingPtId)
      setValue(thisObj, "txtWhouseRcptNum", frmWhouseRceiptStorageLoadData?.lblWRNum)

      let TotalStorageAmt = 0
      let TotalDaysPaid = 0

      let LstrList = await WarehouseReceiptService.RetrieveStoragePaidList(frmWhouseRceiptStorageLoadData?.lblBuyingPtId, frmWhouseRceiptStorageLoadData?.lblWRNum, null)

      if (LstrList != undefined) {
        let js = [];
        if (LstrList.length > 0) {
          for (var i = 0; i < LstrList.length; i++) {
            let obj = {}
            if (LstrList[i].storage_payment != "") {
              if (!isNaN(LstrList[i].storage_payment)) {
                TotalStorageAmt = Number(TotalStorageAmt) + Number(LstrList[i].storage_payment)
              }
              if (!isNaN(LstrList[i].days_paid_for)) {
                TotalDaysPaid = Number(TotalDaysPaid) + Number(LstrList[i].days_paid_for)
              }
              if ((LstrList[i].posting_date).split(' ')[1] == "12:00:00") {
                obj.txtcolPostingDate = moment((LstrList[i].posting_date).toString()).format('M/D/YYYY')
              }
              else {
                obj.txtcolPostingDate = LstrList[i].posting_date;
              }
              obj.txtcolDaysPaidFor = LstrList[i].days_paid_for;
              obj.txtcolStrgPayAmt = trimValue(LstrList[i].storage_payment)
            }
            js.push(obj)
          }
          setValue(thisObj, "gridStrgDetails", js)
          setValue(thisObj, "txtTotalAmt", trimValue(TotalStorageAmt))
          setValue(thisObj, "txtTotalDays", trimValue(TotalDaysPaid))

          bFillForm = true
          setLoading(false);
        }
        else {
          bFillForm = false
          setValue(thisObj, "gridStrgDetails", js)
          setValue(thisObj, "txtTotalAmt", "0")
          setValue(thisObj, "txtTotalDays", "0")
          setLoading(false);
        }
      }

    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on bFillFormFunction"
        );
      }
      return false;
    }
    return true;
  };

  const bFormValidateFunction = async () => {//Validation On Add Btn Functionality.
    try {
      bFormValidate = false
      if (thisObj.values.txtPostingDate === '' || thisObj.values.txtPostingDate === undefined) {
        alert("Enter Valid Date")
        return
      }
      if ((getValue(thisObj, "txtDaysPaidFor") == "") || (getValue(thisObj, "txtDaysPaidFor") == undefined) || (isNaN(getValue(thisObj, "txtDaysPaidFor")))) {
        alert("Day Paid For is a required field.")
        setValue(thisObj, "txtDaysPaidFor", "")
        document.getElementById("txtDaysPaidFor").focus();
        return
      }
      if ((getValue(thisObj, "txtStoragePayment") == "") || (getValue(thisObj, "txtStoragePayment") == undefined) || (isNaN(getValue(thisObj, "txtStoragePayment")))) {
        alert("Storage Payment is a required field.")
        setValue(thisObj, "txtStoragePayment", "")
        document.getElementById("txtStoragePayment").focus();
        return
      }
      bFormValidate = true
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on bFormValidate"
        );
      }
      return false;
    }
    return true;
  };

  const onbtnAddClick = async () => {//Add Btn Functionality.
    try {
      if (!await bFormValidateFunction()) {
        return;
      }
      let dateFormt = moment(thisObj.values.txtPostingDate["_d"].toISOString()).format('MM/DD/YYYY')
      let dataObj = {
        posting_date: dateFormt,
        storage_payment: getValue(thisObj, "txtStoragePayment"),
        days_paid_for: getValue(thisObj, "txtDaysPaidFor"),
        user_id: userid
      }
      let wareHouseReceiptNumber = getValue(thisObj, "txtWhouseRcptNum")
      let BuyingPointId = getValue(thisObj, "txtBuyPtID")
      let response = await WarehouseReceiptService.UpdateStoragePaid(wareHouseReceiptNumber, BuyingPointId, dataObj)
      if (response.status != 200) {
        alert("An error occured while communicating or updating with the database. Please contact your system administrator if this problem persists." + "\n" + "Error:" + "\n"  + response.message )
        return
      }
      else {
        bFillFormFunction()
        setValue(thisObj, "txtDaysPaidFor", "")
        setValue(thisObj, "txtStoragePayment", "")
        setValue(thisObj, "txtPostingDate", "")
        return;
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnAddClick click event"
        );
      }
    }
    return true
  }
  thisObj.onbtnAddClick = onbtnAddClick;

  const onbtnDeleteClick = async () => {//Delete Btn Functionality.
    try {
      let value = thisObj.state.gridStrgDetails.selected[0];
      var wareHouseReceiptNumber = getValue(thisObj, "txtWhouseRcptNum")
      var BuyingPointId = getValue(thisObj, "txtBuyPtID")
      if (value != undefined) {
        var selectedRowValue = getSelectedGridValue(thisObj, "gridStrgDetails", "txtcolPostingDate");
        var NewDate = new Date(new Date(selectedRowValue).getTime() - (new Date(selectedRowValue).getTimezoneOffset() * 60000)).toISOString().slice(0, 23).replace('T', ' ')
        let resp = await WarehouseReceiptService.RemoveStoragePaid(wareHouseReceiptNumber, BuyingPointId, NewDate)
        if (resp.status != 200) {
          alert(resp.message)
        }
        else {
          bFillFormFunction()
          setValue(thisObj, "txtDaysPaidFor", "")
          setValue(thisObj, "txtStoragePayment", "")
          setValue(thisObj, "txtPostingDate", "")
          return;
        }
      }
      else {
        alert("An error occured while communicating or updating with the database. Please contact your system administrator if this problem persists." + "\n" + "Error: Conversion failed when converting date and /or time from character string.")
        return
      }
    } catch (err) {

      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnDeleteClick click event"
        );
      }
    }
    return true
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;


  const onbtnCloseClick = async () => {//Close Btn Functioanlity.
    try {
      if (document.getElementById("WarehouseReceipts_WhouseReceiptStoragePopUp") != null) {
        document.getElementById("WarehouseReceipts_WhouseReceiptStoragePopUp").childNodes[0].click();
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnCloseClick click event"
        );
      }
    }
    return true
  }
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_WhouseReceiptStorage*/}

              {/* END_USER_CODE-USER_BEFORE_WhouseReceiptStorage*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxWhouseReceiptStorage*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxWhouseReceiptStorage*/}

              <GroupBoxWidget
                conf={state.grpbxWhouseReceiptStorage}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails*/}

                <GroupBoxWidget conf={state.grpbxDetails} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblDetails*/}

                  {/* END_USER_CODE-USER_BEFORE_lblDetails*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblDetails}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblDetails*/}

                  {/* END_USER_CODE-USER_AFTER_lblDetails*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCompID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCompID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCompID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCompID*/}

                  {/* END_USER_CODE-USER_AFTER_txtCompID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCropYear*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCropYear*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCropYear}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCropYear*/}

                  {/* END_USER_CODE-USER_AFTER_txtCropYear*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBuyPtID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyPtID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyPtID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyPtID*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyPtID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWhouseRcptNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWhouseRcptNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWhouseRcptNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWhouseRcptNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtWhouseRcptNum*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxAddRemoveDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxAddRemoveDetails*/}

                <GroupBoxWidget
                  conf={state.grpbxAddRemoveDetails}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblAddRemoveDetails*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAddRemoveDetails*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAddRemoveDetails}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAddRemoveDetails*/}

                  {/* END_USER_CODE-USER_AFTER_lblAddRemoveDetails*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPostingDate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPostingDate*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPostingDate}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPostingDate*/}

                  {/* END_USER_CODE-USER_AFTER_txtPostingDate*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDaysPaidFor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDaysPaidFor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDaysPaidFor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDaysPaidFor*/}

                  {/* END_USER_CODE-USER_AFTER_txtDaysPaidFor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtStoragePayment*/}

                  {/* END_USER_CODE-USER_BEFORE_txtStoragePayment*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtStoragePayment}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtStoragePayment*/}

                  {/* END_USER_CODE-USER_AFTER_txtStoragePayment*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_cmmndCntnrEditDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_cmmndCntnrEditDelete*/}

                  <CommandContainerWidget
                    conf={state.cmmndCntnrEditDelete}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                    {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                    <ButtonWidget
                      conf={state.btnAdd}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                    {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                    {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                    {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                    <ButtonWidget
                      conf={state.btnDelete}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                    {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                  </CommandContainerWidget>
                  {/* START_USER_CODE-USER_AFTER_cmmndCntnrEditDelete*/}

                  {/* END_USER_CODE-USER_AFTER_cmmndCntnrEditDelete*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxAddRemoveDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxAddRemoveDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxStrgDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxStrgDetails*/}

                <GroupBoxWidget
                  conf={state.grpbxStrgDetails}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_gridStrgDetails*/}

                  {/* END_USER_CODE-USER_BEFORE_gridStrgDetails*/}

                  <GridWidget
                    conf={state.gridStrgDetails}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridStrgDetails}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridStrgDetails*/}

                  {/* END_USER_CODE-USER_AFTER_gridStrgDetails*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalDays*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalDays*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalDays}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalDays*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalDays*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalAmt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalAmt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalAmt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalAmt*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalAmt*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxStrgDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxStrgDetails*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxWhouseReceiptStorage*/}

              {/* END_USER_CODE-USER_AFTER_grpbxWhouseReceiptStorage*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_WhouseReceiptStorage*/}

              {/* END_USER_CODE-USER_AFTER_WhouseReceiptStorage*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_WhouseReceiptStorage);
