/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_ManualRTPHistSearch from "./ManualRTPHistSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ManualRTPHistSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualRTPHistSearch />
      );
    });
  });
  afterEach(cleanup);
  test("is ManualRTPHistSearch Loads Successfully", () => {
    expect(screen.getByText("ManualRTPHistSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for ManualRTPHistSearch", () => {
    // START_USER_CODE-USER_ManualRTPHistSearch_Custom_Test_Case
    // END_USER_CODE-USER_ManualRTPHistSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualRTPHistSearch />
      );
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistSearch_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("btnView(Button Widget) Test Cases", async () => {
    const btnView = screen.getByTestId("btnView");
    expect(btnView).toBeInTheDocument;
    expect(btnView.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistSearch_btnView")
    );
  });
  test("Custom Test Cases for btnView", () => {
    // START_USER_CODE-USER_btnView_TEST
    // END_USER_CODE-USER_btnView_TEST
  });
  test("gridManualRTPHist(Grid Widget) Test Cases", async () => {
    let gridManualRTPHist = screen.getByTestId("gridManualRTPHist");
    let gridManualRTPHistbtn =
      gridManualRTPHist.nextElementSibling.firstElementChild;
    gridManualRTPHist =
      gridManualRTPHist.parentElement.parentElement.parentElement;
    expect(gridManualRTPHist.tagName).toBe("DIV");
    expect(gridManualRTPHist.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_gridManualRTPHist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridManualRTPHist", () => {
    // START_USER_CODE-USER_gridManualRTPHist_TEST
    // END_USER_CODE-USER_gridManualRTPHist_TEST
  });
  test("grpbxManualRTPHistSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxManualRTPHistSearch = screen.getByTestId(
      "grpbxManualRTPHistSearch"
    );
    expect(grpbxManualRTPHistSearch.tagName).toBe("BUTTON");
    expect(grpbxManualRTPHistSearch.type).toBe("button");
    expect(grpbxManualRTPHistSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxManualRTPHistSearch", () => {
    // START_USER_CODE-USER_grpbxManualRTPHistSearch_TEST
    // END_USER_CODE-USER_grpbxManualRTPHistSearch_TEST
  });
  test("gridManualRTPHist_txtcolAction(Grid Widget) Test Cases", async () => {
    let gridManualRTPHist_txtcolAction = screen.getByTestId(
      "gridManualRTPHist"
    );
    let gridManualRTPHist_txtcolActionbtn =
      gridManualRTPHist_txtcolAction.nextElementSibling
        .firstElementChild;
    gridManualRTPHist_txtcolAction =
      gridManualRTPHist_txtcolAction.parentElement.parentElement
        .parentElement;
    expect(gridManualRTPHist_txtcolAction.tagName).toBe("DIV");
    expect(gridManualRTPHist_txtcolAction.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_gridManualRTPHist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAction", () => {
    // START_USER_CODE-USER_txtcolAction_TEST
    // END_USER_CODE-USER_txtcolAction_TEST
  });
  test("gridManualRTPHist_txtcolCounty(Grid Widget) Test Cases", async () => {
    let gridManualRTPHist_txtcolCounty = screen.getByTestId(
      "gridManualRTPHist"
    );
    let gridManualRTPHist_txtcolCountybtn =
      gridManualRTPHist_txtcolCounty.nextElementSibling
        .firstElementChild;
    gridManualRTPHist_txtcolCounty =
      gridManualRTPHist_txtcolCounty.parentElement.parentElement
        .parentElement;
    expect(gridManualRTPHist_txtcolCounty.tagName).toBe("DIV");
    expect(gridManualRTPHist_txtcolCounty.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_gridManualRTPHist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCounty", () => {
    // START_USER_CODE-USER_txtcolCounty_TEST
    // END_USER_CODE-USER_txtcolCounty_TEST
  });
  test("gridManualRTPHist_txtcolDate(Grid Widget) Test Cases", async () => {
    let gridManualRTPHist_txtcolDate = screen.getByTestId(
      "gridManualRTPHist"
    );
    let gridManualRTPHist_txtcolDatebtn =
      gridManualRTPHist_txtcolDate.nextElementSibling.firstElementChild;
    gridManualRTPHist_txtcolDate =
      gridManualRTPHist_txtcolDate.parentElement.parentElement
        .parentElement;
    expect(gridManualRTPHist_txtcolDate.tagName).toBe("DIV");
    expect(gridManualRTPHist_txtcolDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_gridManualRTPHist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDate", () => {
    // START_USER_CODE-USER_txtcolDate_TEST
    // END_USER_CODE-USER_txtcolDate_TEST
  });
  test("gridManualRTPHist_txtcolFarm(Grid Widget) Test Cases", async () => {
    let gridManualRTPHist_txtcolFarm = screen.getByTestId(
      "gridManualRTPHist"
    );
    let gridManualRTPHist_txtcolFarmbtn =
      gridManualRTPHist_txtcolFarm.nextElementSibling.firstElementChild;
    gridManualRTPHist_txtcolFarm =
      gridManualRTPHist_txtcolFarm.parentElement.parentElement
        .parentElement;
    expect(gridManualRTPHist_txtcolFarm.tagName).toBe("DIV");
    expect(gridManualRTPHist_txtcolFarm.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_gridManualRTPHist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFarm", () => {
    // START_USER_CODE-USER_txtcolFarm_TEST
    // END_USER_CODE-USER_txtcolFarm_TEST
  });
  test("gridManualRTPHist_txtcolNumber1(Grid Widget) Test Cases", async () => {
    let gridManualRTPHist_txtcolNumber1 = screen.getByTestId(
      "gridManualRTPHist"
    );
    let gridManualRTPHist_txtcolNumber1btn =
      gridManualRTPHist_txtcolNumber1.nextElementSibling
        .firstElementChild;
    gridManualRTPHist_txtcolNumber1 =
      gridManualRTPHist_txtcolNumber1.parentElement.parentElement
        .parentElement;
    expect(gridManualRTPHist_txtcolNumber1.tagName).toBe("DIV");
    expect(gridManualRTPHist_txtcolNumber1.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_gridManualRTPHist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber1", () => {
    // START_USER_CODE-USER_txtcolNumber1_TEST
    // END_USER_CODE-USER_txtcolNumber1_TEST
  });
  test("gridManualRTPHist_txtcolNumber2(Grid Widget) Test Cases", async () => {
    let gridManualRTPHist_txtcolNumber2 = screen.getByTestId(
      "gridManualRTPHist"
    );
    let gridManualRTPHist_txtcolNumber2btn =
      gridManualRTPHist_txtcolNumber2.nextElementSibling
        .firstElementChild;
    gridManualRTPHist_txtcolNumber2 =
      gridManualRTPHist_txtcolNumber2.parentElement.parentElement
        .parentElement;
    expect(gridManualRTPHist_txtcolNumber2.tagName).toBe("DIV");
    expect(gridManualRTPHist_txtcolNumber2.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_gridManualRTPHist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNumber2", () => {
    // START_USER_CODE-USER_txtcolNumber2_TEST
    // END_USER_CODE-USER_txtcolNumber2_TEST
  });
  test("gridManualRTPHist_txtcolOpt(Grid Widget) Test Cases", async () => {
    let gridManualRTPHist_txtcolOpt = screen.getByTestId(
      "gridManualRTPHist"
    );
    let gridManualRTPHist_txtcolOptbtn =
      gridManualRTPHist_txtcolOpt.nextElementSibling.firstElementChild;
    gridManualRTPHist_txtcolOpt =
      gridManualRTPHist_txtcolOpt.parentElement.parentElement
        .parentElement;
    expect(gridManualRTPHist_txtcolOpt.tagName).toBe("DIV");
    expect(gridManualRTPHist_txtcolOpt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_gridManualRTPHist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpt", () => {
    // START_USER_CODE-USER_txtcolOpt_TEST
    // END_USER_CODE-USER_txtcolOpt_TEST
  });
  test("gridManualRTPHist_txtcolPayAmount(Grid Widget) Test Cases", async () => {
    let gridManualRTPHist_txtcolPayAmount = screen.getByTestId(
      "gridManualRTPHist"
    );
    let gridManualRTPHist_txtcolPayAmountbtn =
      gridManualRTPHist_txtcolPayAmount.nextElementSibling
        .firstElementChild;
    gridManualRTPHist_txtcolPayAmount =
      gridManualRTPHist_txtcolPayAmount.parentElement.parentElement
        .parentElement;
    expect(gridManualRTPHist_txtcolPayAmount.tagName).toBe("DIV");
    expect(gridManualRTPHist_txtcolPayAmount.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_gridManualRTPHist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPayAmount", () => {
    // START_USER_CODE-USER_txtcolPayAmount_TEST
    // END_USER_CODE-USER_txtcolPayAmount_TEST
  });
  test("gridManualRTPHist_txtcolState(Grid Widget) Test Cases", async () => {
    let gridManualRTPHist_txtcolState = screen.getByTestId(
      "gridManualRTPHist"
    );
    let gridManualRTPHist_txtcolStatebtn =
      gridManualRTPHist_txtcolState.nextElementSibling
        .firstElementChild;
    gridManualRTPHist_txtcolState =
      gridManualRTPHist_txtcolState.parentElement.parentElement
        .parentElement;
    expect(gridManualRTPHist_txtcolState.tagName).toBe("DIV");
    expect(gridManualRTPHist_txtcolState.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_gridManualRTPHist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolState", () => {
    // START_USER_CODE-USER_txtcolState_TEST
    // END_USER_CODE-USER_txtcolState_TEST
  });
  test("gridManualRTPHist_txtcolType1(Grid Widget) Test Cases", async () => {
    let gridManualRTPHist_txtcolType1 = screen.getByTestId(
      "gridManualRTPHist"
    );
    let gridManualRTPHist_txtcolType1btn =
      gridManualRTPHist_txtcolType1.nextElementSibling
        .firstElementChild;
    gridManualRTPHist_txtcolType1 =
      gridManualRTPHist_txtcolType1.parentElement.parentElement
        .parentElement;
    expect(gridManualRTPHist_txtcolType1.tagName).toBe("DIV");
    expect(gridManualRTPHist_txtcolType1.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_gridManualRTPHist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType1", () => {
    // START_USER_CODE-USER_txtcolType1_TEST
    // END_USER_CODE-USER_txtcolType1_TEST
  });
  test("gridManualRTPHist_txtcolType2(Grid Widget) Test Cases", async () => {
    let gridManualRTPHist_txtcolType2 = screen.getByTestId(
      "gridManualRTPHist"
    );
    let gridManualRTPHist_txtcolType2btn =
      gridManualRTPHist_txtcolType2.nextElementSibling
        .firstElementChild;
    gridManualRTPHist_txtcolType2 =
      gridManualRTPHist_txtcolType2.parentElement.parentElement
        .parentElement;
    expect(gridManualRTPHist_txtcolType2.tagName).toBe("DIV");
    expect(gridManualRTPHist_txtcolType2.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_gridManualRTPHist"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType2", () => {
    // START_USER_CODE-USER_txtcolType2_TEST
    // END_USER_CODE-USER_txtcolType2_TEST
  });
  test("txtContract(Textbox Widget) Test Cases", async () => {
    const txtContract = screen.getByTestId("txtContract");
    expect(txtContract.tagName).toBe("INPUT");
    expect(txtContract.type).toBe("text");
    expect(txtContract.classList).toContain("textboxWidgetClass");
    expect(txtContract.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_txtContract"
      )
    );
    await act(async () => {
      userEvent.type(txtContract, "1");
    });
  });
  test("Custom Test Cases for txtContract", () => {
    // START_USER_CODE-USER_txtContract_TEST
    // END_USER_CODE-USER_txtContract_TEST
  });
  test("txtDeliveryAgreement(Textbox Widget) Test Cases", async () => {
    const txtDeliveryAgreement = screen.getByTestId(
      "txtDeliveryAgreement"
    );
    expect(txtDeliveryAgreement.tagName).toBe("INPUT");
    expect(txtDeliveryAgreement.type).toBe("text");
    expect(txtDeliveryAgreement.classList).toContain("textboxWidgetClass");
    expect(txtDeliveryAgreement.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_txtDeliveryAgreement"
      )
    );
    await act(async () => {
      userEvent.type(txtDeliveryAgreement, "1");
    });
  });
  test("Custom Test Cases for txtDeliveryAgreement", () => {
    // START_USER_CODE-USER_txtDeliveryAgreement_TEST
    // END_USER_CODE-USER_txtDeliveryAgreement_TEST
  });
  test("txtFarmSuffix(Textbox Widget) Test Cases", async () => {
    const txtFarmSuffix = screen.getByTestId("txtFarmSuffix");
    expect(txtFarmSuffix.tagName).toBe("INPUT");
    expect(txtFarmSuffix.type).toBe("text");
    expect(txtFarmSuffix.classList).toContain("textboxWidgetClass");
    expect(txtFarmSuffix.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_txtFarmSuffix"
      )
    );
    await act(async () => {
      userEvent.type(txtFarmSuffix, "1");
    });
  });
  test("Custom Test Cases for txtFarmSuffix", () => {
    // START_USER_CODE-USER_txtFarmSuffix_TEST
    // END_USER_CODE-USER_txtFarmSuffix_TEST
  });
  test("txtFarm(Textbox Widget) Test Cases", async () => {
    const txtFarm = screen.getByTestId("txtFarm");
    expect(txtFarm.tagName).toBe("INPUT");
    expect(txtFarm.type).toBe("text");
    expect(txtFarm.classList).toContain("textboxWidgetClass");
    expect(txtFarm.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistSearch_txtFarm")
    );
    await act(async () => {
      userEvent.type(txtFarm, "1");
    });
  });
  test("Custom Test Cases for txtFarm", () => {
    // START_USER_CODE-USER_txtFarm_TEST
    // END_USER_CODE-USER_txtFarm_TEST
  });
  test("txtFreightMemo(Textbox Widget) Test Cases", async () => {
    const txtFreightMemo = screen.getByTestId("txtFreightMemo");
    expect(txtFreightMemo.tagName).toBe("INPUT");
    expect(txtFreightMemo.type).toBe("text");
    expect(txtFreightMemo.classList).toContain("textboxWidgetClass");
    expect(txtFreightMemo.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_txtFreightMemo"
      )
    );
    await act(async () => {
      userEvent.type(txtFreightMemo, "1");
    });
  });
  test("Custom Test Cases for txtFreightMemo", () => {
    // START_USER_CODE-USER_txtFreightMemo_TEST
    // END_USER_CODE-USER_txtFreightMemo_TEST
  });
  test("txtProceeds(Textbox Widget) Test Cases", async () => {
    const txtProceeds = screen.getByTestId("txtProceeds");
    expect(txtProceeds.tagName).toBe("INPUT");
    expect(txtProceeds.type).toBe("text");
    expect(txtProceeds.classList).toContain("textboxWidgetClass");
    expect(txtProceeds.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_txtProceeds"
      )
    );
    await act(async () => {
      userEvent.type(txtProceeds, "1");
    });
  });
  test("Custom Test Cases for txtProceeds", () => {
    // START_USER_CODE-USER_txtProceeds_TEST
    // END_USER_CODE-USER_txtProceeds_TEST
  });
  test("txtSC95(Textbox Widget) Test Cases", async () => {
    const txtSC95 = screen.getByTestId("txtSC95");
    expect(txtSC95.tagName).toBe("INPUT");
    expect(txtSC95.type).toBe("text");
    expect(txtSC95.classList).toContain("textboxWidgetClass");
    expect(txtSC95.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistSearch_txtSC95")
    );
    await act(async () => {
      userEvent.type(txtSC95, "1");
    });
  });
  test("Custom Test Cases for txtSC95", () => {
    // START_USER_CODE-USER_txtSC95_TEST
    // END_USER_CODE-USER_txtSC95_TEST
  });
  test("txtSettlement1007(Textbox Widget) Test Cases", async () => {
    const txtSettlement1007 = screen.getByTestId("txtSettlement1007");
    expect(txtSettlement1007.tagName).toBe("INPUT");
    expect(txtSettlement1007.type).toBe("text");
    expect(txtSettlement1007.classList).toContain("textboxWidgetClass");
    expect(txtSettlement1007.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_txtSettlement1007"
      )
    );
    await act(async () => {
      userEvent.type(txtSettlement1007, "1");
    });
  });
  test("Custom Test Cases for txtSettlement1007", () => {
    // START_USER_CODE-USER_txtSettlement1007_TEST
    // END_USER_CODE-USER_txtSettlement1007_TEST
  });
  test("txtTotalProceeds(Textbox Widget) Test Cases", async () => {
    const txtTotalProceeds = screen.getByTestId("txtTotalProceeds");
    expect(txtTotalProceeds.tagName).toBe("INPUT");
    expect(txtTotalProceeds.type).toBe("text");
    expect(txtTotalProceeds.classList).toContain("textboxWidgetClass");
    expect(txtTotalProceeds.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_txtTotalProceeds"
      )
    );
    await act(async () => {
      userEvent.type(txtTotalProceeds, "1");
    });
  });
  test("Custom Test Cases for txtTotalProceeds", () => {
    // START_USER_CODE-USER_txtTotalProceeds_TEST
    // END_USER_CODE-USER_txtTotalProceeds_TEST
  });
  test("txtTradeInspection(Textbox Widget) Test Cases", async () => {
    const txtTradeInspection = screen.getByTestId("txtTradeInspection");
    expect(txtTradeInspection.tagName).toBe("INPUT");
    expect(txtTradeInspection.type).toBe("text");
    expect(txtTradeInspection.classList).toContain("textboxWidgetClass");
    expect(txtTradeInspection.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_txtTradeInspection"
      )
    );
    await act(async () => {
      userEvent.type(txtTradeInspection, "1");
    });
  });
  test("Custom Test Cases for txtTradeInspection", () => {
    // START_USER_CODE-USER_txtTradeInspection_TEST
    // END_USER_CODE-USER_txtTradeInspection_TEST
  });
  test("txtTradeSettlement(Textbox Widget) Test Cases", async () => {
    const txtTradeSettlement = screen.getByTestId("txtTradeSettlement");
    expect(txtTradeSettlement.tagName).toBe("INPUT");
    expect(txtTradeSettlement.type).toBe("text");
    expect(txtTradeSettlement.classList).toContain("textboxWidgetClass");
    expect(txtTradeSettlement.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_txtTradeSettlement"
      )
    );
    await act(async () => {
      userEvent.type(txtTradeSettlement, "1");
    });
  });
  test("Custom Test Cases for txtTradeSettlement", () => {
    // START_USER_CODE-USER_txtTradeSettlement_TEST
    // END_USER_CODE-USER_txtTradeSettlement_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistSearch_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVndr(Textbox Widget) Test Cases", async () => {
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVndr, "1");
    });
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
  test("txtWarehouseReceipt(Textbox Widget) Test Cases", async () => {
    const txtWarehouseReceipt = screen.getByTestId(
      "txtWarehouseReceipt"
    );
    expect(txtWarehouseReceipt.tagName).toBe("INPUT");
    expect(txtWarehouseReceipt.type).toBe("text");
    expect(txtWarehouseReceipt.classList).toContain("textboxWidgetClass");
    expect(txtWarehouseReceipt.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistSearch_txtWarehouseReceipt"
      )
    );
    await act(async () => {
      userEvent.type(txtWarehouseReceipt, "1");
    });
  });
  test("Custom Test Cases for txtWarehouseReceipt", () => {
    // START_USER_CODE-USER_txtWarehouseReceipt_TEST
    // END_USER_CODE-USER_txtWarehouseReceipt_TEST
  });
});
