/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  CheckboxWidget,
  getData,
  setData,
  getValue,
  setValue,
  goTo,
  disable,
  enable,
  hide,
  show,
  AutocompleteWidget,
  getSelectedRowNumber,
  isEnabled,
  clearValue,
  hideWidgets,
  showWidgets,
  setFieldValues,
  setGridValue,
  getGridValue,
  getRowCount,
  hideColumn
} from "../../shared/WindowImports";

import "./PriceScheduleSetup.scss";
import Loading from "../../../Loader/Loading";

import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService"
import { SettlementService } from "../../Settlements/Service/SettlementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceApplicationSupportService } from "../../SystemMaintenanceApplicationSupport/Service/SystemMaintenanceApplicationSupportService";
import DiscountScheduleSetup from "../DiscountScheduleSetup/DiscountScheduleSetup";
import { response } from "msw";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_PriceScheduleSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let mbFormDirty = false;
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PriceScheduleSetup",
    windowName: "PriceScheduleSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.PriceScheduleSetup",
    // START_USER_CODE-USER_PriceScheduleSetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Price Schedule Setup",
      scrCode: "PN0070A",
    },
    // END_USER_CODE-USER_PriceScheduleSetup_PROPERTIES
    btnAuditHistory: {
      name: "btnAuditHistory",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Audit History",
      CharWidth: "28",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAuditHistory_PROPERTIES

      // END_USER_CODE-USER_btnAuditHistory_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btncolDiscSchd: {
      name: "btncolDiscSchd",
      type: "ButtonWidget",
      colName: "colDiscSchd",
      parent: "gridPriceSchedules",
      Label: "...",
      CharWidth: "9",
      Height: "",
      Width: "",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncolDiscSchd_PROPERTIES

      // END_USER_CODE-USER_btncolDiscSchd_PROPERTIES
    },
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Save",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSave_PROPERTIES

      // END_USER_CODE-USER_btnSave_PROPERTIES
    },
    colArea: {
      name: "colArea",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "Area",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colArea_PROPERTIES

      // END_USER_CODE-USER_colArea_PROPERTIES
    },
    colEmpty1: {
      name: "colEmpty1",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEmpty1_PROPERTIES

      // END_USER_CODE-USER_colEmpty1_PROPERTIES
    },
    colEmpty2: {
      name: "colEmpty2",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEmpty2_PROPERTIES

      // END_USER_CODE-USER_colEmpty2_PROPERTIES
    },
    colCrkBrkDoolFailPeanut: {
      name: "colCrkBrkDoolFailPeanut",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "Crk/Brk Dool Fail Peanut",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCrkBrkDoolFailPeanut_PROPERTIES

      // END_USER_CODE-USER_colCrkBrkDoolFailPeanut_PROPERTIES
    },
    colCrkBrkDoolThreshld: {
      name: "colCrkBrkDoolThreshld",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "Crk/Brk Dool Threshld",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCrkBrkDoolThreshld_PROPERTIES

      // END_USER_CODE-USER_colCrkBrkDoolThreshld_PROPERTIES
    },
    colDiscSchd: {
      name: "colDiscSchd",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "Disc Schd",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDiscSchd_PROPERTIES

      // END_USER_CODE-USER_colDiscSchd_PROPERTIES
    },
    colElkPrem: {
      name: "colElkPrem",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "Elk Prem",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colElkPrem_PROPERTIES

      // END_USER_CODE-USER_colElkPrem_PROPERTIES
    },
    colFancyThreshld: {
      name: "colFancyThreshld",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "Fancy Threshld",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFancyThreshld_PROPERTIES

      // END_USER_CODE-USER_colFancyThreshld_PROPERTIES
    },
    colFanFailPnId: {
      name: "colFanFailPnId",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "Fan Fail Pn Id",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFanFailPnId_PROPERTIES

      // END_USER_CODE-USER_colFanFailPnId_PROPERTIES
    },
    colLSKPerLbs: {
      name: "colLSKPerLbs",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "LSK Per Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLSKPerLbs_PROPERTIES

      // END_USER_CODE-USER_colLSKPerLbs_PROPERTIES
    },
    colMaxMoisture: {
      name: "colMaxMoisture",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "Max Moisture",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMaxMoisture_PROPERTIES

      // END_USER_CODE-USER_colMaxMoisture_PROPERTIES
    },
    colMinFancyElkPrem: {
      name: "colMinFancyElkPrem",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "Minimum Fancy Elk Premium",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMinFancyElkPrem_PROPERTIES

      // END_USER_CODE-USER_colMinFancyElkPrem_PROPERTIES
    },
    colOkPerPct: {
      name: "colOkPerPct",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "Ok Per Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOkPerPct_PROPERTIES

      // END_USER_CODE-USER_colOkPerPct_PROPERTIES
    },
    colPeanutType: {
      name: "colPeanutType",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "Peanut Type:",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutType_PROPERTIES

      // END_USER_CODE-USER_colPeanutType_PROPERTIES
    },
    colSMKSSPerPercent: {
      name: "colSMKSSPerPercent",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "SMK SS Per Percent",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSMKSSPerPercent_PROPERTIES

      // END_USER_CODE-USER_colSMKSSPerPercent_PROPERTIES
    },
    colSupportPerTon: {
      name: "colSupportPerTon",
      type: "GridColumnWidget",
      parent: "gridPriceSchedules",
      Label: "Support Per Ton",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSupportPerTon_PROPERTIES

      // END_USER_CODE-USER_colSupportPerTon_PROPERTIES
    },
    gridPriceSchedules: {
      name: "gridPriceSchedules",
      type: "GridWidget",
      parent: "grpbxPriceScheduleSetup",
      gridCellsOrder:
        "txtcolArea,txtcolPeanuType,txtcolSupportPerTon,txtcolSMKSSPerPercent,txtcolOkPerPct,txtcolElkPrem,txtcolMinFancyElkPrem,txtcolLSKPerLbs,txtcolMaxMoisture,txtcolFancyThreshld,ddcolFanFailPnId,txtcolCrkBrkDoolThreshld,ddcolCrkBrkDoolFailPeanut,btncolDiscSchd,txtcolEmpty2",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      clonedExtId: "50597",
      isEditable: true,
      isCobolTable: true,
      toggleRender:true,
      // START_USER_CODE-USER_gridPriceSchedules_PROPERTIES
      conditionalRowStyles: [
        {
          when: (row) => { return checkForRowData(row) },
          style: {
            button :{
            display: "none",
             }
          }
        }
      ],
      // END_USER_CODE-USER_gridPriceSchedules_PROPERTIES
    },
    lblPriceSchedules: {
      name: "lblPriceSchedules",
      type: "LabelWidget",
      parent: "grpbxPriceScheduleSetup",
      Label: "Price Schedules:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPriceSchedules_PROPERTIES

      // END_USER_CODE-USER_lblPriceSchedules_PROPERTIES
    },
    txtcolArea: {
      name: "txtcolArea",
      type: "TextBoxWidget",
      colName: "colArea",
      parent: "gridPriceSchedules",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true

      // START_USER_CODE-USER_txtcolArea_PROPERTIES

      // END_USER_CODE-USER_txtcolArea_PROPERTIES
    },
    txtcolEmpty1: {
      name: "txtcolEmpty1",
      type: "TextBoxWidget",
      colName: "colEmpty1",
      parent: "gridPriceSchedules",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",


      // START_USER_CODE-USER_txtcolEmpty1_PROPERTIES
ReadOnly:true,
Enabled:false
      // END_USER_CODE-USER_txtcolEmpty1_PROPERTIES
    },
    txtcolEmpty2: {
      name: "txtcolEmpty2",
      type: "TextBoxWidget",
      colName: "colEmpty2",
      parent: "gridPriceSchedules",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      Visible:false
      // START_USER_CODE-USER_txtcolEmpty2_PROPERTIES

      // END_USER_CODE-USER_txtcolEmpty2_PROPERTIES
    },
    // txtcolCrkBrkDoolFailPeanut: {
    //   name: "txtcolCrkBrkDoolFailPeanut",
    //   type: "TextBoxWidget",
    //   colName: "colCrkBrkDoolFailPeanut",
    //   parent: "gridPriceSchedules",
    //   CharWidth: "48",
    //   Height: "",
    //   Width: "",
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtcolCrkBrkDoolFailPeanut_PROPERTIES

    //   // END_USER_CODE-USER_txtcolCrkBrkDoolFailPeanut_PROPERTIES
    // },
    ddcolCrkBrkDoolFailPeanut: {
      name: "ddcolCrkBrkDoolFailPeanut",
      type: "DropDownFieldWidget",
      colName: "colCrkBrkDoolFailPeanut",
      parent: "gridDiscountScheduleSetup",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddcolType_PROPERTIES

      // END_USER_CODE-USER_ddcolType_PROPERTIES
    },
    ddcolFanFailPnId: {
      name: "ddcolFanFailPnId",
      type: "DropDownFieldWidget",
      colName: "colFanFailPnId",
      parent: "gridDiscountScheduleSetup",
      CharWidth: "48",
      DataProviderForDropDown: "inline",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddcolType_PROPERTIES

      // END_USER_CODE-USER_ddcolType_PROPERTIES
    },
    txtcolCrkBrkDoolThreshld: {
      name: "txtcolCrkBrkDoolThreshld",
      type: "TextBoxWidget",
      colName: "colCrkBrkDoolThreshld",
      parent: "gridPriceSchedules",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCrkBrkDoolThreshld_PROPERTIES

      // END_USER_CODE-USER_txtcolCrkBrkDoolThreshld_PROPERTIES
    },
    txtcolElkPrem: {
      name: "txtcolElkPrem",
      type: "TextBoxWidget",
      colName: "colElkPrem",
      parent: "gridPriceSchedules",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolElkPrem_PROPERTIES

      // END_USER_CODE-USER_txtcolElkPrem_PROPERTIES
    },
    txtcolFancyThreshld: {
      name: "txtcolFancyThreshld",
      type: "TextBoxWidget",
      colName: "colFancyThreshld",
      parent: "gridPriceSchedules",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFancyThreshld_PROPERTIES

      // END_USER_CODE-USER_txtcolFancyThreshld_PROPERTIES
    },
    // txtcolFanFailPnId: {
    //   name: "txtcolFanFailPnId",
    //   type: "DropDownFieldWidget",
    //   colName: "colFanFailPnId",
    //   parent: "gridPriceSchedules",
    //   DataProviderForDropDown: "inline",
    //   CharWidth: "48",
    //   Height: "",
    //   Width: "",
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtcolFanFailPnId_PROPERTIES

    //   // END_USER_CODE-USER_txtcolFanFailPnId_PROPERTIES
    // },
    txtcolLSKPerLbs: {
      name: "txtcolLSKPerLbs",
      type: "TextBoxWidget",
      colName: "colLSKPerLbs",
      parent: "gridPriceSchedules",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLSKPerLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolLSKPerLbs_PROPERTIES
    },
    txtcolMaxMoisture: {
      name: "txtcolMaxMoisture",
      type: "TextBoxWidget",
      colName: "colMaxMoisture",
      parent: "gridPriceSchedules",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMaxMoisture_PROPERTIES

      // END_USER_CODE-USER_txtcolMaxMoisture_PROPERTIES
    },
    txtcolMinFancyElkPrem: {
      name: "txtcolMinFancyElkPrem",
      type: "TextBoxWidget",
      colName: "colMinFancyElkPrem",
      parent: "gridPriceSchedules",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMinFancyElkPrem_PROPERTIES

      // END_USER_CODE-USER_txtcolMinFancyElkPrem_PROPERTIES
    },
    txtcolOkPerPct: {
      name: "txtcolOkPerPct",
      type: "TextBoxWidget",
      colName: "colOkPerPct",
      parent: "gridPriceSchedules",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOkPerPct_PROPERTIES

      // END_USER_CODE-USER_txtcolOkPerPct_PROPERTIES
    },
    txtcolPeanuType: {
      name: "txtcolPeanuType",
      type: "TextBoxWidget",
      colName: "colPeanutType",
      parent: "gridPriceSchedules",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true

      // START_USER_CODE-USER_txtcolPeanuType_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanuType_PROPERTIES
    },
    txtcolSMKSSPerPercent: {
      name: "txtcolSMKSSPerPercent",
      type: "TextBoxWidget",
      colName: "colSMKSSPerPercent",
      parent: "gridPriceSchedules",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSMKSSPerPercent_PROPERTIES

      // END_USER_CODE-USER_txtcolSMKSSPerPercent_PROPERTIES
    },
    txtcolSupportPerTon: {
      name: "txtcolSupportPerTon",
      type: "TextBoxWidget",
      colName: "colSupportPerTon",
      parent: "gridPriceSchedules",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSupportPerTon_PROPERTIES

      // END_USER_CODE-USER_txtcolSupportPerTon_PROPERTIES
    },
    grpbxPriceScheduleSetup: {
      name: "grpbxPriceScheduleSetup",
      type: "GroupBoxWidget",
      parent: "PriceScheduleSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPriceScheduleSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxPriceScheduleSetup_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "PriceScheduleSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#PriceScheduleAuditList": {}
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#PriceScheduleAuditList": {}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnAuditHistory: {
      DEFAULT: ["SystemMaintenanceSpecialFunctions#PriceScheduleAuditList#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const [mergeFlag, setMergeFlag] = useState(false);
  // const gridRef = useRef(null);
  // let prevColId;

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    Form_Load()
    setData(thisObj,'mbFormDirty', false)

    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    hideColumn(thisObj,'gridPriceSchedules','txtcolEmpty2')
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  //gridHeader clickHandler
  // const clickHandler = (event) => {
  //   const header = event.target;
  //   const headerID = header.closest('.rdt_TableCol').childNodes[0].id;
  //   const columnID = headerID.substring(7)
  //   if(columnID!="txtcolArea" && columnID!="txtcolPeanuType"){
  //     highlightColumn(columnID,"gridPriceSchedules")
  //   }
  // }

useEffect(()=>{

},[getValue(thisObj,'gridPriceSchedules')])
  
  useEffect(()=>{
     if(mergeFlag===true){
      mergeRows('gridPriceSchedules','txtcolArea',1)
      setMergeFlag(false)
     }
  },[mergeFlag])
  useEffect(() => {
    let newData = getData(thisObj, "PriceScheduleSetup")
    let rowid = getData(thisObj, 'RowIDFor17thcolumn')
    let gridWholeData = getValue(thisObj, 'gridPriceSchedules')
    let newGridData = [...gridWholeData]
    if(newGridData != null && newGridData != undefined && newGridData.length > 0){
      newGridData[rowid].txtcolEmpty2 = newData //gridWholeData[rowid].txtcolEmpty2 + newData

      setValue(thisObj, 'gridPriceSchedules', newGridData)
      setData(thisObj,'mbFormDirty', true)

    }
    setData(thisObj, "gridPriceSchedulesData", getValue(thisObj, 'gridPriceSchedules'))
  },[getData(thisObj, "PriceScheduleSetup")])

  const Form_Load = async () => {
    bFillTypeList()
    FillPriceSchedules()
    hideColumn(thisObj,"gridPriceSchedules","txtcolEmpty1")
    hideColumn(thisObj,"gridPriceSchedules","txtcolEmpty2")

  }

  const bFillTypeList = async () => {
    let js = [];
    ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
        js.push(obj)
      }
      let str = "";
      for (let i in js) {
        str += js[i]["description"] + ":";
        str += js[i]["key"] + ",";
      }
      str = str.slice(0, str.length - 1)
      thisObj.state.ddcolCrkBrkDoolFailPeanut.Options = str;
      thisObj.state.ddcolCrkBrkDoolFailPeanut.valueList = js;

      thisObj.state.ddcolFanFailPnId.Options = str;
      thisObj.state.ddcolFanFailPnId.valueList = js;

    })

  }

  const checkForRowData = (row) => { 
    
    if (row.txtcolSupportPerTon == '' && row.txtcolSMKSSPerPercent == '' && row.txtcolOkPerPct == '' && row.txtcolElkPrem == '' && row.txtcolMinFancyElkPrem == '' && row.txtcolLSKPerLbs == '' && row.txtcolMaxMoisture == '' && row.txtcolFancyThreshld == '' && row.txtcolCrkBrkDoolThreshld == '' && row.ddcolFanFailPnId == '' && row.ddcolCrkBrkDoolFailPeanut == '' ) {
      return true
    } else {
      return false
    }
  }
  
  const FillPriceSchedules = async (buying_point) => {
    setLoading(true)
    let LstrArea = '', LstrAreaID = '', LstrPeanutType = '', LstrPeanutTypeID = '', LstrREC = ''
    let LstrAreaIDArr = []
    let LstrPeanutTypeIDArr = []
    let LstrAreaList = await SettlementService.RetrieveAreaControlDetails(null)
    let griddata = []
    let obj = {}
    if (LstrAreaList != undefined && LstrAreaList.length > 0) {
      for (let i = 0; i < LstrAreaList.length; i++) {
        LstrArea = LstrAreaList[i].areaName
        let LstrPeanutTypeList = await ContractManagementService.RetrievePeanutTypeControls(null)
        for (let j = 0; j < LstrPeanutTypeList.length; j++) {
          LstrAreaID = LstrAreaList[i].areaId
          // LstrAreaIDArr.push(LstrAreaID)
          LstrPeanutType = LstrPeanutTypeList[j].pnutTypeName
          LstrPeanutTypeID = LstrPeanutTypeList[j].pnutTypeId
          LstrPeanutTypeIDArr.push(LstrPeanutTypeID)
          LstrREC = await ContractManagementService.RetrievePeanutPriceScheduleDetails(LstrAreaID, LstrPeanutTypeID)
          if (LstrREC.length > 0) {
            // return '';{
              obj.txtcolArea = LstrArea,
              obj.txtcolPeanuType = LstrPeanutType,
              obj.txtAreaID = LstrAreaID,
              obj.txtPnutID = LstrPeanutTypeID,
              obj.txtcolSupportPerTon = LstrREC[4].strFieldData >=0 && LstrREC[4].strFieldData <1 ?LstrREC[4].strFieldData.slice(1) : LstrREC[4].strFieldData,
              obj.txtcolSMKSSPerPercent = LstrREC[5].strFieldData >=0 && LstrREC[5].strFieldData <1 ?LstrREC[5].strFieldData.slice(1) : LstrREC[5].strFieldData,
              obj.txtcolOkPerPct = LstrREC[6].strFieldData >=0 && LstrREC[6].strFieldData <1 ?LstrREC[6].strFieldData.slice(1) : LstrREC[6].strFieldData,
              obj.txtcolElkPrem = LstrREC[7].strFieldData >=0 && LstrREC[7].strFieldData <1 ?LstrREC[7].strFieldData.slice(1) : LstrREC[7].strFieldData,
              obj.txtcolMinFancyElkPrem = LstrREC[8].strFieldData >=0 && LstrREC[8].strFieldData <1 ?LstrREC[8].strFieldData.slice(1) : LstrREC[8].strFieldData,
              obj.txtcolLSKPerLbs = LstrREC[9].strFieldData >=0 && LstrREC[9].strFieldData <1 ?LstrREC[9].strFieldData.slice(1) : LstrREC[9].strFieldData,
              obj.txtcolMaxMoisture = LstrREC[10].strFieldData >=0 && LstrREC[10].strFieldData <1 ?LstrREC[10].strFieldData.slice(1) : LstrREC[10].strFieldData,
              obj.txtcolFancyThreshld = LstrREC[11].strFieldData >=0 && LstrREC[11].strFieldData <1 ?LstrREC[11].strFieldData.slice(1) : LstrREC[11].strFieldData
              
              if (LstrREC[12].fan_fail_pn_id !== '') {
              obj.ddcolFanFailPnId = LstrREC[12].strFieldData 
            } else {
              obj.ddcolFanFailPnId = ''
            }
            obj.txtcolCrkBrkDoolThreshld = LstrREC[13].strFieldData >=0 && LstrREC[13].strFieldData <1 ?LstrREC[13].strFieldData.slice(1) : LstrREC[13].strFieldData

            if (LstrREC[14].strFieldData  !== '') {
              obj.ddcolCrkBrkDoolFailPeanut = LstrREC[14].strFieldData 
            } else {
              obj.ddcolCrkBrkDoolFailPeanut = ''
            }

            let col16Data = ''
            if (obj.txtcolSupportPerTon != '' && obj.txtcolSMKSSPerPercent != '' && obj.txtcolOkPerPct != '' && obj.txtcolElkPrem != '' && obj.txtcolMinFancyElkPrem != '' && obj.txtcolLSKPerLbs != '' && obj.txtcolMaxMoisture != '' && obj.txtcolFancyThreshld != '' && obj.txtcolCrkBrkDoolThreshld != '') {
              let resp = await SystemMaintenanceSpecialFunctionsService.RetrieveDiscountScheduleDetails(LstrAreaID, LstrPeanutTypeID)
              if (resp != null && resp != undefined && resp.length > 0) {
                for (let k = 0; k < resp.length; k++) {
                  if (resp[k].strFieldName == 'DISCOUNT_TYPE') {
                    col16Data = col16Data + resp[k].strFieldData
                  }
                  if (resp[k].strFieldName == 'DISCOUNT_SCHEDULE') {
                    col16Data = col16Data + '|' + resp[k].strFieldData
                  }
                  if (resp[k].strFieldName == 'READING_FROM') {
                    col16Data = col16Data + '|' + resp[k].strFieldData
                  }
                  if (resp[k].strFieldName == 'READING_TO') {
                    col16Data = col16Data + '|' + resp[k].strFieldData
                  }
                  if (resp[k].strFieldName == 'DISCOUNT_PER_TON') {
                    col16Data = col16Data + '|' + resp[k].strFieldData + '~'
                  }
                  // let col16Data = `${resp[k].discount_type} | ${resp[k].discount_schedule} | ${resp[k].reading_from} | ${resp[k].reading_to} | ${resp[k].discount_per_ton} ~`
                }
              }
            }
            obj.txtcolEmpty2 = col16Data

          } else {
            obj.txtcolArea = LstrArea,
            obj.txtcolPeanuType = LstrPeanutType,
            obj.txtAreaID = LstrAreaID,
            obj.txtPnutID = LstrPeanutTypeID,
              obj.txtcolSupportPerTon = '',
              obj.txtcolSMKSSPerPercent = '',
              obj.txtcolOkPerPct = '',
              obj.txtcolElkPrem = '',
              obj.txtcolMinFancyElkPrem = '',
              obj.txtcolLSKPerLbs = '',
              obj.txtcolMaxMoisture = '',
              obj.txtcolFancyThreshld = ''
              obj.ddcolFanFailPnId = ''
            obj.txtcolCrkBrkDoolThreshld = ''
            obj.ddcolCrkBrkDoolFailPeanut = ''
            obj.btncolDiscSchd = ''
            obj.txtcolEmpty2 = ''
          }
          // obj.btncolDiscSchd
          griddata.push(obj)
          obj = {}
          
          setValue(thisObj, "gridPriceSchedules", griddata);
          setData(thisObj, "gridPriceSchedulesData", griddata)
          hideColumn(thisObj,"gridPriceSchedules","txtcolEmpty2")
          setMergeFlag(true)

        }
      }
    } else {
      setValue(thisObj, "gridPriceSchedules", griddata);
      setData(thisObj, "gridPriceSchedulesData", griddata)
      hideColumn(thisObj,"gridPriceSchedules","txtcolEmpty2")

      setMergeFlag(true)      
      setLoading(false)
    }
    setLoading(false)
  }
  
  // Method for cancel btn
  const onbtnCancelClick = () => {
    try {
      let mbFormDirty = getData(thisObj,'mbFormDirty')
      if (mbFormDirty == true) {
        let res = confirm(`This form contains unsaved data. Some data may be lost. Continue?`)
        if (res == true) {
          setData(thisObj,'mbFormDirty',false)
          // document.getElementById("SystemMaintenanceSpecialFunctions_PriceScheduleSetupPopUp").childNodes[0].click()
        } else {
          return
        }
      } else {
        setData(thisObj,'mbFormDirty',false)
        document.getElementById("SystemMaintenanceSpecialFunctions_PriceScheduleSetupPopUp").childNodes[0].click()
        
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  //added 15Jul INC5836881-PRB0053997 Problem Ticket
const onbtnAuditHistoryClick = () => {
  try {
    goTo(thisObj, "SystemMaintenanceSpecialFunctions#PriceScheduleAuditList#DEFAULT#true#Click");
  } 
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error on Audit History button click"
      );
    }
    return false;
  }
  return true;
};
   
  thisObj.onbtnAuditHistoryClick = onbtnAuditHistoryClick;
  //end of changes

  //After Edit functionality
  const PriceSchedulesAfterEdit = (rowId, col, value) => {
    let gridData = getValue(thisObj, 'gridPriceSchedules')
    // vsfPriceSchedules.Redraw = False
    let row = parseInt(rowId)
    let val 
    
    if (value == '0') {
      
      switch (col) {
        case 'txtcolSupportPerTon':      
          setGridValue(thisObj, 'gridPriceSchedules', col, row, '.00')
          break;
        case 'txtcolSMKSSPerPercent':         
          setGridValue(thisObj, 'gridPriceSchedules', col, row, '.00000')
          break;
        case 'txtcolOkPerPct':          
          setGridValue(thisObj, 'gridPriceSchedules', col, row, '.00000')
          break;
        case 'txtcolElkPrem':          
          setGridValue(thisObj, 'gridPriceSchedules', col, row, '.00000')
          break;
        case 'txtcolMinFancyElkPrem':         
          setGridValue(thisObj, 'gridPriceSchedules', col, row, '.00')
          break;
        case 'txtcolLSKPerLbs':          
          setGridValue(thisObj, 'gridPriceSchedules', col, row, '.00000')
          break;
        case 'txtcolMaxMoisture':          
          setGridValue(thisObj, 'gridPriceSchedules', col, row, '.00')
          break;
        case 'txtcolFancyThreshld':          
          setGridValue(thisObj, 'gridPriceSchedules', col, row, '.00')
          break;
        case 'txtcolCrkBrkDoolThreshld':         
          setGridValue(thisObj, 'gridPriceSchedules', col, row, '.00')
          break;        
      }      
    } 
    else {
      switch (col) {
        case 'txtcolSupportPerTon':
          if (value == '' || isNaN(value)) {
            val = value
          } else if (!isNaN(value))
          {  
            val = value.toFixed(2)
            val = Number(val) < 1 && Number(val) > 0 ? val ?.slice(1) : val

          }
          setGridValue(thisObj, 'gridPriceSchedules', col, row, val)
          break;
        case 'txtcolSMKSSPerPercent':
          if (value == '' || isNaN(value)) {
            val = value
          } else if (!isNaN(value))
          {  
            val = value.toFixed(5)
            val = Number(val) < 1 && Number(val) > 0 ? val ?.slice(1) : val
          }
          setGridValue(thisObj, 'gridPriceSchedules', col, row, val)
          break;
        case 'txtcolOkPerPct':
          if (value == '' || isNaN(value)) {
            val = value
          } else if (!isNaN(value))
          {  
            val = value.toFixed(5)
            val = Number(val) < 1 && Number(val) > 0 ? val ?.slice(1) : val

          }
          setGridValue(thisObj, 'gridPriceSchedules', col, row, val)
          break;
        case 'txtcolElkPrem':
          if (value == '' || isNaN(value)) {
            val = value
          } else if (!isNaN(value))
          {  
            val = value.toFixed(5)
            val = Number(val) < 1 && Number(val) > 0 ? val ?.slice(1) : val

          }
          setGridValue(thisObj, 'gridPriceSchedules', col, row, val)
          break;
        case 'txtcolMinFancyElkPrem':
          if (value == '' || isNaN(value)) {
            val = value
          } else if (!isNaN(value))
          {  
            val = value.toFixed(2)
            val = Number(val) < 1 && Number(val) > 0 ? val ?.slice(1) : val

          }
          setGridValue(thisObj, 'gridPriceSchedules', col, row, val)
          break;
        case 'txtcolLSKPerLbs':
          if (value == '' || isNaN(value)) {
            val = value
          } else if (!isNaN(value))
          {  
            val = value.toFixed(5)
            val = Number(val) < 1 && Number(val) > 0 ? val ?.slice(1) : val

          }
          setGridValue(thisObj, 'gridPriceSchedules', col, row, val)
          break;
        case 'txtcolMaxMoisture':
          if (value == '' || isNaN(value)) {
            val = value
          } else if (!isNaN(value))
          {  
            val = value.toFixed(2)
            val = Number(val) < 1 && Number(val) > 0 ? val ?.slice(1) : val

          }
          setGridValue(thisObj, 'gridPriceSchedules', col, row, val)
          break;
        case 'txtcolFancyThreshld':
          if (value == '' || isNaN(value)) {
            val = value
          } else if (!isNaN(value))
          {  
            val = value.toFixed(2)
            val = Number(val) < 1 && Number(val) > 0 ? val ?.slice(1) : val

          }
          setGridValue(thisObj, 'gridPriceSchedules', col, row, val)
          break;
        case 'txtcolCrkBrkDoolThreshld':
          if (value == '' || isNaN(value)) {
            val = value
          } else if (!isNaN(value))
          {  
            val = value.toFixed(2)
            val = Number(val) < 1 && Number(val) > 0 ? val ?.slice(1) : val

          }
          setGridValue(thisObj, 'gridPriceSchedules', col, row, val)
          break;
        // case 'ddcolCrkBrkDoolFailPeanut':
        //   setGridValue(thisObj, 'gridPriceSchedules', col, row, value.toFixed(2))
        // break;

        default:
          break;
      }
    }

    thisObj.setState({
      ...thisObj.state,
      gridPriceSchedules: {
        ...state.gridPriceSchedules,
        toggleRender: !state.gridPriceSchedules.toggleRender,
      },
      colEmpty2: {
        ...state.colEmpty2,
        Visible: false,
      }
      
    })
    setMergeFlag(true)
    if (gridData[row].txtcolSupportPerTon != '' || gridData[row].txtcolSMKSSPerPercent != '' || gridData[row].txtcolOkPerPct != '' || gridData[row].txtcolElkPrem != '' || gridData[row].txtcolMinFancyElkPrem != '' || gridData[row].txtcolLSKPerLbs != '' || gridData[row].txtcolMaxMoisture != '' || gridData[row].txtcolFancyThreshld != ''|| gridData[row].txtcolCrkBrkDoolThreshld != '') {
      // setGridValue(_kaledo.thisObj, 'gridPriceSchedules', 'btncolDiscSchd', row, true)
      setData(thisObj,'mbFormDirty', true)
    }
    
  }

  // START_USER_CODE-USER_METHODS

  const onbtncolDiscSchdClick = async (event) => {
    let highlight = document.getElementsByClassName("activeRow")
    if (highlight.length > 0) {
      highlight[0].classList.remove("activeRow")
    }
    let rowSelected = event.target.parentElement.parentElement.parentElement
    rowSelected.classList.add("activeRow")
    let rowID = rowSelected.id.substring(4, 5);
    setData(thisObj, 'RowIDFor17thcolumn', rowID)
    let GridValue = getData(thisObj, "gridPriceSchedulesData")
    let Obj = ''
    let Lst = []    
    if(GridValue != undefined && GridValue != null && GridValue[rowID] != undefined && GridValue[rowID] != null && GridValue[rowID].txtcolEmpty2 != undefined){
      Obj = GridValue[rowID].txtcolEmpty2
      Lst = Obj.split('~')
    }

    // let addedValue = getData(thisObj, "PriceScheduleSetup")
    // let addedlist = []
    // if(addedValue != undefined && addedValue != null && addedValue != ''){
    //   addedlist = addedValue.split('~')
    //   for(let i =0; i <addedlist.length; i++ ){
    //     Lst.push(addedlist[i])
    //   }
    // }

    setData(thisObj, 'frmDiscountScheduleSetupData', Lst)
    goTo(thisObj, 'SystemMaintenanceSpecialFunctions#DiscountScheduleSetup#DEFAULT#true#Click')
  }
  thisObj.onbtncolDiscSchdClick = onbtncolDiscSchdClick;

  const mergeRows = (gridId, colId, colNum) => {
    let c = getRowCount(thisObj, gridId);
    let count = 1;
    for (let i = 0; i < c - 1; i++) {
      if(count%2!=0){
        document.getElementById(`row-${i}`).style.background="#ffffc0";
      }
      let val1 = getGridValue(thisObj, gridId, colId, i);
      let val2 = getGridValue(thisObj, gridId, colId, i + 1);
      if (val1 === val2) {
        document.getElementById(`row-${i + 1}`).childNodes[colNum - 1].querySelector('.valRowSel').style.display = 'none';
        document.getElementById(`row-${i}`).childNodes[colNum - 1].style.border = 'none';
      }else{
        count+=1;
      }
    }
    if(count%2!=0){
      document.getElementById(`row-${c-1}`).style.background="#ffffc0";
    }
  }

  //  const highlightColumn = (columnID,gridId) => {
  //   let c = getRowCount(thisObj, gridId);
  //   for (let i = 0; i < c ; i++) {
  //     if(prevColId){
  //       const prevCell = document.getElementById(`cell-${prevColId}_ID-${i}`)
  //       if(prevCell){
  //         prevCell.style.background = 'transparent';
  //       }
  //     }
  //     const cell = document.getElementById(`cell-${columnID}_ID-${i}`);
  //     if(cell){
  //       cell.style.background = 'blue';
  //     }
  //   }
  //   setTimeout(() => {
  //     prevColId = columnID;
  //   },0)
  // }

  const onbtnSaveClick = async () => {
    setLoading(true);
    hideColumn(thisObj,"gridPriceSchedules","txtcolEmpty2")
    
    let GridValue = getValue(thisObj, 'gridPriceSchedules')
    let RowValue = []
    let bErrorDuringSave = false

    if (GridValue != undefined && GridValue != null && GridValue.length > 0) {
      for (let i = 0; i < GridValue.length; i++) {
        RowValue = GridValue[i]
        if (RowValue != undefined && RowValue != null && RowValue != '' && (
          (RowValue.txtcolSupportPerTon != undefined && RowValue.txtcolSupportPerTon != '') ||
          (RowValue.txtcolSMKSSPerPercent != undefined && RowValue.txtcolSMKSSPerPercent != '') ||
          (RowValue.txtcolOkPerPct != undefined && RowValue.txtcolOkPerPct != '') ||
          (RowValue.txtcolElkPrem != undefined && RowValue.txtcolElkPrem != '') ||
          (RowValue.txtcolMinFancyElkPrem != undefined && RowValue.txtcolMinFancyElkPrem != '') ||
          (RowValue.txtcolLSKPerLbs != undefined && RowValue.txtcolLSKPerLbs != '') ||
          (RowValue.txtcolMaxMoisture != undefined && RowValue.txtcolMaxMoisture != '') ||
          (RowValue.txtcolFancyThreshld != undefined && RowValue.txtcolFancyThreshld != '') ||
          (RowValue.ddcolFanFailPnId != undefined && RowValue.ddcolFanFailPnId != '') ||
          (RowValue.txtcolCrkBrkDoolThreshld != undefined && RowValue.txtcolCrkBrkDoolThreshld != '') ||
          (RowValue.ddcolCrkBrkDoolFailPeanut != undefined && RowValue.ddcolCrkBrkDoolFailPeanut != ''))
        ) {
            if (
              RowValue.txtcolSupportPerTon == undefined || RowValue.txtcolSupportPerTon == '' ||
              RowValue.txtcolSMKSSPerPercent == undefined || RowValue.txtcolSMKSSPerPercent == '' ||
              RowValue.txtcolOkPerPct == undefined || RowValue.txtcolOkPerPct == '' ||
              RowValue.txtcolElkPrem == undefined || RowValue.txtcolElkPrem == '' ||
              RowValue.txtcolMinFancyElkPrem == undefined || RowValue.txtcolMinFancyElkPrem == '' ||
              RowValue.txtcolLSKPerLbs == undefined || RowValue.txtcolLSKPerLbs == '' ||
              RowValue.txtcolMaxMoisture == undefined || RowValue.txtcolMaxMoisture == '' ||
              RowValue.txtcolFancyThreshld == undefined || RowValue.txtcolFancyThreshld == '' ||
              RowValue.ddcolFanFailPnId == undefined || RowValue.ddcolFanFailPnId == '' ||
              RowValue.txtcolCrkBrkDoolThreshld == undefined || RowValue.txtcolCrkBrkDoolThreshld == '' ||
              RowValue.ddcolCrkBrkDoolFailPeanut == undefined || RowValue.ddcolCrkBrkDoolFailPeanut == ''
            ) {
              alert("The Price Schedule for the following Area/Peanut Type combination is incomplete!!! \n  Area = " + RowValue.txtcolArea +'\n'+ " Peanut Type = " + RowValue.txtcolPeanuType)
      setLoading(false)
              
              return
            }

            if (RowValue.txtcolSupportPerTon != undefined && RowValue.txtcolSupportPerTon != null && RowValue.txtcolSupportPerTon != '') {
              if (isNaN(RowValue.txtcolSupportPerTon) == false) {
                if ((Number(RowValue.txtcolSupportPerTon) < 0) || (Number(RowValue.txtcolSupportPerTon) > 9999.99)) {
                  alert( "Support Per Ton must be of format ####.##")
      setLoading(false)

                  return
                }
              }
              else {
                alert( "Support Per Ton must be of format ####.##")
      setLoading(false)

                return
              }
            }

            if (RowValue.txtcolSMKSSPerPercent != undefined && RowValue.txtcolSMKSSPerPercent != null && RowValue.txtcolSMKSSPerPercent != '') {
              if (isNaN(RowValue.txtcolSMKSSPerPercent) == false) {
                if ((Number(RowValue.txtcolSMKSSPerPercent) < 0) || (Number(RowValue.txtcolSMKSSPerPercent) > 99.99999)) {
                  alert( "SMK SS Per Percent must be of format ##.#####")
      setLoading(false)

                  return
                }
              }
              else {
                alert( "SMK SS Per Percent must be of format ##.#####")
      setLoading(false)

                return
              }
            }

            if (RowValue.txtcolOkPerPct != undefined && RowValue.txtcolOkPerPct != null && RowValue.txtcolOkPerPct != '') {
              if (isNaN(RowValue.txtcolOkPerPct) == false) {
                if ((Number(RowValue.txtcolOkPerPct) < 0) || (Number(RowValue.txtcolOkPerPct) > 99.99999)) {
                  alert( "OK Percent must be of format ##.#####")
      setLoading(false)

                  return
                }
              }
              else {
                alert( "OK Percent must be of format ##.#####")
      setLoading(false)

                return
              }
            }

            if (RowValue.txtcolElkPrem != undefined && RowValue.txtcolElkPrem != null && RowValue.txtcolElkPrem != '') {
              if (isNaN(RowValue.txtcolElkPrem) == false) {
                if ((Number(RowValue.txtcolElkPrem) < 0) || (Number(RowValue.txtcolElkPrem) > 99.99999)) {
                  alert( "ELK Prem must be of format ##.#####")
      setLoading(false)

                  return
                }
              }
              else {
                alert( "ELK Prem must be of format ##.#####")
      setLoading(false)

                return
              }
            }

            if (RowValue.txtcolMinFancyElkPrem != undefined && RowValue.txtcolMinFancyElkPrem != null && RowValue.txtcolMinFancyElkPrem != '') {
              if (isNaN(RowValue.txtcolMinFancyElkPrem) == false) {
                if ((Number(RowValue.txtcolMinFancyElkPrem) < 0) || (Number(RowValue.txtcolMinFancyElkPrem) > 100)) {
                  alert( "Minimum Fancy Elk Premium must be between 0 and 100.")
      setLoading(false)

                  return
                }
              }
              else {
                alert( "Minimum Fancy Elk Premium must be between 0 and 100.")
      setLoading(false)

                return
              }
            }

            if (RowValue.txtcolLSKPerLbs != undefined && RowValue.txtcolLSKPerLbs != null && RowValue.txtcolLSKPerLbs != '') {
              if (isNaN(RowValue.txtcolLSKPerLbs) == false) {
                if ((Number(RowValue.txtcolLSKPerLbs) < 0) || (Number(RowValue.txtcolLSKPerLbs) > 99.99999)) {
                  alert( "LSK Per Lbs must be of format ##.#####")
      setLoading(false)

                  return
                }
              }
              else {
                alert( "LSK Per Lbs must be of format ##.#####")
      setLoading(false)

                return
              }
            }

            if (RowValue.txtcolMaxMoisture != undefined && RowValue.txtcolMaxMoisture != null && RowValue.txtcolMaxMoisture != '') {
              if (isNaN(RowValue.txtcolMaxMoisture) == false) {
                if ((Number(RowValue.txtcolMaxMoisture) < 0) || (Number(RowValue.txtcolMaxMoisture) > 100)) {
                  alert( "Max Moist must be between 0 and 100.")
      setLoading(false)

                  return
                }
              }
              else {
                alert( "Max Moist must be between 0 and 100.")
      setLoading(false)

                return
              }
            }

            if (RowValue.txtcolFancyThreshld != undefined && RowValue.txtcolFancyThreshld != null && RowValue.txtcolFancyThreshld != '') {
              if (isNaN(RowValue.txtcolFancyThreshld) == false) {
                if ((Number(RowValue.txtcolFancyThreshld) < 0) || (Number(RowValue.txtcolFancyThreshld) > 100)) {
                  alert( "Fancy Threshld must be between 0 and 100.")
      setLoading(false)

                  return
                }
              }
              else {
                alert( "Fancy Threshld must be between 0 and 100.")
      setLoading(false)

                return
              }
            }

            if (RowValue.txtcolCrkBrkDoolThreshld != undefined && RowValue.txtcolCrkBrkDoolThreshld != null && RowValue.txtcolCrkBrkDoolThreshld != '') {
              if (isNaN(RowValue.txtcolCrkBrkDoolThreshld) == false) {
                if ((Number(RowValue.txtcolCrkBrkDoolThreshld) < 0) || (Number(RowValue.txtcolCrkBrkDoolThreshld) > 100)) {
                  alert( "Crk/Brk Dcol Threshld must be between 0 and 100.")
      setLoading(false)

                  return
                }
              }
              else {
                alert( "Crk/Brk Dcol Threshld must be between 0 and 100.")
      setLoading(false)

                return
              }
            }
        }
      }
    }

    let LstrList = []
    await SystemMaintenanceSpecialFunctionsService.RemoveDiscountScheduleDetails(null, null, null, null, null).then(response =>{
      LstrList = response
    })
    if(LstrList.status == 200){
      await SystemMaintenanceSpecialFunctionsService.RemovePriceScheduleDetails(null, null, null).then(response =>{
        LstrList = response
      })
      if(LstrList.status == 200){
        for (let i = 0; i < GridValue.length; i++) {
          RowValue = GridValue[i]
          if(RowValue != undefined && RowValue != null && RowValue.txtcolSupportPerTon != undefined && RowValue.txtcolSupportPerTon != null && RowValue.txtcolSupportPerTon != ''){
            let dataObj = {
              area_id : RowValue.txtAreaID,
              pnut_type_id : RowValue.txtPnutID,
              support_per_ton : Number(RowValue.txtcolSupportPerTon),
              smk_ss_per_pct : Number(RowValue.txtcolSMKSSPerPercent),
              ok_per_pct : Number(RowValue.txtcolOkPerPct),
              elk_prem : Number(RowValue.txtcolElkPrem),
              min_fan_elk_prem : Number(RowValue.txtcolMinFancyElkPrem),
              lsk_per_ton : Number(RowValue.txtcolLSKPerLbs),
              max_moist : Number(RowValue.txtcolMaxMoisture),
              fan_threshold : Number(RowValue.txtcolFancyThreshld),
              fan_fail_pn_id : RowValue.ddcolFanFailPnId,
              cr_br_dcol_threshold : Number(RowValue.txtcolCrkBrkDoolThreshld),
              cr_br_dcol_fail_pn_id : RowValue.ddcolCrkBrkDoolFailPeanut 
            }

            await SystemMaintenanceSpecialFunctionsService.CreatePriceSchedule(null, dataObj).then(response =>{
              LstrList = response
            })
            if(LstrList.status != 200){
              alert( "Error Creating Price Schedule Area = " + RowValue.txtcolArea + " Peanut Type = " + RowValue.txtcolPeanuType)
              bErrorDuringSave = true
              setLoading(false)

              return
            }
            else{

              let Col16Value = []
              let Col16Obj = {}
              let Col16Arr = []
              let Col16Array = []
              let Count = 0
              let GridData = getData(thisObj, "gridPriceSchedulesData")
              let RowData = []
              RowData = GridData[i]
              if(RowData.txtcolEmpty2 != undefined && RowData.txtcolEmpty2 != ''){
                Col16Value = RowData.txtcolEmpty2.split('~')
              }
              
              for (let j = 0; j < Col16Value.length; j++) {
                if (Col16Value[j] != null && Col16Value[j] != '') {
                  Col16Arr = Col16Value[j].split('|')
                  if (Col16Arr != undefined && Col16Arr != null && Col16Arr != '') {
                    Col16Obj = {
                      discount_type: Col16Arr[0],
                      discount_schedule: Col16Arr[1],
                      reading_from: Col16Arr[2],
                      reading_to: Col16Arr[3],
                      discount_per_ton: Col16Arr[4]
                    }
                    Col16Array.push(Col16Obj)
                    Count = Count + 1
                    Col16Obj = {}
                    Col16Arr = []
                  }
                }
              }

              
              let Obj = {
                area_id : RowValue.txtAreaID,
                pnut_type_id : RowValue.txtPnutID,
                discounts : Count,
                discount : Col16Array
              }

              await SystemMaintenanceSpecialFunctionsService.CreateDiscountSchedule(null, Obj).then(response =>{
                LstrList = response
              })
              if(LstrList.status != 200){
                alert( "Error Creating Discount Schedule. Area = " + RowValue.txtcolArea + " Peanut Type = " + RowValue.txtcolPeanuType)
                bErrorDuringSave = true
                 setLoading(false)

                return
              }
            }
          }
        }
      }
      else{
        alert("An error occured while communicating or updating with the database. Please contract your system administrator if the problem persists. \n Error: \n\n" + LstrList?.message)
        alert( "Error Clearing Price Schedules for Reinsertion")
        bErrorDuringSave = true
      setLoading(false)

        return
      }
    }
    else{
      alert("An error occured while communicating or updating with the database. Please contract your system administrator if the problem persists. \n Error: \n\n" + LstrList?.message)
      alert( "Error Clearing Discount Schedules for Reinsertion")
      bErrorDuringSave = true
      setLoading(false)

      return
    }

    await SystemMaintenanceSpecialFunctionsService.UpdatePriceScheduleAudits('UPDATE', null).then(response =>{
      LstrList = response
    })
    if(LstrList.status != 200){
      setLoading(false)
      return;
    }

    if(bErrorDuringSave == false){
      setData(thisObj,'mbFormDirty', false)

      document.getElementById("SystemMaintenanceSpecialFunctions_PriceScheduleSetupPopUp").childNodes[0].click()
    }
    setLoading(false)

  }
  thisObj.onbtnSaveClick = onbtnSaveClick;

  const ontxtcolSupportPerTonBlur = (e) => {
    let colName = 'txtcolSupportPerTon'
    let rowSelected = e.target.parentElement.childNodes[0].id
    let rowID = rowSelected.split('.')[1] //row-0

    let value 

    if (e.target.value == '') {
      value = ''
    }
    else if (!isNaN(e.target.value)) {
      value = parseFloat(e.target.value)
    } else {
      value = (e.target.value).toString()
    }
    PriceSchedulesAfterEdit(rowID, colName, value)

  }
  thisObj.ontxtcolSupportPerTonBlur = ontxtcolSupportPerTonBlur;
  const ontxtcolSMKSSPerPercentBlur = (e) => {
    let colName = 'txtcolSMKSSPerPercent'
    let rowSelected = e.target.parentElement.childNodes[0].id
    let rowID = rowSelected.split('.')[1] //row-0
    let value 
    if (e.target.value == '') {
      value = ''
    }
    else if (!isNaN(e.target.value)) {
      value = parseFloat(e.target.value)
    } else {
      value = (e.target.value).toString()
    }
    PriceSchedulesAfterEdit(rowID, colName, value)

  }
  thisObj.ontxtcolSMKSSPerPercentBlur = ontxtcolSMKSSPerPercentBlur;

  
  const ontxtcolOkPerPctBlur = (e) => {
    let colName = 'txtcolOkPerPct'
    let rowSelected = e.target.parentElement.childNodes[0].id
    let rowID = rowSelected.split('.')[1] //row-0
    let value 
    if (e.target.value == '') {
      value = ''
    }
    else if (!isNaN(e.target.value)) {
      value = parseFloat(e.target.value)
    } else {
      value = (e.target.value).toString()
    }
    PriceSchedulesAfterEdit(rowID, colName, value)

  }
  thisObj.ontxtcolOkPerPctBlur = ontxtcolOkPerPctBlur;

  const ontxtcolElkPremBlur = (e) => {
    let colName = 'txtcolElkPrem'
    let rowSelected = e.target.parentElement.childNodes[0].id
    let rowID = rowSelected.split('.')[1] //row-0
    let value 
    if (e.target.value == '') {
      value = ''
    }
    else if (!isNaN(e.target.value)) {
      value = parseFloat(e.target.value)
    } else {
      value = (e.target.value).toString()
    }
    PriceSchedulesAfterEdit(rowID, colName, value)

  }
  thisObj.ontxtcolElkPremBlur = ontxtcolElkPremBlur;

  const ontxtcolMinFancyElkPremBlur = (e) => {
    let colName = 'txtcolMinFancyElkPrem'
    let rowSelected = e.target.parentElement.childNodes[0].id
    let rowID = rowSelected.split('.')[1] //row-0
    let value 
    if (e.target.value == '') {
      value = ''
    }
    else if (!isNaN(e.target.value)) {
      value = parseFloat(e.target.value)
    } else {
      value = (e.target.value).toString()
    }
    PriceSchedulesAfterEdit(rowID, colName, value)

  }
  thisObj.ontxtcolMinFancyElkPremBlur = ontxtcolMinFancyElkPremBlur;

  const ontxtcolLSKPerLbsBlur = (e) => {
    let colName = 'txtcolLSKPerLbs'
    let rowSelected = e.target.parentElement.childNodes[0].id
    let rowID = rowSelected.split('.')[1] //row-0
    let value 
    if (e.target.value == '') {
      value = ''
    }
    else if (!isNaN(e.target.value)) {
      value = parseFloat(e.target.value)
    } else {
      value = (e.target.value).toString()
    }
    PriceSchedulesAfterEdit(rowID, colName, value)

  }
  thisObj.ontxtcolLSKPerLbsBlur = ontxtcolLSKPerLbsBlur;

  const ontxtcolMaxMoistureBlur = (e) => {
    let colName = 'txtcolMaxMoisture'
    let rowSelected = e.target.parentElement.childNodes[0].id
    let rowID = rowSelected.split('.')[1] //row-0
    let value 
    if (e.target.value == '') {
      value = ''
    }
    else if (!isNaN(e.target.value)) {
      value = parseFloat(e.target.value)
    } else {
      value = (e.target.value).toString()
    }
    PriceSchedulesAfterEdit(rowID, colName, value)

  }
  thisObj.ontxtcolMaxMoistureBlur = ontxtcolMaxMoistureBlur;
  
  const ontxtcolFancyThreshldBlur = (e) => {
    let colName = 'txtcolFancyThreshld'
    let rowSelected = e.target.parentElement.childNodes[0].id
    let rowID = rowSelected.split('.')[1] //row-0
    let value 
    if (e.target.value == '') {
      value = ''
    }
    else if (!isNaN(e.target.value)) {
      value = parseFloat(e.target.value)
    } else {
      value = (e.target.value).toString()
    }
    PriceSchedulesAfterEdit(rowID, colName, value)

  }
  thisObj.ontxtcolFancyThreshldBlur = ontxtcolFancyThreshldBlur;

  const ontxtcolCrkBrkDoolThreshldBlur = (e) => {
    let colName = 'txtcolCrkBrkDoolThreshld'
    let rowSelected = e.target.parentElement.childNodes[0].id
    let rowID = rowSelected.split('.')[1] //row-0
    let value 
    if (e.target.value == '') {
      value = ''
    }
    else if (!isNaN(e.target.value)) {
      value = parseFloat(e.target.value)
    } else {
      value = (e.target.value).toString()
    }
    PriceSchedulesAfterEdit(rowID, colName, value)

  }
  thisObj.ontxtcolCrkBrkDoolThreshldBlur = ontxtcolCrkBrkDoolThreshldBlur;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              <Loading loading={loading}></Loading>
              {/* START_USER_CODE-USER_BEFORE_PriceScheduleSetup*/}

              {/* END_USER_CODE-USER_BEFORE_PriceScheduleSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPriceScheduleSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPriceScheduleSetup*/}

              <GroupBoxWidget
                conf={state.grpbxPriceScheduleSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblPriceSchedules*/}

                {/* END_USER_CODE-USER_BEFORE_lblPriceSchedules*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPriceSchedules}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPriceSchedules*/}

                {/* END_USER_CODE-USER_AFTER_lblPriceSchedules*/}
                {/* START_USER_CODE-USER_BEFORE_gridPriceSchedules*/}

                {/* END_USER_CODE-USER_BEFORE_gridPriceSchedules*/}

                <GridWidget
                  conf={state.gridPriceSchedules}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  // gridRef={gridRef}
                  // clickHandler={clickHandler}
                  rows={values.gridPriceSchedules}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }

                  key={state.gridPriceSchedules.toggleRender}

                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPriceSchedules*/}

                {/* END_USER_CODE-USER_AFTER_gridPriceSchedules*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPriceScheduleSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPriceScheduleSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnAuditHistory*/}

                {/* END_USER_CODE-USER_BEFORE_btnAuditHistory*/}

                <ButtonWidget
                  conf={state.btnAuditHistory}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAuditHistory*/}

                {/* END_USER_CODE-USER_AFTER_btnAuditHistory*/}
                {/* START_USER_CODE-USER_BEFORE_btnSave*/}

                {/* END_USER_CODE-USER_BEFORE_btnSave*/}

                <ButtonWidget
                  conf={state.btnSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSave*/}

                {/* END_USER_CODE-USER_AFTER_btnSave*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_PriceScheduleSetup*/}

              {/* END_USER_CODE-USER_AFTER_PriceScheduleSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_PriceScheduleSetup);
