/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  getValue,
  setValue,
  getData,
  setData,
  getSelectedRowNumber,
  AutocompleteWidget,
  enable,
  disable,
  hide,
} from "../../shared/WindowImports";

import "./SecurityReport.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_SecurityReport(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const useridFromLS = () => sessionStorage.getItem("userid") || "";
  const compIdFromLS = () => sessionStorage.getItem("compId");
  let user_id = useridFromLS();
  let comp_id = compIdFromLS();
  let cropYear = JSON.parse(sessionStorage.getItem("year"));

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SecurityReport",
    windowName: "SecurityReport",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.SecurityReport",
    // START_USER_CODE-USER_SecurityReport_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Report Security Profile",
      scrCode: "PN0060L",
    },
    // END_USER_CODE-USER_SecurityReport_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxAddUser",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDeleteSelectedUsers: {
      name: "btnDeleteSelectedUsers",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Delete Selected Users",
      CharWidth: "43",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteSelectedUsers_PROPERTIES

      // END_USER_CODE-USER_btnDeleteSelectedUsers_PROPERTIES
    },
    btnRequestTheIBMUpdate: {
      name: "btnRequestTheIBMUpdate",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Request the IBM Update",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnRequestTheIBMUpdate_PROPERTIES

      // END_USER_CODE-USER_btnRequestTheIBMUpdate_PROPERTIES
    },
    btnSelectAll: {
      name: "btnSelectAll",
      type: "ButtonWidget",
      parent: "grpbxAddUser",
      Label: "Select All",
      CharWidth: "23",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSelectAll_PROPERTIES

      // END_USER_CODE-USER_btnSelectAll_PROPERTIES
    },
    btnUnselectAll: {
      name: "btnUnselectAll",
      type: "ButtonWidget",
      parent: "grpbxAddUser",
      Label: "Unselect All",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUnselectAll_PROPERTIES

      // END_USER_CODE-USER_btnUnselectAll_PROPERTIES
    },
    btnUpdatSelectedUser: {
      name: "btnUpdatSelectedUser",
      type: "ButtonWidget",
      parent: "grpbxAddUser",
      Label: "Update Selected User",
      CharWidth: "41",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdatSelectedUser_PROPERTIES

      // END_USER_CODE-USER_btnUpdatSelectedUser_PROPERTIES
    },
    colCurrentYears: {
      name: "colCurrentYears",
      type: "GridColumnWidget",
      parent: "gridUsers",
      Label: "Current Years To View",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCurrentYears_PROPERTIES

      // END_USER_CODE-USER_colCurrentYears_PROPERTIES
    },
    colUserID: {
      name: "colUserID",
      type: "GridColumnWidget",
      parent: "gridUsers",
      Label: "User ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUserID_PROPERTIES

      // END_USER_CODE-USER_colUserID_PROPERTIES
    },
    colUserName: {
      name: "colUserName",
      type: "GridColumnWidget",
      parent: "gridUsers",
      Label: "User Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUserName_PROPERTIES

      // END_USER_CODE-USER_colUserName_PROPERTIES
    },
    ddAddUser: {
      name: "ddAddUser",
      type: "DropDownFieldWidget",
      parent: "grpbxAddUser",
      Label: "Add User:",
      ColSpan:"2",
      // caretIcon:true,
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAddUser_PROPERTIES

      // END_USER_CODE-USER_ddAddUser_PROPERTIES
    },
    gridUsers: {
      name: "gridUsers",
      type: "GridWidget",
      parent: "grpbxSecurityReport",
      gridCellsOrder: "txtcolUserID,txtcolUserName,txtcolCurrentYears",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Cols: "2",
      Height: "",
      Width: "",
      isCobolTable: true,
      isMultiSelect: true,
      // START_USER_CODE-USER_gridUsers_PROPERTIES

      // END_USER_CODE-USER_gridUsers_PROPERTIES
    },
    grpbxAddUser: {
      name: "grpbxAddUser",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityReport",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxAddUser_PROPERTIES

      // END_USER_CODE-USER_grpbxAddUser_PROPERTIES
    },
    grpbxCropYear: {
      name: "grpbxCropYear",
      type: "GroupBoxWidget",
      parent: "grpbxSecurityReport",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxCropYear_PROPERTIES

      // END_USER_CODE-USER_grpbxCropYear_PROPERTIES
    },
    radioCropYearsToView: {
      name: "radioCropYearsToView",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxCropYear",
      Options: ">= 2023:1,All Years:2,Other::3",
      Label: "Crop Years To View:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioCropYearsToView_PROPERTIES

      // END_USER_CODE-USER_radioCropYearsToView_PROPERTIES
    },
    txtcolCurrentYears: {
      name: "txtcolCurrentYears",
      type: "TextBoxWidget",
      colName: "colCurrentYears",
      parent: "gridUsers",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCurrentYears_PROPERTIES

      // END_USER_CODE-USER_txtcolCurrentYears_PROPERTIES
    },
    txtcolUserID: {
      name: "txtcolUserID",
      type: "TextBoxWidget",
      colName: "colUserID",
      parent: "gridUsers",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUserID_PROPERTIES

      // END_USER_CODE-USER_txtcolUserID_PROPERTIES
    },
    txtcolUserName: {
      name: "txtcolUserName",
      type: "TextBoxWidget",
      colName: "colUserName",
      parent: "gridUsers",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUserName_PROPERTIES

      // END_USER_CODE-USER_txtcolUserName_PROPERTIES
    },
    txtOtherCropYearsToView: {
      name: "txtOtherCropYearsToView",
      type: "TextBoxWidget",
      parent: "grpbxCropYear",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherCropYearsToView_PROPERTIES

      // END_USER_CODE-USER_txtOtherCropYearsToView_PROPERTIES
    },
    txtOtherCropYearsToView1: {
      name: "txtOtherCropYearsToView1",
      type: "TextBoxWidget",
      parent: "grpbxCropYear",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherCropYearsToView1_PROPERTIES

      // END_USER_CODE-USER_txtOtherCropYearsToView1_PROPERTIES
    },
    grpbxSecurityReport: {
      name: "grpbxSecurityReport",
      type: "GroupBoxWidget",
      parent: "SecurityReport",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSecurityReport_PROPERTIES

      // END_USER_CODE-USER_grpbxSecurityReport_PROPERTIES
    },
    txtFocus: {
      name: "txtFocus",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFocus_PROPERTIES

      // END_USER_CODE-USER_txtFocus_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "SecurityReport",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const [selectedRowNum, setSelectedRowNum] = useState(-1);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    setData(thisObj, "DatafromChildScreensToSecurityMenu", "Update");
    

   hide(thisObj,'txtFocus')
    
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  //Code for FormLoad Function
  let FormLoad = async () => {
    try {
      setLoading(true);
      await bFillAddUsers();
      await bFillUsers();
      let response = await ContractManagementService.RetrieveCropYears();
      let data = response;
      const extractObject = (defaultYearInd) => {
        return data.filter((row) => row.defaultYearInd === defaultYearInd);
      };
      let dataobject = extractObject("Y");
      setData(thisObj, "currentYear", dataobject[0].cropYear);
      let data1 = dataobject[0].defaultYearInd;
      let default_ind = data1 == "Y" ? "1" : "2";
      setValue(thisObj, "radioCropYearsToView", default_ind);
      disable(thisObj, "txtOtherCropYearsToView");
      document.getElementsByClassName(
        "txtOtherCropYearsToView1"
      )[0].style.visibility = "hidden";
      setLoading(false);
    } catch (err) {
      errorHandler(err);
    }
  };

  //Code for radio button change event
  const onradioCropYearsToViewChange = async () => {
    try {
      let radioValue = getValue(thisObj, "radioCropYearsToView");
      if (radioValue == "") {
        enable(thisObj, "txtOtherCropYearsToView");
      }
      else if (radioValue == "1") {
        disable(thisObj, "txtOtherCropYearsToView");
        setValue(thisObj, "txtOtherCropYearsToView1", "");
        setValue(thisObj, "txtOtherCropYearsToView", "");
      }
      else if (radioValue == "2") {
        disable(thisObj, "txtOtherCropYearsToView");
        setValue(thisObj, "txtOtherCropYearsToView1", "");
        setValue(thisObj, "txtOtherCropYearsToView", "");
      }
    } catch (err) {
      errorHandler(err);
    }
  };
  thisObj.onradioCropYearsToViewChange = onradioCropYearsToViewChange;

  //Code for txtOtherCropYearsToView change event
  const ontxtOtherCropYearsToViewChange = async () => {
    try {
      let countValue = getValue(thisObj, "txtOtherCropYearsToView");
      if (countValue == "" || countValue == 0 || /^[a-zA-Z]+$/.test(countValue) || /^[^a-zA-Z0-9]+$/.test(countValue)) {
        setValue(thisObj, "txtOtherCropYearsToView1", "");
        document.getElementsByClassName(
          "txtOtherCropYearsToView1"
        )[0].style.visibility = "hidden";
      } else {
        let yearValue = getData(thisObj, "currentYear");
        let nextYear =">=" + (yearValue - countValue + 1);
        setValue(thisObj, "txtOtherCropYearsToView1", nextYear);
        document.getElementsByClassName(
          "txtOtherCropYearsToView1"
        )[0].style.visibility = "visible";
      }
    } catch (err) {
      errorHandler(err);
    }
  };
  thisObj.ontxtOtherCropYearsToViewChange = ontxtOtherCropYearsToViewChange;

  //Function Errorhandler
  function errorHandler(err) {
    showMessage(thisObj, err.message);
  }

   //Code for Dropdown
   const bFillAddUsers = async () => {
    let response =  await ContractManagementService.RetrieveUserControlDetails();
    let data = response;
    let obj = {};
    let js = [];
    js.push({key:'',description:''})
    if (data !== null && data !== undefined && data.length > 0) {
     for (let i = 0; i < data.length; i++) {
       let dataExist = js.find(elem => elem.key == data[i].userId)
       if (dataExist == undefined) {
        obj={
          key: data[i].userId, 
          description: data[i].userId + " - " + data[i].userName,
          index: i
        }
        js.push(obj);
        obj={}
       }
     }
    }
    setData(thisObj, 'locationLength', js.length);
    setData(thisObj, 'LocationListData', js)
    setValue(thisObj, "ddAddUser", js)
   
    thisObj.setState(current => {
     return {
       ...current,
       ddAddUser: {
         ...state["ddAddUser"],
         valueList: js,
       }
     }
   })
  }
 
  //Code for Add button Click
  const onbtnAddClick = async () => {
    try {
      setLoading(true)
      let dataobject;
      let user_list1 = getValue(thisObj, "ddAddUser");
      let radioButtonNew;
      let radioButtonValue = getValue(thisObj, "radioCropYearsToView");
      if (radioButtonValue == "1") {
        radioButtonNew = "1";
      } else if (radioButtonValue == "2") {
        radioButtonNew = "99";
      } else if (radioButtonValue == "") {
        radioButtonNew = getValue(thisObj, "txtOtherCropYearsToView");
      }
      let user_list = user_list1.slice(0,7) + " , " + radioButtonNew + " | ";
      if (user_list1 == "" || typeof(user_list1) =='object') {
        alert("Please Select a User Id.");
        return;
      }
      let gridData = getValue(thisObj, "gridUsers");
      const extractObject = (txtcolUserID) => {
        return gridData.filter((row) => row.txtcolUserID === txtcolUserID);
      };
      let user_list2=user_list1.slice(0,7)
      dataobject = extractObject(user_list2);
      if (dataobject.length != 0) {
        let user_listnew = dataobject[0].txtcolUserID;
        if (user_listnew === user_list2) {
          alert("User is already listed in the grid.");
          return;
        }
      }
      let data = {
        user_list: user_list,
      };
      let response = await SystemMaintenanceSpecialFunctionsService.UpdateReportUserControl(
        data
      );
      if (![200, 400, 404].includes(response.status)) {
        showMessage(thisObj, "Add Failed.");
        return;
      }
      await bFillUsers();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during Add Button Click");
      }
      return false;
    }
    finally {
    setLoading(false);
  }
  return true;
  };
  thisObj.onbtnAddClick = onbtnAddClick;

  //Code for binding the Grid
  const bFillUsers = async () => {
    try {
      let response = await SystemMaintenanceSpecialFunctionsService.RetrieveReportUserControlDetails();
      let js = [];
      let obj = {};
      if (response !== null && response !== undefined && response.length > 0) {
        let data = response;
        for (var i = 0; i < data.length; i++) {
          obj.txtcolUserID = data[i].user_id;
          obj.txtcolUserName = data[i].user_name;
          obj.txtcolCurrentYears = data[i].years_to_view;
          js.push(obj);
          obj = {};
        }
      }
      setValue(thisObj, "gridUsers", js);
      thisObj.setState((current) => {
        return {
          ...current,
          gridUsers: {
            ...current["gridUsers"],
            valueList: js,
          },
        };
      });
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during binding the grid");
      }
      return false;
    }
  };

  const selectRow = (newRowNum) => {
    const gridData = getValue(thisObj, "gridUsers");
    let rowNum = getSelectedRowNumber(thisObj, "gridUsers");
    if (rowNum == undefined) {
      rowNum = 0;
    }
    if (
      newRowNum !== undefined &&
      selectedRowNum !== undefined &&
      gridData?.length
    ) {
      // gridData[selectedRowNum].isSelected = false;
      setSelectedRowNum(newRowNum);
      return newRowNum;
    }
  };

  const ongridUsersRowSelect = (rowItems) => {
    const rowNum = getSelectedRowNumber(thisObj, "gridUsers");
    selectRow(rowNum);
  };
  thisObj.ongridUsersRowSelect = ongridUsersRowSelect;

  //Code for Update Selected Users button Click
  const onbtnUpdatSelectedUserClick = async () => {
    try {
      setLoading(true);
      let dataobject;
      let gridData = getValue(thisObj, "gridUsers");
      const extractObject = (isSelected) => {
        return gridData.filter((row) => row.isSelected === isSelected);
      };
      dataobject = extractObject(true);
      if (dataobject.length == 0) {
        alert("User(s) must be selected in the grid.");
        return;
      }
      // let user_list1 = dataobject[0].txtcolUserID;
      let js=[]
      let obj={}
      let user_list = ""
      for (var i = 0; i < dataobject.length; i++) {
        obj = dataobject[i].txtcolUserID
        js.push(obj)
      }
      let radioButtonNew;
      let radioButtonValue = getValue(thisObj, "radioCropYearsToView");
      if (radioButtonValue == "1") {
        radioButtonNew = "1";
      } else if (radioButtonValue == "2") {
        radioButtonNew = "99";
      } else if (radioButtonValue == "") {
        radioButtonNew = getValue(thisObj, "txtOtherCropYearsToView");
      }
      // let user_list = user_list1 + " , " + radioButtonNew + " | ";
      for (let i = 0; i < js.length; i++) {
        user_list += js[i] +  " , " + radioButtonNew + " | ";
      }
      let data = {
        user_list: user_list,
      };
      let response = await SystemMaintenanceSpecialFunctionsService.UpdateReportUserControl(
        data
      );
      if (![200, 400, 404].includes(response.status)) {
        showMessage(thisObj, "Save Failed.");
        return;
      }
      await bFillUsers();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during Update Button Click");
      }
      return false;
    } finally {
      setLoading(false);
    }
    return true;
  };
  thisObj.onbtnUpdatSelectedUserClick = onbtnUpdatSelectedUserClick;

  //Code for Select All button Click
  const onbtnSelectAllClick = async () => {
    try {
      let caption = document.getElementById("btnSelectAll").innerHTML;
      let assocList = getValue(thisObj, "gridUsers");
      if (caption.toLocaleUpperCase() == "SELECT ALL") {
        for (var i = 0; i < assocList.length; i++) {
          assocList[i].isSelected = true;
        }
      }
      setValue(thisObj, "gridUsers", assocList);
    } catch (err) {
      errorHandler(err);
    }
  };
  thisObj.onbtnSelectAllClick = onbtnSelectAllClick;

  //Code for Unselect All button Click
  const onbtnUnselectAllClick = async () => {
    try {
      let caption = document.getElementById("btnUnselectAll").innerHTML;
      let assocList = getValue(thisObj, "gridUsers");
      if (caption.toLocaleUpperCase() == "UNSELECT ALL") {
        for (var i = 0; i < assocList.length; i++) {
          assocList[i].isSelected = false;
        }
      }
      setValue(thisObj, "gridUsers", assocList);
    } catch (err) {
      errorHandler(err);
    }
  };
  thisObj.onbtnUnselectAllClick = onbtnUnselectAllClick;

  //Code for delete button Click
  const onbtnDeleteSelectedUsersClick = async () => {
    try {
      setLoading(true);
      let user_list = "";
      let a;
      let array = [];
      let dataobject = [];
      let gridData = getValue(thisObj, "gridUsers");
      const extractObject = (isSelected) => {
        return gridData.filter((row) => row.isSelected === isSelected);
      };
      dataobject = extractObject(true);
      if (dataobject.length == 0) {
        alert("User(s) must be selected in the grid.");
        return;
      }
      for (let i = 0; i < dataobject.length; i++) {
        a = dataobject[i].txtcolUserID;
        array.push(a);
      }
      for (let i = 0; i < array.length; i++) {
        user_list += array[i] + " , " + " 0 " + " | ";
      }
      let data = {
        user_list: user_list,
      };
      let response = await SystemMaintenanceSpecialFunctionsService.UpdateReportUserControl(
        data
      );
      if (![200, 400, 404].includes(response.status)) {
        showMessage(thisObj, "Save Failed.");
        return;
      }
      await bFillUsers();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during Delete Button Click");
      }
      return false;
    } finally {
      setLoading(false);
    }
    return true;
  };
  thisObj.onbtnDeleteSelectedUsersClick = onbtnDeleteSelectedUsersClick;

  //Code for Request the IBM Update button Click
  const onbtnRequestTheIBMUpdateClick = async () => {
    try {
      let job_id = "P38243";
      let dataObj = {
        user_id: user_id,
        comp_id: comp_id,
        crop_year: cropYear.toString(),
      };
      let response = await AccountPayment.CreateBatchJobRequestDetails(
        job_id,
        dataObj
      );
      if (![200, 400, 404].includes(response.status)) {
        alert(
          "An error occured while communicating or updating with the database. Please contact your system administrator if the problem persist.\n\nError:\n\n" +
            response.message.slice(69)
        );
        return;
      } else {
        showMessage(
          thisObj,
          "Request for the Infopac/DocDirect security update has been submitted.",
          true
        );
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during Update Button Click");
      }
      return false;
    }
  };
  thisObj.onbtnRequestTheIBMUpdateClick = onbtnRequestTheIBMUpdateClick;

  //Code for Close button Click
  const onbtnCloseClick = () => {
    try {
      document
        .getElementById("SystemMaintenanceSpecialFunctions_SecurityReportPopUp")
        .childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName +
              "-" +
              state.windowName +
              " col-lg-12" +
              " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={(vlaues) => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SecurityReport*/}

              {/* END_USER_CODE-USER_BEFORE_SecurityReport*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSecurityReport*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSecurityReport*/}

              <GroupBoxWidget
                conf={state.grpbxSecurityReport}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxAddUser*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxAddUser*/}

                <GroupBoxWidget conf={state.grpbxAddUser} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddAddUser*/}

                  {/* END_USER_CODE-USER_BEFORE_ddAddUser*/}
                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFocus}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  <DropDownWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    conf={state.ddAddUser}
                    screenConf={state}
                  ></DropDownWidget>

                  {/* START_USER_CODE-USER_AFTER_ddAddUser*/}

                  {/* END_USER_CODE-USER_AFTER_ddAddUser*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSelectAll*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSelectAll*/}

                  <ButtonWidget
                    conf={state.btnSelectAll}
                    screenConf={state}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSelectAll*/}

                  {/* END_USER_CODE-USER_AFTER_btnSelectAll*/}
                  {/* START_USER_CODE-USER_BEFORE_btnUnselectAll*/}

                  {/* END_USER_CODE-USER_BEFORE_btnUnselectAll*/}

                  <ButtonWidget
                    conf={state.btnUnselectAll}
                    screenConf={state}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnUnselectAll*/}

                  {/* END_USER_CODE-USER_AFTER_btnUnselectAll*/}
                  {/* START_USER_CODE-USER_BEFORE_btnUpdatSelectedUser*/}

                  {/* END_USER_CODE-USER_BEFORE_btnUpdatSelectedUser*/}

                  <ButtonWidget
                    conf={state.btnUpdatSelectedUser}
                    screenConf={state}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnUpdatSelectedUser*/}

                  {/* END_USER_CODE-USER_AFTER_btnUpdatSelectedUser*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxAddUser*/}

                {/* END_USER_CODE-USER_AFTER_grpbxAddUser*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxCropYear*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCropYear*/}

                <GroupBoxWidget conf={state.grpbxCropYear} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_radioCropYearsToView*/}

                  {/* END_USER_CODE-USER_BEFORE_radioCropYearsToView*/}

                  <RadioButtonGroupWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioCropYearsToView}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioCropYearsToView*/}

                  {/* END_USER_CODE-USER_AFTER_radioCropYearsToView*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOtherCropYearsToView*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOtherCropYearsToView*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOtherCropYearsToView}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOtherCropYearsToView1}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOtherCropYearsToView*/}

                  {/* END_USER_CODE-USER_AFTER_txtOtherCropYearsToView*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCropYear*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCropYear*/}
                {/* START_USER_CODE-USER_BEFORE_gridUsers*/}

                {/* END_USER_CODE-USER_BEFORE_gridUsers*/}

                <GridWidget
                  conf={state.gridUsers}
                  screenConf={state}
                  linkClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridUsers}
                  onEvent={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  refObject={thisObj}
                  setFieldValue={setFieldValue}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridUsers*/}

                {/* END_USER_CODE-USER_AFTER_gridUsers*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSecurityReport*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSecurityReport*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnDeleteSelectedUsers*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeleteSelectedUsers*/}

                <ButtonWidget
                  conf={state.btnDeleteSelectedUsers}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeleteSelectedUsers*/}

                {/* END_USER_CODE-USER_AFTER_btnDeleteSelectedUsers*/}
                {/* START_USER_CODE-USER_BEFORE_btnRequestTheIBMUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnRequestTheIBMUpdate*/}

                <ButtonWidget
                  conf={state.btnRequestTheIBMUpdate}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnRequestTheIBMUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnRequestTheIBMUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_SecurityReport*/}

              {/* END_USER_CODE-USER_AFTER_SecurityReport*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_SecurityReport);
