/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  setData,
  getData,
  getValue,
  goTo,
  enable,
  disable,
  getSelectedRowNumber,
  LabelWidget
} from "../../shared/WindowImports";

import "./PriceScheduleAuditDetail.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_PriceScheduleAuditDetail(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PriceScheduleAuditDetail",
    windowName: "PriceScheduleAuditDetail",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.PriceScheduleAuditDetail",
    // START_USER_CODE-USER_PriceScheduleAuditDetail_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Price Schedule Audit History Details",
      scrCode: "PN0070D",
    },
    // END_USER_CODE-USER_PriceScheduleAuditDetail_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    lblNote: {
      name: "lblNote",
      type: "LabelWidget",
      parent: "cmmndCntnrClose",
      Label: "Note",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNote_PROPERTIES

      // END_USER_CODE-USER_lblNote_PROPERTIES
    },
    // btncolDiscSchd: {
    //   name: "btncolDiscSchd",
    //   type: "ButtonWidget",
    //   colName: "colDiscSchd",
    //   parent: "gridPriceScheduleAuditDetail",
    //   Label: "...",
    //   CharWidth: "9",
    //   Height: "",
    //   Width: "",

    //   ofTypeDomain: "d_boolean",
    //   // START_USER_CODE-USER_btncolDiscSchd_PROPERTIES

    //   // END_USER_CODE-USER_btncolDiscSchd_PROPERTIES
    // },
    colArea: {
      name: "colArea",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "Area",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colArea_PROPERTIES

      // END_USER_CODE-USER_colArea_PROPERTIES
    },
    colCrkBrkDoolFailPeanut: {
      name: "colCrkBrkDoolFailPeanut",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "Crk/Brk Dool Fail Peanut",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCrkBrkDoolFailPeanut_PROPERTIES

      // END_USER_CODE-USER_colCrkBrkDoolFailPeanut_PROPERTIES
    },
    colCrkBrkDoolThreshld: {
      name: "colCrkBrkDoolThreshld",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "Crk/Brk Dool Threshld",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCrkBrkDoolThreshld_PROPERTIES

      // END_USER_CODE-USER_colCrkBrkDoolThreshld_PROPERTIES
    },
    colDiscSchd: {
      name: "colDiscSchd",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "Disc Schd",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDiscSchd_PROPERTIES

      // END_USER_CODE-USER_colDiscSchd_PROPERTIES
    },
    colElkPrem: {
      name: "colElkPrem",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "Elk Prem",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colElkPrem_PROPERTIES

      // END_USER_CODE-USER_colElkPrem_PROPERTIES
    },
    colFancyThreshld: {
      name: "colFancyThreshld",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "Fancy Threshld",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFancyThreshld_PROPERTIES

      // END_USER_CODE-USER_colFancyThreshld_PROPERTIES
    },
    colFanFailPnId: {
      name: "colFanFailPnId",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "Fan Fail Pn Id",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFanFailPnId_PROPERTIES

      // END_USER_CODE-USER_colFanFailPnId_PROPERTIES
    },
    colLSKPerLbs: {
      name: "colLSKPerLbs",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "LSK Per Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLSKPerLbs_PROPERTIES

      // END_USER_CODE-USER_colLSKPerLbs_PROPERTIES
    },
    colMaxMoisture: {
      name: "colMaxMoisture",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "Max Moisture",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMaxMoisture_PROPERTIES

      // END_USER_CODE-USER_colMaxMoisture_PROPERTIES
    },
    colMinFancyElkPrem: {
      name: "colMinFancyElkPrem",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "Minimum Fancy Elk Premium",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMinFancyElkPrem_PROPERTIES

      // END_USER_CODE-USER_colMinFancyElkPrem_PROPERTIES
    },
    colOkPerPct: {
      name: "colOkPerPct",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "Ok Per Pct",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOkPerPct_PROPERTIES

      // END_USER_CODE-USER_colOkPerPct_PROPERTIES
    },
    colPeanutType: {
      name: "colPeanutType",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "Peanut Type:",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutType_PROPERTIES

      // END_USER_CODE-USER_colPeanutType_PROPERTIES
    },
    colEmpty: {
      name: "colEmpty",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEmpty_PROPERTIES

      // END_USER_CODE-USER_colEmpty_PROPERTIES
    },
    colSMKSSPerPercent: {
      name: "colSMKSSPerPercent",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "SMK SS Per Percent",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSMKSSPerPercent_PROPERTIES

      // END_USER_CODE-USER_colSMKSSPerPercent_PROPERTIES
    },
    colSupportPerTon: {
      name: "colSupportPerTon",
      type: "GridColumnWidget",
      parent: "gridPriceScheduleAuditDetail",
      Label: "Support Per Ton",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSupportPerTon_PROPERTIES

      // END_USER_CODE-USER_colSupportPerTon_PROPERTIES
    },
    gridPriceScheduleAuditDetail: {
      name: "gridPriceScheduleAuditDetail",
      type: "GridWidget",
      parent: "grpbxPriceScheduleAuditDetail",
      gridCellsOrder:
        "txtcolArea,txtcolPeanuType,txtcolEmpty,txtcolSupportPerTon,txtcolSMKSSPerPercent,txtcolOkPerPct,txtcolElkPrem,txtcolMinFancyElkPrem,txtcolLSKPerLbs,txtcolMaxMoisture,txtcolFancyThreshld,txtcolFanFailPnId,txtcolCrkBrkDoolThreshld,txtcolCrkBrkDoolFailPeanut",
      ColSpan: "4",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      conditionalRowStyles: [
        {
          when: (row) => { return row.txtcolEmpty == "old" },
          style: {
            button: {
              display: 'none'
            }
          }
        }
      ],
      // START_USER_CODE-USER_gridPriceScheduleAuditDetail_PROPERTIES

      // END_USER_CODE-USER_gridPriceScheduleAuditDetail_PROPERTIES
    },
    txtAction: {
      name: "txtAction",
      type: "TextBoxWidget",
      parent: "grpbxPriceScheduleAuditDetail",
      Label: "Action:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAction_PROPERTIES

      // END_USER_CODE-USER_txtAction_PROPERTIES
    },
    txtChangeDateTime: {
      name: "txtChangeDateTime",
      type: "TextBoxWidget",
      parent: "grpbxPriceScheduleAuditDetail",
      Label: "Change Date/Time:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangeDateTime_PROPERTIES

      // END_USER_CODE-USER_txtChangeDateTime_PROPERTIES
    },
    txtChangeUser: {
      name: "txtChangeUser",
      type: "TextBoxWidget",
      parent: "grpbxPriceScheduleAuditDetail",
      Label: "Change User:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangeUser_PROPERTIES

      // END_USER_CODE-USER_txtChangeUser_PROPERTIES
    },
    txtcolArea: {
      name: "txtcolArea",
      type: "TextBoxWidget",
      colName: "colArea",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolArea_PROPERTIES

      // END_USER_CODE-USER_txtcolArea_PROPERTIES
    },
    txtcolCrkBrkDoolFailPeanut: {
      name: "txtcolCrkBrkDoolFailPeanut",
      type: "TextBoxWidget",
      colName: "colCrkBrkDoolFailPeanut",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCrkBrkDoolFailPeanut_PROPERTIES

      // END_USER_CODE-USER_txtcolCrkBrkDoolFailPeanut_PROPERTIES
    },
    txtcolCrkBrkDoolThreshld: {
      name: "txtcolCrkBrkDoolThreshld",
      type: "TextBoxWidget",
      colName: "colCrkBrkDoolThreshld",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCrkBrkDoolThreshld_PROPERTIES

      // END_USER_CODE-USER_txtcolCrkBrkDoolThreshld_PROPERTIES
    },
    txtcolElkPrem: {
      name: "txtcolElkPrem",
      type: "TextBoxWidget",
      colName: "colElkPrem",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolElkPrem_PROPERTIES

      // END_USER_CODE-USER_txtcolElkPrem_PROPERTIES
    },
    txtcolFancyThreshld: {
      name: "txtcolFancyThreshld",
      type: "TextBoxWidget",
      colName: "colFancyThreshld",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFancyThreshld_PROPERTIES

      // END_USER_CODE-USER_txtcolFancyThreshld_PROPERTIES
    },
    txtcolFanFailPnId: {
      name: "txtcolFanFailPnId",
      type: "TextBoxWidget",
      colName: "colFanFailPnId",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFanFailPnId_PROPERTIES

      // END_USER_CODE-USER_txtcolFanFailPnId_PROPERTIES
    },
    txtcolLSKPerLbs: {
      name: "txtcolLSKPerLbs",
      type: "TextBoxWidget",
      colName: "colLSKPerLbs",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLSKPerLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolLSKPerLbs_PROPERTIES
    },
    txtcolMaxMoisture: {
      name: "txtcolMaxMoisture",
      type: "TextBoxWidget",
      colName: "colMaxMoisture",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMaxMoisture_PROPERTIES

      // END_USER_CODE-USER_txtcolMaxMoisture_PROPERTIES
    },
    txtcolMinFancyElkPrem: {
      name: "txtcolMinFancyElkPrem",
      type: "TextBoxWidget",
      colName: "colMinFancyElkPrem",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMinFancyElkPrem_PROPERTIES

      // END_USER_CODE-USER_txtcolMinFancyElkPrem_PROPERTIES
    },
    txtcolOkPerPct: {
      name: "txtcolOkPerPct",
      type: "TextBoxWidget",
      colName: "colOkPerPct",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOkPerPct_PROPERTIES

      // END_USER_CODE-USER_txtcolOkPerPct_PROPERTIES
    },
    txtcolPeanuType: {
      name: "txtcolPeanuType",
      type: "TextBoxWidget",
      colName: "colPeanutType",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanuType_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanuType_PROPERTIES
    },
    txtcolEmpty: {
      name: "txtcolEmpty",
      type: "TextBoxWidget",
      colName: "colEmpty",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolEmpty_PROPERTIES

      // END_USER_CODE-USER_txtcolEmpty_PROPERTIES
    },
    txtcolSMKSSPerPercent: {
      name: "txtcolSMKSSPerPercent",
      type: "TextBoxWidget",
      colName: "colSMKSSPerPercent",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSMKSSPerPercent_PROPERTIES

      // END_USER_CODE-USER_txtcolSMKSSPerPercent_PROPERTIES
    },
    txtcolSupportPerTon: {
      name: "txtcolSupportPerTon",
      type: "TextBoxWidget",
      colName: "colSupportPerTon",
      parent: "gridPriceScheduleAuditDetail",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSupportPerTon_PROPERTIES

      // END_USER_CODE-USER_txtcolSupportPerTon_PROPERTIES
    },
    txtRevision: {
      name: "txtRevision",
      type: "TextBoxWidget",
      parent: "grpbxPriceScheduleAuditDetail",
      Label: "Revision #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRevision_PROPERTIES

      // END_USER_CODE-USER_txtRevision_PROPERTIES
    },
    grpbxPriceScheduleAuditDetail: {
      name: "grpbxPriceScheduleAuditDetail",
      type: "GroupBoxWidget",
      parent: "PriceScheduleAuditDetail",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPriceScheduleAuditDetail_PROPERTIES

      // END_USER_CODE-USER_grpbxPriceScheduleAuditDetail_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "PriceScheduleAuditDetail",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [selectedRowNum, setSelectedRowNum] = useState(-1);
  const[loading,setLoading] = useState(false)
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  const FormLoad = async() => {
    await FillForm()
    disable(thisObj,'txtAction')
    disable(thisObj,'txtRevision')
    disable(thisObj,'txtChangeUser')
    disable(thisObj,'txtChangeDateTime')
  };
  const FillForm = async () => {
   try {
     setLoading(true);
     let priceschedule = getData(thisObj, 'frmPriceScheduleAuditDetail');
     let action_type='INQUIRY'
     let history_key_id=priceschedule.lblKey_ID_Caption
     let response = await SystemMaintenanceSpecialFunctionsService.UpdatePriceScheduleAudits(action_type,history_key_id)
     if (response != undefined && response.status=="200") {
      let res=response.result[0];
      if (res != "" && res != null) {
        setValue(thisObj, "txtAction", res.history_action);
        setValue(thisObj, "txtRevision", res.revision_num);
        setValue(thisObj, "txtChangeUser", res.chg_user);
        setValue(thisObj, "txtChangeDateTime", res.chg_date_time);
      }
      let data = res.priceschedulehists;
      let obj = {};
      let TransformedRowsArray = [];
      let strareaid="";
      let sareaid="";
       for (let i = 0; i < data.length; i++) {
        if(strareaid!=data[i].area_id)
        {
        strareaid=data[i].area_id
        sareaid=data[i].area_id
        }
        else
        sareaid="";
         TransformedRowsArray.push({
           txtcolArea: sareaid,
           txtcolPeanuType: data[i].pnut_type_id,
           txtcolEmpty: 'old',
           txtcolSupportPerTon: data[i].old_support_per_ton,
           txtcolSMKSSPerPercent: data[i].old_smk_ss_per_pct,
           txtcolOkPerPct: data[i].old_ok_per_pct,
           txtcolElkPrem: FormatNumber(data[i].old_elk_prem),
           txtcolMinFancyElkPrem: data[i].old_min_fan_elk_prem == "0.00" ? ".00" : data[i].old_min_fan_elk_prem,
           txtcolLSKPerLbs: FormatNumber(data[i].old_lsk_per_ton),
           txtcolMaxMoisture: data[i].old_max_moist,
           txtcolFancyThreshld: data[i].old_fan_threshold == "0.00" ? ".00" : data[i].old_fan_threshold,
           txtcolFanFailPnId: data[i].old_fan_fail_pn_id,
           txtcolCrkBrkDoolThreshld: data[i].old_cr_br_dcol_threshold,
           txtcolCrkBrkDoolFailPeanut: data[i].old_cr_br_dcol_fail_pn_id,
         })
         TransformedRowsArray.push({
           txtcolArea: "",
           txtcolPeanuType: "",
           txtcolEmpty: 'new',
           txtcolSupportPerTon: data[i].new_support_per_ton,
           txtcolSMKSSPerPercent: data[i].new_smk_ss_per_pct,
           txtcolOkPerPct: data[i].new_ok_per_pct,
           txtcolElkPrem: FormatNumber(data[i].new_elk_prem),
           txtcolMinFancyElkPrem: data[i].new_min_fan_elk_prem == "0.00" ? ".00" : data[i].new_min_fan_elk_prem,
           txtcolLSKPerLbs: FormatNumber(data[i].new_lsk_per_ton),
           txtcolMaxMoisture: data[i].new_max_moist,
           txtcolFancyThreshld: data[i].new_fan_threshold == "0.00" ? ".00" : data[i].new_fan_threshold,
           txtcolFanFailPnId: data[i].new_fan_fail_pn_id,
           txtcolCrkBrkDoolThreshld: data[i].new_cr_br_dcol_threshold,
           txtcolCrkBrkDoolFailPeanut: data[i].new_cr_br_dcol_fail_pn_id,
         })
       }
      setValue(thisObj, "gridPriceScheduleAuditDetail", TransformedRowsArray);
       await setColor(TransformedRowsArray)
    }
     setLoading(false);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(thisObj, "Exception in Presubmit of event code of widget:btnView: Click")
      }

      return false
    }
   return true
  }

  async function setColor(TransformedRowsArray){
    let i = 0;
    while(i < TransformedRowsArray.length){
      if(TransformedRowsArray[i].txtcolSupportPerTon !== TransformedRowsArray[i + 1].txtcolSupportPerTon){
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i}`).childNodes[3].style.color = 'red'
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i+1}`).childNodes[3].style.color = 'red'
      }
      if(TransformedRowsArray[i].txtcolSMKSSPerPercent !== TransformedRowsArray[i + 1].txtcolSMKSSPerPercent){
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i}`).childNodes[4].style.color = 'red'
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i+1}`).childNodes[4].style.color = 'red'
      }
      if(TransformedRowsArray[i].txtcolOkPerPct !== TransformedRowsArray[i + 1].txtcolOkPerPct){
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i}`).childNodes[5].style.color = 'red'
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i+1}`).childNodes[5].style.color = 'red'
      }
      if(TransformedRowsArray[i].txtcolElkPrem !== TransformedRowsArray[i + 1].txtcolElkPrem){
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i}`).childNodes[6].style.color = 'red'
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i+1}`).childNodes[6].style.color = 'red'
      } 
      if(TransformedRowsArray[i].txtcolMinFancyElkPrem !== TransformedRowsArray[i + 1].txtcolMinFancyElkPrem){
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i}`).childNodes[7].style.color = 'red'
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i+1}`).childNodes[7].style.color = 'red'
      }
      if(TransformedRowsArray[i].txtcolLSKPerLbs !== TransformedRowsArray[i + 1].txtcolLSKPerLbs){
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i}`).childNodes[8].style.color = 'red'
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i+1}`).childNodes[8].style.color = 'red'
      }
      if(TransformedRowsArray[i].txtcolMaxMoisture !== TransformedRowsArray[i + 1].txtcolMaxMoisture){
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i}`).childNodes[9].style.color = 'red'
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i+1}`).childNodes[9].style.color = 'red'
      }
      if(TransformedRowsArray[i].txtcolFancyThreshld !== TransformedRowsArray[i + 1].txtcolFancyThreshld){
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i}`).childNodes[10].style.color = 'red'
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i+1}`).childNodes[10].style.color = 'red'
      }
      if(TransformedRowsArray[i].txtcolFanFailPnId !== TransformedRowsArray[i + 1].txtcolFanFailPnId){
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i}`).childNodes[11].style.color = 'red'
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i+1}`).childNodes[11].style.color = 'red'
      }
      if(TransformedRowsArray[i].txtcolCrkBrkDoolThreshld !== TransformedRowsArray[i + 1].txtcolCrkBrkDoolThreshld){
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i}`).childNodes[12].style.color = 'red'
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i+1}`).childNodes[12].style.color = 'red'
      }
      if(TransformedRowsArray[i].txtcolCrkBrkDoolFailPeanut !== TransformedRowsArray[i + 1].txtcolCrkBrkDoolFailPeanut){
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i}`).childNodes[13].style.color = 'red'
        document.querySelector(".gridPriceScheduleAuditDetail").querySelector(`#row-${i+1}`).childNodes[13].style.color = 'red'
      }

      i = i + 2
    }
  }

  function FormatNumber(num){
    try{
      return Number(num) < 1 && Number(num) >= 0 ? num?.slice(1) : num
    }
    catch(err){

    }
  }

//Code for Double click event
const ongridPriceScheduleAuditDetailDblclick = async (event) => {
  try {
    setLoading(true)
    let x;
    let priceschedule = getData(thisObj, "frmPriceScheduleAuditDetail");
    let action_type = "INQUIRY";
    let history_key_id = priceschedule.lblKey_ID_Caption;
    let response = await SystemMaintenanceSpecialFunctionsService.UpdatePriceScheduleAudits( action_type,history_key_id);
    let res = response.result[0];
    let data = res.priceschedulehists;
    let rowNum = getSelectedRowNumber(thisObj, "gridPriceScheduleAuditDetail");
    if(rowNum%2 === 0){
      let y = 0.5 * rowNum;
      setData(thisObj, "frmDiscountScheduleAuditDetail", data[y]);
      goTo( thisObj, "SystemMaintenanceSpecialFunctions#DiscountScheduleAuditDetail#DEFAULT#true#Click" );
      }
      else if(rowNum%2 ===1){
        let x = 0.5 * rowNum - 0.5;
        setData(thisObj, "frmDiscountScheduleAuditDetail", data[x]);
        goTo( thisObj, "SystemMaintenanceSpecialFunctions#DiscountScheduleAuditDetail#DEFAULT#true#Click" );
      }
  } 
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(thisObj, "No row Selected");
    }
    return false;
  }
  finally{
    setLoading(false)
   }
};
thisObj.ongridPriceScheduleAuditDetailDblclick = ongridPriceScheduleAuditDetailDblclick;
 
 
// close button
const onbtnCloseClick = () => {
  try {
    document.getElementById("SystemMaintenanceSpecialFunctions_PriceScheduleAuditDetailPopUp").childNodes[0].click()
  } catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Exception in PreSubmit Event code for widget:btnClose event:Click"
      );
    }
    return false;
  }
  return true;
};
thisObj.onbtnCloseClick = onbtnCloseClick;

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
             <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PriceScheduleAuditDetail*/}

              {/* END_USER_CODE-USER_BEFORE_PriceScheduleAuditDetail*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPriceScheduleAuditDetail*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPriceScheduleAuditDetail*/}

              <GroupBoxWidget
                conf={state.grpbxPriceScheduleAuditDetail}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtAction*/}

                {/* END_USER_CODE-USER_BEFORE_txtAction*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAction}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAction*/}

                {/* END_USER_CODE-USER_AFTER_txtAction*/}
                {/* START_USER_CODE-USER_BEFORE_txtRevision*/}

                {/* END_USER_CODE-USER_BEFORE_txtRevision*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRevision}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRevision*/}

                {/* END_USER_CODE-USER_AFTER_txtRevision*/}
                {/* START_USER_CODE-USER_BEFORE_txtChangeUser*/}

                {/* END_USER_CODE-USER_BEFORE_txtChangeUser*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtChangeUser}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtChangeUser*/}

                {/* END_USER_CODE-USER_AFTER_txtChangeUser*/}
                {/* START_USER_CODE-USER_BEFORE_txtChangeDateTime*/}

                {/* END_USER_CODE-USER_BEFORE_txtChangeDateTime*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtChangeDateTime}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtChangeDateTime*/}

                {/* END_USER_CODE-USER_AFTER_txtChangeDateTime*/}
                {/* START_USER_CODE-USER_BEFORE_gridPriceScheduleAuditDetail*/}

                {/* END_USER_CODE-USER_BEFORE_gridPriceScheduleAuditDetail*/}

                <GridWidget
                  conf={state.gridPriceScheduleAuditDetail}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPriceScheduleAuditDetail}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPriceScheduleAuditDetail*/}

                {/* END_USER_CODE-USER_AFTER_gridPriceScheduleAuditDetail*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPriceScheduleAuditDetail*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPriceScheduleAuditDetail*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}
                <LabelWidget
                  values={values}
                  conf={state.lblNote}
                  screenConf={state}
                ></LabelWidget>
                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_PriceScheduleAuditDetail*/}

              {/* END_USER_CODE-USER_AFTER_PriceScheduleAuditDetail*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_PriceScheduleAuditDetail
);
