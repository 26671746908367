/* eslint-disable*/
import React from "react";
import ContractManagement_ContractExceptPremProfile from "./ContractExceptPremProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ContractExceptPremProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractExceptPremProfile />);
    });
  });
  afterEach(cleanup);
  test("is ContractExceptPremProfile Loads Successfully", () => {
    expect(screen.getByText("ContractExceptionPremiumProfile"))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for ContractExceptPremProfile", () => {
    // START_USER_CODE-USER_ContractExceptPremProfile_Custom_Test_Case
    // END_USER_CODE-USER_ContractExceptPremProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractExceptPremProfile />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("ContractManagement:ContractExceptPremProfile_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("ContractManagement:ContractExceptPremProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("grpbxAddCancel(GroupBox Widget) Test Cases", async () => {
    const grpbxAddCancel = screen.getByTestId("grpbxAddCancel");
    expect(grpbxAddCancel.tagName).toBe("BUTTON");
    expect(grpbxAddCancel.type).toBe("button");
    expect(grpbxAddCancel.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAddCancel", () => {
    // START_USER_CODE-USER_grpbxAddCancel_TEST
    // END_USER_CODE-USER_grpbxAddCancel_TEST
  });
  test("grpbxBuyingPoint(GroupBox Widget) Test Cases", async () => {
    const grpbxBuyingPoint = screen.getByTestId("grpbxBuyingPoint");
    expect(grpbxBuyingPoint.tagName).toBe("BUTTON");
    expect(grpbxBuyingPoint.type).toBe("button");
    expect(grpbxBuyingPoint.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBuyingPoint", () => {
    // START_USER_CODE-USER_grpbxBuyingPoint_TEST
    // END_USER_CODE-USER_grpbxBuyingPoint_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("ContractManagement:ContractExceptPremProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblBuyingPoint(Label Widget) Test Cases", async () => {
    const lblBuyingPoint = screen.getByTestId("lblBuyingPoint");
    expect(lblBuyingPoint.tagName).toBe("LABEL");
    expect(lblBuyingPoint.classList).toContain("form-label");
    expect(lblBuyingPoint.textContent).toEqual(
      t("ContractManagement:ContractExceptPremProfile_lblBuyingPoint")
    );
  });
  test("Custom Test Cases for lblBuyingPoint", () => {
    // START_USER_CODE-USER_lblBuyingPoint_TEST
    // END_USER_CODE-USER_lblBuyingPoint_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("ContractManagement:ContractExceptPremProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("txtRate(Textbox Widget) Test Cases", async () => {
    const txtRate = screen.getByTestId("txtRate");
    expect(txtRate.tagName).toBe("INPUT");
    expect(txtRate.type).toBe("text");
    expect(txtRate.classList).toContain("textboxWidgetClass");
    expect(txtRate.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractExceptPremProfile_txtRate")
    );
    await act(async () => {
      userEvent.type(txtRate, "1");
    });
  });
  test("Custom Test Cases for txtRate", () => {
    // START_USER_CODE-USER_txtRate_TEST
    // END_USER_CODE-USER_txtRate_TEST
  });
  test("txtRateBase(Textbox Widget) Test Cases", async () => {
    const txtRateBase = screen.getByTestId("txtRateBase");
    expect(txtRateBase.tagName).toBe("INPUT");
    expect(txtRateBase.type).toBe("text");
    expect(txtRateBase.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRateBase, "1");
    });
  });
  test("Custom Test Cases for txtRateBase", () => {
    // START_USER_CODE-USER_txtRateBase_TEST
    // END_USER_CODE-USER_txtRateBase_TEST
  });
});
