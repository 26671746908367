/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  TextAreaWidget,
  setValue,
  getValue,
  getData,
  setData,
  disable,
  enable,
  show,
  hide,
  goTo,
  isEnabled,
  CheckboxWidget,
  getGridValue,
  getRowCount,
  hideColumn
} from "../../shared/WindowImports";

import "./ReadyToPayRelease.scss";

import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { AccountPayment } from "../Service/AccountPayment";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
function AccountPayment_ReadyToPayRelease(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));

  let states = {
    Label: "ReadyToPayRelease",
    windowName: "ReadyToPayRelease",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "AccountPayment.ReadyToPayRelease",
    horizontalForm: true,
    headerData: {
      scrName: "Ready To Pay Release",
      scrCode: "PN1100A",
    },

    btnArr1: {
      name: "btnArr1",
      type: "ButtonWidget",
      parent: "grpbxArrows",
      Label: ">>>",
      CharWidth: "9",
    },
    btnArr2: {
      name: "btnArr2",
      type: "ButtonWidget",
      parent: "grpbxArrows",
      Label: "<<<",
      CharWidth: "9",
    },
    btnBrowse: {
      name: "btnBrowse",
      type: "ButtonWidget",
      parent: "grpbxDetails",
      CharWidth: "4",
      HasLabel: false,
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",
    },
    btnPrintCheck: {
      name: "btnPrintCheck",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print Check",
      CharWidth: "24",
    },
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Save",
      CharWidth: "11",
    },
    btnSaveToBatch: {
      name: "btnSaveToBatch",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Save To Batch",
      CharWidth: "28",
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxDetails",
      Label: "Search",
      CharWidth: "15",
      ofTypeDomain: "d_boolean",
    },
    colDateH: {
      name: "colDateH",
      type: "GridColumnWidget",
      parent: "gridReadyToPayHold",
      Label: "Date",
      Height: "",
      Width: "",
    },
    colDateR: {
      name: "colDateR",
      type: "GridColumnWidget",
      parent: "gridReadyToPayReleased",
      Label: "Date",
      Height: "",
      Width: "",
    },
    colNumber1H: {
      name: "colNumber1H",
      type: "GridColumnWidget",
      parent: "gridReadyToPayHold",
      Label: "Number 1",
      Height: "",
      Width: "",
    },
    colNumber1R: {
      name: "colNumber1R",
      type: "GridColumnWidget",
      parent: "gridReadyToPayReleased",
      Label: "Number 1",
      Height: "",
      Width: "",
    },
    colNumber2H: {
      name: "colNumber2H",
      type: "GridColumnWidget",
      parent: "gridReadyToPayHold",
      Label: "Number 2",
      Height: "",
      Width: "",
    },
    colNumber2R: {
      name: "colNumber2R",
      type: "GridColumnWidget",
      parent: "gridReadyToPayReleased",
      Label: "Number 2",
      Height: "",
      Width: "",
    },
    colOptH: {
      name: "colOptH",
      type: "GridColumnWidget",
      parent: "gridReadyToPayHold",
      Label: "Opt",
      Height: "",
      Width: "",
    },
    colOptR: {
      name: "colOptR",
      type: "GridColumnWidget",
      parent: "gridReadyToPayReleased",
      Label: "Opt",
      Height: "",
      Width: "",
    },
    colProceedsH: {
      name: "colProceedsH",
      type: "GridColumnWidget",
      parent: "gridReadyToPayHold",
      Label: "Proceeds",
      Height: "",
      Width: "",
    },
    colProceedsR: {
      name: "colProceedsR",
      type: "GridColumnWidget",
      parent: "gridReadyToPayReleased",
      Label: "Proceeds",
      Height: "",
      Width: "",
    },
    colRemitH: {
      name: "colRemitH",
      type: "GridColumnWidget",
      parent: "gridReadyToPayHold",
      Label: "Remit",
      Height: "",
      Width: "",
    },
    colRemitR: {
      name: "colRemitR",
      type: "GridColumnWidget",
      parent: "gridReadyToPayReleased",
      Label: "Remit",
      Height: "",
      Width: "",
    },
    colSetlDateH: {
      name: "colSetlDateH",
      type: "GridColumnWidget",
      parent: "gridReadyToPayHold",
      Label: "Setl. Date",
      Height: "",
      Width: "",
    },
    colSetlDateR: {
      name: "colSetlDateR",
      type: "GridColumnWidget",
      parent: "gridReadyToPayReleased",
      Label: "Setl. Date",
      Height: "",
      Width: "",
    },
    colType1H: {
      name: "colType1H",
      type: "GridColumnWidget",
      parent: "gridReadyToPayHold",
      Label: "Type 1",
      Height: "",
      Width: "",
    },
    colType1R: {
      name: "colType1R",
      type: "GridColumnWidget",
      parent: "gridReadyToPayReleased",
      Label: "Type 1",
      Height: "",
      Width: "",
    },
    colType2H: {
      name: "colType2H",
      type: "GridColumnWidget",
      parent: "gridReadyToPayHold",
      Label: "Type 2",
      Height: "",
      Width: "",
    },
    colType2R: {
      name: "colType2R",
      type: "GridColumnWidget",
      parent: "gridReadyToPayReleased",
      Label: "Type 2",
      Height: "",
      Width: "",
    },
    colWireXferDataH: {
      name: "colWireXferDataH",
      type: "GridColumnWidget",
      parent: "gridReadyToPayHold",
      Label: "Wire Xfer Data",
      Height: "",
      Width: "",
    },
    colColorH: {
      name: "colColorH",
      type: "GridColumnWidget",
      parent: "gridReadyToPayHold",
      Label: "Color",
      Height: "",
      Width: "",
    },
    colColorR: {
      name: "colColorR",
      type: "GridColumnWidget",
      parent: "gridReadyToPayReleased",
      Label: "Color",
      Height: "",
      Width: "",
    },
    colWireXferDataR: {
      name: "colWireXferDataR",
      type: "GridColumnWidget",
      parent: "gridReadyToPayReleased",
      Label: "Wire Xfer Data",
      Height: "",
      Width: "",
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Location",
      ColSpan: "4",
      ColSpanForLargeDesktop: "4",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
    },
    ddremitTo: {
      name: "ddremitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Remit To:",
      ColSpan: "4",
      ColSpanForLargeDesktop: "4",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxRemit",
      Label: "Remit To:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
    },
    gridReadyToPayHold: {
      name: "gridReadyToPayHold",
      type: "GridWidget",
      parent: "grpbxReadyToPay",
      gridCellsOrder:
        "txtcolType1H,txtcolNumber1H,txtcolType2H,txtcolNumber2H,txtcolProceedsH,txtcolOptH,txtcolDateH,txtcolSetlDateH,txtcolRemitH,txtcolWireXferDataH,txtcolColorH",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isMultiSelect: true,
    },
    gridReadyToPayReleased: {
      name: "gridReadyToPayReleased",
      type: "GridWidget",
      parent: "grpbxReadyToPay",
      gridCellsOrder:
        "txtcolType1R,txtcolNumber1R,txtcolType2R,txtcolNumber2R,txtcolProceedsR,txtcolOptR,txtcolDateR,txtcolSetlDateR,txtcolRemitR,txtcolWireXferDataR,txtcolColorR",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isMultiSelect: true, 
    },
    grpbxArrows: {
      name: "grpbxArrows",
      type: "GroupBoxWidget",
      parent: "grpbxReadyToPay",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
    },
    labelwidget49: {
      name: "labelwidget49",
      type: "LabelWidget",
      parent: "grpbxBalance",
      Label: "Check Information",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblARBalance: {
      name: "lblARBalance",
      type: "LabelWidget",
      parent: "grpbxBalance",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblBalance: {
      name: "lblBalance",
      type: "LabelWidget",
      parent: "grpbxBalance",
      Label: "Balance",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblCorpYear2: {
      name: "lblCorpYear2",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "lblCorpYear2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblCRBalance: {
      name: "lblCRBalance",
      type: "LabelWidget",
      parent: "grpbxBalance",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblLienholders: {
      name: "lblLienholders",
      type: "LabelWidget",
      parent: "grpbxRemit",
      Label: "Lienholders:",
      ColSpanForLargeDesktop: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblLienholdersValue: {
      name: "lblLienholdersValue",
      type: "LabelWidget",
      parent: "grpbxRemit",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblNOTE: {
      name: "lblNOTE",
      type: "LabelWidget",
      parent: "grpbxNoteChck",
      Label: "Leave as ALL unless approved by Collection Point",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblRTPsHold: {
      name: "lblRTPsHold",
      type: "LabelWidget",
      parent: "grpbxReadyToPay",
      Label: "Ready To Pays On Hold:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblRTPsHoldValue: {
      name: "lblRTPsHoldValue",
      type: "LabelWidget",
      parent: "grpbxReadyToPay",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblRTPsReleased: {
      name: "lblRTPsReleased",
      type: "LabelWidget",
      parent: "grpbxReadyToPay",
      Label: "Released Ready To Pays:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblRTPsReleasedValue: {
      name: "lblRTPsReleasedValue",
      type: "LabelWidget",
      parent: "grpbxReadyToPay",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblSaveNote: {
      name: "lblSaveNote",
      type: "LabelWidget",
      parent: "grpbxRemit",
      Label:
        "**The Save button will only save the hold/released status of the ready topays and will not save the remarks",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblSRBalance: {
      name: "lblSRBalance",
      type: "LabelWidget",
      parent: "grpbxBalance",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblValOfRTP: {
      name: "lblValOfRTP",
      type: "LabelWidget",
      parent: "grpbxBalance",
      Label: "Value Of RTP's:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblValOfRTPValue: {
      name: "lblValOfRTPValue",
      type: "LabelWidget",
      parent: "grpbxBalance",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblValueOfCheck: {
      name: "lblValueOfCheck",
      type: "LabelWidget",
      parent: "grpbxBalance",
      Label: "Value Of Check:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblValueOfCheckValue: {
      name: "lblValueOfCheckValue",
      type: "LabelWidget",
      parent: "grpbxBalance",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtarRemarks: {
      name: "txtarRemarks",
      type: "TextAreaWidget",
      parent: "grpbxRemit",
      Label: "Remarks:",
      ColSpan: "2",
      ColSpanForLargeDesktop: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCarrierAbbr: {
      name: "txtCarrierAbbr",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Carrier Abbr",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
    },
    txtVndor: {
      name: "txtVndor",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: " ",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      Enabled: false,
    },
    txtCheck: {
      name: "txtCheck",
      type: "TextBoxWidget",
      parent: "grpbxBalance",
      Label: "Check #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolDateH: {
      name: "txtcolDateH",
      type: "TextBoxWidget",
      colName: "colDateH",
      parent: "gridReadyToPayHold",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolDateR: {
      name: "txtcolDateR",
      type: "TextBoxWidget",
      colName: "colDateR",
      parent: "gridReadyToPayReleased",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCollectAR: {
      name: "txtCollectAR",
      type: "TextBoxWidget",
      parent: "grpbxBalance",
      Label: "Collect AR:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCollectCR: {
      name: "txtCollectCR",
      type: "TextBoxWidget",
      parent: "grpbxBalance",
      Label: "Collect CR:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCollectSR: {
      name: "txtCollectSR",
      type: "TextBoxWidget",
      parent: "grpbxBalance",
      Label: "Collect SR:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolNumber1H: {
      name: "txtcolNumber1H",
      type: "TextBoxWidget",
      colName: "colNumber1H",
      parent: "gridReadyToPayHold",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolNumber1R: {
      name: "txtcolNumber1R",
      type: "TextBoxWidget",
      colName: "colNumber1R",
      parent: "gridReadyToPayReleased",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolNumber2H: {
      name: "txtcolNumber2H",
      type: "TextBoxWidget",
      colName: "colNumber2H",
      parent: "gridReadyToPayHold",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolNumber2R: {
      name: "txtcolNumber2R",
      type: "TextBoxWidget",
      colName: "colNumber2R",
      parent: "gridReadyToPayReleased",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolOptH: {
      name: "txtcolOptH",
      type: "TextBoxWidget",
      colName: "colOptH",
      parent: "gridReadyToPayHold",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolOptR: {
      name: "txtcolOptR",
      type: "TextBoxWidget",
      colName: "colOptR",
      parent: "gridReadyToPayReleased",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolProceedsH: {
      name: "txtcolProceedsH",
      type: "TextBoxWidget",
      colName: "colProceedsH",
      parent: "gridReadyToPayHold",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolProceedsR: {
      name: "txtcolProceedsR",
      type: "TextBoxWidget",
      colName: "colProceedsR",
      parent: "gridReadyToPayReleased",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolRemitH: {
      name: "txtcolRemitH",
      type: "TextBoxWidget",
      colName: "colRemitH",
      parent: "gridReadyToPayHold",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolRemitR: {
      name: "txtcolRemitR",
      type: "TextBoxWidget",
      colName: "colRemitR",
      parent: "gridReadyToPayReleased",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolSetlDateH: {
      name: "txtcolSetlDateH",
      type: "TextBoxWidget",
      colName: "colSetlDateH",
      parent: "gridReadyToPayHold",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolSetlDateR: {
      name: "txtcolSetlDateR",
      type: "TextBoxWidget",
      colName: "colSetlDateR",
      parent: "gridReadyToPayReleased",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolType1H: {
      name: "txtcolType1H",
      type: "TextBoxWidget",
      colName: "colType1H",
      parent: "gridReadyToPayHold",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolType1R: {
      name: "txtcolType1R",
      type: "TextBoxWidget",
      colName: "colType1R",
      parent: "gridReadyToPayReleased",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolType2H: {
      name: "txtcolType2H",
      type: "TextBoxWidget",
      colName: "colType2H",
      parent: "gridReadyToPayHold",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolType2R: {
      name: "txtcolType2R",
      type: "TextBoxWidget",
      colName: "colType2R",
      parent: "gridReadyToPayReleased",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolWireXferDataH: {
      name: "txtcolWireXferDataH",
      type: "TextBoxWidget",
      colName: "colWireXferDataH",
      parent: "gridReadyToPayHold",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolColorH: {
      name: "txtcolColorH",
      type: "TextBoxWidget",
      colName: "colColorH",
      parent: "gridReadyToPayHold",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Hidden : true
    },
    txtcolColorR: {
      name: "txtcolColorR",
      type: "TextBoxWidget",
      colName: "colColorR",
      parent: "gridReadyToPayReleased",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Hidden : true
    },
    txtcolWireXferDataR: {
      name: "txtcolWireXferDataR",
      type: "TextBoxWidget",
      colName: "colWireXferDataR",
      parent: "gridReadyToPayReleased",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtEndCheckNum: {
      name: "txtEndCheckNum",
      type: "TextBoxWidget",
      parent: "grpbxBalance",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtFarm: {
      name: "txtFarm",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Farm",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
    },
    txtFarmSuffix: {
      name: "txtFarmSuffix",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Farm Suffix",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Vendor #:",
      ColSpan: "2",
      ColSpanForLargeDesktop: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
    },
    grpbxDetails: {
      name: "grpbxDetails",
      type: "GroupBoxWidget",
      parent: "ReadyToPayRelease",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      ColsForLargeDesktop: "4",
    },
    grpbxNoteChck: {
      name: "grpbxNoteChck",
      type: "GroupBoxWidget",
      parent: "ReadyToPayRelease",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      ColsForLargeDesktop: "1",
    },
    grpbxReadyToPay: {
      name: "grpbxReadyToPay",
      type: "GroupBoxWidget",
      parent: "ReadyToPayRelease",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
    },
    grpbxRemit: {
      name: "grpbxRemit",
      type: "GroupBoxWidget",
      parent: "ReadyToPayRelease",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      ColsForLargeDesktop: "1",
    },
    grpbxBalance: {
      name: "grpbxBalance",
      type: "GroupBoxWidget",
      parent: "ReadyToPayRelease",
      ColSpan: "1",
      Height: "",
      Width: "",
      clonedExtId: "36111",
      HasLabel: false,
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ReadyToPayRelease",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      ColsForLargeDesktop: "5",
    },
    _dataCopy: {
      FORWARD: { "ContractManagement#MainMenu": {}, },
      REVERSE: { "ContractManagement#MainMenu": {}, },
    },
  };

  let _buttonServices = {};
  
  let _buttonNavigation = {
    btnCancel: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click",],
    },
    btnBrowse: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
  };
  
  let _winServices = {};
  let checkNumber = ''
  let LstrReceivableNum = ''
  let strRemit = ''
  let bRemitDiffer = false;
  let lblValOfRTPValue = ''
  let dblRtpProceeds = 0
  let permissions = {
    'addData'    : '',
    'removeData' : ''
  }
  let lblCollectionPoint = ''
  let textCheckNumber = ''
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });

  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null) {
      show(thisObj, 'txtVndor')
      enable(thisObj, 'btnSearch')
      setValue(thisObj,"txtVendor",vendor_lookup.VendorNumber)
      RemitToList(vendor_lookup.VendorNumber)
      txtVendor.value = vendor_lookup.VendorNumber
      setValue(thisObj, "txtVndor", vendor_lookup.vendorName)
      AccountPayment.RetrieveScacVendorLookupDetails(vendor_lookup.VendorNumber, '').then(response => {
      if (response.length > 0) {
        setValue(thisObj, 'txtCarrierAbbr', response[0].scac)
      } else {
        setValue(thisObj, 'txtCarrierAbbr', '')
      }
      });
    }else{
    hide(thisObj, 'txtVndor')
    disable(thisObj, 'btnSearch')
    setValue(thisObj, 'txtVendor', '')
    setValue(thisObj, 'txtVndor' , '')
    }
  }, [getData(thisObj, 'vendorDetails')]);
  
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, "ddLocation", '')
    setValue(thisObj, "ddremitTo", '')
    setValue(thisObj, "ddRemitTo", '')
    FormLoad();
  }, []);

  const setRowColor = async() => {
    let n = getRowCount(_kaledo.thisObj, 'gridReadyToPayHold');
    for (let i = 0; i < n; i++) {
      let val = getGridValue(_kaledo.thisObj, 'gridReadyToPayHold', 'txtcolColorH', i); ``
      if (val == "&HC0FFC0") {
        document.getElementById(`row-${i}`).style.background = "#00ffc8"
      }
      if (val == "&HE0E0E0") {
        document.getElementById(`row-${i}`).style.background = "pink"
      }
    }

    let n1 = getRowCount(_kaledo.thisObj, 'gridReadyToPayReleased');
    for (let i = 0; i < n1; i++) {
      let val = getGridValue(_kaledo.thisObj, 'gridReadyToPayReleased', 'txtcolColorR', i); ``
      if (val == "&HC0FFC0") {
        document.getElementById(`row-${i}`).style.background = "#00ffc8"
      }
      if (val == "&HE0E0E0") {
             // document.getElementById(`row-${i}`).style.background = "pink"
             document.querySelector("#gridReadyToPayReleasedCollapse").querySelector(`#row-${i}`).style.backgroundColor="pink"
      }
    }
  }

  async function FormLoad() {
    disable(thisObj, 'btnSearch')
    disable(thisObj, 'btnArr1')
    disable(thisObj, 'btnArr2')
    disable(thisObj, 'btnSave')
    disable(thisObj, 'btnSaveToBatch')
    disable(thisObj, 'btnPrintCheck')
    setValue(thisObj, 'lblRTPsHoldValue', 0)
    setValue(thisObj, 'lblRTPsReleasedValue' , 0)
    bFillBuyingPointList();
    setValue(thisObj, 'txtVendor', '')
    setValue(thisObj, 'txtVndor' , '')
    setData(thisObj,"vendorDetails",null)
    disable(thisObj, 'txtCheck')      
    disable(thisObj, 'txtCollectSR')
    disable(thisObj, 'txtCollectAR')
    disable(thisObj, 'txtCollectCR') 

    disable(thisObj, 'lblValOfRTPValue')
    disable(thisObj, 'lblSRBalance')
    disable(thisObj, 'lblARBalance')
    disable(thisObj, 'lblCRBalance')
    setData(thisObj, 'gridReadyToPayHold', [])
    setData(thisObj, 'gridReadyToPayReleased', [])
    let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN1100', '004', 'U', null)
    permissions.addData = response.length > 0 ? response[0].permission : ''

    response = await ContractManagementService.RetrieveAccessPermissionDetails('PN1100', '001', 'U', null)
    permissions.removeData = response.length > 0 ? response[0].permission : ''
    setData(thisObj, 'permissions', {
      addData : permissions.addData,
      removeData : permissions.removeData
    })
    EnableDisable()
    hide(thisObj, 'txtCheck')
    hide(thisObj, 'txtEndCheckNum')
    setValue(thisObj, 'lblCorpYear2', 'Crop Year: ' + cropYearFromLS())
    setLoading(false)
  }

  async function Calc(){
    try{
      let nn = 0;
      let releaseList = getData(thisObj, 'gridReadyToPayReleased')
      
      for(var i=0; i<releaseList.length; i++)
        nn += Number(releaseList[i].txtcolProceedsR)
      
      setValue(thisObj, 'lblValOfRTPValue', (parseFloat(nn)).toFixed(2))
      setData(thisObj, 'lblValOfRTPValue', (parseFloat(nn)).toFixed(2))
      
      if(nn <= 0)
        await ClearTexts();
      let arValue = Number(txtCollectAR.value) == NaN ? 0 : Number(txtCollectAR.value)
      let crValue = Number(txtCollectCR.value) == NaN ? 0 : Number(txtCollectCR.value)
      let srValue = Number(txtCollectSR.value) == NaN ? 0 : Number(txtCollectSR.value)

      let valueOfCheck = nn - arValue - crValue - srValue
      
      setValue(thisObj, 'lblValueOfCheckValue', parseFloat(valueOfCheck).toFixed(2))
      setData(thisObj, 'lblValueOfCheckValue', parseFloat(valueOfCheck).toFixed(2))
      lblValueofCheck_Check()
      await EnableDisableTextBoxes();
      await EnableDisable();      
    }
    catch(err){
      errorHandler(err);
    }
  }

  async function ClearTexts(){
    try{
      setValue(thisObj, 'txtCollectAR', '0.00')
      setValue(thisObj, 'txtCollectCR', '0.00')
      setValue(thisObj, 'txtCollectSR', '0.00')
    }
    catch(err){
      errorHandler(err);
    }
  }

  async function EnableDisableTextBoxes(){
    try{      
      if(getData(thisObj, 'lblValOfRTPValue') <= 0){
        await ClearTexts();
        await EnableDisableAll(false);
        return;
      }
      
      if(parseInt(getData(thisObj, 'lblSRBalance')) > 0)
        enable(thisObj, 'txtCollectSR')
      if(parseInt(getData(thisObj, 'lblARBalance')) > 0)
        enable(thisObj, 'txtCollectAR')
      if(parseInt(getData(thisObj, 'lblCRBalance')) > 0)
        enable(thisObj, 'txtCollectCR')
    }
    catch(err){
      errorHandler(err);
    }
  }

  async function EnableDisable(){
    try{
      if(txtVendor.value == ''){
        disable(thisObj, 'btnSearch')
      }
      else{
        enable(thisObj, 'btnSearch')
      }
      let holdLst = getData(thisObj, 'gridReadyToPayHold');
      let relsLst = getData(thisObj, 'gridReadyToPayReleased');
      
      if(holdLst.length > 0 || relsLst.length > 0){
        enable(thisObj, 'btnArr1')
        enable(thisObj, 'btnArr2')
      }                  
      
      disable(thisObj, 'btnPrintCheck')
      disable(thisObj, 'btnSaveToBatch')
      disable(thisObj, 'btnSave')
      
      if(relsLst.length > 0){
        enable(thisObj, 'btnPrintCheck')
        enable(thisObj, 'btnSaveToBatch')
      }
      else
        disable(thisObj, 'btnPrintCheck')
      
      if(relsLst.length > 0 || holdLst.length > 0)
        enable(thisObj, 'btnSave')
      else
        disable(thisObj, 'btnSave')
    }
    catch(err){
      errorHandler(err);
    }
  }

  async function EnableDisableAll(indBln){
    try{
      if(indBln){
        enable(thisObj, 'txtCollectAR')
        enable(thisObj, 'txtCollectCR')
        enable(thisObj, 'txtCollectSR')
      }
      else{
        disable(thisObj, 'txtCollectAR')
        disable(thisObj, 'txtCollectCR')
        disable(thisObj, 'txtCollectSR')
      }
    }
    catch(err){
      errorHandler(err);
    }
  }

  function ConvertToPascalCase(word){
    return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  }

  function GetFormattedDate(date){
    if(date.getFullYear() == 1)
      return '';
    let formattedDate = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : date.getMonth() + 1
    formattedDate += '/'
    formattedDate += (date.getDate() < 10 ? '0' + (date.getDate()).toString() : date.getDate())
    formattedDate += ('/' + date.getFullYear())
    return formattedDate
  }

  function bAuditPad(strAudit){
    if(strAudit.length < 7 && strAudit.length > 0){
      if(strAudit.length == 1)
        return '000000' + strAudit
      if(strAudit.length == 2)
        return '00000' + strAudit
      if(strAudit.length == 3)
        return '0000' + strAudit
      if(strAudit.length == 4)
        return '000' + strAudit
      if(strAudit.length == 5)
        return '00' + strAudit
      if(strAudit.length == 6)
        return '0' + strAudit
    }
  }

  function checkAgainstValueOfCheck(txtValue){
    try{
      let ldblValueOfCheck = 0 
      let valueOfRTP = lblValOfRTPValue 
      if(valueOfRTP !== ''){
        if(txtValue !== txtCollectSR.value)
          ldblValueOfCheck += txtCollectSR.value
        if(txtValue !== txtCollectAR.value)
          ldblValueOfCheck += txtCollectAR.value
        if(txtValue !== txtCollectCR.value)
          ldblValueOfCheck += txtCollectCR.value
        if(txtValue + ldblValueOfCheck > valueOfRTP)
          txtValue = parseInt(valueOfRTP) - ldblValueOfCheck
        Calc();
      }
    }
    catch(err){
      errorHandler(err);
    }
  }

  function ControlReset() {
    try {
      setValue(thisObj, "ddremitTo", '')
      setValue(thisObj, "ddRemitTo", '')

      setValue(thisObj, "gridReadyToPayHold", []);
      setValue(thisObj, 'lblRTPsHoldValue', 0)

      setValue(thisObj, "gridReadyToPayReleased", []);
      setValue(thisObj, 'lblRTPsReleasedValue', 0)

      setValue(thisObj, 'txtVendor', '')
      setValue(thisObj, 'txtVndor', '')
      txtVendor.value = ''
      txtVndor.value = ''
      setValue(thisObj, 'txtVendor', '')

      disable(thisObj, 'btnSearch')
      disable(thisObj, 'btnSave')
      disable(thisObj, 'btnSaveToBatch')
      disable(thisObj, 'btnPrintCheck')

      setValue(thisObj, 'lblLienholders', '')
      setValue(thisObj, 'lblLienholdersValue', '')
      setValue(thisObj, 'txtarRemarks', '')

      setValue(thisObj, 'txtCheck', '')
      setValue(thisObj, 'txtEndCheckNum', '')
      setValue(thisObj, 'txtCollectAR', '')
      setValue(thisObj, 'txtCollectCR', '')
      setValue(thisObj, 'txtCollectSR', '')
      setValue(thisObj, 'lblValueOfCheckValue', '')
      setValue(thisObj, 'lblValOfRTPValue', '')
      setValue(thisObj, 'lblARBalance', '')
      setValue(thisObj, 'lblCRBalance', '')
      setValue(thisObj, 'lblSRBalance', '')

      thisObj.setState(current => {
        return {
          ...current,
          ddremitTo: {
            ...state["ddremitTo"],
            valueList: [ { key: '', description: '>>> ALL <<<' } ]
          }
        }
      })
      thisObj.setState(current => {
        return {
          ...current,
          ddremitTo: {
            ...state["ddRemitTo"],
            valueList: []
          }
        }
      })
      setData(thisObj, 'remitTo', [])
      setData(thisObj, 'RemitTo', [])
    }
    catch(err) {
      errorHandler(err);
    }
  }

  function bCheck_AR_CR_SR(){
    try{
      let bCheck = false;
      if(getData(thisObj, 'lblARBalance') > 0 && txtCollectAR.value == 0)
        bCheck = true
      if(getData(thisObj, 'lblCRBalance') > 0 && txtCollectCR.value == 0)
        bCheck = true
      if(getData(thisObj, 'lblSRBalance') > 0 && txtCollectSR.value == 0)
        bCheck = true
      return bCheck;
    }
    catch(err){
      errorHandler(err);
    }    
  }

  function bSettlePad(strSettle){
    try{  
      strSettle = strSettle.toString()
      if (strSettle.length == 0)
        return "0000000"
      if (strSettle.length == 1)
        return "000000" + strSettle
      if (strSettle.length == 2)
        return "00000" + strSettle
      if (strSettle.length == 3)
        return "0000" + strSettle
      if (strSettle.length == 4)
        return "000" + strSettle
      if (strSettle.length == 5)
        return "00" + strSettle
      if (strSettle.length == 6)
        return "0" + strSettle
      if (strSettle.length == 7)
          return strSettle      
    }
    catch(err){
      errorHandler(err);
    }
  }

  function FormatCheckNumber(lstrCheckNumber){
    try{
      lstrCheckNumber = lstrCheckNumber.toString()
      if(lstrCheckNumber.indexOf('S') !== -1)
        return 'S' + '0'.repeat(5- lstrCheckNumber.length) + lstrCheckNumber      
      return '0'.repeat(6- lstrCheckNumber.length) + lstrCheckNumber      
    } 
    catch(err){
      errorHandler(err);
    }
  }

  function lblValueofCheck_Check(){
    try{
      let value  = getValue(thisObj, 'lblValueOfCheckValue')
      let txtCheck = ''
      let LstrReceivableNum = getData(thisObj, 'LstrReceivableNum')
      if(Number(value) > 0)
        txtCheck = FormatCheckNumber(checkNumber)
      else
        txtCheck = 'S' + '0'.repeat(5 - LstrReceivableNum.toString().length) + LstrReceivableNum

      setData(thisObj, 'textCheckNumber', txtCheck)
      EnableDisable()
    }
    catch(err){
      errorHandler(err);
    }
  }

  function GetTransSumProceeds(lstrxml, strMemoNum, dblRtpProceeds){
    try{
      let strTransNum = "";
      let strTransNum1 = '';
      let dblAmount = 0;

      for(var i=0; i<lstrxml.length; i++){
        if(lstrxml[i].memo_num !== "" && strMemoNum == lstrxml[i].memo_num){
          strTransNum += (lstrxml[i].transaction_num + ",")
          dblAmount += Number(lstrxml[i].rtp_proceeds)
        }
        else{
          break;
        }
      }
      //let index = strTransNum.indexOf(',')
      let index = strTransNum.lastIndexOf(',')
      if(index > 0){
        //strTransNum = strTransNum.slice((index * -1) -1)
        strTransNum1 = strTransNum.slice(0, index)
      }
      setData(thisObj, 'dblRtpProceeds', dblAmount)
      //return strTransNum
      return strTransNum1
    }
    catch(err){
      errorHandler(err);
    }
  }

  async function txtCollectValidation(txtCollectValue, txtField){
    try{
      if(parseInt(txtCollectValue) < 0){
        showMessage(thisObj, 'Negative collection amount not allowed.')
        txtField.value = 0
        return;
      }
      Calc();
    }
    catch(err){
      errorHandler(err);
    }
  }

  const ontxtCollectARChange = async() =>{
    try{
      if(!isEnabled(thisObj, 'txtCollectAR'))
        return
      if(txtCollectAR.value > getData(thisObj, 'lblARBalance'))
        txtCollectAR.value = parseFloat(getData(thisObj, 'lblARBalance'))
      checkAgainstValueOfCheck(txtCollectAR.value)
      txtCollectValidation(txtCollectAR.value, txtCollectAR)
    }
    catch(err){
      errorHandler(err);
    }
  }
  thisObj.ontxtCollectARChange = ontxtCollectARChange;

  const ontxtCollectCRChange = async() =>{
    try{
      if(!isEnabled(thisObj, 'txtCollectCR'))
        return
      if(txtCollectCR.value > getData(thisObj, 'lblCRBalance'))
        txtCollectCR.value = parseFloat(getData(thisObj, 'lblCRBalance'))
      checkAgainstValueOfCheck(txtCollectCR.value)
      txtCollectValidation(txtCollectCR.value, txtCollectCR)
    }
    catch(err){
      errorHandler(err);
    }
  }
  thisObj.ontxtCollectCRChange = ontxtCollectCRChange;

  const ontxtCollectSRChange = async() =>{
    try{
      if(!isEnabled(thisObj, 'txtCollectSR'))
        return
      if(txtCollectSR.value > getData(thisObj, 'lblSBalance'))
        txtCollectSR.value = parseFloat(getData(thisObj, 'lblSRBalance'))
      checkAgainstValueOfCheck(txtCollectSR.value)
      txtCollectValidation(txtCollectSR.value, txtCollectSR)
    }
    catch(err){
      errorHandler(err);
    }
  }
  thisObj.ontxtCollectSRChange = ontxtCollectSRChange;

  const bFillBuyingPointList = async () => {
    let js = []
    let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1100', null, null, null, null)
    if (response !== undefined && response !== null) {
      for (var i = 0; i < response.length; i++) {
        js.push({
          key         : response[i].buy_pt_id,
          description : response[i].buy_pt_id + ' - ' + response[i].buy_pt_name
        })
      }
    }

    thisObj.setState(current => {
      return {
        ...current,
        ddLocation: {
          ...state["ddLocation"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddLocation', js.at(0).key);
  }

  const bFillRemitToList = async (txtVendorNumber) => {
    try {
      let response = await ContractManagementService.RetrieveVendorByNumber(txtVendorNumber)
      if(response !== null && response !== undefined){
        if(response.length > 0){
          show(thisObj, 'txtVndor')
          setValue(thisObj, "txtVndor", response[0].name)
          setValue(thisObj, 'txtVendor', response[0].vendorid)
          let colorDiv = document.getElementById('txtVndor')
          colorDiv.style.color = "blue";
        }
        else{
          hide(thisObj, 'txtVndor')
          setValue(thisObj, "txtVndor", '')
        }
      }
    }
    catch(err) {
      errorHandler(err);
    }
  }
    
  const RemitToList = async(txtVendorNumber) => {
    try {
      let js = [];
      js.push({ key: '', description: '>>> ALL <<<' })
      let response = await ContractManagementService.RetieveRemitDetails(txtVendorNumber);
      
      if (response != undefined && response != null) {
        for (var i = 0; i < response.length; i++) {
          js.push({
            key         : response[i].remittoid,
            description : response[i].remittoid + ' - ' + ConvertToPascalCase(response[i].name) + ' - ' + ConvertToPascalCase(response[i].city) + ', ' + ConvertToPascalCase(response[i].state)
          })
        }
      }
      
      setData(thisObj, 'remitTo', js)
      thisObj.setState(current => {
        return {
          ...current,
          ddremitTo: {
            ...state["ddremitTo"],
            valueList: js
          }
        }
      })
    }
    catch(err) {
      errorHandler(err);
    }
  }

  const bFillRemitToList2 = async() => {
    try{
      let response = await ContractManagementService.RetieveRemitDetails(txtVendor.value.toLocaleUpperCase());
      let js = [];
      let keyVal = 0;
      for(var i=0 ; i<response.length; i++){
        js.push({
          key         : response[i].remittoid,
          description : response[i].remittoid + ' - ' + ConvertToPascalCase(response[i].name) + ' - ' + ConvertToPascalCase(response[i].city) + ', ' + ConvertToPascalCase(response[i].state)
        })    
      }
      for(var i=0; i< js.length; i++){
        if(js[i].description.split('-')[0] == '000'){
          keyVal = i
          break;
        }
      }
      
      let releaseList = getValue(thisObj, "gridReadyToPayReleased")
      if(releaseList.length)
        SetRemitTo();

      thisObj.setState(current => {
        return {
          ...current,
          ddRemitTo: {
            ...state["ddRemitTo"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddRemitTo', js.at(keyVal).key);
      setData(thisObj, 'ddRemitTo', js.at(0).key);
      setData(thisObj, 'RemitTo', js)
    }
    catch(err){
      errorHandler(err);
    }
  }

  const bFillLienHolders = async() => {
    try{
      let lienHolder = ''
      setValue(thisObj, 'lblLienholders', '')
      if(txtVendor.value == null || txtVendor.value == undefined || txtVendor.value == '')
        return;

      let response = await AccountPayment.RetrieveLienHolderControlDetails(compIdFromLS(), cropYearFromLS(), txtVendor.value, null)
      for(var i=0 ; i<response.length; i++)
        lienHolder += response[i].lienholder_name + ", "        
      
      if(lienHolder.length > 0)
        if(lienHolder.trim().slice(-1) == ",")
          lienHolder = lienHolder.trim().slice(0, lienHolder.trim().length -1)
      setValue(thisObj, 'lblLienholdersValue', lienHolder)
    }
    catch(err){
      errorHandler(err);
    }
  }

  const SetRemitTo = async() => {
    try{
      let n = 0
      let strRemit = ''
      let bRemitDiffer = false;

      let releaseList = getData(thisObj, 'gridReadyToPayHold')
      if(releaseList.length > 0){
        n =1;
        strRemit = releaseList[0].txtcolRemitH
        for(var i=0; releaseList.length; i++){
          if(strRemit != releaseList[i].txtcolRemitR){
            bRemitDiffer = true
            strRemit = ''
            break;
          }
        }
        let remitList2 = getData(thisObj, 'remitTo')
        
        if(remitList2.length > 0){
          if(bRemitDiffer){
            setData(thisObj, 'ddRemitTo', remitList2.at(0).key);
          }
          else{
            setData(thisObj, 'ddRemitTo', remitList2.at(0).key);
            for(var i=0; i<remitList2.length; i++){
              if(remitList2[i].description == strRemit){
                setData(thisObj, 'ddRemitTo', remitList2.at(i).key);
                break;
              }
            }
          }
        }
      }
      
    }
    catch(err){
      errorHandler(err);
    }
  }

  const AddArray = async() => {
    try{
      const Min_MAX_CHECK_ROWS = 5000
      let transferArray1 = []
      let bAttemptedReleaseWireXfer = false
      let rowIdList = []
      
      if (thisObj.state.gridReadyToPayHold.selected == null || thisObj.state.gridReadyToPayHold.selected == undefined)
        return;
      if (thisObj.state.gridReadyToPayHold.selected.length == 0)
        return;
      
      let res = thisObj.state.gridReadyToPayHold.selected
      let lstRelease = getValue(thisObj, "gridReadyToPayReleased")

      if (lstRelease == undefined && lstRelease == null) {
        setValue(thisObj, "gridReadyToPayReleased", [])
      }
      else{
        for (var i = 0; i < lstRelease.length; i++)
          transferArray1.push(lstRelease[i])
      }
      
      for(var i=0; i<res.length; i++){
        if(res[i].color !== "&HE0E0E0" && res[i].color !== "&HC0FFC0" && !(res[i].color == "&HFFC0C0" && getData(thisObj, 'permissions').addData !== 'Y') ){
          if(res[i].txtcolWireXferDataH == ''){
            if(lstRelease.length > Min_MAX_CHECK_ROWS){
              showMessage(thisObj, 'You have reached the maximum limit of ' + Min_MAX_CHECK_ROWS + ' transaction that can be released on a single check.\n Any transaction in excess of this number will not be released!')
              return;
            }
            transferArray1.push({
              rowId               : res[i].rowId,
              rowDataR            : res[i].rowDataH,
              txtcolType1R        : res[i].txtcolType1H,
              txtcolNumber1R      : res[i].txtcolNumber1H,
              txtcolType2R        : res[i].txtcolType2H,
              txtcolNumber2R      : res[i].txtcolNumber2H,
              txtcolProceedsR     : res[i].txtcolProceedsH,
              txtcolOptR          : res[i].txtcolOptH,
              txtcolDateR         : res[i].txtcolDateH,
              txtcolSetlDateR     : res[i].txtcolSetlDateH,
              txtcolHidden1R      : res[i].txtcolHidden1H,
              txtcolHidden2R      : res[i].txtcolHidden2H,
              txtcolHidden3R      : res[i].txtcolHidden3H,
              txtcolRemitR        : res[i].txtcolRemitH,
              txtcolWireXferDataR : res[i].txtcolWireXferDataH,
              color               : res[i].color == "&HFFC0C0" ? "&HFFC0C0" : ""
            })
            rowIdList.push(res[i].rowId)
          }
          else{
            bAttemptedReleaseWireXfer = true;
          }
        }
      }

      if(bAttemptedReleaseWireXfer)
        showMessage(thisObj, 'Wire Transfer Payables Cannot be Released.')
      
      // transferArray1 = [...transferArray1].sort((a, b) => a.txtcolHidden1R - b.txtcolHidden1R)

      setValue(thisObj, "gridReadyToPayReleased", transferArray1);
      setValue(thisObj, 'lblRTPsReleasedValue', transferArray1.length)
      
      setData(thisObj, "gridReadyToPayReleased", transferArray1);

      let holdArr = getValue(thisObj, "gridReadyToPayHold")
      let js = []
      
      for (var i = 0; i < holdArr.length; i++) {
        if(!rowIdList.includes(holdArr[i].rowId))
          js.push(holdArr[i])
      }
      setValue(thisObj, "gridReadyToPayHold", js);
      setValue(thisObj, 'lblRTPsHoldValue', js.length)

      setData(thisObj, "gridReadyToPayHold", js);
      await Calc();
      await SetRemitTo();

    }
    catch(err){
      errorHandler(err);
    }
  }

  const RemoveArray = async() => {
    try{
      const Min_MAX_CHECK_ROWS = 5000
      let transferArray2 = []
      let rowIdList = []
      
      if (thisObj.state.gridReadyToPayReleased.selected == null || thisObj.state.gridReadyToPayReleased.selected == undefined)
        return;
      if (thisObj.state.gridReadyToPayReleased.selected.length == 0)
        return;
      
      let res = thisObj.state.gridReadyToPayReleased.selected
      let lstHold = getValue(thisObj, "gridReadyToPayHold")

      if (lstHold == undefined && lstHold == null) {
        setValue(thisObj, "gridReadyToPayHold", [])
      }
      else{
        for (var i = 0; i < lstHold.length; i++)
          transferArray2.push(lstHold[i])
      }
      
      for(var i=0; i<res.length; i++){
        if(res[i].color !== "&HE0E0E0" && res[i].color !== "&HC0FFC0"){
          transferArray2.push({
            rowID$              : res[i].rowId,
            rowId               : res[i].rowId,
            rowDataH            : res[i].rowDataR,
            txtcolType1H        : res[i].txtcolType1R,
            txtcolNumber1H      : res[i].txtcolNumber1R,
            txtcolType2H        : res[i].txtcolType2R,
            txtcolNumber2H      : res[i].txtcolNumber2R,
            txtcolProceedsH     : res[i].txtcolProceedsR,
            txtcolOptH          : res[i].txtcolOptR,
            txtcolDateH         : res[i].txtcolDateR,
            txtcolSetlDateH     : res[i].txtcolSetlDateR,
            txtcolHidden1H      : res[i].txtcolHidden1R,
            txtcolHidden2H      : res[i].txtcolHidden2R,
            txtcolHidden3H      : res[i].txtcolHidden3R,
            txtcolRemitH        : res[i].txtcolRemitR,
            txtcolWireXferDataH : res[i].txtcolWireXferDataR,
            color               : res[i].color == "&HFFC0C0" ? "&HFFC0C0" : ""
          })
          rowIdList.push(res[i].rowId)
        }        
      }

      // transferArray2 = [...transferArray2].sort((a, b) => a.txtcolHidden1H - b.txtcolHidden1H)

      setValue(thisObj, "gridReadyToPayHold", transferArray2);
      setValue(thisObj, 'lblRTPsHoldValue', transferArray2.length)

      setData(thisObj, "gridReadyToPayHold", transferArray2);
      
      let releaseArr = getValue(thisObj, "gridReadyToPayReleased")
      let js = []
      for (var i = 0; i < releaseArr.length; i++) {
        if(!rowIdList.includes(releaseArr[i].rowId))
          js.push(releaseArr[i])
      }
      setValue(thisObj, "gridReadyToPayReleased", js);
      setValue(thisObj, 'lblRTPsReleasedValue', js.length)

      setData(thisObj, "gridReadyToPayReleased", js);
      await Calc();
      await SetRemitTo();
    }
    catch(err){
      errorHandler(err);
    }
  }

  const onddLocationChange = async() => {
    try{
      await EnableDisable();
    }
    catch(err){
      errorHandler(err);
    }
  }
  thisObj.onddLocationChange = onddLocationChange;

  const ontxtCarrierAbbrBlur = async () => {
    let js = [];
    //let txtCarrier_Abbrv = getValue(thisObj, 'txtCarrierAbbr')
    let txtCarrier_Abbrv = txtCarrierAbbr.value 
    if (txtCarrier_Abbrv !== '') {
      let GetScac = await AccountPayment.RetrieveScacVendorLookupDetails('', txtCarrier_Abbrv);
      if (GetScac.length > 0) {
        show(thisObj, 'txtVndor')
        setValue(thisObj, 'txtCarrierAbbr', GetScac[0].scac)
        setValue(thisObj, 'txtVndor', GetScac[0].name) 
        setValue(thisObj, 'txtVendor', GetScac[0].number)
        await bFillRemitToList(GetScac[0].number)
        await RemitToList(GetScac[0].number)
        enable(thisObj, 'btnSearch')
      } else {
        hide(thisObj, 'txtVndor')
        disable(thisObj, 'btnSearch')
        setValue(thisObj, 'txtCarrierAbbr', '')
        setValue(thisObj, 'txtVendor', '')
        setValue(thisObj, 'txtVndor', '')
        thisObj.setState(current => {
          return {
            ...current,
            ddremitTo: {
              ...state["ddremitTo"],
              valueList: [ { key: '', description: '>>> ALL <<<' } ]
            }
          }
        });
        showMessage(thisObj, "The carrier does not exist or does not have an active status!");
      }
    }
  }
  thisObj.ontxtCarrierAbbrBlur = ontxtCarrierAbbrBlur;

  const ontxtVendorChange = async() => {
    try {      
      txtVendor.value = txtVendor.value == '' ? '' : txtVendor.value.toLocaleUpperCase()
      let txtVendorNumber = txtVendor.value
      if(txtVendorNumber.length > 6)
        txtVendor.value = txtVendorNumber.slice(0,6)
      if (txtVendorNumber.length == 6) {
        let response = await ContractManagementService.RetrieveVendorByNumber(txtVendorNumber)
        if(response.length != 0){
          setValue(thisObj, 'txtVndor', response[0].name)
          txtVendor.value = response[0].vendorid
          await bFillRemitToList(txtVendorNumber)
          await RemitToList(txtVendorNumber)
          enable(thisObj, 'btnSearch')
          // get Carrier abbr
          let GetScac = await AccountPayment.RetrieveScacVendorLookupDetails(txtVendorNumber, '');
          if (GetScac.length > 0) {
            setValue(thisObj, 'txtCarrierAbbr', GetScac[0].scac) 
            //setValue(thisObj, 'txtVendorName', GetScac[0].name)
          } else {
            setValue(thisObj, 'txtCarrierAbbr', '')
            //setValue(thisObj, 'txtVendorName', '')
          }
        }
      }
      else {
        hide(thisObj, 'txtVndor')
        disable(thisObj, 'btnSearch')
        setValue(thisObj, 'txtCarrierAbbr', '')
        thisObj.setState(current => {
          return {
            ...current,
            ddremitTo: {
              ...state["ddremitTo"],
              valueList: [ { key: '', description: '>>> ALL <<<' } ]
            }
          }
        })
      } 
      txtVendor.value = txtVendor.value.toLocaleUpperCase()   
    } 
    catch(err) {
      errorHandler(err);
      return false;
    }
    return true;
  };
  thisObj.ontxtVendorChange = ontxtVendorChange;
  
  const onbtnSearchClick = async() => {
    try {
      setLoading(true)
      setValue(thisObj, 'txtFarm', '')
      setValue(thisObj, 'txtCheck', '')
      setValue(thisObj, 'lblValOfRTPValue', '')
      setValue(thisObj, 'lblLienholders', '')
      
      setValue(thisObj, 'txtCollectAR', '')
      setValue(thisObj, 'txtCollectCR', '')
      setValue(thisObj, 'txtCollectSR', '')

      disable(thisObj, 'txtCollectAR')
      disable(thisObj, 'txtCollectCR')
      disable(thisObj, 'txtCollectSR')

      setValue(thisObj, 'lblSRBalance', '')

      thisObj.setState(current => {
        return {
          ...current,
          ddRemitTo: {
            ...state["ddRemitTo"],
            valueList: []
          }
        }
      })

      let buy_pt_id = thisObj.values['ddLocation']
      let payee_vendor = thisObj.values['txtVendor']      
      let audit_farm_id = thisObj.values.txtFarm
      let audit_farm_suffix = thisObj.values.txtFarmSuffix
      let audit_insp_num = null
      let audit_cont_num = null
      let audit_settle_num = null
      let audit_whse_rcpt = null
      let rtp_proceeds = null
      let rtp_status = null
      let batch_number = null
      let rtp_payee_remit = thisObj.values['ddremitTo']
      let special_type_none = null
      let rtp_status_hr = null
      let strMemoNum = ''
      let LstrReceivableNum = ''
      let bRowInserted = false
      let holdRowCount = 1
      let relRowCount = 1
      let holdTransferArray = [];
      let relTransferArray = [];      

      rtp_status = 'H'
      let response = await AccountPayment.RetrieveReadyToPaymentDetails(buy_pt_id, payee_vendor, audit_farm_id, audit_farm_suffix, audit_insp_num, audit_cont_num, audit_settle_num, audit_whse_rcpt, rtp_proceeds, rtp_status, batch_number, rtp_payee_remit, special_type_none, rtp_status_hr)
      if(response == null || response == undefined)
         return;
      
      permissions.addData = getData(thisObj, 'permissions').addData
      for(var i=0; i<response.length; i++){
        var obj = {}
        bRowInserted = false;
        if(response[i].audit_insp_num !== "" && response[i].audit_whse_rcpt == ""){
          obj.rowId = holdRowCount++;
          obj.rowDataH = response[i].transaction_num;
          bRowInserted = true;
          obj.txtcolType1H = response[i].pay_type_1;
          obj.txtcolNumber1H = response[i].pay_number_1;
          obj.txtcolType2H = response[i].pay_type_2;
          obj.txtcolNumber2H = response[i].pay_number_2;
          obj.txtcolProceedsH = parseFloat(response[i].rtp_proceeds).toFixed(2);
          obj.txtcolOptH = response[i].pay_option_ind;
          obj.txtcolDateH = GetFormattedDate(new Date(response[i].insp_date_time));
          obj.txtcolSetlDateH = GetFormattedDate(new Date(response[i].settlement_date));
          obj.txtcolHidden1H = 'I' + bSettlePad(response[i].audit_settle_num);
          obj.txtcolHidden2H = 'H';
          obj.txtcolHidden3H = response[i].pay_sort;
          obj.txtcolRemitH = response[i].payee_remit;
          obj.txtcolWireXferDataH = response[i].wire_xfer_data;
          obj.color = ""
          obj.txtcolColorH = ""
          holdTransferArray.push(obj)
        }
        else if(response[i].audit_trade_insp !== ""){
          obj.rowId = holdRowCount++;
          obj.rowDataH = response[i].transaction_num;
          bRowInserted = true;
          obj.txtcolType1H = response[i].pay_type_1;
          obj.txtcolNumber1H = response[i].pay_number_1;
          obj.txtcolType2H = response[i].pay_type_2;
          obj.txtcolNumber2H = response[i].pay_number_2;
          obj.txtcolProceedsH = parseFloat(response[i].rtp_proceeds).toFixed(2);
          obj.txtcolOptH = response[i].pay_option_ind;
          obj.txtcolDateH = GetFormattedDate(new Date(response[i].chg_date_time));
          obj.txtcolSetlDateH = "";
          obj.txtcolHidden1H = 'T' + response[i].audit_trade_settle;
          obj.txtcolHidden2H = 'H';
          obj.txtcolHidden3H = response[i].pay_sort;
          obj.txtcolRemitH = response[i].payee_remit;
          obj.txtcolWireXferDataH = response[i].wire_xfer_data;
          obj.color = ""
          obj.txtcolColorH = ""
          holdTransferArray.push(obj)
        }
        else if(response[i].pay_type_1.toLocaleUpperCase() == "MKT REBATE" || response[i].pay_type_1.toLocaleUpperCase() == "MKT GAIN" || response[i].pay_type_1.toLocaleUpperCase() == "FLR PRICING"){
          obj.rowId = holdRowCount++;
          obj.rowDataH = response[i].transaction_num;
          bRowInserted = true;
          obj.txtcolType1H = response[i].pay_type_1;
          obj.txtcolNumber1H = response[i].pay_number_1;
          obj.txtcolType2H = response[i].pay_type_2;
          obj.txtcolNumber2H = response[i].pay_number_2;
          obj.txtcolProceedsH = parseFloat(response[i].rtp_proceeds).toFixed(2);
          obj.txtcolOptH = response[i].pay_option_ind;
          obj.txtcolDateH = GetFormattedDate(new Date(response[i].chg_date_time));
          obj.txtcolSetlDateH = "";
          obj.txtcolHidden1H = 'FM' + response[i].audit_flex_mkt;
          obj.txtcolHidden2H = 'H';
          obj.txtcolHidden3H = response[i].pay_sort;
          obj.txtcolRemitH = response[i].payee_remit;
          obj.txtcolWireXferDataH = response[i].wire_xfer_data;
          obj.color = ""
          obj.txtcolColorH = ""
          holdTransferArray.push(obj)
        }
        else if(response[i].pay_type_2.toLocaleUpperCase() == "LOAN.RD"){
          obj.rowId = holdRowCount++;
          obj.rowDataH = response[i].transaction_num;
          bRowInserted = true;
          obj.txtcolType1H = response[i].pay_type_1;
          obj.txtcolNumber1H = response[i].pay_number_1;
          obj.txtcolType2H = response[i].pay_type_2;
          obj.txtcolNumber2H = response[i].pay_number_2;
          obj.txtcolProceedsH = parseFloat(response[i].rtp_proceeds).toFixed(2);
          obj.txtcolOptH = response[i].pay_option_ind;
          obj.txtcolDateH = GetFormattedDate(new Date(response[i].chg_date_time));
          obj.txtcolSetlDateH = "";
          obj.txtcolHidden1H = 'WR' + bAuditPad(response[i].pay_number_1);
          obj.txtcolHidden2H = 'H';
          obj.txtcolHidden3H = response[i].pay_sort;
          obj.txtcolRemitH = response[i].payee_remit;
          obj.txtcolWireXferDataH = response[i].wire_xfer_data;
          obj.color = ""
          obj.txtcolColorH = ""
          holdTransferArray.push(obj)
        }
        else{
            if((response[i].audit_whse_rcpt !== "" && response[i].audit_whse_rcpt !== 0) && permissions.addData == 'Y'){
              obj.rowId = holdRowCount++;
              obj.rowDataH = response[i].transaction_num;
              bRowInserted = true;
              obj.txtcolType1H = response[i].pay_type_1;
              obj.txtcolNumber1H = response[i].pay_number_1;
              obj.txtcolType2H = response[i].pay_type_2;
              obj.txtcolNumber2H = response[i].pay_number_2;
              obj.txtcolProceedsH = parseFloat(response[i].rtp_proceeds).toFixed(2);
              obj.txtcolOptH = response[i].pay_option_ind;
              obj.txtcolDateH = GetFormattedDate(new Date(response[i].chg_date_time));
              obj.txtcolSetlDateH = "";
              obj.txtcolHidden1H = 'WR' + bAuditPad(response[i].pay_number_1);
              obj.txtcolHidden2H = 'H';
              obj.txtcolHidden3H = response[i].pay_sort;
              obj.txtcolRemitH = response[i].payee_remit;
              obj.txtcolWireXferDataH = response[i].wire_xfer_data;
              obj.color = "&HFFC0C0"
              obj.txtcolColorH = "&HFFC0C0"
              holdTransferArray.push(obj)
            }
            if(response[i].audit_cr_check !== "" && response[i].audit_cr_vendor !== ""){
              obj.rowId = holdRowCount++;
              obj.rowDataH = response[i].transaction_num;
              bRowInserted = true;
              obj.txtcolType1H = response[i].pay_type_1;
              obj.txtcolNumber1H = response[i].pay_number_1;
              obj.txtcolType2H = response[i].pay_type_2;
              obj.txtcolNumber2H = response[i].pay_number_2;
              obj.txtcolProceedsH = parseFloat(response[i].rtp_proceeds).toFixed(2);
              obj.txtcolOptH = response[i].pay_option_ind;
              obj.txtcolDateH = GetFormattedDate(new Date(response[i].chg_date_time));
              obj.txtcolSetlDateH = "";
              obj.txtcolHidden1H = 'CR' + bAuditPad(response[i].pay_number_1);
              obj.txtcolHidden2H = 'H';
              obj.txtcolHidden3H = response[i].pay_sort;
              obj.txtcolRemitH = response[i].payee_remit;
              obj.txtcolWireXferDataH = response[i].wire_xfer_data;
              obj.color = ""
              obj.txtcolColorH = ""
              holdTransferArray.push(obj)
            }
            if(response[i].audit_cont_num !== "" && response[i].audit_cont_num !== 0 && response[i].rtp_proceeds !== 0){
              obj.rowId = holdRowCount++;
              obj.rowDataH = response[i].transaction_num;
              bRowInserted = true;
              obj.txtcolType1H = response[i].pay_type_1;
              obj.txtcolNumber1H = response[i].pay_number_1;
              obj.txtcolType2H = response[i].pay_type_2;
              obj.txtcolNumber2H = response[i].pay_number_2;
              obj.txtcolProceedsH = parseFloat(response[i].rtp_proceeds).toFixed(2);
              obj.txtcolOptH = response[i].pay_option_ind;
              obj.txtcolDateH = GetFormattedDate(new Date(response[i].chg_date_time));
              obj.txtcolSetlDateH = "";
              obj.txtcolHidden1H = 'Cont' + bAuditPad(response[i].pay_number_1);
              obj.txtcolHidden2H = 'H';
              obj.txtcolHidden3H = response[i].pay_sort;
              obj.txtcolRemitH = response[i].payee_remit;
              obj.txtcolWireXferDataH = response[i].wire_xfer_data;
              obj.color = ""
              obj.txtcolColorH = ""
              holdTransferArray.push(obj)
            }
            //holdTransferArray.push(obj)
        }

        if(response[i].audit_delv_agree_num !== ""){
          obj.rowId = holdRowCount++;
          obj.rowDataH = response[i].transaction_num;
          bRowInserted = true;
          obj.txtcolType1H = response[i].pay_type_1;
          obj.txtcolNumber1H = response[i].pay_number_1;
          obj.txtcolType2H = response[i].pay_type_2;
          obj.txtcolNumber2H = response[i].pay_number_2;
          obj.txtcolProceedsH = parseFloat(response[i].rtp_proceeds).toFixed(2);
          obj.txtcolOptH = response[i].pay_option_ind;
          obj.txtcolDateH = GetFormattedDate(new Date(response[i].chg_date_time));
          obj.txtcolSetlDateH = "";
          obj.txtcolHidden1H = 'Delv.' + bAuditPad(response[i].pay_number_2);
          obj.txtcolHidden2H = 'H';
          obj.txtcolHidden3H = response[i].pay_sort;
          obj.txtcolRemitH = response[i].payee_remit;
          obj.txtcolWireXferDataH = response[i].wire_xfer_data;
          obj.color = ""
          obj.txtcolColorH = ""
          holdTransferArray.push(obj)
        }
      
        if(Number(response[i].rtp_proceeds) < 0 && getData(thisObj, 'permissions').removeData !== 'Y' && bRowInserted){
          let res = holdTransferArray.pop()
          res.color = "&HE0E0E0"
          res.txtcolColorH = "&HE0E0E0"
          holdTransferArray.push(res)
        }
        
        if(response[i].intr_agree_num?.trim() !== "" && bRowInserted){
          let res = holdTransferArray.pop()
          res.color = "&HC0FFC0"
          res.txtcolColorH = "&HC0FFC0"
          holdTransferArray.push(res)
        }

        if(response[i].memo_num !== "" && response[i].rtp_proceeds !== 0 && response[i].memo_num !== strMemoNum){
          let sumArr = []
          for(var j=i; j<response.length; j++)
            sumArr.push(response[j])                      
          obj.rowId = holdRowCount++;
          obj.rowDataH = GetTransSumProceeds(sumArr, response[i].memo_num, 0)          

          obj.txtcolType1H = response[i].pay_type_1;
          obj.txtcolNumber1H = response[i].pay_number_1;
          obj.txtcolType2H = response[i].pay_type_2;
          obj.txtcolNumber2H = response[i].pay_number_2;
          obj.txtcolProceedsH = parseFloat(getData(thisObj, 'dblRtpProceeds')).toFixed(2);
          obj.txtcolOptH = response[i].pay_option_ind;
          obj.txtcolDateH = GetFormattedDate(new Date(response[i].chg_date_time));
          obj.txtcolSetlDateH = "";
          strMemoNum = response[i].memo_num;
          obj.txtcolHidden1H = "";
          obj.txtcolHidden2H = 'H';
          obj.txtcolHidden3H = response[i].pay_sort;
          obj.txtcolRemitH = response[i].payee_remit;
          obj.txtcolWireXferDataH = response[i].wire_xfer_data;
          obj.color = ""
          obj.txtcolColorH = ""
          holdTransferArray.push(obj)
        }
      }
      
      rtp_status = 'R'
      response = await AccountPayment.RetrieveReadyToPaymentDetails(buy_pt_id, payee_vendor, audit_farm_id, audit_farm_suffix, audit_insp_num, audit_cont_num, audit_settle_num, audit_whse_rcpt, rtp_proceeds, rtp_status, batch_number, rtp_payee_remit, special_type_none, rtp_status_hr)
      if(response == null || response == undefined)
         return;

      strMemoNum = ""
      setData(thisObj, 'dblRtpProceeds', 0)

      for(var i=0; i<response.length; i++){
        var obj = {}
        bRowInserted = false
        if(response[i].audit_insp_num !== "" && response[i].audit_whse_rcpt == ""){
          obj.rowId = relRowCount++;          
          obj.rowDataR = response[i].transaction_num;
          bRowInserted = true;
          obj.txtcolType1R = response[i].pay_type_1;
          obj.txtcolNumber1R = response[i].pay_number_1;
          obj.txtcolType2R = response[i].pay_type_2;
          obj.txtcolNumber2R = response[i].pay_number_2;
          obj.txtcolProceedsR = parseFloat(response[i].rtp_proceeds).toFixed(2);
          obj.txtcolOptR = response[i].pay_option_ind;
          obj.txtcolDateR = GetFormattedDate(new Date(response[i].insp_date_time));
          obj.txtcolSetlDateR = GetFormattedDate(new Date(response[i].settlement_date));
          obj.txtcolHidden1R = "I" + bSettlePad(response[i].audit_settle_num)
          obj.txtcolHidden2R = "R";
          obj.txtcolHidden3R = response[i].pay_sort;
          obj.txtcolRemitR = response[i].payee_remit;
          obj.txtcolWireXferDataR = response[i].wire_xfer_data
          obj.color = ""
          obj.txtcolColorR = ""
          relTransferArray.push(obj)
        }
        else if(response[i].audit_trade_insp !== ""){
          obj.rowId = relRowCount++;          
          obj.rowDataR = response[i].transaction_num;
          bRowInserted = true;
          obj.txtcolType1R = response[i].pay_type_1;
          obj.txtcolNumber1R = response[i].pay_number_1;
          obj.txtcolType2R = response[i].pay_type_2;
          obj.txtcolNumber2R = response[i].pay_number_2;
          obj.txtcolProceedsR = parseFloat(response[i].rtp_proceeds).toFixed(2);
          obj.txtcolOptR = response[i].pay_option_ind;
          obj.txtcolDateR = GetFormattedDate(new Date(response[i].chg_date_time));
          obj.txtcolSetlDateR = "";
          obj.txtcolHidden1R = "T" + bSettlePad(response[i].audit_settle_num)
          obj.txtcolHidden2R = "R";
          obj.txtcolHidden3R = response[i].pay_sort;
          obj.txtcolRemitR = response[i].payee_remit;
          obj.txtcolWireXferDataR = response[i].wire_xfer_data
          obj.color = ""
          obj.txtcolColorR = ""
          relTransferArray.push(obj)
        }
        else if(response[i].pay_type_1.toLocaleUpperCase() == "MKT REBATE" || response[i].pay_type_1.toLocaleUpperCase() == "MKT GAIN" || response[i].pay_type_1.toLocaleUpperCase() == "FLR PRICING"){
          obj.rowId = relRowCount++;          
          obj.rowDataR = response[i].transaction_num;
          bRowInserted = true;
          obj.txtcolType1R = response[i].pay_type_1;
          obj.txtcolNumber1R = response[i].pay_number_1;
          obj.txtcolType2R = response[i].pay_type_2;
          obj.txtcolNumber2R = response[i].pay_number_2;
          obj.txtcolProceedsR = parseFloat(response[i].rtp_proceeds).toFixed(2);
          obj.txtcolOptR = response[i].pay_option_ind;
          obj.txtcolDateR = GetFormattedDate(new Date(response[i].chg_date_time));
          obj.txtcolSetlDateR = "";
          obj.txtcolHidden1R = "FM" + response[i].audit_flex_mkt;
          obj.txtcolHidden2R = "R";
          obj.txtcolHidden3R = response[i].pay_sort;
          obj.txtcolRemitR = response[i].payee_remit;
          obj.txtcolWireXferDataR = response[i].wire_xfer_data
          obj.color = ""
          obj.txtcolColorR = ""
          relTransferArray.push(obj)
        }
        else if(response[i].pay_type_2.toLocaleUpperCase() == "LOAN.RD"){
          obj.rowId = relRowCount++;          
          obj.rowDataR = response[i].transaction_num;
          bRowInserted = true;
          obj.txtcolType1R = response[i].pay_type_1;
          obj.txtcolNumber1R = response[i].pay_number_1;
          obj.txtcolType2R = response[i].pay_type_2;
          obj.txtcolNumber2R = response[i].pay_number_2;
          obj.txtcolProceedsR = parseFloat(response[i].rtp_proceeds).toFixed(2);
          obj.txtcolOptR = response[i].pay_option_ind;
          obj.txtcolDateR = GetFormattedDate(new Date(response[i].chg_date_time));
          obj.txtcolSetlDateR = "";
          obj.txtcolHidden1R = "WM" + bAuditPad(response[i].pay_type_1);
          obj.txtcolHidden2R = "R";
          obj.txtcolHidden3R = response[i].pay_sort;
          obj.txtcolRemitR = response[i].payee_remit;
          obj.txtcolWireXferDataR = response[i].wire_xfer_data
          obj.color = ""
          obj.txtcolColorR = ""
          relTransferArray.push(obj)
        }
        else{
          if((response[i].audit_whse_rcpt !== "" && response[i].audit_whse_rcpt !== 0) && permissions.addData == 'Y'){
            obj.rowId = relRowCount++;          
            obj.rowDataR = response[i].transaction_num;
            bRowInserted = true;
            obj.txtcolType1R = response[i].pay_type_1;
            obj.txtcolNumber1R = response[i].pay_number_1;
            obj.txtcolType2R = response[i].pay_type_2;
            obj.txtcolNumber2R = response[i].pay_number_2;
            obj.txtcolProceedsR = parseFloat(response[i].rtp_proceeds).toFixed(2);
            obj.txtcolOptR = response[i].pay_option_ind;
            obj.txtcolDateR = GetFormattedDate(new Date(response[i].chg_date_time));
            obj.txtcolSetlDateR = "";
            obj.txtcolHidden1R = "WR" + bAuditPad(response[i].pay_type_1);
            obj.txtcolHidden2R = "R";
            obj.txtcolHidden3R = response[i].pay_sort;
            obj.txtcolRemitR = response[i].payee_remit;
            obj.txtcolWireXferDataR = response[i].wire_xfer_data
            obj.color = "&HFFC0C0"
            obj.txtcolColorR = "&HFFC0C0"
            relTransferArray.push(obj)
          }
          if(response[i].audit_cr_check !== "" && response[i].audit_cr_vendor !== ''){
            obj.rowId = relRowCount++;          
            obj.rowDataR = response[i].transaction_num;
            bRowInserted = true;
            obj.txtcolType1R = response[i].pay_type_1;
            obj.txtcolNumber1R = response[i].pay_number_1;
            obj.txtcolType2R = response[i].pay_type_2;
            obj.txtcolNumber2R = response[i].pay_number_2;
            obj.txtcolProceedsR = parseFloat(response[i].rtp_proceeds).toFixed(2);
            obj.txtcolOptR = response[i].pay_option_ind;
            obj.txtcolDateR = GetFormattedDate(new Date(response[i].chg_date_time));
            obj.txtcolSetlDateR = "";
            obj.txtcolHidden1R = "CR" + bAuditPad(response[i].pay_type_1);
            obj.txtcolHidden2R = "R";
            obj.txtcolHidden3R = response[i].pay_sort;
            obj.txtcolRemitR = response[i].payee_remit;
            obj.txtcolWireXferDataR = response[i].wire_xfer_data
            obj.color = ""
            obj.txtcolColorR = ""
            relTransferArray.push(obj)
          }
          if(response[i].audit_delv_agree_num !== ""){
            obj.rowId = relRowCount++;          
            obj.rowDataR = response[i].transaction_num;
            bRowInserted = true;
            obj.txtcolType1R = response[i].pay_type_1;
            obj.txtcolNumber1R = response[i].pay_number_1;
            obj.txtcolType2R = response[i].pay_type_2;
            obj.txtcolNumber2R = response[i].pay_number_2;
            obj.txtcolProceedsR = parseFloat(response[i].rtp_proceeds).toFixed(2);
            obj.txtcolOptR = response[i].pay_option_ind;
            obj.txtcolDateR = GetFormattedDate(new Date(response[i].chg_date_time));
            obj.txtcolSetlDateR = "";
            obj.txtcolHidden1R = "Delv." + bAuditPad(response[i].pay_type_1);
            obj.txtcolHidden2R = "H";
            obj.txtcolHidden3R = response[i].pay_sort;
            obj.txtcolRemitR = response[i].payee_remit;
            obj.txtcolWireXferDataR = response[i].wire_xfer_data
            obj.color = ""
            obj.txtcolColorR = ""
            relTransferArray.push(obj)
          }
          if(response[i].audit_cont_num !== "" && response[i].audit_cont_num !== 0 && response[i].rtp_proceeds !== 0){
            obj.rowId = relRowCount++;          
            obj.rowDataR = response[i].transaction_num;
            bRowInserted = true;
            obj.txtcolType1R = response[i].pay_type_1;
            obj.txtcolNumber1R = response[i].pay_number_1;
            obj.txtcolType2R = response[i].pay_type_2;
            obj.txtcolNumber2R = response[i].pay_number_2;
            obj.txtcolProceedsR = parseFloat(response[i].rtp_proceeds).toFixed(2);
            obj.txtcolOptR = response[i].pay_option_ind;
            obj.txtcolDateR = GetFormattedDate(new Date(response[i].chg_date_time));
            obj.txtcolSetlDateR = "";
            obj.txtcolHidden1R = "Cont" + bAuditPad(response[i].pay_type_1);
            obj.txtcolHidden2R = "R";
            obj.txtcolHidden3R = response[i].pay_sort;
            obj.txtcolRemitR = response[i].payee_remit;
            obj.txtcolWireXferDataR = response[i].wire_xfer_data
            obj.res = ""
            obj.txtcolColorR = ""
            relTransferArray.push(obj)
          }
        }

        if(Number(response[i].rtp_proceeds) < 0 && getData(thisObj, 'permissions').removeData !== 'Y' && bRowInserted){
          let res = relTransferArray.pop()
          res.color = "&HE0E0E0"
          obj.txtcolColorR = "&HE0E0E0"
          relTransferArray.push(res)
        }
        
        if(response[i].intr_agree_num?.trim() !== "" && bRowInserted){
          let res = relTransferArray.pop()
          res.color = "&HC0FFC0"
          obj.txtcolColorR = "&HC0FFC0"
          relTransferArray.push(res)
        }

        if(response[i].memo_num !== "" && response[i].rtp_proceeds !== 0 && response[i].memo_num !== strMemoNum){
          let sumArr = []
          for(var j=i; j<response.length; j++)
            sumArr.push(response[j])
          obj.rowId = relRowCount++;    
          obj.rowDataR = GetTransSumProceeds(sumArr, response[i].memo_num, 0)
          
          obj.txtcolType1R = response[i].pay_type_1;
          obj.txtcolNumber1R = response[i].pay_number_1;
          obj.txtcolType2R = response[i].pay_type_2;
          obj.txtcolNumber2R = response[i].pay_number_2;
          obj.txtcolProceedsR = parseFloat(getData(thisObj, 'dblRtpProceeds')).toFixed(2);
          obj.txtcolOptR = response[i].pay_option_ind;
          obj.txtcolDateR = GetFormattedDate(new Date(response[i].chg_date_time));
          obj.txtcolSetlDateR = "";
          //obj.txtcolHidden1R = "I" + bSettlePad(response[i].audit_settle_num)
          strMemoNum = response[i].memo_num;
          obj.txtcolHidden2R = "R";
          obj.txtcolHidden3R = response[i].pay_sort;
          obj.txtcolRemitR = response[i].payee_remit;
          obj.txtcolWireXferDataR = response[i].wire_xfer_data
          obj.txtcolColorR = ""
          relTransferArray.push(obj)
        }
      }
      
      hideColumn(thisObj, 'gridReadyToPayHold', 'txtcolColorH')
      hideColumn(thisObj, 'gridReadyToPayReleased', 'txtcolColorR')
      // holdTransferArray = [...holdTransferArray].sort((a, b) => a.txtcolHidden1H - b.txtcolHidden1H)
      // relTransferArray = [...relTransferArray].sort((a, b) => a.txtcolHidden1R - b.txtcolHidden1R)
      
      setValue(thisObj, "gridReadyToPayHold", holdTransferArray);
      setValue(thisObj, 'lblRTPsHoldValue', holdTransferArray.length)  

      setValue(thisObj, "gridReadyToPayReleased", relTransferArray);
      setValue(thisObj, 'lblRTPsReleasedValue' , relTransferArray.length)

      setData(thisObj, "gridReadyToPayHold", holdTransferArray);
      setData(thisObj, 'gridReadyToPayReleased', relTransferArray)  
      
      let farm = getValue(thisObj, 'txtFarm')
      if(farm !== undefined && farm !== null)
        if(farm !== '')
          setValue(thisObj, 'txtFarm', farm)

      await bFillRemitToList2();
      await bFillLienHolders();

      response = await ContractManagementService.RetrieveUserControlDetails(sessionStorage.getItem("userid"))
      setData(thisObj, 'lblCollectionPoint', response[0].buyPtId)
      if(response !== null && response !== undefined){
        if(response.length > 0){          
          let response2 = await ContractManagementService.RetrieveBuyingPointControlDetails(null, null, null, null, response[0].buyPtId)
          if(response2 !== null && response2 !== undefined){
            if(response2.length > 0){
              setValue(thisObj, 'txtEndCheckNum', response2[0].end_check)
              checkNumber = response2[0].next_check;
              setData(thisObj, 'LstrReceivableNum', parseInt(response2[0].new_receivable_no))
              setData(thisObj, 'txtEndCheckNum', response2[0].end_check)
              setData(thisObj, 'textCheckNumber', response2[0].next_check)
            }
          }
        }
      }
      
      response = await AccountPayment.RetrieveOpenAccountControlDetails(buy_pt_id, payee_vendor)
      if(response !== null && response !== undefined){
        if(response.length > 0){
          setValue(thisObj, 'lblARBalance', response[0].open_acct_control.length == 0 ? '0.00' : response[0].open_acct_control[0].ar_end_balance)
          setValue(thisObj, 'lblCRBalance', response[0].open_acct_control.length == 0 ? '0.00' : response[0].open_acct_control[0].cr_end_balance)
          setValue(thisObj, 'lblSRBalance', response[0].open_acct_control.length == 0 ? '0.00' : response[0].open_acct_control[0].sr_end_balance)
          
          setData(thisObj, 'lblARBalance', response[0].open_acct_control.length == 0 ? '0.00' : response[0].open_acct_control[0].ar_end_balance)
          setData(thisObj, 'lblCRBalance', response[0].open_acct_control.length == 0 ? '0.00' : response[0].open_acct_control[0].cr_end_balance)
          setData(thisObj, 'lblSRBalance', response[0].open_acct_control.length == 0 ? '0.00' : response[0].open_acct_control[0].sr_end_balance)

          setValue(thisObj, 'txtCollectAR', '0.00')
          setValue(thisObj, 'txtCollectCR', '0.00')
          setValue(thisObj, 'txtCollectSR', '0.00')
        }
        else{
          setValue(thisObj, 'lblARBalance', '0.00')
          setValue(thisObj, 'lblCRBalance', '0.00')
          setValue(thisObj, 'lblSRBalance', '0.00')
          
          setData(thisObj, 'lblARBalance', '0.00')
          setData(thisObj, 'lblCRBalance', '0.00')
          setData(thisObj, 'lblSRBalance', '0.00')

          setValue(thisObj, 'txtCollectAR', '0.00')
          setValue(thisObj, 'txtCollectCR', '0.00')
          setValue(thisObj, 'txtCollectSR', '0.00')
        }
      }

      await setRowColor();
      await Calc();
      await EnableDisable();      
    }
    catch(err) {
      errorHandler(err);
    }
    finally{
      setLoading(false);
    }
  };
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const onbtnArr1Click = async() => {
    try {
      await AddArray();
    }
    catch(err) {
      errorHandler(err);
    }
  };
  thisObj.onbtnArr1Click = onbtnArr1Click;

  const onbtnArr2Click = async() => {
    try {
      await RemoveArray();
    }
    catch(err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnArr2Click = onbtnArr2Click;

  const onbtnSaveClick = async() => {
    try{
      let rCount = 0
      let js = []

      let holdLst =  getValue(thisObj, "gridReadyToPayHold")
      
      for(var i=0; i<holdLst.length; i++){
        if(holdLst[i].txtcolHidden2H == 'R'){
          if(holdLst[i].rowDataH !== ''){
            let astrTransNum = holdLst[i].rowDataH.toString().split(',')
            for(var j=0; j<astrTransNum.length; j++){
              rCount++;
              js.push({
                "tn" : astrTransNum[j],
                "ty" : 'H'
              })
            }
          }
          else{
            rCount++;
            js.push({
              "tn" : holdLst[j].rowDataH,
              "ty" : 'H'
            })
          }
        }
      }
      
      let releaseLst =  getValue(thisObj, "gridReadyToPayReleased")
      for(var i=0; i<releaseLst.length; i++){
        if(releaseLst[i].txtcolHidden2R == 'H'){
          if(releaseLst[i].rowDataR !== ''){
            let astrTransNum = releaseLst[i].rowDataR.toString().split(',')
            for(var j=0; j<astrTransNum.length; j++){
              rCount++;
              js.push({
                "tn" : astrTransNum[j],
                "ty" : 'R'
              })
            }
          }
          else{
            rCount++;
            js.push({
              "tn" : releaseLst[j].rowDataR,
              "ty" : 'R'
            })
          }
        }
      }
      if(rCount == 0){
        showMessage(thisObj, 'There were no changes found that need to be saved!')
        return;
      }
        
      
      let dataObj = {
        "rcount" : rCount,
        "transactions" : js
      }
      
      let response = await AccountPayment.UpdateReleaseReadyToPayList(dataObj)
      if(response.message == 'Ready to pay released successfully.'){
        showMessage(thisObj, "Check was saved to Batch successfully!", true)
        ControlReset();
      }
      else{
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
      }
    }
    catch(err){      
      errorHandler(err);
    }
  }
  thisObj.onbtnSaveClick = onbtnSaveClick;

  const onbtnSaveToBatchClick = async () => {
    try {
      let rCount = 0
      let js = []

      let response = await ContractManagementService.RetrieveVendorByNumber(txtVendor.value)
      if (response.length == 0) {
        showMessage(thisObj, 'Vendor not found!')
        return;
      }
      if (response[0].number == '') {
        showMessage(thisObj, 'Vendor not found!')
        return;
      }
      if (response[0].pay_ind.toLocaleUpperCase() !== 'Y') {
        showMessage(thisObj, 'The Vendor Master Pay indicator is not set to Y!')
        return;
      }

      if (bCheck_AR_CR_SR() && getValue(thisObj, 'lblValueOfCheckValue') > 0) {
        let res = confirm("Do you wish to collect the AR, CR or SR that is due? ")
        if(res){
          if(isEnabled(thisObj, 'txtCollectSR')){
            document.getElementById('txtCollectSR').focus();
            return;
          }
          if(isEnabled(thisObj, 'txtCollectAR')){
            document.getElementById('txtCollectAR').focus();
            return;
          }
          if(isEnabled(thisObj, 'txtCollectCR')){
            document.getElementById('txtCollectCR').focus();
            return;
          }
        }        
      }
      
      if (thisObj.values['ddRemitTo'] == '' || thisObj.values['ddRemitTo'] == null || thisObj.values['ddRemitTo'] == undefined) {
        showMessage(thisObj, 'Remit To in red at the bottom of the screen must be selected!')
        return;
      }
      let holdLst = getValue(thisObj, "gridReadyToPayHold")
      
      for (var i = 0; i < holdLst.length; i++) {
        if (holdLst[i].txtcolHidden2H == 'R') {
          if (holdLst[i].rowDataH !== '') {
            let astrTransNum = holdLst[i].rowDataH.toString().split(',')
            for (var j = 0; j < astrTransNum.length; j++) {
              rCount++;
              js.push({
                "tn": astrTransNum[j],
                "ty": 'H'
              })
            }
          }
          else {
            rCount++;
            js.push({
              "tn": holdLst[j].rowDataH,
              "ty": 'H'
            })
          }
        }
      }

      let releaseLst = getValue(thisObj, "gridReadyToPayReleased")
      
      for (var i = 0; i < releaseLst.length; i++) {
        //if (releaseLst[i].txtcolHidden2R == 'H') {
          if (releaseLst[i].rowDataR !== '') {
            let astrTransNum = releaseLst[i].rowDataR.toString().split(',')
            for (var j = 0; j < astrTransNum.length; j++) {
              rCount++;
              js.push({
                "tn": astrTransNum[j],
                "ty": 'B'
              })
            }
          }
          else {
            rCount++;
            js.push({
              "tn": releaseLst[i].rowDataR,
              "ty": 'B'
            })
          }
        //}
      }

      let dataObj = {
        "user_id"        : useridFromLS(),
        "interest_amt"   : 0,
        "remit_to"       : thisObj.values['ddRemitTo'],
        "cr_amount"      : Number(txtCollectCR.value),
        "ar_amount"      : Number(txtCollectAR.value),
        "sr_amount"      : Number(txtCollectSR.value),
        "intr_agree_num" : "001826",
        "remarks"        : txtarRemarks.value,
        "rcount"         : rCount,
        "transactions"   : js
      }
      
      response = await AccountPayment.UpdateBatchReadyToPayDetails(thisObj.values['ddLocation'], txtVendor.value, dataObj)
      if(response.message == 'Updated batch ready to pay release successfully.'){
        showMessage(thisObj, "Check was saved to Batch successfully!", true)
        ControlReset();  
      }
      else
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
    }
    catch(err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnSaveToBatchClick = onbtnSaveToBatchClick;

  const onbtnPrintCheckClick = async() => {
    try{
      let rCount = 0;
      let lstrxml = "";
      let js = [];

      lstrxml = await ContractManagementService.RetrieveVendorByNumber(txtVendor.value)
      
      if(lstrxml.length <= 0){
        showMessage(thisObj, 'Vendor not found!')
        return;
      }
      if(lstrxml[0].number.trim() == ''){
        showMessage(thisObj, 'Vendor not found!')
        return;
      }
      if(lstrxml[0].pay_ind.toLocaleUpperCase() !== 'Y'){
        showMessage(thisObj, 'The Vendor Master Pay indicator is not set to Y!')
        return;
      }

      let n = 0;

      if(bCheck_AR_CR_SR() && getData(thisObj, 'lblValueOfCheckValue') > 0){
        let res = confirm("Do you wish to collect the AR, CR or SR that is due?")
        if(res){
          if(isEnabled(thisObj, 'txtCollectSR')){
            document.getElementById('txtCollectSR').focus()
            return;
          }
          if(isEnabled(thisObj, 'txtCollectAR')){
            document.getElementById('txtCollectAR').focus()
            return;
          }
          if(isEnabled(thisObj, 'txtCollectCR')){
            document.getElementById('txtCollectCR').focus()
            return;
          }
        }
      }

      if(thisObj.values['ddRemitTo'] == '' || thisObj.values['ddRemitTo'] == undefined || thisObj.values['ddRemitTo'] == null){
        showMessage(thisObj, 'Remit To in red at the bottom of the screen must be selected!')
        document.getElementById('ddRemitTo').focus();
        return;
      }

      let holdLst = getValue(thisObj, "gridReadyToPayHold")

      for (var i = 0; i < holdLst.length; i++) {
        if(holdLst[i].txtcolHidden2H == 'R') {
          let astrTransNum = holdLst[i].rowDataH.toString().split(',')
          if(astrTransNum.length > 0){
            for(var j=0; j<astrTransNum.length; j++){
              rCount++;
              js.push({
                "tn": astrTransNum[j],
                "ty": 'H'
              })
            }
          }
          else {
            rCount++;
            js.push({
              "tn": holdLst[j].rowDataH,
              "ty": 'H'
            })
          }
        }
      }
      
      let proceedsArr = []
      let releaseLst = getValue(thisObj, "gridReadyToPayReleased")
      for (var i = 0; i < releaseLst.length; i++) {
        proceedsArr.push(releaseLst[i].txtcolProceedsR)
        // if (releaseLst[i].txtcolHidden2R == 'H') {
          if (releaseLst[i].rowDataR !== '') {
            let astrTransNum = releaseLst[i].rowDataR.toString().split(',')
            for (var j = 0; j < astrTransNum.length; j++) {
              rCount++;
              js.push({
                "tn": astrTransNum[j],
                "ty": 'R'
              })
            }
          }
          else {
            rCount++;
            js.push({
              "tn": releaseLst[j].rowDataR,
              "ty": 'R'
            })
          }
        // }
      }

      if(getData(thisObj, 'lblValueOfCheckValue') > 0){
        let tempVal = prompt("Enter check number")
        if(tempVal == null || tempVal == undefined || tempVal == ''){
          showMessage(thisObj, 'Check number must be numeric')
          return;
        }
        if(Number(tempVal) <= 0){
          showMessage(thisObj, 'The Check Number cannot be Zero !')
          return;
        }
        if(Number(tempVal) > Number(getData(thisObj, 'txtEndCheckNum'))){
          showMessage(thisObj, 'The Check Number exceeds number range!')
          return;
        }
        checkNumber = tempVal
        setData(thisObj, 'textCheckNumber', FormatCheckNumber(checkNumber))
      }
      let dataObj = {
        "user_id": useridFromLS(),
        "interest_amt": 0,
        "remit_to": thisObj.values['ddRemitTo'],
        "cr_amount": Number(txtCollectCR.value),
        "ar_amount": Number(txtCollectAR.value),
        "sr_amount": Number(txtCollectSR.value),
        "intr_agree_num": "001826",
        "remarks": txtarRemarks.value,
        "rcount": rCount,
        "transactions": js
      }
      FormatCheckNumber(getData(thisObj, 'LstrReceivableNum'))
      checkNumber = checkNumber == '' ? FormatCheckNumber(getData(thisObj, 'LstrReceivableNum')) : checkNumber
      lstrxml = await AccountPayment.UpdateReleaseReadyToPayDetails(thisObj.values['ddLocation'], getData(thisObj, 'lblCollectionPoint'), getData(thisObj, 'textCheckNumber'),txtVendor.value, dataObj)

      if(lstrxml.message == 'Updated ready to pay release successfully.'){        
        let proceedsArrSum = 0
        for(var i=0; i<proceedsArr.length;i++){
          proceedsArrSum += (Number(proceedsArr[i]))
        }
        if(proceedsArrSum <= 0){
          await ControlReset();
          return;
        }
        // showMessage(thisObj, lstrxml.message, true) 
        // showMessage(thisObj, 'System Void Check: The check was created with a new number '+ checkNumber, true)
        let navigateObj = [{
          vendor       : txtVendor.value,
          VendorName   : txtVndor.value,
          buying_Point : thisObj.values['ddLocation'],
          check_num    : checkNumber,
          Title        : 'ORIGIN BUYING POINT',
          ScreenName   : 'READYTOPAYRELEASE',
          Proceeds     : proceedsArr
        }]
        setData(thisObj, 'vendorDetails', navigateObj);
        setData(thisObj, "ReportPreviewCheck", navigateObj)        
        goTo(thisObj, "AccountPayment#ReportPreviewCheck#DEFAULT#true#Click");  
      }
      else if(lstrxml.status == 500){
        alert('An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n' + lstrxml.message)
        return;
      }
      else{
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
        return;
      }
      await ControlReset();

    }
    catch(err){
      errorHandler(err);
    }
  }
  thisObj.onbtnPrintCheckClick = onbtnPrintCheckClick;

  function errorHandler(err){
    showMessage(thisObj, err instanceof EvalError ? err.message : 'Something went wrong. Please try again.')
  }

  const ongridReadyToPayHoldDblclick = async() => {
    try{
      await AddArray();
    }
    catch(err){
      errorHandler(err);
    }
  }
  thisObj.ongridReadyToPayHoldDblclick = ongridReadyToPayHoldDblclick;

  const ongridReadyToPayReleasedDblclick = async() => {
    try{
      await RemoveArray();
    }
    catch(err){
      errorHandler(err);
    }
  }
  thisObj.ongridReadyToPayReleasedDblclick = ongridReadyToPayReleasedDblclick;
  
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />

            <h1 className="pageHeader">{state.Label}</h1>

            <Form noValidate className="row">
              <GroupBoxWidget conf={state.grpbxDetails} screenConf={state}>
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <ButtonWidget
                  conf={state.btnBrowse}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCarrierAbbr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddremitTo}
                  screenConf={state}
                ></DropDownWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarmSuffix}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
              </GroupBoxWidget>

              <GroupBoxWidget conf={state.grpbxNoteChck} screenConf={state}>
                <LabelWidget
                  values={values}
                  conf={state.lblNOTE}
                  screenConf={state}
                ></LabelWidget>
              </GroupBoxWidget>
              <GroupBoxWidget conf={state.grpbxReadyToPay} screenConf={state}>
                <LabelWidget
                  values={values}
                  conf={state.lblRTPsHold}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblRTPsHoldValue}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblRTPsReleased}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblRTPsReleasedValue}
                  screenConf={state}
                ></LabelWidget>
                <GridWidget
                  conf={state.gridReadyToPayHold}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridReadyToPayHold}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                  refObject = {thisObj}
                ></GridWidget>

                <GroupBoxWidget conf={state.grpbxArrows} screenConf={state}>
                  <ButtonWidget
                    conf={state.btnArr1}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></ButtonWidget>

                  <ButtonWidget
                    conf={state.btnArr2}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></ButtonWidget>
                </GroupBoxWidget>

                <GridWidget
                  conf={state.gridReadyToPayReleased}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridReadyToPayReleased}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                  refObject = {thisObj}
                ></GridWidget>
              </GroupBoxWidget>
              <GroupBoxWidget conf={state.grpbxRemit} screenConf={state}>
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRemitTo}
                  screenConf={state}
                ></DropDownWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblLienholders}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblLienholdersValue}
                  screenConf={state}
                ></LabelWidget>
                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarRemarks}
                  screenConf={state}
                ></TextAreaWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblSaveNote}
                  screenConf={state}
                ></LabelWidget>
              </GroupBoxWidget>
              <GroupBoxWidget conf={state.grpbxBalance} screenConf={state}>
                <LabelWidget
                  values={values}
                  conf={state.labelwidget49}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheck}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEndCheckNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblValOfRTP}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblValOfRTPValue}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblBalance}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCollectSR}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblSRBalance}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCollectAR}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblARBalance}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCollectCR}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblCRBalance}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblValueOfCheck}
                  screenConf={state}
                ></LabelWidget>

                <LabelWidget
                  values={values}
                  conf={state.lblValueOfCheckValue}
                  screenConf={state}
                ></LabelWidget>
              </GroupBoxWidget>
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                <LabelWidget
                  values={values}
                  conf={state.lblCorpYear2}
                  screenConf={state}
                ></LabelWidget>
                <ButtonWidget
                  conf={state.btnSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnSaveToBatch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnPrintCheck}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
              </GroupBoxWidget>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(AccountPayment_ReadyToPayRelease);