/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  CheckboxWidget,
  LabelWidget,
  setValue,
  disable,
  getData,
  getValue,
  setData,

} from "../../shared/WindowImports";

import "./PremiumDeducMasterProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
//import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_PremiumDeducMasterProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PremiumDeductionMasterProfile",
    windowName: "PremiumDeducMasterProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.PremiumDeducMasterProfile",
    // START_USER_CODE-USER_PremiumDeducMasterProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Premium Deduction Master Profile",
      scrCode: "PN0100B",
    },
    // END_USER_CODE-USER_PremiumDeducMasterProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },

    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    chkboxCMAInd: {
      name: "chkboxCMAInd",
      type: "CheckBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "CMA Indicator",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMAInd_PROPERTIES

      // END_USER_CODE-USER_chkboxCMAInd_PROPERTIES
    },
    chkboxExcPrmInd: {
      name: "chkboxExcPrmInd",
      type: "CheckBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Exception Premium Indicator",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxExcPrmInd_PROPERTIES

      // END_USER_CODE-USER_chkboxExcPrmInd_PROPERTIES
    },
    chkboxFrtAccrlInd: {
      name: "chkboxFrtAccrlInd",
      type: "CheckBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Freight Accrual Indicator",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxFrtAccrlInd_PROPERTIES

      // END_USER_CODE-USER_chkboxFrtAccrlInd_PROPERTIES
    },
    chkboxInspInd: {
      name: "chkboxInspInd",
      type: "CheckBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Inspection Indicator",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxInspInd_PROPERTIES

      // END_USER_CODE-USER_chkboxInspInd_PROPERTIES
    },
    chkboxPrint1007Strg: {
      name: "chkboxPrint1007Strg",
      type: "CheckBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Print 1007 Storage",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxPrint1007Strg_PROPERTIES

      // END_USER_CODE-USER_chkboxPrint1007Strg_PROPERTIES
    },
    chkboxRcrdWtAcct: {
      name: "chkboxRcrdWtAcct",
      type: "CheckBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Record Weights in Acct",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxRcrdWtAcct_PROPERTIES

      // END_USER_CODE-USER_chkboxRcrdWtAcct_PROPERTIES
    },
    chkboxRepPremCntrctPstn: {
      name: "chkboxRepPremCntrctPstn",
      type: "CheckBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Report Premium on Contract Position",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxRepPremCntrctPstn_PROPERTIES

      // END_USER_CODE-USER_chkboxRepPremCntrctPstn_PROPERTIES
    },
    chkboxRvnInd: {
      name: "chkboxRvnInd",
      type: "CheckBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Revenue Indicator",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxRvnInd_PROPERTIES

      // END_USER_CODE-USER_chkboxRvnInd_PROPERTIES
    },
    chkboxSpecChkInd: {
      name: "chkboxSpecChkInd",
      type: "CheckBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Special Check Indicator",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxSpecChkInd_PROPERTIES

      // END_USER_CODE-USER_chkboxSpecChkInd_PROPERTIES
    },
    chkboxTax1009Ind: {
      name: "chkboxTax1009Ind",
      type: "CheckBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Tax 1009 Indicator",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxTax1009Ind_PROPERTIES

      // END_USER_CODE-USER_chkboxTax1009Ind_PROPERTIES
    },
    chkboxTradeSettleInd: {
      name: "chkboxTradeSettleInd",
      type: "CheckBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Trade Settlement Indicator",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxTradeSettleInd_PROPERTIES

      // END_USER_CODE-USER_chkboxTradeSettleInd_PROPERTIES
    },
    chkboxWhseInd: {
      name: "chkboxWhseInd",
      type: "CheckBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Warehouse Indicator",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxWhseInd_PROPERTIES

      // END_USER_CODE-USER_chkboxWhseInd_PROPERTIES
    },
    ddAccountingIndicator: {
      name: "ddAccountingIndicator",
      type: "DropDownFieldWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Accounting Indicator:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAccountingIndicator_PROPERTIES

      // END_USER_CODE-USER_ddAccountingIndicator_PROPERTIES
    },
    ddIndicator: {
      name: "ddIndicator",
      type: "DropDownFieldWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Indicator:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddIndicator_PROPERTIES

      // END_USER_CODE-USER_ddIndicator_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtAccount: {
      name: "txtAccount",
      type: "TextBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Account:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccount_PROPERTIES

      // END_USER_CODE-USER_txtAccount_PROPERTIES
    },
    txtDiscription: {
      name: "txtDiscription",
      type: "TextBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "Discription:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 50 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDiscription_PROPERTIES

      // END_USER_CODE-USER_txtDiscription_PROPERTIES
    },
    txtPDCode: {
      name: "txtPDCode",
      type: "TextBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "PD Code:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPDCode_PROPERTIES

      // END_USER_CODE-USER_txtPDCode_PROPERTIES
    },
    txtPDSeedAccount: {
      name: "txtPDSeedAccount",
      type: "TextBoxWidget",
      parent: "grpbxPremiumDeductionMasterProfile",
      Label: "PD Seed Account:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPDSeedAccount_PROPERTIES

      // END_USER_CODE-USER_txtPDSeedAccount_PROPERTIES
    },
    grpbxPremiumDeductionMasterProfile: {
      name: "grpbxPremiumDeductionMasterProfile",
      type: "GroupBoxWidget",
      parent: "PremiumDeducMasterProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPremiumDeductionMasterProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxPremiumDeductionMasterProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "PremiumDeducMasterProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);

    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

 useEffect(() => {
    
    var buttonCaption
    let data = getData(thisObj, 'frmPremiumDeducMasterProfile')
   
    if (data !== null && data !== undefined && data !== '') {
      if(data.cmdOk_Caption == 'Add'){
        document.getElementById("btnOk").innerText = "Add";
        setData(thisObj,"buttonCaption",data.cmdOk_Caption)
      }
      if(data.cmdOk_Caption == 'Update')
      {
        document.getElementById("btnOk").innerText = "Update";
        disable(thisObj, 'txtPDCode')
        setValue(thisObj, "txtPDCode", data.txtPDCode)
        setValue(thisObj, "txtDiscription", data.txtDiscription)
        setValue(thisObj, 'txtAccount', data.txtAccount)
        setValue(thisObj, 'txtPDSeedAccount', data.txtPDSeedAccount)
        setValue(thisObj, 'ddIndicator', data.lstIndicator)
        setValue(thisObj, 'ddAccountingIndicator', data.lstAccountingIndicator)
        setValue(thisObj, "chkboxCMAInd", data.chkCMAInd)
        setValue(thisObj, "chkboxFrtAccrlInd", data.chkFreightAccrualInd)
        setValue(thisObj, "chkboxRcrdWtAcct", data.chkDISTQTYIND)
        setValue(thisObj, "chkboxExcPrmInd", data.chkExceptPrem)
        setValue(thisObj, "chkboxInspInd", data.chkInspInd)
        setValue(thisObj, "chkboxPrint1007Strg", data.chkPrint1007Storage)
        setValue(thisObj, 'chkboxRepPremCntrctPstn', data.chkReportPrem)
        setValue(thisObj, "chkboxSpecChkInd", data.chkSPlCheckInd)
        setValue(thisObj, "chkboxTax1009Ind", data.chkTax1099Ind)
        setValue(thisObj, "chkboxTradeSettleInd", data.chkTradeSettleInd)
        setValue(thisObj, "chkboxWhseInd", data.chkWarehouseInd)
        setValue(thisObj, "chkboxRvnInd", data.chkRevenueInd)
        setValue(thisObj, "lblAddedBy", data.lblAddedBy)
        setValue(thisObj, "lblChangedBy", data.lblChangedBy)
        setData(thisObj,"buttonCaption",data.cmdOk_Caption) 
      }
    }
  },[getData(thisObj, 'frmPremiumDeducMasterProfile')]);
  
  const FormLoad = () => {
    bFillIndicatorList()
    bFillAccountingIndicatorList()

  }
  const bFillIndicatorList = () => {
    let js = []

    js.push({ key: 'P', description: 'Premium' })
    js.push({ key: 'D', description: 'Deduction' })
    setValue(thisObj, 'ddIndicator', js.at(0).key);

    thisObj.setState(current => {
      return {
        ...current,
        ddIndicator: {
          ...state["ddIndicator"],
          valueList: js
        }
      }
    })
    
  }
  const bFillAccountingIndicatorList = () => {
    let js = []

    js.push({ key: 'P/L', description: 'Profit/Loss' })
    js.push({ key: 'BAL', description: 'BALANCE' })
    setValue(thisObj, 'ddAccountingIndicator', js.at(0).key);

    thisObj.setState(current => {
      return {
        ...current,
        ddAccountingIndicator: {
          ...state["ddAccountingIndicator"],
          valueList: js
        }
      }
    })


  }

  const FormValid = () => {
    var bFormValid = false
    let txtPDCode = getValue(thisObj, 'txtPDCode')
    let txtDiscription = getValue(thisObj, 'txtDiscription')
    let txtAccount = getValue(thisObj, 'txtAccount')
    if (txtPDCode == null || txtPDCode == '' || txtPDCode == undefined) {
      showMessage(thisObj, "PDCode is a required field")
      return bFormValid;
    }
    if (txtDiscription == null || txtDiscription == '' || txtDiscription == undefined) {
      showMessage(thisObj, "Description is a required field")
      return bFormValid;
    }
    if (txtAccount == null || txtAccount == '' || txtAccount == undefined) {
      showMessage(thisObj, "Account is a required field")
      return bFormValid;
    }

    bFormValid = true
  }


  const onchkboxCMAIndChange = () => {
    if (thisObj.values.chkboxCMAInd == true && thisObj.values.chkboxExcPrmInd == true) {
      showMessage(thisObj, "CMA Indicator can not be selected while Exception Premium Indicator is selected.")
      return;
    }
  }
  thisObj.onchkboxCMAIndChange = onchkboxCMAIndChange;

  const onchkboxRcrdWtAcctChange = () => {
    if (thisObj.values.chkboxRcrdWtAcct == true && thisObj.values.chkboxExcPrmInd == true) {
      showMessage(thisObj, "Record Weights in Acct Indicator can not be selected while Exception Premium Indicator is selected.")
      return;
    }
  }
  thisObj.onchkboxRcrdWtAcctChange = onchkboxRcrdWtAcctChange;
  const onchkboxSpecChkIndChange = () => {
    if (thisObj.values.chkboxSpecChkInd == true && thisObj.values.chkboxExcPrmInd == true) {
      showMessage(thisObj, "Special Check Indicator can not be selected while Exception Premium Indicator is selected.")
      return;
    }
  }
  thisObj.onchkboxSpecChkIndChange = onchkboxSpecChkIndChange;

  const onchkboxInspIndChange = () => {
    if (thisObj.values.chkboxInspInd == true && thisObj.values.chkboxExcPrmInd == true) {
      showMessage(thisObj, "Inspection Indicator can not be selected while Exception Premium Indicator is selected.")
      return;
    }
  }
  thisObj.onchkboxInspIndChange = onchkboxInspIndChange;

  const onchkboxWhseIndChange = () => {
    if (thisObj.values.chkboxWhseInd == true && thisObj.values.chkboxExcPrmInd == true) {
      showMessage(thisObj, "Warehouse Indicator can not be selected while Exception Premium Indicator is selected.")
      return;
    }
  }
  thisObj.onchkboxWhseIndChange = onchkboxWhseIndChange;

  const onchkboxRvnIndChange = () => {
    if (thisObj.values.chkboxRvnInd == true && thisObj.values.chkboxExcPrmInd == true) {
      showMessage(thisObj, "Revenue Indicator can not be selected while Exception Premium Indicator is selected.")
      return;
    }
  }
  thisObj.onchkboxRvnIndChange = onchkboxRvnIndChange;

  const onchkboxTradeSettleIndChange = () => {
    if (thisObj.values.chkboxTradeSettleInd == true && thisObj.values.chkboxExcPrmInd == true) {
      showMessage(thisObj, "Trade Setttlement Indicator can not be selected while Exception Premium Indicator is selected.")
      return;
    }
  }
  thisObj.onchkboxTradeSettleIndChange = onchkboxTradeSettleIndChange;


  const onchkboxTax1009IndChange = () => {
    if (thisObj.values.chkboxTax1009Ind == true && thisObj.values.chkboxExcPrmInd == true) {
      showMessage(thisObj, "Tax 1009 Indicator can not be selected while Exception Premium Indicator is selected.")
      return;
    }
  }
  thisObj.onchkboxTax1009IndChange = onchkboxTax1009IndChange;

  const onchkboxPrint1007StrgChange = () => {
    if (thisObj.values.chkboxPrint1007Strg == true && thisObj.values.chkboxExcPrmInd == true) {
      showMessage(thisObj, "Print 1007 Storage can not be selected while Exception Premium Indicator is selected.")
      return;
    }
  }
  thisObj.onchkboxPrint1007StrgChange = onchkboxPrint1007StrgChange;

  const onchkboxFrtAccrlIndChange = () => {
    if (thisObj.values.chkboxFrtAccrlInd == true && thisObj.values.chkboxExcPrmInd == true) {
      showMessage(thisObj, "Freight Accrual Indicator  can not be selected while Exception Premium Indicator is selected.")
      return;
    }
  }
  thisObj.onchkboxFrtAccrlIndChange = onchkboxFrtAccrlIndChange;

  const onchkboxRepPremCntrctPstnChange = () => {
    if (thisObj.values.chkboxRepPremCntrctPstn == true && thisObj.values.chkboxExcPrmInd == false) {
      showMessage(thisObj, "Exception Premium Indicator must be selected in order to Report Premium on Contract Position.")
      setValue(thisObj, 'chkboxRepPremCntrctPstn', false)
      return;
    }
  }
  thisObj.onchkboxRepPremCntrctPstnChange = onchkboxRepPremCntrctPstnChange;

  const onchkboxExcPrmIndChange = () => {
    if (thisObj.values.chkboxExcPrmInd == true) {
      if (getValue(thisObj, 'ddIndicator') != 'P') {
        showMessage(thisObj, "Exception Premium Must Indicator can not be selected unless this item is coded as a Premium.")
        setValue(thisObj, 'chkboxExcPrmInd', false)
        return;
      }

      if (thisObj.values.chkboxSpecChkInd == true) {
        showMessage(thisObj, "Exception Premium Indicator can not be selected while Special Check Indicator is selected.")
        setValue(thisObj, 'chkboxExcPrmInd', false)
        return;
      }
      if (thisObj.values.chkboxInspInd == true) {
        showMessage(thisObj, "Exception Premium Indicator can not be selected while Inspection Indicator is selected.")
        setValue(thisObj, 'chkboxExcPrmInd', false)
        return;
      }
      if (thisObj.values.chkboxTax1009Ind == true) {
        showMessage(thisObj, "Exception Premium Indicator can not be selected while Tax 1009 Indicator is selected.")
        setValue(thisObj, 'chkboxExcPrmInd', false)
        return;
      }
      if (thisObj.values.chkboxWhseInd == true) {
        showMessage(thisObj, "Exception Premium Indicator can not be selected while Warehouse Indicator is selected.")
        setValue(thisObj, 'chkboxExcPrmInd', false)
        return;
      }
      if (thisObj.values.chkboxRvnInd == true) {
        showMessage(thisObj, "Exception Premium Indicator can not be selected while Revenue Indicator is selected.")
        setValue(thisObj, 'chkboxExcPrmInd', false)
        return;
      }
      if (thisObj.values.chkboxTradeSettleInd == true) {
        showMessage(thisObj, "Exception Premium Indicator can not be selected while Trade Settlement Indicator is selected.")
        setValue(thisObj, 'chkboxExcPrmInd', false)
        return;
      }
      if (thisObj.values.chkboxRcrdWtAcct == true) {
        showMessage(thisObj, "Exception Premium Indicator can not be selected while Record Weights in Acct is selected.")
        setValue(thisObj, 'chkboxExcPrmInd', false)
        return;
      }
      if (thisObj.values.chkboxPrint1007Strg == true) {
        showMessage(thisObj, "Exception Premium Indicator can not be selected while Print 1007 Storage is selected.")
        setValue(thisObj, 'chkboxExcPrmInd', false)
        return;
      }
      if (thisObj.values.chkboxFrtAccrlInd == true) {
        showMessage(thisObj, "Exception Premium Indicator can not be selected while Freight Accrual Indicator is selected.")
        setValue(thisObj, 'chkboxExcPrmInd', false)
        return;
      }
      if (thisObj.values.chkboxCMAInd == true) {
        showMessage(thisObj, "Exception Premium Indicator can not be selected while CMA Indicator is selected.")
        setValue(thisObj, 'chkboxExcPrmInd', false)
        return;
      }

    }
    else {
      setValue(thisObj, 'chkboxRepPremCntrctPstn', false)
    }

  }
  thisObj.onchkboxExcPrmIndChange = onchkboxExcPrmIndChange;

  const ontxtpdcodeChange = (e) => {
    setValue(thisObj, 'txtpdcode', e.target.value)
  }
  thisObj.ontxtpdcodeChange = ontxtpdcodeChange;

  const ontxtdescriptionChange = (e) => {
    setValue(thisObj, 'txtDiscription', e.target.value)
  }
  thisObj.ontxtdescriptionChange = ontxtdescriptionChange;

  const ontxtAccountChange = (e) => {
    setValue(thisObj, 'txtAccount', e.target.value)
  }
  thisObj.ontxtAccountChange = ontxtAccountChange;


  const ontxtPDSEEDAccountChange = (e) => {
    setValue(thisObj, 'txtPDSeedAccount', e.target.value)
  }
  thisObj.ontxtPDSEEDAccountChange = ontxtPDSEEDAccountChange;

  //buttonfunctionality for ok
  const onbtnOkClick = async () => {
    try {

      let rec_weig_inacc = ''
      let rev_ind = ''

      if (thisObj.values.chkboxRcrdWtAcct == true) {
        rec_weig_inacc = "Y"
      }
      else { rec_weig_inacc = "N" }
      if (thisObj.values.chkboxRvnInd == true) {
        rev_ind = "Y"
      }
      else {
        rev_ind = "N"
      }
      let dataObj = {
        "pd_code": getValue(thisObj, 'txtPDCode')== undefined ? '':  getValue(thisObj, 'txtPDCode'),
        "pd_desc": getValue(thisObj, 'txtDiscription')== undefined ? '':  getValue(thisObj, 'txtDiscription'),
        "pd_ind": getValue(thisObj, 'ddIndicator'),
        "pd_account": getValue(thisObj, 'txtAccount')== undefined ? '':  getValue(thisObj, 'txtAccount'),
        "pd_seed_acct": getValue(thisObj, 'txtPDSeedAccount')== undefined ? '':  getValue(thisObj, 'txtPDSeedAccount'),
        "pl_bal_acct_ind": getValue(thisObj, 'ddAccountingIndicator'),
        "comp_revenue_ind": rev_ind,
        "dist_qty_ind": rec_weig_inacc,
        "insp_enable_ind": getValue(thisObj, 'chkboxInspInd') == true ? 'Y' : 'N',
        "rcpt_enable_ind": getValue(thisObj, 'chkboxWhseInd') == true ? 'Y' : 'N',
        "check_enable_ind": getValue(thisObj, 'chkboxSpecChkInd') == true ? 'Y' : 'N',
        "rpt_1099_ind": getValue(thisObj, 'chkboxTax1009Ind') == true ? 'Y' : 'N',
        "print_1007_storage": getValue(thisObj, 'chkboxPrint1007Strg') == true ? 'Y' : 'N',
        "trade_settle_enable_ind": getValue(thisObj, 'chkboxTradeSettleInd') == true ? 'Y' : 'N',
        "freight_enable_ind": getValue(thisObj, 'chkboxFrtAccrlInd') == true ? 'Y' : 'N',
        "cma_ind_flag": getValue(thisObj, 'chkboxCMAInd') == true ? 'Y' : 'N',
        "except_prem_ind": getValue(thisObj, 'chkboxExcPrmInd') == true ? 'Y' : 'N',
        "contract_rpt_ind": getValue(thisObj, 'chkboxRepPremCntrctPstn') == true ? 'Y' : 'N'
      }


      if (FormValid() == false) {
        return;
      }
      if (getData(thisObj,"buttonCaption") == "Add") {

        let response = await SystemMaintenanceSpecialFunctionsService.CreatePremiumDeductionMaster(null, dataObj)
        let data = response
        if (data.status != 200) {
          showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.", false);
          return;

        }
        let dataobj1 = {
          'lstPDCode_desc': `${getValue(thisObj, 'txtPDCode')} " - " ${getValue(thisObj, 'txtDiscription')}`,
          'txtPDCode': getValue(thisObj, 'txtPDCode')
        }
        setData(thisObj, 'dataforparentscreen', dataobj1)
        setData(thisObj, "refreshindicator", true)
        document.getElementById('SystemMaintenanceSpecialFunctions_PremiumDeducMasterProfilePopUp').childNodes[0].click()
      }
      if (getData(thisObj,"buttonCaption") == "Update") {
        let search = "/"
        let replaceWith = "~"
        let pd_code
       let pd_code1 = getValue(thisObj, 'txtPDCode')
       if(pd_code1.includes("/")) {
       pd_code = pd_code1.split(search).join(replaceWith);
       }
       else
       {
        pd_code = pd_code1
       }
        let response = await SystemMaintenanceSpecialFunctionsService.UpdatePremiumDeductionMaster(null,pd_code, dataObj)
        let data = response
        if (data.status != 200) {
          showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.", false);
          return;
        }
        let dataobj2 = {
          'lstPDCode_desc': `${getValue(thisObj, 'txtPDCode')} " - " ${getValue(thisObj, 'txtDiscription')}`,
          'txtPDCode': getValue(thisObj, 'txtPDCode')
        }
        setData(thisObj, 'dataforparentscreen1', dataobj2)
        setData(thisObj, 'refreshindicator', true)
        document.getElementById('SystemMaintenanceSpecialFunctions_PremiumDeducMasterProfilePopUp').childNodes[0].click()

      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btn event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnOkClick = onbtnOkClick;

  const onbtnCancelClick = () => {
    try {
      document.getElementById('SystemMaintenanceSpecialFunctions_PremiumDeducMasterProfilePopUp').childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;


  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">Header</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PremiumDeducMasterProfile*/}

              {/* END_USER_CODE-USER_BEFORE_PremiumDeducMasterProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPremiumDeductionMasterProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPremiumDeductionMasterProfile*/}

              <GroupBoxWidget
                conf={state.grpbxPremiumDeductionMasterProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtPDCode*/}

                {/* END_USER_CODE-USER_BEFORE_txtPDCode*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPDCode}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPDCode*/}

                {/* END_USER_CODE-USER_AFTER_txtPDCode*/}
                {/* START_USER_CODE-USER_BEFORE_txtDiscription*/}

                {/* END_USER_CODE-USER_BEFORE_txtDiscription*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDiscription}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDiscription*/}

                {/* END_USER_CODE-USER_AFTER_txtDiscription*/}
                {/* START_USER_CODE-USER_BEFORE_ddIndicator*/}

                {/* END_USER_CODE-USER_BEFORE_ddIndicator*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddIndicator}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddIndicator*/}

                {/* END_USER_CODE-USER_AFTER_ddIndicator*/}
                {/* START_USER_CODE-USER_BEFORE_txtAccount*/}

                {/* END_USER_CODE-USER_BEFORE_txtAccount*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAccount*/}

                {/* END_USER_CODE-USER_AFTER_txtAccount*/}
                {/* START_USER_CODE-USER_BEFORE_txtPDSeedAccount*/}

                {/* END_USER_CODE-USER_BEFORE_txtPDSeedAccount*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPDSeedAccount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPDSeedAccount*/}

                {/* END_USER_CODE-USER_AFTER_txtPDSeedAccount*/}
                {/* START_USER_CODE-USER_BEFORE_ddAccountingIndicator*/}

                {/* END_USER_CODE-USER_BEFORE_ddAccountingIndicator*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAccountingIndicator}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAccountingIndicator*/}

                {/* END_USER_CODE-USER_AFTER_ddAccountingIndicator*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxSpecChkInd*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxSpecChkInd*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxSpecChkInd}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxSpecChkInd*/}

                {/* END_USER_CODE-USER_AFTER_chkboxSpecChkInd*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxRcrdWtAcct*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxRcrdWtAcct*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxRcrdWtAcct}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxRcrdWtAcct*/}

                {/* END_USER_CODE-USER_AFTER_chkboxRcrdWtAcct*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxInspInd*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxInspInd*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxInspInd}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxInspInd*/}

                {/* END_USER_CODE-USER_AFTER_chkboxInspInd*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxPrint1007Strg*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxPrint1007Strg*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxPrint1007Strg}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxPrint1007Strg*/}

                {/* END_USER_CODE-USER_AFTER_chkboxPrint1007Strg*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxTax1009Ind*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxTax1009Ind*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxTax1009Ind}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxTax1009Ind*/}

                {/* END_USER_CODE-USER_AFTER_chkboxTax1009Ind*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxFrtAccrlInd*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxFrtAccrlInd*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxFrtAccrlInd}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxFrtAccrlInd*/}

                {/* END_USER_CODE-USER_AFTER_chkboxFrtAccrlInd*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxWhseInd*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxWhseInd*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxWhseInd}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxWhseInd*/}

                {/* END_USER_CODE-USER_AFTER_chkboxWhseInd*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxCMAInd*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxCMAInd*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxCMAInd}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxCMAInd*/}

                {/* END_USER_CODE-USER_AFTER_chkboxCMAInd*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxRvnInd*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxRvnInd*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxRvnInd}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxRvnInd*/}

                {/* END_USER_CODE-USER_AFTER_chkboxRvnInd*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxExcPrmInd*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxExcPrmInd*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxExcPrmInd}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxExcPrmInd*/}

                {/* END_USER_CODE-USER_AFTER_chkboxExcPrmInd*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxTradeSettleInd*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxTradeSettleInd*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxTradeSettleInd}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxTradeSettleInd*/}

                {/* END_USER_CODE-USER_AFTER_chkboxTradeSettleInd*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxRepPremCntrctPstn*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxRepPremCntrctPstn*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxRepPremCntrctPstn}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxRepPremCntrctPstn*/}

                {/* END_USER_CODE-USER_AFTER_chkboxRepPremCntrctPstn*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPremiumDeductionMasterProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPremiumDeductionMasterProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_PremiumDeducMasterProfile*/}

              {/* END_USER_CODE-USER_AFTER_PremiumDeducMasterProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_PremiumDeducMasterProfile
);
