/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_ContractLimitSearch from "./ContractLimitSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ContractLimitSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_ContractLimitSearch />
      );
    });
  });
  afterEach(cleanup);
  test("is ContractLimitSearch Loads Successfully", () => {
    expect(screen.getByText("ContractLimitSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for ContractLimitSearch", () => {
    // START_USER_CODE-USER_ContractLimitSearch_Custom_Test_Case
    // END_USER_CODE-USER_ContractLimitSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_ContractLimitSearch />
      );
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitSearch_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitSearch_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitSearch_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitSearch_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:ContractLimitSearch_btnExportToExcel"
      )
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnNewOffer(Button Widget) Test Cases", async () => {
    const btnNewOffer = screen.getByTestId("btnNewOffer");
    expect(btnNewOffer).toBeInTheDocument;
    expect(btnNewOffer.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitSearch_btnNewOffer")
    );
  });
  test("Custom Test Cases for btnNewOffer", () => {
    // START_USER_CODE-USER_btnNewOffer_TEST
    // END_USER_CODE-USER_btnNewOffer_TEST
  });
  test("btnPrintGrid(Button Widget) Test Cases", async () => {
    const btnPrintGrid = screen.getByTestId("btnPrintGrid");
    expect(btnPrintGrid).toBeInTheDocument;
    expect(btnPrintGrid.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitSearch_btnPrintGrid")
    );
  });
  test("Custom Test Cases for btnPrintGrid", () => {
    // START_USER_CODE-USER_btnPrintGrid_TEST
    // END_USER_CODE-USER_btnPrintGrid_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitSearch_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridContractLimitSearchData(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchDatabtn =
      gridContractLimitSearchData.nextElementSibling.firstElementChild;
    gridContractLimitSearchData =
      gridContractLimitSearchData.parentElement.parentElement.parentElement;
    expect(gridContractLimitSearchData.tagName).toBe("DIV");
    expect(gridContractLimitSearchData.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridContractLimitSearchData", () => {
    // START_USER_CODE-USER_gridContractLimitSearchData_TEST
    // END_USER_CODE-USER_gridContractLimitSearchData_TEST
  });
  test("grpbxContractDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxContractDetails = screen.getByTestId("grpbxContractDetails");
    expect(grpbxContractDetails.tagName).toBe("BUTTON");
    expect(grpbxContractDetails.type).toBe("button");
    expect(grpbxContractDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContractDetails", () => {
    // START_USER_CODE-USER_grpbxContractDetails_TEST
    // END_USER_CODE-USER_grpbxContractDetails_TEST
  });
  test("grpbxContractLimitSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxContractLimitSearch = screen.getByTestId(
      "grpbxContractLimitSearch"
    );
    expect(grpbxContractLimitSearch.tagName).toBe("BUTTON");
    expect(grpbxContractLimitSearch.type).toBe("button");
    expect(grpbxContractLimitSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContractLimitSearch", () => {
    // START_USER_CODE-USER_grpbxContractLimitSearch_TEST
    // END_USER_CODE-USER_grpbxContractLimitSearch_TEST
  });
  test("grpbxContractsSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxContractsSearch = screen.getByTestId("grpbxContractsSearch");
    expect(grpbxContractsSearch.tagName).toBe("BUTTON");
    expect(grpbxContractsSearch.type).toBe("button");
    expect(grpbxContractsSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContractsSearch", () => {
    // START_USER_CODE-USER_grpbxContractsSearch_TEST
    // END_USER_CODE-USER_grpbxContractsSearch_TEST
  });
  test("lblPeanut(Label Widget) Test Cases", async () => {
    const lblPeanut = screen.getByTestId("lblPeanut");
    expect(lblPeanut.tagName).toBe("LABEL");
    expect(lblPeanut.classList).toContain("form-label");
    expect(lblPeanut.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitSearch_lblPeanut")
    );
  });
  test("Custom Test Cases for lblPeanut", () => {
    // START_USER_CODE-USER_lblPeanut_TEST
    // END_USER_CODE-USER_lblPeanut_TEST
  });
  test("lblTotals(Label Widget) Test Cases", async () => {
    const lblTotals = screen.getByTestId("lblTotals");
    expect(lblTotals.tagName).toBe("LABEL");
    expect(lblTotals.classList).toContain("form-label");
    expect(lblTotals.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitSearch_lblTotals")
    );
  });
  test("Custom Test Cases for lblTotals", () => {
    // START_USER_CODE-USER_lblTotals_TEST
    // END_USER_CODE-USER_lblTotals_TEST
  });
  test("gridContractLimitSearchData_txt1(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txt1 = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txt1btn =
      gridContractLimitSearchData_txt1.nextElementSibling.firstElementChild;
    gridContractLimitSearchData_txt1 =
      gridContractLimitSearchData_txt1.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txt1.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txt1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txt1", () => {
    // START_USER_CODE-USER_txt1_TEST
    // END_USER_CODE-USER_txt1_TEST
  });
  test("gridContractLimitSearchData_txtAreaC(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtAreaC = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtAreaCbtn =
      gridContractLimitSearchData_txtAreaC.nextElementSibling.firstElementChild;
    gridContractLimitSearchData_txtAreaC =
      gridContractLimitSearchData_txtAreaC.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtAreaC.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtAreaC.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtAreaC", () => {
    // START_USER_CODE-USER_txtAreaC_TEST
    // END_USER_CODE-USER_txtAreaC_TEST
  });
  test("gridContractLimitSearchData_txtcolDryLandAcresC(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtcolDryLandAcresC = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtcolDryLandAcresCbtn =
      gridContractLimitSearchData_txtcolDryLandAcresC.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtcolDryLandAcresC =
      gridContractLimitSearchData_txtcolDryLandAcresC.parentElement
        .parentElement.parentElement;
    expect(gridContractLimitSearchData_txtcolDryLandAcresC.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtcolDryLandAcresC.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDryLandAcresC", () => {
    // START_USER_CODE-USER_txtcolDryLandAcresC_TEST
    // END_USER_CODE-USER_txtcolDryLandAcresC_TEST
  });
  test("txtContract(Textbox Widget) Test Cases", async () => {
    const txtContract = screen.getByTestId("txtContract");
    expect(txtContract.tagName).toBe("INPUT");
    expect(txtContract.type).toBe("text");
    expect(txtContract.classList).toContain("textboxWidgetClass");
    expect(txtContract.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitSearch_txtContract")
    );
    await act(async () => {
      userEvent.type(txtContract, "1");
    });
  });
  test("Custom Test Cases for txtContract", () => {
    // START_USER_CODE-USER_txtContract_TEST
    // END_USER_CODE-USER_txtContract_TEST
  });
  test("txtDryLandAcresContracted(Textbox Widget) Test Cases", async () => {
    const txtDryLandAcresContracted = screen.getByTestId(
      "txtDryLandAcresContracted"
    );
    expect(txtDryLandAcresContracted.tagName).toBe("INPUT");
    expect(txtDryLandAcresContracted.type).toBe("text");
    expect(txtDryLandAcresContracted.classList).toContain("textboxWidgetClass");
    expect(
      txtDryLandAcresContracted.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceMasterManagement:ContractLimitSearch_txtDryLandAcresContracted"
      )
    );
    await act(async () => {
      userEvent.type(txtDryLandAcresContracted, "1");
    });
  });
  test("Custom Test Cases for txtDryLandAcresContracted", () => {
    // START_USER_CODE-USER_txtDryLandAcresContracted_TEST
    // END_USER_CODE-USER_txtDryLandAcresContracted_TEST
  });
  test("txtIrrigatedAcresContracted(Textbox Widget) Test Cases", async () => {
    const txtIrrigatedAcresContracted = screen.getByTestId(
      "txtIrrigatedAcresContracted"
    );
    expect(txtIrrigatedAcresContracted.tagName).toBe("INPUT");
    expect(txtIrrigatedAcresContracted.type).toBe("text");
    expect(txtIrrigatedAcresContracted.classList).toContain(
      "textboxWidgetClass"
    );
    expect(
      txtIrrigatedAcresContracted.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceMasterManagement:ContractLimitSearch_txtIrrigatedAcresContracted"
      )
    );
    await act(async () => {
      userEvent.type(txtIrrigatedAcresContracted, "1");
    });
  });
  test("Custom Test Cases for txtIrrigatedAcresContracted", () => {
    // START_USER_CODE-USER_txtIrrigatedAcresContracted_TEST
    // END_USER_CODE-USER_txtIrrigatedAcresContracted_TEST
  });
  test("gridContractLimitSearchData_txtIrrigatedAcresContractedC(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtIrrigatedAcresContractedC = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtIrrigatedAcresContractedCbtn =
      gridContractLimitSearchData_txtIrrigatedAcresContractedC
        .nextElementSibling.firstElementChild;
    gridContractLimitSearchData_txtIrrigatedAcresContractedC =
      gridContractLimitSearchData_txtIrrigatedAcresContractedC.parentElement
        .parentElement.parentElement;
    expect(
      gridContractLimitSearchData_txtIrrigatedAcresContractedC.tagName
    ).toBe("DIV");
    expect(
      gridContractLimitSearchData_txtIrrigatedAcresContractedC.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtIrrigatedAcresContractedC", () => {
    // START_USER_CODE-USER_txtIrrigatedAcresContractedC_TEST
    // END_USER_CODE-USER_txtIrrigatedAcresContractedC_TEST
  });
  test("gridContractLimitSearchData_txtLoanRepayMethod(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtLoanRepayMethod = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtLoanRepayMethodbtn =
      gridContractLimitSearchData_txtLoanRepayMethod.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtLoanRepayMethod =
      gridContractLimitSearchData_txtLoanRepayMethod.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtLoanRepayMethod.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtLoanRepayMethod.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtLoanRepayMethod", () => {
    // START_USER_CODE-USER_txtLoanRepayMethod_TEST
    // END_USER_CODE-USER_txtLoanRepayMethod_TEST
  });
  test("gridContractLimitSearchData_txtMktGainSharePercent(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtMktGainSharePercent = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtMktGainSharePercentbtn =
      gridContractLimitSearchData_txtMktGainSharePercent.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtMktGainSharePercent =
      gridContractLimitSearchData_txtMktGainSharePercent.parentElement
        .parentElement.parentElement;
    expect(gridContractLimitSearchData_txtMktGainSharePercent.tagName).toBe(
      "DIV"
    );
    expect(
      gridContractLimitSearchData_txtMktGainSharePercent.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtMktGainSharePercent", () => {
    // START_USER_CODE-USER_txtMktGainSharePercent_TEST
    // END_USER_CODE-USER_txtMktGainSharePercent_TEST
  });
  test("gridContractLimitSearchData_txtMultiPricing(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtMultiPricing = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtMultiPricingbtn =
      gridContractLimitSearchData_txtMultiPricing.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtMultiPricing =
      gridContractLimitSearchData_txtMultiPricing.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtMultiPricing.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtMultiPricing.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtMultiPricing", () => {
    // START_USER_CODE-USER_txtMultiPricing_TEST
    // END_USER_CODE-USER_txtMultiPricing_TEST
  });
  test("gridContractLimitSearchData_txtOleicC(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtOleicC = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtOleicCbtn =
      gridContractLimitSearchData_txtOleicC.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtOleicC =
      gridContractLimitSearchData_txtOleicC.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtOleicC.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtOleicC.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtOleicC", () => {
    // START_USER_CODE-USER_txtOleicC_TEST
    // END_USER_CODE-USER_txtOleicC_TEST
  });
  test("txtOpenTons(Textbox Widget) Test Cases", async () => {
    const txtOpenTons = screen.getByTestId("txtOpenTons");
    expect(txtOpenTons.tagName).toBe("INPUT");
    expect(txtOpenTons.type).toBe("text");
    expect(txtOpenTons.classList).toContain("textboxWidgetClass");
    expect(txtOpenTons.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitSearch_txtOpenTons")
    );
    await act(async () => {
      userEvent.type(txtOpenTons, "1");
    });
  });
  test("Custom Test Cases for txtOpenTons", () => {
    // START_USER_CODE-USER_txtOpenTons_TEST
    // END_USER_CODE-USER_txtOpenTons_TEST
  });
  test("gridContractLimitSearchData_txtOptionPriceCap(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtOptionPriceCap = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtOptionPriceCapbtn =
      gridContractLimitSearchData_txtOptionPriceCap.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtOptionPriceCap =
      gridContractLimitSearchData_txtOptionPriceCap.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtOptionPriceCap.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtOptionPriceCap.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtOptionPriceCap", () => {
    // START_USER_CODE-USER_txtOptionPriceCap_TEST
    // END_USER_CODE-USER_txtOptionPriceCap_TEST
  });
  test("gridContractLimitSearchData_txtOrganicC(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtOrganicC = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtOrganicCbtn =
      gridContractLimitSearchData_txtOrganicC.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtOrganicC =
      gridContractLimitSearchData_txtOrganicC.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtOrganicC.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtOrganicC.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtOrganicC", () => {
    // START_USER_CODE-USER_txtOrganicC_TEST
    // END_USER_CODE-USER_txtOrganicC_TEST
  });
  test("gridContractLimitSearchData_txtPayRebatAt(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtPayRebatAt = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtPayRebatAtbtn =
      gridContractLimitSearchData_txtPayRebatAt.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtPayRebatAt =
      gridContractLimitSearchData_txtPayRebatAt.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtPayRebatAt.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtPayRebatAt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPayRebatAt", () => {
    // START_USER_CODE-USER_txtPayRebatAt_TEST
    // END_USER_CODE-USER_txtPayRebatAt_TEST
  });
  test("gridContractLimitSearchData_txtPeanut(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtPeanut = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtPeanutbtn =
      gridContractLimitSearchData_txtPeanut.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtPeanut =
      gridContractLimitSearchData_txtPeanut.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtPeanut.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtPeanut.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPeanut", () => {
    // START_USER_CODE-USER_txtPeanut_TEST
    // END_USER_CODE-USER_txtPeanut_TEST
  });
  test("gridContractLimitSearchData_txtPricePerTon(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtPricePerTon = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtPricePerTonbtn =
      gridContractLimitSearchData_txtPricePerTon.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtPricePerTon =
      gridContractLimitSearchData_txtPricePerTon.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtPricePerTon.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtPricePerTon.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPricePerTon", () => {
    // START_USER_CODE-USER_txtPricePerTon_TEST
    // END_USER_CODE-USER_txtPricePerTon_TEST
  });
  test("gridContractLimitSearchData_txtPriceWatchEndDate(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtPriceWatchEndDate = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtPriceWatchEndDatebtn =
      gridContractLimitSearchData_txtPriceWatchEndDate.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtPriceWatchEndDate =
      gridContractLimitSearchData_txtPriceWatchEndDate.parentElement
        .parentElement.parentElement;
    expect(gridContractLimitSearchData_txtPriceWatchEndDate.tagName).toBe(
      "DIV"
    );
    expect(
      gridContractLimitSearchData_txtPriceWatchEndDate.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPriceWatchEndDate", () => {
    // START_USER_CODE-USER_txtPriceWatchEndDate_TEST
    // END_USER_CODE-USER_txtPriceWatchEndDate_TEST
  });
  test("gridContractLimitSearchData_txtPricingTypeDesc(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtPricingTypeDesc = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtPricingTypeDescbtn =
      gridContractLimitSearchData_txtPricingTypeDesc.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtPricingTypeDesc =
      gridContractLimitSearchData_txtPricingTypeDesc.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtPricingTypeDesc.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtPricingTypeDesc.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPricingTypeDesc", () => {
    // START_USER_CODE-USER_txtPricingTypeDesc_TEST
    // END_USER_CODE-USER_txtPricingTypeDesc_TEST
  });
  test("gridContractLimitSearchData_txtSegC(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtSegC = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtSegCbtn =
      gridContractLimitSearchData_txtSegC.nextElementSibling.firstElementChild;
    gridContractLimitSearchData_txtSegC =
      gridContractLimitSearchData_txtSegC.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtSegC.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtSegC.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtSegC", () => {
    // START_USER_CODE-USER_txtSegC_TEST
    // END_USER_CODE-USER_txtSegC_TEST
  });
  test("txtTonsAllocated(Textbox Widget) Test Cases", async () => {
    const txtTonsAllocated = screen.getByTestId("txtTonsAllocated");
    expect(txtTonsAllocated.tagName).toBe("INPUT");
    expect(txtTonsAllocated.type).toBe("text");
    expect(txtTonsAllocated.classList).toContain("textboxWidgetClass");
    expect(txtTonsAllocated.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:ContractLimitSearch_txtTonsAllocated"
      )
    );
    await act(async () => {
      userEvent.type(txtTonsAllocated, "1");
    });
  });
  test("Custom Test Cases for txtTonsAllocated", () => {
    // START_USER_CODE-USER_txtTonsAllocated_TEST
    // END_USER_CODE-USER_txtTonsAllocated_TEST
  });
  test("gridContractLimitSearchData_txtTonsAllocatedC(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtTonsAllocatedC = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtTonsAllocatedCbtn =
      gridContractLimitSearchData_txtTonsAllocatedC.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtTonsAllocatedC =
      gridContractLimitSearchData_txtTonsAllocatedC.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtTonsAllocatedC.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtTonsAllocatedC.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtTonsAllocatedC", () => {
    // START_USER_CODE-USER_txtTonsAllocatedC_TEST
    // END_USER_CODE-USER_txtTonsAllocatedC_TEST
  });
  test("txtTonsContracted(Textbox Widget) Test Cases", async () => {
    const txtTonsContracted = screen.getByTestId("txtTonsContracted");
    expect(txtTonsContracted.tagName).toBe("INPUT");
    expect(txtTonsContracted.type).toBe("text");
    expect(txtTonsContracted.classList).toContain("textboxWidgetClass");
    expect(txtTonsContracted.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:ContractLimitSearch_txtTonsContracted"
      )
    );
    await act(async () => {
      userEvent.type(txtTonsContracted, "1");
    });
  });
  test("Custom Test Cases for txtTonsContracted", () => {
    // START_USER_CODE-USER_txtTonsContracted_TEST
    // END_USER_CODE-USER_txtTonsContracted_TEST
  });
  test("gridContractLimitSearchData_txtTonsContractedC(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtTonsContractedC = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtTonsContractedCbtn =
      gridContractLimitSearchData_txtTonsContractedC.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtTonsContractedC =
      gridContractLimitSearchData_txtTonsContractedC.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtTonsContractedC.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtTonsContractedC.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtTonsContractedC", () => {
    // START_USER_CODE-USER_txtTonsContractedC_TEST
    // END_USER_CODE-USER_txtTonsContractedC_TEST
  });
  test("gridContractLimitSearchData_txtTonsOpen(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtTonsOpen = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtTonsOpenbtn =
      gridContractLimitSearchData_txtTonsOpen.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtTonsOpen =
      gridContractLimitSearchData_txtTonsOpen.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtTonsOpen.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtTonsOpen.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtTonsOpen", () => {
    // START_USER_CODE-USER_txtTonsOpen_TEST
    // END_USER_CODE-USER_txtTonsOpen_TEST
  });
  test("txtTotalAcresContracted(Textbox Widget) Test Cases", async () => {
    const txtTotalAcresContracted = screen.getByTestId(
      "txtTotalAcresContracted"
    );
    expect(txtTotalAcresContracted.tagName).toBe("INPUT");
    expect(txtTotalAcresContracted.type).toBe("text");
    expect(txtTotalAcresContracted.classList).toContain("textboxWidgetClass");
    expect(txtTotalAcresContracted.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:ContractLimitSearch_txtTotalAcresContracted"
      )
    );
    await act(async () => {
      userEvent.type(txtTotalAcresContracted, "1");
    });
  });
  test("Custom Test Cases for txtTotalAcresContracted", () => {
    // START_USER_CODE-USER_txtTotalAcresContracted_TEST
    // END_USER_CODE-USER_txtTotalAcresContracted_TEST
  });
  test("gridContractLimitSearchData_txtTotalAcresContractedC(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtTotalAcresContractedC = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtTotalAcresContractedCbtn =
      gridContractLimitSearchData_txtTotalAcresContractedC.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtTotalAcresContractedC =
      gridContractLimitSearchData_txtTotalAcresContractedC.parentElement
        .parentElement.parentElement;
    expect(gridContractLimitSearchData_txtTotalAcresContractedC.tagName).toBe(
      "DIV"
    );
    expect(
      gridContractLimitSearchData_txtTotalAcresContractedC.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtTotalAcresContractedC", () => {
    // START_USER_CODE-USER_txtTotalAcresContractedC_TEST
    // END_USER_CODE-USER_txtTotalAcresContractedC_TEST
  });
  test("gridContractLimitSearchData_txtVariety(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtVariety = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtVarietybtn =
      gridContractLimitSearchData_txtVariety.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtVariety =
      gridContractLimitSearchData_txtVariety.parentElement.parentElement
        .parentElement;
    expect(gridContractLimitSearchData_txtVariety.tagName).toBe("DIV");
    expect(gridContractLimitSearchData_txtVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtVariety", () => {
    // START_USER_CODE-USER_txtVariety_TEST
    // END_USER_CODE-USER_txtVariety_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:ContractLimitSearch_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVndr(Textbox Widget) Test Cases", async () => {
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVndr, "1");
    });
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
  test("gridContractLimitSearchData_txtWeeksPriorMaturity(Grid Widget) Test Cases", async () => {
    let gridContractLimitSearchData_txtWeeksPriorMaturity = screen.getByTestId(
      "gridContractLimitSearchData"
    );
    let gridContractLimitSearchData_txtWeeksPriorMaturitybtn =
      gridContractLimitSearchData_txtWeeksPriorMaturity.nextElementSibling
        .firstElementChild;
    gridContractLimitSearchData_txtWeeksPriorMaturity =
      gridContractLimitSearchData_txtWeeksPriorMaturity.parentElement
        .parentElement.parentElement;
    expect(gridContractLimitSearchData_txtWeeksPriorMaturity.tagName).toBe(
      "DIV"
    );
    expect(
      gridContractLimitSearchData_txtWeeksPriorMaturity.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:ContractLimitSearch_gridContractLimitSearchData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtWeeksPriorMaturity", () => {
    // START_USER_CODE-USER_txtWeeksPriorMaturity_TEST
    // END_USER_CODE-USER_txtWeeksPriorMaturity_TEST
  });
});
