/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_SpecFuncMisc from "./SpecFuncMisc";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SpecFuncMisc Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_SpecFuncMisc />);
    });
  });
  afterEach(cleanup);
  test("is SpecFuncMisc Loads Successfully", () => {
    expect(screen.getByText("SpecFuncMisc")).toBeInTheDocument;
  });
  test("Custom Test Cases for SpecFuncMisc", () => {
    // START_USER_CODE-USER_SpecFuncMisc_Custom_Test_Case
    // END_USER_CODE-USER_SpecFuncMisc_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceApplicationSupport_SpecFuncMisc />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnRequest(Button Widget) Test Cases", async () => {
    const btnRequest = screen.getByTestId("btnRequest");
    expect(btnRequest).toBeInTheDocument;
    expect(btnRequest.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_btnRequest")
    );
  });
  test("Custom Test Cases for btnRequest", () => {
    // START_USER_CODE-USER_btnRequest_TEST
    // END_USER_CODE-USER_btnRequest_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("grpbxChange1007Unload(GroupBox Widget) Test Cases", async () => {
    const grpbxChange1007Unload = screen.getByTestId(
      "grpbxChange1007Unload"
    );
    expect(grpbxChange1007Unload.tagName).toBe("BUTTON");
    expect(grpbxChange1007Unload.type).toBe("button");
    expect(grpbxChange1007Unload.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxChange1007Unload", () => {
    // START_USER_CODE-USER_grpbxChange1007Unload_TEST
    // END_USER_CODE-USER_grpbxChange1007Unload_TEST
  });
  test("grpbxChangeOleicInd(GroupBox Widget) Test Cases", async () => {
    const grpbxChangeOleicInd = screen.getByTestId(
      "grpbxChangeOleicInd"
    );
    expect(grpbxChangeOleicInd.tagName).toBe("BUTTON");
    expect(grpbxChangeOleicInd.type).toBe("button");
    expect(grpbxChangeOleicInd.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxChangeOleicInd", () => {
    // START_USER_CODE-USER_grpbxChangeOleicInd_TEST
    // END_USER_CODE-USER_grpbxChangeOleicInd_TEST
  });
  test("grpbxChangeVicamReading(GroupBox Widget) Test Cases", async () => {
    const grpbxChangeVicamReading = screen.getByTestId(
      "grpbxChangeVicamReading"
    );
    expect(grpbxChangeVicamReading.tagName).toBe("BUTTON");
    expect(grpbxChangeVicamReading.type).toBe("button");
    expect(grpbxChangeVicamReading.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxChangeVicamReading", () => {
    // START_USER_CODE-USER_grpbxChangeVicamReading_TEST
    // END_USER_CODE-USER_grpbxChangeVicamReading_TEST
  });
  test("grpbxChgDt(GroupBox Widget) Test Cases", async () => {
    const grpbxChgDt = screen.getByTestId("grpbxChgDt");
    expect(grpbxChgDt.tagName).toBe("BUTTON");
    expect(grpbxChgDt.type).toBe("button");
    expect(grpbxChgDt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxChgDt", () => {
    // START_USER_CODE-USER_grpbxChgDt_TEST
    // END_USER_CODE-USER_grpbxChgDt_TEST
  });
  test("grpbxChgGrds(GroupBox Widget) Test Cases", async () => {
    const grpbxChgGrds = screen.getByTestId("grpbxChgGrds");
    expect(grpbxChgGrds.tagName).toBe("BUTTON");
    expect(grpbxChgGrds.type).toBe("button");
    expect(grpbxChgGrds.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxChgGrds", () => {
    // START_USER_CODE-USER_grpbxChgGrds_TEST
    // END_USER_CODE-USER_grpbxChgGrds_TEST
  });
  test("grpbxCopyFarms(GroupBox Widget) Test Cases", async () => {
    const grpbxCopyFarms = screen.getByTestId("grpbxCopyFarms");
    expect(grpbxCopyFarms.tagName).toBe("BUTTON");
    expect(grpbxCopyFarms.type).toBe("button");
    expect(grpbxCopyFarms.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCopyFarms", () => {
    // START_USER_CODE-USER_grpbxCopyFarms_TEST
    // END_USER_CODE-USER_grpbxCopyFarms_TEST
  });
  test("grpbxDeleteStockTransfer(GroupBox Widget) Test Cases", async () => {
    const grpbxDeleteStockTransfer = screen.getByTestId(
      "grpbxDeleteStockTransfer"
    );
    expect(grpbxDeleteStockTransfer.tagName).toBe("BUTTON");
    expect(grpbxDeleteStockTransfer.type).toBe("button");
    expect(grpbxDeleteStockTransfer.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDeleteStockTransfer", () => {
    // START_USER_CODE-USER_grpbxDeleteStockTransfer_TEST
    // END_USER_CODE-USER_grpbxDeleteStockTransfer_TEST
  });
  test("grpbxNewCY(GroupBox Widget) Test Cases", async () => {
    const grpbxNewCY = screen.getByTestId("grpbxNewCY");
    expect(grpbxNewCY.tagName).toBe("BUTTON");
    expect(grpbxNewCY.type).toBe("button");
    expect(grpbxNewCY.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxNewCY", () => {
    // START_USER_CODE-USER_grpbxNewCY_TEST
    // END_USER_CODE-USER_grpbxNewCY_TEST
  });
  test("grpbxReopenVoidedClosedContract(GroupBox Widget) Test Cases", async () => {
    const grpbxReopenVoidedClosedContract = screen.getByTestId(
      "grpbxReopenVoidedClosedContract"
    );
    expect(grpbxReopenVoidedClosedContract.tagName).toBe("BUTTON");
    expect(grpbxReopenVoidedClosedContract.type).toBe("button");
    expect(grpbxReopenVoidedClosedContract.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReopenVoidedClosedContract", () => {
    // START_USER_CODE-USER_grpbxReopenVoidedClosedContract_TEST
    // END_USER_CODE-USER_grpbxReopenVoidedClosedContract_TEST
  });
  test("grpbxRequestARDownload(GroupBox Widget) Test Cases", async () => {
    const grpbxRequestARDownload = screen.getByTestId(
      "grpbxRequestARDownload"
    );
    expect(grpbxRequestARDownload.tagName).toBe("BUTTON");
    expect(grpbxRequestARDownload.type).toBe("button");
    expect(grpbxRequestARDownload.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRequestARDownload", () => {
    // START_USER_CODE-USER_grpbxRequestARDownload_TEST
    // END_USER_CODE-USER_grpbxRequestARDownload_TEST
  });
  test("grpbxResetCheckToVoid(GroupBox Widget) Test Cases", async () => {
    const grpbxResetCheckToVoid = screen.getByTestId(
      "grpbxResetCheckToVoid"
    );
    expect(grpbxResetCheckToVoid.tagName).toBe("BUTTON");
    expect(grpbxResetCheckToVoid.type).toBe("button");
    expect(grpbxResetCheckToVoid.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxResetCheckToVoid", () => {
    // START_USER_CODE-USER_grpbxResetCheckToVoid_TEST
    // END_USER_CODE-USER_grpbxResetCheckToVoid_TEST
  });
  test("grpbxResetSignedContract(GroupBox Widget) Test Cases", async () => {
    const grpbxResetSignedContract = screen.getByTestId(
      "grpbxResetSignedContract"
    );
    expect(grpbxResetSignedContract.tagName).toBe("BUTTON");
    expect(grpbxResetSignedContract.type).toBe("button");
    expect(grpbxResetSignedContract.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxResetSignedContract", () => {
    // START_USER_CODE-USER_grpbxResetSignedContract_TEST
    // END_USER_CODE-USER_grpbxResetSignedContract_TEST
  });
  test("grpbxResetUpload211(GroupBox Widget) Test Cases", async () => {
    const grpbxResetUpload211 = screen.getByTestId(
      "grpbxResetUpload211"
    );
    expect(grpbxResetUpload211.tagName).toBe("BUTTON");
    expect(grpbxResetUpload211.type).toBe("button");
    expect(grpbxResetUpload211.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxResetUpload211", () => {
    // START_USER_CODE-USER_grpbxResetUpload211_TEST
    // END_USER_CODE-USER_grpbxResetUpload211_TEST
  });
  test("grpbxResetUploadContract(GroupBox Widget) Test Cases", async () => {
    const grpbxResetUploadContract = screen.getByTestId(
      "grpbxResetUploadContract"
    );
    expect(grpbxResetUploadContract.tagName).toBe("BUTTON");
    expect(grpbxResetUploadContract.type).toBe("button");
    expect(grpbxResetUploadContract.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxResetUploadContract", () => {
    // START_USER_CODE-USER_grpbxResetUploadContract_TEST
    // END_USER_CODE-USER_grpbxResetUploadContract_TEST
  });
  test("grpbxSelectFunc(GroupBox Widget) Test Cases", async () => {
    const grpbxSelectFunc = screen.getByTestId("grpbxSelectFunc");
    expect(grpbxSelectFunc.tagName).toBe("BUTTON");
    expect(grpbxSelectFunc.type).toBe("button");
    expect(grpbxSelectFunc.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSelectFunc", () => {
    // START_USER_CODE-USER_grpbxSelectFunc_TEST
    // END_USER_CODE-USER_grpbxSelectFunc_TEST
  });
  test("grpbxSpecFuncMisc(GroupBox Widget) Test Cases", async () => {
    const grpbxSpecFuncMisc = screen.getByTestId("grpbxSpecFuncMisc");
    expect(grpbxSpecFuncMisc.tagName).toBe("BUTTON");
    expect(grpbxSpecFuncMisc.type).toBe("button");
    expect(grpbxSpecFuncMisc.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSpecFuncMisc", () => {
    // START_USER_CODE-USER_grpbxSpecFuncMisc_TEST
    // END_USER_CODE-USER_grpbxSpecFuncMisc_TEST
  });
  test("lblChange1007Unload(Label Widget) Test Cases", async () => {
    const lblChange1007Unload = screen.getByTestId(
      "lblChange1007Unload"
    );
    expect(lblChange1007Unload.tagName).toBe("LABEL");
    expect(lblChange1007Unload.classList).toContain("form-label");
    expect(lblChange1007Unload.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_lblChange1007Unload"
      )
    );
  });
  test("Custom Test Cases for lblChange1007Unload", () => {
    // START_USER_CODE-USER_lblChange1007Unload_TEST
    // END_USER_CODE-USER_lblChange1007Unload_TEST
  });
  test("lblChangeOleicInd(Label Widget) Test Cases", async () => {
    const lblChangeOleicInd = screen.getByTestId("lblChangeOleicInd");
    expect(lblChangeOleicInd.tagName).toBe("LABEL");
    expect(lblChangeOleicInd.classList).toContain("form-label");
    expect(lblChangeOleicInd.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_lblChangeOleicInd"
      )
    );
  });
  test("Custom Test Cases for lblChangeOleicInd", () => {
    // START_USER_CODE-USER_lblChangeOleicInd_TEST
    // END_USER_CODE-USER_lblChangeOleicInd_TEST
  });
  test("lblChangeVicamReading(Label Widget) Test Cases", async () => {
    const lblChangeVicamReading = screen.getByTestId(
      "lblChangeVicamReading"
    );
    expect(lblChangeVicamReading.tagName).toBe("LABEL");
    expect(lblChangeVicamReading.classList).toContain("form-label");
    expect(lblChangeVicamReading.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_lblChangeVicamReading"
      )
    );
  });
  test("Custom Test Cases for lblChangeVicamReading", () => {
    // START_USER_CODE-USER_lblChangeVicamReading_TEST
    // END_USER_CODE-USER_lblChangeVicamReading_TEST
  });
  test("lblChgDt(Label Widget) Test Cases", async () => {
    const lblChgDt = screen.getByTestId("lblChgDt");
    expect(lblChgDt.tagName).toBe("LABEL");
    expect(lblChgDt.classList).toContain("form-label");
    expect(lblChgDt.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_lblChgDt")
    );
  });
  test("Custom Test Cases for lblChgDt", () => {
    // START_USER_CODE-USER_lblChgDt_TEST
    // END_USER_CODE-USER_lblChgDt_TEST
  });
  test("lblChgGrds(Label Widget) Test Cases", async () => {
    const lblChgGrds = screen.getByTestId("lblChgGrds");
    expect(lblChgGrds.tagName).toBe("LABEL");
    expect(lblChgGrds.classList).toContain("form-label");
    expect(lblChgGrds.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_lblChgGrds")
    );
  });
  test("Custom Test Cases for lblChgGrds", () => {
    // START_USER_CODE-USER_lblChgGrds_TEST
    // END_USER_CODE-USER_lblChgGrds_TEST
  });
  test("lblCopyFarms(Label Widget) Test Cases", async () => {
    const lblCopyFarms = screen.getByTestId("lblCopyFarms");
    expect(lblCopyFarms.tagName).toBe("LABEL");
    expect(lblCopyFarms.classList).toContain("form-label");
    expect(lblCopyFarms.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_lblCopyFarms")
    );
  });
  test("Custom Test Cases for lblCopyFarms", () => {
    // START_USER_CODE-USER_lblCopyFarms_TEST
    // END_USER_CODE-USER_lblCopyFarms_TEST
  });
  test("lblDeleteStockTransfer(Label Widget) Test Cases", async () => {
    const lblDeleteStockTransfer = screen.getByTestId(
      "lblDeleteStockTransfer"
    );
    expect(lblDeleteStockTransfer.tagName).toBe("LABEL");
    expect(lblDeleteStockTransfer.classList).toContain("form-label");
    expect(lblDeleteStockTransfer.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_lblDeleteStockTransfer"
      )
    );
  });
  test("Custom Test Cases for lblDeleteStockTransfer", () => {
    // START_USER_CODE-USER_lblDeleteStockTransfer_TEST
    // END_USER_CODE-USER_lblDeleteStockTransfer_TEST
  });
  test("lblNewCY(Label Widget) Test Cases", async () => {
    const lblNewCY = screen.getByTestId("lblNewCY");
    expect(lblNewCY.tagName).toBe("LABEL");
    expect(lblNewCY.classList).toContain("form-label");
    expect(lblNewCY.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_lblNewCY")
    );
  });
  test("Custom Test Cases for lblNewCY", () => {
    // START_USER_CODE-USER_lblNewCY_TEST
    // END_USER_CODE-USER_lblNewCY_TEST
  });
  test("lblNOTE(Label Widget) Test Cases", async () => {
    const lblNOTE = screen.getByTestId("lblNOTE");
    expect(lblNOTE.tagName).toBe("LABEL");
    expect(lblNOTE.classList).toContain("form-label");
    expect(lblNOTE.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_lblNOTE")
    );
  });
  test("Custom Test Cases for lblNOTE", () => {
    // START_USER_CODE-USER_lblNOTE_TEST
    // END_USER_CODE-USER_lblNOTE_TEST
  });
  test("lblNoteValue(Label Widget) Test Cases", async () => {
    const lblNoteValue = screen.getByTestId("lblNoteValue");
    expect(lblNoteValue.tagName).toBe("LABEL");
    expect(lblNoteValue.classList).toContain("form-label");
    expect(lblNoteValue.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_lblNoteValue")
    );
  });
  test("Custom Test Cases for lblNoteValue", () => {
    // START_USER_CODE-USER_lblNoteValue_TEST
    // END_USER_CODE-USER_lblNoteValue_TEST
  });
  test("lblReopenVoidedClosedContract(Label Widget) Test Cases", async () => {
    const lblReopenVoidedClosedContract = screen.getByTestId(
      "lblReopenVoidedClosedContract"
    );
    expect(lblReopenVoidedClosedContract.tagName).toBe("LABEL");
    expect(lblReopenVoidedClosedContract.classList).toContain("form-label");
    expect(lblReopenVoidedClosedContract.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_lblReopenVoidedClosedContract"
      )
    );
  });
  test("Custom Test Cases for lblReopenVoidedClosedContract", () => {
    // START_USER_CODE-USER_lblReopenVoidedClosedContract_TEST
    // END_USER_CODE-USER_lblReopenVoidedClosedContract_TEST
  });
  test("lblReqKCMORecon(Label Widget) Test Cases", async () => {
    const lblReqKCMORecon = screen.getByTestId("lblReqKCMORecon");
    expect(lblReqKCMORecon.tagName).toBe("LABEL");
    expect(lblReqKCMORecon.classList).toContain("form-label");
    expect(lblReqKCMORecon.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_lblReqKCMORecon")
    );
  });
  test("Custom Test Cases for lblReqKCMORecon", () => {
    // START_USER_CODE-USER_lblReqKCMORecon_TEST
    // END_USER_CODE-USER_lblReqKCMORecon_TEST
  });
  test("lblRequestARDownload(Label Widget) Test Cases", async () => {
    const lblRequestARDownload = screen.getByTestId(
      "lblRequestARDownload"
    );
    expect(lblRequestARDownload.tagName).toBe("LABEL");
    expect(lblRequestARDownload.classList).toContain("form-label");
    expect(lblRequestARDownload.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_lblRequestARDownload"
      )
    );
  });
  test("Custom Test Cases for lblRequestARDownload", () => {
    // START_USER_CODE-USER_lblRequestARDownload_TEST
    // END_USER_CODE-USER_lblRequestARDownload_TEST
  });
  test("lblResetCheckToVoid(Label Widget) Test Cases", async () => {
    const lblResetCheckToVoid = screen.getByTestId(
      "lblResetCheckToVoid"
    );
    expect(lblResetCheckToVoid.tagName).toBe("LABEL");
    expect(lblResetCheckToVoid.classList).toContain("form-label");
    expect(lblResetCheckToVoid.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_lblResetCheckToVoid"
      )
    );
  });
  test("Custom Test Cases for lblResetCheckToVoid", () => {
    // START_USER_CODE-USER_lblResetCheckToVoid_TEST
    // END_USER_CODE-USER_lblResetCheckToVoid_TEST
  });
  test("lblResetSignedContract(Label Widget) Test Cases", async () => {
    const lblResetSignedContract = screen.getByTestId(
      "lblResetSignedContract"
    );
    expect(lblResetSignedContract.tagName).toBe("LABEL");
    expect(lblResetSignedContract.classList).toContain("form-label");
    expect(lblResetSignedContract.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_lblResetSignedContract"
      )
    );
  });
  test("Custom Test Cases for lblResetSignedContract", () => {
    // START_USER_CODE-USER_lblResetSignedContract_TEST
    // END_USER_CODE-USER_lblResetSignedContract_TEST
  });
  test("lblResetUpload211(Label Widget) Test Cases", async () => {
    const lblResetUpload211 = screen.getByTestId("lblResetUpload211");
    expect(lblResetUpload211.tagName).toBe("LABEL");
    expect(lblResetUpload211.classList).toContain("form-label");
    expect(lblResetUpload211.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_lblResetUpload211"
      )
    );
  });
  test("Custom Test Cases for lblResetUpload211", () => {
    // START_USER_CODE-USER_lblResetUpload211_TEST
    // END_USER_CODE-USER_lblResetUpload211_TEST
  });
  test("lblResetUploadContract(Label Widget) Test Cases", async () => {
    const lblResetUploadContract = screen.getByTestId(
      "lblResetUploadContract"
    );
    expect(lblResetUploadContract.tagName).toBe("LABEL");
    expect(lblResetUploadContract.classList).toContain("form-label");
    expect(lblResetUploadContract.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_lblResetUploadContract"
      )
    );
  });
  test("Custom Test Cases for lblResetUploadContract", () => {
    // START_USER_CODE-USER_lblResetUploadContract_TEST
    // END_USER_CODE-USER_lblResetUploadContract_TEST
  });
  test("txtArea(Textbox Widget) Test Cases", async () => {
    const txtArea = screen.getByTestId("txtArea");
    expect(txtArea.tagName).toBe("INPUT");
    expect(txtArea.type).toBe("text");
    expect(txtArea.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtArea, "1");
    });
  });
  test("Custom Test Cases for txtArea", () => {
    // START_USER_CODE-USER_txtArea_TEST
    // END_USER_CODE-USER_txtArea_TEST
  });
  test("txtBin(Textbox Widget) Test Cases", async () => {
    const txtBin = screen.getByTestId("txtBin");
    expect(txtBin.tagName).toBe("INPUT");
    expect(txtBin.type).toBe("text");
    expect(txtBin.classList).toContain("textboxWidgetClass");
    expect(txtBin.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtBin")
    );
    await act(async () => {
      userEvent.type(txtBin, "1");
    });
  });
  test("Custom Test Cases for txtBin", () => {
    // START_USER_CODE-USER_txtBin_TEST
    // END_USER_CODE-USER_txtBin_TEST
  });
  test("txtBuyPtCopyFrom(Textbox Widget) Test Cases", async () => {
    const txtBuyPtCopyFrom = screen.getByTestId("txtBuyPtCopyFrom");
    expect(txtBuyPtCopyFrom.tagName).toBe("INPUT");
    expect(txtBuyPtCopyFrom.type).toBe("text");
    expect(txtBuyPtCopyFrom.classList).toContain("textboxWidgetClass");
    expect(txtBuyPtCopyFrom.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtBuyPtCopyFrom")
    );
    await act(async () => {
      userEvent.type(txtBuyPtCopyFrom, "1");
    });
  });
  test("Custom Test Cases for txtBuyPtCopyFrom", () => {
    // START_USER_CODE-USER_txtBuyPtCopyFrom_TEST
    // END_USER_CODE-USER_txtBuyPtCopyFrom_TEST
  });
  test("txtBuyPtIdChgGrds(Textbox Widget) Test Cases", async () => {
    const txtBuyPtIdChgGrds = screen.getByTestId("txtBuyPtIdChgGrds");
    expect(txtBuyPtIdChgGrds.tagName).toBe("INPUT");
    expect(txtBuyPtIdChgGrds.type).toBe("text");
    expect(txtBuyPtIdChgGrds.classList).toContain("textboxWidgetClass");
    expect(txtBuyPtIdChgGrds.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_txtBuyPtIdChgGrds"
      )
    );
    await act(async () => {
      userEvent.type(txtBuyPtIdChgGrds, "1");
    });
  });
  test("Custom Test Cases for txtBuyPtIdChgGrds", () => {
    // START_USER_CODE-USER_txtBuyPtIdChgGrds_TEST
    // END_USER_CODE-USER_txtBuyPtIdChgGrds_TEST
  });
  test("txtBuyPtIdChgUnload(Textbox Widget) Test Cases", async () => {
    const txtBuyPtIdChgUnload = screen.getByTestId(
      "txtBuyPtIdChgUnload"
    );
    expect(txtBuyPtIdChgUnload.tagName).toBe("INPUT");
    expect(txtBuyPtIdChgUnload.type).toBe("text");
    expect(txtBuyPtIdChgUnload.classList).toContain("textboxWidgetClass");
    expect(txtBuyPtIdChgUnload.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_txtBuyPtIdChgUnload"
      )
    );
    await act(async () => {
      userEvent.type(txtBuyPtIdChgUnload, "1");
    });
  });
  test("Custom Test Cases for txtBuyPtIdChgUnload", () => {
    // START_USER_CODE-USER_txtBuyPtIdChgUnload_TEST
    // END_USER_CODE-USER_txtBuyPtIdChgUnload_TEST
  });
  test("txtBuyPtIdChgVicam(Textbox Widget) Test Cases", async () => {
    const txtBuyPtIdChgVicam = screen.getByTestId("txtBuyPtIdChgVicam");
    expect(txtBuyPtIdChgVicam.tagName).toBe("INPUT");
    expect(txtBuyPtIdChgVicam.type).toBe("text");
    expect(txtBuyPtIdChgVicam.classList).toContain("textboxWidgetClass");
    expect(txtBuyPtIdChgVicam.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_txtBuyPtIdChgVicam"
      )
    );
    await act(async () => {
      userEvent.type(txtBuyPtIdChgVicam, "1");
    });
  });
  test("Custom Test Cases for txtBuyPtIdChgVicam", () => {
    // START_USER_CODE-USER_txtBuyPtIdChgVicam_TEST
    // END_USER_CODE-USER_txtBuyPtIdChgVicam_TEST
  });
  test("txtBuyPtIdCopyTo(Textbox Widget) Test Cases", async () => {
    const txtBuyPtIdCopyTo = screen.getByTestId("txtBuyPtIdCopyTo");
    expect(txtBuyPtIdCopyTo.tagName).toBe("INPUT");
    expect(txtBuyPtIdCopyTo.type).toBe("text");
    expect(txtBuyPtIdCopyTo.classList).toContain("textboxWidgetClass");
    expect(txtBuyPtIdCopyTo.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtBuyPtIdCopyTo")
    );
    await act(async () => {
      userEvent.type(txtBuyPtIdCopyTo, "1");
    });
  });
  test("Custom Test Cases for txtBuyPtIdCopyTo", () => {
    // START_USER_CODE-USER_txtBuyPtIdCopyTo_TEST
    // END_USER_CODE-USER_txtBuyPtIdCopyTo_TEST
  });
  test("txtBuyPtId(Textbox Widget) Test Cases", async () => {
    const txtBuyPtId = screen.getByTestId("txtBuyPtId");
    expect(txtBuyPtId.tagName).toBe("INPUT");
    expect(txtBuyPtId.type).toBe("text");
    expect(txtBuyPtId.classList).toContain("textboxWidgetClass");
    expect(txtBuyPtId.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtBuyPtId")
    );
    await act(async () => {
      userEvent.type(txtBuyPtId, "1");
    });
  });
  test("Custom Test Cases for txtBuyPtId", () => {
    // START_USER_CODE-USER_txtBuyPtId_TEST
    // END_USER_CODE-USER_txtBuyPtId_TEST
  });
  test("txtBuyPt(Textbox Widget) Test Cases", async () => {
    const txtBuyPt = screen.getByTestId("txtBuyPt");
    expect(txtBuyPt.tagName).toBe("INPUT");
    expect(txtBuyPt.type).toBe("text");
    expect(txtBuyPt.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtBuyPt, "1");
    });
  });
  test("Custom Test Cases for txtBuyPt", () => {
    // START_USER_CODE-USER_txtBuyPt_TEST
    // END_USER_CODE-USER_txtBuyPt_TEST
  });
  test("txtCheckNumToVoid(Textbox Widget) Test Cases", async () => {
    const txtCheckNumToVoid = screen.getByTestId("txtCheckNumToVoid");
    expect(txtCheckNumToVoid.tagName).toBe("INPUT");
    expect(txtCheckNumToVoid.type).toBe("text");
    expect(txtCheckNumToVoid.classList).toContain("textboxWidgetClass");
    expect(txtCheckNumToVoid.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_txtCheckNumToVoid"
      )
    );
    await act(async () => {
      userEvent.type(txtCheckNumToVoid, "1");
    });
  });
  test("Custom Test Cases for txtCheckNumToVoid", () => {
    // START_USER_CODE-USER_txtCheckNumToVoid_TEST
    // END_USER_CODE-USER_txtCheckNumToVoid_TEST
  });
  test("txtCollectionPt(Textbox Widget) Test Cases", async () => {
    const txtCollectionPt = screen.getByTestId("txtCollectionPt");
    expect(txtCollectionPt.tagName).toBe("INPUT");
    expect(txtCollectionPt.type).toBe("text");
    expect(txtCollectionPt.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCollectionPt, "1");
    });
  });
  test("Custom Test Cases for txtCollectionPt", () => {
    // START_USER_CODE-USER_txtCollectionPt_TEST
    // END_USER_CODE-USER_txtCollectionPt_TEST
  });
  test("txtContractNumChgDt(Textbox Widget) Test Cases", async () => {
    const txtContractNumChgDt = screen.getByTestId(
      "txtContractNumChgDt"
    );
    expect(txtContractNumChgDt.tagName).toBe("INPUT");
    expect(txtContractNumChgDt.type).toBe("text");
    expect(txtContractNumChgDt.classList).toContain("textboxWidgetClass");
    expect(txtContractNumChgDt.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_txtContractNumChgDt"
      )
    );
    await act(async () => {
      userEvent.type(txtContractNumChgDt, "1");
    });
  });
  test("Custom Test Cases for txtContractNumChgDt", () => {
    // START_USER_CODE-USER_txtContractNumChgDt_TEST
    // END_USER_CODE-USER_txtContractNumChgDt_TEST
  });
  test("txtContractNumToReopen(Textbox Widget) Test Cases", async () => {
    const txtContractNumToReopen = screen.getByTestId(
      "txtContractNumToReopen"
    );
    expect(txtContractNumToReopen.tagName).toBe("INPUT");
    expect(txtContractNumToReopen.type).toBe("text");
    expect(txtContractNumToReopen.classList).toContain(
      "textboxWidgetClass"
    );
    expect(
      txtContractNumToReopen.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_txtContractNumToReopen"
      )
    );
    await act(async () => {
      userEvent.type(txtContractNumToReopen, "1");
    });
  });
  test("Custom Test Cases for txtContractNumToReopen", () => {
    // START_USER_CODE-USER_txtContractNumToReopen_TEST
    // END_USER_CODE-USER_txtContractNumToReopen_TEST
  });
  test("txtContractNumToReset(Textbox Widget) Test Cases", async () => {
    const txtContractNumToReset = screen.getByTestId(
      "txtContractNumToReset"
    );
    expect(txtContractNumToReset.tagName).toBe("INPUT");
    expect(txtContractNumToReset.type).toBe("text");
    expect(txtContractNumToReset.classList).toContain("textboxWidgetClass");
    expect(
      txtContractNumToReset.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_txtContractNumToReset"
      )
    );
    await act(async () => {
      userEvent.type(txtContractNumToReset, "1");
    });
  });
  test("Custom Test Cases for txtContractNumToReset", () => {
    // START_USER_CODE-USER_txtContractNumToReset_TEST
    // END_USER_CODE-USER_txtContractNumToReset_TEST
  });
  test("txtContractNum(Textbox Widget) Test Cases", async () => {
    const txtContractNum = screen.getByTestId("txtContractNum");
    expect(txtContractNum.tagName).toBe("INPUT");
    expect(txtContractNum.type).toBe("text");
    expect(txtContractNum.classList).toContain("textboxWidgetClass");
    expect(txtContractNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtContractNum")
    );
    await act(async () => {
      userEvent.type(txtContractNum, "1");
    });
  });
  test("Custom Test Cases for txtContractNum", () => {
    // START_USER_CODE-USER_txtContractNum_TEST
    // END_USER_CODE-USER_txtContractNum_TEST
  });
  test("txtCropYearKCMO(Textbox Widget) Test Cases", async () => {
    const txtCropYearKCMO = screen.getByTestId("txtCropYearKCMO");
    expect(txtCropYearKCMO.tagName).toBe("INPUT");
    expect(txtCropYearKCMO.type).toBe("text");
    expect(txtCropYearKCMO.classList).toContain("textboxWidgetClass");
    expect(txtCropYearKCMO.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtCropYearKCMO")
    );
    await act(async () => {
      userEvent.type(txtCropYearKCMO, "1");
    });
  });
  test("Custom Test Cases for txtCropYearKCMO", () => {
    // START_USER_CODE-USER_txtCropYearKCMO_TEST
    // END_USER_CODE-USER_txtCropYearKCMO_TEST
  });
  test("txtCropYear(Textbox Widget) Test Cases", async () => {
    const txtCropYear = screen.getByTestId("txtCropYear");
    expect(txtCropYear.tagName).toBe("INPUT");
    expect(txtCropYear.type).toBe("text");
    expect(txtCropYear.classList).toContain("textboxWidgetClass");
    expect(txtCropYear.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtCropYear")
    );
    await act(async () => {
      userEvent.type(txtCropYear, "1");
    });
  });
  test("Custom Test Cases for txtCropYear", () => {
    // START_USER_CODE-USER_txtCropYear_TEST
    // END_USER_CODE-USER_txtCropYear_TEST
  });
  test("txtGr(Textbox Widget) Test Cases", async () => {
    const txtGr = screen.getByTestId("txtGr");
    expect(txtGr.tagName).toBe("INPUT");
    expect(txtGr.type).toBe("text");
    expect(txtGr.classList).toContain("textboxWidgetClass");
    expect(txtGr.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtGr")
    );
    await act(async () => {
      userEvent.type(txtGr, "1");
    });
  });
  test("Custom Test Cases for txtGr", () => {
    // START_USER_CODE-USER_txtGr_TEST
    // END_USER_CODE-USER_txtGr_TEST
  });
  test("txtNewContractDt(Date Widget) Test Cases", async () => {
    const txtNewContractDt = screen.getByTestId("txtNewContractDt");
    expect(txtNewContractDt.tagName).toBe("INPUT");
    expect(txtNewContractDt.type).toBe("text");
    expect(txtNewContractDt.classList).toContain("datetimepicker-input");
    expect(
      txtNewContractDt.parentElement.previousElementSibling.textContent
    ).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtNewContractDt")
    );
    await act(async () => {
      userEvent.click(txtNewContractDt.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtNewContractDt", () => {
    // START_USER_CODE-USER_txtNewContractDt_TEST
    // END_USER_CODE-USER_txtNewContractDt_TEST
  });
  test("txtNewYear(Textbox Widget) Test Cases", async () => {
    const txtNewYear = screen.getByTestId("txtNewYear");
    expect(txtNewYear.tagName).toBe("INPUT");
    expect(txtNewYear.type).toBe("text");
    expect(txtNewYear.classList).toContain("textboxWidgetClass");
    expect(txtNewYear.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtNewYear")
    );
    await act(async () => {
      userEvent.type(txtNewYear, "1");
    });
  });
  test("Custom Test Cases for txtNewYear", () => {
    // START_USER_CODE-USER_txtNewYear_TEST
    // END_USER_CODE-USER_txtNewYear_TEST
  });
  test("txtOldYear(Textbox Widget) Test Cases", async () => {
    const txtOldYear = screen.getByTestId("txtOldYear");
    expect(txtOldYear.tagName).toBe("INPUT");
    expect(txtOldYear.type).toBe("text");
    expect(txtOldYear.classList).toContain("textboxWidgetClass");
    expect(txtOldYear.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtOldYear")
    );
    await act(async () => {
      userEvent.type(txtOldYear, "1");
    });
  });
  test("Custom Test Cases for txtOldYear", () => {
    // START_USER_CODE-USER_txtOldYear_TEST
    // END_USER_CODE-USER_txtOldYear_TEST
  });
  test("txtOptionalMail(Textbox Widget) Test Cases", async () => {
    const txtOptionalMail = screen.getByTestId("txtOptionalMail");
    expect(txtOptionalMail.tagName).toBe("INPUT");
    expect(txtOptionalMail.type).toBe("text");
    expect(txtOptionalMail.classList).toContain("textboxWidgetClass");
    expect(txtOptionalMail.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtOptionalMail")
    );
    await act(async () => {
      userEvent.type(txtOptionalMail, "1");
    });
  });
  test("Custom Test Cases for txtOptionalMail", () => {
    // START_USER_CODE-USER_txtOptionalMail_TEST
    // END_USER_CODE-USER_txtOptionalMail_TEST
  });
  test("txtPct(Textbox Widget) Test Cases", async () => {
    const txtPct = screen.getByTestId("txtPct");
    expect(txtPct.tagName).toBe("INPUT");
    expect(txtPct.type).toBe("text");
    expect(txtPct.classList).toContain("textboxWidgetClass");
    expect(txtPct.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtPct")
    );
    await act(async () => {
      userEvent.type(txtPct, "1");
    });
  });
  test("Custom Test Cases for txtPct", () => {
    // START_USER_CODE-USER_txtPct_TEST
    // END_USER_CODE-USER_txtPct_TEST
  });
  test("txtReqKCMORecon(GroupBox Widget) Test Cases", async () => {
    const txtReqKCMORecon = screen.getByTestId("txtReqKCMORecon");
    expect(txtReqKCMORecon.tagName).toBe("BUTTON");
    expect(txtReqKCMORecon.type).toBe("button");
    expect(txtReqKCMORecon.classList).toContain("btn");
  });
  test("Custom Test Cases for txtReqKCMORecon", () => {
    // START_USER_CODE-USER_txtReqKCMORecon_TEST
    // END_USER_CODE-USER_txtReqKCMORecon_TEST
  });
  test("txtSampleWt(Textbox Widget) Test Cases", async () => {
    const txtSampleWt = screen.getByTestId("txtSampleWt");
    expect(txtSampleWt.tagName).toBe("INPUT");
    expect(txtSampleWt.type).toBe("text");
    expect(txtSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtSampleWt.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtSampleWt")
    );
    await act(async () => {
      userEvent.type(txtSampleWt, "1");
    });
  });
  test("Custom Test Cases for txtSampleWt", () => {
    // START_USER_CODE-USER_txtSampleWt_TEST
    // END_USER_CODE-USER_txtSampleWt_TEST
  });
  test("txtSettlementNum(Textbox Widget) Test Cases", async () => {
    const txtSettlementNum = screen.getByTestId("txtSettlementNum");
    expect(txtSettlementNum.tagName).toBe("INPUT");
    expect(txtSettlementNum.type).toBe("text");
    expect(txtSettlementNum.classList).toContain("textboxWidgetClass");
    expect(txtSettlementNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtSettlementNum")
    );
    await act(async () => {
      userEvent.type(txtSettlementNum, "1");
    });
  });
  test("Custom Test Cases for txtSettlementNum", () => {
    // START_USER_CODE-USER_txtSettlementNum_TEST
    // END_USER_CODE-USER_txtSettlementNum_TEST
  });
  test("txtSettleNumChgGrds(Textbox Widget) Test Cases", async () => {
    const txtSettleNumChgGrds = screen.getByTestId(
      "txtSettleNumChgGrds"
    );
    expect(txtSettleNumChgGrds.tagName).toBe("INPUT");
    expect(txtSettleNumChgGrds.type).toBe("text");
    expect(txtSettleNumChgGrds.classList).toContain("textboxWidgetClass");
    expect(txtSettleNumChgGrds.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_txtSettleNumChgGrds"
      )
    );
    await act(async () => {
      userEvent.type(txtSettleNumChgGrds, "1");
    });
  });
  test("Custom Test Cases for txtSettleNumChgGrds", () => {
    // START_USER_CODE-USER_txtSettleNumChgGrds_TEST
    // END_USER_CODE-USER_txtSettleNumChgGrds_TEST
  });
  test("txtSettleNumChgUnload(Textbox Widget) Test Cases", async () => {
    const txtSettleNumChgUnload = screen.getByTestId(
      "txtSettleNumChgUnload"
    );
    expect(txtSettleNumChgUnload.tagName).toBe("INPUT");
    expect(txtSettleNumChgUnload.type).toBe("text");
    expect(txtSettleNumChgUnload.classList).toContain("textboxWidgetClass");
    expect(
      txtSettleNumChgUnload.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_txtSettleNumChgUnload"
      )
    );
    await act(async () => {
      userEvent.type(txtSettleNumChgUnload, "1");
    });
  });
  test("Custom Test Cases for txtSettleNumChgUnload", () => {
    // START_USER_CODE-USER_txtSettleNumChgUnload_TEST
    // END_USER_CODE-USER_txtSettleNumChgUnload_TEST
  });
  test("txtSettleNumChgVicam(Textbox Widget) Test Cases", async () => {
    const txtSettleNumChgVicam = screen.getByTestId(
      "txtSettleNumChgVicam"
    );
    expect(txtSettleNumChgVicam.tagName).toBe("INPUT");
    expect(txtSettleNumChgVicam.type).toBe("text");
    expect(txtSettleNumChgVicam.classList).toContain("textboxWidgetClass");
    expect(txtSettleNumChgVicam.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_txtSettleNumChgVicam"
      )
    );
    await act(async () => {
      userEvent.type(txtSettleNumChgVicam, "1");
    });
  });
  test("Custom Test Cases for txtSettleNumChgVicam", () => {
    // START_USER_CODE-USER_txtSettleNumChgVicam_TEST
    // END_USER_CODE-USER_txtSettleNumChgVicam_TEST
  });
  test("txtShippingBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtShippingBuyingPoint = screen.getByTestId(
      "txtShippingBuyingPoint"
    );
    expect(txtShippingBuyingPoint.tagName).toBe("INPUT");
    expect(txtShippingBuyingPoint.type).toBe("text");
    expect(txtShippingBuyingPoint.classList).toContain(
      "textboxWidgetClass"
    );
    expect(
      txtShippingBuyingPoint.previousElementSibling.textContent
    ).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_txtShippingBuyingPoint"
      )
    );
    await act(async () => {
      userEvent.type(txtShippingBuyingPoint, "1");
    });
  });
  test("Custom Test Cases for txtShippingBuyingPoint", () => {
    // START_USER_CODE-USER_txtShippingBuyingPoint_TEST
    // END_USER_CODE-USER_txtShippingBuyingPoint_TEST
  });
  test("txtTransferNum(Textbox Widget) Test Cases", async () => {
    const txtTransferNum = screen.getByTestId("txtTransferNum");
    expect(txtTransferNum.tagName).toBe("INPUT");
    expect(txtTransferNum.type).toBe("text");
    expect(txtTransferNum.classList).toContain("textboxWidgetClass");
    expect(txtTransferNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtTransferNum")
    );
    await act(async () => {
      userEvent.type(txtTransferNum, "1");
    });
  });
  test("Custom Test Cases for txtTransferNum", () => {
    // START_USER_CODE-USER_txtTransferNum_TEST
    // END_USER_CODE-USER_txtTransferNum_TEST
  });
  test("txtVendorNumrToReset(Textbox Widget) Test Cases", async () => {
    const txtVendorNumrToReset = screen.getByTestId(
      "txtVendorNumrToReset"
    );
    expect(txtVendorNumrToReset.tagName).toBe("INPUT");
    expect(txtVendorNumrToReset.type).toBe("text");
    expect(txtVendorNumrToReset.classList).toContain("textboxWidgetClass");
    expect(txtVendorNumrToReset.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:SpecFuncMisc_txtVendorNumrToReset"
      )
    );
    await act(async () => {
      userEvent.type(txtVendorNumrToReset, "1");
    });
  });
  test("Custom Test Cases for txtVendorNumrToReset", () => {
    // START_USER_CODE-USER_txtVendorNumrToReset_TEST
    // END_USER_CODE-USER_txtVendorNumrToReset_TEST
  });
  test("txtVicamReading(Textbox Widget) Test Cases", async () => {
    const txtVicamReading = screen.getByTestId("txtVicamReading");
    expect(txtVicamReading.tagName).toBe("INPUT");
    expect(txtVicamReading.type).toBe("text");
    expect(txtVicamReading.classList).toContain("textboxWidgetClass");
    expect(txtVicamReading.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtVicamReading")
    );
    await act(async () => {
      userEvent.type(txtVicamReading, "1");
    });
  });
  test("Custom Test Cases for txtVicamReading", () => {
    // START_USER_CODE-USER_txtVicamReading_TEST
    // END_USER_CODE-USER_txtVicamReading_TEST
  });
  test("txtWhse(Textbox Widget) Test Cases", async () => {
    const txtWhse = screen.getByTestId("txtWhse");
    expect(txtWhse.tagName).toBe("INPUT");
    expect(txtWhse.type).toBe("text");
    expect(txtWhse.classList).toContain("textboxWidgetClass");
    expect(txtWhse.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:SpecFuncMisc_txtWhse")
    );
    await act(async () => {
      userEvent.type(txtWhse, "1");
    });
  });
  test("Custom Test Cases for txtWhse", () => {
    // START_USER_CODE-USER_txtWhse_TEST
    // END_USER_CODE-USER_txtWhse_TEST
  });
});
