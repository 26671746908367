/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_PremiumDeductionScheduleSetup from "./PremiumDeductionScheduleSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PremiumDeductionScheduleSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PremiumDeductionScheduleSetup />
      );
    });
  });
  afterEach(cleanup);
  test("is PremiumDeductionScheduleSetup Loads Successfully", () => {
    expect(screen.getByText("PremiumDeductionScheduleSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for PremiumDeductionScheduleSetup", () => {
    // START_USER_CODE-USER_PremiumDeductionScheduleSetup_Custom_Test_Case
    // END_USER_CODE-USER_PremiumDeductionScheduleSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PremiumDeductionScheduleSetup />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleSetup_btnAdd"
      )
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleSetup_btnClose"
      )
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btncmdEnableDisableControls(Button Widget) Test Cases", async () => {
    const btncmdEnableDisableControls = screen.getByTestId(
      "btncmdEnableDisableControls"
    );
    expect(btncmdEnableDisableControls).toBeInTheDocument;
    expect(btncmdEnableDisableControls.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleSetup_btncmdEnableDisableControls"
      )
    );
  });
  test("Custom Test Cases for btncmdEnableDisableControls", () => {
    // START_USER_CODE-USER_btncmdEnableDisableControls_TEST
    // END_USER_CODE-USER_btncmdEnableDisableControls_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleSetup_btnDelete"
      )
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleSetup_btnEdit"
      )
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("grpbxPremiumDeductionScheduleSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxPremiumDeductionScheduleSetup = screen.getByTestId(
      "grpbxPremiumDeductionScheduleSetup"
    );
    expect(grpbxPremiumDeductionScheduleSetup.tagName).toBe("BUTTON");
    expect(grpbxPremiumDeductionScheduleSetup.type).toBe("button");
    expect(grpbxPremiumDeductionScheduleSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremiumDeductionScheduleSetup", () => {
    // START_USER_CODE-USER_grpbxPremiumDeductionScheduleSetup_TEST
    // END_USER_CODE-USER_grpbxPremiumDeductionScheduleSetup_TEST
  });
  test("lblPDCode(Label Widget) Test Cases", async () => {
    const lblPDCode = screen.getByTestId("lblPDCode");
    expect(lblPDCode.tagName).toBe("LABEL");
    expect(lblPDCode.classList).toContain("form-label");
    expect(lblPDCode.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PremiumDeductionScheduleSetup_lblPDCode"
      )
    );
  });
  test("Custom Test Cases for lblPDCode", () => {
    // START_USER_CODE-USER_lblPDCode_TEST
    // END_USER_CODE-USER_lblPDCode_TEST
  });
});
