import React, { Component } from "react";
import {
  onFileChange,
  getFileRenderTemplate,
} from "../../../../common/ScreenInitialization";
import Form from "react-bootstrap/Form";

class FileControl extends Component {
  state = {
    selectedRadio: this.props.col.name + "_" + this.props.row.rowID$ + 0,
  };

  fileUpload = React.createRef();

  radioOnChange = (e) => {
    this.setState({ selectedRadio: e.target.value });
  };

  render() {
    const col = this.props.col;
    const value = this.props.value;
    const row = this.props.row;
    const isInvalid = this.props.isInvalid;
    const title = this.props.title;
    this.isValueTemplate = this.props.isValueTemplate;
    this.conf = this.props.conf;
    return (
      <div className="py-2">
        {!this.isValueTemplate && (
          <Form.File
            id={col.name}
            ref={this.fileUpload}
            name={`${this.conf.name}.${row.rowID$}.${col.name}`}
            label="Choose file"
            onChange={(e) => onFileChange(e, value, col, this.props.onEvent)}
            isInvalid={isInvalid}
            title={title}
            custom
          />
        )}
        {getFileRenderTemplate(
          value,
          col,
          row.rowID$,
          `${this.conf.name}.${row.rowID$}.${col.name}`,
          this.props.onEvent,
          this.fileUpload,
          this.isValueTemplate,
          this.state,
          this.radioOnChange
        )}
      </div>
    );
  }
}

export default FileControl;
