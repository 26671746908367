/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  setValue,
  setData,
  getValue,
  goTo,
  getData,
} from "../../shared/WindowImports";

import "./DelvAvailAgreement.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// import { getData } from "../../Common/ApiService";
const compIdFromLS = () => (sessionStorage.getItem('compId'));
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_DelvAvailAgreement(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "DelvAvailAgreement",
    windowName: "DelvAvailAgreement",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.DelvAvailAgreement",
    // START_USER_CODE-USER_DelvAvailAgreement_PROPERTIES
    horizontalForm : true,
    headerData: {
      scrName: "Delivery Out Agreement",
      scrCode: "PN1180D",
    },
    // END_USER_CODE-USER_DelvAvailAgreement_PROPERTIES
    btnAttach: {
      name: "btnAttach",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Attach",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAttach_PROPERTIES

      // END_USER_CODE-USER_btnAttach_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnRemove: {
      name: "btnRemove",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Remove",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnRemove_PROPERTIES

      // END_USER_CODE-USER_btnRemove_PROPERTIES
    },
    ddAgreements: {
      name: "ddAgreements",
      type: "DropDownFieldWidget",
      parent: "grpbxDelvAvailAgreement",
      Label: "Agreements",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAgreements_PROPERTIES
      SpecialFirstOption:""
      // END_USER_CODE-USER_ddAgreements_PROPERTIES
    },
    lblAgreeNumBuyPtId: {
      name: "lblAgreeNumBuyPtId",
      type: "LabelWidget",
      parent: "grpbxDelvAvailAgreement",
      Label: "Agree Num - Buy Pt Id",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAgreeNumBuyPtId_PROPERTIES

      // END_USER_CODE-USER_lblAgreeNumBuyPtId_PROPERTIES
    },
    grpbxDelvAvailAgreement: {
      name: "grpbxDelvAvailAgreement",
      type: "GroupBoxWidget",
      parent: "DelvAvailAgreement",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxDelvAvailAgreement_PROPERTIES

      // END_USER_CODE-USER_grpbxDelvAvailAgreement_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "DelvAvailAgreement",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    FormLoad();
  }, []);

  // START_USER_CODE-USER_METHODS

  //formLoad function
  async function FormLoad() {
    try {
      let dataJs = getData(thisObj, "frmDelvAvailAgreementData");
      if (dataJs != null && dataJs != undefined) {
        let response = await WarehouseReceiptService.RetrieveDeliveryOutAgreementWareHouseDetails(dataJs.orgBuyingPtId, dataJs.whseRcptNum);
        let agreement_num = '';
        let dropdownData = setData(thisObj, 'dropdownData');
        if (response?.length > 0) {
          agreement_num = response[0].agree_num;
          bfillagreement(agreement_num);
        }
        else if (response?.length == []) {
          bfillagreement(agreement_num);
        }
        // If bfillagreement dropdown is empty
        else if (dropdownData == false) {
          let js = [];
          js.push({key: '', description: ''})
          thisObj.setState(current => {
            return {
              ...current,
              ddAgreements: {
                ...state["ddAgreements"],
                valueList: js
              }
            }
          });
        }
      }
      setLoading(false)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occured in formLoad"
        );
      }
      return false;
    }
  }
     
  //Function to convert to Pascal Case
  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  //Agreements dropdown binding
  const bfillagreement = async (agreement_num) => {
    try {
      let dataJs = getData(thisObj, "frmDelvAvailAgreementData");
      if (dataJs != null && dataJs != undefined) {
        let js = [];
        if (agreement_num == "") {
          agreement_num = null;
        }
        let data = await WarehouseReceiptService.RetrieveDeliveryOutreceipts(agreement_num, dataJs.peanutType);
        if (data.length > 0) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].buy_pt_id == dataJs.buyingPtId) {
              let paddedNum = data[i].agreement_num.toString().padStart(7, '0');
              let obj = {
                key: paddedNum,
                description: `${paddedNum} - ${ConvertToPascalCase(data[i].buy_pt_id).toString()} - ${ConvertToPascalCase(data[i].buy_pt_name).toString()}`,
                index: i
              }
              js.push(obj);
              obj = {};
            }
          }
          js.sort((a, b) => a.key.localeCompare(b.key));
          thisObj.setState(current => {
            return {
              ...current,
              ddAgreements: {
                ...state["ddAgreements"],
                valueList: js
              }
            }
          });
          if (dataJs.buttonName == 'cancelDelv') {
            setValue(thisObj, 'ddAgreements', js[0].key);
          }
          else{
            for(var i=0; i<js?.length; i++){
              if(js[i].key.includes(agreement_num)){
                setValue(thisObj, 'ddAgreements', js[i].key)
                dataJs.lblAgreePresent = 'Y' 
                setData(thisObj, "frmDelvAvailAgreementData", dataJs);
                return
              }
            }
          }
          setData(thisObj, 'dropdownData', true);
        }
        else {
          setData(thisObj, 'dropdownData', false);
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occured in Agreements dropdown"
        );
      }
      return false;
    }
  }

  // Close button functionality
  const onbtnCloseClick = async(event) => {
    try {
      let obj = {}
      let agreeList = thisObj.state['ddAgreements'].valueList;
      let SelectedAgreeListValue = thisObj.values['ddAgreements'];
      if (SelectedAgreeListValue !== "" && SelectedAgreeListValue !== undefined && SelectedAgreeListValue !== null) {
        obj.agreeName = agreeList.find(elem => elem.key === SelectedAgreeListValue).description;
        if(event?.target?.value == true){
          setData(thisObj, 'frmBeginDelvOutFlag', {
            flag: true,
            methodName: getData(thisObj, 'frmDelvAvailAgreementData')?.buttonName,
            lblAgreePresent: getData(thisObj, 'frmDelvAvailAgreementData')?.buttonName !== "cancelDelv" ? 'Y' : 'N'
          })
        }
        else{
          setData(thisObj, 'frmBeginDelvOutFlag', null)
        }
      }
      document.getElementById("WarehouseReceipts_DelvAvailAgreementPopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // Attach button functionality
  const onbtnAttachClick = async () => {
    try {
      let parentData = getData(thisObj, "frmDelvAvailAgreementData");
      let obj = {};
      let agreeList = thisObj.state['ddAgreements'].valueList;
      let SelectedAgreeListValue = thisObj.values['ddAgreements'];
      if (SelectedAgreeListValue !== "" && SelectedAgreeListValue !== undefined && SelectedAgreeListValue !== null) {
        obj.agreement_num = agreeList.find(elem => elem.key === SelectedAgreeListValue).key;
        obj.agreeName = agreeList.find(elem => elem.key === SelectedAgreeListValue).description;
        obj.indexNum = agreeList.find(elem => elem.key === SelectedAgreeListValue).index;
      }
      if (obj.indexNum == null || obj.indexNum == undefined || obj.indexNum == '') {
        return;
      }
      if (parentData.lblAgreePresent == 'Y') {
        return;
      }
      let temp = {
        "agreement_num" : parseInt(obj.agreement_num, 10).toString(),
        "prev_agree" : "NONE"
      }
      let response = await WarehouseReceiptService.UpdateDeliveryOutwarehouseAgreements(parentData.orgBuyingPtId, parentData.whseRcptNum, temp);
      if (response.status == 200) {
        alert("Agreement tied Successfully");
        if (obj.agreeName !== "") {
          setData(thisObj, "lblAgreePresent", "Y");
        }
        await onbtnCloseClick({ target : { value : true }})
      }
      else {
        alert("An error occured while Adding the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist.");
        return;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnAttach event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnAttachClick = onbtnAttachClick;

  // Remove button functionality
  const onbtnRemoveClick = async () => {
    try {
      let parentData = getData(thisObj, "frmDelvAvailAgreementData");
      let obj = {};
      let agreeList = thisObj.state['ddAgreements'].valueList;
      let SelectedAgreeListValue = thisObj.values['ddAgreements'];
      if (SelectedAgreeListValue !== "" && SelectedAgreeListValue !== undefined && SelectedAgreeListValue !== null) {
        obj.agreement_num = agreeList.find(elem => elem.key === SelectedAgreeListValue).key;
        obj.indexNum = agreeList.find(elem => elem.key === SelectedAgreeListValue).index;
      }
      if (obj.indexNum == null  || obj.indexNum == undefined || obj.indexNum == '') {
        return;
      }
      if (parentData.lblAgreePresent !== 'Y') {
        return;
      }
      let temp = {
        "agreement_num" : "Null",
        "prev_agree" : parseInt(obj.agreement_num, 10).toString()
      }
      let response = await WarehouseReceiptService.UpdateDeliveryOutwarehouseAgreements(parentData.orgBuyingPtId, parentData.whseRcptNum, temp);
      if (response.status == 200) {
        alert("Agreement Removed Successfully");
        setData(thisObj, "lblAgreePresent", "N");
        await onbtnCloseClick({ target : { value : true }})
      }
      else {
        alert("An error occured while Adding the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist.");
        return;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnRemove event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnRemoveClick = onbtnRemoveClick;

  // END_USER_CODE-USER_METHODS

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_DelvAvailAgreement*/}

              {/* END_USER_CODE-USER_BEFORE_DelvAvailAgreement*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDelvAvailAgreement*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDelvAvailAgreement*/}

              <GroupBoxWidget
                conf={state.grpbxDelvAvailAgreement}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddAgreements*/}

                {/* END_USER_CODE-USER_BEFORE_ddAgreements*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAgreements}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAgreements*/}

                {/* END_USER_CODE-USER_AFTER_ddAgreements*/}
                {/* START_USER_CODE-USER_BEFORE_lblAgreeNumBuyPtId*/}

                {/* END_USER_CODE-USER_BEFORE_lblAgreeNumBuyPtId*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAgreeNumBuyPtId}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAgreeNumBuyPtId*/}

                {/* END_USER_CODE-USER_AFTER_lblAgreeNumBuyPtId*/}
                
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDelvAvailAgreement*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDelvAvailAgreement*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnRemove*/}

                {/* END_USER_CODE-USER_BEFORE_btnRemove*/}

                <ButtonWidget
                  conf={state.btnRemove}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnRemove*/}

                {/* END_USER_CODE-USER_AFTER_btnRemove*/}
                {/* START_USER_CODE-USER_BEFORE_btnAttach*/}

                {/* END_USER_CODE-USER_BEFORE_btnAttach*/}

                <ButtonWidget
                  conf={state.btnAttach}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAttach*/}

                {/* END_USER_CODE-USER_AFTER_btnAttach*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_DelvAvailAgreement*/}

              {/* END_USER_CODE-USER_AFTER_DelvAvailAgreement*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_DelvAvailAgreement);
