/* eslint-disable*/
import React from "react";
import ContractManagement_AccountDistributionActivity from "./AccountDistributionActivity";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("AccountDistributionActivity Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_AccountDistributionActivity />);
    });
  });
  afterEach(cleanup);
  test("is AccountDistributionActivity Loads Successfully", () => {
    expect(screen.getByText("AccountDistributionActivity")).toBeInTheDocument;
  });
  test("Custom Test Cases for AccountDistributionActivity", () => {
    // START_USER_CODE-USER_AccountDistributionActivity_Custom_Test_Case
    // END_USER_CODE-USER_AccountDistributionActivity_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_AccountDistributionActivity />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("ContractManagement:AccountDistributionActivity_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnPrintScreen(Button Widget) Test Cases", async () => {
    const btnPrintScreen = screen.getByTestId("btnPrintScreen");
    expect(btnPrintScreen).toBeInTheDocument;
    expect(btnPrintScreen.textContent).toEqual(
      t("ContractManagement:AccountDistributionActivity_btnPrintScreen")
    );
  });
  test("Custom Test Cases for btnPrintScreen", () => {
    // START_USER_CODE-USER_btnPrintScreen_TEST
    // END_USER_CODE-USER_btnPrintScreen_TEST
  });
  test("gridAccountDistribution(Grid Widget) Test Cases", async () => {
    let gridAccountDistribution = screen.getByTestId("gridAccountDistribution");
    let gridAccountDistributionbtn =
      gridAccountDistribution.nextElementSibling.firstElementChild;
    gridAccountDistribution =
      gridAccountDistribution.parentElement.parentElement.parentElement;
    expect(gridAccountDistribution.tagName).toBe("DIV");
    expect(gridAccountDistribution.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "ContractManagement:AccountDistributionActivity_gridAccountDistribution"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAccountDistribution", () => {
    // START_USER_CODE-USER_gridAccountDistribution_TEST
    // END_USER_CODE-USER_gridAccountDistribution_TEST
  });
  test("grpbxAccntDstrbtn(GroupBox Widget) Test Cases", async () => {
    const grpbxAccntDstrbtn = screen.getByTestId("grpbxAccntDstrbtn");
    expect(grpbxAccntDstrbtn.tagName).toBe("BUTTON");
    expect(grpbxAccntDstrbtn.type).toBe("button");
    expect(grpbxAccntDstrbtn.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAccntDstrbtn", () => {
    // START_USER_CODE-USER_grpbxAccntDstrbtn_TEST
    // END_USER_CODE-USER_grpbxAccntDstrbtn_TEST
  });
  test("grpbxClosePrint(GroupBox Widget) Test Cases", async () => {
    const grpbxClosePrint = screen.getByTestId("grpbxClosePrint");
    expect(grpbxClosePrint.tagName).toBe("BUTTON");
    expect(grpbxClosePrint.type).toBe("button");
    expect(grpbxClosePrint.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxClosePrint", () => {
    // START_USER_CODE-USER_grpbxClosePrint_TEST
    // END_USER_CODE-USER_grpbxClosePrint_TEST
  });
  test("lblAccountDistribution(Label Widget) Test Cases", async () => {
    const lblAccountDistribution = screen.getByTestId("lblAccountDistribution");
    expect(lblAccountDistribution.tagName).toBe("LABEL");
    expect(lblAccountDistribution.classList).toContain("form-label");
    expect(lblAccountDistribution.textContent).toEqual(
      t("ContractManagement:AccountDistributionActivity_lblAccountDistribution")
    );
  });
  test("Custom Test Cases for lblAccountDistribution", () => {
    // START_USER_CODE-USER_lblAccountDistribution_TEST
    // END_USER_CODE-USER_lblAccountDistribution_TEST
  });
  test("gridAccountDistribution_txtAcct(Grid Widget) Test Cases", async () => {
    let gridAccountDistribution_txtAcct = screen.getByTestId(
      "gridAccountDistribution"
    );
    let gridAccountDistribution_txtAcctbtn =
      gridAccountDistribution_txtAcct.nextElementSibling.firstElementChild;
    gridAccountDistribution_txtAcct =
      gridAccountDistribution_txtAcct.parentElement.parentElement.parentElement;
    expect(gridAccountDistribution_txtAcct.tagName).toBe("DIV");
    expect(gridAccountDistribution_txtAcct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "ContractManagement:AccountDistributionActivity_gridAccountDistribution"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtAcct", () => {
    // START_USER_CODE-USER_txtAcct_TEST
    // END_USER_CODE-USER_txtAcct_TEST
  });
  test("gridAccountDistribution_txtAmount(Grid Widget) Test Cases", async () => {
    let gridAccountDistribution_txtAmount = screen.getByTestId(
      "gridAccountDistribution"
    );
    let gridAccountDistribution_txtAmountbtn =
      gridAccountDistribution_txtAmount.nextElementSibling.firstElementChild;
    gridAccountDistribution_txtAmount =
      gridAccountDistribution_txtAmount.parentElement.parentElement
        .parentElement;
    expect(gridAccountDistribution_txtAmount.tagName).toBe("DIV");
    expect(gridAccountDistribution_txtAmount.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "ContractManagement:AccountDistributionActivity_gridAccountDistribution"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtAmount", () => {
    // START_USER_CODE-USER_txtAmount_TEST
    // END_USER_CODE-USER_txtAmount_TEST
  });
  test("gridAccountDistribution_txtCntrctNo(Grid Widget) Test Cases", async () => {
    let gridAccountDistribution_txtCntrctNo = screen.getByTestId(
      "gridAccountDistribution"
    );
    let gridAccountDistribution_txtCntrctNobtn =
      gridAccountDistribution_txtCntrctNo.nextElementSibling.firstElementChild;
    gridAccountDistribution_txtCntrctNo =
      gridAccountDistribution_txtCntrctNo.parentElement.parentElement
        .parentElement;
    expect(gridAccountDistribution_txtCntrctNo.tagName).toBe("DIV");
    expect(gridAccountDistribution_txtCntrctNo.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "ContractManagement:AccountDistributionActivity_gridAccountDistribution"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtCntrctNo", () => {
    // START_USER_CODE-USER_txtCntrctNo_TEST
    // END_USER_CODE-USER_txtCntrctNo_TEST
  });
  test("gridAccountDistribution_txtDate(Grid Widget) Test Cases", async () => {
    let gridAccountDistribution_txtDate = screen.getByTestId(
      "gridAccountDistribution"
    );
    let gridAccountDistribution_txtDatebtn =
      gridAccountDistribution_txtDate.nextElementSibling.firstElementChild;
    gridAccountDistribution_txtDate =
      gridAccountDistribution_txtDate.parentElement.parentElement.parentElement;
    expect(gridAccountDistribution_txtDate.tagName).toBe("DIV");
    expect(gridAccountDistribution_txtDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "ContractManagement:AccountDistributionActivity_gridAccountDistribution"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtDate", () => {
    // START_USER_CODE-USER_txtDate_TEST
    // END_USER_CODE-USER_txtDate_TEST
  });
  test("gridAccountDistribution_txtDept(Grid Widget) Test Cases", async () => {
    let gridAccountDistribution_txtDept = screen.getByTestId(
      "gridAccountDistribution"
    );
    let gridAccountDistribution_txtDeptbtn =
      gridAccountDistribution_txtDept.nextElementSibling.firstElementChild;
    gridAccountDistribution_txtDept =
      gridAccountDistribution_txtDept.parentElement.parentElement.parentElement;
    expect(gridAccountDistribution_txtDept.tagName).toBe("DIV");
    expect(gridAccountDistribution_txtDept.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "ContractManagement:AccountDistributionActivity_gridAccountDistribution"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtDept", () => {
    // START_USER_CODE-USER_txtDept_TEST
    // END_USER_CODE-USER_txtDept_TEST
  });
  test("gridAccountDistribution_txtIBMDate(Grid Widget) Test Cases", async () => {
    let gridAccountDistribution_txtIBMDate = screen.getByTestId(
      "gridAccountDistribution"
    );
    let gridAccountDistribution_txtIBMDatebtn =
      gridAccountDistribution_txtIBMDate.nextElementSibling.firstElementChild;
    gridAccountDistribution_txtIBMDate =
      gridAccountDistribution_txtIBMDate.parentElement.parentElement
        .parentElement;
    expect(gridAccountDistribution_txtIBMDate.tagName).toBe("DIV");
    expect(gridAccountDistribution_txtIBMDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "ContractManagement:AccountDistributionActivity_gridAccountDistribution"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtIBMDate", () => {
    // START_USER_CODE-USER_txtIBMDate_TEST
    // END_USER_CODE-USER_txtIBMDate_TEST
  });
  test("gridAccountDistribution_txtLoc(Grid Widget) Test Cases", async () => {
    let gridAccountDistribution_txtLoc = screen.getByTestId(
      "gridAccountDistribution"
    );
    let gridAccountDistribution_txtLocbtn =
      gridAccountDistribution_txtLoc.nextElementSibling.firstElementChild;
    gridAccountDistribution_txtLoc =
      gridAccountDistribution_txtLoc.parentElement.parentElement.parentElement;
    expect(gridAccountDistribution_txtLoc.tagName).toBe("DIV");
    expect(gridAccountDistribution_txtLoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "ContractManagement:AccountDistributionActivity_gridAccountDistribution"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtLoc", () => {
    // START_USER_CODE-USER_txtLoc_TEST
    // END_USER_CODE-USER_txtLoc_TEST
  });
  test("gridAccountDistribution_txtManualInd(Grid Widget) Test Cases", async () => {
    let gridAccountDistribution_txtManualInd = screen.getByTestId(
      "gridAccountDistribution"
    );
    let gridAccountDistribution_txtManualIndbtn =
      gridAccountDistribution_txtManualInd.nextElementSibling.firstElementChild;
    gridAccountDistribution_txtManualInd =
      gridAccountDistribution_txtManualInd.parentElement.parentElement
        .parentElement;
    expect(gridAccountDistribution_txtManualInd.tagName).toBe("DIV");
    expect(gridAccountDistribution_txtManualInd.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "ContractManagement:AccountDistributionActivity_gridAccountDistribution"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtManualInd", () => {
    // START_USER_CODE-USER_txtManualInd_TEST
    // END_USER_CODE-USER_txtManualInd_TEST
  });
  test("gridAccountDistribution_txtQuantity(Grid Widget) Test Cases", async () => {
    let gridAccountDistribution_txtQuantity = screen.getByTestId(
      "gridAccountDistribution"
    );
    let gridAccountDistribution_txtQuantitybtn =
      gridAccountDistribution_txtQuantity.nextElementSibling.firstElementChild;
    gridAccountDistribution_txtQuantity =
      gridAccountDistribution_txtQuantity.parentElement.parentElement
        .parentElement;
    expect(gridAccountDistribution_txtQuantity.tagName).toBe("DIV");
    expect(gridAccountDistribution_txtQuantity.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "ContractManagement:AccountDistributionActivity_gridAccountDistribution"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtQuantity", () => {
    // START_USER_CODE-USER_txtQuantity_TEST
    // END_USER_CODE-USER_txtQuantity_TEST
  });
  test("gridAccountDistribution_txtResp(Grid Widget) Test Cases", async () => {
    let gridAccountDistribution_txtResp = screen.getByTestId(
      "gridAccountDistribution"
    );
    let gridAccountDistribution_txtRespbtn =
      gridAccountDistribution_txtResp.nextElementSibling.firstElementChild;
    gridAccountDistribution_txtResp =
      gridAccountDistribution_txtResp.parentElement.parentElement.parentElement;
    expect(gridAccountDistribution_txtResp.tagName).toBe("DIV");
    expect(gridAccountDistribution_txtResp.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "ContractManagement:AccountDistributionActivity_gridAccountDistribution"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtResp", () => {
    // START_USER_CODE-USER_txtResp_TEST
    // END_USER_CODE-USER_txtResp_TEST
  });
  test("gridAccountDistribution_txtRevInd(Grid Widget) Test Cases", async () => {
    let gridAccountDistribution_txtRevInd = screen.getByTestId(
      "gridAccountDistribution"
    );
    let gridAccountDistribution_txtRevIndbtn =
      gridAccountDistribution_txtRevInd.nextElementSibling.firstElementChild;
    gridAccountDistribution_txtRevInd =
      gridAccountDistribution_txtRevInd.parentElement.parentElement
        .parentElement;
    expect(gridAccountDistribution_txtRevInd.tagName).toBe("DIV");
    expect(gridAccountDistribution_txtRevInd.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "ContractManagement:AccountDistributionActivity_gridAccountDistribution"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtRevInd", () => {
    // START_USER_CODE-USER_txtRevInd_TEST
    // END_USER_CODE-USER_txtRevInd_TEST
  });
  test("gridAccountDistribution_txtSubDept(Grid Widget) Test Cases", async () => {
    let gridAccountDistribution_txtSubDept = screen.getByTestId(
      "gridAccountDistribution"
    );
    let gridAccountDistribution_txtSubDeptbtn =
      gridAccountDistribution_txtSubDept.nextElementSibling.firstElementChild;
    gridAccountDistribution_txtSubDept =
      gridAccountDistribution_txtSubDept.parentElement.parentElement
        .parentElement;
    expect(gridAccountDistribution_txtSubDept.tagName).toBe("DIV");
    expect(gridAccountDistribution_txtSubDept.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "ContractManagement:AccountDistributionActivity_gridAccountDistribution"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtSubDept", () => {
    // START_USER_CODE-USER_txtSubDept_TEST
    // END_USER_CODE-USER_txtSubDept_TEST
  });
});
