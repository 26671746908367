/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  DateWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  enable,
  disable,
  getData,
  goTo,
  split,
  hide,
  show

} from "../../shared/WindowImports";

import "./ScaleTickets.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import moment from 'moment';
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SettlementService } from "../Service/SettlementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function Settlements_ScaleTickets(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));

  let parentData = {}

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ScaleTickets",
    windowName: "ScaleTickets",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.ScaleTickets",
    // START_USER_CODE-USER_ScaleTickets_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Weight Ticket",
      scrCode: "PN1030B",
    },
    // END_USER_CODE-USER_ScaleTickets_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnAdd1: {
      name: "btnAdd1",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd1_PROPERTIES

      // END_USER_CODE-USER_btnAdd1_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Update",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd1_PROPERTIES

      // END_USER_CODE-USER_btnAdd1_PROPERTIES
    },
    btnSelectUpload: {
      name: "btnSelectUpload",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Select Upload",
      CharWidth: "28",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSelectUpload_PROPERTIES

      // END_USER_CODE-USER_btnSelectUpload_PROPERTIES
    },
    ddWeigher: {
      name: "ddWeigher",
      type: "DropDownFieldWidget",
      parent: "grpbxWeigher",
      Label: "Certified Weigher Name:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWeigher_PROPERTIES

      // END_USER_CODE-USER_ddWeigher_PROPERTIES
    },
    grpbxWeigher: {
      name: "grpbxWeigher",
      type: "GroupBoxWidget",
      parent: "grpbxScaleTickets",
      ColSpan: "4",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxWeigher_PROPERTIES

      // END_USER_CODE-USER_grpbxWeigher_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblBuyingPoint: {
      name: "lblBuyingPoint",
      type: "LabelWidget",
      parent: "grpbxScaleTickets",
      Label: "Buying Point",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_lblBuyingPoint_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblCounty: {
      name: "lblCounty",
      type: "LabelWidget",
      parent: "grpbxWeigher",
      Label: "County",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCounty_PROPERTIES

      // END_USER_CODE-USER_lblCounty_PROPERTIES
    },
    lblDateFormatDT: {
      name: "lblDateFormatDT",
      type: "LabelWidget",
      parent: "grpbxScaleTickets",
      Label: "mm/dd/yyyy",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDateFormatDT_PROPERTIES

      // END_USER_CODE-USER_lblDateFormatDT_PROPERTIES
    },
    lblDateFormatGT: {
      name: "lblDateFormatGT",
      type: "LabelWidget",
      parent: "grpbxScaleTickets",
      Label: "mm/dd/yyyy",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDateFormatGT_PROPERTIES

      // END_USER_CODE-USER_lblDateFormatGT_PROPERTIES
    },
    lblDryTicket: {
      name: "lblDryTicket",
      type: "LabelWidget",
      parent: "grpbxScaleTickets",
      Label: "Dry Ticket",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDryTicket_PROPERTIES

      // END_USER_CODE-USER_lblDryTicket_PROPERTIES
    },
    lblFarm: {
      name: "lblFarm",
      type: "LabelWidget",
      parent: "grpbxWeigher",
      Label: "Farm",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFarm_PROPERTIES

      // END_USER_CODE-USER_lblFarm_PROPERTIES
    },
    lblFarmSuffix: {
      name: "lblFarmSuffix",
      type: "LabelWidget",
      parent: "grpbxWeigher",
      Label: "FarmSuffix",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFarmSuffix_PROPERTIES

      // END_USER_CODE-USER_lblFarmSuffix_PROPERTIES
    },
    lblFV95: {
      name: "lblFV95",
      type: "LabelWidget",
      parent: "grpbxWeigher",
      Label: "lblFV95",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFV95_PROPERTIES

      // END_USER_CODE-USER_lblFV95_PROPERTIES
    },
    lblGreenTicket: {
      name: "lblGreenTicket",
      type: "LabelWidget",
      parent: "grpbxScaleTickets",
      Label: "Green Ticket",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGreenTicket_PROPERTIES

      // END_USER_CODE-USER_lblGreenTicket_PROPERTIES
    },
    lblHMCIND: {
      name: "lblHMCIND",
      type: "LabelWidget",
      parent: "grpbxScaleTickets",
      Label: "lblHMC_IND",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblHMCIND_PROPERTIES

      // END_USER_CODE-USER_lblHMCIND_PROPERTIES
    },
    lblState: {
      name: "lblState",
      type: "LabelWidget",
      parent: "grpbxWeigher",
      Label: "State",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblState_PROPERTIES

      // END_USER_CODE-USER_lblState_PROPERTIES
    },
    lblTicketUpload: {
      name: "lblTicketUpload",
      type: "LabelWidget",
      parent: "grpbxWeigher",
      Label: "lblTicketUpload",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTicketUpload_PROPERTIES

      // END_USER_CODE-USER_lblTicketUpload_PROPERTIES
    },
    lblUploadSelect: {
      name: "lblUploadSelect",
      type: "LabelWidget",
      parent: "grpbxWeigher",
      Label: "lblUploadSelect",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblUploadSelect_PROPERTIES

      // END_USER_CODE-USER_lblUploadSelect_PROPERTIES
    },
    lblWeigher: {
      name: "lblWeigher",
      type: "LabelWidget",
      parent: "grpbxWeigher",
      Label: "Weigher",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWeigher_PROPERTIES

      // END_USER_CODE-USER_lblWeigher_PROPERTIES
    },
    txtCarrierDT: {
      name: "txtCarrierDT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Carrier:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrierDT_PROPERTIES

      // END_USER_CODE-USER_txtCarrierDT_PROPERTIES
    },
    txtCarrierGT: {
      name: "txtCarrierGT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Carrier:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrierGT_PROPERTIES

      // END_USER_CODE-USER_txtCarrierGT_PROPERTIES
    },
    txtDateDT: {
      name: "txtDateDT",
      type: "DateWidget",
      parent: "grpbxCheckDetails1",
      Label: "-",
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtDateDT_PROPERTIES

      // END_USER_CODE-USER_txtDateDT_PROPERTIES
    },
    txtDateGT: {
      name: "txtDateGT",
      type: "DateWidget",
      parent: "grpbxCheckDetails1",
      Label: "-",
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtDateGT_PROPERTIES

      // END_USER_CODE-USER_txtDateGT_PROPERTIES
    },
    txtDiapatchDT: {
      name: "txtDiapatchDT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Dispatch #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDiapatchDT_PROPERTIES

      // END_USER_CODE-USER_txtDiapatchDT_PROPERTIES
    },
    txtDispatchGT: {
      name: "txtDispatchGT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Dispatch #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDispatchGT_PROPERTIES

      // END_USER_CODE-USER_txtDispatchGT_PROPERTIES
    },
    txtVehicleNumDT: {
      name: "txtVehicleNumDT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Vehicle No.:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVehicleNumDT_PROPERTIES

      // END_USER_CODE-USER_txtVehicleNumDT_PROPERTIES
    },
    txtVehivleNumGT: {
      name: "txtVehivleNumGT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Vehicle No.:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVehivleNumGT_PROPERTIES

      // END_USER_CODE-USER_txtVehivleNumGT_PROPERTIES
    },
    txtWeightTicketDT: {
      name: "txtWeightTicketDT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Weight Ticket #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWeightTicketDT_PROPERTIES

      // END_USER_CODE-USER_txtWeightTicketDT_PROPERTIES
    },
    txtWeightTicketGT: {
      name: "txtWeightTicketGT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Weight Ticket #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWeightTicketGT_PROPERTIES

      // END_USER_CODE-USER_txtWeightTicketGT_PROPERTIES
    },
    txtWtIncludingVehicleDT: {
      name: "txtWtIncludingVehicleDT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Wt.Including Vehicle:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtIncludingVehicleDT_PROPERTIES

      // END_USER_CODE-USER_txtWtIncludingVehicleDT_PROPERTIES
    },
    txtWtIncludingVehicleGT: {
      name: "txtWtIncludingVehicleGT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Wt.Including Vehicle:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtIncludingVehicleGT_PROPERTIES

      // END_USER_CODE-USER_txtWtIncludingVehicleGT_PROPERTIES
    },
    txtWtOfPeanutsDT: {
      name: "txtWtOfPeanutsDT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Wt.Of Peanuts:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtOfPeanutsDT_PROPERTIES

      // END_USER_CODE-USER_txtWtOfPeanutsDT_PROPERTIES
    },
    txtWtOfPeanutsGT: {
      name: "txtWtOfPeanutsGT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Wt.Of Peanuts:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtOfPeanutsGT_PROPERTIES

      // END_USER_CODE-USER_txtWtOfPeanutsGT_PROPERTIES
    },
    txtWtOfVehicleDT: {
      name: "txtWtOfVehicleDT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Wt.Of Vehicle:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtOfVehicleDT_PROPERTIES

      // END_USER_CODE-USER_txtWtOfVehicleDT_PROPERTIES
    },
    txtWtOfVehicleGT: {
      name: "txtWtOfVehicleGT",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Wt.Of Vehicle:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtOfVehicleGT_PROPERTIES

      // END_USER_CODE-USER_txtWtOfVehicleGT_PROPERTIES
    },
    grpbxScaleTickets: {
      name: "grpbxScaleTickets",
      type: "GroupBoxWidget",
      parent: "ScaleTickets",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxScaleTickets_PROPERTIES

      // END_USER_CODE-USER_grpbxScaleTickets_PROPERTIES
    },
    grpbxDryTicket: {
      name: "grpbxDryTicket",
      type: "GroupBoxWidget",
      parent: "grpbxScaleTickets",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxScaleTickets_PROPERTIES

      // END_USER_CODE-USER_grpbxScaleTickets_PROPERTIES
    },
    grpbxGreenTicket: {
      name: "grpbxGreenTicket",
      type: "GroupBoxWidget",
      parent: "grpbxScaleTickets",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxScaleTickets_PROPERTIES

      // END_USER_CODE-USER_grpbxScaleTickets_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ScaleTickets",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "Settlements#FV95": {},
      },
      REVERSE: {
        "Settlements#FV95": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnCancel: {
    //   DEFAULT: ["Settlements#FV95#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let DataObj = getData(thisObj, 'frmScaleTickets')
    disable(thisObj, 'txtWtOfPeanutsDT')
    disable(thisObj, 'txtWtOfPeanutsGT')
    if (DataObj.highmoisture) {
      HighMoisture()
    }
    // let EditData = getData(thisObj, 'EditData')
    // setData(thisObj, 'UpdateData', EditData)
    hide(thisObj, 'btnUpdate')
    hide(thisObj, 'btnAdd1')
    setData(thisObj, 'parentData', DataObj)
    if (DataObj.cmdOk_Caption == "Add") {
      show(thisObj, 'btnAdd1')
    }
    if (DataObj.cmdOk_Caption == "Update") {
      show(thisObj, 'btnUpdate')
    }
    if(DataObj.cmdOk_Caption == "Inquiry"){
      disable(thisObj, 'txtWeightTicketDT')
      disable(thisObj, 'txtDateDT')
      disable(thisObj, 'txtCarrierDT')
      disable(thisObj, 'txtDiapatchDT')
      disable(thisObj, 'txtVehicleNumDT')
      disable(thisObj, 'txtWtIncludingVehicleDT')
      disable(thisObj, 'txtWtOfVehicleDT')
      disable(thisObj, 'txtWtOfPeanutsDT')
      disable(thisObj, 'txtWeightTicketGT')
      disable(thisObj, 'txtDateGT')
      disable(thisObj, 'txtCarrierGT')
      disable(thisObj, 'txtDispatchGT')
      disable(thisObj, 'txtVehivleNumGT')
      disable(thisObj, 'txtWtIncludingVehicleGT')
      disable(thisObj, 'txtWtOfVehicleGT')
      disable(thisObj, 'txtWtOfPeanutsGT')
      disable(thisObj, 'ddWeigher')
    }
    FillWeigherList()
    Form_Activate()
    if (FillWeigherList()) {
      Form_Load()
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    let updateDataObj = getData(thisObj, 'frmScaleTickets')
    setData(thisObj, 'UpdateData', updateDataObj)
    if (updateDataObj != null && updateDataObj != undefined && updateDataObj.length != '') {
      FormLoadData()
    }
  }, [getData(thisObj, 'frmScaleTickets')]);

  // START_USER_CODE-USER_METHODS
  const HighMoisture = () => {
    // hide(thisObj, 'txtWeightTicketDT')
    // hide(thisObj, 'txtDateDT')
    // hide(thisObj, 'lblDateFormatDT')
    // hide(thisObj, 'txtCarrierDT')
    // hide(thisObj, 'txtDiapatchDT')
    // hide(thisObj, 'txtVehicleNumDT')
    // hide(thisObj, 'txtWtIncludingVehicleDT')
    // hide(thisObj, 'txtWtOfVehicleDT')
    // hide(thisObj, 'txtWtOfPeanutsDT')

    document.getElementsByClassName("lblDryTicket")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtWeightTicketDT")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtDateDT")[0].style.visibility = "hidden";
    document.getElementsByClassName("lblDateFormatDT")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtCarrierDT")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtDiapatchDT")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtVehicleNumDT")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtWtIncludingVehicleDT")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtWtOfVehicleDT")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtWtOfPeanutsDT")[0].style.visibility = "hidden";
  }

  const FormLoadData = () => {
    let Updatedat = getData(thisObj, 'UpdateData')
    if (Updatedat.arrTicketInfo != null && Updatedat.arrTicketInfo != undefined) {
      let UpdateData = Updatedat.arrTicketInfo[0].split('|')

      if (UpdateData[0] != undefined && UpdateData[0] != null && UpdateData[0] != 'undefined') {
        setValue(thisObj, 'txtWeightTicketDT', UpdateData[0])
      }
      if (UpdateData[1] != undefined && UpdateData[1] != null && UpdateData[1] != 'undefined') {
        setValue(thisObj, "txtDateDT", UpdateData[1])
      }
      if (UpdateData[2] != undefined && UpdateData[2] != null && UpdateData[2] != 'undefined') {
        setValue(thisObj, 'txtCarrierDT', UpdateData[2])
      }
      if (UpdateData[3] != undefined && UpdateData[3] != null && UpdateData[3] != 'undefined') {
        setValue(thisObj, 'txtDiapatchDT', UpdateData[3])
      }
      if (UpdateData[4] != undefined && UpdateData[4] != null && UpdateData[4] != 'undefined') {
        setValue(thisObj, 'txtVehicleNumDT', UpdateData[4])
      }
      if (UpdateData[5] != undefined && UpdateData[5] != null && UpdateData[5] != 'undefined') {
        setValue(thisObj, 'txtWtIncludingVehicleDT', UpdateData[5])
      }
      if (UpdateData[6] != undefined && UpdateData[6] != null && UpdateData[6] != 'undefined') {
        setValue(thisObj, 'txtWtOfVehicleDT', UpdateData[6])
      }
      if (UpdateData[7] != undefined && UpdateData[7] != null && UpdateData[7] != 'undefined') {
        setValue(thisObj, 'txtWtOfPeanutsDT', UpdateData[7])
      }
      if (UpdateData[8] != undefined && UpdateData[8] != null && UpdateData[8] != 'undefined') {
        setValue(thisObj, 'txtWeightTicketGT', UpdateData[8])
      }
      if (UpdateData[9] != undefined && UpdateData[9] != null && UpdateData[9] != 'undefined') {
        setValue(thisObj, "txtDateGT", UpdateData[9])
      }
      if (UpdateData[10] != undefined && UpdateData[10] != null && UpdateData[10] != 'undefined') {
        setValue(thisObj, 'txtCarrierGT', UpdateData[10])
      }
      if (UpdateData[11] != undefined && UpdateData[11] != null && UpdateData[11] != 'undefined') {
        setValue(thisObj, 'txtDispatchGT', UpdateData[11])
      }
      if (UpdateData[12] != undefined && UpdateData[12] != null && UpdateData[12] != 'undefined') {
        setValue(thisObj, 'txtVehivleNumGT', UpdateData[12])
      }
      if (UpdateData[13] != undefined && UpdateData[13] != null && UpdateData[13] != 'undefined') {
        setValue(thisObj, 'txtWtIncludingVehicleGT', UpdateData[13])
      }
      if (UpdateData[14] != undefined && UpdateData[14] != null && UpdateData[14] != 'undefined') {
        setValue(thisObj, 'txtWtOfVehicleGT', UpdateData[14])
      }
      if (UpdateData[15] != undefined && UpdateData[15] != null && UpdateData[15] != 'undefined') {
        setValue(thisObj, 'txtWtOfPeanutsGT', UpdateData[15])
      }
      if (UpdateData[16] != undefined && UpdateData[16] != null && UpdateData[16] != 'undefined') {
        setValue(thisObj, 'ddWeigher', UpdateData[16])
      }
    }
  }

  const DateValidation = (data) => {
    if (data != undefined) {
      let dateString = data.split('/');
      let Month = dateString[0]
      let date = dateString[1]
      let Year = dateString[2]
      if (Year == cropYearFromLS()) {
        if (Month >= 7) {
          return true;
        } else if (Month < 7) {
          return false;
        }
      } else if (Year == cropYearFromLS() + 1) {
        if (Month <= 7) {
          return true;
        } else if (Month > 7) {
          return false;
        }
      } else {
        return false;
      }
    }
  }


  const onbtnAdd1Click = () => {
    parentData = getData(thisObj, 'parentData')
    let dryScaleTicket = getValue(thisObj, 'txtWeightTicketDT')
    let dryDate = ''
    let DryDt = getValue(thisObj, 'txtDateDT')
    if (DryDt != undefined) {
      if (DryDt.length >= 8) {
        DryDt = DryDt.split(' ')[0]
        dryDate = DryDt;
      } else {
        dryDate = convert(getValue(thisObj, "txtDateDT")['_d'])
        if (dryDate == "aN/aN/NaN") {
          dryDate = ""
        }
      }
    }
    let dryWtOfPeanuts = getValue(thisObj, 'txtWtOfPeanutsDT')
    let dryCarrier = getValue(thisObj, 'txtCarrierDT')
    let dryDispatch = getValue(thisObj, 'txtDiapatchDT')
    let dryVehicleID = getValue(thisObj, 'txtVehicleNumDT')
    let dryWtIncVehicle = getValue(thisObj, 'txtWtIncludingVehicleDT')
    let dryWtOfVehicle = getValue(thisObj, 'txtWtOfVehicleDT')
    let greenScaleTicket = getValue(thisObj, 'txtWeightTicketGT')
    let GreenDt = getValue(thisObj, "txtDateGT")
    let greenDate = ''
    if (GreenDt != undefined) {
      if (GreenDt.length >= 8) {
        GreenDt = GreenDt.split(' ')[0]
        greenDate = GreenDt;
      }
      else {
        greenDate = convert(getValue(thisObj, "txtDateGT")['_d'])
        if (greenDate == "aN/aN/NaN") {
          greenDate = ""
        }
      }
    }
    let greenCarrier = getValue(thisObj, 'txtCarrierGT')
    let greenDispatch = getValue(thisObj, 'txtDispatchGT')
    let greenVehicleID = getValue(thisObj, 'txtVehivleNumGT')
    let greenWtIncVehicle = getValue(thisObj, 'txtWtIncludingVehicleGT')
    let greenWtOfVehicle = getValue(thisObj, 'txtWtOfVehicleGT')
    let greenWtOfPeanuts = getValue(thisObj, 'txtWtOfPeanutsGT')
    let Weigher = getValue(thisObj, 'ddWeigher')
    if (parentData.lblHMC_IND == 'Y') {
      if (FormValid_HMC() == true) {
        dryScaleTicket = greenScaleTicket
        dryDate = greenDate
        dryWtOfPeanuts = greenWtOfPeanuts
        dryCarrier = greenCarrier
        dryDispatch = greenDispatch
        dryVehicleID = greenVehicleID
        dryWtIncVehicle = greenWtIncVehicle
        dryWtOfVehicle = greenWtOfVehicle
        let Array = []
        let Obj = {}
        Obj.BtnID = 'ADD'
        Obj.DryScaleTicket = dryScaleTicket
        Obj.DryDate = dryDate
        Obj.DryWtOfPeanuts = dryWtOfPeanuts
        Obj.RowData = dryScaleTicket + "|" + dryDate + "|" + dryCarrier + "|" + dryDispatch + "|" + dryVehicleID + "|" + dryWtIncVehicle + "|" + dryWtOfVehicle + "|" + dryWtOfPeanuts + "|" + greenScaleTicket + "|" + greenDate + "|" + greenCarrier + "|" + greenDispatch + "|" + greenVehicleID + "|" + greenWtIncVehicle + "|" + greenWtOfVehicle + "|" + greenWtOfPeanuts + "|" + Weigher
        Obj.TicketUploadMod = parentData.ticketUploadMod + dryScaleTicket + ",A,"
        Array.push(Obj)
        setData(thisObj, 'frmFV95Data', Obj)
        setData(thisObj, 'frmFV95Array', Array)
        setData(thisObj, 'lblFormDirty', "True")

        document.getElementById("Settlements_ScaleTicketsPopUp").childNodes[0].click()
      }
    }
    else {
      if (FormValid() == true) {
        let Array = []
        let Obj = {}
        Obj.BtnID = 'ADD'
        Obj.DryScaleTicket = dryScaleTicket
        Obj.DryDate = dryDate
        Obj.DryWtOfPeanuts = dryWtOfPeanuts
        Obj.RowData = dryScaleTicket + "|" + dryDate + "|" + dryCarrier + "|" + dryDispatch + "|" + dryVehicleID + "|" + dryWtIncVehicle + "|" + dryWtOfVehicle + "|" + dryWtOfPeanuts + "|" + greenScaleTicket + "|" + greenDate + "|" + greenCarrier + "|" + greenDispatch + "|" + greenVehicleID + "|" + greenWtIncVehicle + "|" + greenWtOfVehicle + "|" + greenWtOfPeanuts + "|" + Weigher
        Obj.TicketUploadMod = parentData.ticketUploadMod + dryScaleTicket + ",A,"
        Array.push(Obj)
        setData(thisObj, 'frmFV95Data', Obj)
        setData(thisObj, 'frmFV95Array', Array)
        setData(thisObj, 'lblFormDirty', "True")
        document.getElementById("Settlements_ScaleTicketsPopUp").childNodes[0].click()
      }
    }
  }
  thisObj.onbtnAdd1Click = onbtnAdd1Click;

  const onbtnUpdateClick = () => {
    parentData = getData(thisObj, 'parentData')
    let dryScaleTicket = getValue(thisObj, 'txtWeightTicketDT')
    let dryDate = ''
    let DryDt = getValue(thisObj, 'txtDateDT')
    if (DryDt != undefined) {
      if (DryDt.length >= 8) {
        DryDt = DryDt.split(' ')[0]
        dryDate = DryDt;
      } else {
        dryDate = convert(getValue(thisObj, "txtDateDT")['_d'])
        if (dryDate == "aN/aN/NaN") {
          dryDate = ""
        }
      }
    }
    let dryWtOfPeanuts = getValue(thisObj, 'txtWtOfPeanutsDT')
    let dryCarrier = getValue(thisObj, 'txtCarrierDT')
    let dryDispatch = getValue(thisObj, 'txtDiapatchDT')
    let dryVehicleID = getValue(thisObj, 'txtVehicleNumDT')
    let dryWtIncVehicle = getValue(thisObj, 'txtWtIncludingVehicleDT')
    let dryWtOfVehicle = getValue(thisObj, 'txtWtOfVehicleDT')
    let greenScaleTicket = getValue(thisObj, 'txtWeightTicketGT')
    let greenDate = ''
    let GreenDt = getValue(thisObj, "txtDateGT")
    if (GreenDt != undefined) {
      if (GreenDt.length >= 8) {
        GreenDt = GreenDt.split(' ')[0]
        greenDate = GreenDt;
      }
      else {
        greenDate = convert(getValue(thisObj, "txtDateGT")['_d'])
        if (greenDate == "aN/aN/NaN") {
          greenDate = ""
        }
      }
    }
    let greenCarrier = getValue(thisObj, 'txtCarrierGT')
    let greenDispatch = getValue(thisObj, 'txtDispatchGT')
    let greenVehicleID = getValue(thisObj, 'txtVehivleNumGT')
    let greenWtIncVehicle = getValue(thisObj, 'txtWtIncludingVehicleGT')
    let greenWtOfVehicle = getValue(thisObj, 'txtWtOfVehicleGT')
    let greenWtOfPeanuts = getValue(thisObj, 'txtWtOfPeanutsGT')
    let Weigher = getValue(thisObj, 'ddWeigher')
    if (parentData.lblHMC_IND == 'Y') {
      if (FormValid_HMC() == true) {
        dryScaleTicket = greenScaleTicket
        dryDate = greenDate
        dryWtOfPeanuts = greenWtOfPeanuts
        dryCarrier = greenCarrier
        dryDispatch = greenDispatch
        dryVehicleID = greenVehicleID
        dryWtIncVehicle = greenWtIncVehicle
        dryWtOfVehicle = greenWtOfVehicle
        let Array = []
        let Obj = {}
        Obj.BtnID = 'UPDATE'
        Obj.DryScaleTicket = dryScaleTicket
        Obj.DryDate = dryDate
        Obj.DryWtOfPeanuts = dryWtOfPeanuts
        Obj.RowData = dryScaleTicket + "|" + dryDate + "|" + dryCarrier + "|" + dryDispatch + "|" + dryVehicleID + "|" + dryWtIncVehicle + "|" + dryWtOfVehicle + "|" + dryWtOfPeanuts + "|" + greenScaleTicket + "|" + greenDate + "|" + greenCarrier + "|" + greenDispatch + "|" + greenVehicleID + "|" + greenWtIncVehicle + "|" + greenWtOfVehicle + "|" + greenWtOfPeanuts + "|" + Weigher
        Array.push(Obj)
        setData(thisObj, 'frmFV95Data', Obj)
        setData(thisObj, 'frmFV95Array', Array)
        setData(thisObj, 'lblFormDirty', "True")
        document.getElementById("Settlements_ScaleTicketsPopUp").childNodes[0].click()
      }
    }
    else {
      if (FormValid() == true) {
        let Array = []
        let Obj = {}
        Obj.BtnID = 'UPDATE'
        Obj.DryScaleTicket = dryScaleTicket
        Obj.DryDate = dryDate
        Obj.DryWtOfPeanuts = dryWtOfPeanuts
        Obj.RowData = dryScaleTicket + "|" + dryDate + "|" + dryCarrier + "|" + dryDispatch + "|" + dryVehicleID + "|" + dryWtIncVehicle + "|" + dryWtOfVehicle + "|" + dryWtOfPeanuts + "|" + greenScaleTicket + "|" + greenDate + "|" + greenCarrier + "|" + greenDispatch + "|" + greenVehicleID + "|" + greenWtIncVehicle + "|" + greenWtOfVehicle + "|" + greenWtOfPeanuts + "|" + Weigher
        Array.push(Obj)
        setData(thisObj, 'frmFV95Data', Obj)
        setData(thisObj, 'frmFV95Array', Array)
        setData(thisObj, 'lblFormDirty', "True")
        document.getElementById("Settlements_ScaleTicketsPopUp").childNodes[0].click()
      }
    }
  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick;

  const ontxtWeightTicketDTChange = () => {
    try {
      let value = getValue(thisObj, 'txtWeightTicketDT')
      let strRegex = new RegExp(/^[A-Z]\d*$/)
      const reg = new RegExp('^[0-9]+$');
      if (strRegex.test(value))
        setValue(thisObj, 'txtWeightTicketDT', value)
      else
      if(reg.test(value))
      {
       setValue(thisObj, 'txtWeightTicketDT', value)
      }
      else{
        setValue(thisObj, 'txtWeightTicketDT', '')
      }
       }
    catch (err) {
      showMessage(thisObj, err.message)
    }
  }
 thisObj.ontxtWeightTicketDTChange = ontxtWeightTicketDTChange

 const ontxtWeightTicketGTChange = () => {
  try {
    let value = getValue(thisObj, 'txtWeightTicketGT')
    let strRegex = new RegExp(/^[A-Z]\d*$/)
    const reg = new RegExp('^[0-9]+$');
    if (strRegex.test(value))
      setValue(thisObj, 'txtWeightTicketGT', value)
    else
    if(reg.test(value))
    {
     setValue(thisObj, 'txtWeightTicketGT', value)
    }
    else{
      setValue(thisObj, 'txtWeightTicketGT', '')
    }
     }
  catch (err) {
    showMessage(thisObj, err.message)
  }
}
thisObj.ontxtWeightTicketGTChange = ontxtWeightTicketGTChange

  const CalculateDryTotal = () => {
    let dryWtIncVehicle = getValue(thisObj, 'txtWtIncludingVehicleDT')
    let dryWtOfVehicle = getValue(thisObj, 'txtWtOfVehicleDT')
    let WtOfPeanuts = 0
    if ((Number(dryWtIncVehicle) || dryWtIncVehicle == '' || dryWtIncVehicle == null || dryWtIncVehicle == undefined) && (Number(dryWtOfVehicle) || dryWtOfVehicle == '' || dryWtOfVehicle == null || dryWtOfVehicle == undefined)) {
      if ((dryWtIncVehicle == null || dryWtIncVehicle == undefined || dryWtIncVehicle == '') && (dryWtOfVehicle == '' || dryWtOfVehicle == null || dryWtOfVehicle == undefined)) {
        setValue(thisObj, 'txtWtOfPeanutsDT', '')
      }
      else {
        if ((dryWtIncVehicle != '' || dryWtIncVehicle != null || dryWtIncVehicle != undefined) && (dryWtOfVehicle != '' || dryWtOfVehicle != null || dryWtOfVehicle != undefined)) {
          WtOfPeanuts = Number(dryWtIncVehicle) - Number(dryWtOfVehicle)
          setValue(thisObj, 'txtWtOfPeanutsDT', WtOfPeanuts)
        }
        else {
          if (dryWtIncVehicle == null || dryWtIncVehicle == undefined || dryWtIncVehicle == '') {
            WtOfPeanuts = 0 - Number(dryWtOfVehicle)
            setValue(thisObj, 'txtWtOfPeanutsDT', WtOfPeanuts)
          }
          else {
            WtOfPeanuts = Number(dryWtIncVehicle) - 0
            setValue(thisObj, 'txtWtOfPeanutsDT', WtOfPeanuts)
          }
        }
      }
    }
    else {
      setValue(thisObj, 'txtWtOfPeanutsDT', '')
    }
  }

  const CalculateGreenTotal = () => {
    let greenWtIncVehicle = getValue(thisObj, 'txtWtIncludingVehicleGT')
    let greenWtOfVehicle = getValue(thisObj, 'txtWtOfVehicleGT')
    let greenWtOfPeanuts = 0
    if ((Number(greenWtIncVehicle) || greenWtIncVehicle == '' || greenWtIncVehicle == null || greenWtIncVehicle == undefined) && (Number(greenWtOfVehicle) || greenWtOfVehicle == '' || greenWtOfVehicle == null || greenWtOfVehicle == undefined)) {
      if ((greenWtIncVehicle == '' || greenWtIncVehicle == null || greenWtIncVehicle == undefined) && (greenWtOfVehicle == '' || greenWtOfVehicle == null || greenWtOfVehicle == undefined)) {
        setValue(thisObj, 'txtWtOfPeanutsGT', '')
      }
      else {
        if ((greenWtIncVehicle !== '' || greenWtIncVehicle !== null || greenWtIncVehicle !== undefined) && (greenWtOfVehicle !== '' || greenWtOfVehicle !== null || greenWtOfVehicle !== undefined)) {
          let greenWtOfPeanuts = greenWtIncVehicle - greenWtOfVehicle
          setValue(thisObj, 'txtWtOfPeanutsGT', greenWtOfPeanuts)
        }
        else {
          if (greenWtIncVehicle == '' || greenWtIncVehicle == null || greenWtIncVehicle == undefined) {
            greenWtOfPeanuts = 0 - greenWtOfVehicle
            setValue(thisObj, 'txtWtOfPeanutsGT', greenWtOfPeanuts)
          }
          else {
            greenWtOfPeanuts = greenWtIncVehicle - 0
            setValue(thisObj, 'txtWtOfPeanutsGT', greenWtOfPeanuts)
          }
        }
      }
    }
    else {
      setValue(thisObj, 'txtWtOfPeanutsGT', '')
    }
  }

  const onbtnCancelClick = () => {
    try {
      document.getElementById("Settlements_ScaleTicketsPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnClose event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onbtnSelectUploadClick = () => {
    setData(thisObj, 'lblWeigher', getValue(thisObj, 'ddWeigher'))
    setData(thisObj, 'lblMode', "UPDATE")
    //goTo(thisObj, "SystemMaintenance#ScaleTicketUploadSelect#DEFAULT#true#Click")
    return true;
  }
  thisObj.onbtnSelectUploadClick = onbtnSelectUploadClick;

  const Form_Activate = async () => {
    parentData = getData(thisObj, 'parentData')
    if (parentData != null && parentData != undefined) {
      let BuyPt = parentData.lblBuyingPoint
      let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN1171', null, 'U', BuyPt)
      if (response != undefined && response != null && response.length > 0) {
        let Permission = response[0].permission
        if (Permission !== 'Y') {
          document.getElementsByClassName("btnSelectUpload")[0].style.visibility = "hidden";
        }
      }
      if (parentData.cmdOk_Caption == "Add") {
        enable(thisObj, 'btnSelectUpload')
      }
      else {
        disable(thisObj, 'btnSelectUpload')
      }
    }
  }

  const Form_Load = () => {
    parentData = getData(thisObj, 'parentData')
    if (parentData.lblElecUploadInd == "Y") {
      disable(thisObj, 'txtVehicleNumDT')
      disable(thisObj, 'txtVehivleNumGT')
    }
  }

  const ontxtWtIncludingVehicleDTChange = () => {
    CalculateDryTotal()
  }
  thisObj.ontxtWtIncludingVehicleDTChange = ontxtWtIncludingVehicleDTChange

  const ontxtWtIncludingVehicleDTBlur = () => {
    let dryWtIncVehicle = getValue(thisObj, 'txtWtIncludingVehicleDT')
    if (Number(dryWtIncVehicle)) {
      dryWtIncVehicle = Number(dryWtIncVehicle).toFixed(0)
      getValue(thisObj, 'txtWtIncludingVehicleDT', dryWtIncVehicle)
    }
  }
  thisObj.ontxtWtIncludingVehicleDTBlur = ontxtWtIncludingVehicleDTBlur

  const ontxtWtOfVehicleDTChange = () => {
    CalculateDryTotal()
  }
  thisObj.ontxtWtOfVehicleDTChange = ontxtWtOfVehicleDTChange;

  const ontxtWtOfVehicleDTBlur = () => {
    let dryWtOfVehicle = getValue(thisObj, 'txtWtOfVehicleDT')
    if (Number(dryWtOfVehicle)) {
      dryWtOfVehicle = Number(dryWtOfVehicle).toFixed(0)
      setValue(thisObj, 'txtWtOfVehicleDT', dryWtOfVehicle)
    }
  }
  thisObj.ontxtWtOfVehicleDTBlur = ontxtWtOfVehicleDTBlur;

  const ontxtWtIncludingVehicleGTChange = () => {
    CalculateGreenTotal()
  }
  thisObj.ontxtWtIncludingVehicleGTChange = ontxtWtIncludingVehicleGTChange;

  const ontxtWtIncludingVehicleGTBlur = () => {
    let greenWtIncVehicle = getValue(thisObj, 'txtWtIncludingVehicleGT')
    if (Number(greenWtIncVehicle)) {
      greenWtIncVehicle = Number(greenWtIncVehicle).toFixed(0)
      setValue(thisObj, 'txtWtIncludingVehicleGT', greenWtIncVehicle)
    }
  }
  thisObj.ontxtWtIncludingVehicleGTBlur = ontxtWtIncludingVehicleGTBlur;

  const ontxtWtOfVehicleGTChange = () => {
    CalculateGreenTotal()
  }
  thisObj.ontxtWtOfVehicleGTChange = ontxtWtOfVehicleGTChange;

  const ontxtWtOfVehicleGTBlur = () => {
    let greenWtOfVehicle = getValue(thisObj, 'txtWtOfVehicleGT')
    if (Number(greenWtOfVehicle)) {
      greenWtOfVehicle = Number(greenWtOfVehicle).toFixed(0)
      getValue(thisObj, 'txtWtOfVehicleGT', greenWtOfVehicle)
    }
  }
  thisObj.ontxtWtOfVehicleGTBlur = ontxtWtOfVehicleGTBlur;

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const FormValid = () => {

    var bFormValid = false

    let dryScaleTicket = getValue(thisObj, 'txtWeightTicketDT')
    let dryDate = ''
    let DryDt = getValue(thisObj, 'txtDateDT')
    if (DryDt != undefined) {
      if (DryDt.length >= 8) {
        DryDt = DryDt.split(' ')[0]
        dryDate = DryDt
      }
      else {
        dryDate = convert(getValue(thisObj, "txtDateDT")['_d'])
        if (dryDate == "aN/aN/NaN") {
          dryDate = ""
        }
      }
    }
    let dryWtOfPeanuts = getValue(thisObj, 'txtWtOfPeanutsDT')
    let dryWtIncVehicle = getValue(thisObj, 'txtWtIncludingVehicleDT')
    let dryWtOfVehicle = getValue(thisObj, 'txtWtOfVehicleDT')
    let greenScaleTicket = getValue(thisObj, 'txtWeightTicketGT')
    let greenDate = ''
    let GreenDt = getValue(thisObj, "txtDateGT")
    if (GreenDt != undefined) {
      if (GreenDt.length >= 8) {
        GreenDt = GreenDt.split(' ')[0]
        greenDate = GreenDt
      }
      else {
        greenDate = convert(getValue(thisObj, "txtDateGT")['_d'])
        if (greenDate == "aN/aN/NaN") {
          greenDate = ""
        }
      }
    }
    let greenCarrier = getValue(thisObj, 'txtCarrierGT')
    let greenDispatch = getValue(thisObj, 'txtDispatchGT')
    let greenWtIncVehicle = getValue(thisObj, 'txtWtIncludingVehicleGT')
    let greenWtOfVehicle = getValue(thisObj, 'txtWtOfVehicleGT')
    let Weigher = getValue(thisObj, 'ddWeigher')

    if (dryScaleTicket == null || dryScaleTicket == '' || dryScaleTicket == undefined) {
      showMessage(thisObj, "Dry Scale Ticket # is a required field")
      return bFormValid;
    }

    if (dryDate == null || dryDate == '' || dryDate == undefined) {
      showMessage(thisObj, "Dry Date must be of format ##/##/####")
      return bFormValid;
    }
    if (dryDate != undefined && dryDate != null && dryDate != '') {
      if (DateValidation(dryDate) == false) {
        showMessage(thisObj, 'Dry Ticket date does not conform to current crop year date range specification. Start Date: 07/01/' + cropYearFromLS() + ' and End Date: 07/31/' + (cropYearFromLS() + 1))
        return bFormValid;
      }
    }
    if (greenDate != undefined && greenDate != null && greenDate != '') {
      if (DateValidation(greenDate) == false) {
        showMessage(thisObj, 'green Ticket date does not conform to current crop year date range specification. Start Date: 07/01/' + cropYearFromLS() + ' and End Date: 07/31/' + (cropYearFromLS() + 1))
        return bFormValid;
      }
    }

    if (dryWtIncVehicle == null || dryWtIncVehicle == '' || dryWtIncVehicle == undefined) {
      showMessage(thisObj, "Dry Weight Including Vehicle is a required field")
      return bFormValid;
    }
    if (Number(dryWtIncVehicle)) {
      if (Number(dryWtIncVehicle) < 1 || Number(dryWtIncVehicle) > 999999) {
        showMessage(thisObj, "Dry Weight Including Vehicle must be between 1 and 999,999")
        return bFormValid;
      }
    } else {
      showMessage(thisObj, "Dry Weight Including Vehicle must be of format ######")
      return bFormValid;
    }
    if (dryWtOfVehicle == null || dryWtOfVehicle == undefined || dryWtOfVehicle == '') {
      showMessage(thisObj, "Dry Weight Of Vehicle is a required field")
      return bFormValid;
    }
    if (dryWtOfVehicle !== null && dryWtOfVehicle !== undefined && dryWtOfVehicle !== '') {
      if (Number(dryWtOfVehicle) < 1 || Number(dryWtOfVehicle) > 999999) {
        showMessage(thisObj, "Dry Weight Of Vehicle must be between 1 and 999,999")
        return bFormValid;
      }
    } else {
      showMessage(thisObj, "Dry Weight Of Vehicle must be of format ######")
      return bFormValid;
    }

    if (dryWtOfPeanuts == null || dryWtOfPeanuts == undefined || dryWtOfPeanuts == '') {
      showMessage(thisObj, "Dry Weight Of Peanuts is a required field")
      return bFormValid;
    }
    if (Number(dryWtOfPeanuts)) {
      if (Number(dryWtOfPeanuts) < 1 || Number(dryWtOfPeanuts) > 999999) {
        showMessage(thisObj, "Dry Weight Of Peanuts must be between 1 and 99,999")
        return bFormValid;
      }
    } else {
      showMessage(thisObj, "Dry Weight Of Peanuts must be of format #####")
      return bFormValid;
    }
    if (greenScaleTicket !== null && greenDate !== null && greenCarrier !== null && greenDispatch !== null && greenWtIncVehicle !== null && greenWtOfVehicle !== null &&
      greenScaleTicket !== undefined && greenDate !== undefined && greenCarrier !== undefined && greenDispatch !== undefined && greenWtIncVehicle !== undefined && greenWtOfVehicle !== undefined &&
      greenScaleTicket !== '' && greenDate !== '' && greenCarrier !== '' && greenDispatch !== '' && greenWtIncVehicle !== '' && greenWtOfVehicle !== '') {
      if (FormValid_HMC() !== true) {
        return bFormValid;
      }
    }
    if (Weigher == null || Weigher == undefined || Weigher == '') {
      showMessage(thisObj, "Weigher is Required")
      return bFormValid;
    }

    bFormValid = true;
    return bFormValid;
  }

  const FormValid_HMC = () => {
    var bFormValid_HMC = false

    let dryDate = ''
    let DryDt = getValue(thisObj, 'txtDateDT')
    if (DryDt != undefined) {
      if (DryDt.length == 10) {
        DryDt = DryDt.split(' ')[0]
        dryDate = DryDt;
      } else {
        dryDate = convert(getValue(thisObj, "txtDateDT")['_d'])
        if (dryDate == "aN/aN/NaN") {
          dryDate = ""
        }
      }
    }

    let greenScaleTicket = getValue(thisObj, 'txtWeightTicketGT')
    let greenDate = ''
    let GreenDt = getValue(thisObj, "txtDateGT")
    if (GreenDt != undefined) {
      if (GreenDt.length >= 8) {
        GreenDt = GreenDt.split(' ')[0]
        greenDate = GreenDt;
      } else {
        greenDate = convert(getValue(thisObj, "txtDateGT")['_d'])
        if (greenDate == "aN/aN/NaN") {
          greenDate = ""
        }
      }
    }
    let greenWtIncVehicle = getValue(thisObj, 'txtWtIncludingVehicleGT')
    let greenWtOfVehicle = getValue(thisObj, 'txtWtOfVehicleGT')
    let greenWtOfPeanuts = getValue(thisObj, 'txtWtOfPeanutsGT')
    let Weigher = getValue(thisObj, 'ddWeigher')

    if (greenScaleTicket == null || greenScaleTicket == undefined || greenScaleTicket == '') {
      showMessage(thisObj, "Green Scale Ticket # is a required field")
      return bFormValid_HMC;
    }
    if (greenDate == null || greenDate == undefined || greenDate == '') {
      showMessage(thisObj, "Green Date must be of format ##/##/####")
      return bFormValid_HMC;
    }
    if (greenDate != undefined && greenDate != null && greenDate != '') {
      if (DateValidation(greenDate) == false) {
        showMessage(thisObj, 'Green Ticket date does not conform to current crop year date range specification. Start Date: 07/01/' + cropYearFromLS() + ' and End Date: 07/31/' + (cropYearFromLS() + 1))
        return bFormValid_HMC;
      }
    }

    if (dryDate != undefined && dryDate != null && dryDate != '') {
      if (DateValidation(dryDate) == false) {
        showMessage(thisObj, 'Dry Ticket date does not conform to current crop year date range specification. Start Date: 07/01/' + cropYearFromLS() + ' and End Date: 07/31/' + (cropYearFromLS() + 1))
        return bFormValid_HMC;
      }
    }

    if (greenWtIncVehicle == null || greenWtIncVehicle == undefined || greenWtIncVehicle == '') {
      showMessage(thisObj, "Green Weight Including Vehicle is a required field")
      return bFormValid_HMC;
    }
    if (Number(greenWtIncVehicle)) {
      if (Number(greenWtIncVehicle) < 1 || Number(greenWtIncVehicle) > 999999) {
        showMessage(thisObj, "Green Weight Including Vehicle must be between 1 and 999,999")
        return bFormValid_HMC;
      }
    } else {
      showMessage(thisObj, "Green Weight Including Vehicle must be of format ######")
      return bFormValid_HMC;
    }
    if (greenWtOfVehicle == null || greenWtOfVehicle == undefined || greenWtOfVehicle == '') {
      showMessage(thisObj, "Green Weight Of Vehicle is a required field")
      return bFormValid_HMC;
    }
    if (Number(greenWtOfVehicle)) {
      if (Number(greenWtOfVehicle) < 1 || Number(greenWtOfVehicle) > 999999) {
        showMessage(thisObj, "Green Weight Of Vehicle must be between 1 and 999,999")
        return bFormValid_HMC;
      }
    } else {
      showMessage(thisObj, "Green Weight Of Vehicle must be of format ######")
      return bFormValid_HMC;
    }
    if (greenWtOfPeanuts == null || greenWtOfPeanuts == undefined || greenWtOfPeanuts == '') {
      showMessage(thisObj, "Green Weight Of Peanuts is a required field")
      return bFormValid_HMC;
    }
    if (Number(greenWtOfPeanuts)) {
      if (Number(greenWtOfPeanuts) < 1 || Number(greenWtOfPeanuts) > 999999) {
        showMessage(thisObj, "Green Weight Of Peanuts must be between 1 and 99,999")
        return bFormValid_HMC;
      }
    } else {
      showMessage(thisObj, "Green Weight Of Peanuts must be of format #####")
      return bFormValid_HMC;
    }
    if (Weigher == null || Weigher == undefined || Weigher == '') {
      showMessage(thisObj, "Weigher is Required")
      return bFormValid_HMC;
    }

    bFormValid_HMC = true
    return bFormValid_HMC;
  }

  const FillWeigherList = async () => {
    parentData = getData(thisObj, 'parentData')
    let bFillWeigherList = false
      if (parentData != null && parentData != undefined) {
        let BuyPt = parentData.lblBuyingPoint
        let insp_num = parentData.inspNum
        let dry_ticket = getValue(thisObj, 'txtWeightTicketDT')
        let js = []
        js.push({ key: '', description: '' });
        let response = await SettlementService.RetrieveWeigherControlList(BuyPt, insp_num, dry_ticket)
        if (response != undefined && response.length > 0) {
          let data = response
          for (var i = 0; i < data.length; i++) {
            let obj = { key: data[i].weigher_name, description: data[i].weigher_name.trim() }
            js.push(obj)
          }
          bFillWeigherList = true
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddWeigher: {
              ...state["ddWeigher"],
              valueList: js
            }
          }
        })
        let DataObj = getData(thisObj, 'frmScaleTickets')
        if(DataObj.cmdOk_Caption == "Add"){
          setValue(thisObj, 'ddWeigher', js[0].key);
        }
      // return bFillWeigherList
    }
  }


  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ScaleTickets*/}

              {/* END_USER_CODE-USER_BEFORE_ScaleTickets*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxScaleTickets*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxScaleTickets*/}

              <GroupBoxWidget conf={state.grpbxScaleTickets} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblDryTicket*/}

                {/* END_USER_CODE-USER_BEFORE_lblDryTicket*/}
                <GroupBoxWidget conf={state.grpbxDryTicket} screenConf={state}>
                <LabelWidget
                  values={values}
                  conf={state.lblDryTicket}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblDryTicket*/}

                {/* END_USER_CODE-USER_AFTER_lblDryTicket*/}
                {/* START_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBuyingPoint}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_lblBuyingPoint*/}
                
                {/* START_USER_CODE-USER_BEFORE_lblHMCIND*/}

                {/* END_USER_CODE-USER_BEFORE_lblHMCIND*/}

                <LabelWidget
                  values={values}
                  conf={state.lblHMCIND}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblHMCIND*/}

                {/* END_USER_CODE-USER_AFTER_lblHMCIND*/}
                {/* START_USER_CODE-USER_BEFORE_txtWeightTicketDT*/}

                {/* END_USER_CODE-USER_BEFORE_txtWeightTicketDT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWeightTicketDT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWeightTicketDT*/}

                {/* END_USER_CODE-USER_AFTER_txtWeightTicketDT*/}
                
                {/* START_USER_CODE-USER_BEFORE_txtDateDT*/}

                {/* END_USER_CODE-USER_BEFORE_txtDateDT*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDateDT}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtDateDT*/}

                {/* END_USER_CODE-USER_AFTER_txtDateDT*/}
                {/* START_USER_CODE-USER_BEFORE_lblDateFormatDT*/}

                {/* END_USER_CODE-USER_BEFORE_lblDateFormatDT*/}

                <LabelWidget
                  values={values}
                  conf={state.lblDateFormatDT}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblDateFormatDT*/}

                {/* END_USER_CODE-USER_AFTER_lblDateFormatDT*/}
               
                {/* START_USER_CODE-USER_BEFORE_txtCarrierDT*/}

                {/* END_USER_CODE-USER_BEFORE_txtCarrierDT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCarrierDT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCarrierDT*/}

                {/* END_USER_CODE-USER_AFTER_txtCarrierDT*/}
               
                {/* START_USER_CODE-USER_BEFORE_txtDiapatchDT*/}

                {/* END_USER_CODE-USER_BEFORE_txtDiapatchDT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDiapatchDT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDiapatchDT*/}

                {/* END_USER_CODE-USER_AFTER_txtDiapatchDT*/}
               
                {/* START_USER_CODE-USER_BEFORE_txtVehicleNumDT*/}

                {/* END_USER_CODE-USER_BEFORE_txtVehicleNumDT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVehicleNumDT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVehicleNumDT*/}

                {/* END_USER_CODE-USER_AFTER_txtVehicleNumDT*/}
               
                {/* START_USER_CODE-USER_BEFORE_txtWtIncludingVehicleDT*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtIncludingVehicleDT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtIncludingVehicleDT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtIncludingVehicleDT*/}

                {/* END_USER_CODE-USER_AFTER_txtWtIncludingVehicleDT*/}
                
                {/* START_USER_CODE-USER_BEFORE_txtWtOfVehicleDT*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtOfVehicleDT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtOfVehicleDT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtOfVehicleDT*/}

                {/* END_USER_CODE-USER_AFTER_txtWtOfVehicleDT*/}
                
                {/* START_USER_CODE-USER_BEFORE_txtWtOfPeanutsDT*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtOfPeanutsDT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtOfPeanutsDT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtOfPeanutsDT*/}

                {/* END_USER_CODE-USER_AFTER_txtWtOfPeanutsDT*/}
               
                {/* START_USER_CODE-USER_BEFORE_grpbxWeigher*/}
                  </GroupBoxWidget>
                  <GroupBoxWidget conf={state.grpbxGreenTicket} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_lblGreenTicket*/}

                {/* END_USER_CODE-USER_BEFORE_lblGreenTicket*/}

                <LabelWidget
                  values={values}
                  conf={state.lblGreenTicket}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblGreenTicket*/}

                {/* END_USER_CODE-USER_AFTER_lblGreenTicket*/}
{/* START_USER_CODE-USER_BEFORE_txtWeightTicketGT*/}

                {/* END_USER_CODE-USER_BEFORE_txtWeightTicketGT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWeightTicketGT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWeightTicketGT*/}

                {/* END_USER_CODE-USER_AFTER_txtWeightTicketGT*/}
 {/* START_USER_CODE-USER_BEFORE_txtDateGT*/}

                {/* END_USER_CODE-USER_BEFORE_txtDateGT*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDateGT}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtDateGT*/}

                {/* END_USER_CODE-USER_AFTER_txtDateGT*/}
                {/* START_USER_CODE-USER_BEFORE_lblDateFormatGT*/}

                {/* END_USER_CODE-USER_BEFORE_lblDateFormatGT*/}

                <LabelWidget
                  values={values}
                  conf={state.lblDateFormatGT}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblDateFormatGT*/}

                {/* END_USER_CODE-USER_AFTER_lblDateFormatGT*/}
 {/* START_USER_CODE-USER_BEFORE_txtCarrierGT*/}

                {/* END_USER_CODE-USER_BEFORE_txtCarrierGT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCarrierGT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCarrierGT*/}

                {/* END_USER_CODE-USER_AFTER_txtCarrierGT*/}
 {/* START_USER_CODE-USER_BEFORE_txtDispatchGT*/}

                {/* END_USER_CODE-USER_BEFORE_txtDispatchGT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDispatchGT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDispatchGT*/}

                {/* END_USER_CODE-USER_AFTER_txtDispatchGT*/}
 {/* START_USER_CODE-USER_BEFORE_txtVehivleNumGT*/}

                {/* END_USER_CODE-USER_BEFORE_txtVehivleNumGT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVehivleNumGT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVehivleNumGT*/}

                {/* END_USER_CODE-USER_AFTER_txtVehivleNumGT*/}
{/* START_USER_CODE-USER_BEFORE_txtWtIncludingVehicleGT*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtIncludingVehicleGT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtIncludingVehicleGT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtIncludingVehicleGT*/}

                {/* END_USER_CODE-USER_AFTER_txtWtIncludingVehicleGT*/}
{/* START_USER_CODE-USER_BEFORE_txtWtOfVehicleGT*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtOfVehicleGT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtOfVehicleGT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtOfVehicleGT*/}

                {/* END_USER_CODE-USER_AFTER_txtWtOfVehicleGT*/}
 {/* START_USER_CODE-USER_BEFORE_txtWtOfPeanutsGT*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtOfPeanutsGT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtOfPeanutsGT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtOfPeanutsGT*/}

                {/* END_USER_CODE-USER_AFTER_txtWtOfPeanutsGT*/}
                  </GroupBoxWidget>
                {/* END_USER_CODE-USER_BEFORE_grpbxWeigher*/}

                <GroupBoxWidget conf={state.grpbxWeigher} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblWeigher*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWeigher*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWeigher}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWeigher*/}

                  {/* END_USER_CODE-USER_AFTER_lblWeigher*/}
                  {/* START_USER_CODE-USER_BEFORE_lblState*/}

                  {/* END_USER_CODE-USER_BEFORE_lblState*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblState}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblState*/}

                  {/* END_USER_CODE-USER_AFTER_lblState*/}
                  {/* START_USER_CODE-USER_BEFORE_lblCounty*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCounty*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCounty}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCounty*/}

                  {/* END_USER_CODE-USER_AFTER_lblCounty*/}
                  {/* START_USER_CODE-USER_BEFORE_lblFarm*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFarm*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFarm}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFarm*/}

                  {/* END_USER_CODE-USER_AFTER_lblFarm*/}
                  {/* START_USER_CODE-USER_BEFORE_lblFarmSuffix*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFarmSuffix*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFarmSuffix}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFarmSuffix*/}

                  {/* END_USER_CODE-USER_AFTER_lblFarmSuffix*/}
                  {/* START_USER_CODE-USER_BEFORE_ddWeigher*/}

                  {/* END_USER_CODE-USER_BEFORE_ddWeigher*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddWeigher}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddWeigher*/}

                  {/* END_USER_CODE-USER_AFTER_ddWeigher*/}
                  {/* START_USER_CODE-USER_BEFORE_lblTicketUpload*/}

                  {/* END_USER_CODE-USER_BEFORE_lblTicketUpload*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblTicketUpload}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblTicketUpload*/}

                  {/* END_USER_CODE-USER_AFTER_lblTicketUpload*/}
                  {/* START_USER_CODE-USER_BEFORE_lblFV95*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFV95*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFV95}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFV95*/}

                  {/* END_USER_CODE-USER_AFTER_lblFV95*/}
                  {/* START_USER_CODE-USER_BEFORE_lblUploadSelect*/}

                  {/* END_USER_CODE-USER_BEFORE_lblUploadSelect*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblUploadSelect}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblUploadSelect*/}

                  {/* END_USER_CODE-USER_AFTER_lblUploadSelect*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxWeigher*/}

                {/* END_USER_CODE-USER_AFTER_grpbxWeigher*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxScaleTickets*/}

              {/* END_USER_CODE-USER_AFTER_grpbxScaleTickets*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnSelectUpload*/}

                {/* END_USER_CODE-USER_BEFORE_btnSelectUpload*/}

                <ButtonWidget
                  conf={state.btnSelectUpload}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSelectUpload*/}

                {/* END_USER_CODE-USER_AFTER_btnSelectUpload*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd1*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd1*/}

                <ButtonWidget
                  conf={state.btnAdd1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd1*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd1*/}
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ScaleTickets*/}

              {/* END_USER_CODE-USER_AFTER_ScaleTickets*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_ScaleTickets);
