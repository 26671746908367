/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  setData,
  setValue,
  getValue,
  getData,
  goTo
} from "../../shared/WindowImports";

import "./PremiumDeductionContractProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_PremiumDeductionContractProfile(
  props
) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES
  const compIdFromLS = sessionStorage.getItem('compId');
  const cropYearFromLS = JSON.parse(sessionStorage.getItem('year'))
  const useridFromLS = sessionStorage.getItem('userid');
  let states = {
    Label: "PremiumDeductionContractProfile",
    windowName: "PremiumDeductionContractProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.PremiumDeductionContractProfile",
    // START_USER_CODE-USER_PremiumDeductionContractProfile_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Premium Deduction Contract Profile",
      scrCode: "PN0120D",
    },
    // END_USER_CODE-USER_PremiumDeductionContractProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added  By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxPremiumDeductionContractProfile",
      Label: "Buying Point:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES
      Readonly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpbxPremiumDeductionContractProfile",
      Label: "Contract #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES

      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    txtPremDeductCode: {
      name: "txtPremDeductCode",
      type: "TextBoxWidget",
      parent: "grpbxPremiumDeductionContractProfile",
      Label: "Premium Deduction Code:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremDeductCode_PROPERTIES
      Readonly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtPremDeductCode_PROPERTIES
    },
    grpbxPremiumDeductionContractProfile: {
      name: "grpbxPremiumDeductionContractProfile",
      type: "GroupBoxWidget",
      parent: "PremiumDeductionContractProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPremiumDeductionContractProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxPremiumDeductionContractProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "PremiumDeductionContractProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    let premiumdeductioncontractprofiledetails = getData(thisObj, "premiumdeductioncontractprofiledata");
    setValue(thisObj, "txtBuyingPoint", premiumdeductioncontractprofiledetails.buyingpointname);
    setValue(thisObj, "txtPremDeductCode", premiumdeductioncontractprofiledetails.pdcodename);
    document.getElementsByClassName("btnOkButton")[1].innerText = premiumdeductioncontractprofiledetails.cmdOkCaption;
  }, [getData(thisObj, "premiumdeductioncontractprofiledata")]);
  // START_USER_CODE-USER_METHODS
  function isNumeric(value) {
    return value !== '' && !isNaN(parseFloat(value)) && isFinite(value);
  }
  const bFormValid = async () => {
    let contractvalue = getValue(thisObj, "txtContract");
    let bFormValid = false
    let bDataValid = true
    if (isNumeric(contractvalue)) {
      if (contractvalue < 0 || contractvalue > 999999) {
        bDataValid = false
      }
    }
    else {
      bDataValid = false
    }
    if (!bDataValid) {
      bDataValid = false;
      showMessage(thisObj, "Contract must be of format ######");
      return false;
    }
    {
      let frmPremiumDeductionScheduleProfile = getData(thisObj, "frmPremiumDeductionScheduleProfile_PDCP");
      for (let i = 0; i < frmPremiumDeductionScheduleProfile.length; i++) {
        if (frmPremiumDeductionScheduleProfile[i].key == contractvalue) {
          bDataValid = false;
          showMessage(thisObj, "This contract has already been added.");
          return false;
        }
      }
      let response = await ContractManagementService.RetrieveContractDetails(getValue(thisObj, "txtContract"), "PN0120", "")
      let data = response;
      if (response == undefined || response == null){
        bDataValid = false;
        showMessage(thisObj, "Database Error selecting contract.");
        return false;
      }
      else {
        if (data.length == 0) {
            bDataValid = false;
            showMessage(thisObj, "Contract does not exist.");
            return false;
          }
          else
            if (data[0].cont_type == "S") {
              bDataValid = false;
              showMessage(thisObj, "Seed Addendum Contracts are not allowed to be added.");
              return false;
            }
      }
    }
    if(bDataValid==false)
    return false;
    else
    return true;
  }
  // Ok Button Functionality
  const onbtnOkClick = async () => {
    if (!await bFormValid()) {
      return false;
    }
    
      let premiumdeductioncontractprofiledetails = getData(thisObj, "premiumdeductioncontractprofiledata");
      let btntype = premiumdeductioncontractprofiledetails.cmdOkCaption;
      if (btntype == 'Add') {
      }
      let dataObj = {
        "comp_id": compIdFromLS,
        "crop_year": cropYearFromLS,
        "buy_pt_id": premiumdeductioncontractprofiledetails.buyingpointid,
        "pd_code": premiumdeductioncontractprofiledetails.pdcode,
        "pd_contract": getValue(thisObj, "txtContract"),
        "user_id": useridFromLS,
      }
      let response = await SystemMaintenanceMasterManagementService.CreatePremiumDeductionContract(dataObj)
      if (response.status == 200) {
        let js = {
          contract: getValue(thisObj, "txtContract")
        }
        setData(thisObj, "scheduledetails", js);
        setData(thisObj, "frmPremiumDeductionScheduleProfileEnableDisableControls", true);
        document.getElementById("SystemMaintenanceMasterManagement_PremiumDeductionContractProfilePopUp").childNodes[0].click();
      }
      else {
        showMessage(
          thisObj,
          "An error occured while Adding the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist."
        );
      }
  }
  thisObj.onbtnOkClick = onbtnOkClick;
  // Cancel Button Code
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_PremiumDeductionContractProfilePopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnCancel event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PremiumDeductionContractProfile*/}

              {/* END_USER_CODE-USER_BEFORE_PremiumDeductionContractProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPremiumDeductionContractProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPremiumDeductionContractProfile*/}

              <GroupBoxWidget
                conf={state.grpbxPremiumDeductionContractProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBuyingPoint}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_txtBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txtPremDeductCode*/}

                {/* END_USER_CODE-USER_BEFORE_txtPremDeductCode*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPremDeductCode}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPremDeductCode*/}

                {/* END_USER_CODE-USER_AFTER_txtPremDeductCode*/}
                {/* START_USER_CODE-USER_BEFORE_txtContract*/}

                {/* END_USER_CODE-USER_BEFORE_txtContract*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContract*/}

                {/* END_USER_CODE-USER_AFTER_txtContract*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPremiumDeductionContractProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPremiumDeductionContractProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_PremiumDeductionContractProfile*/}

              {/* END_USER_CODE-USER_AFTER_PremiumDeductionContractProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_PremiumDeductionContractProfile
);
