/* eslint-disable*/
import React from "react";
import WarehouseReceipts_WarehouseReceiptInquiryPopUp from "./WarehouseReceiptInquiryPopUp";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("WarehouseReceiptInquiryPopUp Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WarehouseReceiptInquiryPopUp />);
    });
  });
  afterEach(cleanup);
  test("is WarehouseReceiptInquiryPopUp Loads Successfully", () => {
    expect(screen.getByText("WarehouseReceiptInquiryPopUp")).toBeInTheDocument;
  });
  test("Custom Test Cases for WarehouseReceiptInquiryPopUp", () => {
    // START_USER_CODE-USER_WarehouseReceiptInquiryPopUp_Custom_Test_Case
    // END_USER_CODE-USER_WarehouseReceiptInquiryPopUp_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WarehouseReceiptInquiryPopUp />);
    });
  });
  afterEach(cleanup);
  test("btnNo(Button Widget) Test Cases", async () => {
    const btnNo = screen.getByTestId("btnNo");
    expect(btnNo).toBeInTheDocument;
    expect(btnNo.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiryPopUp_btnNo")
    );
  });
  test("Custom Test Cases for btnNo", () => {
    // START_USER_CODE-USER_btnNo_TEST
    // END_USER_CODE-USER_btnNo_TEST
  });
  test("btnYes(Button Widget) Test Cases", async () => {
    const btnYes = screen.getByTestId("btnYes");
    expect(btnYes).toBeInTheDocument;
    expect(btnYes.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptInquiryPopUp_btnYes")
    );
  });
  test("Custom Test Cases for btnYes", () => {
    // START_USER_CODE-USER_btnYes_TEST
    // END_USER_CODE-USER_btnYes_TEST
  });
  test("grpbxPopUp(GroupBox Widget) Test Cases", async () => {
    const grpbxPopUp = screen.getByTestId("grpbxPopUp");
    expect(grpbxPopUp.tagName).toBe("BUTTON");
    expect(grpbxPopUp.type).toBe("button");
    expect(grpbxPopUp.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPopUp", () => {
    // START_USER_CODE-USER_grpbxPopUp_TEST
    // END_USER_CODE-USER_grpbxPopUp_TEST
  });
  test("txtDoYouWant(Textbox Widget) Test Cases", async () => {
    const txtDoYouWant = screen.getByTestId("txtDoYouWant");
    expect(txtDoYouWant.tagName).toBe("INPUT");
    expect(txtDoYouWant.type).toBe("text");
    expect(txtDoYouWant.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDoYouWant, "1");
    });
  });
  test("Custom Test Cases for txtDoYouWant", () => {
    // START_USER_CODE-USER_txtDoYouWant_TEST
    // END_USER_CODE-USER_txtDoYouWant_TEST
  });
});
