/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  DateWidget,
  setValue,
  getValue,
  getData,
  setData,
  enable,
  disable,
  goTo,
} from "../../shared/WindowImports";

import "./LoanInterestProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_LoanInterestProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  let mbSuccessfulLoad = false;
  // START_USER_CODE-USER_PROPERTIES
  let userId = sessionStorage.getItem("userid"); 
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "LoanInterestProfile",
    windowName: "LoanInterestProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.LoanInterestProfile",
    // START_USER_CODE-USER_LoanInterestProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Loan Interest Profile",
      scrCode: "PN0290B",
    },
    // END_USER_CODE-USER_LoanInterestProfile_PROPERTIES
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "btnOk",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtEffectivedate: {
      name: "txtEffectivedate",
      type: "DateTimeWidget",
      parent: "grpbcLoanInterestProfile",
      Label: "Effective date:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtEffectivedate_PROPERTIES

      // END_USER_CODE-USER_txtEffectivedate_PROPERTIES
    },
    txtInterestRate: {
      name: "txtInterestRate",
      type: "TextBoxWidget",
      parent: "grpbcLoanInterestProfile",
      Label: "Interest Rate:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      //DecimalPlaces: 5,
      ofTypeDomain: "d_float",
      // START_USER_CODE-USER_txtInterestRate_PROPERTIES

      // END_USER_CODE-USER_txtInterestRate_PROPERTIES
    },
    grpbcLoanInterestProfile: {
      name: "grpbcLoanInterestProfile",
      type: "GroupBoxWidget",
      parent: "LoanInterestProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbcLoanInterestProfile_PROPERTIES

      // END_USER_CODE-USER_grpbcLoanInterestProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "LoanInterestProfile",
      Height: "",
      Width: "",
      clonedExtId: "44193",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  //Logic for Form Load
  const FormLoad = async () => {
    try {
      let objData = getData(thisObj, "LoanInterestProfile");
      let btnCaption = objData.cmdOk_Caption;
      document.getElementById('btnOk').innerText = btnCaption;
      if (btnCaption == "Update") {
        disable(thisObj, "txtEffectivedate", true);
        thisObj.values.txtEffectivedate = objData.txtEffectiveDateTime;
        thisObj.values.txtInterestRate = Number(objData.txtInterestRate).toFixed(3);
        thisObj.values.lblAddedByValue = objData.lblAddedByCaption;
        thisObj.values.lblChangedByValue = objData.lblChangedByCaption;
        document.getElementById("txtInterestRate").focus();
      }
    } catch (err) {
      showMessage(err.message);
    }
  }
  // bForm valid logic
  async function bFormValid() {
    try {
      let formValid = false;
      const IsNumeric = new RegExp(/^-?\d+(\.\d+)?$/);
      let effectDate = getValue(thisObj, "txtEffectivedate");
      let interestRate = getValue(thisObj, "txtInterestRate");
      if (moment.isDate(effectDate) || effectDate == null || effectDate == undefined || effectDate == '') {
        showMessage(thisObj, "Invalid Effective Date Time!!!");
        mbSuccessfulLoad;
        return formValid;
      } else {
        document.getElementById('txtInterestRate').focus();
      }
      if (IsNumeric.test(interestRate)) {
        if (interestRate < 0 || interestRate > 100) {
          showMessage(thisObj, "Interest Rate must be of format ###.###");
          document.getElementById('txtInterestRate').focus();
          return formValid;
        }
      } else {
        showMessage(thisObj, "Interest Rate must be of format ###.###");
        document.getElementById('txtInterestRate').focus();
        return formValid;
      }
      mbSuccessfulLoad = true;
      formValid = true
      return formValid;
    } catch (err) {
      showMessage(err.message);
      return false;
    }
  };
  // Logic for Add button
  const onbtnOkClick = async () => {
    try {
      let objData = getData(thisObj, "LoanInterestProfile");
      let cmdOK = objData.cmdOk_Caption;
      let effectDate = getValue(thisObj, "txtEffectivedate");
      let interestRate = getValue(thisObj, "txtInterestRate");
      let edate = "";
      let response;
      if (effectDate != "" && effectDate != undefined) {
        edate = moment(String(effectDate)).format('MM-DD-YYYY HH:mm')
      }
      if (await bFormValid()) {        
        let frmLoanInterestSetup = {
          txtEffectiveDateTime: edate,
          lstLoanInterest: interestRate
        }
        if (cmdOK == "Add") {
          let data = {
            "effect_date_time": edate,
            "interest_rate": Number(interestRate).toFixed(3)
          }
          response = await SystemMaintenanceSpecialFunctionsService.CreateLoanInterest(data);
        }
        else if (cmdOK == "Update") {
          let data = {
            "interest_rate": Number(interestRate).toFixed(3),
            "user_id": userId
          }
          response = await SystemMaintenanceSpecialFunctionsService.UpdateLoanInterest(edate, data);
        }
        if (response.status == '200') {
          setData(thisObj, 'frmLoanInterestSetup', frmLoanInterestSetup);
          document.getElementById("SystemMaintenanceSpecialFunctions_LoanInterestProfilePopUp").childNodes[0].click();
          return true;
        }
        else {
          showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
        }
        frmLoanInterestSetup.cmdEnableDisableControls = true;
      }
    }
    catch (err) {
      showMessage(thisObj, err.message);
      return false;
    }
    return true;
  }
  thisObj.onbtnOkClick = onbtnOkClick;
  // Implement functionality for Cancel button___
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_LoanInterestProfilePopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_LoanInterestProfile*/}

              {/* END_USER_CODE-USER_BEFORE_LoanInterestProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbcLoanInterestProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbcLoanInterestProfile*/}

              <GroupBoxWidget
                conf={state.grpbcLoanInterestProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtEffectivedate*/}

                {/* END_USER_CODE-USER_BEFORE_txtEffectivedate*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEffectivedate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtEffectivedate*/}

                {/* END_USER_CODE-USER_AFTER_txtEffectivedate*/}
                {/* START_USER_CODE-USER_BEFORE_txtInterestRate*/}

                {/* END_USER_CODE-USER_BEFORE_txtInterestRate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInterestRate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtInterestRate*/}

                {/* END_USER_CODE-USER_AFTER_txtInterestRate*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbcLoanInterestProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbcLoanInterestProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_LoanInterestProfile*/}

              {/* END_USER_CODE-USER_AFTER_LoanInterestProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_LoanInterestProfile
);
