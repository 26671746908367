/* eslint-disable*/
import React from "react";
import Modal from "react-bootstrap/Modal";
import "./Modal.scss";

const modal = props => {
  let data = props.data;
  if (!props.component) {
    return null;
  }
  let popUpClass = "";
  if(props.component.name == "ContractManagement_ContractProfile"|| "StockTransfer_StockTransfer" || "SystemMaintenanceMasterManagement_FarmProfile"||"SystemMaintenanceSpecialFunctions_Price Schedule Setup"){
    popUpClass = " newWidth"
  }
  const TargetComponent = props.component.default; //Added .default fix Popup close issue
  return (
    <Modal show={props.show} onHide={props.onHide} dialogClassName={"modal-90w"+popUpClass} backdrop="static">
       <Modal.Header closeButton id={props.component.name + "PopUp"}></Modal.Header>  {/* Removed WrappedComponent to fix Popup close issue */}
      <Modal.Body className="formHeight">
      <TargetComponent
          {...props.thisObj && {thisObj:props.thisObj}} // If {props.thisObj} is defined then open popup passing props.thisObj as props.
          data={data}
          onHide={props.onHide}
          ispopupScreen={true}
        />
      </Modal.Body>
    </Modal>
  );
};

export default modal;
