/* eslint-disable*/
import React from "react";
import SystemMaintenance_ScaleTickets from "./ScaleTickets";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ScaleTickets Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenance_ScaleTickets />);
    });
  });
  afterEach(cleanup);
  test("is ScaleTickets Loads Successfully", () => {
    expect(screen.getByText("ScaleTickets")).toBeInTheDocument;
  });
  test("Custom Test Cases for ScaleTickets", () => {
    // START_USER_CODE-USER_ScaleTickets_Custom_Test_Case
    // END_USER_CODE-USER_ScaleTickets_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenance_ScaleTickets />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("btnSelectUpload(Button Widget) Test Cases", async () => {
    const btnSelectUpload = screen.getByTestId("btnSelectUpload");
    expect(btnSelectUpload).toBeInTheDocument;
    expect(btnSelectUpload.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_btnSelectUpload")
    );
  });
  test("Custom Test Cases for btnSelectUpload", () => {
    // START_USER_CODE-USER_btnSelectUpload_TEST
    // END_USER_CODE-USER_btnSelectUpload_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxScaleTickets(GroupBox Widget) Test Cases", async () => {
    const grpbxScaleTickets = screen.getByTestId("grpbxScaleTickets");
    expect(grpbxScaleTickets.tagName).toBe("BUTTON");
    expect(grpbxScaleTickets.type).toBe("button");
    expect(grpbxScaleTickets.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxScaleTickets", () => {
    // START_USER_CODE-USER_grpbxScaleTickets_TEST
    // END_USER_CODE-USER_grpbxScaleTickets_TEST
  });
  test("grpbxWeigher(GroupBox Widget) Test Cases", async () => {
    const grpbxWeigher = screen.getByTestId("grpbxWeigher");
    expect(grpbxWeigher.tagName).toBe("BUTTON");
    expect(grpbxWeigher.type).toBe("button");
    expect(grpbxWeigher.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWeigher", () => {
    // START_USER_CODE-USER_grpbxWeigher_TEST
    // END_USER_CODE-USER_grpbxWeigher_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblBuyingPoint(Label Widget) Test Cases", async () => {
    const lblBuyingPoint = screen.getByTestId("lblBuyingPoint");
    expect(lblBuyingPoint.tagName).toBe("LABEL");
    expect(lblBuyingPoint.classList).toContain("form-label");
    expect(lblBuyingPoint.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblBuyingPoint")
    );
  });
  test("Custom Test Cases for lblBuyingPoint", () => {
    // START_USER_CODE-USER_lblBuyingPoint_TEST
    // END_USER_CODE-USER_lblBuyingPoint_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblCounty(Label Widget) Test Cases", async () => {
    const lblCounty = screen.getByTestId("lblCounty");
    expect(lblCounty.tagName).toBe("LABEL");
    expect(lblCounty.classList).toContain("form-label");
    expect(lblCounty.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblCounty")
    );
  });
  test("Custom Test Cases for lblCounty", () => {
    // START_USER_CODE-USER_lblCounty_TEST
    // END_USER_CODE-USER_lblCounty_TEST
  });
  test("lblDateFormatDT(Label Widget) Test Cases", async () => {
    const lblDateFormatDT = screen.getByTestId("lblDateFormatDT");
    expect(lblDateFormatDT.tagName).toBe("LABEL");
    expect(lblDateFormatDT.classList).toContain("form-label");
    expect(lblDateFormatDT.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblDateFormatDT")
    );
  });
  test("Custom Test Cases for lblDateFormatDT", () => {
    // START_USER_CODE-USER_lblDateFormatDT_TEST
    // END_USER_CODE-USER_lblDateFormatDT_TEST
  });
  test("lblDateFormatGT(Label Widget) Test Cases", async () => {
    const lblDateFormatGT = screen.getByTestId("lblDateFormatGT");
    expect(lblDateFormatGT.tagName).toBe("LABEL");
    expect(lblDateFormatGT.classList).toContain("form-label");
    expect(lblDateFormatGT.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblDateFormatGT")
    );
  });
  test("Custom Test Cases for lblDateFormatGT", () => {
    // START_USER_CODE-USER_lblDateFormatGT_TEST
    // END_USER_CODE-USER_lblDateFormatGT_TEST
  });
  test("lblDryTicket(Label Widget) Test Cases", async () => {
    const lblDryTicket = screen.getByTestId("lblDryTicket");
    expect(lblDryTicket.tagName).toBe("LABEL");
    expect(lblDryTicket.classList).toContain("form-label");
    expect(lblDryTicket.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblDryTicket")
    );
  });
  test("Custom Test Cases for lblDryTicket", () => {
    // START_USER_CODE-USER_lblDryTicket_TEST
    // END_USER_CODE-USER_lblDryTicket_TEST
  });
  test("lblFarm(Label Widget) Test Cases", async () => {
    const lblFarm = screen.getByTestId("lblFarm");
    expect(lblFarm.tagName).toBe("LABEL");
    expect(lblFarm.classList).toContain("form-label");
    expect(lblFarm.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblFarm")
    );
  });
  test("Custom Test Cases for lblFarm", () => {
    // START_USER_CODE-USER_lblFarm_TEST
    // END_USER_CODE-USER_lblFarm_TEST
  });
  test("lblFarmSuffix(Label Widget) Test Cases", async () => {
    const lblFarmSuffix = screen.getByTestId("lblFarmSuffix");
    expect(lblFarmSuffix.tagName).toBe("LABEL");
    expect(lblFarmSuffix.classList).toContain("form-label");
    expect(lblFarmSuffix.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblFarmSuffix")
    );
  });
  test("Custom Test Cases for lblFarmSuffix", () => {
    // START_USER_CODE-USER_lblFarmSuffix_TEST
    // END_USER_CODE-USER_lblFarmSuffix_TEST
  });
  test("lblFV95(Label Widget) Test Cases", async () => {
    const lblFV95 = screen.getByTestId("lblFV95");
    expect(lblFV95.tagName).toBe("LABEL");
    expect(lblFV95.classList).toContain("form-label");
    expect(lblFV95.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblFV95")
    );
  });
  test("Custom Test Cases for lblFV95", () => {
    // START_USER_CODE-USER_lblFV95_TEST
    // END_USER_CODE-USER_lblFV95_TEST
  });
  test("lblGreenTicket(Label Widget) Test Cases", async () => {
    const lblGreenTicket = screen.getByTestId("lblGreenTicket");
    expect(lblGreenTicket.tagName).toBe("LABEL");
    expect(lblGreenTicket.classList).toContain("form-label");
    expect(lblGreenTicket.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblGreenTicket")
    );
  });
  test("Custom Test Cases for lblGreenTicket", () => {
    // START_USER_CODE-USER_lblGreenTicket_TEST
    // END_USER_CODE-USER_lblGreenTicket_TEST
  });
  test("lblHMCIND(Label Widget) Test Cases", async () => {
    const lblHMCIND = screen.getByTestId("lblHMCIND");
    expect(lblHMCIND.tagName).toBe("LABEL");
    expect(lblHMCIND.classList).toContain("form-label");
    expect(lblHMCIND.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblHMCIND")
    );
  });
  test("Custom Test Cases for lblHMCIND", () => {
    // START_USER_CODE-USER_lblHMCIND_TEST
    // END_USER_CODE-USER_lblHMCIND_TEST
  });
  test("lblState(Label Widget) Test Cases", async () => {
    const lblState = screen.getByTestId("lblState");
    expect(lblState.tagName).toBe("LABEL");
    expect(lblState.classList).toContain("form-label");
    expect(lblState.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblState")
    );
  });
  test("Custom Test Cases for lblState", () => {
    // START_USER_CODE-USER_lblState_TEST
    // END_USER_CODE-USER_lblState_TEST
  });
  test("lblTicketUpload(Label Widget) Test Cases", async () => {
    const lblTicketUpload = screen.getByTestId("lblTicketUpload");
    expect(lblTicketUpload.tagName).toBe("LABEL");
    expect(lblTicketUpload.classList).toContain("form-label");
    expect(lblTicketUpload.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblTicketUpload")
    );
  });
  test("Custom Test Cases for lblTicketUpload", () => {
    // START_USER_CODE-USER_lblTicketUpload_TEST
    // END_USER_CODE-USER_lblTicketUpload_TEST
  });
  test("lblUploadSelect(Label Widget) Test Cases", async () => {
    const lblUploadSelect = screen.getByTestId("lblUploadSelect");
    expect(lblUploadSelect.tagName).toBe("LABEL");
    expect(lblUploadSelect.classList).toContain("form-label");
    expect(lblUploadSelect.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblUploadSelect")
    );
  });
  test("Custom Test Cases for lblUploadSelect", () => {
    // START_USER_CODE-USER_lblUploadSelect_TEST
    // END_USER_CODE-USER_lblUploadSelect_TEST
  });
  test("lblWeigher(Label Widget) Test Cases", async () => {
    const lblWeigher = screen.getByTestId("lblWeigher");
    expect(lblWeigher.tagName).toBe("LABEL");
    expect(lblWeigher.classList).toContain("form-label");
    expect(lblWeigher.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_lblWeigher")
    );
  });
  test("Custom Test Cases for lblWeigher", () => {
    // START_USER_CODE-USER_lblWeigher_TEST
    // END_USER_CODE-USER_lblWeigher_TEST
  });
  test("txtCarrierDT(Textbox Widget) Test Cases", async () => {
    const txtCarrierDT = screen.getByTestId("txtCarrierDT");
    expect(txtCarrierDT.tagName).toBe("INPUT");
    expect(txtCarrierDT.type).toBe("text");
    expect(txtCarrierDT.classList).toContain("textboxWidgetClass");
    expect(txtCarrierDT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtCarrierDT")
    );
    await act(async () => {
      userEvent.type(txtCarrierDT, "1");
    });
  });
  test("Custom Test Cases for txtCarrierDT", () => {
    // START_USER_CODE-USER_txtCarrierDT_TEST
    // END_USER_CODE-USER_txtCarrierDT_TEST
  });
  test("txtCarrierGT(Textbox Widget) Test Cases", async () => {
    const txtCarrierGT = screen.getByTestId("txtCarrierGT");
    expect(txtCarrierGT.tagName).toBe("INPUT");
    expect(txtCarrierGT.type).toBe("text");
    expect(txtCarrierGT.classList).toContain("textboxWidgetClass");
    expect(txtCarrierGT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtCarrierGT")
    );
    await act(async () => {
      userEvent.type(txtCarrierGT, "1");
    });
  });
  test("Custom Test Cases for txtCarrierGT", () => {
    // START_USER_CODE-USER_txtCarrierGT_TEST
    // END_USER_CODE-USER_txtCarrierGT_TEST
  });
  test("txtDateDT(Textbox Widget) Test Cases", async () => {
    const txtDateDT = screen.getByTestId("txtDateDT");
    expect(txtDateDT.tagName).toBe("INPUT");
    expect(txtDateDT.type).toBe("text");
    expect(txtDateDT.classList).toContain("textboxWidgetClass");
    expect(txtDateDT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtDateDT")
    );
    await act(async () => {
      userEvent.type(txtDateDT, "1");
    });
  });
  test("Custom Test Cases for txtDateDT", () => {
    // START_USER_CODE-USER_txtDateDT_TEST
    // END_USER_CODE-USER_txtDateDT_TEST
  });
  test("txtDateGT(Textbox Widget) Test Cases", async () => {
    const txtDateGT = screen.getByTestId("txtDateGT");
    expect(txtDateGT.tagName).toBe("INPUT");
    expect(txtDateGT.type).toBe("text");
    expect(txtDateGT.classList).toContain("textboxWidgetClass");
    expect(txtDateGT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtDateGT")
    );
    await act(async () => {
      userEvent.type(txtDateGT, "1");
    });
  });
  test("Custom Test Cases for txtDateGT", () => {
    // START_USER_CODE-USER_txtDateGT_TEST
    // END_USER_CODE-USER_txtDateGT_TEST
  });
  test("txtDiapatchDT(Textbox Widget) Test Cases", async () => {
    const txtDiapatchDT = screen.getByTestId("txtDiapatchDT");
    expect(txtDiapatchDT.tagName).toBe("INPUT");
    expect(txtDiapatchDT.type).toBe("text");
    expect(txtDiapatchDT.classList).toContain("textboxWidgetClass");
    expect(txtDiapatchDT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtDiapatchDT")
    );
    await act(async () => {
      userEvent.type(txtDiapatchDT, "1");
    });
  });
  test("Custom Test Cases for txtDiapatchDT", () => {
    // START_USER_CODE-USER_txtDiapatchDT_TEST
    // END_USER_CODE-USER_txtDiapatchDT_TEST
  });
  test("txtDispatchGT(Textbox Widget) Test Cases", async () => {
    const txtDispatchGT = screen.getByTestId("txtDispatchGT");
    expect(txtDispatchGT.tagName).toBe("INPUT");
    expect(txtDispatchGT.type).toBe("text");
    expect(txtDispatchGT.classList).toContain("textboxWidgetClass");
    expect(txtDispatchGT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtDispatchGT")
    );
    await act(async () => {
      userEvent.type(txtDispatchGT, "1");
    });
  });
  test("Custom Test Cases for txtDispatchGT", () => {
    // START_USER_CODE-USER_txtDispatchGT_TEST
    // END_USER_CODE-USER_txtDispatchGT_TEST
  });
  test("txtVehicleNumDT(Textbox Widget) Test Cases", async () => {
    const txtVehicleNumDT = screen.getByTestId("txtVehicleNumDT");
    expect(txtVehicleNumDT.tagName).toBe("INPUT");
    expect(txtVehicleNumDT.type).toBe("text");
    expect(txtVehicleNumDT.classList).toContain("textboxWidgetClass");
    expect(txtVehicleNumDT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtVehicleNumDT")
    );
    await act(async () => {
      userEvent.type(txtVehicleNumDT, "1");
    });
  });
  test("Custom Test Cases for txtVehicleNumDT", () => {
    // START_USER_CODE-USER_txtVehicleNumDT_TEST
    // END_USER_CODE-USER_txtVehicleNumDT_TEST
  });
  test("txtVehivleNumGT(Textbox Widget) Test Cases", async () => {
    const txtVehivleNumGT = screen.getByTestId("txtVehivleNumGT");
    expect(txtVehivleNumGT.tagName).toBe("INPUT");
    expect(txtVehivleNumGT.type).toBe("text");
    expect(txtVehivleNumGT.classList).toContain("textboxWidgetClass");
    expect(txtVehivleNumGT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtVehivleNumGT")
    );
    await act(async () => {
      userEvent.type(txtVehivleNumGT, "1");
    });
  });
  test("Custom Test Cases for txtVehivleNumGT", () => {
    // START_USER_CODE-USER_txtVehivleNumGT_TEST
    // END_USER_CODE-USER_txtVehivleNumGT_TEST
  });
  test("txtWeightTicketDT(Textbox Widget) Test Cases", async () => {
    const txtWeightTicketDT = screen.getByTestId("txtWeightTicketDT");
    expect(txtWeightTicketDT.tagName).toBe("INPUT");
    expect(txtWeightTicketDT.type).toBe("text");
    expect(txtWeightTicketDT.classList).toContain("textboxWidgetClass");
    expect(txtWeightTicketDT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtWeightTicketDT")
    );
    await act(async () => {
      userEvent.type(txtWeightTicketDT, "1");
    });
  });
  test("Custom Test Cases for txtWeightTicketDT", () => {
    // START_USER_CODE-USER_txtWeightTicketDT_TEST
    // END_USER_CODE-USER_txtWeightTicketDT_TEST
  });
  test("txtWeightTicketGT(Textbox Widget) Test Cases", async () => {
    const txtWeightTicketGT = screen.getByTestId("txtWeightTicketGT");
    expect(txtWeightTicketGT.tagName).toBe("INPUT");
    expect(txtWeightTicketGT.type).toBe("text");
    expect(txtWeightTicketGT.classList).toContain("textboxWidgetClass");
    expect(txtWeightTicketGT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtWeightTicketGT")
    );
    await act(async () => {
      userEvent.type(txtWeightTicketGT, "1");
    });
  });
  test("Custom Test Cases for txtWeightTicketGT", () => {
    // START_USER_CODE-USER_txtWeightTicketGT_TEST
    // END_USER_CODE-USER_txtWeightTicketGT_TEST
  });
  test("txtWtIncludingVehicleDT(Textbox Widget) Test Cases", async () => {
    const txtWtIncludingVehicleDT = screen.getByTestId(
      "txtWtIncludingVehicleDT"
    );
    expect(txtWtIncludingVehicleDT.tagName).toBe("INPUT");
    expect(txtWtIncludingVehicleDT.type).toBe("text");
    expect(txtWtIncludingVehicleDT.classList).toContain("textboxWidgetClass");
    expect(txtWtIncludingVehicleDT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtWtIncludingVehicleDT")
    );
    await act(async () => {
      userEvent.type(txtWtIncludingVehicleDT, "1");
    });
  });
  test("Custom Test Cases for txtWtIncludingVehicleDT", () => {
    // START_USER_CODE-USER_txtWtIncludingVehicleDT_TEST
    // END_USER_CODE-USER_txtWtIncludingVehicleDT_TEST
  });
  test("txtWtIncludingVehicleGT(Textbox Widget) Test Cases", async () => {
    const txtWtIncludingVehicleGT = screen.getByTestId(
      "txtWtIncludingVehicleGT"
    );
    expect(txtWtIncludingVehicleGT.tagName).toBe("INPUT");
    expect(txtWtIncludingVehicleGT.type).toBe("text");
    expect(txtWtIncludingVehicleGT.classList).toContain("textboxWidgetClass");
    expect(txtWtIncludingVehicleGT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtWtIncludingVehicleGT")
    );
    await act(async () => {
      userEvent.type(txtWtIncludingVehicleGT, "1");
    });
  });
  test("Custom Test Cases for txtWtIncludingVehicleGT", () => {
    // START_USER_CODE-USER_txtWtIncludingVehicleGT_TEST
    // END_USER_CODE-USER_txtWtIncludingVehicleGT_TEST
  });
  test("txtWtOfPeanutsDT(Textbox Widget) Test Cases", async () => {
    const txtWtOfPeanutsDT = screen.getByTestId("txtWtOfPeanutsDT");
    expect(txtWtOfPeanutsDT.tagName).toBe("INPUT");
    expect(txtWtOfPeanutsDT.type).toBe("text");
    expect(txtWtOfPeanutsDT.classList).toContain("textboxWidgetClass");
    expect(txtWtOfPeanutsDT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtWtOfPeanutsDT")
    );
    await act(async () => {
      userEvent.type(txtWtOfPeanutsDT, "1");
    });
  });
  test("Custom Test Cases for txtWtOfPeanutsDT", () => {
    // START_USER_CODE-USER_txtWtOfPeanutsDT_TEST
    // END_USER_CODE-USER_txtWtOfPeanutsDT_TEST
  });
  test("txtWtOfPeanutsGT(Textbox Widget) Test Cases", async () => {
    const txtWtOfPeanutsGT = screen.getByTestId("txtWtOfPeanutsGT");
    expect(txtWtOfPeanutsGT.tagName).toBe("INPUT");
    expect(txtWtOfPeanutsGT.type).toBe("text");
    expect(txtWtOfPeanutsGT.classList).toContain("textboxWidgetClass");
    expect(txtWtOfPeanutsGT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtWtOfPeanutsGT")
    );
    await act(async () => {
      userEvent.type(txtWtOfPeanutsGT, "1");
    });
  });
  test("Custom Test Cases for txtWtOfPeanutsGT", () => {
    // START_USER_CODE-USER_txtWtOfPeanutsGT_TEST
    // END_USER_CODE-USER_txtWtOfPeanutsGT_TEST
  });
  test("txtWtOfVehicleDT(Textbox Widget) Test Cases", async () => {
    const txtWtOfVehicleDT = screen.getByTestId("txtWtOfVehicleDT");
    expect(txtWtOfVehicleDT.tagName).toBe("INPUT");
    expect(txtWtOfVehicleDT.type).toBe("text");
    expect(txtWtOfVehicleDT.classList).toContain("textboxWidgetClass");
    expect(txtWtOfVehicleDT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtWtOfVehicleDT")
    );
    await act(async () => {
      userEvent.type(txtWtOfVehicleDT, "1");
    });
  });
  test("Custom Test Cases for txtWtOfVehicleDT", () => {
    // START_USER_CODE-USER_txtWtOfVehicleDT_TEST
    // END_USER_CODE-USER_txtWtOfVehicleDT_TEST
  });
  test("txtWtOfVehicleGT(Textbox Widget) Test Cases", async () => {
    const txtWtOfVehicleGT = screen.getByTestId("txtWtOfVehicleGT");
    expect(txtWtOfVehicleGT.tagName).toBe("INPUT");
    expect(txtWtOfVehicleGT.type).toBe("text");
    expect(txtWtOfVehicleGT.classList).toContain("textboxWidgetClass");
    expect(txtWtOfVehicleGT.previousElementSibling.textContent).toEqual(
      t("SystemMaintenance:ScaleTickets_txtWtOfVehicleGT")
    );
    await act(async () => {
      userEvent.type(txtWtOfVehicleGT, "1");
    });
  });
  test("Custom Test Cases for txtWtOfVehicleGT", () => {
    // START_USER_CODE-USER_txtWtOfVehicleGT_TEST
    // END_USER_CODE-USER_txtWtOfVehicleGT_TEST
  });
});
