/* eslint-disable*/
import React, { Component } from "react";
import classes from "./Footer.module.scss";
import {useAppContext} from "../App";
function Footer(props) {
  const context = useAppContext();
    let t = context.t;
    return (
      <div className={classes.footer1 + " bg-dark"} data-testid="Footer">
        <div className="container-fluid">
          <div className={classes["footer-bottom"]}>
            <div className={classes["pull-left"]}>
              {t(`translate:Copyright @ 2020 Tata Consultancy Services`)}
            </div>
            <div className={classes["pull-right"]}>
              <ul>
                <li>
                  <button className="button-anchor" type="button">
                    {t(`translate:Terms of Use`)}
                  </button>
                </li>
                <li>
                  <button type="button" className="button-anchor">
                    {t(`translate:Sitemap`)}
                  </button>
                </li>
                <li>
                  <button type="button" className="button-anchor">
                    {t(`translate:Browser and Display Compatibility`)}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  
}

export default Footer;
