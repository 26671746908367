/* eslint-disable*/
import React from "react";
import Settlements_TradeSettleScaleTicket from "./TradeSettleScaleTicket";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("TradeSettleScaleTicket Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettleScaleTicket />);
    });
  });
  afterEach(cleanup);
  test("is TradeSettleScaleTicket Loads Successfully", () => {
    expect(screen.getByText("TradeSettleScaleTicket")).toBeInTheDocument;
  });
  test("Custom Test Cases for TradeSettleScaleTicket", () => {
    // START_USER_CODE-USER_TradeSettleScaleTicket_Custom_Test_Case
    // END_USER_CODE-USER_TradeSettleScaleTicket_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettleScaleTicket />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("grpbxLoadInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxLoadInfo = screen.getByTestId("grpbxLoadInfo");
    expect(grpbxLoadInfo.tagName).toBe("BUTTON");
    expect(grpbxLoadInfo.type).toBe("button");
    expect(grpbxLoadInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLoadInfo", () => {
    // START_USER_CODE-USER_grpbxLoadInfo_TEST
    // END_USER_CODE-USER_grpbxLoadInfo_TEST
  });
  test("grpbxRecdWeight(GroupBox Widget) Test Cases", async () => {
    const grpbxRecdWeight = screen.getByTestId("grpbxRecdWeight");
    expect(grpbxRecdWeight.tagName).toBe("BUTTON");
    expect(grpbxRecdWeight.type).toBe("button");
    expect(grpbxRecdWeight.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRecdWeight", () => {
    // START_USER_CODE-USER_grpbxRecdWeight_TEST
    // END_USER_CODE-USER_grpbxRecdWeight_TEST
  });
  test("grpbxShippedWeight(GroupBox Widget) Test Cases", async () => {
    const grpbxShippedWeight = screen.getByTestId("grpbxShippedWeight");
    expect(grpbxShippedWeight.tagName).toBe("BUTTON");
    expect(grpbxShippedWeight.type).toBe("button");
    expect(grpbxShippedWeight.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxShippedWeight", () => {
    // START_USER_CODE-USER_grpbxShippedWeight_TEST
    // END_USER_CODE-USER_grpbxShippedWeight_TEST
  });
  test("grpbxUpdate(GroupBox Widget) Test Cases", async () => {
    const grpbxUpdate = screen.getByTestId("grpbxUpdate");
    expect(grpbxUpdate.tagName).toBe("BUTTON");
    expect(grpbxUpdate.type).toBe("button");
    expect(grpbxUpdate.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxUpdate", () => {
    // START_USER_CODE-USER_grpbxUpdate_TEST
    // END_USER_CODE-USER_grpbxUpdate_TEST
  });
  test("grpbxUpdateInventory(GroupBox Widget) Test Cases", async () => {
    const grpbxUpdateInventory = screen.getByTestId("grpbxUpdateInventory");
    expect(grpbxUpdateInventory.tagName).toBe("BUTTON");
    expect(grpbxUpdateInventory.type).toBe("button");
    expect(grpbxUpdateInventory.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxUpdateInventory", () => {
    // START_USER_CODE-USER_grpbxUpdateInventory_TEST
    // END_USER_CODE-USER_grpbxUpdateInventory_TEST
  });
  test("grpbxWeightVariance(GroupBox Widget) Test Cases", async () => {
    const grpbxWeightVariance = screen.getByTestId("grpbxWeightVariance");
    expect(grpbxWeightVariance.tagName).toBe("BUTTON");
    expect(grpbxWeightVariance.type).toBe("button");
    expect(grpbxWeightVariance.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWeightVariance", () => {
    // START_USER_CODE-USER_grpbxWeightVariance_TEST
    // END_USER_CODE-USER_grpbxWeightVariance_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByVal(Label Widget) Test Cases", async () => {
    const lblAddedByVal = screen.getByTestId("lblAddedByVal");
    expect(lblAddedByVal.tagName).toBe("LABEL");
    expect(lblAddedByVal.classList).toContain("form-label");
    expect(lblAddedByVal.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblAddedByVal")
    );
  });
  test("Custom Test Cases for lblAddedByVal", () => {
    // START_USER_CODE-USER_lblAddedByVal_TEST
    // END_USER_CODE-USER_lblAddedByVal_TEST
  });
  test("lblChngdBy(Label Widget) Test Cases", async () => {
    const lblChngdBy = screen.getByTestId("lblChngdBy");
    expect(lblChngdBy.tagName).toBe("LABEL");
    expect(lblChngdBy.classList).toContain("form-label");
    expect(lblChngdBy.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblChngdBy")
    );
  });
  test("Custom Test Cases for lblChngdBy", () => {
    // START_USER_CODE-USER_lblChngdBy_TEST
    // END_USER_CODE-USER_lblChngdBy_TEST
  });
  test("lblChngdByValue(Label Widget) Test Cases", async () => {
    const lblChngdByValue = screen.getByTestId("lblChngdByValue");
    expect(lblChngdByValue.tagName).toBe("LABEL");
    expect(lblChngdByValue.classList).toContain("form-label");
    expect(lblChngdByValue.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblChngdByValue")
    );
  });
  test("Custom Test Cases for lblChngdByValue", () => {
    // START_USER_CODE-USER_lblChngdByValue_TEST
    // END_USER_CODE-USER_lblChngdByValue_TEST
  });
  test("lblDateFormat(Label Widget) Test Cases", async () => {
    const lblDateFormat = screen.getByTestId("lblDateFormat");
    expect(lblDateFormat.tagName).toBe("LABEL");
    expect(lblDateFormat.classList).toContain("form-label");
    expect(lblDateFormat.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblDateFormat")
    );
  });
  test("Custom Test Cases for lblDateFormat", () => {
    // START_USER_CODE-USER_lblDateFormat_TEST
    // END_USER_CODE-USER_lblDateFormat_TEST
  });
  test("lblLbs(Label Widget) Test Cases", async () => {
    const lblLbs = screen.getByTestId("lblLbs");
    expect(lblLbs.tagName).toBe("LABEL");
    expect(lblLbs.classList).toContain("form-label");
    expect(lblLbs.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblLbs")
    );
  });
  test("Custom Test Cases for lblLbs", () => {
    // START_USER_CODE-USER_lblLbs_TEST
    // END_USER_CODE-USER_lblLbs_TEST
  });
  test("lblLoadInfo(Label Widget) Test Cases", async () => {
    const lblLoadInfo = screen.getByTestId("lblLoadInfo");
    expect(lblLoadInfo.tagName).toBe("LABEL");
    expect(lblLoadInfo.classList).toContain("form-label");
    expect(lblLoadInfo.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblLoadInfo")
    );
  });
  test("Custom Test Cases for lblLoadInfo", () => {
    // START_USER_CODE-USER_lblLoadInfo_TEST
    // END_USER_CODE-USER_lblLoadInfo_TEST
  });
  test("lblPeanutVal(Label Widget) Test Cases", async () => {
    const lblPeanutVal = screen.getByTestId("lblPeanutVal");
    expect(lblPeanutVal.tagName).toBe("LABEL");
    expect(lblPeanutVal.classList).toContain("form-label");
    expect(lblPeanutVal.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblPeanutVal")
    );
  });
  test("Custom Test Cases for lblPeanutVal", () => {
    // START_USER_CODE-USER_lblPeanutVal_TEST
    // END_USER_CODE-USER_lblPeanutVal_TEST
  });
  test("lblPnut(Label Widget) Test Cases", async () => {
    const lblPnut = screen.getByTestId("lblPnut");
    expect(lblPnut.tagName).toBe("LABEL");
    expect(lblPnut.classList).toContain("form-label");
    expect(lblPnut.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblPnut")
    );
  });
  test("Custom Test Cases for lblPnut", () => {
    // START_USER_CODE-USER_lblPnut_TEST
    // END_USER_CODE-USER_lblPnut_TEST
  });
  test("lblPnuts(Label Widget) Test Cases", async () => {
    const lblPnuts = screen.getByTestId("lblPnuts");
    expect(lblPnuts.tagName).toBe("LABEL");
    expect(lblPnuts.classList).toContain("form-label");
    expect(lblPnuts.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblPnuts")
    );
  });
  test("Custom Test Cases for lblPnuts", () => {
    // START_USER_CODE-USER_lblPnuts_TEST
    // END_USER_CODE-USER_lblPnuts_TEST
  });
  test("lblPnutVal(Label Widget) Test Cases", async () => {
    const lblPnutVal = screen.getByTestId("lblPnutVal");
    expect(lblPnutVal.tagName).toBe("LABEL");
    expect(lblPnutVal.classList).toContain("form-label");
    expect(lblPnutVal.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblPnutVal")
    );
  });
  test("Custom Test Cases for lblPnutVal", () => {
    // START_USER_CODE-USER_lblPnutVal_TEST
    // END_USER_CODE-USER_lblPnutVal_TEST
  });
  test("lblRecdDateFormat(Label Widget) Test Cases", async () => {
    const lblRecdDateFormat = screen.getByTestId("lblRecdDateFormat");
    expect(lblRecdDateFormat.tagName).toBe("LABEL");
    expect(lblRecdDateFormat.classList).toContain("form-label");
    expect(lblRecdDateFormat.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblRecdDateFormat")
    );
  });
  test("Custom Test Cases for lblRecdDateFormat", () => {
    // START_USER_CODE-USER_lblRecdDateFormat_TEST
    // END_USER_CODE-USER_lblRecdDateFormat_TEST
  });
  test("lblRecdSplitWeight(Label Widget) Test Cases", async () => {
    const lblRecdSplitWeight = screen.getByTestId("lblRecdSplitWeight");
    expect(lblRecdSplitWeight.tagName).toBe("LABEL");
    expect(lblRecdSplitWeight.classList).toContain("form-label");
    expect(lblRecdSplitWeight.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblRecdSplitWeight")
    );
  });
  test("Custom Test Cases for lblRecdSplitWeight", () => {
    // START_USER_CODE-USER_lblRecdSplitWeight_TEST
    // END_USER_CODE-USER_lblRecdSplitWeight_TEST
  });
  test("lblRecdWeight(Label Widget) Test Cases", async () => {
    const lblRecdWeight = screen.getByTestId("lblRecdWeight");
    expect(lblRecdWeight.tagName).toBe("LABEL");
    expect(lblRecdWeight.classList).toContain("form-label");
    expect(lblRecdWeight.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblRecdWeight")
    );
  });
  test("Custom Test Cases for lblRecdWeight", () => {
    // START_USER_CODE-USER_lblRecdWeight_TEST
    // END_USER_CODE-USER_lblRecdWeight_TEST
  });
  test("lblShippedWight(Label Widget) Test Cases", async () => {
    const lblShippedWight = screen.getByTestId("lblShippedWight");
    expect(lblShippedWight.tagName).toBe("LABEL");
    expect(lblShippedWight.classList).toContain("form-label");
    expect(lblShippedWight.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblShippedWight")
    );
  });
  test("Custom Test Cases for lblShippedWight", () => {
    // START_USER_CODE-USER_lblShippedWight_TEST
    // END_USER_CODE-USER_lblShippedWight_TEST
  });
  test("lblSpltWght(Label Widget) Test Cases", async () => {
    const lblSpltWght = screen.getByTestId("lblSpltWght");
    expect(lblSpltWght.tagName).toBe("LABEL");
    expect(lblSpltWght.classList).toContain("form-label");
    expect(lblSpltWght.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblSpltWght")
    );
  });
  test("Custom Test Cases for lblSpltWght", () => {
    // START_USER_CODE-USER_lblSpltWght_TEST
    // END_USER_CODE-USER_lblSpltWght_TEST
  });
  test("lblUpdateInventory(Label Widget) Test Cases", async () => {
    const lblUpdateInventory = screen.getByTestId("lblUpdateInventory");
    expect(lblUpdateInventory.tagName).toBe("LABEL");
    expect(lblUpdateInventory.classList).toContain("form-label");
    expect(lblUpdateInventory.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_lblUpdateInventory")
    );
  });
  test("Custom Test Cases for lblUpdateInventory", () => {
    // START_USER_CODE-USER_lblUpdateInventory_TEST
    // END_USER_CODE-USER_lblUpdateInventory_TEST
  });
  test("txtDriver(Textbox Widget) Test Cases", async () => {
    const txtDriver = screen.getByTestId("txtDriver");
    expect(txtDriver.tagName).toBe("INPUT");
    expect(txtDriver.type).toBe("text");
    expect(txtDriver.classList).toContain("textboxWidgetClass");
    expect(txtDriver.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtDriver")
    );
    await act(async () => {
      userEvent.type(txtDriver, "1");
    });
  });
  test("Custom Test Cases for txtDriver", () => {
    // START_USER_CODE-USER_txtDriver_TEST
    // END_USER_CODE-USER_txtDriver_TEST
  });
  test("txtIncludingVehicle(Textbox Widget) Test Cases", async () => {
    const txtIncludingVehicle = screen.getByTestId("txtIncludingVehicle");
    expect(txtIncludingVehicle.tagName).toBe("INPUT");
    expect(txtIncludingVehicle.type).toBe("text");
    expect(txtIncludingVehicle.classList).toContain("textboxWidgetClass");
    expect(txtIncludingVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtIncludingVehicle")
    );
    await act(async () => {
      userEvent.type(txtIncludingVehicle, "1");
    });
  });
  test("Custom Test Cases for txtIncludingVehicle", () => {
    // START_USER_CODE-USER_txtIncludingVehicle_TEST
    // END_USER_CODE-USER_txtIncludingVehicle_TEST
  });
  test("txtLoad(Textbox Widget) Test Cases", async () => {
    const txtLoad = screen.getByTestId("txtLoad");
    expect(txtLoad.tagName).toBe("INPUT");
    expect(txtLoad.type).toBe("text");
    expect(txtLoad.classList).toContain("textboxWidgetClass");
    expect(txtLoad.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtLoad")
    );
    await act(async () => {
      userEvent.type(txtLoad, "1");
    });
  });
  test("Custom Test Cases for txtLoad", () => {
    // START_USER_CODE-USER_txtLoad_TEST
    // END_USER_CODE-USER_txtLoad_TEST
  });
  test("txtOtherWt(Textbox Widget) Test Cases", async () => {
    const txtOtherWt = screen.getByTestId("txtOtherWt");
    expect(txtOtherWt.tagName).toBe("INPUT");
    expect(txtOtherWt.type).toBe("text");
    expect(txtOtherWt.classList).toContain("textboxWidgetClass");
    expect(txtOtherWt.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtOtherWt")
    );
    await act(async () => {
      userEvent.type(txtOtherWt, "1");
    });
  });
  test("Custom Test Cases for txtOtherWt", () => {
    // START_USER_CODE-USER_txtOtherWt_TEST
    // END_USER_CODE-USER_txtOtherWt_TEST
  });
  test("txtRecdDate(Textbox Widget) Test Cases", async () => {
    const txtRecdDate = screen.getByTestId("txtRecdDate");
    expect(txtRecdDate.tagName).toBe("INPUT");
    expect(txtRecdDate.type).toBe("text");
    expect(txtRecdDate.classList).toContain("textboxWidgetClass");
    expect(txtRecdDate.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtRecdDate")
    );
    await act(async () => {
      userEvent.type(txtRecdDate, "1");
    });
  });
  test("Custom Test Cases for txtRecdDate", () => {
    // START_USER_CODE-USER_txtRecdDate_TEST
    // END_USER_CODE-USER_txtRecdDate_TEST
  });
  test("txtRecdScaleTicket(Textbox Widget) Test Cases", async () => {
    const txtRecdScaleTicket = screen.getByTestId("txtRecdScaleTicket");
    expect(txtRecdScaleTicket.tagName).toBe("INPUT");
    expect(txtRecdScaleTicket.type).toBe("text");
    expect(txtRecdScaleTicket.classList).toContain("textboxWidgetClass");
    expect(txtRecdScaleTicket.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtRecdScaleTicket")
    );
    await act(async () => {
      userEvent.type(txtRecdScaleTicket, "1");
    });
  });
  test("Custom Test Cases for txtRecdScaleTicket", () => {
    // START_USER_CODE-USER_txtRecdScaleTicket_TEST
    // END_USER_CODE-USER_txtRecdScaleTicket_TEST
  });
  test("txtRecdWtIncVehicle(Textbox Widget) Test Cases", async () => {
    const txtRecdWtIncVehicle = screen.getByTestId("txtRecdWtIncVehicle");
    expect(txtRecdWtIncVehicle.tagName).toBe("INPUT");
    expect(txtRecdWtIncVehicle.type).toBe("text");
    expect(txtRecdWtIncVehicle.classList).toContain("textboxWidgetClass");
    expect(txtRecdWtIncVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtRecdWtIncVehicle")
    );
    await act(async () => {
      userEvent.type(txtRecdWtIncVehicle, "1");
    });
  });
  test("Custom Test Cases for txtRecdWtIncVehicle", () => {
    // START_USER_CODE-USER_txtRecdWtIncVehicle_TEST
    // END_USER_CODE-USER_txtRecdWtIncVehicle_TEST
  });
  test("txtRecdWtVehicle(Textbox Widget) Test Cases", async () => {
    const txtRecdWtVehicle = screen.getByTestId("txtRecdWtVehicle");
    expect(txtRecdWtVehicle.tagName).toBe("INPUT");
    expect(txtRecdWtVehicle.type).toBe("text");
    expect(txtRecdWtVehicle.classList).toContain("textboxWidgetClass");
    expect(txtRecdWtVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtRecdWtVehicle")
    );
    await act(async () => {
      userEvent.type(txtRecdWtVehicle, "1");
    });
  });
  test("Custom Test Cases for txtRecdWtVehicle", () => {
    // START_USER_CODE-USER_txtRecdWtVehicle_TEST
    // END_USER_CODE-USER_txtRecdWtVehicle_TEST
  });
  test("txtScaleTicket(Textbox Widget) Test Cases", async () => {
    const txtScaleTicket = screen.getByTestId("txtScaleTicket");
    expect(txtScaleTicket.tagName).toBe("INPUT");
    expect(txtScaleTicket.type).toBe("text");
    expect(txtScaleTicket.classList).toContain("textboxWidgetClass");
    expect(txtScaleTicket.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtScaleTicket")
    );
    await act(async () => {
      userEvent.type(txtScaleTicket, "1");
    });
  });
  test("Custom Test Cases for txtScaleTicket", () => {
    // START_USER_CODE-USER_txtScaleTicket_TEST
    // END_USER_CODE-USER_txtScaleTicket_TEST
  });
  test("txtShippingDate(Textbox Widget) Test Cases", async () => {
    const txtShippingDate = screen.getByTestId("txtShippingDate");
    expect(txtShippingDate.tagName).toBe("INPUT");
    expect(txtShippingDate.type).toBe("text");
    expect(txtShippingDate.classList).toContain("textboxWidgetClass");
    expect(txtShippingDate.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtShippingDate")
    );
    await act(async () => {
      userEvent.type(txtShippingDate, "1");
    });
  });
  test("Custom Test Cases for txtShippingDate", () => {
    // START_USER_CODE-USER_txtShippingDate_TEST
    // END_USER_CODE-USER_txtShippingDate_TEST
  });
  test("txtTruckLine(Textbox Widget) Test Cases", async () => {
    const txtTruckLine = screen.getByTestId("txtTruckLine");
    expect(txtTruckLine.tagName).toBe("INPUT");
    expect(txtTruckLine.type).toBe("text");
    expect(txtTruckLine.classList).toContain("textboxWidgetClass");
    expect(txtTruckLine.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtTruckLine")
    );
    await act(async () => {
      userEvent.type(txtTruckLine, "1");
    });
  });
  test("Custom Test Cases for txtTruckLine", () => {
    // START_USER_CODE-USER_txtTruckLine_TEST
    // END_USER_CODE-USER_txtTruckLine_TEST
  });
  test("txtVehicle(Textbox Widget) Test Cases", async () => {
    const txtVehicle = screen.getByTestId("txtVehicle");
    expect(txtVehicle.tagName).toBe("INPUT");
    expect(txtVehicle.type).toBe("text");
    expect(txtVehicle.classList).toContain("textboxWidgetClass");
    expect(txtVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtVehicle")
    );
    await act(async () => {
      userEvent.type(txtVehicle, "1");
    });
  });
  test("Custom Test Cases for txtVehicle", () => {
    // START_USER_CODE-USER_txtVehicle_TEST
    // END_USER_CODE-USER_txtVehicle_TEST
  });
  test("txtWtOfVehicle(Textbox Widget) Test Cases", async () => {
    const txtWtOfVehicle = screen.getByTestId("txtWtOfVehicle");
    expect(txtWtOfVehicle.tagName).toBe("INPUT");
    expect(txtWtOfVehicle.type).toBe("text");
    expect(txtWtOfVehicle.classList).toContain("textboxWidgetClass");
    expect(txtWtOfVehicle.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtWtOfVehicle")
    );
    await act(async () => {
      userEvent.type(txtWtOfVehicle, "1");
    });
  });
  test("Custom Test Cases for txtWtOfVehicle", () => {
    // START_USER_CODE-USER_txtWtOfVehicle_TEST
    // END_USER_CODE-USER_txtWtOfVehicle_TEST
  });
  test("txtWtVariance(Textbox Widget) Test Cases", async () => {
    const txtWtVariance = screen.getByTestId("txtWtVariance");
    expect(txtWtVariance.tagName).toBe("INPUT");
    expect(txtWtVariance.type).toBe("text");
    expect(txtWtVariance.classList).toContain("textboxWidgetClass");
    expect(txtWtVariance.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleScaleTicket_txtWtVariance")
    );
    await act(async () => {
      userEvent.type(txtWtVariance, "1");
    });
  });
  test("Custom Test Cases for txtWtVariance", () => {
    // START_USER_CODE-USER_txtWtVariance_TEST
    // END_USER_CODE-USER_txtWtVariance_TEST
  });
});
