/* istanbul ignore file */
import React from "react";
import collect from "collect.js";
import { isEmptyObject, getBase64FileType } from "./ScreenInitialization";
import moment from "moment";
import axios from "axios";
import GetMessage from "./GetMessage";
import $ from "jquery";
var DotObject = require("dot-object");
const _kaledo = window._kaledo;
//var thisObj={};
export async function callService(thisObj1,event) {
  let thisObj=thisObj1;
  let typeOfEvent = event.type;
  let widgetId = event.target.id;
  if (widgetId.indexOf(".") !== -1) {
    let widgetIdArr = widgetId.split(".");
	let gridWidgetId = widgetIdArr[0];
	if (thisObj.state[gridWidgetId]) {
	  let type = thisObj.state[gridWidgetId].type;
	  if (type === "GridWidget") {
	    widgetId = widgetIdArr[2];
	  }
	}
  }

  if (typeOfEvent !== "PagerORsort" && typeOfEvent !== "RowSelect" && typeOfEvent !== "NodeClick") {
	typeOfEvent = toTitleCase(typeOfEvent);
  }
  
  if(thisObj.state[widgetId]){
	  thisObj.eventInfo = {
		 type: typeOfEvent,
	     target: widgetId
	  };
	  thisObj.state[widgetId][typeOfEvent+'Event'] = event;
  }
  if (!(executePreSubmit( thisObj,typeOfEvent, widgetId,event))) {//await is removed as it throws some error
    return;
  }
  if (callCloseORCancel( thisObj,widgetId, typeOfEvent)) {
    return;
  }
  if (
    thisObj.isValid ||
    typeOfEvent === "PagerORsort" ||
    typeOfEvent === "RowSelect" ||
    typeOfEvent === "Change"
  ) {
    if ((
      thisObj._buttonServices &&
      thisObj._buttonServices[widgetId] &&
      thisObj._buttonServices[widgetId].DEFAULT &&
      thisObj._buttonServices[widgetId].DEFAULT.length
      )|| (thisObj._buttonServices &&thisObj._buttonServices[widgetId+"_NodeClick"]&&
      thisObj._buttonServices[widgetId+"_NodeClick"].DEFAULT&&thisObj._buttonServices[widgetId+"_NodeClick"].DEFAULT.length)) {
      let serviceArr=[];
      if(thisObj._buttonServices[widgetId+"_NodeClick"]){
         serviceArr = thisObj._buttonServices[widgetId+"_NodeClick"].DEFAULT;
      }
      else
       serviceArr = thisObj._buttonServices[widgetId].DEFAULT;
      let widgetObj = getWidgetObj(thisObj,widgetId);
      if (widgetObj.isSynchronous) {
        prepareAndCallService(
          thisObj,
          typeOfEvent,
          widgetId,
          serviceArr,
          0,
          widgetObj.isSynchronous
        );
      } else {
        for (let i = 0; i < serviceArr.length; i++) {
          let isContinue = prepareAndCallService(
            thisObj,
            typeOfEvent,
            widgetId,
            serviceArr,
            i,
            widgetObj.isSynchronous
          );
          if (isContinue) {
            continue;
          }
        }
      }
    } else if ((
      thisObj._buttonNavigation &&
      thisObj._buttonNavigation[widgetId] &&
      thisObj._buttonNavigation[widgetId]["DEFAULT"] &&
      thisObj._buttonNavigation[widgetId]["DEFAULT"].length
    )||(thisObj._buttonNavigation &&
      thisObj._buttonNavigation[widgetId+"_NodeClick"] && thisObj._buttonNavigation[widgetId+"_NodeClick"]["DEFAULT"]&&thisObj._buttonNavigation[widgetId+"_NodeClick"]["DEFAULT"].length )) {
        if(thisObj._buttonNavigation[widgetId+"_NodeClick"]&& thisObj._buttonNavigation[widgetId+"_NodeClick"]["DEFAULT"].length){
          thisObj.buttonNavigationHandler(thisObj,widgetId+"_NodeClick", typeOfEvent);
        }
        else
        thisObj.buttonNavigationHandler(thisObj,widgetId, typeOfEvent);
    } else if (
      typeOfEvent === "PagerORsort" &&
      getWidgetObj(thisObj,widgetId).type === "GridWidget"
    ) {
      let widgetObj = getWidgetObj(thisObj,widgetId);
      if (widgetObj.paginationService) {
        let serviceArr = [widgetObj.paginationService];
        prepareAndCallService(
          thisObj,
          typeOfEvent,
          widgetId,
          serviceArr,
          0,
          true
        );
      }
    }
  }
}

function callCloseORCancel(thisObj1,widgetId, typeOfEvent) {
  let thisObj=thisObj1;
  if (!widgetId) return;
  let widgetObj = thisObj.state[widgetId];
  if (widgetId === thisObj.state.windowName) {
    widgetObj = thisObj.state;
  }
  if (
    widgetObj.Category &&
    (widgetObj.Category === "Close" || widgetObj.Category === "Cancel")
  ) {
    thisObj.buttonNavigationHandler(thisObj,widgetId, typeOfEvent);
    return true;
  }
  return false;
}

function prepareAndCallService(thisObj1,typeOfEvent, widgetId, serviceArr, index, sync) {
  let thisObj=thisObj1;
  if (!serviceArr[index]) {
    return;
  }
  let serviceStr = serviceArr[index];
  if (serviceStr.indexOf("#") === -1) {
    return;
  }
  let lastHashIndex = serviceStr.lastIndexOf("#");
  let serviceName = serviceStr.substring(0, lastHashIndex);
  if (serviceStr.indexOf("@@@") !== -1) {
    let rmdDDDomainAndName = serviceStr.split("@@@")[1];
    var rmdDDDomain = rmdDDDomainAndName.split(":")[0];
    let rmdDDName = rmdDDDomainAndName.split(":")[1];
    let outObj = {
      returnMappingObj: {
        [rmdDDName]: [
          "returnMappingObj.optionKey",
          "returnMappingObj.optionDesc"
        ]
      }
    };

    thisObj._winServices[serviceName]["OUT"] = outObj;
    serviceStr = serviceStr.split("@@@")[0];
  }
  var eventType = serviceStr.substring(lastHashIndex + 1);
  let titledEventType = toTitleCase(typeOfEvent);

  if (titledEventType !== toTitleCase(eventType)) {
    if (sync) {
      if (serviceStr[index + 1]) {
        prepareAndCallService(
          thisObj,
          typeOfEvent,
          widgetId,
          serviceArr,
          index + 1,
          sync
        );
      } else {
        return;
      }
    } else {
      return true;
    }
  }

  // Todo Processing Text

  if (!thisObj._winServices[serviceName]) {
    thisObj.showMessage(thisObj,"SERVICE UNAVAILABLE");
    return;
  }
  let serviceInParam = thisObj._winServices[serviceName]["IN"];
  let serviceOutParam = thisObj._winServices[serviceName]["OUT"];
  var serviceInfo = thisObj._winServices[serviceName]["DESC"];
  let actForm = thisObj.state.windowName;
  let svcInData = {};

  if (serviceInfo["controller"] === "reference-meta-data") {
    if (serviceName === actForm + "#getAllOptions") {
      svcInData = {
        domainName: rmdDDDomain,
        languageId: "0"
      };
    } else if (serviceName === actForm + "#getDependentOptions") {
      let driverValue;
      if (thisObj.values[widgetId]) driverValue = thisObj.values[widgetId];
      else driverValue = thisObj.state[widgetId].value;
      driverValue = Array.isArray(driverValue) ? driverValue[0] : driverValue;
      svcInData = {
        domainName: rmdDDDomain,
        driverDomainName: thisObj.state[widgetId].key,
        driverValue: driverValue,
        languageId: "0"
      };
    }
  }

  let url = prepareURL(serviceInfo);
  updateProducesAndConsumes(serviceInfo);
  prepareServiceInputData( thisObj,serviceInParam, svcInData);

  let headers = getHeaders(serviceInfo);

  let params, requestBody;
  ({ params, requestBody, url, headers } = getRequestConfig(
    thisObj,
    serviceInParam,
    serviceOutParam,
    svcInData,
    serviceInfo,
    url,
    headers,
    widgetId
  ));

  let responsePromise = callHttpAPI(
    serviceInfo,
    params,
    url,
    requestBody,
    headers
  );

  responsePromise
    .then((response) => {
      handleResponse(
        thisObj,
        response,
        typeOfEvent,
        widgetId,
        serviceArr,
        index,
        sync,
        serviceOutParam
      );
    })
    .catch((error) => {
      handleCatch( thisObj,error);
    });
}

function handleResponse(
  thisObj1,
  response,
  typeOfEvent,
  widgetId,
  serviceArr,
  index,
  sync,
  serviceOutParam
) {
  let thisObj=thisObj1;
  let url = response.config.url;
  if (url.includes("oauth")) {
    thisObj.context.setAuthTokens(response.data);
  }
  let getMessage = <GetMessage response={response} />;
  if (response.error) {
    thisObj.showMessage(thisObj,getMessage);
    return;
  }
  if (response.data && response.data.message) {
    thisObj.showMessage(thisObj,getMessage, true);
  }
  postServiceCallProcessingForSpringBootWB(
    thisObj,
    response.data.data,
    serviceOutParam,
    typeOfEvent,
    widgetId,
    response
  );
  if (sync) {
    prepareAndCallService(
      thisObj,
      typeOfEvent,
      widgetId,
      serviceArr,
      index + 1,
      sync
    );
  }
}

function handleCatch(thisObj1,error) {
  let thisObj=thisObj1;
 let { response,message }=error;
 if(response!==undefined){
  let url = response.config.url;
  if (url.includes("oauth")) {
    if (response && response.data && response.data.error_description) {
      let error = response.data.error_description;
      if (error.includes("No value") || error.includes("Bad credentials")) {
        thisObj.showMessage(thisObj,thisObj.context.t(`AppJSMessage:INVALID_USER`));
      }
    }
  }
  let getMessage = <GetMessage response={response} />;
  if (response.error) {
    thisObj.showMessage(thisObj,getMessage);
    return;
  }
}
else{
  thisObj.showMessage(thisObj,message);
}
}

function postServiceCallProcessingForSpringBootWB(
  thisObj1,
  svcData,
  serviceOutParam,
  typeOfEvent,
  widgetId,
  response
) {
  let thisObj=thisObj1;
  let scrnObj = thisObj.state;
  let screenName = thisObj.state.windowName;
  let paginationResponse;
  if (response.config.url.includes("/reference-meta-data/"))
    svcData = response.data;
  if (svcData) {
    if (svcData.content) {
      paginationResponse = svcData;
      svcData = svcData.content;
    }
    for (let outputParamName in serviceOutParam) {
      let outputParamObj = serviceOutParam[outputParamName];
      let gridMappingCollection = collect(outputParamObj).filter(
        (value, key) => {
          return isGridColumn(key, scrnObj) && !Array.isArray(value);
        }
      );
      let gridMappingObj = gridMappingCollection.all();

      let autocompleteMappingCollection = collect(outputParamObj).filter(
        (value, key) => {
          return key.indexOf("_Options") !== -1;
        }
      );
      let autocompleteMappingObj = autocompleteMappingCollection.all();

      let selectedDescOptMappingCollection = collect(outputParamObj).filter(
        (value, key) => {
          return key.indexOf("_selectedDesc") !== -1;
        }
      );
      let selectedDescOptMappingObj = selectedDescOptMappingCollection.all();

      let wVMulYMappingCollection = collect(outputParamObj).filter(
        (value, key) => {
          return key.indexOf("#Y") !== -1;
        }
      );
      let wVMulYMappingObj = wVMulYMappingCollection.all();

      let formMappingCollection = collect(outputParamObj).filter(
        (value, key) => {
          return (
            key.indexOf("_Options") === -1 &&
            key.indexOf("_selectedDesc") === -1 &&
            (!isGridColumn(key, scrnObj) || Array.isArray(value)) &&
            key.indexOf("#Y") === -1
          );
        }
      );
      let formMappingObj = formMappingCollection.all();

      if (!isEmptyObject(formMappingObj)) {
        for (let clientKey in formMappingObj) {
          let serverKey = formMappingObj[clientKey];
          let widgetObj = scrnObj[clientKey];
          if (Array.isArray(serverKey)) {
            populateSelectableWidgetOptions(
              serverKey,
              widgetObj,
              outputParamName,
              svcData
            );
          } else {
            serverKey = serverKey.replace(outputParamName + ".", "");
            let value;
            if (typeof svcData === "object") {
              value = DotObject.pick(serverKey, svcData);
            } else {
              value = svcData;
            }
            if(widgetObj.type === "ImageWidget" && !svcData.hasOwnProperty(serverKey)){
              value = svcData;
            }
            setWidgetValue( thisObj,widgetObj, value);
          }
        }
      }

      if (!isEmptyObject(selectedDescOptMappingObj)) {
        for (let clientKey in selectedDescOptMappingObj) {
          let serverKey = selectedDescOptMappingObj[clientKey];
          clientKey = clientKey.replace("_selectedDesc", "");
          let widgetObj = scrnObj[clientKey];
          populateSelectedDesc(
            serverKey,
            widgetObj,
            outputParamName,
            svcData
          );
        }
      }

      if (!isEmptyObject(gridMappingObj)) {
        prepareGridData(
          thisObj,
          gridMappingObj,
          svcData,
          outputParamName,
          scrnObj,
          paginationResponse
        );
      }

      if (!isEmptyObject(autocompleteMappingObj)) {
        for (let clientKey in autocompleteMappingObj) {
          let serverKey = autocompleteMappingObj[clientKey];
          let widgetObj = scrnObj[clientKey.replace("_Options", "")];
          populateAutocompleteOptions(
            serverKey,
            widgetObj,
            outputParamName,
            svcData
          );
        }
      }

      if (!isEmptyObject(wVMulYMappingObj)) {
        for (let clientKey in wVMulYMappingObj) {
          let serverKey = wVMulYMappingObj[clientKey];
          serverKey = serverKey.replace(outputParamName + ".", "");
          let accessKeys = getAccessKeys(serverKey);
          let arrayAccessKey = accessKeys[0];
          let valueAccessKey = accessKeys[1];
          let wvArr;
          if (Array.isArray(svcData)) {
            wvArr = svcData;
          } else {
            wvArr = DotObject.pick(arrayAccessKey, svcData);
          }

          let valueArr = collect(wvArr).pluck(valueAccessKey).all();
          clientKey = clientKey.replace("#Y", "");
          let widgetObj = scrnObj[clientKey];
          widgetObj.value = valueArr;
        }
      }
    }
  }

  if (screenName !== widgetId && typeOfEvent !== "Open") {
    let butType;
    if (scrnObj[widgetId] && scrnObj[widgetId]["Category"]) {
      butType = scrnObj[widgetId]["Category"];
    }
    if (butType && (butType === "Close" || butType === "Cancel")) {
      if (butType === "Close") {
        thisObj.goTo(thisObj,"", "R", true);
      } else {
        thisObj.goTo(thisObj,"", "R", false);
      }
      return;
    }
  }

  let postSubRet = executePostSubmit(
    thisObj,
    typeOfEvent,
    widgetId,
    response
  );
  if (postSubRet) {
    if (
      thisObj._buttonNavigation &&
      thisObj._buttonNavigation[widgetId] &&
      thisObj._buttonNavigation[widgetId]["DEFAULT"] &&
      thisObj._buttonNavigation[widgetId]["DEFAULT"].length
    ) {
      thisObj.buttonNavigationHandler(thisObj,widgetId, typeOfEvent);
    }
  }
}

function prepareGridData(
  thisObj1,
  gridMappingObj,
  svcData,
  outputParamName,
  scrnObj,
  paginationResponse
) {
  let thisObj=thisObj1;
  let gridData = collect([]);
  let gridName;
  let gridObj;
  let gridArr=[];  //This is used in case of multiple grids mapped to the same service
  for (let clientKey in gridMappingObj) {
    let clientColName = clientKey;
    gridName = scrnObj[clientColName].parent;
    if(!gridArr.includes(gridName)) {
        gridArr.push(gridName);
      }
    gridObj = scrnObj[gridName];
    gridObj.isLoading = false;
    let serverKey = gridMappingObj[clientKey];
    serverKey = serverKey.replace(outputParamName + ".", "");

    let accessKeys = getAccessKeys(serverKey);
    let arrayAccessKey = accessKeys[0];
    let valueAccessKey = accessKeys[1];

    let gridDataArr;
    if (Array.isArray(svcData)) {
      gridDataArr = svcData;
    } else {
      gridDataArr = DotObject.pick(arrayAccessKey, svcData);
    }
    let currentKeyValueColl = collect(gridDataArr).pluck(valueAccessKey);

    let currentKeyValueArray = currentKeyValueColl.all();
    let currKeyObjArr = [];
    currentKeyValueArray.forEach((value) => {
      let currentKeyObj = {};
      value = transformValueForGrid(thisObj,scrnObj[clientColName], value);
      currentKeyObj[clientColName] = value;
      currKeyObjArr.push(currentKeyObj);
    });

    if (gridData.isEmpty()) {
      gridData = collect(currKeyObjArr);
    } else {
      gridData = gridData.map((obj, index) => {
        return { ...obj, ...currKeyObjArr[index] };
      });
    }
  }

  let rows = gridData.all();
  for(let currGrid in gridArr) {
    gridName = gridArr[currGrid];
    gridObj = scrnObj[gridName];    
    gridObj.responseData = {};
	gridObj.responseData = svcData;
	if (gridObj.Pagination || gridObj.lazyLoading) {
	  let page = gridObj.page;
	  if (rows.length > page.size) {
        gridObj.clientPagination = true;
        thisObj.setValue(thisObj,gridName, rows);
      } else {
        if (gridObj.page) {
          if (paginationResponse && paginationResponse.totalElements) {
            gridObj.totalElements = paginationResponse.totalElements;
          } else {
            gridObj.totalElements = rows.length;
          }
        }
        if (gridObj.lazyLoading) {
          rows = [...gridObj.rows, ...rows];
        }
        thisObj.setValue(thisObj,gridName, rows);
      }
    } else {
      thisObj.setValue(thisObj,gridName, rows);
    }
    scrnObj[gridName] = { ...gridObj };
  }
}

function transformValueForGrid(thisObj1,widgetObj, value) {
  let thisObj=thisObj1;
  let returnValue = null;
  if (typeof value === "undefined" || value === null) {
    return returnValue;
  }
  switch (widgetObj.type) {
    case "DateWidget":
      returnValue = moment(value, "YYYY-MM-DD");
      break;
    case "DateTimeWidget":
      returnValue = moment(value, "YYYY-MM-DDTHH:mm:ss");
      break;
    case "TimeWidget":
      returnValue = moment(value, "HH:mm:ss");
      break;
    case "FileWidget":
      setupFileWidgetValue( thisObj,value, widgetObj);
      returnValue = widgetObj.valueList;
      widgetObj.valueList = [];
      break;
    default:
      returnValue = value;
  }
  return returnValue;
}

function populateSelectedDesc(serverKey, widgetObj, outputParamName, svcData) {
  //let thisObj=thisObj1;
  serverKey = serverKey.replace(outputParamName + ".", "");
  let accessKeys = getAccessKeys(serverKey);
  let arrayAccessKey = accessKeys[0];
  let valueAccessKey = accessKeys[1];

  let serverSelectedDescArr;
  if (Array.isArray(svcData)) {
    serverSelectedDescArr = svcData;
  } else {
    serverSelectedDescArr = DotObject.pick(arrayAccessKey, svcData);
  }

  let selectedOptions = collect(serverSelectedDescArr)
    .pluck(valueAccessKey)
    .all();

  if (widgetObj.valueList && widgetObj.valueList.length) {
    let valueList = widgetObj.valueList;
    widgetObj.valueList = valueList.map((optionObj, index) => {
      return {
        selectedOptionDescription: selectedOptions[index],
        ...optionObj
      };
    });
  }
}

function populateSelectableWidgetOptions(
  serverKey,
  widgetObj,
  outputParamName,
  svcData
) {
  //let thisObj=thisObj1;
  let optionsKeyArr = [];
  let optionsDescArr = [];
  serverKey.forEach((ddOptionsKey, index) => {
    ddOptionsKey = ddOptionsKey.replace(outputParamName + ".", "");
    let accessKeys = getAccessKeys(ddOptionsKey);
    let arrayAccessKey = accessKeys[0];
    let valueAccessKey = accessKeys[1];
    let serverOptionsArr;
    if (Array.isArray(svcData)) {
      serverOptionsArr = svcData;
    } else {
      serverOptionsArr = DotObject.pick(arrayAccessKey, svcData);
    }

    let optionsArr = collect(serverOptionsArr)
      .pluck(valueAccessKey.trim())
      .all();
    if (index === 0) {
      optionsKeyArr = optionsArr;
    } else if (index === 1) {
      optionsDescArr = optionsArr;
    }
  });

  let valueList = [];
  optionsKeyArr.forEach((optionKey, index) => {
    let optionObj = {
      key: optionKey,
      description: optionsDescArr[index]
    };
    valueList.push(optionObj);
  });
  widgetObj.valueList = valueList;

  if (widgetObj.type === "ListSelectDeselectFieldWidget") {
    // let id = widgetObj.name;
    // $("#" + id).empty();
    // var optionsArr = [];
    // $.each(valueList, function(indx, val) {
    //   $("#" + id).append(
    //     "<option value='" + val.key + "'>" + val.description + "</option>"
    //   );
    //   var optionString = val.description + ":" + val.key;
    //   optionsArr.push(optionString);
    // });
    // widgetObj["Options"] = optionsArr + "";
    // $("#" + id).bootstrapDualListbox("refresh", true);
  }
}

function populateAutocompleteOptions(
  serverKey,
  widgetObj,
  outputParamName,
  svcData
) {
  //let thisObj=thisObj1;
  serverKey = serverKey.replace(outputParamName + ".", "");
  let accessKeys = getAccessKeys(serverKey);
  let arrayAccessKey = accessKeys[0];
  let valueAccessKey = accessKeys[1];
  let autoCompOptionsArr;
  if (Array.isArray(svcData)) {
    autoCompOptionsArr = svcData;
  } else {
    autoCompOptionsArr = DotObject.pick(arrayAccessKey, svcData);
  }

  let matches = autoCompOptionsArr.map((dataObj) => {
    return typeof dataObj === "string"
      ? dataObj
      : DotObject.pick(valueAccessKey, dataObj);
  });
  widgetObj.valueListForAutocomplete = matches;
}

function setb64Data(value, widgetObj) {
  if (value.fileName) {
    let fileName = value.fileName;
    let fileExtension = fileName.split(".").pop();
    let base64FileType = getBase64FileType(
      "." + fileExtension.toLowerCase()
    );

    //data:image/jpeg;base64,
    if (base64FileType.indexOf("image") !== -1) {
      //data:image/.....
      widgetObj["fileType"] = "image";
    } else if (base64FileType.indexOf("audio") !== -1) {
      //data:audio/.....
      widgetObj["fileType"] = "audio";
    } else if (base64FileType.indexOf("video") !== -1) {
      //data:video/.....
      widgetObj["fileType"] = "video";
    } else {
      widgetObj["fileType"] = "other";
    }

    widgetObj["b64fileType"] = base64FileType;
    let base64FileTypeForData = "data:" + base64FileType + ";base64,";
    widgetObj["b64data"] = base64FileTypeForData + "";
    if (value.fileData) {
      widgetObj["b64data"] = widgetObj["b64data"] + value.fileData;
    }
  }
}

function setupFileWidgetValue(thisObj1,value, widgetObj) {
  let thisObj=thisObj1;
  if (value) {
    if (Array.isArray(value)) {
      if (!widgetObj.Multiplicity) {
        if (value.length >= 1) {
          value = value.slice(0, 1);
        }
      }
      value.forEach((sFileObj, index) => {
        setFileWidgetValue(sFileObj, widgetObj);
      });
    } else {
      let sFileObj = value;
      setFileWidgetValue(sFileObj, widgetObj);
    }
    thisObj.setValue(thisObj,widgetObj.name, widgetObj.valueList);
    widgetObj["isLinkVisible"] = true;
    widgetObj["isFileVisible"] = false;
  } else {
    widgetObj["isLinkVisible"] = false;
    widgetObj["isFileVisible"] = true;
    if (widgetObj.ShowAs && widgetObj.ShowAs === "RenderOnScreen") {
      // $("#" + widgetObj.name + "_file" + 0)
      //   .parent()
      //   .addClass("renderInvisible");
    }
  }
}

function setFileWidgetValue(sFileObj, widgetObj) {
  let fileObj = {};
  if (sFileObj.fileName) {
    fileObj.value = sFileObj.fileName;
  }
  if (sFileObj.fileData) {
    fileObj.data = sFileObj.fileData;
  }
  if (widgetObj.ShowAs && widgetObj.ShowAs === "RenderOnScreen") {
    setb64Data(sFileObj, widgetObj);
    fileObj["b64data"] = widgetObj["b64data"];
    fileObj["fileType"] = widgetObj["fileType"];
    fileObj["b64fileType"] = widgetObj["b64fileType"];
  }
  if (widgetObj.valueList) {
    widgetObj.valueList.push(fileObj);
  } else {
    widgetObj.valueList = [fileObj];
  }
}

function setWidgetValue(thisObj1,widgetObj, value) {
  let thisObj=thisObj1;
  if (typeof value === "undefined" || value === null) {
    return;
  }
  let widgetName = widgetObj.name;

  switch (widgetObj.type) {
    case "DateWidget":
    case "DateTimeWidget":
    case "TimeWidget":
      thisObj.setValue(thisObj,widgetName, moment(value).toDate());
      break;
    case "TextAreaWidget":
      thisObj.setValue(thisObj,widgetName, value);
      break;
    case "ImageWidget":
      let fileObj = { fileType: "image" };
      if (value.fileName) {
        fileObj.value = value.fileName;
      }
      if (value.fileData) {
        fileObj.data = value.fileData;
      }
      if (value.fileName) {
        let fileName = value.fileName;
        let fileExtension = fileName.split(".").pop();
        let base64FileType = getBase64FileType(
          "." + fileExtension.toLowerCase()
        );
        fileObj["b64fileType"] = base64FileType;
        let base64FileTypeForData = "data:" + base64FileType + ";base64,";
        fileObj["b64data"] = base64FileTypeForData + "";
        if (value.fileData) {
          fileObj["b64data"] = fileObj["b64data"] + value.fileData;
        }
      }
      thisObj.setValue(thisObj,widgetName, [fileObj]);
      break;
    case "FileWidget":
      setupFileWidgetValue( thisObj,value, widgetObj);
      break;
    case "CheckBoxGroupFieldWidget":
      thisObj.setValue(thisObj,widgetName, value);
      break;
    case "ListBoxFieldWidget":
      let valueArr = [];
      if (typeof value == "string") {
        if (value.indexOf(",") !== -1) {
          valueArr = value.split(",");
        } else {
          valueArr.push(value);
        }
      } else if (Array.isArray(value)) {
        valueArr = value;
      }
      thisObj.setValue(thisObj,widgetName, valueArr);
      break;
    case "ListSelectDeselectFieldWidget":
      // valueArr = new Array();
      // if (typeof value == "string") {
      //   if (value.indexOf(",") != -1) {
      //     valueArr = value.split(",");
      //   } else {
      //     valueArr.push(value);
      //   }
      // } else if ($.isArray(value)) {
      //   valueArr = value;
      // }
      // widgetObj["value"] = valueArr.toString();
      // $("#" + widgetObj.name).val(valueArr);
      // (<any>$("#" + widgetObj.name)).bootstrapDualListbox("refresh", true);
      break;
    default:
      thisObj.setValue(thisObj,widgetName, value);
  }
}

function callHttpAPI(serviceInfo, params, url, requestBody, headers) {
  // Add a response interceptor

  switch (serviceInfo.type) {
    case "GET":
      return axios.get(url, { data: {}, params, headers });
    case "POST":
      return axios.post(url, requestBody, { params, headers });
    case "DELETE":
      return axios.delete(url, { data: {}, params, headers });
    case "PUT":
      return axios.put(url, requestBody, { params, headers });
    case "PATCH":
      return axios.patch(url, requestBody, {
        params,
        headers
      });
    default:
      return axios.get(url, { params, headers });
  }
}

function getRequestConfig(
  thisObj1,
  serviceInParam,
  serviceOutParam,
  svcInData,
  serviceInfo,
  url,
  headers,
  widgetId
) {
  let thisObj=thisObj1;
  let widgetObj = getWidgetObj( thisObj,widgetId);
  let inParamsArr = Object.keys(serviceInfo);
  let params = {};
  let requestBody = {};
  inParamsArr.forEach((inParam) => {
    if (typeof svcInData[inParam] === "undefined") {
      return;
    }
    let requestParamHttpType = serviceInfo[inParam];
    if (requestParamHttpType === "RequestParam") {
      params[inParam] = svcInData[inParam];
    } else if (requestParamHttpType === "PathVariable") {
      if (serviceInfo["path"].indexOf(`{${inParam}}`) !== -1) {
        url = url.replace(`{${inParam}}`, svcInData[inParam]);
      } else {
        url += "/" + svcInData[inParam];
      }
    } else if (requestParamHttpType === "RequestBody") {
      requestBody = Object.assign(requestBody, svcInData[inParam]);
    } else if (requestParamHttpType === "RequestHeader") {
      headers[inParam] = svcInData[inParam];
    }
  });

  if (serviceInfo.ownerComp === "AuthRoot") {
    requestBody.grant_type = "password";
  }

  if (serviceInfo.consumes.includes("application/x-www-form-urlencoded")) {
    requestBody = $.param(requestBody);
  }

  if (widgetObj.type && widgetObj.type === "GridWidget") {
    if (widgetObj.page) {
      params.page = widgetObj.page.pageNumber;
      params.size = widgetObj.page.size;
    }
    if (widgetObj.sort) {
      params.sort = widgetObj.sort.prop + "," + widgetObj.sort.dir;
    }
  } else {
    if (
      serviceInfo["paginationRequired"] &&
      Object.keys(serviceOutParam)[0]
    ) {
      let retObj = serviceOutParam[Object.keys(serviceOutParam)[0]];
      let child = thisObj.state[Object.keys(retObj)[0]];
      let parent = thisObj.state[child.parent];
      if (parent.type && parent.type === "GridWidget") {
        if (parent.page) {
          params.page = parent.page.pageNumber;
          params.size = parent.page.size;
        }
        if (parent.sort) {
          params.sort = parent.sort.prop + "," + parent.sort.dir;
        }
      }
    }
  }

  let encoded;
  if (_kaledo.Auth === "BASIC") {
    encoded = encode("username:password");
    headers.Authorization = "Basic " + encoded;
  } else if (_kaledo.Auth === "OAUTH2") {
    if (_kaledo.authData) {
      let authTokens = _kaledo.authData;
      headers.Authorization = "Bearer " + authTokens.access_token;
    } else {
      encoded = encode(_kaledo.AuthClientId + ":" + _kaledo.AuthClientSecret);
      headers.Authorization = "Basic " + encoded;
    }
  }

  return { params, url, requestBody, headers };
}

function getWidgetObj(thisObj1,widgetId) {
  let thisObj=thisObj1;
  if (widgetId === thisObj.state.windowName) {
    return thisObj.state;
  } else {
    return thisObj.state[widgetId];
  }
}

function encode(input) {
  let output = "";
  let chr1, chr2, chr3;
  let enc1, enc2, enc3, enc4;
  let i = 0;

  do {
    chr1 = input.charCodeAt(i++);
    chr2 = input.charCodeAt(i++);
    chr3 = input.charCodeAt(i++);

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    if (!_kaledo.keyStr) {
      _kaledo.keyStr =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    }
    output =
      output +
      _kaledo.keyStr.charAt(enc1) +
      _kaledo.keyStr.charAt(enc2) +
      _kaledo.keyStr.charAt(enc3) +
      _kaledo.keyStr.charAt(enc4);
    chr1 = chr2 = chr3 = "";
    enc1 = enc2 = enc3 = enc4 = "";
  } while (i < input.length);

  return output;
}

function getHeaders(serviceInfo) {
  let headers = {
    "Content-Type": "application/json; charset=utf-8"
  };
  if (serviceInfo.consumes) {
    headers["Content-Type"] = serviceInfo.consumes + "; charset=utf-8";
    if (
      serviceInfo.versioningRequired &&
      serviceInfo.versioningType === "Header"
    ) {
      headers["X-API-Version"] = serviceInfo.versioningValue;
    }
  }
  if (serviceInfo.produces) {
    headers["Accept"] = serviceInfo.produces;
    headers["Accept-Language"] = "en-US";
  }
  if (serviceInfo.cache) {
    headers["X-Request-Cache"] = "true";
  }

  return headers;
}

function prepareURL(serviceInfo) {
  let url = _kaledo.compRoot[serviceInfo.ownerComp];
  if (serviceInfo.controller && serviceInfo.controller.trim()) {
    url += serviceInfo.controller.trim() + "/";
  }
  if (serviceInfo.versioningRequired) {
    if (serviceInfo.versioningType === "URI" && serviceInfo.versioningValue) {
      url += serviceInfo.versioningValue + "/";
    }
  }
  if (serviceInfo.path) {
    url += serviceInfo.path;
  }
  return url;
}

function updateProducesAndConsumes(serviceInfo) {
  ["consumes", "produces"].forEach((consumesOrProduces) => {
    if (serviceInfo[consumesOrProduces]) {
      if (serviceInfo[consumesOrProduces] === "application/text") {
        serviceInfo[consumesOrProduces] = "text/plain";
      }
    }
  });

  if (serviceInfo.versioningRequired) {
    ["consumes", "produces"].forEach((consumesOrProduces) =>
      updateProdConForVersion(serviceInfo, consumesOrProduces)
    );
  } else {
    if (
      _kaledo.hateousEnabledComponents &&
      Array.isArray(_kaledo.hateousEnabledComponents) &&
      _kaledo.hateousEnabledComponents.includes(serviceInfo.ownerComp) &&
      !serviceInfo.isProducesAndConsumesUpdated
    ) {
      if (serviceInfo.produces) {
        let producesStr = serviceInfo["produces"];
        let slashIndex = producesStr.indexOf("/");
        if (slashIndex !== -1) {
          slashIndex = slashIndex + 1;
          let hateoasStr = "hal+";
          serviceInfo["produces"] =
            producesStr.substring(0, slashIndex) +
            hateoasStr +
            producesStr.substring(slashIndex, producesStr.length);
        }
      }
    }
  }
  serviceInfo.isProducesAndConsumesUpdated = true;
}

function updateProdConForVersion(serviceInfo, consumesOrProduces) {
  if (
    serviceInfo.versioningType === "MediaType" &&
    serviceInfo.versioningValue &&
    !serviceInfo.isProducesAndConsumesUpdated
  ) {
    if (serviceInfo[consumesOrProduces]) {
      let consumesOrProducesStr = serviceInfo[consumesOrProduces];
      let slashIndex = consumesOrProducesStr.indexOf("/");
      if (slashIndex !== -1) {
        slashIndex = slashIndex + 1;
        let versionStr = serviceInfo["versioningValue"] + "+";
        if (
          _kaledo.hateousEnabledComponents &&
          Array.isArray(_kaledo.hateousEnabledComponents) &&
          _kaledo.hateousEnabledComponents.includes(serviceInfo.ownerComp)
        ) {
          versionStr = "hal+" + versionStr;
        }
        serviceInfo[consumesOrProduces] =
          consumesOrProducesStr.substring(0, slashIndex) +
          versionStr +
          consumesOrProducesStr.substring(
            slashIndex,
            consumesOrProducesStr.length
          );
      }
    }
  }
}

function isGridColumn(key, scrnObj) {
  let isGridCol = false;
  let clientObj = scrnObj[key];
  if (clientObj && clientObj.parent) {
    let parentWidgetName = clientObj.parent;
    let parentWidgetObj = scrnObj[parentWidgetName];
    if (parentWidgetObj.type === "GridWidget") {
      isGridCol = true;
    }
  }
  return isGridCol;
}

function prepareServiceInputData(thisObj1,serviceInParam, svcInData) {
  let thisObj=thisObj1;
  let scrnObj = thisObj.state;
  for (let inputParamName in serviceInParam) {
    let inputParamObj = serviceInParam[inputParamName];
    let gridMappingCollection = collect(inputParamObj).filter((value, key) => {
      return isGridColumn(value, scrnObj);
    });
    let gridMappingObj = gridMappingCollection.all();
    if (!isEmptyObject(gridMappingObj)) {
      prepareGridInputData(
        
        gridMappingObj,
        scrnObj,
        inputParamName,
        svcInData
      );
    }
    let formMappingCollection = collect(inputParamObj).filter((value, key) => {
      return !isGridColumn(value, scrnObj);
    });
    let formMappingObj = formMappingCollection.all();
    if (!isEmptyObject(formMappingObj)) {
      prepareFormInputData(
        thisObj,
        formMappingObj,
        scrnObj,
        inputParamName,
        svcInData
      );
    }
  }
}

function prepareFormInputData(
  thisObj1,
  formMappingObj,
  scrnObj,
  inputParamName,
  svcInData
) {
  let thisObj=thisObj1;
  for (let serverKey in formMappingObj) {
    let clientKey = formMappingObj[serverKey];
    let widgetObj = scrnObj[clientKey];
    let transformedValue;
    if (widgetObj) {
      transformedValue = transformValueForServiceInput(
        widgetObj,
        thisObj.values[widgetObj.name]
      );
    } else {
      transformedValue = thisObj.values[clientKey];
    }
    serverKey = serverKey.replace(inputParamName, "");
    DotObject.str(inputParamName + serverKey, transformedValue, svcInData);
  }
}

function prepareGridInputData(
  gridMappingObj,
  scrnObj,
  inputParamName,
  svcInData
) {
  let serverGridData = collect([]);
  let arrayAccessKey;
  for (let serverKey in gridMappingObj) {
    let clientKey = gridMappingObj[serverKey];
    let colName = clientKey;
    let gridName = scrnObj[colName].parent;
    let gridObj = scrnObj[gridName];
    let gridData = gridObj.gridData;

    if (!gridObj.isEditable) {
      if (gridObj.selected) {
        gridData = gridObj.selected;
      }
    }

    let colNameValArr = collect(gridData).pluck(colName).all();

    serverKey = serverKey.replace(inputParamName + ".", "");
    let accessKeys = getAccessKeys(serverKey);
    arrayAccessKey = accessKeys[0];
    let valueAccessKey = accessKeys[1];

    let serverColValArr = colNameValArr.map((colNameVal) => {
      colNameVal = transformValueForServiceInput(scrnObj[colName], colNameVal);

      if (!gridObj.isEditable) {
        if (gridObj.selected) {
          return colNameVal;
        }
      }

      let serverGridObj = {};
      DotObject.str(valueAccessKey, colNameVal, serverGridObj);
      return serverGridObj;
    });
    if (serverGridData.isEmpty()) {
      serverGridData = collect(serverColValArr);
    } else {
      serverGridData = serverGridData.map((gridObj, index) => {
        return { ...gridObj, ...serverColValArr[index] };
      });
    }
  }
  let inputKey;
  if (arrayAccessKey) {
    inputKey = inputParamName + "." + arrayAccessKey;
  } else {
    inputKey = inputParamName;
  }

  DotObject.str(inputKey, serverGridData.all(), svcInData);
}

function getAccessKeys(serverKey) {
  let serverKeyArr = serverKey.split(".");
  let arrayAccessKey;
  let tempKey = "";
  serverKeyArr.forEach((key) => {
    key = tempKey + key;
    if (key.indexOf("###Y") !== -1) {
      arrayAccessKey = key;
      return false;
    } else {
      tempKey = key + ".";
    }
  });
  let valueAccessKey;
  if (arrayAccessKey) {
    valueAccessKey = serverKey.replace(arrayAccessKey + ".", "");
    arrayAccessKey = arrayAccessKey.replace("###Y", "");
  } else {
    valueAccessKey = serverKey;
  }
  return [arrayAccessKey, valueAccessKey];
}

function transformValueForServiceInput(widgetObj, value) {
  let transformedValue = "";
  switch (widgetObj.type) {
    case "DateWidget":
      transformedValue = moment(value).format("YYYY-MM-DD");
      break;
    case "DateTimeWidget":
      transformedValue = moment(value).format("YYYY-MM-DDTHH:mm:ss");
      break;
    case "TimeWidget":
      transformedValue = moment(value).format("HH:mm:ss");
      break;
    case "FileWidget":
      if (widgetObj.Multiplicity) {
        transformedValue = value.map((fileObj) => {
          return {
            fileData: fileObj.data,
            fileName: fileObj.value
          };
        });
      } else {
        if (value.length === 1) {
          transformedValue = {
            fileData: value[0].data,
            fileName: value[0].value
          };
        }
      }
      break;
    case "AutocompleteWidget":
      if (widgetObj.multiple) {
        transformedValue = value.join(",");
      } else {
        if (value.length === 1) transformedValue = value[0];
      }
      break;
    case "ListBoxFieldWidget":
    case "ListSelectDeselectFieldWidget":
      transformedValue = typeof value === "string" ? value.split(",") : value;
      break;
    case "ImageWidget":
      if (value.length === 1) {
        transformedValue = {
          fileData: value[0].data,
          fileName: value[0].value,
        };
      }
      break;
    default:
      transformedValue = value;
  }
  return transformedValue;
}

export function executePreSubmit(thisObj1,typeOfEvent, widgetId,event) {
  let thisObj=thisObj1;
  let preSubString = `on${widgetId}${typeOfEvent}`;
  let preSubFunc = thisObj[preSubString];
  if (preSubFunc){
    if(event!==undefined) 
     return preSubFunc(event);
   else 
     return preSubFunc();
   }
  return true;
}

function executePostSubmit(thisObj1,typeOfEvent, widgetId, response) {
  let thisObj=thisObj1;
  let postSubString = `onPostSubmitOf${widgetId}${typeOfEvent}`;
  let postSubFunc = thisObj[postSubString];
  if (postSubFunc) {
    return postSubFunc(response);
  }
  return true;
}

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
