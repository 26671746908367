/* eslint-disable*/
import React from "react";
import Settlements_TradeSettlePremDeductView from "./TradeSettlePremDeductView";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("TradeSettlePremDeductView Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettlePremDeductView />);
    });
  });
  afterEach(cleanup);
  test("is TradeSettlePremDeductView Loads Successfully", () => {
    expect(screen.getByText("TradeSettlePremDeductView")).toBeInTheDocument;
  });
  test("Custom Test Cases for TradeSettlePremDeductView", () => {
    // START_USER_CODE-USER_TradeSettlePremDeductView_Custom_Test_Case
    // END_USER_CODE-USER_TradeSettlePremDeductView_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettlePremDeductView />);
    });
  });
  afterEach(cleanup);
  test("btnCncl(Button Widget) Test Cases", async () => {
    const btnCncl = screen.getByTestId("btnCncl");
    expect(btnCncl).toBeInTheDocument;
    expect(btnCncl.textContent).toEqual(
      t("Settlements:TradeSettlePremDeductView_btnCncl")
    );
  });
  test("Custom Test Cases for btnCncl", () => {
    // START_USER_CODE-USER_btnCncl_TEST
    // END_USER_CODE-USER_btnCncl_TEST
  });
  test("gridPremDed(Grid Widget) Test Cases", async () => {
    let gridPremDed = screen.getByTestId("gridPremDed");
    let gridPremDedbtn = gridPremDed.nextElementSibling.firstElementChild;
    gridPremDed = gridPremDed.parentElement.parentElement.parentElement;
    expect(gridPremDed.tagName).toBe("DIV");
    expect(gridPremDed.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePremDeductView_gridPremDed")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPremDed", () => {
    // START_USER_CODE-USER_gridPremDed_TEST
    // END_USER_CODE-USER_gridPremDed_TEST
  });
  test("grpbxPremDedView(GroupBox Widget) Test Cases", async () => {
    const grpbxPremDedView = screen.getByTestId("grpbxPremDedView");
    expect(grpbxPremDedView.tagName).toBe("BUTTON");
    expect(grpbxPremDedView.type).toBe("button");
    expect(grpbxPremDedView.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremDedView", () => {
    // START_USER_CODE-USER_grpbxPremDedView_TEST
    // END_USER_CODE-USER_grpbxPremDedView_TEST
  });
  test("gridPremDed_txtcolAmnt(Grid Widget) Test Cases", async () => {
    let gridPremDed_txtcolAmnt = screen.getByTestId("gridPremDed");
    let gridPremDed_txtcolAmntbtn =
      gridPremDed_txtcolAmnt.nextElementSibling.firstElementChild;
    gridPremDed_txtcolAmnt =
      gridPremDed_txtcolAmnt.parentElement.parentElement.parentElement;
    expect(gridPremDed_txtcolAmnt.tagName).toBe("DIV");
    expect(gridPremDed_txtcolAmnt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePremDeductView_gridPremDed")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAmnt", () => {
    // START_USER_CODE-USER_txtcolAmnt_TEST
    // END_USER_CODE-USER_txtcolAmnt_TEST
  });
  test("gridPremDed_txtcolPremDeduct(Grid Widget) Test Cases", async () => {
    let gridPremDed_txtcolPremDeduct = screen.getByTestId("gridPremDed");
    let gridPremDed_txtcolPremDeductbtn =
      gridPremDed_txtcolPremDeduct.nextElementSibling.firstElementChild;
    gridPremDed_txtcolPremDeduct =
      gridPremDed_txtcolPremDeduct.parentElement.parentElement.parentElement;
    expect(gridPremDed_txtcolPremDeduct.tagName).toBe("DIV");
    expect(gridPremDed_txtcolPremDeduct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePremDeductView_gridPremDed")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPremDeduct", () => {
    // START_USER_CODE-USER_txtcolPremDeduct_TEST
    // END_USER_CODE-USER_txtcolPremDeduct_TEST
  });
  test("gridPremDed_txtcolVndr(Grid Widget) Test Cases", async () => {
    let gridPremDed_txtcolVndr = screen.getByTestId("gridPremDed");
    let gridPremDed_txtcolVndrbtn =
      gridPremDed_txtcolVndr.nextElementSibling.firstElementChild;
    gridPremDed_txtcolVndr =
      gridPremDed_txtcolVndr.parentElement.parentElement.parentElement;
    expect(gridPremDed_txtcolVndr.tagName).toBe("DIV");
    expect(gridPremDed_txtcolVndr.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettlePremDeductView_gridPremDed")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVndr", () => {
    // START_USER_CODE-USER_txtcolVndr_TEST
    // END_USER_CODE-USER_txtcolVndr_TEST
  });
  test("txtpreDed1(Textbox Widget) Test Cases", async () => {
    const txtpreDed1 = screen.getByTestId("txtpreDed1");
    expect(txtpreDed1.tagName).toBe("INPUT");
    expect(txtpreDed1.type).toBe("text");
    expect(txtpreDed1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtpreDed1, "1");
    });
  });
  test("Custom Test Cases for txtpreDed1", () => {
    // START_USER_CODE-USER_txtpreDed1_TEST
    // END_USER_CODE-USER_txtpreDed1_TEST
  });
  test("txtPreDed2(Textbox Widget) Test Cases", async () => {
    const txtPreDed2 = screen.getByTestId("txtPreDed2");
    expect(txtPreDed2.tagName).toBe("INPUT");
    expect(txtPreDed2.type).toBe("text");
    expect(txtPreDed2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPreDed2, "1");
    });
  });
  test("Custom Test Cases for txtPreDed2", () => {
    // START_USER_CODE-USER_txtPreDed2_TEST
    // END_USER_CODE-USER_txtPreDed2_TEST
  });
  test("txtPreDed3(Textbox Widget) Test Cases", async () => {
    const txtPreDed3 = screen.getByTestId("txtPreDed3");
    expect(txtPreDed3.tagName).toBe("INPUT");
    expect(txtPreDed3.type).toBe("text");
    expect(txtPreDed3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPreDed3, "1");
    });
  });
  test("Custom Test Cases for txtPreDed3", () => {
    // START_USER_CODE-USER_txtPreDed3_TEST
    // END_USER_CODE-USER_txtPreDed3_TEST
  });
  test("txtPremDed(Textbox Widget) Test Cases", async () => {
    const txtPremDed = screen.getByTestId("txtPremDed");
    expect(txtPremDed.tagName).toBe("INPUT");
    expect(txtPremDed.type).toBe("text");
    expect(txtPremDed.classList).toContain("textboxWidgetClass");
    expect(txtPremDed.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettlePremDeductView_txtPremDed")
    );
    await act(async () => {
      userEvent.type(txtPremDed, "1");
    });
  });
  test("Custom Test Cases for txtPremDed", () => {
    // START_USER_CODE-USER_txtPremDed_TEST
    // END_USER_CODE-USER_txtPremDed_TEST
  });
});
