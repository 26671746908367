/* eslint-disable*/
import React from "react";
import TopMenu from "./TopMenu";
import { cleanup, screen } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import userEvent from "@testing-library/user-event";
import renderTestScreen from "../../../common/renderTestScreen";

describe("TopMenu Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<TopMenu />);
    });
  });
  afterEach(cleanup);
  test("is TopMenu Loads Successfully", async () => {
    let stdScreen = screen.getAllByTestId("scrnDispName");
    stdScreen = stdScreen[0].firstChild;
    let langSel = screen.getByTestId("scrnDispName").nextSibling.firstChild;
    var sel_lang = window._kaledo.languages[0].langName;
    userEvent.click(langSel);
    userEvent.click(screen.getByText(sel_lang));
    await act(async () => {
      userEvent.click(stdScreen, { button: 0 });
    });
    expect(false).toBe(false);
  });
});
