/* eslint-disable*/
import React from "react";
import SpinnerWidget from "./SpinnerWidget";
import { screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("SpinnerWidget1 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let spinnerwidget1 = {
        name: "spinnerwidget1",
        type: "SpinnerWidget",
        parent: "groupboxwidget0",
        Label: "spinnerwidget1",
        LengthRange: { MinLength: 0, MaxLength: 9 },
        ofTypeDomain: "d_int",
      };
      let stateScreenConf = {
        Label: "ChildScreen",
        windowName: "ChildScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestABSGUI.ChildScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      renderTestScreen(
        <SpinnerWidget
          conf={spinnerwidget1}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Is SpinnerWIdget1 Loads Successfully", async () => {
    const spinnerwidget1 = screen.getByTestId("spinnerwidget1");
    expect(spinnerwidget1.tagName).toBe("INPUT");
    expect(spinnerwidget1.type).toBe("number");
    expect(spinnerwidget1.classList).toContain("textboxWidgetClass");
  });
});
describe("Spinner Widget2 Test Cases", () => {
  afterEach(cleanup);
  test("Is SpinnerWIdget2 Loads Successfully", () => {
    let spinnerwidget2 = {
      name: "spinnerwidget2",
      type: "SpinnerWidget",
      parent: "groupboxwidget0",
      Label: "spinnerwidget2",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
    };
    let stateScreenConf = {
      Label: "ChildScreen",
      windowName: "ChildScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestABSGUI.ChildScreen",
    };
    let values = {};
    let errors = jest.fn();
    let touched = jest.fn();
    window._kaledo["dispFormatReqd"] = true;
    const testScreen = renderTestScreen(
      <SpinnerWidget
        conf={spinnerwidget2}
        screenConf={stateScreenConf}
        values={values}
        touched={touched}
        errors={errors}
      />
    );
    const spinnerwidget2el = testScreen.getByTestId("spinnerwidget2");
    expect(spinnerwidget2el.tagName).toBe("INPUT");
    expect(spinnerwidget2el.type).toBe("number");
    expect(spinnerwidget2el.classList).toContain("textboxWidgetClass");
  });
  test("SpinnerWidget with horizontal form Test Case", async () => {
    let spinnerwidget3 = {
      name: "spinnerwidget3",
      type: "SpinnerWidget",
      parent: "groupboxwidget0",
      Label: "spinnerwidget3",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_int",
    };
    let stateScreenConf = {
      Label: "ChildScreen",
      windowName: "ChildScreen",
      template: "Standard Screen",
      Cols: "3",
      ColsForMobile: "3",
      ColsForTabLandscape: "3",
      ColsForTabPotrait: "3",
      isResponsiveLayout: false,
      Height: "",
      Left: "",
      Top: "",
      Width: "",
      i18Key: "TestABSGUI.ChildScreen",
      horizontalForm: true,
    };
    let values = {};
    let errors = jest.fn();
    let touched = jest.fn();
    const testScreen = renderTestScreen(
      <SpinnerWidget
        conf={spinnerwidget3}
        screenConf={stateScreenConf}
        values={values}
        touched={touched}
        errors={errors}
      />
    );
    const spinnerwidget3el = testScreen.getByTestId("spinnerwidget3");
    expect(spinnerwidget3el.tagName).toBe("INPUT");
    expect(spinnerwidget3el.type).toBe("number");
    expect(spinnerwidget3el.classList).toContain("textboxWidgetClass");
  });
});

describe("Spinner Widget3 Test Case", () => {
  beforeEach(async () => {
    act(() => {
      let spinnerwidget4 = {
        name: "spinnerwidget4",
        type: "SpinnerWidget",
        parent: "groupboxwidget0",
        Label: "spinnerwidget4",
        LengthRange: { MinLength: 0, MaxLength: 9 },
        ofTypeDomain: "d_int",
        formattingReqd: true,
      };
      let stateScreenConf = {
        Label: "ChildScreen",
        windowName: "ChildScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestABSGUI.ChildScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      window._kaledo["dispFormatReqd"] = true;
      renderTestScreen(
        <SpinnerWidget
          conf={spinnerwidget4}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Is SpinnerWIdget3 Loads Successfully", () => {
    const spinnerwidget4el = screen.getByTestId("spinnerwidget4");
    expect(spinnerwidget4el.tagName).toBe("INPUT");
    expect(spinnerwidget4el.type).toBe("number");
    expect(spinnerwidget4el.classList).toContain("textboxWidgetClass");
  });
});
describe("Spinner Widget4 Test Case", () => {
  beforeEach(async () => {
    act(() => {
      let spinnerwidget5 = {
        name: "spinnerwidget5",
        type: "SpinnerWidget",
        parent: "groupboxwidget0",
        Label: "spinnerwidget5",
        LengthRange: { MinLength: 0, MaxLength: 9 },
        ofTypeDomain: "d_int",
      };
      let stateScreenConf = {
        Label: "ChildScreen",
        windowName: "ChildScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestABSGUI.ChildScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      renderTestScreen(
        <SpinnerWidget
          conf={spinnerwidget5}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Is SpinnerWIdget4 Loads Successfully", async () => {
    const spinnerwidget5el = screen.getByTestId("spinnerwidget5");
    expect(spinnerwidget5el.tagName).toBe("INPUT");
    expect(spinnerwidget5el.type).toBe("number");
    expect(spinnerwidget5el.classList).toContain("textboxWidgetClass");
  });
});
describe("Spinner Widget5 Test Case", () => {
  beforeEach(async () => {
    act(() => {
      let spinnerwidget7 = {
        name: "spinnerwidget7",
        type: "SpinnerWidget",
        parent: "groupboxwidget0",
        Label: "spinnerwidget7",
        LengthRange: { MinLength: 0, MaxLength: 9 },
        ofTypeDomain: "d_int",
        formattingReqd: true,
      };
      let stateScreenConf = {
        Label: "ChildScreen",
        windowName: "ChildScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestABSGUI.ChildScreen",
        horizontalForm: true,
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      renderTestScreen(
        <SpinnerWidget
          onChange={onChange}
          conf={spinnerwidget7}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Is SpinnerWIdget5 Loads Successfully", async () => {
    const spinnerwidget7el = screen.getByTestId("spinnerwidget7");
    expect(spinnerwidget7el.tagName).toBe("INPUT");
    expect(spinnerwidget7el.type).toBe("number");
    expect(spinnerwidget7el.classList).toContain("textboxWidgetClass");
    await act(async () => {
      fireEvent.change(spinnerwidget7el, { target: { value: 1 } });
    });
    expect(spinnerwidget7el.getAttribute("value")).toBe("1");
  });
});
