/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_PeanutVarietySetup from "./PeanutVarietySetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PeanutVarietySetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PeanutVarietySetup />
      );
    });
  });
  afterEach(cleanup);
  test("is PeanutVarietySetup Loads Successfully", () => {
    expect(screen.getByText("PeanutVarietySetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for PeanutVarietySetup", () => {
    // START_USER_CODE-USER_PeanutVarietySetup_Custom_Test_Case
    // END_USER_CODE-USER_PeanutVarietySetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PeanutVarietySetup />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietySetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnAddV(Button Widget) Test Cases", async () => {
    const btnAddV = screen.getByTestId("btnAddV");
    expect(btnAddV).toBeInTheDocument;
    expect(btnAddV.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietySetup_btnAddV")
    );
  });
  test("Custom Test Cases for btnAddV", () => {
    // START_USER_CODE-USER_btnAddV_TEST
    // END_USER_CODE-USER_btnAddV_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietySetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietySetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnDeleteV(Button Widget) Test Cases", async () => {
    const btnDeleteV = screen.getByTestId("btnDeleteV");
    expect(btnDeleteV).toBeInTheDocument;
    expect(btnDeleteV.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietySetup_btnDeleteV")
    );
  });
  test("Custom Test Cases for btnDeleteV", () => {
    // START_USER_CODE-USER_btnDeleteV_TEST
    // END_USER_CODE-USER_btnDeleteV_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietySetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnEditV(Button Widget) Test Cases", async () => {
    const btnEditV = screen.getByTestId("btnEditV");
    expect(btnEditV).toBeInTheDocument;
    expect(btnEditV.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutVarietySetup_btnEditV")
    );
  });
  test("Custom Test Cases for btnEditV", () => {
    // START_USER_CODE-USER_btnEditV_TEST
    // END_USER_CODE-USER_btnEditV_TEST
  });
  test("grpbxPeanutVarietySetup(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanutVarietySetup = screen.getByTestId(
      "grpbxPeanutVarietySetup"
    );
    expect(grpbxPeanutVarietySetup.tagName).toBe("BUTTON");
    expect(grpbxPeanutVarietySetup.type).toBe("button");
    expect(grpbxPeanutVarietySetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPeanutVarietySetup", () => {
    // START_USER_CODE-USER_grpbxPeanutVarietySetup_TEST
    // END_USER_CODE-USER_grpbxPeanutVarietySetup_TEST
  });
});
