/* eslint-disable*/
import React from "react";
import AccountPayment_NullifyCheck from "./NullifyCheck";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("NullifyCheck Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_NullifyCheck />);
    });
  });
  afterEach(cleanup);
  test("is NullifyCheck Loads Successfully", () => {
    expect(screen.getByText("NullifyCheck")).toBeInTheDocument;
  });
  test("Custom Test Cases for NullifyCheck", () => {
    // START_USER_CODE-USER_NullifyCheck_Custom_Test_Case
    // END_USER_CODE-USER_NullifyCheck_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_NullifyCheck />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("AccountPayment:NullifyCheck_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnSpoilChecks(Button Widget) Test Cases", async () => {
    const btnSpoilChecks = screen.getByTestId("btnSpoilChecks");
    expect(btnSpoilChecks).toBeInTheDocument;
    expect(btnSpoilChecks.textContent).toEqual(
      t("AccountPayment:NullifyCheck_btnSpoilChecks")
    );
  });
  test("Custom Test Cases for btnSpoilChecks", () => {
    // START_USER_CODE-USER_btnSpoilChecks_TEST
    // END_USER_CODE-USER_btnSpoilChecks_TEST
  });
  test("grpbxCheckDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxCheckDetails = screen.getByTestId("grpbxCheckDetails");
    expect(grpbxCheckDetails.tagName).toBe("BUTTON");
    expect(grpbxCheckDetails.type).toBe("button");
    expect(grpbxCheckDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCheckDetails", () => {
    // START_USER_CODE-USER_grpbxCheckDetails_TEST
    // END_USER_CODE-USER_grpbxCheckDetails_TEST
  });
  test("lblEnterCheckNumber(Label Widget) Test Cases", async () => {
    const lblEnterCheckNumber = screen.getByTestId("lblEnterCheckNumber");
    expect(lblEnterCheckNumber.tagName).toBe("LABEL");
    expect(lblEnterCheckNumber.classList).toContain("form-label");
    expect(lblEnterCheckNumber.textContent).toEqual(
      t("AccountPayment:NullifyCheck_lblEnterCheckNumber")
    );
  });
  test("Custom Test Cases for lblEnterCheckNumber", () => {
    // START_USER_CODE-USER_lblEnterCheckNumber_TEST
    // END_USER_CODE-USER_lblEnterCheckNumber_TEST
  });
  test("txtCheckNum1(Textbox Widget) Test Cases", async () => {
    const txtCheckNum1 = screen.getByTestId("txtCheckNum1");
    expect(txtCheckNum1.tagName).toBe("INPUT");
    expect(txtCheckNum1.type).toBe("text");
    expect(txtCheckNum1.classList).toContain("textboxWidgetClass");
    expect(txtCheckNum1.previousElementSibling.textContent).toEqual(
      t("AccountPayment:NullifyCheck_txtCheckNum1")
    );
    await act(async () => {
      userEvent.type(txtCheckNum1, "1");
    });
  });
  test("Custom Test Cases for txtCheckNum1", () => {
    // START_USER_CODE-USER_txtCheckNum1_TEST
    // END_USER_CODE-USER_txtCheckNum1_TEST
  });
  test("txtCheckNum2(Textbox Widget) Test Cases", async () => {
    const txtCheckNum2 = screen.getByTestId("txtCheckNum2");
    expect(txtCheckNum2.tagName).toBe("INPUT");
    expect(txtCheckNum2.type).toBe("text");
    expect(txtCheckNum2.classList).toContain("textboxWidgetClass");
    expect(txtCheckNum2.previousElementSibling.textContent).toEqual(
      t("AccountPayment:NullifyCheck_txtCheckNum2")
    );
    await act(async () => {
      userEvent.type(txtCheckNum2, "1");
    });
  });
  test("Custom Test Cases for txtCheckNum2", () => {
    // START_USER_CODE-USER_txtCheckNum2_TEST
    // END_USER_CODE-USER_txtCheckNum2_TEST
  });
  test("txtCheckNum3(Textbox Widget) Test Cases", async () => {
    const txtCheckNum3 = screen.getByTestId("txtCheckNum3");
    expect(txtCheckNum3.tagName).toBe("INPUT");
    expect(txtCheckNum3.type).toBe("text");
    expect(txtCheckNum3.classList).toContain("textboxWidgetClass");
    expect(txtCheckNum3.previousElementSibling.textContent).toEqual(
      t("AccountPayment:NullifyCheck_txtCheckNum3")
    );
    await act(async () => {
      userEvent.type(txtCheckNum3, "1");
    });
  });
  test("Custom Test Cases for txtCheckNum3", () => {
    // START_USER_CODE-USER_txtCheckNum3_TEST
    // END_USER_CODE-USER_txtCheckNum3_TEST
  });
  test("txtCheckNum4(Textbox Widget) Test Cases", async () => {
    const txtCheckNum4 = screen.getByTestId("txtCheckNum4");
    expect(txtCheckNum4.tagName).toBe("INPUT");
    expect(txtCheckNum4.type).toBe("text");
    expect(txtCheckNum4.classList).toContain("textboxWidgetClass");
    expect(txtCheckNum4.previousElementSibling.textContent).toEqual(
      t("AccountPayment:NullifyCheck_txtCheckNum4")
    );
    await act(async () => {
      userEvent.type(txtCheckNum4, "1");
    });
  });
  test("Custom Test Cases for txtCheckNum4", () => {
    // START_USER_CODE-USER_txtCheckNum4_TEST
    // END_USER_CODE-USER_txtCheckNum4_TEST
  });
  test("txtCheckNum5(Textbox Widget) Test Cases", async () => {
    const txtCheckNum5 = screen.getByTestId("txtCheckNum5");
    expect(txtCheckNum5.tagName).toBe("INPUT");
    expect(txtCheckNum5.type).toBe("text");
    expect(txtCheckNum5.classList).toContain("textboxWidgetClass");
    expect(txtCheckNum5.previousElementSibling.textContent).toEqual(
      t("AccountPayment:NullifyCheck_txtCheckNum5")
    );
    await act(async () => {
      userEvent.type(txtCheckNum5, "1");
    });
  });
  test("Custom Test Cases for txtCheckNum5", () => {
    // START_USER_CODE-USER_txtCheckNum5_TEST
    // END_USER_CODE-USER_txtCheckNum5_TEST
  });
  test("txtCheckNum6(Textbox Widget) Test Cases", async () => {
    const txtCheckNum6 = screen.getByTestId("txtCheckNum6");
    expect(txtCheckNum6.tagName).toBe("INPUT");
    expect(txtCheckNum6.type).toBe("text");
    expect(txtCheckNum6.classList).toContain("textboxWidgetClass");
    expect(txtCheckNum6.previousElementSibling.textContent).toEqual(
      t("AccountPayment:NullifyCheck_txtCheckNum6")
    );
    await act(async () => {
      userEvent.type(txtCheckNum6, "1");
    });
  });
  test("Custom Test Cases for txtCheckNum6", () => {
    // START_USER_CODE-USER_txtCheckNum6_TEST
    // END_USER_CODE-USER_txtCheckNum6_TEST
  });
  test("txtCheckNum7(Textbox Widget) Test Cases", async () => {
    const txtCheckNum7 = screen.getByTestId("txtCheckNum7");
    expect(txtCheckNum7.tagName).toBe("INPUT");
    expect(txtCheckNum7.type).toBe("text");
    expect(txtCheckNum7.classList).toContain("textboxWidgetClass");
    expect(txtCheckNum7.previousElementSibling.textContent).toEqual(
      t("AccountPayment:NullifyCheck_txtCheckNum7")
    );
    await act(async () => {
      userEvent.type(txtCheckNum7, "1");
    });
  });
  test("Custom Test Cases for txtCheckNum7", () => {
    // START_USER_CODE-USER_txtCheckNum7_TEST
    // END_USER_CODE-USER_txtCheckNum7_TEST
  });
  test("txtCheckNum8(Textbox Widget) Test Cases", async () => {
    const txtCheckNum8 = screen.getByTestId("txtCheckNum8");
    expect(txtCheckNum8.tagName).toBe("INPUT");
    expect(txtCheckNum8.type).toBe("text");
    expect(txtCheckNum8.classList).toContain("textboxWidgetClass");
    expect(txtCheckNum8.previousElementSibling.textContent).toEqual(
      t("AccountPayment:NullifyCheck_txtCheckNum8")
    );
    await act(async () => {
      userEvent.type(txtCheckNum8, "1");
    });
  });
  test("Custom Test Cases for txtCheckNum8", () => {
    // START_USER_CODE-USER_txtCheckNum8_TEST
    // END_USER_CODE-USER_txtCheckNum8_TEST
  });
});
