/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  setData,
  setValue,
  getData,
  getValue,
  goTo,
  enable,
  disable,
} from "../../shared/WindowImports";

import "./CountySetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices"
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_CountySetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "CountySetup",
    windowName: "CountySetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.CountySetup",
    // START_USER_CODE-USER_CountySetup_PROPERTIES
    headerData: {
      scrName: "County Setup",
      scrCode: "PN0010A",
    },
    // END_USER_CODE-USER_CountySetup_PROPERTIES
    btnAddC: {
      name: "btnAddC",
      type: "ButtonWidget",
      parent: "grpbxCountySetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddC_PROPERTIES

      // END_USER_CODE-USER_btnAddC_PROPERTIES
    },
    btnAddS: {
      name: "btnAddS",
      type: "ButtonWidget",
      parent: "grpbxCountySetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddS_PROPERTIES

      // END_USER_CODE-USER_btnAddS_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnreDisableClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDeleteC: {
      name: "btnDeleteC",
      type: "ButtonWidget",
      parent: "grpbxCountySetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteC_PROPERTIES

      // END_USER_CODE-USER_btnDeleteC_PROPERTIES
    },
    btnDleteS: {
      name: "btnDleteS",
      type: "ButtonWidget",
      parent: "grpbxCountySetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDleteS_PROPERTIES

      // END_USER_CODE-USER_btnDleteS_PROPERTIES
    },
    btneDisable: {
      name: "btneDisable",
      type: "ButtonWidget",
      parent: "cmmndCntnreDisableClose",
      Label: "eDisable",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btneDisable_PROPERTIES

      // END_USER_CODE-USER_btneDisable_PROPERTIES
    },
    btnEditC: {
      name: "btnEditC",
      type: "ButtonWidget",
      parent: "grpbxCountySetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditC_PROPERTIES

      // END_USER_CODE-USER_btnEditC_PROPERTIES
    },
    btnEditS: {
      name: "btnEditS",
      type: "ButtonWidget",
      parent: "grpbxCountySetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditS_PROPERTIES

      // END_USER_CODE-USER_btnEditS_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxCountySetup",
      Label: "State:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES

      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    lstCounty: {
      name: "lstCounty",
      type: "ListBoxFieldWidget",
      parent: "grpbxCountySetup",
      Label: "County:",
      ColSpan: "3",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstCounty_PROPERTIES

      // END_USER_CODE-USER_lstCounty_PROPERTIES
    },
    grpbxCountySetup: {
      name: "grpbxCountySetup",
      type: "GroupBoxWidget",
      parent: "CountySetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCountySetup_PROPERTIES

      // END_USER_CODE-USER_grpbxCountySetup_PROPERTIES
    },
    cmmndCntnreDisableClose: {
      name: "cmmndCntnreDisableClose",
      type: "CommandContainerWidget",
      parent: "CountySetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnreDisableClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnreDisableClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceMasterManagement#StateProfile": {},
        "ContractManagement#MainMenu": {},
        "SystemMaintenanceMasterManagement#CountyProfile": {},
      },
      REVERSE: {
        "SystemMaintenanceMasterManagement#StateProfile": {},
        "ContractManagement#MainMenu": {},
        "SystemMaintenanceMasterManagement#CountyProfile": {},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnAddS: {
      DEFAULT: ["SystemMaintenanceMasterManagement#StateProfile#DEFAULT#true#Click"]
    },
    // btnEditS: {
    //   DEFAULT: ["SystemMaintenanceMasterManagement#StateProfile#DEFAULT#true#Click"]
    // },
    // btnAddC: {
    //   DEFAULT: ["SystemMaintenanceMasterManagement#CountyProfile#DEFAULT#true#Click"]
    // },
    // btnEditC: {
    //   DEFAULT: ["SystemMaintenanceMasterManagement#CountyProfile#DEFAULT#true#Click"]
    // },
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    }
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  // let countyLength=0;
  // let stateLength=0

  useEffect(() => {
    stateSetup();
    EnableDisableControls();
  }, [getData(thisObj, 'frmCountySetup')])

  let stateSetup = async () => {
    let newData = getData(thisObj, 'frmCountySetup')
    if (newData !== null && newData != "" && newData != undefined) {
      await bFillStateList();
      let stateList = getData(thisObj, 'StateListData')
      let state_abbr = newData.txtAbbreviation
      let state_index = 0;
      if (stateList !== undefined && stateList !== null) {
        state_index = stateList.find(elem => elem.key == state_abbr).index;
      }
      setValue(thisObj, 'ddState', stateList.at(state_index).key)
      bFillCountyList(state_abbr)
      setData(thisObj, 'frmCountySetup','')
    }
  }

  useEffect(() => {
    countyChange();
  }, [getData(thisObj, 'AddUpdateData')])

  const countyChange = async () => {
    let state_abbr = getData(thisObj, 'AddUpdateData')
    if (state_abbr != undefined && state_abbr != "" && state_abbr != null) {
      await bFillCountyList(state_abbr)
      EnableDisableControls()
    }
    setData(thisObj, 'AddUpdateData', '')
  }
  // START_USER_CODE-USER_METHODS
  let FormLoad = async () => {
    setLoading(true);
    await bFillStateList();
    let state_abbr = getData(thisObj,'StateId')
    await bFillCountyList(state_abbr);
    document.getElementById("btneDisable").style.visibility = "hidden";
    EnableDisableControls()
    setLoading(false)
  }

  //Dropdown for State List
  const bFillStateList = async () => {
    let response = await SystemMaintenanceMasterManagementService.RetrieveStateControlDetails(null);
    let data = response;
    let obj = {};
    let js = [];
    if (data !== null && data !== undefined && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let dataExist = js.find(elem => elem.key == data[i].state_abbr)

        if (dataExist == undefined) {
          obj = {
            key: data[i].state_abbr,
            description: data[i].state_name,
            stateId: data[i].state_id,
            index: i
          }
          js.push(obj);
          obj = {};
          // setValue(thisObj, 'ddState', data[i].state_abbr);
        }
      }
    }
    setData(thisObj, 'stateLength', js.length);
    setData(thisObj, 'StateListData', js)
    setValue(thisObj, 'ddState', js.at(0).key);
    setData(thisObj, 'StateId', js.at(0).key);

    thisObj.setState(current => {
      return {
        ...current,
        ddState: {
          ...state["ddState"],
          valueList: js,
        }
      }
    })

  }

  //Dropddown for County List
  const bFillCountyList = async (state_abbr) => {
    try {
      // let state_abbr = getValue(thisObj, 'ddState'
    let response = await ContractManagementService.RetrieveCountyControlDetails(null, null, state_abbr, null);
    let data = response;
    let obj = {};
    let js = [];

    if (data !== null && data !== undefined && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let dataExist = js.find(elem => elem.key == data[i].countyId)

        if (dataExist == undefined) {
          obj = {
            key: data[i].countyId,
            description: data[i].countyName + ' - ' + data[i].countyId,
            index: i
          }
          js.push(obj);
          obj = {};
        }
      }
    }
    if (js.length > 0) {
      setValue(thisObj, 'lstCounty', [js.at(0).key]);
    }
    setData(thisObj, 'countyLength', js.length);
    // setValue(thisObj,'lstCounty',js)
    thisObj.setState(current => {
      return {
        ...current,
        lstCounty: {
          ...state["lstCounty"],
          valueList: js,
        }
      }
    })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during setting County list"
        );
      }
      return false;
    }

  }

  //Click event of State Add button
  const onbtnAddSClick = () => {
    try {
      let frmStateProfile = {}
      frmStateProfile.cmdOk_Caption = "Add"
      frmStateProfile.txtAbbreviation_enabled = true
      setData(thisObj, 'frmStateProfile', frmStateProfile)
      goTo(thisObj, "SystemMaintenanceMasterManagement#StateProfile#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during State Add Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAddSClick = onbtnAddSClick

  //Click event of State Edit button
  const onbtnEditSClick = async () => {

    let state_abbr = thisObj.values['ddState'];
    let response = await SystemMaintenanceMasterManagementService.RetrieveStateControlDetails(state_abbr);
    let data = response;
    let frmStateProfile = {};
    frmStateProfile.cmdOk_Caption = 'Update';
    frmStateProfile.txtAbbreviation = data[0].state_abbr
    frmStateProfile.txtStateID = data[0].state_id
    frmStateProfile.txtName = data[0].state_name
    frmStateProfile.txtInCharges = data[0].in_charg_rate
    frmStateProfile.txtInspFees = data[0].insp_fee_rate
    frmStateProfile.txtFreightLoadLimit = data[0].freight_load_limit
    let add_date_time = moment(data[0].add_date_time).format("MM/DD/YYYY");
    frmStateProfile.lblAddedBy = data[0].add_user + " " + add_date_time
    let chg_date_time = moment(data[0].chg_date_time).format("MM/DD/YYYY");
    frmStateProfile.lblChangedBy = data[0].chg_user + " " + chg_date_time
    setData(thisObj, 'frmStateProfile', frmStateProfile)
    goTo(thisObj, "SystemMaintenanceMasterManagement#StateProfile#DEFAULT#true#Click")
  }
  thisObj.onbtnEditSClick = onbtnEditSClick

  //Click event of County Add button
  const onbtnAddCClick = () => {
    let stateList = thisObj.state['ddState'].valueList
    let state_abbr = getValue(thisObj, 'ddState');
    let stateName = ''

    if (state_abbr !== undefined && state_abbr !== null && state_abbr !== '') {
      stateName = stateList.find(elem => elem.key == state_abbr).description

    }
    let frmCountyProfile = {};
    frmCountyProfile.cmdOk_Caption = "Add";
    frmCountyProfile.txtID_enabled = true;
    frmCountyProfile.lblState_Caption = stateName,
      frmCountyProfile.state_abbr = state_abbr,


    setData(thisObj, 'frmCountyProfileData', frmCountyProfile)
    goTo(thisObj, "SystemMaintenanceMasterManagement#CountyProfile#DEFAULT#true#Click")

  }
  thisObj.onbtnAddCClick = onbtnAddCClick;

  //Click event of County Edit button
  const onbtnEditCClick = async () => {
    let state_abbr = thisObj.values['ddState'];
    let county_id = getValue(thisObj, 'lstCounty')[0]
    if (county_id == undefined) {
      showMessage(thisObj, 'Please select a county to Edit')
      return;
    }

    let stateList = thisObj.state['ddState'].valueList
    let stateName = ''
    if (state_abbr !== undefined && state_abbr !== null && state_abbr !== '') {
      stateName = stateList.find(elem => elem.key == state_abbr).description
    }

    let response = await SystemMaintenanceMasterManagementService.RetrieveCountyControlDetails(county_id, state_abbr);
    let data = response
    let frmCountyProfile = {}
    frmCountyProfile.cmdOk_Caption = 'Update';
    frmCountyProfile.txtID_enabled = false;
    frmCountyProfile.stateName = stateName;
    frmCountyProfile.txtName = data[2].strFieldData;
    frmCountyProfile.txtID = data[1].strFieldData;
    frmCountyProfile.txtCCCFAXNumber = data[7].strFieldData;
    frmCountyProfile.txtCCCFAXContact = data[8].strFieldData;
    frmCountyProfile.txtCCCEmailAddress = data[9].strFieldData
    frmCountyProfile.lblAddedBy = data[3].strFieldData + " " + data[4].strFieldData.slice(0,-11)
    frmCountyProfile.lblChangedBy = data[5].strFieldData + " " + data[6].strFieldData.slice(0,-11)
    frmCountyProfile.state_abbr = state_abbr;

    setData(thisObj, 'frmCountyProfileData', frmCountyProfile);
    goTo(thisObj, "SystemMaintenanceMasterManagement#CountyProfile#DEFAULT#true#Click")
  }
  thisObj.onbtnEditCClick = onbtnEditCClick

  //Function for State Delete button
  let onbtnDleteSClick = async () => {
    let state_abbr = getValue(thisObj, 'ddState')
    let stateList = thisObj.state['ddState'].valueList
    let stateName = ''
    let stateIndex;
    if (state_abbr !== undefined && state_abbr !== null && state_abbr !== '') {
      stateName = stateList.find(elem => elem.key == state_abbr).description;
      stateIndex = stateList.find(elem => elem.key == state_abbr).index;
    }

    let countyList = thisObj.state['lstCounty'].valueList
    if (countyList[0].key != undefined) {
      showMessage(thisObj, 'Must Delete all the Counties Controls within the State before you can delete the State Control!')
      return;
    }

    let res = confirm(`Are you sure you wish to delete this state '${stateName}'`)
    if (res == true) {
      let response = await SystemMaintenanceMasterManagementService.RemoveStateControl(null, state_abbr)
      if (![200, 400, 404].includes(response.status)) {
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
      }
      else {
        showMessage(thisObj, response.message, true)

      }
      let Newstate_abbr;
      await bFillStateList();
      if (stateIndex == (stateList.length - 1)) {
        setValue(thisObj, 'ddState', stateList.at(stateIndex - 1).key);
        Newstate_abbr = stateList.at(stateIndex - 1).key
      }
      else {
        setValue(thisObj, 'ddState', stateList.at(stateIndex + 1).key);
        Newstate_abbr = stateList.at(stateIndex + 1).key
        // let state=getValue(thisObj, 'ddState')
      }
      await bFillCountyList(Newstate_abbr)
      EnableDisableControls()
    }
    else {
      return;
    }

  }
  thisObj.onbtnDleteSClick = onbtnDleteSClick

  //Function for County Delete button
  let onbtnDeleteCClick = async () => {
    setLoading(true)
    let countyId = thisObj.values.lstCounty[0]
    if (countyId == undefined) {
      showMessage(thisObj, 'Please select a county to delete')
      setLoading(false)
      return;
    }
    
    countyId = countyId.trim()
    let state_abbr = getValue(thisObj, 'ddState')
    let countyList = thisObj.state['lstCounty'].valueList
    let countyName = ''
    let CountyIndex;
    if (countyId !== undefined && countyId !== null && countyId !== '') {
      countyName = countyList.find(elem => elem.key.trim() == countyId).description;
      CountyIndex = countyList.find(elem => elem.key.trim() == countyId).index;
    }
    let res = confirm(`Are you sure you wish to delete this County '${countyName}'`)
    if (res == true) {
      let response = await SystemMaintenanceMasterManagementService.RemoveCountyControl(countyId, state_abbr)
      if (![200, 400, 404].includes(response.status)) {
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
      }
      else {
        showMessage(thisObj, response.message, true)
      }

      await bFillCountyList(state_abbr)
      EnableDisableControls()

    }
    else {
      setLoading(false)
      return;
    }
    setLoading(false)
  }
  thisObj.onbtnDeleteCClick = onbtnDeleteCClick

  // Enable Disable function
  const EnableDisableControls = () => {
    try {
      enable(thisObj, 'btnAddC')
      enable(thisObj, 'btnEditC')
      enable(thisObj, 'btnDeleteC')

      let stateList = getData(thisObj, 'stateLength');
      if (stateList <= 0) {
        disable(thisObj, 'btnEditS')
        disable(thisObj, 'btnDleteS')
        disable(thisObj, 'btnAddC')
      }
      else {
        enable(thisObj, 'btnEditS')
        enable(thisObj, 'btnDleteS')
        enable(thisObj, 'btnAddC')
      }
      let coutyList = getData(thisObj, 'countyLength');
      if (coutyList <= 0) {
        disable(thisObj, 'btnEditC')
        disable(thisObj, 'btnDeleteC')
      }
      else {
        enable(thisObj, 'btnEditC')
        enable(thisObj, 'btnDeleteC')
      }

      if (stateList == 0) {
        enable(thisObj, 'btnAddC')
        enable(thisObj, 'btnEditC')
        enable(thisObj, 'btnDeleteC')
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred EnableDisable Function"
        );
      }
      return false;
    }
  }
  //Code for change and click event of state, County , eDisable button 
  let onddStateChange = async () => {
    setLoading(true);
    let state_abbr = getValue(thisObj, 'ddState')
    await bFillCountyList(state_abbr)
    EnableDisableControls()
    setLoading(false)
  }
  thisObj.onddStateChange = onddStateChange

  let onbtneDisableClick = async () => {
    EnableDisableControls()
  }
  thisObj.onbtneDisableClick = onbtneDisableClick

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_CountySetup*/}

              {/* END_USER_CODE-USER_BEFORE_CountySetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCountySetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCountySetup*/}

              <GroupBoxWidget conf={state.grpbxCountySetup} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnAddS*/}

                {/* END_USER_CODE-USER_BEFORE_btnAddS*/}

                <ButtonWidget
                  conf={state.btnAddS}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAddS*/}

                {/* END_USER_CODE-USER_AFTER_btnAddS*/}
                {/* START_USER_CODE-USER_BEFORE_btnEditS*/}

                {/* END_USER_CODE-USER_BEFORE_btnEditS*/}

                <ButtonWidget
                  conf={state.btnEditS}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEditS*/}

                {/* END_USER_CODE-USER_AFTER_btnEditS*/}
                {/* START_USER_CODE-USER_BEFORE_btnDleteS*/}

                {/* END_USER_CODE-USER_BEFORE_btnDleteS*/}

                <ButtonWidget
                  conf={state.btnDleteS}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDleteS*/}

                {/* END_USER_CODE-USER_AFTER_btnDleteS*/}
                {/* START_USER_CODE-USER_BEFORE_ddState*/}

                {/* END_USER_CODE-USER_BEFORE_ddState*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddState}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddState*/}

                {/* END_USER_CODE-USER_AFTER_ddState*/}
                {/* START_USER_CODE-USER_BEFORE_btnAddC*/}

                {/* END_USER_CODE-USER_BEFORE_btnAddC*/}

                <ButtonWidget
                  conf={state.btnAddC}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAddC*/}

                {/* END_USER_CODE-USER_AFTER_btnAddC*/}
                {/* START_USER_CODE-USER_BEFORE_btnEditC*/}

                {/* END_USER_CODE-USER_BEFORE_btnEditC*/}

                <ButtonWidget
                  conf={state.btnEditC}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEditC*/}

                {/* END_USER_CODE-USER_AFTER_btnEditC*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeleteC*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeleteC*/}

                <ButtonWidget
                  conf={state.btnDeleteC}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeleteC*/}

                {/* END_USER_CODE-USER_AFTER_btnDeleteC*/}
                {/* START_USER_CODE-USER_BEFORE_lstCounty*/}

                {/* END_USER_CODE-USER_BEFORE_lstCounty*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstCounty}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstCounty*/}

                {/* END_USER_CODE-USER_AFTER_lstCounty*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCountySetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCountySetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnreDisableClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnreDisableClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnreDisableClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btneDisable*/}

                {/* END_USER_CODE-USER_BEFORE_btneDisable*/}

                <ButtonWidget
                  conf={state.btneDisable}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btneDisable*/}

                {/* END_USER_CODE-USER_AFTER_btneDisable*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnreDisableClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnreDisableClose*/}

              {/* START_USER_CODE-USER_AFTER_CountySetup*/}

              {/* END_USER_CODE-USER_AFTER_CountySetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_CountySetup);
