/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_PeanutSpecificationProfile from "./PeanutSpecificationProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PeanutSpecificationProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PeanutSpecificationProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is PeanutSpecificationProfile Loads Successfully", () => {
    expect(screen.getByText("PeanutSpecificationProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for PeanutSpecificationProfile", () => {
    // START_USER_CODE-USER_PeanutSpecificationProfile_Custom_Test_Case
    // END_USER_CODE-USER_PeanutSpecificationProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_PeanutSpecificationProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_btnCancel"
      )
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutSpecificationProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCornIndicator(GroupBox Widget) Test Cases", async () => {
    const grpbxCornIndicator = screen.getByTestId("grpbxCornIndicator");
    expect(grpbxCornIndicator.tagName).toBe("BUTTON");
    expect(grpbxCornIndicator.type).toBe("button");
    expect(grpbxCornIndicator.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCornIndicator", () => {
    // START_USER_CODE-USER_grpbxCornIndicator_TEST
    // END_USER_CODE-USER_grpbxCornIndicator_TEST
  });
  test("grpbxFlavusIndicator(GroupBox Widget) Test Cases", async () => {
    const grpbxFlavusIndicator = screen.getByTestId("grpbxFlavusIndicator");
    expect(grpbxFlavusIndicator.tagName).toBe("BUTTON");
    expect(grpbxFlavusIndicator.type).toBe("button");
    expect(grpbxFlavusIndicator.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFlavusIndicator", () => {
    // START_USER_CODE-USER_grpbxFlavusIndicator_TEST
    // END_USER_CODE-USER_grpbxFlavusIndicator_TEST
  });
  test("grpbxPeanutSpecificationProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanutSpecificationProfile = screen.getByTestId(
      "grpbxPeanutSpecificationProfile"
    );
    expect(grpbxPeanutSpecificationProfile.tagName).toBe("BUTTON");
    expect(grpbxPeanutSpecificationProfile.type).toBe("button");
    expect(grpbxPeanutSpecificationProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPeanutSpecificationProfile", () => {
    // START_USER_CODE-USER_grpbxPeanutSpecificationProfile_TEST
    // END_USER_CODE-USER_grpbxPeanutSpecificationProfile_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_lblAddedBy"
      )
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_lblChangedBy"
      )
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblMax(Label Widget) Test Cases", async () => {
    const lblMax = screen.getByTestId("lblMax");
    expect(lblMax.tagName).toBe("LABEL");
    expect(lblMax.classList).toContain("form-label");
    expect(lblMax.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutSpecificationProfile_lblMax")
    );
  });
  test("Custom Test Cases for lblMax", () => {
    // START_USER_CODE-USER_lblMax_TEST
    // END_USER_CODE-USER_lblMax_TEST
  });
  test("lblMaximum(Label Widget) Test Cases", async () => {
    const lblMaximum = screen.getByTestId("lblMaximum");
    expect(lblMaximum.tagName).toBe("LABEL");
    expect(lblMaximum.classList).toContain("form-label");
    expect(lblMaximum.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_lblMaximum"
      )
    );
  });
  test("Custom Test Cases for lblMaximum", () => {
    // START_USER_CODE-USER_lblMaximum_TEST
    // END_USER_CODE-USER_lblMaximum_TEST
  });
  test("lblMin(Label Widget) Test Cases", async () => {
    const lblMin = screen.getByTestId("lblMin");
    expect(lblMin.tagName).toBe("LABEL");
    expect(lblMin.classList).toContain("form-label");
    expect(lblMin.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutSpecificationProfile_lblMin")
    );
  });
  test("Custom Test Cases for lblMin", () => {
    // START_USER_CODE-USER_lblMin_TEST
    // END_USER_CODE-USER_lblMin_TEST
  });
  test("lblMinimum(Label Widget) Test Cases", async () => {
    const lblMinimum = screen.getByTestId("lblMinimum");
    expect(lblMinimum.tagName).toBe("LABEL");
    expect(lblMinimum.classList).toContain("form-label");
    expect(lblMinimum.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_lblMinimum"
      )
    );
  });
  test("Custom Test Cases for lblMinimum", () => {
    // START_USER_CODE-USER_lblMinimum_TEST
    // END_USER_CODE-USER_lblMinimum_TEST
  });
  test("lblSeed(Label Widget) Test Cases", async () => {
    const lblSeed = screen.getByTestId("lblSeed");
    expect(lblSeed.tagName).toBe("LABEL");
    expect(lblSeed.classList).toContain("form-label");
    expect(lblSeed.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutSpecificationProfile_lblSeed")
    );
  });
  test("Custom Test Cases for lblSeed", () => {
    // START_USER_CODE-USER_lblSeed_TEST
    // END_USER_CODE-USER_lblSeed_TEST
  });
  test("txtArea(Textbox Widget) Test Cases", async () => {
    const txtArea = screen.getByTestId("txtArea");
    expect(txtArea.tagName).toBe("INPUT");
    expect(txtArea.type).toBe("text");
    expect(txtArea.classList).toContain("textboxWidgetClass");
    expect(txtArea.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtArea")
    );
    await act(async () => {
      userEvent.type(txtArea, "1");
    });
  });
  test("Custom Test Cases for txtArea", () => {
    // START_USER_CODE-USER_txtArea_TEST
    // END_USER_CODE-USER_txtArea_TEST
  });
  test("txtCncleRmdPercentMax(Textbox Widget) Test Cases", async () => {
    const txtCncleRmdPercentMax = screen.getByTestId("txtCncleRmdPercentMax");
    expect(txtCncleRmdPercentMax.tagName).toBe("INPUT");
    expect(txtCncleRmdPercentMax.type).toBe("text");
    expect(txtCncleRmdPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCncleRmdPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtCncleRmdPercentMax", () => {
    // START_USER_CODE-USER_txtCncleRmdPercentMax_TEST
    // END_USER_CODE-USER_txtCncleRmdPercentMax_TEST
  });
  test("txtCncleRmdPercentMin(Textbox Widget) Test Cases", async () => {
    const txtCncleRmdPercentMin = screen.getByTestId("txtCncleRmdPercentMin");
    expect(txtCncleRmdPercentMin.tagName).toBe("INPUT");
    expect(txtCncleRmdPercentMin.type).toBe("text");
    expect(txtCncleRmdPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtCncleRmdPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtCncleRmdPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtCncleRmdPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtCncleRmdPercentMin", () => {
    // START_USER_CODE-USER_txtCncleRmdPercentMin_TEST
    // END_USER_CODE-USER_txtCncleRmdPercentMin_TEST
  });
  test("txtCRBRPercentMax(Textbox Widget) Test Cases", async () => {
    const txtCRBRPercentMax = screen.getByTestId("txtCRBRPercentMax");
    expect(txtCRBRPercentMax.tagName).toBe("INPUT");
    expect(txtCRBRPercentMax.type).toBe("text");
    expect(txtCRBRPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCRBRPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtCRBRPercentMax", () => {
    // START_USER_CODE-USER_txtCRBRPercentMax_TEST
    // END_USER_CODE-USER_txtCRBRPercentMax_TEST
  });
  test("txtCRBRPercentMin(Textbox Widget) Test Cases", async () => {
    const txtCRBRPercentMin = screen.getByTestId("txtCRBRPercentMin");
    expect(txtCRBRPercentMin.tagName).toBe("INPUT");
    expect(txtCRBRPercentMin.type).toBe("text");
    expect(txtCRBRPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtCRBRPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtCRBRPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtCRBRPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtCRBRPercentMin", () => {
    // START_USER_CODE-USER_txtCRBRPercentMin_TEST
    // END_USER_CODE-USER_txtCRBRPercentMin_TEST
  });
  test("txtDamPercentMax(Textbox Widget) Test Cases", async () => {
    const txtDamPercentMax = screen.getByTestId("txtDamPercentMax");
    expect(txtDamPercentMax.tagName).toBe("INPUT");
    expect(txtDamPercentMax.type).toBe("text");
    expect(txtDamPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDamPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtDamPercentMax", () => {
    // START_USER_CODE-USER_txtDamPercentMax_TEST
    // END_USER_CODE-USER_txtDamPercentMax_TEST
  });
  test("txtDamPercentMin(Textbox Widget) Test Cases", async () => {
    const txtDamPercentMin = screen.getByTestId("txtDamPercentMin");
    expect(txtDamPercentMin.tagName).toBe("INPUT");
    expect(txtDamPercentMin.type).toBe("text");
    expect(txtDamPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtDamPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtDamPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtDamPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtDamPercentMin", () => {
    // START_USER_CODE-USER_txtDamPercentMin_TEST
    // END_USER_CODE-USER_txtDamPercentMin_TEST
  });
  test("txtDCOLSHELPercentMax(Textbox Widget) Test Cases", async () => {
    const txtDCOLSHELPercentMax = screen.getByTestId("txtDCOLSHELPercentMax");
    expect(txtDCOLSHELPercentMax.tagName).toBe("INPUT");
    expect(txtDCOLSHELPercentMax.type).toBe("text");
    expect(txtDCOLSHELPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDCOLSHELPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtDCOLSHELPercentMax", () => {
    // START_USER_CODE-USER_txtDCOLSHELPercentMax_TEST
    // END_USER_CODE-USER_txtDCOLSHELPercentMax_TEST
  });
  test("txtDCOLSHELPercentMin(Textbox Widget) Test Cases", async () => {
    const txtDCOLSHELPercentMin = screen.getByTestId("txtDCOLSHELPercentMin");
    expect(txtDCOLSHELPercentMin.tagName).toBe("INPUT");
    expect(txtDCOLSHELPercentMin.type).toBe("text");
    expect(txtDCOLSHELPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtDCOLSHELPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtDCOLSHELPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtDCOLSHELPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtDCOLSHELPercentMin", () => {
    // START_USER_CODE-USER_txtDCOLSHELPercentMin_TEST
    // END_USER_CODE-USER_txtDCOLSHELPercentMin_TEST
  });
  test("txtELKPercentMax(Textbox Widget) Test Cases", async () => {
    const txtELKPercentMax = screen.getByTestId("txtELKPercentMax");
    expect(txtELKPercentMax.tagName).toBe("INPUT");
    expect(txtELKPercentMax.type).toBe("text");
    expect(txtELKPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtELKPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtELKPercentMax", () => {
    // START_USER_CODE-USER_txtELKPercentMax_TEST
    // END_USER_CODE-USER_txtELKPercentMax_TEST
  });
  test("txtELKPercentMin(Textbox Widget) Test Cases", async () => {
    const txtELKPercentMin = screen.getByTestId("txtELKPercentMin");
    expect(txtELKPercentMin.tagName).toBe("INPUT");
    expect(txtELKPercentMin.type).toBe("text");
    expect(txtELKPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtELKPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtELKPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtELKPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtELKPercentMin", () => {
    // START_USER_CODE-USER_txtELKPercentMin_TEST
    // END_USER_CODE-USER_txtELKPercentMin_TEST
  });
  test("txtFanPercentMax(Textbox Widget) Test Cases", async () => {
    const txtFanPercentMax = screen.getByTestId("txtFanPercentMax");
    expect(txtFanPercentMax.tagName).toBe("INPUT");
    expect(txtFanPercentMax.type).toBe("text");
    expect(txtFanPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFanPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtFanPercentMax", () => {
    // START_USER_CODE-USER_txtFanPercentMax_TEST
    // END_USER_CODE-USER_txtFanPercentMax_TEST
  });
  test("txtFanPercentMin(Textbox Widget) Test Cases", async () => {
    const txtFanPercentMin = screen.getByTestId("txtFanPercentMin");
    expect(txtFanPercentMin.tagName).toBe("INPUT");
    expect(txtFanPercentMin.type).toBe("text");
    expect(txtFanPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtFanPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtFanPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtFanPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtFanPercentMin", () => {
    // START_USER_CODE-USER_txtFanPercentMin_TEST
    // END_USER_CODE-USER_txtFanPercentMin_TEST
  });
  test("txtFMPercentMax(Textbox Widget) Test Cases", async () => {
    const txtFMPercentMax = screen.getByTestId("txtFMPercentMax");
    expect(txtFMPercentMax.tagName).toBe("INPUT");
    expect(txtFMPercentMax.type).toBe("text");
    expect(txtFMPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFMPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtFMPercentMax", () => {
    // START_USER_CODE-USER_txtFMPercentMax_TEST
    // END_USER_CODE-USER_txtFMPercentMax_TEST
  });
  test("txtFMPercentMin(Textbox Widget) Test Cases", async () => {
    const txtFMPercentMin = screen.getByTestId("txtFMPercentMin");
    expect(txtFMPercentMin.tagName).toBe("INPUT");
    expect(txtFMPercentMin.type).toBe("text");
    expect(txtFMPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtFMPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtFMPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtFMPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtFMPercentMin", () => {
    // START_USER_CODE-USER_txtFMPercentMin_TEST
    // END_USER_CODE-USER_txtFMPercentMin_TEST
  });
  test("txtFrezDamPercentMax(Textbox Widget) Test Cases", async () => {
    const txtFrezDamPercentMax = screen.getByTestId("txtFrezDamPercentMax");
    expect(txtFrezDamPercentMax.tagName).toBe("INPUT");
    expect(txtFrezDamPercentMax.type).toBe("text");
    expect(txtFrezDamPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFrezDamPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtFrezDamPercentMax", () => {
    // START_USER_CODE-USER_txtFrezDamPercentMax_TEST
    // END_USER_CODE-USER_txtFrezDamPercentMax_TEST
  });
  test("txtFrezDamPercentMin(Textbox Widget) Test Cases", async () => {
    const txtFrezDamPercentMin = screen.getByTestId("txtFrezDamPercentMin");
    expect(txtFrezDamPercentMin.tagName).toBe("INPUT");
    expect(txtFrezDamPercentMin.type).toBe("text");
    expect(txtFrezDamPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtFrezDamPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtFrezDamPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtFrezDamPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtFrezDamPercentMin", () => {
    // START_USER_CODE-USER_txtFrezDamPercentMin_TEST
    // END_USER_CODE-USER_txtFrezDamPercentMin_TEST
  });
  test("txtHULBritPercentMax(Textbox Widget) Test Cases", async () => {
    const txtHULBritPercentMax = screen.getByTestId("txtHULBritPercentMax");
    expect(txtHULBritPercentMax.tagName).toBe("INPUT");
    expect(txtHULBritPercentMax.type).toBe("text");
    expect(txtHULBritPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHULBritPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtHULBritPercentMax", () => {
    // START_USER_CODE-USER_txtHULBritPercentMax_TEST
    // END_USER_CODE-USER_txtHULBritPercentMax_TEST
  });
  test("txtHULBritPercentMin(Textbox Widget) Test Cases", async () => {
    const txtHULBritPercentMin = screen.getByTestId("txtHULBritPercentMin");
    expect(txtHULBritPercentMin.tagName).toBe("INPUT");
    expect(txtHULBritPercentMin.type).toBe("text");
    expect(txtHULBritPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtHULBritPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtHULBritPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtHULBritPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtHULBritPercentMin", () => {
    // START_USER_CODE-USER_txtHULBritPercentMin_TEST
    // END_USER_CODE-USER_txtHULBritPercentMin_TEST
  });
  test("txtHullPercentMax(Textbox Widget) Test Cases", async () => {
    const txtHullPercentMax = screen.getByTestId("txtHullPercentMax");
    expect(txtHullPercentMax.tagName).toBe("INPUT");
    expect(txtHullPercentMax.type).toBe("text");
    expect(txtHullPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtHullPercentMax", () => {
    // START_USER_CODE-USER_txtHullPercentMax_TEST
    // END_USER_CODE-USER_txtHullPercentMax_TEST
  });
  test("txtHullPercentMin(Textbox Widget) Test Cases", async () => {
    const txtHullPercentMin = screen.getByTestId("txtHullPercentMin");
    expect(txtHullPercentMin.tagName).toBe("INPUT");
    expect(txtHullPercentMin.type).toBe("text");
    expect(txtHullPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtHullPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtHullPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtHullPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtHullPercentMin", () => {
    // START_USER_CODE-USER_txtHullPercentMin_TEST
    // END_USER_CODE-USER_txtHullPercentMin_TEST
  });
  test("txtJumboPercentMax(Textbox Widget) Test Cases", async () => {
    const txtJumboPercentMax = screen.getByTestId("txtJumboPercentMax");
    expect(txtJumboPercentMax.tagName).toBe("INPUT");
    expect(txtJumboPercentMax.type).toBe("text");
    expect(txtJumboPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtJumboPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtJumboPercentMax", () => {
    // START_USER_CODE-USER_txtJumboPercentMax_TEST
    // END_USER_CODE-USER_txtJumboPercentMax_TEST
  });
  test("txtJumboPercentMin(Textbox Widget) Test Cases", async () => {
    const txtJumboPercentMin = screen.getByTestId("txtJumboPercentMin");
    expect(txtJumboPercentMin.tagName).toBe("INPUT");
    expect(txtJumboPercentMin.type).toBe("text");
    expect(txtJumboPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtJumboPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtJumboPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtJumboPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtJumboPercentMin", () => {
    // START_USER_CODE-USER_txtJumboPercentMin_TEST
    // END_USER_CODE-USER_txtJumboPercentMin_TEST
  });
  test("txtLSKPercentMax(Textbox Widget) Test Cases", async () => {
    const txtLSKPercentMax = screen.getByTestId("txtLSKPercentMax");
    expect(txtLSKPercentMax.tagName).toBe("INPUT");
    expect(txtLSKPercentMax.type).toBe("text");
    expect(txtLSKPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLSKPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtLSKPercentMax", () => {
    // START_USER_CODE-USER_txtLSKPercentMax_TEST
    // END_USER_CODE-USER_txtLSKPercentMax_TEST
  });
  test("txtLSKPercentMin(Textbox Widget) Test Cases", async () => {
    const txtLSKPercentMin = screen.getByTestId("txtLSKPercentMin");
    expect(txtLSKPercentMin.tagName).toBe("INPUT");
    expect(txtLSKPercentMin.type).toBe("text");
    expect(txtLSKPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtLSKPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtLSKPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtLSKPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtLSKPercentMin", () => {
    // START_USER_CODE-USER_txtLSKPercentMin_TEST
    // END_USER_CODE-USER_txtLSKPercentMin_TEST
  });
  test("txtMoistPercentMax(Textbox Widget) Test Cases", async () => {
    const txtMoistPercentMax = screen.getByTestId("txtMoistPercentMax");
    expect(txtMoistPercentMax.tagName).toBe("INPUT");
    expect(txtMoistPercentMax.type).toBe("text");
    expect(txtMoistPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMoistPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtMoistPercentMax", () => {
    // START_USER_CODE-USER_txtMoistPercentMax_TEST
    // END_USER_CODE-USER_txtMoistPercentMax_TEST
  });
  test("txtMoistPercentMin(Textbox Widget) Test Cases", async () => {
    const txtMoistPercentMin = screen.getByTestId("txtMoistPercentMin");
    expect(txtMoistPercentMin.tagName).toBe("INPUT");
    expect(txtMoistPercentMin.type).toBe("text");
    expect(txtMoistPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtMoistPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtMoistPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtMoistPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtMoistPercentMin", () => {
    // START_USER_CODE-USER_txtMoistPercentMin_TEST
    // END_USER_CODE-USER_txtMoistPercentMin_TEST
  });
  test("txtOKPercentMax(Textbox Widget) Test Cases", async () => {
    const txtOKPercentMax = screen.getByTestId("txtOKPercentMax");
    expect(txtOKPercentMax.tagName).toBe("INPUT");
    expect(txtOKPercentMax.type).toBe("text");
    expect(txtOKPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOKPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtOKPercentMax", () => {
    // START_USER_CODE-USER_txtOKPercentMax_TEST
    // END_USER_CODE-USER_txtOKPercentMax_TEST
  });
  test("txtOKPercentMin(Textbox Widget) Test Cases", async () => {
    const txtOKPercentMin = screen.getByTestId("txtOKPercentMin");
    expect(txtOKPercentMin.tagName).toBe("INPUT");
    expect(txtOKPercentMin.type).toBe("text");
    expect(txtOKPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtOKPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtOKPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtOKPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtOKPercentMin", () => {
    // START_USER_CODE-USER_txtOKPercentMin_TEST
    // END_USER_CODE-USER_txtOKPercentMin_TEST
  });
  test("txtSMKPercentMam(Textbox Widget) Test Cases", async () => {
    const txtSMKPercentMam = screen.getByTestId("txtSMKPercentMam");
    expect(txtSMKPercentMam.tagName).toBe("INPUT");
    expect(txtSMKPercentMam.type).toBe("text");
    expect(txtSMKPercentMam.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMKPercentMam, "1");
    });
  });
  test("Custom Test Cases for txtSMKPercentMam", () => {
    // START_USER_CODE-USER_txtSMKPercentMam_TEST
    // END_USER_CODE-USER_txtSMKPercentMam_TEST
  });
  test("txtSMKPercentMin(Textbox Widget) Test Cases", async () => {
    const txtSMKPercentMin = screen.getByTestId("txtSMKPercentMin");
    expect(txtSMKPercentMin.tagName).toBe("INPUT");
    expect(txtSMKPercentMin.type).toBe("text");
    expect(txtSMKPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtSMKPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtSMKPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtSMKPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtSMKPercentMin", () => {
    // START_USER_CODE-USER_txtSMKPercentMin_TEST
    // END_USER_CODE-USER_txtSMKPercentMin_TEST
  });
  test("txtSSPercentMax(Textbox Widget) Test Cases", async () => {
    const txtSSPercentMax = screen.getByTestId("txtSSPercentMax");
    expect(txtSSPercentMax.tagName).toBe("INPUT");
    expect(txtSSPercentMax.type).toBe("text");
    expect(txtSSPercentMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSSPercentMax, "1");
    });
  });
  test("Custom Test Cases for txtSSPercentMax", () => {
    // START_USER_CODE-USER_txtSSPercentMax_TEST
    // END_USER_CODE-USER_txtSSPercentMax_TEST
  });
  test("txtSSPercentMin(Textbox Widget) Test Cases", async () => {
    const txtSSPercentMin = screen.getByTestId("txtSSPercentMin");
    expect(txtSSPercentMin.tagName).toBe("INPUT");
    expect(txtSSPercentMin.type).toBe("text");
    expect(txtSSPercentMin.classList).toContain("textboxWidgetClass");
    expect(txtSSPercentMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtSSPercentMin"
      )
    );
    await act(async () => {
      userEvent.type(txtSSPercentMin, "1");
    });
  });
  test("Custom Test Cases for txtSSPercentMin", () => {
    // START_USER_CODE-USER_txtSSPercentMin_TEST
    // END_USER_CODE-USER_txtSSPercentMin_TEST
  });
  test("txtVicamPPBMax(Textbox Widget) Test Cases", async () => {
    const txtVicamPPBMax = screen.getByTestId("txtVicamPPBMax");
    expect(txtVicamPPBMax.tagName).toBe("INPUT");
    expect(txtVicamPPBMax.type).toBe("text");
    expect(txtVicamPPBMax.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVicamPPBMax, "1");
    });
  });
  test("Custom Test Cases for txtVicamPPBMax", () => {
    // START_USER_CODE-USER_txtVicamPPBMax_TEST
    // END_USER_CODE-USER_txtVicamPPBMax_TEST
  });
  test("txtVicamPPBMin(Textbox Widget) Test Cases", async () => {
    const txtVicamPPBMin = screen.getByTestId("txtVicamPPBMin");
    expect(txtVicamPPBMin.tagName).toBe("INPUT");
    expect(txtVicamPPBMin.type).toBe("text");
    expect(txtVicamPPBMin.classList).toContain("textboxWidgetClass");
    expect(txtVicamPPBMin.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:PeanutSpecificationProfile_txtVicamPPBMin"
      )
    );
    await act(async () => {
      userEvent.type(txtVicamPPBMin, "1");
    });
  });
  test("Custom Test Cases for txtVicamPPBMin", () => {
    // START_USER_CODE-USER_txtVicamPPBMin_TEST
    // END_USER_CODE-USER_txtVicamPPBMin_TEST
  });
});
