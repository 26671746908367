/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  getValue,
  setValue,
  setData,
  getData
} from "../../shared/WindowImports";

import "./EWRSendRRI.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS

const listPeanutType = [];
const listReceiptStatus = [];
const listCCCLoanStatus = [];

function WarehouseReceipts_EWRSendRRI(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "EWRSendRRI",
    windowName: "EWRSendRRI",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.EWRSendRRI",
    // START_USER_CODE-USER_EWRSendRRI_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "RRI Send",
      scrCode: "PN1090K",
    },
    // END_USER_CODE-USER_EWRSendRRI_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnSend: {
      name: "btnSend",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Send",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSend_PROPERTIES

      // END_USER_CODE-USER_btnSend_PROPERTIES
    },
    lblFileNumber: {
      name: "lblFileNumber",
      type: "LabelWidget",
      parent: "grpbxEWRSendRRI",
      Label: "File Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFileNumber_PROPERTIES

      // END_USER_CODE-USER_lblFileNumber_PROPERTIES
    },
    lblFileNumberValue: {
      name: "lblFileNumberValue",
      type: "LabelWidget",
      parent: "grpbxEWRSendRRI",
      // Label: "lblFileNumber",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFileNumberValue_PROPERTIES

      // END_USER_CODE-USER_lblFileNumberValue_PROPERTIES
    },
    txtLicenseNumber: {
      name: "txtLicenseNumber",
      type: "TextBoxWidget",
      parent: "grpbxEWRSendRRI",
      Label: "License Number:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLicenseNumber_PROPERTIES

      // END_USER_CODE-USER_txtLicenseNumber_PROPERTIES
    },
    txtReceiptNumber: {
      name: "txtReceiptNumber",
      type: "TextBoxWidget",
      parent: "grpbxEWRSendRRI",
      Label: "Receipt Number:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReceiptNumber_PROPERTIES

      // END_USER_CODE-USER_txtReceiptNumber_PROPERTIES
    },
    txtWarehouseCode: {
      name: "txtWarehouseCode",
      type: "TextBoxWidget",
      parent: "grpbxEWRSendRRI",
      Label: "Warehouse Code:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWarehouseCode_PROPERTIES

      // END_USER_CODE-USER_txtWarehouseCode_PROPERTIES
    },
    grpbxEWRSendRRI: {
      name: "grpbxEWRSendRRI",
      type: "GroupBoxWidget",
      parent: "EWRSendRRI",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxEWRSendRRI_PROPERTIES

      // END_USER_CODE-USER_grpbxEWRSendRRI_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "EWRSendRRI",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    const warehouseCode = getData(thisObj, 'txtWarehouseCode');
    if (warehouseCode !== null && warehouseCode !== undefined) {
      setValue(thisObj, 'txtWarehouseCode', warehouseCode);
    }
  }, [getData(thisObj, 'txtWarehouseCode')]);

  useEffect(() => {
    const receiptNumber = getData(thisObj, 'txtReceiptNumber');
    if (receiptNumber !== null && receiptNumber !== undefined) {
      setValue(thisObj, 'txtReceiptNumber', receiptNumber);
    }
  }, [getData(thisObj, 'txtReceiptNumber')]);

  // START_USER_CODE-USER_METHODS
  const formLoad = () => {
    fillPeanutType();
    fillReceiptStatus();
    fillCCCLoanStatus();
  }

  const fillPeanutType = () => {
    listPeanutType.push({ key: '', description: '' });
    listPeanutType.push({ key: 'RUN', description: 'Runner' });
    listPeanutType.push({ key: 'SPE', description: 'Spanish Southeast' });
    listPeanutType.push({ key: 'SPW', description: 'Spanish Southwest' });
    listPeanutType.push({ key: 'VAL', description: 'Valencia' });
    listPeanutType.push({ key: 'VIR', description: 'Virginia' });
  }

  const fillReceiptStatus = () => {
    listReceiptStatus.push({ key: '', description: '' });
    listReceiptStatus.push({ key: 'C', description: 'Cancelled' });
    listReceiptStatus.push({ key: 'O', description: 'Open' });
    listReceiptStatus.push({ key: 'P', description: 'Paper' });
    listReceiptStatus.push({ key: 'V', description: 'Void' });
  }

  const fillCCCLoanStatus = () => {
    listCCCLoanStatus.push({ key: '', description: '' });
    listCCCLoanStatus.push({ key: 'A', description: 'Approved' });
    listCCCLoanStatus.push({ key: 'P', description: 'Pending' });
    listCCCLoanStatus.push({ key: 'R', description: 'Rejected' });
    listCCCLoanStatus.push({ key: 'E', description: 'Released' });
  }

  const ontxtWarehouseCodeBlur = () => {
    let warehouseNumber = getValue(thisObj, 'txtWarehouseCode') || '';
    warehouseNumber = warehouseNumber.replace(/-/g, '');
    warehouseNumber = warehouseNumber.replace(/\s/g, '');
    setValue(thisObj, 'txtWarehouseCode', warehouseNumber);
  }

  thisObj.ontxtWarehouseCodeBlur = ontxtWarehouseCodeBlur;

  const ontxtReceiptNumberBlur = () => {
    let receiptNumber = getValue(thisObj, 'txtReceiptNumber') || '';
    receiptNumber = receiptNumber.replace(/-/g, '');
    receiptNumber = receiptNumber.replace(/\s/g, '');
    setValue(thisObj, 'txtReceiptNumber', receiptNumber);
  }

  thisObj.ontxtReceiptNumberBlur = ontxtReceiptNumberBlur;

  const ontxtLicenseNumberBlur = () => {
    let licenceNumber = getValue(thisObj, 'txtLicenseNumber') || '';
    licenceNumber = licenceNumber.replace(/-/g, '');
    licenceNumber = licenceNumber.replace(/\s/g, '');
    setValue(thisObj, 'txtLicenseNumber', licenceNumber);
  }

  thisObj.ontxtLicenseNumberBlur = ontxtLicenseNumberBlur;

  const isFormValid = () => {
    const warehouseNumber = getValue(thisObj, 'txtWarehouseCode');
    const receiptNumber = getValue(thisObj, 'txtReceiptNumber');
    const licenceNumber = getValue(thisObj, 'txtLicenseNumber');
    if (!warehouseNumber
      && !receiptNumber
      && !licenceNumber) {
      showMessage(thisObj, 'Must enter at least one value to filter on.');
      return false;
    }

    if (Boolean(warehouseNumber) & isNaN(warehouseNumber)) {
      showMessage(thisObj, 'Warehouse Code must be numeric.');
      return false;
    }

    if (Boolean(licenceNumber) & isNaN(licenceNumber)) {
      showMessage(thisObj, 'License Number must be numeric.');
      return false;
    }

    return true;
  }

  const setDataForCreateEWRInquirySend = (
    warehouseCode, receiptNumber, licenceNumber, peanutType, receiptStatus, cccLoanStatus,
    fsaFarmNumber, buyingPointNumber, loanNumber
  ) => {
    const createEwrInquirySendData = {};
    createEwrInquirySendData.whse_code = warehouseCode || 72205;
    createEwrInquirySendData.receipt_num = receiptNumber || "32322";
    createEwrInquirySendData.license_num = licenceNumber || 0;
    createEwrInquirySendData.fsa_farm_num = fsaFarmNumber || 0;
    createEwrInquirySendData.pnut_type = peanutType || '';
    createEwrInquirySendData.buying_pt_num = buyingPointNumber || 0;
    createEwrInquirySendData.loan_num = loanNumber || 0;
    createEwrInquirySendData.receipt_status = receiptStatus || '';
    createEwrInquirySendData.ccc_loan_status = cccLoanStatus || '';
    setData(thisObj, 'CreateEWRInquirySend', createEwrInquirySendData);
  }

  const onbtnSendClick = () => {
    let lstrxml = '';
    try {
      if (isFormValid()) {
        const warehouseNumber = parseInt(getValue(thisObj, 'txtWarehouseCode') || '0');
        const receiptNumber = getValue(thisObj, 'txtReceiptNumber') || '';
        const licenceNumber = parseInt(getValue(thisObj, 'txtLicenseNumber') || '0');

        // Get data from parent form
        const ewrInquiryData = getData(thisObj, 'ewrInquiryData') || {};

        const peanutType = ewrInquiryData?.peanutType || listPeanutType[0].key;
        const receiptStatus = ewrInquiryData?.receiptStatus || listReceiptStatus[0].key;
        const cccLoanStatus = ewrInquiryData?.cccLoanStatus || listCCCLoanStatus[0].key;
        const fsaFarmNumber = ewrInquiryData?.fsaFarmNumber || 0;
        const buyingPointNumber = ewrInquiryData?.buyingPointNumber || 0;
        const loanNumber = ewrInquiryData?.loanNumber || 0;

        setDataForCreateEWRInquirySend(warehouseNumber, receiptNumber, licenceNumber,
          peanutType, receiptStatus, cccLoanStatus, fsaFarmNumber, buyingPointNumber, loanNumber);
        const createEwrInquirySendObj = getData(thisObj, 'CreateEWRInquirySend');

        WarehouseReceiptService.CreateEwrInquirySendDetails(createEwrInquirySendObj)
          .then(response => {
            setData(thisObj, 'lstrxml', response);
            if (response.status === 200 && response.result) {
              showMessage(thisObj, 'EWR Inquiry Send table successfully updated.', true);
              setValue(thisObj, 'lblFileNumberValue', response.result);
            } else {
              showMessage(thisObj, 'An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
            }
            return true;
          }).catch(error => {
            if (error instanceof EvalError) {
              showMessage(thisObj, error.message);
            } else {
              showMessage(
                thisObj,
                `Error occurred during CreateEWRInquirySend API in EWRSendRRI: ${error.toString()}`
              );
            }
            return false;
          });
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during CreateEWRInquirySend in EWRSendRRI: ${error.toString()}`
        );
      }
      return false;
    }
  }

  thisObj.onbtnSendClick = onbtnSendClick;

  const onbtnCloseClick = () => {
    try {
      document.getElementById("WarehouseReceipts_EWRSendRRIPopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError)
        showMessage(thisObj, err.message)
      else
        showMessage(thisObj, `Exception in Close button Click: ${err.toString()}`);
    }
  }

  thisObj.onbtnCloseClick = onbtnCloseClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_EWRSendRRI*/}

              {/* END_USER_CODE-USER_BEFORE_EWRSendRRI*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxEWRSendRRI*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxEWRSendRRI*/}

              <GroupBoxWidget conf={state.grpbxEWRSendRRI} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtWarehouseCode*/}

                {/* END_USER_CODE-USER_BEFORE_txtWarehouseCode*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWarehouseCode}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWarehouseCode*/}

                {/* END_USER_CODE-USER_AFTER_txtWarehouseCode*/}
                {/* START_USER_CODE-USER_BEFORE_txtReceiptNumber*/}

                {/* END_USER_CODE-USER_BEFORE_txtReceiptNumber*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtReceiptNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtReceiptNumber*/}

                {/* END_USER_CODE-USER_AFTER_txtReceiptNumber*/}
                {/* START_USER_CODE-USER_BEFORE_txtLicenseNumber*/}

                {/* END_USER_CODE-USER_BEFORE_txtLicenseNumber*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLicenseNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLicenseNumber*/}

                {/* END_USER_CODE-USER_AFTER_txtLicenseNumber*/}
                {/* START_USER_CODE-USER_BEFORE_lblFileNumber*/}

                {/* END_USER_CODE-USER_BEFORE_lblFileNumber*/}

                <LabelWidget
                  values={values}
                  conf={state.lblFileNumber}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblFileNumber*/}

                {/* END_USER_CODE-USER_AFTER_lblFileNumber*/}
                {/* START_USER_CODE-USER_BEFORE_lblFileNumberValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblFileNumberValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblFileNumberValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblFileNumberValue*/}

                {/* END_USER_CODE-USER_AFTER_lblFileNumberValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxEWRSendRRI*/}

              {/* END_USER_CODE-USER_AFTER_grpbxEWRSendRRI*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnSend*/}

                {/* END_USER_CODE-USER_BEFORE_btnSend*/}

                <ButtonWidget
                  conf={state.btnSend}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSend*/}

                {/* END_USER_CODE-USER_AFTER_btnSend*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_EWRSendRRI*/}

              {/* END_USER_CODE-USER_AFTER_EWRSendRRI*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_EWRSendRRI);
