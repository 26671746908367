/* eslint-disable*/
import React from "react";
import Settlements_ContApplEditAuthorize from "./ContApplEditAuthorize";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ContApplEditAuthorize Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ContApplEditAuthorize />);
    });
  });
  afterEach(cleanup);
  test("is ContApplEditAuthorize Loads Successfully", () => {
    expect(screen.getByText("ContApplEditAuthorize")).toBeInTheDocument;
  });
  test("Custom Test Cases for ContApplEditAuthorize", () => {
    // START_USER_CODE-USER_ContApplEditAuthorize_Custom_Test_Case
    // END_USER_CODE-USER_ContApplEditAuthorize_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ContApplEditAuthorize />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("Settlements:ContApplEditAuthorize_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnCreateAuthorization(Button Widget) Test Cases", async () => {
    const btnCreateAuthorization = screen.getByTestId("btnCreateAuthorization");
    expect(btnCreateAuthorization).toBeInTheDocument;
    expect(btnCreateAuthorization.textContent).toEqual(
      t("Settlements:ContApplEditAuthorize_btnCreateAuthorization")
    );
  });
  test("Custom Test Cases for btnCreateAuthorization", () => {
    // START_USER_CODE-USER_btnCreateAuthorization_TEST
    // END_USER_CODE-USER_btnCreateAuthorization_TEST
  });
  test("grpbxContApplEditAuthorize(GroupBox Widget) Test Cases", async () => {
    const grpbxContApplEditAuthorize = screen.getByTestId(
      "grpbxContApplEditAuthorize"
    );
    expect(grpbxContApplEditAuthorize.tagName).toBe("BUTTON");
    expect(grpbxContApplEditAuthorize.type).toBe("button");
    expect(grpbxContApplEditAuthorize.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContApplEditAuthorize", () => {
    // START_USER_CODE-USER_grpbxContApplEditAuthorize_TEST
    // END_USER_CODE-USER_grpbxContApplEditAuthorize_TEST
  });
  test("txtSC95(Textbox Widget) Test Cases", async () => {
    const txtSC95 = screen.getByTestId("txtSC95");
    expect(txtSC95.tagName).toBe("INPUT");
    expect(txtSC95.type).toBe("text");
    expect(txtSC95.classList).toContain("textboxWidgetClass");
    expect(txtSC95.previousElementSibling.textContent).toEqual(
      t("Settlements:ContApplEditAuthorize_txtSC95")
    );
    await act(async () => {
      userEvent.type(txtSC95, "1");
    });
  });
  test("Custom Test Cases for txtSC95", () => {
    // START_USER_CODE-USER_txtSC95_TEST
    // END_USER_CODE-USER_txtSC95_TEST
  });
});
