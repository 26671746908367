/* eslint-disable*/
import React from "react";
import GroupBoxWidget from "./GroupBoxWidget";
import { screen } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

describe("GroupBoxWidget1 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let groupboxwidget1 = {
        name: "groupboxwidget1",
        type: "GroupBoxWidget",
        parent: "TestScreen",
        Label: "Select ReleatedWidgets",
        Height: "",
        Width: "",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      renderTestScreen(
        <GroupBoxWidget conf={groupboxwidget1} screenConf={stateScreenConf} />
      );
    });
  });
  afterEach(cleanup);
  test("is GroupBoxWidget1 Loads Succesfully", async () => {
    const groupboxwidget1 = screen.getByTestId("groupboxwidget1");
    expect(groupboxwidget1.tagName).toBe("BUTTON");
    expect(groupboxwidget1.type).toBe("button");
    expect(groupboxwidget1.classList).toContain("btn");
    //expect(groupboxwidget1.textContent).toEqual("Select ReleatedWidgets");
  });
});
describe("GroupBoxWidget2 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let groupboxwidget2 = {
        name: "groupboxwidget2",
        type: "GroupBoxWidget",
        parent: "TestScreen",
        Label: "GroupBoxWidget2 TestCase",
        Height: "",
        Width: "",
        HasLabel: true,
        defaultCollapsed: true,
      };
      let groupboxwidget3 = {
        name: "groupboxwidget3",
        type: "GroupBoxWidget",
        parent: "TestScreen",
        Label: "GroupBoxWidget3 TestCase",
        Height: "",
        Width: "",
        hideContainer: true,
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      renderTestScreen(
        <div>
          <GroupBoxWidget conf={groupboxwidget2} screenConf={stateScreenConf} />
          <GroupBoxWidget conf={groupboxwidget3} screenConf={stateScreenConf} />
        </div>
      );
    });
  });
  afterEach(cleanup);
  test("is GroupBoxWidget2 Loads Succesfully", async () => {
    const groupboxwidget2 = screen.getByTestId("groupboxwidget2");
    expect(groupboxwidget2.tagName).toBe("BUTTON");
    expect(groupboxwidget2.type).toBe("button");
    expect(groupboxwidget2.classList).toContain("btn");
    await act(async () => {
      userEvent.click(groupboxwidget2);
    });
  });
});
