/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  TextAreaWidget,
  LabelWidget,
  getData,
  setValue
} from "../../shared/WindowImports";

import "./TransferErrorList.scss";
import ContractManagement_header from "../../Header/Header";
// START_USER_CODE-USER_IMPORTS

// END_USER_CODE-USER_IMPORTS
function StockTransfer_TransferErrorList(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "TransferErrorList",
    windowName: "TransferErrorList",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "StockTransfer.TransferErrorList",
    // START_USER_CODE-USER_TransferErrorList_PROPERTIES
    headerData: {
      scrName: "Transfer Error List",
      scrCode: "",
    },
    // END_USER_CODE-USER_TransferErrorList_PROPERTIES
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    lblHeading: {
      name: "lblHeading",
      type: "LabelWidget",
      parent: "grpbxError",
      Label: "Transfer Error List",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblHeading_PROPERTIES

      // END_USER_CODE-USER_lblHeading_PROPERTIES
    },
    txtarError: {
      name: "txtarError",
      type: "TextAreaWidget",
      parent: "grpbxError",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      // START_USER_CODE-USER_txtarError_PROPERTIES

      // END_USER_CODE-USER_txtarError_PROPERTIES
    },
    grpbxError: {
      name: "grpbxError",
      type: "GroupBoxWidget",
      parent: "TransferErrorList",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxError_PROPERTIES

      // END_USER_CODE-USER_grpbxError_PROPERTIES
    },
    cmndCntnrActions: {
      name: "cmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "TransferErrorList",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formload()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  function formload() {
    let errorlist = getData(thisObj, "DataForErrorList")
    let text = []
    for ( let key in errorlist ) {
      text.push(key)
    }
    setValue(thisObj, "txtarError", text.join('\n'))
  }

  const onbtnOkClick = () => {
    document.getElementById("StockTransfer_TransferErrorListPopUp").childNodes[0].click();
  }
  thisObj.onbtnOkClick = onbtnOkClick;

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
           
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_TransferErrorList*/}

              {/* END_USER_CODE-USER_BEFORE_TransferErrorList*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxError*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxError*/}

              <GroupBoxWidget conf={state.grpbxError} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtarError*/}

                {/* END_USER_CODE-USER_BEFORE_txtarError*/}
                <LabelWidget
                  values={values}
                  conf={state.lblHeading}
                  screenConf={state}
                ></LabelWidget>
                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarError}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarError*/}

                {/* END_USER_CODE-USER_AFTER_txtarError*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxError*/}

              {/* END_USER_CODE-USER_AFTER_grpbxError*/}
              {/* START_USER_CODE-USER_BEFORE_cmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmndCntnrActions*/}

              <CommandContainerWidget
                conf={state.cmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_TransferErrorList*/}

              {/* END_USER_CODE-USER_AFTER_TransferErrorList*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(StockTransfer_TransferErrorList);
