/* eslint-disable*/
import React from "react";
import WarehouseReceipts_DelvSettleProfile from "./DelvSettleProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DelvSettleProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvSettleProfile />);
    });
  });
  afterEach(cleanup);
  test("is DelvSettleProfile Loads Successfully", () => {
    expect(screen.getByText("DelvSettleProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for DelvSettleProfile", () => {
    // START_USER_CODE-USER_DelvSettleProfile_Custom_Test_Case
    // END_USER_CODE-USER_DelvSettleProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvSettleProfile />);
    });
  });
  afterEach(cleanup);
  test("btn0(Button Widget) Test Cases", async () => {
    const btn0 = screen.getByTestId("btn0");
    expect(btn0).toBeInTheDocument;
    expect(btn0.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn0")
    );
  });
  test("Custom Test Cases for btn0", () => {
    // START_USER_CODE-USER_btn0_TEST
    // END_USER_CODE-USER_btn0_TEST
  });
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btn10(Button Widget) Test Cases", async () => {
    const btn10 = screen.getByTestId("btn10");
    expect(btn10).toBeInTheDocument;
    expect(btn10.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn10")
    );
  });
  test("Custom Test Cases for btn10", () => {
    // START_USER_CODE-USER_btn10_TEST
    // END_USER_CODE-USER_btn10_TEST
  });
  test("btn11(Button Widget) Test Cases", async () => {
    const btn11 = screen.getByTestId("btn11");
    expect(btn11).toBeInTheDocument;
    expect(btn11.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn11")
    );
  });
  test("Custom Test Cases for btn11", () => {
    // START_USER_CODE-USER_btn11_TEST
    // END_USER_CODE-USER_btn11_TEST
  });
  test("btn12(Button Widget) Test Cases", async () => {
    const btn12 = screen.getByTestId("btn12");
    expect(btn12).toBeInTheDocument;
    expect(btn12.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn12")
    );
  });
  test("Custom Test Cases for btn12", () => {
    // START_USER_CODE-USER_btn12_TEST
    // END_USER_CODE-USER_btn12_TEST
  });
  test("btn13(Button Widget) Test Cases", async () => {
    const btn13 = screen.getByTestId("btn13");
    expect(btn13).toBeInTheDocument;
    expect(btn13.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn13")
    );
  });
  test("Custom Test Cases for btn13", () => {
    // START_USER_CODE-USER_btn13_TEST
    // END_USER_CODE-USER_btn13_TEST
  });
  test("btn14(Button Widget) Test Cases", async () => {
    const btn14 = screen.getByTestId("btn14");
    expect(btn14).toBeInTheDocument;
    expect(btn14.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn14")
    );
  });
  test("Custom Test Cases for btn14", () => {
    // START_USER_CODE-USER_btn14_TEST
    // END_USER_CODE-USER_btn14_TEST
  });
  test("btn15(Button Widget) Test Cases", async () => {
    const btn15 = screen.getByTestId("btn15");
    expect(btn15).toBeInTheDocument;
    expect(btn15.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn15")
    );
  });
  test("Custom Test Cases for btn15", () => {
    // START_USER_CODE-USER_btn15_TEST
    // END_USER_CODE-USER_btn15_TEST
  });
  test("btn16(Button Widget) Test Cases", async () => {
    const btn16 = screen.getByTestId("btn16");
    expect(btn16).toBeInTheDocument;
    expect(btn16.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn16")
    );
  });
  test("Custom Test Cases for btn16", () => {
    // START_USER_CODE-USER_btn16_TEST
    // END_USER_CODE-USER_btn16_TEST
  });
  test("btn17(Button Widget) Test Cases", async () => {
    const btn17 = screen.getByTestId("btn17");
    expect(btn17).toBeInTheDocument;
    expect(btn17.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn17")
    );
  });
  test("Custom Test Cases for btn17", () => {
    // START_USER_CODE-USER_btn17_TEST
    // END_USER_CODE-USER_btn17_TEST
  });
  test("btn18(Button Widget) Test Cases", async () => {
    const btn18 = screen.getByTestId("btn18");
    expect(btn18).toBeInTheDocument;
    expect(btn18.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn18")
    );
  });
  test("Custom Test Cases for btn18", () => {
    // START_USER_CODE-USER_btn18_TEST
    // END_USER_CODE-USER_btn18_TEST
  });
  test("btn19(Button Widget) Test Cases", async () => {
    const btn19 = screen.getByTestId("btn19");
    expect(btn19).toBeInTheDocument;
    expect(btn19.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn19")
    );
  });
  test("Custom Test Cases for btn19", () => {
    // START_USER_CODE-USER_btn19_TEST
    // END_USER_CODE-USER_btn19_TEST
  });
  test("btn2(Button Widget) Test Cases", async () => {
    const btn2 = screen.getByTestId("btn2");
    expect(btn2).toBeInTheDocument;
    expect(btn2.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn2")
    );
  });
  test("Custom Test Cases for btn2", () => {
    // START_USER_CODE-USER_btn2_TEST
    // END_USER_CODE-USER_btn2_TEST
  });
  test("btn3(Button Widget) Test Cases", async () => {
    const btn3 = screen.getByTestId("btn3");
    expect(btn3).toBeInTheDocument;
    expect(btn3.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn3")
    );
  });
  test("Custom Test Cases for btn3", () => {
    // START_USER_CODE-USER_btn3_TEST
    // END_USER_CODE-USER_btn3_TEST
  });
  test("btn4(Button Widget) Test Cases", async () => {
    const btn4 = screen.getByTestId("btn4");
    expect(btn4).toBeInTheDocument;
    expect(btn4.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn4")
    );
  });
  test("Custom Test Cases for btn4", () => {
    // START_USER_CODE-USER_btn4_TEST
    // END_USER_CODE-USER_btn4_TEST
  });
  test("btn5(Button Widget) Test Cases", async () => {
    const btn5 = screen.getByTestId("btn5");
    expect(btn5).toBeInTheDocument;
    expect(btn5.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn5")
    );
  });
  test("Custom Test Cases for btn5", () => {
    // START_USER_CODE-USER_btn5_TEST
    // END_USER_CODE-USER_btn5_TEST
  });
  test("btn6(Button Widget) Test Cases", async () => {
    const btn6 = screen.getByTestId("btn6");
    expect(btn6).toBeInTheDocument;
    expect(btn6.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn6")
    );
  });
  test("Custom Test Cases for btn6", () => {
    // START_USER_CODE-USER_btn6_TEST
    // END_USER_CODE-USER_btn6_TEST
  });
  test("btn7(Button Widget) Test Cases", async () => {
    const btn7 = screen.getByTestId("btn7");
    expect(btn7).toBeInTheDocument;
    expect(btn7.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn7")
    );
  });
  test("Custom Test Cases for btn7", () => {
    // START_USER_CODE-USER_btn7_TEST
    // END_USER_CODE-USER_btn7_TEST
  });
  test("btn8(Button Widget) Test Cases", async () => {
    const btn8 = screen.getByTestId("btn8");
    expect(btn8).toBeInTheDocument;
    expect(btn8.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn8")
    );
  });
  test("Custom Test Cases for btn8", () => {
    // START_USER_CODE-USER_btn8_TEST
    // END_USER_CODE-USER_btn8_TEST
  });
  test("btn9(Button Widget) Test Cases", async () => {
    const btn9 = screen.getByTestId("btn9");
    expect(btn9).toBeInTheDocument;
    expect(btn9.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btn9")
    );
  });
  test("Custom Test Cases for btn9", () => {
    // START_USER_CODE-USER_btn9_TEST
    // END_USER_CODE-USER_btn9_TEST
  });
  test("btnAddST(Button Widget) Test Cases", async () => {
    const btnAddST = screen.getByTestId("btnAddST");
    expect(btnAddST).toBeInTheDocument;
    expect(btnAddST.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btnAddST")
    );
  });
  test("Custom Test Cases for btnAddST", () => {
    // START_USER_CODE-USER_btnAddST_TEST
    // END_USER_CODE-USER_btnAddST_TEST
  });
  test("btnAgreeAppl(Button Widget) Test Cases", async () => {
    const btnAgreeAppl = screen.getByTestId("btnAgreeAppl");
    expect(btnAgreeAppl).toBeInTheDocument;
    expect(btnAgreeAppl.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btnAgreeAppl")
    );
  });
  test("Custom Test Cases for btnAgreeAppl", () => {
    // START_USER_CODE-USER_btnAgreeAppl_TEST
    // END_USER_CODE-USER_btnAgreeAppl_TEST
  });
  test("btnCalculate(Button Widget) Test Cases", async () => {
    const btnCalculate = screen.getByTestId("btnCalculate");
    expect(btnCalculate).toBeInTheDocument;
    expect(btnCalculate.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btnCalculate")
    );
  });
  test("Custom Test Cases for btnCalculate", () => {
    // START_USER_CODE-USER_btnCalculate_TEST
    // END_USER_CODE-USER_btnCalculate_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCreateSettlement(Button Widget) Test Cases", async () => {
    const btnCreateSettlement = screen.getByTestId("btnCreateSettlement");
    expect(btnCreateSettlement).toBeInTheDocument;
    expect(btnCreateSettlement.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btnCreateSettlement")
    );
  });
  test("Custom Test Cases for btnCreateSettlement", () => {
    // START_USER_CODE-USER_btnCreateSettlement_TEST
    // END_USER_CODE-USER_btnCreateSettlement_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnDeleteST(Button Widget) Test Cases", async () => {
    const btnDeleteST = screen.getByTestId("btnDeleteST");
    expect(btnDeleteST).toBeInTheDocument;
    expect(btnDeleteST.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btnDeleteST")
    );
  });
  test("Custom Test Cases for btnDeleteST", () => {
    // START_USER_CODE-USER_btnDeleteST_TEST
    // END_USER_CODE-USER_btnDeleteST_TEST
  });
  test("btnEditST(Button Widget) Test Cases", async () => {
    const btnEditST = screen.getByTestId("btnEditST");
    expect(btnEditST).toBeInTheDocument;
    expect(btnEditST.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btnEditST")
    );
  });
  test("Custom Test Cases for btnEditST", () => {
    // START_USER_CODE-USER_btnEditST_TEST
    // END_USER_CODE-USER_btnEditST_TEST
  });
  test("btnPrintSettlement(Button Widget) Test Cases", async () => {
    const btnPrintSettlement = screen.getByTestId("btnPrintSettlement");
    expect(btnPrintSettlement).toBeInTheDocument;
    expect(btnPrintSettlement.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btnPrintSettlement")
    );
  });
  test("Custom Test Cases for btnPrintSettlement", () => {
    // START_USER_CODE-USER_btnPrintSettlement_TEST
    // END_USER_CODE-USER_btnPrintSettlement_TEST
  });
  test("btnReversal(Button Widget) Test Cases", async () => {
    const btnReversal = screen.getByTestId("btnReversal");
    expect(btnReversal).toBeInTheDocument;
    expect(btnReversal.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btnReversal")
    );
  });
  test("Custom Test Cases for btnReversal", () => {
    // START_USER_CODE-USER_btnReversal_TEST
    // END_USER_CODE-USER_btnReversal_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btnSave")
    );
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("btnUnvoid(Button Widget) Test Cases", async () => {
    const btnUnvoid = screen.getByTestId("btnUnvoid");
    expect(btnUnvoid).toBeInTheDocument;
    expect(btnUnvoid.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btnUnvoid")
    );
  });
  test("Custom Test Cases for btnUnvoid", () => {
    // START_USER_CODE-USER_btnUnvoid_TEST
    // END_USER_CODE-USER_btnUnvoid_TEST
  });
  test("btnVoid(Button Widget) Test Cases", async () => {
    const btnVoid = screen.getByTestId("btnVoid");
    expect(btnVoid).toBeInTheDocument;
    expect(btnVoid.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_btnVoid")
    );
  });
  test("Custom Test Cases for btnVoid", () => {
    // START_USER_CODE-USER_btnVoid_TEST
    // END_USER_CODE-USER_btnVoid_TEST
  });
  test("gridScaleTickets(Grid Widget) Test Cases", async () => {
    let gridScaleTickets = screen.getByTestId("gridScaleTickets");
    let gridScaleTicketsbtn =
      gridScaleTickets.nextElementSibling.firstElementChild;
    gridScaleTickets =
      gridScaleTickets.parentElement.parentElement.parentElement;
    expect(gridScaleTickets.tagName).toBe("DIV");
    expect(gridScaleTickets.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleProfile_gridScaleTickets")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridScaleTickets", () => {
    // START_USER_CODE-USER_gridScaleTickets_TEST
    // END_USER_CODE-USER_gridScaleTickets_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxDamage(GroupBox Widget) Test Cases", async () => {
    const grpbxDamage = screen.getByTestId("grpbxDamage");
    expect(grpbxDamage.tagName).toBe("BUTTON");
    expect(grpbxDamage.type).toBe("button");
    expect(grpbxDamage.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDamage", () => {
    // START_USER_CODE-USER_grpbxDamage_TEST
    // END_USER_CODE-USER_grpbxDamage_TEST
  });
  test("grpbxDeliveryDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDeliveryDetails = screen.getByTestId("grpbxDeliveryDetails");
    expect(grpbxDeliveryDetails.tagName).toBe("BUTTON");
    expect(grpbxDeliveryDetails.type).toBe("button");
    expect(grpbxDeliveryDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDeliveryDetails", () => {
    // START_USER_CODE-USER_grpbxDeliveryDetails_TEST
    // END_USER_CODE-USER_grpbxDeliveryDetails_TEST
  });
  test("grpbxPeanutInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanutInfo = screen.getByTestId("grpbxPeanutInfo");
    expect(grpbxPeanutInfo.tagName).toBe("BUTTON");
    expect(grpbxPeanutInfo.type).toBe("button");
    expect(grpbxPeanutInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPeanutInfo", () => {
    // START_USER_CODE-USER_grpbxPeanutInfo_TEST
    // END_USER_CODE-USER_grpbxPeanutInfo_TEST
  });
  test("grpbxPntShippd(GroupBox Widget) Test Cases", async () => {
    const grpbxPntShippd = screen.getByTestId("grpbxPntShippd");
    expect(grpbxPntShippd.tagName).toBe("BUTTON");
    expect(grpbxPntShippd.type).toBe("button");
    expect(grpbxPntShippd.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPntShippd", () => {
    // START_USER_CODE-USER_grpbxPntShippd_TEST
    // END_USER_CODE-USER_grpbxPntShippd_TEST
  });
  test("grpbxReGrading(GroupBox Widget) Test Cases", async () => {
    const grpbxReGrading = screen.getByTestId("grpbxReGrading");
    expect(grpbxReGrading.tagName).toBe("BUTTON");
    expect(grpbxReGrading.type).toBe("button");
    expect(grpbxReGrading.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReGrading", () => {
    // START_USER_CODE-USER_grpbxReGrading_TEST
    // END_USER_CODE-USER_grpbxReGrading_TEST
  });
  test("grpbxScaleTickets(GroupBox Widget) Test Cases", async () => {
    const grpbxScaleTickets = screen.getByTestId("grpbxScaleTickets");
    expect(grpbxScaleTickets.tagName).toBe("BUTTON");
    expect(grpbxScaleTickets.type).toBe("button");
    expect(grpbxScaleTickets.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxScaleTickets", () => {
    // START_USER_CODE-USER_grpbxScaleTickets_TEST
    // END_USER_CODE-USER_grpbxScaleTickets_TEST
  });
  test("grpbxSeg(GroupBox Widget) Test Cases", async () => {
    const grpbxSeg = screen.getByTestId("grpbxSeg");
    expect(grpbxSeg.tagName).toBe("BUTTON");
    expect(grpbxSeg.type).toBe("button");
    expect(grpbxSeg.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSeg", () => {
    // START_USER_CODE-USER_grpbxSeg_TEST
    // END_USER_CODE-USER_grpbxSeg_TEST
  });
  test("grpbxSettlementInformation(GroupBox Widget) Test Cases", async () => {
    const grpbxSettlementInformation = screen.getByTestId(
      "grpbxSettlementInformation"
    );
    expect(grpbxSettlementInformation.tagName).toBe("BUTTON");
    expect(grpbxSettlementInformation.type).toBe("button");
    expect(grpbxSettlementInformation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSettlementInformation", () => {
    // START_USER_CODE-USER_grpbxSettlementInformation_TEST
    // END_USER_CODE-USER_grpbxSettlementInformation_TEST
  });
  test("grpbxShippedFrom(GroupBox Widget) Test Cases", async () => {
    const grpbxShippedFrom = screen.getByTestId("grpbxShippedFrom");
    expect(grpbxShippedFrom.tagName).toBe("BUTTON");
    expect(grpbxShippedFrom.type).toBe("button");
    expect(grpbxShippedFrom.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxShippedFrom", () => {
    // START_USER_CODE-USER_grpbxShippedFrom_TEST
    // END_USER_CODE-USER_grpbxShippedFrom_TEST
  });
  test("grpbxShippedTo(GroupBox Widget) Test Cases", async () => {
    const grpbxShippedTo = screen.getByTestId("grpbxShippedTo");
    expect(grpbxShippedTo.tagName).toBe("BUTTON");
    expect(grpbxShippedTo.type).toBe("button");
    expect(grpbxShippedTo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxShippedTo", () => {
    // START_USER_CODE-USER_grpbxShippedTo_TEST
    // END_USER_CODE-USER_grpbxShippedTo_TEST
  });
  test("grpbxTradeSettleProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxTradeSettleProfile = screen.getByTestId(
      "grpbxTradeSettleProfile"
    );
    expect(grpbxTradeSettleProfile.tagName).toBe("BUTTON");
    expect(grpbxTradeSettleProfile.type).toBe("button");
    expect(grpbxTradeSettleProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTradeSettleProfile", () => {
    // START_USER_CODE-USER_grpbxTradeSettleProfile_TEST
    // END_USER_CODE-USER_grpbxTradeSettleProfile_TEST
  });
  test("grpbxValueCalculation(GroupBox Widget) Test Cases", async () => {
    const grpbxValueCalculation = screen.getByTestId("grpbxValueCalculation");
    expect(grpbxValueCalculation.tagName).toBe("BUTTON");
    expect(grpbxValueCalculation.type).toBe("button");
    expect(grpbxValueCalculation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxValueCalculation", () => {
    // START_USER_CODE-USER_grpbxValueCalculation_TEST
    // END_USER_CODE-USER_grpbxValueCalculation_TEST
  });
  test("grpbxVendorSplitRow0(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow0 = screen.getByTestId("grpbxVendorSplitRow0");
    expect(grpbxVendorSplitRow0.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow0.type).toBe("button");
    expect(grpbxVendorSplitRow0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow0", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow0_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow0_TEST
  });
  test("grpbxVendorSplitRow1(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow1 = screen.getByTestId("grpbxVendorSplitRow1");
    expect(grpbxVendorSplitRow1.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow1.type).toBe("button");
    expect(grpbxVendorSplitRow1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow1", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow1_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow1_TEST
  });
  test("grpbxVendorSplitRow10(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow10 = screen.getByTestId("grpbxVendorSplitRow10");
    expect(grpbxVendorSplitRow10.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow10.type).toBe("button");
    expect(grpbxVendorSplitRow10.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow10", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow10_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow10_TEST
  });
  test("grpbxVendorSplitRow11(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow11 = screen.getByTestId("grpbxVendorSplitRow11");
    expect(grpbxVendorSplitRow11.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow11.type).toBe("button");
    expect(grpbxVendorSplitRow11.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow11", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow11_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow11_TEST
  });
  test("grpbxVendorSplitRow12(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow12 = screen.getByTestId("grpbxVendorSplitRow12");
    expect(grpbxVendorSplitRow12.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow12.type).toBe("button");
    expect(grpbxVendorSplitRow12.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow12", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow12_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow12_TEST
  });
  test("grpbxVendorSplitRow13(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow13 = screen.getByTestId("grpbxVendorSplitRow13");
    expect(grpbxVendorSplitRow13.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow13.type).toBe("button");
    expect(grpbxVendorSplitRow13.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow13", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow13_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow13_TEST
  });
  test("grpbxVendorSplitRow14(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow14 = screen.getByTestId("grpbxVendorSplitRow14");
    expect(grpbxVendorSplitRow14.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow14.type).toBe("button");
    expect(grpbxVendorSplitRow14.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow14", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow14_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow14_TEST
  });
  test("grpbxVendorSplitRow15(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow15 = screen.getByTestId("grpbxVendorSplitRow15");
    expect(grpbxVendorSplitRow15.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow15.type).toBe("button");
    expect(grpbxVendorSplitRow15.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow15", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow15_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow15_TEST
  });
  test("grpbxVendorSplitRow16(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow16 = screen.getByTestId("grpbxVendorSplitRow16");
    expect(grpbxVendorSplitRow16.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow16.type).toBe("button");
    expect(grpbxVendorSplitRow16.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow16", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow16_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow16_TEST
  });
  test("grpbxVendorSplitRow17(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow17 = screen.getByTestId("grpbxVendorSplitRow17");
    expect(grpbxVendorSplitRow17.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow17.type).toBe("button");
    expect(grpbxVendorSplitRow17.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow17", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow17_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow17_TEST
  });
  test("grpbxVendorSplitRow18(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow18 = screen.getByTestId("grpbxVendorSplitRow18");
    expect(grpbxVendorSplitRow18.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow18.type).toBe("button");
    expect(grpbxVendorSplitRow18.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow18", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow18_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow18_TEST
  });
  test("grpbxVendorSplitRow19(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow19 = screen.getByTestId("grpbxVendorSplitRow19");
    expect(grpbxVendorSplitRow19.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow19.type).toBe("button");
    expect(grpbxVendorSplitRow19.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow19", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow19_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow19_TEST
  });
  test("grpbxVendorSplitRow2(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow2 = screen.getByTestId("grpbxVendorSplitRow2");
    expect(grpbxVendorSplitRow2.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow2.type).toBe("button");
    expect(grpbxVendorSplitRow2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow2", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow2_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow2_TEST
  });
  test("grpbxVendorSplitRow3(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow3 = screen.getByTestId("grpbxVendorSplitRow3");
    expect(grpbxVendorSplitRow3.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow3.type).toBe("button");
    expect(grpbxVendorSplitRow3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow3", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow3_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow3_TEST
  });
  test("grpbxVendorSplitRow4(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow4 = screen.getByTestId("grpbxVendorSplitRow4");
    expect(grpbxVendorSplitRow4.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow4.type).toBe("button");
    expect(grpbxVendorSplitRow4.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow4", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow4_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow4_TEST
  });
  test("grpbxVendorSplitRow5(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow5 = screen.getByTestId("grpbxVendorSplitRow5");
    expect(grpbxVendorSplitRow5.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow5.type).toBe("button");
    expect(grpbxVendorSplitRow5.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow5", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow5_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow5_TEST
  });
  test("grpbxVendorSplitRow6(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow6 = screen.getByTestId("grpbxVendorSplitRow6");
    expect(grpbxVendorSplitRow6.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow6.type).toBe("button");
    expect(grpbxVendorSplitRow6.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow6", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow6_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow6_TEST
  });
  test("grpbxVendorSplitRow7(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow7 = screen.getByTestId("grpbxVendorSplitRow7");
    expect(grpbxVendorSplitRow7.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow7.type).toBe("button");
    expect(grpbxVendorSplitRow7.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow7", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow7_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow7_TEST
  });
  test("grpbxVendorSplitRow8(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow8 = screen.getByTestId("grpbxVendorSplitRow8");
    expect(grpbxVendorSplitRow8.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow8.type).toBe("button");
    expect(grpbxVendorSplitRow8.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow8", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow8_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow8_TEST
  });
  test("grpbxVendorSplitRow9(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitRow9 = screen.getByTestId("grpbxVendorSplitRow9");
    expect(grpbxVendorSplitRow9.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitRow9.type).toBe("button");
    expect(grpbxVendorSplitRow9.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitRow9", () => {
    // START_USER_CODE-USER_grpbxVendorSplitRow9_TEST
    // END_USER_CODE-USER_grpbxVendorSplitRow9_TEST
  });
  test("grpbxVendorSplits(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplits = screen.getByTestId("grpbxVendorSplits");
    expect(grpbxVendorSplits.tagName).toBe("BUTTON");
    expect(grpbxVendorSplits.type).toBe("button");
    expect(grpbxVendorSplits.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplits", () => {
    // START_USER_CODE-USER_grpbxVendorSplits_TEST
    // END_USER_CODE-USER_grpbxVendorSplits_TEST
  });
  test("grpbxVendorSplitsRows(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitsRows = screen.getByTestId("grpbxVendorSplitsRows");
    expect(grpbxVendorSplitsRows.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitsRows.type).toBe("button");
    expect(grpbxVendorSplitsRows.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitsRows", () => {
    // START_USER_CODE-USER_grpbxVendorSplitsRows_TEST
    // END_USER_CODE-USER_grpbxVendorSplitsRows_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblCropYear2(Label Widget) Test Cases", async () => {
    const lblCropYear2 = screen.getByTestId("lblCropYear2");
    expect(lblCropYear2.tagName).toBe("LABEL");
    expect(lblCropYear2.classList).toContain("form-label");
    expect(lblCropYear2.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblCropYear2")
    );
  });
  test("Custom Test Cases for lblCropYear2", () => {
    // START_USER_CODE-USER_lblCropYear2_TEST
    // END_USER_CODE-USER_lblCropYear2_TEST
  });
  test("lblKernRidingELKPct(Textbox Widget) Test Cases", async () => {
    const lblKernRidingELKPct = screen.getByTestId("lblKernRidingELKPct");
    expect(lblKernRidingELKPct.tagName).toBe("INPUT");
    expect(lblKernRidingELKPct.type).toBe("text");
    expect(lblKernRidingELKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(lblKernRidingELKPct, "1");
    });
  });
  test("Custom Test Cases for lblKernRidingELKPct", () => {
    // START_USER_CODE-USER_lblKernRidingELKPct_TEST
    // END_USER_CODE-USER_lblKernRidingELKPct_TEST
  });
  test("lblPeanutInformation(Label Widget) Test Cases", async () => {
    const lblPeanutInformation = screen.getByTestId("lblPeanutInformation");
    expect(lblPeanutInformation.tagName).toBe("LABEL");
    expect(lblPeanutInformation.classList).toContain("form-label");
    expect(lblPeanutInformation.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblPeanutInformation")
    );
  });
  test("Custom Test Cases for lblPeanutInformation", () => {
    // START_USER_CODE-USER_lblPeanutInformation_TEST
    // END_USER_CODE-USER_lblPeanutInformation_TEST
  });
  test("lblPerLb(Label Widget) Test Cases", async () => {
    const lblPerLb = screen.getByTestId("lblPerLb");
    expect(lblPerLb.tagName).toBe("LABEL");
    expect(lblPerLb.classList).toContain("form-label");
    expect(lblPerLb.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblPerLb")
    );
  });
  test("Custom Test Cases for lblPerLb", () => {
    // START_USER_CODE-USER_lblPerLb_TEST
    // END_USER_CODE-USER_lblPerLb_TEST
  });
  test("lblPerLb2(Label Widget) Test Cases", async () => {
    const lblPerLb2 = screen.getByTestId("lblPerLb2");
    expect(lblPerLb2.tagName).toBe("LABEL");
    expect(lblPerLb2.classList).toContain("form-label");
    expect(lblPerLb2.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblPerLb2")
    );
  });
  test("Custom Test Cases for lblPerLb2", () => {
    // START_USER_CODE-USER_lblPerLb2_TEST
    // END_USER_CODE-USER_lblPerLb2_TEST
  });
  test("lblPerLb3(Label Widget) Test Cases", async () => {
    const lblPerLb3 = screen.getByTestId("lblPerLb3");
    expect(lblPerLb3.tagName).toBe("LABEL");
    expect(lblPerLb3.classList).toContain("form-label");
    expect(lblPerLb3.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblPerLb3")
    );
  });
  test("Custom Test Cases for lblPerLb3", () => {
    // START_USER_CODE-USER_lblPerLb3_TEST
    // END_USER_CODE-USER_lblPerLb3_TEST
  });
  test("lblPerTon(Label Widget) Test Cases", async () => {
    const lblPerTon = screen.getByTestId("lblPerTon");
    expect(lblPerTon.tagName).toBe("LABEL");
    expect(lblPerTon.classList).toContain("form-label");
    expect(lblPerTon.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblPerTon")
    );
  });
  test("Custom Test Cases for lblPerTon", () => {
    // START_USER_CODE-USER_lblPerTon_TEST
    // END_USER_CODE-USER_lblPerTon_TEST
  });
  test("lblPrintSeg(Label Widget) Test Cases", async () => {
    const lblPrintSeg = screen.getByTestId("lblPrintSeg");
    expect(lblPrintSeg.tagName).toBe("LABEL");
    expect(lblPrintSeg.classList).toContain("form-label");
    expect(lblPrintSeg.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblPrintSeg")
    );
  });
  test("Custom Test Cases for lblPrintSeg", () => {
    // START_USER_CODE-USER_lblPrintSeg_TEST
    // END_USER_CODE-USER_lblPrintSeg_TEST
  });
  test("lblReGrading(Label Widget) Test Cases", async () => {
    const lblReGrading = screen.getByTestId("lblReGrading");
    expect(lblReGrading.tagName).toBe("LABEL");
    expect(lblReGrading.classList).toContain("form-label");
    expect(lblReGrading.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblReGrading")
    );
  });
  test("Custom Test Cases for lblReGrading", () => {
    // START_USER_CODE-USER_lblReGrading_TEST
    // END_USER_CODE-USER_lblReGrading_TEST
  });
  test("lblRemitTo(Label Widget) Test Cases", async () => {
    const lblRemitTo = screen.getByTestId("lblRemitTo");
    expect(lblRemitTo.tagName).toBe("LABEL");
    expect(lblRemitTo.classList).toContain("form-label");
    expect(lblRemitTo.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblRemitTo")
    );
  });
  test("Custom Test Cases for lblRemitTo", () => {
    // START_USER_CODE-USER_lblRemitTo_TEST
    // END_USER_CODE-USER_lblRemitTo_TEST
  });
  test("lblScaleTickets(Label Widget) Test Cases", async () => {
    const lblScaleTickets = screen.getByTestId("lblScaleTickets");
    expect(lblScaleTickets.tagName).toBe("LABEL");
    expect(lblScaleTickets.classList).toContain("form-label");
    expect(lblScaleTickets.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblScaleTickets")
    );
  });
  test("Custom Test Cases for lblScaleTickets", () => {
    // START_USER_CODE-USER_lblScaleTickets_TEST
    // END_USER_CODE-USER_lblScaleTickets_TEST
  });
  test("lblSettlementInformation(Label Widget) Test Cases", async () => {
    const lblSettlementInformation = screen.getByTestId(
      "lblSettlementInformation"
    );
    expect(lblSettlementInformation.tagName).toBe("LABEL");
    expect(lblSettlementInformation.classList).toContain("form-label");
    expect(lblSettlementInformation.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblSettlementInformation")
    );
  });
  test("Custom Test Cases for lblSettlementInformation", () => {
    // START_USER_CODE-USER_lblSettlementInformation_TEST
    // END_USER_CODE-USER_lblSettlementInformation_TEST
  });
  test("lblSharePercentage(Label Widget) Test Cases", async () => {
    const lblSharePercentage = screen.getByTestId("lblSharePercentage");
    expect(lblSharePercentage.tagName).toBe("LABEL");
    expect(lblSharePercentage.classList).toContain("form-label");
    expect(lblSharePercentage.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblSharePercentage")
    );
  });
  test("Custom Test Cases for lblSharePercentage", () => {
    // START_USER_CODE-USER_lblSharePercentage_TEST
    // END_USER_CODE-USER_lblSharePercentage_TEST
  });
  test("lblShippedFrom(Label Widget) Test Cases", async () => {
    const lblShippedFrom = screen.getByTestId("lblShippedFrom");
    expect(lblShippedFrom.tagName).toBe("LABEL");
    expect(lblShippedFrom.classList).toContain("form-label");
    expect(lblShippedFrom.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblShippedFrom")
    );
  });
  test("Custom Test Cases for lblShippedFrom", () => {
    // START_USER_CODE-USER_lblShippedFrom_TEST
    // END_USER_CODE-USER_lblShippedFrom_TEST
  });
  test("lblShippedTo(Label Widget) Test Cases", async () => {
    const lblShippedTo = screen.getByTestId("lblShippedTo");
    expect(lblShippedTo.tagName).toBe("LABEL");
    expect(lblShippedTo.classList).toContain("form-label");
    expect(lblShippedTo.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblShippedTo")
    );
  });
  test("Custom Test Cases for lblShippedTo", () => {
    // START_USER_CODE-USER_lblShippedTo_TEST
    // END_USER_CODE-USER_lblShippedTo_TEST
  });
  test("lblValueCalculation(Label Widget) Test Cases", async () => {
    const lblValueCalculation = screen.getByTestId("lblValueCalculation");
    expect(lblValueCalculation.tagName).toBe("LABEL");
    expect(lblValueCalculation.classList).toContain("form-label");
    expect(lblValueCalculation.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblValueCalculation")
    );
  });
  test("Custom Test Cases for lblValueCalculation", () => {
    // START_USER_CODE-USER_lblValueCalculation_TEST
    // END_USER_CODE-USER_lblValueCalculation_TEST
  });
  test("lblVendor(Label Widget) Test Cases", async () => {
    const lblVendor = screen.getByTestId("lblVendor");
    expect(lblVendor.tagName).toBe("LABEL");
    expect(lblVendor.classList).toContain("form-label");
    expect(lblVendor.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblVendor")
    );
  });
  test("Custom Test Cases for lblVendor", () => {
    // START_USER_CODE-USER_lblVendor_TEST
    // END_USER_CODE-USER_lblVendor_TEST
  });
  test("lblVendorSplits(Label Widget) Test Cases", async () => {
    const lblVendorSplits = screen.getByTestId("lblVendorSplits");
    expect(lblVendorSplits.tagName).toBe("LABEL");
    expect(lblVendorSplits.classList).toContain("form-label");
    expect(lblVendorSplits.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblVendorSplits")
    );
  });
  test("Custom Test Cases for lblVendorSplits", () => {
    // START_USER_CODE-USER_lblVendorSplits_TEST
    // END_USER_CODE-USER_lblVendorSplits_TEST
  });
  test("lblXX0(Label Widget) Test Cases", async () => {
    const lblXX0 = screen.getByTestId("lblXX0");
    expect(lblXX0.tagName).toBe("LABEL");
    expect(lblXX0.classList).toContain("form-label");
    expect(lblXX0.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX0")
    );
  });
  test("Custom Test Cases for lblXX0", () => {
    // START_USER_CODE-USER_lblXX0_TEST
    // END_USER_CODE-USER_lblXX0_TEST
  });
  test("lblXX1(Label Widget) Test Cases", async () => {
    const lblXX1 = screen.getByTestId("lblXX1");
    expect(lblXX1.tagName).toBe("LABEL");
    expect(lblXX1.classList).toContain("form-label");
    expect(lblXX1.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX1")
    );
  });
  test("Custom Test Cases for lblXX1", () => {
    // START_USER_CODE-USER_lblXX1_TEST
    // END_USER_CODE-USER_lblXX1_TEST
  });
  test("lblXX10(Label Widget) Test Cases", async () => {
    const lblXX10 = screen.getByTestId("lblXX10");
    expect(lblXX10.tagName).toBe("LABEL");
    expect(lblXX10.classList).toContain("form-label");
    expect(lblXX10.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX10")
    );
  });
  test("Custom Test Cases for lblXX10", () => {
    // START_USER_CODE-USER_lblXX10_TEST
    // END_USER_CODE-USER_lblXX10_TEST
  });
  test("lblXX11(Label Widget) Test Cases", async () => {
    const lblXX11 = screen.getByTestId("lblXX11");
    expect(lblXX11.tagName).toBe("LABEL");
    expect(lblXX11.classList).toContain("form-label");
    expect(lblXX11.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX11")
    );
  });
  test("Custom Test Cases for lblXX11", () => {
    // START_USER_CODE-USER_lblXX11_TEST
    // END_USER_CODE-USER_lblXX11_TEST
  });
  test("lblXX12(Label Widget) Test Cases", async () => {
    const lblXX12 = screen.getByTestId("lblXX12");
    expect(lblXX12.tagName).toBe("LABEL");
    expect(lblXX12.classList).toContain("form-label");
    expect(lblXX12.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX12")
    );
  });
  test("Custom Test Cases for lblXX12", () => {
    // START_USER_CODE-USER_lblXX12_TEST
    // END_USER_CODE-USER_lblXX12_TEST
  });
  test("lblXX13(Label Widget) Test Cases", async () => {
    const lblXX13 = screen.getByTestId("lblXX13");
    expect(lblXX13.tagName).toBe("LABEL");
    expect(lblXX13.classList).toContain("form-label");
    expect(lblXX13.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX13")
    );
  });
  test("Custom Test Cases for lblXX13", () => {
    // START_USER_CODE-USER_lblXX13_TEST
    // END_USER_CODE-USER_lblXX13_TEST
  });
  test("lblXX14(Label Widget) Test Cases", async () => {
    const lblXX14 = screen.getByTestId("lblXX14");
    expect(lblXX14.tagName).toBe("LABEL");
    expect(lblXX14.classList).toContain("form-label");
    expect(lblXX14.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX14")
    );
  });
  test("Custom Test Cases for lblXX14", () => {
    // START_USER_CODE-USER_lblXX14_TEST
    // END_USER_CODE-USER_lblXX14_TEST
  });
  test("lblXX15(Label Widget) Test Cases", async () => {
    const lblXX15 = screen.getByTestId("lblXX15");
    expect(lblXX15.tagName).toBe("LABEL");
    expect(lblXX15.classList).toContain("form-label");
    expect(lblXX15.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX15")
    );
  });
  test("Custom Test Cases for lblXX15", () => {
    // START_USER_CODE-USER_lblXX15_TEST
    // END_USER_CODE-USER_lblXX15_TEST
  });
  test("lblXX16(Label Widget) Test Cases", async () => {
    const lblXX16 = screen.getByTestId("lblXX16");
    expect(lblXX16.tagName).toBe("LABEL");
    expect(lblXX16.classList).toContain("form-label");
    expect(lblXX16.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX16")
    );
  });
  test("Custom Test Cases for lblXX16", () => {
    // START_USER_CODE-USER_lblXX16_TEST
    // END_USER_CODE-USER_lblXX16_TEST
  });
  test("lblXX17(Label Widget) Test Cases", async () => {
    const lblXX17 = screen.getByTestId("lblXX17");
    expect(lblXX17.tagName).toBe("LABEL");
    expect(lblXX17.classList).toContain("form-label");
    expect(lblXX17.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX17")
    );
  });
  test("Custom Test Cases for lblXX17", () => {
    // START_USER_CODE-USER_lblXX17_TEST
    // END_USER_CODE-USER_lblXX17_TEST
  });
  test("lblXX18(Label Widget) Test Cases", async () => {
    const lblXX18 = screen.getByTestId("lblXX18");
    expect(lblXX18.tagName).toBe("LABEL");
    expect(lblXX18.classList).toContain("form-label");
    expect(lblXX18.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX18")
    );
  });
  test("Custom Test Cases for lblXX18", () => {
    // START_USER_CODE-USER_lblXX18_TEST
    // END_USER_CODE-USER_lblXX18_TEST
  });
  test("lblXX19(Label Widget) Test Cases", async () => {
    const lblXX19 = screen.getByTestId("lblXX19");
    expect(lblXX19.tagName).toBe("LABEL");
    expect(lblXX19.classList).toContain("form-label");
    expect(lblXX19.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX19")
    );
  });
  test("Custom Test Cases for lblXX19", () => {
    // START_USER_CODE-USER_lblXX19_TEST
    // END_USER_CODE-USER_lblXX19_TEST
  });
  test("lblXX2(Label Widget) Test Cases", async () => {
    const lblXX2 = screen.getByTestId("lblXX2");
    expect(lblXX2.tagName).toBe("LABEL");
    expect(lblXX2.classList).toContain("form-label");
    expect(lblXX2.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX2")
    );
  });
  test("Custom Test Cases for lblXX2", () => {
    // START_USER_CODE-USER_lblXX2_TEST
    // END_USER_CODE-USER_lblXX2_TEST
  });
  test("lblXX3(Label Widget) Test Cases", async () => {
    const lblXX3 = screen.getByTestId("lblXX3");
    expect(lblXX3.tagName).toBe("LABEL");
    expect(lblXX3.classList).toContain("form-label");
    expect(lblXX3.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX3")
    );
  });
  test("Custom Test Cases for lblXX3", () => {
    // START_USER_CODE-USER_lblXX3_TEST
    // END_USER_CODE-USER_lblXX3_TEST
  });
  test("lblXX4(Label Widget) Test Cases", async () => {
    const lblXX4 = screen.getByTestId("lblXX4");
    expect(lblXX4.tagName).toBe("LABEL");
    expect(lblXX4.classList).toContain("form-label");
    expect(lblXX4.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX4")
    );
  });
  test("Custom Test Cases for lblXX4", () => {
    // START_USER_CODE-USER_lblXX4_TEST
    // END_USER_CODE-USER_lblXX4_TEST
  });
  test("lblXX5(Label Widget) Test Cases", async () => {
    const lblXX5 = screen.getByTestId("lblXX5");
    expect(lblXX5.tagName).toBe("LABEL");
    expect(lblXX5.classList).toContain("form-label");
    expect(lblXX5.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX5")
    );
  });
  test("Custom Test Cases for lblXX5", () => {
    // START_USER_CODE-USER_lblXX5_TEST
    // END_USER_CODE-USER_lblXX5_TEST
  });
  test("lblXX6(Label Widget) Test Cases", async () => {
    const lblXX6 = screen.getByTestId("lblXX6");
    expect(lblXX6.tagName).toBe("LABEL");
    expect(lblXX6.classList).toContain("form-label");
    expect(lblXX6.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX6")
    );
  });
  test("Custom Test Cases for lblXX6", () => {
    // START_USER_CODE-USER_lblXX6_TEST
    // END_USER_CODE-USER_lblXX6_TEST
  });
  test("lblXX7(Label Widget) Test Cases", async () => {
    const lblXX7 = screen.getByTestId("lblXX7");
    expect(lblXX7.tagName).toBe("LABEL");
    expect(lblXX7.classList).toContain("form-label");
    expect(lblXX7.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX7")
    );
  });
  test("Custom Test Cases for lblXX7", () => {
    // START_USER_CODE-USER_lblXX7_TEST
    // END_USER_CODE-USER_lblXX7_TEST
  });
  test("lblXX8(Label Widget) Test Cases", async () => {
    const lblXX8 = screen.getByTestId("lblXX8");
    expect(lblXX8.tagName).toBe("LABEL");
    expect(lblXX8.classList).toContain("form-label");
    expect(lblXX8.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX8")
    );
  });
  test("Custom Test Cases for lblXX8", () => {
    // START_USER_CODE-USER_lblXX8_TEST
    // END_USER_CODE-USER_lblXX8_TEST
  });
  test("lblXX9(Label Widget) Test Cases", async () => {
    const lblXX9 = screen.getByTestId("lblXX9");
    expect(lblXX9.tagName).toBe("LABEL");
    expect(lblXX9.classList).toContain("form-label");
    expect(lblXX9.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_lblXX9")
    );
  });
  test("Custom Test Cases for lblXX9", () => {
    // START_USER_CODE-USER_lblXX9_TEST
    // END_USER_CODE-USER_lblXX9_TEST
  });
  test("txt1007Handler(Textbox Widget) Test Cases", async () => {
    const txt1007Handler = screen.getByTestId("txt1007Handler");
    expect(txt1007Handler.tagName).toBe("INPUT");
    expect(txt1007Handler.type).toBe("text");
    expect(txt1007Handler.classList).toContain("textboxWidgetClass");
    expect(txt1007Handler.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txt1007Handler")
    );
    await act(async () => {
      userEvent.type(txt1007Handler, "1");
    });
  });
  test("Custom Test Cases for txt1007Handler", () => {
    // START_USER_CODE-USER_txt1007Handler_TEST
    // END_USER_CODE-USER_txt1007Handler_TEST
  });
  test("txt1007HandlerName(Textbox Widget) Test Cases", async () => {
    const txt1007HandlerName = screen.getByTestId("txt1007HandlerName");
    expect(txt1007HandlerName.tagName).toBe("INPUT");
    expect(txt1007HandlerName.type).toBe("text");
    expect(txt1007HandlerName.classList).toContain("textboxWidgetClass");
    expect(txt1007HandlerName.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txt1007HandlerName")
    );
    await act(async () => {
      userEvent.type(txt1007HandlerName, "1");
    });
  });
  test("Custom Test Cases for txt1007HandlerName", () => {
    // START_USER_CODE-USER_txt1007HandlerName_TEST
    // END_USER_CODE-USER_txt1007HandlerName_TEST
  });
  test("txt100PctAdjValue(Textbox Widget) Test Cases", async () => {
    const txt100PctAdjValue = screen.getByTestId("txt100PctAdjValue");
    expect(txt100PctAdjValue.tagName).toBe("INPUT");
    expect(txt100PctAdjValue.type).toBe("text");
    expect(txt100PctAdjValue.classList).toContain("textboxWidgetClass");
    expect(txt100PctAdjValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txt100PctAdjValue")
    );
    await act(async () => {
      userEvent.type(txt100PctAdjValue, "1");
    });
  });
  test("Custom Test Cases for txt100PctAdjValue", () => {
    // START_USER_CODE-USER_txt100PctAdjValue_TEST
    // END_USER_CODE-USER_txt100PctAdjValue_TEST
  });
  test("txtAccessFMPerPound(Textbox Widget) Test Cases", async () => {
    const txtAccessFMPerPound = screen.getByTestId("txtAccessFMPerPound");
    expect(txtAccessFMPerPound.tagName).toBe("INPUT");
    expect(txtAccessFMPerPound.type).toBe("text");
    expect(txtAccessFMPerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAccessFMPerPound, "1");
    });
  });
  test("Custom Test Cases for txtAccessFMPerPound", () => {
    // START_USER_CODE-USER_txtAccessFMPerPound_TEST
    // END_USER_CODE-USER_txtAccessFMPerPound_TEST
  });
  test("txtAccessFMPerTon(Textbox Widget) Test Cases", async () => {
    const txtAccessFMPerTon = screen.getByTestId("txtAccessFMPerTon");
    expect(txtAccessFMPerTon.tagName).toBe("INPUT");
    expect(txtAccessFMPerTon.type).toBe("text");
    expect(txtAccessFMPerTon.classList).toContain("textboxWidgetClass");
    expect(txtAccessFMPerTon.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtAccessFMPerTon")
    );
    await act(async () => {
      userEvent.type(txtAccessFMPerTon, "1");
    });
  });
  test("Custom Test Cases for txtAccessFMPerTon", () => {
    // START_USER_CODE-USER_txtAccessFMPerTon_TEST
    // END_USER_CODE-USER_txtAccessFMPerTon_TEST
  });
  test("txtAFlavusPerPound(Textbox Widget) Test Cases", async () => {
    const txtAFlavusPerPound = screen.getByTestId("txtAFlavusPerPound");
    expect(txtAFlavusPerPound.tagName).toBe("INPUT");
    expect(txtAFlavusPerPound.type).toBe("text");
    expect(txtAFlavusPerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAFlavusPerPound, "1");
    });
  });
  test("Custom Test Cases for txtAFlavusPerPound", () => {
    // START_USER_CODE-USER_txtAFlavusPerPound_TEST
    // END_USER_CODE-USER_txtAFlavusPerPound_TEST
  });
  test("txtAFlavusPerTon(Textbox Widget) Test Cases", async () => {
    const txtAFlavusPerTon = screen.getByTestId("txtAFlavusPerTon");
    expect(txtAFlavusPerTon.tagName).toBe("INPUT");
    expect(txtAFlavusPerTon.type).toBe("text");
    expect(txtAFlavusPerTon.classList).toContain("textboxWidgetClass");
    expect(txtAFlavusPerTon.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtAFlavusPerTon")
    );
    await act(async () => {
      userEvent.type(txtAFlavusPerTon, "1");
    });
  });
  test("Custom Test Cases for txtAFlavusPerTon", () => {
    // START_USER_CODE-USER_txtAFlavusPerTon_TEST
    // END_USER_CODE-USER_txtAFlavusPerTon_TEST
  });
  test("txtAgreementPrice(Textbox Widget) Test Cases", async () => {
    const txtAgreementPrice = screen.getByTestId("txtAgreementPrice");
    expect(txtAgreementPrice.tagName).toBe("INPUT");
    expect(txtAgreementPrice.type).toBe("text");
    expect(txtAgreementPrice.classList).toContain("textboxWidgetClass");
    expect(txtAgreementPrice.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtAgreementPrice")
    );
    await act(async () => {
      userEvent.type(txtAgreementPrice, "1");
    });
  });
  test("Custom Test Cases for txtAgreementPrice", () => {
    // START_USER_CODE-USER_txtAgreementPrice_TEST
    // END_USER_CODE-USER_txtAgreementPrice_TEST
  });
  test("txtBluePanWT(Textbox Widget) Test Cases", async () => {
    const txtBluePanWT = screen.getByTestId("txtBluePanWT");
    expect(txtBluePanWT.tagName).toBe("INPUT");
    expect(txtBluePanWT.type).toBe("text");
    expect(txtBluePanWT.classList).toContain("textboxWidgetClass");
    expect(txtBluePanWT.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtBluePanWT")
    );
    await act(async () => {
      userEvent.type(txtBluePanWT, "1");
    });
  });
  test("Custom Test Cases for txtBluePanWT", () => {
    // START_USER_CODE-USER_txtBluePanWT_TEST
    // END_USER_CODE-USER_txtBluePanWT_TEST
  });
  test("txtCleanSampleWt(Textbox Widget) Test Cases", async () => {
    const txtCleanSampleWt = screen.getByTestId("txtCleanSampleWt");
    expect(txtCleanSampleWt.tagName).toBe("INPUT");
    expect(txtCleanSampleWt.type).toBe("text");
    expect(txtCleanSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtCleanSampleWt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtCleanSampleWt")
    );
    await act(async () => {
      userEvent.type(txtCleanSampleWt, "1");
    });
  });
  test("Custom Test Cases for txtCleanSampleWt", () => {
    // START_USER_CODE-USER_txtCleanSampleWt_TEST
    // END_USER_CODE-USER_txtCleanSampleWt_TEST
  });
  test("gridScaleTickets_txtcolInventoryWt(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolInventoryWt = screen.getByTestId(
      "gridScaleTickets"
    );
    let gridScaleTickets_txtcolInventoryWtbtn =
      gridScaleTickets_txtcolInventoryWt.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolInventoryWt =
      gridScaleTickets_txtcolInventoryWt.parentElement.parentElement
        .parentElement;
    expect(gridScaleTickets_txtcolInventoryWt.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolInventoryWt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleProfile_gridScaleTickets")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInventoryWt", () => {
    // START_USER_CODE-USER_txtcolInventoryWt_TEST
    // END_USER_CODE-USER_txtcolInventoryWt_TEST
  });
  test("gridScaleTickets_txtcolLoad(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolLoad = screen.getByTestId("gridScaleTickets");
    let gridScaleTickets_txtcolLoadbtn =
      gridScaleTickets_txtcolLoad.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolLoad =
      gridScaleTickets_txtcolLoad.parentElement.parentElement.parentElement;
    expect(gridScaleTickets_txtcolLoad.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolLoad.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleProfile_gridScaleTickets")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoad", () => {
    // START_USER_CODE-USER_txtcolLoad_TEST
    // END_USER_CODE-USER_txtcolLoad_TEST
  });
  test("gridScaleTickets_txtcolRecvDate(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolRecvDate = screen.getByTestId(
      "gridScaleTickets"
    );
    let gridScaleTickets_txtcolRecvDatebtn =
      gridScaleTickets_txtcolRecvDate.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolRecvDate =
      gridScaleTickets_txtcolRecvDate.parentElement.parentElement.parentElement;
    expect(gridScaleTickets_txtcolRecvDate.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolRecvDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleProfile_gridScaleTickets")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecvDate", () => {
    // START_USER_CODE-USER_txtcolRecvDate_TEST
    // END_USER_CODE-USER_txtcolRecvDate_TEST
  });
  test("gridScaleTickets_txtcolRecvTicket(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolRecvTicket = screen.getByTestId(
      "gridScaleTickets"
    );
    let gridScaleTickets_txtcolRecvTicketbtn =
      gridScaleTickets_txtcolRecvTicket.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolRecvTicket =
      gridScaleTickets_txtcolRecvTicket.parentElement.parentElement
        .parentElement;
    expect(gridScaleTickets_txtcolRecvTicket.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolRecvTicket.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleProfile_gridScaleTickets")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecvTicket", () => {
    // START_USER_CODE-USER_txtcolRecvTicket_TEST
    // END_USER_CODE-USER_txtcolRecvTicket_TEST
  });
  test("gridScaleTickets_txtcolRecvWt(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolRecvWt = screen.getByTestId("gridScaleTickets");
    let gridScaleTickets_txtcolRecvWtbtn =
      gridScaleTickets_txtcolRecvWt.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolRecvWt =
      gridScaleTickets_txtcolRecvWt.parentElement.parentElement.parentElement;
    expect(gridScaleTickets_txtcolRecvWt.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolRecvWt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleProfile_gridScaleTickets")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecvWt", () => {
    // START_USER_CODE-USER_txtcolRecvWt_TEST
    // END_USER_CODE-USER_txtcolRecvWt_TEST
  });
  test("gridScaleTickets_txtcolShipDate(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolShipDate = screen.getByTestId(
      "gridScaleTickets"
    );
    let gridScaleTickets_txtcolShipDatebtn =
      gridScaleTickets_txtcolShipDate.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolShipDate =
      gridScaleTickets_txtcolShipDate.parentElement.parentElement.parentElement;
    expect(gridScaleTickets_txtcolShipDate.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolShipDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleProfile_gridScaleTickets")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShipDate", () => {
    // START_USER_CODE-USER_txtcolShipDate_TEST
    // END_USER_CODE-USER_txtcolShipDate_TEST
  });
  test("gridScaleTickets_txtcolShipTicket(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolShipTicket = screen.getByTestId(
      "gridScaleTickets"
    );
    let gridScaleTickets_txtcolShipTicketbtn =
      gridScaleTickets_txtcolShipTicket.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolShipTicket =
      gridScaleTickets_txtcolShipTicket.parentElement.parentElement
        .parentElement;
    expect(gridScaleTickets_txtcolShipTicket.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolShipTicket.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleProfile_gridScaleTickets")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShipTicket", () => {
    // START_USER_CODE-USER_txtcolShipTicket_TEST
    // END_USER_CODE-USER_txtcolShipTicket_TEST
  });
  test("gridScaleTickets_txtcolShipWt(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolShipWt = screen.getByTestId("gridScaleTickets");
    let gridScaleTickets_txtcolShipWtbtn =
      gridScaleTickets_txtcolShipWt.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolShipWt =
      gridScaleTickets_txtcolShipWt.parentElement.parentElement.parentElement;
    expect(gridScaleTickets_txtcolShipWt.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolShipWt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleProfile_gridScaleTickets")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShipWt", () => {
    // START_USER_CODE-USER_txtcolShipWt_TEST
    // END_USER_CODE-USER_txtcolShipWt_TEST
  });
  test("gridScaleTickets_txtcolVehicle(Grid Widget) Test Cases", async () => {
    let gridScaleTickets_txtcolVehicle = screen.getByTestId("gridScaleTickets");
    let gridScaleTickets_txtcolVehiclebtn =
      gridScaleTickets_txtcolVehicle.nextElementSibling.firstElementChild;
    gridScaleTickets_txtcolVehicle =
      gridScaleTickets_txtcolVehicle.parentElement.parentElement.parentElement;
    expect(gridScaleTickets_txtcolVehicle.tagName).toBe("DIV");
    expect(gridScaleTickets_txtcolVehicle.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettleProfile_gridScaleTickets")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVehicle", () => {
    // START_USER_CODE-USER_txtcolVehicle_TEST
    // END_USER_CODE-USER_txtcolVehicle_TEST
  });
  test("txtConcealedRMD(Textbox Widget) Test Cases", async () => {
    const txtConcealedRMD = screen.getByTestId("txtConcealedRMD");
    expect(txtConcealedRMD.tagName).toBe("INPUT");
    expect(txtConcealedRMD.type).toBe("text");
    expect(txtConcealedRMD.classList).toContain("textboxWidgetClass");
    expect(txtConcealedRMD.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtConcealedRMD")
    );
    await act(async () => {
      userEvent.type(txtConcealedRMD, "1");
    });
  });
  test("Custom Test Cases for txtConcealedRMD", () => {
    // START_USER_CODE-USER_txtConcealedRMD_TEST
    // END_USER_CODE-USER_txtConcealedRMD_TEST
  });
  test("txtConcealedRMDPct(Textbox Widget) Test Cases", async () => {
    const txtConcealedRMDPct = screen.getByTestId("txtConcealedRMDPct");
    expect(txtConcealedRMDPct.tagName).toBe("INPUT");
    expect(txtConcealedRMDPct.type).toBe("text");
    expect(txtConcealedRMDPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtConcealedRMDPct, "1");
    });
  });
  test("Custom Test Cases for txtConcealedRMDPct", () => {
    // START_USER_CODE-USER_txtConcealedRMDPct_TEST
    // END_USER_CODE-USER_txtConcealedRMDPct_TEST
  });
  test("txtConversionPct(Textbox Widget) Test Cases", async () => {
    const txtConversionPct = screen.getByTestId("txtConversionPct");
    expect(txtConversionPct.tagName).toBe("INPUT");
    expect(txtConversionPct.type).toBe("text");
    expect(txtConversionPct.classList).toContain("textboxWidgetClass");
    expect(txtConversionPct.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtConversionPct")
    );
    await act(async () => {
      userEvent.type(txtConversionPct, "1");
    });
  });
  test("Custom Test Cases for txtConversionPct", () => {
    // START_USER_CODE-USER_txtConversionPct_TEST
    // END_USER_CODE-USER_txtConversionPct_TEST
  });
  test("txtConvertedValue(Textbox Widget) Test Cases", async () => {
    const txtConvertedValue = screen.getByTestId("txtConvertedValue");
    expect(txtConvertedValue.tagName).toBe("INPUT");
    expect(txtConvertedValue.type).toBe("text");
    expect(txtConvertedValue.classList).toContain("textboxWidgetClass");
    expect(txtConvertedValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtConvertedValue")
    );
    await act(async () => {
      userEvent.type(txtConvertedValue, "1");
    });
  });
  test("Custom Test Cases for txtConvertedValue", () => {
    // START_USER_CODE-USER_txtConvertedValue_TEST
    // END_USER_CODE-USER_txtConvertedValue_TEST
  });
  test("txtCrackedBroken(Textbox Widget) Test Cases", async () => {
    const txtCrackedBroken = screen.getByTestId("txtCrackedBroken");
    expect(txtCrackedBroken.tagName).toBe("INPUT");
    expect(txtCrackedBroken.type).toBe("text");
    expect(txtCrackedBroken.classList).toContain("textboxWidgetClass");
    expect(txtCrackedBroken.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtCrackedBroken")
    );
    await act(async () => {
      userEvent.type(txtCrackedBroken, "1");
    });
  });
  test("Custom Test Cases for txtCrackedBroken", () => {
    // START_USER_CODE-USER_txtCrackedBroken_TEST
    // END_USER_CODE-USER_txtCrackedBroken_TEST
  });
  test("txtCrackedBrokenPct(Textbox Widget) Test Cases", async () => {
    const txtCrackedBrokenPct = screen.getByTestId("txtCrackedBrokenPct");
    expect(txtCrackedBrokenPct.tagName).toBe("INPUT");
    expect(txtCrackedBrokenPct.type).toBe("text");
    expect(txtCrackedBrokenPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCrackedBrokenPct, "1");
    });
  });
  test("Custom Test Cases for txtCrackedBrokenPct", () => {
    // START_USER_CODE-USER_txtCrackedBrokenPct_TEST
    // END_USER_CODE-USER_txtCrackedBrokenPct_TEST
  });
  test("txtDamagedKRS(Textbox Widget) Test Cases", async () => {
    const txtDamagedKRS = screen.getByTestId("txtDamagedKRS");
    expect(txtDamagedKRS.tagName).toBe("INPUT");
    expect(txtDamagedKRS.type).toBe("text");
    expect(txtDamagedKRS.classList).toContain("textboxWidgetClass");
    expect(txtDamagedKRS.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtDamagedKRS")
    );
    await act(async () => {
      userEvent.type(txtDamagedKRS, "1");
    });
  });
  test("Custom Test Cases for txtDamagedKRS", () => {
    // START_USER_CODE-USER_txtDamagedKRS_TEST
    // END_USER_CODE-USER_txtDamagedKRS_TEST
  });
  test("txtDamagePerPound(Textbox Widget) Test Cases", async () => {
    const txtDamagePerPound = screen.getByTestId("txtDamagePerPound");
    expect(txtDamagePerPound.tagName).toBe("INPUT");
    expect(txtDamagePerPound.type).toBe("text");
    expect(txtDamagePerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDamagePerPound, "1");
    });
  });
  test("Custom Test Cases for txtDamagePerPound", () => {
    // START_USER_CODE-USER_txtDamagePerPound_TEST
    // END_USER_CODE-USER_txtDamagePerPound_TEST
  });
  test("txtDamagePerTon(Textbox Widget) Test Cases", async () => {
    const txtDamagePerTon = screen.getByTestId("txtDamagePerTon");
    expect(txtDamagePerTon.tagName).toBe("INPUT");
    expect(txtDamagePerTon.type).toBe("text");
    expect(txtDamagePerTon.classList).toContain("textboxWidgetClass");
    expect(txtDamagePerTon.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtDamagePerTon")
    );
    await act(async () => {
      userEvent.type(txtDamagePerTon, "1");
    });
  });
  test("Custom Test Cases for txtDamagePerTon", () => {
    // START_USER_CODE-USER_txtDamagePerTon_TEST
    // END_USER_CODE-USER_txtDamagePerTon_TEST
  });
  test("txtDamageSplits(Textbox Widget) Test Cases", async () => {
    const txtDamageSplits = screen.getByTestId("txtDamageSplits");
    expect(txtDamageSplits.tagName).toBe("INPUT");
    expect(txtDamageSplits.type).toBe("text");
    expect(txtDamageSplits.classList).toContain("textboxWidgetClass");
    expect(txtDamageSplits.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtDamageSplits")
    );
    await act(async () => {
      userEvent.type(txtDamageSplits, "1");
    });
  });
  test("Custom Test Cases for txtDamageSplits", () => {
    // START_USER_CODE-USER_txtDamageSplits_TEST
    // END_USER_CODE-USER_txtDamageSplits_TEST
  });
  test("txtDeductions(Textbox Widget) Test Cases", async () => {
    const txtDeductions = screen.getByTestId("txtDeductions");
    expect(txtDeductions.tagName).toBe("INPUT");
    expect(txtDeductions.type).toBe("text");
    expect(txtDeductions.classList).toContain("textboxWidgetClass");
    expect(txtDeductions.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtDeductions")
    );
    await act(async () => {
      userEvent.type(txtDeductions, "1");
    });
  });
  test("Custom Test Cases for txtDeductions", () => {
    // START_USER_CODE-USER_txtDeductions_TEST
    // END_USER_CODE-USER_txtDeductions_TEST
  });
  test("txtDiscoloredShells(Textbox Widget) Test Cases", async () => {
    const txtDiscoloredShells = screen.getByTestId("txtDiscoloredShells");
    expect(txtDiscoloredShells.tagName).toBe("INPUT");
    expect(txtDiscoloredShells.type).toBe("text");
    expect(txtDiscoloredShells.classList).toContain("textboxWidgetClass");
    expect(txtDiscoloredShells.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtDiscoloredShells")
    );
    await act(async () => {
      userEvent.type(txtDiscoloredShells, "1");
    });
  });
  test("Custom Test Cases for txtDiscoloredShells", () => {
    // START_USER_CODE-USER_txtDiscoloredShells_TEST
    // END_USER_CODE-USER_txtDiscoloredShells_TEST
  });
  test("txtDiscoloredShellsPct(Textbox Widget) Test Cases", async () => {
    const txtDiscoloredShellsPct = screen.getByTestId("txtDiscoloredShellsPct");
    expect(txtDiscoloredShellsPct.tagName).toBe("INPUT");
    expect(txtDiscoloredShellsPct.type).toBe("text");
    expect(txtDiscoloredShellsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDiscoloredShellsPct, "1");
    });
  });
  test("Custom Test Cases for txtDiscoloredShellsPct", () => {
    // START_USER_CODE-USER_txtDiscoloredShellsPct_TEST
    // END_USER_CODE-USER_txtDiscoloredShellsPct_TEST
  });
  test("txtDTWhseBinNum(Textbox Widget) Test Cases", async () => {
    const txtDTWhseBinNum = screen.getByTestId("txtDTWhseBinNum");
    expect(txtDTWhseBinNum.tagName).toBe("INPUT");
    expect(txtDTWhseBinNum.type).toBe("text");
    expect(txtDTWhseBinNum.classList).toContain("textboxWidgetClass");
    expect(txtDTWhseBinNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtDTWhseBinNum")
    );
    await act(async () => {
      userEvent.type(txtDTWhseBinNum, "1");
    });
  });
  test("Custom Test Cases for txtDTWhseBinNum", () => {
    // START_USER_CODE-USER_txtDTWhseBinNum_TEST
    // END_USER_CODE-USER_txtDTWhseBinNum_TEST
  });
  test("txtELKDamaged(Textbox Widget) Test Cases", async () => {
    const txtELKDamaged = screen.getByTestId("txtELKDamaged");
    expect(txtELKDamaged.tagName).toBe("INPUT");
    expect(txtELKDamaged.type).toBe("text");
    expect(txtELKDamaged.classList).toContain("textboxWidgetClass");
    expect(txtELKDamaged.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtELKDamaged")
    );
    await act(async () => {
      userEvent.type(txtELKDamaged, "1");
    });
  });
  test("Custom Test Cases for txtELKDamaged", () => {
    // START_USER_CODE-USER_txtELKDamaged_TEST
    // END_USER_CODE-USER_txtELKDamaged_TEST
  });
  test("txtELKPremiumPerPound(Textbox Widget) Test Cases", async () => {
    const txtELKPremiumPerPound = screen.getByTestId("txtELKPremiumPerPound");
    expect(txtELKPremiumPerPound.tagName).toBe("INPUT");
    expect(txtELKPremiumPerPound.type).toBe("text");
    expect(txtELKPremiumPerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtELKPremiumPerPound, "1");
    });
  });
  test("Custom Test Cases for txtELKPremiumPerPound", () => {
    // START_USER_CODE-USER_txtELKPremiumPerPound_TEST
    // END_USER_CODE-USER_txtELKPremiumPerPound_TEST
  });
  test("txtELKPremiumPerTon(Textbox Widget) Test Cases", async () => {
    const txtELKPremiumPerTon = screen.getByTestId("txtELKPremiumPerTon");
    expect(txtELKPremiumPerTon.tagName).toBe("INPUT");
    expect(txtELKPremiumPerTon.type).toBe("text");
    expect(txtELKPremiumPerTon.classList).toContain("textboxWidgetClass");
    expect(txtELKPremiumPerTon.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtELKPremiumPerTon")
    );
    await act(async () => {
      userEvent.type(txtELKPremiumPerTon, "1");
    });
  });
  test("Custom Test Cases for txtELKPremiumPerTon", () => {
    // START_USER_CODE-USER_txtELKPremiumPerTon_TEST
    // END_USER_CODE-USER_txtELKPremiumPerTon_TEST
  });
  test("txtExcessMoisture(Textbox Widget) Test Cases", async () => {
    const txtExcessMoisture = screen.getByTestId("txtExcessMoisture");
    expect(txtExcessMoisture.tagName).toBe("INPUT");
    expect(txtExcessMoisture.type).toBe("text");
    expect(txtExcessMoisture.classList).toContain("textboxWidgetClass");
    expect(txtExcessMoisture.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtExcessMoisture")
    );
    await act(async () => {
      userEvent.type(txtExcessMoisture, "1");
    });
  });
  test("Custom Test Cases for txtExcessMoisture", () => {
    // START_USER_CODE-USER_txtExcessMoisture_TEST
    // END_USER_CODE-USER_txtExcessMoisture_TEST
  });
  test("txtExcessSplitsPerPound(Textbox Widget) Test Cases", async () => {
    const txtExcessSplitsPerPound = screen.getByTestId(
      "txtExcessSplitsPerPound"
    );
    expect(txtExcessSplitsPerPound.tagName).toBe("INPUT");
    expect(txtExcessSplitsPerPound.type).toBe("text");
    expect(txtExcessSplitsPerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtExcessSplitsPerPound, "1");
    });
  });
  test("Custom Test Cases for txtExcessSplitsPerPound", () => {
    // START_USER_CODE-USER_txtExcessSplitsPerPound_TEST
    // END_USER_CODE-USER_txtExcessSplitsPerPound_TEST
  });
  test("txtExcessSplitsPerTon(Textbox Widget) Test Cases", async () => {
    const txtExcessSplitsPerTon = screen.getByTestId("txtExcessSplitsPerTon");
    expect(txtExcessSplitsPerTon.tagName).toBe("INPUT");
    expect(txtExcessSplitsPerTon.type).toBe("text");
    expect(txtExcessSplitsPerTon.classList).toContain("textboxWidgetClass");
    expect(txtExcessSplitsPerTon.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtExcessSplitsPerTon")
    );
    await act(async () => {
      userEvent.type(txtExcessSplitsPerTon, "1");
    });
  });
  test("Custom Test Cases for txtExcessSplitsPerTon", () => {
    // START_USER_CODE-USER_txtExcessSplitsPerTon_TEST
    // END_USER_CODE-USER_txtExcessSplitsPerTon_TEST
  });
  test("txtFancy(Textbox Widget) Test Cases", async () => {
    const txtFancy = screen.getByTestId("txtFancy");
    expect(txtFancy.tagName).toBe("INPUT");
    expect(txtFancy.type).toBe("text");
    expect(txtFancy.classList).toContain("textboxWidgetClass");
    expect(txtFancy.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtFancy")
    );
    await act(async () => {
      userEvent.type(txtFancy, "1");
    });
  });
  test("Custom Test Cases for txtFancy", () => {
    // START_USER_CODE-USER_txtFancy_TEST
    // END_USER_CODE-USER_txtFancy_TEST
  });
  test("txtFancyPct(Textbox Widget) Test Cases", async () => {
    const txtFancyPct = screen.getByTestId("txtFancyPct");
    expect(txtFancyPct.tagName).toBe("INPUT");
    expect(txtFancyPct.type).toBe("text");
    expect(txtFancyPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFancyPct, "1");
    });
  });
  test("Custom Test Cases for txtFancyPct", () => {
    // START_USER_CODE-USER_txtFancyPct_TEST
    // END_USER_CODE-USER_txtFancyPct_TEST
  });
  test("txtFMSampleWt(Textbox Widget) Test Cases", async () => {
    const txtFMSampleWt = screen.getByTestId("txtFMSampleWt");
    expect(txtFMSampleWt.tagName).toBe("INPUT");
    expect(txtFMSampleWt.type).toBe("text");
    expect(txtFMSampleWt.classList).toContain("textboxWidgetClass");
    expect(txtFMSampleWt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtFMSampleWt")
    );
    await act(async () => {
      userEvent.type(txtFMSampleWt, "1");
    });
  });
  test("Custom Test Cases for txtFMSampleWt", () => {
    // START_USER_CODE-USER_txtFMSampleWt_TEST
    // END_USER_CODE-USER_txtFMSampleWt_TEST
  });
  test("txtForeignMaterial(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterial = screen.getByTestId("txtForeignMaterial");
    expect(txtForeignMaterial.tagName).toBe("INPUT");
    expect(txtForeignMaterial.type).toBe("text");
    expect(txtForeignMaterial.classList).toContain("textboxWidgetClass");
    expect(txtForeignMaterial.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtForeignMaterial")
    );
    await act(async () => {
      userEvent.type(txtForeignMaterial, "1");
    });
  });
  test("Custom Test Cases for txtForeignMaterial", () => {
    // START_USER_CODE-USER_txtForeignMaterial_TEST
    // END_USER_CODE-USER_txtForeignMaterial_TEST
  });
  test("txtForeignMaterialPct(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterialPct = screen.getByTestId("txtForeignMaterialPct");
    expect(txtForeignMaterialPct.tagName).toBe("INPUT");
    expect(txtForeignMaterialPct.type).toBe("text");
    expect(txtForeignMaterialPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtForeignMaterialPct, "1");
    });
  });
  test("Custom Test Cases for txtForeignMaterialPct", () => {
    // START_USER_CODE-USER_txtForeignMaterialPct_TEST
    // END_USER_CODE-USER_txtForeignMaterialPct_TEST
  });
  test("txtForeignMtrl(Textbox Widget) Test Cases", async () => {
    const txtForeignMtrl = screen.getByTestId("txtForeignMtrl");
    expect(txtForeignMtrl.tagName).toBe("INPUT");
    expect(txtForeignMtrl.type).toBe("text");
    expect(txtForeignMtrl.classList).toContain("textboxWidgetClass");
    expect(txtForeignMtrl.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtForeignMtrl")
    );
    await act(async () => {
      userEvent.type(txtForeignMtrl, "1");
    });
  });
  test("Custom Test Cases for txtForeignMtrl", () => {
    // START_USER_CODE-USER_txtForeignMtrl_TEST
    // END_USER_CODE-USER_txtForeignMtrl_TEST
  });
  test("txtFreezeDamage(Textbox Widget) Test Cases", async () => {
    const txtFreezeDamage = screen.getByTestId("txtFreezeDamage");
    expect(txtFreezeDamage.tagName).toBe("INPUT");
    expect(txtFreezeDamage.type).toBe("text");
    expect(txtFreezeDamage.classList).toContain("textboxWidgetClass");
    expect(txtFreezeDamage.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtFreezeDamage")
    );
    await act(async () => {
      userEvent.type(txtFreezeDamage, "1");
    });
  });
  test("Custom Test Cases for txtFreezeDamage", () => {
    // START_USER_CODE-USER_txtFreezeDamage_TEST
    // END_USER_CODE-USER_txtFreezeDamage_TEST
  });
  test("txtFreezeDamagePct(Textbox Widget) Test Cases", async () => {
    const txtFreezeDamagePct = screen.getByTestId("txtFreezeDamagePct");
    expect(txtFreezeDamagePct.tagName).toBe("INPUT");
    expect(txtFreezeDamagePct.type).toBe("text");
    expect(txtFreezeDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFreezeDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtFreezeDamagePct", () => {
    // START_USER_CODE-USER_txtFreezeDamagePct_TEST
    // END_USER_CODE-USER_txtFreezeDamagePct_TEST
  });
  test("txtGrossWeight(Textbox Widget) Test Cases", async () => {
    const txtGrossWeight = screen.getByTestId("txtGrossWeight");
    expect(txtGrossWeight.tagName).toBe("INPUT");
    expect(txtGrossWeight.type).toBe("text");
    expect(txtGrossWeight.classList).toContain("textboxWidgetClass");
    expect(txtGrossWeight.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtGrossWeight")
    );
    await act(async () => {
      userEvent.type(txtGrossWeight, "1");
    });
  });
  test("Custom Test Cases for txtGrossWeight", () => {
    // START_USER_CODE-USER_txtGrossWeight_TEST
    // END_USER_CODE-USER_txtGrossWeight_TEST
  });
  test("txtH(Textbox Widget) Test Cases", async () => {
    const txtH = screen.getByTestId("txtH");
    expect(txtH.tagName).toBe("INPUT");
    expect(txtH.type).toBe("text");
    expect(txtH.classList).toContain("textboxWidgetClass");
    expect(txtH.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtH")
    );
    await act(async () => {
      userEvent.type(txtH, "1");
    });
  });
  test("Custom Test Cases for txtH", () => {
    // START_USER_CODE-USER_txtH_TEST
    // END_USER_CODE-USER_txtH_TEST
  });
  test("txtHullBrightness(Textbox Widget) Test Cases", async () => {
    const txtHullBrightness = screen.getByTestId("txtHullBrightness");
    expect(txtHullBrightness.tagName).toBe("INPUT");
    expect(txtHullBrightness.type).toBe("text");
    expect(txtHullBrightness.classList).toContain("textboxWidgetClass");
    expect(txtHullBrightness.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtHullBrightness")
    );
    await act(async () => {
      userEvent.type(txtHullBrightness, "1");
    });
  });
  test("Custom Test Cases for txtHullBrightness", () => {
    // START_USER_CODE-USER_txtHullBrightness_TEST
    // END_USER_CODE-USER_txtHullBrightness_TEST
  });
  test("txtHullBrightnessPct(Textbox Widget) Test Cases", async () => {
    const txtHullBrightnessPct = screen.getByTestId("txtHullBrightnessPct");
    expect(txtHullBrightnessPct.tagName).toBe("INPUT");
    expect(txtHullBrightnessPct.type).toBe("text");
    expect(txtHullBrightnessPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullBrightnessPct, "1");
    });
  });
  test("Custom Test Cases for txtHullBrightnessPct", () => {
    // START_USER_CODE-USER_txtHullBrightnessPct_TEST
    // END_USER_CODE-USER_txtHullBrightnessPct_TEST
  });
  test("txtHulls(Textbox Widget) Test Cases", async () => {
    const txtHulls = screen.getByTestId("txtHulls");
    expect(txtHulls.tagName).toBe("INPUT");
    expect(txtHulls.type).toBe("text");
    expect(txtHulls.classList).toContain("textboxWidgetClass");
    expect(txtHulls.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtHulls")
    );
    await act(async () => {
      userEvent.type(txtHulls, "1");
    });
  });
  test("Custom Test Cases for txtHulls", () => {
    // START_USER_CODE-USER_txtHulls_TEST
    // END_USER_CODE-USER_txtHulls_TEST
  });
  test("txtHullsPct(Textbox Widget) Test Cases", async () => {
    const txtHullsPct = screen.getByTestId("txtHullsPct");
    expect(txtHullsPct.tagName).toBe("INPUT");
    expect(txtHullsPct.type).toBe("text");
    expect(txtHullsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullsPct, "1");
    });
  });
  test("Custom Test Cases for txtHullsPct", () => {
    // START_USER_CODE-USER_txtHullsPct_TEST
    // END_USER_CODE-USER_txtHullsPct_TEST
  });
  test("txtJumbo(Textbox Widget) Test Cases", async () => {
    const txtJumbo = screen.getByTestId("txtJumbo");
    expect(txtJumbo.tagName).toBe("INPUT");
    expect(txtJumbo.type).toBe("text");
    expect(txtJumbo.classList).toContain("textboxWidgetClass");
    expect(txtJumbo.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtJumbo")
    );
    await act(async () => {
      userEvent.type(txtJumbo, "1");
    });
  });
  test("Custom Test Cases for txtJumbo", () => {
    // START_USER_CODE-USER_txtJumbo_TEST
    // END_USER_CODE-USER_txtJumbo_TEST
  });
  test("txtJumboGrPct(Textbox Widget) Test Cases", async () => {
    const txtJumboGrPct = screen.getByTestId("txtJumboGrPct");
    expect(txtJumboGrPct.tagName).toBe("INPUT");
    expect(txtJumboGrPct.type).toBe("text");
    expect(txtJumboGrPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtJumboGrPct, "1");
    });
  });
  test("Custom Test Cases for txtJumboGrPct", () => {
    // START_USER_CODE-USER_txtJumboGrPct_TEST
    // END_USER_CODE-USER_txtJumboGrPct_TEST
  });
  test("txtKernelsREL(Textbox Widget) Test Cases", async () => {
    const txtKernelsREL = screen.getByTestId("txtKernelsREL");
    expect(txtKernelsREL.tagName).toBe("INPUT");
    expect(txtKernelsREL.type).toBe("text");
    expect(txtKernelsREL.classList).toContain("textboxWidgetClass");
    expect(txtKernelsREL.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtKernelsREL")
    );
    await act(async () => {
      userEvent.type(txtKernelsREL, "1");
    });
  });
  test("Custom Test Cases for txtKernelsREL", () => {
    // START_USER_CODE-USER_txtKernelsREL_TEST
    // END_USER_CODE-USER_txtKernelsREL_TEST
  });
  test("txtKernelsRPS(Textbox Widget) Test Cases", async () => {
    const txtKernelsRPS = screen.getByTestId("txtKernelsRPS");
    expect(txtKernelsRPS.tagName).toBe("INPUT");
    expect(txtKernelsRPS.type).toBe("text");
    expect(txtKernelsRPS.classList).toContain("textboxWidgetClass");
    expect(txtKernelsRPS.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtKernelsRPS")
    );
    await act(async () => {
      userEvent.type(txtKernelsRPS, "1");
    });
  });
  test("Custom Test Cases for txtKernelsRPS", () => {
    // START_USER_CODE-USER_txtKernelsRPS_TEST
    // END_USER_CODE-USER_txtKernelsRPS_TEST
  });
  test("txtKernelValuePerPound(Textbox Widget) Test Cases", async () => {
    const txtKernelValuePerPound = screen.getByTestId("txtKernelValuePerPound");
    expect(txtKernelValuePerPound.tagName).toBe("INPUT");
    expect(txtKernelValuePerPound.type).toBe("text");
    expect(txtKernelValuePerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtKernelValuePerPound, "1");
    });
  });
  test("Custom Test Cases for txtKernelValuePerPound", () => {
    // START_USER_CODE-USER_txtKernelValuePerPound_TEST
    // END_USER_CODE-USER_txtKernelValuePerPound_TEST
  });
  test("txtKernelValuePerTon(Textbox Widget) Test Cases", async () => {
    const txtKernelValuePerTon = screen.getByTestId("txtKernelValuePerTon");
    expect(txtKernelValuePerTon.tagName).toBe("INPUT");
    expect(txtKernelValuePerTon.type).toBe("text");
    expect(txtKernelValuePerTon.classList).toContain("textboxWidgetClass");
    expect(txtKernelValuePerTon.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtKernelValuePerTon")
    );
    await act(async () => {
      userEvent.type(txtKernelValuePerTon, "1");
    });
  });
  test("Custom Test Cases for txtKernelValuePerTon", () => {
    // START_USER_CODE-USER_txtKernelValuePerTon_TEST
    // END_USER_CODE-USER_txtKernelValuePerTon_TEST
  });
  test("txtKernRidingELK(Textbox Widget) Test Cases", async () => {
    const txtKernRidingELK = screen.getByTestId("txtKernRidingELK");
    expect(txtKernRidingELK.tagName).toBe("INPUT");
    expect(txtKernRidingELK.type).toBe("text");
    expect(txtKernRidingELK.classList).toContain("textboxWidgetClass");
    expect(txtKernRidingELK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtKernRidingELK")
    );
    await act(async () => {
      userEvent.type(txtKernRidingELK, "1");
    });
  });
  test("Custom Test Cases for txtKernRidingELK", () => {
    // START_USER_CODE-USER_txtKernRidingELK_TEST
    // END_USER_CODE-USER_txtKernRidingELK_TEST
  });
  test("txtKernRidingPrescribe(Textbox Widget) Test Cases", async () => {
    const txtKernRidingPrescribe = screen.getByTestId("txtKernRidingPrescribe");
    expect(txtKernRidingPrescribe.tagName).toBe("INPUT");
    expect(txtKernRidingPrescribe.type).toBe("text");
    expect(txtKernRidingPrescribe.classList).toContain("textboxWidgetClass");
    expect(txtKernRidingPrescribe.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtKernRidingPrescribe")
    );
    await act(async () => {
      userEvent.type(txtKernRidingPrescribe, "1");
    });
  });
  test("Custom Test Cases for txtKernRidingPrescribe", () => {
    // START_USER_CODE-USER_txtKernRidingPrescribe_TEST
    // END_USER_CODE-USER_txtKernRidingPrescribe_TEST
  });
  test("txtKernRidingPrescribePct(Textbox Widget) Test Cases", async () => {
    const txtKernRidingPrescribePct = screen.getByTestId(
      "txtKernRidingPrescribePct"
    );
    expect(txtKernRidingPrescribePct.tagName).toBe("INPUT");
    expect(txtKernRidingPrescribePct.type).toBe("text");
    expect(txtKernRidingPrescribePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtKernRidingPrescribePct, "1");
    });
  });
  test("Custom Test Cases for txtKernRidingPrescribePct", () => {
    // START_USER_CODE-USER_txtKernRidingPrescribePct_TEST
    // END_USER_CODE-USER_txtKernRidingPrescribePct_TEST
  });
  test("txtLoadLess100PctAdjValue(Textbox Widget) Test Cases", async () => {
    const txtLoadLess100PctAdjValue = screen.getByTestId(
      "txtLoadLess100PctAdjValue"
    );
    expect(txtLoadLess100PctAdjValue.tagName).toBe("INPUT");
    expect(txtLoadLess100PctAdjValue.type).toBe("text");
    expect(txtLoadLess100PctAdjValue.classList).toContain("textboxWidgetClass");
    expect(
      txtLoadLess100PctAdjValue.previousElementSibling.textContent
    ).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtLoadLess100PctAdjValue")
    );
    await act(async () => {
      userEvent.type(txtLoadLess100PctAdjValue, "1");
    });
  });
  test("Custom Test Cases for txtLoadLess100PctAdjValue", () => {
    // START_USER_CODE-USER_txtLoadLess100PctAdjValue_TEST
    // END_USER_CODE-USER_txtLoadLess100PctAdjValue_TEST
  });
  test("txtLoadNum(Textbox Widget) Test Cases", async () => {
    const txtLoadNum = screen.getByTestId("txtLoadNum");
    expect(txtLoadNum.tagName).toBe("INPUT");
    expect(txtLoadNum.type).toBe("text");
    expect(txtLoadNum.classList).toContain("textboxWidgetClass");
    expect(txtLoadNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtLoadNum")
    );
    await act(async () => {
      userEvent.type(txtLoadNum, "1");
    });
  });
  test("Custom Test Cases for txtLoadNum", () => {
    // START_USER_CODE-USER_txtLoadNum_TEST
    // END_USER_CODE-USER_txtLoadNum_TEST
  });
  test("txtLotNum(Textbox Widget) Test Cases", async () => {
    const txtLotNum = screen.getByTestId("txtLotNum");
    expect(txtLotNum.tagName).toBe("INPUT");
    expect(txtLotNum.type).toBe("text");
    expect(txtLotNum.classList).toContain("textboxWidgetClass");
    expect(txtLotNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtLotNum")
    );
    await act(async () => {
      userEvent.type(txtLotNum, "1");
    });
  });
  test("Custom Test Cases for txtLotNum", () => {
    // START_USER_CODE-USER_txtLotNum_TEST
    // END_USER_CODE-USER_txtLotNum_TEST
  });
  test("txtLSK(Textbox Widget) Test Cases", async () => {
    const txtLSK = screen.getByTestId("txtLSK");
    expect(txtLSK.tagName).toBe("INPUT");
    expect(txtLSK.type).toBe("text");
    expect(txtLSK.classList).toContain("textboxWidgetClass");
    expect(txtLSK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtLSK")
    );
    await act(async () => {
      userEvent.type(txtLSK, "1");
    });
  });
  test("Custom Test Cases for txtLSK", () => {
    // START_USER_CODE-USER_txtLSK_TEST
    // END_USER_CODE-USER_txtLSK_TEST
  });
  test("txtLSKDamageRiding(Textbox Widget) Test Cases", async () => {
    const txtLSKDamageRiding = screen.getByTestId("txtLSKDamageRiding");
    expect(txtLSKDamageRiding.tagName).toBe("INPUT");
    expect(txtLSKDamageRiding.type).toBe("text");
    expect(txtLSKDamageRiding.classList).toContain("textboxWidgetClass");
    expect(txtLSKDamageRiding.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtLSKDamageRiding")
    );
    await act(async () => {
      userEvent.type(txtLSKDamageRiding, "1");
    });
  });
  test("Custom Test Cases for txtLSKDamageRiding", () => {
    // START_USER_CODE-USER_txtLSKDamageRiding_TEST
    // END_USER_CODE-USER_txtLSKDamageRiding_TEST
  });
  test("txtLSKFallThru(Textbox Widget) Test Cases", async () => {
    const txtLSKFallThru = screen.getByTestId("txtLSKFallThru");
    expect(txtLSKFallThru.tagName).toBe("INPUT");
    expect(txtLSKFallThru.type).toBe("text");
    expect(txtLSKFallThru.classList).toContain("textboxWidgetClass");
    expect(txtLSKFallThru.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtLSKFallThru")
    );
    await act(async () => {
      userEvent.type(txtLSKFallThru, "1");
    });
  });
  test("Custom Test Cases for txtLSKFallThru", () => {
    // START_USER_CODE-USER_txtLSKFallThru_TEST
    // END_USER_CODE-USER_txtLSKFallThru_TEST
  });
  test("txtLSKRidingGr(Textbox Widget) Test Cases", async () => {
    const txtLSKRidingGr = screen.getByTestId("txtLSKRidingGr");
    expect(txtLSKRidingGr.tagName).toBe("INPUT");
    expect(txtLSKRidingGr.type).toBe("text");
    expect(txtLSKRidingGr.classList).toContain("textboxWidgetClass");
    expect(txtLSKRidingGr.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtLSKRidingGr")
    );
    await act(async () => {
      userEvent.type(txtLSKRidingGr, "1");
    });
  });
  test("Custom Test Cases for txtLSKRidingGr", () => {
    // START_USER_CODE-USER_txtLSKRidingGr_TEST
    // END_USER_CODE-USER_txtLSKRidingGr_TEST
  });
  test("txtLSKRidingPct(Textbox Widget) Test Cases", async () => {
    const txtLSKRidingPct = screen.getByTestId("txtLSKRidingPct");
    expect(txtLSKRidingPct.tagName).toBe("INPUT");
    expect(txtLSKRidingPct.type).toBe("text");
    expect(txtLSKRidingPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLSKRidingPct, "1");
    });
  });
  test("Custom Test Cases for txtLSKRidingPct", () => {
    // START_USER_CODE-USER_txtLSKRidingPct_TEST
    // END_USER_CODE-USER_txtLSKRidingPct_TEST
  });
  test("txtMeterReading(Textbox Widget) Test Cases", async () => {
    const txtMeterReading = screen.getByTestId("txtMeterReading");
    expect(txtMeterReading.tagName).toBe("INPUT");
    expect(txtMeterReading.type).toBe("text");
    expect(txtMeterReading.classList).toContain("textboxWidgetClass");
    expect(txtMeterReading.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtMeterReading")
    );
    await act(async () => {
      userEvent.type(txtMeterReading, "1");
    });
  });
  test("Custom Test Cases for txtMeterReading", () => {
    // START_USER_CODE-USER_txtMeterReading_TEST
    // END_USER_CODE-USER_txtMeterReading_TEST
  });
  test("txtMoisture(Textbox Widget) Test Cases", async () => {
    const txtMoisture = screen.getByTestId("txtMoisture");
    expect(txtMoisture.tagName).toBe("INPUT");
    expect(txtMoisture.type).toBe("text");
    expect(txtMoisture.classList).toContain("textboxWidgetClass");
    expect(txtMoisture.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtMoisture")
    );
    await act(async () => {
      userEvent.type(txtMoisture, "1");
    });
  });
  test("Custom Test Cases for txtMoisture", () => {
    // START_USER_CODE-USER_txtMoisture_TEST
    // END_USER_CODE-USER_txtMoisture_TEST
  });
  test("txtNetELK(Textbox Widget) Test Cases", async () => {
    const txtNetELK = screen.getByTestId("txtNetELK");
    expect(txtNetELK.tagName).toBe("INPUT");
    expect(txtNetELK.type).toBe("text");
    expect(txtNetELK.classList).toContain("textboxWidgetClass");
    expect(txtNetELK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtNetELK")
    );
    await act(async () => {
      userEvent.type(txtNetELK, "1");
    });
  });
  test("Custom Test Cases for txtNetELK", () => {
    // START_USER_CODE-USER_txtNetELK_TEST
    // END_USER_CODE-USER_txtNetELK_TEST
  });
  test("txtNetELKPct(Textbox Widget) Test Cases", async () => {
    const txtNetELKPct = screen.getByTestId("txtNetELKPct");
    expect(txtNetELKPct.tagName).toBe("INPUT");
    expect(txtNetELKPct.type).toBe("text");
    expect(txtNetELKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtNetELKPct, "1");
    });
  });
  test("Custom Test Cases for txtNetELKPct", () => {
    // START_USER_CODE-USER_txtNetELKPct_TEST
    // END_USER_CODE-USER_txtNetELKPct_TEST
  });
  test("txtNetValPerTonExclSLK(Textbox Widget) Test Cases", async () => {
    const txtNetValPerTonExclSLK = screen.getByTestId("txtNetValPerTonExclSLK");
    expect(txtNetValPerTonExclSLK.tagName).toBe("INPUT");
    expect(txtNetValPerTonExclSLK.type).toBe("text");
    expect(txtNetValPerTonExclSLK.classList).toContain("textboxWidgetClass");
    expect(txtNetValPerTonExclSLK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtNetValPerTonExclSLK")
    );
    await act(async () => {
      userEvent.type(txtNetValPerTonExclSLK, "1");
    });
  });
  test("Custom Test Cases for txtNetValPerTonExclSLK", () => {
    // START_USER_CODE-USER_txtNetValPerTonExclSLK_TEST
    // END_USER_CODE-USER_txtNetValPerTonExclSLK_TEST
  });
  test("txtNetWeight(Textbox Widget) Test Cases", async () => {
    const txtNetWeight = screen.getByTestId("txtNetWeight");
    expect(txtNetWeight.tagName).toBe("INPUT");
    expect(txtNetWeight.type).toBe("text");
    expect(txtNetWeight.classList).toContain("textboxWidgetClass");
    expect(txtNetWeight.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtNetWeight")
    );
    await act(async () => {
      userEvent.type(txtNetWeight, "1");
    });
  });
  test("Custom Test Cases for txtNetWeight", () => {
    // START_USER_CODE-USER_txtNetWeight_TEST
    // END_USER_CODE-USER_txtNetWeight_TEST
  });
  test("txtNetWt(Textbox Widget) Test Cases", async () => {
    const txtNetWt = screen.getByTestId("txtNetWt");
    expect(txtNetWt.tagName).toBe("INPUT");
    expect(txtNetWt.type).toBe("text");
    expect(txtNetWt.classList).toContain("textboxWidgetClass");
    expect(txtNetWt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtNetWt")
    );
    await act(async () => {
      userEvent.type(txtNetWt, "1");
    });
  });
  test("Custom Test Cases for txtNetWt", () => {
    // START_USER_CODE-USER_txtNetWt_TEST
    // END_USER_CODE-USER_txtNetWt_TEST
  });
  test("txtNetWtExcludingLSK(Textbox Widget) Test Cases", async () => {
    const txtNetWtExcludingLSK = screen.getByTestId("txtNetWtExcludingLSK");
    expect(txtNetWtExcludingLSK.tagName).toBe("INPUT");
    expect(txtNetWtExcludingLSK.type).toBe("text");
    expect(txtNetWtExcludingLSK.classList).toContain("textboxWidgetClass");
    expect(txtNetWtExcludingLSK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtNetWtExcludingLSK")
    );
    await act(async () => {
      userEvent.type(txtNetWtExcludingLSK, "1");
    });
  });
  test("Custom Test Cases for txtNetWtExcludingLSK", () => {
    // START_USER_CODE-USER_txtNetWtExcludingLSK_TEST
    // END_USER_CODE-USER_txtNetWtExcludingLSK_TEST
  });
  test("txtOI(Textbox Widget) Test Cases", async () => {
    const txtOI = screen.getByTestId("txtOI");
    expect(txtOI.tagName).toBe("INPUT");
    expect(txtOI.type).toBe("text");
    expect(txtOI.classList).toContain("textboxWidgetClass");
    expect(txtOI.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtOI")
    );
    await act(async () => {
      userEvent.type(txtOI, "1");
    });
  });
  test("Custom Test Cases for txtOI", () => {
    // START_USER_CODE-USER_txtOI_TEST
    // END_USER_CODE-USER_txtOI_TEST
  });
  test("txtOKDamageRiding(Textbox Widget) Test Cases", async () => {
    const txtOKDamageRiding = screen.getByTestId("txtOKDamageRiding");
    expect(txtOKDamageRiding.tagName).toBe("INPUT");
    expect(txtOKDamageRiding.type).toBe("text");
    expect(txtOKDamageRiding.classList).toContain("textboxWidgetClass");
    expect(txtOKDamageRiding.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtOKDamageRiding")
    );
    await act(async () => {
      userEvent.type(txtOKDamageRiding, "1");
    });
  });
  test("Custom Test Cases for txtOKDamageRiding", () => {
    // START_USER_CODE-USER_txtOKDamageRiding_TEST
    // END_USER_CODE-USER_txtOKDamageRiding_TEST
  });
  test("txtOKFallThru(Textbox Widget) Test Cases", async () => {
    const txtOKFallThru = screen.getByTestId("txtOKFallThru");
    expect(txtOKFallThru.tagName).toBe("INPUT");
    expect(txtOKFallThru.type).toBe("text");
    expect(txtOKFallThru.classList).toContain("textboxWidgetClass");
    expect(txtOKFallThru.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtOKFallThru")
    );
    await act(async () => {
      userEvent.type(txtOKFallThru, "1");
    });
  });
  test("Custom Test Cases for txtOKFallThru", () => {
    // START_USER_CODE-USER_txtOKFallThru_TEST
    // END_USER_CODE-USER_txtOKFallThru_TEST
  });
  test("txtOKRidingGr(Textbox Widget) Test Cases", async () => {
    const txtOKRidingGr = screen.getByTestId("txtOKRidingGr");
    expect(txtOKRidingGr.tagName).toBe("INPUT");
    expect(txtOKRidingGr.type).toBe("text");
    expect(txtOKRidingGr.classList).toContain("textboxWidgetClass");
    expect(txtOKRidingGr.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtOKRidingGr")
    );
    await act(async () => {
      userEvent.type(txtOKRidingGr, "1");
    });
  });
  test("Custom Test Cases for txtOKRidingGr", () => {
    // START_USER_CODE-USER_txtOKRidingGr_TEST
    // END_USER_CODE-USER_txtOKRidingGr_TEST
  });
  test("txtOKRidingPct(Textbox Widget) Test Cases", async () => {
    const txtOKRidingPct = screen.getByTestId("txtOKRidingPct");
    expect(txtOKRidingPct.tagName).toBe("INPUT");
    expect(txtOKRidingPct.type).toBe("text");
    expect(txtOKRidingPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOKRidingPct, "1");
    });
  });
  test("Custom Test Cases for txtOKRidingPct", () => {
    // START_USER_CODE-USER_txtOKRidingPct_TEST
    // END_USER_CODE-USER_txtOKRidingPct_TEST
  });
  test("txtOtherFM(Textbox Widget) Test Cases", async () => {
    const txtOtherFM = screen.getByTestId("txtOtherFM");
    expect(txtOtherFM.tagName).toBe("INPUT");
    expect(txtOtherFM.type).toBe("text");
    expect(txtOtherFM.classList).toContain("textboxWidgetClass");
    expect(txtOtherFM.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtOtherFM")
    );
    await act(async () => {
      userEvent.type(txtOtherFM, "1");
    });
  });
  test("Custom Test Cases for txtOtherFM", () => {
    // START_USER_CODE-USER_txtOtherFM_TEST
    // END_USER_CODE-USER_txtOtherFM_TEST
  });
  test("txtPerG(Textbox Widget) Test Cases", async () => {
    const txtPerG = screen.getByTestId("txtPerG");
    expect(txtPerG.tagName).toBe("INPUT");
    expect(txtPerG.type).toBe("text");
    expect(txtPerG.classList).toContain("textboxWidgetClass");
    expect(txtPerG.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtPerG")
    );
    await act(async () => {
      userEvent.type(txtPerG, "1");
    });
  });
  test("Custom Test Cases for txtPerG", () => {
    // START_USER_CODE-USER_txtPerG_TEST
    // END_USER_CODE-USER_txtPerG_TEST
  });
  test("txtPittingDamage(Textbox Widget) Test Cases", async () => {
    const txtPittingDamage = screen.getByTestId("txtPittingDamage");
    expect(txtPittingDamage.tagName).toBe("INPUT");
    expect(txtPittingDamage.type).toBe("text");
    expect(txtPittingDamage.classList).toContain("textboxWidgetClass");
    expect(txtPittingDamage.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtPittingDamage")
    );
    await act(async () => {
      userEvent.type(txtPittingDamage, "1");
    });
  });
  test("Custom Test Cases for txtPittingDamage", () => {
    // START_USER_CODE-USER_txtPittingDamage_TEST
    // END_USER_CODE-USER_txtPittingDamage_TEST
  });
  test("txtPittingDamagePct(Textbox Widget) Test Cases", async () => {
    const txtPittingDamagePct = screen.getByTestId("txtPittingDamagePct");
    expect(txtPittingDamagePct.tagName).toBe("INPUT");
    expect(txtPittingDamagePct.type).toBe("text");
    expect(txtPittingDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPittingDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtPittingDamagePct", () => {
    // START_USER_CODE-USER_txtPittingDamagePct_TEST
    // END_USER_CODE-USER_txtPittingDamagePct_TEST
  });
  test("txtPremiums(Textbox Widget) Test Cases", async () => {
    const txtPremiums = screen.getByTestId("txtPremiums");
    expect(txtPremiums.tagName).toBe("INPUT");
    expect(txtPremiums.type).toBe("text");
    expect(txtPremiums.classList).toContain("textboxWidgetClass");
    expect(txtPremiums.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtPremiums")
    );
    await act(async () => {
      userEvent.type(txtPremiums, "1");
    });
  });
  test("Custom Test Cases for txtPremiums", () => {
    // START_USER_CODE-USER_txtPremiums_TEST
    // END_USER_CODE-USER_txtPremiums_TEST
  });
  test("txtProbingPattern(Textbox Widget) Test Cases", async () => {
    const txtProbingPattern = screen.getByTestId("txtProbingPattern");
    expect(txtProbingPattern.tagName).toBe("INPUT");
    expect(txtProbingPattern.type).toBe("text");
    expect(txtProbingPattern.classList).toContain("textboxWidgetClass");
    expect(txtProbingPattern.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtProbingPattern")
    );
    await act(async () => {
      userEvent.type(txtProbingPattern, "1");
    });
  });
  test("Custom Test Cases for txtProbingPattern", () => {
    // START_USER_CODE-USER_txtProbingPattern_TEST
    // END_USER_CODE-USER_txtProbingPattern_TEST
  });
  test("txtProceeds(Textbox Widget) Test Cases", async () => {
    const txtProceeds = screen.getByTestId("txtProceeds");
    expect(txtProceeds.tagName).toBe("INPUT");
    expect(txtProceeds.type).toBe("text");
    expect(txtProceeds.classList).toContain("textboxWidgetClass");
    expect(txtProceeds.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtProceeds")
    );
    await act(async () => {
      userEvent.type(txtProceeds, "1");
    });
  });
  test("Custom Test Cases for txtProceeds", () => {
    // START_USER_CODE-USER_txtProceeds_TEST
    // END_USER_CODE-USER_txtProceeds_TEST
  });
  test("txtSealNum(Textbox Widget) Test Cases", async () => {
    const txtSealNum = screen.getByTestId("txtSealNum");
    expect(txtSealNum.tagName).toBe("INPUT");
    expect(txtSealNum.type).toBe("text");
    expect(txtSealNum.classList).toContain("textboxWidgetClass");
    expect(txtSealNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtSealNum")
    );
    await act(async () => {
      userEvent.type(txtSealNum, "1");
    });
  });
  test("Custom Test Cases for txtSealNum", () => {
    // START_USER_CODE-USER_txtSealNum_TEST
    // END_USER_CODE-USER_txtSealNum_TEST
  });
  test("txtSeedMeetingSpec(Textbox Widget) Test Cases", async () => {
    const txtSeedMeetingSpec = screen.getByTestId("txtSeedMeetingSpec");
    expect(txtSeedMeetingSpec.tagName).toBe("INPUT");
    expect(txtSeedMeetingSpec.type).toBe("text");
    expect(txtSeedMeetingSpec.classList).toContain("textboxWidgetClass");
    expect(txtSeedMeetingSpec.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtSeedMeetingSpec")
    );
    await act(async () => {
      userEvent.type(txtSeedMeetingSpec, "1");
    });
  });
  test("Custom Test Cases for txtSeedMeetingSpec", () => {
    // START_USER_CODE-USER_txtSeedMeetingSpec_TEST
    // END_USER_CODE-USER_txtSeedMeetingSpec_TEST
  });
  test("txtSharePercentage0(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage0 = screen.getByTestId("txtSharePercentage0");
    expect(txtSharePercentage0.tagName).toBe("INPUT");
    expect(txtSharePercentage0.type).toBe("text");
    expect(txtSharePercentage0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage0, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage0", () => {
    // START_USER_CODE-USER_txtSharePercentage0_TEST
    // END_USER_CODE-USER_txtSharePercentage0_TEST
  });
  test("txtSharePercentage1(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage1 = screen.getByTestId("txtSharePercentage1");
    expect(txtSharePercentage1.tagName).toBe("INPUT");
    expect(txtSharePercentage1.type).toBe("text");
    expect(txtSharePercentage1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage1, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage1", () => {
    // START_USER_CODE-USER_txtSharePercentage1_TEST
    // END_USER_CODE-USER_txtSharePercentage1_TEST
  });
  test("txtSharePercentage10(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage10 = screen.getByTestId("txtSharePercentage10");
    expect(txtSharePercentage10.tagName).toBe("INPUT");
    expect(txtSharePercentage10.type).toBe("text");
    expect(txtSharePercentage10.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage10, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage10", () => {
    // START_USER_CODE-USER_txtSharePercentage10_TEST
    // END_USER_CODE-USER_txtSharePercentage10_TEST
  });
  test("txtSharePercentage11(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage11 = screen.getByTestId("txtSharePercentage11");
    expect(txtSharePercentage11.tagName).toBe("INPUT");
    expect(txtSharePercentage11.type).toBe("text");
    expect(txtSharePercentage11.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage11, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage11", () => {
    // START_USER_CODE-USER_txtSharePercentage11_TEST
    // END_USER_CODE-USER_txtSharePercentage11_TEST
  });
  test("txtSharePercentage12(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage12 = screen.getByTestId("txtSharePercentage12");
    expect(txtSharePercentage12.tagName).toBe("INPUT");
    expect(txtSharePercentage12.type).toBe("text");
    expect(txtSharePercentage12.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage12, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage12", () => {
    // START_USER_CODE-USER_txtSharePercentage12_TEST
    // END_USER_CODE-USER_txtSharePercentage12_TEST
  });
  test("txtSharePercentage13(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage13 = screen.getByTestId("txtSharePercentage13");
    expect(txtSharePercentage13.tagName).toBe("INPUT");
    expect(txtSharePercentage13.type).toBe("text");
    expect(txtSharePercentage13.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage13, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage13", () => {
    // START_USER_CODE-USER_txtSharePercentage13_TEST
    // END_USER_CODE-USER_txtSharePercentage13_TEST
  });
  test("txtSharePercentage14(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage14 = screen.getByTestId("txtSharePercentage14");
    expect(txtSharePercentage14.tagName).toBe("INPUT");
    expect(txtSharePercentage14.type).toBe("text");
    expect(txtSharePercentage14.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage14, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage14", () => {
    // START_USER_CODE-USER_txtSharePercentage14_TEST
    // END_USER_CODE-USER_txtSharePercentage14_TEST
  });
  test("txtSharePercentage15(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage15 = screen.getByTestId("txtSharePercentage15");
    expect(txtSharePercentage15.tagName).toBe("INPUT");
    expect(txtSharePercentage15.type).toBe("text");
    expect(txtSharePercentage15.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage15, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage15", () => {
    // START_USER_CODE-USER_txtSharePercentage15_TEST
    // END_USER_CODE-USER_txtSharePercentage15_TEST
  });
  test("txtSharePercentage16(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage16 = screen.getByTestId("txtSharePercentage16");
    expect(txtSharePercentage16.tagName).toBe("INPUT");
    expect(txtSharePercentage16.type).toBe("text");
    expect(txtSharePercentage16.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage16, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage16", () => {
    // START_USER_CODE-USER_txtSharePercentage16_TEST
    // END_USER_CODE-USER_txtSharePercentage16_TEST
  });
  test("txtSharePercentage17(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage17 = screen.getByTestId("txtSharePercentage17");
    expect(txtSharePercentage17.tagName).toBe("INPUT");
    expect(txtSharePercentage17.type).toBe("text");
    expect(txtSharePercentage17.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage17, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage17", () => {
    // START_USER_CODE-USER_txtSharePercentage17_TEST
    // END_USER_CODE-USER_txtSharePercentage17_TEST
  });
  test("txtSharePercentage18(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage18 = screen.getByTestId("txtSharePercentage18");
    expect(txtSharePercentage18.tagName).toBe("INPUT");
    expect(txtSharePercentage18.type).toBe("text");
    expect(txtSharePercentage18.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage18, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage18", () => {
    // START_USER_CODE-USER_txtSharePercentage18_TEST
    // END_USER_CODE-USER_txtSharePercentage18_TEST
  });
  test("txtSharePercentage19(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage19 = screen.getByTestId("txtSharePercentage19");
    expect(txtSharePercentage19.tagName).toBe("INPUT");
    expect(txtSharePercentage19.type).toBe("text");
    expect(txtSharePercentage19.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage19, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage19", () => {
    // START_USER_CODE-USER_txtSharePercentage19_TEST
    // END_USER_CODE-USER_txtSharePercentage19_TEST
  });
  test("txtSharePercentage2(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage2 = screen.getByTestId("txtSharePercentage2");
    expect(txtSharePercentage2.tagName).toBe("INPUT");
    expect(txtSharePercentage2.type).toBe("text");
    expect(txtSharePercentage2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage2, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage2", () => {
    // START_USER_CODE-USER_txtSharePercentage2_TEST
    // END_USER_CODE-USER_txtSharePercentage2_TEST
  });
  test("txtSharePercentage3(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage3 = screen.getByTestId("txtSharePercentage3");
    expect(txtSharePercentage3.tagName).toBe("INPUT");
    expect(txtSharePercentage3.type).toBe("text");
    expect(txtSharePercentage3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage3, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage3", () => {
    // START_USER_CODE-USER_txtSharePercentage3_TEST
    // END_USER_CODE-USER_txtSharePercentage3_TEST
  });
  test("txtSharePercentage4(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage4 = screen.getByTestId("txtSharePercentage4");
    expect(txtSharePercentage4.tagName).toBe("INPUT");
    expect(txtSharePercentage4.type).toBe("text");
    expect(txtSharePercentage4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage4, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage4", () => {
    // START_USER_CODE-USER_txtSharePercentage4_TEST
    // END_USER_CODE-USER_txtSharePercentage4_TEST
  });
  test("txtSharePercentage5(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage5 = screen.getByTestId("txtSharePercentage5");
    expect(txtSharePercentage5.tagName).toBe("INPUT");
    expect(txtSharePercentage5.type).toBe("text");
    expect(txtSharePercentage5.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage5, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage5", () => {
    // START_USER_CODE-USER_txtSharePercentage5_TEST
    // END_USER_CODE-USER_txtSharePercentage5_TEST
  });
  test("txtSharePercentage6(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage6 = screen.getByTestId("txtSharePercentage6");
    expect(txtSharePercentage6.tagName).toBe("INPUT");
    expect(txtSharePercentage6.type).toBe("text");
    expect(txtSharePercentage6.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage6, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage6", () => {
    // START_USER_CODE-USER_txtSharePercentage6_TEST
    // END_USER_CODE-USER_txtSharePercentage6_TEST
  });
  test("txtSharePercentage7(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage7 = screen.getByTestId("txtSharePercentage7");
    expect(txtSharePercentage7.tagName).toBe("INPUT");
    expect(txtSharePercentage7.type).toBe("text");
    expect(txtSharePercentage7.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage7, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage7", () => {
    // START_USER_CODE-USER_txtSharePercentage7_TEST
    // END_USER_CODE-USER_txtSharePercentage7_TEST
  });
  test("txtSharePercentage8(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage8 = screen.getByTestId("txtSharePercentage8");
    expect(txtSharePercentage8.tagName).toBe("INPUT");
    expect(txtSharePercentage8.type).toBe("text");
    expect(txtSharePercentage8.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage8, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage8", () => {
    // START_USER_CODE-USER_txtSharePercentage8_TEST
    // END_USER_CODE-USER_txtSharePercentage8_TEST
  });
  test("txtSharePercentage9(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage9 = screen.getByTestId("txtSharePercentage9");
    expect(txtSharePercentage9.tagName).toBe("INPUT");
    expect(txtSharePercentage9.type).toBe("text");
    expect(txtSharePercentage9.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage9, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage9", () => {
    // START_USER_CODE-USER_txtSharePercentage9_TEST
    // END_USER_CODE-USER_txtSharePercentage9_TEST
  });
  test("txtSMK1Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK1Gr = screen.getByTestId("txtSMK1Gr");
    expect(txtSMK1Gr.tagName).toBe("INPUT");
    expect(txtSMK1Gr.type).toBe("text");
    expect(txtSMK1Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK1Gr.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtSMK1Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK1Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK1Gr", () => {
    // START_USER_CODE-USER_txtSMK1Gr_TEST
    // END_USER_CODE-USER_txtSMK1Gr_TEST
  });
  test("txtSMK1Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK1Pct = screen.getByTestId("txtSMK1Pct");
    expect(txtSMK1Pct.tagName).toBe("INPUT");
    expect(txtSMK1Pct.type).toBe("text");
    expect(txtSMK1Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK1Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK1Pct", () => {
    // START_USER_CODE-USER_txtSMK1Pct_TEST
    // END_USER_CODE-USER_txtSMK1Pct_TEST
  });
  test("txtSMK2Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK2Gr = screen.getByTestId("txtSMK2Gr");
    expect(txtSMK2Gr.tagName).toBe("INPUT");
    expect(txtSMK2Gr.type).toBe("text");
    expect(txtSMK2Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK2Gr.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtSMK2Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK2Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK2Gr", () => {
    // START_USER_CODE-USER_txtSMK2Gr_TEST
    // END_USER_CODE-USER_txtSMK2Gr_TEST
  });
  test("txtSMK2Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK2Pct = screen.getByTestId("txtSMK2Pct");
    expect(txtSMK2Pct.tagName).toBe("INPUT");
    expect(txtSMK2Pct.type).toBe("text");
    expect(txtSMK2Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK2Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK2Pct", () => {
    // START_USER_CODE-USER_txtSMK2Pct_TEST
    // END_USER_CODE-USER_txtSMK2Pct_TEST
  });
  test("txtSMK3Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK3Gr = screen.getByTestId("txtSMK3Gr");
    expect(txtSMK3Gr.tagName).toBe("INPUT");
    expect(txtSMK3Gr.type).toBe("text");
    expect(txtSMK3Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK3Gr.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtSMK3Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK3Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK3Gr", () => {
    // START_USER_CODE-USER_txtSMK3Gr_TEST
    // END_USER_CODE-USER_txtSMK3Gr_TEST
  });
  test("txtSMK3Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK3Pct = screen.getByTestId("txtSMK3Pct");
    expect(txtSMK3Pct.tagName).toBe("INPUT");
    expect(txtSMK3Pct.type).toBe("text");
    expect(txtSMK3Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK3Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK3Pct", () => {
    // START_USER_CODE-USER_txtSMK3Pct_TEST
    // END_USER_CODE-USER_txtSMK3Pct_TEST
  });
  test("txtSMK4Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK4Gr = screen.getByTestId("txtSMK4Gr");
    expect(txtSMK4Gr.tagName).toBe("INPUT");
    expect(txtSMK4Gr.type).toBe("text");
    expect(txtSMK4Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK4Gr.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtSMK4Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK4Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK4Gr", () => {
    // START_USER_CODE-USER_txtSMK4Gr_TEST
    // END_USER_CODE-USER_txtSMK4Gr_TEST
  });
  test("txtSMK4Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK4Pct = screen.getByTestId("txtSMK4Pct");
    expect(txtSMK4Pct.tagName).toBe("INPUT");
    expect(txtSMK4Pct.type).toBe("text");
    expect(txtSMK4Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK4Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK4Pct", () => {
    // START_USER_CODE-USER_txtSMK4Pct_TEST
    // END_USER_CODE-USER_txtSMK4Pct_TEST
  });
  test("txtSoundSplits(Textbox Widget) Test Cases", async () => {
    const txtSoundSplits = screen.getByTestId("txtSoundSplits");
    expect(txtSoundSplits.tagName).toBe("INPUT");
    expect(txtSoundSplits.type).toBe("text");
    expect(txtSoundSplits.classList).toContain("textboxWidgetClass");
    expect(txtSoundSplits.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtSoundSplits")
    );
    await act(async () => {
      userEvent.type(txtSoundSplits, "1");
    });
  });
  test("Custom Test Cases for txtSoundSplits", () => {
    // START_USER_CODE-USER_txtSoundSplits_TEST
    // END_USER_CODE-USER_txtSoundSplits_TEST
  });
  test("txtSoundSplitsPct(Textbox Widget) Test Cases", async () => {
    const txtSoundSplitsPct = screen.getByTestId("txtSoundSplitsPct");
    expect(txtSoundSplitsPct.tagName).toBe("INPUT");
    expect(txtSoundSplitsPct.type).toBe("text");
    expect(txtSoundSplitsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSoundSplitsPct, "1");
    });
  });
  test("Custom Test Cases for txtSoundSplitsPct", () => {
    // START_USER_CODE-USER_txtSoundSplitsPct_TEST
    // END_USER_CODE-USER_txtSoundSplitsPct_TEST
  });
  test("txtStatus(Textbox Widget) Test Cases", async () => {
    const txtStatus = screen.getByTestId("txtStatus");
    expect(txtStatus.tagName).toBe("INPUT");
    expect(txtStatus.type).toBe("text");
    expect(txtStatus.classList).toContain("textboxWidgetClass");
    expect(txtStatus.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtStatus")
    );
    await act(async () => {
      userEvent.type(txtStatus, "123");
    });
    expect(txtStatus.getAttribute("value")).toBe("");
    expect(txtStatus.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtStatus", () => {
    // START_USER_CODE-USER_txtStatus_TEST
    // END_USER_CODE-USER_txtStatus_TEST
  });
  test("txtTckt(Textbox Widget) Test Cases", async () => {
    const txtTckt = screen.getByTestId("txtTckt");
    expect(txtTckt.tagName).toBe("INPUT");
    expect(txtTckt.type).toBe("text");
    expect(txtTckt.classList).toContain("textboxWidgetClass");
    expect(txtTckt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTckt")
    );
    await act(async () => {
      userEvent.type(txtTckt, "1");
    });
  });
  test("Custom Test Cases for txtTckt", () => {
    // START_USER_CODE-USER_txtTckt_TEST
    // END_USER_CODE-USER_txtTckt_TEST
  });
  test("txtTotal(Textbox Widget) Test Cases", async () => {
    const txtTotal = screen.getByTestId("txtTotal");
    expect(txtTotal.tagName).toBe("INPUT");
    expect(txtTotal.type).toBe("text");
    expect(txtTotal.classList).toContain("textboxWidgetClass");
    expect(txtTotal.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTotal")
    );
    await act(async () => {
      userEvent.type(txtTotal, "1");
    });
  });
  test("Custom Test Cases for txtTotal", () => {
    // START_USER_CODE-USER_txtTotal_TEST
    // END_USER_CODE-USER_txtTotal_TEST
  });
  test("txtTotalDamage(Textbox Widget) Test Cases", async () => {
    const txtTotalDamage = screen.getByTestId("txtTotalDamage");
    expect(txtTotalDamage.tagName).toBe("INPUT");
    expect(txtTotalDamage.type).toBe("text");
    expect(txtTotalDamage.classList).toContain("textboxWidgetClass");
    expect(txtTotalDamage.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTotalDamage")
    );
    await act(async () => {
      userEvent.type(txtTotalDamage, "1");
    });
  });
  test("Custom Test Cases for txtTotalDamage", () => {
    // START_USER_CODE-USER_txtTotalDamage_TEST
    // END_USER_CODE-USER_txtTotalDamage_TEST
  });
  test("txtTotalDamagePct(Textbox Widget) Test Cases", async () => {
    const txtTotalDamagePct = screen.getByTestId("txtTotalDamagePct");
    expect(txtTotalDamagePct.tagName).toBe("INPUT");
    expect(txtTotalDamagePct.type).toBe("text");
    expect(txtTotalDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtTotalDamagePct", () => {
    // START_USER_CODE-USER_txtTotalDamagePct_TEST
    // END_USER_CODE-USER_txtTotalDamagePct_TEST
  });
  test("txtTotalDiscountsPerPound(Textbox Widget) Test Cases", async () => {
    const txtTotalDiscountsPerPound = screen.getByTestId(
      "txtTotalDiscountsPerPound"
    );
    expect(txtTotalDiscountsPerPound.tagName).toBe("INPUT");
    expect(txtTotalDiscountsPerPound.type).toBe("text");
    expect(txtTotalDiscountsPerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalDiscountsPerPound, "1");
    });
  });
  test("Custom Test Cases for txtTotalDiscountsPerPound", () => {
    // START_USER_CODE-USER_txtTotalDiscountsPerPound_TEST
    // END_USER_CODE-USER_txtTotalDiscountsPerPound_TEST
  });
  test("txtTotalDiscountsPerTon(Textbox Widget) Test Cases", async () => {
    const txtTotalDiscountsPerTon = screen.getByTestId(
      "txtTotalDiscountsPerTon"
    );
    expect(txtTotalDiscountsPerTon.tagName).toBe("INPUT");
    expect(txtTotalDiscountsPerTon.type).toBe("text");
    expect(txtTotalDiscountsPerTon.classList).toContain("textboxWidgetClass");
    expect(txtTotalDiscountsPerTon.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTotalDiscountsPerTon")
    );
    await act(async () => {
      userEvent.type(txtTotalDiscountsPerTon, "1");
    });
  });
  test("Custom Test Cases for txtTotalDiscountsPerTon", () => {
    // START_USER_CODE-USER_txtTotalDiscountsPerTon_TEST
    // END_USER_CODE-USER_txtTotalDiscountsPerTon_TEST
  });
  test("txtTotalELKDamage(Textbox Widget) Test Cases", async () => {
    const txtTotalELKDamage = screen.getByTestId("txtTotalELKDamage");
    expect(txtTotalELKDamage.tagName).toBe("INPUT");
    expect(txtTotalELKDamage.type).toBe("text");
    expect(txtTotalELKDamage.classList).toContain("textboxWidgetClass");
    expect(txtTotalELKDamage.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTotalELKDamage")
    );
    await act(async () => {
      userEvent.type(txtTotalELKDamage, "1");
    });
  });
  test("Custom Test Cases for txtTotalELKDamage", () => {
    // START_USER_CODE-USER_txtTotalELKDamage_TEST
    // END_USER_CODE-USER_txtTotalELKDamage_TEST
  });
  test("txtTotalELKDamagePct(Textbox Widget) Test Cases", async () => {
    const txtTotalELKDamagePct = screen.getByTestId("txtTotalELKDamagePct");
    expect(txtTotalELKDamagePct.tagName).toBe("INPUT");
    expect(txtTotalELKDamagePct.type).toBe("text");
    expect(txtTotalELKDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalELKDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtTotalELKDamagePct", () => {
    // START_USER_CODE-USER_txtTotalELKDamagePct_TEST
    // END_USER_CODE-USER_txtTotalELKDamagePct_TEST
  });
  test("txtTotalKernels(Textbox Widget) Test Cases", async () => {
    const txtTotalKernels = screen.getByTestId("txtTotalKernels");
    expect(txtTotalKernels.tagName).toBe("INPUT");
    expect(txtTotalKernels.type).toBe("text");
    expect(txtTotalKernels.classList).toContain("textboxWidgetClass");
    expect(txtTotalKernels.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTotalKernels")
    );
    await act(async () => {
      userEvent.type(txtTotalKernels, "1");
    });
  });
  test("Custom Test Cases for txtTotalKernels", () => {
    // START_USER_CODE-USER_txtTotalKernels_TEST
    // END_USER_CODE-USER_txtTotalKernels_TEST
  });
  test("txtTotalKernelsAndHulls(Textbox Widget) Test Cases", async () => {
    const txtTotalKernelsAndHulls = screen.getByTestId(
      "txtTotalKernelsAndHulls"
    );
    expect(txtTotalKernelsAndHulls.tagName).toBe("INPUT");
    expect(txtTotalKernelsAndHulls.type).toBe("text");
    expect(txtTotalKernelsAndHulls.classList).toContain("textboxWidgetClass");
    expect(txtTotalKernelsAndHulls.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTotalKernelsAndHulls")
    );
    await act(async () => {
      userEvent.type(txtTotalKernelsAndHulls, "1");
    });
  });
  test("Custom Test Cases for txtTotalKernelsAndHulls", () => {
    // START_USER_CODE-USER_txtTotalKernelsAndHulls_TEST
    // END_USER_CODE-USER_txtTotalKernelsAndHulls_TEST
  });
  test("txtTotalLoadVal(Textbox Widget) Test Cases", async () => {
    const txtTotalLoadVal = screen.getByTestId("txtTotalLoadVal");
    expect(txtTotalLoadVal.tagName).toBe("INPUT");
    expect(txtTotalLoadVal.type).toBe("text");
    expect(txtTotalLoadVal.classList).toContain("textboxWidgetClass");
    expect(txtTotalLoadVal.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTotalLoadVal")
    );
    await act(async () => {
      userEvent.type(txtTotalLoadVal, "1");
    });
  });
  test("Custom Test Cases for txtTotalLoadVal", () => {
    // START_USER_CODE-USER_txtTotalLoadVal_TEST
    // END_USER_CODE-USER_txtTotalLoadVal_TEST
  });
  test("txtTotalLoadValue(Textbox Widget) Test Cases", async () => {
    const txtTotalLoadValue = screen.getByTestId("txtTotalLoadValue");
    expect(txtTotalLoadValue.tagName).toBe("INPUT");
    expect(txtTotalLoadValue.type).toBe("text");
    expect(txtTotalLoadValue.classList).toContain("textboxWidgetClass");
    expect(txtTotalLoadValue.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTotalLoadValue")
    );
    await act(async () => {
      userEvent.type(txtTotalLoadValue, "1");
    });
  });
  test("Custom Test Cases for txtTotalLoadValue", () => {
    // START_USER_CODE-USER_txtTotalLoadValue_TEST
    // END_USER_CODE-USER_txtTotalLoadValue_TEST
  });
  test("txtTotalOK(Textbox Widget) Test Cases", async () => {
    const txtTotalOK = screen.getByTestId("txtTotalOK");
    expect(txtTotalOK.tagName).toBe("INPUT");
    expect(txtTotalOK.type).toBe("text");
    expect(txtTotalOK.classList).toContain("textboxWidgetClass");
    expect(txtTotalOK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTotalOK")
    );
    await act(async () => {
      userEvent.type(txtTotalOK, "1");
    });
  });
  test("Custom Test Cases for txtTotalOK", () => {
    // START_USER_CODE-USER_txtTotalOK_TEST
    // END_USER_CODE-USER_txtTotalOK_TEST
  });
  test("txtTotalOKPct(Textbox Widget) Test Cases", async () => {
    const txtTotalOKPct = screen.getByTestId("txtTotalOKPct");
    expect(txtTotalOKPct.tagName).toBe("INPUT");
    expect(txtTotalOKPct.type).toBe("text");
    expect(txtTotalOKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalOKPct, "1");
    });
  });
  test("Custom Test Cases for txtTotalOKPct", () => {
    // START_USER_CODE-USER_txtTotalOKPct_TEST
    // END_USER_CODE-USER_txtTotalOKPct_TEST
  });
  test("txtTotalPerPound(Textbox Widget) Test Cases", async () => {
    const txtTotalPerPound = screen.getByTestId("txtTotalPerPound");
    expect(txtTotalPerPound.tagName).toBe("INPUT");
    expect(txtTotalPerPound.type).toBe("text");
    expect(txtTotalPerPound.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalPerPound, "1");
    });
  });
  test("Custom Test Cases for txtTotalPerPound", () => {
    // START_USER_CODE-USER_txtTotalPerPound_TEST
    // END_USER_CODE-USER_txtTotalPerPound_TEST
  });
  test("txtTotalPerTon(Textbox Widget) Test Cases", async () => {
    const txtTotalPerTon = screen.getByTestId("txtTotalPerTon");
    expect(txtTotalPerTon.tagName).toBe("INPUT");
    expect(txtTotalPerTon.type).toBe("text");
    expect(txtTotalPerTon.classList).toContain("textboxWidgetClass");
    expect(txtTotalPerTon.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTotalPerTon")
    );
    await act(async () => {
      userEvent.type(txtTotalPerTon, "1");
    });
  });
  test("Custom Test Cases for txtTotalPerTon", () => {
    // START_USER_CODE-USER_txtTotalPerTon_TEST
    // END_USER_CODE-USER_txtTotalPerTon_TEST
  });
  test("txtTotalSLK(Textbox Widget) Test Cases", async () => {
    const txtTotalSLK = screen.getByTestId("txtTotalSLK");
    expect(txtTotalSLK.tagName).toBe("INPUT");
    expect(txtTotalSLK.type).toBe("text");
    expect(txtTotalSLK.classList).toContain("textboxWidgetClass");
    expect(txtTotalSLK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTotalSLK")
    );
    await act(async () => {
      userEvent.type(txtTotalSLK, "1");
    });
  });
  test("Custom Test Cases for txtTotalSLK", () => {
    // START_USER_CODE-USER_txtTotalSLK_TEST
    // END_USER_CODE-USER_txtTotalSLK_TEST
  });
  test("txtTotalSLKPct(Textbox Widget) Test Cases", async () => {
    const txtTotalSLKPct = screen.getByTestId("txtTotalSLKPct");
    expect(txtTotalSLKPct.tagName).toBe("INPUT");
    expect(txtTotalSLKPct.type).toBe("text");
    expect(txtTotalSLKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalSLKPct, "1");
    });
  });
  test("Custom Test Cases for txtTotalSLKPct", () => {
    // START_USER_CODE-USER_txtTotalSLKPct_TEST
    // END_USER_CODE-USER_txtTotalSLKPct_TEST
  });
  test("txtTotalSMK(Textbox Widget) Test Cases", async () => {
    const txtTotalSMK = screen.getByTestId("txtTotalSMK");
    expect(txtTotalSMK.tagName).toBe("INPUT");
    expect(txtTotalSMK.type).toBe("text");
    expect(txtTotalSMK.classList).toContain("textboxWidgetClass");
    expect(txtTotalSMK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTotalSMK")
    );
    await act(async () => {
      userEvent.type(txtTotalSMK, "1");
    });
  });
  test("Custom Test Cases for txtTotalSMK", () => {
    // START_USER_CODE-USER_txtTotalSMK_TEST
    // END_USER_CODE-USER_txtTotalSMK_TEST
  });
  test("txtTotalSMKRScreen(Textbox Widget) Test Cases", async () => {
    const txtTotalSMKRScreen = screen.getByTestId("txtTotalSMKRScreen");
    expect(txtTotalSMKRScreen.tagName).toBe("INPUT");
    expect(txtTotalSMKRScreen.type).toBe("text");
    expect(txtTotalSMKRScreen.classList).toContain("textboxWidgetClass");
    expect(txtTotalSMKRScreen.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTotalSMKRScreen")
    );
    await act(async () => {
      userEvent.type(txtTotalSMKRScreen, "1");
    });
  });
  test("Custom Test Cases for txtTotalSMKRScreen", () => {
    // START_USER_CODE-USER_txtTotalSMKRScreen_TEST
    // END_USER_CODE-USER_txtTotalSMKRScreen_TEST
  });
  test("txtTotalSMKRScreenPct(Textbox Widget) Test Cases", async () => {
    const txtTotalSMKRScreenPct = screen.getByTestId("txtTotalSMKRScreenPct");
    expect(txtTotalSMKRScreenPct.tagName).toBe("INPUT");
    expect(txtTotalSMKRScreenPct.type).toBe("text");
    expect(txtTotalSMKRScreenPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalSMKRScreenPct, "1");
    });
  });
  test("Custom Test Cases for txtTotalSMKRScreenPct", () => {
    // START_USER_CODE-USER_txtTotalSMKRScreenPct_TEST
    // END_USER_CODE-USER_txtTotalSMKRScreenPct_TEST
  });
  test("txtTradeInspectSC95(Textbox Widget) Test Cases", async () => {
    const txtTradeInspectSC95 = screen.getByTestId("txtTradeInspectSC95");
    expect(txtTradeInspectSC95.tagName).toBe("INPUT");
    expect(txtTradeInspectSC95.type).toBe("text");
    expect(txtTradeInspectSC95.classList).toContain("textboxWidgetClass");
    expect(txtTradeInspectSC95.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTradeInspectSC95")
    );
    await act(async () => {
      userEvent.type(txtTradeInspectSC95, "1");
    });
  });
  test("Custom Test Cases for txtTradeInspectSC95", () => {
    // START_USER_CODE-USER_txtTradeInspectSC95_TEST
    // END_USER_CODE-USER_txtTradeInspectSC95_TEST
  });
  test("txtTradeSettle1007(Textbox Widget) Test Cases", async () => {
    const txtTradeSettle1007 = screen.getByTestId("txtTradeSettle1007");
    expect(txtTradeSettle1007.tagName).toBe("INPUT");
    expect(txtTradeSettle1007.type).toBe("text");
    expect(txtTradeSettle1007.classList).toContain("textboxWidgetClass");
    expect(txtTradeSettle1007.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtTradeSettle1007")
    );
    await act(async () => {
      userEvent.type(txtTradeSettle1007, "123");
    });
    expect(txtTradeSettle1007.getAttribute("value")).toBe("");
    expect(txtTradeSettle1007.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTradeSettle1007", () => {
    // START_USER_CODE-USER_txtTradeSettle1007_TEST
    // END_USER_CODE-USER_txtTradeSettle1007_TEST
  });
  test("txtValPerPoundExclSLK(Textbox Widget) Test Cases", async () => {
    const txtValPerPoundExclSLK = screen.getByTestId("txtValPerPoundExclSLK");
    expect(txtValPerPoundExclSLK.tagName).toBe("INPUT");
    expect(txtValPerPoundExclSLK.type).toBe("text");
    expect(txtValPerPoundExclSLK.classList).toContain("textboxWidgetClass");
    expect(txtValPerPoundExclSLK.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtValPerPoundExclSLK")
    );
    await act(async () => {
      userEvent.type(txtValPerPoundExclSLK, "1");
    });
  });
  test("Custom Test Cases for txtValPerPoundExclSLK", () => {
    // START_USER_CODE-USER_txtValPerPoundExclSLK_TEST
    // END_USER_CODE-USER_txtValPerPoundExclSLK_TEST
  });
  test("txtVehicleWt(Textbox Widget) Test Cases", async () => {
    const txtVehicleWt = screen.getByTestId("txtVehicleWt");
    expect(txtVehicleWt.tagName).toBe("INPUT");
    expect(txtVehicleWt.type).toBe("text");
    expect(txtVehicleWt.classList).toContain("textboxWidgetClass");
    expect(txtVehicleWt.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtVehicleWt")
    );
    await act(async () => {
      userEvent.type(txtVehicleWt, "1");
    });
  });
  test("Custom Test Cases for txtVehicleWt", () => {
    // START_USER_CODE-USER_txtVehicleWt_TEST
    // END_USER_CODE-USER_txtVehicleWt_TEST
  });
  test("txtVendor0(Textbox Widget) Test Cases", async () => {
    const txtVendor0 = screen.getByTestId("txtVendor0");
    expect(txtVendor0.tagName).toBe("INPUT");
    expect(txtVendor0.type).toBe("text");
    expect(txtVendor0.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor0, "1");
    });
  });
  test("Custom Test Cases for txtVendor0", () => {
    // START_USER_CODE-USER_txtVendor0_TEST
    // END_USER_CODE-USER_txtVendor0_TEST
  });
  test("txtVendor1(Textbox Widget) Test Cases", async () => {
    const txtVendor1 = screen.getByTestId("txtVendor1");
    expect(txtVendor1.tagName).toBe("INPUT");
    expect(txtVendor1.type).toBe("text");
    expect(txtVendor1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor1, "1");
    });
  });
  test("Custom Test Cases for txtVendor1", () => {
    // START_USER_CODE-USER_txtVendor1_TEST
    // END_USER_CODE-USER_txtVendor1_TEST
  });
  test("txtVendor10(Textbox Widget) Test Cases", async () => {
    const txtVendor10 = screen.getByTestId("txtVendor10");
    expect(txtVendor10.tagName).toBe("INPUT");
    expect(txtVendor10.type).toBe("text");
    expect(txtVendor10.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor10, "1");
    });
  });
  test("Custom Test Cases for txtVendor10", () => {
    // START_USER_CODE-USER_txtVendor10_TEST
    // END_USER_CODE-USER_txtVendor10_TEST
  });
  test("txtVendor11(Textbox Widget) Test Cases", async () => {
    const txtVendor11 = screen.getByTestId("txtVendor11");
    expect(txtVendor11.tagName).toBe("INPUT");
    expect(txtVendor11.type).toBe("text");
    expect(txtVendor11.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor11, "1");
    });
  });
  test("Custom Test Cases for txtVendor11", () => {
    // START_USER_CODE-USER_txtVendor11_TEST
    // END_USER_CODE-USER_txtVendor11_TEST
  });
  test("txtVendor12(Textbox Widget) Test Cases", async () => {
    const txtVendor12 = screen.getByTestId("txtVendor12");
    expect(txtVendor12.tagName).toBe("INPUT");
    expect(txtVendor12.type).toBe("text");
    expect(txtVendor12.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor12, "1");
    });
  });
  test("Custom Test Cases for txtVendor12", () => {
    // START_USER_CODE-USER_txtVendor12_TEST
    // END_USER_CODE-USER_txtVendor12_TEST
  });
  test("txtVendor13(Textbox Widget) Test Cases", async () => {
    const txtVendor13 = screen.getByTestId("txtVendor13");
    expect(txtVendor13.tagName).toBe("INPUT");
    expect(txtVendor13.type).toBe("text");
    expect(txtVendor13.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor13, "1");
    });
  });
  test("Custom Test Cases for txtVendor13", () => {
    // START_USER_CODE-USER_txtVendor13_TEST
    // END_USER_CODE-USER_txtVendor13_TEST
  });
  test("txtVendor14(Textbox Widget) Test Cases", async () => {
    const txtVendor14 = screen.getByTestId("txtVendor14");
    expect(txtVendor14.tagName).toBe("INPUT");
    expect(txtVendor14.type).toBe("text");
    expect(txtVendor14.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor14, "1");
    });
  });
  test("Custom Test Cases for txtVendor14", () => {
    // START_USER_CODE-USER_txtVendor14_TEST
    // END_USER_CODE-USER_txtVendor14_TEST
  });
  test("txtVendor15(Textbox Widget) Test Cases", async () => {
    const txtVendor15 = screen.getByTestId("txtVendor15");
    expect(txtVendor15.tagName).toBe("INPUT");
    expect(txtVendor15.type).toBe("text");
    expect(txtVendor15.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor15, "1");
    });
  });
  test("Custom Test Cases for txtVendor15", () => {
    // START_USER_CODE-USER_txtVendor15_TEST
    // END_USER_CODE-USER_txtVendor15_TEST
  });
  test("txtVendor16(Textbox Widget) Test Cases", async () => {
    const txtVendor16 = screen.getByTestId("txtVendor16");
    expect(txtVendor16.tagName).toBe("INPUT");
    expect(txtVendor16.type).toBe("text");
    expect(txtVendor16.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor16, "1");
    });
  });
  test("Custom Test Cases for txtVendor16", () => {
    // START_USER_CODE-USER_txtVendor16_TEST
    // END_USER_CODE-USER_txtVendor16_TEST
  });
  test("txtVendor17(Textbox Widget) Test Cases", async () => {
    const txtVendor17 = screen.getByTestId("txtVendor17");
    expect(txtVendor17.tagName).toBe("INPUT");
    expect(txtVendor17.type).toBe("text");
    expect(txtVendor17.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor17, "1");
    });
  });
  test("Custom Test Cases for txtVendor17", () => {
    // START_USER_CODE-USER_txtVendor17_TEST
    // END_USER_CODE-USER_txtVendor17_TEST
  });
  test("txtVendor18(Textbox Widget) Test Cases", async () => {
    const txtVendor18 = screen.getByTestId("txtVendor18");
    expect(txtVendor18.tagName).toBe("INPUT");
    expect(txtVendor18.type).toBe("text");
    expect(txtVendor18.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor18, "1");
    });
  });
  test("Custom Test Cases for txtVendor18", () => {
    // START_USER_CODE-USER_txtVendor18_TEST
    // END_USER_CODE-USER_txtVendor18_TEST
  });
  test("txtVendor19(Textbox Widget) Test Cases", async () => {
    const txtVendor19 = screen.getByTestId("txtVendor19");
    expect(txtVendor19.tagName).toBe("INPUT");
    expect(txtVendor19.type).toBe("text");
    expect(txtVendor19.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor19, "1");
    });
  });
  test("Custom Test Cases for txtVendor19", () => {
    // START_USER_CODE-USER_txtVendor19_TEST
    // END_USER_CODE-USER_txtVendor19_TEST
  });
  test("txtVendor2(Textbox Widget) Test Cases", async () => {
    const txtVendor2 = screen.getByTestId("txtVendor2");
    expect(txtVendor2.tagName).toBe("INPUT");
    expect(txtVendor2.type).toBe("text");
    expect(txtVendor2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor2, "1");
    });
  });
  test("Custom Test Cases for txtVendor2", () => {
    // START_USER_CODE-USER_txtVendor2_TEST
    // END_USER_CODE-USER_txtVendor2_TEST
  });
  test("txtVendor3(Textbox Widget) Test Cases", async () => {
    const txtVendor3 = screen.getByTestId("txtVendor3");
    expect(txtVendor3.tagName).toBe("INPUT");
    expect(txtVendor3.type).toBe("text");
    expect(txtVendor3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor3, "1");
    });
  });
  test("Custom Test Cases for txtVendor3", () => {
    // START_USER_CODE-USER_txtVendor3_TEST
    // END_USER_CODE-USER_txtVendor3_TEST
  });
  test("txtVendor4(Textbox Widget) Test Cases", async () => {
    const txtVendor4 = screen.getByTestId("txtVendor4");
    expect(txtVendor4.tagName).toBe("INPUT");
    expect(txtVendor4.type).toBe("text");
    expect(txtVendor4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor4, "1");
    });
  });
  test("Custom Test Cases for txtVendor4", () => {
    // START_USER_CODE-USER_txtVendor4_TEST
    // END_USER_CODE-USER_txtVendor4_TEST
  });
  test("txtVendor5(Textbox Widget) Test Cases", async () => {
    const txtVendor5 = screen.getByTestId("txtVendor5");
    expect(txtVendor5.tagName).toBe("INPUT");
    expect(txtVendor5.type).toBe("text");
    expect(txtVendor5.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor5, "1");
    });
  });
  test("Custom Test Cases for txtVendor5", () => {
    // START_USER_CODE-USER_txtVendor5_TEST
    // END_USER_CODE-USER_txtVendor5_TEST
  });
  test("txtVendor6(Textbox Widget) Test Cases", async () => {
    const txtVendor6 = screen.getByTestId("txtVendor6");
    expect(txtVendor6.tagName).toBe("INPUT");
    expect(txtVendor6.type).toBe("text");
    expect(txtVendor6.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor6, "1");
    });
  });
  test("Custom Test Cases for txtVendor6", () => {
    // START_USER_CODE-USER_txtVendor6_TEST
    // END_USER_CODE-USER_txtVendor6_TEST
  });
  test("txtVendor7(Textbox Widget) Test Cases", async () => {
    const txtVendor7 = screen.getByTestId("txtVendor7");
    expect(txtVendor7.tagName).toBe("INPUT");
    expect(txtVendor7.type).toBe("text");
    expect(txtVendor7.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor7, "1");
    });
  });
  test("Custom Test Cases for txtVendor7", () => {
    // START_USER_CODE-USER_txtVendor7_TEST
    // END_USER_CODE-USER_txtVendor7_TEST
  });
  test("txtVendor8(Textbox Widget) Test Cases", async () => {
    const txtVendor8 = screen.getByTestId("txtVendor8");
    expect(txtVendor8.tagName).toBe("INPUT");
    expect(txtVendor8.type).toBe("text");
    expect(txtVendor8.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor8, "1");
    });
  });
  test("Custom Test Cases for txtVendor8", () => {
    // START_USER_CODE-USER_txtVendor8_TEST
    // END_USER_CODE-USER_txtVendor8_TEST
  });
  test("txtVendor9(Textbox Widget) Test Cases", async () => {
    const txtVendor9 = screen.getByTestId("txtVendor9");
    expect(txtVendor9.tagName).toBe("INPUT");
    expect(txtVendor9.type).toBe("text");
    expect(txtVendor9.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor9, "1");
    });
  });
  test("Custom Test Cases for txtVendor9", () => {
    // START_USER_CODE-USER_txtVendor9_TEST
    // END_USER_CODE-USER_txtVendor9_TEST
  });
  test("txtVicamReading(Textbox Widget) Test Cases", async () => {
    const txtVicamReading = screen.getByTestId("txtVicamReading");
    expect(txtVicamReading.tagName).toBe("INPUT");
    expect(txtVicamReading.type).toBe("text");
    expect(txtVicamReading.classList).toContain("textboxWidgetClass");
    expect(txtVicamReading.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtVicamReading")
    );
    await act(async () => {
      userEvent.type(txtVicamReading, "1");
    });
  });
  test("Custom Test Cases for txtVicamReading", () => {
    // START_USER_CODE-USER_txtVicamReading_TEST
    // END_USER_CODE-USER_txtVicamReading_TEST
  });
  test("txtWeightLessFM(Textbox Widget) Test Cases", async () => {
    const txtWeightLessFM = screen.getByTestId("txtWeightLessFM");
    expect(txtWeightLessFM.tagName).toBe("INPUT");
    expect(txtWeightLessFM.type).toBe("text");
    expect(txtWeightLessFM.classList).toContain("textboxWidgetClass");
    expect(txtWeightLessFM.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtWeightLessFM")
    );
    await act(async () => {
      userEvent.type(txtWeightLessFM, "1");
    });
  });
  test("Custom Test Cases for txtWeightLessFM", () => {
    // START_USER_CODE-USER_txtWeightLessFM_TEST
    // END_USER_CODE-USER_txtWeightLessFM_TEST
  });
  test("txtWormDamagePct(Textbox Widget) Test Cases", async () => {
    const txtWormDamagePct = screen.getByTestId("txtWormDamagePct");
    expect(txtWormDamagePct.tagName).toBe("INPUT");
    expect(txtWormDamagePct.type).toBe("text");
    expect(txtWormDamagePct.classList).toContain("textboxWidgetClass");
    expect(txtWormDamagePct.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtWormDamagePct")
    );
    await act(async () => {
      userEvent.type(txtWormDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtWormDamagePct", () => {
    // START_USER_CODE-USER_txtWormDamagePct_TEST
    // END_USER_CODE-USER_txtWormDamagePct_TEST
  });
  test("txtWtIncludingVehicle(Textbox Widget) Test Cases", async () => {
    const txtWtIncludingVehicle = screen.getByTestId("txtWtIncludingVehicle");
    expect(txtWtIncludingVehicle.tagName).toBe("INPUT");
    expect(txtWtIncludingVehicle.type).toBe("text");
    expect(txtWtIncludingVehicle.classList).toContain("textboxWidgetClass");
    expect(txtWtIncludingVehicle.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:DelvSettleProfile_txtWtIncludingVehicle")
    );
    await act(async () => {
      userEvent.type(txtWtIncludingVehicle, "1");
    });
  });
  test("Custom Test Cases for txtWtIncludingVehicle", () => {
    // START_USER_CODE-USER_txtWtIncludingVehicle_TEST
    // END_USER_CODE-USER_txtWtIncludingVehicle_TEST
  });
});
