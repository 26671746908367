/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  hide,
  show,
  setData,
  getData,
  setValue,
  getValue,
  disable,
  enable,
  goTo
} from "../../shared/WindowImports";

import "./FarmerStockPriceSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import {FormatNumber} from "../../Common/CommonFunctions"
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// import {SystemMaintenance}



// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FarmerStockPriceSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FarmerStockPriceSetup",
    windowName: "FarmerStockPriceSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FarmerStockPriceSetup",
    // START_USER_CODE-USER_FarmerStockPriceSetup_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Farmer Stock Price Setup",
      scrCode: "PN0270A",
    },
    // END_USER_CODE-USER_FarmerStockPriceSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxFrmrStockPrice",
      Label: "Add",
      CharWidth: "10",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmndCntnrFrmrStock",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxFrmrStockPrice",
      Label: "Delete",
      CharWidth: "15",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxFrmrStockPrice",
      Label: "Edit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    ddArea: {
      name: "ddArea",
      type: "DropDownFieldWidget",
      parent: "grpbxFrmrStockPrice",
      Label: "Area:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddArea_PROPERTIES

      // END_USER_CODE-USER_ddArea_PROPERTIES
    },
    ddPnutTyp: {
      name: "ddPnutTyp",
      type: "DropDownFieldWidget",
      parent: "grpbxFrmrStockPrice",
      Label: "Peanut Type:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPnutTyp_PROPERTIES

      // END_USER_CODE-USER_ddPnutTyp_PROPERTIES
    },
    lblFrmrStckPric: {
      name: "lblFrmrStckPric",
      type: "LabelWidget",
      parent: "grpbxFrmrStockPrice",
      Label: "Farmer Stock Price:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFrmrStckPric_PROPERTIES

      // END_USER_CODE-USER_lblFrmrStckPric_PROPERTIES
    },
    lstbxFrmrStock: {
      name: "lstbxFrmrStock",
      type: "ListBoxFieldWidget",
      parent: "grpbxFrmrStockPrice",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstbxFrmrStock_PROPERTIES

      // END_USER_CODE-USER_lstbxFrmrStock_PROPERTIES
    },
    grpbxFrmrStockPrice: {
      name: "grpbxFrmrStockPrice",
      type: "GroupBoxWidget",
      parent: "FarmerStockPriceSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxFrmrStockPrice_PROPERTIES

      // END_USER_CODE-USER_grpbxFrmrStockPrice_PROPERTIES
    },
    cmndCntnrFrmrStock: {
      name: "cmndCntnrFrmrStock",
      type: "CommandContainerWidget",
      parent: "FarmerStockPriceSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmndCntnrFrmrStock_PROPERTIES

      // END_USER_CODE-USER_cmndCntnrFrmrStock_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},
        "SystemMaintenanceMasterManagement#FarmerStockPriceProfile": {},
      },
      REVERSE: {
        "ContractManagement#MainMenu": {},
        "SystemMaintenanceMasterManagement#FarmerStockPriceProfile": {},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAdd:{
    //   DEFAULT: ["SystemMaintenanceMasterManagement#FarmerStockPriceProfile#DEFAULT#true#Click"]
    // },
    // btnEdit:{
    //   DEFAULT: ["SystemMaintenanceMasterManagement#FarmerStockPriceProfile#DEFAULT#true#Click"]
    // },
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    FarmerStockPriceUpdate();
  }, [getData(thisObj, 'closingOfFarmerProfile')])

  const FarmerStockPriceUpdate = async () => {
    let temp = getData(thisObj, 'closingOfFarmerProfile')
    
    if (temp != undefined && temp != "" && temp != null) {
      let area_id=temp.area_id;
      let pnut_type_id=temp.pnut_type_id;
      await bFillFarmerStockPriceList(area_id,pnut_type_id,null,null)
      EnableDisableControls()
    }
    setData(thisObj, 'closingOfFarmerProfile', '')
  }

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  const [loading, setLoading] = useState(false);
  // START_USER_CODE-USER_METHODS
  let FormLoad=async()=>{
    setLoading(true)
    hide(thisObj,'btnAdd')
    hide(thisObj,'btnEdit')
    hide(thisObj,'btnDelete')

    let permisson=await bfillAccess('PN0270',null,'U','')
    if(permisson=='Y'){
      show(thisObj,'btnAdd')
      show(thisObj,'btnEdit')
    }

    permisson=await bfillAccess('PN0270',null,'D','')
    if(permisson=='Y'){
      show(thisObj,'btnDelete')
    }

    await bFillAreaList()
    await bFillTypeList()
    let area_id=getValue(thisObj,'ddArea')
    let pnut_type_id=getValue(thisObj,'ddPnutTyp')
    await bFillFarmerStockPriceList(area_id,pnut_type_id,null,null);

    EnableDisableControls()
    setLoading(false)
  }

  let bfillAccess = async (funcId, funcSubId, access, buyPtId) => {
    let permission = await ContractManagementService.RetrieveAccessPermissionDetails(funcId, funcSubId, access, buyPtId)
    return permission[0].permission
  }

  //Binding the Area Dropdown
  let bFillAreaList=async()=>{
    let js=[];
    let obj={};
    js.push({ key: '', description: '>>> Select <<<' });
    let response =await SettlementService.RetrieveAreaControlDetails()
    let data=response;

    if(data.length>0 && data!=undefined && data!=null){
      for(let i=0;i<data.length;i++){
        let dataExist=js.find(elem=>elem.key==data[i].areaId)
          if(dataExist==undefined){
            obj={
              key:data[i].areaId,
              description:data[i].areaId + ' - '+ data[i].areaName 
            }
            js.push(obj);
            obj={};
          }
        }
      }
      setValue(thisObj, 'ddArea', js.at(0).key);
      thisObj.setState(current => {
        return {
          ...current,
          ddArea: {
            ...state["ddArea"],
            valueList: js
          }
        }
      })
    }

  let bFillTypeList=async()=>{
    let js=[];
    let obj={};
    js.push({ key: '', description: '>>> Select <<<' });
    let response =await ContractManagementService.RetrievePeanutTypeControls(null);
    let data=response

    if(data.length>0 && data!=undefined && data!=null){
      for(let i=0;i<data.length;i++){
        let dataExist=js.find(elem=>elem.key==data[i].areaId)
          if(dataExist==undefined){
            obj={
              key:data[i].pnutTypeId,
              description:data[i].pnutTypeId + ' - '+ data[i].pnutTypeName 
            }
            js.push(obj);
            obj={};
          }
        }
      }
      else{
        return;
      }
      setValue(thisObj, 'ddPnutTyp', js.at(0).key);
      thisObj.setState(current => {
        return {
          ...current,
          ddPnutTyp: {
            ...state["ddPnutTyp"],
            valueList: js
          }
        }
      })
  }

  let bFillFarmerStockPriceList=async(area_id,pnut_type_id,effect_date_time,cdate)=>{
    if(area_id==null || area_id==undefined || area_id=='') return
    if(pnut_type_id==null || pnut_type_id==undefined || pnut_type_id=='') return

    let response=await SystemMaintenanceMasterManagementService.RetrieveFarmerStockPriceControlDetails(area_id,pnut_type_id,effect_date_time,cdate)
    let data=response
    let js=[];
    let obj={}
    if(data.length>0 && data!=undefined && data!=null){
      for(let i=0;i<data.length;i++){
        let dataExist=js.find(elem=>elem.key==data[i].areaId)
        let amPm=data[i].effect_date_time.split(':')[2].slice(3,5)
        let date=data[i].effect_date_time.slice(0,-6)
          if(dataExist==undefined){
            obj={
              key:data[i].effect_date_time,
              description:date+" "+amPm + ' -  $' + FormatNumber(data[i].market_price,2)
            }
            js.push(obj);
            obj={};
          }
        }
      }
      if(js.length>0){
        setValue(thisObj, 'lstbxFrmrStock', [js.at(0).key]); 
        }
      setData(thisObj,'listLength',js.length)
      thisObj.setState(current => {
        return {
          ...current,
          lstbxFrmrStock: {
            ...state["lstbxFrmrStock"],
            valueList: js
          }
        }
      })
  } 

  let EnableDisableControls=async()=>{
    let area_id=getValue(thisObj,'ddArea')
    let pnut_type_id=getValue(thisObj,'ddPnutTyp')

    if(area_id==null || area_id==undefined || area_id==''){ 
      disable(thisObj,'btnAdd')
      disable(thisObj,'btnEdit')
      disable(thisObj,'btnDelete')
      return
    }
    if(pnut_type_id==null || pnut_type_id==undefined || pnut_type_id=='') {
      disable(thisObj,'btnAdd')
      disable(thisObj,'btnEdit')
      disable(thisObj,'btnDelete')
      return
    }
    enable(thisObj,'btnAdd')
    let listLength=getData(thisObj,'listLength')
    if(listLength<=0){
      disable(thisObj,'btnEdit')
      disable(thisObj,'btnDelete')
    }
    else{
      enable(thisObj,'btnEdit')
      enable(thisObj,'btnDelete')
    }
  }

  let onddAreaChange=async()=>{
    setLoading(true)
    let area_id=getValue(thisObj,'ddArea')
    if(area_id==''){
      thisObj.setState(current => {
        return {
          ...current,
          lstbxFrmrStock: {
            ...state["lstbxFrmrStock"],
            valueList: []
          }
        }
      })
      await EnableDisableControls()
      setLoading(false)
      return;
    }

    let pnut_type_id=getValue(thisObj,'ddPnutTyp')
    if(pnut_type_id==''){
      thisObj.setState(current => {
        return {
          ...current,
          lstbxFrmrStock: {
            ...state["lstbxFrmrStock"],
            valueList: []
          }
        }
      })
      await EnableDisableControls()
      setLoading(false)
      return;
    }
    await bFillFarmerStockPriceList(area_id,pnut_type_id,null,null);
    EnableDisableControls()
    setLoading(false)
  }
  thisObj.onddAreaChange=onddAreaChange

  let onddPnutTypChange=async()=>{
    setLoading(true)
    let area_id=getValue(thisObj,'ddArea')
    if(area_id==''){
      thisObj.setState(current => {
        return {
          ...current,
          lstbxFrmrStock: {
            ...state["lstbxFrmrStock"],
            valueList: []
          }
        }
      })
      await EnableDisableControls()
      setLoading(false)
      return;
    }

    let pnut_type_id=getValue(thisObj,'ddPnutTyp')
    if(pnut_type_id==''){
      thisObj.setState(current => {
        return {
          ...current,
          lstbxFrmrStock: {
            ...state["lstbxFrmrStock"],
            valueList: []
          }
        }
      })
      await EnableDisableControls()
      setLoading(false)
      return;
    }

    await bFillFarmerStockPriceList(area_id,pnut_type_id,null,null);
    EnableDisableControls()
    setLoading(false)
  }
  thisObj.onddPnutTypChange=onddPnutTypChange

  let onbtnAddClick=()=>{
    try{
    let areaList=thisObj.state.ddArea.valueList
    let areaId=getValue(thisObj,'ddArea')
    let areaName=''
    if (areaId !== undefined && areaId !== null && areaId !== '') {
      areaName = areaList.find(elem => elem.key == areaId).description
    }

    let peanutList=thisObj.state.ddPnutTyp.valueList
    let PeanutName=''
    let pnut_type_id=getValue(thisObj,'ddPnutTyp')
    if (pnut_type_id !== undefined && pnut_type_id !== null && pnut_type_id !== '') {
      PeanutName = peanutList.find(elem => elem.key == pnut_type_id).description
    }

    let frmFarmerStockPriceProfile={};
    frmFarmerStockPriceProfile.cmdOk="ADD"
    frmFarmerStockPriceProfile.Area=areaName
    frmFarmerStockPriceProfile.Peanut_type=PeanutName
    setData(thisObj,'StockProfileData',frmFarmerStockPriceProfile)
    goTo(thisObj,"SystemMaintenanceMasterManagement#FarmerStockPriceProfile#DEFAULT#true#Click")

    //Integration from child to parent is pending
  }
  catch(err){
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred in Add Button"
      );
    }
    return false;
  }
  }
  
  thisObj.onbtnAddClick=onbtnAddClick

  let onbtnEditClick=async ()=>{
    try{
    let areaId=getValue(thisObj,'ddArea')
    let pnut_type_id=getValue(thisObj,'ddPnutTyp')
    let lstFarmerStockPrice=getValue(thisObj,'lstbxFrmrStock')[0]
    if(lstFarmerStockPrice==undefined){
      showMessage(thisObj,'Please selete a Farmer Stock Price to Edit')
      return;
    }
    let areaList=thisObj.state.ddArea.valueList
    // let areaId=getValue(thisObj,'ddArea')
    let areaName=''
    if (areaId !== undefined && areaId !== null && areaId !== '') {
      areaName = areaList.find(elem => elem.key == areaId).description
    }

    let peanutList=thisObj.state.ddPnutTyp.valueList
    let PeanutName=''
    // let pnut_type_id=getValue(thisObj,'ddPnutTyp')
    if (pnut_type_id !== undefined && pnut_type_id !== null && pnut_type_id !== '') {
      PeanutName = peanutList.find(elem => elem.key == pnut_type_id).description
    }
    let response=await SystemMaintenanceMasterManagementService.RetrieveFarmerStockPriceControlDetails(areaId,pnut_type_id,lstFarmerStockPrice,null)
    let data=response;
    let frmFarmerStockPriceProfile={};
    frmFarmerStockPriceProfile.cmdOk='Update';
    frmFarmerStockPriceProfile.Area=areaName
    frmFarmerStockPriceProfile.Peanut_type=PeanutName
    frmFarmerStockPriceProfile.txtEffectiveDateTime=data[0].effect_date_time
    frmFarmerStockPriceProfile.txtFarmerStockPrice=data[0].market_price
    frmFarmerStockPriceProfile.lblAddedBy=data[0].add_user + " " + data[0].add_date_time
    frmFarmerStockPriceProfile.lblChangedBy=data[0].chg_user + " " + data[0].chg_date_time
    setData(thisObj,'StockProfileData',frmFarmerStockPriceProfile)
    goTo(thisObj,"SystemMaintenanceMasterManagement#FarmerStockPriceProfile#DEFAULT#true#Click")

    }
    catch(err){
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in Edit button"
        );
      }
      return false;
    }
    }
  thisObj.onbtnEditClick=onbtnEditClick

  let onbtnDeleteClick = async()=>{
    try{
    let effect_date_time=getValue(thisObj,'lstbxFrmrStock')[0]
    if(effect_date_time==undefined){
      showMessage(thisObj,'Please selete a Farmer Stock Price to Delete')
      return;
    }
    let areaId=getValue(thisObj,'ddArea')
    let pnut_type_id=getValue(thisObj,'ddPnutTyp')

    let lstFarmerStockPrice=thisObj.state.lstbxFrmrStock.valueList
    let farmerDespription=''
    if (effect_date_time !== undefined && effect_date_time !== null && effect_date_time !== '') {
      farmerDespription = lstFarmerStockPrice.find(elem => elem.key == effect_date_time).description
    }

    let res=confirm(`Are you sure you wish to delete this Price Control: ${farmerDespription}?`)
    if(res==true){
      let response=await SystemMaintenanceMasterManagementService.RemoveFarmerStockPriceControlDetails(areaId,pnut_type_id,effect_date_time.replaceAll('/','-'));
      if (![200, 400, 404].includes(response.status)) {
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
      }
      else if(response.status==200){
        showMessage(thisObj, response.message,true)
      }
      else{
        showMessage(thisObj, response.message)
      }
      bFillFarmerStockPriceList(areaId,pnut_type_id,null)
      EnableDisableControls();
    }
    else{
      return
    }
  }
  catch(err){
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred in Delete button"
      );
    }
    return false;
  }
  }
  thisObj.onbtnDeleteClick=onbtnDeleteClick

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FarmerStockPriceSetup*/}

              {/* END_USER_CODE-USER_BEFORE_FarmerStockPriceSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFrmrStockPrice*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFrmrStockPrice*/}

              <GroupBoxWidget
                conf={state.grpbxFrmrStockPrice}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddArea*/}

                {/* END_USER_CODE-USER_BEFORE_ddArea*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddArea}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddArea*/}

                {/* END_USER_CODE-USER_AFTER_ddArea*/}
                {/* START_USER_CODE-USER_BEFORE_ddPnutTyp*/}

                {/* END_USER_CODE-USER_BEFORE_ddPnutTyp*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPnutTyp}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPnutTyp*/}

                {/* END_USER_CODE-USER_AFTER_ddPnutTyp*/}
                {/* START_USER_CODE-USER_BEFORE_lblFrmrStckPric*/}

                {/* END_USER_CODE-USER_BEFORE_lblFrmrStckPric*/}

                <LabelWidget
                  values={values}
                  conf={state.lblFrmrStckPric}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblFrmrStckPric*/}

                {/* END_USER_CODE-USER_AFTER_lblFrmrStckPric*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_lstbxFrmrStock*/}

                {/* END_USER_CODE-USER_BEFORE_lstbxFrmrStock*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstbxFrmrStock}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstbxFrmrStock*/}

                {/* END_USER_CODE-USER_AFTER_lstbxFrmrStock*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFrmrStockPrice*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFrmrStockPrice*/}
              {/* START_USER_CODE-USER_BEFORE_cmndCntnrFrmrStock*/}

              {/* END_USER_CODE-USER_BEFORE_cmndCntnrFrmrStock*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmndCntnrFrmrStock}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmndCntnrFrmrStock*/}

              {/* END_USER_CODE-USER_AFTER_cmndCntnrFrmrStock*/}

              {/* START_USER_CODE-USER_AFTER_FarmerStockPriceSetup*/}

              {/* END_USER_CODE-USER_AFTER_FarmerStockPriceSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_FarmerStockPriceSetup
);
