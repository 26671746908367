/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  hide,
  enable,
  disable, 
  goTo
} from "../../shared/WindowImports";

import "./ReportUserSignatureTemplate.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { GetReportLogoImage, GetReportLogoImageDimension } from "./../../Common/Constants";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_ReportUserSignatureTemplate(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const imgUrl = GetReportLogoImage(window.location.origin)
  const imgDimension = GetReportLogoImageDimension()
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ReportUserSignatureTemplate",
    windowName: "ReportUserSignatureTemplate",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.ReportUserSignatureTemplate",
    // START_USER_CODE-USER_ReportUserSignatureTemplate_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "User Signature Template",
      scrCode: "PN0205B",
    },
    // END_USER_CODE-USER_ReportUserSignatureTemplate_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btn2: {
      name: "btn2",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn2_PROPERTIES

      // END_USER_CODE-USER_btn2_PROPERTIES
    },
    btnCLose: {
      name: "btnCLose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",
      Enable: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCLose_PROPERTIES

      // END_USER_CODE-USER_btnCLose_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",
      Enable: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnPrintSetup: {
      name: "btnPrintSetup",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print Setup",
      CharWidth: "24",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintSetup_PROPERTIES

      // END_USER_CODE-USER_btnPrintSetup_PROPERTIES
    },
    btnSaveAsPDF: {
      name: "btnSaveAsPDF",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Save as PDF",
      CharWidth: "25",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSaveAsPDF_PROPERTIES

      // END_USER_CODE-USER_btnSaveAsPDF_PROPERTIES
    },
    grpbxUserSignatureTemplate: {
      name: "grpbxUserSignatureTemplate",
      type: "GroupBoxWidget",
      parent: "ReportUserSignatureTemplate",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxUserSignatureTemplate_PROPERTIES

      // END_USER_CODE-USER_grpbxUserSignatureTemplate_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "ReportUserSignatureTemplate",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const getBlobURL = (code, type) => {
    try {
      return URL.createObjectURL(new Blob([code], { type }))
    }
    catch(err) {
      errorHandler(err);
    }
  }

  async function FormLoad(){
    try{
      hide(thisObj, 'btnSaveAsPDF') 
      hide(thisObj, 'btnPrintSetup')  
      hide(thisObj, 'btn1')
      hide(thisObj, 'btn2')
      var renderCode = await GetHtmlCode()
      state.renderCode = renderCode
      state.renderUrl = getBlobURL(renderCode, 'text/html')
      document.getElementById('docFrame').setAttribute("src", state.renderUrl)
      
      enable(thisObj, "btnPrint")
      enable(thisObj, "btnCLose")
    }
    catch(err){
      errorHandler(err)
    }
  }

  async function GetHtmlCode(){
    try{
      return `<html>
      <style>
      body:before{
          content: '${process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'}';
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;  
          color: #0d745e;
          font-size: 250px;
          font-weight: 500px;
          display: grid;
          justify-content: center;
          align-content: center;
          opacity: 0.35;
      }
      @media print {
          @page :footer { display: none }    
          @page :header { display: none }    
          @page { margin-top: 0; margin-bottom: 0; }    
          html,
          body { height: 100%; margin: 0 !important; padding: 0 !important; }
      } 
      </style>
      <p style="page-break-after: always">
        <table style="align-items:center;width: 100%;font-size:70%;padding-top:1%;font-family:'Arial'">
          <tr style="padding-right: 1%;padding-top:8%;">
            <td style="padding-left:0.01%; padding-right: 1%;padding-top:8%;">    
              <img src=${imgUrl} ${imgDimension}/>
            </td>
            <td style="padding-left:25%;">
              <span style="text-align-last: left;color:black;font-size:130%;"> <b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
              <span style="text-align: right;color:black;font-size:80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400</b> <br/></span>
              <span style="text-align: right;color:black;font-size:80%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; <b> ALPHARETTA, GEORGIA 30022-8209 </b> <br/></span>
            </td>
          </tr>
        </table>

        <table style="text-align:center;;width: 100%;font-family:'Arial'">
          <tr style="text-align:center;">
            <td style="text-align:center;">
              <b>
                <br/> User Signature Template <br/>
                Sign in the box only and place in flatbed <br/>
                Do not make any marks outside of the box <br/>
              </b>

              Box size: 2 inches x .75 inch <br/> <br/>
              <svg width="400" height="180">
                <rect x="30" y="20" width="200" height="75" style="fill:white;stroke:black;stroke-width:1;" />
                Sorry, your browser does not support inline SVG.  
              </svg> 

            </td>
          </tr>
        </table>
      </p>
      <html>`
    }
    catch(err){
      errorHandler(err)
    }
  }

  const onbtnPrintClick = async() => {
    try{
      var code = await GetHtmlCode()
      var type = 'text/html'
      alert("Suggested layout type for the document is " + 'PORTRAIT' + " for better visualization.") // LANDSCAPE
      const dataUrl1 = window.URL.createObjectURL(new Blob([code], { type }));
      const pdfWindow = window.open(dataUrl1, '', 'width=1000,height=850,scrollbars=1,menuBar=1')
      pdfWindow.focus()
      pdfWindow.addEventListener('beforeprint', event => {})
      pdfWindow.print() 
      pdfWindow.addEventListener('afterprint', (event) => {
        //pdfWindow.close() //Commented this line as PDF window was getting closed before print operation
      })        
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;
  
  const onbtnCLoseClick = async() => {
    try{
      goTo(thisObj, 'SystemMaintenanceSpecialFunctions#UserSignatureSetup#DEFAULT#false#Click')
    }
    catch(err){
      errorHandler(err)
    }
  }
  thisObj.onbtnCLoseClick = onbtnCLoseClick;

  function errorHandler(err){
    if(err.message == "Cannot read properties of null (reading 'focus')")
      alert('Please allow your browser to display pop up window and then click on ok to show the print window.')
    else
      showMessage(thisObj, err.message)
  }
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ReportUserSignatureTemplate*/}

              {/* END_USER_CODE-USER_BEFORE_ReportUserSignatureTemplate*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxUserSignatureTemplate*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxUserSignatureTemplate*/}
              <iframe src={state.renderUrl} id="docFrame" width="100%" height="500px" style={{background:"White"}}></iframe>
              <GroupBoxWidget
                conf={state.grpbxUserSignatureTemplate}
                screenConf={state}
              ></GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxUserSignatureTemplate*/}

              {/* END_USER_CODE-USER_AFTER_grpbxUserSignatureTemplate*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnPrintSetup*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintSetup*/}

                <ButtonWidget
                  conf={state.btnPrintSetup}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintSetup*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintSetup*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btnSaveAsPDF*/}

                {/* END_USER_CODE-USER_BEFORE_btnSaveAsPDF*/}

                <ButtonWidget
                  conf={state.btnSaveAsPDF}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSaveAsPDF*/}

                {/* END_USER_CODE-USER_AFTER_btnSaveAsPDF*/}
                {/* START_USER_CODE-USER_BEFORE_btnCLose*/}

                {/* END_USER_CODE-USER_BEFORE_btnCLose*/}

                <ButtonWidget
                  conf={state.btnCLose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCLose*/}

                {/* END_USER_CODE-USER_AFTER_btnCLose*/}
                {/* START_USER_CODE-USER_BEFORE_btn1*/}

                {/* END_USER_CODE-USER_BEFORE_btn1*/}

                <ButtonWidget
                  conf={state.btn1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn1*/}

                {/* END_USER_CODE-USER_AFTER_btn1*/}
                {/* START_USER_CODE-USER_BEFORE_btn2*/}

                {/* END_USER_CODE-USER_BEFORE_btn2*/}

                <ButtonWidget
                  conf={state.btn2}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn2*/}

                {/* END_USER_CODE-USER_AFTER_btn2*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_ReportUserSignatureTemplate*/}

              {/* END_USER_CODE-USER_AFTER_ReportUserSignatureTemplate*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_ReportUserSignatureTemplate
);
