/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  TextAreaWidget,
  setValue,
  disable,
  enable,
  goTo,
  setData,
  getData,

} from "../../shared/WindowImports";

import "./Scan211Document.scss";

// START_USER_CODE-USER_IMPORTS
import ModalPopUp from "react-bootstrap/Modal";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { useDropzone } from "react-dropzone";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_Scan211Document(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const handleClose = () => invokeModal(false)
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "Scan211Document",
    windowName: "Scan211Document",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.Scan211Document",
    // START_USER_CODE-USER_Scan211Document_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Scan 211 Document",
      scrCode: "PN0350D",
    },
    // END_USER_CODE-USER_Scan211Document_PROPERTIES
    btnCancelSave: {
      name: "btnCancelSave",
      type: "ButtonWidget",
      parent: "grpbxSavingOptions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancelSave_PROPERTIES

      // END_USER_CODE-USER_btnCancelSave_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxVerificationResult",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnSaveFromExistingFile: {
      name: "btnSaveFromExistingFile",
      type: "ButtonWidget",
      parent: "grpbxSavingOptions",
      Label: "Save from Existing File",
      CharWidth: "46",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSaveFromExistingFile_PROPERTIES

      // END_USER_CODE-USER_btnSaveFromExistingFile_PROPERTIES
    },
    btnVerifyAndSave: {
      name: "btnVerifyAndSave",
      type: "ButtonWidget",
      parent: "grpbxVerificationResult",
      Label: "Verify and Save",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVerifyAndSave_PROPERTIES

      // END_USER_CODE-USER_btnVerifyAndSave_PROPERTIES
    },
    grpbx211DocumentInformation: {
      name: "grpbx211DocumentInformation",
      type: "GroupBoxWidget",
      parent: "grpbxScan211Documents",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbx211DocumentInformation_PROPERTIES

      // END_USER_CODE-USER_grpbx211DocumentInformation_PROPERTIES
    },
    grpbxSavingOptions: {
      name: "grpbxSavingOptions",
      type: "GroupBoxWidget",
      parent: "grpbxScan211Documents",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSavingOptions_PROPERTIES

      // END_USER_CODE-USER_grpbxSavingOptions_PROPERTIES
    },
    grpbxVerificationResult: {
      name: "grpbxVerificationResult",
      type: "GroupBoxWidget",
      parent: "grpbxScan211Documents",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVerificationResult_PROPERTIES

      // END_USER_CODE-USER_grpbxVerificationResult_PROPERTIES
    },
    lbl211DocumentInformation: {
      name: "lbl211DocumentInformation",
      type: "LabelWidget",
      parent: "grpbx211DocumentInformation",
      Label: "211 Document Information",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl211DocumentInformation_PROPERTIES

      // END_USER_CODE-USER_lbl211DocumentInformation_PROPERTIES
    },
    lblSaving211Options: {
      name: "lblSaving211Options",
      type: "LabelWidget",
      parent: "grpbxSavingOptions",
      Label: "Saving 211 Options",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSaving211Options_PROPERTIES

      // END_USER_CODE-USER_lblSaving211Options_PROPERTIES
    },
    txtActionType: {
      name: "txtActionType",
      type: "TextBoxWidget",
      parent: "grpbx211DocumentInformation",
      Label: "Action Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtActionType_PROPERTIES
      ReadOnly: true,
      Enable: false
      // END_USER_CODE-USER_txtActionType_PROPERTIES
    },
    txtarVerificationResult: {
      name: "txtarVerificationResult",
      type: "TextAreaWidget",
      parent: "grpbxVerificationResult",
      Label: "Verification Result",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarVerificationResult_PROPERTIES

      // END_USER_CODE-USER_txtarVerificationResult_PROPERTIES
    },
    txtDocumentType: {
      name: "txtDocumentType",
      type: "TextBoxWidget",
      parent: "grpbx211DocumentInformation",
      Label: "Document Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDocumentType_PROPERTIES
      ReadOnly: true,
      Enable: false
      // END_USER_CODE-USER_txtDocumentType_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbx211DocumentInformation",
      Label: "Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES
      ReadOnly: true,
      Enable: false
      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    grpbxScan211Documents: {
      name: "grpbxScan211Documents",
      type: "GroupBoxWidget",
      parent: "Scan211Document",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxScan211Documents_PROPERTIES

      // END_USER_CODE-USER_grpbxScan211Documents_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  let mLoc = "";
  let mnFTPTimerInterval = 0;
  let mnFTPRetryLimit = 0;
  let msDocNum = "";
  let msFTPEnableLog = "";
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    Formload();
    EnableDisableControls();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');

  const selectionMode = "Selection"
  const scanningMode = "Scanning"
  const verifyMode = "Verifying"

  const [isShow, invokeModal] = React.useState(false);
  const [files, setFiles] = useState([]);

  let cropYear = cropYearFromLS();
  let comp_id = compIdFromLS();
  let user_id = useridFromLS()

  let buy_pt_id = ""
  let cont_num = ""
  let func_id = ""
  let mbMode = selectionMode;
  let timeout = null;
  let dialogOpen;

  function Formload() {
    var data = getData(thisObj, "ScanDocumentData");
    //   actionType: "P",
    //   vendor: "112276",
    //   DocType: "CON"
    // }
    // setData(thisObj, 'DataFromPrntScreen', data)
    if (data !== null || data !== undefined) {
      let actionType = data.actionType == 'P' ? "Scan" : "Rescan";
      let vendor = data.vendor;
      let DocType = data.DocType;
      setValue(thisObj, "txtDocumentType", DocType == 'GPC'? 'Golden' : 'Concordia DMA');
      setValue(thisObj, "txtActionType", actionType);
      setValue(thisObj, "txtVendor", vendor);
    }
    // disable(thisObj, "btnSaveFromExistingFile");
    disable(thisObj, "txtarVerificationResult");
    disable(thisObj, "btnVerifyAndSave");
  }

  // Start method for Upload Scan document
  function UploadScannedDocument(fileName) {
    try {
      if (fileName == '') {
        showMessage(thisObj, "FileName is empty")
        return false;
      } else if (fileName.split('.')[1] !== 'pdf') {
        showMessage(thisObj, "File is not pdf format")
        return false;
      }
      setValue(thisObj, "txtarVerificationResult", fileName + " loaded. Select Verify and Save to verify. Cancel to exit scanning.")
      invokeModal(false)
      return true;
    }
    catch (error) {
      setValue(thisObj, "lblVerifctnResultVal", "Please try to upload the file again.")
      invokeModal(false)
      return false;
    }
  }
  // End method for Upload Scan document

  // Start method of FTP Document and called in verifyScanDocument method
  async function FTPDocument(file) {
    try {
      let FTP_Method = "";
      let sUBuyPtId = "";
      ContractManagementService.RetrieveUserControlDetails(user_id).then(response => {
        sUBuyPtId = response[0].buyPtId;
        ContractManagementService.RetrieveBuyingPointControlDetails('PN1060', null, null, sUBuyPtId, null).then(response => {
          let collectionData = response;
          for (let i = 0; i < collectionData.length; i++) {
            mLoc = collectionData[i].buy_pt_id;
            setData(thisObj, 'mLoc_scan211', mLoc)
            msFTPEnableLog = collectionData[i].ftp_enable_log;
            mnFTPTimerInterval = collectionData[i].ftp_timer_interval;
            mnFTPRetryLimit = collectionData[i].ftp_retry_limit;
            FTP_Method = collectionData[i].ftp_method;
          }
        });
      });


      var date = new Date();
      let data = getData(thisObj, 'ScanDocumentData')
      var VendorNumber = data.vendor
      var cont = ''
      if (VendorNumber.length <= 6) {
        for (var i = 0; i < (6 - VendorNumber.length); i++) {
          cont += '0'
        }
      }
      const numArr = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59]
      //var scanType = data.actionType == 'P' ? 'S' : 'R'
      var scanType = data.actionType
      var PREFIX_211_SCAN = 'F211'
      var fileNameToProcess = PREFIX_211_SCAN + scanType + comp_id.slice(0, 2) + data.vendor + data.DocType +
        date.getFullYear() + numArr[date.getMonth() + 1] + numArr[date.getDate()] + numArr[date.getHours()] +
        numArr[date.getMinutes()] + numArr[date.getSeconds()] + ".pdf"

      setData(thisObj, "FTPfileNameScan211", fileNameToProcess)

      var environmentType = 'PPSTest'
      if (process.env.REACT_APP_ENVIR == 'PROD') {
        environmentType = 'PPSProd'
      }

      const formData = new FormData();
      formData.append('FormFile', file[0]);
      formData.append('filename', fileNameToProcess)
      formData.append('environment', environmentType)
      formData.append('folderType', 'form211')

      let response = await ContractManagementService.UploadFtpFile(formData)
      if (response != undefined && response != null) {
        if (response.message == 'File transfer successfully') {
          let mLocc = getData(thisObj, 'mLoc_scan211')
          setData(thisObj, 'T/F_Scan211', true)
          let strMessage = `FTP State Change: State=200, The request has completed and all data has been received`
            let dataObj = {
              trans_num: 4,
              message: strMessage
            }
            let logData = await ContractManagementService.CreateInspectUploadLogDetails(mLocc, dataObj);

          setValue(thisObj, "txtarVerificationResult", "File " + file[0].name + " successfully transferred as " + fileNameToProcess)
          enable(thisObj, "btnSaveFromExistingFile")
          disable(thisObj, "btnVerifyAndSave")
          let scanObj = {
            method: FTP_Method == 'Scan' ? 'S' : 'R',
            uploadstatus: '',
            filname: fileNameToProcess
          }
          setData(thisObj, 'Scan211Doc', scanObj)

          alert("File " + file[0].name + " successfully transferred as " + fileNameToProcess)
          goTo(thisObj, 'SystemMaintenance#VendorMaintenance#DEFAULT#false#Click')
        }
        else if (response.message != 'File transfer successfully') {
          let mLocc = getData(thisObj, 'mLoc_scan211')
          let strMessage = `FTP State Error = ${response.message}`
            let dataObj = {
              trans_num: 4,
              message: strMessage
            }
            let logData = await ContractManagementService.CreateInspectUploadLogDetails(mLocc, dataObj);
          setValue(thisObj, "txtarVerificationResult", "Problem with saving (FTP) the Contract Pricing. You can retry or upload the file later")
          showMessage(thisObj, "Problem with saving (FTP) the Contract Pricing. You can retry or upload the file later")
          disable(thisObj, "btnVerifyAndSave")
          enable(thisObj, "btnSaveFromExistingFile")
          // enable(thisObj, "onbtnCnclClick")
          enable(thisObj, "btnCancel")
          setData(thisObj, 'T/F_Scan211', false)
        }
      }
        else if (response == null || response == undefined) {
          showMessage(thisObj, "The PDF file is of a lower version and not supported. To re-create the file in higher version, Open the File > Go to File > Print > Select Printer Microsoft Print to PDF > Save this version somewhere. Upload the new saved contract. Please contact your system administrator if the problem persist.");
          return false;
        }
      enable(thisObj, "btnCancel")
      setValue(thisObj, "txtarVerificationResult", response.message)
      // showMessage(thisObj, response.message)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:FTPDocument event:Click");
      }
      return false;
    }
    return true;
  }
  // ENd method of FTP Document and called in verifyScanDocument method

  // Start method to Verify scan document
  async function VerifyScanDocument(file) {
    try {
      await FTPDocument(file)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:VerifyScanDocument event:Click");
      }
      return false;
    }
    return true;
  }
  // End method to Verify scan document

  //Start method to enable disable controls
  function EnableDisableControls() {
    if (mbMode == selectionMode) {
      disable(thisObj, "btnCancelSave")
      disable(thisObj, "btnVerifyAndSave")
      setValue(thisObj, "txtarVerificationResult", "")
    }
    else if (mbMode == scanningMode) {
      disable(thisObj, "btnSaveFromExistingFile")
      disable(thisObj, "btnCancelSave")
      setValue(thisObj, "txtarVerificationResult", "")
    }
    else if (mbMode == verifyMode) {
      disable(thisObj, "btnSaveFromExistingFile")
      disable(thisObj, "btnCancelSave")
      setValue(thisObj, "txtarVerificationResult", "Review the scanned document.  Click Verify and Save to save the document.  Click Cancel to return to Saving Options")
    }
    else {
      showMessage(thisObj, "Invalid selection. ")
    }
  }
  //End method to enable disable controls

  // Start method for getting popup and Drag-n Drop the files
  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )

      if (acceptedFiles != undefined && acceptedFiles != null) {
        if (acceptedFiles[0].name.split('.')[1] != 'pdf') {
          invokeModal(false);
          disable(thisObj, "btnVerifyAndSave")
          enable(thisObj, "btnSaveFromExistingFile")
          disable(thisObj, "btnCancelSave")
          setValue(thisObj, "txtarVerificationResult", "Please select only pdf files.")
          return;
        }
        invokeModal(false);
      }
      state.currentFile = acceptedFiles
      let promptresponse = window.confirm("Do you want to preview the document " + acceptedFiles[0].name + " before uploading ?");
      if (promptresponse == true) {
        window.open(acceptedFiles[0].preview)
      }
      setData(thisObj, "currentFile", acceptedFiles)
      setValue(thisObj, "currentFile", acceptedFiles)
      var res = UploadScannedDocument(acceptedFiles[0].name)
      if (res) {
        disable(thisObj, "btnSaveFromExistingFile")
        enable(thisObj, "btnVerifyAndSave")
        enable(thisObj, "btnCancelSave")
      }
      else {
        invokeModal(false);
        enable(thisObj, "btnSaveFromExistingFile")
        disable(thisObj, "btnVerifyAndSave")
        disable(thisObj, "btnCancelSave")
      }
    }
  })
  // End method for getting popup and Drag-n Drop the files

  // Start mthod for selecting the file on cick
  const selectFiles = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    )

    if (acceptedFiles != undefined && acceptedFiles != null) {
      if (acceptedFiles[0].name.split('.')[1] != 'pdf') {
        invokeModal(false);
        disable(thisObj, "btnVerifyAndSave")
        enable(thisObj, "btnSaveFromExistingFile")
        disable(thisObj, "btnCancelSave")
        setValue(thisObj, "txtarVerificationResult", "Please select only pdf files.")
        return;
      }
      invokeModal(false);
    }
    state.currentFile = acceptedFiles
    let promptresponse = window.confirm("Do you want to preview the document " + acceptedFiles[0].name + " before uploading ?");
    if (promptresponse == true) {
      setTimeout(()=>{
        window.open(acceptedFiles[0].preview)
      },100)
    }
    setData(thisObj, "currentFile", acceptedFiles)
    setValue(thisObj, "currentFile", acceptedFiles)
    var res = UploadScannedDocument(acceptedFiles[0].name)
    if (res) {
      disable(thisObj, "btnSaveFromExistingFile")
      enable(thisObj, "btnVerifyAndSave")
      enable(thisObj, "btnCancelSave")
    }
    else {
      invokeModal(false);
      enable(thisObj, "btnSaveFromExistingFile")
      disable(thisObj, "btnVerifyAndSave")
      disable(thisObj, "btnCancelSave")
    }
  }
  // End method for selecting the file on click

  // Start method for Saving Option cancel button
  const onbtnCancelSaveClick = () => {
    try {
      enable(thisObj, "btnSaveFromExistingFile");
      // enable(thisObj, "txtDocument");      
      disable(thisObj, "btnVerifyAndSave");
      setValue(thisObj, "txtarVerificationResult", "");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbtnCancelSaveClick event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelSaveClick = onbtnCancelSaveClick;
  // End method for Saving Option cancel button 

  // Start method for Save Existing File button 
  const onbtnSaveFromExistingFileClick = () => {
    try {
      invokeModal(true)
      thisObj.setState((current)=>{
        return{
          ...current,
          dropzoneFlag: !thisObj.state.dropzoneFlag
        }
      })
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnSaveFromExistingFile event:Click");
      }
      return false;
    }
  };
  thisObj.onbtnSaveFromExistingFileClick = onbtnSaveFromExistingFileClick;
  // End method for Save Existing File button

  // Start method for Verification result section cancel button
  const onbtnCancelClick = () => {
    try {
      // goTo(thisObj, "SystemMaintenance#VendorMaintenance#DEFAULT#false#Click");
      document.getElementById("SystemMaintenanceApplicationSupport_Scan211DocumentPopUp").childNodes[0].click()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbtnCnclClick event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // End method for Verification result section cancel button

 const onbtnVerifyAndSaveClick = async () => {
   try {
    let data = getData(thisObj, 'ScanDocumentData')
     let fileName = getData(thisObj, "currentFile");
     let vendor_Num = data.vendor
     let doc_type = data.DocType
     
     await VerifyScanDocument(fileName)
     let fileNametoUpdate = getData(thisObj, "FTPfileNameScan211");
     let dataObj = {
       "scan_action": data.actionType,
       "user_id": user_id,
       "ftp_file_name": fileNametoUpdate
      }
     if(getData(thisObj, 'T/F_Scan211') != false && getData(thisObj, 'T/F_Scan211') != undefined && getData(thisObj, 'T/F_Scan211') != null){
      SystemMaintenanceApplicationSupportService.Update211DocumentScan(vendor_Num, doc_type, dataObj).then(response => {
        // if (response.status == 200) {
        //   showMessage(thisObj, `File ${fileName[0].name} successfully transferred`, true)
        // }
      })
     }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbuttonwidget5Click event:Click");
      }
      return false;
    }
    return true;
 }
thisObj.onbtnVerifyAndSaveClick = onbtnVerifyAndSaveClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_Scan211Document*/}

              {/* END_USER_CODE-USER_BEFORE_Scan211Document*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxScan211Documents*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxScan211Documents*/}

              <GroupBoxWidget
                conf={state.grpbxScan211Documents}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbx211DocumentInformation*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx211DocumentInformation*/}

                <GroupBoxWidget
                  conf={state.grpbx211DocumentInformation}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lbl211DocumentInformation*/}

                  {/* END_USER_CODE-USER_BEFORE_lbl211DocumentInformation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lbl211DocumentInformation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lbl211DocumentInformation*/}

                  {/* END_USER_CODE-USER_AFTER_lbl211DocumentInformation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDocumentType*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDocumentType*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDocumentType}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDocumentType*/}

                  {/* END_USER_CODE-USER_AFTER_txtDocumentType*/}
                  {/* START_USER_CODE-USER_BEFORE_txtActionType*/}

                  {/* END_USER_CODE-USER_BEFORE_txtActionType*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtActionType}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtActionType*/}

                  {/* END_USER_CODE-USER_AFTER_txtActionType*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx211DocumentInformation*/}

                {/* END_USER_CODE-USER_AFTER_grpbx211DocumentInformation*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSavingOptions*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSavingOptions*/}

                <GroupBoxWidget
                  conf={state.grpbxSavingOptions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblSaving211Options*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSaving211Options*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSaving211Options}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSaving211Options*/}

                  {/* END_USER_CODE-USER_AFTER_lblSaving211Options*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSaveFromExistingFile*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSaveFromExistingFile*/}

                  <ButtonWidget
                    conf={state.btnSaveFromExistingFile}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSaveFromExistingFile*/}
                  <ModalPopUp id="blur"
                  show={isShow}
                  onHide={handleClose}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  keyboard="false"
                  style={{ width: "50%", height: "100%", left: "26%" }}
                  autoFocus
                  centered
                >
                  <ModalPopUp.Header closeButton style={{backgroundColor:"#2c3146", borderBottom:"none", padding:"0"}}>
                </ModalPopUp.Header>
                  <ModalPopUp.Body>
                    <div style={{ alignContent: "centered", textAlign: "centered", height: "100%", backgroundColor:"#dbd6d6" }}>
                      <div className="dropArea" {...getRootProps()} style={{ border: "1px solid #ccc", borderTop:"none", textAlign: "centered", height: "100%", backgroundColor:"white" }}>
                        {/* {...getRootProps()} */}
                        <label htmlFor="dropFile" style={{ textAlign: "center", width: "100%", height: "100%" }} className="btn">
                          <strong> Drag 'n' drop some file here, or click to select file. </strong>
                        </label>
                        {/* <input {...getInputProps()} accept=".pdf" id="dropFile" type="file" style={{ width: "50%", height : "100%" , visibility: "hidden" }}/> */}
                        <input 
                        onChange={(event) => {event.persist(); selectFiles([...event.target.files])}}
                        accept=".pdf" id="dropFile" type="file" style={{ width: "50%", height : "100%", visibility : "hidden" }}/>
                      </div>
                    </div>
                  </ModalPopUp.Body>
                </ModalPopUp>
                  {/* END_USER_CODE-USER_AFTER_btnSaveFromExistingFile*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCancelSave*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCancelSave*/}

                  <ButtonWidget
                    conf={state.btnCancelSave}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCancelSave*/}

                  {/* END_USER_CODE-USER_AFTER_btnCancelSave*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSavingOptions*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSavingOptions*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxVerificationResult*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxVerificationResult*/}

                <GroupBoxWidget
                  conf={state.grpbxVerificationResult}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_txtarVerificationResult*/}

                  {/* END_USER_CODE-USER_BEFORE_txtarVerificationResult*/}

                  <TextAreaWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtarVerificationResult}
                    screenConf={state}
                  ></TextAreaWidget>
                  {/* START_USER_CODE-USER_AFTER_txtarVerificationResult*/}

                  {/* END_USER_CODE-USER_AFTER_txtarVerificationResult*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVerifyAndSave*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVerifyAndSave*/}

                  <ButtonWidget
                    conf={state.btnVerifyAndSave}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVerifyAndSave*/}

                  {/* END_USER_CODE-USER_AFTER_btnVerifyAndSave*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                  <ButtonWidget
                    conf={state.btnCancel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                  {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxVerificationResult*/}

                {/* END_USER_CODE-USER_AFTER_grpbxVerificationResult*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxScan211Documents*/}

              {/* END_USER_CODE-USER_AFTER_grpbxScan211Documents*/}

              {/* START_USER_CODE-USER_AFTER_Scan211Document*/}

              {/* END_USER_CODE-USER_AFTER_Scan211Document*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceApplicationSupport_Scan211Document);
