/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FarmProfile from "./FarmProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FarmProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FarmProfile />);
    });
  });
  afterEach(cleanup);
  test("is FarmProfile Loads Successfully", () => {
    expect(screen.getByText("FarmProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for FarmProfile", () => {
    // START_USER_CODE-USER_FarmProfile_Custom_Test_Case
    // END_USER_CODE-USER_FarmProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FarmProfile />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnDown(Button Widget) Test Cases", async () => {
    const btnDown = screen.getByTestId("btnDown");
    expect(btnDown).toBeInTheDocument;
    expect(btnDown.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_btnDown")
    );
  });
  test("Custom Test Cases for btnDown", () => {
    // START_USER_CODE-USER_btnDown_TEST
    // END_USER_CODE-USER_btnDown_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("btnSaveOrder(Button Widget) Test Cases", async () => {
    const btnSaveOrder = screen.getByTestId("btnSaveOrder");
    expect(btnSaveOrder).toBeInTheDocument;
    expect(btnSaveOrder.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_btnSaveOrder")
    );
  });
  test("Custom Test Cases for btnSaveOrder", () => {
    // START_USER_CODE-USER_btnSaveOrder_TEST
    // END_USER_CODE-USER_btnSaveOrder_TEST
  });
  test("btnUp(Button Widget) Test Cases", async () => {
    const btnUp = screen.getByTestId("btnUp");
    expect(btnUp).toBeInTheDocument;
    expect(btnUp.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_btnUp")
    );
  });
  test("Custom Test Cases for btnUp", () => {
    // START_USER_CODE-USER_btnUp_TEST
    // END_USER_CODE-USER_btnUp_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxFarmDetails1(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmDetails1 = screen.getByTestId("grpbxFarmDetails1");
    expect(grpbxFarmDetails1.tagName).toBe("BUTTON");
    expect(grpbxFarmDetails1.type).toBe("button");
    expect(grpbxFarmDetails1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmDetails1", () => {
    // START_USER_CODE-USER_grpbxFarmDetails1_TEST
    // END_USER_CODE-USER_grpbxFarmDetails1_TEST
  });
  test("grpbxFarmDetails2(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmDetails2 = screen.getByTestId("grpbxFarmDetails2");
    expect(grpbxFarmDetails2.tagName).toBe("BUTTON");
    expect(grpbxFarmDetails2.type).toBe("button");
    expect(grpbxFarmDetails2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmDetails2", () => {
    // START_USER_CODE-USER_grpbxFarmDetails2_TEST
    // END_USER_CODE-USER_grpbxFarmDetails2_TEST
  });
  test("grpbxFarmProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmProfile = screen.getByTestId("grpbxFarmProfile");
    expect(grpbxFarmProfile.tagName).toBe("BUTTON");
    expect(grpbxFarmProfile.type).toBe("button");
    expect(grpbxFarmProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmProfile", () => {
    // START_USER_CODE-USER_grpbxFarmProfile_TEST
    // END_USER_CODE-USER_grpbxFarmProfile_TEST
  });
  test("grpbxVendors(GroupBox Widget) Test Cases", async () => {
    const grpbxVendors = screen.getByTestId("grpbxVendors");
    expect(grpbxVendors.tagName).toBe("BUTTON");
    expect(grpbxVendors.type).toBe("button");
    expect(grpbxVendors.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendors", () => {
    // START_USER_CODE-USER_grpbxVendors_TEST
    // END_USER_CODE-USER_grpbxVendors_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblVendors(Label Widget) Test Cases", async () => {
    const lblVendors = screen.getByTestId("lblVendors");
    expect(lblVendors.tagName).toBe("LABEL");
    expect(lblVendors.classList).toContain("form-label");
    expect(lblVendors.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_lblVendors")
    );
  });
  test("Custom Test Cases for lblVendors", () => {
    // START_USER_CODE-USER_lblVendors_TEST
    // END_USER_CODE-USER_lblVendors_TEST
  });
  test("txtAcres(Textbox Widget) Test Cases", async () => {
    const txtAcres = screen.getByTestId("txtAcres");
    expect(txtAcres.tagName).toBe("INPUT");
    expect(txtAcres.type).toBe("text");
    expect(txtAcres.classList).toContain("textboxWidgetClass");
    expect(txtAcres.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_txtAcres")
    );
    await act(async () => {
      userEvent.type(txtAcres, "1");
    });
  });
  test("Custom Test Cases for txtAcres", () => {
    // START_USER_CODE-USER_txtAcres_TEST
    // END_USER_CODE-USER_txtAcres_TEST
  });
  test("txtAddedBy(Textbox Widget) Test Cases", async () => {
    const txtAddedBy = screen.getByTestId("txtAddedBy");
    expect(txtAddedBy.tagName).toBe("INPUT");
    expect(txtAddedBy.type).toBe("text");
    expect(txtAddedBy.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAddedBy, "1");
    });
  });
  test("Custom Test Cases for txtAddedBy", () => {
    // START_USER_CODE-USER_txtAddedBy_TEST
    // END_USER_CODE-USER_txtAddedBy_TEST
  });
  test("txtAddress(Textbox Widget) Test Cases", async () => {
    const txtAddress = screen.getByTestId("txtAddress");
    expect(txtAddress.tagName).toBe("INPUT");
    expect(txtAddress.type).toBe("text");
    expect(txtAddress.classList).toContain("textboxWidgetClass");
    expect(txtAddress.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_txtAddress")
    );
    await act(async () => {
      userEvent.type(txtAddress, "1");
    });
  });
  test("Custom Test Cases for txtAddress", () => {
    // START_USER_CODE-USER_txtAddress_TEST
    // END_USER_CODE-USER_txtAddress_TEST
  });
  test("txtChangedBy(Textbox Widget) Test Cases", async () => {
    const txtChangedBy = screen.getByTestId("txtChangedBy");
    expect(txtChangedBy.tagName).toBe("INPUT");
    expect(txtChangedBy.type).toBe("text");
    expect(txtChangedBy.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtChangedBy, "1");
    });
  });
  test("Custom Test Cases for txtChangedBy", () => {
    // START_USER_CODE-USER_txtChangedBy_TEST
    // END_USER_CODE-USER_txtChangedBy_TEST
  });
  test("txtCity(Textbox Widget) Test Cases", async () => {
    const txtCity = screen.getByTestId("txtCity");
    expect(txtCity.tagName).toBe("INPUT");
    expect(txtCity.type).toBe("text");
    expect(txtCity.classList).toContain("textboxWidgetClass");
    expect(txtCity.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_txtCity")
    );
    await act(async () => {
      userEvent.type(txtCity, "1");
    });
  });
  test("Custom Test Cases for txtCity", () => {
    // START_USER_CODE-USER_txtCity_TEST
    // END_USER_CODE-USER_txtCity_TEST
  });
  test("txtFrmId(Textbox Widget) Test Cases", async () => {
    const txtFrmId = screen.getByTestId("txtFrmId");
    expect(txtFrmId.tagName).toBe("INPUT");
    expect(txtFrmId.type).toBe("text");
    expect(txtFrmId.classList).toContain("textboxWidgetClass");
    expect(txtFrmId.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_txtFrmId")
    );
    await act(async () => {
      userEvent.type(txtFrmId, "1");
    });
  });
  test("Custom Test Cases for txtFrmId", () => {
    // START_USER_CODE-USER_txtFrmId_TEST
    // END_USER_CODE-USER_txtFrmId_TEST
  });
  test("txtFrmNm(Textbox Widget) Test Cases", async () => {
    const txtFrmNm = screen.getByTestId("txtFrmNm");
    expect(txtFrmNm.tagName).toBe("INPUT");
    expect(txtFrmNm.type).toBe("text");
    expect(txtFrmNm.classList).toContain("textboxWidgetClass");
    expect(txtFrmNm.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_txtFrmNm")
    );
    await act(async () => {
      userEvent.type(txtFrmNm, "1");
    });
  });
  test("Custom Test Cases for txtFrmNm", () => {
    // START_USER_CODE-USER_txtFrmNm_TEST
    // END_USER_CODE-USER_txtFrmNm_TEST
  });
  test("txtFrmSffx(Textbox Widget) Test Cases", async () => {
    const txtFrmSffx = screen.getByTestId("txtFrmSffx");
    expect(txtFrmSffx.tagName).toBe("INPUT");
    expect(txtFrmSffx.type).toBe("text");
    expect(txtFrmSffx.classList).toContain("textboxWidgetClass");
    expect(txtFrmSffx.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_txtFrmSffx")
    );
    await act(async () => {
      userEvent.type(txtFrmSffx, "1");
    });
  });
  test("Custom Test Cases for txtFrmSffx", () => {
    // START_USER_CODE-USER_txtFrmSffx_TEST
    // END_USER_CODE-USER_txtFrmSffx_TEST
  });
  test("txtPhn(Textbox Widget) Test Cases", async () => {
    const txtPhn = screen.getByTestId("txtPhn");
    expect(txtPhn.tagName).toBe("INPUT");
    expect(txtPhn.type).toBe("text");
    expect(txtPhn.classList).toContain("textboxWidgetClass");
    expect(txtPhn.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_txtPhn")
    );
    await act(async () => {
      userEvent.type(txtPhn, "1");
    });
  });
  test("Custom Test Cases for txtPhn", () => {
    // START_USER_CODE-USER_txtPhn_TEST
    // END_USER_CODE-USER_txtPhn_TEST
  });
  test("txtSortNm(Textbox Widget) Test Cases", async () => {
    const txtSortNm = screen.getByTestId("txtSortNm");
    expect(txtSortNm.tagName).toBe("INPUT");
    expect(txtSortNm.type).toBe("text");
    expect(txtSortNm.classList).toContain("textboxWidgetClass");
    expect(txtSortNm.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_txtSortNm")
    );
    await act(async () => {
      userEvent.type(txtSortNm, "1");
    });
  });
  test("Custom Test Cases for txtSortNm", () => {
    // START_USER_CODE-USER_txtSortNm_TEST
    // END_USER_CODE-USER_txtSortNm_TEST
  });
  test("txtZip(Textbox Widget) Test Cases", async () => {
    const txtZip = screen.getByTestId("txtZip");
    expect(txtZip.tagName).toBe("INPUT");
    expect(txtZip.type).toBe("text");
    expect(txtZip.classList).toContain("textboxWidgetClass");
    expect(txtZip.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmProfile_txtZip")
    );
    await act(async () => {
      userEvent.type(txtZip, "1");
    });
  });
  test("Custom Test Cases for txtZip", () => {
    // START_USER_CODE-USER_txtZip_TEST
    // END_USER_CODE-USER_txtZip_TEST
  });
});
