/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_BuyingPointSetup from "./BuyingPointSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("BuyingPointSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_BuyingPointSetup />);
    });
  });
  afterEach(cleanup);
  test("is BuyingPointSetup Loads Successfully", () => {
    expect(screen.getByText("BuyingPointSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for BuyingPointSetup", () => {
    // START_USER_CODE-USER_BuyingPointSetup_Custom_Test_Case
    // END_USER_CODE-USER_BuyingPointSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_BuyingPointSetup />);
    });
  });
  afterEach(cleanup);
  test("btnAddArea(Button Widget) Test Cases", async () => {
    const btnAddArea = screen.getByTestId("btnAddArea");
    expect(btnAddArea).toBeInTheDocument;
    expect(btnAddArea.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointSetup_btnAddArea")
    );
  });
  test("Custom Test Cases for btnAddArea", () => {
    // START_USER_CODE-USER_btnAddArea_TEST
    // END_USER_CODE-USER_btnAddArea_TEST
  });
  test("btnAddBuyingPoint(Button Widget) Test Cases", async () => {
    const btnAddBuyingPoint = screen.getByTestId("btnAddBuyingPoint");
    expect(btnAddBuyingPoint).toBeInTheDocument;
    expect(btnAddBuyingPoint.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointSetup_btnAddBuyingPoint")
    );
  });
  test("Custom Test Cases for btnAddBuyingPoint", () => {
    // START_USER_CODE-USER_btnAddBuyingPoint_TEST
    // END_USER_CODE-USER_btnAddBuyingPoint_TEST
  });
  test("btnAddCollectionPoint(Button Widget) Test Cases", async () => {
    const btnAddCollectionPoint = screen.getByTestId("btnAddCollectionPoint");
    expect(btnAddCollectionPoint).toBeInTheDocument;
    expect(btnAddCollectionPoint.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointSetup_btnAddCollectionPoint"
      )
    );
  });
  test("Custom Test Cases for btnAddCollectionPoint", () => {
    // START_USER_CODE-USER_btnAddCollectionPoint_TEST
    // END_USER_CODE-USER_btnAddCollectionPoint_TEST
  });
  test("btnAddTransferBuyingPoint(Button Widget) Test Cases", async () => {
    const btnAddTransferBuyingPoint = screen.getByTestId(
      "btnAddTransferBuyingPoint"
    );
    expect(btnAddTransferBuyingPoint).toBeInTheDocument;
    expect(btnAddTransferBuyingPoint.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointSetup_btnAddTransferBuyingPoint"
      )
    );
  });
  test("Custom Test Cases for btnAddTransferBuyingPoint", () => {
    // START_USER_CODE-USER_btnAddTransferBuyingPoint_TEST
    // END_USER_CODE-USER_btnAddTransferBuyingPoint_TEST
  });
  test("btnAddWeighers(Button Widget) Test Cases", async () => {
    const btnAddWeighers = screen.getByTestId("btnAddWeighers");
    expect(btnAddWeighers).toBeInTheDocument;
    expect(btnAddWeighers.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointSetup_btnAddWeighers")
    );
  });
  test("Custom Test Cases for btnAddWeighers", () => {
    // START_USER_CODE-USER_btnAddWeighers_TEST
    // END_USER_CODE-USER_btnAddWeighers_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btncmdEnableDisableControls1(Button Widget) Test Cases", async () => {
    const btncmdEnableDisableControls1 = screen.getByTestId(
      "btncmdEnableDisableControls1"
    );
    expect(btncmdEnableDisableControls1).toBeInTheDocument;
    expect(btncmdEnableDisableControls1.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointSetup_btncmdEnableDisableControls1"
      )
    );
  });
  test("Custom Test Cases for btncmdEnableDisableControls1", () => {
    // START_USER_CODE-USER_btncmdEnableDisableControls1_TEST
    // END_USER_CODE-USER_btncmdEnableDisableControls1_TEST
  });
  test("btncmdEnableDisableControls2(Button Widget) Test Cases", async () => {
    const btncmdEnableDisableControls2 = screen.getByTestId(
      "btncmdEnableDisableControls2"
    );
    expect(btncmdEnableDisableControls2).toBeInTheDocument;
    expect(btncmdEnableDisableControls2.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointSetup_btncmdEnableDisableControls2"
      )
    );
  });
  test("Custom Test Cases for btncmdEnableDisableControls2", () => {
    // START_USER_CODE-USER_btncmdEnableDisableControls2_TEST
    // END_USER_CODE-USER_btncmdEnableDisableControls2_TEST
  });
  test("btnDeleteArea(Button Widget) Test Cases", async () => {
    const btnDeleteArea = screen.getByTestId("btnDeleteArea");
    expect(btnDeleteArea).toBeInTheDocument;
    expect(btnDeleteArea.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointSetup_btnDeleteArea")
    );
  });
  test("Custom Test Cases for btnDeleteArea", () => {
    // START_USER_CODE-USER_btnDeleteArea_TEST
    // END_USER_CODE-USER_btnDeleteArea_TEST
  });
  test("btnDeleteBuyingPoint(Button Widget) Test Cases", async () => {
    const btnDeleteBuyingPoint = screen.getByTestId("btnDeleteBuyingPoint");
    expect(btnDeleteBuyingPoint).toBeInTheDocument;
    expect(btnDeleteBuyingPoint.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointSetup_btnDeleteBuyingPoint"
      )
    );
  });
  test("Custom Test Cases for btnDeleteBuyingPoint", () => {
    // START_USER_CODE-USER_btnDeleteBuyingPoint_TEST
    // END_USER_CODE-USER_btnDeleteBuyingPoint_TEST
  });
  test("btnDeleteCollectionPoint(Button Widget) Test Cases", async () => {
    const btnDeleteCollectionPoint = screen.getByTestId(
      "btnDeleteCollectionPoint"
    );
    expect(btnDeleteCollectionPoint).toBeInTheDocument;
    expect(btnDeleteCollectionPoint.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointSetup_btnDeleteCollectionPoint"
      )
    );
  });
  test("Custom Test Cases for btnDeleteCollectionPoint", () => {
    // START_USER_CODE-USER_btnDeleteCollectionPoint_TEST
    // END_USER_CODE-USER_btnDeleteCollectionPoint_TEST
  });
  test("btnDeleteTransferBuyingPoint(Button Widget) Test Cases", async () => {
    const btnDeleteTransferBuyingPoint = screen.getByTestId(
      "btnDeleteTransferBuyingPoint"
    );
    expect(btnDeleteTransferBuyingPoint).toBeInTheDocument;
    expect(btnDeleteTransferBuyingPoint.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointSetup_btnDeleteTransferBuyingPoint"
      )
    );
  });
  test("Custom Test Cases for btnDeleteTransferBuyingPoint", () => {
    // START_USER_CODE-USER_btnDeleteTransferBuyingPoint_TEST
    // END_USER_CODE-USER_btnDeleteTransferBuyingPoint_TEST
  });
  test("btnDeleteWeighers(Button Widget) Test Cases", async () => {
    const btnDeleteWeighers = screen.getByTestId("btnDeleteWeighers");
    expect(btnDeleteWeighers).toBeInTheDocument;
    expect(btnDeleteWeighers.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointSetup_btnDeleteWeighers")
    );
  });
  test("Custom Test Cases for btnDeleteWeighers", () => {
    // START_USER_CODE-USER_btnDeleteWeighers_TEST
    // END_USER_CODE-USER_btnDeleteWeighers_TEST
  });
  test("btnEditArea(Button Widget) Test Cases", async () => {
    const btnEditArea = screen.getByTestId("btnEditArea");
    expect(btnEditArea).toBeInTheDocument;
    expect(btnEditArea.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointSetup_btnEditArea")
    );
  });
  test("Custom Test Cases for btnEditArea", () => {
    // START_USER_CODE-USER_btnEditArea_TEST
    // END_USER_CODE-USER_btnEditArea_TEST
  });
  test("btnEditBuyingPoint(Button Widget) Test Cases", async () => {
    const btnEditBuyingPoint = screen.getByTestId("btnEditBuyingPoint");
    expect(btnEditBuyingPoint).toBeInTheDocument;
    expect(btnEditBuyingPoint.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointSetup_btnEditBuyingPoint")
    );
  });
  test("Custom Test Cases for btnEditBuyingPoint", () => {
    // START_USER_CODE-USER_btnEditBuyingPoint_TEST
    // END_USER_CODE-USER_btnEditBuyingPoint_TEST
  });
  test("btnEditCollectionPoint(Button Widget) Test Cases", async () => {
    const btnEditCollectionPoint = screen.getByTestId("btnEditCollectionPoint");
    expect(btnEditCollectionPoint).toBeInTheDocument;
    expect(btnEditCollectionPoint.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointSetup_btnEditCollectionPoint"
      )
    );
  });
  test("Custom Test Cases for btnEditCollectionPoint", () => {
    // START_USER_CODE-USER_btnEditCollectionPoint_TEST
    // END_USER_CODE-USER_btnEditCollectionPoint_TEST
  });
  test("btnEditWeighers(Button Widget) Test Cases", async () => {
    const btnEditWeighers = screen.getByTestId("btnEditWeighers");
    expect(btnEditWeighers).toBeInTheDocument;
    expect(btnEditWeighers.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointSetup_btnEditWeighers")
    );
  });
  test("Custom Test Cases for btnEditWeighers", () => {
    // START_USER_CODE-USER_btnEditWeighers_TEST
    // END_USER_CODE-USER_btnEditWeighers_TEST
  });
  test("grpbxBuyingPointDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxBuyingPointDetails = screen.getByTestId(
      "grpbxBuyingPointDetails"
    );
    expect(grpbxBuyingPointDetails.tagName).toBe("BUTTON");
    expect(grpbxBuyingPointDetails.type).toBe("button");
    expect(grpbxBuyingPointDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBuyingPointDetails", () => {
    // START_USER_CODE-USER_grpbxBuyingPointDetails_TEST
    // END_USER_CODE-USER_grpbxBuyingPointDetails_TEST
  });
  test("grpbxTransfers(GroupBox Widget) Test Cases", async () => {
    const grpbxTransfers = screen.getByTestId("grpbxTransfers");
    expect(grpbxTransfers.tagName).toBe("BUTTON");
    expect(grpbxTransfers.type).toBe("button");
    expect(grpbxTransfers.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTransfers", () => {
    // START_USER_CODE-USER_grpbxTransfers_TEST
    // END_USER_CODE-USER_grpbxTransfers_TEST
  });
  test("grpbxWeighers(GroupBox Widget) Test Cases", async () => {
    const grpbxWeighers = screen.getByTestId("grpbxWeighers");
    expect(grpbxWeighers.tagName).toBe("BUTTON");
    expect(grpbxWeighers.type).toBe("button");
    expect(grpbxWeighers.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWeighers", () => {
    // START_USER_CODE-USER_grpbxWeighers_TEST
    // END_USER_CODE-USER_grpbxWeighers_TEST
  });
  test("lblTransfersOrObligationLocations(Label Widget) Test Cases", async () => {
    const lblTransfersOrObligationLocations = screen.getByTestId(
      "lblTransfersOrObligationLocations"
    );
    expect(lblTransfersOrObligationLocations.tagName).toBe("LABEL");
    expect(lblTransfersOrObligationLocations.classList).toContain("form-label");
    expect(lblTransfersOrObligationLocations.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointSetup_lblTransfersOrObligationLocations"
      )
    );
  });
  test("Custom Test Cases for lblTransfersOrObligationLocations", () => {
    // START_USER_CODE-USER_lblTransfersOrObligationLocations_TEST
    // END_USER_CODE-USER_lblTransfersOrObligationLocations_TEST
  });
  test("lblWeighers(Label Widget) Test Cases", async () => {
    const lblWeighers = screen.getByTestId("lblWeighers");
    expect(lblWeighers.tagName).toBe("LABEL");
    expect(lblWeighers.classList).toContain("form-label");
    expect(lblWeighers.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointSetup_lblWeighers")
    );
  });
  test("Custom Test Cases for lblWeighers", () => {
    // START_USER_CODE-USER_lblWeighers_TEST
    // END_USER_CODE-USER_lblWeighers_TEST
  });
});
