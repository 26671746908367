/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FreightMenu from "./FreightMenu";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FreightMenu Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FreightMenu />);
    });
  });
  afterEach(cleanup);
  test("is FreightMenu Loads Successfully", () => {
    expect(screen.getByText("FreightMenu")).toBeInTheDocument;
  });
  test("Custom Test Cases for FreightMenu", () => {
    // START_USER_CODE-USER_FreightMenu_Custom_Test_Case
    // END_USER_CODE-USER_FreightMenu_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FreightMenu />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightMenu_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnFreightLocToLocMileage(Button Widget) Test Cases", async () => {
    const btnFreightLocToLocMileage = screen.getByTestId(
      "btnFreightLocToLocMileage"
    );
    expect(btnFreightLocToLocMileage).toBeInTheDocument;
    expect(btnFreightLocToLocMileage.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FreightMenu_btnFreightLocToLocMileage"
      )
    );
  });
  test("Custom Test Cases for btnFreightLocToLocMileage", () => {
    // START_USER_CODE-USER_btnFreightLocToLocMileage_TEST
    // END_USER_CODE-USER_btnFreightLocToLocMileage_TEST
  });
  test("btnFreightRate(Button Widget) Test Cases", async () => {
    const btnFreightRate = screen.getByTestId("btnFreightRate");
    expect(btnFreightRate).toBeInTheDocument;
    expect(btnFreightRate.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightMenu_btnFreightRate")
    );
  });
  test("Custom Test Cases for btnFreightRate", () => {
    // START_USER_CODE-USER_btnFreightRate_TEST
    // END_USER_CODE-USER_btnFreightRate_TEST
  });
  test("btnFuelSurchargeInquiry(Button Widget) Test Cases", async () => {
    const btnFuelSurchargeInquiry = screen.getByTestId(
      "btnFuelSurchargeInquiry"
    );
    expect(btnFuelSurchargeInquiry).toBeInTheDocument;
    expect(btnFuelSurchargeInquiry.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightMenu_btnFuelSurchargeInquiry")
    );
  });
  test("Custom Test Cases for btnFuelSurchargeInquiry", () => {
    // START_USER_CODE-USER_btnFuelSurchargeInquiry_TEST
    // END_USER_CODE-USER_btnFuelSurchargeInquiry_TEST
  });
  test("btnLeasedTrailerControl(Button Widget) Test Cases", async () => {
    const btnLeasedTrailerControl = screen.getByTestId(
      "btnLeasedTrailerControl"
    );
    expect(btnLeasedTrailerControl).toBeInTheDocument;
    expect(btnLeasedTrailerControl.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightMenu_btnLeasedTrailerControl")
    );
  });
  test("Custom Test Cases for btnLeasedTrailerControl", () => {
    // START_USER_CODE-USER_btnLeasedTrailerControl_TEST
    // END_USER_CODE-USER_btnLeasedTrailerControl_TEST
  });
  test("grpbxFreightMenu(GroupBox Widget) Test Cases", async () => {
    const grpbxFreightMenu = screen.getByTestId("grpbxFreightMenu");
    expect(grpbxFreightMenu.tagName).toBe("BUTTON");
    expect(grpbxFreightMenu.type).toBe("button");
    expect(grpbxFreightMenu.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFreightMenu", () => {
    // START_USER_CODE-USER_grpbxFreightMenu_TEST
    // END_USER_CODE-USER_grpbxFreightMenu_TEST
  });
});
