/* eslint-disable*/
import React from "react";
import ReactApp_LOVChildPage from "./LOVChildPage";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("LOVChildPage Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ReactApp_LOVChildPage />);
    });
  });
  afterEach(cleanup);
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ReactApp_LOVChildPage />);
    });
  });
  afterEach(cleanup);
  test("buttonwidget3(Button Widget) Test Cases", async () => {
    const buttonwidget3 = screen.getByTestId("buttonwidget3");
    expect(buttonwidget3).toBeInTheDocument;
    expect(buttonwidget3.textContent).toEqual(
      t("translate:LOVChildPage_buttonwidget3")
    );
  });
  test("Custom Test Cases for buttonwidget3", () => {

  });
  test("buttonwidget4(Button Widget) Test Cases", async () => {
    const buttonwidget4 = screen.getByTestId("buttonwidget4");
    expect(buttonwidget4).toBeInTheDocument;
    expect(buttonwidget4.textContent).toEqual(
      t("translate:LOVChildPage_buttonwidget4")
    );
  });
  test("Custom Test Cases for buttonwidget4", () => {

  });
  test("buttonwidget5(Button Widget) Test Cases", async () => {
    const buttonwidget5 = screen.getByTestId("buttonwidget5");
    expect(buttonwidget5).toBeInTheDocument;
    expect(buttonwidget5.textContent).toEqual(
      t("translate:LOVChildPage_buttonwidget5")
    );
  });
  test("Custom Test Cases for buttonwidget5", () => {

  });
  test("groupboxwidget0(GroupBox Widget) Test Cases", async () => {
    const groupboxwidget0 = screen.getByTestId("groupboxwidget0");
    expect(groupboxwidget0.tagName).toBe("BUTTON");
    expect(groupboxwidget0.type).toBe("button");
    expect(groupboxwidget0.classList).toContain("btn");
    expect(groupboxwidget0.textContent).toEqual(
      t("translate:LOVChildPage_groupboxwidget0")
    );
  });
  test("Custom Test Cases for groupboxwidget0", () => {

  });
  test("textboxwidget1(Textbox Widget) Test Cases", async () => {
    const textboxwidget1 = screen.getByTestId("textboxwidget1");
    expect(textboxwidget1.tagName).toBe("INPUT");
    expect(textboxwidget1.type).toBe("text");
    expect(textboxwidget1.classList).toContain("textboxWidgetClass");
    expect(textboxwidget1.previousElementSibling.textContent).toEqual(
      t("translate:LOVChildPage_textboxwidget1")
    );
    await act(async () => {
      userEvent.type(textboxwidget1, "1");
    });
    expect(textboxwidget1.getAttribute("value")).toBe("1");
  });
  test("Custom Test Cases for textboxwidget1", () => {

  });
  test("textboxwidget6(Textbox Widget) Test Cases", async () => {
    const textboxwidget6 = screen.getByTestId("textboxwidget6");
    expect(textboxwidget6.tagName).toBe("INPUT");
    expect(textboxwidget6.type).toBe("text");
    expect(textboxwidget6.classList).toContain("textboxWidgetClass");
    expect(textboxwidget6.previousElementSibling.textContent).toEqual(
      t("translate:LOVChildPage_textboxwidget6")
    );
    await act(async () => {
      userEvent.type(textboxwidget6, "123");
    });
    expect(textboxwidget6.getAttribute("value")).toBe("");
    expect(textboxwidget6.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for textboxwidget6", () => {

  });
});
