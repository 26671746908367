/* eslint-disable*/
import React from "react";
import Settlements_PremiumDeductionsVendorSplits from "./PremiumDeductionsVendorSplits";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PremiumDeductionsVendorSplits Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_PremiumDeductionsVendorSplits />);
    });
  });
  afterEach(cleanup);
  test("is PremiumDeductionsVendorSplits Loads Successfully", () => {
    expect(screen.getByText("PremiumDeductionsVendorSplits")).toBeInTheDocument;
  });
  test("Custom Test Cases for PremiumDeductionsVendorSplits", () => {
    // START_USER_CODE-USER_PremiumDeductionsVendorSplits_Custom_Test_Case
    // END_USER_CODE-USER_PremiumDeductionsVendorSplits_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_PremiumDeductionsVendorSplits />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxPrchsLabels(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsLabels = screen.getByTestId("grpbxPrchsLabels");
    expect(grpbxPrchsLabels.tagName).toBe("BUTTON");
    expect(grpbxPrchsLabels.type).toBe("button");
    expect(grpbxPrchsLabels.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsLabels", () => {
    // START_USER_CODE-USER_grpbxPrchsLabels_TEST
    // END_USER_CODE-USER_grpbxPrchsLabels_TEST
  });
  test("grpbxPrchsRow1(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow1 = screen.getByTestId("grpbxPrchsRow1");
    expect(grpbxPrchsRow1.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow1.type).toBe("button");
    expect(grpbxPrchsRow1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow1", () => {
    // START_USER_CODE-USER_grpbxPrchsRow1_TEST
    // END_USER_CODE-USER_grpbxPrchsRow1_TEST
  });
  test("grpbxPrchsRow10(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow10 = screen.getByTestId("grpbxPrchsRow10");
    expect(grpbxPrchsRow10.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow10.type).toBe("button");
    expect(grpbxPrchsRow10.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow10", () => {
    // START_USER_CODE-USER_grpbxPrchsRow10_TEST
    // END_USER_CODE-USER_grpbxPrchsRow10_TEST
  });
  test("grpbxPrchsRow11(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow11 = screen.getByTestId("grpbxPrchsRow11");
    expect(grpbxPrchsRow11.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow11.type).toBe("button");
    expect(grpbxPrchsRow11.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow11", () => {
    // START_USER_CODE-USER_grpbxPrchsRow11_TEST
    // END_USER_CODE-USER_grpbxPrchsRow11_TEST
  });
  test("grpbxPrchsRow12(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow12 = screen.getByTestId("grpbxPrchsRow12");
    expect(grpbxPrchsRow12.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow12.type).toBe("button");
    expect(grpbxPrchsRow12.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow12", () => {
    // START_USER_CODE-USER_grpbxPrchsRow12_TEST
    // END_USER_CODE-USER_grpbxPrchsRow12_TEST
  });
  test("grpbxPrchsRow13(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow13 = screen.getByTestId("grpbxPrchsRow13");
    expect(grpbxPrchsRow13.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow13.type).toBe("button");
    expect(grpbxPrchsRow13.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow13", () => {
    // START_USER_CODE-USER_grpbxPrchsRow13_TEST
    // END_USER_CODE-USER_grpbxPrchsRow13_TEST
  });
  test("grpbxPrchsRow14(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow14 = screen.getByTestId("grpbxPrchsRow14");
    expect(grpbxPrchsRow14.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow14.type).toBe("button");
    expect(grpbxPrchsRow14.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow14", () => {
    // START_USER_CODE-USER_grpbxPrchsRow14_TEST
    // END_USER_CODE-USER_grpbxPrchsRow14_TEST
  });
  test("grpbxPrchsRow15(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow15 = screen.getByTestId("grpbxPrchsRow15");
    expect(grpbxPrchsRow15.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow15.type).toBe("button");
    expect(grpbxPrchsRow15.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow15", () => {
    // START_USER_CODE-USER_grpbxPrchsRow15_TEST
    // END_USER_CODE-USER_grpbxPrchsRow15_TEST
  });
  test("grpbxPrchsRow16(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow16 = screen.getByTestId("grpbxPrchsRow16");
    expect(grpbxPrchsRow16.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow16.type).toBe("button");
    expect(grpbxPrchsRow16.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow16", () => {
    // START_USER_CODE-USER_grpbxPrchsRow16_TEST
    // END_USER_CODE-USER_grpbxPrchsRow16_TEST
  });
  test("grpbxPrchsRow17(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow17 = screen.getByTestId("grpbxPrchsRow17");
    expect(grpbxPrchsRow17.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow17.type).toBe("button");
    expect(grpbxPrchsRow17.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow17", () => {
    // START_USER_CODE-USER_grpbxPrchsRow17_TEST
    // END_USER_CODE-USER_grpbxPrchsRow17_TEST
  });
  test("grpbxPrchsRow18(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow18 = screen.getByTestId("grpbxPrchsRow18");
    expect(grpbxPrchsRow18.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow18.type).toBe("button");
    expect(grpbxPrchsRow18.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow18", () => {
    // START_USER_CODE-USER_grpbxPrchsRow18_TEST
    // END_USER_CODE-USER_grpbxPrchsRow18_TEST
  });
  test("grpbxPrchsRow19(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow19 = screen.getByTestId("grpbxPrchsRow19");
    expect(grpbxPrchsRow19.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow19.type).toBe("button");
    expect(grpbxPrchsRow19.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow19", () => {
    // START_USER_CODE-USER_grpbxPrchsRow19_TEST
    // END_USER_CODE-USER_grpbxPrchsRow19_TEST
  });
  test("grpbxPrchsRow2(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow2 = screen.getByTestId("grpbxPrchsRow2");
    expect(grpbxPrchsRow2.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow2.type).toBe("button");
    expect(grpbxPrchsRow2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow2", () => {
    // START_USER_CODE-USER_grpbxPrchsRow2_TEST
    // END_USER_CODE-USER_grpbxPrchsRow2_TEST
  });
  test("grpbxPrchsRow20(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow20 = screen.getByTestId("grpbxPrchsRow20");
    expect(grpbxPrchsRow20.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow20.type).toBe("button");
    expect(grpbxPrchsRow20.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow20", () => {
    // START_USER_CODE-USER_grpbxPrchsRow20_TEST
    // END_USER_CODE-USER_grpbxPrchsRow20_TEST
  });
  test("grpbxPrchsRow3(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow3 = screen.getByTestId("grpbxPrchsRow3");
    expect(grpbxPrchsRow3.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow3.type).toBe("button");
    expect(grpbxPrchsRow3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow3", () => {
    // START_USER_CODE-USER_grpbxPrchsRow3_TEST
    // END_USER_CODE-USER_grpbxPrchsRow3_TEST
  });
  test("grpbxPrchsRow4(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow4 = screen.getByTestId("grpbxPrchsRow4");
    expect(grpbxPrchsRow4.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow4.type).toBe("button");
    expect(grpbxPrchsRow4.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow4", () => {
    // START_USER_CODE-USER_grpbxPrchsRow4_TEST
    // END_USER_CODE-USER_grpbxPrchsRow4_TEST
  });
  test("grpbxPrchsRow5(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow5 = screen.getByTestId("grpbxPrchsRow5");
    expect(grpbxPrchsRow5.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow5.type).toBe("button");
    expect(grpbxPrchsRow5.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow5", () => {
    // START_USER_CODE-USER_grpbxPrchsRow5_TEST
    // END_USER_CODE-USER_grpbxPrchsRow5_TEST
  });
  test("grpbxPrchsRow6(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow6 = screen.getByTestId("grpbxPrchsRow6");
    expect(grpbxPrchsRow6.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow6.type).toBe("button");
    expect(grpbxPrchsRow6.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow6", () => {
    // START_USER_CODE-USER_grpbxPrchsRow6_TEST
    // END_USER_CODE-USER_grpbxPrchsRow6_TEST
  });
  test("grpbxPrchsRow7(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow7 = screen.getByTestId("grpbxPrchsRow7");
    expect(grpbxPrchsRow7.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow7.type).toBe("button");
    expect(grpbxPrchsRow7.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow7", () => {
    // START_USER_CODE-USER_grpbxPrchsRow7_TEST
    // END_USER_CODE-USER_grpbxPrchsRow7_TEST
  });
  test("grpbxPrchsRow8(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow8 = screen.getByTestId("grpbxPrchsRow8");
    expect(grpbxPrchsRow8.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow8.type).toBe("button");
    expect(grpbxPrchsRow8.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow8", () => {
    // START_USER_CODE-USER_grpbxPrchsRow8_TEST
    // END_USER_CODE-USER_grpbxPrchsRow8_TEST
  });
  test("grpbxPrchsRow9(GroupBox Widget) Test Cases", async () => {
    const grpbxPrchsRow9 = screen.getByTestId("grpbxPrchsRow9");
    expect(grpbxPrchsRow9.tagName).toBe("BUTTON");
    expect(grpbxPrchsRow9.type).toBe("button");
    expect(grpbxPrchsRow9.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPrchsRow9", () => {
    // START_USER_CODE-USER_grpbxPrchsRow9_TEST
    // END_USER_CODE-USER_grpbxPrchsRow9_TEST
  });
  test("grpbxPremiumDeductionsVendorSplits(GroupBox Widget) Test Cases", async () => {
    const grpbxPremiumDeductionsVendorSplits = screen.getByTestId(
      "grpbxPremiumDeductionsVendorSplits"
    );
    expect(grpbxPremiumDeductionsVendorSplits.tagName).toBe("BUTTON");
    expect(grpbxPremiumDeductionsVendorSplits.type).toBe("button");
    expect(grpbxPremiumDeductionsVendorSplits.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPremiumDeductionsVendorSplits", () => {
    // START_USER_CODE-USER_grpbxPremiumDeductionsVendorSplits_TEST
    // END_USER_CODE-USER_grpbxPremiumDeductionsVendorSplits_TEST
  });
  test("grpbxPurchase(GroupBox Widget) Test Cases", async () => {
    const grpbxPurchase = screen.getByTestId("grpbxPurchase");
    expect(grpbxPurchase.tagName).toBe("BUTTON");
    expect(grpbxPurchase.type).toBe("button");
    expect(grpbxPurchase.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPurchase", () => {
    // START_USER_CODE-USER_grpbxPurchase_TEST
    // END_USER_CODE-USER_grpbxPurchase_TEST
  });
  test("grpbxStorage(GroupBox Widget) Test Cases", async () => {
    const grpbxStorage = screen.getByTestId("grpbxStorage");
    expect(grpbxStorage.tagName).toBe("BUTTON");
    expect(grpbxStorage.type).toBe("button");
    expect(grpbxStorage.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStorage", () => {
    // START_USER_CODE-USER_grpbxStorage_TEST
    // END_USER_CODE-USER_grpbxStorage_TEST
  });
  test("grpbxStrgLabels(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgLabels = screen.getByTestId("grpbxStrgLabels");
    expect(grpbxStrgLabels.tagName).toBe("BUTTON");
    expect(grpbxStrgLabels.type).toBe("button");
    expect(grpbxStrgLabels.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgLabels", () => {
    // START_USER_CODE-USER_grpbxStrgLabels_TEST
    // END_USER_CODE-USER_grpbxStrgLabels_TEST
  });
  test("grpbxStrgRow1(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow1 = screen.getByTestId("grpbxStrgRow1");
    expect(grpbxStrgRow1.tagName).toBe("BUTTON");
    expect(grpbxStrgRow1.type).toBe("button");
    expect(grpbxStrgRow1.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow1", () => {
    // START_USER_CODE-USER_grpbxStrgRow1_TEST
    // END_USER_CODE-USER_grpbxStrgRow1_TEST
  });
  test("grpbxStrgRow10(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow10 = screen.getByTestId("grpbxStrgRow10");
    expect(grpbxStrgRow10.tagName).toBe("BUTTON");
    expect(grpbxStrgRow10.type).toBe("button");
    expect(grpbxStrgRow10.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow10", () => {
    // START_USER_CODE-USER_grpbxStrgRow10_TEST
    // END_USER_CODE-USER_grpbxStrgRow10_TEST
  });
  test("grpbxStrgRow11(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow11 = screen.getByTestId("grpbxStrgRow11");
    expect(grpbxStrgRow11.tagName).toBe("BUTTON");
    expect(grpbxStrgRow11.type).toBe("button");
    expect(grpbxStrgRow11.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow11", () => {
    // START_USER_CODE-USER_grpbxStrgRow11_TEST
    // END_USER_CODE-USER_grpbxStrgRow11_TEST
  });
  test("grpbxStrgRow12(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow12 = screen.getByTestId("grpbxStrgRow12");
    expect(grpbxStrgRow12.tagName).toBe("BUTTON");
    expect(grpbxStrgRow12.type).toBe("button");
    expect(grpbxStrgRow12.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow12", () => {
    // START_USER_CODE-USER_grpbxStrgRow12_TEST
    // END_USER_CODE-USER_grpbxStrgRow12_TEST
  });
  test("grpbxStrgRow13(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow13 = screen.getByTestId("grpbxStrgRow13");
    expect(grpbxStrgRow13.tagName).toBe("BUTTON");
    expect(grpbxStrgRow13.type).toBe("button");
    expect(grpbxStrgRow13.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow13", () => {
    // START_USER_CODE-USER_grpbxStrgRow13_TEST
    // END_USER_CODE-USER_grpbxStrgRow13_TEST
  });
  test("grpbxStrgRow14(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow14 = screen.getByTestId("grpbxStrgRow14");
    expect(grpbxStrgRow14.tagName).toBe("BUTTON");
    expect(grpbxStrgRow14.type).toBe("button");
    expect(grpbxStrgRow14.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow14", () => {
    // START_USER_CODE-USER_grpbxStrgRow14_TEST
    // END_USER_CODE-USER_grpbxStrgRow14_TEST
  });
  test("grpbxStrgRow15(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow15 = screen.getByTestId("grpbxStrgRow15");
    expect(grpbxStrgRow15.tagName).toBe("BUTTON");
    expect(grpbxStrgRow15.type).toBe("button");
    expect(grpbxStrgRow15.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow15", () => {
    // START_USER_CODE-USER_grpbxStrgRow15_TEST
    // END_USER_CODE-USER_grpbxStrgRow15_TEST
  });
  test("grpbxStrgRow16(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow16 = screen.getByTestId("grpbxStrgRow16");
    expect(grpbxStrgRow16.tagName).toBe("BUTTON");
    expect(grpbxStrgRow16.type).toBe("button");
    expect(grpbxStrgRow16.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow16", () => {
    // START_USER_CODE-USER_grpbxStrgRow16_TEST
    // END_USER_CODE-USER_grpbxStrgRow16_TEST
  });
  test("grpbxStrgRow17(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow17 = screen.getByTestId("grpbxStrgRow17");
    expect(grpbxStrgRow17.tagName).toBe("BUTTON");
    expect(grpbxStrgRow17.type).toBe("button");
    expect(grpbxStrgRow17.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow17", () => {
    // START_USER_CODE-USER_grpbxStrgRow17_TEST
    // END_USER_CODE-USER_grpbxStrgRow17_TEST
  });
  test("grpbxStrgRow18(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow18 = screen.getByTestId("grpbxStrgRow18");
    expect(grpbxStrgRow18.tagName).toBe("BUTTON");
    expect(grpbxStrgRow18.type).toBe("button");
    expect(grpbxStrgRow18.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow18", () => {
    // START_USER_CODE-USER_grpbxStrgRow18_TEST
    // END_USER_CODE-USER_grpbxStrgRow18_TEST
  });
  test("grpbxStrgRow19(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow19 = screen.getByTestId("grpbxStrgRow19");
    expect(grpbxStrgRow19.tagName).toBe("BUTTON");
    expect(grpbxStrgRow19.type).toBe("button");
    expect(grpbxStrgRow19.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow19", () => {
    // START_USER_CODE-USER_grpbxStrgRow19_TEST
    // END_USER_CODE-USER_grpbxStrgRow19_TEST
  });
  test("grpbxStrgRow2(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow2 = screen.getByTestId("grpbxStrgRow2");
    expect(grpbxStrgRow2.tagName).toBe("BUTTON");
    expect(grpbxStrgRow2.type).toBe("button");
    expect(grpbxStrgRow2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow2", () => {
    // START_USER_CODE-USER_grpbxStrgRow2_TEST
    // END_USER_CODE-USER_grpbxStrgRow2_TEST
  });
  test("grpbxStrgRow20(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow20 = screen.getByTestId("grpbxStrgRow20");
    expect(grpbxStrgRow20.tagName).toBe("BUTTON");
    expect(grpbxStrgRow20.type).toBe("button");
    expect(grpbxStrgRow20.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow20", () => {
    // START_USER_CODE-USER_grpbxStrgRow20_TEST
    // END_USER_CODE-USER_grpbxStrgRow20_TEST
  });
  test("grpbxStrgRow3(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow3 = screen.getByTestId("grpbxStrgRow3");
    expect(grpbxStrgRow3.tagName).toBe("BUTTON");
    expect(grpbxStrgRow3.type).toBe("button");
    expect(grpbxStrgRow3.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow3", () => {
    // START_USER_CODE-USER_grpbxStrgRow3_TEST
    // END_USER_CODE-USER_grpbxStrgRow3_TEST
  });
  test("grpbxStrgRow4(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow4 = screen.getByTestId("grpbxStrgRow4");
    expect(grpbxStrgRow4.tagName).toBe("BUTTON");
    expect(grpbxStrgRow4.type).toBe("button");
    expect(grpbxStrgRow4.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow4", () => {
    // START_USER_CODE-USER_grpbxStrgRow4_TEST
    // END_USER_CODE-USER_grpbxStrgRow4_TEST
  });
  test("grpbxStrgRow5(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow5 = screen.getByTestId("grpbxStrgRow5");
    expect(grpbxStrgRow5.tagName).toBe("BUTTON");
    expect(grpbxStrgRow5.type).toBe("button");
    expect(grpbxStrgRow5.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow5", () => {
    // START_USER_CODE-USER_grpbxStrgRow5_TEST
    // END_USER_CODE-USER_grpbxStrgRow5_TEST
  });
  test("grpbxStrgRow6(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow6 = screen.getByTestId("grpbxStrgRow6");
    expect(grpbxStrgRow6.tagName).toBe("BUTTON");
    expect(grpbxStrgRow6.type).toBe("button");
    expect(grpbxStrgRow6.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow6", () => {
    // START_USER_CODE-USER_grpbxStrgRow6_TEST
    // END_USER_CODE-USER_grpbxStrgRow6_TEST
  });
  test("grpbxStrgRow7(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow7 = screen.getByTestId("grpbxStrgRow7");
    expect(grpbxStrgRow7.tagName).toBe("BUTTON");
    expect(grpbxStrgRow7.type).toBe("button");
    expect(grpbxStrgRow7.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow7", () => {
    // START_USER_CODE-USER_grpbxStrgRow7_TEST
    // END_USER_CODE-USER_grpbxStrgRow7_TEST
  });
  test("grpbxStrgRow8(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow8 = screen.getByTestId("grpbxStrgRow8");
    expect(grpbxStrgRow8.tagName).toBe("BUTTON");
    expect(grpbxStrgRow8.type).toBe("button");
    expect(grpbxStrgRow8.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow8", () => {
    // START_USER_CODE-USER_grpbxStrgRow8_TEST
    // END_USER_CODE-USER_grpbxStrgRow8_TEST
  });
  test("grpbxStrgRow9(GroupBox Widget) Test Cases", async () => {
    const grpbxStrgRow9 = screen.getByTestId("grpbxStrgRow9");
    expect(grpbxStrgRow9.tagName).toBe("BUTTON");
    expect(grpbxStrgRow9.type).toBe("button");
    expect(grpbxStrgRow9.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxStrgRow9", () => {
    // START_USER_CODE-USER_grpbxStrgRow9_TEST
    // END_USER_CODE-USER_grpbxStrgRow9_TEST
  });
  test("grpbxTotalPrchs(GroupBox Widget) Test Cases", async () => {
    const grpbxTotalPrchs = screen.getByTestId("grpbxTotalPrchs");
    expect(grpbxTotalPrchs.tagName).toBe("BUTTON");
    expect(grpbxTotalPrchs.type).toBe("button");
    expect(grpbxTotalPrchs.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTotalPrchs", () => {
    // START_USER_CODE-USER_grpbxTotalPrchs_TEST
    // END_USER_CODE-USER_grpbxTotalPrchs_TEST
  });
  test("grpbxTotalStrg(GroupBox Widget) Test Cases", async () => {
    const grpbxTotalStrg = screen.getByTestId("grpbxTotalStrg");
    expect(grpbxTotalStrg.tagName).toBe("BUTTON");
    expect(grpbxTotalStrg.type).toBe("button");
    expect(grpbxTotalStrg.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTotalStrg", () => {
    // START_USER_CODE-USER_grpbxTotalStrg_TEST
    // END_USER_CODE-USER_grpbxTotalStrg_TEST
  });
  test("lbl1(Label Widget) Test Cases", async () => {
    const lbl1 = screen.getByTestId("lbl1");
    expect(lbl1.tagName).toBe("LABEL");
    expect(lbl1.classList).toContain("form-label");
    expect(lbl1.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl1")
    );
  });
  test("Custom Test Cases for lbl1", () => {
    // START_USER_CODE-USER_lbl1_TEST
    // END_USER_CODE-USER_lbl1_TEST
  });
  test("lbl10(Label Widget) Test Cases", async () => {
    const lbl10 = screen.getByTestId("lbl10");
    expect(lbl10.tagName).toBe("LABEL");
    expect(lbl10.classList).toContain("form-label");
    expect(lbl10.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl10")
    );
  });
  test("Custom Test Cases for lbl10", () => {
    // START_USER_CODE-USER_lbl10_TEST
    // END_USER_CODE-USER_lbl10_TEST
  });
  test("lbl10s(Label Widget) Test Cases", async () => {
    const lbl10s = screen.getByTestId("lbl10s");
    expect(lbl10s.tagName).toBe("LABEL");
    expect(lbl10s.classList).toContain("form-label");
    expect(lbl10s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl10s")
    );
  });
  test("Custom Test Cases for lbl10s", () => {
    // START_USER_CODE-USER_lbl10s_TEST
    // END_USER_CODE-USER_lbl10s_TEST
  });
  test("lbl11(Label Widget) Test Cases", async () => {
    const lbl11 = screen.getByTestId("lbl11");
    expect(lbl11.tagName).toBe("LABEL");
    expect(lbl11.classList).toContain("form-label");
    expect(lbl11.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl11")
    );
  });
  test("Custom Test Cases for lbl11", () => {
    // START_USER_CODE-USER_lbl11_TEST
    // END_USER_CODE-USER_lbl11_TEST
  });
  test("lbl11s(Label Widget) Test Cases", async () => {
    const lbl11s = screen.getByTestId("lbl11s");
    expect(lbl11s.tagName).toBe("LABEL");
    expect(lbl11s.classList).toContain("form-label");
    expect(lbl11s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl11s")
    );
  });
  test("Custom Test Cases for lbl11s", () => {
    // START_USER_CODE-USER_lbl11s_TEST
    // END_USER_CODE-USER_lbl11s_TEST
  });
  test("lbl12(Label Widget) Test Cases", async () => {
    const lbl12 = screen.getByTestId("lbl12");
    expect(lbl12.tagName).toBe("LABEL");
    expect(lbl12.classList).toContain("form-label");
    expect(lbl12.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl12")
    );
  });
  test("Custom Test Cases for lbl12", () => {
    // START_USER_CODE-USER_lbl12_TEST
    // END_USER_CODE-USER_lbl12_TEST
  });
  test("lbl12s(Label Widget) Test Cases", async () => {
    const lbl12s = screen.getByTestId("lbl12s");
    expect(lbl12s.tagName).toBe("LABEL");
    expect(lbl12s.classList).toContain("form-label");
    expect(lbl12s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl12s")
    );
  });
  test("Custom Test Cases for lbl12s", () => {
    // START_USER_CODE-USER_lbl12s_TEST
    // END_USER_CODE-USER_lbl12s_TEST
  });
  test("lbl13(Label Widget) Test Cases", async () => {
    const lbl13 = screen.getByTestId("lbl13");
    expect(lbl13.tagName).toBe("LABEL");
    expect(lbl13.classList).toContain("form-label");
    expect(lbl13.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl13")
    );
  });
  test("Custom Test Cases for lbl13", () => {
    // START_USER_CODE-USER_lbl13_TEST
    // END_USER_CODE-USER_lbl13_TEST
  });
  test("lbl13s(Label Widget) Test Cases", async () => {
    const lbl13s = screen.getByTestId("lbl13s");
    expect(lbl13s.tagName).toBe("LABEL");
    expect(lbl13s.classList).toContain("form-label");
    expect(lbl13s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl13s")
    );
  });
  test("Custom Test Cases for lbl13s", () => {
    // START_USER_CODE-USER_lbl13s_TEST
    // END_USER_CODE-USER_lbl13s_TEST
  });
  test("lbl14(Label Widget) Test Cases", async () => {
    const lbl14 = screen.getByTestId("lbl14");
    expect(lbl14.tagName).toBe("LABEL");
    expect(lbl14.classList).toContain("form-label");
    expect(lbl14.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl14")
    );
  });
  test("Custom Test Cases for lbl14", () => {
    // START_USER_CODE-USER_lbl14_TEST
    // END_USER_CODE-USER_lbl14_TEST
  });
  test("lbl14s(Label Widget) Test Cases", async () => {
    const lbl14s = screen.getByTestId("lbl14s");
    expect(lbl14s.tagName).toBe("LABEL");
    expect(lbl14s.classList).toContain("form-label");
    expect(lbl14s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl14s")
    );
  });
  test("Custom Test Cases for lbl14s", () => {
    // START_USER_CODE-USER_lbl14s_TEST
    // END_USER_CODE-USER_lbl14s_TEST
  });
  test("lbl15(Label Widget) Test Cases", async () => {
    const lbl15 = screen.getByTestId("lbl15");
    expect(lbl15.tagName).toBe("LABEL");
    expect(lbl15.classList).toContain("form-label");
    expect(lbl15.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl15")
    );
  });
  test("Custom Test Cases for lbl15", () => {
    // START_USER_CODE-USER_lbl15_TEST
    // END_USER_CODE-USER_lbl15_TEST
  });
  test("lbl15s(Label Widget) Test Cases", async () => {
    const lbl15s = screen.getByTestId("lbl15s");
    expect(lbl15s.tagName).toBe("LABEL");
    expect(lbl15s.classList).toContain("form-label");
    expect(lbl15s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl15s")
    );
  });
  test("Custom Test Cases for lbl15s", () => {
    // START_USER_CODE-USER_lbl15s_TEST
    // END_USER_CODE-USER_lbl15s_TEST
  });
  test("lbl16(Label Widget) Test Cases", async () => {
    const lbl16 = screen.getByTestId("lbl16");
    expect(lbl16.tagName).toBe("LABEL");
    expect(lbl16.classList).toContain("form-label");
    expect(lbl16.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl16")
    );
  });
  test("Custom Test Cases for lbl16", () => {
    // START_USER_CODE-USER_lbl16_TEST
    // END_USER_CODE-USER_lbl16_TEST
  });
  test("lbl16s(Label Widget) Test Cases", async () => {
    const lbl16s = screen.getByTestId("lbl16s");
    expect(lbl16s.tagName).toBe("LABEL");
    expect(lbl16s.classList).toContain("form-label");
    expect(lbl16s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl16s")
    );
  });
  test("Custom Test Cases for lbl16s", () => {
    // START_USER_CODE-USER_lbl16s_TEST
    // END_USER_CODE-USER_lbl16s_TEST
  });
  test("lbl17(Label Widget) Test Cases", async () => {
    const lbl17 = screen.getByTestId("lbl17");
    expect(lbl17.tagName).toBe("LABEL");
    expect(lbl17.classList).toContain("form-label");
    expect(lbl17.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl17")
    );
  });
  test("Custom Test Cases for lbl17", () => {
    // START_USER_CODE-USER_lbl17_TEST
    // END_USER_CODE-USER_lbl17_TEST
  });
  test("lbl17s(Label Widget) Test Cases", async () => {
    const lbl17s = screen.getByTestId("lbl17s");
    expect(lbl17s.tagName).toBe("LABEL");
    expect(lbl17s.classList).toContain("form-label");
    expect(lbl17s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl17s")
    );
  });
  test("Custom Test Cases for lbl17s", () => {
    // START_USER_CODE-USER_lbl17s_TEST
    // END_USER_CODE-USER_lbl17s_TEST
  });
  test("lbl18(Label Widget) Test Cases", async () => {
    const lbl18 = screen.getByTestId("lbl18");
    expect(lbl18.tagName).toBe("LABEL");
    expect(lbl18.classList).toContain("form-label");
    expect(lbl18.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl18")
    );
  });
  test("Custom Test Cases for lbl18", () => {
    // START_USER_CODE-USER_lbl18_TEST
    // END_USER_CODE-USER_lbl18_TEST
  });
  test("lbl18s(Label Widget) Test Cases", async () => {
    const lbl18s = screen.getByTestId("lbl18s");
    expect(lbl18s.tagName).toBe("LABEL");
    expect(lbl18s.classList).toContain("form-label");
    expect(lbl18s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl18s")
    );
  });
  test("Custom Test Cases for lbl18s", () => {
    // START_USER_CODE-USER_lbl18s_TEST
    // END_USER_CODE-USER_lbl18s_TEST
  });
  test("lbl19(Label Widget) Test Cases", async () => {
    const lbl19 = screen.getByTestId("lbl19");
    expect(lbl19.tagName).toBe("LABEL");
    expect(lbl19.classList).toContain("form-label");
    expect(lbl19.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl19")
    );
  });
  test("Custom Test Cases for lbl19", () => {
    // START_USER_CODE-USER_lbl19_TEST
    // END_USER_CODE-USER_lbl19_TEST
  });
  test("lbl19s(Label Widget) Test Cases", async () => {
    const lbl19s = screen.getByTestId("lbl19s");
    expect(lbl19s.tagName).toBe("LABEL");
    expect(lbl19s.classList).toContain("form-label");
    expect(lbl19s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl19s")
    );
  });
  test("Custom Test Cases for lbl19s", () => {
    // START_USER_CODE-USER_lbl19s_TEST
    // END_USER_CODE-USER_lbl19s_TEST
  });
  test("lbl1s(Label Widget) Test Cases", async () => {
    const lbl1s = screen.getByTestId("lbl1s");
    expect(lbl1s.tagName).toBe("LABEL");
    expect(lbl1s.classList).toContain("form-label");
    expect(lbl1s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl1s")
    );
  });
  test("Custom Test Cases for lbl1s", () => {
    // START_USER_CODE-USER_lbl1s_TEST
    // END_USER_CODE-USER_lbl1s_TEST
  });
  test("lbl2(Label Widget) Test Cases", async () => {
    const lbl2 = screen.getByTestId("lbl2");
    expect(lbl2.tagName).toBe("LABEL");
    expect(lbl2.classList).toContain("form-label");
    expect(lbl2.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl2")
    );
  });
  test("Custom Test Cases for lbl2", () => {
    // START_USER_CODE-USER_lbl2_TEST
    // END_USER_CODE-USER_lbl2_TEST
  });
  test("lbl20(Label Widget) Test Cases", async () => {
    const lbl20 = screen.getByTestId("lbl20");
    expect(lbl20.tagName).toBe("LABEL");
    expect(lbl20.classList).toContain("form-label");
    expect(lbl20.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl20")
    );
  });
  test("Custom Test Cases for lbl20", () => {
    // START_USER_CODE-USER_lbl20_TEST
    // END_USER_CODE-USER_lbl20_TEST
  });
  test("lbl20s(Label Widget) Test Cases", async () => {
    const lbl20s = screen.getByTestId("lbl20s");
    expect(lbl20s.tagName).toBe("LABEL");
    expect(lbl20s.classList).toContain("form-label");
    expect(lbl20s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl20s")
    );
  });
  test("Custom Test Cases for lbl20s", () => {
    // START_USER_CODE-USER_lbl20s_TEST
    // END_USER_CODE-USER_lbl20s_TEST
  });
  test("lbl2s(Label Widget) Test Cases", async () => {
    const lbl2s = screen.getByTestId("lbl2s");
    expect(lbl2s.tagName).toBe("LABEL");
    expect(lbl2s.classList).toContain("form-label");
    expect(lbl2s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl2s")
    );
  });
  test("Custom Test Cases for lbl2s", () => {
    // START_USER_CODE-USER_lbl2s_TEST
    // END_USER_CODE-USER_lbl2s_TEST
  });
  test("lbl3(Label Widget) Test Cases", async () => {
    const lbl3 = screen.getByTestId("lbl3");
    expect(lbl3.tagName).toBe("LABEL");
    expect(lbl3.classList).toContain("form-label");
    expect(lbl3.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl3")
    );
  });
  test("Custom Test Cases for lbl3", () => {
    // START_USER_CODE-USER_lbl3_TEST
    // END_USER_CODE-USER_lbl3_TEST
  });
  test("lbl3s(Label Widget) Test Cases", async () => {
    const lbl3s = screen.getByTestId("lbl3s");
    expect(lbl3s.tagName).toBe("LABEL");
    expect(lbl3s.classList).toContain("form-label");
    expect(lbl3s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl3s")
    );
  });
  test("Custom Test Cases for lbl3s", () => {
    // START_USER_CODE-USER_lbl3s_TEST
    // END_USER_CODE-USER_lbl3s_TEST
  });
  test("lbl4(Label Widget) Test Cases", async () => {
    const lbl4 = screen.getByTestId("lbl4");
    expect(lbl4.tagName).toBe("LABEL");
    expect(lbl4.classList).toContain("form-label");
    expect(lbl4.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl4")
    );
  });
  test("Custom Test Cases for lbl4", () => {
    // START_USER_CODE-USER_lbl4_TEST
    // END_USER_CODE-USER_lbl4_TEST
  });
  test("lbl4s(Label Widget) Test Cases", async () => {
    const lbl4s = screen.getByTestId("lbl4s");
    expect(lbl4s.tagName).toBe("LABEL");
    expect(lbl4s.classList).toContain("form-label");
    expect(lbl4s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl4s")
    );
  });
  test("Custom Test Cases for lbl4s", () => {
    // START_USER_CODE-USER_lbl4s_TEST
    // END_USER_CODE-USER_lbl4s_TEST
  });
  test("lbl5(Label Widget) Test Cases", async () => {
    const lbl5 = screen.getByTestId("lbl5");
    expect(lbl5.tagName).toBe("LABEL");
    expect(lbl5.classList).toContain("form-label");
    expect(lbl5.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl5")
    );
  });
  test("Custom Test Cases for lbl5", () => {
    // START_USER_CODE-USER_lbl5_TEST
    // END_USER_CODE-USER_lbl5_TEST
  });
  test("lbl5s(Label Widget) Test Cases", async () => {
    const lbl5s = screen.getByTestId("lbl5s");
    expect(lbl5s.tagName).toBe("LABEL");
    expect(lbl5s.classList).toContain("form-label");
    expect(lbl5s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl5s")
    );
  });
  test("Custom Test Cases for lbl5s", () => {
    // START_USER_CODE-USER_lbl5s_TEST
    // END_USER_CODE-USER_lbl5s_TEST
  });
  test("lbl6(Label Widget) Test Cases", async () => {
    const lbl6 = screen.getByTestId("lbl6");
    expect(lbl6.tagName).toBe("LABEL");
    expect(lbl6.classList).toContain("form-label");
    expect(lbl6.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl6")
    );
  });
  test("Custom Test Cases for lbl6", () => {
    // START_USER_CODE-USER_lbl6_TEST
    // END_USER_CODE-USER_lbl6_TEST
  });
  test("lbl6s(Label Widget) Test Cases", async () => {
    const lbl6s = screen.getByTestId("lbl6s");
    expect(lbl6s.tagName).toBe("LABEL");
    expect(lbl6s.classList).toContain("form-label");
    expect(lbl6s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl6s")
    );
  });
  test("Custom Test Cases for lbl6s", () => {
    // START_USER_CODE-USER_lbl6s_TEST
    // END_USER_CODE-USER_lbl6s_TEST
  });
  test("lbl7(Label Widget) Test Cases", async () => {
    const lbl7 = screen.getByTestId("lbl7");
    expect(lbl7.tagName).toBe("LABEL");
    expect(lbl7.classList).toContain("form-label");
    expect(lbl7.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl7")
    );
  });
  test("Custom Test Cases for lbl7", () => {
    // START_USER_CODE-USER_lbl7_TEST
    // END_USER_CODE-USER_lbl7_TEST
  });
  test("lbl7s(Label Widget) Test Cases", async () => {
    const lbl7s = screen.getByTestId("lbl7s");
    expect(lbl7s.tagName).toBe("LABEL");
    expect(lbl7s.classList).toContain("form-label");
    expect(lbl7s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl7s")
    );
  });
  test("Custom Test Cases for lbl7s", () => {
    // START_USER_CODE-USER_lbl7s_TEST
    // END_USER_CODE-USER_lbl7s_TEST
  });
  test("lbl8(Label Widget) Test Cases", async () => {
    const lbl8 = screen.getByTestId("lbl8");
    expect(lbl8.tagName).toBe("LABEL");
    expect(lbl8.classList).toContain("form-label");
    expect(lbl8.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl8")
    );
  });
  test("Custom Test Cases for lbl8", () => {
    // START_USER_CODE-USER_lbl8_TEST
    // END_USER_CODE-USER_lbl8_TEST
  });
  test("lbl8s(Label Widget) Test Cases", async () => {
    const lbl8s = screen.getByTestId("lbl8s");
    expect(lbl8s.tagName).toBe("LABEL");
    expect(lbl8s.classList).toContain("form-label");
    expect(lbl8s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl8s")
    );
  });
  test("Custom Test Cases for lbl8s", () => {
    // START_USER_CODE-USER_lbl8s_TEST
    // END_USER_CODE-USER_lbl8s_TEST
  });
  test("lbl9(Label Widget) Test Cases", async () => {
    const lbl9 = screen.getByTestId("lbl9");
    expect(lbl9.tagName).toBe("LABEL");
    expect(lbl9.classList).toContain("form-label");
    expect(lbl9.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl9")
    );
  });
  test("Custom Test Cases for lbl9", () => {
    // START_USER_CODE-USER_lbl9_TEST
    // END_USER_CODE-USER_lbl9_TEST
  });
  test("lbl9s(Label Widget) Test Cases", async () => {
    const lbl9s = screen.getByTestId("lbl9s");
    expect(lbl9s.tagName).toBe("LABEL");
    expect(lbl9s.classList).toContain("form-label");
    expect(lbl9s.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lbl9s")
    );
  });
  test("Custom Test Cases for lbl9s", () => {
    // START_USER_CODE-USER_lbl9s_TEST
    // END_USER_CODE-USER_lbl9s_TEST
  });
  test("lblPurchase(Label Widget) Test Cases", async () => {
    const lblPurchase = screen.getByTestId("lblPurchase");
    expect(lblPurchase.tagName).toBe("LABEL");
    expect(lblPurchase.classList).toContain("form-label");
    expect(lblPurchase.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lblPurchase")
    );
  });
  test("Custom Test Cases for lblPurchase", () => {
    // START_USER_CODE-USER_lblPurchase_TEST
    // END_USER_CODE-USER_lblPurchase_TEST
  });
  test("lblShareAmountPrchs(Label Widget) Test Cases", async () => {
    const lblShareAmountPrchs = screen.getByTestId("lblShareAmountPrchs");
    expect(lblShareAmountPrchs.tagName).toBe("LABEL");
    expect(lblShareAmountPrchs.classList).toContain("form-label");
    expect(lblShareAmountPrchs.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lblShareAmountPrchs")
    );
  });
  test("Custom Test Cases for lblShareAmountPrchs", () => {
    // START_USER_CODE-USER_lblShareAmountPrchs_TEST
    // END_USER_CODE-USER_lblShareAmountPrchs_TEST
  });
  test("lblShareAmountStrg(Label Widget) Test Cases", async () => {
    const lblShareAmountStrg = screen.getByTestId("lblShareAmountStrg");
    expect(lblShareAmountStrg.tagName).toBe("LABEL");
    expect(lblShareAmountStrg.classList).toContain("form-label");
    expect(lblShareAmountStrg.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lblShareAmountStrg")
    );
  });
  test("Custom Test Cases for lblShareAmountStrg", () => {
    // START_USER_CODE-USER_lblShareAmountStrg_TEST
    // END_USER_CODE-USER_lblShareAmountStrg_TEST
  });
  test("lblSharePctPrchs(Label Widget) Test Cases", async () => {
    const lblSharePctPrchs = screen.getByTestId("lblSharePctPrchs");
    expect(lblSharePctPrchs.tagName).toBe("LABEL");
    expect(lblSharePctPrchs.classList).toContain("form-label");
    expect(lblSharePctPrchs.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lblSharePctPrchs")
    );
  });
  test("Custom Test Cases for lblSharePctPrchs", () => {
    // START_USER_CODE-USER_lblSharePctPrchs_TEST
    // END_USER_CODE-USER_lblSharePctPrchs_TEST
  });
  test("lblSharePctStrg(Label Widget) Test Cases", async () => {
    const lblSharePctStrg = screen.getByTestId("lblSharePctStrg");
    expect(lblSharePctStrg.tagName).toBe("LABEL");
    expect(lblSharePctStrg.classList).toContain("form-label");
    expect(lblSharePctStrg.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lblSharePctStrg")
    );
  });
  test("Custom Test Cases for lblSharePctStrg", () => {
    // START_USER_CODE-USER_lblSharePctStrg_TEST
    // END_USER_CODE-USER_lblSharePctStrg_TEST
  });
  test("lblStorage(Label Widget) Test Cases", async () => {
    const lblStorage = screen.getByTestId("lblStorage");
    expect(lblStorage.tagName).toBe("LABEL");
    expect(lblStorage.classList).toContain("form-label");
    expect(lblStorage.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lblStorage")
    );
  });
  test("Custom Test Cases for lblStorage", () => {
    // START_USER_CODE-USER_lblStorage_TEST
    // END_USER_CODE-USER_lblStorage_TEST
  });
  test("lblVendorPrchs(Label Widget) Test Cases", async () => {
    const lblVendorPrchs = screen.getByTestId("lblVendorPrchs");
    expect(lblVendorPrchs.tagName).toBe("LABEL");
    expect(lblVendorPrchs.classList).toContain("form-label");
    expect(lblVendorPrchs.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lblVendorPrchs")
    );
  });
  test("Custom Test Cases for lblVendorPrchs", () => {
    // START_USER_CODE-USER_lblVendorPrchs_TEST
    // END_USER_CODE-USER_lblVendorPrchs_TEST
  });
  test("lblVendorStrg(Label Widget) Test Cases", async () => {
    const lblVendorStrg = screen.getByTestId("lblVendorStrg");
    expect(lblVendorStrg.tagName).toBe("LABEL");
    expect(lblVendorStrg.classList).toContain("form-label");
    expect(lblVendorStrg.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_lblVendorStrg")
    );
  });
  test("Custom Test Cases for lblVendorStrg", () => {
    // START_USER_CODE-USER_lblVendorStrg_TEST
    // END_USER_CODE-USER_lblVendorStrg_TEST
  });
  test("txtShareAmount1(Textbox Widget) Test Cases", async () => {
    const txtShareAmount1 = screen.getByTestId("txtShareAmount1");
    expect(txtShareAmount1.tagName).toBe("INPUT");
    expect(txtShareAmount1.type).toBe("text");
    expect(txtShareAmount1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount1, "123");
    });
    expect(txtShareAmount1.getAttribute("value")).toBe("");
    expect(txtShareAmount1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount1", () => {
    // START_USER_CODE-USER_txtShareAmount1_TEST
    // END_USER_CODE-USER_txtShareAmount1_TEST
  });
  test("txtShareAmount10(Textbox Widget) Test Cases", async () => {
    const txtShareAmount10 = screen.getByTestId("txtShareAmount10");
    expect(txtShareAmount10.tagName).toBe("INPUT");
    expect(txtShareAmount10.type).toBe("text");
    expect(txtShareAmount10.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount10, "123");
    });
    expect(txtShareAmount10.getAttribute("value")).toBe("");
    expect(txtShareAmount10.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount10", () => {
    // START_USER_CODE-USER_txtShareAmount10_TEST
    // END_USER_CODE-USER_txtShareAmount10_TEST
  });
  test("txtShareAmount10s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount10s = screen.getByTestId("txtShareAmount10s");
    expect(txtShareAmount10s.tagName).toBe("INPUT");
    expect(txtShareAmount10s.type).toBe("text");
    expect(txtShareAmount10s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount10s, "123");
    });
    expect(txtShareAmount10s.getAttribute("value")).toBe("");
    expect(txtShareAmount10s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount10s", () => {
    // START_USER_CODE-USER_txtShareAmount10s_TEST
    // END_USER_CODE-USER_txtShareAmount10s_TEST
  });
  test("txtShareAmount11(Textbox Widget) Test Cases", async () => {
    const txtShareAmount11 = screen.getByTestId("txtShareAmount11");
    expect(txtShareAmount11.tagName).toBe("INPUT");
    expect(txtShareAmount11.type).toBe("text");
    expect(txtShareAmount11.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount11, "123");
    });
    expect(txtShareAmount11.getAttribute("value")).toBe("");
    expect(txtShareAmount11.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount11", () => {
    // START_USER_CODE-USER_txtShareAmount11_TEST
    // END_USER_CODE-USER_txtShareAmount11_TEST
  });
  test("txtShareAmount11s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount11s = screen.getByTestId("txtShareAmount11s");
    expect(txtShareAmount11s.tagName).toBe("INPUT");
    expect(txtShareAmount11s.type).toBe("text");
    expect(txtShareAmount11s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount11s, "123");
    });
    expect(txtShareAmount11s.getAttribute("value")).toBe("");
    expect(txtShareAmount11s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount11s", () => {
    // START_USER_CODE-USER_txtShareAmount11s_TEST
    // END_USER_CODE-USER_txtShareAmount11s_TEST
  });
  test("txtShareAmount12(Textbox Widget) Test Cases", async () => {
    const txtShareAmount12 = screen.getByTestId("txtShareAmount12");
    expect(txtShareAmount12.tagName).toBe("INPUT");
    expect(txtShareAmount12.type).toBe("text");
    expect(txtShareAmount12.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount12, "123");
    });
    expect(txtShareAmount12.getAttribute("value")).toBe("");
    expect(txtShareAmount12.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount12", () => {
    // START_USER_CODE-USER_txtShareAmount12_TEST
    // END_USER_CODE-USER_txtShareAmount12_TEST
  });
  test("txtShareAmount12s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount12s = screen.getByTestId("txtShareAmount12s");
    expect(txtShareAmount12s.tagName).toBe("INPUT");
    expect(txtShareAmount12s.type).toBe("text");
    expect(txtShareAmount12s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount12s, "123");
    });
    expect(txtShareAmount12s.getAttribute("value")).toBe("");
    expect(txtShareAmount12s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount12s", () => {
    // START_USER_CODE-USER_txtShareAmount12s_TEST
    // END_USER_CODE-USER_txtShareAmount12s_TEST
  });
  test("txtShareAmount13(Textbox Widget) Test Cases", async () => {
    const txtShareAmount13 = screen.getByTestId("txtShareAmount13");
    expect(txtShareAmount13.tagName).toBe("INPUT");
    expect(txtShareAmount13.type).toBe("text");
    expect(txtShareAmount13.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount13, "123");
    });
    expect(txtShareAmount13.getAttribute("value")).toBe("");
    expect(txtShareAmount13.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount13", () => {
    // START_USER_CODE-USER_txtShareAmount13_TEST
    // END_USER_CODE-USER_txtShareAmount13_TEST
  });
  test("txtShareAmount13s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount13s = screen.getByTestId("txtShareAmount13s");
    expect(txtShareAmount13s.tagName).toBe("INPUT");
    expect(txtShareAmount13s.type).toBe("text");
    expect(txtShareAmount13s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount13s, "123");
    });
    expect(txtShareAmount13s.getAttribute("value")).toBe("");
    expect(txtShareAmount13s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount13s", () => {
    // START_USER_CODE-USER_txtShareAmount13s_TEST
    // END_USER_CODE-USER_txtShareAmount13s_TEST
  });
  test("txtShareAmount14(Textbox Widget) Test Cases", async () => {
    const txtShareAmount14 = screen.getByTestId("txtShareAmount14");
    expect(txtShareAmount14.tagName).toBe("INPUT");
    expect(txtShareAmount14.type).toBe("text");
    expect(txtShareAmount14.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount14, "123");
    });
    expect(txtShareAmount14.getAttribute("value")).toBe("");
    expect(txtShareAmount14.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount14", () => {
    // START_USER_CODE-USER_txtShareAmount14_TEST
    // END_USER_CODE-USER_txtShareAmount14_TEST
  });
  test("txtShareAmount14s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount14s = screen.getByTestId("txtShareAmount14s");
    expect(txtShareAmount14s.tagName).toBe("INPUT");
    expect(txtShareAmount14s.type).toBe("text");
    expect(txtShareAmount14s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount14s, "123");
    });
    expect(txtShareAmount14s.getAttribute("value")).toBe("");
    expect(txtShareAmount14s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount14s", () => {
    // START_USER_CODE-USER_txtShareAmount14s_TEST
    // END_USER_CODE-USER_txtShareAmount14s_TEST
  });
  test("txtShareAmount15(Textbox Widget) Test Cases", async () => {
    const txtShareAmount15 = screen.getByTestId("txtShareAmount15");
    expect(txtShareAmount15.tagName).toBe("INPUT");
    expect(txtShareAmount15.type).toBe("text");
    expect(txtShareAmount15.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount15, "123");
    });
    expect(txtShareAmount15.getAttribute("value")).toBe("");
    expect(txtShareAmount15.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount15", () => {
    // START_USER_CODE-USER_txtShareAmount15_TEST
    // END_USER_CODE-USER_txtShareAmount15_TEST
  });
  test("txtShareAmount15s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount15s = screen.getByTestId("txtShareAmount15s");
    expect(txtShareAmount15s.tagName).toBe("INPUT");
    expect(txtShareAmount15s.type).toBe("text");
    expect(txtShareAmount15s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount15s, "123");
    });
    expect(txtShareAmount15s.getAttribute("value")).toBe("");
    expect(txtShareAmount15s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount15s", () => {
    // START_USER_CODE-USER_txtShareAmount15s_TEST
    // END_USER_CODE-USER_txtShareAmount15s_TEST
  });
  test("txtShareAmount16(Textbox Widget) Test Cases", async () => {
    const txtShareAmount16 = screen.getByTestId("txtShareAmount16");
    expect(txtShareAmount16.tagName).toBe("INPUT");
    expect(txtShareAmount16.type).toBe("text");
    expect(txtShareAmount16.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount16, "123");
    });
    expect(txtShareAmount16.getAttribute("value")).toBe("");
    expect(txtShareAmount16.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount16", () => {
    // START_USER_CODE-USER_txtShareAmount16_TEST
    // END_USER_CODE-USER_txtShareAmount16_TEST
  });
  test("txtShareAmount16s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount16s = screen.getByTestId("txtShareAmount16s");
    expect(txtShareAmount16s.tagName).toBe("INPUT");
    expect(txtShareAmount16s.type).toBe("text");
    expect(txtShareAmount16s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount16s, "123");
    });
    expect(txtShareAmount16s.getAttribute("value")).toBe("");
    expect(txtShareAmount16s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount16s", () => {
    // START_USER_CODE-USER_txtShareAmount16s_TEST
    // END_USER_CODE-USER_txtShareAmount16s_TEST
  });
  test("txtShareAmount17(Textbox Widget) Test Cases", async () => {
    const txtShareAmount17 = screen.getByTestId("txtShareAmount17");
    expect(txtShareAmount17.tagName).toBe("INPUT");
    expect(txtShareAmount17.type).toBe("text");
    expect(txtShareAmount17.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount17, "123");
    });
    expect(txtShareAmount17.getAttribute("value")).toBe("");
    expect(txtShareAmount17.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount17", () => {
    // START_USER_CODE-USER_txtShareAmount17_TEST
    // END_USER_CODE-USER_txtShareAmount17_TEST
  });
  test("txtShareAmount17s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount17s = screen.getByTestId("txtShareAmount17s");
    expect(txtShareAmount17s.tagName).toBe("INPUT");
    expect(txtShareAmount17s.type).toBe("text");
    expect(txtShareAmount17s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount17s, "123");
    });
    expect(txtShareAmount17s.getAttribute("value")).toBe("");
    expect(txtShareAmount17s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount17s", () => {
    // START_USER_CODE-USER_txtShareAmount17s_TEST
    // END_USER_CODE-USER_txtShareAmount17s_TEST
  });
  test("txtShareAmount18(Textbox Widget) Test Cases", async () => {
    const txtShareAmount18 = screen.getByTestId("txtShareAmount18");
    expect(txtShareAmount18.tagName).toBe("INPUT");
    expect(txtShareAmount18.type).toBe("text");
    expect(txtShareAmount18.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount18, "123");
    });
    expect(txtShareAmount18.getAttribute("value")).toBe("");
    expect(txtShareAmount18.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount18", () => {
    // START_USER_CODE-USER_txtShareAmount18_TEST
    // END_USER_CODE-USER_txtShareAmount18_TEST
  });
  test("txtShareAmount18s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount18s = screen.getByTestId("txtShareAmount18s");
    expect(txtShareAmount18s.tagName).toBe("INPUT");
    expect(txtShareAmount18s.type).toBe("text");
    expect(txtShareAmount18s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount18s, "123");
    });
    expect(txtShareAmount18s.getAttribute("value")).toBe("");
    expect(txtShareAmount18s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount18s", () => {
    // START_USER_CODE-USER_txtShareAmount18s_TEST
    // END_USER_CODE-USER_txtShareAmount18s_TEST
  });
  test("txtShareAmount19(Textbox Widget) Test Cases", async () => {
    const txtShareAmount19 = screen.getByTestId("txtShareAmount19");
    expect(txtShareAmount19.tagName).toBe("INPUT");
    expect(txtShareAmount19.type).toBe("text");
    expect(txtShareAmount19.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount19, "123");
    });
    expect(txtShareAmount19.getAttribute("value")).toBe("");
    expect(txtShareAmount19.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount19", () => {
    // START_USER_CODE-USER_txtShareAmount19_TEST
    // END_USER_CODE-USER_txtShareAmount19_TEST
  });
  test("txtShareAmount19s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount19s = screen.getByTestId("txtShareAmount19s");
    expect(txtShareAmount19s.tagName).toBe("INPUT");
    expect(txtShareAmount19s.type).toBe("text");
    expect(txtShareAmount19s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount19s, "123");
    });
    expect(txtShareAmount19s.getAttribute("value")).toBe("");
    expect(txtShareAmount19s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount19s", () => {
    // START_USER_CODE-USER_txtShareAmount19s_TEST
    // END_USER_CODE-USER_txtShareAmount19s_TEST
  });
  test("txtShareAmount1s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount1s = screen.getByTestId("txtShareAmount1s");
    expect(txtShareAmount1s.tagName).toBe("INPUT");
    expect(txtShareAmount1s.type).toBe("text");
    expect(txtShareAmount1s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount1s, "123");
    });
    expect(txtShareAmount1s.getAttribute("value")).toBe("");
    expect(txtShareAmount1s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount1s", () => {
    // START_USER_CODE-USER_txtShareAmount1s_TEST
    // END_USER_CODE-USER_txtShareAmount1s_TEST
  });
  test("txtShareAmount2(Textbox Widget) Test Cases", async () => {
    const txtShareAmount2 = screen.getByTestId("txtShareAmount2");
    expect(txtShareAmount2.tagName).toBe("INPUT");
    expect(txtShareAmount2.type).toBe("text");
    expect(txtShareAmount2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount2, "123");
    });
    expect(txtShareAmount2.getAttribute("value")).toBe("");
    expect(txtShareAmount2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount2", () => {
    // START_USER_CODE-USER_txtShareAmount2_TEST
    // END_USER_CODE-USER_txtShareAmount2_TEST
  });
  test("txtShareAmount20(Textbox Widget) Test Cases", async () => {
    const txtShareAmount20 = screen.getByTestId("txtShareAmount20");
    expect(txtShareAmount20.tagName).toBe("INPUT");
    expect(txtShareAmount20.type).toBe("text");
    expect(txtShareAmount20.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount20, "123");
    });
    expect(txtShareAmount20.getAttribute("value")).toBe("");
    expect(txtShareAmount20.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount20", () => {
    // START_USER_CODE-USER_txtShareAmount20_TEST
    // END_USER_CODE-USER_txtShareAmount20_TEST
  });
  test("txtShareAmount20s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount20s = screen.getByTestId("txtShareAmount20s");
    expect(txtShareAmount20s.tagName).toBe("INPUT");
    expect(txtShareAmount20s.type).toBe("text");
    expect(txtShareAmount20s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount20s, "123");
    });
    expect(txtShareAmount20s.getAttribute("value")).toBe("");
    expect(txtShareAmount20s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount20s", () => {
    // START_USER_CODE-USER_txtShareAmount20s_TEST
    // END_USER_CODE-USER_txtShareAmount20s_TEST
  });
  test("txtShareAmount2s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount2s = screen.getByTestId("txtShareAmount2s");
    expect(txtShareAmount2s.tagName).toBe("INPUT");
    expect(txtShareAmount2s.type).toBe("text");
    expect(txtShareAmount2s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount2s, "123");
    });
    expect(txtShareAmount2s.getAttribute("value")).toBe("");
    expect(txtShareAmount2s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount2s", () => {
    // START_USER_CODE-USER_txtShareAmount2s_TEST
    // END_USER_CODE-USER_txtShareAmount2s_TEST
  });
  test("txtShareAmount3(Textbox Widget) Test Cases", async () => {
    const txtShareAmount3 = screen.getByTestId("txtShareAmount3");
    expect(txtShareAmount3.tagName).toBe("INPUT");
    expect(txtShareAmount3.type).toBe("text");
    expect(txtShareAmount3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount3, "123");
    });
    expect(txtShareAmount3.getAttribute("value")).toBe("");
    expect(txtShareAmount3.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount3", () => {
    // START_USER_CODE-USER_txtShareAmount3_TEST
    // END_USER_CODE-USER_txtShareAmount3_TEST
  });
  test("txtShareAmount3s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount3s = screen.getByTestId("txtShareAmount3s");
    expect(txtShareAmount3s.tagName).toBe("INPUT");
    expect(txtShareAmount3s.type).toBe("text");
    expect(txtShareAmount3s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount3s, "123");
    });
    expect(txtShareAmount3s.getAttribute("value")).toBe("");
    expect(txtShareAmount3s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount3s", () => {
    // START_USER_CODE-USER_txtShareAmount3s_TEST
    // END_USER_CODE-USER_txtShareAmount3s_TEST
  });
  test("txtShareAmount4(Textbox Widget) Test Cases", async () => {
    const txtShareAmount4 = screen.getByTestId("txtShareAmount4");
    expect(txtShareAmount4.tagName).toBe("INPUT");
    expect(txtShareAmount4.type).toBe("text");
    expect(txtShareAmount4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount4, "123");
    });
    expect(txtShareAmount4.getAttribute("value")).toBe("");
    expect(txtShareAmount4.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount4", () => {
    // START_USER_CODE-USER_txtShareAmount4_TEST
    // END_USER_CODE-USER_txtShareAmount4_TEST
  });
  test("txtShareAmount4s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount4s = screen.getByTestId("txtShareAmount4s");
    expect(txtShareAmount4s.tagName).toBe("INPUT");
    expect(txtShareAmount4s.type).toBe("text");
    expect(txtShareAmount4s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount4s, "123");
    });
    expect(txtShareAmount4s.getAttribute("value")).toBe("");
    expect(txtShareAmount4s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount4s", () => {
    // START_USER_CODE-USER_txtShareAmount4s_TEST
    // END_USER_CODE-USER_txtShareAmount4s_TEST
  });
  test("txtShareAmount5(Textbox Widget) Test Cases", async () => {
    const txtShareAmount5 = screen.getByTestId("txtShareAmount5");
    expect(txtShareAmount5.tagName).toBe("INPUT");
    expect(txtShareAmount5.type).toBe("text");
    expect(txtShareAmount5.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount5, "123");
    });
    expect(txtShareAmount5.getAttribute("value")).toBe("");
    expect(txtShareAmount5.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount5", () => {
    // START_USER_CODE-USER_txtShareAmount5_TEST
    // END_USER_CODE-USER_txtShareAmount5_TEST
  });
  test("txtShareAmount5s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount5s = screen.getByTestId("txtShareAmount5s");
    expect(txtShareAmount5s.tagName).toBe("INPUT");
    expect(txtShareAmount5s.type).toBe("text");
    expect(txtShareAmount5s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount5s, "123");
    });
    expect(txtShareAmount5s.getAttribute("value")).toBe("");
    expect(txtShareAmount5s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount5s", () => {
    // START_USER_CODE-USER_txtShareAmount5s_TEST
    // END_USER_CODE-USER_txtShareAmount5s_TEST
  });
  test("txtShareAmount6(Textbox Widget) Test Cases", async () => {
    const txtShareAmount6 = screen.getByTestId("txtShareAmount6");
    expect(txtShareAmount6.tagName).toBe("INPUT");
    expect(txtShareAmount6.type).toBe("text");
    expect(txtShareAmount6.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount6, "123");
    });
    expect(txtShareAmount6.getAttribute("value")).toBe("");
    expect(txtShareAmount6.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount6", () => {
    // START_USER_CODE-USER_txtShareAmount6_TEST
    // END_USER_CODE-USER_txtShareAmount6_TEST
  });
  test("txtShareAmount6s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount6s = screen.getByTestId("txtShareAmount6s");
    expect(txtShareAmount6s.tagName).toBe("INPUT");
    expect(txtShareAmount6s.type).toBe("text");
    expect(txtShareAmount6s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount6s, "123");
    });
    expect(txtShareAmount6s.getAttribute("value")).toBe("");
    expect(txtShareAmount6s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount6s", () => {
    // START_USER_CODE-USER_txtShareAmount6s_TEST
    // END_USER_CODE-USER_txtShareAmount6s_TEST
  });
  test("txtShareAmount7(Textbox Widget) Test Cases", async () => {
    const txtShareAmount7 = screen.getByTestId("txtShareAmount7");
    expect(txtShareAmount7.tagName).toBe("INPUT");
    expect(txtShareAmount7.type).toBe("text");
    expect(txtShareAmount7.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount7, "123");
    });
    expect(txtShareAmount7.getAttribute("value")).toBe("");
    expect(txtShareAmount7.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount7", () => {
    // START_USER_CODE-USER_txtShareAmount7_TEST
    // END_USER_CODE-USER_txtShareAmount7_TEST
  });
  test("txtShareAmount7s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount7s = screen.getByTestId("txtShareAmount7s");
    expect(txtShareAmount7s.tagName).toBe("INPUT");
    expect(txtShareAmount7s.type).toBe("text");
    expect(txtShareAmount7s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount7s, "123");
    });
    expect(txtShareAmount7s.getAttribute("value")).toBe("");
    expect(txtShareAmount7s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount7s", () => {
    // START_USER_CODE-USER_txtShareAmount7s_TEST
    // END_USER_CODE-USER_txtShareAmount7s_TEST
  });
  test("txtShareAmount8(Textbox Widget) Test Cases", async () => {
    const txtShareAmount8 = screen.getByTestId("txtShareAmount8");
    expect(txtShareAmount8.tagName).toBe("INPUT");
    expect(txtShareAmount8.type).toBe("text");
    expect(txtShareAmount8.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount8, "123");
    });
    expect(txtShareAmount8.getAttribute("value")).toBe("");
    expect(txtShareAmount8.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount8", () => {
    // START_USER_CODE-USER_txtShareAmount8_TEST
    // END_USER_CODE-USER_txtShareAmount8_TEST
  });
  test("txtShareAmount8s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount8s = screen.getByTestId("txtShareAmount8s");
    expect(txtShareAmount8s.tagName).toBe("INPUT");
    expect(txtShareAmount8s.type).toBe("text");
    expect(txtShareAmount8s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount8s, "123");
    });
    expect(txtShareAmount8s.getAttribute("value")).toBe("");
    expect(txtShareAmount8s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount8s", () => {
    // START_USER_CODE-USER_txtShareAmount8s_TEST
    // END_USER_CODE-USER_txtShareAmount8s_TEST
  });
  test("txtShareAmount9(Textbox Widget) Test Cases", async () => {
    const txtShareAmount9 = screen.getByTestId("txtShareAmount9");
    expect(txtShareAmount9.tagName).toBe("INPUT");
    expect(txtShareAmount9.type).toBe("text");
    expect(txtShareAmount9.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount9, "123");
    });
    expect(txtShareAmount9.getAttribute("value")).toBe("");
    expect(txtShareAmount9.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount9", () => {
    // START_USER_CODE-USER_txtShareAmount9_TEST
    // END_USER_CODE-USER_txtShareAmount9_TEST
  });
  test("txtShareAmount9s(Textbox Widget) Test Cases", async () => {
    const txtShareAmount9s = screen.getByTestId("txtShareAmount9s");
    expect(txtShareAmount9s.tagName).toBe("INPUT");
    expect(txtShareAmount9s.type).toBe("text");
    expect(txtShareAmount9s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtShareAmount9s, "123");
    });
    expect(txtShareAmount9s.getAttribute("value")).toBe("");
    expect(txtShareAmount9s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtShareAmount9s", () => {
    // START_USER_CODE-USER_txtShareAmount9s_TEST
    // END_USER_CODE-USER_txtShareAmount9s_TEST
  });
  test("txtSharePct1(Textbox Widget) Test Cases", async () => {
    const txtSharePct1 = screen.getByTestId("txtSharePct1");
    expect(txtSharePct1.tagName).toBe("INPUT");
    expect(txtSharePct1.type).toBe("text");
    expect(txtSharePct1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct1, "1");
    });
  });
  test("Custom Test Cases for txtSharePct1", () => {
    // START_USER_CODE-USER_txtSharePct1_TEST
    // END_USER_CODE-USER_txtSharePct1_TEST
  });
  test("txtSharePct10(Textbox Widget) Test Cases", async () => {
    const txtSharePct10 = screen.getByTestId("txtSharePct10");
    expect(txtSharePct10.tagName).toBe("INPUT");
    expect(txtSharePct10.type).toBe("text");
    expect(txtSharePct10.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct10, "1");
    });
  });
  test("Custom Test Cases for txtSharePct10", () => {
    // START_USER_CODE-USER_txtSharePct10_TEST
    // END_USER_CODE-USER_txtSharePct10_TEST
  });
  test("txtSharePct10s(Textbox Widget) Test Cases", async () => {
    const txtSharePct10s = screen.getByTestId("txtSharePct10s");
    expect(txtSharePct10s.tagName).toBe("INPUT");
    expect(txtSharePct10s.type).toBe("text");
    expect(txtSharePct10s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct10s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct10s", () => {
    // START_USER_CODE-USER_txtSharePct10s_TEST
    // END_USER_CODE-USER_txtSharePct10s_TEST
  });
  test("txtSharePct11(Textbox Widget) Test Cases", async () => {
    const txtSharePct11 = screen.getByTestId("txtSharePct11");
    expect(txtSharePct11.tagName).toBe("INPUT");
    expect(txtSharePct11.type).toBe("text");
    expect(txtSharePct11.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct11, "1");
    });
  });
  test("Custom Test Cases for txtSharePct11", () => {
    // START_USER_CODE-USER_txtSharePct11_TEST
    // END_USER_CODE-USER_txtSharePct11_TEST
  });
  test("txtSharePct11s(Textbox Widget) Test Cases", async () => {
    const txtSharePct11s = screen.getByTestId("txtSharePct11s");
    expect(txtSharePct11s.tagName).toBe("INPUT");
    expect(txtSharePct11s.type).toBe("text");
    expect(txtSharePct11s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct11s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct11s", () => {
    // START_USER_CODE-USER_txtSharePct11s_TEST
    // END_USER_CODE-USER_txtSharePct11s_TEST
  });
  test("txtSharePct12(Textbox Widget) Test Cases", async () => {
    const txtSharePct12 = screen.getByTestId("txtSharePct12");
    expect(txtSharePct12.tagName).toBe("INPUT");
    expect(txtSharePct12.type).toBe("text");
    expect(txtSharePct12.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct12, "1");
    });
  });
  test("Custom Test Cases for txtSharePct12", () => {
    // START_USER_CODE-USER_txtSharePct12_TEST
    // END_USER_CODE-USER_txtSharePct12_TEST
  });
  test("txtSharePct12s(Textbox Widget) Test Cases", async () => {
    const txtSharePct12s = screen.getByTestId("txtSharePct12s");
    expect(txtSharePct12s.tagName).toBe("INPUT");
    expect(txtSharePct12s.type).toBe("text");
    expect(txtSharePct12s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct12s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct12s", () => {
    // START_USER_CODE-USER_txtSharePct12s_TEST
    // END_USER_CODE-USER_txtSharePct12s_TEST
  });
  test("txtSharePct13(Textbox Widget) Test Cases", async () => {
    const txtSharePct13 = screen.getByTestId("txtSharePct13");
    expect(txtSharePct13.tagName).toBe("INPUT");
    expect(txtSharePct13.type).toBe("text");
    expect(txtSharePct13.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct13, "1");
    });
  });
  test("Custom Test Cases for txtSharePct13", () => {
    // START_USER_CODE-USER_txtSharePct13_TEST
    // END_USER_CODE-USER_txtSharePct13_TEST
  });
  test("txtSharePct13s(Textbox Widget) Test Cases", async () => {
    const txtSharePct13s = screen.getByTestId("txtSharePct13s");
    expect(txtSharePct13s.tagName).toBe("INPUT");
    expect(txtSharePct13s.type).toBe("text");
    expect(txtSharePct13s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct13s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct13s", () => {
    // START_USER_CODE-USER_txtSharePct13s_TEST
    // END_USER_CODE-USER_txtSharePct13s_TEST
  });
  test("txtSharePct14(Textbox Widget) Test Cases", async () => {
    const txtSharePct14 = screen.getByTestId("txtSharePct14");
    expect(txtSharePct14.tagName).toBe("INPUT");
    expect(txtSharePct14.type).toBe("text");
    expect(txtSharePct14.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct14, "1");
    });
  });
  test("Custom Test Cases for txtSharePct14", () => {
    // START_USER_CODE-USER_txtSharePct14_TEST
    // END_USER_CODE-USER_txtSharePct14_TEST
  });
  test("txtSharePct14s(Textbox Widget) Test Cases", async () => {
    const txtSharePct14s = screen.getByTestId("txtSharePct14s");
    expect(txtSharePct14s.tagName).toBe("INPUT");
    expect(txtSharePct14s.type).toBe("text");
    expect(txtSharePct14s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct14s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct14s", () => {
    // START_USER_CODE-USER_txtSharePct14s_TEST
    // END_USER_CODE-USER_txtSharePct14s_TEST
  });
  test("txtSharePct15(Textbox Widget) Test Cases", async () => {
    const txtSharePct15 = screen.getByTestId("txtSharePct15");
    expect(txtSharePct15.tagName).toBe("INPUT");
    expect(txtSharePct15.type).toBe("text");
    expect(txtSharePct15.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct15, "1");
    });
  });
  test("Custom Test Cases for txtSharePct15", () => {
    // START_USER_CODE-USER_txtSharePct15_TEST
    // END_USER_CODE-USER_txtSharePct15_TEST
  });
  test("txtSharePct15s(Textbox Widget) Test Cases", async () => {
    const txtSharePct15s = screen.getByTestId("txtSharePct15s");
    expect(txtSharePct15s.tagName).toBe("INPUT");
    expect(txtSharePct15s.type).toBe("text");
    expect(txtSharePct15s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct15s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct15s", () => {
    // START_USER_CODE-USER_txtSharePct15s_TEST
    // END_USER_CODE-USER_txtSharePct15s_TEST
  });
  test("txtSharePct16(Textbox Widget) Test Cases", async () => {
    const txtSharePct16 = screen.getByTestId("txtSharePct16");
    expect(txtSharePct16.tagName).toBe("INPUT");
    expect(txtSharePct16.type).toBe("text");
    expect(txtSharePct16.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct16, "1");
    });
  });
  test("Custom Test Cases for txtSharePct16", () => {
    // START_USER_CODE-USER_txtSharePct16_TEST
    // END_USER_CODE-USER_txtSharePct16_TEST
  });
  test("txtSharePct16s(Textbox Widget) Test Cases", async () => {
    const txtSharePct16s = screen.getByTestId("txtSharePct16s");
    expect(txtSharePct16s.tagName).toBe("INPUT");
    expect(txtSharePct16s.type).toBe("text");
    expect(txtSharePct16s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct16s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct16s", () => {
    // START_USER_CODE-USER_txtSharePct16s_TEST
    // END_USER_CODE-USER_txtSharePct16s_TEST
  });
  test("txtSharePct17(Textbox Widget) Test Cases", async () => {
    const txtSharePct17 = screen.getByTestId("txtSharePct17");
    expect(txtSharePct17.tagName).toBe("INPUT");
    expect(txtSharePct17.type).toBe("text");
    expect(txtSharePct17.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct17, "1");
    });
  });
  test("Custom Test Cases for txtSharePct17", () => {
    // START_USER_CODE-USER_txtSharePct17_TEST
    // END_USER_CODE-USER_txtSharePct17_TEST
  });
  test("txtSharePct17s(Textbox Widget) Test Cases", async () => {
    const txtSharePct17s = screen.getByTestId("txtSharePct17s");
    expect(txtSharePct17s.tagName).toBe("INPUT");
    expect(txtSharePct17s.type).toBe("text");
    expect(txtSharePct17s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct17s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct17s", () => {
    // START_USER_CODE-USER_txtSharePct17s_TEST
    // END_USER_CODE-USER_txtSharePct17s_TEST
  });
  test("txtSharePct18(Textbox Widget) Test Cases", async () => {
    const txtSharePct18 = screen.getByTestId("txtSharePct18");
    expect(txtSharePct18.tagName).toBe("INPUT");
    expect(txtSharePct18.type).toBe("text");
    expect(txtSharePct18.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct18, "1");
    });
  });
  test("Custom Test Cases for txtSharePct18", () => {
    // START_USER_CODE-USER_txtSharePct18_TEST
    // END_USER_CODE-USER_txtSharePct18_TEST
  });
  test("txtSharePct18s(Textbox Widget) Test Cases", async () => {
    const txtSharePct18s = screen.getByTestId("txtSharePct18s");
    expect(txtSharePct18s.tagName).toBe("INPUT");
    expect(txtSharePct18s.type).toBe("text");
    expect(txtSharePct18s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct18s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct18s", () => {
    // START_USER_CODE-USER_txtSharePct18s_TEST
    // END_USER_CODE-USER_txtSharePct18s_TEST
  });
  test("txtSharePct19(Textbox Widget) Test Cases", async () => {
    const txtSharePct19 = screen.getByTestId("txtSharePct19");
    expect(txtSharePct19.tagName).toBe("INPUT");
    expect(txtSharePct19.type).toBe("text");
    expect(txtSharePct19.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct19, "1");
    });
  });
  test("Custom Test Cases for txtSharePct19", () => {
    // START_USER_CODE-USER_txtSharePct19_TEST
    // END_USER_CODE-USER_txtSharePct19_TEST
  });
  test("txtSharePct19s(Textbox Widget) Test Cases", async () => {
    const txtSharePct19s = screen.getByTestId("txtSharePct19s");
    expect(txtSharePct19s.tagName).toBe("INPUT");
    expect(txtSharePct19s.type).toBe("text");
    expect(txtSharePct19s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct19s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct19s", () => {
    // START_USER_CODE-USER_txtSharePct19s_TEST
    // END_USER_CODE-USER_txtSharePct19s_TEST
  });
  test("txtSharePct1s(Textbox Widget) Test Cases", async () => {
    const txtSharePct1s = screen.getByTestId("txtSharePct1s");
    expect(txtSharePct1s.tagName).toBe("INPUT");
    expect(txtSharePct1s.type).toBe("text");
    expect(txtSharePct1s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct1s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct1s", () => {
    // START_USER_CODE-USER_txtSharePct1s_TEST
    // END_USER_CODE-USER_txtSharePct1s_TEST
  });
  test("txtSharePct2(Textbox Widget) Test Cases", async () => {
    const txtSharePct2 = screen.getByTestId("txtSharePct2");
    expect(txtSharePct2.tagName).toBe("INPUT");
    expect(txtSharePct2.type).toBe("text");
    expect(txtSharePct2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct2, "1");
    });
  });
  test("Custom Test Cases for txtSharePct2", () => {
    // START_USER_CODE-USER_txtSharePct2_TEST
    // END_USER_CODE-USER_txtSharePct2_TEST
  });
  test("txtSharePct20(Textbox Widget) Test Cases", async () => {
    const txtSharePct20 = screen.getByTestId("txtSharePct20");
    expect(txtSharePct20.tagName).toBe("INPUT");
    expect(txtSharePct20.type).toBe("text");
    expect(txtSharePct20.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct20, "1");
    });
  });
  test("Custom Test Cases for txtSharePct20", () => {
    // START_USER_CODE-USER_txtSharePct20_TEST
    // END_USER_CODE-USER_txtSharePct20_TEST
  });
  test("txtSharePct20s(Textbox Widget) Test Cases", async () => {
    const txtSharePct20s = screen.getByTestId("txtSharePct20s");
    expect(txtSharePct20s.tagName).toBe("INPUT");
    expect(txtSharePct20s.type).toBe("text");
    expect(txtSharePct20s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct20s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct20s", () => {
    // START_USER_CODE-USER_txtSharePct20s_TEST
    // END_USER_CODE-USER_txtSharePct20s_TEST
  });
  test("txtSharePct2s(Textbox Widget) Test Cases", async () => {
    const txtSharePct2s = screen.getByTestId("txtSharePct2s");
    expect(txtSharePct2s.tagName).toBe("INPUT");
    expect(txtSharePct2s.type).toBe("text");
    expect(txtSharePct2s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct2s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct2s", () => {
    // START_USER_CODE-USER_txtSharePct2s_TEST
    // END_USER_CODE-USER_txtSharePct2s_TEST
  });
  test("txtSharePct3(Textbox Widget) Test Cases", async () => {
    const txtSharePct3 = screen.getByTestId("txtSharePct3");
    expect(txtSharePct3.tagName).toBe("INPUT");
    expect(txtSharePct3.type).toBe("text");
    expect(txtSharePct3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct3, "1");
    });
  });
  test("Custom Test Cases for txtSharePct3", () => {
    // START_USER_CODE-USER_txtSharePct3_TEST
    // END_USER_CODE-USER_txtSharePct3_TEST
  });
  test("txtSharePct3s(Textbox Widget) Test Cases", async () => {
    const txtSharePct3s = screen.getByTestId("txtSharePct3s");
    expect(txtSharePct3s.tagName).toBe("INPUT");
    expect(txtSharePct3s.type).toBe("text");
    expect(txtSharePct3s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct3s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct3s", () => {
    // START_USER_CODE-USER_txtSharePct3s_TEST
    // END_USER_CODE-USER_txtSharePct3s_TEST
  });
  test("txtSharePct4(Textbox Widget) Test Cases", async () => {
    const txtSharePct4 = screen.getByTestId("txtSharePct4");
    expect(txtSharePct4.tagName).toBe("INPUT");
    expect(txtSharePct4.type).toBe("text");
    expect(txtSharePct4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct4, "1");
    });
  });
  test("Custom Test Cases for txtSharePct4", () => {
    // START_USER_CODE-USER_txtSharePct4_TEST
    // END_USER_CODE-USER_txtSharePct4_TEST
  });
  test("txtSharePct4s(Textbox Widget) Test Cases", async () => {
    const txtSharePct4s = screen.getByTestId("txtSharePct4s");
    expect(txtSharePct4s.tagName).toBe("INPUT");
    expect(txtSharePct4s.type).toBe("text");
    expect(txtSharePct4s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct4s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct4s", () => {
    // START_USER_CODE-USER_txtSharePct4s_TEST
    // END_USER_CODE-USER_txtSharePct4s_TEST
  });
  test("txtSharePct5(Textbox Widget) Test Cases", async () => {
    const txtSharePct5 = screen.getByTestId("txtSharePct5");
    expect(txtSharePct5.tagName).toBe("INPUT");
    expect(txtSharePct5.type).toBe("text");
    expect(txtSharePct5.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct5, "1");
    });
  });
  test("Custom Test Cases for txtSharePct5", () => {
    // START_USER_CODE-USER_txtSharePct5_TEST
    // END_USER_CODE-USER_txtSharePct5_TEST
  });
  test("txtSharePct5s(Textbox Widget) Test Cases", async () => {
    const txtSharePct5s = screen.getByTestId("txtSharePct5s");
    expect(txtSharePct5s.tagName).toBe("INPUT");
    expect(txtSharePct5s.type).toBe("text");
    expect(txtSharePct5s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct5s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct5s", () => {
    // START_USER_CODE-USER_txtSharePct5s_TEST
    // END_USER_CODE-USER_txtSharePct5s_TEST
  });
  test("txtSharePct6(Textbox Widget) Test Cases", async () => {
    const txtSharePct6 = screen.getByTestId("txtSharePct6");
    expect(txtSharePct6.tagName).toBe("INPUT");
    expect(txtSharePct6.type).toBe("text");
    expect(txtSharePct6.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct6, "1");
    });
  });
  test("Custom Test Cases for txtSharePct6", () => {
    // START_USER_CODE-USER_txtSharePct6_TEST
    // END_USER_CODE-USER_txtSharePct6_TEST
  });
  test("txtSharePct6s(Textbox Widget) Test Cases", async () => {
    const txtSharePct6s = screen.getByTestId("txtSharePct6s");
    expect(txtSharePct6s.tagName).toBe("INPUT");
    expect(txtSharePct6s.type).toBe("text");
    expect(txtSharePct6s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct6s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct6s", () => {
    // START_USER_CODE-USER_txtSharePct6s_TEST
    // END_USER_CODE-USER_txtSharePct6s_TEST
  });
  test("txtSharePct7(Textbox Widget) Test Cases", async () => {
    const txtSharePct7 = screen.getByTestId("txtSharePct7");
    expect(txtSharePct7.tagName).toBe("INPUT");
    expect(txtSharePct7.type).toBe("text");
    expect(txtSharePct7.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct7, "1");
    });
  });
  test("Custom Test Cases for txtSharePct7", () => {
    // START_USER_CODE-USER_txtSharePct7_TEST
    // END_USER_CODE-USER_txtSharePct7_TEST
  });
  test("txtSharePct7s(Textbox Widget) Test Cases", async () => {
    const txtSharePct7s = screen.getByTestId("txtSharePct7s");
    expect(txtSharePct7s.tagName).toBe("INPUT");
    expect(txtSharePct7s.type).toBe("text");
    expect(txtSharePct7s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct7s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct7s", () => {
    // START_USER_CODE-USER_txtSharePct7s_TEST
    // END_USER_CODE-USER_txtSharePct7s_TEST
  });
  test("txtSharePct8(Textbox Widget) Test Cases", async () => {
    const txtSharePct8 = screen.getByTestId("txtSharePct8");
    expect(txtSharePct8.tagName).toBe("INPUT");
    expect(txtSharePct8.type).toBe("text");
    expect(txtSharePct8.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct8, "1");
    });
  });
  test("Custom Test Cases for txtSharePct8", () => {
    // START_USER_CODE-USER_txtSharePct8_TEST
    // END_USER_CODE-USER_txtSharePct8_TEST
  });
  test("txtSharePct8s(Textbox Widget) Test Cases", async () => {
    const txtSharePct8s = screen.getByTestId("txtSharePct8s");
    expect(txtSharePct8s.tagName).toBe("INPUT");
    expect(txtSharePct8s.type).toBe("text");
    expect(txtSharePct8s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct8s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct8s", () => {
    // START_USER_CODE-USER_txtSharePct8s_TEST
    // END_USER_CODE-USER_txtSharePct8s_TEST
  });
  test("txtSharePct9(Textbox Widget) Test Cases", async () => {
    const txtSharePct9 = screen.getByTestId("txtSharePct9");
    expect(txtSharePct9.tagName).toBe("INPUT");
    expect(txtSharePct9.type).toBe("text");
    expect(txtSharePct9.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct9, "1");
    });
  });
  test("Custom Test Cases for txtSharePct9", () => {
    // START_USER_CODE-USER_txtSharePct9_TEST
    // END_USER_CODE-USER_txtSharePct9_TEST
  });
  test("txtSharePct9s(Textbox Widget) Test Cases", async () => {
    const txtSharePct9s = screen.getByTestId("txtSharePct9s");
    expect(txtSharePct9s.tagName).toBe("INPUT");
    expect(txtSharePct9s.type).toBe("text");
    expect(txtSharePct9s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePct9s, "1");
    });
  });
  test("Custom Test Cases for txtSharePct9s", () => {
    // START_USER_CODE-USER_txtSharePct9s_TEST
    // END_USER_CODE-USER_txtSharePct9s_TEST
  });
  test("txtTotalShareAmount(Textbox Widget) Test Cases", async () => {
    const txtTotalShareAmount = screen.getByTestId("txtTotalShareAmount");
    expect(txtTotalShareAmount.tagName).toBe("INPUT");
    expect(txtTotalShareAmount.type).toBe("text");
    expect(txtTotalShareAmount.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalShareAmount, "123");
    });
    expect(txtTotalShareAmount.getAttribute("value")).toBe("");
    expect(txtTotalShareAmount.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalShareAmount", () => {
    // START_USER_CODE-USER_txtTotalShareAmount_TEST
    // END_USER_CODE-USER_txtTotalShareAmount_TEST
  });
  test("txtTotalShareAmounts(Textbox Widget) Test Cases", async () => {
    const txtTotalShareAmounts = screen.getByTestId("txtTotalShareAmounts");
    expect(txtTotalShareAmounts.tagName).toBe("INPUT");
    expect(txtTotalShareAmounts.type).toBe("text");
    expect(txtTotalShareAmounts.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalShareAmounts, "123");
    });
    expect(txtTotalShareAmounts.getAttribute("value")).toBe("");
    expect(txtTotalShareAmounts.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalShareAmounts", () => {
    // START_USER_CODE-USER_txtTotalShareAmounts_TEST
    // END_USER_CODE-USER_txtTotalShareAmounts_TEST
  });
  test("txtTotalSharePct(Textbox Widget) Test Cases", async () => {
    const txtTotalSharePct = screen.getByTestId("txtTotalSharePct");
    expect(txtTotalSharePct.tagName).toBe("INPUT");
    expect(txtTotalSharePct.type).toBe("text");
    expect(txtTotalSharePct.classList).toContain("textboxWidgetClass");
    expect(txtTotalSharePct.previousElementSibling.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_txtTotalSharePct")
    );
    await act(async () => {
      userEvent.type(txtTotalSharePct, "123");
    });
    expect(txtTotalSharePct.getAttribute("value")).toBe("");
    expect(txtTotalSharePct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalSharePct", () => {
    // START_USER_CODE-USER_txtTotalSharePct_TEST
    // END_USER_CODE-USER_txtTotalSharePct_TEST
  });
  test("txtTotalSharePcts(Textbox Widget) Test Cases", async () => {
    const txtTotalSharePcts = screen.getByTestId("txtTotalSharePcts");
    expect(txtTotalSharePcts.tagName).toBe("INPUT");
    expect(txtTotalSharePcts.type).toBe("text");
    expect(txtTotalSharePcts.classList).toContain("textboxWidgetClass");
    expect(txtTotalSharePcts.previousElementSibling.textContent).toEqual(
      t("Settlements:PremiumDeductionsVendorSplits_txtTotalSharePcts")
    );
    await act(async () => {
      userEvent.type(txtTotalSharePcts, "123");
    });
    expect(txtTotalSharePcts.getAttribute("value")).toBe("");
    expect(txtTotalSharePcts.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalSharePcts", () => {
    // START_USER_CODE-USER_txtTotalSharePcts_TEST
    // END_USER_CODE-USER_txtTotalSharePcts_TEST
  });
  test("txtVendor1(Textbox Widget) Test Cases", async () => {
    const txtVendor1 = screen.getByTestId("txtVendor1");
    expect(txtVendor1.tagName).toBe("INPUT");
    expect(txtVendor1.type).toBe("text");
    expect(txtVendor1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor1, "123");
    });
    expect(txtVendor1.getAttribute("value")).toBe("");
    expect(txtVendor1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor1", () => {
    // START_USER_CODE-USER_txtVendor1_TEST
    // END_USER_CODE-USER_txtVendor1_TEST
  });
  test("txtVendor10(Textbox Widget) Test Cases", async () => {
    const txtVendor10 = screen.getByTestId("txtVendor10");
    expect(txtVendor10.tagName).toBe("INPUT");
    expect(txtVendor10.type).toBe("text");
    expect(txtVendor10.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor10, "123");
    });
    expect(txtVendor10.getAttribute("value")).toBe("");
    expect(txtVendor10.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor10", () => {
    // START_USER_CODE-USER_txtVendor10_TEST
    // END_USER_CODE-USER_txtVendor10_TEST
  });
  test("txtVendor10s(Textbox Widget) Test Cases", async () => {
    const txtVendor10s = screen.getByTestId("txtVendor10s");
    expect(txtVendor10s.tagName).toBe("INPUT");
    expect(txtVendor10s.type).toBe("text");
    expect(txtVendor10s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor10s, "123");
    });
    expect(txtVendor10s.getAttribute("value")).toBe("");
    expect(txtVendor10s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor10s", () => {
    // START_USER_CODE-USER_txtVendor10s_TEST
    // END_USER_CODE-USER_txtVendor10s_TEST
  });
  test("txtVendor11(Textbox Widget) Test Cases", async () => {
    const txtVendor11 = screen.getByTestId("txtVendor11");
    expect(txtVendor11.tagName).toBe("INPUT");
    expect(txtVendor11.type).toBe("text");
    expect(txtVendor11.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor11, "123");
    });
    expect(txtVendor11.getAttribute("value")).toBe("");
    expect(txtVendor11.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor11", () => {
    // START_USER_CODE-USER_txtVendor11_TEST
    // END_USER_CODE-USER_txtVendor11_TEST
  });
  test("txtVendor11s(Textbox Widget) Test Cases", async () => {
    const txtVendor11s = screen.getByTestId("txtVendor11s");
    expect(txtVendor11s.tagName).toBe("INPUT");
    expect(txtVendor11s.type).toBe("text");
    expect(txtVendor11s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor11s, "123");
    });
    expect(txtVendor11s.getAttribute("value")).toBe("");
    expect(txtVendor11s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor11s", () => {
    // START_USER_CODE-USER_txtVendor11s_TEST
    // END_USER_CODE-USER_txtVendor11s_TEST
  });
  test("txtVendor12(Textbox Widget) Test Cases", async () => {
    const txtVendor12 = screen.getByTestId("txtVendor12");
    expect(txtVendor12.tagName).toBe("INPUT");
    expect(txtVendor12.type).toBe("text");
    expect(txtVendor12.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor12, "123");
    });
    expect(txtVendor12.getAttribute("value")).toBe("");
    expect(txtVendor12.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor12", () => {
    // START_USER_CODE-USER_txtVendor12_TEST
    // END_USER_CODE-USER_txtVendor12_TEST
  });
  test("txtVendor12s(Textbox Widget) Test Cases", async () => {
    const txtVendor12s = screen.getByTestId("txtVendor12s");
    expect(txtVendor12s.tagName).toBe("INPUT");
    expect(txtVendor12s.type).toBe("text");
    expect(txtVendor12s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor12s, "123");
    });
    expect(txtVendor12s.getAttribute("value")).toBe("");
    expect(txtVendor12s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor12s", () => {
    // START_USER_CODE-USER_txtVendor12s_TEST
    // END_USER_CODE-USER_txtVendor12s_TEST
  });
  test("txtVendor13(Textbox Widget) Test Cases", async () => {
    const txtVendor13 = screen.getByTestId("txtVendor13");
    expect(txtVendor13.tagName).toBe("INPUT");
    expect(txtVendor13.type).toBe("text");
    expect(txtVendor13.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor13, "123");
    });
    expect(txtVendor13.getAttribute("value")).toBe("");
    expect(txtVendor13.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor13", () => {
    // START_USER_CODE-USER_txtVendor13_TEST
    // END_USER_CODE-USER_txtVendor13_TEST
  });
  test("txtVendor13s(Textbox Widget) Test Cases", async () => {
    const txtVendor13s = screen.getByTestId("txtVendor13s");
    expect(txtVendor13s.tagName).toBe("INPUT");
    expect(txtVendor13s.type).toBe("text");
    expect(txtVendor13s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor13s, "123");
    });
    expect(txtVendor13s.getAttribute("value")).toBe("");
    expect(txtVendor13s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor13s", () => {
    // START_USER_CODE-USER_txtVendor13s_TEST
    // END_USER_CODE-USER_txtVendor13s_TEST
  });
  test("txtVendor14(Textbox Widget) Test Cases", async () => {
    const txtVendor14 = screen.getByTestId("txtVendor14");
    expect(txtVendor14.tagName).toBe("INPUT");
    expect(txtVendor14.type).toBe("text");
    expect(txtVendor14.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor14, "123");
    });
    expect(txtVendor14.getAttribute("value")).toBe("");
    expect(txtVendor14.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor14", () => {
    // START_USER_CODE-USER_txtVendor14_TEST
    // END_USER_CODE-USER_txtVendor14_TEST
  });
  test("txtVendor14s(Textbox Widget) Test Cases", async () => {
    const txtVendor14s = screen.getByTestId("txtVendor14s");
    expect(txtVendor14s.tagName).toBe("INPUT");
    expect(txtVendor14s.type).toBe("text");
    expect(txtVendor14s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor14s, "123");
    });
    expect(txtVendor14s.getAttribute("value")).toBe("");
    expect(txtVendor14s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor14s", () => {
    // START_USER_CODE-USER_txtVendor14s_TEST
    // END_USER_CODE-USER_txtVendor14s_TEST
  });
  test("txtVendor15(Textbox Widget) Test Cases", async () => {
    const txtVendor15 = screen.getByTestId("txtVendor15");
    expect(txtVendor15.tagName).toBe("INPUT");
    expect(txtVendor15.type).toBe("text");
    expect(txtVendor15.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor15, "123");
    });
    expect(txtVendor15.getAttribute("value")).toBe("");
    expect(txtVendor15.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor15", () => {
    // START_USER_CODE-USER_txtVendor15_TEST
    // END_USER_CODE-USER_txtVendor15_TEST
  });
  test("txtVendor15s(Textbox Widget) Test Cases", async () => {
    const txtVendor15s = screen.getByTestId("txtVendor15s");
    expect(txtVendor15s.tagName).toBe("INPUT");
    expect(txtVendor15s.type).toBe("text");
    expect(txtVendor15s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor15s, "123");
    });
    expect(txtVendor15s.getAttribute("value")).toBe("");
    expect(txtVendor15s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor15s", () => {
    // START_USER_CODE-USER_txtVendor15s_TEST
    // END_USER_CODE-USER_txtVendor15s_TEST
  });
  test("txtVendor16(Textbox Widget) Test Cases", async () => {
    const txtVendor16 = screen.getByTestId("txtVendor16");
    expect(txtVendor16.tagName).toBe("INPUT");
    expect(txtVendor16.type).toBe("text");
    expect(txtVendor16.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor16, "123");
    });
    expect(txtVendor16.getAttribute("value")).toBe("");
    expect(txtVendor16.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor16", () => {
    // START_USER_CODE-USER_txtVendor16_TEST
    // END_USER_CODE-USER_txtVendor16_TEST
  });
  test("txtVendor16s(Textbox Widget) Test Cases", async () => {
    const txtVendor16s = screen.getByTestId("txtVendor16s");
    expect(txtVendor16s.tagName).toBe("INPUT");
    expect(txtVendor16s.type).toBe("text");
    expect(txtVendor16s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor16s, "123");
    });
    expect(txtVendor16s.getAttribute("value")).toBe("");
    expect(txtVendor16s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor16s", () => {
    // START_USER_CODE-USER_txtVendor16s_TEST
    // END_USER_CODE-USER_txtVendor16s_TEST
  });
  test("txtVendor17(Textbox Widget) Test Cases", async () => {
    const txtVendor17 = screen.getByTestId("txtVendor17");
    expect(txtVendor17.tagName).toBe("INPUT");
    expect(txtVendor17.type).toBe("text");
    expect(txtVendor17.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor17, "123");
    });
    expect(txtVendor17.getAttribute("value")).toBe("");
    expect(txtVendor17.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor17", () => {
    // START_USER_CODE-USER_txtVendor17_TEST
    // END_USER_CODE-USER_txtVendor17_TEST
  });
  test("txtVendor17s(Textbox Widget) Test Cases", async () => {
    const txtVendor17s = screen.getByTestId("txtVendor17s");
    expect(txtVendor17s.tagName).toBe("INPUT");
    expect(txtVendor17s.type).toBe("text");
    expect(txtVendor17s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor17s, "123");
    });
    expect(txtVendor17s.getAttribute("value")).toBe("");
    expect(txtVendor17s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor17s", () => {
    // START_USER_CODE-USER_txtVendor17s_TEST
    // END_USER_CODE-USER_txtVendor17s_TEST
  });
  test("txtVendor18(Textbox Widget) Test Cases", async () => {
    const txtVendor18 = screen.getByTestId("txtVendor18");
    expect(txtVendor18.tagName).toBe("INPUT");
    expect(txtVendor18.type).toBe("text");
    expect(txtVendor18.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor18, "123");
    });
    expect(txtVendor18.getAttribute("value")).toBe("");
    expect(txtVendor18.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor18", () => {
    // START_USER_CODE-USER_txtVendor18_TEST
    // END_USER_CODE-USER_txtVendor18_TEST
  });
  test("txtVendor18s(Textbox Widget) Test Cases", async () => {
    const txtVendor18s = screen.getByTestId("txtVendor18s");
    expect(txtVendor18s.tagName).toBe("INPUT");
    expect(txtVendor18s.type).toBe("text");
    expect(txtVendor18s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor18s, "123");
    });
    expect(txtVendor18s.getAttribute("value")).toBe("");
    expect(txtVendor18s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor18s", () => {
    // START_USER_CODE-USER_txtVendor18s_TEST
    // END_USER_CODE-USER_txtVendor18s_TEST
  });
  test("txtVendor19(Textbox Widget) Test Cases", async () => {
    const txtVendor19 = screen.getByTestId("txtVendor19");
    expect(txtVendor19.tagName).toBe("INPUT");
    expect(txtVendor19.type).toBe("text");
    expect(txtVendor19.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor19, "123");
    });
    expect(txtVendor19.getAttribute("value")).toBe("");
    expect(txtVendor19.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor19", () => {
    // START_USER_CODE-USER_txtVendor19_TEST
    // END_USER_CODE-USER_txtVendor19_TEST
  });
  test("txtVendor19s(Textbox Widget) Test Cases", async () => {
    const txtVendor19s = screen.getByTestId("txtVendor19s");
    expect(txtVendor19s.tagName).toBe("INPUT");
    expect(txtVendor19s.type).toBe("text");
    expect(txtVendor19s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor19s, "123");
    });
    expect(txtVendor19s.getAttribute("value")).toBe("");
    expect(txtVendor19s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor19s", () => {
    // START_USER_CODE-USER_txtVendor19s_TEST
    // END_USER_CODE-USER_txtVendor19s_TEST
  });
  test("txtVendor1s(Textbox Widget) Test Cases", async () => {
    const txtVendor1s = screen.getByTestId("txtVendor1s");
    expect(txtVendor1s.tagName).toBe("INPUT");
    expect(txtVendor1s.type).toBe("text");
    expect(txtVendor1s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor1s, "123");
    });
    expect(txtVendor1s.getAttribute("value")).toBe("");
    expect(txtVendor1s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor1s", () => {
    // START_USER_CODE-USER_txtVendor1s_TEST
    // END_USER_CODE-USER_txtVendor1s_TEST
  });
  test("txtVendor2(Textbox Widget) Test Cases", async () => {
    const txtVendor2 = screen.getByTestId("txtVendor2");
    expect(txtVendor2.tagName).toBe("INPUT");
    expect(txtVendor2.type).toBe("text");
    expect(txtVendor2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor2, "123");
    });
    expect(txtVendor2.getAttribute("value")).toBe("");
    expect(txtVendor2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor2", () => {
    // START_USER_CODE-USER_txtVendor2_TEST
    // END_USER_CODE-USER_txtVendor2_TEST
  });
  test("txtVendor20(Textbox Widget) Test Cases", async () => {
    const txtVendor20 = screen.getByTestId("txtVendor20");
    expect(txtVendor20.tagName).toBe("INPUT");
    expect(txtVendor20.type).toBe("text");
    expect(txtVendor20.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor20, "123");
    });
    expect(txtVendor20.getAttribute("value")).toBe("");
    expect(txtVendor20.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor20", () => {
    // START_USER_CODE-USER_txtVendor20_TEST
    // END_USER_CODE-USER_txtVendor20_TEST
  });
  test("txtVendor20s(Textbox Widget) Test Cases", async () => {
    const txtVendor20s = screen.getByTestId("txtVendor20s");
    expect(txtVendor20s.tagName).toBe("INPUT");
    expect(txtVendor20s.type).toBe("text");
    expect(txtVendor20s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor20s, "123");
    });
    expect(txtVendor20s.getAttribute("value")).toBe("");
    expect(txtVendor20s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor20s", () => {
    // START_USER_CODE-USER_txtVendor20s_TEST
    // END_USER_CODE-USER_txtVendor20s_TEST
  });
  test("txtVendor2s(Textbox Widget) Test Cases", async () => {
    const txtVendor2s = screen.getByTestId("txtVendor2s");
    expect(txtVendor2s.tagName).toBe("INPUT");
    expect(txtVendor2s.type).toBe("text");
    expect(txtVendor2s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor2s, "123");
    });
    expect(txtVendor2s.getAttribute("value")).toBe("");
    expect(txtVendor2s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor2s", () => {
    // START_USER_CODE-USER_txtVendor2s_TEST
    // END_USER_CODE-USER_txtVendor2s_TEST
  });
  test("txtVendor3(Textbox Widget) Test Cases", async () => {
    const txtVendor3 = screen.getByTestId("txtVendor3");
    expect(txtVendor3.tagName).toBe("INPUT");
    expect(txtVendor3.type).toBe("text");
    expect(txtVendor3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor3, "123");
    });
    expect(txtVendor3.getAttribute("value")).toBe("");
    expect(txtVendor3.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor3", () => {
    // START_USER_CODE-USER_txtVendor3_TEST
    // END_USER_CODE-USER_txtVendor3_TEST
  });
  test("txtVendor3s(Textbox Widget) Test Cases", async () => {
    const txtVendor3s = screen.getByTestId("txtVendor3s");
    expect(txtVendor3s.tagName).toBe("INPUT");
    expect(txtVendor3s.type).toBe("text");
    expect(txtVendor3s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor3s, "123");
    });
    expect(txtVendor3s.getAttribute("value")).toBe("");
    expect(txtVendor3s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor3s", () => {
    // START_USER_CODE-USER_txtVendor3s_TEST
    // END_USER_CODE-USER_txtVendor3s_TEST
  });
  test("txtVendor4(Textbox Widget) Test Cases", async () => {
    const txtVendor4 = screen.getByTestId("txtVendor4");
    expect(txtVendor4.tagName).toBe("INPUT");
    expect(txtVendor4.type).toBe("text");
    expect(txtVendor4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor4, "123");
    });
    expect(txtVendor4.getAttribute("value")).toBe("");
    expect(txtVendor4.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor4", () => {
    // START_USER_CODE-USER_txtVendor4_TEST
    // END_USER_CODE-USER_txtVendor4_TEST
  });
  test("txtVendor4s(Textbox Widget) Test Cases", async () => {
    const txtVendor4s = screen.getByTestId("txtVendor4s");
    expect(txtVendor4s.tagName).toBe("INPUT");
    expect(txtVendor4s.type).toBe("text");
    expect(txtVendor4s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor4s, "123");
    });
    expect(txtVendor4s.getAttribute("value")).toBe("");
    expect(txtVendor4s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor4s", () => {
    // START_USER_CODE-USER_txtVendor4s_TEST
    // END_USER_CODE-USER_txtVendor4s_TEST
  });
  test("txtVendor5(Textbox Widget) Test Cases", async () => {
    const txtVendor5 = screen.getByTestId("txtVendor5");
    expect(txtVendor5.tagName).toBe("INPUT");
    expect(txtVendor5.type).toBe("text");
    expect(txtVendor5.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor5, "123");
    });
    expect(txtVendor5.getAttribute("value")).toBe("");
    expect(txtVendor5.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor5", () => {
    // START_USER_CODE-USER_txtVendor5_TEST
    // END_USER_CODE-USER_txtVendor5_TEST
  });
  test("txtVendor5s(Textbox Widget) Test Cases", async () => {
    const txtVendor5s = screen.getByTestId("txtVendor5s");
    expect(txtVendor5s.tagName).toBe("INPUT");
    expect(txtVendor5s.type).toBe("text");
    expect(txtVendor5s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor5s, "123");
    });
    expect(txtVendor5s.getAttribute("value")).toBe("");
    expect(txtVendor5s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor5s", () => {
    // START_USER_CODE-USER_txtVendor5s_TEST
    // END_USER_CODE-USER_txtVendor5s_TEST
  });
  test("txtVendor6(Textbox Widget) Test Cases", async () => {
    const txtVendor6 = screen.getByTestId("txtVendor6");
    expect(txtVendor6.tagName).toBe("INPUT");
    expect(txtVendor6.type).toBe("text");
    expect(txtVendor6.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor6, "123");
    });
    expect(txtVendor6.getAttribute("value")).toBe("");
    expect(txtVendor6.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor6", () => {
    // START_USER_CODE-USER_txtVendor6_TEST
    // END_USER_CODE-USER_txtVendor6_TEST
  });
  test("txtVendor6s(Textbox Widget) Test Cases", async () => {
    const txtVendor6s = screen.getByTestId("txtVendor6s");
    expect(txtVendor6s.tagName).toBe("INPUT");
    expect(txtVendor6s.type).toBe("text");
    expect(txtVendor6s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor6s, "123");
    });
    expect(txtVendor6s.getAttribute("value")).toBe("");
    expect(txtVendor6s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor6s", () => {
    // START_USER_CODE-USER_txtVendor6s_TEST
    // END_USER_CODE-USER_txtVendor6s_TEST
  });
  test("txtVendor7(Textbox Widget) Test Cases", async () => {
    const txtVendor7 = screen.getByTestId("txtVendor7");
    expect(txtVendor7.tagName).toBe("INPUT");
    expect(txtVendor7.type).toBe("text");
    expect(txtVendor7.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor7, "123");
    });
    expect(txtVendor7.getAttribute("value")).toBe("");
    expect(txtVendor7.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor7", () => {
    // START_USER_CODE-USER_txtVendor7_TEST
    // END_USER_CODE-USER_txtVendor7_TEST
  });
  test("txtVendor7s(Textbox Widget) Test Cases", async () => {
    const txtVendor7s = screen.getByTestId("txtVendor7s");
    expect(txtVendor7s.tagName).toBe("INPUT");
    expect(txtVendor7s.type).toBe("text");
    expect(txtVendor7s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor7s, "123");
    });
    expect(txtVendor7s.getAttribute("value")).toBe("");
    expect(txtVendor7s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor7s", () => {
    // START_USER_CODE-USER_txtVendor7s_TEST
    // END_USER_CODE-USER_txtVendor7s_TEST
  });
  test("txtVendor8(Textbox Widget) Test Cases", async () => {
    const txtVendor8 = screen.getByTestId("txtVendor8");
    expect(txtVendor8.tagName).toBe("INPUT");
    expect(txtVendor8.type).toBe("text");
    expect(txtVendor8.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor8, "123");
    });
    expect(txtVendor8.getAttribute("value")).toBe("");
    expect(txtVendor8.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor8", () => {
    // START_USER_CODE-USER_txtVendor8_TEST
    // END_USER_CODE-USER_txtVendor8_TEST
  });
  test("txtVendor8s(Textbox Widget) Test Cases", async () => {
    const txtVendor8s = screen.getByTestId("txtVendor8s");
    expect(txtVendor8s.tagName).toBe("INPUT");
    expect(txtVendor8s.type).toBe("text");
    expect(txtVendor8s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor8s, "123");
    });
    expect(txtVendor8s.getAttribute("value")).toBe("");
    expect(txtVendor8s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor8s", () => {
    // START_USER_CODE-USER_txtVendor8s_TEST
    // END_USER_CODE-USER_txtVendor8s_TEST
  });
  test("txtVendor9(Textbox Widget) Test Cases", async () => {
    const txtVendor9 = screen.getByTestId("txtVendor9");
    expect(txtVendor9.tagName).toBe("INPUT");
    expect(txtVendor9.type).toBe("text");
    expect(txtVendor9.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor9, "123");
    });
    expect(txtVendor9.getAttribute("value")).toBe("");
    expect(txtVendor9.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor9", () => {
    // START_USER_CODE-USER_txtVendor9_TEST
    // END_USER_CODE-USER_txtVendor9_TEST
  });
  test("txtVendor9s(Textbox Widget) Test Cases", async () => {
    const txtVendor9s = screen.getByTestId("txtVendor9s");
    expect(txtVendor9s.tagName).toBe("INPUT");
    expect(txtVendor9s.type).toBe("text");
    expect(txtVendor9s.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor9s, "123");
    });
    expect(txtVendor9s.getAttribute("value")).toBe("");
    expect(txtVendor9s.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor9s", () => {
    // START_USER_CODE-USER_txtVendor9s_TEST
    // END_USER_CODE-USER_txtVendor9s_TEST
  });
});
