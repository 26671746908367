/* eslint-disable*/
import React from 'react';
import "../Loader/Loading.scss";

function Loading(props) {
    if (props.loading == true) {
        return (
            <div className="container">
                <div className="loader-container">
                    <div className="spinner">
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default Loading;