/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_OpenAccountsProfile from "./OpenAccountsProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("OpenAccountsProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_OpenAccountsProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is OpenAccountsProfile Loads Successfully", () => {
    expect(screen.getByText("OpenAccountsProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for OpenAccountsProfile", () => {
    // START_USER_CODE-USER_OpenAccountsProfile_Custom_Test_Case
    // END_USER_CODE-USER_OpenAccountsProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_OpenAccountsProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnAccountVendor(Button Widget) Test Cases", async () => {
    const btnAccountVendor = screen.getByTestId("btnAccountVendor");
    expect(btnAccountVendor).toBeInTheDocument;
    expect(btnAccountVendor.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_btnAccountVendor"
      )
    );
  });
  test("Custom Test Cases for btnAccountVendor", () => {
    // START_USER_CODE-USER_btnAccountVendor_TEST
    // END_USER_CODE-USER_btnAccountVendor_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountsProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnDeductionTracking(Button Widget) Test Cases", async () => {
    const btnDeductionTracking = screen.getByTestId(
      "btnDeductionTracking"
    );
    expect(btnDeductionTracking).toBeInTheDocument;
    expect(btnDeductionTracking.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_btnDeductionTracking"
      )
    );
  });
  test("Custom Test Cases for btnDeductionTracking", () => {
    // START_USER_CODE-USER_btnDeductionTracking_TEST
    // END_USER_CODE-USER_btnDeductionTracking_TEST
  });
  test("btnMakeAdj(Button Widget) Test Cases", async () => {
    const btnMakeAdj = screen.getByTestId("btnMakeAdj");
    expect(btnMakeAdj).toBeInTheDocument;
    expect(btnMakeAdj.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_btnMakeAdj"
      )
    );
  });
  test("Custom Test Cases for btnMakeAdj", () => {
    // START_USER_CODE-USER_btnMakeAdj_TEST
    // END_USER_CODE-USER_btnMakeAdj_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountsProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("btnViewAdj(Button Widget) Test Cases", async () => {
    const btnViewAdj = screen.getByTestId("btnViewAdj");
    expect(btnViewAdj).toBeInTheDocument;
    expect(btnViewAdj.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_btnViewAdj"
      )
    );
  });
  test("Custom Test Cases for btnViewAdj", () => {
    // START_USER_CODE-USER_btnViewAdj_TEST
    // END_USER_CODE-USER_btnViewAdj_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxOpenAccountsProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxOpenAccountsProfile = screen.getByTestId(
      "grpbxOpenAccountsProfile"
    );
    expect(grpbxOpenAccountsProfile.tagName).toBe("BUTTON");
    expect(grpbxOpenAccountsProfile.type).toBe("button");
    expect(grpbxOpenAccountsProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxOpenAccountsProfile", () => {
    // START_USER_CODE-USER_grpbxOpenAccountsProfile_TEST
    // END_USER_CODE-USER_grpbxOpenAccountsProfile_TEST
  });
  test("lblAcctReceivableAdj(Label Widget) Test Cases", async () => {
    const lblAcctReceivableAdj = screen.getByTestId(
      "lblAcctReceivableAdj"
    );
    expect(lblAcctReceivableAdj.tagName).toBe("LABEL");
    expect(lblAcctReceivableAdj.classList).toContain("form-label");
    expect(lblAcctReceivableAdj.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblAcctReceivableAdj"
      )
    );
  });
  test("Custom Test Cases for lblAcctReceivableAdj", () => {
    // START_USER_CODE-USER_lblAcctReceivableAdj_TEST
    // END_USER_CODE-USER_lblAcctReceivableAdj_TEST
  });
  test("lblAcctReceivableCB(Label Widget) Test Cases", async () => {
    const lblAcctReceivableCB = screen.getByTestId(
      "lblAcctReceivableCB"
    );
    expect(lblAcctReceivableCB.tagName).toBe("LABEL");
    expect(lblAcctReceivableCB.classList).toContain("form-label");
    expect(lblAcctReceivableCB.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblAcctReceivableCB"
      )
    );
  });
  test("Custom Test Cases for lblAcctReceivableCB", () => {
    // START_USER_CODE-USER_lblAcctReceivableCB_TEST
    // END_USER_CODE-USER_lblAcctReceivableCB_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblAddedBy"
      )
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblAdjustments(Label Widget) Test Cases", async () => {
    const lblAdjustments = screen.getByTestId("lblAdjustments");
    expect(lblAdjustments.tagName).toBe("LABEL");
    expect(lblAdjustments.classList).toContain("form-label");
    expect(lblAdjustments.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblAdjustments"
      )
    );
  });
  test("Custom Test Cases for lblAdjustments", () => {
    // START_USER_CODE-USER_lblAdjustments_TEST
    // END_USER_CODE-USER_lblAdjustments_TEST
  });
  test("lblBalances(Label Widget) Test Cases", async () => {
    const lblBalances = screen.getByTestId("lblBalances");
    expect(lblBalances.tagName).toBe("LABEL");
    expect(lblBalances.classList).toContain("form-label");
    expect(lblBalances.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblBalances"
      )
    );
  });
  test("Custom Test Cases for lblBalances", () => {
    // START_USER_CODE-USER_lblBalances_TEST
    // END_USER_CODE-USER_lblBalances_TEST
  });
  test("lblBeginningBalance(Label Widget) Test Cases", async () => {
    const lblBeginningBalance = screen.getByTestId(
      "lblBeginningBalance"
    );
    expect(lblBeginningBalance.tagName).toBe("LABEL");
    expect(lblBeginningBalance.classList).toContain("form-label");
    expect(lblBeginningBalance.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblBeginningBalance"
      )
    );
  });
  test("Custom Test Cases for lblBeginningBalance", () => {
    // START_USER_CODE-USER_lblBeginningBalance_TEST
    // END_USER_CODE-USER_lblBeginningBalance_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblChangedBy"
      )
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblCollectFormSettlement(Label Widget) Test Cases", async () => {
    const lblCollectFormSettlement = screen.getByTestId(
      "lblCollectFormSettlement"
    );
    expect(lblCollectFormSettlement.tagName).toBe("LABEL");
    expect(lblCollectFormSettlement.classList).toContain("form-label");
    expect(lblCollectFormSettlement.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblCollectFormSettlement"
      )
    );
  });
  test("Custom Test Cases for lblCollectFormSettlement", () => {
    // START_USER_CODE-USER_lblCollectFormSettlement_TEST
    // END_USER_CODE-USER_lblCollectFormSettlement_TEST
  });
  test("lblCommReceivableAdj(Label Widget) Test Cases", async () => {
    const lblCommReceivableAdj = screen.getByTestId(
      "lblCommReceivableAdj"
    );
    expect(lblCommReceivableAdj.tagName).toBe("LABEL");
    expect(lblCommReceivableAdj.classList).toContain("form-label");
    expect(lblCommReceivableAdj.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblCommReceivableAdj"
      )
    );
  });
  test("Custom Test Cases for lblCommReceivableAdj", () => {
    // START_USER_CODE-USER_lblCommReceivableAdj_TEST
    // END_USER_CODE-USER_lblCommReceivableAdj_TEST
  });
  test("lblCommReceivableCB(Label Widget) Test Cases", async () => {
    const lblCommReceivableCB = screen.getByTestId(
      "lblCommReceivableCB"
    );
    expect(lblCommReceivableCB.tagName).toBe("LABEL");
    expect(lblCommReceivableCB.classList).toContain("form-label");
    expect(lblCommReceivableCB.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblCommReceivableCB"
      )
    );
  });
  test("Custom Test Cases for lblCommReceivableCB", () => {
    // START_USER_CODE-USER_lblCommReceivableCB_TEST
    // END_USER_CODE-USER_lblCommReceivableCB_TEST
  });
  test("lblCurrentBalance(Label Widget) Test Cases", async () => {
    const lblCurrentBalance = screen.getByTestId("lblCurrentBalance");
    expect(lblCurrentBalance.tagName).toBe("LABEL");
    expect(lblCurrentBalance.classList).toContain("form-label");
    expect(lblCurrentBalance.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblCurrentBalance"
      )
    );
  });
  test("Custom Test Cases for lblCurrentBalance", () => {
    // START_USER_CODE-USER_lblCurrentBalance_TEST
    // END_USER_CODE-USER_lblCurrentBalance_TEST
  });
  test("lblOpenBalanceAdj(Label Widget) Test Cases", async () => {
    const lblOpenBalanceAdj = screen.getByTestId("lblOpenBalanceAdj");
    expect(lblOpenBalanceAdj.tagName).toBe("LABEL");
    expect(lblOpenBalanceAdj.classList).toContain("form-label");
    expect(lblOpenBalanceAdj.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblOpenBalanceAdj"
      )
    );
  });
  test("Custom Test Cases for lblOpenBalanceAdj", () => {
    // START_USER_CODE-USER_lblOpenBalanceAdj_TEST
    // END_USER_CODE-USER_lblOpenBalanceAdj_TEST
  });
  test("lblOpenBalanceBB(Label Widget) Test Cases", async () => {
    const lblOpenBalanceBB = screen.getByTestId("lblOpenBalanceBB");
    expect(lblOpenBalanceBB.tagName).toBe("LABEL");
    expect(lblOpenBalanceBB.classList).toContain("form-label");
    expect(lblOpenBalanceBB.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblOpenBalanceBB"
      )
    );
  });
  test("Custom Test Cases for lblOpenBalanceBB", () => {
    // START_USER_CODE-USER_lblOpenBalanceBB_TEST
    // END_USER_CODE-USER_lblOpenBalanceBB_TEST
  });
  test("lblOpenBalanceCB(Label Widget) Test Cases", async () => {
    const lblOpenBalanceCB = screen.getByTestId("lblOpenBalanceCB");
    expect(lblOpenBalanceCB.tagName).toBe("LABEL");
    expect(lblOpenBalanceCB.classList).toContain("form-label");
    expect(lblOpenBalanceCB.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblOpenBalanceCB"
      )
    );
  });
  test("Custom Test Cases for lblOpenBalanceCB", () => {
    // START_USER_CODE-USER_lblOpenBalanceCB_TEST
    // END_USER_CODE-USER_lblOpenBalanceCB_TEST
  });
  test("lblOpenBalance(Label Widget) Test Cases", async () => {
    const lblOpenBalance = screen.getByTestId("lblOpenBalance");
    expect(lblOpenBalance.tagName).toBe("LABEL");
    expect(lblOpenBalance.classList).toContain("form-label");
    expect(lblOpenBalance.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblOpenBalance"
      )
    );
  });
  test("Custom Test Cases for lblOpenBalance", () => {
    // START_USER_CODE-USER_lblOpenBalance_TEST
    // END_USER_CODE-USER_lblOpenBalance_TEST
  });
  test("lblSeedReceivableAdj(Label Widget) Test Cases", async () => {
    const lblSeedReceivableAdj = screen.getByTestId(
      "lblSeedReceivableAdj"
    );
    expect(lblSeedReceivableAdj.tagName).toBe("LABEL");
    expect(lblSeedReceivableAdj.classList).toContain("form-label");
    expect(lblSeedReceivableAdj.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblSeedReceivableAdj"
      )
    );
  });
  test("Custom Test Cases for lblSeedReceivableAdj", () => {
    // START_USER_CODE-USER_lblSeedReceivableAdj_TEST
    // END_USER_CODE-USER_lblSeedReceivableAdj_TEST
  });
  test("lblSeedReceivableCB(Label Widget) Test Cases", async () => {
    const lblSeedReceivableCB = screen.getByTestId(
      "lblSeedReceivableCB"
    );
    expect(lblSeedReceivableCB.tagName).toBe("LABEL");
    expect(lblSeedReceivableCB.classList).toContain("form-label");
    expect(lblSeedReceivableCB.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_lblSeedReceivableCB"
      )
    );
  });
  test("Custom Test Cases for lblSeedReceivableCB", () => {
    // START_USER_CODE-USER_lblSeedReceivableCB_TEST
    // END_USER_CODE-USER_lblSeedReceivableCB_TEST
  });
  test("txtAccountVendor(Textbox Widget) Test Cases", async () => {
    const txtAccountVendor = screen.getByTestId("txtAccountVendor");
    expect(txtAccountVendor.tagName).toBe("INPUT");
    expect(txtAccountVendor.type).toBe("text");
    expect(txtAccountVendor.classList).toContain("textboxWidgetClass");
    expect(txtAccountVendor.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_txtAccountVendor"
      )
    );
    await act(async () => {
      userEvent.type(txtAccountVendor, "1");
    });
  });
  test("Custom Test Cases for txtAccountVendor", () => {
    // START_USER_CODE-USER_txtAccountVendor_TEST
    // END_USER_CODE-USER_txtAccountVendor_TEST
  });
  test("txtAcctReceivableBB(Textbox Widget) Test Cases", async () => {
    const txtAcctReceivableBB = screen.getByTestId(
      "txtAcctReceivableBB"
    );
    expect(txtAcctReceivableBB.tagName).toBe("INPUT");
    expect(txtAcctReceivableBB.type).toBe("text");
    expect(txtAcctReceivableBB.classList).toContain("textboxWidgetClass");
    expect(txtAcctReceivableBB.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_txtAcctReceivableBB"
      )
    );
    await act(async () => {
      userEvent.type(txtAcctReceivableBB, "1");
    });
  });
  test("Custom Test Cases for txtAcctReceivableBB", () => {
    // START_USER_CODE-USER_txtAcctReceivableBB_TEST
    // END_USER_CODE-USER_txtAcctReceivableBB_TEST
  });
  test("txtAcctVndr(Textbox Widget) Test Cases", async () => {
    const txtAcctVndr = screen.getByTestId("txtAcctVndr");
    expect(txtAcctVndr.tagName).toBe("INPUT");
    expect(txtAcctVndr.type).toBe("text");
    expect(txtAcctVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAcctVndr, "1");
    });
  });
  test("Custom Test Cases for txtAcctVndr", () => {
    // START_USER_CODE-USER_txtAcctVndr_TEST
    // END_USER_CODE-USER_txtAcctVndr_TEST
  });
  test("txtBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtBuyingPoint = screen.getByTestId("txtBuyingPoint");
    expect(txtBuyingPoint.tagName).toBe("INPUT");
    expect(txtBuyingPoint.type).toBe("text");
    expect(txtBuyingPoint.classList).toContain("textboxWidgetClass");
    expect(txtBuyingPoint.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_txtBuyingPoint"
      )
    );
    await act(async () => {
      userEvent.type(txtBuyingPoint, "1");
    });
  });
  test("Custom Test Cases for txtBuyingPoint", () => {
    // START_USER_CODE-USER_txtBuyingPoint_TEST
    // END_USER_CODE-USER_txtBuyingPoint_TEST
  });
  test("txtCollectionAmount(Textbox Widget) Test Cases", async () => {
    const txtCollectionAmount = screen.getByTestId(
      "txtCollectionAmount"
    );
    expect(txtCollectionAmount.tagName).toBe("INPUT");
    expect(txtCollectionAmount.type).toBe("text");
    expect(txtCollectionAmount.classList).toContain("textboxWidgetClass");
    expect(txtCollectionAmount.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_txtCollectionAmount"
      )
    );
    await act(async () => {
      userEvent.type(txtCollectionAmount, "1");
    });
  });
  test("Custom Test Cases for txtCollectionAmount", () => {
    // START_USER_CODE-USER_txtCollectionAmount_TEST
    // END_USER_CODE-USER_txtCollectionAmount_TEST
  });
  test("txtCollectionPercent(Textbox Widget) Test Cases", async () => {
    const txtCollectionPercent = screen.getByTestId(
      "txtCollectionPercent"
    );
    expect(txtCollectionPercent.tagName).toBe("INPUT");
    expect(txtCollectionPercent.type).toBe("text");
    expect(txtCollectionPercent.classList).toContain("textboxWidgetClass");
    expect(txtCollectionPercent.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_txtCollectionPercent"
      )
    );
    await act(async () => {
      userEvent.type(txtCollectionPercent, "1");
    });
  });
  test("Custom Test Cases for txtCollectionPercent", () => {
    // START_USER_CODE-USER_txtCollectionPercent_TEST
    // END_USER_CODE-USER_txtCollectionPercent_TEST
  });
  test("txtCommReceivableBB(Textbox Widget) Test Cases", async () => {
    const txtCommReceivableBB = screen.getByTestId(
      "txtCommReceivableBB"
    );
    expect(txtCommReceivableBB.tagName).toBe("INPUT");
    expect(txtCommReceivableBB.type).toBe("text");
    expect(txtCommReceivableBB.classList).toContain("textboxWidgetClass");
    expect(txtCommReceivableBB.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_txtCommReceivableBB"
      )
    );
    await act(async () => {
      userEvent.type(txtCommReceivableBB, "1");
    });
  });
  test("Custom Test Cases for txtCommReceivableBB", () => {
    // START_USER_CODE-USER_txtCommReceivableBB_TEST
    // END_USER_CODE-USER_txtCommReceivableBB_TEST
  });
  test("txtSeedReceivableBB(Textbox Widget) Test Cases", async () => {
    const txtSeedReceivableBB = screen.getByTestId(
      "txtSeedReceivableBB"
    );
    expect(txtSeedReceivableBB.tagName).toBe("INPUT");
    expect(txtSeedReceivableBB.type).toBe("text");
    expect(txtSeedReceivableBB.classList).toContain("textboxWidgetClass");
    expect(txtSeedReceivableBB.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountsProfile_txtSeedReceivableBB"
      )
    );
    await act(async () => {
      userEvent.type(txtSeedReceivableBB, "1");
    });
  });
  test("Custom Test Cases for txtSeedReceivableBB", () => {
    // START_USER_CODE-USER_txtSeedReceivableBB_TEST
    // END_USER_CODE-USER_txtSeedReceivableBB_TEST
  });
});
