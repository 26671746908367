/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  getValue,
  setData,
  getData,
  LabelWidget
} from "../../shared/WindowImports";

import "./ReviewCorrectionLog.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../Service/SettlementService";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import { response } from "msw";
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from "moment";//For date time

// END_USER_CODE-USER_IMPORTS
function Settlements_ReviewCorrectionLog(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  
  // START_USER_CODE-USER_PROPERTIES
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  var ReviewCorrectLogData = {}
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ReviewCorrectionLog",
    windowName: "ReviewCorrectionLog",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.ReviewCorrectionLog",
    // START_USER_CODE-USER_ReviewCorrectionLog_PROPERTIES
    headerData: {
      scrName: "Review Correction Log",
      scrCode: "PN0160H",
    },
    // END_USER_CODE-USER_ReviewCorrectionLog_PROPERTIES
    lblBuyPt:{
      name: "lblBuyPt",
      type: "LabelWidget",
      parent: "gridCorrectionLog",
      Label: "lblBuyPt",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblInspNum:{
      name: "lblInspNum",
      type: "LabelWidget",
      parent: "gridCorrectionLog",
      Label: "lblInspNum",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "grpbxReviewCorrectionLog",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    colCorrectionCount: {
      name: "colCorrectionCount",
      type: "GridColumnWidget",
      parent: "gridCorrectionLog",
      Label: "Correction Count",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCorrectionCount_PROPERTIES

      // END_USER_CODE-USER_colCorrectionCount_PROPERTIES
    },
    colFieldDiscription: {
      name: "colFieldDiscription",
      type: "GridColumnWidget",
      parent: "gridCorrectionLog",
      Label: "Field Discription",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFieldDiscription_PROPERTIES

      // END_USER_CODE-USER_colFieldDiscription_PROPERTIES
    },
    colLogDate: {
      name: "colLogDate",
      type: "GridColumnWidget",
      parent: "gridCorrectionLog",
      Label: "Log Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLogDate_PROPERTIES

      // END_USER_CODE-USER_colLogDate_PROPERTIES
    },
    colNewValue: {
      name: "colNewValue",
      type: "GridColumnWidget",
      parent: "gridCorrectionLog",
      Label: "New Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNewValue_PROPERTIES

      // END_USER_CODE-USER_colNewValue_PROPERTIES
    },
    colOldValue: {
      name: "colOldValue",
      type: "GridColumnWidget",
      parent: "gridCorrectionLog",
      Label: "Old Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOldValue_PROPERTIES

      // END_USER_CODE-USER_colOldValue_PROPERTIES
    },
    colRevisionNumber: {
      name: "colRevisionNumber",
      type: "GridColumnWidget",
      parent: "gridCorrectionLog",
      Label: "Revision Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRevisionNumber_PROPERTIES

      // END_USER_CODE-USER_colRevisionNumber_PROPERTIES
    },
    colSettlementNumber: {
      name: "colSettlementNumber",
      type: "GridColumnWidget",
      parent: "gridCorrectionLog",
      Label: "Settlement Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSettlementNumber_PROPERTIES

      // END_USER_CODE-USER_colSettlementNumber_PROPERTIES
    },
    gridCorrectionLog: {
      name: "gridCorrectionLog",
      type: "GridWidget",
      parent: "grpbxReviewCorrectionLog",
      gridCellsOrder:
        "txtcolLogDate,txtcolRevisionNumber,txtcolSettlementNumber,txtcolCorrectionCount,txtcolFieldDiscription,txtcolOldValue,txtcolNewValue",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridCorrectionLog_PROPERTIES

      // END_USER_CODE-USER_gridCorrectionLog_PROPERTIES
    },
    txtcolCorrectionCount: {
      name: "txtcolCorrectionCount",
      type: "TextBoxWidget",
      colName: "colCorrectionCount",
      parent: "gridCorrectionLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCorrectionCount_PROPERTIES

      // END_USER_CODE-USER_txtcolCorrectionCount_PROPERTIES
    },
    txtcolFieldDiscription: {
      name: "txtcolFieldDiscription",
      type: "TextBoxWidget",
      colName: "colFieldDiscription",
      parent: "gridCorrectionLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFieldDiscription_PROPERTIES

      // END_USER_CODE-USER_txtcolFieldDiscription_PROPERTIES
    },
    txtcolLogDate: {
      name: "txtcolLogDate",
      type: "TextBoxWidget",
      colName: "colLogDate",
      parent: "gridCorrectionLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLogDate_PROPERTIES

      // END_USER_CODE-USER_txtcolLogDate_PROPERTIES
    },
    txtcolNewValue: {
      name: "txtcolNewValue",
      type: "TextBoxWidget",
      colName: "colNewValue",
      parent: "gridCorrectionLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNewValue_PROPERTIES

      // END_USER_CODE-USER_txtcolNewValue_PROPERTIES
    },
    txtcolOldValue: {
      name: "txtcolOldValue",
      type: "TextBoxWidget",
      colName: "colOldValue",
      parent: "gridCorrectionLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOldValue_PROPERTIES

      // END_USER_CODE-USER_txtcolOldValue_PROPERTIES
    },
    txtcolRevisionNumber: {
      name: "txtcolRevisionNumber",
      type: "TextBoxWidget",
      colName: "colRevisionNumber",
      parent: "gridCorrectionLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRevisionNumber_PROPERTIES

      // END_USER_CODE-USER_txtcolRevisionNumber_PROPERTIES
    },
    txtcolSettlementNumber: {
      name: "txtcolSettlementNumber",
      type: "TextBoxWidget",
      colName: "colSettlementNumber",
      parent: "gridCorrectionLog",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSettlementNumber_PROPERTIES

      // END_USER_CODE-USER_txtcolSettlementNumber_PROPERTIES
    },
    grpbxReviewCorrectionLog: {
      name: "grpbxReviewCorrectionLog",
      type: "GroupBoxWidget",
      parent: "ReviewCorrectionLog",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxReviewCorrectionLog_PROPERTIES

      // END_USER_CODE-USER_grpbxReviewCorrectionLog_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    ReadCorrectionLog();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  
  const onbtnCloseClick = () => {
    try {
      document.getElementById("Settlements_ReviewCorrectionLogPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;



 const ReadCorrectionLog = () => {
  //let ReviewCorrectLogObj
  //  ReviewCorrectLogObj.buy_pt_id = 'R05'
  //  ReviewCorrectLogObj.insp_num = '2223412'
  //  setData(thisObj, 'ReviewCorrectLogObj', ReviewCorrectLogObj)
  ReviewCorrectLogData = getData(thisObj, 'correctionLogOnLoadData')
   try {
     setValue(thisObj, 'lblBuyPt', ReviewCorrectLogData.buy_pt_id)
     setValue(thisObj, 'lblInspNum', ReviewCorrectLogData.insp_num)
     if (ReviewCorrectLogData.buy_pt_id != null && ReviewCorrectLogData.buy_pt_id != undefined &&
       ReviewCorrectLogData.insp_num != null && ReviewCorrectLogData.insp_num != undefined) {
       SettlementService.RetrieveInspectCorrectionLogDetails(ReviewCorrectLogData.buy_pt_id, ReviewCorrectLogData.insp_num)
         .then(response => {
           let list = []
           let obj = {}
           let data = response
           for (var i = 0; i < data.length; i++) {
             //txtcolLogDate,txtcolRevisionNumber,txtcolSettlementNumber,txtcolCorrectionCount,txtcolFieldDiscription,txtcolOldValue,txtcolNewValue
             var dataDateTime = moment(data[i].add_date_time).format('MM/DD/YYYY');
             obj.txtcolLogDate = dataDateTime
             obj.txtcolRevisionNumber = data[i].revision_num
             obj.txtcolSettlementNumber = data[i].settle_num
             obj.txtcolCorrectionCount = data[i].correct_cnt
             obj.txtcolFieldDiscription = data[i].field_desc
             obj.txtcolOldValue = data[i].old_value
             obj.txtcolNewValue = data[i].new_value
             list.push(obj)
             obj = {}
           }
           setValue(thisObj, 'gridCorrectionLog', list)
         });
     }
   } catch (err) {
     if (err instanceof EvalError) {
       showMessage(thisObj, err.message);
     } else {
       showMessage(
         thisObj,
         "Error occurred during gridCorrectionLog load event"
       );
     }
     return false;
   }
   return true;
 }

  const onbtnExportToExcelClick = () => {
    try {
      ReviewCorrectLogData = getData(thisObj, 'correctionLogOnLoadData')
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, "gridCorrectionLog")
      if (excelData.length > 0) {
        var fileName = "CorrectionLog" + cropYearFromLS() + "_" + ReviewCorrectLogData.buy_pt_id + "_" + ReviewCorrectLogData.insp_num
        const arrayData = []
        for (var i = 0; i < excelData.length; i++) {
          var obj = {
            "Log Date": excelData[i].txtcolLogDate,
            "Revision Number": ExcelNumericDataConvert(excelData[i].txtcolRevisionNumber),
            "Settlement Number": ExcelNumericDataConvert(excelData[i].txtcolSettlementNumber),
            "CorrectionCount": ExcelNumericDataConvert(excelData[i].txtcolCorrectionCount),
            "Field Discription": excelData[i].txtcolFieldDiscription,
            "Old Value": ExcelNumericDataConvert(excelData[i].txtcolOldValue,[',']),
            "New Value": ExcelNumericDataConvert(excelData[i].txtcolNewValue,[',']),
          }
          arrayData.push(obj);
        }
        const ws = XLSX.utils.json_to_sheet(arrayData)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var FileSaver = require('file-saver');
        FileSaver.saveAs(data, fileName + fileExtension);
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during gridCorrectionLog load event"
        );
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;


  const onbtnPrintClick = () => {
    try {
      ReviewCorrectLogData = getData(thisObj, 'correctionLogOnLoadData')
      var data = getValue(thisObj, 'gridCorrectionLog')
      var dateTime = moment(new Date()).format('MM/DD/YYYY hh:mm A');
      if (data.length > 0) {
        var res = []
        for (var i = 0; i < data.length; i++) {
          res.push([
            data[i].txtcolLogDate, data[i].txtcolRevisionNumber, data[i].txtcolSettlementNumber, data[i].txtcolCorrectionCount, data[i].txtcolFieldDiscription, data[i].txtcolOldValue, data[i].txtcolNewValue
          ])
        }
        var obj = new jsPDF();
        obj.autoTable({
          // startY: 5,
          // startX: 20,
          styles: { fontSize: 7, lineWidth: 0.2, color: 10, halign: 'center'},
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Log Date', 'Revision Number', 'Settlement Number', 'Correction Count', 'Field Discription', 'Old Value', 'New Value']
          ]
        });
        let fileName = cropYearFromLS() + "CorrectionLog - " + ReviewCorrectLogData.buy_pt_id + " - SC95: " + ReviewCorrectLogData.insp_num + '-' + dateTime
        obj.save(fileName + '.pdf')
      } else {
        showMessage(thisObj, "No value in the grid to print")
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during gridCorrectionLog load event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;
 
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ReviewCorrectionLog*/}

              {/* END_USER_CODE-USER_BEFORE_ReviewCorrectionLog*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxReviewCorrectionLog*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxReviewCorrectionLog*/}

              <GroupBoxWidget
                conf={state.grpbxReviewCorrectionLog}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_gridCorrectionLog*/}

                {/* END_USER_CODE-USER_BEFORE_gridCorrectionLog*/}
                <LabelWidget
                  values={values}
                  conf={state.lblBuyPt}
                  screenConf={state}
                ></LabelWidget>                 <LabelWidget
                  values={values}
                  conf={state.lblInspNum}
                  screenConf={state}
                ></LabelWidget>
                <GridWidget
                  conf={state.gridCorrectionLog}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridCorrectionLog}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridCorrectionLog*/}

                {/* END_USER_CODE-USER_AFTER_gridCorrectionLog*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                  <ButtonWidget
                    conf={state.btnClose}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClose*/}

                  {/* END_USER_CODE-USER_AFTER_btnClose*/}
                  {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  <ButtonWidget
                    conf={state.btnExportToExcel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                  <ButtonWidget
                    conf={state.btnPrint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                  {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxReviewCorrectionLog*/}

              {/* END_USER_CODE-USER_AFTER_grpbxReviewCorrectionLog*/}

              {/* START_USER_CODE-USER_AFTER_ReviewCorrectionLog*/}

              {/* END_USER_CODE-USER_AFTER_ReviewCorrectionLog*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_ReviewCorrectionLog);
