/* eslint-disable*/
import React from "react";
import ContractManagement_ApplicationToContract from "./ApplicationToContract";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ApplicationToContract Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ApplicationToContract />);
    });
  });
  afterEach(cleanup);
  test("is ApplicationToContract Loads Successfully", () => {
    expect(screen.getByText("Application to Contract")).toBeInTheDocument;
  });
  test("Custom Test Cases for ApplicationToContract", () => {
    // START_USER_CODE-USER_ApplicationToContract_Custom_Test_Case
    // END_USER_CODE-USER_ApplicationToContract_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ApplicationToContract />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("ContractManagement:ApplicationToContract_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("ContractManagement:ApplicationToContract_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrintSearch(Button Widget) Test Cases", async () => {
    const btnPrintSearch = screen.getByTestId("btnPrintSearch");
    expect(btnPrintSearch).toBeInTheDocument;
    expect(btnPrintSearch.textContent).toEqual(
      t("ContractManagement:ApplicationToContract_btnPrintSearch")
    );
  });
  test("Custom Test Cases for btnPrintSearch", () => {
    // START_USER_CODE-USER_btnPrintSearch_TEST
    // END_USER_CODE-USER_btnPrintSearch_TEST
  });
  test("gridAppToContrct(Grid Widget) Test Cases", async () => {
    let gridAppToContrct = screen.getByTestId("gridAppToContrct");
    let gridAppToContrctbtn =
      gridAppToContrct.nextElementSibling.firstElementChild;
    gridAppToContrct =
      gridAppToContrct.parentElement.parentElement.parentElement;
    expect(gridAppToContrct.tagName).toBe("DIV");
    expect(gridAppToContrct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ApplicationToContract_gridAppToContrct")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAppToContrct", () => {
    // START_USER_CODE-USER_gridAppToContrct_TEST
    // END_USER_CODE-USER_gridAppToContrct_TEST
  });
  test("grpbxCnclExprt(GroupBox Widget) Test Cases", async () => {
    const grpbxCnclExprt = screen.getByTestId("grpbxCnclExprt");
    expect(grpbxCnclExprt.tagName).toBe("BUTTON");
    expect(grpbxCnclExprt.type).toBe("button");
    expect(grpbxCnclExprt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCnclExprt", () => {
    // START_USER_CODE-USER_grpbxCnclExprt_TEST
    // END_USER_CODE-USER_grpbxCnclExprt_TEST
  });
  test("gridAppToContrct_txtAppliedPounds(Grid Widget) Test Cases", async () => {
    let gridAppToContrct_txtAppliedPounds = screen.getByTestId(
      "gridAppToContrct"
    );
    let gridAppToContrct_txtAppliedPoundsbtn =
      gridAppToContrct_txtAppliedPounds.nextElementSibling.firstElementChild;
    gridAppToContrct_txtAppliedPounds =
      gridAppToContrct_txtAppliedPounds.parentElement.parentElement
        .parentElement;
    expect(gridAppToContrct_txtAppliedPounds.tagName).toBe("DIV");
    expect(gridAppToContrct_txtAppliedPounds.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ApplicationToContract_gridAppToContrct")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtAppliedPounds", () => {
    // START_USER_CODE-USER_txtAppliedPounds_TEST
    // END_USER_CODE-USER_txtAppliedPounds_TEST
  });
  test("gridAppToContrct_txtBuyingPoint(Grid Widget) Test Cases", async () => {
    let gridAppToContrct_txtBuyingPoint = screen.getByTestId(
      "gridAppToContrct"
    );
    let gridAppToContrct_txtBuyingPointbtn =
      gridAppToContrct_txtBuyingPoint.nextElementSibling.firstElementChild;
    gridAppToContrct_txtBuyingPoint =
      gridAppToContrct_txtBuyingPoint.parentElement.parentElement.parentElement;
    expect(gridAppToContrct_txtBuyingPoint.tagName).toBe("DIV");
    expect(gridAppToContrct_txtBuyingPoint.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ApplicationToContract_gridAppToContrct")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtBuyingPoint", () => {
    // START_USER_CODE-USER_txtBuyingPoint_TEST
    // END_USER_CODE-USER_txtBuyingPoint_TEST
  });
  test("gridAppToContrct_txtDateSettle(Grid Widget) Test Cases", async () => {
    let gridAppToContrct_txtDateSettle = screen.getByTestId("gridAppToContrct");
    let gridAppToContrct_txtDateSettlebtn =
      gridAppToContrct_txtDateSettle.nextElementSibling.firstElementChild;
    gridAppToContrct_txtDateSettle =
      gridAppToContrct_txtDateSettle.parentElement.parentElement.parentElement;
    expect(gridAppToContrct_txtDateSettle.tagName).toBe("DIV");
    expect(gridAppToContrct_txtDateSettle.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ApplicationToContract_gridAppToContrct")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtDateSettle", () => {
    // START_USER_CODE-USER_txtDateSettle_TEST
    // END_USER_CODE-USER_txtDateSettle_TEST
  });
  test("gridAppToContrct_txtInspDate(Grid Widget) Test Cases", async () => {
    let gridAppToContrct_txtInspDate = screen.getByTestId("gridAppToContrct");
    let gridAppToContrct_txtInspDatebtn =
      gridAppToContrct_txtInspDate.nextElementSibling.firstElementChild;
    gridAppToContrct_txtInspDate =
      gridAppToContrct_txtInspDate.parentElement.parentElement.parentElement;
    expect(gridAppToContrct_txtInspDate.tagName).toBe("DIV");
    expect(gridAppToContrct_txtInspDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ApplicationToContract_gridAppToContrct")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtInspDate", () => {
    // START_USER_CODE-USER_txtInspDate_TEST
    // END_USER_CODE-USER_txtInspDate_TEST
  });
  test("gridAppToContrct_txtInspNum(Grid Widget) Test Cases", async () => {
    let gridAppToContrct_txtInspNum = screen.getByTestId("gridAppToContrct");
    let gridAppToContrct_txtInspNumbtn =
      gridAppToContrct_txtInspNum.nextElementSibling.firstElementChild;
    gridAppToContrct_txtInspNum =
      gridAppToContrct_txtInspNum.parentElement.parentElement.parentElement;
    expect(gridAppToContrct_txtInspNum.tagName).toBe("DIV");
    expect(gridAppToContrct_txtInspNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ApplicationToContract_gridAppToContrct")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtInspNum", () => {
    // START_USER_CODE-USER_txtInspNum_TEST
    // END_USER_CODE-USER_txtInspNum_TEST
  });
  test("gridAppToContrct_txtSttlmntNum(Grid Widget) Test Cases", async () => {
    let gridAppToContrct_txtSttlmntNum = screen.getByTestId("gridAppToContrct");
    let gridAppToContrct_txtSttlmntNumbtn =
      gridAppToContrct_txtSttlmntNum.nextElementSibling.firstElementChild;
    gridAppToContrct_txtSttlmntNum =
      gridAppToContrct_txtSttlmntNum.parentElement.parentElement.parentElement;
    expect(gridAppToContrct_txtSttlmntNum.tagName).toBe("DIV");
    expect(gridAppToContrct_txtSttlmntNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ApplicationToContract_gridAppToContrct")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtSttlmntNum", () => {
    // START_USER_CODE-USER_txtSttlmntNum_TEST
    // END_USER_CODE-USER_txtSttlmntNum_TEST
  });
  test("gridAppToContrct_txtTentativePounds(Grid Widget) Test Cases", async () => {
    let gridAppToContrct_txtTentativePounds = screen.getByTestId(
      "gridAppToContrct"
    );
    let gridAppToContrct_txtTentativePoundsbtn =
      gridAppToContrct_txtTentativePounds.nextElementSibling.firstElementChild;
    gridAppToContrct_txtTentativePounds =
      gridAppToContrct_txtTentativePounds.parentElement.parentElement
        .parentElement;
    expect(gridAppToContrct_txtTentativePounds.tagName).toBe("DIV");
    expect(gridAppToContrct_txtTentativePounds.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("ContractManagement:ApplicationToContract_gridAppToContrct")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtTentativePounds", () => {
    // START_USER_CODE-USER_txtTentativePounds_TEST
    // END_USER_CODE-USER_txtTentativePounds_TEST
  });
  test("gridAppToContrct_txtWRDate(Grid Widget) Test Cases", async () => {
    let gridAppToContrct_txtWRDate = screen.getByTestId("gridAppToContrct");
    let gridAppToContrct_txtWRDatebtn =
      gridAppToContrct_txtWRDate.nextElementSibling.firstElementChild;
    gridAppToContrct_txtWRDate =
      gridAppToContrct_txtWRDate.parentElement.parentElement.parentElement;
    expect(gridAppToContrct_txtWRDate.tagName).toBe("DIV");
    expect(gridAppToContrct_txtWRDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ApplicationToContract_gridAppToContrct")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtWRDate", () => {
    // START_USER_CODE-USER_txtWRDate_TEST
    // END_USER_CODE-USER_txtWRDate_TEST
  });
  test("gridAppToContrct_txtWRNum(Grid Widget) Test Cases", async () => {
    let gridAppToContrct_txtWRNum = screen.getByTestId("gridAppToContrct");
    let gridAppToContrct_txtWRNumbtn =
      gridAppToContrct_txtWRNum.nextElementSibling.firstElementChild;
    gridAppToContrct_txtWRNum =
      gridAppToContrct_txtWRNum.parentElement.parentElement.parentElement;
    expect(gridAppToContrct_txtWRNum.tagName).toBe("DIV");
    expect(gridAppToContrct_txtWRNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:ApplicationToContract_gridAppToContrct")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtWRNum", () => {
    // START_USER_CODE-USER_txtWRNum_TEST
    // END_USER_CODE-USER_txtWRNum_TEST
  });
});
