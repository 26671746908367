import { StockTransferService } from "../Service/StockTransferService";

export class clsBinList {

    constructor(thisObj) {
        this.thisObj = thisObj;
    }

    Load = async (CompanyID, CropYear, BuyingPoingID, PeanutType = "", SegmentType = "", Organic = "") => {
        const mBinList = [];
        let binListData = await StockTransferService.RetrieveWhouseBinInventoryList(BuyingPoingID)
        if (binListData.length > 0) {
            for (let i = 0; i < binListData.length; i++) {
                if ((PeanutType === "" && SegmentType === "" && Organic === "") ||
                    (PeanutType === binListData[i].pnut_type_id && SegmentType === binListData[i].seg_type && (Organic === binListData[i].organic_ind || Organic === "Y"))) {
                    mBinList.push(binListData[i]);
                }
            }
        }
        return mBinList;
    }
}

