/* eslint-disable*/
import React, { Component } from "react";
import AppContext from "../../../context/AppContext";
import "./HamburgerMenu.scss";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import {useAppContext} from "../../App";
const _kaledo = window._kaledo;

function HamburgerMenu(props) {
  const context = useAppContext();
  //static contextType = AppContext;
  let _scrnList = context.screenList;
  let _languages = context._languages;
  let langClick = context.langClick;
  let logout = context.logout;
  let _auth = _kaledo["Auth"];

      let t = context.t;
    let _selectedLang = context._selectedLang;
    return (
      <Navbar.Collapse className="hamburger" id="basic-navbar-nav">
        <Nav className="ml-auto">
          {_scrnList.map((menus, i) => (
            <NavDropdown
              key={"menu" + i}
              title={t("translate:" + menus.menuLabel)}
              id={"ddMenu_" + i}
              data-testid="scrnDispName"
            >
              {menus.submenus.map(srcn => (
                <LinkContainer key={srcn.screenName + i} to={srcn.URIPath}>
                  <NavDropdown.Item href="#">
                    {t("translate:" + srcn.displayName)}
                  </NavDropdown.Item>
                </LinkContainer>
              ))}
            </NavDropdown>
          ))}
          <NavDropdown title={_selectedLang.langName} id="langDD">
            {_languages.map((language, i) => (
              <NavDropdown.Item
                eventKey={language.langCode}
                onSelect={() => langClick(language)}
                key={"lang" + i}
                href="#"
              >
                {t("translate:" + language.langName)}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        </Nav>
        {_auth && _auth === "OAUTH2" ? (
          <button
            className="btn btn-outline-danger ml-2 my-2 my-sm-0"
            onClick={logout}
          >
            {t("translate:Logout")}
          </button>
        ) : null}
      </Navbar.Collapse>
    );
  
}

export default HamburgerMenu;
