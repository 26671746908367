/* eslint-disable*/
import React from "react";
import ContractManagement_ExceptPremRequestProfile from "./ExceptPremRequestProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ExceptPremRequestProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ExceptPremRequestProfile />);
    });
  });
  afterEach(cleanup);
  test("is ExceptPremRequestProfile Loads Successfully", () => {
    expect(screen.getByText("ExceptPremRequestProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for ExceptPremRequestProfile", () => {
    // START_USER_CODE-USER_ExceptPremRequestProfile_Custom_Test_Case
    // END_USER_CODE-USER_ExceptPremRequestProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ExceptPremRequestProfile />);
    });
  });
  afterEach(cleanup);
  test("btnApprove(Button Widget) Test Cases", async () => {
    const btnApprove = screen.getByTestId("btnApprove");
    expect(btnApprove).toBeInTheDocument;
    expect(btnApprove.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_btnApprove")
    );
  });
  test("Custom Test Cases for btnApprove", () => {
    // START_USER_CODE-USER_btnApprove_TEST
    // END_USER_CODE-USER_btnApprove_TEST
  });
  test("btnCreatePayable(Button Widget) Test Cases", async () => {
    const btnCreatePayable = screen.getByTestId("btnCreatePayable");
    expect(btnCreatePayable).toBeInTheDocument;
    expect(btnCreatePayable.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_btnCreatePayable")
    );
  });
  test("Custom Test Cases for btnCreatePayable", () => {
    // START_USER_CODE-USER_btnCreatePayable_TEST
    // END_USER_CODE-USER_btnCreatePayable_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnReject(Button Widget) Test Cases", async () => {
    const btnReject = screen.getByTestId("btnReject");
    expect(btnReject).toBeInTheDocument;
    expect(btnReject.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_btnReject")
    );
  });
  test("Custom Test Cases for btnReject", () => {
    // START_USER_CODE-USER_btnReject_TEST
    // END_USER_CODE-USER_btnReject_TEST
  });
  test("btnReprint(Button Widget) Test Cases", async () => {
    const btnReprint = screen.getByTestId("btnReprint");
    expect(btnReprint).toBeInTheDocument;
    expect(btnReprint.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_btnReprint")
    );
  });
  test("Custom Test Cases for btnReprint", () => {
    // START_USER_CODE-USER_btnReprint_TEST
    // END_USER_CODE-USER_btnReprint_TEST
  });
  test("btnReverse(Button Widget) Test Cases", async () => {
    const btnReverse = screen.getByTestId("btnReverse");
    expect(btnReverse).toBeInTheDocument;
    expect(btnReverse.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_btnReverse")
    );
  });
  test("Custom Test Cases for btnReverse", () => {
    // START_USER_CODE-USER_btnReverse_TEST
    // END_USER_CODE-USER_btnReverse_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("btnUpdateExceptPrems(Button Widget) Test Cases", async () => {
    const btnUpdateExceptPrems = screen.getByTestId("btnUpdateExceptPrems");
    expect(btnUpdateExceptPrems).toBeInTheDocument;
    expect(btnUpdateExceptPrems.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_btnUpdateExceptPrems")
    );
  });
  test("Custom Test Cases for btnUpdateExceptPrems", () => {
    // START_USER_CODE-USER_btnUpdateExceptPrems_TEST
    // END_USER_CODE-USER_btnUpdateExceptPrems_TEST
  });
  test("frpbxUpdateExceptPrem(GroupBox Widget) Test Cases", async () => {
    const frpbxUpdateExceptPrem = screen.getByTestId("frpbxUpdateExceptPrem");
    expect(frpbxUpdateExceptPrem.tagName).toBe("BUTTON");
    expect(frpbxUpdateExceptPrem.type).toBe("button");
    expect(frpbxUpdateExceptPrem.classList).toContain("btn");
  });
  test("Custom Test Cases for frpbxUpdateExceptPrem", () => {
    // START_USER_CODE-USER_frpbxUpdateExceptPrem_TEST
    // END_USER_CODE-USER_frpbxUpdateExceptPrem_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxLabels(GroupBox Widget) Test Cases", async () => {
    const grpbxLabels = screen.getByTestId("grpbxLabels");
    expect(grpbxLabels.tagName).toBe("BUTTON");
    expect(grpbxLabels.type).toBe("button");
    expect(grpbxLabels.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLabels", () => {
    // START_USER_CODE-USER_grpbxLabels_TEST
    // END_USER_CODE-USER_grpbxLabels_TEST
  });
  test("grpbxReqDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxReqDetails = screen.getByTestId("grpbxReqDetails");
    expect(grpbxReqDetails.tagName).toBe("BUTTON");
    expect(grpbxReqDetails.type).toBe("button");
    expect(grpbxReqDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReqDetails", () => {
    // START_USER_CODE-USER_grpbxReqDetails_TEST
    // END_USER_CODE-USER_grpbxReqDetails_TEST
  });
  test("grpbxSpoolDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxSpoolDetails = screen.getByTestId("grpbxSpoolDetails");
    expect(grpbxSpoolDetails.tagName).toBe("BUTTON");
    expect(grpbxSpoolDetails.type).toBe("button");
    expect(grpbxSpoolDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSpoolDetails", () => {
    // START_USER_CODE-USER_grpbxSpoolDetails_TEST
    // END_USER_CODE-USER_grpbxSpoolDetails_TEST
  });
  test("txtApprovedBy(Textbox Widget) Test Cases", async () => {
    const txtApprovedBy = screen.getByTestId("txtApprovedBy");
    expect(txtApprovedBy.tagName).toBe("INPUT");
    expect(txtApprovedBy.type).toBe("text");
    expect(txtApprovedBy.classList).toContain("textboxWidgetClass");
    expect(txtApprovedBy.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_txtApprovedBy")
    );
    await act(async () => {
      userEvent.type(txtApprovedBy, "1");
    });
  });
  test("Custom Test Cases for txtApprovedBy", () => {
    // START_USER_CODE-USER_txtApprovedBy_TEST
    // END_USER_CODE-USER_txtApprovedBy_TEST
  });
  test("txtDateApproved(Textbox Widget) Test Cases", async () => {
    const txtDateApproved = screen.getByTestId("txtDateApproved");
    expect(txtDateApproved.tagName).toBe("INPUT");
    expect(txtDateApproved.type).toBe("text");
    expect(txtDateApproved.classList).toContain("textboxWidgetClass");
    expect(txtDateApproved.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_txtDateApproved")
    );
    await act(async () => {
      userEvent.type(txtDateApproved, "1");
    });
  });
  test("Custom Test Cases for txtDateApproved", () => {
    // START_USER_CODE-USER_txtDateApproved_TEST
    // END_USER_CODE-USER_txtDateApproved_TEST
  });
  test("txtDateRequested(Textbox Widget) Test Cases", async () => {
    const txtDateRequested = screen.getByTestId("txtDateRequested");
    expect(txtDateRequested.tagName).toBe("INPUT");
    expect(txtDateRequested.type).toBe("text");
    expect(txtDateRequested.classList).toContain("textboxWidgetClass");
    expect(txtDateRequested.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_txtDateRequested")
    );
    await act(async () => {
      userEvent.type(txtDateRequested, "1");
    });
  });
  test("Custom Test Cases for txtDateRequested", () => {
    // START_USER_CODE-USER_txtDateRequested_TEST
    // END_USER_CODE-USER_txtDateRequested_TEST
  });
  test("txtLocation(Textbox Widget) Test Cases", async () => {
    const txtLocation = screen.getByTestId("txtLocation");
    expect(txtLocation.tagName).toBe("INPUT");
    expect(txtLocation.type).toBe("text");
    expect(txtLocation.classList).toContain("textboxWidgetClass");
    expect(txtLocation.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_txtLocation")
    );
    await act(async () => {
      userEvent.type(txtLocation, "123");
    });
    expect(txtLocation.getAttribute("value")).toBe("");
    expect(txtLocation.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLocation", () => {
    // START_USER_CODE-USER_txtLocation_TEST
    // END_USER_CODE-USER_txtLocation_TEST
  });
  test("txtLocationID(Textbox Widget) Test Cases", async () => {
    const txtLocationID = screen.getByTestId("txtLocationID");
    expect(txtLocationID.tagName).toBe("INPUT");
    expect(txtLocationID.type).toBe("text");
    expect(txtLocationID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLocationID, "1");
    });
  });
  test("Custom Test Cases for txtLocationID", () => {
    // START_USER_CODE-USER_txtLocationID_TEST
    // END_USER_CODE-USER_txtLocationID_TEST
  });
  test("txtPayRequest(Textbox Widget) Test Cases", async () => {
    const txtPayRequest = screen.getByTestId("txtPayRequest");
    expect(txtPayRequest.tagName).toBe("INPUT");
    expect(txtPayRequest.type).toBe("text");
    expect(txtPayRequest.classList).toContain("textboxWidgetClass");
    expect(txtPayRequest.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_txtPayRequest")
    );
    await act(async () => {
      userEvent.type(txtPayRequest, "123");
    });
    expect(txtPayRequest.getAttribute("value")).toBe("");
    expect(txtPayRequest.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPayRequest", () => {
    // START_USER_CODE-USER_txtPayRequest_TEST
    // END_USER_CODE-USER_txtPayRequest_TEST
  });
  test("txtPremiumCode(Textbox Widget) Test Cases", async () => {
    const txtPremiumCode = screen.getByTestId("txtPremiumCode");
    expect(txtPremiumCode.tagName).toBe("INPUT");
    expect(txtPremiumCode.type).toBe("text");
    expect(txtPremiumCode.classList).toContain("textboxWidgetClass");
    expect(txtPremiumCode.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_txtPremiumCode")
    );
    await act(async () => {
      userEvent.type(txtPremiumCode, "123");
    });
    expect(txtPremiumCode.getAttribute("value")).toBe("");
    expect(txtPremiumCode.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPremiumCode", () => {
    // START_USER_CODE-USER_txtPremiumCode_TEST
    // END_USER_CODE-USER_txtPremiumCode_TEST
  });
  test("txtPremiumCodeID(Textbox Widget) Test Cases", async () => {
    const txtPremiumCodeID = screen.getByTestId("txtPremiumCodeID");
    expect(txtPremiumCodeID.tagName).toBe("INPUT");
    expect(txtPremiumCodeID.type).toBe("text");
    expect(txtPremiumCodeID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPremiumCodeID, "1");
    });
  });
  test("Custom Test Cases for txtPremiumCodeID", () => {
    // START_USER_CODE-USER_txtPremiumCodeID_TEST
    // END_USER_CODE-USER_txtPremiumCodeID_TEST
  });
  test("txtRequestedBy(Textbox Widget) Test Cases", async () => {
    const txtRequestedBy = screen.getByTestId("txtRequestedBy");
    expect(txtRequestedBy.tagName).toBe("INPUT");
    expect(txtRequestedBy.type).toBe("text");
    expect(txtRequestedBy.classList).toContain("textboxWidgetClass");
    expect(txtRequestedBy.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_txtRequestedBy")
    );
    await act(async () => {
      userEvent.type(txtRequestedBy, "1");
    });
  });
  test("Custom Test Cases for txtRequestedBy", () => {
    // START_USER_CODE-USER_txtRequestedBy_TEST
    // END_USER_CODE-USER_txtRequestedBy_TEST
  });
  test("txtSpoolLocID(Textbox Widget) Test Cases", async () => {
    const txtSpoolLocID = screen.getByTestId("txtSpoolLocID");
    expect(txtSpoolLocID.tagName).toBe("INPUT");
    expect(txtSpoolLocID.type).toBe("text");
    expect(txtSpoolLocID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSpoolLocID, "1");
    });
  });
  test("Custom Test Cases for txtSpoolLocID", () => {
    // START_USER_CODE-USER_txtSpoolLocID_TEST
    // END_USER_CODE-USER_txtSpoolLocID_TEST
  });
  test("txtStatus(Textbox Widget) Test Cases", async () => {
    const txtStatus = screen.getByTestId("txtStatus");
    expect(txtStatus.tagName).toBe("INPUT");
    expect(txtStatus.type).toBe("text");
    expect(txtStatus.classList).toContain("textboxWidgetClass");
    expect(txtStatus.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_txtStatus")
    );
    await act(async () => {
      userEvent.type(txtStatus, "123");
    });
    expect(txtStatus.getAttribute("value")).toBe("");
    expect(txtStatus.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtStatus", () => {
    // START_USER_CODE-USER_txtStatus_TEST
    // END_USER_CODE-USER_txtStatus_TEST
  });
  test("txtTotalAmount(Textbox Widget) Test Cases", async () => {
    const txtTotalAmount = screen.getByTestId("txtTotalAmount");
    expect(txtTotalAmount.tagName).toBe("INPUT");
    expect(txtTotalAmount.type).toBe("text");
    expect(txtTotalAmount.classList).toContain("textboxWidgetClass");
    expect(txtTotalAmount.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_txtTotalAmount")
    );
    await act(async () => {
      userEvent.type(txtTotalAmount, "123");
    });
    expect(txtTotalAmount.getAttribute("value")).toBe("");
    expect(txtTotalAmount.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalAmount", () => {
    // START_USER_CODE-USER_txtTotalAmount_TEST
    // END_USER_CODE-USER_txtTotalAmount_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestProfile_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "123");
    });
    expect(txtVendor.getAttribute("value")).toBe("");
    expect(txtVendor.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtVendorID(Textbox Widget) Test Cases", async () => {
    const txtVendorID = screen.getByTestId("txtVendorID");
    expect(txtVendorID.tagName).toBe("INPUT");
    expect(txtVendorID.type).toBe("text");
    expect(txtVendorID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendorID, "1");
    });
  });
  test("Custom Test Cases for txtVendorID", () => {
    // START_USER_CODE-USER_txtVendorID_TEST
    // END_USER_CODE-USER_txtVendorID_TEST
  });
});
