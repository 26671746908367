import React from "react";
import Button from "react-bootstrap/Button";
import {getVisibleResponsiveClasses,getWidgetClasses,getWidgetLabel,getTooltip} from "../ParentWidgetFunc";

function LinkWidget(props){
  let conf = props.conf;
  let screenConf = props.screenConf;
  /* getButtonVariant = () => {
    if (this.conf.variant) {
      return this.conf.variant;
    }
    switch (this.conf.Category) {
      case "SubmitPage":
        return "primary";
      case "Cancel":
        return "outline-danger";
      default:
        return "secondary";
    }
  }; */

  const getLinkCSS=()=> {
    let linkCSS = [
      conf.name + "Link",
      "linkWidgetClass",
      "mr-2",
      ...getVisibleResponsiveClasses(conf),
      conf.Mandatory ? "requiredBtn" : ""
    ];
    return linkCSS.join(" ");
  }

    return (
      <div
        className={getWidgetClasses(conf,screenConf).join(" ") + " mb-2 align-self-end"}
      >
        <div className="row">
          <Button tabIndex={window._kaledo.isCobolTable ? "-1" : "0"}
            title={getTooltip(conf)}
            id={conf.name}
            variant="link"
            disabled={!conf.Enabled}
            className={getLinkCSS()}
            onClick={props.onClick}
            data-testid={conf.name}
          >
            {/* {getWidgetLabel(conf)} */}
            {conf.value === undefined ? getWidgetLabel(conf) : conf.value}
          </Button>
        </div>
      </div>
    );
  
}

export default LinkWidget;
