/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_SpecialFunctions from "./SpecialFunctions";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SpecialFunctions Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_SpecialFunctions />);
    });
  });
  afterEach(cleanup);
  test("is SpecialFunctions Loads Successfully", () => {
    expect(screen.getByText("SpecialFunctions")).toBeInTheDocument;
  });
  test("Custom Test Cases for SpecialFunctions", () => {
    // START_USER_CODE-USER_SpecialFunctions_Custom_Test_Case
    // END_USER_CODE-USER_SpecialFunctions_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_SpecialFunctions />);
    });
  });
  afterEach(cleanup);
  test("btnAcctDistMntnce(Button Widget) Test Cases", async () => {
    const btnAcctDistMntnce = screen.getByTestId("btnAcctDistMntnce");
    expect(btnAcctDistMntnce).toBeInTheDocument;
    expect(btnAcctDistMntnce.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnAcctDistMntnce"
      )
    );
  });
  test("Custom Test Cases for btnAcctDistMntnce", () => {
    // START_USER_CODE-USER_btnAcctDistMntnce_TEST
    // END_USER_CODE-USER_btnAcctDistMntnce_TEST
  });
  test("btnBankingHolidays(Button Widget) Test Cases", async () => {
    const btnBankingHolidays = screen.getByTestId("btnBankingHolidays");
    expect(btnBankingHolidays).toBeInTheDocument;
    expect(btnBankingHolidays.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnBankingHolidays"
      )
    );
  });
  test("Custom Test Cases for btnBankingHolidays", () => {
    // START_USER_CODE-USER_btnBankingHolidays_TEST
    // END_USER_CODE-USER_btnBankingHolidays_TEST
  });
  test("btnChangeNumbers(Button Widget) Test Cases", async () => {
    const btnChangeNumbers = screen.getByTestId("btnChangeNumbers");
    expect(btnChangeNumbers).toBeInTheDocument;
    expect(btnChangeNumbers.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnChangeNumbers"
      )
    );
  });
  test("Custom Test Cases for btnChangeNumbers", () => {
    // START_USER_CODE-USER_btnChangeNumbers_TEST
    // END_USER_CODE-USER_btnChangeNumbers_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpecialFunctions_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCMADMAControl(Button Widget) Test Cases", async () => {
    const btnCMADMAControl = screen.getByTestId("btnCMADMAControl");
    expect(btnCMADMAControl).toBeInTheDocument;
    expect(btnCMADMAControl.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnCMADMAControl"
      )
    );
  });
  test("Custom Test Cases for btnCMADMAControl", () => {
    // START_USER_CODE-USER_btnCMADMAControl_TEST
    // END_USER_CODE-USER_btnCMADMAControl_TEST
  });
  test("btnCompanies(Button Widget) Test Cases", async () => {
    const btnCompanies = screen.getByTestId("btnCompanies");
    expect(btnCompanies).toBeInTheDocument;
    expect(btnCompanies.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpecialFunctions_btnCompanies")
    );
  });
  test("Custom Test Cases for btnCompanies", () => {
    // START_USER_CODE-USER_btnCompanies_TEST
    // END_USER_CODE-USER_btnCompanies_TEST
  });
  test("btnDashboard(Button Widget) Test Cases", async () => {
    const btnDashboard = screen.getByTestId("btnDashboard");
    expect(btnDashboard).toBeInTheDocument;
    expect(btnDashboard.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpecialFunctions_btnDashboard")
    );
  });
  test("Custom Test Cases for btnDashboard", () => {
    // START_USER_CODE-USER_btnDashboard_TEST
    // END_USER_CODE-USER_btnDashboard_TEST
  });
  test("btnErrorCodeFilterMntnce(Button Widget) Test Cases", async () => {
    const btnErrorCodeFilterMntnce = screen.getByTestId(
      "btnErrorCodeFilterMntnce"
    );
    expect(btnErrorCodeFilterMntnce).toBeInTheDocument;
    expect(btnErrorCodeFilterMntnce.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnErrorCodeFilterMntnce"
      )
    );
  });
  test("Custom Test Cases for btnErrorCodeFilterMntnce", () => {
    // START_USER_CODE-USER_btnErrorCodeFilterMntnce_TEST
    // END_USER_CODE-USER_btnErrorCodeFilterMntnce_TEST
  });
  test("btnHighMoistureSetup(Button Widget) Test Cases", async () => {
    const btnHighMoistureSetup = screen.getByTestId(
      "btnHighMoistureSetup"
    );
    expect(btnHighMoistureSetup).toBeInTheDocument;
    expect(btnHighMoistureSetup.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnHighMoistureSetup"
      )
    );
  });
  test("Custom Test Cases for btnHighMoistureSetup", () => {
    // START_USER_CODE-USER_btnHighMoistureSetup_TEST
    // END_USER_CODE-USER_btnHighMoistureSetup_TEST
  });
  test("btnInventoryMntnce(Button Widget) Test Cases", async () => {
    const btnInventoryMntnce = screen.getByTestId("btnInventoryMntnce");
    expect(btnInventoryMntnce).toBeInTheDocument;
    expect(btnInventoryMntnce.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnInventoryMntnce"
      )
    );
  });
  test("Custom Test Cases for btnInventoryMntnce", () => {
    // START_USER_CODE-USER_btnInventoryMntnce_TEST
    // END_USER_CODE-USER_btnInventoryMntnce_TEST
  });
  test("btnLoanInterest(Button Widget) Test Cases", async () => {
    const btnLoanInterest = screen.getByTestId("btnLoanInterest");
    expect(btnLoanInterest).toBeInTheDocument;
    expect(btnLoanInterest.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnLoanInterest"
      )
    );
  });
  test("Custom Test Cases for btnLoanInterest", () => {
    // START_USER_CODE-USER_btnLoanInterest_TEST
    // END_USER_CODE-USER_btnLoanInterest_TEST
  });
  test("btnLoanReduction(Button Widget) Test Cases", async () => {
    const btnLoanReduction = screen.getByTestId("btnLoanReduction");
    expect(btnLoanReduction).toBeInTheDocument;
    expect(btnLoanReduction.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnLoanReduction"
      )
    );
  });
  test("Custom Test Cases for btnLoanReduction", () => {
    // START_USER_CODE-USER_btnLoanReduction_TEST
    // END_USER_CODE-USER_btnLoanReduction_TEST
  });
  test("btnLoanRepayment(Button Widget) Test Cases", async () => {
    const btnLoanRepayment = screen.getByTestId("btnLoanRepayment");
    expect(btnLoanRepayment).toBeInTheDocument;
    expect(btnLoanRepayment.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnLoanRepayment"
      )
    );
  });
  test("Custom Test Cases for btnLoanRepayment", () => {
    // START_USER_CODE-USER_btnLoanRepayment_TEST
    // END_USER_CODE-USER_btnLoanRepayment_TEST
  });
  test("btnMarketPriceSetup(Button Widget) Test Cases", async () => {
    const btnMarketPriceSetup = screen.getByTestId(
      "btnMarketPriceSetup"
    );
    expect(btnMarketPriceSetup).toBeInTheDocument;
    expect(btnMarketPriceSetup.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnMarketPriceSetup"
      )
    );
  });
  test("Custom Test Cases for btnMarketPriceSetup", () => {
    // START_USER_CODE-USER_btnMarketPriceSetup_TEST
    // END_USER_CODE-USER_btnMarketPriceSetup_TEST
  });
  test("btnMiscFunctions(Button Widget) Test Cases", async () => {
    const btnMiscFunctions = screen.getByTestId("btnMiscFunctions");
    expect(btnMiscFunctions).toBeInTheDocument;
    expect(btnMiscFunctions.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnMiscFunctions"
      )
    );
  });
  test("Custom Test Cases for btnMiscFunctions", () => {
    // START_USER_CODE-USER_btnMiscFunctions_TEST
    // END_USER_CODE-USER_btnMiscFunctions_TEST
  });
  test("btnPricingSchedule(Button Widget) Test Cases", async () => {
    const btnPricingSchedule = screen.getByTestId("btnPricingSchedule");
    expect(btnPricingSchedule).toBeInTheDocument;
    expect(btnPricingSchedule.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnPricingSchedule"
      )
    );
  });
  test("Custom Test Cases for btnPricingSchedule", () => {
    // START_USER_CODE-USER_btnPricingSchedule_TEST
    // END_USER_CODE-USER_btnPricingSchedule_TEST
  });
  test("btnReadyToPayMntnce(Button Widget) Test Cases", async () => {
    const btnReadyToPayMntnce = screen.getByTestId(
      "btnReadyToPayMntnce"
    );
    expect(btnReadyToPayMntnce).toBeInTheDocument;
    expect(btnReadyToPayMntnce.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnReadyToPayMntnce"
      )
    );
  });
  test("Custom Test Cases for btnReadyToPayMntnce", () => {
    // START_USER_CODE-USER_btnReadyToPayMntnce_TEST
    // END_USER_CODE-USER_btnReadyToPayMntnce_TEST
  });
  test("btnReprintDocuments(Button Widget) Test Cases", async () => {
    const btnReprintDocuments = screen.getByTestId(
      "btnReprintDocuments"
    );
    expect(btnReprintDocuments).toBeInTheDocument;
    expect(btnReprintDocuments.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnReprintDocuments"
      )
    );
  });
  test("Custom Test Cases for btnReprintDocuments", () => {
    // START_USER_CODE-USER_btnReprintDocuments_TEST
    // END_USER_CODE-USER_btnReprintDocuments_TEST
  });
  test("btnSecurity(Button Widget) Test Cases", async () => {
    const btnSecurity = screen.getByTestId("btnSecurity");
    expect(btnSecurity).toBeInTheDocument;
    expect(btnSecurity.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpecialFunctions_btnSecurity")
    );
  });
  test("Custom Test Cases for btnSecurity", () => {
    // START_USER_CODE-USER_btnSecurity_TEST
    // END_USER_CODE-USER_btnSecurity_TEST
  });
  test("btnTradeCategory(Button Widget) Test Cases", async () => {
    const btnTradeCategory = screen.getByTestId("btnTradeCategory");
    expect(btnTradeCategory).toBeInTheDocument;
    expect(btnTradeCategory.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnTradeCategory"
      )
    );
  });
  test("Custom Test Cases for btnTradeCategory", () => {
    // START_USER_CODE-USER_btnTradeCategory_TEST
    // END_USER_CODE-USER_btnTradeCategory_TEST
  });
  test("btnWireTransferSetup(Button Widget) Test Cases", async () => {
    const btnWireTransferSetup = screen.getByTestId(
      "btnWireTransferSetup"
    );
    expect(btnWireTransferSetup).toBeInTheDocument;
    expect(btnWireTransferSetup.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpecialFunctions_btnWireTransferSetup"
      )
    );
  });
  test("Custom Test Cases for btnWireTransferSetup", () => {
    // START_USER_CODE-USER_btnWireTransferSetup_TEST
    // END_USER_CODE-USER_btnWireTransferSetup_TEST
  });
  test("grpbxSpecialFunctions(GroupBox Widget) Test Cases", async () => {
    const grpbxSpecialFunctions = screen.getByTestId(
      "grpbxSpecialFunctions"
    );
    expect(grpbxSpecialFunctions.tagName).toBe("BUTTON");
    expect(grpbxSpecialFunctions.type).toBe("button");
    expect(grpbxSpecialFunctions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSpecialFunctions", () => {
    // START_USER_CODE-USER_grpbxSpecialFunctions_TEST
    // END_USER_CODE-USER_grpbxSpecialFunctions_TEST
  });
});
