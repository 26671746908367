/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  enable,
  disable,
  setData,
  getData,
  setValue,
  getValue,
} from "../../shared/WindowImports";

import "./CleaningCuringCosts.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../Service/SettlementService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS

let listCureScheduleId = [];
let listCureScheduleNum = [];
let listCureDryGreenInd = [];
let listCostToCure = [];
let listCureRateType = [];
let listCurePDCode = [];

let listCureScheduleId1 = [];
let listCureScheduleNum1 = [];
let listCureDryGreenInd1 = [];
let listCostToCure1 = [];
let listCureRateType1 = [];
let listCurePDCode1 = [];

let listCureScheduleId2 = [];
let listCureScheduleNum2 = [];
let listCureDryGreenInd2 = [];
let listCostToCure2 = [];
let listCureRateType2 = [];
let listCurePDCode2 = [];

let listCleanScheduleId = [];
let listCleanScheduleNum = [];
let listCleanDryGreenInd = [];
let listCostToClean = [];
let listCleanRateType = [];
let listCleanPDCode = [];

const emptyScheduleList = [{ key: '', description: '<<< Select >>>' }];

function Settlements_CleaningCuringCosts(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let lstrxml = [];
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "CleaningCuringCosts",
    windowName: "CleaningCuringCosts",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.CleaningCuringCosts",
    horizontalForm: true,
    // START_USER_CODE-USER_CleaningCuringCosts_PROPERTIES
    headerData: {
      scrName: "Cleaning Curing Costs",
      scrCode: "PN1030C",
    },
    // END_USER_CODE-USER_CleaningCuringCosts_PROPERTIES
    btnCanceluic0: {
      name: "btnCanceluic0",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCanceluic0_PROPERTIES

      // END_USER_CODE-USER_btnCanceluic0_PROPERTIES
    },
    btnOkuic0: {
      name: "btnOkuic0",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOkuic0_PROPERTIES

      // END_USER_CODE-USER_btnOkuic0_PROPERTIES
    },
    ddCCNWSchedule: {
      name: "ddCCNWSchedule",
      type: "DropDownFieldWidget",
      parent: "grpbxCureCleanNetWtRow",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      valueList: emptyScheduleList,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCCNWSchedule_PROPERTIES

      // END_USER_CODE-USER_ddCCNWSchedule_PROPERTIES
    },
    ddCCOSchedule: {
      name: "ddCCOSchedule",
      type: "DropDownFieldWidget",
      parent: "grpbxCureCleanOtherRow",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      valueList: emptyScheduleList,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCCOSchedule_PROPERTIES

      // END_USER_CODE-USER_ddCCOSchedule_PROPERTIES
    },
    ddCCSchedule0: {
      name: "ddCCSchedule0",
      type: "DropDownFieldWidget",
      parent: "grpbxCureCleanGrossWtRow0",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      valueList: emptyScheduleList,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCCSchedule0_PROPERTIES

      // END_USER_CODE-USER_ddCCSchedule0_PROPERTIES
    },
    ddCCSchedule1: {
      name: "ddCCSchedule1",
      type: "DropDownFieldWidget",
      parent: "grpbxCureCleanGrossWtRow1",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      valueList: emptyScheduleList,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCCSchedule1_PROPERTIES

      // END_USER_CODE-USER_ddCCSchedule1_PROPERTIES
    },
    ddCCSchedule2: {
      name: "ddCCSchedule2",
      type: "DropDownFieldWidget",
      parent: "grpbxCureCleanGrossWtRow2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      valueList: emptyScheduleList,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCCSchedule2_PROPERTIES

      // END_USER_CODE-USER_ddCCSchedule2_PROPERTIES
    },
    ddCNWSchedule: {
      name: "ddCNWSchedule",
      type: "DropDownFieldWidget",
      parent: "grpbxCleanNetWtRow",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCNWSchedule_PROPERTIES

      // END_USER_CODE-USER_ddCNWSchedule_PROPERTIES
    },
    ddCOSchedule: {
      name: "ddCOSchedule",
      type: "DropDownFieldWidget",
      parent: "grpbxCleanOtherRow",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCOSchedule_PROPERTIES

      // END_USER_CODE-USER_ddCOSchedule_PROPERTIES
    },
    ddCSchedule0: {
      name: "ddCSchedule0",
      type: "DropDownFieldWidget",
      parent: "grpbxCleanGrossWtRow0",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCSchedule0_PROPERTIES

      // END_USER_CODE-USER_ddCSchedule0_PROPERTIES
    },
    ddCSchedule1: {
      name: "ddCSchedule1",
      type: "DropDownFieldWidget",
      parent: "grpbxCleanGrossWtRow1",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCSchedule1_PROPERTIES

      // END_USER_CODE-USER_ddCSchedule1_PROPERTIES
    },
    ddCSchedule2: {
      name: "ddCSchedule2",
      type: "DropDownFieldWidget",
      parent: "grpbxCleanGrossWtRow2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCSchedule2_PROPERTIES

      // END_USER_CODE-USER_ddCSchedule2_PROPERTIES
    },
    grpbxClean: {
      name: "grpbxClean",
      type: "GroupBoxWidget",
      parent: "grpbxCleaningCuringCosts",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxClean_PROPERTIES

      // END_USER_CODE-USER_grpbxClean_PROPERTIES
    },
    grpbxCleanGrossWt: {
      name: "grpbxCleanGrossWt",
      type: "GroupBoxWidget",
      parent: "grpbxClean",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxCleanGrossWt_PROPERTIES

      // END_USER_CODE-USER_grpbxCleanGrossWt_PROPERTIES
    },
    grpbxCleanGrossWtRow0: {
      name: "grpbxCleanGrossWtRow0",
      type: "GroupBoxWidget",
      parent: "grpbxCleanGrossWt",
      ColSpan: "5",
      Height: "",
      Width: "",
      clonedExtId: "56894",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxCleanGrossWtRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxCleanGrossWtRow0_PROPERTIES
    },
    grpbxCleanGrossWtRow1: {
      name: "grpbxCleanGrossWtRow1",
      type: "GroupBoxWidget",
      parent: "grpbxCleanGrossWt",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxCleanGrossWtRow1_PROPERTIES

      // END_USER_CODE-USER_grpbxCleanGrossWtRow1_PROPERTIES
    },
    grpbxCleanGrossWtRow2: {
      name: "grpbxCleanGrossWtRow2",
      type: "GroupBoxWidget",
      parent: "grpbxCleanGrossWt",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxCleanGrossWtRow2_PROPERTIES

      // END_USER_CODE-USER_grpbxCleanGrossWtRow2_PROPERTIES
    },
    grpbxCleanNetWt: {
      name: "grpbxCleanNetWt",
      type: "GroupBoxWidget",
      parent: "grpbxClean",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCleanNetWt_PROPERTIES

      // END_USER_CODE-USER_grpbxCleanNetWt_PROPERTIES
    },
    grpbxCleanNetWtRow: {
      name: "grpbxCleanNetWtRow",
      type: "GroupBoxWidget",
      parent: "grpbxCleanNetWt",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCleanNetWtRow_PROPERTIES

      // END_USER_CODE-USER_grpbxCleanNetWtRow_PROPERTIES
    },
    grpbxCleanOther: {
      name: "grpbxCleanOther",
      type: "GroupBoxWidget",
      parent: "grpbxClean",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCleanOther_PROPERTIES

      // END_USER_CODE-USER_grpbxCleanOther_PROPERTIES
    },
    grpbxCleanOtherRow: {
      name: "grpbxCleanOtherRow",
      type: "GroupBoxWidget",
      parent: "grpbxCleanOther",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCleanOtherRow_PROPERTIES

      // END_USER_CODE-USER_grpbxCleanOtherRow_PROPERTIES
    },
    grpbxCureClean: {
      name: "grpbxCureClean",
      type: "GroupBoxWidget",
      parent: "grpbxCleaningCuringCosts",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCureClean_PROPERTIES

      // END_USER_CODE-USER_grpbxCureClean_PROPERTIES
    },
    grpbxCureCleanGrossWt: {
      name: "grpbxCureCleanGrossWt",
      type: "GroupBoxWidget",
      parent: "grpbxCureClean",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxCureCleanGrossWt_PROPERTIES

      // END_USER_CODE-USER_grpbxCureCleanGrossWt_PROPERTIES
    },
    grpbxCureCleanGrossWtRow0: {
      name: "grpbxCureCleanGrossWtRow0",
      type: "GroupBoxWidget",
      parent: "grpbxCureCleanGrossWt",
      ColSpan: "6",
      Height: "",
      Width: "",
      clonedExtId: "56863",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxCureCleanGrossWtRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxCureCleanGrossWtRow0_PROPERTIES
    },
    grpbxCureCleanGrossWtRow1: {
      name: "grpbxCureCleanGrossWtRow1",
      type: "GroupBoxWidget",
      parent: "grpbxCureCleanGrossWt",
      ColSpan: "6",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxCureCleanGrossWtRow1_PROPERTIES

      // END_USER_CODE-USER_grpbxCureCleanGrossWtRow1_PROPERTIES
    },
    grpbxCureCleanGrossWtRow2: {
      name: "grpbxCureCleanGrossWtRow2",
      type: "GroupBoxWidget",
      parent: "grpbxCureCleanGrossWt",
      ColSpan: "6",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxCureCleanGrossWtRow2_PROPERTIES

      // END_USER_CODE-USER_grpbxCureCleanGrossWtRow2_PROPERTIES
    },
    grpbxCureCleanNetWt: {
      name: "grpbxCureCleanNetWt",
      type: "GroupBoxWidget",
      parent: "grpbxCureClean",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxCureCleanNetWt_PROPERTIES

      // END_USER_CODE-USER_grpbxCureCleanNetWt_PROPERTIES
    },
    grpbxCureCleanNetWtRow: {
      name: "grpbxCureCleanNetWtRow",
      type: "GroupBoxWidget",
      parent: "grpbxCureCleanNetWt",
      ColSpan: "4",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxCureCleanNetWtRow_PROPERTIES

      // END_USER_CODE-USER_grpbxCureCleanNetWtRow_PROPERTIES
    },
    grpbxCureCleanOther: {
      name: "grpbxCureCleanOther",
      type: "GroupBoxWidget",
      parent: "grpbxCureClean",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxCureCleanOther_PROPERTIES

      // END_USER_CODE-USER_grpbxCureCleanOther_PROPERTIES
    },
    grpbxCureCleanOtherRow: {
      name: "grpbxCureCleanOtherRow",
      type: "GroupBoxWidget",
      parent: "grpbxCureCleanOther",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      // START_USER_CODE-USER_grpbxCureCleanOtherRow_PROPERTIES

      // END_USER_CODE-USER_grpbxCureCleanOtherRow_PROPERTIES
    },
    grpbxRadioClean: {
      name: "grpbxRadioClean",
      type: "GroupBoxWidget",
      parent: "grpbxClean",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxRadioClean_PROPERTIES

      // END_USER_CODE-USER_grpbxRadioClean_PROPERTIES
    },
    grpbxRadioCureClean: {
      name: "grpbxRadioCureClean",
      type: "GroupBoxWidget",
      parent: "grpbxCureClean",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxRadioCureClean_PROPERTIES

      // END_USER_CODE-USER_grpbxRadioCureClean_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblCCCost: {
      name: "lblCCCost",
      type: "LabelWidget",
      parent: "grpbxCureCleanGrossWt",
      Label: "Cost",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCCost_PROPERTIES

      // END_USER_CODE-USER_lblCCCost_PROPERTIES
    },
    lblCCDryWt: {
      name: "lblCCDryWt",
      type: "LabelWidget",
      parent: "grpbxCureCleanGrossWt",
      Label: "Dry Wt",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCDryWt_PROPERTIES

      // END_USER_CODE-USER_lblCCDryWt_PROPERTIES
    },
    lblCCGreenWt: {
      name: "lblCCGreenWt",
      type: "LabelWidget",
      parent: "grpbxCureCleanGrossWt",
      Label: "Green Wt.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCGreenWt_PROPERTIES

      // END_USER_CODE-USER_lblCCGreenWt_PROPERTIES
    },
    lblCCMoisture: {
      name: "lblCCMoisture",
      type: "LabelWidget",
      parent: "grpbxCureCleanGrossWt",
      Label: "Moisture",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCMoisture_PROPERTIES

      // END_USER_CODE-USER_lblCCMoisture_PROPERTIES
    },
    lblCCNetWeight: {
      name: "lblCCNetWeight",
      type: "LabelWidget",
      parent: "grpbxCureCleanNetWt",
      Label: "Weight",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCNetWeight_PROPERTIES

      // END_USER_CODE-USER_lblCCNetWeight_PROPERTIES
    },
    lblCCNWCost: {
      name: "lblCCNWCost",
      type: "LabelWidget",
      parent: "grpbxCureCleanNetWt",
      Label: "Cost",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCNWCost_PROPERTIES

      // END_USER_CODE-USER_lblCCNWCost_PROPERTIES
    },
    lblCCNWMoisture: {
      name: "lblCCNWMoisture",
      type: "LabelWidget",
      parent: "grpbxCureCleanNetWt",
      Label: "Moisture",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCNWMoisture_PROPERTIES

      // END_USER_CODE-USER_lblCCNWMoisture_PROPERTIES
    },
    lblCCNWSchedule: {
      name: "lblCCNWSchedule",
      type: "LabelWidget",
      parent: "grpbxCureCleanNetWt",
      Label: "Schedule",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCNWSchedule_PROPERTIES

      // END_USER_CODE-USER_lblCCNWSchedule_PROPERTIES
    },
    lblCCOCost: {
      name: "lblCCOCost",
      type: "LabelWidget",
      parent: "grpbxCureCleanOther",
      Label: "Cost",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCOCost_PROPERTIES

      // END_USER_CODE-USER_lblCCOCost_PROPERTIES
    },
    lblCCOMoisture: {
      name: "lblCCOMoisture",
      type: "LabelWidget",
      parent: "grpbxCureCleanOther",
      Label: "Moisture",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCOMoisture_PROPERTIES

      // END_USER_CODE-USER_lblCCOMoisture_PROPERTIES
    },
    lblCCOSchedule: {
      name: "lblCCOSchedule",
      type: "LabelWidget",
      parent: "grpbxCureCleanOther",
      Label: "Schedule",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCOSchedule_PROPERTIES

      // END_USER_CODE-USER_lblCCOSchedule_PROPERTIES
    },
    lblCCost: {
      name: "lblCCost",
      type: "LabelWidget",
      parent: "grpbxCleanGrossWt",
      Label: "Cost",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCost_PROPERTIES

      // END_USER_CODE-USER_lblCCost_PROPERTIES
    },
    lblCCOWeight: {
      name: "lblCCOWeight",
      type: "LabelWidget",
      parent: "grpbxCureCleanOther",
      Label: "Weight",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCOWeight_PROPERTIES

      // END_USER_CODE-USER_lblCCOWeight_PROPERTIES
    },
    lblCCSchedule: {
      name: "lblCCSchedule",
      type: "LabelWidget",
      parent: "grpbxCureCleanGrossWt",
      Label: "Schedule",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCSchedule_PROPERTIES

      // END_USER_CODE-USER_lblCCSchedule_PROPERTIES
    },
    lblCCTicket: {
      name: "lblCCTicket",
      type: "LabelWidget",
      parent: "grpbxCureCleanGrossWt",
      Label: "Ticket:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCCTicket_PROPERTIES

      // END_USER_CODE-USER_lblCCTicket_PROPERTIES
    },
    lblCDryWt: {
      name: "lblCDryWt",
      type: "LabelWidget",
      parent: "grpbxCleanGrossWt",
      Label: "Dry Wt.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCDryWt_PROPERTIES

      // END_USER_CODE-USER_lblCDryWt_PROPERTIES
    },
    lblCGreenWt: {
      name: "lblCGreenWt",
      type: "LabelWidget",
      parent: "grpbxCleanGrossWt",
      Label: "Green Wt.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCGreenWt_PROPERTIES

      // END_USER_CODE-USER_lblCGreenWt_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblCleaning: {
      name: "lblCleaning",
      type: "LabelWidget",
      parent: "grpbxRadioClean",
      Label: "Cleaning",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCleaning_PROPERTIES

      // END_USER_CODE-USER_lblCleaning_PROPERTIES
    },
    lblCNetWeight: {
      name: "lblCNetWeight",
      type: "LabelWidget",
      parent: "grpbxCleanNetWt",
      Label: "Weight",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCNetWeight_PROPERTIES

      // END_USER_CODE-USER_lblCNetWeight_PROPERTIES
    },
    lblCNWCost: {
      name: "lblCNWCost",
      type: "LabelWidget",
      parent: "grpbxCleanNetWt",
      Label: "Cost",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCNWCost_PROPERTIES

      // END_USER_CODE-USER_lblCNWCost_PROPERTIES
    },
    lblCNWSchedule: {
      name: "lblCNWSchedule",
      type: "LabelWidget",
      parent: "grpbxCleanNetWt",
      Label: "Schedule",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCNWSchedule_PROPERTIES

      // END_USER_CODE-USER_lblCNWSchedule_PROPERTIES
    },
    lblCOCost: {
      name: "lblCOCost",
      type: "LabelWidget",
      parent: "grpbxCleanOther",
      Label: "Cost",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCOCost_PROPERTIES

      // END_USER_CODE-USER_lblCOCost_PROPERTIES
    },
    lblCOSchedule: {
      name: "lblCOSchedule",
      type: "LabelWidget",
      parent: "grpbxCleanOther",
      Label: "Schedule",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCOSchedule_PROPERTIES

      // END_USER_CODE-USER_lblCOSchedule_PROPERTIES
    },
    lblCOWeight: {
      name: "lblCOWeight",
      type: "LabelWidget",
      parent: "grpbxCleanOther",
      Label: "Weight",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCOWeight_PROPERTIES

      // END_USER_CODE-USER_lblCOWeight_PROPERTIES
    },
    lblCSchedule: {
      name: "lblCSchedule",
      type: "LabelWidget",
      parent: "grpbxCleanGrossWt",
      Label: "Schedule",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCSchedule_PROPERTIES

      // END_USER_CODE-USER_lblCSchedule_PROPERTIES
    },
    lblCTicket: {
      name: "lblCTicket",
      type: "LabelWidget",
      parent: "grpbxCleanGrossWt",
      Label: "Ticket:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCTicket_PROPERTIES

      // END_USER_CODE-USER_lblCTicket_PROPERTIES
    },
    radioCleanGrossWt: {
      name: "radioCleanGrossWt",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxCleanGrossWt",
      Options: "Gross Weight:1",
      ColSpan: "5",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioCleanGrossWt_PROPERTIES

      // END_USER_CODE-USER_radioCleanGrossWt_PROPERTIES
    },
    radioCleanNetWt: {
      name: "radioCleanNetWt",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxCleanNetWt",
      Options: "Net Weight:1",
      ColSpan: "3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioCleanNetWt_PROPERTIES

      // END_USER_CODE-USER_radioCleanNetWt_PROPERTIES
    },
    radioCleanOther: {
      name: "radioCleanOther",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxCleanOther",
      Options: "Other:1",
      ColSpan: "3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioCleanOther_PROPERTIES

      // END_USER_CODE-USER_radioCleanOther_PROPERTIES
    },
    radioCureClean: {
      name: "radioCureClean",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxRadioCureClean",
      Options: "Curing:1,Cure and Clean:2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioCureClean_PROPERTIES

      // END_USER_CODE-USER_radioCureClean_PROPERTIES
    },
    radioCureCleanGrossWt: {
      name: "radioCureCleanGrossWt",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxCureCleanGrossWt",
      Options: "Gross Weight:1",
      ColSpan: "6",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioCureCleanGrossWt_PROPERTIES

      // END_USER_CODE-USER_radioCureCleanGrossWt_PROPERTIES
    },
    radioCureCleanNetWt: {
      name: "radioCureCleanNetWt",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxCureCleanNetWt",
      Options: "Net Weight:1",
      ColSpan: "4",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioCureCleanNetWt_PROPERTIES

      // END_USER_CODE-USER_radioCureCleanNetWt_PROPERTIES
    },
    radioCureCleanOther: {
      name: "radioCureCleanOther",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxCureCleanOther",
      Options: "Other:1",
      ColSpan: "4",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioCureCleanOther_PROPERTIES

      // END_USER_CODE-USER_radioCureCleanOther_PROPERTIES
    },
    txtCCCost0: {
      name: "txtCCCost0",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow0",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCCost0_PROPERTIES

      // END_USER_CODE-USER_txtCCCost0_PROPERTIES
    },
    txtCCCost1: {
      name: "txtCCCost1",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCCost1_PROPERTIES

      // END_USER_CODE-USER_txtCCCost1_PROPERTIES
    },
    txtCCCost2: {
      name: "txtCCCost2",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCCost2_PROPERTIES

      // END_USER_CODE-USER_txtCCCost2_PROPERTIES
    },
    txtCCDryWt0: {
      name: "txtCCDryWt0",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow0",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCDryWt0_PROPERTIES

      // END_USER_CODE-USER_txtCCDryWt0_PROPERTIES
    },
    txtCCDryWt1: {
      name: "txtCCDryWt1",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCDryWt1_PROPERTIES

      // END_USER_CODE-USER_txtCCDryWt1_PROPERTIES
    },
    txtCCDryWt2: {
      name: "txtCCDryWt2",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCDryWt2_PROPERTIES

      // END_USER_CODE-USER_txtCCDryWt2_PROPERTIES
    },
    txtCCGreenWt0: {
      name: "txtCCGreenWt0",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow0",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCGreenWt0_PROPERTIES

      // END_USER_CODE-USER_txtCCGreenWt0_PROPERTIES
    },
    txtCCGreenWt1: {
      name: "txtCCGreenWt1",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCGreenWt1_PROPERTIES

      // END_USER_CODE-USER_txtCCGreenWt1_PROPERTIES
    },
    txtCCGreenWt2: {
      name: "txtCCGreenWt2",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCGreenWt2_PROPERTIES

      // END_USER_CODE-USER_txtCCGreenWt2_PROPERTIES
    },
    txtCCMoisture0: {
      name: "txtCCMoisture0",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCMoisture0_PROPERTIES

      // END_USER_CODE-USER_txtCCMoisture0_PROPERTIES
    },
    txtCCMoisture1: {
      name: "txtCCMoisture1",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCMoisture1_PROPERTIES

      // END_USER_CODE-USER_txtCCMoisture1_PROPERTIES
    },
    txtCCMoisture2: {
      name: "txtCCMoisture2",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCMoisture2_PROPERTIES

      // END_USER_CODE-USER_txtCCMoisture2_PROPERTIES
    },
    txtCCNetWeight: {
      name: "txtCCNetWeight",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanNetWtRow",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCNetWeight_PROPERTIES

      // END_USER_CODE-USER_txtCCNetWeight_PROPERTIES
    },
    txtCCNWCost: {
      name: "txtCCNWCost",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanNetWtRow",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCNWCost_PROPERTIES

      // END_USER_CODE-USER_txtCCNWCost_PROPERTIES
    },
    txtCCNWMoisture: {
      name: "txtCCNWMoisture",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanNetWtRow",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCNWMoisture_PROPERTIES

      // END_USER_CODE-USER_txtCCNWMoisture_PROPERTIES
    },
    txtCCOCost: {
      name: "txtCCOCost",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanOtherRow",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCOCost_PROPERTIES

      // END_USER_CODE-USER_txtCCOCost_PROPERTIES
    },
    txtCCOMoisture: {
      name: "txtCCOMoisture",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanOtherRow",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCOMoisture_PROPERTIES

      // END_USER_CODE-USER_txtCCOMoisture_PROPERTIES
    },
    txtCCost0: {
      name: "txtCCost0",
      type: "TextBoxWidget",
      parent: "grpbxCleanGrossWtRow0",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCost0_PROPERTIES

      // END_USER_CODE-USER_txtCCost0_PROPERTIES
    },
    txtCCost1: {
      name: "txtCCost1",
      type: "TextBoxWidget",
      parent: "grpbxCleanGrossWtRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCost1_PROPERTIES

      // END_USER_CODE-USER_txtCCost1_PROPERTIES
    },
    txtCCost2: {
      name: "txtCCost2",
      type: "TextBoxWidget",
      parent: "grpbxCleanGrossWtRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCost2_PROPERTIES

      // END_USER_CODE-USER_txtCCost2_PROPERTIES
    },
    txtCCOWeight: {
      name: "txtCCOWeight",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanOtherRow",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCOWeight_PROPERTIES

      // END_USER_CODE-USER_txtCCOWeight_PROPERTIES
    },
    txtCCTicket0: {
      name: "txtCCTicket0",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow0",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCTicket0_PROPERTIES

      // END_USER_CODE-USER_txtCCTicket0_PROPERTIES
    },
    txtCCTicket1: {
      name: "txtCCTicket1",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCTicket1_PROPERTIES

      // END_USER_CODE-USER_txtCCTicket1_PROPERTIES
    },
    txtCCTicket2: {
      name: "txtCCTicket2",
      type: "TextBoxWidget",
      parent: "grpbxCureCleanGrossWtRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCTicket2_PROPERTIES

      // END_USER_CODE-USER_txtCCTicket2_PROPERTIES
    },
    txtCDryWt0: {
      name: "txtCDryWt0",
      type: "TextBoxWidget",
      parent: "grpbxCleanGrossWtRow0",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCDryWt0_PROPERTIES

      // END_USER_CODE-USER_txtCDryWt0_PROPERTIES
    },
    txtCDryWt1: {
      name: "txtCDryWt1",
      type: "TextBoxWidget",
      parent: "grpbxCleanGrossWtRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCDryWt1_PROPERTIES

      // END_USER_CODE-USER_txtCDryWt1_PROPERTIES
    },
    txtCDryWt2: {
      name: "txtCDryWt2",
      type: "TextBoxWidget",
      parent: "grpbxCleanGrossWtRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCDryWt2_PROPERTIES

      // END_USER_CODE-USER_txtCDryWt2_PROPERTIES
    },
    txtCGreenWt0: {
      name: "txtCGreenWt0",
      type: "TextBoxWidget",
      parent: "grpbxCleanGrossWtRow0",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCGreenWt0_PROPERTIES

      // END_USER_CODE-USER_txtCGreenWt0_PROPERTIES
    },
    txtCGreenWt1: {
      name: "txtCGreenWt1",
      type: "TextBoxWidget",
      parent: "grpbxCleanGrossWtRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCGreenWt1_PROPERTIES

      // END_USER_CODE-USER_txtCGreenWt1_PROPERTIES
    },
    txtCGreenWt2: {
      name: "txtCGreenWt2",
      type: "TextBoxWidget",
      parent: "grpbxCleanGrossWtRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCGreenWt2_PROPERTIES

      // END_USER_CODE-USER_txtCGreenWt2_PROPERTIES
    },
    txtCleanCureTicketNum: {
      name: "txtCleanCureTicketNum",
      type: "TextBoxWidget",
      parent: "grpbxCleaningCuringCosts",
      Enabled: false,
      Label: "Cleaning/Curing Ticket #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCleanCureTicketNum_PROPERTIES

      // END_USER_CODE-USER_txtCleanCureTicketNum_PROPERTIES
    },
    txtCNetWeight: {
      name: "txtCNetWeight",
      type: "TextBoxWidget",
      parent: "grpbxCleanNetWtRow",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCNetWeight_PROPERTIES

      // END_USER_CODE-USER_txtCNetWeight_PROPERTIES
    },
    txtCNWCost: {
      name: "txtCNWCost",
      type: "TextBoxWidget",
      parent: "grpbxCleanNetWtRow",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCNWCost_PROPERTIES

      // END_USER_CODE-USER_txtCNWCost_PROPERTIES
    },
    txtCOCost: {
      name: "txtCOCost",
      type: "TextBoxWidget",
      parent: "grpbxCleanOtherRow",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCOCost_PROPERTIES

      // END_USER_CODE-USER_txtCOCost_PROPERTIES
    },
    txtCostToClean: {
      name: "txtCostToClean",
      type: "TextBoxWidget",
      parent: "grpbxCleaningCuringCosts",
      Label: "Cost to Clean:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCostToClean_PROPERTIES

      // END_USER_CODE-USER_txtCostToClean_PROPERTIES
    },
    txtCostToCure: {
      name: "txtCostToCure",
      type: "TextBoxWidget",
      parent: "grpbxCleaningCuringCosts",
      Label: "Cost to Cure:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCostToCure_PROPERTIES

      // END_USER_CODE-USER_txtCostToCure_PROPERTIES
    },
    txtCOWeight: {
      name: "txtCOWeight",
      type: "TextBoxWidget",
      parent: "grpbxCleanOtherRow",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCOWeight_PROPERTIES

      // END_USER_CODE-USER_txtCOWeight_PROPERTIES
    },
    txtCTicket0: {
      name: "txtCTicket0",
      type: "TextBoxWidget",
      parent: "grpbxCleanGrossWtRow0",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCTicket0_PROPERTIES

      // END_USER_CODE-USER_txtCTicket0_PROPERTIES
    },
    txtCTicket1: {
      name: "txtCTicket1",
      type: "TextBoxWidget",
      parent: "grpbxCleanGrossWtRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCTicket1_PROPERTIES

      // END_USER_CODE-USER_txtCTicket1_PROPERTIES
    },
    txtCTicket2: {
      name: "txtCTicket2",
      type: "TextBoxWidget",
      parent: "grpbxCleanGrossWtRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCTicket2_PROPERTIES

      // END_USER_CODE-USER_txtCTicket2_PROPERTIES
    },
    grpbxCleaningCuringCosts: {
      name: "grpbxCleaningCuringCosts",
      type: "GroupBoxWidget",
      parent: "CleaningCuringCosts",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCleaningCuringCosts_PROPERTIES

      // END_USER_CODE-USER_grpbxCleaningCuringCosts_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "CleaningCuringCosts",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    resetCuringFields();
    resetCleaningFields();
    ReadCleaningCuringCosts();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const [loading, setLoading] = useState(true);

  const getRadioCureCleanValue = () => {
    let selectedRadioValue = '0';
    if (getValue(thisObj, 'radioCureCleanGrossWt') === '1') {
      selectedRadioValue = '1';
    } else if (getValue(thisObj, 'radioCureCleanNetWt') === '1') {
      selectedRadioValue = '2';
    } else if (getValue(thisObj, 'radioCureCleanOther') === '1') {
      selectedRadioValue = '3';
    }
    return selectedRadioValue;
  };

  const setRadioCureCleanValue = (newValue) => {
    switch (newValue) {
      case '1':
        setValue(thisObj, 'radioCureCleanGrossWt', '1');
        setValue(thisObj, 'radioCureCleanNetWt', '0');
        setValue(thisObj, 'radioCureCleanOther', '0');
        break;
      case '2':
        setValue(thisObj, 'radioCureCleanGrossWt', '0');
        setValue(thisObj, 'radioCureCleanNetWt', '1');
        setValue(thisObj, 'radioCureCleanOther', '0');
        break;
      case '3':
        setValue(thisObj, 'radioCureCleanGrossWt', '0');
        setValue(thisObj, 'radioCureCleanNetWt', '0');
        setValue(thisObj, 'radioCureCleanOther', '1');
        break;
      default:
        break;
    }
  };

  const getRadioCleanValue = () => {
    let selectedRadioValue = '0';
    if (getValue(thisObj, 'radioCleanGrossWt') === '1') {
      selectedRadioValue = '1';
    } else if (getValue(thisObj, 'radioCleanNetWt') === '1') {
      selectedRadioValue = '2';
    } else if (getValue(thisObj, 'radioCleanOther') === '1') {
      selectedRadioValue = '3';
    }
    return selectedRadioValue;
  };

  const setRadioCleanValue = (newValue) => {
    switch (newValue) {
      case '1':
        setValue(thisObj, 'radioCleanGrossWt', '1');
        setValue(thisObj, 'radioCleanNetWt', '0');
        setValue(thisObj, 'radioCleanOther', '0');
        break;
      case '2':
        setValue(thisObj, 'radioCleanGrossWt', '0');
        setValue(thisObj, 'radioCleanNetWt', '1');
        setValue(thisObj, 'radioCleanOther', '0');
        break;
      case '3':
        setValue(thisObj, 'radioCleanGrossWt', '0');
        setValue(thisObj, 'radioCleanNetWt', '0');
        setValue(thisObj, 'radioCleanOther', '1');
        break;
      default:
        break;
    }
  };

  function enableDisableControls() {
    const parentFormData = getData(thisObj, 'parentForm') || { Name: 'frmFV95' };
    if (!['frmFV95', 'frm1007Settlement'].includes(parentFormData.Name)) {
      disable(thisObj, 'btnOkuic0');

      disable(thisObj, 'radioCureClean');
      disable(thisObj, 'radioCureCleanGrossWt');
      disable(thisObj, 'radioCureCleanNetWt');
      disable(thisObj, 'radioCureCleanOther');
      disable(thisObj, 'radioCleanGrossWt');
      disable(thisObj, 'radioCleanNetWt');
      disable(thisObj, 'radioCleanOther');
      disable(thisObj, 'txtCleanCureTicketNum');
      enableDisableCuringFields();
      enableDisableCleaningFields();
    } else {
      enable(thisObj, 'txtCleanCureTicketNum');
    }
    if (loading === true) {
      setLoading(false);
    }
  }

  function roundTo(n, digits) {
    var negative = false;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n = n * -1;
    }
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(digits);
    if (negative) {
      n = (n * -1).toFixed(digits);
    }
    return n;
  }

  const fractionOption =  {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  function setDataForFillCureCleanSchedule(cure_clean_ind = "", dry_green_ind = "") {
    let FillCureCleanScheduleDataobj = {};
    const cleaningCuringCostsOnLoadData = getData(thisObj, 'CleaningCuringCostsOnLoadData');
    FillCureCleanScheduleDataobj.buy_pt_id = cleaningCuringCostsOnLoadData.buy_pt_id;
    FillCureCleanScheduleDataobj.cure_clean_ind = cure_clean_ind;
    FillCureCleanScheduleDataobj.dry_green_ind = dry_green_ind;
    setData(thisObj, 'FillCureCleanScheduleData', FillCureCleanScheduleDataobj);
  }

  const ReadCleaningCuringCosts = () => {
    try {
      const CleaningCuringCostsData = getData(thisObj, 'CleaningCuringCostsOnLoadData');

      if (CleaningCuringCostsData.buy_pt_id != null && CleaningCuringCostsData.buy_pt_id != undefined &&
        CleaningCuringCostsData.insp_num != null && CleaningCuringCostsData.insp_num != undefined) {
        const correction = CleaningCuringCostsData.mbCorrectionProcess ? 'Y' : 'N';
        SettlementService.RetrieveCuringCleaningCostsDetails(CleaningCuringCostsData.buy_pt_id, CleaningCuringCostsData.insp_num, correction)
          .then(response => {
            setData(thisObj, 'lstrxml', response);
            try {
              let data = response;
              for (var i = 0; i < data.length; i++) {
                const cureCleanCostDetail = data[i];
                const inspectScaleTickets = cureCleanCostDetail.inspect_scale_ticket;
                setValue(thisObj, 'radioCureClean', cureCleanCostDetail.cure_clean_ind === 'C/C' ? '2' : '1');

                setValue(thisObj, 'txtCCNetWeight', parseFloat(cureCleanCostDetail.neT_WT.toString()).toLocaleString('en-US'));
                setValue(thisObj, 'txtCNetWeight', parseFloat(cureCleanCostDetail.neT_WT.toString()).toLocaleString('en-US'));

                setValue(thisObj, 'txtCOWeight', cureCleanCostDetail.tickeT_CLEAN_WT_OTHER);

                // Set Added by and Changed by from first inspect_scale_ticket
                setValue(thisObj, 'lblAddedByValue',
                  inspectScaleTickets[0].add_user + " "
                  + inspectScaleTickets[0].add_date_time);
                setValue(thisObj, 'lblChangedByValue',
                  inspectScaleTickets[0].chg_user + " "
                  + inspectScaleTickets[0].chg_date_time);

                // Set Clean Cure ticket number
                setValue(thisObj, 'txtCleanCureTicketNum', cureCleanCostDetail.tickeT_NUMBERS_CURE_CLEAN);

                setCuringData(cureCleanCostDetail);
                setCleaningData(cureCleanCostDetail);
              }
              return true;
            } catch (error) {
              showMessage(
                thisObj,
                "Error occurred during CleaningCuringCosts load event"
              );
              return false;
            }
          });
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during CleaningCuringCosts load event"
        );
      }
      return false;
    }
  }

  function setCuringData(cureCleanCostDetail, curingSelectedRadio) {
    try {
      setValue(thisObj, 'txtCostToCure', '0.00');
      const inspectScaleTickets = cureCleanCostDetail.inspect_scale_ticket;
      // Loop inspect_scale_ticket array
      for (let i = 0; i < inspectScaleTickets.length; i++) {
        const ticketData = inspectScaleTickets[i];

        setValue(thisObj, `txtCCTicket${i}`, ticketData.dry_ticket);
        setValue(thisObj, `txtCCDryWt${i}`, parseFloat(ticketData.dry_peanut_wt.toString()).toLocaleString('en-US'));
        setValue(thisObj, `txtCCGreenWt${i}`, parseFloat(ticketData.green_peanut_wt.toString()).toLocaleString('en-US'));
      }

      // Cost to cure should be automatically calculated
      // // setValue(thisObj, `txtCCCost${i}`, ticketData.cost_to_cure);
      let selectedCuringDryGreenInd = null;
      const moistureValues = [];
      const indexValues = [];
      const findWtIndex = curingSelectedRadio ? curingSelectedRadio : cureCleanCostDetail.tickeT_CURING_WT_IND;
      switch (findWtIndex) {
        case 'G':
          enableDisableCuringFields('1');
          setRadioCureCleanValue('1');
          for (let i = 0; i < inspectScaleTickets.length; i++) {
            const ticketData = inspectScaleTickets[i];

            setValue(thisObj, `txtCCMoisture${i}`, ticketData.moisture_pct);
            // Multiple records here
            if (ticketData.cure_schedule) {
              setValue(thisObj, `ddCCSchedule${i}`, ticketData.cure_schedule)
            }
            selectedCuringDryGreenInd = 'D,G';
            const moistureValueGW = curingSelectedRadio ? 0 : parseFloat(ticketData.moisture_pct?.toString() || '0.00');
            moistureValues.push(moistureValueGW);
            indexValues.push(i);
          }
          break;
        case 'N':
          enableDisableCuringFields('2');
          setRadioCureCleanValue('2');
          setValue(thisObj, 'txtCCNetWeight', parseFloat(cureCleanCostDetail.neT_WT.toString()).toLocaleString('en-US'));
          setValue(thisObj, 'txtCCNWMoisture', cureCleanCostDetail.tickeT_MOISTURE);
          setValue(thisObj, 'ddCCNWSchedule', cureCleanCostDetail.tickeT_CURE_SCHEDULE);
          selectedCuringDryGreenInd = 'N';
          const moistureValueNW = curingSelectedRadio ? 0 : parseFloat(cureCleanCostDetail.tickeT_MOISTURE?.toString() || '0.00');
          moistureValues.push(moistureValueNW);
          indexValues.push(0);
          break;
        case 'O':
          enableDisableCuringFields('3');
          setRadioCureCleanValue('3');
          setValue(thisObj, 'txtCCOWeight', parseFloat(cureCleanCostDetail.tickeT_CURE_WT_OTHER.toString()).toLocaleString('en-US'));
          setValue(thisObj, 'txtCCOMoisture', cureCleanCostDetail.tickeT_MOISTURE);
          setValue(thisObj, 'ddCCOSchedule', cureCleanCostDetail.tickeT_CURE_SCHEDULE);
          selectedCuringDryGreenInd = 'O';
          const moistureValueOther = curingSelectedRadio ? 0 : parseFloat(cureCleanCostDetail.tickeT_MOISTURE?.toString() || '0.00');
          moistureValues.push(moistureValueOther);
          indexValues.push(0);
          break;
      }
      setData(thisObj, 'curingMoistureValues', moistureValues);
      setData(thisObj, 'curingIndexValues', indexValues);
      if (selectedCuringDryGreenInd) {
        fillInitialCuringSchedule(selectedCuringDryGreenInd, curingSelectedRadio);
      }
    } catch (error) {
    }
  }

  function setCleaningData(cureCleanCostDetail) {
    setValue(thisObj, 'txtCostToClean', '0.00');
    const inspectScaleTickets = cureCleanCostDetail.inspect_scale_ticket;
    for (let i = 0; i < inspectScaleTickets.length; i++) {
      const ticketData = inspectScaleTickets[i];
      setValue(thisObj, `txtCTicket${i}`, ticketData.dry_ticket);
      setValue(thisObj, `txtCDryWt${i}`, parseFloat(ticketData.dry_peanut_wt.toString()).toLocaleString('en-US'));
      setValue(thisObj, `txtCGreenWt${i}`, parseFloat(ticketData.green_peanut_wt.toString()).toLocaleString('en-US'));
    }
    // Cost to clean should be automatically calculated
    // // setValue(thisObj, `txtCCost${i}`, ticketData.cost_to_clean);

    let selectedCleaningRadio = null;
    switch (cureCleanCostDetail.tickeT_CLEANING_WT_IND) {
      case 'G':
        enableDisableCleaningFields('1');
        setRadioCleanValue('1');
        // Clean Multiple records here
        for (let i = 0; i < inspectScaleTickets.length; i++) {
          const ticketData = inspectScaleTickets[i];

          if (ticketData.clean_schedule) {
            setValue(thisObj, `ddCSchedule${i}`, ticketData.clean_schedule);
          }
          selectedCleaningRadio = '1';
        }
        break;
      case 'N':
        enableDisableCleaningFields('2');
        setRadioCleanValue('2');
        setValue(thisObj, 'txtCNetWeight', parseFloat(cureCleanCostDetail.neT_WT.toString()).toLocaleString('en-US'));
        setValue(thisObj, 'ddCNWSchedule', cureCleanCostDetail.tickeT_CLEAN_SCHEDULE);
        selectedCleaningRadio = '2';
        break;
      case 'O':
        enableDisableCleaningFields('3');
        setRadioCleanValue('3');
        setValue(thisObj, 'txtCOWeight', parseFloat(cureCleanCostDetail.tickeT_CLEAN_WT_OTHER.toString()).toLocaleString('en-US'));
        setValue(thisObj, 'ddCOSchedule', cureCleanCostDetail.tickeT_CLEAN_SCHEDULE);
        selectedCleaningRadio = '3';
        break;
    }
    if (selectedCleaningRadio) {
      fillCleanSchedule(null, selectedCleaningRadio);
    }
  }

  //------------------------------------------- Start fillCureSchedule -------------------------------------
  function fillInitialCuringSchedule(dryGreenInd = "", curingSelectedRadio = null) {
    let strCC_Flag = 'CUR';

    if (getValue(thisObj, 'radioCureClean') === '2') {
      strCC_Flag = "C/C";
    }
    try {
      setDataForFillCureCleanSchedule(strCC_Flag, dryGreenInd);
      const FillInitialCureScheduleData = getData(thisObj, 'FillCureCleanScheduleData');
      SettlementService.RetrieveCureCleanScheduleDetails(
        FillInitialCureScheduleData.buy_pt_id,
        FillInitialCureScheduleData.cure_clean_ind,
        FillInitialCureScheduleData.dry_green_ind
      )
        .then(response => {
          const moistureValues = getData(thisObj, 'curingMoistureValues');
          const indexValues = getData(thisObj, 'curingIndexValues');
          for (let i = 0; i < moistureValues.length; i++) {
            const indexValue = indexValues[i];
            const moistureValue = moistureValues[i];
            calculateCuringValues(response, moistureValue, indexValue, dryGreenInd, curingSelectedRadio);
          }
          return true;
        })
        .catch(err => {
          if (err instanceof EvalError) {
            showMessage(thisObj, err.message);
          } else {
            showMessage(
              thisObj,
              "Error occurred during fillInitialCureSchedule function"
            );
          }
          return false;
        })
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during fillInitialCureSchedule function"
        );
      }
      return false;
    }
  }

  function calculateCuringValues(curingResponse, moistureValue, index = 0, dryGreenInd = "", curingSelectedRadio = null) {
    let listObjComboBox = [];
    listObjComboBox.push({ key: '', description: '<<< Select >>>' });
    if (index === 0) {
      listCureScheduleId = [];
      listCureScheduleNum = [];
      listCureDryGreenInd = [];
      listCostToCure = [];
      listCureRateType = [];
      listCurePDCode = [];

      listCureScheduleId.push('');
      listCureScheduleNum.push('');
      listCureDryGreenInd.push('');
      listCostToCure.push('');
      listCureRateType.push('');
      listCurePDCode.push('');
    } else if (index === 1) {
      listCureScheduleId1 = [];
      listCureScheduleNum1 = [];
      listCureDryGreenInd1 = [];
      listCostToCure1 = [];
      listCureRateType1 = [];
      listCurePDCode1 = [];

      listCureScheduleId1.push('');
      listCureScheduleNum1.push('');
      listCureDryGreenInd1.push('');
      listCostToCure1.push('');
      listCureRateType1.push('');
      listCurePDCode1.push('');
    } else if (index === 2) {
      listCureScheduleId2 = [];
      listCureScheduleNum2 = [];
      listCureDryGreenInd2 = [];
      listCostToCure2 = [];
      listCureRateType2 = [];
      listCurePDCode2 = [];

      listCureScheduleId2.push('');
      listCureScheduleNum2.push('');
      listCureDryGreenInd2.push('');
      listCostToCure2.push('');
      listCureRateType2.push('');
      listCurePDCode2.push('');
    }

    const cureCleanCostDetails = getData(thisObj, 'lstrxml');
    const cureCleanCostDetail = cureCleanCostDetails[cureCleanCostDetails.length - 1];
    const ticketData = cureCleanCostDetail?.inspect_scale_ticket?.[index];
    let nwCostToCure = 0, otherCostToCure = 0, scheduleListCount = 0, firstSelectedSchedule = 0, secondSelectedSchedule = 0;
    const selectedCuring = curingSelectedRadio || cureCleanCostDetail.tickeT_CURING_WT_IND;
    try {
      for (let curingScheduleIndex = 0; curingScheduleIndex < curingResponse.length; curingScheduleIndex++) {
        const curingSchedule = curingResponse[curingScheduleIndex];
        let firstGrossWtIndex = 0, secondGrossWtIndex = 0;
        if (curingSchedule.loMoistPct <= moistureValue && curingSchedule.hiMoistPct >= moistureValue) {
          if (curingSchedule.cureCleanInd.toUpperCase() === 'CUR') {
            scheduleListCount++;
            const dryWt = parseFloat(ticketData?.dry_peanut_wt?.toString() || '0.00');
            const greenWt = parseFloat(ticketData?.green_peanut_wt?.toString() || '0.00');

            if (selectedCuring === 'G'
              && ((curingSchedule.dryGreenInd.trim().toUpperCase() === 'D'
                && dryWt > 0)
                || (curingSchedule.dryGreenInd.trim().toUpperCase() === 'G'
                  && greenWt > 0))) {
              listObjComboBox.push({
                key: curingSchedule.scheduleId.trim(),
                description: curingSchedule.scheduleId.trim() + " - " + curingSchedule.scheduleDesc.trim()
              });

              if (index === 0) {
                firstGrossWtIndex = curingScheduleIndex;
                firstSelectedSchedule = scheduleListCount;
                listCureScheduleId.push(curingSchedule.scheduleId.trim());
                listCureScheduleNum.push(curingSchedule.scheduleNum);
                listCostToCure.push(curingSchedule.baseRate);
                listCureDryGreenInd.push(curingSchedule.dryGreenInd.trim());
                listCureRateType.push(curingSchedule.rateType.trim());
                listCurePDCode.push(curingSchedule.pdCode.trim());

                const dropdownValue = cureCleanCostDetail?.inspect_scale_ticket[index]?.cure_schedule || '';
                setValue(thisObj, `ddCCSchedule${index}`, dropdownValue);
                const hasScheduleMatched = dropdownValue === curingSchedule.scheduleId.trim();
                let costToCure0 = 0;
                if (hasScheduleMatched) {
                  costToCure0 = calcCostToCure(null, dryWt, greenWt, scheduleListCount, index);
                  setValue(thisObj, `txtCCCost${index}`, costToCure0 ? parseFloat(roundTo(costToCure0, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                }

                const costToCure1 = parseFloat(getValue(thisObj, 'txtCCCost1') || '0.00');
                const costToCure2 = parseFloat(getValue(thisObj, 'txtCCCost2') || '0.00');
                const totalCostToCure = costToCure0 + costToCure1 + costToCure2;
                setValue(thisObj, 'txtCostToCure', totalCostToCure ? parseFloat(roundTo(totalCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                if (hasScheduleMatched) {
                  break;
                }
              } else if (index === 1) {
                secondGrossWtIndex = curingScheduleIndex;
                secondSelectedSchedule = scheduleListCount;
                listCureScheduleId1.push(curingSchedule.scheduleId.trim());
                listCureScheduleNum1.push(curingSchedule.scheduleNum);
                listCostToCure1.push(curingSchedule.baseRate);
                listCureDryGreenInd1.push(curingSchedule.dryGreenInd.trim());
                listCureRateType1.push(curingSchedule.rateType.trim());
                listCurePDCode1.push(curingSchedule.pdCode.trim());
                const dropdownValue0 = cureCleanCostDetail?.inspect_scale_ticket[0]?.cure_schedule || '';
                const ticketData0 = cureCleanCostDetail?.inspect_scale_ticket?.[0];
                const dryWt0 = parseFloat(ticketData0?.dry_peanut_wt?.toString() || '0.00');
                const greenWt0 = parseFloat(ticketData0?.green_peanut_wt?.toString() || '0.00');
                const defaultSelectedIndex0 = dropdownValue0 === curingResponse[firstGrossWtIndex].scheduleId.trim() ? firstSelectedSchedule : 0;
                const costToCure0 = parseFloat(getValue(thisObj, 'txtCCCost0') || '0.00')
                  || calcCostToCure(null, dryWt0, greenWt0, defaultSelectedIndex0, 0);

                const dropdownValue = cureCleanCostDetail?.inspect_scale_ticket[index]?.cure_schedule || '';
                setValue(thisObj, `ddCCSchedule${index}`, dropdownValue);
                const hasScheduleMatched = dropdownValue === curingSchedule.scheduleId.trim();
                let costToCure1 = 0;
                if (hasScheduleMatched) {
                  costToCure1 = calcCostToCure(null, dryWt, greenWt, scheduleListCount, index);
                  setValue(thisObj, `txtCCCost${index}`, costToCure1 ? parseFloat(roundTo(costToCure1, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                }

                const costToCure2 = parseFloat(getValue(thisObj, 'txtCCCost2') || '0.00');
                const totalCostToCure = costToCure0 + costToCure1 + costToCure2;
                setValue(thisObj, 'txtCostToCure', totalCostToCure ? parseFloat(roundTo(totalCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                if (hasScheduleMatched) {
                  break;
                }
              } else if (index === 2) {
                listCureScheduleId2.push(curingSchedule.scheduleId.trim());
                listCureScheduleNum2.push(curingSchedule.scheduleNum);
                listCostToCure2.push(curingSchedule.baseRate);
                listCureDryGreenInd2.push(curingSchedule.dryGreenInd.trim());
                listCureRateType2.push(curingSchedule.rateType.trim());
                listCurePDCode2.push(curingSchedule.pdCode.trim());
                const dropdownValue0 = cureCleanCostDetail?.inspect_scale_ticket[0]?.cure_schedule || '';
                const ticketData0 = cureCleanCostDetail?.inspect_scale_ticket?.[0];
                const dryWt0 = parseFloat(ticketData0?.dry_peanut_wt?.toString() || '0.00');
                const greenWt0 = parseFloat(ticketData0?.green_peanut_wt?.toString() || '0.00');
                const defaultSelectedIndex0 = dropdownValue0 === curingResponse[firstGrossWtIndex].scheduleId.trim() ? firstSelectedSchedule : 0;
                const costToCure0 = parseFloat(getValue(thisObj, 'txtCCCost0') || '0.00') ||
                  calcCostToCure(null, dryWt0, greenWt0, defaultSelectedIndex0, 0);

                const dropdownValue1 = cureCleanCostDetail?.inspect_scale_ticket[1]?.cure_schedule || '';
                const ticketData1 = cureCleanCostDetail?.inspect_scale_ticket?.[1];
                const dryWt1 = parseFloat(ticketData1?.dry_peanut_wt?.toString() || '0.00');
                const greenWt1 = parseFloat(ticketData1?.green_peanut_wt?.toString() || '0.00');
                const defaultSelectedIndex1 = dropdownValue1 === curingResponse[secondGrossWtIndex].scheduleId.trim() ? secondSelectedSchedule : 0;
                const costToCure1 = parseFloat(getValue(thisObj, 'txtCCCost1') || '0.00') ||
                  calcCostToCure(null, dryWt1, greenWt1, defaultSelectedIndex1, 1);

                const dropdownValue = cureCleanCostDetail?.inspect_scale_ticket[index]?.cure_schedule || '';
                setValue(thisObj, `ddCCSchedule${index}`, dropdownValue);
                const hasScheduleMatched = dropdownValue === curingSchedule.scheduleId.trim();
                let costToCure2 = 0;
                if (hasScheduleMatched) {
                  costToCure2 = calcCostToCure(null, dryWt, greenWt, curingScheduleIndex + 1, index);
                  setValue(thisObj, `txtCCCost${index}`, costToCure2 ? parseFloat(roundTo(costToCure2, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                }
                const totalCostToCure = costToCure0 + costToCure1 + costToCure2;
                setValue(thisObj, 'txtCostToCure', totalCostToCure ? parseFloat(roundTo(totalCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                if (hasScheduleMatched) {
                  break;
                }
              }
            } else if (selectedCuring === 'N'
              && curingSchedule.dryGreenInd.trim().toUpperCase() === 'N') {
              listObjComboBox.push({
                key: curingSchedule.scheduleId.trim(),
                description: curingSchedule.scheduleId.trim() + " - " + curingSchedule.scheduleDesc.trim()
              });
              listCureScheduleId.push(curingSchedule.scheduleId.trim());
              listCureScheduleNum.push(curingSchedule.scheduleNum);
              listCostToCure.push(curingSchedule.baseRate);
              listCureDryGreenInd.push(curingSchedule.dryGreenInd.trim());
              listCureRateType.push(curingSchedule.rateType.trim());
              listCurePDCode.push(curingSchedule.pdCode.trim());
              const hasScheduleMatched = cureCleanCostDetail.tickeT_CURE_SCHEDULE === curingSchedule.scheduleId.trim();
              setValue(thisObj, 'ddCCNWSchedule', cureCleanCostDetail.tickeT_CURE_SCHEDULE);
              if (nwCostToCure === 0) {
                nwCostToCure = calcCostToCure(null, cureCleanCostDetail.neT_WT, 0, curingScheduleIndex + 1, index);
                setValue(thisObj, 'txtCCNWCost', nwCostToCure ? parseFloat(roundTo(nwCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                setValue(thisObj, 'txtCostToCure', nwCostToCure ? parseFloat(roundTo(nwCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
              }
              if (hasScheduleMatched) {
                break;
              }
            } else if (selectedCuring === 'O'
              && curingSchedule.dryGreenInd.trim().toUpperCase() === 'O') {
              listObjComboBox.push({
                key: curingSchedule.scheduleId.trim(),
                description: curingSchedule.scheduleId.trim() + " - " + curingSchedule.scheduleDesc.trim()
              });
              listCureScheduleId.push(curingSchedule.scheduleId.trim());
              listCureScheduleNum.push(curingSchedule.scheduleNum);
              listCostToCure.push(curingSchedule.baseRate);
              listCureDryGreenInd.push(curingSchedule.dryGreenInd.trim());
              listCureRateType.push(curingSchedule.rateType.trim());
              listCurePDCode.push(curingSchedule.pdCode.trim());
              const hasScheduleMatched = cureCleanCostDetail.tickeT_CURE_SCHEDULE === curingSchedule.scheduleId.trim();
              setValue(thisObj, 'ddCCOSchedule', cureCleanCostDetail.tickeT_CURE_SCHEDULE);
              if (otherCostToCure === 0 && hasScheduleMatched) {
                otherCostToCure = calcCostToCure(null, cureCleanCostDetail.tickeT_CURE_WT_OTHER, 0, curingScheduleIndex + 1, index);
                setValue(thisObj, 'txtCCOCost', otherCostToCure ? parseFloat(roundTo(otherCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                setValue(thisObj, 'txtCostToCure', otherCostToCure ? parseFloat(roundTo(otherCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
              }
              if (hasScheduleMatched) {
                break;
              }
            }
          } else if (curingSchedule.cureCleanInd.toUpperCase() === 'C/C') {
            listObjComboBox.push({
              key: curingSchedule.scheduleId.trim(),
              description: curingSchedule.scheduleId.trim() + " - " + curingSchedule.scheduleDesc.trim()
            });
            listCureScheduleId.push(curingSchedule.scheduleId.trim());
            listCureScheduleNum.push(curingSchedule.scheduleNum);
            listCostToCure.push(curingSchedule.baseRate);
            listCureDryGreenInd.push(curingSchedule.dryGreenInd.trim());
            listCureRateType.push(curingSchedule.rateType.trim());
            listCurePDCode.push(curingSchedule.pdCode.trim());
          }
        }
      }

      switch (dryGreenInd) {
        case 'D,G':
          if (ticketData?.cure_schedule) {
            setValue(thisObj, `ddCCSchedule${index}`, ticketData.cure_schedule)
          }
          if (index === 0) {
            thisObj.setState(current => {
              return {
                ...current,
                ddCCSchedule0: {
                  ...state.ddCCSchedule0,
                  valueList: listObjComboBox,
                  Enabled: dryGreenInd === 'D,G'
                }
              }
            });
          } else if (index === 1) {
            thisObj.setState(current => {
              return {
                ...current,
                ddCCSchedule1: {
                  ...state.ddCCSchedule1,
                  valueList: listObjComboBox,
                  Enabled: dryGreenInd === 'D,G'
                }
              }
            });
          } else if (index === 2) {
            thisObj.setState(current => {
              return {
                ...current,
                ddCCSchedule2: {
                  ...state.ddCCSchedule2,
                  valueList: listObjComboBox,
                  Enabled: dryGreenInd === 'D,G'
                }
              }
            });
          }
          break;
        case 'N':
          thisObj.setState(current => {
            return {
              ...current,
              ddCCNWSchedule: {
                ...state.ddCCNWSchedule,
                valueList: listObjComboBox,
                Enabled: dryGreenInd === 'N'
              }
            }
          });
          setValue(thisObj, 'ddCCNWSchedule', cureCleanCostDetail.tickeT_CURE_SCHEDULE);
          break;
        case 'O':
          thisObj.setState(current => {
            return {
              ...current,
              ddCCOSchedule: {
                ...state.ddCCOSchedule,
                valueList: listObjComboBox,
                Enabled: dryGreenInd === 'O'
              }
            }
          });
          setValue(thisObj, 'ddCCOSchedule', cureCleanCostDetail.tickeT_CURE_SCHEDULE);
          break;
        default:
          thisObj.setState(current => {
            return {
              ...current,
              ddCCSchedule0: {
                ...state.ddCCSchedule0,
                valueList: listObjComboBox
              }
            }
          });
          break;
      }
      return true;
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during calculateCuringValues function"
        );
      }
      return false;
    }
  }

  function fillCureSchedule(objComboBox, moistureValue, index = 0, dryGreenInd = "") {
    let strCC_Flag = 'CUR';

    let listObjComboBox = [];
    listObjComboBox.push({ key: '', description: '<<< Select >>>' });
    if (index === 0) {
      listCureScheduleId = [];
      listCureScheduleNum = [];
      listCureDryGreenInd = [];
      listCostToCure = [];
      listCureRateType = [];
      listCurePDCode = [];

      listCureScheduleId.push('');
      listCureScheduleNum.push('');
      listCureDryGreenInd.push('');
      listCostToCure.push('');
      listCureRateType.push('');
      listCurePDCode.push('');
    } else if (index === 1) {
      listCureScheduleId1 = [];
      listCureScheduleNum1 = [];
      listCureDryGreenInd1 = [];
      listCostToCure1 = [];
      listCureRateType1 = [];
      listCurePDCode1 = [];

      listCureScheduleId1.push('');
      listCureScheduleNum1.push('');
      listCureDryGreenInd1.push('');
      listCostToCure1.push('');
      listCureRateType1.push('');
      listCurePDCode1.push('');
    } else if (index === 2) {
      listCureScheduleId2 = [];
      listCureScheduleNum2 = [];
      listCureDryGreenInd2 = [];
      listCostToCure2 = [];
      listCureRateType2 = [];
      listCurePDCode2 = [];

      listCureScheduleId2.push('');
      listCureScheduleNum2.push('');
      listCureDryGreenInd2.push('');
      listCostToCure2.push('');
      listCureRateType2.push('');
      listCurePDCode2.push('');
    }

    if (getValue(thisObj, 'radioCureClean') === '2') {
      strCC_Flag = "C/C";
    }
    try {
      setDataForFillCureCleanSchedule(strCC_Flag, dryGreenInd);
      const curingRadio = getRadioCureCleanValue();
      const FillCureScheduleData = getData(thisObj, 'FillCureCleanScheduleData');
      SettlementService.RetrieveCureCleanScheduleDetails(
        FillCureScheduleData.buy_pt_id,
        FillCureScheduleData.cure_clean_ind,
        FillCureScheduleData.dry_green_ind
      )
        .then(response => {
          const cureCleanCostDetails = getData(thisObj, 'lstrxml');
          const cureCleanCostDetail = cureCleanCostDetails[cureCleanCostDetails.length - 1];
          const ticketData = cureCleanCostDetail?.inspect_scale_ticket?.[index];
          let nwCostToCure = 0, otherCostToCure = 0;
          try {
            response.forEach((curingSchedule, curingScheduleIndex) => {
              let firstGrossWtIndex = 0, secondGrossWtIndex = 0;
              if (curingSchedule.loMoistPct <= moistureValue && curingSchedule.hiMoistPct >= moistureValue) {
                if (curingSchedule.cureCleanInd.toUpperCase() === 'CUR') {
                  const dryWt = parseFloat(ticketData?.dry_peanut_wt?.toString() || '0.00');
                  const greenWt = parseFloat(ticketData?.green_peanut_wt?.toString() || '0.00');

                  if (curingRadio === '1'
                    && ((curingSchedule.dryGreenInd.trim().toUpperCase() === 'D'
                      && dryWt > 0)
                      || (curingSchedule.dryGreenInd.trim().toUpperCase() === 'G'
                        && greenWt > 0))) {
                    listObjComboBox.push({
                      key: curingSchedule.scheduleId.trim(),
                      description: curingSchedule.scheduleId.trim() + " - " + curingSchedule.scheduleDesc.trim()
                    });

                    if (index === 0) {
                      firstGrossWtIndex = curingScheduleIndex;
                      listCureScheduleId.push(curingSchedule.scheduleId.trim());
                      listCureScheduleNum.push(curingSchedule.scheduleNum);
                      listCostToCure.push(curingSchedule.baseRate);
                      listCureDryGreenInd.push(curingSchedule.dryGreenInd.trim());
                      listCureRateType.push(curingSchedule.rateType.trim());
                      listCurePDCode.push(curingSchedule.pdCode.trim());

                      const dropdownValue = cureCleanCostDetail?.inspect_scale_ticket[index]?.cure_schedule || '';
                      setValue(thisObj, `ddCCSchedule${index}`, dropdownValue);
                      const defaultSelectedIndex = dropdownValue === curingSchedule.scheduleId.trim() ? 1 : 0;
                      const costToCure0 = calcCostToCure(null, dryWt, greenWt, defaultSelectedIndex, index);
                      setValue(thisObj, `txtCCCost${index}`, costToCure0 ? parseFloat(roundTo(costToCure0, 2)).toLocaleString('en-US', fractionOption) : '0.00');

                      const costToCure1 = parseFloat(getValue(thisObj, 'txtCCCost1') || '0.00');
                      const costToCure2 = parseFloat(getValue(thisObj, 'txtCCCost2') || '0.00');
                      const totalCostToCure = costToCure0 + costToCure1 + costToCure2;
                      setValue(thisObj, 'txtCostToCure', totalCostToCure ? parseFloat(roundTo(totalCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                    } else if (index === 1) {
                      secondGrossWtIndex = curingScheduleIndex;
                      listCureScheduleId1.push(curingSchedule.scheduleId.trim());
                      listCureScheduleNum1.push(curingSchedule.scheduleNum);
                      listCostToCure1.push(curingSchedule.baseRate);
                      listCureDryGreenInd1.push(curingSchedule.dryGreenInd.trim());
                      listCureRateType1.push(curingSchedule.rateType.trim());
                      listCurePDCode1.push(curingSchedule.pdCode.trim());
                      const dropdownValue0 = cureCleanCostDetail?.inspect_scale_ticket[0]?.cure_schedule || '';
                      const ticketData0 = cureCleanCostDetail?.inspect_scale_ticket?.[0];
                      const dryWt0 = parseFloat(ticketData0?.dry_peanut_wt?.toString() || '0.00');
                      const greenWt0 = parseFloat(ticketData0?.green_peanut_wt?.toString() || '0.00');
                      const defaultSelectedIndex0 = dropdownValue0 === response[firstGrossWtIndex].scheduleId.trim() ? 1 : 0;
                      const costToCure0 = objComboBox ? parseFloat(getValue(thisObj, 'txtCCCost0') || '0.00')
                        : calcCostToCure(null, dryWt0, greenWt0, defaultSelectedIndex0, 0);

                      const dropdownValue = cureCleanCostDetail?.inspect_scale_ticket[index]?.cure_schedule || '';
                      setValue(thisObj, `ddCCSchedule${index}`, dropdownValue);
                      const defaultSelectedIndex = dropdownValue === curingSchedule.scheduleId.trim() ? 1 : 0;
                      const costToCure1 = calcCostToCure(null, dryWt, greenWt, defaultSelectedIndex, index);
                      setValue(thisObj, `txtCCCost${index}`, costToCure1 ? parseFloat(roundTo(costToCure1, 2)).toLocaleString('en-US', fractionOption) : '0.00');

                      const costToCure2 = parseFloat(getValue(thisObj, 'txtCCCost2') || '0.00');
                      const totalCostToCure = costToCure0 + costToCure1 + costToCure2;
                      setValue(thisObj, 'txtCostToCure', totalCostToCure ? parseFloat(roundTo(totalCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                    } else if (index === 2) {
                      listCureScheduleId2.push(curingSchedule.scheduleId.trim());
                      listCureScheduleNum2.push(curingSchedule.scheduleNum);
                      listCostToCure2.push(curingSchedule.baseRate);
                      listCureDryGreenInd2.push(curingSchedule.dryGreenInd.trim());
                      listCureRateType2.push(curingSchedule.rateType.trim());
                      listCurePDCode2.push(curingSchedule.pdCode.trim());
                      const dropdownValue0 = cureCleanCostDetail?.inspect_scale_ticket[0]?.cure_schedule || '';
                      const ticketData0 = cureCleanCostDetail?.inspect_scale_ticket?.[0];
                      const dryWt0 = parseFloat(ticketData0?.dry_peanut_wt?.toString() || '0.00');
                      const greenWt0 = parseFloat(ticketData0?.green_peanut_wt?.toString() || '0.00');
                      const defaultSelectedIndex0 = dropdownValue0 === response[firstGrossWtIndex].scheduleId.trim() ? 1 : 0;
                      const costToCure0 = objComboBox ? parseFloat(getValue(thisObj, 'txtCCCost0') || '0.00')
                        : calcCostToCure(null, dryWt0, greenWt0, defaultSelectedIndex0, 0);

                      const dropdownValue1 = cureCleanCostDetail?.inspect_scale_ticket[1]?.cure_schedule || '';
                      const ticketData1 = cureCleanCostDetail?.inspect_scale_ticket?.[1];
                      const dryWt1 = parseFloat(ticketData1?.dry_peanut_wt?.toString() || '0.00');
                      const greenWt1 = parseFloat(ticketData1?.green_peanut_wt?.toString() || '0.00');
                      const defaultSelectedIndex1 = dropdownValue1 === response[secondGrossWtIndex].scheduleId.trim() ? 1 : 0;
                      const costToCure1 = objComboBox ? parseFloat(getValue(thisObj, 'txtCCCost1') || '0.00')
                        : calcCostToCure(null, dryWt1, greenWt1, defaultSelectedIndex1, 1);

                      const dropdownValue = cureCleanCostDetail?.inspect_scale_ticket[index]?.cure_schedule || '';
                      setValue(thisObj, `ddCCSchedule${index}`, dropdownValue);
                      const defaultSelectedIndex = dropdownValue === curingSchedule.scheduleId.trim() ? 1 : 0;
                      const costToCure2 = calcCostToCure(null, dryWt, greenWt, defaultSelectedIndex, index);
                      setValue(thisObj, `txtCCCost${index}`, costToCure2 ? parseFloat(roundTo(costToCure2, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                      const totalCostToCure = costToCure0 + costToCure1 + costToCure2;
                      setValue(thisObj, 'txtCostToCure', totalCostToCure ? parseFloat(roundTo(totalCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                    }
                  } else if (curingRadio === '2'
                    && curingSchedule.dryGreenInd.trim().toUpperCase() === 'N') {
                    listObjComboBox.push({
                      key: curingSchedule.scheduleId.trim(),
                      description: curingSchedule.scheduleId.trim() + " - " + curingSchedule.scheduleDesc.trim()
                    });
                    listCureScheduleId.push(curingSchedule.scheduleId.trim());
                    listCureScheduleNum.push(curingSchedule.scheduleNum);
                    listCostToCure.push(curingSchedule.baseRate);
                    listCureDryGreenInd.push(curingSchedule.dryGreenInd.trim());
                    listCureRateType.push(curingSchedule.rateType.trim());
                    listCurePDCode.push(curingSchedule.pdCode.trim());
                    const defaultSelectedIndex = cureCleanCostDetail.tickeT_CURE_SCHEDULE === curingSchedule.scheduleId.trim() ? 1 : 0;
                    setValue(thisObj, 'ddCCNWSchedule', cureCleanCostDetail.tickeT_CURE_SCHEDULE);
                    if (nwCostToCure === 0) {
                      nwCostToCure = calcCostToCure(null, cureCleanCostDetail.neT_WT, 0, defaultSelectedIndex, index);
                      setValue(thisObj, 'txtCCNWCost', nwCostToCure ? parseFloat(roundTo(nwCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                      setValue(thisObj, 'txtCostToCure', nwCostToCure ? parseFloat(roundTo(nwCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                    }
                  } else if (curingRadio === '3'
                    && curingSchedule.dryGreenInd.trim().toUpperCase() === 'O') {
                    listObjComboBox.push({
                      key: curingSchedule.scheduleId.trim(),
                      description: curingSchedule.scheduleId.trim() + " - " + curingSchedule.scheduleDesc.trim()
                    });
                    listCureScheduleId.push(curingSchedule.scheduleId.trim());
                    listCureScheduleNum.push(curingSchedule.scheduleNum);
                    listCostToCure.push(curingSchedule.baseRate);
                    listCureDryGreenInd.push(curingSchedule.dryGreenInd.trim());
                    listCureRateType.push(curingSchedule.rateType.trim());
                    listCurePDCode.push(curingSchedule.pdCode.trim());
                    const defaultSelectedIndex = cureCleanCostDetail.tickeT_CURE_SCHEDULE === curingSchedule.scheduleId.trim() ? 1 : 0;
                    setValue(thisObj, 'ddCCOSchedule', cureCleanCostDetail.tickeT_CURE_SCHEDULE);
                    if (otherCostToCure === 0) {
                      otherCostToCure = calcCostToCure(null, cureCleanCostDetail.tickeT_CURE_WT_OTHER, 0, defaultSelectedIndex, index);
                      setValue(thisObj, 'txtCCOCost', otherCostToCure ? parseFloat(roundTo(otherCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                      setValue(thisObj, 'txtCostToCure', otherCostToCure ? parseFloat(roundTo(otherCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                    }
                  }
                } else if (curingSchedule.cureCleanInd.toUpperCase() === 'C/C') {
                  listObjComboBox.push({
                    key: curingSchedule.scheduleId.trim(),
                    description: curingSchedule.scheduleId.trim() + " - " + curingSchedule.scheduleDesc.trim()
                  });
                  listCureScheduleId.push(curingSchedule.scheduleId.trim());
                  listCureScheduleNum.push(curingSchedule.scheduleNum);
                  listCostToCure.push(curingSchedule.baseRate);
                  listCureDryGreenInd.push(curingSchedule.dryGreenInd.trim());
                  listCureRateType.push(curingSchedule.rateType.trim());
                  listCurePDCode.push(curingSchedule.pdCode.trim());
                }
              }
            });

            if (listObjComboBox.length > 1) {
              if (objComboBox) {
                thisObj.setState(current => {
                  return {
                    ...current,
                    [objComboBox.name]: {
                      ...state[objComboBox.name],
                      valueList: listObjComboBox
                    }
                  }
                });
              } else {
                switch (dryGreenInd) {
                  case 'D,G':
                    if (ticketData?.cure_schedule) {
                      setValue(thisObj, `ddCCSchedule${index}`, ticketData.cure_schedule)
                    }
                    if (index === 0) {
                      thisObj.setState(current => {
                        return {
                          ...current,
                          ddCCSchedule0: {
                            ...state.ddCCSchedule0,
                            valueList: listObjComboBox
                          }
                        }
                      });
                    } else if (index === 1) {
                      thisObj.setState(current => {
                        return {
                          ...current,
                          ddCCSchedule1: {
                            ...state.ddCCSchedule1,
                            valueList: listObjComboBox
                          }
                        }
                      });
                    } else if (index === 2) {
                      thisObj.setState(current => {
                        return {
                          ...current,
                          ddCCSchedule2: {
                            ...state.ddCCSchedule2,
                            valueList: listObjComboBox
                          }
                        }
                      });
                    }
                    break;
                  case 'N':
                    thisObj.setState(current => {
                      return {
                        ...current,
                        ddCCNWSchedule: {
                          ...state.ddCCNWSchedule,
                          valueList: listObjComboBox
                        }
                      }
                    });
                    setValue(thisObj, 'ddCCNWSchedule', cureCleanCostDetail.tickeT_CURE_SCHEDULE);
                    break;
                  case 'O':
                    thisObj.setState(current => {
                      return {
                        ...current,
                        ddCCOSchedule: {
                          ...state.ddCCOSchedule,
                          valueList: listObjComboBox
                        }
                      }
                    });
                    setValue(thisObj, 'ddCCOSchedule', cureCleanCostDetail.tickeT_CURE_SCHEDULE);
                    break;
                  default:
                    thisObj.setState(current => {
                      return {
                        ...current,
                        ddCCSchedule0: {
                          ...state.ddCCSchedule0,
                          valueList: listObjComboBox
                        }
                      }
                    });
                    break;
                }
              }
            }
            return true;
          } catch (err) {
            if (err instanceof EvalError) {
              showMessage(thisObj, err.message);
            } else {
              showMessage(
                thisObj,
                "Error occurred during fillCureSchedule function"
              );
            }
            return false;
          }
        })
        .catch(err => {
          if (err instanceof EvalError) {
            showMessage(thisObj, err.message);
          } else {
            showMessage(
              thisObj,
              "Error occurred during fillCureSchedule function"
            );
          }
          if (objComboBox) {
            thisObj.setState(current => {
              return {
                ...current,
                [objComboBox.name]: {
                  ...state[objComboBox.name],
                  valueList: listObjComboBox
                }
              }
            });
          }
          return false;
        })
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during fillCureSchedule function"
        );
      }
      return false;
    }
  }
  //------------------------------------------- End fillCureSchedule -------------------------------------

  //---------------------------------------- Start fillCleanSchedule -------------------------------------
  function fillCleanSchedule(objComboBox = null, initialRadioSelect = null) {
    let listObjComboBox = [];
    let listCleanGrossWt0 = [];
    let listCleanGrossWt1 = [];
    let listCleanGrossWt2 = [];
    let listCleanNetWt = [];
    let listCleanOther = [];

    listCleanScheduleId = [];
    listCleanScheduleNum = [];
    listCleanDryGreenInd = [];
    listCostToClean = [];
    listCleanRateType = [];
    listCleanPDCode = [];

    listObjComboBox.push({ key: '', description: '<<< Select >>>' });
    listCleanGrossWt0.push({ key: '', description: '<<< Select >>>' });
    listCleanGrossWt1.push({ key: '', description: '<<< Select >>>' });
    listCleanGrossWt2.push({ key: '', description: '<<< Select >>>' });
    listCleanNetWt.push({ key: '', description: '<<< Select >>>' });
    listCleanOther.push({ key: '', description: '<<< Select >>>' });

    listCleanScheduleId.push("");
    listCleanScheduleNum.push("");
    listCleanDryGreenInd.push("");
    listCostToClean.push("");
    listCleanRateType.push("");
    listCleanPDCode.push("");

    const cleaningRadio = initialRadioSelect || getRadioCleanValue();
    try {
      setDataForFillCureCleanSchedule('CLN');
      const FillCleanScheduleData = getData(thisObj, 'FillCureCleanScheduleData');
      SettlementService.RetrieveCureCleanScheduleDetails(
        FillCleanScheduleData.buy_pt_id,
        FillCleanScheduleData.cure_clean_ind,
        FillCleanScheduleData.dry_green_ind
      )
        .then(response => {
          const cureCleanCostDetails = getData(thisObj, 'lstrxml');
          const cureCleanCostDetail = cureCleanCostDetails[cureCleanCostDetails.length - 1];
          let grossWtIndex = -1, netWtIndex = -1, otherIndex = -1;
          let costToClean0 = 0, costToClean1 = 0, costToClean2 = 0, nwCostToClean = 0, otherCostToClean = 0;
          const addedList = [];
          try {
            response.forEach((cleaningSchedule, cleaningScheduleIndex) => {
              if (cleaningSchedule.cureCleanInd.toUpperCase() === 'CLN') {
                if (cleaningRadio === '1'
                  && (cleaningSchedule.dryGreenInd.trim().toUpperCase() === 'D'
                    || cleaningSchedule.dryGreenInd.trim().toUpperCase() === 'G')) {

                  if (objComboBox) {
                    listObjComboBox.push({
                      key: cleaningSchedule.scheduleId.trim(),
                      description: cleaningSchedule.scheduleId.trim() + " - " + cleaningSchedule.scheduleDesc.trim()
                    });
                    listCleanScheduleId.push(cleaningSchedule.scheduleId.trim());
                    listCleanScheduleNum.push(cleaningSchedule.scheduleNum);
                    listCostToClean.push(cleaningSchedule.baseRate);
                    listCleanDryGreenInd.push(cleaningSchedule.dryGreenInd.trim());
                    listCleanRateType.push(cleaningSchedule.rateType.trim());
                    listCleanPDCode.push(cleaningSchedule.pdCode.trim());
                  }
                  if (!objComboBox) {
                    grossWtIndex++;
                    for (let i = 0; i < 3; i++) {
                      const dryWt = parseFloat(cureCleanCostDetail?.inspect_scale_ticket[i]?.dry_peanut_wt?.toString() || '0');
                      const greenWt = parseFloat(cureCleanCostDetail?.inspect_scale_ticket[i]?.green_peanut_wt?.toString() || '0');
                      if ((cleaningSchedule.dryGreenInd.trim().toUpperCase() === 'D'
                        && dryWt > 0)
                        || (cleaningSchedule.dryGreenInd.trim().toUpperCase() === 'G'
                          && greenWt > 0)) {
                        if (!addedList.includes(cleaningScheduleIndex)) {
                          addedList.push(cleaningScheduleIndex);
                          listCleanScheduleId.push(cleaningSchedule.scheduleId.trim());
                          listCleanScheduleNum.push(cleaningSchedule.scheduleNum);
                          listCostToClean.push(cleaningSchedule.baseRate);
                          listCleanDryGreenInd.push(cleaningSchedule.dryGreenInd.trim());
                          listCleanRateType.push(cleaningSchedule.rateType.trim());
                          listCleanPDCode.push(cleaningSchedule.pdCode.trim());
                        }
                        if (i === 0) {
                          listCleanGrossWt0.push({
                            key: cleaningSchedule.scheduleId.trim(),
                            description: cleaningSchedule.scheduleId.trim() + " - " + cleaningSchedule.scheduleDesc.trim()
                          });
                          const dropdownValue = cureCleanCostDetail?.inspect_scale_ticket[i]?.clean_schedule || '';
                          setValue(thisObj, 'ddCSchedule0', dropdownValue);
                          if (costToClean0 === 0) {
                            const defaultSelectedIndex = dropdownValue === cleaningSchedule.scheduleId.trim() ? grossWtIndex + 1 : 0;
                            costToClean0 = calcCostToClean(null, dryWt, greenWt, defaultSelectedIndex);
                            setValue(thisObj, 'txtCCost0', costToClean0 ? parseFloat(roundTo(costToClean0, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                          }
                        } else if (i === 1) {
                          listCleanGrossWt1.push({
                            key: cleaningSchedule.scheduleId.trim(),
                            description: cleaningSchedule.scheduleId.trim() + " - " + cleaningSchedule.scheduleDesc.trim()
                          });
                          const dropdownValue = cureCleanCostDetail?.inspect_scale_ticket[i]?.clean_schedule || '';
                          setValue(thisObj, 'ddCSchedule1', dropdownValue);
                          if (costToClean1 === 0) {
                            const defaultSelectedIndex = dropdownValue === cleaningSchedule.scheduleId.trim() ? grossWtIndex + 1 : 0;
                            costToClean1 = calcCostToClean(null, dryWt, greenWt, defaultSelectedIndex);
                            setValue(thisObj, 'txtCCost1', costToClean1 ? parseFloat(roundTo(costToClean1, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                          }
                        } else if (i === 2) {
                          listCleanGrossWt2.push({
                            key: cleaningSchedule.scheduleId.trim(),
                            description: cleaningSchedule.scheduleId.trim() + " - " + cleaningSchedule.scheduleDesc.trim()
                          });
                          const dropdownValue = cureCleanCostDetail?.inspect_scale_ticket[i]?.clean_schedule || '';
                          setValue(thisObj, 'ddCSchedule2', dropdownValue);
                          if (costToClean2 === 0) {
                            const defaultSelectedIndex = dropdownValue === cleaningSchedule.scheduleId.trim() ? grossWtIndex + 1 : 0;
                            costToClean2 = calcCostToClean(null, dryWt, greenWt, defaultSelectedIndex);
                            setValue(thisObj, 'txtCCost2', costToClean2 ? parseFloat(roundTo(costToClean2, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                          }
                        }
                      }
                    }

                    const finalCleanGrossWtCost = costToClean0 + costToClean1 + costToClean2;
                    setValue(thisObj, 'txtCostToClean', finalCleanGrossWtCost ? parseFloat(roundTo(finalCleanGrossWtCost, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                  }
                } else if (cleaningRadio === '2'
                  && cleaningSchedule.dryGreenInd.trim().toUpperCase() === 'N') {
                  if (objComboBox) {
                    listObjComboBox.push({
                      key: cleaningSchedule.scheduleId.trim(),
                      description: cleaningSchedule.scheduleId.trim() + " - " + cleaningSchedule.scheduleDesc.trim()
                    });
                  }
                  if (!objComboBox) {
                    listCleanNetWt.push({
                      key: cleaningSchedule.scheduleId.trim(),
                      description: cleaningSchedule.scheduleId.trim() + " - " + cleaningSchedule.scheduleDesc.trim()
                    });
                  }
                  netWtIndex++;
                  listCleanScheduleId.push(cleaningSchedule.scheduleId.trim());
                  listCleanScheduleNum.push(cleaningSchedule.scheduleNum);
                  listCostToClean.push(cleaningSchedule.baseRate);
                  listCleanDryGreenInd.push(cleaningSchedule.dryGreenInd.trim());
                  listCleanRateType.push(cleaningSchedule.rateType.trim());
                  listCleanPDCode.push(cleaningSchedule.pdCode.trim());

                  const defaultSelectedIndex = cureCleanCostDetail.tickeT_CLEAN_SCHEDULE === cleaningSchedule.scheduleId.trim() ? netWtIndex + 1 : 0;
                  setValue(thisObj, 'ddCNWSchedule', cureCleanCostDetail.tickeT_CLEAN_SCHEDULE);
                  if (nwCostToClean === 0) {
                    nwCostToClean = calcCostToClean(null, cureCleanCostDetail.neT_WT, 0, defaultSelectedIndex);
                    setValue(thisObj, 'txtCNWCost', nwCostToClean ? parseFloat(roundTo(nwCostToClean, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                    setValue(thisObj, 'txtCostToClean', nwCostToClean ? parseFloat(roundTo(nwCostToClean, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                  }
                } else if (cleaningRadio === '3'
                  && cleaningSchedule.dryGreenInd.trim().toUpperCase() === 'O') {
                  if (objComboBox) {
                    listObjComboBox.push({
                      key: cleaningSchedule.scheduleId.trim(),
                      description: cleaningSchedule.scheduleId.trim() + " - " + cleaningSchedule.scheduleDesc.trim()
                    });
                  }
                  if (!objComboBox) {
                    listCleanOther.push({
                      key: cleaningSchedule.scheduleId.trim(),
                      description: cleaningSchedule.scheduleId.trim() + " - " + cleaningSchedule.scheduleDesc.trim()
                    });
                  }
                  otherIndex++;
                  listCleanScheduleId.push(cleaningSchedule.scheduleId.trim());
                  listCleanScheduleNum.push(cleaningSchedule.scheduleNum);
                  listCostToClean.push(cleaningSchedule.baseRate);
                  listCleanDryGreenInd.push(cleaningSchedule.dryGreenInd.trim());
                  listCleanRateType.push(cleaningSchedule.rateType.trim());
                  listCleanPDCode.push(cleaningSchedule.pdCode.trim());
                  const defaultSelectedIndex = cureCleanCostDetail.tickeT_CLEAN_SCHEDULE === cleaningSchedule.scheduleId.trim() ? otherIndex + 1 : 0;
                  setValue(thisObj, 'ddCOSchedule', cureCleanCostDetail.tickeT_CLEAN_SCHEDULE);
                  if (otherCostToClean === 0) {
                    otherCostToClean = calcCostToClean(null, cureCleanCostDetail.tickeT_CLEAN_WT_OTHER, 0, defaultSelectedIndex);
                    setValue(thisObj, 'txtCOCost', otherCostToClean ? parseFloat(roundTo(otherCostToClean, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                    setValue(thisObj, 'txtCostToClean', otherCostToClean ? parseFloat(roundTo(otherCostToClean, 2)).toLocaleString('en-US', fractionOption) : '0.00');
                  }
                }
              }
            });

            if (objComboBox) {
              thisObj.setState(current => {
                return {
                  ...current,
                  [objComboBox.name]: {
                    ...state[objComboBox.name],
                    valueList: listObjComboBox
                  }
                }
              });
            } else {
              thisObj.setState(current => {
                return {
                  ...current,
                  ddCSchedule0: {
                    ...state["ddCSchedule0"],
                    valueList: listCleanGrossWt0,
                    Enabled: cleaningRadio === '1'
                  },
                  ddCSchedule1: {
                    ...state["ddCSchedule1"],
                    valueList: listCleanGrossWt1,
                    Enabled: cleaningRadio === '1'
                  },
                  ddCSchedule2: {
                    ...state["ddCSchedule2"],
                    valueList: listCleanGrossWt2,
                    Enabled: cleaningRadio === '1'
                  },
                  ddCNWSchedule: {
                    ...state["ddCNWSchedule"],
                    valueList: listCleanNetWt,
                    Enabled: cleaningRadio === '2'
                  },
                  ddCOSchedule: {
                    ...state["ddCOSchedule"],
                    valueList: listCleanOther,
                    Enabled: cleaningRadio === '3'
                  }
                }
              });
            }
            enableDisableControls();
            return true;
          } catch (err) {
            if (err instanceof EvalError) {
              showMessage(thisObj, err.message);
            } else {
              showMessage(
                thisObj,
                "Error occurred during fillCleanSchedule function"
              );
            }
            return false;
          }
        })
        .catch(err => {
          if (err instanceof EvalError) {
            showMessage(thisObj, err.message);
          } else {
            showMessage(
              thisObj,
              "Error occurred during fillCleanSchedule function"
            );
          }

          if (objComboBox) {
            thisObj.setState(current => {
              return {
                ...current,
                [objComboBox.name]: {
                  ...state[objComboBox.name],
                  valueList: listObjComboBox
                }
              }
            });
          } else {
            thisObj.setState(current => {
              return {
                ...current,
                ddCSchedule0: {
                  ...state["ddCSchedule0"],
                  valueList: listCleanGrossWt0,
                  Enabled: cleaningRadio === '1'
                },
                ddCSchedule1: {
                  ...state["ddCSchedule1"],
                  valueList: listCleanGrossWt1,
                  Enabled: cleaningRadio === '1'
                },
                ddCSchedule2: {
                  ...state["ddCSchedule2"],
                  valueList: listCleanGrossWt2,
                  Enabled: cleaningRadio === '1'
                },
                ddCNWSchedule: {
                  ...state["ddCNWSchedule"],
                  valueList: listCleanNetWt,
                  Enabled: cleaningRadio === '2'
                },
                ddCOSchedule: {
                  ...state["ddCOSchedule"],
                  valueList: listCleanOther,
                  Enabled: cleaningRadio === '3'
                }
              }
            });
          }
          return false;
        })
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during fillCleanSchedule function"
        );
      }
      return false;
    }
  }
  //------------------------------------------ End fillCleanSchedule -------------------------------------

  //------------------------------------------- Start calcCostToCure -------------------------------------
  const calcCostToCure = (objCombo, dryWtOfPeanuts = 0, greenWtOfPeanuts = 0, defaultSelectedIndex = 0, index = 0) => {
    let weight;
    let costToCure = 0.00;
    const dryWtOfPeanutsFloat = parseFloat(dryWtOfPeanuts?.toString() || '0.00');
    const greenWtOfPeanutsFloat = parseFloat(greenWtOfPeanuts?.toString() || '0.00');

    if (objCombo !== null && !getValue(thisObj, [objCombo.name])) {
      // 'Listindex 0 is a dummy value "<<< Select >>>"
      return costToCure;
    }
    const dropdownSelectedIndex = objCombo ? objCombo.valueList.findIndex(item => item.key === getValue(thisObj, [objCombo.name])) : defaultSelectedIndex;
    if (dropdownSelectedIndex > 0) {

      const dryGreenIndList = index === 0 ? listCureDryGreenInd
        : (index === 1 ? listCureDryGreenInd1 : listCureDryGreenInd2);
      const rateTypeList = index === 0 ? listCureRateType
        : (index === 1 ? listCureRateType1 : listCureRateType2);
      const baseRateList = index === 0 ? listCostToCure
        : (index === 1 ? listCostToCure1 : listCostToCure2);

      switch (dryGreenIndList[dropdownSelectedIndex]?.trim()?.toUpperCase()) {
        case "D":
        case "N":
        case "O":
          weight = dryWtOfPeanutsFloat;
          break;
        case "G":
          weight = greenWtOfPeanutsFloat;
          break;
        default:
          weight = 0.00;
          break;
      }

      switch (rateTypeList[dropdownSelectedIndex]?.trim()?.toUpperCase()) {
        case "T":
          costToCure = parseFloat(baseRateList[dropdownSelectedIndex]?.toString() || '0.00') * weight / 2000;
          break;
        case "P":
          costToCure = parseFloat(baseRateList[dropdownSelectedIndex]?.toString() || '0.00') * weight;
          break;
        case "L":
          costToCure = parseFloat(baseRateList[dropdownSelectedIndex]?.toString() || '0.00');
          break;
        case "":
          costToCure = 0.00;
          break;
        default:
          break;
      }
    }
    return costToCure;
  }
  //------------------------------------------ End calcCostToCure ---------------------------------------

  //---------------------------------------- Start calcCostToClean --------------------------------------
  const calcCostToClean = (objCombo, dryWtOfPeanuts = 0.00, greenWtOfPeanuts = 0.00, defaultSelectedIndex = 0) => {
    let weight;
    let costToClean = 0.00;
    const dryWtOfPeanutsFloat = parseFloat(dryWtOfPeanuts?.toString() || '0.00');
    const greenWtOfPeanutsFloat = parseFloat(greenWtOfPeanuts?.toString() || '0.00');

    if (objCombo !== null && !getValue(thisObj, [objCombo.name])) {
      // 'Listindex 0 is a dummy value "<<< Select >>>"
      return costToClean;
    }
    const dropdownSelectedIndex = objCombo ? objCombo.valueList.findIndex(item => item.key === getValue(thisObj, [objCombo.name])) : defaultSelectedIndex;
    if (dropdownSelectedIndex > 0) {
      switch (listCleanDryGreenInd?.[dropdownSelectedIndex]?.trim()?.toUpperCase()) {
        case "D":
        case "N":
        case "O":
          weight = dryWtOfPeanutsFloat;
          break;
        case "G":
          weight = greenWtOfPeanutsFloat;
          break;
        default:
          weight = 0.00;
          break;
      }

      switch (listCleanRateType?.[dropdownSelectedIndex]?.trim()?.toUpperCase()) {
        case "T":
          costToClean = parseFloat(listCostToClean?.[dropdownSelectedIndex]?.toString() || '0.00') * weight / 2000;
          break;
        case "P":
          costToClean = parseFloat(listCostToClean?.[dropdownSelectedIndex]?.toString() || '0.00') * weight;
          break;
        case "L":
          costToClean = parseFloat(listCostToClean?.[dropdownSelectedIndex]?.toString() || '0.00');
          break;
        case "":
          costToClean = 0.00;
          break;
        default:
          break;
      }
    }
    return costToClean;
  }
  //----------------------------------------------- End calcCostToClean ------------------------------------------

  //------------------------------- Start Enable Required Curing Fields on Radio Change --------------------------
  const enableDisableCuringFields = (initialValue = null) => {
    const cureCleanGrossWtRadioValue = initialValue;
    if (cureCleanGrossWtRadioValue === '1') {
      enable(thisObj, 'txtCCMoisture0');
      enable(thisObj, 'txtCCMoisture1');
      enable(thisObj, 'txtCCMoisture2');
      enable(thisObj, 'ddCCSchedule0');
      enable(thisObj, 'ddCCSchedule1');
      enable(thisObj, 'ddCCSchedule2');
      disable(thisObj, 'txtCCNWMoisture');
      disable(thisObj, 'ddCCNWSchedule');
      disable(thisObj, 'txtCCOWeight');
      disable(thisObj, 'txtCCOMoisture');
      disable(thisObj, 'ddCCOSchedule');
    } else if (cureCleanGrossWtRadioValue === '2') {
      enable(thisObj, 'txtCCNWMoisture');
      enable(thisObj, 'ddCCNWSchedule');

      disable(thisObj, 'txtCCMoisture0');
      disable(thisObj, 'txtCCMoisture1');
      disable(thisObj, 'txtCCMoisture2');
      disable(thisObj, 'ddCCSchedule0');
      disable(thisObj, 'ddCCSchedule1');
      disable(thisObj, 'ddCCSchedule2');
      disable(thisObj, 'txtCCOWeight');
      disable(thisObj, 'txtCCOMoisture');
      disable(thisObj, 'ddCCOSchedule');
    } else if (cureCleanGrossWtRadioValue === '3') {
      enable(thisObj, 'txtCCOWeight');
      enable(thisObj, 'txtCCOMoisture');
      enable(thisObj, 'ddCCOSchedule');

      disable(thisObj, 'txtCCMoisture0');
      disable(thisObj, 'txtCCMoisture1');
      disable(thisObj, 'txtCCMoisture2');
      disable(thisObj, 'ddCCSchedule0');
      disable(thisObj, 'ddCCSchedule1');
      disable(thisObj, 'ddCCSchedule2');
      disable(thisObj, 'txtCCNWMoisture');
      disable(thisObj, 'ddCCNWSchedule');
    } else {
      disable(thisObj, 'txtCCMoisture0');
      disable(thisObj, 'txtCCMoisture1');
      disable(thisObj, 'txtCCMoisture2');
      disable(thisObj, 'ddCCSchedule0');
      disable(thisObj, 'ddCCSchedule1');
      disable(thisObj, 'ddCCSchedule2');
      disable(thisObj, 'txtCCNWMoisture');
      disable(thisObj, 'ddCCNWSchedule');
      disable(thisObj, 'txtCCOWeight');
      disable(thisObj, 'txtCCOMoisture');
      disable(thisObj, 'ddCCOSchedule');
    }
  };

  const resetCuringFields = () => {
    setValue(thisObj, 'txtCCMoisture0', '0.00');
    setValue(thisObj, 'txtCCMoisture1', '0.00');
    setValue(thisObj, 'txtCCMoisture2', '0.00');
    setValue(thisObj, 'txtCCCost0', '0.00');
    setValue(thisObj, 'txtCCCost1', '0.00');
    setValue(thisObj, 'txtCCCost2', '0.00');
    setValue(thisObj, 'ddCCSchedule0', '');
    setValue(thisObj, 'ddCCSchedule1', '');
    setValue(thisObj, 'ddCCSchedule2', '');
    setValue(thisObj, 'txtCCNWMoisture', '0.00');
    setValue(thisObj, 'txtCCNWCost', '0.00');
    setValue(thisObj, 'ddCCNWSchedule', '');
    setValue(thisObj, 'txtCCOWeight', '0.00');
    setValue(thisObj, 'txtCCOMoisture', '0.00');
    setValue(thisObj, 'txtCCOCost', '0.00');
    setValue(thisObj, 'ddCCOSchedule', '');
  };
  //---------------------------- End Enable Required Curing Fields on Radio Change ------------------------------

  //------------------------- Start Enable Required Cleaning Fields on Radio Change -----------------------------
  const enableDisableCleaningFields = (initialValue = null) => {
    const cleanGrossWtRadioValue = initialValue;
    if (cleanGrossWtRadioValue === '1') {
      enable(thisObj, 'ddCSchedule0');
      enable(thisObj, 'ddCSchedule1');
      enable(thisObj, 'ddCSchedule2');

      disable(thisObj, 'ddCNWSchedule');
      disable(thisObj, 'txtCOWeight');
      disable(thisObj, 'ddCOSchedule');
    } else if (cleanGrossWtRadioValue === '2') {
      enable(thisObj, 'ddCNWSchedule');

      disable(thisObj, 'ddCSchedule0');
      disable(thisObj, 'ddCSchedule1');
      disable(thisObj, 'ddCSchedule2');
      disable(thisObj, 'txtCOWeight');
      disable(thisObj, 'ddCOSchedule');
    } else if (cleanGrossWtRadioValue === '3') {
      enable(thisObj, 'txtCOWeight');
      enable(thisObj, 'ddCOSchedule');

      disable(thisObj, 'ddCSchedule0');
      disable(thisObj, 'ddCSchedule1');
      disable(thisObj, 'ddCSchedule2');
      disable(thisObj, 'ddCNWSchedule');
    } else {
      disable(thisObj, 'ddCSchedule0');
      disable(thisObj, 'ddCSchedule1');
      disable(thisObj, 'ddCSchedule2');
      disable(thisObj, 'ddCNWSchedule');
      disable(thisObj, 'txtCOWeight');
      disable(thisObj, 'ddCOSchedule');
    }
  };

  const resetCleaningFields = () => {
    setValue(thisObj, 'ddCSchedule0', '');
    setValue(thisObj, 'ddCSchedule1', '');
    setValue(thisObj, 'ddCSchedule2', '');
    setValue(thisObj, 'txtCCost0', '0.00');
    setValue(thisObj, 'txtCCost1', '0.00');
    setValue(thisObj, 'txtCCost2', '0.00');

    setValue(thisObj, 'ddCNWSchedule', '');
    setValue(thisObj, 'txtCNWCost', '0.00');
    setValue(thisObj, 'ddCOSchedule', '');
    setValue(thisObj, 'txtCOCost', '0.00');
  };
  //--------------------------- End Enable Required Cleaning Fields on Radio Change --------------------------------
  const isFormValid = () => {
    const isFormValid = true;
    // 'No checks necessary currently!
    return isFormValid;
  }

  const onbtnOkuic0Click = () => {
    // Set data to send in parent screen
    let LstrCureCleanCosts = '';
    let LstrPDCODES_And_CureCleanCosts = '';
    let LstrTICKET_CURING_WT_IND = '';
    let LstrTICKET_CLEANING_WT_IND = '';
    let LstrTICKET_MOISTURE = 0.00;
    let LstrTICKET_CLEAN_WT_OTHER = 0.00;
    let LstrTICKET_CURE_WT_OTHER = 0.00;
    let LstrTICKET_CURE_SCHEDULE = '';
    let LstrTICKET_CLEAN_SCHEDULE = '';

    const parentFormData = getData(thisObj, 'parentForm') || { Name: 'frmFV95' };
    if (['frmFV95', 'frm1007Settlement'].includes(parentFormData.Name)) {
      //   // 'generate save info only when called from FV95
      if (isFormValid()) {

        LstrTICKET_CURING_WT_IND = "G";
        LstrTICKET_CLEANING_WT_IND = "G";
        LstrTICKET_MOISTURE = 0;
        LstrTICKET_CLEAN_WT_OTHER = 0;
        LstrTICKET_CURE_WT_OTHER = 0;
        LstrTICKET_CURE_SCHEDULE = "";
        LstrTICKET_CLEAN_SCHEDULE = "";

        const txtClnCrgTicketNumber = getValue(thisObj, 'txtCleanCureTicketNum') || '';
        LstrCureCleanCosts = txtClnCrgTicketNumber + "|";
        // ' txtClnCrgTicketNumber - always the first record even if txtClnCrgTicketNumber is blank
        // ' Cure|Clean Costs
        // '   CUR | CLN
        // '   Gross (G)| Net (N)| Other(O)
        // '   Ticket# (Gross only) otherwise blank
        // '   Weight (Other Only) otherwise 0
        // '   Moisture
        // '   ScheduleID
        // '   Cost
        LstrPDCODES_And_CureCleanCosts = "";
        // ' PD Codes + Cure|Clean Costs
        // '   PD_CODE
        // '   CURE|CLEAN Cost

        // '********
        // 'Curing
        // '********

        const selectedCuringRadio = getRadioCureCleanValue();
        if (selectedCuringRadio === '1') {
          LstrTICKET_CURING_WT_IND = "G"
          for (let i = 0; i < 3; i++) {
            const grossWtTicket = (getValue(thisObj, `txtCCTicket${i}`) || '').toString().trim();
            if (grossWtTicket) {
              const moistureValue = parseFloat(getValue(thisObj, `txtCCMoisture${i}`)?.toString() || '0.00');
              const scheduleLabel = getValue(thisObj, ["ddCCSchedule" + (i)]) == undefined ? '' : getValue(thisObj, ["ddCCSchedule" + (i)]);
              const costValue = parseFloat(getValue(thisObj, `txtCCCost${i}`)?.replace(/,/g, '') || '0.00');
              const costLabel = 'CURE';
              LstrCureCleanCosts = LstrCureCleanCosts + "CUR,G," + grossWtTicket + ",0,"
                + moistureValue
                + "," + scheduleLabel + ","
                + costValue + "|";
              if (costValue > 0) {
                LstrPDCODES_And_CureCleanCosts = LstrPDCODES_And_CureCleanCosts
                  + costLabel + ",CUR,"
                  + costValue + "|";
              }
            }
          }
        } else if (selectedCuringRadio === '2') {
          const scheduleLabel = getValue(thisObj, "ddCCNWSchedule") == undefined ? '' : getValue(thisObj, "ddCCNWSchedule");
          const moistureValue = parseFloat(getValue(thisObj, 'txtCCNWMoisture') || '0.00');
          const costValue = parseFloat(getValue(thisObj, 'txtCCNWCost')?.replace(/,/g, '') || '0.00');
          const costLabel = 'CURE';
          LstrTICKET_CURING_WT_IND = "N";
          LstrTICKET_CURE_SCHEDULE = scheduleLabel;
          LstrTICKET_MOISTURE = moistureValue;
          LstrCureCleanCosts = LstrCureCleanCosts + "CUR,N,,0,"
            + moistureValue + "," + scheduleLabel + ","
            + costValue + "|";
          if (costValue > 0) {
            LstrPDCODES_And_CureCleanCosts = LstrPDCODES_And_CureCleanCosts
              + costLabel + ",CUR," + costValue + "|";
          }
        } else if (selectedCuringRadio === '3') {
          const weightValue = parseFloat(getValue(thisObj, 'txtCCOWeight')?.replace(/,/g, '') || '0.00');
          const scheduleLabel = getValue(thisObj, "ddCCOSchedule") == undefined ? '' : getValue(thisObj, "ddCCOSchedule");
          const moistureValue = parseFloat(getValue(thisObj, 'txtCCOMoisture') || '0.00');
          const costValue = parseFloat(getValue(thisObj, 'txtCCOCost')?.replace(/,/g, '') || '0.00');
          const costLabel = 'CURE';
          LstrTICKET_CURING_WT_IND = "O";
          LstrTICKET_CURE_SCHEDULE = scheduleLabel;
          LstrTICKET_CURE_WT_OTHER = weightValue;
          LstrCureCleanCosts = LstrCureCleanCosts + "CUR,O,,"
            + weightValue + ","
            + moistureValue + "," + scheduleLabel + "," + costValue + "|"
          if (costValue > 0) {
            LstrPDCODES_And_CureCleanCosts = LstrPDCODES_And_CureCleanCosts
              + costLabel + ",CUR," + costValue + "|"
          }
        }
        // '********
        // 'Cleaning
        // '********
        const selectedCleaningRadio = getRadioCleanValue();
        if (selectedCleaningRadio === '1') {
          LstrTICKET_CLEANING_WT_IND = "G"
          for (let i = 0; i < 3; i++) {
            const grossWtTicket = (getValue(thisObj, `txtCTicket${i}`) || '').toString().trim();
            if (grossWtTicket) {
              const scheduleLabel = getValue(thisObj, ["ddCSchedule" + (i)]) == undefined ? '' : getValue(thisObj, ["ddCSchedule" + (i)]);
              const costValue = parseFloat(getValue(thisObj, `txtCCost${i}`)?.replace(/,/g, '') || '0.00');
              const costLabel = 'CLEAN';
              LstrCureCleanCosts = LstrCureCleanCosts + "CLN,G,"
                + grossWtTicket + ",0,0," + scheduleLabel + "," + costValue + "|";
              if (costValue > 0) {
                LstrPDCODES_And_CureCleanCosts = LstrPDCODES_And_CureCleanCosts
                  + costLabel + ",CLN," + costValue + "|";
              }
            }
          }
        } else if (selectedCleaningRadio === '2') {
          const scheduleLabel = getValue(thisObj, "ddCNWSchedule") == undefined ? '' : getValue(thisObj, "ddCNWSchedule");
          const costValue = parseFloat(getValue(thisObj, 'txtCNWCost')?.replace(/,/g, '') || '0.00');
          const costLabel = 'CLEAN';
          LstrTICKET_CLEANING_WT_IND = "N";
          LstrTICKET_CLEAN_SCHEDULE = scheduleLabel;
          LstrCureCleanCosts = LstrCureCleanCosts + "CLN,N,,0,0,"
            + scheduleLabel + "," + costValue + "|";
          if (costValue > 0) {
            LstrPDCODES_And_CureCleanCosts = LstrPDCODES_And_CureCleanCosts
              + costLabel + ",CLN," + costValue + "|";
          }
        } else if (selectedCleaningRadio === '3') {
          const weightValue = parseFloat(getValue(thisObj, 'txtCOWeight')?.replace(/,/g, '') || '0.00');
          const scheduleLabel = getValue(thisObj, "ddCOSchedule") == undefined ? '' : getValue(thisObj, "ddCOSchedule");
          const costValue = parseFloat(getValue(thisObj, 'txtCOCost')?.replace(/,/g, '') || '0.00');
          const costLabel = 'CLEAN';
          LstrTICKET_CLEANING_WT_IND = "O";
          LstrTICKET_CLEAN_SCHEDULE = scheduleLabel;
          LstrTICKET_CLEAN_WT_OTHER = weightValue;
          LstrCureCleanCosts = LstrCureCleanCosts + "CLN,O,,"
            + weightValue + ",0," + scheduleLabel + ","
            + costValue + "|";
          if (costValue > 0) {
            LstrPDCODES_And_CureCleanCosts = LstrPDCODES_And_CureCleanCosts
              + costLabel + ",CLN," + costValue + "|";
          }
        }

        const costToCure = parseFloat(getValue(thisObj, 'txtCostToCure')?.replace(/,/g, '') || '0.00');
        const costToClean = parseFloat(getValue(thisObj, 'txtCostToClean')?.replace(/,/g, '') || '0.00');
        // 'vars in form reset to new values
        const ticketInfo = {
          txtClnCrgTicketNumber,
          LstrTICKET_CURING_WT_IND,
          LstrTICKET_CLEANING_WT_IND,
          LstrTICKET_MOISTURE,
          LstrTICKET_CLEAN_WT_OTHER,
          LstrTICKET_CURE_WT_OTHER,
          LstrTICKET_CURE_SCHEDULE,
          LstrTICKET_CLEAN_SCHEDULE,
          lblCostToCure: costToCure,
          lblCostToClean: costToClean
        };
        setData(thisObj, 'TicketInfo', ticketInfo);

        setData(thisObj, 'CureCleanCosts', LstrCureCleanCosts);
        setData(thisObj, 'PDcodes_cure_clean_costs', LstrPDCODES_And_CureCleanCosts);
        setData(thisObj, 'TotalCureCost', costToCure);
        setData(thisObj, 'TotalCleanCost', costToClean);
        setData(thisObj, 'mbCancelled', false);
        setData(thisObj, 'closingOfCleaningCuringCost', true);
      }
    }

    // Unload
    try {
      document.getElementById("Settlements_CleaningCuringCostsPopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError)
        showMessage(thisObj, err.message);
      else
        showMessage(thisObj, 'Exception in Ok button Click');
    }
  }

  const onbtnCanceluic0Click = async () => {
    try {
      const parentFormData = getData(thisObj, 'parentForm') || { Name: 'frmFV95' };
      if (['frmFV95', 'frm1007Settlement'].includes(parentFormData.Name)) {
        setData(thisObj, 'mbCancelled', true);
      }
      document.getElementById("Settlements_CleaningCuringCostsPopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError)
        showMessage(thisObj, err.message);
      else
        showMessage(thisObj, 'Exception in Cancel button Click');
    }
  }
  thisObj.onbtnOkuic0Click = onbtnOkuic0Click;
  thisObj.onbtnCanceluic0Click = onbtnCanceluic0Click;

  const ontxtCCMoisture0Change = () => {
    if (getValue(thisObj, 'txtCCMoisture0') && getValue(thisObj, 'txtCCMoisture0') >= 0) {
      fillCureSchedule(state.ddCCSchedule0, getValue(thisObj, 'txtCCMoisture0'), 0, "D,G");
      const costToCure0 = calcCostToClean(state.ddCCSchedule0, getValue(thisObj, 'txtCCDryWt0')?.replace(/,/g, ''), getValue(thisObj, 'txtCCGreenWt0')?.replace(/,/g, ''), 0, 0);
      setValue(thisObj, 'txtCCCost0', costToCure0 ? parseFloat(roundTo(costToCure0, 2)).toLocaleString('en-US', fractionOption) : '0.00');
    } else {
      setValue(thisObj, 'txtCCCost0', '0.00');
    }
    calcCostToCureTotal();
  };

  thisObj.ontxtCCMoisture0Change = ontxtCCMoisture0Change;

  const ontxtCCMoisture0Blur = () => {
    if (!getValue(thisObj, 'txtCCMoisture0')) {
      setValue(thisObj, 'txtCCMoisture0', '0.00');
      fillCureSchedule(state.ddCCSchedule0, 0, 0, "D,G");
      const costToCure0 = calcCostToClean(state.ddCCSchedule0, getValue(thisObj, 'txtCCDryWt0')?.replace(/,/g, ''), getValue(thisObj, 'txtCCGreenWt0')?.replace(/,/g, ''), 0, 0);
      setValue(thisObj, 'txtCCCost0', costToCure0 ? parseFloat(roundTo(costToCure0, 2)).toLocaleString('en-US', fractionOption) : '0.00');
      calcCostToCureTotal();
    }
  };

  thisObj.ontxtCCMoisture0Blur = ontxtCCMoisture0Blur;

  const ontxtCCMoisture1Change = () => {
    if (getValue(thisObj, 'txtCCMoisture1') && getValue(thisObj, 'txtCCMoisture1') >= 0) {
      fillCureSchedule(state.ddCCSchedule1, getValue(thisObj, 'txtCCMoisture1'), 1, "D,G");
      const costToCure1 = calcCostToClean(state.ddCCSchedule1, getValue(thisObj, 'txtCCDryWt1')?.replace(/,/g, ''), getValue(thisObj, 'txtCCGreenWt1')?.replace(/,/g, ''), 0, 1);
      setValue(thisObj, 'txtCCCost1', costToCure1 ? parseFloat(roundTo(costToCure1, 2)).toLocaleString('en-US', fractionOption) : '0.00');
    } else {
      setValue(thisObj, 'txtCCCost1', '0.00');
    }
    calcCostToCureTotal();
  };

  thisObj.ontxtCCMoisture1Change = ontxtCCMoisture1Change;

  const ontxtCCMoisture1Blur = () => {
    if (!getValue(thisObj, 'txtCCMoisture1')) {
      setValue(thisObj, 'txtCCMoisture1', '0.00');
      fillCureSchedule(state.ddCCSchedule1, 0, 1, "D,G");
      const costToCure1 = calcCostToClean(state.ddCCSchedule1, getValue(thisObj, 'txtCCDryWt1')?.replace(/,/g, ''), getValue(thisObj, 'txtCCGreenWt1')?.replace(/,/g, ''), 0, 1);
      setValue(thisObj, 'txtCCCost1', costToCure1 ? parseFloat(roundTo(costToCure1, 2)).toLocaleString('en-US', fractionOption) : '0.00');
      calcCostToCureTotal();
    }
  };

  thisObj.ontxtCCMoisture1Blur = ontxtCCMoisture1Blur;

  const ontxtCCMoisture2Change = () => {
    if (getValue(thisObj, 'txtCCMoisture2') && getValue(thisObj, 'txtCCMoisture2') >= 0) {
      fillCureSchedule(state.ddCCSchedule2, getValue(thisObj, 'txtCCMoisture2'), 2, "D,G");
      const costToCure2 = calcCostToClean(state.ddCCSchedule2, getValue(thisObj, 'txtCCDryWt2')?.replace(/,/g, ''), getValue(thisObj, 'txtCCGreenWt2')?.replace(/,/g, ''), 0, 2);
      setValue(thisObj, 'txtCCCost2', costToCure2 ? parseFloat(roundTo(costToCure2, 2)).toLocaleString('en-US', fractionOption) : '0.00');
    } else {
      setValue(thisObj, 'txtCCCost2', '0.00');
    }
    calcCostToCureTotal();
  };

  thisObj.ontxtCCMoisture2Change = ontxtCCMoisture2Change;

  const ontxtCCMoisture2Blur = () => {
    if (!getValue(thisObj, 'txtCCMoisture2')) {
      setValue(thisObj, 'txtCCMoisture2', '0.00');
      fillCureSchedule(state.ddCCSchedule2, 0, 2, "D,G");
      const costToCure2 = calcCostToClean(state.ddCCSchedule2, getValue(thisObj, 'txtCCDryWt2')?.replace(/,/g, ''), getValue(thisObj, 'txtCCGreenWt2')?.replace(/,/g, ''), 0, 2);
      setValue(thisObj, 'txtCCCost2', costToCure2 ? parseFloat(roundTo(costToCure2, 2)).toLocaleString('en-US', fractionOption) : '0.00');
      calcCostToCureTotal();
    }
  };

  thisObj.ontxtCCMoisture2Blur = ontxtCCMoisture2Blur;

  const ontxtCCNWMoistureChange = () => {
    if (getValue(thisObj, 'txtCCNWMoisture') && getValue(thisObj, 'txtCCNWMoisture') >= 0) {
      fillCureSchedule(state.ddCCNWSchedule, getValue(thisObj, 'txtCCNWMoisture'), 0, "N");
      const costToCureNW = calcCostToClean(state.ddCCNWSchedule, getValue(thisObj, 'txtCCNetWeight')?.replace(/,/g, ''), 0, 0, 0);
      setValue(thisObj, 'txtCCNWCost', costToCureNW ? parseFloat(roundTo(costToCureNW, 2)).toLocaleString('en-US', fractionOption) : '0.00');
    } else {
      setValue(thisObj, 'txtCCNWCost', '0.00');
    }
    calcCostToCureTotal();
  };

  thisObj.ontxtCCNWMoistureChange = ontxtCCNWMoistureChange;

  const ontxtCCNWMoistureBlur = () => {
    if (!getValue(thisObj, 'txtCCNWMoisture')) {
      setValue(thisObj, 'txtCCNWMoisture', '0.00');
      fillCureSchedule(state.ddCCNWSchedule, 0, 0, "N");
      const costToCureNW = calcCostToClean(state.ddCCNWSchedule, getValue(thisObj, 'txtCCNetWeight')?.replace(/,/g, ''), 0, 0, 0);
      setValue(thisObj, 'txtCCNWCost', costToCureNW ? parseFloat(roundTo(costToCureNW, 2)).toLocaleString('en-US', fractionOption) : '0.00');
      calcCostToCureTotal();
    }
  };

  thisObj.ontxtCCNWMoistureBlur = ontxtCCNWMoistureBlur;

  const ontxtCCOMoistureChange = () => {
    if (getValue(thisObj, 'txtCCOMoisture') && getValue(thisObj, 'txtCCOMoisture') >= 0) {
      fillCureSchedule(state.ddCCOSchedule, getValue(thisObj, 'txtCCOMoisture'), 0, "O");
      const costToCureO = calcCostToClean(state.ddCCOSchedule, getValue(thisObj, 'txtCCOWeight')?.replace(/,/g, ''), 0, 0, 0);
      setValue(thisObj, 'txtCCOCost', costToCureO ? parseFloat(roundTo(costToCureO, 2)).toLocaleString('en-US', fractionOption) : '0.00');
    } else {
      setValue(thisObj, 'txtCCOCost', '0.00');
    }
    calcCostToCureTotal();
  };

  thisObj.ontxtCCOMoistureChange = ontxtCCOMoistureChange;

  const ontxtCCOMoistureBlur = () => {
    if (!getValue(thisObj, 'txtCCOMoisture')) {
      setValue(thisObj, 'txtCCOMoisture', '0.00');
      fillCureSchedule(state.ddCCOSchedule, 0, 0, "O");
      const costToCureO = calcCostToClean(state.ddCCOSchedule, getValue(thisObj, 'txtCCOWeight')?.replace(/,/g, ''), 0, 0, 0);
      setValue(thisObj, 'txtCCOCost', costToCureO ? parseFloat(roundTo(costToCureO, 2)).toLocaleString('en-US', fractionOption) : '0.00');
      calcCostToCureTotal();
    }
  };

  thisObj.ontxtCCOMoistureBlur = ontxtCCOMoistureBlur;

  const onddCCSchedule0Change = () => {
    const costToCure0 = calcCostToCure(state.ddCCSchedule0, getValue(thisObj, 'txtCCDryWt0')?.replace(/,/g, ''), getValue(thisObj, 'txtCCGreenWt0')?.replace(/,/g, ''), 0, 0);
    setValue(thisObj, 'txtCCCost0', costToCure0 ? parseFloat(roundTo(costToCure0, 2)).toLocaleString('en-US', fractionOption) : '0.00');

    calcCostToCureTotal();
  };
  thisObj.onddCCSchedule0Change = onddCCSchedule0Change;

  const onddCCSchedule1Change = () => {
    const costToCure1 = calcCostToCure(state.ddCCSchedule1, getValue(thisObj, 'txtCCDryWt1')?.replace(/,/g, ''), getValue(thisObj, 'txtCCGreenWt1')?.replace(/,/g, ''), 0, 1);
    setValue(thisObj, 'txtCCCost1', costToCure1 ? parseFloat(roundTo(costToCure1, 2)).toLocaleString('en-US', fractionOption) : '0.00');

    calcCostToCureTotal();
  };
  thisObj.onddCCSchedule1Change = onddCCSchedule1Change;

  const onddCCSchedule2Change = () => {
    const costToCure2 = calcCostToCure(state.ddCCSchedule2, getValue(thisObj, 'txtCCDryWt2')?.replace(/,/g, ''), getValue(thisObj, 'txtCCGreenWt2')?.replace(/,/g, ''), 0, 2);
    setValue(thisObj, 'txtCCCost2', costToCure2 ? parseFloat(roundTo(costToCure2, 2)).toLocaleString('en-US', fractionOption) : '0.00');

    calcCostToCureTotal();
  };
  thisObj.onddCCSchedule2Change = onddCCSchedule2Change;

  const onddCCNWScheduleChange = () => {
    const nwCostToCure = calcCostToCure(state.ddCCNWSchedule, getValue(thisObj, 'txtCCNetWeight')?.replace(/,/g, ''), 0, 0, 0);
    setValue(thisObj, 'txtCCNWCost', nwCostToCure ? parseFloat(roundTo(nwCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');

    calcCostToCureTotal();
  };
  thisObj.onddCCNWScheduleChange = onddCCNWScheduleChange;

  const onddCCOScheduleChange = () => {
    const otherCostToCure = calcCostToCure(state.ddCCOSchedule, getValue(thisObj, 'txtCCOWeight')?.replace(/,/g, ''), 0, 0, 0);
    setValue(thisObj, 'txtCCOCost', otherCostToCure ? parseFloat(roundTo(otherCostToCure, 2)).toLocaleString('en-US', fractionOption) : '0.00');

    calcCostToCureTotal();
  };
  thisObj.onddCCOScheduleChange = onddCCOScheduleChange;

  const onddCSchedule0Change = () => {
    const costToClean0 = calcCostToClean(state.ddCSchedule0, getValue(thisObj, 'txtCDryWt0')?.replace(/,/g, ''), getValue(thisObj, 'txtCGreenWt0')?.replace(/,/g, ''));
    setValue(thisObj, 'txtCCost0', costToClean0 ? parseFloat(roundTo(costToClean0, 2)).toLocaleString('en-US', fractionOption) : '0.00');

    calcCostToCleanTotal();
  };
  thisObj.onddCSchedule0Change = onddCSchedule0Change;

  const onddCSchedule1Change = () => {
    const costToClean1 = calcCostToClean(state.ddCSchedule1, getValue(thisObj, 'txtCDryWt1')?.replace(/,/g, ''), getValue(thisObj, 'txtCGreenWt1')?.replace(/,/g, ''));
    setValue(thisObj, 'txtCCost1', costToClean1 ? parseFloat(roundTo(costToClean1, 2)).toLocaleString('en-US', fractionOption) : '0.00');

    calcCostToCleanTotal();
  };
  thisObj.onddCSchedule1Change = onddCSchedule1Change;

  const onddCSchedule2Change = () => {
    const costToClean2 = calcCostToClean(state.ddCSchedule2, getValue(thisObj, 'txtCDryWt2')?.replace(/,/g, ''), getValue(thisObj, 'txtCGreenWt2')?.replace(/,/g, ''));
    setValue(thisObj, 'txtCCost2', costToClean2 ? parseFloat(roundTo(costToClean2, 2)).toLocaleString('en-US', fractionOption) : '0.00');

    calcCostToCleanTotal();
  };
  thisObj.onddCSchedule2Change = onddCSchedule2Change;

  const onddCNWScheduleChange = () => {
    const nwCostToClean = calcCostToClean(state.ddCNWSchedule, getValue(thisObj, 'txtCNetWeight')?.replace(/,/g, ''), 0);
    setValue(thisObj, 'txtCNWCost', nwCostToClean ? parseFloat(roundTo(nwCostToClean, 2)).toLocaleString('en-US', fractionOption) : '0.00');

    calcCostToCleanTotal();
  };
  thisObj.onddCNWScheduleChange = onddCNWScheduleChange;

  const onddCOScheduleChange = () => {
    const otherCostToClean = calcCostToClean(state.ddCOSchedule, getValue(thisObj, 'txtCOWeight')?.replace(/,/g, ''), 0);
    setValue(thisObj, 'txtCOCost', otherCostToClean ? parseFloat(roundTo(otherCostToClean, 2)).toLocaleString('en-US', fractionOption) : '0.00');

    calcCostToCleanTotal();
  };
  thisObj.onddCOScheduleChange = onddCOScheduleChange;

  const calcCostToCureTotal = () => {
    let costToCure = 0.00;
    const cureCleanGrossWtRadioValue = getRadioCureCleanValue();
    if (cureCleanGrossWtRadioValue) {
      if (cureCleanGrossWtRadioValue === '1') {
        const costToCure0 = parseFloat((getValue(thisObj, 'txtCCCost0')?.replace(/,/g, '') || 0.00).toString());
        const costToCure1 = parseFloat((getValue(thisObj, 'txtCCCost1')?.replace(/,/g, '') || 0.00).toString());
        const costToCure2 = parseFloat((getValue(thisObj, 'txtCCCost2')?.replace(/,/g, '') || 0.00).toString());
        costToCure = costToCure0 + costToCure1 + costToCure2;
      } else if (cureCleanGrossWtRadioValue === '2') {
        costToCure = parseFloat((getValue(thisObj, 'txtCCNWCost')?.replace(/,/g, '') || 0.00).toString());
      } else if (cureCleanGrossWtRadioValue === '3') {
        costToCure = parseFloat((getValue(thisObj, 'txtCCOCost')?.replace(/,/g, '') || 0.00).toString());
      }
    }
    setValue(thisObj, 'txtCostToCure', parseFloat(roundTo(costToCure, 2)).toLocaleString('en-US', fractionOption));
  };

  const calcCostToCleanTotal = () => {
    let costToClean = 0.00;
    const cleanGrossWtRadioValue = getRadioCleanValue();
    if (cleanGrossWtRadioValue) {
      if (cleanGrossWtRadioValue === '1') {
        const costToClean0 = parseFloat((getValue(thisObj, 'txtCCost0')?.replace(/,/g, '') || 0.00).toString());
        const costToClean1 = parseFloat((getValue(thisObj, 'txtCCost1')?.replace(/,/g, '') || 0.00).toString());
        const costToClean2 = parseFloat((getValue(thisObj, 'txtCCost2')?.replace(/,/g, '') || 0.00).toString());
        costToClean = costToClean0 + costToClean1 + costToClean2;
      } else if (cleanGrossWtRadioValue === '2') {
        costToClean = parseFloat((getValue(thisObj, 'txtCNWCost')?.replace(/,/g, '') || 0.00).toString());
      } else if (cleanGrossWtRadioValue === '3') {
        costToClean = parseFloat((getValue(thisObj, 'txtCOCost')?.replace(/,/g, '') || 0.00).toString());
      }
    }
    setValue(thisObj, 'txtCostToClean', parseFloat(roundTo(costToClean, 2)).toLocaleString('en-US', fractionOption));
  };

  const removeCleanScheduleOptions = () => {
    const cleaningRadio = getRadioCleanValue();
    const emptyValueList = [{ key: '', description: '' }];
    thisObj.setState(current => {
      return {
        ...current,
        ddCSchedule0: {
          ...state["ddCSchedule0"],
          valueList: emptyValueList,
          Enabled: cleaningRadio === '1'
        },
        ddCSchedule1: {
          ...state["ddCSchedule1"],
          valueList: emptyValueList,
          Enabled: cleaningRadio === '1'
        },
        ddCSchedule2: {
          ...state["ddCSchedule2"],
          valueList: emptyValueList,
          Enabled: cleaningRadio === '1'
        },
        ddCNWSchedule: {
          ...state["ddCNWSchedule"],
          valueList: emptyValueList,
          Enabled: cleaningRadio === '2'
        },
        ddCOSchedule: {
          ...state["ddCOSchedule"],
          valueList: emptyValueList,
          Enabled: cleaningRadio === '3'
        }
      }
    });
  };

  const onradioCureCleanChange = () => {
    resetCuringFields();
    resetCleaningFields();
    if (getValue(thisObj, 'radioCureClean') === '2') {
      removeCleanScheduleOptions();
      disable(thisObj, 'radioCleanGrossWt');
      disable(thisObj, 'radioCleanNetWt');
      disable(thisObj, 'radioCleanOther');
    } else {
      fillCleanSchedule();
      enable(thisObj, 'radioCleanGrossWt');
      enable(thisObj, 'radioCleanNetWt');
      enable(thisObj, 'radioCleanOther');
    }
  };

  thisObj.onradioCureCleanChange = onradioCureCleanChange;

  const onradioCureCleanValueChange = () => {
    const selectedCuringRadio = getRadioCureCleanValue();
    resetCuringFields();
    calcCostToCureTotal();
    const cureCleanCostDetails = getData(thisObj, 'lstrxml');
    let selectedCuringWtInd = '';
    if (selectedCuringRadio === '1') {
      selectedCuringWtInd = 'G';
    } else if (selectedCuringRadio === '2') {
      selectedCuringWtInd = 'N';
    } else if (selectedCuringRadio === '3') {
      selectedCuringWtInd = 'O';
    }
    setCuringData(cureCleanCostDetails[0], selectedCuringWtInd);
  };

  const onradioCureCleanGrossWtChange = () => {
    if (getValue(thisObj, 'radioCureCleanGrossWt') === '1') {
      setRadioCureCleanValue('1');
      onradioCureCleanValueChange();
    }
  };

  const onradioCureCleanNetWtChange = () => {
    if (getValue(thisObj, 'radioCureCleanNetWt') === '1') {
      setRadioCureCleanValue('2');
      onradioCureCleanValueChange();
    }
  };

  const onradioCureCleanOtherChange = () => {
    if (getValue(thisObj, 'radioCureCleanOther') === '1') {
      setRadioCureCleanValue('3');
      onradioCureCleanValueChange();
    }
  };

  thisObj.onradioCureCleanGrossWtChange = onradioCureCleanGrossWtChange;
  thisObj.onradioCureCleanNetWtChange = onradioCureCleanNetWtChange;
  thisObj.onradioCureCleanOtherChange = onradioCureCleanOtherChange;

  const onradioCleanValueChange = () => {
    const selectedCleaningRadio = getRadioCleanValue();
    enableDisableCleaningFields(selectedCleaningRadio);
    resetCleaningFields();
    fillCleanSchedule();
    calcCostToCleanTotal();
  };

  const onradioCleanGrossWtChange = () => {
    if (getValue(thisObj, 'radioCleanGrossWt') === '1') {
      setRadioCleanValue('1');
      onradioCleanValueChange();
    }
  };

  const onradioCleanNetWtChange = () => {
    if (getValue(thisObj, 'radioCleanNetWt') === '1') {
      setRadioCleanValue('2');
      onradioCleanValueChange();
    }
  };

  const onradioCleanOtherChange = () => {
    if (getValue(thisObj, 'radioCleanOther') === '1') {
      setRadioCleanValue('3');
      onradioCleanValueChange();
    }
  };

  thisObj.onradioCleanGrossWtChange = onradioCleanGrossWtChange;
  thisObj.onradioCleanNetWtChange = onradioCleanNetWtChange;
  thisObj.onradioCleanOtherChange = onradioCleanOtherChange;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={values => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_CleaningCuringCosts*/}

              {/* END_USER_CODE-USER_BEFORE_CleaningCuringCosts*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCleaningCuringCosts*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCleaningCuringCosts*/}

              <GroupBoxWidget
                conf={state.grpbxCleaningCuringCosts}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxCureClean*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCureClean*/}

                <GroupBoxWidget conf={state.grpbxCureClean} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_grpbxRadioCureClean*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRadioCureClean*/}

                  <GroupBoxWidget
                    conf={state.grpbxRadioCureClean}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_radioCureClean*/}

                    {/* END_USER_CODE-USER_BEFORE_radioCureClean*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioCureClean}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioCureClean*/}

                    {/* END_USER_CODE-USER_AFTER_radioCureClean*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRadioCureClean*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRadioCureClean*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxCureCleanGrossWt*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxCureCleanGrossWt*/}

                  <GroupBoxWidget
                    conf={state.grpbxCureCleanGrossWt}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_radioCureCleanGrossWt*/}

                    {/* END_USER_CODE-USER_BEFORE_radioCureCleanGrossWt*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioCureCleanGrossWt}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioCureCleanGrossWt*/}

                    {/* END_USER_CODE-USER_AFTER_radioCureCleanGrossWt*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCTicket*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCTicket*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCTicket}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCTicket*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCTicket*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCDryWt*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCDryWt*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCDryWt}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCDryWt*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCDryWt*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCGreenWt*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCGreenWt*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCGreenWt}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCGreenWt*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCGreenWt*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCMoisture*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCMoisture*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCMoisture}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCMoisture*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCMoisture*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCSchedule*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCSchedule*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCSchedule}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCSchedule*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCSchedule*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCCost*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCCost*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCCost}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCCost*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCCost*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxCureCleanGrossWtRow0*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxCureCleanGrossWtRow0*/}

                    <GroupBoxWidget
                      conf={state.grpbxCureCleanGrossWtRow0}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtCCTicket0*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCTicket0*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCTicket0}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCTicket0*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCTicket0*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCDryWt0*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCDryWt0*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCDryWt0}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCDryWt0*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCDryWt0*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCGreenWt0*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCGreenWt0*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCGreenWt0}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCGreenWt0*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCGreenWt0*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCMoisture0*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCMoisture0*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCMoisture0}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCMoisture0*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCMoisture0*/}
                      {/* START_USER_CODE-USER_BEFORE_ddCCSchedule0*/}

                      {/* END_USER_CODE-USER_BEFORE_ddCCSchedule0*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddCCSchedule0}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddCCSchedule0*/}

                      {/* END_USER_CODE-USER_AFTER_ddCCSchedule0*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCCost0*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCCost0*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCCost0}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCCost0*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCCost0*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxCureCleanGrossWtRow0*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxCureCleanGrossWtRow0*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxCureCleanGrossWtRow1*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxCureCleanGrossWtRow1*/}

                    <GroupBoxWidget
                      conf={state.grpbxCureCleanGrossWtRow1}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtCCTicket1*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCTicket1*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCTicket1}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCTicket1*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCTicket1*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCDryWt1*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCDryWt1*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCDryWt1}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCDryWt1*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCDryWt1*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCGreenWt1*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCGreenWt1*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCGreenWt1}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCGreenWt1*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCGreenWt1*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCMoisture1*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCMoisture1*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCMoisture1}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCMoisture1*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCMoisture1*/}
                      {/* START_USER_CODE-USER_BEFORE_ddCCSchedule1*/}

                      {/* END_USER_CODE-USER_BEFORE_ddCCSchedule1*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddCCSchedule1}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddCCSchedule1*/}

                      {/* END_USER_CODE-USER_AFTER_ddCCSchedule1*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCCost1*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCCost1*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCCost1}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCCost1*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCCost1*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxCureCleanGrossWtRow1*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxCureCleanGrossWtRow1*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxCureCleanGrossWtRow2*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxCureCleanGrossWtRow2*/}

                    <GroupBoxWidget
                      conf={state.grpbxCureCleanGrossWtRow2}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtCCTicket2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCTicket2*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCTicket2}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCTicket2*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCTicket2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCDryWt2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCDryWt2*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCDryWt2}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCDryWt2*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCDryWt2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCGreenWt2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCGreenWt2*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCGreenWt2}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCGreenWt2*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCGreenWt2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCMoisture2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCMoisture2*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCMoisture2}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCMoisture2*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCMoisture2*/}
                      {/* START_USER_CODE-USER_BEFORE_ddCCSchedule2*/}

                      {/* END_USER_CODE-USER_BEFORE_ddCCSchedule2*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddCCSchedule2}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddCCSchedule2*/}

                      {/* END_USER_CODE-USER_AFTER_ddCCSchedule2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCCost2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCCost2*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCCost2}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCCost2*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCCost2*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxCureCleanGrossWtRow2*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxCureCleanGrossWtRow2*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxCureCleanGrossWt*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxCureCleanGrossWt*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxCureCleanNetWt*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxCureCleanNetWt*/}

                  <GroupBoxWidget
                    conf={state.grpbxCureCleanNetWt}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_radioCureCleanNetWt*/}

                    {/* END_USER_CODE-USER_BEFORE_radioCureCleanNetWt*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioCureCleanNetWt}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioCureCleanNetWt*/}

                    {/* END_USER_CODE-USER_AFTER_radioCureCleanNetWt*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCNetWeight*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCNetWeight*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCNetWeight}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCNetWeight*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCNetWeight*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCNWMoisture*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCNWMoisture*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCNWMoisture}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCNWMoisture*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCNWMoisture*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCNWSchedule*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCNWSchedule*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCNWSchedule}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCNWSchedule*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCNWSchedule*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCNWCost*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCNWCost*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCNWCost}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCNWCost*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCNWCost*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxCureCleanNetWtRow*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxCureCleanNetWtRow*/}

                    <GroupBoxWidget
                      conf={state.grpbxCureCleanNetWtRow}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtCCNetWeight*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCNetWeight*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCNetWeight}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCNetWeight*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCNetWeight*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCNWMoisture*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCNWMoisture*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCNWMoisture}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCNWMoisture*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCNWMoisture*/}
                      {/* START_USER_CODE-USER_BEFORE_ddCCNWSchedule*/}

                      {/* END_USER_CODE-USER_BEFORE_ddCCNWSchedule*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddCCNWSchedule}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddCCNWSchedule*/}

                      {/* END_USER_CODE-USER_AFTER_ddCCNWSchedule*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCNWCost*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCNWCost*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCNWCost}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCNWCost*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCNWCost*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxCureCleanNetWtRow*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxCureCleanNetWtRow*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxCureCleanNetWt*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxCureCleanNetWt*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxCureCleanOther*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxCureCleanOther*/}

                  <GroupBoxWidget
                    conf={state.grpbxCureCleanOther}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_radioCureCleanOther*/}

                    {/* END_USER_CODE-USER_BEFORE_radioCureCleanOther*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioCureCleanOther}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioCureCleanOther*/}

                    {/* END_USER_CODE-USER_AFTER_radioCureCleanOther*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCOWeight*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCOWeight*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCOWeight}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCOWeight*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCOWeight*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCOMoisture*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCOMoisture*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCOMoisture}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCOMoisture*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCOMoisture*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCOSchedule*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCOSchedule*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCOSchedule}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCOSchedule*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCOSchedule*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCOCost*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCOCost*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCOCost}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCOCost*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCOCost*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxCureCleanOtherRow*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxCureCleanOtherRow*/}

                    <GroupBoxWidget
                      conf={state.grpbxCureCleanOtherRow}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtCCOWeight*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCOWeight*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCOWeight}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCOWeight*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCOWeight*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCOMoisture*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCOMoisture*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCOMoisture}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCOMoisture*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCOMoisture*/}
                      {/* START_USER_CODE-USER_BEFORE_ddCCOSchedule*/}

                      {/* END_USER_CODE-USER_BEFORE_ddCCOSchedule*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddCCOSchedule}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddCCOSchedule*/}

                      {/* END_USER_CODE-USER_AFTER_ddCCOSchedule*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCOCost*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCOCost*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCOCost}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCOCost*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCOCost*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxCureCleanOtherRow*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxCureCleanOtherRow*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxCureCleanOther*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxCureCleanOther*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCureClean*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCureClean*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxClean*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxClean*/}

                <GroupBoxWidget conf={state.grpbxClean} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_grpbxRadioClean*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRadioClean*/}

                  <GroupBoxWidget
                    conf={state.grpbxRadioClean}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblCleaning*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCleaning*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCleaning}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCleaning*/}

                    {/* END_USER_CODE-USER_AFTER_lblCleaning*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRadioClean*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRadioClean*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxCleanGrossWt*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxCleanGrossWt*/}

                  <GroupBoxWidget
                    conf={state.grpbxCleanGrossWt}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_radioCleanGrossWt*/}

                    {/* END_USER_CODE-USER_BEFORE_radioCleanGrossWt*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioCleanGrossWt}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioCleanGrossWt*/}

                    {/* END_USER_CODE-USER_AFTER_radioCleanGrossWt*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCTicket*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCTicket*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCTicket}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCTicket*/}

                    {/* END_USER_CODE-USER_AFTER_lblCTicket*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCDryWt*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCDryWt*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCDryWt}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCDryWt*/}

                    {/* END_USER_CODE-USER_AFTER_lblCDryWt*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCGreenWt*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCGreenWt*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCGreenWt}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCGreenWt*/}

                    {/* END_USER_CODE-USER_AFTER_lblCGreenWt*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCSchedule*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCSchedule*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCSchedule}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCSchedule*/}

                    {/* END_USER_CODE-USER_AFTER_lblCSchedule*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCCost*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCCost*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCCost}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCCost*/}

                    {/* END_USER_CODE-USER_AFTER_lblCCost*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxCleanGrossWtRow0*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxCleanGrossWtRow0*/}

                    <GroupBoxWidget
                      conf={state.grpbxCleanGrossWtRow0}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtCTicket0*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCTicket0*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCTicket0}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCTicket0*/}

                      {/* END_USER_CODE-USER_AFTER_txtCTicket0*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCDryWt0*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCDryWt0*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCDryWt0}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCDryWt0*/}

                      {/* END_USER_CODE-USER_AFTER_txtCDryWt0*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCGreenWt0*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCGreenWt0*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCGreenWt0}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCGreenWt0*/}

                      {/* END_USER_CODE-USER_AFTER_txtCGreenWt0*/}
                      {/* START_USER_CODE-USER_BEFORE_ddCSchedule0*/}

                      {/* END_USER_CODE-USER_BEFORE_ddCSchedule0*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddCSchedule0}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddCSchedule0*/}

                      {/* END_USER_CODE-USER_AFTER_ddCSchedule0*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCost0*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCost0*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCost0}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCost0*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCost0*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxCleanGrossWtRow0*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxCleanGrossWtRow0*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxCleanGrossWtRow1*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxCleanGrossWtRow1*/}

                    <GroupBoxWidget
                      conf={state.grpbxCleanGrossWtRow1}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtCTicket1*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCTicket1*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCTicket1}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCTicket1*/}

                      {/* END_USER_CODE-USER_AFTER_txtCTicket1*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCDryWt1*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCDryWt1*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCDryWt1}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCDryWt1*/}

                      {/* END_USER_CODE-USER_AFTER_txtCDryWt1*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCGreenWt1*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCGreenWt1*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCGreenWt1}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCGreenWt1*/}

                      {/* END_USER_CODE-USER_AFTER_txtCGreenWt1*/}
                      {/* START_USER_CODE-USER_BEFORE_ddCSchedule1*/}

                      {/* END_USER_CODE-USER_BEFORE_ddCSchedule1*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddCSchedule1}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddCSchedule1*/}

                      {/* END_USER_CODE-USER_AFTER_ddCSchedule1*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCost1*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCost1*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCost1}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCost1*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCost1*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxCleanGrossWtRow1*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxCleanGrossWtRow1*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxCleanGrossWtRow2*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxCleanGrossWtRow2*/}

                    <GroupBoxWidget
                      conf={state.grpbxCleanGrossWtRow2}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtCTicket2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCTicket2*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCTicket2}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCTicket2*/}

                      {/* END_USER_CODE-USER_AFTER_txtCTicket2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCDryWt2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCDryWt2*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCDryWt2}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCDryWt2*/}

                      {/* END_USER_CODE-USER_AFTER_txtCDryWt2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCGreenWt2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCGreenWt2*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCGreenWt2}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCGreenWt2*/}

                      {/* END_USER_CODE-USER_AFTER_txtCGreenWt2*/}
                      {/* START_USER_CODE-USER_BEFORE_ddCSchedule2*/}

                      {/* END_USER_CODE-USER_BEFORE_ddCSchedule2*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddCSchedule2}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddCSchedule2*/}

                      {/* END_USER_CODE-USER_AFTER_ddCSchedule2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCCost2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCCost2*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCCost2}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCCost2*/}

                      {/* END_USER_CODE-USER_AFTER_txtCCost2*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxCleanGrossWtRow2*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxCleanGrossWtRow2*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxCleanGrossWt*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxCleanGrossWt*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxCleanNetWt*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxCleanNetWt*/}

                  <GroupBoxWidget
                    conf={state.grpbxCleanNetWt}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_radioCleanNetWt*/}

                    {/* END_USER_CODE-USER_BEFORE_radioCleanNetWt*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioCleanNetWt}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioCleanNetWt*/}

                    {/* END_USER_CODE-USER_AFTER_radioCleanNetWt*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCNetWeight*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCNetWeight*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCNetWeight}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCNetWeight*/}

                    {/* END_USER_CODE-USER_AFTER_lblCNetWeight*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCNWSchedule*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCNWSchedule*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCNWSchedule}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCNWSchedule*/}

                    {/* END_USER_CODE-USER_AFTER_lblCNWSchedule*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCNWCost*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCNWCost*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCNWCost}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCNWCost*/}

                    {/* END_USER_CODE-USER_AFTER_lblCNWCost*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxCleanNetWtRow*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxCleanNetWtRow*/}

                    <GroupBoxWidget
                      conf={state.grpbxCleanNetWtRow}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtCNetWeight*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCNetWeight*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCNetWeight}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCNetWeight*/}

                      {/* END_USER_CODE-USER_AFTER_txtCNetWeight*/}
                      {/* START_USER_CODE-USER_BEFORE_ddCNWSchedule*/}

                      {/* END_USER_CODE-USER_BEFORE_ddCNWSchedule*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddCNWSchedule}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddCNWSchedule*/}

                      {/* END_USER_CODE-USER_AFTER_ddCNWSchedule*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCNWCost*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCNWCost*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCNWCost}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCNWCost*/}

                      {/* END_USER_CODE-USER_AFTER_txtCNWCost*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxCleanNetWtRow*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxCleanNetWtRow*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxCleanNetWt*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxCleanNetWt*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxCleanOther*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxCleanOther*/}

                  <GroupBoxWidget
                    conf={state.grpbxCleanOther}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_radioCleanOther*/}

                    {/* END_USER_CODE-USER_BEFORE_radioCleanOther*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioCleanOther}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioCleanOther*/}

                    {/* END_USER_CODE-USER_AFTER_radioCleanOther*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCOWeight*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCOWeight*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCOWeight}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCOWeight*/}

                    {/* END_USER_CODE-USER_AFTER_lblCOWeight*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCOSchedule*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCOSchedule*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCOSchedule}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCOSchedule*/}

                    {/* END_USER_CODE-USER_AFTER_lblCOSchedule*/}
                    {/* START_USER_CODE-USER_BEFORE_lblCOCost*/}

                    {/* END_USER_CODE-USER_BEFORE_lblCOCost*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblCOCost}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblCOCost*/}

                    {/* END_USER_CODE-USER_AFTER_lblCOCost*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxCleanOtherRow*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxCleanOtherRow*/}

                    <GroupBoxWidget
                      conf={state.grpbxCleanOtherRow}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtCOWeight*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCOWeight*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCOWeight}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCOWeight*/}

                      {/* END_USER_CODE-USER_AFTER_txtCOWeight*/}
                      {/* START_USER_CODE-USER_BEFORE_ddCOSchedule*/}

                      {/* END_USER_CODE-USER_BEFORE_ddCOSchedule*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddCOSchedule}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddCOSchedule*/}

                      {/* END_USER_CODE-USER_AFTER_ddCOSchedule*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCOCost*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCOCost*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCOCost}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCOCost*/}

                      {/* END_USER_CODE-USER_AFTER_txtCOCost*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxCleanOtherRow*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxCleanOtherRow*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxCleanOther*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxCleanOther*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxClean*/}

                {/* END_USER_CODE-USER_AFTER_grpbxClean*/}
                {/* START_USER_CODE-USER_BEFORE_txtCostToCure*/}

                {/* END_USER_CODE-USER_BEFORE_txtCostToCure*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCostToCure}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCostToCure*/}

                {/* END_USER_CODE-USER_AFTER_txtCostToCure*/}
                {/* START_USER_CODE-USER_BEFORE_txtCostToClean*/}

                {/* END_USER_CODE-USER_BEFORE_txtCostToClean*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCostToClean}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCostToClean*/}

                {/* END_USER_CODE-USER_AFTER_txtCostToClean*/}
                {/* START_USER_CODE-USER_BEFORE_txtCleanCureTicketNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtCleanCureTicketNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCleanCureTicketNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCleanCureTicketNum*/}

                {/* END_USER_CODE-USER_AFTER_txtCleanCureTicketNum*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCleaningCuringCosts*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCleaningCuringCosts*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOkuic0*/}

                {/* END_USER_CODE-USER_BEFORE_btnOkuic0*/}

                <ButtonWidget
                  conf={state.btnOkuic0}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOkuic0*/}

                {/* END_USER_CODE-USER_AFTER_btnOkuic0*/}
                {/* START_USER_CODE-USER_BEFORE_btnCanceluic0*/}

                {/* END_USER_CODE-USER_BEFORE_btnCanceluic0*/}

                <ButtonWidget
                  conf={state.btnCanceluic0}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCanceluic0*/}

                {/* END_USER_CODE-USER_AFTER_btnCanceluic0*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_CleaningCuringCosts*/}

              {/* END_USER_CODE-USER_AFTER_CleaningCuringCosts*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_CleaningCuringCosts);
