/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  setData,
  getData,
  setValue,
  getValue,
  enable,
  disable,
  hide,
  show,
} from "../../shared/WindowImports";

import "./SecurityUserLocationSelect.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_SecurityUserLocationSelect(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SecurityUserLocationSelect",
    windowName: "SecurityUserLocationSelect",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.SecurityUserLocationSelect",
    // START_USER_CODE-USER_SecurityUserLocationSelect_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Security User Location Select",
      scrCode: "PN0060F",
    },
    // END_USER_CODE-USER_SecurityUserLocationSelect_PROPERTIES
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    ddAccessLevel: {
      name: "ddAccessLevel",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityUserLocationSelect",
      Label: "Access Level:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAccessLevel_PROPERTIES

      // END_USER_CODE-USER_ddAccessLevel_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityUserLocationSelect",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtKeyInformation: {
      name: "txtKeyInformation",
      type: "TextBoxWidget",
      parent: "grpbxSecurityUserLocationSelect",
      Label: "Key Information:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKeyInformation_PROPERTIES

      // END_USER_CODE-USER_txtKeyInformation_PROPERTIES
    },
    grpbxSecurityUserLocationSelect: {
      name: "grpbxSecurityUserLocationSelect",
      type: "GroupBoxWidget",
      parent: "SecurityUserLocationSelect",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSecurityUserLocationSelect_PROPERTIES

      // END_USER_CODE-USER_grpbxSecurityUserLocationSelect_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "SecurityUserLocationSelect",
      Height: "",
      Width: "",
      clonedExtId: "44193",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  
    formLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);


  // START_USER_CODE-USER_METHODS

  //Formload
  const formLoad = async () => {

    let ParentData = getData(thisObj, "dataForSecurityUserLocationSelect");
    document.getElementById("btnUpdate").innerText = ParentData.cmdOkCaption;
    if(ParentData.cmdOkCaption == "Update"){
      disable(thisObj, "ddBuyingPoint");
    }
    setValue(thisObj, "txtKeyInformation", ParentData.lblKeyInformationCaption);
    disable(thisObj, "txtKeyInformation")
    setValue(thisObj,"lblAddedByValue",ParentData.lblAddedByCaption)
    setValue(thisObj,"lblChangedByValue",ParentData.lblChangedByCaption);
    bFillBuyingPoint(ParentData.lstBuyingPointID);// Method for binding Buying Points
    bFillAccessLevelList(ParentData.lstAccessLevelId);//Method for binding Access Levels
 

  }

  

   function ConvertToPascalCase(word){
    return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  }

  //Dropdown for buying point
  const bFillBuyingPoint = async(buyptid) => {
    try{
    let js = []
    await ContractManagementService.RetrieveBuyingPointControlDetails('PN0060', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + ConvertToPascalCase(data[i].buy_pt_name).trim(), buy_pt_name: ConvertToPascalCase(data[i].buy_pt_name).trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })
      if (buyptid == "" || buyptid == undefined || buyptid == null)
        buyptid = js.at(0).key;
      else
        disable(thisObj, "ddBuyingPoint", true);
      setValue(thisObj, 'ddBuyingPoint', buyptid);
    
    })
  
 
  
  }
  catch (err) {
    if (err instanceof EvalError) {
     showMessage(thisObj, err.message)
   }
   else {
     showMessage(
       thisObj, "Error in loading dropdown for Buying point type"
     )
   }
 }}

 //Dropdown for Access Level
  const bFillAccessLevelList = async (accesslevelid) => {
    try{
    let js = [];
    js.push({ key: 'I', description: 'Inquiry' });
    js.push({ key: 'U', description: 'Update' });
    js.push({ key: 'D', description: 'Delete' });
    js.push({ key: 'X', description: 'Revoke' });
    thisObj.setState(current => {
      return {
        ...current,
        ddAccessLevel: {
          ...state["ddAccessLevel"],
          valueList: js
        },
      }
    });
    if (accesslevelid == "" || accesslevelid == undefined || accesslevelid == null)
      accesslevelid = js.at(0).key;
    setValue(thisObj, 'ddAccessLevel', accesslevelid);
  }
  catch (err) {
  if (err instanceof EvalError) {
   showMessage(thisObj, err.message)
 }
 else {
   showMessage(
     thisObj, "Error in loading dropdown for access dropdown type"
   )
 }
}
}

//Add and Update button functionality
  const onbtnUpdateClick = async () => {
    try{
    let ParentData = getData(thisObj, "dataForSecurityUserLocationSelect");
    let response;
    let data;
    let compId = sessionStorage.getItem('compId')
    let userid = (sessionStorage.getItem('userid') || '')
    let KeyInformationTag = ParentData.lblKeyInformationTag
    let bypt = getValue(thisObj, "ddBuyingPoint")
    let condition

    if (ParentData.cmdOkCaption == "Add") {

      data = {
        "comp_id": compId,
        "user_id": KeyInformationTag,
        "buy_pt_id": bypt,
        "access_level": getValue(thisObj, "ddAccessLevel"),
        "uid": userid

      }
      response = await SystemMaintenanceSpecialFunctionsService.CreateUserLocationTransfer(data);
      condition = "Add"
    }
    else {
      data = {
        "access_level": getValue(thisObj, "ddAccessLevel"),
        "uid": userid
      }
      response = await SystemMaintenanceSpecialFunctionsService.UpdateUserLocationTransfer(KeyInformationTag, bypt, data);
      condition = "Update"
    }
    if (response.status == '200') {
      let buyinglist = thisObj.state['ddBuyingPoint'].valueList;
      let buyingDescription = '';
      let buyingId = thisObj.values['ddBuyingPoint'];
      if (buyingId !== undefined && buyingId !== null) {
        buyingDescription = buyinglist.find(elem => elem.key == buyingId).buy_pt_name;
      }
      let obj = {
        "lstLocationCaption": getValue(thisObj, "ddBuyingPoint") + " (" + getValue(thisObj, "ddAccessLevel") + ") - " + buyingDescription,
        "lstLocationIds": getValue(thisObj, "ddBuyingPoint"),
        "condition": condition
      }
      setData(thisObj, "dataForSecurityUser", obj)
      setData(thisObj, "closingOfSecurityUserLocationSelect", true)
      document.getElementById("SystemMaintenanceSpecialFunctions_SecurityUserLocationSelectPopUp").childNodes[0].click();
    }
    else {
      showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\n"  + response.message);
    }
  }

  catch (err) {
    if (err instanceof EvalError) {
     showMessage(thisObj, err.message)
   }
   else {
     showMessage(
       thisObj, "Error in Add button for peanut type"
     )
   }
 }}
  thisObj.onbtnUpdateClick = onbtnUpdateClick

  //Cancel button
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_SecurityUserLocationSelectPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnCancel event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SecurityUserLocationSelect*/}

              {/* END_USER_CODE-USER_BEFORE_SecurityUserLocationSelect*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSecurityUserLocationSelect*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSecurityUserLocationSelect*/}

              <GroupBoxWidget
                conf={state.grpbxSecurityUserLocationSelect}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtKeyInformation*/}

                {/* END_USER_CODE-USER_BEFORE_txtKeyInformation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKeyInformation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKeyInformation*/}

                {/* END_USER_CODE-USER_AFTER_txtKeyInformation*/}
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_ddAccessLevel*/}

                {/* END_USER_CODE-USER_BEFORE_ddAccessLevel*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAccessLevel}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAccessLevel*/}

                {/* END_USER_CODE-USER_AFTER_ddAccessLevel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSecurityUserLocationSelect*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSecurityUserLocationSelect*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_SecurityUserLocationSelect*/}

              {/* END_USER_CODE-USER_AFTER_SecurityUserLocationSelect*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_SecurityUserLocationSelect
);
