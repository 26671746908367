/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_WarehouseBinProfile from "./WarehouseBinProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("WarehouseBinProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_WarehouseBinProfile />);
    });
  });
  afterEach(cleanup);
  test("is WarehouseBinProfile Loads Successfully", () => {
    expect(screen.getByText("WarehouseBinProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for WarehouseBinProfile", () => {
    // START_USER_CODE-USER_WarehouseBinProfile_Custom_Test_Case
    // END_USER_CODE-USER_WarehouseBinProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_WarehouseBinProfile />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxWarehouseBinProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxWarehouseBinProfile = screen.getByTestId(
      "grpbxWarehouseBinProfile"
    );
    expect(grpbxWarehouseBinProfile.tagName).toBe("BUTTON");
    expect(grpbxWarehouseBinProfile.type).toBe("button");
    expect(grpbxWarehouseBinProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWarehouseBinProfile", () => {
    // START_USER_CODE-USER_grpbxWarehouseBinProfile_TEST
    // END_USER_CODE-USER_grpbxWarehouseBinProfile_TEST
  });
  test("grpbxWarehouseBinProfile2(GroupBox Widget) Test Cases", async () => {
    const grpbxWarehouseBinProfile2 = screen.getByTestId(
      "grpbxWarehouseBinProfile2"
    );
    expect(grpbxWarehouseBinProfile2.tagName).toBe("BUTTON");
    expect(grpbxWarehouseBinProfile2.type).toBe("button");
    expect(grpbxWarehouseBinProfile2.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWarehouseBinProfile2", () => {
    // START_USER_CODE-USER_grpbxWarehouseBinProfile2_TEST
    // END_USER_CODE-USER_grpbxWarehouseBinProfile2_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblBuyingPoint(Label Widget) Test Cases", async () => {
    const lblBuyingPoint = screen.getByTestId("lblBuyingPoint");
    expect(lblBuyingPoint.tagName).toBe("LABEL");
    expect(lblBuyingPoint.classList).toContain("form-label");
    expect(lblBuyingPoint.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_lblBuyingPoint")
    );
  });
  test("Custom Test Cases for lblBuyingPoint", () => {
    // START_USER_CODE-USER_lblBuyingPoint_TEST
    // END_USER_CODE-USER_lblBuyingPoint_TEST
  });
  test("lblBuyingPointValue(Label Widget) Test Cases", async () => {
    const lblBuyingPointValue = screen.getByTestId("lblBuyingPointValue");
    expect(lblBuyingPointValue.tagName).toBe("LABEL");
    expect(lblBuyingPointValue.classList).toContain("form-label");
    expect(lblBuyingPointValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_lblBuyingPointValue")
    );
  });
  test("Custom Test Cases for lblBuyingPointValue", () => {
    // START_USER_CODE-USER_lblBuyingPointValue_TEST
    // END_USER_CODE-USER_lblBuyingPointValue_TEST
  });
  test("lblOrganic(Label Widget) Test Cases", async () => {
    const lblOrganic = screen.getByTestId("lblOrganic");
    expect(lblOrganic.tagName).toBe("LABEL");
    expect(lblOrganic.classList).toContain("form-label");
    expect(lblOrganic.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_lblOrganic")
    );
  });
  test("Custom Test Cases for lblOrganic", () => {
    // START_USER_CODE-USER_lblOrganic_TEST
    // END_USER_CODE-USER_lblOrganic_TEST
  });
  test("lblTransfersAllowed(Label Widget) Test Cases", async () => {
    const lblTransfersAllowed = screen.getByTestId("lblTransfersAllowed");
    expect(lblTransfersAllowed.tagName).toBe("LABEL");
    expect(lblTransfersAllowed.classList).toContain("form-label");
    expect(lblTransfersAllowed.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_lblTransfersAllowed")
    );
  });
  test("Custom Test Cases for lblTransfersAllowed", () => {
    // START_USER_CODE-USER_lblTransfersAllowed_TEST
    // END_USER_CODE-USER_lblTransfersAllowed_TEST
  });
  test("txtBin(Textbox Widget) Test Cases", async () => {
    const txtBin = screen.getByTestId("txtBin");
    expect(txtBin.tagName).toBe("INPUT");
    expect(txtBin.type).toBe("text");
    expect(txtBin.classList).toContain("textboxWidgetClass");
    expect(txtBin.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_txtBin")
    );
    await act(async () => {
      userEvent.type(txtBin, "1");
    });
  });
  test("Custom Test Cases for txtBin", () => {
    // START_USER_CODE-USER_txtBin_TEST
    // END_USER_CODE-USER_txtBin_TEST
  });
  test("txtChangedBy(Label Widget) Test Cases", async () => {
    const txtChangedBy = screen.getByTestId("txtChangedBy");
    expect(txtChangedBy.tagName).toBe("LABEL");
    expect(txtChangedBy.classList).toContain("form-label");
    expect(txtChangedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_txtChangedBy")
    );
  });
  test("Custom Test Cases for txtChangedBy", () => {
    // START_USER_CODE-USER_txtChangedBy_TEST
    // END_USER_CODE-USER_txtChangedBy_TEST
  });
  test("txtChangedByValue(Label Widget) Test Cases", async () => {
    const txtChangedByValue = screen.getByTestId("txtChangedByValue");
    expect(txtChangedByValue.tagName).toBe("LABEL");
    expect(txtChangedByValue.classList).toContain("form-label");
    expect(txtChangedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_txtChangedByValue")
    );
  });
  test("Custom Test Cases for txtChangedByValue", () => {
    // START_USER_CODE-USER_txtChangedByValue_TEST
    // END_USER_CODE-USER_txtChangedByValue_TEST
  });
  test("txtGovCapacity(Textbox Widget) Test Cases", async () => {
    const txtGovCapacity = screen.getByTestId("txtGovCapacity");
    expect(txtGovCapacity.tagName).toBe("INPUT");
    expect(txtGovCapacity.type).toBe("text");
    expect(txtGovCapacity.classList).toContain("textboxWidgetClass");
    expect(txtGovCapacity.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_txtGovCapacity")
    );
    await act(async () => {
      userEvent.type(txtGovCapacity, "1");
    });
  });
  test("Custom Test Cases for txtGovCapacity", () => {
    // START_USER_CODE-USER_txtGovCapacity_TEST
    // END_USER_CODE-USER_txtGovCapacity_TEST
  });
  test("txtStartupTons(Textbox Widget) Test Cases", async () => {
    const txtStartupTons = screen.getByTestId("txtStartupTons");
    expect(txtStartupTons.tagName).toBe("INPUT");
    expect(txtStartupTons.type).toBe("text");
    expect(txtStartupTons.classList).toContain("textboxWidgetClass");
    expect(txtStartupTons.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_txtStartupTons")
    );
    await act(async () => {
      userEvent.type(txtStartupTons, "1");
    });
  });
  test("Custom Test Cases for txtStartupTons", () => {
    // START_USER_CODE-USER_txtStartupTons_TEST
    // END_USER_CODE-USER_txtStartupTons_TEST
  });
  test("txtWarehouse(Textbox Widget) Test Cases", async () => {
    const txtWarehouse = screen.getByTestId("txtWarehouse");
    expect(txtWarehouse.tagName).toBe("INPUT");
    expect(txtWarehouse.type).toBe("text");
    expect(txtWarehouse.classList).toContain("textboxWidgetClass");
    expect(txtWarehouse.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_txtWarehouse")
    );
    await act(async () => {
      userEvent.type(txtWarehouse, "1");
    });
  });
  test("Custom Test Cases for txtWarehouse", () => {
    // START_USER_CODE-USER_txtWarehouse_TEST
    // END_USER_CODE-USER_txtWarehouse_TEST
  });
  test("txtWarehouseBinDesc(Textbox Widget) Test Cases", async () => {
    const txtWarehouseBinDesc = screen.getByTestId("txtWarehouseBinDesc");
    expect(txtWarehouseBinDesc.tagName).toBe("INPUT");
    expect(txtWarehouseBinDesc.type).toBe("text");
    expect(txtWarehouseBinDesc.classList).toContain("textboxWidgetClass");
    expect(txtWarehouseBinDesc.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:WarehouseBinProfile_txtWarehouseBinDesc")
    );
    await act(async () => {
      userEvent.type(txtWarehouseBinDesc, "1");
    });
  });
  test("Custom Test Cases for txtWarehouseBinDesc", () => {
    // START_USER_CODE-USER_txtWarehouseBinDesc_TEST
    // END_USER_CODE-USER_txtWarehouseBinDesc_TEST
  });
});
