/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  enable,
  disable,
  getData,
  getValue,
  setData,
  getSelectedRowNumber,
  goTo
} from "../../shared/WindowImports";

import "./ExceptionPremiumSearch.scss";

// START_USER_CODE-USER_IMPORTS
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { ContractManagementService } from "../Service/ContractManagementService";
import { returnNumericValues } from './../../Common/Constants';
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ExceptionPremiumSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(false);
  // START_USER_CODE-USER_PROPERTIES
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "Exception Premium Search",
    windowName: "ExceptionPremiumSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ExceptionPremiumSearch",
    // START_USER_CODE-USER_ExceptionPremiumSearch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Exception Premiums Search",
      scrCode: "PN1190A"
    },
    // END_USER_CODE-USER_ExceptionPremiumSearch_PROPERTIES
    btnContrctPyReq: {
      name: "btnContrctPyReq",
      type: "ButtonWidget",
      parent: "grpbxExcPremSrch",
      Label: "Contract Pay Request",
      CharWidth: "41",
      // START_USER_CODE-USER_btnContrctPyReq_PROPERTIES

      // END_USER_CODE-USER_btnContrctPyReq_PROPERTIES
    },
    txtVndor: {
      name: "txtVndor",
      type: "TextBoxWidget",
      parent: "grpbxExcPremSrch",
      Label: "",
      HasLabel:false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrcnt_PROPERTIES
      ReadOnly: true,
      Enabled:false,
      // END_USER_CODE-USER_txtPrcnt_PROPERTIES
    },
    btnCrtExcPrem: {
      name: "btnCrtExcPrem",
      type: "ButtonWidget",
      parent: "grpbxExcPremSrch",
      Label: "Create Exception Premium",
      CharWidth: "48",
      // START_USER_CODE-USER_btnCrtExcPrem_PROPERTIES

      // END_USER_CODE-USER_btnCrtExcPrem_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxExprtPrnt",
      Label: "Exit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnExport: {
      name: "btnExport",
      type: "ButtonWidget",
      parent: "grpbxExprtPrnt",
      Label: "Export to Excel",
      CharWidth: "32",
      // START_USER_CODE-USER_btnExport_PROPERTIES

      // END_USER_CODE-USER_btnExport_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "grpbxExcPremSrch",
      Label: "Open",
      CharWidth: "11",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnPopupVendr: {
      name: "btnPopupVendr",
      type: "ButtonWidget",
      parent: "grpbxExcPremSrch",
      Label: "...",
      CharWidth: "9",
      // START_USER_CODE-USER_btnPopupVendr_PROPERTIES

      // END_USER_CODE-USER_btnPopupVendr_PROPERTIES
    },
    btnPrntSrch: {
      name: "btnPrntSrch",
      type: "ButtonWidget",
      parent: "grpbxExprtPrnt",
      Label: "Print Search",
      CharWidth: "26",
      // START_USER_CODE-USER_btnPrntSrch_PROPERTIES

      // END_USER_CODE-USER_btnPrntSrch_PROPERTIES
    },
    btnSrch: {
      name: "btnSrch",
      type: "ButtonWidget",
      parent: "grpbxExcPremSrch",
      Label: "Search",
      ColSpan: "3",
      CharWidth: "15",
      // START_USER_CODE-USER_btnSrch_PROPERTIES

      // END_USER_CODE-USER_btnSrch_PROPERTIES
    },
    ddGen: {
      name: "ddGen",
      type: "DropDownFieldWidget",
      parent: "grpbxExcPremSrch",
      Label: "Generation:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGen_PROPERTIES

      // END_USER_CODE-USER_ddGen_PROPERTIES
    },
    ddLoc: {
      name: "ddLoc",
      type: "DropDownFieldWidget",
      parent: "grpbxExcPremSrch",
      Label: "Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLoc_PROPERTIES

      // END_USER_CODE-USER_ddLoc_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxExcPremSrch",
      Label: "Oleic:",
      ColSpan: "6",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPnutTyp: {
      name: "ddPnutTyp",
      type: "DropDownFieldWidget",
      parent: "grpbxExcPremSrch",
      Label: "Peanut Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPnutTyp_PROPERTIES

      // END_USER_CODE-USER_ddPnutTyp_PROPERTIES
    },
    ddPnutVrty: {
      name: "ddPnutVrty",
      type: "DropDownFieldWidget",
      parent: "grpbxExcPremSrch",
      Label: "Peanut Variety:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPnutVrty_PROPERTIES

      // END_USER_CODE-USER_ddPnutVrty_PROPERTIES
    },
    ddPrmCode: {
      name: "ddPrmCode",
      type: "DropDownFieldWidget",
      parent: "grpbxExcPremSrch",
      Label: "Premium Code:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPrmCode_PROPERTIES

      // END_USER_CODE-USER_ddPrmCode_PROPERTIES
    },
    ddSeed: {
      name: "ddSeed",
      type: "DropDownFieldWidget",
      parent: "grpbxExcPremSrch",
      Label: "Seed:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeed_PROPERTIES

      // END_USER_CODE-USER_ddSeed_PROPERTIES
    },
    ddSegTyp: {
      name: "ddSegTyp",
      type: "DropDownFieldWidget",
      parent: "grpbxExcPremSrch",
      Label: "Seg Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegTyp_PROPERTIES

      // END_USER_CODE-USER_ddSegTyp_PROPERTIES
    },
    ddStatus: {
      name: "ddStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxExcPremSrch",
      Label: "Status:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddStatus_PROPERTIES

      // END_USER_CODE-USER_ddStatus_PROPERTIES
    },
    ddbutton: {
      name: "ddbutton",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "ddbutton:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    txt1007: {
      name: "txt1007",
      type: "TextBoxWidget",
      parent: "grpbxExcPremSrch",
      Label: "1007 #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007_PROPERTIES     
      // formattingReqd: true,
      // END_USER_CODE-USER_txt1007_PROPERTIES
    },
    txtContrct: {
      name: "txtContrct",
      type: "TextBoxWidget",
      parent: "grpbxExcPremSrch",
      Label: "Contract #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContrct_PROPERTIES
      // formattingReqd: true,
      // END_USER_CODE-USER_txtContrct_PROPERTIES
    },
    txtFrm: {
      name: "txtFrm",
      type: "TextBoxWidget",
      parent: "grpbxExcPremSrch",
      Label: "Farm #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFrm_PROPERTIES

      // END_USER_CODE-USER_txtFrm_PROPERTIES
    },
    txtFrmSfx: {
      name: "txtFrmSfx",
      type: "TextBoxWidget",
      parent: "grpbxExcPremSrch",
      Label: "Farm Suffix #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFrmSfx_PROPERTIES

      // END_USER_CODE-USER_txtFrmSfx_PROPERTIES
    },
    txtPayReq: {
      name: "txtPayReq",
      type: "TextBoxWidget",
      parent: "grpbxExcPremSrch",
      Label: "Pay Request #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayReq_PROPERTIES
      // formattingReqd: true,
      // END_USER_CODE-USER_txtPayReq_PROPERTIES
    },
    txtsc95: {
      name: "txtsc95",
      type: "TextBoxWidget",
      parent: "grpbxExcPremSrch",
      Label: "SC95#:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtsc95_PROPERTIES

      // END_USER_CODE-USER_txtsc95_PROPERTIES
    },
    txtTotlAmt: {
      name: "txtTotlAmt",
      type: "TextBoxWidget",
      parent: "grpbxExprtPrnt",
      Label: "Total Amount:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotlAmt_PROPERTIES
      ReadOnly: true,
      Enabled:false,
      // END_USER_CODE-USER_txtTotlAmt_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxExcPremSrch",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },  
    txtApproveDate: {
      name: "txtApproveDate",
      type: "TextBoxWidget",
      colName: "txtColApproveDate",
      parent: "gridExceptionPremiumSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtApproveDate_PROPERTIES

      // END_USER_CODE-USER_txtApproveDate_PROPERTIES
    },
    txtColApproveDate: {
      name: "txtColApproveDate",
      type: "GridColumnWidget",
      parent: "gridExceptionPremiumSearch",
      Label: "Approve Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColApproveDate_PROPERTIES

      // END_USER_CODE-USER_txtColApproveDate_PROPERTIES
    },
    txtColLocation: {
      name: "txtColLocation",
      type: "GridColumnWidget",
      parent: "gridExceptionPremiumSearch",
      Label: "Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColLocation_PROPERTIES

      // END_USER_CODE-USER_txtColLocation_PROPERTIES
    },
    txtColPayRequest: {
      name: "txtColPayRequest",
      type: "GridColumnWidget",
      parent: "gridExceptionPremiumSearch",
      Label: "Pay Request",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPayRequest_PROPERTIES

      // END_USER_CODE-USER_txtColPayRequest_PROPERTIES
    },
    txtColPremiumCode: {
      name: "txtColPremiumCode",
      type: "GridColumnWidget",
      parent: "gridExceptionPremiumSearch",
      Label: "Premium Code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPremiumCode_PROPERTIES

      // END_USER_CODE-USER_txtColPremiumCode_PROPERTIES
    },
    txtColRequestDate: {
      name: "txtColRequestDate",
      type: "GridColumnWidget",
      parent: "gridExceptionPremiumSearch",
      Label: "Request Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColRequestDate_PROPERTIES

      // END_USER_CODE-USER_txtColRequestDate_PROPERTIES
    },
    txtColStatus: {
      name: "txtColStatus",
      type: "GridColumnWidget",
      parent: "gridExceptionPremiumSearch",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColStatus_PROPERTIES

      // END_USER_CODE-USER_txtColStatus_PROPERTIES
    },
    txtColTotalAmount: {
      name: "txtColTotalAmount",
      type: "GridColumnWidget",
      parent: "gridExceptionPremiumSearch",
      Label: "Total Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColTotalAmount_PROPERTIES

      // END_USER_CODE-USER_txtColTotalAmount_PROPERTIES
    },
    txtColVendor: {
      name: "txtColVendor",
      type: "GridColumnWidget",
      parent: "gridExceptionPremiumSearch",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColVendor_PROPERTIES

      // END_USER_CODE-USER_txtColVendor_PROPERTIES
    },
    txtLocationC: {
      name: "txtLocationC",
      type: "TextBoxWidget",
      colName: "txtColLocation",
      parent: "gridExceptionPremiumSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocationC_PROPERTIES

      // END_USER_CODE-USER_txtLocationC_PROPERTIES
    },
    txtPayRequestC: {
      name: "txtPayRequestC",
      type: "TextBoxWidget",
      colName: "txtColPayRequest",
      parent: "gridExceptionPremiumSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayRequestC_PROPERTIES

      // END_USER_CODE-USER_txtPayRequestC_PROPERTIES
    },
    txtPremiumCodeC: {
      name: "txtPremiumCodeC",
      type: "TextBoxWidget",
      colName: "txtColPremiumCode",
      parent: "gridExceptionPremiumSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremiumCodeC_PROPERTIES

      // END_USER_CODE-USER_txtPremiumCodeC_PROPERTIES
    },
    txtRequestDate: {
      name: "txtRequestDate",
      type: "TextBoxWidget",
      colName: "txtColRequestDate",
      parent: "gridExceptionPremiumSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRequestDate_PROPERTIES

      // END_USER_CODE-USER_txtRequestDate_PROPERTIES
    },
    txtStatusC: {
      name: "txtStatusC",
      type: "TextBoxWidget",
      colName: "txtColStatus",
      parent: "gridExceptionPremiumSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatusC_PROPERTIES

      // END_USER_CODE-USER_txtStatusC_PROPERTIES
    },
    txtTotalAmount: {
      name: "txtTotalAmount",
      type: "TextBoxWidget",
      colName: "txtColTotalAmount",
      parent: "gridExceptionPremiumSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalAmount_PROPERTIES

      // END_USER_CODE-USER_txtTotalAmount_PROPERTIES
    },
    txtVendorC: {
      name: "txtVendorC",
      type: "TextBoxWidget",
      colName: "txtColVendor",
      parent: "gridExceptionPremiumSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorC_PROPERTIES

      // END_USER_CODE-USER_txtVendorC_PROPERTIES
    },
    gridExceptionPremiumSearch: {
      name: "gridExceptionPremiumSearch",
      type: "GridWidget",
      parent: "ExceptionPremiumSearch",
      gridCellsOrder:
        "txtPayRequestC,txtStatusC,txtLocationC,txtVendorC,txtPremiumCodeC,txtTotalAmount,txtRequestDate,txtApproveDate",
      Pagination: false,
      HasLabel: false,
      Cols: "5",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridExceptionPremiumSearch_PROPERTIES
      focusIn: true,
      // END_USER_CODE-USER_gridExceptionPremiumSearch_PROPERTIES
    },
    grpbxExcPremSrch: {
      name: "grpbxExcPremSrch",
      type: "GroupBoxWidget",
      parent: "ExceptionPremiumSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxExcPremSrch_PROPERTIES

      // END_USER_CODE-USER_grpbxExcPremSrch_PROPERTIES
    },
    grpbxExprtPrnt: {
      name: "grpbxExprtPrnt",
      type: "GroupBoxWidget",
      parent: "ExceptionPremiumSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxExprtPrnt_PROPERTIES

      // END_USER_CODE-USER_grpbxExprtPrnt_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},

        "ContractManagement#ExceptPremRequestProfile": {},

        "ContractManagement#VendorLookup": {},
        "ContractManagement#ExceptPremRequestSearch": {},
        "ContractManagement#ExceptPremCreate":{},
      },
      REVERSE: {
        "ContractManagement#MainMenu": {},

        "ContractManagement#ExceptPremRequestProfile": {},

        "ContractManagement#VendorLookup": {},
        "ContractManagement#ExceptPremRequestSearch": {},
        "ContractManagement#ExceptPremCreate":{},
      },
    },
  };
  let _buttonServices = { MainMenu: {}, VendorLookup: {}, ExceptPremRequestProfile: {}, ExceptPremRequestSearch: {} };
  let _buttonNavigation = {
    btnExit: {
      DEFAULT: [
        "ContractManagement#MainMenu#DEFAULT#false#Click",
      ],
    },
    
    // btnOpen: {
    //   DEFAULT: [
    //     "ContractManagement#ExceptPremRequestProfile#DEFAULT#true#Click",
    //   ],
    // },
    btnCrtExcPrem : {
      DEFAULT: [
        "ContractManagement#ExceptPremCreate#DEFAULT#true#Click",
      ],
    },
    btnContrctPyReq: {
      DEFAULT: [
        "ContractManagement#ExceptPremRequestSearch#DEFAULT#true#Click",
      ],
    },
    btnPopupVendr: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  // Grid Changing After calling From Except prem Req profile screen status
  useEffect(() => {
    let flag = getData(thisObj, "flagToggle");
    if (flag !== 'initial'&& flag!==null ) {
      setTimeout(() => {
        gridLoad()
      }, 2000)
    } 
    setData(thisObj,"flagToggle","initial")
  }, [getData(thisObj, "flagToggle")]);

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    setVendorNameBasedOnVendorNumber()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj,'vendorDetails')]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    EnableDisableControl();
    setValue(thisObj, "ddStatus", ''),
    setValue(thisObj, "ddSeed", ''),
    setValue(thisObj, "ddGen", ''),
    setValue(thisObj, "ddSegTyp", ''),
    setValue(thisObj, "ddOleic", ''),
    setValue(thisObj, "ddLoc", ''),
    setValue(thisObj, "ddPrmCode", '')
    setValue(thisObj, "ddPnutTyp", '')
    setValue(thisObj, "ddPnutVrty", '')
    setValue(thisObj,"txtVndr",'')
    setValue(thisObj,"txtVndor",'')
    FormLoad();
    
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  //only numeric value from 0 to 9 allowed functionality(Ascii Functionality)
  
  const ontxtPayReqChange = () => {
    setValue(thisObj, "txtPayReq", returnNumericValues(txtPayReq.value))
  };
  thisObj.ontxtPayReqChange = ontxtPayReqChange;

  const ontxt1007Change = () => {
    setValue(thisObj, "txt1007", returnNumericValues(txt1007.value))
  };
  thisObj.ontxt1007Change = ontxt1007Change;

  const ontxtContrctChange = () => {
    setValue(thisObj, "txtContrct", returnNumericValues(txtContrct.value))
  };
  thisObj.ontxtContrctChange = ontxtContrctChange;

  // Formating for the FarmBlur and Farm suffix Blur TextBox
  const ontxtFrmBlur = () =>{
    let FarmNumber = getValue(thisObj,'txtFrm')
    if(FarmNumber != null && FarmNumber != undefined){
    let FarmNumLength = FarmNumber.length
    if(FarmNumLength < 7 && FarmNumLength >0){
      let NewFrmNum = '0'.repeat((7-FarmNumLength)) + FarmNumber
      setValue(thisObj,'txtFrm',NewFrmNum )
    }
  }
  }
  thisObj.ontxtFrmBlur = ontxtFrmBlur

  const ontxtFrmSfxBlur = () =>{
    let FarmNumber = getValue(thisObj,'txtFrmSfx')
    if(FarmNumber != null && FarmNumber != undefined){
    let FarmNumLength = FarmNumber.length
    if(FarmNumLength < 3 && FarmNumLength >0){
      let NewFrmNum = '0'.repeat((3-FarmNumLength)) + FarmNumber
      setValue(thisObj,'txtFrmSfx',NewFrmNum )
    }
  }
  }
  thisObj.ontxtFrmSfxBlur = ontxtFrmSfxBlur

// Double click Event in Grid
  const ongridExceptionPremiumSearchDblclick = () => {
    try {
      let selectedRow = getSelectedRowNumber(thisObj, "gridExceptionPremiumSearch")
      if (selectedRow !== undefined) {
        let rowselectedvalue = getValue(thisObj, "gridExceptionPremiumSearch"); 
        let row = getSelectedRowNumber(thisObj,"gridExceptionPremiumSearch")
        setData(thisObj, 'frmExceptPremRequestProfileData', rowselectedvalue[row])
        goTo(thisObj, "ContractManagement#ExceptPremRequestProfile#DEFAULT#true#Click");
      }
      else {
        showMessage(thisObj, "Aleast One should be selected to Open Exception Premium Request Profile");
        return false
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred while getting selected row object on Grid Row Double click event"
        );
      }
    }
    return true
  }
  thisObj.ongridExceptionPremiumSearchDblclick = ongridExceptionPremiumSearchDblclick

  //Enable Disable Controls
  const EnableDisableControl = () => {
    disable(thisObj, "btnOpen");
    disable(thisObj, "btnExport");
    disable(thisObj, "btnPrntSrch");
  }
  //Peanut Type dropdown change event
  const onPeanutTypeChange = () => {
    try {
      let PeanutTypelist =thisObj.state['ddPnutTyp'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPnutTyp'];
      let pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      let BuyingPointList= thisObj.state['ddLoc'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddLoc'];
      let buyingPointId = null
        if (SelectedBuyingPointValue !== null && SelectedBuyingPointValue !== undefined) {
          buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
        }
      //Bind Peanut Variety based on Buying Point and Peanut Type
      bFillPeanutVarietyList(buyingPointId,pnut_type_id)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Peanut Type dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddPnutTypChange = onPeanutTypeChange;

  const onBuyingPointChange = () => {
    try{
    let PeanutTypelist = thisObj.state['ddPnutTyp'].valueList;
    let SelectedPeanutTypeValue = thisObj.values['ddPnutTyp'];
    let pnut_type_id = null
      if (SelectedPeanutTypeValue !== "" && SelectedPeanutTypeValue !== undefined) {
        pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      }
    let BuyingPointList = thisObj.state['ddLoc'].valueList;
    let SelectedBuyingPointValue = thisObj.values['ddLoc'];
    let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
    }
    //Bind Peanut Variety and  based on Buying Point and Peanut Type
    bFillPeanutVarietyList(buyingPointId,pnut_type_id)
    bFillPremCodeList(buyingPointId)
  }
  catch(err){
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred during Buying Point dropdown change event"
      );
    }
    return false;
  } 
  return true;
  };
  thisObj.onddLocChange = onBuyingPointChange;

  //Method for binding Peanut Variety based on Buying Point and Peanut Type	
  const bFillPeanutVarietyList = (buyingPointId, pnut_type_id) => {
    let js = []
    let obj = { key: '', description: ">>> All Varieties <<<" }
    js.push(obj)

    if (pnut_type_id !== null && pnut_type_id !== '' && pnut_type_id !== undefined) {
      ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null)
        .then(response => {
          let data = response;
          if (data !== null && data !== undefined) {
            let js1 = [];		
      for (var i = 0; i < data.length; i++) {	
        if (!(js1.includes(data[i].pnut_variety_id))){	
        obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name }	
        if (data[i].symbol_ind == "TRADEMARK") {	
          obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name + 'ᵀᴹ' }	
        }	
        if (data[i].symbol_ind == "REGISTERED TRADEMARK") {	
          obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name + '®' }	
        }	
        if (data[i].symbol_ind == "COPYRIGHT") {	
          obj = { key:data[i].pnut_variety_id, description: data[i].pnut_variety_name + '©' }	
        }	
        js.push(obj);	
        js1.push(data[i].pnut_variety_id)	
      }	
      	
      }	}})}
      thisObj.setState(current => {	
        return {	
          ...current,	
          ddPnutVrty: {	
            ...state["ddPnutVrty"],	
            valueList: js	
          }	
        }	
      });	
  }

  function FormLoad() {
    bFillPeanutTypeList();
    bFillBuyingPointList();
    bFillPeanutVarietyList(null, null);
    bFillStatusList();
    bFillPremCodeList();
    bFillSeedList();
    bFillGenList();
    bFillSegList();
    bFillOleicList();
    GetSecurityRoles();
    document.getElementsByClassName("txtVndor")[0].style.visibility = "hidden";
    setValue(thisObj,"txtVndor","")
    setValue(thisObj,"txtVndr","")
  }


  // Form Load Api calling
  const bFillPeanutTypeList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Types <<< ' }) //Set All as default value
    ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
        js.push(obj)

      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPnutTyp: {
            ...state["ddPnutTyp"],
            valueList: js,
          }
        }
      })
    })
  }

  // User Id Passed temporary need to take from login
  const GetSecurityRoles = () => {
    ContractManagementService.RetrieveAccessPermissionDetails('PN1190', '001', 'U', null).then(response => {
      if (response[0].permission == 'Y') {
        enable(thisObj, "btnCrtExcPrem");
      }
      else {
        disable(thisObj, "btnCrtExcPrem");
      }
    });

    ContractManagementService.RetrieveAccessPermissionDetails('PN1190', '002', 'U', null).then(response => {
      if (response[0].permission == 'Y') {
        enable(thisObj, "btnContrctPyReq");
      }
      else {
        disable(thisObj, "btnContrctPyReq");
      }
    });
  }

  const bFillBuyingPointList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Buying Points <<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1190', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddLoc: {
            ...state["ddLoc"],
            valueList: js
          }
        }
      })
    })
  }

  const bFillPremCodeList = (buyingPointId) => {
    let js = [];
    js.push({ key: '', description: '>>> All Premium Codes <<<' })
    let pd_code = null
    let inspect_ind = null
    let rate_type = null
    let check_enable_ind = null
    let rcpt_enable_ind = null
    let rpt_1099_ind = null
    let except_prem_ind = 'Y'
    ContractManagementService.RetrivePremiumDeductionScheduleDetails(buyingPointId, pd_code, inspect_ind, rate_type, check_enable_ind, rcpt_enable_ind, rpt_1099_ind, except_prem_ind).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pdCode+i, description: data[i].pdCode.trim() + '-' + data[i].pdDesc.trim() }
          js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPrmCode: {
            ...state["ddPrmCode"],
            valueList: js,
          }
        }
      })
    })
  }

  //showing vendorname txtbox change value
  const ontxtVndrChange = () => {
    try {
//PRB0054498 - Searching the vendors with vendor number from vendor textbox and convert to uppercase
  let vendorNumber = txtVndr.value.toUpperCase()
      txtVndr.value = vendorNumber
      if (vendorNumber.length == 6) {
        thisObj.setFieldValue('ddbutton', 'txtvndrchange')
        setVendorNameBasedOnVendorNumber(vendorNumber)
      }
      else {
        document.getElementsByClassName("txtVndor")[0].style.visibility = "hidden";
      }
  
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:txtVondor event:Change"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtVndrChange = ontxtVndrChange;  

  const setVendorNameBasedOnVendorNumber = () => {

    let vendorValueFromTextBox = getValue(thisObj,'txtVndr')
    let venname = null
    let vendornamevisible = false
    let staticButtonValue=getValue(thisObj,'ddbutton')
    let vendor_lookup=getData(thisObj,'vendorDetails')
    let vendorNumber=''
    if(vendorValueFromTextBox !== undefined && staticButtonValue =='txtvndrchange'){
      vendorNumber=vendorValueFromTextBox
    }else if(vendor_lookup !== null && staticButtonValue =='btnPopupVendr'){
      vendorNumber=vendor_lookup.VendorNumber
    }
    if(vendorNumber!=""){
    ContractManagementService.RetrieveVendorByNumber(vendorNumber).then(response => {
      let data = response
      if (response !== undefined && response.length> 0) {
        venname = data[0].name
        vendornamevisible = true
        
      }
      if (vendornamevisible == true) {
        document.getElementsByClassName("txtVndor")[0].style.visibility = "visible";
        setValue(thisObj, "txtVndor", venname)
        setValue(thisObj,"txtVndr",vendorNumber)
      }
      else {
        document.getElementsByClassName("txtVndor")[0].style.visibility = "hidden";
        setValue(thisObj, "txtVndor", '')
      }
    })
  }
  }

   // vendor lookupup Popup Click
   const onbtnPopupVendrClick = () => {
    try {
     thisObj.setFieldValue('ddbutton', 'btnPopupVendr')
     setVendorNameBasedOnVendorNumber()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnPopupVendr: Click"
        )
      }
      return false
    }
    return true
  };
  thisObj.onbtnPopupVendrClick = onbtnPopupVendrClick;

 // vendor refreshing value for vendor textbox 
  const onbtnCrtExcPremClick = () => {
    try {
     thisObj.setFieldValue('ddbutton', 'btnCrtExcPrem')
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnCrtExcPrem: Click"
        )
      }
      return false
    }
    return true
  };
  thisObj.onbtnCrtExcPremClick = onbtnCrtExcPremClick;
  

  const onbtnContrctPyReqClick = () => {
    try {
     thisObj.setFieldValue('ddbutton', 'btnContrctPyReq')
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnContrctPyReq: Click"
        )
      }
      return false
    }
    return true
  };
  thisObj.onbtnContrctPyReqClick = onbtnContrctPyReqClick;

  // Static values with parameters
  const bFillStatusList = () => {
    let js = [];
    //js.push({key:'0',description:'>>>All Statuses<<<'})
    js.push({ key: '', description: '>>> Any Status <<<' })
    js.push({ key: 'PENDING', description: 'Pending' })
    js.push({ key: 'APPROVED', description: 'Approved' })
    js.push({ key: 'PAID', description: 'Paid' })
    js.push({ key: 'REJECTED', description: 'Rejected' })
    js.push({ key: 'REVERSED', description: 'Reversed' })
    setValue(thisObj, "ddStatus", '')
    thisObj.setState(current => {
      return {
        ...current,
        ddStatus: {
          ...state["ddStatus"],
          valueList: js
        }
      }
    })
  }

  const bFillSeedList = () => {
    let js = [];
    js.push({ key: '', description: '>>> Both Seeds <<<' })
    js.push({ key: 'Y', description: 'Seed' })
    js.push({ key: 'N', description: 'Non-Seed' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSeed: {
          ...state["ddSeed"],
          valueList: js
        }
      }
    })
  }

  const bFillGenList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Generation <<<' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    thisObj.setState(current => {
      return {
        ...current,
        ddGen: {
          ...state["ddGen"],
          valueList: js
        }
      }
    })
  }

  const bFillSegList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Segments <<<' })
    js.push({ key: '1', description: 'Seg1' })
    js.push({ key: '2', description: 'Seg2' })
    js.push({ key: '3', description: 'Seg3' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSegTyp: {
          ...state["ddSegTyp"],
          valueList: js
        }
      }
    })
  }

  const bFillOleicList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Oleic <<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })
  }

  // Grid Data Loading again after changing status 
    function gridLoad () {
    let TransformedRowsArray = [];
    let request_memo_num = txtPayReq.value
    let buy_pt_id = thisObj.values['ddLoc']
    let prem_vendor = txtVndr.value
    let request_status = thisObj.values['ddStatus']
    let settle_num = txt1007.value
    let insp_num = txtsc95.value
    let contract_num = txtContrct.value
    let farm_id = txtFrm.value
    let farm_suffix = txtFrmSfx.value
    let pnut_type_id = thisObj.values['ddPnutTyp']
    let pnut_variety_id = thisObj.values['ddPnutVrty']
    let seed_ind = thisObj.values['ddSeed']
    let seed_gen = thisObj.values['ddGen']
    let seg_type = thisObj.values['ddSegTyp']
    let oleic_ind = thisObj.values['ddOleic']
    let prem_code = thisObj.values['ddPrmCode']
    prem_code = prem_code.substring(0, prem_code.length - 1);
    ContractManagementService.RetrieveExceptionPremiumRequestDetails(request_memo_num, buy_pt_id, insp_num, settle_num, prem_vendor, farm_id, farm_suffix, seg_type, pnut_type_id, pnut_variety_id, oleic_ind, seed_ind, seed_gen, contract_num, request_status, prem_code)
    .then(response => {
    if (response == undefined || response[0] == undefined) {
      setValue(thisObj, "gridExceptionPremiumSearch", TransformedRowsArray);//To make grid empty if data not found
      showMessage(thisObj, "No Request Details Found !")
      setValue(thisObj, "txtTotlAmt", "")
      disable(thisObj, "btnOpen")
      disable(thisObj, 'btnExport')
      disable(thisObj, 'btnPrntSrch')
    }
    else {
      let data = response;
      let js = [];
      let obj = {}
      let totalAmt = 0;
      if (data.length != 0 && data != null) {
        enable(thisObj, "btnOpen");
        enable(thisObj, "btnExport");
        enable(thisObj, "btnPrntSrch");
      }
          for (var i = 0; i < data.length; i++) {
            obj.txtPayRequestC = data[i].request_memo_num;
            obj.txtStatusC = data[i].request_status;
            obj.txtLocationC = data[i].buy_pt_id + '-' + data[i].buy_pt_name;
            obj.txtVendorC = data[i].prem_vendor + '-' + data[i].vendor_name;
            obj.txtPremiumCodeC = data[i].prem_code + '-' + data[i].pd_desc;
            obj.txtTotalAmount = data[i].total_amount;
            obj.txtApproveDate = data[i].approve_date_time;
            obj.txtRequestDate = data[i].request_date_time;
            if (data[i].request_status == 'APPROVED' || data[i].request_status == 'PENDING' || data[i].request_status=='PAID') {
              totalAmt += Number(data[i].total_amount)
            };
            js.push(obj)
            obj = {}
          }
          setValue(thisObj, "gridExceptionPremiumSearch", js)
          let totalAmount = totalAmt.toFixed(2)
          setValue(thisObj, "txtTotlAmt", totalAmount)
        }
      })
  }

  // search btn implimentation
  const onbtnSearchClick = () => {
    try {
      if (txtPayReq.value == "" && getValue(thisObj, "ddLoc") == "" && txtVndr.value == "") {//Location dropdown validation pending
        alert("Location or vendor number is required.",false);
      }
      else {
        setLoading(true);
        let TransformedRowsArray = [];  
        let request_memo_num = txtPayReq.value
        let buy_pt_id = thisObj.values['ddLoc']
        let prem_vendor = txtVndr.value
        let request_status = thisObj.values['ddStatus']
        let settle_num = txt1007.value
        let insp_num = txtsc95.value
        let contract_num = txtContrct.value
        let farm_id = txtFrm.value
        let farm_suffix = txtFrmSfx.value
        let pnut_type_id = thisObj.values['ddPnutTyp']
        let pnut_variety_id = thisObj.values['ddPnutVrty']
        let seed_ind = thisObj.values['ddSeed']
        let seed_gen = thisObj.values['ddGen']
        let seg_type = thisObj.values['ddSegTyp']
        let oleic_ind = thisObj.values['ddOleic']
        let prem_code = thisObj.values['ddPrmCode']
        prem_code=prem_code.substring(0,prem_code.length-1);
        ContractManagementService.RetrieveExceptionPremiumRequestDetails(request_memo_num, buy_pt_id, insp_num, settle_num, prem_vendor, farm_id, farm_suffix, seg_type, pnut_type_id, pnut_variety_id, oleic_ind, seed_ind, seed_gen, contract_num, request_status, prem_code)
          .then(response => {
            if (response == undefined || response[0]==undefined) {
              setValue(thisObj, "gridExceptionPremiumSearch", TransformedRowsArray);//To make grid empty if data not found
              showMessage(thisObj,"No Request Details Found !")
              setValue(thisObj,"txtTotlAmt","")
              disable(thisObj, "btnOpen")
              disable(thisObj,'btnExport')
              disable(thisObj,'btnPrntSrch')
              setLoading(false);
            }
            else {
              let data = response;
              let js = [];
              let obj = {}
              let totalAmt = 0;
              if (data.length != 0 && data != null) {
                enable(thisObj, "btnOpen");
                enable(thisObj, "btnExport");
                enable(thisObj, "btnPrntSrch");
              }
              for (var i = 0; i < data.length; i++) {
                obj.txtPayRequestC = data[i].request_memo_num;
                obj.txtStatusC = data[i].request_status;
                obj.txtLocationC = data[i].buy_pt_id + '-' + data[i].buy_pt_name;
                obj.txtVendorC =data[i].prem_vendor + '-' + data[i].vendor_name;
                obj.txtPremiumCodeC = data[i].prem_code + '-' + data[i].pd_desc;
                obj.txtTotalAmount = data[i].total_amount;
                obj.txtRequestDate = data[i].request_date_time;
                obj.txtApproveDate = data[i].approve_date_time;
                js.push(obj)
                if(data[i].request_status=='APPROVED' || data[i].request_status=='PENDING' || data[i].request_status=='PAID'){
                totalAmt += Number(data[i].total_amount)};
                obj = {}
              }
              setValue(thisObj, "gridExceptionPremiumSearch", js)
              let totalAmount = totalAmt.toFixed(2)
              setValue(thisObj, "txtTotlAmt", totalAmount)
              setLoading(false);
            }
          }) 
      }
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
        )
      }
      return false
    }
    return true
  };
  thisObj.onbtnSrchClick = onbtnSearchClick;

  // On Open Button Click data pass to Exception Premium Request Profile
  const onbtnOpenClick = () => {
    try {
      let rowselectedvalue = getValue(thisObj, "gridExceptionPremiumSearch"); 
      let row = getSelectedRowNumber(thisObj,"gridExceptionPremiumSearch")
      if(row>-1){
        setData(thisObj, 'frmExceptPremRequestProfileData', rowselectedvalue[row])
        goTo(thisObj, "ContractManagement#ExceptPremRequestProfile#DEFAULT#true#Click");
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnOpen: Click"
        )
      }
      return false
    }
    return true
  };
  thisObj.onbtnOpenClick = onbtnOpenClick;

  // Export to excel functionality
  const onbtnExportClick = async () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let excelData = getValue(thisObj, "gridExceptionPremiumSearch")
    if (excelData !== null || excelData !== undefined || excelData !== '') {
      var fileName = "Exception Premium Search"
      const arrayData = []
      for (var i = 0; i < excelData.length; i++) {
        var obj = {
          "Pay Request #": ExcelNumericDataConvert(excelData[i].txtPayRequestC),
          "Status": excelData[i].txtStatusC,
          "Location": excelData[i].txtLocationC,
          "Vendor": excelData[i].txtVendorC,
          "Premium Code": excelData[i].txtPremiumCodeC,
          "Total Amount": ExcelNumericDataConvert(excelData[i].txtTotalAmount),
          "Request Date": excelData[i].txtRequestDate,
          "Approve Date": excelData[i].txtApproveDate
        }
        arrayData.push(obj);
      }
      const ws = XLSX.utils.json_to_sheet(arrayData)
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }
  thisObj.onbtnExportClick = onbtnExportClick;

  // Print Button Functionality

  const onbtnPrntSrchClick = () => {
    try {
      var data = getValue(thisObj, "gridExceptionPremiumSearch")
      var res = []
      for (var i = 0; i < data.length; i++) {
        res.push([
          data[i].txtPayRequestC, data[i].txtStatusC, data[i].txtLocationC, data[i].txtVendorC, data[i].txtPremiumCodeC, data[i].txtTotalAmount, data[i].txtRequestDate, data[i].txtApproveDate
        ])
      }

      var obj = new jsPDF();
      obj.autoTable({
        // startY: 5,
        styles: { fontSize: 5,lineWidth : 0.2},
        margin: { top: 5 , left:0.5 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [
          [
            'Pay Request #', 'Status', 'Location', 'Vendor', 'Premium Code', 'Total Amount', 'Request Date', 'Approve Date'
          ]
        ]
      });
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          showMessage(thisObj,"Please enter the file name!",false)
        }
      }
      else {
        alert("Routine:frmExceptPremSearch.cmdPrintSearch_Click",false)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrntSrchClick = onbtnPrntSrchClick;


  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ExceptionPremiumSearch*/}

              {/* END_USER_CODE-USER_BEFORE_ExceptionPremiumSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxExcPremSrch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxExcPremSrch*/}

              <GroupBoxWidget conf={state.grpbxExcPremSrch} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtPayReq*/}

                {/* END_USER_CODE-USER_BEFORE_txtPayReq*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPayReq}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPayReq*/}

                {/* END_USER_CODE-USER_AFTER_txtPayReq*/}
                {/* START_USER_CODE-USER_BEFORE_txt1007*/}

                {/* END_USER_CODE-USER_BEFORE_txt1007*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1007*/}

                {/* END_USER_CODE-USER_AFTER_txt1007*/}
                {/* START_USER_CODE-USER_BEFORE_ddPnutTyp*/}

                {/* END_USER_CODE-USER_BEFORE_ddPnutTyp*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPnutTyp}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPnutTyp*/}

                {/* END_USER_CODE-USER_AFTER_ddPnutTyp*/}
                {/* START_USER_CODE-USER_BEFORE_ddLoc*/}

                {/* END_USER_CODE-USER_BEFORE_ddLoc*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLoc}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLoc*/}

                {/* END_USER_CODE-USER_AFTER_ddLoc*/}
                {/* START_USER_CODE-USER_BEFORE_txtsc95*/}

                {/* END_USER_CODE-USER_BEFORE_txtsc95*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtsc95}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtsc95*/}

                {/* END_USER_CODE-USER_AFTER_txtsc95*/}
                {/* START_USER_CODE-USER_BEFORE_ddPnutVrty*/}

                {/* END_USER_CODE-USER_BEFORE_ddPnutVrty*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPnutVrty}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPnutVrty*/}

                {/* END_USER_CODE-USER_AFTER_ddPnutVrty*/}
                {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                {/* START_USER_CODE-USER_BEFORE_btnPopupVendr*/}

                {/* END_USER_CODE-USER_BEFORE_btnPopupVendr*/}

                <ButtonWidget
                  conf={state.btnPopupVendr}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPopupVendr*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* END_USER_CODE-USER_AFTER_btnPopupVendr*/}
                {/* START_USER_CODE-USER_BEFORE_txtContrct*/}

                {/* END_USER_CODE-USER_BEFORE_txtContrct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContrct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContrct*/}

                {/* END_USER_CODE-USER_AFTER_txtContrct*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeed*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeed*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeed}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeed*/}

                {/* END_USER_CODE-USER_AFTER_ddSeed*/}
                {/* START_USER_CODE-USER_BEFORE_ddStatus*/}

                {/* END_USER_CODE-USER_BEFORE_ddStatus*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddStatus}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddStatus*/}

                {/* END_USER_CODE-USER_AFTER_ddStatus*/}
                {/* START_USER_CODE-USER_BEFORE_txtFrm*/}

                {/* END_USER_CODE-USER_BEFORE_txtFrm*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFrm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFrm*/}

                {/* END_USER_CODE-USER_AFTER_txtFrm*/}
                {/* START_USER_CODE-USER_BEFORE_ddGen*/}

                {/* END_USER_CODE-USER_BEFORE_ddGen*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddGen}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddGen*/}

                {/* END_USER_CODE-USER_AFTER_ddGen*/}
                {/* START_USER_CODE-USER_BEFORE_ddPrmCode*/}

                {/* END_USER_CODE-USER_BEFORE_ddPrmCode*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPrmCode}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPrmCode*/}

                {/* END_USER_CODE-USER_AFTER_ddPrmCode*/}
                {/* START_USER_CODE-USER_BEFORE_txtFrmSfx*/}

                {/* END_USER_CODE-USER_BEFORE_txtFrmSfx*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFrmSfx}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFrmSfx*/}

                {/* END_USER_CODE-USER_AFTER_txtFrmSfx*/}
                {/* START_USER_CODE-USER_BEFORE_ddSegTyp*/}

                {/* END_USER_CODE-USER_BEFORE_ddSegTyp*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSegTyp}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSegTyp*/}

                {/* END_USER_CODE-USER_AFTER_ddSegTyp*/}
                {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleic}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                {/* START_USER_CODE-USER_BEFORE_btnSrch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSrch*/}

                <ButtonWidget
                  conf={state.btnSrch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSrch*/}

                {/* END_USER_CODE-USER_AFTER_btnSrch*/}
                {/* START_USER_CODE-USER_BEFORE_btnCrtExcPrem*/}

                {/* END_USER_CODE-USER_BEFORE_btnCrtExcPrem*/}

                <ButtonWidget
                  conf={state.btnCrtExcPrem}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCrtExcPrem*/}

                {/* END_USER_CODE-USER_AFTER_btnCrtExcPrem*/}
                {/* START_USER_CODE-USER_BEFORE_btnContrctPyReq*/}

                {/* END_USER_CODE-USER_BEFORE_btnContrctPyReq*/}

                <ButtonWidget
                  conf={state.btnContrctPyReq}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnContrctPyReq*/}

                {/* END_USER_CODE-USER_AFTER_btnContrctPyReq*/}
                {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                <ButtonWidget
                  conf={state.btnOpen}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOpen*/}
                <GridWidget
                  conf={state.gridExceptionPremiumSearch}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridExceptionPremiumSearch}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  //GridRowDoubleClicked={row=>rowDoubleClick(row)}
                  setFieldValue={setFieldValue}
                // onRowChange={row=>rowChange(row)}
                ></GridWidget>
                {/* END_USER_CODE-USER_AFTER_btnOpen*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxExcPremSrch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxExcPremSrch*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxExprtPrnt*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxExprtPrnt*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxExprtPrnt} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtTotlAmt*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotlAmt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotlAmt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotlAmt*/}

                {/* END_USER_CODE-USER_AFTER_txtTotlAmt*/}
                {/* START_USER_CODE-USER_BEFORE_btnExport*/}

                {/* END_USER_CODE-USER_BEFORE_btnExport*/}

                <ButtonWidget
                  conf={state.btnExport}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExport*/}

                {/* END_USER_CODE-USER_AFTER_btnExport*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrntSrch*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrntSrch*/}

                <ButtonWidget
                  conf={state.btnPrntSrch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrntSrch*/}

                {/* END_USER_CODE-USER_AFTER_btnPrntSrch*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxExprtPrnt*/}

              {/* END_USER_CODE-USER_AFTER_grpbxExprtPrnt*/}

              {/* START_USER_CODE-USER_AFTER_ExceptionPremiumSearch*/}

              {/* END_USER_CODE-USER_AFTER_ExceptionPremiumSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ExceptionPremiumSearch);
