/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  getData,
  setData,
  goTo,
  setValue,
  getValue
} from "../../shared/WindowImports";

import "./WeigherProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_WeigherProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "WeigherProfile",
    windowName: "WeigherProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.WeigherProfile",
    // START_USER_CODE-USER_WeigherProfile_PROPERTIES
    headerData: {
      scrName: "Buying Point Weigher Profile",
      scrCode: "PN0020F",
    },
    // END_USER_CODE-USER_WeigherProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    chkboxDefaultWeigher: {
      name: "chkboxDefaultWeigher",
      type: "CheckBoxWidget",
      parent: "grpbxWeigherProfile",
      Label: "Default Weigher",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxDefaultWeigher_PROPERTIES

      // END_USER_CODE-USER_chkboxDefaultWeigher_PROPERTIES
    },
    lblBuyingPoint: {
      name: "lblBuyingPoint",
      type: "LabelWidget",
      parent: "grpbxWeigherProfile",
      Label: "Buying Point:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_lblBuyingPoint_PROPERTIES
    },
    lblBuyingPointValue: {
      name: "lblBuyingPointValue",
      type: "LabelWidget",
      parent: "grpbxWeigherProfile",
      Label: ".",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyingPointValue_PROPERTIES

      // END_USER_CODE-USER_lblBuyingPointValue_PROPERTIES
    },
    lblWeigher: {
      name: "lblWeigher",
      type: "LabelWidget",
      parent: "grpbxWeigherProfile",
      Label: "lblWeigher",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWeigher_PROPERTIES

      // END_USER_CODE-USER_lblWeigher_PROPERTIES
    },
    txtWeigher: {
      name: "txtWeigher",
      type: "TextBoxWidget",
      parent: "grpbxWeigherProfile",
      Label: "Weigher:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWeigher_PROPERTIES

      // END_USER_CODE-USER_txtWeigher_PROPERTIES
    },
    grpbxWeigherProfile: {
      name: "grpbxWeigherProfile",
      type: "GroupBoxWidget",
      parent: "WeigherProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxWeigherProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxWeigherProfile_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "WeigherProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    document.getElementsByClassName("lblWeigher")[0].style.visibility = "hidden";
    let weigherprofiledetails=getData(thisObj,"BPSetupWeighersData");
  if(weigherprofiledetails!="" && weigherprofiledetails!=null && weigherprofiledetails!=undefined)
  {
setValue(thisObj,"lblBuyingPointValue",weigherprofiledetails.lblBuyingPoint)
setValue(thisObj,"txtWeigher",weigherprofiledetails.txtWeigher)
if(weigherprofiledetails.chkDefaultWeigher==undefined ||  weigherprofiledetails.chkDefaultWeigher=="")
setValue(thisObj,"chkboxDefaultWeigher",false)
else
  setValue(thisObj,"chkboxDefaultWeigher",true)
document.getElementById("btnOk").innerText = weigherprofiledetails.btnOk;
  }
  
  }, [getData(thisObj, "BPSetupWeighersData")]);


  // START_USER_CODE-USER_METHODS
  // Update Button

  const onbtnOkClick = async () => {
    
    if (getValue(thisObj, "txtWeigher") == "") {
      showMessage(
        thisObj,
        "Weigher is a required field.", "Required Field Missing"
      );
      return
    }
    let sDflt = ''
    let resp = ''
    let dflt_weigher_ind = ''
    if (thisObj.values.chkboxDefaultWeigher == true) { dflt_weigher_ind = "Y" }
    else { dflt_weigher_ind = "N" }
    let dataObj={};
    let weigherprofiledetails=getData(thisObj,"BPSetupWeighersData")
    
    let flag = weigherprofiledetails.btnOk;
      if (flag == 'Add') {
    
        dataObj["dflt_weigher_ind"]=dflt_weigher_ind;
        //dataObj["weigher_name"]=getValue(thisObj, "txtWeigher").trim();
        dataObj["weigher_name"]= Buffer.from(getValue(thisObj, "txtWeigher").trim()).toString('base64');
      }
  else
  {
    dataObj["dflt_weigher_ind"]=dflt_weigher_ind;
    //dataObj["weigher_name"]=weigherprofiledetails.lblWeigher.trim();
    dataObj["weigher_name"]=Buffer.from(weigherprofiledetails.lblWeigher.trim()).toString('base64');
    // dataObj["new_weigher_name"]=getValue(thisObj, "txtWeigher").trim();
    dataObj["new_weigher_name"]=Buffer.from(getValue(thisObj, "txtWeigher").trim()).toString('base64');
  }
      resp = await SystemMaintenanceMasterManagementService.CreateWeigherControl(weigherprofiledetails.BuyingPointId, null, dataObj)
    
    
    if (resp.status == 200) {
      showMessage(thisObj, resp.message, true);
    }
    else {
      showMessage(thisObj, "Weigher is a required field.", false);
      return
    }
    let js = {}
    let WeigherText=getValue(thisObj,'txtWeigher')
    if (flag == "Add") {
      js = {
        txtWeigher: WeigherText,
        newIndex: 0
      }
    }
    else {
      js = {
        txtWeigher: WeigherText
      }
    }
    setData(thisObj, 'BuyingPointSetUp', js)
    setData(thisObj, 'closingOfWeigherProfile', true);
   // goTo(thisObj,"SystemMaintenanceMasterManagement#BuyingPointSetup#DEFAULT#true#Click")
    document.getElementById("SystemMaintenanceMasterManagement_WeigherProfilePopUp").childNodes[0].click()
  };

  thisObj.onbtnOkClick = onbtnOkClick;
  // Cancel Button 

  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_WeigherProfilePopUp").childNodes[0].click()

      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;


  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_WeigherProfile*/}

              {/* END_USER_CODE-USER_BEFORE_WeigherProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxWeigherProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxWeigherProfile*/}

              <GroupBoxWidget
                conf={state.grpbxWeigherProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBuyingPoint}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_lblBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_lblBuyingPointValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblBuyingPointValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBuyingPointValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBuyingPointValue*/}

                {/* END_USER_CODE-USER_AFTER_lblBuyingPointValue*/}
                {/* START_USER_CODE-USER_BEFORE_txtWeigher*/}

                {/* END_USER_CODE-USER_BEFORE_txtWeigher*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWeigher}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWeigher*/}

                {/* END_USER_CODE-USER_AFTER_txtWeigher*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxDefaultWeigher*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxDefaultWeigher*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxDefaultWeigher}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxDefaultWeigher*/}

                {/* END_USER_CODE-USER_AFTER_chkboxDefaultWeigher*/}
                {/* START_USER_CODE-USER_BEFORE_lblWeigher*/}

                {/* END_USER_CODE-USER_BEFORE_lblWeigher*/}

                <LabelWidget
                  values={values}
                  conf={state.lblWeigher}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblWeigher*/}

                {/* END_USER_CODE-USER_AFTER_lblWeigher*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxWeigherProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxWeigherProfile*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_WeigherProfile*/}

              {/* END_USER_CODE-USER_AFTER_WeigherProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_WeigherProfile);
