/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  DateWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  disable,
  enable,
  setData,
  setValue,
  getData,
  getValue,
  show,
  hide,
  setLabel,
} from "../../shared/WindowImports";

import "./SpecFuncMisc.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { isDate } from "moment";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment"
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService"
import { returnNumericValues } from "../../Common/Constants";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { response } from "msw";
import Loading from "../../../Loader/Loading";
import { Buffer } from 'buffer';
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_SpecFuncMisc(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES
  let userid = sessionStorage.getItem("userid");
  let compid = sessionStorage.getItem('compId');
  let cropyear = sessionStorage.getItem('year');
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SpecFuncMisc",
    windowName: "SpecFuncMisc",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.SpecFuncMisc",
    // START_USER_CODE-USER_SpecFuncMisc_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Special Functions/Miscellaneous",
      scrCode: "PN0200D",
    },
    // END_USER_CODE-USER_SpecFuncMisc_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "commandcontainerwidget1",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnRequest: {
      name: "btnRequest",
      type: "ButtonWidget",
      parent: "commandcontainerwidget1",
      Label: "Request",
      CharWidth: "17",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnRequest_PROPERTIES

      // END_USER_CODE-USER_btnRequest_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "commandcontainerwidget1",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    chkboxVicam: {
      name: "chkboxVicam",
      type: "CheckBoxWidget",
      parent: "grpbxChangeVicamReading",
      Label: "Vicam:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxVicam_PROPERTIES

      // END_USER_CODE-USER_chkboxVicam_PROPERTIES
    },
    ddOleicInd: {
      name: "ddOleicInd",
      type: "DropDownFieldWidget",
      parent: "grpbxChangeOleicInd",
      Label: "Oleic Ind:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleicInd_PROPERTIES

      // END_USER_CODE-USER_ddOleicInd_PROPERTIES
    },
    ddTransferType: {
      name: "ddTransferType",
      type: "DropDownFieldWidget",
      parent: "grpbxDeleteStockTransfer",
      Label: "Transfer Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddTransferType_PROPERTIES

      // END_USER_CODE-USER_ddTransferType_PROPERTIES
    },
    grpbxChange1007Unload: {
      name: "grpbxChange1007Unload",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxChange1007Unload_PROPERTIES

      // END_USER_CODE-USER_grpbxChange1007Unload_PROPERTIES
    },
    grpbxChangeOleicInd: {
      name: "grpbxChangeOleicInd",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbxChangeOleicInd_PROPERTIES

      // END_USER_CODE-USER_grpbxChangeOleicInd_PROPERTIES
    },
    grpbxChangeVicamReading: {
      name: "grpbxChangeVicamReading",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxChangeVicamReading_PROPERTIES

      // END_USER_CODE-USER_grpbxChangeVicamReading_PROPERTIES
    },
    grpbxChgDt: {
      name: "grpbxChgDt",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxChgDt_PROPERTIES

      // END_USER_CODE-USER_grpbxChgDt_PROPERTIES
    },
    grpbxChgGrds: {
      name: "grpbxChgGrds",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxChgGrds_PROPERTIES

      // END_USER_CODE-USER_grpbxChgGrds_PROPERTIES
    },
    grpbxCopyFarms: {
      name: "grpbxCopyFarms",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxCopyFarms_PROPERTIES

      // END_USER_CODE-USER_grpbxCopyFarms_PROPERTIES
    },
    grpbxDeleteStockTransfer: {
      name: "grpbxDeleteStockTransfer",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbxDeleteStockTransfer_PROPERTIES

      // END_USER_CODE-USER_grpbxDeleteStockTransfer_PROPERTIES
    },
    grpbxNewCY: {
      name: "grpbxNewCY",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxNewCY_PROPERTIES

      // END_USER_CODE-USER_grpbxNewCY_PROPERTIES
    },
    grpbxAllign: {
      name: "grpbxAllign",
      type: "GroupBoxWidget",
      parent: "grpbxRequestARDownload",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxAllign_PROPERTIES

      // END_USER_CODE-USER_grpbxAllign_PROPERTIES
    },
    grpbxReopenVoidedClosedContract: {
      name: "grpbxReopenVoidedClosedContract",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbxReopenVoidedClosedContract_PROPERTIES

      // END_USER_CODE-USER_grpbxReopenVoidedClosedContract_PROPERTIES
    },
    grpbxRequestARDownload: {
      name: "grpbxRequestARDownload",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxRequestARDownload_PROPERTIES

      // END_USER_CODE-USER_grpbxRequestARDownload_PROPERTIES
    },
    grpbxResetCheckToVoid: {
      name: "grpbxResetCheckToVoid",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxResetCheckToVoid_PROPERTIES

      // END_USER_CODE-USER_grpbxResetCheckToVoid_PROPERTIES
    },
    grpbxResetSignedContract: {
      name: "grpbxResetSignedContract",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbxResetSignedContract_PROPERTIES

      // END_USER_CODE-USER_grpbxResetSignedContract_PROPERTIES
    },
    grpbxResetUpload211: {
      name: "grpbxResetUpload211",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxResetUpload211_PROPERTIES

      // END_USER_CODE-USER_grpbxResetUpload211_PROPERTIES
    },
    grpbxResetUploadContract: {
      name: "grpbxResetUploadContract",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxResetUploadContract_PROPERTIES

      // END_USER_CODE-USER_grpbxResetUploadContract_PROPERTIES
    },
    grpbxSelectFunc: {
      name: "grpbxSelectFunc",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSelectFunc_PROPERTIES

      // END_USER_CODE-USER_grpbxSelectFunc_PROPERTIES
    },
    lblChange1007Unload: {
      name: "lblChange1007Unload",
      type: "LabelWidget",
      parent: "grpbxChange1007Unload",
      Label: "Change Unload Warehouse and Bin on a 1007:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChange1007Unload_PROPERTIES

      // END_USER_CODE-USER_lblChange1007Unload_PROPERTIES
    },
    lblChangeOleicInd: {
      name: "lblChangeOleicInd",
      type: "LabelWidget",
      parent: "grpbxChangeOleicInd",
      Label: "Change Oleic Ind on a 1007:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangeOleicInd_PROPERTIES

      // END_USER_CODE-USER_lblChangeOleicInd_PROPERTIES
    },
    lblChangeVicamReading: {
      name: "lblChangeVicamReading",
      type: "LabelWidget",
      parent: "grpbxChangeVicamReading",
      Label: "Change Vicam Reading on a 1007:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangeVicamReading_PROPERTIES

      // END_USER_CODE-USER_lblChangeVicamReading_PROPERTIES
    },
    lblChgDt: {
      name: "lblChgDt",
      type: "LabelWidget",
      parent: "grpbxChgDt",
      Label: "Change Contract Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChgDt_PROPERTIES

      // END_USER_CODE-USER_lblChgDt_PROPERTIES
    },
    lblChgGrds: {
      name: "lblChgGrds",
      type: "LabelWidget",
      parent: "grpbxChgGrds",
      Label: "Change Virginia Cr/Br Grades on a 1007:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChgGrds_PROPERTIES

      // END_USER_CODE-USER_lblChgGrds_PROPERTIES
    },
    lblCopyFarms: {
      name: "lblCopyFarms",
      type: "LabelWidget",
      parent: "grpbxCopyFarms",
      Label: "Copy Farms from existing Buying Pt to another Buying Pt:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCopyFarms_PROPERTIES

      // END_USER_CODE-USER_lblCopyFarms_PROPERTIES
    },
    lblDeleteStockTransfer: {
      name: "lblDeleteStockTransfer",
      type: "LabelWidget",
      parent: "grpbxDeleteStockTransfer",
      Label: "Delete Stock Transfer:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDeleteStockTransfer_PROPERTIES

      // END_USER_CODE-USER_lblDeleteStockTransfer_PROPERTIES
    },
    lblNewCY: {
      name: "lblNewCY",
      type: "LabelWidget",
      parent: "grpbxNewCY",
      Label: "New Crop Year:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNewCY_PROPERTIES

      // END_USER_CODE-USER_lblNewCY_PROPERTIES
    },
    lblNOTE: {
      name: "lblNOTE",
      type: "LabelWidget",
      parent: "txtReqKCMORecon",
      Label: "NOTE:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNOTE_PROPERTIES

      // END_USER_CODE-USER_lblNOTE_PROPERTIES
    },
    lblNote1: {
      name: "lblNote1",
      type: "LabelWidget",
      parent: "txtReqKCMORecon",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNote1_PROPERTIES

      // END_USER_CODE-USER_lblNote1_PROPERTIES
    },
    lblNoteValue: {
      name: "lblNoteValue",
      type: "LabelWidget",
      parent: "txtReqKCMORecon",
      Label: ".....",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNoteValue_PROPERTIES

      // END_USER_CODE-USER_lblNoteValue_PROPERTIES
    },
    lblOptional: {
      name: "lblOptional",
      type: "LabelWidget",
      parent: "grpbxNewCY",
      Label: "(optional)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOptional_PROPERTIES

      // END_USER_CODE-USER_lblOptional_PROPERTIES
    },
    lblReopenVoidedClosedContract: {
      name: "lblReopenVoidedClosedContract",
      type: "LabelWidget",
      parent: "grpbxReopenVoidedClosedContract",
      Label: "Reopen a Voided or Closed Contract:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblReopenVoidedClosedContract_PROPERTIES

      // END_USER_CODE-USER_lblReopenVoidedClosedContract_PROPERTIES
    },
    lblReqKCMORecon: {
      name: "lblReqKCMORecon",
      type: "LabelWidget",
      parent: "txtReqKCMORecon",
      Label: "Request Year End Recon file be sent to Kansas City, MO:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblReqKCMORecon_PROPERTIES

      // END_USER_CODE-USER_lblReqKCMORecon_PROPERTIES
    },
    lblRequestARDownload: {
      name: "lblRequestARDownload",
      type: "LabelWidget",
      parent: "grpbxRequestARDownload",
      Label: "Request A/R Balances from the IBM:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRequestARDownload_PROPERTIES

      // END_USER_CODE-USER_lblRequestARDownload_PROPERTIES
    },
    lblResetCheckToVoid: {
      name: "lblResetCheckToVoid",
      type: "LabelWidget",
      parent: "grpbxResetCheckToVoid",
      Label: "Reset Cleared Check back to VOID:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblResetCheckToVoid_PROPERTIES

      // END_USER_CODE-USER_lblResetCheckToVoid_PROPERTIES
    },
    lblResetSignedContract: {
      name: "lblResetSignedContract",
      type: "LabelWidget",
      parent: "grpbxResetSignedContract",
      Label: "Reset  a Signed Contract back to Pending:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblResetSignedContract_PROPERTIES

      // END_USER_CODE-USER_lblResetSignedContract_PROPERTIES
    },
    lblResetUpload211: {
      name: "lblResetUpload211",
      type: "LabelWidget",
      parent: "grpbxResetUpload211",
      Label: "Reset the upload of the 211 Form image:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblResetUpload211_PROPERTIES

      // END_USER_CODE-USER_lblResetUpload211_PROPERTIES
    },
    lblResetUploadContract: {
      name: "lblResetUploadContract",
      type: "LabelWidget",
      parent: "grpbxResetUploadContract",
      Label: "Reset the upload of the Contract image:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblResetUploadContract_PROPERTIES

      // END_USER_CODE-USER_lblResetUploadContract_PROPERTIES
    },
    radioReqAR: {
      name: "radioReqAR",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxRequestARDownload",
      Options:
        "Buying Point:0,Collection Point:1,Area:2",
      ColSpan: "3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioReqAR_PROPERTIES

      // END_USER_CODE-USER_radioReqAR_PROPERTIES
    },
    radioNewCY: {
      name: "radioNewCY",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxNewCY",
      Options:
        "Sync Crop Year data for Harvest:0,Sync Farm Data ONLY:1",
      ColSpan: "3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioNewCY_PROPERTIES

      // END_USER_CODE-USER_radioNewCY_PROPERTIES
    },
    radioMisc: {
      name: "radioMisc",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxSelectFunc",
      Options:
        "New Crop Year:0,Reset  Signed Contract:3,Change Oleic Ind:6,Request A/R download:1,Reopen Void/Closed Contract:4,Change Vicam Reading:7,Delete Stock Transfer:2,Change 1007 Unload:5,Change VA Cr/Br Grades:8,Request KCMO Recon:9,Reset Check to VOID:10,Change Contract Date:11,Reset upload 211 PDF:12,Reset upload Contract PDF:13,Copy Farms from BP:14",
      Label: "Miscellaneous:",
      ColSpan: "3",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioMisc_PROPERTIES

      // END_USER_CODE-USER_radioMisc_PROPERTIES
    },
    radioReset: {
      name: "radioReset",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxResetUpload211",
      Options: "Reset GPC 211 Form:1,Reset Concordia 211 Form:2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioReset_PROPERTIES

      // END_USER_CODE-USER_radioReset_PROPERTIES
    },
    txtArea: {
      name: "txtArea",
      type: "TextBoxWidget",
      parent: "grpbxRequestARDownload",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtArea_PROPERTIES

      // END_USER_CODE-USER_txtArea_PROPERTIES
    },

    txtBin: {
      name: "txtBin",
      type: "TextBoxWidget",
      parent: "grpbxChange1007Unload",
      Label: "Bin:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBin_PROPERTIES

      // END_USER_CODE-USER_txtBin_PROPERTIES
    },
    txtBPForFarmSync: {
      name: "txtBPForFarmSync",
      type: "TextBoxWidget",
      parent: "grpbxNewCY",
      Label: "BP for Farm Sync:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBPForFarmSync_PROPERTIES

      // END_USER_CODE-USER_txtBPForFarmSync_PROPERTIES
    },
    txtBuyPtCopyFrom: {
      name: "txtBuyPtCopyFrom",
      type: "TextBoxWidget",
      parent: "grpbxCopyFarms",
      Label: "Copy from Buying Pt Id:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyPtCopyFrom_PROPERTIES

      // END_USER_CODE-USER_txtBuyPtCopyFrom_PROPERTIES
    },
    txtBuyPtIdChgGrds: {
      name: "txtBuyPtIdChgGrds",
      type: "TextBoxWidget",
      parent: "grpbxChgGrds",
      Label: "Buy Pt Id:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyPtIdChgGrds_PROPERTIES

      // END_USER_CODE-USER_txtBuyPtIdChgGrds_PROPERTIES
    },
    txtBuyPtIdChgUnload: {
      name: "txtBuyPtIdChgUnload",
      type: "TextBoxWidget",
      parent: "grpbxChange1007Unload",
      Label: "Buy Pt Id:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyPtIdChgUnload_PROPERTIES

      // END_USER_CODE-USER_txtBuyPtIdChgUnload_PROPERTIES
    },
    txtBuyPtIdChgVicam: {
      name: "txtBuyPtIdChgVicam",
      type: "TextBoxWidget",
      parent: "grpbxChangeVicamReading",
      Label: "Buy Pt Id:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyPtIdChgVicam_PROPERTIES

      // END_USER_CODE-USER_txtBuyPtIdChgVicam_PROPERTIES
    },
    txtBuyPtIdCopyTo: {
      name: "txtBuyPtIdCopyTo",
      type: "TextBoxWidget",
      parent: "grpbxCopyFarms",
      Label: "to Buying Pt Id:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyPtIdCopyTo_PROPERTIES

      // END_USER_CODE-USER_txtBuyPtIdCopyTo_PROPERTIES
    },
    txtBuyPtId: {
      name: "txtBuyPtId",
      type: "TextBoxWidget",
      parent: "grpbxChangeOleicInd",
      Label: "Buy Pt Id:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyPtId_PROPERTIES

      // END_USER_CODE-USER_txtBuyPtId_PROPERTIES
    },
    txtBuyPt: {
      name: "txtBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxRequestARDownload",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtBuyPt_PROPERTIES
    },
    txtCheckNumToVoid: {
      name: "txtCheckNumToVoid",
      type: "TextBoxWidget",
      parent: "grpbxResetCheckToVoid",
      Label: "Check# to Void:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheckNumToVoid_PROPERTIES

      // END_USER_CODE-USER_txtCheckNumToVoid_PROPERTIES
    },
    txtCollectionPt: {
      name: "txtCollectionPt",
      type: "TextBoxWidget",
      parent: "grpbxRequestARDownload",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCollectionPt_PROPERTIES

      // END_USER_CODE-USER_txtCollectionPt_PROPERTIES
    },
    txtContractNumChgDt: {
      name: "txtContractNumChgDt",
      type: "TextBoxWidget",
      parent: "grpbxChgDt",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContractNumChgDt_PROPERTIES

      // END_USER_CODE-USER_txtContractNumChgDt_PROPERTIES
    },
    txtContractNumToReopen: {
      name: "txtContractNumToReopen",
      type: "TextBoxWidget",
      parent: "grpbxReopenVoidedClosedContract",
      Label: "Contract #: ",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContractNumToReopen_PROPERTIES

      // END_USER_CODE-USER_txtContractNumToReopen_PROPERTIES
    },
    txtContractNumToReset: {
      name: "txtContractNumToReset",
      type: "TextBoxWidget",
      parent: "grpbxResetUploadContract",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContractNumToReset_PROPERTIES

      // END_USER_CODE-USER_txtContractNumToReset_PROPERTIES
    },
    txtContractNum: {
      name: "txtContractNum",
      type: "TextBoxWidget",
      parent: "grpbxResetSignedContract",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContractNum_PROPERTIES

      // END_USER_CODE-USER_txtContractNum_PROPERTIES
    },
    txtCropYearKCMO: {
      name: "txtCropYearKCMO",
      type: "TextBoxWidget",
      parent: "txtReqKCMORecon",
      Label: "Crop Year:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCropYearKCMO_PROPERTIES

      // END_USER_CODE-USER_txtCropYearKCMO_PROPERTIES
    },
    txtCropYear: {
      name: "txtCropYear",
      type: "TextBoxWidget",
      parent: "grpbxRequestARDownload",
      Label: "Crop Year:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCropYear_PROPERTIES

      // END_USER_CODE-USER_txtCropYear_PROPERTIES
    },
    txtGr: {
      name: "txtGr",
      type: "TextBoxWidget",
      parent: "grpbxChgGrds",
      Label: "Grams:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGr_PROPERTIES

      // END_USER_CODE-USER_txtGr_PROPERTIES
    },
    txtNewContractDt: {
      name: "txtNewContractDt",
      type: "DateWidget",
      parent: "grpbxChgDt",
      Label: "New Contract Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtNewContractDt_PROPERTIES

      // END_USER_CODE-USER_txtNewContractDt_PROPERTIES
    },
    txtNewYear: {
      name: "txtNewYear",
      type: "TextBoxWidget",
      parent: "grpbxNewCY",
      Label: "New year #:",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNewYear_PROPERTIES

      // END_USER_CODE-USER_txtNewYear_PROPERTIES
    },
    txtOldYear: {
      name: "txtOldYear",
      type: "TextBoxWidget",
      parent: "grpbxNewCY",
      Label: "Old Year #:",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOldYear_PROPERTIES

      // END_USER_CODE-USER_txtOldYear_PROPERTIES
    },
    txtOptionalMail: {
      name: "txtOptionalMail",
      type: "TextBoxWidget",
      parent: "txtReqKCMORecon",
      Label: "Optional Email:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOptionalMail_PROPERTIES

      // END_USER_CODE-USER_txtOptionalMail_PROPERTIES
    },
    txtPct: {
      name: "txtPct",
      type: "TextBoxWidget",
      parent: "grpbxChgGrds",
      Label: "Percent:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPct_PROPERTIES

      // END_USER_CODE-USER_txtPct_PROPERTIES
    },
    txtReqKCMORecon: {
      name: "txtReqKCMORecon",
      type: "GroupBoxWidget",
      parent: "grpbxSpecFuncMisc",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_txtReqKCMORecon_PROPERTIES

      // END_USER_CODE-USER_txtReqKCMORecon_PROPERTIES
    },
    txtSampleWt: {
      name: "txtSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxChgGrds",
      Label: "Sample Wt:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSampleWt_PROPERTIES

      // END_USER_CODE-USER_txtSampleWt_PROPERTIES
    },
    txtSettlementNum: {
      name: "txtSettlementNum",
      type: "TextBoxWidget",
      parent: "grpbxChangeOleicInd",
      Label: "Settlement Num:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSettlementNum_PROPERTIES

      // END_USER_CODE-USER_txtSettlementNum_PROPERTIES
    },
    txtSettleNumChgGrds: {
      name: "txtSettleNumChgGrds",
      type: "TextBoxWidget",
      parent: "grpbxChgGrds",
      Label: "Settlement Num:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSettleNumChgGrds_PROPERTIES

      // END_USER_CODE-USER_txtSettleNumChgGrds_PROPERTIES
    },
    txtSettleNumChgUnload: {
      name: "txtSettleNumChgUnload",
      type: "TextBoxWidget",
      parent: "grpbxChange1007Unload",
      Label: "Settlement Num:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSettleNumChgUnload_PROPERTIES

      // END_USER_CODE-USER_txtSettleNumChgUnload_PROPERTIES
    },
    txtSettleNumChgVicam: {
      name: "txtSettleNumChgVicam",
      type: "TextBoxWidget",
      parent: "grpbxChangeVicamReading",
      Label: "Settlement Num:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSettleNumChgVicam_PROPERTIES

      // END_USER_CODE-USER_txtSettleNumChgVicam_PROPERTIES
    },
    txtShippingBuyingPoint: {
      name: "txtShippingBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxDeleteStockTransfer",
      Label: "Shipping Buying Point:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShippingBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtShippingBuyingPoint_PROPERTIES
    },
    txtTransferNum: {
      name: "txtTransferNum",
      type: "TextBoxWidget",
      parent: "grpbxDeleteStockTransfer",
      Label: "Transfer #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum_PROPERTIES
    },
    txtVendorNumrToReset: {
      name: "txtVendorNumrToReset",
      type: "TextBoxWidget",
      parent: "grpbxResetUpload211",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNumrToReset_PROPERTIES

      // END_USER_CODE-USER_txtVendorNumrToReset_PROPERTIES
    },
    txtVicamReading: {
      name: "txtVicamReading",
      type: "TextBoxWidget",
      parent: "grpbxChangeVicamReading",
      Label: "Reading:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVicamReading_PROPERTIES

      // END_USER_CODE-USER_txtVicamReading_PROPERTIES
    },
    txtWhse: {
      name: "txtWhse",
      type: "TextBoxWidget",
      parent: "grpbxChange1007Unload",
      Label: "Whse:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhse_PROPERTIES

      // END_USER_CODE-USER_txtWhse_PROPERTIES
    },
    grpbxSpecFuncMisc: {
      name: "grpbxSpecFuncMisc",
      type: "GroupBoxWidget",
      parent: "SpecFuncMisc",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSpecFuncMisc_PROPERTIES

      // END_USER_CODE-USER_grpbxSpecFuncMisc_PROPERTIES
    },
    commandcontainerwidget1: {
      name: "commandcontainerwidget1",
      type: "CommandContainerWidget",
      parent: "SpecFuncMisc",
      Label: "commandcontainerwidget1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_commandcontainerwidget1_PROPERTIES

      // END_USER_CODE-USER_commandcontainerwidget1_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    disable(thisObj, "txtPct");
    hidshowgrpbx();
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    Formload()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  const [mstrSyncOption, SetmstrSyncOption] = useState(" ");

  // START_USER_CODE-USER_METHODS


  //Formload
  const Formload = async () => {
    try {
      setLoading(true);
      let NewCropYear = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '001', 'U', null);
      if (NewCropYear[0].permission != 'Y') {
        hideradiobuttons(0);
      }
      let ReqarDownload = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '002', 'U', null);
      if (ReqarDownload[0].permission != 'Y') {
        hideradiobuttons(3);
      }
      let DeleteStockTransfer = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '003', 'U', null);
      if (DeleteStockTransfer[0].permission != 'Y') {
        hideradiobuttons(6);
      }
      let ResetSignedContract = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '004', 'U', null);
      if (ResetSignedContract[0].permission != 'Y') {
        hideradiobuttons(1);
      }
      let ReopenVoidClosedContracts = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '005', 'U', null);
      if (ReopenVoidClosedContracts[0].permission != 'Y') {
        
        hideradiobuttons(4);
      }
      let Change1007Unload = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '006', 'U', null);
      if (Change1007Unload[0].permission != 'Y') {
        hideradiobuttons(7);
      }
      let ChangeOleicInd = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '007', 'U', null);
      if (ChangeOleicInd[0].permission != 'Y') {
        hideradiobuttons(2);
      }
      let ChangeVicamReading = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '008', 'U', null);
      if (ChangeVicamReading[0].permission != 'Y') {
        hideradiobuttons(5);
      }
      let ChangeVAGrades = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '009', 'U', null);
      if (ChangeVAGrades[0].permission != 'Y') {
        hideradiobuttons(8);
      }
      let RequestKCMORecon = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '010', 'U', null);
      if (RequestKCMORecon[0].permission != 'Y') {
        hideradiobuttons(9);
      }
      let ResetChecktoVoid = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '011', 'U', null);
      if (ResetChecktoVoid[0].permission != 'Y') {
        hideradiobuttons(10);
      }
      let ChangeContractDate = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '012', 'U', null);
      if (ChangeContractDate[0].permission != 'Y') {
        hideradiobuttons(11);
      }
      let Resetupload211PDF = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '013', 'U', null);
      if (Resetupload211PDF[0].permission != 'Y') {
        hideradiobuttons(12);
      }
      let ResetuploadContrectPDF = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '014', 'U', null);
      if (ResetuploadContrectPDF[0].permission != 'Y') {
        hideradiobuttons(13);
      }
      let CopyFarmsfromBP = await ContractManagementService.RetrieveAccessPermissionDetails('PN0203', '015', 'U', null);
      if (CopyFarmsfromBP[0].permission != 'Y') {
        hideradiobuttons(14);
      }
      assignvalurforradioMisc(NewCropYear[0].permission,ReqarDownload[0].permission,DeleteStockTransfer[0].permission,ResetSignedContract[0].permission,
        ReopenVoidClosedContracts[0].permission,Change1007Unload[0].permission,ChangeOleicInd[0].permission,ChangeVicamReading[0].permission,ChangeVAGrades[0].permission,RequestKCMORecon[0].permission,ResetChecktoVoid[0].permission,ChangeContractDate[0].permission,Resetupload211PDF[0].permission,ResetuploadContrectPDF[0].permission,CopyFarmsfromBP[0].permission);
      setValue(thisObj, "radioMisc", getData(thisObj, "radioMisc"))
      hidshowcontrols(getData(thisObj, "radioMisc"),0);
      setData(thisObj, "mstrSyncOption", "NONE");
      if (getData(thisObj, "radioMisc") == "" || getData(thisObj, "radioMisc") == null || getData(thisObj, "radioMisc") == undefined)
        hide(thisObj, "btnUpdate")
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during Formload')
      }
    }
    finally {
      setLoading(false);
    }
  }
  const assignvalurforradioMisc = async (opt0, opt1, opt2, opt3, opt4, opt5, opt6, opt7, opt8, opt9, opt10, opt11, opt12, opt13, opt14) => {
    if (opt0 == "Y") {
      setData(thisObj, "radioMisc", "0");
      return false;
    }
    if (opt1 == "Y") {
      setData(thisObj, "radioMisc", "1");
      return false;
    }
    if (opt2 == "Y") {
      setData(thisObj, "radioMisc", "2");
      return false;
    }
    if (opt9 == "Y") {
      setData(thisObj, "radioMisc", "9")
      return false;
    }
    if (opt12 == "Y") {
      setData(thisObj, "radioMisc", "12")
      return false;
    }
    if (opt3 == "Y") {
      setData(thisObj, "radioMisc", "3")
      return false;
    }
    if (opt4 == "Y") {
      setData(thisObj, "radioMisc", "4")
      return false;
    }
    if (opt5 == "Y") {
      setData(thisObj, "radioMisc", "5")
      return false;
    }
    if (opt10 == "Y") {
      setData(thisObj, "radioMisc", "10")
      return false;
    }
    if (opt13 == "Y") {
      setData(thisObj, "radioMisc", "13")
      return false;
    }
    if (opt6 == "Y") {
      setData(thisObj, "radioMisc", "6")
      return false;
    }
    if (opt7 == "Y") {
      setData(thisObj, "radioMisc", "7")
      return false;
    }
    if (opt8 == "Y") {
      setData(thisObj, "radioMisc", "8")
      return false;
    }
    if (opt11 == "Y") {
      setData(thisObj, "radioMisc", "11")
      return false;
    }
    if (opt14 == "Y") {
      setData(thisObj, "radioMisc", "14")
      return false;
    }



  }
  //Hide/Show grpbx
  const hidshowgrpbx = async () => {
    try {
      hide(thisObj, "grpbxNewCY");
      hide(thisObj, "grpbxResetSignedContract");
      hide(thisObj, "grpbxChangeOleicInd");
      hide(thisObj, "grpbxChangeOleicInd");
      hide(thisObj, "grpbxRequestARDownload");
      hide(thisObj, "grpbxReopenVoidedClosedContract");
      hide(thisObj, "grpbxChangeVicamReading");
      hide(thisObj, "grpbxDeleteStockTransfer");
      hide(thisObj, "grpbxChange1007Unload");
      hide(thisObj, "grpbxChgGrds");
      hide(thisObj, "txtReqKCMORecon");
      hide(thisObj, "grpbxResetCheckToVoid");
      hide(thisObj, "grpbxChgDt");
      hide(thisObj, "grpbxResetUpload211");
      hide(thisObj, "grpbxResetUploadContract");
      hide(thisObj, "grpbxCopyFarms");
      hide(thisObj, "btnRequest");
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during hidshowgrpbx')
      }
    }
  }
  //Hide/Show Controls based on radiobutton change
  const hidshowcontrols = async (misctype,flg) => {
    try {
      document.getElementById("btnUpdate").innerText = "Update";
      if (misctype == "0") {
        show(thisObj, "grpbxNewCY");
        hide(thisObj, "radioNewCY");
        hide(thisObj, "txtBPForFarmSync");
        hide(thisObj, "lblOptional");
        // if(getData(thisObj, "radioNewCY")=="1")
        //setValue(thisObj,"radioNewCY","1"),
        //  radioNewCY();
        syncoptions(getData(thisObj, "mstrSyncOption"),flg);
        radioNewCY();
        if (getData(thisObj, "radioNewCY") == "1")
          setValue(thisObj, "radioNewCY", "1");
      }
      else if (misctype == "1") {
        show(thisObj, "grpbxRequestARDownload");
        document.getElementById("btnUpdate").innerText = "Request";
        radioReqAR(0);
        // disable(thisObj, "txtBuyPt");
        // disable(thisObj, "txtCollectionPt");
        // disable(thisObj, "txtArea");
      }
      else if (misctype == "2") {
        show(thisObj, "grpbxDeleteStockTransfer");
        bFillTransferTypeList();

      }
      else if (misctype == "3")
        show(thisObj, "grpbxResetSignedContract");
      else if (misctype == "4")
        show(thisObj, "grpbxReopenVoidedClosedContract");
      else if (misctype == "5")
        show(thisObj, "grpbxChange1007Unload");
      else if (misctype == "6") {
        show(thisObj, "grpbxChangeOleicInd");
        bFillddOleicIndList();
      }
      else if (misctype == "7") {
        show(thisObj, "grpbxChangeVicamReading");
        chkboxVicam();
        // disable(thisObj, "txtVicamReading");
      }
      else if (misctype == "8")
        show(thisObj, "grpbxChgGrds");
      else if (misctype == "9") {
        show(thisObj, "txtReqKCMORecon");
        document.getElementById("btnUpdate").innerText = "Request";
      }
      else if (misctype == "10")
        show(thisObj, "grpbxResetCheckToVoid");
      else if (misctype == "11")
        show(thisObj, "grpbxChgDt");
      else if (misctype == "12") {
        show(thisObj, "grpbxResetUpload211");
        setValue(thisObj, "radioReset", "1");
      }
      else if (misctype == "13")
        show(thisObj, "grpbxResetUploadContract");
      else if (misctype == "14")
        show(thisObj, "grpbxCopyFarms");
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during hidshowcontrols')
      }
    }
  }
  //implemented newcropyear onchange content
  const syncoptions = (sync_option,flg) => {
    try {
      switch (sync_option) {
        case "CREATE":
          document.getElementsByClassName("lblNewCY")[0].firstChild.firstChild.innerText = "Create New Crop Year:"
          setData(thisObj, "mstrSyncOption", "CREATE");
          setValue(thisObj, "radioNewCY", "0");
          hide(thisObj, "radioNewCY");
          hide(thisObj, "txtBPForFarmSync");
          hide(thisObj, "lblOptional");
          setValue(thisObj, "txtBPForFarmSync", "");
          break;
        case "SYNC":
          document.getElementsByClassName("lblNewCY")[0].firstChild.firstChild.innerText = "Sync Crop Year:"
          setData(thisObj, "mstrSyncOption", "SYNC");
          setValue(thisObj, "radioNewCY", "0");
          show(thisObj, "radioNewCY");
          hide(thisObj, "txtBPForFarmSync");
          hide(thisObj, "lblOptional");
          setValue(thisObj, "txtBPForFarmSync", "");
          break;
        case "SYNC_FARM":
          document.getElementsByClassName("lblNewCY")[0].firstChild.firstChild.innerText = "Sync Farm Data:"
          setData(thisObj, "mstrSyncOption", "SYNC_FARM");
          setValue(thisObj, "radioNewCY", "1");
          hide(thisObj, "radioNewCY");
          show(thisObj, "txtBPForFarmSync");
          show(thisObj, "lblOptional");
          setValue(thisObj, "txtBPForFarmSync", "");
          break;
        default:
          
          if(flg==0 )
          document.getElementsByClassName("lblNewCY")[0].firstChild.firstChild.innerText = "Create/Sync New Crop Year:"
          else
          document.getElementsByClassName("lblNewCY")[0].firstChild.firstChild.innerText = "New Crop Year:"
          setData(thisObj, "mstrSyncOption", "NONE");
          setValue(thisObj, "radioNewCY", "0");
          hide(thisObj, "radioNewCY");
          hide(thisObj, "txtBPForFarmSync");
          hide(thisObj, "lblOptional");
          setValue(thisObj, "txtBPForFarmSync", "");
          break;
      }
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during syncoptions')
      }
    }
  }
  const txtNewYearlostfocus = async () => {
    try {
      let response;
      let txtNewCropYear = getValue(thisObj, 'txtNewYear')
      if (txtNewCropYear != "" && txtNewCropYear != null && txtNewCropYear != undefined && isNaN(txtNewCropYear) == false && txtNewCropYear > 2003 && txtNewCropYear < 2100) {
        response = await SystemMaintenanceApplicationSupportService.RetrieveBeginYearInquiryDetails(txtNewCropYear);
      }
      if (response != "" && response != null && response != undefined) {
        setData(thisObj,"txtNewYearlostfocuschange",response);
        if (getData(thisObj, "mstrSyncOption") != response[0].sync_option) {
          syncoptions(response[0].sync_option.toUpperCase(),1);
          // switch (response[0].sync_option.toUpperCase()) {
          //   case "CREATE":
          //     document.getElementsByClassName("lblNewCY")[0].firstChild.firstChild.innerText="Create New Crop Year:"
          //     setData(thisObj, "mstrSyncOption", "CREATE");
          //     setValue(thisObj, "radioNewCY", "0");
          //     hide(thisObj, "radioNewCY");
          //     hide(thisObj, "txtBPForFarmSync");
          //     hide(thisObj, "lblOptional");
          //     setValue(thisObj, "txtBPForFarmSync", "");
          //     break;
          //   case "SYNC":
          //     document.getElementsByClassName("lblNewCY")[0].firstChild.firstChild.innerText="Sync Crop Year:"
          //     setData(thisObj, "mstrSyncOption", "SYNC");
          //     setValue(thisObj, "radioNewCY", "0");
          //     show(thisObj, "radioNewCY");
          //     hide(thisObj, "txtBPForFarmSync");
          //     hide(thisObj, "lblOptional");
          //     setValue(thisObj, "txtBPForFarmSync", "");
          //     break;
          //   case "SYNC_FARM":
          //     document.getElementsByClassName("lblNewCY")[0].firstChild.firstChild.innerText="Sync Farm Data:"
          //     setData(thisObj, "mstrSyncOption", "SYNC_FARM");
          //     setValue(thisObj, "radioNewCY", "1");
          //     hide(thisObj, "radioNewCY");
          //     show(thisObj, "txtBPForFarmSync");
          //     show(thisObj, "lblOptional");
          //     setValue(thisObj, "txtBPForFarmSync", "");
          //     break;
          //   default:
          //     document.getElementsByClassName("lblNewCY")[0].firstChild.firstChild.innerText="New Crop Year:"
          //     setData(thisObj, "mstrSyncOption", "NONE");
          //     setValue(thisObj, "radioNewCY", "0");
          //     hide(thisObj, "radioNewCY");
          //     hide(thisObj, "txtBPForFarmSync");
          //     hide(thisObj, "lblOptional");
          //     setValue(thisObj, "txtBPForFarmSync", "");
          //     break;
          // }
        }
      }
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during txtNewYearlostfocus')
      }
    }
  }
  //on clicking on radio buttons
  const onradioMiscChange = async () => {
    try {
      setLoading(true);
      hidshowgrpbx();
      let checkdata=getData(thisObj,"txtNewYearlostfocuschange");
      if (checkdata==undefined || checkdata==null || checkdata=="" || (checkdata!="SYNC" && checkdata!="SYNC_FARM" && checkdata!="CREATE"))
      hidshowcontrols(getValue(thisObj, "radioMisc"),0)
      else
      hidshowcontrols(getValue(thisObj, "radioMisc"),1)
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during Radio button change event')
      }
    }
    finally {
      setLoading(false);
    }
  }
  thisObj.onradioMiscChange = onradioMiscChange
  const hideradiobuttons = (indx) => {
    let id = "radioMisc" + indx
    document.getElementById(id).style.visibility = "hidden"
    document.getElementsByClassName("form-check-label")[indx].style.display = "none"
  }
  //implemented to select either buying point or area id or collection point in Request A/R Balances from the IBM
  const radioReqAR = (flg) => {
    try {
      if (flg != 0) {
        setValue(thisObj, "txtBuyPt", "");
        setValue(thisObj, "txtCollectionPt", "");
        setValue(thisObj, "txtArea", "");
      }
      else {
        disable(thisObj, "txtBuyPt");
        disable(thisObj, "txtCollectionPt");
        disable(thisObj, "txtArea");
      }
      if (getValue(thisObj, "radioReqAR") == "0" || getData(thisObj, "radioReqAR") == "0") {
        enable(thisObj, "txtBuyPt");
        disable(thisObj, "txtCollectionPt");
        disable(thisObj, "txtArea");
      }
      else if (getValue(thisObj, "radioReqAR") == "1" || getData(thisObj, "radioReqAR") == "1") {
        enable(thisObj, "txtCollectionPt");
        disable(thisObj, "txtBuyPt");
        disable(thisObj, "txtArea");
      }
      else if (getValue(thisObj, "radioReqAR") == "2" || getData(thisObj, "radioReqAR") == "2") {
        enable(thisObj, "txtArea");
        disable(thisObj, "txtBuyPt");
        disable(thisObj, "txtCollectionPt");
      }
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during radioReqAR')
      }
    }
  }

  const onradioReqARChange = async () => {
    try {
      setData(thisObj, "radioReqAR", getValue(thisObj, "radioReqAR"))
      radioReqAR(1);
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during onradioReqARChange')
      }
    }
  };
  thisObj.onradioReqARChange = onradioReqARChange
  //implemented to show or hide FarmSync buying point
  const radioNewCY = () => {
    try {
      if (getValue(thisObj, "radioNewCY") == "1" || getData(thisObj, "radioNewCY") == "1") {
        show(thisObj, "txtBPForFarmSync");
        show(thisObj, "lblOptional");
      }
      else {
        hide(thisObj, "txtBPForFarmSync");
        hide(thisObj, "lblOptional");
      }
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during radioNewCY')
      }
    }
  }
  const onradioNewCYChange = async () => {
    try {
      setData(thisObj, "radioNewCY", getValue(thisObj, "radioNewCY"))
      radioNewCY();
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during onradioNewCYChange')
      }
    }
    // if (getValue(thisObj, "radioNewCY") == "1") {
    //   show(thisObj, "txtBPForFarmSync");
    //   show(thisObj, "lblOptional");
    // }
    // else {
    //   hide(thisObj, "txtBPForFarmSync");
    //   hide(thisObj, "lblOptional");
    // }
  }
  thisObj.onradioNewCYChange = onradioNewCYChange

  //implemented to update input controls data on update button
  const onbtnUpdateClick = async () => {
    try {
      setLoading(true);
      let response;
      let data;
      let message;
      if (getValue(thisObj, 'radioMisc') == '0') {
        
        let txtOldYear = getValue(thisObj, 'txtOldYear')
        let txtNewYear = getValue(thisObj, 'txtNewYear')

        if (txtOldYear == undefined || txtOldYear == null || txtOldYear == '') {
          showMessage(thisObj, "Please enter a value for Old Crop Year")
          document.getElementById("txtOldYear").focus();
          return false;
        }
        else if (isNaN(txtOldYear) == true && txtNewYear != undefined && txtNewYear != null && txtNewYear != '') {
          showMessage(thisObj, "Please enter a valid value for Old Crop Year")
          document.getElementById("txtOldYear").focus();
          return false;
        }
        else if (txtOldYear < 2013 || txtOldYear > 2100) {
          showMessage(thisObj, "Please enter a valid value for Old Crop Year")
          document.getElementById("txtOldYear").focus();
          return false;
        }
        if (txtNewYear == undefined || txtNewYear == null || txtNewYear == '') {
          showMessage(thisObj, "Please enter a value for New Crop Year")
          document.getElementById("txtNewYear").focus();
          return false;
        }
        else if (isNaN(txtNewYear) == true && txtNewYear != undefined && txtNewYear != null && txtNewYear != '') {
          showMessage(thisObj, "Please enter a valid value for New Crop Year")
          document.getElementById("txtNewYear").focus();
          return false;
        }
        else if (txtNewYear < 2013 || txtNewYear > 2100) {
          showMessage(thisObj, "Please enter a valid value for New Crop Year")
          document.getElementById("txtNewYear").focus();
          return false;
        }
        if (txtOldYear == txtNewYear) {
          showMessage(thisObj, "Old Crop Year and New Crop Year can not be the same value. Please check Year")
          document.getElementById("txtOldYear").focus();
          return false;
        }
        else if (txtOldYear > txtNewYear) {
          showMessage(thisObj, "Old Crop Year must be before New Crop Year. Please check Year")
          document.getElementById("txtOldYear").focus();
          return false;
        }
        if (getData(thisObj, "mstrSyncOption") == "NONE")
        {
        txtNewYearlostfocus();
        return false;
        }
        if (getData(thisObj, "mstrSyncOption") != "CREATE" && getData(thisObj, "mstrSyncOption") != "SYNC" && getData(thisObj, "mstrSyncOption") != "SYNC_FARM") {
          showMessage(thisObj, "There is a data problem with the New Crop Year. Please check Year");
          return false;
        }
        if (getValue(thisObj, "radioNewCY") == 1 && (getValue(thisObj, "txtBPForFarmSync") == "" || getValue(thisObj, "txtBPForFarmSync") == null || getValue(thisObj, "txtBPForFarmSync") == undefined)) {
          message = confirm("If you leave the Location blank then the Farms at All Locations will be updated! Are you sure you wish to Sync ALL Farms?");
          if (message == false) {
            showMessage(thisObj, "Enter the Location where you want to sync the Farms.");
            return false;
          }
        }
        let action_request = "";
        let buy_pt_id = "";
        if (getData(thisObj, "mstrSyncOption") == "CREATE")
          action_request = "CREATE";
        else if (getData(thisObj, "mstrSyncOption") == "SYNC" && getValue(thisObj, "radioNewCY") == 0) {
          action_request = "SYNC";
        }
        if (getValue(thisObj, "radioNewCY") == 1) {
          action_request = "SYNC_FARM";
          buy_pt_id = getValue(thisObj, "txtBPForFarmSync");
        }
        data = {
          "action_request": action_request,
          "buy_pt_id": buy_pt_id,
          "old_crop_year": txtOldYear,
          "new_crop_year": txtNewYear,
          "user_id": userid
        }
        response = await SystemMaintenanceApplicationSupportService.UpdateBeginYear(data);
        if (response?.status == 200) {
          showMessage(thisObj, "New Year " + txtNewYear + " was successfully updated.", true)
          setData(thisObj, "mstrSyncOption", "NONE");
          txtNewYearlostfocus();
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }
      }
      else if (getValue(thisObj, 'radioMisc') == "1") {
        let PARM_NAME_03 = "";
        let PARM_NAME_04 = "";
        let PARM_VALUE_03 = "";
        let PARM_VALUE_04 = "";
        let txtCropYear = getValue(thisObj, "txtCropYear");
        let txtBuyPt = getValue(thisObj, "txtBuyPt");
        let txtCollectionPt = getValue(thisObj, "txtCollectionPt");
        let txtArea = getValue(thisObj, "txtArea");

        if (txtCropYear == undefined || txtCropYear == null || txtCropYear == '') {
          showMessage(thisObj, "Please enter a value for Crop Year.")
          document.getElementById("txtCropYear").focus();
          return false;
        }
        else if (isNaN(txtCropYear)) {
          showMessage(thisObj, "Please enter a valid value for Crop Year.")
          document.getElementById("txtCropYear").focus();
          return false;
        }
        else if (txtCropYear < 2013 || txtCropYear > 2100) {
          showMessage(thisObj, "Please enter a valid value for Crop Year.")
          document.getElementById("txtNewYear").focus();
          return false;
        }
        if (txtCropYear != cropyear) {
          message = confirm("The Crop Year requested is not the same as the Crop Year you are currently have set. Are you sure you wish to update the " + txtCropYear + " crop year balances?");
          if (message == false) {
            showMessage(thisObj, "Enter the Crop Year you want to update.");
            document.getElementById("txtNewYear").focus();
            return false;
          }
        }
        PARM_NAME_03 = "REQ_CROP_YEAR";
        PARM_VALUE_03 = txtCropYear;
        let radioReqAR = getValue(thisObj, 'radioReqAR');
        if (radioReqAR == "" || radioReqAR == undefined || radioReqAR == null) {
          showMessage(thisObj, "You must select to enter either a Buying Point ID, a Collection Point ID, or an Area ID.");
          return false;
        }

        if (radioReqAR == 0) {
          if (txtBuyPt == "" || txtBuyPt == null || txtBuyPt == undefined) {
            showMessage(thisObj, "You must enter the Buying Point ID.")
            document.getElementById("txtBuyPt").focus();
            return false;
          }
          else {
            PARM_NAME_04 = "REQ_BUY_PT_ID";
            PARM_VALUE_04 = txtBuyPt.toUpperCase();
          }
        }
        else if (radioReqAR == 1) {
          if (txtCollectionPt == "" || txtCollectionPt == null || txtCollectionPt == undefined) {
            showMessage(thisObj, "You must enter either the Collection Point ID.")
            document.getElementById("txtCollectionPt").focus();
            return false;
          }
          else {
            PARM_NAME_04 = "REQ_COLL_PT_ID";
            PARM_VALUE_04 = txtCollectionPt.toUpperCase();
          }
        }
        else if (radioReqAR == 2) {
          if (txtArea == "" || txtArea == null || txtArea == undefined) {
            showMessage(thisObj, "You must enter the an Area ID.")
            document.getElementById("txtArea").focus();
            return false;
          }
          else {
            PARM_NAME_04 = "REQ_AREA_ID";
            PARM_VALUE_04 = txtArea.toUpperCase();
          }
        }

        data = {
          "user_id": userid,
          "comp_id": compid,
          "crop_year": cropyear,
          "parm_name_03": PARM_NAME_03,
          "parm_value_03": PARM_VALUE_03,
          "parm_name_04": PARM_NAME_04,
          "parm_value_04": PARM_VALUE_04
        }
        response = await AccountPayment.CreateBatchJobRequestDetails("P38977", data);
        if (response?.status == 200) {
          showMessage(thisObj, "Request for A/R Balances to be downloaded from the IBM has been submitted.", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }
      }
      else if (getValue(thisObj, 'radioMisc') == "2") {
        let txtShippingBuyingPoint = getValue(thisObj, 'txtShippingBuyingPoint')
        let txtTransferNum = getValue(thisObj, 'txtTransferNum')
        let ddTransferType = getValue(thisObj, 'ddTransferType')
        if (txtShippingBuyingPoint == undefined || txtShippingBuyingPoint == null || txtShippingBuyingPoint == '' || txtShippingBuyingPoint.length != 3) {
          showMessage(thisObj, "Please enter a valid Shipping Buying Point.")
          document.getElementById("txtShippingBuyingPoint").focus();
          return false;
        }
        else if (txtTransferNum == undefined || txtTransferNum == null || txtTransferNum == '' || txtTransferNum.length > 6) {
          showMessage(thisObj, "Please enter a valid Transfer Number.")
          document.getElementById("txtTransferNum").focus();
          return false;
        }
        else if (ddTransferType == undefined || ddTransferType == null || ddTransferType == '') {
          showMessage(thisObj, "Please select a Transfer Type.")
          document.getElementById("txtTransferNum").focus();
          return false;
        }
        response = await SystemMaintenanceApplicationSupportService.RemoveTransferHeader(txtShippingBuyingPoint, txtTransferNum, ddTransferType);
        if (response?.status == 200) {
          showMessage(thisObj, "Transfer #:" + txtTransferNum + " has been successfully deleted.", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }
      }
      else if (getValue(thisObj, 'radioMisc') == "3") {
        let txtContractNum = getValue(thisObj, "txtContractNum");
        if (txtContractNum == undefined || txtContractNum == null || txtContractNum == '' || txtContractNum.length > 6 || isNaN(txtContractNum)) {
          showMessage(thisObj, "Please enter a valid Contract Number.")
          document.getElementById("txtContractNum").focus();
          return false;
        }
        response = await SystemMaintenanceApplicationSupportService.UpdateResetContractStatus(compid, cropyear, txtContractNum, userid);
        if (response?.status == 200) {
          showMessage(thisObj, "Contract #:" + txtContractNum + " has been successfully reset to pending status.", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }
      }
      else if (getValue(thisObj, 'radioMisc') == "4") {
        let txtContractNumToReopen = getValue(thisObj, "txtContractNumToReopen");
        if (txtContractNumToReopen == undefined || txtContractNumToReopen == null || txtContractNumToReopen == '' || txtContractNumToReopen.length > 6 || isNaN(txtContractNumToReopen)) {
          showMessage(thisObj, "Please enter a valid Contract Number.")
          document.getElementById("txtContractNumToReopen").focus();
          return false;
        }
        response = await SystemMaintenanceApplicationSupportService.Updatereopencontract(compid, cropyear, txtContractNumToReopen, userid);
        if (response?.status == 200) {
          showMessage(thisObj, "Contract #:" + txtContractNumToReopen + " has been successfully Reopend.", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }

      }
      else if (getValue(thisObj, 'radioMisc') == "5") {
        let txtBuyPtIdChgUnload = getValue(thisObj, 'txtBuyPtIdChgUnload');
        let txtSettleNumChgUnload = getValue(thisObj, 'txtSettleNumChgUnload');
        let txtWhse = getValue(thisObj, 'txtWhse');
        let txtBin = getValue(thisObj, 'txtBin');
        if (txtBuyPtIdChgUnload == undefined || txtBuyPtIdChgUnload == null || txtBuyPtIdChgUnload == '') {
          showMessage(thisObj, "Buying point is required")
          document.getElementById("txtBuyPtIdChgUnload").focus();
          return false;
        }
        else if (txtSettleNumChgUnload == undefined || txtSettleNumChgUnload == null || txtSettleNumChgUnload == '') {
          showMessage(thisObj, "Settlement number is required")
          document.getElementById("txtSettleNumChgUnload").focus();
          return false;
        }
        else if (txtWhse == undefined || txtWhse == null || txtWhse == '') {
          showMessage(thisObj, "Warehouse number is required")
          document.getElementById("txtWhse").focus();
          return false;
        }
        else if (txtBin == undefined || txtBin == null || txtBin == '') {
          showMessage(thisObj, "Bin number is required")
          document.getElementById("txtBin").focus();
          return false;
        }
        response = await SystemMaintenanceApplicationSupportService.Update1007unload(txtBuyPtIdChgUnload, txtSettleNumChgUnload, txtWhse, txtBin, userid);
        if (response?.status == 200) {
          showMessage(thisObj, "Warehouse and bin numbers have been successfully changed.", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }
      }
      else if (getValue(thisObj, 'radioMisc') == "6") {
        let txtBuyPtId = getValue(thisObj, 'txtBuyPtId');
        let txtSettlementNum = getValue(thisObj, 'txtSettlementNum');
        if (txtBuyPtId == undefined || txtBuyPtId == null || txtBuyPtId == '') {
          showMessage(thisObj, "Please enter a valid Buying Point ID.")
          document.getElementById("txtBuyPtId").focus();
          return false;
        }
        else if (txtSettlementNum == undefined || txtSettlementNum == null || txtSettlementNum == '') {
          showMessage(thisObj, "Please enter a valid Settlement Number.")
          document.getElementById("txtSettlementNum").focus();
          return false;
        }
        response = await SystemMaintenanceApplicationSupportService.UpdateOleicIndicator(txtBuyPtId, txtSettlementNum, getValue(thisObj, 'ddOleicInd'), userid);
        if (response?.status == 200) {
          showMessage(thisObj, "Oleic Indicator for Settlement - " + txtSettlementNum + " has been successfully Changed.", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }
      }
      else if (getValue(thisObj, 'radioMisc') == "7") {
        let txtBuyPtIdChgVicam = getValue(thisObj, 'txtBuyPtIdChgVicam');
        let txtSettleNumChgVicam = getValue(thisObj, 'txtSettleNumChgVicam');
        let txtVicamReading = getValue(thisObj, 'txtVicamReading');
        let chkboxVicam = getValue(thisObj, 'chkboxVicam');
        let vicamind = 'N';
        if (chkboxVicam == true)
          vicamind = "Y";
        if (txtBuyPtIdChgVicam == undefined || txtBuyPtIdChgVicam == null || txtBuyPtIdChgVicam == '') {
          showMessage(thisObj, "Please enter a valid Buying Point ID.")
          document.getElementById("txtBuyPtIdChgVicam").focus();
          return false;
        }
        else if (txtSettleNumChgVicam == undefined || txtSettleNumChgVicam == null || txtSettleNumChgVicam == '' || isNaN(txtSettleNumChgVicam)) {
          showMessage(thisObj, "Please enter a valid Settlement Number.")
          document.getElementById("txtSettleNumChgVicam").focus();
          return false;
        }
        else if (chkboxVicam == true && (txtVicamReading == undefined || txtVicamReading == null || txtVicamReading == '' || isNaN(txtVicamReading))) {
          showMessage(thisObj, "Please enter a valid Vicam reading.")
          document.getElementById("txtSettleNumChgVicam").focus();
          return false;
        }
        response = await SystemMaintenanceApplicationSupportService.UpdateVicamPpb(txtBuyPtIdChgVicam, txtSettleNumChgVicam, vicamind, txtVicamReading, userid);
        if (response?.status == 200) {
          showMessage(thisObj, "Vicam Reading for Settlement - " + txtSettleNumChgVicam + " has been successfully changed.", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }
      }
      else if (getValue(thisObj, 'radioMisc') == "8") {
        let txtBuyPtIdChgGrds = getValue(thisObj, 'txtBuyPtIdChgGrds');
        let txtSettleNumChgGrds = getValue(thisObj, 'txtSettleNumChgGrds');
        let txtSampleWt = getValue(thisObj, 'txtSampleWt');
        let txtGr = getValue(thisObj, 'txtGr');
        let txtPct = getValue(thisObj, 'txtPct');
        if (txtBuyPtIdChgGrds == undefined || txtBuyPtIdChgGrds == null || txtBuyPtIdChgGrds == '') {
          showMessage(thisObj, "Please enter a valid Buying Point ID.")
          document.getElementById("txtBuyPtIdChgGrds").focus();
          return false;
        }
        else if (txtSettleNumChgGrds == undefined || txtSettleNumChgGrds == null || txtSettleNumChgGrds == '' || isNaN(txtSettleNumChgGrds)) {
          showMessage(thisObj, "Please enter a valid Settlement Number.")
          document.getElementById("txtSettleNumChgGrds").focus();
          return false;
        }
        else if (txtSampleWt == undefined || txtSampleWt == null || txtSampleWt == '' || isNaN(txtSampleWt)) {
          showMessage(thisObj, "Please enter a valid Virginia Cr/Br Sample Weight.")
          document.getElementById("txtSampleWt").focus();
          return false;
        }
        else if (txtGr == undefined || txtGr == null || txtGr == '' || isNaN(txtGr)) {
          showMessage(thisObj, "Please enter a valid Virginia Cr/Br Grams Reading.")
          document.getElementById("txtGr").focus();
          return false;
        }
        else if (txtPct == undefined || txtPct == null || txtPct == '' || isNaN(txtPct)) {
          showMessage(thisObj, "Please enter a valid Virginia Cr/Br Percent Grade.")
          document.getElementById("txtPct").focus();
          return false;
        }
        else if (txtSampleWt < 0 || txtSampleWt > 99999) {
          showMessage(thisObj, "Virginia Cr/Br Sample Weight out of valid range.")
          document.getElementById("txtSampleWt").focus();
          return false;
        }
        else if (Number(txtGr) < 0 || Number(txtGr) > Number(txtSampleWt)) {
          showMessage(thisObj, "Virginia Cr/Br Grams Reading out of valid range.")
          document.getElementById("txtSampleWt").focus();
          return false;
        }
        else if ( txtPct < 0 || txtPct > 100) {
          showMessage(thisObj, "Virginia Cr/Br Percent Grade out of valid range.")
          document.getElementById("txtPct").focus();
          return false;
        }
        response = await SystemMaintenanceApplicationSupportService.UpdateVaCrBrGrades(txtBuyPtIdChgGrds, txtSettleNumChgGrds, txtSampleWt, txtGr, txtPct);
        if (response?.status == 200) {
          showMessage(thisObj, "Virgina Cr/Br Grades for Settlement - " + txtSettleNumChgGrds + " has been successfully changed.", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }
      }
      else if (getValue(thisObj, 'radioMisc') == "9") {
        let txtCropYearKCMO = getValue(thisObj, 'txtCropYearKCMO');
        if (txtCropYearKCMO == undefined || txtCropYearKCMO == null || txtCropYearKCMO == '') {
          showMessage(thisObj, "Please enter a value for Crop Year")
          document.getElementById("txtCropYearKCMO").focus();
          return false;
        }
        else if (isNaN(txtCropYearKCMO)) {
          showMessage(thisObj, "Please enter a valid value for Crop Year")
          document.getElementById("txtCropYearKCMO").focus();
          return false;
        }
        else if (txtCropYearKCMO < 2014 || txtCropYearKCMO > 2100) {
          showMessage(thisObj, "Please enter a valid value for Crop Year")
          document.getElementById("txtCropYearKCMO").focus();
          return false;
        }
        message = confirm("Are you sure you want to send the " + txtCropYearKCMO + " Year End Recon data to Kansas City, MO?");
        if (message == false) {
          return false;
        }
        let strtxtOptionalMail ="";
        if(getValue(thisObj, "txtOptionalMail")!=null && getValue(thisObj, "txtOptionalMail")!="" && getValue(thisObj, "txtOptionalMail")!=undefined)
         strtxtOptionalMail = Buffer.from(getValue(thisObj, "txtOptionalMail")).toString('base64');
        data = {
          "user_id": userid,
          "comp_id": compid,
          "crop_year": cropyear,
          "PARM_NAME_02": "CROP_YEAR",
          "PARM_VALUE_02": txtCropYearKCMO,
          "PARM_NAME_03": "CONFIRM_EMAIL",
          "PARM_VALUE_03": strtxtOptionalMail,
          "PARM_NAME_04": ""
        }
        response = await AccountPayment.CreateBatchJobRequestDetails("SendKCMOReconFile", data);
        if (response?.status == 200) {
          showMessage(thisObj, "The " + txtCropYearKCMO + " Year End Recon data will be resent to Kansas City, MO tonight along with the regular nightly file transmission.", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }

      }
      else if (getValue(thisObj, 'radioMisc') == "10") {
        let txtCheckNumToVoid = getValue(thisObj, 'txtCheckNumToVoid');
        if (txtCheckNumToVoid == undefined || txtCheckNumToVoid == null || txtCheckNumToVoid == '' || txtCheckNumToVoid.length > 6 || isNaN(txtCheckNumToVoid)) {
          showMessage(thisObj, "Please enter a valid Check Number.")
          document.getElementById("txtCheckNumToVoid").focus();
          return false;
        }
        response = await SystemMaintenanceApplicationSupportService.UpdateResetVoidCheck(txtCheckNumToVoid);
        if (response?.status == 200) {
          showMessage(thisObj, "Check #:" + txtCheckNumToVoid + " has been successfully reset to VOID status.", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }

      }
      else if (getValue(thisObj, 'radioMisc') == "11") {
        let txtContractNumChgDt = getValue(thisObj, 'txtContractNumChgDt');
        let txtNewContractDt = getValue(thisObj, 'txtNewContractDt');
        let test = isDate(txtNewContractDt);
        if (txtContractNumChgDt == undefined || txtContractNumChgDt == null || txtContractNumChgDt == '' || txtContractNumChgDt.length > 6 || isNaN(txtContractNumChgDt)) {
          showMessage(thisObj, "Please enter a valid Contract Number.")
          document.getElementById("txtContractNumChgDt").focus();
          return false;
        }
        else if (moment.isDate(txtNewContractDt) || txtNewContractDt == null || txtNewContractDt == undefined || txtNewContractDt == '') {
          showMessage(thisObj, "Please enter a valid Contract Date.");
          document.getElementById("txtContractNumChgDt").focus();
          return false;
        }
        response = await SystemMaintenanceApplicationSupportService.UpdateContractDate(txtContractNumChgDt, moment(String(txtNewContractDt)).format('MM/DD/YYYY'));
        if (response?.status == 200) {
          showMessage(thisObj, "The Date on Contract #:" + txtContractNumChgDt + " has been successfully changed to " + moment(String(txtNewContractDt)).format('MM/DD/YYYY') + ".", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }
      }
      else if (getValue(thisObj, 'radioMisc') == "12") {
        let txtVendorNumrToReset = getValue(thisObj, 'txtVendorNumrToReset')
        let radioReset = "";
        if (txtVendorNumrToReset == undefined || txtVendorNumrToReset == null || txtVendorNumrToReset == '' || txtVendorNumrToReset.length != 6 || isNaN(txtVendorNumrToReset)) {
          showMessage(thisObj, "Please enter a valid Vendor Number.")
          document.getElementById("txtVendorNumrToReset").focus();
          return false;
        }
        if (getValue(thisObj, "radioReset") == "1") {
          radioReset = "GPC";
        }
        else {
          radioReset = "CON";
        }
        response = await SystemMaintenanceApplicationSupportService.Update211Scan(txtVendorNumrToReset, radioReset);
        if (response?.status == 200) {
          showMessage(thisObj, "The 211 Form for Vendor #:" + txtVendorNumrToReset + " has been successfully reset to be uploaded again.", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }

      }
      else if (getValue(thisObj, 'radioMisc') == "13") {
        let txtContractNumToReset = getValue(thisObj, 'txtContractNumToReset');
        if (txtContractNumToReset == undefined || txtContractNumToReset == null || txtContractNumToReset == '' || txtContractNumToReset.length > 6 || isNaN(txtContractNumToReset)) {
          showMessage(thisObj, "Please enter a valid Contract Number.")
          document.getElementById("txtContractNumToReset").focus();
          return false;
        }
        response = await SystemMaintenanceApplicationSupportService.UpdateContractImage(txtContractNumToReset);
        if (response?.status == 200) {
          showMessage(thisObj, "The image for Contract #:" + txtContractNumToReset + " has been successfully reset to be uploaded again.", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }
      }
      else if (getValue(thisObj, 'radioMisc') == "14") {
        let txtBuyPtCopyFrom = getValue(thisObj, 'txtBuyPtCopyFrom');
        let txtBuyPtIdCopyTo = getValue(thisObj, 'txtBuyPtIdCopyTo');
        if (txtBuyPtCopyFrom == undefined || txtBuyPtCopyFrom == null || txtBuyPtCopyFrom == '' || txtBuyPtCopyFrom.length != 3) {
          showMessage(thisObj, "Please enter a valid from Buying Point.")
          document.getElementById("txtContractNumToReset").focus();
          return false;
        }
        else if (txtBuyPtIdCopyTo == undefined || txtBuyPtIdCopyTo == null || txtBuyPtIdCopyTo == '' || txtBuyPtIdCopyTo.length != 3) {
          showMessage(thisObj, "Please enter a valid to Buying Point.")
          document.getElementById("txtContractNumToReset").focus();
          return false;
        }
        response = await SystemMaintenanceApplicationSupportService.UpdateFarmControlCopy(txtBuyPtCopyFrom, txtBuyPtIdCopyTo);
        if (response?.status == 200) {
          showMessage(thisObj, "The farms from buying point " + txtBuyPtCopyFrom.toUpperCase() + " have been copied to buying point " + txtBuyPtIdCopyTo.toUpperCase() + ".", true)
        }
        else {
          showMessage(thisObj, response.message);
          return false;
        }
      }
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnUpdate click event"
        );
      }
      return false;

    }
    finally {
      setLoading(false);
    }

  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick;
  //implemented to close the popup
  const onbtnCancelClick = () => {
    try {
      setData(thisObj, "mstrSyncOption", "NONE");
      setData(thisObj, "chkboxVicam", "");
      setData(thisObj, "radioReqAR", "");
      setData(thisObj, "ddOleicInd", "");
      document.getElementById("SystemMaintenanceApplicationSupport_SpecFuncMiscPopUp").childNodes[0].click();
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during onbtnCancelClick')
      }
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;
  //implemented to bind the transfer type data to the drodown
  const bFillTransferTypeList = async () => {
    try {
      let js = []
      js.push({ key: '', description: '' })
      js.push({ key: 'ST', description: 'Stock Transfer' })
      js.push({ key: 'FF', description: 'Farm Freight' })
      js.push({ key: 'NL', description: 'No Load' })

      setValue(thisObj, 'ddTransferType', js.at(0).key);

      thisObj.setState(current => {
        return {
          ...current,
          ddTransferType: {
            ...state["ddTransferType"],
            valueList: js
          }
        }
      })
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during bFillTransferTypeList')
      }
    }

  }
  //implemented to bind oliec data to the dropdown
  const onddOleicIndChange = () => {
    try {
      setData(thisObj, "ddOleicInd", getValue(thisObj, "ddOleicInd"));
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during onddOleicIndChange')
      }
    }

  }
  thisObj.onddOleicIndChange = onddOleicIndChange
  const bFillddOleicIndList = async () => {
    try {
      let js = []
      js.push({ key: '', description: '' })
      js.push({ key: 'M', description: 'M' })
      js.push({ key: 'H', description: 'H' })
      if (getData(thisObj, "ddOleicInd") != "" && getData(thisObj, "ddOleicInd") != null && getData(thisObj, "ddOleicInd") != undefined)
        setValue(thisObj, 'ddOleicInd', getData(thisObj, "ddOleicInd"));
      else
        setValue(thisObj, 'ddOleicInd', js.at(0).key);
      thisObj.setState(current => {
        return {
          ...current,
          ddOleicInd: {
            ...state["ddOleicInd"],
            valueList: js
          }
        }
      })
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during bFillddOleicIndList')
      }
    }

  }
  //validating the settlement number value
  const ontxtSettlementNumChange = () => {
    try {
      let SettlementNum = getValue(thisObj, 'txtSettlementNum')
      setValue(thisObj, "txtSettlementNum", validatetextbox(SettlementNum));
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtSettlementNumChange')
      }
    }
  };
  thisObj.ontxtSettlementNumChange = ontxtSettlementNumChange
  //changing buying point value to upper case
  const ontxtBuyPtIdChange = () => {
    try {
      let PayAmountValue = getValue(thisObj, 'txtBuyPtId')
      setValue(thisObj, "txtBuyPtId", PayAmountValue.toUpperCase());
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtBuyPtIdChange')
      }
    }
  };
  thisObj.ontxtBuyPtIdChange = ontxtBuyPtIdChange
  //validating the old year  value
  const ontxtOldYearChange = () => {
    try {
      let OldYear = getValue(thisObj, 'txtOldYear')
      setValue(thisObj, "txtOldYear", validatetextbox(OldYear));
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtOldYearChange')
      }
    }
  };
  thisObj.ontxtOldYearChange = ontxtOldYearChange
  //validating the new year  value
  const ontxtNewYearChange = () => {
    try {
      let NewYear = getValue(thisObj, 'txtNewYear')
      setValue(thisObj, "txtNewYear", validatetextbox(NewYear));
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtOldYearChange');
      }
    }
  };
  thisObj.ontxtNewYearChange = ontxtNewYearChange
  //validating the vendor number value
  const ontxtVendorNumrToResetChange = () => {
    try {
      let VendorNumrToReset = getValue(thisObj, 'txtVendorNumrToReset')
      setValue(thisObj, "txtVendorNumrToReset", validatetextbox1(VendorNumrToReset));
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtVendorNumrToResetChange');
      }
    }
  };
  thisObj.ontxtVendorNumrToResetChange = ontxtVendorNumrToResetChange
  //validating the crop year  value
  const ontxtCropYearChange = () => {
    try {
      let CropYear = getValue(thisObj, 'txtCropYear')
      setValue(thisObj, "txtCropYear", validatetextbox(CropYear));
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtCropYearChange');
      }
    }
  };
  thisObj.ontxtCropYearChange = ontxtCropYearChange
  //validating the  contract number value
  const ontxtContractNumChgDtChange = () => {
    try {
      let ContractNumChgDt = getValue(thisObj, 'txtContractNumChgDt')
      setValue(thisObj, "txtContractNumChgDt", validatetextbox(ContractNumChgDt));
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtContractNumChgDtChange');
      }
    }
  }
  thisObj.ontxtContractNumChgDtChange = ontxtContractNumChgDtChange
  //validating the settlement number value
  const ontxtSettleNumChgGrdsChange = () => {
    try {
      let SettleNumChgGrds = getValue(thisObj, 'txtSettleNumChgGrds')
      setValue(thisObj, "txtSettleNumChgGrds", validatetextbox(SettleNumChgGrds));
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtSettleNumChgGrdsChange');
      }
    }
  }
  thisObj.ontxtSettleNumChgGrdsChange = ontxtSettleNumChgGrdsChange
  //validating sample weight
  const ontxtSampleWtBlur = () => {
    try {
      let SampleWt = getValue(thisObj, "txtSampleWt");

      let grams = getValue(thisObj, "txtGr");
      if (!isNaN(SampleWt) && SampleWt != "" && SampleWt != undefined) {
        if (SampleWt.length < 6) {
          SampleWt = Number(SampleWt).toFixed(1);
          if (SampleWt.indexOf(".") == 5)
            SampleWt = SampleWt.substring(0, 6)
        }
        setValue(thisObj, "txtSampleWt", SampleWt);
      }
      else {
        setValue(thisObj, "txtSampleWt", "");
      }
      if (!isNaN(grams) && grams != "" && grams != undefined) {
        if (SampleWt > 0) {
          let calc = Number(grams * 100 / SampleWt).toFixed(2);
          if (calc > 6)
            calc = calc.substring(0, 6)
          setValue(thisObj, "txtPct", calc);
        }
      }
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtSampleWtBlur');
      }
    }
  }
  thisObj.ontxtSampleWtBlur = ontxtSampleWtBlur
  //checking grades
  const ontxtGrBlur = () => {
    try {
      let SampleWt = getValue(thisObj, "txtSampleWt");
      let grams = getValue(thisObj, "txtGr");
      if (!isNaN(grams) && grams != "" && grams != undefined) {
        if (grams.length < 6) {
          grams = Number(grams).toFixed(1);
          if (grams.indexOf(".") == 5)
            grams = grams.substring(0, 6)
        }
        setValue(thisObj, "txtGr", grams);
      }
      else {
        setValue(thisObj, "txtGr", "");
      }
      if (!isNaN(SampleWt) && SampleWt != "" && SampleWt != undefined) {
        if (SampleWt > 0 && grams != undefined) {
          let calc = Number(grams * 100 / SampleWt).toFixed(2);
          let test = calc.indexOf(".")
          if (calc > 6)
            calc = calc.substring(0, 6)
          setValue(thisObj, "txtPct", calc);
        }
      }
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtGrBlur');
      }
    }
  }
  thisObj.ontxtGrBlur = ontxtGrBlur
  //validating the year value
  const ontxtNewYearBlur = async () => {
    try {
      //setData(thisObj,"txtNewYearBlur",1);
      txtNewYearlostfocus();
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtNewYearBlur');
      }
    }
    // let response;
    // let txtNewCropYear = getValue(thisObj, 'txtNewYear')
    // if (txtNewCropYear != "" && txtNewCropYear != null && txtNewCropYear != undefined && isNaN(txtNewCropYear) == false && txtNewCropYear > 2003 && txtNewCropYear < 2100) {
    //   response = await SystemMaintenanceApplicationSupportService.RetrieveBeginYearInquiryDetails(txtNewCropYear);
    // }
    // if (response != "" && response != null && response != undefined) {
    //   if (getData(thisObj, "mstrSyncOption") != response[0].sync_option) {
    //     switch (response[0].sync_option.toUpperCase()) {
    //       case "CREATE":
    //         setValue(thisObj, "lblNewCY", "Create New Crop Year:");
    //         setData(thisObj, "mstrSyncOption", "CREATE");
    //         setValue(thisObj, "radioNewCY", "0");
    //         hide(thisObj, "radioNewCY");
    //         hide(thisObj, "txtBPForFarmSync");
    //         hide(thisObj, "lblOptional");
    //         setValue(thisObj, "txtBPForFarmSync", "");
    //         break;
    //       case "SYNC":
    //         setValue(thisObj, "lblNewCY", "Sync Crop Year:");
    //         setData(thisObj, "mstrSyncOption", "SYNC");
    //         setValue(thisObj, "radioNewCY", "0");
    //         show(thisObj, "radioNewCY");
    //         hide(thisObj, "txtBPForFarmSync");
    //         hide(thisObj, "lblOptional");
    //         setValue(thisObj, "txtBPForFarmSync", "");
    //         break;
    //       case "SYNC_FARM":
    //         setValue(thisObj, "lblNewCY", "Sync Farm Data:");
    //         setData(thisObj, "mstrSyncOption", "SYNC_FARM");
    //         setValue(thisObj, "radioNewCY", "1");
    //         hide(thisObj, "radioNewCY");
    //         show(thisObj, "txtBPForFarmSync");
    //         show(thisObj, "lblOptional");
    //         setValue(thisObj, "txtBPForFarmSync", "");
    //         break;
    //       default:
    //         setValue(thisObj, "lblNewCY", "New Crop Year:");
    //         setData(thisObj, "mstrSyncOption", "NONE");
    //         setValue(thisObj, "radioNewCY", "0");
    //         hide(thisObj, "radioNewCY");
    //         hide(thisObj, "txtBPForFarmSync");
    //         hide(thisObj, "lblOptional");
    //         setValue(thisObj, "txtBPForFarmSync", "");
    //         break;


    //     }
    //   }
    // }
  }
  thisObj.ontxtNewYearBlur = ontxtNewYearBlur
  //validating the settlement number vicam  value
  const ontxtSettleNumChgVicamChange = () => {
    try {
      let SettleNumChgVicam = getValue(thisObj, 'txtSettleNumChgVicam')
      setValue(thisObj, "txtSettleNumChgVicam", validatetextbox(SettleNumChgVicam));
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtSettleNumChgVicamChange');
      }
    }
  }
  thisObj.ontxtSettleNumChgVicamChange = ontxtSettleNumChgVicamChange
  //validating the vicam value
  const chkboxVicam = () => {
    try {
      if (getValue(thisObj, "chkboxVicam") == true || getData(thisObj, "chkboxVicam") == true) {
        enable(thisObj, "txtVicamReading");
      }
      else {
        setValue(thisObj, "txtVicamReading", "");
        disable(thisObj, "txtVicamReading");
      }
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during chkboxVicam');
      }
    }
  }
  const onchkboxVicamChange = () => {
    try {
      setData(thisObj, "chkboxVicam", getValue(thisObj, "chkboxVicam"));
      chkboxVicam();
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during onchkboxVicamChange');
      }
    }
    // if (getValue(thisObj, "chkboxVicam") == true || getData(thisObj, "chkboxVicam") == true) {
    //   enable(thisObj, "txtVicamReading");
    // }
    // else {
    //   setValue(thisObj,"txtVicamReading","");
    //   disable(thisObj, "txtVicamReading");
    // }

  }
  thisObj.onchkboxVicamChange = onchkboxVicamChange
  //validating the transfer number value
  const zeroPad = (num, places) => String(num).padStart(places, '0');
  const ontxtTransferNumBlur = async () => {
    try {
      if (getValue(thisObj, "txtTransferNum") != "" && getValue(thisObj, "txtTransferNum") != null && getValue(thisObj, "txtTransferNum") != undefined)
        setValue(thisObj, "txtTransferNum", zeroPad(getValue(thisObj, "txtTransferNum"), 6).toUpperCase())
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtTransferNumBlur');
      }
    }
  }
  thisObj.ontxtTransferNumBlur = ontxtTransferNumBlur

  const ontxtTransferNumChange = () => {
    try {
      let TransferNum = getValue(thisObj, 'txtTransferNum')
      if (TransferNum != "" && TransferNum != null && TransferNum != undefined)
        setValue(thisObj, "txtTransferNum", validatetextbox1(TransferNum))
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtTransferNumChange');
      }
    }
  }
  thisObj.ontxtTransferNumChange = ontxtTransferNumChange

  //validating the contract number value
  const ontxtContractNumChange = () => {
    try {
      let ContractNum = getValue(thisObj, 'txtContractNum')
      setValue(thisObj, "txtContractNum", validatetextbox(ContractNum))
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtContractNumChange');
      }
    }
  }
  thisObj.ontxtContractNumChange = ontxtContractNumChange
  //validating the repoen contract number  value
  const ontxtContractNumToReopenChange = () => {
    try {
      let ContractNumToReopen = getValue(thisObj, 'txtContractNumToReopen')
      setValue(thisObj, "txtContractNumToReopen", validatetextbox(ContractNumToReopen))
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtContractNumToReopenChange');
      }
    }
  }
  thisObj.ontxtContractNumToReopenChange = ontxtContractNumToReopenChange
  //validating the settlement unload value
  const ontxtSettleNumChgUnloadChange = () => {
    try {
      let SettleNumChgUnload = getValue(thisObj, 'txtSettleNumChgUnload')
      setValue(thisObj, "txtSettleNumChgUnload", validatetextbox(SettleNumChgUnload))
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtSettleNumChgUnloadChange');
      }
    }
  }
  thisObj.ontxtSettleNumChgUnloadChange = ontxtSettleNumChgUnloadChange
  //validating the year  value
  const ontxtCropYearKCMOChange = () => {
    try {
      let CropYearKCMO = getValue(thisObj, 'txtCropYearKCMO')
      setValue(thisObj, "txtCropYearKCMO", validatetextbox(CropYearKCMO));
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtCropYearKCMOChange');
      }
    }
  }
  thisObj.ontxtCropYearKCMOChange = ontxtCropYearKCMOChange
  //validating the check number to void value
  const ontxtCheckNumToVoidChange = () => {
    try {
      let CheckNumToVoid = getValue(thisObj, 'txtCheckNumToVoid')
      setValue(thisObj, "txtCheckNumToVoid", validatetextbox(CheckNumToVoid));
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtCheckNumToVoidChange');
      }
    }
  }
  thisObj.ontxtCheckNumToVoidChange = ontxtCheckNumToVoidChange
  //validating the contract number to reset value
  const ontxtContractNumToResetChange = () => {
    try {
      let ContractNumToReset = getValue(thisObj, 'txtContractNumToReset')
      setValue(thisObj, "txtContractNumToReset", validatetextbox(ContractNumToReset))
    }
    catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during ontxtContractNumToResetChange');
      }
    }
  }
  thisObj.ontxtContractNumToResetChange = ontxtContractNumToResetChange

  //common function for validationg the values of controls

  const validatetextbox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 8) || (data[i].charCodeAt(0) == 3) || (data[i].charCodeAt(0) == 22 || (data[i].charCodeAt(0) == 24))) {
        res += data[i]
      }
    }
    return res;
  }
  //common function for validationg the values of controls
  const validatetextbox1 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (data[i].charCodeAt(0) != 3 && data[i].charCodeAt(0) != 22 && data[i].charCodeAt(0) != 24) {
        if (data[i].charCodeAt(0) != 8 && (data[i].charCodeAt(0) < 48 || data[i].charCodeAt(0) > 57) && (data[i].charCodeAt(0) < 65 || data[i].charCodeAt(0) > 90) && (data[i].charCodeAt(0) < 97 || data[i].charCodeAt(0) > 122)) {

        }
        else {
          res += data[i]
        }
      }
    }
    return res;
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SpecFuncMisc*/}

              {/* END_USER_CODE-USER_BEFORE_SpecFuncMisc*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSpecFuncMisc*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSpecFuncMisc*/}

              <GroupBoxWidget
                conf={state.grpbxSpecFuncMisc}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxSelectFunc*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSelectFunc*/}

                <GroupBoxWidget
                  conf={state.grpbxSelectFunc}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_radioMisc*/}

                  {/* END_USER_CODE-USER_BEFORE_radioMisc*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioMisc}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioMisc*/}

                  {/* END_USER_CODE-USER_AFTER_radioMisc*/}
                  {/* START_USER_CODE-USER_BEFORE_radioMisc2*/}

                  {/* END_USER_CODE-USER_BEFORE_radioMisc2*/}

                  {/* <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioMisc2}
                    screenConf={state}
                  ></RadioButtonGroupWidget> */}
                  {/* START_USER_CODE-USER_AFTER_radioMisc2*/}

                  {/* END_USER_CODE-USER_AFTER_radioMisc2*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSelectFunc*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSelectFunc*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxNewCY*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxNewCY*/}

                <GroupBoxWidget conf={state.grpbxNewCY} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblNewCY*/}

                  {/* END_USER_CODE-USER_BEFORE_lblNewCY*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblNewCY}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblNewCY*/}

                  {/* END_USER_CODE-USER_AFTER_lblNewCY*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOldYear*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOldYear*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOldYear}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOldYear*/}

                  {/* END_USER_CODE-USER_AFTER_txtOldYear*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNewYear*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNewYear*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNewYear}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNewYear*/}

                  {/* END_USER_CODE-USER_AFTER_txtNewYear*/}

                  {/* START_USER_CODE-USER_BEFORE_radioSyncCYData*/}

                  {/* END_USER_CODE-USER_BEFORE_radioSyncCYData*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioNewCY}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_BEFORE_txtBPForFarmSync*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBPForFarmSync*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBPForFarmSync}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBPForFarmSync*/}

                  {/* END_USER_CODE-USER_AFTER_txtBPForFarmSync*/}
                  {/* START_USER_CODE-USER_BEFORE_lblOptional*/}

                  {/* END_USER_CODE-USER_BEFORE_lblOptional*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblOptional}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblOptional*/}

                  {/* END_USER_CODE-USER_AFTER_lblOptional*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxNewCY*/}

                {/* END_USER_CODE-USER_AFTER_grpbxNewCY*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxResetSignedContract*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxResetSignedContract*/}

                <GroupBoxWidget
                  conf={state.grpbxResetSignedContract}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblResetSignedContract*/}

                  {/* END_USER_CODE-USER_BEFORE_lblResetSignedContract*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblResetSignedContract}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblResetSignedContract*/}

                  {/* END_USER_CODE-USER_AFTER_lblResetSignedContract*/}
                  {/* START_USER_CODE-USER_BEFORE_txtContractNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtContractNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtContractNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtContractNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtContractNum*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxResetSignedContract*/}

                {/* END_USER_CODE-USER_AFTER_grpbxResetSignedContract*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxChangeOleicInd*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxChangeOleicInd*/}

                <GroupBoxWidget
                  conf={state.grpbxChangeOleicInd}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblChangeOleicInd*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChangeOleicInd*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChangeOleicInd}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChangeOleicInd*/}

                  {/* END_USER_CODE-USER_AFTER_lblChangeOleicInd*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBuyPtId*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyPtId*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyPtId}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyPtId*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyPtId*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSettlementNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSettlementNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSettlementNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSettlementNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtSettlementNum*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOleicInd*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOleicInd*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOleicInd}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOleicInd*/}

                  {/* END_USER_CODE-USER_AFTER_ddOleicInd*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxChangeOleicInd*/}

                {/* END_USER_CODE-USER_AFTER_grpbxChangeOleicInd*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxRequestARDownload*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxRequestARDownload*/}

                <GroupBoxWidget
                  conf={state.grpbxRequestARDownload}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblRequestARDownload*/}

                  {/* END_USER_CODE-USER_BEFORE_lblRequestARDownload*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblRequestARDownload}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblRequestARDownload*/}

                  {/* END_USER_CODE-USER_AFTER_lblRequestARDownload*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCropYear*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCropYear*/}
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCropYear}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioReqAR}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  <GroupBoxWidget
                    conf={state.grpbxAllign}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_AFTER_txtCropYear*/}

                    {/* END_USER_CODE-USER_AFTER_txtCropYear*/}
                    {/* START_USER_CODE-USER_BEFORE_radioBuyPt*/}

                    {/* END_USER_CODE-USER_BEFORE_radioBuyPt*/}

                    {/* <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioBuyPt}
                    screenConf={state}
                  ></RadioButtonGroupWidget> */}
                    {/* START_USER_CODE-USER_AFTER_radioBuyPt*/}

                    {/* END_USER_CODE-USER_AFTER_radioBuyPt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtBuyPt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtBuyPt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtBuyPt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtBuyPt*/}

                    {/* END_USER_CODE-USER_AFTER_txtBuyPt*/}
                    {/* START_USER_CODE-USER_BEFORE_radioCollectionPt*/}

                    {/* END_USER_CODE-USER_BEFORE_radioCollectionPt*/}

                    {/* <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioCollectionPt}
                    screenConf={state}
                  ></RadioButtonGroupWidget> */}
                    {/* START_USER_CODE-USER_AFTER_radioCollectionPt*/}

                    {/* END_USER_CODE-USER_AFTER_radioCollectionPt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCollectionPt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCollectionPt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCollectionPt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCollectionPt*/}

                    {/* END_USER_CODE-USER_AFTER_txtCollectionPt*/}
                    {/* START_USER_CODE-USER_BEFORE_radioArea*/}

                    {/* END_USER_CODE-USER_BEFORE_radioArea*/}

                    {/* <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioArea}
                    screenConf={state}
                  ></RadioButtonGroupWidget> */}
                    {/* START_USER_CODE-USER_AFTER_radioArea*/}

                    {/* END_USER_CODE-USER_AFTER_radioArea*/}
                    {/* START_USER_CODE-USER_BEFORE_txtArea*/}

                    {/* END_USER_CODE-USER_BEFORE_txtArea*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtArea}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtArea*/}

                    {/* END_USER_CODE-USER_AFTER_txtArea*/}
                  </GroupBoxWidget>
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxRequestARDownload*/}

                {/* END_USER_CODE-USER_AFTER_grpbxRequestARDownload*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxReopenVoidedClosedContract*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxReopenVoidedClosedContract*/}

                <GroupBoxWidget
                  conf={state.grpbxReopenVoidedClosedContract}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblReopenVoidedClosedContract*/}

                  {/* END_USER_CODE-USER_BEFORE_lblReopenVoidedClosedContract*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblReopenVoidedClosedContract}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblReopenVoidedClosedContract*/}

                  {/* END_USER_CODE-USER_AFTER_lblReopenVoidedClosedContract*/}
                  {/* START_USER_CODE-USER_BEFORE_txtContractNumToReopen*/}

                  {/* END_USER_CODE-USER_BEFORE_txtContractNumToReopen*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtContractNumToReopen}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtContractNumToReopen*/}

                  {/* END_USER_CODE-USER_AFTER_txtContractNumToReopen*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxReopenVoidedClosedContract*/}

                {/* END_USER_CODE-USER_AFTER_grpbxReopenVoidedClosedContract*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxChangeVicamReading*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxChangeVicamReading*/}

                <GroupBoxWidget
                  conf={state.grpbxChangeVicamReading}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblChangeVicamReading*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChangeVicamReading*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChangeVicamReading}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChangeVicamReading*/}

                  {/* END_USER_CODE-USER_AFTER_lblChangeVicamReading*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBuyPtIdChgVicam*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyPtIdChgVicam*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyPtIdChgVicam}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyPtIdChgVicam*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyPtIdChgVicam*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSettleNumChgVicam*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSettleNumChgVicam*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSettleNumChgVicam}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSettleNumChgVicam*/}

                  {/* END_USER_CODE-USER_AFTER_txtSettleNumChgVicam*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxVicam*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxVicam*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxVicam}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxVicam*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxVicam*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVicamReading*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVicamReading*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVicamReading}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVicamReading*/}

                  {/* END_USER_CODE-USER_AFTER_txtVicamReading*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxChangeVicamReading*/}

                {/* END_USER_CODE-USER_AFTER_grpbxChangeVicamReading*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDeleteStockTransfer*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDeleteStockTransfer*/}

                <GroupBoxWidget
                  conf={state.grpbxDeleteStockTransfer}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblDeleteStockTransfer*/}

                  {/* END_USER_CODE-USER_BEFORE_lblDeleteStockTransfer*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblDeleteStockTransfer}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblDeleteStockTransfer*/}

                  {/* END_USER_CODE-USER_AFTER_lblDeleteStockTransfer*/}
                  {/* START_USER_CODE-USER_BEFORE_txtShippingBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_txtShippingBuyingPoint*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtShippingBuyingPoint}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtShippingBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_txtShippingBuyingPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTransferNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTransferNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTransferNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTransferNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtTransferNum*/}
                  {/* START_USER_CODE-USER_BEFORE_ddTransferType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddTransferType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddTransferType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddTransferType*/}

                  {/* END_USER_CODE-USER_AFTER_ddTransferType*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDeleteStockTransfer*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDeleteStockTransfer*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxChange1007Unload*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxChange1007Unload*/}

                <GroupBoxWidget
                  conf={state.grpbxChange1007Unload}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblChange1007Unload*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChange1007Unload*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChange1007Unload}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChange1007Unload*/}

                  {/* END_USER_CODE-USER_AFTER_lblChange1007Unload*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBuyPtIdChgUnload*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyPtIdChgUnload*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyPtIdChgUnload}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyPtIdChgUnload*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyPtIdChgUnload*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSettleNumChgUnload*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSettleNumChgUnload*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSettleNumChgUnload}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSettleNumChgUnload*/}

                  {/* END_USER_CODE-USER_AFTER_txtSettleNumChgUnload*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWhse*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWhse*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWhse}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWhse*/}

                  {/* END_USER_CODE-USER_AFTER_txtWhse*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBin*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBin*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBin}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBin*/}

                  {/* END_USER_CODE-USER_AFTER_txtBin*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxChange1007Unload*/}

                {/* END_USER_CODE-USER_AFTER_grpbxChange1007Unload*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxChgGrds*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxChgGrds*/}

                <GroupBoxWidget
                  conf={state.grpbxChgGrds}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblChgGrds*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChgGrds*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChgGrds}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChgGrds*/}

                  {/* END_USER_CODE-USER_AFTER_lblChgGrds*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBuyPtIdChgGrds*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyPtIdChgGrds*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyPtIdChgGrds}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyPtIdChgGrds*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyPtIdChgGrds*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSettleNumChgGrds*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSettleNumChgGrds*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSettleNumChgGrds}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSettleNumChgGrds*/}

                  {/* END_USER_CODE-USER_AFTER_txtSettleNumChgGrds*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSampleWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSampleWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSampleWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSampleWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtSampleWt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtGr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtGr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtGr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtGr*/}

                  {/* END_USER_CODE-USER_AFTER_txtGr*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtPct*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxChgGrds*/}

                {/* END_USER_CODE-USER_AFTER_grpbxChgGrds*/}
                {/* START_USER_CODE-USER_BEFORE_txtReqKCMORecon*/}

                {/* END_USER_CODE-USER_BEFORE_txtReqKCMORecon*/}

                <GroupBoxWidget
                  conf={state.txtReqKCMORecon}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblReqKCMORecon*/}

                  {/* END_USER_CODE-USER_BEFORE_lblReqKCMORecon*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblReqKCMORecon}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblReqKCMORecon*/}

                  {/* END_USER_CODE-USER_AFTER_lblReqKCMORecon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCropYearKCMO*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCropYearKCMO*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCropYearKCMO}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCropYearKCMO*/}

                  {/* END_USER_CODE-USER_AFTER_txtCropYearKCMO*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOptionalMail*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOptionalMail*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOptionalMail}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOptionalMail*/}

                  {/* END_USER_CODE-USER_AFTER_txtOptionalMail*/}
                  {/* START_USER_CODE-USER_BEFORE_lblNOTE*/}

                  {/* END_USER_CODE-USER_BEFORE_lblNOTE*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblNOTE}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblNOTE*/}

                  {/* END_USER_CODE-USER_AFTER_lblNOTE*/}
                  {/* START_USER_CODE-USER_BEFORE_lblNoteValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblNoteValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblNoteValue}
                    screenConf={state}
                  ></LabelWidget>
                   <LabelWidget
                    values={values}
                    conf={state.lblNote1}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblNoteValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblNoteValue*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtReqKCMORecon*/}

                {/* END_USER_CODE-USER_AFTER_txtReqKCMORecon*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxResetCheckToVoid*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxResetCheckToVoid*/}

                <GroupBoxWidget
                  conf={state.grpbxResetCheckToVoid}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblResetCheckToVoid*/}

                  {/* END_USER_CODE-USER_BEFORE_lblResetCheckToVoid*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblResetCheckToVoid}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblResetCheckToVoid*/}

                  {/* END_USER_CODE-USER_AFTER_lblResetCheckToVoid*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCheckNumToVoid*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCheckNumToVoid*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCheckNumToVoid}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCheckNumToVoid*/}

                  {/* END_USER_CODE-USER_AFTER_txtCheckNumToVoid*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxResetCheckToVoid*/}

                {/* END_USER_CODE-USER_AFTER_grpbxResetCheckToVoid*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxChgDt*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxChgDt*/}

                <GroupBoxWidget conf={state.grpbxChgDt} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblChgDt*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChgDt*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChgDt}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChgDt*/}

                  {/* END_USER_CODE-USER_AFTER_lblChgDt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtContractNumChgDt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtContractNumChgDt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtContractNumChgDt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtContractNumChgDt*/}

                  {/* END_USER_CODE-USER_AFTER_txtContractNumChgDt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNewContractDt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNewContractDt*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNewContractDt}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNewContractDt*/}

                  {/* END_USER_CODE-USER_AFTER_txtNewContractDt*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxChgDt*/}

                {/* END_USER_CODE-USER_AFTER_grpbxChgDt*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxResetUpload211*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxResetUpload211*/}

                <GroupBoxWidget
                  conf={state.grpbxResetUpload211}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblResetUpload211*/}

                  {/* END_USER_CODE-USER_BEFORE_lblResetUpload211*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblResetUpload211}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblResetUpload211*/}

                  {/* END_USER_CODE-USER_AFTER_lblResetUpload211*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendorNumrToReset*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendorNumrToReset*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendorNumrToReset}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendorNumrToReset*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendorNumrToReset*/}
                  {/* START_USER_CODE-USER_BEFORE_radioReset*/}

                  {/* END_USER_CODE-USER_BEFORE_radioReset*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioReset}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioReset*/}

                  {/* END_USER_CODE-USER_AFTER_radioReset*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxResetUpload211*/}

                {/* END_USER_CODE-USER_AFTER_grpbxResetUpload211*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxResetUploadContract*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxResetUploadContract*/}

                <GroupBoxWidget
                  conf={state.grpbxResetUploadContract}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblResetUploadContract*/}

                  {/* END_USER_CODE-USER_BEFORE_lblResetUploadContract*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblResetUploadContract}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblResetUploadContract*/}

                  {/* END_USER_CODE-USER_AFTER_lblResetUploadContract*/}
                  {/* START_USER_CODE-USER_BEFORE_txtContractNumToReset*/}

                  {/* END_USER_CODE-USER_BEFORE_txtContractNumToReset*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtContractNumToReset}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtContractNumToReset*/}

                  {/* END_USER_CODE-USER_AFTER_txtContractNumToReset*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxResetUploadContract*/}

                {/* END_USER_CODE-USER_AFTER_grpbxResetUploadContract*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxCopyFarms*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCopyFarms*/}

                <GroupBoxWidget
                  conf={state.grpbxCopyFarms}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblCopyFarms*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCopyFarms*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCopyFarms}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCopyFarms*/}

                  {/* END_USER_CODE-USER_AFTER_lblCopyFarms*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBuyPtCopyFrom*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyPtCopyFrom*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyPtCopyFrom}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyPtCopyFrom*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyPtCopyFrom*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBuyPtIdCopyTo*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyPtIdCopyTo*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyPtIdCopyTo}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyPtIdCopyTo*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyPtIdCopyTo*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCopyFarms*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCopyFarms*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSpecFuncMisc*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSpecFuncMisc*/}
              {/* START_USER_CODE-USER_BEFORE_commandcontainerwidget1*/}

              {/* END_USER_CODE-USER_BEFORE_commandcontainerwidget1*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.commandcontainerwidget1}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnRequest*/}

                {/* END_USER_CODE-USER_BEFORE_btnRequest*/}

                <ButtonWidget
                  conf={state.btnRequest}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnRequest*/}

                {/* END_USER_CODE-USER_AFTER_btnRequest*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_commandcontainerwidget1*/}

              {/* END_USER_CODE-USER_AFTER_commandcontainerwidget1*/}

              {/* START_USER_CODE-USER_AFTER_SpecFuncMisc*/}

              {/* END_USER_CODE-USER_AFTER_SpecFuncMisc*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceApplicationSupport_SpecFuncMisc);
