/* eslint-disable*/
import React from "react";
import Settlements_ViewPremiumDeductions from "./ViewPremiumDeductions";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ViewPremiumDeductions Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ViewPremiumDeductions />);
    });
  });
  afterEach(cleanup);
  test("is ViewPremiumDeductions Loads Successfully", () => {
    expect(screen.getByText("ViewPremiumDeductions")).toBeInTheDocument;
  });
  test("Custom Test Cases for ViewPremiumDeductions", () => {
    // START_USER_CODE-USER_ViewPremiumDeductions_Custom_Test_Case
    // END_USER_CODE-USER_ViewPremiumDeductions_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ViewPremiumDeductions />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("Settlements:ViewPremiumDeductions_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("gridViewPremiumDeductions(Grid Widget) Test Cases", async () => {
    let gridViewPremiumDeductions = screen.getByTestId(
      "gridViewPremiumDeductions"
    );
    let gridViewPremiumDeductionsbtn =
      gridViewPremiumDeductions.nextElementSibling.firstElementChild;
    gridViewPremiumDeductions =
      gridViewPremiumDeductions.parentElement.parentElement.parentElement;
    expect(gridViewPremiumDeductions.tagName).toBe("DIV");
    expect(gridViewPremiumDeductions.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ViewPremiumDeductions_gridViewPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridViewPremiumDeductions", () => {
    // START_USER_CODE-USER_gridViewPremiumDeductions_TEST
    // END_USER_CODE-USER_gridViewPremiumDeductions_TEST
  });
  test("grpbxViewPremiumDeductions(GroupBox Widget) Test Cases", async () => {
    const grpbxViewPremiumDeductions = screen.getByTestId(
      "grpbxViewPremiumDeductions"
    );
    expect(grpbxViewPremiumDeductions.tagName).toBe("BUTTON");
    expect(grpbxViewPremiumDeductions.type).toBe("button");
    expect(grpbxViewPremiumDeductions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxViewPremiumDeductions", () => {
    // START_USER_CODE-USER_grpbxViewPremiumDeductions_TEST
    // END_USER_CODE-USER_grpbxViewPremiumDeductions_TEST
  });
  test("lblPremiumsDeductions(Label Widget) Test Cases", async () => {
    const lblPremiumsDeductions = screen.getByTestId("lblPremiumsDeductions");
    expect(lblPremiumsDeductions.tagName).toBe("LABEL");
    expect(lblPremiumsDeductions.classList).toContain("form-label");
    expect(lblPremiumsDeductions.textContent).toEqual(
      t("Settlements:ViewPremiumDeductions_lblPremiumsDeductions")
    );
  });
  test("Custom Test Cases for lblPremiumsDeductions", () => {
    // START_USER_CODE-USER_lblPremiumsDeductions_TEST
    // END_USER_CODE-USER_lblPremiumsDeductions_TEST
  });
  test("gridViewPremiumDeductions_txtcolAmount(Grid Widget) Test Cases", async () => {
    let gridViewPremiumDeductions_txtcolAmount = screen.getByTestId(
      "gridViewPremiumDeductions"
    );
    let gridViewPremiumDeductions_txtcolAmountbtn =
      gridViewPremiumDeductions_txtcolAmount.nextElementSibling
        .firstElementChild;
    gridViewPremiumDeductions_txtcolAmount =
      gridViewPremiumDeductions_txtcolAmount.parentElement.parentElement
        .parentElement;
    expect(gridViewPremiumDeductions_txtcolAmount.tagName).toBe("DIV");
    expect(gridViewPremiumDeductions_txtcolAmount.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ViewPremiumDeductions_gridViewPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAmount", () => {
    // START_USER_CODE-USER_txtcolAmount_TEST
    // END_USER_CODE-USER_txtcolAmount_TEST
  });
  test("gridViewPremiumDeductions_txtcolDescription(Grid Widget) Test Cases", async () => {
    let gridViewPremiumDeductions_txtcolDescription = screen.getByTestId(
      "gridViewPremiumDeductions"
    );
    let gridViewPremiumDeductions_txtcolDescriptionbtn =
      gridViewPremiumDeductions_txtcolDescription.nextElementSibling
        .firstElementChild;
    gridViewPremiumDeductions_txtcolDescription =
      gridViewPremiumDeductions_txtcolDescription.parentElement.parentElement
        .parentElement;
    expect(gridViewPremiumDeductions_txtcolDescription.tagName).toBe("DIV");
    expect(gridViewPremiumDeductions_txtcolDescription.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ViewPremiumDeductions_gridViewPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDescription", () => {
    // START_USER_CODE-USER_txtcolDescription_TEST
    // END_USER_CODE-USER_txtcolDescription_TEST
  });
  test("gridViewPremiumDeductions_txtcolPremiumsDeductions(Grid Widget) Test Cases", async () => {
    let gridViewPremiumDeductions_txtcolPremiumsDeductions = screen.getByTestId(
      "gridViewPremiumDeductions"
    );
    let gridViewPremiumDeductions_txtcolPremiumsDeductionsbtn =
      gridViewPremiumDeductions_txtcolPremiumsDeductions.nextElementSibling
        .firstElementChild;
    gridViewPremiumDeductions_txtcolPremiumsDeductions =
      gridViewPremiumDeductions_txtcolPremiumsDeductions.parentElement
        .parentElement.parentElement;
    expect(gridViewPremiumDeductions_txtcolPremiumsDeductions.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewPremiumDeductions_txtcolPremiumsDeductions.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ViewPremiumDeductions_gridViewPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPremiumsDeductions", () => {
    // START_USER_CODE-USER_txtcolPremiumsDeductions_TEST
    // END_USER_CODE-USER_txtcolPremiumsDeductions_TEST
  });
  test("gridViewPremiumDeductions_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridViewPremiumDeductions_txtcolVendor = screen.getByTestId(
      "gridViewPremiumDeductions"
    );
    let gridViewPremiumDeductions_txtcolVendorbtn =
      gridViewPremiumDeductions_txtcolVendor.nextElementSibling
        .firstElementChild;
    gridViewPremiumDeductions_txtcolVendor =
      gridViewPremiumDeductions_txtcolVendor.parentElement.parentElement
        .parentElement;
    expect(gridViewPremiumDeductions_txtcolVendor.tagName).toBe("DIV");
    expect(gridViewPremiumDeductions_txtcolVendor.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ViewPremiumDeductions_gridViewPremiumDeductions")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
});
