/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_LienHolderProfile from "./LienHolderProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("LienHolderProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_LienHolderProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is LienHolderProfile Loads Successfully", () => {
    expect(screen.getByText("LienHolderProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for LienHolderProfile", () => {
    // START_USER_CODE-USER_LienHolderProfile_Custom_Test_Case
    // END_USER_CODE-USER_LienHolderProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_LienHolderProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:LienHolderProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:LienHolderProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxLienHolderDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxLienHolderDetails = screen.getByTestId(
      "grpbxLienHolderDetails"
    );
    expect(grpbxLienHolderDetails.tagName).toBe("BUTTON");
    expect(grpbxLienHolderDetails.type).toBe("button");
    expect(grpbxLienHolderDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLienHolderDetails", () => {
    // START_USER_CODE-USER_grpbxLienHolderDetails_TEST
    // END_USER_CODE-USER_grpbxLienHolderDetails_TEST
  });
  test("txtAddedBy(Textbox Widget) Test Cases", async () => {
    const txtAddedBy = screen.getByTestId("txtAddedBy");
    expect(txtAddedBy.tagName).toBe("INPUT");
    expect(txtAddedBy.type).toBe("text");
    expect(txtAddedBy.classList).toContain("textboxWidgetClass");
    expect(txtAddedBy.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:LienHolderProfile_txtAddedBy")
    );
    await act(async () => {
      userEvent.type(txtAddedBy, "123");
    });
    expect(txtAddedBy.getAttribute("value")).toBe("");
    expect(txtAddedBy.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAddedBy", () => {
    // START_USER_CODE-USER_txtAddedBy_TEST
    // END_USER_CODE-USER_txtAddedBy_TEST
  });
  test("txtAddress(Textbox Widget) Test Cases", async () => {
    const txtAddress = screen.getByTestId("txtAddress");
    expect(txtAddress.tagName).toBe("INPUT");
    expect(txtAddress.type).toBe("text");
    expect(txtAddress.classList).toContain("textboxWidgetClass");
    expect(txtAddress.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:LienHolderProfile_txtAddress")
    );
    await act(async () => {
      userEvent.type(txtAddress, "1");
    });
  });
  test("Custom Test Cases for txtAddress", () => {
    // START_USER_CODE-USER_txtAddress_TEST
    // END_USER_CODE-USER_txtAddress_TEST
  });
  test("txtChangedBy(Textbox Widget) Test Cases", async () => {
    const txtChangedBy = screen.getByTestId("txtChangedBy");
    expect(txtChangedBy.tagName).toBe("INPUT");
    expect(txtChangedBy.type).toBe("text");
    expect(txtChangedBy.classList).toContain("textboxWidgetClass");
    expect(txtChangedBy.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:LienHolderProfile_txtChangedBy"
      )
    );
    await act(async () => {
      userEvent.type(txtChangedBy, "123");
    });
    expect(txtChangedBy.getAttribute("value")).toBe("");
    expect(txtChangedBy.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtChangedBy", () => {
    // START_USER_CODE-USER_txtChangedBy_TEST
    // END_USER_CODE-USER_txtChangedBy_TEST
  });
  test("txtCity(Textbox Widget) Test Cases", async () => {
    const txtCity = screen.getByTestId("txtCity");
    expect(txtCity.tagName).toBe("INPUT");
    expect(txtCity.type).toBe("text");
    expect(txtCity.classList).toContain("textboxWidgetClass");
    expect(txtCity.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:LienHolderProfile_txtCity")
    );
    await act(async () => {
      userEvent.type(txtCity, "1");
    });
  });
  test("Custom Test Cases for txtCity", () => {
    // START_USER_CODE-USER_txtCity_TEST
    // END_USER_CODE-USER_txtCity_TEST
  });
  test("txtLienOrder(Textbox Widget) Test Cases", async () => {
    const txtLienOrder = screen.getByTestId("txtLienOrder");
    expect(txtLienOrder.tagName).toBe("INPUT");
    expect(txtLienOrder.type).toBe("text");
    expect(txtLienOrder.classList).toContain("textboxWidgetClass");
    expect(txtLienOrder.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:LienHolderProfile_txtLienOrder"
      )
    );
    await act(async () => {
      userEvent.type(txtLienOrder, "1");
    });
  });
  test("Custom Test Cases for txtLienOrder", () => {
    // START_USER_CODE-USER_txtLienOrder_TEST
    // END_USER_CODE-USER_txtLienOrder_TEST
  });
  test("txtName(Textbox Widget) Test Cases", async () => {
    const txtName = screen.getByTestId("txtName");
    expect(txtName.tagName).toBe("INPUT");
    expect(txtName.type).toBe("text");
    expect(txtName.classList).toContain("textboxWidgetClass");
    expect(txtName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:LienHolderProfile_txtName")
    );
    await act(async () => {
      userEvent.type(txtName, "1");
    });
  });
  test("Custom Test Cases for txtName", () => {
    // START_USER_CODE-USER_txtName_TEST
    // END_USER_CODE-USER_txtName_TEST
  });
  test("txtNatureOfLien(Textbox Widget) Test Cases", async () => {
    const txtNatureOfLien = screen.getByTestId("txtNatureOfLien");
    expect(txtNatureOfLien.tagName).toBe("INPUT");
    expect(txtNatureOfLien.type).toBe("text");
    expect(txtNatureOfLien.classList).toContain("textboxWidgetClass");
    expect(txtNatureOfLien.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:LienHolderProfile_txtNatureOfLien"
      )
    );
    await act(async () => {
      userEvent.type(txtNatureOfLien, "1");
    });
  });
  test("Custom Test Cases for txtNatureOfLien", () => {
    // START_USER_CODE-USER_txtNatureOfLien_TEST
    // END_USER_CODE-USER_txtNatureOfLien_TEST
  });
  test("txtRemitTo(Textbox Widget) Test Cases", async () => {
    const txtRemitTo = screen.getByTestId("txtRemitTo");
    expect(txtRemitTo.tagName).toBe("INPUT");
    expect(txtRemitTo.type).toBe("text");
    expect(txtRemitTo.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtRemitTo, "1");
    });
  });
  test("Custom Test Cases for txtRemitTo", () => {
    // START_USER_CODE-USER_txtRemitTo_TEST
    // END_USER_CODE-USER_txtRemitTo_TEST
  });
  test("txtStateID1(Textbox Widget) Test Cases", async () => {
    const txtStateID1 = screen.getByTestId("txtStateID1");
    expect(txtStateID1.tagName).toBe("INPUT");
    expect(txtStateID1.type).toBe("text");
    expect(txtStateID1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtStateID1, "1");
    });
  });
  test("Custom Test Cases for txtStateID1", () => {
    // START_USER_CODE-USER_txtStateID1_TEST
    // END_USER_CODE-USER_txtStateID1_TEST
  });
  test("txtStateID(Textbox Widget) Test Cases", async () => {
    const txtStateID = screen.getByTestId("txtStateID");
    expect(txtStateID.tagName).toBe("INPUT");
    expect(txtStateID.type).toBe("text");
    expect(txtStateID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtStateID, "1");
    });
  });
  test("Custom Test Cases for txtStateID", () => {
    // START_USER_CODE-USER_txtStateID_TEST
    // END_USER_CODE-USER_txtStateID_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:LienHolderProfile_txtVendor")
    );
    expect(txtVendor.getAttribute("readonly")).toContain("");
    await act(async () => {
      userEvent.type(txtVendor, "123");
    });
    expect(txtVendor.getAttribute("value")).toBe("");
    expect(txtVendor.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtZip(Textbox Widget) Test Cases", async () => {
    const txtZip = screen.getByTestId("txtZip");
    expect(txtZip.tagName).toBe("INPUT");
    expect(txtZip.type).toBe("text");
    expect(txtZip.classList).toContain("textboxWidgetClass");
    expect(txtZip.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:LienHolderProfile_txtZip")
    );
    await act(async () => {
      userEvent.type(txtZip, "1");
    });
  });
  test("Custom Test Cases for txtZip", () => {
    // START_USER_CODE-USER_txtZip_TEST
    // END_USER_CODE-USER_txtZip_TEST
  });
});
