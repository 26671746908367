/* eslint-disable*/
import React, { useState, useEffect, useRef,useContext } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,  
  Modal,
  _kaledo,
  initForm,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  getData,
  getValue,
  setValue,
  hideColumn,
  LabelWidget,
  GroupBoxWidget
} from "../../shared/WindowImports";

import "./ViewPayments.scss";
import { ContractManagementService } from "../Service/ContractManagementService";
// To Share Data
import CommonContext from '../../Store/CommonContext';

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
var ParentData = {}
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ViewPayments(props) {
  const commonContext = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();    

  let states = {
    Label: "View Payments",
    windowName: "ViewPayments",
    template: "Standard Screen",
    Cols: "3",
    rows: [],
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ViewPayments",
    // START_USER_CODE-USER_ViewPayments_PROPERTIES
    headerData: {
      scrName: "Payments",
      scrCode: "PN1060E"
    },
    // END_USER_CODE-USER_ViewPayments_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "btnContainer",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExport: {
      name: "btnExport",
      type: "ButtonWidget",
      parent: "btnContainer",
      Label: "Export to Excel",
      CharWidth: "32",
      // START_USER_CODE-USER_btnExport_PROPERTIES

      // END_USER_CODE-USER_btnExport_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "btnContainer",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    colTxtBuyngPoint: {
      name: "colTxtBuyngPoint",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTxtBuyngPoint_PROPERTIES

      // END_USER_CODE-USER_colTxtBuyngPoint_PROPERTIES
    },
    colTxtChckNum: {
      name: "colTxtChckNum",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Check Num",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTxtChckNum_PROPERTIES

      // END_USER_CODE-USER_colTxtChckNum_PROPERTIES
    },
    coltxtSC95: {
      name: "coltxtSC95",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "SC95#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTxtChckNum_PROPERTIES

      // END_USER_CODE-USER_colTxtChckNum_PROPERTIES
    },
    coltxt1007: {
      name: "coltxt1007",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "1007#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTxtChckNum_PROPERTIES

      // END_USER_CODE-USER_colTxtChckNum_PROPERTIES
    },
    colTxtClrDate: {
      name: "colTxtClrDate",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Cleared Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTxtClrDate_PROPERTIES

      // END_USER_CODE-USER_colTxtClrDate_PROPERTIES
    },
    colTxtContrct: {
      name: "colTxtContrct",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Contract #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTxtContrct_PROPERTIES

      // END_USER_CODE-USER_colTxtContrct_PROPERTIES
    },
    colTxtIssuedDt: {
      name: "colTxtIssuedDt",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Issued Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTxtIssuedDt_PROPERTIES

      // END_USER_CODE-USER_colTxtIssuedDt_PROPERTIES
    },
    colTxtMemo: {
      name: "colTxtMemo",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Memo Num",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTxtMemo_PROPERTIES

      // END_USER_CODE-USER_colTxtMemo_PROPERTIES
    },
    colTxtPrceeds: {
      name: "colTxtPrceeds",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Proceeds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTxtPrceeds_PROPERTIES

      // END_USER_CODE-USER_colTxtPrceeds_PROPERTIES
    },
    colTxtSpclTyp: {
      name: "colTxtSpclTyp",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Special Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTxtSpclTyp_PROPERTIES

      // END_USER_CODE-USER_colTxtSpclTyp_PROPERTIES
    },
    colTxtStatus: {
      name: "colTxtStatus",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTxtStatus_PROPERTIES

      // END_USER_CODE-USER_colTxtStatus_PROPERTIES
    },
    colTxtVendr: {
      name: "colTxtVendr",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTxtVendr_PROPERTIES

      // END_USER_CODE-USER_colTxtVendr_PROPERTIES
    },
    coltxtInspLoc: {
      name: "coltxtInspLoc",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_coltxtInspLoc_PROPERTIES

      // END_USER_CODE-USER_coltxtInspLoc_PROPERTIES
    },
    coltxtTransferShipLoc: {
      name: "coltxtTransferShipLoc",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Shipping Loc",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_coltxtTransferShipLoc_PROPERTIES

      // END_USER_CODE-USER_coltxtTransferShipLoc_PROPERTIES
    },
    coltxtTransferRecLoc: {
      name: "coltxtTransferRecLoc",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Receiving Loc",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_coltxtTransferRecLoc_PROPERTIES

      // END_USER_CODE-USER_coltxtTransferRecLoc_PROPERTIES
    },
    coltxtTransferNum: {
      name: "coltxtTransferNum",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Transfer #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_coltxtTransferNum_PROPERTIES

      // END_USER_CODE-USERcoltxtTransferNum_PROPERTIES
    },
    colTxtVoidDt: {
      name: "colTxtVoidDt",
      type: "GridColumnWidget",
      parent: "gridContrctExcPremProf",
      Label: "Void Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTxtVoidDt_PROPERTIES

      // END_USER_CODE-USER_colTxtVoidDt_PROPERTIES
    },
    txtByngPt: {
      name: "txtByngPt",
      type: "TextBoxWidget",
      colName: "colTxtBuyngPoint",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtByngPt_PROPERTIES

      // END_USER_CODE-USER_txtByngPt_PROPERTIES
    },
    txtChckNum: {
      name: "txtChckNum",
      type: "TextBoxWidget",
      colName: "colTxtChckNum",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChckNum_PROPERTIES

      // END_USER_CODE-USER_txtChckNum_PROPERTIES
    },
    txtSC95: {
      name: "txtSC95",
      type: "TextBoxWidget",
      colName: "coltxtSC95",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChckNum_PROPERTIES

      // END_USER_CODE-USER_txtChckNum_PROPERTIES
    },
    lblPayments: {
      name: "lblPayments",
      type: "LabelWidget",
      parent: "grpbxViewWRPayments",
      Label: "Payments:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPayments_PROPERTIES

      // END_USER_CODE-USER_lblPayments_PROPERTIES
    },
    grpbxViewWRPayments: {
      name: "grpbxViewWRPayments",
      type: "GroupBoxWidget",
      parent: "ViewWRPayments",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxViewWRPayments_PROPERTIES

      // END_USER_CODE-USER_grpbxViewWRPayments_PROPERTIES
    },
    txt1007: {
      name: "txt1007",
      type: "TextBoxWidget",
      colName: "coltxt1007",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChckNum_PROPERTIES

      // END_USER_CODE-USER_txtChckNum_PROPERTIES
    },
    txtClrdDt: {
      name: "txtClrdDt",
      type: "TextBoxWidget",
      colName: "colTxtClrDate",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtClrdDt_PROPERTIES

      // END_USER_CODE-USER_txtClrdDt_PROPERTIES
    },
    txtContrct: {
      name: "txtContrct",
      type: "TextBoxWidget",
      colName: "colTxtContrct",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContrct_PROPERTIES

      // END_USER_CODE-USER_txtContrct_PROPERTIES
    },
    txtIssueDate: {
      name: "txtIssueDate",
      type: "TextBoxWidget",
      colName: "colTxtIssuedDt",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIssueDate_PROPERTIES

      // END_USER_CODE-USER_txtIssueDate_PROPERTIES
    },
    txtMemo: {
      name: "txtMemo",
      type: "TextBoxWidget",
      colName: "colTxtMemo",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMemo_PROPERTIES

      // END_USER_CODE-USER_txtMemo_PROPERTIES
    },
    txtProceeds: {
      name: "txtProceeds",
      type: "TextBoxWidget",
      colName: "colTxtPrceeds",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProceeds_PROPERTIES

      // END_USER_CODE-USER_txtProceeds_PROPERTIES
    },
    txtSpclTyp: {
      name: "txtSpclTyp",
      type: "TextBoxWidget",
      colName: "colTxtSpclTyp",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSpclTyp_PROPERTIES

      // END_USER_CODE-USER_txtSpclTyp_PROPERTIES
    },
    txtStatus: {
      name: "txtStatus",
      type: "TextBoxWidget",
      colName: "colTxtStatus",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatus_PROPERTIES

      // END_USER_CODE-USER_txtStatus_PROPERTIES
    },
    txtVendr: {
      name: "txtVendr",
      type: "TextBoxWidget",
      colName: "colTxtVendr",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendr_PROPERTIES

      // END_USER_CODE-USER_txtVendr_PROPERTIES
    },
    txtInspLoc: {
      name: "txtInspLoc",
      type: "TextBoxWidget",
      colName: "coltxtInspLoc",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendr_PROPERTIES

      // END_USER_CODE-USER_txtVendr_PROPERTIES
    },
    txtTransferShipLoc: {
      name: "txtTransferShipLoc",
      type: "TextBoxWidget",
      colName: "coltxtTransferShipLoc",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendr_PROPERTIES

      // END_USER_CODE-USER_txtVendr_PROPERTIES
    },
    txtTransferRecLoc: {
      name: "txtTransferRecLoc",
      type: "TextBoxWidget",
      colName: "coltxtTransferRecLoc",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendr_PROPERTIES

      // END_USER_CODE-USER_txtVendr_PROPERTIES
    },
    txtTransferNum: {
      name: "txtTransferNum",
      type: "TextBoxWidget",
      colName: "coltxtTransferNum",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum_PROPERTIES
    },
    txtVoidDate: {
      name: "txtVoidDate",
      type: "TextBoxWidget",
      colName: "colTxtVoidDt",
      parent: "gridContrctExcPremProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVoidDate_PROPERTIES

      // END_USER_CODE-USER_txtVoidDate_PROPERTIES
    },
    lblPayments: {
      name: "lblPayments",
      type: "LabelWidget",
      parent: "ViewWRPayments",
      Label: "Payments:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPayments_PROPERTIES

      // END_USER_CODE-USER_lblPayments_PROPERTIES
    },
    gridContrctExcPremProf: {
      name: "gridContrctExcPremProf",
      type: "GridWidget",
      parent: "ViewPayments",
      gridCellsOrder:
        "txtChckNum,txtVendr,txtStatus,txtProceeds,txtMemo,txtSpclTyp,txtIssueDate,txtClrdDt,txtVoidDate,txtByngPt,txtContrct,txtInspLoc,txtSC95,txt1007,txtTransferShipLoc,txtTransferRecLoc,txtTransferNum",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridContrctExcPremProf_PROPERTIES

      // END_USER_CODE-USER_gridContrctExcPremProf_PROPERTIES
    },
    btnContainer: {
      name: "btnContainer",
      type: "CommandContainerWidget",
      parent: "ViewPayments",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_btnContainer_PROPERTIES

      // END_USER_CODE-USER_btnContainer_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {},
      "ContractManagement#MainMenu": {},

      REVERSE: {},
      "ContractManagement#MainMenu": {},
    },
  };
  const [loading, setLoading] = useState(true);
  let _buttonServices = {};
  let _buttonNavigation = { 
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const[buyingPoint, setbuyingPoint] = useState('')
  const[contractNumber, setcontractNumber] = useState('')

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    ParentData = getData(thisObj, 'ReviewPaymentsFormLoadData')
    FormLoad(ParentData)
    InitPaymentGrid(ParentData)
    thisObj.setState(current => {
      return {
        ...current,
        headerDetails: {
          ...state["headerDetails"],
          year: getData(thisObj, 'headDetailYear'),
          companyName: getData(thisObj, 'headDetailCompName') 
        }
      }
    });
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  
  function FormLoad() {
    setLoading(true)
    let FunctionName = ParentData.Function_Name
    if(FunctionName == "Contract" ){
      ReadPayments_Cont()
    }
    if(FunctionName == 'FV95'){
      ReadPayments_Insp()
    }
    if(FunctionName == 'Transfer'){
      ReadPayments_Xref()
    }
    setLoading(false)
  }

  const InitPaymentGrid = () =>{
    let FunctionName = ParentData.Function_Name
    if(FunctionName == null || FunctionName == undefined ||FunctionName == ''){
      hideColumn(thisObj,"gridContrctExcPremProf","txtByngPt")
      hideColumn(thisObj,"gridContrctExcPremProf","txtContrct")
      hideColumn(thisObj,"gridContrctExcPremProf","txtSC95")
      hideColumn(thisObj,"gridContrctExcPremProf","txt1007")
      hideColumn(thisObj,"gridContrctExcPremProf","txtInspLoc")
      hideColumn(thisObj,"gridContrctExcPremProf","txtTransferShipLoc")
      hideColumn(thisObj,"gridContrctExcPremProf","txtTransferRecLoc")
      hideColumn(thisObj,"gridContrctExcPremProf","txtTransferNum")
    }
    else if(FunctionName == 'Contract'){
      hideColumn(thisObj,"gridContrctExcPremProf","txtSC95")
      hideColumn(thisObj,"gridContrctExcPremProf","txt1007")
      hideColumn(thisObj,"gridContrctExcPremProf","txtInspLoc")
      hideColumn(thisObj,"gridContrctExcPremProf","txtTransferShipLoc")
      hideColumn(thisObj,"gridContrctExcPremProf","txtTransferRecLoc")
      hideColumn(thisObj,"gridContrctExcPremProf","txtTransferNum")
    }
    else if(FunctionName == 'FV95'){
      //hideColumn(thisObj,"gridContrctExcPremProf","txtByngPt")
      hideColumn(thisObj,"gridContrctExcPremProf","txtContrct")
      hideColumn(thisObj,"gridContrctExcPremProf","txtInspLoc")
      hideColumn(thisObj,"gridContrctExcPremProf","txtTransferShipLoc")
      hideColumn(thisObj,"gridContrctExcPremProf","txtTransferRecLoc")
      hideColumn(thisObj,"gridContrctExcPremProf","txtTransferNum")
    }
    else if(FunctionName == 'Transfer'){
      hideColumn(thisObj,"gridContrctExcPremProf","txtByngPt")
      hideColumn(thisObj,"gridContrctExcPremProf","txtContrct")
      hideColumn(thisObj,"gridContrctExcPremProf","txtSC95")
      hideColumn(thisObj,"gridContrctExcPremProf","txt1007")
      hideColumn(thisObj,"gridContrctExcPremProf","txtInspLoc")
    }
  }

  // Method for getting View Payment details
  const ReadPayments_Cont = async () => {

    let buy_pt_id = ParentData.buy_point_id;
    let audit_cont_num = ParentData.audit_cont_num;
    let tmpdata = []
    let Obj = {}
    setbuyingPoint(buy_pt_id);
    setcontractNumber(audit_cont_num);
    let readyToPay = await ContractManagementService.RetrieveReadyToPayDetails(buy_pt_id, audit_cont_num, null, null, null, null, null, 'B')
    for (let i = 0; i < readyToPay.length; i++) {
      Obj.txtChckNum = "R-T-P"
      Obj.txtVendr = readyToPay[i].payeeVendor
      Obj.txtStatus = "Unpaid"
      Obj.txtProceeds = Number(readyToPay[i].rtpProceeds).toFixed(2)
      Obj.txtMemo = readyToPay[i].intrAgreeNum
      Obj.txtSpclTyp = readyToPay[i].specialType
      Obj.txtIssueDate = ""
      Obj.txtClrdDt = ""
      Obj.txtVoidDate = ""
      Obj.txtByngPt = readyToPay[i].buyPtId
      Obj.txtContrct = readyToPay[i].auditContNum
      tmpdata.push(Obj)
      Obj = {}
    };
    let paymentActivity = await ContractManagementService.RetrievePaymentActivityDetails(buy_pt_id, null, null, audit_cont_num, null, null, null, null, null, null, null, null)
    if (paymentActivity.length > 0) {
      for (let i = 0; i < paymentActivity.length; i++) {
        Obj.txtChckNum = paymentActivity[i].checkNum
        Obj.txtVendr = paymentActivity[i].payeeVendor
        switch (paymentActivity[i].checkStatus) {
          case "I":
          case "R":
            Obj.txtStatus = "Issued";
            break;
          case "V":
            Obj.txtStatus = "Voided";
            break;
          case "C":
            Obj.txtStatus = "Cleared";
            break;
        }
        Obj.txtProceeds = Number(paymentActivity[i].checkProceeds).toFixed(2)
        Obj.txtMemo = paymentActivity[i].intrAgreeNum
        Obj.txtSpclTyp = paymentActivity[i].specialType
        if (paymentActivity[i].issuedDate !== null && paymentActivity[i].issuedDate !== '') {
          let month = new Date(paymentActivity[i].issuedDate).getMonth() + 1
          let date = new Date(paymentActivity[i].issuedDate).getDate();
          let year = new Date(paymentActivity[i].issuedDate).getFullYear();
          Obj.txtIssueDate = month + '/' + date + '/' + year;
        } else {
          Obj.txtIssueDate = ''
        }
        if (paymentActivity[i].clearDate !== null && paymentActivity[i].clearDate !== '') {
          let month = new Date(paymentActivity[i].clearDate).getMonth() + 1
          let date = new Date(paymentActivity[i].clearDate).getDate();
          let year = new Date(paymentActivity[i].clearDate).getFullYear();
          Obj.txtClrdDt = month + '/' + date + '/' + year;
        } else {
          Obj.txtClrdDt = ''
        }
        if (paymentActivity[i].voidDate !== null && paymentActivity[i].voidDate !== '') {
          let month = new Date(paymentActivity[i].voidDate).getMonth() + 1
          let date = new Date(paymentActivity[i].voidDate).getDate();
          let year = new Date(paymentActivity[i].voidDate).getFullYear();
          Obj.txtVoidDate = month + '/' + date + '/' + year;
        } else {
          Obj.txtVoidDate = ''
        }
        Obj.txtByngPt = paymentActivity[i].buyPtId
        Obj.txtContrct = paymentActivity[i].auditContNum
        tmpdata.push(Obj)
        Obj = {}
      }
    };
    setValue(thisObj, "gridContrctExcPremProf", tmpdata)
    thisObj.setState(current => {
      return {
        ...current,
        rows:
          tmpdata
      }
    });
  };


  const ReadPayments_Insp = async () => {
    let buy_pt_id = ParentData.buy_point_id;
    let audit_insp_num = ParentData.txtTradeInspNum;
    let lbl1007 = ParentData.lbl1007
    let tmpdata = []
    let Obj = {}
    let readyToPay = await ContractManagementService.RetrieveReadyToPayDetails(buy_pt_id, null, null, null, audit_insp_num, null, null, 'B')
    for (let i = 0; i < readyToPay.length; i++) {
      Obj.txtChckNum = "R-T-P"
      Obj.txtVendr = readyToPay[i].payeeVendor
      Obj.txtStatus = "Unpaid"
      Obj.txtProceeds = Number(readyToPay[i].rtpProceeds).toFixed(2)
      Obj.txtMemo = readyToPay[i].intrAgreeNum
      Obj.txtSpclTyp = readyToPay[i].specialType
      Obj.txtIssueDate = ""
      Obj.txtClrdDt = ""
      Obj.txtVoidDate = ""
      Obj.txtByngPt = readyToPay[i].buyPtId
      Obj.txtSC95 = audit_insp_num
      Obj.txt1007 = lbl1007
      Obj.txtContrct = readyToPay[i].auditContNum
      tmpdata.push(Obj)
      Obj = {}
    };
    let paymentActivity = await ContractManagementService.RetrievePaymentActivityDetails(buy_pt_id, null, null, null, null, null, audit_insp_num, null, null, null, null, null)
    if (paymentActivity.length > 0) {
      for (let i = 0; i < paymentActivity.length; i++) {
        Obj.txtChckNum = paymentActivity[i].checkNum
        Obj.txtVendr = paymentActivity[i].payeeVendor
        switch (paymentActivity[i].checkStatus) {
          case "I":
          case "R":
            Obj.txtStatus = "Issued";
            break;
          case "V":
            Obj.txtStatus = "Voided";
            break;
          case "C":
            Obj.txtStatus = "Cleared";
            break;
        }
        Obj.txtProceeds = Number(paymentActivity[i].checkProceeds).toFixed(2)
        Obj.txtMemo = paymentActivity[i].intrAgreeNum
        Obj.txtSpclTyp = paymentActivity[i].specialType
        if (paymentActivity[i].issuedDate !== null && paymentActivity[i].issuedDate !== '') {
          let month = new Date(paymentActivity[i].issuedDate).getMonth() + 1
          let date = new Date(paymentActivity[i].issuedDate).getDate();
          let year = new Date(paymentActivity[i].issuedDate).getFullYear();
          Obj.txtIssueDate = month + '/' + date + '/' + year;
        } else {
          Obj.txtIssueDate = ''
        }
        if (paymentActivity[i].clearDate !== null && paymentActivity[i].clearDate !== '') {
          let month = new Date(paymentActivity[i].clearDate).getMonth() + 1
          let date = new Date(paymentActivity[i].clearDate).getDate();
          let year = new Date(paymentActivity[i].clearDate).getFullYear();
          Obj.txtClrdDt = month + '/' + date + '/' + year;
        } else {
          Obj.txtClrdDt = ''
        }
        if (paymentActivity[i].voidDate !== null && paymentActivity[i].voidDate !== '') {
          let month = new Date(paymentActivity[i].voidDate).getMonth() + 1
          let date = new Date(paymentActivity[i].voidDate).getDate();
          let year = new Date(paymentActivity[i].voidDate).getFullYear();
          Obj.txtVoidDate = month + '/' + date + '/' + year;
        } else {
          Obj.txtVoidDate = ''
        }
        Obj.txtByngPt = paymentActivity[i].buyPtId
        Obj.txtSC95 = audit_insp_num
        Obj.txt1007 = lbl1007
        Obj.txtContrct = paymentActivity[i].auditContNum
        tmpdata.push(Obj)
        Obj = {}
      }
    };
    setValue(thisObj, "gridContrctExcPremProf", tmpdata)
    thisObj.setState(current => {
      return {
        ...current,
        rows:
          tmpdata
      }
    });
  }

  const ReadPayments_Xref = async () => {
    let rec_buy_pt_ID = ParentData.rec_buy_pt_ID;
    let shp_buy_pt = ParentData.shp_buy_pt;
    let audit_xfer_num = ParentData.audit_xfer_num;
    let Obj = {}
    let tmpdata = []
    let ReadPayments = await ContractManagementService.RetrieveFreightAccrualPayDetails(shp_buy_pt, audit_xfer_num)
    for (var i = 0; i < ReadPayments.length; i++) {
      Obj.txtChckNum = ReadPayments[i].check_num
      Obj.txtVendr = ReadPayments[i].frt_vendor
      switch (ReadPayments[i].check_status) {
        case "I":
        case "R":
          Obj.txtStatus = "Issued";
          break;
        case "V":
          Obj.txtStatus = "Voided";
          break;
        case "C":
          Obj.txtStatus = "Cleared";
          break;
        default:
          Obj.txtStatus = "Unpaid";
          break;
      }
      Obj.txtProceeds = Number(ReadPayments[i].accrual_amount).toFixed(2)
      Obj.txtMemo = ReadPayments[i].audit_memo_num
      Obj.txtSpclTyp = ReadPayments[i].audit_special_type

      if (ReadPayments[i].issued_date !== null && ReadPayments[i].issued_date !== '' && ReadPayments[i].issued_date != '0001-01-01T00:00:00') {
        let month = new Date(ReadPayments[i].issued_date).getMonth() + 1
        let date = new Date(ReadPayments[i].issued_date).getDate();
        let year = new Date(ReadPayments[i].issued_date).getFullYear();
        Obj.txtIssueDate = month + '/' + date + '/' + year;
      } else {
        Obj.txtIssueDate = ''
      }
      if (ReadPayments[i].clear_date !== null && ReadPayments[i].clear_date !== '' && ReadPayments[i].clear_date != '0001-01-01T00:00:00') {
        let month = new Date(ReadPayments[i].clear_date).getMonth() + 1
        let date = new Date(ReadPayments[i].clear_date).getDate();
        let year = new Date(ReadPayments[i].clear_date).getFullYear();
        Obj.txtClrdDt = month + '/' + date + '/' + year;
      } else {
        Obj.txtClrdDt = ''
      }
      if (ReadPayments[i].void_date !== null && ReadPayments[i].void_date !== '') {
        let month = new Date(ReadPayments[i].void_date).getMonth() + 1
        let date = new Date(ReadPayments[i].void_date).getDate();
        let year = new Date(ReadPayments[i].void_date).getFullYear();
        //Obj.txtVoidDate = month + '/' + date + '/' + year;
      } else {
        Obj.txtVoidDate = ''
      }
      Obj.txtTransferShipLoc = ReadPayments[i].audit_buy_pt
      Obj.txtTransferRecLoc = rec_buy_pt_ID
      Obj.txtTransferNum = ReadPayments[i].audit_xfer_num
      tmpdata.push(Obj)
      Obj = {}
    }
    setValue(thisObj, "gridContrctExcPremProf", tmpdata)
    thisObj.setState(current => {
      return {
        ...current,
        rows:
          tmpdata
      }
    });
  }

  const Print = () => {

    let FunctionName = ParentData.Function_Name
    if (FunctionName == "Contract") {
      var data1 = getValue(thisObj, "gridContrctExcPremProf")
      if (data1.length != 0) {
        var res = []
        for (var i = 0; i < data1.length; i++) {
          res.push([
            data1[i].txtChckNum, data1[i].txtVendr, data1[i].txtStatus, data1[i].txtProceeds, data1[i].txtMemo, data1[i].txtSpclTyp, data1[i].txtIssueDate, data1[i].txtClrdDt, data1[i].txtVoidDate, data1[i].txtByngPt, data1[i].txtContrct
          ])
        }
        var obj = new jsPDF();
        obj.autoTable({
          styles: { fontSize: 5, lineWidth: 0.2 },
          margin: { top: 5, left: 0.5 },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Check Num', 'Vendor', 'Status', 'Proceeds', 'Memo Num', 'Special Type', 'Issued Date', 'Cleared Date', 'Void Date', 'Buying Point', 'Contract#'
            ]
          ]
        });
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Routine:viewPayment.cmdPrint_Click")
          }
        }
        else {
          alert("Routine:viewPayment.cmdPrint_Click")
        }
      }
    }
    if (FunctionName == 'FV95') {

      var data1 = getValue(thisObj, "gridContrctExcPremProf")

      if (data1.length != 0) {
        var res = []
        for (var i = 0; i < data1.length; i++) {
          res.push([
            data1[i].txtChckNum, data1[i].txtVendr, data1[i].txtStatus, data1[i].txtProceeds, data1[i].txtMemo, data1[i].txtSpclTyp, data1[i].txtIssueDate, data1[i].txtClrdDt, data1[i].txtVoidDate, data1[i].txtInspLoc, data1[i].txtSC95, data1[i].txt1007
          ])
        }
        var obj = new jsPDF();
        obj.autoTable({
          styles: { fontSize: 5, lineWidth: 0.2 },
          margin: { top: 5, left: 0.5 },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Check Num', 'Vendor', 'Status', 'Proceeds', 'Memo Num', 'Special Type', 'Issued Date', 'Cleared Date', 'Void Date', 'Buying Point', 'SC95#', '1007#'
            ]
          ]
        });
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Routine:viewPayment.cmdPrint_Click")
          }
        }
        else {
          alert("Routine:viewPayment.cmdPrint_Click")
        }
      }

    }
    if (FunctionName == 'Transfer') {
      var data1 = getValue(thisObj, "gridContrctExcPremProf")
      let shp_buy_pt = ParentData.shp_buy_pt;
      let audit_xfer_num = ParentData.audit_xfer_num;
      let fileName1 = "Payment History Transfer" + ' ' + shp_buy_pt + audit_xfer_num;
      if (data1.length != 0) {
        var res = []
        for (var i = 0; i < data1.length; i++) {
          res.push([
            data1[i].txtChckNum, data1[i].txtVendr, data1[i].txtStatus, data1[i].txtProceeds, data1[i].txtMemo, data1[i].txtSpclTyp, data1[i].txtIssueDate, data1[i].txtClrdDt, data1[i].txtVoidDate, data1[i].txtTransferShipLoc, data1[i].txtTransferRecLoc, data1[i].txtTransferNum
          ])
        }
        var obj = new jsPDF();
        obj.autoTable({
          styles: { fontSize: 5, lineWidth: 0.2 },
          margin: { top: 5, left: 0.5 },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              'Check Num', 'Vendor', 'Status', 'Proceeds', 'Memo Num', 'Special Type', 'Issued Date', 'Cleared Date', 'Void Date', 'Shipping Loc', 'Receiving Loc', 'Transfer #'
            ]
          ]
        });
        var pageCount = obj.internal.getNumberOfPages();
        for (let w = 0; w <= pageCount; w++) {
          obj.setPage(w);
          let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
          obj.setFontSize(8);
         obj.text( fileName1, 10, obj.internal.pageSize.height - 10);
        }
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Routine:viewPayment.cmdPrint_Click")
          }
        }
        else {
          alert("Routine:viewPayment.cmdPrint_Click")
        }
      }
    }
  }

  // Print Button Functionality
  const onbtnPrintClick = () => {
    try {
      Print()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const Excel = () => {
    let FunctionName = ParentData.Function_Name
    if (FunctionName == "Contract") {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, 'gridContrctExcPremProf')
      if (excelData.length != 0) {
        var fileName = "Payments History Contract" + ' ' + buyingPoint + contractNumber;
        const arrayData = []
        for (var i = 0; i < excelData.length; i++) {
          var Obj = {
            "Check Num": ExcelNumericDataConvert(excelData[i].txtChckNum),
            "Vendor": ExcelNumericDataConvert(excelData[i].txtVendr),
            "Status": excelData[i].txtStatus,
            "Proceeds": ExcelNumericDataConvert(excelData[i].txtProceeds),
            "Memo Num": ExcelNumericDataConvert(excelData[i].txtMemo),
            "Special Type": excelData[i].txtSpclTyp,
            "Issued Date": excelData[i].txtIssueDate,
            "Cleared Date": excelData[i].txtClrdDt,
            "Void Date": excelData[i].txtVoidDate,
            "Buying Point": excelData[i].txtByngPt,
            "Contract# ": ExcelNumericDataConvert(excelData[i].txtContrct)
          }
          arrayData.push(Obj);
        }
        const ws = XLSX.utils.json_to_sheet(arrayData)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var FileSaver = require('file-saver');
        FileSaver.saveAs(data, fileName + fileExtension);
      }
    }
    if (FunctionName == 'FV95') {
      let buy_pt_id = ParentData.buy_point_id;
      let audit_insp_num = ParentData.txtTradeInspNum;
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, 'gridContrctExcPremProf')
      if (excelData.length != 0) {
        var fileName = "Payment History SC95 " + ' ' + buy_pt_id + audit_insp_num;
        const arrayData = []
        for (var i = 0; i < excelData.length; i++) {
          var Obj = {

            "Check Num": excelData[i].txtChckNum,
            "Vendor": ExcelNumericDataConvert(excelData[i].txtVendr),
            "Status": excelData[i].txtStatus,
            "Proceeds": ExcelNumericDataConvert(excelData[i].txtProceeds),
            "Memo Num": ExcelNumericDataConvert(excelData[i].txtMemo),
            "Special Type": excelData[i].txtSpclTyp,
            "Issued Date": excelData[i].txtIssueDate,
            "Cleared Date": excelData[i].txtClrdDt,
            "Void Date": excelData[i].txtVoidDate,
            "Buying Point": excelData[i].txtInspLoc,
            "SC95# ": excelData[i].txtSC95,
            "1007#": excelData[i].txt1007,
          }
          arrayData.push(Obj);
        }
        const ws = XLSX.utils.json_to_sheet(arrayData)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var FileSaver = require('file-saver');
        FileSaver.saveAs(data, fileName + fileExtension);
      }
    }
    if (FunctionName == 'Transfer') {
      let shp_buy_pt = ParentData.shp_buy_pt;
      let audit_xfer_num = ParentData.audit_xfer_num;
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, 'gridContrctExcPremProf')
      if (excelData.length != 0) {
        let fileName = "Payment History Transfer" + ' ' + shp_buy_pt + audit_xfer_num;
        const arrayData = []
        for (var i = 0; i < excelData.length; i++) {
          var Obj = {

            "Check Num": ExcelNumericDataConvert(excelData[i].txtChckNum),
            "Vendor": ExcelNumericDataConvert(excelData[i].txtVendr),
            "Status": excelData[i].txtStatus,
            "Proceeds": ExcelNumericDataConvert(excelData[i].txtProceeds),
            "Memo Num": ExcelNumericDataConvert(excelData[i].txtMemo),
            "Special Type": excelData[i].txtSpclTyp,
            "Issued Date": excelData[i].txtIssueDate,
            "Cleared Date": excelData[i].txtClrdDt,
            "Void Date": excelData[i].txtVoidDate,
            "Shipping Loc": excelData[i].txtTransferShipLoc,
            "Receiving Loc ": excelData[i].txtTransferRecLoc,
            "Transfer # ": excelData[i].txtTransferNum

          }
          arrayData.push(Obj);
        }
        const ws = XLSX.utils.json_to_sheet(arrayData)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        let FileSaver = require('file-saver');
        FileSaver.saveAs(data, fileName + fileExtension);
      }
    }

  }
  // Export to excel functionality
  const onbtnExportClick = async () => {
    try {
      Excel()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnExportClick event:Click"
        );
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnExportClick = onbtnExportClick;

  // START_USER_CODE-USER_METHODS
  const onbtnCloseClick = () => {
    try {
      document.getElementById("ContractManagement_ViewPaymentsPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} headerDetails={state.headerDetails} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ViewPayments*/}

              {/* END_USER_CODE-USER_BEFORE_ViewPayments*/}

              {/* START_USER_CODE-USER_BEFORE_gridContrctExcPremProf*/}

              {/* END_USER_CODE-USER_BEFORE_gridContrctExcPremProf*/}
              <GroupBoxWidget
                conf={state.grpbxViewWRPayments}
                screenConf={state}
              >
                <LabelWidget
                  values={values}
                  conf={state.lblPayments}
                  screenConf={state}
                ></LabelWidget>
                </GroupBoxWidget>
              <GridWidget
                conf={state.gridContrctExcPremProf}
                screenConf={state}
                linkClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                errors={errors}
                rows={state.rows}
                onEvent={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                setFieldValue={setFieldValue}
              ></GridWidget>
              {/* START_USER_CODE-USER_AFTER_gridContrctExcPremProf*/}

              {/* END_USER_CODE-USER_AFTER_gridContrctExcPremProf*/}
              {/* START_USER_CODE-USER_BEFORE_btnContainer*/}

              {/* END_USER_CODE-USER_BEFORE_btnContainer*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.btnContainer}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExport*/}

                {/* END_USER_CODE-USER_BEFORE_btnExport*/}

                <ButtonWidget
                  conf={state.btnExport}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    //GetViewPayment.setDomLayout('print')
                    //GetViewPayment.setDomLayout()
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExport*/}

                {/* END_USER_CODE-USER_AFTER_btnExport*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_btnContainer*/}

              {/* END_USER_CODE-USER_AFTER_btnContainer*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}

export default withRouter(ContractManagement_ViewPayments);