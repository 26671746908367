/* eslint-disable*/
import React from "react";
import Settlements_TradeSettleApplyView from "./TradeSettleApplyView";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("TradeSettleApplyView Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettleApplyView />);
    });
  });
  afterEach(cleanup);
  test("is TradeSettleApplyView Loads Successfully", () => {
    expect(screen.getByText("TradeSettleApplyView")).toBeInTheDocument;
  });
  test("Custom Test Cases for TradeSettleApplyView", () => {
    // START_USER_CODE-USER_TradeSettleApplyView_Custom_Test_Case
    // END_USER_CODE-USER_TradeSettleApplyView_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettleApplyView />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("Settlements:TradeSettleApplyView_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("gridAgreementApplications(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplicationsbtn =
      gridAgreementApplications.nextElementSibling.firstElementChild;
    gridAgreementApplications =
      gridAgreementApplications.parentElement.parentElement.parentElement;
    expect(gridAgreementApplications.tagName).toBe("DIV");
    expect(gridAgreementApplications.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAgreementApplications", () => {
    // START_USER_CODE-USER_gridAgreementApplications_TEST
    // END_USER_CODE-USER_gridAgreementApplications_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxTradeSettleApplyView(GroupBox Widget) Test Cases", async () => {
    const grpbxTradeSettleApplyView = screen.getByTestId(
      "grpbxTradeSettleApplyView"
    );
    expect(grpbxTradeSettleApplyView.tagName).toBe("BUTTON");
    expect(grpbxTradeSettleApplyView.type).toBe("button");
    expect(grpbxTradeSettleApplyView.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTradeSettleApplyView", () => {
    // START_USER_CODE-USER_grpbxTradeSettleApplyView_TEST
    // END_USER_CODE-USER_grpbxTradeSettleApplyView_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("Settlements:TradeSettleApplyView_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("Settlements:TradeSettleApplyView_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblAgreementApplications(Label Widget) Test Cases", async () => {
    const lblAgreementApplications = screen.getByTestId(
      "lblAgreementApplications"
    );
    expect(lblAgreementApplications.tagName).toBe("LABEL");
    expect(lblAgreementApplications.classList).toContain("form-label");
    expect(lblAgreementApplications.textContent).toEqual(
      t("Settlements:TradeSettleApplyView_lblAgreementApplications")
    );
  });
  test("Custom Test Cases for lblAgreementApplications", () => {
    // START_USER_CODE-USER_lblAgreementApplications_TEST
    // END_USER_CODE-USER_lblAgreementApplications_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("Settlements:TradeSettleApplyView_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("Settlements:TradeSettleApplyView_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("gridAgreementApplications_txtcolAgreedPounds(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolAgreedPounds = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolAgreedPoundsbtn =
      gridAgreementApplications_txtcolAgreedPounds.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolAgreedPounds =
      gridAgreementApplications_txtcolAgreedPounds.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolAgreedPounds.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolAgreedPounds.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreedPounds", () => {
    // START_USER_CODE-USER_txtcolAgreedPounds_TEST
    // END_USER_CODE-USER_txtcolAgreedPounds_TEST
  });
  test("gridAgreementApplications_txtcolAgreeDt(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolAgreeDt = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolAgreeDtbtn =
      gridAgreementApplications_txtcolAgreeDt.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolAgreeDt =
      gridAgreementApplications_txtcolAgreeDt.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolAgreeDt.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolAgreeDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreeDt", () => {
    // START_USER_CODE-USER_txtcolAgreeDt_TEST
    // END_USER_CODE-USER_txtcolAgreeDt_TEST
  });
  test("gridAgreementApplications_txtcolAgreeNum(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolAgreeNum = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolAgreeNumbtn =
      gridAgreementApplications_txtcolAgreeNum.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolAgreeNum =
      gridAgreementApplications_txtcolAgreeNum.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolAgreeNum.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolAgreeNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAgreeNum", () => {
    // START_USER_CODE-USER_txtcolAgreeNum_TEST
    // END_USER_CODE-USER_txtcolAgreeNum_TEST
  });
  test("gridAgreementApplications_txtcolApplied(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolApplied = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolAppliedbtn =
      gridAgreementApplications_txtcolApplied.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolApplied =
      gridAgreementApplications_txtcolApplied.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolApplied.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolApplied.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolApplied", () => {
    // START_USER_CODE-USER_txtcolApplied_TEST
    // END_USER_CODE-USER_txtcolApplied_TEST
  });
  test("gridAgreementApplications_txtcolOpenBalance(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolOpenBalance = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolOpenBalancebtn =
      gridAgreementApplications_txtcolOpenBalance.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolOpenBalance =
      gridAgreementApplications_txtcolOpenBalance.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolOpenBalance.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolOpenBalance.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpenBalance", () => {
    // START_USER_CODE-USER_txtcolOpenBalance_TEST
    // END_USER_CODE-USER_txtcolOpenBalance_TEST
  });
  test("gridAgreementApplications_txtcolPeanutType(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolPeanutType = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolPeanutTypebtn =
      gridAgreementApplications_txtcolPeanutType.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolPeanutType =
      gridAgreementApplications_txtcolPeanutType.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolPeanutType.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolPeanutType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutType", () => {
    // START_USER_CODE-USER_txtcolPeanutType_TEST
    // END_USER_CODE-USER_txtcolPeanutType_TEST
  });
  test("gridAgreementApplications_txtcolPeanutVariety(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolPeanutVariety = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolPeanutVarietybtn =
      gridAgreementApplications_txtcolPeanutVariety.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolPeanutVariety =
      gridAgreementApplications_txtcolPeanutVariety.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolPeanutVariety.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolPeanutVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutVariety", () => {
    // START_USER_CODE-USER_txtcolPeanutVariety_TEST
    // END_USER_CODE-USER_txtcolPeanutVariety_TEST
  });
  test("gridAgreementApplications_txtcolPoundsToApply(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolPoundsToApply = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolPoundsToApplybtn =
      gridAgreementApplications_txtcolPoundsToApply.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolPoundsToApply =
      gridAgreementApplications_txtcolPoundsToApply.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolPoundsToApply.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolPoundsToApply.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPoundsToApply", () => {
    // START_USER_CODE-USER_txtcolPoundsToApply_TEST
    // END_USER_CODE-USER_txtcolPoundsToApply_TEST
  });
  test("gridAgreementApplications_txtcolPrice(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolPrice = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolPricebtn =
      gridAgreementApplications_txtcolPrice.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolPrice =
      gridAgreementApplications_txtcolPrice.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolPrice.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolPrice.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPrice", () => {
    // START_USER_CODE-USER_txtcolPrice_TEST
    // END_USER_CODE-USER_txtcolPrice_TEST
  });
  test("gridAgreementApplications_txtcolPricingMethod(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolPricingMethod = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolPricingMethodbtn =
      gridAgreementApplications_txtcolPricingMethod.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolPricingMethod =
      gridAgreementApplications_txtcolPricingMethod.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolPricingMethod.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolPricingMethod.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPricingMethod", () => {
    // START_USER_CODE-USER_txtcolPricingMethod_TEST
    // END_USER_CODE-USER_txtcolPricingMethod_TEST
  });
  test("gridAgreementApplications_txtcolPurchaseSale(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolPurchaseSale = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolPurchaseSalebtn =
      gridAgreementApplications_txtcolPurchaseSale.nextElementSibling
        .firstElementChild;
    gridAgreementApplications_txtcolPurchaseSale =
      gridAgreementApplications_txtcolPurchaseSale.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolPurchaseSale.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolPurchaseSale.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPurchaseSale", () => {
    // START_USER_CODE-USER_txtcolPurchaseSale_TEST
    // END_USER_CODE-USER_txtcolPurchaseSale_TEST
  });
  test("gridAgreementApplications_txtcolSeed(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolSeed = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolSeedbtn =
      gridAgreementApplications_txtcolSeed.nextElementSibling.firstElementChild;
    gridAgreementApplications_txtcolSeed =
      gridAgreementApplications_txtcolSeed.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolSeed.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolSeed.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeed", () => {
    // START_USER_CODE-USER_txtcolSeed_TEST
    // END_USER_CODE-USER_txtcolSeed_TEST
  });
  test("gridAgreementApplications_txtcolType(Grid Widget) Test Cases", async () => {
    let gridAgreementApplications_txtcolType = screen.getByTestId(
      "gridAgreementApplications"
    );
    let gridAgreementApplications_txtcolTypebtn =
      gridAgreementApplications_txtcolType.nextElementSibling.firstElementChild;
    gridAgreementApplications_txtcolType =
      gridAgreementApplications_txtcolType.parentElement.parentElement
        .parentElement;
    expect(gridAgreementApplications_txtcolType.tagName).toBe("DIV");
    expect(gridAgreementApplications_txtcolType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeSettleApplyView_gridAgreementApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolType", () => {
    // START_USER_CODE-USER_txtcolType_TEST
    // END_USER_CODE-USER_txtcolType_TEST
  });
  test("txtPeanutType(Textbox Widget) Test Cases", async () => {
    const txtPeanutType = screen.getByTestId("txtPeanutType");
    expect(txtPeanutType.tagName).toBe("INPUT");
    expect(txtPeanutType.type).toBe("text");
    expect(txtPeanutType.classList).toContain("textboxWidgetClass");
    expect(txtPeanutType.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApplyView_txtPeanutType")
    );
    await act(async () => {
      userEvent.type(txtPeanutType, "123");
    });
    expect(txtPeanutType.getAttribute("value")).toBe("");
    expect(txtPeanutType.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
  test("txtPeanutVariety(Textbox Widget) Test Cases", async () => {
    const txtPeanutVariety = screen.getByTestId("txtPeanutVariety");
    expect(txtPeanutVariety.tagName).toBe("INPUT");
    expect(txtPeanutVariety.type).toBe("text");
    expect(txtPeanutVariety.classList).toContain("textboxWidgetClass");
    expect(txtPeanutVariety.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApplyView_txtPeanutVariety")
    );
    await act(async () => {
      userEvent.type(txtPeanutVariety, "123");
    });
    expect(txtPeanutVariety.getAttribute("value")).toBe("");
    expect(txtPeanutVariety.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPeanutVariety", () => {
    // START_USER_CODE-USER_txtPeanutVariety_TEST
    // END_USER_CODE-USER_txtPeanutVariety_TEST
  });
  test("txtPounds(Textbox Widget) Test Cases", async () => {
    const txtPounds = screen.getByTestId("txtPounds");
    expect(txtPounds.tagName).toBe("INPUT");
    expect(txtPounds.type).toBe("text");
    expect(txtPounds.classList).toContain("textboxWidgetClass");
    expect(txtPounds.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApplyView_txtPounds")
    );
    await act(async () => {
      userEvent.type(txtPounds, "123");
    });
    expect(txtPounds.getAttribute("value")).toBe("");
    expect(txtPounds.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPounds", () => {
    // START_USER_CODE-USER_txtPounds_TEST
    // END_USER_CODE-USER_txtPounds_TEST
  });
  test("txtPurchaseSale(Textbox Widget) Test Cases", async () => {
    const txtPurchaseSale = screen.getByTestId("txtPurchaseSale");
    expect(txtPurchaseSale.tagName).toBe("INPUT");
    expect(txtPurchaseSale.type).toBe("text");
    expect(txtPurchaseSale.classList).toContain("textboxWidgetClass");
    expect(txtPurchaseSale.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApplyView_txtPurchaseSale")
    );
    await act(async () => {
      userEvent.type(txtPurchaseSale, "123");
    });
    expect(txtPurchaseSale.getAttribute("value")).toBe("");
    expect(txtPurchaseSale.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPurchaseSale", () => {
    // START_USER_CODE-USER_txtPurchaseSale_TEST
    // END_USER_CODE-USER_txtPurchaseSale_TEST
  });
  test("txtSeed(Textbox Widget) Test Cases", async () => {
    const txtSeed = screen.getByTestId("txtSeed");
    expect(txtSeed.tagName).toBe("INPUT");
    expect(txtSeed.type).toBe("text");
    expect(txtSeed.classList).toContain("textboxWidgetClass");
    expect(txtSeed.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApplyView_txtSeed")
    );
    await act(async () => {
      userEvent.type(txtSeed, "123");
    });
    expect(txtSeed.getAttribute("value")).toBe("");
    expect(txtSeed.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeed", () => {
    // START_USER_CODE-USER_txtSeed_TEST
    // END_USER_CODE-USER_txtSeed_TEST
  });
  test("txtSettleNumuic0(Textbox Widget) Test Cases", async () => {
    const txtSettleNumuic0 = screen.getByTestId("txtSettleNumuic0");
    expect(txtSettleNumuic0.tagName).toBe("INPUT");
    expect(txtSettleNumuic0.type).toBe("text");
    expect(txtSettleNumuic0.classList).toContain("textboxWidgetClass");
    expect(txtSettleNumuic0.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApplyView_txtSettleNumuic0")
    );
    await act(async () => {
      userEvent.type(txtSettleNumuic0, "123");
    });
    expect(txtSettleNumuic0.getAttribute("value")).toBe("");
    expect(txtSettleNumuic0.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSettleNumuic0", () => {
    // START_USER_CODE-USER_txtSettleNumuic0_TEST
    // END_USER_CODE-USER_txtSettleNumuic0_TEST
  });
  test("txtTradeInspNum(Textbox Widget) Test Cases", async () => {
    const txtTradeInspNum = screen.getByTestId("txtTradeInspNum");
    expect(txtTradeInspNum.tagName).toBe("INPUT");
    expect(txtTradeInspNum.type).toBe("text");
    expect(txtTradeInspNum.classList).toContain("textboxWidgetClass");
    expect(txtTradeInspNum.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleApplyView_txtTradeInspNum")
    );
    await act(async () => {
      userEvent.type(txtTradeInspNum, "123");
    });
    expect(txtTradeInspNum.getAttribute("value")).toBe("");
    expect(txtTradeInspNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTradeInspNum", () => {
    // START_USER_CODE-USER_txtTradeInspNum_TEST
    // END_USER_CODE-USER_txtTradeInspNum_TEST
  });
});
