/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_PriceScheduleAuditDetail from "./PriceScheduleAuditDetail";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PriceScheduleAuditDetail Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_PriceScheduleAuditDetail />
      );
    });
  });
  afterEach(cleanup);
  test("is PriceScheduleAuditDetail Loads Successfully", () => {
    expect(screen.getByText("PriceScheduleAuditDetail")).toBeInTheDocument;
  });
  test("Custom Test Cases for PriceScheduleAuditDetail", () => {
    // START_USER_CODE-USER_PriceScheduleAuditDetail_Custom_Test_Case
    // END_USER_CODE-USER_PriceScheduleAuditDetail_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_PriceScheduleAuditDetail />
      );
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("gridPriceScheduleAuditDetail_btncolDiscSchd(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_btncolDiscSchd = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_btncolDiscSchdbtn =
      gridPriceScheduleAuditDetail_btncolDiscSchd.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_btncolDiscSchd =
      gridPriceScheduleAuditDetail_btncolDiscSchd.parentElement.parentElement
        .parentElement;
    expect(gridPriceScheduleAuditDetail_btncolDiscSchd.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditDetail_btncolDiscSchd.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for btncolDiscSchd", () => {
    // START_USER_CODE-USER_btncolDiscSchd_TEST
    // END_USER_CODE-USER_btncolDiscSchd_TEST
  });
  test("gridPriceScheduleAuditDetail(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetailbtn =
      gridPriceScheduleAuditDetail.nextElementSibling.firstElementChild;
    gridPriceScheduleAuditDetail =
      gridPriceScheduleAuditDetail.parentElement.parentElement.parentElement;
    expect(gridPriceScheduleAuditDetail.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditDetail.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPriceScheduleAuditDetail", () => {
    // START_USER_CODE-USER_gridPriceScheduleAuditDetail_TEST
    // END_USER_CODE-USER_gridPriceScheduleAuditDetail_TEST
  });
  test("grpbxPriceScheduleAuditDetail(GroupBox Widget) Test Cases", async () => {
    const grpbxPriceScheduleAuditDetail = screen.getByTestId(
      "grpbxPriceScheduleAuditDetail"
    );
    expect(grpbxPriceScheduleAuditDetail.tagName).toBe("BUTTON");
    expect(grpbxPriceScheduleAuditDetail.type).toBe("button");
    expect(grpbxPriceScheduleAuditDetail.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPriceScheduleAuditDetail", () => {
    // START_USER_CODE-USER_grpbxPriceScheduleAuditDetail_TEST
    // END_USER_CODE-USER_grpbxPriceScheduleAuditDetail_TEST
  });
  test("txtAction(Textbox Widget) Test Cases", async () => {
    const txtAction = screen.getByTestId("txtAction");
    expect(txtAction.tagName).toBe("INPUT");
    expect(txtAction.type).toBe("text");
    expect(txtAction.classList).toContain("textboxWidgetClass");
    expect(txtAction.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_txtAction")
    );
    await act(async () => {
      userEvent.type(txtAction, "1");
    });
  });
  test("Custom Test Cases for txtAction", () => {
    // START_USER_CODE-USER_txtAction_TEST
    // END_USER_CODE-USER_txtAction_TEST
  });
  test("txtChangeDateTime(Textbox Widget) Test Cases", async () => {
    const txtChangeDateTime = screen.getByTestId("txtChangeDateTime");
    expect(txtChangeDateTime.tagName).toBe("INPUT");
    expect(txtChangeDateTime.type).toBe("text");
    expect(txtChangeDateTime.classList).toContain("textboxWidgetClass");
    expect(txtChangeDateTime.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_txtChangeDateTime"
      )
    );
    await act(async () => {
      userEvent.type(txtChangeDateTime, "1");
    });
  });
  test("Custom Test Cases for txtChangeDateTime", () => {
    // START_USER_CODE-USER_txtChangeDateTime_TEST
    // END_USER_CODE-USER_txtChangeDateTime_TEST
  });
  test("txtChangeUser(Textbox Widget) Test Cases", async () => {
    const txtChangeUser = screen.getByTestId("txtChangeUser");
    expect(txtChangeUser.tagName).toBe("INPUT");
    expect(txtChangeUser.type).toBe("text");
    expect(txtChangeUser.classList).toContain("textboxWidgetClass");
    expect(txtChangeUser.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_txtChangeUser"
      )
    );
    await act(async () => {
      userEvent.type(txtChangeUser, "1");
    });
  });
  test("Custom Test Cases for txtChangeUser", () => {
    // START_USER_CODE-USER_txtChangeUser_TEST
    // END_USER_CODE-USER_txtChangeUser_TEST
  });
  test("gridPriceScheduleAuditDetail_txtcolArea(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_txtcolArea = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_txtcolAreabtn =
      gridPriceScheduleAuditDetail_txtcolArea.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_txtcolArea =
      gridPriceScheduleAuditDetail_txtcolArea.parentElement.parentElement
        .parentElement;
    expect(gridPriceScheduleAuditDetail_txtcolArea.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditDetail_txtcolArea.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolArea", () => {
    // START_USER_CODE-USER_txtcolArea_TEST
    // END_USER_CODE-USER_txtcolArea_TEST
  });
  test("gridPriceScheduleAuditDetail_txtcolCrkBrkDoolFailPeanut(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_txtcolCrkBrkDoolFailPeanut = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_txtcolCrkBrkDoolFailPeanutbtn =
      gridPriceScheduleAuditDetail_txtcolCrkBrkDoolFailPeanut.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_txtcolCrkBrkDoolFailPeanut =
      gridPriceScheduleAuditDetail_txtcolCrkBrkDoolFailPeanut.parentElement
        .parentElement.parentElement;
    expect(
      gridPriceScheduleAuditDetail_txtcolCrkBrkDoolFailPeanut.tagName
    ).toBe("DIV");
    expect(
      gridPriceScheduleAuditDetail_txtcolCrkBrkDoolFailPeanut.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCrkBrkDoolFailPeanut", () => {
    // START_USER_CODE-USER_txtcolCrkBrkDoolFailPeanut_TEST
    // END_USER_CODE-USER_txtcolCrkBrkDoolFailPeanut_TEST
  });
  test("gridPriceScheduleAuditDetail_txtcolCrkBrkDoolThreshld(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_txtcolCrkBrkDoolThreshld = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_txtcolCrkBrkDoolThreshldbtn =
      gridPriceScheduleAuditDetail_txtcolCrkBrkDoolThreshld.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_txtcolCrkBrkDoolThreshld =
      gridPriceScheduleAuditDetail_txtcolCrkBrkDoolThreshld.parentElement
        .parentElement.parentElement;
    expect(gridPriceScheduleAuditDetail_txtcolCrkBrkDoolThreshld.tagName).toBe(
      "DIV"
    );
    expect(
      gridPriceScheduleAuditDetail_txtcolCrkBrkDoolThreshld.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCrkBrkDoolThreshld", () => {
    // START_USER_CODE-USER_txtcolCrkBrkDoolThreshld_TEST
    // END_USER_CODE-USER_txtcolCrkBrkDoolThreshld_TEST
  });
  test("gridPriceScheduleAuditDetail_txtcolElkPrem(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_txtcolElkPrem = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_txtcolElkPrembtn =
      gridPriceScheduleAuditDetail_txtcolElkPrem.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_txtcolElkPrem =
      gridPriceScheduleAuditDetail_txtcolElkPrem.parentElement.parentElement
        .parentElement;
    expect(gridPriceScheduleAuditDetail_txtcolElkPrem.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditDetail_txtcolElkPrem.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolElkPrem", () => {
    // START_USER_CODE-USER_txtcolElkPrem_TEST
    // END_USER_CODE-USER_txtcolElkPrem_TEST
  });
  test("gridPriceScheduleAuditDetail_txtcolFancyThreshld(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_txtcolFancyThreshld = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_txtcolFancyThreshldbtn =
      gridPriceScheduleAuditDetail_txtcolFancyThreshld.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_txtcolFancyThreshld =
      gridPriceScheduleAuditDetail_txtcolFancyThreshld.parentElement
        .parentElement.parentElement;
    expect(gridPriceScheduleAuditDetail_txtcolFancyThreshld.tagName).toBe(
      "DIV"
    );
    expect(
      gridPriceScheduleAuditDetail_txtcolFancyThreshld.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFancyThreshld", () => {
    // START_USER_CODE-USER_txtcolFancyThreshld_TEST
    // END_USER_CODE-USER_txtcolFancyThreshld_TEST
  });
  test("gridPriceScheduleAuditDetail_txtcolFanFailPnId(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_txtcolFanFailPnId = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_txtcolFanFailPnIdbtn =
      gridPriceScheduleAuditDetail_txtcolFanFailPnId.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_txtcolFanFailPnId =
      gridPriceScheduleAuditDetail_txtcolFanFailPnId.parentElement.parentElement
        .parentElement;
    expect(gridPriceScheduleAuditDetail_txtcolFanFailPnId.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditDetail_txtcolFanFailPnId.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFanFailPnId", () => {
    // START_USER_CODE-USER_txtcolFanFailPnId_TEST
    // END_USER_CODE-USER_txtcolFanFailPnId_TEST
  });
  test("gridPriceScheduleAuditDetail_txtcolLSKPerLbs(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_txtcolLSKPerLbs = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_txtcolLSKPerLbsbtn =
      gridPriceScheduleAuditDetail_txtcolLSKPerLbs.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_txtcolLSKPerLbs =
      gridPriceScheduleAuditDetail_txtcolLSKPerLbs.parentElement.parentElement
        .parentElement;
    expect(gridPriceScheduleAuditDetail_txtcolLSKPerLbs.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditDetail_txtcolLSKPerLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLSKPerLbs", () => {
    // START_USER_CODE-USER_txtcolLSKPerLbs_TEST
    // END_USER_CODE-USER_txtcolLSKPerLbs_TEST
  });
  test("gridPriceScheduleAuditDetail_txtcolMaxMoisture(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_txtcolMaxMoisture = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_txtcolMaxMoisturebtn =
      gridPriceScheduleAuditDetail_txtcolMaxMoisture.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_txtcolMaxMoisture =
      gridPriceScheduleAuditDetail_txtcolMaxMoisture.parentElement.parentElement
        .parentElement;
    expect(gridPriceScheduleAuditDetail_txtcolMaxMoisture.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditDetail_txtcolMaxMoisture.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMaxMoisture", () => {
    // START_USER_CODE-USER_txtcolMaxMoisture_TEST
    // END_USER_CODE-USER_txtcolMaxMoisture_TEST
  });
  test("gridPriceScheduleAuditDetail_txtcolMinFancyElkPrem(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_txtcolMinFancyElkPrem = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_txtcolMinFancyElkPrembtn =
      gridPriceScheduleAuditDetail_txtcolMinFancyElkPrem.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_txtcolMinFancyElkPrem =
      gridPriceScheduleAuditDetail_txtcolMinFancyElkPrem.parentElement
        .parentElement.parentElement;
    expect(gridPriceScheduleAuditDetail_txtcolMinFancyElkPrem.tagName).toBe(
      "DIV"
    );
    expect(
      gridPriceScheduleAuditDetail_txtcolMinFancyElkPrem.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMinFancyElkPrem", () => {
    // START_USER_CODE-USER_txtcolMinFancyElkPrem_TEST
    // END_USER_CODE-USER_txtcolMinFancyElkPrem_TEST
  });
  test("gridPriceScheduleAuditDetail_txtcolOkPerPct(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_txtcolOkPerPct = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_txtcolOkPerPctbtn =
      gridPriceScheduleAuditDetail_txtcolOkPerPct.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_txtcolOkPerPct =
      gridPriceScheduleAuditDetail_txtcolOkPerPct.parentElement.parentElement
        .parentElement;
    expect(gridPriceScheduleAuditDetail_txtcolOkPerPct.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditDetail_txtcolOkPerPct.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOkPerPct", () => {
    // START_USER_CODE-USER_txtcolOkPerPct_TEST
    // END_USER_CODE-USER_txtcolOkPerPct_TEST
  });
  test("gridPriceScheduleAuditDetail_txtcolPeanuType(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_txtcolPeanuType = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_txtcolPeanuTypebtn =
      gridPriceScheduleAuditDetail_txtcolPeanuType.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_txtcolPeanuType =
      gridPriceScheduleAuditDetail_txtcolPeanuType.parentElement.parentElement
        .parentElement;
    expect(gridPriceScheduleAuditDetail_txtcolPeanuType.tagName).toBe("DIV");
    expect(gridPriceScheduleAuditDetail_txtcolPeanuType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanuType", () => {
    // START_USER_CODE-USER_txtcolPeanuType_TEST
    // END_USER_CODE-USER_txtcolPeanuType_TEST
  });
  test("gridPriceScheduleAuditDetail_txtcolSMKSSPerPercent(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_txtcolSMKSSPerPercent = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_txtcolSMKSSPerPercentbtn =
      gridPriceScheduleAuditDetail_txtcolSMKSSPerPercent.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_txtcolSMKSSPerPercent =
      gridPriceScheduleAuditDetail_txtcolSMKSSPerPercent.parentElement
        .parentElement.parentElement;
    expect(gridPriceScheduleAuditDetail_txtcolSMKSSPerPercent.tagName).toBe(
      "DIV"
    );
    expect(
      gridPriceScheduleAuditDetail_txtcolSMKSSPerPercent.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSMKSSPerPercent", () => {
    // START_USER_CODE-USER_txtcolSMKSSPerPercent_TEST
    // END_USER_CODE-USER_txtcolSMKSSPerPercent_TEST
  });
  test("gridPriceScheduleAuditDetail_txtcolSupportPerTon(Grid Widget) Test Cases", async () => {
    let gridPriceScheduleAuditDetail_txtcolSupportPerTon = screen.getByTestId(
      "gridPriceScheduleAuditDetail"
    );
    let gridPriceScheduleAuditDetail_txtcolSupportPerTonbtn =
      gridPriceScheduleAuditDetail_txtcolSupportPerTon.nextElementSibling
        .firstElementChild;
    gridPriceScheduleAuditDetail_txtcolSupportPerTon =
      gridPriceScheduleAuditDetail_txtcolSupportPerTon.parentElement
        .parentElement.parentElement;
    expect(gridPriceScheduleAuditDetail_txtcolSupportPerTon.tagName).toBe(
      "DIV"
    );
    expect(
      gridPriceScheduleAuditDetail_txtcolSupportPerTon.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_gridPriceScheduleAuditDetail"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSupportPerTon", () => {
    // START_USER_CODE-USER_txtcolSupportPerTon_TEST
    // END_USER_CODE-USER_txtcolSupportPerTon_TEST
  });
  test("txtRevision(Textbox Widget) Test Cases", async () => {
    const txtRevision = screen.getByTestId("txtRevision");
    expect(txtRevision.tagName).toBe("INPUT");
    expect(txtRevision.type).toBe("text");
    expect(txtRevision.classList).toContain("textboxWidgetClass");
    expect(txtRevision.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:PriceScheduleAuditDetail_txtRevision"
      )
    );
    await act(async () => {
      userEvent.type(txtRevision, "1");
    });
  });
  test("Custom Test Cases for txtRevision", () => {
    // START_USER_CODE-USER_txtRevision_TEST
    // END_USER_CODE-USER_txtRevision_TEST
  });
});
