/* eslint-disable*/
import { StockTransferService } from "../Service/StockTransferService";

const defaultBinInfoProps = {
    COMP_ID: "",
    CROP_YEAR: 0,
    BUY_PT_ID: "",
    WHOUSE_NUM: "",
    BIN_NUM: "",
    WHOUSE_BIN_ID: "",
    STATUS_IND: "",
    PNUT_TYPE_ID: "",
    PNUT_VARIETY_ID: "",
    SEED_GEN: "",
    SEG_TYPE: "",
    EDIBLE_OIL_IND: "",
    OLEIC_IND: "",
    WHS_RCPT_IND: "",
    CAPACITY_TONS: 0.0,
    PHYSICAL_INV: 0.0,
    WHOUSE_RCPT_INV: 0.0,
    UNRCPTED_INV: 0.0,
    UNDETERMINED_INV: 0.0,
    COMP_OWN_INV: 0.0,
    PURCHASE_TONS: 0.0,
    STOR_PURCH_TONS: 0.0,
    TRANSFER_IN_TONS: 0.0,
    TRANSFER_OUT_TONS: 0.0,
    DELIV_OUT_TONS: 0.0,
    STARTUP_TONS: 0.0,
    INTRANSIT_TONS: 0.0,
    FLAVUS_IND: "",
    WA_VICAM_IND: "",
    WA_VICAM_PPB: 0,
    WA_FM_SAMPLE: 0.0,
    WA_CLN_SAMPLE: 0.0,
    WA_SMK_GR: 0.0,
    WA_SS_GR: 0.0,
    WA_OK_GR: 0.0,
    WA_DAM_SPLIT_GR: 0.0,
    WA_DAM_GR: 0.0,
    WA_HULL_GR: 0.0,
    WA_FREZ_DAM_GR: 0.0,
    WA_PIT_DAM_GR: 0.0,
    WA_CNCL_RMD_GR: 0.0,
    WA_CR_BR_GR: 0.0,
    WA_DCOL_SHEL_GR: 0.0,
    WA_HUL_BRIT_GR: 0.0,
    WA_JUMBO_GR: 0.0,
    WA_ELK_GR: 0.0,
    WA_FM_GR: 0.0,
    WA_LSK_GR: 0.0,
    WA_FAN_GR: 0.0,
    WA_SMK_PCT: 0.0,
    WA_SMK_1_GUAGE: "",
    WA_SMK_1_PCT: 0.0,
    WA_SMK_2_GUAGE: "",
    WA_SMK_2_PCT: 0.0,
    WA_SMK_3_GUAGE: "",
    WA_SMK_3_PCT: 0.0,
    WA_SMK_4_GUAGE: "",
    WA_SMK_4_PCT: 0.0,
    WA_SS_PCT: 0.0,
    WA_OK_PCT: 0.0,
    WA_DAM_PCT: 0.0,
    WA_TKC_PCT: 0.0,
    WA_HULL_PCT: 0.0,
    WA_FREZ_DAM_PCT: 0.0,
    WA_PIT_DAM_PCT: 0.0,
    WA_CNCL_RMD_PCT: 0.0,
    WA_CR_BR_PCT: 0.0,
    WA_DCOL_SHEL_PCT: 0.0,
    WA_HUL_BRIT_PCT: 0.0,
    WA_JUMBO_PCT: 0.0,
    WA_ELK_PCT: 0.0,
    WA_FM_PCT: 0.0,
    WA_LSK_PCT: 0.0,
    WA_MOIST_PCT: 0.0,
    WA_FAN_PCT: 0.0,
    WA_COST_PER_TON: 0.0,
    WA_PURCH_COST: 0.0,
    WA_STR_PUR_COST: 0.0,
    ORGANIC_IND: "",
    XFER_ALLOW_IND: "",
    ADD_USER: "",
    ADD_DATE_TIME: "",
    CHG_USER: "",
    CHG_DATE_TIME: "",
    PNUT_VARIETY_NAME: "",
    SYMBOL_IND: "",
    GRADE_SOURCE: "",
    OVERRIDE_SOURCE: "",
}

export class clsBin {

    constructor(thisObj) {
        this.thisObj = thisObj;
        this.mudtProps = Object.assign({}, defaultBinInfoProps);
        this.mudtSave = Object.assign({}, defaultBinInfoProps);
        this.mflgNew = Boolean
        this.mflgEditing = Boolean
        this.mflgDeleted = Boolean
        this.mflgDirty = Boolean
    }

    BeginEdit() {
        if (this.mflgEditing) {
            throw Error;
        }
        this.mudtSave = this.mudtProps
        this.mflgEditing = true
    }

    CancelEdit() {
        if (this.mflgDirty) {
            this.mudtProps = this.mudtSave
        }
        this.mflgEditing = false
    }

    ApplyEdit() {
        if (this.mflgDirty) {
            this.mudtSave = this.mudtProps
        }
        this.mflgEditing = false
        this.mflgDirty = false
    }


    //"let" values inside fetch
    COMP_ID = (value) => {
        this.mudtProps.COMP_ID = value
        this.mflgDirty = true
    }

    CROP_YEAR = (value) => {
        this.mudtProps.CROP_YEAR = value
        this.mflgDirty = true
    }

    BUY_PT_ID = (value) => {
        this.mudtProps.BUY_PT_ID = value
        this.mflgDirty = true
    }

    WHOUSE_NUM = (value) => {
        this.mudtProps.WHOUSE_NUM = value
        this.mflgDirty = true
    }

    BIN_NUM = (value) => {
        this.mudtProps.BIN_NUM = value
        this.mflgDirty = true
    }

    WHOUSE_BIN_ID = (value) => {
        this.mudtProps.WHOUSE_BIN_ID = value
        this.mflgDirty = true
    }

    STATUS_IND = (value) => {
        this.mudtProps.STATUS_IND = value
        this.mflgDirty = true
    }

    PNUT_TYPE_ID = (value) => {
        this.mudtProps.PNUT_TYPE_ID = value
        this.mflgDirty = true
    }

    PNUT_VARIETY_ID = (value) => {
        this.mudtProps.PNUT_VARIETY_ID = value
        this.mflgDirty = true
    }

    PNUT_VARIETY_NAME = (value) => {
        this.mudtProps.PNUT_VARIETY_NAME = value
    }

    SYMBOL_IND = (value) => {
        this.mudtProps.SYMBOL_IND = value

        switch (value.toUpperCase()) {
            case "TRADEMARK":
                this.mudtProps.PNUT_VARIETY_NAME = (this.mudtProps.PNUT_VARIETY_NAME + " \u2122").trim();
                break;

            case "REGISTERED TRADEMARK":
                this.mudtProps.PNUT_VARIETY_NAME = (this.mudtProps.PNUT_VARIETY_NAME + " \xAE").trim();
                break;

            case "COPYRIGHT":
                this.mudtProps.PNUT_VARIETY_NAME = (this.mudtProps.PNUT_VARIETY_NAME + " \xA9").trim();
                break;

            default:
                this.mudtProps.PNUT_VARIETY_NAME = this.mudtProps.PNUT_VARIETY_NAME.trim()
                break;
        }
    }

    SEED_GEN = (value) => {
        this.mudtProps.SEED_GEN = value
        this.mflgDirty = true
    }

    SEG_TYPE = (value) => {
        this.mudtProps.SEG_TYPE = value
        this.mflgDirty = true
    }

    EDIBLE_OIL_IND = (value) => {
        this.mudtProps.EDIBLE_OIL_IND = value
        this.mflgDirty = true
    }

    OLEIC_IND = (value) => {
        this.mudtProps.OLEIC_IND = value
        this.mflgDirty = true
    }

    WHS_RCPT_IND = (value) => {
        this.mudtProps.WHS_RCPT_IND = value
        this.mflgDirty = true
    }

    CAPACITY_TONS = (value) => {
        this.mudtProps.CAPACITY_TONS = value
        this.mflgDirty = true
    }

    PHYSICAL_INV = (value) => {
        this.mudtProps.PHYSICAL_INV = value
        this.mflgDirty = true
    }

    WHOUSE_RCPT_INV = (value) => {
        this.mudtProps.WHOUSE_RCPT_INV = value
        this.mflgDirty = true
    }

    UNRCPTED_INV = (value) => {
        this.mudtProps.UNRCPTED_INV = value
        this.mflgDirty = true
    }

    UNDETERMINED_INV = (value) => {
        this.mudtProps.UNDETERMINED_INV = value
        this.mflgDirty = true
    }

    COMP_OWN_INV = (value) => {
        this.mudtProps.COMP_OWN_INV = value
        this.mflgDirty = true
    }

    PURCHASE_TONS = (value) => {
        this.mudtProps.PURCHASE_TONS = value
        this.mflgDirty = true
    }

    STOR_PURCH_TONS = (value) => {
        this.mudtProps.STOR_PURCH_TONS = value
        this.mflgDirty = true
    }

    TRANSFER_IN_TONS = (value) => {
        this.mudtProps.TRANSFER_IN_TONS = value
        this.mflgDirty = true
    }

    TRANSFER_OUT_TONS = (value) => {
        this.mudtProps.TRANSFER_OUT_TONS = value
        this.mflgDirty = true
    }

    DELIV_OUT_TONS = (value) => {
        this.mudtProps.DELIV_OUT_TONS = value
        this.mflgDirty = true
    }

    STARTUP_TONS = (value) => {
        this.mudtProps.STARTUP_TONS = value
        this.mflgDirty = true
    }

    INTRANSIT_TONS = (value) => {
        this.mudtProps.INTRANSIT_TONS = value
        this.mflgDirty = true
    }

    FLAVUS_IND = (value) => {
        this.mudtProps.FLAVUS_IND = value
        this.mflgDirty = true
    }

    WA_VICAM_IND = (value) => {
        this.mudtProps.WA_VICAM_IND = value
        this.mflgDirty = true
    }

    WA_VICAM_PPB = (value) => {
        this.mudtProps.WA_VICAM_PPB = value
        this.mflgDirty = true
    }

    WA_FM_SAMPLE = (value) => {
        this.mudtProps.WA_FM_SAMPLE = value
        this.mflgDirty = true
    }

    WA_CLN_SAMPLE = (value) => {
        this.mudtProps.WA_CLN_SAMPLE = value
        this.mflgDirty = true
    }

    WA_SMK_GR = (value) => {
        this.mudtProps.WA_SMK_GR = value
        this.mflgDirty = true
    }

    WA_SS_GR = (value) => {
        this.mudtProps.WA_SS_GR = value
        this.mflgDirty = true
    }

    WA_OK_GR = (value) => {
        this.mudtProps.WA_OK_GR = value
        this.mflgDirty = true
    }

    WA_DAM_SPLIT_GR = (value) => {
        this.mudtProps.WA_DAM_SPLIT_GR = value
        this.mflgDirty = true
    }

    WA_DAM_GR = (value) => {
        this.mudtProps.WA_DAM_GR = value
        this.mflgDirty = true
    }

    WA_HULL_GR = (value) => {
        this.mudtProps.WA_HULL_GR = value
        this.mflgDirty = true
    }

    WA_FREZ_DAM_GR = (value) => {
        this.mudtProps.WA_FREZ_DAM_GR = value
        this.mflgDirty = true
    }

    WA_PIT_DAM_GR = (value) => {
        this.mudtProps.WA_PIT_DAM_GR = value
        this.mflgDirty = true
    }

    WA_CNCL_RMD_GR = (value) => {
        this.mudtProps.WA_CNCL_RMD_GR = value
        this.mflgDirty = true
    }

    WA_CR_BR_GR = (value) => {
        this.mudtProps.WA_CR_BR_GR = value
        this.mflgDirty = true
    }

    WA_DCOL_SHEL_GR = (value) => {
        this.mudtProps.WA_DCOL_SHEL_GR = value
        this.mflgDirty = true
    }

    WA_HUL_BRIT_GR = (value) => {
        this.mudtProps.WA_HUL_BRIT_GR = value
        this.mflgDirty = true
    }

    WA_JUMBO_GR = (value) => {
        this.mudtProps.WA_JUMBO_GR = value
        this.mflgDirty = true
    }

    WA_ELK_GR = (value) => {
        this.mudtProps.WA_ELK_GR = value
        this.mflgDirty = true
    }

    WA_FM_GR = (value) => {
        this.mudtProps.WA_FM_GR = value
        this.mflgDirty = true
    }

    WA_LSK_GR = (value) => {
        this.mudtProps.WA_LSK_GR = value
        this.mflgDirty = true
    }

    WA_FAN_GR = (value) => {
        this.mudtProps.WA_FAN_GR = value
        this.mflgDirty = true
    }

    WA_SMK_PCT = (value) => {
        this.mudtProps.WA_SMK_PCT = value
        this.mflgDirty = true
    }

    WA_SMK_1_GUAGE = (value) => {
        this.mudtProps.WA_SMK_1_GUAGE = value
        this.mflgDirty = true
    }

    WA_SMK_1_PCT = (value) => {
        this.mudtProps.WA_SMK_1_PCT = value
        this.mflgDirty = true
    }

    WA_SMK_2_GUAGE = (value) => {
        this.mudtProps.WA_SMK_2_GUAGE = value
        this.mflgDirty = true
    }

    WA_SMK_2_PCT = (value) => {
        this.mudtProps.WA_SMK_2_PCT = value
        this.mflgDirty = true
    }

    WA_SMK_3_GUAGE = (value) => {
        this.mudtProps.WA_SMK_3_GUAGE = value
        this.mflgDirty = true
    }

    WA_SMK_3_PCT = (value) => {
        this.mudtProps.WA_SMK_3_PCT = value
        this.mflgDirty = true
    }

    WA_SMK_4_GUAGE = (value) => {
        this.mudtProps.WA_SMK_4_GUAGE = value
        this.mflgDirty = true
    }

    WA_SMK_4_PCT = (value) => {
        this.mudtProps.WA_SMK_4_PCT = value
        this.mflgDirty = true
    }

    WA_SS_PCT = (value) => {
        this.mudtProps.WA_SS_PCT = value
        this.mflgDirty = true
    }

    WA_OK_PCT = (value) => {
        this.mudtProps.WA_OK_PCT = value
        this.mflgDirty = true
    }

    WA_DAM_PCT = (value) => {
        this.mudtProps.WA_DAM_PCT = value
        this.mflgDirty = true
    }

    WA_TKC_PCT = (value) => {
        this.mudtProps.WA_TKC_PCT = value
        this.mflgDirty = true
    }

    WA_HULL_PCT = (value) => {
        this.mudtProps.WA_HULL_PCT = value
        this.mflgDirty = true
    }

    WA_FREZ_DAM_PCT = (value) => {
        this.mudtProps.WA_FREZ_DAM_PCT = value
        this.mflgDirty = true
    }

    WA_PIT_DAM_PCT = (value) => {
        this.mudtProps.WA_PIT_DAM_PCT = value
        this.mflgDirty = true
    }

    WA_CNCL_RMD_PCT = (value) => {
        this.mudtProps.WA_CNCL_RMD_PCT = value
        this.mflgDirty = true
    }

    WA_CR_BR_PCT = (value) => {
        this.mudtProps.WA_CR_BR_PCT = value
        this.mflgDirty = true
    }

    WA_DCOL_SHEL_PCT = (value) => {
        this.mudtProps.WA_DCOL_SHEL_PCT = value
        this.mflgDirty = true
    }

    WA_HUL_BRIT_PCT = (value) => {
        this.mudtProps.WA_HUL_BRIT_PCT = value
        this.mflgDirty = true
    }

    WA_JUMBO_PCT = (value) => {
        this.mudtProps.WA_JUMBO_PCT = value
        this.mflgDirty = true
    }

    WA_ELK_PCT = (value) => {
        this.mudtProps.WA_ELK_PCT = value
        this.mflgDirty = true
    }

    WA_FM_PCT = (value) => {
        this.mudtProps.WA_FM_PCT = value
        this.mflgDirty = true
    }

    WA_LSK_PCT = (value) => {
        this.mudtProps.WA_LSK_PCT = value
        this.mflgDirty = true
    }

    WA_MOIST_PCT = (value) => {
        this.mudtProps.WA_MOIST_PCT = value
        this.mflgDirty = true
    }

    WA_FAN_PCT = (value) => {
        this.mudtProps.WA_FAN_PCT = value
        this.mflgDirty = true
    }

    WA_COST_PER_TON = (value) => {
        this.mudtProps.WA_COST_PER_TON = value
        this.mflgDirty = true
    }

    WA_PURCH_COST = (value) => {
        this.mudtProps.WA_PURCH_COST = value
        this.mflgDirty = true
    }

    WA_STR_PUR_COST = (value) => {
        this.mudtProps.WA_STR_PUR_COST = value
        this.mflgDirty = true
    }

    ADD_USER = (value) => {
        this.mudtProps.ADD_USER = value
        this.mflgDirty = true
    }

    ADD_DATE_TIME = (value) => {
        this.mudtProps.ADD_DATE_TIME = value
        this.mflgDirty = true
    }

    CHG_USER = (value) => {
        this.mudtProps.CHG_USER = value
        this.mflgDirty = true
    }

    CHG_DATE_TIME = (value) => {
        this.mudtProps.CHG_DATE_TIME = value
        this.mflgDirty = true
    }

    ORGANIC_IND = (value) => {
        this.mudtProps.ORGANIC_IND = value
        this.mflgDirty = true
    }

    XFER_ALLOW_IND = (value) => {
        this.mudtProps.XFER_ALLOW_IND = value
        this.mflgDirty = true
    }

    GRADE_SOURCE = (value) => {
        this.mudtProps.GRADE_SOURCE = value
        this.mflgDirty = true
    }

    OVERRIDE_SOURCE = (value) => {
        this.mudtProps.OVERRIDE_SOURCE = value
        this.mflgDirty = true
    }



    // functions and private sub in class
    PeanutTypeName() {
        switch (this.mudtProps.PNUT_TYPE_ID) {
            case "SP":
                return "Spanish"

            case "RU":
                return "Runner"

            case "VA":
                return "Virginia"

            case "VL":
                return "Valencia"

            default:
                break;
        }
    }

    async load(BuyingPointID, WhouseNum, BinNum, TransferNum, InspectNum, SettleNum) {
        const compIdFromLS = () => (sessionStorage.getItem('compId'));
        const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
        let response =
            await StockTransferService.RetrieveWhouseBinInventoryList
                (
                    BuyingPointID,
                    WhouseNum,
                    BinNum,
                    TransferNum,
                    InspectNum,
                    SettleNum
                );  //2023,2CY,B9,00143P,3307239,001,01

        let data = response[0];  // collecting first row only
        this.Fetch(data);
    }

    Fetch = (data) => {
        try {
            let self = this;
            Object.keys(data).forEach(function (reskey) {
                try {
                    self[reskey.toUpperCase()](data[reskey]);
                } catch (error) {
                }    
            });
        } catch (error) {
            alert("Something went wrong, please check console.logs ")
        }
    };

    FetchShippingPoint = (data) => {
        this.mudtProps.BUY_PT_ID = data.shp_buy_pt
        this.mudtProps.WHOUSE_NUM = data.shp_whouse
        this.mudtProps.BIN_NUM = data.shp_bin
        this.mudtProps.PNUT_TYPE_ID = data.PNUT_TYPE_ID
        this.mudtProps.SEG_TYPE = data.shp_buy_pt
        this.mudtProps.PNUT_VARIETY_ID = data.shp_buy_pt
        this.mudtProps.PNUT_VARIETY_NAME = data.shp_buy_pt
    }

    IsSelectable = () => {
        if (this.mudtProps.STATUS_IND == "I") {
            alert("This bin is inactive!")
            return false;
        }

        if (this.mudtProps.XFER_ALLOW_IND == "N") {
            alert("Transfer from this bin is not allowed!")
            return false;
        }

        return true;
    }

    HasGrades = async() => {
        if (this.mudtProps.WA_SMK_PCT > 0) {
            return true;
        } else {
            return false;
        }
    }
}
//END Bin class