/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  enable,
  disable,
  getValue,
  setData,
  setValue,
  getData,
  goTo,
  hide
} from "../../shared/WindowImports";

import "./FarmSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import Loading from "../../../Loader/Loading";
import CommonContext from "../../Store/CommonContext";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FarmSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const commonContext = useContext(CommonContext)
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FarmSetup",
    windowName: "FarmSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FarmSetup",
    // START_USER_CODE-USER_FarmSetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Farm Setup",
      scrCode: "PN0030A",
    },
    // END_USER_CODE-USER_FarmSetup_PROPERTIES
    btnAddFarm: {
      name: "btnAddFarm",
      type: "ButtonWidget",
      parent: "grpbxFarmSetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddFarm_PROPERTIES

      // END_USER_CODE-USER_btnAddFarm_PROPERTIES
    },
    btnAddVendor: {
      name: "btnAddVendor",
      type: "ButtonWidget",
      parent: "grpbxFarmSetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAddVendor_PROPERTIES

      // END_USER_CODE-USER_btnAddVendor_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnDeleteFarm: {
      name: "btnDeleteFarm",
      type: "ButtonWidget",
      parent: "grpbxFarmSetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteFarm_PROPERTIES

      // END_USER_CODE-USER_btnDeleteFarm_PROPERTIES
    },
    btnDeleteVendor: {
      name: "btnDeleteVendor",
      type: "ButtonWidget",
      parent: "grpbxFarmSetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteVendor_PROPERTIES

      // END_USER_CODE-USER_btnDeleteVendor_PROPERTIES
    },
    btnDown: {
      name: "btnDown",
      type: "ButtonWidget",
      parent: "grpbxFarmSetup",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDown_PROPERTIES
      iconName: 'fa-arrow-up'
      // END_USER_CODE-USER_btnDown_PROPERTIES
    },
    btnEditFarm: {
      name: "btnEditFarm",
      type: "ButtonWidget",
      parent: "grpbxFarmSetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditFarm_PROPERTIES

      // END_USER_CODE-USER_btnEditFarm_PROPERTIES
    },
    btnEditVendor: {
      name: "btnEditVendor",
      type: "ButtonWidget",
      parent: "grpbxFarmSetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditVendor_PROPERTIES

      // END_USER_CODE-USER_btnEditVendor_PROPERTIES
    },
    btnGovFarmData: {
      name: "btnGovFarmData",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Gov Farm Data",
      CharWidth: "28",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnGovFarmData_PROPERTIES

      // END_USER_CODE-USER_btnGovFarmData_PROPERTIES
    },
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Save",
      CharWidth: "11",
      // START_USER_CODE-USER_btnSave_PROPERTIES

      // END_USER_CODE-USER_btnSave_PROPERTIES
    },
    btnUp: {
      name: "btnUp",
      type: "ButtonWidget",
      parent: "grpbxFarmSetup",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUp_PROPERTIES
      iconName: 'fa-arrow-down'
      // END_USER_CODE-USER_btnUp_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmSetup",
      Label: "Buying Point:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    ddCounty1: {
      name: "ddCounty1",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmSetup",
      Label: "County",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCounty1_PROPERTIES

      // END_USER_CODE-USER_ddCounty1_PROPERTIES
    },
    ddFarms: {
      name: "ddFarms",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmSetup",
      ColSpan: "4",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFarms_PROPERTIES

      // END_USER_CODE-USER_ddFarms_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmSetup",
      Label: "State",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES

      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    lblFarms: {
      name: "lblFarms",
      type: "LabelWidget",
      parent: "grpbxFarmSetup",
      Label: "Farms:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFarms_PROPERTIES

      // END_USER_CODE-USER_lblFarms_PROPERTIES
    },
    lblVendors: {
      name: "lblVendors",
      type: "LabelWidget",
      parent: "grpbxFarmSetup",
      Label: "Vendors:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendors_PROPERTIES

      // END_USER_CODE-USER_lblVendors_PROPERTIES
    },
    lstVendors: {
      name: "lstVendors",
      type: "ListBoxFieldWidget",
      parent: "grpbxFarmSetup",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstVendors_PROPERTIES

      // END_USER_CODE-USER_lstVendors_PROPERTIES
    },
    grpbxFarmSetup: {
      name: "grpbxFarmSetup",
      type: "GroupBoxWidget",
      parent: "FarmSetup",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxFarmSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxFarmSetup_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "FarmSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    formload()
    getData(thisObj, 'farmSetupData')
    document.getElementsByClassName('btnExit')[0].childNodes[0].childNodes[0].innerText = "Select";
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let reversecaseForFarmProfile = getData(thisObj, "reversecaseForFarmProfile")
    if (reversecaseForFarmProfile == "AddeddatafromFarmProfile") {
      farmprofilecasereverse()
    }
    setData(thisObj, "reversecaseForFarmProfile", null)
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj, "reversecaseForFarmProfile")]);


  // START_USER_CODE-USER_METHODS
  //formload
  const formload = async () => {
    setLoading(true);
    hide(thisObj, 'btnAddVendor',false)
    hide(thisObj, 'btnEditVendor',false)
    hide(thisObj, 'btnDeleteVendor',false)
    hide(thisObj, 'btnGovFarmData', false)
    hide(thisObj, 'btnSave', false)
    hide(thisObj, 'btnUp',false)
    hide(thisObj, 'btnDown',false)
    await bFillBuyingPointList()
    setValue(thisObj, 'ddBuyingPoint', getData(thisObj, 'farmSetupData').buyingPointId)
    await bFillStateList()
    setValue(thisObj, 'ddState', getData(thisObj, 'farmSetupData').stateAbbr)
    await bFillCountyList()
    setValue(thisObj, 'ddCounty1', getData(thisObj, 'farmSetupData').county_Name)
    await bFillFarmlist()
    await bFillVendorList()
    await EnableDisableControls()
    disable(thisObj, 'ddCounty1')
    setData(thisObj, 'CbFillFarmListFlag', false)
    setData(thisObj, 'mbSuccessfulLoad', true)
    setData(thisObj, 'mbVendorExists', true)
    setData(thisObj, 'CVendorFlag', false)
    setLoading(false);
  }


  const farmprofilecasereverse = async () => {
    hide(thisObj, 'btnAddVendor')
    hide(thisObj, 'btnEditVendor')
    hide(thisObj, 'btnDeleteVendor')
    hide(thisObj, 'btnGovFarmData', false)
    hide(thisObj, 'btnSave', false)
    hide(thisObj, 'btnUp')
    hide(thisObj, 'btnDown')
    await bFillBuyingPointList()
    setValue(thisObj, 'ddBuyingPoint', getData(thisObj, 'farmSetupData').buyingPointId)
     await bFillStateList()
     setValue(thisObj, 'ddState', getData(thisObj, 'farmSetupData').stateAbbr)
    await bFillCountyList()
     setValue(thisObj, 'ddCounty1', getData(thisObj, 'farmSetupData').county_Name)
    await bFillFarmlist()
    if(getData(thisObj, 'ddFarmData') !== null && getData(thisObj, 'ddFarmData') !== undefined){
      setValue(thisObj, 'ddFarms', getData(thisObj, 'ddFarmData'))
    }
    await bFillVendorList()
    await EnableDisableControls()

  }
  //enable disable controls
  const EnableDisableControls = async () => {
    enable(thisObj, 'btnAddFarm')
    enable(thisObj, 'btnEditFarm')
    enable(thisObj, 'btnAddVendor')
    enable(thisObj, 'btnEditVendor')
    enable(thisObj, 'btnDeleteVendor')
    let ddFarmsValue = getValue(_kaledo.thisObj, 'ddFarms')
    let lstVendorsValue = getData(thisObj,'vendorlist')
    if (ddFarmsValue == undefined || ddFarmsValue == null || ddFarmsValue == '') {
      disable(thisObj, 'btnEditFarm')
      if((lstVendorsValue == undefined || lstVendorsValue == null || lstVendorsValue == '' || lstVendorsValue.length <= 0) && ddFarmsValue !== undefined && ddFarmsValue !== null && ddFarmsValue !== '') {
        enable(thisObj, 'btnDeleteFarm')
        disable(thisObj, 'ddFarms')
        disable(thisObj, 'btnExit')
        disable(thisObj, 'ddBuyingPoint')
        disable(thisObj, 'ddState')
        disable(thisObj, 'ddCounty1')
       
      }
      else {
        disable(thisObj, 'btnDeleteFarm')
        enable(thisObj, 'ddFarms')
        enable(thisObj, 'btnExit')
        enable(thisObj, 'ddBuyingPoint')
        enable(thisObj, 'ddState')
        enable(thisObj, 'ddCounty1')
      }
      disable(thisObj, 'btnAddVendor')
    }
    else {
      enable(thisObj, 'btnEditFarm')
      if((lstVendorsValue == undefined || lstVendorsValue == null || lstVendorsValue == '' || lstVendorsValue.length <= 0) && ddFarmsValue !== undefined && ddFarmsValue !== null && ddFarmsValue !== ''){

        enable(thisObj, 'btnDeleteFarm')
        disable(thisObj, 'ddFarms')
        disable(thisObj, 'btnExit')
        disable(thisObj, 'ddBuyingPoint')
        disable(thisObj, 'ddState')
        disable(thisObj, 'ddCounty1')
      }
      else {
        disable(thisObj, 'btnDeleteFarm')
        enable(thisObj, 'ddFarms')
        enable(thisObj, 'btnExit')
        enable(thisObj, 'ddBuyingPoint')
        enable(thisObj, 'ddState')
        enable(thisObj, 'ddCounty1')
      }
      enable(thisObj, 'btnAddVendor')
    }
    if (lstVendorsValue !== undefined && lstVendorsValue !== null && lstVendorsValue.length > 0) {
      enable(thisObj, 'btnEditVendor')
      enable(thisObj, 'btnDeleteVendor')
    }
  }

  //Convert to PascalCase function
  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }
  //bfillbuyingpointlist
  const bFillBuyingPointList = async () => {
    try {
      setValue(thisObj, 'ddBuyingPoint', '')
      let js = []
      js.push({ key: '', description: '' });
      let data = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0030', null, null, null, null)
      let buy_pt_name = ''
      for (var i = 0; i < data.length; i++) {
        buy_pt_name = data[i].buy_pt_name.trim() == undefined || data[i].buy_pt_name.trim() == null || data[i].buy_pt_name.trim() == '' ? '' : ConvertToPascalCase(data[i].buy_pt_name.trim())
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + buy_pt_name }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...current["ddBuyingPoint"],
            valueList: js

          }
        }
      })
      if (js.length > 1) {
        setValue(thisObj, 'ddBuyingPoint', js[0].key)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during loading buying point load event");
      }
      return false;
    }
    return true;
  }
  //function bFillCountyList
  const bFillCountyList = async () => {
    setValue(thisObj, 'ddCounty1', '')
    setValue(thisObj, 'ddFarms', '')
    setValue(thisObj, 'lstVendors', [])
    let stateAbbrevations = getValue(_kaledo.thisObj, 'ddState')
    let isbFillCountyList = false
    let js = []
    let obj = {}
    let LstrCountyList = await ContractManagementService.RetrieveCountyControlDetails(null, null, stateAbbrevations, null)
    if (LstrCountyList != undefined && LstrCountyList != null && LstrCountyList.length > 0) {
      for (let i = 0; i < LstrCountyList.length; i++) {

        obj = { key: LstrCountyList[i].countyName.trim(), description: LstrCountyList[i].countyName.trim(), CountyID: LstrCountyList[i].countyId.trim() }
        js.push(obj)
        obj = {}
      }
      isbFillCountyList = true
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddCounty1: {
          ...state['ddCounty1'],
          valueList: js,
        }
      }
    })
    if (js.length > 0) {
      setData(thisObj, 'ddCounty1lst', js)
      setValue(thisObj, 'ddCounty1', js[0].key)
    }
    return isbFillCountyList
  }
  //funtion  bFillFarmlist
  const bFillFarmlist = async () => {
    try {
      setValue(thisObj, 'ddFarms', '')
      setValue(thisObj, 'lstVendors', [])
      let buy_pt_id = getValue(_kaledo.thisObj, 'ddBuyingPoint')
      let stateAbbrevations = getValue(_kaledo.thisObj, 'ddState')
      let countyList = getData(thisObj, 'ddCounty1lst')
      let CountyName = getValue(_kaledo.thisObj, 'ddCounty1').trim()
      let CountyIDvalue = ''
      if (countyList.length > 0 && CountyName != undefined && CountyName != null && CountyName != '') {
        CountyIDvalue = countyList.find(elem => elem.key == CountyName).CountyID;
      }

      let js = [];
      let obj = {};
      let isbFillFarmlist = false

      let data = await ContractManagementService.RetrieveFarmControlDetails(null, null, buy_pt_id, stateAbbrevations, CountyIDvalue, null, null)
      obj = { key: "", description: "SELECT", farmSuffix: '' };
      js.push(obj);
      if (data !== null && data !== undefined && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          obj = {
            key: data[i].farm_id + '-' + data[i].farm_suffix + '-' + data[i].farm_name,
            description: data[i].farm_id + '-' + data[i].farm_suffix + '-' + data[i].farm_name,
            farmSuffix: data[i].farm_suffix,
          }
          js.push(obj)
          obj = {}
        }
        isbFillFarmlist = true
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddFarms: {
            ...state["ddFarms"],
            valueList: js,
          }
        }
      })
      if (js.length > 1) {
        setValue(thisObj, 'ddFarms', js[1].key)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during loading Farm event"
        );
      }
      return;
    }

  };

  //function bFillVendorList
  const bFillVendorList = async () => {
    setValue(thisObj, 'lstVendors', '')
    let ddFarmList = _kaledo.thisObj.state['ddFarms'].valueList;
    let SelectedddFarmValue = getValue(_kaledo.thisObj, 'ddFarms');
    let farm_Suffix = {}
    if (SelectedddFarmValue !== "" && SelectedddFarmValue !== undefined && ddFarmList != undefined && ddFarmList.length > 1) {
      farm_Suffix = ddFarmList.find(elem => elem.key === SelectedddFarmValue).farmSuffix;
    }
    let buy_point_id = getValue(_kaledo.thisObj, 'ddBuyingPoint')
    let stateAbbr = getValue(_kaledo.thisObj, 'ddState')
    let countyList = getData(_kaledo.thisObj, 'ddCounty1lst')
    let KeyValue = getValue(_kaledo.thisObj, 'ddCounty1')
    let county_id = ''
    if (KeyValue != undefined && KeyValue != null && KeyValue != '') {
      county_id = countyList.find(elem => elem.key == KeyValue).CountyID;
    }
    
    let farm_id = getValue(_kaledo.thisObj, 'ddFarms')
    if(farm_id !=undefined && farm_id!= null && farm_id !=""){
      farm_id = farm_id.split("-")[0]
    }
    let js = [];
    let obj = {};
    let isbFillVendorList = false

    let response = await ContractManagementService.RetrieveFarmVendorSplitDetails(buy_point_id, stateAbbr, county_id, farm_id, farm_Suffix)
    let LstrList = response
    if (LstrList != undefined && LstrList != null && LstrList.length > 0) {
      for (let i = 0; i < LstrList.length; i++) {
        let VendorNumber = LstrList[i].split_vendor;
        let data = await ContractManagementService.RetrieveVendorByNumber(VendorNumber)
        let LstrList2 = data
        let VendorName = ''
        if (LstrList2 != undefined && LstrList2 != null && LstrList2.length > 0) {
          VendorName = LstrList2[0].name == undefined || LstrList2[0].name == null || LstrList2[0].name == '' ? '' : ConvertToPascalCase(LstrList2[0].name)

          obj = {
            key: LstrList[i].split_vendor,
            description: LstrList[i].split_vendor + ' - ' + VendorName + ' (' + Number(LstrList[i].split_share_pct) + ')',
            lstSplitPercent: LstrList[i].split_share_pct,
          }
          js.push(obj)
          obj = {}
        }
      }
      isbFillVendorList = true
      thisObj.setState(current => {
        return {
          ...current,
          lstVendors: {
            ...state["lstVendors"],
            valueList: js,
          }
        }
      })
    }
    else{
      thisObj.setState(current => {
        return {
          ...current,
          lstVendors: {
            ...state["lstVendors"],
            valueList: [],
          }
        }
      })
    }
    
    if (js.length > 0) {
      setValue(thisObj, 'lstVendors', [js[0].key]);
    }
    setData(thisObj,'vendorlist',js)
    return isbFillVendorList
  }
  // function bfillsatelist
  const bFillStateList = async () => {
    setValue(thisObj, 'ddState', '')
    setValue(thisObj, 'ddFarms', '')
    setValue(thisObj, 'lstVendors', [])
    setValue(thisObj, 'ddCounty1', '')
    let isbFillStateList = false
    let js = [];
    let obj = {};
    // obj = { key: "", description: "Select" , statecontrols : ''};
    // js.push(obj)
    let response = await ContractManagementService.RetrievePeanutStateControlDetails(null, null, false)
    let data = response;
    if (data !== null && data !== undefined && data.length > 0) {
      for (let i = 0; i < data.length; i++) {

        obj = {
          key: data[i].stateAbbr,
          description: data[i].stateName,
          statecontrols: data[i].stateChargesControls
        }
        js.push(obj);
        obj = {};

      }

    }
    isbFillStateList = true
    thisObj.setState(current => {
      return {
        ...current,
        ddState: {
          ...state["ddState"],
          valueList: js,
        }
      }
    })
    if (js.length > 0) {
      setValue(thisObj, 'ddState', js[0].key)
    }
    return isbFillStateList
  }
  //function bCheckPercent
  const bCheckPercent = async () => {

    let dsum = 0
    let isbCheckPercent = false
    let lstVendorsValue = _kaledo.thisObj.state['lstVendors'].valueList
    if (lstVendorsValue !== undefined && lstVendorsValue !== null) {
      for (let i = 0; i < lstVendorsValue.length; i++) {
        dsum = dsum + Number(lstVendorsValue[i].lstSplitPercent)
      }
      if (Number(dsum) != 100) {
        if (Number(dsum) > 100) {
          showMessage(thisObj, 'Split percentages total' + dsum + 'is more than 100', 'Invalid Split Percentages')
        }
        else {
          showMessage(thisObj, 'Split percentages total' + dsum + 'is less than 100', 'Invalid Split Percentages')

        }
      }
      else {
        isbCheckPercent = true
      }

    }
    return isbCheckPercent
  }



  const onddBuyingPointChange = async () => {

    await bFillFarmlist()
    await bFillVendorList()
    EnableDisableControls()
  }
  thisObj.onddBuyingPointChange = onddBuyingPointChange

  const onddCounty1Change = async () => {

    await bFillFarmlist()
    await bFillVendorList()
    EnableDisableControls()
  }
  thisObj.onddCounty1Change = onddCounty1Change

  const onddCounty1Blur = async () => {
    if (bCheckPercent() == false) {
      document.getElementById("lstVendors").focus();
      return;

    }
  }
  thisObj.onddCounty1Blur = onddCounty1Blur

  const onddFarmsChange = async () => {

    await bFillVendorList()
    EnableDisableControls()

  }
  thisObj.onddFarmsChange = onddFarmsChange

  const onddFarmsBlur = async () => {
    if (bCheckPercent() == false) {
      document.getElementById("lstVendors").focus();
      return;

    }
  }
  thisObj.onddFarmsBlur = onddFarmsBlur

  const onddStateChange = async () => {

    await bFillCountyList()
    await bFillFarmlist()
    await bFillVendorList()
    EnableDisableControls()

  }
  thisObj.onddStateChange = onddStateChange

  const onddStateBlur = async () => {
    if (bCheckPercent() == false) {
      document.getElementById("lstVendors").focus();
      return;

    }
  }
  thisObj.onddStateBlur = onddStateBlur

  // add button function for farm 

  const onbtnAddFarmClick = async () => {
    let obj = {}
    obj.btnOkCaption = 'AddSetUpScreen'
    obj.buyingpoint = getValue(thisObj, 'ddBuyingPoint') == undefined || getValue(thisObj, 'ddBuyingPoint') == null || getValue(thisObj, 'ddBuyingPoint') == '' ? '' : getValue(thisObj, 'ddBuyingPoint')
    obj.stateAbbrv = getValue(thisObj, 'ddState') == undefined || getValue(thisObj, 'ddState') == null || getValue(thisObj, 'ddState') == '' ? "" : getValue(thisObj, 'ddState')
    let countyList = getData(thisObj, 'ddCounty1lst')
    if (countyList != undefined && countyList.length > 0) {
      let county_id = countyList.find(elem => elem.key == getValue(_kaledo.thisObj, 'ddCounty1')).CountyID;
      obj.county = county_id
    }
    else {
      obj.county = ''
    }
    // obj.farm = getValue(thisObj, 'ddFarms') === undefined || null ?  "001" : ""
    let js = [];
    js.push(obj);
    setData(thisObj, 'farmprofileAddData', js)
    commonContext.setFarmInquiry_InquiryEdit('')
    goTo(thisObj, "SystemMaintenanceMasterManagement#FarmProfile#DEFAULT#true#Click");

  }

  thisObj.onbtnAddFarmClick = onbtnAddFarmClick


  //edit farm button funtion 
  const onbtnEditFarmClick = async () => {
    try {
      let obj = {};
      let js = [];
      js.push(obj);
      obj.cmdOkCaption = "Update"
      obj.buyingpoint = getValue(thisObj, 'ddBuyingPoint') == undefined || getValue(thisObj, 'ddBuyingPoint') == null || getValue(thisObj, 'ddBuyingPoint') == '' ? '' : getValue(thisObj, 'ddBuyingPoint')
      obj.stateAbbrv = getValue(thisObj, 'ddState') == undefined || getValue(thisObj, 'ddState') == null || getValue(thisObj, 'ddState') == '' ? '' : getValue(thisObj, 'ddState')

      let countyList = getData(thisObj, 'ddCounty1lst')
      if (countyList != undefined && countyList.length > 0) {
        obj.county = countyList.find(elem => elem.key == getValue(_kaledo.thisObj, 'ddCounty1')).CountyID;
      }
      else {
        obj.county = ''
      }
      obj.farm_id = getValue(thisObj, 'ddFarms') == undefined || getValue(thisObj, 'ddFarms') == null || getValue(thisObj, 'ddFarms') == '' ? '' : getValue(thisObj, 'ddFarms')
      if(obj.farm_id !=undefined && obj.farm_id!= null && obj.farm_id !=""){
        obj.farm_id = obj.farm_id.split("-")[0]
      }
      let ddFarmList = thisObj.state['ddFarms'].valueList;
      let farm_suffix = ''
      let SelectedddFarmValue = getValue(thisObj, 'ddFarms');
      if (SelectedddFarmValue !== "" && SelectedddFarmValue !== undefined && ddFarmList != undefined && ddFarmList.length > 0) {
        farm_suffix = ddFarmList.find(elem => elem.key === SelectedddFarmValue).farmSuffix;
      }

      let data = await SystemMaintenanceMasterManagementService.RetrieveFarmControlDetails(obj.buyingpoint, obj.stateAbbrv, obj.county, obj.farm_id, farm_suffix)

      if (data != undefined && data.length > 0) {
        obj.farm_name = data[0].farmName;
        obj.farm_sort_name = data[0].farmSortName;
        obj.add_user = data[0].addUser + " " + moment(data[0].addDateTime).format("MM/DD/YYYY")
        obj.chg_user = data[0].chgUser + " " + moment(data[0].chgDateTime).format("MM/DD/YYYY")
        obj.farm_address = data[0].farmAddress
        obj.farm_city = data[0].farmCity
        obj.farm_zip = data[0].farmZip
        obj.farm_phone = data[0].farmPhone
        obj.farm_acres = data[0].farmAcres
        obj.IrrDry = data[0].irrDryDefault
      }
      obj.FarmsuffixEnabled = true
      obj.buyingPointEnabled = false;
      obj.StateEnabled = false;
      obj.CountyEnabled = false;
      obj.txtIdEnabled = false;
      obj.farm_suffix=farm_suffix
      commonContext.setFarmInquiry_InquiryEdit(js)
      setData(thisObj, 'farmprofileAddData', '')
      goTo(thisObj, "SystemMaintenanceMasterManagement#FarmProfile#DEFAULT#true#Click");


    }


    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during Edit button result"
        );
      }
      return false;
    }
    return true;
  }

    ;
  thisObj.onbtnEditFarmClick = onbtnEditFarmClick;
  // delete farm function 
  const onbtnDeleteFarmClick = async () => {
    let farm_suffix = ''
    let ddFarmList = thisObj.state['ddFarms'].valueList;
    let SelectedddFarmValue = getValue(thisObj, 'ddFarms');
    if (SelectedddFarmValue !== "" && SelectedddFarmValue !== undefined && ddFarmList != undefined && ddFarmList.length > 0) {
      farm_suffix = ddFarmList.find(elem => elem.key === SelectedddFarmValue).farmSuffix;
    }
    let buy_pt_id = getValue(thisObj, 'ddBuyingPoint')
    let state_abbr = getValue(thisObj, 'ddState')
    let county_id = getValue(thisObj, 'ddCounty1')
    let farm_id = getValue(thisObj, 'ddFarms')
    const confirmBox = confirm(`Are you sure you wish to delete this Farm ${farm_id}`)
    if (confirmBox == true) {
      let response = await SystemMaintenanceMasterManagementService.RemoveFarmControl(buy_pt_id, state_abbr, county_id, farm_id, farm_suffix);
      if (response.status != '200') {
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.",!Delete Farm');
      }
      else if (response.status == '200') {
        showMessage(thisObj, response.message, true);
      }
      else {
        showMessage(thisObj, response.message);
      }
    }
    bFillVendorList()
    EnableDisableControls()
  }
  thisObj.onbtnDeleteFarmClick = onbtnDeleteFarmClick

  // close button as select functionality
  const onbtnExitClick = async () => {
    setLoading(true);
    let dataObj={}
     dataObj.farmDetails=getValue(thisObj, 'ddFarms')
     dataObj.buy_point_id=getValue(thisObj,'ddBuyingPoint')
     dataObj.state_abbr=getValue(thisObj,'ddState')

    let ddCountyist = _kaledo.thisObj.state['ddCounty1'].valueList;
    let SelectedCountyValue = getValue(_kaledo.thisObj, 'ddCounty1');
    let countY_ID = {}
    if (SelectedCountyValue !== "" && SelectedCountyValue !== undefined && ddCountyist != undefined && ddCountyist.length > 1) {
      countY_ID = ddCountyist.find(elem => elem.key === SelectedCountyValue).CountyID;
    }
    dataObj.county_id=countY_ID
    setData(thisObj, 'msSelectedFarmId',dataObj)
    document.getElementById("SystemMaintenanceMasterManagement_FarmSetupPopUp").childNodes[0].click()
    setLoading(false)
    
  }
  thisObj.onbtnExitClick = onbtnExitClick















  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              thisObj={thisObj} // Passing Screen reference for closing popup using HANDLEMODALHide API.
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FarmSetup*/}

              {/* END_USER_CODE-USER_BEFORE_FarmSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFarmSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFarmSetup*/}

              <GroupBoxWidget conf={state.grpbxFarmSetup} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_ddState*/}

                {/* END_USER_CODE-USER_BEFORE_ddState*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddState}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddState*/}

                {/* END_USER_CODE-USER_AFTER_ddState*/}
                {/* START_USER_CODE-USER_BEFORE_ddCounty*/}

                {/* END_USER_CODE-USER_BEFORE_ddCounty*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddCounty1}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddCounty*/}

                {/* END_USER_CODE-USER_AFTER_ddCounty*/}
                {/* START_USER_CODE-USER_BEFORE_lblFarms*/}

                {/* END_USER_CODE-USER_BEFORE_lblFarms*/}

                <LabelWidget
                  values={values}
                  conf={state.lblFarms}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblFarms*/}

                {/* END_USER_CODE-USER_AFTER_lblFarms*/}
                {/* START_USER_CODE-USER_BEFORE_btnAddFarm*/}

                {/* END_USER_CODE-USER_BEFORE_btnAddFarm*/}

                <ButtonWidget
                  conf={state.btnAddFarm}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAddFarm*/}

                {/* END_USER_CODE-USER_AFTER_btnAddFarm*/}
                {/* START_USER_CODE-USER_BEFORE_btnEditFarm*/}

                {/* END_USER_CODE-USER_BEFORE_btnEditFarm*/}

                <ButtonWidget
                  conf={state.btnEditFarm}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEditFarm*/}

                {/* END_USER_CODE-USER_AFTER_btnEditFarm*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeleteFarm*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeleteFarm*/}

                <ButtonWidget
                  conf={state.btnDeleteFarm}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeleteFarm*/}

                {/* END_USER_CODE-USER_AFTER_btnDeleteFarm*/}
                {/* START_USER_CODE-USER_BEFORE_ddFarms*/}

                {/* END_USER_CODE-USER_BEFORE_ddFarms*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddFarms}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddFarms*/}

                {/* END_USER_CODE-USER_AFTER_ddFarms*/}
                {/* START_USER_CODE-USER_BEFORE_lblVendors*/}

                {/* END_USER_CODE-USER_BEFORE_lblVendors*/}

                <LabelWidget
                  values={values}
                  conf={state.lblVendors}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblVendors*/}

                {/* END_USER_CODE-USER_AFTER_lblVendors*/}
                {/* START_USER_CODE-USER_BEFORE_btnUp*/}

                {/* END_USER_CODE-USER_BEFORE_btnUp*/}

                <ButtonWidget
                  conf={state.btnUp}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUp*/}

                {/* END_USER_CODE-USER_AFTER_btnUp*/}
                {/* START_USER_CODE-USER_BEFORE_btnDown*/}

                {/* END_USER_CODE-USER_BEFORE_btnDown*/}

                <ButtonWidget
                  conf={state.btnDown}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDown*/}

                {/* END_USER_CODE-USER_AFTER_btnDown*/}
                {/* START_USER_CODE-USER_BEFORE_btnAddVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnAddVendor*/}

                <ButtonWidget
                  conf={state.btnAddVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAddVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnAddVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnEditVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnEditVendor*/}

                <ButtonWidget
                  conf={state.btnEditVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEditVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnEditVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeleteVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeleteVendor*/}

                <ButtonWidget
                  conf={state.btnDeleteVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeleteVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnDeleteVendor*/}
                {/* START_USER_CODE-USER_BEFORE_lstVendors*/}

                {/* END_USER_CODE-USER_BEFORE_lstVendors*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstVendors}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstVendors*/}

                {/* END_USER_CODE-USER_AFTER_lstVendors*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFarmSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFarmSetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnSave*/}

                {/* END_USER_CODE-USER_BEFORE_btnSave*/}

                <ButtonWidget
                  conf={state.btnSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSave*/}

                {/* END_USER_CODE-USER_AFTER_btnSave*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
                {/* START_USER_CODE-USER_BEFORE_btnGovFarmData*/}

                {/* END_USER_CODE-USER_BEFORE_btnGovFarmData*/}

                <ButtonWidget
                  conf={state.btnGovFarmData}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnGovFarmData*/}

                {/* END_USER_CODE-USER_AFTER_btnGovFarmData*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_FarmSetup*/}

              {/* END_USER_CODE-USER_AFTER_FarmSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_FarmSetup);
