/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  setValue,
  setData,
  getData,
  getValue,
  goTo,
  enable,
  disable,
  getSelectedRowNumber
} from "../../shared/WindowImports";

import "./LoanReductionSetup.scss";
import Loading from "../../../Loader/Loading";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_LoanReductionSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "LoanReductionSetup",
    windowName: "LoanReductionSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.LoanReductionSetup",
    // START_USER_CODE-USER_LoanReductionSetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Loan Reduction Setup",
      scrCode: "PN0330A",
    },
    // END_USER_CODE-USER_LoanReductionSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxLoanReduction",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxLoanReduction",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxLoanReduction",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    lblLoanReduction: {
      name: "lblLoanReduction",
      type: "LabelWidget",
      parent: "grpbxLoanReduction",
      Label: "Loan Reduction:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLoanReduction_PROPERTIES

      // END_USER_CODE-USER_lblLoanReduction_PROPERTIES
    },
    lstLoanReduction: {
      name: "lstLoanReduction",
      type: "ListBoxFieldWidget",
      parent: "grpbxLoanReduction",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstLoanReduction_PROPERTIES

      // END_USER_CODE-USER_lstLoanReduction_PROPERTIES
    },
    grpbxLoanReduction: {
      name: "grpbxLoanReduction",
      type: "GroupBoxWidget",
      parent: "LoanReductionSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxLoanReduction_PROPERTIES

      // END_USER_CODE-USER_grpbxLoanReduction_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "LoanReductionSetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#LoanReductionProfile": {}
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#LoanReductionProfile": {}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAdd: {
    //   DEFAULT: ["SystemMaintenanceSpecialFunctions#LoanReductionProfile#DEFAULT#true#Click"],
    // },
    // btnEdit: {
    //   DEFAULT: ["SystemMaintenanceSpecialFunctions#LoanReductionProfile#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    EnableDisableControls()
    Formload()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    let childData = getData(thisObj, 'frmLoanReductionSetupSetup')
    if (childData !== null) {
      let Reduction=getData(thisObj, 'Loan')
      bFillLoanReductionList(childData)
    }
  }, [getData(thisObj, 'frmLoanReductionSetupSetup')])

  let Formload = async () => {
    EnableDisableControls()
    await bFillLoanReductionList(); // Method for binding LoanReductionList
  }

  // EnableDisableControls functionalty

  const EnableDisableControls = () => {
    enable(thisObj, 'btnAdd')
    let LoanReduction = getData(thisObj, 'ReductionLength');
    if (LoanReduction <= 0) {
      disable(thisObj, "btnEdit")
      disable(thisObj, "btnDelete")
    }
    else {
      enable(thisObj, "btnEdit")
      enable(thisObj, "btnDelete")
    }
  }

  // LoanReductionList

  let bFillLoanReductionList = async (effect_date_time) => {
    try {
      setLoading(true)
      let js = [];
      let Ind = 0
      let childData = getData(thisObj, 'frmLoanReductionSetupSetup')

      let response = await SystemMaintenanceSpecialFunctionsService.RetrieveLoanReductionControlDetails(null)
      let data = response;
      if (data.length > 0) {
        enable(thisObj, "btnEdit")
        enable(thisObj, "btnDelete")
        if (data != undefined && data != null && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let dataExist = js.find(elem => elem.key == data[i].effectDateTime)
            if (dataExist == undefined) {
              js.push({
                key: data[i].effectDateTime,
                description: moment(data[i].effectDateTime).format('MM/DD/YYYY') + " - " + moment(data[i].effectDateTime).format('hh:mm A') + " - " + Number(data[i].loanReductRate).toFixed(2),
                date: data[i].effectDateTime
              }) 
              
              if(new Date(data[i].effectDateTime)?.getTime() == new Date(childData?.effect_date_time)?.getTime()){
                Ind = i
              }
            }
          }
        }
        setData(thisObj, 'ReductionLength', js.length)
      }
      else {
        disable(thisObj, "btnEdit")
        disable(thisObj, "btnDelete")
      }
      
      if (js.length > 0) {
        if(effect_date_time!=null &&effect_date_time!=undefined){
          setValue(thisObj, 'lstLoanReduction', [js.at(Ind).key]);
        }
        else{
          setValue(thisObj, 'lstLoanReduction', [js.at(0).key]);
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          lstLoanReduction: {
            ...state["lstLoanReduction"],
            valueList: js
          }
        }
      })
      setLoading(false)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during loading grid"
        );
      }
    }
  }

  //Delete button functionalty

  let onbtnDeleteClick = async () => {
    try {
      let reductionList = thisObj.state['lstLoanReduction'].valueList
      let effect_date_time = getValue(thisObj, 'lstLoanReduction')[0]
      let loanDetails = ''
      if (effect_date_time !== undefined && effect_date_time !== null && effect_date_time !== '') {
        loanDetails = reductionList.find(elem => elem.key == effect_date_time).description
      }
      if (effect_date_time == null) {
        showMessage(thisObj, 'Please select a Loan Repayment to delete')
        return;
      }
      let res = confirm(`Are you sure you wish to delete this Loan Reduction \n'${loanDetails}'`)
      if (res == true) {
        let response = await SystemMaintenanceSpecialFunctionsService.RemoveLoanReductionControl(effect_date_time);
        if (![200, 400, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
        }
        else if (response.status != 200) {
          showMessage(thisObj, response.message, true)
        }
        setData(thisObj, 'ReductionLength', '')
        await bFillLoanReductionList()
        EnableDisableControls();
      }
      else {
        return;
      }

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during executing delete button"
        );
      }
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick

  // Add button functionality

  let onbtnAddClick = async () => {
    try {
      let frmLoanReductionProfile = {};
      frmLoanReductionProfile.cmdOk_Caption = "Add"
      frmLoanReductionProfile.txtEffectiveDateTime_enabled = 'True'
      setData(thisObj, "LoanReductionProfile", frmLoanReductionProfile)
      goTo(thisObj, 'SystemMaintenanceSpecialFunctions#LoanReductionProfile#DEFAULT#true#Click')
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during executing Add button"
        );
      }
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick

  //Edit button functionality

  let onbtnEditClick = async () => {
    try {
      let reductionList = thisObj.state['lstLoanReduction'].valueList
      let effect_date_time = getValue(thisObj, 'lstLoanReduction')[0]
      let date = ''
      if (effect_date_time !== undefined && effect_date_time !== null && effect_date_time !== '') {
        date = reductionList.find(elem => elem.key == effect_date_time).date
      }
      let response = await SystemMaintenanceSpecialFunctionsService.RetrieveLoanReductionControlDetails(effect_date_time)
      let frmLoanReductionProfile = {}
      frmLoanReductionProfile.cmdOk_Caption = "Update"
      frmLoanReductionProfile.txtEffectiveDateTime_enabled = 'False'
      frmLoanReductionProfile.txtEffectiveDateTime = moment(response[0].effectDateTime).format('yyyy-MM-DD HH:mm:ss.SSS')
      frmLoanReductionProfile.txtReductionRate = Number(response[0].loanReductRate).toFixed(2)
      frmLoanReductionProfile.lblAddedByCaption = response[0].addUser + " " + moment(response[0].addDateTime).format('MM/DD/YYYY')
      frmLoanReductionProfile.lblChangedByCaption = response[0].chgUser + " " + moment(response[0].chgDateTime).format('MM/DD/YYYY')
      setData(thisObj, "LoanReductionProfile", frmLoanReductionProfile)
      goTo(thisObj, 'SystemMaintenanceSpecialFunctions#LoanReductionProfile#DEFAULT#true#Click')
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Edit Button Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnEditClick = onbtnEditClick

  // Close button Functionality

  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_LoanReductionSetupPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
              <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_LoanReductionSetup*/}

              {/* END_USER_CODE-USER_BEFORE_LoanReductionSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxLoanReduction*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxLoanReduction*/}

              <GroupBoxWidget
                conf={state.grpbxLoanReduction}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblLoanReduction*/}

                {/* END_USER_CODE-USER_BEFORE_lblLoanReduction*/}

                <LabelWidget
                  values={values}
                  conf={state.lblLoanReduction}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblLoanReduction*/}

                {/* END_USER_CODE-USER_AFTER_lblLoanReduction*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_lstLoanReduction*/}

                {/* END_USER_CODE-USER_BEFORE_lstLoanReduction*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstLoanReduction}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstLoanReduction*/}

                {/* END_USER_CODE-USER_AFTER_lstLoanReduction*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxLoanReduction*/}

              {/* END_USER_CODE-USER_AFTER_grpbxLoanReduction*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_LoanReductionSetup*/}

              {/* END_USER_CODE-USER_AFTER_LoanReductionSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_LoanReductionSetup);
