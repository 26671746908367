/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  hide,
  show,
  getValue,
  setValue

} from "../../shared/WindowImports";

import "./SpecFuncChgNum.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import { getData } from "../../Common/ApiService";
import StripPic from "../../../../assets/img/PinStrip.png";
const compIdFromLS = () => (sessionStorage.getItem('compId'));
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
const useridFromLS = () => (sessionStorage.getItem('userid') || '');
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_SpecFuncChgNum(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SpecFuncChgNum",
    windowName: "SpecFuncChgNum",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.SpecFuncChgNum",
    // START_USER_CODE-USER_SpecFuncChgNum_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Special Functions/Change Number",
      scrCode: "PN0200B",
    },
    // END_USER_CODE-USER_SpecFuncChgNum_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    txtOldInspection: {
      name: "txtOldInspection",
      type: "TextBoxWidget",
      parent: "grpbxChngInsNum",
      Label: "Old Inspection #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOldInspection_PROPERTIES

      // END_USER_CODE-USER_txtOldInspection_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    Chng1007VndrNum: {
      name: "Chng1007VndrNum",
      type: "GroupBoxWidget",
      parent: "grpbxChangeNumbers",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_Chng1007VndrNum_PROPERTIES

      // END_USER_CODE-USER_Chng1007VndrNum_PROPERTIES
    },
    grpbxChangeTransferNum: {
      name: "grpbxChangeTransferNum",
      type: "GroupBoxWidget",
      parent: "grpbxChangeNumbers",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxChangeTransferNum_PROPERTIES

      // END_USER_CODE-USER_grpbxChangeTransferNum_PROPERTIES
    },
    grpbxChngInsNum: {
      name: "grpbxChngInsNum",
      type: "GroupBoxWidget",
      parent: "grpbxChangeNumbers",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxChngInsNum_PROPERTIES

      // END_USER_CODE-USER_grpbxChngInsNum_PROPERTIES
    },
    grpbxChngSettle1007Num: {
      name: "grpbxChngSettle1007Num",
      type: "GroupBoxWidget",
      parent: "grpbxChangeNumbers",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbxChngSettle1007Num_PROPERTIES

      // END_USER_CODE-USER_grpbxChngSettle1007Num_PROPERTIES
    },
    grpbxChngWRNum: {
      name: "grpbxChngWRNum",
      type: "GroupBoxWidget",
      parent: "grpbxChangeNumbers",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbxChngWRNum_PROPERTIES

      // END_USER_CODE-USER_grpbxChngWRNum_PROPERTIES
    },
    grpbxTransferNum: {
      name: "grpbxTransferNum",
      type: "GroupBoxWidget",
      parent: "grpbxChangeTransferNum",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbxTransferNum_PROPERTIES

      // END_USER_CODE-USER_grpbxTransferNum_PROPERTIES
    },
    grpbxTransferType: {
      name: "grpbxTransferType",
      type: "GroupBoxWidget",
      parent: "grpbxChangeTransferNum",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbxTransferType_PROPERTIES

      // END_USER_CODE-USER_grpbxTransferType_PROPERTIES
    },
    lblChangeNumbers: {
      name: "lblChangeNumbers",
      type: "LabelWidget",
      parent: "grpbxChangeNumbers",
      Label: "Change Numbers:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangeNumbers_PROPERTIES

      // END_USER_CODE-USER_lblChangeNumbers_PROPERTIES
    },
    lblChangeTransferNum: {
      name: "lblChangeTransferNum",
      type: "LabelWidget",
      parent: "grpbxChangeTransferNum",
      Label: "Change Transfer Number:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangeTransferNum_PROPERTIES

      // END_USER_CODE-USER_lblChangeTransferNum_PROPERTIES
    },
    lblChng1007VndrNum: {
      name: "lblChng1007VndrNum",
      type: "LabelWidget",
      parent: "Chng1007VndrNum",
      Label: "Change 1007 Vendor Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChng1007VndrNum_PROPERTIES

      // END_USER_CODE-USER_lblChng1007VndrNum_PROPERTIES
    },
    lblChngInsNum: {
      name: "lblChngInsNum",
      type: "LabelWidget",
      parent: "grpbxChngInsNum",
      Label: "Change Inspection (SC95) Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChngInsNum_PROPERTIES

      // END_USER_CODE-USER_lblChngInsNum_PROPERTIES
    },
    lblChngSettle1007Num: {
      name: "lblChngSettle1007Num",
      type: "LabelWidget",
      parent: "grpbxChngSettle1007Num",
      Label: "Change Settlemnt 1007 Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChngSettle1007Num_PROPERTIES

      // END_USER_CODE-USER_lblChngSettle1007Num_PROPERTIES
    },
    lblChngWRNum: {
      name: "lblChngWRNum",
      type: "LabelWidget",
      parent: "grpbxChngWRNum",
      Label: "Change Warehouse Receipt Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChngWRNum_PROPERTIES

      // END_USER_CODE-USER_lblChngWRNum_PROPERTIES
    },
    radioChangeNumbers: {
      name: "radioChangeNumbers",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxChangeNumbers",
      Options:
        "Inspection (SC95):1,Transfer:2,Corrected 1007 Vendors:3,Settlement (1007):4,Warehouse Receipt:5",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioChangeNumbers_PROPERTIES

      // END_USER_CODE-USER_radioChangeNumbers_PROPERTIES
    },
    radioTranferType: {
      name: "radioTranferType",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxTransferType",
      Options: "Stock Transfer:1,Farm Freight:2,No Load:3",
      Label: "Transfer Type:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioTranferType_PROPERTIES

      // END_USER_CODE-USER_radioTranferType_PROPERTIES
    },
    txtBuyPt: {
      name: "txtBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxChngInsNum",
      Label: "Buying Point:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtBuyPt_PROPERTIES
    },
    txtBuyPtCSN: {
      name: "txtBuyPtCSN",
      type: "TextBoxWidget",
      parent: "grpbxChngSettle1007Num",
      Label: "Buying Point:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyPtCSN_PROPERTIES

      // END_USER_CODE-USER_txtBuyPtCSN_PROPERTIES
    },
    txtBuyPtCVN: {
      name: "txtBuyPtCVN",
      type: "TextBoxWidget",
      parent: "Chng1007VndrNum",
      Label: "Buying Point:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyPtCVN_PROPERTIES

      // END_USER_CODE-USER_txtBuyPtCVN_PROPERTIES
    },
    txtBuyPtWR: {
      name: "txtBuyPtWR",
      type: "TextBoxWidget",
      parent: "grpbxChngWRNum",
      Label: "Buying point:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyPtWR_PROPERTIES

      // END_USER_CODE-USER_txtBuyPtWR_PROPERTIES
    },
    txtInspectionNum: {
      name: "txtInspectionNum",
      type: "TextBoxWidget",
      parent: "Chng1007VndrNum",
      Label: "Inspection #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInspectionNum_PROPERTIES

      // END_USER_CODE-USER_txtInspectionNum_PROPERTIES
    },
    txtInspNumCSN: {
      name: "txtInspNumCSN",
      type: "TextBoxWidget",
      parent: "grpbxChngSettle1007Num",
      Label: "Inspection #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInspNumCSN_PROPERTIES

      // END_USER_CODE-USER_txtInspNumCSN_PROPERTIES
    },
    txtNewInspection: {
      name: "txtNewInspection",
      type: "TextBoxWidget",
      parent: "grpbxChngInsNum",
      Label: "New Inspection #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNewInspection_PROPERTIES

      // END_USER_CODE-USER_txtNewInspection_PROPERTIES
    },
    txtNewReceiptNum: {
      name: "txtNewReceiptNum",
      type: "TextBoxWidget",
      parent: "grpbxChngWRNum",
      Label: "New Receipt #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNewReceiptNum_PROPERTIES

      // END_USER_CODE-USER_txtNewReceiptNum_PROPERTIES
    },
    txtNewSettleNum: {
      name: "txtNewSettleNum",
      type: "TextBoxWidget",
      parent: "grpbxChngSettle1007Num",
      Label: "New Settlement #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNewSettleNum_PROPERTIES

      // END_USER_CODE-USER_txtNewSettleNum_PROPERTIES
    },
    txtNewTransferNum: {
      name: "txtNewTransferNum",
      type: "TextBoxWidget",
      parent: "grpbxTransferNum",
      Label: "New Transfer #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNewTransferNum_PROPERTIES

      // END_USER_CODE-USER_txtNewTransferNum_PROPERTIES
    },
    txtNewVendorNum: {
      name: "txtNewVendorNum",
      type: "TextBoxWidget",
      parent: "Chng1007VndrNum",
      Label: "New Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNewVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtNewVendorNum_PROPERTIES
    },
    txtOldReceiptNum: {
      name: "txtOldReceiptNum",
      type: "TextBoxWidget",
      parent: "grpbxChngWRNum",
      Label: "Old Receipt #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOldReceiptNum_PROPERTIES

      // END_USER_CODE-USER_txtOldReceiptNum_PROPERTIES
    },
    txtOldSettleNum: {
      name: "txtOldSettleNum",
      type: "TextBoxWidget",
      parent: "grpbxChngSettle1007Num",
      Label: "Old Settlement #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOldSettleNum_PROPERTIES

      // END_USER_CODE-USER_txtOldSettleNum_PROPERTIES
    },
    txtOldTransferNum: {
      name: "txtOldTransferNum",
      type: "TextBoxWidget",
      parent: "grpbxTransferNum",
      Label: "Old Transfer #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOldTransferNum_PROPERTIES

      // END_USER_CODE-USER_txtOldTransferNum_PROPERTIES
    },
    txtOldVendorNum: {
      name: "txtOldVendorNum",
      type: "TextBoxWidget",
      parent: "Chng1007VndrNum",
      Label: "Old Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOldVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtOldVendorNum_PROPERTIES
    },
    txtShippingBuyPt: {
      name: "txtShippingBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxTransferNum",
      Label: "Shipping Buying Point:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShippingBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtShippingBuyPt_PROPERTIES
    },
    grpbxChangeNumbers: {
      name: "grpbxChangeNumbers",
      type: "GroupBoxWidget",
      parent: "SpecFuncChgNum",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxChangeNumbers_PROPERTIES

      // END_USER_CODE-USER_grpbxChangeNumbers_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "SpecFuncChgNum",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const FormLoad = () => {
    hide(thisObj, 'grpbxChngInsNum')
    hide(thisObj, 'grpbxChangeTransferNum')
    hide(thisObj, 'Chng1007VndrNum')
    hide(thisObj, 'grpbxChngSettle1007Num')
    hide(thisObj, 'grpbxChngWRNum')
    hide(thisObj, 'btnUpdate', false)
  }

  const onradioChangeNumbersDblclick = () => {
    try {
      setValue(thisObj, 'radioChangeNumbers', '')
      FormLoad()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during Dblclick event')
      }
    }
  }
  thisObj.onradioChangeNumbersDblclick = onradioChangeNumbersDblclick;

  // onchange event fro radiochangenumbers
  const onradioChangeNumbersChange = async () => {
    try {

      let radioChangeNumbers = getValue(thisObj, 'radioChangeNumbers')

      if (radioChangeNumbers == '1') {
        show(thisObj, 'grpbxChngInsNum')
        hide(thisObj, 'grpbxChangeTransferNum')
        hide(thisObj, 'Chng1007VndrNum')
        hide(thisObj, 'grpbxChngSettle1007Num')
        hide(thisObj, 'grpbxChngWRNum')
        show(thisObj, 'btnUpdate')
      }
      else if (radioChangeNumbers == '2') {
        show(thisObj, 'grpbxChangeTransferNum')
        hide(thisObj, 'grpbxChngInsNum')
        hide(thisObj, 'Chng1007VndrNum')
        hide(thisObj, 'grpbxChngSettle1007Num')
        hide(thisObj, 'grpbxChngWRNum')
        show(thisObj, 'btnUpdate')
      }
      else if (radioChangeNumbers == '3') {
        show(thisObj, 'Chng1007VndrNum')
        hide(thisObj, 'grpbxChngSettle1007Num')
        hide(thisObj, 'grpbxChngWRNum')
        hide(thisObj, 'grpbxChangeTransferNum')
        hide(thisObj, 'grpbxChngWRNum')
        show(thisObj, 'btnUpdate')
      }
      else if (radioChangeNumbers == '4') {
        show(thisObj, 'grpbxChngSettle1007Num')
        hide(thisObj, 'grpbxChngInsNum')
        hide(thisObj, 'grpbxChangeTransferNum')
        hide(thisObj, 'Chng1007VndrNum')
        hide(thisObj, 'grpbxChngWRNum')
        show(thisObj, 'btnUpdate')
      } else if (radioChangeNumbers == '5') {
        show(thisObj, 'grpbxChngWRNum')
        hide(thisObj, 'grpbxChngInsNum')
        hide(thisObj, 'grpbxChangeTransferNum')
        hide(thisObj, 'Chng1007VndrNum')
        hide(thisObj, 'grpbxChngSettle1007Num')
        show(thisObj, 'btnUpdate')
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during radioChangeNumbersChange event')
      }
    }

  };
  thisObj.onradioChangeNumbersChange = onradioChangeNumbersChange

  const onbtnUpdateClick = async () => {
    try {
      //=== Change Inspection / Begin ===//

      if (getValue(thisObj, 'radioChangeNumbers') == '1') {

        let txtBuyPtValue = getValue(thisObj, 'txtBuyPt')
        if (txtBuyPtValue == undefined || txtBuyPtValue == null || txtBuyPtValue == '' || txtBuyPtValue.length != 3) {
          alert('Please enter a valid Buying Point!')
          document.getElementById("txtBuyPt").focus();
          return;
        }
        let txtOldInspectionValue = getValue(thisObj, 'txtOldInspection')
        if (txtOldInspectionValue == undefined || txtOldInspectionValue == null || txtOldInspectionValue == '' || txtOldInspectionValue.length != 7) {
          alert('Please enter a valid Inspection Number!')
          document.getElementById("txtOldInspection").focus();
          return;
        }
        let txtNewInspectionValue = getValue(thisObj, 'txtNewInspection')
        if (txtNewInspectionValue == undefined || txtNewInspectionValue == null || txtNewInspectionValue == '' || txtOldInspectionValue.length != 7) {
          alert('Please enter a valid Inspection Number!')
          document.getElementById("txtNewInspection").focus();
          return;
        }
        if (getValue(thisObj, 'txtOldInspection') == getValue(thisObj, 'txtNewInspection')) {
          alert('Old Inspection # and New Inspection # can not be the same value. Please check entry."')
          document.getElementById("txtOldInspection").focus();
          return;
        }
        let buyingPt = getValue(thisObj, 'txtBuyPt')
        let oldInspNum = getValue(thisObj, 'txtOldInspection')
        let newInspNum = getValue(thisObj, 'txtNewInspection')
        let dataObj = {}
        dataObj.user_id = useridFromLS()
        let response = await SettlementService.UpdateChangeInspectionNumber(compIdFromLS(), cropYearFromLS(), buyingPt, oldInspNum, newInspNum, dataObj)

        if (response.status == 200) {
          showMessage(thisObj, 'Inspection #: ' + oldInspNum + " was successfully changed to  " + newInspNum, true)
          return;
        }

        else if ([500, 400, 404].includes(response?.status)) {

          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n" + response?.message)
          showMessage(thisObj, 'Change Inspection Number Failed!')
        }




      }
      //=== Change Settlement / Begin ===
      if (getValue(thisObj, 'radioChangeNumbers') == '4') {
        let txtBuyPtCSNValue = getValue(thisObj, 'txtBuyPtCSN')
        if (txtBuyPtCSNValue == undefined || txtBuyPtCSNValue == null || txtBuyPtCSNValue == '' || txtBuyPtCSNValue.length != 3) {
          alert('Please enter a valid Buying Point!Change Settlement')
          document.getElementById("txtBuyPtCSN").focus();
          return;
        }
        let txtInspNumCSNValue = getValue(thisObj, 'txtInspNumCSN')
        if (txtInspNumCSNValue == undefined || txtInspNumCSNValue == null || txtInspNumCSNValue == '' || txtInspNumCSNValue.length != 7) {
          alert('Please enter a valid Inspection Number!Change Settlement')
          document.getElementById("txtInspNumCSN").focus();
          return;
        }
        let txtOldSettleNumValue = getValue(thisObj, 'txtOldSettleNum')
        if (txtOldSettleNumValue == undefined || txtOldSettleNumValue == null || txtOldSettleNumValue == '' || txtOldSettleNumValue.length > 7 || isNaN(txtOldSettleNumValue) == true) {
          alert('Please enter a valid Settlement Number!Change Settlement')
          document.getElementById("txtOldSettleNum").focus();
          return;
        }
        let txtNewSettleNumValue = getValue(thisObj, 'txtNewSettleNum')
        if (txtNewSettleNumValue == undefined || txtNewSettleNumValue == null || txtNewSettleNumValue == '' || txtNewSettleNumValue.length > 7 || isNaN(txtOldSettleNumValue) == true) {
          alert('Please enter a valid Settlement Number!Change Settlement')
          document.getElementById("txtNewSettleNum").focus();
          return;
        }
        if (getValue(thisObj, 'txtOldSettleNum') == getValue(thisObj, 'txtNewSettleNum')) {
          alert('Old Settlement # and New Settlement # can not be the same value. Please check entry."!, "Change Settlement')
          document.getElementById("txtOldInspection").focus();
          return;
        }
        let buy_pt_id = getValue(thisObj, 'txtBuyPtCSN')
        let insp_num = getValue(thisObj, 'txtInspNumCSN')
        let settlement_num = getValue(thisObj, 'txtOldSettleNum')
        let dataObj = {}
        dataObj.new_settlement_num = getValue(thisObj, 'txtNewSettleNum')
        dataObj.user_id = useridFromLS()
        await SystemMaintenanceApplicationSupportService.UpdateSettlementNumber(buy_pt_id, insp_num, settlement_num, dataObj).then(response => {
          if (response.status == 200) {
            showMessage(thisObj, 'Settlement #: ' + settlement_num + ' was successfully changed to ' + dataObj.new_settlement_num, true)
            return;
          }
          else if ([500, 400, 404].includes(response?.status)) {
            alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n" + response?.message)
            showMessage(thisObj, "Change Settlement Number Failed!");
          }
        })

      }
      //change transfer//
      if (getValue(thisObj, 'radioChangeNumbers') == '2') {
        let txtShippingBuyPtValue = getValue(thisObj, 'txtShippingBuyPt')
        if (txtShippingBuyPtValue == undefined || txtShippingBuyPtValue == null || txtShippingBuyPtValue == '' || txtShippingBuyPtValue.length != 3) {
          alert('Please enter a valid Shipping Buying Point!')
          document.getElementById("txtShippingBuyPt").focus();
          return;
        }
        let txtOldTransferNumValue = getValue(thisObj, 'txtOldTransferNum')
        if (txtOldTransferNumValue == undefined || txtOldTransferNumValue == null || txtOldTransferNumValue == '' || txtOldTransferNumValue.length > 6) {
          alert('Please enter a valid Transfer Number!')
          document.getElementById("txtOldTransferNum").focus();
          return;
        }
        let txtNewTransferNumValue = getValue(thisObj, 'txtNewTransferNum')
        if (txtNewTransferNumValue == undefined || txtNewTransferNumValue == null || txtNewTransferNumValue == '' || txtNewTransferNumValue.length > 6 || isNaN(txtNewTransferNumValue) == true) {
          alert('Please enter a valid Transfer Number!')
          document.getElementById("txtNewTransferNum").focus();
          return;
        }
        if (getValue(thisObj, 'txtOldTransferNum') == getValue(thisObj, 'txtNewTransferNum')) {
          alert('Old Transfer # and New Transfer # can not be the same value. Please check entry."!')
          document.getElementById("txtOldTransferNum").focus();
          return;
        }
        let RadioValue = getValue(thisObj, 'radioTranferType')
        let sTransferType = ''

        if (RadioValue == '1') {
          sTransferType = "ST"
        }
        else if (RadioValue == '2') {
          sTransferType = "FF"
        }
        else if (RadioValue == '3') {
          sTransferType = "NL"
        }
        else {
          alert('Please select a Transfer Type!')
        }

        let buy_pt_id = getValue(thisObj, 'txtShippingBuyPt')
        let transfer_type = sTransferType
        let transfer_num = getValue(thisObj, 'txtOldTransferNum')
        let dataObj = {}
        dataObj.new_transfer_num = getValue(thisObj, 'txtNewTransferNum')
        await SystemMaintenanceApplicationSupportService.UpdateTransferNumber(buy_pt_id, transfer_type, transfer_num, dataObj).then(response => {
          if (response.status == 200) {
            showMessage(thisObj, 'Transfer #: ' + transfer_num + ' was successfully changed to ' + dataObj.new_transfer_num, true)
            return;
          }
          else if ([500, 400, 404].includes(response?.status)) {
            alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n" + response?.message)
            showMessage(thisObj, "Change Transfer Number Failed!");
          }
        })
      }
      //Change Warehouse Receipt
      if (getValue(thisObj, 'radioChangeNumbers') == '5') {
        let txtBuyPtWRValue = getValue(thisObj, 'txtBuyPtWR')
        if (txtBuyPtWRValue == undefined || txtBuyPtWRValue == null || txtBuyPtWRValue == '' || txtBuyPtWRValue.length != 3) {
          alert('Please enter a valid Shipping Buying Point!')
          document.getElementById("txtBuyPtWR").focus();
          return;
        }
        let txtOldReceiptNumValue = getValue(thisObj, 'txtOldReceiptNum')
        if (txtOldReceiptNumValue == undefined || txtOldReceiptNumValue == null || txtOldReceiptNumValue == '' || txtOldReceiptNumValue.length > 6 || isNaN(txtOldReceiptNumValue) == true) {
          alert('Please enter a valid Warehouse Receipt Number!')
          document.getElementById("txtOldReceiptNum").focus();
          return;
        }
        let txtNewReceiptNumValue = getValue(thisObj, 'txtNewReceiptNum')
        if (txtNewReceiptNumValue == undefined || txtNewReceiptNumValue == null || txtNewReceiptNumValue == '' || txtNewReceiptNumValue.length > 6 || isNaN(txtNewReceiptNumValue) == true) {
          alert('Please enter a valid Warehouse Receipt Number!')
          document.getElementById("txtNewReceiptNum").focus();
          return;
        }
        if (getValue(thisObj, 'txtOldReceiptNum') == getValue(thisObj, 'txtNewReceiptNum')) {
          alert('Old Warehouse Receipt Number # and Warehouse Receipt Number  # can not be the same value. Please check entry."!')
          document.getElementById("txtOldReceiptNum").focus();
          return;
        }
        let buy_pt_id = getValue(thisObj, 'txtBuyPtWR')
        let receipt_number = getValue(thisObj, 'txtOldReceiptNum')
        let dataObj = {}
        dataObj.new_receipt_number = getValue(thisObj, 'txtNewReceiptNum')
        dataObj.user_id = useridFromLS()
        await SystemMaintenanceApplicationSupportService.UpdateReceiptNumber(buy_pt_id, receipt_number, dataObj).then(response => {
          if (response.status == 200) {
            showMessage(thisObj, 'Warehouse Receipt#: ' + receipt_number + ' was successfully changed to ' + dataObj.new_receipt_number, true)
            return;
          }
          else if ([500, 400, 404].includes(response?.status)) {
            alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n" + response?.message)
            showMessage(thisObj, "Change Warehouse Receipt Number Failed!");
          }
        })


      }
      // Change 1007 Vendor 
      if (getValue(thisObj, 'radioChangeNumbers') == '3') {
        let txtBuyPtCVNValue = getValue(thisObj, 'txtBuyPtCVN')
        if (txtBuyPtCVNValue == undefined || txtBuyPtCVNValue == null || txtBuyPtCVNValue == '' || txtBuyPtCVNValue.length != 3) {
          alert('Please enter a valid Shipping Buying Point!')
          document.getElementById("txtBuyPtCVN").focus();
          return;
        }
        let txtInspectionNumValue = getValue(thisObj, 'txtInspectionNum')
        if (txtInspectionNumValue == undefined || txtInspectionNumValue == null || txtInspectionNumValue == '' || txtInspectionNumValue.length != 7) {
          alert('Please enter a valid  Inspection Number!')
          document.getElementById("txtInspectionNum").focus();
          return;
        }
        let txtOldVendorNumValue = getValue(thisObj, 'txtOldVendorNum')
        if (txtOldVendorNumValue == undefined || txtOldVendorNumValue == null || txtOldVendorNumValue == '' || txtOldVendorNumValue.length != 6) {
          alert('Please enter a valid  Old Vendor Number!')
          document.getElementById("txtOldVendorNum").focus();
          return;
        }
        let txtNewVendorNumValue = getValue(thisObj, 'txtNewVendorNum')
        if (txtNewVendorNumValue == undefined || txtNewVendorNumValue == null || txtNewVendorNumValue == '' || txtNewVendorNumValue.length != 6) {
          alert('Please enter a valid  new Vendor Number!')
          document.getElementById("txtNewVendorNum").focus();
          return;
        }
        if (getValue(thisObj, 'txtNewVendorNum') == getValue(thisObj, 'txtOldVendorNum')) {
          alert('Old Vendor # and New Vendor # can not be the same value. Please check entry."!')
          document.getElementById("txtNewVendorNum").focus();
          return;
        }
        let buy_pt_id = getValue(thisObj, 'txtBuyPtCVN')
        let insp_num = getValue(thisObj, 'txtInspectionNum')
        let vendor_num = getValue(thisObj, 'txtOldVendorNum')
        let dataObj = {}
        dataObj.new_vendor = getValue(thisObj, 'txtNewVendorNum')
        await SystemMaintenanceApplicationSupportService.Update1007Vendor(buy_pt_id, insp_num, vendor_num, dataObj).then(response => {
          if (response.status == 200) {
            showMessage(thisObj, 'Inspect Vendor has been changed to vendor: ' + dataObj.new_vendor, true)
            return;
          }
          else if ([500, 400, 404].includes(response?.status)) {
            alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n" + response?.message)
            showMessage(thisObj, "Change Vendor Number Failed!");
          }
        }
        )
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during btnUpdate event')
      }
    }
  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick;

  //text box validation 
  const validateNewTransferTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
        res += data[i]
      }
    }
    return res;
  }
  const validateOldTransferTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) >= 65 && data[i].charCodeAt(0) <= 90) || (data[i].charCodeAt(0) >= 97 && data[i].charCodeAt(0) <= 122)) {
        res += data[i]
      }
    }
    return res;
  }
  //new transfer num textbox
  const ontxtNewTransferNumChange = () => {
    try {
      let txtNewTransferNumValue = getValue(thisObj, 'txtNewTransferNum')
      setValue(thisObj, "txtNewTransferNum", validateNewTransferTextBox(txtNewTransferNumValue))
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during validateNewTransferTextBox event')
      }
    }
  };
  thisObj.ontxtNewTransferNumChange = ontxtNewTransferNumChange;

  const ontxtNewTransferNumBlur = () => {
    try {
      let txtNewTransferNumValue = getValue(thisObj, 'txtNewTransferNum')
      if (txtNewTransferNumValue != undefined && txtNewTransferNumValue != null) {
        let NewTransferNumLength = txtNewTransferNumValue.length
        if (NewTransferNumLength < 6 && NewTransferNumLength > 0) {
          let NewTransferNum = '0'.repeat((6 - NewTransferNumLength)) + txtNewTransferNumValue
          setValue(thisObj, 'txtNewTransferNum', NewTransferNum)
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during txtNewTransferNumBlur event')
      }
    }
  }
  thisObj.ontxtNewTransferNumBlur = ontxtNewTransferNumBlur;

  //old transfer num textbox     

  const ontxtOldTransferNumChange = () => {
    try {
      let txtOldTransferNumValue = getValue(thisObj, 'txtOldTransferNum')
      setValue(thisObj, "txtOldTransferNum", validateOldTransferTextBox(txtOldTransferNumValue))
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during txtOldTransferNumChange event')
      }
    }
  };
  thisObj.ontxtOldTransferNumChange = ontxtOldTransferNumChange

  const ontxtOldTransferNumValueBlur = () => {
    try {
      let txtOldTransferNumValue = getValue(thisObj, 'txtOldTransferNum')
      if (txtOldTransferNumValue != undefined && txtOldTransferNumValue != null) {
        let OldTransferNumLength = txtOldTransferNumValue.length
        if (OldTransferNumLength < 6 && OldTransferNumLength > 0) {
          let NewTransferNum = '0'.repeat((6 - OldTransferNumLength)) + txtOldTransferNumValue
          setValue(thisObj, 'txtOldTransferNum', NewTransferNum)
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during txtOldTransferNumValueBlur event')
      }
    }
  }
  thisObj.ontxtOldTransferNumValueBlur = ontxtOldTransferNumValueBlur
  //cancel button click event 
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceApplicationSupport_SpecFuncChgNumPopUp").childNodes[0].click()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during btncancel event')
      }
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SpecFuncChgNum*/}

              {/* END_USER_CODE-USER_BEFORE_SpecFuncChgNum*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxChangeNumbers*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxChangeNumbers*/}

              <GroupBoxWidget
                conf={state.grpbxChangeNumbers}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblChangeNumbers*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangeNumbers*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangeNumbers}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangeNumbers*/}

                {/* END_USER_CODE-USER_AFTER_lblChangeNumbers*/}
                {/* START_USER_CODE-USER_BEFORE_radioChangeNumbers*/}

                {/* END_USER_CODE-USER_BEFORE_radioChangeNumbers*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onClick={(event) =>

                    invokeEventHandler(event, handleChange, handleBlur, thisObj)

                  }

                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioChangeNumbers}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioChangeNumbers*/}

                {/* END_USER_CODE-USER_AFTER_radioChangeNumbers*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxChngInsNum*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxChngInsNum*/}

                <GroupBoxWidget conf={state.grpbxChngInsNum} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblChngInsNum*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChngInsNum*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChngInsNum}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChngInsNum*/}

                  {/* END_USER_CODE-USER_AFTER_lblChngInsNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBuyPt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyPt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyPt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyPt*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyPt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOldInspection*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOldInspection*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOldInspection}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOldInspection*/}

                  {/* END_USER_CODE-USER_AFTER_txtOldInspection*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNewInspection*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNewInspection*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNewInspection}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNewInspection*/}

                  {/* END_USER_CODE-USER_AFTER_txtNewInspection*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxChngInsNum*/}

                {/* END_USER_CODE-USER_AFTER_grpbxChngInsNum*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxChangeTransferNum*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxChangeTransferNum*/}

                <GroupBoxWidget
                  conf={state.grpbxChangeTransferNum}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblChangeTransferNum*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChangeTransferNum*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChangeTransferNum}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChangeTransferNum*/}

                  {/* END_USER_CODE-USER_AFTER_lblChangeTransferNum*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxTransferNum*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxTransferNum*/}

                  <GroupBoxWidget
                    conf={state.grpbxTransferNum}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_txtShippingBuyPt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShippingBuyPt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShippingBuyPt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShippingBuyPt*/}

                    {/* END_USER_CODE-USER_AFTER_txtShippingBuyPt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOldTransferNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOldTransferNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOldTransferNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOldTransferNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtOldTransferNum*/}
                    {/* START_USER_CODE-USER_BEFORE_txtNewTransferNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtNewTransferNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtNewTransferNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtNewTransferNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtNewTransferNum*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxTransferNum*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxTransferNum*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxTransferType*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxTransferType*/}

                  <GroupBoxWidget
                    conf={state.grpbxTransferType}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_radioTranferType*/}

                    {/* END_USER_CODE-USER_BEFORE_radioTranferType*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioTranferType}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioTranferType*/}

                    {/* END_USER_CODE-USER_AFTER_radioTranferType*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxTransferType*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxTransferType*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxChangeTransferNum*/}

                {/* END_USER_CODE-USER_AFTER_grpbxChangeTransferNum*/}
                {/* START_USER_CODE-USER_BEFORE_Chng1007VndrNum*/}

                {/* END_USER_CODE-USER_BEFORE_Chng1007VndrNum*/}

                <GroupBoxWidget conf={state.Chng1007VndrNum} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblChng1007VndrNum*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChng1007VndrNum*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChng1007VndrNum}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChng1007VndrNum*/}

                  {/* END_USER_CODE-USER_AFTER_lblChng1007VndrNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBuyPtCVN*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyPtCVN*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyPtCVN}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyPtCVN*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyPtCVN*/}
                  {/* START_USER_CODE-USER_BEFORE_txtInspectionNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtInspectionNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtInspectionNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtInspectionNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtInspectionNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOldVendorNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOldVendorNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOldVendorNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOldVendorNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtOldVendorNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNewVendorNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNewVendorNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNewVendorNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNewVendorNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtNewVendorNum*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_Chng1007VndrNum*/}

                {/* END_USER_CODE-USER_AFTER_Chng1007VndrNum*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxChngSettle1007Num*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxChngSettle1007Num*/}

                <GroupBoxWidget
                  conf={state.grpbxChngSettle1007Num}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblChngSettle1007Num*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChngSettle1007Num*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChngSettle1007Num}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChngSettle1007Num*/}

                  {/* END_USER_CODE-USER_AFTER_lblChngSettle1007Num*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBuyPtCSN*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyPtCSN*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyPtCSN}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyPtCSN*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyPtCSN*/}
                  {/* START_USER_CODE-USER_BEFORE_txtInspNumCSN*/}

                  {/* END_USER_CODE-USER_BEFORE_txtInspNumCSN*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtInspNumCSN}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtInspNumCSN*/}

                  {/* END_USER_CODE-USER_AFTER_txtInspNumCSN*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOldSettleNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOldSettleNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOldSettleNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOldSettleNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtOldSettleNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNewSettleNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNewSettleNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNewSettleNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNewSettleNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtNewSettleNum*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxChngSettle1007Num*/}

                {/* END_USER_CODE-USER_AFTER_grpbxChngSettle1007Num*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxChngWRNum*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxChngWRNum*/}

                <GroupBoxWidget conf={state.grpbxChngWRNum} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblChngWRNum*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChngWRNum*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChngWRNum}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChngWRNum*/}

                  {/* END_USER_CODE-USER_AFTER_lblChngWRNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBuyPtWR*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBuyPtWR*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBuyPtWR}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBuyPtWR*/}

                  {/* END_USER_CODE-USER_AFTER_txtBuyPtWR*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOldReceiptNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOldReceiptNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOldReceiptNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOldReceiptNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtOldReceiptNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNewReceiptNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNewReceiptNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNewReceiptNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNewReceiptNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtNewReceiptNum*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxChngWRNum*/}

                {/* END_USER_CODE-USER_AFTER_grpbxChngWRNum*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxChangeNumbers*/}

              {/* END_USER_CODE-USER_AFTER_grpbxChangeNumbers*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_SpecFuncChgNum*/}

              {/* END_USER_CODE-USER_AFTER_SpecFuncChgNum*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceApplicationSupport_SpecFuncChgNum);
