/* eslint-disable*/
import React from "react";
import ListSelectDeselectWidget from "./ListSelectDeselectWidget";
import { screen } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup, fireEvent } from "@testing-library/react";

describe("List selecting test cases :1", () => {
  beforeEach(async () => {
    act(() => {
      let ListSelectDeselectWidget1 = {
        name: "ListSelectDeselectWidget1",
        type: "ListSelectDeselectWidget",
        parent: "groupboxwidget0",
        Label: "ListSelectDeselectWidget1",
        ofTypeDomain: "d_String",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "3",
        RowSpanForLargeDesktop: "3",
        RowSpanForMobile: "3",
        RowSpanForTabLandscape: "3",
        RowSpanForTabPotrait: "3",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      renderTestScreen(
        <ListSelectDeselectWidget
          conf={ListSelectDeselectWidget1}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("List selecting test cases", async () => {
    const listOption = screen.getByText("Option1");
    fireEvent.click(listOption);
    expect(listOption).toBeTruthy();

    const listOption2 = screen.getByText("Option1");
    fireEvent.click(listOption2);
    expect(listOption2).toBeTruthy();
  });
});

describe("List select deselect test cases : 2", () => {
  beforeEach(async () => {
    act(() => {
      let ListSelectDeselectWidget1 = {
        name: "ListSelectDeselectWidget1",
        type: "ListSelectDeselectWidget",
        parent: "groupboxwidget0",
        Label: "ListSelectDeselectWidget1",
        ofTypeDomain: "d_String",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "3",
        RowSpanForLargeDesktop: "3",
        RowSpanForMobile: "3",
        RowSpanForTabLandscape: "3",
        RowSpanForTabPotrait: "3",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true,
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      renderTestScreen(
        <ListSelectDeselectWidget
          conf={ListSelectDeselectWidget1}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("List select deselect test cases", async () => {
    const ListSelectDeselectWidget1 = screen.getByTestId(
      "ListSelectDeselectWidget1"
    ).firstChild.firstChild;
    expect(ListSelectDeselectWidget1.tagName).toBe("LABEL");
    const listSelect = screen.getByText("Option1");
    expect(listSelect.value).toBe("1");
  });
});
describe("List button test cases", () => {
  beforeEach(async () => {
    act(() => {
      let ListSelectDeselectWidget3 = {
        name: "ListSelectDeselectWidget3",
        type: "ListSelectDeselectWidget",
        parent: "groupboxwidget0",
        Label: "ListSelectDeselectWidget3",
        ofTypeDomain: "d_String",
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "3",
        RowSpanForLargeDesktop: "3",
        RowSpanForMobile: "3",
        RowSpanForTabLandscape: "3",
        RowSpanForTabPotrait: "3",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true,
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      let onChange = jest.fn();
      renderTestScreen(
        <ListSelectDeselectWidget
          onChange={onChange}
          conf={ListSelectDeselectWidget3}
          screenConf={stateScreenConf}
          values={values}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("list button Test Cases", async () => {
    const btn1 = screen.getByTitle("Move right");
    expect(btn1.tagName).toBe("BUTTON");
    expect(btn1.type).toBe("button");

    const opt = screen.getByText("Option1");
    fireEvent.click(opt, { button: 0 });
    fireEvent.click(btn1, { button: 0 });
    expect(opt).toBeInTheDocument;
  });
});
