/* eslint-disable*/
import React from "react";
import ContractManagement_ExceptPremRequestSearch from "./ExceptPremRequestSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ExceptPremRequestSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ExceptPremRequestSearch />);
    });
  });
  afterEach(cleanup);
  test("is ExceptPremRequestSearch Loads Successfully", () => {
    expect(screen.getByText("ReportPreviewExceptPrem")).toBeInTheDocument;
  });
  test("Custom Test Cases for ExceptPremRequestSearch", () => {
    // START_USER_CODE-USER_ExceptPremRequestSearch_Custom_Test_Case
    // END_USER_CODE-USER_ExceptPremRequestSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ExceptPremRequestSearch />);
    });
  });
  afterEach(cleanup);
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnPrintPayReq(Button Widget) Test Cases", async () => {
    const btnPrintPayReq = screen.getByTestId("btnPrintPayReq");
    expect(btnPrintPayReq).toBeInTheDocument;
    expect(btnPrintPayReq.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_btnPrintPayReq")
    );
  });
  test("Custom Test Cases for btnPrintPayReq", () => {
    // START_USER_CODE-USER_btnPrintPayReq_TEST
    // END_USER_CODE-USER_btnPrintPayReq_TEST
  });
  test("btnRejectPrem(Button Widget) Test Cases", async () => {
    const btnRejectPrem = screen.getByTestId("btnRejectPrem");
    expect(btnRejectPrem).toBeInTheDocument;
    expect(btnRejectPrem.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_btnRejectPrem")
    );
  });
  test("Custom Test Cases for btnRejectPrem", () => {
    // START_USER_CODE-USER_btnRejectPrem_TEST
    // END_USER_CODE-USER_btnRejectPrem_TEST
  });
  test("btnReprintPayReq(Button Widget) Test Cases", async () => {
    const btnReprintPayReq = screen.getByTestId("btnReprintPayReq");
    expect(btnReprintPayReq).toBeInTheDocument;
    expect(btnReprintPayReq.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_btnReprintPayReq")
    );
  });
  test("Custom Test Cases for btnReprintPayReq", () => {
    // START_USER_CODE-USER_btnReprintPayReq_TEST
    // END_USER_CODE-USER_btnReprintPayReq_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("grpbx1007s(GroupBox Widget) Test Cases", async () => {
    const grpbx1007s = screen.getByTestId("grpbx1007s");
    expect(grpbx1007s.tagName).toBe("BUTTON");
    expect(grpbx1007s.type).toBe("button");
    expect(grpbx1007s.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbx1007s", () => {
    // START_USER_CODE-USER_grpbx1007s_TEST
    // END_USER_CODE-USER_grpbx1007s_TEST
  });
  test("grpbxExit(GroupBox Widget) Test Cases", async () => {
    const grpbxExit = screen.getByTestId("grpbxExit");
    expect(grpbxExit.tagName).toBe("BUTTON");
    expect(grpbxExit.type).toBe("button");
    expect(grpbxExit.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxExit", () => {
    // START_USER_CODE-USER_grpbxExit_TEST
    // END_USER_CODE-USER_grpbxExit_TEST
  });
  test("grpbxPayReq(GroupBox Widget) Test Cases", async () => {
    const grpbxPayReq = screen.getByTestId("grpbxPayReq");
    expect(grpbxPayReq.tagName).toBe("BUTTON");
    expect(grpbxPayReq.type).toBe("button");
    expect(grpbxPayReq.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPayReq", () => {
    // START_USER_CODE-USER_grpbxPayReq_TEST
    // END_USER_CODE-USER_grpbxPayReq_TEST
  });
  test("grpbxReprintPayReq(GroupBox Widget) Test Cases", async () => {
    const grpbxReprintPayReq = screen.getByTestId("grpbxReprintPayReq");
    expect(grpbxReprintPayReq.tagName).toBe("BUTTON");
    expect(grpbxReprintPayReq.type).toBe("button");
    expect(grpbxReprintPayReq.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReprintPayReq", () => {
    // START_USER_CODE-USER_grpbxReprintPayReq_TEST
    // END_USER_CODE-USER_grpbxReprintPayReq_TEST
  });
  test("grpbxSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxSearch = screen.getByTestId("grpbxSearch");
    expect(grpbxSearch.tagName).toBe("BUTTON");
    expect(grpbxSearch.type).toBe("button");
    expect(grpbxSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSearch", () => {
    // START_USER_CODE-USER_grpbxSearch_TEST
    // END_USER_CODE-USER_grpbxSearch_TEST
  });
  test("lblTotal(Label Widget) Test Cases", async () => {
    const lblTotal = screen.getByTestId("lblTotal");
    expect(lblTotal.tagName).toBe("LABEL");
    expect(lblTotal.classList).toContain("form-label");
    expect(lblTotal.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_lblTotal")
    );
  });
  test("Custom Test Cases for lblTotal", () => {
    // START_USER_CODE-USER_lblTotal_TEST
    // END_USER_CODE-USER_lblTotal_TEST
  });
  test("txtContract(Textbox Widget) Test Cases", async () => {
    const txtContract = screen.getByTestId("txtContract");
    expect(txtContract.tagName).toBe("INPUT");
    expect(txtContract.type).toBe("text");
    expect(txtContract.classList).toContain("textboxWidgetClass");
    expect(txtContract.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_txtContract")
    );
    await act(async () => {
      userEvent.type(txtContract, "1");
    });
  });
  test("Custom Test Cases for txtContract", () => {
    // START_USER_CODE-USER_txtContract_TEST
    // END_USER_CODE-USER_txtContract_TEST
  });
  test("txtDateReq(Textbox Widget) Test Cases", async () => {
    const txtDateReq = screen.getByTestId("txtDateReq");
    expect(txtDateReq.tagName).toBe("INPUT");
    expect(txtDateReq.type).toBe("text");
    expect(txtDateReq.classList).toContain("textboxWidgetClass");
    expect(txtDateReq.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_txtDateReq")
    );
    await act(async () => {
      userEvent.type(txtDateReq, "1");
    });
  });
  test("Custom Test Cases for txtDateReq", () => {
    // START_USER_CODE-USER_txtDateReq_TEST
    // END_USER_CODE-USER_txtDateReq_TEST
  });
  test("txtFarm(Textbox Widget) Test Cases", async () => {
    const txtFarm = screen.getByTestId("txtFarm");
    expect(txtFarm.tagName).toBe("INPUT");
    expect(txtFarm.type).toBe("text");
    expect(txtFarm.classList).toContain("textboxWidgetClass");
    expect(txtFarm.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_txtFarm")
    );
    await act(async () => {
      userEvent.type(txtFarm, "1");
    });
  });
  test("Custom Test Cases for txtFarm", () => {
    // START_USER_CODE-USER_txtFarm_TEST
    // END_USER_CODE-USER_txtFarm_TEST
  });
  test("txtFarmSuffix(Textbox Widget) Test Cases", async () => {
    const txtFarmSuffix = screen.getByTestId("txtFarmSuffix");
    expect(txtFarmSuffix.tagName).toBe("INPUT");
    expect(txtFarmSuffix.type).toBe("text");
    expect(txtFarmSuffix.classList).toContain("textboxWidgetClass");
    expect(txtFarmSuffix.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_txtFarmSuffix")
    );
    await act(async () => {
      userEvent.type(txtFarmSuffix, "1");
    });
  });
  test("Custom Test Cases for txtFarmSuffix", () => {
    // START_USER_CODE-USER_txtFarmSuffix_TEST
    // END_USER_CODE-USER_txtFarmSuffix_TEST
  });
  test("txtGeneration(Textbox Widget) Test Cases", async () => {
    const txtGeneration = screen.getByTestId("txtGeneration");
    expect(txtGeneration.tagName).toBe("INPUT");
    expect(txtGeneration.type).toBe("text");
    expect(txtGeneration.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtGeneration, "1");
    });
  });
  test("Custom Test Cases for txtGeneration", () => {
    // START_USER_CODE-USER_txtGeneration_TEST
    // END_USER_CODE-USER_txtGeneration_TEST
  });
  test("txtGrossWT(Textbox Widget) Test Cases", async () => {
    const txtGrossWT = screen.getByTestId("txtGrossWT");
    expect(txtGrossWT.tagName).toBe("INPUT");
    expect(txtGrossWT.type).toBe("text");
    expect(txtGrossWT.classList).toContain("textboxWidgetClass");
    expect(txtGrossWT.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_txtGrossWT")
    );
    await act(async () => {
      userEvent.type(txtGrossWT, "1");
    });
  });
  test("Custom Test Cases for txtGrossWT", () => {
    // START_USER_CODE-USER_txtGrossWT_TEST
    // END_USER_CODE-USER_txtGrossWT_TEST
  });
  test("txtLocation(Textbox Widget) Test Cases", async () => {
    const txtLocation = screen.getByTestId("txtLocation");
    expect(txtLocation.tagName).toBe("INPUT");
    expect(txtLocation.type).toBe("text");
    expect(txtLocation.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLocation, "1");
    });
  });
  test("Custom Test Cases for txtLocation", () => {
    // START_USER_CODE-USER_txtLocation_TEST
    // END_USER_CODE-USER_txtLocation_TEST
  });
  test("txtLSKWT(Textbox Widget) Test Cases", async () => {
    const txtLSKWT = screen.getByTestId("txtLSKWT");
    expect(txtLSKWT.tagName).toBe("INPUT");
    expect(txtLSKWT.type).toBe("text");
    expect(txtLSKWT.classList).toContain("textboxWidgetClass");
    expect(txtLSKWT.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_txtLSKWT")
    );
    await act(async () => {
      userEvent.type(txtLSKWT, "1");
    });
  });
  test("Custom Test Cases for txtLSKWT", () => {
    // START_USER_CODE-USER_txtLSKWT_TEST
    // END_USER_CODE-USER_txtLSKWT_TEST
  });
  test("txtNetWT(Textbox Widget) Test Cases", async () => {
    const txtNetWT = screen.getByTestId("txtNetWT");
    expect(txtNetWT.tagName).toBe("INPUT");
    expect(txtNetWT.type).toBe("text");
    expect(txtNetWT.classList).toContain("textboxWidgetClass");
    expect(txtNetWT.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_txtNetWT")
    );
    await act(async () => {
      userEvent.type(txtNetWT, "1");
    });
  });
  test("Custom Test Cases for txtNetWT", () => {
    // START_USER_CODE-USER_txtNetWT_TEST
    // END_USER_CODE-USER_txtNetWT_TEST
  });
  test("txtOleic(Textbox Widget) Test Cases", async () => {
    const txtOleic = screen.getByTestId("txtOleic");
    expect(txtOleic.tagName).toBe("INPUT");
    expect(txtOleic.type).toBe("text");
    expect(txtOleic.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOleic, "1");
    });
  });
  test("Custom Test Cases for txtOleic", () => {
    // START_USER_CODE-USER_txtOleic_TEST
    // END_USER_CODE-USER_txtOleic_TEST
  });
  test("txtPayReq(Textbox Widget) Test Cases", async () => {
    const txtPayReq = screen.getByTestId("txtPayReq");
    expect(txtPayReq.tagName).toBe("INPUT");
    expect(txtPayReq.type).toBe("text");
    expect(txtPayReq.classList).toContain("textboxWidgetClass");
    expect(txtPayReq.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_txtPayReq")
    );
    await act(async () => {
      userEvent.type(txtPayReq, "1");
    });
  });
  test("Custom Test Cases for txtPayReq", () => {
    // START_USER_CODE-USER_txtPayReq_TEST
    // END_USER_CODE-USER_txtPayReq_TEST
  });
  test("txtPeanuttype(Textbox Widget) Test Cases", async () => {
    const txtPeanuttype = screen.getByTestId("txtPeanuttype");
    expect(txtPeanuttype.tagName).toBe("INPUT");
    expect(txtPeanuttype.type).toBe("text");
    expect(txtPeanuttype.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPeanuttype, "1");
    });
  });
  test("Custom Test Cases for txtPeanuttype", () => {
    // START_USER_CODE-USER_txtPeanuttype_TEST
    // END_USER_CODE-USER_txtPeanuttype_TEST
  });
  test("txtPeanutVariety(Textbox Widget) Test Cases", async () => {
    const txtPeanutVariety = screen.getByTestId("txtPeanutVariety");
    expect(txtPeanutVariety.tagName).toBe("INPUT");
    expect(txtPeanutVariety.type).toBe("text");
    expect(txtPeanutVariety.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPeanutVariety, "1");
    });
  });
  test("Custom Test Cases for txtPeanutVariety", () => {
    // START_USER_CODE-USER_txtPeanutVariety_TEST
    // END_USER_CODE-USER_txtPeanutVariety_TEST
  });
  test("txtPremiumAmount(Textbox Widget) Test Cases", async () => {
    const txtPremiumAmount = screen.getByTestId("txtPremiumAmount");
    expect(txtPremiumAmount.tagName).toBe("INPUT");
    expect(txtPremiumAmount.type).toBe("text");
    expect(txtPremiumAmount.classList).toContain("textboxWidgetClass");
    expect(txtPremiumAmount.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_txtPremiumAmount")
    );
    await act(async () => {
      userEvent.type(txtPremiumAmount, "1");
    });
  });
  test("Custom Test Cases for txtPremiumAmount", () => {
    // START_USER_CODE-USER_txtPremiumAmount_TEST
    // END_USER_CODE-USER_txtPremiumAmount_TEST
  });
  test("txtPremiumCode(Textbox Widget) Test Cases", async () => {
    const txtPremiumCode = screen.getByTestId("txtPremiumCode");
    expect(txtPremiumCode.tagName).toBe("INPUT");
    expect(txtPremiumCode.type).toBe("text");
    expect(txtPremiumCode.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPremiumCode, "1");
    });
  });
  test("Custom Test Cases for txtPremiumCode", () => {
    // START_USER_CODE-USER_txtPremiumCode_TEST
    // END_USER_CODE-USER_txtPremiumCode_TEST
  });
  test("txtReqBy(Textbox Widget) Test Cases", async () => {
    const txtReqBy = screen.getByTestId("txtReqBy");
    expect(txtReqBy.tagName).toBe("INPUT");
    expect(txtReqBy.type).toBe("text");
    expect(txtReqBy.classList).toContain("textboxWidgetClass");
    expect(txtReqBy.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_txtReqBy")
    );
    await act(async () => {
      userEvent.type(txtReqBy, "1");
    });
  });
  test("Custom Test Cases for txtReqBy", () => {
    // START_USER_CODE-USER_txtReqBy_TEST
    // END_USER_CODE-USER_txtReqBy_TEST
  });
  test("txtSeed(Textbox Widget) Test Cases", async () => {
    const txtSeed = screen.getByTestId("txtSeed");
    expect(txtSeed.tagName).toBe("INPUT");
    expect(txtSeed.type).toBe("text");
    expect(txtSeed.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSeed, "1");
    });
  });
  test("Custom Test Cases for txtSeed", () => {
    // START_USER_CODE-USER_txtSeed_TEST
    // END_USER_CODE-USER_txtSeed_TEST
  });
  test("txtSegType(Textbox Widget) Test Cases", async () => {
    const txtSegType = screen.getByTestId("txtSegType");
    expect(txtSegType.tagName).toBe("INPUT");
    expect(txtSegType.type).toBe("text");
    expect(txtSegType.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSegType, "1");
    });
  });
  test("Custom Test Cases for txtSegType", () => {
    // START_USER_CODE-USER_txtSegType_TEST
    // END_USER_CODE-USER_txtSegType_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ExceptPremRequestSearch_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
});
