/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  getValue,
  setData,
  getData,
  goTo
} from "../../shared/WindowImports";

import "./EWRListRRI.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import Loading from "../../../Loader/Loading";
import moment from "moment";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_EWRListRRI(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "EWRListRRI",
    windowName: "EWRListRRI",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.EWRListRRI",
    // START_USER_CODE-USER_EWRListRRI_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "EWR List RRI",
      scrCode: "PN9010L",
    },
    // END_USER_CODE-USER_EWRListRRI_PROPERTIES
    btnView: {
      name: "btnView",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "View",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnView_PROPERTIES

      // END_USER_CODE-USER_btnView_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "grpbxDetails",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    colAddDate: {
      name: "colAddDate",
      type: "GridColumnWidget",
      parent: "gridListRRI",
      Label: "Add Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAddDate_PROPERTIES

      // END_USER_CODE-USER_colAddDate_PROPERTIES
    },
    colAddUser: {
      name: "colAddUser",
      type: "GridColumnWidget",
      parent: "gridListRRI",
      Label: "Add User",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAddUser_PROPERTIES

      // END_USER_CODE-USER_colAddUser_PROPERTIES
    },
    colBPNum: {
      name: "colBPNum",
      type: "GridColumnWidget",
      parent: "gridListRRI",
      Label: "BP #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBPNum_PROPERTIES

      // END_USER_CODE-USER_colBPNum_PROPERTIES
    },
    colFarmNum: {
      name: "colFarmNum",
      type: "GridColumnWidget",
      parent: "gridListRRI",
      Label: "Farm #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarmNum_PROPERTIES

      // END_USER_CODE-USER_colFarmNum_PROPERTIES
    },
    colFileNum: {
      name: "colFileNum",
      type: "GridColumnWidget",
      parent: "gridListRRI",
      Label: "File #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFileNum_PROPERTIES

      // END_USER_CODE-USER_colFileNum_PROPERTIES
    },
    colLicenseNum: {
      name: "colLicenseNum",
      type: "GridColumnWidget",
      parent: "gridListRRI",
      Label: "Lic #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLicenseNum_PROPERTIES

      // END_USER_CODE-USER_colLicenseNum_PROPERTIES
    },
    colLoanNum: {
      name: "colLoanNum",
      type: "GridColumnWidget",
      parent: "gridListRRI",
      Label: "Loan #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoanNum_PROPERTIES

      // END_USER_CODE-USER_colLoanNum_PROPERTIES
    },
    colLoanStatus: {
      name: "colLoanStatus",
      type: "GridColumnWidget",
      parent: "gridListRRI",
      Label: "Loan Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoanStatus_PROPERTIES

      // END_USER_CODE-USER_colLoanStatus_PROPERTIES
    },
    colPnutType: {
      name: "colPnutType",
      type: "GridColumnWidget",
      parent: "gridListRRI",
      Label: "Pnut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutType_PROPERTIES

      // END_USER_CODE-USER_colPnutType_PROPERTIES
    },
    colRcptNum: {
      name: "colRcptNum",
      type: "GridColumnWidget",
      parent: "gridListRRI",
      Label: "Rcpt #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRcptNum_PROPERTIES

      // END_USER_CODE-USER_colRcptNum_PROPERTIES
    },
    colRcptStatus: {
      name: "colRcptStatus",
      type: "GridColumnWidget",
      parent: "gridListRRI",
      Label: "Rcpt Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRcptStatus_PROPERTIES

      // END_USER_CODE-USER_colRcptStatus_PROPERTIES
    },
    colRRIStatus: {
      name: "colRRIStatus",
      type: "GridColumnWidget",
      parent: "gridListRRI",
      Label: "RRI Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRRIStatus_PROPERTIES

      // END_USER_CODE-USER_colRRIStatus_PROPERTIES
    },
    colWhseCode: {
      name: "colWhseCode",
      type: "GridColumnWidget",
      parent: "gridListRRI",
      Label: "Whse Code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhseCode_PROPERTIES

      // END_USER_CODE-USER_colWhseCode_PROPERTIES
    },
    ddCCCLoanStatus: {
      name: "ddCCCLoanStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "CCC Loan Status:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCCCLoanStatus_PROPERTIES

      // END_USER_CODE-USER_ddCCCLoanStatus_PROPERTIES
    },
    ddEWRPeanutType: {
      name: "ddEWRPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "EWR Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEWRPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddEWRPeanutType_PROPERTIES
    },
    ddEWRReceiptStatus: {
      name: "ddEWRReceiptStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "EWR Receipt Status:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEWRReceiptStatus_PROPERTIES

      // END_USER_CODE-USER_ddEWRReceiptStatus_PROPERTIES
    },
    gridListRRI: {
      name: "gridListRRI",
      type: "GridWidget",
      parent: "grpbxEWRListRRI",
      gridCellsOrder:
        "txtcolFileNum,txtcolRRIStatus,txtcolWhseCode,txtcolRcptNum,txtcolLicenseNum,txtcolFarmNum,txtcolPnutType,txtcolBPNum,txtcolLoanNum,txtcolRcptStatus,txtcolLoanStatus,txtcolAddUser,txtcolAddDate",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridListRRI_PROPERTIES

      // END_USER_CODE-USER_gridListRRI_PROPERTIES
    },
    grpbxDetails: {
      name: "grpbxDetails",
      type: "GroupBoxWidget",
      parent: "grpbxEWRListRRI",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails_PROPERTIES
    },
    txtcolAddDate: {
      name: "txtcolAddDate",
      type: "TextBoxWidget",
      colName: "colAddDate",
      parent: "gridListRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAddDate_PROPERTIES

      // END_USER_CODE-USER_txtcolAddDate_PROPERTIES
    },
    txtcolAddUser: {
      name: "txtcolAddUser",
      type: "TextBoxWidget",
      colName: "colAddUser",
      parent: "gridListRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAddUser_PROPERTIES

      // END_USER_CODE-USER_txtcolAddUser_PROPERTIES
    },
    txtcolBPNum: {
      name: "txtcolBPNum",
      type: "TextBoxWidget",
      colName: "colBPNum",
      parent: "gridListRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBPNum_PROPERTIES

      // END_USER_CODE-USER_txtcolBPNum_PROPERTIES
    },
    txtcolFarmNum: {
      name: "txtcolFarmNum",
      type: "TextBoxWidget",
      colName: "colFarmNum",
      parent: "gridListRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarmNum_PROPERTIES

      // END_USER_CODE-USER_txtcolFarmNum_PROPERTIES
    },
    txtcolFileNum: {
      name: "txtcolFileNum",
      type: "TextBoxWidget",
      colName: "colFileNum",
      parent: "gridListRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFileNum_PROPERTIES

      // END_USER_CODE-USER_txtcolFileNum_PROPERTIES
    },
    txtcolLicenseNum: {
      name: "txtcolLicenseNum",
      type: "TextBoxWidget",
      colName: "colLicenseNum",
      parent: "gridListRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLicenseNum_PROPERTIES

      // END_USER_CODE-USER_txtcolLicenseNum_PROPERTIES
    },
    txtcolLoanNum: {
      name: "txtcolLoanNum",
      type: "TextBoxWidget",
      colName: "colLoanNum",
      parent: "gridListRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoanNum_PROPERTIES

      // END_USER_CODE-USER_txtcolLoanNum_PROPERTIES
    },
    txtcolLoanStatus: {
      name: "txtcolLoanStatus",
      type: "TextBoxWidget",
      colName: "colLoanStatus",
      parent: "gridListRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoanStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolLoanStatus_PROPERTIES
    },
    txtcolPnutType: {
      name: "txtcolPnutType",
      type: "TextBoxWidget",
      colName: "colPnutType",
      parent: "gridListRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutType_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutType_PROPERTIES
    },
    txtcolRcptNum: {
      name: "txtcolRcptNum",
      type: "TextBoxWidget",
      colName: "colRcptNum",
      parent: "gridListRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRcptNum_PROPERTIES

      // END_USER_CODE-USER_txtcolRcptNum_PROPERTIES
    },
    txtcolRcptStatus: {
      name: "txtcolRcptStatus",
      type: "TextBoxWidget",
      colName: "colRcptStatus",
      parent: "gridListRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRcptStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolRcptStatus_PROPERTIES
    },
    txtcolRRIStatus: {
      name: "txtcolRRIStatus",
      type: "TextBoxWidget",
      colName: "colRRIStatus",
      parent: "gridListRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRRIStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolRRIStatus_PROPERTIES
    },
    txtcolWhseCode: {
      name: "txtcolWhseCode",
      type: "TextBoxWidget",
      colName: "colWhseCode",
      parent: "gridListRRI",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWhseCode_PROPERTIES

      // END_USER_CODE-USER_txtcolWhseCode_PROPERTIES
    },
    txtFarmNumber: {
      name: "txtFarmNumber",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Farm Number:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmNumber_PROPERTIES

      // END_USER_CODE-USER_txtFarmNumber_PROPERTIES
    },
    txtFedBPNumber: {
      name: "txtFedBPNumber",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Fed. BP Number:",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFedBPNumber_PROPERTIES

      // END_USER_CODE-USER_txtFedBPNumber_PROPERTIES
    },
    txtFileNumber: {
      name: "txtFileNumber",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "File Number:",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFileNumber_PROPERTIES

      // END_USER_CODE-USER_txtFileNumber_PROPERTIES
    },
    txtLicenseNumber: {
      name: "txtLicenseNumber",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "License Number:",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLicenseNumber_PROPERTIES

      // END_USER_CODE-USER_txtLicenseNumber_PROPERTIES
    },
    txtLoanNumber: {
      name: "txtLoanNumber",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Loan Number:",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanNumber_PROPERTIES

      // END_USER_CODE-USER_txtLoanNumber_PROPERTIES
    },
    txtReceiptNumber: {
      name: "txtReceiptNumber",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Receipt Number:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReceiptNumber_PROPERTIES

      // END_USER_CODE-USER_txtReceiptNumber_PROPERTIES
    },
    txtWarehouseCode: {
      name: "txtWarehouseCode",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Warehouse Code:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWarehouseCode_PROPERTIES

      // END_USER_CODE-USER_txtWarehouseCode_PROPERTIES
    },
    grpbxEWRListRRI: {
      name: "grpbxEWRListRRI",
      type: "GroupBoxWidget",
      parent: "EWRListRRI",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxEWRListRRI_PROPERTIES

      // END_USER_CODE-USER_grpbxEWRListRRI_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "EWRListRRI",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
  };
  const [loading, setLoading] = useState(true);
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    setTabIndex();
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    setValue(thisObj, "ddEWRPeanutType", ''),
    setValue(thisObj, "ddEWRReceiptStatus", ''),
    setValue(thisObj, "ddCCCLoanStatus", ''),
    FormLoad()
  }, []);
  function FormLoad() {
    setLoading(true)
     let receiptnumber=getData(thisObj,"txtReceiptNumber");
     let warehouseCode=getData(thisObj,"txtWarehouseCode");
     if(receiptnumber!=undefined)
     setValue(thisObj,"txtReceiptNumber",receiptnumber);
     if(warehouseCode!=undefined)
     setValue(thisObj,"txtWarehouseCode",warehouseCode)
    bFillPeanutType();
    bFillReceiptStatus();
    bFillCCCLoanStatus()
    setLoading(false)
  }
  //Peanut Type Dropdown
  const bFillPeanutType = () => {
    let js = []
    js.push({ key: '', description: '' })
    js.push({ key: 'RUN', description: 'Runner' })
    js.push({ key: 'SPE', description: 'Spanish Southeast' })
    js.push({ key: 'SPW', description: 'Spanish Southwest' })
    js.push({ key: 'VAL', description: 'Valencia' })
    js.push({ key: 'VIR', description: 'Virginia' })
    thisObj.setState(current => {
      return {
        ...current,
        ddEWRPeanutType: {
          ...state["ddEWRPeanutType"],
          valueList: js
        }
      }
    })
  }
  //Receipt Status Dropdown
  const bFillReceiptStatus = () => {
    let js = []
    js.push({ key: '', description: '' })
    js.push({ key: 'C', description: 'Cancelled' })
    js.push({ key: 'O', description: 'Open' })
    js.push({ key: 'P', description: 'Paper' })
    js.push({ key: 'V', description: 'Void' })
    thisObj.setState(current => {
      return {
        ...current,
        ddEWRReceiptStatus: {
          ...state["ddEWRReceiptStatus"],
          valueList: js
        }
      }
    })
  }
  // Loan Status Dropdown
  const bFillCCCLoanStatus = () => {
    let js = []
    js.push({ key: '', description: '' })
    js.push({ key: 'A', description: 'Approved' })
    js.push({ key: 'P', description: 'Pending' })
    js.push({ key: 'R', description: 'Rejected' })
    js.push({ key: 'E', description: 'Released' })
    thisObj.setState(current => {
      return {
        ...current,
        ddCCCLoanStatus: {
          ...state["ddCCCLoanStatus"],
          valueList: js
        }
      }
    })
  }
  //Close button
  const onbtnCloseClick = () => {
    try {
      document.getElementById("WarehouseReceipts_EWRListRRIPopUp").childNodes[0].click()
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  //Close Button
  //Search button
  const onbtnSearchClick = async() => {
    try {
      setLoading(true);
      let func_id = 'PN9010L'
      let func_sub_id = null
      let  file_num = getValue(thisObj, "txtFileNumber", '')
      let  whse_code = getValue(thisObj, "txtWarehouseCode", '')
      let  receipt_num = getValue(thisObj, "txtReceiptNumber", '')
      let  license_num = getValue(thisObj, "txtLicenseNumber", '')
      let  fsa_farm_num = getValue(thisObj, "txtFarmNumber", '')
      let  pnut_type = getValue(thisObj, "ddEWRPeanutType", '')
      let  buying_pt_num = getValue(thisObj, "txtFedBPNumber", '')
      let  loan_num = getValue(thisObj, "txtLoanNumber", '')
      let  receipt_status = getValue(thisObj, "ddEWRReceiptStatus", '')
      let  ccc_loan_status = getValue(thisObj, "ddCCCLoanStatus", '')
      let data = null
      let obj = {}
      let inqarr = []
      let response = await WarehouseReceiptService.RetrieveEWareHouseInquirySendDetails(file_num, whse_code, receipt_num, license_num, fsa_farm_num, pnut_type, buying_pt_num, loan_num, receipt_status, ccc_loan_status)
      if (response != undefined && response.length > 0) {
        data = response
      }
      else{
        setValue(thisObj,"gridListRRI",[])
        setLoading(false)
        return 
      }
      for (var i=0;i<data.length;i++)
      {
        obj.txtcolFileNum = data[i].transaction_num
        obj.txtcolRRIStatus = data[i].ewr_transmission_status
        obj.txtcolWhseCode = data[i].whse_code
        obj.txtcolRcptNum = data[i].receipt_num
        obj.txtcolLicenseNum = data[i].license_num
        obj.txtcolFarmNum = data[i].fsa_farm_num
        obj.txtcolPnutType = data[i].pnut_type
        obj.txtcolBPNum = data[i].buying_pt_num
        obj.txtcolLoanNum = data[i].loan_num
        obj.txtcolRcptStatus = data[i].receipt_status
        obj.txtcolLoanStatus = data[i].ccc_loan_status
        obj.txtcolAddUser = data[i].add_user
        obj.txtcolAddDate = moment(data[i].add_date_time).format("MM/DD/YYYY");
        inqarr.push(obj)
        obj = {}
      }
      setValue(thisObj,'gridListRRI',inqarr)
      setLoading(false);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
        )
      }
       setLoading(false);
      return false
    }
    return true
  };
  thisObj.onbtnSearchClick = onbtnSearchClick;
  //Search button
  // View Button
  const onbtnViewClick = async () => {
    try{
    let file_num = ""
    let ewr_transmission_status = ""
    let data = thisObj.state.gridListRRI.selected[0];
    if (data !== undefined && data !== null && data !== "") {
      if (data.txtcolRRIStatus != "A") {
        let response = await WarehouseReceiptService.RetrieveEWareHouseInquirySendDetails(file_num, null, null, null, null, null, null, null, null, null)
        if (response != undefined && response.length > 0) {
          data = response
          if (ewr_transmission_status != "A") {
            showMessage(thisObj, "Request cannot be viewed until response is returned by EWR")
          }
          else {
            showMessage(thisObj, "Request cannot be viewed until response is returned by EWR")
          }
        }
      }
      else {
        setData(thisObj, "gridListRRI", data);
        setData(thisObj,"EWRViewRRIFormLoadData", data.txtcolFileNum)
        goTo(thisObj, "WarehouseReceipts#EWRViewRRI#DEFAULT#true#Click")
      }
    }
    else {
      showMessage(thisObj, "A row must be selected to view")
    }
  }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnView: Click"
        )
      }
      return false
    }
  }
  thisObj.onbtnViewClick = onbtnViewClick;

  const onGridRowChange = (rowSelected) => {
    let data3 = rowSelected;
    if (data3.selectedRows) {
      data3 = data3.selectedRows[0];
      enable(thisObj, 'btnView')
    }
  };
  thisObj.onGridRowChange = onGridRowChange;
// Blur Event on Licence Nunber and Loan NUmber TextBoxes
  const ontxtLicenseNumberBlur = () => {
    let licenceNumber = getValue(thisObj, 'txtLicenseNumber') || '';
    licenceNumber = licenceNumber.replace(/-/g, '');
    setValue(thisObj, 'txtLicenseNumber', licenceNumber);
  }
  thisObj.ontxtLicenseNumberBlur = ontxtLicenseNumberBlur;

  const ontxtLoanNumberBlur = () => {
    let loanNumber = getValue(thisObj, 'txtLoanNumber') || '';
    loanNumber = loanNumber.replace(/-/g, '');
    loanNumber = loanNumber.replace(/\s/g, '');
    setValue(thisObj, 'txtLoanNumber', loanNumber);
  }
  thisObj.ontxtLoanNumberBlur = ontxtLoanNumberBlur;

  const setTabIndex = () => {
    let elements = [
      'txtFileNumber',
      'txtWarehouseCode',
      'txtReceiptNumber',
      'txtLicenseNumber',
      'txtFarmNumber',
      'ddEWRPeanutType',
      'txtFedBPNumber',
      'txtLoanNumber',
      'ddEWRReceiptStatus',
      'ddCCCLoanStatus',
      'btnSearch',
      'btnView',
      'btnClose'
    ] 
    elements.map((element, index) => {
      let tabElement = document.querySelectorAll(".modal")[1].querySelector(`#${element}`);
      if(tabElement){
        tabElement.tabIndex = index + 1;
      }
    })
  }
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_EWRListRRI*/}

              {/* END_USER_CODE-USER_BEFORE_EWRListRRI*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxEWRListRRI*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxEWRListRRI*/}

              <GroupBoxWidget conf={state.grpbxEWRListRRI} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails*/}

                <GroupBoxWidget conf={state.grpbxDetails} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtFileNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFileNumber*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFileNumber}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFileNumber*/}

                  {/* END_USER_CODE-USER_AFTER_txtFileNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFarmNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFarmNumber*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFarmNumber}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFarmNumber*/}

                  {/* END_USER_CODE-USER_AFTER_txtFarmNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLoanNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLoanNumber*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLoanNumber}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLoanNumber*/}

                  {/* END_USER_CODE-USER_AFTER_txtLoanNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWarehouseCode*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWarehouseCode*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWarehouseCode}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWarehouseCode*/}

                  {/* END_USER_CODE-USER_AFTER_txtWarehouseCode*/}
                  {/* START_USER_CODE-USER_BEFORE_ddEWRPeanutType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddEWRPeanutType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddEWRPeanutType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddEWRPeanutType*/}

                  {/* END_USER_CODE-USER_AFTER_ddEWRPeanutType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddEWRReceiptStatus*/}

                  {/* END_USER_CODE-USER_BEFORE_ddEWRReceiptStatus*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddEWRReceiptStatus}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddEWRReceiptStatus*/}

                  {/* END_USER_CODE-USER_AFTER_ddEWRReceiptStatus*/}
                  {/* START_USER_CODE-USER_BEFORE_txtReceiptNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_txtReceiptNumber*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtReceiptNumber}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtReceiptNumber*/}

                  {/* END_USER_CODE-USER_AFTER_txtReceiptNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFedBPNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFedBPNumber*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFedBPNumber}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFedBPNumber*/}

                  {/* END_USER_CODE-USER_AFTER_txtFedBPNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCCCLoanStatus*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCCCLoanStatus*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCCCLoanStatus}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCCCLoanStatus*/}

                  {/* END_USER_CODE-USER_AFTER_ddCCCLoanStatus*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLicenseNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLicenseNumber*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLicenseNumber}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLicenseNumber*/}

                  {/* END_USER_CODE-USER_AFTER_txtLicenseNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                  {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
                  <CommandContainerWidget
                    conf={state.cmmndCntnrActions}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                    {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                    <ButtonWidget
                      conf={state.btnSearch}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                    {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                    {/* START_USER_CODE-USER_BEFORE_btnView*/}

                    {/* END_USER_CODE-USER_BEFORE_btnView*/}

                    <ButtonWidget
                      conf={state.btnView}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnView*/}

                    {/* END_USER_CODE-USER_AFTER_btnView*/}
                  </CommandContainerWidget>
                  {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

                  {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails*/}
                {/* START_USER_CODE-USER_BEFORE_gridListRRI*/}

                {/* END_USER_CODE-USER_BEFORE_gridListRRI*/}

                <GridWidget
                  conf={state.gridListRRI}
                  screenConf={state}
                  linkClick={event => {
                    if (state.gridListRRI.selected.length > 0) {

                      thisObj.selectedRow = state.gridListRRI.selected[0];
                    }
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridListRRI}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onRowChange={selectedRow => onGridRowChange(selectedRow)}
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridListRRI*/}

                {/* END_USER_CODE-USER_AFTER_gridListRRI*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxEWRListRRI*/}

              {/* END_USER_CODE-USER_AFTER_grpbxEWRListRRI*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_EWRListRRI*/}

              {/* END_USER_CODE-USER_AFTER_EWRListRRI*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_EWRListRRI);
