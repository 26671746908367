/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  setData,getData,
  setValue,getValue
} from "../../shared/WindowImports";

import "./CorrectCheck.scss";
import { AccountPayment } from "../Service/AccountPayment";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function AccountPayment_CorrectCheck(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "CorrectCheck",
    windowName: "CorrectCheck",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "AccountPayment.CorrectCheck",
    // START_USER_CODE-USER_CorrectCheck_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Check Correction",
      scrCode: "PN0010C",
    },
    // END_USER_CODE-USER_CorrectCheck_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    lblOldCheckNumber: {
      name: "lblOldCheckNumber",
      type: "LabelWidget",
      parent: "grpbxCheckNumber",
      Label: "Old Check Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOldCheckNumber_PROPERTIES

      // END_USER_CODE-USER_lblOldCheckNumber_PROPERTIES
    },
    lblOldCheckNumValue: {
      name: "lblOldCheckNumValue",
      type: "LabelWidget",
      parent: "grpbxCheckNumber",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOldCheckNumValue_PROPERTIES

      // END_USER_CODE-USER_lblOldCheckNumValue_PROPERTIES
    },
    txtNewCheckNumber: {
      name: "txtNewCheckNumber",
      type: "TextBoxWidget",
      parent: "grpbxCheckNumber",
      Label: "New Check Number:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNewCheckNumber_PROPERTIES

      // END_USER_CODE-USER_txtNewCheckNumber_PROPERTIES
    },
    grpbxCheckNumber: {
      name: "grpbxCheckNumber",
      type: "GroupBoxWidget",
      parent: "CorrectCheck",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCheckNumber_PROPERTIES

      // END_USER_CODE-USER_grpbxCheckNumber_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "CorrectCheck",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
  
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let existing_check_num= getData(thisObj,"frmCorrectCheck")
    setValue(thisObj,"lblOldCheckNumValue",existing_check_num)
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  
  // START_USER_CODE-USER_METHODS

  const onbtnOkClick = async () => {
    try {
      let txtNewCheckNumber = getValue(thisObj, "txtNewCheckNumber");
      let response = await AccountPayment.RetrieveVerifyChecksUnusedDetails(txtNewCheckNumber)
         //.then(response => {
          let data = response;
          if(data.length!==0 && data!==undefined){
            if(data[0].successful==false){
              showMessage(thisObj,"An error occured while correcting selected check!!!")
              return false;
            }
            else{
              if(data[0].check_num!=="" && data[0].check_num!==undefined){
                showMessage(thisObj,"A check with that number already exists!!!")
                return false;
              }
            }
          }
          // else{
          //   showMessage(thisObj,"An error occured while correcting selected check!!!")
          //   return false;
          // }
        //}
        //)

        let existing_check_num= getData(thisObj,"frmCorrectCheck")
        let datafromgrandparent = getData(thisObj, 'frmreviewcheckReadCheckInfo')
        let buyingPointId = datafromgrandparent.LstrBuyPtID

        let dataObj = {
          user_id: sessionStorage.getItem('userid')
        }
        let response1 = await AccountPayment.UpdateChangeCheckNumberDetails(buyingPointId,existing_check_num,txtNewCheckNumber,dataObj)
          //.then(response => {
           let data1 = response1
           if(data1.length!==0 && data1!==undefined){
            if(data1.status!==200 || data1.status==undefined){
              showMessage(thisObj,"An error occured while correcting selected check!!!")
              return false;
            }
            else{
              setData(thisObj,"dataForReviewCheck",txtNewCheckNumber) //need to show new check number on label of ReviewCheck screen
              setData(thisObj,"dataForCheckInquiry",txtNewCheckNumber) // need to show new check number in selected row of CheckInquiry
              showMessage(thisObj,data1.message,true);
              document.getElementById("AccountPayment_CorrectCheckPopUp").childNodes[0].click()
            }
           }
           else{
            showMessage(thisObj,"An error occured while correcting selected check!!!")
            return false;
           }
          //}
          //)
      
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error on OK button Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnOkClick = onbtnOkClick;

  const onbtnCancelClick = () => {
    try {
      document.getElementById("AccountPayment_CorrectCheckPopUp").childNodes[0].click()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
          
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_CorrectCheck*/}

              {/* END_USER_CODE-USER_BEFORE_CorrectCheck*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCheckNumber*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCheckNumber*/}

              <GroupBoxWidget conf={state.grpbxCheckNumber} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblOldCheckNumber*/}

                {/* END_USER_CODE-USER_BEFORE_lblOldCheckNumber*/}

                <LabelWidget
                  values={values}
                  conf={state.lblOldCheckNumber}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblOldCheckNumber*/}

                {/* END_USER_CODE-USER_AFTER_lblOldCheckNumber*/}
                {/* START_USER_CODE-USER_BEFORE_lblOldCheckNumValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblOldCheckNumValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblOldCheckNumValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblOldCheckNumValue*/}

                {/* END_USER_CODE-USER_AFTER_lblOldCheckNumValue*/}
                {/* START_USER_CODE-USER_BEFORE_txtNewCheckNumber*/}

                {/* END_USER_CODE-USER_BEFORE_txtNewCheckNumber*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNewCheckNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNewCheckNumber*/}

                {/* END_USER_CODE-USER_AFTER_txtNewCheckNumber*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCheckNumber*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCheckNumber*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_CorrectCheck*/}

              {/* END_USER_CODE-USER_AFTER_CorrectCheck*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(AccountPayment_CorrectCheck);
