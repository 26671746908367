/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_BankHolidayProfile from "./BankHolidayProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("BankHolidayProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_BankHolidayProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is BankHolidayProfile Loads Successfully", () => {
    expect(screen.getByText("BankHolidayProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for BankHolidayProfile", () => {
    // START_USER_CODE-USER_BankHolidayProfile_Custom_Test_Case
    // END_USER_CODE-USER_BankHolidayProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_BankHolidayProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:BankHolidayProfile_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:BankHolidayProfile_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("dpHolidayDate(Date Widget) Test Cases", async () => {
    const dpHolidayDate = screen.getByTestId("dpHolidayDate");
    expect(dpHolidayDate.tagName).toBe("INPUT");
    expect(dpHolidayDate.type).toBe("text");
    expect(dpHolidayDate.classList).toContain("datetimepicker-input");
    expect(
      dpHolidayDate.parentElement.previousElementSibling.textContent
    ).toEqual(
      t("SystemMaintenanceSpecialFunctions:BankHolidayProfile_dpHolidayDate")
    );
    await act(async () => {
      userEvent.click(dpHolidayDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dpHolidayDate", () => {
    // START_USER_CODE-USER_dpHolidayDate_TEST
    // END_USER_CODE-USER_dpHolidayDate_TEST
  });
  test("grpbxBankHolidayProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxBankHolidayProfile = screen.getByTestId(
      "grpbxBankHolidayProfile"
    );
    expect(grpbxBankHolidayProfile.tagName).toBe("BUTTON");
    expect(grpbxBankHolidayProfile.type).toBe("button");
    expect(grpbxBankHolidayProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBankHolidayProfile", () => {
    // START_USER_CODE-USER_grpbxBankHolidayProfile_TEST
    // END_USER_CODE-USER_grpbxBankHolidayProfile_TEST
  });
  test("txtHolidayName(Textbox Widget) Test Cases", async () => {
    const txtHolidayName = screen.getByTestId("txtHolidayName");
    expect(txtHolidayName.tagName).toBe("INPUT");
    expect(txtHolidayName.type).toBe("text");
    expect(txtHolidayName.classList).toContain("textboxWidgetClass");
    expect(txtHolidayName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:BankHolidayProfile_txtHolidayName")
    );
    await act(async () => {
      txtHolidayName.focus();
      txtHolidayName.blur();
    });
    expect(
      screen.getAllByText(t("AppJSMessage:VALIDATIONMSG_REQUIRED"))
        .toBeInTheDocument
    );
    expect(txtHolidayName.classList).toContain("requiredField");
    await act(async () => {
      userEvent.type(txtHolidayName, "1");
    });
  });
  test("Custom Test Cases for txtHolidayName", () => {
    // START_USER_CODE-USER_txtHolidayName_TEST
    // END_USER_CODE-USER_txtHolidayName_TEST
  });
});
