/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  TextAreaWidget,
  setValue,
  getValue,
  setData,
  getData,
  enable,
  disable,
  goTo
} from "../../shared/WindowImports";

import "./SpecialPayablesScan.scss";

import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { useDropzone } from "react-dropzone";
import ModalPopUp from "react-bootstrap/Modal";
import { AccountPayment } from "../Service/AccountPayment";
import StripPic from "../../../../assets/img/PinStrip.png";
//import { getData } from "../../Common/ApiService";
// END_USER_CODE-USER_IMPORTS
function AccountPayment_SpecialPayablesScan(props) {
  const contextType = useAppContext();
  const screenRef = useRef();

  // START_USER_CODE-USER_PROPERTIES
  const selectionMode = "Selection"
  const scanningMode = "Scanning"
  const verifyMode = "Verifying"
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  let userid = useridFromLS();
  let cropYear = cropYearFromLS();
  let comp_id = compIdFromLS();
  const [isShow, invokeModal] = React.useState(false);
  const [files, setFiles] = useState([]);
  let buy_pt_id = ""
  let cont_num = ""
  let func_id = ""
  let mbMode = selectionMode;
  let timeout = null;
  let dialogOpen;
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SpecialPayablesScan",
    windowName: "SpecialPayablesScan",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "AccountPayment.SpecialPayablesScan",
    // START_USER_CODE-USER_SpecialPayablesScan_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Scan Special Payable Documents",
      scrCode: "PN1100J",
    },
    // END_USER_CODE-USER_SpecialPayablesScan_PROPERTIES


    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxVerificationResult",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnCancelSave: {
      name: "btnCancelSave",
      type: "ButtonWidget",
      parent: "grpbxSavingDocumentOptions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancelSave_PROPERTIES

      // END_USER_CODE-USER_btnCancelSave_PROPERTIES
    },

    btnSaveFromExistingFile: {
      name: "btnSaveFromExistingFile",
      type: "ButtonWidget",
      parent: "grpbxSavingDocumentOptions",
      Label: "Save from Existing File",
      CharWidth: "46",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSaveFromExistingFile_PROPERTIES

      // END_USER_CODE-USER_btnSaveFromExistingFile_PROPERTIES
    },



    buttonwidget5: {
      name: "buttonwidget5",
      type: "ButtonWidget",
      parent: "grpbxVerificationResult",
      Label: "Verify and Save",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_buttonwidget5_PROPERTIES

      // END_USER_CODE-USER_buttonwidget5_PROPERTIES
    },
    lblMemo: {
      name: "lblMemo",
      type: "LabelWidget",
      parent: "grpbxDocumentDetails",
      Label: "Memo:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMemo_PROPERTIES

      // END_USER_CODE-USER_lblMemo_PROPERTIES
    },
    lblMemoValue: {
      name: "lblMemoValue",
      type: "LabelWidget",
      parent: "grpbxDocumentDetails",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMemoValue_PROPERTIES

      // END_USER_CODE-USER_lblMemoValue_PROPERTIES
    },
    lblSavingDocumentOptions: {
      name: "lblSavingDocumentOptions",
      type: "LabelWidget",
      parent: "grpbxSavingDocumentOptions",
      Label: "Saving Document Options:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSavingDocumentOptions_PROPERTIES

      // END_USER_CODE-USER_lblSavingDocumentOptions_PROPERTIES
    },



    lblSpecialPayDocInfo: {
      name: "lblSpecialPayDocInfo",
      type: "LabelWidget",
      parent: "grpbxDocumentDetails",
      Label: "Special Pay Document Information:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpecialPayDocInfo_PROPERTIES

      // END_USER_CODE-USER_lblSpecialPayDocInfo_PROPERTIES
    },
    lblSpoolLocation: {
      name: "lblSpoolLocation",
      type: "LabelWidget",
      parent: "grpbxDocumentDetails",
      Label: "Spool Location:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpoolLocation_PROPERTIES

      // END_USER_CODE-USER_lblSpoolLocation_PROPERTIES
    },
    lblSpoolLocationValue: {
      name: "lblSpoolLocationValue",
      type: "LabelWidget",
      parent: "grpbxDocumentDetails",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpoolLocationValue_PROPERTIES

      // END_USER_CODE-USER_lblSpoolLocationValue_PROPERTIES
    },
    lblVendor: {
      name: "lblVendor",
      type: "LabelWidget",
      parent: "grpbxDocumentDetails",
      Label: "Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor_PROPERTIES

      // END_USER_CODE-USER_lblVendor_PROPERTIES
    },
    lblVendorValue: {
      name: "lblVendorValue",
      type: "LabelWidget",
      parent: "grpbxDocumentDetails",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendorValue_PROPERTIES

      // END_USER_CODE-USER_lblVendorValue_PROPERTIES
    },
    lblVerifictnResult: {
      name: "lblVerifictnResult",
      type: "LabelWidget",
      parent: "grpbxVerificationResult",
      // Label: "Verification Result:",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVerifictnResult_PROPERTIES

      // END_USER_CODE-USER_lblVerifictnResult_PROPERTIES
    },
    // lblVerifictnResultVal: {
    //   name: "lblVerifictnResultVal",
    //   type: "LabelWidget",
    //   parent: "grpbxVerificationResult",
    //   Label: "",
    //   ColSpan: "5",
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_lblVerifictnResultVal_PROPERTIES

    //   // END_USER_CODE-USER_lblVerifictnResultVal_PROPERTIES
    // },
    txtarVerificationResult: {
      name: "txtarVerificationResult",
      type: "TextAreaWidget",
      parent: "grpbxVerificationResult",
      Label: "Verification Result:",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarVerificationResult_PROPERTIES

      // END_USER_CODE-USER_txtarVerificationResult_PROPERTIES
    },
    txtDocument: {
      name: "txtDocument",
      type: "TextBoxWidget",
      parent: "grpbxDocumentDetails",
      Label: "Document:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDocument_PROPERTIES

      // END_USER_CODE-USER_txtDocument_PROPERTIES
    },
    grpbxDocumentDetails: {
      name: "grpbxDocumentDetails",
      type: "GroupBoxWidget",
      parent: "SpecialPayablesScan",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDocumentDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxDocumentDetails_PROPERTIES
    },
    grpbxSavingDocumentOptions: {
      name: "grpbxSavingDocumentOptions",
      type: "GroupBoxWidget",
      parent: "SpecialPayablesScan",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSavingDocumentOptions_PROPERTIES

      // END_USER_CODE-USER_grpbxSavingDocumentOptions_PROPERTIES
    },
    grpbxScanningOptions: {
      name: "grpbxScanningOptions",
      type: "GroupBoxWidget",
      parent: "SpecialPayablesScan",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxScanningOptions_PROPERTIES

      // END_USER_CODE-USER_grpbxScanningOptions_PROPERTIES
    },
    grpbxVerificationResult: {
      name: "grpbxVerificationResult",
      type: "GroupBoxWidget",
      parent: "SpecialPayablesScan",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVerificationResult_PROPERTIES

      // END_USER_CODE-USER_grpbxVerificationResult_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},
      },
      REVERSE: {
        "ContractManagement#MainMenu": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
  };
  let SpoolLocation = "";
  let vendor = "";
  let memoNum = "";
  let mLoc = "";
  let mnFTPTimerInterval = 0;
  let mnFTPRetryLimit = 0;
  let msDocNum = "";
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    Formload();
    EnableDisableControls();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  function Formload() {
    let data = getData(thisObj, "ScaneDocument");
    if (data !== null || data !== undefined) {
      SpoolLocation = data.cboSpoolLocation;
      vendor = data.vendor;
      memoNum = data.memoNumber;
      setValue(thisObj, "lblSpoolLocationValue", SpoolLocation);
      setValue(thisObj, "lblVendorValue", vendor);
      setValue(thisObj, "lblMemoValue", memoNum);
    }
    disable(thisObj, "btnSaveFromExistingFile");
    disable(thisObj, "txtarVerificationResult");
    disable(thisObj, "buttonwidget5");
  }

  // Start method for Document Number change event
  const ontxtDocumentChange = async () => {
    try {
      let documentNum = thisObj.values.txtDocument;
      if (documentNum == undefined || documentNum == "" || documentNum == null) {
        disable(thisObj, "btnSaveFromExistingFile");
      }
      else {
        enable(thisObj, "btnSaveFromExistingFile");
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:txtDocument event:Change"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtDocumentChange = ontxtDocumentChange;
  // End method for Document Number change event

  
  // Start method for Upload Special payable document
  function UploadSpecialPayableDoc(fileName) {
    try {
      if (fileName == '') {
        showMessage(thisObj, "FileName is empty")
        return false;
      } else if (fileName.split('.')[1] !== 'pdf') {
        showMessage(thisObj, "File is not pdf format")
        return false;
      }
      setValue(thisObj, "txtarVerificationResult", fileName + " loaded. Select Verify and Save to verify. Cancel to exit scanning.")
      invokeModal(false)
      return true;
    }
    catch (error) {
      setValue(thisObj, "txtarVerificationResult", "Please try to upload the file again.")
      invokeModal(false)
      return false;
    }
  }
  // End method for Upload Special payable document

  // Start method of FTP Document and called in verifySpecialPayable method
  async function FTPDocument(file) {
    try{
      let FTP_Method = "";
      let sUBuyPtId = "";
      ContractManagementService.RetrieveUserControlDetails(userid).then(response => {
        sUBuyPtId = response[0].buyPtId;
        ContractManagementService.RetrieveBuyingPointControlDetails('PN1060', null, null, sUBuyPtId, null).then(response => {
          let collectionData = response;
          for (let i = 0; i < collectionData.length; i++) {
            mLoc = collectionData[i].buy_pt_id;
            mnFTPTimerInterval = collectionData[i].ftp_timer_interval;
            mnFTPRetryLimit = collectionData[i].ftp_retry_limit;
            FTP_Method = collectionData[i].ftp_method;
          }
        });
      });
  
      let fileNameToProcess = "SPAY" + msDocNum + ".pdf";
      let environmentType = 'PPSTest';
      if (process.env.REACT_APP_ENVIR == 'PROD') {
        environmentType = 'PPSProd';
      }
      const formData = new FormData();
      formData.append('FormFile', file[0]);
      formData.append('filename', fileNameToProcess);
      formData.append('environment', environmentType);
      formData.append('folderType', 'specialPay');
  
      let response = await ContractManagementService.UploadFtpFile(formData)
      if (response != undefined && response != null) {
        if (response.message == 'File transfer successfully') {
          let dataobj = {
            ftp_file_name:fileNameToProcess
          }
          AccountPayment.UpdateSpecialPayDocumentScanList(msDocNum,dataobj).then(response1 =>{
            if(response1.status == 200){
              //alert("File " + file[0].name + " successfully transferred as " + fileNameToProcess);
            }
          })

          setValue(thisObj, "txtarVerificationResult", "File " + file[0].name + " successfully transferred as " + fileNameToProcess);
          enable(thisObj, "btnSaveFromExistingFile");
          disable(thisObj, "buttonwidget5");        
          let scanObj = {
            method: FTP_Method == 'S',
            uploadstatus: '',
            filname: fileNameToProcess
          }
          setData(thisObj, 'SpecialPayableScan', scanObj);
  
          alert("File " + file[0].name + " successfully transferred as " + fileNameToProcess);
          goTo(thisObj, "AccountPayment#SpecialPayablesProfile#DEFAULT#ture#Click");
        }
        else if (response.message != 'File transfer successfully') {
          setValue(thisObj, "txtarVerificationResult", "Problem with saving (FTP) the Contract Pricing. You can retry or upload the file later");
          showMessage(thisObj, "Problem with saving (FTP) the Contract Pricing. You can retry or upload the file later");         
          
          disable(thisObj, "buttonwidget5")
          enable(thisObj, "btnSaveFromExistingFile")
          enable(thisObj, "onbtnCnclClick")

          AccountPayment.UpdateSpecialPayUploadDocument(msDocNum).then(response2 =>{
            if(response2.status == 200){
              //invokeModal(true);
            }
          })
        }  
        setValue(thisObj, "txtarVerificationResult", response.message);      
      }
      else {
        setValue(thisObj, "txtarVerificationResult", "The PDF file is of a lower version and not supported. To re-create the file in higher version, Open the File > Go to File > Print > Select Printer Microsoft Print to PDF > Save this version somewhere. Upload the new saved contract. Please contact your system administrator if the problem persist.")
        showMessage(thisObj, "The PDF file is of a lower version and not supported. To re-create the file in higher version, Open the File > Go to File > Print > Select Printer Microsoft Print to PDF > Save this version somewhere. Upload the new saved contract. Please contact your system administrator if the problem persist.")
      }
      
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:FTPDocument event:Click");
      }
      return false;
    }
    return true;
  }
  // End method of FTP Document and called in verifySpecialPayable method

  // Start method to Verify Special Payable document
  function VerifySpecialPayable(file) {
    try {
      FTPDocument(file)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:VerifySpecialPayable event:Click");
      }
      return false;
    }
    return true;
  }
  // End method to Verify Special Payable document

//Start method to enable disable controls
  function EnableDisableControls() {
    if (mbMode == selectionMode) {
      disable(thisObj, "btnCancelSave")
      disable(thisObj, "buttonwidget5")
      setValue(thisObj, "txtarVerificationResult", "")
    }
    else if (mbMode == scanningMode) {
      disable(thisObj, "btnSaveFromExistingFile")
      disable(thisObj, "btnCancelSave")
      setValue(thisObj, "txtarVerificationResult", "")
    }
    else if (mbMode == verifyMode) {
      disable(thisObj, "btnSaveFromExistingFile")
      disable(thisObj, "btnCancelSave")
      disable(thisObj, "btnScanDocFeeder")
      setValue(thisObj, "txtarVerificationResult", "Review the scanned document.  Click Verify and Save to save the document.  Click Cancel to return to Saving Options")
    }
    else {
      showMessage(thisObj, "Invalid selection. ")
    }
  }
//End method to enable disable controls

  // Start method for getting popup and Drag-n Drop the files
  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )

      if (acceptedFiles != undefined && acceptedFiles != null) {
        if (acceptedFiles[0].name.split('.')[1] != 'pdf') {
          invokeModal(false);
          disable(thisObj, "buttonwidget5")
          enable(thisObj, "btnSaveFromExistingFile")
          disable(thisObj, "btnCancelSave")
          setValue(thisObj, "txtarVerificationResult", "Please select only pdf files.")
          return;
        }
        invokeModal(false);
      }
      state.currentFile = acceptedFiles
      let promptresponse = window.confirm("Do you want to preview the document " + acceptedFiles[0].name + " before uploading ?");
      if(promptresponse==true){
        window.open(acceptedFiles[0].preview)
      }
      setData(thisObj, "currentFile", acceptedFiles)
      setValue(thisObj, "currentFile", acceptedFiles)
      var res = UploadSpecialPayableDoc(acceptedFiles[0].name)
      if (res) {
        disable(thisObj, "btnSaveFromExistingFile")
        enable(thisObj, "buttonwidget5")
        enable(thisObj, "btnCancelSave")
      }
      else {
        invokeModal(false);
        enable(thisObj, "btnSaveFromExistingFile")
        disable(thisObj, "buttonwidget5")
        disable(thisObj, "btnCancelSave")
      }
    }
  })
  // End method for getting popup and Drag-n Drop the files

  // Start method for Saving Option cancel button
  const onbtnCancelSaveClick = () => {
    try {
      enable(thisObj, "btnSaveFromExistingFile");
      enable(thisObj, "txtDocument");      
      disable(thisObj, "buttonwidget5");
      setValue(thisObj, "txtarVerificationResult", "");      
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbtnCancelSaveClick event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelSaveClick = onbtnCancelSaveClick;
  // End method for Saving Option cancel button 

  // Start method for Save Existing File button 
  const onbtnSaveFromExistingFileClick = () => {
    try {
      let docName = thisObj.values.txtDocument;
      let memo_num = thisObj.values.lblMemoValue;
      let doc_name = "";
      AccountPayment.RetrieveSpecialPayDocumentDetails(memo_num).then(response => {
        let data = response
        if( data.length !== 0){
          for (var i = 0; i < data.length; i++) {
            doc_name = data[i].document_name;
            if (docName == doc_name) {
              let message = confirm("A Document has already been saved to this Special Pay record with this Title!" + "Do you want to overwrite the existing Document?");
              if (message == true) {
                msDocNum = response[i].document_num;
                AccountPayment.UpdateSpecialPayUploadDocument(msDocNum).then(response =>{
                  if(response.status == 200){
                    invokeModal(true);
                  }
                })
              }
              else {
                alert("Change the Document Title and retry!");
                enable(thisObj, "txtDocument");
                invokeModal(false);
                return false;
              }
            } 
            else{
              disable(thisObj, "txtDocument");
              invokeModal(true);
            }            
          }
        }
        else {
          disable(thisObj, "txtDocument");
          invokeModal(true);
        }                      
      });      
      thisObj.setState((current) => {
        return {
          ...current,
          dropzoneFlag: !thisObj.state.dropzoneFlag
        }
      })

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnSaveFromExistingFile event:Click");
      }
      return false;
    }
  };
  thisObj.onbtnSaveFromExistingFileClick = onbtnSaveFromExistingFileClick;
  // End method for Save Existing File button 

  // Start method for Verification result section cancel button
  const onbtnCancelClick = () => {
    try {
      goTo(thisObj, "AccountPayment#SpecialPayablesProfile#DEFAULT#false#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbtnCnclClick event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // End method for Verification result section cancel button

  // Start method for Verification result section Verify and save button
  const onbuttonwidget5Click = async () => {
    try {
      let dataobj = {
        comp_id: comp_id,
        crop_year: cropYear,
        memo_num: thisObj.values.lblMemoValue,
        document_name: thisObj.values.txtDocument,
        uid: userid
      }
      AccountPayment.UpdateSpecialPayDocumentDetails(null,dataobj).then(response => {
        let data = response.result;
        msDocNum = data;
        VerifySpecialPayable(getData(thisObj, "currentFile"));
      });

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbuttonwidget5Click event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbuttonwidget5Click = onbuttonwidget5Click
  // End method for Verification result section Verify and save button



  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SpecialPayablesScan*/}

              {/* END_USER_CODE-USER_BEFORE_SpecialPayablesScan*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxDocumentDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxDocumentDetails*/}

              <GroupBoxWidget
                conf={state.grpbxDocumentDetails}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblSpecialPayDocInfo*/}

                {/* END_USER_CODE-USER_BEFORE_lblSpecialPayDocInfo*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSpecialPayDocInfo}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSpecialPayDocInfo*/}

                {/* END_USER_CODE-USER_AFTER_lblSpecialPayDocInfo*/}
                {/* START_USER_CODE-USER_BEFORE_lblSpoolLocation*/}

                {/* END_USER_CODE-USER_BEFORE_lblSpoolLocation*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSpoolLocation}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSpoolLocation*/}

                {/* END_USER_CODE-USER_AFTER_lblSpoolLocation*/}
                {/* START_USER_CODE-USER_BEFORE_lblSpoolLocationValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblSpoolLocationValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSpoolLocationValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSpoolLocationValue*/}

                {/* END_USER_CODE-USER_AFTER_lblSpoolLocationValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblVendor*/}

                {/* END_USER_CODE-USER_BEFORE_lblVendor*/}

                <LabelWidget
                  values={values}
                  conf={state.lblVendor}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblVendor*/}

                {/* END_USER_CODE-USER_AFTER_lblVendor*/}
                {/* START_USER_CODE-USER_BEFORE_lblVendorValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblVendorValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblVendorValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblVendorValue*/}

                {/* END_USER_CODE-USER_AFTER_lblVendorValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblMemo*/}

                {/* END_USER_CODE-USER_BEFORE_lblMemo*/}

                <LabelWidget
                  values={values}
                  conf={state.lblMemo}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblMemo*/}

                {/* END_USER_CODE-USER_AFTER_lblMemo*/}
                {/* START_USER_CODE-USER_BEFORE_lblMemoValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblMemoValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblMemoValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblMemoValue*/}

                {/* END_USER_CODE-USER_AFTER_lblMemoValue*/}
                {/* START_USER_CODE-USER_BEFORE_txtDocument*/}

                {/* END_USER_CODE-USER_BEFORE_txtDocument*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDocument}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDocument*/}

                {/* END_USER_CODE-USER_AFTER_txtDocument*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxDocumentDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxDocumentDetails*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxSavingDocumentOptions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSavingDocumentOptions*/}

              <GroupBoxWidget
                conf={state.grpbxSavingDocumentOptions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblSavingDocumentOptions*/}

                {/* END_USER_CODE-USER_BEFORE_lblSavingDocumentOptions*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSavingDocumentOptions}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSavingDocumentOptions*/}

                {/* END_USER_CODE-USER_AFTER_lblSavingDocumentOptions*/}

                {/* START_USER_CODE-USER_BEFORE_btnSaveFromExistingFile*/}

                {/* END_USER_CODE-USER_BEFORE_btnSaveFromExistingFile*/}

                <ButtonWidget
                  conf={state.btnSaveFromExistingFile}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSaveFromExistingFile*/}

                {/* END_USER_CODE-USER_AFTER_btnSaveFromExistingFile*/}
                <ModalPopUp id="blur"
                  show={isShow}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  keyboard="false"
                  style={{ width: "50%", height: "100%", left: "26%" }}
                  autoFocus
                  centered
                >
                  <ModalPopUp.Body>
                    <div style={{ alignContent: "centered", textAlign: "centered", height: "100%" }}>
                      <div className="dropArea" {...getRootProps()} style={{ border: "1px solid #ccc", textAlign: "centered", height: "100%", backgroundColor:"white" }}>
                        {/* {...getRootProps()} */}
                        <label for="dropFile" style={{ textAlign: "center", width: "100%", height: "100%" }} class="btn">
                          <strong> Drag 'n' drop some file here, or click to select file. </strong>
                        </label>
                        {/* <input {...getInputProps()} accept=".pdf" id="dropFile" type="file" style={{ width: "50%", height : "100%" , visibility: "hidden" }}/> */}
                      </div>
                    </div>
                  </ModalPopUp.Body>
                </ModalPopUp>
                {/* START_USER_CODE-USER_BEFORE_btnCancelSave*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancelSave*/}

                <ButtonWidget
                  conf={state.btnCancelSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancelSave*/}

                {/* END_USER_CODE-USER_AFTER_btnCancelSave*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSavingDocumentOptions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSavingDocumentOptions*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxVerificationResult*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxVerificationResult*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxVerificationResult}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblVerifictnResult*/}

                {/* END_USER_CODE-USER_BEFORE_lblVerifictnResult*/}

                <LabelWidget
                  values={values}
                  conf={state.lblVerifictnResult}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblVerifictnResult*/}

                {/* END_USER_CODE-USER_AFTER_lblVerifictnResult*/}
                {/* START_USER_CODE-USER_BEFORE_lblVerifictnResultVal*/}

                {/* END_USER_CODE-USER_BEFORE_lblVerifictnResultVal*/}

                {/* <LabelWidget
                  values={values}
                  conf={state.lblVerifictnResultVal}
                  screenConf={state}
                ></LabelWidget> */}
                {/* START_USER_CODE-USER_AFTER_lblVerifictnResultVal*/}

                {/* END_USER_CODE-USER_AFTER_lblVerifictnResultVal*/}
                {/* START_USER_CODE-USER_BEFORE_txtarVerificationResult*/}

                {/* END_USER_CODE-USER_BEFORE_txtarVerificationResult*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarVerificationResult}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarVerificationResult*/}

                {/* END_USER_CODE-USER_AFTER_txtarVerificationResult*/}
                {/* START_USER_CODE-USER_BEFORE_buttonwidget5*/}

                {/* END_USER_CODE-USER_BEFORE_buttonwidget5*/}

                <ButtonWidget
                  conf={state.buttonwidget5}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_buttonwidget5*/}

                {/* END_USER_CODE-USER_AFTER_buttonwidget5*/}



                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxVerificationResult*/}

              {/* END_USER_CODE-USER_AFTER_grpbxVerificationResult*/}

              {/* START_USER_CODE-USER_AFTER_SpecialPayablesScan*/}

              {/* END_USER_CODE-USER_AFTER_SpecialPayablesScan*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(AccountPayment_SpecialPayablesScan);
