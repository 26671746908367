/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_VoidReasonProfile from "./VoidReasonProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("VoidReasonProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_VoidReasonProfile />);
    });
  });
  afterEach(cleanup);
  test("is VoidReasonProfile Loads Successfully", () => {
    expect(screen.getByText("VoidReasonProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for VoidReasonProfile", () => {
    // START_USER_CODE-USER_VoidReasonProfile_Custom_Test_Case
    // END_USER_CODE-USER_VoidReasonProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_VoidReasonProfile />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VoidReasonProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VoidReasonProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxVoidReasonProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxVoidReasonProfile = screen.getByTestId("grpbxVoidReasonProfile");
    expect(grpbxVoidReasonProfile.tagName).toBe("BUTTON");
    expect(grpbxVoidReasonProfile.type).toBe("button");
    expect(grpbxVoidReasonProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVoidReasonProfile", () => {
    // START_USER_CODE-USER_grpbxVoidReasonProfile_TEST
    // END_USER_CODE-USER_grpbxVoidReasonProfile_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VoidReasonProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VoidReasonProfile_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VoidReasonProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VoidReasonProfile_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("txtCode(Textbox Widget) Test Cases", async () => {
    const txtCode = screen.getByTestId("txtCode");
    expect(txtCode.tagName).toBe("INPUT");
    expect(txtCode.type).toBe("text");
    expect(txtCode.classList).toContain("textboxWidgetClass");
    expect(txtCode.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VoidReasonProfile_txtCode")
    );
    await act(async () => {
      userEvent.type(txtCode, "1");
    });
  });
  test("Custom Test Cases for txtCode", () => {
    // START_USER_CODE-USER_txtCode_TEST
    // END_USER_CODE-USER_txtCode_TEST
  });
  test("txtDiscription(Textbox Widget) Test Cases", async () => {
    const txtDiscription = screen.getByTestId("txtDiscription");
    expect(txtDiscription.tagName).toBe("INPUT");
    expect(txtDiscription.type).toBe("text");
    expect(txtDiscription.classList).toContain("textboxWidgetClass");
    expect(txtDiscription.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VoidReasonProfile_txtDiscription")
    );
    await act(async () => {
      userEvent.type(txtDiscription, "1");
    });
  });
  test("Custom Test Cases for txtDiscription", () => {
    // START_USER_CODE-USER_txtDiscription_TEST
    // END_USER_CODE-USER_txtDiscription_TEST
  });
  test("txtTransmitCode(Textbox Widget) Test Cases", async () => {
    const txtTransmitCode = screen.getByTestId("txtTransmitCode");
    expect(txtTransmitCode.tagName).toBe("INPUT");
    expect(txtTransmitCode.type).toBe("text");
    expect(txtTransmitCode.classList).toContain("textboxWidgetClass");
    expect(txtTransmitCode.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:VoidReasonProfile_txtTransmitCode")
    );
    await act(async () => {
      userEvent.type(txtTransmitCode, "1");
    });
  });
  test("Custom Test Cases for txtTransmitCode", () => {
    // START_USER_CODE-USER_txtTransmitCode_TEST
    // END_USER_CODE-USER_txtTransmitCode_TEST
  });
});
