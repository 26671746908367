/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FedWhouseLicenseProfile from "./FedWhouseLicenseProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FedWhouseLicenseProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FedWhouseLicenseProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is FedWhouseLicenseProfile Loads Successfully", () => {
    expect(screen.getByText("FedWhouseLicenseProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for FedWhouseLicenseProfile", () => {
    // START_USER_CODE-USER_FedWhouseLicenseProfile_Custom_Test_Case
    // END_USER_CODE-USER_FedWhouseLicenseProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FedWhouseLicenseProfile />
      );
    });
  });
  afterEach(cleanup);
  test("buttonwidget38(Button Widget) Test Cases", async () => {
    const buttonwidget38 = screen.getByTestId("buttonwidget38");
    expect(buttonwidget38).toBeInTheDocument;
    expect(buttonwidget38.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_buttonwidget38"
      )
    );
  });
  test("Custom Test Cases for buttonwidget38", () => {
    // START_USER_CODE-USER_buttonwidget38_TEST
    // END_USER_CODE-USER_buttonwidget38_TEST
  });
  test("buttonwidget39(Button Widget) Test Cases", async () => {
    const buttonwidget39 = screen.getByTestId("buttonwidget39");
    expect(buttonwidget39).toBeInTheDocument;
    expect(buttonwidget39.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_buttonwidget39"
      )
    );
  });
  test("Custom Test Cases for buttonwidget39", () => {
    // START_USER_CODE-USER_buttonwidget39_TEST
    // END_USER_CODE-USER_buttonwidget39_TEST
  });
  test("dtStrgPaid(Date Widget) Test Cases", async () => {
    const dtStrgPaid = screen.getByTestId("dtStrgPaid");
    expect(dtStrgPaid.tagName).toBe("INPUT");
    expect(dtStrgPaid.type).toBe("text");
    expect(dtStrgPaid.classList).toContain("datetimepicker-input");
    expect(dtStrgPaid.parentElement.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_dtStrgPaid")
    );
    await act(async () => {
      userEvent.click(dtStrgPaid.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtStrgPaid", () => {
    // START_USER_CODE-USER_dtStrgPaid_TEST
    // END_USER_CODE-USER_dtStrgPaid_TEST
  });
  test("gridLicProf(Grid Widget) Test Cases", async () => {
    let gridLicProf = screen.getByTestId("gridLicProf");
    let gridLicProfbtn = gridLicProf.nextElementSibling.firstElementChild;
    gridLicProf = gridLicProf.parentElement.parentElement.parentElement;
    expect(gridLicProf.tagName).toBe("DIV");
    expect(gridLicProf.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_gridLicProf"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridLicProf", () => {
    // START_USER_CODE-USER_gridLicProf_TEST
    // END_USER_CODE-USER_gridLicProf_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxBegEnd(GroupBox Widget) Test Cases", async () => {
    const grpbxBegEnd = screen.getByTestId("grpbxBegEnd");
    expect(grpbxBegEnd.tagName).toBe("BUTTON");
    expect(grpbxBegEnd.type).toBe("button");
    expect(grpbxBegEnd.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBegEnd", () => {
    // START_USER_CODE-USER_grpbxBegEnd_TEST
    // END_USER_CODE-USER_grpbxBegEnd_TEST
  });
  test("grpbxFedWhouse(GroupBox Widget) Test Cases", async () => {
    const grpbxFedWhouse = screen.getByTestId("grpbxFedWhouse");
    expect(grpbxFedWhouse.tagName).toBe("BUTTON");
    expect(grpbxFedWhouse.type).toBe("button");
    expect(grpbxFedWhouse.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFedWhouse", () => {
    // START_USER_CODE-USER_grpbxFedWhouse_TEST
    // END_USER_CODE-USER_grpbxFedWhouse_TEST
  });
  test("grpbxRateLayout(GroupBox Widget) Test Cases", async () => {
    const grpbxRateLayout = screen.getByTestId("grpbxRateLayout");
    expect(grpbxRateLayout.tagName).toBe("BUTTON");
    expect(grpbxRateLayout.type).toBe("button");
    expect(grpbxRateLayout.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRateLayout", () => {
    // START_USER_CODE-USER_grpbxRateLayout_TEST
    // END_USER_CODE-USER_grpbxRateLayout_TEST
  });
  test("lblDaco(Label Widget) Test Cases", async () => {
    const lblDaco = screen.getByTestId("lblDaco");
    expect(lblDaco.tagName).toBe("LABEL");
    expect(lblDaco.classList).toContain("form-label");
    expect(lblDaco.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_lblDaco")
    );
  });
  test("Custom Test Cases for lblDaco", () => {
    // START_USER_CODE-USER_lblDaco_TEST
    // END_USER_CODE-USER_lblDaco_TEST
  });
  test("lblEwhseRcpt(Label Widget) Test Cases", async () => {
    const lblEwhseRcpt = screen.getByTestId("lblEwhseRcpt");
    expect(lblEwhseRcpt.tagName).toBe("LABEL");
    expect(lblEwhseRcpt.classList).toContain("form-label");
    expect(lblEwhseRcpt.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_lblEwhseRcpt"
      )
    );
  });
  test("Custom Test Cases for lblEwhseRcpt", () => {
    // START_USER_CODE-USER_lblEwhseRcpt_TEST
    // END_USER_CODE-USER_lblEwhseRcpt_TEST
  });
  test("lblWhseRcpt(Label Widget) Test Cases", async () => {
    const lblWhseRcpt = screen.getByTestId("lblWhseRcpt");
    expect(lblWhseRcpt.tagName).toBe("LABEL");
    expect(lblWhseRcpt.classList).toContain("form-label");
    expect(lblWhseRcpt.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_lblWhseRcpt")
    );
  });
  test("Custom Test Cases for lblWhseRcpt", () => {
    // START_USER_CODE-USER_lblWhseRcpt_TEST
    // END_USER_CODE-USER_lblWhseRcpt_TEST
  });
  test("textboxwidget40(Textbox Widget) Test Cases", async () => {
    const textboxwidget40 = screen.getByTestId("textboxwidget40");
    expect(textboxwidget40.tagName).toBe("INPUT");
    expect(textboxwidget40.type).toBe("text");
    expect(textboxwidget40.classList).toContain("textboxWidgetClass");
    expect(textboxwidget40.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_textboxwidget40"
      )
    );
    await act(async () => {
      userEvent.type(textboxwidget40, "1");
    });
  });
  test("Custom Test Cases for textboxwidget40", () => {
    // START_USER_CODE-USER_textboxwidget40_TEST
    // END_USER_CODE-USER_textboxwidget40_TEST
  });
  test("txtAddedBy(Textbox Widget) Test Cases", async () => {
    const txtAddedBy = screen.getByTestId("txtAddedBy");
    expect(txtAddedBy.tagName).toBe("INPUT");
    expect(txtAddedBy.type).toBe("text");
    expect(txtAddedBy.classList).toContain("textboxWidgetClass");
    expect(txtAddedBy.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtAddedBy")
    );
    await act(async () => {
      userEvent.type(txtAddedBy, "1");
    });
  });
  test("Custom Test Cases for txtAddedBy", () => {
    // START_USER_CODE-USER_txtAddedBy_TEST
    // END_USER_CODE-USER_txtAddedBy_TEST
  });
  test("txtBeg1(Textbox Widget) Test Cases", async () => {
    const txtBeg1 = screen.getByTestId("txtBeg1");
    expect(txtBeg1.tagName).toBe("INPUT");
    expect(txtBeg1.type).toBe("text");
    expect(txtBeg1.classList).toContain("textboxWidgetClass");
    expect(txtBeg1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtBeg1")
    );
    await act(async () => {
      userEvent.type(txtBeg1, "1");
    });
  });
  test("Custom Test Cases for txtBeg1", () => {
    // START_USER_CODE-USER_txtBeg1_TEST
    // END_USER_CODE-USER_txtBeg1_TEST
  });
  test("txtBeg2(Textbox Widget) Test Cases", async () => {
    const txtBeg2 = screen.getByTestId("txtBeg2");
    expect(txtBeg2.tagName).toBe("INPUT");
    expect(txtBeg2.type).toBe("text");
    expect(txtBeg2.classList).toContain("textboxWidgetClass");
    expect(txtBeg2.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtBeg2")
    );
    await act(async () => {
      userEvent.type(txtBeg2, "1");
    });
  });
  test("Custom Test Cases for txtBeg2", () => {
    // START_USER_CODE-USER_txtBeg2_TEST
    // END_USER_CODE-USER_txtBeg2_TEST
  });
  test("txtBeg3(Textbox Widget) Test Cases", async () => {
    const txtBeg3 = screen.getByTestId("txtBeg3");
    expect(txtBeg3.tagName).toBe("INPUT");
    expect(txtBeg3.type).toBe("text");
    expect(txtBeg3.classList).toContain("textboxWidgetClass");
    expect(txtBeg3.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtBeg3")
    );
    await act(async () => {
      userEvent.type(txtBeg3, "1");
    });
  });
  test("Custom Test Cases for txtBeg3", () => {
    // START_USER_CODE-USER_txtBeg3_TEST
    // END_USER_CODE-USER_txtBeg3_TEST
  });
  test("txtCity(Textbox Widget) Test Cases", async () => {
    const txtCity = screen.getByTestId("txtCity");
    expect(txtCity.tagName).toBe("INPUT");
    expect(txtCity.type).toBe("text");
    expect(txtCity.classList).toContain("textboxWidgetClass");
    expect(txtCity.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtCity")
    );
    await act(async () => {
      userEvent.type(txtCity, "1");
    });
  });
  test("Custom Test Cases for txtCity", () => {
    // START_USER_CODE-USER_txtCity_TEST
    // END_USER_CODE-USER_txtCity_TEST
  });
  test("gridLicProf_txtcolPnutTyp(Grid Widget) Test Cases", async () => {
    let gridLicProf_txtcolPnutTyp = screen.getByTestId("gridLicProf");
    let gridLicProf_txtcolPnutTypbtn =
      gridLicProf_txtcolPnutTyp.nextElementSibling.firstElementChild;
    gridLicProf_txtcolPnutTyp =
      gridLicProf_txtcolPnutTyp.parentElement.parentElement.parentElement;
    expect(gridLicProf_txtcolPnutTyp.tagName).toBe("DIV");
    expect(gridLicProf_txtcolPnutTyp.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_gridLicProf"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutTyp", () => {
    // START_USER_CODE-USER_txtcolPnutTyp_TEST
    // END_USER_CODE-USER_txtcolPnutTyp_TEST
  });
  test("gridLicProf_txtcolPnutTypId(Grid Widget) Test Cases", async () => {
    let gridLicProf_txtcolPnutTypId = screen.getByTestId("gridLicProf");
    let gridLicProf_txtcolPnutTypIdbtn =
      gridLicProf_txtcolPnutTypId.nextElementSibling.firstElementChild;
    gridLicProf_txtcolPnutTypId =
      gridLicProf_txtcolPnutTypId.parentElement.parentElement.parentElement;
    expect(gridLicProf_txtcolPnutTypId.tagName).toBe("DIV");
    expect(gridLicProf_txtcolPnutTypId.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_gridLicProf"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutTypId", () => {
    // START_USER_CODE-USER_txtcolPnutTypId_TEST
    // END_USER_CODE-USER_txtcolPnutTypId_TEST
  });
  test("gridLicProf_txtcolShrinkWtPct(Grid Widget) Test Cases", async () => {
    let gridLicProf_txtcolShrinkWtPct = screen.getByTestId("gridLicProf");
    let gridLicProf_txtcolShrinkWtPctbtn =
      gridLicProf_txtcolShrinkWtPct.nextElementSibling.firstElementChild;
    gridLicProf_txtcolShrinkWtPct =
      gridLicProf_txtcolShrinkWtPct.parentElement.parentElement.parentElement;
    expect(gridLicProf_txtcolShrinkWtPct.tagName).toBe("DIV");
    expect(gridLicProf_txtcolShrinkWtPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_gridLicProf"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShrinkWtPct", () => {
    // START_USER_CODE-USER_txtcolShrinkWtPct_TEST
    // END_USER_CODE-USER_txtcolShrinkWtPct_TEST
  });
  test("gridLicProf_txtcolShrnkValPct(Grid Widget) Test Cases", async () => {
    let gridLicProf_txtcolShrnkValPct = screen.getByTestId("gridLicProf");
    let gridLicProf_txtcolShrnkValPctbtn =
      gridLicProf_txtcolShrnkValPct.nextElementSibling.firstElementChild;
    gridLicProf_txtcolShrnkValPct =
      gridLicProf_txtcolShrnkValPct.parentElement.parentElement.parentElement;
    expect(gridLicProf_txtcolShrnkValPct.tagName).toBe("DIV");
    expect(gridLicProf_txtcolShrnkValPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_gridLicProf"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShrnkValPct", () => {
    // START_USER_CODE-USER_txtcolShrnkValPct_TEST
    // END_USER_CODE-USER_txtcolShrnkValPct_TEST
  });
  test("txtEnd1(Textbox Widget) Test Cases", async () => {
    const txtEnd1 = screen.getByTestId("txtEnd1");
    expect(txtEnd1.tagName).toBe("INPUT");
    expect(txtEnd1.type).toBe("text");
    expect(txtEnd1.classList).toContain("textboxWidgetClass");
    expect(txtEnd1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtEnd1")
    );
    await act(async () => {
      userEvent.type(txtEnd1, "1");
    });
  });
  test("Custom Test Cases for txtEnd1", () => {
    // START_USER_CODE-USER_txtEnd1_TEST
    // END_USER_CODE-USER_txtEnd1_TEST
  });
  test("txtEnd2(Textbox Widget) Test Cases", async () => {
    const txtEnd2 = screen.getByTestId("txtEnd2");
    expect(txtEnd2.tagName).toBe("INPUT");
    expect(txtEnd2.type).toBe("text");
    expect(txtEnd2.classList).toContain("textboxWidgetClass");
    expect(txtEnd2.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtEnd2")
    );
    await act(async () => {
      userEvent.type(txtEnd2, "1");
    });
  });
  test("Custom Test Cases for txtEnd2", () => {
    // START_USER_CODE-USER_txtEnd2_TEST
    // END_USER_CODE-USER_txtEnd2_TEST
  });
  test("txtEnd3(Textbox Widget) Test Cases", async () => {
    const txtEnd3 = screen.getByTestId("txtEnd3");
    expect(txtEnd3.tagName).toBe("INPUT");
    expect(txtEnd3.type).toBe("text");
    expect(txtEnd3.classList).toContain("textboxWidgetClass");
    expect(txtEnd3.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtEnd3")
    );
    await act(async () => {
      userEvent.type(txtEnd3, "1");
    });
  });
  test("Custom Test Cases for txtEnd3", () => {
    // START_USER_CODE-USER_txtEnd3_TEST
    // END_USER_CODE-USER_txtEnd3_TEST
  });
  test("txtEwrEmail(Textbox Widget) Test Cases", async () => {
    const txtEwrEmail = screen.getByTestId("txtEwrEmail");
    expect(txtEwrEmail.tagName).toBe("INPUT");
    expect(txtEwrEmail.type).toBe("text");
    expect(txtEwrEmail.classList).toContain("textboxWidgetClass");
    expect(txtEwrEmail.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtEwrEmail")
    );
    await act(async () => {
      userEvent.type(txtEwrEmail, "1");
    });
  });
  test("Custom Test Cases for txtEwrEmail", () => {
    // START_USER_CODE-USER_txtEwrEmail_TEST
    // END_USER_CODE-USER_txtEwrEmail_TEST
  });
  test("txtFedWhouseLic(Textbox Widget) Test Cases", async () => {
    const txtFedWhouseLic = screen.getByTestId("txtFedWhouseLic");
    expect(txtFedWhouseLic.tagName).toBe("INPUT");
    expect(txtFedWhouseLic.type).toBe("text");
    expect(txtFedWhouseLic.classList).toContain("textboxWidgetClass");
    expect(txtFedWhouseLic.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtFedWhouseLic"
      )
    );
    await act(async () => {
      userEvent.type(txtFedWhouseLic, "1");
    });
  });
  test("Custom Test Cases for txtFedWhouseLic", () => {
    // START_USER_CODE-USER_txtFedWhouseLic_TEST
    // END_USER_CODE-USER_txtFedWhouseLic_TEST
  });
  test("txtFedWhsLicNm(Textbox Widget) Test Cases", async () => {
    const txtFedWhsLicNm = screen.getByTestId("txtFedWhsLicNm");
    expect(txtFedWhsLicNm.tagName).toBe("INPUT");
    expect(txtFedWhsLicNm.type).toBe("text");
    expect(txtFedWhsLicNm.classList).toContain("textboxWidgetClass");
    expect(txtFedWhsLicNm.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtFedWhsLicNm"
      )
    );
    await act(async () => {
      userEvent.type(txtFedWhsLicNm, "1");
    });
  });
  test("Custom Test Cases for txtFedWhsLicNm", () => {
    // START_USER_CODE-USER_txtFedWhsLicNm_TEST
    // END_USER_CODE-USER_txtFedWhsLicNm_TEST
  });
  test("txtMnthlyStrgRate(Textbox Widget) Test Cases", async () => {
    const txtMnthlyStrgRate = screen.getByTestId("txtMnthlyStrgRate");
    expect(txtMnthlyStrgRate.tagName).toBe("INPUT");
    expect(txtMnthlyStrgRate.type).toBe("text");
    expect(txtMnthlyStrgRate.classList).toContain("textboxWidgetClass");
    expect(txtMnthlyStrgRate.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtMnthlyStrgRate"
      )
    );
    await act(async () => {
      userEvent.type(txtMnthlyStrgRate, "1");
    });
  });
  test("Custom Test Cases for txtMnthlyStrgRate", () => {
    // START_USER_CODE-USER_txtMnthlyStrgRate_TEST
    // END_USER_CODE-USER_txtMnthlyStrgRate_TEST
  });
  test("txtNxt1(Textbox Widget) Test Cases", async () => {
    const txtNxt1 = screen.getByTestId("txtNxt1");
    expect(txtNxt1.tagName).toBe("INPUT");
    expect(txtNxt1.type).toBe("text");
    expect(txtNxt1.classList).toContain("textboxWidgetClass");
    expect(txtNxt1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtNxt1")
    );
    await act(async () => {
      userEvent.type(txtNxt1, "1");
    });
  });
  test("Custom Test Cases for txtNxt1", () => {
    // START_USER_CODE-USER_txtNxt1_TEST
    // END_USER_CODE-USER_txtNxt1_TEST
  });
  test("txtNxt2(Textbox Widget) Test Cases", async () => {
    const txtNxt2 = screen.getByTestId("txtNxt2");
    expect(txtNxt2.tagName).toBe("INPUT");
    expect(txtNxt2.type).toBe("text");
    expect(txtNxt2.classList).toContain("textboxWidgetClass");
    expect(txtNxt2.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtNxt2")
    );
    await act(async () => {
      userEvent.type(txtNxt2, "1");
    });
  });
  test("Custom Test Cases for txtNxt2", () => {
    // START_USER_CODE-USER_txtNxt2_TEST
    // END_USER_CODE-USER_txtNxt2_TEST
  });
  test("txtNxt3(Textbox Widget) Test Cases", async () => {
    const txtNxt3 = screen.getByTestId("txtNxt3");
    expect(txtNxt3.tagName).toBe("INPUT");
    expect(txtNxt3.type).toBe("text");
    expect(txtNxt3.classList).toContain("textboxWidgetClass");
    expect(txtNxt3.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtNxt3")
    );
    await act(async () => {
      userEvent.type(txtNxt3, "1");
    });
  });
  test("Custom Test Cases for txtNxt3", () => {
    // START_USER_CODE-USER_txtNxt3_TEST
    // END_USER_CODE-USER_txtNxt3_TEST
  });
  test("txtShrunkLoad(Textbox Widget) Test Cases", async () => {
    const txtShrunkLoad = screen.getByTestId("txtShrunkLoad");
    expect(txtShrunkLoad.tagName).toBe("INPUT");
    expect(txtShrunkLoad.type).toBe("text");
    expect(txtShrunkLoad.classList).toContain("textboxWidgetClass");
    expect(txtShrunkLoad.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtShrunkLoad"
      )
    );
    await act(async () => {
      userEvent.type(txtShrunkLoad, "1");
    });
  });
  test("Custom Test Cases for txtShrunkLoad", () => {
    // START_USER_CODE-USER_txtShrunkLoad_TEST
    // END_USER_CODE-USER_txtShrunkLoad_TEST
  });
  test("txtState(Textbox Widget) Test Cases", async () => {
    const txtState = screen.getByTestId("txtState");
    expect(txtState.tagName).toBe("INPUT");
    expect(txtState.type).toBe("text");
    expect(txtState.classList).toContain("textboxWidgetClass");
    expect(txtState.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtState")
    );
    await act(async () => {
      userEvent.type(txtState, "1");
    });
  });
  test("Custom Test Cases for txtState", () => {
    // START_USER_CODE-USER_txtState_TEST
    // END_USER_CODE-USER_txtState_TEST
  });
  test("txtStorgRate(Textbox Widget) Test Cases", async () => {
    const txtStorgRate = screen.getByTestId("txtStorgRate");
    expect(txtStorgRate.tagName).toBe("INPUT");
    expect(txtStorgRate.type).toBe("text");
    expect(txtStorgRate.classList).toContain("textboxWidgetClass");
    expect(txtStorgRate.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtStorgRate"
      )
    );
    await act(async () => {
      userEvent.type(txtStorgRate, "1");
    });
  });
  test("Custom Test Cases for txtStorgRate", () => {
    // START_USER_CODE-USER_txtStorgRate_TEST
    // END_USER_CODE-USER_txtStorgRate_TEST
  });
  test("txtUnshrunkLoad(Textbox Widget) Test Cases", async () => {
    const txtUnshrunkLoad = screen.getByTestId("txtUnshrunkLoad");
    expect(txtUnshrunkLoad.tagName).toBe("INPUT");
    expect(txtUnshrunkLoad.type).toBe("text");
    expect(txtUnshrunkLoad.classList).toContain("textboxWidgetClass");
    expect(txtUnshrunkLoad.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtUnshrunkLoad"
      )
    );
    await act(async () => {
      userEvent.type(txtUnshrunkLoad, "1");
    });
  });
  test("Custom Test Cases for txtUnshrunkLoad", () => {
    // START_USER_CODE-USER_txtUnshrunkLoad_TEST
    // END_USER_CODE-USER_txtUnshrunkLoad_TEST
  });
  test("txtWhouseSign(Textbox Widget) Test Cases", async () => {
    const txtWhouseSign = screen.getByTestId("txtWhouseSign");
    expect(txtWhouseSign.tagName).toBe("INPUT");
    expect(txtWhouseSign.type).toBe("text");
    expect(txtWhouseSign.classList).toContain("textboxWidgetClass");
    expect(txtWhouseSign.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FedWhouseLicenseProfile_txtWhouseSign"
      )
    );
    await act(async () => {
      userEvent.type(txtWhouseSign, "1");
    });
  });
  test("Custom Test Cases for txtWhouseSign", () => {
    // START_USER_CODE-USER_txtWhouseSign_TEST
    // END_USER_CODE-USER_txtWhouseSign_TEST
  });
});
