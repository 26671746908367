/* eslint-disable*/
import React from "react";
import WarehouseReceipts_ViewWRApplications from "./ViewWRApplications";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ViewWRApplications Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ViewWRApplications />);
    });
  });
  afterEach(cleanup);
  test("is ViewWRApplications Loads Successfully", () => {
    expect(screen.getByText("ViewWRApplications")).toBeInTheDocument;
  });
  test("Custom Test Cases for ViewWRApplications", () => {
    // START_USER_CODE-USER_ViewWRApplications_Custom_Test_Case
    // END_USER_CODE-USER_ViewWRApplications_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_ViewWRApplications />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("WarehouseReceipts:ViewWRApplications_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("gridContractApplications(Grid Widget) Test Cases", async () => {
    let gridContractApplications = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplicationsbtn =
      gridContractApplications.nextElementSibling.firstElementChild;
    gridContractApplications =
      gridContractApplications.parentElement.parentElement.parentElement;
    expect(gridContractApplications.tagName).toBe("DIV");
    expect(gridContractApplications.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridContractApplications", () => {
    // START_USER_CODE-USER_gridContractApplications_TEST
    // END_USER_CODE-USER_gridContractApplications_TEST
  });
  test("gridSeedGrowerApplications(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplicationsbtn =
      gridSeedGrowerApplications.nextElementSibling.firstElementChild;
    gridSeedGrowerApplications =
      gridSeedGrowerApplications.parentElement.parentElement.parentElement;
    expect(gridSeedGrowerApplications.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridSeedGrowerApplications", () => {
    // START_USER_CODE-USER_gridSeedGrowerApplications_TEST
    // END_USER_CODE-USER_gridSeedGrowerApplications_TEST
  });
  test("grpbxContractApplication(GroupBox Widget) Test Cases", async () => {
    const grpbxContractApplication = screen.getByTestId(
      "grpbxContractApplication"
    );
    expect(grpbxContractApplication.tagName).toBe("BUTTON");
    expect(grpbxContractApplication.type).toBe("button");
    expect(grpbxContractApplication.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContractApplication", () => {
    // START_USER_CODE-USER_grpbxContractApplication_TEST
    // END_USER_CODE-USER_grpbxContractApplication_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("grpbxSeedGroverApplications(GroupBox Widget) Test Cases", async () => {
    const grpbxSeedGroverApplications = screen.getByTestId(
      "grpbxSeedGroverApplications"
    );
    expect(grpbxSeedGroverApplications.tagName).toBe("BUTTON");
    expect(grpbxSeedGroverApplications.type).toBe("button");
    expect(grpbxSeedGroverApplications.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSeedGroverApplications", () => {
    // START_USER_CODE-USER_grpbxSeedGroverApplications_TEST
    // END_USER_CODE-USER_grpbxSeedGroverApplications_TEST
  });
  test("grpbxSpotApplication(GroupBox Widget) Test Cases", async () => {
    const grpbxSpotApplication = screen.getByTestId("grpbxSpotApplication");
    expect(grpbxSpotApplication.tagName).toBe("BUTTON");
    expect(grpbxSpotApplication.type).toBe("button");
    expect(grpbxSpotApplication.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSpotApplication", () => {
    // START_USER_CODE-USER_grpbxSpotApplication_TEST
    // END_USER_CODE-USER_grpbxSpotApplication_TEST
  });
  test("grpbxViewWRApplications(GroupBox Widget) Test Cases", async () => {
    const grpbxViewWRApplications = screen.getByTestId(
      "grpbxViewWRApplications"
    );
    expect(grpbxViewWRApplications.tagName).toBe("BUTTON");
    expect(grpbxViewWRApplications.type).toBe("button");
    expect(grpbxViewWRApplications.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxViewWRApplications", () => {
    // START_USER_CODE-USER_grpbxViewWRApplications_TEST
    // END_USER_CODE-USER_grpbxViewWRApplications_TEST
  });
  test("lblContractApplications(Label Widget) Test Cases", async () => {
    const lblContractApplications = screen.getByTestId(
      "lblContractApplications"
    );
    expect(lblContractApplications.tagName).toBe("LABEL");
    expect(lblContractApplications.classList).toContain("form-label");
    expect(lblContractApplications.textContent).toEqual(
      t("WarehouseReceipts:ViewWRApplications_lblContractApplications")
    );
  });
  test("Custom Test Cases for lblContractApplications", () => {
    // START_USER_CODE-USER_lblContractApplications_TEST
    // END_USER_CODE-USER_lblContractApplications_TEST
  });
  test("lblSeedGrowerApplications(Label Widget) Test Cases", async () => {
    const lblSeedGrowerApplications = screen.getByTestId(
      "lblSeedGrowerApplications"
    );
    expect(lblSeedGrowerApplications.tagName).toBe("LABEL");
    expect(lblSeedGrowerApplications.classList).toContain("form-label");
    expect(lblSeedGrowerApplications.textContent).toEqual(
      t("WarehouseReceipts:ViewWRApplications_lblSeedGrowerApplications")
    );
  });
  test("Custom Test Cases for lblSeedGrowerApplications", () => {
    // START_USER_CODE-USER_lblSeedGrowerApplications_TEST
    // END_USER_CODE-USER_lblSeedGrowerApplications_TEST
  });
  test("lblSpotApplication(Label Widget) Test Cases", async () => {
    const lblSpotApplication = screen.getByTestId("lblSpotApplication");
    expect(lblSpotApplication.tagName).toBe("LABEL");
    expect(lblSpotApplication.classList).toContain("form-label");
    expect(lblSpotApplication.textContent).toEqual(
      t("WarehouseReceipts:ViewWRApplications_lblSpotApplication")
    );
  });
  test("Custom Test Cases for lblSpotApplication", () => {
    // START_USER_CODE-USER_lblSpotApplication_TEST
    // END_USER_CODE-USER_lblSpotApplication_TEST
  });
  test("gridSeedGrowerApplications_txtcol1SGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcol1SGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcol1SGAbtn =
      gridSeedGrowerApplications_txtcol1SGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcol1SGA =
      gridSeedGrowerApplications_txtcol1SGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcol1SGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcol1SGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcol1SGA", () => {
    // START_USER_CODE-USER_txtcol1SGA_TEST
    // END_USER_CODE-USER_txtcol1SGA_TEST
  });
  test("gridContractApplications_txtcolAppliedLbsAdj(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolAppliedLbsAdj = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolAppliedLbsAdjbtn =
      gridContractApplications_txtcolAppliedLbsAdj.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolAppliedLbsAdj =
      gridContractApplications_txtcolAppliedLbsAdj.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolAppliedLbsAdj.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolAppliedLbsAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAppliedLbsAdj", () => {
    // START_USER_CODE-USER_txtcolAppliedLbsAdj_TEST
    // END_USER_CODE-USER_txtcolAppliedLbsAdj_TEST
  });
  test("gridSeedGrowerApplications_txtcolAppliedLbsSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolAppliedLbsSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolAppliedLbsSGAbtn =
      gridSeedGrowerApplications_txtcolAppliedLbsSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolAppliedLbsSGA =
      gridSeedGrowerApplications_txtcolAppliedLbsSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolAppliedLbsSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolAppliedLbsSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAppliedLbsSGA", () => {
    // START_USER_CODE-USER_txtcolAppliedLbsSGA_TEST
    // END_USER_CODE-USER_txtcolAppliedLbsSGA_TEST
  });
  test("gridContractApplications_txtcolBasis(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolBasis = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolBasisbtn =
      gridContractApplications_txtcolBasis.nextElementSibling.firstElementChild;
    gridContractApplications_txtcolBasis =
      gridContractApplications_txtcolBasis.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolBasis.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolBasis.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBasis", () => {
    // START_USER_CODE-USER_txtcolBasis_TEST
    // END_USER_CODE-USER_txtcolBasis_TEST
  });
  test("gridSeedGrowerApplications_txtcolBasisSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolBasisSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolBasisSGAbtn =
      gridSeedGrowerApplications_txtcolBasisSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolBasisSGA =
      gridSeedGrowerApplications_txtcolBasisSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolBasisSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolBasisSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBasisSGA", () => {
    // START_USER_CODE-USER_txtcolBasisSGA_TEST
    // END_USER_CODE-USER_txtcolBasisSGA_TEST
  });
  test("gridContractApplications_txtcolBuyPt(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolBuyPt = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolBuyPtbtn =
      gridContractApplications_txtcolBuyPt.nextElementSibling.firstElementChild;
    gridContractApplications_txtcolBuyPt =
      gridContractApplications_txtcolBuyPt.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolBuyPt.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolBuyPt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyPt", () => {
    // START_USER_CODE-USER_txtcolBuyPt_TEST
    // END_USER_CODE-USER_txtcolBuyPt_TEST
  });
  test("gridSeedGrowerApplications_txtcolBuyPtSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolBuyPtSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolBuyPtSGAbtn =
      gridSeedGrowerApplications_txtcolBuyPtSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolBuyPtSGA =
      gridSeedGrowerApplications_txtcolBuyPtSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolBuyPtSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolBuyPtSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBuyPtSGA", () => {
    // START_USER_CODE-USER_txtcolBuyPtSGA_TEST
    // END_USER_CODE-USER_txtcolBuyPtSGA_TEST
  });
  test("gridContractApplications_txtcolContractDate(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolContractDate = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolContractDatebtn =
      gridContractApplications_txtcolContractDate.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolContractDate =
      gridContractApplications_txtcolContractDate.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolContractDate.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolContractDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractDate", () => {
    // START_USER_CODE-USER_txtcolContractDate_TEST
    // END_USER_CODE-USER_txtcolContractDate_TEST
  });
  test("gridSeedGrowerApplications_txtcolContractDateSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolContractDateSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolContractDateSGAbtn =
      gridSeedGrowerApplications_txtcolContractDateSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolContractDateSGA =
      gridSeedGrowerApplications_txtcolContractDateSGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcolContractDateSGA.tagName).toBe(
      "DIV"
    );
    expect(
      gridSeedGrowerApplications_txtcolContractDateSGA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractDateSGA", () => {
    // START_USER_CODE-USER_txtcolContractDateSGA_TEST
    // END_USER_CODE-USER_txtcolContractDateSGA_TEST
  });
  test("gridSeedGrowerApplications_txtcolContractLbsSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolContractLbsSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolContractLbsSGAbtn =
      gridSeedGrowerApplications_txtcolContractLbsSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolContractLbsSGA =
      gridSeedGrowerApplications_txtcolContractLbsSGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcolContractLbsSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolContractLbsSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractLbsSGA", () => {
    // START_USER_CODE-USER_txtcolContractLbsSGA_TEST
    // END_USER_CODE-USER_txtcolContractLbsSGA_TEST
  });
  test("gridContractApplications_txtcolContractNumber(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolContractNumber = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolContractNumberbtn =
      gridContractApplications_txtcolContractNumber.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolContractNumber =
      gridContractApplications_txtcolContractNumber.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolContractNumber.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolContractNumber.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractNumber", () => {
    // START_USER_CODE-USER_txtcolContractNumber_TEST
    // END_USER_CODE-USER_txtcolContractNumber_TEST
  });
  test("gridSeedGrowerApplications_txtcolContractNumberSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolContractNumberSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolContractNumberSGAbtn =
      gridSeedGrowerApplications_txtcolContractNumberSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolContractNumberSGA =
      gridSeedGrowerApplications_txtcolContractNumberSGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcolContractNumberSGA.tagName).toBe(
      "DIV"
    );
    expect(
      gridSeedGrowerApplications_txtcolContractNumberSGA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractNumberSGA", () => {
    // START_USER_CODE-USER_txtcolContractNumberSGA_TEST
    // END_USER_CODE-USER_txtcolContractNumberSGA_TEST
  });
  test("gridContractApplications_txtcolContType(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolContType = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolContTypebtn =
      gridContractApplications_txtcolContType.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolContType =
      gridContractApplications_txtcolContType.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolContType.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolContType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContType", () => {
    // START_USER_CODE-USER_txtcolContType_TEST
    // END_USER_CODE-USER_txtcolContType_TEST
  });
  test("gridSeedGrowerApplications_txtcolContTypeSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolContTypeSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolContTypeSGAbtn =
      gridSeedGrowerApplications_txtcolContTypeSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolContTypeSGA =
      gridSeedGrowerApplications_txtcolContTypeSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolContTypeSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolContTypeSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContTypeSGA", () => {
    // START_USER_CODE-USER_txtcolContTypeSGA_TEST
    // END_USER_CODE-USER_txtcolContTypeSGA_TEST
  });
  test("gridContractApplications_txtcolDelvEndDt(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolDelvEndDt = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolDelvEndDtbtn =
      gridContractApplications_txtcolDelvEndDt.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolDelvEndDt =
      gridContractApplications_txtcolDelvEndDt.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolDelvEndDt.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolDelvEndDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDelvEndDt", () => {
    // START_USER_CODE-USER_txtcolDelvEndDt_TEST
    // END_USER_CODE-USER_txtcolDelvEndDt_TEST
  });
  test("gridContractApplications_txtcolDelvStartDt(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolDelvStartDt = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolDelvStartDtbtn =
      gridContractApplications_txtcolDelvStartDt.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolDelvStartDt =
      gridContractApplications_txtcolDelvStartDt.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolDelvStartDt.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolDelvStartDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDelvStartDt", () => {
    // START_USER_CODE-USER_txtcolDelvStartDt_TEST
    // END_USER_CODE-USER_txtcolDelvStartDt_TEST
  });
  test("gridContractApplications_txtcolGrouping(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolGrouping = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolGroupingbtn =
      gridContractApplications_txtcolGrouping.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolGrouping =
      gridContractApplications_txtcolGrouping.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolGrouping.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolGrouping.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGrouping", () => {
    // START_USER_CODE-USER_txtcolGrouping_TEST
    // END_USER_CODE-USER_txtcolGrouping_TEST
  });
  test("gridSeedGrowerApplications_txtcolOpenLbsSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolOpenLbsSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolOpenLbsSGAbtn =
      gridSeedGrowerApplications_txtcolOpenLbsSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolOpenLbsSGA =
      gridSeedGrowerApplications_txtcolOpenLbsSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolOpenLbsSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolOpenLbsSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpenLbsSGA", () => {
    // START_USER_CODE-USER_txtcolOpenLbsSGA_TEST
    // END_USER_CODE-USER_txtcolOpenLbsSGA_TEST
  });
  test("gridContractApplications_txtcolPeanutVariety(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolPeanutVariety = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolPeanutVarietybtn =
      gridContractApplications_txtcolPeanutVariety.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolPeanutVariety =
      gridContractApplications_txtcolPeanutVariety.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolPeanutVariety.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolPeanutVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutVariety", () => {
    // START_USER_CODE-USER_txtcolPeanutVariety_TEST
    // END_USER_CODE-USER_txtcolPeanutVariety_TEST
  });
  test("gridSeedGrowerApplications_txtcolPeanutVarietySGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolPeanutVarietySGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolPeanutVarietySGAbtn =
      gridSeedGrowerApplications_txtcolPeanutVarietySGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolPeanutVarietySGA =
      gridSeedGrowerApplications_txtcolPeanutVarietySGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcolPeanutVarietySGA.tagName).toBe(
      "DIV"
    );
    expect(
      gridSeedGrowerApplications_txtcolPeanutVarietySGA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutVarietySGA", () => {
    // START_USER_CODE-USER_txtcolPeanutVarietySGA_TEST
    // END_USER_CODE-USER_txtcolPeanutVarietySGA_TEST
  });
  test("gridSeedGrowerApplications_txtcolPoundsToApplySGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolPoundsToApplySGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolPoundsToApplySGAbtn =
      gridSeedGrowerApplications_txtcolPoundsToApplySGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolPoundsToApplySGA =
      gridSeedGrowerApplications_txtcolPoundsToApplySGA.parentElement
        .parentElement.parentElement;
    expect(gridSeedGrowerApplications_txtcolPoundsToApplySGA.tagName).toBe(
      "DIV"
    );
    expect(
      gridSeedGrowerApplications_txtcolPoundsToApplySGA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPoundsToApplySGA", () => {
    // START_USER_CODE-USER_txtcolPoundsToApplySGA_TEST
    // END_USER_CODE-USER_txtcolPoundsToApplySGA_TEST
  });
  test("gridContractApplications_txtcolPrice(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolPrice = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolPricebtn =
      gridContractApplications_txtcolPrice.nextElementSibling.firstElementChild;
    gridContractApplications_txtcolPrice =
      gridContractApplications_txtcolPrice.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolPrice.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolPrice.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPrice", () => {
    // START_USER_CODE-USER_txtcolPrice_TEST
    // END_USER_CODE-USER_txtcolPrice_TEST
  });
  test("gridSeedGrowerApplications_txtcolPriceSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolPriceSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolPriceSGAbtn =
      gridSeedGrowerApplications_txtcolPriceSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolPriceSGA =
      gridSeedGrowerApplications_txtcolPriceSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolPriceSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolPriceSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPriceSGA", () => {
    // START_USER_CODE-USER_txtcolPriceSGA_TEST
    // END_USER_CODE-USER_txtcolPriceSGA_TEST
  });
  test("gridContractApplications_txtcolSeed(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolSeed = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolSeedbtn =
      gridContractApplications_txtcolSeed.nextElementSibling.firstElementChild;
    gridContractApplications_txtcolSeed =
      gridContractApplications_txtcolSeed.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolSeed.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolSeed.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeed", () => {
    // START_USER_CODE-USER_txtcolSeed_TEST
    // END_USER_CODE-USER_txtcolSeed_TEST
  });
  test("gridSeedGrowerApplications_txtcolSeedSGA(Grid Widget) Test Cases", async () => {
    let gridSeedGrowerApplications_txtcolSeedSGA = screen.getByTestId(
      "gridSeedGrowerApplications"
    );
    let gridSeedGrowerApplications_txtcolSeedSGAbtn =
      gridSeedGrowerApplications_txtcolSeedSGA.nextElementSibling
        .firstElementChild;
    gridSeedGrowerApplications_txtcolSeedSGA =
      gridSeedGrowerApplications_txtcolSeedSGA.parentElement.parentElement
        .parentElement;
    expect(gridSeedGrowerApplications_txtcolSeedSGA.tagName).toBe("DIV");
    expect(gridSeedGrowerApplications_txtcolSeedSGA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridSeedGrowerApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedSGA", () => {
    // START_USER_CODE-USER_txtcolSeedSGA_TEST
    // END_USER_CODE-USER_txtcolSeedSGA_TEST
  });
  test("gridContractApplications_txtcolTentLbsAdj(Grid Widget) Test Cases", async () => {
    let gridContractApplications_txtcolTentLbsAdj = screen.getByTestId(
      "gridContractApplications"
    );
    let gridContractApplications_txtcolTentLbsAdjbtn =
      gridContractApplications_txtcolTentLbsAdj.nextElementSibling
        .firstElementChild;
    gridContractApplications_txtcolTentLbsAdj =
      gridContractApplications_txtcolTentLbsAdj.parentElement.parentElement
        .parentElement;
    expect(gridContractApplications_txtcolTentLbsAdj.tagName).toBe("DIV");
    expect(gridContractApplications_txtcolTentLbsAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:ViewWRApplications_gridContractApplications")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTentLbsAdj", () => {
    // START_USER_CODE-USER_txtcolTentLbsAdj_TEST
    // END_USER_CODE-USER_txtcolTentLbsAdj_TEST
  });
  test("txtLbsToApply(Textbox Widget) Test Cases", async () => {
    const txtLbsToApply = screen.getByTestId("txtLbsToApply");
    expect(txtLbsToApply.tagName).toBe("INPUT");
    expect(txtLbsToApply.type).toBe("text");
    expect(txtLbsToApply.classList).toContain("textboxWidgetClass");
    expect(txtLbsToApply.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ViewWRApplications_txtLbsToApply")
    );
    await act(async () => {
      userEvent.type(txtLbsToApply, "123");
    });
    expect(txtLbsToApply.getAttribute("value")).toBe("");
    expect(txtLbsToApply.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLbsToApply", () => {
    // START_USER_CODE-USER_txtLbsToApply_TEST
    // END_USER_CODE-USER_txtLbsToApply_TEST
  });
  test("txtObligationLocation(Textbox Widget) Test Cases", async () => {
    const txtObligationLocation = screen.getByTestId("txtObligationLocation");
    expect(txtObligationLocation.tagName).toBe("INPUT");
    expect(txtObligationLocation.type).toBe("text");
    expect(txtObligationLocation.classList).toContain("textboxWidgetClass");
    expect(txtObligationLocation.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ViewWRApplications_txtObligationLocation")
    );
    await act(async () => {
      userEvent.type(txtObligationLocation, "123");
    });
    expect(txtObligationLocation.getAttribute("value")).toBe("");
    expect(txtObligationLocation.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtObligationLocation", () => {
    // START_USER_CODE-USER_txtObligationLocation_TEST
    // END_USER_CODE-USER_txtObligationLocation_TEST
  });
  test("txtSpotPrice(Textbox Widget) Test Cases", async () => {
    const txtSpotPrice = screen.getByTestId("txtSpotPrice");
    expect(txtSpotPrice.tagName).toBe("INPUT");
    expect(txtSpotPrice.type).toBe("text");
    expect(txtSpotPrice.classList).toContain("textboxWidgetClass");
    expect(txtSpotPrice.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ViewWRApplications_txtSpotPrice")
    );
    await act(async () => {
      userEvent.type(txtSpotPrice, "123");
    });
    expect(txtSpotPrice.getAttribute("value")).toBe("");
    expect(txtSpotPrice.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSpotPrice", () => {
    // START_USER_CODE-USER_txtSpotPrice_TEST
    // END_USER_CODE-USER_txtSpotPrice_TEST
  });
  test("txtUnloadLocation(Textbox Widget) Test Cases", async () => {
    const txtUnloadLocation = screen.getByTestId("txtUnloadLocation");
    expect(txtUnloadLocation.tagName).toBe("INPUT");
    expect(txtUnloadLocation.type).toBe("text");
    expect(txtUnloadLocation.classList).toContain("textboxWidgetClass");
    expect(txtUnloadLocation.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ViewWRApplications_txtUnloadLocation")
    );
    await act(async () => {
      userEvent.type(txtUnloadLocation, "123");
    });
    expect(txtUnloadLocation.getAttribute("value")).toBe("");
    expect(txtUnloadLocation.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtUnloadLocation", () => {
    // START_USER_CODE-USER_txtUnloadLocation_TEST
    // END_USER_CODE-USER_txtUnloadLocation_TEST
  });
  test("txtWarehouseReceiptNum(Textbox Widget) Test Cases", async () => {
    const txtWarehouseReceiptNum = screen.getByTestId("txtWarehouseReceiptNum");
    expect(txtWarehouseReceiptNum.tagName).toBe("INPUT");
    expect(txtWarehouseReceiptNum.type).toBe("text");
    expect(txtWarehouseReceiptNum.classList).toContain("textboxWidgetClass");
    expect(txtWarehouseReceiptNum.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:ViewWRApplications_txtWarehouseReceiptNum")
    );
    await act(async () => {
      userEvent.type(txtWarehouseReceiptNum, "123");
    });
    expect(txtWarehouseReceiptNum.getAttribute("value")).toBe("");
    expect(txtWarehouseReceiptNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtWarehouseReceiptNum", () => {
    // START_USER_CODE-USER_txtWarehouseReceiptNum_TEST
    // END_USER_CODE-USER_txtWarehouseReceiptNum_TEST
  });
});
