/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
} from "../../shared/WindowImports";

import "./VendorLookup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_VendorLookup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "VendorLookup",
    windowName: "VendorLookup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.VendorLookup",
    // START_USER_CODE-USER_VendorLookup_PROPERTIES
    headerData: {
      scrnName: "Vendor Lookup",
      scrnId: "PN0350E",
      year: "2020"
    },
    // END_USER_CODE-USER_VendorLookup_PROPERTIES
    btnCncl: {
      name: "btnCncl",
      type: "ButtonWidget",
      parent: "btnCntnr",
      Label: "Cancel",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCncl_PROPERTIES

      // END_USER_CODE-USER_btnCncl_PROPERTIES
    },
    btnCntnr: {
      name: "btnCntnr",
      type: "CommandContainerWidget",
      parent: "grpbxVendorLookup",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_btnCntnr_PROPERTIES

      // END_USER_CODE-USER_btnCntnr_PROPERTIES
    },
    btnSelect: {
      name: "btnSelect",
      type: "ButtonWidget",
      parent: "btnCntnr",
      Label: "Select",
      CharWidth: "15",
      // START_USER_CODE-USER_btnSelect_PROPERTIES

      // END_USER_CODE-USER_btnSelect_PROPERTIES
    },
    btnSrch: {
      name: "btnSrch",
      type: "ButtonWidget",
      parent: "grpbxVendorLookup",
      Label: "Search",
      CharWidth: "15",
      // START_USER_CODE-USER_btnSrch_PROPERTIES

      // END_USER_CODE-USER_btnSrch_PROPERTIES
    },
    lstBoxVndrLst: {
      name: "lstBoxVndrLst",
      type: "ListBoxFieldWidget",
      parent: "grpbxVendorLookup",
      Label: "Vendor List:",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstBoxVndrLst_PROPERTIES

      // END_USER_CODE-USER_lstBoxVndrLst_PROPERTIES
    },
    txtSrch: {
      name: "txtSrch",
      type: "TextBoxWidget",
      parent: "grpbxVendorLookup",
      Label: "Search For: (min. of 4 chars.)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSrch_PROPERTIES

      // END_USER_CODE-USER_txtSrch_PROPERTIES
    },
    grpbxVendorLookup: {
      name: "grpbxVendorLookup",
      type: "GroupBoxWidget",
      parent: "VendorLookup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxVendorLookup_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorLookup_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {},
      REVERSE: {},
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  const onbtnCnclClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      // Modal.close();
      document.getElementsByClassName("close")[0].click()
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCncl event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCnclClick = onbtnCnclClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData = {state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_VendorLookup*/}

              {/* END_USER_CODE-USER_BEFORE_VendorLookup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxVendorLookup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxVendorLookup*/}

              <GroupBoxWidget conf={state.grpbxVendorLookup} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtSrch*/}

                {/* END_USER_CODE-USER_BEFORE_txtSrch*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSrch}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSrch*/}

                {/* END_USER_CODE-USER_AFTER_txtSrch*/}
                {/* START_USER_CODE-USER_BEFORE_btnSrch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSrch*/}

                <ButtonWidget
                  conf={state.btnSrch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSrch*/}

                {/* END_USER_CODE-USER_AFTER_btnSrch*/}
                {/* START_USER_CODE-USER_BEFORE_lstBoxVndrLst*/}

                {/* END_USER_CODE-USER_BEFORE_lstBoxVndrLst*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstBoxVndrLst}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstBoxVndrLst*/}

                {/* END_USER_CODE-USER_AFTER_lstBoxVndrLst*/}
                {/* START_USER_CODE-USER_BEFORE_btnCntnr*/}

                {/* END_USER_CODE-USER_BEFORE_btnCntnr*/}

                <CommandContainerWidget
                  conf={state.btnCntnr}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnSelect*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSelect*/}

                  <ButtonWidget
                    conf={state.btnSelect}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSelect*/}

                  {/* END_USER_CODE-USER_AFTER_btnSelect*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCncl*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCncl*/}

                  <ButtonWidget
                    conf={state.btnCncl}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCncl*/}

                  {/* END_USER_CODE-USER_AFTER_btnCncl*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_btnCntnr*/}

                {/* END_USER_CODE-USER_AFTER_btnCntnr*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxVendorLookup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxVendorLookup*/}

              {/* START_USER_CODE-USER_AFTER_VendorLookup*/}

              {/* END_USER_CODE-USER_AFTER_VendorLookup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_VendorLookup);
