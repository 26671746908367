/* eslint-disable*/
import React from "react";
import WarehouseReceipts_EWRViewRRI from "./EWRViewRRI";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("EWRViewRRI Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_EWRViewRRI />);
    });
  });
  afterEach(cleanup);
  test("is EWRViewRRI Loads Successfully", () => {
    expect(screen.getByText("EWRViewRRI")).toBeInTheDocument;
  });
  test("Custom Test Cases for EWRViewRRI", () => {
    // START_USER_CODE-USER_EWRViewRRI_Custom_Test_Case
    // END_USER_CODE-USER_EWRViewRRI_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_EWRViewRRI />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("gridEWRViewRRI(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRIbtn = gridEWRViewRRI.nextElementSibling.firstElementChild;
    gridEWRViewRRI = gridEWRViewRRI.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI.tagName).toBe("DIV");
    expect(gridEWRViewRRI.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridEWRViewRRI", () => {
    // START_USER_CODE-USER_gridEWRViewRRI_TEST
    // END_USER_CODE-USER_gridEWRViewRRI_TEST
  });
  test("grpbxEWRViewRRI(GroupBox Widget) Test Cases", async () => {
    const grpbxEWRViewRRI = screen.getByTestId("grpbxEWRViewRRI");
    expect(grpbxEWRViewRRI.tagName).toBe("BUTTON");
    expect(grpbxEWRViewRRI.type).toBe("button");
    expect(grpbxEWRViewRRI.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxEWRViewRRI", () => {
    // START_USER_CODE-USER_grpbxEWRViewRRI_TEST
    // END_USER_CODE-USER_grpbxEWRViewRRI_TEST
  });
  test("gridEWRViewRRI_trtcolTotSmkPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_trtcolTotSmkPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_trtcolTotSmkPctbtn =
      gridEWRViewRRI_trtcolTotSmkPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_trtcolTotSmkPct =
      gridEWRViewRRI_trtcolTotSmkPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_trtcolTotSmkPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_trtcolTotSmkPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for trtcolTotSmkPct", () => {
    // START_USER_CODE-USER_trtcolTotSmkPct_TEST
    // END_USER_CODE-USER_trtcolTotSmkPct_TEST
  });
  test("gridEWRViewRRI_txtcolAction(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolAction = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolActionbtn =
      gridEWRViewRRI_txtcolAction.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolAction =
      gridEWRViewRRI_txtcolAction.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolAction.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolAction.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAction", () => {
    // START_USER_CODE-USER_txtcolAction_TEST
    // END_USER_CODE-USER_txtcolAction_TEST
  });
  test("gridEWRViewRRI_txtcolAddDate(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolAddDate = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolAddDatebtn =
      gridEWRViewRRI_txtcolAddDate.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolAddDate =
      gridEWRViewRRI_txtcolAddDate.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolAddDate.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolAddDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAddDate", () => {
    // START_USER_CODE-USER_txtcolAddDate_TEST
    // END_USER_CODE-USER_txtcolAddDate_TEST
  });
  test("gridEWRViewRRI_txtcolAddUser(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolAddUser = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolAddUserbtn =
      gridEWRViewRRI_txtcolAddUser.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolAddUser =
      gridEWRViewRRI_txtcolAddUser.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolAddUser.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolAddUser.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAddUser", () => {
    // START_USER_CODE-USER_txtcolAddUser_TEST
    // END_USER_CODE-USER_txtcolAddUser_TEST
  });
  test("gridEWRViewRRI_txtcolBPName(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolBPName = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolBPNamebtn =
      gridEWRViewRRI_txtcolBPName.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolBPName =
      gridEWRViewRRI_txtcolBPName.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolBPName.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolBPName.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBPName", () => {
    // START_USER_CODE-USER_txtcolBPName_TEST
    // END_USER_CODE-USER_txtcolBPName_TEST
  });
  test("gridEWRViewRRI_txtcolBPNum(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolBPNum = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolBPNumbtn =
      gridEWRViewRRI_txtcolBPNum.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolBPNum =
      gridEWRViewRRI_txtcolBPNum.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolBPNum.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolBPNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBPNum", () => {
    // START_USER_CODE-USER_txtcolBPNum_TEST
    // END_USER_CODE-USER_txtcolBPNum_TEST
  });
  test("gridEWRViewRRI_txtcolCCCErrCode(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolCCCErrCode = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolCCCErrCodebtn =
      gridEWRViewRRI_txtcolCCCErrCode.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolCCCErrCode =
      gridEWRViewRRI_txtcolCCCErrCode.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolCCCErrCode.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolCCCErrCode.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCCCErrCode", () => {
    // START_USER_CODE-USER_txtcolCCCErrCode_TEST
    // END_USER_CODE-USER_txtcolCCCErrCode_TEST
  });
  test("gridEWRViewRRI_txtcolCCCLoanApplDt(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolCCCLoanApplDt = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolCCCLoanApplDtbtn =
      gridEWRViewRRI_txtcolCCCLoanApplDt.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolCCCLoanApplDt =
      gridEWRViewRRI_txtcolCCCLoanApplDt.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolCCCLoanApplDt.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolCCCLoanApplDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCCCLoanApplDt", () => {
    // START_USER_CODE-USER_txtcolCCCLoanApplDt_TEST
    // END_USER_CODE-USER_txtcolCCCLoanApplDt_TEST
  });
  test("gridEWRViewRRI_txtcolCCCLoanDispDt(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolCCCLoanDispDt = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolCCCLoanDispDtbtn =
      gridEWRViewRRI_txtcolCCCLoanDispDt.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolCCCLoanDispDt =
      gridEWRViewRRI_txtcolCCCLoanDispDt.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolCCCLoanDispDt.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolCCCLoanDispDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCCCLoanDispDt", () => {
    // START_USER_CODE-USER_txtcolCCCLoanDispDt_TEST
    // END_USER_CODE-USER_txtcolCCCLoanDispDt_TEST
  });
  test("gridEWRViewRRI_txtcolCCCLoanRecvDt(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolCCCLoanRecvDt = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolCCCLoanRecvDtbtn =
      gridEWRViewRRI_txtcolCCCLoanRecvDt.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolCCCLoanRecvDt =
      gridEWRViewRRI_txtcolCCCLoanRecvDt.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolCCCLoanRecvDt.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolCCCLoanRecvDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCCCLoanRecvDt", () => {
    // START_USER_CODE-USER_txtcolCCCLoanRecvDt_TEST
    // END_USER_CODE-USER_txtcolCCCLoanRecvDt_TEST
  });
  test("gridEWRViewRRI_txtcolCCCLoanRepayDt(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolCCCLoanRepayDt = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolCCCLoanRepayDtbtn =
      gridEWRViewRRI_txtcolCCCLoanRepayDt.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolCCCLoanRepayDt =
      gridEWRViewRRI_txtcolCCCLoanRepayDt.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolCCCLoanRepayDt.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolCCCLoanRepayDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCCCLoanRepayDt", () => {
    // START_USER_CODE-USER_txtcolCCCLoanRepayDt_TEST
    // END_USER_CODE-USER_txtcolCCCLoanRepayDt_TEST
  });
  test("gridEWRViewRRI_txtcolCCCLoanStatus(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolCCCLoanStatus = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolCCCLoanStatusbtn =
      gridEWRViewRRI_txtcolCCCLoanStatus.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolCCCLoanStatus =
      gridEWRViewRRI_txtcolCCCLoanStatus.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolCCCLoanStatus.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolCCCLoanStatus.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCCCLoanStatus", () => {
    // START_USER_CODE-USER_txtcolCCCLoanStatus_TEST
    // END_USER_CODE-USER_txtcolCCCLoanStatus_TEST
  });
  test("gridEWRViewRRI_txtcolClnChrg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolClnChrg = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolClnChrgbtn =
      gridEWRViewRRI_txtcolClnChrg.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolClnChrg =
      gridEWRViewRRI_txtcolClnChrg.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolClnChrg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolClnChrg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolClnChrg", () => {
    // START_USER_CODE-USER_txtcolClnChrg_TEST
    // END_USER_CODE-USER_txtcolClnChrg_TEST
  });
  test("gridEWRViewRRI_txtcolClnDryChrg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolClnDryChrg = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolClnDryChrgbtn =
      gridEWRViewRRI_txtcolClnDryChrg.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolClnDryChrg =
      gridEWRViewRRI_txtcolClnDryChrg.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolClnDryChrg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolClnDryChrg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolClnDryChrg", () => {
    // START_USER_CODE-USER_txtcolClnDryChrg_TEST
    // END_USER_CODE-USER_txtcolClnDryChrg_TEST
  });
  test("gridEWRViewRRI_txtcolCropYr(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolCropYr = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolCropYrbtn =
      gridEWRViewRRI_txtcolCropYr.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolCropYr =
      gridEWRViewRRI_txtcolCropYr.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolCropYr.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolCropYr.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCropYr", () => {
    // START_USER_CODE-USER_txtcolCropYr_TEST
    // END_USER_CODE-USER_txtcolCropYr_TEST
  });
  test("gridEWRViewRRI_txtcolCurrHolder(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolCurrHolder = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolCurrHolderbtn =
      gridEWRViewRRI_txtcolCurrHolder.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolCurrHolder =
      gridEWRViewRRI_txtcolCurrHolder.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolCurrHolder.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolCurrHolder.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCurrHolder", () => {
    // START_USER_CODE-USER_txtcolCurrHolder_TEST
    // END_USER_CODE-USER_txtcolCurrHolder_TEST
  });
  test("gridEWRViewRRI_txtcolCurrHolderName(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolCurrHolderName = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolCurrHolderNamebtn =
      gridEWRViewRRI_txtcolCurrHolderName.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolCurrHolderName =
      gridEWRViewRRI_txtcolCurrHolderName.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolCurrHolderName.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolCurrHolderName.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCurrHolderName", () => {
    // START_USER_CODE-USER_txtcolCurrHolderName_TEST
    // END_USER_CODE-USER_txtcolCurrHolderName_TEST
  });
  test("gridEWRViewRRI_txtcolDamSplitPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolDamSplitPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolDamSplitPctbtn =
      gridEWRViewRRI_txtcolDamSplitPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolDamSplitPct =
      gridEWRViewRRI_txtcolDamSplitPct.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolDamSplitPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolDamSplitPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDamSplitPct", () => {
    // START_USER_CODE-USER_txtcolDamSplitPct_TEST
    // END_USER_CODE-USER_txtcolDamSplitPct_TEST
  });
  test("gridEWRViewRRI_txtcolDateCancelled(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolDateCancelled = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolDateCancelledbtn =
      gridEWRViewRRI_txtcolDateCancelled.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolDateCancelled =
      gridEWRViewRRI_txtcolDateCancelled.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolDateCancelled.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolDateCancelled.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDateCancelled", () => {
    // START_USER_CODE-USER_txtcolDateCancelled_TEST
    // END_USER_CODE-USER_txtcolDateCancelled_TEST
  });
  test("gridEWRViewRRI_txtcolDateCurrHolder(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolDateCurrHolder = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolDateCurrHolderbtn =
      gridEWRViewRRI_txtcolDateCurrHolder.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolDateCurrHolder =
      gridEWRViewRRI_txtcolDateCurrHolder.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolDateCurrHolder.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolDateCurrHolder.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDateCurrHolder", () => {
    // START_USER_CODE-USER_txtcolDateCurrHolder_TEST
    // END_USER_CODE-USER_txtcolDateCurrHolder_TEST
  });
  test("gridEWRViewRRI_txtcolDateIssued(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolDateIssued = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolDateIssuedbtn =
      gridEWRViewRRI_txtcolDateIssued.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolDateIssued =
      gridEWRViewRRI_txtcolDateIssued.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolDateIssued.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolDateIssued.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDateIssued", () => {
    // START_USER_CODE-USER_txtcolDateIssued_TEST
    // END_USER_CODE-USER_txtcolDateIssued_TEST
  });
  test("gridEWRViewRRI_txtcolDateLastAppvRej(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolDateLastAppvRej = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolDateLastAppvRejbtn =
      gridEWRViewRRI_txtcolDateLastAppvRej.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolDateLastAppvRej =
      gridEWRViewRRI_txtcolDateLastAppvRej.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolDateLastAppvRej.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolDateLastAppvRej.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDateLastAppvRej", () => {
    // START_USER_CODE-USER_txtcolDateLastAppvRej_TEST
    // END_USER_CODE-USER_txtcolDateLastAppvRej_TEST
  });
  test("gridEWRViewRRI_txtcolDateLastChg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolDateLastChg = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolDateLastChgbtn =
      gridEWRViewRRI_txtcolDateLastChg.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolDateLastChg =
      gridEWRViewRRI_txtcolDateLastChg.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolDateLastChg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolDateLastChg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDateLastChg", () => {
    // START_USER_CODE-USER_txtcolDateLastChg_TEST
    // END_USER_CODE-USER_txtcolDateLastChg_TEST
  });
  test("gridEWRViewRRI_txtcolDateLoanReleased(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolDateLoanReleased = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolDateLoanReleasedbtn =
      gridEWRViewRRI_txtcolDateLoanReleased.nextElementSibling
        .firstElementChild;
    gridEWRViewRRI_txtcolDateLoanReleased =
      gridEWRViewRRI_txtcolDateLoanReleased.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolDateLoanReleased.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolDateLoanReleased.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDateLoanReleased", () => {
    // START_USER_CODE-USER_txtcolDateLoanReleased_TEST
    // END_USER_CODE-USER_txtcolDateLoanReleased_TEST
  });
  test("gridEWRViewRRI_txtcolDatePrevHolderChg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolDatePrevHolderChg = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolDatePrevHolderChgbtn =
      gridEWRViewRRI_txtcolDatePrevHolderChg.nextElementSibling
        .firstElementChild;
    gridEWRViewRRI_txtcolDatePrevHolderChg =
      gridEWRViewRRI_txtcolDatePrevHolderChg.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolDatePrevHolderChg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolDatePrevHolderChg.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDatePrevHolderChg", () => {
    // START_USER_CODE-USER_txtcolDatePrevHolderChg_TEST
    // END_USER_CODE-USER_txtcolDatePrevHolderChg_TEST
  });
  test("gridEWRViewRRI_txtcolDownloadDate(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolDownloadDate = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolDownloadDatebtn =
      gridEWRViewRRI_txtcolDownloadDate.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolDownloadDate =
      gridEWRViewRRI_txtcolDownloadDate.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolDownloadDate.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolDownloadDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDownloadDate", () => {
    // START_USER_CODE-USER_txtcolDownloadDate_TEST
    // END_USER_CODE-USER_txtcolDownloadDate_TEST
  });
  test("gridEWRViewRRI_txtcolDownloadTime(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolDownloadTime = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolDownloadTimebtn =
      gridEWRViewRRI_txtcolDownloadTime.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolDownloadTime =
      gridEWRViewRRI_txtcolDownloadTime.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolDownloadTime.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolDownloadTime.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDownloadTime", () => {
    // START_USER_CODE-USER_txtcolDownloadTime_TEST
    // END_USER_CODE-USER_txtcolDownloadTime_TEST
  });
  test("gridEWRViewRRI_txtcolDPSCnty(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolDPSCnty = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolDPSCntybtn =
      gridEWRViewRRI_txtcolDPSCnty.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolDPSCnty =
      gridEWRViewRRI_txtcolDPSCnty.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolDPSCnty.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolDPSCnty.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDPSCnty", () => {
    // START_USER_CODE-USER_txtcolDPSCnty_TEST
    // END_USER_CODE-USER_txtcolDPSCnty_TEST
  });
  test("gridEWRViewRRI_txtcolDPSSt(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolDPSSt = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolDPSStbtn =
      gridEWRViewRRI_txtcolDPSSt.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolDPSSt =
      gridEWRViewRRI_txtcolDPSSt.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolDPSSt.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolDPSSt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDPSSt", () => {
    // START_USER_CODE-USER_txtcolDPSSt_TEST
    // END_USER_CODE-USER_txtcolDPSSt_TEST
  });
  test("gridEWRViewRRI_txtcolDryChrg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolDryChrg = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolDryChrgbtn =
      gridEWRViewRRI_txtcolDryChrg.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolDryChrg =
      gridEWRViewRRI_txtcolDryChrg.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolDryChrg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolDryChrg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDryChrg", () => {
    // START_USER_CODE-USER_txtcolDryChrg_TEST
    // END_USER_CODE-USER_txtcolDryChrg_TEST
  });
  test("gridEWRViewRRI_txtcolErrCode(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolErrCode = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolErrCodebtn =
      gridEWRViewRRI_txtcolErrCode.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolErrCode =
      gridEWRViewRRI_txtcolErrCode.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolErrCode.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolErrCode.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolErrCode", () => {
    // START_USER_CODE-USER_txtcolErrCode_TEST
    // END_USER_CODE-USER_txtcolErrCode_TEST
  });
  test("gridEWRViewRRI_txtcolErrText(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolErrText = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolErrTextbtn =
      gridEWRViewRRI_txtcolErrText.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolErrText =
      gridEWRViewRRI_txtcolErrText.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolErrText.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolErrText.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolErrText", () => {
    // START_USER_CODE-USER_txtcolErrText_TEST
    // END_USER_CODE-USER_txtcolErrText_TEST
  });
  test("gridEWRViewRRI_txtcolFarmCnty(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolFarmCnty = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolFarmCntybtn =
      gridEWRViewRRI_txtcolFarmCnty.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolFarmCnty =
      gridEWRViewRRI_txtcolFarmCnty.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolFarmCnty.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolFarmCnty.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFarmCnty", () => {
    // START_USER_CODE-USER_txtcolFarmCnty_TEST
    // END_USER_CODE-USER_txtcolFarmCnty_TEST
  });
  test("gridEWRViewRRI_txtcolFarmNum(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolFarmNum = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolFarmNumbtn =
      gridEWRViewRRI_txtcolFarmNum.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolFarmNum =
      gridEWRViewRRI_txtcolFarmNum.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolFarmNum.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolFarmNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFarmNum", () => {
    // START_USER_CODE-USER_txtcolFarmNum_TEST
    // END_USER_CODE-USER_txtcolFarmNum_TEST
  });
  test("gridEWRViewRRI_txtcolFarmSt(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolFarmSt = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolFarmStbtn =
      gridEWRViewRRI_txtcolFarmSt.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolFarmSt =
      gridEWRViewRRI_txtcolFarmSt.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolFarmSt.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolFarmSt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFarmSt", () => {
    // START_USER_CODE-USER_txtcolFarmSt_TEST
    // END_USER_CODE-USER_txtcolFarmSt_TEST
  });
  test("gridEWRViewRRI_txtcolFlavFound(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolFlavFound = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolFlavFoundbtn =
      gridEWRViewRRI_txtcolFlavFound.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolFlavFound =
      gridEWRViewRRI_txtcolFlavFound.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolFlavFound.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolFlavFound.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFlavFound", () => {
    // START_USER_CODE-USER_txtcolFlavFound_TEST
    // END_USER_CODE-USER_txtcolFlavFound_TEST
  });
  test("gridEWRViewRRI_txtcolFMPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolFMPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolFMPctbtn =
      gridEWRViewRRI_txtcolFMPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolFMPct =
      gridEWRViewRRI_txtcolFMPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolFMPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolFMPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFMPct", () => {
    // START_USER_CODE-USER_txtcolFMPct_TEST
    // END_USER_CODE-USER_txtcolFMPct_TEST
  });
  test("gridEWRViewRRI_txtcolFromHolder(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolFromHolder = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolFromHolderbtn =
      gridEWRViewRRI_txtcolFromHolder.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolFromHolder =
      gridEWRViewRRI_txtcolFromHolder.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolFromHolder.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolFromHolder.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFromHolder", () => {
    // START_USER_CODE-USER_txtcolFromHolder_TEST
    // END_USER_CODE-USER_txtcolFromHolder_TEST
  });
  test("gridEWRViewRRI_txtcolFrzPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolFrzPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolFrzPctbtn =
      gridEWRViewRRI_txtcolFrzPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolFrzPct =
      gridEWRViewRRI_txtcolFrzPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolFrzPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolFrzPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFrzPct", () => {
    // START_USER_CODE-USER_txtcolFrzPct_TEST
    // END_USER_CODE-USER_txtcolFrzPct_TEST
  });
  test("gridEWRViewRRI_txtcolGraded(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolGraded = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolGradedbtn =
      gridEWRViewRRI_txtcolGraded.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolGraded =
      gridEWRViewRRI_txtcolGraded.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolGraded.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolGraded.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGraded", () => {
    // START_USER_CODE-USER_txtcolGraded_TEST
    // END_USER_CODE-USER_txtcolGraded_TEST
  });
  test("gridEWRViewRRI_txtcolGrowHolder(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolGrowHolder = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolGrowHolderbtn =
      gridEWRViewRRI_txtcolGrowHolder.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolGrowHolder =
      gridEWRViewRRI_txtcolGrowHolder.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolGrowHolder.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolGrowHolder.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGrowHolder", () => {
    // START_USER_CODE-USER_txtcolGrowHolder_TEST
    // END_USER_CODE-USER_txtcolGrowHolder_TEST
  });
  test("gridEWRViewRRI_txtcolHaulChrg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolHaulChrg = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolHaulChrgbtn =
      gridEWRViewRRI_txtcolHaulChrg.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolHaulChrg =
      gridEWRViewRRI_txtcolHaulChrg.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolHaulChrg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolHaulChrg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolHaulChrg", () => {
    // START_USER_CODE-USER_txtcolHaulChrg_TEST
    // END_USER_CODE-USER_txtcolHaulChrg_TEST
  });
  test("gridEWRViewRRI_txtcolHullPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolHullPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolHullPctbtn =
      gridEWRViewRRI_txtcolHullPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolHullPct =
      gridEWRViewRRI_txtcolHullPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolHullPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolHullPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolHullPct", () => {
    // START_USER_CODE-USER_txtcolHullPct_TEST
    // END_USER_CODE-USER_txtcolHullPct_TEST
  });
  test("gridEWRViewRRI_txtcolInsured(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolInsured = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolInsuredbtn =
      gridEWRViewRRI_txtcolInsured.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolInsured =
      gridEWRViewRRI_txtcolInsured.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolInsured.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolInsured.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInsured", () => {
    // START_USER_CODE-USER_txtcolInsured_TEST
    // END_USER_CODE-USER_txtcolInsured_TEST
  });
  test("gridEWRViewRRI_txtcolLicNum(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolLicNum = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolLicNumbtn =
      gridEWRViewRRI_txtcolLicNum.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolLicNum =
      gridEWRViewRRI_txtcolLicNum.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolLicNum.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolLicNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLicNum", () => {
    // START_USER_CODE-USER_txtcolLicNum_TEST
    // END_USER_CODE-USER_txtcolLicNum_TEST
  });
  test("gridEWRViewRRI_txtcolLicType(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolLicType = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolLicTypebtn =
      gridEWRViewRRI_txtcolLicType.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolLicType =
      gridEWRViewRRI_txtcolLicType.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolLicType.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolLicType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLicType", () => {
    // START_USER_CODE-USER_txtcolLicType_TEST
    // END_USER_CODE-USER_txtcolLicType_TEST
  });
  test("gridEWRViewRRI_txtcolLoanable(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolLoanable = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolLoanablebtn =
      gridEWRViewRRI_txtcolLoanable.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolLoanable =
      gridEWRViewRRI_txtcolLoanable.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolLoanable.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolLoanable.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoanable", () => {
    // START_USER_CODE-USER_txtcolLoanable_TEST
    // END_USER_CODE-USER_txtcolLoanable_TEST
  });
  test("gridEWRViewRRI_txtcolLoanLDPType(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolLoanLDPType = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolLoanLDPTypebtn =
      gridEWRViewRRI_txtcolLoanLDPType.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolLoanLDPType =
      gridEWRViewRRI_txtcolLoanLDPType.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolLoanLDPType.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolLoanLDPType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoanLDPType", () => {
    // START_USER_CODE-USER_txtcolLoanLDPType_TEST
    // END_USER_CODE-USER_txtcolLoanLDPType_TEST
  });
  test("gridEWRViewRRI_txtcolLoanNum(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolLoanNum = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolLoanNumbtn =
      gridEWRViewRRI_txtcolLoanNum.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolLoanNum =
      gridEWRViewRRI_txtcolLoanNum.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolLoanNum.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolLoanNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoanNum", () => {
    // START_USER_CODE-USER_txtcolLoanNum_TEST
    // END_USER_CODE-USER_txtcolLoanNum_TEST
  });
  test("gridEWRViewRRI_txtcolLotNum(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolLotNum = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolLotNumbtn =
      gridEWRViewRRI_txtcolLotNum.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolLotNum =
      gridEWRViewRRI_txtcolLotNum.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolLotNum.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolLotNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLotNum", () => {
    // START_USER_CODE-USER_txtcolLotNum_TEST
    // END_USER_CODE-USER_txtcolLotNum_TEST
  });
  test("gridEWRViewRRI_txtcolLSKLbs(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolLSKLbs = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolLSKLbsbtn =
      gridEWRViewRRI_txtcolLSKLbs.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolLSKLbs =
      gridEWRViewRRI_txtcolLSKLbs.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolLSKLbs.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolLSKLbs.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLSKLbs", () => {
    // START_USER_CODE-USER_txtcolLSKLbs_TEST
    // END_USER_CODE-USER_txtcolLSKLbs_TEST
  });
  test("gridEWRViewRRI_txtcolLskTons(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolLskTons = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolLskTonsbtn =
      gridEWRViewRRI_txtcolLskTons.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolLskTons =
      gridEWRViewRRI_txtcolLskTons.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolLskTons.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolLskTons.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLskTons", () => {
    // START_USER_CODE-USER_txtcolLskTons_TEST
    // END_USER_CODE-USER_txtcolLskTons_TEST
  });
  test("gridEWRViewRRI_txtcolMoistPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolMoistPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolMoistPctbtn =
      gridEWRViewRRI_txtcolMoistPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolMoistPct =
      gridEWRViewRRI_txtcolMoistPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolMoistPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolMoistPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMoistPct", () => {
    // START_USER_CODE-USER_txtcolMoistPct_TEST
    // END_USER_CODE-USER_txtcolMoistPct_TEST
  });
  test("gridEWRViewRRI_txtcolNegot(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolNegot = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolNegotbtn =
      gridEWRViewRRI_txtcolNegot.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolNegot =
      gridEWRViewRRI_txtcolNegot.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolNegot.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolNegot.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNegot", () => {
    // START_USER_CODE-USER_txtcolNegot_TEST
    // END_USER_CODE-USER_txtcolNegot_TEST
  });
  test("gridEWRViewRRI_txtcolNetLbs(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolNetLbs = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolNetLbsbtn =
      gridEWRViewRRI_txtcolNetLbs.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolNetLbs =
      gridEWRViewRRI_txtcolNetLbs.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolNetLbs.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolNetLbs.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNetLbs", () => {
    // START_USER_CODE-USER_txtcolNetLbs_TEST
    // END_USER_CODE-USER_txtcolNetLbs_TEST
  });
  test("gridEWRViewRRI_txtcolNetTons(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolNetTons = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolNetTonsbtn =
      gridEWRViewRRI_txtcolNetTons.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolNetTons =
      gridEWRViewRRI_txtcolNetTons.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolNetTons.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolNetTons.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNetTons", () => {
    // START_USER_CODE-USER_txtcolNetTons_TEST
    // END_USER_CODE-USER_txtcolNetTons_TEST
  });
  test("gridEWRViewRRI_txtcolNum(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolNum = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolNumbtn =
      gridEWRViewRRI_txtcolNum.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolNum =
      gridEWRViewRRI_txtcolNum.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolNum.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNum", () => {
    // START_USER_CODE-USER_txtcolNum_TEST
    // END_USER_CODE-USER_txtcolNum_TEST
  });
  test("gridEWRViewRRI_txtcolOthChrg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolOthChrg = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolOthChrgbtn =
      gridEWRViewRRI_txtcolOthChrg.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolOthChrg =
      gridEWRViewRRI_txtcolOthChrg.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolOthChrg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolOthChrg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOthChrg", () => {
    // START_USER_CODE-USER_txtcolOthChrg_TEST
    // END_USER_CODE-USER_txtcolOthChrg_TEST
  });
  test("gridEWRViewRRI_txtcolOthKernPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolOthKernPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolOthKernPctbtn =
      gridEWRViewRRI_txtcolOthKernPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolOthKernPct =
      gridEWRViewRRI_txtcolOthKernPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolOthKernPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolOthKernPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOthKernPct", () => {
    // START_USER_CODE-USER_txtcolOthKernPct_TEST
    // END_USER_CODE-USER_txtcolOthKernPct_TEST
  });
  test("gridEWRViewRRI_txtcolPaperCntlNum(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolPaperCntlNum = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolPaperCntlNumbtn =
      gridEWRViewRRI_txtcolPaperCntlNum.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolPaperCntlNum =
      gridEWRViewRRI_txtcolPaperCntlNum.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolPaperCntlNum.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolPaperCntlNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPaperCntlNum", () => {
    // START_USER_CODE-USER_txtcolPaperCntlNum_TEST
    // END_USER_CODE-USER_txtcolPaperCntlNum_TEST
  });
  test("gridEWRViewRRI_txtcolPaperNum(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolPaperNum = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolPaperNumbtn =
      gridEWRViewRRI_txtcolPaperNum.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolPaperNum =
      gridEWRViewRRI_txtcolPaperNum.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolPaperNum.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolPaperNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPaperNum", () => {
    // START_USER_CODE-USER_txtcolPaperNum_TEST
    // END_USER_CODE-USER_txtcolPaperNum_TEST
  });
  test("gridEWRViewRRI_txtcolParWhseCity(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolParWhseCity = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolParWhseCitybtn =
      gridEWRViewRRI_txtcolParWhseCity.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolParWhseCity =
      gridEWRViewRRI_txtcolParWhseCity.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolParWhseCity.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolParWhseCity.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolParWhseCity", () => {
    // START_USER_CODE-USER_txtcolParWhseCity_TEST
    // END_USER_CODE-USER_txtcolParWhseCity_TEST
  });
  test("gridEWRViewRRI_txtcolParWhseSt(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolParWhseSt = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolParWhseStbtn =
      gridEWRViewRRI_txtcolParWhseSt.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolParWhseSt =
      gridEWRViewRRI_txtcolParWhseSt.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolParWhseSt.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolParWhseSt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolParWhseSt", () => {
    // START_USER_CODE-USER_txtcolParWhseSt_TEST
    // END_USER_CODE-USER_txtcolParWhseSt_TEST
  });
  test("gridEWRViewRRI_txtcolPnutType(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolPnutType = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolPnutTypebtn =
      gridEWRViewRRI_txtcolPnutType.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolPnutType =
      gridEWRViewRRI_txtcolPnutType.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolPnutType.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolPnutType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutType", () => {
    // START_USER_CODE-USER_txtcolPnutType_TEST
    // END_USER_CODE-USER_txtcolPnutType_TEST
  });
  test("gridEWRViewRRI_txtcolPrepaidInCharge(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolPrepaidInCharge = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolPrepaidInChargebtn =
      gridEWRViewRRI_txtcolPrepaidInCharge.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolPrepaidInCharge =
      gridEWRViewRRI_txtcolPrepaidInCharge.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolPrepaidInCharge.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolPrepaidInCharge.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPrepaidInCharge", () => {
    // START_USER_CODE-USER_txtcolPrepaidInCharge_TEST
    // END_USER_CODE-USER_txtcolPrepaidInCharge_TEST
  });
  test("gridEWRViewRRI_txtcolPrepaidOutCharge(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolPrepaidOutCharge = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolPrepaidOutChargebtn =
      gridEWRViewRRI_txtcolPrepaidOutCharge.nextElementSibling
        .firstElementChild;
    gridEWRViewRRI_txtcolPrepaidOutCharge =
      gridEWRViewRRI_txtcolPrepaidOutCharge.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolPrepaidOutCharge.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolPrepaidOutCharge.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPrepaidOutCharge", () => {
    // START_USER_CODE-USER_txtcolPrepaidOutCharge_TEST
    // END_USER_CODE-USER_txtcolPrepaidOutCharge_TEST
  });
  test("gridEWRViewRRI_txtcolPrevHolderName(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolPrevHolderName = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolPrevHolderNamebtn =
      gridEWRViewRRI_txtcolPrevHolderName.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolPrevHolderName =
      gridEWRViewRRI_txtcolPrevHolderName.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolPrevHolderName.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolPrevHolderName.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPrevHolderName", () => {
    // START_USER_CODE-USER_txtcolPrevHolderName_TEST
    // END_USER_CODE-USER_txtcolPrevHolderName_TEST
  });
  test("gridEWRViewRRI_txtcolPrevSubholder(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolPrevSubholder = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolPrevSubholderbtn =
      gridEWRViewRRI_txtcolPrevSubholder.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolPrevSubholder =
      gridEWRViewRRI_txtcolPrevSubholder.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolPrevSubholder.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolPrevSubholder.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPrevSubholder", () => {
    // START_USER_CODE-USER_txtcolPrevSubholder_TEST
    // END_USER_CODE-USER_txtcolPrevSubholder_TEST
  });
  test("gridEWRViewRRI_txtcolProdName(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolProdName = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolProdNamebtn =
      gridEWRViewRRI_txtcolProdName.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolProdName =
      gridEWRViewRRI_txtcolProdName.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolProdName.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolProdName.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProdName", () => {
    // START_USER_CODE-USER_txtcolProdName_TEST
    // END_USER_CODE-USER_txtcolProdName_TEST
  });
  test("gridEWRViewRRI_txtcolRcptNum(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolRcptNum = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolRcptNumbtn =
      gridEWRViewRRI_txtcolRcptNum.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolRcptNum =
      gridEWRViewRRI_txtcolRcptNum.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolRcptNum.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolRcptNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRcptNum", () => {
    // START_USER_CODE-USER_txtcolRcptNum_TEST
    // END_USER_CODE-USER_txtcolRcptNum_TEST
  });
  test("gridEWRViewRRI_txtcolRcptStatus(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolRcptStatus = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolRcptStatusbtn =
      gridEWRViewRRI_txtcolRcptStatus.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolRcptStatus =
      gridEWRViewRRI_txtcolRcptStatus.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolRcptStatus.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolRcptStatus.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRcptStatus", () => {
    // START_USER_CODE-USER_txtcolRcptStatus_TEST
    // END_USER_CODE-USER_txtcolRcptStatus_TEST
  });
  test("gridEWRViewRRI_txtcolRcptType(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolRcptType = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolRcptTypebtn =
      gridEWRViewRRI_txtcolRcptType.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolRcptType =
      gridEWRViewRRI_txtcolRcptType.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolRcptType.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolRcptType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRcptType", () => {
    // START_USER_CODE-USER_txtcolRcptType_TEST
    // END_USER_CODE-USER_txtcolRcptType_TEST
  });
  test("gridEWRViewRRI_txtcolRcptVal(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolRcptVal = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolRcptValbtn =
      gridEWRViewRRI_txtcolRcptVal.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolRcptVal =
      gridEWRViewRRI_txtcolRcptVal.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolRcptVal.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolRcptVal.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRcptVal", () => {
    // START_USER_CODE-USER_txtcolRcptVal_TEST
    // END_USER_CODE-USER_txtcolRcptVal_TEST
  });
  test("gridEWRViewRRI_txtcolRecvChrgAmt(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolRecvChrgAmt = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolRecvChrgAmtbtn =
      gridEWRViewRRI_txtcolRecvChrgAmt.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolRecvChrgAmt =
      gridEWRViewRRI_txtcolRecvChrgAmt.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolRecvChrgAmt.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolRecvChrgAmt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecvChrgAmt", () => {
    // START_USER_CODE-USER_txtcolRecvChrgAmt_TEST
    // END_USER_CODE-USER_txtcolRecvChrgAmt_TEST
  });
  test("gridEWRViewRRI_txtcolRecvChrgPaid(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolRecvChrgPaid = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolRecvChrgPaidbtn =
      gridEWRViewRRI_txtcolRecvChrgPaid.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolRecvChrgPaid =
      gridEWRViewRRI_txtcolRecvChrgPaid.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolRecvChrgPaid.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolRecvChrgPaid.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecvChrgPaid", () => {
    // START_USER_CODE-USER_txtcolRecvChrgPaid_TEST
    // END_USER_CODE-USER_txtcolRecvChrgPaid_TEST
  });
  test("gridEWRViewRRI_txtcolRecvChrgPer(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolRecvChrgPer = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolRecvChrgPerbtn =
      gridEWRViewRRI_txtcolRecvChrgPer.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolRecvChrgPer =
      gridEWRViewRRI_txtcolRecvChrgPer.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolRecvChrgPer.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolRecvChrgPer.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecvChrgPer", () => {
    // START_USER_CODE-USER_txtcolRecvChrgPer_TEST
    // END_USER_CODE-USER_txtcolRecvChrgPer_TEST
  });
  test("gridEWRViewRRI_txtcolRecvdCity(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolRecvdCity = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolRecvdCitybtn =
      gridEWRViewRRI_txtcolRecvdCity.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolRecvdCity =
      gridEWRViewRRI_txtcolRecvdCity.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolRecvdCity.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolRecvdCity.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecvdCity", () => {
    // START_USER_CODE-USER_txtcolRecvdCity_TEST
    // END_USER_CODE-USER_txtcolRecvdCity_TEST
  });
  test("gridEWRViewRRI_txtcolRecvdFrom(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolRecvdFrom = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolRecvdFrombtn =
      gridEWRViewRRI_txtcolRecvdFrom.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolRecvdFrom =
      gridEWRViewRRI_txtcolRecvdFrom.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolRecvdFrom.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolRecvdFrom.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecvdFrom", () => {
    // START_USER_CODE-USER_txtcolRecvdFrom_TEST
    // END_USER_CODE-USER_txtcolRecvdFrom_TEST
  });
  test("gridEWRViewRRI_txtcolRecvdSt(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolRecvdSt = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolRecvdStbtn =
      gridEWRViewRRI_txtcolRecvdSt.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolRecvdSt =
      gridEWRViewRRI_txtcolRecvdSt.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolRecvdSt.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolRecvdSt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRecvdSt", () => {
    // START_USER_CODE-USER_txtcolRecvdSt_TEST
    // END_USER_CODE-USER_txtcolRecvdSt_TEST
  });
  test("gridEWRViewRRI_txtcolRmdPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolRmdPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolRmdPctbtn =
      gridEWRViewRRI_txtcolRmdPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolRmdPct =
      gridEWRViewRRI_txtcolRmdPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolRmdPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolRmdPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRmdPct", () => {
    // START_USER_CODE-USER_txtcolRmdPct_TEST
    // END_USER_CODE-USER_txtcolRmdPct_TEST
  });
  test("gridEWRViewRRI_txtcolSeg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolSeg = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolSegbtn =
      gridEWRViewRRI_txtcolSeg.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolSeg =
      gridEWRViewRRI_txtcolSeg.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolSeg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolSeg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg", () => {
    // START_USER_CODE-USER_txtcolSeg_TEST
    // END_USER_CODE-USER_txtcolSeg_TEST
  });
  test("gridEWRViewRRI_txtcolSmkrdPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolSmkrdPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolSmkrdPctbtn =
      gridEWRViewRRI_txtcolSmkrdPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolSmkrdPct =
      gridEWRViewRRI_txtcolSmkrdPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolSmkrdPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolSmkrdPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSmkrdPct", () => {
    // START_USER_CODE-USER_txtcolSmkrdPct_TEST
    // END_USER_CODE-USER_txtcolSmkrdPct_TEST
  });
  test("gridEWRViewRRI_txtcolSSPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolSSPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolSSPctbtn =
      gridEWRViewRRI_txtcolSSPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolSSPct =
      gridEWRViewRRI_txtcolSSPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolSSPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolSSPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSSPct", () => {
    // START_USER_CODE-USER_txtcolSSPct_TEST
    // END_USER_CODE-USER_txtcolSSPct_TEST
  });
  test("gridEWRViewRRI_txtcolStgCont(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolStgCont = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolStgContbtn =
      gridEWRViewRRI_txtcolStgCont.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolStgCont =
      gridEWRViewRRI_txtcolStgCont.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolStgCont.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolStgCont.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStgCont", () => {
    // START_USER_CODE-USER_txtcolStgCont_TEST
    // END_USER_CODE-USER_txtcolStgCont_TEST
  });
  test("gridEWRViewRRI_txtcolStgDate(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolStgDate = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolStgDatebtn =
      gridEWRViewRRI_txtcolStgDate.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolStgDate =
      gridEWRViewRRI_txtcolStgDate.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolStgDate.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolStgDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStgDate", () => {
    // START_USER_CODE-USER_txtcolStgDate_TEST
    // END_USER_CODE-USER_txtcolStgDate_TEST
  });
  test("gridEWRViewRRI_txtcolStgStatus(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolStgStatus = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolStgStatusbtn =
      gridEWRViewRRI_txtcolStgStatus.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolStgStatus =
      gridEWRViewRRI_txtcolStgStatus.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolStgStatus.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolStgStatus.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStgStatus", () => {
    // START_USER_CODE-USER_txtcolStgStatus_TEST
    // END_USER_CODE-USER_txtcolStgStatus_TEST
  });
  test("gridEWRViewRRI_txtcolStorageCharge(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolStorageCharge = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolStorageChargebtn =
      gridEWRViewRRI_txtcolStorageCharge.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolStorageCharge =
      gridEWRViewRRI_txtcolStorageCharge.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolStorageCharge.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolStorageCharge.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStorageCharge", () => {
    // START_USER_CODE-USER_txtcolStorageCharge_TEST
    // END_USER_CODE-USER_txtcolStorageCharge_TEST
  });
  test("gridEWRViewRRI_txtcolStoragePaidThroughDate(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolStoragePaidThroughDate = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolStoragePaidThroughDatebtn =
      gridEWRViewRRI_txtcolStoragePaidThroughDate.nextElementSibling
        .firstElementChild;
    gridEWRViewRRI_txtcolStoragePaidThroughDate =
      gridEWRViewRRI_txtcolStoragePaidThroughDate.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolStoragePaidThroughDate.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolStoragePaidThroughDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStoragePaidThroughDate", () => {
    // START_USER_CODE-USER_txtcolStoragePaidThroughDate_TEST
    // END_USER_CODE-USER_txtcolStoragePaidThroughDate_TEST
  });
  test("gridEWRViewRRI_txtcolSubholder(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolSubholder = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolSubholderbtn =
      gridEWRViewRRI_txtcolSubholder.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolSubholder =
      gridEWRViewRRI_txtcolSubholder.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolSubholder.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolSubholder.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSubholder", () => {
    // START_USER_CODE-USER_txtcolSubholder_TEST
    // END_USER_CODE-USER_txtcolSubholder_TEST
  });
  test("gridEWRViewRRI_txtcolToHolder(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolToHolder = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolToHolderbtn =
      gridEWRViewRRI_txtcolToHolder.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolToHolder =
      gridEWRViewRRI_txtcolToHolder.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolToHolder.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolToHolder.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolToHolder", () => {
    // START_USER_CODE-USER_txtcolToHolder_TEST
    // END_USER_CODE-USER_txtcolToHolder_TEST
  });
  test("gridEWRViewRRI_txtcolTotalLbs(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTotalLbs = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolTotalLbsbtn =
      gridEWRViewRRI_txtcolTotalLbs.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolTotalLbs =
      gridEWRViewRRI_txtcolTotalLbs.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolTotalLbs.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTotalLbs.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotalLbs", () => {
    // START_USER_CODE-USER_txtcolTotalLbs_TEST
    // END_USER_CODE-USER_txtcolTotalLbs_TEST
  });
  test("gridEWRViewRRI_txtcolTotalPrepaidInOutCharges(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTotalPrepaidInOutCharges = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolTotalPrepaidInOutChargesbtn =
      gridEWRViewRRI_txtcolTotalPrepaidInOutCharges.nextElementSibling
        .firstElementChild;
    gridEWRViewRRI_txtcolTotalPrepaidInOutCharges =
      gridEWRViewRRI_txtcolTotalPrepaidInOutCharges.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolTotalPrepaidInOutCharges.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTotalPrepaidInOutCharges.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotalPrepaidInOutCharges", () => {
    // START_USER_CODE-USER_txtcolTotalPrepaidInOutCharges_TEST
    // END_USER_CODE-USER_txtcolTotalPrepaidInOutCharges_TEST
  });
  test("gridEWRViewRRI_txtcolTotalTons(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTotalTons = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolTotalTonsbtn =
      gridEWRViewRRI_txtcolTotalTons.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolTotalTons =
      gridEWRViewRRI_txtcolTotalTons.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolTotalTons.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTotalTons.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotalTons", () => {
    // START_USER_CODE-USER_txtcolTotalTons_TEST
    // END_USER_CODE-USER_txtcolTotalTons_TEST
  });
  test("gridEWRViewRRI_txtcolTotClnChrg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTotClnChrg = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolTotClnChrgbtn =
      gridEWRViewRRI_txtcolTotClnChrg.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolTotClnChrg =
      gridEWRViewRRI_txtcolTotClnChrg.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolTotClnChrg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTotClnChrg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotClnChrg", () => {
    // START_USER_CODE-USER_txtcolTotClnChrg_TEST
    // END_USER_CODE-USER_txtcolTotClnChrg_TEST
  });
  test("gridEWRViewRRI_txtcolTotClnDryChrg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTotClnDryChrg = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolTotClnDryChrgbtn =
      gridEWRViewRRI_txtcolTotClnDryChrg.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolTotClnDryChrg =
      gridEWRViewRRI_txtcolTotClnDryChrg.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolTotClnDryChrg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTotClnDryChrg.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotClnDryChrg", () => {
    // START_USER_CODE-USER_txtcolTotClnDryChrg_TEST
    // END_USER_CODE-USER_txtcolTotClnDryChrg_TEST
  });
  test("gridEWRViewRRI_txtcolTotDamKern(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTotDamKern = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolTotDamKernbtn =
      gridEWRViewRRI_txtcolTotDamKern.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolTotDamKern =
      gridEWRViewRRI_txtcolTotDamKern.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolTotDamKern.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTotDamKern.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotDamKern", () => {
    // START_USER_CODE-USER_txtcolTotDamKern_TEST
    // END_USER_CODE-USER_txtcolTotDamKern_TEST
  });
  test("gridEWRViewRRI_txtcolTotDamPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTotDamPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolTotDamPctbtn =
      gridEWRViewRRI_txtcolTotDamPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolTotDamPct =
      gridEWRViewRRI_txtcolTotDamPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolTotDamPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTotDamPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotDamPct", () => {
    // START_USER_CODE-USER_txtcolTotDamPct_TEST
    // END_USER_CODE-USER_txtcolTotDamPct_TEST
  });
  test("gridEWRViewRRI_txtcolTotDryChrg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTotDryChrg = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolTotDryChrgbtn =
      gridEWRViewRRI_txtcolTotDryChrg.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolTotDryChrg =
      gridEWRViewRRI_txtcolTotDryChrg.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolTotDryChrg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTotDryChrg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotDryChrg", () => {
    // START_USER_CODE-USER_txtcolTotDryChrg_TEST
    // END_USER_CODE-USER_txtcolTotDryChrg_TEST
  });
  test("gridEWRViewRRI_txtcolTotHaulChrg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTotHaulChrg = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolTotHaulChrgbtn =
      gridEWRViewRRI_txtcolTotHaulChrg.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolTotHaulChrg =
      gridEWRViewRRI_txtcolTotHaulChrg.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolTotHaulChrg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTotHaulChrg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotHaulChrg", () => {
    // START_USER_CODE-USER_txtcolTotHaulChrg_TEST
    // END_USER_CODE-USER_txtcolTotHaulChrg_TEST
  });
  test("gridEWRViewRRI_txtcolTotHullKernPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTotHullKernPct = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolTotHullKernPctbtn =
      gridEWRViewRRI_txtcolTotHullKernPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolTotHullKernPct =
      gridEWRViewRRI_txtcolTotHullKernPct.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolTotHullKernPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTotHullKernPct.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotHullKernPct", () => {
    // START_USER_CODE-USER_txtcolTotHullKernPct_TEST
    // END_USER_CODE-USER_txtcolTotHullKernPct_TEST
  });
  test("gridEWRViewRRI_txtcolTotKern(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTotKern = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolTotKernbtn =
      gridEWRViewRRI_txtcolTotKern.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolTotKern =
      gridEWRViewRRI_txtcolTotKern.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolTotKern.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTotKern.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotKern", () => {
    // START_USER_CODE-USER_txtcolTotKern_TEST
    // END_USER_CODE-USER_txtcolTotKern_TEST
  });
  test("gridEWRViewRRI_txtcolTotKernPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTotKernPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolTotKernPctbtn =
      gridEWRViewRRI_txtcolTotKernPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolTotKernPct =
      gridEWRViewRRI_txtcolTotKernPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolTotKernPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTotKernPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotKernPct", () => {
    // START_USER_CODE-USER_txtcolTotKernPct_TEST
    // END_USER_CODE-USER_txtcolTotKernPct_TEST
  });
  test("gridEWRViewRRI_txtcolTotOthChrg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTotOthChrg = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolTotOthChrgbtn =
      gridEWRViewRRI_txtcolTotOthChrg.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolTotOthChrg =
      gridEWRViewRRI_txtcolTotOthChrg.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolTotOthChrg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTotOthChrg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTotOthChrg", () => {
    // START_USER_CODE-USER_txtcolTotOthChrg_TEST
    // END_USER_CODE-USER_txtcolTotOthChrg_TEST
  });
  test("gridEWRViewRRI_txtcolTransmissionNum(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolTransmissionNum = screen.getByTestId(
      "gridEWRViewRRI"
    );
    let gridEWRViewRRI_txtcolTransmissionNumbtn =
      gridEWRViewRRI_txtcolTransmissionNum.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolTransmissionNum =
      gridEWRViewRRI_txtcolTransmissionNum.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolTransmissionNum.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolTransmissionNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTransmissionNum", () => {
    // START_USER_CODE-USER_txtcolTransmissionNum_TEST
    // END_USER_CODE-USER_txtcolTransmissionNum_TEST
  });
  test("gridEWRViewRRI_txtcolUploadFile(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolUploadFile = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolUploadFilebtn =
      gridEWRViewRRI_txtcolUploadFile.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolUploadFile =
      gridEWRViewRRI_txtcolUploadFile.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolUploadFile.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolUploadFile.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUploadFile", () => {
    // START_USER_CODE-USER_txtcolUploadFile_TEST
    // END_USER_CODE-USER_txtcolUploadFile_TEST
  });
  test("gridEWRViewRRI_txtcolUserCancel(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolUserCancel = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolUserCancelbtn =
      gridEWRViewRRI_txtcolUserCancel.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolUserCancel =
      gridEWRViewRRI_txtcolUserCancel.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolUserCancel.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolUserCancel.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUserCancel", () => {
    // START_USER_CODE-USER_txtcolUserCancel_TEST
    // END_USER_CODE-USER_txtcolUserCancel_TEST
  });
  test("gridEWRViewRRI_txtcolUserChg(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolUserChg = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolUserChgbtn =
      gridEWRViewRRI_txtcolUserChg.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolUserChg =
      gridEWRViewRRI_txtcolUserChg.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolUserChg.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolUserChg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUserChg", () => {
    // START_USER_CODE-USER_txtcolUserChg_TEST
    // END_USER_CODE-USER_txtcolUserChg_TEST
  });
  test("gridEWRViewRRI_txtcolUserDefined(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolUserDefined = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolUserDefinedbtn =
      gridEWRViewRRI_txtcolUserDefined.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolUserDefined =
      gridEWRViewRRI_txtcolUserDefined.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolUserDefined.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolUserDefined.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUserDefined", () => {
    // START_USER_CODE-USER_txtcolUserDefined_TEST
    // END_USER_CODE-USER_txtcolUserDefined_TEST
  });
  test("gridEWRViewRRI_txtcolUserIssued(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolUserIssued = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolUserIssuedbtn =
      gridEWRViewRRI_txtcolUserIssued.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolUserIssued =
      gridEWRViewRRI_txtcolUserIssued.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolUserIssued.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolUserIssued.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUserIssued", () => {
    // START_USER_CODE-USER_txtcolUserIssued_TEST
    // END_USER_CODE-USER_txtcolUserIssued_TEST
  });
  test("gridEWRViewRRI_txtcolUserLDP(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolUserLDP = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolUserLDPbtn =
      gridEWRViewRRI_txtcolUserLDP.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolUserLDP =
      gridEWRViewRRI_txtcolUserLDP.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolUserLDP.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolUserLDP.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUserLDP", () => {
    // START_USER_CODE-USER_txtcolUserLDP_TEST
    // END_USER_CODE-USER_txtcolUserLDP_TEST
  });
  test("gridEWRViewRRI_txtcolVaELKPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolVaELKPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolVaELKPctbtn =
      gridEWRViewRRI_txtcolVaELKPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolVaELKPct =
      gridEWRViewRRI_txtcolVaELKPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolVaELKPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolVaELKPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVaELKPct", () => {
    // START_USER_CODE-USER_txtcolVaELKPct_TEST
    // END_USER_CODE-USER_txtcolVaELKPct_TEST
  });
  test("gridEWRViewRRI_txtcolVaFancyPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolVaFancyPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolVaFancyPctbtn =
      gridEWRViewRRI_txtcolVaFancyPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolVaFancyPct =
      gridEWRViewRRI_txtcolVaFancyPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolVaFancyPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolVaFancyPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVaFancyPct", () => {
    // START_USER_CODE-USER_txtcolVaFancyPct_TEST
    // END_USER_CODE-USER_txtcolVaFancyPct_TEST
  });
  test("gridEWRViewRRI_txtcolVICrkedPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolVICrkedPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolVICrkedPctbtn =
      gridEWRViewRRI_txtcolVICrkedPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolVICrkedPct =
      gridEWRViewRRI_txtcolVICrkedPct.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolVICrkedPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolVICrkedPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVICrkedPct", () => {
    // START_USER_CODE-USER_txtcolVICrkedPct_TEST
    // END_USER_CODE-USER_txtcolVICrkedPct_TEST
  });
  test("gridEWRViewRRI_txtcolVIDiscolPct(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolVIDiscolPct = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolVIDiscolPctbtn =
      gridEWRViewRRI_txtcolVIDiscolPct.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolVIDiscolPct =
      gridEWRViewRRI_txtcolVIDiscolPct.parentElement.parentElement
        .parentElement;
    expect(gridEWRViewRRI_txtcolVIDiscolPct.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolVIDiscolPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVIDiscolPct", () => {
    // START_USER_CODE-USER_txtcolVIDiscolPct_TEST
    // END_USER_CODE-USER_txtcolVIDiscolPct_TEST
  });
  test("gridEWRViewRRI_txtcolWhseCode(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolWhseCode = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolWhseCodebtn =
      gridEWRViewRRI_txtcolWhseCode.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolWhseCode =
      gridEWRViewRRI_txtcolWhseCode.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolWhseCode.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolWhseCode.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWhseCode", () => {
    // START_USER_CODE-USER_txtcolWhseCode_TEST
    // END_USER_CODE-USER_txtcolWhseCode_TEST
  });
  test("gridEWRViewRRI_txtcolWhsemanDep(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolWhsemanDep = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolWhsemanDepbtn =
      gridEWRViewRRI_txtcolWhsemanDep.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolWhsemanDep =
      gridEWRViewRRI_txtcolWhsemanDep.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolWhsemanDep.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolWhsemanDep.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWhsemanDep", () => {
    // START_USER_CODE-USER_txtcolWhsemanDep_TEST
    // END_USER_CODE-USER_txtcolWhsemanDep_TEST
  });
  test("gridEWRViewRRI_txtcolWhseOper(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtcolWhseOper = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtcolWhseOperbtn =
      gridEWRViewRRI_txtcolWhseOper.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtcolWhseOper =
      gridEWRViewRRI_txtcolWhseOper.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtcolWhseOper.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtcolWhseOper.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWhseOper", () => {
    // START_USER_CODE-USER_txtcolWhseOper_TEST
    // END_USER_CODE-USER_txtcolWhseOper_TEST
  });
  test("gridEWRViewRRI_txtFileNum(Grid Widget) Test Cases", async () => {
    let gridEWRViewRRI_txtFileNum = screen.getByTestId("gridEWRViewRRI");
    let gridEWRViewRRI_txtFileNumbtn =
      gridEWRViewRRI_txtFileNum.nextElementSibling.firstElementChild;
    gridEWRViewRRI_txtFileNum =
      gridEWRViewRRI_txtFileNum.parentElement.parentElement.parentElement;
    expect(gridEWRViewRRI_txtFileNum.tagName).toBe("DIV");
    expect(gridEWRViewRRI_txtFileNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("WarehouseReceipts:EWRViewRRI_gridEWRViewRRI"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtFileNum", () => {
    // START_USER_CODE-USER_txtFileNum_TEST
    // END_USER_CODE-USER_txtFileNum_TEST
  });
});
