/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  ButtonWidget,			
  disable,	
  enable,	
  getData,	
  setData,	
  hide,
} from "../../shared/WindowImports";

import "./ReportPreviewCheck.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import CommonContext from '../../Store/CommonContext';	
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { AccountPayment } from "../Service/AccountPayment";
import { json2xml } from "xml-js";
import { GetReportLogoImage } from "./../../Common/Constants";
import { GetReportLogoImageDimension } from "./../../Common/Constants";

import moment from 'moment';
// END_USER_CODE-USER_IMPORTS
function AccountPayment_ReportPreviewCheck(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const commonContext = useContext(CommonContext);	
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  const imgUrl = GetReportLogoImage(window.location.origin)
  const imgDimension = GetReportLogoImageDimension('ReportPreviewCheck')
  let ParentData = {}
  var pageCount = 1
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ReportPreviewCheck",
    windowName: "ReportPreviewCheck",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "AccountPayment.ReportPreviewCheck",
    // START_USER_CODE-USER_ReportPreviewCheck_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Print Preview",
      scrCode: "PN9000A",
    },
  renderUrl: "",	
  renderCode: "",	
  lstReport: [],	
  address: {},	
  contract_num: {},	
  lstReportDetail: [],

    // END_USER_CODE-USER_ReportPreviewCheck_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxPreviewActions",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btn2: {
      name: "btn2",
      type: "ButtonWidget",
      parent: "grpbxPreviewActions",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn2_PROPERTIES

      // END_USER_CODE-USER_btn2_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxPreviewActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxPreviewActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnPrintSetup: {
      name: "btnPrintSetup",
      type: "ButtonWidget",
      parent: "grpbxPreviewActions",
      Label: "Print Setup",
      CharWidth: "24",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintSetup_PROPERTIES

      // END_USER_CODE-USER_btnPrintSetup_PROPERTIES
    },
    btnSaveasPDF: {
      name: "btnSaveasPDF",
      type: "ButtonWidget",
      parent: "grpbxPreviewActions",
      Label: "Save as PDF",
      CharWidth: "25",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSaveasPDF_PROPERTIES

      // END_USER_CODE-USER_btnSaveasPDF_PROPERTIES
    },
    grpbxPreviewActions: {
      name: "grpbxPreviewActions",
      type: "GroupBoxWidget",
      parent: "ReportPreviewCheck",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxPreviewActions_PROPERTIES

      // END_USER_CODE-USER_grpbxPreviewActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},
        "AccountPayment#ReviewCheck" :{},
      },
      REVERSE: {
        "ContractManagement#MainMenu": {},
        "AccountPayment#ReviewCheck" :{},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnClose : {
    //   DEFAULT: [
    //     "AccountPayment#ReviewCheck#DEFAULT#true#Click",
    //   ],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
}, []);

useEffect(() => {
  let ArrayData = getData(thisObj, "ReportPreviewCheck")
  setData(thisObj, 'ArrayData', ArrayData)
  if (ArrayData.length == 1) {
    ParentData = ArrayData[0]
    ReadFile()
    PutMethod()
  }
  if(ArrayData.length > 1){
    ReadBatchFile()
  }
}, [getData(thisObj, 'ArrayData')]);

useEffect(() =>{
  return () => {
    setData(thisObj, "closingOfReportPreviewCheck", true)
    let caption = getData(thisObj, 'SpecialPayablesProfileData')
    if(caption != undefined && caption != null && caption.cmdOpen != undefined && caption.cmdOpen == true){
      setData(thisObj, "refeshGridData", "OK");
    }
  }
});


function NumberToWordConvert(num){
  var bigNumArry = new Array('', ' Thousand', ' Million', ' Billion', ' Trillion', ' Quadrillion', ' Quintillion');

  var output = '';
  var numString = num;
  let Value = ''
  var finlOutPut = new Array();

  if (numString == '00') {
      Value = 'No';
      return Value;
  }

  if (numString == 0) {
    Value = 'No'
    return Value;
  }

  var i = numString.length;
  i = i - 1;

  //cut the number to grups of three digits and add them to the Arry
  while (numString.length > 3) {
      var triDig = new Array(3);
      triDig[2] = numString.charAt(numString.length - 1);
      triDig[1] = numString.charAt(numString.length - 2);
      triDig[0] = numString.charAt(numString.length - 3);

      var varToAdd = triDig[0] + triDig[1] + triDig[2];
      finlOutPut.push(varToAdd);
      i--;
      numString = numString.substring(0, numString.length - 3);
  }
  finlOutPut.push(numString);
  finlOutPut.reverse();

  //conver each grup of three digits to english word
  //if all digits are zero the triConvert
  //function return the string "dontAddBigSufix"
  for (var j = 0; j < finlOutPut.length; j++) {
      finlOutPut[j] = triConvert(parseInt(finlOutPut[j]));
  }

  var bigScalCntr = 0; //this int mark the million billion trillion... Arry

  for (var b = finlOutPut.length - 1; b >= 0; b--) {
      if (finlOutPut[b] != "dontAddBigSufix") {
          finlOutPut[b] = finlOutPut[b] + bigNumArry[bigScalCntr];
          bigScalCntr++;
      }
      else {
          //replace the string at finlOP[b] from "dontAddBigSufix" to empty String.
          finlOutPut[b] = ' ';
          bigScalCntr++; //advance the counter  
      }
  }

      //convert The output Arry to , more printable string 
      for(var n = 0; n<finlOutPut.length; n++){
          output +=finlOutPut[n];
      }

  Value = output;//print the output
  return Value
}

//simple function to convert from numbers to words from 1 to 999
function triConvert(num){
  var ones = new Array('', ' One', ' Two', ' Three', ' Four', ' Five', ' Six', ' Seven', ' Eight', ' Nine', ' Ten', ' Eleven', ' Twelve', ' Thirteen', ' Fourteen', ' Fifteen', ' Sixteen', ' Seventeen', ' Eighteen', ' Nineteen');
  var tens = new Array('', '', ' Twenty', ' Thirty', ' Forty', ' Fifty', ' Sixty', ' Seventy', ' Eighty', ' Ninety');
  var hundred = ' Hundred';
  var output = '';
  var numString = num.toString();

  if (num == 0) {
      return 'dontAddBigSufix';
  }
  //the case of 10, 11, 12 ,13, .... 19 
  if (num < 20) {
      output = ones[num];
      return output;
  }

  //100 and more
  if (numString.length == 3) {
    if(numString.slice(1,2) != '1' && numString.slice(1,2) != '0'){
      output = ones[parseInt(numString.charAt(0))] + hundred;
      output += tens[parseInt(numString.charAt(1))];
      output += ones[parseInt(numString.charAt(2))];
      return output;
    }
    else{
      output = ones[parseInt(numString.charAt(0))] + hundred;
      output += ones[parseInt(numString.slice(1,3))];
      return output;
    }
  }

  output += tens[parseInt(numString.charAt(0))];
  output += ones[parseInt(numString.charAt(1))];

  return output;
}

async function ReadFile() {
    var renderCode = await GetHtmlCode()
    if(renderCode != undefined && renderCode != null){
      state.renderCode = renderCode
      state.renderUrl = getBlobURL(renderCode, 'text/html')
      document.getElementById("docFrame").setAttribute("src", state.renderUrl);
    }
    else{
      document.getElementById("AccountPayment_ReportPreviewCheckPopUp").childNodes[0].click();
    }
}

async function ReadBatchFile() {
  var renderCode = await BatchReport()
  if(renderCode != undefined && renderCode != null){
    state.renderCode = renderCode
    state.renderUrl = getBlobURL(renderCode, 'text/html')
    document.getElementById("docFrame").setAttribute("src", state.renderUrl);
  }
  else{
    document.getElementById("AccountPayment_ReportPreviewCheckPopUp").childNodes[0].click();
  }
}

async function GetHtmlCode() {
    var renderCode = ''
    renderCode = await getOptionContract()
    return renderCode;
}

async function GetHtmlCode2() {
  var renderCode = ''
  renderCode = await getOptionContract2()
  return renderCode;
}

async function GetHtmlCode3() {
  var renderCode = ''
  renderCode = await getOptionContract3()
  return renderCode;
}

async function PutMethod (){
  let Buy_Pt = ''
  if(ParentData.buying_Point != undefined && ParentData.buying_Point != null){
    Buy_Pt = ParentData.buying_Point
  }
  let Check_Number = ''
  if(ParentData.check_num != undefined && ParentData.check_num != null){
    Check_Number = ParentData.check_num
  }
  let VendorNumber = ''
  if(ParentData.vendor != null && ParentData.vendor != undefined){
    VendorNumber = ParentData.vendor
  }
  let respons = await AccountPayment.RetrieveBatchCheckPrintDetails(Buy_Pt, Check_Number, VendorNumber)
  let xml = json2xml(respons, {
   compact: true
  });
  let dataObj = {
    check_num: respons[0].check_num,
    user_id: useridFromLS(),
    orig_check_print_xml: '',
    formxmlvalue: ''
  }
  let putresponse = await AccountPayment.UpdateBatchCheckDetails(Check_Number,Buy_Pt,VendorNumber, dataObj)
  return putresponse;
}

async function getHeader(pageCount){
  let Buy_Pt = ParentData.buying_Point
  let Check_Number = ParentData.check_num
  let VendorNumber =  ParentData.vendor
  let title = ''
  if(ParentData.Title != undefined){
    title = ParentData.Title
  }
  let response = await AccountPayment.RetrieveBatchCheckPrintDetails(Buy_Pt, Check_Number, VendorNumber)
  if(response.length>0 && response != undefined && response != null){
  let Issued_Date = ''
  if(response[0].issued_date != undefined){
    Issued_Date = formatDate(response[0].issued_date).split(' ')[0]
  }
  let Crop_Year = ''
  if(response[0].crop_year != undefined){
    Crop_Year = response[0].crop_year
  }
  let Check_Num = ''
  if(response[0].check_num != undefined){
    if(response[0].check_num != 0){
      Check_Num = response[0].check_num
    }
    else{
      let ReceivableNumLength = (response[0].receivable_num).length
        if (ReceivableNumLength < 5 && ReceivableNumLength > 0) {
          let NewReceivableNum = '0'.repeat((5 - ReceivableNumLength)) + response[0].receivable_num
          Check_Num = 'S' + NewReceivableNum
        }
    }
  }
  let Check_Status_Response = ''
  if(response[0].check_status != undefined){
    Check_Status_Response = response[0].check_status
  }
  Check_Status_Response
  let Check_Status = ''
  if(Check_Status_Response == "I"){
    Check_Status = "Issued"
  }
  else if(Check_Status_Response == "V"){
    Check_Status = "Void"
  }
  else if(Check_Status_Response == "C"){
    Check_Status = "Cleared"
  }
  else if(Check_Status_Response == "R"){
    Check_Status = "Sent To Recon"
  }
  else if(Check_Status_Response == "S"){
    if(response[0].wire_xfer_data !== null && response[0].wire_xfer_data !== undefined && response[0].wire_xfer_data !== ''){
      Check_Status = "Wire Transfer"
    }
    else{
      Check_Status = "System Void"
    }
  }
  else{
    Check_Status = "System Void"
  }
  let Payee_Vendor = ''
  if(response[0].payee_vendor!= undefined){
    Payee_Vendor = response[0].payee_vendor
  }
  let Payee_Remit = ''
  if(response[0].payee_remit != undefined){
    Payee_Remit = response[0].payee_remit
  }
  let Payee_Remit_Name = ''
  let Payee_Remit_Name_Address1 = ''
  let Payee_Remit_Name_Address2 = ''
  let Payee_Remit_City = ''
  let Payee_Remit_State = ''
  let Payee_Remit_Zipcode = ''

  if(response[0].payee_remits[0] != undefined){
    if(response[0].payee_remits[0].payee_remit_name != undefined){
      Payee_Remit_Name = response[0].payee_remits[0].payee_remit_name
    }
    if(response[0].payee_remits[0].payee_remit_name_address1 != undefined){
      Payee_Remit_Name_Address1 = response[0].payee_remits[0].payee_remit_name_address1
    }
    if(response[0].payee_remits[0].payee_remit_name_address2 != undefined){
      Payee_Remit_Name_Address2 = response[0].payee_remits[0].payee_remit_name_address2
    }
    if(response[0].payee_remits[0].payee_remit_city != undefined){
      Payee_Remit_City = response[0].payee_remits[0].payee_remit_city + ','
    }
    if(response[0].payee_remits[0].payee_remit_state != undefined){
      Payee_Remit_State = response[0].payee_remits[0].payee_remit_state
    }
    if(response[0].payee_remits[0].payee_remit_zipcode != undefined){
      Payee_Remit_Zipcode = response[0].payee_remits[0].payee_remit_zipcode
      if(Payee_Remit_Zipcode.length >5){
        Payee_Remit_Zipcode = Payee_Remit_Zipcode.slice(0,5) + '-' + Payee_Remit_Zipcode.slice(5,9)
      }
    }
  }
  let LineHolder = ''
  if(response[0].lienholders != undefined){
    LineHolder = response[0].lienholders
  }
  let  Buy_Pt_ID = ''
  if(response[0].buy_pt_id != undefined){
    Buy_Pt_ID = response[0].buy_pt_id
  }
  let Buy_Pt_Name = ''
  if(response[0].buy_pt_name != undefined){
    Buy_Pt_Name = response[0].buy_pt_name
  }
  let Buy_Pt_Physical_City = ''
  if(response[0].buy_pt_physical_city != undefined){
    Buy_Pt_Physical_City = response[0].buy_pt_physical_city + ','
  }
  let Buy_Pt_Physical_State = ''
  if(response[0].buy_pt_physical_state != undefined){
    Buy_Pt_Physical_State = response[0].buy_pt_physical_state
  }
  let Buy_Pt_Physical_Zipcode = ''
  if(response[0].buy_pt_physical_zip != undefined){
    Buy_Pt_Physical_Zipcode = response[0].buy_pt_physical_zip

    if(Buy_Pt_Physical_Zipcode.length >5){
      Buy_Pt_Physical_Zipcode = Buy_Pt_Physical_Zipcode.slice(0,5) + '-' + Buy_Pt_Physical_Zipcode.slice(5,9)
    }
  }

  var renderHeader = '';

  if(LineHolder != null && LineHolder != undefined && LineHolder != ''){
  renderHeader = `  
  <table style="align-items: center;width: 100%;font-size:85%;">
              <tr>
                  <td style="padding-left:2%;padding-right:1%;"colspan="1">                	
                  <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                  </td>
                  <td style="padding-left:2%;text-align: right" colspan="2">
                      <br/>
                      <span style="text-align: left;font-size:100%;color: black;">
                          <b> ${title} </b>
                      </span>
                  </td>
                  <td style="padding-left:0%; padding-right:2%;padding-top: 0%; vertical-align: top;text-align: end;width:38%;"colspan="1">
                      <span style="text-align-last: left;color:black;font-size: 110%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                      <span style="text-align: right;color:black;font-size: 76%;"><b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                      <span style="text-align: right;color:black;font-size: 76%;"><b> ALPHARETTA, GEORGIA 30022-8262 </b><br/><br/><br/></span>
                      <span style="text-align: right;color:black;font-size: 97%; horizontal-align: right;"> Page ${pageCount} of Check: <b> ${Check_Num}</b><br/><br/> </span>
                      <span style="text-align: right;color:black;font-size: 97%; horizontal-align: right;">  <b> Check Status: ${Check_Status} </b> </span>
                      </td>
              </tr>     
              <tr>
                  <td style="padding-left:2%;padding-top: 2%;color:black;font-size:75%;" colspan="1">${Payee_Vendor} ${Payee_Remit} - ${Payee_Remit_Name}</td>
                  <td style="text-align: left;padding-left:4%;padding-top: 2%;color:black;font-size:75%;" colspan="2"> ${Buy_Pt_ID} - ${Buy_Pt_Name}</td>
                  <td style="text-align: end;padding-top: 2%;padding-right: 2%;color:black;font-size:80%;"colspan="1"> <b> ${Issued_Date} </b> </td> 
              </tr>
              <tr>
                  <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${LineHolder}</td>
                  <td style="text-align: left;padding-left:4%;color:black;font-size:75%;" colspan="2"> ${Buy_Pt_Physical_City} ${Buy_Pt_Physical_State} ${Buy_Pt_Physical_Zipcode}</td>
                  <td style="padding-right: 2%;text-align: end;color:black;font-size:80%;"> <b> Crop Year: ${Crop_Year} </b> </td>               
              </tr>
              <tr>
                  <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${Payee_Remit_Name_Address1} </br> ${Payee_Remit_Name_Address2}</td>
              </tr>
              <tr>
                  <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${Payee_Remit_City}  ${Payee_Remit_State}  ${Payee_Remit_Zipcode} </br></br></br> </td>
              </tr>
</table>`
  }else{
    renderHeader = `<table style="align-items: center;width: 100%;font-size:85%;">
        <tr>
                    <td style="padding-left:2%;padding-right:1%;"colspan="1">                	
                    <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                    </td>
                    <td style="padding-left:2%;text-align: left" colspan="2">
                        <br/>
                        <span style="text-align: right;font-size:100%;color: black;">
                            <b> ${title} </b>
                        </span>
                    </td>
                    <td style="padding-left:0%; padding-right:2%;padding-top: 0%; vertical-align: top;text-align: end;width:38%;"colspan="1">
                        <span style="text-align-last: left;color:black;font-size: 110%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                        <span style="text-align: right;color:black;font-size: 76%;"><b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                        <span style="text-align: right;color:black;font-size: 76%;"><b> ALPHARETTA, GEORGIA 30022-8262 </b><br/><br/><br/></span>
                        <span style="text-align: right;color:black;font-size: 97%; horizontal-align: right;"> Page ${pageCount} of Check: <b> ${Check_Num}</b><br/><br/> </span>
                        <span style="text-align: right;color:black;font-size: 97%; horizontal-align: right;">  <b> Check Status: ${Check_Status} </b> </span>
                        </td>
                </tr>     
                <tr>
                    <td style="padding-left:2%;padding-top: 2%;color:black;font-size:75%;" colspan="1">${Payee_Vendor} ${Payee_Remit} - ${Payee_Remit_Name}</td>
                    <td style="text-align: left;padding-left:4%;padding-top: 2%;color:black;font-size:75%;" colspan="2"> ${Buy_Pt_ID} - ${Buy_Pt_Name}</td>
                    <td style="text-align: end;padding-top: 2%;padding-right: 2%;color:black;font-size:80%;"colspan="1"> <b> ${Issued_Date} </b> </td> 
                </tr>
                <tr>
                    <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${Payee_Remit_Name_Address1} </br> ${Payee_Remit_Name_Address2}</td>
                    <td style="text-align: left;padding-left:4%;color:black;font-size:75%;" colspan="2"> ${Buy_Pt_Physical_City} ${Buy_Pt_Physical_State} ${Buy_Pt_Physical_Zipcode}</td>
                    <td style="padding-right: 2%;text-align: end;color:black;font-size:80%;"> <b> Crop Year: ${Crop_Year} </b> </td>               
                </tr>
                <tr>
                    <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${Payee_Remit_City}  ${Payee_Remit_State}  ${Payee_Remit_Zipcode} </br></br></br></td>
                </tr>
  </table>`
  }
  return renderHeader;
}
}

async function getHeader2(pageCount){
  let Buy_Pt = ParentData.buying_Point
  let Check_Number = ParentData.check_num
  let VendorNumber =  ParentData.vendor
  let title = ''
  if(ParentData.Title != undefined){
    title = ParentData.Title
  }
  let response = await AccountPayment.RetrieveBatchCheckPrintDetails(Buy_Pt, Check_Number, VendorNumber)
  if(response.length>0 && response != undefined && response != null){
  let Issued_Date = ''
  if(response[0].issued_date != undefined){
    Issued_Date = formatDate(response[0].issued_date).split(' ')[0]
  }
  let Crop_Year = ''
  if(response[0].crop_year != undefined){
    Crop_Year = response[0].crop_year
  }
  let Check_Num = ''
  if(response[0].check_num != undefined){
    Check_Num = response[0].check_num
  }
  let Check_Status_Response = ''
  if(response[0].check_status != undefined){
    Check_Status_Response = response[0].check_status
  }
  let Check_Status = ''
  if(Check_Status_Response == "I"){
    Check_Status = "Issued"
  }
  else if(Check_Status_Response == "V"){
    Check_Status = "Void"
  }
  else if(Check_Status_Response == "C"){
    Check_Status = "Cleared"
  }
  else if(Check_Status_Response == "R"){
    Check_Status = "Sent To Recon"
  }
  else if(Check_Status_Response == "S"){
    if(response[0].wire_xfer_data !== null && response[0].wire_xfer_data !== undefined && response[0].wire_xfer_data !== ''){
      Check_Status = "Wire Transfer"
    }
  }
  else{
    Check_Status = "System Void"
  }
  let Payee_Vendor = ''
  if(response[0].payee_vendor!= undefined){
    Payee_Vendor = response[0].payee_vendor
  }
  let Payee_Remit = ''
  if(response[0].payee_remit != undefined){
    Payee_Remit = response[0].payee_remit
  }
  let Payee_Remit_Name = ''
  let Payee_Remit_Name_Address1 = ''
  let Payee_Remit_Name_Address2 = ''
  let Payee_Remit_City = ''
  let Payee_Remit_State = ''
  let Payee_Remit_Zipcode = ''

  if(response[0].payee_remits[0] != undefined){
    if(response[0].payee_remits[0].payee_remit_name != undefined){
      Payee_Remit_Name = response[0].payee_remits[0].payee_remit_name
    }
    if(response[0].payee_remits[0].payee_remit_name_address1 != undefined){
      Payee_Remit_Name_Address1 = response[0].payee_remits[0].payee_remit_name_address1
    }
    if(response[0].payee_remits[0].payee_remit_name_address2 != undefined){
      Payee_Remit_Name_Address2 = response[0].payee_remits[0].payee_remit_name_address2
    }
    if(response[0].payee_remits[0].payee_remit_city != undefined){
      Payee_Remit_City = response[0].payee_remits[0].payee_remit_city + ','
    }
    if(response[0].payee_remits[0].payee_remit_state != undefined){
      Payee_Remit_State = response[0].payee_remits[0].payee_remit_state
    }
    if(response[0].payee_remits[0].payee_remit_zipcode != undefined){
      Payee_Remit_Zipcode = response[0].payee_remits[0].payee_remit_zipcode
      if(Payee_Remit_Zipcode.length >5){
        Payee_Remit_Zipcode = Payee_Remit_Zipcode.slice(0,5) + '-' + Payee_Remit_Zipcode.slice(5,9)
      }
    }
  }
  let LineHolder = ''
  if(response[0].lienholders != undefined){
    LineHolder = response[0].lienholders
  }
  let  Buy_Pt_ID = ''
  if(response[0].buy_pt_id != undefined){
    Buy_Pt_ID = response[0].buy_pt_id
  }
  let Buy_Pt_Name = ''
  if(response[0].buy_pt_name != undefined){
    Buy_Pt_Name = response[0].buy_pt_name
  }
  let Buy_Pt_Physical_City = ''
  if(response[0].buy_pt_physical_city != undefined){
    Buy_Pt_Physical_City = response[0].buy_pt_physical_city + ','
  }
  let Buy_Pt_Physical_State = ''
  if(response[0].buy_pt_physical_state != undefined){
    Buy_Pt_Physical_State = response[0].buy_pt_physical_state
  }
  let Buy_Pt_Physical_Zipcode = ''
  if(response[0].buy_pt_physical_zip != undefined){
    Buy_Pt_Physical_Zipcode = response[0].buy_pt_physical_zip
    if(Buy_Pt_Physical_Zipcode.length>5){
      Buy_Pt_Physical_Zipcode = Buy_Pt_Physical_Zipcode.slice(0,5) + '-' + Buy_Pt_Physical_Zipcode.slice(5,9)
    }
  }

  
  var renderHeader = '';

  if(LineHolder != null && LineHolder != undefined && LineHolder != ''){
  renderHeader = `  
  <table style="align-items: center;width: 100%;font-size:90%;">
              <tr></br></br>
                  <td style="padding-left:2%;padding-top: 2%;color:black;font-size:75%;" colspan="1">${Payee_Vendor} ${Payee_Remit} - ${Payee_Remit_Name}</td>
                  <td style="padding-left:1%;padding-top: 2%;color:black;font-size:75%;" colspan="1"> ${Buy_Pt_ID} - ${Buy_Pt_Name}</td>
                  <td style="text-align: end;padding-top: 2%;padding-right: 2%;color:black;font-size:80%;"colspan="1"> ${Issued_Date} </td> 
              </tr>
              <tr>
                  <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${LineHolder}</td>
                  <td style="padding-left:1%;color:black;font-size:75%;" colspan="1"> ${Buy_Pt_Physical_City} ${Buy_Pt_Physical_State} ${Buy_Pt_Physical_Zipcode}</td>
                  <td style="padding-right: 2%;text-align: end;color:black;font-size:80%;"> Crop Year: ${Crop_Year} </td>               
              </tr>
              <tr>
                  <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${Payee_Remit_Name_Address1} </br> ${Payee_Remit_Name_Address2}</td>
              </tr>
              <tr>
                  <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${Payee_Remit_City}  ${Payee_Remit_State}  ${Payee_Remit_Zipcode} </br></br></td>
              </tr>
</table>`
  }else{
    renderHeader = `<table style="align-items: center;width: 100%;font-size:90%;">
                <tr></br></br>
                    <td style="padding-left:2%;padding-top: 2%;color:black;font-size:75%;" colspan="1">${Payee_Vendor} ${Payee_Remit} - ${Payee_Remit_Name}</td>
                    <td style="padding-left:1%;padding-top: 2%;color:black;font-size:75%;" colspan="1"> ${Buy_Pt_ID} - ${Buy_Pt_Name}</td>
                    <td style="text-align: end;padding-top: 2%;padding-right: 2%;color:black;font-size:80%;"colspan="1"> ${Issued_Date} </td> 
                </tr>
                <tr>
                    <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${Payee_Remit_Name_Address1} </br> ${Payee_Remit_Name_Address2}</td>
                    <td style="padding-left:1%;color:black;font-size:75%;" colspan="1"> ${Buy_Pt_Physical_City} ${Buy_Pt_Physical_State} ${Buy_Pt_Physical_Zipcode}</td>
                    <td style="padding-right: 2%;text-align: end;color:black;font-size:80%;"> Crop Year: ${Crop_Year} </td>               
                </tr>
                <tr>
                    <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${Payee_Remit_City}  ${Payee_Remit_State}  ${Payee_Remit_Zipcode} </br></br></td>
                </tr>
  </table>`
  }
  return renderHeader;
}
}

async function getHeader3(pageCount){
  let Buy_Pt = ParentData.buying_Point
  let Check_Number = ParentData.check_num
  let VendorNumber =  ParentData.vendor

  let response = await AccountPayment.RetrieveBatchCheckPrintDetails(Buy_Pt, Check_Number, VendorNumber)
  if(response.length>0 && response != undefined && response != null){
  let Issued_Date = ''
  if(response[0].issued_date != undefined){
    Issued_Date = formatDate(response[0].issued_date).split(' ')[0]
  }
  let Crop_Year = ''
  if(response[0].crop_year != undefined){
    Crop_Year = response[0].crop_year
  }
  let Check_Num = ''
  if(response[0].check_num != undefined){
    if(response[0].check_num != 0){
      Check_Num = response[0].check_num
    }
    else{
      let ReceivableNumLength = (response[0].receivable_num).length
        if (ReceivableNumLength < 5 && ReceivableNumLength > 0) {
          let NewReceivableNum = '0'.repeat((5 - ReceivableNumLength)) + response[0].receivable_num
          Check_Num = 'S' + NewReceivableNum
        }
    }
  }
  let Check_Status_Response = ''
  if(response[0].check_status != undefined){
    Check_Status_Response = response[0].check_status
  }
  Check_Status_Response
  let Check_Status = ''
  if(Check_Status_Response == "I"){
    Check_Status = "Issued"
  }
  else if(Check_Status_Response == "V"){
    Check_Status = "Void"
  }
  else if(Check_Status_Response == "C"){
    Check_Status = "Cleared"
  }
  else if(Check_Status_Response == "R"){
    Check_Status = "Sent To Recon"
  }
  else if(Check_Status_Response == "S"){
    if(response[0].wire_xfer_data !== null && response[0].wire_xfer_data !== undefined && response[0].wire_xfer_data !== ''){
      Check_Status = "Wire Transfer"
    }
    else{
      Check_Status = "System Void"
    }
  }
  else{
    Check_Status = "System Void"
  }
  let Payee_Vendor = ''
  if(response[0].payee_vendor!= undefined){
    Payee_Vendor = response[0].payee_vendor
  }
  let Payee_Remit = ''
  if(response[0].payee_remit != undefined){
    Payee_Remit = response[0].payee_remit
  }
  let Payee_Remit_Name = ''
  let Payee_Remit_Name_Address1 = ''
  let Payee_Remit_Name_Address2 = ''
  let Payee_Remit_City = ''
  let Payee_Remit_State = ''
  let Payee_Remit_Zipcode = ''

  if(response[0].payee_remits[0] != undefined){
    if(response[0].payee_remits[0].payee_remit_name != undefined){
      Payee_Remit_Name = response[0].payee_remits[0].payee_remit_name
    }
    if(response[0].payee_remits[0].payee_remit_name_address1 != undefined){
      Payee_Remit_Name_Address1 = response[0].payee_remits[0].payee_remit_name_address1
    }
    if(response[0].payee_remits[0].payee_remit_name_address2 != undefined){
      Payee_Remit_Name_Address2 = response[0].payee_remits[0].payee_remit_name_address2
    }
    if(response[0].payee_remits[0].payee_remit_city != undefined){
      Payee_Remit_City = response[0].payee_remits[0].payee_remit_city + ','
    }
    if(response[0].payee_remits[0].payee_remit_state != undefined){
      Payee_Remit_State = response[0].payee_remits[0].payee_remit_state
    }
    if(response[0].payee_remits[0].payee_remit_zipcode != undefined){
      Payee_Remit_Zipcode = response[0].payee_remits[0].payee_remit_zipcode
      if(Payee_Remit_Zipcode.length >5){
        Payee_Remit_Zipcode = Payee_Remit_Zipcode.slice(0,5) + '-' + Payee_Remit_Zipcode.slice(5,9)
      }
    }
  }
  let LineHolder = ''
  if(response[0].lienholders != undefined){
    LineHolder = response[0].lienholders
  }
  let  Buy_Pt_ID = ''
  if(response[0].buy_pt_id != undefined){
    Buy_Pt_ID = response[0].buy_pt_id
  }
  let Buy_Pt_Name = ''
  if(response[0].buy_pt_name != undefined){
    Buy_Pt_Name = response[0].buy_pt_name
  }
  let Buy_Pt_Physical_City = ''
  if(response[0].buy_pt_physical_city != undefined){
    Buy_Pt_Physical_City = response[0].buy_pt_physical_city + ','
  }
  let Buy_Pt_Physical_State = ''
  if(response[0].buy_pt_physical_state != undefined){
    Buy_Pt_Physical_State = response[0].buy_pt_physical_state
  }
  let Buy_Pt_Physical_Zipcode = ''
  if(response[0].buy_pt_physical_zip != undefined){
    Buy_Pt_Physical_Zipcode = response[0].buy_pt_physical_zip

    if(Buy_Pt_Physical_Zipcode.length >5){
      Buy_Pt_Physical_Zipcode = Buy_Pt_Physical_Zipcode.slice(0,5) + '-' + Buy_Pt_Physical_Zipcode.slice(5,9)
    }
  }

  var renderHeader = '';

  if(LineHolder != null && LineHolder != undefined && LineHolder != ''){
  renderHeader = `  
  <table style="align-items: center;width: 100%;font-size:85%;">
              <tr>
                  <td style="padding-left:2%;padding-right:1%;"colspan="1">                	
                  <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                  </td>
                  <td style="padding-left:2%;text-align: right" colspan="2">
                      <br/>
                      <span style="text-align: left;font-size:100%;color: black;">
                          <b> </b>
                      </span>
                  </td>
                  <td style="padding-left:0%; padding-right:2%;padding-top: 0%; vertical-align: top;text-align: end;width:38%;"colspan="1">
                      <span style="text-align-last: left;color:black;font-size: 110%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                      <span style="text-align: right;color:black;font-size: 76%;"><b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                      <span style="text-align: right;color:black;font-size: 76%;"><b> ALPHARETTA, GEORGIA 30022-8262 </b><br/><br/><br/></span>
                      <span style="text-align: right;color:black;font-size: 97%; horizontal-align: right;"> Page ${pageCount} of Check: <b> ${Check_Num}</b><br/><br/> </span>
                      <span style="text-align: right;color:black;font-size: 97%; horizontal-align: right;">  <b></b> </span>
                  </td>
              </tr>     
              <tr>
                  <td style="padding-left:2%;padding-top: 2%;color:black;font-size:75%;" colspan="1">${Payee_Vendor} ${Payee_Remit} - ${Payee_Remit_Name}</td>
                  <td style="text-align: left;padding-left:4%;padding-top: 2%;color:black;font-size:75%;" colspan="2"> ${Buy_Pt_ID} - ${Buy_Pt_Name}</td>
                  <td style="text-align: end;padding-top: 2%;padding-right: 2%;color:black;font-size:80%;"colspan="1"> <b> ${Issued_Date} </b> </td> 
              </tr>
              <tr>
                  <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${LineHolder}</td>
                  <td style="text-align: left;padding-left:4%;color:black;font-size:75%;" colspan="2"> ${Buy_Pt_Physical_City} ${Buy_Pt_Physical_State} ${Buy_Pt_Physical_Zipcode}</td>
                  <td style="padding-right: 2%;text-align: end;color:black;font-size:80%;"> <b> Crop Year: ${Crop_Year} </b> </td>               
              </tr>
              <tr>
                  <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${Payee_Remit_Name_Address1} </br> ${Payee_Remit_Name_Address2}</td>
              </tr>
              <tr>
                  <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${Payee_Remit_City}  ${Payee_Remit_State}  ${Payee_Remit_Zipcode} </br></br></br> </td>
              </tr>
</table>`
  }else{
    renderHeader = `<table style="align-items: center;width: 100%;font-size:85%;">
        <tr>
                    <td style="padding-left:2%;padding-right:1%;"colspan="1">                	
                    <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                    </td>
                    <td style="padding-left:2%;text-align: left" colspan="2">
                        <br/>
                        <span style="text-align: right;font-size:100%;color: black;">
                            <b> </b>
                        </span>
                    </td>
                    <td style="padding-left:0%; padding-right:2%;padding-top: 0%; vertical-align: top;text-align: end;width:38%;"colspan="1">
                        <span style="text-align-last: left;color:black;font-size: 110%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                        <span style="text-align: right;color:black;font-size: 76%;"><b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                        <span style="text-align: right;color:black;font-size: 76%;"><b> ALPHARETTA, GEORGIA 30022-8262 </b><br/><br/><br/></span>
                        <span style="text-align: right;color:black;font-size: 97%; horizontal-align: right;"> Page ${pageCount} of Check: <b> ${Check_Num}</b><br/><br/> </span>
                        <span style="text-align: right;color:black;font-size: 97%; horizontal-align: right;">  <b> </b> </span>
                        </td>
                </tr>     
                <tr>
                    <td style="padding-left:2%;padding-top: 2%;color:black;font-size:75%;" colspan="1">${Payee_Vendor} ${Payee_Remit} - ${Payee_Remit_Name}</td>
                    <td style="text-align: left;padding-left:4%;padding-top: 2%;color:black;font-size:75%;" colspan="2"> ${Buy_Pt_ID} - ${Buy_Pt_Name}</td>
                    <td style="text-align: end;padding-top: 2%;padding-right: 2%;color:black;font-size:80%;"colspan="1"> <b> ${Issued_Date} </b> </td> 
                </tr>
                <tr>
                    <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${Payee_Remit_Name_Address1} </br> ${Payee_Remit_Name_Address2}</td>
                    <td style="text-align: left;padding-left:4%;color:black;font-size:75%;" colspan="2"> ${Buy_Pt_Physical_City} ${Buy_Pt_Physical_State} ${Buy_Pt_Physical_Zipcode}</td>
                    <td style="padding-right: 2%;text-align: end;color:black;font-size:80%;"> <b> Crop Year: ${Crop_Year} </b> </td>               
                </tr>
                <tr>
                    <td style="padding-left:2%;color:black;font-size:75%;" colspan="1">${Payee_Remit_City}  ${Payee_Remit_State}  ${Payee_Remit_Zipcode} </br></br></br></td>
                </tr>
  </table>`
  }
  return renderHeader;
}
}

async function Footer(){
  let Buy_Pt = ParentData.buying_Point
  let Check_Number = ParentData.check_num
  let VendorNumber =  ParentData.vendor
  let title = ''
  if(ParentData.Title != undefined){
    title = ParentData.Title
  }
  let response = await AccountPayment.RetrieveBatchCheckPrintDetails(Buy_Pt, Check_Number, VendorNumber)
  if(response.length>0 && response != undefined && response != null){
  let Issued_Date = ''
  if(response[0].issued_date != undefined){
    Issued_Date = formatDate(response[0].issued_date).split(' ')[0]
  }
  let Crop_Year = ''
  if(response[0].crop_year != undefined){
    Crop_Year = response[0].crop_year
  }
  let Check_Num = ''
  if(response[0].check_num != undefined){
    Check_Num = response[0].check_num
  }
  let Check_Status_Response = ''
  if(response[0].check_status != undefined){
    Check_Status_Response = response[0].check_status
  }
  let Check_Status = ''
  if(Check_Status_Response == "I"){
    Check_Status = "Issued"
  }
  else if(Check_Status_Response == "V"){
    Check_Status = "Void"
  }
  else if(Check_Status_Response == "C"){
    Check_Status = "Cleared"
  }
  else if(Check_Status_Response == "R"){
    Check_Status = "Sent To Recon"
  }
  else if(Check_Status_Response == "S"){
    if(response[0].wire_xfer_data !== null && response[0].wire_xfer_data !== undefined && response[0].wire_xfer_data !== ''){
      Check_Status = "Wire Transfer"
    }
  }
  else{
    Check_Status = "System Void"
  }
  let Payee_Vendor = ''
  if(response[0].payee_vendor!= undefined){
    Payee_Vendor = response[0].payee_vendor
  }
  let Payee_Remit = ''
  if(response[0].payee_remit != undefined){
    Payee_Remit = response[0].payee_remit
  }
  let Payee_Remit_Name = ''
  let Payee_Remit_Name_Address = ''
  let BottomLength = '3.3%'
  let Payee_Remit_City = ''
  let Payee_Remit_State = ''
  let Payee_Remit_Zipcode = ''

  if(response[0]?.payee_remits[0]?.payee_remit_name_address2 != undefined && response[0]?.payee_remits[0]?.payee_remit_name_address2 != null && response[0]?.payee_remits[0]?.payee_remit_name_address2 != '' && 
    response[0]?.lienholders != undefined && response[0]?.lienholders != null && response[0]?.lienholders != ''){
      BottomLength = '1.3%'
  }
  else if((response[0]?.payee_remits[0]?.payee_remit_name_address2 != undefined && response[0]?.payee_remits[0]?.payee_remit_name_address2 != null && response[0]?.payee_remits[0]?.payee_remit_name_address2 != '') || 
          (response[0]?.lienholders != undefined && response[0]?.lienholders != null && response[0]?.lienholders != '')){
      BottomLength = '2.3%'     
  }

  if(response[0].payee_remits[0] != undefined){
    if(response[0].payee_remits[0].payee_remit_name != undefined){
      Payee_Remit_Name = response[0].payee_remits[0].payee_remit_name
    }
    if(response[0].payee_remits[0].payee_remit_name_address1 != undefined){
      Payee_Remit_Name_Address += response[0].payee_remits[0].payee_remit_name_address1
    }
    if(response[0].payee_remits[0].payee_remit_name_address2 != undefined && response[0].payee_remits[0].payee_remit_name_address2 != null && response[0].payee_remits[0].payee_remit_name_address2 != ''){
      Payee_Remit_Name_Address += '<br/>' + response[0].payee_remits[0].payee_remit_name_address2
    }
    if(response[0].payee_remits[0].payee_remit_city != undefined){
      Payee_Remit_City = response[0].payee_remits[0].payee_remit_city
    }
    if(response[0].payee_remits[0].payee_remit_state != undefined){
      Payee_Remit_State = response[0].payee_remits[0].payee_remit_state
    }
    if(response[0].payee_remits[0].payee_remit_zipcode != undefined){
      Payee_Remit_Zipcode = response[0].payee_remits[0].payee_remit_zipcode
      if(Payee_Remit_Zipcode.length>5){
        Payee_Remit_Zipcode = Payee_Remit_Zipcode.slice(0,5) + '-' + Payee_Remit_Zipcode.slice(5,9)
      }
    }
  }
  let LineHolder = ''
  if(response[0].lienholders != undefined){
    LineHolder = response[0].lienholders
  }
  let  Buy_Pt_ID = ''
  if(response[0].buy_pt_id != undefined){
    Buy_Pt_ID = response[0].buy_pt_id
  }
  let Buy_Pt_Name = ''
  if(response[0].buy_pt_name != undefined){
    Buy_Pt_Name = response[0].buy_pt_name
  }
  let Buy_Pt_Physical_City = ''
  if(response[0].buy_pt_physical_city != undefined){
    Buy_Pt_Physical_City = response[0].buy_pt_physical_city
  }
  let Buy_Pt_Physical_State = ''
  if(response[0].buy_pt_physical_state != undefined){
    Buy_Pt_Physical_State = response[0].buy_pt_physical_state
  }
  let Buy_Pt_Physical_Zipcode = ''
  if(response[0].buy_pt_physical_zip != undefined){
    Buy_Pt_Physical_Zipcode = response[0].buy_pt_physical_zip
    if(Buy_Pt_Physical_Zipcode.length>5){
      Buy_Pt_Physical_Zipcode = Buy_Pt_Physical_Zipcode.slice(0,5) + '-' + Buy_Pt_Physical_Zipcode.slice(5,9)
    }
  }

  let TotalPayPound = getData(thisObj, 'TotalPayPound')
  let TotalPayAmount = getData(thisObj, 'TotalPayAmount')

  let NumArray = TotalPayAmount.split('.')
  let Num = NumArray[0]
  let Decimal = NumArray[1]
  let NumWord = await NumberToWordConvert(Num)
  let DecimalWord = await NumberToWordConvert(Decimal)

  TotalPayAmount = Intl.NumberFormat('en-US').format(Num) + '.' + Decimal

  var renderFooter = '';

  if(LineHolder != null && LineHolder != undefined && LineHolder != ''){
    renderFooter = `  
  <table style="max-height:5%; position:fixed; left:0; top:84%; width:100%; font-size:74%;">
              <tr>
                  <td style="color:black; width:62%;" colspan="5"> </td>
                  <td style="color:black; width:100%;" colspan="1"> ${Buy_Pt_ID} &nbsp&nbsp&nbsp&nbsp ${Check_Num} &nbsp&nbsp&nbsp&nbsp ${Issued_Date} &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp $ ${TotalPayAmount} </br> </td>
              </tr>
              <tr>
                  <td style="padding-left:2%; color:black; font-size:118%;" colspan="3";> ${NumWord} Dollars and ${DecimalWord} Cents </br> </td>
              </tr>
              <tr>
                  <td style="position:fixed; left: 2%; top:90%; color:black; vertical-align: top;" colspan="1">${Payee_Vendor} </td>
                  <td style="position:fixed; left: 12%; top:90%; color:black; vertical-align: top;" colspan="1"> 
                    ${Payee_Remit_Name} <br/>
                    ${LineHolder} <br/>
                    ${Payee_Remit_Name_Address}<br/>
                    ${Payee_Remit_City}, ${Payee_Remit_State}  ${Payee_Remit_Zipcode}
                  </td>
              </tr>
</table>`
  }else{
    renderFooter = `
    <table style="max-height:5%; position:fixed; left:0; top:84%; width:100%; font-size:74%;">
    <tr>
        <td style="color:black; width:62%;" colspan="5"> </td>
        <td style="color:black; width:100%;" colspan="1"> ${Buy_Pt_ID} &nbsp&nbsp&nbsp&nbsp ${Check_Num} &nbsp&nbsp&nbsp&nbsp ${Issued_Date} &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp $ ${TotalPayAmount} </br> </td>
    </tr>
    <tr>
        <td style="padding-left:2%; color:black; font-size:118%;" colspan="3";> ${NumWord} Dollars and ${DecimalWord} Cents </br></td>
    </tr>
    <tr>
        <td style="position:fixed; left:2%; top:90%; color:black; vertical-align:top;" colspan="1">${Payee_Vendor} </td>
        <td style="position:fixed; left:12%; top:90%; color:black; vertical-align:top;" colspan="1"> 
          ${Payee_Remit_Name} <br/>
          ${Payee_Remit_Name_Address} <br/>
          ${Payee_Remit_City}, ${Payee_Remit_State}  ${Payee_Remit_Zipcode}
        </td>
    </tr>
</table>`
  }
  return renderFooter;
}
}

async function BatchReport(){

  let ArrayData = getData(thisObj, 'ArrayData')
  let tableData = '';
  for(var i =0; i< ArrayData.length; i++){
    ParentData = ''
    ParentData = ArrayData[i]
    let Buy_Pt = ParentData.buying_Point
    let Check_Number = ParentData.check_num
    let VendorNumber =  ParentData.vendor
    let BatchStatus = ''
    let PutStatus = await PutMethod(ParentData)
    if(PutStatus.status == '200'){
      BatchStatus = 'OK'
    }
    let PageNo = 1
    let Payee_Vendor = ''
    let LineHolder = ''
    let Payee_Remit_Name = ''
    let Payee_Remit_Name_Address1 = ''
    let Payee_Remit_Name_Address2 = ''
    let Payee_Remit_City =''
    let Payee_Remit_State = ''
    let Payee_Remit_Zipcode = ''
    let CropYear = ''
  
    let response = await AccountPayment.RetrieveBatchCheckPrintDetails(Buy_Pt, Check_Number, VendorNumber)
  
    if(response.length>0 && response != undefined && response != null){
      if(response[0].crop_year != undefined){
        CropYear = response[0].crop_year
      }
      if(response[0].pay_rec != undefined){
        let resData = response[0].pay_rec
        let DataRatio = Math.floor(resData.length/40)
        PageNo = DataRatio + 1
      }
      if(response[0].payee_vendor!= undefined){
        Payee_Vendor = response[0].payee_vendor
      }
      if(response[0].lienholders != undefined){
        LineHolder = response[0].lienholders
      }
      if(response[0].payee_remits[0] != undefined){
        if(response[0].payee_remits[0].payee_remit_name != undefined){
          Payee_Remit_Name = response[0].payee_remits[0].payee_remit_name
        }
        if(response[0].payee_remits[0].payee_remit_name_address1 != undefined){
          Payee_Remit_Name_Address1 = response[0].payee_remits[0].payee_remit_name_address1
        }
        if(response[0].payee_remits[0].payee_remit_name_address2 != undefined){
          Payee_Remit_Name_Address2 = response[0].payee_remits[0].payee_remit_name_address2
        }
        if(response[0].payee_remits[0].payee_remit_city != undefined){
          Payee_Remit_City = response[0].payee_remits[0].payee_remit_city
        }
        if(response[0].payee_remits[0].payee_remit_state != undefined){
          Payee_Remit_State = response[0].payee_remits[0].payee_remit_state
        }
        if(response[0].payee_remits[0].payee_remit_zipcode != undefined){
          Payee_Remit_Zipcode = response[0].payee_remits[0].payee_remit_zipcode

          if(Payee_Remit_Zipcode.length>5){
            Payee_Remit_Zipcode = Payee_Remit_Zipcode.slice(0,5) + '-' + Payee_Remit_Zipcode.slice(5,9)
          }
        } 
      }
    }
  
    let BatchReportObj = {}
    BatchReportObj.CheckNum = Check_Number
    BatchReportObj.Vendor = Payee_Vendor + ' ' + Payee_Remit_Name
    BatchReportObj.RemitTo = LineHolder + ' ' + Payee_Remit_Name_Address1 + ' ' + Payee_Remit_Name_Address2 + ' ' + Payee_Remit_City + ' ' + Payee_Remit_State + ' ' + Payee_Remit_Zipcode
    BatchReportObj.Pages = PageNo
    BatchReportObj.Proceeds = ParentData.Proceeds
    BatchReportObj.Status = ParentData.statustoPrintscreen

    let NoArray = BatchReportObj.Proceeds.split('.')
    let No = NoArray[0]
    let Decimalpt = NoArray[1]
    BatchReportObj.Proceeds = Intl.NumberFormat('en-US').format(No) + '.' + Decimalpt

    tableData += `
      <table style="text-align:center; font-size: 70%;margin-left: 2%;width: 96%;padding-top:2%;border-collapse:collapse;">
        <tr>
          <td style="text-align:left;width: 14%;border: 0.1px solid black;border-collapse:collapse;">${BatchReportObj.CheckNum}</td>
          <td style="text-align:left;width: 28%;border: 0.1px solid black;border-collapse:collapse;">${BatchReportObj.Vendor}</td>
          <td style="text-align:left;width: 30%;border: 0.1px solid black;border-collapse:collapse;">${BatchReportObj.RemitTo}</td>
          <td style="text-align:center;width: 8%;border: 0.1px solid black;border-collapse:collapse;">${BatchReportObj.Pages}</td>
          <td style="text-align:right;width: 10%;border: 0.1px solid black;border-collapse:collapse;">${BatchReportObj.Proceeds}</td>
          <td style="text-align:center;width: 10%;border: 0.1px solid black;border-collapse:collapse;">${BatchStatus}</td>
        </tr>
     </table>`

  }

  var renderBatchReport = '';

  let TotalProceeds = 0.00
  for(var i =0; i< ArrayData.length; i++){
    TotalProceeds = TotalProceeds + Number(ArrayData[i].Proceeds)
  }

  TotalProceeds = TotalProceeds.toFixed(2)
  let NumArray = TotalProceeds.split('.')
  let Num = NumArray[0]
  let Decimal = NumArray[1]
  TotalProceeds = Intl.NumberFormat('en-US').format(Num) + '.' + Decimal
  
  renderBatchReport +=
  `<table style="align-items: center;width: 100%;font-size:80%;">
  <tr>
    <td style="padding-left:2%;padding-right:1%;"colspan="1">                	
      <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
      </br></br>
    </td>
    <td style="padding-left:0%; padding-right:2%;padding-top: 0%; vertical-align: top;text-align: end;"colspan="1">
      <span style="text-align-last: left;color:black;font-size: 120%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
      <span style="text-align: right;color:black;font-size: 80%;"><b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
      <span style="text-align: right;color:black;font-size: 80%;"><b> ALPHARETTA, GEORGIA 30022-8262 </b><br/><br/></span>
      <span style="text-align: right;color:black;font-size: 110%;"><b> Check Batch Report - Crop Year ${cropYearFromLS()}</b></span>
    </td>
  </tr>
</table>
<table style="text-align:center; font-size: 73%;margin-left: 2%;width: 96%;padding-top:10%;border-collapse:collapse;padding-top: 5%;">
  <tr>
    <td style="text-align:left;width: 14%;border: 0.1px solid black;border-collapse:collapse;">Check#</td>
    <td style="text-align:left;width: 28%;border: 0.1px solid black;border-collapse:collapse;">Vendor</td>
    <td style="text-align:left;width: 30%;border: 0.1px solid black;border-collapse:collapse;">Remit To</td>
    <td style="text-align:center;width: 8%;border: 0.1px solid black;border-collapse:collapse;">Pages</td>
    <td style="text-align:right;width: 10%;border: 0.1px solid black;border-collapse:collapse;">Proceeds</td>
    <td style="text-align:center;width: 10%;border: 0.1px solid black;border-collapse:collapse;">Status</td>
  </tr>
</table>
${tableData}
<table style="text-align:center; font-size: 73%;margin-left: 2%;width: 96%;border-collapse:collapse;">
  <tr>
    <td style="text-align:left;width: 14%;border: 0.1px solid black;border-collapse:collapse;"></td>
    <td style="text-align:left;width: 28%;border: 0.1px solid black;border-collapse:collapse;"></td>
    <td style="text-align:left;width: 30%;border: 0.1px solid black;border-collapse:collapse;"></td>
    <td style="text-align:center;width: 8%;border: 0.1px solid black;border-collapse:collapse;"> TOTAL </td>
    <td style="text-align:right;width: 10%;border: 0.1px solid black;border-collapse:collapse;"> $${TotalProceeds} </td>
    <td style="text-align:center;width: 10%;border: 0.1px solid black;border-collapse:collapse;"></td>
  </tr>
</table>`

return renderBatchReport;
}

  async function getOptionContract() {
    let Buy_Pt = ParentData.buying_Point
    let Check_Number = ParentData.check_num
    let VendorNumber = ParentData.vendor
    let response = await AccountPayment.RetrieveBatchCheckPrintDetails(Buy_Pt, Check_Number, VendorNumber)
      if (response != null && response != undefined && response.length > 0) {
        let resData = ''
        let Remarks = response[0].remarks == undefined || response[0].remarks == null || response[0].remarks == '' ? '' : response[0].remarks
        if(response[0].pay_rec != undefined){
          resData = response[0].pay_rec
        }
        let Total_pay_pounds = 0.00
        let Total_pay_amount = 0.00
        pageCount = 1;
        let tableData = '';
        let pageGap = '';
        pageGap += `<p style="page-break-after: always"></p>`
        var renderHeader = ''
        renderHeader = await getHeader(pageCount)
        var Column_Header = ''
        Column_Header += `
    <table style="text-align:center; font-size: 75%;margin-left: 2%;width: 96%;padding-top:2%;border: 1px solid black;border-collapse:collapse;">
    <tr>
     <td style="text-align:left;width: 9%;"><b>Type</b></td>
     <td style="text-align:left;width: 17%;"><b>Number</b></td>
     <td style="text-align:left;width: 9%;"><b>Type</b></td>
     <td style="text-align:left;width: 16%;"><b>Number</b></td>
     <td style="text-align:center;width: 4%;"><b>OPT</b></td>
     <td style="text-align:center;width: 4%;"><b>ST</b></td>
     <td style="text-align:center;width: 4%;"><b>CTY</b></td>
     <td style="text-align:center;width: 15%;"><b>Farm</b></td>
     <td style="text-align:right;width: 9%;"><b>Pounds</b></td>
     <td style="text-align:right;width: 9%;"><b>Amount</b></td>
   </tr>
   </table>`
        for (var i = 0; i < resData.length-1; i++) {
          Total_pay_amount = Total_pay_amount + Number(resData[i].pay_amount)
          let PayPounds = ''
          if(i==0){
            PayPounds = resData[i].pay_pounds
            Total_pay_pounds = Total_pay_pounds + Number(PayPounds)
            if(Number(PayPounds) == 0){
              PayPounds = ''
            }
            else{
              PayPounds = Intl.NumberFormat('en-US').format(PayPounds)
            }
          }
          if(i>0){
            // PRB0054898
            // if((resData[i].pay_pounds)==(resData[i-1].pay_pounds)){
            //   PayPounds =''
            // }
            // else{
              PayPounds = resData[i].pay_pounds
              Total_pay_pounds = Total_pay_pounds + Number(PayPounds)
              if(Number(PayPounds) == 0){
                PayPounds = ''
              }
              else{
                PayPounds = Intl.NumberFormat('en-US').format(PayPounds)
              }
            //}
          }
          let PayAmount = 0.00
          if(resData[i].pay_amount != undefined){
            PayAmount = Number(resData[i].pay_amount)
          }

          PayAmount  = PayAmount.toFixed(2)
          let NumArray = PayAmount.split('.')
          let Num = NumArray[0]
          let Decimal = NumArray[1]
          let payNumber1 = resData[i].pay_number_1
          PayAmount = Intl.NumberFormat('en-US').format(Num) + '.' + Decimal
          if((payNumber1.slice(0,5)) == 'SGNAD'){
            payNumber1 = payNumber1.slice(0,(payNumber1.length-1))
          }
          if (i < 40 * pageCount) {
            tableData += `
      <table style="text-align:center; font-size: 72%;margin-left: 2%;width: 96%;padding-top:2%;border-collapse:collapse;">
      <tr>
       <td style="text-align:left;width: 9%;"> ${resData[i].pay_type_1} </td>
       <td style="text-align:left;width: 17%;"> ${payNumber1} </td>
       <td style="text-align:left;width: 9%;"> ${resData[i].pay_type_2} </td>
       <td style="text-align:left;width: 16%;"> ${resData[i].pay_number_2} </td>
       <td style="width: 4%;"> ${resData[i].pay_option_ind} </td>
       <td style="width: 4%;"> ${resData[i].pay_state}</td>
       <td style="width: 4%;"> ${resData[i].pay_county} </td>
       <td style="width: 15%;"> ${resData[i].pay_farm} ${resData[i].pay_farm_suffix}</td>
       <td style="text-align:right;width: 9%;"> ${PayPounds} </td>
       <td style="text-align:right;width: 9%;"> ${PayAmount} </td>
     </tr>
     </table>`
          }
          if (i == 40 * pageCount) {
            pageCount = pageCount + 1
            tableData += `
    <table style="text-align:center; font-size: 72%;margin-left: 2%;width: 96%;padding-top:2%;border-collapse:collapse;">
    <tr>
     <td style="text-align:left;width: 9%;"> ${resData[i].pay_type_1} </td>
     <td style="text-align:left;width: 17%;"> ${resData[i].pay_number_1} </td>
     <td style="text-align:left;width: 9%;"> ${resData[i].pay_type_2} </td>
     <td style="text-align:left;width: 16%;"> ${resData[i].pay_number_2} </td>
     <td style="width: 4%;"> ${resData[i].pay_option_ind} </td>
     <td style="width: 4%;"> ${resData[i].pay_state}</td>
     <td style="width: 4%;"> ${resData[i].pay_county} </td>
     <td style="width: 15%;"> ${resData[i].pay_farm} ${resData[i].pay_farm_suffix}</td>
     <td style="text-align:right;width: 9%;"> ${PayPounds} </td>
     <td style="text-align:right;width: 9%;"> ${PayAmount} </td>
   </tr>
   </table>
   ${pageGap}
   ${await getHeader(pageCount)}
   ${Column_Header}`
          }
        }
        let Total_pay_pounds_R = Total_pay_pounds.toFixed(2)
        if(Number(Total_pay_pounds) == 0){
          Total_pay_pounds_R = ''
        }
        else{
          Total_pay_pounds_R = Intl.NumberFormat('en-US').format(Total_pay_pounds_R)
        }

        Total_pay_amount  = Total_pay_amount.toFixed(2)
        let NoArray = Total_pay_amount.split('.')
        let No = NoArray[0]
        let Decimalpt = NoArray[1]
        let Total_pay_amount_R = Intl.NumberFormat('en-US').format(No) + '.' + Decimalpt
        // setData(thisObj, 'TotalPayPound', Total_pay_pounds_R)
        // setData(thisObj, 'TotalPayAmount', Total_pay_amount_R)
        var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
        var optionPage = `<html>
    <p>
    ${renderHeader}
    ${Column_Header}
    ${tableData}
<table style="text-align:center; font-size: 75%;margin-left: 2%;;width: 96%;padding-top:2%;border: 1px solid black;border-collapse:collapse;">
  <tr>
    <td style="text-align:left;width: 14%;"><b>Grand Total</b></td>
    <td style="text-align:left;width: 9%;"><b> </b></td>
    <td style="text-align:left;width: 9%;"><b> </b></td>
    <td style="text-align:left;width: 13%;"><b> </b></td>
    <td style="text-align:center;width: 6%;"><b> </b></td>
    <td style="text-align:center;width: 6%;"><b> </b></td>
    <td style="text-align:center;width: 6%;"><b> </b></td>
    <td style="text-align:center;width: 15%;"><b> </b></td>
    <td style="text-align:right;width: 9%;"><b>${Total_pay_pounds_R}</b></td>
    <td style="text-align:right;width: 9%;"><b>${Total_pay_amount_R}</b></td>
  </tr>
</table>
<table style="text-align:left; font-size: 75%;margin-left: 2%;;width: 96%;padding-top:2%;border: none;border-collapse:collapse;">
  <tr>
    <td>${Remarks}</td>
  </tr>
</table>
    </p>`
      return optionPage + `</html>`        
      } else {
        return  `<p>No response found</p>`
        
      }
  }

  async function getOptionContract2() {
    let Buy_Pt = ParentData.buying_Point
    let Check_Number = ParentData.check_num
    let VendorNumber = ParentData.vendor
    let response = await AccountPayment.RetrieveBatchCheckPrintDetails(Buy_Pt, Check_Number, VendorNumber)
      if (response != null && response != undefined && response.length > 0) {
        let resData = ''
        let Remarks = response[0].remarks == undefined || response[0].remarks == null || response[0].remarks == '' ? '' : response[0].remarks
        if(response[0].pay_rec != undefined){
          resData = response[0].pay_rec
        }
        let Total_pay_pounds = 0.00
        let Total_pay_amount = 0.00
        for (var i = 0; i < resData.length - 1; i++) {
          let PayPound = ''
          Total_pay_amount = Total_pay_amount + Number(resData[i].pay_amount)
          if (i == 0) {
            PayPound = resData[i].pay_pounds
            Total_pay_pounds = Total_pay_pounds + Number(PayPound)
          }
          if (i > 0) {
            if ((resData[i].pay_pounds) == (resData[i - 1].pay_pounds)) {
              PayPound = ''
            }
            else {
              PayPound = resData[i].pay_pounds
              Total_pay_pounds = Total_pay_pounds + Number(PayPound)
            }
          }
        }
        pageCount = 1;
        let MaxLimit = 25;
        if(resData.length <= 26){
          MaxLimit = resData.length-1;
        }
        let tableData1 = '';
        let pageGap = '';
        pageGap += `<p style="page-break-after: always"></p>`
        var renderHeader = ''
        renderHeader = await getHeader2(pageCount)
        var Column_Header = ''
        Column_Header += `
    <table style="text-align:center; font-size: 75%;margin-left: 2%;width: 96%;padding-top:5%;border: 1px solid black;border-collapse:collapse;">
    <tr>
     <td style="text-align:left;width: 9%;"><b>Type</b></td>
     <td style="text-align:left;width: 17%;"><b>Number</b></td>
     <td style="text-align:left;width: 9%;"><b>Type</b></td>
     <td style="text-align:left;width: 16%;"><b>Number</b></td>
     <td style="text-align:center;width: 4%;"><b>OPT</b></td>
     <td style="text-align:center;width: 4%;"><b>ST</b></td>
     <td style="text-align:center;width: 4%;"><b>CTY</b></td>
     <td style="text-align:center;width: 15%;"><b>Farm</b></td>
     <td style="text-align:right;width: 9%;"><b>Pounds</b></td>
     <td style="text-align:right;width: 9%;"><b>Amount</b></td>
   </tr>
   </table>`
        for (var i = 0; i < MaxLimit; i++) {
          let PayPounds = ''
          if(i==0){
            PayPounds = resData[i].pay_pounds
            if(Number(PayPounds) == 0){
              PayPounds = ''
            }
            else{
              PayPounds = Intl.NumberFormat('en-US').format(PayPounds)
            }
          }
          if(i>0){
            // if((resData[i].pay_pounds)==(resData[i-1].pay_pounds)){
            //   PayPounds =''
            // }
            // else{
              PayPounds = resData[i].pay_pounds
              if(Number(PayPounds) == 0){
                PayPounds = ''
              }
              else{
                PayPounds = Intl.NumberFormat('en-US').format(PayPounds)
              }
            //}
          }
          let PayAmount = ''
          if(resData[i].pay_amount != undefined){
            PayAmount = resData[i].pay_amount
          }
          var payNumber1 = resData[i].pay_number_1
          if((payNumber1.slice(0,5)) == 'SGNAD'){
            payNumber1 = payNumber1.slice(0,(payNumber1.length-1))
          }

          let NumArray = PayAmount.split('.')
          let Num = NumArray[0]
          let Decimal = NumArray[1]
          PayAmount = Intl.NumberFormat('en-US').format(Num) + '.' + Decimal
          if (i < MaxLimit) {
            tableData1 += `
      <table style="text-align:center; font-size: 72%;margin-left: 2%;width: 96%;padding-top:2%;border-collapse:collapse;">
      <tr>
       <td style="text-align:left;width: 9%;"> ${resData[i].pay_type_1} </td>
       <td style="text-align:left;width: 17%;"> ${payNumber1} </td>
       <td style="text-align:left;width: 9%;"> ${resData[i].pay_type_2} </td>
       <td style="text-align:left;width: 16%;"> ${resData[i].pay_number_2} </td>
       <td style="width: 4%;"> ${resData[i].pay_option_ind} </td>
       <td style="width: 4%;"> ${resData[i].pay_state}</td>
       <td style="width: 4%;"> ${resData[i].pay_county} </td>
       <td style="width: 15%;"> ${resData[i].pay_farm} ${resData[i].pay_farm_suffix}</td>
       <td style="text-align:right;width: 9%;"> ${PayPounds} </td>
       <td style="text-align:right;width: 9%;"> ${PayAmount} </td>
     </tr>
     </table>`
          }
        }
   
        let Total_pay_pounds_R = Intl.NumberFormat('en-US').format(Total_pay_pounds)
        if(Number(Total_pay_pounds) == 0){
          Total_pay_pounds_R = ''
        }
        let Total_pay_amount_R = Total_pay_amount.toFixed(2)
        setData(thisObj, 'TotalPayPound', Total_pay_pounds_R)
        setData(thisObj, 'TotalPayAmount', Total_pay_amount_R)
        let NoArray = Total_pay_amount_R.split('.')
        let No = NoArray[0]
        let Decimalpt = NoArray[1]
        Total_pay_amount_R = Intl.NumberFormat('en-US').format(No)+ '.' + Decimalpt
        var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'


        
        let EmptyLineCount = 0
        if(resData.length <= 26){
          EmptyLineCount = 36 - MaxLimit
        }
        else if( resData.length >26){
          EmptyLineCount = 42 - MaxLimit
        }


  if(resData.length <= 26){
  var optionPage = `<html>
    <p>
    ${renderHeader}
    ${Column_Header}
    ${tableData1}
    <table style="text-align:center; font-size: 75%;margin-left: 2%;;width: 96%;padding-top:2%;border: 1px solid black;border-collapse:collapse;">
      <tr>
        <td style="text-align:left;width: 14%;"><b>Grand Total</b></td>
        <td style="text-align:left;width: 9%;"><b> </b></td>
        <td style="text-align:left;width: 9%;"><b> </b></td>
        <td style="text-align:left;width: 13%;"><b> </b></td>
        <td style="text-align:center;width: 6%;"><b> </b></td>
        <td style="text-align:center;width: 6%;"><b> </b></td>
        <td style="text-align:center;width: 6%;"><b> </b></td>
        <td style="text-align:center;width: 15%;"><b> </b></td>
        <td style="text-align:right;width: 9%;"><b>${Total_pay_pounds_R}</b></td>
        <td style="text-align:right;width: 9%;"><b>${Total_pay_amount_R}</b></td>
      </tr>
    </table>
    
    <table style="text-align:left; font-size: 75%;margin-left: 2%;;width: 96%;padding-top:2%;border: none;border-collapse:collapse;">
      <tr>
        <td>${Remarks}</td>
      </tr>
    </table>
    ${await Footer()}
    </p>`
  }
  else if( resData.length >26){
    var optionPage = `<html>
    <p>
    ${renderHeader}
    ${Column_Header}
    ${tableData1} 
    ${await Footer()}
    </p>`

    let ArrayData = getData(thisObj, 'ArrayData')
    ParentData = ArrayData[0]
    await Print3(ParentData)
  }

return optionPage + `</html>`        
} else {
  return  `<p>No response found</p>` 
  }   
}

async function getOptionContract3() {
  let Buy_Pt = ParentData.buying_Point
  let Check_Number = ParentData.check_num
  let VendorNumber = ParentData.vendor
  let response = await AccountPayment.RetrieveBatchCheckPrintDetails(Buy_Pt, Check_Number, VendorNumber)
    if (response != null && response != undefined && response.length > 0) {
      let resData = ''
      let Remarks = response[0].remarks == undefined || response[0].remarks == null || response[0].remarks == '' ? '' : response[0].remarks
      if(response[0].pay_rec != undefined){
        resData = response[0].pay_rec
      }

      pageCount = 2;
      let tableData = '';
      let pageGap = '';
      pageGap += `<p style="page-break-after: always"></p>`
      var renderHeader = ''
      renderHeader = await getHeader3(pageCount)
      var Column_Header = ''
      Column_Header += `
  <table style="text-align:center; font-size: 75%;margin-left: 2%;width: 96%;padding-top:2%;border: 1px solid black;border-collapse:collapse;">
  <tr>
   <td style="text-align:left;width: 9%;"><b>Type</b></td>
   <td style="text-align:left;width: 17%;"><b>Number</b></td>
   <td style="text-align:left;width: 9%;"><b>Type</b></td>
   <td style="text-align:left;width: 16%;"><b>Number</b></td>
   <td style="text-align:center;width: 4%;"><b>OPT</b></td>
   <td style="text-align:center;width: 4%;"><b>ST</b></td>
   <td style="text-align:center;width: 4%;"><b>CTY</b></td>
   <td style="text-align:center;width: 15%;"><b>Farm</b></td>
   <td style="text-align:right;width: 9%;"><b>Pounds</b></td>
   <td style="text-align:right;width: 9%;"><b>Amount</b></td>
 </tr>
 </table>`
      for (var i = 25; i < resData.length-1; i++) {
        let PayPounds = ''
        if(i==0){
          PayPounds = resData[i].pay_pounds
          if(Number(PayPounds) == 0){
            PayPounds = ''
          }
          else{
            PayPounds = Intl.NumberFormat('en-US').format(PayPounds)
          }
        }
        if(i>0){
          // if((resData[i].pay_pounds)==(resData[i-1].pay_pounds)){
          //   PayPounds =''
          // }
          // else{
            PayPounds = resData[i].pay_pounds
            if(Number(PayPounds) == 0){
              PayPounds = ''
            }
            else{
              PayPounds = Intl.NumberFormat('en-US').format(PayPounds)
            }
          //}
        }
        let PayAmount = 0.00
        if(resData[i].pay_amount != undefined){
          PayAmount = Number(resData[i].pay_amount)
        }

        PayAmount  = PayAmount.toFixed(2)
        let NumArray = PayAmount.split('.')
        let Num = NumArray[0]
        let Decimal = NumArray[1]
        let payNumber1 = resData[i].pay_number_1
        PayAmount = Intl.NumberFormat('en-US').format(Num) + '.' + Decimal
        if((payNumber1.slice(0,5)) == 'SGNAD'){
          payNumber1 = payNumber1.slice(0,(payNumber1.length-1))
        }
        if (i < ((40 * (pageCount-1))+25)) {
          tableData += `
    <table style="text-align:center; font-size: 72%;margin-left: 2%;width: 96%;padding-top:2%;border-collapse:collapse;">
    <tr>
     <td style="text-align:left;width: 9%;"> ${resData[i].pay_type_1} </td>
     <td style="text-align:left;width: 17%;"> ${payNumber1} </td>
     <td style="text-align:left;width: 9%;"> ${resData[i].pay_type_2} </td>
     <td style="text-align:left;width: 16%;"> ${resData[i].pay_number_2} </td>
     <td style="width: 4%;"> ${resData[i].pay_option_ind} </td>
     <td style="width: 4%;"> ${resData[i].pay_state}</td>
     <td style="width: 4%;"> ${resData[i].pay_county} </td>
     <td style="width: 15%;"> ${resData[i].pay_farm} ${resData[i].pay_farm_suffix}</td>
     <td style="text-align:right;width: 9%;"> ${PayPounds} </td>
     <td style="text-align:right;width: 9%;"> ${PayAmount} </td>
   </tr>
   </table>`
        }
        if (i == ((40 * (pageCount -1))+25)) {
          pageCount = pageCount + 1
          tableData += `
  <table style="text-align:center; font-size: 72%;margin-left: 2%;width: 96%;padding-top:2%;border-collapse:collapse;">
  <tr>
   <td style="text-align:left;width: 9%;"> ${resData[i].pay_type_1} </td>
   <td style="text-align:left;width: 17%;"> ${resData[i].pay_number_1} </td>
   <td style="text-align:left;width: 9%;"> ${resData[i].pay_type_2} </td>
   <td style="text-align:left;width: 16%;"> ${resData[i].pay_number_2} </td>
   <td style="width: 4%;"> ${resData[i].pay_option_ind} </td>
   <td style="width: 4%;"> ${resData[i].pay_state}</td>
   <td style="width: 4%;"> ${resData[i].pay_county} </td>
   <td style="width: 15%;"> ${resData[i].pay_farm} ${resData[i].pay_farm_suffix}</td>
   <td style="text-align:right;width: 9%;"> ${PayPounds} </td>
   <td style="text-align:right;width: 9%;"> ${PayAmount} </td>
 </tr>
 </table>
 ${pageGap}
 ${await getHeader3(pageCount)}
 ${Column_Header}`
        }
      }

      let TotalPayPound = getData(thisObj, 'TotalPayPound')
      let Total_pay_pounds_R = TotalPayPound
      if(Number(getData(thisObj, 'TotalPayPound')) == 0){
        Total_pay_pounds_R = ''
      }
      else{
        Total_pay_pounds_R = TotalPayPound
      }

      let TotalPayAmount = getData(thisObj, 'TotalPayAmount')
      let Total_pay_amount  = Number(TotalPayAmount).toFixed(2)
      let NoArray = Total_pay_amount.split('.')
      let No = NoArray[0]
      let Decimalpt = NoArray[1]
      let Total_pay_amount_R = Intl.NumberFormat('en-US').format(No) + '.' + Decimalpt
      var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
      var optionPage = `<html>
  <p>
  ${renderHeader}
  ${Column_Header}
  ${tableData}
<table style="text-align:center; font-size: 75%;margin-left: 2%;;width: 96%;padding-top:2%;border: 1px solid black;border-collapse:collapse;">
<tr>
  <td style="text-align:left;width: 14%;"><b>Grand Total</b></td>
  <td style="text-align:left;width: 9%;"><b> </b></td>
  <td style="text-align:left;width: 9%;"><b> </b></td>
  <td style="text-align:left;width: 13%;"><b> </b></td>
  <td style="text-align:center;width: 6%;"><b> </b></td>
  <td style="text-align:center;width: 6%;"><b> </b></td>
  <td style="text-align:center;width: 6%;"><b> </b></td>
  <td style="text-align:center;width: 15%;"><b> </b></td>
  <td style="text-align:right;width: 9%;"><b>${Total_pay_pounds_R}</b></td>
  <td style="text-align:right;width: 9%;"><b>${Total_pay_amount_R}</b></td>
</tr>
</table>
<table style="text-align:left; font-size: 75%;margin-left: 2%;;width: 96%;padding-top:2%;border: none;border-collapse:collapse;">
  <tr>
    <td>${Remarks}</td>
  </tr>
</table>
  </p>`
    return optionPage + `</html>`        
    } else {
      return  `<p>No response found</p>` 
    }
}

const getBlobURL = (code, type) => {
    const blob = new Blob([code], { type });
    return URL.createObjectURL(blob)
}

  const Print = async () => {
    try{
      var code = await GetHtmlCode()
      var type = 'text/html'
      var fileName = "PDF"
      const dataUrl1 = window.URL.createObjectURL(new Blob([code], { type }));
      const pdfWindow = window.open(dataUrl1, '', 'width=1000,height=850,scrollbars=1,menuBar=1,name=' + fileName)
      pdfWindow.focus()
      pdfWindow.addEventListener('beforeprint', event => {})
      pdfWindow.print()
      pdfWindow.addEventListener('afterprint', (event) => {
        //pdfWindow.close() //Commented this line as PDF window was getting closed before print operation
      })
    }
    catch(err){
      errorHandler(err)
    }
  }

  const Print2 = async () => {
    try{
      var code = await GetHtmlCode2()
      var type = 'text/html'
      var fileName = "PDF"
      const dataUrl1 = window.URL.createObjectURL(new Blob([code], { type }));
      const pdfWindow = window.open(dataUrl1, '', 'width=1000,height=850,scrollbars=1,menuBar=1,name=' + fileName)
      pdfWindow.focus()
      pdfWindow.addEventListener('beforeprint', event => {
      document.title = fileName
      })
      pdfWindow.print()
      pdfWindow.addEventListener('afterprint', (event) => {
      //pdfWindow.close() //Commented this line as PDF window was getting closed before print operation
      })
    }
    catch(err){
      errorHandler(err)
    }
  }

  const Print3 = async () => {
    try{
      var code = await GetHtmlCode3()
      var type = 'text/html'
      var fileName = "PDF"
      const dataUrl1 = window.URL.createObjectURL(new Blob([code], { type }));
      const pdfWindow = window.open(dataUrl1, '', 'width=1000,height=850,scrollbars=1,menuBar=1,name=' + fileName)
      pdfWindow.focus()
      pdfWindow.addEventListener('beforeprint', event => {})
      pdfWindow.print()
      pdfWindow.addEventListener('afterprint', (event) => {
        //pdfWindow.close() //Commented this line as PDF window was getting closed before print operation
      })
    }
    catch(err){
      errorHandler(err)
    }
  }

  function errorHandler(err){
    console.log(err)
    if(err.message == "Cannot read properties of null (reading 'focus')")
      alert('Please allow your browser to display pop up window and then click on ok to show the print window.')
    else
      showMessage(thisObj, err.message)
  }

  const PrintBatchReport = async () => {
    try{
      var code = await BatchReport()
      var type = 'text/html'
      var fileName = "PDF"
      const dataUrl1 = window.URL.createObjectURL(new Blob([code], { type }));
      const pdfWindow = window.open(dataUrl1, '', 'width=1000,height=850,scrollbars=1,menuBar=1,name=' + fileName)
      pdfWindow.focus()
      pdfWindow.addEventListener('beforeprint', event => {
        document.title = fileName
      })
      pdfWindow.print()
      pdfWindow.addEventListener('afterprint', (event) => {
      //pdfWindow.close() //Commented this line as PDF window was getting closed before print operation
      })
    }
    catch(err){
      errorHandler(err)
    }
  }

const onbtnPrintClick = async () =>{
  try{
    let ArrayData = getData(thisObj, 'ArrayData')
    if(ArrayData[0].ScreenName == 'PrintChecks'){
      for(var i =0; i< ArrayData.length; i++){
        ParentData = ArrayData[i]
        if(ParentData.Proceeds >0){
          await Print2(ParentData)
        }
      }
      await PrintBatchReport()
      for(var i =0; i< ArrayData.length; i++){
        ParentData = ArrayData[i]
        if(ParentData.Proceeds >0){
          await Print(ParentData)
        }
      }
    }
    if(ArrayData[0].ScreenName == 'SpecialPayablesProfile' || ArrayData[0].ScreenName == 'READYTOPAYRELEASE' || ArrayData[0].ScreenName == 'INTRESTAGREEMENTPROFILE'){
      ParentData = ArrayData[0]
      await Print2(ParentData)
      await Print(ParentData)
    }
    if(ArrayData[0].ScreenName == 'ReviewCheck'){
      ParentData = ArrayData[0]
      await Print(ParentData)
    }
    document.getElementById("AccountPayment_ReportPreviewCheckPopUp").childNodes[0].click();
  }  catch (err) {
    console.log(err)
    if (err instanceof EvalError) {
        alert(err.message);
    } else {
        alert("Exception in PreSubmit Event code for widget:btnPrint event:Click");
    }
}
}
thisObj.onbtnPrintClick = onbtnPrintClick

const onbtnCloseClick = () => {
  try {
    document.getElementById("AccountPayment_ReportPreviewCheckPopUp").childNodes[0].click();
  } catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnClose event:Click");
    }
    return false;
  }
  return true;
};
thisObj.onbtnCloseClick = onbtnCloseClick;

function formatDate(dateVal) {
  var newDate = new Date(dateVal);
  var sMonth = (newDate.getMonth() + 1);
  var sDay = padValue(newDate.getDate());
  var sYear = newDate.getFullYear();
  var sHour = newDate.getHours();
  var sMinute = padValue(newDate.getMinutes());
  var sSecond = padValue(newDate.getSeconds());
  var sAMPM = "AM";

  var iHourCheck = parseInt(sHour);

  if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = iHourCheck - 12;
  }
  else if (iHourCheck === 0) {
      sHour = "12";
  }
  //sHour = padValue(sHour);
  return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + ":" + sSecond + " " + sAMPM;
}

function padValue(value) {
  return (value < 10) ? "0" + value : value;
}


  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} headerDetails={state.headerDetails} />
          
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ReportPreviewCheck*/}
              <iframe src={state.renderUrl} id="docFrame" width="64%" height="500px" style={{marginLeft:"17%",background:"White"}}></iframe>
              {/* END_USER_CODE-USER_BEFORE_ReportPreviewCheck*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPreviewActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPreviewActions*/}

              <GroupBoxWidget
                conf={state.grpbxPreviewActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnPrintSetup*/}
                {/* <iframe src="http://africau.edu/images/default/sample.pdf" width="100%" height="500px"></iframe> */}
              
                {/* END_USER_CODE-USER_BEFORE_btnPrintSetup*/}

                <ButtonWidget
                  conf={state.btnPrintSetup}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintSetup*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintSetup*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btnSaveasPDF*/}

                {/* END_USER_CODE-USER_BEFORE_btnSaveasPDF*/}

                <ButtonWidget
                  conf={state.btnSaveasPDF}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSaveasPDF*/}

                {/* END_USER_CODE-USER_AFTER_btnSaveasPDF*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btn1*/}

                {/* END_USER_CODE-USER_BEFORE_btn1*/}

                <ButtonWidget
                  conf={state.btn1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn1*/}

                {/* END_USER_CODE-USER_AFTER_btn1*/}
                {/* START_USER_CODE-USER_BEFORE_btn2*/}

                {/* END_USER_CODE-USER_BEFORE_btn2*/}

                <ButtonWidget
                  conf={state.btn2}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn2*/}

                {/* END_USER_CODE-USER_AFTER_btn2*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPreviewActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPreviewActions*/}

              {/* START_USER_CODE-USER_AFTER_ReportPreviewCheck*/}

              {/* END_USER_CODE-USER_AFTER_ReportPreviewCheck*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(AccountPayment_ReportPreviewCheck);
