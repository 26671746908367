/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  ListboxWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
  goTo,
  enable,
  disable
} from "../../shared/WindowImports";

import "./LoanRepaySetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import Loading from "../../../Loader/Loading";
import moment from "moment";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_LoanRepaySetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "LoanRepaySetup",
    windowName: "LoanRepaySetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.LoanRepaySetup",
    // START_USER_CODE-USER_LoanRepaySetup_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Loan Repayment Setup",
      scrCode: "PN0170A",
    },
    // END_USER_CODE-USER_LoanRepaySetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxLoanRepaySetup",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxLoanRepaySetup",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxLoanRepaySetup",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxLoanRepaySetup",
      Label: "Peanut Type:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    lblLoanRepayment: {
      name: "lblLoanRepayment",
      type: "LabelWidget",
      parent: "grpbxLoanRepaySetup",
      Label: "Loan Repayment:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLoanRepayment_PROPERTIES

      // END_USER_CODE-USER_lblLoanRepayment_PROPERTIES
    },
    lstLoanRepayment: {
      name: "lstLoanRepayment",
      type: "ListBoxFieldWidget",
      parent: "grpbxLoanRepaySetup",
      ColSpan: "4",
      DataProviderForListBox: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstLoanRepayment_PROPERTIES

      // END_USER_CODE-USER_lstLoanRepayment_PROPERTIES
    },
    grpbxLoanRepaySetup: {
      name: "grpbxLoanRepaySetup",
      type: "GroupBoxWidget",
      parent: "LoanRepaySetup",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxLoanRepaySetup_PROPERTIES

      // END_USER_CODE-USER_grpbxLoanRepaySetup_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "LoanRepaySetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceSpecialFunctions#LoanRepayProfile": {}
      },
      REVERSE: {
        "SystemMaintenanceSpecialFunctions#LoanRepayProfile": {}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAdd: {
    //   DEFAULT: ["SystemMaintenanceSpecialFunctions#LoanRepayProfile#DEFAULT#true#Click"],
    // },

    // btnEdit: {
    //   DEFAULT: ["SystemMaintenanceSpecialFunctions#LoanRepayProfile#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(()=>{
    loanRepayUpdate()
  },[getData(thisObj,'frmloanrepayprofile')])

  let loanRepayUpdate=async()=>{
    let refresh=getData(thisObj,'frmloanrepayprofile')
    if(refresh !== null && refresh != "" && refresh != undefined){
      let pnutTypeId=refresh.pnut_type_id
      await bFillLoanRepayList(pnutTypeId)
      await EnableDisableControls()
    }
    setData(thisObj,'frmloanrepayprofile','')
  }
  // START_USER_CODE-USER_METHODS
  const FormLoad=async()=>{
    setLoading(true);
    await bFillTypeList();
    let Peanut_type_id=getData(thisObj,'Peanut_type_id')
    await bFillLoanRepayList(Peanut_type_id)
    await EnableDisableControls();
    setLoading(false)
  }

  let bFillTypeList=async()=>{
    try{
    let obj={};
    let js=[];

    let response=await ContractManagementService.RetrievePeanutTypeControls();
    let data=response;
    if(data!=undefined && data!=null && data.length>0){
      for(let i=0;i<data.length;i++){
        let dataExist=js.find(elem=>elem.key==data[i].pnutTypeId)
        if(dataExist==undefined){
        obj={
          key:data[i].pnutTypeId,
          description:data[i].pnutTypeName
        }
        js.push(obj);
        obj={};
      }
      }
    }
    setValue(thisObj, 'ddPeanutType', js.at(0).key);
    setData(thisObj, 'Peanut_type_id', js.at(0).key);
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutType: {
          ...state["ddPeanutType"],
          valueList: js
        }
      }
    })
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred loading peanut dropdown"
      );
    }
  }
  }

  let bFillLoanRepayList=async(Peanut_type_id,index=null)=>{
    try{
    let js=[];
    let obj={};
    let response=await SettlementService.RetrieveLoanRePaymentControlDetails(Peanut_type_id,null,null)
    let data=response;
    if(data!=undefined && data!=null && data.length>0){
      for(let i=0;i<data.length;i++){
        let dataExist=js.find(elem=>elem.key==data[i].effectDateTime)
        if(dataExist==undefined){
        obj={
          key:data[i].effectDateTime,
          description:moment(data[i].effectDateTime).format('MM/DD/YYYY') +" - "+moment(data[i].effectDateTime).format('hh:mm A')+ " - " + Number(data[i].ratePerTon).toFixed(2),
          date:data[i].effectDateTime,
          index: i
        }
        js.push(obj);
        obj={};
      }
      }
    }
    if(js.length>0){
    setValue(thisObj, 'lstLoanRepayment', [js.at(0).key]); 
    }
    setData(thisObj,'repaymentListLength',js.length)
    thisObj.setState(current => {
      return {
        ...current,
        lstLoanRepayment: {
          ...state["lstLoanRepayment"],
          valueList: js
        }
      }
    })
    if(index!=null){
      if (js !== undefined && js.length > 0) {
        if (js.length > index) {
          setValue(thisObj, 'lstLoanRepayment', [js.at(index).key]); //will select next value
        } else {
          setValue(thisObj, 'lstLoanRepayment', [js.at(index - 1).key]); //will select previous value
        }
      }
    }
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred during loading grid"
      );
    }
  }
  }

  let onbtnAddClick=async()=>{
    try{
    let peanutList= thisObj.state['ddPeanutType'].valueList
    let peanut_type_id=getValue(thisObj,'ddPeanutType')
    let peanutName='';
    if (peanut_type_id !== undefined && peanut_type_id !== null && peanut_type_id !== '') {
      peanutName = peanutList.find(elem => elem.key == peanut_type_id).description

    }

    let frmLoanRepayProfile={};
    frmLoanRepayProfile.cmdOkCaption = "Add"
    frmLoanRepayProfile.lblPeanutTypeTag =peanut_type_id
    frmLoanRepayProfile.lblPeanutTypeCaption = peanutName
    setData(thisObj, "frmloanrepaysetup", frmLoanRepayProfile)
    goTo(thisObj,'SystemMaintenanceSpecialFunctions#LoanRepayProfile#DEFAULT#true#Click')
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred during executing Add button"
      );
    }
  }

  }
  thisObj.onbtnAddClick=onbtnAddClick

  let onbtnEditClick=async()=>{
    try{
    let peanutList= thisObj.state['ddPeanutType'].valueList
    let repaymentList=thisObj.state['lstLoanRepayment'].valueList
    let peanut_type_id=getValue(thisObj,'ddPeanutType')
    let effectiveDateTime=getValue(thisObj,'lstLoanRepayment')[0]
    let date=''
    let peanutName='';
    if (peanut_type_id !== undefined && peanut_type_id !== null && peanut_type_id !== '') {
      peanutName = peanutList.find(elem => elem.key == peanut_type_id).description
    }

    if (effectiveDateTime !== undefined && effectiveDateTime !== null && effectiveDateTime !== '') {
      date = repaymentList.find(elem => elem.key == effectiveDateTime).date
    }

    let response=await SettlementService.RetrieveLoanRePaymentControlDetails(peanut_type_id,date,null)
    let frmLoanRepayProfile={};
    frmLoanRepayProfile.lblPeanutTypeTag =peanut_type_id
    frmLoanRepayProfile.lblPeanutTypeCaption = peanutName
    frmLoanRepayProfile.txtEffectiveDateTime = moment(response[0].effectDateTime).format('MM/DD/YYYY hh:mm A')
    frmLoanRepayProfile.cmdOkCaption = "Update"
    frmLoanRepayProfile.txtRatePerTon=Number(response[0].ratePerTon).toFixed(2)
    frmLoanRepayProfile.lblAddedByCaption=response[0].addUser+" "+moment(response[0].addDateTime).format('MM/DD/YYYY')
    frmLoanRepayProfile.lblChangedByCaption=response[0].chgUser+" "+moment(response[0].chgDateTime).format('MM/DD/YYYY')
    setData(thisObj, "frmloanrepaysetup", frmLoanRepayProfile)
    goTo(thisObj,'SystemMaintenanceSpecialFunctions#LoanRepayProfile#DEFAULT#true#Click')
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred during executing Edit button"
      );
    }
  }

  }
  thisObj.onbtnEditClick=onbtnEditClick

  let onbtnDeleteClick=async()=>{
    try{
    let repaymentList=thisObj.state['lstLoanRepayment'].valueList
    let peanut_type_id=getValue(thisObj,'ddPeanutType')
    let effectiveDateTime=getValue(thisObj,'lstLoanRepayment')[0]
    let loanDetails=''
    if (effectiveDateTime !== undefined && effectiveDateTime !== null && effectiveDateTime !== '') {
      loanDetails = repaymentList.find(elem => elem.key == effectiveDateTime).description
    }
    if(effectiveDateTime==null){
      showMessage(thisObj,'Please select a Loan Repayment to delete')
      return;
    }
    let index = repaymentList.find(item => item.key === effectiveDateTime).index
    let res=confirm(`Are you sure you wish to delete this Loan Repayment \n'${loanDetails}'`)
    if(res==true){
      let response=await SystemMaintenanceSpecialFunctionsService.RemoveLoanRePaymentControl(null,peanut_type_id,effectiveDateTime);
      if (![200, 400, 404].includes(response.status)) {
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
      }
      else if(response.status!=200){
        showMessage(thisObj, response.message,true)
      }
      
      await bFillLoanRepayList(peanut_type_id,index)
      await EnableDisableControls();
    }
    else{
      return;
    }

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during executing delete button"
        );
      }
    }
  }
  thisObj.onbtnDeleteClick=onbtnDeleteClick

  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_LoanRepaySetupPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const onddPeanutTypeChange=async()=>{
    setLoading(true)
    let peanut_type_id=getValue(thisObj,'ddPeanutType')
    await bFillLoanRepayList(peanut_type_id)
    await EnableDisableControls();
    setLoading(false)
  }
  thisObj.onddPeanutTypeChange=onddPeanutTypeChange

  const EnableDisableControls=async()=>{
    let peanut_type_id=getValue(thisObj,'ddPeanutType')
    let listLength=getData(thisObj,'repaymentListLength')
    if(peanut_type_id==null || peanut_type_id==undefined || peanut_type_id==''){ 
      disable(thisObj,'btnAdd')
      disable(thisObj,'btnEdit')
      disable(thisObj,'btnDelete')
      return
    }
    else{
      enable(thisObj,'btnAdd')
      if(listLength<=0){
        disable(thisObj,'btnEdit')
        disable(thisObj,'btnDelete')
        return;
      }
      else{
        enable(thisObj,'btnEdit')
        enable(thisObj,'btnDelete')
        return;
      }
    }
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_LoanRepaySetup*/}

              {/* END_USER_CODE-USER_BEFORE_LoanRepaySetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxLoanRepaySetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxLoanRepaySetup*/}

              <GroupBoxWidget
                conf={state.grpbxLoanRepaySetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_lblLoanRepayment*/}

                {/* END_USER_CODE-USER_BEFORE_lblLoanRepayment*/}

                <LabelWidget
                  values={values}
                  conf={state.lblLoanRepayment}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblLoanRepayment*/}

                {/* END_USER_CODE-USER_AFTER_lblLoanRepayment*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_lstLoanRepayment*/}

                {/* END_USER_CODE-USER_BEFORE_lstLoanRepayment*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstLoanRepayment}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstLoanRepayment*/}

                {/* END_USER_CODE-USER_AFTER_lstLoanRepayment*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxLoanRepaySetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxLoanRepaySetup*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_LoanRepaySetup*/}

              {/* END_USER_CODE-USER_AFTER_LoanRepaySetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_LoanRepaySetup);
