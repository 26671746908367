/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_PriceScheduleSetup from "./PriceScheduleSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PriceScheduleSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_PriceScheduleSetup />
      );
    });
  });
  afterEach(cleanup);
  test("is PriceScheduleSetup Loads Successfully", () => {
    expect(screen.getByText("PriceScheduleSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for PriceScheduleSetup", () => {
    // START_USER_CODE-USER_PriceScheduleSetup_Custom_Test_Case
    // END_USER_CODE-USER_PriceScheduleSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceSpecialFunctions_PriceScheduleSetup />
      );
    });
  });
  afterEach(cleanup);
  test("btnAuditHistory(Button Widget) Test Cases", async () => {
    const btnAuditHistory = screen.getByTestId("btnAuditHistory");
    expect(btnAuditHistory).toBeInTheDocument;
    expect(btnAuditHistory.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PriceScheduleSetup_btnAuditHistory")
    );
  });
  test("Custom Test Cases for btnAuditHistory", () => {
    // START_USER_CODE-USER_btnAuditHistory_TEST
    // END_USER_CODE-USER_btnAuditHistory_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PriceScheduleSetup_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("gridPriceSchedules_btncolDiscSchd(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_btncolDiscSchd = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_btncolDiscSchdbtn =
      gridPriceSchedules_btncolDiscSchd.nextElementSibling.firstElementChild;
    gridPriceSchedules_btncolDiscSchd =
      gridPriceSchedules_btncolDiscSchd.parentElement.parentElement
        .parentElement;
    expect(gridPriceSchedules_btncolDiscSchd.tagName).toBe("DIV");
    expect(gridPriceSchedules_btncolDiscSchd.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for btncolDiscSchd", () => {
    // START_USER_CODE-USER_btncolDiscSchd_TEST
    // END_USER_CODE-USER_btncolDiscSchd_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:PriceScheduleSetup_btnSave")
    );
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("gridPriceSchedules(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules = screen.getByTestId("gridPriceSchedules");
    let gridPriceSchedulesbtn =
      gridPriceSchedules.nextElementSibling.firstElementChild;
    gridPriceSchedules =
      gridPriceSchedules.parentElement.parentElement.parentElement;
    expect(gridPriceSchedules.tagName).toBe("DIV");
    expect(gridPriceSchedules.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPriceSchedules", () => {
    // START_USER_CODE-USER_gridPriceSchedules_TEST
    // END_USER_CODE-USER_gridPriceSchedules_TEST
  });
  test("grpbxPriceScheduleSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxPriceScheduleSetup = screen.getByTestId(
      "grpbxPriceScheduleSetup"
    );
    expect(grpbxPriceScheduleSetup.tagName).toBe("BUTTON");
    expect(grpbxPriceScheduleSetup.type).toBe("button");
    expect(grpbxPriceScheduleSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPriceScheduleSetup", () => {
    // START_USER_CODE-USER_grpbxPriceScheduleSetup_TEST
    // END_USER_CODE-USER_grpbxPriceScheduleSetup_TEST
  });
  test("lblPriceSchedules(Label Widget) Test Cases", async () => {
    const lblPriceSchedules = screen.getByTestId("lblPriceSchedules");
    expect(lblPriceSchedules.tagName).toBe("LABEL");
    expect(lblPriceSchedules.classList).toContain("form-label");
    expect(lblPriceSchedules.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_lblPriceSchedules"
      )
    );
  });
  test("Custom Test Cases for lblPriceSchedules", () => {
    // START_USER_CODE-USER_lblPriceSchedules_TEST
    // END_USER_CODE-USER_lblPriceSchedules_TEST
  });
  test("gridPriceSchedules_txtcolArea(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_txtcolArea = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_txtcolAreabtn =
      gridPriceSchedules_txtcolArea.nextElementSibling.firstElementChild;
    gridPriceSchedules_txtcolArea =
      gridPriceSchedules_txtcolArea.parentElement.parentElement.parentElement;
    expect(gridPriceSchedules_txtcolArea.tagName).toBe("DIV");
    expect(gridPriceSchedules_txtcolArea.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolArea", () => {
    // START_USER_CODE-USER_txtcolArea_TEST
    // END_USER_CODE-USER_txtcolArea_TEST
  });
  test("gridPriceSchedules_txtcolCrkBrkDoolFailPeanut(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_txtcolCrkBrkDoolFailPeanut = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_txtcolCrkBrkDoolFailPeanutbtn =
      gridPriceSchedules_txtcolCrkBrkDoolFailPeanut.nextElementSibling
        .firstElementChild;
    gridPriceSchedules_txtcolCrkBrkDoolFailPeanut =
      gridPriceSchedules_txtcolCrkBrkDoolFailPeanut.parentElement.parentElement
        .parentElement;
    expect(gridPriceSchedules_txtcolCrkBrkDoolFailPeanut.tagName).toBe("DIV");
    expect(gridPriceSchedules_txtcolCrkBrkDoolFailPeanut.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCrkBrkDoolFailPeanut", () => {
    // START_USER_CODE-USER_txtcolCrkBrkDoolFailPeanut_TEST
    // END_USER_CODE-USER_txtcolCrkBrkDoolFailPeanut_TEST
  });
  test("gridPriceSchedules_txtcolCrkBrkDoolThreshld(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_txtcolCrkBrkDoolThreshld = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_txtcolCrkBrkDoolThreshldbtn =
      gridPriceSchedules_txtcolCrkBrkDoolThreshld.nextElementSibling
        .firstElementChild;
    gridPriceSchedules_txtcolCrkBrkDoolThreshld =
      gridPriceSchedules_txtcolCrkBrkDoolThreshld.parentElement.parentElement
        .parentElement;
    expect(gridPriceSchedules_txtcolCrkBrkDoolThreshld.tagName).toBe("DIV");
    expect(gridPriceSchedules_txtcolCrkBrkDoolThreshld.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCrkBrkDoolThreshld", () => {
    // START_USER_CODE-USER_txtcolCrkBrkDoolThreshld_TEST
    // END_USER_CODE-USER_txtcolCrkBrkDoolThreshld_TEST
  });
  test("gridPriceSchedules_txtcolElkPrem(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_txtcolElkPrem = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_txtcolElkPrembtn =
      gridPriceSchedules_txtcolElkPrem.nextElementSibling.firstElementChild;
    gridPriceSchedules_txtcolElkPrem =
      gridPriceSchedules_txtcolElkPrem.parentElement.parentElement
        .parentElement;
    expect(gridPriceSchedules_txtcolElkPrem.tagName).toBe("DIV");
    expect(gridPriceSchedules_txtcolElkPrem.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolElkPrem", () => {
    // START_USER_CODE-USER_txtcolElkPrem_TEST
    // END_USER_CODE-USER_txtcolElkPrem_TEST
  });
  test("gridPriceSchedules_txtcolFancyThreshld(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_txtcolFancyThreshld = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_txtcolFancyThreshldbtn =
      gridPriceSchedules_txtcolFancyThreshld.nextElementSibling
        .firstElementChild;
    gridPriceSchedules_txtcolFancyThreshld =
      gridPriceSchedules_txtcolFancyThreshld.parentElement.parentElement
        .parentElement;
    expect(gridPriceSchedules_txtcolFancyThreshld.tagName).toBe("DIV");
    expect(gridPriceSchedules_txtcolFancyThreshld.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFancyThreshld", () => {
    // START_USER_CODE-USER_txtcolFancyThreshld_TEST
    // END_USER_CODE-USER_txtcolFancyThreshld_TEST
  });
  test("gridPriceSchedules_txtcolFanFailPnId(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_txtcolFanFailPnId = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_txtcolFanFailPnIdbtn =
      gridPriceSchedules_txtcolFanFailPnId.nextElementSibling.firstElementChild;
    gridPriceSchedules_txtcolFanFailPnId =
      gridPriceSchedules_txtcolFanFailPnId.parentElement.parentElement
        .parentElement;
    expect(gridPriceSchedules_txtcolFanFailPnId.tagName).toBe("DIV");
    expect(gridPriceSchedules_txtcolFanFailPnId.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFanFailPnId", () => {
    // START_USER_CODE-USER_txtcolFanFailPnId_TEST
    // END_USER_CODE-USER_txtcolFanFailPnId_TEST
  });
  test("gridPriceSchedules_txtcolLSKPerLbs(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_txtcolLSKPerLbs = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_txtcolLSKPerLbsbtn =
      gridPriceSchedules_txtcolLSKPerLbs.nextElementSibling.firstElementChild;
    gridPriceSchedules_txtcolLSKPerLbs =
      gridPriceSchedules_txtcolLSKPerLbs.parentElement.parentElement
        .parentElement;
    expect(gridPriceSchedules_txtcolLSKPerLbs.tagName).toBe("DIV");
    expect(gridPriceSchedules_txtcolLSKPerLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLSKPerLbs", () => {
    // START_USER_CODE-USER_txtcolLSKPerLbs_TEST
    // END_USER_CODE-USER_txtcolLSKPerLbs_TEST
  });
  test("gridPriceSchedules_txtcolMaxMoisture(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_txtcolMaxMoisture = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_txtcolMaxMoisturebtn =
      gridPriceSchedules_txtcolMaxMoisture.nextElementSibling.firstElementChild;
    gridPriceSchedules_txtcolMaxMoisture =
      gridPriceSchedules_txtcolMaxMoisture.parentElement.parentElement
        .parentElement;
    expect(gridPriceSchedules_txtcolMaxMoisture.tagName).toBe("DIV");
    expect(gridPriceSchedules_txtcolMaxMoisture.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMaxMoisture", () => {
    // START_USER_CODE-USER_txtcolMaxMoisture_TEST
    // END_USER_CODE-USER_txtcolMaxMoisture_TEST
  });
  test("gridPriceSchedules_txtcolMinFancyElkPrem(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_txtcolMinFancyElkPrem = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_txtcolMinFancyElkPrembtn =
      gridPriceSchedules_txtcolMinFancyElkPrem.nextElementSibling
        .firstElementChild;
    gridPriceSchedules_txtcolMinFancyElkPrem =
      gridPriceSchedules_txtcolMinFancyElkPrem.parentElement.parentElement
        .parentElement;
    expect(gridPriceSchedules_txtcolMinFancyElkPrem.tagName).toBe("DIV");
    expect(gridPriceSchedules_txtcolMinFancyElkPrem.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMinFancyElkPrem", () => {
    // START_USER_CODE-USER_txtcolMinFancyElkPrem_TEST
    // END_USER_CODE-USER_txtcolMinFancyElkPrem_TEST
  });
  test("gridPriceSchedules_txtcolOkPerPct(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_txtcolOkPerPct = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_txtcolOkPerPctbtn =
      gridPriceSchedules_txtcolOkPerPct.nextElementSibling.firstElementChild;
    gridPriceSchedules_txtcolOkPerPct =
      gridPriceSchedules_txtcolOkPerPct.parentElement.parentElement
        .parentElement;
    expect(gridPriceSchedules_txtcolOkPerPct.tagName).toBe("DIV");
    expect(gridPriceSchedules_txtcolOkPerPct.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOkPerPct", () => {
    // START_USER_CODE-USER_txtcolOkPerPct_TEST
    // END_USER_CODE-USER_txtcolOkPerPct_TEST
  });
  test("gridPriceSchedules_txtcolPeanuType(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_txtcolPeanuType = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_txtcolPeanuTypebtn =
      gridPriceSchedules_txtcolPeanuType.nextElementSibling.firstElementChild;
    gridPriceSchedules_txtcolPeanuType =
      gridPriceSchedules_txtcolPeanuType.parentElement.parentElement
        .parentElement;
    expect(gridPriceSchedules_txtcolPeanuType.tagName).toBe("DIV");
    expect(gridPriceSchedules_txtcolPeanuType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanuType", () => {
    // START_USER_CODE-USER_txtcolPeanuType_TEST
    // END_USER_CODE-USER_txtcolPeanuType_TEST
  });
  test("gridPriceSchedules_txtcolSMKSSPerPercent(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_txtcolSMKSSPerPercent = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_txtcolSMKSSPerPercentbtn =
      gridPriceSchedules_txtcolSMKSSPerPercent.nextElementSibling
        .firstElementChild;
    gridPriceSchedules_txtcolSMKSSPerPercent =
      gridPriceSchedules_txtcolSMKSSPerPercent.parentElement.parentElement
        .parentElement;
    expect(gridPriceSchedules_txtcolSMKSSPerPercent.tagName).toBe("DIV");
    expect(gridPriceSchedules_txtcolSMKSSPerPercent.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSMKSSPerPercent", () => {
    // START_USER_CODE-USER_txtcolSMKSSPerPercent_TEST
    // END_USER_CODE-USER_txtcolSMKSSPerPercent_TEST
  });
  test("gridPriceSchedules_txtcolSupportPerTon(Grid Widget) Test Cases", async () => {
    let gridPriceSchedules_txtcolSupportPerTon = screen.getByTestId(
      "gridPriceSchedules"
    );
    let gridPriceSchedules_txtcolSupportPerTonbtn =
      gridPriceSchedules_txtcolSupportPerTon.nextElementSibling
        .firstElementChild;
    gridPriceSchedules_txtcolSupportPerTon =
      gridPriceSchedules_txtcolSupportPerTon.parentElement.parentElement
        .parentElement;
    expect(gridPriceSchedules_txtcolSupportPerTon.tagName).toBe("DIV");
    expect(gridPriceSchedules_txtcolSupportPerTon.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:PriceScheduleSetup_gridPriceSchedules"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSupportPerTon", () => {
    // START_USER_CODE-USER_txtcolSupportPerTon_TEST
    // END_USER_CODE-USER_txtcolSupportPerTon_TEST
  });
});
