/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_CompanyProfile from "./CompanyProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("CompanyProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_CompanyProfile />);
    });
  });
  afterEach(cleanup);
  test("is CompanyProfile Loads Successfully", () => {
    expect(screen.getByText("CompanyProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for CompanyProfile", () => {
    // START_USER_CODE-USER_CompanyProfile_Custom_Test_Case
    // END_USER_CODE-USER_CompanyProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_CompanyProfile />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCompanyDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxCompanyDetails = screen.getByTestId("grpbxCompanyDetails");
    expect(grpbxCompanyDetails.tagName).toBe("BUTTON");
    expect(grpbxCompanyDetails.type).toBe("button");
    expect(grpbxCompanyDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCompanyDetails", () => {
    // START_USER_CODE-USER_grpbxCompanyDetails_TEST
    // END_USER_CODE-USER_grpbxCompanyDetails_TEST
  });
  test("grpbxCompanyProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxCompanyProfile = screen.getByTestId("grpbxCompanyProfile");
    expect(grpbxCompanyProfile.tagName).toBe("BUTTON");
    expect(grpbxCompanyProfile.type).toBe("button");
    expect(grpbxCompanyProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCompanyProfile", () => {
    // START_USER_CODE-USER_grpbxCompanyProfile_TEST
    // END_USER_CODE-USER_grpbxCompanyProfile_TEST
  });
  test("grpbxWireTransferData(GroupBox Widget) Test Cases", async () => {
    const grpbxWireTransferData = screen.getByTestId("grpbxWireTransferData");
    expect(grpbxWireTransferData.tagName).toBe("BUTTON");
    expect(grpbxWireTransferData.type).toBe("button");
    expect(grpbxWireTransferData.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWireTransferData", () => {
    // START_USER_CODE-USER_grpbxWireTransferData_TEST
    // END_USER_CODE-USER_grpbxWireTransferData_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblWireTransferData(Label Widget) Test Cases", async () => {
    const lblWireTransferData = screen.getByTestId("lblWireTransferData");
    expect(lblWireTransferData.tagName).toBe("LABEL");
    expect(lblWireTransferData.classList).toContain("form-label");
    expect(lblWireTransferData.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_lblWireTransferData")
    );
  });
  test("Custom Test Cases for lblWireTransferData", () => {
    // START_USER_CODE-USER_lblWireTransferData_TEST
    // END_USER_CODE-USER_lblWireTransferData_TEST
  });
  test("txtAddress(Textbox Widget) Test Cases", async () => {
    const txtAddress = screen.getByTestId("txtAddress");
    expect(txtAddress.tagName).toBe("INPUT");
    expect(txtAddress.type).toBe("text");
    expect(txtAddress.classList).toContain("textboxWidgetClass");
    expect(txtAddress.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtAddress")
    );
    await act(async () => {
      userEvent.type(txtAddress, "1");
    });
  });
  test("Custom Test Cases for txtAddress", () => {
    // START_USER_CODE-USER_txtAddress_TEST
    // END_USER_CODE-USER_txtAddress_TEST
  });
  test("txtAddress1(Textbox Widget) Test Cases", async () => {
    const txtAddress1 = screen.getByTestId("txtAddress1");
    expect(txtAddress1.tagName).toBe("INPUT");
    expect(txtAddress1.type).toBe("text");
    expect(txtAddress1.classList).toContain("textboxWidgetClass");
    expect(txtAddress1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtAddress1")
    );
    await act(async () => {
      userEvent.type(txtAddress1, "1");
    });
  });
  test("Custom Test Cases for txtAddress1", () => {
    // START_USER_CODE-USER_txtAddress1_TEST
    // END_USER_CODE-USER_txtAddress1_TEST
  });
  test("txtAddress2(Textbox Widget) Test Cases", async () => {
    const txtAddress2 = screen.getByTestId("txtAddress2");
    expect(txtAddress2.tagName).toBe("INPUT");
    expect(txtAddress2.type).toBe("text");
    expect(txtAddress2.classList).toContain("textboxWidgetClass");
    expect(txtAddress2.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtAddress2")
    );
    await act(async () => {
      userEvent.type(txtAddress2, "1");
    });
  });
  test("Custom Test Cases for txtAddress2", () => {
    // START_USER_CODE-USER_txtAddress2_TEST
    // END_USER_CODE-USER_txtAddress2_TEST
  });
  test("txtADMComapnyID(Textbox Widget) Test Cases", async () => {
    const txtADMComapnyID = screen.getByTestId("txtADMComapnyID");
    expect(txtADMComapnyID.tagName).toBe("INPUT");
    expect(txtADMComapnyID.type).toBe("text");
    expect(txtADMComapnyID.classList).toContain("textboxWidgetClass");
    expect(txtADMComapnyID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtADMComapnyID")
    );
    await act(async () => {
      userEvent.type(txtADMComapnyID, "1");
    });
  });
  test("Custom Test Cases for txtADMComapnyID", () => {
    // START_USER_CODE-USER_txtADMComapnyID_TEST
    // END_USER_CODE-USER_txtADMComapnyID_TEST
  });
  test("txtBankABANum(Textbox Widget) Test Cases", async () => {
    const txtBankABANum = screen.getByTestId("txtBankABANum");
    expect(txtBankABANum.tagName).toBe("INPUT");
    expect(txtBankABANum.type).toBe("text");
    expect(txtBankABANum.classList).toContain("textboxWidgetClass");
    expect(txtBankABANum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtBankABANum")
    );
    await act(async () => {
      userEvent.type(txtBankABANum, "1");
    });
  });
  test("Custom Test Cases for txtBankABANum", () => {
    // START_USER_CODE-USER_txtBankABANum_TEST
    // END_USER_CODE-USER_txtBankABANum_TEST
  });
  test("txtBankAccountNum(Textbox Widget) Test Cases", async () => {
    const txtBankAccountNum = screen.getByTestId("txtBankAccountNum");
    expect(txtBankAccountNum.tagName).toBe("INPUT");
    expect(txtBankAccountNum.type).toBe("text");
    expect(txtBankAccountNum.classList).toContain("textboxWidgetClass");
    expect(txtBankAccountNum.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtBankAccountNum")
    );
    await act(async () => {
      userEvent.type(txtBankAccountNum, "1");
    });
  });
  test("Custom Test Cases for txtBankAccountNum", () => {
    // START_USER_CODE-USER_txtBankAccountNum_TEST
    // END_USER_CODE-USER_txtBankAccountNum_TEST
  });
  test("txtCity(Textbox Widget) Test Cases", async () => {
    const txtCity = screen.getByTestId("txtCity");
    expect(txtCity.tagName).toBe("INPUT");
    expect(txtCity.type).toBe("text");
    expect(txtCity.classList).toContain("textboxWidgetClass");
    expect(txtCity.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtCity")
    );
    await act(async () => {
      userEvent.type(txtCity, "1");
    });
  });
  test("Custom Test Cases for txtCity", () => {
    // START_USER_CODE-USER_txtCity_TEST
    // END_USER_CODE-USER_txtCity_TEST
  });
  test("txtCity1(Textbox Widget) Test Cases", async () => {
    const txtCity1 = screen.getByTestId("txtCity1");
    expect(txtCity1.tagName).toBe("INPUT");
    expect(txtCity1.type).toBe("text");
    expect(txtCity1.classList).toContain("textboxWidgetClass");
    expect(txtCity1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtCity1")
    );
    await act(async () => {
      userEvent.type(txtCity1, "1");
    });
  });
  test("Custom Test Cases for txtCity1", () => {
    // START_USER_CODE-USER_txtCity1_TEST
    // END_USER_CODE-USER_txtCity1_TEST
  });
  test("txtCity2(Textbox Widget) Test Cases", async () => {
    const txtCity2 = screen.getByTestId("txtCity2");
    expect(txtCity2.tagName).toBe("INPUT");
    expect(txtCity2.type).toBe("text");
    expect(txtCity2.classList).toContain("textboxWidgetClass");
    expect(txtCity2.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtCity2")
    );
    await act(async () => {
      userEvent.type(txtCity2, "1");
    });
  });
  test("Custom Test Cases for txtCity2", () => {
    // START_USER_CODE-USER_txtCity2_TEST
    // END_USER_CODE-USER_txtCity2_TEST
  });
  test("txtComapnyName(Textbox Widget) Test Cases", async () => {
    const txtComapnyName = screen.getByTestId("txtComapnyName");
    expect(txtComapnyName.tagName).toBe("INPUT");
    expect(txtComapnyName.type).toBe("text");
    expect(txtComapnyName.classList).toContain("textboxWidgetClass");
    expect(txtComapnyName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtComapnyName")
    );
    await act(async () => {
      userEvent.type(txtComapnyName, "1");
    });
  });
  test("Custom Test Cases for txtComapnyName", () => {
    // START_USER_CODE-USER_txtComapnyName_TEST
    // END_USER_CODE-USER_txtComapnyName_TEST
  });
  test("txtCompanyID(Textbox Widget) Test Cases", async () => {
    const txtCompanyID = screen.getByTestId("txtCompanyID");
    expect(txtCompanyID.tagName).toBe("INPUT");
    expect(txtCompanyID.type).toBe("text");
    expect(txtCompanyID.classList).toContain("textboxWidgetClass");
    expect(txtCompanyID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtCompanyID")
    );
    await act(async () => {
      userEvent.type(txtCompanyID, "1");
    });
  });
  test("Custom Test Cases for txtCompanyID", () => {
    // START_USER_CODE-USER_txtCompanyID_TEST
    // END_USER_CODE-USER_txtCompanyID_TEST
  });
  test("txtEWRID(Textbox Widget) Test Cases", async () => {
    const txtEWRID = screen.getByTestId("txtEWRID");
    expect(txtEWRID.tagName).toBe("INPUT");
    expect(txtEWRID.type).toBe("text");
    expect(txtEWRID.classList).toContain("textboxWidgetClass");
    expect(txtEWRID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtEWRID")
    );
    await act(async () => {
      userEvent.type(txtEWRID, "1");
    });
  });
  test("Custom Test Cases for txtEWRID", () => {
    // START_USER_CODE-USER_txtEWRID_TEST
    // END_USER_CODE-USER_txtEWRID_TEST
  });
  test("txtLastFile(Textbox Widget) Test Cases", async () => {
    const txtLastFile = screen.getByTestId("txtLastFile");
    expect(txtLastFile.tagName).toBe("INPUT");
    expect(txtLastFile.type).toBe("text");
    expect(txtLastFile.classList).toContain("textboxWidgetClass");
    expect(txtLastFile.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtLastFile")
    );
    await act(async () => {
      userEvent.type(txtLastFile, "1");
    });
  });
  test("Custom Test Cases for txtLastFile", () => {
    // START_USER_CODE-USER_txtLastFile_TEST
    // END_USER_CODE-USER_txtLastFile_TEST
  });
  test("txtlZip(Textbox Widget) Test Cases", async () => {
    const txtlZip = screen.getByTestId("txtlZip");
    expect(txtlZip.tagName).toBe("INPUT");
    expect(txtlZip.type).toBe("text");
    expect(txtlZip.classList).toContain("textboxWidgetClass");
    expect(txtlZip.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtlZip")
    );
    await act(async () => {
      userEvent.type(txtlZip, "1");
    });
  });
  test("Custom Test Cases for txtlZip", () => {
    // START_USER_CODE-USER_txtlZip_TEST
    // END_USER_CODE-USER_txtlZip_TEST
  });
  test("txtPayerBank(Textbox Widget) Test Cases", async () => {
    const txtPayerBank = screen.getByTestId("txtPayerBank");
    expect(txtPayerBank.tagName).toBe("INPUT");
    expect(txtPayerBank.type).toBe("text");
    expect(txtPayerBank.classList).toContain("textboxWidgetClass");
    expect(txtPayerBank.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtPayerBank")
    );
    await act(async () => {
      userEvent.type(txtPayerBank, "1");
    });
  });
  test("Custom Test Cases for txtPayerBank", () => {
    // START_USER_CODE-USER_txtPayerBank_TEST
    // END_USER_CODE-USER_txtPayerBank_TEST
  });
  test("txtPayerName(Textbox Widget) Test Cases", async () => {
    const txtPayerName = screen.getByTestId("txtPayerName");
    expect(txtPayerName.tagName).toBe("INPUT");
    expect(txtPayerName.type).toBe("text");
    expect(txtPayerName.classList).toContain("textboxWidgetClass");
    expect(txtPayerName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtPayerName")
    );
    await act(async () => {
      userEvent.type(txtPayerName, "1");
    });
  });
  test("Custom Test Cases for txtPayerName", () => {
    // START_USER_CODE-USER_txtPayerName_TEST
    // END_USER_CODE-USER_txtPayerName_TEST
  });
  test("txtPhone(Textbox Widget) Test Cases", async () => {
    const txtPhone = screen.getByTestId("txtPhone");
    expect(txtPhone.tagName).toBe("INPUT");
    expect(txtPhone.type).toBe("text");
    expect(txtPhone.classList).toContain("textboxWidgetClass");
    expect(txtPhone.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtPhone")
    );
    await act(async () => {
      userEvent.type(txtPhone, "1");
    });
  });
  test("Custom Test Cases for txtPhone", () => {
    // START_USER_CODE-USER_txtPhone_TEST
    // END_USER_CODE-USER_txtPhone_TEST
  });
  test("txtSortOrder(Textbox Widget) Test Cases", async () => {
    const txtSortOrder = screen.getByTestId("txtSortOrder");
    expect(txtSortOrder.tagName).toBe("INPUT");
    expect(txtSortOrder.type).toBe("text");
    expect(txtSortOrder.classList).toContain("textboxWidgetClass");
    expect(txtSortOrder.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtSortOrder")
    );
    await act(async () => {
      userEvent.type(txtSortOrder, "1");
    });
  });
  test("Custom Test Cases for txtSortOrder", () => {
    // START_USER_CODE-USER_txtSortOrder_TEST
    // END_USER_CODE-USER_txtSortOrder_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtZip1(Textbox Widget) Test Cases", async () => {
    const txtZip1 = screen.getByTestId("txtZip1");
    expect(txtZip1.tagName).toBe("INPUT");
    expect(txtZip1.type).toBe("text");
    expect(txtZip1.classList).toContain("textboxWidgetClass");
    expect(txtZip1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtZip1")
    );
    await act(async () => {
      userEvent.type(txtZip1, "1");
    });
  });
  test("Custom Test Cases for txtZip1", () => {
    // START_USER_CODE-USER_txtZip1_TEST
    // END_USER_CODE-USER_txtZip1_TEST
  });
  test("txtZip2(Textbox Widget) Test Cases", async () => {
    const txtZip2 = screen.getByTestId("txtZip2");
    expect(txtZip2.tagName).toBe("INPUT");
    expect(txtZip2.type).toBe("text");
    expect(txtZip2.classList).toContain("textboxWidgetClass");
    expect(txtZip2.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:CompanyProfile_txtZip2")
    );
    await act(async () => {
      userEvent.type(txtZip2, "1");
    });
  });
  test("Custom Test Cases for txtZip2", () => {
    // START_USER_CODE-USER_txtZip2_TEST
    // END_USER_CODE-USER_txtZip2_TEST
  });
});
