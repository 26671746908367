/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FreightLocToLocSetup from "./FreightLocToLocSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FreightLocToLocSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FreightLocToLocSetup />
      );
    });
  });
  afterEach(cleanup);
  test("is FreightLocToLocSetup Loads Successfully", () => {
    expect(screen.getByText("FreightLocToLocSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for FreightLocToLocSetup", () => {
    // START_USER_CODE-USER_FreightLocToLocSetup_Custom_Test_Case
    // END_USER_CODE-USER_FreightLocToLocSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FreightLocToLocSetup />
      );
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocSetup_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocSetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FreightLocToLocSetup_btnExportToExcel"
      )
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocSetup_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocSetup_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("gridFreightLocToLocSetupData(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupDatabtn =
      gridFreightLocToLocSetupData.nextElementSibling.firstElementChild;
    gridFreightLocToLocSetupData =
      gridFreightLocToLocSetupData.parentElement.parentElement.parentElement;
    expect(gridFreightLocToLocSetupData.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridFreightLocToLocSetupData", () => {
    // START_USER_CODE-USER_gridFreightLocToLocSetupData_TEST
    // END_USER_CODE-USER_gridFreightLocToLocSetupData_TEST
  });
  test("grpbxDestination(GroupBox Widget) Test Cases", async () => {
    const grpbxDestination = screen.getByTestId("grpbxDestination");
    expect(grpbxDestination.tagName).toBe("BUTTON");
    expect(grpbxDestination.type).toBe("button");
    expect(grpbxDestination.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDestination", () => {
    // START_USER_CODE-USER_grpbxDestination_TEST
    // END_USER_CODE-USER_grpbxDestination_TEST
  });
  test("grpbxEffectiveDate(GroupBox Widget) Test Cases", async () => {
    const grpbxEffectiveDate = screen.getByTestId("grpbxEffectiveDate");
    expect(grpbxEffectiveDate.tagName).toBe("BUTTON");
    expect(grpbxEffectiveDate.type).toBe("button");
    expect(grpbxEffectiveDate.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxEffectiveDate", () => {
    // START_USER_CODE-USER_grpbxEffectiveDate_TEST
    // END_USER_CODE-USER_grpbxEffectiveDate_TEST
  });
  test("grpbxFreightLocToLocSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxFreightLocToLocSetup = screen.getByTestId(
      "grpbxFreightLocToLocSetup"
    );
    expect(grpbxFreightLocToLocSetup.tagName).toBe("BUTTON");
    expect(grpbxFreightLocToLocSetup.type).toBe("button");
    expect(grpbxFreightLocToLocSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFreightLocToLocSetup", () => {
    // START_USER_CODE-USER_grpbxFreightLocToLocSetup_TEST
    // END_USER_CODE-USER_grpbxFreightLocToLocSetup_TEST
  });
  test("grpbxOrigination(GroupBox Widget) Test Cases", async () => {
    const grpbxOrigination = screen.getByTestId("grpbxOrigination");
    expect(grpbxOrigination.tagName).toBe("BUTTON");
    expect(grpbxOrigination.type).toBe("button");
    expect(grpbxOrigination.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxOrigination", () => {
    // START_USER_CODE-USER_grpbxOrigination_TEST
    // END_USER_CODE-USER_grpbxOrigination_TEST
  });
  test("lblDestination(Label Widget) Test Cases", async () => {
    const lblDestination = screen.getByTestId("lblDestination");
    expect(lblDestination.tagName).toBe("LABEL");
    expect(lblDestination.classList).toContain("form-label");
    expect(lblDestination.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocSetup_lblDestination")
    );
  });
  test("Custom Test Cases for lblDestination", () => {
    // START_USER_CODE-USER_lblDestination_TEST
    // END_USER_CODE-USER_lblDestination_TEST
  });
  test("lblEffectiveDate(Label Widget) Test Cases", async () => {
    const lblEffectiveDate = screen.getByTestId("lblEffectiveDate");
    expect(lblEffectiveDate.tagName).toBe("LABEL");
    expect(lblEffectiveDate.classList).toContain("form-label");
    expect(lblEffectiveDate.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FreightLocToLocSetup_lblEffectiveDate"
      )
    );
  });
  test("Custom Test Cases for lblEffectiveDate", () => {
    // START_USER_CODE-USER_lblEffectiveDate_TEST
    // END_USER_CODE-USER_lblEffectiveDate_TEST
  });
  test("lblNote(Label Widget) Test Cases", async () => {
    const lblNote = screen.getByTestId("lblNote");
    expect(lblNote.tagName).toBe("LABEL");
    expect(lblNote.classList).toContain("form-label");
    expect(lblNote.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocSetup_lblNote")
    );
  });
  test("Custom Test Cases for lblNote", () => {
    // START_USER_CODE-USER_lblNote_TEST
    // END_USER_CODE-USER_lblNote_TEST
  });
  test("lblOrigination(Label Widget) Test Cases", async () => {
    const lblOrigination = screen.getByTestId("lblOrigination");
    expect(lblOrigination.tagName).toBe("LABEL");
    expect(lblOrigination.classList).toContain("form-label");
    expect(lblOrigination.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocSetup_lblOrigination")
    );
  });
  test("Custom Test Cases for lblOrigination", () => {
    // START_USER_CODE-USER_lblOrigination_TEST
    // END_USER_CODE-USER_lblOrigination_TEST
  });
  test("gridFreightLocToLocSetupData_txtCarrierAbbr(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtCarrierAbbr = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtCarrierAbbrbtn =
      gridFreightLocToLocSetupData_txtCarrierAbbr.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtCarrierAbbr =
      gridFreightLocToLocSetupData_txtCarrierAbbr.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtCarrierAbbr.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtCarrierAbbr.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtCarrierAbbr", () => {
    // START_USER_CODE-USER_txtCarrierAbbr_TEST
    // END_USER_CODE-USER_txtCarrierAbbr_TEST
  });
  test("gridFreightLocToLocSetupData_txtCountyID(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtCountyID = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtCountyIDbtn =
      gridFreightLocToLocSetupData_txtCountyID.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtCountyID =
      gridFreightLocToLocSetupData_txtCountyID.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtCountyID.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtCountyID.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtCountyID", () => {
    // START_USER_CODE-USER_txtCountyID_TEST
    // END_USER_CODE-USER_txtCountyID_TEST
  });
  test("gridFreightLocToLocSetupData_txtDestArea(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtDestArea = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtDestAreabtn =
      gridFreightLocToLocSetupData_txtDestArea.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtDestArea =
      gridFreightLocToLocSetupData_txtDestArea.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtDestArea.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtDestArea.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtDestArea", () => {
    // START_USER_CODE-USER_txtDestArea_TEST
    // END_USER_CODE-USER_txtDestArea_TEST
  });
  test("gridFreightLocToLocSetupData_txtDestBuyPt(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtDestBuyPt = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtDestBuyPtbtn =
      gridFreightLocToLocSetupData_txtDestBuyPt.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtDestBuyPt =
      gridFreightLocToLocSetupData_txtDestBuyPt.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtDestBuyPt.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtDestBuyPt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtDestBuyPt", () => {
    // START_USER_CODE-USER_txtDestBuyPt_TEST
    // END_USER_CODE-USER_txtDestBuyPt_TEST
  });
  test("gridFreightLocToLocSetupData_txtDestCollectionPoint(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtDestCollectionPoint = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtDestCollectionPointbtn =
      gridFreightLocToLocSetupData_txtDestCollectionPoint.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtDestCollectionPoint =
      gridFreightLocToLocSetupData_txtDestCollectionPoint.parentElement
        .parentElement.parentElement;
    expect(gridFreightLocToLocSetupData_txtDestCollectionPoint.tagName).toBe(
      "DIV"
    );
    expect(
      gridFreightLocToLocSetupData_txtDestCollectionPoint.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtDestCollectionPoint", () => {
    // START_USER_CODE-USER_txtDestCollectionPoint_TEST
    // END_USER_CODE-USER_txtDestCollectionPoint_TEST
  });
  test("txtEffectiveDate(Date Widget) Test Cases", async () => {
    const txtEffectiveDate = screen.getByTestId("txtEffectiveDate");
    expect(txtEffectiveDate.tagName).toBe("INPUT");
    expect(txtEffectiveDate.type).toBe("text");
    expect(txtEffectiveDate.classList).toContain("datetimepicker-input");
    await act(async () => {
      userEvent.click(txtEffectiveDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for txtEffectiveDate", () => {
    // START_USER_CODE-USER_txtEffectiveDate_TEST
    // END_USER_CODE-USER_txtEffectiveDate_TEST
  });
  test("gridFreightLocToLocSetupData_txtEffectiveDt(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtEffectiveDt = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtEffectiveDtbtn =
      gridFreightLocToLocSetupData_txtEffectiveDt.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtEffectiveDt =
      gridFreightLocToLocSetupData_txtEffectiveDt.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtEffectiveDt.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtEffectiveDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtEffectiveDt", () => {
    // START_USER_CODE-USER_txtEffectiveDt_TEST
    // END_USER_CODE-USER_txtEffectiveDt_TEST
  });
  test("gridFreightLocToLocSetupData_txtFarmID(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtFarmID = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtFarmIDbtn =
      gridFreightLocToLocSetupData_txtFarmID.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtFarmID =
      gridFreightLocToLocSetupData_txtFarmID.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtFarmID.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtFarmID.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtFarmID", () => {
    // START_USER_CODE-USER_txtFarmID_TEST
    // END_USER_CODE-USER_txtFarmID_TEST
  });
  test("gridFreightLocToLocSetupData_txtMiles(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtMiles = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtMilesbtn =
      gridFreightLocToLocSetupData_txtMiles.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtMiles =
      gridFreightLocToLocSetupData_txtMiles.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtMiles.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtMiles.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtMiles", () => {
    // START_USER_CODE-USER_txtMiles_TEST
    // END_USER_CODE-USER_txtMiles_TEST
  });
  test("gridFreightLocToLocSetupData_txtMinWt(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtMinWt = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtMinWtbtn =
      gridFreightLocToLocSetupData_txtMinWt.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtMinWt =
      gridFreightLocToLocSetupData_txtMinWt.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtMinWt.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtMinWt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtMinWt", () => {
    // START_USER_CODE-USER_txtMinWt_TEST
    // END_USER_CODE-USER_txtMinWt_TEST
  });
  test("gridFreightLocToLocSetupData_txtNLLoadWt(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtNLLoadWt = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtNLLoadWtbtn =
      gridFreightLocToLocSetupData_txtNLLoadWt.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtNLLoadWt =
      gridFreightLocToLocSetupData_txtNLLoadWt.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtNLLoadWt.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtNLLoadWt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtNLLoadWt", () => {
    // START_USER_CODE-USER_txtNLLoadWt_TEST
    // END_USER_CODE-USER_txtNLLoadWt_TEST
  });
  test("gridFreightLocToLocSetupData_txtNLRate(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtNLRate = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtNLRatebtn =
      gridFreightLocToLocSetupData_txtNLRate.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtNLRate =
      gridFreightLocToLocSetupData_txtNLRate.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtNLRate.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtNLRate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtNLRate", () => {
    // START_USER_CODE-USER_txtNLRate_TEST
    // END_USER_CODE-USER_txtNLRate_TEST
  });
  test("gridFreightLocToLocSetupData_txtNLRateBasis(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtNLRateBasis = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtNLRateBasisbtn =
      gridFreightLocToLocSetupData_txtNLRateBasis.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtNLRateBasis =
      gridFreightLocToLocSetupData_txtNLRateBasis.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtNLRateBasis.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtNLRateBasis.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtNLRateBasis", () => {
    // START_USER_CODE-USER_txtNLRateBasis_TEST
    // END_USER_CODE-USER_txtNLRateBasis_TEST
  });
  test("gridFreightLocToLocSetupData_txtOrigArea(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtOrigArea = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtOrigAreabtn =
      gridFreightLocToLocSetupData_txtOrigArea.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtOrigArea =
      gridFreightLocToLocSetupData_txtOrigArea.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtOrigArea.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtOrigArea.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtOrigArea", () => {
    // START_USER_CODE-USER_txtOrigArea_TEST
    // END_USER_CODE-USER_txtOrigArea_TEST
  });
  test("gridFreightLocToLocSetupData_txtOrigBuyPt(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtOrigBuyPt = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtOrigBuyPtbtn =
      gridFreightLocToLocSetupData_txtOrigBuyPt.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtOrigBuyPt =
      gridFreightLocToLocSetupData_txtOrigBuyPt.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtOrigBuyPt.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtOrigBuyPt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtOrigBuyPt", () => {
    // START_USER_CODE-USER_txtOrigBuyPt_TEST
    // END_USER_CODE-USER_txtOrigBuyPt_TEST
  });
  test("gridFreightLocToLocSetupData_txtOrigCollectionPoint(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtOrigCollectionPoint = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtOrigCollectionPointbtn =
      gridFreightLocToLocSetupData_txtOrigCollectionPoint.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtOrigCollectionPoint =
      gridFreightLocToLocSetupData_txtOrigCollectionPoint.parentElement
        .parentElement.parentElement;
    expect(gridFreightLocToLocSetupData_txtOrigCollectionPoint.tagName).toBe(
      "DIV"
    );
    expect(
      gridFreightLocToLocSetupData_txtOrigCollectionPoint.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtOrigCollectionPoint", () => {
    // START_USER_CODE-USER_txtOrigCollectionPoint_TEST
    // END_USER_CODE-USER_txtOrigCollectionPoint_TEST
  });
  test("gridFreightLocToLocSetupData_txtRate(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtRate = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtRatebtn =
      gridFreightLocToLocSetupData_txtRate.nextElementSibling.firstElementChild;
    gridFreightLocToLocSetupData_txtRate =
      gridFreightLocToLocSetupData_txtRate.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtRate.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtRate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtRate", () => {
    // START_USER_CODE-USER_txtRate_TEST
    // END_USER_CODE-USER_txtRate_TEST
  });
  test("gridFreightLocToLocSetupData_txtRateBasis(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtRateBasis = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtRateBasisbtn =
      gridFreightLocToLocSetupData_txtRateBasis.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtRateBasis =
      gridFreightLocToLocSetupData_txtRateBasis.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtRateBasis.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtRateBasis.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtRateBasis", () => {
    // START_USER_CODE-USER_txtRateBasis_TEST
    // END_USER_CODE-USER_txtRateBasis_TEST
  });
  test("gridFreightLocToLocSetupData_txtStateID(Grid Widget) Test Cases", async () => {
    let gridFreightLocToLocSetupData_txtStateID = screen.getByTestId(
      "gridFreightLocToLocSetupData"
    );
    let gridFreightLocToLocSetupData_txtStateIDbtn =
      gridFreightLocToLocSetupData_txtStateID.nextElementSibling
        .firstElementChild;
    gridFreightLocToLocSetupData_txtStateID =
      gridFreightLocToLocSetupData_txtStateID.parentElement.parentElement
        .parentElement;
    expect(gridFreightLocToLocSetupData_txtStateID.tagName).toBe("DIV");
    expect(gridFreightLocToLocSetupData_txtStateID.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:FreightLocToLocSetup_gridFreightLocToLocSetupData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtStateID", () => {
    // START_USER_CODE-USER_txtStateID_TEST
    // END_USER_CODE-USER_txtStateID_TEST
  });
});
