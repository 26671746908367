/* eslint-disable*/
import React from "react";
import PasswordWidget from "./PasswordWidget";
import { screen } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("PasswordWidget1 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let passwordboxwidget1 = {
        name: "passwordboxwidget1",
        type: "PasswordBoxWidget",
        parent: "groupboxwidget0",
        Label: "passwordboxwidget1",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      renderTestScreen(
        <PasswordWidget
          values={values}
          conf={passwordboxwidget1}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Is PasswordWidget1 Loads Successfully", async () => {
    const passwordboxwidget1 = screen.getByTestId("passwordboxwidget1");
    expect(passwordboxwidget1.tagName).toBe("INPUT");
    expect(passwordboxwidget1.type).toBe("password");
    expect(passwordboxwidget1.classList).toContain("textboxWidgetClass");
  });
});

describe("PasswordWidget2 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let passwordboxwidget2 = {
        name: "passwordboxwidget2",
        type: "PasswordBoxWidget",
        parent: "groupboxwidget0",
        Label: "passwordboxwidget2",
        LengthRange: { MinLength: 0, MaxLength: 256 },
        ofTypeDomain: "d_String",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
        horizontalForm: true,
      };
      let values = {};
      let errors = jest.fn();
      let touched = jest.fn();
      renderTestScreen(
        <PasswordWidget
          values={values}
          conf={passwordboxwidget2}
          screenConf={stateScreenConf}
          touched={touched}
          errors={errors}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Is PasswordWidget2 Loads Successfully", async () => {
    const passwordboxwidget2 = screen.getByTestId("passwordboxwidget2");
    expect(passwordboxwidget2.tagName).toBe("INPUT");
    expect(passwordboxwidget2.type).toBe("password");
    expect(passwordboxwidget2.classList).toContain("textboxWidgetClass");
  });
});
