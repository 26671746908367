/* eslint-disable*/
import React from "react";
import Settlements_ContractApplications2009 from "./ContractApplications2009";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ContractApplications2009 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ContractApplications2009 />);
    });
  });
  afterEach(cleanup);
  test("is ContractApplications2009 Loads Successfully", () => {
    expect(screen.getByText("ContractApplications2009")).toBeInTheDocument;
  });
  test("Custom Test Cases for ContractApplications2009", () => {
    // START_USER_CODE-USER_ContractApplications2009_Custom_Test_Case
    // END_USER_CODE-USER_ContractApplications2009_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ContractApplications2009 />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("Settlements:ContractApplications2009_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("gridViewContractApplication_btncol1(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_btncol1 = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_btncol1btn =
      gridViewContractApplication_btncol1.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_btncol1 =
      gridViewContractApplication_btncol1.parentElement.parentElement
        .parentElement;
    expect(gridViewContractApplication_btncol1.tagName).toBe("DIV");
    expect(gridViewContractApplication_btncol1.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for btncol1", () => {
    // START_USER_CODE-USER_btncol1_TEST
    // END_USER_CODE-USER_btncol1_TEST
  });
  test("btnEditApp(Button Widget) Test Cases", async () => {
    const btnEditApp = screen.getByTestId("btnEditApp");
    expect(btnEditApp).toBeInTheDocument;
    expect(btnEditApp.textContent).toEqual(
      t("Settlements:ContractApplications2009_btnEditApp")
    );
  });
  test("Custom Test Cases for btnEditApp", () => {
    // START_USER_CODE-USER_btnEditApp_TEST
    // END_USER_CODE-USER_btnEditApp_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("Settlements:ContractApplications2009_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("btnPurchase(Button Widget) Test Cases", async () => {
    const btnPurchase = screen.getByTestId("btnPurchase");
    expect(btnPurchase).toBeInTheDocument;
    expect(btnPurchase.textContent).toEqual(
      t("Settlements:ContractApplications2009_btnPurchase")
    );
  });
  test("Custom Test Cases for btnPurchase", () => {
    // START_USER_CODE-USER_btnPurchase_TEST
    // END_USER_CODE-USER_btnPurchase_TEST
  });
  test("gridSeedGroverApp(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp = screen.getByTestId("gridSeedGroverApp");
    let gridSeedGroverAppbtn =
      gridSeedGroverApp.nextElementSibling.firstElementChild;
    gridSeedGroverApp =
      gridSeedGroverApp.parentElement.parentElement.parentElement;
    expect(gridSeedGroverApp.tagName).toBe("DIV");
    expect(gridSeedGroverApp.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridSeedGroverApp", () => {
    // START_USER_CODE-USER_gridSeedGroverApp_TEST
    // END_USER_CODE-USER_gridSeedGroverApp_TEST
  });
  test("gridViewContractApplication(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplicationbtn =
      gridViewContractApplication.nextElementSibling.firstElementChild;
    gridViewContractApplication =
      gridViewContractApplication.parentElement.parentElement.parentElement;
    expect(gridViewContractApplication.tagName).toBe("DIV");
    expect(gridViewContractApplication.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridViewContractApplication", () => {
    // START_USER_CODE-USER_gridViewContractApplication_TEST
    // END_USER_CODE-USER_gridViewContractApplication_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxContractApplication2009(GroupBox Widget) Test Cases", async () => {
    const grpbxContractApplication2009 = screen.getByTestId(
      "grpbxContractApplication2009"
    );
    expect(grpbxContractApplication2009.tagName).toBe("BUTTON");
    expect(grpbxContractApplication2009.type).toBe("button");
    expect(grpbxContractApplication2009.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContractApplication2009", () => {
    // START_USER_CODE-USER_grpbxContractApplication2009_TEST
    // END_USER_CODE-USER_grpbxContractApplication2009_TEST
  });
  test("grpbxSeedGroverApp(GroupBox Widget) Test Cases", async () => {
    const grpbxSeedGroverApp = screen.getByTestId("grpbxSeedGroverApp");
    expect(grpbxSeedGroverApp.tagName).toBe("BUTTON");
    expect(grpbxSeedGroverApp.type).toBe("button");
    expect(grpbxSeedGroverApp.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSeedGroverApp", () => {
    // START_USER_CODE-USER_grpbxSeedGroverApp_TEST
    // END_USER_CODE-USER_grpbxSeedGroverApp_TEST
  });
  test("grpbxSpotApplication(GroupBox Widget) Test Cases", async () => {
    const grpbxSpotApplication = screen.getByTestId(
      "grpbxSpotApplication"
    );
    expect(grpbxSpotApplication.tagName).toBe("BUTTON");
    expect(grpbxSpotApplication.type).toBe("button");
    expect(grpbxSpotApplication.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSpotApplication", () => {
    // START_USER_CODE-USER_grpbxSpotApplication_TEST
    // END_USER_CODE-USER_grpbxSpotApplication_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("Settlements:ContractApplications2009_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("Settlements:ContractApplications2009_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("Settlements:ContractApplications2009_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("Settlements:ContractApplications2009_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblContractApplications(Label Widget) Test Cases", async () => {
    const lblContractApplications = screen.getByTestId(
      "lblContractApplications"
    );
    expect(lblContractApplications.tagName).toBe("LABEL");
    expect(lblContractApplications.classList).toContain("form-label");
    expect(lblContractApplications.textContent).toEqual(
      t("Settlements:ContractApplications2009_lblContractApplications")
    );
  });
  test("Custom Test Cases for lblContractApplications", () => {
    // START_USER_CODE-USER_lblContractApplications_TEST
    // END_USER_CODE-USER_lblContractApplications_TEST
  });
  test("lblSeedGroverApp(Label Widget) Test Cases", async () => {
    const lblSeedGroverApp = screen.getByTestId("lblSeedGroverApp");
    expect(lblSeedGroverApp.tagName).toBe("LABEL");
    expect(lblSeedGroverApp.classList).toContain("form-label");
    expect(lblSeedGroverApp.textContent).toEqual(
      t("Settlements:ContractApplications2009_lblSeedGroverApp")
    );
  });
  test("Custom Test Cases for lblSeedGroverApp", () => {
    // START_USER_CODE-USER_lblSeedGroverApp_TEST
    // END_USER_CODE-USER_lblSeedGroverApp_TEST
  });
  test("lblSpotApplications(Label Widget) Test Cases", async () => {
    const lblSpotApplications = screen.getByTestId(
      "lblSpotApplications"
    );
    expect(lblSpotApplications.tagName).toBe("LABEL");
    expect(lblSpotApplications.classList).toContain("form-label");
    expect(lblSpotApplications.textContent).toEqual(
      t("Settlements:ContractApplications2009_lblSpotApplications")
    );
  });
  test("Custom Test Cases for lblSpotApplications", () => {
    // START_USER_CODE-USER_lblSpotApplications_TEST
    // END_USER_CODE-USER_lblSpotApplications_TEST
  });
  test("lblUnappliedLbs(Label Widget) Test Cases", async () => {
    const lblUnappliedLbs = screen.getByTestId("lblUnappliedLbs");
    expect(lblUnappliedLbs.tagName).toBe("LABEL");
    expect(lblUnappliedLbs.classList).toContain("form-label");
    expect(lblUnappliedLbs.textContent).toEqual(
      t("Settlements:ContractApplications2009_lblUnappliedLbs")
    );
  });
  test("Custom Test Cases for lblUnappliedLbs", () => {
    // START_USER_CODE-USER_lblUnappliedLbs_TEST
    // END_USER_CODE-USER_lblUnappliedLbs_TEST
  });
  test("gridViewContractApplication_textboxwidget27(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_textboxwidget27 = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_textboxwidget27btn =
      gridViewContractApplication_textboxwidget27.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_textboxwidget27 =
      gridViewContractApplication_textboxwidget27.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_textboxwidget27.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_textboxwidget27.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for textboxwidget27", () => {
    // START_USER_CODE-USER_textboxwidget27_TEST
    // END_USER_CODE-USER_textboxwidget27_TEST
  });
  test("gridViewContractApplication_textboxwidget31(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_textboxwidget31 = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_textboxwidget31btn =
      gridViewContractApplication_textboxwidget31.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_textboxwidget31 =
      gridViewContractApplication_textboxwidget31.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_textboxwidget31.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_textboxwidget31.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for textboxwidget31", () => {
    // START_USER_CODE-USER_textboxwidget31_TEST
    // END_USER_CODE-USER_textboxwidget31_TEST
  });
  test("txt1007Num(Textbox Widget) Test Cases", async () => {
    const txt1007Num = screen.getByTestId("txt1007Num");
    expect(txt1007Num.tagName).toBe("INPUT");
    expect(txt1007Num.type).toBe("text");
    expect(txt1007Num.classList).toContain("textboxWidgetClass");
    expect(txt1007Num.previousElementSibling.textContent).toEqual(
      t("Settlements:ContractApplications2009_txt1007Num")
    );
    await act(async () => {
      userEvent.type(txt1007Num, "123");
    });
    expect(txt1007Num.getAttribute("value")).toBe("");
    expect(txt1007Num.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txt1007Num", () => {
    // START_USER_CODE-USER_txt1007Num_TEST
    // END_USER_CODE-USER_txt1007Num_TEST
  });
  test("gridSeedGroverApp_txtcolAppliedSG(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolAppliedSG = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolAppliedSGbtn =
      gridSeedGroverApp_txtcolAppliedSG.nextElementSibling
        .firstElementChild;
    gridSeedGroverApp_txtcolAppliedSG =
      gridSeedGroverApp_txtcolAppliedSG.parentElement.parentElement
        .parentElement;
    expect(gridSeedGroverApp_txtcolAppliedSG.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolAppliedSG.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAppliedSG", () => {
    // START_USER_CODE-USER_txtcolAppliedSG_TEST
    // END_USER_CODE-USER_txtcolAppliedSG_TEST
  });
  test("gridViewContractApplication_txtcolApplLbs(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolApplLbs = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolApplLbsbtn =
      gridViewContractApplication_txtcolApplLbs.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolApplLbs =
      gridViewContractApplication_txtcolApplLbs.parentElement.parentElement
        .parentElement;
    expect(gridViewContractApplication_txtcolApplLbs.tagName).toBe("DIV");
    expect(gridViewContractApplication_txtcolApplLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolApplLbs", () => {
    // START_USER_CODE-USER_txtcolApplLbs_TEST
    // END_USER_CODE-USER_txtcolApplLbs_TEST
  });
  test("gridViewContractApplication_txtcolContApplLbs(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolContApplLbs = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolContApplLbsbtn =
      gridViewContractApplication_txtcolContApplLbs.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolContApplLbs =
      gridViewContractApplication_txtcolContApplLbs.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolContApplLbs.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_txtcolContApplLbs.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContApplLbs", () => {
    // START_USER_CODE-USER_txtcolContApplLbs_TEST
    // END_USER_CODE-USER_txtcolContApplLbs_TEST
  });
  test("gridViewContractApplication_txtcolContApplTentLbs(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolContApplTentLbs = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolContApplTentLbsbtn =
      gridViewContractApplication_txtcolContApplTentLbs.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolContApplTentLbs =
      gridViewContractApplication_txtcolContApplTentLbs.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolContApplTentLbs.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_txtcolContApplTentLbs.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContApplTentLbs", () => {
    // START_USER_CODE-USER_txtcolContApplTentLbs_TEST
    // END_USER_CODE-USER_txtcolContApplTentLbs_TEST
  });
  test("gridSeedGroverApp_txtcolContDateSG(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolContDateSG = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolContDateSGbtn =
      gridSeedGroverApp_txtcolContDateSG.nextElementSibling
        .firstElementChild;
    gridSeedGroverApp_txtcolContDateSG =
      gridSeedGroverApp_txtcolContDateSG.parentElement.parentElement
        .parentElement;
    expect(gridSeedGroverApp_txtcolContDateSG.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolContDateSG.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContDateSG", () => {
    // START_USER_CODE-USER_txtcolContDateSG_TEST
    // END_USER_CODE-USER_txtcolContDateSG_TEST
  });
  test("gridViewContractApplication_txtcolContDate(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolContDate = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolContDatebtn =
      gridViewContractApplication_txtcolContDate.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolContDate =
      gridViewContractApplication_txtcolContDate.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolContDate.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_txtcolContDate.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContDate", () => {
    // START_USER_CODE-USER_txtcolContDate_TEST
    // END_USER_CODE-USER_txtcolContDate_TEST
  });
  test("gridViewContractApplication_txtcolContGroup(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolContGroup = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolContGroupbtn =
      gridViewContractApplication_txtcolContGroup.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolContGroup =
      gridViewContractApplication_txtcolContGroup.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolContGroup.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_txtcolContGroup.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContGroup", () => {
    // START_USER_CODE-USER_txtcolContGroup_TEST
    // END_USER_CODE-USER_txtcolContGroup_TEST
  });
  test("gridSeedGroverApp_txtcolContract(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolContract = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolContractbtn =
      gridSeedGroverApp_txtcolContract.nextElementSibling.firstElementChild;
    gridSeedGroverApp_txtcolContract =
      gridSeedGroverApp_txtcolContract.parentElement.parentElement
        .parentElement;
    expect(gridSeedGroverApp_txtcolContract.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolContract.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContract", () => {
    // START_USER_CODE-USER_txtcolContract_TEST
    // END_USER_CODE-USER_txtcolContract_TEST
  });
  test("gridViewContractApplication_txtcolContractCA(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolContractCA = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolContractCAbtn =
      gridViewContractApplication_txtcolContractCA.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolContractCA =
      gridViewContractApplication_txtcolContractCA.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolContractCA.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_txtcolContractCA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractCA", () => {
    // START_USER_CODE-USER_txtcolContractCA_TEST
    // END_USER_CODE-USER_txtcolContractCA_TEST
  });
  test("gridSeedGroverApp_txtcolContractsPoundsSG(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolContractsPoundsSG = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolContractsPoundsSGbtn =
      gridSeedGroverApp_txtcolContractsPoundsSG.nextElementSibling
        .firstElementChild;
    gridSeedGroverApp_txtcolContractsPoundsSG =
      gridSeedGroverApp_txtcolContractsPoundsSG.parentElement
        .parentElement.parentElement;
    expect(gridSeedGroverApp_txtcolContractsPoundsSG.tagName).toBe(
      "DIV"
    );
    expect(
      gridSeedGroverApp_txtcolContractsPoundsSG.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractsPoundsSG", () => {
    // START_USER_CODE-USER_txtcolContractsPoundsSG_TEST
    // END_USER_CODE-USER_txtcolContractsPoundsSG_TEST
  });
  test("gridViewContractApplication_txtcolContractsPounds(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolContractsPounds = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolContractsPoundsbtn =
      gridViewContractApplication_txtcolContractsPounds
        .nextElementSibling.firstElementChild;
    gridViewContractApplication_txtcolContractsPounds =
      gridViewContractApplication_txtcolContractsPounds.parentElement
        .parentElement.parentElement;
    expect(
      gridViewContractApplication_txtcolContractsPounds.tagName
    ).toBe("DIV");
    expect(
      gridViewContractApplication_txtcolContractsPounds.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContractsPounds", () => {
    // START_USER_CODE-USER_txtcolContractsPounds_TEST
    // END_USER_CODE-USER_txtcolContractsPounds_TEST
  });
  test("gridViewContractApplication_txtcolCont(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolCont = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolContbtn =
      gridViewContractApplication_txtcolCont.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolCont =
      gridViewContractApplication_txtcolCont.parentElement.parentElement
        .parentElement;
    expect(gridViewContractApplication_txtcolCont.tagName).toBe("DIV");
    expect(gridViewContractApplication_txtcolCont.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCont", () => {
    // START_USER_CODE-USER_txtcolCont_TEST
    // END_USER_CODE-USER_txtcolCont_TEST
  });
  test("gridViewContractApplication_txtcolDeliveryEndDate(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolDeliveryEndDate = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolDeliveryEndDatebtn =
      gridViewContractApplication_txtcolDeliveryEndDate
        .nextElementSibling.firstElementChild;
    gridViewContractApplication_txtcolDeliveryEndDate =
      gridViewContractApplication_txtcolDeliveryEndDate.parentElement
        .parentElement.parentElement;
    expect(
      gridViewContractApplication_txtcolDeliveryEndDate.tagName
    ).toBe("DIV");
    expect(
      gridViewContractApplication_txtcolDeliveryEndDate.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDeliveryEndDate", () => {
    // START_USER_CODE-USER_txtcolDeliveryEndDate_TEST
    // END_USER_CODE-USER_txtcolDeliveryEndDate_TEST
  });
  test("gridViewContractApplication_txtcolDeliveryStartDate(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolDeliveryStartDate = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolDeliveryStartDatebtn =
      gridViewContractApplication_txtcolDeliveryStartDate
        .nextElementSibling.firstElementChild;
    gridViewContractApplication_txtcolDeliveryStartDate =
      gridViewContractApplication_txtcolDeliveryStartDate.parentElement
        .parentElement.parentElement;
    expect(
      gridViewContractApplication_txtcolDeliveryStartDate.tagName
    ).toBe("DIV");
    expect(
      gridViewContractApplication_txtcolDeliveryStartDate.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDeliveryStartDate", () => {
    // START_USER_CODE-USER_txtcolDeliveryStartDate_TEST
    // END_USER_CODE-USER_txtcolDeliveryStartDate_TEST
  });
  test("gridViewContractApplication_txtcolFOrB(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolFOrB = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolFOrBbtn =
      gridViewContractApplication_txtcolFOrB.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolFOrB =
      gridViewContractApplication_txtcolFOrB.parentElement.parentElement
        .parentElement;
    expect(gridViewContractApplication_txtcolFOrB.tagName).toBe("DIV");
    expect(gridViewContractApplication_txtcolFOrB.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFOrB", () => {
    // START_USER_CODE-USER_txtcolFOrB_TEST
    // END_USER_CODE-USER_txtcolFOrB_TEST
  });
  test("gridViewContractApplication_txtcolGrpLbs(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolGrpLbs = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolGrpLbsbtn =
      gridViewContractApplication_txtcolGrpLbs.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolGrpLbs =
      gridViewContractApplication_txtcolGrpLbs.parentElement.parentElement
        .parentElement;
    expect(gridViewContractApplication_txtcolGrpLbs.tagName).toBe("DIV");
    expect(gridViewContractApplication_txtcolGrpLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolGrpLbs", () => {
    // START_USER_CODE-USER_txtcolGrpLbs_TEST
    // END_USER_CODE-USER_txtcolGrpLbs_TEST
  });
  test("gridSeedGroverApp_txtcolMeetGenProduced(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolMeetGenProduced = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolMeetGenProducedbtn =
      gridSeedGroverApp_txtcolMeetGenProduced.nextElementSibling
        .firstElementChild;
    gridSeedGroverApp_txtcolMeetGenProduced =
      gridSeedGroverApp_txtcolMeetGenProduced.parentElement.parentElement
        .parentElement;
    expect(gridSeedGroverApp_txtcolMeetGenProduced.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolMeetGenProduced.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMeetGenProduced", () => {
    // START_USER_CODE-USER_txtcolMeetGenProduced_TEST
    // END_USER_CODE-USER_txtcolMeetGenProduced_TEST
  });
  test("gridViewContractApplication_txtcolMeetGenProducedCA(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolMeetGenProducedCA = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolMeetGenProducedCAbtn =
      gridViewContractApplication_txtcolMeetGenProducedCA.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolMeetGenProducedCA =
      gridViewContractApplication_txtcolMeetGenProducedCA.parentElement
        .parentElement.parentElement;
    expect(
      gridViewContractApplication_txtcolMeetGenProducedCA.tagName
    ).toBe("DIV");
    expect(
      gridViewContractApplication_txtcolMeetGenProducedCA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolMeetGenProducedCA", () => {
    // START_USER_CODE-USER_txtcolMeetGenProducedCA_TEST
    // END_USER_CODE-USER_txtcolMeetGenProducedCA_TEST
  });
  test("gridSeedGroverApp_txtcolOpenBalanceSG(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolOpenBalanceSG = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolOpenBalanceSGbtn =
      gridSeedGroverApp_txtcolOpenBalanceSG.nextElementSibling
        .firstElementChild;
    gridSeedGroverApp_txtcolOpenBalanceSG =
      gridSeedGroverApp_txtcolOpenBalanceSG.parentElement.parentElement
        .parentElement;
    expect(gridSeedGroverApp_txtcolOpenBalanceSG.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolOpenBalanceSG.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpenBalanceSG", () => {
    // START_USER_CODE-USER_txtcolOpenBalanceSG_TEST
    // END_USER_CODE-USER_txtcolOpenBalanceSG_TEST
  });
  test("gridViewContractApplication_txtcolOpenBalance(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolOpenBalance = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolOpenBalancebtn =
      gridViewContractApplication_txtcolOpenBalance.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolOpenBalance =
      gridViewContractApplication_txtcolOpenBalance.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolOpenBalance.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_txtcolOpenBalance.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOpenBalance", () => {
    // START_USER_CODE-USER_txtcolOpenBalance_TEST
    // END_USER_CODE-USER_txtcolOpenBalance_TEST
  });
  test("gridViewContractApplication_txtcolPeanutVariety(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolPeanutVariety = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolPeanutVarietybtn =
      gridViewContractApplication_txtcolPeanutVariety.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolPeanutVariety =
      gridViewContractApplication_txtcolPeanutVariety.parentElement
        .parentElement.parentElement;
    expect(
      gridViewContractApplication_txtcolPeanutVariety.tagName
    ).toBe("DIV");
    expect(
      gridViewContractApplication_txtcolPeanutVariety.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutVariety", () => {
    // START_USER_CODE-USER_txtcolPeanutVariety_TEST
    // END_USER_CODE-USER_txtcolPeanutVariety_TEST
  });
  test("gridSeedGroverApp_txtcolPoundsToApplySG(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolPoundsToApplySG = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolPoundsToApplySGbtn =
      gridSeedGroverApp_txtcolPoundsToApplySG.nextElementSibling
        .firstElementChild;
    gridSeedGroverApp_txtcolPoundsToApplySG =
      gridSeedGroverApp_txtcolPoundsToApplySG.parentElement
        .parentElement.parentElement;
    expect(gridSeedGroverApp_txtcolPoundsToApplySG.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolPoundsToApplySG.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPoundsToApplySG", () => {
    // START_USER_CODE-USER_txtcolPoundsToApplySG_TEST
    // END_USER_CODE-USER_txtcolPoundsToApplySG_TEST
  });
  test("gridViewContractApplication_txtcolPoundsToApply(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolPoundsToApply = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolPoundsToApplybtn =
      gridViewContractApplication_txtcolPoundsToApply.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolPoundsToApply =
      gridViewContractApplication_txtcolPoundsToApply.parentElement
        .parentElement.parentElement;
    expect(
      gridViewContractApplication_txtcolPoundsToApply.tagName
    ).toBe("DIV");
    expect(
      gridViewContractApplication_txtcolPoundsToApply.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPoundsToApply", () => {
    // START_USER_CODE-USER_txtcolPoundsToApply_TEST
    // END_USER_CODE-USER_txtcolPoundsToApply_TEST
  });
  test("gridSeedGroverApp_txtcolPriceSG(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolPriceSG = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolPriceSGbtn =
      gridSeedGroverApp_txtcolPriceSG.nextElementSibling
        .firstElementChild;
    gridSeedGroverApp_txtcolPriceSG =
      gridSeedGroverApp_txtcolPriceSG.parentElement.parentElement
        .parentElement;
    expect(gridSeedGroverApp_txtcolPriceSG.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolPriceSG.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPriceSG", () => {
    // START_USER_CODE-USER_txtcolPriceSG_TEST
    // END_USER_CODE-USER_txtcolPriceSG_TEST
  });
  test("gridViewContractApplication_txtcolPrice(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolPrice = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolPricebtn =
      gridViewContractApplication_txtcolPrice.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolPrice =
      gridViewContractApplication_txtcolPrice.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolPrice.tagName).toBe("DIV");
    expect(gridViewContractApplication_txtcolPrice.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPrice", () => {
    // START_USER_CODE-USER_txtcolPrice_TEST
    // END_USER_CODE-USER_txtcolPrice_TEST
  });
  test("gridViewContractApplication_txtcolPurLbsToApply(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolPurLbsToApply = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolPurLbsToApplybtn =
      gridViewContractApplication_txtcolPurLbsToApply.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolPurLbsToApply =
      gridViewContractApplication_txtcolPurLbsToApply.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolPurLbsToApply.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_txtcolPurLbsToApply.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPurLbsToApply", () => {
    // START_USER_CODE-USER_txtcolPurLbsToApply_TEST
    // END_USER_CODE-USER_txtcolPurLbsToApply_TEST
  });
  test("gridSeedGroverApp_txtcolPurSto1(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolPurSto1 = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolPurSto1btn =
      gridSeedGroverApp_txtcolPurSto1.nextElementSibling.firstElementChild;
    gridSeedGroverApp_txtcolPurSto1 =
      gridSeedGroverApp_txtcolPurSto1.parentElement.parentElement
        .parentElement;
    expect(gridSeedGroverApp_txtcolPurSto1.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolPurSto1.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPurSto1", () => {
    // START_USER_CODE-USER_txtcolPurSto1_TEST
    // END_USER_CODE-USER_txtcolPurSto1_TEST
  });
  test("gridViewContractApplication_txtcolPurSto1CA(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolPurSto1CA = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolPurSto1CAbtn =
      gridViewContractApplication_txtcolPurSto1CA.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolPurSto1CA =
      gridViewContractApplication_txtcolPurSto1CA.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolPurSto1CA.tagName).toBe("DIV");
    expect(gridViewContractApplication_txtcolPurSto1CA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPurSto1CA", () => {
    // START_USER_CODE-USER_txtcolPurSto1CA_TEST
    // END_USER_CODE-USER_txtcolPurSto1CA_TEST
  });
  test("gridSeedGroverApp_txtcolPurSto2(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolPurSto2 = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolPurSto2btn =
      gridSeedGroverApp_txtcolPurSto2.nextElementSibling.firstElementChild;
    gridSeedGroverApp_txtcolPurSto2 =
      gridSeedGroverApp_txtcolPurSto2.parentElement.parentElement
        .parentElement;
    expect(gridSeedGroverApp_txtcolPurSto2.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolPurSto2.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPurSto2", () => {
    // START_USER_CODE-USER_txtcolPurSto2_TEST
    // END_USER_CODE-USER_txtcolPurSto2_TEST
  });
  test("gridViewContractApplication_txtcolPurSto2CA(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolPurSto2CA = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolPurSto2CAbtn =
      gridViewContractApplication_txtcolPurSto2CA.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolPurSto2CA =
      gridViewContractApplication_txtcolPurSto2CA.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolPurSto2CA.tagName).toBe("DIV");
    expect(gridViewContractApplication_txtcolPurSto2CA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPurSto2CA", () => {
    // START_USER_CODE-USER_txtcolPurSto2CA_TEST
    // END_USER_CODE-USER_txtcolPurSto2CA_TEST
  });
  test("gridViewContractApplication_txtcolSeedCont(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolSeedCont = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolSeedContbtn =
      gridViewContractApplication_txtcolSeedCont.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolSeedCont =
      gridViewContractApplication_txtcolSeedCont.parentElement.parentElement
        .parentElement;
    expect(gridViewContractApplication_txtcolSeedCont.tagName).toBe("DIV");
    expect(gridViewContractApplication_txtcolSeedCont.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedCont", () => {
    // START_USER_CODE-USER_txtcolSeedCont_TEST
    // END_USER_CODE-USER_txtcolSeedCont_TEST
  });
  test("gridViewContractApplication_txtcolSeedLeft(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolSeedLeft = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolSeedLeftbtn =
      gridViewContractApplication_txtcolSeedLeft.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolSeedLeft =
      gridViewContractApplication_txtcolSeedLeft.parentElement.parentElement
        .parentElement;
    expect(gridViewContractApplication_txtcolSeedLeft.tagName).toBe("DIV");
    expect(gridViewContractApplication_txtcolSeedLeft.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedLeft", () => {
    // START_USER_CODE-USER_txtcolSeedLeft_TEST
    // END_USER_CODE-USER_txtcolSeedLeft_TEST
  });
  test("gridViewContractApplication_txtcolSeed(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolSeed = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolSeedbtn =
      gridViewContractApplication_txtcolSeed.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolSeed =
      gridViewContractApplication_txtcolSeed.parentElement.parentElement
        .parentElement;
    expect(gridViewContractApplication_txtcolSeed.tagName).toBe("DIV");
    expect(gridViewContractApplication_txtcolSeed.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeed", () => {
    // START_USER_CODE-USER_txtcolSeed_TEST
    // END_USER_CODE-USER_txtcolSeed_TEST
  });
  test("gridSeedGroverApp_txtcolSeedVarietyTBD(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolSeedVarietyTBD = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolSeedVarietyTBDbtn =
      gridSeedGroverApp_txtcolSeedVarietyTBD.nextElementSibling
        .firstElementChild;
    gridSeedGroverApp_txtcolSeedVarietyTBD =
      gridSeedGroverApp_txtcolSeedVarietyTBD.parentElement.parentElement
        .parentElement;
    expect(gridSeedGroverApp_txtcolSeedVarietyTBD.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolSeedVarietyTBD.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedVarietyTBD", () => {
    // START_USER_CODE-USER_txtcolSeedVarietyTBD_TEST
    // END_USER_CODE-USER_txtcolSeedVarietyTBD_TEST
  });
  test("gridViewContractApplication_txtcolSeedVarietyTBDCA(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolSeedVarietyTBDCA = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolSeedVarietyTBDCAbtn =
      gridViewContractApplication_txtcolSeedVarietyTBDCA.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolSeedVarietyTBDCA =
      gridViewContractApplication_txtcolSeedVarietyTBDCA.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolSeedVarietyTBDCA.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_txtcolSeedVarietyTBDCA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedVarietyTBDCA", () => {
    // START_USER_CODE-USER_txtcolSeedVarietyTBDCA_TEST
    // END_USER_CODE-USER_txtcolSeedVarietyTBDCA_TEST
  });
  test("gridViewContractApplication_txtcolStoLbsToApply(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolStoLbsToApply = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolStoLbsToApplybtn =
      gridViewContractApplication_txtcolStoLbsToApply.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolStoLbsToApply =
      gridViewContractApplication_txtcolStoLbsToApply.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolStoLbsToApply.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_txtcolStoLbsToApply.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStoLbsToApply", () => {
    // START_USER_CODE-USER_txtcolStoLbsToApply_TEST
    // END_USER_CODE-USER_txtcolStoLbsToApply_TEST
  });
  test("gridViewContractApplication_txtcolTentativeApplied(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolTentativeApplied = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolTentativeAppliedbtn =
      gridViewContractApplication_txtcolTentativeApplied
        .nextElementSibling.firstElementChild;
    gridViewContractApplication_txtcolTentativeApplied =
      gridViewContractApplication_txtcolTentativeApplied.parentElement
        .parentElement.parentElement;
    expect(
      gridViewContractApplication_txtcolTentativeApplied.tagName
    ).toBe("DIV");
    expect(
      gridViewContractApplication_txtcolTentativeApplied.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTentativeApplied", () => {
    // START_USER_CODE-USER_txtcolTentativeApplied_TEST
    // END_USER_CODE-USER_txtcolTentativeApplied_TEST
  });
  test("gridViewContractApplication_txtcolTentLbs(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolTentLbs = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolTentLbsbtn =
      gridViewContractApplication_txtcolTentLbs.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolTentLbs =
      gridViewContractApplication_txtcolTentLbs.parentElement.parentElement
        .parentElement;
    expect(gridViewContractApplication_txtcolTentLbs.tagName).toBe("DIV");
    expect(gridViewContractApplication_txtcolTentLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTentLbs", () => {
    // START_USER_CODE-USER_txtcolTentLbs_TEST
    // END_USER_CODE-USER_txtcolTentLbs_TEST
  });
  test("gridSeedGroverApp_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolVendor = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolVendorbtn =
      gridSeedGroverApp_txtcolVendor.nextElementSibling.firstElementChild;
    gridSeedGroverApp_txtcolVendor =
      gridSeedGroverApp_txtcolVendor.parentElement.parentElement
        .parentElement;
    expect(gridSeedGroverApp_txtcolVendor.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolVendor.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
  test("gridViewContractApplication_txtcolVendorCA(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolVendorCA = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolVendorCAbtn =
      gridViewContractApplication_txtcolVendorCA.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolVendorCA =
      gridViewContractApplication_txtcolVendorCA.parentElement.parentElement
        .parentElement;
    expect(gridViewContractApplication_txtcolVendorCA.tagName).toBe("DIV");
    expect(gridViewContractApplication_txtcolVendorCA.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorCA", () => {
    // START_USER_CODE-USER_txtcolVendorCA_TEST
    // END_USER_CODE-USER_txtcolVendorCA_TEST
  });
  test("gridSeedGroverApp_txtcolVendorContNumsSG(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolVendorContNumsSG = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolVendorContNumsSGbtn =
      gridSeedGroverApp_txtcolVendorContNumsSG.nextElementSibling
        .firstElementChild;
    gridSeedGroverApp_txtcolVendorContNumsSG =
      gridSeedGroverApp_txtcolVendorContNumsSG.parentElement
        .parentElement.parentElement;
    expect(gridSeedGroverApp_txtcolVendorContNumsSG.tagName).toBe(
      "DIV"
    );
    expect(
      gridSeedGroverApp_txtcolVendorContNumsSG.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorContNumsSG", () => {
    // START_USER_CODE-USER_txtcolVendorContNumsSG_TEST
    // END_USER_CODE-USER_txtcolVendorContNumsSG_TEST
  });
  test("gridViewContractApplication_txtcolVendorContNum(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolVendorContNum = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolVendorContNumbtn =
      gridViewContractApplication_txtcolVendorContNum.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolVendorContNum =
      gridViewContractApplication_txtcolVendorContNum.parentElement
        .parentElement.parentElement;
    expect(
      gridViewContractApplication_txtcolVendorContNum.tagName
    ).toBe("DIV");
    expect(
      gridViewContractApplication_txtcolVendorContNum.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorContNum", () => {
    // START_USER_CODE-USER_txtcolVendorContNum_TEST
    // END_USER_CODE-USER_txtcolVendorContNum_TEST
  });
  test("gridSeedGroverApp_txtcolVendorLbs1(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolVendorLbs1 = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolVendorLbs1btn =
      gridSeedGroverApp_txtcolVendorLbs1.nextElementSibling
        .firstElementChild;
    gridSeedGroverApp_txtcolVendorLbs1 =
      gridSeedGroverApp_txtcolVendorLbs1.parentElement.parentElement
        .parentElement;
    expect(gridSeedGroverApp_txtcolVendorLbs1.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolVendorLbs1.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorLbs1", () => {
    // START_USER_CODE-USER_txtcolVendorLbs1_TEST
    // END_USER_CODE-USER_txtcolVendorLbs1_TEST
  });
  test("gridViewContractApplication_txtcolVendorLbs1CA(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolVendorLbs1CA = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolVendorLbs1CAbtn =
      gridViewContractApplication_txtcolVendorLbs1CA.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolVendorLbs1CA =
      gridViewContractApplication_txtcolVendorLbs1CA.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolVendorLbs1CA.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_txtcolVendorLbs1CA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorLbs1CA", () => {
    // START_USER_CODE-USER_txtcolVendorLbs1CA_TEST
    // END_USER_CODE-USER_txtcolVendorLbs1CA_TEST
  });
  test("gridSeedGroverApp_txtcolVendorLbs2(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolVendorLbs2 = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolVendorLbs2btn =
      gridSeedGroverApp_txtcolVendorLbs2.nextElementSibling
        .firstElementChild;
    gridSeedGroverApp_txtcolVendorLbs2 =
      gridSeedGroverApp_txtcolVendorLbs2.parentElement.parentElement
        .parentElement;
    expect(gridSeedGroverApp_txtcolVendorLbs2.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolVendorLbs2.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorLbs2", () => {
    // START_USER_CODE-USER_txtcolVendorLbs2_TEST
    // END_USER_CODE-USER_txtcolVendorLbs2_TEST
  });
  test("gridViewContractApplication_txtcolVendorLbs2CA(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolVendorLbs2CA = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolVendorLbs2CAbtn =
      gridViewContractApplication_txtcolVendorLbs2CA.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolVendorLbs2CA =
      gridViewContractApplication_txtcolVendorLbs2CA.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolVendorLbs2CA.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_txtcolVendorLbs2CA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorLbs2CA", () => {
    // START_USER_CODE-USER_txtcolVendorLbs2CA_TEST
    // END_USER_CODE-USER_txtcolVendorLbs2CA_TEST
  });
  test("gridSeedGroverApp_txtcolVendorRcpt(Grid Widget) Test Cases", async () => {
    let gridSeedGroverApp_txtcolVendorRcpt = screen.getByTestId(
      "gridSeedGroverApp"
    );
    let gridSeedGroverApp_txtcolVendorRcptbtn =
      gridSeedGroverApp_txtcolVendorRcpt.nextElementSibling
        .firstElementChild;
    gridSeedGroverApp_txtcolVendorRcpt =
      gridSeedGroverApp_txtcolVendorRcpt.parentElement.parentElement
        .parentElement;
    expect(gridSeedGroverApp_txtcolVendorRcpt.tagName).toBe("DIV");
    expect(gridSeedGroverApp_txtcolVendorRcpt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ContractApplications2009_gridSeedGroverApp")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorRcpt", () => {
    // START_USER_CODE-USER_txtcolVendorRcpt_TEST
    // END_USER_CODE-USER_txtcolVendorRcpt_TEST
  });
  test("gridViewContractApplication_txtcolVendorRcptCA(Grid Widget) Test Cases", async () => {
    let gridViewContractApplication_txtcolVendorRcptCA = screen.getByTestId(
      "gridViewContractApplication"
    );
    let gridViewContractApplication_txtcolVendorRcptCAbtn =
      gridViewContractApplication_txtcolVendorRcptCA.nextElementSibling
        .firstElementChild;
    gridViewContractApplication_txtcolVendorRcptCA =
      gridViewContractApplication_txtcolVendorRcptCA.parentElement
        .parentElement.parentElement;
    expect(gridViewContractApplication_txtcolVendorRcptCA.tagName).toBe(
      "DIV"
    );
    expect(
      gridViewContractApplication_txtcolVendorRcptCA.classList
    ).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "Settlements:ContractApplications2009_gridViewContractApplication"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendorRcptCA", () => {
    // START_USER_CODE-USER_txtcolVendorRcptCA_TEST
    // END_USER_CODE-USER_txtcolVendorRcptCA_TEST
  });
  test("txtFarm(Textbox Widget) Test Cases", async () => {
    const txtFarm = screen.getByTestId("txtFarm");
    expect(txtFarm.tagName).toBe("INPUT");
    expect(txtFarm.type).toBe("text");
    expect(txtFarm.classList).toContain("textboxWidgetClass");
    expect(txtFarm.previousElementSibling.textContent).toEqual(
      t("Settlements:ContractApplications2009_txtFarm")
    );
    await act(async () => {
      userEvent.type(txtFarm, "123");
    });
    expect(txtFarm.getAttribute("value")).toBe("");
    expect(txtFarm.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFarm", () => {
    // START_USER_CODE-USER_txtFarm_TEST
    // END_USER_CODE-USER_txtFarm_TEST
  });
  test("txtLbsToApply(Textbox Widget) Test Cases", async () => {
    const txtLbsToApply = screen.getByTestId("txtLbsToApply");
    expect(txtLbsToApply.tagName).toBe("INPUT");
    expect(txtLbsToApply.type).toBe("text");
    expect(txtLbsToApply.classList).toContain("textboxWidgetClass");
    expect(txtLbsToApply.previousElementSibling.textContent).toEqual(
      t("Settlements:ContractApplications2009_txtLbsToApply")
    );
    await act(async () => {
      userEvent.type(txtLbsToApply, "123");
    });
    expect(txtLbsToApply.getAttribute("value")).toBe("");
    expect(txtLbsToApply.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLbsToApply", () => {
    // START_USER_CODE-USER_txtLbsToApply_TEST
    // END_USER_CODE-USER_txtLbsToApply_TEST
  });
  test("txtPeanutType(Textbox Widget) Test Cases", async () => {
    const txtPeanutType = screen.getByTestId("txtPeanutType");
    expect(txtPeanutType.tagName).toBe("INPUT");
    expect(txtPeanutType.type).toBe("text");
    expect(txtPeanutType.classList).toContain("textboxWidgetClass");
    expect(txtPeanutType.previousElementSibling.textContent).toEqual(
      t("Settlements:ContractApplications2009_txtPeanutType")
    );
    await act(async () => {
      userEvent.type(txtPeanutType, "123");
    });
    expect(txtPeanutType.getAttribute("value")).toBe("");
    expect(txtPeanutType.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
  test("txtPounds(Textbox Widget) Test Cases", async () => {
    const txtPounds = screen.getByTestId("txtPounds");
    expect(txtPounds.tagName).toBe("INPUT");
    expect(txtPounds.type).toBe("text");
    expect(txtPounds.classList).toContain("textboxWidgetClass");
    expect(txtPounds.previousElementSibling.textContent).toEqual(
      t("Settlements:ContractApplications2009_txtPounds")
    );
    await act(async () => {
      userEvent.type(txtPounds, "123");
    });
    expect(txtPounds.getAttribute("value")).toBe("");
    expect(txtPounds.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPounds", () => {
    // START_USER_CODE-USER_txtPounds_TEST
    // END_USER_CODE-USER_txtPounds_TEST
  });
  test("txtPrchsStrg(Textbox Widget) Test Cases", async () => {
    const txtPrchsStrg = screen.getByTestId("txtPrchsStrg");
    expect(txtPrchsStrg.tagName).toBe("INPUT");
    expect(txtPrchsStrg.type).toBe("text");
    expect(txtPrchsStrg.classList).toContain("textboxWidgetClass");
    expect(txtPrchsStrg.previousElementSibling.textContent).toEqual(
      t("Settlements:ContractApplications2009_txtPrchsStrg")
    );
    await act(async () => {
      userEvent.type(txtPrchsStrg, "123");
    });
    expect(txtPrchsStrg.getAttribute("value")).toBe("");
    expect(txtPrchsStrg.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPrchsStrg", () => {
    // START_USER_CODE-USER_txtPrchsStrg_TEST
    // END_USER_CODE-USER_txtPrchsStrg_TEST
  });
  test("txtPrice(Textbox Widget) Test Cases", async () => {
    const txtPrice = screen.getByTestId("txtPrice");
    expect(txtPrice.tagName).toBe("INPUT");
    expect(txtPrice.type).toBe("text");
    expect(txtPrice.classList).toContain("textboxWidgetClass");
    expect(txtPrice.previousElementSibling.textContent).toEqual(
      t("Settlements:ContractApplications2009_txtPrice")
    );
    await act(async () => {
      userEvent.type(txtPrice, "123");
    });
    expect(txtPrice.getAttribute("value")).toBe("");
    expect(txtPrice.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPrice", () => {
    // START_USER_CODE-USER_txtPrice_TEST
    // END_USER_CODE-USER_txtPrice_TEST
  });
  test("txtSC95Num(Textbox Widget) Test Cases", async () => {
    const txtSC95Num = screen.getByTestId("txtSC95Num");
    expect(txtSC95Num.tagName).toBe("INPUT");
    expect(txtSC95Num.type).toBe("text");
    expect(txtSC95Num.classList).toContain("textboxWidgetClass");
    expect(txtSC95Num.previousElementSibling.textContent).toEqual(
      t("Settlements:ContractApplications2009_txtSC95Num")
    );
    await act(async () => {
      userEvent.type(txtSC95Num, "123");
    });
    expect(txtSC95Num.getAttribute("value")).toBe("");
    expect(txtSC95Num.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSC95Num", () => {
    // START_USER_CODE-USER_txtSC95Num_TEST
    // END_USER_CODE-USER_txtSC95Num_TEST
  });
  test("txtSeedSpec(Textbox Widget) Test Cases", async () => {
    const txtSeedSpec = screen.getByTestId("txtSeedSpec");
    expect(txtSeedSpec.tagName).toBe("INPUT");
    expect(txtSeedSpec.type).toBe("text");
    expect(txtSeedSpec.classList).toContain("textboxWidgetClass");
    expect(txtSeedSpec.previousElementSibling.textContent).toEqual(
      t("Settlements:ContractApplications2009_txtSeedSpec")
    );
    await act(async () => {
      userEvent.type(txtSeedSpec, "123");
    });
    expect(txtSeedSpec.getAttribute("value")).toBe("");
    expect(txtSeedSpec.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeedSpec", () => {
    // START_USER_CODE-USER_txtSeedSpec_TEST
    // END_USER_CODE-USER_txtSeedSpec_TEST
  });
  test("txtSeed(Textbox Widget) Test Cases", async () => {
    const txtSeed = screen.getByTestId("txtSeed");
    expect(txtSeed.tagName).toBe("INPUT");
    expect(txtSeed.type).toBe("text");
    expect(txtSeed.classList).toContain("textboxWidgetClass");
    expect(txtSeed.previousElementSibling.textContent).toEqual(
      t("Settlements:ContractApplications2009_txtSeed")
    );
    await act(async () => {
      userEvent.type(txtSeed, "123");
    });
    expect(txtSeed.getAttribute("value")).toBe("");
    expect(txtSeed.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeed", () => {
    // START_USER_CODE-USER_txtSeed_TEST
    // END_USER_CODE-USER_txtSeed_TEST
  });
});
