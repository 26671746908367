/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FarmerStockPriceProfile from "./FarmerStockPriceProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FarmerStockPriceProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FarmerStockPriceProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is FarmerStockPriceProfile Loads Successfully", () => {
    expect(screen.getByText("FarmerStockPriceProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for FarmerStockPriceProfile", () => {
    // START_USER_CODE-USER_FarmerStockPriceProfile_Custom_Test_Case
    // END_USER_CODE-USER_FarmerStockPriceProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FarmerStockPriceProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmerStockPriceProfile_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmerStockPriceProfile_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpFarmSrockPriceProfile(GroupBox Widget) Test Cases", async () => {
    const grpFarmSrockPriceProfile = screen.getByTestId(
      "grpFarmSrockPriceProfile"
    );
    expect(grpFarmSrockPriceProfile.tagName).toBe("BUTTON");
    expect(grpFarmSrockPriceProfile.type).toBe("button");
    expect(grpFarmSrockPriceProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpFarmSrockPriceProfile", () => {
    // START_USER_CODE-USER_grpFarmSrockPriceProfile_TEST
    // END_USER_CODE-USER_grpFarmSrockPriceProfile_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmerStockPriceProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FarmerStockPriceProfile_lblAddedByValue"
      )
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FarmerStockPriceProfile_lblChangedBy"
      )
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FarmerStockPriceProfile_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("txtArea(Textbox Widget) Test Cases", async () => {
    const txtArea = screen.getByTestId("txtArea");
    expect(txtArea.tagName).toBe("INPUT");
    expect(txtArea.type).toBe("text");
    expect(txtArea.classList).toContain("textboxWidgetClass");
    expect(txtArea.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmerStockPriceProfile_txtArea")
    );
    await act(async () => {
      userEvent.type(txtArea, "1");
    });
  });
  test("Custom Test Cases for txtArea", () => {
    // START_USER_CODE-USER_txtArea_TEST
    // END_USER_CODE-USER_txtArea_TEST
  });
  test("txtFarmerStockPrice(Textbox Widget) Test Cases", async () => {
    const txtFarmerStockPrice = screen.getByTestId("txtFarmerStockPrice");
    expect(txtFarmerStockPrice.tagName).toBe("INPUT");
    expect(txtFarmerStockPrice.type).toBe("text");
    expect(txtFarmerStockPrice.classList).toContain("textboxWidgetClass");
    expect(txtFarmerStockPrice.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FarmerStockPriceProfile_txtFarmerStockPrice"
      )
    );
    await act(async () => {
      userEvent.type(txtFarmerStockPrice, "1");
    });
  });
  test("Custom Test Cases for txtFarmerStockPrice", () => {
    // START_USER_CODE-USER_txtFarmerStockPrice_TEST
    // END_USER_CODE-USER_txtFarmerStockPrice_TEST
  });
  test("txtPeanutType(Textbox Widget) Test Cases", async () => {
    const txtPeanutType = screen.getByTestId("txtPeanutType");
    expect(txtPeanutType.tagName).toBe("INPUT");
    expect(txtPeanutType.type).toBe("text");
    expect(txtPeanutType.classList).toContain("textboxWidgetClass");
    expect(txtPeanutType.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FarmerStockPriceProfile_txtPeanutType"
      )
    );
    await act(async () => {
      userEvent.type(txtPeanutType, "1");
    });
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
});
