/* eslint-disable*/
import React from "react";
import ContractManagement_AddendumSeedGrower2009 from "./AddendumSeedGrower2009";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("AddendumSeedGrower2009 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_AddendumSeedGrower2009 />);
    });
  });
  afterEach(cleanup);
  test("is AddendumSeedGrower2009 Loads Successfully", () => {
    expect(screen.getByText("Seed Grower Agreement")).toBeInTheDocument;
  });
  test("Custom Test Cases for AddendumSeedGrower2009", () => {
    // START_USER_CODE-USER_AddendumSeedGrower2009_Custom_Test_Case
    // END_USER_CODE-USER_AddendumSeedGrower2009_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_AddendumSeedGrower2009 />);
    });
  });
  afterEach(cleanup);
  test("btnApplications(Button Widget) Test Cases", async () => {
    const btnApplications = screen.getByTestId("btnApplications");
    expect(btnApplications).toBeInTheDocument;
    expect(btnApplications.textContent).toEqual(
      t("ContractManagement:AddendumSeedGrower2009_btnApplications")
    );
  });
  test("Custom Test Cases for btnApplications", () => {
    // START_USER_CODE-USER_btnApplications_TEST
    // END_USER_CODE-USER_btnApplications_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("ContractManagement:AddendumSeedGrower2009_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("ContractManagement:AddendumSeedGrower2009_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("gridPeanut(Grid Widget) Test Cases", async () => {
    let gridPeanut = screen.getByTestId("gridPeanut");
    let gridPeanutbtn = gridPeanut.nextElementSibling.firstElementChild;
    gridPeanut = gridPeanut.parentElement.parentElement.parentElement;
    expect(gridPeanut.tagName).toBe("DIV");
    expect(gridPeanut.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:AddendumSeedGrower2009_gridPeanut")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPeanut", () => {
    // START_USER_CODE-USER_gridPeanut_TEST
    // END_USER_CODE-USER_gridPeanut_TEST
  });
  test("grpSeedGrower(GroupBox Widget) Test Cases", async () => {
    const grpSeedGrower = screen.getByTestId("grpSeedGrower");
    expect(grpSeedGrower.tagName).toBe("BUTTON");
    expect(grpSeedGrower.type).toBe("button");
    expect(grpSeedGrower.classList).toContain("btn");
  });
  test("Custom Test Cases for grpSeedGrower", () => {
    // START_USER_CODE-USER_grpSeedGrower_TEST
    // END_USER_CODE-USER_grpSeedGrower_TEST
  });
  test("grpTotalApp(GroupBox Widget) Test Cases", async () => {
    const grpTotalApp = screen.getByTestId("grpTotalApp");
    expect(grpTotalApp.tagName).toBe("BUTTON");
    expect(grpTotalApp.type).toBe("button");
    expect(grpTotalApp.classList).toContain("btn");
  });
  test("Custom Test Cases for grpTotalApp", () => {
    // START_USER_CODE-USER_grpTotalApp_TEST
    // END_USER_CODE-USER_grpTotalApp_TEST
  });
  test("lblPerTon(Label Widget) Test Cases", async () => {
    const lblPerTon = screen.getByTestId("lblPerTon");
    expect(lblPerTon.tagName).toBe("LABEL");
    expect(lblPerTon.classList).toContain("form-label");
    expect(lblPerTon.textContent).toEqual(
      t("ContractManagement:AddendumSeedGrower2009_lblPerTon")
    );
  });
  test("Custom Test Cases for lblPerTon", () => {
    // START_USER_CODE-USER_lblPerTon_TEST
    // END_USER_CODE-USER_lblPerTon_TEST
  });
  test("gridPeanut_txtApplied(Grid Widget) Test Cases", async () => {
    let gridPeanut_txtApplied = screen.getByTestId("gridPeanut");
    let gridPeanut_txtAppliedbtn =
      gridPeanut_txtApplied.nextElementSibling.firstElementChild;
    gridPeanut_txtApplied =
      gridPeanut_txtApplied.parentElement.parentElement.parentElement;
    expect(gridPeanut_txtApplied.tagName).toBe("DIV");
    expect(gridPeanut_txtApplied.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("ContractManagement:AddendumSeedGrower2009_gridPeanut")
      )
    ).toBeInTheDocument;
    const gridPeanut_txtApplieddata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridPeanut_txtApplieddata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridPeanut"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridPeanut_txtAppliedbtn, { button: 0 });
      });
      const gridPeanut_txtAppliedadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridPeanut_txtAppliedadd, { button: 0 });
      });
    }
    const txtApplied = screen.getByTestId("txtApplied");
    expect(txtApplied.tagName).toBe("INPUT");
    expect(txtApplied.type).toBe("text");
    expect(txtApplied.classList).toContain("textboxWidgetClass");
    expect(
      screen.getAllByText(
        t("ContractManagement:AddendumSeedGrower2009_txtApplied")
      )
    ).toBeInTheDocument;
    await act(async () => {
      userEvent.type(txtApplied, "1");
    });
  });
  test("Custom Test Cases for txtApplied", () => {
    // START_USER_CODE-USER_txtApplied_TEST
    // END_USER_CODE-USER_txtApplied_TEST
  });
  test("txtCompany(Textbox Widget) Test Cases", async () => {
    const txtCompany = screen.getByTestId("txtCompany");
    expect(txtCompany.tagName).toBe("INPUT");
    expect(txtCompany.type).toBe("text");
    expect(txtCompany.classList).toContain("textboxWidgetClass");
    expect(txtCompany.previousElementSibling.textContent).toEqual(
      t("ContractManagement:AddendumSeedGrower2009_txtCompany")
    );
    expect(txtCompany.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtCompany", () => {
    // START_USER_CODE-USER_txtCompany_TEST
    // END_USER_CODE-USER_txtCompany_TEST
  });
  test("txtContract(Textbox Widget) Test Cases", async () => {
    const txtContract = screen.getByTestId("txtContract");
    expect(txtContract.tagName).toBe("INPUT");
    expect(txtContract.type).toBe("text");
    expect(txtContract.classList).toContain("textboxWidgetClass");
    expect(txtContract.previousElementSibling.textContent).toEqual(
      t("ContractManagement:AddendumSeedGrower2009_txtContract")
    );
    expect(txtContract.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtContract", () => {
    // START_USER_CODE-USER_txtContract_TEST
    // END_USER_CODE-USER_txtContract_TEST
  });
  test("txtContract2(Textbox Widget) Test Cases", async () => {
    const txtContract2 = screen.getByTestId("txtContract2");
    expect(txtContract2.tagName).toBe("INPUT");
    expect(txtContract2.type).toBe("text");
    expect(txtContract2.classList).toContain("textboxWidgetClass");
    expect(txtContract2.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtContract2", () => {
    // START_USER_CODE-USER_txtContract2_TEST
    // END_USER_CODE-USER_txtContract2_TEST
  });
  test("txtOpenPounds(Textbox Widget) Test Cases", async () => {
    const txtOpenPounds = screen.getByTestId("txtOpenPounds");
    expect(txtOpenPounds.tagName).toBe("INPUT");
    expect(txtOpenPounds.type).toBe("text");
    expect(txtOpenPounds.classList).toContain("textboxWidgetClass");
    expect(txtOpenPounds.previousElementSibling.textContent).toEqual(
      t("ContractManagement:AddendumSeedGrower2009_txtOpenPounds")
    );
    expect(txtOpenPounds.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtOpenPounds", () => {
    // START_USER_CODE-USER_txtOpenPounds_TEST
    // END_USER_CODE-USER_txtOpenPounds_TEST
  });
  test("txtPeanutType(Textbox Widget) Test Cases", async () => {
    const txtPeanutType = screen.getByTestId("txtPeanutType");
    expect(txtPeanutType.tagName).toBe("INPUT");
    expect(txtPeanutType.type).toBe("text");
    expect(txtPeanutType.classList).toContain("textboxWidgetClass");
    expect(txtPeanutType.previousElementSibling.textContent).toEqual(
      t("ContractManagement:AddendumSeedGrower2009_txtPeanutType")
    );
    expect(txtPeanutType.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
  test("txtPounds(Textbox Widget) Test Cases", async () => {
    const txtPounds = screen.getByTestId("txtPounds");
    expect(txtPounds.tagName).toBe("INPUT");
    expect(txtPounds.type).toBe("text");
    expect(txtPounds.classList).toContain("textboxWidgetClass");
    expect(txtPounds.previousElementSibling.textContent).toEqual(
      t("ContractManagement:AddendumSeedGrower2009_txtPounds")
    );
    await act(async () => {
      userEvent.type(txtPounds, "1");
    });
  });
  test("Custom Test Cases for txtPounds", () => {
    // START_USER_CODE-USER_txtPounds_TEST
    // END_USER_CODE-USER_txtPounds_TEST
  });
  test("txtSdAcr1(Textbox Widget) Test Cases", async () => {
    const txtSdAcr1 = screen.getByTestId("txtSdAcr1");
    expect(txtSdAcr1.tagName).toBe("INPUT");
    expect(txtSdAcr1.type).toBe("text");
    expect(txtSdAcr1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSdAcr1, "1");
    });
  });
  test("Custom Test Cases for txtSdAcr1", () => {
    // START_USER_CODE-USER_txtSdAcr1_TEST
    // END_USER_CODE-USER_txtSdAcr1_TEST
  });
  test("txtSdAcr2(Textbox Widget) Test Cases", async () => {
    const txtSdAcr2 = screen.getByTestId("txtSdAcr2");
    expect(txtSdAcr2.tagName).toBe("INPUT");
    expect(txtSdAcr2.type).toBe("text");
    expect(txtSdAcr2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSdAcr2, "1");
    });
  });
  test("Custom Test Cases for txtSdAcr2", () => {
    // START_USER_CODE-USER_txtSdAcr2_TEST
    // END_USER_CODE-USER_txtSdAcr2_TEST
  });
  test("txtSeededAcres(Textbox Widget) Test Cases", async () => {
    const txtSeededAcres = screen.getByTestId("txtSeededAcres");
    expect(txtSeededAcres.tagName).toBe("INPUT");
    expect(txtSeededAcres.type).toBe("text");
    expect(txtSeededAcres.classList).toContain("textboxWidgetClass");
    expect(txtSeededAcres.previousElementSibling.textContent).toEqual(
      t("ContractManagement:AddendumSeedGrower2009_txtSeededAcres")
    );
    await act(async () => {
      userEvent.type(txtSeededAcres, "1");
    });
  });
  test("Custom Test Cases for txtSeededAcres", () => {
    // START_USER_CODE-USER_txtSeededAcres_TEST
    // END_USER_CODE-USER_txtSeededAcres_TEST
  });
  test("txtTotApplied(Textbox Widget) Test Cases", async () => {
    const txtTotApplied = screen.getByTestId("txtTotApplied");
    expect(txtTotApplied.tagName).toBe("INPUT");
    expect(txtTotApplied.type).toBe("text");
    expect(txtTotApplied.classList).toContain("textboxWidgetClass");
    expect(txtTotApplied.previousElementSibling.textContent).toEqual(
      t("ContractManagement:AddendumSeedGrower2009_txtTotApplied")
    );
    expect(txtTotApplied.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtTotApplied", () => {
    // START_USER_CODE-USER_txtTotApplied_TEST
    // END_USER_CODE-USER_txtTotApplied_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("ContractManagement:AddendumSeedGrower2009_txtVendor")
    );
    expect(txtVendor.getAttribute("readonly")).toContain("");
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
});
