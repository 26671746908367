/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  goTo,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  TextAreaWidget,
  getData,
  DateWidget,
  setData,
  disable,
  enable,
  setValue,
  getValue,
  hide,
  show,
  isEnabled
} from "../../shared/WindowImports";

import "./SpecialPayablesProfile.scss";

import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { AccountPayment } from "../Service/AccountPayment";
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { NumericValueValidataion } from './../../Common/Constants';
import StripPic from "../../../../assets/img/PinStrip.png";

// END_USER_CODE-USER_IMPORTS
function AccountPayment_SpecialPayablesProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SpecialPayablesProfile",
    windowName: "SpecialPayablesProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "AccountPayment.SpecialPayablesProfile",
    lstPremiumDeductionSchDetail: [],
    // START_USER_CODE-USER_SpecialPayablesProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Special Payment Entry",
      scrCode: "PN1100I",
    },
    // END_USER_CODE-USER_SpecialPayablesProfile_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxSpcPayableProfile2",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCreateHoldPay: {
      name: "btnCreateHoldPay",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Create Hold Pay",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreateHoldPay_PROPERTIES

      // END_USER_CODE-USER_btnCreateHoldPay_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxSpcPayableProfile2",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxSpcPayableProfile2",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnPrintChecks: {
      name: "btnPrintChecks",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print Checks",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintChecks_PROPERTIES

      // END_USER_CODE-USER_btnPrintChecks_PROPERTIES
    },
    btnSaveToBatch: {
      name: "btnSaveToBatch",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Save to Batch",
      CharWidth: "28",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSaveToBatch_PROPERTIES

      // END_USER_CODE-USER_btnSaveToBatch_PROPERTIES
    },
    btnScanDocuments: {
      name: "btnScanDocuments",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Scan Documents",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnScanDocuments_PROPERTIES

      // END_USER_CODE-USER_btnScanDocuments_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxSpcPayableProfile1",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    txtVndrName: {
      name: "txtVndrName",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocation_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtLocation_PROPERTIES
    },
    btnViewDocuments: {
      name: "btnViewDocuments",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "View Documents",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnViewDocuments_PROPERTIES

      // END_USER_CODE-USER_btnViewDocuments_PROPERTIES
    },
    colChargeLocation: {
      name: "colChargeLocation",
      type: "GridColumnWidget",
      parent: "gridSpecialPaymentEntry",
      Label: "Charge Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChargeLocation_PROPERTIES

      // END_USER_CODE-USER_colChargeLocation_PROPERTIES
    },
    colComments: {
      name: "colComments",
      type: "GridColumnWidget",
      parent: "gridSpecialPaymentEntry",
      Label: "Comments",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colComments_PROPERTIES

      // END_USER_CODE-USER_colComments_PROPERTIES
    },
    colDate: {
      name: "colDate",
      type: "GridColumnWidget",
      parent: "gridSpecialPaymentEntry",
      Label: "Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDate_PROPERTIES

      // END_USER_CODE-USER_colDate_PROPERTIES
    },
    colInvoiceNumber: {
      name: "colInvoiceNumber",
      type: "GridColumnWidget",
      parent: "gridSpecialPaymentEntry",
      Label: "Invoice Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInvoiceNumber_PROPERTIES

      // END_USER_CODE-USER_colInvoiceNumber_PROPERTIES
    },
    colItem: {
      name: "colItem",
      type: "GridColumnWidget",
      parent: "gridSpecialPaymentEntry",
      Label: "Item #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colItem_PROPERTIES

      // END_USER_CODE-USER_colItem_PROPERTIES
    },
    colItemCode: {
      name: "colItemCode",
      type: "GridColumnWidget",
      parent: "gridSpecialPaymentEntry",
      Label: "Item Code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colItemCode_PROPERTIES

      // END_USER_CODE-USER_colItemCode_PROPERTIES
    },
    colOleicInd: {
      name: "colOleicInd",
      type: "GridColumnWidget",
      parent: "gridSpecialPaymentEntry",
      Label: "Oleic Ind",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOleicInd_PROPERTIES

      // END_USER_CODE-USER_colOleicInd_PROPERTIES
    },
    colPayAmount: {
      name: "colPayAmount",
      type: "GridColumnWidget",
      parent: "gridSpecialPaymentEntry",
      Label: "Pay Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPayAmount_PROPERTIES

      // END_USER_CODE-USER_colPayAmount_PROPERTIES
    },
    colPeanutType: {
      name: "colPeanutType",
      type: "GridColumnWidget",
      parent: "gridSpecialPaymentEntry",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutType_PROPERTIES

      // END_USER_CODE-USER_colPeanutType_PROPERTIES
    },
    colTransaction: {
      name: "colTransaction",
      type: "GridColumnWidget",
      parent: "gridSpecialPaymentEntry",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      Visible: false
      // START_USER_CODE-USER_colPeanutType_PROPERTIES

      // END_USER_CODE-USER_colPeanutType_PROPERTIES
    },
    colSeedGeneration: {
      name: "colSeedGeneration",
      type: "GridColumnWidget",
      parent: "gridSpecialPaymentEntry",
      Label: "Seed Generation",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedGeneration_PROPERTIES

      // END_USER_CODE-USER_colSeedGeneration_PROPERTIES
    },
    ddChargeLocationprof: {
      name: "ddChargeLocationprof",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableProfile2",
      Label: "Charge Location",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
      optionMaxLength: 20

      // START_USER_CODE-USER_ddChargeLocationprof_PROPERTIES

      // END_USER_CODE-USER_ddChargeLocationprof_PROPERTIES
    },
    ddItemCodeprof: {
      name: "ddItemCodeprof",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableProfile2",
      Label: "Item",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
      optionMaxLength: 20

      // START_USER_CODE-USER_ddItemCodeprof_PROPERTIES

      // END_USER_CODE-USER_ddItemCodeprof_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableProfile2",
      Label: "Oleic",
      DataProviderForDropDown: "inline",
      // Option: '',
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPayeeProf: {
      name: "ddPayeeProf",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableProfile1",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPayeeProf_PROPERTIES
      optionMaxLength: 40
      // END_USER_CODE-USER_ddPayeeProf_PROPERTIES
    },
    ddPeanutTypeprof: {
      name: "ddPeanutTypeprof",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableProfile2",
      Label: "Peanut Type",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutTypeprof_PROPERTIES

      // END_USER_CODE-USER_ddPeanutTypeprof_PROPERTIES
    },
    ddSeedGenerationprof: {
      name: "ddSeedGenerationprof",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableProfile2",
      Label: "Seed",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeedGenerationprof_PROPERTIES

      // END_USER_CODE-USER_ddSeedGenerationprof_PROPERTIES
    },
    ddSpoolLocationProf: {
      name: "ddSpoolLocationProf",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableProfile1",
      Label: "Spool Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSpoolLocationProf_PROPERTIES

      // END_USER_CODE-USER_ddSpoolLocationProf_PROPERTIES
    },
    grpbxPendingEdit: {
      name: "grpbxPendingEdit",
      type: "GroupBoxWidget",
      parent: "grpbxSpcPayableProfile1",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxPendingEdit_PROPERTIES

      // END_USER_CODE-USER_grpbxPendingEdit_PROPERTIES
    },
    txtARBalance: {
      name: "txtARBalance",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true

      // START_USER_CODE-USER_txtCollectAR_PROPERTIES

      // END_USER_CODE-USER_txtCollectAR_PROPERTIES
    },
    txtCRBalance: {
      name: "txtCRBalance",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtCollectAR_PROPERTIES

      // END_USER_CODE-USER_txtCollectAR_PROPERTIES
    },
    lblCropYear2: {
      name: "lblCropYear2",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Crop Year :",
      ColSpan: "6",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear2_PROPERTIES

      // END_USER_CODE-USER_lblCropYear2_PROPERTIES
    },

    lblPending: {
      name: "lblPending",
      type: "LabelWidget",
      parent: "grpbxPendingEdit",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Width: "50"
      // START_USER_CODE-USER_lblPending_PROPERTIES

      // END_USER_CODE-USER_lblPending_PROPERTIES
    },
    lblEdite: {
      name: "lblEdite",
      type: "LabelWidget",
      parent: "grpbxPendingEdit",
      Label: "* Edit *",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Width: "50"
      // START_USER_CODE-USER_lblPending_PROPERTIES

      // END_USER_CODE-USER_lblPending_PROPERTIES
    },
    lblAdd: {
      name: "lblAdd",
      type: "LabelWidget",
      parent: "grpbxPendingEdit",
      Label: "* Add *",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Width: "50"
      // START_USER_CODE-USER_lblPending_PROPERTIES

      // END_USER_CODE-USER_lblPending_PROPERTIES
    },
    lblRemarksComment: {
      name: "lblRemarksComment",
      type: "LabelWidget",
      parent: "grpbxremarks",
      Label:
        '*The "Create Hold Pay" button will send the RTP\'s to the Hold Pay screen and will not save the remarks.*',
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemarksComment_PROPERTIES

      // END_USER_CODE-USER_lblRemarksComment_PROPERTIES
    },
    txtSRBalance: {
      name: "txtSRBalance",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtCollectAR_PROPERTIES

      // END_USER_CODE-USER_txtCollectAR_PROPERTIES
    },
    txtValOfRTP: {
      name: "txtValOfRTP",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      HasLabel: "Value Of RTP's:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValOfRTP_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtValOfRTP_PROPERTIES
    },
    txtValueOfCheck: {
      name: "txtValueOfCheck",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtCollectAR_PROPERTIES

      // END_USER_CODE-USER_txtCollectAR_PROPERTIES
    },
    txtCheck: {
      name: "txtCheck",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "Check #:",
      ColSpan: "2",
      Visible: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheck_PROPERTIES

      // END_USER_CODE-USER_txtCheck_PROPERTIES
    },
    txtcolChargeLocation: {
      name: "txtcolChargeLocation",
      type: "TextBoxWidget",
      colName: "colChargeLocation",
      parent: "gridSpecialPaymentEntry",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChargeLocation_PROPERTIES

      // END_USER_CODE-USER_txtcolChargeLocation_PROPERTIES
    },
    txtcolComments: {
      name: "txtcolComments",
      type: "TextBoxWidget",
      colName: "colComments",
      parent: "gridSpecialPaymentEntry",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolComments_PROPERTIES

      // END_USER_CODE-USER_txtcolComments_PROPERTIES
    },
    txtcolDate: {
      name: "txtcolDate",
      type: "TextBoxWidget",
      colName: "colDate",
      parent: "gridSpecialPaymentEntry",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDate_PROPERTIES

      // END_USER_CODE-USER_txtcolDate_PROPERTIES
    },
    txtcolInvoiceNumber: {
      name: "txtcolInvoiceNumber",
      type: "TextBoxWidget",
      colName: "colInvoiceNumber",
      parent: "gridSpecialPaymentEntry",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInvoiceNumber_PROPERTIES

      // END_USER_CODE-USER_txtcolInvoiceNumber_PROPERTIES
    },
    txtcolItem: {
      name: "txtcolItem",
      type: "TextBoxWidget",
      colName: "colItem",
      parent: "gridSpecialPaymentEntry",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolItem_PROPERTIES

      // END_USER_CODE-USER_txtcolItem_PROPERTIES
    },
    txtcolItemCode: {
      name: "txtcolItemCode",
      type: "TextBoxWidget",
      colName: "colItemCode",
      parent: "gridSpecialPaymentEntry",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolItemCode_PROPERTIES

      // END_USER_CODE-USER_txtcolItemCode_PROPERTIES
    },
    txtCollectAR: {
      name: "txtCollectAR",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "Collect AR:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtCollectAR_PROPERTIES

      // END_USER_CODE-USER_txtCollectAR_PROPERTIES
    },
    txtCollectCR: {
      name: "txtCollectCR",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "Collect CR:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtCollectCR_PROPERTIES

      // END_USER_CODE-USER_txtCollectCR_PROPERTIES
    },
    txtCollectSR: {
      name: "txtCollectSR",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "Collect SR:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtCollectSR_PROPERTIES

      // END_USER_CODE-USER_txtCollectSR_PROPERTIES
    },
    txtcolOleicInd: {
      name: "txtcolOleicInd",
      type: "TextBoxWidget",
      colName: "colOleicInd",
      parent: "gridSpecialPaymentEntry",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOleicInd_PROPERTIES

      // END_USER_CODE-USER_txtcolOleicInd_PROPERTIES
    },
    txtcolPayAmount: {
      name: "txtcolPayAmount",
      type: "TextBoxWidget",
      colName: "colPayAmount",
      parent: "gridSpecialPaymentEntry",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPayAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolPayAmount_PROPERTIES
    },
    txtcolPeanutType: {
      name: "txtcolPeanutType",
      type: "TextBoxWidget",
      colName: "colPeanutType",
      parent: "gridSpecialPaymentEntry",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanutType_PROPERTIES
    },
    txtcolTransactionNumber: {
      name: "txtcolTransactionNumber",
      type: "TextBoxWidget",
      colName: "colTransaction",
      parent: "gridSpecialPaymentEntry",
      CharWidth: "48",
      Height: "",
      Width: "",
      Label: "Transaction Number",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible: false,
      Disable: true,
      // START_USER_CODE-USER_txtcolPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanutType_PROPERTIES
    },
    txtcolSeedGeneration: {
      name: "txtcolSeedGeneration",
      type: "TextBoxWidget",
      colName: "colSeedGeneration",
      parent: "gridSpecialPaymentEntry",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeedGeneration_PROPERTIES

      // END_USER_CODE-USER_txtcolSeedGeneration_PROPERTIES
    },
    txtComment: {
      name: "txtComment",
      type: "TextBoxWidget",
      parent: "grpbxSpcPayableProfile2",
      Label: "Comment",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtComment_PROPERTIES

      // END_USER_CODE-USER_txtComment_PROPERTIES
    },
    txtDate: {
      name: "txtDate",
      type: "DateWidget",
      parent: "grpbxSpcPayableProfile2",
      Label: "Date",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtEnd_PROPERTIES
      // END_USER_CODE-USER_dtEnd_PROPERTIES
    },
    txtEndCheckNum: {
      name: "txtEndCheckNum",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      HasLabel: false,
      Visible: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEndCheckNum_PROPERTIES

      // END_USER_CODE-USER_txtEndCheckNum_PROPERTIES
    },
    txtInvoiceNumber: {
      name: "txtInvoiceNumber",
      type: "TextBoxWidget",
      parent: "grpbxSpcPayableProfile2",
      Label: "Invoice Number",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInvoiceNumber_PROPERTIES

      // END_USER_CODE-USER_txtInvoiceNumber_PROPERTIES
    },
    txtPayAmountprof: {
      name: "txtPayAmountprof",
      type: "TextBoxWidget",
      parent: "grpbxSpcPayableProfile2",
      Label: "Pay Amount",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 12 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayAmountprof_PROPERTIES

      // END_USER_CODE-USER_txtPayAmountprof_PROPERTIES
    },
    txtRemarks: {
      name: "txtRemarks",
      type: "TextAreaWidget",
      parent: "grpbxremarks",
      Label: "Remarks:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemarks_PROPERTIES

      // END_USER_CODE-USER_txtRemarks_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxSpcPayableProfile1",
      Label: "Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtMemo: {
      name: "txtMemo",
      type: "TextBoxWidget",
      parent: "grpbxSpcPayableProfile1",
      Label: "Memo:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtMemo_PROPERTIES

      // END_USER_CODE-USER_txtMemo_PROPERTIES
    },
    grpbxSpcPayableProfile1: {
      name: "grpbxSpcPayableProfile1",
      type: "GroupBoxWidget",
      parent: "SpecialPayablesProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxSpcPayableProfile1_PROPERTIES

      // END_USER_CODE-USER_grpbxSpcPayableProfile1_PROPERTIES
    },
    grpbxSpcPayableProfile2: {
      name: "grpbxSpcPayableProfile2",
      type: "GroupBoxWidget",
      parent: "SpecialPayablesProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "9",
      HasLabel: false,
      Cols: "9",
      ColsForTabPotrait: "9",
      ColsForLargeDesktop: "9",
      // START_USER_CODE-USER_grpbxSpcPayableProfile2_PROPERTIES

      // END_USER_CODE-USER_grpbxSpcPayableProfile2_PROPERTIES
    },
    gridSpecialPaymentEntry: {
      name: "gridSpecialPaymentEntry",
      type: "GridWidget",
      parent: "SpecialPayablesProfile",
      gridCellsOrder:
        "txtcolTransactionNumber,txtcolItem,txtcolItemCode,txtcolDate,txtcolPeanutType,txtcolSeedGeneration,txtcolOleicInd,txtcolPayAmount,txtcolChargeLocation,txtcolInvoiceNumber,txtcolComments",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridSpecialPaymentEntry_PROPERTIES

      // END_USER_CODE-USER_gridSpecialPaymentEntry_PROPERTIES
    },
    grpbxremarks: {
      name: "grpbxremarks",
      type: "GroupBoxWidget",
      parent: "SpecialPayablesProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxremarks_PROPERTIES

      // END_USER_CODE-USER_grpbxremarks_PROPERTIES
    },
    grpbxCheckInfo: {
      name: "grpbxCheckInfo",
      type: "GroupBoxWidget",
      parent: "SpecialPayablesProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCheckInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxCheckInfo_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "SpecialPayablesProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#VendorLookup": {},
        "ContractManagement#MainMenu": {},
        "AccountPayment#SpecialPayablesScan": {},
        "AccountPayment#Payment#SpecialPayDocsView": {},

      },
      REVERSE: {
        "ContractManagement#VendorLookup": {},
        "ContractManagement#MainMenu": {},
        "AccountPayment#SpecialPayablesScan": {},
        "AccountPayment#Payment#SpecialPayDocsView": {},
      },
    },
  };
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  let cropYear = cropYearFromLS();
  let userid = useridFromLS();
  let chknumber = '';
  let _buttonServices = {};
  let _buttonNavigation = {
    btnClose: {
      DEFAULT: ["ContractManagement#SpecialPayablesInquiry#DEFAULT#false#Click"],
    },
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [userBuyPtId, setcBuyptId] = useState('');
  const [ReceivableNumber, setReceivableNumber] = useState('');
  const [buying_point, setBuyptId] = useState('');
  const [cboSpoolLoc, setSpoolLocation] = useState('');
  const [mintMemo, setmintMemo] = useState('');
  const [remitId, setremit] = useState("");
  const [remitName, setname] = useState("");
  const [remitCity, setcity] = useState("");
  const [loading, setLoading] = useState(true);
  const [remitState, setstate] = useState("");
  let bCheck = false;
  var acct_vendor = '';
  let CheckNumber = 0;
  let mintMemoNum = 0;
  let receivablenumber = 0;
  let receivedNumber = 0;
  let lblStatusDetail = '';
  let statusbar = '';
  let VendorNumber = '';
  let strInvoiceNum = '';
  let rePrint = '';
  let ARBalance = 0;
  let SRBalance = 0;
  let CRBalance = 0;
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    _kaledo.thisObj = thisObj
    parentWindow(thisObj);
    let data = getData(thisObj, "refresh")
    if (data == true) {
      PopulateGrid();
    }
  });

  useEffect(() =>{
    return () => {
      rePrint = getData(thisObj, "closingOfReportPreviewCheck");
      if (rePrint == true) {
        let message = confirm("Would you like enter another special check?");
        if (message == true) {
          ClearTextBoxes();
          ClearControls();
          ResetMemoHeader();
          ClearTexts();
          GetNextCheckNum();
          enable(thisObj, "ddSpoolLocationProf", "");
          enable(thisObj, "ddPayeeProf", "");
          enable(thisObj, "txtVendor");
          enable(thisObj, "btnVendor");
          disable(thisObj, "txtCollectAR");
          disable(thisObj, "txtCollectSR");
          disable(thisObj, "txtCollectCR");
          setValue(thisObj, "txtARBalance", "") // balance values needs to reset to empty when the process is completed
          setValue(thisObj, "txtCRBalance", "") // balance values needs to reset to empty when the process is completed
          setValue(thisObj, "txtSRBalance", "") // balance values needs to reset to empty when the process is completed
          setValue(thisObj, "txtRemarks", "")   // remarks needs to reset to empty when the process is completed
          setValue(thisObj, 'txtDate', "")      // date needs to reset to empty when the process is completed
          setValue(thisObj, 'txtCollectAR', "0.00") // collect values needs to reset to empty when the process is completed
          setValue(thisObj, 'txtCollectCR', "0.00") // collect values needs to reset to empty when the process is completed
          setValue(thisObj, 'txtCollectSR', "0.00") // collect values needs to reset to empty when the process is completed
        }
        else {
          goTo(thisObj, "AccountPayment#SpecialPayablesInquiry#DEFAULT#false#Click");
        }
        setData(thisObj, "closingOfReportPreviewCheck", false);
      }
    }
  },[thisObj]);

  // Start Method for load the Vendor details  
  useEffect(() => {
    // to Reprint function reset the controls to add new records to reprint    
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if ((vendor_lookup !== null) && (vendor_lookup !== "") && (vendor_lookup !== undefined)) {
      thisObj.setFieldValue("txtVendor", vendor_lookup.VendorNumber);
      document.getElementsByClassName("txtVndrName")[0].style.visibility = "visible";
      thisObj.setFieldValue("txtVndrName", vendor_lookup.key);
      acct_vendor = getData(thisObj, txtVendor);
      bFillRemitToList(vendor_lookup.VendorNumber);
      DisplayValues();
    }
    else {
      document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
    }
    setData(thisObj, 'vendorDetails', null)
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    FormLoad();
    setValue(thisObj, "lblCropYear2", cropYear);
    setValue(thisObj, "ddItemCodeprof", "");
    setValue(thisObj, "ddPeanutTypeprof", "");
    setValue(thisObj, "ddSeedGenerationprof", "");
    setValue(thisObj, "ddOleic", "");
    setValue(thisObj, "ddChargeLocationprof", "");
    let caption = getData(thisObj, 'SpecialPayablesProfileData');
    if (caption !== null) {
      if (caption.Status == 'RTP-P' && caption.cmdOpen == false) {
        ClearChecks();
        setValue(thisObj, "ddSpoolLocationProf", "");
        setValue(thisObj, "ddPayeeProf", "");
        setValue(thisObj, "txtVendor", "");
        setValue(thisObj, "txtVndrName", "");
        setData(thisObj, "gridSpecialPaymentEntry", "");
        disable(thisObj, "txtCollectAR");
        disable(thisObj, "txtCollectSR");
        disable(thisObj, "txtCollectCR");
      }
      else {
        disable(thisObj, "ddSpoolLocationProf");
        disable(thisObj, "ddPayeeProf");
        disable(thisObj, "btnVendor");
      }
      if(['RTP-B', 'PAY-S', 'PAY-V', 'PAY-I'].includes(caption.Status)){ // added to mark remaks fiels as not editable for batch Pay, Void and Paid
        disable(thisObj, 'txtRemarks')
      }
    }
    hide(thisObj, "lblEdite");
    hide(thisObj, "lblAdd");
  }, []);


  const FormLoad = async() => {
    setValue(thisObj, 'txtMemo', "");
    setData(thisObj, 'txtMemo', "");
    document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
    await ddSpoolLocationProf();
    bFillPeanutType();
    bFillSeedGeneration();
    bFillOleicInd();
    ddChargeLocationprof();
    PremiumCodeListDetails();
    GetValues();
    DisplayValues();
    CheckSecurity();
    setLoading(false)
  }

  // Start method to clear the controls
  const ClearControls = async () => {
    try {
      let vendor = thisObj.values.txtVendor;
      setValue(thisObj, "ddSpoolLocationProf", "");
      setValue(thisObj, "txtVendor", "");
      setValue(thisObj, "ddPayeeProf", "");
      setValue(thisObj, "txtValueOfCheck", "0.00");
      setValue(thisObj, "txtValOfRTP", "0.00");
      setValue(thisObj, "txtCollectAR", "0.00");
      setValue(thisObj, "txtCollectCR", "0.00");
      setValue(thisObj, "txtCollectSR", "0.00");
      setValue(thisObj, "txtVndrName", "");
      if (vendor == undefined || vendor == null || vendor == "") {
        document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
      }
      else {
        document.getElementsByClassName("txtVndrName")[0].style.visibility = "visible";
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ClearControls event:Click");
      }
      return false;
    }
    return true;
  }
  // End method to clear the controls

  // Start method to get the data at the time of formload
  const GetValues = async () => {
    try {
      let caption = getData(thisObj, 'SpecialPayablesProfileData');
      if (caption !== null) {
        if (caption.Status == 'RTP-P' && caption.cmdOpen == false) {
          ClearTexts();
          GetCheckDetails();
          DisableControl();
          disable(thisObj, "btnEdit");
          disable(thisObj, "btnDelete");
          statusbar = caption.Status;
          if (statusbar == 'RTP-P') {
            setValue(thisObj, 'lblPending', "Pending");
          }
          CheckNumber = caption.lblChkNum;
          receivedNumber = caption.lblRecvNum;
        }
        else {
          if (caption.cmdOpen == true) {
            VendorNumber = caption.txtVendor;
            setValue(thisObj, "ddSpoolLocationProf", caption.ByPtID);
            setValue(thisObj, "txtVendor", caption.txtVendor);
            setValue(thisObj, "ddPayeeProf", caption.lblRemitTo);
            setValue(thisObj, "txtMemo", caption.lblMemo);
            disable(thisObj, "ddSpoolLocationProf");
            disable(thisObj, "txtVendor");
            disable(thisObj, "ddPayeeProf");
            statusbar = caption.Status;
            // bind the status of the checks
            setValue(thisObj, 'lblPending', caption.lblStatusDetail);
            CheckNumber = caption.lblCheckNum;
            receivedNumber = caption.lblRecvNum;
            lblStatusDetail = caption.lblStatusDetail;
            DisableControl();
            // Binding the account details
            GetAccountDetails(caption.ByPtID, caption.txtVendor);
            // loading the data into gridview on click of open button in inquiry page
            PopulateGrid();
            // on click of open button from inquiry page binding bFillRemitToList on change of vendor number
            bFillRemitToList(VendorNumber);
            // binding label based on vendor number called in vendor change event
            setVendorNameBasedOnVendorNumber(VendorNumber);
            // binding Item code based on the spoollocation
            onddSpoolLocationProfChange();
            if ((statusbar == "RTP-P") || (statusbar == "RTP-H") || (statusbar == "RTP-R")) {
              // Nothing yo do.. Should not Hide controls
            }else {
              document.getElementsByClassName("btnCreateHoldPay")[0].style.visibility = "hidden";
              document.getElementsByClassName("btnPrintChecks")[0].style.visibility = "hidden";
              document.getElementsByClassName("btnSaveToBatch")[0].style.visibility = "hidden";
              document.getElementsByClassName("ddItemCodeprof")[0].style.visibility = "hidden";
              document.getElementsByClassName("txtDate")[0].style.visibility = "hidden";
              document.getElementsByClassName("ddPeanutTypeprof")[0].style.visibility = "hidden";
              document.getElementsByClassName("ddSeedGenerationprof")[0].style.visibility = "hidden";
              document.getElementsByClassName("ddOleic")[0].style.visibility = "hidden";
              document.getElementsByClassName("txtPayAmountprof")[0].style.visibility = "hidden";
              document.getElementsByClassName("ddChargeLocationprof")[0].style.visibility = "hidden";
              document.getElementsByClassName("txtInvoiceNumber")[0].style.visibility = "hidden";
              document.getElementsByClassName("txtComment")[0].style.visibility = "hidden";
              document.getElementsByClassName("btnAdd")[0].style.visibility = "hidden";
              document.getElementsByClassName("btnEdit")[0].style.visibility = "hidden";
              document.getElementsByClassName("btnDelete")[0].style.visibility = "hidden";
              disable(thisObj, "txtRemarks"); 
            }
          }
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:GetValues event:Click");
      }
      return false;
    }
    return true;
  }
  // End method to get the data at the time of formload

  // Start method to load the Gridview
  const PopulateGrid = async () => {
    try {
      let arBal = 0;
      let crBal = 0;
      let srBal = 0;
      let caption = getData(thisObj, 'SpecialPayablesProfileData');
      if (caption !== null) {
        if (caption.Status == 'RTP-P' && caption.cmdOpen == false) {
          let strBuyPtId = thisObj.values.ddSpoolLocationProf;
          let vendorId = thisObj.values.txtVendor;
          mintMemoNum = thisObj.values.txtMemo;
          let mstrPrevStatus = caption.Status;
          let checkNum = caption.lblCheckNum;
          if (checkNum == null) {
            checkNum = 0;
          }
          else {
            checkNum = caption.lblCheckNum;
          }
          let RecNum = caption.lblRecvNum;
          if (RecNum == null) {
            RecNum = 0;
          }
          else {
            RecNum = caption.lblRecvNum;
          }
          await AccountPayment.RetrieveSpecialPayableList(strBuyPtId, vendorId, mintMemoNum, mstrPrevStatus, checkNum, RecNum).then(response => {
            let data = response;
            let js = [];
            let obj = {};
            for (let i = 0; i < data.length; i++) {
              obj.txtcolTransactionNumber = data[i].transaction_num;
              arBal = data[i].ar_adjustment; //setValue(thisObj, "txtCollectAR", data[i].ar_adjustment);
              crBal = data[i].cr_adjustment; //setValue(thisObj, "txtCollectCR", data[i].cr_adjustment);
              srBal = data[i].sr_adjustment; //setValue(thisObj, "txtCollectSR", data[i].sr_adjustment);
              let count = i + 1;
              obj.txtcolItem = ("0".repeat(3 - count.toString().length)).toString() + count.toString();
              obj.txtcolItemCode = data[i].special_type;
              obj.txtcolDate = ConvertToDate(data[i].special_date);
              obj.txtcolPeanutType = data[i].pnut_type_name;
              switch (data[i].seed_gen) {
                case "F":
                  obj.txtcolSeedGeneration = 'Foundation';
                  break;
                case "R":
                  obj.txtcolSeedGeneration = 'Registered';
                  break;
                case "C":
                  obj.txtcolSeedGeneration = 'Certified';
                  break;
              }
              switch (data[i].oleic_ind) {
                case "H":
                  obj.txtcolOleicInd = 'High';
                  break;
                case "M":
                  obj.txtcolOleicInd = 'Mid'; // from Medium to Mid to display in grid
                  break;
              }
              obj.txtcolPayAmount = data[i].special_proceeds;
              obj.txtcolChargeLocation = data[i].charge_buy_id;
              obj.txtcolInvoiceNumber = data[i].invoice_num;
              obj.txtcolComments = data[i].audit_reason;
              js.push(obj);
              obj = {};
            }
            setValue(thisObj, "txtCollectAR", arBal);
            setValue(thisObj, "txtCollectCR", crBal);
            setValue(thisObj, "txtCollectSR", srBal);
            setValue(thisObj, "gridSpecialPaymentEntry", js);
            setData(thisObj, "gridSpecialPaymentEntry", js);
            DisplayValues();
          });
        }
        else {
          if (caption.cmdOpen == true) {
            let strBuyPtId = caption.ByPtID;
            let vendorId = caption.txtVendor;
            mintMemoNum = caption.lblMemo;
            let mstrPrevStatus = caption.Status;
            let checkNum = caption.lblCheckNum;
            if (checkNum == null) {
              checkNum = 0;
            }
            else {
              checkNum = caption.lblCheckNum;
            }
            let RecNum = caption.lblRecvNum;
            if (RecNum == null) {
              RecNum = 0;
            }
            else {
              RecNum = caption.lblRecvNum;
            }
            await AccountPayment.RetrieveSpecialPayableList(strBuyPtId, vendorId, mintMemoNum, mstrPrevStatus, checkNum, RecNum).then(response => {
              let data = response;
              let js = [];
              for (let i = 0; i < data.length; i++) {
                let obj = {};
                obj.txtcolTransactionNumber = data[i].transaction_num;
                arBal = data[i].ar_adjustment; //setValue(thisObj, "txtCollectAR", data[i].ar_adjustment);
                crBal = data[i].cr_adjustment; //setValue(thisObj, "txtCollectCR", data[i].cr_adjustment);
                srBal = data[i].sr_adjustment; //setValue(thisObj, "txtCollectSR", data[i].sr_adjustment);
                let count = i + 1;
                obj.txtcolItem = ("0".repeat(3 - count.toString().length)).toString() + count.toString();
                obj.txtcolItemCode = data[i].special_type;
                obj.txtcolDate = ConvertToDate(data[i].special_date);
                obj.txtcolPeanutType = data[i].pnut_type_name;
                switch (data[i].seed_gen) {
                  case "F":
                    obj.txtcolSeedGeneration = 'Foundation';
                    break;
                  case "R":
                    obj.txtcolSeedGeneration = 'Registered';
                    break;
                  case "C":
                    obj.txtcolSeedGeneration = 'Certified';
                    break;
                }
                switch (data[i].oleic_ind) {
                  case "H":
                    obj.txtcolOleicInd = 'High';
                    break;
                  case "M":
                    obj.txtcolOleicInd = 'Mid'; // from Medium to Mid to display in grid
                    break;
                }
                obj.txtcolPayAmount = data[i].special_proceeds;
                obj.txtcolChargeLocation = data[i].charge_buy_id;
                obj.txtcolInvoiceNumber = data[i].invoice_num;
                obj.txtcolComments = data[i].audit_reason;
                js.push(obj);
              }
              setValue(thisObj, "txtCollectAR", arBal);
              setValue(thisObj, "txtCollectCR", crBal);
              setValue(thisObj, "txtCollectSR", srBal);
              setValue(thisObj, "gridSpecialPaymentEntry", js);
              setData(thisObj, "gridSpecialPaymentEntry", js);
              DisplayValues();
            });
          }
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:PopulateGrid event:Click");
      }
      return false;
    }
    return true;
  }
  // End method to load the Gridview

  // Start method to reset the GridView
  const ResetMemoHeader = async () => {
    let strBuyPtId = thisObj.values.ddSpoolLocationProf;
    let vendorId = thisObj.values.txtVendor;
    mintMemoNum = thisObj.values.txtMemo;
    let mstrPrevStatus = "";
    let checkNum = 0;
    let RecNum = 0;
    let arBal = 0;
    let crBal = 0;
    let srBal = 0;
    await AccountPayment.RetrieveSpecialPayableList(strBuyPtId, vendorId, mintMemoNum, mstrPrevStatus, checkNum, RecNum).then(response => {
      let data = response;
      let js = [];
      let obj = {};
      for (let i = 0; i < data.length; i++) {
        obj.txtcolTransactionNumber = data[i].transaction_num;
        arBal = data[i].ar_adjustment //setValue(thisObj, "txtCollectAR", data[i].ar_adjustment);
        crBal = data[i].cr_adjustment //setValue(thisObj, "txtCollectCR", data[i].cr_adjustment);
        srBal = data[i].sr_adjustment //setValue(thisObj, "txtCollectSR", data[i].sr_adjustment);
        let count = i + 1;
        obj.txtcolItem = ("0".repeat(3 - count.toString().length)).toString() + count.toString();
        obj.txtcolItemCode = data[i].special_type;
        obj.txtcolDate = ConvertToDate(data[i].special_date);
        obj.txtcolPeanutType = data[i].pnut_type_name;
        obj.txtcolSeedGeneration = data[i].seed_gen;
        obj.txtcolOleicInd = data[i].oleic_ind;
        obj.txtcolPayAmount = data[i].special_proceeds;
        obj.txtcolChargeLocation = data[i].charge_buy_id;
        obj.txtcolInvoiceNumber = data[i].invoice_num;
        obj.txtcolComments = data[i].audit_reason;
        js.push(obj);
        obj = {};
      }
      setValue(thisObj, "txtCollectAR", arBal);
      setValue(thisObj, "txtCollectCR", crBal);
      setValue(thisObj, "txtCollectSR", srBal);
      setValue(thisObj, "gridSpecialPaymentEntry", js);
      setData(thisObj, "gridSpecialPaymentEntry", js);
      GetCheckDetails();
      SetMemoNum();
    });

  }
  // End method to reset the GridView

  // Start method of change event for the textboxes
  let mstrStatus = '';

  const ontxtMemoChange = async () => {
    mintMemoNum = thisObj.values.txtMemo;
  }
  thisObj.ontxtMemoChange = ontxtMemoChange

  const ontxtPayAmountprofBlur = () => {
    setValue(thisObj, "txtPayAmountprof", Number(txtPayAmountprof.value).toFixed(2))
  }
  thisObj.ontxtPayAmountprofBlur = ontxtPayAmountprofBlur

  const ontxtPayAmountprofChange = () => {
    setValue(thisObj, "txtPayAmountprof", NumericValueValidataion(txtPayAmountprof.value.toString()))
  };
  thisObj.ontxtPayAmountprofChange = ontxtPayAmountprofChange;

  const SetMemoNum = () => {
    mintMemoNum = 0;
  }

  function txtValueOfCheck() {
    try {
      if (thisObj.values.txtValueOfCheck > 0) {
        thisObj.values.txtCheck = chknumber;
      }
      else {
        thisObj.values.txtCheck = "S000" + (ReceivableNumber);
        setValue(thisObj, "txtCheck", thisObj.values.txtCheck);
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ontxtValueOfCheckChange event:Click");
      }
      return false;
    }
    return true;
  }
  //Start method for change event for txtValueOfCheck 
  const ontxtValueOfCheckChange = async () => {
    try {
      if (thisObj.values.txtValueOfCheck > 0) {
        thisObj.values.txtCheck = chknumber;
      }
      else {
        thisObj.values.txtCheck = "S000" + (ReceivableNumber);
        setValue(thisObj, "txtCheck", thisObj.values.txtCheck);
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ontxtValueOfCheckChange event:Click");
      }
      return false;
    }
    return true;
  }
  thisObj.ontxtValueOfCheckChange = ontxtValueOfCheckChange
  //End method for change event for txtValueOfCheck 

  const ontxtCollectARBlur = () =>{
    if(getValue(thisObj, 'txtCollectAR') == undefined || getValue(thisObj, 'txtCollectAR') == null || getValue(thisObj, 'txtCollectAR') == ''){
      setValue(thisObj, 'txtCollectAR', '0.00')
    }
  }
  thisObj.ontxtCollectARBlur = ontxtCollectARBlur;

  const ontxtCollectARChange = async () => {
    try {
      if (!isEnabled(thisObj, 'txtCollectAR')) {
        return
      }
      if (Number(getValue(thisObj, 'txtCollectAR')) > Number(getValue(thisObj, 'txtARBalance'))) {
        thisObj.values.txtCollectAR = Number(getData(thisObj, 'txtARBalance'));
      }
      checkAgainstValueOfCheck(getValue(thisObj, 'txtCollectAR'))
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ontxtCollectARChange event:Click");
      }
      return false;
    }
    return true;
  }
  thisObj.ontxtCollectARChange = ontxtCollectARChange

  const ontxtCollectCRBlur = () =>{
    if(getValue(thisObj, 'txtCollectCR') == undefined || getValue(thisObj, 'txtCollectCR') == null || getValue(thisObj, 'txtCollectCR') == ''){
      setValue(thisObj, 'txtCollectCR', '0.00')
    }
  }
  thisObj.ontxtCollectCRBlur = ontxtCollectCRBlur;

  const ontxtCollectCRChange = async () => {
    try {
      if (!isEnabled(thisObj, 'txtCollectCR')) {
        return;
      }
      if (Number(getValue(thisObj, 'txtCollectCR')) > Number(getValue(thisObj, 'txtCRBalance'))) {
        thisObj.values.txtCollectCR = Number(getValue(thisObj,'txtCRBalance'));
      }
      checkAgainstValueOfCheck(getValue(thisObj, 'txtCollectCR'))
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ontxtCollectCRChange event:Click");
      }
      return false;
    }
    return true;
  }
  thisObj.ontxtCollectCRChange = ontxtCollectCRChange

  const ontxtCollectSRBlur = () =>{
    if(getValue(thisObj, 'txtCollectSR') == undefined || getValue(thisObj, 'txtCollectSR') == null || getValue(thisObj, 'txtCollectSR') == ''){
      setValue(thisObj, 'txtCollectSR', '0.00')
    }
  }
  thisObj.ontxtCollectSRBlur = ontxtCollectSRBlur;

  const ontxtCollectSRChange = async () => {
    try {
      if (!isEnabled(thisObj, 'txtCollectSR')) {
        return
      }
      if (Number(getValue(thisObj, 'txtCollectSR')) > Number(getValue(thisObj, 'txtSRBalance'))) {
        thisObj.values.txtCollectSR = Number(getValue(thisObj, 'txtSRBalance'));
      }
      checkAgainstValueOfCheck(getValue(thisObj, 'txtCollectSR'))
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ontxtCollectSRChange event:Click");
      }
      return false;
    }
    return true;
  }
  thisObj.ontxtCollectSRChange = ontxtCollectSRChange
  // End method of change event for the textboxes

  //Start method for vendor textbox change event to bind the vendor name
//PRB0054498 - Searching the vendors with vendor number from vendor textbox and convert to uppercase  
    
    const ontxtVendorChange = async () => {
    try {
      let VendorNumber = thisObj.values.txtVendor;
      VendorNumber = VendorNumber.toUpperCase()
      if (VendorNumber.length == 6) {
        setVendorNameBasedOnVendorNumber(VendorNumber);
        bFillRemitToList(VendorNumber);
        DisplayValues();
      } 
      else {
        document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
      }
  
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:ontxtVendorChange event:Change"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtVendorChange = ontxtVendorChange;  
  //End method for vendor textbox change event to bind the vendor name

  //Start method to bind the vendor name and called vendor textbox change event
  const setVendorNameBasedOnVendorNumber = async (VendorNumber) => {
    try {
      let venname = null
      let vendornamevisible = false
      // changes the API call : for vendors for which data was not available, response was in Pending state, making Page Unresponsive.
      let response = await ContractManagementService.RetrieveVendorByNumber(VendorNumber)
      if (response !== null && response !== undefined) {
        if (response.length == 0) {
          return;
        }
        else {
          venname = response[0].name
          vendornamevisible = true
        }
      }
      if (vendornamevisible == true) {
        document.getElementsByClassName("txtVndrName")[0].style.visibility = "visible";
        setValue(thisObj, "txtVndrName", venname)
      }
      else {
        document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
      }
      // await ContractManagementService.RetrieveVendorByNumber(VendorNumber).then(response => {
      //   let data = response
      //   if (data !== null && data !== undefined) {
      //     if (data.length == 0) {
      //       return;
      //     }
      //     else {
      //       venname = data[0].name
      //       vendornamevisible = true
      //     }
      //   }
      //   if (vendornamevisible == true) {
      //     document.getElementsByClassName("txtVndrName")[0].style.visibility = "visible";
      //     setValue(thisObj, "txtVndrName", venname)
      //   }
      //   else {
      //     document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
      //   }
      // });
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:setVendorNameBasedOnVendorNumber event:Click");
      }
      return false;
    }
    return true;
  }
  //End method to bind the vendor name and called vendor textbox change event


  // Start Method called in ResetMemoHeader to get check details  
  const GetCheckDetails = async () => {
    try {
      ContractManagementService.RetrieveUserControlDetails(userid).then(response => {
        setcBuyptId(response[0].buyPtId);
        let mstrcbuy_pt_id = response[0].buyPtId;
        ContractManagementService.RetrieveBuyingPointControlDetails(null, null, null, null, mstrcbuy_pt_id).then(response => {
          let collectionData = response;
          for (let i = 0; i < collectionData.length; i++) {
            thisObj.values.txtEndCheckNum = collectionData[i].end_check;
            thisObj.values.chknumber = collectionData[i].next_check;
            setReceivableNumber(collectionData[i].new_receivable_no);
            receivablenumber = collectionData[i].new_receivable_no;
            thisObj.values.txtCheck = collectionData[i].next_check;
            setValue(thisObj, "txtMemo", collectionData[i].new_memo_no);
          }
        });
      });
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:GetCheckDetails event:Click"
        );
      }
      return false;
    }
    return true;
  }
  // End Method called in ResetMemoHeader to get check details  

  // Start method to reset the Check
  const ClearChecks = async () => {
    try {
      setValue(thisObj, "txtCollectSR", "0.00");
      setValue(thisObj, "txtCollectAR", "0.00");
      setValue(thisObj, "txtCollectCR", "0.00");
      setValue(thisObj, "txtCRBalance", "");
      setValue(thisObj, "txtARBalance", "")
      setValue(thisObj, "txtSRBalance", "");
      setValue(thisObj, "txtValOfRTP", "0.00");
      setValue(thisObj, "txtValueOfCheck", "0.00");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ClearChecks event:Click");
      }
      return false;
    }
    return true;
  }
  // End method to reset the Check

  // Start method to reset the controls
  const ClearTexts = async () => {
    try {
      let dblPayAmount = 0;
      let data = getData(thisObj, "gridSpecialPaymentEntry");
      if (data !== undefined && data !== null) {
        setValue(thisObj, "txtCollectSR", "0.00");
        setValue(thisObj, "txtCollectAR", "0.00");
        // setValue(thisObj, "txtCRBalance", "");
        // setValue(thisObj, "txtARBalance", "")
        // setValue(thisObj, "txtSRBalance", "");
        // setValue(thisObj, "txtValOfRTP", "0.00");
        // setValue(thisObj, "txtValueOfCheck", "0.00");
        // setValue(thisObj, "ddPeanutTypeprof", "");
        setValue(thisObj, "txtCollectCR", "0.00");
        // setValue(thisObj, "ddItemCodeprof", "");
        // setValue(thisObj, "ddSeedGenerationprof", "");
        // setValue(thisObj, "ddOleic", "");
        // setValue(thisObj, "ddChargeLocationprof", "");
        // setValue(thisObj, "ddPayeeProf", '');
      }
      else {
        setValue(thisObj, "txtCollectSR", "0.00");
        setValue(thisObj, "txtCollectAR", "0.00");
        setValue(thisObj, "txtCollectCR", "0.00");
        // setValue(thisObj, "txtCRBalance", "");
        // setValue(thisObj, "txtARBalance", "")
        // setValue(thisObj, "txtSRBalance", "");
        // setValue(thisObj, "txtValOfRTP", "0.00");
        // setValue(thisObj, "txtValueOfCheck", "0.00");
        // setValue(thisObj, "ddPeanutTypeprof", "");
        // setValue(thisObj, "ddItemCodeprof", "");
        // setValue(thisObj, "ddSeedGenerationprof", "");
        // setValue(thisObj, "ddOleic", "");
        // setValue(thisObj, "ddChargeLocationprof", "");
        // setValue(thisObj, "ddPayeeProf", '');
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ClearTexts event:Click");
      }
      return false;
    }
    return true;
  }
  // End method to reset the controls

  // Start method to display the values
  const DisplayValues = async () => {
    try {
      let collectAr = getValue(thisObj, 'txtCollectAR');
      let collectCr = getValue(thisObj, 'txtCollectCR');
      let collectSr = getValue(thisObj, 'txtCollectSR');
      let dblPayAmount = 0;
      let valueOfRTP = thisObj.values.txtValOfRTP;
      let valueOfCheck = thisObj.values.txtValueOfCheck;
      if (collectAr !== undefined && collectAr !== null && collectAr !== "" && collectAr !== "0.00") {
        if (collectAr === collectAr && collectAr % 1 === 0) {
          setValue(thisObj, "txtCollectAR", Number(collectAr).toFixed(2))//collectAr + ".00");
        }
        else {
          setValue(thisObj, "txtCollectAR", Number(collectAr).toFixed(2));
        }
      }
      else {
        setValue(thisObj, "txtCollectAR", "0.00");
      }

      if (collectSr !== undefined && collectSr !== null && collectSr !== "" && collectSr !== "0.00") {
        if (collectSr === collectSr && collectSr % 1 === 0) {
          setValue(thisObj, "txtCollectSR", Number(collectSr).toFixed(2)) //collectSr + ".00");
        }
        else {
          setValue(thisObj, "txtCollectSR", Number(collectSr).toFixed(2));
        }
      }
      else {
        setValue(thisObj, "txtCollectSR", "0.00");
      }

      if (collectCr !== undefined && collectCr !== null && collectCr !== "" && collectCr !== "0.00") {
        if (collectCr === collectCr && collectCr % 1 === 0) {
          setValue(thisObj, "txtCollectCR", Number(collectCr).toFixed(2))//collectCr + ".00");
        }
        else {
          setValue(thisObj, "txtCollectCR", Number(collectCr).toFixed(2));
        }
      }
      else {
        setValue(thisObj, "txtCollectCR", "0.00");
      }

      let data = getData(thisObj, "gridSpecialPaymentEntry");
      if (data !== undefined && data !== null) {
        for (let i = 0; i < data.length; i++) {
          dblPayAmount = Number(dblPayAmount) + Number(data[i].txtcolPayAmount);
          if (dblPayAmount === dblPayAmount && dblPayAmount % 1 === 0) {
            valueOfRTP = Number(dblPayAmount);
            //setValue(thisObj, "txtValueOfCheck", Number(dblPayAmount).toFixed(2)) // + ".00");
            //setValue(thisObj, "txtValOfRTP", Number(dblPayAmount).toFixed(2)) // + ".00");
          }
          else {
            valueOfRTP = dblPayAmount;
            //setValue(thisObj, "txtValueOfCheck", Number(dblPayAmount).toFixed(2));
            //setValue(thisObj, "txtValOfRTP", Number(dblPayAmount).toFixed(2));
          }
        }
        setValue(thisObj, "txtValueOfCheck", Number(dblPayAmount).toFixed(2)) // + ".00");
        setValue(thisObj, "txtValOfRTP", Number(dblPayAmount).toFixed(2))
      }

      if (dblPayAmount == 0) {
        setValue(thisObj, "txtValOfRTP", "0.00");
        setValue(thisObj, "txtValueOfCheck", "0.00");
      }
      txtValueOfCheck();
      Calc()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:DisplayValues event:Click");
      }
      return false;
    }
    return true;
  }
  // End method to display the values

  // Start method to get security checks
  const CheckSecurity = async () => {
    try {
      let access = "U";
      let lblAdd = "";
      let lblDelete = "";
      let buy_pt_id = thisObj.values.ddSpoolLocationProf;
      await ContractManagementService.RetrieveAccessPermissionDetails(buy_pt_id, 'PN1100', '005', access).then(response => {
        let data = response;
        for (let i = 0; i < data.length; i++) {
          lblAdd = data[i].permission = "Y";
          lblDelete = data[i].permission = "Y";
          if (lblAdd == "Y" && lblDelete == "Y") {
            enable(thisObj, "btnAdd");
          }
        }
      });
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:CheckSecurity event:Click");
      }
      return false;
    }
    return true;
  }
  // End method to get security checks

  // Start method to enable and disable the textbox
  const EnableDisableTextBoxes = async () => {
    try {
      await GetAccountDetails()
      let data = getData(thisObj, "gridSpecialPaymentEntry");
      let caption = getData(thisObj, 'SpecialPayablesProfileData');
      let rtpValue = 0;
      let arBalance = 0;
      let srBalance = 0;
      let crBalance = 0;
      if (data !== undefined && data !== null) {
        for (let i = 0; i < data.length; i++) {
          rtpValue = Number(rtpValue) + Number(data[i].txtcolPayAmount);
        }
      }

      if (rtpValue <= 0) {
        disable(thisObj, "txtCollectAR",true);
        disable(thisObj, "txtCollectSR",true);
        disable(thisObj, "txtCollectCR",true);
      }
      else {
        let caption = getData(thisObj, 'SpecialPayablesProfileData');
        if (caption !== null) {
          if (caption.Status == 'RTP-P' && caption.cmdOpen == false) {
            arBalance = getValue(thisObj, 'txtARBalance')
            srBalance = getValue(thisObj, 'txtSRBalance')
            crBalance = getValue(thisObj, 'txtCRBalance')
            if (arBalance > 0) {
              enable(thisObj, "txtCollectAR");
            }
            if (srBalance > 0) {
              enable(thisObj, "txtCollectSR");
            }
            if (crBalance > 0) {
              enable(thisObj, "txtCollectCR");
            }
          }
          else{
            arBalance = ARBalance; 
            srBalance = SRBalance; 
            crBalance = CRBalance; 
            if (arBalance > 0) {
              enable(thisObj, "txtCollectAR");
            }
            if (srBalance > 0) {
              enable(thisObj, "txtCollectSR");
            }
            if (crBalance > 0) {
              enable(thisObj, "txtCollectCR");
            }
          }
        }
        
      }
      statusbar = caption.Status;
      if ((statusbar == "RTP-B") || (statusbar == "PAY-I") || (statusbar == "PAY-V") || (statusbar == "PAY-S")){
        disable(thisObj, "txtCollectAR",true);
        disable(thisObj, "txtCollectSR",true);
        disable(thisObj, "txtCollectCR",true);
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:EnableDisableTextBoxes event:Click");
      }
      return false;
    }
    return true;
  }
  // End method to enable and disable the textbox

  // Start method for Edit the Grid View details
  const onbtnEditClick = async () => {
    try {
      setLoading(true)
      let lblEdit = document.getElementsByClassName('btnEdit')[0].childNodes[0].childNodes[0].innerText == "Edit";
      let data = thisObj.state.gridSpecialPaymentEntry.selected[0];
      if (lblEdit == true) {

        if (data !== undefined && data !== null && data !== "") {
          document.getElementsByClassName('btnEdit')[0].childNodes[0].childNodes[0].innerText = "Save";
          document.getElementsByClassName('btnDelete')[0].childNodes[0].childNodes[0].innerText = "Cancel";
          enable(thisObj, "btnAdd");
          enable(thisObj, "ddItemCodeprof");
          enable(thisObj, "txtDate");
          enable(thisObj, "ddPeanutTypeprof");
          enable(thisObj, "ddSeedGenerationprof");
          enable(thisObj, "ddOleic");
          enable(thisObj, "txtPayAmountprof");
          enable(thisObj, "ddChargeLocationprof");
          enable(thisObj, "txtInvoiceNumber");
          enable(thisObj, "txtComment");
          disable(thisObj, "btnCreateHoldPay");
          disable(thisObj, "btnSaveToBatch");
          disable(thisObj, "btnPrintChecks");
          show(thisObj, "lblEdite");
          hide(thisObj, "lblAdd");
          thisObj.values.txtcolTransactionNumber = data
          if (data.txtcolItemCode !== null && data.txtcolItemCode !== undefined && data.txtcolItemCode !== "") {
            setValue(thisObj, "ddItemCodeprof", (data.txtcolItemCode));
          }
          else {
            setValue(thisObj, "ddItemCodeprof", "");
          }
          if (data.txtcolDate !== null && data.txtcolDate !== undefined && data.txtcolDate !== "") {
            setValue(thisObj, "txtDate", (data.txtcolDate));
          }
          else {
            setValue(thisObj, "txtDate", "");
          }
          if (data.txtcolPeanutType !== null && data.txtcolPeanutType !== undefined && data.txtcolPeanutType !== "") {
            switch (data.txtcolPeanutType) {
              case "Runner":
                setValue(thisObj, "ddPeanutTypeprof", "RU");
                break;
              case "Spanish":
                setValue(thisObj, "ddPeanutTypeprof", "SP");
                break;
              case "Valencia":
                setValue(thisObj, "ddPeanutTypeprof", "VL");
                break;
              case "Virginia":
                setValue(thisObj, "ddPeanutTypeprof", "VA");
                break;
            }
          }
          else {
            setValue(thisObj, "ddPeanutTypeprof", "");
          }
          if (data.txtcolSeedGeneration !== null && data.txtcolSeedGeneration !== undefined && data.txtcolSeedGeneration !== "") {
            setValue(thisObj, "ddSeedGenerationprof", (data.txtcolSeedGeneration).substring(0, 1).toUpperCase());
          }
          else {
            setValue(thisObj, "ddSeedGenerationprof", "");
          }
          if (data.txtcolOleicInd !== null && data.txtcolOleicInd !== undefined && data.txtcolOleicInd !== "") {
            setValue(thisObj, "ddOleic", (data.txtcolOleicInd).substring(0, 1).toUpperCase());
          }
          else {
            setValue(thisObj, "ddOleic", "");
          }
          if (data.txtcolPayAmount !== null && data.txtcolPayAmount !== undefined && data.txtcolPayAmount !== "") {
            setValue(thisObj, "txtPayAmountprof", (data.txtcolPayAmount));
          }
          else {
            setValue(thisObj, "txtPayAmountprof", "");
          }
          if (data.txtcolChargeLocation !== null && data.txtcolChargeLocation !== undefined && data.txtcolChargeLocation !== "") {
            setValue(thisObj, "ddChargeLocationprof", (data.txtcolChargeLocation));
          }
          else {
            setValue(thisObj, "ddChargeLocationprof", "");
          }
          if (data.txtcolInvoiceNumber !== null && data.txtcolInvoiceNumber !== undefined && data.txtcolInvoiceNumber !== "") {
            setValue(thisObj, "txtInvoiceNumber", (data.txtcolInvoiceNumber));
          }
          else {
            setValue(thisObj, "txtInvoiceNumber", "");
          }
          if (data.txtcolComments !== null && data.txtcolComments !== undefined && data.txtcolComments !== "") {
            setValue(thisObj, "txtComment", (data.txtcolComments));
          }
          else {
            setValue(thisObj, "txtComment", "");
          }
         
          
         
        }
        else {
          alert("Atleast one row must be selected");
          return false;
        }
      }
      else {
        if(data !== undefined){
          DeleteMemoLine();
        }
        
        hide(thisObj, "lblAdd");
        hide(thisObj, "lblEdite");
        disable(thisObj, "ddSpoolLocationProf");
        disable(thisObj, "ddPayeeProf");
        disable(thisObj, "txtVendor");
        disable(thisObj, "btnVendor");
        let strSpoolLoc = thisObj.values.ddSpoolLocationProf;
        let strVendor = thisObj.values.txtVendor;
        let strItemCode = thisObj.values.ddItemCodeprof;
        let dDate = new Date(thisObj.values.txtDate);
        let strPnutType = thisObj.values.ddPeanutTypeprof;
        let dblPayAmount = thisObj.values.txtPayAmountprof;
        let strChargeLoc = thisObj.values.ddChargeLocationprof;
        let strseedGeneration = thisObj.values.ddSeedGenerationprof;
        if (strSpoolLoc == undefined || strSpoolLoc == null || strSpoolLoc == "") {
          alert("Spool Location can not be blank");
          return false;
        }
        if (strVendor == undefined || strVendor == null || strVendor == "") {
          alert("Vendor can not be blank");
          return false;
        }
        if (strItemCode == undefined || strItemCode == null || strItemCode == "") {
          alert("Item code can not be blank");
          return false;
        }
        if (dDate == undefined || dDate == null || dDate == "") {
          alert("Date can not be blank");
          return false;
        }
        if (strPnutType == undefined || strPnutType == null || strPnutType == "") {
          alert("Peanut type can not be blank");
          return false;
        }
        if ((strItemCode == 'SP')) {
          if (strseedGeneration == undefined || strseedGeneration == null || strseedGeneration == "") {
            alert("Seed Generation must be provided if Seed Premium is selected.");
            return false;
          }
        }
        if (dblPayAmount == undefined || dblPayAmount == null || dblPayAmount == "") {
          alert("Pay amount can not be blank");
          return false;
        }
        if(Number(dblPayAmount) == 0){
          alert("Pay amount can not be zero");
          return false;
        }
        if (strChargeLoc == undefined || strChargeLoc == null || strChargeLoc == "") {
          alert("Charge Location can not be blank");
          return false;
        }

        if (txtInvoiceNumber !== undefined && txtInvoiceNumber !== null && txtInvoiceNumber !== "") {
          let invoiceNnumber = thisObj.values.txtInvoiceNumber;
          AccountPayment.RetrieveSpecialPayableDetails(invoiceNnumber).then(response => {
            if (response.length > 0) {
              strInvoiceNum = response[0].invoice_num;
              alert("Invoice number '" + strInvoiceNum + "' already exists");
              setValue(thisObj, "txtInvoiceNumber", "");
              return false;
            }
            else {
              strInvoiceNum = thisObj.values.txtInvoiceNumber;
            }
            CreateMemoDetails();
            ClearTextBoxes();
            DisableControl();
            document.getElementsByClassName('btnEdit')[0].childNodes[0].childNodes[0].innerText = "Edit";
            document.getElementsByClassName('btnDelete')[0].childNodes[0].childNodes[0].innerText = "Delete";
            enable(thisObj, "btnAdd");
            enable(thisObj, "btnCreateHoldPay");
            enable(thisObj, "btnSaveToBatch");
            enable(thisObj, "btnPrintChecks");
            return false;

          });
        }

      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnEditClick event:Click"
        );
      }
      return false;
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnEditClick = onbtnEditClick
  // End method for Edit the Grid View details

  // Start method for delete the records from Gridview
  const onbtnDeleteClick = async () => {
    try {
      setLoading(true)
      let itemNum = "";
      let lblDelete = document.getElementsByClassName('btnDelete')[0].childNodes[0].childNodes[0].innerText == "Delete";
      if (lblDelete == true) {
        hide(thisObj, "lblEdite");
        hide(thisObj, "lblAdd");
        let data = thisObj.state.gridSpecialPaymentEntry.selected[0];
        if (data !== undefined && data !== null && data !== "") {
          itemNum = data.txtcolItem;
          let message = confirm("Are you sure to delete item " + itemNum);
          if (message == true) {
            DeleteMemoLine();
          }
          document.getElementsByClassName('btnEdit')[0].childNodes[0].childNodes[0].innerText = "Edit";
          let grdData = getData(thisObj, "gridSpecialPaymentEntry");
          if (grdData.length > 0) {
            enable(thisObj, "btnAdd");
            enable(thisObj, "btnEdit");
            enable(thisObj, "btnDelete");
          }
          else {
            enable(thisObj, "btnAdd");
            disable(thisObj, "btnEdit");
            disable(thisObj, "btnDelete");
          }
        }
        else {
          alert("Atleast one row must be selected");
          return false;
        }
      }
      else {
        let data = thisObj.state.gridSpecialPaymentEntry.selected[0];
        if (data !== undefined && data !== null && data !== "") {
          document.getElementsByClassName('btnDelete')[0].childNodes[0].childNodes[0].innerText = "Delete";
          document.getElementsByClassName('btnEdit')[0].childNodes[0].childNodes[0].innerText = "Edit";
          hide(thisObj, "lblEdite");
          hide(thisObj, "lblAdd");
          enable(thisObj, "btnAdd");
          enable(thisObj, "btnEdit");
          enable(thisObj, "btnDelete");
          enable(thisObj, "btnCreateHoldPay");
          enable(thisObj, "btnSaveToBatch");
          enable(thisObj, "btnPrintChecks");
          DisableControl();
          ClearTextBoxes();
        }
        else {
          document.getElementsByClassName('btnEdit')[0].childNodes[0].childNodes[0].innerText = "Edit";
          document.getElementsByClassName('btnDelete')[0].childNodes[0].childNodes[0].innerText = "Delete";
          enable(thisObj, "btnAdd");
          disable(thisObj, "btnEdit");
          disable(thisObj, "btnDelete");
          enable(thisObj, "btnCreateHoldPay");
          enable(thisObj, "btnSaveToBatch");
          enable(thisObj, "btnPrintChecks");
          DisableControl();
          ClearTextBoxes();
        }

      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnDeleteClick event:Click"
        );
      }
      return false;
    }
    finally{
      setLoading(false)
    }
    return true;
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;
  // End method for delete the records from Gridview

  // Start method for clode button
  const onbtnCloseClick = async () => {
    try {
      let strSpoolLoc = thisObj.values.ddSpoolLocationProf;
      let strVendor = thisObj.values.txtVendor;
      let mstrPrevStatus = thisObj.values.lblPending;
      let strMemoNum = thisObj.values.txtMemo
      let dataObj = {
        rtp_status: "R"
      }
      if (mstrPrevStatus == "Hold" || mstrPrevStatus == "Released") {
        await AccountPayment.UpdateSpecialPayablesReset(strSpoolLoc, strVendor, strMemoNum, dataObj).then(response => {
          if (response.status == 200) {
            mstrStatus = '';
            lblStatusDetail = "Pending"
            setData(thisObj, "refeshGridData", "OK");
          }
        });
      }
      goTo(thisObj, "AccountPayment#SpecialPayablesInquiry#DEFAULT#false#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbtnCloseClick event:Click");
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnCloseClick = onbtnCloseClick;
  // End method for clode button

  // Start method for delete the record from the GridView and called in onbtnDeleteClick method
  const DeleteMemoLine = async () => {
    try {
      let data = thisObj.state.gridSpecialPaymentEntry.selected[0];
      thisObj.state.gridSpecialPaymentEntry.selected = null
      if (data.txtcolTransactionNumber > 0) {
        let strBuyPtId = thisObj.values.ddSpoolLocationProf;
        let strPayeeVendor = thisObj.values.txtVendor;
        let strMemoNum = thisObj.values.txtMemo;
        let mstrPrevStatus = thisObj.values.lblPending;
        let lblStatusDetail = '';
        let lngTransNum = data.txtcolTransactionNumber;
        let dataObj = {
          rtp_status: "R"
        }
        if (mstrPrevStatus == "Hold" || mstrPrevStatus == "Released") {
          await AccountPayment.UpdateSpecialPayablesReset(strBuyPtId, strPayeeVendor, strMemoNum, dataObj).then(response => {
            if (response.status == 200) {
              mstrStatus = '';
              lblStatusDetail = "Pending"
            }
          });
        }
        await AccountPayment.RemoveSpecialPayables(strBuyPtId, strPayeeVendor, strMemoNum, lngTransNum).then(response => {
          if (response.status == 200) {
            PopulateGrid();
            DisplayValues();
          }
        });
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:DeleteMemoLine event:Click");
      }
      return false;
    }
    return true;
  }
  // End method for delete the record from the GridView and called in onbtnDeleteClick method

  // Start method to check value
  function checkAgainstValueOfCheck(txtValue) {
    try {
      if (txtValue == NaN)
        return
      let ldblValueOfCheck = 0;
      let valueOfRTP = Number(thisObj.values.txtValueOfCheck);
      if (valueOfRTP !== "") {
        if (Number(txtValue) !== Number(getValue(thisObj, 'txtCollectSR')))
          ldblValueOfCheck += Number(getValue(thisObj, 'txtCollectSR'))
        if (Number(txtValue) !== Number(getValue(thisObj, 'txtCollectAR')))
          ldblValueOfCheck += Number(getValue(thisObj, 'txtCollectAR'))
        if (Number(txtValue) !== Number(getValue(thisObj, 'txtCollectCR')))
          ldblValueOfCheck += Number(getValue(thisObj, 'txtCollectCR'))
        if (Number(txtValue) + Number(ldblValueOfCheck) > Number(valueOfRTP))
          txtValue = Number(valueOfRTP) - Number(ldblValueOfCheck)
      }
      Calc();
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:checkAgainstValueOfCheck event:Click");
      }
      return false;
    }
    return true;
  }
  // End method to check value

  // Start method to check AR SR and CR value
  const bCheck_AR_CR_SR = async () => {
    try {
      if (Number(getValue(thisObj, 'txtCRBalance')) > 0 && Number(getValue(thisObj, 'txtCollectCR') == 0)) {
        bCheck = true;
      }
      if (Number(getValue(thisObj, 'txtSRBalance')) > 0 && Number(getValue(thisObj, 'txtCollectSR') == 0)) {
        bCheck = true;
      }
      if (Number(getValue(thisObj, 'txtARBalance')) > 0 && Number(getValue(thisObj, 'txtCollectAR') == 0)) {
        bCheck = true;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:bCheck_AR_CR_SR event:Click");
      }
      return false;
    }
    return true;
  }
  // End method to check AR SR and CR value

  async function Calc() {
    try {
      let nn = 0;
      let entryList = getData(thisObj, 'gridSpecialPaymentEntry')
      
      if(entryList != undefined && entryList.length > 0){
        for (var i = 0; i < entryList.length; i++) {
          nn += Number(entryList[i].txtcolPayAmount);
        }
      }

      setValue(thisObj, 'txtValOfRTP', (Number(nn)).toFixed(2))
      setData(thisObj, 'txtValOfRTP', (Number(nn)).toFixed(2))

      if (nn <= 0)
        await ClearTexts();
      let arValue = getValue(thisObj, 'txtCollectAR') == undefined || Number(getValue(thisObj, 'txtCollectAR')) == NaN ? 0 : Number(getValue(thisObj, 'txtCollectAR'))
      let crValue = getValue(thisObj, 'txtCollectCR') == undefined || Number(getValue(thisObj, 'txtCollectCR')) == NaN ? 0 : Number(getValue(thisObj, 'txtCollectCR'))
      let srValue = getValue(thisObj, 'txtCollectSR') == undefined || Number(getValue(thisObj, 'txtCollectSR')) == NaN ? 0 : Number(getValue(thisObj, 'txtCollectSR'))

      let valueOfCheck = nn - arValue - crValue - srValue
      setValue(thisObj, 'txtValueOfCheck', Number(valueOfCheck).toFixed(2))
      setData(thisObj, 'txtValueOfCheck', Number(valueOfCheck).toFixed(2))
      await EnableDisableTextBoxes();
    }
    catch (err) {
      errorHandler(err);
    }
  }
  // Start method for Enable and Disables button
  const EnableDisableButtons = async () => {
    try {
      let data = getData(thisObj, "gridSpecialPaymentEntry");
      if (data !== null || data !== undefined)
        if (data.length > 0) {
          enable(thisObj, "btnEdit");
          enable(thisObj, "btnDelete");
        }
        else {
          return false;
        }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:EnableDisableButtons event:Click");
      }
      return false;
    }
    return true;
  }
  // End method for Enable and Disables button

  // Start method for Create memo details and called in onbtnEditClick function
  const CreateMemoDetails = async () => {
    try {
      let strSpoolLoc = '';
      let strVendor = '';
      let strPayee = '';
      let strItemCode = '';
      let dDate = '';
      let strPnutType = '';
      let strSeedGen = '';
      let strOleicInd = '';
      let dblPayAmount = 0;
      let strChargeLoc = '';
      let strComments = '';
      let Adduser = userid;
      let ChangeUser = userid;
      let mstrPrevStatus = thisObj.values.lblPending;
      let strMemoNum = thisObj.values.txtMemo
      let data = getData(thisObj, "gridSpecialPaymentEntry");
      if (data !== null && data !== "" && data !== undefined && data.length > 0) {
        mintMemoNum = thisObj.values.txtMemo;
      }
      else {
        mintMemoNum = 0;
      }
      strSpoolLoc = thisObj.values.ddSpoolLocationProf;
      strVendor = thisObj.values.txtVendor;
      strPayee = thisObj.values.ddPayeeProf;
      strItemCode = thisObj.values.ddItemCodeprof;
      dDate = new Date(thisObj.values.txtDate);
      strPnutType = thisObj.values.ddPeanutTypeprof;
      strSeedGen = thisObj.values.ddSeedGenerationprof;
      if (strSeedGen !== undefined && strSeedGen !== "" && strSeedGen !== null) {
        strSeedGen = thisObj.values.ddSeedGenerationprof;
      }
      else {
        strSeedGen = "";
      }
      strOleicInd = thisObj.values.ddOleic;
      if (strOleicInd !== undefined && strOleicInd !== "" && strOleicInd !== null) {
        strOleicInd = thisObj.values.ddOleic;
      }
      else {
        strOleicInd = "";
      }
      if (Number(thisObj.values.txtPayAmountprof)) {
        dblPayAmount = thisObj.values.txtPayAmountprof;
      }
      else {
        dblPayAmount = "00";
      }
      strChargeLoc = thisObj.values.ddChargeLocationprof;
      strInvoiceNum = (thisObj.values.txtInvoiceNumber);
      if (strInvoiceNum !== undefined && strInvoiceNum !== "" && strInvoiceNum !== null) {
        strInvoiceNum = (thisObj.values.txtInvoiceNumber);
      }
      else {
        strInvoiceNum = "";
      }
      strComments = (thisObj.values.txtComment);
      if (strComments !== undefined && strComments !== "" && strComments !== null) {
        strComments = (thisObj.values.txtComment);
      }
      else {
        strComments = "";
      }
      let rtpStatus = "P";
      let snumber = 0;
      let dataObj = {
        payee_vendor: strVendor,
        payee_remit: strPayee,
        rtp_proceeds: dblPayAmount,
        special_type: strItemCode,
        add_user: Adduser,
        chg_user: ChangeUser,
        special_date: dDate,
        spool_buy_pt_id: strSpoolLoc,
        pnut_type_id: strPnutType,
        seed_gen: strSeedGen,
        oleic_ind: strOleicInd,
        charge_buy_id: strChargeLoc,
        invoice_num: strInvoiceNum,
        audit_reason: strComments
      }
      setData(thisObj, 'createMemo', dataObj)
      let resetObj = {
        rtp_status: "R"
      }
      if (mstrPrevStatus == "Hold" || mstrPrevStatus == "Released") {
        await AccountPayment.UpdateSpecialPayablesReset(strSpoolLoc, strVendor, strMemoNum, resetObj).then(response => {
          if (response.status !== 200) {
            mstrStatus = '';
            lblStatusDetail = "Pending"
          }
        });
      }
      await AccountPayment.CreateSpecialPayables(mintMemoNum, dataObj).then(response => {
        let js = [];
        let memoObj = {};
        let data = response.result[0];
        let memoData = getData(thisObj, 'createMemo');
        let count = getValue(thisObj, "gridSpecialPaymentEntry");
        if (count !== undefined && count.length !== 0) {
          // for (var i = 0; i < count.length; i++) {
          //   snumber = count.length;
          // }
          
          snumber = count.length; // no need for iteration when we just need the length of the list.
        }
        memoObj.txtcolItem = '0'.repeat(3 - (snumber + 1).toString().length) + (snumber + 1);
        memoObj.txtcolItemCode = memoData.special_type;
        memoObj.txtcolDate = ConvertToDate(memoData.special_date);
        switch (memoData.pnut_type_id) {
          case "RU":
            memoObj.txtcolPeanutType = 'Runner';
            break;
          case "SP":
            memoObj.txtcolPeanutType = 'Spanish';
            break;
          case "VL":
            memoObj.txtcolPeanutType = 'Valencia';
            break;
          case "VA":
            memoObj.txtcolPeanutType = 'Virginia';
            break;
        }
        switch (memoData.seed_gen) {
          case "F":
            memoObj.txtcolSeedGeneration = 'Foundation';
            break;
          case "R":
            memoObj.txtcolSeedGeneration = 'Registered';
            break;
          case "C":
            memoObj.txtcolSeedGeneration = 'Certified';
            break;
        }
        switch (memoData.oleic_ind) {
          case "H":
            memoObj.txtcolOleicInd = 'High';
            break;
          case "M":
            memoObj.txtcolOleicInd = 'Mid'; // from Medium to Mid to display in grid
            break;
        }
        let payAmoutnt = memoData.rtp_proceeds;
        if ((payAmoutnt) === payAmoutnt && payAmoutnt % 1 === 0) {
          memoObj.txtcolPayAmount = Number(payAmoutnt) + ".00";
        }
        else {
          memoObj.txtcolPayAmount = payAmoutnt;
        }
        memoObj.txtcolChargeLocation = memoData.charge_buy_id;
        memoObj.txtcolInvoiceNumber = memoData.invoice_num;
        memoObj.txtcolComments = memoData.audit_reason;
        memoObj.txtcolTransactionNumber = data == undefined || data.transaction_num == undefined ? '' : data.transaction_num;
        memoObj.txtMemo = data == undefined || data.last_memo_num == undefined ? '' : data.last_memo_num;
        memoObj.mintMemoNum = data == undefined || data.last_memo_num == undefined ? '' : data.last_memo_num;

        // let list = getValue(thisObj, "gridSpecialPaymentEntry");
        let list = getData(thisObj, "gridSpecialPaymentEntry");
        if (list !== undefined && list.length !== 0) {
          for (var i = 0; i < list.length; i++) {
            js.push(list[i]);
          }
        }
        js.push(memoObj);
        memoObj = {};
        setValue(thisObj, "gridSpecialPaymentEntry", js);
        setData(thisObj, "gridSpecialPaymentEntry", js);
        DisplayValues();
        ClearTextBoxes();
      });
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:CreateMemoDetails event:Click");
      }
      return false;
    }
    return true;
  }
  // End method for Create memo details and called in onbtnEditClick function

  //Start Method to clear the controls
  const ClearTextBoxes = async () => {
    try {
      setValue(thisObj, "ddItemCodeprof", "");
      setValue(thisObj, "ddPeanutTypeprof", "");
      setValue(thisObj, "ddSeedGenerationprof", "");
      setValue(thisObj, "ddOleic", "");
      setValue(thisObj, "txtPayAmountprof", "");
      setValue(thisObj, "ddChargeLocationprof", "");
      setValue(thisObj, "txtInvoiceNumber", "");
      setValue(thisObj, "txtComment", "");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ClearTextBoxes event:Click");
      }
      return false;
    }
    return true;
  }
  //End Method to clear the controls

  //Start Method to disable the controls
  const DisableControl = async () => {
    try {
      disable(thisObj, 'ddItemCodeprof');
      disable(thisObj, 'ddSeedGenerationprof');
      disable(thisObj, 'txtDate');
      disable(thisObj, 'ddOleic');
      disable(thisObj, 'txtPayAmountprof');
      disable(thisObj, 'ddChargeLocationprof');
      disable(thisObj, 'ddPeanutTypeprof');
      disable(thisObj, 'txtInvoiceNumber');
      disable(thisObj, 'txtComment');
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:DisableControl event:Click");
      }
      return false;
    }
    return true;
  }
  //End Method to disable the controls

  //Start Method to slice the date time
  function ConvertToDate(str) {
    try {
      if (str !== undefined && str !== '') {
        let date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
      } else {
        return '';
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ConvertToDate event:Click");
      }
      return false;
    }
    return true;
  }
  //End Method to slice the date time


  // Start method for Add button 
  const onbtnAddClick = async () => {
    try {
      setLoading(true)
      hide(thisObj, "lblEdite");
      show(thisObj, "lblAdd");
      enable(thisObj, "ddItemCodeprof");
      enable(thisObj, "txtDate");
      enable(thisObj, "ddPeanutTypeprof");
      enable(thisObj, "ddSeedGenerationprof");
      enable(thisObj, "ddOleic");
      enable(thisObj, "txtPayAmountprof");
      enable(thisObj, "ddChargeLocationprof");
      enable(thisObj, "txtInvoiceNumber");
      enable(thisObj, "txtComment");
      enable(thisObj, "btnEdit");
      enable(thisObj, "btnDelete");
      disable(thisObj, "btnAdd");
      disable(thisObj, "btnCreateHoldPay");
      disable(thisObj, "btnSaveToBatch");
      disable(thisObj, "btnPrintChecks");
      thisObj.values.txtDate = ConvertToDate(new Date());
      document.getElementsByClassName('btnEdit')[0].childNodes[0].childNodes[0].innerText = "Save"
      document.getElementsByClassName('btnDelete')[0].childNodes[0].childNodes[0].innerText = "Cancel";
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnAddClick event:Click"
        );
      }
      return false;
    }
    finally{
      setLoading(false)
    }
    return true;
  }
  thisObj.onbtnAddClick = onbtnAddClick;
  // End method for Add button 

  // Start method for print checks button
  const onbtnPrintChecksClick = async () => {
    try {
      //let isSelected = thisObj.state.gridSpecialPaymentEntry.selected[0];

      let isSelected = thisObj.state.gridSpecialPaymentEntry.selected[0];

      if (isSelected !== undefined && isSelected !== null && isSelected !== "") {
        let trasnobj = {};
        let trasnArr = [];
        let js = [];
        let transaction_num = 0;
        let lngRCOUNT = 0;
        let LstrTN = "";
        let LstrTY = "";
        let tempVal = "";
        let CBuyPtId = "";
        let rtpProceed = 0;
        let collectAr = Number(getValue(thisObj, 'txtCollectAR'));
        let collectSr = Number(getValue(thisObj, 'txtCollectSR'));
        let collectCr = Number(getValue(thisObj, 'txtCollectCR'));
        let remitTo = thisObj.values.ddPayeeProf;
        let accVendor = thisObj.values.txtVendor;
        let buy_pt_id = thisObj.values.ddSpoolLocationProf;
        let remarks = thisObj.values.txtRemarks;
        let payInd = "";
        if (remarks == undefined || remarks == null) {
          remarks = "";
        }
        let intr_agree_num = "";
        await ContractManagementService.RetrieveVendorByNumber(accVendor).then(response => {
          let data = response;
          if (data.length < 0) {
            alert("Vendor not found!");
            return false;
          }
          for (let i = 0; i < data.length; i++) {
            payInd = data[i].pay_ind;
            if (data[i].number == "") {
              alert("Vendor not found!");
              return false;
            }
          }
        });
        if (payInd !== "Y") {
          alert("The Vendor Master Pay indicator is not set to Y!");
          return false;
        }

        let n = 0;
        if (bCheck_AR_CR_SR() && thisObj.values.txtValueOfCheck > 0) {
          if (bCheck == true) {
            let res = confirm("Do you wish to collect the AR, CR or SR that is due?")
            if (res) {
              if (isEnabled(thisObj, 'txtCollectSR')) {
                document.getElementById('txtCollectSR').focus()
                return;
              }
              if (isEnabled(thisObj, 'txtCollectAR')) {
                document.getElementById('txtCollectAR').focus()
                return;
              }
              if (isEnabled(thisObj, 'txtCollectCR')) {
                document.getElementById('txtCollectCR').focus()
                return;
              }
            }
          }
        }

        let griData = getData(thisObj,"gridSpecialPaymentEntry");
        if (griData !== undefined && griData !== null && griData !== "") {
          for(let i = 0;  i < griData.length; i++){
            lngRCOUNT = lngRCOUNT + 1;
            transaction_num = griData[i].txtcolTransactionNumber;
            trasnobj.LstrTN = transaction_num
            trasnobj.LstrTY = "R" ;
            trasnArr.push(trasnobj);
            trasnobj = {};
          }          
        }
        else {
          alert("Items not found to print checks");
          return false;
        }

        if (thisObj.values.txtValueOfCheck > 0) {
          tempVal = prompt("Enter check number");
          if (isNaN(tempVal)) {
            alert("Check Number must be numeric");
            return false;
          }
          if (tempVal <= 0) {
            alert("The Check Number cannot be Zero !");
            return false;
          }
          if (tempVal > thisObj.values.txtEndCheckNum) {
            alert("The Check Number exceeds number range!");
            return false;
          }
          chknumber = tempVal
          thisObj.values.txtCheck = chknumber;
          setValue(thisObj, "txtCheck", chknumber);
        }
        else {
          chknumber = thisObj.values.txtCheck;
        }
        for(let i = 0 ; i < trasnArr.length; i++){
          js.push({
            "TN" : trasnArr[i].LstrTN.toString(),
            "TY" :  trasnArr[i].LstrTY
          })            
        }
        let dataObj = {
          user_id: userid,
          rcount: lngRCOUNT,
          cr_amount: collectCr,
          ar_amount: collectAr,
          sr_amount: collectSr,
          interest_amt: rtpProceed,
          remit_to: remitTo,
          remarks: remarks,
          intr_agree_num: intr_agree_num,
          transactions:   js           
          
        }
        if (dataObj !== undefined && dataObj !== null && dataObj !== "") {
          ContractManagementService.RetrieveUserControlDetails(userid).then(response => {
            CBuyPtId = response[0].buyPtId;
            AccountPayment.UpdateReleaseReadyToPayDetails(buy_pt_id, CBuyPtId, chknumber, accVendor, dataObj).then(response => {
              if (response.status !== 200) {
                alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\n" + response.message.substring(69, 100));
                return false;
              }
              else {
                // need to pass following thing in Report preview Check screen txtCheckNumber,ddSpoolLocationProf,txtVendor         
                //Loade(frmReportPreviewCheck);
                let cboSpoolLocation = thisObj.values.ddSpoolLocationProf;
                let vendor = thisObj.values.txtVendor;
                let checkNum = thisObj.values.txtCheck;
                let arr = [];
                let obj = {
                  buying_Point: cboSpoolLocation,
                  vendor: vendor,
                  check_num: checkNum,
                  Title: "ORIGIN BUYING POINT",
                  ScreenName: "SpecialPayablesProfile",
                  Proceeds: ""
                };
                arr.push(obj)
                obj = {}
                setData(thisObj, "ReportPreviewCheck", arr);
                setData(thisObj, "closingOfReportPreviewCheck", false);
                if (thisObj.values.txtValueOfCheck > 0) {
                  goTo(thisObj, "AccountPayment#ReportPreviewCheck#DEFAULT#true#Click");
                  let LstrXMLCopy = '';
                  if (Number(thisObj.values.txtCheck)) {
                    AccountPayment.RetrieveBatchCheckPrintDetails(buy_pt_id, chknumber, accVendor).then(response => {
                      if (response.status == 200) {
                        LstrXMLCopy = "<msg_printer_select>Y</msg_printer_select>";
                        //frmReportPreviewCheck("<msg_printer_select>Y</msg_printer_select>", "Origin Buying Point");
                      }
                    });
                  }
                }
              }
            });
          });
        }
        if (thisObj.values.txtValueOfCheck <= 0) {
          let message = confirm("Would you like enter another special check?");
          if (message == true) {
            ClearTextBoxes();
            ClearControls();
            ResetMemoHeader();
            ClearTexts();
            GetNextCheckNum();
            enable(thisObj, "ddSpoolLocationProf");
            enable(thisObj, "ddPayeeProf");
            enable(thisObj, "txtVendor");
            enable(thisObj, "btnVendor");
            disable(thisObj, "txtCollectAR");
            disable(thisObj, "txtCollectSR");
            disable(thisObj, "txtCollectCR");
            setValue(thisObj, "txtARBalance", "") // balance values needs to reset to empty when the process is completed
            setValue(thisObj, "txtCRBalance", "") // balance values needs to reset to empty when the process is completed
            setValue(thisObj, "txtSRBalance", "") // balance values needs to reset to empty when the process is completed
            setValue(thisObj, "txtRemarks", "")   // remarks needs to reset to empty when the process is completed
            setValue(thisObj, 'txtDate', "")      // date needs to reset to empty when the process is completed
            setValue(thisObj, 'txtCollectAR', "0.00") // collect values needs to reset to empty when the process is completed
            setValue(thisObj, 'txtCollectCR', "0.00") // collect values needs to reset to empty when the process is completed
            setValue(thisObj, 'txtCollectSR', "0.00") // collect values needs to reset to empty when the process is completed
          }
          else {
            goTo(thisObj, "AccountPayment#SpecialPayablesInquiry#DEFAULT#false#Click");
            return true;
          }
        }
      }
      else {
        alert("Atleat one row must be selected!");
        return false;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbtnPrintChecksClick event:Click");
      }
      return false;
    }
  }
  thisObj.onbtnPrintChecksClick = onbtnPrintChecksClick;
  // End method for print checks button

  // Start method for Save to Batch button
  const onbtnSaveToBatchClick = async () => {
    try {
      let isSelected = thisObj.state.gridSpecialPaymentEntry.selected[0];
      
      if (isSelected !== undefined && isSelected !== null && isSelected !== "") {
        let trasnobj = {};
        let trasnArr = [];
        let js = [];
        let transaction_num = 0;
        let LlngRCount = 0;
        let LstrTN = "";
        let LstrTY = "";
        let rtpProceed = 0;
        let CollectCR = Number(getValue(thisObj, 'txtCollectCR'));
        let CollectAR = Number(getValue(thisObj, 'txtCollectAR'));
        let CollectSR = Number(getValue(thisObj, 'txtCollectSR'));
        let remitTo = thisObj.values.ddPayeeProf;
        let accVendor = thisObj.values.txtVendor;
        let buy_pt_id = thisObj.values.ddSpoolLocationProf;
        let remark = thisObj.values.txtRemarks;
        if (remark == undefined || remark == null) {
          remark = "";
        }
        let intr_agree_num = "";
        await ContractManagementService.RetrieveVendorByNumber(accVendor).then(response => {
          let data = response;
          if (data.length < 0) {
            alert("Vendor not found!");
            return false;
          }
          for (let i = 0; i < data.length; i++) {
            if (data[i].number == "") {
              alert("Vendor not found!");
              return false;
            }
            if (data[i].pay_ind !== "Y") {
              alert("The Vendor Master Pay indicator is not set to Y!");
              return false;
            }
            let n = 0;
            if (bCheck_AR_CR_SR() && thisObj.values.txtValueOfCheck > 0) {
              if (bCheck == true) {
                let res = confirm("Do you wish to collect the AR, CR or SR that is due?")
                if (res) {
                  if (isEnabled(thisObj, 'txtCollectSR')) {
                    document.getElementById('txtCollectSR').focus()
                    return;
                  }
                  if (isEnabled(thisObj, 'txtCollectAR')) {
                    document.getElementById('txtCollectAR').focus()
                    return;
                  }
                  if (isEnabled(thisObj, 'txtCollectCR')) {
                    document.getElementById('txtCollectCR').focus()
                    return;
                  }
                }
              }
            }
            
            let griData = getData(thisObj,"gridSpecialPaymentEntry");
            if (griData !== undefined && griData !== null && griData !== "") {
              for(let i = 0;  i < griData.length; i++){
                LlngRCount = LlngRCount + 1;
                transaction_num = griData[i].txtcolTransactionNumber;
                trasnobj.LstrTN = transaction_num
                trasnobj.LstrTY = "R" ;
                trasnArr.push(trasnobj);
                trasnobj = {};
              }          
            }
            
            if (griData.length < 0) {
              showMessage("No Special Payables found!");
              return false;
            }
            for(let i = 0 ; i < trasnArr.length; i++){
              js.push({
                "TN" : trasnArr[i].LstrTN.toString(),
                "TY" :  trasnArr[i].LstrTY
              })            
            }
            let obj = {
              user_id: userid,
              rcount: LlngRCount,
              cr_amount: CollectCR,
              ar_amoun: CollectAR,
              sr_amoun: CollectSR,
              interest_amt: rtpProceed,
              remit_to: remitTo,
              remarks: remark,
              intr_agree_num: intr_agree_num,
              transactions: js
            }
            AccountPayment.UpdateBatchReadyToPayDetails(buy_pt_id, accVendor, obj).then(response => {
              if (response.status !== 200) {
                alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
                return false;
              }
              else {
                alert("Special Payable Check was saved to Batch successfully!");
              }
              let message = confirm("Would you like enter another special check?");
              if (message == true) {
                ClearTextBoxes();
                ClearControls();
                ResetMemoHeader();
                ClearTexts();
                enable(thisObj, "ddSpoolLocationProf");
                enable(thisObj, "ddPayeeProf");
                enable(thisObj, "txtVendor");
                enable(thisObj, "btnVendor");
                disable(thisObj, "txtCollectAR");
                disable(thisObj, "txtCollectSR");
                disable(thisObj, "txtCollectCR");
                setValue(thisObj, "txtARBalance", "") // balance values needs to reset to empty when the process is completed
                setValue(thisObj, "txtCRBalance", "") // balance values needs to reset to empty when the process is completed
                setValue(thisObj, "txtSRBalance", "") // balance values needs to reset to empty when the process is completed
                setValue(thisObj, "txtRemarks", "")   // remarks needs to reset to empty when the process is completed
                setValue(thisObj, 'txtDate', "")      // date needs to reset to empty when the process is completed
                setValue(thisObj, 'txtCollectAR', "0.00") // collect values needs to reset to empty when the process is completed
                setValue(thisObj, 'txtCollectCR', "0.00") // collect values needs to reset to empty when the process is completed
                setValue(thisObj, 'txtCollectSR', "0.00") // collect values needs to reset to empty when the process is completed
              }
              else {
                goTo(thisObj, "AccountPayment#SpecialPayablesInquiry#DEFAULT#false#Click");
                return true;
              }
            });
          }
        });
      }
      else {
        alert("Atleat one row must be selected!");
        return false;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbtnSaveToBatchClick event:Click");
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnSaveToBatchClick = onbtnSaveToBatchClick;
  // End method for Save to Batch button

  // Start method for Create hold Pay request
  const onbtnCreateHoldPayClick = async () => {
    try {
      let isSelected = thisObj.state.gridSpecialPaymentEntry.selected[0];
      if (isSelected !== undefined && isSelected !== null && isSelected !== "") {
        mintMemoNum = thisObj.values.txtMemo
        let accVendor = thisObj.values.txtVendor;
        let buy_pt_id = thisObj.values.ddSpoolLocationProf;
        await ContractManagementService.RetrieveVendorByNumber(accVendor).then(response => {
          let data = response;
          if (data.length < 0) {
            alert("Vendor not found!");
            return false;
          }
          for (let i = 0; i < data.length; i++) {
            if (data[i].number == "") {
              alert("Vendor not found!");
              return false;
            }
            if (data[i].pay_ind !== "Y") {
              alert("The Vendor Master Pay indicator is not set to Y!");
              return false;
            }
            
            let griData = getValue(thisObj, 'gridSpecialPaymentEntry');
            if (griData.length > 0) {
              let Confirm = confirm("Are you sure to want to change the items to 'HOLD' status?");
              if (Confirm == true) {
                AccountPayment.UpdateSpecialPayablesSetOnHold(buy_pt_id, accVendor, mintMemoNum).then(response => {
                  if (response.status !== 200) {
                    alert("Items not found to change status");
                    return false;
                  }
                  else {
                    alert("Items status changed to 'HOLD' successfully.");
                  }
                  let message = confirm("Would you like enter another special check?");
                  if (message == true) {
                    ClearTextBoxes();
                    ClearControls();
                    ResetMemoHeader();
                    ClearTexts();
                    enable(thisObj, "ddSpoolLocationProf");
                    enable(thisObj, "ddPayeeProf");
                    enable(thisObj, "txtVendor");
                    enable(thisObj, "btnVendor");
                    disable(thisObj, "txtCollectAR");
                    disable(thisObj, "txtCollectSR");
                    disable(thisObj, "txtCollectCR");
                    setValue(thisObj, "txtARBalance", "") // balance values needs to reset to empty when the process is completed
                    setValue(thisObj, "txtCRBalance", "") // balance values needs to reset to empty when the process is completed
                    setValue(thisObj, "txtSRBalance", "") // balance values needs to reset to empty when the process is completed
                    setValue(thisObj, "txtRemarks", "")   // remarks needs to reset to empty when the process is completed
                    setValue(thisObj, 'txtDate', "")      // date needs to reset to empty when the process is completed
                    setValue(thisObj, 'txtCollectAR', "0.00") // collect values needs to reset to empty when the process is completed
                    setValue(thisObj, 'txtCollectCR', "0.00") // collect values needs to reset to empty when the process is completed
                    setValue(thisObj, 'txtCollectSR', "0.00") // collect values needs to reset to empty when the process is completed
                  }
                  else {
                    goTo(thisObj, "AccountPayment#SpecialPayablesInquiry#DEFAULT#false#Click");
                  }
                });
              }
            }
          }
        });
      }
      else {
        alert("Atleast one row must be selected!");
        return false;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbtnCreateHoldPayClick event:Click");
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnCreateHoldPayClick = onbtnCreateHoldPayClick;
  // End method for Create hold Pay request

  //Start Method for Scanned document button
  const onbtnScanDocumentsClick = async () => {
    try {
      let buyingPointId = thisObj.state['ddSpoolLocationProf'].valueList;
      let ByPtID = thisObj.values.ddSpoolLocationProf;
      let cboSpoolLocationDescription = buyingPointId.find(elem => elem.key == ByPtID).description;

      let payeeRemit = thisObj.state['ddPayeeProf'].valueList;
      let ddPayeeProf = payeeRemit.find(elem => elem.description).description;

      let cboSpoolLocation = cboSpoolLocationDescription;
      let vendor = thisObj.values.txtVendor + "-" + ddPayeeProf;
      let memoNumber = thisObj.values.txtMemo;
      let data = thisObj.state.gridSpecialPaymentEntry.selected[0];
      if ((data !== undefined) && (data !== null) && (data !== "")) {
        let obj = {
          cboSpoolLocation: cboSpoolLocation,
          vendor: vendor,
          memoNumber: memoNumber
        };
        setData(thisObj, "ScaneDocument", obj);
        goTo(thisObj, "AccountPayment#SpecialPayablesScan#DEFAULT#true#Click");
      }
      else {
        alert("Atleast one row must be selected");
        return false;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbtnScanDocumentsClick event:Click");
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnScanDocumentsClick = onbtnScanDocumentsClick;
  // End Method for Scanned document button

  // Start Method for ViewDocuments and called in onbtnViewDocumentClick
  async function ViewDocuments() {
    try {
      let buyingPointId = thisObj.state['ddSpoolLocationProf'].valueList;
      let ByPtID = thisObj.values.ddSpoolLocationProf;
      let cboSpoolLocationDescription = buyingPointId.find(elem => elem.key == ByPtID).description;
      let payeeRemit = thisObj.state['ddPayeeProf'].valueList;
      let ddPayeeProf = payeeRemit.find(elem => elem.description).description;

      let cboSpoolLocation = cboSpoolLocationDescription;
      let vendor = thisObj.values.txtVendor + "-" + ddPayeeProf;
      let memoNumber = thisObj.values.txtMemo;

      let data = thisObj.state.gridSpecialPaymentEntry.selected[0];
      if ((data !== undefined) && (data !== null) && (data !== "")) {
        let obj = {
          cboSpoolLocation: cboSpoolLocation,
          vendor: vendor,
          memoNumber: memoNumber
        };
        setData(thisObj, "ViewDocument", obj);
        goTo(thisObj, "AccountPayment#SpecialPayDocsView#DEFAULT#true#Click");
        return true;
      }
      else {
        alert("Atleast one row must be selected");
        return false;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ViewDocuments event:Click");
      }
      return false;
    }
    return true;
  }
  // End Method for ViewDocuments and called in onbtnViewDocumentClick

  //Start Method for View document button
  const onbtnViewDocumentsClick = async () => {
    try {
      let res = await ViewDocuments();
      if (!res)
        return;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbtnViewDocumentsClick event:Click");
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnViewDocumentsClick = onbtnViewDocumentsClick;
  //End Method fro View document button

  // Start Method to get Next CheckNum
  const GetNextCheckNum = async () => {
    try {
      let mstrcbuy_pt_id = thisObj.values.ddSpoolLocationProf;
      if (Number(thisObj.values.txtCheck)) {
        await ContractManagementService.RetrieveBuyingPointControlDetails(null, null, null, null, mstrcbuy_pt_id, userid).then(response => {
          let data = response;
          for (let i = 0; i < data.length; i++) {
            chknumber = data[i].next_check;
            txtCheck.value = chknumber;
          }
        })
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:GetNextCheckNum event:Click");
      }
      return false;
    }
    return true;
  }
  // End Method to get Next CheckNum


  //Start Method to get Account details
  const GetAccountDetails = async () => {
    try {
      let arBal = ''
      let crBal = ''
      let srBal = ''
      let arHide = true;
      let crHide = true;
      let srHide = true;

      let buy_pt_id = getValue(thisObj, 'ddSpoolLocationProf') //thisObj.values.ddSpoolLocationProf;
      let acct_vendor = getValue(thisObj, 'txtVendor') //thisObj.values.txtVendor;
      let caption = getData(thisObj, 'SpecialPayablesProfileData');
      if (caption !== null) {
        if (caption.Status == 'RTP-P' && buy_pt_id !== null && buy_pt_id !== undefined && caption.cmdOpen == false && acct_vendor !== null && acct_vendor !== undefined && acct_vendor !== '') {
          await AccountPayment.RetrieveOpenAccountControlDetails(buy_pt_id, acct_vendor).then(response => {
            let data = response[0];
            if (data !== undefined && data !== null && data.open_acct_control !== undefined && data.open_acct_control !== null) { // Length property was not available for 'data' parameter, hence it was always making the condition as false.
              for (let i = 0; i < data.open_acct_control.length; i++) {
                let arBalance = data.open_acct_control[0].ar_end_balance;
                ARBalance = data.open_acct_control[0].ar_end_balance;
                if (arBalance !== null && arBalance !== undefined && arBalance !== "" && arBalance !== '0.00') {
                  if (Number(arBalance) === arBalance && arBalance % 1 === 0) {
                    arBal = Number(arBalance).toFixed(2)// + ".00" //setValue(thisObj, "txtARBalance", arBalance + ".00");
                  } else {
                    arBal = Number(arBalance).toFixed(2) // setValue(thisObj, "txtARBalance", Number(arBalance).toFixed(2));
                  }
                } else {
                  arHide = false;//disable(thisObj, "txtCollectAR");
                  arBal = "0.00" //setValue(thisObj, "txtARBalance", "0.00");
                }

                let srBalance = data.open_acct_control[0].sr_end_balance;
                SRBalance = data.open_acct_control[0].sr_end_balance;

                if (srBalance !== null && srBalance !== undefined && srBalance !== "" && srBalance !== "0.00") {
                  if (Number(srBalance) === srBalance && srBalance % 1 === 0) {
                    srBal = Number(srBalance).toFixed(2) //+ ".00" //setValue(thisObj, "txtSRBalance", srBalance + ".00");
                  } else {
                    srBal = Number(srBalance).toFixed(2) //setValue(thisObj, "txtSRBalance", Number(srBalance).toFixed(2));
                  }
                } else {
                  srHide = false; //disable(thisObj, "txtCollectSR");
                  srBal = "0.00" //setValue(thisObj, "txtSRBalance", "0.00");
                }

                let crBalance = data.open_acct_control[0].cr_end_balance;
                CRBalance = data.open_acct_control[0].cr_end_balance;

                if (crBalance !== null && crBalance !== undefined && crBalance !== "" && crBalance !== "0.00") {
                  if (Number(crBalance) === crBalance && crBalance % 1 === 0) {
                    crBal = Number(crBalance).toFixed(2) // + ".00" //setValue(thisObj, "txtCRBalance", crBalance + ".00");
                  } else {
                    crBal = Number(crBalance).toFixed(2) // setValue(thisObj, "txtCRBalance", Number(crBalance).toFixed(2));
                  }
                } else {
                  crHide = false; //disable(thisObj, "txtCollectCR");
                  crBal = "0.00" //setValue(thisObj, "txtCRBalance", "0.00");
                }
              }
              setValue(thisObj, "txtARBalance", Number(arBal).toFixed(2));
              setValue(thisObj, "txtCRBalance", Number(crBal).toFixed(2));
              setValue(thisObj, "txtSRBalance", Number(srBal).toFixed(2));
              if(!arHide){
                disable(thisObj, 'txtCollectAR')
              }
              if(!crHide){
                disable(thisObj, 'txtCollectCR')
              }
              if(!srHide){
                disable(thisObj, 'txtCollectSR')
              }
            }
            else {
              setValue(thisObj, "txtARBalance", "0.00");
              setValue(thisObj, "txtSRBalance", "0.00");
              setValue(thisObj, "txtCRBalance", "0.00");
              disable(thisObj, "txtCollectAR");
              disable(thisObj, "txtCollectSR");
              disable(thisObj, "txtCollectCR");

            }
          });
        } else {
          if(![undefined, null, ''].includes(caption.ByPtID) && ![undefined, null, ''].includes(caption.txtVendor)){
            await AccountPayment.RetrieveOpenAccountControlDetails(caption.ByPtID, caption.txtVendor).then(response => {
              let data = response[0];
              if (data!== undefined && data !== null && data.open_acct_control !== undefined && data.open_acct_control !== null) { // Length property was not available for 'data' parameter, hence it was always making the condition as false.
                for (let i = 0; i < data.open_acct_control.length; i++) {
                  
                  let arBalance = data.open_acct_control[0].ar_end_balance;
                  ARBalance = data.open_acct_control[0].ar_end_balance;
                  if (arBalance !== null && arBalance !== undefined && arBalance !== "" && arBalance !== "0.00") {
                    if (Number(arBalance) === arBalance && arBalance % 1 === 0) {
                      arBal = Number(arBalance).toFixed(2) //+ ".00" //setValue(thisObj, "txtARBalance", arBalance + ".00");
                    } else {
                      arBal = Number(arBalance).toFixed(2)//setValue(thisObj, "txtARBalance", Number(arBalance).toFixed(2));
                    }
                  } else {
                    arHide = false; //disable(thisObj, "txtCollectAR");
                    arBal = "0.00" //setValue(thisObj, "txtARBalance", "0.00");
                  }
  
                  let srBalance = data.open_acct_control[0].sr_end_balance;
                  SRBalance = data.open_acct_control[0].sr_end_balance;
                  if (srBalance !== null && srBalance !== undefined && srBalance !== "" && srBalance !== "0.00") {
                    if (Number(srBalance) === srBalance && srBalance % 1 === 0) {
                      srBal = Number(srBalance).toFixed(2) // + ".00" //setValue(thisObj, "txtSRBalance", srBalance + ".00");
                    } else {
                      srBal = Number(srBalance).toFixed(2) //setValue(thisObj, "txtSRBalance", Number(srBalance).toFixed(2));
                    }
                  } else {
                    srHide = false; //disable(thisObj, "txtCollectSR");
                    srBal = "0.00" //setValue(thisObj, "txtSRBalance", "0.00");
                  }
  
                  let crBalance = data.open_acct_control[0].cr_end_balance;
                  CRBalance = data.open_acct_control[0].cr_end_balance;
                  if (crBalance !== null && crBalance !== undefined && crBalance !== "" && crBalance !== "0.00") {
                    if (Number(crBalance) === crBalance && crBalance % 1 === 0) {
                      crBal = Number(crBalance).toFixed(2) //+ "0.00" //setValue(thisObj, "txtCRBalance", crBalance + ".00");
                    } else {
                      crBal = Number(crBalance).toFixed(2) //setValue(thisObj, "txtCRBalance", Number(crBalance).toFixed(2));
                    }
                  } else {
                    crHide = false; //disable(thisObj, "txtCollectCR");
                    crBal = "0.00";//setValue(thisObj, "txtCRBalance", "0.00");
                  }
                }
                setValue(thisObj, "txtARBalance", arBal);
                setValue(thisObj, "txtCRBalance", crBal);
                setValue(thisObj, "txtSRBalance", srBal);
                if(!arHide){
                  disable(thisObj, 'txtCollectAR')
                }
                if(!crHide){
                  disable(thisObj, 'txtCollectCR')
                }
                if(!srHide){
                  disable(thisObj, 'txtCollectSR')
                }
              }
              else {
                setValue(thisObj, "txtARBalance", "0.00");
                setValue(thisObj, "txtSRBalance", "0.00");
                setValue(thisObj, "txtCRBalance", "0.00");
                disable(thisObj, "txtCollectAR");
                disable(thisObj, "txtCollectSR");
                disable(thisObj, "txtCollectCR");
              }
            });
          }
          else{
            setValue(thisObj, "txtARBalance", "0.00");
            setValue(thisObj, "txtSRBalance", "0.00");
            setValue(thisObj, "txtCRBalance", "0.00");
            disable(thisObj, "txtCollectAR");
            disable(thisObj, "txtCollectSR");
            disable(thisObj, "txtCollectCR");
          }
        } 
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:GetAccountDetails event:Click");
      }
      return false;
    }
    return true;
  }
  //End Method to get Account details

  // Start Method for binding Buying Points
  const ddSpoolLocationProf = async() => {
    try {
      let js = []
      js.push({ key: '', description: ' ' });
      let userid = useridFromLS();
      await ContractManagementService.RetrieveBuyingPointControlDetails('PN1100', '005', null, null, null, userid).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          setSpoolLocation(data[i].buy_pt_id);
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
          js.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddSpoolLocationProf: {
              ...current["ddSpoolLocationProf"],
              valueList: js

            }
          }
        })
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ddSpoolLocationProf event:Click");
      }
      return false;
    }
    return true;
  }
  // End for binding Buying Points

  // Start Method for binding ddChargeLocationprof
  const ddChargeLocationprof = () => {
    try {
      let js = []
      js.push({ key: '', description: ' ' });
      let userid = useridFromLS();
      ContractManagementService.RetrieveBuyingPointControlDetails('PN1100', null, null, null, null, userid).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
          js.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddChargeLocationprof: {
              ...current["ddChargeLocationprof"],
              valueList: js,
              Enabled: false
            }
          }
        }
        )
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:ddChargeLocationprof event:Click");
      }
      return false;
    }
    return true;
  }
  // End for binding ddChargeLocationprof

  // Start Method for Buying Point dropdown change event
  const onddSpoolLocationProfChange = () => {
    try {
      ContractManagementService.RetrieveUserControlDetails(userid).then(response => {
        let userControlData = response;
        for (var i = 0; i < userControlData.length; i++) {
          setBuyptId(userControlData[i].buyPtId);
          let buyingPointId = userControlData[i].buyPtId
          //Bind premium code based on Buying Point 
          filterPremiumCodeList(buyingPointId);
          GetAccountDetails()
        }
      });
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Spool Location dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddSpoolLocationProfChange = onddSpoolLocationProfChange;
  // End Method for Buying Point dropdown change event

  // // Start Method for Buying Point dropdown change event
  // const onddPayeeProfChange = () => {
  //   try {
  //     GetAccountDetails();
  //   }
  //   catch (err) {
  //     if (err instanceof EvalError) {
  //       showMessage(thisObj, err.message);
  //     } else {
  //       showMessage(
  //         thisObj,
  //         "Error occurred during Payee Vendor dropdown change event"
  //       );
  //     }
  //     return false;
  //   }
  //   return true;
  // };
  // thisObj.onddPayeeProfChange = onddPayeeProfChange;
  // // End Method for Buying Point dropdown change event

  // Start Method for Item code dropdown change event
  const onddItemCodeprofChange = () => {
    try {
      let itemCode = thisObj.values.ddItemCodeprof;
      if (itemCode == "SP") {
        document.getElementsByClassName("ddSeedGenerationprof")[0].style.color = "red";
      }
      else {
        document.getElementsByClassName("ddSeedGenerationprof")[0].style.color = "#566573";
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Item code dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddItemCodeprofChange = onddItemCodeprofChange;
  // End Method for Item code dropdown change event

  // Start Method for binding Premium Code dropdown called in page load
  const PremiumCodeListDetails = () => {
    try {
      let js = [];
      let res = []
      let pd_code = null
      let inspect_ind = null
      let rate_type = null
      let check_enable_ind = 'Y'
      let rcpt_enable_ind = null
      let rpt_1099_ind = null
      let except_prem_ind = null
      ContractManagementService.RetrivePremiumDeductionScheduleDetails(userBuyPtId, pd_code, inspect_ind, rate_type, check_enable_ind, rcpt_enable_ind, rpt_1099_ind, except_prem_ind).then(response => {
        let data = response
        setData(thisObj, "lstPremiumDeductionSchDetail", response)
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pdCode, description: data[i].pdDesc.trim() }
          if (!res.includes(data[i].pdCode)) {
            js.push(obj)
            res.push(data[i].pdCode)
          }
        }
      });
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:PremiumCodeListDetails event:Click");
      }
      return false;
    }
    return true;
  }
  // End Method for binding Premium Code dropdown called in page load

  // Start Method for binding Premium Code dropdown based on buying points called in SpoolLocation change event
  function filterPremiumCodeList(buyingPointId) {
    try {
      let dataList = getData(thisObj, "lstPremiumDeductionSchDetail")
      var res = []
      res.push({ key: '', description: ' ' })
      if (dataList !== undefined && dataList !== null && dataList !== "") {
        for (var i = 0; i < dataList.length; i++) {
          if (dataList[i].buyPtId == buyingPointId) {
            var obj = { key: dataList[i].pdCode, description: dataList[i].pdDesc.trim() }
            res.push(obj)
          }
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddItemCodeprof: {
            ...current["ddItemCodeprof"],
            valueList: res,
            Enabled: false
          }
        }
      })
      if(!isEnabled(thisObj, 'btnAdd')){ // Item code was always disabled, even after selecting the Spool Location, after we click in ADD button.
        enable(thisObj, 'ddItemCodeprof')
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:filterPremiumCodeList event:Click");
      }
      return false;
    }
    return true;
  }
  // End Method for binding Premium Code dropdown based on buying points called in SpoolLocation change event

  // Start Method for getting Vendor Lookup
  const bFillRemitToList = (VendorNumber) => {
    try {
      if (VendorNumber !== undefined || VendorNumber !== null) {
        GetAccountDetails();
        ContractManagementService.RetieveRemitDetails(VendorNumber).then(response => {
          let data = response;
          if (data.length > 0) {
            let js = [];
            for (var i = 0; i < data.length; i++) {
              if (data[0].status !== "A" || data[0].grain_code !== "C") {
                showMessage(thisObj, "Vendor either does not have Active Status or correct Grain Code")
              }
              setremit(data[i].remittoid);
              setname(data[i].name);
              setcity(data[i].city);
              setstate(data[i].state);
              let description = data[i].remittoid + '-' + data[i].name + '-' + data[i].city + ',' + data[i].state;
              let obj = { key: data[i].remittoid, description: description };
              js.push(obj);
            }
            thisObj.setState(current => {
              return {
                ...current,
                ddPayeeProf: {
                  ...current["ddPayeeProf"],
                  valueList: js
                },
              }
            });
            setValue(thisObj, 'ddPayeeProf', js.at(0).key);
            setData(thisObj, 'ddPayeeProf', js.at(0).key);
          }
        });
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:bFillRemitToList event:Click");
      }
      return false;
    }
    return true;
  };
  // End art Method for getting Vendor Lookup

  // Start Method for binding Peanut Type dropdown
  const bFillPeanutType = () => {
    try {
      let js = [];
      js.push({ key: '', description: ' ' }) //Set All as default value
      ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
          js.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddPeanutTypeprof: {
              ...current["ddPeanutTypeprof"],
              valueList: js,
              Enabled: false,
            }
          }
        })
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:bFillPeanutType event:Click");
      }
      return false;
    }
    return true;
  }
  // End Method for binding Peanut Type dropdown

  // Start Method for binding Generation list
  const bFillSeedGeneration = () => {
    try {
      let js = [];
      js.push({ key: '', description: '' })
      js.push({ key: 'F', description: 'Foundation' })
      js.push({ key: 'R', description: 'Registered' })
      js.push({ key: 'C', description: 'Certified' })
      thisObj.setState(current => {
        return {
          ...current,
          ddSeedGenerationprof: {
            ...current["ddSeedGenerationprof"],
            valueList: js,
            Enabled: false,
          }
        }
      })
      setValue(thisObj, 'ddSeedGenerationprof', js.at(0).key) // Always the second parameter was getting selected as default, where as field has to be empty.
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:bFillSeedGeneration event:Click");
      }
      return false;
    }
    return true;
  }
  // End Method for binding Generation list

  // Start Method for binding Oleic values
  const bFillOleicInd = () => {
    try {
      let js = []
      js.push({ key: '', description: '' })
      js.push({ key: 'H', description: 'High' })
      js.push({ key: 'M', description: 'Mid' })
      thisObj.setState(current => {
        return {
          ...current,
          ddOleic: {
            ...current["ddOleic"],
            valueList: js,
            Enabled: false,
          }
        }
      })
      setValue(thisObj, 'ddOleic', js.at(0).key); // Always the second parameter was getting selected as default, where as field has to be empty.
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:bFillOleicInd event:Click");
      }
      return false;
    }
    return true;
  }
  // End Method for binding Oleic values

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SpecialPayablesProfile*/}

              {/* END_USER_CODE-USER_BEFORE_SpecialPayablesProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSpcPayableProfile1*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSpcPayableProfile1*/}

              <GroupBoxWidget
                conf={state.grpbxSpcPayableProfile1}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddSpoolLocationProf*/}

                {/* END_USER_CODE-USER_BEFORE_ddSpoolLocationProf*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSpoolLocationProf}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSpoolLocationProf*/}

                {/* END_USER_CODE-USER_AFTER_ddSpoolLocation*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxPendingEdit*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPendingEdit*/}

                <GroupBoxWidget
                  conf={state.grpbxPendingEdit}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblPending*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPending*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPending}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPending*/}

                  {/* END_USER_CODE-USER_AFTER_lblPending*/}

                  {/* START_USER_CODE-USER_BEFORE_lblPending*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPending*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblEdite}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPending*/}

                  {/* END_USER_CODE-USER_AFTER_lblPending*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPending*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPending*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAdd}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPending*/}

                  {/* END_USER_CODE-USER_AFTER_lblPending*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPendingEdit*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPendingEdit*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_ddPayeeProf*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPayeeProf}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPayeeProf*/}

                {/* END_USER_CODE-USER_AFTER_ddPayeeProf*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndrName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_BEFORE_ddPayeeProf*/}
                {/* START_USER_CODE-USER_BEFORE_txtMemo*/}

                {/* END_USER_CODE-USER_BEFORE_txtMemo*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMemo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>

                {/* START_USER_CODE-USER_AFTER_txtMemoValue*/}

                {/* END_USER_CODE-USER_AFTER_txtMemoValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSpcPayableProfile1*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSpcPayableProfile1*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxSpcPayableProfile2*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSpcPayableProfile2*/}

              <GroupBoxWidget
                conf={state.grpbxSpcPayableProfile2}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddItemCodeprof*/}

                {/* END_USER_CODE-USER_BEFORE_ddItemCodeprof*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddItemCodeprof}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddItemCodeprof*/}

                {/* END_USER_CODE-USER_AFTER_ddItemCodeprof*/}
                {/* START_USER_CODE-USER_BEFORE_txtDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtDate*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtDate*/}

                {/* END_USER_CODE-USER_AFTER_txtDate*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutTypeprof*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutTypeprof*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutTypeprof}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutTypeprof*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutTypeprof*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeedGenerationprof*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeedGenerationprof*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeedGenerationprof}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeedGenerationprof*/}

                {/* END_USER_CODE-USER_AFTER_ddSeedGenerationprof*/}
                {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleic}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                {/* START_USER_CODE-USER_BEFORE_txtPayAmountprof*/}

                {/* END_USER_CODE-USER_BEFORE_txtPayAmountprof*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPayAmountprof}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPayAmountprof*/}

                {/* END_USER_CODE-USER_AFTER_txtPayAmountprof*/}
                {/* START_USER_CODE-USER_BEFORE_ddChargeLocationprof*/}

                {/* END_USER_CODE-USER_BEFORE_ddChargeLocationprof*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddChargeLocationprof}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddChargeLocationprof*/}

                {/* END_USER_CODE-USER_AFTER_ddChargeLocationprof*/}
                {/* START_USER_CODE-USER_BEFORE_txtInvoiceNumber*/}

                {/* END_USER_CODE-USER_BEFORE_txtInvoiceNumber*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInvoiceNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtInvoiceNumber*/}

                {/* END_USER_CODE-USER_AFTER_txtInvoiceNumber*/}
                {/* START_USER_CODE-USER_BEFORE_txtComment*/}

                {/* END_USER_CODE-USER_BEFORE_txtComment*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtComment}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtComment*/}

                {/* END_USER_CODE-USER_AFTER_txtComment*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSpcPayableProfile2*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSpcPayableProfile2*/}
              {/* START_USER_CODE-USER_BEFORE_gridSpecialPaymentEntry*/}

              {/* END_USER_CODE-USER_BEFORE_gridSpecialPaymentEntry*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GridWidget
                conf={state.gridSpecialPaymentEntry}
                screenConf={state}
                linkClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                errors={errors}
                touched={touched}
                rows={values.gridSpecialPaymentEntry}
                onEvent={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
              ></GridWidget>
              {/* START_USER_CODE-USER_AFTER_gridSpecialPaymentEntry*/}

              {/* END_USER_CODE-USER_AFTER_gridSpecialPaymentEntry*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxremarks*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxremarks*/}

              <GroupBoxWidget conf={state.grpbxremarks} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtRemarks*/}

                {/* END_USER_CODE-USER_BEFORE_txtRemarks*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRemarks}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtRemarks*/}

                {/* END_USER_CODE-USER_AFTER_txtRemarks*/}
                {/* START_USER_CODE-USER_BEFORE_lblRemarksComment*/}

                {/* END_USER_CODE-USER_BEFORE_lblRemarksComment*/}

                <LabelWidget
                  values={values}
                  conf={state.lblRemarksComment}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblRemarksComment*/}

                {/* END_USER_CODE-USER_AFTER_lblRemarksComment*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxremarks*/}

              {/* END_USER_CODE-USER_AFTER_grpbxremarks*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxCheckInfo*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCheckInfo*/}

              <GroupBoxWidget conf={state.grpbxCheckInfo} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtCheck*/}

                {/* END_USER_CODE-USER_BEFORE_txtCheck*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheck}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCheck*/}

                {/* END_USER_CODE-USER_AFTER_txtCheck*/}
                {/* START_USER_CODE-USER_BEFORE_txtEndCheckNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtEndCheckNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEndCheckNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtEndCheckNum*/}

                {/* END_USER_CODE-USER_AFTER_txtEndCheckNum*/}

                {/* START_USER_CODE-USER_BEFORE_txtValOfRTP*/}

                {/* END_USER_CODE-USER_BEFORE_txtValOfRTP*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValOfRTP}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtValOfRTP*/}

                {/* END_USER_CODE-USER_AFTER_txtValOfRTP*/}
                {/* START_USER_CODE-USER_BEFORE_txtCollectSR*/}

                {/* END_USER_CODE-USER_BEFORE_txtCollectSR*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCollectSR}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCollectSR*/}

                {/* END_USER_CODE-USER_AFTER_txtCollectSR*/}
                {/* START_USER_CODE-USER_BEFORE_txtSRBalance*/}

                {/* END_USER_CODE-USER_BEFORE_txtSRBalance*/}


                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSRBalance}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSRBalance*/}

                {/* END_USER_CODE-USER_AFTER_txtSRBalance*/}
                {/* START_USER_CODE-USER_BEFORE_txtCollectAR*/}

                {/* END_USER_CODE-USER_BEFORE_txtCollectAR*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCollectAR}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCollectAR*/}

                {/* END_USER_CODE-USER_AFTER_txtCollectAR*/}
                {/* START_USER_CODE-USER_BEFORE_txtARBalance*/}

                {/* END_USER_CODE-USER_BEFORE_txtARBalance*/}


                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtARBalance}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtARBalance*/}

                {/* END_USER_CODE-USER_AFTER_txtARBalance*/}
                {/* START_USER_CODE-USER_BEFORE_txtCollectCR*/}

                {/* END_USER_CODE-USER_BEFORE_txtCollectCR*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCollectCR}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCollectCR*/}

                {/* END_USER_CODE-USER_AFTER_txtCollectCR*/}
                {/* START_USER_CODE-USER_BEFORE_txtCRBalance*/}

                {/* END_USER_CODE-USER_BEFORE_txtCRBalance*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCRBalance}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCRBalance*/}

                {/* END_USER_CODE-USER_AFTER_txtCRBalance*/}

                {/* START_USER_CODE-USER_BEFORE_txtValueOfCheck*/}

                {/* END_USER_CODE-USER_BEFORE_txtValueOfCheck*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValueOfCheck}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtValueOfCheck*/}

                {/* END_USER_CODE-USER_AFTER_txtValueOfCheck*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCheckInfo*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCheckInfo*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnScanDocuments*/}

                {/* END_USER_CODE-USER_BEFORE_btnScanDocuments*/}

                <ButtonWidget
                  conf={state.btnScanDocuments}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnScanDocuments*/}

                {/* END_USER_CODE-USER_AFTER_btnScanDocuments*/}
                {/* START_USER_CODE-USER_BEFORE_btnViewDocuments*/}

                {/* END_USER_CODE-USER_BEFORE_btnViewDocuments*/}

                <ButtonWidget
                  conf={state.btnViewDocuments}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnViewDocuments*/}

                {/* END_USER_CODE-USER_AFTER_btnViewDocuments*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreateHoldPay*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreateHoldPay*/}

                <ButtonWidget
                  conf={state.btnCreateHoldPay}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreateHoldPay*/}

                {/* END_USER_CODE-USER_AFTER_btnCreateHoldPay*/}
                {/* START_USER_CODE-USER_BEFORE_btnSaveToBatch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSaveToBatch*/}

                <ButtonWidget
                  conf={state.btnSaveToBatch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSaveToBatch*/}

                {/* END_USER_CODE-USER_AFTER_btnSaveToBatch*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintChecks*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintChecks*/}

                <ButtonWidget
                  conf={state.btnPrintChecks}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintChecks*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintChecks*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_lblCropYear2*/}

                {/* END_USER_CODE-USER_BEFORE_lblCropYear2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCropYear2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCropYear2*/}

                {/* END_USER_CODE-USER_AFTER_lblCropYear2*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_SpecialPayablesProfile*/}

              {/* END_USER_CODE-USER_AFTER_SpecialPayablesProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(AccountPayment_SpecialPayablesProfile);

