/* eslint-disable*/
import React from "react";
import ContractManagement_ContractGroupingSearch from "./ContractGroupingSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ContractGroupingSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractGroupingSearch />);
    });
  });
  afterEach(cleanup);
  test("is ContractGroupingSearch Loads Successfully", () => {
    expect(screen.getByText("Contract Grouping Search")).toBeInTheDocument;
  });
  test("Custom Test Cases for ContractGroupingSearch", () => {
    // START_USER_CODE-USER_ContractGroupingSearch_Custom_Test_Case
    // END_USER_CODE-USER_ContractGroupingSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_ContractGroupingSearch />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("ContractManagement:ContractGroupingSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCrtContrctGrpSrch(Button Widget) Test Cases", async () => {
    const btnCrtContrctGrpSrch = screen.getByTestId("btnCrtContrctGrpSrch");
    expect(btnCrtContrctGrpSrch).toBeInTheDocument;
    expect(btnCrtContrctGrpSrch.textContent).toEqual(
      t("ContractManagement:ContractGroupingSearch_btnCrtContrctGrpSrch")
    );
  });
  test("Custom Test Cases for btnCrtContrctGrpSrch", () => {
    // START_USER_CODE-USER_btnCrtContrctGrpSrch_TEST
    // END_USER_CODE-USER_btnCrtContrctGrpSrch_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("ContractManagement:ContractGroupingSearch_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnPopUp(Button Widget) Test Cases", async () => {
    const btnPopUp = screen.getByTestId("btnPopUp");
    expect(btnPopUp).toBeInTheDocument;
    expect(btnPopUp.textContent).toEqual(
      t("ContractManagement:ContractGroupingSearch_btnPopUp")
    );
  });
  test("Custom Test Cases for btnPopUp", () => {
    // START_USER_CODE-USER_btnPopUp_TEST
    // END_USER_CODE-USER_btnPopUp_TEST
  });
  test("btnSrch(Button Widget) Test Cases", async () => {
    const btnSrch = screen.getByTestId("btnSrch");
    expect(btnSrch).toBeInTheDocument;
    expect(btnSrch.textContent).toEqual(
      t("ContractManagement:ContractGroupingSearch_btnSrch")
    );
  });
  test("Custom Test Cases for btnSrch", () => {
    // START_USER_CODE-USER_btnSrch_TEST
    // END_USER_CODE-USER_btnSrch_TEST
  });
  test("grpbxClose(GroupBox Widget) Test Cases", async () => {
    const grpbxClose = screen.getByTestId("grpbxClose");
    expect(grpbxClose.tagName).toBe("BUTTON");
    expect(grpbxClose.type).toBe("button");
    expect(grpbxClose.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxClose", () => {
    // START_USER_CODE-USER_grpbxClose_TEST
    // END_USER_CODE-USER_grpbxClose_TEST
  });
  test("grpbxCntctGrpSrch(GroupBox Widget) Test Cases", async () => {
    const grpbxCntctGrpSrch = screen.getByTestId("grpbxCntctGrpSrch");
    expect(grpbxCntctGrpSrch.tagName).toBe("BUTTON");
    expect(grpbxCntctGrpSrch.type).toBe("button");
    expect(grpbxCntctGrpSrch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCntctGrpSrch", () => {
    // START_USER_CODE-USER_grpbxCntctGrpSrch_TEST
    // END_USER_CODE-USER_grpbxCntctGrpSrch_TEST
  });
  test("txtContrct(Textbox Widget) Test Cases", async () => {
    const txtContrct = screen.getByTestId("txtContrct");
    expect(txtContrct.tagName).toBe("INPUT");
    expect(txtContrct.type).toBe("text");
    expect(txtContrct.classList).toContain("textboxWidgetClass");
    expect(txtContrct.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractGroupingSearch_txtContrct")
    );
    await act(async () => {
      userEvent.type(txtContrct, "1");
    });
  });
  test("Custom Test Cases for txtContrct", () => {
    // START_USER_CODE-USER_txtContrct_TEST
    // END_USER_CODE-USER_txtContrct_TEST
  });
  test("txtGrpng(Textbox Widget) Test Cases", async () => {
    const txtGrpng = screen.getByTestId("txtGrpng");
    expect(txtGrpng.tagName).toBe("INPUT");
    expect(txtGrpng.type).toBe("text");
    expect(txtGrpng.classList).toContain("textboxWidgetClass");
    expect(txtGrpng.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractGroupingSearch_txtGrpng")
    );
    await act(async () => {
      userEvent.type(txtGrpng, "1");
    });
  });
  test("Custom Test Cases for txtGrpng", () => {
    // START_USER_CODE-USER_txtGrpng_TEST
    // END_USER_CODE-USER_txtGrpng_TEST
  });
  test("txtVndr(Textbox Widget) Test Cases", async () => {
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    expect(txtVndr.previousElementSibling.textContent).toEqual(
      t("ContractManagement:ContractGroupingSearch_txtVndr")
    );
    await act(async () => {
      userEvent.type(txtVndr, "1");
    });
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
});
