/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FarmGovData from "./FarmGovData";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FarmGovData Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FarmGovData />);
    });
  });
  afterEach(cleanup);
  test("is FarmGovData Loads Successfully", () => {
    expect(screen.getByText("FarmGovData")).toBeInTheDocument;
  });
  test("Custom Test Cases for FarmGovData", () => {
    // START_USER_CODE-USER_FarmGovData_Custom_Test_Case
    // END_USER_CODE-USER_FarmGovData_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceMasterManagement_FarmGovData />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmGovData_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmGovData_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnCopyData(Button Widget) Test Cases", async () => {
    const btnCopyData = screen.getByTestId("btnCopyData");
    expect(btnCopyData).toBeInTheDocument;
    expect(btnCopyData.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmGovData_btnCopyData")
    );
  });
  test("Custom Test Cases for btnCopyData", () => {
    // START_USER_CODE-USER_btnCopyData_TEST
    // END_USER_CODE-USER_btnCopyData_TEST
  });
  test("btnExportCounties(Button Widget) Test Cases", async () => {
    const btnExportCounties = screen.getByTestId("btnExportCounties");
    expect(btnExportCounties).toBeInTheDocument;
    expect(btnExportCounties.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmGovData_btnExportCounties")
    );
  });
  test("Custom Test Cases for btnExportCounties", () => {
    // START_USER_CODE-USER_btnExportCounties_TEST
    // END_USER_CODE-USER_btnExportCounties_TEST
  });
  test("btnExportFarms(Button Widget) Test Cases", async () => {
    const btnExportFarms = screen.getByTestId("btnExportFarms");
    expect(btnExportFarms).toBeInTheDocument;
    expect(btnExportFarms.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FarmGovData_btnExportFarms")
    );
  });
  test("Custom Test Cases for btnExportFarms", () => {
    // START_USER_CODE-USER_btnExportFarms_TEST
    // END_USER_CODE-USER_btnExportFarms_TEST
  });
  test("grpbxFarmGovData(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmGovData = screen.getByTestId("grpbxFarmGovData");
    expect(grpbxFarmGovData.tagName).toBe("BUTTON");
    expect(grpbxFarmGovData.type).toBe("button");
    expect(grpbxFarmGovData.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmGovData", () => {
    // START_USER_CODE-USER_grpbxFarmGovData_TEST
    // END_USER_CODE-USER_grpbxFarmGovData_TEST
  });
  test("txtBuyingPointID(Textbox Widget) Test Cases", async () => {
    const txtBuyingPointID = screen.getByTestId("txtBuyingPointID");
    expect(txtBuyingPointID.tagName).toBe("INPUT");
    expect(txtBuyingPointID.type).toBe("text");
    expect(txtBuyingPointID.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtBuyingPointID, "1");
    });
  });
  test("Custom Test Cases for txtBuyingPointID", () => {
    // START_USER_CODE-USER_txtBuyingPointID_TEST
    // END_USER_CODE-USER_txtBuyingPointID_TEST
  });
});
