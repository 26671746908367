/* eslint-disable*/
import React from "react";
import StockTransfer_StockTransfer from "./StockTransfer";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("StockTransfer Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_StockTransfer />);
    });
  });
  afterEach(cleanup);
  test("is StockTransfer Loads Successfully", () => {
    expect(screen.getByText("TransferSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for StockTransfer", () => {
    // START_USER_CODE-USER_StockTransfer_Custom_Test_Case
    // END_USER_CODE-USER_StockTransfer_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<StockTransfer_StockTransfer />);
    });
  });
  afterEach(cleanup);
  test("btnAcctDist(Button Widget) Test Cases", async () => {
    const btnAcctDist = screen.getByTestId("btnAcctDist");
    expect(btnAcctDist).toBeInTheDocument;
    expect(btnAcctDist.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnAcctDist")
    );
  });
  test("Custom Test Cases for btnAcctDist", () => {
    // START_USER_CODE-USER_btnAcctDist_TEST
    // END_USER_CODE-USER_btnAcctDist_TEST
  });
  test("btnArrow(Button Widget) Test Cases", async () => {
    const btnArrow = screen.getByTestId("btnArrow");
    expect(btnArrow).toBeInTheDocument;
    expect(btnArrow.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnArrow")
    );
  });
  test("Custom Test Cases for btnArrow", () => {
    // START_USER_CODE-USER_btnArrow_TEST
    // END_USER_CODE-USER_btnArrow_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDollarVal(Button Widget) Test Cases", async () => {
    const btnDollarVal = screen.getByTestId("btnDollarVal");
    expect(btnDollarVal).toBeInTheDocument;
    expect(btnDollarVal.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnDollarVal")
    );
  });
  test("Custom Test Cases for btnDollarVal", () => {
    // START_USER_CODE-USER_btnDollarVal_TEST
    // END_USER_CODE-USER_btnDollarVal_TEST
  });
  test("btnFreightAcc(Button Widget) Test Cases", async () => {
    const btnFreightAcc = screen.getByTestId("btnFreightAcc");
    expect(btnFreightAcc).toBeInTheDocument;
    expect(btnFreightAcc.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnFreightAcc")
    );
  });
  test("Custom Test Cases for btnFreightAcc", () => {
    // START_USER_CODE-USER_btnFreightAcc_TEST
    // END_USER_CODE-USER_btnFreightAcc_TEST
  });
  test("btnFrmBin(Button Widget) Test Cases", async () => {
    const btnFrmBin = screen.getByTestId("btnFrmBin");
    expect(btnFrmBin).toBeInTheDocument;
    expect(btnFrmBin.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnFrmBin")
    );
  });
  test("Custom Test Cases for btnFrmBin", () => {
    // START_USER_CODE-USER_btnFrmBin_TEST
    // END_USER_CODE-USER_btnFrmBin_TEST
  });
  test("btnNewTrnsfr(Button Widget) Test Cases", async () => {
    const btnNewTrnsfr = screen.getByTestId("btnNewTrnsfr");
    expect(btnNewTrnsfr).toBeInTheDocument;
    expect(btnNewTrnsfr.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnNewTrnsfr")
    );
  });
  test("Custom Test Cases for btnNewTrnsfr", () => {
    // START_USER_CODE-USER_btnNewTrnsfr_TEST
    // END_USER_CODE-USER_btnNewTrnsfr_TEST
  });
  test("btnPayment(Button Widget) Test Cases", async () => {
    const btnPayment = screen.getByTestId("btnPayment");
    expect(btnPayment).toBeInTheDocument;
    expect(btnPayment.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnPayment")
    );
  });
  test("Custom Test Cases for btnPayment", () => {
    // START_USER_CODE-USER_btnPayment_TEST
    // END_USER_CODE-USER_btnPayment_TEST
  });
  test("btnPopup(Button Widget) Test Cases", async () => {
    const btnPopup = screen.getByTestId("btnPopup");
    expect(btnPopup).toBeInTheDocument;
    expect(btnPopup.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnPopup")
    );
  });
  test("Custom Test Cases for btnPopup", () => {
    // START_USER_CODE-USER_btnPopup_TEST
    // END_USER_CODE-USER_btnPopup_TEST
  });
  test("btnPremDeduct(Button Widget) Test Cases", async () => {
    const btnPremDeduct = screen.getByTestId("btnPremDeduct");
    expect(btnPremDeduct).toBeInTheDocument;
    expect(btnPremDeduct.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnPremDeduct")
    );
  });
  test("Custom Test Cases for btnPremDeduct", () => {
    // START_USER_CODE-USER_btnPremDeduct_TEST
    // END_USER_CODE-USER_btnPremDeduct_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnSave")
    );
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("btnToBin(Button Widget) Test Cases", async () => {
    const btnToBin = screen.getByTestId("btnToBin");
    expect(btnToBin).toBeInTheDocument;
    expect(btnToBin.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnToBin")
    );
  });
  test("Custom Test Cases for btnToBin", () => {
    // START_USER_CODE-USER_btnToBin_TEST
    // END_USER_CODE-USER_btnToBin_TEST
  });
  test("btnVoid(Button Widget) Test Cases", async () => {
    const btnVoid = screen.getByTestId("btnVoid");
    expect(btnVoid).toBeInTheDocument;
    expect(btnVoid.textContent).toEqual(
      t("StockTransfer:StockTransfer_btnVoid")
    );
  });
  test("Custom Test Cases for btnVoid", () => {
    // START_USER_CODE-USER_btnVoid_TEST
    // END_USER_CODE-USER_btnVoid_TEST
  });
  test("dtRcvdDate(Date Widget) Test Cases", async () => {
    const dtRcvdDate = screen.getByTestId("dtRcvdDate");
    expect(dtRcvdDate.tagName).toBe("INPUT");
    expect(dtRcvdDate.type).toBe("text");
    expect(dtRcvdDate.classList).toContain("datetimepicker-input");
    expect(dtRcvdDate.parentElement.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_dtRcvdDate")
    );
    await act(async () => {
      userEvent.click(dtRcvdDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtRcvdDate", () => {
    // START_USER_CODE-USER_dtRcvdDate_TEST
    // END_USER_CODE-USER_dtRcvdDate_TEST
  });
  test("dtShipDate(Date Widget) Test Cases", async () => {
    const dtShipDate = screen.getByTestId("dtShipDate");
    expect(dtShipDate.tagName).toBe("INPUT");
    expect(dtShipDate.type).toBe("text");
    expect(dtShipDate.classList).toContain("datetimepicker-input");
    expect(dtShipDate.parentElement.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_dtShipDate")
    );
    await act(async () => {
      userEvent.click(dtShipDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtShipDate", () => {
    // START_USER_CODE-USER_dtShipDate_TEST
    // END_USER_CODE-USER_dtShipDate_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxBtns(GroupBox Widget) Test Cases", async () => {
    const grpbxBtns = screen.getByTestId("grpbxBtns");
    expect(grpbxBtns.tagName).toBe("BUTTON");
    expect(grpbxBtns.type).toBe("button");
    expect(grpbxBtns.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBtns", () => {
    // START_USER_CODE-USER_grpbxBtns_TEST
    // END_USER_CODE-USER_grpbxBtns_TEST
  });
  test("grpbxCheckbxViacam(GroupBox Widget) Test Cases", async () => {
    const grpbxCheckbxViacam = screen.getByTestId("grpbxCheckbxViacam");
    expect(grpbxCheckbxViacam.tagName).toBe("BUTTON");
    expect(grpbxCheckbxViacam.type).toBe("button");
    expect(grpbxCheckbxViacam.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCheckbxViacam", () => {
    // START_USER_CODE-USER_grpbxCheckbxViacam_TEST
    // END_USER_CODE-USER_grpbxCheckbxViacam_TEST
  });
  test("grpbxDamage(GroupBox Widget) Test Cases", async () => {
    const grpbxDamage = screen.getByTestId("grpbxDamage");
    expect(grpbxDamage.tagName).toBe("BUTTON");
    expect(grpbxDamage.type).toBe("button");
    expect(grpbxDamage.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDamage", () => {
    // START_USER_CODE-USER_grpbxDamage_TEST
    // END_USER_CODE-USER_grpbxDamage_TEST
  });
  test("grpbxFarmFreight(GroupBox Widget) Test Cases", async () => {
    const grpbxFarmFreight = screen.getByTestId("grpbxFarmFreight");
    expect(grpbxFarmFreight.tagName).toBe("BUTTON");
    expect(grpbxFarmFreight.type).toBe("button");
    expect(grpbxFarmFreight.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFarmFreight", () => {
    // START_USER_CODE-USER_grpbxFarmFreight_TEST
    // END_USER_CODE-USER_grpbxFarmFreight_TEST
  });
  test("grpbxFreight(GroupBox Widget) Test Cases", async () => {
    const grpbxFreight = screen.getByTestId("grpbxFreight");
    expect(grpbxFreight.tagName).toBe("BUTTON");
    expect(grpbxFreight.type).toBe("button");
    expect(grpbxFreight.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFreight", () => {
    // START_USER_CODE-USER_grpbxFreight_TEST
    // END_USER_CODE-USER_grpbxFreight_TEST
  });
  test("grpbxFreightAcc(GroupBox Widget) Test Cases", async () => {
    const grpbxFreightAcc = screen.getByTestId("grpbxFreightAcc");
    expect(grpbxFreightAcc.tagName).toBe("BUTTON");
    expect(grpbxFreightAcc.type).toBe("button");
    expect(grpbxFreightAcc.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFreightAcc", () => {
    // START_USER_CODE-USER_grpbxFreightAcc_TEST
    // END_USER_CODE-USER_grpbxFreightAcc_TEST
  });
  test("grpbxFromToBin(GroupBox Widget) Test Cases", async () => {
    const grpbxFromToBin = screen.getByTestId("grpbxFromToBin");
    expect(grpbxFromToBin.tagName).toBe("BUTTON");
    expect(grpbxFromToBin.type).toBe("button");
    expect(grpbxFromToBin.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFromToBin", () => {
    // START_USER_CODE-USER_grpbxFromToBin_TEST
    // END_USER_CODE-USER_grpbxFromToBin_TEST
  });
  test("grpbxGridCorn(GroupBox Widget) Test Cases", async () => {
    const grpbxGridCorn = screen.getByTestId("grpbxGridCorn");
    expect(grpbxGridCorn.tagName).toBe("BUTTON");
    expect(grpbxGridCorn.type).toBe("button");
    expect(grpbxGridCorn.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGridCorn", () => {
    // START_USER_CODE-USER_grpbxGridCorn_TEST
    // END_USER_CODE-USER_grpbxGridCorn_TEST
  });
  test("grpbxLoad(GroupBox Widget) Test Cases", async () => {
    const grpbxLoad = screen.getByTestId("grpbxLoad");
    expect(grpbxLoad.tagName).toBe("BUTTON");
    expect(grpbxLoad.type).toBe("button");
    expect(grpbxLoad.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLoad", () => {
    // START_USER_CODE-USER_grpbxLoad_TEST
    // END_USER_CODE-USER_grpbxLoad_TEST
  });
  test("grpbxLoadShippedRcvd(GroupBox Widget) Test Cases", async () => {
    const grpbxLoadShippedRcvd = screen.getByTestId("grpbxLoadShippedRcvd");
    expect(grpbxLoadShippedRcvd.tagName).toBe("BUTTON");
    expect(grpbxLoadShippedRcvd.type).toBe("button");
    expect(grpbxLoadShippedRcvd.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLoadShippedRcvd", () => {
    // START_USER_CODE-USER_grpbxLoadShippedRcvd_TEST
    // END_USER_CODE-USER_grpbxLoadShippedRcvd_TEST
  });
  test("grpbxLocation(GroupBox Widget) Test Cases", async () => {
    const grpbxLocation = screen.getByTestId("grpbxLocation");
    expect(grpbxLocation.tagName).toBe("BUTTON");
    expect(grpbxLocation.type).toBe("button");
    expect(grpbxLocation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLocation", () => {
    // START_USER_CODE-USER_grpbxLocation_TEST
    // END_USER_CODE-USER_grpbxLocation_TEST
  });
  test("grpbxMid(GroupBox Widget) Test Cases", async () => {
    const grpbxMid = screen.getByTestId("grpbxMid");
    expect(grpbxMid.tagName).toBe("BUTTON");
    expect(grpbxMid.type).toBe("button");
    expect(grpbxMid.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxMid", () => {
    // START_USER_CODE-USER_grpbxMid_TEST
    // END_USER_CODE-USER_grpbxMid_TEST
  });
  test("grpbxNewPending(GroupBox Widget) Test Cases", async () => {
    const grpbxNewPending = screen.getByTestId("grpbxNewPending");
    expect(grpbxNewPending.tagName).toBe("BUTTON");
    expect(grpbxNewPending.type).toBe("button");
    expect(grpbxNewPending.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxNewPending", () => {
    // START_USER_CODE-USER_grpbxNewPending_TEST
    // END_USER_CODE-USER_grpbxNewPending_TEST
  });
  test("grpbxRecvd(GroupBox Widget) Test Cases", async () => {
    const grpbxRecvd = screen.getByTestId("grpbxRecvd");
    expect(grpbxRecvd.tagName).toBe("BUTTON");
    expect(grpbxRecvd.type).toBe("button");
    expect(grpbxRecvd.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRecvd", () => {
    // START_USER_CODE-USER_grpbxRecvd_TEST
    // END_USER_CODE-USER_grpbxRecvd_TEST
  });
  test("grpbxRegrading(GroupBox Widget) Test Cases", async () => {
    const grpbxRegrading = screen.getByTestId("grpbxRegrading");
    expect(grpbxRegrading.tagName).toBe("BUTTON");
    expect(grpbxRegrading.type).toBe("button");
    expect(grpbxRegrading.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRegrading", () => {
    // START_USER_CODE-USER_grpbxRegrading_TEST
    // END_USER_CODE-USER_grpbxRegrading_TEST
  });
  test("grpbxReGradinguic0(GroupBox Widget) Test Cases", async () => {
    const grpbxReGradinguic0 = screen.getByTestId("grpbxReGradinguic0");
    expect(grpbxReGradinguic0.tagName).toBe("BUTTON");
    expect(grpbxReGradinguic0.type).toBe("button");
    expect(grpbxReGradinguic0.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReGradinguic0", () => {
    // START_USER_CODE-USER_grpbxReGradinguic0_TEST
    // END_USER_CODE-USER_grpbxReGradinguic0_TEST
  });
  test("grpbxRegrd(GroupBox Widget) Test Cases", async () => {
    const grpbxRegrd = screen.getByTestId("grpbxRegrd");
    expect(grpbxRegrd.tagName).toBe("BUTTON");
    expect(grpbxRegrd.type).toBe("button");
    expect(grpbxRegrd.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRegrd", () => {
    // START_USER_CODE-USER_grpbxRegrd_TEST
    // END_USER_CODE-USER_grpbxRegrd_TEST
  });
  test("grpbxRegrdSettlement(GroupBox Widget) Test Cases", async () => {
    const grpbxRegrdSettlement = screen.getByTestId("grpbxRegrdSettlement");
    expect(grpbxRegrdSettlement.tagName).toBe("BUTTON");
    expect(grpbxRegrdSettlement.type).toBe("button");
    expect(grpbxRegrdSettlement.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRegrdSettlement", () => {
    // START_USER_CODE-USER_grpbxRegrdSettlement_TEST
    // END_USER_CODE-USER_grpbxRegrdSettlement_TEST
  });
  test("grpbxRemrks(GroupBox Widget) Test Cases", async () => {
    const grpbxRemrks = screen.getByTestId("grpbxRemrks");
    expect(grpbxRemrks.tagName).toBe("BUTTON");
    expect(grpbxRemrks.type).toBe("button");
    expect(grpbxRemrks.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxRemrks", () => {
    // START_USER_CODE-USER_grpbxRemrks_TEST
    // END_USER_CODE-USER_grpbxRemrks_TEST
  });
  test("grpbxSettlement(GroupBox Widget) Test Cases", async () => {
    const grpbxSettlement = screen.getByTestId("grpbxSettlement");
    expect(grpbxSettlement.tagName).toBe("BUTTON");
    expect(grpbxSettlement.type).toBe("button");
    expect(grpbxSettlement.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSettlement", () => {
    // START_USER_CODE-USER_grpbxSettlement_TEST
    // END_USER_CODE-USER_grpbxSettlement_TEST
  });
  test("grpbxSettlementFreight(GroupBox Widget) Test Cases", async () => {
    const grpbxSettlementFreight = screen.getByTestId("grpbxSettlementFreight");
    expect(grpbxSettlementFreight.tagName).toBe("BUTTON");
    expect(grpbxSettlementFreight.type).toBe("button");
    expect(grpbxSettlementFreight.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSettlementFreight", () => {
    // START_USER_CODE-USER_grpbxSettlementFreight_TEST
    // END_USER_CODE-USER_grpbxSettlementFreight_TEST
  });
  test("grpbxShippd(GroupBox Widget) Test Cases", async () => {
    const grpbxShippd = screen.getByTestId("grpbxShippd");
    expect(grpbxShippd.tagName).toBe("BUTTON");
    expect(grpbxShippd.type).toBe("button");
    expect(grpbxShippd.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxShippd", () => {
    // START_USER_CODE-USER_grpbxShippd_TEST
    // END_USER_CODE-USER_grpbxShippd_TEST
  });
  test("grpbxShippdFrmTo(GroupBox Widget) Test Cases", async () => {
    const grpbxShippdFrmTo = screen.getByTestId("grpbxShippdFrmTo");
    expect(grpbxShippdFrmTo.tagName).toBe("BUTTON");
    expect(grpbxShippdFrmTo.type).toBe("button");
    expect(grpbxShippdFrmTo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxShippdFrmTo", () => {
    // START_USER_CODE-USER_grpbxShippdFrmTo_TEST
    // END_USER_CODE-USER_grpbxShippdFrmTo_TEST
  });
  test("grpbxUpdateInvnt(GroupBox Widget) Test Cases", async () => {
    const grpbxUpdateInvnt = screen.getByTestId("grpbxUpdateInvnt");
    expect(grpbxUpdateInvnt.tagName).toBe("BUTTON");
    expect(grpbxUpdateInvnt.type).toBe("button");
    expect(grpbxUpdateInvnt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxUpdateInvnt", () => {
    // START_USER_CODE-USER_grpbxUpdateInvnt_TEST
    // END_USER_CODE-USER_grpbxUpdateInvnt_TEST
  });
  test("grpbxWtVariance(GroupBox Widget) Test Cases", async () => {
    const grpbxWtVariance = screen.getByTestId("grpbxWtVariance");
    expect(grpbxWtVariance.tagName).toBe("BUTTON");
    expect(grpbxWtVariance.type).toBe("button");
    expect(grpbxWtVariance.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWtVariance", () => {
    // START_USER_CODE-USER_grpbxWtVariance_TEST
    // END_USER_CODE-USER_grpbxWtVariance_TEST
  });
  test("lblAddedDate(Label Widget) Test Cases", async () => {
    const lblAddedDate = screen.getByTestId("lblAddedDate");
    expect(lblAddedDate.tagName).toBe("LABEL");
    expect(lblAddedDate.classList).toContain("form-label");
    expect(lblAddedDate.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblAddedDate")
    );
  });
  test("Custom Test Cases for lblAddedDate", () => {
    // START_USER_CODE-USER_lblAddedDate_TEST
    // END_USER_CODE-USER_lblAddedDate_TEST
  });
  test("lblChngdDate(Label Widget) Test Cases", async () => {
    const lblChngdDate = screen.getByTestId("lblChngdDate");
    expect(lblChngdDate.tagName).toBe("LABEL");
    expect(lblChngdDate.classList).toContain("form-label");
    expect(lblChngdDate.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblChngdDate")
    );
  });
  test("Custom Test Cases for lblChngdDate", () => {
    // START_USER_CODE-USER_lblChngdDate_TEST
    // END_USER_CODE-USER_lblChngdDate_TEST
  });
  test("lblDollar(Textbox Widget) Test Cases", async () => {
    const lblDollar = screen.getByTestId("lblDollar");
    expect(lblDollar.tagName).toBe("INPUT");
    expect(lblDollar.type).toBe("text");
    expect(lblDollar.classList).toContain("textboxWidgetClass");
    expect(lblDollar.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblDollar")
    );
    await act(async () => {
      userEvent.type(lblDollar, "1");
    });
  });
  test("Custom Test Cases for lblDollar", () => {
    // START_USER_CODE-USER_lblDollar_TEST
    // END_USER_CODE-USER_lblDollar_TEST
  });
  test("lblEdibleOil(Label Widget) Test Cases", async () => {
    const lblEdibleOil = screen.getByTestId("lblEdibleOil");
    expect(lblEdibleOil.tagName).toBe("LABEL");
    expect(lblEdibleOil.classList).toContain("form-label");
    expect(lblEdibleOil.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblEdibleOil")
    );
  });
  test("Custom Test Cases for lblEdibleOil", () => {
    // START_USER_CODE-USER_lblEdibleOil_TEST
    // END_USER_CODE-USER_lblEdibleOil_TEST
  });
  test("lblFarmFrgtInfo(Label Widget) Test Cases", async () => {
    const lblFarmFrgtInfo = screen.getByTestId("lblFarmFrgtInfo");
    expect(lblFarmFrgtInfo.tagName).toBe("LABEL");
    expect(lblFarmFrgtInfo.classList).toContain("form-label");
    expect(lblFarmFrgtInfo.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblFarmFrgtInfo")
    );
  });
  test("Custom Test Cases for lblFarmFrgtInfo", () => {
    // START_USER_CODE-USER_lblFarmFrgtInfo_TEST
    // END_USER_CODE-USER_lblFarmFrgtInfo_TEST
  });
  test("lblFm(Label Widget) Test Cases", async () => {
    const lblFm = screen.getByTestId("lblFm");
    expect(lblFm.tagName).toBe("LABEL");
    expect(lblFm.classList).toContain("form-label");
    expect(lblFm.textContent).toEqual(t("StockTransfer:StockTransfer_lblFm"));
  });
  test("Custom Test Cases for lblFm", () => {
    // START_USER_CODE-USER_lblFm_TEST
    // END_USER_CODE-USER_lblFm_TEST
  });
  test("lblFrghtAccrl(Label Widget) Test Cases", async () => {
    const lblFrghtAccrl = screen.getByTestId("lblFrghtAccrl");
    expect(lblFrghtAccrl.tagName).toBe("LABEL");
    expect(lblFrghtAccrl.classList).toContain("form-label");
    expect(lblFrghtAccrl.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblFrghtAccrl")
    );
  });
  test("Custom Test Cases for lblFrghtAccrl", () => {
    // START_USER_CODE-USER_lblFrghtAccrl_TEST
    // END_USER_CODE-USER_lblFrghtAccrl_TEST
  });
  test("lblFrghtAccrual(Label Widget) Test Cases", async () => {
    const lblFrghtAccrual = screen.getByTestId("lblFrghtAccrual");
    expect(lblFrghtAccrual.tagName).toBe("LABEL");
    expect(lblFrghtAccrual.classList).toContain("form-label");
    expect(lblFrghtAccrual.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblFrghtAccrual")
    );
  });
  test("Custom Test Cases for lblFrghtAccrual", () => {
    // START_USER_CODE-USER_lblFrghtAccrual_TEST
    // END_USER_CODE-USER_lblFrghtAccrual_TEST
  });
  test("lblg(Label Widget) Test Cases", async () => {
    const lblg = screen.getByTestId("lblg");
    expect(lblg.tagName).toBe("LABEL");
    expect(lblg.classList).toContain("form-label");
    expect(lblg.textContent).toEqual(t("StockTransfer:StockTransfer_lblg"));
  });
  test("Custom Test Cases for lblg", () => {
    // START_USER_CODE-USER_lblg_TEST
    // END_USER_CODE-USER_lblg_TEST
  });
  test("lblG(Label Widget) Test Cases", async () => {
    const lblG = screen.getByTestId("lblG");
    expect(lblG.tagName).toBe("LABEL");
    expect(lblG.classList).toContain("form-label");
    expect(lblG.textContent).toEqual(t("StockTransfer:StockTransfer_lblG"));
  });
  test("Custom Test Cases for lblG", () => {
    // START_USER_CODE-USER_lblG_TEST
    // END_USER_CODE-USER_lblG_TEST
  });
  test("lblGeneration(Label Widget) Test Cases", async () => {
    const lblGeneration = screen.getByTestId("lblGeneration");
    expect(lblGeneration.tagName).toBe("LABEL");
    expect(lblGeneration.classList).toContain("form-label");
    expect(lblGeneration.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblGeneration")
    );
  });
  test("Custom Test Cases for lblGeneration", () => {
    // START_USER_CODE-USER_lblGeneration_TEST
    // END_USER_CODE-USER_lblGeneration_TEST
  });
  test("lblGrams(Label Widget) Test Cases", async () => {
    const lblGrams = screen.getByTestId("lblGrams");
    expect(lblGrams.tagName).toBe("LABEL");
    expect(lblGrams.classList).toContain("form-label");
    expect(lblGrams.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblGrams")
    );
  });
  test("Custom Test Cases for lblGrams", () => {
    // START_USER_CODE-USER_lblGrams_TEST
    // END_USER_CODE-USER_lblGrams_TEST
  });
  test("lblGrams2(Label Widget) Test Cases", async () => {
    const lblGrams2 = screen.getByTestId("lblGrams2");
    expect(lblGrams2.tagName).toBe("LABEL");
    expect(lblGrams2.classList).toContain("form-label");
    expect(lblGrams2.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblGrams2")
    );
  });
  test("Custom Test Cases for lblGrams2", () => {
    // START_USER_CODE-USER_lblGrams2_TEST
    // END_USER_CODE-USER_lblGrams2_TEST
  });
  test("lblGrossWt(Label Widget) Test Cases", async () => {
    const lblGrossWt = screen.getByTestId("lblGrossWt");
    expect(lblGrossWt.tagName).toBe("LABEL");
    expect(lblGrossWt.classList).toContain("form-label");
    expect(lblGrossWt.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblGrossWt")
    );
  });
  test("Custom Test Cases for lblGrossWt", () => {
    // START_USER_CODE-USER_lblGrossWt_TEST
    // END_USER_CODE-USER_lblGrossWt_TEST
  });
  test("lblKernRidingELKPct(Textbox Widget) Test Cases", async () => {
    const lblKernRidingELKPct = screen.getByTestId("lblKernRidingELKPct");
    expect(lblKernRidingELKPct.tagName).toBe("INPUT");
    expect(lblKernRidingELKPct.type).toBe("text");
    expect(lblKernRidingELKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(lblKernRidingELKPct, "1");
    });
  });
  test("Custom Test Cases for lblKernRidingELKPct", () => {
    // START_USER_CODE-USER_lblKernRidingELKPct_TEST
    // END_USER_CODE-USER_lblKernRidingELKPct_TEST
  });
  test("lblLb(Label Widget) Test Cases", async () => {
    const lblLb = screen.getByTestId("lblLb");
    expect(lblLb.tagName).toBe("LABEL");
    expect(lblLb.classList).toContain("form-label");
    expect(lblLb.textContent).toEqual(t("StockTransfer:StockTransfer_lblLb"));
  });
  test("Custom Test Cases for lblLb", () => {
    // START_USER_CODE-USER_lblLb_TEST
    // END_USER_CODE-USER_lblLb_TEST
  });
  test("lblLessFm(Label Widget) Test Cases", async () => {
    const lblLessFm = screen.getByTestId("lblLessFm");
    expect(lblLessFm.tagName).toBe("LABEL");
    expect(lblLessFm.classList).toContain("form-label");
    expect(lblLessFm.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblLessFm")
    );
  });
  test("Custom Test Cases for lblLessFm", () => {
    // START_USER_CODE-USER_lblLessFm_TEST
    // END_USER_CODE-USER_lblLessFm_TEST
  });
  test("lblLessLsk(Label Widget) Test Cases", async () => {
    const lblLessLsk = screen.getByTestId("lblLessLsk");
    expect(lblLessLsk.tagName).toBe("LABEL");
    expect(lblLessLsk.classList).toContain("form-label");
    expect(lblLessLsk.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblLessLsk")
    );
  });
  test("Custom Test Cases for lblLessLsk", () => {
    // START_USER_CODE-USER_lblLessLsk_TEST
    // END_USER_CODE-USER_lblLessLsk_TEST
  });
  test("lblLoad(Label Widget) Test Cases", async () => {
    const lblLoad = screen.getByTestId("lblLoad");
    expect(lblLoad.tagName).toBe("LABEL");
    expect(lblLoad.classList).toContain("form-label");
    expect(lblLoad.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblLoad")
    );
  });
  test("Custom Test Cases for lblLoad", () => {
    // START_USER_CODE-USER_lblLoad_TEST
    // END_USER_CODE-USER_lblLoad_TEST
  });
  test("lblLocation(Label Widget) Test Cases", async () => {
    const lblLocation = screen.getByTestId("lblLocation");
    expect(lblLocation.tagName).toBe("LABEL");
    expect(lblLocation.classList).toContain("form-label");
    expect(lblLocation.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblLocation")
    );
  });
  test("Custom Test Cases for lblLocation", () => {
    // START_USER_CODE-USER_lblLocation_TEST
    // END_USER_CODE-USER_lblLocation_TEST
  });
  test("lblLsk(Label Widget) Test Cases", async () => {
    const lblLsk = screen.getByTestId("lblLsk");
    expect(lblLsk.tagName).toBe("LABEL");
    expect(lblLsk.classList).toContain("form-label");
    expect(lblLsk.textContent).toEqual(t("StockTransfer:StockTransfer_lblLsk"));
  });
  test("Custom Test Cases for lblLsk", () => {
    // START_USER_CODE-USER_lblLsk_TEST
    // END_USER_CODE-USER_lblLsk_TEST
  });
  test("lblMoistr(Label Widget) Test Cases", async () => {
    const lblMoistr = screen.getByTestId("lblMoistr");
    expect(lblMoistr.tagName).toBe("LABEL");
    expect(lblMoistr.classList).toContain("form-label");
    expect(lblMoistr.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblMoistr")
    );
  });
  test("Custom Test Cases for lblMoistr", () => {
    // START_USER_CODE-USER_lblMoistr_TEST
    // END_USER_CODE-USER_lblMoistr_TEST
  });
  test("lblNetwt(Label Widget) Test Cases", async () => {
    const lblNetwt = screen.getByTestId("lblNetwt");
    expect(lblNetwt.tagName).toBe("LABEL");
    expect(lblNetwt.classList).toContain("form-label");
    expect(lblNetwt.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblNetwt")
    );
  });
  test("Custom Test Cases for lblNetwt", () => {
    // START_USER_CODE-USER_lblNetwt_TEST
    // END_USER_CODE-USER_lblNetwt_TEST
  });
  test("lblNew(Label Widget) Test Cases", async () => {
    const lblNew = screen.getByTestId("lblNew");
    expect(lblNew.tagName).toBe("LABEL");
    expect(lblNew.classList).toContain("form-label");
    expect(lblNew.textContent).toEqual(t("StockTransfer:StockTransfer_lblNew"));
  });
  test("Custom Test Cases for lblNew", () => {
    // START_USER_CODE-USER_lblNew_TEST
    // END_USER_CODE-USER_lblNew_TEST
  });
  test("lblOleic(Label Widget) Test Cases", async () => {
    const lblOleic = screen.getByTestId("lblOleic");
    expect(lblOleic.tagName).toBe("LABEL");
    expect(lblOleic.classList).toContain("form-label");
    expect(lblOleic.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblOleic")
    );
  });
  test("Custom Test Cases for lblOleic", () => {
    // START_USER_CODE-USER_lblOleic_TEST
    // END_USER_CODE-USER_lblOleic_TEST
  });
  test("lblOrganic(Label Widget) Test Cases", async () => {
    const lblOrganic = screen.getByTestId("lblOrganic");
    expect(lblOrganic.tagName).toBe("LABEL");
    expect(lblOrganic.classList).toContain("form-label");
    expect(lblOrganic.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblOrganic")
    );
  });
  test("Custom Test Cases for lblOrganic", () => {
    // START_USER_CODE-USER_lblOrganic_TEST
    // END_USER_CODE-USER_lblOrganic_TEST
  });
  test("lblPct(Label Widget) Test Cases", async () => {
    const lblPct = screen.getByTestId("lblPct");
    expect(lblPct.tagName).toBe("LABEL");
    expect(lblPct.classList).toContain("form-label");
    expect(lblPct.textContent).toEqual(t("StockTransfer:StockTransfer_lblPct"));
  });
  test("Custom Test Cases for lblPct", () => {
    // START_USER_CODE-USER_lblPct_TEST
    // END_USER_CODE-USER_lblPct_TEST
  });
  test("lblPending(Label Widget) Test Cases", async () => {
    const lblPending = screen.getByTestId("lblPending");
    expect(lblPending.tagName).toBe("LABEL");
    expect(lblPending.classList).toContain("form-label");
    expect(lblPending.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblPending")
    );
  });
  test("Custom Test Cases for lblPending", () => {
    // START_USER_CODE-USER_lblPending_TEST
    // END_USER_CODE-USER_lblPending_TEST
  });
  test("lblPerTon(Label Widget) Test Cases", async () => {
    const lblPerTon = screen.getByTestId("lblPerTon");
    expect(lblPerTon.tagName).toBe("LABEL");
    expect(lblPerTon.classList).toContain("form-label");
    expect(lblPerTon.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblPerTon")
    );
  });
  test("Custom Test Cases for lblPerTon", () => {
    // START_USER_CODE-USER_lblPerTon_TEST
    // END_USER_CODE-USER_lblPerTon_TEST
  });
  test("lblPnutType(Label Widget) Test Cases", async () => {
    const lblPnutType = screen.getByTestId("lblPnutType");
    expect(lblPnutType.tagName).toBe("LABEL");
    expect(lblPnutType.classList).toContain("form-label");
    expect(lblPnutType.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblPnutType")
    );
  });
  test("Custom Test Cases for lblPnutType", () => {
    // START_USER_CODE-USER_lblPnutType_TEST
    // END_USER_CODE-USER_lblPnutType_TEST
  });
  test("lblPrcnt(Label Widget) Test Cases", async () => {
    const lblPrcnt = screen.getByTestId("lblPrcnt");
    expect(lblPrcnt.tagName).toBe("LABEL");
    expect(lblPrcnt.classList).toContain("form-label");
    expect(lblPrcnt.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblPrcnt")
    );
  });
  test("Custom Test Cases for lblPrcnt", () => {
    // START_USER_CODE-USER_lblPrcnt_TEST
    // END_USER_CODE-USER_lblPrcnt_TEST
  });
  test("lblPrcnt2(Label Widget) Test Cases", async () => {
    const lblPrcnt2 = screen.getByTestId("lblPrcnt2");
    expect(lblPrcnt2.tagName).toBe("LABEL");
    expect(lblPrcnt2.classList).toContain("form-label");
    expect(lblPrcnt2.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblPrcnt2")
    );
  });
  test("Custom Test Cases for lblPrcnt2", () => {
    // START_USER_CODE-USER_lblPrcnt2_TEST
    // END_USER_CODE-USER_lblPrcnt2_TEST
  });
  test("lblReceived(Label Widget) Test Cases", async () => {
    const lblReceived = screen.getByTestId("lblReceived");
    expect(lblReceived.tagName).toBe("LABEL");
    expect(lblReceived.classList).toContain("form-label");
    expect(lblReceived.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblReceived")
    );
  });
  test("Custom Test Cases for lblReceived", () => {
    // START_USER_CODE-USER_lblReceived_TEST
    // END_USER_CODE-USER_lblReceived_TEST
  });
  test("lblRegrading(Label Widget) Test Cases", async () => {
    const lblRegrading = screen.getByTestId("lblRegrading");
    expect(lblRegrading.tagName).toBe("LABEL");
    expect(lblRegrading.classList).toContain("form-label");
    expect(lblRegrading.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblRegrading")
    );
  });
  test("Custom Test Cases for lblRegrading", () => {
    // START_USER_CODE-USER_lblRegrading_TEST
    // END_USER_CODE-USER_lblRegrading_TEST
  });
  test("lblSeg(Label Widget) Test Cases", async () => {
    const lblSeg = screen.getByTestId("lblSeg");
    expect(lblSeg.tagName).toBe("LABEL");
    expect(lblSeg.classList).toContain("form-label");
    expect(lblSeg.textContent).toEqual(t("StockTransfer:StockTransfer_lblSeg"));
  });
  test("Custom Test Cases for lblSeg", () => {
    // START_USER_CODE-USER_lblSeg_TEST
    // END_USER_CODE-USER_lblSeg_TEST
  });
  test("lblSettlementInfo(Label Widget) Test Cases", async () => {
    const lblSettlementInfo = screen.getByTestId("lblSettlementInfo");
    expect(lblSettlementInfo.tagName).toBe("LABEL");
    expect(lblSettlementInfo.classList).toContain("form-label");
    expect(lblSettlementInfo.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblSettlementInfo")
    );
  });
  test("Custom Test Cases for lblSettlementInfo", () => {
    // START_USER_CODE-USER_lblSettlementInfo_TEST
    // END_USER_CODE-USER_lblSettlementInfo_TEST
  });
  test("lblShippdFrm(Label Widget) Test Cases", async () => {
    const lblShippdFrm = screen.getByTestId("lblShippdFrm");
    expect(lblShippdFrm.tagName).toBe("LABEL");
    expect(lblShippdFrm.classList).toContain("form-label");
    expect(lblShippdFrm.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblShippdFrm")
    );
  });
  test("Custom Test Cases for lblShippdFrm", () => {
    // START_USER_CODE-USER_lblShippdFrm_TEST
    // END_USER_CODE-USER_lblShippdFrm_TEST
  });
  test("lblShippdTo(Label Widget) Test Cases", async () => {
    const lblShippdTo = screen.getByTestId("lblShippdTo");
    expect(lblShippdTo.tagName).toBe("LABEL");
    expect(lblShippdTo.classList).toContain("form-label");
    expect(lblShippdTo.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblShippdTo")
    );
  });
  test("Custom Test Cases for lblShippdTo", () => {
    // START_USER_CODE-USER_lblShippdTo_TEST
    // END_USER_CODE-USER_lblShippdTo_TEST
  });
  test("lblShipped(Label Widget) Test Cases", async () => {
    const lblShipped = screen.getByTestId("lblShipped");
    expect(lblShipped.tagName).toBe("LABEL");
    expect(lblShipped.classList).toContain("form-label");
    expect(lblShipped.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblShipped")
    );
  });
  test("Custom Test Cases for lblShipped", () => {
    // START_USER_CODE-USER_lblShipped_TEST
    // END_USER_CODE-USER_lblShipped_TEST
  });
  test("lblVariety(Label Widget) Test Cases", async () => {
    const lblVariety = screen.getByTestId("lblVariety");
    expect(lblVariety.tagName).toBe("LABEL");
    expect(lblVariety.classList).toContain("form-label");
    expect(lblVariety.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblVariety")
    );
  });
  test("Custom Test Cases for lblVariety", () => {
    // START_USER_CODE-USER_lblVariety_TEST
    // END_USER_CODE-USER_lblVariety_TEST
  });
  test("lblWhseBin(Label Widget) Test Cases", async () => {
    const lblWhseBin = screen.getByTestId("lblWhseBin");
    expect(lblWhseBin.tagName).toBe("LABEL");
    expect(lblWhseBin.classList).toContain("form-label");
    expect(lblWhseBin.textContent).toEqual(
      t("StockTransfer:StockTransfer_lblWhseBin")
    );
  });
  test("Custom Test Cases for lblWhseBin", () => {
    // START_USER_CODE-USER_lblWhseBin_TEST
    // END_USER_CODE-USER_lblWhseBin_TEST
  });
  test("textboxwidget61(Textbox Widget) Test Cases", async () => {
    const textboxwidget61 = screen.getByTestId("textboxwidget61");
    expect(textboxwidget61.tagName).toBe("INPUT");
    expect(textboxwidget61.type).toBe("text");
    expect(textboxwidget61.classList).toContain("textboxWidgetClass");
    expect(textboxwidget61.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_textboxwidget61")
    );
    await act(async () => {
      userEvent.type(textboxwidget61, "1");
    });
  });
  test("Custom Test Cases for textboxwidget61", () => {
    // START_USER_CODE-USER_textboxwidget61_TEST
    // END_USER_CODE-USER_textboxwidget61_TEST
  });
  test("txt1007(Textbox Widget) Test Cases", async () => {
    const txt1007 = screen.getByTestId("txt1007");
    expect(txt1007.tagName).toBe("INPUT");
    expect(txt1007.type).toBe("text");
    expect(txt1007.classList).toContain("textboxWidgetClass");
    expect(txt1007.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txt1007")
    );
    await act(async () => {
      userEvent.type(txt1007, "1");
    });
  });
  test("Custom Test Cases for txt1007", () => {
    // START_USER_CODE-USER_txt1007_TEST
    // END_USER_CODE-USER_txt1007_TEST
  });
  test("txtAddDest(Textbox Widget) Test Cases", async () => {
    const txtAddDest = screen.getByTestId("txtAddDest");
    expect(txtAddDest.tagName).toBe("INPUT");
    expect(txtAddDest.type).toBe("text");
    expect(txtAddDest.classList).toContain("textboxWidgetClass");
    expect(txtAddDest.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtAddDest")
    );
    await act(async () => {
      userEvent.type(txtAddDest, "1");
    });
  });
  test("Custom Test Cases for txtAddDest", () => {
    // START_USER_CODE-USER_txtAddDest_TEST
    // END_USER_CODE-USER_txtAddDest_TEST
  });
  test("txtAddedBy(Textbox Widget) Test Cases", async () => {
    const txtAddedBy = screen.getByTestId("txtAddedBy");
    expect(txtAddedBy.tagName).toBe("INPUT");
    expect(txtAddedBy.type).toBe("text");
    expect(txtAddedBy.classList).toContain("textboxWidgetClass");
    expect(txtAddedBy.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtAddedBy")
    );
    await act(async () => {
      userEvent.type(txtAddedBy, "1");
    });
  });
  test("Custom Test Cases for txtAddedBy", () => {
    // START_USER_CODE-USER_txtAddedBy_TEST
    // END_USER_CODE-USER_txtAddedBy_TEST
  });
  test("txtAddShip(Textbox Widget) Test Cases", async () => {
    const txtAddShip = screen.getByTestId("txtAddShip");
    expect(txtAddShip.tagName).toBe("INPUT");
    expect(txtAddShip.type).toBe("text");
    expect(txtAddShip.classList).toContain("textboxWidgetClass");
    expect(txtAddShip.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtAddShip")
    );
    await act(async () => {
      userEvent.type(txtAddShip, "1");
    });
  });
  test("Custom Test Cases for txtAddShip", () => {
    // START_USER_CODE-USER_txtAddShip_TEST
    // END_USER_CODE-USER_txtAddShip_TEST
  });
  test("txtCarrAbbr(Textbox Widget) Test Cases", async () => {
    const txtCarrAbbr = screen.getByTestId("txtCarrAbbr");
    expect(txtCarrAbbr.tagName).toBe("INPUT");
    expect(txtCarrAbbr.type).toBe("text");
    expect(txtCarrAbbr.classList).toContain("textboxWidgetClass");
    expect(txtCarrAbbr.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtCarrAbbr")
    );
    await act(async () => {
      userEvent.type(txtCarrAbbr, "1");
    });
  });
  test("Custom Test Cases for txtCarrAbbr", () => {
    // START_USER_CODE-USER_txtCarrAbbr_TEST
    // END_USER_CODE-USER_txtCarrAbbr_TEST
  });
  test("txtChngdBy(Textbox Widget) Test Cases", async () => {
    const txtChngdBy = screen.getByTestId("txtChngdBy");
    expect(txtChngdBy.tagName).toBe("INPUT");
    expect(txtChngdBy.type).toBe("text");
    expect(txtChngdBy.classList).toContain("textboxWidgetClass");
    expect(txtChngdBy.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtChngdBy")
    );
    await act(async () => {
      userEvent.type(txtChngdBy, "1");
    });
  });
  test("Custom Test Cases for txtChngdBy", () => {
    // START_USER_CODE-USER_txtChngdBy_TEST
    // END_USER_CODE-USER_txtChngdBy_TEST
  });
  test("txtClnSmplWt(Textbox Widget) Test Cases", async () => {
    const txtClnSmplWt = screen.getByTestId("txtClnSmplWt");
    expect(txtClnSmplWt.tagName).toBe("INPUT");
    expect(txtClnSmplWt.type).toBe("text");
    expect(txtClnSmplWt.classList).toContain("textboxWidgetClass");
    expect(txtClnSmplWt.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtClnSmplWt")
    );
    await act(async () => {
      userEvent.type(txtClnSmplWt, "1");
    });
  });
  test("Custom Test Cases for txtClnSmplWt", () => {
    // START_USER_CODE-USER_txtClnSmplWt_TEST
    // END_USER_CODE-USER_txtClnSmplWt_TEST
  });
  test("txtConcealedRMD(Textbox Widget) Test Cases", async () => {
    const txtConcealedRMD = screen.getByTestId("txtConcealedRMD");
    expect(txtConcealedRMD.tagName).toBe("INPUT");
    expect(txtConcealedRMD.type).toBe("text");
    expect(txtConcealedRMD.classList).toContain("textboxWidgetClass");
    expect(txtConcealedRMD.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtConcealedRMD")
    );
    await act(async () => {
      userEvent.type(txtConcealedRMD, "1");
    });
  });
  test("Custom Test Cases for txtConcealedRMD", () => {
    // START_USER_CODE-USER_txtConcealedRMD_TEST
    // END_USER_CODE-USER_txtConcealedRMD_TEST
  });
  test("txtConcealedRMDPct(Textbox Widget) Test Cases", async () => {
    const txtConcealedRMDPct = screen.getByTestId("txtConcealedRMDPct");
    expect(txtConcealedRMDPct.tagName).toBe("INPUT");
    expect(txtConcealedRMDPct.type).toBe("text");
    expect(txtConcealedRMDPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtConcealedRMDPct, "1");
    });
  });
  test("Custom Test Cases for txtConcealedRMDPct", () => {
    // START_USER_CODE-USER_txtConcealedRMDPct_TEST
    // END_USER_CODE-USER_txtConcealedRMDPct_TEST
  });
  test("txtCrackedBroken(Textbox Widget) Test Cases", async () => {
    const txtCrackedBroken = screen.getByTestId("txtCrackedBroken");
    expect(txtCrackedBroken.tagName).toBe("INPUT");
    expect(txtCrackedBroken.type).toBe("text");
    expect(txtCrackedBroken.classList).toContain("textboxWidgetClass");
    expect(txtCrackedBroken.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtCrackedBroken")
    );
    await act(async () => {
      userEvent.type(txtCrackedBroken, "1");
    });
  });
  test("Custom Test Cases for txtCrackedBroken", () => {
    // START_USER_CODE-USER_txtCrackedBroken_TEST
    // END_USER_CODE-USER_txtCrackedBroken_TEST
  });
  test("txtCrackedBrokenPct(Textbox Widget) Test Cases", async () => {
    const txtCrackedBrokenPct = screen.getByTestId("txtCrackedBrokenPct");
    expect(txtCrackedBrokenPct.tagName).toBe("INPUT");
    expect(txtCrackedBrokenPct.type).toBe("text");
    expect(txtCrackedBrokenPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCrackedBrokenPct, "1");
    });
  });
  test("Custom Test Cases for txtCrackedBrokenPct", () => {
    // START_USER_CODE-USER_txtCrackedBrokenPct_TEST
    // END_USER_CODE-USER_txtCrackedBrokenPct_TEST
  });
  test("txtDamageSplits(Textbox Widget) Test Cases", async () => {
    const txtDamageSplits = screen.getByTestId("txtDamageSplits");
    expect(txtDamageSplits.tagName).toBe("INPUT");
    expect(txtDamageSplits.type).toBe("text");
    expect(txtDamageSplits.classList).toContain("textboxWidgetClass");
    expect(txtDamageSplits.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtDamageSplits")
    );
    await act(async () => {
      userEvent.type(txtDamageSplits, "1");
    });
  });
  test("Custom Test Cases for txtDamageSplits", () => {
    // START_USER_CODE-USER_txtDamageSplits_TEST
    // END_USER_CODE-USER_txtDamageSplits_TEST
  });
  test("txtDamageSplits2(Textbox Widget) Test Cases", async () => {
    const txtDamageSplits2 = screen.getByTestId("txtDamageSplits2");
    expect(txtDamageSplits2.tagName).toBe("INPUT");
    expect(txtDamageSplits2.type).toBe("text");
    expect(txtDamageSplits2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDamageSplits2, "1");
    });
  });
  test("Custom Test Cases for txtDamageSplits2", () => {
    // START_USER_CODE-USER_txtDamageSplits2_TEST
    // END_USER_CODE-USER_txtDamageSplits2_TEST
  });
  test("txtDeductnAmt(Textbox Widget) Test Cases", async () => {
    const txtDeductnAmt = screen.getByTestId("txtDeductnAmt");
    expect(txtDeductnAmt.tagName).toBe("INPUT");
    expect(txtDeductnAmt.type).toBe("text");
    expect(txtDeductnAmt.classList).toContain("textboxWidgetClass");
    expect(txtDeductnAmt.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtDeductnAmt")
    );
    await act(async () => {
      userEvent.type(txtDeductnAmt, "1");
    });
  });
  test("Custom Test Cases for txtDeductnAmt", () => {
    // START_USER_CODE-USER_txtDeductnAmt_TEST
    // END_USER_CODE-USER_txtDeductnAmt_TEST
  });
  test("txtDiscoloredShells(Textbox Widget) Test Cases", async () => {
    const txtDiscoloredShells = screen.getByTestId("txtDiscoloredShells");
    expect(txtDiscoloredShells.tagName).toBe("INPUT");
    expect(txtDiscoloredShells.type).toBe("text");
    expect(txtDiscoloredShells.classList).toContain("textboxWidgetClass");
    expect(txtDiscoloredShells.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtDiscoloredShells")
    );
    await act(async () => {
      userEvent.type(txtDiscoloredShells, "1");
    });
  });
  test("Custom Test Cases for txtDiscoloredShells", () => {
    // START_USER_CODE-USER_txtDiscoloredShells_TEST
    // END_USER_CODE-USER_txtDiscoloredShells_TEST
  });
  test("txtDiscoloredShellsPct(Textbox Widget) Test Cases", async () => {
    const txtDiscoloredShellsPct = screen.getByTestId("txtDiscoloredShellsPct");
    expect(txtDiscoloredShellsPct.tagName).toBe("INPUT");
    expect(txtDiscoloredShellsPct.type).toBe("text");
    expect(txtDiscoloredShellsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtDiscoloredShellsPct, "1");
    });
  });
  test("Custom Test Cases for txtDiscoloredShellsPct", () => {
    // START_USER_CODE-USER_txtDiscoloredShellsPct_TEST
    // END_USER_CODE-USER_txtDiscoloredShellsPct_TEST
  });
  test("txtDispatch(Textbox Widget) Test Cases", async () => {
    const txtDispatch = screen.getByTestId("txtDispatch");
    expect(txtDispatch.tagName).toBe("INPUT");
    expect(txtDispatch.type).toBe("text");
    expect(txtDispatch.classList).toContain("textboxWidgetClass");
    expect(txtDispatch.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtDispatch")
    );
    await act(async () => {
      userEvent.type(txtDispatch, "1");
    });
  });
  test("Custom Test Cases for txtDispatch", () => {
    // START_USER_CODE-USER_txtDispatch_TEST
    // END_USER_CODE-USER_txtDispatch_TEST
  });
  test("txtEdiblOilShippdFrm(Textbox Widget) Test Cases", async () => {
    const txtEdiblOilShippdFrm = screen.getByTestId("txtEdiblOilShippdFrm");
    expect(txtEdiblOilShippdFrm.tagName).toBe("INPUT");
    expect(txtEdiblOilShippdFrm.type).toBe("text");
    expect(txtEdiblOilShippdFrm.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtEdiblOilShippdFrm, "1");
    });
  });
  test("Custom Test Cases for txtEdiblOilShippdFrm", () => {
    // START_USER_CODE-USER_txtEdiblOilShippdFrm_TEST
    // END_USER_CODE-USER_txtEdiblOilShippdFrm_TEST
  });
  test("txtEdiblOilShippdTo(Textbox Widget) Test Cases", async () => {
    const txtEdiblOilShippdTo = screen.getByTestId("txtEdiblOilShippdTo");
    expect(txtEdiblOilShippdTo.tagName).toBe("INPUT");
    expect(txtEdiblOilShippdTo.type).toBe("text");
    expect(txtEdiblOilShippdTo.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtEdiblOilShippdTo, "1");
    });
  });
  test("Custom Test Cases for txtEdiblOilShippdTo", () => {
    // START_USER_CODE-USER_txtEdiblOilShippdTo_TEST
    // END_USER_CODE-USER_txtEdiblOilShippdTo_TEST
  });
  test("txtExcMoisture(Textbox Widget) Test Cases", async () => {
    const txtExcMoisture = screen.getByTestId("txtExcMoisture");
    expect(txtExcMoisture.tagName).toBe("INPUT");
    expect(txtExcMoisture.type).toBe("text");
    expect(txtExcMoisture.classList).toContain("textboxWidgetClass");
    expect(txtExcMoisture.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtExcMoisture")
    );
    await act(async () => {
      userEvent.type(txtExcMoisture, "1");
    });
  });
  test("Custom Test Cases for txtExcMoisture", () => {
    // START_USER_CODE-USER_txtExcMoisture_TEST
    // END_USER_CODE-USER_txtExcMoisture_TEST
  });
  test("txtFancy(Textbox Widget) Test Cases", async () => {
    const txtFancy = screen.getByTestId("txtFancy");
    expect(txtFancy.tagName).toBe("INPUT");
    expect(txtFancy.type).toBe("text");
    expect(txtFancy.classList).toContain("textboxWidgetClass");
    expect(txtFancy.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtFancy")
    );
    await act(async () => {
      userEvent.type(txtFancy, "1");
    });
  });
  test("Custom Test Cases for txtFancy", () => {
    // START_USER_CODE-USER_txtFancy_TEST
    // END_USER_CODE-USER_txtFancy_TEST
  });
  test("txtFancyPct(Textbox Widget) Test Cases", async () => {
    const txtFancyPct = screen.getByTestId("txtFancyPct");
    expect(txtFancyPct.tagName).toBe("INPUT");
    expect(txtFancyPct.type).toBe("text");
    expect(txtFancyPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFancyPct, "1");
    });
  });
  test("Custom Test Cases for txtFancyPct", () => {
    // START_USER_CODE-USER_txtFancyPct_TEST
    // END_USER_CODE-USER_txtFancyPct_TEST
  });
  test("txtFm(Textbox Widget) Test Cases", async () => {
    const txtFm = screen.getByTestId("txtFm");
    expect(txtFm.tagName).toBe("INPUT");
    expect(txtFm.type).toBe("text");
    expect(txtFm.classList).toContain("textboxWidgetClass");
    expect(txtFm.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtFm")
    );
    await act(async () => {
      userEvent.type(txtFm, "1");
    });
  });
  test("Custom Test Cases for txtFm", () => {
    // START_USER_CODE-USER_txtFm_TEST
    // END_USER_CODE-USER_txtFm_TEST
  });
  test("txtFmSample(Textbox Widget) Test Cases", async () => {
    const txtFmSample = screen.getByTestId("txtFmSample");
    expect(txtFmSample.tagName).toBe("INPUT");
    expect(txtFmSample.type).toBe("text");
    expect(txtFmSample.classList).toContain("textboxWidgetClass");
    expect(txtFmSample.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtFmSample")
    );
    await act(async () => {
      userEvent.type(txtFmSample, "1");
    });
  });
  test("Custom Test Cases for txtFmSample", () => {
    // START_USER_CODE-USER_txtFmSample_TEST
    // END_USER_CODE-USER_txtFmSample_TEST
  });
  test("txtForeignMaterialPct(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterialPct = screen.getByTestId("txtForeignMaterialPct");
    expect(txtForeignMaterialPct.tagName).toBe("INPUT");
    expect(txtForeignMaterialPct.type).toBe("text");
    expect(txtForeignMaterialPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtForeignMaterialPct, "1");
    });
  });
  test("Custom Test Cases for txtForeignMaterialPct", () => {
    // START_USER_CODE-USER_txtForeignMaterialPct_TEST
    // END_USER_CODE-USER_txtForeignMaterialPct_TEST
  });
  test("txtForeignMtrl(Textbox Widget) Test Cases", async () => {
    const txtForeignMtrl = screen.getByTestId("txtForeignMtrl");
    expect(txtForeignMtrl.tagName).toBe("INPUT");
    expect(txtForeignMtrl.type).toBe("text");
    expect(txtForeignMtrl.classList).toContain("textboxWidgetClass");
    expect(txtForeignMtrl.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtForeignMtrl")
    );
    await act(async () => {
      userEvent.type(txtForeignMtrl, "1");
    });
  });
  test("Custom Test Cases for txtForeignMtrl", () => {
    // START_USER_CODE-USER_txtForeignMtrl_TEST
    // END_USER_CODE-USER_txtForeignMtrl_TEST
  });
  test("txtFreezeDamage(Textbox Widget) Test Cases", async () => {
    const txtFreezeDamage = screen.getByTestId("txtFreezeDamage");
    expect(txtFreezeDamage.tagName).toBe("INPUT");
    expect(txtFreezeDamage.type).toBe("text");
    expect(txtFreezeDamage.classList).toContain("textboxWidgetClass");
    expect(txtFreezeDamage.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtFreezeDamage")
    );
    await act(async () => {
      userEvent.type(txtFreezeDamage, "1");
    });
  });
  test("Custom Test Cases for txtFreezeDamage", () => {
    // START_USER_CODE-USER_txtFreezeDamage_TEST
    // END_USER_CODE-USER_txtFreezeDamage_TEST
  });
  test("txtFreezeDamagePct(Textbox Widget) Test Cases", async () => {
    const txtFreezeDamagePct = screen.getByTestId("txtFreezeDamagePct");
    expect(txtFreezeDamagePct.tagName).toBe("INPUT");
    expect(txtFreezeDamagePct.type).toBe("text");
    expect(txtFreezeDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtFreezeDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtFreezeDamagePct", () => {
    // START_USER_CODE-USER_txtFreezeDamagePct_TEST
    // END_USER_CODE-USER_txtFreezeDamagePct_TEST
  });
  test("txtFreightRate(Textbox Widget) Test Cases", async () => {
    const txtFreightRate = screen.getByTestId("txtFreightRate");
    expect(txtFreightRate.tagName).toBe("INPUT");
    expect(txtFreightRate.type).toBe("text");
    expect(txtFreightRate.classList).toContain("textboxWidgetClass");
    expect(txtFreightRate.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtFreightRate")
    );
    await act(async () => {
      userEvent.type(txtFreightRate, "1");
    });
  });
  test("Custom Test Cases for txtFreightRate", () => {
    // START_USER_CODE-USER_txtFreightRate_TEST
    // END_USER_CODE-USER_txtFreightRate_TEST
  });
  test("txtFrghtAm(Textbox Widget) Test Cases", async () => {
    const txtFrghtAm = screen.getByTestId("txtFrghtAm");
    expect(txtFrghtAm.tagName).toBe("INPUT");
    expect(txtFrghtAm.type).toBe("text");
    expect(txtFrghtAm.classList).toContain("textboxWidgetClass");
    expect(txtFrghtAm.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtFrghtAm")
    );
    await act(async () => {
      userEvent.type(txtFrghtAm, "1");
    });
  });
  test("Custom Test Cases for txtFrghtAm", () => {
    // START_USER_CODE-USER_txtFrghtAm_TEST
    // END_USER_CODE-USER_txtFrghtAm_TEST
  });
  test("txtGenerationShippdFrm(Textbox Widget) Test Cases", async () => {
    const txtGenerationShippdFrm = screen.getByTestId("txtGenerationShippdFrm");
    expect(txtGenerationShippdFrm.tagName).toBe("INPUT");
    expect(txtGenerationShippdFrm.type).toBe("text");
    expect(txtGenerationShippdFrm.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtGenerationShippdFrm, "1");
    });
  });
  test("Custom Test Cases for txtGenerationShippdFrm", () => {
    // START_USER_CODE-USER_txtGenerationShippdFrm_TEST
    // END_USER_CODE-USER_txtGenerationShippdFrm_TEST
  });
  test("txtGenerationShippdTo(Textbox Widget) Test Cases", async () => {
    const txtGenerationShippdTo = screen.getByTestId("txtGenerationShippdTo");
    expect(txtGenerationShippdTo.tagName).toBe("INPUT");
    expect(txtGenerationShippdTo.type).toBe("text");
    expect(txtGenerationShippdTo.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtGenerationShippdTo, "1");
    });
  });
  test("Custom Test Cases for txtGenerationShippdTo", () => {
    // START_USER_CODE-USER_txtGenerationShippdTo_TEST
    // END_USER_CODE-USER_txtGenerationShippdTo_TEST
  });
  test("txtGrossWt(Textbox Widget) Test Cases", async () => {
    const txtGrossWt = screen.getByTestId("txtGrossWt");
    expect(txtGrossWt.tagName).toBe("INPUT");
    expect(txtGrossWt.type).toBe("text");
    expect(txtGrossWt.classList).toContain("textboxWidgetClass");
    expect(txtGrossWt.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtGrossWt")
    );
    await act(async () => {
      userEvent.type(txtGrossWt, "1");
    });
  });
  test("Custom Test Cases for txtGrossWt", () => {
    // START_USER_CODE-USER_txtGrossWt_TEST
    // END_USER_CODE-USER_txtGrossWt_TEST
  });
  test("txtHullBrightness(Textbox Widget) Test Cases", async () => {
    const txtHullBrightness = screen.getByTestId("txtHullBrightness");
    expect(txtHullBrightness.tagName).toBe("INPUT");
    expect(txtHullBrightness.type).toBe("text");
    expect(txtHullBrightness.classList).toContain("textboxWidgetClass");
    expect(txtHullBrightness.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtHullBrightness")
    );
    await act(async () => {
      userEvent.type(txtHullBrightness, "1");
    });
  });
  test("Custom Test Cases for txtHullBrightness", () => {
    // START_USER_CODE-USER_txtHullBrightness_TEST
    // END_USER_CODE-USER_txtHullBrightness_TEST
  });
  test("txtHullBrightnessPct(Textbox Widget) Test Cases", async () => {
    const txtHullBrightnessPct = screen.getByTestId("txtHullBrightnessPct");
    expect(txtHullBrightnessPct.tagName).toBe("INPUT");
    expect(txtHullBrightnessPct.type).toBe("text");
    expect(txtHullBrightnessPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullBrightnessPct, "1");
    });
  });
  test("Custom Test Cases for txtHullBrightnessPct", () => {
    // START_USER_CODE-USER_txtHullBrightnessPct_TEST
    // END_USER_CODE-USER_txtHullBrightnessPct_TEST
  });
  test("txtHulls(Textbox Widget) Test Cases", async () => {
    const txtHulls = screen.getByTestId("txtHulls");
    expect(txtHulls.tagName).toBe("INPUT");
    expect(txtHulls.type).toBe("text");
    expect(txtHulls.classList).toContain("textboxWidgetClass");
    expect(txtHulls.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtHulls")
    );
    await act(async () => {
      userEvent.type(txtHulls, "1");
    });
  });
  test("Custom Test Cases for txtHulls", () => {
    // START_USER_CODE-USER_txtHulls_TEST
    // END_USER_CODE-USER_txtHulls_TEST
  });
  test("txtHullsPct(Textbox Widget) Test Cases", async () => {
    const txtHullsPct = screen.getByTestId("txtHullsPct");
    expect(txtHullsPct.tagName).toBe("INPUT");
    expect(txtHullsPct.type).toBe("text");
    expect(txtHullsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHullsPct, "1");
    });
  });
  test("Custom Test Cases for txtHullsPct", () => {
    // START_USER_CODE-USER_txtHullsPct_TEST
    // END_USER_CODE-USER_txtHullsPct_TEST
  });
  test("txtInvoice(Textbox Widget) Test Cases", async () => {
    const txtInvoice = screen.getByTestId("txtInvoice");
    expect(txtInvoice.tagName).toBe("INPUT");
    expect(txtInvoice.type).toBe("text");
    expect(txtInvoice.classList).toContain("textboxWidgetClass");
    expect(txtInvoice.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtInvoice")
    );
    await act(async () => {
      userEvent.type(txtInvoice, "1");
    });
  });
  test("Custom Test Cases for txtInvoice", () => {
    // START_USER_CODE-USER_txtInvoice_TEST
    // END_USER_CODE-USER_txtInvoice_TEST
  });
  test("txtJumbo(Textbox Widget) Test Cases", async () => {
    const txtJumbo = screen.getByTestId("txtJumbo");
    expect(txtJumbo.tagName).toBe("INPUT");
    expect(txtJumbo.type).toBe("text");
    expect(txtJumbo.classList).toContain("textboxWidgetClass");
    expect(txtJumbo.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtJumbo")
    );
    await act(async () => {
      userEvent.type(txtJumbo, "1");
    });
  });
  test("Custom Test Cases for txtJumbo", () => {
    // START_USER_CODE-USER_txtJumbo_TEST
    // END_USER_CODE-USER_txtJumbo_TEST
  });
  test("txtJumboGrPct(Textbox Widget) Test Cases", async () => {
    const txtJumboGrPct = screen.getByTestId("txtJumboGrPct");
    expect(txtJumboGrPct.tagName).toBe("INPUT");
    expect(txtJumboGrPct.type).toBe("text");
    expect(txtJumboGrPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtJumboGrPct, "1");
    });
  });
  test("Custom Test Cases for txtJumboGrPct", () => {
    // START_USER_CODE-USER_txtJumboGrPct_TEST
    // END_USER_CODE-USER_txtJumboGrPct_TEST
  });
  test("txtKernRidingELK(Textbox Widget) Test Cases", async () => {
    const txtKernRidingELK = screen.getByTestId("txtKernRidingELK");
    expect(txtKernRidingELK.tagName).toBe("INPUT");
    expect(txtKernRidingELK.type).toBe("text");
    expect(txtKernRidingELK.classList).toContain("textboxWidgetClass");
    expect(txtKernRidingELK.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtKernRidingELK")
    );
    await act(async () => {
      userEvent.type(txtKernRidingELK, "1");
    });
  });
  test("Custom Test Cases for txtKernRidingELK", () => {
    // START_USER_CODE-USER_txtKernRidingELK_TEST
    // END_USER_CODE-USER_txtKernRidingELK_TEST
  });
  test("txtKernRidingPrescribe(Textbox Widget) Test Cases", async () => {
    const txtKernRidingPrescribe = screen.getByTestId("txtKernRidingPrescribe");
    expect(txtKernRidingPrescribe.tagName).toBe("INPUT");
    expect(txtKernRidingPrescribe.type).toBe("text");
    expect(txtKernRidingPrescribe.classList).toContain("textboxWidgetClass");
    expect(txtKernRidingPrescribe.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtKernRidingPrescribe")
    );
    await act(async () => {
      userEvent.type(txtKernRidingPrescribe, "1");
    });
  });
  test("Custom Test Cases for txtKernRidingPrescribe", () => {
    // START_USER_CODE-USER_txtKernRidingPrescribe_TEST
    // END_USER_CODE-USER_txtKernRidingPrescribe_TEST
  });
  test("txtKernRidingPrescribePct(Textbox Widget) Test Cases", async () => {
    const txtKernRidingPrescribePct = screen.getByTestId(
      "txtKernRidingPrescribePct"
    );
    expect(txtKernRidingPrescribePct.tagName).toBe("INPUT");
    expect(txtKernRidingPrescribePct.type).toBe("text");
    expect(txtKernRidingPrescribePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtKernRidingPrescribePct, "1");
    });
  });
  test("Custom Test Cases for txtKernRidingPrescribePct", () => {
    // START_USER_CODE-USER_txtKernRidingPrescribePct_TEST
    // END_USER_CODE-USER_txtKernRidingPrescribePct_TEST
  });
  test("txtLegMsg(Textbox Widget) Test Cases", async () => {
    const txtLegMsg = screen.getByTestId("txtLegMsg");
    expect(txtLegMsg.tagName).toBe("INPUT");
    expect(txtLegMsg.type).toBe("text");
    expect(txtLegMsg.classList).toContain("textboxWidgetClass");
    expect(txtLegMsg.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtLegMsg")
    );
    await act(async () => {
      userEvent.type(txtLegMsg, "1");
    });
  });
  test("Custom Test Cases for txtLegMsg", () => {
    // START_USER_CODE-USER_txtLegMsg_TEST
    // END_USER_CODE-USER_txtLegMsg_TEST
  });
  test("txtLsk(Textbox Widget) Test Cases", async () => {
    const txtLsk = screen.getByTestId("txtLsk");
    expect(txtLsk.tagName).toBe("INPUT");
    expect(txtLsk.type).toBe("text");
    expect(txtLsk.classList).toContain("textboxWidgetClass");
    expect(txtLsk.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtLsk")
    );
    await act(async () => {
      userEvent.type(txtLsk, "1");
    });
  });
  test("Custom Test Cases for txtLsk", () => {
    // START_USER_CODE-USER_txtLsk_TEST
    // END_USER_CODE-USER_txtLsk_TEST
  });
  test("txtLSKDamageRiding(Textbox Widget) Test Cases", async () => {
    const txtLSKDamageRiding = screen.getByTestId("txtLSKDamageRiding");
    expect(txtLSKDamageRiding.tagName).toBe("INPUT");
    expect(txtLSKDamageRiding.type).toBe("text");
    expect(txtLSKDamageRiding.classList).toContain("textboxWidgetClass");
    expect(txtLSKDamageRiding.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtLSKDamageRiding")
    );
    await act(async () => {
      userEvent.type(txtLSKDamageRiding, "1");
    });
  });
  test("Custom Test Cases for txtLSKDamageRiding", () => {
    // START_USER_CODE-USER_txtLSKDamageRiding_TEST
    // END_USER_CODE-USER_txtLSKDamageRiding_TEST
  });
  test("txtLSKDamageRiding2(Textbox Widget) Test Cases", async () => {
    const txtLSKDamageRiding2 = screen.getByTestId("txtLSKDamageRiding2");
    expect(txtLSKDamageRiding2.tagName).toBe("INPUT");
    expect(txtLSKDamageRiding2.type).toBe("text");
    expect(txtLSKDamageRiding2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLSKDamageRiding2, "1");
    });
  });
  test("Custom Test Cases for txtLSKDamageRiding2", () => {
    // START_USER_CODE-USER_txtLSKDamageRiding2_TEST
    // END_USER_CODE-USER_txtLSKDamageRiding2_TEST
  });
  test("txtLSKFallThru(Textbox Widget) Test Cases", async () => {
    const txtLSKFallThru = screen.getByTestId("txtLSKFallThru");
    expect(txtLSKFallThru.tagName).toBe("INPUT");
    expect(txtLSKFallThru.type).toBe("text");
    expect(txtLSKFallThru.classList).toContain("textboxWidgetClass");
    expect(txtLSKFallThru.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtLSKFallThru")
    );
    await act(async () => {
      userEvent.type(txtLSKFallThru, "1");
    });
  });
  test("Custom Test Cases for txtLSKFallThru", () => {
    // START_USER_CODE-USER_txtLSKFallThru_TEST
    // END_USER_CODE-USER_txtLSKFallThru_TEST
  });
  test("txtLSKFallThru2(Textbox Widget) Test Cases", async () => {
    const txtLSKFallThru2 = screen.getByTestId("txtLSKFallThru2");
    expect(txtLSKFallThru2.tagName).toBe("INPUT");
    expect(txtLSKFallThru2.type).toBe("text");
    expect(txtLSKFallThru2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLSKFallThru2, "1");
    });
  });
  test("Custom Test Cases for txtLSKFallThru2", () => {
    // START_USER_CODE-USER_txtLSKFallThru2_TEST
    // END_USER_CODE-USER_txtLSKFallThru2_TEST
  });
  test("txtLSKRidingGr(Textbox Widget) Test Cases", async () => {
    const txtLSKRidingGr = screen.getByTestId("txtLSKRidingGr");
    expect(txtLSKRidingGr.tagName).toBe("INPUT");
    expect(txtLSKRidingGr.type).toBe("text");
    expect(txtLSKRidingGr.classList).toContain("textboxWidgetClass");
    expect(txtLSKRidingGr.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtLSKRidingGr")
    );
    await act(async () => {
      userEvent.type(txtLSKRidingGr, "1");
    });
  });
  test("Custom Test Cases for txtLSKRidingGr", () => {
    // START_USER_CODE-USER_txtLSKRidingGr_TEST
    // END_USER_CODE-USER_txtLSKRidingGr_TEST
  });
  test("txtLSKRidingGr2(Textbox Widget) Test Cases", async () => {
    const txtLSKRidingGr2 = screen.getByTestId("txtLSKRidingGr2");
    expect(txtLSKRidingGr2.tagName).toBe("INPUT");
    expect(txtLSKRidingGr2.type).toBe("text");
    expect(txtLSKRidingGr2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLSKRidingGr2, "1");
    });
  });
  test("Custom Test Cases for txtLSKRidingGr2", () => {
    // START_USER_CODE-USER_txtLSKRidingGr2_TEST
    // END_USER_CODE-USER_txtLSKRidingGr2_TEST
  });
  test("txtLSKRidingPct(Textbox Widget) Test Cases", async () => {
    const txtLSKRidingPct = screen.getByTestId("txtLSKRidingPct");
    expect(txtLSKRidingPct.tagName).toBe("INPUT");
    expect(txtLSKRidingPct.type).toBe("text");
    expect(txtLSKRidingPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLSKRidingPct, "1");
    });
  });
  test("Custom Test Cases for txtLSKRidingPct", () => {
    // START_USER_CODE-USER_txtLSKRidingPct_TEST
    // END_USER_CODE-USER_txtLSKRidingPct_TEST
  });
  test("txtMileage(Textbox Widget) Test Cases", async () => {
    const txtMileage = screen.getByTestId("txtMileage");
    expect(txtMileage.tagName).toBe("INPUT");
    expect(txtMileage.type).toBe("text");
    expect(txtMileage.classList).toContain("textboxWidgetClass");
    expect(txtMileage.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtMileage")
    );
    await act(async () => {
      userEvent.type(txtMileage, "1");
    });
  });
  test("Custom Test Cases for txtMileage", () => {
    // START_USER_CODE-USER_txtMileage_TEST
    // END_USER_CODE-USER_txtMileage_TEST
  });
  test("txtMileageOvrrd(Textbox Widget) Test Cases", async () => {
    const txtMileageOvrrd = screen.getByTestId("txtMileageOvrrd");
    expect(txtMileageOvrrd.tagName).toBe("INPUT");
    expect(txtMileageOvrrd.type).toBe("text");
    expect(txtMileageOvrrd.classList).toContain("textboxWidgetClass");
    expect(txtMileageOvrrd.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtMileageOvrrd")
    );
    await act(async () => {
      userEvent.type(txtMileageOvrrd, "1");
    });
  });
  test("Custom Test Cases for txtMileageOvrrd", () => {
    // START_USER_CODE-USER_txtMileageOvrrd_TEST
    // END_USER_CODE-USER_txtMileageOvrrd_TEST
  });
  test("txtMoisture(Textbox Widget) Test Cases", async () => {
    const txtMoisture = screen.getByTestId("txtMoisture");
    expect(txtMoisture.tagName).toBe("INPUT");
    expect(txtMoisture.type).toBe("text");
    expect(txtMoisture.classList).toContain("textboxWidgetClass");
    expect(txtMoisture.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtMoisture")
    );
    await act(async () => {
      userEvent.type(txtMoisture, "1");
    });
  });
  test("Custom Test Cases for txtMoisture", () => {
    // START_USER_CODE-USER_txtMoisture_TEST
    // END_USER_CODE-USER_txtMoisture_TEST
  });
  test("txtMoisture2(Textbox Widget) Test Cases", async () => {
    const txtMoisture2 = screen.getByTestId("txtMoisture2");
    expect(txtMoisture2.tagName).toBe("INPUT");
    expect(txtMoisture2.type).toBe("text");
    expect(txtMoisture2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtMoisture2, "1");
    });
  });
  test("Custom Test Cases for txtMoisture2", () => {
    // START_USER_CODE-USER_txtMoisture2_TEST
    // END_USER_CODE-USER_txtMoisture2_TEST
  });
  test("txtNetELK(Textbox Widget) Test Cases", async () => {
    const txtNetELK = screen.getByTestId("txtNetELK");
    expect(txtNetELK.tagName).toBe("INPUT");
    expect(txtNetELK.type).toBe("text");
    expect(txtNetELK.classList).toContain("textboxWidgetClass");
    expect(txtNetELK.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtNetELK")
    );
    await act(async () => {
      userEvent.type(txtNetELK, "1");
    });
  });
  test("Custom Test Cases for txtNetELK", () => {
    // START_USER_CODE-USER_txtNetELK_TEST
    // END_USER_CODE-USER_txtNetELK_TEST
  });
  test("txtNetELKPct(Textbox Widget) Test Cases", async () => {
    const txtNetELKPct = screen.getByTestId("txtNetELKPct");
    expect(txtNetELKPct.tagName).toBe("INPUT");
    expect(txtNetELKPct.type).toBe("text");
    expect(txtNetELKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtNetELKPct, "1");
    });
  });
  test("Custom Test Cases for txtNetELKPct", () => {
    // START_USER_CODE-USER_txtNetELKPct_TEST
    // END_USER_CODE-USER_txtNetELKPct_TEST
  });
  test("txtNetLessLsk(Textbox Widget) Test Cases", async () => {
    const txtNetLessLsk = screen.getByTestId("txtNetLessLsk");
    expect(txtNetLessLsk.tagName).toBe("INPUT");
    expect(txtNetLessLsk.type).toBe("text");
    expect(txtNetLessLsk.classList).toContain("textboxWidgetClass");
    expect(txtNetLessLsk.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtNetLessLsk")
    );
    await act(async () => {
      userEvent.type(txtNetLessLsk, "1");
    });
  });
  test("Custom Test Cases for txtNetLessLsk", () => {
    // START_USER_CODE-USER_txtNetLessLsk_TEST
    // END_USER_CODE-USER_txtNetLessLsk_TEST
  });
  test("txtNetWt(Textbox Widget) Test Cases", async () => {
    const txtNetWt = screen.getByTestId("txtNetWt");
    expect(txtNetWt.tagName).toBe("INPUT");
    expect(txtNetWt.type).toBe("text");
    expect(txtNetWt.classList).toContain("textboxWidgetClass");
    expect(txtNetWt.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtNetWt")
    );
    await act(async () => {
      userEvent.type(txtNetWt, "1");
    });
  });
  test("Custom Test Cases for txtNetWt", () => {
    // START_USER_CODE-USER_txtNetWt_TEST
    // END_USER_CODE-USER_txtNetWt_TEST
  });
  test("txtOKDamageRiding(Textbox Widget) Test Cases", async () => {
    const txtOKDamageRiding = screen.getByTestId("txtOKDamageRiding");
    expect(txtOKDamageRiding.tagName).toBe("INPUT");
    expect(txtOKDamageRiding.type).toBe("text");
    expect(txtOKDamageRiding.classList).toContain("textboxWidgetClass");
    expect(txtOKDamageRiding.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtOKDamageRiding")
    );
    await act(async () => {
      userEvent.type(txtOKDamageRiding, "1");
    });
  });
  test("Custom Test Cases for txtOKDamageRiding", () => {
    // START_USER_CODE-USER_txtOKDamageRiding_TEST
    // END_USER_CODE-USER_txtOKDamageRiding_TEST
  });
  test("txtOKDamageRiding2(Textbox Widget) Test Cases", async () => {
    const txtOKDamageRiding2 = screen.getByTestId("txtOKDamageRiding2");
    expect(txtOKDamageRiding2.tagName).toBe("INPUT");
    expect(txtOKDamageRiding2.type).toBe("text");
    expect(txtOKDamageRiding2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOKDamageRiding2, "1");
    });
  });
  test("Custom Test Cases for txtOKDamageRiding2", () => {
    // START_USER_CODE-USER_txtOKDamageRiding2_TEST
    // END_USER_CODE-USER_txtOKDamageRiding2_TEST
  });
  test("txtOKFallThru(Textbox Widget) Test Cases", async () => {
    const txtOKFallThru = screen.getByTestId("txtOKFallThru");
    expect(txtOKFallThru.tagName).toBe("INPUT");
    expect(txtOKFallThru.type).toBe("text");
    expect(txtOKFallThru.classList).toContain("textboxWidgetClass");
    expect(txtOKFallThru.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtOKFallThru")
    );
    await act(async () => {
      userEvent.type(txtOKFallThru, "1");
    });
  });
  test("Custom Test Cases for txtOKFallThru", () => {
    // START_USER_CODE-USER_txtOKFallThru_TEST
    // END_USER_CODE-USER_txtOKFallThru_TEST
  });
  test("txtOKFallThru2(Textbox Widget) Test Cases", async () => {
    const txtOKFallThru2 = screen.getByTestId("txtOKFallThru2");
    expect(txtOKFallThru2.tagName).toBe("INPUT");
    expect(txtOKFallThru2.type).toBe("text");
    expect(txtOKFallThru2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOKFallThru2, "1");
    });
  });
  test("Custom Test Cases for txtOKFallThru2", () => {
    // START_USER_CODE-USER_txtOKFallThru2_TEST
    // END_USER_CODE-USER_txtOKFallThru2_TEST
  });
  test("txtOKRidingGr(Textbox Widget) Test Cases", async () => {
    const txtOKRidingGr = screen.getByTestId("txtOKRidingGr");
    expect(txtOKRidingGr.tagName).toBe("INPUT");
    expect(txtOKRidingGr.type).toBe("text");
    expect(txtOKRidingGr.classList).toContain("textboxWidgetClass");
    expect(txtOKRidingGr.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtOKRidingGr")
    );
    await act(async () => {
      userEvent.type(txtOKRidingGr, "1");
    });
  });
  test("Custom Test Cases for txtOKRidingGr", () => {
    // START_USER_CODE-USER_txtOKRidingGr_TEST
    // END_USER_CODE-USER_txtOKRidingGr_TEST
  });
  test("txtOKRidingGr2(Textbox Widget) Test Cases", async () => {
    const txtOKRidingGr2 = screen.getByTestId("txtOKRidingGr2");
    expect(txtOKRidingGr2.tagName).toBe("INPUT");
    expect(txtOKRidingGr2.type).toBe("text");
    expect(txtOKRidingGr2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOKRidingGr2, "1");
    });
  });
  test("Custom Test Cases for txtOKRidingGr2", () => {
    // START_USER_CODE-USER_txtOKRidingGr2_TEST
    // END_USER_CODE-USER_txtOKRidingGr2_TEST
  });
  test("txtOKRidingPct(Textbox Widget) Test Cases", async () => {
    const txtOKRidingPct = screen.getByTestId("txtOKRidingPct");
    expect(txtOKRidingPct.tagName).toBe("INPUT");
    expect(txtOKRidingPct.type).toBe("text");
    expect(txtOKRidingPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOKRidingPct, "1");
    });
  });
  test("Custom Test Cases for txtOKRidingPct", () => {
    // START_USER_CODE-USER_txtOKRidingPct_TEST
    // END_USER_CODE-USER_txtOKRidingPct_TEST
  });
  test("txtOleicShippdFrm(Textbox Widget) Test Cases", async () => {
    const txtOleicShippdFrm = screen.getByTestId("txtOleicShippdFrm");
    expect(txtOleicShippdFrm.tagName).toBe("INPUT");
    expect(txtOleicShippdFrm.type).toBe("text");
    expect(txtOleicShippdFrm.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOleicShippdFrm, "1");
    });
  });
  test("Custom Test Cases for txtOleicShippdFrm", () => {
    // START_USER_CODE-USER_txtOleicShippdFrm_TEST
    // END_USER_CODE-USER_txtOleicShippdFrm_TEST
  });
  test("txtOleicShippdTo(Textbox Widget) Test Cases", async () => {
    const txtOleicShippdTo = screen.getByTestId("txtOleicShippdTo");
    expect(txtOleicShippdTo.tagName).toBe("INPUT");
    expect(txtOleicShippdTo.type).toBe("text");
    expect(txtOleicShippdTo.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOleicShippdTo, "1");
    });
  });
  test("Custom Test Cases for txtOleicShippdTo", () => {
    // START_USER_CODE-USER_txtOleicShippdTo_TEST
    // END_USER_CODE-USER_txtOleicShippdTo_TEST
  });
  test("txtOrganicShippdFrm(Textbox Widget) Test Cases", async () => {
    const txtOrganicShippdFrm = screen.getByTestId("txtOrganicShippdFrm");
    expect(txtOrganicShippdFrm.tagName).toBe("INPUT");
    expect(txtOrganicShippdFrm.type).toBe("text");
    expect(txtOrganicShippdFrm.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOrganicShippdFrm, "1");
    });
  });
  test("Custom Test Cases for txtOrganicShippdFrm", () => {
    // START_USER_CODE-USER_txtOrganicShippdFrm_TEST
    // END_USER_CODE-USER_txtOrganicShippdFrm_TEST
  });
  test("txtOrganicShippdTo(Textbox Widget) Test Cases", async () => {
    const txtOrganicShippdTo = screen.getByTestId("txtOrganicShippdTo");
    expect(txtOrganicShippdTo.tagName).toBe("INPUT");
    expect(txtOrganicShippdTo.type).toBe("text");
    expect(txtOrganicShippdTo.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtOrganicShippdTo, "1");
    });
  });
  test("Custom Test Cases for txtOrganicShippdTo", () => {
    // START_USER_CODE-USER_txtOrganicShippdTo_TEST
    // END_USER_CODE-USER_txtOrganicShippdTo_TEST
  });
  test("txtOtherFM(Textbox Widget) Test Cases", async () => {
    const txtOtherFM = screen.getByTestId("txtOtherFM");
    expect(txtOtherFM.tagName).toBe("INPUT");
    expect(txtOtherFM.type).toBe("text");
    expect(txtOtherFM.classList).toContain("textboxWidgetClass");
    expect(txtOtherFM.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtOtherFM")
    );
    await act(async () => {
      userEvent.type(txtOtherFM, "1");
    });
  });
  test("Custom Test Cases for txtOtherFM", () => {
    // START_USER_CODE-USER_txtOtherFM_TEST
    // END_USER_CODE-USER_txtOtherFM_TEST
  });
  test("txtPeanutsRecvd(Textbox Widget) Test Cases", async () => {
    const txtPeanutsRecvd = screen.getByTestId("txtPeanutsRecvd");
    expect(txtPeanutsRecvd.tagName).toBe("INPUT");
    expect(txtPeanutsRecvd.type).toBe("text");
    expect(txtPeanutsRecvd.classList).toContain("textboxWidgetClass");
    expect(txtPeanutsRecvd.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtPeanutsRecvd")
    );
    await act(async () => {
      userEvent.type(txtPeanutsRecvd, "1");
    });
  });
  test("Custom Test Cases for txtPeanutsRecvd", () => {
    // START_USER_CODE-USER_txtPeanutsRecvd_TEST
    // END_USER_CODE-USER_txtPeanutsRecvd_TEST
  });
  test("txtPeanutsShip(Textbox Widget) Test Cases", async () => {
    const txtPeanutsShip = screen.getByTestId("txtPeanutsShip");
    expect(txtPeanutsShip.tagName).toBe("INPUT");
    expect(txtPeanutsShip.type).toBe("text");
    expect(txtPeanutsShip.classList).toContain("textboxWidgetClass");
    expect(txtPeanutsShip.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtPeanutsShip")
    );
    await act(async () => {
      userEvent.type(txtPeanutsShip, "1");
    });
  });
  test("Custom Test Cases for txtPeanutsShip", () => {
    // START_USER_CODE-USER_txtPeanutsShip_TEST
    // END_USER_CODE-USER_txtPeanutsShip_TEST
  });
  test("txtPhnDest(Textbox Widget) Test Cases", async () => {
    const txtPhnDest = screen.getByTestId("txtPhnDest");
    expect(txtPhnDest.tagName).toBe("INPUT");
    expect(txtPhnDest.type).toBe("text");
    expect(txtPhnDest.classList).toContain("textboxWidgetClass");
    expect(txtPhnDest.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtPhnDest")
    );
    await act(async () => {
      userEvent.type(txtPhnDest, "1");
    });
  });
  test("Custom Test Cases for txtPhnDest", () => {
    // START_USER_CODE-USER_txtPhnDest_TEST
    // END_USER_CODE-USER_txtPhnDest_TEST
  });
  test("txtPhnShip(Textbox Widget) Test Cases", async () => {
    const txtPhnShip = screen.getByTestId("txtPhnShip");
    expect(txtPhnShip.tagName).toBe("INPUT");
    expect(txtPhnShip.type).toBe("text");
    expect(txtPhnShip.classList).toContain("textboxWidgetClass");
    expect(txtPhnShip.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtPhnShip")
    );
    await act(async () => {
      userEvent.type(txtPhnShip, "1");
    });
  });
  test("Custom Test Cases for txtPhnShip", () => {
    // START_USER_CODE-USER_txtPhnShip_TEST
    // END_USER_CODE-USER_txtPhnShip_TEST
  });
  test("txtPittingDamage(Textbox Widget) Test Cases", async () => {
    const txtPittingDamage = screen.getByTestId("txtPittingDamage");
    expect(txtPittingDamage.tagName).toBe("INPUT");
    expect(txtPittingDamage.type).toBe("text");
    expect(txtPittingDamage.classList).toContain("textboxWidgetClass");
    expect(txtPittingDamage.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtPittingDamage")
    );
    await act(async () => {
      userEvent.type(txtPittingDamage, "1");
    });
  });
  test("Custom Test Cases for txtPittingDamage", () => {
    // START_USER_CODE-USER_txtPittingDamage_TEST
    // END_USER_CODE-USER_txtPittingDamage_TEST
  });
  test("txtPittingDamagePct(Textbox Widget) Test Cases", async () => {
    const txtPittingDamagePct = screen.getByTestId("txtPittingDamagePct");
    expect(txtPittingDamagePct.tagName).toBe("INPUT");
    expect(txtPittingDamagePct.type).toBe("text");
    expect(txtPittingDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPittingDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtPittingDamagePct", () => {
    // START_USER_CODE-USER_txtPittingDamagePct_TEST
    // END_USER_CODE-USER_txtPittingDamagePct_TEST
  });
  test("txtPnutShippdFrm(Textbox Widget) Test Cases", async () => {
    const txtPnutShippdFrm = screen.getByTestId("txtPnutShippdFrm");
    expect(txtPnutShippdFrm.tagName).toBe("INPUT");
    expect(txtPnutShippdFrm.type).toBe("text");
    expect(txtPnutShippdFrm.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPnutShippdFrm, "1");
    });
  });
  test("Custom Test Cases for txtPnutShippdFrm", () => {
    // START_USER_CODE-USER_txtPnutShippdFrm_TEST
    // END_USER_CODE-USER_txtPnutShippdFrm_TEST
  });
  test("txtPnutShippdTo(Textbox Widget) Test Cases", async () => {
    const txtPnutShippdTo = screen.getByTestId("txtPnutShippdTo");
    expect(txtPnutShippdTo.tagName).toBe("INPUT");
    expect(txtPnutShippdTo.type).toBe("text");
    expect(txtPnutShippdTo.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPnutShippdTo, "1");
    });
  });
  test("Custom Test Cases for txtPnutShippdTo", () => {
    // START_USER_CODE-USER_txtPnutShippdTo_TEST
    // END_USER_CODE-USER_txtPnutShippdTo_TEST
  });
  test("txtPremAmnt(Textbox Widget) Test Cases", async () => {
    const txtPremAmnt = screen.getByTestId("txtPremAmnt");
    expect(txtPremAmnt.tagName).toBe("INPUT");
    expect(txtPremAmnt.type).toBe("text");
    expect(txtPremAmnt.classList).toContain("textboxWidgetClass");
    expect(txtPremAmnt.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtPremAmnt")
    );
    await act(async () => {
      userEvent.type(txtPremAmnt, "1");
    });
  });
  test("Custom Test Cases for txtPremAmnt", () => {
    // START_USER_CODE-USER_txtPremAmnt_TEST
    // END_USER_CODE-USER_txtPremAmnt_TEST
  });
  test("txtProceedAmt(Textbox Widget) Test Cases", async () => {
    const txtProceedAmt = screen.getByTestId("txtProceedAmt");
    expect(txtProceedAmt.tagName).toBe("INPUT");
    expect(txtProceedAmt.type).toBe("text");
    expect(txtProceedAmt.classList).toContain("textboxWidgetClass");
    expect(txtProceedAmt.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtProceedAmt")
    );
    await act(async () => {
      userEvent.type(txtProceedAmt, "1");
    });
  });
  test("Custom Test Cases for txtProceedAmt", () => {
    // START_USER_CODE-USER_txtProceedAmt_TEST
    // END_USER_CODE-USER_txtProceedAmt_TEST
  });
  test("txtSclTcktRcvd(Textbox Widget) Test Cases", async () => {
    const txtSclTcktRcvd = screen.getByTestId("txtSclTcktRcvd");
    expect(txtSclTcktRcvd.tagName).toBe("INPUT");
    expect(txtSclTcktRcvd.type).toBe("text");
    expect(txtSclTcktRcvd.classList).toContain("textboxWidgetClass");
    expect(txtSclTcktRcvd.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtSclTcktRcvd")
    );
    await act(async () => {
      userEvent.type(txtSclTcktRcvd, "1");
    });
  });
  test("Custom Test Cases for txtSclTcktRcvd", () => {
    // START_USER_CODE-USER_txtSclTcktRcvd_TEST
    // END_USER_CODE-USER_txtSclTcktRcvd_TEST
  });
  test("txtSclTcktShip(Textbox Widget) Test Cases", async () => {
    const txtSclTcktShip = screen.getByTestId("txtSclTcktShip");
    expect(txtSclTcktShip.tagName).toBe("INPUT");
    expect(txtSclTcktShip.type).toBe("text");
    expect(txtSclTcktShip.classList).toContain("textboxWidgetClass");
    expect(txtSclTcktShip.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtSclTcktShip")
    );
    await act(async () => {
      userEvent.type(txtSclTcktShip, "1");
    });
  });
  test("Custom Test Cases for txtSclTcktShip", () => {
    // START_USER_CODE-USER_txtSclTcktShip_TEST
    // END_USER_CODE-USER_txtSclTcktShip_TEST
  });
  test("txtSegShppdFrm(Textbox Widget) Test Cases", async () => {
    const txtSegShppdFrm = screen.getByTestId("txtSegShppdFrm");
    expect(txtSegShppdFrm.tagName).toBe("INPUT");
    expect(txtSegShppdFrm.type).toBe("text");
    expect(txtSegShppdFrm.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSegShppdFrm, "1");
    });
  });
  test("Custom Test Cases for txtSegShppdFrm", () => {
    // START_USER_CODE-USER_txtSegShppdFrm_TEST
    // END_USER_CODE-USER_txtSegShppdFrm_TEST
  });
  test("txtSegShppdTo(Textbox Widget) Test Cases", async () => {
    const txtSegShppdTo = screen.getByTestId("txtSegShppdTo");
    expect(txtSegShppdTo.tagName).toBe("INPUT");
    expect(txtSegShppdTo.type).toBe("text");
    expect(txtSegShppdTo.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSegShppdTo, "1");
    });
  });
  test("Custom Test Cases for txtSegShppdTo", () => {
    // START_USER_CODE-USER_txtSegShppdTo_TEST
    // END_USER_CODE-USER_txtSegShppdTo_TEST
  });
  test("txtSMK1Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK1Gr = screen.getByTestId("txtSMK1Gr");
    expect(txtSMK1Gr.tagName).toBe("INPUT");
    expect(txtSMK1Gr.type).toBe("text");
    expect(txtSMK1Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK1Gr.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtSMK1Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK1Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK1Gr", () => {
    // START_USER_CODE-USER_txtSMK1Gr_TEST
    // END_USER_CODE-USER_txtSMK1Gr_TEST
  });
  test("txtSMK1Gr2(Textbox Widget) Test Cases", async () => {
    const txtSMK1Gr2 = screen.getByTestId("txtSMK1Gr2");
    expect(txtSMK1Gr2.tagName).toBe("INPUT");
    expect(txtSMK1Gr2.type).toBe("text");
    expect(txtSMK1Gr2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK1Gr2, "1");
    });
  });
  test("Custom Test Cases for txtSMK1Gr2", () => {
    // START_USER_CODE-USER_txtSMK1Gr2_TEST
    // END_USER_CODE-USER_txtSMK1Gr2_TEST
  });
  test("txtSMK1Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK1Pct = screen.getByTestId("txtSMK1Pct");
    expect(txtSMK1Pct.tagName).toBe("INPUT");
    expect(txtSMK1Pct.type).toBe("text");
    expect(txtSMK1Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK1Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK1Pct", () => {
    // START_USER_CODE-USER_txtSMK1Pct_TEST
    // END_USER_CODE-USER_txtSMK1Pct_TEST
  });
  test("txtSMK2Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK2Gr = screen.getByTestId("txtSMK2Gr");
    expect(txtSMK2Gr.tagName).toBe("INPUT");
    expect(txtSMK2Gr.type).toBe("text");
    expect(txtSMK2Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK2Gr.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtSMK2Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK2Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK2Gr", () => {
    // START_USER_CODE-USER_txtSMK2Gr_TEST
    // END_USER_CODE-USER_txtSMK2Gr_TEST
  });
  test("txtSMK2Gr2(Textbox Widget) Test Cases", async () => {
    const txtSMK2Gr2 = screen.getByTestId("txtSMK2Gr2");
    expect(txtSMK2Gr2.tagName).toBe("INPUT");
    expect(txtSMK2Gr2.type).toBe("text");
    expect(txtSMK2Gr2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK2Gr2, "1");
    });
  });
  test("Custom Test Cases for txtSMK2Gr2", () => {
    // START_USER_CODE-USER_txtSMK2Gr2_TEST
    // END_USER_CODE-USER_txtSMK2Gr2_TEST
  });
  test("txtSMK2Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK2Pct = screen.getByTestId("txtSMK2Pct");
    expect(txtSMK2Pct.tagName).toBe("INPUT");
    expect(txtSMK2Pct.type).toBe("text");
    expect(txtSMK2Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK2Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK2Pct", () => {
    // START_USER_CODE-USER_txtSMK2Pct_TEST
    // END_USER_CODE-USER_txtSMK2Pct_TEST
  });
  test("txtSMK3Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK3Gr = screen.getByTestId("txtSMK3Gr");
    expect(txtSMK3Gr.tagName).toBe("INPUT");
    expect(txtSMK3Gr.type).toBe("text");
    expect(txtSMK3Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK3Gr.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtSMK3Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK3Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK3Gr", () => {
    // START_USER_CODE-USER_txtSMK3Gr_TEST
    // END_USER_CODE-USER_txtSMK3Gr_TEST
  });
  test("txtSMK3Gr2(Textbox Widget) Test Cases", async () => {
    const txtSMK3Gr2 = screen.getByTestId("txtSMK3Gr2");
    expect(txtSMK3Gr2.tagName).toBe("INPUT");
    expect(txtSMK3Gr2.type).toBe("text");
    expect(txtSMK3Gr2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK3Gr2, "1");
    });
  });
  test("Custom Test Cases for txtSMK3Gr2", () => {
    // START_USER_CODE-USER_txtSMK3Gr2_TEST
    // END_USER_CODE-USER_txtSMK3Gr2_TEST
  });
  test("txtSMK3Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK3Pct = screen.getByTestId("txtSMK3Pct");
    expect(txtSMK3Pct.tagName).toBe("INPUT");
    expect(txtSMK3Pct.type).toBe("text");
    expect(txtSMK3Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK3Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK3Pct", () => {
    // START_USER_CODE-USER_txtSMK3Pct_TEST
    // END_USER_CODE-USER_txtSMK3Pct_TEST
  });
  test("txtSMK4Gr(Textbox Widget) Test Cases", async () => {
    const txtSMK4Gr = screen.getByTestId("txtSMK4Gr");
    expect(txtSMK4Gr.tagName).toBe("INPUT");
    expect(txtSMK4Gr.type).toBe("text");
    expect(txtSMK4Gr.classList).toContain("textboxWidgetClass");
    expect(txtSMK4Gr.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtSMK4Gr")
    );
    await act(async () => {
      userEvent.type(txtSMK4Gr, "1");
    });
  });
  test("Custom Test Cases for txtSMK4Gr", () => {
    // START_USER_CODE-USER_txtSMK4Gr_TEST
    // END_USER_CODE-USER_txtSMK4Gr_TEST
  });
  test("txtSMK4Gr2(Textbox Widget) Test Cases", async () => {
    const txtSMK4Gr2 = screen.getByTestId("txtSMK4Gr2");
    expect(txtSMK4Gr2.tagName).toBe("INPUT");
    expect(txtSMK4Gr2.type).toBe("text");
    expect(txtSMK4Gr2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK4Gr2, "1");
    });
  });
  test("Custom Test Cases for txtSMK4Gr2", () => {
    // START_USER_CODE-USER_txtSMK4Gr2_TEST
    // END_USER_CODE-USER_txtSMK4Gr2_TEST
  });
  test("txtSMK4Pct(Textbox Widget) Test Cases", async () => {
    const txtSMK4Pct = screen.getByTestId("txtSMK4Pct");
    expect(txtSMK4Pct.tagName).toBe("INPUT");
    expect(txtSMK4Pct.type).toBe("text");
    expect(txtSMK4Pct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSMK4Pct, "1");
    });
  });
  test("Custom Test Cases for txtSMK4Pct", () => {
    // START_USER_CODE-USER_txtSMK4Pct_TEST
    // END_USER_CODE-USER_txtSMK4Pct_TEST
  });
  test("txtSoundSplits(Textbox Widget) Test Cases", async () => {
    const txtSoundSplits = screen.getByTestId("txtSoundSplits");
    expect(txtSoundSplits.tagName).toBe("INPUT");
    expect(txtSoundSplits.type).toBe("text");
    expect(txtSoundSplits.classList).toContain("textboxWidgetClass");
    expect(txtSoundSplits.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtSoundSplits")
    );
    await act(async () => {
      userEvent.type(txtSoundSplits, "1");
    });
  });
  test("Custom Test Cases for txtSoundSplits", () => {
    // START_USER_CODE-USER_txtSoundSplits_TEST
    // END_USER_CODE-USER_txtSoundSplits_TEST
  });
  test("txtSoundSplitsPct(Textbox Widget) Test Cases", async () => {
    const txtSoundSplitsPct = screen.getByTestId("txtSoundSplitsPct");
    expect(txtSoundSplitsPct.tagName).toBe("INPUT");
    expect(txtSoundSplitsPct.type).toBe("text");
    expect(txtSoundSplitsPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSoundSplitsPct, "1");
    });
  });
  test("Custom Test Cases for txtSoundSplitsPct", () => {
    // START_USER_CODE-USER_txtSoundSplitsPct_TEST
    // END_USER_CODE-USER_txtSoundSplitsPct_TEST
  });
  test("txtTotalDamage(Textbox Widget) Test Cases", async () => {
    const txtTotalDamage = screen.getByTestId("txtTotalDamage");
    expect(txtTotalDamage.tagName).toBe("INPUT");
    expect(txtTotalDamage.type).toBe("text");
    expect(txtTotalDamage.classList).toContain("textboxWidgetClass");
    expect(txtTotalDamage.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtTotalDamage")
    );
    await act(async () => {
      userEvent.type(txtTotalDamage, "1");
    });
  });
  test("Custom Test Cases for txtTotalDamage", () => {
    // START_USER_CODE-USER_txtTotalDamage_TEST
    // END_USER_CODE-USER_txtTotalDamage_TEST
  });
  test("txtTotalDamagePct(Textbox Widget) Test Cases", async () => {
    const txtTotalDamagePct = screen.getByTestId("txtTotalDamagePct");
    expect(txtTotalDamagePct.tagName).toBe("INPUT");
    expect(txtTotalDamagePct.type).toBe("text");
    expect(txtTotalDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtTotalDamagePct", () => {
    // START_USER_CODE-USER_txtTotalDamagePct_TEST
    // END_USER_CODE-USER_txtTotalDamagePct_TEST
  });
  test("txtTotalELKDamage(Textbox Widget) Test Cases", async () => {
    const txtTotalELKDamage = screen.getByTestId("txtTotalELKDamage");
    expect(txtTotalELKDamage.tagName).toBe("INPUT");
    expect(txtTotalELKDamage.type).toBe("text");
    expect(txtTotalELKDamage.classList).toContain("textboxWidgetClass");
    expect(txtTotalELKDamage.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtTotalELKDamage")
    );
    await act(async () => {
      userEvent.type(txtTotalELKDamage, "1");
    });
  });
  test("Custom Test Cases for txtTotalELKDamage", () => {
    // START_USER_CODE-USER_txtTotalELKDamage_TEST
    // END_USER_CODE-USER_txtTotalELKDamage_TEST
  });
  test("txtTotalELKDamagePct(Textbox Widget) Test Cases", async () => {
    const txtTotalELKDamagePct = screen.getByTestId("txtTotalELKDamagePct");
    expect(txtTotalELKDamagePct.tagName).toBe("INPUT");
    expect(txtTotalELKDamagePct.type).toBe("text");
    expect(txtTotalELKDamagePct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalELKDamagePct, "1");
    });
  });
  test("Custom Test Cases for txtTotalELKDamagePct", () => {
    // START_USER_CODE-USER_txtTotalELKDamagePct_TEST
    // END_USER_CODE-USER_txtTotalELKDamagePct_TEST
  });
  test("txtTotalKernels(Textbox Widget) Test Cases", async () => {
    const txtTotalKernels = screen.getByTestId("txtTotalKernels");
    expect(txtTotalKernels.tagName).toBe("INPUT");
    expect(txtTotalKernels.type).toBe("text");
    expect(txtTotalKernels.classList).toContain("textboxWidgetClass");
    expect(txtTotalKernels.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtTotalKernels")
    );
    await act(async () => {
      userEvent.type(txtTotalKernels, "1");
    });
  });
  test("Custom Test Cases for txtTotalKernels", () => {
    // START_USER_CODE-USER_txtTotalKernels_TEST
    // END_USER_CODE-USER_txtTotalKernels_TEST
  });
  test("txtTotalKernels2(Textbox Widget) Test Cases", async () => {
    const txtTotalKernels2 = screen.getByTestId("txtTotalKernels2");
    expect(txtTotalKernels2.tagName).toBe("INPUT");
    expect(txtTotalKernels2.type).toBe("text");
    expect(txtTotalKernels2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalKernels2, "1");
    });
  });
  test("Custom Test Cases for txtTotalKernels2", () => {
    // START_USER_CODE-USER_txtTotalKernels2_TEST
    // END_USER_CODE-USER_txtTotalKernels2_TEST
  });
  test("txtTotalKernelsAndHulls(Textbox Widget) Test Cases", async () => {
    const txtTotalKernelsAndHulls = screen.getByTestId(
      "txtTotalKernelsAndHulls"
    );
    expect(txtTotalKernelsAndHulls.tagName).toBe("INPUT");
    expect(txtTotalKernelsAndHulls.type).toBe("text");
    expect(txtTotalKernelsAndHulls.classList).toContain("textboxWidgetClass");
    expect(txtTotalKernelsAndHulls.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtTotalKernelsAndHulls")
    );
    await act(async () => {
      userEvent.type(txtTotalKernelsAndHulls, "1");
    });
  });
  test("Custom Test Cases for txtTotalKernelsAndHulls", () => {
    // START_USER_CODE-USER_txtTotalKernelsAndHulls_TEST
    // END_USER_CODE-USER_txtTotalKernelsAndHulls_TEST
  });
  test("txtTotalKernelsAndHulls2(Textbox Widget) Test Cases", async () => {
    const txtTotalKernelsAndHulls2 = screen.getByTestId(
      "txtTotalKernelsAndHulls2"
    );
    expect(txtTotalKernelsAndHulls2.tagName).toBe("INPUT");
    expect(txtTotalKernelsAndHulls2.type).toBe("text");
    expect(txtTotalKernelsAndHulls2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalKernelsAndHulls2, "1");
    });
  });
  test("Custom Test Cases for txtTotalKernelsAndHulls2", () => {
    // START_USER_CODE-USER_txtTotalKernelsAndHulls2_TEST
    // END_USER_CODE-USER_txtTotalKernelsAndHulls2_TEST
  });
  test("txtTotalOK(Textbox Widget) Test Cases", async () => {
    const txtTotalOK = screen.getByTestId("txtTotalOK");
    expect(txtTotalOK.tagName).toBe("INPUT");
    expect(txtTotalOK.type).toBe("text");
    expect(txtTotalOK.classList).toContain("textboxWidgetClass");
    expect(txtTotalOK.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtTotalOK")
    );
    await act(async () => {
      userEvent.type(txtTotalOK, "1");
    });
  });
  test("Custom Test Cases for txtTotalOK", () => {
    // START_USER_CODE-USER_txtTotalOK_TEST
    // END_USER_CODE-USER_txtTotalOK_TEST
  });
  test("txtTotalOKPct(Textbox Widget) Test Cases", async () => {
    const txtTotalOKPct = screen.getByTestId("txtTotalOKPct");
    expect(txtTotalOKPct.tagName).toBe("INPUT");
    expect(txtTotalOKPct.type).toBe("text");
    expect(txtTotalOKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalOKPct, "1");
    });
  });
  test("Custom Test Cases for txtTotalOKPct", () => {
    // START_USER_CODE-USER_txtTotalOKPct_TEST
    // END_USER_CODE-USER_txtTotalOKPct_TEST
  });
  test("txtTotalSLK(Textbox Widget) Test Cases", async () => {
    const txtTotalSLK = screen.getByTestId("txtTotalSLK");
    expect(txtTotalSLK.tagName).toBe("INPUT");
    expect(txtTotalSLK.type).toBe("text");
    expect(txtTotalSLK.classList).toContain("textboxWidgetClass");
    expect(txtTotalSLK.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtTotalSLK")
    );
    await act(async () => {
      userEvent.type(txtTotalSLK, "1");
    });
  });
  test("Custom Test Cases for txtTotalSLK", () => {
    // START_USER_CODE-USER_txtTotalSLK_TEST
    // END_USER_CODE-USER_txtTotalSLK_TEST
  });
  test("txtTotalSLKPct(Textbox Widget) Test Cases", async () => {
    const txtTotalSLKPct = screen.getByTestId("txtTotalSLKPct");
    expect(txtTotalSLKPct.tagName).toBe("INPUT");
    expect(txtTotalSLKPct.type).toBe("text");
    expect(txtTotalSLKPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalSLKPct, "1");
    });
  });
  test("Custom Test Cases for txtTotalSLKPct", () => {
    // START_USER_CODE-USER_txtTotalSLKPct_TEST
    // END_USER_CODE-USER_txtTotalSLKPct_TEST
  });
  test("txtTotalSMK(Textbox Widget) Test Cases", async () => {
    const txtTotalSMK = screen.getByTestId("txtTotalSMK");
    expect(txtTotalSMK.tagName).toBe("INPUT");
    expect(txtTotalSMK.type).toBe("text");
    expect(txtTotalSMK.classList).toContain("textboxWidgetClass");
    expect(txtTotalSMK.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtTotalSMK")
    );
    await act(async () => {
      userEvent.type(txtTotalSMK, "1");
    });
  });
  test("Custom Test Cases for txtTotalSMK", () => {
    // START_USER_CODE-USER_txtTotalSMK_TEST
    // END_USER_CODE-USER_txtTotalSMK_TEST
  });
  test("txtTotalSMK2(Textbox Widget) Test Cases", async () => {
    const txtTotalSMK2 = screen.getByTestId("txtTotalSMK2");
    expect(txtTotalSMK2.tagName).toBe("INPUT");
    expect(txtTotalSMK2.type).toBe("text");
    expect(txtTotalSMK2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalSMK2, "1");
    });
  });
  test("Custom Test Cases for txtTotalSMK2", () => {
    // START_USER_CODE-USER_txtTotalSMK2_TEST
    // END_USER_CODE-USER_txtTotalSMK2_TEST
  });
  test("txtTotalSMKRScreen(Textbox Widget) Test Cases", async () => {
    const txtTotalSMKRScreen = screen.getByTestId("txtTotalSMKRScreen");
    expect(txtTotalSMKRScreen.tagName).toBe("INPUT");
    expect(txtTotalSMKRScreen.type).toBe("text");
    expect(txtTotalSMKRScreen.classList).toContain("textboxWidgetClass");
    expect(txtTotalSMKRScreen.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtTotalSMKRScreen")
    );
    await act(async () => {
      userEvent.type(txtTotalSMKRScreen, "1");
    });
  });
  test("Custom Test Cases for txtTotalSMKRScreen", () => {
    // START_USER_CODE-USER_txtTotalSMKRScreen_TEST
    // END_USER_CODE-USER_txtTotalSMKRScreen_TEST
  });
  test("txtTotalSMKRScreenPct(Textbox Widget) Test Cases", async () => {
    const txtTotalSMKRScreenPct = screen.getByTestId("txtTotalSMKRScreenPct");
    expect(txtTotalSMKRScreenPct.tagName).toBe("INPUT");
    expect(txtTotalSMKRScreenPct.type).toBe("text");
    expect(txtTotalSMKRScreenPct.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTotalSMKRScreenPct, "1");
    });
  });
  test("Custom Test Cases for txtTotalSMKRScreenPct", () => {
    // START_USER_CODE-USER_txtTotalSMKRScreenPct_TEST
    // END_USER_CODE-USER_txtTotalSMKRScreenPct_TEST
  });
  test("txtTruckLine(Textbox Widget) Test Cases", async () => {
    const txtTruckLine = screen.getByTestId("txtTruckLine");
    expect(txtTruckLine.tagName).toBe("INPUT");
    expect(txtTruckLine.type).toBe("text");
    expect(txtTruckLine.classList).toContain("textboxWidgetClass");
    expect(txtTruckLine.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtTruckLine")
    );
    await act(async () => {
      userEvent.type(txtTruckLine, "1");
    });
  });
  test("Custom Test Cases for txtTruckLine", () => {
    // START_USER_CODE-USER_txtTruckLine_TEST
    // END_USER_CODE-USER_txtTruckLine_TEST
  });
  test("txtVarietyShppdFrm(Textbox Widget) Test Cases", async () => {
    const txtVarietyShppdFrm = screen.getByTestId("txtVarietyShppdFrm");
    expect(txtVarietyShppdFrm.tagName).toBe("INPUT");
    expect(txtVarietyShppdFrm.type).toBe("text");
    expect(txtVarietyShppdFrm.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVarietyShppdFrm, "1");
    });
  });
  test("Custom Test Cases for txtVarietyShppdFrm", () => {
    // START_USER_CODE-USER_txtVarietyShppdFrm_TEST
    // END_USER_CODE-USER_txtVarietyShppdFrm_TEST
  });
  test("txtVarietyShppdTo(Textbox Widget) Test Cases", async () => {
    const txtVarietyShppdTo = screen.getByTestId("txtVarietyShppdTo");
    expect(txtVarietyShppdTo.tagName).toBe("INPUT");
    expect(txtVarietyShppdTo.type).toBe("text");
    expect(txtVarietyShppdTo.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVarietyShppdTo, "1");
    });
  });
  test("Custom Test Cases for txtVarietyShppdTo", () => {
    // START_USER_CODE-USER_txtVarietyShppdTo_TEST
    // END_USER_CODE-USER_txtVarietyShppdTo_TEST
  });
  test("txtVehicle(Textbox Widget) Test Cases", async () => {
    const txtVehicle = screen.getByTestId("txtVehicle");
    expect(txtVehicle.tagName).toBe("INPUT");
    expect(txtVehicle.type).toBe("text");
    expect(txtVehicle.classList).toContain("textboxWidgetClass");
    expect(txtVehicle.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtVehicle")
    );
    await act(async () => {
      userEvent.type(txtVehicle, "1");
    });
  });
  test("Custom Test Cases for txtVehicle", () => {
    // START_USER_CODE-USER_txtVehicle_TEST
    // END_USER_CODE-USER_txtVehicle_TEST
  });
  test("txtVicamReading(Textbox Widget) Test Cases", async () => {
    const txtVicamReading = screen.getByTestId("txtVicamReading");
    expect(txtVicamReading.tagName).toBe("INPUT");
    expect(txtVicamReading.type).toBe("text");
    expect(txtVicamReading.classList).toContain("textboxWidgetClass");
    expect(txtVicamReading.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtVicamReading")
    );
    await act(async () => {
      userEvent.type(txtVicamReading, "1");
    });
  });
  test("Custom Test Cases for txtVicamReading", () => {
    // START_USER_CODE-USER_txtVicamReading_TEST
    // END_USER_CODE-USER_txtVicamReading_TEST
  });
  test("txtVndrId(Textbox Widget) Test Cases", async () => {
    const txtVndrId = screen.getByTestId("txtVndrId");
    expect(txtVndrId.tagName).toBe("INPUT");
    expect(txtVndrId.type).toBe("text");
    expect(txtVndrId.classList).toContain("textboxWidgetClass");
    expect(txtVndrId.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtVndrId")
    );
    await act(async () => {
      userEvent.type(txtVndrId, "1");
    });
  });
  test("Custom Test Cases for txtVndrId", () => {
    // START_USER_CODE-USER_txtVndrId_TEST
    // END_USER_CODE-USER_txtVndrId_TEST
  });
  test("txtWhseBinShippdFrm(Textbox Widget) Test Cases", async () => {
    const txtWhseBinShippdFrm = screen.getByTestId("txtWhseBinShippdFrm");
    expect(txtWhseBinShippdFrm.tagName).toBe("INPUT");
    expect(txtWhseBinShippdFrm.type).toBe("text");
    expect(txtWhseBinShippdFrm.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtWhseBinShippdFrm, "1");
    });
  });
  test("Custom Test Cases for txtWhseBinShippdFrm", () => {
    // START_USER_CODE-USER_txtWhseBinShippdFrm_TEST
    // END_USER_CODE-USER_txtWhseBinShippdFrm_TEST
  });
  test("txtWhseBinShippdTo(Textbox Widget) Test Cases", async () => {
    const txtWhseBinShippdTo = screen.getByTestId("txtWhseBinShippdTo");
    expect(txtWhseBinShippdTo.tagName).toBe("INPUT");
    expect(txtWhseBinShippdTo.type).toBe("text");
    expect(txtWhseBinShippdTo.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtWhseBinShippdTo, "1");
    });
  });
  test("Custom Test Cases for txtWhseBinShippdTo", () => {
    // START_USER_CODE-USER_txtWhseBinShippdTo_TEST
    // END_USER_CODE-USER_txtWhseBinShippdTo_TEST
  });
  test("txtWt(Textbox Widget) Test Cases", async () => {
    const txtWt = screen.getByTestId("txtWt");
    expect(txtWt.tagName).toBe("INPUT");
    expect(txtWt.type).toBe("text");
    expect(txtWt.classList).toContain("textboxWidgetClass");
    expect(txtWt.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtWt")
    );
    await act(async () => {
      userEvent.type(txtWt, "1");
    });
  });
  test("Custom Test Cases for txtWt", () => {
    // START_USER_CODE-USER_txtWt_TEST
    // END_USER_CODE-USER_txtWt_TEST
  });
  test("txtWtInclVehclRecvd(Textbox Widget) Test Cases", async () => {
    const txtWtInclVehclRecvd = screen.getByTestId("txtWtInclVehclRecvd");
    expect(txtWtInclVehclRecvd.tagName).toBe("INPUT");
    expect(txtWtInclVehclRecvd.type).toBe("text");
    expect(txtWtInclVehclRecvd.classList).toContain("textboxWidgetClass");
    expect(txtWtInclVehclRecvd.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtWtInclVehclRecvd")
    );
    await act(async () => {
      userEvent.type(txtWtInclVehclRecvd, "1");
    });
  });
  test("Custom Test Cases for txtWtInclVehclRecvd", () => {
    // START_USER_CODE-USER_txtWtInclVehclRecvd_TEST
    // END_USER_CODE-USER_txtWtInclVehclRecvd_TEST
  });
  test("txtWtLessFm(Textbox Widget) Test Cases", async () => {
    const txtWtLessFm = screen.getByTestId("txtWtLessFm");
    expect(txtWtLessFm.tagName).toBe("INPUT");
    expect(txtWtLessFm.type).toBe("text");
    expect(txtWtLessFm.classList).toContain("textboxWidgetClass");
    expect(txtWtLessFm.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtWtLessFm")
    );
    await act(async () => {
      userEvent.type(txtWtLessFm, "1");
    });
  });
  test("Custom Test Cases for txtWtLessFm", () => {
    // START_USER_CODE-USER_txtWtLessFm_TEST
    // END_USER_CODE-USER_txtWtLessFm_TEST
  });
  test("txtWtVehclShip(Textbox Widget) Test Cases", async () => {
    const txtWtVehclShip = screen.getByTestId("txtWtVehclShip");
    expect(txtWtVehclShip.tagName).toBe("INPUT");
    expect(txtWtVehclShip.type).toBe("text");
    expect(txtWtVehclShip.classList).toContain("textboxWidgetClass");
    expect(txtWtVehclShip.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtWtVehclShip")
    );
    await act(async () => {
      userEvent.type(txtWtVehclShip, "1");
    });
  });
  test("Custom Test Cases for txtWtVehclShip", () => {
    // START_USER_CODE-USER_txtWtVehclShip_TEST
    // END_USER_CODE-USER_txtWtVehclShip_TEST
  });
  test("txtWtVehicleShip(Textbox Widget) Test Cases", async () => {
    const txtWtVehicleShip = screen.getByTestId("txtWtVehicleShip");
    expect(txtWtVehicleShip.tagName).toBe("INPUT");
    expect(txtWtVehicleShip.type).toBe("text");
    expect(txtWtVehicleShip.classList).toContain("textboxWidgetClass");
    expect(txtWtVehicleShip.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtWtVehicleShip")
    );
    await act(async () => {
      userEvent.type(txtWtVehicleShip, "1");
    });
  });
  test("Custom Test Cases for txtWtVehicleShip", () => {
    // START_USER_CODE-USER_txtWtVehicleShip_TEST
    // END_USER_CODE-USER_txtWtVehicleShip_TEST
  });
  test("txtWtVhclRcvd(Textbox Widget) Test Cases", async () => {
    const txtWtVhclRcvd = screen.getByTestId("txtWtVhclRcvd");
    expect(txtWtVhclRcvd.tagName).toBe("INPUT");
    expect(txtWtVhclRcvd.type).toBe("text");
    expect(txtWtVhclRcvd.classList).toContain("textboxWidgetClass");
    expect(txtWtVhclRcvd.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txtWtVhclRcvd")
    );
    await act(async () => {
      userEvent.type(txtWtVhclRcvd, "1");
    });
  });
  test("Custom Test Cases for txtWtVhclRcvd", () => {
    // START_USER_CODE-USER_txtWtVhclRcvd_TEST
    // END_USER_CODE-USER_txtWtVhclRcvd_TEST
  });
  test("txxtDriver(Textbox Widget) Test Cases", async () => {
    const txxtDriver = screen.getByTestId("txxtDriver");
    expect(txxtDriver.tagName).toBe("INPUT");
    expect(txxtDriver.type).toBe("text");
    expect(txxtDriver.classList).toContain("textboxWidgetClass");
    expect(txxtDriver.previousElementSibling.textContent).toEqual(
      t("StockTransfer:StockTransfer_txxtDriver")
    );
    await act(async () => {
      userEvent.type(txxtDriver, "1");
    });
  });
  test("Custom Test Cases for txxtDriver", () => {
    // START_USER_CODE-USER_txxtDriver_TEST
    // END_USER_CODE-USER_txxtDriver_TEST
  });
});
