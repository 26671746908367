/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  DateWidget,
  getValue,
  setData,
  setValue,
} from "../../shared/WindowImports";

import "./BankHolidayProfile.scss";
import moment, { isDate } from "moment";//For date time

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { DateTime } from "../../widgets/common/DateTime";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import { response } from "msw";
import StripPic from "../../../../assets/img/PinStrip.png";
const useridFromLS = () => (sessionStorage.getItem('userid') || '');
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_BankHolidayProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "BankHolidayProfile",
    windowName: "BankHolidayProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.BankHolidayProfile",
    // START_USER_CODE-USER_BankHolidayProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Bank Holiday Profile",
      scrCode: "PN0150B",
    },
    // END_USER_CODE-USER_BankHolidayProfile_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Add",
      CharWidth: "10",
      Mandatory: true,
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",
      Mandatory: true,
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    dpHolidayDate: {
      name: "dpHolidayDate",
      type: "DateWidget",
      parent: "grpbxBankHolidayProfile",
      Label: "Holiday Date:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dpHolidayDate_PROPERTIES

      // END_USER_CODE-USER_dpHolidayDate_PROPERTIES
    },
    txtHolidayName: {
      name: "txtHolidayName",
      type: "TextBoxWidget",
      parent: "grpbxBankHolidayProfile",
      Label: "Holiday Name:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 30 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHolidayName_PROPERTIES

      // END_USER_CODE-USER_txtHolidayName_PROPERTIES
    },
    grpbxBankHolidayProfile: {
      name: "grpbxBankHolidayProfile",
      type: "GroupBoxWidget",
      parent: "BankHolidayProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxBankHolidayProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxBankHolidayProfile_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "BankHolidayProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  //form validation 
  const bFormValid = async () => {
    var isbformValid = false
      let dpHolidayDateValue = getValue(thisObj, 'dpHolidayDate')
      dpHolidayDateValue = dpHolidayDateValue == undefined || dpHolidayDateValue == null || dpHolidayDateValue == '' ? '' : moment(dpHolidayDateValue).format('MM/DD/YYYY')
      if (dpHolidayDateValue == '') {
        alert('Holiday Date is not valid!  Date Error!')
        return isbformValid;
      }
      // let txtHolidayNameValue = getValue(thisObj, 'txtHolidayName')
      // if (txtHolidayNameValue == undefined ) {
      //   alert('Holiday Name is not valid!   Date Error!')
      //   return isbformValid;
      // }
    isbformValid = true
    return isbformValid
  }

  //ADD button functionality
  const onbtnAddClick = async () => {
     try {
      let validation = false
      await bFormValid().then(response =>{
        validation = response
      })
      if(validation){
        let datefor = getValue(thisObj, 'dpHolidayDate')
        let datavalue = moment(datefor).format('MM/DD/YYYY')
        let value = getValue(thisObj, 'txtHolidayName')
        //API binding
        let dataObj = {}
        dataObj.holiday_date = getValue(thisObj, 'dpHolidayDate')
        dataObj.user_id = useridFromLS()
        dataObj.holiday_name = getValue(thisObj, 'txtHolidayName')
        let response = await SystemMaintenanceSpecialFunctionsService.UpdateBankHolidayControldetails('ADD', dataObj)
        if (![200].includes(response.status)) {
          if(response.message!= undefined && response.message!= null){
            showMessage(thisObj,'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.'+ response.message)
          }
        }
        setData(thisObj,'BankHolidaySetupdata',dataObj)
        setValue(thisObj,'txtHolidayName','')
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "An error occured while add btn click event "
        );
      }   
   }
    return true
    
  }
  thisObj.onbtnAddClick = onbtnAddClick;



  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_BankHolidayProfilePopUp").childNodes[0].click()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, 'An error occured during btnClose event')
      }
    }
  }
  thisObj.onbtnCloseClick = onbtnCloseClick
 

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
            
              {/* START_USER_CODE-USER_BEFORE_BankHolidayProfile*/}

              {/* END_USER_CODE-USER_BEFORE_BankHolidayProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxBankHolidayProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxBankHolidayProfile*/}

              <GroupBoxWidget
                conf={state.grpbxBankHolidayProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_dpHolidayDate*/}

                {/* END_USER_CODE-USER_BEFORE_dpHolidayDate*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dpHolidayDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_dpHolidayDate*/}

                {/* END_USER_CODE-USER_AFTER_dpHolidayDate*/}
                {/* START_USER_CODE-USER_BEFORE_txtHolidayName*/}

                {/* END_USER_CODE-USER_BEFORE_txtHolidayName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHolidayName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHolidayName*/}

                {/* END_USER_CODE-USER_AFTER_txtHolidayName*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxBankHolidayProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxBankHolidayProfile*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_BankHolidayProfile*/}

              {/* END_USER_CODE-USER_AFTER_BankHolidayProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceSpecialFunctions_BankHolidayProfile);
