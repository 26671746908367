/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  setData,
  getData,
  setValue,
  getValue,
  enable,
  disable,
  goTo,
} from "../../shared/WindowImports";

import "./OpenAccountsProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import { SystemMaintenanceMasterManagementService } from "../../SystemMaintenanceMasterManagement/Service/SystemMaintenanceMasterManagementServices"
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { response } from "msw";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_OpenAccountsProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "OpenAccountsProfile",
    windowName: "OpenAccountsProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.OpenAccountsProfile",
    // START_USER_CODE-USER_OpenAccountsProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Open Account Profile",
      scrCode: "PN0160B",
    },
    // END_USER_CODE-USER_OpenAccountsProfile_PROPERTIES
    btnAccountVendor: {
      name: "btnAccountVendor",
      type: "ButtonWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAccountVendor_PROPERTIES

      // END_USER_CODE-USER_btnAccountVendor_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnDeductionTracking: {
      name: "btnDeductionTracking",
      type: "ButtonWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Deduction Tracking",
      CharWidth: "37",
      // START_USER_CODE-USER_btnDeductionTracking_PROPERTIES

      // END_USER_CODE-USER_btnDeductionTracking_PROPERTIES
    },
    btnMakeAdj: {
      name: "btnMakeAdj",
      type: "ButtonWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Make Adj.",
      CharWidth: "21",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnMakeAdj_PROPERTIES

      // END_USER_CODE-USER_btnMakeAdj_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnViewAdj: {
      name: "btnViewAdj",
      type: "ButtonWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "View Adj.",
      CharWidth: "21",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnViewAdj_PROPERTIES

      // END_USER_CODE-USER_btnViewAdj_PROPERTIES
    },
    chkboxCollectFormSettlement: {
      name: "chkboxCollectFormSettlement",
      type: "CheckBoxWidget",
      parent: "grpbxOpenAccountsProfile",
      ColSpan: "3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCollectFormSettlement_PROPERTIES

      // END_USER_CODE-USER_chkboxCollectFormSettlement_PROPERTIES
    },
    ddAccountRemitTo: {
      name: "ddAccountRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Account Remit To:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAccountRemitTo_PROPERTIES

      // END_USER_CODE-USER_ddAccountRemitTo_PROPERTIES
    },
    lblAcctReceivableAdj: {
      name: "lblAcctReceivableAdj",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAcctReceivableAdj_PROPERTIES

      // END_USER_CODE-USER_lblAcctReceivableAdj_PROPERTIES
    },
    lblAcctReceivableCB: {
      name: "lblAcctReceivableCB",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAcctReceivableCB_PROPERTIES

      // END_USER_CODE-USER_lblAcctReceivableCB_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblAdjustments: {
      name: "lblAdjustments",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Adjustments",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAdjustments_PROPERTIES

      // END_USER_CODE-USER_lblAdjustments_PROPERTIES
    },
    lblBalances: {
      name: "lblBalances",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Balances",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBalances_PROPERTIES

      // END_USER_CODE-USER_lblBalances_PROPERTIES
    },
    lblBeginningBalance: {
      name: "lblBeginningBalance",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Beginning Balance",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBeginningBalance_PROPERTIES

      // END_USER_CODE-USER_lblBeginningBalance_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblCollectFormSettlement: {
      name: "lblCollectFormSettlement",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Collect From Settlement",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCollectFormSettlement_PROPERTIES

      // END_USER_CODE-USER_lblCollectFormSettlement_PROPERTIES
    },
    lblCommReceivableAdj: {
      name: "lblCommReceivableAdj",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCommReceivableAdj_PROPERTIES

      // END_USER_CODE-USER_lblCommReceivableAdj_PROPERTIES
    },
    lblCommReceivableCB: {
      name: "lblCommReceivableCB",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCommReceivableCB_PROPERTIES

      // END_USER_CODE-USER_lblCommReceivableCB_PROPERTIES
    },
    lblCurrentBalance: {
      name: "lblCurrentBalance",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Current. Balance",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCurrentBalance_PROPERTIES

      // END_USER_CODE-USER_lblCurrentBalance_PROPERTIES
    },
    lblOpenBalanceAdj: {
      name: "lblOpenBalanceAdj",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOpenBalanceAdj_PROPERTIES

      // END_USER_CODE-USER_lblOpenBalanceAdj_PROPERTIES
    },
    lblOpenBalanceBB: {
      name: "lblOpenBalanceBB",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOpenBalanceBB_PROPERTIES

      // END_USER_CODE-USER_lblOpenBalanceBB_PROPERTIES
    },
    lblOpenBalanceCB: {
      name: "lblOpenBalanceCB",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOpenBalanceCB_PROPERTIES

      // END_USER_CODE-USER_lblOpenBalanceCB_PROPERTIES
    },
    lblOpenBalance: {
      name: "lblOpenBalance",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Open Balance:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOpenBalance_PROPERTIES

      // END_USER_CODE-USER_lblOpenBalance_PROPERTIES
    },
    lblSeedReceivableAdj: {
      name: "lblSeedReceivableAdj",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSeedReceivableAdj_PROPERTIES

      // END_USER_CODE-USER_lblSeedReceivableAdj_PROPERTIES
    },
    lblSeedReceivableCB: {
      name: "lblSeedReceivableCB",
      type: "LabelWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSeedReceivableCB_PROPERTIES

      // END_USER_CODE-USER_lblSeedReceivableCB_PROPERTIES
    },
    txtAccountVendor: {
      name: "txtAccountVendor",
      type: "TextBoxWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Account Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccountVendor_PROPERTIES

      // END_USER_CODE-USER_txtAccountVendor_PROPERTIES
    },
    txtAcctReceivableBB: {
      name: "txtAcctReceivableBB",
      type: "TextBoxWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Acct. Receivable:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 11 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAcctReceivableBB_PROPERTIES

      // END_USER_CODE-USER_txtAcctReceivableBB_PROPERTIES
    },
    txtAcctVndr: {
      name: "txtAcctVndr",
      type: "TextBoxWidget",
      parent: "grpbxOpenAccountsProfile",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAcctVndr_PROPERTIES

      // END_USER_CODE-USER_txtAcctVndr_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Buying Point:",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    txtCollectionAmount: {
      name: "txtCollectionAmount",
      type: "TextBoxWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Collection Amount:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 11 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCollectionAmount_PROPERTIES

      // END_USER_CODE-USER_txtCollectionAmount_PROPERTIES
    },
    txtCollectionPercent: {
      name: "txtCollectionPercent",
      type: "TextBoxWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Collection Percent:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCollectionPercent_PROPERTIES

      // END_USER_CODE-USER_txtCollectionPercent_PROPERTIES
    },
    txtCommReceivableBB: {
      name: "txtCommReceivableBB",
      type: "TextBoxWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Comm. Receivable:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 11 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCommReceivableBB_PROPERTIES

      // END_USER_CODE-USER_txtCommReceivableBB_PROPERTIES
    },
    txtSeedReceivableBB: {
      name: "txtSeedReceivableBB",
      type: "TextBoxWidget",
      parent: "grpbxOpenAccountsProfile",
      Label: "Seed Receivable:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 11 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedReceivableBB_PROPERTIES

      // END_USER_CODE-USER_txtSeedReceivableBB_PROPERTIES
    },
    grpbxOpenAccountsProfile: {
      name: "grpbxOpenAccountsProfile",
      type: "GroupBoxWidget",
      parent: "OpenAccountsProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxOpenAccountsProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxOpenAccountsProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "OpenAccountsProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceApplicationSupport#OpenAccountAdjustments": {},
        "SystemMaintenanceApplicationSupport#OpenAdjustmentEntry": {},
        "SystemMaintenanceApplicationSupport#DeductionTrackingSummary": {},
      },
      REVERSE: {
        "SystemMaintenanceApplicationSupport#OpenAccountAdjustments": {},
        "SystemMaintenanceApplicationSupport#OpenAdjustmentEntry": {},
        "SystemMaintenanceApplicationSupport#DeductionTrackingSummary": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnViewAdj: {
      DEFAULT: [
        "SystemMaintenanceApplicationSupport#OpenAccountAdjustments#DEFAULT#true#Click",
      ],
    },
    btnMakeAdj: {
      DEFAULT: [
        "SystemMaintenanceApplicationSupport#OpenAdjustmentEntry#DEFAULT#true#Click",
      ],
    },
    // btnDeductionTracking: {
    //   DEFAULT: [
    //     "SystemMaintenanceApplicationSupport#DeductionTrackingSummary#DEFAULT#true#Click",
    //   ],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
  let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null && vendor_lookup !== undefined && vendor_lookup !== '') {
      thisObj.setFieldValue("txtAccountVendor", vendor_lookup.VendorNumber);
      let VenNumber = vendor_lookup.VendorNumber
      bFillAccountRemitToList(vendor_lookup.VendorNumber)
      thisObj.setFieldValue("txtAcctVndr", ConvertToPascalCase(vendor_lookup.vendorName))
      let js = [];
      js.push({ key: '', description: '' }) //Set default value
        ContractManagementService.RetieveRemitDetails(VenNumber).then(response => {
          if (response != undefined && response.length > 0) {
            let data = response
            for (var i = 0; i < data.length; i++) {
              var vendorName = data[i].name
              let obj = { key: data[i].remittoid, description: data[i].remittoid  + " - " + ConvertToPascalCase(data[i].name) + " - " + ConvertToPascalCase(data[i].city) + ", " + ConvertToPascalCase(data[i].state) }
              js.push(obj)
            }
            setValue(thisObj, "txtAcctVndr", ConvertToPascalCase(vendorName))
            setValue(thisObj, "ddAccountRemitTo", js.at(1).key);
            setData(thisObj, "remitToLength", js.length);
          }
        })
      thisObj.setState(current => {
        return {
          ...current,
          ddAccountRemitTo: {
            ...state["ddAccountRemitTo"],
            valueList: js,
          }
        }
      })
      setData(thisObj, "vendorDetails",null)
    }
  },[getData(thisObj, 'vendorDetails')]);

  useEffect(() =>{
    let childData1 = getData(thisObj, 'closingOfOpenAdjustment')
    if (childData1!== null) {
     let buy_pt_id=childData1.buy_pt_id
     let acct_vendor=childData1.acct_vendor
     bFillForm(buy_pt_id,acct_vendor)
     disable(thisObj,"btnAccountVendor");
     setData(thisObj, 'closingOfOpenAdjustment', null)
    }
  },[getData(thisObj, 'closingOfOpenAdjustment')])

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  //Function errorHandler
  function errorHandler(err){
    showMessage(thisObj, err.message)
  }

  //FormLoad function
  const FormLoad = async () => {
    let buyingpointdetails=getData(thisObj,'frmOpenAccountsProfile')
    let pOkCaption=buyingpointdetails.cmdOk_Caption
    
    if (pOkCaption == "Add") {
      document.getElementById("btnOk").innerText = "Add";
      setValue(thisObj, "txtBuyingPoint", buyingpointdetails.lblBuyingPoint_Caption);     
      setValue(thisObj, "chkboxCollectFormSettlement", true);
      document.getElementById("txtAccountVendor").focus();
      disable(thisObj, "txtBuyingPoint");
      disable(thisObj, "txtCollectionAmount");
      disable(thisObj, "btnViewAdj");
      disable(thisObj, "btnMakeAdj");
      disable(thisObj,'btnMakeAdj')
      setValue(thisObj,"txtCollectionPercent",100);
      setValue(thisObj, "lblOpenBalanceBB",'0.00');
      setValue(thisObj, "lblOpenBalanceAdj",'0.00');
      setValue(thisObj, "lblSeedReceivableAdj",'0.00');
      setValue(thisObj, "lblAcctReceivableAdj",'0.00');
      setValue(thisObj, "lblCommReceivableAdj",'0.00');
      setValue(thisObj, "lblAcctReceivableCB",'0.00');
      setValue(thisObj, "lblSeedReceivableCB",'0.00');
      setValue(thisObj, "lblCommReceivableCB",'0.00');
      setValue(thisObj, "lblOpenBalanceCB",'0.00');
    }
    else if (pOkCaption == "Update") {
      document.getElementById("btnOk").innerText = "Update";
      setValue(thisObj,'txtBuyingPoint',buyingpointdetails.lblBuyingPoint_Caption)
      setValue(thisObj,'txtAccountVendor',buyingpointdetails.txtAccountVendor)
      disable(thisObj, "txtBuyingPoint");
      disable(thisObj, "txtAccountVendor");
      disable(thisObj,'btnAccountVendor')
      disable(thisObj,'txtAcctVndr')
      let value2= buyingpointdetails.chkboxCollectFormSettlement
      const value3=(value2 === 'Y'? true : false);
      setValue(thisObj,'lblAddedByValue',buyingpointdetails.lblAddedBy)
      setValue(thisObj,'lblChangedByValue',buyingpointdetails.lblChangedBy)
      setValue(thisObj,'txtAcctVndr',ConvertToPascalCase(buyingpointdetails.txtAcctVndr))
      setValue(thisObj,'txtCollectionAmount',buyingpointdetails.txtCollectionAmount)
      setValue(thisObj,'lblOpenBalanceBB',Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(buyingpointdetails.lblOpenBalanceBB)))
      setValue(thisObj,'lblOpenBalanceAdj',Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(buyingpointdetails.lblOpenBalanceAdj)))
      setValue(thisObj,'lblOpenBalanceCB',Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(buyingpointdetails.lblOpenBalanceCB)))
      setValue(thisObj,'txtCollectionPercent',buyingpointdetails.txtCollectionPercent)
      setValue(thisObj,'txtSeedReceivableBB',Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(buyingpointdetails.lblSeedReceivableBB)))
      setValue(thisObj,'lblSeedReceivableAdj',Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(buyingpointdetails.lblSeedReceivableAdj)))
      setValue(thisObj,'lblSeedReceivableCB',Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(buyingpointdetails.lblSeedReceivableCB)))
      setValue(thisObj,'txtAcctReceivableBB',Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(buyingpointdetails.lblAcctReceivableBB)))
      setValue(thisObj,'lblAcctReceivableAdj',Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(buyingpointdetails.lblAcctReceivableAdj)))
      setValue(thisObj,'lblAcctReceivableCB',Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(buyingpointdetails.lblAcctReceivableCB)))
      setValue(thisObj,'txtCommReceivableBB',Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(buyingpointdetails.lblCommReceivableBB)))
      setValue(thisObj,'lblCommReceivableAdj',Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(buyingpointdetails.lblCommReceivableAdj)))
      setValue(thisObj,'lblCommReceivableCB',Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(buyingpointdetails.lblCommReceivableCB)))
      if(buyingpointdetails.lblSeedReceivableAdj !='0.00'){
        disable(thisObj,'txtSeedReceivableBB')
      }
      if(buyingpointdetails.lblAcctReceivableAdj !='0.00'){
        disable(thisObj,'txtAcctReceivableBB')
      }
      if(buyingpointdetails.lblCommReceivableAdj !='0.00'){
        disable(thisObj,'txtCommReceivableBB')
      }
      if(buyingpointdetails.txtCollectionAmount == '')
      {
        disable(thisObj,'txtCollectionAmount')
      }
      else if(buyingpointdetails.txtCollectionPercent == ''){
        disable(thisObj,'txtCollectionPercent')
      }
      setValue(thisObj,'chkboxCollectFormSettlement',value3)
      let data=buyingpointdetails.ddAccountRemitTo
      let js=[]
      let obj={}
      for(var i = 0; i < data.length; i++) {
        let obj={ key: data[i].key, description: data[i].description}
        js.push(obj)
      }
      setValue(thisObj, "ddAccountRemitTo", js);
      setValue(thisObj, "ddAccountRemitTo", js.at(1).key);
      thisObj.setState(current => {
        return {
          ...current,
          ddAccountRemitTo: {
            ...state["ddAccountRemitTo"],
            valueList: js,
          }
        }
      })
    }
    let response1 = await ContractManagementService.RetrieveAccessPermissionDetails(
      'PN0160',
      null,
      "U",
      null
    );

    let data1 = response1;
    let permission = data1[0].permission;
    if (permission == "Y") {
      enable(thisObj, "btnMakeAdj");
    }
    else if (permission == "N") {
      disable(thisObj, "btnMakeAdj");
    }
  }
  //Click event of Add Account Vendor button
  const onbtnAccountVendorClick = async () => {
   try{
    goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click");
   }
   catch(err){
    errorHandler(err)
   }
  };
  thisObj.onbtnAccountVendorClick = onbtnAccountVendorClick;
  
  //Account Vendor Textbox
  const ontxtAccountVendorBlur = () => {
    try{
    let txtVendorNumber = txtAccountVendor.value
    let enteredVendorNumber = txtAccountVendor.value
    if (txtVendorNumber.length == 6) {
      bFillAccountRemitToList(txtVendorNumber)
    } else {
      setValue(thisObj,'txtAcctVndr','')
      disable(thisObj,'txtAcctVndr')
      document.getElementsByClassName("txtAcctVndr")[0].style.visibility = "hidden";
    }
    let js = [];
    js.push({ key: '', description: '' }) //Set default value
    if (enteredVendorNumber.length == 6) {
      ContractManagementService.RetieveRemitDetails(enteredVendorNumber).then(response => {
        if (response != undefined && response.length > 0) {
          let data = response
          for (var i = 0; i < data.length; i++) {
            var vendorName = data[i].name
            let obj = { key: data[i].remittoid, description: data[i].remittoid + " - " + ConvertToPascalCase(data[i].name) + " - " + ConvertToPascalCase(data[i].city) + ", " + ConvertToPascalCase(data[i].state) }
            js.push(obj)
          }
          // setValue(thisObj, "txtAcctVndr", vendorName)
          setValue(thisObj, "ddAccountRemitTo", js.at(1).key);
          setData(thisObj, "remitToLength", js.length);
        }
      })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddAccountRemitTo: {
          ...state["ddAccountRemitTo"],
          valueList: js,
        }
      }
    })
    setData(thisObj, "remitToLength",null);
    
  }catch(err){	
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on txtVendor change event"	)	
    }
    return false
  }
  return true;
  }
  thisObj.ontxtAccountVendorBlur = ontxtAccountVendorBlur;  
    
//seaching vendorname based on vendorname  
  const bFillAccountRemitToList = (txtVendorNumber) => {
    let venname = null
    let vendornamevisible = false
    ContractManagementService.RetrieveVendorByNumber(txtVendorNumber).then(response => {
      let data = response
      if (data !== null && data !== undefined && data.length > 0) {
        venname = data[0].name
        vendornamevisible = true
      }
      if (vendornamevisible == true) {
        document.getElementsByClassName("txtAcctVndr")[0].style.visibility = "visible";
        setValue(thisObj, "txtAcctVndr", ConvertToPascalCase(venname))
        disable(thisObj,'txtAcctVndr')
      } else {
        document.getElementsByClassName("txtAcctVndr")[0].style.visibility = "hidden";
        setValue(thisObj, "txtAcctVndr", '')
      }
    })
  };

  //Convert to PascalCase function
  function ConvertToPascalCase(word){
    return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  }
  //formValid for validations
  const FormValid = () => {
    var bFormValid = false;

    let lstAccountRemitTo = getData(thisObj, "remitToLength");
    let one=getValue(thisObj, "ddAccountRemitTo")
    if (
      lstAccountRemitTo <= 0 ||
      lstAccountRemitTo == "" ||
      lstAccountRemitTo == undefined ||
      lstAccountRemitTo == null
    ) {
      showMessage(thisObj, "A valid Account Vendor is required.");
      return bFormValid;
    }

    let value = getValue(thisObj, "txtSeedReceivableBB") === undefined ? '':getValue(thisObj, "txtSeedReceivableBB")

    if(typeof value === 'string'){
      value=value.replace(/,/g, '').replace(/(\.| ,) /, ',')
      }
     
    if (isNaN(value)) {
      showMessage(thisObj, "Seed Receivable beginning balance must be numeric");
      return bFormValid;
    } else if ((!isNaN(value) && value < -9999999.99) || value > 99999999.99) {
      showMessage(
        thisObj,
        "SR beginning balance must be of format ########.##"
      );
      return bFormValid;
    }

    let value1 = getValue(thisObj, "txtAcctReceivableBB") === undefined ? '':getValue(thisObj, "txtAcctReceivableBB")
    if(typeof value1 === 'string'){
      value1=value1.replace(/,/g, '').replace(/(\.| ,) /, ',')
      }
    if (isNaN(value1)) {
      showMessage(
        thisObj,
        "Account Receivable beginning balance must be numeric"
      );
      return bFormValid;
    } else if (
      (!isNaN(value1) && value1 < -9999999.99) ||
      value1 > 99999999.99
    ) {
      showMessage(
        thisObj,
        "AR beginning balance must be of format ########.##"
      );
      return bFormValid;
    }

    let value2 = getValue(thisObj, "txtCommReceivableBB") === undefined ? '':getValue(thisObj, "txtCommReceivableBB")
    if(typeof value2 === 'string'){
      value2=value2.replace(/,/g, '').replace(/(\.| ,) /, ',')
      }
    if (isNaN(value2)) {
      showMessage(
        thisObj,
        "Commission Receivable beginning balance must be numeric"
      );
      return bFormValid;
    } else if (
      (!isNaN(value2) && value2 < -9999999.99) ||
      value2 > 99999999.99
    ) {
      showMessage(
        thisObj,
        "CR beginning balance must be of format ########.##"
      );
      return bFormValid;
    }

    let value3 = getValue(thisObj, "chkboxCollectFormSettlement");
    let value4 = getValue(thisObj, "txtCollectionAmount");
    let value5 = getValue(thisObj, "txtCollectionPercent");
    if ((value3 == true) && ((value4 == undefined || value4 == null || value4 == '') && (value5 == undefined || value5==null || value5 ==''))) {
        showMessage(
          thisObj,
          "A collection amount or percentage must be supplied."
        );
        return bFormValid;
      
    }

    if (!(value4 == "" || value4 == null || value4 == 'undefined') && (isNaN(value4) ||(value4 < 0 || value4 > 99999999.99))){
      showMessage(thisObj, "Collection Amount must be of format ########.##.");
      return bFormValid;
    }

    if ((value5 != "" || value5 != null || value5 != undefined) && (isNaN(value5) || (value5 < 0 || value5 > 100))) {
      showMessage(thisObj, "Collection Percent must be between 0 and 100.");
      return bFormValid;
    }
    bFormValid = true;
  };

  //Add function
  const onbtnOkClick = async () => {
   try{
    if (FormValid() == false) {
      return;
    }
    let buttonCaption = document.getElementById('btnOk').innerHTML
    let value5=getValue(thisObj,'txtBuyingPoint')
    let value6=value5.split('-');
    let buy_pt_id=value6[0].trim();
    let acct_vendor = txtAccountVendor.value;
    let vendor_sort_name=getValue(thisObj,"txtAcctVndr")
    let acct_remit = getValue(thisObj, "ddAccountRemitTo");
    let sr_beg_balance = getValue(thisObj, "txtSeedReceivableBB");
    let ar_beg_balance = getValue(thisObj, "txtAcctReceivableBB");
    let cr_beg_balance = getValue(thisObj, "txtCommReceivableBB");
    let collect_ind1 = getValue(thisObj, "chkboxCollectFormSettlement");
    let collect_ind=(collect_ind1 == true? 'Y':'N');
    let collect_amt1 = getValue(thisObj, "txtCollectionAmount");
    let collect_amt=(collect_amt1 == undefined || collect_amt1 == null || collect_amt1 == '' ? '':collect_amt1);
    let collect_pct1 = getValue(thisObj, "txtCollectionPercent");
    let collect_pct=(collect_pct1 == undefined || collect_pct1 == null || collect_pct1 == '' ? '0':collect_pct1);
    const dateTime=new Date()
    let add_date_time = moment(dateTime).format("yyyy-MM-DD HH:mm:ss.SSS")
    let chg_date_time=moment(dateTime).format("yyyy-MM-DD HH:mm:ss.SSS")
    let open_acct = getValue(thisObj,'lblOpenBalanceCB')
    if(buttonCaption.toLocaleUpperCase() == "ADD"){
      let data = {
        'acct_vendor': acct_vendor,
        'acct_remit': acct_remit,
        'sr_beg_balance': sr_beg_balance,
        'ar_beg_balance': ar_beg_balance,
        'cr_beg_balance': cr_beg_balance,
        'collect_ind': collect_ind,
        'collect_amt':collect_amt,   
        'collect_pct':collect_pct,
        'add_date_time':add_date_time,
        'open_acct':open_acct,
        'vendor_sort_name':vendor_sort_name,
      }
      let response = await SystemMaintenanceMasterManagementService.CreateOpenAccountControl(buy_pt_id,data);
      if (![200, 400, 404].includes(response.status)) {
        alert('An error occured while communicating or updating with the database. Please contact your system administrator if the problem persist.\n\nError:\n\n' +
        response.message.slice(69))
      
      }
      else{
        if(response.status==200){
          let Array = []
          let Obj = {}
          Obj.txtAccountVendor = acct_vendor
          Obj.txtAcctVndr = vendor_sort_name
          Obj.lblOpenBalanceCB = open_acct
          Obj.txtAcctReceivableBB = ar_beg_balance
          Obj.txtSeedReceivableBB = sr_beg_balance
          Obj.txtCommReceivableBB = cr_beg_balance
          Array.push(Obj)
          setData(thisObj, 'frmOpenAccountsSetup', Obj)
          goTo(thisObj,'SystemMaintenanceApplicationSupportOpenAccountsSetup_PopUp')
        }
        else{
         showMessage(thisObj,response.message)
        }
      }
    }

  else if(buttonCaption.toLocaleUpperCase() == "UPDATE"){
    let data = {
      
      'acct_remit': acct_remit,
      'sr_beg_balance': sr_beg_balance,
      'ar_beg_balance': ar_beg_balance,
      'cr_beg_balance': cr_beg_balance,
      'collect_ind': collect_ind,
      'collect_amt':collect_amt,   
      'collect_pct':collect_pct,
      'chg_date_time':chg_date_time,
      
      }
      let response = await SystemMaintenanceMasterManagementService.UpdateOpenAccountControl(buy_pt_id,acct_vendor,data);
      if (![200, 400, 404].includes(response.status)) {
        alert('An error occured while communicating or updating with the database. Please contact your system administrator if the problem persist.\n\nError:\n\n' +
        response.message.slice(69))
      }
      else{
        if(response.status==200){
          setData(thisObj, 'frmOpenAccountsSetup',data)
        goTo(thisObj,'SystemMaintenanceApplicationSupportOpenAccountsSetup_PopUp')
        }
      }
    }
  }

 catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred during Add button"
      );
    }
    return false;
    }
  }
  thisObj.onbtnOkClick = onbtnOkClick;

  //Deduction tracking
  const onbtnDeductionTrackingClick = () => {
    try{
    let openaccountprofiledata = {}
    let value5=getValue(thisObj,'txtBuyingPoint')
    let value6=value5.split('-');
    let buy_pt_id=value6[0].trim();
    let vendor_sort_name=getValue(thisObj,"txtAcctVndr")
    openaccountprofiledata.buyingpointname = value5;
    openaccountprofiledata.lstBuyingPointID = buy_pt_id
    openaccountprofiledata.acctvendor = txtAccountVendor.value;
    openaccountprofiledata.acctvendorname = vendor_sort_name
    setData(thisObj, 'openaccountprofiledata', openaccountprofiledata)
    goTo(
      thisObj,
      "SystemMaintenanceApplicationSupport#DeductionTrackingSummary#DEFAULT#true#Click"
    );
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during Deduction Tracking Click"
        );
      }
      return false;
      }
  };
  thisObj.onbtnDeductionTrackingClick = onbtnDeductionTrackingClick;

  //Make Adjustments
  const onbtnMakeAdjClick = () => {
    try {
      let acctvendor = txtAccountVendor.value;
      let acctremit = getValue(thisObj, "ddAccountRemitTo");

      let value5=getValue(thisObj,'txtBuyingPoint')
      let value6=value5.split('-');
      let buy_pt_id=value6[0].trim();

      let openaccountprofiledata = {};
      openaccountprofiledata.buyingpointid = buy_pt_id;
      openaccountprofiledata.acctremit = acctremit;
      openaccountprofiledata.acctvendor = acctvendor;
      setData(thisObj, "openaccountprofiledata", openaccountprofiledata);
      goTo(
        thisObj,
        "SystemMaintenanceApplicationSupport#OpenAdjustmentEntry#DEFAULT#true#Click"
      );
     
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during Make Adjustment Click");
      }
      return false;
    }
  };
  thisObj.onbtnMakeAdjClick = onbtnMakeAdjClick;

  //View Adjustments
  const onbtnViewAdjClick = () => {
    try {
      let buyingpointdetails=getData(thisObj,'frmOpenAccountsProfile')
      let data=buyingpointdetails.ddAccountRemitTo
      let js=[]
      let obj={}
      for(var i = 0; i < data.length; i++) {
        let obj={ key: data[i].key, description: data[i].description}
        js.push(obj)
      }
      let value5=getValue(thisObj,'txtBuyingPoint')
      let value6=value5.split('-');
      let buy_pt_id=value6[0].trim();
      let acctvendor = txtAccountVendor.value;
      let acctremit = getValue(thisObj, "ddAccountRemitTo")
      let openaccountprofiledetails = {};
      openaccountprofiledetails.txtAccountVendor = acctvendor
      openaccountprofiledetails.lstBuyingPointID = buy_pt_id
      openaccountprofiledetails.cmbAccountRemitTo = acctremit
      openaccountprofiledetails.vendor_sort_name = js[1].description
      setData(thisObj, "openaccountprofiledetails", openaccountprofiledetails);
      goTo(
        thisObj,
        "SystemMaintenanceApplicationSupport#OpenAccountAdjustments#DEFAULT#true#Click"
      );
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during View Adjustment Click");
      }
      return false;
    }
  };
  thisObj.onbtnViewAdjClick = onbtnViewAdjClick;

  //Code for Cancel Click
  const onbtnCancelClick = () => {
    try {
      document
        .getElementById(
          "SystemMaintenanceApplicationSupport_OpenAccountsProfilePopUp"
        )
        .childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  //bFillFormFunction

  const bFillForm = async (buy_pt_id,acct_vendor) => {
   try{
    let response = await AccountPayment.RetrieveOpenAccountControlDetails(
      buy_pt_id,
      acct_vendor
    );
    let data = response;
    let obj = {};
    let js = [];
    if (data !== null && data !== undefined && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let dataExist = js.find(
          (elem) => elem.key == data[i].open_acct_control
        );
        if (dataExist == undefined) {
          obj = {
            key: data[i].open_acct_control,
            // description: data[i].vendorid + " - " + data[i].name,
            index: i,
          };
          js.push(obj);
          obj = {};
        }
      }
    }
   
    let lblOpenBalanceBB = js[0].key[0].open_beg_balance;
    setValue(thisObj, "lblOpenBalanceBB", Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(lblOpenBalanceBB)));
    let lblOpenBalanceAdj = js[0].key[0].open_adjustment;
    setValue(thisObj, "lblOpenBalanceAdj", Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(lblOpenBalanceAdj)));
    let lblSeedReceivableAdj = js[0].key[0].sr_adjustment;
    setValue(thisObj, "lblSeedReceivableAdj", Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(lblSeedReceivableAdj)));
    let lblAcctReceivableAdj = js[0].key[0].ar_adjustment;
    setValue(thisObj, "lblAcctReceivableAdj", Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(lblAcctReceivableAdj)));
    let lblCommReceivableAdj = js[0].key[0].cr_adjustment;
    setValue(thisObj, "lblCommReceivableAdj", Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(lblCommReceivableAdj)));
    let lblAcctReceivableCB = js[0].key[0].ar_end_balance;
    setValue(thisObj, "lblAcctReceivableCB", Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(lblAcctReceivableCB)));
    let lblSeedReceivableCB = js[0].key[0].sr_end_balance;
    setValue(thisObj, "lblSeedReceivableCB", Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(lblSeedReceivableCB)));
    let lblCommReceivableCB = js[0].key[0].cr_end_balance;
    setValue(thisObj, "lblCommReceivableCB", Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(lblCommReceivableCB)));
    let lblOpenBalanceCB = js[0].key[0].open_end_balance;
    setValue(thisObj, "lblOpenBalanceCB", Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(lblOpenBalanceCB)));
    let collect_ind = js[0].key[0].collect_ind;
    setData(thisObj,'collect_ind',collect_ind)
    let collect_amt1 = js[0].key[0].collect_amt;
    let collect_amt=(collect_amt1 === undefined || collect_amt1 === null || collect_amt1 == '' && '0');
    setData(thisObj,'txtCollectionAmount',collect_amt)
    let collect_pct1 = js[0].key[0].collect_pct;
    let collect_pct=(collect_pct1 === undefined || collect_pct1 === null || collect_pct1 == '' && '0');
    setData(thisObj,'txtCollectionPercent',collect_pct)
    let add_date_time = moment(js[0].key[0].add_date_time).format("yyyy-MM-DDTHH:mm:ss.SSS");
    setData(thisObj,'add_date_time',add_date_time)
    if(lblSeedReceivableAdj !='0.00'){
      disable(thisObj,'txtSeedReceivableBB')
    }
    if(lblAcctReceivableAdj !='0.00'){
      disable(thisObj,'txtAcctReceivableBB')
    }
    if(lblCommReceivableAdj !='0.00'){
      disable(thisObj,'txtCommReceivableBB')
    }
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred during Add button"
      );
    }
    return false;
    }
  };

  //Enable Disable Controls
  function EnableDisableCollections() {
    try {
      let chkboxCollectFormSettlement = getValue(
        thisObj,
        "chkboxCollectFormSettlement"
      );
      let txtCollectionAmount=getValue(thisObj, "txtCollectionAmount") == undefined ? '':getValue(thisObj, "txtCollectionAmount")
      let txtCollectionPercent=getValue(thisObj, "txtCollectionPercent")

      if(chkboxCollectFormSettlement == false) {
        disable(thisObj, "txtCollectionAmount");
        disable(thisObj, "txtCollectionPercent");
        setValue(thisObj, "txtCollectionAmount", "");
        setValue(thisObj, "txtCollectionPercent", "");
      } else{
        enable(thisObj, "txtCollectionAmount");
        enable(thisObj, "txtCollectionPercent");
        if (txtCollectionAmount != '')  {
          setValue(thisObj, "txtCollectionPercent", "");
          disable(thisObj, "txtCollectionPercent");
        } else if (txtCollectionPercent !='') {
          setValue(thisObj, "txtCollectionAmount", "");
          disable(thisObj, "txtCollectionAmount");
        }
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred EnableDisable Function");
      }
      return false;
    }
  }

  //TextCollectionAmount Function
  const ontxtCollectionAmountChange = () => {
    EnableDisableCollections();
  };
  thisObj.ontxtCollectionAmountChange = ontxtCollectionAmountChange;

  //TextCollectionPercent Function
  const ontxtCollectionPercentChange = () => {
    EnableDisableCollections();
  };
  thisObj.ontxtCollectionPercentChange = ontxtCollectionPercentChange;

  //Checkbox Function
  const onchkboxCollectFormSettlementChange = () => {
    EnableDisableCollections();
  };
  thisObj.onchkboxCollectFormSettlementChange = onchkboxCollectFormSettlementChange;

  //Validation of txtAcctReceivableBB
  const ontxtAcctReceivableBBChange = () => {
    let txtAR = getValue(thisObj, "txtAcctReceivableBB");
    setValue(thisObj, "txtAcctReceivableBB", validateTextBox(txtAR));
    let lblAcctReceivableAdj = getValue(thisObj, "lblAcctReceivableAdj")
    let lblAcctReceivableCB = 
      (Number(txtAR) + Number(lblAcctReceivableAdj)).toFixed(2)
    setValue(thisObj, "lblAcctReceivableCB", Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(lblAcctReceivableCB)));
  };
  thisObj.ontxtAcctReceivableBBChange = ontxtAcctReceivableBBChange;
  
  const validateTextBox = (data) => {
    var res = "";
    for (var i = 0; i < data.length; i++) {
      if (
        (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) ||
        data[i].charCodeAt(0) == 46 || data[i].charCodeAt(0) == 45
      ) {
        res += data[i];
      }
    }
    return res;
  };

  // Validation of txtSeedReceivableBB
  const ontxtSeedReceivableBBChange = () => {
    let txtSR = getValue(thisObj, "txtSeedReceivableBB");
    setValue(thisObj, "txtSeedReceivableBB", validateTextBox1(txtSR));
    let lblSeedReceivableAdj = getValue(thisObj, "lblSeedReceivableAdj");
    let lblSeedReceivableCB = 
      (Number(txtSR) + Number(lblSeedReceivableAdj)).toFixed(2)
    setValue(thisObj, "lblSeedReceivableCB", Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(lblSeedReceivableCB)));
  };
  thisObj.ontxtSeedReceivableBBChange = ontxtSeedReceivableBBChange;
  const validateTextBox1 = (data) => {
    var res = "";
    for (var i = 0; i < data.length; i++) {
      if (
        (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) ||
        data[i].charCodeAt(0) == 46 || data[i].charCodeAt(0) == 45
      ) {
        res += data[i];
      }
    }
    return res;
  };
  

  //Validation of txtCommReceivableBB
  const ontxtCommReceivableBBChange = () => {
    let txtCR = getValue(thisObj, "txtCommReceivableBB");
    setValue(thisObj, "txtCommReceivableBB", validateTextBox2(txtCR));
    let lblCommReceivableAdj = getValue(thisObj, "lblCommReceivableAdj");
    let lblCommReceivableCB = 
      (Number(txtCR) + Number(lblCommReceivableAdj)).toFixed(2)
    setValue(thisObj, "lblCommReceivableCB",  Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(lblCommReceivableCB)))
  };
  thisObj.ontxtCommReceivableBBChange = ontxtCommReceivableBBChange;
  const validateTextBox2 = (data) => {
    var res = "";
    for (var i = 0; i < data.length; i++) {
      if (
        (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) ||
        data[i].charCodeAt(0) == 46 || data[i].charCodeAt(0) == 45
      ) {
        res += data[i];
      }
    }
    return res;
  };

  //toFixed for txtCommReceivableBB
  const ontxtCommReceivableBBBlur = (e) => {
    if (e.target.value != "") {
      setValue(
        thisObj,
        "txtCommReceivableBB",
        Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(e.target.value).toFixed(2))
      );
      
    }
    
  };
  thisObj.ontxtCommReceivableBBBlur = ontxtCommReceivableBBBlur;

  //toFixed for txtAcctReceivableBB
  const ontxtAcctReceivableBBBlur = (e) => {
    if (e.target.value != "") {
      setValue(
        thisObj,
        "txtAcctReceivableBB",
        Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(e.target.value).toFixed(2))
      );
    }
  };
  thisObj.ontxtAcctReceivableBBBlur = ontxtAcctReceivableBBBlur;

  //toFixed for txtSeedReceivableBB
  const ontxtSeedReceivableBBBlur = (e) => {
    if (e.target.value != "") {
      
      setValue(
        thisObj,
        "txtSeedReceivableBB",
        Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(e.target.value).toFixed(2))
      );
    }
  };
  thisObj.ontxtSeedReceivableBBBlur = ontxtSeedReceivableBBBlur;

  //toFixed for txtCollectionAmount
  const ontxtCollectionAmountBlur = (e) => {
    if (e.target.value != "") {
      setValue(
        thisObj,
        "txtCollectionAmount",
        Number(e.target.value)
      );
    }
  };
  thisObj.ontxtCollectionAmountBlur = ontxtCollectionAmountBlur;

   //toFixed for txtCollectionPercent
   const ontxtCollectionPercentBlur = (e) => {
    if (e.target.value != "") {
      setValue(
        thisObj,
        "txtCollectionPercent",
        Number(e.target.value)
      );
    }
  };
  thisObj.ontxtCollectionPercentBlur = ontxtCollectionPercentBlur;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName +
              "-" +
              state.windowName +
              " col-lg-12" +
              " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={(vlaues) => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_OpenAccountsProfile*/}

              {/* END_USER_CODE-USER_BEFORE_OpenAccountsProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxOpenAccountsProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxOpenAccountsProfile*/}

              <GroupBoxWidget
                conf={state.grpbxOpenAccountsProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBuyingPoint}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_txtBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txtAccountVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtAccountVendor*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccountVendor}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAccountVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtAccountVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnAccountVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnAccountVendor*/}

                <ButtonWidget
                  conf={state.btnAccountVendor}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAccountVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnAccountVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txtAcctVndr*/}

                {/* END_USER_CODE-USER_BEFORE_txtAcctVndr*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAcctVndr}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAcctVndr*/}

                {/* END_USER_CODE-USER_AFTER_txtAcctVndr*/}
                {/* START_USER_CODE-USER_BEFORE_ddAccountRemitTo*/}

                {/* END_USER_CODE-USER_BEFORE_ddAccountRemitTo*/}

                <DropDownWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAccountRemitTo}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAccountRemitTo*/}

                {/* END_USER_CODE-USER_AFTER_ddAccountRemitTo*/}
                {/* START_USER_CODE-USER_BEFORE_lblCollectFormSettlement*/}

                {/* END_USER_CODE-USER_BEFORE_lblCollectFormSettlement*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCollectFormSettlement}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCollectFormSettlement*/}

                {/* END_USER_CODE-USER_AFTER_lblCollectFormSettlement*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxCollectFormSettlement*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxCollectFormSettlement*/}

                <CheckboxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxCollectFormSettlement}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxCollectFormSettlement*/}

                {/* END_USER_CODE-USER_AFTER_chkboxCollectFormSettlement*/}
                {/* START_USER_CODE-USER_BEFORE_txtCollectionAmount*/}

                {/* END_USER_CODE-USER_BEFORE_txtCollectionAmount*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCollectionAmount}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCollectionAmount*/}

                {/* END_USER_CODE-USER_AFTER_txtCollectionAmount*/}
                {/* START_USER_CODE-USER_BEFORE_txtCollectionPercent*/}

                {/* END_USER_CODE-USER_BEFORE_txtCollectionPercent*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCollectionPercent}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCollectionPercent*/}

                {/* END_USER_CODE-USER_AFTER_txtCollectionPercent*/}
                {/* START_USER_CODE-USER_BEFORE_lblBalances*/}

                {/* END_USER_CODE-USER_BEFORE_lblBalances*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBalances}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBalances*/}

                {/* END_USER_CODE-USER_AFTER_lblBalances*/}
                {/* START_USER_CODE-USER_BEFORE_btnViewAdj*/}

                {/* END_USER_CODE-USER_BEFORE_btnViewAdj*/}

                <ButtonWidget
                  conf={state.btnViewAdj}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnViewAdj*/}

                {/* END_USER_CODE-USER_AFTER_btnViewAdj*/}
                {/* START_USER_CODE-USER_BEFORE_btnMakeAdj*/}

                {/* END_USER_CODE-USER_BEFORE_btnMakeAdj*/}

                <ButtonWidget
                  conf={state.btnMakeAdj}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnMakeAdj*/}

                {/* END_USER_CODE-USER_AFTER_btnMakeAdj*/}
                {/* START_USER_CODE-USER_BEFORE_lblBeginningBalance*/}

                {/* END_USER_CODE-USER_BEFORE_lblBeginningBalance*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBeginningBalance}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBeginningBalance*/}

                {/* END_USER_CODE-USER_AFTER_lblBeginningBalance*/}
                {/* START_USER_CODE-USER_BEFORE_lblAdjustments*/}

                {/* END_USER_CODE-USER_BEFORE_lblAdjustments*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAdjustments}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAdjustments*/}

                {/* END_USER_CODE-USER_AFTER_lblAdjustments*/}
                {/* START_USER_CODE-USER_BEFORE_lblCurrentBalance*/}

                {/* END_USER_CODE-USER_BEFORE_lblCurrentBalance*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCurrentBalance}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCurrentBalance*/}

                {/* END_USER_CODE-USER_AFTER_lblCurrentBalance*/}
                {/* START_USER_CODE-USER_BEFORE_lblOpenBalance*/}

                {/* END_USER_CODE-USER_BEFORE_lblOpenBalance*/}

                <LabelWidget
                  values={values}
                  conf={state.lblOpenBalance}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblOpenBalance*/}

                {/* END_USER_CODE-USER_AFTER_lblOpenBalance*/}
                {/* START_USER_CODE-USER_BEFORE_lblOpenBalanceBB*/}

                {/* END_USER_CODE-USER_BEFORE_lblOpenBalanceBB*/}

                <LabelWidget
                  values={values}
                  conf={state.lblOpenBalanceBB}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblOpenBalanceBB*/}

                {/* END_USER_CODE-USER_AFTER_lblOpenBalanceBB*/}
                {/* START_USER_CODE-USER_BEFORE_lblOpenBalanceAdj*/}

                {/* END_USER_CODE-USER_BEFORE_lblOpenBalanceAdj*/}

                <LabelWidget
                  values={values}
                  conf={state.lblOpenBalanceAdj}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblOpenBalanceAdj*/}

                {/* END_USER_CODE-USER_AFTER_lblOpenBalanceAdj*/}
                {/* START_USER_CODE-USER_BEFORE_lblOpenBalanceCB*/}

                {/* END_USER_CODE-USER_BEFORE_lblOpenBalanceCB*/}

                <LabelWidget
                  values={values}
                  conf={state.lblOpenBalanceCB}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblOpenBalanceCB*/}

                {/* END_USER_CODE-USER_AFTER_lblOpenBalanceCB*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeedReceivableBB*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeedReceivableBB*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeedReceivableBB}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeedReceivableBB*/}

                {/* END_USER_CODE-USER_AFTER_txtSeedReceivableBB*/}
                {/* START_USER_CODE-USER_BEFORE_lblSeedReceivableAdj*/}

                {/* END_USER_CODE-USER_BEFORE_lblSeedReceivableAdj*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSeedReceivableAdj}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSeedReceivableAdj*/}

                {/* END_USER_CODE-USER_AFTER_lblSeedReceivableAdj*/}
                {/* START_USER_CODE-USER_BEFORE_lblSeedReceivableCB*/}

                {/* END_USER_CODE-USER_BEFORE_lblSeedReceivableCB*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSeedReceivableCB}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSeedReceivableCB*/}

                {/* END_USER_CODE-USER_AFTER_lblSeedReceivableCB*/}
                {/* START_USER_CODE-USER_BEFORE_txtAcctReceivableBB*/}

                {/* END_USER_CODE-USER_BEFORE_txtAcctReceivableBB*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAcctReceivableBB}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAcctReceivableBB*/}

                {/* END_USER_CODE-USER_AFTER_txtAcctReceivableBB*/}
                {/* START_USER_CODE-USER_BEFORE_lblAcctReceivableAdj*/}

                {/* END_USER_CODE-USER_BEFORE_lblAcctReceivableAdj*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAcctReceivableAdj}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAcctReceivableAdj*/}

                {/* END_USER_CODE-USER_AFTER_lblAcctReceivableAdj*/}
                {/* START_USER_CODE-USER_BEFORE_lblAcctReceivableCB*/}

                {/* END_USER_CODE-USER_BEFORE_lblAcctReceivableCB*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAcctReceivableCB}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAcctReceivableCB*/}

                {/* END_USER_CODE-USER_AFTER_lblAcctReceivableCB*/}
                {/* START_USER_CODE-USER_BEFORE_txtCommReceivableBB*/}

                {/* END_USER_CODE-USER_BEFORE_txtCommReceivableBB*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCommReceivableBB}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCommReceivableBB*/}

                {/* END_USER_CODE-USER_AFTER_txtCommReceivableBB*/}
                {/* START_USER_CODE-USER_BEFORE_lblCommReceivableAdj*/}

                {/* END_USER_CODE-USER_BEFORE_lblCommReceivableAdj*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCommReceivableAdj}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCommReceivableAdj*/}

                {/* END_USER_CODE-USER_AFTER_lblCommReceivableAdj*/}
                {/* START_USER_CODE-USER_BEFORE_lblCommReceivableCB*/}

                {/* END_USER_CODE-USER_BEFORE_lblCommReceivableCB*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCommReceivableCB}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCommReceivableCB*/}

                {/* END_USER_CODE-USER_AFTER_lblCommReceivableCB*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeductionTracking*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeductionTracking*/}

                <ButtonWidget
                  conf={state.btnDeductionTracking}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeductionTracking*/}

                {/* END_USER_CODE-USER_AFTER_btnDeductionTracking*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxOpenAccountsProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxOpenAccountsProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_OpenAccountsProfile*/}

              {/* END_USER_CODE-USER_AFTER_OpenAccountsProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_OpenAccountsProfile
);
