/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  CheckboxGroupWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  setValue,
  getValue,
  setData,
  getData,
  enable,
  disable,
  hide,
  isEnabled,
  show,
  hideColumn,
  goTo,
} from "../../shared/WindowImports";

import "./1007GrpSelect.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import Loading from "../../../Loader/Loading";
import moment from "moment";
import jsPDF from "jspdf";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_1007GrpSelect(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  let states = {
    Label: "1007GrpSelect",
    windowName: "1007GrpSelect",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.1007GrpSelect",
    // START_USER_CODE-USER_1007GrpSelect_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "1007 Grouping Select",
      scrCode: "PN1160C",
    },
    // END_USER_CODE-USER_1007GrpSelect_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnGroupAndPrint: {
      name: "btnGroupAndPrint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Group and Print",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnGroupAndPrint_PROPERTIES

      // END_USER_CODE-USER_btnGroupAndPrint_PROPERTIES
    },
    btnReprintGrouping: {
      name: "btnReprintGrouping",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Reprint Grouping",
      CharWidth: "33",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReprintGrouping_PROPERTIES

      // END_USER_CODE-USER_btnReprintGrouping_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxDetails2",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxDetails1",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    chkboxCMA: {
      name: "chkboxCMA",
      type: "CheckBoxWidget",
      parent: "grpbxchkbox",
      Label: "CMA",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
    },
    chkboxDMA: {
      name: "chkboxDMA",
      type: "CheckBoxWidget",
      parent: "grpbxchkbox",
      Label: "CMA",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
    },
    chkboxDeferred: {
      name: "chkboxDeferred",
      type: "CheckBoxWidget",
      parent: "grpbxchkbox",
      Label: "CMA",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
    },
    chkboxCMASearch: {
      name: "chkboxCMASearch",
      type: "CheckBoxWidget",
      parent: "grpbxAvailable1007s",
      Label: "CMA Search",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMASearch_PROPERTIES

      // END_USER_CODE-USER_chkboxCMASearch_PROPERTIES
    },
    col1007: {
      name: "col1007",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "1007",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1007_PROPERTIES

      // END_USER_CODE-USER_col1007_PROPERTIES
    },
    col1007Value: {
      name: "col1007Value",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "1007 Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1007Value_PROPERTIES

      // END_USER_CODE-USER_col1007Value_PROPERTIES
    },
    colContract: {
      name: "colContract",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Contract",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContract_PROPERTIES

      // END_USER_CODE-USER_colContract_PROPERTIES
    },
    colCounty: {
      name: "colCounty",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "County",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCounty_PROPERTIES

      // END_USER_CODE-USER_colCounty_PROPERTIES
    },
    colFarm: {
      name: "colFarm",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Farm",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarm_PROPERTIES

      // END_USER_CODE-USER_colFarm_PROPERTIES
    },
    colGroup: {
      name: "colGroup",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Group",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGroup_PROPERTIES

      // END_USER_CODE-USER_colGroup_PROPERTIES
    },
    colInspectDate: {
      name: "colInspectDate",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Inspect Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInspectDate_PROPERTIES

      // END_USER_CODE-USER_colInspectDate_PROPERTIES
    },
    colNetLbs: {
      name: "colNetLbs",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Net LBS",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetLbs_PROPERTIES

      // END_USER_CODE-USER_colNetLbs_PROPERTIES
    },
    colOblBin: {
      name: "colOblBin",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Obl. Bin",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOblBin_PROPERTIES

      // END_USER_CODE-USER_colOblBin_PROPERTIES
    },
    colOblBP: {
      name: "colOblBP",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Obl. BP",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOblBP_PROPERTIES

      // END_USER_CODE-USER_colOblBP_PROPERTIES
    },
    colOblLoc: {
      name: "colOblLoc",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Obl. Loc",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOblLoc_PROPERTIES

      // END_USER_CODE-USER_colOblLoc_PROPERTIES
    },
    colOblWhse: {
      name: "colOblWhse",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Obl. Whse",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOblWhse_PROPERTIES

      // END_USER_CODE-USER_colOblWhse_PROPERTIES
    },
    colOleic: {
      name: "colOleic",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOleic_PROPERTIES

      // END_USER_CODE-USER_colOleic_PROPERTIES
    },
    colSC95: {
      name: "colSC95",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "SC95",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSC95_PROPERTIES

      // END_USER_CODE-USER_colSC95_PROPERTIES
    },
    colSeedGen: {
      name: "colSeedGen",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Seed Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedGen_PROPERTIES

      // END_USER_CODE-USER_colSeedGen_PROPERTIES
    },
    colSeedSpec: {
      name: "colSeedSpec",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Seed Spec",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedSpec_PROPERTIES

      // END_USER_CODE-USER_colSeedSpec_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colState: {
      name: "colState",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "State",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colState_PROPERTIES

      // END_USER_CODE-USER_colState_PROPERTIES
    },
    colType: {
      name: "colType",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType_PROPERTIES

      // END_USER_CODE-USER_colType_PROPERTIES
    },
    colUnlBin: {
      name: "colUnlBin",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Unl. Bin",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUnlBin_PROPERTIES

      // END_USER_CODE-USER_colUnlBin_PROPERTIES
    },
    colUnlBP: {
      name: "colUnlBP",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Unl. BP",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUnlBP_PROPERTIES

      // END_USER_CODE-USER_colUnlBP_PROPERTIES
    },
    colUnlWhse: {
      name: "colUnlWhse",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Unl. Whse",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUnlWhse_PROPERTIES

      // END_USER_CODE-USER_colUnlWhse_PROPERTIES
    },
    colVariety: {
      name: "colVariety",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVariety_PROPERTIES

      // END_USER_CODE-USER_colVariety_PROPERTIES
    },
    ddCounty: {
      name: "ddCounty",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "County:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCounty_PROPERTIES

      // END_USER_CODE-USER_ddCounty_PROPERTIES
    },
    ddFarmNum: {
      name: "ddFarmNum",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "Farm #:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: ["d_String","d_Null"],
      // START_USER_CODE-USER_ddFarmNum_PROPERTIES
      optionMaxLength: 28,
      SpecialFirstOption: "",
      // END_USER_CODE-USER_ddFarmNum_PROPERTIES
    },
    ddGeneration: {
      name: "ddGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGeneration_PROPERTIES

      // END_USER_CODE-USER_ddGeneration_PROPERTIES
    },
    ddHolderID: {
      name: "ddHolderID",
      type: "DropDownFieldWidget",
      parent: "grpbxActions",
      Label: "Holder ID:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddHolderID_PROPERTIES
      optionMaxLength: 30
      // END_USER_CODE-USER_ddHolderID_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Peanut Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: ["d_String","d_Null"],
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddSeed: {
      name: "ddSeed",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Seed:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeed_PROPERTIES

      // END_USER_CODE-USER_ddSeed_PROPERTIES
    },
    ddSegType: {
      name: "ddSegType",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Seg Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegType_PROPERTIES

      // END_USER_CODE-USER_ddSegType_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "State:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES

      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    gridAvailable1007s: {
      name: "gridAvailable1007s",
      type: "GridWidget",
      parent: "grpbxAvailable1007s",
      gridCellsOrder:
        "btnCol,chkboxcol,txtcol1007,txtcolFarm,txtcolContract,txtcolSC95,txtcolUnlBP,txtcolUnlWhse,txtcolUnlBin,txtcolOblLoc,txtcolOblBP,txtcolOblWhse,txtcolOblBin,txtcolInspectDate,txtcolNetLbs,txtcol1007Value,txtcolSeg,txtcolType,txtcolVariety,txtcolSeedGen,txtcolSeedSpec,txtcolOleic,txtcolGroup,txtcolState,txtcolCounty",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridAvailable1007s_PROPERTIES
      isCobolTable: true,
      isEditable: true,
      // END_USER_CODE-USER_gridAvailable1007s_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "grpbx1007GrpSelect",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    grpbxAvailable1007s: {
      name: "grpbxAvailable1007s",
      type: "GroupBoxWidget",
      parent: "grpbx1007GrpSelect",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxAvailable1007s_PROPERTIES

      // END_USER_CODE-USER_grpbxAvailable1007s_PROPERTIES
    },
    grpbxDetails1: {
      name: "grpbxDetails1",
      type: "GroupBoxWidget",
      parent: "grpbx1007GrpSelect",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDetails1_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails1_PROPERTIES
    },
    grpbxDetails2: {
      name: "grpbxDetails2",
      type: "GroupBoxWidget",
      parent: "grpbx1007GrpSelect",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDetails2_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails2_PROPERTIES
    },
    grpbxchkbox: {
      name: "grpbxchkbox",
      type: "GroupBoxWidget",
      parent: "grpbxActions",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    hckboxAll1007s: {
      name: "hckboxAll1007s",
      type: "CheckBoxWidget",
      parent: "grpbxAvailable1007s",
      Label: "All 1007s",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_hckboxAll1007s_PROPERTIES

      // END_USER_CODE-USER_hckboxAll1007s_PROPERTIES
    },
    lblAvailable1007s: {
      name: "lblAvailable1007s",
      type: "LabelWidget",
      parent: "grpbxAvailable1007s",
      Label: "Available 1007's:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAvailable1007s_PROPERTIES

      // END_USER_CODE-USER_lblAvailable1007s_PROPERTIES
    },
    lblCropYear: {
      name: "lblCropYear",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Crop Year:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear_PROPERTIES

      // END_USER_CODE-USER_lblCropYear_PROPERTIES
    },
    lblGoupingNumber: {
      name: "lblGoupingNumber",
      type: "LabelWidget",
      parent: "grpbxDetails2",
      Label: "lblGRP#",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGoupingNumber_PROPERTIES

      // END_USER_CODE-USER_lblGoupingNumber_PROPERTIES
    },
    radioAppliedTo: {
      name: "radioAppliedTo",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxDetails2",
      Options:
        "Tentatively Applied to an Option Contract:1,Tentatively Applied to a Flex Market Contract :2,Tentatively Applied to a Farmer Stock Contract:3,Tentatively Applied to a Floor Contract:4,Not Applied to a Contract:5",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioAppliedTo_PROPERTIES

      // END_USER_CODE-USER_radioAppliedTo_PROPERTIES
    },
    txtcol1007: {
      name: "txtcol1007",
      type: "TextBoxWidget",
      colName: "col1007",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol1007_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcol1007_PROPERTIES
    },
    btnCol: {
      name: "btnCol",
      type: "ButtonWidget",
      colName: "colButton",
      parent: "gridAvailable1007s",
      CharWidth: "15",
      //Height: "",
      //Width: "",
      //HasLabel: false,
      Label: "view 1007",
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCol_PROPERTIES

      // END_USER_CODE-USER_btnCol_PROPERTIES
    },
    colButton: {
      name: "colButton",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colButton_PROPERTIES

      // END_USER_CODE-USER_colButton_PROPERTIES
    },
    chkboxcol: {
      name: "chkboxcol",
      type: "CheckBoxWidget",
      colName: "colCheckbox",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxcol_PROPERTIES

      // END_USER_CODE-USER_chkboxcol_PROPERTIES
    },
    colCheckbox: {
      name: "colCheckbox",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCheckbox_PROPERTIES

      // END_USER_CODE-USER_colCheckbox_PROPERTIES
    },
    txtcol1007Value: {
      name: "txtcol1007Value",
      type: "TextBoxWidget",
      colName: "col1007Value",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol1007Value_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcol1007Value_PROPERTIES
    },
    txtcolContract: {
      name: "txtcolContract",
      type: "TextBoxWidget",
      colName: "colContract",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContract_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolContract_PROPERTIES
    },
    txtcolCounty: {
      name: "txtcolCounty",
      type: "TextBoxWidget",
      colName: "colCounty",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCounty_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolCounty_PROPERTIES
    },
    txtcolFarm: {
      name: "txtcolFarm",
      type: "TextBoxWidget",
      colName: "colFarm",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarm_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolFarm_PROPERTIES
    },
    txtcolGroup: {
      name: "txtcolGroup",
      type: "TextBoxWidget",
      colName: "colGroup",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGroup_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolGroup_PROPERTIES
    },
    txtcolInspectDate: {
      name: "txtcolInspectDate",
      type: "TextBoxWidget",
      colName: "colInspectDate",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInspectDate_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolInspectDate_PROPERTIES
    },
    txtcolNetLbs: {
      name: "txtcolNetLbs",
      type: "TextBoxWidget",
      colName: "colNetLbs",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNetLbs_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolNetLbs_PROPERTIES
    },
    txtcolOblBin: {
      name: "txtcolOblBin",
      type: "TextBoxWidget",
      colName: "colOblBin",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOblBin_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolOblBin_PROPERTIES
    },
    txtcolOblBP: {
      name: "txtcolOblBP",
      type: "TextBoxWidget",
      colName: "colOblBP",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOblBP_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolOblBP_PROPERTIES
    },
    txtcolOblLoc: {
      name: "txtcolOblLoc",
      type: "TextBoxWidget",
      colName: "colOblLoc",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOblLoc_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolOblLoc_PROPERTIES
    },
    txtcolOblWhse: {
      name: "txtcolOblWhse",
      type: "TextBoxWidget",
      colName: "colOblWhse",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOblWhse_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolOblWhse_PROPERTIES
    },
    txtcolOleic: {
      name: "txtcolOleic",
      type: "TextBoxWidget",
      colName: "colOleic",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOleic_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolOleic_PROPERTIES
    },
    txtcolSC95: {
      name: "txtcolSC95",
      type: "TextBoxWidget",
      colName: "colSC95",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSC95_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolSC95_PROPERTIES
    },
    txtcolSeedGen: {
      name: "txtcolSeedGen",
      type: "TextBoxWidget",
      colName: "colSeedGen",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeedGen_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolSeedGen_PROPERTIES
    },
    txtcolSeedSpec: {
      name: "txtcolSeedSpec",
      type: "TextBoxWidget",
      colName: "colSeedSpec",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeedSpec_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolSeedSpec_PROPERTIES
    },
    txtcolSeg: {
      name: "txtcolSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolSeg_PROPERTIES
    },
    txtcolState: {
      name: "txtcolState",
      type: "TextBoxWidget",
      colName: "colState",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolState_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolState_PROPERTIES
    },
    txtcolType: {
      name: "txtcolType",
      type: "TextBoxWidget",
      colName: "colType",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolType_PROPERTIES
    },
    txtcolUnlBin: {
      name: "txtcolUnlBin",
      type: "TextBoxWidget",
      colName: "colUnlBin",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUnlBin_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolUnlBin_PROPERTIES
    },
    txtcolUnlBP: {
      name: "txtcolUnlBP",
      type: "TextBoxWidget",
      colName: "colUnlBP",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUnlBP_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolUnlBP_PROPERTIES
    },
    txtcolUnlWhse: {
      name: "txtcolUnlWhse",
      type: "TextBoxWidget",
      colName: "colUnlWhse",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUnlWhse_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolUnlWhse_PROPERTIES
    },
    txtcolVariety: {
      name: "txtcolVariety",
      type: "TextBoxWidget",
      colName: "colVariety",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVariety_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolVariety_PROPERTIES
    },
    txtContractNum: {
      name: "txtContractNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Contract #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContractNum_PROPERTIES

      // END_USER_CODE-USER_txtContractNum_PROPERTIES
    },
    txtFarmNum: {
      name: "txtFarmNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmNum_PROPERTIES

      // END_USER_CODE-USER_txtFarmNum_PROPERTIES
    },
    txtNumber: {
      name: "txtNumber",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "#",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNumber_PROPERTIES

      // END_USER_CODE-USER_txtNumber_PROPERTIES
    },
    txtTotalNetWeight: {
      name: "txtTotalNetWeight",
      type: "TextBoxWidget",
      parent: "grpbxAvailable1007s",
      Label: "Total Net Weight:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalNetWeight_PROPERTIES

      // END_USER_CODE-USER_txtTotalNetWeight_PROPERTIES
    },
    txtTotalValue: {
      name: "txtTotalValue",
      type: "TextBoxWidget",
      parent: "grpbxAvailable1007s",
      Label: "Total Value:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalValue_PROPERTIES

      // END_USER_CODE-USER_txtTotalValue_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      ColSpan: "3",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVendorNum: {
      name: "txtVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtVendorNum_PROPERTIES
    },
    grpbx1007GrpSelect: {
      name: "grpbx1007GrpSelect",
      type: "GroupBoxWidget",
      parent: "1007GrpSelect",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbx1007GrpSelect_PROPERTIES

      // END_USER_CODE-USER_grpbx1007GrpSelect_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#VendorLookup": {}
      },
      REVERSE: {
        "ContractManagement#VendorLookup": {}
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};

  const [state, setState] = useState(states);

  // START_USER_CODE-USER_PROPERTIES
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');

  const [loading, setLoading] = useState(false);
  // END_USER_CODE-USER_PROPERTIES

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj;
  });
  useEffect(() => {
    setTabIndex();
  },[]);

  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null) {
      setValue(thisObj, "txtVendorNum", vendor_lookup.VendorNumber);
      show(thisObj, "txtVendor");
      enable(thisObj, "btnSearch")
      setValue(thisObj, "txtVendor", toPascalCase(vendor_lookup.key));
    }
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    document.getElementsByClassName('lblCropYear')[0].innerText += cropYearFromLS();

    // let js = [{
    //   btnCol : "view 1007",
    //   txtcolContract:123,
    //   txtcol1007Value:102
    // }]

    // setValue(thisObj, "gridAvailable1007s", js)

    return () => {
      setData(thisObj, "closingOf1007GrpSelect", true);
      //check if all setData is needed to be deleted or not
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  async function FormLoad() {
    try {
      setLoading(true)

      let firstState = await bFillStateList()

      let firstlocation = await bFillLocationList();

      let firstPeanutType = await bFillTypeList();

      await bFillSegmentList();
      await bFillPeanutVarietyList(firstlocation, firstPeanutType);
      setLoading(true)
      await bFillGeneration();
      await bFillOleic();
      await bFillSeed();
      await bFillHolderList(firstlocation);
      setValue(thisObj, "txtVendorNum", "")
      await lblVendor_Change();
      setValue(thisObj, 'hckboxAll1007s', 0)


      setValue(thisObj, 'radioAppliedTo', '1')
      setValue(thisObj, 'txtTotalNetWeight', '0')
      setValue(thisObj, 'txtTotalValue', '0')
      let firstCounty = await bFillCountyList(firstlocation, firstState)

      await bFillFarmList(firstlocation, firstState, firstCounty);
      hide(thisObj, 'txtFarmNum')
    }
    catch (err) {
      errorHandler(err);
    }
    finally {
      setLoading(false);
      document.getElementById("txtVendorNum").focus()
    }
  }

  async function bFillStateList() {
    try {
      let js = [];
      // setData(thisObj , 'lstStateID', '')
      // setData(thisObj , 'lstStateID2', '')

      let LstrStateList = await ContractManagementService.RetrievePeanutStateControlDetails(null, null, false);

      if (LstrStateList.length <= 0)
        return null;

      for (var i = 0; i < LstrStateList.length; i++) {
        js.push({
          key: LstrStateList[i].stateId,
          description: LstrStateList[i].stateName,
          abbr: LstrStateList[i].stateAbbr
        })
        //lstStateID.push(LstrStateList[i].stateAbbr)
        //lstStateID2.push(LstrStateList[i].stateId)
        setData(thisObj, 'stateList', js)
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddState: {
            ...state["ddState"],
            valueList: js
          }
        }
      })

      setValue(thisObj, 'ddState', js.at(0).key);
      //setData(thisObj, 'ddState', js.at(0).key);
      return js.at(0).key;
    }
    catch (err) {
      errorHandler(err);
      return null;
    }
  }

  async function bFillLocationList() {
    try {
      let js = []
      let lstLocFedNum = [];
      let lstLocArea = [];
      let lstLocCmaVendor = [];
      //setData(thisObj, 'lstLocFedNum', [])
      //setData(thisObj, 'lstLocArea', [])
      //setData(thisObj, 'lstLocCmaVendor', [])

      let LstrList = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1030', null, null, null, null)

      if (LstrList == undefined || LstrList.length <= 0) {
        return null;
      }

      for (var i = 0; i < LstrList.length; i++) {
        js.push({
          key: LstrList[i].buy_pt_id,
          description: LstrList[i].buy_pt_id + ' - ' + toPascalCase(LstrList[i].buy_pt_name),
          lstLocFedNum: LstrList[i].fed_whouse_license,
          lstLocArea: LstrList[i].area_id,
          lstLocCmaVendor: LstrList[i].default_cma_vendor_num
        })
        lstLocFedNum.push(LstrList[i].fed_whouse_license)
        lstLocArea.push(LstrList[i].area_id)
        lstLocCmaVendor.push(LstrList[i].default_cma_vendor_num)
      }
      //setData(thisObj, 'lstLocFedNum', lstLocFedNum)
      //setData(thisObj, 'lstLocArea', lstLocArea)
      //setData(thisObj, 'lstLocCmaVendor', lstLocCmaVendor)

      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js
          }
        }
      })

      setValue(thisObj, 'ddLocation', js.at(0).key);
      setData(thisObj, 'ddLocation', js.at(0).key);  // it is required for grid-button click 
      // let cboLocation = {
      //   index       : 1,
      //   key         : LstrList[0].buy_pt_id,
      //   description : LstrList[0].buy_pt_id + ' - ' + LstrList[0].buy_pt_name        
      // }
      // setData(thisObj, 'cboLocation', cboLocation) //check why needed and where needed

      setData(thisObj, "locationValueList", js);  // required for setDefaultHolder
      return js.at(0).key;
    }
    catch (err) {
      errorHandler(err);
      return null;
    }
  }

  async function bFillTypeList() {
    try {
      let js = [];

      let LstrTypeList = await ContractManagementService.RetrievePeanutTypeControls(null)
      if (LstrTypeList.length <= 0)
        return null;
      for (var i = 0; i < LstrTypeList.length; i++) {
        js.push({
          key: LstrTypeList[i].pnutTypeId,
          description: LstrTypeList[i].pnutTypeName
        })
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddPeanutType', js.at(0).key);
      return js.at(0).key;
    }
    catch (err) {
      errorHandler(err);
      return null;
    }
  }

  async function bFillSegmentList() {
    try {
      let js = [
        { key: '1', description: 'Seg1' },
        { key: '2', description: 'Seg2' },
        { key: '3', description: 'Seg3' },
      ];

      thisObj.setState(current => {
        return {
          ...current,
          ddSegType: {
            ...state["ddSegType"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddSegType', js.at(0).key);
      //setData(thisObj, 'ddSegType', js.at(0).description);
    }
    catch (err) {
      errorHandler(err);
    }
  }

  // async function bFillPeanutVarietyList() {
  //   try {
  //     let sXML = '';
  //     let sSymbol = '';
  //     let sSavVarID = '';
  //     let lCnt = 0;
  //     let bNewVar = false;

  //     let js = [
  //       { key: '', description: 'All Varieties' }
  //     ];

  //     //sSavVarID = UCase(Trim(lstPeanutVarietyID.List(cboPeanutVariety.ListIndex)))
  //     let buyingPt = thisObj.values['ddLocation']
  //     let peanutType = thisObj.values['ddPeanutType']

  //     if (peanutType !== '' && peanutType !== undefined && peanutType !== null) {
  //       sXML = await ContractManagementService.RetrievePeanutVarietyControls(buyingPt, peanutType)

  //       for (var i = 0; i < sXML.length; i++) {
  //         bNewVar = true;

  //         if (bNewVar) {
  //           switch (sXML[i].symboL_IND) {
  //             case "TRADEMARK":
  //               sSymbol = " " + " ᵀᴹ";
  //               break;
  //             case "REGISTERED TRADEMARK":
  //               sSymbol = " " + " ®";
  //               break;
  //             case "COPYRIGHT":
  //               sSymbol = " " + " ©";
  //               break;
  //             default:
  //               sSymbol = ""
  //           }
  //           js.push({
  //             key: sXML[i].pnuT_VARIETY_ID,
  //             description: sXML[i].pnuT_VARIETY_NAME + sSymbol
  //           })
  //         }
  //       }
  //     }

  //     thisObj.setState(current => {
  //       return {
  //         ...current,
  //         ddPeanutVariety: {
  //           ...state["ddPeanutVariety"],
  //           valueList: js
  //         }
  //       }
  //     })
  //     setValue(thisObj, 'ddPeanutVariety', js.at(0).key);
  //     return true;
  //   }
  //   catch(err) {
  //     errorHandler(err);
  //     return false;
  //   }
  // }

  async function bFillPeanutVarietyList(location = thisObj.values['ddLocation'], pnuttype = thisObj.values['ddPeanutType']) {
    try {
      setLoading(true);
      let js = [];
      js.push({ key: '', description: 'All Varieties' })

      let buyingPt = location
      let peanut_type = pnuttype

      if (peanut_type !== null && peanut_type !== "" && peanut_type !== undefined) {
        let response = await ContractManagementService.RetrievePeanutVarietyControls(buyingPt, null, null, peanut_type, null)
        if (response === undefined || response == null || response.length === 0) {
          thisObj.setState(current => {
            return {
              ...current,
              ddPeanutVariety: {
                ...state["ddPeanutVariety"],
                valueList: js
              }
            }
          })
          setValue(thisObj, 'ddPeanutVariety', js[0].key);
          setLoading(false);
          return false;
        } else {
          let data = response;
          let count = 0;
          data.forEach(element => {
            count++;
            let newVar = true;
            for (let i = 0; i < count - 1; i++) {
              if ((element.pnut_variety_id).toUpperCase() === (data[i].pnut_variety_id).toUpperCase()) {
                newVar = false;
                break;
              }
            }
            let symbol;
            if (newVar) {
              switch (element.symbol_ind.trim()) {
                case "TRADEMARK":
                  symbol = " \u2122"
                  break;
                case "REGISTERED TRADEMARK":
                  symbol = " \xAE"
                  break;
                case "COPYRIGHT":
                  symbol = " \xA9"
                  break;
                default:
                  symbol = "";
              }
              let obj = { key: element.pnut_variety_id, description: element.pnut_variety_name + symbol }
              js.push(obj)
            }
          });
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutVariety: {
            ...state["ddPeanutVariety"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddPeanutVariety', js[0].key);
      setLoading(false);
      return true;
    } catch (error) {
      errorHandler(error)
      return false;
    }
  }

  async function bFillGeneration() {
    try {
      let js = [
        { key: '', description: 'All' },
        { key: 'F', description: 'Foundation' },
        { key: 'R', description: 'Registered' },
        { key: 'C', description: 'Certified' }
      ];

      thisObj.setState(current => {
        return {
          ...current,
          ddGeneration: {
            ...state["ddGeneration"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddGeneration', js.at(0).key);
    }
    catch (err) {
      errorHandler(err);
    }
  }

  async function bFillOleic() {
    try {
      let js = [
        { key: '', description: 'All' },
        { key: 'H', description: 'High' },
        { key: 'M', description: 'Mid' },
        { key: 'N', description: 'None' },
      ];

      thisObj.setState(current => {
        return {
          ...current,
          ddOleic: {
            ...state["ddOleic"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddOleic', js.at(0).key);
    }
    catch (err) {
      errorHandler(err);
    }
  }

  async function bFillSeed() {
    try {
      let js = [
        { key: '', description: 'Both' },
        { key: 'Y', description: 'Seed' },
        { key: 'N', description: 'Non-Seed' },
      ];

      thisObj.setState(current => {
        return {
          ...current,
          ddSeed: {
            ...state["ddSeed"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddSeed', js.at(0).key);
    }
    catch (err) {
      errorHandler(err);
    }
  }

  async function bFillHolderList(firstlocation) {
    try {
      let js = [
        {
          key: '',
          description: '>>> All Holders <<<',
          lstCurrentHolder: '',
          lstDefaultHolder: '',
          lstHolderVendor: ''
        }
      ];
      let lstCurrentHolder = [">>> All Holders <<<"]
      let lstDefaultHolder = [""];
      let lstHolderVendor = [""];
      let LstrList = await WarehouseReceiptService.RetrieveVendorHolderTransferDetails(compIdFromLS(), cropYearFromLS())

      //setData(thisObj, "lstCurrentHolder" , lstCurrentHolder)    
      //setData(thisObj, 'lstDefaultHolder', lstDefaultHolder)
      //setData(thisObj, 'lstHolderVendor', lstHolderVendor)

      for (var i = 0; i < LstrList.length; i++) {
        js.push({
          key: LstrList[i].holder_id,
          description: LstrList[i].holder_name,
          lstCurrentHolder: LstrList[i].holder_name,
          lstDefaultHolder: LstrList[i].holder_default,
          lstHolderVendor: LstrList[i].holder_vendor
        })
        lstCurrentHolder.push(LstrList[i].holder_name)
        lstDefaultHolder.push(LstrList[i].holder_default)
        lstHolderVendor.push(LstrList[i].holder_vendor)
      }
      //setData(thisObj, "lstCurrentHolder" , lstCurrentHolder)      
      //setData(thisObj, 'lstDefaultHolder', lstDefaultHolder)
      //setData(thisObj, 'lstHolderVendor', lstHolderVendor)

      thisObj.setState(current => {
        return {
          ...current,
          ddHolderID: {
            ...state["ddHolderID"],
            valueList: js
          }
        }
      })

      if (js.length > 1) {
        setValue(thisObj, 'ddHolderID', js.at(1).key);
        await SetDefaultHolder(js, firstlocation, js.at(1).key);
      }
      else {
        setValue(thisObj, 'ddHolderID', js.at(0).key);
        await SetDefaultHolder(js, firstlocation, js.at(0).key);
      }

    }
    catch (err) {
      errorHandler(err);
    }
  }

  async function bFillFarmList(locationvalue = getValue(thisObj, 'ddLocation'), statevalue = getValue(thisObj, 'ddState'), countyvalue = getValue(thisObj, 'ddCounty')) {
    try {

      //clearing existing values
      thisObj.setState(current => {
        return {
          ...current,
          ddFarmNum: {
            ...state["ddFarmNum"],
            valueList: []
          }
        }
      })

      let buyingPt = locationvalue
      let stateLst = getData(thisObj, 'stateList')
      let countyId = countyvalue
      let stateAbbr = ''
      for (var i = 0; i < stateLst.length; i++) {
        if (stateLst[i].key == statevalue) {
          stateAbbr = stateLst[i].abbr;
          break;
        }
      }

      let js = [];
      let LstrFarmList = await ContractManagementService.RetrieveFarmControlDetails('PN1030', null, buyingPt, stateAbbr, countyId, null, null)

      if (LstrFarmList.length <= 0)
        return;
      for (var i = 0; i < LstrFarmList.length; i++) {
        js.push({
          key: LstrFarmList[i].farm_id + ' - ' + LstrFarmList[i].farm_suffix,
          description: LstrFarmList[i].farm_id + ' - ' + LstrFarmList[i].farm_suffix + ' - ' + LstrFarmList[i].farm_name
        })
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddFarmNum: {
            ...state["ddFarmNum"],
            valueList: js
          }
        }
      })

      //setValue(thisObj, 'ddFarmNum', js.at(0).key);
      //setData(thisObj, 'ddFarmNum', js.at(0).description);
    }
    catch (err) {
      errorHandler(err);
    }
  }

  async function bFillCountyList(default_location, default_state) {
    try {
      //clearing existing data
      thisObj.setState(current => {
        return {
          ...current,
          ddCounty: {
            ...state["ddCounty"],
            valueList: []
          }
        }
      })

      let buyingPt = getValue(thisObj, 'ddLocation') == undefined ? default_location : getValue(thisObj, 'ddLocation');
      let stateLst = getData(thisObj, 'stateList')
      let stateAbbr = ''
      let stateValue = thisObj.values.ddState == undefined ? default_state : thisObj.values.ddState
      for (var i = 0; i < stateLst.length; i++) {
        if (stateLst[i].key == stateValue) {
          stateAbbr = stateLst[i].abbr;
          break;
        }
      }
      let js = []
      let LstrCountyList = await ContractManagementService.RetrieveCountyControlDetails(buyingPt, false, stateAbbr, null)

      if (LstrCountyList.length <= 0)
        return null;
      for (var i = 0; i < LstrCountyList.length; i++) {
        js.push({
          key: LstrCountyList[i].countyId,
          description: LstrCountyList[i].countyName.trim()
        })
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddCounty: {
            ...state["ddCounty"],
            valueList: js
          }
        }
      })

      setValue(thisObj, 'ddCounty', js.at(0).key);
      return js.at(0).key;
    }
    catch (err) {
      errorHandler(err);
      return null;
    }

  }

  async function bFillRemitToList() {
    try {
      let LstrList = await ContractManagementService.RetrieveVendorByNumber(thisObj.values.txtVendorNum)
      if (LstrList.length > 0) {
        let vendor_name = toPascalCase(LstrList[0].name)
        show(thisObj, "txtVendor")
        setValue(thisObj, "txtVendor", vendor_name)
        return true;
      }
      else
        hide(thisObj, "txtVendor", false)
      setValue(thisObj, "txtVendor", "")
      return false;
    }
    catch (err) {
      errorHandler(err);
      return false;
    }
  }



  async function SetDefaultHolder(HolderData = thisObj.state.ddHolderID.valueList, ddlocation = getValue(thisObj, "ddLocation"), ddHolder = getValue(thisObj, "ddHolderID")) {
    try {
      let dfltFound = false;

      let chkCMA = getValue(thisObj, 'chkboxCMA')
      let chkDMA = getValue(thisObj, 'chkboxDMA')
      let lstCurrentHolder = getData(thisObj, "lstCurrentHolder")
      let defaultHolder = getData(thisObj, 'lstDefaultHolder')
      let holderVendor = getData(thisObj, 'lstHolderVendor')
      let locCmaVendor = getData(thisObj, 'lstLocCmaVendor')
      let cboLoc = getData(thisObj, 'cboLocation') //check this value , might need location dropdown value here


      let ddHolderValue = ddHolder
      let ddLocationValue = ddlocation

      let LocationList = getData(thisObj, "locationValueList");
      let HolderList = HolderData;
      let locationIndex = LocationList.findIndex(item => item.key == ddLocationValue);


      if (chkCMA) {
        // for(var i=1; i<lstCurrentHolder.length; i++){
        //   if(defaultHolder[i-1] !== '' && defaultHolder[i-1] !== undefined && defaultHolder[i-1] !== null){
        //     if(defaultHolder[i-1].trim().toLocaleUpperCase() == 'D'){
        //       if(locCmaVendor[cboLoc.index] == holderVendor[i-1]){
        //         setValue(thisObj, 'ddHolderID', lstCurrentHolder.at(i).key);// cboCurrentHolder.ListIndex = LintCounter
        //         dfltFound = true;
        //         break;
        //       }
        //     }
        //   }
        // }
        // if (!dfltFound) {
        //   for (var i = 1; i < lstCurrentHolder.length; i++) {
        //     if (defaultHolder[i - 1] !== '' && defaultHolder[i-1] !== undefined && defaultHolder[i-1] !== null) {
        //       if (defaultHolder[i - 1].trim().toLocaleUpperCase() == 'D') {
        //         if (locCmaVendor[cboLoc.index] == holderVendor[i - 1]) {
        //           setValue(thisObj, 'ddHolderID', lstCurrentHolder.at(i).key);// cboCurrentHolder.ListIndex = LintCounter
        //           dfltFound = true;
        //           break;
        //         }
        //       }
        //     }
        //   }
        // }


        let keyForHolder = HolderList.filter(item => item.lstDefaultHolder == "C" && item.lstHolderVendor == LocationList[locationIndex].lstLocCmaVendor)[0]?.key;
        if (keyForHolder !== undefined) {
          setValue(thisObj, 'ddHolderID', keyForHolder);
          dfltFound = true;
        }
        if (!dfltFound) {
          keyForHolder = HolderList.filter(item => item.lstDefaultHolder == "Y")[0]?.key;
          if (keyForHolder !== undefined) {
            setValue(thisObj, 'ddHolderID', keyForHolder);
            dfltFound = true;
          }
        }
      }

      if (chkDMA) {
        // for(var i=1; i<lstCurrentHolder.length; i++){
        //   if(defaultHolder[i-1] !== '' && defaultHolder[i-1] !== undefined && defaultHolder[i-1] !== null){
        //     if(defaultHolder[i-1].trim().toLocaleUpperCase() == 'D'){
        //       if(locCmaVendor[cboLoc.index] == holderVendor[i-1]){
        //         setValue(thisObj, 'ddHolderID', lstCurrentHolder.at(i).key);// cboCurrentHolder.ListIndex = LintCounter
        //         dfltFound = true;
        //         break;
        //       }
        //     }
        //   }
        // }

        let keyForHolder = HolderList.filter(item => item.lstDefaultHolder == "D")[0]?.key;
        if (keyForHolder !== undefined) {
          setValue(thisObj, 'ddHolderID', keyForHolder);
          dfltFound = true;
        }

      }

      if (!chkCMA && !chkDMA) {
        // for(var i=1; i<lstCurrentHolder.length; i++){
        //   if(defaultHolder[i-1] !== '' && defaultHolder[i-1] !== undefined && defaultHolder[i-1] !== null){
        //     if(defaultHolder[i-1].trim().toLocaleUpperCase() == 'D'){
        //       if(locCmaVendor[cboLoc.index] == holderVendor[i-1]){
        //         setValue(thisObj, 'ddHolderID', lstCurrentHolder.at(i).key);// cboCurrentHolder.ListIndex = LintCounter
        //         dfltFound = true;
        //         break;
        //       }
        //     }
        //   }
        // }


        let keyForHolder = HolderList.filter(item => item.lstDefaultHolder == "Y")[0]?.key;
        if (keyForHolder !== undefined) {
          setValue(thisObj, 'ddHolderID', keyForHolder);
          dfltFound = true;
        }
      }

      if (!dfltFound) {
        //setValue(thisObj, 'ddHolderID', lstCurrentHolder.at(0).key);//cboCurrentHolder.ListIndex = 0

        setValue(thisObj, 'ddHolderID', ddHolderValue)
      }

    }
    catch (err) {
      errorHandler(err);
    }
  }


  async function DoSearch(sAction) {
    try {
      setLoading(true);
      let lstrvendor;
      let sCMD = [];
      let nV = 0;
      let sCmaSearch, msApplied, msContractType;
      let Farm_suffix;
      let totalNetWeight = 0;
      let totalValue = 0;

      let stateLst = getData(thisObj, 'stateList')
      let stateAbbr = ''
      for (var i = 0; i < stateLst.length; i++) {
        if (stateLst[i].key == getValue(thisObj, 'ddState')) {
          stateAbbr = stateLst[i].abbr;
          break;
        }
      }
      //column list : _ _ 1007, Farm, Contract, SC95, Inspect Date, Unl. BP, Unl. Whse, Unl. Bin, Obl. BP, Obl. Whse, Obl. Bin, Net LBS, 1007 Value, Seg, Type, variety, Seed Gen, Seed Spec, Oliec, Group, State, County
      SetDisplayColumnWidths();
      let radioButtonValue = thisObj.values.radioAppliedTo


      if (radioButtonValue == '5') {
        msApplied = "N"
        msContractType = "S"
      } else {
        msApplied = "Y"
        switch (radioButtonValue) {
          case "1":
            msContractType = "O"
            break;
          case "2":
            msContractType = "M"
            break;
          case "3":
            msContractType = "C"
            break;
          case "4":
            msContractType = "L"
            break;

          default:
            break;
        }
      }

      nV = 0;

      let cMASearch = getValue(thisObj, 'chkboxCMASearch')
      sCmaSearch = cMASearch == true ? 'YES' : 'NO'
      setData(thisObj, "msApplied", msApplied);
      setData(thisObj, "msContractType", msContractType);

      let farmId = thisObj.values['ddFarmNum']
      //farmId = farmId.slice(0, farmId.indexOf(" -")).trim()
      let farmList = thisObj.state.ddFarmNum.valueList
      let description = farmList.filter(item => item.key == farmId)[0]?.description
      Farm_suffix = description?.split(" - ")[1]

      let dataObj = {
        settlement_grouping_num: sAction == 'REPRINT' ? getValue(thisObj, "txtNumber") : '0',  // txtReprintGroupingNum 
        purch_store_ind: 'S',
        settlement_num: 'S',
        applied: sAction == 'REPRINT' ? '' : msApplied,  //in asp , this parameter is commented hence api is not reading this one
        contract_type: sAction == 'REPRINT' ? '' : msContractType,
        buyingPtId: sAction == 'REPRINT' ? '' : getValue(thisObj, 'ddLocation'),
        split_vendor: sAction == 'REPRINT' ? '' : thisObj.values.txtVendorNum,
        state_abbr: sAction == 'REPRINT' ? '' : stateAbbr,
        countyId: sAction == 'REPRINT' ? '' : getValue(thisObj, 'ddCounty'),
        farmId: sAction == 'REPRINT' ? '' : farmId?.split(" - ")[0],
        farm_suffix: sAction == 'REPRINT' ? '' : Farm_suffix,
        contract_num: sAction == 'REPRINT' ? '' : thisObj.values.txtContractNum,
        pnut_type_id: sAction == 'REPRINT' ? '' : getValue(thisObj, 'ddPeanutType'),
        pnut_variety_id: sAction == 'REPRINT' ? '' : getValue(thisObj, 'ddPeanutVariety'),
        seg_type: sAction == 'REPRINT' ? '' : getValue(thisObj, 'ddSegType'),
        oliec_ind: sAction == 'REPRINT' ? '' : getValue(thisObj, 'ddOleic'),
        seed_gen: sAction == 'REPRINT' ? '' : getValue(thisObj, 'ddGeneration'),
        seed_rcpt: sAction == 'REPRINT' ? '' : getValue(thisObj, 'ddSeed'),
        cmaSearch: sAction == 'REPRINT' ? '' : sCmaSearch, //sCmaSearch,
        inspNumber: '',
        inspStatus: '',
        obl_buy_id: '',
        insp_start_date_time: '',
        insp_end_date_time: '',
        rcpt_buy_pt: '',
        whse_rcpt_num: ''
      }   
      sCMD = await WarehouseReceiptService.RetrieveInspectHeaderAvailable1007Details(dataObj.buyingPtId, dataObj.inspNumber, dataObj.settlement_num, dataObj.pnut_type_id, dataObj.pnut_variety_id, dataObj.farmId, dataObj.farm_suffix, dataObj.inspStatus, dataObj.seed_rcpt, dataObj.seed_gen, dataObj.seg_type, dataObj.oliec_ind, dataObj.state_abbr,
        dataObj.countyId, dataObj.purch_store_ind, dataObj.obl_buy_id, dataObj.insp_start_date_time, dataObj.insp_end_date_time, dataObj.split_vendor, dataObj.contract_num, dataObj.rcpt_buy_pt, dataObj.contract_type, dataObj.settlement_grouping_num, dataObj.whse_rcpt_num, dataObj.cmaSearch)
      if (sCMD.message !== undefined || sCMD.length < 1) {
        setLoading(false)
        //showMessage(thisObj, sCMD.message ? sCMD.message : "Data not found" )
        setValue(thisObj, "gridAvailable1007s", [])
        return [];
      }

      let js = []
      let settlementNum;
      let farmName
      for (var i = 0; i < sCMD.length; i++) {
        settlementNum = replicateString("0", 7 - sCMD[i].settlement_num.trim().length) + sCMD[i].settlement_num
        farmName = sCMD[i].farm_name.split(' - ')[0] + ' - ' + sCMD[i].farm_name.split(' - ')[1]
        js.push({
          btnCol: "VIEW 1007",
          chkboxcol: false,
          txtcol1007: settlementNum,
          txtcolFarm: farmName,
          txtcolContract: sCMD[i].contract_num,
          txtcolSC95: sCMD[i].insp_num,
          txtcolInspectDate: moment(sCMD[i].insp_date_time).format("MM/DD/YYYY"),
          txtcolUnlBP: sCMD[i].buy_pt_id,
          txtcolUnlWhse: sCMD[i].unld_whse_id,
          txtcolUnlBin: sCMD[i].unld_bin_id,
          txtcolOblLoc: sCMD[i].obl_pt_name,
          txtcolOblBP: sCMD[i].obl_buy_id,
          txtcolOblWhse: sCMD[i].obl_whse_id,
          txtcolOblBin: sCMD[i].obl_bin_id,
          txtcolNetLbs: sCMD[i].net_wt,
          txtcol1007Value: sCMD[i].basis_grade_amt,
          txtcolSeg: sCMD[i].seg_type,
          txtcolType: sCMD[i].pnut_type_id,
          txtcolVariety: sCMD[i].pnut_variety_id,
          txtcolSeedGen: sCMD[i].seed_gen,
          txtcolSeedSpec: sCMD[i].seed_meeting_spec_ind,
          txtcolOleic: sCMD[i].oleic_ind,
          txtcolGroup: sCMD[i].settlement_grouping_num,
          txtcolState: sCMD[i].state_abbr,
          txtcolCounty: sCMD[i].county_id
        })

        totalNetWeight += Number(sCMD[i].net_wt)
        totalValue += Number(sCMD[i].basis_grade_amt)

        setData(thisObj, 'lblReprintCmaDma', sCMD[i].settle_grp_cma_dma_ind)
        setData(thisObj, 'lblReprintDeferred', sCMD[i].settle_grp_deferred_ind)
        setData(thisObj, 'lblReprintGrpDate', sCMD[i].settle_grp_date)
        setData(thisObj, 'lblReprintGrpHolder', sCMD[i].settle_grp_holder_id)

        if (sAction == "REPRINT" && nV == 0) {
          setData(thisObj, 'lblReprintVendor', sCMD[i].split_vendor)
          lstrvendor = await ContractManagementService.RetrieveVendorByNumber(sCMD[i].split_vendor);
          if (lstrvendor.length > 0)
            setData(thisObj, 'lblReprintVendorName', lstrvendor[0]?.name)
          nV = 1;
        }

      }
      setValue(thisObj, 'gridAvailable1007s', js)

      setValue(thisObj, 'txtTotalNetWeight', '0')
      setValue(thisObj, 'txtTotalValue', '0')

      if (sAction == 'REPRINT') {
        setData(thisObj, "msGrouping", nV == 0 ? '000' : getValue(thisObj, "txtNumber"));

        setData(thisObj, "reprint_totalNetWeight" , totalNetWeight)
        setData(thisObj, "reprint_totalValue" , totalValue)
      }

      setData(thisObj, 'lblTotNetWt', 0)
      setData(thisObj, 'lblTotValue', 0)
      setLoading(false);
      return js;
    }
    catch (err) {
      errorHandler(err);
    }
    // finally{
    //   setLoading(false);
    // }
  }

  async function SetDisplayColumnWidths() {
    try {
      hideColumn(thisObj, "gridAvailable1007s", "txtcolUnlBP")
      hideColumn(thisObj, "gridAvailable1007s", "txtcolOblLoc")
      hideColumn(thisObj, "gridAvailable1007s", "txtcolGroup")
      hideColumn(thisObj, "gridAvailable1007s", "txtcolState")
      hideColumn(thisObj, "gridAvailable1007s", "txtcolCounty")
    }
    catch (err) {
      errorHandler(err);
    }
  }

  async function CalcTotals(unchecked) {
    try {
      let totalWeight = 0;
      let totalValue = 0;
      setData(thisObj, 'lblTotNetWt', 0)
      setData(thisObj, 'lblTotValue', 0)
      let gridData = getValue(thisObj, "gridAvailable1007s");

      if (unchecked) {
        for (let i = 0; i < gridData.length; i++) {
          totalWeight += Number(gridData[i].txtcolNetLbs)
          totalValue += Number(gridData[i].txtcol1007Value)
        }

      } else {
        for (let i = 0; i < gridData.length; i++) {
          if (gridData[i].chkboxcol) {
            totalWeight += Number(gridData[i].txtcolNetLbs)
            totalValue += Number(gridData[i].txtcol1007Value)
          }
        }
      }

      setValue(thisObj, 'txtTotalNetWeight', totalWeight.toFixed(2).split('.')[1] == '00' ? totalWeight.toFixed(2).split('.')[0] : totalWeight.toFixed(2))
      setValue(thisObj, 'txtTotalValue', totalValue.toFixed(2).split('.')[1] == '00' ? totalValue.toFixed(2).split('.')[0] : totalValue.toFixed(2))

      setData(thisObj, 'lblTotNetWt', totalWeight.toFixed(2).split('.')[1] == '00' ? totalWeight.toFixed(2).split('.')[0] : totalWeight.toFixed(2))
      setData(thisObj, 'lblTotValue', totalValue.toFixed(2).split('.')[1] == '00' ? totalValue.toFixed(2).split('.')[0] : totalValue.toFixed(2))
    }
    catch (err) {
      errorHandler(err);
    }
  }

  async function PrintGrouping(sAction , searchResponse = []) {
    try {
      let sDocName = '';
      let sCMAorDMA = '';
      let sVendorNum = '';
      let sVendorName = '';
      let n = 0;
      let gridData = sAction == 'REPRINT' ? searchResponse : getValue(thisObj, "gridAvailable1007s")

      if (sAction == 'REPRINT') {
        sVendorNum = getData(thisObj, 'lblReprintVendor')
        sVendorName = getData(thisObj, 'lblReprintVendorName')
        if (getData(thisObj, 'lblReprintCmaDma') !== undefined && getData(thisObj, 'lblReprintCmaDma') !== null) {
          if (getData(thisObj, 'lblReprintCmaDma').toLocaleUpperCase() == 'C') {
            sCMAorDMA = ' CMA '
          }
          else if (getData(thisObj, 'lblReprintCmaDma').toLocaleUpperCase() == 'D') {
            sCMAorDMA = ' DMA '
          }
          else {
            sCMAorDMA = ''
          }
        }
      }
      else {
        sVendorNum = getValue(thisObj, "txtVendorNum")
        sVendorName = getValue(thisObj, "txtVendor")

        let newGridData = [];
        for (let i = 0; i < gridData.length; i++) {
          if (gridData[i].chkboxcol == true) {
            newGridData.push(gridData[i])
          }
        }
        gridData = newGridData;

        if (getValue(thisObj, "chkboxCMA")) {
          sCMAorDMA = ' CMA '
        }
        else if (getValue(thisObj, "chkboxDMA")) {
          sCMAorDMA = ' DMA '
        }
        else {
          sCMAorDMA = ''
        }
      }

      if (sAction == "REPRINT") {
        sDocName = "Whouse Receipt Request: " + moment(getData(thisObj, "lblReprintGrpDate")).format("MM/DD/YYYY") + " Grp#" + getData(thisObj, "msGrouping") + " Vendor:" + sVendorNum + " " + sVendorName + (getData(thisObj, "lblReprintDeferred").toUpperCase() == "Y" ? " DEFERRED " : " ") + sCMAorDMA + " " + getData(thisObj, "lblReprintGrpHolder") + " "
      } else {
        sDocName = "Whouse Receipt Request: " + moment().format("MM/DD/YYYY") + " Grp#" + getData(thisObj, "msGrouping") + " Vendor:" + sVendorNum + " " + sVendorName + (getValue(thisObj, "chkboxDeferred") == true ? " DEFERRED " : " ") + sCMAorDMA + " " + getValue(thisObj, "ddHolderID") + " "
      }

      var obj = new jsPDF({
        orientation: 'landscape',
      });
      let bodyArray = [];
      let headerArray = [];
      let header = [];

      //dynamic binding for Print 

      let headerAll = ["1007", "Farm", "Contract", "SC95", "Inspect Date", "Unl. BP", "Unl. Whse", "Unl. Bin", "Obl. Loc", "Obl. BP", "Obl. Whse", "Obl. Bin", "Net LBS", "1007 Value", "Seg", "Type", "Variety", "Seed Gen", "Seed Spec", "Oleic", "Group", "State", "County"];

      for (let i = 0; i < thisObj.state.gridAvailable1007s.columns.length - 2; i++) {
        if (thisObj.state.gridAvailable1007s.columns[i + 2].Visible) {
          header.push(headerAll[i])
        }
      }

      headerArray.push(header);

      //let columnName = ["txtcol1007","txtcolFarm","txtcolContract","txtcolSC95","txtcolInspectDate","txtcolUnlBP","txtcolUnlWhse","txtcolUnlBin","txtcolOblLoc","txtcolOblBP","txtcolOblWhse","txtcolOblBin","txtcolNetLbs","txtcol1007Value","txtcolSeg","txtcolType","txtcolVariety","txtcolSeedGen","txtcolSeedSpec","txtcolOleic","txtcolGroup","txtcolState","txtcolCounty"]
      let columnName = ["txtcol1007", "txtcolFarm", "txtcolContract", "txtcolSC95", "txtcolUnlBP", "txtcolUnlWhse", "txtcolUnlBin", "txtcolOblLoc", "txtcolOblBP", "txtcolOblWhse", "txtcolOblBin", "txtcolInspectDate", "txtcolNetLbs", "txtcol1007Value", "txtcolSeg", "txtcolType", "txtcolVariety", "txtcolSeedGen", "txtcolSeedSpec", "txtcolOleic", "txtcolGroup", "txtcolState", "txtcolCounty"]

      for (let i = 0; i < gridData.length; i++) {
        let rowData = [];

        for (let j = 0; j < thisObj.state.gridAvailable1007s.columns.length - 2; j++) {
          if (thisObj.state.gridAvailable1007s.columns[j + 2].Visible) {
            rowData.push(gridData[i][columnName[j]])
          }
        }

        bodyArray.push(rowData);
      }

      let secondLastRow = []
      let lastRow = []
      for (let i = 0; i < header.length; i++) {
        let noValue = false;
        secondLastRow.push('');
        if (header[i] == "Obl. Bin") {
          lastRow.push('Total:')
          noValue = true;
        }
        if (header[i] == "Net LBS") {
          lastRow.push(sAction == 'REPRINT' ? getData(thisObj , "reprint_totalNetWeight") : getData(thisObj, 'lblTotNetWt'))
          noValue = true;
        }
        if (header[i] == "1007 Value") {
          lastRow.push(sAction == 'REPRINT' ? getData(thisObj , "reprint_totalValue") : getData(thisObj, 'lblTotValue'))
          noValue = true;
        }
        if (noValue == false) {
          lastRow.push('')
        }
      }
      bodyArray.push(secondLastRow);
      bodyArray.push(lastRow);

      //index of columns which requires left alignments
      let aligncolumn1 = headerArray[0].indexOf("Net LBS");
      let aligncolumn2 = headerArray[0].indexOf("1007 Value");

      

      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 7, lineWidth: 0.2, color: 10 ,
          cell: { textAlign: 'center' },
          head: { textAlign: 'center' }
        },
        body: bodyArray,
        theme: 'plain',
        fontStyle: 'normal',
        head: headerArray,


        //for coloring row
        didParseCell: function (res) {

          if ((aligncolumn1 == res.column.index || aligncolumn2 == res.column.index) && res.section !== "head") {
            res.cell.styles.halign = "right"
          } else {
            res.cell.styles.halign = "center"
          }
        },

      });

      var pageCount = obj.internal.getNumberOfPages();
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(7);
        obj.text(sDocName, 10, obj.internal.pageSize.height - 10);
      }

      let fileName = prompt("Please enter the file name :");
      if (fileName !== null && fileName !== undefined) {
        if (fileName !== '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Invalid file name, please try again!")
        }
      }
      else {
        alert("Invalid file name, please try again!")
      }

    }
    catch (err) {
      errorHandler(err);
    }
  }

  // Change events

  async function lblVendor_Change() {
    try {
      let lblVendorNum = thisObj.values.txtVendorNum
      let lblVendorName = thisObj.values.txtVendor
      let vendorboolean = false;
      if (lblVendorNum?.length > 5) {
        vendorboolean = await bFillRemitToList()
      } else {
        hide(thisObj, "txtVendor", false)
        setValue(thisObj, "txtVendor", "")
      }
      disable(thisObj, 'btnSearch')
      if (vendorboolean) {
        enable(thisObj, 'btnSearch')
      }
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.ontxtVendorNumChange = lblVendor_Change;


  //dropdown changes started
  const onddLocationChange = async () => {
    try {
      let locationvalue = getValue(thisObj, "ddLocation")
      await bFillPeanutVarietyList(locationvalue)
      await bFillFarmList(locationvalue)
      setData(thisObj, 'ddLocation', locationvalue);  //required for grid-button click
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onddLocationChange = onddLocationChange;

  const onddPeanutTypeChange = async () => {
    try {
      let locationvalue = getValue(thisObj, "ddLocation")
      let pnuttypevalue = getValue(thisObj, "ddPeanutType")
      await bFillPeanutVarietyList(locationvalue, pnuttypevalue)
    } catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange;

  const onddStateChange = () => {
    try {
      bFillCountyList()
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onddStateChange = onddStateChange;

  const onddCountyChange = () => {
    try {
      bFillFarmList()
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onddCountyChange = onddCountyChange;
  //dropdown changed ended

  const onchkboxCMAChange = () => {
    try {
      let DMAvalue = getValue(thisObj, "chkboxDMA")
      if (DMAvalue) {
        setValue(thisObj, "chkboxDMA", false);
      }
      SetDefaultHolder()
    } catch (error) {
      errorHandler(error);
    }
  }
  thisObj.onchkboxCMAChange = onchkboxCMAChange;

  const onchkboxDMAChange = () => {
    try {
      let CMAvalue = getValue(thisObj, "chkboxCMA")
      if (CMAvalue) {
        setValue(thisObj, "chkboxCMA", false);
      }
      SetDefaultHolder()
    } catch (error) {
      errorHandler(error);
    }
  }
  thisObj.onchkboxDMAChange = onchkboxDMAChange;

  const onhckboxAll1007sChange = () => {
    try {
      let gridData = getValue(thisObj, "gridAvailable1007s");
      let checkboxValue = getValue(thisObj, "hckboxAll1007s")
      if (gridData !== undefined && gridData !== null && gridData.length > 0) {
        if (checkboxValue) {
          let totalWeight = 0, totalValue = 0;
          for (let i = 0; i < gridData.length; i++) {
            gridData[i].chkboxcol = true
            totalWeight = Number(totalWeight) + Number(gridData[i].txtcolNetLbs);
            totalValue = Number(totalValue) + Number(gridData[i].txtcol1007Value);
          }
          setValue(thisObj, "gridAvailable1007s", gridData);
          setValue(thisObj, 'txtTotalNetWeight', totalWeight.toFixed(2).split('.')[1] == '00' ? totalWeight.toFixed(2).split('.')[0] : totalWeight.toFixed(2))
          setValue(thisObj, 'txtTotalValue', totalValue.toFixed(2).split('.')[1] == '00' ? totalValue.toFixed(2).split('.')[0] : totalValue.toFixed(2))

        } else {
          for (let i = 0; i < gridData.length; i++) {
            gridData[i].chkboxcol = false
          }
          setValue(thisObj, "gridAvailable1007s", gridData);
          setValue(thisObj, 'txtTotalNetWeight', '0')
          setValue(thisObj, 'txtTotalValue', '0')

        }
      }
    } catch (error) {
      errorHandler(error);
    }
  }
  thisObj.onhckboxAll1007sChange = onhckboxAll1007sChange;

  //checkbox inside grid
  const onchkboxcolChange = () => {
    try {
      CalcTotals(false);
    } catch (error) {
      errorHandler(error);
    }
  }
  thisObj.onchkboxcolChange = onchkboxcolChange;

  // Click events
  //buttons inside grid
  const onbtnColClick = (event) => {
    try {
      let location = getData(thisObj, 'ddLocation');
      let highlight = document.getElementsByClassName("activeRow")
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow")
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement
      //rowSelected.classList.add("activeRow")
      let rowID = rowSelected.id.split('-')[1];
      let gridData = getValue(thisObj, 'gridAvailable1007s')
      if (gridData !== undefined && gridData !== null && gridData.length > 0) {
        let settlement_num = gridData[rowID].txtcolSC95;

        let viewSettlementScreenData = {
          inspNum: settlement_num,
          buyPtId: location
        }
        setData(thisObj, 'viewSettlementData', viewSettlementScreenData);
        goTo(thisObj, "Settlements#ViewSettlement#DEFAULT#true#Click");
      }

    } catch (error) {
      errorHandler(error);
    }
  }
  thisObj.onbtnColClick = onbtnColClick;

  const onbtnSearchClick = async () => {
    try {
      let locationDropdown = getValue(thisObj, "ddLocation")
      let vendorValue = getValue(thisObj, "txtVendor")
      if (vendorValue == undefined || vendorValue == "" || vendorValue == null || locationDropdown == undefined || locationDropdown == null || locationDropdown == "") {
        showMessage(thisObj, 'Vendor#, Location # must be specified for searches')
        document.getElementById('txtVendorNum').focus();
        return;
      }
      let farmDropdown = getValue(thisObj, "ddFarmNum")
      if (farmDropdown == undefined || farmDropdown == null || farmDropdown == '') {
        showMessage(thisObj, 'Farm# must be specified for searches')
        document.getElementById('txtFarmNum').focus();
        return;
      }
      let contractValue = getValue(thisObj, "txtContractNum")
      let radioButtonValue = getValue(thisObj, "radioAppliedTo")
      if ((contractValue == undefined || contractValue == null || contractValue == '') && (radioButtonValue == "3")) {
        showMessage(thisObj, 'Must Enter a Contract Number when searching Farmer Stock Contract.')
        document.getElementById('txtContractNum').focus();
        return;
      }

      setValue(thisObj, 'hckboxAll1007s', false)
      DoSearch("")
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const onbtnGroupAndPrintClick = async () => {
    try {
      setLoading(true);
      let bSelectedRows = false,
        strAction = "UPDATE", sCMAorDMA, sdeferred;
      setData(thisObj, "msGrouping", "")

      let gridData = getValue(thisObj, "gridAvailable1007s");
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].chkboxcol == true) {
          bSelectedRows = true;
          if (gridData[i].txtcolGroup !== undefined && gridData[i].txtcolGroup !== null && gridData[i].txtcolGroup !== "" && !isNaN(gridData[i].txtcolGroup)) {
            alert("1007 #" + gridData[i].txtcol1007 + " already belongs to grouping " + gridData[i].txtcolGroup + '.');
            setLoading(false);
            return;
          }
        }
      }

      if (!bSelectedRows) {
        showMessage(thisObj, "At least one 1007 # must be checkmarked for selection.");
        setLoading(false);
        return;
      }

      let CMAvalue = getValue(thisObj, "chkboxCMA");
      let DMAvalue = getValue(thisObj, "chkboxDMA");
      let deferredValue = getValue(thisObj, "chkboxDeferred")
      let holderID = getValue(thisObj, "ddHolderID");

      if (CMAvalue) {
        sCMAorDMA = "C";
        if (holderID == undefined || holderID == null || holderID == "") {
          showMessage(thisObj, "Holder must be selected for CMA!")
          setLoading(false);
          return;
        }
      } else if (DMAvalue) {
        sCMAorDMA = "D";
        if (holderID == undefined || holderID == null || holderID == "") {
          showMessage(thisObj, "Holder must be selected for DMA!")
          setLoading(false);
          return;
        }
      } else {
        sCMAorDMA = "N";
      }

      if (deferredValue) {
        sdeferred = "Y"
      } else {
        sdeferred = "N";
      }

      let bps_list = ""
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].chkboxcol == true) {
          bps_list += gridData[i].txtcolUnlBP + "," + gridData[i].txtcol1007 + "," + gridData[i].txtcolContract + "|"
        }
      }

      let dataObj = {
        "applied": getData(thisObj, "msApplied"),
        "cont_type": getData(thisObj, "msContractType"),
        "vendor": getValue(thisObj, "txtVendorNum"),
        "bps_list": bps_list,
        "settle_grp_cma_dma_ind": sCMAorDMA,
        "settle_grp_deferred_ind": sdeferred,
        "settle_grp_date": new Date().toISOString(),
        "settle_grp_holder_id": holderID
      }

      let response = await WarehouseReceiptService.Update1007Grouping(strAction, 0, dataObj);
      if (response.status == 200) {
        setData(thisObj, "msGrouping", response.result);
        alert(response.message);
      } else {
        setLoading(false);
        alert("1007 Grouping " + strAction + " failed.");
        return;
      }

      setLoading(false);
      CalcTotals(false);
      await PrintGrouping("");
      setValue(thisObj, "hckboxAll1007s", false);
      await DoSearch("");
      setValue(thisObj, 'txtTotalNetWeight', '0')
      setValue(thisObj, 'txtTotalValue', '0')

    } catch (error) {
      errorHandler(error);
    }
  }
  thisObj.onbtnGroupAndPrintClick = onbtnGroupAndPrintClick;

  const onbtnCancelClick = () => {
    try {
      document.getElementsByClassName("close")[0].click();
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onbtnReprintGroupingClick = async () => {
    try {

      let txtReprintGroupingNum = thisObj.values.txtNumber;
      let selectedRows;
      if (txtReprintGroupingNum !== undefined && txtReprintGroupingNum !== '' && !isNaN(txtReprintGroupingNum)) {
        if (isEnabled(thisObj, 'btnSearch')) {
          setData(thisObj, 'lblTotNetWt', 0)
          setData(thisObj, 'lblTotValue', 0)

          selectedRows = []
          let oldGridData = getValue(thisObj, "gridAvailable1007s");
          for (let i = 0; i < oldGridData.length; i++) {
            if (oldGridData[i].chkboxcol == true) {
              selectedRows.push(oldGridData[i].txtcol1007)
            }
          }
        }

        let response = await DoSearch('REPRINT')
        await CalcTotals(true)

        if (getData(thisObj, "msGrouping") == '000' || response == undefined || response == [] || response.length == 0) {
          showMessage(thisObj, 'Grouping does not exist!')
          setData(thisObj, "msGrouping", "")
        }
        else {
          await PrintGrouping('REPRINT' , response)
        }

        setData(thisObj, 'lblReprintVendor', '')
        setData(thisObj, 'lblReprintVendorName', '')

        if (isEnabled(thisObj, 'btnSearch')) {
          let newGridData = await DoSearch('')

          for (let i = 0; i < newGridData.length; i++) {
            if (selectedRows.includes(newGridData[i].txtcol1007)) {
              newGridData[i].chkboxcol = true;
            }

          }
          setValue(thisObj, "gridAvailable1007s", newGridData);

          CalcTotals(false)
        }
        else {
          setData(thisObj, 'lblTotNetWt', 0)
          setData(thisObj, 'lblTotValue', 0)

          setValue(thisObj, 'txtTotalNetWeight', '0')
          setValue(thisObj, 'txtTotalValue', '0')
          setValue(thisObj, "gridAvailable1007s", [])
        }

      }
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnReprintGroupingClick = onbtnReprintGroupingClick;

  //additional functions

  function replicateString(char, num) {
    return char.repeat(num);
  }

  function toPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  function errorHandler(err) {
    setLoading(false);
    showMessage(thisObj, err instanceof EvalError ? err.message : 'Something went wrong. Please try again.')
  }

  function errorHandler(err) {
    setLoading(false);
    showMessage(thisObj, err instanceof EvalError ? err.message : 'Something went wrong. Please try again.')
  }

  const setTabIndex = () => {
    let elements = [
      'txtVendorNum',
      'btnVendor',
      'ddLocation',
      'ddState',
      'ddCounty',
      'ddFarmNum',
      'txtContractNum',
      'ddHolderID',
      'ddPeanutType',
      'ddSeed',
      'ddSegType',
      'ddPeanutVariety',
      'ddGeneration',
      'ddOleic',
      'radioAppliedTo0',
      'chkboxCMASearch',
      'hckboxAll1007s',
      'btnGroupAndPrint',
      'chkboxCMA',
      'chkboxDMA',
      'chkboxDeferred',
      'btnReprintGrouping',
      'txtNumber',
      'btnCancel'
    ]
    elements.map((element, index) => {
      let tabElement = document.querySelectorAll(".modal")[0].querySelector(`#${element}`);
      if(tabElement){
        tabElement.tabIndex = index + 1;
      }
    })
  }
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={values => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_1007GrpSelect*/}

              {/* END_USER_CODE-USER_BEFORE_1007GrpSelect*/}

              {/* START_USER_CODE-USER_BEFORE_grpbx1007GrpSelect*/}

              {/* END_USER_CODE-USER_BEFORE_grpbx1007GrpSelect*/}

              <GroupBoxWidget
                conf={state.grpbx1007GrpSelect}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails1*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails1*/}

                <GroupBoxWidget conf={state.grpbxDetails1} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtVendorNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendorNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendorNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendorNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendorNum*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                  <ButtonWidget
                    conf={state.btnVendor}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                  {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_ddState*/}

                  {/* END_USER_CODE-USER_BEFORE_ddState*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddState}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddState*/}

                  {/* END_USER_CODE-USER_AFTER_ddState*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCounty*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCounty*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCounty}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCounty*/}

                  {/* END_USER_CODE-USER_AFTER_ddCounty*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFarmNum*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFarmNum*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFarmNum}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFarmNum*/}

                  {/* END_USER_CODE-USER_AFTER_ddFarmNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtContractNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtContractNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtContractNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtContractNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtContractNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFarmNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFarmNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFarmNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFarmNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtFarmNum*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails1*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails1*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails2*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails2*/}

                <GroupBoxWidget conf={state.grpbxDetails2} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutVariety}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeed*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeed*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeed}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeed*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeed*/}
                  {/* START_USER_CODE-USER_BEFORE_ddGeneration*/}

                  {/* END_USER_CODE-USER_BEFORE_ddGeneration*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddGeneration}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddGeneration*/}

                  {/* END_USER_CODE-USER_AFTER_ddGeneration*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSegType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSegType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSegType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSegType*/}

                  {/* END_USER_CODE-USER_AFTER_ddSegType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOleic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                  {/* START_USER_CODE-USER_BEFORE_lblGoupingNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_lblGoupingNumber*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblGoupingNumber}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblGoupingNumber*/}

                  {/* END_USER_CODE-USER_AFTER_lblGoupingNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_radioAppliedTo*/}

                  {/* END_USER_CODE-USER_BEFORE_radioAppliedTo*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioAppliedTo}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioAppliedTo*/}

                  {/* END_USER_CODE-USER_AFTER_radioAppliedTo*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails2*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails2*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxAvailable1007s*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxAvailable1007s*/}

                <GroupBoxWidget
                  conf={state.grpbxAvailable1007s}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblAvailable1007s*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAvailable1007s*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAvailable1007s}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAvailable1007s*/}

                  {/* END_USER_CODE-USER_AFTER_lblAvailable1007s*/}
                  {/* START_USER_CODE-USER_BEFORE_hckboxAll1007s*/}

                  {/* END_USER_CODE-USER_BEFORE_hckboxAll1007s*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.hckboxAll1007s}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_hckboxAll1007s*/}

                  {/* END_USER_CODE-USER_AFTER_hckboxAll1007s*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxCMASearch*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxCMASearch*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxCMASearch}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxCMASearch*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxCMASearch*/}
                  {/* START_USER_CODE-USER_BEFORE_gridAvailable1007s*/}

                  {/* END_USER_CODE-USER_BEFORE_gridAvailable1007s*/}

                  <GridWidget
                    refObject={thisObj}
                    conf={state.gridAvailable1007s}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridAvailable1007s}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridAvailable1007s*/}

                  {/* END_USER_CODE-USER_AFTER_gridAvailable1007s*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalNetWeight*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalNetWeight*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalNetWeight}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalNetWeight*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalNetWeight*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalValue*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalValue*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalValue}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalValue*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalValue*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxAvailable1007s*/}

                {/* END_USER_CODE-USER_AFTER_grpbxAvailable1007s*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}

                <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnGroupAndPrint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnGroupAndPrint*/}

                  <ButtonWidget
                    conf={state.btnGroupAndPrint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnGroupAndPrint*/}

                  {/* END_USER_CODE-USER_AFTER_btnGroupAndPrint*/}

                  <GroupBoxWidget conf={state.grpbxchkbox} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_chkboxCmaDmaDefRed*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCmaDmaDefRed*/}
                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA}
                      screenConf={state}
                    ></CheckboxWidget>
                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxDMA}
                      screenConf={state}
                    ></CheckboxWidget>
                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxDeferred}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCmaDmaDefRed*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCmaDmaDefRed*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_BEFORE_ddHolderID*/}

                  {/* END_USER_CODE-USER_BEFORE_ddHolderID*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddHolderID}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddHolderID*/}

                  {/* END_USER_CODE-USER_AFTER_ddHolderID*/}
                  {/* START_USER_CODE-USER_BEFORE_btnReprintGrouping*/}

                  {/* END_USER_CODE-USER_BEFORE_btnReprintGrouping*/}

                  <ButtonWidget
                    conf={state.btnReprintGrouping}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnReprintGrouping*/}

                  {/* END_USER_CODE-USER_AFTER_btnReprintGrouping*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNumber*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNumber}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNumber*/}

                  {/* END_USER_CODE-USER_AFTER_txtNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_lblCropYear*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCropYear*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCropYear}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCropYear*/}

                  {/* END_USER_CODE-USER_AFTER_lblCropYear*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                  <ButtonWidget
                    conf={state.btnCancel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                  {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

                {/* END_USER_CODE-USER_AFTER_grpbxActions*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbx1007GrpSelect*/}

              {/* END_USER_CODE-USER_AFTER_grpbx1007GrpSelect*/}

              {/* START_USER_CODE-USER_AFTER_1007GrpSelect*/}

              {/* END_USER_CODE-USER_AFTER_1007GrpSelect*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_1007GrpSelect);