/* eslint-disable*/
import React from "react";
import Settlements_ReviewCorrectionLog from "./ReviewCorrectionLog";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ReviewCorrectionLog Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ReviewCorrectionLog />);
    });
  });
  afterEach(cleanup);
  test("is ReviewCorrectionLog Loads Successfully", () => {
    expect(screen.getByText("ReviewCorrectionLog")).toBeInTheDocument;
  });
  test("Custom Test Cases for ReviewCorrectionLog", () => {
    // START_USER_CODE-USER_ReviewCorrectionLog_Custom_Test_Case
    // END_USER_CODE-USER_ReviewCorrectionLog_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_ReviewCorrectionLog />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(t("Settlements:ReviewCorrectionLog_btn1"));
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("Settlements:ReviewCorrectionLog_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("Settlements:ReviewCorrectionLog_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("Settlements:ReviewCorrectionLog_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("gridCorrectionLog(Grid Widget) Test Cases", async () => {
    let gridCorrectionLog = screen.getByTestId("gridCorrectionLog");
    let gridCorrectionLogbtn =
      gridCorrectionLog.nextElementSibling.firstElementChild;
    gridCorrectionLog =
      gridCorrectionLog.parentElement.parentElement.parentElement;
    expect(gridCorrectionLog.tagName).toBe("DIV");
    expect(gridCorrectionLog.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ReviewCorrectionLog_gridCorrectionLog")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridCorrectionLog", () => {
    // START_USER_CODE-USER_gridCorrectionLog_TEST
    // END_USER_CODE-USER_gridCorrectionLog_TEST
  });
  test("grpbxReviewCorrectionLog(GroupBox Widget) Test Cases", async () => {
    const grpbxReviewCorrectionLog = screen.getByTestId(
      "grpbxReviewCorrectionLog"
    );
    expect(grpbxReviewCorrectionLog.tagName).toBe("BUTTON");
    expect(grpbxReviewCorrectionLog.type).toBe("button");
    expect(grpbxReviewCorrectionLog.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxReviewCorrectionLog", () => {
    // START_USER_CODE-USER_grpbxReviewCorrectionLog_TEST
    // END_USER_CODE-USER_grpbxReviewCorrectionLog_TEST
  });
  test("gridCorrectionLog_txtcolCorrectionCount(Grid Widget) Test Cases", async () => {
    let gridCorrectionLog_txtcolCorrectionCount = screen.getByTestId(
      "gridCorrectionLog"
    );
    let gridCorrectionLog_txtcolCorrectionCountbtn =
      gridCorrectionLog_txtcolCorrectionCount.nextElementSibling
        .firstElementChild;
    gridCorrectionLog_txtcolCorrectionCount =
      gridCorrectionLog_txtcolCorrectionCount.parentElement.parentElement
        .parentElement;
    expect(gridCorrectionLog_txtcolCorrectionCount.tagName).toBe("DIV");
    expect(gridCorrectionLog_txtcolCorrectionCount.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ReviewCorrectionLog_gridCorrectionLog")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCorrectionCount", () => {
    // START_USER_CODE-USER_txtcolCorrectionCount_TEST
    // END_USER_CODE-USER_txtcolCorrectionCount_TEST
  });
  test("gridCorrectionLog_txtcolFieldDiscription(Grid Widget) Test Cases", async () => {
    let gridCorrectionLog_txtcolFieldDiscription = screen.getByTestId(
      "gridCorrectionLog"
    );
    let gridCorrectionLog_txtcolFieldDiscriptionbtn =
      gridCorrectionLog_txtcolFieldDiscription.nextElementSibling
        .firstElementChild;
    gridCorrectionLog_txtcolFieldDiscription =
      gridCorrectionLog_txtcolFieldDiscription.parentElement.parentElement
        .parentElement;
    expect(gridCorrectionLog_txtcolFieldDiscription.tagName).toBe("DIV");
    expect(gridCorrectionLog_txtcolFieldDiscription.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ReviewCorrectionLog_gridCorrectionLog")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFieldDiscription", () => {
    // START_USER_CODE-USER_txtcolFieldDiscription_TEST
    // END_USER_CODE-USER_txtcolFieldDiscription_TEST
  });
  test("gridCorrectionLog_txtcolLogDate(Grid Widget) Test Cases", async () => {
    let gridCorrectionLog_txtcolLogDate = screen.getByTestId(
      "gridCorrectionLog"
    );
    let gridCorrectionLog_txtcolLogDatebtn =
      gridCorrectionLog_txtcolLogDate.nextElementSibling.firstElementChild;
    gridCorrectionLog_txtcolLogDate =
      gridCorrectionLog_txtcolLogDate.parentElement.parentElement.parentElement;
    expect(gridCorrectionLog_txtcolLogDate.tagName).toBe("DIV");
    expect(gridCorrectionLog_txtcolLogDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ReviewCorrectionLog_gridCorrectionLog")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLogDate", () => {
    // START_USER_CODE-USER_txtcolLogDate_TEST
    // END_USER_CODE-USER_txtcolLogDate_TEST
  });
  test("gridCorrectionLog_txtcolNewValue(Grid Widget) Test Cases", async () => {
    let gridCorrectionLog_txtcolNewValue = screen.getByTestId(
      "gridCorrectionLog"
    );
    let gridCorrectionLog_txtcolNewValuebtn =
      gridCorrectionLog_txtcolNewValue.nextElementSibling.firstElementChild;
    gridCorrectionLog_txtcolNewValue =
      gridCorrectionLog_txtcolNewValue.parentElement.parentElement
        .parentElement;
    expect(gridCorrectionLog_txtcolNewValue.tagName).toBe("DIV");
    expect(gridCorrectionLog_txtcolNewValue.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ReviewCorrectionLog_gridCorrectionLog")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolNewValue", () => {
    // START_USER_CODE-USER_txtcolNewValue_TEST
    // END_USER_CODE-USER_txtcolNewValue_TEST
  });
  test("gridCorrectionLog_txtcolOldValue(Grid Widget) Test Cases", async () => {
    let gridCorrectionLog_txtcolOldValue = screen.getByTestId(
      "gridCorrectionLog"
    );
    let gridCorrectionLog_txtcolOldValuebtn =
      gridCorrectionLog_txtcolOldValue.nextElementSibling.firstElementChild;
    gridCorrectionLog_txtcolOldValue =
      gridCorrectionLog_txtcolOldValue.parentElement.parentElement
        .parentElement;
    expect(gridCorrectionLog_txtcolOldValue.tagName).toBe("DIV");
    expect(gridCorrectionLog_txtcolOldValue.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:ReviewCorrectionLog_gridCorrectionLog")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOldValue", () => {
    // START_USER_CODE-USER_txtcolOldValue_TEST
    // END_USER_CODE-USER_txtcolOldValue_TEST
  });
  test("gridCorrectionLog_txtcolRevisionNumber(Grid Widget) Test Cases", async () => {
    let gridCorrectionLog_txtcolRevisionNumber = screen.getByTestId(
      "gridCorrectionLog"
    );
    let gridCorrectionLog_txtcolRevisionNumberbtn =
      gridCorrectionLog_txtcolRevisionNumber.nextElementSibling
        .firstElementChild;
    gridCorrectionLog_txtcolRevisionNumber =
      gridCorrectionLog_txtcolRevisionNumber.parentElement.parentElement
        .parentElement;
    expect(gridCorrectionLog_txtcolRevisionNumber.tagName).toBe("DIV");
    expect(gridCorrectionLog_txtcolRevisionNumber.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ReviewCorrectionLog_gridCorrectionLog")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRevisionNumber", () => {
    // START_USER_CODE-USER_txtcolRevisionNumber_TEST
    // END_USER_CODE-USER_txtcolRevisionNumber_TEST
  });
  test("gridCorrectionLog_txtcolSettlementNumber(Grid Widget) Test Cases", async () => {
    let gridCorrectionLog_txtcolSettlementNumber = screen.getByTestId(
      "gridCorrectionLog"
    );
    let gridCorrectionLog_txtcolSettlementNumberbtn =
      gridCorrectionLog_txtcolSettlementNumber.nextElementSibling
        .firstElementChild;
    gridCorrectionLog_txtcolSettlementNumber =
      gridCorrectionLog_txtcolSettlementNumber.parentElement.parentElement
        .parentElement;
    expect(gridCorrectionLog_txtcolSettlementNumber.tagName).toBe("DIV");
    expect(gridCorrectionLog_txtcolSettlementNumber.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:ReviewCorrectionLog_gridCorrectionLog")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSettlementNumber", () => {
    // START_USER_CODE-USER_txtcolSettlementNumber_TEST
    // END_USER_CODE-USER_txtcolSettlementNumber_TEST
  });
});
