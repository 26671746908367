/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_SpotControlSetup from "./SpotControlSetup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SpotControlSetup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_SpotControlSetup />);
    });
  });
  afterEach(cleanup);
  test("is SpotControlSetup Loads Successfully", () => {
    expect(screen.getByText("SpotControlSetup")).toBeInTheDocument;
  });
  test("Custom Test Cases for SpotControlSetup", () => {
    // START_USER_CODE-USER_SpotControlSetup_Custom_Test_Case
    // END_USER_CODE-USER_SpotControlSetup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_SpotControlSetup />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlSetup_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlSetup_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btncmdEnableDisableControls(Button Widget) Test Cases", async () => {
    const btncmdEnableDisableControls = screen.getByTestId(
      "btncmdEnableDisableControls"
    );
    expect(btncmdEnableDisableControls).toBeInTheDocument;
    expect(btncmdEnableDisableControls.textContent).toEqual(
      t(
        "SystemMaintenanceSpecialFunctions:SpotControlSetup_btncmdEnableDisableControls"
      )
    );
  });
  test("Custom Test Cases for btncmdEnableDisableControls", () => {
    // START_USER_CODE-USER_btncmdEnableDisableControls_TEST
    // END_USER_CODE-USER_btncmdEnableDisableControls_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlSetup_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlSetup_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("gridSpotPrices(Grid Widget) Test Cases", async () => {
    let gridSpotPrices = screen.getByTestId("gridSpotPrices");
    let gridSpotPricesbtn =
      gridSpotPrices.nextElementSibling.firstElementChild;
    gridSpotPrices =
      gridSpotPrices.parentElement.parentElement.parentElement;
    expect(gridSpotPrices.tagName).toBe("DIV");
    expect(gridSpotPrices.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:SpotControlSetup_gridSpotPrices"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridSpotPrices", () => {
    // START_USER_CODE-USER_gridSpotPrices_TEST
    // END_USER_CODE-USER_gridSpotPrices_TEST
  });
  test("grpbxSpotControlSetup(GroupBox Widget) Test Cases", async () => {
    const grpbxSpotControlSetup = screen.getByTestId(
      "grpbxSpotControlSetup"
    );
    expect(grpbxSpotControlSetup.tagName).toBe("BUTTON");
    expect(grpbxSpotControlSetup.type).toBe("button");
    expect(grpbxSpotControlSetup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSpotControlSetup", () => {
    // START_USER_CODE-USER_grpbxSpotControlSetup_TEST
    // END_USER_CODE-USER_grpbxSpotControlSetup_TEST
  });
  test("lblSpotPrices(Label Widget) Test Cases", async () => {
    const lblSpotPrices = screen.getByTestId("lblSpotPrices");
    expect(lblSpotPrices.tagName).toBe("LABEL");
    expect(lblSpotPrices.classList).toContain("form-label");
    expect(lblSpotPrices.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SpotControlSetup_lblSpotPrices")
    );
  });
  test("Custom Test Cases for lblSpotPrices", () => {
    // START_USER_CODE-USER_lblSpotPrices_TEST
    // END_USER_CODE-USER_lblSpotPrices_TEST
  });
  test("gridSpotPrices_txtcolEffective(Grid Widget) Test Cases", async () => {
    let gridSpotPrices_txtcolEffective = screen.getByTestId(
      "gridSpotPrices"
    );
    let gridSpotPrices_txtcolEffectivebtn =
      gridSpotPrices_txtcolEffective.nextElementSibling
        .firstElementChild;
    gridSpotPrices_txtcolEffective =
      gridSpotPrices_txtcolEffective.parentElement.parentElement
        .parentElement;
    expect(gridSpotPrices_txtcolEffective.tagName).toBe("DIV");
    expect(gridSpotPrices_txtcolEffective.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:SpotControlSetup_gridSpotPrices"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolEffective", () => {
    // START_USER_CODE-USER_txtcolEffective_TEST
    // END_USER_CODE-USER_txtcolEffective_TEST
  });
  test("gridSpotPrices_txtcolSeg1(Grid Widget) Test Cases", async () => {
    let gridSpotPrices_txtcolSeg1 = screen.getByTestId(
      "gridSpotPrices"
    );
    let gridSpotPrices_txtcolSeg1btn =
      gridSpotPrices_txtcolSeg1.nextElementSibling.firstElementChild;
    gridSpotPrices_txtcolSeg1 =
      gridSpotPrices_txtcolSeg1.parentElement.parentElement
        .parentElement;
    expect(gridSpotPrices_txtcolSeg1.tagName).toBe("DIV");
    expect(gridSpotPrices_txtcolSeg1.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:SpotControlSetup_gridSpotPrices"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg1", () => {
    // START_USER_CODE-USER_txtcolSeg1_TEST
    // END_USER_CODE-USER_txtcolSeg1_TEST
  });
  test("gridSpotPrices_txtcolSeg2Edible(Grid Widget) Test Cases", async () => {
    let gridSpotPrices_txtcolSeg2Edible = screen.getByTestId(
      "gridSpotPrices"
    );
    let gridSpotPrices_txtcolSeg2Ediblebtn =
      gridSpotPrices_txtcolSeg2Edible.nextElementSibling
        .firstElementChild;
    gridSpotPrices_txtcolSeg2Edible =
      gridSpotPrices_txtcolSeg2Edible.parentElement.parentElement
        .parentElement;
    expect(gridSpotPrices_txtcolSeg2Edible.tagName).toBe("DIV");
    expect(gridSpotPrices_txtcolSeg2Edible.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:SpotControlSetup_gridSpotPrices"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg2Edible", () => {
    // START_USER_CODE-USER_txtcolSeg2Edible_TEST
    // END_USER_CODE-USER_txtcolSeg2Edible_TEST
  });
  test("gridSpotPrices_txtcolSeg2Oil(Grid Widget) Test Cases", async () => {
    let gridSpotPrices_txtcolSeg2Oil = screen.getByTestId(
      "gridSpotPrices"
    );
    let gridSpotPrices_txtcolSeg2Oilbtn =
      gridSpotPrices_txtcolSeg2Oil.nextElementSibling.firstElementChild;
    gridSpotPrices_txtcolSeg2Oil =
      gridSpotPrices_txtcolSeg2Oil.parentElement.parentElement
        .parentElement;
    expect(gridSpotPrices_txtcolSeg2Oil.tagName).toBe("DIV");
    expect(gridSpotPrices_txtcolSeg2Oil.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:SpotControlSetup_gridSpotPrices"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg2Oil", () => {
    // START_USER_CODE-USER_txtcolSeg2Oil_TEST
    // END_USER_CODE-USER_txtcolSeg2Oil_TEST
  });
  test("gridSpotPrices_txtcolSeg2(Grid Widget) Test Cases", async () => {
    let gridSpotPrices_txtcolSeg2 = screen.getByTestId(
      "gridSpotPrices"
    );
    let gridSpotPrices_txtcolSeg2btn =
      gridSpotPrices_txtcolSeg2.nextElementSibling.firstElementChild;
    gridSpotPrices_txtcolSeg2 =
      gridSpotPrices_txtcolSeg2.parentElement.parentElement
        .parentElement;
    expect(gridSpotPrices_txtcolSeg2.tagName).toBe("DIV");
    expect(gridSpotPrices_txtcolSeg2.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:SpotControlSetup_gridSpotPrices"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg2", () => {
    // START_USER_CODE-USER_txtcolSeg2_TEST
    // END_USER_CODE-USER_txtcolSeg2_TEST
  });
  test("gridSpotPrices_txtcolSeg3Edible(Grid Widget) Test Cases", async () => {
    let gridSpotPrices_txtcolSeg3Edible = screen.getByTestId(
      "gridSpotPrices"
    );
    let gridSpotPrices_txtcolSeg3Ediblebtn =
      gridSpotPrices_txtcolSeg3Edible.nextElementSibling
        .firstElementChild;
    gridSpotPrices_txtcolSeg3Edible =
      gridSpotPrices_txtcolSeg3Edible.parentElement.parentElement
        .parentElement;
    expect(gridSpotPrices_txtcolSeg3Edible.tagName).toBe("DIV");
    expect(gridSpotPrices_txtcolSeg3Edible.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:SpotControlSetup_gridSpotPrices"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg3Edible", () => {
    // START_USER_CODE-USER_txtcolSeg3Edible_TEST
    // END_USER_CODE-USER_txtcolSeg3Edible_TEST
  });
  test("gridSpotPrices_txtcolSeg3Oil(Grid Widget) Test Cases", async () => {
    let gridSpotPrices_txtcolSeg3Oil = screen.getByTestId(
      "gridSpotPrices"
    );
    let gridSpotPrices_txtcolSeg3Oilbtn =
      gridSpotPrices_txtcolSeg3Oil.nextElementSibling.firstElementChild;
    gridSpotPrices_txtcolSeg3Oil =
      gridSpotPrices_txtcolSeg3Oil.parentElement.parentElement
        .parentElement;
    expect(gridSpotPrices_txtcolSeg3Oil.tagName).toBe("DIV");
    expect(gridSpotPrices_txtcolSeg3Oil.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:SpotControlSetup_gridSpotPrices"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg3Oil", () => {
    // START_USER_CODE-USER_txtcolSeg3Oil_TEST
    // END_USER_CODE-USER_txtcolSeg3Oil_TEST
  });
  test("gridSpotPrices_txtcolSeg3(Grid Widget) Test Cases", async () => {
    let gridSpotPrices_txtcolSeg3 = screen.getByTestId(
      "gridSpotPrices"
    );
    let gridSpotPrices_txtcolSeg3btn =
      gridSpotPrices_txtcolSeg3.nextElementSibling.firstElementChild;
    gridSpotPrices_txtcolSeg3 =
      gridSpotPrices_txtcolSeg3.parentElement.parentElement
        .parentElement;
    expect(gridSpotPrices_txtcolSeg3.tagName).toBe("DIV");
    expect(gridSpotPrices_txtcolSeg3.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceSpecialFunctions:SpotControlSetup_gridSpotPrices"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg3", () => {
    // START_USER_CODE-USER_txtcolSeg3_TEST
    // END_USER_CODE-USER_txtcolSeg3_TEST
  });
});
