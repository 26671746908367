/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  getData,
  setData,
  getValue,
  setValue,
  goTo,
  disable,
  enable,
  hide,
  show,
  AutocompleteWidget,
  getSelectedRowNumber,
  isEnabled,
  clearValue,
  hideWidgets,
  showWidgets,
  setFieldValues
} from "../../shared/WindowImports";
import { ExcelNumericDataConvert,ExcelNumericDatafloatConvert } from "../../Common/CommonFunctions"
import jsPDF from 'jspdf';

import "./WarehouseBinSetup.scss";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";

import { SettlementService } from "../../Settlements/Service/SettlementService";
import Loading from "../../../Loader/Loading";
import * as XLSX from 'xlsx/xlsx.mjs';

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_WarehouseBinSetup(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES
  const compIdFromLS = sessionStorage.getItem('compId');
  const cropYearFromLS = JSON.parse(sessionStorage.getItem('year'))
  let states = {
    Label: "WarehouseBinSetup",
    windowName: "WarehouseBinSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.WarehouseBinSetup",
    // START_USER_CODE-USER_WarehouseBinSetup_PROPERTIES
    horizontalForm:true,
    headerData: {
     scrName: "Warehouse And Bin Setup",
     scrCode: "PN0090A",
   },
    // END_USER_CODE-USER_WarehouseBinSetup_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: ".",
      CharWidth: "6",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxWarehouseBinSetup",
      Label: "Add",
      CharWidth: "10",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCopy: {
      name: "btnCopy",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Copy",
      CharWidth: "11",
      // START_USER_CODE-USER_btnCopy_PROPERTIES

      // END_USER_CODE-USER_btnCopy_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxWarehouseBinSetup",
      Label: "Delete",
      CharWidth: "15",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxWarehouseBinSetup",
      Label: "Edit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export to Excel",
      CharWidth: "32",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "grpbxWarehouseBinSetup",
      ColSpan: "5",
      ColSpanForLargeDesktop: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxWarehouseBinSetup",
      Label: "Buying Point",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    gridCol: {
      name: "gridCol",
      type: "GridWidget",
      parent: "grpbxWarehouseBinSetup",
      gridCellsOrder:
        "txtWhs,txtBin,txtDescription,txtPeanutType,txtPeanutVariety,txtSeedGen,txtSegType,txtEdibleOil,txtOleic,txtOrganic,txtGovCapacity,txtStartupTons,txtObligationAssignment,txtAllowReceipts,txtAllowTransfers",
      ColSpan: "5",
      Pagination: false,
      ColSpanForLargeDesktop: "5",
      HasLabel: false,
      Cols: "10",
      ColsForLargeDesktop: "5",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridCol_PROPERTIES

      // END_USER_CODE-USER_gridCol_PROPERTIES
    },
    txtAllowReceipts: {
      name: "txtAllowReceipts",
      type: "TextBoxWidget",
      colName: "txtColAllowReceipts",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAllowReceipts_PROPERTIES

      // END_USER_CODE-USER_txtAllowReceipts_PROPERTIES
    },
    txtAllowTransfers: {
      name: "txtAllowTransfers",
      type: "TextBoxWidget",
      colName: "txtColAllowTransfers",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAllowTransfers_PROPERTIES

      // END_USER_CODE-USER_txtAllowTransfers_PROPERTIES
    },
    txtBin: {
      name: "txtBin",
      type: "TextBoxWidget",
      colName: "txtColBin",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBin_PROPERTIES

      // END_USER_CODE-USER_txtBin_PROPERTIES
    },
    txtColAllowReceipts: {
      name: "txtColAllowReceipts",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Allow Receipts",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAllowReceipts_PROPERTIES

      // END_USER_CODE-USER_txtColAllowReceipts_PROPERTIES
    },
    txtColAllowTransfers: {
      name: "txtColAllowTransfers",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Allow Transfers",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAllowTransfers_PROPERTIES

      // END_USER_CODE-USER_txtColAllowTransfers_PROPERTIES
    },
    txtColBin: {
      name: "txtColBin",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Bin#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColBin_PROPERTIES

      // END_USER_CODE-USER_txtColBin_PROPERTIES
    },
    txtColDescription: {
      name: "txtColDescription",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Description",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColDescription_PROPERTIES

      // END_USER_CODE-USER_txtColDescription_PROPERTIES
    },
    txtColEdibleOil: {
      name: "txtColEdibleOil",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Edible/Oil",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColEdibleOil_PROPERTIES

      // END_USER_CODE-USER_txtColEdibleOil_PROPERTIES
    },
    txtColGovCapacity: {
      name: "txtColGovCapacity",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Gov Capacity (Tons)",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColGovCapacity_PROPERTIES

      // END_USER_CODE-USER_txtColGovCapacity_PROPERTIES
    },
    txtColObligationAssignment: {
      name: "txtColObligationAssignment",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Obligation Assignment",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColObligationAssignment_PROPERTIES

      // END_USER_CODE-USER_txtColObligationAssignment_PROPERTIES
    },
    txtColOleic: {
      name: "txtColOleic",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColOleic_PROPERTIES

      // END_USER_CODE-USER_txtColOleic_PROPERTIES
    },
    txtColOrganic: {
      name: "txtColOrganic",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Organic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColOrganic_PROPERTIES

      // END_USER_CODE-USER_txtColOrganic_PROPERTIES
    },
    txtColPeanutType: {
      name: "txtColPeanutType",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtColPeanutType_PROPERTIES
    },
    txtColPeanutVariety: {
      name: "txtColPeanutVariety",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_txtColPeanutVariety_PROPERTIES
    },
    txtColSeedGen: {
      name: "txtColSeedGen",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Seed Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSeedGen_PROPERTIES

      // END_USER_CODE-USER_txtColSeedGen_PROPERTIES
    },
    txtColSegType: {
      name: "txtColSegType",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Seg Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSegType_PROPERTIES

      // END_USER_CODE-USER_txtColSegType_PROPERTIES
    },
    txtColStartupTons: {
      name: "txtColStartupTons",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Startup Tons",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColStartupTons_PROPERTIES

      // END_USER_CODE-USER_txtColStartupTons_PROPERTIES
    },
    txtColWhs: {
      name: "txtColWhs",
      type: "GridColumnWidget",
      parent: "gridCol",
      Label: "Whs#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColWhs_PROPERTIES

      // END_USER_CODE-USER_txtColWhs_PROPERTIES
    },
    txtDescription: {
      name: "txtDescription",
      type: "TextBoxWidget",
      colName: "txtColDescription",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDescription_PROPERTIES

      // END_USER_CODE-USER_txtDescription_PROPERTIES
    },
    txtEdibleOil: {
      name: "txtEdibleOil",
      type: "TextBoxWidget",
      colName: "txtColEdibleOil",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEdibleOil_PROPERTIES

      // END_USER_CODE-USER_txtEdibleOil_PROPERTIES
    },
    txtGovCapacity: {
      name: "txtGovCapacity",
      type: "TextBoxWidget",
      colName: "txtColGovCapacity",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGovCapacity_PROPERTIES

      // END_USER_CODE-USER_txtGovCapacity_PROPERTIES
    },
    txtObligationAssignment: {
      name: "txtObligationAssignment",
      type: "TextBoxWidget",
      colName: "txtColObligationAssignment",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtObligationAssignment_PROPERTIES

      // END_USER_CODE-USER_txtObligationAssignment_PROPERTIES
    },
    txtOleic: {
      name: "txtOleic",
      type: "TextBoxWidget",
      colName: "txtColOleic",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOleic_PROPERTIES

      // END_USER_CODE-USER_txtOleic_PROPERTIES
    },
    txtOrganic: {
      name: "txtOrganic",
      type: "TextBoxWidget",
      colName: "txtColOrganic",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOrganic_PROPERTIES

      // END_USER_CODE-USER_txtOrganic_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      colName: "txtColPeanutType",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtPeanutVariety: {
      name: "txtPeanutVariety",
      type: "TextBoxWidget",
      colName: "txtColPeanutVariety",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_txtPeanutVariety_PROPERTIES
    },
    txtSeedGen: {
      name: "txtSeedGen",
      type: "TextBoxWidget",
      colName: "txtColSeedGen",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedGen_PROPERTIES

      // END_USER_CODE-USER_txtSeedGen_PROPERTIES
    },
    txtSegType: {
      name: "txtSegType",
      type: "TextBoxWidget",
      colName: "txtColSegType",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegType_PROPERTIES

      // END_USER_CODE-USER_txtSegType_PROPERTIES
    },
    txtStartupTons: {
      name: "txtStartupTons",
      type: "TextBoxWidget",
      colName: "txtColStartupTons",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStartupTons_PROPERTIES

      // END_USER_CODE-USER_txtStartupTons_PROPERTIES
    },
    txtWhs: {
      name: "txtWhs",
      type: "TextBoxWidget",
      colName: "txtColWhs",
      parent: "gridCol",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhs_PROPERTIES

      // END_USER_CODE-USER_txtWhs_PROPERTIES
    },
    grpbxWarehouseBinSetup: {
      name: "grpbxWarehouseBinSetup",
      type: "GroupBoxWidget",
      parent: "WarehouseBinSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxWarehouseBinSetup_PROPERTIES

      // END_USER_CODE-USER_grpbxWarehouseBinSetup_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceMasterManagement#WarehouseBinProfile":{}
      },
      REVERSE: {
        "SystemMaintenanceMasterManagement#WarehouseBinProfile":{}
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
    btnAdd: {
      DEFAULT: ["SystemMaintenanceMasterManagement#WarehouseBinProfile#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);


  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    Form_Load()
   
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(()=>{
    let Status = getData(thisObj, 'warehosesetupLoad')
    if(Status ==true){
      let ddBuyingPointvalue = getValue(thisObj,'ddBuyingPoint')
      bFillBinsList(ddBuyingPointvalue)
    }
  }, [getData(thisObj, 'warehosesetupLoad')]);
  const Form_Load = async () => {
   await bFillBuyingPointList()
  }
  const EnableDisableControls = async (cmbBuyingPoint,vsfWhsBin) => {
    if(cmbBuyingPoint =='' && cmbBuyingPoint ==undefined ){
      disable(thisObj,'btnAdd')
      disable(thisObj,'btnEdit')
      disable(thisObj,'btnDelete')
    }else{
      enable(thisObj,'btnAdd')
      if(vsfWhsBin.length>0){
        enable(thisObj,'btnEdit')
        enable(thisObj,'btnDelete')
      }else{
        disable(thisObj,'btnEdit')
        disable(thisObj,'btnDelete') 
        
      }

    }
  } 
 
  const bFillBuyingPointList = async () => {
    let js = []
    let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0030', null, null, null, null)
    let data = response
    for (var i = 0; i < data.length; i++) {
      let obj = {
        key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim()
      }
      js.push(obj)
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddBuyingPoint: {
          ...state["ddBuyingPoint"],
          valueList: js
        }
      }
    })
    setValue(thisObj,'ddBuyingPoint',js.at(0).key)
    bFillBinsList(js.at(0).key)
  }
  const bFillBinsList = async (ddBuyingPointvalue) => {
    setLoading(true)
    let data = await SettlementService.RetrieveWhouseBinInventoryDetails(ddBuyingPointvalue, null, null, null, null)
    let griddata=[]
    let obj = {};
    if (data != undefined && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      obj.txtAllowReceipts=data[i].allowRcptInd
      obj.txtAllowTransfers=data[i].xferAllowInd
      obj.txtGovCapacity=data[i].newCapacityTons == '0.0000'?(data[i].newCapacityTons).slice(1, 6):data[i].newCapacityTons !== '' && data[i].newCapacityTons !==null ?parseFloat(data[i].newCapacityTons).toFixed(4):''
      obj.txtObligationAssignment=data[i].whsRcptInd,
      obj.txtStartupTons=data[i].newStartupTons == '0.0000'?(data[i].newStartupTons).slice(1, 6):data[i].newStartupTons !== '' && data[i].newStartupTons !==null ?parseFloat(data[i].newStartupTons).toFixed(4):''
      obj.newCapacityTons=data[i].newCapacityTons
      obj.newStartupTons=data[i].newStartupTons
      obj.txtWhs=data[i].whouseNum,
      obj.txtBin=data[i].binNum,
      obj.txtDescription=data[i].whouseBinId,
      obj.txtPeanutType=data[i].pnutTypeId,
      obj.txtPeanutVariety=data[i].pnutVarietyId,
      obj.txtSeedGen=data[i].seedGen,
      obj.txtSegType=data[i].segType,
      obj.txtEdibleOil=data[i].edibleOilInd,
      obj.txtOleic=data[i].oleicInd,
      obj.txtOrganic=data[i].organicInd,
      obj.addDateTime=moment(String(data[i].addDateTime)).format('MM/DD/YYYY hh:mm A'),
      obj.chgDateTime=moment(String(data[i].chgDateTime)).format('MM/DD/YYYY hh:mm A'),
      obj.addedby=data[i].addUser+ " " + moment(String(data[i].addDateTime)).format('MM/DD/YYYY'),
      obj.changedby=data[i].chgUser+ " " + moment(String(data[i].chgDateTime)).format('MM/DD/YYYY')
      griddata.push(obj)
      obj = {}
      }
      setValue(thisObj, "gridCol", griddata);
    }else{
      setValue(thisObj, "gridCol", griddata);
    }
    EnableDisableControls(ddBuyingPointvalue,griddata)
    setLoading(false)
    }
  const onddBuyingPointChange = async() => {
    try {
      let ddBuyingPointvalue = getValue(thisObj,'ddBuyingPoint')
      bFillBinsList(ddBuyingPointvalue)
      
    }
    catch (err) {
    }

  };
  thisObj.onddBuyingPointChange = onddBuyingPointChange;

  const onbtnExportToExcelClick = () => {
    try {

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, "gridCol")
      let ddBuyingPoint= getValue(thisObj,'ddBuyingPoint')
      var fileName = "_Whs_Bin_List"
      const arrayData = []
      if (excelData.length > 1)
        for (var i = 0; i < excelData.length; i++) {
          var obj = {
            "Whs#": ExcelNumericDataConvert(excelData[i].txtWhs),
            "Bin#": ExcelNumericDataConvert(excelData[i].txtBin),
            "Description": excelData[i].txtDescription,
            "Peanut Type": excelData[i].txtPeanutType,
            "Peanut Variety": excelData[i].txtPeanutVariety,
            "Seed Gen": excelData[i].txtSeedGen,
            "Seg Type":ExcelNumericDataConvert(excelData[i].txtSegType),
            "Edible/Oil": excelData[i].txtEdibleOil,
            "Oleic": excelData[i].txtOleic,
            "Organic":excelData[i].txtOrganic,
            "Gov Capacity (Tons)":ExcelNumericDataConvert(excelData[i].newCapacityTons,[],[4]),
            "Startup Tons":ExcelNumericDataConvert(excelData[i].newStartupTons,[],[4]),
            "Obligation Assignment":excelData[i].txtObligationAssignment,
            "Allow Receipts":excelData[i].txtAllowReceipts,
            "Allow Transfers":excelData[i].txtAllowTransfers,
           
          }
          arrayData.push(obj);

        } else {
        var obj = {
          "Whs#": '',
          "Bin#": '',
          "Description": '',
          "Peanut Type": '',
          "Peanut Variety": '',
          "Seed Gen": '',
          "Seg Type": '',
          "Edible/Oil": '',
          "Oleic": '',
          "Organic":'',
          "Gov Capacity (Tons)":'',
          "Startup Tons":'',
          "Obligation Assignment":'',
          "Allow Receipts":'',
          "Allow Transfers":''
        }

        arrayData.push(obj);
      }

      const ws = XLSX.utils.json_to_sheet(arrayData)
      var wscols = [
        {width:10},
        {width:10},
        {width:30},
        {width:25},
        {width:15},
        {width:10},
        {width:10},
        {width:15},
        {width:15},
        {width:10},
        {width:10},
        {width:10},
        {width:15},
        {width:15},
        {width:10},
        {width:15},
        {width:15},
    ];
    ws['!cols'] = wscols;
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, ddBuyingPoint+''+fileName+fileExtension);
    } catch (err) {

    }

  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;
  
  const onbtnPrintClick = () => {
    var data1 = getValue(thisObj, "gridCol")

    if (data1.length != 0) {
      var res = []
      for (var i = 0; i < data1.length; i++) {
        res.push([
          data1[i].txtWhs,
          data1[i].txtBin,
          data1[i].txtDescription,
          data1[i].txtPeanutType,
          data1[i].txtPeanutVariety,
          data1[i].txtSeedGen,
          data1[i].txtSegType,
          data1[i].txtEdibleOil,
          data1[i].txtOleic,
          data1[i].txtOrganic,
          data1[i].txtGovCapacity,
          data1[i].txtStartupTons,
          data1[i].txtObligationAssignment,
          data1[i].txtAllowReceipts,
          data1[i].txtAllowTransfers,
        ])
        
      }
     
      var obj = new jsPDF();
      const addFooters = obj => {
        const pageCount = obj.internal.getNumberOfPages()
        let sDocName = `${cropYearFromLS} Checks`,
        SelectedddBuyingPoint = thisObj.values['ddBuyingPoint'],
        ddBuyingPointList = thisObj.state['ddBuyingPoint'].valueList,
        SelectedBuyingPointlist = ddBuyingPointList.filter(elem => elem.key === SelectedddBuyingPoint);
          sDocName = `${cropYearFromLS} Warehouses and Bins for Location ${SelectedBuyingPointlist[0].description}`
        obj.setFont('helvetica', 'italic')
        obj.setFontSize(8)
        for (var i = 1; i <= pageCount; i++) {
          obj.setPage(i)
          obj.text(sDocName, 12, 285);
          obj.text(190, 285, String(i))
        }
      }
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 4, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [
          [
            "Whs#", "Bin#", "Description", "Peanut Type", "Peanut Variety", "Seed Gen", "Seg Type", "Edible/Oil", "Oleic","Organic","Gov Capacity (Tons)","Startup Tons","Obligation Assignment","Allow Receipts","Allow Transfers"
          ]
        ]
      });
      addFooters(obj)
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:Accdist.cmdPrint_Click")
        }
      }
      else {
        alert("Routine:Accdist.cmdPrint_Click")
      }
    } else {
      var res = []
      var obj = new jsPDF();
      let sDocName = `${cropYearFromLS} Checks`
      const addFooters = obj => {
        const pageCount = obj.internal.getNumberOfPages()
        obj.setFont('helvetica', 'italic')
        obj.setFontSize(8)
        for (var i = 1; i <= pageCount; i++) {
          obj.setPage(i)
          obj.text(sDocName, 12, 285);
          obj.text(190, 285, String(i))
        }
      }
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 4, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [
          [
            "Whs#", "Bin#", "Description", "Peanut Type", "Peanut Variety", "Seed Gen", "Seg Type", "Edible/Oil", "Oleic","Organic","Gov Capacity (Tons)","Startup Tons","Obligation Assignment","Allow Receipts","Allow Transfers"
          ]
        ]
      });
      addFooters(obj)
      // obj.setFontSize(7)
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:Accdist.cmdPrint_Click")
        }
      }
      else {
        alert("Routine:Accdist.cmdPrint_Click")
      }
    }
  } 
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const onbtnAddClick = () => {
    let ddBuyingPointList = thisObj.state['ddBuyingPoint'].valueList; 
    let SelectedddBuyingPoint = thisObj.values['ddBuyingPoint'];
    let SelectedBuyingPointlist = ddBuyingPointList.filter(elem => elem.key === SelectedddBuyingPoint);
    let frmWarehouseBinProfile={}
    frmWarehouseBinProfile.cmdOk="Add"
    frmWarehouseBinProfile.lblBuyingPoint=SelectedddBuyingPoint
    frmWarehouseBinProfile.txtWarehouseNumberEnabled=true
    frmWarehouseBinProfile.txtBinNumberEnabled=true
    frmWarehouseBinProfile.txtWarehouseNumber=''
    frmWarehouseBinProfile.txtBinNumber=''
    frmWarehouseBinProfile.cmbBuyingPoint=SelectedBuyingPointlist[0].description
    setData(thisObj,'frmWarehouseBinProfileData',frmWarehouseBinProfile)
    setData(thisObj,'warehosesetupLoad',false)
    goTo(thisObj, "SystemMaintenanceMasterManagement#WarehouseBinProfile#DEFAULT#true#Click")

  } 
  thisObj.onbtnAddClick=onbtnAddClick;

  const onbtnEditClick = () => {
    try {
      let row = getSelectedRowNumber(thisObj, "gridCol")
      let ddBuyingPointList = thisObj.state['ddBuyingPoint'].valueList; 
      let SelectedddBuyingPoint = thisObj.values['ddBuyingPoint'];
      let SelectedBuyingPointlist = ddBuyingPointList.filter(elem => elem.key === SelectedddBuyingPoint); 
      setData(thisObj,'warehosesetupLoad',false)
      if (row !== '' && row !== null && row !== undefined) {
          let obj = thisObj.state.gridCol.selected;
          obj.cmdOk = "Update"
          obj.lblBuyingPoint=SelectedddBuyingPoint
          obj.txtWarehouseNumberEnabled=true
          obj.txtBinNumberEnabled=true
          obj.txtWarehouseNumber=obj[0].txtWhs
          obj.txtBinNumber=obj[0].txtBin
          obj.cmbBuyingPoint=SelectedBuyingPointlist[0].description
          setData(thisObj,'frmWarehouseBinProfileData',obj)
          goTo(thisObj, "SystemMaintenanceMasterManagement#WarehouseBinProfile#DEFAULT#true#Click")
      } else {
        alert("Select only one warehouse and bin to be updated.");
      }
    }
    catch (err) {
    }
  } 
  thisObj.onbtnEditClick=onbtnEditClick

  const onbtnDeleteClick = async() => {
    try {
      let row = getSelectedRowNumber(thisObj, "gridCol") 
      let cmbBuyingPoint=getValue(thisObj,'ddBuyingPoint')
      let selectedRows = thisObj.state.gridCol.selected;
      if (row !== '' && row !== null && row !== undefined) {
          if (confirm(`Are you sure you wish to delete this Whouse Bin Inventory ${selectedRows[0].txtWhs} -${selectedRows[0].txtBin} -${selectedRows[0].txtDescription}`) == true) {
            let response = await SystemMaintenanceMasterManagementService.RetrieveWarehouseBinInventoryCheck(cmbBuyingPoint,selectedRows[0].txtWhs,selectedRows[0].txtBin)
            let indicator =''
            if(response.length>0){
             indicator=response[0].indicator
            }else{
              indicator=''
            }
            if(indicator !=='T'){
              alert("Either Physical Inventory or Obligated Inventory is present for this Warehouse and Bin. This Warehouse and Bin cannot be deleted. ");
            }else if(indicator =='T'){
              let remove = await SystemMaintenanceMasterManagementService.RemoveWarehouseBinInventory(cmbBuyingPoint,selectedRows[0].txtWhs,selectedRows[0].txtBin)
              if(remove.status ==200){
                   bFillBinsList(cmbBuyingPoint)
                 }
            }
           
          }
       
      } else {
        alert("No warehosue and bin has been selected.");
      }
    }
    catch (err) {
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  const ongridColSelect = (rowSelected) => {
    let data3 = rowSelected;
    setData(thisObj, 'rowSelected', rowSelected.selectedRows);
    if (data3.selectedRows) {
      data3 = data3.selectedRows[0];
    }

  };
  thisObj.ongridColSelect = ongridColSelect;

  const onbtnCopyClick = () => {
    let row = getSelectedRowNumber(thisObj, "gridCol")
    let ddBuyingPointList = thisObj.state['ddBuyingPoint'].valueList; 
    let SelectedddBuyingPoint = thisObj.values['ddBuyingPoint'];
    let SelectedBuyingPointlist = ddBuyingPointList.filter(elem => elem.key === SelectedddBuyingPoint); 
    if (row !== '' && row !== null && row !== undefined) {
        let obj = thisObj.state.gridCol.selected;
        obj.cmdOk = "Update"
        obj.lblBuyingPoint=SelectedddBuyingPoint
        obj.txtWarehouseNumber=obj[0].txtWhs
        obj.txtBinNumber=obj[0].txtBin
        obj.cmbBuyingPoint=SelectedBuyingPointlist[0].description
        setData(thisObj,'frmWarehouseBincopydata',obj)
        goTo(thisObj, "SystemMaintenanceMasterManagement#WarehouseBinCopy#DEFAULT#true#Click")
    } else {
      alert("Select only one warehouse and bin to be copied.");
    }
    
  } 
  thisObj.onbtnCopyClick=onbtnCopyClick
 
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        setValues
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        thisObj.setValues = setValues = setValues;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
            <Loading loading={loading}></Loading>
              {/* START_USER_CODE-USER_BEFORE_WarehouseBinSetup*/}

              {/* END_USER_CODE-USER_BEFORE_WarehouseBinSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxWarehouseBinSetup*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxWarehouseBinSetup*/}

              <GroupBoxWidget
                conf={state.grpbxWarehouseBinSetup}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_gridCol*/}

                {/* END_USER_CODE-USER_BEFORE_gridCol*/}

                <GridWidget
                  conf={state.gridCol}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridCol}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridCol*/}

                {/* END_USER_CODE-USER_AFTER_gridCol*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrActions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                  <ButtonWidget
                    conf={state.btnPrint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                  {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                  {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  <ButtonWidget
                    conf={state.btnExportToExcel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                  {/* START_USER_CODE-USER_BEFORE_btn1*/}

                  {/* END_USER_CODE-USER_BEFORE_btn1*/}

                  {/* <ButtonWidget
                    conf={state.btn1}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget> */}
                  {/* START_USER_CODE-USER_AFTER_btn1*/}

                  {/* END_USER_CODE-USER_AFTER_btn1*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCopy*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCopy*/}

                  <ButtonWidget
                    conf={state.btnCopy}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCopy*/}

                  {/* END_USER_CODE-USER_AFTER_btnCopy*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                  <ButtonWidget
                    conf={state.btnClose}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClose*/}

                  {/* END_USER_CODE-USER_AFTER_btnClose*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxWarehouseBinSetup*/}

              {/* END_USER_CODE-USER_AFTER_grpbxWarehouseBinSetup*/}

              {/* START_USER_CODE-USER_AFTER_WarehouseBinSetup*/}

              {/* END_USER_CODE-USER_AFTER_WarehouseBinSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_WarehouseBinSetup);
