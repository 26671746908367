import React,{useState} from "react";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import IframeComm from "react-iframe-comm";
import {getPanelClasses} from "../ParentWidgetFunc";

function IframeWidget(props) {
    let conf = props.conf;
    let screenConf = props.screenConf;
    const [state,setState] = useState({ open: !conf.defaultCollapsed });
    const toggleClasses = ["pr-1", "fa"];    
    const attributes = {
      src: conf.src,
      width:conf.Width,
      height:conf.Height
    };

    state.open
      ? toggleClasses.push("fa-caret-down")
      : toggleClasses.push("fa-caret-right");

    return (
      <div className={getPanelClasses(conf,screenConf).join(" ")}>
        <Card>
          <Card.Header data-testid={conf.name}>
            <Button
              className="text-decoration-none p-0"
              variant="link"
              onClick={() => setState(current => {  
                              return {
                                ...current,
                                open: !state.open
                              };
                            })}
              aria-controls={conf.name + "Collapse"}
              aria-expanded={state.open}
            >
              <i className={toggleClasses.join(" ")} aria-hidden="true"></i>
              {conf.HasLabel ? conf.Label : ""}
            </Button>
          </Card.Header>
          <Collapse in={state.open}>
            <Card.Body>
              <IframeComm attributes={attributes} />
            </Card.Body>
          </Collapse>
        </Card>
      </div>
    );
  
}

export default IframeWidget;
