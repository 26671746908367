/* eslint-disable*/
import React from "react";
import AccountPayment_PrintChecks from "./PrintChecks";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("PrintChecks Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_PrintChecks />);
    });
  });
  afterEach(cleanup);
  test("is PrintChecks Loads Successfully", () => {
    expect(screen.getByText("PrintChecks")).toBeInTheDocument;
  });
  test("Custom Test Cases for PrintChecks", () => {
    // START_USER_CODE-USER_PrintChecks_Custom_Test_Case
    // END_USER_CODE-USER_PrintChecks_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_PrintChecks />);
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("AccountPayment:PrintChecks_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("gridBatchedReadyToPays_btncolNav(Grid Widget) Test Cases", async () => {
    let gridBatchedReadyToPays_btncolNav = screen.getByTestId(
      "gridBatchedReadyToPays"
    );
    let gridBatchedReadyToPays_btncolNavbtn =
      gridBatchedReadyToPays_btncolNav.nextElementSibling.firstElementChild;
    gridBatchedReadyToPays_btncolNav =
      gridBatchedReadyToPays_btncolNav.parentElement.parentElement
        .parentElement;
    expect(gridBatchedReadyToPays_btncolNav.tagName).toBe("DIV");
    expect(gridBatchedReadyToPays_btncolNav.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:PrintChecks_gridBatchedReadyToPays")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for btncolNav", () => {
    // START_USER_CODE-USER_btncolNav_TEST
    // END_USER_CODE-USER_btncolNav_TEST
  });
  test("btnPrintChecks(Button Widget) Test Cases", async () => {
    const btnPrintChecks = screen.getByTestId("btnPrintChecks");
    expect(btnPrintChecks).toBeInTheDocument;
    expect(btnPrintChecks.textContent).toEqual(
      t("AccountPayment:PrintChecks_btnPrintChecks")
    );
  });
  test("Custom Test Cases for btnPrintChecks", () => {
    // START_USER_CODE-USER_btnPrintChecks_TEST
    // END_USER_CODE-USER_btnPrintChecks_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("AccountPayment:PrintChecks_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("AccountPayment:PrintChecks_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridBatchedReadyToPays(Grid Widget) Test Cases", async () => {
    let gridBatchedReadyToPays = screen.getByTestId("gridBatchedReadyToPays");
    let gridBatchedReadyToPaysbtn =
      gridBatchedReadyToPays.nextElementSibling.firstElementChild;
    gridBatchedReadyToPays =
      gridBatchedReadyToPays.parentElement.parentElement.parentElement;
    expect(gridBatchedReadyToPays.tagName).toBe("DIV");
    expect(gridBatchedReadyToPays.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("AccountPayment:PrintChecks_gridBatchedReadyToPays")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridBatchedReadyToPays", () => {
    // START_USER_CODE-USER_gridBatchedReadyToPays_TEST
    // END_USER_CODE-USER_gridBatchedReadyToPays_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxCheckDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxCheckDetails = screen.getByTestId("grpbxCheckDetails");
    expect(grpbxCheckDetails.tagName).toBe("BUTTON");
    expect(grpbxCheckDetails.type).toBe("button");
    expect(grpbxCheckDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCheckDetails", () => {
    // START_USER_CODE-USER_grpbxCheckDetails_TEST
    // END_USER_CODE-USER_grpbxCheckDetails_TEST
  });
  test("lblBatchedReadyToPays(Label Widget) Test Cases", async () => {
    const lblBatchedReadyToPays = screen.getByTestId("lblBatchedReadyToPays");
    expect(lblBatchedReadyToPays.tagName).toBe("LABEL");
    expect(lblBatchedReadyToPays.classList).toContain("form-label");
    expect(lblBatchedReadyToPays.textContent).toEqual(
      t("AccountPayment:PrintChecks_lblBatchedReadyToPays")
    );
  });
  test("Custom Test Cases for lblBatchedReadyToPays", () => {
    // START_USER_CODE-USER_lblBatchedReadyToPays_TEST
    // END_USER_CODE-USER_lblBatchedReadyToPays_TEST
  });
  test("lblChecksSelected(Label Widget) Test Cases", async () => {
    const lblChecksSelected = screen.getByTestId("lblChecksSelected");
    expect(lblChecksSelected.tagName).toBe("LABEL");
    expect(lblChecksSelected.classList).toContain("form-label");
    expect(lblChecksSelected.textContent).toEqual(
      t("AccountPayment:PrintChecks_lblChecksSelected")
    );
  });
  test("Custom Test Cases for lblChecksSelected", () => {
    // START_USER_CODE-USER_lblChecksSelected_TEST
    // END_USER_CODE-USER_lblChecksSelected_TEST
  });
  test("lblChecksSelectedValue(Label Widget) Test Cases", async () => {
    const lblChecksSelectedValue = screen.getByTestId("lblChecksSelectedValue");
    expect(lblChecksSelectedValue.tagName).toBe("LABEL");
    expect(lblChecksSelectedValue.classList).toContain("form-label");
    expect(lblChecksSelectedValue.textContent).toEqual(
      t("AccountPayment:PrintChecks_lblChecksSelectedValue")
    );
  });
  test("Custom Test Cases for lblChecksSelectedValue", () => {
    // START_USER_CODE-USER_lblChecksSelectedValue_TEST
    // END_USER_CODE-USER_lblChecksSelectedValue_TEST
  });
  test("gridBatchedReadyToPays_txtcolProceeds(Grid Widget) Test Cases", async () => {
    let gridBatchedReadyToPays_txtcolProceeds = screen.getByTestId(
      "gridBatchedReadyToPays"
    );
    let gridBatchedReadyToPays_txtcolProceedsbtn =
      gridBatchedReadyToPays_txtcolProceeds.nextElementSibling
        .firstElementChild;
    gridBatchedReadyToPays_txtcolProceeds =
      gridBatchedReadyToPays_txtcolProceeds.parentElement.parentElement
        .parentElement;
    expect(gridBatchedReadyToPays_txtcolProceeds.tagName).toBe("DIV");
    expect(gridBatchedReadyToPays_txtcolProceeds.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:PrintChecks_gridBatchedReadyToPays")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProceeds", () => {
    // START_USER_CODE-USER_txtcolProceeds_TEST
    // END_USER_CODE-USER_txtcolProceeds_TEST
  });
  test("gridBatchedReadyToPays_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridBatchedReadyToPays_txtcolVendor = screen.getByTestId(
      "gridBatchedReadyToPays"
    );
    let gridBatchedReadyToPays_txtcolVendorbtn =
      gridBatchedReadyToPays_txtcolVendor.nextElementSibling.firstElementChild;
    gridBatchedReadyToPays_txtcolVendor =
      gridBatchedReadyToPays_txtcolVendor.parentElement.parentElement
        .parentElement;
    expect(gridBatchedReadyToPays_txtcolVendor.tagName).toBe("DIV");
    expect(gridBatchedReadyToPays_txtcolVendor.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("AccountPayment:PrintChecks_gridBatchedReadyToPays")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
  test("txtEndingCheckNumber(Textbox Widget) Test Cases", async () => {
    const txtEndingCheckNumber = screen.getByTestId("txtEndingCheckNumber");
    expect(txtEndingCheckNumber.tagName).toBe("INPUT");
    expect(txtEndingCheckNumber.type).toBe("text");
    expect(txtEndingCheckNumber.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtEndingCheckNumber, "1");
    });
  });
  test("Custom Test Cases for txtEndingCheckNumber", () => {
    // START_USER_CODE-USER_txtEndingCheckNumber_TEST
    // END_USER_CODE-USER_txtEndingCheckNumber_TEST
  });
  test("txtStartingCheck(Textbox Widget) Test Cases", async () => {
    const txtStartingCheck = screen.getByTestId("txtStartingCheck");
    expect(txtStartingCheck.tagName).toBe("INPUT");
    expect(txtStartingCheck.type).toBe("text");
    expect(txtStartingCheck.classList).toContain("textboxWidgetClass");
    expect(txtStartingCheck.previousElementSibling.textContent).toEqual(
      t("AccountPayment:PrintChecks_txtStartingCheck")
    );
    await act(async () => {
      userEvent.type(txtStartingCheck, "1");
    });
  });
  test("Custom Test Cases for txtStartingCheck", () => {
    // START_USER_CODE-USER_txtStartingCheck_TEST
    // END_USER_CODE-USER_txtStartingCheck_TEST
  });
  test("txtStartingReceivableNumber(Textbox Widget) Test Cases", async () => {
    const txtStartingReceivableNumber = screen.getByTestId(
      "txtStartingReceivableNumber"
    );
    expect(txtStartingReceivableNumber.tagName).toBe("INPUT");
    expect(txtStartingReceivableNumber.type).toBe("text");
    expect(txtStartingReceivableNumber.classList).toContain(
      "textboxWidgetClass"
    );
    await act(async () => {
      userEvent.type(txtStartingReceivableNumber, "1");
    });
  });
  test("Custom Test Cases for txtStartingReceivableNumber", () => {
    // START_USER_CODE-USER_txtStartingReceivableNumber_TEST
    // END_USER_CODE-USER_txtStartingReceivableNumber_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("AccountPayment:PrintChecks_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
});
