/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_VendorMaintenance from "./VendorMaintenance";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("VendorMaintenance Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_VendorMaintenance />
      );
    });
  });
  afterEach(cleanup);
  test("is VendorMaintenance Loads Successfully", () => {
    expect(screen.getByText("VendorMaintenance")).toBeInTheDocument;
  });
  test("Custom Test Cases for VendorMaintenance", () => {
    // START_USER_CODE-USER_VendorMaintenance_Custom_Test_Case
    // END_USER_CODE-USER_VendorMaintenance_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_VendorMaintenance />
      );
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnEdit(Button Widget) Test Cases", async () => {
    const btnEdit = screen.getByTestId("btnEdit");
    expect(btnEdit).toBeInTheDocument;
    expect(btnEdit.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_btnEdit")
    );
  });
  test("Custom Test Cases for btnEdit", () => {
    // START_USER_CODE-USER_btnEdit_TEST
    // END_USER_CODE-USER_btnEdit_TEST
  });
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("btnSave(Button Widget) Test Cases", async () => {
    const btnSave = screen.getByTestId("btnSave");
    expect(btnSave).toBeInTheDocument;
    expect(btnSave.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_btnSave")
    );
  });
  test("Custom Test Cases for btnSave", () => {
    // START_USER_CODE-USER_btnSave_TEST
    // END_USER_CODE-USER_btnSave_TEST
  });
  test("btnScan211Concordia(Button Widget) Test Cases", async () => {
    const btnScan211Concordia = screen.getByTestId(
      "btnScan211Concordia"
    );
    expect(btnScan211Concordia).toBeInTheDocument;
    expect(btnScan211Concordia.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_btnScan211Concordia"
      )
    );
  });
  test("Custom Test Cases for btnScan211Concordia", () => {
    // START_USER_CODE-USER_btnScan211Concordia_TEST
    // END_USER_CODE-USER_btnScan211Concordia_TEST
  });
  test("btnScan211GPC(Button Widget) Test Cases", async () => {
    const btnScan211GPC = screen.getByTestId("btnScan211GPC");
    expect(btnScan211GPC).toBeInTheDocument;
    expect(btnScan211GPC.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_btnScan211GPC"
      )
    );
  });
  test("Custom Test Cases for btnScan211GPC", () => {
    // START_USER_CODE-USER_btnScan211GPC_TEST
    // END_USER_CODE-USER_btnScan211GPC_TEST
  });
  test("btnView211Concordia(Button Widget) Test Cases", async () => {
    const btnView211Concordia = screen.getByTestId(
      "btnView211Concordia"
    );
    expect(btnView211Concordia).toBeInTheDocument;
    expect(btnView211Concordia.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_btnView211Concordia"
      )
    );
  });
  test("Custom Test Cases for btnView211Concordia", () => {
    // START_USER_CODE-USER_btnView211Concordia_TEST
    // END_USER_CODE-USER_btnView211Concordia_TEST
  });
  test("btnView211GPC(Button Widget) Test Cases", async () => {
    const btnView211GPC = screen.getByTestId("btnView211GPC");
    expect(btnView211GPC).toBeInTheDocument;
    expect(btnView211GPC.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_btnView211GPC"
      )
    );
  });
  test("Custom Test Cases for btnView211GPC", () => {
    // START_USER_CODE-USER_btnView211GPC_TEST
    // END_USER_CODE-USER_btnView211GPC_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxContactInformation(GroupBox Widget) Test Cases", async () => {
    const grpbxContactInformation = screen.getByTestId(
      "grpbxContactInformation"
    );
    expect(grpbxContactInformation.tagName).toBe("BUTTON");
    expect(grpbxContactInformation.type).toBe("button");
    expect(grpbxContactInformation.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContactInformation", () => {
    // START_USER_CODE-USER_grpbxContactInformation_TEST
    // END_USER_CODE-USER_grpbxContactInformation_TEST
  });
  test("grpbxContractInformationFromAP(GroupBox Widget) Test Cases", async () => {
    const grpbxContractInformationFromAP = screen.getByTestId(
      "grpbxContractInformationFromAP"
    );
    expect(grpbxContractInformationFromAP.tagName).toBe("BUTTON");
    expect(grpbxContractInformationFromAP.type).toBe("button");
    expect(grpbxContractInformationFromAP.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContractInformationFromAP", () => {
    // START_USER_CODE-USER_grpbxContractInformationFromAP_TEST
    // END_USER_CODE-USER_grpbxContractInformationFromAP_TEST
  });
  test("grpbxLienHolders(GroupBox Widget) Test Cases", async () => {
    const grpbxLienHolders = screen.getByTestId("grpbxLienHolders");
    expect(grpbxLienHolders.tagName).toBe("BUTTON");
    expect(grpbxLienHolders.type).toBe("button");
    expect(grpbxLienHolders.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLienHolders", () => {
    // START_USER_CODE-USER_grpbxLienHolders_TEST
    // END_USER_CODE-USER_grpbxLienHolders_TEST
  });
  test("grpbxMinority(GroupBox Widget) Test Cases", async () => {
    const grpbxMinority = screen.getByTestId("grpbxMinority");
    expect(grpbxMinority.tagName).toBe("BUTTON");
    expect(grpbxMinority.type).toBe("button");
    expect(grpbxMinority.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxMinority", () => {
    // START_USER_CODE-USER_grpbxMinority_TEST
    // END_USER_CODE-USER_grpbxMinority_TEST
  });
  test("grpbxVendorMaintenance(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorMaintenance = screen.getByTestId(
      "grpbxVendorMaintenance"
    );
    expect(grpbxVendorMaintenance.tagName).toBe("BUTTON");
    expect(grpbxVendorMaintenance.type).toBe("button");
    expect(grpbxVendorMaintenance.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorMaintenance", () => {
    // START_USER_CODE-USER_grpbxVendorMaintenance_TEST
    // END_USER_CODE-USER_grpbxVendorMaintenance_TEST
  });
  test("grpbxVeteran(GroupBox Widget) Test Cases", async () => {
    const grpbxVeteran = screen.getByTestId("grpbxVeteran");
    expect(grpbxVeteran.tagName).toBe("BUTTON");
    expect(grpbxVeteran.type).toBe("button");
    expect(grpbxVeteran.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVeteran", () => {
    // START_USER_CODE-USER_grpbxVeteran_TEST
    // END_USER_CODE-USER_grpbxVeteran_TEST
  });
  test("grpbxWomen(GroupBox Widget) Test Cases", async () => {
    const grpbxWomen = screen.getByTestId("grpbxWomen");
    expect(grpbxWomen.tagName).toBe("BUTTON");
    expect(grpbxWomen.type).toBe("button");
    expect(grpbxWomen.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxWomen", () => {
    // START_USER_CODE-USER_grpbxWomen_TEST
    // END_USER_CODE-USER_grpbxWomen_TEST
  });
  test("lblContactInfo(Label Widget) Test Cases", async () => {
    const lblContactInfo = screen.getByTestId("lblContactInfo");
    expect(lblContactInfo.tagName).toBe("LABEL");
    expect(lblContactInfo.classList).toContain("form-label");
    expect(lblContactInfo.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_lblContactInfo")
    );
  });
  test("Custom Test Cases for lblContactInfo", () => {
    // START_USER_CODE-USER_lblContactInfo_TEST
    // END_USER_CODE-USER_lblContactInfo_TEST
  });
  test("lblContactInformationFromAP(Label Widget) Test Cases", async () => {
    const lblContactInformationFromAP = screen.getByTestId(
      "lblContactInformationFromAP"
    );
    expect(lblContactInformationFromAP.tagName).toBe("LABEL");
    expect(lblContactInformationFromAP.classList).toContain("form-label");
    expect(lblContactInformationFromAP.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_lblContactInformationFromAP"
      )
    );
  });
  test("Custom Test Cases for lblContactInformationFromAP", () => {
    // START_USER_CODE-USER_lblContactInformationFromAP_TEST
    // END_USER_CODE-USER_lblContactInformationFromAP_TEST
  });
  test("lblLienHolders(Label Widget) Test Cases", async () => {
    const lblLienHolders = screen.getByTestId("lblLienHolders");
    expect(lblLienHolders.tagName).toBe("LABEL");
    expect(lblLienHolders.classList).toContain("form-label");
    expect(lblLienHolders.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_lblLienHolders")
    );
  });
  test("Custom Test Cases for lblLienHolders", () => {
    // START_USER_CODE-USER_lblLienHolders_TEST
    // END_USER_CODE-USER_lblLienHolders_TEST
  });
  test("lblMinority(Label Widget) Test Cases", async () => {
    const lblMinority = screen.getByTestId("lblMinority");
    expect(lblMinority.tagName).toBe("LABEL");
    expect(lblMinority.classList).toContain("form-label");
    expect(lblMinority.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_lblMinority")
    );
  });
  test("Custom Test Cases for lblMinority", () => {
    // START_USER_CODE-USER_lblMinority_TEST
    // END_USER_CODE-USER_lblMinority_TEST
  });
  test("lblMustBeAUSCitizenM(Label Widget) Test Cases", async () => {
    const lblMustBeAUSCitizenM = screen.getByTestId(
      "lblMustBeAUSCitizenM"
    );
    expect(lblMustBeAUSCitizenM.tagName).toBe("LABEL");
    expect(lblMustBeAUSCitizenM.classList).toContain("form-label");
    expect(lblMustBeAUSCitizenM.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_lblMustBeAUSCitizenM"
      )
    );
  });
  test("Custom Test Cases for lblMustBeAUSCitizenM", () => {
    // START_USER_CODE-USER_lblMustBeAUSCitizenM_TEST
    // END_USER_CODE-USER_lblMustBeAUSCitizenM_TEST
  });
  test("lblMustBeAUSCitizenV(Label Widget) Test Cases", async () => {
    const lblMustBeAUSCitizenV = screen.getByTestId(
      "lblMustBeAUSCitizenV"
    );
    expect(lblMustBeAUSCitizenV.tagName).toBe("LABEL");
    expect(lblMustBeAUSCitizenV.classList).toContain("form-label");
    expect(lblMustBeAUSCitizenV.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_lblMustBeAUSCitizenV"
      )
    );
  });
  test("Custom Test Cases for lblMustBeAUSCitizenV", () => {
    // START_USER_CODE-USER_lblMustBeAUSCitizenV_TEST
    // END_USER_CODE-USER_lblMustBeAUSCitizenV_TEST
  });
  test("lblMustBeAUSCitizenW(Label Widget) Test Cases", async () => {
    const lblMustBeAUSCitizenW = screen.getByTestId(
      "lblMustBeAUSCitizenW"
    );
    expect(lblMustBeAUSCitizenW.tagName).toBe("LABEL");
    expect(lblMustBeAUSCitizenW.classList).toContain("form-label");
    expect(lblMustBeAUSCitizenW.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_lblMustBeAUSCitizenW"
      )
    );
  });
  test("Custom Test Cases for lblMustBeAUSCitizenW", () => {
    // START_USER_CODE-USER_lblMustBeAUSCitizenW_TEST
    // END_USER_CODE-USER_lblMustBeAUSCitizenW_TEST
  });
  test("lblOMCMinority(Label Widget) Test Cases", async () => {
    const lblOMCMinority = screen.getByTestId("lblOMCMinority");
    expect(lblOMCMinority.tagName).toBe("LABEL");
    expect(lblOMCMinority.classList).toContain("form-label");
    expect(lblOMCMinority.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_lblOMCMinority"
      )
    );
  });
  test("Custom Test Cases for lblOMCMinority", () => {
    // START_USER_CODE-USER_lblOMCMinority_TEST
    // END_USER_CODE-USER_lblOMCMinority_TEST
  });
  test("lblOMCVetaran(Label Widget) Test Cases", async () => {
    const lblOMCVetaran = screen.getByTestId("lblOMCVetaran");
    expect(lblOMCVetaran.tagName).toBe("LABEL");
    expect(lblOMCVetaran.classList).toContain("form-label");
    expect(lblOMCVetaran.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_lblOMCVetaran"
      )
    );
  });
  test("Custom Test Cases for lblOMCVetaran", () => {
    // START_USER_CODE-USER_lblOMCVetaran_TEST
    // END_USER_CODE-USER_lblOMCVetaran_TEST
  });
  test("lblOMCWomen(Label Widget) Test Cases", async () => {
    const lblOMCWomen = screen.getByTestId("lblOMCWomen");
    expect(lblOMCWomen.tagName).toBe("LABEL");
    expect(lblOMCWomen.classList).toContain("form-label");
    expect(lblOMCWomen.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_lblOMCWomen")
    );
  });
  test("Custom Test Cases for lblOMCWomen", () => {
    // START_USER_CODE-USER_lblOMCWomen_TEST
    // END_USER_CODE-USER_lblOMCWomen_TEST
  });
  test("lblVeteran(Label Widget) Test Cases", async () => {
    const lblVeteran = screen.getByTestId("lblVeteran");
    expect(lblVeteran.tagName).toBe("LABEL");
    expect(lblVeteran.classList).toContain("form-label");
    expect(lblVeteran.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_lblVeteran")
    );
  });
  test("Custom Test Cases for lblVeteran", () => {
    // START_USER_CODE-USER_lblVeteran_TEST
    // END_USER_CODE-USER_lblVeteran_TEST
  });
  test("lblWomen(Label Widget) Test Cases", async () => {
    const lblWomen = screen.getByTestId("lblWomen");
    expect(lblWomen.tagName).toBe("LABEL");
    expect(lblWomen.classList).toContain("form-label");
    expect(lblWomen.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_lblWomen")
    );
  });
  test("Custom Test Cases for lblWomen", () => {
    // START_USER_CODE-USER_lblWomen_TEST
    // END_USER_CODE-USER_lblWomen_TEST
  });
  test("textboxwidget21(Textbox Widget) Test Cases", async () => {
    const textboxwidget21 = screen.getByTestId("textboxwidget21");
    expect(textboxwidget21.tagName).toBe("INPUT");
    expect(textboxwidget21.type).toBe("text");
    expect(textboxwidget21.classList).toContain("textboxWidgetClass");
    expect(textboxwidget21.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_textboxwidget21"
      )
    );
    await act(async () => {
      userEvent.type(textboxwidget21, "1");
    });
  });
  test("Custom Test Cases for textboxwidget21", () => {
    // START_USER_CODE-USER_textboxwidget21_TEST
    // END_USER_CODE-USER_textboxwidget21_TEST
  });
  test("txtAddedBy(Textbox Widget) Test Cases", async () => {
    const txtAddedBy = screen.getByTestId("txtAddedBy");
    expect(txtAddedBy.tagName).toBe("INPUT");
    expect(txtAddedBy.type).toBe("text");
    expect(txtAddedBy.classList).toContain("textboxWidgetClass");
    expect(txtAddedBy.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtAddedBy")
    );
    await act(async () => {
      userEvent.type(txtAddedBy, "123");
    });
    expect(txtAddedBy.getAttribute("value")).toBe("");
    expect(txtAddedBy.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAddedBy", () => {
    // START_USER_CODE-USER_txtAddedBy_TEST
    // END_USER_CODE-USER_txtAddedBy_TEST
  });
  test("txtChangedBy(Textbox Widget) Test Cases", async () => {
    const txtChangedBy = screen.getByTestId("txtChangedBy");
    expect(txtChangedBy.tagName).toBe("INPUT");
    expect(txtChangedBy.type).toBe("text");
    expect(txtChangedBy.classList).toContain("textboxWidgetClass");
    expect(txtChangedBy.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_txtChangedBy"
      )
    );
    await act(async () => {
      userEvent.type(txtChangedBy, "123");
    });
    expect(txtChangedBy.getAttribute("value")).toBe("");
    expect(txtChangedBy.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtChangedBy", () => {
    // START_USER_CODE-USER_txtChangedBy_TEST
    // END_USER_CODE-USER_txtChangedBy_TEST
  });
  test("txtCityMA(Textbox Widget) Test Cases", async () => {
    const txtCityMA = screen.getByTestId("txtCityMA");
    expect(txtCityMA.tagName).toBe("INPUT");
    expect(txtCityMA.type).toBe("text");
    expect(txtCityMA.classList).toContain("textboxWidgetClass");
    expect(txtCityMA.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtCityMA")
    );
    await act(async () => {
      userEvent.type(txtCityMA, "123");
    });
    expect(txtCityMA.getAttribute("value")).toBe("");
    expect(txtCityMA.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCityMA", () => {
    // START_USER_CODE-USER_txtCityMA_TEST
    // END_USER_CODE-USER_txtCityMA_TEST
  });
  test("txtCityPA(Textbox Widget) Test Cases", async () => {
    const txtCityPA = screen.getByTestId("txtCityPA");
    expect(txtCityPA.tagName).toBe("INPUT");
    expect(txtCityPA.type).toBe("text");
    expect(txtCityPA.classList).toContain("textboxWidgetClass");
    expect(txtCityPA.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtCityPA")
    );
    await act(async () => {
      userEvent.type(txtCityPA, "123");
    });
    expect(txtCityPA.getAttribute("value")).toBe("");
    expect(txtCityPA.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCityPA", () => {
    // START_USER_CODE-USER_txtCityPA_TEST
    // END_USER_CODE-USER_txtCityPA_TEST
  });
  test("txtCustomer(Textbox Widget) Test Cases", async () => {
    const txtCustomer = screen.getByTestId("txtCustomer");
    expect(txtCustomer.tagName).toBe("INPUT");
    expect(txtCustomer.type).toBe("text");
    expect(txtCustomer.classList).toContain("textboxWidgetClass");
    expect(txtCustomer.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtCustomer")
    );
    await act(async () => {
      userEvent.type(txtCustomer, "123");
    });
    expect(txtCustomer.getAttribute("value")).toBe("");
    expect(txtCustomer.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCustomer", () => {
    // START_USER_CODE-USER_txtCustomer_TEST
    // END_USER_CODE-USER_txtCustomer_TEST
  });
  test("txtEmail1(Textbox Widget) Test Cases", async () => {
    const txtEmail1 = screen.getByTestId("txtEmail1");
    expect(txtEmail1.tagName).toBe("INPUT");
    expect(txtEmail1.type).toBe("text");
    expect(txtEmail1.classList).toContain("textboxWidgetClass");
    expect(txtEmail1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtEmail1")
    );
    await act(async () => {
      userEvent.type(txtEmail1, "1");
    });
  });
  test("Custom Test Cases for txtEmail1", () => {
    // START_USER_CODE-USER_txtEmail1_TEST
    // END_USER_CODE-USER_txtEmail1_TEST
  });
  test("txtEmail2(Textbox Widget) Test Cases", async () => {
    const txtEmail2 = screen.getByTestId("txtEmail2");
    expect(txtEmail2.tagName).toBe("INPUT");
    expect(txtEmail2.type).toBe("text");
    expect(txtEmail2.classList).toContain("textboxWidgetClass");
    expect(txtEmail2.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtEmail2")
    );
    await act(async () => {
      userEvent.type(txtEmail2, "1");
    });
  });
  test("Custom Test Cases for txtEmail2", () => {
    // START_USER_CODE-USER_txtEmail2_TEST
    // END_USER_CODE-USER_txtEmail2_TEST
  });
  test("txtEmail3(Textbox Widget) Test Cases", async () => {
    const txtEmail3 = screen.getByTestId("txtEmail3");
    expect(txtEmail3.tagName).toBe("INPUT");
    expect(txtEmail3.type).toBe("text");
    expect(txtEmail3.classList).toContain("textboxWidgetClass");
    expect(txtEmail3.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtEmail3")
    );
    await act(async () => {
      userEvent.type(txtEmail3, "1");
    });
  });
  test("Custom Test Cases for txtEmail3", () => {
    // START_USER_CODE-USER_txtEmail3_TEST
    // END_USER_CODE-USER_txtEmail3_TEST
  });
  test("txtFax(Textbox Widget) Test Cases", async () => {
    const txtFax = screen.getByTestId("txtFax");
    expect(txtFax.tagName).toBe("INPUT");
    expect(txtFax.type).toBe("text");
    expect(txtFax.classList).toContain("textboxWidgetClass");
    expect(txtFax.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtFax")
    );
    await act(async () => {
      userEvent.type(txtFax, "1");
    });
  });
  test("Custom Test Cases for txtFax", () => {
    // START_USER_CODE-USER_txtFax_TEST
    // END_USER_CODE-USER_txtFax_TEST
  });
  test("txtLienHolder(Textbox Widget) Test Cases", async () => {
    const txtLienHolder = screen.getByTestId("txtLienHolder");
    expect(txtLienHolder.tagName).toBe("INPUT");
    expect(txtLienHolder.type).toBe("text");
    expect(txtLienHolder.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLienHolder, "1");
    });
  });
  test("Custom Test Cases for txtLienHolder", () => {
    // START_USER_CODE-USER_txtLienHolder_TEST
    // END_USER_CODE-USER_txtLienHolder_TEST
  });
  test("txtMailingAddress(Textbox Widget) Test Cases", async () => {
    const txtMailingAddress = screen.getByTestId("txtMailingAddress");
    expect(txtMailingAddress.tagName).toBe("INPUT");
    expect(txtMailingAddress.type).toBe("text");
    expect(txtMailingAddress.classList).toContain("textboxWidgetClass");
    expect(txtMailingAddress.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_txtMailingAddress"
      )
    );
    await act(async () => {
      userEvent.type(txtMailingAddress, "123");
    });
    expect(txtMailingAddress.getAttribute("value")).toBe("");
    expect(txtMailingAddress.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtMailingAddress", () => {
    // START_USER_CODE-USER_txtMailingAddress_TEST
    // END_USER_CODE-USER_txtMailingAddress_TEST
  });
  test("txtPhoneCI(Textbox Widget) Test Cases", async () => {
    const txtPhoneCI = screen.getByTestId("txtPhoneCI");
    expect(txtPhoneCI.tagName).toBe("INPUT");
    expect(txtPhoneCI.type).toBe("text");
    expect(txtPhoneCI.classList).toContain("textboxWidgetClass");
    expect(txtPhoneCI.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtPhoneCI")
    );
    await act(async () => {
      userEvent.type(txtPhoneCI, "1");
    });
  });
  test("Custom Test Cases for txtPhoneCI", () => {
    // START_USER_CODE-USER_txtPhoneCI_TEST
    // END_USER_CODE-USER_txtPhoneCI_TEST
  });
  test("txtPhone(Textbox Widget) Test Cases", async () => {
    const txtPhone = screen.getByTestId("txtPhone");
    expect(txtPhone.tagName).toBe("INPUT");
    expect(txtPhone.type).toBe("text");
    expect(txtPhone.classList).toContain("textboxWidgetClass");
    expect(txtPhone.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtPhone")
    );
    await act(async () => {
      userEvent.type(txtPhone, "123");
    });
    expect(txtPhone.getAttribute("value")).toBe("");
    expect(txtPhone.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPhone", () => {
    // START_USER_CODE-USER_txtPhone_TEST
    // END_USER_CODE-USER_txtPhone_TEST
  });
  test("txtPhysicalAddress(Textbox Widget) Test Cases", async () => {
    const txtPhysicalAddress = screen.getByTestId("txtPhysicalAddress");
    expect(txtPhysicalAddress.tagName).toBe("INPUT");
    expect(txtPhysicalAddress.type).toBe("text");
    expect(txtPhysicalAddress.classList).toContain("textboxWidgetClass");
    expect(txtPhysicalAddress.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_txtPhysicalAddress"
      )
    );
    await act(async () => {
      userEvent.type(txtPhysicalAddress, "123");
    });
    expect(txtPhysicalAddress.getAttribute("value")).toBe("");
    expect(txtPhysicalAddress.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPhysicalAddress", () => {
    // START_USER_CODE-USER_txtPhysicalAddress_TEST
    // END_USER_CODE-USER_txtPhysicalAddress_TEST
  });
  test("txtStateMA(Textbox Widget) Test Cases", async () => {
    const txtStateMA = screen.getByTestId("txtStateMA");
    expect(txtStateMA.tagName).toBe("INPUT");
    expect(txtStateMA.type).toBe("text");
    expect(txtStateMA.classList).toContain("textboxWidgetClass");
    expect(txtStateMA.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtStateMA")
    );
    await act(async () => {
      userEvent.type(txtStateMA, "123");
    });
    expect(txtStateMA.getAttribute("value")).toBe("");
    expect(txtStateMA.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtStateMA", () => {
    // START_USER_CODE-USER_txtStateMA_TEST
    // END_USER_CODE-USER_txtStateMA_TEST
  });
  test("txtStatePA(Textbox Widget) Test Cases", async () => {
    const txtStatePA = screen.getByTestId("txtStatePA");
    expect(txtStatePA.tagName).toBe("INPUT");
    expect(txtStatePA.type).toBe("text");
    expect(txtStatePA.classList).toContain("textboxWidgetClass");
    expect(txtStatePA.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtStatePA")
    );
    await act(async () => {
      userEvent.type(txtStatePA, "123");
    });
    expect(txtStatePA.getAttribute("value")).toBe("");
    expect(txtStatePA.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtStatePA", () => {
    // START_USER_CODE-USER_txtStatePA_TEST
    // END_USER_CODE-USER_txtStatePA_TEST
  });
  test("txtVendorName(Textbox Widget) Test Cases", async () => {
    const txtVendorName = screen.getByTestId("txtVendorName");
    expect(txtVendorName.tagName).toBe("INPUT");
    expect(txtVendorName.type).toBe("text");
    expect(txtVendorName.classList).toContain("textboxWidgetClass");
    expect(txtVendorName.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:VendorMaintenance_txtVendorName"
      )
    );
    await act(async () => {
      userEvent.type(txtVendorName, "123");
    });
    expect(txtVendorName.getAttribute("value")).toBe("");
    expect(txtVendorName.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendorName", () => {
    // START_USER_CODE-USER_txtVendorName_TEST
    // END_USER_CODE-USER_txtVendorName_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "123");
    });
    expect(txtVendor.getAttribute("value")).toBe("");
    expect(txtVendor.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("txtZipMA(Textbox Widget) Test Cases", async () => {
    const txtZipMA = screen.getByTestId("txtZipMA");
    expect(txtZipMA.tagName).toBe("INPUT");
    expect(txtZipMA.type).toBe("text");
    expect(txtZipMA.classList).toContain("textboxWidgetClass");
    expect(txtZipMA.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtZipMA")
    );
    await act(async () => {
      userEvent.type(txtZipMA, "123");
    });
    expect(txtZipMA.getAttribute("value")).toBe("");
    expect(txtZipMA.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtZipMA", () => {
    // START_USER_CODE-USER_txtZipMA_TEST
    // END_USER_CODE-USER_txtZipMA_TEST
  });
  test("txtZipPA(Textbox Widget) Test Cases", async () => {
    const txtZipPA = screen.getByTestId("txtZipPA");
    expect(txtZipPA.tagName).toBe("INPUT");
    expect(txtZipPA.type).toBe("text");
    expect(txtZipPA.classList).toContain("textboxWidgetClass");
    expect(txtZipPA.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:VendorMaintenance_txtZipPA")
    );
    await act(async () => {
      userEvent.type(txtZipPA, "123");
    });
    expect(txtZipPA.getAttribute("value")).toBe("");
    expect(txtZipPA.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtZipPA", () => {
    // START_USER_CODE-USER_txtZipPA_TEST
    // END_USER_CODE-USER_txtZipPA_TEST
  });
});
