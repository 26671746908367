/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  ButtonWidget,
  disable,
  enable,
  setData,
  hide,
  getData
} from "../../shared/WindowImports";
import "./FlexMktPrint.scss";

import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../Service/ContractManagementService";
import CommonContext from "../../Store/CommonContext";
import jsPDF from "jspdf";
import { GetReportLogoImage, GetReportLogoImageDimension } from "./../../Common/Constants";

function ContractManagement_FlexMktPrint(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const commonContext = useContext(CommonContext);

  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));

  const printWorksheet = "WORKSHEET"
  const printPricingContract = "PRICING CONTRACT"

  const imgUrl = GetReportLogoImage(window.location.origin)
  const imgDimension = GetReportLogoImageDimension()

  let cropYear = cropYearFromLS()
  let contract_num = commonContext.getContractProfile_ContractNumber;
  let pricing_line = ""
  let price_suffix = "";
  let lstContract = [];
  let lstContractLienHolders = [];
  let lstMarketPriceElection = [];
  let lstMarketDetail = [];
  let overFlowFlag;
  
  let states = {
    Label: "FlexMktPrint",
    windowName: "FlexMktPrint",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.FlexMktPrint",

    headerData: {
      scrName: "Print Preview",
      scrCode: "PN9000A",
    },
    renderUrl: "",
    renderCode: "",

    groupboxwidget0: {
      name: "groupboxwidget0",
      type: "GroupBoxWidget",
      parent: "FlexMktPrint",
      Label: "groupboxwidget0",
      Height: "",
      Width: "",
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "groupboxwidget0",
      Label: "Print",
      CharWidth: "10",
      ofTypeDomain: "d_boolean",
    },
    btnSaveAsPdf: {
      name: "btnSaveAsPdf",
      type: "ButtonWidget",
      parent: "groupboxwidget0",
      Label: "Save As PDF",
      CharWidth: "10",
      ofTypeDomain: "d_boolean",
      Visible: false,
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "groupboxwidget0",
      Label: "Close",
      CharWidth: "10",
      ofTypeDomain: "d_boolean",
    },
    txtDate: {
      name: "txtDate",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Date: ",
      ReadOnly: true,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCropYear: {
      name: "txtCropYear",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "Crop Year: ",
      ReadOnly: true,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtNo: {
      name: "txtNo",
      type: "TextBoxWidget",
      parent: "groupboxwidget0",
      Label: "No: ",
      ReadOnly: true,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    _dataCopy: {
      FORWARD: {},
      REVERSE: {},
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    commonContext.setFlexMktPrint_PrintType('')
    disable(thisObj, "btnPrint")
    hide(thisObj, "btnSaveAsPdf")
    disable(thisObj, "btnClose")
    GetApiData()
  }, []);

  async function GetApiData() {
    setData(thisObj, 'overFlowFlag', true)
    if (commonContext.getContractProfile_ContractNumber != undefined && commonContext.getContractProfile_ContractNumber != null) {
      if (commonContext.getContractProfile_ContractNumber != '') {
        contract_num = commonContext.getContractProfile_ContractNumber;
        setData(thisObj, 'contract_num', contract_num)
      }
    }
    else {
      alert('Contract number is missing')
      return;
    }

    let response = await ContractManagementService.RetrieveContractDetails(contract_num, 'PN1010')
    if (response != null && response != undefined) {
      if (response.length != 0) {
        lstContract = response[0]
        if (response[0].length != 0) {
          lstContractLienHolders = response[0].contract_lienholders
          if (response[0].contract_pricings.length != 0) {
            pricing_line = response[0].contract_pricings[0].pricing_line
            price_suffix = response[0].contract_pricings[0].firm_basis
          }
        }
      }
      else {
        alert("No data for the contract details.")
        return;
      }
    }
    else {
      alert("Some technical issue has happened. Please try again.")
      return;
    }

    let response2 = await ContractManagementService.RetrieveFlexMarketDetails(contract_num)
    if (response2 != null && response2 != undefined) {
      if (response2.length != 0) {
        lstMarketDetail = response2

        enable(thisObj, "btnPrint")
        disable(thisObj, "btnSaveAsPdf")
        enable(thisObj, "btnClose")
        ReadFile()
      }
      else {
        alert("Cannot retrieve flex market details.")
        return;
      }
    }
    else {
      alert("Some technical issue has happened. Please try again.")
      return;
    }
  }

  async function ReadFile() {
    var renderCode = await GetHtmlCode(78, 80, '')
    state.renderCode = renderCode
    state.renderUrl = getBlobURL(renderCode, 'text/html')
    document.getElementById("docFrame").setAttribute("src", state.renderUrl);
  }

  function GetHTMLData() {
    if (lstMarketDetail.length == 0)
      return;

    if (lstContract.length == 0) {
      lstContract = {
        "handlerNo": '',
        "licenseNo": '',
        "center_pivot_acres": '',
        "dry_land_acres": '',
        "yield_per_acre": '',
        "pnut_variety_name": '',
        "seed_grower": '',
        "oleic_ind": '',
        "organic": '',
        'contract_pricings': []
      }
      lstContractLienHolders = [
        { "lienholder_name": '', "lienholder_address": '', "nature_of_lien": '' },
        { "lienholder_name": '', "lienholder_address": '', "nature_of_lien": '' }
      ]
    }
    lstContractLienHolders = lstContract.contract_lienholders

    var parentData = {
      collectionPoint: lstContract.coll_pt_id,
      deliveryPoint: lstContract.buy_pt_id,
      handlerNo: lstContract.handler_loc_num,
      licenseNo: lstContract.fed_whouse_license,
      centerPivotIrr: lstContract.center_pivot_acres,
      cableTowIrrigated: "",
      dryLand: lstContract.dry_land_acres,
      total: Number(lstContract.center_pivot_acres) + Number(lstContract.dry_land_acres),
      avgYield: lstContract.yield_per_acre,
      variety: lstContract.pnut_variety_name,
      seedGrower: lstContract.seed_grower,
      seedCredit: "",
      highOleic: lstContract.oleic_ind,
      organic: lstContract.organic,
      lineHolderName1: lstContractLienHolders[0].lienholder_name,
      lineHolderAddr1: lstContractLienHolders[0].lienholder_address,
      lineHolderNature1: lstContractLienHolders[0].nature_of_lien,
      lineHolderName2: lstContractLienHolders[1].lienholder_name,
      lineHolderAddr2: lstContractLienHolders[1].lienholder_address,
      lineHolderNature2: lstContractLienHolders[1].nature_of_lien
    }

    var data1 = lstMarketDetail
    
    return {
      cropYear: data1[0].contract_headers[0].crop_year,
      contractNumber: data1[0].contract_headers[0].contract_num,
      contractType : lstContract.cont_type,
      vendorNumber: "<u> " + data1[0].vendors[0].split_vendor + " </u>",
      vendorName: "<u> " + data1[0].vendors[0].vendor_name + " </u>",
      vendorAddress1: "<u> " + data1[0].vendors[0].vendor_addr1 + " </u>",
      vendorAddress2: "<u> " + data1[0].vendors[0].vendor_addr2 + " </u>",
      vendorCity: "<u> " + data1[0].vendors[0].vendor_city + " </u>",
      vendorState: "<u> " + data1[0].vendors[0].vendor_state + " </u>",
      vendorZip: "<u> " + data1[0].vendors[0].vendor_zip + " </u>",
      deliveryPoint: "<u> " + parentData.deliveryPoint + " </u>",
      collectionPoint: "<u> " + parentData.collectionPoint + " </u>",
      handlerNo: "<u> " + parentData.handlerNo + " </u>",
      licenseNo: "<u> " + parentData.licenseNo + " </u>",
      contractType: data1[0].contract_headers[0].cont_type,
      centerPivotIrr: parentData.centerPivotIrr,
      cableTowIrrigated: parentData.cableTowIrrigated,
      dryLand: parentData.dryLand,
      total: parentData.total,
      avgYield: parentData.avgYield,
      variety: parentData.variety,
      peanutType: data1[0].contract_headers[0].pnut_type_id,
      segregation: data1[0].contract_headers[0].seg_type,
      seedGrower: parentData.seedGrower,
      seedCredit: parentData.seedCredit,
      highOleic: parentData.highOleic,
      organic: parentData.organic,
      purchasePeriod: "???",
      lineHolderName1: "<u> " + parentData.lineHolderName1 + " </u>",
      lineHolderAddr1: "<u> " + parentData.lineHolderAddr1 + " </u>",
      lineHolderNature1: "<u> " + parentData.lineHolderNature1 + " </u>",
      lineHolderName2: "<u> " + parentData.lineHolderName2 + " </u>",
      lineHolderAddr2: "<u> " + parentData.lineHolderAddr2 + " </u>",
      lineHolderNature2: "<u> " + parentData.lineHolderNature2 + " </u>"
    }
  }

  async function GetHtmlCode(fontSize, tableWidth, pageGap) {
    var obj = GetHTMLData()
    if (obj == null && obj == undefined) {
      obj = {
        cropYear: cropYear,
        contractNumber: contract_num,
        vendorNumber: "______",
        vendorName: "_______________",
        vendorAddress1: "_______________",
        vendorAddress2: "",
        vendorCity: "______",
        vendorState: "_______",
        vendorZip: "________",
        deliveryPoint: "___",
        collectionPoint: "___",
        handlerNo: "______",
        licenseNo: "______",
        contractType: "M",
        centerPivotIrr: "",
        cableTowIrrigated: "",
        dryLand: "",
        total: "",
        avgYield: "",
        variety: "",
        peanutType: "",
        segregation: "",
        seedGrower: "",
        seedCredit: "",
        highOleic: "",
        organic: "",
        purchasePeriod: "___",
        lineHolderName1: "___________________",
        lineHolderAddr1: "___________________",
        lineHolderNature1: "___________________",
        lineHolderName2: "___________________",
        lineHolderAddr2: "___________________",
        lineHolderNature2: "___________________"
      }
    }
    var renderCode = ''
    let printType;
    
    if (commonContext.getFlexMktPrint_PrintType != null && commonContext.getFlexMktPrint_PrintType != undefined) {
      if(commonContext.getFlexMktPrint_PrintType != ''){
        printType = commonContext.getFlexMktPrint_PrintType
        setData(thisObj, 'printType', printType);
      }
      else {
        printType = getData(thisObj, 'printType');
      }
    }
    else {
      printType = getData(thisObj, 'printType');
    }
    
    if (printType == printPricingContract) {
      renderCode = getPricingContract(obj, fontSize, tableWidth)
    }
    else if (printType == printWorksheet) {
      renderCode = getWorkSheet(obj)
    }
    
    return renderCode;
  }

  async function getPricingContract(obj, fontSize, tableWidth) {
    let pricingElection = getData(thisObj, 'pricingNumber');
    contract_num = getData(thisObj, 'contract_num')
    let response = await ContractManagementService.RetrieveContractDetails(contract_num, 'PN1010')
    pricing_line = response[0].contract_pricings[0].pricing_line
    price_suffix = pricingElection.slice(-1);
    
    let response1 = await ContractManagementService.RetrieveFlexMarketPriceElectionPrint(contract_num, pricing_line, price_suffix)
    
    if (response1 != null && response1 != undefined) {
      if (response1.length != 0) {
        lstMarketPriceElection = response1
      }
      else {
        alert("No data for flex market price election details.")
        return;
      }
    }
    else {
      alert("Some technical issue has happened. Please try again.")
      return;
    }

    var pricingContractObj = {
      'contractNumber': lstMarketPriceElection[0].contract_num == '' ? '______' : lstMarketPriceElection[0].contract_num,
      'pricingNumber': pricingElection == '' ? '______' : pricingElection,
      'cropYear': lstMarketPriceElection[0].crop_year,
      'vendorNumber': lstMarketPriceElection[0].split_vendor,
      'vendorName': lstMarketPriceElection[0].vendor_name,
      'vendorAddress1': lstMarketPriceElection[0].vendor_addr1,
      'vendorAddress2': lstMarketPriceElection[0].vendor_addr2,
      'vendorCity': lstMarketPriceElection[0].vendor_city,
      'vendorState': lstMarketPriceElection[0].vendor_state,
      'vendorZip': lstMarketPriceElection[0].vendor_zip,
      'deliveryPoint': lstMarketPriceElection[0].buy_pt_id,
      'collectionPoint': lstMarketPriceElection[0].coll_pt_id,
      'pounds': lstMarketPriceElection[0].priced_pounds == '' ? '______' : lstMarketPriceElection[0].priced_pounds,
      'marketPrice': lstMarketPriceElection[0].market_price,
      'rebate': lstMarketPriceElection[0].rebate_rate == '' ? '______' : parseFloat(lstMarketPriceElection[0].rebate_rate).toFixed(2),
      'balance': lstMarketPriceElection[0].open_lbs == '' ? '______' : lstMarketPriceElection[0].open_lbs,
      'pricePounds' : lstMarketPriceElection[0].priced_pounds == '' ? '______' : lstMarketPriceElection[0].priced_pounds,
      'payType' : lstMarketPriceElection[0].pay_rebate_at == 'DELIVERY' ? 'contracted' : 'delivered'
    }
    var pricingBody = ''
    if(obj.contractType == 'L'){
      pricingBody = `
          <tr>
              <td style="padding-left:3%;padding-right: 12%;padding-right:13%;text-align: justify;font-size:110%;color: black;" colspan="3">
                  Seller/Producer entered into <i> Option for Purchase of Farmer Stock Peanuts (Golden Acceptance Required) </i>
                  ("Contract") numbered <u> ${pricingContractObj.contractNumber} </u> .
                  Under the terms of the contract, Golden is hereby notified that Seller/Producer elects to make a price election on the above referenced Contract on 
                  <u> &nbsp; ${pricingContractObj.pounds} </u> pounds at $ <u> ${pricingContractObj.rebate} </u> per net ton (basis grade) which reflects a farmer stock price of $ <u> ${pricingContractObj.rebate} </u>.  <br/> <br/>
              </td>    
          </tr>
          <tr>
              <td style="padding-left:3%;padding-right: 12%;padding-right:13%;text-align: justify;font-size:110%;color: black;" colspan="3">
                  This Price Election is irrevocable and leaves a balance of <u> ${pricingContractObj.balance} </u> ${pricingContractObj.payType} pounds not priced. This Price Election is not 
                  valid until accepted by an authorized Golden employee.  <br/> <br/>
              </td>    
          </tr>
          <tr>
              <td style="padding-left:3%;padding-right: 12%;padding-right:13%;text-align: justify;font-size:110%;color: black;" colspan="3">
                  This Price Election is hereby acknowledged and accepted.  <br/> <br/> 
              </td>    
          </tr>
          `      
    }
    else if(obj.contractType == 'M'){
      pricingBody = ` 
          <tr>
              <td style="padding-left:3%;padding-right: 12%;padding-right:13%;text-align: justify;font-size:110%;color: black;" colspan="3">
                  Seller/Producer entered into <i> Option for Purchase of Farmer Stock Peanuts (Golden Acceptance Required) </i> ("Contract") numbered <u> ${pricingContractObj.contractNumber} </u> .
                  and received a letter from Golden Peanut Company, LLC ("Golden") of eligibility to participate in the Golden Flexible Marketing Plan ("Plan").
                  Under the terms of the Plan, Golden is hereby notified that Seller/Producer elects to make a price election on the above-referenced Contract on 
                  <u> ${pricingContractObj.pounds} </u> pounds. Seller/Producer elects the following: <br/>
              </td>    
          </tr>
          <tr>
              <td style="padding-left:3%;padding-right: 12%;padding-right:13%;text-align: justify;font-size:110%;color: black;" colspan="3">
                  Market Rebate ("Rebate"). The effective U.S. runner medium market price of $ <u> ${pricingContractObj.marketPrice} </u> per pound results in a Rebate of $ <u> ${pricingContractObj.rebate} </u> <br/> 
              </td>    
          </tr>
          <tr>
              <td style="padding-left:3%;padding-right: 12%;padding-right:13%;text-align: justify;font-size:110%;color: black;" colspan="3">
                  The Price Election is irrevocable and leaves a balance of <u> ${pricingContractObj.balance}  </u> delivered pounds unpriced. This price Election is not valid until accepted by an authorized Golden employee.  <br/> <br/>
              </td>    
          </tr>
          <tr>
              <td style="padding-left:3%;padding-right: 12%;padding-right:13%;text-align: justify;font-size:110%;color: black;" colspan="3">
                  The price used to establish the Market Rebate above is based on the price announced and posted at 3:00 p.m. Eastern time. &nbsp; The Rebate will be paid at the end of the Marketing Period.  <br/> <br/>
              </td>    
          </tr>
          <tr>
              <td style="padding-left:3%;padding-right: 12%;padding-right:3.75%;text-align: justify;font-size:110%;color: black;" colspan="3">
                  This Price Election is hereby acknowledged and accepted.  <br/> <br/>
              </td>    
          </tr>
          `
    }
    var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test';
    return `<html>
      <style>
      body:before{
        content: '${environmentType}';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;  
        color: #0d745e;
        font-size: 250px;
        font-weight: 500px;
        display: grid;
        justify-content: center;
        align-content: center;
        opacity: 0.35;
      }
        @media print {
          @page :footer { display: none }
          @page :header { display: none }
          @page { margin-top : 0; margin-bottom: 0; }
          html, body {
            height:100%; 
            margin: 0 !important; 
            padding: 0 !important;
          }
        }
      </style>
      <br/> <br/> 
      <table style="align-items: center;width: ${tableWidth}%;font-size:${fontSize}%;">
            <tr style="padding-left:1%; padding-right: 1%;">
                <td style="padding-left:3%; padding-right: 1%;">
                <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" /> <br/> 
                </td>
                <td style="padding-left:1%;text-align: center;padding-right: 1%;color: black;">
                    <br/> <br/>
                    <span style="font-size:130%;">
                      <br/> <br/> <br/>  <b> Notice of Price Election <br/> Pricing Number  :  ${pricingContractObj.pricingNumber}  </b>
                    </span>
                </td>
                <td>
                    <span style="text-align-last: left;color:black;font-size: 130%;"> &nbsp; Golden Peanut Company, LLC<br/></span>
                    <span style="text-align: center;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;  (Golden)<br/></span>
                    <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 100 North Point Center East, Suite 400<br/></span>
                    <span style="text-align: right;color:black;font-size: 80%;"> &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Alpharetta, Georgia 30022-8290<br/></span>
                </td>
            </tr>
  
            <tr>
              <td colspan="3" style="padding-left: 60%;color: black;">
                  <span style="font-size:120%;">  <b> CROP YEAR &nbsp; <u> ${pricingContractObj.cropYear} </u> </b> <br/> <br/>  </span>
              </td>
            </tr>
  
            <tr>
              <td style="padding-left:3%;font-size:110%;color: black;" colspan="2"> 
                  Seller/Producer Name & Address: Vendor # &nbsp; <u> ${pricingContractObj.vendorNumber} </u>
              </td>
              <td style="padding-left: 1%;font-size:110%;"> Delivery Point: &nbsp; <u> ${pricingContractObj.deliveryPoint}  </u> </td> 
          </tr>
          <tr>
              <td style="padding-left:3%;font-size:110%;color: black;" colspan="2"> 
                  <u> ${pricingContractObj.vendorName} </u>
              </td>
              <td style="padding-left: 1%;font-size:110%;color: black;"> Collection Point: &nbsp; <u> ${pricingContractObj.collectionPoint} &nbsp; </u> </td> 
          </tr>
          <tr>
              <td style="padding-left:3%;font-size:110%;color: black;" colspan="2"> 
                  <u> ${pricingContractObj.vendorAddress1} &nbsp; ${pricingContractObj.vendorAddress2} &nbsp; &nbsp; </u>
              </td>
          </tr>
          <tr>
              <td style="padding-left:3%;font-size:110%;color: black;" colspan="2"> 
                  <u> ${pricingContractObj.vendorCity} &nbsp;, ${pricingContractObj.vendorState} &nbsp; ${pricingContractObj.vendorZip} </u> <br/> <br/>
              </td>    
          </tr>
          ${pricingBody}
          <tr>
              <td style="padding-left:3%;padding-right: 12%;padding-right:3.75%;text-align: justify;font-size:110%;color: black;" colspan="3">
                  By : <u> 
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
                  </u> <br/>
                  &nbsp; &nbsp; &nbsp; &nbsp;  <span style="font-size: 65%;;">Seller/Producer</span>
                  <br/>  <br/>
              </td>    
          </tr>
          <tr>
              <td style="padding-left:3%;padding-right: 12%;padding-right:3.75%;text-align: justify;font-size:110%;color: black;" colspan="3">
                  Date : <u> 
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </u>  <br/>  <br/>  <br/>  <br/>
              </td>    
          </tr>
  
          <tr>
              <td style="padding-left:3%;padding-right: 12%;padding-right:3.75%;text-align: justify;font-size:110%;color: black;" colspan="3">
                  <b> Golden Peanut Company, LLC  </b> <br/>
                  By, Name and Title
              </td>    
          </tr>
      </table>
      </html>`
  }

  function getWorkSheet(obj) {
    var date = new Date()
    var month = date.getMonth() + 1
    if (month < 10)
      month = "0" + month;
    var d = date.getDate()
    if (d < 10)
      d = "0" + d;
    var todayDate = month + "/" + d + "/" + date.getFullYear()
    
    var headers = lstMarketDetail[0].contract_headers[0]
    var workSheetObj = {
      'todayDate': todayDate,
      'vendorNumber': obj.vendorNumber,
      'vendorName': obj.vendorName,
      'vendorAddress1': obj.vendorAddress1,
      'vendorAddress2': obj.vendorAddress2,
      'vendorCity': obj.vendorCity,
      'vendorState': obj.vendorState,
      'vendorZip': obj.vendorZip,
      'contractNumber': headers.contract_num,
      'cropYear': headers.crop_year,
      'contApprDate': headers.approved_date_time.split(' ')[0],
      'peanutType': headers.pnut_type_name,
      'seg': headers.seg_type,
      'buyingPtLoc': headers.buy_pt_id,
      'loanRepayMethod': headers.loan_repay_ind == 'G' ? 'Golden' : headers.loan_repay_ind == 'U' ? 'USDA' : '',
      'marketingPeriod': headers.mkt_wk_prior_mat,
      'multiplePricing': lstContract.contract_pricings.length >= 1 ? 'Y' : 'N',
      'pricingTypeDesc': headers.pricing_type_desc,
      'orgContractLBS': headers.cont_lbs,
      'contractLBSNotDel': headers.not_delv_lbs,
      'delLBS': headers.delv_lbs,
      'delLBSWithOutLoan': headers.delv_no_loan_lbs,
      'delLBSWithLoan': headers.delv_loan_lbs,
      'loanLBSPriced': headers.loan_priced,
      'loanLBSNotPriced': headers.loan_not_priced,
      'endMarketPeriod': headers.flex_mkt_end_date == '' ? '' : (new Date(headers.flex_mkt_end_date)).toLocaleString().split(',')[0],
      'headingName' : obj.contractType == 'M' ? 'FLEXIBLE MARKET' : 'FLOOR'
    }
    
    if(obj.contractType == 'M')
      return getFlexMarketWorkSheet(workSheetObj)
    else
      return getFloorWorkSheet(workSheetObj)
  }

  function getFlexMarketWorkSheet(workSheetObj) {    
    var open_whs_rcpt = `<table style="align-items: center;width: 25%;font-size:80%;padding-left:0.5%;padding-top: 1%;">
      <tr style="padding-left:1%; padding-right: 1%;color: black;">
        <td style="padding-left:1%;padding-right: 1%;text-align: left;color: black;"> RECEIPT &nbsp; # </td>
        <td style="padding-left:1%;padding-right: 1%;text-align: right;color: black;"> LBS. NOT PRICED </td>
      </tr>
      <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;">
        <td colspan="10"> <hr style="width: 110%;"> </td>
      </tr>`
    var openWhsRcptList = []
    for(var i=0 ; i< lstMarketDetail[0].open_whs_rcpts.length; i++){
      let openPound = Intl.NumberFormat('en-US').format(lstMarketDetail[0].open_whs_rcpts[i].open_pounds);
      openWhsRcptList.push({
        'whs_rcpt_bp'  : lstMarketDetail[0].open_whs_rcpts[i].whs_rcpt_bp,
        'whs_rcpt_num' : lstMarketDetail[0].open_whs_rcpts[i].whs_rcpt_num,
        'open_pounds'  : openPound
      })
    }
    
    if (openWhsRcptList.length != 0) {      
      for (var i = 0; i < 1; i++) {
        open_whs_rcpt += `<tr style="padding-left:1%; padding-right: 1%;color: black;">
            <td style="padding-left:1%;padding-right: 1%;text-align: left;color: black;">  ${openWhsRcptList[i].whs_rcpt_bp} - ${openWhsRcptList[i].whs_rcpt_num} </td>
            <td style="padding-left:1%;padding-right: 1%;text-align: right;color: black;"> ${(openWhsRcptList[i].open_pounds)} </td>
          </tr>`
      }
      open_whs_rcpt += `</table>`
    }
    else{
      open_whs_rcpt += `<tr style="padding-left:1%; padding-right: 1%;color: black;">
            <td style="padding-left:1%;padding-right: 1%;text-align: left;color: black;">  Open Total: </td>
            <td style="padding-left:1%;padding-right: 1%;text-align: right;color: black;"> 0 </td>
          </tr> </table>`
    }

    var contractFlexMktPricing = `<table style="align-items: center;width: 100%;font-size:80%;padding-left: 0.01%;">
    <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;">
        <td style="color: black;"> <br/> <br/> <br/> <br/> CONTRACT  <br/> PRICING #  </td> 
        <td style="color: black;"> <br/> <br/> <br/> <br/> PRICING   <br/> STATUS  </td>
        <td style="color: black;"> <br/> <br/> <br/> <br/> RECIPT <br/> # </td> 
        <td style="color: black;"> <br/> <br/> <br/> <br/> LBS <br/> PRICED   </td> 
        <td style="color: black;"> <br/> <br/> <br/> <br/> DATE   OF <br/> ELECTION   </td> 
        <td style="color: black;"> <br/> <br/> <br/> DATE  OF <br/> POSTED <br/> PRICE </td>
        <td style="color: black;"> <br/> <br/> <br/> <br/>ELECTION  <br/> TYPE </td> 
        <td style="color: black;"> <br/> <br/> <br/>MARKET  <br/> PRICE <br/> PER   LB. </td> 
        <td style="color: black;"> <br/> <br/> <br/> REBATE <br/> PER   TON <br/>BASIS <br/>GRADE </td> 
        <td style="color: black;"> <br/> <br/> REBATE <br/> VALUE <br/> FOR <br/> PRICED   LBS. </td>
        <td style="color: black;"> <br/> <br/> <br/> <br/> POSTED <br/> PRICE </td>
        <td style="color: black;"> <br/> <br/> PRODUCER  <br/>GPC  LOAN   <br/>GAIN PER <br/> TON </td>
        <td style="color: black;"> <br/> <br/> <br/> LOAN <br/>GAIN FOR <br/>PRICES LBS. </td>
        <td style="color: black;"> <br/> <br/> <br/> <br/> DATE <br/> PAID </td>
        <td style="color: black;"> <br/> <br/> <br/> <br/>  <br/>CHECK   # </td> 
        <td style="color: black;"> <br/> <br/> <br/> <br/> <br/> AMOUNT PAID </td> 
    </tr>`+
      `<tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;">
        <td style="padding-left: 0%;color: black;"colspan="16"> <hr style="width: 100%;"> </td>
    </tr>`

    var contractFlexMktPricingList = []
    var contractPricingList = []
    
    if (commonContext.getFlexMktPrint_ContractPricingList != undefined || commonContext.getFlexMktPrint_ContractPricingList != null) {
      contractPricingList = commonContext.getFlexMktPrint_ContractPricingList;
      
      var dict = { 'P': 'Pending', 'S': 'Signed', 'A': 'Approved', 'V': 'Voided' }

      if (contractPricingList.colBtnWhsRcpts == '-') {        
        for (var i = 0; i < contractPricingList.length; i++) {
          contractFlexMktPricingList.push({
            contract_pricing: contractPricingList[i].colTxtPricingNum,
            pricing_status: contractPricingList[i].colTxtStatus,
            receipt: contractPricingList[i].colBtnWhsRcpts == '-' ? '' : contractPricingList[i].colBtnWhsRcpts,
            priced_pounds: contractPricingList[i].colTxtPounds,
            pricing_date: contractPricingList[i].colTxtPricingDate,
            price_posting_date: '',
            market_election: contractPricingList[i].colTxtElection,
            market_price_per_lb: contractPricingList[i].colTxtMarketPrice,
            rebate_per_ton_basis_grade: contractPricingList[i].colTxtRebateRate,
            rebate_value_for_priced_lbs: '',
            posted_price: '',
            producer_GPC_loan_gain_per_ton: '',
            loan_gain_priced_lbs: '',
            data_paid: '',
            check: '',
            amout_paid: ''
          })
        }
      }
      else {        
        if (contractPricingList != null && contractPricingList != undefined) {          
          if (contractPricingList.length != 0 && lstMarketDetail[0].contract_flex_mkt_pricings[0].length != 0) {           
            var obj = {
              contract_pricing: lstMarketDetail[0].contract_flex_mkt_pricings[i].contract_num + lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_suffix,
              pricing_status: dict[lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_status],
              receipt: lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_bp + '-' + lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_num,
              priced_pounds: lstMarketDetail[0].contract_flex_mkt_pricings[i].priced_pounds,
              pricing_date: (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_date)).toLocaleString().split(',')[0],
              price_posting_date: lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date == '' ? '' : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date)).toLocaleString().split(',')[0],
              market_election: lstMarketDetail[0].contract_flex_mkt_pricings[i].market_election,
              market_price_per_lb: '',
              rebate_per_ton_basis_grade: '',
              rebate_value_for_priced_lbs: '',
              posted_price: lstMarketDetail[0].contract_flex_mkt_pricings[i].posted_price,
              producer_GPC_loan_gain_per_ton: '',
              loan_gain_priced_lbs: '',
              data_paid: '',
              check: '',
              amout_paid: '',
            }
            if (lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length != 0) {
              obj.data_paid = (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].issued_date)).toLocaleString().split(',')[0]
              obj.check = lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_num
              obj.amout_paid = (lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_proceeds).toLocaleString("en-US") + "  " + (lstMarketDetail[0].contract_flex_mkt_pricings[i].rtp_ind == 'Y' ? 'R-T-P' : '') 
            }
            contractFlexMktPricingList.push(obj)
          }
          else{            
            for(var i=0; i<lstMarketDetail[0].contract_flex_mkt_pricings.length; i++){
              contractFlexMktPricingList.push({
                contract_pricing: lstMarketDetail[0].contract_flex_mkt_pricings[i].contract_num + lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_suffix,
                pricing_status: dict[lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_status],          
                receipt: lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_bp + '-' + lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_num,
                priced_pounds: lstMarketDetail[0].contract_flex_mkt_pricings[i].priced_pounds,
                pricing_date: (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_date)).toLocaleString().split(',')[0],
                price_posting_date: lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date == '' ? '' : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date)).toLocaleString().split(',')[0],
                market_election: lstMarketDetail[0].contract_flex_mkt_pricings[i].market_election,
                market_price_per_lb: lstMarketDetail[0].contract_flex_mkt_pricings[i].market_price,
                rebate_per_ton_basis_grade: lstMarketDetail[0].contract_flex_mkt_pricings[i].rebate_rate,
                rebate_value_for_priced_lbs: lstMarketDetail[0].contract_flex_mkt_pricings[i].rebate_value,
                posted_price: lstMarketDetail[0].contract_flex_mkt_pricings[i].posted_price,
                producer_GPC_loan_gain_per_ton: '',
                loan_gain_priced_lbs: '',
                data_paid: lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length == 0 ? '' : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].issued_date)).toLocaleString().split(',')[0],
                check: lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length == 0 ? '' : lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_num,
                amout_paid: lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length == 0 ? '' : (lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_proceeds).toLocaleString("en-US") + "  " + (lstMarketDetail[0].contract_flex_mkt_pricings[i].rtp_ind == 'Y' ? 'R-T-P' : '') 
              })
            }

          }
        }
        else {          
          for (var i = 0; i < lstMarketDetail[0].contract_flex_mkt_pricings.length; i++) {
            obj = {
              contract_pricing: lstMarketDetail[0].contract_flex_mkt_pricings[i].contract_num + lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_suffix,
              pricing_status: dict[lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_status],
              receipt: lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_bp + '-' + lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_num,
              priced_pounds: lstMarketDetail[0].contract_flex_mkt_pricings[i].priced_pounds,
              pricing_date: (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_date)).toLocaleString().split(',')[0],
              price_posting_date: lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date == '' ? '' : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date)).toLocaleString().split(',')[0],
              market_election: lstMarketDetail[0].contract_flex_mkt_pricings[i].market_election,
              market_price_per_lb: '',
              rebate_per_ton_basis_grade: '',
              rebate_value_for_priced_lbs: '',
              posted_price: lstMarketDetail[0].contract_flex_mkt_pricings[i].posted_price,
              producer_GPC_loan_gain_per_ton: '',
              loan_gain_priced_lbs: '',
              data_paid: '',
              check: '',
              amout_paid: '',
            }
            
            if (lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length != 0) {
              let amtPaid = (lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_proceeds).toLocaleString("en-US") 

              obj.data_paid = (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].issued_date)).toLocaleString().split(',')[0]
              obj.check = lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_num
              obj.amout_paid = amtPaid + "  " + (lstMarketDetail[0].contract_flex_mkt_pricings[i].rtp_ind == 'Y' ? 'R-T-P' : '') 
            }
            contractFlexMktPricingList.push(obj)
          }
        }
      }
    }
    else {
      var dict = { 'P': 'Pending', 'S': 'Signed', 'A': 'Approved', 'V': 'Voided' }
      
      for(var i=0; i<lstMarketDetail[0].contract_flex_mkt_pricings.length; i++){          
        let lbsPriced = Intl.NumberFormat('en-US').format(lstMarketDetail[0].contract_flex_mkt_pricings[i].priced_pounds);
        //let rebateValueForPricedLbs = Intl.NumberFormat('en-US').format(lstMarketDetail[0].contract_flex_mkt_pricings[i].rebate_value);
        let rebateValueForPricedLbs = Number(lstMarketDetail[0].contract_flex_mkt_pricings[i].rebate_value).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        contractFlexMktPricingList.push({
          contract_pricing               : lstMarketDetail[0].contract_flex_mkt_pricings[i].contract_num + lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_suffix,
          pricing_status                 : dict[lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_status],          
          receipt                        : lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_bp + '-' + lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_num,
          priced_pounds                  : lbsPriced, 
          pricing_date                   : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_date)).toLocaleString().split(',')[0],
          price_posting_date             : lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date == '' ? '' : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date)).toLocaleString().split(',')[0],
          market_election                : lstMarketDetail[0].contract_flex_mkt_pricings[i].market_election,
          market_price_per_lb            : lstMarketDetail[0].contract_flex_mkt_pricings[i].market_price,
          rebate_per_ton_basis_grade     : lstMarketDetail[0].contract_flex_mkt_pricings[i].rebate_rate,
          rebate_value_for_priced_lbs    : rebateValueForPricedLbs, 
          posted_price                   : lstMarketDetail[0].contract_flex_mkt_pricings[i].posted_price,
          producer_GPC_loan_gain_per_ton : '',
          loan_gain_priced_lbs           : '',
          data_paid                      : lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length == 0 ? '' : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].issued_date)).toLocaleString().split(',')[0],
          check                          : lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length == 0 ? '' : lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_num,
          //amout_paid                     : lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length == 0 ? '' : Intl.NumberFormat('en-US').format(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_proceeds) + "  " + (lstMarketDetail[0].contract_flex_mkt_pricings[i].rtp_ind == 'Y' ? 'R-T-P' : '') 
          amout_paid : lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length == 0 ? '' : Number(lstMarketDetail[0].contract_flex_mkt_pricings[i].rebate_value).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + "  " + (lstMarketDetail[0].contract_flex_mkt_pricings[i].rtp_ind == 'Y' ? 'R-T-P' : '')
        })
      }
    }
    
    let flag = getData(thisObj, 'overFlowFlag');
    
    if(flag){
      if(contractFlexMktPricingList.length > 12){
        alert("Overflow of WHs reciept")
        setData(thisObj, 'overFlowFlag', false)
      }
    }
      
    if (contractFlexMktPricingList.length != 0) {
      
      var dblPricedLbs   = 0
      var dblRebateValue = 0
      var dblPaidValue   = 0
      for(var i=0; i<lstMarketDetail[0].contract_flex_mkt_pricings.length; i++){
        if(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length != 0){
          dblPaidValue += Number(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_proceeds)
        }
      }
      for (var i = 0; i < contractFlexMktPricingList.length; i++) {
        contractFlexMktPricing += `
            <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> 
              <td style="color: black;">${contractFlexMktPricingList[i].contract_pricing} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].pricing_status} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].receipt} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].priced_pounds} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].pricing_date} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].price_posting_date} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].market_election} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].market_price_per_lb} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].rebate_per_ton_basis_grade} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].rebate_value_for_priced_lbs} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].posted_price} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].producer_GPC_loan_gain_per_ton} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].loan_gain_priced_lbs} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].data_paid} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].check} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].amout_paid} </td>
            </tr>`
            if(contractFlexMktPricingList[i].pricing_status != "Voided"){
              dblPricedLbs   += Number(parseFloat(contractFlexMktPricingList[i].priced_pounds.replaceAll(',', '')))
              dblRebateValue += Number(parseFloat(contractFlexMktPricingList[i].rebate_value_for_priced_lbs.replaceAll(',', '')))              
            }
      }
      dblPricedLbs   = dblPricedLbs   == 0 ? '' : (dblPricedLbs).toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2})
      dblRebateValue = dblRebateValue == 0 ? '' : (dblRebateValue).toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2})
      
      dblPaidValue   = Intl.NumberFormat('en-US').format(dblPaidValue) //(dblPaidValue).toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2})

      contractFlexMktPricing += `
            <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> 
              <td colspan="16"> <hr style="width: 100%;"> </td>
            </tr>
            <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> 
              <td style="color: black;">Totals: </td>
              <td style="color: black;"></td>
              <td style="color: black;"></td>
              <td style="color: black;">${dblPricedLbs}</td>
              <td style="color: black;"></td>
              <td style="color: black;"></td>
              <td style="color: black;"></td>
              <td style="color: black;"></td>
              <td style="color: black;"></td>
              <td style="color: black;">${dblRebateValue}</td>
              <td style="color: black;"></td>
              <td style="color: black;"></td>
              <td style="color: black;"></td>
              <td style="color: black;"></td>
              <td style="color: black;"></td>
              <td style="color: black;">${dblPaidValue}</td>
            </tr>`
      contractFlexMktPricing += `</table> `      
    }
    else{ 
      
      contractFlexMktPricing += `<tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> 
          <td style="color: black;" colspan="16"> Totals: </td>
          </tr> </table>`
    }
    var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
    return `<html>
      <style>
      body:before{
        content: '${environmentType}';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;  
        color: #0d745e;
        font-size: 250px;
        font-weight: 500px;
        display: grid;
        justify-content: center;
        align-content: center;
        opacity: 0.35;
    }
        @media print {
          @page {  }
          html, body {
            height:100%; 
            margin: 0 !important; 
            padding: 0 !important;
          }
        }
      </style>

      <table style="align-items: center;width: 80%;font-size:80%;">
        <tr style="padding-left:1%; padding-right: 1%;">              
            <td style="padding-left:9%;text-align: center; padding-right: 1%;" colspan="10">
                <span style="font-size:130%;color: black;">
                    <b> ${workSheetObj.headingName} &nbsp; CONTRACT <br/>
                        ${workSheetObj.todayDate} <br/>
                    </b>
                </span>
            </td>              
        </tr>
    </table>
    <table style="padding-left: 0.5%;width: 90%;">
        <tr> <td style="padding-left:1%;font-size:85%;color: black;" colspan="5"> Vendor : <u> &nbsp; ${workSheetObj.vendorNumber}  </u> </td> </tr>
        <tr> <td style="padding-left:1%;font-size:85%;color: black;" colspan="5"> <u> ${workSheetObj.vendorName} &nbsp; &nbsp; </u> </td> </tr>
        <tr> <td style="padding-left:1%;font-size:85%;color: black;" colspan="5"> <u> ${workSheetObj.vendorAddress1} &nbsp; ${workSheetObj.vendorAddress2} &nbsp; &nbsp; </u> </td> </tr>
        <tr> <td style="padding-left:1%;font-size:85%;color: black;" colspan="5"> <u> ${workSheetObj.vendorCity} &nbsp; ${workSheetObj.vendorState} &nbsp; ${workSheetObj.vendorZip}  </u> <br/> <br/> </td> </tr>
    </table>
      
      <table style="align-items: center;width: 90%;font-size:80%;padding-left: 1.5%;">
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;">
              <td style="padding-left:1%;padding-right: 1%;color: black;"> CONTRACT   <br> NO.</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> CROP       <br> YEAR</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> CONTRACT   <br> &nbsp; APPROVED DATE.</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> PEANUT     <br> TYPE</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> <br/>           SEG</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> BUY POINT  <br> LOCATION</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> LOAN REPAY <br> METHOD</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> MARKETING  <br> PERIOD &nbsp; (WKS)</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> MULTIPLE   <br> PRICING</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> PRICING    <br> TYPE &nbsp; DESC.</td>
          </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;">
              <td style="padding-left: 1%;"colspan="10"> <hr style="width: 100%;"> </td>
          </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;">
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.contractNumber} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.cropYear} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.contApprDate} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.peanutType} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.seg} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.buyingPtLoc} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.loanRepayMethod} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.marketingPeriod} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.multiplePricing} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.pricingTypeDesc} </td> 
          </tr>
      </table>
      <br/>
      <table style="align-items: center;width: 80%;font-size:80%;padding-left: 2%;">
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> <td colspan="7"> ORIGINAL &nbsp; CONTRACT &nbsp;LBS. &nbsp;: &nbsp;                       ${workSheetObj.orgContractLBS} </td> </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> <td colspan="7"> CONTRACT &nbsp; LBS. &nbsp; NOT &nbsp;DELIVERED. &nbsp;: &nbsp;                 ${workSheetObj.contractLBSNotDel} </td> </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> <td colspan="7"> DELIVERED &nbsp;LBS. &nbsp;: &nbsp;                               ${workSheetObj.delLBS} </td> </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> <td colspan="7"> DELIVERED &nbsp;LBS. &nbsp;WITHOUT &nbsp;A &nbsp;LOAN &nbsp;: &nbsp;                ${workSheetObj.delLBSWithOutLoan} </td> </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> <td colspan="7"> DELIVERED &nbsp;LBS. &nbsp;WITH &nbsp;A &nbsp;LOAN &nbsp;: &nbsp;                   ${workSheetObj.delLBSWithLoan} </td> </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> <td colspan="7"> LOAN &nbsp;LBS. &nbsp;PRICED &nbsp;YTD (&nbsp;INCLUDING &nbsp;THIS &nbsp;PRICING&nbsp;) &nbsp;: &nbsp;${workSheetObj.loanLBSPriced} </td> </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> <td colspan="7"> LOAN &nbsp; LBS. &nbsp; NOT PRICED &nbsp;: &nbsp;                         ${workSheetObj.loanLBSNotPriced} </td> </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> <td colspan="7"> END OF MARKET &nbsp; PERIOD &nbsp;: &nbsp;                         ${workSheetObj.endMarketPeriod} </td> </tr> 
      </table>`
      + open_whs_rcpt + contractFlexMktPricing
  }

  function getFloorWorkSheet(workSheetObj) {    
    var open_whs_rcpt = ''
    
    var openWhsRcptList = []
    for(var i=0 ; i< lstMarketDetail[0].open_whs_rcpts.length; i++){
      let openPound = Intl.NumberFormat('en-US').format(lstMarketDetail[0].open_whs_rcpts[i].open_pounds);
      openWhsRcptList.push({
        'whs_rcpt_bp'  : lstMarketDetail[0].open_whs_rcpts[i].whs_rcpt_bp,
        'whs_rcpt_num' : lstMarketDetail[0].open_whs_rcpts[i].whs_rcpt_num,
        'open_pounds'  : openPound
      })
    }
    
    
    var contractFlexMktPricing = `<table style="align-items: center;width: 100%;font-size:80%;padding-left: 2%;">
    <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;">
        <td style="color: black;"> <br/> <br/> <br/> <br/> CONTRACT  <br/> PRICING #  </td> 
        <td style="color: black;"> <br/> <br/> <br/> <br/> PRICING   <br/> STATUS  </td>
        <td style="color: black;"> <br/> <br/> <br/> <br/> SC95 / 1007 </td> 
        <td style="color: black;"> <br/> <br/> <br/> <br/> POUNDS <br/> PRICED   </td> 
        <td style="color: black;"> <br/> <br/> <br/> <br/> DATE   OF <br/> ELECTION   </td> 

        <td style="color: black;"> <br/> <br/> <br/> <br/> POSTED <br/> PRICE   </td> 
        <td style="color: black;"> <br/> <br/> <br/> <br/> POSTED <br/> DATE </td>
        <td style="color: black;"> <br/> <br/> <br/> <br/> OPTION/TON <br/>(BASIS GRADE) </td> 
        <td style="color: black;"> <br/> <br/> <br/> <br/>OPTION  <br/> VALUE </td> 
        
        <td style="color: black;"> <br/> <br/> <br/> <br/> DATE <br/> PAID </td>
        <td style="color: black;"> <br/> <br/> <br/> <br/>  <br/>CHECK   # </td> 
        <td style="color: black;"> <br/> <br/> <br/> <br/> <br/> AMOUNT PAID </td> 
    </tr>`+
      `<tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;">
        <td style="padding-left: 0%;color: black;"colspan="12"> <hr style="width: 100%;"> </td>
    </tr>`

    var contractFlexMktPricingList = []
    var contractPricingList = []
    
    if (commonContext.getFlexMktPrint_ContractPricingList != undefined || commonContext.getFlexMktPrint_ContractPricingList != null) {
      contractPricingList = commonContext.getFlexMktPrint_ContractPricingList;
      
      var dict = { 'P': 'Pending', 'S': 'Signed', 'A': 'Approved', 'V': 'Voided' }

      if (contractPricingList.colBtnWhsRcpts == '-') {        
        for (var i = 0; i < contractPricingList.length; i++) {
          contractFlexMktPricingList.push({
            contract_pricing: contractPricingList[i].colTxtPricingNum,
            pricing_status: contractPricingList[i].colTxtStatus,
            receipt: contractPricingList[i].colBtnWhsRcpts == '-' ? '' : contractPricingList[i].colBtnWhsRcpts,
            priced_pounds: contractPricingList[i].colTxtPounds,
            pricing_date: contractPricingList[i].colTxtPricingDate,
            price_posting_date: '',
            market_election: contractPricingList[i].colTxtElection,
            market_price_per_lb: contractPricingList[i].colTxtMarketPrice,
            rebate_per_ton_basis_grade: contractPricingList[i].colTxtRebateRate,
            rebate_value_for_priced_lbs: '',
            posted_price: '',
            producer_GPC_loan_gain_per_ton: '',
            loan_gain_priced_lbs: '',
            data_paid: '',
            check: '',
            amout_paid: ''
          })
        }
      }
      else {        
        if (contractPricingList != null && contractPricingList != undefined) {          
          if (contractPricingList.length != 0 && lstMarketDetail[0].contract_flex_mkt_pricings[0].length != 0) {           
            var obj = {
              contract_pricing: lstMarketDetail[0].contract_flex_mkt_pricings[i].contract_num + lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_suffix,
              pricing_status: dict[lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_status],
              receipt: lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_bp + '-' + lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_num,
              priced_pounds: lstMarketDetail[0].contract_flex_mkt_pricings[i].priced_pounds,
              pricing_date: (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_date)).toLocaleString().split(',')[0],
              price_posting_date: lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date == '' ? '' : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date)).toLocaleString().split(',')[0],
              market_election: lstMarketDetail[0].contract_flex_mkt_pricings[i].market_election,
              market_price_per_lb: '',
              rebate_per_ton_basis_grade: '',
              rebate_value_for_priced_lbs: '',
              posted_price: lstMarketDetail[0].contract_flex_mkt_pricings[i].posted_price,
              producer_GPC_loan_gain_per_ton: '',
              loan_gain_priced_lbs: '',
              data_paid: '',
              check: '',
              amout_paid: '',
            }
            if (lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length != 0) {
              obj.data_paid = (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].issued_date)).toLocaleString().split(',')[0]
              obj.check = lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_num
              obj.amout_paid = (lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_proceeds).toLocaleString("en-US") + "  " + (lstMarketDetail[0].contract_flex_mkt_pricings[i].rtp_ind == 'Y' ? 'R-T-P' : '') 
            }
            contractFlexMktPricingList.push(obj)
          }
          else{            
            for(var i=0; i<lstMarketDetail[0].contract_flex_mkt_pricings.length; i++){
              contractFlexMktPricingList.push({
                contract_pricing: lstMarketDetail[0].contract_flex_mkt_pricings[i].contract_num + lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_suffix,
                pricing_status: dict[lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_status],          
                receipt: lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_bp + '-' + lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_num,
                priced_pounds: lstMarketDetail[0].contract_flex_mkt_pricings[i].priced_pounds,
                pricing_date: (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_date)).toLocaleString().split(',')[0],
                price_posting_date: lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date == '' ? '' : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date)).toLocaleString().split(',')[0],
                market_election: lstMarketDetail[0].contract_flex_mkt_pricings[i].market_election,
                market_price_per_lb: lstMarketDetail[0].contract_flex_mkt_pricings[i].market_price,
                rebate_per_ton_basis_grade: lstMarketDetail[0].contract_flex_mkt_pricings[i].rebate_rate,
                rebate_value_for_priced_lbs: lstMarketDetail[0].contract_flex_mkt_pricings[i].rebate_value,
                posted_price: lstMarketDetail[0].contract_flex_mkt_pricings[i].posted_price,
                producer_GPC_loan_gain_per_ton: '',
                loan_gain_priced_lbs: '',
                data_paid: lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length == 0 ? '' : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].issued_date)).toLocaleString().split(',')[0],
                check: lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length == 0 ? '' : lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_num,
                amout_paid: lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length == 0 ? '' : (lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_proceeds).toLocaleString("en-US") + "  " + (lstMarketDetail[0].contract_flex_mkt_pricings[i].rtp_ind == 'Y' ? 'R-T-P' : '') 
              })
            }

          }
        }
        else {          
          for (var i = 0; i < lstMarketDetail[0].contract_flex_mkt_pricings.length; i++) {
            obj = {
              contract_pricing: lstMarketDetail[0].contract_flex_mkt_pricings[i].contract_num + lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_suffix,
              pricing_status: dict[lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_status],
              receipt: lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_bp + '-' + lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_num,
              priced_pounds: lstMarketDetail[0].contract_flex_mkt_pricings[i].priced_pounds,
              pricing_date: (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_date)).toLocaleString().split(',')[0],
              price_posting_date: lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date == '' ? '' : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date)).toLocaleString().split(',')[0],
              market_election: lstMarketDetail[0].contract_flex_mkt_pricings[i].market_election,
              market_price_per_lb: '',
              rebate_per_ton_basis_grade: '',
              rebate_value_for_priced_lbs: '',
              posted_price: lstMarketDetail[0].contract_flex_mkt_pricings[i].posted_price,
              producer_GPC_loan_gain_per_ton: '',
              loan_gain_priced_lbs: '',
              data_paid: '',
              check: '',
              amout_paid: '',
            }
            
            if (lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length != 0) {
              let amtPaid = (lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_proceeds).toLocaleString("en-US") 

              obj.data_paid = (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].issued_date)).toLocaleString().split(',')[0]
              obj.check = lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_num
              obj.amout_paid = amtPaid + "  " + (lstMarketDetail[0].contract_flex_mkt_pricings[i].rtp_ind == 'Y' ? 'R-T-P' : '') 
            }
            contractFlexMktPricingList.push(obj)
          }
        }
      }
    }
    else {
      var dict = { 'P': 'Pending', 'S': 'Signed', 'A': 'Approved', 'V': 'Voided' }
      
      for(var i=0; i<lstMarketDetail[0].contract_flex_mkt_pricings.length; i++){        
        let lbsPriced = Intl.NumberFormat('en-US').format(lstMarketDetail[0].contract_flex_mkt_pricings[i].priced_pounds);
        let rebateValueForPricedLbs = Intl.NumberFormat('en-US').format(lstMarketDetail[0].contract_flex_mkt_pricings[i].rebate_value);

        contractFlexMktPricingList.push({
          contract_pricing               : lstMarketDetail[0].contract_flex_mkt_pricings[i].contract_num + lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_suffix,
          pricing_status                 : dict[lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_status],          
          receipt                        : lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_bp + '-' + lstMarketDetail[0].contract_flex_mkt_pricings[i].whs_rcpt_num,
          priced_pounds                  : lbsPriced, 
          pricing_date                   : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].pricing_date)).toLocaleString().split(',')[0],
          price_posting_date             : lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date == '' ? '' : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].price_posting_date)).toLocaleString().split(',')[0],
          market_election                : lstMarketDetail[0].contract_flex_mkt_pricings[i].market_election,
          market_price_per_lb            : lstMarketDetail[0].contract_flex_mkt_pricings[i].market_price,
          rebate_per_ton_basis_grade     : lstMarketDetail[0].contract_flex_mkt_pricings[i].rebate_rate,
          rebate_value_for_priced_lbs    : parseFloat(rebateValueForPricedLbs).toFixed(2), 
          posted_price                   : lstMarketDetail[0].contract_flex_mkt_pricings[i].posted_price,
          producer_GPC_loan_gain_per_ton : '',
          loan_gain_priced_lbs           : '',
          data_paid                      : lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length == 0 ? '' : (new Date(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].issued_date)).toLocaleString().split(',')[0],
          check                          : lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length == 0 ? '' : lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_num,
          amout_paid                     : lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length == 0 ? '' : Intl.NumberFormat('en-US').format(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_proceeds) + "  " + (lstMarketDetail[0].contract_flex_mkt_pricings[i].rtp_ind == 'Y' ? 'R-T-P' : '') 
        })
      }
    }
    
    let flag = getData(thisObj, 'overFlowFlag');
    
    if(flag){
      if(contractFlexMktPricingList.length > 12){
        alert("Overflow of WHs reciept")
        setData(thisObj, 'overFlowFlag', false)
      }
    }
    
    if (contractFlexMktPricingList.length != 0) {
      
      var dblPricedLbs   = 0
      var dblRebateValue = 0
      var dblPaidValue   = 0
      for(var i=0; i<lstMarketDetail[0].contract_flex_mkt_pricings.length; i++){
        if(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities.length != 0){
          dblPaidValue += Number(lstMarketDetail[0].contract_flex_mkt_pricings[i].payment_activities[0].check_proceeds)
        }
      }
      for (var i = 0; i < contractFlexMktPricingList.length; i++) {
        contractFlexMktPricing += `
            <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> 
              <td style="color: black;">${contractFlexMktPricingList[i].contract_pricing} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].pricing_status} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].receipt == '-' ? '' : contractFlexMktPricingList[i].receipt} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].priced_pounds} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].pricing_date} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].posted_price} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].price_posting_date} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].rebate_per_ton_basis_grade} </td>
              <td style="color: black;">${(contractFlexMktPricingList[i].rebate_value_for_priced_lbs).toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2})} </td>              
              <td style="color: black;">${contractFlexMktPricingList[i].data_paid} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].check} </td>
              <td style="color: black;">${contractFlexMktPricingList[i].amout_paid} </td>
            </tr>`
            if(contractFlexMktPricingList[i].pricing_status != "Voided"){
              dblPricedLbs   += Number(parseFloat(contractFlexMktPricingList[i].priced_pounds.replaceAll(',', '')))
              dblRebateValue += Number(parseFloat(contractFlexMktPricingList[i].rebate_value_for_priced_lbs.replaceAll(',', '')))              
            }
      }
      dblPricedLbs   = dblPricedLbs   == 0 ? '' : dblPricedLbs //(dblPricedLbs).toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2})
      dblRebateValue = dblRebateValue == 0 ? '' : (dblRebateValue).toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2})
      
      dblPaidValue   = dblPaidValue == 0 ? '' : Intl.NumberFormat('en-US').format(dblPaidValue) //(dblPaidValue).toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2})

      contractFlexMktPricing += `
            <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> 
              <td colspan="12"> <hr style="width: 100%;"> </td>
            </tr>
            <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> 
              <td style="color: black;">Totals: </td>
              <td style="color: black;"></td>
              <td style="color: black;"></td>
              <td style="color: black;">${dblPricedLbs}</td>
              <td style="color: black;"></td>
              <td style="color: black;"></td>
              <td style="color: black;"></td>
              <td style="color: black;">${dblRebateValue}</td>
              <td style="color: black;"></td>
                <td style="color: black;"></td>
              <td style="color: black;"></td>
              <td style="color: black;">${dblPaidValue}</td>
            </tr>`
      contractFlexMktPricing += `</table> `      
    }
    else{      
      contractFlexMktPricing += `<tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> 
          <td style="color: black;" colspan="12"> Totals: </td>
          </tr> </table>`
    }
    var environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
    return `<html>
      <style>
      body:before{
        content: '${environmentType}';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;  
        color: #0d745e;
        font-size: 250px;
        font-weight: 500px;
        display: grid;
        justify-content: center;
        align-content: center;
        opacity: 0.35;
    }
        @media print {
          @page {  }
          html, body {
            height:100%; 
            margin: 0 !important; 
            padding: 0 !important;
          }
        }
      </style>

      <table style="align-items: center;width: 80%;font-size:80%;">
        <tr style="padding-left:1%; padding-right: 1%;">              
            <td style="padding-left:9%;text-align: center; padding-right: 1%;" colspan="10">
                <span style="font-size:130%;color: black;">
                    <b> ${workSheetObj.headingName} &nbsp; CONTRACT PRICINGS <br/>
                        ${workSheetObj.todayDate} <br/>
                    </b>
                </span>
            </td>              
        </tr>
    </table>
    <table style="padding-left: 0.5%;width: 90%;">
        <tr> <td style="padding-left:1%;font-size:85%;color: black;" colspan="5"> Vendor : <u> &nbsp; ${workSheetObj.vendorNumber}  </u> </td> </tr>
        <tr> <td style="padding-left:1%;font-size:85%;color: black;" colspan="5"> <u> ${workSheetObj.vendorName} &nbsp; &nbsp; </u> </td> </tr>
        <tr> <td style="padding-left:1%;font-size:85%;color: black;" colspan="5"> <u> ${workSheetObj.vendorAddress1} &nbsp; ${workSheetObj.vendorAddress2} &nbsp; &nbsp; </u> </td> </tr>
        <tr> <td style="padding-left:1%;font-size:85%;color: black;" colspan="5"> <u> ${workSheetObj.vendorCity} &nbsp; ${workSheetObj.vendorState} &nbsp; ${workSheetObj.vendorZip}  </u> <br/> <br/> </td> </tr>
    </table>
      
      <table style="align-items: center;width: 90%;font-size:80%;padding-left: 1.5%;">
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;">
              <td style="padding-left:1%;padding-right: 1%;color: black;"> CONTRACT   <br> NO.</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> CROP       <br> YEAR</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> CONTRACT   <br> &nbsp; APPROVED DATE.</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> PEANUT     <br> TYPE</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> <br/>           SEG</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> BUY POINT  <br> LOCATION</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> MULTIPLE   <br> PRICING</td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> PRICING    <br> TYPE &nbsp; DESC.</td>
          </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;">
              <td style="padding-left: 1%;"colspan="10"> <hr style="width: 100%;"> </td>
          </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;">
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.contractNumber} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.cropYear} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.contApprDate} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.peanutType} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.seg} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.buyingPtLoc} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.multiplePricing == 'Y' ? 'Yes' : 'No'} </td>
              <td style="padding-left:1%;padding-right: 1%;color: black;"> ${workSheetObj.pricingTypeDesc} </td> 
          </tr>
      </table>
      <br/>
      <table style="align-items: center;width: 80%;font-size:80%;padding-left: 2%;">
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> <td colspan="7"> ORIGINAL &nbsp; CONTRACT &nbsp;LBS. &nbsp;: &nbsp;                       ${workSheetObj.orgContractLBS} </td> </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> <td colspan="7"> CONTRACT &nbsp; LBS. &nbsp; NOT &nbsp;DELIVERED. &nbsp;: &nbsp;                 ${workSheetObj.contractLBSNotDel} </td> </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> <td colspan="7"> DELIVERED &nbsp;LBS. &nbsp;: &nbsp;                               ${workSheetObj.delLBS} </td> </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> <td colspan="7"> POUNDS &nbsp;PRICED PRICED &nbsp;YTD (&nbsp;INCLUDING &nbsp;THIS &nbsp;PRICING&nbsp;) &nbsp;: &nbsp;${workSheetObj.loanLBSPriced} </td> </tr>
          <tr style="padding-left:1%; padding-right: 1%;text-align: left;color: black;"> <td colspan="7"> POUND &nbsp; NOT PRICED &nbsp;: &nbsp;                         ${workSheetObj.loanLBSNotPriced} </td> </tr>
      </table>`
      + open_whs_rcpt + contractFlexMktPricing
  }
  
  function getFileName() {
    var printType = commonContext.ContractManagement_FlexMktPrint == '' ? getData(thisObj, 'printType') : commonContext.ContractManagement_FlexMktPrint;
    contract_num = commonContext.getContractProfile_ContractNumber;
    price_suffix = commonContext.getFlexMktPrint_PriceSuffix;
    if (printType == printWorksheet) {
      return 'PE_WS_' + cropYear + "_" + contract_num
    }
    else if (printType == printPricingContract) {
      return 'PE_' + cropYear + "_" + contract_num + "_I"
    }
    else {
      return "PDF_" + cropYear + "_" + contract_num + "_" + price_suffix
    }
  }

  const getBlobURL = (code, type) => {
    const blob = new Blob([code], { type });
    return URL.createObjectURL(blob)
  }

  const onbtnPrintClick = async () => {
    try {
      //pricing_line = commonContext.getFlexMktPrint_PriceLine
      //price_suffix = commonContext.getFlexMktPrint_PriceSuffix
      let contract_num = getData(thisObj, 'contract_num')
      let response = await ContractManagementService.RetrieveContractDetails(contract_num, 'PN1010')
      pricing_line = response[0].contract_pricings[0].pricing_line
      price_suffix = response[0].contract_pricings[0].firm_basis
      lstContract = response[0]
      lstMarketPriceElection = await ContractManagementService.RetrieveFlexMarketPriceElectionPrint(contract_num, pricing_line, price_suffix)
      lstMarketDetail = await ContractManagementService.RetrieveFlexMarketDetails(contract_num)
      
      var code = await GetHtmlCode(58, 90, '<br/> <br/>')
      var type = 'text/html'
      const dataUrl1 = window.URL.createObjectURL(new Blob([code], { type }));
      const pdfWindow = window.open(dataUrl1, '', 'width=1000,height=850,scrollbars=1,menuBar=1')
      pdfWindow.focus()
      pdfWindow.addEventListener('beforeprint', event => {

      })
      pdfWindow.print()
      pdfWindow.addEventListener('afterprint', (event) => {
        //pdfWindow.close() //Commented this line as PDF window was getting closed before print operation
      })
    }
    catch (err) {
      if (err instanceof EvalError) {
        alert(err.message);
      } else {
        alert("Exception in PreSubmit Event code for widget:btnPrint event:Click");
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const onbtnSaveAsPdfClick = () => {
    try {
      var renderText = document.getElementById('docFrame').contentWindow.document.body;
      var fileName = getFileName()
      var pdfWidth = 250;
      var pdfWindowWidth = 1000;
      var marginArr = [5, 5, 5, 5]
      if (commonContext.getFlexMktPrint_PrintType == printWorksheet) {
        pdfWidth = 270;
        pdfWindowWidth = 1050;
        marginArr = [5, 1, 5, 1]
      }
      var doc = new jsPDF('p', 'mm', 'a4');
      doc.addFont({ fontStyle: "Arial" })
      doc.setTextColor("black")
      doc.html(renderText, {
        callback: function (doc) {
          if (commonContext.getFlexMktPrint_PrintType == printWorksheet) {
            doc.setFontSize(8)
            doc.text('Page 1 of 1', 90, 280)
          }
          doc.save(fileName + '.pdf')
        },
        margin: marginArr,
        autoPaging: 'text',
        x: 0,
        y: 0,
        width: pdfWidth,
        windowWidth: pdfWindowWidth
      });
    }
    catch (err) {
      if (err instanceof EvalError) {
        alert(err.message);
      } else {
        alert("Exception in PreSubmit Event code for widget:btnSaveAsPdf event:Click");
      }
    }
  }
  thisObj.onbtnSaveAsPdfClick = onbtnSaveAsPdfClick;

  const onbtnCloseClick = () => {
    try {
      setData(thisObj, 'screen_reload_print', true)
      document.getElementById("ContractManagement_FlexMktPrintPopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError) {
        alert(err.message);
      } else {
        alert("Exception in PreSubmit Event code for widget:btnClose event:Click");
      }
    }
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />

            <ContractManagement_header headerData={state.headerData} />
            <h1 className="pageHeader">{state.Label}</h1>
            <Form noValidate className="row">

              <iframe src={state.renderUrl} id="docFrame" width="100%" height="500px" style={{background:"White"}}></iframe>

              <GroupBoxWidget
                conf={state.groupboxwidget0}
                screenConf={state}
              >
                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnSaveAsPdf}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
              </GroupBoxWidget>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}

export default withRouter(ContractManagement_FlexMktPrint);