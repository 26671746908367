/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  DateWidget,
  getValue,
  setValue,
  getData,
  setData,
  goTo,
  disable,
} from "../../shared/WindowImports";

import "./LoanReductionProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_LoanReductionProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "LoanReductionProfile",
    windowName: "LoanReductionProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.LoanReductionProfile",
    // START_USER_CODE-USER_LoanReductionProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Loan Reduction Profile",
      scrCode: "PN0330B",
    },
    // END_USER_CODE-USER_LoanReductionProfile_PROPERTIES
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtEffectiveDate: {
      name: "txtEffectiveDate",
      type: "DateTimeWidget",
      parent: "grpbxLoanReductionProfile",
      Label: "Effective Date:",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_txtEffectiveDate_PROPERTIES
    },
    txtReductionRate: {
      name: "txtReductionRate",
      type: "TextBoxWidget",
      parent: "grpbxLoanReductionProfile",
      Label: "Reduction Rate:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReductionRate_PROPERTIES

      // END_USER_CODE-USER_txtReductionRate_PROPERTIES
    },
    grpbxLoanReductionProfile: {
      name: "grpbxLoanReductionProfile",
      type: "GroupBoxWidget",
      parent: "LoanReductionProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxLoanReductionProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxLoanReductionProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "LoanReductionProfile",
      Height: "",
      Width: "",
      clonedExtId: "44193",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  //FormLoad function
  const FormLoad = () => {
    try {
      let profileData = getData(thisObj, "LoanReductionProfile");
      let action_type = profileData.cmdOk_Caption;
      if (action_type == "Add") {
        document.getElementById("btnOk").innerText = "Add";
      } else if (action_type == "Update") {
        disable(thisObj, "txtEffectiveDate");
        document.getElementById("btnOk").innerText = "Update";
        setValue(thisObj, "txtEffectiveDate", profileData.txtEffectiveDateTime);
        setValue(thisObj, "txtReductionRate", profileData.txtReductionRate);
        setValue(thisObj, "lblAddedByValue", profileData.lblAddedByCaption);
        setValue(thisObj, "lblChangedByValue", profileData.lblChangedByCaption);
        document.getElementById("txtReductionRate").focus();
      }
    } catch (err) {
      errorHandler(err);
    }
  };
  //Code for Cancel button
  const onbtnCancelClick = () => {
    try {
      document
        .getElementById(
          "SystemMaintenanceSpecialFunctions_LoanReductionProfilePopUp"
        )
        .childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        alert(err.message);
      } else {
        alert(
          "Exception in PreSubmit Event code for widget:btnExit event:Click"
        );
      }
    }
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  //formValid for validations
  const FormValid = () => {
    try {
      var bformValid = false;
      let txtEffectivedate = getValue(thisObj, "txtEffectiveDate");
      const dateTime = new Date(txtEffectivedate);
      const formatDate = moment(dateTime, "yyyy-MM-dd HH:mm:ss.SSS").isValid();
      if (formatDate == false) {
        alert("Invalid Effective Date Time!!!");
        return bformValid;
      }
      let txtReductionRate = getValue(thisObj, "txtReductionRate");
      if (
        !/^\$?\d+(\.\d+)?$/.test(txtReductionRate) ||
        txtReductionRate < 0 ||
        txtReductionRate > 100
      ) {
        alert("Reduction Rate must be of format ###.##");
        return bformValid;
      }
      bformValid = true;
    } catch (err) {
      errorHandler(err);
    }
  };

  //Function Errorhandler
  function errorHandler(err) {
    showMessage(thisObj, err.message);
  }

  //Add and Update button function
  const onbtnOkClick = async () => {
    try {
      if (FormValid() == false) {
        return;
      }
      let profile_effective_date1 = getValue(thisObj, "txtEffectiveDate");
      const dateTime = new Date(profile_effective_date1);
      const effect_date_time = moment(dateTime).format(
        "yyyy-MM-DD HH:mm"
      );
      let loan_reduct_rate1 = getValue(thisObj, "txtReductionRate");
      let loan_reduct_rate;
      if (loan_reduct_rate1.includes("$")) {
        let loan_reduct_rate3 = parseFloat(loan_reduct_rate1.replace("$", ""));
        loan_reduct_rate = Number(loan_reduct_rate3).toFixed(2);
      } else {
        let loan_reduct_rate2 = parseFloat(loan_reduct_rate1);
        loan_reduct_rate = Number(loan_reduct_rate2).toFixed(2);
      }
      const useridFromLS = () => sessionStorage.getItem("userid") || "";
      let user_id = useridFromLS();
      let profileData = getData(thisObj, "LoanReductionProfile");
      let action_type = profileData.cmdOk_Caption;
      if (action_type == "Add") {
        let data = {
          effect_date_time: effect_date_time,
          loan_reduct_rate: loan_reduct_rate,
        };
        let response;
        response = await SystemMaintenanceSpecialFunctionsService.CreateLoanReductionControl(
          data
        );
        if (![200, 400, 404].includes(response.status)) {
          alert(
            "An error occured while communicating or updating with the database. Please contact your system administrator if the problem persist.\n\nError:\n\n" +
              response.message.slice(69)
          );
        } else {
          if (response.status == 200) {
            let Loan =((effect_date_time).replace(" ","T")).concat(":00")
        setData(thisObj, "frmLoanReductionSetupSetup",Loan );
            setData(thisObj, "frmLoanReductionSetupSetup", data);
            goTo(
              thisObj,
              "SystemMaintenanceSpecialFunctions_LoanReductionProfilePopUp"
            );
          } else {
            showMessage(thisObj, response.message);
          }
        }
      } else if (action_type == "Update") {
        let data = {
          user_id: user_id,
          loan_reduct_rate: loan_reduct_rate,
        };

        let response = await SystemMaintenanceSpecialFunctionsService.UpdateLoanReductionControl(
          effect_date_time,
          data
        );
        if (![200, 400, 404].includes(response.status)) {
          showMessage(
            thisObj,
            "An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists."
          );
        } else {
          if (response.status == 200) {
          } else {
            showMessage(thisObj, response.message);
          }
        }
        let Loan = {
          effect_date_time : ((profileData.txtEffectiveDateTime).replace(" ","T")).slice(0, -4)
        }
        setData(thisObj, "frmLoanReductionSetupSetup",Loan );
        goTo(
          thisObj,
          "SystemMaintenanceSpecialFunctions_LoanReductionProfilePopUp"
        );
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during Add Button Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnOkClick = onbtnOkClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName +
              "-" +
              state.windowName +
              " col-lg-12" +
              " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={(vlaues) => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_LoanReductionProfile*/}

              {/* END_USER_CODE-USER_BEFORE_LoanReductionProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxLoanReductionProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxLoanReductionProfile*/}

              <GroupBoxWidget
                conf={state.grpbxLoanReductionProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                <DateWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEffectiveDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_AFTER_txtEffectiveDate*/}
                {/* START_USER_CODE-USER_BEFORE_txtReductionRate*/}

                {/* END_USER_CODE-USER_BEFORE_txtReductionRate*/}

                <TextBoxWidget
                  onChange={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtReductionRate}
                  screenConf={state}
                  onPaste={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtReductionRate*/}

                {/* END_USER_CODE-USER_AFTER_txtReductionRate*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxLoanReductionProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxLoanReductionProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_LoanReductionProfile*/}

              {/* END_USER_CODE-USER_AFTER_LoanReductionProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_LoanReductionProfile
);
