/* eslint-disable*/
import React from "react";
import ButtonWidget from "./ButtonWidget";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";

describe("ButtonWidget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let buttonwidget1 = {
        name: "buttonwidget1",
        type: "ButtonWidget",
        parent: "commandcontainerwidget1",
        Label: "Ok",
        CharWidth: "8",
        Mandatory: true,
        variant: "primary",
      };
      let buttonwidget2 = {
        name: "buttonwidget2",
        type: "ButtonWidget",
        parent: "commandcontainerwidget1",
        Label: "Submit",
        CharWidth: "8",
        Mandatory: true,
        Category: "SubmitPage",
      };
      let buttonwidget3 = {
        name: "buttonwidget3",
        type: "ButtonWidget",
        parent: "commandcontainerwidget1",
        Label: "Cancel",
        CharWidth: "8",
        Mandatory: true,
        Category: "Cancel",
      };
      let buttonwidget4 = {
        name: "buttonwidget4",
        type: "ButtonWidget",
        parent: "commandcontainerwidget1",
        Label: "Default",
        CharWidth: "8",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      renderTestScreen(
        <div>
          <ButtonWidget conf={buttonwidget1} screenConf={stateScreenConf} />
          <ButtonWidget conf={buttonwidget2} screenConf={stateScreenConf} />
          );
          <ButtonWidget conf={buttonwidget3} screenConf={stateScreenConf} />
          );
          <ButtonWidget conf={buttonwidget4} screenConf={stateScreenConf} />
          );
        </div>
      );
    });
  });
  afterEach(cleanup);
  test("is Button Widget Loads Successfully", () => {
    expect(screen.getByText("Ok")).toBeInTheDocument;
    const buttonwidget1 = screen.getByTestId("buttonwidget1");
    expect(buttonwidget1).toBeInTheDocument;
    expect(buttonwidget1.textContent).toEqual("Ok");
  });
});
