/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  setData,
  getData,
  setValue,
  disable,
  getValue,
} from "../../shared/WindowImports";

import "./CollectionPointProfile.scss";


// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_CollectionPointProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "CollectionPointProfile",
    windowName: "CollectionPointProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.CollectionPointProfile",
    // START_USER_CODE-USER_CollectionPointProfile_PROPERTIES
    horizontalForm:true,
    headerData: {
     scrName: "Collection Point Profile",
     scrCode: "PN0020C",
   },
    // END_USER_CODE-USER_CollectionPointProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblArea: {
      name: "lblArea",
      type: "LabelWidget",
      parent: "grpbxCollectionpointDetails",
      Label: "Area:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblArea_PROPERTIES

      // END_USER_CODE-USER_lblArea_PROPERTIES
    },
    lblAreaValue: {
      name: "lblAreaValue",
      type: "LabelWidget",
      parent: "grpbxCollectionpointDetails",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAreaValue_PROPERTIES

      // END_USER_CODE-USER_lblAreaValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblCompany: {
      name: "lblCompany",
      type: "LabelWidget",
      parent: "grpbxCollectionpointDetails",
      Label: "Company:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCompany_PROPERTIES

      // END_USER_CODE-USER_lblCompany_PROPERTIES
    },
    lblCompanyValue: {
      name: "lblCompanyValue",
      type: "LabelWidget",
      parent: "grpbxCollectionpointDetails",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCompanyValue_PROPERTIES

      // END_USER_CODE-USER_lblCompanyValue_PROPERTIES
    },
    txtID: {
      name: "txtID",
      type: "TextBoxWidget",
      parent: "grpbxCollectionpointDetails",
      Label: "ID:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtID_PROPERTIES

      // END_USER_CODE-USER_txtID_PROPERTIES
    },
    grpbxCollectionpointDetails: {
      name: "grpbxCollectionpointDetails",
      type: "GroupBoxWidget",
      parent: "CollectionPointProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCollectionpointDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxCollectionpointDetails_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "CollectionPointProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    formLoad();
    // GetCompanyList()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
//formload function
  const formLoad =async () =>{
    let dataFrmParent  = getData(thisObj,"BPSetupCollectionPointData");
     if(dataFrmParent.btnOk=="Add" ){
          //Retriving Comapany Name
        ContractManagementService.RetrieveCompanyDetails().then(response => {
        let data = response;
        setValue(thisObj,"lblCompanyValue",data[0].compName)
        });
        setValue(thisObj,"lblAreaValue",dataFrmParent.lblArea)
        document.getElementById('btnOk').innerText = "Add";
      }
      else if(dataFrmParent.btnOk=="Update"){
          if(dataFrmParent.txtID_enabled == false){
                document.getElementById("txtID").disabled = true;
                document.getElementById("btnOk").disabled = true;
              }
              if(dataFrmParent.txtID_enabled == true){
                document.getElementById("txtID").disabled = false;
                document.getElementById("btnOk").disabled = false;
              }
              setValue(thisObj,"lblCompanyValue","Golden Peanut Company")
              setValue(thisObj,"txtID",dataFrmParent.txtID)
              setValue(thisObj,"lblAreaValue",dataFrmParent.lblArea)
              setValue(thisObj,"lblAddedByValue",dataFrmParent.lblAddedBy[0].lblAddedById+" "+dataFrmParent.lblAddedBy[0].txtDate
              )
              setValue(thisObj,"lblChangedByValue",dataFrmParent.lblChangedBy[0].lblChangedById+" "+dataFrmParent.lblChangedBy[0].txtDate
              )
              document.getElementById('btnOk').innerText = "Update";
          }
    }

  const onbtnOkClick=async ()=>{
    let dataFrmParent= getData(thisObj,"BPSetupCollectionPointData");
    if (getValue(thisObj, "txtID") ==null || getValue(thisObj, "txtID") =="" ) {
      showMessage(thisObj,'ID is a required field')
    }
    else{
      let cmdOk_Caption=dataFrmParent.btnOk;
      let cmd_id=getValue(thisObj,'txtID');
      if(cmdOk_Caption=='Add'){
        let data = {
          "coll_pt_id": cmd_id,
          "area_id": dataFrmParent.AreaId
        }
        let response=await SystemMaintenanceMasterManagementService.CreateCollectionPointControl(data);
        if (![200, 400, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
        }
        else{
          if(response.status==200){
            showMessage(thisObj,response.message,true)
          }
          else{
            showMessage(thisObj,response.message)
          }
        }
      }
      else if(cmdOk_Caption=='Update' && dataFrmParent.txtID_enabled == true){
        let data={
            "area_id": dataFrmParent.AreaId
        };
        let cmd_id_ByUser = getValue(thisObj,"txtID")
        let response= await SystemMaintenanceMasterManagementService.UpdateCollectionPointControl(cmd_id_ByUser,data);
        if (![200, 400, 404].includes(response.status)) {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.')
        }
        else{
          if(response.status==200){
            showMessage(thisObj,response.message,true)
          }
          else{
            showMessage(thisObj,response.message)
          }
         
        }
      }
      setData(thisObj, 'closingOfCollectionPointProfile', true)
      document.getElementById("SystemMaintenanceMasterManagement_CollectionPointProfilePopUp").childNodes[0].click()
    }
  }
  thisObj.onbtnOkClick= onbtnOkClick;
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceMasterManagement_CollectionPointProfilePopUp").childNodes[0].click()

      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_CollectionPointProfile*/}

              {/* END_USER_CODE-USER_BEFORE_CollectionPointProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCollectionpointDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCollectionpointDetails*/}

              <GroupBoxWidget
                conf={state.grpbxCollectionpointDetails}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblCompany*/}

                {/* END_USER_CODE-USER_BEFORE_lblCompany*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCompany}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCompany*/}

                {/* END_USER_CODE-USER_AFTER_lblCompany*/}
                {/* START_USER_CODE-USER_BEFORE_lblCompanyValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblCompanyValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCompanyValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCompanyValue*/}

                {/* END_USER_CODE-USER_AFTER_lblCompanyValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblArea*/}

                {/* END_USER_CODE-USER_BEFORE_lblArea*/}

                <LabelWidget
                  values={values}
                  conf={state.lblArea}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblArea*/}

                {/* END_USER_CODE-USER_AFTER_lblArea*/}
                {/* START_USER_CODE-USER_BEFORE_lblAreaValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAreaValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAreaValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAreaValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAreaValue*/}
                {/* START_USER_CODE-USER_BEFORE_txtID*/}

                {/* END_USER_CODE-USER_BEFORE_txtID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtID*/}

                {/* END_USER_CODE-USER_AFTER_txtID*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCollectionpointDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCollectionpointDetails*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_CollectionPointProfile*/}

              {/* END_USER_CODE-USER_AFTER_CollectionPointProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_CollectionPointProfile
);
