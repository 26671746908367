import { clsAddress } from "./clsAddress";

const BuyingPointInfoProps = {
    ID: '',
    Name: '',
    AreaID: '',
    PhysicalAddress: '',
    MailingAddress: '',
    Phone: '',
    GramEntryInd: false,
    AutoNumberInd: false,
    TransferGramEntryInd: false,
    TransferEditMoistPct: false,
    FV95ResetInd: false
}

export class clsBuyingPointInfo {

    constructor(thisObj) {
        this.thisObj = thisObj;
        this.mudtProps = Object.assign({}, BuyingPointInfoProps);
        this.mudtSave = Object.assign({}, BuyingPointInfoProps);
        this.mudtProps.PhysicalAddress = new clsAddress();
        this.mudtProps.MailingAddress = new clsAddress();
    }



    Name = (Value) => {
        this.mudtProps.Name = Value;
    }

    AreaID = (Value) => {
        this.mudtProps.AreaID = Value;
    }

    Phone = (Value) => {
        this.mudtProps.Phone = Value;
    }

    GramEntryInd = (Value) => {
        this.mudtProps.GramEntryInd = Value;
    }

    TransferGramEntryInd = (Value) => {
        this.mudtProps.TransferGramEntryInd = Value;
    }

    TransferEditMoistPct = (Value) => {
        this.mudtProps.TransferEditMoistPct = Value;
    }

    FV95ResetInd = (Value) => {
        this.mudtProps.FV95ResetInd = Value;
    }

    Fetch = (buyingPoint) => {
        this.mudtProps.ID = buyingPoint.buy_pt_id;
        this.mudtProps.Name = buyingPoint.buy_pt_name;
        this.mudtProps.AreaID = buyingPoint.area_id;
        this.mudtProps.Phone = buyingPoint.buy_pt_phone;
        this.mudtProps.GramEntryInd = buyingPoint.gram_entry_ind === 'Y' ? true : false;
        this.mudtProps.TransferGramEntryInd = buyingPoint.XFER_GRAM_ENTRY_IND === 'Y' ? true : false;
        this.mudtProps.TransferEditMoistPct = buyingPoint.XFER_EDIT_MOIST_PCT === 'Y' ? true : false;
        this.mudtProps.FV95ResetInd = buyingPoint.fv95_reset_ind === 'Y' ? true : false;
        this.mudtProps.PhysicalAddress.City = buyingPoint.physical_city;
        this.mudtProps.PhysicalAddress.state = buyingPoint.physical_state;
        this.mudtProps.AutoNumberInd = buyingPoint.xfer_auto_num_ind === 'Y' ? true : false;
    }

    FetchDestinationPoint = (buyingPoint) => {
        this.mudtProps.ID = buyingPoint.buy_pt_id;
        this.mudtProps.Name = buyingPoint.xfer_buy_pt_name;
        this.mudtProps.AreaID = buyingPoint.area_id;
        this.mudtProps.Phone = buyingPoint.xfer_buy_pt_phone;
        this.mudtProps.GramEntryInd = buyingPoint.gram_entry_ind === 'Y' ? true : false;
        this.mudtProps.TransferGramEntryInd = buyingPoint.XFER_GRAM_ENTRY_IND === 'Y' ? true : false;
        this.mudtProps.TransferEditMoistPct = buyingPoint.XFER_EDIT_MOIST_PCT === 'Y' ? true : false;
        this.mudtProps.FV95ResetInd = buyingPoint.fv95_reset_ind === 'Y' ? true : false;
        this.mudtProps.PhysicalAddress.City = buyingPoint.xfer_buy_pt_physical_city;
        this.mudtProps.PhysicalAddress.state = buyingPoint.xfer_buy_pt_physical_state;
        this.mudtProps.AutoNumberInd = buyingPoint.xfer_auto_num_ind === 'Y' ? true : false;
    }

    BuyingPointInfo = () => {
        return this.mudtProps.Name & " - " & this.mudtProps.PhysicalAddress.City & " - " & this.mudtProps.PhysicalAddress.state;
    }
}
