/* eslint-disable*/
import React from "react";
import WhiteSpaceWidget from "./WhiteSpaceWidget";
import { screen } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../../common/renderTestScreen";
import { cleanup } from "@testing-library/react";

describe("WhiteSpaceWidget1 Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      let whitespacewidget1 = {
        name: "whitespacewidget1",
        type: "WhiteSpaceWidget",
        parent: "groupboxwidget0",
        Label: "whitespacewidget1",
      };
      let stateScreenConf = {
        Label: "TestScreen",
        windowName: "TestScreen",
        template: "Standard Screen",
        Cols: "3",
        ColsForMobile: "3",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        isResponsiveLayout: false,
        Height: "",
        Left: "",
        Top: "",
        Width: "",
        i18Key: "TestGUI.TestScreen",
      };
      renderTestScreen(
        <WhiteSpaceWidget
          conf={whitespacewidget1}
          screenConf={stateScreenConf}
        />
      );
    });
  });
  afterEach(cleanup);
  test("Is WhiteSpaceWidget1 Loads Succesfully", async () => {
    const whitespacewidget1 = screen.getByTestId("whitespacewidget1");
    expect(whitespacewidget1.tagName).toBe("DIV");
    expect(whitespacewidget1.classList).toContain("whitespacewidget1");
  });
});
