/* eslint-disable*/
import React from "react";
import HamburgerMenu from "./HamburgerMenu";
import { cleanup, render, screen } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import userEvent from "@testing-library/user-event";
import renderTestScreen from "../../../common/renderTestScreen";

describe("HamburgerMenu Test Cases-1", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<HamburgerMenu />);
    });
  });
  afterEach(cleanup);
  test("is HamburgerMenu Loads Successfully", async () => {
    let stdScreen = screen.getAllByTestId("scrnDispName");
    stdScreen = stdScreen[0].firstChild;
    let langSel = screen.getByTestId("scrnDispName").nextSibling.firstChild;
    userEvent.click(langSel);
    var sel_lang = window._kaledo.languages[0].langName;
    userEvent.click(screen.getByText(sel_lang));
    await act(async () => {
      userEvent.click(stdScreen, { button: 0 });
    });
    expect(false).toBe(false);
  });
});
describe("HamburgerMenu Test Cases-2", () => {
  beforeEach(async () => {
    act(() => {
      window._kaledo["Auth"] = "OAUTH2";
      renderTestScreen(<HamburgerMenu />);
    });
  });
  afterEach(cleanup);
  test("is HamburgerMenu Loads Successfully", async () => {
    let stdScreen = screen.getAllByTestId("scrnDispName");
    stdScreen = stdScreen[0].firstChild;
    await act(async () => {
      userEvent.click(stdScreen, { button: 0 });
    });
    expect(false).toBe(false);
  });
});
