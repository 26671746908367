/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  ListboxWidget,
  setValue,
  getData,
  setData
} from "../../shared/WindowImports";

import "./ContractExceptionPremiumSetup.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../Service/ContractManagementService";
import { CommonKeys } from './../../Common/Constants';
import CommonContext from '../../Store/CommonContext';
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ContractExceptionPremiumSetup(props) {
  const commonContext = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let comp_id = sessionStorage.getItem('compId');
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ContractExceptionPremiumSetup",
    windowName: "ContractExceptionPremiumSetup",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ContractExceptionPremiumSetup",
    // START_USER_CODE-USER_ContractExceptionPremiumSetup_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Contract Exception Premium Setup",
      scrCode: "PN1020F"
    },
    // END_USER_CODE-USER_ContractExceptionPremiumSetup_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxAddDel",
      Label: "Add",
      CharWidth: "10",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbcContrctExcPremSet",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDel: {
      name: "btnDel",
      type: "ButtonWidget",
      parent: "grpbxAddDel",
      Label: "Delete",
      CharWidth: "15",
      // START_USER_CODE-USER_btnDel_PROPERTIES

      // END_USER_CODE-USER_btnDel_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxAddDel",
      Label: "Edit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    // btnRescan: {
    //   name: "btnRescan",
    //   type: "ButtonWidget",
    //   parent: "grpbcContrctExcPremSet",
    //   Label: "Rescan for New Exceptions",
    //   CharWidth: "50",
    // START_USER_CODE-USER_btnRescan_PROPERTIES

    // END_USER_CODE-USER_btnRescan_PROPERTIES
    // },
    grpbxAddDel: {
      name: "grpbxAddDel",
      type: "GroupBoxWidget",
      parent: "grpbcContrctExcPremSet",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxAddDel_PROPERTIES

      // END_USER_CODE-USER_grpbxAddDel_PROPERTIES
    },
    lstExceptions: {
      name: "lstExceptions",
      type: "ListBoxFieldWidget",
      parent: "grpbcContrctExcPremSet",
      Label: "Exceptions",
      ColSpan: "3",
      DataProviderForListBox: "inline",
      Options: "",
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstExceptions_PROPERTIES

      // END_USER_CODE-USER_lstExceptions_PROPERTIES
    },
    txtContrctNm: {
      name: "txtContrctNm",
      type: "TextBoxWidget",
      parent: "grpbcContrctExcPremSet",
      Label: "Contract Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContrctNm_PROPERTIES
      ReadOnly: true,
      Enabled: false
      // END_USER_CODE-USER_txtContrctNm_PROPERTIES
    },
    grpbcContrctExcPremSet: {
      name: "grpbcContrctExcPremSet",
      type: "GroupBoxWidget",
      parent: "ContractExceptionPremiumSetup",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbcContrctExcPremSet_PROPERTIES

      // END_USER_CODE-USER_grpbcContrctExcPremSet_PROPERTIES
    },
    deleteToggle: true,
    _dataCopy: {
      FORWARD: {
        "ContractManagement#ContractExceptPremProfile": {},
      },
      REVERSE: {
        "ContractManagement#ContractExceptPremProfile": {},
      },
    },
  };
  let _buttonServices = { ContractExceptPremProfile: {} };
  let _buttonNavigation = {
    btnAdd: {
      DEFAULT: [
        "ContractManagement#ContractExceptPremProfile#DEFAULT#true#Click",
      ],
    },
    btnEdit: {
      DEFAULT: [
        "ContractManagement#ContractExceptPremProfile#DEFAULT#true#Click",
      ],
    }
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let contract_num = commonContext.getContractProfile_ContractNumber; 
    setValue(thisObj, 'txtContrctNm', contract_num);
    exceptPremiumDetails();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  },[getData(thisObj, "selData"),state.deleteToggle]);

  // The premiumCode at lstExceptions listbox on Form Load
  function exceptPremiumDetails () {
    let buY_PT_ID = commonContext.getContractProfile_BuyingPointId; 
    let contract_num = commonContext.getContractProfile_ContractNumber; 
    ContractManagementService.RetrieveContractExceptionPremiumDetails(buY_PT_ID, contract_num)
    .then(response => {
      let data = response;
      setData(thisObj, 'ContractProfile_ExceptionPremiumSetupDetails', data);
      let js = [];
      if (data == null || data.length == 0 || data == undefined) {
        setValue(thisObj, 'txtContrctNm', contract_num);// The contract Number is passing from 'Contract Management Profile-2009' in old application but I am passing from the GET Api Due to Unavailability of that parameters from Screen
      }
      else {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pdCode.trim(), description: data[i].pdCode.trim() + '-' + data[i].pdDesc.trim() }
          js.push(obj);
        }
        setValue(thisObj,"lstExceptions",[data[0].pdCode])
      }
      thisObj.setState(current => {
        return {
          ...current,
          lstExceptions: {
            ...state["lstExceptions"],
            valueList: js
          }
        }
      });
    });
    setData(thisObj,"selData",false)
  }

  // Button Add click 
  const onbtnAddClick = () => {
    try {

      // document.getElementsByClassName("btnAddButton")[0].click();
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      setData(thisObj, "flag", "Add");
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnAdd event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnAddClick = onbtnAddClick;

  //Button Edit Click
  const onbtnEditClick = () => {
    try {

      // GET API Calling Edit Button Click
      let PdCode = lstExceptions.value
      if (PdCode == "") {
        return false;
      }
      else {
        let buY_PT_ID = commonContext.getContractProfile_BuyingPointId;
        let contract_num = commonContext.getContractProfile_ContractNumber;
        ContractManagementService.RetrieveContractExceptionPremiumDetails(buY_PT_ID, contract_num, PdCode).then(response => {
          let data = response;
          let js = [];
          for (var i = 0; i < data.length; i++) {
            let obj = { key: data[i], description: data[i].pdDesc }
            js.push(obj);
          }
          setData(thisObj, 'PdRate', js[0].key.pdRate);
          setData(thisObj, 'PdRateBase', js[0].key.pdRateBase);
          setData(thisObj, 'DelvLoadType', js[0].key.delvLoadType);
          setData(thisObj, 'Explanation', js[0].key.explanation);
          setData(thisObj, 'AddUser', js[0].key.addUser);
          setData(thisObj, 'ChgUser', js[0].key.chgUser);
          thisObj.setState(current => {
            return {
              ...current,
              btnEdit: {
                ...state["btnEdit"],
                valueList: js
              }
            }
          });
        })
        setData(thisObj, 'flag', "Edit");
        setData(thisObj, 'PdCode', PdCode);
        //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnEdit event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnEditClick = onbtnEditClick;

  // START_USER_CODE-USER_METHODS
  // Delete API Contract_Except_Prem_Delete
  const onbtnDelClick = () => {
    try {
      if (lstExceptions.value == "") {
        return false;
      }
      else {
        const confirmBox = window.confirm("Please confirm you want to delete this Exception Premium!")
        if (confirmBox == true) {
          let dataObj = {
            comp_id: comp_id,
            crop_Year: cropYear,
            buY_PT_ID: commonContext.getContractProfile_BuyingPointId,
            contract_num: commonContext.getContractProfile_ContractNumber,
            pD_CODE: lstExceptions.value
          }
          ContractManagementService.RemoveContractExceptionPremium(dataObj.buY_PT_ID, dataObj.contract_num, dataObj.pD_CODE)
            .then(response => {
              let deleteToggle = state.deleteToggle;
              setState(current => {
                return {
                  ...current,
                  deleteToggle: !deleteToggle
                }
              })
              showMessage(thisObj, response.message, true);
            })
          }
      }
    } 
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Delete Failed Please contact your system administrator!"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnDelClick = onbtnDelClick;

  // START_USER_CODE-USER_METHODS
  const onbtnCloseClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
     document.getElementById("ContractManagement_ContractExceptionPremiumSetupPopUp").childNodes[0].click();
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ContractExceptionPremiumSetup*/}

              {/* END_USER_CODE-USER_BEFORE_ContractExceptionPremiumSetup*/}

              {/* START_USER_CODE-USER_BEFORE_grpbcContrctExcPremSet*/}

              {/* END_USER_CODE-USER_BEFORE_grpbcContrctExcPremSet*/}

              <GroupBoxWidget
                conf={state.grpbcContrctExcPremSet}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtContrctNm*/}

                {/* END_USER_CODE-USER_BEFORE_txtContrctNm*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContrctNm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContrctNm*/}

                {/* END_USER_CODE-USER_AFTER_txtContrctNm*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxAddDel*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxAddDel*/}

                <GroupBoxWidget conf={state.grpbxAddDel} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                  <ButtonWidget
                    conf={state.btnEdit}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                  {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDel*/}

                  <ButtonWidget
                    conf={state.btnDel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDel*/}

                  {/* END_USER_CODE-USER_AFTER_btnDel*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxAddDel*/}

                {/* END_USER_CODE-USER_AFTER_grpbxAddDel*/}
                {/* START_USER_CODE-USER_BEFORE_lstExceptions*/}

                {/* END_USER_CODE-USER_BEFORE_lstExceptions*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstExceptions}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstExceptions*/}

                {/* END_USER_CODE-USER_AFTER_lstExceptions*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnRescan*/}

                {/* END_USER_CODE-USER_BEFORE_btnRescan*/}

                {/* <ButtonWidget
                  conf={state.btnRescan}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget> */}
                {/* START_USER_CODE-USER_AFTER_btnRescan*/}

                {/* END_USER_CODE-USER_AFTER_btnRescan*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbcContrctExcPremSet*/}

              {/* END_USER_CODE-USER_AFTER_grpbcContrctExcPremSet*/}

              {/* START_USER_CODE-USER_AFTER_ContractExceptionPremiumSetup*/}

              {/* END_USER_CODE-USER_AFTER_ContractExceptionPremiumSetup*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ContractExceptionPremiumSetup);
