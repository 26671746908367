/* eslint-disable*/
import Form from "react-bootstrap/Form";
import { object } from "yup";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import d3 from "d3";

import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";

import GroupBoxWidget from "../../widgets/GroupBoxWidget/GroupBoxWidget";
import TextBoxWidget from "../../widgets/TextBoxWidget/TextBoxWidget";
import DropDownWidget from "../../widgets/DropDownWidget/DropDownWidget";
import CommandContainerWidget from "../../widgets/CommandContainerWidget/CommandContainerWidget";
import ButtonWidget from "../../widgets/ButtonWidget/ButtonWidget";
import LinkWidget from "../../widgets/LinkWidget/LinkWidget";
import GridWidget from "../../widgets/GridWidget/GridWidget";
import FileWidget from "../../widgets/FileWidget/FileWidget";
import DateWidget from "../../widgets/DateWidget/DateWidget";
import CheckboxGroupWidget from "../../widgets/CheckboxGroupWidget/CheckboxGroupWidget";
import ImageWidget from "../../widgets/ImageWidget/ImageWidget";
import ListboxWidget from "../../widgets/ListboxWidget/ListboxWidget";
import LabelWidget from "../../widgets/LabelWidget/LabelWidget";
import RadioButtonGroupWidget from "../../widgets/RadioButtonGroupWidget/RadioButtonGroupWidget";
import CheckboxWidget from "../../widgets/CheckboxWidget/CheckboxWidget";
import RichTextEditor from "../../widgets/RichTextEditor/RichTextEditor";
import TextAreaWidget from "../../widgets/TextAreaWidget/TextAreaWidget";
import RangeWidget from "../../widgets/RangeWidget/RangeWidget";
import ListSelectDeselectWidget from "../../widgets/ListSelectDeselectWidget/ListSelectDeselectWidget";
import GroupWidget from "../../widgets/GroupWidget/GroupWidget";
import WhiteSpaceWidget from "../../widgets/WhiteSpaceWidget/WhiteSpaceWidget";
import SpinnerWidget from "../../widgets/SpinnerWidget/SpinnerWidget";
import PasswordWidget from "../../widgets/PasswordWidget/PasswordWidget";
import ChartWidget from "../../widgets/ChartWidget/ChartWidget";
import AutocompleteWidget from "../../widgets/AutocompleteWidget/AutocompleteWidget";
import IframeWidget from "../../widgets/IframeWidget/IframeWidget";
import TreeWidget from "../../widgets/TreeWidget/TreeWidget";
import Modal from "../Modal/Modal";
//import ParentWindow from "../ParentWindow";
import { initForm } from "../../../../common/ScreenInitialization";
import { useAppContext } from "../../../App";
import {useHistory,useLocation,useRouteMatch} from "react-router-dom";
import {invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,parentWindow,
  handleModalHide,dataCopyObj,
  showMessage,
  getLabel,setLabel,hide,show,isEnabled,isVisible,enable,disable,readonly,clearField,attachHotKeysHandler,
  getEventObj,setData,getData,clearSessionData,configureService,callOauthService,getValue,setValue,setFieldValues,getSelectedGridValue,
  switchToTab,setGridValue,getGridValue,showColumn,hideColumn,editRow,setSelectedGridValue,disableGridColumn,enableGridColumn,
  setGridLastCell,getSelectedRowNumber,getSelectedRowNumbers,hasSelectedRows,getRowCount,gridRowStyle,isGridColumnEnable,
  deleteGridRow,hideGridHeader,showGridHeader,readonlyGridColumn,getGridLastCell,setSelectedRows,navigateToNextPage,navigateToPreviousPage,
  attachHomeKeyHandleForGrid,navigate,goTo,clearValue,buttonNavigationHandler,isElementNeedsFocus,focusFirstElement,showWidgets, hideWidgets, enableWidgets,setValueList,setGridColList
} from "../ParentWindow";
const _kaledo = window._kaledo;

export {
  Form,
  object,
  Formik,
  withRouter,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  LinkWidget,
  GridWidget,
  IframeWidget,
  TreeWidget,
  FileWidget,
  DateWidget,
  CheckboxGroupWidget,
  ImageWidget,
  ListboxWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  RichTextEditor,
  TextAreaWidget,
  RangeWidget,
  ListSelectDeselectWidget,
  GroupWidget,
  WhiteSpaceWidget,
  SpinnerWidget,
  PasswordWidget,
  ChartWidget,
  AutocompleteWidget,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  parentWindow,
  handleModalHide,
  dataCopyObj,
  showMessage,getLabel,setLabel,hide,show,isEnabled,isVisible,enable,disable,readonly,clearField,attachHotKeysHandler,
  getEventObj,setData,getData,clearSessionData,configureService,callOauthService,getValue,setValue,setFieldValues,getSelectedGridValue,
  switchToTab,setGridValue,getGridValue,showColumn,hideColumn,editRow,setSelectedGridValue,disableGridColumn,enableGridColumn,
  setGridLastCell,getSelectedRowNumber,getSelectedRowNumbers,hasSelectedRows,getRowCount,gridRowStyle,isGridColumnEnable,
  deleteGridRow,hideGridHeader,showGridHeader,readonlyGridColumn,getGridLastCell,setSelectedRows,navigateToNextPage,navigateToPreviousPage,
  attachHomeKeyHandleForGrid,navigate,goTo,clearValue,buttonNavigationHandler,isElementNeedsFocus,focusFirstElement,setValueList,
  Modal,
  _kaledo,
  initForm,
  useAppContext,useHistory,useLocation,useRouteMatch,
  Tab,
  Col,
  Row,
  Nav,
  d3,
  showWidgets, hideWidgets, enableWidgets,setGridColList
};
