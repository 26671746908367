/* eslint-disable*/
const _kaledo = window._kaledo;
export const getSize = (conf) => {
  return conf.size || "sm";
};
export const getWidgetLabel = (conf) => {
  return conf.Label;
};

export const getPanelClasses = (conf,screenConf) => {
  return [...getWidgetClasses(conf,screenConf), "mb-3"];
};

export const getFieldContentClasses = (screenConf) => {
  return [screenConf.horizontalForm ? "d-flex " : ""];
};

export const getFieldContentStyle = (conf) => {
  return {
    width: conf.contentWidth ? conf.contentWidth + "%" : "100%"
  };
};

export const getVisibility = (conf,screenConf) => {
  if (!conf.HasLabel) {
    if (screenConf.horizontalForm) {
      return { display: "none" };
    } else {
      return { visibility: "hidden" };
    }
  }
  return null;
};

export const getWidgetClasses = (conf,screenConf) => {
  return [
    conf.name,
    ...getResponsiveClasses(conf,screenConf),
    ...getVisibleResponsiveClasses(conf),
    ...getRowSpanClasses(conf),
    conf.Mandatory ? "required" : "",
    `${conf.type.toLowerCase()}-container`,
    conf.contentAlign ? `justify-content-${conf.contentAlign}` : ""
  ];
};

const getRowSpanClasses = (conf) => {
  const {
    RowSpan,
    RowSpanForTabLandscape,
    RowSpanForTabPotrait,
    RowSpanForMobile
  } = conf;
  let rowSpanCSSClasses = [];
  if (RowSpan) {
    rowSpanCSSClasses.push(
      "rowspanResponsive-lg-" + RowSpan,
      "rowspanResponsive-md-" + RowSpanForTabLandscape,
      "rowspanResponsive-sm-" + RowSpanForTabPotrait,
      "rowspanResponsive-xs-" + RowSpanForMobile
    );
  }
  return rowSpanCSSClasses;
};

const is12ColumnLayout = (screenConf) => {
  return screenConf.is12Column;
};

// export const getVisibleResponsiveClasses = (conf) => {
//   let tempArr = [];
//   if (conf.VisibleForMobile === false || conf.Visible === false) {
//     tempArr.push("d-none", "d-sm-block");
//     const div = document.getElementsByClassName(conf.name)[0];
//     if(div!==undefined){
//       div.classList.remove('d-flex')
//     }
//   }
//   if (conf.VisibleForTabPotrait === false || conf.Visible === false) {
//     tempArr.push("d-sm-none", "d-md-block");
//   }
//   if (conf.VisibleForTabLandscape === false || conf.Visible === false) {
//     tempArr.push("d-md-none", "d-lg-block");
//   }
//   if (conf.Visible === false) {
//     tempArr.push("d-lg-none");
//   }

//   let respArr = ["sm", "md", "lg"];
//   respArr.forEach(elem => {
//     if (
//       tempArr.indexOf("d-" + elem + "-block") !== -1 &&
//       tempArr.indexOf("d-" + elem + "-none") !== -1
//     ) {
//       let blockIndex = tempArr.indexOf("d-" + elem + "-block");
//       delete tempArr[blockIndex];
//     }
//   });

//   return tempArr;
// };
export const getVisibleResponsiveClasses = (conf) => {
  let tempArr = [];
  if(conf.withCollapse===undefined || conf.withCollapse===true){
  if (conf.VisibleForMobile === false || conf.Visible === false) {
    tempArr.push("d-none", "d-sm-block");
    const div = document.getElementsByClassName(conf.name)[0];
    if (div != undefined){
      div.classList.remove('d-flex');

    }
  }
  if (conf.VisibleForTabPotrait === false || conf.Visible === false) {
    tempArr.push("d-sm-none", "d-md-block");
  }
  if (conf.VisibleForTabLandscape === false || conf.Visible === false) {
    tempArr.push("d-md-none", "d-lg-block");
  }
  if (conf.Visible === false) {
    tempArr.push("d-lg-none");
  }
  }
  if(conf.withCollapse!==undefined && conf.withCollapse===false){
      tempArr.push("hideControl");
  }
  let respArr = ["sm", "md", "lg"];
  respArr.forEach(elem => {
    if (
      tempArr.indexOf("d-" + elem + "-block") !== -1 &&
      tempArr.indexOf("d-" + elem + "-none") !== -1
    ) {
      let blockIndex = tempArr.indexOf("d-" + elem + "-block");
      delete tempArr[blockIndex];
    }
  });

  return tempArr;
};

export const getResponsiveClasses = (conf,screenConf) => {
  let parentConf = screenConf[conf.parent];
  if (!parentConf) {
    parentConf = screenConf;
  }
  const {
    Cols,
    ColsForTabLandscape,
    ColsForTabPotrait,
    ColsForMobile
  } = parentConf;

  const {
    ColSpan,
    ColSpanForTabLandscape,
    ColSpanForTabPotrait,
    ColSpanForMobile
  } = conf;

  let responsiveClasses = [];
  if(_kaledo.isCobolTable || _kaledo.isZoomIn){
  let colSpanNumber = parseInt(ColSpan) * (12 / parseInt(Cols));
  colSpanNumber = Math.round(colSpanNumber);
  responsiveClasses.push("col-" + colSpanNumber);
}
else{
  responsiveClasses.push(
    getBSClasses(screenConf,Cols, ColSpan),
    getBSClasses(screenConf,
    ColsForTabLandscape,
    ColSpanForTabLandscape,
    "TabLandscape"
    ),
    getBSClasses(screenConf,ColsForTabPotrait, ColSpanForTabPotrait, "TabPotrait"),
    getBSClasses(screenConf,ColsForMobile, ColSpanForMobile, "Mobile")
  );
}

  return responsiveClasses;
};

const getBSClasses = (screenConf,cols, colSpan, type) => {
  let colSpanNumber;
  if (is12ColumnLayout(screenConf)) {
    colSpanNumber = parseInt(colSpan);
  } else {
    colSpanNumber = parseInt(colSpan) * (12 / parseInt(cols));
  }
  colSpanNumber = Math.round(colSpanNumber);
  switch (type) {
    case "TabLandscape":
      return "col-md-" + colSpanNumber;
    case "TabPotrait":
      return "col-sm-" + colSpanNumber;
    case "Mobile":
      return "col-xs-" + colSpanNumber;
    default:
      return "col-lg-" + colSpanNumber;
  }
};

export const checkInvalid=(props,conf)=> {
  return (
    props.touched[conf.name] && props.errors[conf.name]
  );
};

export const getTooltip=(conf,col)=> {
  let obj = conf;
  if(col !== undefined)
    obj = col

  return obj.ToolTip ? obj.ToolTip.Label
    ? obj.ToolTip.Label : obj.ToolTip
    : "";
};

export const getMaxlength=(conf,col)=> {
  let maxlength=256;
  let ofTypeDomain=col?col.ofTypeDomain:conf.ofTypeDomain;
  let LengthRange= col?col.LengthRange:conf.LengthRange;
  if(ofTypeDomain==="d_String")
  maxlength=LengthRange ? LengthRange.MaxLength : 256;
  if(ofTypeDomain.includes("d_int"))
  maxlength=LengthRange ? LengthRange.MaxLength : 9;
  if(ofTypeDomain.includes("d_long"))
  maxlength=LengthRange ? LengthRange.MaxLength : 17;
  if(ofTypeDomain.includes("d_float"))
  maxlength=LengthRange ? LengthRange.MaxLength : 14;
  if(ofTypeDomain.includes("d_double"))
  maxlength=LengthRange ? LengthRange.MaxLength : 17;
  return maxlength;
};

export const getTextBoxClasses = (conf,col) => {
  let textBoxClasses = ["textboxWidgetClass"];
  switch (conf.Alignment) {
    case "Left":
      textBoxClasses.push("leftAlignClass");
      break;
    case "Right":
      textBoxClasses.push("rightAlignClass");
      break;
    case "Center":
      textBoxClasses.push("centerAlignClass");
      break;
    default:
      textBoxClasses.push("centerAlignClass");
      break;
  }
if (_kaledo.isCobolTable) {
  if(conf.ReadOnly===true)
    {
        textBoxClasses.push("RemoveBottomBorder");
    }
  var maxLen = "";
  var lengthClass = "";
  if(conf.type ==="GridWidget" && col!==undefined && col.type==='TextBoxWidget' && col.LengthRange!==undefined && col.LengthRange.MaxLength <= 100){
    maxLen = col.LengthRange.MaxLength;
    lengthClass = "ch-"+maxLen;
    textBoxClasses.push(lengthClass);
  }
  else if (conf.ReadOnly !== true && conf.Enabled !== false && conf.type === 'TextBoxWidget' && conf.LengthRange && conf.LengthRange.MaxLength<= 100) {
    maxLen = conf.LengthRange.MaxLength;
    lengthClass = "ch-"+maxLen;
    textBoxClasses.push(lengthClass);
  }
  }
  if(conf.type ==="GridWidget" && col!==undefined && col.type==='TextBoxWidget' && col.Mandatory){
    textBoxClasses.push("requiredField");
  }
  if (conf.Mandatory) {
    textBoxClasses.push("requiredField");
  }
  if (conf.LOV) {
    textBoxClasses.push("lovTextBoxClass");
  }
  return textBoxClasses.join(" ");
};

export const getTextAreaClasses = (conf) => {
  let textBoxClasses = ["textareaWidgetClass"];
  switch (conf.Alignment) {
    case "Left":
      textBoxClasses.push("leftAlignClass");
      break;
    case "Right":
      textBoxClasses.push("rightAlignClass");
      break;
    case "Center":
      textBoxClasses.push("centerAlignClass");
      break;
    default:
      textBoxClasses.push("centerAlignClass");
      break;
  }
  if (conf.Mandatory) {
    textBoxClasses.push("requiredField");
  }
  return textBoxClasses.join(" ");
};

export const getPlaceholder=(conf)=> {
  return conf.PlaceholderForTextBox
    ? conf.PlaceholderForTextBox
    : "";
}

export const getInputType=(conf)=> {
  if(_kaledo["errorPosition"]!==undefined)
  conf.errorPosition = _kaledo["errorPosition"];
else
  conf.errorPosition = "field";
  if (conf.inputType && conf.inputType !== "None") {
    return conf.inputType;
  } else if (conf.type === "PasswordBoxWidget") {
    return "password";
  } else if (conf.type === "SpinnerWidget") {
    return "number";
  } else {
    return "text";
  }
};

export const isDispFormatReqd=()=> {
  let DispFormatReqd = false;
  if (_kaledo["dispFormatReqd"])
    DispFormatReqd = true;
  return DispFormatReqd;
};

export const getDispFormat=(confObj,obj)=> {
  let pattern = "";
  let conf;
  if(confObj.type === "GridWidget"){
    conf = obj;
  }else{
    conf = confObj;
  }
  let domain =  "d_int";
  if(domain === conf.ofTypeDomain){
        let len = conf.LengthRange.MaxLength;
        let x = "9";
        for (let i = 1; i <= len; i++) {
          pattern += x;
        }
        pattern = "("+pattern+")";
        pattern = conf.formattingReqd ? pattern.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : pattern;
  }
  switch (conf.type) {
    case "DateWidget":
      pattern = conf.format || _kaledo["format"]["dateFormat"];
      pattern = "("+pattern+")";
      break;
    case "DateTimeWidget":
      pattern = conf.format || _kaledo["format"]["dateTimeFormat"];
      pattern = "("+pattern+")";
      break;
    case "TimeWidget":
      pattern = conf.format || _kaledo["format"]["timeFormat"];
      pattern = "("+pattern+")";
      break;
    default:
      break;
  }      
  return pattern;
};

export const captureDateFormat=(conf)=> {
  switch (conf.type) {
    case "DateWidget":
      conf.format = conf.format || _kaledo["format"]["dateFormat"];
      break;
    case "DateTimeWidget":
      conf.format =
        conf.format || _kaledo["format"]["dateTimeFormat"];
      break;
    case "TimeWidget":
      conf.format = conf.format || _kaledo["format"]["timeFormat"];
      break;
    default:
      conf.format = "";
  }
};

export const prepareDatePickerOptions=(conf)=> {
  let formatString = conf.format;
  if (conf.is24HourClock || conf.isTime24HourClock) {
    formatString = formatString.replace(" a", "");
    formatString = formatString.replace("hh", "HH");
  }
  if (!conf.secondTimeRequired) {
    formatString = formatString.replace(":ss", "");
  }
  conf.format = formatString;
  let pickerOptions = {
    minDate: conf.dateOptions && conf.dateOptions.minDate,
    maxDate: conf.dateOptions && conf.dateOptions.maxDate,
    format: conf.format,
    useCurrent:false,
  };
  return pickerOptions;
};



