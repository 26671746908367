/* eslint-disable*/
import React, { Component } from "react";
import "./Header.module.scss";
import logo from "../../assets/img/EnterpriseAppsMaker.png";
import TopMenu from "./TopMenu/TopMenu";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import SideMenu from "./SideMenu/SideMenu";
import Navbar from "react-bootstrap/Navbar";

function Header(props) {
 let menuType = props.menuType;
    return (
      <React.Fragment>
        <Navbar
          collapseOnSelect
          expand={menuType !== "hamburger" ? "lg" : null}
          bg="dark"
          variant="dark"
          fixed="top"
          data-testid="header"
        >
          <Navbar.Brand href="#home">
            <img
              src={logo}
              width="225"
              height="30"
              className="d-inline-block align-top"
              alt="EnterpriseAppsMaker"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            className={menuType === "hamburger" ? "d-block" : ""}
            aria-controls="basic-navbar-nav"
          />
          {menuType !== "hamburger" ? (
            <TopMenu menuType={menuType} />
          ) : null}
          {menuType === "hamburger" ? (
            <HamburgerMenu menuType={menuType} />
          ) : null}
        </Navbar>
        {menuType === "side" ? <SideMenu menuType={menuType} /> : null}
      </React.Fragment>
    );
  
}

export default Header;
