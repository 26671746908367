/* eslint-disable*/
import React from "react";
import Settlements_TradeSettleSearch from "./TradeSettleSearch";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("TradeSettleSearch Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettleSearch />);
    });
  });
  afterEach(cleanup);
  test("is TradeSettleSearch Loads Successfully", () => {
    expect(screen.getByText("TradeSettleSearch")).toBeInTheDocument;
  });
  test("Custom Test Cases for TradeSettleSearch", () => {
    // START_USER_CODE-USER_TradeSettleSearch_Custom_Test_Case
    // END_USER_CODE-USER_TradeSettleSearch_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeSettleSearch />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(t("Settlements:TradeSettleSearch_btn1"));
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("Settlements:TradeSettleSearch_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCreateTradeSettlement(Button Widget) Test Cases", async () => {
    const btnCreateTradeSettlement = screen.getByTestId(
      "btnCreateTradeSettlement"
    );
    expect(btnCreateTradeSettlement).toBeInTheDocument;
    expect(btnCreateTradeSettlement.textContent).toEqual(
      t("Settlements:TradeSettleSearch_btnCreateTradeSettlement")
    );
  });
  test("Custom Test Cases for btnCreateTradeSettlement", () => {
    // START_USER_CODE-USER_btnCreateTradeSettlement_TEST
    // END_USER_CODE-USER_btnCreateTradeSettlement_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("Settlements:TradeSettleSearch_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnOpen(Button Widget) Test Cases", async () => {
    const btnOpen = screen.getByTestId("btnOpen");
    expect(btnOpen).toBeInTheDocument;
    expect(btnOpen.textContent).toEqual(
      t("Settlements:TradeSettleSearch_btnOpen")
    );
  });
  test("Custom Test Cases for btnOpen", () => {
    // START_USER_CODE-USER_btnOpen_TEST
    // END_USER_CODE-USER_btnOpen_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("Settlements:TradeSettleSearch_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("Settlements:TradeSettleSearch_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("btnVendor(Button Widget) Test Cases", async () => {
    const btnVendor = screen.getByTestId("btnVendor");
    expect(btnVendor).toBeInTheDocument;
    expect(btnVendor.textContent).toEqual(
      t("Settlements:TradeSettleSearch_btnVendor")
    );
  });
  test("Custom Test Cases for btnVendor", () => {
    // START_USER_CODE-USER_btnVendor_TEST
    // END_USER_CODE-USER_btnVendor_TEST
  });
  test("gridTrdSettlSrch(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrchbtn =
      gridTrdSettlSrch.nextElementSibling.firstElementChild;
    gridTrdSettlSrch =
      gridTrdSettlSrch.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch.tagName).toBe("DIV");
    expect(gridTrdSettlSrch.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridTrdSettlSrch", () => {
    // START_USER_CODE-USER_gridTrdSettlSrch_TEST
    // END_USER_CODE-USER_gridTrdSettlSrch_TEST
  });
  test("grpbxAction(GroupBox Widget) Test Cases", async () => {
    const grpbxAction = screen.getByTestId("grpbxAction");
    expect(grpbxAction.tagName).toBe("BUTTON");
    expect(grpbxAction.type).toBe("button");
    expect(grpbxAction.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAction", () => {
    // START_USER_CODE-USER_grpbxAction_TEST
    // END_USER_CODE-USER_grpbxAction_TEST
  });
  test("grpbxSearch(GroupBox Widget) Test Cases", async () => {
    const grpbxSearch = screen.getByTestId("grpbxSearch");
    expect(grpbxSearch.tagName).toBe("BUTTON");
    expect(grpbxSearch.type).toBe("button");
    expect(grpbxSearch.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSearch", () => {
    // START_USER_CODE-USER_grpbxSearch_TEST
    // END_USER_CODE-USER_grpbxSearch_TEST
  });
  test("grpbxTradeSettlementCrateOpen(GroupBox Widget) Test Cases", async () => {
    const grpbxTradeSettlementCrateOpen = screen.getByTestId(
      "grpbxTradeSettlementCrateOpen"
    );
    expect(grpbxTradeSettlementCrateOpen.tagName).toBe("BUTTON");
    expect(grpbxTradeSettlementCrateOpen.type).toBe("button");
    expect(grpbxTradeSettlementCrateOpen.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTradeSettlementCrateOpen", () => {
    // START_USER_CODE-USER_grpbxTradeSettlementCrateOpen_TEST
    // END_USER_CODE-USER_grpbxTradeSettlementCrateOpen_TEST
  });
  test("lbl100Val(Label Widget) Test Cases", async () => {
    const lbl100Val = screen.getByTestId("lbl100Val");
    expect(lbl100Val.tagName).toBe("LABEL");
    expect(lbl100Val.classList).toContain("form-label");
    expect(lbl100Val.textContent).toEqual(
      t("Settlements:TradeSettleSearch_lbl100Val")
    );
  });
  test("Custom Test Cases for lbl100Val", () => {
    // START_USER_CODE-USER_lbl100Val_TEST
    // END_USER_CODE-USER_lbl100Val_TEST
  });
  test("lbl100Value(Label Widget) Test Cases", async () => {
    const lbl100Value = screen.getByTestId("lbl100Value");
    expect(lbl100Value.tagName).toBe("LABEL");
    expect(lbl100Value.classList).toContain("form-label");
    expect(lbl100Value.textContent).toEqual(
      t("Settlements:TradeSettleSearch_lbl100Value")
    );
  });
  test("Custom Test Cases for lbl100Value", () => {
    // START_USER_CODE-USER_lbl100Value_TEST
    // END_USER_CODE-USER_lbl100Value_TEST
  });
  test("lblCropYear2(Label Widget) Test Cases", async () => {
    const lblCropYear2 = screen.getByTestId("lblCropYear2");
    expect(lblCropYear2.tagName).toBe("LABEL");
    expect(lblCropYear2.classList).toContain("form-label");
    expect(lblCropYear2.textContent).toEqual(
      t("Settlements:TradeSettleSearch_lblCropYear2")
    );
  });
  test("Custom Test Cases for lblCropYear2", () => {
    // START_USER_CODE-USER_lblCropYear2_TEST
    // END_USER_CODE-USER_lblCropYear2_TEST
  });
  test("lblNetWt(Label Widget) Test Cases", async () => {
    const lblNetWt = screen.getByTestId("lblNetWt");
    expect(lblNetWt.tagName).toBe("LABEL");
    expect(lblNetWt.classList).toContain("form-label");
    expect(lblNetWt.textContent).toEqual(
      t("Settlements:TradeSettleSearch_lblNetWt")
    );
  });
  test("Custom Test Cases for lblNetWt", () => {
    // START_USER_CODE-USER_lblNetWt_TEST
    // END_USER_CODE-USER_lblNetWt_TEST
  });
  test("lblNetWtVal(Label Widget) Test Cases", async () => {
    const lblNetWtVal = screen.getByTestId("lblNetWtVal");
    expect(lblNetWtVal.tagName).toBe("LABEL");
    expect(lblNetWtVal.classList).toContain("form-label");
    expect(lblNetWtVal.textContent).toEqual(
      t("Settlements:TradeSettleSearch_lblNetWtVal")
    );
  });
  test("Custom Test Cases for lblNetWtVal", () => {
    // START_USER_CODE-USER_lblNetWtVal_TEST
    // END_USER_CODE-USER_lblNetWtVal_TEST
  });
  test("lblSegVal(Label Widget) Test Cases", async () => {
    const lblSegVal = screen.getByTestId("lblSegVal");
    expect(lblSegVal.tagName).toBe("LABEL");
    expect(lblSegVal.classList).toContain("form-label");
    expect(lblSegVal.textContent).toEqual(
      t("Settlements:TradeSettleSearch_lblSegVal")
    );
  });
  test("Custom Test Cases for lblSegVal", () => {
    // START_USER_CODE-USER_lblSegVal_TEST
    // END_USER_CODE-USER_lblSegVal_TEST
  });
  test("lblSegValueVal(Label Widget) Test Cases", async () => {
    const lblSegValueVal = screen.getByTestId("lblSegValueVal");
    expect(lblSegValueVal.tagName).toBe("LABEL");
    expect(lblSegValueVal.classList).toContain("form-label");
    expect(lblSegValueVal.textContent).toEqual(
      t("Settlements:TradeSettleSearch_lblSegValueVal")
    );
  });
  test("Custom Test Cases for lblSegValueVal", () => {
    // START_USER_CODE-USER_lblSegValueVal_TEST
    // END_USER_CODE-USER_lblSegValueVal_TEST
  });
  test("lblTkcLbs(Label Widget) Test Cases", async () => {
    const lblTkcLbs = screen.getByTestId("lblTkcLbs");
    expect(lblTkcLbs.tagName).toBe("LABEL");
    expect(lblTkcLbs.classList).toContain("form-label");
    expect(lblTkcLbs.textContent).toEqual(
      t("Settlements:TradeSettleSearch_lblTkcLbs")
    );
  });
  test("Custom Test Cases for lblTkcLbs", () => {
    // START_USER_CODE-USER_lblTkcLbs_TEST
    // END_USER_CODE-USER_lblTkcLbs_TEST
  });
  test("lblTkcLbsVal(Label Widget) Test Cases", async () => {
    const lblTkcLbsVal = screen.getByTestId("lblTkcLbsVal");
    expect(lblTkcLbsVal.tagName).toBe("LABEL");
    expect(lblTkcLbsVal.classList).toContain("form-label");
    expect(lblTkcLbsVal.textContent).toEqual(
      t("Settlements:TradeSettleSearch_lblTkcLbsVal")
    );
  });
  test("Custom Test Cases for lblTkcLbsVal", () => {
    // START_USER_CODE-USER_lblTkcLbsVal_TEST
    // END_USER_CODE-USER_lblTkcLbsVal_TEST
  });
  test("gridTrdSettlSrch_txt100ValueC(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txt100ValueC = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txt100ValueCbtn =
      gridTrdSettlSrch_txt100ValueC.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txt100ValueC =
      gridTrdSettlSrch_txt100ValueC.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txt100ValueC.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txt100ValueC.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txt100ValueCdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txt100ValueCdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txt100ValueCbtn, { button: 0 });
      });
      const gridTrdSettlSrch_txt100ValueCadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txt100ValueCadd, { button: 0 });
      });
    }
    const txt100ValueC = screen.getByTestId("txt100ValueC");
    expect(txt100ValueC.tagName).toBe("INPUT");
    expect(txt100ValueC.type).toBe("text");
    expect(txt100ValueC.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txt100ValueC, "1");
    });
  });
  test("Custom Test Cases for txt100ValueC", () => {
    // START_USER_CODE-USER_txt100ValueC_TEST
    // END_USER_CODE-USER_txt100ValueC_TEST
  });
  test("gridTrdSettlSrch_txtAgree(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtAgree = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txtAgreebtn =
      gridTrdSettlSrch_txtAgree.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtAgree =
      gridTrdSettlSrch_txtAgree.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtAgree.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtAgree.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtAgreedata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtAgreedata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtAgreebtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtAgreeadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtAgreeadd, { button: 0 });
      });
    }
    const txtAgree = screen.getByTestId("txtAgree");
    expect(txtAgree.tagName).toBe("INPUT");
    expect(txtAgree.type).toBe("text");
    expect(txtAgree.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAgree, "1");
    });
  });
  test("Custom Test Cases for txtAgree", () => {
    // START_USER_CODE-USER_txtAgree_TEST
    // END_USER_CODE-USER_txtAgree_TEST
  });
  test("txtAgreement(Textbox Widget) Test Cases", async () => {
    const txtAgreement = screen.getByTestId("txtAgreement");
    expect(txtAgreement.tagName).toBe("INPUT");
    expect(txtAgreement.type).toBe("text");
    expect(txtAgreement.classList).toContain("textboxWidgetClass");
    expect(txtAgreement.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleSearch_txtAgreement")
    );
    await act(async () => {
      userEvent.type(txtAgreement, "1");
    });
  });
  test("Custom Test Cases for txtAgreement", () => {
    // START_USER_CODE-USER_txtAgreement_TEST
    // END_USER_CODE-USER_txtAgreement_TEST
  });
  test("gridTrdSettlSrch_txtAgreeType(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtAgreeType = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txtAgreeTypebtn =
      gridTrdSettlSrch_txtAgreeType.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtAgreeType =
      gridTrdSettlSrch_txtAgreeType.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtAgreeType.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtAgreeType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtAgreeTypedata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtAgreeTypedata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtAgreeTypebtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtAgreeTypeadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtAgreeTypeadd, { button: 0 });
      });
    }
    const txtAgreeType = screen.getByTestId("txtAgreeType");
    expect(txtAgreeType.tagName).toBe("INPUT");
    expect(txtAgreeType.type).toBe("text");
    expect(txtAgreeType.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtAgreeType, "1");
    });
  });
  test("Custom Test Cases for txtAgreeType", () => {
    // START_USER_CODE-USER_txtAgreeType_TEST
    // END_USER_CODE-USER_txtAgreeType_TEST
  });
  test("gridTrdSettlSrch_txtCol(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtCol = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txtColbtn =
      gridTrdSettlSrch_txtCol.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtCol =
      gridTrdSettlSrch_txtCol.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtCol.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtCol.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtColdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtColdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtColbtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtColadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtColadd, { button: 0 });
      });
    }
    const txtCol = screen.getByTestId("txtCol");
    expect(txtCol.tagName).toBe("INPUT");
    expect(txtCol.type).toBe("text");
    expect(txtCol.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtCol, "1");
    });
  });
  test("Custom Test Cases for txtCol", () => {
    // START_USER_CODE-USER_txtCol_TEST
    // END_USER_CODE-USER_txtCol_TEST
  });
  test("gridTrdSettlSrch_txtGen(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtGen = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txtGenbtn =
      gridTrdSettlSrch_txtGen.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtGen =
      gridTrdSettlSrch_txtGen.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtGen.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtGen.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtGendata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtGendata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtGenbtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtGenadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtGenadd, { button: 0 });
      });
    }
    const txtGen = screen.getByTestId("txtGen");
    expect(txtGen.tagName).toBe("INPUT");
    expect(txtGen.type).toBe("text");
    expect(txtGen.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtGen, "1");
    });
  });
  test("Custom Test Cases for txtGen", () => {
    // START_USER_CODE-USER_txtGen_TEST
    // END_USER_CODE-USER_txtGen_TEST
  });
  test("gridTrdSettlSrch_txtLoc(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtLoc = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txtLocbtn =
      gridTrdSettlSrch_txtLoc.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtLoc =
      gridTrdSettlSrch_txtLoc.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtLoc.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtLoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtLocdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtLocdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtLocbtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtLocadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtLocadd, { button: 0 });
      });
    }
    const txtLoc = screen.getByTestId("txtLoc");
    expect(txtLoc.tagName).toBe("INPUT");
    expect(txtLoc.type).toBe("text");
    expect(txtLoc.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtLoc, "1");
    });
  });
  test("Custom Test Cases for txtLoc", () => {
    // START_USER_CODE-USER_txtLoc_TEST
    // END_USER_CODE-USER_txtLoc_TEST
  });
  test("gridTrdSettlSrch_txtNetWeightC(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtNetWeightC = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txtNetWeightCbtn =
      gridTrdSettlSrch_txtNetWeightC.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtNetWeightC =
      gridTrdSettlSrch_txtNetWeightC.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtNetWeightC.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtNetWeightC.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtNetWeightCdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtNetWeightCdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtNetWeightCbtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtNetWeightCadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtNetWeightCadd, { button: 0 });
      });
    }
    const txtNetWeightC = screen.getByTestId("txtNetWeightC");
    expect(txtNetWeightC.tagName).toBe("INPUT");
    expect(txtNetWeightC.type).toBe("text");
    expect(txtNetWeightC.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtNetWeightC, "1");
    });
  });
  test("Custom Test Cases for txtNetWeightC", () => {
    // START_USER_CODE-USER_txtNetWeightC_TEST
    // END_USER_CODE-USER_txtNetWeightC_TEST
  });
  test("gridTrdSettlSrch_txtPnutType(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtPnutType = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txtPnutTypebtn =
      gridTrdSettlSrch_txtPnutType.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtPnutType =
      gridTrdSettlSrch_txtPnutType.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtPnutType.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtPnutType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtPnutTypedata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtPnutTypedata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtPnutTypebtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtPnutTypeadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtPnutTypeadd, { button: 0 });
      });
    }
    const txtPnutType = screen.getByTestId("txtPnutType");
    expect(txtPnutType.tagName).toBe("INPUT");
    expect(txtPnutType.type).toBe("text");
    expect(txtPnutType.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPnutType, "1");
    });
  });
  test("Custom Test Cases for txtPnutType", () => {
    // START_USER_CODE-USER_txtPnutType_TEST
    // END_USER_CODE-USER_txtPnutType_TEST
  });
  test("gridTrdSettlSrch_txtPnutVariety(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtPnutVariety = screen.getByTestId(
      "gridTrdSettlSrch"
    );
    let gridTrdSettlSrch_txtPnutVarietybtn =
      gridTrdSettlSrch_txtPnutVariety.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtPnutVariety =
      gridTrdSettlSrch_txtPnutVariety.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtPnutVariety.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtPnutVariety.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtPnutVarietydata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtPnutVarietydata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtPnutVarietybtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtPnutVarietyadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtPnutVarietyadd, { button: 0 });
      });
    }
    const txtPnutVariety = screen.getByTestId("txtPnutVariety");
    expect(txtPnutVariety.tagName).toBe("INPUT");
    expect(txtPnutVariety.type).toBe("text");
    expect(txtPnutVariety.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtPnutVariety, "1");
    });
  });
  test("Custom Test Cases for txtPnutVariety", () => {
    // START_USER_CODE-USER_txtPnutVariety_TEST
    // END_USER_CODE-USER_txtPnutVariety_TEST
  });
  test("gridTrdSettlSrch_txtSeg(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtSeg = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txtSegbtn =
      gridTrdSettlSrch_txtSeg.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtSeg =
      gridTrdSettlSrch_txtSeg.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtSeg.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtSeg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtSegdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtSegdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtSegbtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtSegadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtSegadd, { button: 0 });
      });
    }
    const txtSeg = screen.getByTestId("txtSeg");
    expect(txtSeg.tagName).toBe("INPUT");
    expect(txtSeg.type).toBe("text");
    expect(txtSeg.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSeg, "1");
    });
  });
  test("Custom Test Cases for txtSeg", () => {
    // START_USER_CODE-USER_txtSeg_TEST
    // END_USER_CODE-USER_txtSeg_TEST
  });
  test("gridTrdSettlSrch_txtSegValueC(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtSegValueC = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txtSegValueCbtn =
      gridTrdSettlSrch_txtSegValueC.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtSegValueC =
      gridTrdSettlSrch_txtSegValueC.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtSegValueC.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtSegValueC.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtSegValueCdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtSegValueCdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtSegValueCbtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtSegValueCadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtSegValueCadd, { button: 0 });
      });
    }
    const txtSegValueC = screen.getByTestId("txtSegValueC");
    expect(txtSegValueC.tagName).toBe("INPUT");
    expect(txtSegValueC.type).toBe("text");
    expect(txtSegValueC.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSegValueC, "1");
    });
  });
  test("Custom Test Cases for txtSegValueC", () => {
    // START_USER_CODE-USER_txtSegValueC_TEST
    // END_USER_CODE-USER_txtSegValueC_TEST
  });
  test("gridTrdSettlSrch_txtSettle(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtSettle = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txtSettlebtn =
      gridTrdSettlSrch_txtSettle.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtSettle =
      gridTrdSettlSrch_txtSettle.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtSettle.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtSettle.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtSettledata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtSettledata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtSettlebtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtSettleadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtSettleadd, { button: 0 });
      });
    }
    const txtSettle = screen.getByTestId("txtSettle");
    expect(txtSettle.tagName).toBe("INPUT");
    expect(txtSettle.type).toBe("text");
    expect(txtSettle.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSettle, "1");
    });
  });
  test("Custom Test Cases for txtSettle", () => {
    // START_USER_CODE-USER_txtSettle_TEST
    // END_USER_CODE-USER_txtSettle_TEST
  });
  test("gridTrdSettlSrch_txtStatusC(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtStatusC = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txtStatusCbtn =
      gridTrdSettlSrch_txtStatusC.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtStatusC =
      gridTrdSettlSrch_txtStatusC.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtStatusC.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtStatusC.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtStatusCdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtStatusCdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtStatusCbtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtStatusCadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtStatusCadd, { button: 0 });
      });
    }
    const txtStatusC = screen.getByTestId("txtStatusC");
    expect(txtStatusC.tagName).toBe("INPUT");
    expect(txtStatusC.type).toBe("text");
    expect(txtStatusC.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtStatusC, "1");
    });
  });
  test("Custom Test Cases for txtStatusC", () => {
    // START_USER_CODE-USER_txtStatusC_TEST
    // END_USER_CODE-USER_txtStatusC_TEST
  });
  test("gridTrdSettlSrch_txtTKClbs(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtTKClbs = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txtTKClbsbtn =
      gridTrdSettlSrch_txtTKClbs.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtTKClbs =
      gridTrdSettlSrch_txtTKClbs.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtTKClbs.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtTKClbs.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtTKClbsdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtTKClbsdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtTKClbsbtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtTKClbsadd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtTKClbsadd, { button: 0 });
      });
    }
    const txtTKClbs = screen.getByTestId("txtTKClbs");
    expect(txtTKClbs.tagName).toBe("INPUT");
    expect(txtTKClbs.type).toBe("text");
    expect(txtTKClbs.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtTKClbs, "1");
    });
  });
  test("Custom Test Cases for txtTKClbs", () => {
    // START_USER_CODE-USER_txtTKClbs_TEST
    // END_USER_CODE-USER_txtTKClbs_TEST
  });
  test("txtTradeInspect(Textbox Widget) Test Cases", async () => {
    const txtTradeInspect = screen.getByTestId("txtTradeInspect");
    expect(txtTradeInspect.tagName).toBe("INPUT");
    expect(txtTradeInspect.type).toBe("text");
    expect(txtTradeInspect.classList).toContain("textboxWidgetClass");
    expect(txtTradeInspect.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleSearch_txtTradeInspect")
    );
    await act(async () => {
      userEvent.type(txtTradeInspect, "1");
    });
  });
  test("Custom Test Cases for txtTradeInspect", () => {
    // START_USER_CODE-USER_txtTradeInspect_TEST
    // END_USER_CODE-USER_txtTradeInspect_TEST
  });
  test("txtTradeSettle(Textbox Widget) Test Cases", async () => {
    const txtTradeSettle = screen.getByTestId("txtTradeSettle");
    expect(txtTradeSettle.tagName).toBe("INPUT");
    expect(txtTradeSettle.type).toBe("text");
    expect(txtTradeSettle.classList).toContain("textboxWidgetClass");
    expect(txtTradeSettle.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleSearch_txtTradeSettle")
    );
    await act(async () => {
      userEvent.type(txtTradeSettle, "1");
    });
  });
  test("Custom Test Cases for txtTradeSettle", () => {
    // START_USER_CODE-USER_txtTradeSettle_TEST
    // END_USER_CODE-USER_txtTradeSettle_TEST
  });
  test("txtVendor(Textbox Widget) Test Cases", async () => {
    const txtVendor = screen.getByTestId("txtVendor");
    expect(txtVendor.tagName).toBe("INPUT");
    expect(txtVendor.type).toBe("text");
    expect(txtVendor.classList).toContain("textboxWidgetClass");
    expect(txtVendor.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeSettleSearch_txtVendor")
    );
    await act(async () => {
      userEvent.type(txtVendor, "1");
    });
  });
  test("Custom Test Cases for txtVendor", () => {
    // START_USER_CODE-USER_txtVendor_TEST
    // END_USER_CODE-USER_txtVendor_TEST
  });
  test("gridTrdSettlSrch_txtVndr(Grid Widget) Test Cases", async () => {
    let gridTrdSettlSrch_txtVndr = screen.getByTestId("gridTrdSettlSrch");
    let gridTrdSettlSrch_txtVndrbtn =
      gridTrdSettlSrch_txtVndr.nextElementSibling.firstElementChild;
    gridTrdSettlSrch_txtVndr =
      gridTrdSettlSrch_txtVndr.parentElement.parentElement.parentElement;
    expect(gridTrdSettlSrch_txtVndr.tagName).toBe("DIV");
    expect(gridTrdSettlSrch_txtVndr.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(t("Settlements:TradeSettleSearch_gridTrdSettlSrch"))
    ).toBeInTheDocument;
    const gridTrdSettlSrch_txtVndrdata = screen.queryAllByText(
      "There are no records to display"
    );
    let isEmptyGrid = false;
    gridTrdSettlSrch_txtVndrdata.forEach(element => {
      if (isEmptyGrid) return false;
      isEmptyGrid = element.parentElement.parentElement.parentElement.parentElement.parentElement.id.startsWith(
        "gridTrdSettlSrch"
      )
        ? true
        : false;
    });
    if (isEmptyGrid === true) {
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtVndrbtn, { button: 0 });
      });
      const gridTrdSettlSrch_txtVndradd = screen.getByText("Add");
      await act(async () => {
        userEvent.click(gridTrdSettlSrch_txtVndradd, { button: 0 });
      });
    }
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVndr, "1");
    });
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
});
