/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_InventoryAdjustments from "./InventoryAdjustments";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("InventoryAdjustments Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_InventoryAdjustments />
      );
    });
  });
  afterEach(cleanup);
  test("is InventoryAdjustments Loads Successfully", () => {
    expect(screen.getByText("InventoryAdjustments")).toBeInTheDocument;
  });
  test("Custom Test Cases for InventoryAdjustments", () => {
    // START_USER_CODE-USER_InventoryAdjustments_Custom_Test_Case
    // END_USER_CODE-USER_InventoryAdjustments_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_InventoryAdjustments />
      );
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:InventoryAdjustments_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnExporttoExcel(Button Widget) Test Cases", async () => {
    const btnExporttoExcel = screen.getByTestId("btnExporttoExcel");
    expect(btnExporttoExcel).toBeInTheDocument;
    expect(btnExporttoExcel.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:InventoryAdjustments_btnExporttoExcel"
      )
    );
  });
  test("Custom Test Cases for btnExporttoExcel", () => {
    // START_USER_CODE-USER_btnExporttoExcel_TEST
    // END_USER_CODE-USER_btnExporttoExcel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:InventoryAdjustments_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:InventoryAdjustments_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("gridAdjustments(Grid Widget) Test Cases", async () => {
    let gridAdjustments = screen.getByTestId("gridAdjustments");
    let gridAdjustmentsbtn =
      gridAdjustments.nextElementSibling.firstElementChild;
    gridAdjustments = gridAdjustments.parentElement.parentElement.parentElement;
    expect(gridAdjustments.tagName).toBe("DIV");
    expect(gridAdjustments.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAdjustments", () => {
    // START_USER_CODE-USER_gridAdjustments_TEST
    // END_USER_CODE-USER_gridAdjustments_TEST
  });
  test("grpbxInventoryAdjustments(GroupBox Widget) Test Cases", async () => {
    const grpbxInventoryAdjustments = screen.getByTestId(
      "grpbxInventoryAdjustments"
    );
    expect(grpbxInventoryAdjustments.tagName).toBe("BUTTON");
    expect(grpbxInventoryAdjustments.type).toBe("button");
    expect(grpbxInventoryAdjustments.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxInventoryAdjustments", () => {
    // START_USER_CODE-USER_grpbxInventoryAdjustments_TEST
    // END_USER_CODE-USER_grpbxInventoryAdjustments_TEST
  });
  test("lblAdjustments(Label Widget) Test Cases", async () => {
    const lblAdjustments = screen.getByTestId("lblAdjustments");
    expect(lblAdjustments.tagName).toBe("LABEL");
    expect(lblAdjustments.classList).toContain("form-label");
    expect(lblAdjustments.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:InventoryAdjustments_lblAdjustments"
      )
    );
  });
  test("Custom Test Cases for lblAdjustments", () => {
    // START_USER_CODE-USER_lblAdjustments_TEST
    // END_USER_CODE-USER_lblAdjustments_TEST
  });
  test("gridAdjustments_txtcolAddDateTime(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolAddDateTime = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolAddDateTimebtn =
      gridAdjustments_txtcolAddDateTime.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolAddDateTime =
      gridAdjustments_txtcolAddDateTime.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolAddDateTime.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolAddDateTime.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAddDateTime", () => {
    // START_USER_CODE-USER_txtcolAddDateTime_TEST
    // END_USER_CODE-USER_txtcolAddDateTime_TEST
  });
  test("gridAdjustments_txtcolAddUser(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolAddUser = screen.getByTestId("gridAdjustments");
    let gridAdjustments_txtcolAddUserbtn =
      gridAdjustments_txtcolAddUser.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolAddUser =
      gridAdjustments_txtcolAddUser.parentElement.parentElement.parentElement;
    expect(gridAdjustments_txtcolAddUser.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolAddUser.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAddUser", () => {
    // START_USER_CODE-USER_txtcolAddUser_TEST
    // END_USER_CODE-USER_txtcolAddUser_TEST
  });
  test("gridAdjustments_txtcolAdjLbs(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolAdjLbs = screen.getByTestId("gridAdjustments");
    let gridAdjustments_txtcolAdjLbsbtn =
      gridAdjustments_txtcolAdjLbs.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolAdjLbs =
      gridAdjustments_txtcolAdjLbs.parentElement.parentElement.parentElement;
    expect(gridAdjustments_txtcolAdjLbs.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolAdjLbs.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAdjLbs", () => {
    // START_USER_CODE-USER_txtcolAdjLbs_TEST
    // END_USER_CODE-USER_txtcolAdjLbs_TEST
  });
  test("gridAdjustments_txtcolComments(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolComments = screen.getByTestId("gridAdjustments");
    let gridAdjustments_txtcolCommentsbtn =
      gridAdjustments_txtcolComments.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolComments =
      gridAdjustments_txtcolComments.parentElement.parentElement.parentElement;
    expect(gridAdjustments_txtcolComments.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolComments.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolComments", () => {
    // START_USER_CODE-USER_txtcolComments_TEST
    // END_USER_CODE-USER_txtcolComments_TEST
  });
  test("gridAdjustments_txtcolDateTime(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolDateTime = screen.getByTestId("gridAdjustments");
    let gridAdjustments_txtcolDateTimebtn =
      gridAdjustments_txtcolDateTime.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolDateTime =
      gridAdjustments_txtcolDateTime.parentElement.parentElement.parentElement;
    expect(gridAdjustments_txtcolDateTime.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolDateTime.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolDateTime", () => {
    // START_USER_CODE-USER_txtcolDateTime_TEST
    // END_USER_CODE-USER_txtcolDateTime_TEST
  });
  test("gridAdjustments_txtcolOleic(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolOleic = screen.getByTestId("gridAdjustments");
    let gridAdjustments_txtcolOleicbtn =
      gridAdjustments_txtcolOleic.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolOleic =
      gridAdjustments_txtcolOleic.parentElement.parentElement.parentElement;
    expect(gridAdjustments_txtcolOleic.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolOleic.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOleic", () => {
    // START_USER_CODE-USER_txtcolOleic_TEST
    // END_USER_CODE-USER_txtcolOleic_TEST
  });
  test("gridAdjustments_txtcolOrganic(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolOrganic = screen.getByTestId("gridAdjustments");
    let gridAdjustments_txtcolOrganicbtn =
      gridAdjustments_txtcolOrganic.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolOrganic =
      gridAdjustments_txtcolOrganic.parentElement.parentElement.parentElement;
    expect(gridAdjustments_txtcolOrganic.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolOrganic.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOrganic", () => {
    // START_USER_CODE-USER_txtcolOrganic_TEST
    // END_USER_CODE-USER_txtcolOrganic_TEST
  });
  test("gridAdjustments_txtcolPeanutType(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolPeanutType = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolPeanutTypebtn =
      gridAdjustments_txtcolPeanutType.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolPeanutType =
      gridAdjustments_txtcolPeanutType.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolPeanutType.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolPeanutType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutType", () => {
    // START_USER_CODE-USER_txtcolPeanutType_TEST
    // END_USER_CODE-USER_txtcolPeanutType_TEST
  });
  test("gridAdjustments_txtcolPeanutVariety(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolPeanutVariety = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolPeanutVarietybtn =
      gridAdjustments_txtcolPeanutVariety.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolPeanutVariety =
      gridAdjustments_txtcolPeanutVariety.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolPeanutVariety.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolPeanutVariety.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPeanutVariety", () => {
    // START_USER_CODE-USER_txtcolPeanutVariety_TEST
    // END_USER_CODE-USER_txtcolPeanutVariety_TEST
  });
  test("gridAdjustments_txtcolSeedGen(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolSeedGen = screen.getByTestId("gridAdjustments");
    let gridAdjustments_txtcolSeedGenbtn =
      gridAdjustments_txtcolSeedGen.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolSeedGen =
      gridAdjustments_txtcolSeedGen.parentElement.parentElement.parentElement;
    expect(gridAdjustments_txtcolSeedGen.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolSeedGen.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeedGen", () => {
    // START_USER_CODE-USER_txtcolSeedGen_TEST
    // END_USER_CODE-USER_txtcolSeedGen_TEST
  });
  test("gridAdjustments_txtcolSeg(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolSeg = screen.getByTestId("gridAdjustments");
    let gridAdjustments_txtcolSegbtn =
      gridAdjustments_txtcolSeg.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolSeg =
      gridAdjustments_txtcolSeg.parentElement.parentElement.parentElement;
    expect(gridAdjustments_txtcolSeg.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolSeg.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSeg", () => {
    // START_USER_CODE-USER_txtcolSeg_TEST
    // END_USER_CODE-USER_txtcolSeg_TEST
  });
  test("gridAdjustments_txtcolWhseBin(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolWhseBin = screen.getByTestId("gridAdjustments");
    let gridAdjustments_txtcolWhseBinbtn =
      gridAdjustments_txtcolWhseBin.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolWhseBin =
      gridAdjustments_txtcolWhseBin.parentElement.parentElement.parentElement;
    expect(gridAdjustments_txtcolWhseBin.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolWhseBin.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:InventoryAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWhseBin", () => {
    // START_USER_CODE-USER_txtcolWhseBin_TEST
    // END_USER_CODE-USER_txtcolWhseBin_TEST
  });
});
