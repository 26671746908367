/* eslint-disable*/
import React from "react";
import ContractManagement_FlexMktSign from "./FlexMktSign";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FlexMktSign Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_FlexMktSign />);
    });
  });
  afterEach(cleanup);
  test("is FlexMktSign Loads Successfully", () => {
    expect(screen.getByText("FlexMktSign")).toBeInTheDocument;
  });
  test("Custom Test Cases for FlexMktSign", () => {
    // START_USER_CODE-USER_FlexMktSign_Custom_Test_Case
    // END_USER_CODE-USER_FlexMktSign_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<ContractManagement_FlexMktSign />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(t("ContractManagement:FlexMktSign_btn1"));
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btn2(Button Widget) Test Cases", async () => {
    const btn2 = screen.getByTestId("btn2");
    expect(btn2).toBeInTheDocument;
    expect(btn2.textContent).toEqual(t("ContractManagement:FlexMktSign_btn2"));
  });
  test("Custom Test Cases for btn2", () => {
    // START_USER_CODE-USER_btn2_TEST
    // END_USER_CODE-USER_btn2_TEST
  });
  test("btn3(Button Widget) Test Cases", async () => {
    const btn3 = screen.getByTestId("btn3");
    expect(btn3).toBeInTheDocument;
    expect(btn3.textContent).toEqual(t("ContractManagement:FlexMktSign_btn3"));
  });
  test("Custom Test Cases for btn3", () => {
    // START_USER_CODE-USER_btn3_TEST
    // END_USER_CODE-USER_btn3_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("ContractManagement:FlexMktSign_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnCancleScan(Button Widget) Test Cases", async () => {
    const btnCancleScan = screen.getByTestId("btnCancleScan");
    expect(btnCancleScan).toBeInTheDocument;
    expect(btnCancleScan.textContent).toEqual(
      t("ContractManagement:FlexMktSign_btnCancleScan")
    );
  });
  test("Custom Test Cases for btnCancleScan", () => {
    // START_USER_CODE-USER_btnCancleScan_TEST
    // END_USER_CODE-USER_btnCancleScan_TEST
  });
  test("btnCncl(Button Widget) Test Cases", async () => {
    const btnCncl = screen.getByTestId("btnCncl");
    expect(btnCncl).toBeInTheDocument;
    expect(btnCncl.textContent).toEqual(
      t("ContractManagement:FlexMktSign_btnCncl")
    );
  });
  test("Custom Test Cases for btnCncl", () => {
    // START_USER_CODE-USER_btnCncl_TEST
    // END_USER_CODE-USER_btnCncl_TEST
  });
  test("btnSaveFrmExFile(Button Widget) Test Cases", async () => {
    const btnSaveFrmExFile = screen.getByTestId("btnSaveFrmExFile");
    expect(btnSaveFrmExFile).toBeInTheDocument;
    expect(btnSaveFrmExFile.textContent).toEqual(
      t("ContractManagement:FlexMktSign_btnSaveFrmExFile")
    );
  });
  test("Custom Test Cases for btnSaveFrmExFile", () => {
    // START_USER_CODE-USER_btnSaveFrmExFile_TEST
    // END_USER_CODE-USER_btnSaveFrmExFile_TEST
  });
  test("btnScanCntrct(Button Widget) Test Cases", async () => {
    const btnScanCntrct = screen.getByTestId("btnScanCntrct");
    expect(btnScanCntrct).toBeInTheDocument;
    expect(btnScanCntrct.textContent).toEqual(
      t("ContractManagement:FlexMktSign_btnScanCntrct")
    );
  });
  test("Custom Test Cases for btnScanCntrct", () => {
    // START_USER_CODE-USER_btnScanCntrct_TEST
    // END_USER_CODE-USER_btnScanCntrct_TEST
  });
  test("btnScanDocFeeder(Button Widget) Test Cases", async () => {
    const btnScanDocFeeder = screen.getByTestId("btnScanDocFeeder");
    expect(btnScanDocFeeder).toBeInTheDocument;
    expect(btnScanDocFeeder.textContent).toEqual(
      t("ContractManagement:FlexMktSign_btnScanDocFeeder")
    );
  });
  test("Custom Test Cases for btnScanDocFeeder", () => {
    // START_USER_CODE-USER_btnScanDocFeeder_TEST
    // END_USER_CODE-USER_btnScanDocFeeder_TEST
  });
  test("btnScanFlatbed(Button Widget) Test Cases", async () => {
    const btnScanFlatbed = screen.getByTestId("btnScanFlatbed");
    expect(btnScanFlatbed).toBeInTheDocument;
    expect(btnScanFlatbed.textContent).toEqual(
      t("ContractManagement:FlexMktSign_btnScanFlatbed")
    );
  });
  test("Custom Test Cases for btnScanFlatbed", () => {
    // START_USER_CODE-USER_btnScanFlatbed_TEST
    // END_USER_CODE-USER_btnScanFlatbed_TEST
  });
  test("btnSignCntrctWOScng(Button Widget) Test Cases", async () => {
    const btnSignCntrctWOScng = screen.getByTestId("btnSignCntrctWOScng");
    expect(btnSignCntrctWOScng).toBeInTheDocument;
    expect(btnSignCntrctWOScng.textContent).toEqual(
      t("ContractManagement:FlexMktSign_btnSignCntrctWOScng")
    );
  });
  test("Custom Test Cases for btnSignCntrctWOScng", () => {
    // START_USER_CODE-USER_btnSignCntrctWOScng_TEST
    // END_USER_CODE-USER_btnSignCntrctWOScng_TEST
  });
  test("btnVerSave(Button Widget) Test Cases", async () => {
    const btnVerSave = screen.getByTestId("btnVerSave");
    expect(btnVerSave).toBeInTheDocument;
    expect(btnVerSave.textContent).toEqual(
      t("ContractManagement:FlexMktSign_btnVerSave")
    );
  });
  test("Custom Test Cases for btnVerSave", () => {
    // START_USER_CODE-USER_btnVerSave_TEST
    // END_USER_CODE-USER_btnVerSave_TEST
  });
  test("grpbxCntrctPrcInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxCntrctPrcInfo = screen.getByTestId("grpbxCntrctPrcInfo");
    expect(grpbxCntrctPrcInfo.tagName).toBe("BUTTON");
    expect(grpbxCntrctPrcInfo.type).toBe("button");
    expect(grpbxCntrctPrcInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCntrctPrcInfo", () => {
    // START_USER_CODE-USER_grpbxCntrctPrcInfo_TEST
    // END_USER_CODE-USER_grpbxCntrctPrcInfo_TEST
  });
  test("grpbxSavingCntrctOpt(GroupBox Widget) Test Cases", async () => {
    const grpbxSavingCntrctOpt = screen.getByTestId("grpbxSavingCntrctOpt");
    expect(grpbxSavingCntrctOpt.tagName).toBe("BUTTON");
    expect(grpbxSavingCntrctOpt.type).toBe("button");
    expect(grpbxSavingCntrctOpt.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSavingCntrctOpt", () => {
    // START_USER_CODE-USER_grpbxSavingCntrctOpt_TEST
    // END_USER_CODE-USER_grpbxSavingCntrctOpt_TEST
  });
  test("grpbxScanningOptions(GroupBox Widget) Test Cases", async () => {
    const grpbxScanningOptions = screen.getByTestId("grpbxScanningOptions");
    expect(grpbxScanningOptions.tagName).toBe("BUTTON");
    expect(grpbxScanningOptions.type).toBe("button");
    expect(grpbxScanningOptions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxScanningOptions", () => {
    // START_USER_CODE-USER_grpbxScanningOptions_TEST
    // END_USER_CODE-USER_grpbxScanningOptions_TEST
  });
  test("grpbxVerResultActs(GroupBox Widget) Test Cases", async () => {
    const grpbxVerResultActs = screen.getByTestId("grpbxVerResultActs");
    expect(grpbxVerResultActs.tagName).toBe("BUTTON");
    expect(grpbxVerResultActs.type).toBe("button");
    expect(grpbxVerResultActs.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVerResultActs", () => {
    // START_USER_CODE-USER_grpbxVerResultActs_TEST
    // END_USER_CODE-USER_grpbxVerResultActs_TEST
  });
  test("lblCntrctPrcInfo(Label Widget) Test Cases", async () => {
    const lblCntrctPrcInfo = screen.getByTestId("lblCntrctPrcInfo");
    expect(lblCntrctPrcInfo.tagName).toBe("LABEL");
    expect(lblCntrctPrcInfo.classList).toContain("form-label");
    expect(lblCntrctPrcInfo.textContent).toEqual(
      t("ContractManagement:FlexMktSign_lblCntrctPrcInfo")
    );
  });
  test("Custom Test Cases for lblCntrctPrcInfo", () => {
    // START_USER_CODE-USER_lblCntrctPrcInfo_TEST
    // END_USER_CODE-USER_lblCntrctPrcInfo_TEST
  });
  test("lblSavingCntrctPrcOpt(Label Widget) Test Cases", async () => {
    const lblSavingCntrctPrcOpt = screen.getByTestId("lblSavingCntrctPrcOpt");
    expect(lblSavingCntrctPrcOpt.tagName).toBe("LABEL");
    expect(lblSavingCntrctPrcOpt.classList).toContain("form-label");
    expect(lblSavingCntrctPrcOpt.textContent).toEqual(
      t("ContractManagement:FlexMktSign_lblSavingCntrctPrcOpt")
    );
  });
  test("Custom Test Cases for lblSavingCntrctPrcOpt", () => {
    // START_USER_CODE-USER_lblSavingCntrctPrcOpt_TEST
    // END_USER_CODE-USER_lblSavingCntrctPrcOpt_TEST
  });
  test("lblScanningOptions(Label Widget) Test Cases", async () => {
    const lblScanningOptions = screen.getByTestId("lblScanningOptions");
    expect(lblScanningOptions.tagName).toBe("LABEL");
    expect(lblScanningOptions.classList).toContain("form-label");
    expect(lblScanningOptions.textContent).toEqual(
      t("ContractManagement:FlexMktSign_lblScanningOptions")
    );
  });
  test("Custom Test Cases for lblScanningOptions", () => {
    // START_USER_CODE-USER_lblScanningOptions_TEST
    // END_USER_CODE-USER_lblScanningOptions_TEST
  });
  test("txtCntrctPrcNum(Textbox Widget) Test Cases", async () => {
    const txtCntrctPrcNum = screen.getByTestId("txtCntrctPrcNum");
    expect(txtCntrctPrcNum.tagName).toBe("INPUT");
    expect(txtCntrctPrcNum.type).toBe("text");
    expect(txtCntrctPrcNum.classList).toContain("textboxWidgetClass");
    expect(txtCntrctPrcNum.previousElementSibling.textContent).toEqual(
      t("ContractManagement:FlexMktSign_txtCntrctPrcNum")
    );
    await act(async () => {
      userEvent.type(txtCntrctPrcNum, "1");
    });
  });
  test("Custom Test Cases for txtCntrctPrcNum", () => {
    // START_USER_CODE-USER_txtCntrctPrcNum_TEST
    // END_USER_CODE-USER_txtCntrctPrcNum_TEST
  });
});
