/* eslint-disable*/
import React from "react";
import SystemMaintenanceSpecialFunctions_SecurityGroup from "./SecurityGroup";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SecurityGroup Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_SecurityGroup />);
    });
  });
  afterEach(cleanup);
  test("is SecurityGroup Loads Successfully", () => {
    expect(screen.getByText("SecurityGroup")).toBeInTheDocument;
  });
  test("Custom Test Cases for SecurityGroup", () => {
    // START_USER_CODE-USER_SecurityGroup_Custom_Test_Case
    // END_USER_CODE-USER_SecurityGroup_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<SystemMaintenanceSpecialFunctions_SecurityGroup />);
    });
  });
  afterEach(cleanup);
  test("btnSGAdd(Button Widget) Test Cases", async () => {
    const btnSGAdd = screen.getByTestId("btnSGAdd");
    expect(btnSGAdd).toBeInTheDocument;
    expect(btnSGAdd.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_btnSGAdd")
    );
  });
  test("Custom Test Cases for btnSGAdd", () => {
    // START_USER_CODE-USER_btnSGAdd_TEST
    // END_USER_CODE-USER_btnSGAdd_TEST
  });
  test("btnAddFunction(Button Widget) Test Cases", async () => {
    const btnAddFunction = screen.getByTestId("btnAddFunction");
    expect(btnAddFunction).toBeInTheDocument;
    expect(btnAddFunction.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_btnAddFunction")
    );
  });
  test("Custom Test Cases for btnAddFunction", () => {
    // START_USER_CODE-USER_btnAddFunction_TEST
    // END_USER_CODE-USER_btnAddFunction_TEST
  });
  test("btnAddLocation(Button Widget) Test Cases", async () => {
    const btnAddLocation = screen.getByTestId("btnAddLocation");
    expect(btnAddLocation).toBeInTheDocument;
    expect(btnAddLocation.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_btnAddLocation")
    );
  });
  test("Custom Test Cases for btnAddLocation", () => {
    // START_USER_CODE-USER_btnAddLocation_TEST
    // END_USER_CODE-USER_btnAddLocation_TEST
  });
  test("btnAddUser(Button Widget) Test Cases", async () => {
    const btnAddUser = screen.getByTestId("btnAddUser");
    expect(btnAddUser).toBeInTheDocument;
    expect(btnAddUser.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_btnAddUser")
    );
  });
  test("Custom Test Cases for btnAddUser", () => {
    // START_USER_CODE-USER_btnAddUser_TEST
    // END_USER_CODE-USER_btnAddUser_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnDeleteFunction(Button Widget) Test Cases", async () => {
    const btnDeleteFunction = screen.getByTestId("btnDeleteFunction");
    expect(btnDeleteFunction).toBeInTheDocument;
    expect(btnDeleteFunction.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_btnDeleteFunction")
    );
  });
  test("Custom Test Cases for btnDeleteFunction", () => {
    // START_USER_CODE-USER_btnDeleteFunction_TEST
    // END_USER_CODE-USER_btnDeleteFunction_TEST
  });
  test("btnDeleteLocation(Button Widget) Test Cases", async () => {
    const btnDeleteLocation = screen.getByTestId("btnDeleteLocation");
    expect(btnDeleteLocation).toBeInTheDocument;
    expect(btnDeleteLocation.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_btnDeleteLocation")
    );
  });
  test("Custom Test Cases for btnDeleteLocation", () => {
    // START_USER_CODE-USER_btnDeleteLocation_TEST
    // END_USER_CODE-USER_btnDeleteLocation_TEST
  });
  test("btnDeleteUser(Button Widget) Test Cases", async () => {
    const btnDeleteUser = screen.getByTestId("btnDeleteUser");
    expect(btnDeleteUser).toBeInTheDocument;
    expect(btnDeleteUser.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_btnDeleteUser")
    );
  });
  test("Custom Test Cases for btnDeleteUser", () => {
    // START_USER_CODE-USER_btnDeleteUser_TEST
    // END_USER_CODE-USER_btnDeleteUser_TEST
  });
  test("btnEditFunction(Button Widget) Test Cases", async () => {
    const btnEditFunction = screen.getByTestId("btnEditFunction");
    expect(btnEditFunction).toBeInTheDocument;
    expect(btnEditFunction.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_btnEditFunction")
    );
  });
  test("Custom Test Cases for btnEditFunction", () => {
    // START_USER_CODE-USER_btnEditFunction_TEST
    // END_USER_CODE-USER_btnEditFunction_TEST
  });
  test("btnEditLocation(Button Widget) Test Cases", async () => {
    const btnEditLocation = screen.getByTestId("btnEditLocation");
    expect(btnEditLocation).toBeInTheDocument;
    expect(btnEditLocation.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_btnEditLocation")
    );
  });
  test("Custom Test Cases for btnEditLocation", () => {
    // START_USER_CODE-USER_btnEditLocation_TEST
    // END_USER_CODE-USER_btnEditLocation_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxFunctions(GroupBox Widget) Test Cases", async () => {
    const grpbxFunctions = screen.getByTestId("grpbxFunctions");
    expect(grpbxFunctions.tagName).toBe("BUTTON");
    expect(grpbxFunctions.type).toBe("button");
    expect(grpbxFunctions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFunctions", () => {
    // START_USER_CODE-USER_grpbxFunctions_TEST
    // END_USER_CODE-USER_grpbxFunctions_TEST
  });
  test("grpbxLocations(GroupBox Widget) Test Cases", async () => {
    const grpbxLocations = screen.getByTestId("grpbxLocations");
    expect(grpbxLocations.tagName).toBe("BUTTON");
    expect(grpbxLocations.type).toBe("button");
    expect(grpbxLocations.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLocations", () => {
    // START_USER_CODE-USER_grpbxLocations_TEST
    // END_USER_CODE-USER_grpbxLocations_TEST
  });
  test("grpbxSecurityGroup(GroupBox Widget) Test Cases", async () => {
    const grpbxSecurityGroup = screen.getByTestId("grpbxSecurityGroup");
    expect(grpbxSecurityGroup.tagName).toBe("BUTTON");
    expect(grpbxSecurityGroup.type).toBe("button");
    expect(grpbxSecurityGroup.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSecurityGroup", () => {
    // START_USER_CODE-USER_grpbxSecurityGroup_TEST
    // END_USER_CODE-USER_grpbxSecurityGroup_TEST
  });
  test("grpbxUsers(GroupBox Widget) Test Cases", async () => {
    const grpbxUsers = screen.getByTestId("grpbxUsers");
    expect(grpbxUsers.tagName).toBe("BUTTON");
    expect(grpbxUsers.type).toBe("button");
    expect(grpbxUsers.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxUsers", () => {
    // START_USER_CODE-USER_grpbxUsers_TEST
    // END_USER_CODE-USER_grpbxUsers_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_lblChangedByValue")
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblFunctions(Label Widget) Test Cases", async () => {
    const lblFunctions = screen.getByTestId("lblFunctions");
    expect(lblFunctions.tagName).toBe("LABEL");
    expect(lblFunctions.classList).toContain("form-label");
    expect(lblFunctions.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_lblFunctions")
    );
  });
  test("Custom Test Cases for lblFunctions", () => {
    // START_USER_CODE-USER_lblFunctions_TEST
    // END_USER_CODE-USER_lblFunctions_TEST
  });
  test("lblLocations(Label Widget) Test Cases", async () => {
    const lblLocations = screen.getByTestId("lblLocations");
    expect(lblLocations.tagName).toBe("LABEL");
    expect(lblLocations.classList).toContain("form-label");
    expect(lblLocations.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_lblLocations")
    );
  });
  test("Custom Test Cases for lblLocations", () => {
    // START_USER_CODE-USER_lblLocations_TEST
    // END_USER_CODE-USER_lblLocations_TEST
  });
  test("lblUsers(Label Widget) Test Cases", async () => {
    const lblUsers = screen.getByTestId("lblUsers");
    expect(lblUsers.tagName).toBe("LABEL");
    expect(lblUsers.classList).toContain("form-label");
    expect(lblUsers.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_lblUsers")
    );
  });
  test("Custom Test Cases for lblUsers", () => {
    // START_USER_CODE-USER_lblUsers_TEST
    // END_USER_CODE-USER_lblUsers_TEST
  });
  test("txtSecurityGroup(Textbox Widget) Test Cases", async () => {
    const txtSecurityGroup = screen.getByTestId("txtSecurityGroup");
    expect(txtSecurityGroup.tagName).toBe("INPUT");
    expect(txtSecurityGroup.type).toBe("text");
    expect(txtSecurityGroup.classList).toContain("textboxWidgetClass");
    expect(txtSecurityGroup.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceSpecialFunctions:SecurityGroup_txtSecurityGroup")
    );
    await act(async () => {
      userEvent.type(txtSecurityGroup, "1");
    });
  });
  test("Custom Test Cases for txtSecurityGroup", () => {
    // START_USER_CODE-USER_txtSecurityGroup_TEST
    // END_USER_CODE-USER_txtSecurityGroup_TEST
  });
});
