/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FreightLocToLocProfile from "./FreightLocToLocProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FreightLocToLocProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FreightLocToLocProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is FreightLocToLocProfile Loads Successfully", () => {
    expect(screen.getByText("FreightLocToLocProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for FreightLocToLocProfile", () => {
    // START_USER_CODE-USER_FreightLocToLocProfile_Custom_Test_Case
    // END_USER_CODE-USER_FreightLocToLocProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FreightLocToLocProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("dtEffDate(Date Widget) Test Cases", async () => {
    const dtEffDate = screen.getByTestId("dtEffDate");
    expect(dtEffDate.tagName).toBe("INPUT");
    expect(dtEffDate.type).toBe("text");
    expect(dtEffDate.classList).toContain("datetimepicker-input");
    expect(dtEffDate.parentElement.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_dtEffDate")
    );
    await act(async () => {
      userEvent.click(dtEffDate.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtEffDate", () => {
    // START_USER_CODE-USER_dtEffDate_TEST
    // END_USER_CODE-USER_dtEffDate_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxFreightLocToLoc(GroupBox Widget) Test Cases", async () => {
    const grpbxFreightLocToLoc = screen.getByTestId("grpbxFreightLocToLoc");
    expect(grpbxFreightLocToLoc.tagName).toBe("BUTTON");
    expect(grpbxFreightLocToLoc.type).toBe("button");
    expect(grpbxFreightLocToLoc.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFreightLocToLoc", () => {
    // START_USER_CODE-USER_grpbxFreightLocToLoc_TEST
    // END_USER_CODE-USER_grpbxFreightLocToLoc_TEST
  });
  test("lblEntrInfo(Label Widget) Test Cases", async () => {
    const lblEntrInfo = screen.getByTestId("lblEntrInfo");
    expect(lblEntrInfo.tagName).toBe("LABEL");
    expect(lblEntrInfo.classList).toContain("form-label");
    expect(lblEntrInfo.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_lblEntrInfo")
    );
  });
  test("Custom Test Cases for lblEntrInfo", () => {
    // START_USER_CODE-USER_lblEntrInfo_TEST
    // END_USER_CODE-USER_lblEntrInfo_TEST
  });
  test("lblNote(Label Widget) Test Cases", async () => {
    const lblNote = screen.getByTestId("lblNote");
    expect(lblNote.tagName).toBe("LABEL");
    expect(lblNote.classList).toContain("form-label");
    expect(lblNote.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_lblNote")
    );
  });
  test("Custom Test Cases for lblNote", () => {
    // START_USER_CODE-USER_lblNote_TEST
    // END_USER_CODE-USER_lblNote_TEST
  });
  test("lblPounds(Label Widget) Test Cases", async () => {
    const lblPounds = screen.getByTestId("lblPounds");
    expect(lblPounds.tagName).toBe("LABEL");
    expect(lblPounds.classList).toContain("form-label");
    expect(lblPounds.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_lblPounds")
    );
  });
  test("Custom Test Cases for lblPounds", () => {
    // START_USER_CODE-USER_lblPounds_TEST
    // END_USER_CODE-USER_lblPounds_TEST
  });
  test("lblPounds2(Label Widget) Test Cases", async () => {
    const lblPounds2 = screen.getByTestId("lblPounds2");
    expect(lblPounds2.tagName).toBe("LABEL");
    expect(lblPounds2.classList).toContain("form-label");
    expect(lblPounds2.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_lblPounds2")
    );
  });
  test("Custom Test Cases for lblPounds2", () => {
    // START_USER_CODE-USER_lblPounds2_TEST
    // END_USER_CODE-USER_lblPounds2_TEST
  });
  test("txtAddedBy(Textbox Widget) Test Cases", async () => {
    const txtAddedBy = screen.getByTestId("txtAddedBy");
    expect(txtAddedBy.tagName).toBe("INPUT");
    expect(txtAddedBy.type).toBe("text");
    expect(txtAddedBy.classList).toContain("textboxWidgetClass");
    expect(txtAddedBy.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_txtAddedBy")
    );
    await act(async () => {
      userEvent.type(txtAddedBy, "1");
    });
  });
  test("Custom Test Cases for txtAddedBy", () => {
    // START_USER_CODE-USER_txtAddedBy_TEST
    // END_USER_CODE-USER_txtAddedBy_TEST
  });
  test("txtCarrAbbr(Textbox Widget) Test Cases", async () => {
    const txtCarrAbbr = screen.getByTestId("txtCarrAbbr");
    expect(txtCarrAbbr.tagName).toBe("INPUT");
    expect(txtCarrAbbr.type).toBe("text");
    expect(txtCarrAbbr.classList).toContain("textboxWidgetClass");
    expect(txtCarrAbbr.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_txtCarrAbbr")
    );
    await act(async () => {
      userEvent.type(txtCarrAbbr, "1");
    });
  });
  test("Custom Test Cases for txtCarrAbbr", () => {
    // START_USER_CODE-USER_txtCarrAbbr_TEST
    // END_USER_CODE-USER_txtCarrAbbr_TEST
  });
  test("txtChngdBy(Textbox Widget) Test Cases", async () => {
    const txtChngdBy = screen.getByTestId("txtChngdBy");
    expect(txtChngdBy.tagName).toBe("INPUT");
    expect(txtChngdBy.type).toBe("text");
    expect(txtChngdBy.classList).toContain("textboxWidgetClass");
    expect(txtChngdBy.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_txtChngdBy")
    );
    await act(async () => {
      userEvent.type(txtChngdBy, "1");
    });
  });
  test("Custom Test Cases for txtChngdBy", () => {
    // START_USER_CODE-USER_txtChngdBy_TEST
    // END_USER_CODE-USER_txtChngdBy_TEST
  });
  test("txtMiles(Textbox Widget) Test Cases", async () => {
    const txtMiles = screen.getByTestId("txtMiles");
    expect(txtMiles.tagName).toBe("INPUT");
    expect(txtMiles.type).toBe("text");
    expect(txtMiles.classList).toContain("textboxWidgetClass");
    expect(txtMiles.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_txtMiles")
    );
    await act(async () => {
      userEvent.type(txtMiles, "1");
    });
  });
  test("Custom Test Cases for txtMiles", () => {
    // START_USER_CODE-USER_txtMiles_TEST
    // END_USER_CODE-USER_txtMiles_TEST
  });
  test("txtMinWt(Textbox Widget) Test Cases", async () => {
    const txtMinWt = screen.getByTestId("txtMinWt");
    expect(txtMinWt.tagName).toBe("INPUT");
    expect(txtMinWt.type).toBe("text");
    expect(txtMinWt.classList).toContain("textboxWidgetClass");
    expect(txtMinWt.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_txtMinWt")
    );
    await act(async () => {
      userEvent.type(txtMinWt, "1");
    });
  });
  test("Custom Test Cases for txtMinWt", () => {
    // START_USER_CODE-USER_txtMinWt_TEST
    // END_USER_CODE-USER_txtMinWt_TEST
  });
  test("txtNoLoadRate(Textbox Widget) Test Cases", async () => {
    const txtNoLoadRate = screen.getByTestId("txtNoLoadRate");
    expect(txtNoLoadRate.tagName).toBe("INPUT");
    expect(txtNoLoadRate.type).toBe("text");
    expect(txtNoLoadRate.classList).toContain("textboxWidgetClass");
    expect(txtNoLoadRate.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:FreightLocToLocProfile_txtNoLoadRate"
      )
    );
    await act(async () => {
      userEvent.type(txtNoLoadRate, "1");
    });
  });
  test("Custom Test Cases for txtNoLoadRate", () => {
    // START_USER_CODE-USER_txtNoLoadRate_TEST
    // END_USER_CODE-USER_txtNoLoadRate_TEST
  });
  test("txtNoLoadWt(Textbox Widget) Test Cases", async () => {
    const txtNoLoadWt = screen.getByTestId("txtNoLoadWt");
    expect(txtNoLoadWt.tagName).toBe("INPUT");
    expect(txtNoLoadWt.type).toBe("text");
    expect(txtNoLoadWt.classList).toContain("textboxWidgetClass");
    expect(txtNoLoadWt.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_txtNoLoadWt")
    );
    await act(async () => {
      userEvent.type(txtNoLoadWt, "1");
    });
  });
  test("Custom Test Cases for txtNoLoadWt", () => {
    // START_USER_CODE-USER_txtNoLoadWt_TEST
    // END_USER_CODE-USER_txtNoLoadWt_TEST
  });
  test("txtRate(Textbox Widget) Test Cases", async () => {
    const txtRate = screen.getByTestId("txtRate");
    expect(txtRate.tagName).toBe("INPUT");
    expect(txtRate.type).toBe("text");
    expect(txtRate.classList).toContain("textboxWidgetClass");
    expect(txtRate.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightLocToLocProfile_txtRate")
    );
    await act(async () => {
      userEvent.type(txtRate, "1");
    });
  });
  test("Custom Test Cases for txtRate", () => {
    // START_USER_CODE-USER_txtRate_TEST
    // END_USER_CODE-USER_txtRate_TEST
  });
});
