/* eslint-disable*/
import { ApiService } from "../../Common/ApiService";
export const SystemMaintenanceApplicationSupportService = {
    RetrieveWareHouseBnInventoryCalcDetails,
    RetrieveOpenAccountAdjustmentDetails,
    RetrieveStateControlDetails,
    CreateLienHolderControl,
    UpdateLienHolderControl,
    Update211DocumentScan,
    CreateOpenAccountAdjustment,
    UpdateManualAccountingDistribution,
    RetrieveManualAccountingDistribution,
    Retrieve211DocumentScanDetails,
    UpdateSettlementNumber,
    UpdateTransferNumber,
    UpdateReceiptNumber,
    Update1007Vendor,
    RetrieveDeductionTrackingSummaryDetails,
    UpdateDeductionTrackingClearBalancedue,
    RemoveLienHolderControl,
    RetrieveInventoryAdjustmentDetails,
    UpdateInventoryAdjustment,
    RetrieveManualInvestmaintDetails,
    RetrieveManualReadyToPayMaintenanceDetails,
    CreateManualReadyToPayMaintenance,
    UpdateManualReadyToPayMaintenance,
    RemoveManualReadyToPayMaintenance,
    RetrieveManualReadyToPaymentHistoryDetails,
    RetrieveManualReadyToPayDetails,
    RetrieveBeginYearInquiryDetails,
    UpdateManualInvestmaint,
    UpdateBeginYear,
    RemoveTransferHeader,
    UpdateResetContractStatus,
    Updatereopencontract,
    Update1007unload,
    UpdateOleicIndicator,
    UpdateVicamPpb,
    UpdateVaCrBrGrades,
    UpdateResetVoidCheck,
    UpdateContractDate,
    Update211Scan,
    UpdateContractImage,
    UpdateFarmControlCopy,
    RetrieveManualReadytoPayMaintHistDetails,
}

const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
const compIdFromLS = () => (sessionStorage.getItem('compId'));
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
const useridFromLS = () => (sessionStorage.getItem('userid') || '');

const systemmaintenance_url_api = '/system-maintenance/v1/';

export function RetrieveWareHouseBnInventoryCalcDetails(func_id, func_sub_id, buy_pt_id, whouse_num, bin_num, sortby, where, showgrades) {
    let optionalParamsUrl = ''
    if (func_sub_id !== undefined && func_sub_id !== null && func_sub_id !== '') {
        optionalParamsUrl += `func_sub_id=${func_sub_id}&`
    }
    if (buy_pt_id !== undefined && buy_pt_id !== null && buy_pt_id !== '') {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    if (whouse_num !== undefined && whouse_num !== null && whouse_num !== '') {
        optionalParamsUrl += `whouse_num=${whouse_num}&`
    }
    if (bin_num !== undefined && bin_num !== null && bin_num !== '') {
        optionalParamsUrl += `bin_num=${bin_num}&`
    }
    if (sortby !== undefined && sortby !== null && sortby !== '') {
        optionalParamsUrl += `sortby=${sortby}&`
    }
    if (where !== undefined && where !== null && where !== '') {
        optionalParamsUrl += `where=${where}&`
    }
    if (sortby !== undefined && showgrades !== null && showgrades !== '') {
        optionalParamsUrl += `showgrades=${showgrades}`
    }
    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrieveWareHouseBnInventoryCalcDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/bininventories?${optionalParamsUrl}`
            : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/functions/${func_id}/users/${useridFromLS()}/bininventories?${optionalParamsUrl}`
    )
}
export function RetrieveOpenAccountAdjustmentDetails(buy_pt_id, acct_vendor) {
    let optionalParamsUrl = ''
    if (buy_pt_id !== undefined && buy_pt_id !== null && buy_pt_id !== '') {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    if (acct_vendor !== undefined && acct_vendor !== null && acct_vendor !== '') {
        optionalParamsUrl += `acct_vendor=${acct_vendor}`
    }
    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrieveOpenAccountAdjustmentDetails/companies/${compIdFromLS()}/accountadjustments?${optionalParamsUrl}`
            : `${systemmaintenance_url_api}companies/${compIdFromLS()}/accountadjustments?${optionalParamsUrl}`
    )
}
export function RetrieveStateControlDetails(state_abbr) {
    let optionalParamsUrl = ``

    if (state_abbr !== null && state_abbr !== '' && state_abbr !== undefined)
        optionalParamsUrl += `state_abbr=${state_abbr}`
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveStateControlDetails/crop-years/${cropYearFromLS()}/states?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}crop-years/${cropYearFromLS()}/states?${optionalParamsUrl}`);
}

export function CreateLienHolderControl(buy_pt_id, dataObj) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateLienHolderControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/lienholders`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/lienholders`, dataObj);
}
export function UpdateLienHolderControl(buy_pt_id, vendor, lienholder_line, dataObj) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateLienHolderControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${vendor}/lienholder-lines/${lienholder_line}/users/${useridFromLS()}/lienholders`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${vendor}/lienholder-lines/${lienholder_line}/users/${useridFromLS()}/lienholders`, dataObj);
}
export function Update211DocumentScan(vendor_num, doc_type, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/Update211DocumentScan/companies/${compIdFromLS()}/vendors/${vendor_num}/document-types/${doc_type}/211Documents` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/vendors/${vendor_num}/document-types/${doc_type}/211Documents`, data)
}
export function CreateOpenAccountAdjustment(buy_pt_id, acct_vendor, data) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateOpenAccountAdjustment/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/account-vendors/${acct_vendor}/accountadjustments`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/users/${useridFromLS()}/account-vendors/${acct_vendor}/accountadjustments`, data
    );
}
export function UpdateManualAccountingDistribution(action_type, buy_pt_id, transaction_num, data) {
    let optionalParamsUrl = ``

    if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined)
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`

    if (transaction_num !== null && transaction_num !== '' && transaction_num !== undefined)
        optionalParamsUrl += `transaction_num=${transaction_num}`

    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateManualAccountingDistribution/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/actions/${action_type}/distributions?${optionalParamsUrl}` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/actions/${action_type}/distributions?${optionalParamsUrl}`, data)
}
export function RetrieveManualAccountingDistribution(action_type, buy_pt_id, dist_account, dist_resp, dist_dept, dist_sub_dept, dist_loc, dist_amount, dist_quantity, audit_check_num, audit_receivable_num, audit_cont_num, audit_insp_num, audit_settle_num, audit_whse_rcpt, audit_xfer_num, audit_trade_insp, audit_trade_settle, audit_delv_agree, transaction_num) {
    let optionalParamsUrl = ``

    if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined)
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}`

    if (dist_account !== null && dist_account !== '' && dist_account !== undefined)
        optionalParamsUrl += `dist_account=${dist_account}`

    if (dist_resp !== null && dist_resp !== '' && dist_resp !== undefined)
        optionalParamsUrl += `dist_resp=${dist_resp}`

    if (dist_dept !== null && dist_dept !== '' && dist_dept !== undefined)
        optionalParamsUrl += `dist_dept=${dist_dept}`

    if (dist_sub_dept !== null && dist_sub_dept !== '' && dist_sub_dept !== undefined)
        optionalParamsUrl += `dist_sub_dept=${dist_sub_dept}`

    if (dist_loc !== null && dist_loc !== '' && dist_loc !== undefined)
        optionalParamsUrl += `dist_loc=${dist_loc}`

    if (dist_amount !== null && dist_amount !== '' && dist_amount !== undefined)
        optionalParamsUrl += `dist_amount=${dist_amount}`

    if (dist_quantity !== null && dist_quantity !== '' && dist_quantity !== undefined)
        optionalParamsUrl += `dist_quantity=${dist_quantity}`

    if (audit_check_num !== null && audit_check_num !== '' && audit_check_num !== undefined)
        optionalParamsUrl += `audit_check_num=${audit_check_num}`

    if (audit_receivable_num !== null && audit_receivable_num !== '' && audit_receivable_num !== undefined)
        optionalParamsUrl += `audit_receivable_num=${audit_receivable_num}`

    if (audit_cont_num !== null && audit_cont_num !== '' && audit_cont_num !== undefined)
        optionalParamsUrl += `audit_cont_num=${audit_cont_num}`

    if (audit_insp_num !== null && audit_insp_num !== '' && audit_insp_num !== undefined)
        optionalParamsUrl += `audit_insp_num=${audit_insp_num}`

    if (audit_settle_num !== null && audit_settle_num !== '' && audit_settle_num !== undefined)
        optionalParamsUrl += `audit_settle_num=${audit_settle_num}`

    if (audit_whse_rcpt !== null && audit_whse_rcpt !== '' && audit_whse_rcpt !== undefined)
        optionalParamsUrl += `audit_insp_num=${audit_insp_num}`

    if (audit_insp_num !== null && audit_insp_num !== '' && audit_insp_num !== undefined)
        optionalParamsUrl += `audit_whse_rcpt=${audit_whse_rcpt}`

    if (audit_xfer_num !== null && audit_xfer_num !== '' && audit_xfer_num !== undefined)
        optionalParamsUrl += `audit_xfer_num=${audit_xfer_num}`

    if (audit_trade_insp !== null && audit_trade_insp !== '' && audit_trade_insp !== undefined)
        optionalParamsUrl += `audit_trade_insp=${audit_trade_insp}`

    if (audit_trade_settle !== null && audit_trade_settle !== '' && audit_trade_settle !== undefined)
        optionalParamsUrl += `audit_trade_settle=${audit_trade_settle}`

    if (audit_delv_agree !== null && audit_delv_agree !== '' && audit_delv_agree !== undefined)
        optionalParamsUrl += `audit_delv_agree=${audit_delv_agree}`

    if (transaction_num !== null && transaction_num !== '' && transaction_num !== undefined)
        optionalParamsUrl += `transaction_num=${transaction_num}`


    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveManualAccountingDistribution/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/actions/${action_type}/manualaccountdistributions?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/actions/${action_type}/manualaccountdistributions?${optionalParamsUrl}`);
}
export function UpdateSettlementNumber(buy_pt_id, insp_num, settlement_num, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateSettlementNumber/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/settlement-numbers/${settlement_num}/settlements` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/settlement-numbers/${settlement_num}/settlements`, data)
}
export function UpdateTransferNumber(buy_pt_id, transfer_type, transfer_num, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateTransferNumber/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/transfer-types/${transfer_type}/transfer-numbers/${transfer_num}/transfers` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/transfer-types/${transfer_type}/transfer-numbers/${transfer_num}/transfers`, data)
}
export function UpdateReceiptNumber(buy_pt_id, receipt_number, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateReceiptNumber/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/receipt-numbers/${receipt_number}/receipts` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/receipt-numbers/${receipt_number}/receipts`, data)
}
export function Update1007Vendor(buy_pt_id, insp_num, vendor_num, data) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/Update1007Vendor/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/vendor-numbers/${vendor_num}/vendors` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/inspection-numbers/${insp_num}/vendor-numbers/${vendor_num}/vendors`, data)
}

export function Retrieve211DocumentScanDetails(vendor_num, doc_type) {
    return ApiService.getData(isLocal() ? `/SystemMaintenance/Retrieve211DocumentScanDetails/companies/${compIdFromLS()}/vendors/${vendor_num}/form_types/${doc_type}/211Documents` :
        `${systemmaintenance_url_api}companies/${compIdFromLS()}/vendors/${vendor_num}/form_types/${doc_type}/211Documents`);
}
export function RetrieveDeductionTrackingSummaryDetails(comp_id, crop_year, buy_pt_id, vendor_num) {
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveDeductionTrackingSummaryDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${vendor_num}/trackings` :
        `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${vendor_num}/trackings`);
}
export function UpdateDeductionTrackingClearBalancedue(buy_pt_id, acct_vendor, deduct_code, action_type) {
    let optionalParamsUrl = '';
    if (action_type !== null && action_type !== undefined && action_type !== "") {
        optionalParamsUrl += `action_type=${action_type}&`
    }
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateDeductionTrackingClearBalancedue/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${acct_vendor}/deductions/${deduct_code}/users/${useridFromLS()}/trackings?${optionalParamsUrl}` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/buying-points/${buy_pt_id}/vendors/${acct_vendor}/deductions/${deduct_code}/users/${useridFromLS()}/trackings?${optionalParamsUrl}`);
}


export function RemoveLienHolderControl(vendor, lienholder_line, buy_pt_id) {
    let optionalParamsUrl = ``

    if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined) {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}`
    }

    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveLienHolderControl/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/vendors/${vendor}/lienholder-lines/${lienholder_line}/users/${useridFromLS()}/lienholders?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/vendors/${vendor}/lienholder-lines/${lienholder_line}/users/${useridFromLS()}/lienholders?${optionalParamsUrl}`)
}

export function RetrieveInventoryAdjustmentDetails(buy_pt_id) {
    let optionalParamsUrl = ''
    if (buy_pt_id !== undefined && buy_pt_id !== null && buy_pt_id !== '') {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    return ApiService.getData(
        isLocal()
            ? `/SystemMaintenance/RetrieveInventoryAdjustmentDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inventoryadjustments?${optionalParamsUrl}`
            : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/inventoryadjustments?${optionalParamsUrl}`
    )
}

export function UpdateInventoryAdjustment(dataObj) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateInventoryAdjustment/inventoryadjustments` : `${systemmaintenance_url_api}inventoryadjustments`, dataObj);
}

export function RetrieveManualInvestmaintDetails(action_code, key_id, buy_pt_id, whs_num, bin_num, beg_date, end_date, insp_num, settle_num, whs_rcpt, xfer_num, trade_insp, trade_settle, pnut_type, pnut_variety, seed_gen, seg_type, oleic_ind, organic_ind, audit_user) {
    let optionalParamsUrl = ''
    if (key_id !== undefined && key_id !== null && key_id !== '') {
        optionalParamsUrl += `key_id=${key_id}&`
    }
    if (buy_pt_id !== undefined && buy_pt_id !== null && buy_pt_id !== '') {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    if (whs_num !== undefined && whs_num !== null && whs_num !== '') {
        optionalParamsUrl += `whs_num=${whs_num}&`
    }
    if (bin_num !== undefined && bin_num !== null && bin_num !== '') {
        optionalParamsUrl += `bin_num=${bin_num}&`
    }
    if (beg_date !== undefined && beg_date !== null && beg_date !== '') {
        optionalParamsUrl += `beg_date=${beg_date}&`
    }
    if (end_date !== undefined && end_date !== null && end_date !== '') {
        optionalParamsUrl += `end_date=${end_date}&`
    }
    if (insp_num !== undefined && insp_num !== null && insp_num !== '') {
        optionalParamsUrl += `insp_num=${insp_num}&`
    }
    if (settle_num !== undefined && settle_num !== null && settle_num !== '') {
        optionalParamsUrl += `settle_num=${settle_num}&`
    }
    if (whs_rcpt !== undefined && whs_rcpt !== null && whs_rcpt !== '') {
        optionalParamsUrl += `whs_rcpt=${whs_rcpt}&`
    }
    if (xfer_num !== undefined && xfer_num !== null && xfer_num !== '') {
        optionalParamsUrl += `xfer_num=${xfer_num}&`
    }
    if (trade_insp !== undefined && trade_insp !== null && trade_insp !== '') {
        optionalParamsUrl += `trade_insp=${trade_insp}&`
    }
    if (trade_settle !== undefined && trade_settle !== null && trade_settle !== '') {
        optionalParamsUrl += `trade_settle=${trade_settle}&`
    }
    if (pnut_type !== undefined && pnut_type !== null && pnut_type !== '') {
        optionalParamsUrl += `pnut_type=${pnut_type}&`
    }
    if (pnut_variety !== undefined && pnut_variety !== null && pnut_variety !== '') {
        optionalParamsUrl += `pnut_variety=${pnut_variety}&`
    }
    if (seed_gen !== undefined && seed_gen !== null && seed_gen !== '') {
        optionalParamsUrl += `seed_gen=${seed_gen}&`
    }
    if (seg_type !== undefined && seg_type !== null && seg_type !== '') {
        optionalParamsUrl += `seg_type=${seg_type}&`
    }
    if (oleic_ind !== undefined && oleic_ind !== null && oleic_ind !== '') {
        optionalParamsUrl += `oleic_ind=${oleic_ind}&`
    }
    if (organic_ind !== undefined && organic_ind !== null && organic_ind !== '') {
        optionalParamsUrl += `organic_ind=${organic_ind}&`
    }
    if (audit_user !== undefined && audit_user !== null && audit_user !== '') {
        optionalParamsUrl += `audit_user=${audit_user}&`
    }

    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveManualInvestmaintDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/action-codes/${action_code}/users/${useridFromLS()}/manualinvests?${optionalParamsUrl}` :
        `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/action-codes/${action_code}/users/${useridFromLS()}/manualinvests?${optionalParamsUrl}`)
}
export function RetrieveManualReadyToPayMaintenanceDetails(transaction_num) {
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveManualReadyToPayMaintenanceDetails/numbers/${transaction_num}/readytopays` :
        `${systemmaintenance_url_api}numbers/${transaction_num}/readytopays`);
}
export function CreateManualReadyToPayMaintenance(dataObj) {
    return ApiService.postData(isLocal() ? `/SystemMaintenance/CreateManualReadyToPayMaintenance/manualreadytopays` :
        `${systemmaintenance_url_api}manualreadytopays`, dataObj);
}
export function UpdateManualReadyToPayMaintenance(transaction_num, dataObj) {
    return ApiService.updateData(isLocal() ? `/SystemMaintenance/UpdateManualReadyToPayMaintenance/transaction-numbers/${transaction_num}/manualreadytopays` :
        `${systemmaintenance_url_api}transaction-numbers/${transaction_num}/manualreadytopays`, dataObj);
}
export function RemoveManualReadyToPayMaintenance(transaction_num, audit_reason, dataObj) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveManualReadyToPayMaintenance/users/${useridFromLS()}/transactions/${transaction_num}/audit-reason/${audit_reason}` :
        `${systemmaintenance_url_api}users/${useridFromLS()}/transactions/${transaction_num}/audit-reason/${audit_reason}`, dataObj);
}

export function RetrieveManualReadyToPaymentHistoryDetails(buy_pt_id, payee_vendor, payee_remit, audit_cont_num, audit_insp_num, audit_settle_num, freight_memo_num, audit_trade_insp, audit_trade_settle, audit_whse_rcpt, audit_farm_id, audit_farm_suffix, audit_delv_agree, rtp_proceeds, special_type_none, rtp_status_hr) {
    let optionalParamsUrl = ``

    if (buy_pt_id !== null && buy_pt_id !== '' && buy_pt_id !== undefined)
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`

    if (payee_vendor !== null && payee_vendor !== '' && payee_vendor !== undefined)
        optionalParamsUrl += `payee_vendor=${payee_vendor}&`

    if (payee_remit !== null && payee_remit !== '' && payee_remit !== undefined)
        optionalParamsUrl += `payee_remit=${payee_remit}&`

    if (audit_cont_num !== null && audit_cont_num !== '' && audit_cont_num !== undefined)
        optionalParamsUrl += `audit_cont_num=${audit_cont_num}&`

    if (audit_insp_num !== null && audit_insp_num !== '' && audit_insp_num !== undefined)
        optionalParamsUrl += `audit_insp_num=${audit_insp_num}&`

    if (audit_settle_num !== null && audit_settle_num !== '' && audit_settle_num !== undefined)
        optionalParamsUrl += `audit_settle_num=${audit_settle_num}&`

    if (freight_memo_num !== null && freight_memo_num !== '' && freight_memo_num !== undefined)
        optionalParamsUrl += `freight_memo_num=${freight_memo_num}&`

    if (audit_trade_insp !== null && audit_trade_insp !== '' && audit_trade_insp !== undefined)
        optionalParamsUrl += `audit_trade_insp=${audit_trade_insp}&`

    if (audit_trade_settle !== null && audit_trade_settle !== '' && audit_trade_settle !== undefined)
        optionalParamsUrl += `audit_trade_settle=${audit_trade_settle}&`

    if (audit_whse_rcpt !== null && audit_whse_rcpt !== '' && audit_whse_rcpt !== undefined)
        optionalParamsUrl += `audit_whse_rcpt=${audit_whse_rcpt}&`

    if (audit_farm_id !== null && audit_farm_id !== '' && audit_farm_id !== undefined)
        optionalParamsUrl += `audit_farm_id=${audit_farm_id}&`

    if (audit_farm_suffix !== null && audit_farm_suffix !== '' && audit_farm_suffix !== undefined)
        optionalParamsUrl += `audit_farm_suffix=${audit_farm_suffix}&`

    if (audit_delv_agree !== null && audit_delv_agree !== '' && audit_delv_agree !== undefined)
        optionalParamsUrl += `audit_delv_agree=${audit_delv_agree}&`

    if (rtp_proceeds !== null && rtp_proceeds !== '' && rtp_proceeds !== undefined)
        optionalParamsUrl += `rtp_proceeds=${rtp_proceeds}&`

    if (special_type_none !== null && special_type_none !== '' && special_type_none !== undefined)
        optionalParamsUrl += `special_type_none=${special_type_none}&`

    if (rtp_status_hr !== null && rtp_status_hr !== '' && rtp_status_hr !== undefined)
        optionalParamsUrl += `rtp_status_hr=${rtp_status_hr}&`

    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveManualReadyToPaymentHistoryDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/paymenthistories?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/paymenthistories?${optionalParamsUrl}`);

}

export function RetrieveManualReadyToPayDetails(buy_pt_id, payee_vendor, payee_remit, audit_cont_num, audit_insp_num, audit_settle_num, freight_memo_num, audit_trade_insp, audit_trade_settle, audit_whse_rcpt, audit_farm_id, audit_farm_suffix, audit_delv_agree, rtp_proceeds, special_type_none, rtp_status_hr) {
    let optionalParamsUrl = '';
    if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "") {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    if (payee_vendor !== null && payee_vendor !== undefined && payee_vendor != "") {
        optionalParamsUrl += `payee_vendor=${payee_vendor}&`
    }
    if (payee_remit !== null && payee_remit !== undefined && payee_remit != "") {
        optionalParamsUrl += `payee_remit=${payee_remit}&`
    }
    if (audit_cont_num !== null && audit_cont_num !== undefined && audit_cont_num != "") {
        optionalParamsUrl += `audit_cont_num=${audit_cont_num}&`
    }
    if (audit_insp_num !== null && audit_insp_num !== undefined && audit_insp_num != "") {
        optionalParamsUrl += `audit_insp_num=${audit_insp_num}&`
    }
    if (audit_settle_num !== null && audit_settle_num !== undefined && audit_settle_num !== "") {
        optionalParamsUrl += `audit_settle_num=${audit_settle_num}&`
    }
    if (freight_memo_num !== null && freight_memo_num !== undefined && freight_memo_num !== "") {
        optionalParamsUrl += `freight_memo_num=${freight_memo_num}&`
    }
    if (audit_trade_insp !== null && audit_trade_insp !== undefined && audit_trade_insp !== "") {
        optionalParamsUrl += `audit_trade_insp=${audit_trade_insp}&`
    }
    if (audit_trade_settle !== null && audit_trade_settle !== undefined && audit_trade_settle !== "") {
        optionalParamsUrl += `audit_trade_settle=${audit_trade_settle}&`
    }
    if (audit_whse_rcpt !== null && audit_whse_rcpt !== undefined && audit_whse_rcpt !== "") {
        optionalParamsUrl += `audit_whse_rcpt=${audit_whse_rcpt}&`
    }
    if (audit_farm_id !== null && audit_farm_id !== undefined && audit_farm_id !== "") {
        optionalParamsUrl += `audit_farm_id=${audit_farm_id}&`
    }
    if (audit_farm_suffix !== null && audit_farm_suffix !== undefined && audit_farm_suffix !== "") {
        optionalParamsUrl += `audit_farm_suffix=${audit_farm_suffix}&`
    }
    if (audit_delv_agree !== null && audit_delv_agree !== undefined && audit_delv_agree !== "") {
        optionalParamsUrl += `audit_delv_agree=${audit_delv_agree}&`
    }
    if (rtp_proceeds !== null && rtp_proceeds !== undefined && rtp_proceeds !== "") {
        optionalParamsUrl += `rtp_proceeds=${rtp_proceeds}&`
    }
    if (special_type_none !== null && special_type_none !== undefined && special_type_none !== "") {
        optionalParamsUrl += `special_type_none=${special_type_none}&`
    }
    if (rtp_status_hr !== null && rtp_status_hr !== undefined && rtp_status_hr !== "") {
        optionalParamsUrl += `rtp_status_hr=${rtp_status_hr}&`
    }

    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveManualReadyToPayDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/payments?${optionalParamsUrl}` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/payments?${optionalParamsUrl}`);
}

export function RetrieveBeginYearInquiryDetails(newcrop_year) {
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveBeginYearInquiryDetails/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/new-crop-years/${newcrop_year}/YearInquiries` : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/users/${useridFromLS()}/new-crop-years/${newcrop_year}/YearInquiries`);
}

export function UpdateManualInvestmaint(action_code, dataObj) {
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateManualInvestmaint/actions/${action_code}/users/${useridFromLS()}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/manualinvestmaints`
        : `${systemmaintenance_url_api}actions/${action_code}/users/${useridFromLS()}/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/manualinvestmaints`, dataObj)
}
export function UpdateBeginYear(dataObj) {
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateBeginYear/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/beginyears`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/beginyears`, dataObj)
}

export function RemoveTransferHeader(shp_buy_pt, transfer_num, transfer_type) {
    return ApiService.removeData(isLocal() ? `/SystemMaintenance/RemoveTransferHeader/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/shp-buying-points/${shp_buy_pt}/transfer-numbers/${transfer_num}/transfer-types/${transfer_type}/users/${useridFromLS()}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/shp-buying-points/${shp_buy_pt}/transfer-numbers/${transfer_num}/transfer-types/${transfer_type}/users/${useridFromLS()}`)
}


export function UpdateResetContractStatus(comp_id, crop_year, contract_num, user_id) {
    let optionalParamsUrl = '';
    if (comp_id !== null && comp_id !== undefined && comp_id != "") {
        optionalParamsUrl += `comp_id=${comp_id}&`
    }
    if (crop_year !== null && crop_year !== undefined && crop_year != "") {
        optionalParamsUrl += `crop_year=${crop_year}&`
    }
    if (contract_num !== null && contract_num !== undefined && contract_num != "") {
        optionalParamsUrl += `contract_num=${contract_num}&`
    }
    if (user_id !== null && user_id !== undefined && user_id != "") {
        optionalParamsUrl += `user_id=${user_id}&`
    }
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateResetContractStatus/contractstatuses?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}contractstatuses?${optionalParamsUrl}`)
}

export function Updatereopencontract(comp_id, crop_year, contract_num, user_id) {
    let optionalParamsUrl = '';
    if (comp_id !== null && comp_id !== undefined && comp_id != "") {
        optionalParamsUrl += `comp_id=${comp_id}&`
    }
    if (crop_year !== null && crop_year !== undefined && crop_year != "") {
        optionalParamsUrl += `crop_year=${crop_year}&`
    }
    if (contract_num !== null && contract_num !== undefined && contract_num != "") {
        optionalParamsUrl += `contract_num=${contract_num}&`
    }
    if (user_id !== null && user_id !== undefined && user_id != "") {
        optionalParamsUrl += `user_id=${user_id}&`
    }
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/Updatereopencontract/reopencontracts?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}reopencontracts?${optionalParamsUrl}`)
}

export function Update1007unload(buy_pt_id, settlement_num, whse, bin, user_id) {
    let optionalParamsUrl = '';
    if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "") {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    if (settlement_num !== null && settlement_num !== undefined && settlement_num != "") {
        optionalParamsUrl += `settlement_num=${settlement_num}&`
    }
    if (whse !== null && whse !== undefined && whse != "") {
        optionalParamsUrl += `whse=${whse}&`
    }
    if (bin !== null && bin !== undefined && bin != "") {
        optionalParamsUrl += `bin=${bin}&`
    }
    if (user_id !== null && user_id !== undefined && user_id != "") {
        optionalParamsUrl += `user_id=${user_id}&`
    }
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/Update1007unload/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/1007unloads?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/1007unloads?${optionalParamsUrl}`)
}
export function UpdateOleicIndicator(buy_pt_id, settlement_num, ind, user_id) {
    let optionalParamsUrl = '';
    if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "") {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    if (settlement_num !== null && settlement_num !== undefined && settlement_num != "") {
        optionalParamsUrl += `settlement_num=${settlement_num}&`
    }
    if (ind !== null && ind !== undefined && ind != "") {
        optionalParamsUrl += `ind=${ind}&`
    }
    if (user_id !== null && user_id !== undefined && user_id != "") {
        optionalParamsUrl += `user_id=${user_id}&`
    }
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateOleicIndicator/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/oleicindicators?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/oleicindicators?${optionalParamsUrl}`)
}
export function UpdateVicamPpb(buy_pt_id, settlement_num, vicam_ind, vicam_ppb, user_id) {
    let optionalParamsUrl = '';
    if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "") {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    if (settlement_num !== null && settlement_num !== undefined && settlement_num != "") {
        optionalParamsUrl += `settlement_num=${settlement_num}&`
    }
    if (vicam_ind !== null && vicam_ind !== undefined && vicam_ind != "") {
        optionalParamsUrl += `vicam_ind=${vicam_ind}&`
    }
    if (vicam_ppb !== null && vicam_ppb !== undefined && vicam_ppb != "") {
        optionalParamsUrl += `vicam_ppb=${vicam_ppb}&`
    }
    if (user_id !== null && user_id !== undefined && user_id != "") {
        optionalParamsUrl += `user_id=${user_id}&`
    }
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateVicamPpb/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/vicamppbs?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/vicamppbs?${optionalParamsUrl}`)
}
export function UpdateVaCrBrGrades(buy_pt_id, settlement_num, va_cr_br_sample, va_cr_br_gram,va_cr_br_pct) {
    let optionalParamsUrl = '';
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`
    optionalParamsUrl += `user_id=${useridFromLS()}&`
    
    if (buy_pt_id !== null && buy_pt_id !== undefined && buy_pt_id != "") {
        optionalParamsUrl += `buy_pt_id=${buy_pt_id}&`
    }
    if (settlement_num !== null && settlement_num !== undefined && settlement_num != "") {
        optionalParamsUrl += `settlement_num=${settlement_num}&`
    }
    if (va_cr_br_sample !== null && va_cr_br_sample !== undefined && va_cr_br_sample != "") {
        optionalParamsUrl += `va_cr_br_sample=${va_cr_br_sample}&`
    }
    if (va_cr_br_gram !== null && va_cr_br_gram !== undefined && va_cr_br_gram != "") {
        optionalParamsUrl += `va_cr_br_gram=${va_cr_br_gram}&`
    }
    if (va_cr_br_pct !== null && va_cr_br_pct !== undefined && va_cr_br_pct != "") {
        optionalParamsUrl += `va_cr_br_pct=${va_cr_br_pct}&`
    }
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateVaCrBrGrades/vacrbrgrades?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}vacrbrgrades?${optionalParamsUrl}`)
}
export function UpdateResetVoidCheck(check_num) {
    let optionalParamsUrl = '';
        
        optionalParamsUrl += `user_id=${useridFromLS()}&`
    if (check_num !== null && check_num !== undefined && check_num != "") {
        optionalParamsUrl += `check_num=${check_num}&`
    }
    
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateResetVoidCheck/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/voidchecks?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/voidchecks?${optionalParamsUrl}`)
}
export function UpdateContractDate(contract_num, contract_date) {
    let optionalParamsUrl = '';
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`
    optionalParamsUrl += `user_id=${useridFromLS()}&`
    
    if (contract_num !== null && contract_num !== undefined && contract_num != "") {
        optionalParamsUrl += `contract_num=${contract_num}&`
    }
    if (contract_date !== null && contract_date !== undefined && contract_date != "") {
        optionalParamsUrl += `contract_date=${contract_date}&`
    }
   
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateContractDate/contractdates?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}contractdates?${optionalParamsUrl}`)
}
export function Update211Scan(vendor_num, form_type) {
    let optionalParamsUrl = '';
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`
    optionalParamsUrl += `user_id=${useridFromLS()}&`
    
    if (vendor_num !== null && vendor_num !== undefined && vendor_num != "") {
        optionalParamsUrl += `vendor_num=${vendor_num}&`
    }
    if (form_type !== null && form_type !== undefined && form_type != "") {
        optionalParamsUrl += `form_type=${form_type}&`
    }
   
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/Update211Scan/211scans?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}211scans?${optionalParamsUrl}`)
}
export function UpdateContractImage(contract_num) {
    let optionalParamsUrl = '';
    optionalParamsUrl += `comp_id=${compIdFromLS()}&`
    optionalParamsUrl += `crop_year=${cropYearFromLS()}&`
    optionalParamsUrl += `user_id=${useridFromLS()}&`
    
    if (contract_num !== null && contract_num !== undefined && contract_num != "") {
        optionalParamsUrl += `contract_num=${contract_num}&`
    }
      
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateContractImage/contractimages?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}contractimages?${optionalParamsUrl}`)
}
export function UpdateFarmControlCopy(copy_farm_from_buy_pt,copy_farm_to_buy_pt) {
    let optionalParamsUrl = '';
        optionalParamsUrl += `user_id=${useridFromLS()}&`
    if (copy_farm_from_buy_pt !== null && copy_farm_from_buy_pt !== undefined && copy_farm_from_buy_pt != "") {
        optionalParamsUrl += `copy_farm_from_buy_pt=${copy_farm_from_buy_pt}&`
    }
    if (copy_farm_to_buy_pt !== null && copy_farm_to_buy_pt !== undefined && copy_farm_to_buy_pt != "") {
        optionalParamsUrl += `copy_farm_to_buy_pt=${copy_farm_to_buy_pt}&`
    }
    
    return ApiService.updateData(isLocal()
        ? `/SystemMaintenance/UpdateFarmControlCopy/companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/frmcontrols?${optionalParamsUrl}`
        : `${systemmaintenance_url_api}companies/${compIdFromLS()}/crop-years/${cropYearFromLS()}/frmcontrols?${optionalParamsUrl}`)
}

export function RetrieveManualReadytoPayMaintHistDetails(transaction_num, audit_date_time) {
    return ApiService.getData(isLocal() ? `/SystemMaintenance/RetrieveManualReadytoPaymentsMaintHistDetails/transaction-numbers/${transaction_num}/audit-datetime/${audit_date_time}/manualreadytopayments` 
    : `${systemmaintenance_url_api}transaction-numbers/${transaction_num}/audit-datetime/${audit_date_time}/manualreadytopayments`);
}