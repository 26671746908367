/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  DropDownWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  setValue,
  setLabel,
  getValue,
  setData,
  getData
} from "../../shared/WindowImports";

import "./ExtReceiptForfeituresCropYear.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_ExtReceiptForfeituresCropYear(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  //  let userid = sessionStorage.getItem("userid");
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  //  let compId = sessionStorage.getItem('compId');

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ExtReceiptForfeituresCropYear",
    windowName: "ExtReceiptForfeituresCropYear",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.ExtReceiptForfeituresCropYear",
    // START_USER_CODE-USER_ExtReceiptForfeituresCropYear_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Copy External ReceiptForfeitures",
      scrCode: "",
    },
    // END_USER_CODE-USER_ExtReceiptForfeituresCropYear_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnCopy: {
      name: "btnCopy",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Copy",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCopy_PROPERTIES

      // END_USER_CODE-USER_btnCopy_PROPERTIES
    },
    ddSelectNewCropYear: {
      name: "ddSelectNewCropYear",
      type: "DropDownFieldWidget",
      parent: "grpbxExtReceiptForfeituresCropYear",
      Label: "Select new crop year:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_ddSelectNewCropYear_PROPERTIES

      // END_USER_CODE-USER_ddSelectNewCropYear_PROPERTIES
    },
    lblCropYear: {
      name: "lblCropYear",
      type: "LabelWidget",
      parent: "grpbxExtReceiptForfeituresCropYear",
      Label: "Crop Year",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_lblCropYear_PROPERTIES

      // END_USER_CODE-USER_lblCropYear_PROPERTIES
    },
    txtYear: {
      name: "txtYear",
      type: "TextBoxWidget",
      parent: "grpbxExtReceiptForfeituresCropYear",
      Label: "",
      ColSpan: "2",
      HasLabel:false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
     Enabled:false,
     ReadOnly:true,
    },
    
    grpbxExtReceiptForfeituresCropYear: {
      name: "grpbxExtReceiptForfeituresCropYear",
      type: "GroupBoxWidget",
      parent: "ExtReceiptForfeituresCropYear",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxExtReceiptForfeituresCropYear_PROPERTIES

      // END_USER_CODE-USER_grpbxExtReceiptForfeituresCropYear_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "ExtReceiptForfeituresCropYear",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, 'txtYear', sessionStorage.getItem('year'));
    FormLoad()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

    async function FormLoad() {
    GetCropYearList(cropYear)
    setLabel(thisObj, "lblCropYear", cropYear)
  }
  const GetCropYearList = async (cropYear) => {
    let response = await ContractManagementService.RetrieveCropYears()    
    let data = response;
    let js = [];
    for (var i = 0; i < data.length; i++) {
       if (data[i].cropYear > cropYear) {
        let obj = { key: data[i].cropYear, description: data[i].cropYear }
        js.push(obj)
      }
    }
     js = [].concat(js).reverse()
    thisObj.setState(current => {
      return {
        ...current,
        ddSelectNewCropYear: {
          ...state["ddSelectNewCropYear"],
          valueList: js
        }
      }      
    });
    setValue(thisObj, 'ddSelectNewCropYear', js.at(0).key.toString());
    }
  const onbtnCopyClick = async () => {
    let obj = {}
   try {
      let parentdata= getData(thisObj,"ExtRctForfCropYearData")
        let cropyear=getValue(thisObj, 'txtYear');
        let SelectedCropyearlistValue = thisObj.values['ddSelectNewCropYear'];
        let crop_year=getValue(thisObj,'ddSelectNewCropYear')
        if ((SelectedCropyearlistValue != "") && (SelectedCropyearlistValue != undefined)) {
          obj.comp_id=sessionStorage.getItem('compId');
          obj.crop_year =cropyear;
          obj.new_crop_year = parseInt(crop_year);
          obj.receipt_num = parentdata.lblERcptNum;
          obj.ccc_whse_code = parentdata.lblCCCCode;
          obj.user_id=sessionStorage.getItem('userid');
        let response = await WarehouseReceiptService.UpdateExternalReceiptCopy(obj)
        if (response != null || response != "")
        {
          alert(`External Receipt has been successfully copied to ${SelectedCropyearlistValue} crop year.`,true)
        }
        setData(thisObj, 'frmExtRctForfCropYearData',obj)
        setData(thisObj, 'frmExtRctForfCropYearClose', true)
        document.getElementById("WarehouseReceipts_ExtReceiptForfeituresCropYearPopUp").childNodes[0].click()
      }
     }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during copy change event"
        );
      }
      return false;
    }
    return true;
   };
  
thisObj.onbtnCopyClick = onbtnCopyClick;

//  Cancel Button
const onbtnCancelClick = () => {
  try {
    document.getElementById("WarehouseReceipts_ExtReceiptForfeituresCropYearPopUp").childNodes[0].click();
  }
  catch (err) {
    if (err instanceof EvalError) {
      alert(err.message);
    } else {
      alert("Exception in PreSubmit Event code for widget:btnClose event:Click");
    }
  }
};
thisObj.onbtnCancelClick = onbtnCancelClick;


// START_USER_CODE-USER_METHODS

// END_USER_CODE-USER_METHODS
initForm(thisObj);
let validationSchema = object(_kaledo.validationSchema);
let initialValues = _kaledo.initialValues;
return (
  <Formik validationSchema={validationSchema} initialValues={initialValues}>
    {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      isValid,
      errors,
      setFieldValue,
    }) => {
      thisObj.setFieldValue = setFieldValue = setFieldValue;
      thisObj.values = values = values;
      thisObj.handleChange = handleChange = handleChange;
      thisObj.handleBlur = handleBlur = handleBlur;
      thisObj.isValid = isValid = isValid;
      thisObj.errors = errors = errors;
      return (
        <div
          className={
            _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
          data-testid={state.windowName}
          ref={screenRef}
        >
          <Modal
            show={state.showPopup}
            onHide={vlaues => {
              handleModalHide(thisObj, values);
            }}
            data={dataCopyObj.dataToCopy}
            component={state.popupComponent}
          />
          {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
          <ContractManagement_header headerData={state.headerData} />
          {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
          <h1 className="pageHeader">{state.Label}</h1>
          {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

          {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

          <Form noValidate className="row">
            {/* START_USER_CODE-USER_BEFORE_ExtReceiptForfeituresCropYear*/}

            {/* END_USER_CODE-USER_BEFORE_ExtReceiptForfeituresCropYear*/}

            {/* START_USER_CODE-USER_BEFORE_grpbxExtReceiptForfeituresCropYear*/}

            {/* END_USER_CODE-USER_BEFORE_grpbxExtReceiptForfeituresCropYear*/}

            <GroupBoxWidget
              conf={state.grpbxExtReceiptForfeituresCropYear}
              screenConf={state}
            >
              {/* START_USER_CODE-USER_BEFORE_lblCropYear*/}

              {/* END_USER_CODE-USER_BEFORE_lblCropYear*/}

              <LabelWidget
                values={values}
                conf={state.lblCropYear}
                screenConf={state}
              ></LabelWidget>
               <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtYear}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
              {/* START_USER_CODE-USER_AFTER_lblCropYear*/}

              {/* END_USER_CODE-USER_AFTER_lblCropYear*/}
              {/* START_USER_CODE-USER_BEFORE_ddSelectNewCropYear*/}

              {/* END_USER_CODE-USER_BEFORE_ddSelectNewCropYear*/}

              <DropDownWidget
                onChange={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                onBlur={handleBlur}
                values={values}
                touched={touched}
                errors={errors}
                conf={state.ddSelectNewCropYear}
                screenConf={state}
              ></DropDownWidget>
              {/* START_USER_CODE-USER_AFTER_ddSelectNewCropYear*/}

              {/* END_USER_CODE-USER_AFTER_ddSelectNewCropYear*/}
            </GroupBoxWidget>
            {/* START_USER_CODE-USER_AFTER_grpbxExtReceiptForfeituresCropYear*/}

            {/* END_USER_CODE-USER_AFTER_grpbxExtReceiptForfeituresCropYear*/}
            {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

            {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

            <CommandContainerWidget
              conf={state.cmmndCntnrActions}
              screenConf={state}
            >
              {/* START_USER_CODE-USER_BEFORE_btnCopy*/}

              {/* END_USER_CODE-USER_BEFORE_btnCopy*/}

              <ButtonWidget
                conf={state.btnCopy}
                screenConf={state}
                onClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
              ></ButtonWidget>
              {/* START_USER_CODE-USER_AFTER_btnCopy*/}

              {/* END_USER_CODE-USER_AFTER_btnCopy*/}
              {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

              {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

              <ButtonWidget
                conf={state.btnCancel}
                screenConf={state}
                onClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
              ></ButtonWidget>
              {/* START_USER_CODE-USER_AFTER_btnCancel*/}

              {/* END_USER_CODE-USER_AFTER_btnCancel*/}
            </CommandContainerWidget>
            {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

            {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

            {/* START_USER_CODE-USER_AFTER_ExtReceiptForfeituresCropYear*/}

            {/* END_USER_CODE-USER_AFTER_ExtReceiptForfeituresCropYear*/}
          </Form>
        </div>
      );
    }}
  </Formik>
);
}
export default withRouter(WarehouseReceipts_ExtReceiptForfeituresCropYear);
