/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  setData,
  getData,
  setValue,
  getValue,
  hideColumn,
  getGridValue,
  getSelectedRowNumber,
  disable,
  enable,
  goTo,
} from "../../shared/WindowImports";

// START_USER_CODE-USER_IMPORTS
import "./MultiStopSearch.scss";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import Loading from "../../../Loader/Loading";
import ContractManagement_header from "../../Header/Header";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { StockTransferService } from "../Service/StockTransferService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function StockTransfer_MultiStopSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "MultiStopSearch",
    windowName: "MultiStopSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "StockTransfer.MultiStopSearch",
    // START_USER_CODE-USER_MultiStopSearch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Multi Transfer Search",
      scrCode: "PN1080J",
    },
    // END_USER_CODE-USER_MultiStopSearch_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCreateMultiTransfer: {
      name: "btnCreateMultiTransfer",
      type: "ButtonWidget",
      parent: "grpbxTransferList",
      Label: "Create Multi Transfer",
      CharWidth: "43",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreateMultiTransfer_PROPERTIES

      // END_USER_CODE-USER_btnCreateMultiTransfer_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "grpbxTransferList",
      Label: "Open",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxDetails",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    colDestBuyingPoint: {
      name: "colDestBuyingPoint",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Dest Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDestBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_colDestBuyingPoint_PROPERTIES
    },
    colLegNum: {
      name: "colLegNum",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Leg #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLegNum_PROPERTIES

      // END_USER_CODE-USER_colLegNum_PROPERTIES
    },
    colOrigBuyingPoint: {
      name: "colOrigBuyingPoint",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Orig Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOrigBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_colOrigBuyingPoint_PROPERTIES
    },
    colTransferNum: {
      name: "colTransferNum",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Transfer #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTransferNum_PROPERTIES

      // END_USER_CODE-USER_colTransferNum_PROPERTIES
    },
    colTransferType: {
      name: "colTransferType",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Transfer Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTransferType_PROPERTIES

      // END_USER_CODE-USER_colTransferType_PROPERTIES
    },
    colTripNum: {
      name: "colTripNum",
      type: "GridColumnWidget",
      parent: "gridTransferList",
      Label: "Trip #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTripNum_PROPERTIES

      // END_USER_CODE-USER_colTripNum_PROPERTIES
    },
    ddDestinationLocation: {
      name: "ddDestinationLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Destination Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestinationLocation_PROPERTIES

      // END_USER_CODE-USER_ddDestinationLocation_PROPERTIES
    },
    ddOriginalLocation: {
      name: "ddOriginalLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Original Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOriginalLocation_PROPERTIES

      // END_USER_CODE-USER_ddOriginalLocation_PROPERTIES
    },
    ddTransferType: {
      name: "ddTransferType",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Transfer Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddTransferType_PROPERTIES

      // END_USER_CODE-USER_ddTransferType_PROPERTIES
    },
    gridTransferList: {
      name: "gridTransferList",
      type: "GridWidget",
      parent: "grpbxTransferList",
      gridCellsOrder:
        "txtcolTripNum,txtcolLegNum,txtcolTransferNum,txtcolTransferType,txtcolOrigBuyingPoint,txtcolDestBuyingPoint",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridTransferList_PROPERTIES

      clientPagination: true,
      DisplaySize: 10,
      expandedRows: [],
      pushExpandedRows: (isExpanded, row) => {
        if (isExpanded) {
          states.gridTransferList.expandedRows.push(row)
        }
        else {
          states.gridTransferList.expandedRows = states.gridTransferList.expandedRows.filter(rows => rows.rowID$ !== row.rowID$)
        }
      },
      isExpandable: true,
      defaultExpand: false,
      needGridChange: false,
      isCobolTable: true,
      expandedCompTemplate: ({ data }) => (
        <div>
          {data.childrow ? (
            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
              {data.childrow.map((elem, i) =>
                <div className="py-3 expandable-area col-sm-12 rdt_TableRow selectable" key={"Row" + i}>
                  <div className={"row" + " rdt_ExpanderRow"} key={"ExpanderRow" + i} >
                    <div className="expandIconSpc"></div>
                    {thisObj.state.gridTransferList.columns[0].Visible ?
                      <div className="extraRowCell_1" key="expandCell1">
                        <label>
                          {elem.trip}
                        </label>
                      </div> : <></>}
                    {thisObj.state.gridTransferList.columns[1].Visible ?
                      <div className="extraRowCell_2" key="expandCell2">
                        <label>
                          {elem.leg}
                        </label>
                      </div> : <></>}
                    {thisObj.state.gridTransferList.columns[2].Visible ?
                      <div className="extraRowCell_3" key="expandCell3">
                        <label>
                          {elem.transfer}
                        </label>
                      </div> : <></>}
                    {thisObj.state.gridTransferList.columns[3].Visible ?
                      <div className="extraRowCell_5" key="expandCell5">
                        <label>
                          {elem.transferType}
                        </label>
                      </div> :
                      <></>}
                    {thisObj.state.gridTransferList.columns[4].Visible ?
                      <div className="extraRowCell_4" key="expandCell4">
                        <label>
                          {elem.orgBuyingPoint}
                        </label>
                      </div> : <></>}
                    {thisObj.state.gridTransferList.columns[5].Visible ?
                      <div className="extraRowCell_6" key="expandCell6">
                        <label>
                          {elem.destBuyingPoint}
                        </label>
                      </div> : <></>}
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
      // END_USER_CODE-USER_gridTransferList_PROPERTIES
    },
    grpbxDetails: {
      name: "grpbxDetails",
      type: "GroupBoxWidget",
      parent: "grpbxMultiStopSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails_PROPERTIES
    },
    grpbxTransferList: {
      name: "grpbxTransferList",
      type: "GroupBoxWidget",
      parent: "grpbxMultiStopSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxTransferList_PROPERTIES

      // END_USER_CODE-USER_grpbxTransferList_PROPERTIES
    },
    txtcolDestBuyingPoint: {
      name: "txtcolDestBuyingPoint",
      type: "TextBoxWidget",
      colName: "colDestBuyingPoint",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDestBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtcolDestBuyingPoint_PROPERTIES
    },
    txtcolLegNum: {
      name: "txtcolLegNum",
      type: "TextBoxWidget",
      colName: "colLegNum",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLegNum_PROPERTIES

      // END_USER_CODE-USER_txtcolLegNum_PROPERTIES
    },
    txtcolOrigBuyingPoint: {
      name: "txtcolOrigBuyingPoint",
      type: "TextBoxWidget",
      colName: "colOrigBuyingPoint",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOrigBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtcolOrigBuyingPoint_PROPERTIES
    },
    txtcolTransferNum: {
      name: "txtcolTransferNum",
      type: "TextBoxWidget",
      colName: "colTransferNum",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTransferNum_PROPERTIES

      // END_USER_CODE-USER_txtcolTransferNum_PROPERTIES
    },
    txtcolTransferType: {
      name: "txtcolTransferType",
      type: "TextBoxWidget",
      colName: "colTransferType",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTransferType_PROPERTIES

      // END_USER_CODE-USER_txtcolTransferType_PROPERTIES
    },
    txtcolTripNum: {
      name: "txtcolTripNum",
      type: "TextBoxWidget",
      colName: "colTripNum",
      parent: "gridTransferList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTripNum_PROPERTIES

      // END_USER_CODE-USER_txtcolTripNum_PROPERTIES
    },
    txtTransferNum: {
      name: "txtTransferNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Transfer #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTransferNum_PROPERTIES

      // END_USER_CODE-USER_txtTransferNum_PROPERTIES
    },
    txtTripNum: {
      name: "txtTripNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Trip #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTripNum_PROPERTIES

      // END_USER_CODE-USER_txtTripNum_PROPERTIES
    },
    grpbxMultiStopSearch: {
      name: "grpbxMultiStopSearch",
      type: "GroupBoxWidget",
      parent: "MultiStopSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxMultiStopSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxMultiStopSearch_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "MultiStopSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "StockTransfer#MultiStopProfile": {},
      },
      REVERSE: {
        "StockTransfer#MultiStopProfile": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnCreateMultiTransfer: {
    //   DEFAULT: ["StockTransfer#MultiStopProfile#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    Formload();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    if (getData(thisObj, 'RefreshMultiStopSearch') == true) {
      doSearch();
    }
    setData(thisObj, 'RefreshMultiStopSearch', false)
  }, [getData(thisObj, 'RefreshMultiStopSearch')])

  // START_USER_CODE-USER_METHODS

  let Formload = async () => {
    setLoading(true)
    await bFillBuyingPointList();
    bFillTransferType();
    disable(thisObj, 'btnOpen')
    setLoading(false)
  }
  // Dropdowns binding-------

  const bFillBuyingPointList = async () => {
    try {
      let js = []
      let lstLocCmaVendor = [];
      js.push({ key: '', description: '>>> All Buying Points <<<' });
      let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0160', null, null, null, null)
      let data = response
      for (let i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + ' - ' + ConvertToPascalCase(data[i].buy_pt_name.trim()) }
        lstLocCmaVendor.push({ buy_pt_id: data[i].buy_pt_id, loccmavendor: data[i].default_cma_vendor_num })
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddOriginalLocation: {
            ...state["ddOriginalLocation"],
            valueList: js
          }
        }
      })
      thisObj.setState(current => {
        return {
          ...current,
          ddDestinationLocation: {
            ...state["ddDestinationLocation"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddOriginalLocation', js.at(0).key);
      setValue(thisObj, 'ddDestinationLocation', js.at(0).key);
    } catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occured in Dropdowns"
        );
      }
      return false;
    }
  }
  // Dropdown binding Transfer tpye----

  const bFillTransferType = () => {
    try {
      let js = []
      js.push({ key: '', description: '' })
      js.push({ key: 'ST', description: 'Stock Transfer'})
      js.push({ key: 'FF', description: 'Farm Freight'})
      js.push({ key: 'NL', description: 'No Load'})

      thisObj.setState(current => {
        return {
          ...current,
          ddTransferType: {
            ...state["ddTransferType"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddTransferType', js.at(0).key);

    } catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occured in Transfer Type Dropdown"
        );
      }
      return false;
    }
  }

  //Function to convert to Pascal Case
  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  // Close Button --

  const onbtnCloseClick = async () => {
    try {
      document.getElementById("StockTransfer_MultiStopSearchPopUp").childNodes[0].click()
      return true;
    }
    catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occured in Close Button"
        );
      }
      return false;
    }
  }
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const onbtnCreateMultiTransferClick = () => {
    try {
      let obj = {
        button : 'Create'
      }
      setData(thisObj, 'frmMultiStopProfile', obj)
      goTo(thisObj, "StockTransfer#MultiStopProfile#DEFAULT#true#Click")
    } catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occured in Create Button"
        );
      }
      return false;
    }
  }
  thisObj.onbtnCreateMultiTransferClick = onbtnCreateMultiTransferClick

  // Search button --

  const onbtnSearchClick = async () => {
    try {
      setLoading(true)
      let originalBuyingpoint = getValue(thisObj, 'ddOriginalLocation')
      let destinationBuyingPoint = getValue(thisObj, 'ddDestinationLocation')
      let trip_num = getValue(thisObj, "txtTripNum") == undefined ? '' : getValue(thisObj, "txtTripNum")
      let transfer_num = getValue(thisObj, "txtTransferNum") == undefined ? '' : getValue(thisObj, "txtTransferNum")
      let transfer_type = getValue(thisObj, "ddTransferType")

      if (originalBuyingpoint == "" && destinationBuyingPoint == "" && trip_num == '' && transfer_num == '') {
        alert("Must enter Shipping or Receiving location, when not specifying Transfer or Trip number.")
        setLoading(false)
        return;
      }
      // blur event need to add for tranfer nummmm in another function.

      let response = await StockTransferService.RetrieveMultistopTripDetails(transfer_num, trip_num, transfer_type, originalBuyingpoint, destinationBuyingPoint)
      let data = response
      let sGroupingNum = ""

      if (data != undefined && data != null && data != '') {
        let TransformedRowsArray = [];
        let obj = {};

        for (let i = 0; i < data.length; i++) {
          if (sGroupingNum != data[i].trip_id) {
            let expandObj = {}
            let expandArray = []
            sGroupingNum = data[i].trip_id

            obj.txtcolTripNum = data[i].trip_id
            obj.txtcolLegNum = data[i].leg_count
            obj.txtcolTransferNum = data[i].transfer_num
            obj.txtcolTransferType = data[i].transfer_type
            obj.txtcolOrigBuyingPoint = data[i].shp_buy_pt
            obj.txtcolDestBuyingPoint = data[i].rec_buy_pt

            for (let j = i + 1; j < data.length; j++) {
              if (data[j].trip_id == sGroupingNum) {
                expandObj = {}
                expandObj.trip = data[j].trip_id
                expandObj.leg = data[j].leg_count
                expandObj.transfer = data[j].transfer_num
                expandObj.transferType = data[j].transfer_type
                expandObj.orgBuyingPoint = data[j].shp_buy_pt
                expandObj.destBuyingPoint = data[j].rec_buy_pt

                expandArray.push(expandObj)
                obj.childrow = (expandArray) 
              }
            }
            expandArray = []
            TransformedRowsArray.push(obj)
            obj = {}
          }
        }
        setValue(thisObj, 'gridTransferList', TransformedRowsArray)
        enable(thisObj, 'btnOpen')
      }
      else {
        alert('No Transfers Found!!!')
        setValue(thisObj, 'gridTransferList', [])
        disable(thisObj, 'btnOpen')
        setLoading(false)
        return;
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occured on search button click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnSearchClick = onbtnSearchClick

  const doSearch = async () => {
    try{
      // setLoading(true)
      let originalBuyingpoint = getValue(thisObj, 'ddOriginalLocation') || ''
      let destinationBuyingPoint = getValue(thisObj, 'ddDestinationLocation') || ''
      let trip_num = getValue(thisObj, "txtTripNum") == undefined ? '' : getValue(thisObj, "txtTripNum")
      let transfer_num = getValue(thisObj, "txtTransferNum") == undefined ? '' : getValue(thisObj, "txtTransferNum")
      let transfer_type = getValue(thisObj, "ddTransferType") || ''
      if (originalBuyingpoint != "" || destinationBuyingPoint != "" || trip_num != '' || transfer_num != '') {
      
      let response = await StockTransferService.RetrieveMultistopTripDetails(transfer_num, trip_num, transfer_type, originalBuyingpoint, destinationBuyingPoint)
      let data = response
      let sGroupingNum = ""

      if (data != undefined && data != null && data != '') {
        let TransformedRowsArray = [];
        let obj = {};

        for (let i = 0; i < data.length; i++) {
          if (sGroupingNum != data[i].trip_id) {
            let expandObj = {}
            let expandArray = []
            sGroupingNum = data[i].trip_id

            obj.txtcolTripNum = data[i].trip_id
            obj.txtcolLegNum = data[i].leg_count
            obj.txtcolTransferNum = data[i].transfer_num
            obj.txtcolTransferType = data[i].transfer_type
            obj.txtcolOrigBuyingPoint = data[i].shp_buy_pt
            obj.txtcolDestBuyingPoint = data[i].rec_buy_pt

            for (let j = i + 1; j < data.length; j++) {
              if (data[j].trip_id == sGroupingNum) {
                expandObj = {}
                expandObj.trip = data[j].trip_id
                expandObj.leg = data[j].leg_count
                expandObj.transfer = data[j].transfer_num
                expandObj.transferType = data[j].transfer_type
                expandObj.orgBuyingPoint = data[j].shp_buy_pt
                expandObj.destBuyingPoint = data[j].rec_buy_pt

                expandArray.push(expandObj)
                obj.childrow = (expandArray) 
              }
            }
            expandArray = []
            TransformedRowsArray.push(obj)
            obj = {}
          }
        }
        setValue(thisObj, 'gridTransferList', TransformedRowsArray)
        enable(thisObj, 'btnOpen')
      }
    }
    }
    catch (err) {
      // setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occured on dosearch "
        );
      }
      return false;
    }
  }

  // Open button--
  const onbtnOpenClick = () => {
    try {
      let gridData = getValue(thisObj, 'gridTransferList')
      let rowNum = getSelectedRowNumber(thisObj, 'gridTransferList')
      if (rowNum == undefined) {
        alert("A grouping must be selected to open.")
        return;
      }
      let obj = {}
      let trip_id = getGridValue(thisObj, 'gridTransferList', 'txtcolTripNum', rowNum)
      obj.trip_id = trip_id
      obj.button = 'Open'
      if (trip_id == '') {
        return;
      }
      setData(thisObj, 'frmMultiStopProfile', obj)
      goTo(thisObj, "StockTransfer#MultiStopProfile#DEFAULT#true#Click")

      // cmd search need to add in useeffect
    } catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occured on open button click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnOpenClick = onbtnOpenClick

  // Double click event for grid row --

  const ongridTransferListDblclick = () => {
    try {
      // setLoading(true)
      let gridData = getValue(thisObj, 'gridTransferList')
      if (gridData.length == 0) {
        setLoading(false)
        return;
      }
      let rowNum = getSelectedRowNumber(thisObj, 'gridTransferList')
      let trip_id = getGridValue(thisObj, 'gridTransferList', 'txtcolTripNum', rowNum)
      if (trip_id == '') {
        return;
      }
      if (rowNum >= 0) {
        onbtnOpenClick();
      }
    } catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occured on Double click"
        );
      }
      return false;
    }
  }
  thisObj.ongridTransferListDblclick = ongridTransferListDblclick

  // Blur event for transfer Number --

  const ontxtTransferNumBlur = async () => {
    let transfer_num = getValue(thisObj, 'txtTransferNum')
    if (transfer_num == '' || transfer_num == undefined || transfer_num == null) return;

    let finalAnswer = '';
    let textLength;
    if (transfer_num.length < 6) {
      textLength = 6 - (transfer_num.length)
    }
    else {
      textLength = 6;
    }
    for (let i = 0; i < textLength; i++) {
      finalAnswer += '0'
    }
    if (textLength != 6) {
      setValue(thisObj, 'txtTransferNum', (finalAnswer + transfer_num).toUpperCase())
    }
    else {
      setValue(thisObj, 'txtTransferNum', transfer_num.toUpperCase())
    }
  }
  thisObj.ontxtTransferNumBlur = ontxtTransferNumBlur

  // Validation for Transfer number text box ----------

  const ontxtTransferNumChange = () => {
    let transferNumValue = getValue(thisObj, 'txtTransferNum')
    setValue(thisObj, "txtTransferNum", validateTextBox1(transferNumValue))
  };
  thisObj.ontxtTransferNumChange = ontxtTransferNumChange

  const validateTextBox1 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46) ||
        (data[i].charCodeAt(0) >= 65 && data[i].charCodeAt(0) <= 90)
        || (data[i].charCodeAt(0) >= 97 && data[i].charCodeAt(0) <= 122)) {
        res += data[i]
      }
    }
    return res;
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_MultiStopSearch*/}

              {/* END_USER_CODE-USER_BEFORE_MultiStopSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxMultiStopSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxMultiStopSearch*/}

              <GroupBoxWidget
                conf={state.grpbxMultiStopSearch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails*/}

                <GroupBoxWidget conf={state.grpbxDetails} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddOriginalLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOriginalLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOriginalLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOriginalLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddOriginalLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_ddDestinationLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddDestinationLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestinationLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddDestinationLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddDestinationLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTripNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTripNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTripNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTripNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtTripNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTransferNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTransferNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTransferNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTransferNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtTransferNum*/}
                  {/* START_USER_CODE-USER_BEFORE_ddTransferType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddTransferType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddTransferType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddTransferType*/}

                  {/* END_USER_CODE-USER_AFTER_ddTransferType*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxTransferList*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxTransferList*/}

                <GroupBoxWidget
                  conf={state.grpbxTransferList}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnCreateMultiTransfer*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCreateMultiTransfer*/}

                  <ButtonWidget
                    conf={state.btnCreateMultiTransfer}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCreateMultiTransfer*/}

                  {/* END_USER_CODE-USER_AFTER_btnCreateMultiTransfer*/}
                  {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                  {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                  <ButtonWidget
                    conf={state.btnOpen}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnOpen*/}

                  {/* END_USER_CODE-USER_AFTER_btnOpen*/}
                  {/* START_USER_CODE-USER_BEFORE_gridTransferList*/}

                  {/* END_USER_CODE-USER_BEFORE_gridTransferList*/}

                  <GridWidget
                    conf={state.gridTransferList}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridTransferList}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridTransferList*/}

                  {/* END_USER_CODE-USER_AFTER_gridTransferList*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxTransferList*/}

                {/* END_USER_CODE-USER_AFTER_grpbxTransferList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxMultiStopSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxMultiStopSearch*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_MultiStopSearch*/}

              {/* END_USER_CODE-USER_AFTER_MultiStopSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(StockTransfer_MultiStopSearch);
