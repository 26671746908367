/* eslint-disable*/
import React from "react";
import SideMenu from "./SideMenu";
import { cleanup, render, screen, fireEvent } from "@testing-library/react";
import "regenerator-runtime/runtime";
import { act } from "react-dom/test-utils";
import renderTestScreen from "../../../common/renderTestScreen";
import AppContext from "../../../context/AppContext";
import { t, getTwoLevelMenus } from "../../../__mocks__/react-i18next";
import { MemoryRouter } from "react-router-dom";

describe("SideMenu Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      render(
        <AppContext.Provider
          value={{
            t: t,
            _selectedLang: "en",
            screenList: getTwoLevelMenus(),
            _languages: window._kaledo["languages"],
            activeTab: 0
          }}
        >
          <MemoryRouter>{<SideMenu />}</MemoryRouter>
        </AppContext.Provider>
      );
      //renderTestScreen(<SideMenu />);
    });
  });
  afterEach(cleanup);
  test("is SideMenu Loads Successfully", async () => {
    let stdScreen = screen.getAllByTestId("scrnDispName");
    stdScreen = stdScreen[0].firstChild;
    await act(async () => {
      fireEvent.focus(stdScreen);
    });
    fireEvent.mouseOver(stdScreen);
  });
});
describe("SideMenu Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      render(
        <AppContext.Provider
          value={{
            t: t,
            _selectedLang: "en",
            screenList: getTwoLevelMenus(),
            _languages: window._kaledo["languages"],
            activeTab: 1
          }}
        >
          <MemoryRouter>{<SideMenu />}</MemoryRouter>
        </AppContext.Provider>
      );
      //renderTestScreen(<SideMenu />);
    });
  });
  afterEach(cleanup);
  test("is SideMenu Loads Successfully", async () => {
    let stdScreen = screen.getAllByTestId("scrnDispName");
    stdScreen = stdScreen[0].firstChild;
    await act(async () => {
      fireEvent.focus(stdScreen);
    });
    fireEvent.mouseOver(stdScreen);
  });
});
