/* eslint-disable*/
export const CommonKeys = {
    Pending: 'Pending', 
    Signed: 'Signed',
    Approved: 'Approved',
    Cancelled: 'Cancelled',
    Voided: 'Voided',
    Closed: 'Closed'
}

export const VendorLookUpValidation = {
    AtleastFourCharacter: 'At least 4 characters must be specified to perform a search!!!',
}
export const ExceptPremRequestProfile = {
    cmdApprove_Click: 'Requested By is required!!!',
}

export const CommonMessages = {
    DataViewedSuccessfully: 'Data viewed successfully',
    DataNotFound: 'Data not found'
}

export const dictRolesControls = [
    //** Applications Section **/
    {key: 'PN1010', value:'btnNewContract'},
    {key: 'PN1020', value:'btnSearchForContracts'},
    {key: 'PN1150', value:'btnContractGroupings'},
    {key: 'PN1190', value:'btnExceptionPremiums'},
    {key: 'PN1040', value:'btnVoidNoSaleSC95'},
    {key: 'PN1130', value:'btnTradeAgreements'},
    //{key: 'PN0070', value:'btnUserManuals'},
    {key: 'PN1060', value:'btnSearchFor'},
    {key: 'PN1140', value:'btnTradeSettlements'},
    {key: 'PN1090', value:'btnWarehouseRecipts'},
    {key: 'PN1080', value:'btnStockTransfers'},
    {key: 'PN1160', value:'btnGroupings1007'},
    //{key: '', value:'btnReports'},
    {key: 'PN1050', value:'btnFarmSumm'},
    //{key: '', value:'btnViewReports'},
    {key: 'PN1030', value:'btnProcReport'},
    {key: 'PN1100', value:'btnPaymentProcessing'},
    //{key: '', value:'btnReadyToPayRelease'},
    //{key: '', value:'btnPrintChecks'},
    //{key: '', value:'btnSpoiledCheckEntry'},
   //{key: '', value:'btnInquireVoidCorrectChecks'},
    //{key: '', value:'btnSpecialPayables'},
    {key: 'PN1180', value:'btnDeliveryInOut'},

    //** System Maintenance && Setup **/
    {key: 'PN0010', value:'btnStateCountries'},
    {key: 'PN0040', value:'btnPeanuts'},
    {key: 'PN0020', value:'btnBuyingPoints'},
    {key: 'PN0180', value:'btnVoidNoSaleSetup'},
    {key: 'PN0350', value:'btnVendoeMaintenance'},
    {key: 'PN0030', value:'btnFarms'},
    {key: 'PN0090', value:'btnStorageSchedule'},
    {key: 'PN0140', value:'btnCureCleanSchedule'},
    {key: 'PN1110', value:'btnVoidWRcontrolNumber'},
    {key: 'PN0205', value:'btnSignatureSetup'},
    {key: 'PN1170', value:'btnScaleTicket'},
    {key: 'PN0100', value:'btnPremDeducsMaster'},
    {key: 'PN0120', value:'btnPremDeducsRates'},
    {key: 'PN0260', value:'btnContractLimitControl'},
    {key: 'PN0250', value:'btnFreightControl'},
    {key: 'PN0080', value:'btnPeanutSpecifications'},
    {key: 'PN0110', value:'btnSpotPrice'},
    {key: 'PN0130', value:'btnInterestAgreement'},
    {key: 'PN0310', value:'btnFilters'},
    {key: 'PN0270', value:'btnFarmerStockPriceSetup'},
    {key: 'PN1070', value:'btnInventory'},
    {key: 'PN0160', value:'btnOpenAccounts'},
    {key: 'PN0190', value:'btnFederalWhseLic'},
    {key: 'PN0200', value:'btnSpecialFunction'},
];

//** Special function menu */
export const dictSpecialFunctionsRoles = [
    {key: 'PN0201', value:'btnChangeNumbers'},
    {key: 'PN0202', value:'btnReprintDocuments'},
    {key: 'PN0203', value:'btnMiscFunctions'},
    {key: 'PN0050', value:'btnCompanies'},
    {key: 'PN0210', value:'btnReadyToPayMntnce'},
    {key: 'PN0060', value:'btnSecurity'},
    {key: 'PN0230', value:'btnErrorCodeFilterMntnce'},
    {key: 'PN0240', value:'btnAcctDistMntnce'},
    {key: 'PN0300', value:'btnHighMoistureSetup'},
    {key: 'PN0320', value:'btnInventoryMntnce'},
    {key: 'PN0204', value:'btnWireTransferSetup'},
    {key: 'PN1120', value:'btnCMADMAControl'},
    {key: 'PN0220', value:'btnTradeCategory'},
    {key: 'PN0206', value:'btnDashboard'},
    {key: 'PN0070', value:'btnPricingSchedule'},
    {key: 'PN0170', value:'btnLoanRepayment'},
    {key: 'PN0290', value:'btnLoanInterest'},
    {key: 'PN0280', value:'btnMarketPriceSetup'},
    {key: 'PN0150', value:'btnBankingHolidays'},
    {key: 'PN0330', value:'btnLoanReduction'},
];

export const RegEx = {
    OnlyIntegerNumber: /^\d+$/,
}

export const Application = {
    Major: 11, 
    Minor: 1,
    Revision: 28
}

export const returnNumericValues = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
            res += data[i]
        }
    }
    return res;
}

export const NumericValueValidataion = (value) =>{
    let res = ''
    for(var i=0;i<value.length;i++){
      res += (['0','1','2','3','4','5','6','7','8','9','.', '-'].includes(value[i]) ? value[i] : '')
    }
    return res
}

export const toPascalCase = (word) => {    
    return word?.replace(/\w+/g,
      function (w) {
        return w[0].toUpperCase() + w.slice(1).toLowerCase();
      });
}

export const GetReportLogoImage = (environmentURL) => {
    let url = require('../../../assets/img/ReportLogo.png')
    return environmentURL  + ( url.slice(0, 1) == "."
                                ? url.slice(1)
                                : url
                             )
}

export const GetReportLogoImageDimension = (ScreenName) => {
    if(ScreenName == 'ReportPreviewCheck'){
        return `width="200px" height="80px"`
    }
    return `width="200px" height="80px"`
}