/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  LabelWidget,
  TextAreaWidget,
  disable,
  enable,
  setValue,
  setData,
  getData,
  hide,
  goTo
} from "../../shared/WindowImports";

import "./FlexMktSign.scss";

import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../Service/ContractManagementService";
import ModalPopUp from "react-bootstrap/Modal";
import { useDropzone } from "react-dropzone";

function ContractManagement_FlexMktSign(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  
  const selectionMode = "Selection"
  const scanningMode = "Scanning"
  const verifyMode = "Verifying"

  const [isShow, invokeModal] = React.useState(false)
  const [files, setFiles] = useState([]);
  let cropYear = cropYearFromLS()
  let comp_id = compIdFromLS()

  let func_id = ""
  let mbMode = selectionMode;
  let dialogOpen = false;

  let states = {
    Label: "FlexMktSign",
    windowName: "FlexMktSign",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.FlexMktSign",

    selectedFiles: "No file selected",
    currentFile: "No file selected",
    progress: 0,
    message: "",
    fileInfos: [],

    headerData
      : {
      scrName: "Scan and Sign Contract Pricing",
      scrCode: "PN1010H"
    },
    btnSaveFrmExFile: {
      name: "btnSaveFrmExFile",
      type: "ButtonWidget",
      parent: "grpbxSavingCntrctOpt",
      Label: "Save From Existing File",
      CharWidth: "47",
      ofTypeDomain: "d_boolean",
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxSavingCntrctOpt",
      Label: "Cancel",
      CharWidth: "15",
    },
    btnVerSave: {
      name: "btnVerSave",
      type: "ButtonWidget",
      parent: "grpbxVerResultActs",
      Label: "Verify And Save",
      CharWidth: "32",
      ofTypeDomain: "d_boolean",
    },
    btnCncl: {
      name: "btnCncl",
      type: "ButtonWidget",
      parent: "grpbxVerResultActs",
      Label: "Cancel",
      CharWidth: "15",
      ofTypeDomain: "d_boolean",
    },
    lblCntrctPrcInfo: {
      name: "lblCntrctPrcInfo",
      type: "LabelWidget",
      parent: "grpbxCntrctPrcInfo",
      Label: "Contract Pricing Information:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblSavingCntrctPrcOpt: {
      name: "lblSavingCntrctPrcOpt",
      type: "LabelWidget",
      parent: "grpbxSavingCntrctOpt",
      Label: "Saving Contract Pricing Options:",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblScanningOptions: {
      name: "lblScanningOptions",
      type: "LabelWidget",
      parent: "grpbxScanningOptions",
      Label: "Scanning Options:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtarScanningStatus: {
      name: "txtarScanningStatus",
      type: "TextAreaWidget",
      parent: "grpbxScanningOptions",
      Label: "Scanning Status:",
      ColSpan: "3",
      RowSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtarVerResult: {
      name: "txtarVerResult",
      type: "TextAreaWidget",
      parent: "grpbxVerResultActs",
      Label: "Verification Result:",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCntrctPrcNum: {
      name: "txtCntrctPrcNum",
      type: "TextBoxWidget",
      parent: "grpbxCntrctPrcInfo",
      Label: "Contract Pricing Number:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    grpbxCntrctPrcInfo: {
      name: "grpbxCntrctPrcInfo",
      type: "GroupBoxWidget",
      parent: "FlexMktSign",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
    },
    grpbxSavingCntrctOpt: {
      name: "grpbxSavingCntrctOpt",
      type: "GroupBoxWidget",
      parent: "FlexMktSign",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
    },
    grpbxScanningOptions: {
      name: "grpbxScanningOptions",
      type: "GroupBoxWidget",
      parent: "FlexMktSign",
      Height: "",
      Width: "",
      HasLabel: false,
    },
    grpbxVerResultActs: {
      name: "grpbxVerResultActs",
      type: "GroupBoxWidget",
      parent: "FlexMktSign",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
    },
    dropzoneFlag: true
  };

  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    let screenData = getData(thisObj, 'frmFlexMktSigntdata')
    setValue(thisObj, 'txtCntrctPrcNum', screenData[0].lblContractPricing)
    func_id = state.headerData.scrCode
    
    EnableDisableControls()
    disable(thisObj, "txtCntrctPrcNum")
    disable(thisObj, "txtarVerResult")
    enable(thisObj, "btnCncl")
    hide(thisObj, "grpbxScanningOptions")
  }, [thisObj.state.dropzoneFlag]);

  function UploadScannedContract(fileName) {
    try {
      if (fileName == '') {
        showMessage(thisObj, "FileName is empty")
        return false;
      } else if (fileName.split('.')[1] !== 'pdf') {
        showMessage(thisObj, "File is not pdf format")
        return false;
      }
      setValue(thisObj, "txtarVerResult",fileName + " loaded. Select Verify and Save to verify. Cancel to exit scanning.")
      invokeModal(false)
      return true;
    }
    catch (error) {
      setValue(thisObj, "txtarVerResult", "Please try to upload the file again.")
      invokeModal(false)
      return false;
    }
  }

  async function FTPContract(file) {
    let screenData = getData(thisObj, 'frmFlexMktSigntdata')
    var environmentType = 'PPSTest'
    if(process.env.REACT_APP_ENVIR == 'PROD'){
      environmentType = 'PPSProd'
    }
    var cont = ''
    
    if(screenData[0].mstrContractNumber.length <= 6){
      for(var i=0; i<(6-screenData[0].mstrContractNumber.length); i++){
        cont+= '0'
      }
    }
    
    var contractNumber = cont + screenData[0].mstrContractNumber;
    var date = new Date();
    const numArr = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59]
    var priceSuffix = screenData[0].lblContractPricing.slice(-1)
    var fileNameToProcess = "PEF" + screenData[0].ContractAction + comp_id.slice(0,2) + cropYear + contractNumber + "_".repeat(3 - priceSuffix?.length) + screenData[0].lblContractPricing.slice(-1) +
                            date.getFullYear() + numArr[date.getMonth() + 1] + numArr[date.getDate()] + numArr[date.getHours()] + numArr[date.getMinutes()] + numArr[date.getSeconds()] + ".pdf"
    
    const formData = new FormData();
    formData.append('FormFile', file[0]);
    formData.append('filename', fileNameToProcess)
    formData.append('environment', environmentType)
    formData.append('folderType', 'PriceElection')

    let response = await ContractManagementService.UploadFtpFile(formData)
    if (response != undefined && response != null) {
      if (response.message == 'File transfer successfully') {
        setValue(thisObj, "txtarVerResult", "File " + file[0].name + " successfully transferred as " + fileNameToProcess)
        enable(thisObj, "btnSaveFrmExFile")
        disable(thisObj, "btnVerSave")
        disable(thisObj, "btnCancel")
        enable(thisObj, "btnCncl")
        alert("File " + file[0].name + " successfully transferred as " + fileNameToProcess)
        if (screenData[0].ContractAction == 'S') {
          let response = await ContractManagementService.RetrieveFlexMarketPriceElectionSignDetails(screenData[0].mstrContractNumber, screenData[0].lblContractPricing.slice(-1), null)
          
          if (response != null || response != undefined) {
            if (response.length != 0) {
              setData(thisObj, 'screen_reload', true)
              goTo(thisObj, 'ContractManagement#FlexibleMarketPricings#DEFAULT#false#Click')
            }
          }
          else {
            showMessage(thisObj, 'Pricing Election not signed.')
            setValue(thisObj, "txtarVerResult", 'Pricing Election not signed.')
            setData(thisObj, 'screen_reload', false)
          }
        }
        else if (screenData[0].ContractAction == 'R') {
          setData(thisObj, 'screen_reload', true)
          goTo(thisObj, 'ContractManagement#FlexibleMarketPricings#DEFAULT#false#Click')
        }        
        return true;

      } 
      else if (response.message != 'File transfer successfully') {
        setValue(thisObj, "txtarVerResult", "Problem with saving (FTP) the Contract Pricing. You can retry or upload the file later")
        showMessage(thisObj, "Problem with saving (FTP) the Contract Pricing. You can retry or upload the file later")
        disable(thisObj, "btnVerSave")
        enable(thisObj, "btnSaveFrmExFile")
        enable(thisObj, "onbtnCnclClick")
        enable(thisObj, "btnCncl")
      }
    }
    else {
      setValue(thisObj, "txtarVerResult", "The PDF file is of a lower version and not supported. To re-create the file in higher version, Open the File > Go to File > Print > Select Printer Microsoft Print to PDF > Save this version somewhere. Upload the new saved contract. Please contact your system administrator if the problem persist.")
      showMessage(thisObj, "The PDF file is of a lower version and not supported. To re-create the file in higher version, Open the File > Go to File > Print > Select Printer Microsoft Print to PDF > Save this version somewhere. Upload the new saved contract. Please contact your system administrator if the problem persist.")
      disable(thisObj, "btnVerSave")
      enable(thisObj, "btnSaveFrmExFile")
      enable(thisObj, "onbtnCnclClick")
      enable(thisObj, "btnCncl")
    }
    setValue(thisObj, "txtarVerResult", response.message)
    showMessage(thisObj, response.message)
  }

  function VerifyContract(file) {
    try {
      FTPContract(file)
    }
    catch (err) {
      showMessage(thisObj, err.message)
      return false;
    }
  }

  function EnableDisableControls() {
    if (mbMode == selectionMode) {
      disable(thisObj, "btnCancel")
      disable(thisObj, "btnVerSave")
      disable(thisObj, "txtCntrctPrcNum")
      setValue(thisObj, "txtarScanningStatus", "")
      setValue(thisObj, "txtarVerResult", "")
    }
    else if (mbMode == scanningMode) {
      disable(thisObj, "btnSaveFrmExFile")
      disable(thisObj, "btnCancel")
      setValue(thisObj, "txtarScanningStatus", "Load Contract Pricing and select scanning option. Cancel to exit scanning.")
      setValue(thisObj, "txtarVerResult", "")
    }
    else if (mbMode == verifyMode) {
      disable(thisObj, "btnSaveFrmExFile")
      disable(thisObj, "btnCancel")
      disable(thisObj, "btnScanDocFeeder")
      setValue(thisObj, "txtarScanningStatus", "")
      setValue(thisObj, "txtarVerResult", "Review scanned Contract Pricing. Click verify and Save to save Contract Pricing.  Click Cancel to return to Saving Options")
    }
    else {
      showMessage(thisObj, "Invalid selection. ")
    }
  }

  function checkFiles() {
    dialogOpen = false;
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept : ".pdf",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )

      if (acceptedFiles != undefined && acceptedFiles != null) {
        if (acceptedFiles[0].name.split('.')[1] != 'pdf') {
          invokeModal(false);
          disable(thisObj, "btnVerSave")
          enable(thisObj, "btnSaveFrmExFile")
          disable(thisObj, "btnCancel")
          setValue(thisObj, "txtarVerResult", "Please select only pdf files.")
          return;
        }
        invokeModal(false);
      }
      state.currentFile = acceptedFiles
      setData(thisObj, "currentFile", acceptedFiles)
      setValue(thisObj, "currentFile", acceptedFiles)
      var res = UploadScannedContract(acceptedFiles[0].name)
      if (res) {
        disable(thisObj, "btnSaveFrmExFile")
        enable(thisObj, "btnVerSave")
        enable(thisObj, "btnCancel")
      }
      else {
        invokeModal(false);
        enable(thisObj, "btnSaveFrmExFile")
        disable(thisObj, "btnVerSave")
        disable(thisObj, "btnCancel")
      }
    }
  })

  const onbtnCancelClick = () => {
    try {
      disable(thisObj, "btnVerSave")
      enable(thisObj, "btnSaveFrmExFile")
      disable(thisObj, "btnCancel")
      setValue(thisObj, "txtarVerResult", "")
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbtnCnclClick event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onbtnSaveFrmExFileClick = () => {
    try {
      invokeModal(true)
      thisObj.setState((current) => {
        return {
          ...current,
          dropzoneFlag: !thisObj.state.dropzoneFlag
        }
      })
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnScanCntrct event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnSaveFrmExFileClick = onbtnSaveFrmExFileClick;

  const onbtnCnclClick = () => {
    try {
      document.getElementsByClassName("close")[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onbtnCnclClick event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCnclClick = onbtnCnclClick;

  const onbtnVerSaveClick = () => {
    try {
      disable(thisObj, "onbtnCncl")
      disable(thisObj, "btnSaveFrmExFile")
      VerifyContract(getData(thisObj, "currentFile"))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:onVerSaveClick event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnVerSaveClick = onbtnVerSaveClick;

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div id="blur"
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />

            <ContractManagement_header headerData={state.headerData} />
            <h1 className="pageHeader">{state.Label}</h1>

            <Form noValidate className="row">
              <GroupBoxWidget
                conf={state.grpbxCntrctPrcInfo}
                screenConf={state}
              >
                <LabelWidget
                  values={values}
                  conf={state.lblCntrctPrcInfo}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCntrctPrcNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
              </GroupBoxWidget>

              <GroupBoxWidget
                conf={state.grpbxSavingCntrctOpt}
                screenConf={state}
              >
                <LabelWidget
                  values={values}
                  conf={state.lblSavingCntrctPrcOpt}
                  screenConf={state}
                ></LabelWidget>
                <ButtonWidget
                  conf={state.btnSaveFrmExFile}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ModalPopUp id="blur"
                  show={isShow}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  keyboard="false"
                  style={{ width: "50%", height: "100%", left: "26%" }}
                  autoFocus
                  centered
                >
                  <ModalPopUp.Body >
                    <div style={{ alignContent: "centered", textAlign: "centered", height: "100%" }}>
                      <div className="dropArea" {...getRootProps()} style={{ border: "1px solid #ccc", textAlign: "centered", height: "100%",backgroundColor:"white" }}>
                        <label for="dropFile" style={{ textAlign: "center", width: "100%", height: "100%" }} class="btn">
                          <strong> Drag 'n' drop some file here, or click to select file. </strong>
                        </label>
                        {/* <input {...getInputProps()} accept=".pdf" id="dropFile" type="file" style={{ width: "50%", height: "100%", visibility: "hidden" }} /> */}
                      </div>
                    </div>
                  </ModalPopUp.Body>
                </ModalPopUp>
                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
              </GroupBoxWidget>

              <GroupBoxWidget
                conf={state.grpbxVerResultActs}
                screenConf={state}
              >
                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarVerResult}
                  screenConf={state}
                ></TextAreaWidget>
                <ButtonWidget
                  conf={state.btnVerSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnCncl}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
              </GroupBoxWidget>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_FlexMktSign);