/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_LeasedTrailerProfile from "./LeasedTrailerProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("LeasedTrailerProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_LeasedTrailerProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is LeasedTrailerProfile Loads Successfully", () => {
    expect(screen.getByText("LeasedTrailerProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for LeasedTrailerProfile", () => {
    // START_USER_CODE-USER_LeasedTrailerProfile_Custom_Test_Case
    // END_USER_CODE-USER_LeasedTrailerProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_LeasedTrailerProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:LeasedTrailerProfile_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:LeasedTrailerProfile_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:LeasedTrailerProfile_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("gridLeasedTrailerListData(Grid Widget) Test Cases", async () => {
    let gridLeasedTrailerListData = screen.getByTestId(
      "gridLeasedTrailerListData"
    );
    let gridLeasedTrailerListDatabtn =
      gridLeasedTrailerListData.nextElementSibling.firstElementChild;
    gridLeasedTrailerListData =
      gridLeasedTrailerListData.parentElement.parentElement.parentElement;
    expect(gridLeasedTrailerListData.tagName).toBe("DIV");
    expect(gridLeasedTrailerListData.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:LeasedTrailerProfile_gridLeasedTrailerListData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridLeasedTrailerListData", () => {
    // START_USER_CODE-USER_gridLeasedTrailerListData_TEST
    // END_USER_CODE-USER_gridLeasedTrailerListData_TEST
  });
  test("grpbxLeasedTrailerList(GroupBox Widget) Test Cases", async () => {
    const grpbxLeasedTrailerList = screen.getByTestId("grpbxLeasedTrailerList");
    expect(grpbxLeasedTrailerList.tagName).toBe("BUTTON");
    expect(grpbxLeasedTrailerList.type).toBe("button");
    expect(grpbxLeasedTrailerList.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLeasedTrailerList", () => {
    // START_USER_CODE-USER_grpbxLeasedTrailerList_TEST
    // END_USER_CODE-USER_grpbxLeasedTrailerList_TEST
  });
  test("grpbxLeaseTrailerProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxLeaseTrailerProfile = screen.getByTestId(
      "grpbxLeaseTrailerProfile"
    );
    expect(grpbxLeaseTrailerProfile.tagName).toBe("BUTTON");
    expect(grpbxLeaseTrailerProfile.type).toBe("button");
    expect(grpbxLeaseTrailerProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLeaseTrailerProfile", () => {
    // START_USER_CODE-USER_grpbxLeaseTrailerProfile_TEST
    // END_USER_CODE-USER_grpbxLeaseTrailerProfile_TEST
  });
  test("lblLeasedTrailerList(Label Widget) Test Cases", async () => {
    const lblLeasedTrailerList = screen.getByTestId("lblLeasedTrailerList");
    expect(lblLeasedTrailerList.tagName).toBe("LABEL");
    expect(lblLeasedTrailerList.classList).toContain("form-label");
    expect(lblLeasedTrailerList.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:LeasedTrailerProfile_lblLeasedTrailerList"
      )
    );
  });
  test("Custom Test Cases for lblLeasedTrailerList", () => {
    // START_USER_CODE-USER_lblLeasedTrailerList_TEST
    // END_USER_CODE-USER_lblLeasedTrailerList_TEST
  });
  test("lblPercent(Label Widget) Test Cases", async () => {
    const lblPercent = screen.getByTestId("lblPercent");
    expect(lblPercent.tagName).toBe("LABEL");
    expect(lblPercent.classList).toContain("form-label");
    expect(lblPercent.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:LeasedTrailerProfile_lblPercent")
    );
  });
  test("Custom Test Cases for lblPercent", () => {
    // START_USER_CODE-USER_lblPercent_TEST
    // END_USER_CODE-USER_lblPercent_TEST
  });
  test("gridLeasedTrailerListData_txtAddedBy(Grid Widget) Test Cases", async () => {
    let gridLeasedTrailerListData_txtAddedBy = screen.getByTestId(
      "gridLeasedTrailerListData"
    );
    let gridLeasedTrailerListData_txtAddedBybtn =
      gridLeasedTrailerListData_txtAddedBy.nextElementSibling.firstElementChild;
    gridLeasedTrailerListData_txtAddedBy =
      gridLeasedTrailerListData_txtAddedBy.parentElement.parentElement
        .parentElement;
    expect(gridLeasedTrailerListData_txtAddedBy.tagName).toBe("DIV");
    expect(gridLeasedTrailerListData_txtAddedBy.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:LeasedTrailerProfile_gridLeasedTrailerListData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtAddedBy", () => {
    // START_USER_CODE-USER_txtAddedBy_TEST
    // END_USER_CODE-USER_txtAddedBy_TEST
  });
  test("gridLeasedTrailerListData_txtLocation(Grid Widget) Test Cases", async () => {
    let gridLeasedTrailerListData_txtLocation = screen.getByTestId(
      "gridLeasedTrailerListData"
    );
    let gridLeasedTrailerListData_txtLocationbtn =
      gridLeasedTrailerListData_txtLocation.nextElementSibling
        .firstElementChild;
    gridLeasedTrailerListData_txtLocation =
      gridLeasedTrailerListData_txtLocation.parentElement.parentElement
        .parentElement;
    expect(gridLeasedTrailerListData_txtLocation.tagName).toBe("DIV");
    expect(gridLeasedTrailerListData_txtLocation.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:LeasedTrailerProfile_gridLeasedTrailerListData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtLocation", () => {
    // START_USER_CODE-USER_txtLocation_TEST
    // END_USER_CODE-USER_txtLocation_TEST
  });
  test("txtPercentage(Textbox Widget) Test Cases", async () => {
    const txtPercentage = screen.getByTestId("txtPercentage");
    expect(txtPercentage.tagName).toBe("INPUT");
    expect(txtPercentage.type).toBe("text");
    expect(txtPercentage.classList).toContain("textboxWidgetClass");
    expect(txtPercentage.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:LeasedTrailerProfile_txtPercentage")
    );
    await act(async () => {
      userEvent.type(txtPercentage, "1");
    });
  });
  test("Custom Test Cases for txtPercentage", () => {
    // START_USER_CODE-USER_txtPercentage_TEST
    // END_USER_CODE-USER_txtPercentage_TEST
  });
  test("gridLeasedTrailerListData_txtPercentageC(Grid Widget) Test Cases", async () => {
    let gridLeasedTrailerListData_txtPercentageC = screen.getByTestId(
      "gridLeasedTrailerListData"
    );
    let gridLeasedTrailerListData_txtPercentageCbtn =
      gridLeasedTrailerListData_txtPercentageC.nextElementSibling
        .firstElementChild;
    gridLeasedTrailerListData_txtPercentageC =
      gridLeasedTrailerListData_txtPercentageC.parentElement.parentElement
        .parentElement;
    expect(gridLeasedTrailerListData_txtPercentageC.tagName).toBe("DIV");
    expect(gridLeasedTrailerListData_txtPercentageC.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:LeasedTrailerProfile_gridLeasedTrailerListData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtPercentageC", () => {
    // START_USER_CODE-USER_txtPercentageC_TEST
    // END_USER_CODE-USER_txtPercentageC_TEST
  });
  test("gridLeasedTrailerListData_txtTrailerType(Grid Widget) Test Cases", async () => {
    let gridLeasedTrailerListData_txtTrailerType = screen.getByTestId(
      "gridLeasedTrailerListData"
    );
    let gridLeasedTrailerListData_txtTrailerTypebtn =
      gridLeasedTrailerListData_txtTrailerType.nextElementSibling
        .firstElementChild;
    gridLeasedTrailerListData_txtTrailerType =
      gridLeasedTrailerListData_txtTrailerType.parentElement.parentElement
        .parentElement;
    expect(gridLeasedTrailerListData_txtTrailerType.tagName).toBe("DIV");
    expect(gridLeasedTrailerListData_txtTrailerType.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:LeasedTrailerProfile_gridLeasedTrailerListData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtTrailerType", () => {
    // START_USER_CODE-USER_txtTrailerType_TEST
    // END_USER_CODE-USER_txtTrailerType_TEST
  });
  test("gridLeasedTrailerListData_txtVehicleNum(Grid Widget) Test Cases", async () => {
    let gridLeasedTrailerListData_txtVehicleNum = screen.getByTestId(
      "gridLeasedTrailerListData"
    );
    let gridLeasedTrailerListData_txtVehicleNumbtn =
      gridLeasedTrailerListData_txtVehicleNum.nextElementSibling
        .firstElementChild;
    gridLeasedTrailerListData_txtVehicleNum =
      gridLeasedTrailerListData_txtVehicleNum.parentElement.parentElement
        .parentElement;
    expect(gridLeasedTrailerListData_txtVehicleNum.tagName).toBe("DIV");
    expect(gridLeasedTrailerListData_txtVehicleNum.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceMasterManagement:LeasedTrailerProfile_gridLeasedTrailerListData"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtVehicleNum", () => {
    // START_USER_CODE-USER_txtVehicleNum_TEST
    // END_USER_CODE-USER_txtVehicleNum_TEST
  });
  test("txtVehicleNumber(Textbox Widget) Test Cases", async () => {
    const txtVehicleNumber = screen.getByTestId("txtVehicleNumber");
    expect(txtVehicleNumber.tagName).toBe("INPUT");
    expect(txtVehicleNumber.type).toBe("text");
    expect(txtVehicleNumber.classList).toContain("textboxWidgetClass");
    expect(txtVehicleNumber.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:LeasedTrailerProfile_txtVehicleNumber"
      )
    );
    await act(async () => {
      userEvent.type(txtVehicleNumber, "1");
    });
  });
  test("Custom Test Cases for txtVehicleNumber", () => {
    // START_USER_CODE-USER_txtVehicleNumber_TEST
    // END_USER_CODE-USER_txtVehicleNumber_TEST
  });
});
