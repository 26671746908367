/* eslint-disable*/
import { toast } from "react-toastify";
import hotkeys from "hotkeys-js";
import { callService } from "../../../common/PageSubmit";
import $ from "jquery";
import collect from "collect.js";
const _kaledo = window._kaledo;
  //static contextType = AppContext;
  let hotKeysMap = new Map();
  let thisObjGl={};
  export const dataCopyObj={};
  export const parentWindow=(thisObj1)=>{
    thisObjGl=thisObj1;
  }
  export const callOnOpenPreSubmit = (thisObj,state) => {
    let preSubOfOpen = thisObj[`on${state.windowName}Open`];
    if (preSubOfOpen) {
      preSubOfOpen();
    }
  };

 export const invokeEventHandler = (event,handleChange,handleBlur,thisObj) => {
   //thisObj=thisObj1;
	thisObj.state._screenErrors=[]
	thisObj.state._screenMessages= [];
  thisObj.buttonNavigationHandler=buttonNavigationHandler;
  thisObj.goTo=goTo;
  thisObj.setValue=setValue;
  thisObj.showMessage=showMessage;
  let thisObj1=thisObj;
    if (event.type === "change") {
      handleChange(event);
      setTimeout(() => {
        callService(thisObj1, event);
      }, 0);
    }
	else if (event.type === "blur") {
      handleBlur(event);
      setTimeout(() => {
        callService(thisObj1, event);
      }, 0);
    }
    else if (event.type === "paste"){
      if(_kaledo.isCobolTable && event.clipboardData !== undefined && event.target.attributes["maxlength"]!==undefined){
        //Todo:abs specific element
        var elemErr = document.getElementById('txtErrMsgs');
        var pasteText=event.clipboardData.getData('text');
        var maxLength=event.target.attributes["maxlength"].value;
        let requiredMsg = thisObj.context.t(
        `AppJSMessage:VALIDATIONMSG_PASTERESTRMSG`
        );
        const element = event.target;
        var allEle = window.$('input:enabled:visible:not([readonly])');
        var indexofCur=allEle.index(element);
        const position = element.selectionStart;
        if (position + pasteText.length > maxLength) {
          event.preventDefault();
          if(elemErr!==null){
            elemErr.value = requiredMsg;
            elemErr.style.color = 'red';
            elemErr.style.borderColor = 'red';
            setTimeout(() => {  
               elemErr.value = "";
            },4000);
          }
          else{
            toast.error(requiredMsg);
          }
    
        }
        else{
          if(elemErr!==null){
          elemErr.value = "";
          }
          
          if((position + pasteText.length)===maxLength){
          var next = element;		
          setTimeout(function(){ 
                  if(indexofCur<allEle.length-1){
                    next = allEle.get(indexofCur+1);
                  }
                  else{
                    next = allEle.get(0);
                  }
                  if(next!==undefined){
                    next.focus();
                    if(next.type==="text" || next.type==="password" || next.type==="textarea")
                      next.setSelectionRange(0, 0);
                  }
                  },0);
            }
        }
        }
    }
	else {
      callService(thisObj1, event);
    }
  };

  export const setValue = (thisObj,widgetId, value) => {
    let widgetObj = thisObj.state[widgetId];
    let widgetType = widgetObj.type;
    if (widgetType && widgetType === "GridWidget") {
      //chane start for removal of highlight on data change 
      // thisObj.state[widgetId].selected = [];
      // let activeElem  = document.getElementsByClassName("activeRow");
      // if(activeElem.length>0)
      //   activeElem[0].classList.remove("activeRow");
      //Fixed Given By FEE Engineering Team Regarding the Row Selectable in grid when coming back from child screen as well .
      thisObj.state[widgetId].selected = [];
     //const currentTarget = document.getElementsByClassName(widgetId);
    const currentTarget = document.getElementById(widgetId + "Collapse");
    //for (let div of currentTarget) {
    const activeElem = currentTarget.getElementsByClassName("activeRow");
    if (activeElem && activeElem.length > 0)
      for (let i = 0; i < activeElem.length; i++) {
        activeElem[i].classList.remove("activeRow");
      }
    // }
      //change end for removal of highlight on data change
      if (widgetObj.isEditable && _kaledo.isCobolTable) {
        if (Array.isArray(value) && value.length) {
          value = value.map(row => {
            return {
              ...row,
              opColumn: row.opColumn || "",
              isSelected: row.isSelected || false
            };
          });
          let firstObj = value[0];
          firstObj = { ...firstObj };
          for (let key in firstObj) {
            firstObj[key] = "";
          }
          let remainder = value.length % widgetObj.DisplaySize;
          if (remainder) {
            let remainngNoOfRows = parseInt(widgetObj.DisplaySize) - remainder;
            for (let i = 0; i < remainngNoOfRows; i++) {
              firstObj.isNewRow = true;
              value.push(firstObj);
            }
          }
        }
      }
    }
    if(widgetType && widgetType === "LinkWidget"){
      thisObj.state[widgetId].value = value
    }
    if (thisObj.setFieldValue) {
      thisObj.setFieldValue(widgetId, value);
    } else {
      // eslint-disable-next-line
      thisObj.state[widgetId].value = value;
    }
  };

//  export const getValue = (thisObj,widgetId) => {
//     if (thisObj.values) {
// 	  let widgetObj = thisObj.state[widgetId];
//       let widgetType = widgetObj.type;
//       if (widgetType && widgetType === "GridWidget") {
//         if (widgetObj.isEditable && _kaledo.isCobolTable) {
//           let rows = thisObj.values[widgetId];
//           rows = JSON.parse(JSON.stringify(rows));
//           let updatedRows = widgetObj.updatedRows;
//           let statusColName = widgetObj.statusColName;
//           rows = rows.map((row, index) => {
//             if (updatedRows.includes(index)) {
//               row[statusColName] = "U";
//             }

//             if (row.isNewRow) {
//               let isEmpty = true;
//               for (let key in row) {
//                 if (
//                   key === statusColName ||
//                   key === "isNewRow" ||
//                   key === "opColumn" ||
//                   key === "editing"
//                 ) {
//                   continue;
//                 }
//                 if (row[key]) {
//                   isEmpty = false;
//                   break;
//                 }
//               }

//               if (!isEmpty) {
//                 row[statusColName] = "C";
//               }
//             }

//             if (row.opColumn && (row.opColumn === "D" || row.opColumn === "d")) {
//               row[statusColName] = "D";
//             }
//             return row;
//           });

//           rows = rows.filter(row => row[statusColName]);

//           rows = rows.map(row => {
//             delete row.rowID$;
//             delete row.opColumn;
//             delete row.isSelected;
//             delete row.isNewRow;
//             delete row.editing;
//             return row;
//           });
//           return rows;
//         }
//       }
//       return thisObj.values[widgetId];
//     } else {
//       return thisObj.state[widgetId].value;
//     }
//   };

export const getValue = (thisObj, widgetId) => {
  if (thisObj.values) {
    let widgetObj = thisObj.state[widgetId];
    let widgetType = widgetObj.type;
    let gridData = widgetObj.gridData;
    if (widgetType && widgetType === "GridWidget") {
      if (widgetObj.isEditable && _kaledo.isCobolTable) {
        let rows = thisObj.values[widgetId];
        rows = JSON.parse(JSON.stringify(rows));
        let updatedRows = widgetObj.updatedRows;
        let statusColName = widgetObj.statusColName;
        rows = rows.map((row, index) => {
          if (updatedRows.includes(index)) {
            row[statusColName] = "U";
          }
          if (row.isNewRow) {
            let isEmpty = true;
            for (let key in row) {
              if (
                key === statusColName ||                
                key === "isNewRow" ||                
                key === "opColumn" ||                
                key === "editing"              
                ) {
                continue;
              }
              if (row[key]) {
                isEmpty = false;
                break;
              }
            }
            if (!isEmpty) {
              row[statusColName] = "C";
            }
          }
          if (row.opColumn && (row.opColumn === "D" || row.opColumn === "d")) {
            row[statusColName] = "D";
          }
          return row;
        });
        rows = rows.filter(row => row[statusColName]);
        rows = rows.map(row => {
          delete row.rowID$;
          delete row.opColumn;
          delete row.isSelected;
          delete row.isNewRow;
          delete row.editing;
          return row;
        });
        return rows;
      }
    }
    if (
      _kaledo.thisObj &&      thisObj.values[widgetId] &&      thisObj.values[widgetId].length < 1 &&      thisObj.state.Label == _kaledo.thisObj.state.Label    ) {
      return _kaledo.thisObj.values[widgetId];
    }
    return thisObj.values[widgetId];
  } else {
    return thisObj.state[widgetId].value;
  }
};

 export const showMessage = (thisObj,msg, isSuccess) => {
    if (isSuccess) {
		if(_kaledo["errorPosition"]!==undefined && _kaledo["errorPosition"]==="common")
		{
			var newState=[...thisObj.state._screenMessages];
      newState.push(msg);
      thisObj.setState(current => {  
        return {
          ...current,
          _screenMessages: newState,
        };
      });
      //return newState;
		}
		else
      toast.success(msg);
      //return null;

    } else {
		  if(_kaledo["errorPosition"]!==undefined && _kaledo["errorPosition"]==="common")
		  {
				var newState1=[...thisObj.state._screenErrors];
        newState1.push(msg);
        thisObj.setState(current => {
  
          return {
            ...current,
            _screenMessages: newState1,
          };
        });
        //return newState1;
		  }
		  else{   
          toast.error(msg);
         // return null;
		  }
    }
  };

export const  navigate = (thisObj,path) => {
    let { history } = thisObj.props;
    history.push(path);
  };

export const  handleModalHide = (thisObj,values) => {
    for (let [key, value] of hotKeysMap) {
        hotkeys.unbind(key);
        hotkeys(key, value);
    }
    if (values) {
      for (let key in values.values) {
        setValue(thisObj,key, values.values[key]);
      }
    }
    /*thisObj.setState({
      showPopup: false,
      popupComponent: null
    });*/
    thisObj.setState(current => {
  
      return {
        ...current,
        showPopup: false,
      popupComponent: null
      };
    });
  };

 export const buttonNavigationHandler = (thisObj,widgetId, typeOfEvent) => {
    let btnId = widgetId;
    if(widgetId.includes("_NodeClick")){
      btnId=widgetId.split("_NodeClick")[0];
    }
    let butType = thisObj.state[btnId]["Category"];
    if (butType && (butType === "Close" || butType === "Cancel")) {
      if (butType === "Close") goTo(thisObj,"", "R", true);
      else goTo(thisObj,"", "R", false);
      return;
    }
    let pageName = thisObj._buttonNavigation[widgetId]["DEFAULT"][0];
    if (pageName && pageName.indexOf("#") !== -1) {
      if (pageName.split("#").length > 4) {
        let evenType = pageName.split("#")[4];
        if (evenType !== typeOfEvent) {
          return;
        }
      }
    }
    goTo(thisObj,pageName, "F", true);
  };

  export const popupHandler = (thisObj,pageName, doDataCopy) => {
    let _guiModuleName = pageName.split("#")[0];
    let _scrName = pageName.split("#")[1];
    let popUpId = _guiModuleName + "_" + _scrName; //Added to fix Popup close issue
    if (
      doDataCopy &&
      thisObj.state["_dataCopy"]["FORWARD"][_guiModuleName + "#" + _scrName]
    ) {
      let mappingInfo = thisObj.state["_dataCopy"]["FORWARD"][
        _guiModuleName + "#" + _scrName
      ];
      thisObj.dataToCopy = {};
      for (let key in mappingInfo) {
        if (
          thisObj.state[thisObj.state[mappingInfo[key]].parent].type ===
            "GridWidget" &&
          thisObj.state[thisObj.state[mappingInfo[key]].parent].selected &&
          thisObj.state[thisObj.state[mappingInfo[key]].parent].selected.length
        ) {
          let selectedRows = thisObj.state[thisObj.state[mappingInfo[key]].parent]
            .selected;
          if (selectedRows[0].hasOwnProperty(mappingInfo[key]))
            thisObj.dataToCopy[key] = selectedRows[0][mappingInfo[key]];
        } else {
          thisObj.dataToCopy[key] = thisObj.values[mappingInfo[key]]
            ? thisObj.values[mappingInfo[key]]
            : thisObj.state[mappingInfo[key]].value;
        }
      }
      if (thisObj.state["_dataCopy"]["REVERSE"][_guiModuleName + "#" + _scrName]) {
        thisObj.dataToCopy["reverseMappingInfo"] = thisObj.state["_dataCopy"][
          "REVERSE"
        ][_guiModuleName + "#" + _scrName];
      }
    dataCopyObj.dataToCopy=thisObj.dataToCopy;

    }
    import(`../${_guiModuleName}/${_scrName}/${_scrName}`).then(component => {
      /*thisObj.setState({
        showPopup: true,
        popupComponent: component.default
      });*/
      component.name = popUpId; //Added to fix Popup close issue
      thisObj.setState(current => {
  
        return {
          ...current,
          showPopup: true,
          popupComponent: component //Removed .default to fix Popup close issue
        };
      });
    });
  };

 export const goTo = (thisObj,pageName, dir, doDataCopy) => {
    let _navigationUrl = "";
    let _navigationObj = {};
    let foundScrn = false;
    let isPopUp = "false";
    if (pageName.split("#").length > 2) {
      isPopUp = pageName.split("#")[3];
      if (isPopUp === "true") {
        popupHandler(thisObj,pageName, doDataCopy);
        return;
      }
    }
    if (dir === "F") {
      if (!_kaledo.screenFlowList) {
        _kaledo["screenFlowList"] = [];
      }
      _kaledo.screenFlowList.push(
        _kaledo.guiModuleName + "#" + thisObj.state.windowName + "#DEFAULT"
      );
      let _guiModuleName = pageName.split("#")[0];
      let _scrName = pageName.split("#")[1];
      _navigationUrl = _guiModuleName + "_" + _scrName;
      let obj = {};
      for (obj of _kaledo.ScreenList) {
        if (
          obj.GUIName === _guiModuleName &&
          obj.screenName === _scrName &&
          obj.URIPath
        ) {
          foundScrn = true;
          _navigationUrl = obj.URIPath;
          break;
        }
      }
      if (
        doDataCopy &&
        thisObj.state["_dataCopy"]["FORWARD"][_guiModuleName + "#" + _scrName]
      ) {
        let mappingInfo = thisObj.state["_dataCopy"]["FORWARD"][
          _guiModuleName + "#" + _scrName
        ];
        let dataToCopy = {};
        for (let key in mappingInfo) {
          if (
            thisObj.state[thisObj.state[mappingInfo[key]].parent].type ===
              "GridWidget" &&
            thisObj.state[thisObj.state[mappingInfo[key]].parent].selected &&
            thisObj.state[thisObj.state[mappingInfo[key]].parent].selected.length
          ) {
            let selectedRows = thisObj.state[thisObj.state[mappingInfo[key]].parent]
              .selected;
            if (selectedRows[0].hasOwnProperty(mappingInfo[key]))
              dataToCopy[key] = selectedRows[0][mappingInfo[key]];
          } else {
            dataToCopy[key] = thisObj.values[mappingInfo[key]]
              ? thisObj.values[mappingInfo[key]]
              : thisObj.state[mappingInfo[key]].value;
          }
        }
        if (
          thisObj.state["_dataCopy"]["REVERSE"][_guiModuleName + "#" + _scrName]
        ) {
          dataToCopy["reverseMappingInfo"] = thisObj.state["_dataCopy"]["REVERSE"][
            _guiModuleName + "#" + _scrName
          ];
        }
        _navigationObj.pathname = "/" + _navigationUrl;
        _navigationObj.data = dataToCopy;
      }
    } else {
      // let { history } = thisObj.props;
      // history.goBack();
      if (thisObj.props.ispopupScreen === true) {
        if (doDataCopy) {
          let values = {};
          values.values = {};
          if (thisObj.props.data.reverseMappingInfo) {
            let reverseMappingInfo = thisObj.props.data.reverseMappingInfo;
            for (let key in reverseMappingInfo) {
              if (
                thisObj.state[thisObj.state[reverseMappingInfo[key]].parent].type ===
                  "GridWidget" &&
                thisObj.state[thisObj.state[reverseMappingInfo[key]].parent]
                  .selected &&
                thisObj.state[thisObj.state[reverseMappingInfo[key]].parent].selected
                  .length
              ) {
                let selectedRow = thisObj.state[
                  thisObj.state[reverseMappingInfo[key]].parent
                ].selected[0];
                if (selectedRow.hasOwnProperty(reverseMappingInfo[key]))
                  values.values[key] = selectedRow[reverseMappingInfo[key]];
              } else values.values[key] = thisObj.values[reverseMappingInfo[key]];
            }
          }
          values.moduleName = _kaledo.guiModuleName;
          thisObj.props.onHide(values);
        } else thisObj.props.onHide();
      } else {
        if (_kaledo.screenFlowList && _kaledo.screenFlowList.length > 0) {
          pageName = _kaledo.screenFlowList.pop();
          let _guiModuleName = pageName.split("#")[0];
          let _scrName = pageName.split("#")[1];
          _navigationUrl = _guiModuleName + "_" + _scrName;
        }

        if (doDataCopy) {
          if (_navigationUrl === "") return;
          _navigationObj.pathname = "/" + _navigationUrl;
          let obj = {
            values: {}
          };
          if (thisObj.props.location.data.reverseMappingInfo) {
            let reverseMappingInfo = thisObj.props.location.data
              .reverseMappingInfo;
            for (let key in reverseMappingInfo) {
              if (
                thisObj.state[thisObj.state[reverseMappingInfo[key]].parent].type ===
                  "GridWidget" &&
                thisObj.state[thisObj.state[reverseMappingInfo[key]].parent]
                  .selected &&
                thisObj.state[thisObj.state[reverseMappingInfo[key]].parent].selected
                  .length
              ) {
                let selectedRow = thisObj.state[
                  thisObj.state[reverseMappingInfo[key]].parent
                ].selected[0];
                if (selectedRow.hasOwnProperty(reverseMappingInfo[key]))
                  obj["values"][key] = selectedRow[reverseMappingInfo[key]];
              } else obj["values"][key] = thisObj.values[reverseMappingInfo[key]];
            }
          }

          obj.moduleName = _kaledo.guiModuleName;
          obj.windowName = thisObj.state.windowName;
          _navigationObj.reverseData = obj;
        }
      }
    }
    if ((dir === "F" && foundScrn === true) || dir === "R") {
      if (Object.keys(_navigationObj).length !== 0)
        navigate(thisObj,_navigationObj);
      else {
        if (_navigationUrl !== "") navigate(thisObj,_navigationUrl);
      }
    }
  };

 export const callOnOpenService=(thisObj,screenRef) =>{
    for (let [key, value] of hotKeysMap) {
      hotkeys.unbind(key);
      hotkeys(key, value);
    }
    let event = {
      type: "open",
      target: {
        id: thisObj.state.windowName
      }
    };
    invokeEventHandler(event,undefined,undefined,thisObj);
    if(screenRef.current !== null){
		setTimeout(() => {
			focusFirstElement(screenRef,thisObj.state);
		}, 1);
    }
	document.title=thisObj.state.Label;
  }

 export const switchToTab=(thisObj,tabName)=> {
    let tabContainer = thisObj.state[tabName].parent;
    /*thisObj.setState({
      [tabContainer]: {
        ...thisObj.state[tabContainer],
        activeTab: tabName
      }
    });*/
    thisObj.setState(current => {
      return {
        ...current,
       [tabContainer]: {
              ...thisObj.state[tabContainer],
              activeTab: tabName
            }
      };
    });
  }
 export const focusFirstElement=(screenObj, obj)=> {
    if (screenObj.current !== null && screenObj.current !== undefined) {
      let widgets = [].slice.call(screenObj.current.children).find(name => name.localName === 'form');
      [].slice.call(widgets).every(child => {
        let input = isElementNeedsFocus(child, obj);
        if (input) {
          input.focus();
          return false;
        } else {
          return true;
        }
      });
    }
  }

 export const isElementNeedsFocus=(element, obj) =>{
    let id = "";
    let disabled = false;
    if (!element || !element.children) return undefined;
    if (!element.children.length && element.id !== "") {
      id = element.id;
      $.each(obj, function (widgetKey, widgetObj) {
        if (widgetObj["name"] === id && widgetObj["Enabled"] === false)
          disabled = true;
      });
    }
    if (!disabled &&
      (!element.children.length && (element.localName === 'input' || (typeof element.className === "String" &&element.className.includes("dropdown-toggle")) || element.localName === 'textarea')) &&
      !element.hidden)
      return element;

    let input;

    [].slice.call(element.children).every(c => {
      input = isElementNeedsFocus(c, obj);
      if (input) return false; // break
      return true; // continue!
    });

    return input;
  }
  
  export const updateChartScopeData = (thisObj,fieldName, extendedOptions, data) => {
    try {
      var finalOptions;
      if (typeof extendedOptions !== "undefined") {
        var prevOptions = thisObj.state[fieldName].options.chart;
        finalOptions = { ...prevOptions, ...extendedOptions };
        thisObj.setState({
          [fieldName]: {
            ...thisObj.state[fieldName],
            options: {
              chart: finalOptions
            }
          }
        });
      }
      if (typeof data !== "undefined" && data !== "") {
        if (thisObj.setFieldValue) {
          thisObj.setFieldValue(fieldName, data);
        } else {
          thisObj.setState({
            [fieldName]: {
              ...thisObj.state[fieldName],
              value: data
            }
          });
          // thisObj.state[fieldName].value = data;
        }
      }
      window.dispatchEvent(new Event("resize"));
    } catch (err) {
      toast.error(
        "updateChartScopeData(thisObj," + fieldName + ") Failed! Reason: " + err.message
      );
    }
  };

  //widget apis start

  // export const hide = (thisObj,widgetId, viewPort) => {
  //   /*const updateState=(widgetId,visible)=>{
  //     thisObj.setState(current => {
  //       return {
  //         ...current,
  //        [widgetId]: {
  //               ...thisObj.state[widgetId],
  //               [visible]: false
  //             }
  //       };
  //     });
  //   }*/
  //   if (thisObj.state[widgetId] !== undefined) {
  //     if (!viewPort) {
  //       if (thisObj.setFieldValue) {
  //         updateState(thisObj,widgetId,"Visible",false);
  //       } else {
  //         // eslint-disable-next-line
  //         thisObj.state[widgetId].Visible = false;
  //       }
  //     }
  //     if (viewPort === "TabLandscape") {
  //       if (thisObj.setFieldValue) {
  //         updateState(thisObj,widgetId,"VisibleForTabLandscape",false);
  //       } else {
  //         // eslint-disable-next-line
  //         thisObj.state[widgetId].VisibleForTabLandscape = false;
  //       }
  //     }
  //     if (viewPort === "TabPotrait") {
  //       if (thisObj.setFieldValue) {
  //         updateState(thisObj,widgetId,"VisibleForTabPotrait",false);
  //        } else {
  //         // eslint-disable-next-line
  //         thisObj.state[widgetId].VisibleForTabPotrait = false;
  //       }
  //     }
  //     if (viewPort === "Mobile") {
  //       if (thisObj.setFieldValue) {
  //         updateState(thisObj,widgetId,"VisibleForMobile",false);
  //       } else {
  //         // eslint-disable-next-line
  //         thisObj.state[widgetId].VisibleForMobile = false;
  //       }
  //     }
  //   }
  // };
  export const hide = (thisObj, widgetId, withCollapse=true, viewPort) => {
    if (thisObj.state[widgetId] !== undefined && withCollapse) {
      if (!viewPort) {
        if (thisObj.setFieldValue) {
          updateState(thisObj,widgetId,"Visible",false);
        } else {
          // eslint-disable-next-line
          thisObj.state[widgetId].Visible = false;
        }
      }
      if (viewPort === "TabLandscape") {
        if (thisObj.setFieldValue) {
          updateState(thisObj,widgetId,"VisibleForTabLandscape",false);
        } else {
          // eslint-disable-next-line
          thisObj.state[widgetId].VisibleForTabLandscape = false;
        }
      }
      if (viewPort === "TabPotrait") {
        if (thisObj.setFieldValue) {
          updateState(thisObj,widgetId,"VisibleForTabPotrait",false);
         } else {
          // eslint-disable-next-line
          thisObj.state[widgetId].VisibleForTabPotrait = false;
        }
      }
      if (viewPort === "Mobile") {
        if (thisObj.setFieldValue) {
          updateState(thisObj,widgetId,"VisibleForMobile",false);
        } else {
          // eslint-disable-next-line
          thisObj.state[widgetId].VisibleForMobile = false;
        }
      }
    }
    if(thisObj.state[widgetId] !== undefined && withCollapse=== false){
      thisObj.setState(current => {
        return {
          ...current,
         [widgetId]: {
                ...current[widgetId],
                withCollapse: withCollapse,
                Visible: false 
              }
        };
      });
    }
  };
  const updateState=(thisObj,widgetId,visible,val)=>{
    thisObj.setState(current => {
      return {
        ...current,
       [widgetId]: {
              ...current[widgetId],
              [visible]: val
            }
      };
    });
  }
  export const show = (thisObj,widgetId, viewPort) => {
    if (thisObj.state[widgetId] !== undefined) {
      if (!viewPort) {
        if (thisObj.setFieldValue) {
          updateState(thisObj,widgetId,"Visible",true);
        } else {
          // eslint-disable-next-line
          thisObj.state[widgetId].Visible = true;
        }
      }
      if (viewPort === "TabLandscape") {
        if (thisObj.setFieldValue) {
          updateState(thisObj,widgetId,"VisibleForTabLandscape",true);
        } else {
          // eslint-disable-next-line
          thisObj.state[widgetId].VisibleForTabLandscape = true;
        }
      }
      if (viewPort === "TabPotrait") {
        if (thisObj.setFieldValue) {
          updateState(thisObj,widgetId,"VisibleForTabPotrait",true);
        } else {
          // eslint-disable-next-line
          thisObj.state[widgetId].VisibleForTabPotrait = true;
        }
      }
      if (viewPort === "Mobile") {
        if (thisObj.setFieldValue) {
          updateState(thisObj,widgetId,"VisibleForMobile",true);
        } else {
          // eslint-disable-next-line
          thisObj.state[widgetId].VisibleForMobile = true;
        }
      }
    }
    document.getElementsByClassName(widgetId)[0].classList.remove('hideControl');
    thisObj.setState(current => {
      return {
        ...current,
       [widgetId]: {
              ...current[widgetId],
              withCollapse: true,
            }
      };
    });
  };

 export const getLabel = (thisObj,widgetId) => {
    if (thisObj.state[widgetId] !== undefined) {
      return thisObj.state[widgetId].Label;
    } else {
      return thisObj.state[widgetId];
    }
  };

  export const setLabel = (thisObj,widgetId, value) => {
    if (thisObj.setFieldLabel) {
      thisObj.setFieldLabel(widgetId, value);
    } else {
      //eslint-disable-next-line
      thisObj.state[widgetId].Label = value;
    }
  };

  export const isEnabled = (thisObj,widgetId) => {
    if (thisObj.state[widgetId].Enabled) {
      return true;
    } else {
      return false;
    }
  };

  export const isVisible = (thisObj,widgetId) => {
    if (thisObj.state[widgetId].Visible) {
      return true;
    } else {
      return false;
    }
  };

  export const enable = (thisObj,widgetId) => {
    if (thisObj.state[widgetId] !== undefined) {
      if (
        thisObj.state[widgetId].type === "TextAreaWidget" &&
        thisObj.state[widgetId].textareaType === "advanced"
      ) {
        $("[id^='react-summernote']").summernote("enable");
      }
      /*thisObj.setState({
        [widgetId]: { ...thisObj.state[widgetId], Enabled: true }
      });*/
      updateState(thisObj,widgetId,"Enabled",true);
    }
  };

 export const disable = (thisObj,widgetId) => {
    if (thisObj.state[widgetId] !== undefined) {
      if (
        thisObj.state[widgetId].type === "TextAreaWidget" &&
        thisObj.state[widgetId].textareaType === "advanced"
      ) {
        $("[id^='react-summernote']").summernote("disable");
      }
      /*thisObj.setState({
        [widgetId]: { ...thisObj.state[widgetId], Enabled: false }
      });*/
      updateState(thisObj,widgetId,"Enabled",false);
    }
  };

 export const readonly = (thisObj,widgetId, value) => {
    if (value) {
      updateState(thisObj,widgetId,"ReadOnly",true);
    } else {
      return false;
    }
  };

export const clearField = (thisObj,widgetId) => {
    if (thisObj.setFieldValue) {
      clearValue(thisObj,widgetId);
    } else {
      //eslint-disable-next-line
      thisObj.state[widgetId].value = "";
    }
  };

 export const clearValue = (thisObj,widgetId) => {
    if (thisObj.setFieldValue) {
      thisObj.setFieldValue(widgetId, "");
    } else {
      //eslint-disable-next-line
      thisObj.state[widgetId].value = "";
    }
  };

  // alertDialog = (Title, Bssize) => {
  //   thisObj.dialog.showAlert(Title, Bssize);
  // };

  // confirmationDialog = (Title, Body) => {
  //   thisObj.dialog.show({
  //     title: Title,
  //     body: Body,
  //     actions: [Dialog.CancelAction(), Dialog.OKAction()],
  //     bsSize: "medium"
  //   });
  // };

  // promptDialog = Title => {
  //   thisObj.dialog.show({
  //     title: Title,
  //     prompt: {
  //       initialValue: "",
  //       placeholder: "Write your name",
  //       required: true
  //     },
  //     actions: [Dialog.CancelAction(), Dialog.OKAction()],
  //     bsSize: "small"
  //   });
  // };

  export const getSelectedGridValue = (thisObj,gridId, columnId) => {
    var rowData;
    var cellData = null;
    let winObj = thisObj.state;
    if (!winObj.hasOwnProperty(gridId)) {
      throw new EvalError("Invalid Grid Id " + gridId + "'");
    }
    if (!winObj.hasOwnProperty(columnId)) {
      throw new EvalError("Invalid Column Id " + columnId + "'");
    }
    let gridObj = winObj[gridId];
    rowData = gridObj.selected[0];
    if (typeof rowData != "undefined" && Object.keys(rowData).length > 0) {
      if (rowData[columnId]) {
        cellData = rowData[columnId];
      }
    } else {
      showMessage(thisObj,"Row not selected");
    }
    return cellData;
  };

export const setSelectedGridValue = (thisObj,gridId, columnId, value) => {
    let selectedRowData;
    let winObj = thisObj.state;
    if (!winObj.hasOwnProperty(gridId)) {
      throw new EvalError("Invalid Grid Id " + gridId + "'");
    }
    if (!winObj.hasOwnProperty(columnId)) {
      throw new EvalError("Invalid Column Id " + columnId + "'");
    }
    let gridObj = winObj[gridId];
    selectedRowData = gridObj.selected[0];
    if (
      typeof selectedRowData != "undefined" &&
      Object.keys(selectedRowData).length > 0
    ) {
      let rowID$ = selectedRowData.rowID$;
      let rows = thisObj.values[gridId];
      let row = rows[rowID$];
      row = { ...row, [columnId]: value };
      rows[rowID$] = row;
      setValue(thisObj,gridId, rows);
    } else {
      throw new EvalError("Row not selected");
    }
  };

 export const setGridValue = (thisObj,gridId, colName, rowNo, unformattedVal) => {
    if (typeof gridId === "undefined" || gridId === "") {
      throw new EvalError(`Incorrect Grid Id`);
    }

    if (typeof colName === "undefined" || colName === "") {
      throw new EvalError(`Incorrect Column Name`);
    }

    if (typeof rowNo === "undefined" || rowNo < 0) {
      throw new EvalError(`Incorrect Row Number`);
    }

    if (thisObj.values[gridId] && thisObj.values[gridId].length) {
      let rows = thisObj.values[gridId];
      let row = rows[rowNo];
      row = { ...row, [colName]: unformattedVal };
      rows[rowNo] = row;
      setValue(thisObj,gridId, [...rows]);
    } else {
      throw new EvalError(`No Rows in Grid ${gridId}`);
    }
  };

 export const getGridValue = (thisObj,gridId, colName, rowNo) => {
    var cellData = null;
    let winObj = thisObj.state;
    if (typeof gridId === "undefined" || gridId === "") {
      throw new EvalError(`Incorrect Grid Id`);
    }

    if (typeof colName === "undefined" || colName === "") {
      throw new EvalError(`Incorrect Column Name`);
    }

    if (typeof rowNo === "undefined" || rowNo < 0) {
      throw new EvalError(`Incorrect Row Number`);
    }

    if (winObj && winObj[gridId]) {
      let gridrowObj = thisObj.values[gridId];
      if (gridrowObj && gridrowObj.length) {
        let rowObj = gridrowObj[rowNo];
        cellData = rowObj[colName];
      } else {
        throw new EvalError(`Incorrect Row Number`);
      }
    } else {
      throw new EvalError(`Incorrect Grid Id ${gridId}`);
    }
    return cellData;
  };

 export const disableGridColumn = (thisObj,gridId, colId) => {
    let winObj = thisObj.state;
    if (!gridId || !winObj[gridId]) {
      throw new EvalError("Incorrect Grid Name '" + gridId + "'");
    }
    if (!colId || !winObj[colId]) {
      throw new EvalError("Incorrect Column Name '" + colId + "'");
    }

    let gridObj = winObj[gridId];
    if (gridObj.columns) {
      let columns = gridObj.columns;
      let column = columns.find(column => {
        return column.name === colId;
      });
      if (column) {
        column.Enabled = false;
        /*thisObj.setState({
          gridId: {
            ...thisObj.state[gridId],
            columns: [...columns]
          }
        });*/
        thisObj.setState(current => {
          return {
            ...current,
           [gridId]: {
                  ...thisObj.state[gridId],
                  columns: [...columns]
                }
          };
        });
      }
    }
  };

 export const enableGridColumn = (thisObj,gridId, colId) => {
    let winObj = thisObj.state;
    if (!gridId || !winObj[gridId]) {
      throw new EvalError("Incorrect Grid Name '" + gridId + "'");
    }
    if (!colId || !winObj[colId]) {
      throw new EvalError("Incorrect Column Name '" + colId + "'");
    }

    let gridObj = winObj[gridId];
    if (gridObj.columns) {
      let columns = gridObj.columns;
      let column = columns.find(column => {
        return column.name === colId;
      });
      if (column) {
        column.Enabled = true;
        /*thisObj.setState({
          gridId: {
            ...thisObj.state[gridId],
            columns: [...columns]
          }
        });*/
        thisObj.setState(current => {
          return {
            ...current,
           [gridId]: {
                  ...thisObj.state[gridId],
                  columns: [...columns]
                }
          };
        });
      }
    }
  };

 export const showColumn = (thisObj,gridId, colId) => {
    let winObj = thisObj.state;
    if (!gridId || !winObj[gridId]) {
      throw new EvalError("Incorrect Grid Name '" + gridId + "'");
    }
    if (!colId || !winObj[colId]) {
      throw new EvalError("Incorrect Column Name '" + colId + "'");
    }

    let gridObj = winObj[gridId];
    if (gridObj.columns) {
      let columns = gridObj.columns;
      let column = columns.find(column => {
        return column.name === colId;
      });
      if (column) {
        column.Visible = true;
        /*thisObj.setState({
          gridId: {
            ...thisObj.state[gridId],
            columns: [...columns]
          }
        });*/
        thisObj.setState(current => {
          return {
            ...current,
           [gridId]: {
                  ...thisObj.state[gridId],
                  columns: [...columns]
                }
          };
        });
      }
    }
  };

 export const hideColumn = (thisObj,gridId, colId) => {
    let winObj = thisObj.state;
    if (!gridId || !winObj[gridId]) {
      throw new EvalError("Incorrect Grid Name '" + gridId + "'");
    }
    if (!colId || !winObj[colId]) {
      throw new EvalError("Incorrect Column Name '" + colId + "'");
    }

    let gridObj = winObj[gridId];
    if (gridObj.columns) {
      let columns = gridObj.columns;
      let column = columns.find(column => {
        return column.name === colId;
      });
      if (column) {
        column.Visible = false;
        /*thisObj.setState({
          gridId: {
            ...thisObj.state[gridId],
            columns: [...columns]
          }
        });*/
        thisObj.setState(current => {
          return {
            ...current,
           [gridId]: {
                  ...thisObj.state[gridId],
                  columns: [...columns]
                }
          };
        });
      }
    }
  };

 export const getRowCount = (thisObj,gridId) => {
    if (typeof gridId === "undefined" || gridId === "" || !thisObj.state[gridId]) {
      throw new EvalError(`Incorrect Grid Id`);
    }
    let gridObj = thisObj.values[gridId];
    let rowCount = gridObj.length;
    return rowCount;
  };

 export const gridRowStyle = (thisObj,gridId, rowClassFn) => {
    if (typeof gridId === "undefined" || gridId === "" || !thisObj.state[gridId]) {
      throw new EvalError(`Incorrect Grid Id`);
    }
    let gridObj = thisObj.state[gridId];
    gridObj.getRowClass = rowClassFn;
  };

 export const isGridColumnEnable = (thisObj,gridId, columnId) => {
    if (columnId === undefined || columnId === "") {
      throw new EvalError("Incorrect Column Name '" + columnId + "'");
    }
    if (thisObj.state[gridId].columns) {
      return thisObj.state[columnId].Enabled;
    } else {
      throw new EvalError("Incorrect Column Name '" + columnId + "'");
    }
  };

 export const editRow=(thisObj,gridId, rowNo)=> {
    let scrnObj = thisObj.state;
    if (typeof gridId === "undefined" || gridId === "" || !scrnObj[gridId]) {
      throw new EvalError(`Incorrect Grid Id`);
    }
    if (typeof rowNo === "undefined" || rowNo < 0) {
      throw new EvalError(`Incorrect Row Number`);
    }

    let rows = thisObj.values[gridId];

    if (rows && rows.length) {
      let row = rows[rowNo];
     /* thisObj.setState({
        [gridId]: {
          ...thisObj.state[gridId],
          selected: [row]
        }
      });*/
      thisObj.setState(current => {
        return {
          ...current,
         [gridId]: {
                ...thisObj.state[gridId],
                selected: [row]
              }
        };
      });
      let thisobjConf={conf:thisObj.state[gridId],
                        rows:rows
      };
      row.isSelected=true;
      row.rowID$=rowNo;
      thisobjConf.conf.selected=thisobjConf.conf.selected.length==0?[row]:thisobjConf.conf.selected;
      thisObj.state[gridId].actionList[1].clickEvent("",thisobjConf);
    } else {
      throw new EvalError(`Incorrect Row Number`);
    }
  }

 export const deleteGridRow = (thisObj,gridId, rowId) => {
    let scrnObj = thisObj.state;
    if (typeof gridId === "undefined" || gridId === "" || !scrnObj[gridId]) {
      throw new EvalError(`Incorrect Grid Id`);
    }
    if (typeof rowId === "undefined" || rowId < 0) {
      throw new EvalError(`Incorrect Row Number`);
    }
    if (thisObj.values[gridId]) {
      let row = thisObj.values[gridId][rowId];
     /* thisObj.setState({
        [gridId]: {
          ...thisObj.state[gridId],
          selected: [row]
        }
      });*/
      thisObj.setState(current => {
        return {
          ...current,
         [gridId]: {
                ...thisObj.state[gridId],
                selected: [row]
              }
        };
      });
      thisObj.state[gridId].actionList[2].clickEvent();
    } else {
      throw new EvalError(`Incorrect Row Number`);
    }
  };

 export const hideGridHeader = (thisObj,gridId) => {
    if (!thisObj.state.hasOwnProperty(gridId)) {
      throw new EvalError("Invalid Grid Id '" + gridId + "'");
    }
    if (thisObj.state[gridId]) {
      /*thisObj.setState({
        [gridId]: { ...thisObj.state[gridId], hideHeader: true }
      });*/
      thisObj.setState(current => {
        return {
          ...current,
         [gridId]: {
                ...thisObj.state[gridId],
                hideHeader: true
              }
        };
      });
    } else {
      throw new EvalError("Incorrect Grid Id '" + gridId + "'");
    }
  };

 export const showGridHeader = (thisObj,gridId) => {
    if (!thisObj.state.hasOwnProperty(gridId)) {
      throw new EvalError("Invalid Grid Id '" + gridId + "'");
    }
    if (thisObj.state[gridId]) {
      /*thisObj.setState({
        [gridId]: { ...thisObj.state[gridId], hideHeader: false }
      });*/
      thisObj.setState(current => {
        return {
          ...current,
         [gridId]: {
                ...thisObj.state[gridId],
                hideHeader: false
              }
        };
      });
    } else {
      throw new EvalError("Incorrect Grid Id '" + gridId + "'");
    }
  };

  export const readonlyGridColumn = (thisObj,gridId, colId, value) => {
    let winObj = thisObj.state;
      if (!gridId || !winObj[gridId]) {
        throw new EvalError("Incorrect Grid Name '" + gridId + "'");
      }
      if (!colId || !winObj[colId]) {
        throw new EvalError("Incorrect Column Name '" + colId + "'");
      }
  
      let gridObj = winObj[gridId];
      if (gridObj.columns) {
        let columns = gridObj.columns;
        let column = columns.find(column => {
          return column.name === colId;
        });
        if (column) {
          column.ReadOnly = value;
          /*thisObj.setState({
            gridId: {
              ...thisObj.state[gridId],
              columns: [...columns]
            }
          });*/
          thisObj.setState(current => {
            return {
              ...current,
             [gridId]: {
                    ...thisObj.state[gridId],
                    columns: [...columns]
                  }
            };
          });
        }
      }
    };

  export const getGridLastCell = (thisObj,gridId, columnId) => {
    if (thisObj.values[gridId] && thisObj.values[gridId].length) {
      let rowIndex = thisObj.values[gridId].length - 1;
      return thisObj.getGridValue(thisObj,gridId, columnId, rowIndex);
    } else {
      throw EvalError("Incorrect Grid Id");
    }
  };

 export const setGridLastCell = (thisObj,gridId, columnId, value) => {
    if (thisObj.values[gridId] && thisObj.values[gridId].length) {
      let rowIndex = thisObj.values[gridId].length - 1;
      thisObj.setGridValue(thisObj,gridId, columnId, rowIndex, value);
    } else {
      throw EvalError("Incorrect Grid Id");
    }
  };

 export const getSelectedRowNumber = (thisObj,gridId) => {
    var rowIndex;
    let scrnObj = thisObj.state;
    if (scrnObj && scrnObj[gridId]) {
      //let gridObj = scrnObj[gridId];
      if (scrnObj[gridId].selected) {
        let selectedRows = scrnObj[gridId].selected;
        let selectedRowIndexes = collect(selectedRows)
          .pluck("rowID$")
          .all();
        rowIndex = selectedRowIndexes[0];
      } else {
        throw new EvalError("No Row Selected");
      }
    } else {
      throw new EvalError("Incorrect Grid Id '" + gridId + "'");
    }
    return rowIndex;
  };

  export const getSelectedRowNumbers = (thisObj,gridId) => {
    var selectedRowIndexes = [];
    let scrnObj = thisObj.state;
    if (scrnObj && scrnObj[gridId]) {
      let gridObj = scrnObj[gridId];
      if (gridObj.selected && gridObj.selected.length) {
        let selectedRows = gridObj.selected;
        selectedRowIndexes = collect(selectedRows)
          .pluck("rowID$")
          .all();
      } else {
        throw new EvalError("No Row Selected");
      }
    } else {
      throw new EvalError("Incorrect Grid Id '" + gridId + "'");
    }
    return selectedRowIndexes;
  };

 export const configureService = (thisObj,
    widgetId,
    eventName,
    serviceName,
    inputMappingObject,
    outputMappingObject,
    serviceDetails
  ) => {
    let buttonServiceString = serviceName + "#" + eventName;
    if (!(widgetId in thisObj._buttonServices)) {
      thisObj._buttonServices[widgetId] = {
        DEFAULT: []
      };
    }
    thisObj._buttonServices[widgetId]["DEFAULT"].push(buttonServiceString);

    let descObj = {
      isSpringBoot: true,
      produces: "application/json",
      consumes: "application/json",
      versioningRequired: false,
      paginationRequired: false
    };

    descObj = Object.assign(descObj, serviceDetails);

    thisObj._winServices[serviceName] = {
      IN: inputMappingObject,
      OUT: outputMappingObject,
      DESC: descObj
    };
  };

  export const callOauthService = (thisObj,widgetId, usernameFldName, passwordFldName) => {
    _kaledo.compRoot.AuthRoot = `${_kaledo.AuthRoot}oauth/token`;

    let inObj = {
      inDetails: {
        "inDetails.username": usernameFldName,
        "inDetails.password": passwordFldName
      }
    };

    let desc = {
      ownerComp: "AuthRoot",
      consumes: "application/x-www-form-urlencoded",
      inDetails: "RequestBody",
      type: "POST"
    };

    configureService(thisObj,widgetId, "Click", "getAccessToken", inObj, {}, desc);
  };

  export const attachHotKeysHandler = (thisObj, key, handler) => { 
    hotkeys.unbind(key); 
    hotkeys(key, handler); 
    if (!thisObj.hotKeysMap) { 
      thisObj.hotKeysMap = new Map(); 
    } 
    thisObj.hotKeysMap.set(key, handler); 
  };

  export const detachHotKeysHandler = (key=undefined) => {
    if(key){
      hotkeys.unbind(key); 
    }
    else{
      hotkeys.unbind(); 
    }
  }
  
 export const hasSelectedRows = (thisObj,gridId) => {
    let rows = thisObj.values[gridId];
    return rows.some(row => row.opColumn && (row.opColumn === "S" || row.opColumn === "s"));											  
  };

  export const setSelectedRows = (thisObj,gridId) => {
    let rows = thisObj.values[gridId];
    rows = JSON.parse(JSON.stringify(rows));
    rows.forEach(row => {
      if (row.opColumn && (row.opColumn === "S" || row.opColumn === "s")) {
        row.isSelected = true;
      } else {
        row.isSelected = false;	
      }
    });
    setValue(thisObj,gridId, rows);
  };
  
  export const attachHomeKeyHandleForGrid = (thisObj,gridId) => {
	  hotkeys.unbind("home");
	  hotkeys("home", e => {
      e.preventDefault();
      let page = thisObj.state[gridId].page;
      let rows = thisObj.values[gridId];
      let start = 0;
      let end = thisObj.state[gridId].DisplaySize;
      let flag=-1;
      let firstObj = {...rows[0]};
      for (let key in firstObj) {
        firstObj[key] = "";
      }
      firstObj['isNewRow']=true;
      if (rows.length > page.size) {
        let totalElements = rows.length;
        start = (page.pageNumber-1) * page.size;
        end = Math.min(start + page.size, totalElements);
      }
      for (let i = start; i < end; i++) {
        const jsonObj = { ...rows[i] };
        if(JSON.stringify(jsonObj) === JSON.stringify(firstObj)){
          flag = i;
          break;
        }
      }
      for(let column of thisObj.state[gridId].columns){
        if(column['Enabled']){
          let ele;
          if (flag === -1) {
            ele = document.getElementById(`${gridId}.${start}.${column.name}`);
            if (ele) ele.focus();
          } else {
            ele = document.getElementById(`${gridId}.${flag}.${column.name}`);
            if (ele) ele.focus();
          }
          break;
        }
      }    
    });
  };

  export const getEventObj = (thisObj) => {
    let id = thisObj.eventInfo.target;
    let evntType = thisObj.eventInfo.type;
    let widgetObject = thisObj.state[id];
    let eventObj;
    if(widgetObject && widgetObject[evntType+ "Event"]){
      eventObj=widgetObject[evntType+ "Event"];
    }
    return eventObj;
  };

  export const navigateToNextPage=(thisObj,gridId,newPageRequired)=>{
    let defaultNewRow = {};
    let statusColName="";
    let widgetObj = thisObj.state[gridId];
      widgetObj.columns.forEach(col => {
      switch (col.type) {
        case "FileWidget":
          defaultNewRow[col.name] = [];
          break;
         default:
          defaultNewRow[col.name] = "";
      }
      if (col.isStatusColumn) {
        statusColName = col.name;
        }
      });
    let rows = thisObj.values[gridId];
      rows = JSON.parse(JSON.stringify(rows));
    let newGridObj = rows;
    var gridElemForPag=document.getElementsByClassName("sc-fzoXWK");
    for(var i=0;i<gridElemForPag.length;i++){
    if(gridElemForPag[i].id=="pagination-next-page" && gridElemForPag[i].closest('div.'+gridId)){
      var elemPag=gridElemForPag[i];
    if(newPageRequired===true && widgetObj.page.pageNumber === rows.length/widgetObj.page.size){
          for (let i = 0; i < widgetObj.DisplaySize; i++) {
            let newRow = JSON.parse(JSON.stringify(defaultNewRow));
            newRow.isNewRow = true;
            delete newRow[statusColName];
           newGridObj.push(defaultNewRow);
          }
      setValue(thisObj,gridId,newGridObj);
        setTimeout(() => {
          elemPag.click();
          });
    } else {
      elemPag.click();
      }
    }
    }
  };

 export const navigateToPreviousPage=(thisObj,gridId)=>{
    let defaultNewRow = {};
    let statusColName="";
    let widgetObj = thisObj.state[gridId];
    let rows = thisObj.values[gridId];
      rows = JSON.parse(JSON.stringify(rows));
    let newGridObj = rows;
    var gridElemForPag=document.getElementsByClassName("sc-fzoXWK");
    for(var i=0;i<gridElemForPag.length;i++){
    if(gridElemForPag[i].id=="pagination-previous-page" && gridElemForPag[i].closest('div.'+gridId)){
      var elemPag=gridElemForPag[i];
      elemPag.click();
      }
    }
  };
  
  export const setData = (thisObj,key, value, isRestrictedToModule,sessionStoreRequired) => {
	    let globalData = thisObj.context.data;
	    let activeGUIModuleName = _kaledo.guiModuleName;
	    if (globalData.hasOwnProperty("sharedData")) {
	      let sharedData = globalData.sharedData;
	      if (isRestrictedToModule) {
	        if (sharedData.hasOwnProperty(activeGUIModuleName)) {
	          let sharedDataUnderModule = sharedData[activeGUIModuleName];
	          sharedDataUnderModule[key] = value;
	        } else {
	          let sharedDataUnderModule = (sharedData[activeGUIModuleName] = {});
	          sharedDataUnderModule[key] = value;
	        }
	      } else {
	        sharedData[key] = value;
	      }
	    } else {
	      let sharedData = (globalData["sharedData"] = {});
	      if (isRestrictedToModule) {
	        let sharedDataUnderModule = (sharedData[activeGUIModuleName] = {});
	        sharedDataUnderModule[key] = value;
	      } else {
	        sharedData[key] = value;
	      }
	    }
		if(sessionStoreRequired){
			window.sessionStorage.setItem(key, value);
		}
	  };

	 export const clearSessionData = () => {
		window.sessionStorage.clear();
	  };

	  export const getData = (thisObj,key, isRestrictedToModule) => {
		var sessionData=window.sessionStorage.getItem(key);
	    let globalData = thisObj.context.data;
	    let activeGUIModuleName = _kaledo.guiModuleName;
	    if (!globalData.hasOwnProperty("sharedData")) {
			if(sessionData!= null){
				return sessionData
			}
	      return null;
	    }
	    let sharedData = globalData.sharedData;
	    if (isRestrictedToModule) {
	      if (sharedData.hasOwnProperty(activeGUIModuleName)) {
	        let sharedDataUnderModule = sharedData[activeGUIModuleName];
	        if (sharedDataUnderModule.hasOwnProperty(key)) {
	          return sharedDataUnderModule[key];
	        } else {
	          throw new EvalError(
	            "No data available for key '" +
	              key +
	              "' in module '" +
	              activeGUIModuleName +
	              "'"
	          );
	        }
	      } else {
	        throw new EvalError(
	          "No data available for key '" +
	            key +
	            "' in module '" +
	            activeGUIModuleName +
	            "'"
	        );
	      }
	    } else {
	      if (sharedData.hasOwnProperty(key)) {
	        return sharedData[key];
	      } else {
	        return null;
	      }
	    }
	  };

    export const setFieldValues = (thisObj, values, shouldValidate = false) => {
      if (thisObj.setValues) {
        thisObj.setValues((obj) => {
          return { ...obj, ...values };
        }, shouldValidate);
      }
    };

    export const updateVisibility = (
      thisObj,
      widgetArray,
      isVisible = true,
      port = "Visible"
    ) => {
      if (widgetArray && Array.isArray(widgetArray)) {
        widgetArray.map((widgetId) => {
          if (!thisObj.state[widgetId]) return "";
          // thisObj.state[widgetId][port] = isVisible;
          //////
          thisObj.setState((current) => {
            return {
              ...current,
              [widgetId]: {
                ...current[widgetId],
                [port]: isVisible,
              },
            };
          });
          ////
          return "";
        });
      } else {
        return;
      }
    };
    
    export const setVisiblityArray = (
      thisObj,
      widgetArray,
      viewPort,
      isVisible = true
    ) => {
      if (!viewPort) {
        updateVisibility(thisObj, widgetArray, isVisible, "Visible");
      } else if (viewPort === "TabLandscape") {
        updateVisibility(thisObj, widgetArray, isVisible, "VisibleForTabLandscape");
      } else if (viewPort === "TabPotrait") {
        updateVisibility(thisObj, widgetArray, isVisible, "VisibleForTabPotrait");
      } else if (viewPort === "Mobile") {
        updateVisibility(thisObj, widgetArray, isVisible, "VisibleForMobile");
      }
    };
    
    export const showWidgets = (thisObj, widgetArray, viewPort) => {
      if (widgetArray && Array.isArray(widgetArray)) {
        setVisiblityArray(thisObj, widgetArray, viewPort);
        return;
      }
    };
    
    export const hideWidgets = (thisObj, widgetArray, viewPort) => {
      if (widgetArray && Array.isArray(widgetArray)) {
        setVisiblityArray(thisObj, widgetArray, viewPort, false);
        return;
      }
    };  // showWidgets, hideWidgets
    export const enableWidgets = (thisObj, widgetArray, isEnable = true) => {
      if (widgetArray && Array.isArray(widgetArray)) {
        updateVisibility(thisObj, widgetArray, isEnable, "Enabled");
        return;
      }
    };
    //enableWidgets

    export const setGridDropDownOptionList = (thisObj, widgetId, value) => {
      thisObj.setState((current) => {
        return {
          ...current,
          [widgetId]: {
            ...current[widgetId],
            optionsList: value,
          },
        };
      });
    };

    export const setValueList=(thisObj, widgetId, value)=>{
      thisObj.setState(current => {
        return {
          ...current,
         [widgetId]: {
                ...current[widgetId],
                valueList: value
              }
        };
      });
    }
    export const setGridColList = (
      thisObj,
      gridId,
      colName,
      rowNo,
      valObj,
      refColName
    ) => {
      if (typeof gridId === "undefined" || gridId === "") {
        throw new EvalError(`Incorrect Grid Id`);
      }
    
      if (typeof colName === "undefined" || colName === "") {
        throw new EvalError(`Incorrect Column Name`);
      }
    
      if (typeof rowNo === "undefined" || rowNo < 0) {
        throw new EvalError(`Incorrect Row Numbr`);
      }
      if (
        thisObj.state &&
        thisObj.state[colName] &&
        valObj &&
        valObj.valueList &&
        Array.isArray(valObj.valueList)
      ) {
        thisObj.state[colName].valRowNo = rowNo;
        thisObj.state[colName].colValList = valObj.valueList;
        thisObj.state[colName].refColName = refColName;
        /*
thisObj.setState((current) => {
        return {
          ...current,{
          [widgetId]: {
            ...current[widgetId],
            test: value,
          },}
        };
      });
        */
        //thisObj.state[colName].valueList = valObj.valueList;
      }
    };
  