/* eslint-disable*/
import React from "react";
import WarehouseReceipts_DelvSettlePaymentView from "./DelvSettlePaymentView";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("DelvSettlePaymentView Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvSettlePaymentView />);
    });
  });
  afterEach(cleanup);
  test("is DelvSettlePaymentView Loads Successfully", () => {
    expect(screen.getByText("DelvSettlePaymentView")).toBeInTheDocument;
  });
  test("Custom Test Cases for DelvSettlePaymentView", () => {
    // START_USER_CODE-USER_DelvSettlePaymentView_Custom_Test_Case
    // END_USER_CODE-USER_DelvSettlePaymentView_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_DelvSettlePaymentView />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:DelvSettlePaymentView_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("WarehouseReceipts:DelvSettlePaymentView_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("WarehouseReceipts:DelvSettlePaymentView_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("gridPayments(Grid Widget) Test Cases", async () => {
    let gridPayments = screen.getByTestId("gridPayments");
    let gridPaymentsbtn = gridPayments.nextElementSibling.firstElementChild;
    gridPayments = gridPayments.parentElement.parentElement.parentElement;
    expect(gridPayments.tagName).toBe("DIV");
    expect(gridPayments.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettlePaymentView_gridPayments")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridPayments", () => {
    // START_USER_CODE-USER_gridPayments_TEST
    // END_USER_CODE-USER_gridPayments_TEST
  });
  test("grpbxDelvSettlePaymentView(GroupBox Widget) Test Cases", async () => {
    const grpbxDelvSettlePaymentView = screen.getByTestId(
      "grpbxDelvSettlePaymentView"
    );
    expect(grpbxDelvSettlePaymentView.tagName).toBe("BUTTON");
    expect(grpbxDelvSettlePaymentView.type).toBe("button");
    expect(grpbxDelvSettlePaymentView.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDelvSettlePaymentView", () => {
    // START_USER_CODE-USER_grpbxDelvSettlePaymentView_TEST
    // END_USER_CODE-USER_grpbxDelvSettlePaymentView_TEST
  });
  test("lblPayments(Label Widget) Test Cases", async () => {
    const lblPayments = screen.getByTestId("lblPayments");
    expect(lblPayments.tagName).toBe("LABEL");
    expect(lblPayments.classList).toContain("form-label");
    expect(lblPayments.textContent).toEqual(
      t("WarehouseReceipts:DelvSettlePaymentView_lblPayments")
    );
  });
  test("Custom Test Cases for lblPayments", () => {
    // START_USER_CODE-USER_lblPayments_TEST
    // END_USER_CODE-USER_lblPayments_TEST
  });
  test("gridPayments_txtcolCheckNum(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolCheckNum = screen.getByTestId("gridPayments");
    let gridPayments_txtcolCheckNumbtn =
      gridPayments_txtcolCheckNum.nextElementSibling.firstElementChild;
    gridPayments_txtcolCheckNum =
      gridPayments_txtcolCheckNum.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolCheckNum.tagName).toBe("DIV");
    expect(gridPayments_txtcolCheckNum.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettlePaymentView_gridPayments")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCheckNum", () => {
    // START_USER_CODE-USER_txtcolCheckNum_TEST
    // END_USER_CODE-USER_txtcolCheckNum_TEST
  });
  test("gridPayments_txtcolClearedDate(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolClearedDate = screen.getByTestId("gridPayments");
    let gridPayments_txtcolClearedDatebtn =
      gridPayments_txtcolClearedDate.nextElementSibling.firstElementChild;
    gridPayments_txtcolClearedDate =
      gridPayments_txtcolClearedDate.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolClearedDate.tagName).toBe("DIV");
    expect(gridPayments_txtcolClearedDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettlePaymentView_gridPayments")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolClearedDate", () => {
    // START_USER_CODE-USER_txtcolClearedDate_TEST
    // END_USER_CODE-USER_txtcolClearedDate_TEST
  });
  test("gridPayments_txtcolIntrestAgreement(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolIntrestAgreement = screen.getByTestId(
      "gridPayments"
    );
    let gridPayments_txtcolIntrestAgreementbtn =
      gridPayments_txtcolIntrestAgreement.nextElementSibling.firstElementChild;
    gridPayments_txtcolIntrestAgreement =
      gridPayments_txtcolIntrestAgreement.parentElement.parentElement
        .parentElement;
    expect(gridPayments_txtcolIntrestAgreement.tagName).toBe("DIV");
    expect(gridPayments_txtcolIntrestAgreement.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettlePaymentView_gridPayments")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolIntrestAgreement", () => {
    // START_USER_CODE-USER_txtcolIntrestAgreement_TEST
    // END_USER_CODE-USER_txtcolIntrestAgreement_TEST
  });
  test("gridPayments_txtcolIssuedDate(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolIssuedDate = screen.getByTestId("gridPayments");
    let gridPayments_txtcolIssuedDatebtn =
      gridPayments_txtcolIssuedDate.nextElementSibling.firstElementChild;
    gridPayments_txtcolIssuedDate =
      gridPayments_txtcolIssuedDate.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolIssuedDate.tagName).toBe("DIV");
    expect(gridPayments_txtcolIssuedDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettlePaymentView_gridPayments")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolIssuedDate", () => {
    // START_USER_CODE-USER_txtcolIssuedDate_TEST
    // END_USER_CODE-USER_txtcolIssuedDate_TEST
  });
  test("gridPayments_txtcolProceeds(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolProceeds = screen.getByTestId("gridPayments");
    let gridPayments_txtcolProceedsbtn =
      gridPayments_txtcolProceeds.nextElementSibling.firstElementChild;
    gridPayments_txtcolProceeds =
      gridPayments_txtcolProceeds.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolProceeds.tagName).toBe("DIV");
    expect(gridPayments_txtcolProceeds.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettlePaymentView_gridPayments")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolProceeds", () => {
    // START_USER_CODE-USER_txtcolProceeds_TEST
    // END_USER_CODE-USER_txtcolProceeds_TEST
  });
  test("gridPayments_txtcolSpecialType(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolSpecialType = screen.getByTestId("gridPayments");
    let gridPayments_txtcolSpecialTypebtn =
      gridPayments_txtcolSpecialType.nextElementSibling.firstElementChild;
    gridPayments_txtcolSpecialType =
      gridPayments_txtcolSpecialType.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolSpecialType.tagName).toBe("DIV");
    expect(gridPayments_txtcolSpecialType.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettlePaymentView_gridPayments")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSpecialType", () => {
    // START_USER_CODE-USER_txtcolSpecialType_TEST
    // END_USER_CODE-USER_txtcolSpecialType_TEST
  });
  test("gridPayments_txtcolStatus(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolStatus = screen.getByTestId("gridPayments");
    let gridPayments_txtcolStatusbtn =
      gridPayments_txtcolStatus.nextElementSibling.firstElementChild;
    gridPayments_txtcolStatus =
      gridPayments_txtcolStatus.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolStatus.tagName).toBe("DIV");
    expect(gridPayments_txtcolStatus.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettlePaymentView_gridPayments")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolStatus", () => {
    // START_USER_CODE-USER_txtcolStatus_TEST
    // END_USER_CODE-USER_txtcolStatus_TEST
  });
  test("gridPayments_txtcolVendor(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolVendor = screen.getByTestId("gridPayments");
    let gridPayments_txtcolVendorbtn =
      gridPayments_txtcolVendor.nextElementSibling.firstElementChild;
    gridPayments_txtcolVendor =
      gridPayments_txtcolVendor.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolVendor.tagName).toBe("DIV");
    expect(gridPayments_txtcolVendor.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettlePaymentView_gridPayments")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVendor", () => {
    // START_USER_CODE-USER_txtcolVendor_TEST
    // END_USER_CODE-USER_txtcolVendor_TEST
  });
  test("gridPayments_txtcolVoidDate(Grid Widget) Test Cases", async () => {
    let gridPayments_txtcolVoidDate = screen.getByTestId("gridPayments");
    let gridPayments_txtcolVoidDatebtn =
      gridPayments_txtcolVoidDate.nextElementSibling.firstElementChild;
    gridPayments_txtcolVoidDate =
      gridPayments_txtcolVoidDate.parentElement.parentElement.parentElement;
    expect(gridPayments_txtcolVoidDate.tagName).toBe("DIV");
    expect(gridPayments_txtcolVoidDate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:DelvSettlePaymentView_gridPayments")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolVoidDate", () => {
    // START_USER_CODE-USER_txtcolVoidDate_TEST
    // END_USER_CODE-USER_txtcolVoidDate_TEST
  });
});
