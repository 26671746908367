/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  setData,
  getData,
  setValue,
  getValue,
  enable,
  disable
} from "../../shared/WindowImports";

import "./SecurityFunctionInquiry.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_SecurityFunctionInquiry(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SecurityFunctionInquiry",
    windowName: "SecurityFunctionInquiry",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.SecurityFunctionInquiry",
    // START_USER_CODE-USER_SecurityFunctionInquiry_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Security Function Inquiry",
      scrCode: "PN0060B",
    },
    // END_USER_CODE-USER_SecurityFunctionInquiry_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    chkboxExtendAreaLevel: {
      name: "chkboxExtendAreaLevel",
      type: "CheckBoxWidget",
      parent: "grpbxSecurityFunctionInquiry",
      Label:
        "Extend Area Level user's to have access to all Loc within the same:",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxExtendAreaLevel_PROPERTIES

      // END_USER_CODE-USER_chkboxExtendAreaLevel_PROPERTIES
    },
    chkboxExtendBuyPtLevel: {
      name: "chkboxExtendBuyPtLevel",
      type: "CheckBoxWidget",
      parent: "grpbxSecurityFunctionInquiry",
      Label:
        "Extend Buy Pt Level user's to have access to all loc within the same:",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxExtendBuyPtLevel_PROPERTIES

      // END_USER_CODE-USER_chkboxExtendBuyPtLevel_PROPERTIES
    },
    chkboxExtendCollPtLevel: {
      name: "chkboxExtendCollPtLevel",
      type: "CheckBoxWidget",
      parent: "grpbxSecurityFunctionInquiry",
      Label:
        "Extend Coll Pt Level user's to have access to all loc within the same:",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxExtendCollPtLevel_PROPERTIES

      // END_USER_CODE-USER_chkboxExtendCollPtLevel_PROPERTIES
    },
    ddAccessLevel: {
      name: "ddAccessLevel",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityFunctionInquiry",
      Label: "Access Level:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAccessLevel_PROPERTIES

      // END_USER_CODE-USER_ddAccessLevel_PROPERTIES
    },
    ddExtendAreaLevel: {
      name: "ddExtendAreaLevel",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityFunctionInquiry",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddExtendAreaLevel_PROPERTIES

      // END_USER_CODE-USER_ddExtendAreaLevel_PROPERTIES
    },
    ddExtendBuyPtLevel: {
      name: "ddExtendBuyPtLevel",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityFunctionInquiry",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddExtendBuyPtLevel_PROPERTIES

      // END_USER_CODE-USER_ddExtendBuyPtLevel_PROPERTIES
    },
    ddExtendCollPtLevel: {
      name: "ddExtendCollPtLevel",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityFunctionInquiry",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddExtendCollPtLevel_PROPERTIES

      // END_USER_CODE-USER_ddExtendCollPtLevel_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtFunction: {
      name: "txtFunction",
      type: "TextBoxWidget",
      parent: "grpbxSecurityFunctionInquiry",
      Label: "Function:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFunction_PROPERTIES

      // END_USER_CODE-USER_txtFunction_PROPERTIES
    },
    txtFunctionDiscription: {
      name: "txtFunctionDiscription",
      type: "TextBoxWidget",
      parent: "grpbxSecurityFunctionInquiry",
      Label: "Function Description:",
      LengthRange: { MinLength: 0, MaxLength: 100 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFunctionDiscription_PROPERTIES

      // END_USER_CODE-USER_txtFunctionDiscription_PROPERTIES
    },
    txtSubFunction: {
      name: "txtSubFunction",
      type: "TextBoxWidget",
      parent: "grpbxSecurityFunctionInquiry",
      Label: "Sub-Function:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSubFunction_PROPERTIES

      // END_USER_CODE-USER_txtSubFunction_PROPERTIES
    },
    grpbxSecurityFunctionInquiry: {
      name: "grpbxSecurityFunctionInquiry",
      type: "GroupBoxWidget",
      parent: "SecurityFunctionInquiry",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxSecurityFunctionInquiry_PROPERTIES

      // END_USER_CODE-USER_grpbxSecurityFunctionInquiry_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "SecurityFunctionInquiry",
      Height: "",
      Width: "",
      clonedExtId: "44193",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };

  let sExtendBP = "";
  let sExtendCP = "";
  let sExtendAR = ""

  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    Form_Load()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const Form_Load = async () => {
    await bFillAccessLevelList()

    let BuyPtLevel = getValue(thisObj, "chkboxExtendBuyPtLevel");
    if (BuyPtLevel == false) {
      disable(thisObj, 'ddExtendBuyPtLevel')
    }
    if (BuyPtLevel == true) {
      await onchkboxExtendBuyPtLevelChange({ target : {
        value : true
      }})
      enable(thisObj, 'ddExtendBuyPtLevel')
    }

    let CollPtLevel = getValue(thisObj, "chkboxExtendCollPtLevel");
    if (CollPtLevel == false) {
      disable(thisObj, 'ddExtendCollPtLevel')
    }
    if (CollPtLevel == true) {
      await onchkboxExtendCollPtLevelChange({ target : {
        value : true
      }})
      enable(thisObj, 'ddExtendCollPtLevel')
    }

    let AreaLevel = getValue(thisObj, "chkboxExtendAreaLevel");
    if (AreaLevel == false) {
      disable(thisObj, 'ddExtendAreaLevel')
    }
    if (AreaLevel == true) {
      await onchkboxExtendAreaLevelChange({ target : {
        value : true
      }})
      enable(thisObj, 'ddExtendAreaLevel')
    }
    
    let frmSecurityMenuArrey = getData(thisObj, 'dataSendingtoSecurityFunctionInquiry');
    if (frmSecurityMenuArrey != '' && frmSecurityMenuArrey != undefined && frmSecurityMenuArrey != null) {
      let frmSecurityMenu = frmSecurityMenuArrey[0]
      let cmdOk = frmSecurityMenu.cmdOkCaption;
      if (cmdOk == "Add") {
        document.getElementById('btnAdd').innerText = cmdOk

        let js = [{
          key : "",
          description : ""
        }]
        thisObj.setState(current => {
          return {
            ...current,
            ddExtendBuyPtLevel: {
              ...state["ddExtendBuyPtLevel"],
              valueList: js
            },
          }
        });
        setValue(thisObj, "ddExtendBuyPtLevel", js[0].key)

        thisObj.setState(current => {
          return {
            ...current,
            ddExtendAreaLevel: {
              ...state["ddExtendAreaLevel"],
              valueList: js
            },
          }
        });
        setValue(thisObj, "ddExtendAreaLevel", js[0].key)

        thisObj.setState(current => {
          return {
            ...current,
            ddExtendCollPtLevel: {
              ...state["ddExtendCollPtLevel"],
              valueList: js
            },
          }
        });
        setValue(thisObj, "ddExtendCollPtLevel", js[0].key)

        disable(thisObj, "ddExtendAreaLevel")
        disable(thisObj, "ddExtendCollPtLevel")
        disable(thisObj, "ddExtendBuyPtLevel")
      }
    }

    let frmSecurityMenuArrey1 = getData(thisObj, 'dataSendingtoSecurityFunctionInquiry1');
    if (frmSecurityMenuArrey1 != '' && frmSecurityMenuArrey1 != undefined && frmSecurityMenuArrey1 != null) {
      let frmSecurityMenu1 = frmSecurityMenuArrey1[0]
      let cmdOk = frmSecurityMenu1.cmdOkCaption;
      let addedBy = frmSecurityMenu1.lblAddedByCaption;
      let chgedBy = frmSecurityMenu1.lblChangedByCaption;
      let txtDescriptionText = frmSecurityMenu1.txtDescriptionText
      if (cmdOk == 'Update') {

        document.getElementById("txtFunctionDiscription").focus();
      
        setValue(thisObj, "lblAddedByValue", addedBy)
        setValue(thisObj, "lblChangedByValue", chgedBy)
        setValue(thisObj, "txtFunction", frmSecurityMenu1.txtFunctionText);
        setValue(thisObj, "txtFunctionDiscription", txtDescriptionText);
        setValue(thisObj, "txtSubFunction", frmSecurityMenu1.txtSubFunctionText);
        setValue(thisObj, "ddAccessLevel", frmSecurityMenu1.cboAccessLevelText);
        setValue(thisObj, "chkboxExtendAreaLevel", frmSecurityMenu1.chkExtendARValue);
        setValue(thisObj, "chkboxExtendCollPtLevel", frmSecurityMenu1.chkExtendCPValue);
        setValue(thisObj, "chkboxExtendBuyPtLevel", frmSecurityMenu1.chkExtendBPValue);

        let js = [{
          key : "",
          description : ""
        }]
        if (frmSecurityMenu1.chkExtendARValue == true) {
          await onchkboxExtendAreaLevelChange({ target : {
            value : true
          }})
          enable(thisObj, 'ddExtendAreaLevel')
        }
        else {
          thisObj.setState(current => {
            return {
              ...current,
              ddExtendAreaLevel: {
                ...state["ddExtendAreaLevel"],
                valueList: js
              },
            }
          });
          setValue(thisObj, "ddExtendAreaLevel", js[0].key)
          disable(thisObj, "ddExtendAreaLevel")
        }
        
        if (frmSecurityMenu1.chkExtendCPValue == true) {
          await onchkboxExtendCollPtLevelChange({ target : {
            value : true
          }})
          enable(thisObj, 'ddExtendCollPtLevel')
        }
        else {
          thisObj.setState(current => {
            return {
              ...current,
              ddExtendCollPtLevel: {
                ...state["ddExtendCollPtLevel"],
                valueList: js
              },
            }
          });
          setValue(thisObj, "ddExtendCollPtLevel", js[0].key)
          disable(thisObj, "ddExtendCollPtLevel")
        }
        
        if (frmSecurityMenu1.chkExtendBPValue == true) {
          await onchkboxExtendBuyPtLevelChange({ target : {
            value : true
          }})
          enable(thisObj, 'ddExtendBuyPtLevel')
        }
        else {
          thisObj.setState(current => {
            return {
              ...current,
              ddExtendBuyPtLevel: {
                ...state["ddExtendBuyPtLevel"],
                valueList: js
              },
            }
          });
          setValue(thisObj, "ddExtendBuyPtLevel", js[0].key)
          disable(thisObj, "ddExtendBuyPtLevel")
        }
        disable(thisObj, 'txtFunction')
        disable(thisObj, 'txtSubFunction')
        document.getElementById('btnAdd').innerText = cmdOk
      }
    }
    setData(thisObj, 'dataSendingtoSecurityFunctionInquiry', null);
    setData(thisObj, 'dataSendingtoSecurityFunctionInquiry1', null);
  }

  // Access Level Dropdown
  async function bFillAccessLevelList() {
    try {
      let js = []
      js.push({ key: 'Inquiry', description: 'Inquiry' })
      js.push({ key: 'Update', description: 'Update' })
      js.push({ key: 'Delete', description: 'Delete' })
      js.push({ key: 'Revoke', description: 'Revoke' })

      thisObj.setState(current => {
        return {
          ...current,
          ddAccessLevel: {
            ...state["ddAccessLevel"],
            valueList: js
          },
        }
      });
      setValue(thisObj, "ddAccessLevel", js[0].key)
      return true;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      return false;
    }
  }

  // collection point Dropdown
  async function bFillCollPtLists() {
    try {
      let ddExtendCollPtLeveljs = [
        { key: 'AR', description: 'Area' },
        { key: 'CO', description: 'Company' }
      ]

      thisObj.setState(current => {
        return {
          ...current,
          ddExtendCollPtLevel: {
            ...state["ddExtendCollPtLevel"],
            valueList: ddExtendCollPtLeveljs
          },
        }
      });
      setValue(thisObj, "ddExtendCollPtLevel", ddExtendCollPtLeveljs[0].key)
    }
    catch (err) {

    }
  }
    // Area Dropdown
  async function bFillAreaLists() {
    try {
      let ddExtendAreaLeveljs = [{ 
        key: 'CO', description: 'Company' 
      }]

      thisObj.setState(current => {
        return {
          ...current,
          ddExtendAreaLevel: {
            ...state["ddExtendAreaLevel"],
            valueList: ddExtendAreaLeveljs
          },
        }
      });
      setValue(thisObj, "ddExtendAreaLevel", ddExtendAreaLeveljs[0].key)
    }
    catch (err) {

    }
  }
  // Buying points Dropdown
  async function bFillExtendLists() {
    try {
      let ddExtendBuyPtLeveljs = []
      ddExtendBuyPtLeveljs.push({ key: 'CP', description: 'Collection Point' })
      ddExtendBuyPtLeveljs.push({ key: 'AR', description: 'Area' })
      ddExtendBuyPtLeveljs.push({ key: 'CO', description: 'Company' })
      thisObj.setState(current => {
        return {
          ...current,
          ddExtendBuyPtLevel: {
            ...state["ddExtendBuyPtLevel"],
            valueList: ddExtendBuyPtLeveljs
          },
        }
      });
      setValue(thisObj, "ddExtendBuyPtLevel", ddExtendBuyPtLeveljs[0].key)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in fetching location list.");
      }
      return false;
    }
  }
  // Enable Disable Fuctionality
  function EnableDisableCollections(name, flag) {
    try {
      if(name == "area"){
        if(flag){
          enable(thisObj, "ddExtendAreaLevel")
        }
        else{
          disable(thisObj, "ddExtendAreaLevel")
        }
      }

      if(name == "coll"){
        if(flag){
          enable(thisObj, "ddExtendCollPtLevel")
        }
        else{
          disable(thisObj, "ddExtendCollPtLevel")
        }
      }

      if(name == "buying"){
        if(flag){
          enable(thisObj, "ddExtendBuyPtLevel")
        }
        else{
          disable(thisObj, "ddExtendBuyPtLevel")
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred EnableDisable Function");
      }
      return false;
    }
  };
  // Check box AreaLevel
  const onchkboxExtendAreaLevelChange = async (event) => {
    if (event?.target?.value) {
      await bFillAreaLists()
    } else {
      setValue(thisObj, "ddExtendAreaLevel", "")
      thisObj.setState(current => {
        return {
          ...current,
          ddExtendAreaLevel: {
            ...state["ddExtendAreaLevel"],
            valueList: [{
              key: "",
              description: ""
            }]
          },
        }
      });
      thisObj.values.ddExtendAreaLevel = ''
    }
    EnableDisableCollections("area", event?.target?.value)
  }
  thisObj.onchkboxExtendAreaLevelChange = onchkboxExtendAreaLevelChange;
  // check box Collection points
  const onchkboxExtendCollPtLevelChange = async (event) => {
    if (event?.target?.value) {
      await bFillCollPtLists()
    }
    else {
      setValue(thisObj, "ddExtendCollPtLevel", "")
        thisObj.setState(current => {
          return {
            ...current,
            ddExtendCollPtLevel: {
              ...state["ddExtendCollPtLevel"],
              valueList: [{
                key: "",
                description: ""
              }]
            },
          }
        });
        thisObj.values.ddExtendCollPtLevel = ''
    }
    EnableDisableCollections("coll", event?.target?.value)
  }
  thisObj.onchkboxExtendCollPtLevelChange = onchkboxExtendCollPtLevelChange;
  // check box Buying points
  const onchkboxExtendBuyPtLevelChange = async (event) => {
    try {
      if (event?.target?.value) {
        await bFillExtendLists()
      }
      else {
        setValue(thisObj, "ddExtendBuyPtLevel", "")
        thisObj.setState(current => {
          return {
            ...current,
            ddExtendBuyPtLevel: {
              ...state["ddExtendBuyPtLevel"],
              valueList: [{
                key: "",
                description: ""
              }]
            },
          }
        });
        thisObj.values.ddExtendBuyPtLevel = ''
      }
      EnableDisableCollections("buying", event?.target?.value);
    }
    catch (err) {
    }

  }
  thisObj.onchkboxExtendBuyPtLevelChange = onchkboxExtendBuyPtLevelChange;
  
  let userid = sessionStorage.getItem("userid");
  // Add button 
  const onbtnAddClick = async () => {
    try {
      let lstrxml = [];
      let access_level = getValue(thisObj, "ddAccessLevel")
      let sAccessLevel = "";
      switch (access_level.trim()) {
        case "Inquiry":
          sAccessLevel = "I";
          break;
        case "Update":
          sAccessLevel = "U";
          break;
        case "Delete":
          sAccessLevel = "D";
          break;
        case "Revoke":
          sAccessLevel = "X";
          break;
        default:
          break;
      }
      let extend_ar_auth_level_to = getValue(thisObj, "ddExtendAreaLevel")
      let extend_bp_auth_level_to = getValue(thisObj, "ddExtendBuyPtLevel")
      let extend_cp_auth_level_to = getValue(thisObj, "ddExtendCollPtLevel")
      let func_id = getValue(thisObj, 'txtFunction')
      let func_name = getValue(thisObj, 'txtFunctionDiscription')
      let func_sub_id = getValue(thisObj, 'txtSubFunction')
      if (func_sub_id == undefined || func_sub_id == null) {
        func_sub_id = ''
      }
      let data = {
        "func_id": func_id,
        "func_name": func_name,
        "access_level": sAccessLevel,
        "func_sub_id": func_sub_id,
        "extend_bp_auth_level_to": extend_bp_auth_level_to,
        "extend_cp_auth_level_to": extend_cp_auth_level_to,
        "extend_ar_auth_level_to": extend_ar_auth_level_to,
        "user_id": userid,
      }
      if (document.getElementById("btnAdd").innerText == 'Add') {
        lstrxml = await SystemMaintenanceSpecialFunctionsService.CreateFunctionControl(data)
        if (lstrxml?.status == 200) {
          setData(thisObj, "dataSendingtoSecurityFunctionInquiry", '')
          let obj = {};
          obj.discription = func_id + "." + func_sub_id + " (" + sAccessLevel + ")" + "- " + func_name
          obj.func_id = func_id
          obj.func_sub_id = func_sub_id
          obj.sAccessLevel = sAccessLevel
          obj.func_name = func_name
          obj.condition = "Add"
          obj.screen = "FunctionInquiry"
          setData(thisObj, "DatafromChildScreensToSecurityMenu", obj);
          document.getElementById("SystemMaintenanceSpecialFunctions_SecurityFunctionInquiryPopUp").childNodes[0].click();
          return true;
        }
        else {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\nError: \n\n" + lstrxml?.message?.split('Error Meesage :')[1])
          return false;
        }
      }
      else {
        let data = {
          "func_name": func_name,
          "access_level": sAccessLevel,
          "extend_bp_auth_level_to": extend_bp_auth_level_to,
          "extend_cp_auth_level_to": extend_cp_auth_level_to,
          "extend_ar_auth_level_to": extend_ar_auth_level_to,
          "user_id": userid,
        }
        lstrxml = await SystemMaintenanceSpecialFunctionsService.UpdateFunctionControl(func_id, func_sub_id, data)
        if (lstrxml?.status == 200) {
          setData(thisObj, "dataSendingtoSecurityFunctionInquiry1", '')
          let obj = {};
          obj.discription = func_id + "." + func_sub_id + " (" + sAccessLevel + ")" + "- " + func_name
          obj.func_id = func_id
          obj.func_sub_id = func_sub_id
          obj.sAccessLevel = sAccessLevel
          obj.func_name = func_name
          obj.condition = "Update"
          obj.screen = "FunctionInquiry"
          setData(thisObj, "DatafromChildScreensToSecurityMenu", obj);
          document.getElementById("SystemMaintenanceSpecialFunctions_SecurityFunctionInquiryPopUp").childNodes[0].click();
          return true;
        }
        else {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\nError: \n\n" + lstrxml?.message?.split('Error Meesage :')[1])
          return false;
        }
      }
    } catch (err) {
      showMessage(thisObj, err.message);
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick;
  // cancel button
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_SecurityFunctionInquiryPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SecurityFunctionInquiry*/}

              {/* END_USER_CODE-USER_BEFORE_SecurityFunctionInquiry*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSecurityFunctionInquiry*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSecurityFunctionInquiry*/}

              <GroupBoxWidget
                conf={state.grpbxSecurityFunctionInquiry}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtFunction*/}

                {/* END_USER_CODE-USER_BEFORE_txtFunction*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFunction}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFunction*/}

                {/* END_USER_CODE-USER_AFTER_txtFunction*/}
                {/* START_USER_CODE-USER_BEFORE_txtSubFunction*/}

                {/* END_USER_CODE-USER_BEFORE_txtSubFunction*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSubFunction}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSubFunction*/}

                {/* END_USER_CODE-USER_AFTER_txtSubFunction*/}
                {/* START_USER_CODE-USER_BEFORE_txtFunctionDiscription*/}

                {/* END_USER_CODE-USER_BEFORE_txtFunctionDiscription*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFunctionDiscription}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFunctionDiscription*/}

                {/* END_USER_CODE-USER_AFTER_txtFunctionDiscription*/}
                {/* START_USER_CODE-USER_BEFORE_ddAccessLevel*/}

                {/* END_USER_CODE-USER_BEFORE_ddAccessLevel*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAccessLevel}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAccessLevel*/}

                {/* END_USER_CODE-USER_AFTER_ddAccessLevel*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxExtendBuyPtLevel*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxExtendBuyPtLevel*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxExtendBuyPtLevel}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxExtendBuyPtLevel*/}

                {/* END_USER_CODE-USER_AFTER_chkboxExtendBuyPtLevel*/}
                {/* START_USER_CODE-USER_BEFORE_ddExtendBuyPtLevel*/}

                {/* END_USER_CODE-USER_BEFORE_ddExtendBuyPtLevel*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddExtendBuyPtLevel}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddExtendBuyPtLevel*/}

                {/* END_USER_CODE-USER_AFTER_ddExtendBuyPtLevel*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxExtendCollPtLevel*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxExtendCollPtLevel*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxExtendCollPtLevel}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxExtendCollPtLevel*/}

                {/* END_USER_CODE-USER_AFTER_chkboxExtendCollPtLevel*/}
                {/* START_USER_CODE-USER_BEFORE_ddExtendCollPtLevel*/}

                {/* END_USER_CODE-USER_BEFORE_ddExtendCollPtLevel*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddExtendCollPtLevel}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddExtendCollPtLevel*/}

                {/* END_USER_CODE-USER_AFTER_ddExtendCollPtLevel*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxExtendAreaLevel*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxExtendAreaLevel*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxExtendAreaLevel}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxExtendAreaLevel*/}

                {/* END_USER_CODE-USER_AFTER_chkboxExtendAreaLevel*/}
                {/* START_USER_CODE-USER_BEFORE_ddExtendAreaLevel*/}

                {/* END_USER_CODE-USER_BEFORE_ddExtendAreaLevel*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddExtendAreaLevel}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddExtendAreaLevel*/}

                {/* END_USER_CODE-USER_AFTER_ddExtendAreaLevel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSecurityFunctionInquiry*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSecurityFunctionInquiry*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_SecurityFunctionInquiry*/}

              {/* END_USER_CODE-USER_AFTER_SecurityFunctionInquiry*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_SecurityFunctionInquiry
);
