/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_BuyingPointProfile from "./BuyingPointProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("BuyingPointProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_BuyingPointProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is BuyingPointProfile Loads Successfully", () => {
    expect(screen.getByText("BuyingPointProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for BuyingPointProfile", () => {
    // START_USER_CODE-USER_BuyingPointProfile_Custom_Test_Case
    // END_USER_CODE-USER_BuyingPointProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_BuyingPointProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnMakeInactive(Button Widget) Test Cases", async () => {
    const btnMakeInactive = screen.getByTestId("btnMakeInactive");
    expect(btnMakeInactive).toBeInTheDocument;
    expect(btnMakeInactive.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_btnMakeInactive")
    );
  });
  test("Custom Test Cases for btnMakeInactive", () => {
    // START_USER_CODE-USER_btnMakeInactive_TEST
    // END_USER_CODE-USER_btnMakeInactive_TEST
  });
  test("btnOk(Button Widget) Test Cases", async () => {
    const btnOk = screen.getByTestId("btnOk");
    expect(btnOk).toBeInTheDocument;
    expect(btnOk.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_btnOk")
    );
  });
  test("Custom Test Cases for btnOk", () => {
    // START_USER_CODE-USER_btnOk_TEST
    // END_USER_CODE-USER_btnOk_TEST
  });
  test("btnPrintScreen(Button Widget) Test Cases", async () => {
    const btnPrintScreen = screen.getByTestId("btnPrintScreen");
    expect(btnPrintScreen).toBeInTheDocument;
    expect(btnPrintScreen.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_btnPrintScreen")
    );
  });
  test("Custom Test Cases for btnPrintScreen", () => {
    // START_USER_CODE-USER_btnPrintScreen_TEST
    // END_USER_CODE-USER_btnPrintScreen_TEST
  });
  test("btnVendorCCV(Button Widget) Test Cases", async () => {
    const btnVendorCCV = screen.getByTestId("btnVendorCCV");
    expect(btnVendorCCV).toBeInTheDocument;
    expect(btnVendorCCV.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_btnVendorCCV")
    );
  });
  test("Custom Test Cases for btnVendorCCV", () => {
    // START_USER_CODE-USER_btnVendorCCV_TEST
    // END_USER_CODE-USER_btnVendorCCV_TEST
  });
  test("btnVendorDCV(Button Widget) Test Cases", async () => {
    const btnVendorDCV = screen.getByTestId("btnVendorDCV");
    expect(btnVendorDCV).toBeInTheDocument;
    expect(btnVendorDCV.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_btnVendorDCV")
    );
  });
  test("Custom Test Cases for btnVendorDCV", () => {
    // START_USER_CODE-USER_btnVendorDCV_TEST
    // END_USER_CODE-USER_btnVendorDCV_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxBuyingPoint(GroupBox Widget) Test Cases", async () => {
    const grpbxBuyingPoint = screen.getByTestId("grpbxBuyingPoint");
    expect(grpbxBuyingPoint.tagName).toBe("BUTTON");
    expect(grpbxBuyingPoint.type).toBe("button");
    expect(grpbxBuyingPoint.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBuyingPoint", () => {
    // START_USER_CODE-USER_grpbxBuyingPoint_TEST
    // END_USER_CODE-USER_grpbxBuyingPoint_TEST
  });
  test("grpbxBuyingPointProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxBuyingPointProfile = screen.getByTestId(
      "grpbxBuyingPointProfile"
    );
    expect(grpbxBuyingPointProfile.tagName).toBe("BUTTON");
    expect(grpbxBuyingPointProfile.type).toBe("button");
    expect(grpbxBuyingPointProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBuyingPointProfile", () => {
    // START_USER_CODE-USER_grpbxBuyingPointProfile_TEST
    // END_USER_CODE-USER_grpbxBuyingPointProfile_TEST
  });
  test("grpbxCharacteristic(GroupBox Widget) Test Cases", async () => {
    const grpbxCharacteristic = screen.getByTestId("grpbxCharacteristic");
    expect(grpbxCharacteristic.tagName).toBe("BUTTON");
    expect(grpbxCharacteristic.type).toBe("button");
    expect(grpbxCharacteristic.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCharacteristic", () => {
    // START_USER_CODE-USER_grpbxCharacteristic_TEST
    // END_USER_CODE-USER_grpbxCharacteristic_TEST
  });
  test("grpbxCompanyArea(GroupBox Widget) Test Cases", async () => {
    const grpbxCompanyArea = screen.getByTestId("grpbxCompanyArea");
    expect(grpbxCompanyArea.tagName).toBe("BUTTON");
    expect(grpbxCompanyArea.type).toBe("button");
    expect(grpbxCompanyArea.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCompanyArea", () => {
    // START_USER_CODE-USER_grpbxCompanyArea_TEST
    // END_USER_CODE-USER_grpbxCompanyArea_TEST
  });
  test("grpbxContactInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxContactInfo = screen.getByTestId("grpbxContactInfo");
    expect(grpbxContactInfo.tagName).toBe("BUTTON");
    expect(grpbxContactInfo.type).toBe("button");
    expect(grpbxContactInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxContactInfo", () => {
    // START_USER_CODE-USER_grpbxContactInfo_TEST
    // END_USER_CODE-USER_grpbxContactInfo_TEST
  });
  test("grpbxControlNumbers(GroupBox Widget) Test Cases", async () => {
    const grpbxControlNumbers = screen.getByTestId("grpbxControlNumbers");
    expect(grpbxControlNumbers.tagName).toBe("BUTTON");
    expect(grpbxControlNumbers.type).toBe("button");
    expect(grpbxControlNumbers.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxControlNumbers", () => {
    // START_USER_CODE-USER_grpbxControlNumbers_TEST
    // END_USER_CODE-USER_grpbxControlNumbers_TEST
  });
  test("grpbxCuredCleanVendor(GroupBox Widget) Test Cases", async () => {
    const grpbxCuredCleanVendor = screen.getByTestId("grpbxCuredCleanVendor");
    expect(grpbxCuredCleanVendor.tagName).toBe("BUTTON");
    expect(grpbxCuredCleanVendor.type).toBe("button");
    expect(grpbxCuredCleanVendor.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxCuredCleanVendor", () => {
    // START_USER_CODE-USER_grpbxCuredCleanVendor_TEST
    // END_USER_CODE-USER_grpbxCuredCleanVendor_TEST
  });
  test("grpbxDefaultCMAVendor(GroupBox Widget) Test Cases", async () => {
    const grpbxDefaultCMAVendor = screen.getByTestId("grpbxDefaultCMAVendor");
    expect(grpbxDefaultCMAVendor.tagName).toBe("BUTTON");
    expect(grpbxDefaultCMAVendor.type).toBe("button");
    expect(grpbxDefaultCMAVendor.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDefaultCMAVendor", () => {
    // START_USER_CODE-USER_grpbxDefaultCMAVendor_TEST
    // END_USER_CODE-USER_grpbxDefaultCMAVendor_TEST
  });
  test("grpbxFTPAdmin(GroupBox Widget) Test Cases", async () => {
    const grpbxFTPAdmin = screen.getByTestId("grpbxFTPAdmin");
    expect(grpbxFTPAdmin.tagName).toBe("BUTTON");
    expect(grpbxFTPAdmin.type).toBe("button");
    expect(grpbxFTPAdmin.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFTPAdmin", () => {
    // START_USER_CODE-USER_grpbxFTPAdmin_TEST
    // END_USER_CODE-USER_grpbxFTPAdmin_TEST
  });
  test("grpbxGovernmentIssued(GroupBox Widget) Test Cases", async () => {
    const grpbxGovernmentIssued = screen.getByTestId("grpbxGovernmentIssued");
    expect(grpbxGovernmentIssued.tagName).toBe("BUTTON");
    expect(grpbxGovernmentIssued.type).toBe("button");
    expect(grpbxGovernmentIssued.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGovernmentIssued", () => {
    // START_USER_CODE-USER_grpbxGovernmentIssued_TEST
    // END_USER_CODE-USER_grpbxGovernmentIssued_TEST
  });
  test("grpbxMailingAddress(GroupBox Widget) Test Cases", async () => {
    const grpbxMailingAddress = screen.getByTestId("grpbxMailingAddress");
    expect(grpbxMailingAddress.tagName).toBe("BUTTON");
    expect(grpbxMailingAddress.type).toBe("button");
    expect(grpbxMailingAddress.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxMailingAddress", () => {
    // START_USER_CODE-USER_grpbxMailingAddress_TEST
    // END_USER_CODE-USER_grpbxMailingAddress_TEST
  });
  test("grpbxMiscellaneuos(GroupBox Widget) Test Cases", async () => {
    const grpbxMiscellaneuos = screen.getByTestId("grpbxMiscellaneuos");
    expect(grpbxMiscellaneuos.tagName).toBe("BUTTON");
    expect(grpbxMiscellaneuos.type).toBe("button");
    expect(grpbxMiscellaneuos.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxMiscellaneuos", () => {
    // START_USER_CODE-USER_grpbxMiscellaneuos_TEST
    // END_USER_CODE-USER_grpbxMiscellaneuos_TEST
  });
  test("grpbxPhysicalAddress(GroupBox Widget) Test Cases", async () => {
    const grpbxPhysicalAddress = screen.getByTestId("grpbxPhysicalAddress");
    expect(grpbxPhysicalAddress.tagName).toBe("BUTTON");
    expect(grpbxPhysicalAddress.type).toBe("button");
    expect(grpbxPhysicalAddress.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPhysicalAddress", () => {
    // START_USER_CODE-USER_grpbxPhysicalAddress_TEST
    // END_USER_CODE-USER_grpbxPhysicalAddress_TEST
  });
  test("lbl1007(Label Widget) Test Cases", async () => {
    const lbl1007 = screen.getByTestId("lbl1007");
    expect(lbl1007.tagName).toBe("LABEL");
    expect(lbl1007.classList).toContain("form-label");
    expect(lbl1007.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lbl1007")
    );
  });
  test("Custom Test Cases for lbl1007", () => {
    // START_USER_CODE-USER_lbl1007_TEST
    // END_USER_CODE-USER_lbl1007_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByValue(Label Widget) Test Cases", async () => {
    const lblAddedByValue = screen.getByTestId("lblAddedByValue");
    expect(lblAddedByValue.tagName).toBe("LABEL");
    expect(lblAddedByValue.classList).toContain("form-label");
    expect(lblAddedByValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblAddedByValue")
    );
  });
  test("Custom Test Cases for lblAddedByValue", () => {
    // START_USER_CODE-USER_lblAddedByValue_TEST
    // END_USER_CODE-USER_lblAddedByValue_TEST
  });
  test("lblAdditional1007(Label Widget) Test Cases", async () => {
    const lblAdditional1007 = screen.getByTestId("lblAdditional1007");
    expect(lblAdditional1007.tagName).toBe("LABEL");
    expect(lblAdditional1007.classList).toContain("form-label");
    expect(lblAdditional1007.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointProfile_lblAdditional1007"
      )
    );
  });
  test("Custom Test Cases for lblAdditional1007", () => {
    // START_USER_CODE-USER_lblAdditional1007_TEST
    // END_USER_CODE-USER_lblAdditional1007_TEST
  });
  test("lblArea(Label Widget) Test Cases", async () => {
    const lblArea = screen.getByTestId("lblArea");
    expect(lblArea.tagName).toBe("LABEL");
    expect(lblArea.classList).toContain("form-label");
    expect(lblArea.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblArea")
    );
  });
  test("Custom Test Cases for lblArea", () => {
    // START_USER_CODE-USER_lblArea_TEST
    // END_USER_CODE-USER_lblArea_TEST
  });
  test("lblAreaValue(Label Widget) Test Cases", async () => {
    const lblAreaValue = screen.getByTestId("lblAreaValue");
    expect(lblAreaValue.tagName).toBe("LABEL");
    expect(lblAreaValue.classList).toContain("form-label");
    expect(lblAreaValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblAreaValue")
    );
  });
  test("Custom Test Cases for lblAreaValue", () => {
    // START_USER_CODE-USER_lblAreaValue_TEST
    // END_USER_CODE-USER_lblAreaValue_TEST
  });
  test("lblBeg1007(Label Widget) Test Cases", async () => {
    const lblBeg1007 = screen.getByTestId("lblBeg1007");
    expect(lblBeg1007.tagName).toBe("LABEL");
    expect(lblBeg1007.classList).toContain("form-label");
    expect(lblBeg1007.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblBeg1007")
    );
  });
  test("Custom Test Cases for lblBeg1007", () => {
    // START_USER_CODE-USER_lblBeg1007_TEST
    // END_USER_CODE-USER_lblBeg1007_TEST
  });
  test("lblBeg11007(Label Widget) Test Cases", async () => {
    const lblBeg11007 = screen.getByTestId("lblBeg11007");
    expect(lblBeg11007.tagName).toBe("LABEL");
    expect(lblBeg11007.classList).toContain("form-label");
    expect(lblBeg11007.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblBeg11007")
    );
  });
  test("Custom Test Cases for lblBeg11007", () => {
    // START_USER_CODE-USER_lblBeg11007_TEST
    // END_USER_CODE-USER_lblBeg11007_TEST
  });
  test("lblBegCheck(Label Widget) Test Cases", async () => {
    const lblBegCheck = screen.getByTestId("lblBegCheck");
    expect(lblBegCheck.tagName).toBe("LABEL");
    expect(lblBegCheck.classList).toContain("form-label");
    expect(lblBegCheck.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblBegCheck")
    );
  });
  test("Custom Test Cases for lblBegCheck", () => {
    // START_USER_CODE-USER_lblBegCheck_TEST
    // END_USER_CODE-USER_lblBegCheck_TEST
  });
  test("lblBuyingPoint(Label Widget) Test Cases", async () => {
    const lblBuyingPoint = screen.getByTestId("lblBuyingPoint");
    expect(lblBuyingPoint.tagName).toBe("LABEL");
    expect(lblBuyingPoint.classList).toContain("form-label");
    expect(lblBuyingPoint.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblBuyingPoint")
    );
  });
  test("Custom Test Cases for lblBuyingPoint", () => {
    // START_USER_CODE-USER_lblBuyingPoint_TEST
    // END_USER_CODE-USER_lblBuyingPoint_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByValue(Label Widget) Test Cases", async () => {
    const lblChangedByValue = screen.getByTestId("lblChangedByValue");
    expect(lblChangedByValue.tagName).toBe("LABEL");
    expect(lblChangedByValue.classList).toContain("form-label");
    expect(lblChangedByValue.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointProfile_lblChangedByValue"
      )
    );
  });
  test("Custom Test Cases for lblChangedByValue", () => {
    // START_USER_CODE-USER_lblChangedByValue_TEST
    // END_USER_CODE-USER_lblChangedByValue_TEST
  });
  test("lblCharacteristics(Label Widget) Test Cases", async () => {
    const lblCharacteristics = screen.getByTestId("lblCharacteristics");
    expect(lblCharacteristics.tagName).toBe("LABEL");
    expect(lblCharacteristics.classList).toContain("form-label");
    expect(lblCharacteristics.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointProfile_lblCharacteristics"
      )
    );
  });
  test("Custom Test Cases for lblCharacteristics", () => {
    // START_USER_CODE-USER_lblCharacteristics_TEST
    // END_USER_CODE-USER_lblCharacteristics_TEST
  });
  test("lblCompany(Label Widget) Test Cases", async () => {
    const lblCompany = screen.getByTestId("lblCompany");
    expect(lblCompany.tagName).toBe("LABEL");
    expect(lblCompany.classList).toContain("form-label");
    expect(lblCompany.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblCompany")
    );
  });
  test("Custom Test Cases for lblCompany", () => {
    // START_USER_CODE-USER_lblCompany_TEST
    // END_USER_CODE-USER_lblCompany_TEST
  });
  test("lblCompanyValue(Label Widget) Test Cases", async () => {
    const lblCompanyValue = screen.getByTestId("lblCompanyValue");
    expect(lblCompanyValue.tagName).toBe("LABEL");
    expect(lblCompanyValue.classList).toContain("form-label");
    expect(lblCompanyValue.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblCompanyValue")
    );
  });
  test("Custom Test Cases for lblCompanyValue", () => {
    // START_USER_CODE-USER_lblCompanyValue_TEST
    // END_USER_CODE-USER_lblCompanyValue_TEST
  });
  test("lblContactInfo(Label Widget) Test Cases", async () => {
    const lblContactInfo = screen.getByTestId("lblContactInfo");
    expect(lblContactInfo.tagName).toBe("LABEL");
    expect(lblContactInfo.classList).toContain("form-label");
    expect(lblContactInfo.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblContactInfo")
    );
  });
  test("Custom Test Cases for lblContactInfo", () => {
    // START_USER_CODE-USER_lblContactInfo_TEST
    // END_USER_CODE-USER_lblContactInfo_TEST
  });
  test("lblControlNumbers(Label Widget) Test Cases", async () => {
    const lblControlNumbers = screen.getByTestId("lblControlNumbers");
    expect(lblControlNumbers.tagName).toBe("LABEL");
    expect(lblControlNumbers.classList).toContain("form-label");
    expect(lblControlNumbers.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointProfile_lblControlNumbers"
      )
    );
  });
  test("Custom Test Cases for lblControlNumbers", () => {
    // START_USER_CODE-USER_lblControlNumbers_TEST
    // END_USER_CODE-USER_lblControlNumbers_TEST
  });
  test("lblCuredCleanVendor(Label Widget) Test Cases", async () => {
    const lblCuredCleanVendor = screen.getByTestId("lblCuredCleanVendor");
    expect(lblCuredCleanVendor.tagName).toBe("LABEL");
    expect(lblCuredCleanVendor.classList).toContain("form-label");
    expect(lblCuredCleanVendor.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointProfile_lblCuredCleanVendor"
      )
    );
  });
  test("Custom Test Cases for lblCuredCleanVendor", () => {
    // START_USER_CODE-USER_lblCuredCleanVendor_TEST
    // END_USER_CODE-USER_lblCuredCleanVendor_TEST
  });
  test("lblDefaultCMAVendor(Label Widget) Test Cases", async () => {
    const lblDefaultCMAVendor = screen.getByTestId("lblDefaultCMAVendor");
    expect(lblDefaultCMAVendor.tagName).toBe("LABEL");
    expect(lblDefaultCMAVendor.classList).toContain("form-label");
    expect(lblDefaultCMAVendor.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointProfile_lblDefaultCMAVendor"
      )
    );
  });
  test("Custom Test Cases for lblDefaultCMAVendor", () => {
    // START_USER_CODE-USER_lblDefaultCMAVendor_TEST
    // END_USER_CODE-USER_lblDefaultCMAVendor_TEST
  });
  test("lblEnd1007(Label Widget) Test Cases", async () => {
    const lblEnd1007 = screen.getByTestId("lblEnd1007");
    expect(lblEnd1007.tagName).toBe("LABEL");
    expect(lblEnd1007.classList).toContain("form-label");
    expect(lblEnd1007.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblEnd1007")
    );
  });
  test("Custom Test Cases for lblEnd1007", () => {
    // START_USER_CODE-USER_lblEnd1007_TEST
    // END_USER_CODE-USER_lblEnd1007_TEST
  });
  test("lblEnd11007(Label Widget) Test Cases", async () => {
    const lblEnd11007 = screen.getByTestId("lblEnd11007");
    expect(lblEnd11007.tagName).toBe("LABEL");
    expect(lblEnd11007.classList).toContain("form-label");
    expect(lblEnd11007.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblEnd11007")
    );
  });
  test("Custom Test Cases for lblEnd11007", () => {
    // START_USER_CODE-USER_lblEnd11007_TEST
    // END_USER_CODE-USER_lblEnd11007_TEST
  });
  test("lblEndCheck(Label Widget) Test Cases", async () => {
    const lblEndCheck = screen.getByTestId("lblEndCheck");
    expect(lblEndCheck.tagName).toBe("LABEL");
    expect(lblEndCheck.classList).toContain("form-label");
    expect(lblEndCheck.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblEndCheck")
    );
  });
  test("Custom Test Cases for lblEndCheck", () => {
    // START_USER_CODE-USER_lblEndCheck_TEST
    // END_USER_CODE-USER_lblEndCheck_TEST
  });
  test("lblFTPAdmin(Label Widget) Test Cases", async () => {
    const lblFTPAdmin = screen.getByTestId("lblFTPAdmin");
    expect(lblFTPAdmin.tagName).toBe("LABEL");
    expect(lblFTPAdmin.classList).toContain("form-label");
    expect(lblFTPAdmin.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblFTPAdmin")
    );
  });
  test("Custom Test Cases for lblFTPAdmin", () => {
    // START_USER_CODE-USER_lblFTPAdmin_TEST
    // END_USER_CODE-USER_lblFTPAdmin_TEST
  });
  test("lblGovernmentIssued(Label Widget) Test Cases", async () => {
    const lblGovernmentIssued = screen.getByTestId("lblGovernmentIssued");
    expect(lblGovernmentIssued.tagName).toBe("LABEL");
    expect(lblGovernmentIssued.classList).toContain("form-label");
    expect(lblGovernmentIssued.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointProfile_lblGovernmentIssued"
      )
    );
  });
  test("Custom Test Cases for lblGovernmentIssued", () => {
    // START_USER_CODE-USER_lblGovernmentIssued_TEST
    // END_USER_CODE-USER_lblGovernmentIssued_TEST
  });
  test("lblLocationRecordIsInactive(Label Widget) Test Cases", async () => {
    const lblLocationRecordIsInactive = screen.getByTestId(
      "lblLocationRecordIsInactive"
    );
    expect(lblLocationRecordIsInactive.tagName).toBe("LABEL");
    expect(lblLocationRecordIsInactive.classList).toContain("form-label");
    expect(lblLocationRecordIsInactive.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointProfile_lblLocationRecordIsInactive"
      )
    );
  });
  test("Custom Test Cases for lblLocationRecordIsInactive", () => {
    // START_USER_CODE-USER_lblLocationRecordIsInactive_TEST
    // END_USER_CODE-USER_lblLocationRecordIsInactive_TEST
  });
  test("lblMailingAddress(Label Widget) Test Cases", async () => {
    const lblMailingAddress = screen.getByTestId("lblMailingAddress");
    expect(lblMailingAddress.tagName).toBe("LABEL");
    expect(lblMailingAddress.classList).toContain("form-label");
    expect(lblMailingAddress.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointProfile_lblMailingAddress"
      )
    );
  });
  test("Custom Test Cases for lblMailingAddress", () => {
    // START_USER_CODE-USER_lblMailingAddress_TEST
    // END_USER_CODE-USER_lblMailingAddress_TEST
  });
  test("lblMiscellaneous(Label Widget) Test Cases", async () => {
    const lblMiscellaneous = screen.getByTestId("lblMiscellaneous");
    expect(lblMiscellaneous.tagName).toBe("LABEL");
    expect(lblMiscellaneous.classList).toContain("form-label");
    expect(lblMiscellaneous.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblMiscellaneous")
    );
  });
  test("Custom Test Cases for lblMiscellaneous", () => {
    // START_USER_CODE-USER_lblMiscellaneous_TEST
    // END_USER_CODE-USER_lblMiscellaneous_TEST
  });
  test("lblName(Textbox Widget) Test Cases", async () => {
    const lblName = screen.getByTestId("lblName");
    expect(lblName.tagName).toBe("INPUT");
    expect(lblName.type).toBe("text");
    expect(lblName.classList).toContain("textboxWidgetClass");
    expect(lblName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblName")
    );
    await act(async () => {
      userEvent.type(lblName, "1");
    });
  });
  test("Custom Test Cases for lblName", () => {
    // START_USER_CODE-USER_lblName_TEST
    // END_USER_CODE-USER_lblName_TEST
  });
  test("lblNext1007(Label Widget) Test Cases", async () => {
    const lblNext1007 = screen.getByTestId("lblNext1007");
    expect(lblNext1007.tagName).toBe("LABEL");
    expect(lblNext1007.classList).toContain("form-label");
    expect(lblNext1007.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblNext1007")
    );
  });
  test("Custom Test Cases for lblNext1007", () => {
    // START_USER_CODE-USER_lblNext1007_TEST
    // END_USER_CODE-USER_lblNext1007_TEST
  });
  test("lblNextCheck(Label Widget) Test Cases", async () => {
    const lblNextCheck = screen.getByTestId("lblNextCheck");
    expect(lblNextCheck.tagName).toBe("LABEL");
    expect(lblNextCheck.classList).toContain("form-label");
    expect(lblNextCheck.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_lblNextCheck")
    );
  });
  test("Custom Test Cases for lblNextCheck", () => {
    // START_USER_CODE-USER_lblNextCheck_TEST
    // END_USER_CODE-USER_lblNextCheck_TEST
  });
  test("lblNextWhouseCntl(Label Widget) Test Cases", async () => {
    const lblNextWhouseCntl = screen.getByTestId("lblNextWhouseCntl");
    expect(lblNextWhouseCntl.tagName).toBe("LABEL");
    expect(lblNextWhouseCntl.classList).toContain("form-label");
    expect(lblNextWhouseCntl.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointProfile_lblNextWhouseCntl"
      )
    );
  });
  test("Custom Test Cases for lblNextWhouseCntl", () => {
    // START_USER_CODE-USER_lblNextWhouseCntl_TEST
    // END_USER_CODE-USER_lblNextWhouseCntl_TEST
  });
  test("lblPhysicalAddress(Label Widget) Test Cases", async () => {
    const lblPhysicalAddress = screen.getByTestId("lblPhysicalAddress");
    expect(lblPhysicalAddress.tagName).toBe("LABEL");
    expect(lblPhysicalAddress.classList).toContain("form-label");
    expect(lblPhysicalAddress.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointProfile_lblPhysicalAddress"
      )
    );
  });
  test("Custom Test Cases for lblPhysicalAddress", () => {
    // START_USER_CODE-USER_lblPhysicalAddress_TEST
    // END_USER_CODE-USER_lblPhysicalAddress_TEST
  });
  test("txtAddressMA(Textbox Widget) Test Cases", async () => {
    const txtAddressMA = screen.getByTestId("txtAddressMA");
    expect(txtAddressMA.tagName).toBe("INPUT");
    expect(txtAddressMA.type).toBe("text");
    expect(txtAddressMA.classList).toContain("textboxWidgetClass");
    expect(txtAddressMA.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtAddressMA")
    );
    await act(async () => {
      userEvent.type(txtAddressMA, "1");
    });
  });
  test("Custom Test Cases for txtAddressMA", () => {
    // START_USER_CODE-USER_txtAddressMA_TEST
    // END_USER_CODE-USER_txtAddressMA_TEST
  });
  test("txtAddressPA(Textbox Widget) Test Cases", async () => {
    const txtAddressPA = screen.getByTestId("txtAddressPA");
    expect(txtAddressPA.tagName).toBe("INPUT");
    expect(txtAddressPA.type).toBe("text");
    expect(txtAddressPA.classList).toContain("textboxWidgetClass");
    expect(txtAddressPA.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtAddressPA")
    );
    await act(async () => {
      userEvent.type(txtAddressPA, "1");
    });
  });
  test("Custom Test Cases for txtAddressPA", () => {
    // START_USER_CODE-USER_txtAddressPA_TEST
    // END_USER_CODE-USER_txtAddressPA_TEST
  });
  test("txtBalAcctDept(Textbox Widget) Test Cases", async () => {
    const txtBalAcctDept = screen.getByTestId("txtBalAcctDept");
    expect(txtBalAcctDept.tagName).toBe("INPUT");
    expect(txtBalAcctDept.type).toBe("text");
    expect(txtBalAcctDept.classList).toContain("textboxWidgetClass");
    expect(txtBalAcctDept.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtBalAcctDept")
    );
    await act(async () => {
      userEvent.type(txtBalAcctDept, "1");
    });
  });
  test("Custom Test Cases for txtBalAcctDept", () => {
    // START_USER_CODE-USER_txtBalAcctDept_TEST
    // END_USER_CODE-USER_txtBalAcctDept_TEST
  });
  test("txtBalAcctResp(Textbox Widget) Test Cases", async () => {
    const txtBalAcctResp = screen.getByTestId("txtBalAcctResp");
    expect(txtBalAcctResp.tagName).toBe("INPUT");
    expect(txtBalAcctResp.type).toBe("text");
    expect(txtBalAcctResp.classList).toContain("textboxWidgetClass");
    expect(txtBalAcctResp.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtBalAcctResp")
    );
    await act(async () => {
      userEvent.type(txtBalAcctResp, "1");
    });
  });
  test("Custom Test Cases for txtBalAcctResp", () => {
    // START_USER_CODE-USER_txtBalAcctResp_TEST
    // END_USER_CODE-USER_txtBalAcctResp_TEST
  });
  test("txtBeg(Textbox Widget) Test Cases", async () => {
    const txtBeg = screen.getByTestId("txtBeg");
    expect(txtBeg.tagName).toBe("INPUT");
    expect(txtBeg.type).toBe("text");
    expect(txtBeg.classList).toContain("textboxWidgetClass");
    expect(txtBeg.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtBeg")
    );
    await act(async () => {
      userEvent.type(txtBeg, "1");
    });
  });
  test("Custom Test Cases for txtBeg", () => {
    // START_USER_CODE-USER_txtBeg_TEST
    // END_USER_CODE-USER_txtBeg_TEST
  });
  test("txtBeg1(Textbox Widget) Test Cases", async () => {
    const txtBeg1 = screen.getByTestId("txtBeg1");
    expect(txtBeg1.tagName).toBe("INPUT");
    expect(txtBeg1.type).toBe("text");
    expect(txtBeg1.classList).toContain("textboxWidgetClass");
    expect(txtBeg1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtBeg1")
    );
    await act(async () => {
      userEvent.type(txtBeg1, "1");
    });
  });
  test("Custom Test Cases for txtBeg1", () => {
    // START_USER_CODE-USER_txtBeg1_TEST
    // END_USER_CODE-USER_txtBeg1_TEST
  });
  test("txtBegCheck(Textbox Widget) Test Cases", async () => {
    const txtBegCheck = screen.getByTestId("txtBegCheck");
    expect(txtBegCheck.tagName).toBe("INPUT");
    expect(txtBegCheck.type).toBe("text");
    expect(txtBegCheck.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtBegCheck, "1");
    });
  });
  test("Custom Test Cases for txtBegCheck", () => {
    // START_USER_CODE-USER_txtBegCheck_TEST
    // END_USER_CODE-USER_txtBegCheck_TEST
  });
  test("txtCCCLoc(Textbox Widget) Test Cases", async () => {
    const txtCCCLoc = screen.getByTestId("txtCCCLoc");
    expect(txtCCCLoc.tagName).toBe("INPUT");
    expect(txtCCCLoc.type).toBe("text");
    expect(txtCCCLoc.classList).toContain("textboxWidgetClass");
    expect(txtCCCLoc.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtCCCLoc")
    );
    await act(async () => {
      userEvent.type(txtCCCLoc, "1");
    });
  });
  test("Custom Test Cases for txtCCCLoc", () => {
    // START_USER_CODE-USER_txtCCCLoc_TEST
    // END_USER_CODE-USER_txtCCCLoc_TEST
  });
  test("txtCheck(Textbox Widget) Test Cases", async () => {
    const txtCheck = screen.getByTestId("txtCheck");
    expect(txtCheck.tagName).toBe("INPUT");
    expect(txtCheck.type).toBe("text");
    expect(txtCheck.classList).toContain("textboxWidgetClass");
    expect(txtCheck.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtCheck")
    );
    await act(async () => {
      userEvent.type(txtCheck, "1");
    });
  });
  test("Custom Test Cases for txtCheck", () => {
    // START_USER_CODE-USER_txtCheck_TEST
    // END_USER_CODE-USER_txtCheck_TEST
  });
  test("txtCityMA(Textbox Widget) Test Cases", async () => {
    const txtCityMA = screen.getByTestId("txtCityMA");
    expect(txtCityMA.tagName).toBe("INPUT");
    expect(txtCityMA.type).toBe("text");
    expect(txtCityMA.classList).toContain("textboxWidgetClass");
    expect(txtCityMA.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtCityMA")
    );
    await act(async () => {
      userEvent.type(txtCityMA, "1");
    });
  });
  test("Custom Test Cases for txtCityMA", () => {
    // START_USER_CODE-USER_txtCityMA_TEST
    // END_USER_CODE-USER_txtCityMA_TEST
  });
  test("txtCityPA(Textbox Widget) Test Cases", async () => {
    const txtCityPA = screen.getByTestId("txtCityPA");
    expect(txtCityPA.tagName).toBe("INPUT");
    expect(txtCityPA.type).toBe("text");
    expect(txtCityPA.classList).toContain("textboxWidgetClass");
    expect(txtCityPA.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtCityPA")
    );
    await act(async () => {
      userEvent.type(txtCityPA, "1");
    });
  });
  test("Custom Test Cases for txtCityPA", () => {
    // START_USER_CODE-USER_txtCityPA_TEST
    // END_USER_CODE-USER_txtCityPA_TEST
  });
  test("txtEnd(Textbox Widget) Test Cases", async () => {
    const txtEnd = screen.getByTestId("txtEnd");
    expect(txtEnd.tagName).toBe("INPUT");
    expect(txtEnd.type).toBe("text");
    expect(txtEnd.classList).toContain("textboxWidgetClass");
    expect(txtEnd.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtEnd")
    );
    await act(async () => {
      userEvent.type(txtEnd, "1");
    });
  });
  test("Custom Test Cases for txtEnd", () => {
    // START_USER_CODE-USER_txtEnd_TEST
    // END_USER_CODE-USER_txtEnd_TEST
  });
  test("txtEnd1(Textbox Widget) Test Cases", async () => {
    const txtEnd1 = screen.getByTestId("txtEnd1");
    expect(txtEnd1.tagName).toBe("INPUT");
    expect(txtEnd1.type).toBe("text");
    expect(txtEnd1.classList).toContain("textboxWidgetClass");
    expect(txtEnd1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtEnd1")
    );
    await act(async () => {
      userEvent.type(txtEnd1, "1");
    });
  });
  test("Custom Test Cases for txtEnd1", () => {
    // START_USER_CODE-USER_txtEnd1_TEST
    // END_USER_CODE-USER_txtEnd1_TEST
  });
  test("txtEndCheck(Textbox Widget) Test Cases", async () => {
    const txtEndCheck = screen.getByTestId("txtEndCheck");
    expect(txtEndCheck.tagName).toBe("INPUT");
    expect(txtEndCheck.type).toBe("text");
    expect(txtEndCheck.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtEndCheck, "1");
    });
  });
  test("Custom Test Cases for txtEndCheck", () => {
    // START_USER_CODE-USER_txtEndCheck_TEST
    // END_USER_CODE-USER_txtEndCheck_TEST
  });
  test("txtFedBuyPt(Textbox Widget) Test Cases", async () => {
    const txtFedBuyPt = screen.getByTestId("txtFedBuyPt");
    expect(txtFedBuyPt.tagName).toBe("INPUT");
    expect(txtFedBuyPt.type).toBe("text");
    expect(txtFedBuyPt.classList).toContain("textboxWidgetClass");
    expect(txtFedBuyPt.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtFedBuyPt")
    );
    await act(async () => {
      userEvent.type(txtFedBuyPt, "1");
    });
  });
  test("Custom Test Cases for txtFedBuyPt", () => {
    // START_USER_CODE-USER_txtFedBuyPt_TEST
    // END_USER_CODE-USER_txtFedBuyPt_TEST
  });
  test("txtGroupID(Textbox Widget) Test Cases", async () => {
    const txtGroupID = screen.getByTestId("txtGroupID");
    expect(txtGroupID.tagName).toBe("INPUT");
    expect(txtGroupID.type).toBe("text");
    expect(txtGroupID.classList).toContain("textboxWidgetClass");
    expect(txtGroupID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtGroupID")
    );
    await act(async () => {
      userEvent.type(txtGroupID, "1");
    });
  });
  test("Custom Test Cases for txtGroupID", () => {
    // START_USER_CODE-USER_txtGroupID_TEST
    // END_USER_CODE-USER_txtGroupID_TEST
  });
  test("txtID(Textbox Widget) Test Cases", async () => {
    const txtID = screen.getByTestId("txtID");
    expect(txtID.tagName).toBe("INPUT");
    expect(txtID.type).toBe("text");
    expect(txtID.classList).toContain("textboxWidgetClass");
    expect(txtID.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtID")
    );
    await act(async () => {
      userEvent.type(txtID, "1");
    });
  });
  test("Custom Test Cases for txtID", () => {
    // START_USER_CODE-USER_txtID_TEST
    // END_USER_CODE-USER_txtID_TEST
  });
  test("txtName(Textbox Widget) Test Cases", async () => {
    const txtName = screen.getByTestId("txtName");
    expect(txtName.tagName).toBe("INPUT");
    expect(txtName.type).toBe("text");
    expect(txtName.classList).toContain("textboxWidgetClass");
    expect(txtName.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtName")
    );
    await act(async () => {
      userEvent.type(txtName, "1");
    });
  });
  test("Custom Test Cases for txtName", () => {
    // START_USER_CODE-USER_txtName_TEST
    // END_USER_CODE-USER_txtName_TEST
  });
  test("txtNext(Textbox Widget) Test Cases", async () => {
    const txtNext = screen.getByTestId("txtNext");
    expect(txtNext.tagName).toBe("INPUT");
    expect(txtNext.type).toBe("text");
    expect(txtNext.classList).toContain("textboxWidgetClass");
    expect(txtNext.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtNext")
    );
    await act(async () => {
      userEvent.type(txtNext, "1");
    });
  });
  test("Custom Test Cases for txtNext", () => {
    // START_USER_CODE-USER_txtNext_TEST
    // END_USER_CODE-USER_txtNext_TEST
  });
  test("txtPhone(Textbox Widget) Test Cases", async () => {
    const txtPhone = screen.getByTestId("txtPhone");
    expect(txtPhone.tagName).toBe("INPUT");
    expect(txtPhone.type).toBe("text");
    expect(txtPhone.classList).toContain("textboxWidgetClass");
    expect(txtPhone.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtPhone")
    );
    await act(async () => {
      userEvent.type(txtPhone, "1");
    });
  });
  test("Custom Test Cases for txtPhone", () => {
    // START_USER_CODE-USER_txtPhone_TEST
    // END_USER_CODE-USER_txtPhone_TEST
  });
  test("txtPIAcctDept(Textbox Widget) Test Cases", async () => {
    const txtPIAcctDept = screen.getByTestId("txtPIAcctDept");
    expect(txtPIAcctDept.tagName).toBe("INPUT");
    expect(txtPIAcctDept.type).toBe("text");
    expect(txtPIAcctDept.classList).toContain("textboxWidgetClass");
    expect(txtPIAcctDept.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtPIAcctDept")
    );
    await act(async () => {
      userEvent.type(txtPIAcctDept, "1");
    });
  });
  test("Custom Test Cases for txtPIAcctDept", () => {
    // START_USER_CODE-USER_txtPIAcctDept_TEST
    // END_USER_CODE-USER_txtPIAcctDept_TEST
  });
  test("txtPIAcctResp(Textbox Widget) Test Cases", async () => {
    const txtPIAcctResp = screen.getByTestId("txtPIAcctResp");
    expect(txtPIAcctResp.tagName).toBe("INPUT");
    expect(txtPIAcctResp.type).toBe("text");
    expect(txtPIAcctResp.classList).toContain("textboxWidgetClass");
    expect(txtPIAcctResp.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtPIAcctResp")
    );
    await act(async () => {
      userEvent.type(txtPIAcctResp, "1");
    });
  });
  test("Custom Test Cases for txtPIAcctResp", () => {
    // START_USER_CODE-USER_txtPIAcctResp_TEST
    // END_USER_CODE-USER_txtPIAcctResp_TEST
  });
  test("txtProfitCenter(Textbox Widget) Test Cases", async () => {
    const txtProfitCenter = screen.getByTestId("txtProfitCenter");
    expect(txtProfitCenter.tagName).toBe("INPUT");
    expect(txtProfitCenter.type).toBe("text");
    expect(txtProfitCenter.classList).toContain("textboxWidgetClass");
    expect(txtProfitCenter.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtProfitCenter")
    );
    await act(async () => {
      userEvent.type(txtProfitCenter, "1");
    });
  });
  test("Custom Test Cases for txtProfitCenter", () => {
    // START_USER_CODE-USER_txtProfitCenter_TEST
    // END_USER_CODE-USER_txtProfitCenter_TEST
  });
  test("txtRetries(Textbox Widget) Test Cases", async () => {
    const txtRetries = screen.getByTestId("txtRetries");
    expect(txtRetries.tagName).toBe("INPUT");
    expect(txtRetries.type).toBe("text");
    expect(txtRetries.classList).toContain("textboxWidgetClass");
    expect(txtRetries.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtRetries")
    );
    await act(async () => {
      userEvent.type(txtRetries, "1");
    });
  });
  test("Custom Test Cases for txtRetries", () => {
    // START_USER_CODE-USER_txtRetries_TEST
    // END_USER_CODE-USER_txtRetries_TEST
  });
  test("txtServiceProvider(Textbox Widget) Test Cases", async () => {
    const txtServiceProvider = screen.getByTestId("txtServiceProvider");
    expect(txtServiceProvider.tagName).toBe("INPUT");
    expect(txtServiceProvider.type).toBe("text");
    expect(txtServiceProvider.classList).toContain("textboxWidgetClass");
    expect(txtServiceProvider.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceMasterManagement:BuyingPointProfile_txtServiceProvider"
      )
    );
    await act(async () => {
      userEvent.type(txtServiceProvider, "1");
    });
  });
  test("Custom Test Cases for txtServiceProvider", () => {
    // START_USER_CODE-USER_txtServiceProvider_TEST
    // END_USER_CODE-USER_txtServiceProvider_TEST
  });
  test("txtTimerInterval(Textbox Widget) Test Cases", async () => {
    const txtTimerInterval = screen.getByTestId("txtTimerInterval");
    expect(txtTimerInterval.tagName).toBe("INPUT");
    expect(txtTimerInterval.type).toBe("text");
    expect(txtTimerInterval.classList).toContain("textboxWidgetClass");
    expect(txtTimerInterval.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtTimerInterval")
    );
    await act(async () => {
      userEvent.type(txtTimerInterval, "1");
    });
  });
  test("Custom Test Cases for txtTimerInterval", () => {
    // START_USER_CODE-USER_txtTimerInterval_TEST
    // END_USER_CODE-USER_txtTimerInterval_TEST
  });
  test("txtVendorCCV(Textbox Widget) Test Cases", async () => {
    const txtVendorCCV = screen.getByTestId("txtVendorCCV");
    expect(txtVendorCCV.tagName).toBe("INPUT");
    expect(txtVendorCCV.type).toBe("text");
    expect(txtVendorCCV.classList).toContain("textboxWidgetClass");
    expect(txtVendorCCV.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtVendorCCV")
    );
    await act(async () => {
      userEvent.type(txtVendorCCV, "1");
    });
  });
  test("Custom Test Cases for txtVendorCCV", () => {
    // START_USER_CODE-USER_txtVendorCCV_TEST
    // END_USER_CODE-USER_txtVendorCCV_TEST
  });
  test("txtVendorDCV(Textbox Widget) Test Cases", async () => {
    const txtVendorDCV = screen.getByTestId("txtVendorDCV");
    expect(txtVendorDCV.tagName).toBe("INPUT");
    expect(txtVendorDCV.type).toBe("text");
    expect(txtVendorDCV.classList).toContain("textboxWidgetClass");
    expect(txtVendorDCV.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtVendorDCV")
    );
    await act(async () => {
      userEvent.type(txtVendorDCV, "1");
    });
  });
  test("Custom Test Cases for txtVendorDCV", () => {
    // START_USER_CODE-USER_txtVendorDCV_TEST
    // END_USER_CODE-USER_txtVendorDCV_TEST
  });
  test("txtVndr(Textbox Widget) Test Cases", async () => {
    const txtVndr = screen.getByTestId("txtVndr");
    expect(txtVndr.tagName).toBe("INPUT");
    expect(txtVndr.type).toBe("text");
    expect(txtVndr.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVndr, "1");
    });
  });
  test("Custom Test Cases for txtVndr", () => {
    // START_USER_CODE-USER_txtVndr_TEST
    // END_USER_CODE-USER_txtVndr_TEST
  });
  test("txtWhouse(Textbox Widget) Test Cases", async () => {
    const txtWhouse = screen.getByTestId("txtWhouse");
    expect(txtWhouse.tagName).toBe("INPUT");
    expect(txtWhouse.type).toBe("text");
    expect(txtWhouse.classList).toContain("textboxWidgetClass");
    expect(txtWhouse.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtWhouse")
    );
    await act(async () => {
      userEvent.type(txtWhouse, "1");
    });
  });
  test("Custom Test Cases for txtWhouse", () => {
    // START_USER_CODE-USER_txtWhouse_TEST
    // END_USER_CODE-USER_txtWhouse_TEST
  });
  test("txtZipMA(Textbox Widget) Test Cases", async () => {
    const txtZipMA = screen.getByTestId("txtZipMA");
    expect(txtZipMA.tagName).toBe("INPUT");
    expect(txtZipMA.type).toBe("text");
    expect(txtZipMA.classList).toContain("textboxWidgetClass");
    expect(txtZipMA.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtZipMA")
    );
    await act(async () => {
      userEvent.type(txtZipMA, "1");
    });
  });
  test("Custom Test Cases for txtZipMA", () => {
    // START_USER_CODE-USER_txtZipMA_TEST
    // END_USER_CODE-USER_txtZipMA_TEST
  });
  test("txtZipPA(Textbox Widget) Test Cases", async () => {
    const txtZipPA = screen.getByTestId("txtZipPA");
    expect(txtZipPA.tagName).toBe("INPUT");
    expect(txtZipPA.type).toBe("text");
    expect(txtZipPA.classList).toContain("textboxWidgetClass");
    expect(txtZipPA.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:BuyingPointProfile_txtZipPA")
    );
    await act(async () => {
      userEvent.type(txtZipPA, "1");
    });
  });
  test("Custom Test Cases for txtZipPA", () => {
    // START_USER_CODE-USER_txtZipPA_TEST
    // END_USER_CODE-USER_txtZipPA_TEST
  });
});
