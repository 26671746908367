/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  getData,
  setValue,
  getValue,
  hide,
  disable,
  enable,
  setData,
  hideColumn,
  getGridValue,
  setGridValue,
  show
} from "../../shared/WindowImports";

import "./PremiumDeductionsFrtAccrl.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../../SystemMaintenanceMasterManagement/Service/SystemMaintenanceMasterManagementServices";
import { StockTransferService } from "../Service/StockTransferService";
import { clsTransfer } from "../Common/clsTransfer";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";

// END_USER_CODE-USER_IMPORTS
function StockTransfer_PremiumDeductionsFrtAccrl(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let comp_id = sessionStorage.getItem('compId');
  let user_id = sessionStorage.getItem('userid') || '';
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PremiumDeductionsFrtAccrl",
    windowName: "PremiumDeductionsFrtAccrl",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "StockTransfer.PremiumDeductionsFrtAccrl",
    // START_USER_CODE-USER_PremiumDeductionsFrtAccrl_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Freight Accrual Premiums/Deductions",
      scrCode: "PN1080C",
    },
    // END_USER_CODE-USER_PremiumDeductionsFrtAccrl_PROPERTIES
    btmViewTransferDetail: {
      name: "btmViewTransferDetail",
      type: "ButtonWidget",
      parent: "cmmndcntnrActions",
      Label: "View Transfer Detail",
      CharWidth: "41",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btmViewTransferDetail_PROPERTIES

      // END_USER_CODE-USER_btmViewTransferDetail_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndcntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnComplete: {
      name: "btnComplete",
      type: "ButtonWidget",
      parent: "cmmndcntnrActions",
      Label: "Complete",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnComplete_PROPERTIES

      // END_USER_CODE-USER_btnComplete_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndcntnrActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndcntnrActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnReverseFrtAccrl: {
      name: "btnReverseFrtAccrl",
      type: "ButtonWidget",
      parent: "cmmndcntnrActions",
      Label: "Reverse Freight Accural",
      CharWidth: "46",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReverseFrtAccrl_PROPERTIES

      // END_USER_CODE-USER_btnReverseFrtAccrl_PROPERTIES
    },
    cmmndcntnrActions: {
      name: "cmmndcntnrActions",
      type: "CommandContainerWidget",
      parent: "grpbxPremiumDeductionsFrtAccrl",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "5",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_cmmndcntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndcntnrActions_PROPERTIES
    },
    colEmpty: {
      name: "colEmpty",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEmpty_PROPERTIES

      // END_USER_CODE-USER_colEmpty_PROPERTIES
    },
    colChckbx: {
      name: "colChckbx",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChckbx_PROPERTIES

      // END_USER_CODE-USER_colChckbx_PROPERTIES
    },
    colPremDed: {
      name: "colPremDed",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Premium/Deduction",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPremDed_PROPERTIES

      // END_USER_CODE-USER_colPremDed_PROPERTIES
    },
    colAmount: {
      name: "colAmount",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmount_PROPERTIES

      // END_USER_CODE-USER_colAmount_PROPERTIES
    },
    colPdDesc: {
      name: "colPdDesc",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPdDesc_PROPERTIES

      // END_USER_CODE-USER_colPdDesc_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    colPayeeVendr: {
      name: "colPayeeVendr",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPayeeVendr_PROPERTIES

      // END_USER_CODE-USER_colPayeeVendr_PROPERTIES
    },
    colPayeeRemit: {
      name: "colPayeeRemit",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPayeeRemit_PROPERTIES

      // END_USER_CODE-USER_colPayeeRemit_PROPERTIES
    },
    colAmnt: {
      name: "colAmnt",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmnt_PROPERTIES

      // END_USER_CODE-USER_colAmnt_PROPERTIES
    },
    colPct: {
      name: "colPct",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPct_PROPERTIES

      // END_USER_CODE-USER_colPct_PROPERTIES
    },
    colPdCode: {
      name: "colPdCode",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPdCode_PROPERTIES

      // END_USER_CODE-USER_colPdCode_PROPERTIES
    },
    colPdInd: {
      name: "colPdInd",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPdInd_PROPERTIES

      // END_USER_CODE-USER_colPdInd_PROPERTIES
    },
    colRateTyp: {
      name: "colRateTyp",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRateTyp_PROPERTIES

      // END_USER_CODE-USER_colRateTyp_PROPERTIES
    },
    colAotoInd: {
      name: "colAotoInd",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAotoInd_PROPERTIES

      // END_USER_CODE-USER_colAotoInd_PROPERTIES
    },
    colInspInd: {
      name: "colInspInd",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInspInd_PROPERTIES

      // END_USER_CODE-USER_colInspInd_PROPERTIES
    },
    colReqInd: {
      name: "colReqInd",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReqInd_PROPERTIES

      // END_USER_CODE-USER_colReqInd_PROPERTIES
    },
    colBefrRecInd: {
      name: "colBefrRecInd",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBefrRecInd_PROPERTIES

      // END_USER_CODE-USER_colBefrRecInd_PROPERTIES
    },
    colPS: {
      name: "colPS",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPS_PROPERTIES

      // END_USER_CODE-USER_colPS_PROPERTIES
    },
    colUseExpFuel: {
      name: "colUseExpFuel",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUseExpFuel_PROPERTIES

      // END_USER_CODE-USER_colUseExpFuel_PROPERTIES
    },
    colsFrtVendor: {
      name: "colsFrtVendor",
      type: "GridColumnWidget",
      parent: "gridPremsDeducts",
      Label: "sFrtVendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colsFrtVendor_PROPERTIES

      // END_USER_CODE-USER_colsFrtVendor_PROPERTIES
    },
    gridPremsDeducts: {
      name: "gridPremsDeducts",
      type: "GridWidget",
      parent: "grpbxPremsDeducts",
      gridCellsOrder:
        "txtcolEmpty,chkbxCol,txtcolPremDed,txtcolAmount,txtcolPdDesc,txtcolVendor,txtcolPayeeVendr,txtcolPayeeRemit,txtcolAmnt,txtcolPct,txtcolPdCode,txtcolPdInd,txtcolRateTyp,txtcolAotoInd,txtcolInspInd,txtcolReqInd,txtcolBefrRecInd,txtcolPS,txtcolUseExpFuel,txtcolsFrtVendor",
      ColSpan: "2",
      Pagination: false,
      HasLabel: false,
      Cols: "2",
      Height: "",
      Width: "",
      isEditable: true,
      isCobolTable: true,
      // START_USER_CODE-USER_gridPremsDeducts_PROPERTIES
      conditionalRowStyles: [
        {
          when: (row) => { return row.txtcolRateTyp != 'ENTRY' || row.txtcolReqInd == "Y" },
          style: {
            input: {
              pointerEvents: "none",
              border: 'none',
              boxShadow: 'none',
              background: 'transparent',
            },
            "input[type='checkbox']": {
              pointerEvents: "auto"
            }
          }
        }
      ],
      // END_USER_CODE-USER_gridPremsDeducts_PROPERTIES
    },
    txtcolEmpty: {
      name: "txtcolEmpty",
      type: "TextBoxWidget",
      colName: "colEmpty",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      Enabled: false
      // START_USER_CODE-USER_txtcolEmpty_PROPERTIES

      // END_USER_CODE-USER_txtcolEmpty_PROPERTIES
    },
    txtcolPremDed: {
      name: "txtcolPremDed",
      type: "TextBoxWidget",
      colName: "colPremDed",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      Enabled: false
      // START_USER_CODE-USER_txtcolPremDed_PROPERTIES

      // END_USER_CODE-USER_txtcolPremDed_PROPERTIES
    },
    txtcolAmount: {
      name: "txtcolAmount",
      type: "TextBoxWidget",
      colName: "colAmount",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolAmount_PROPERTIES
    },
    txtcolPdDesc: {
      name: "txtcolPdDesc",
      type: "TextBoxWidget",
      colName: "colPdDesc",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPdDesc_PROPERTIES

      // END_USER_CODE-USER_txtcolPdDesc_PROPERTIES
    },

    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtcolPayeeVendr: {
      name: "txtcolPayeeVendr",
      type: "TextBoxWidget",
      colName: "colPayeeVendr",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPayeeVendr_PROPERTIES

      // END_USER_CODE-USER_txtcolPayeeVendr_PROPERTIES
    },
    txtcolPayeeRemit: {
      name: "txtcolPayeeRemit",
      type: "TextBoxWidget",
      colName: "colPayeeRemit",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPayeeRemit_PROPERTIES

      // END_USER_CODE-USER_txtcolPayeeRemit_PROPERTIES
    },
    txtcolAmnt: {
      name: "txtcolAmnt",
      type: "TextBoxWidget",
      colName: "colAmnt",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmnt_PROPERTIES

      // END_USER_CODE-USER_txtcolAmnt_PROPERTIES
    },
    txtcolPct: {
      name: "txtcolPct",
      type: "TextBoxWidget",
      colName: "colPct",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPct_PROPERTIES

      // END_USER_CODE-USER_txtcolPct_PROPERTIES
    },
    txtcolPdCode: {
      name: "txtcolPdCode",
      type: "TextBoxWidget",
      colName: "colPdCode",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPdCode_PROPERTIES

      // END_USER_CODE-USER_txtcolPdCode_PROPERTIES
    },
    txtcolPdInd: {
      name: "txtcolPdInd",
      type: "TextBoxWidget",
      colName: "colPdInd",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPdInd_PROPERTIES

      // END_USER_CODE-USER_txtcolPdInd_PROPERTIES
    },
    txtcolRateTyp: {
      name: "txtcolRateTyp",
      type: "TextBoxWidget",
      colName: "colRateTyp",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRateTyp_PROPERTIES

      // END_USER_CODE-USER_txtcolRateTyp_PROPERTIES
    },
    txtcolAotoInd: {
      name: "txtcolAotoInd",
      type: "TextBoxWidget",
      colName: "colAotoInd",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAotoInd_PROPERTIES

      // END_USER_CODE-USER_txtcolAotoInd_PROPERTIES
    },
    txtcolInspInd: {
      name: "txtcolInspInd",
      type: "TextBoxWidget",
      colName: "colInspInd",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInspInd_PROPERTIES

      // END_USER_CODE-USER_txtcolInspInd_PROPERTIES
    },
    txtcolReqInd: {
      name: "txtcolReqInd",
      type: "TextBoxWidget",
      colName: "colReqInd",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolReqInd_PROPERTIES

      // END_USER_CODE-USER_txtcolReqInd_PROPERTIES
    },
    txtcolBefrRecInd: {
      name: "txtcolBefrRecInd",
      type: "TextBoxWidget",
      colName: "colBefrRecInd",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBefrRecInd_PROPERTIES

      // END_USER_CODE-USER_txtcolBefrRecInd_PROPERTIES
    },
    txtcolPS: {
      name: "txtcolPS",
      type: "TextBoxWidget",
      colName: "colPS",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPS_PROPERTIES

      // END_USER_CODE-USER_txtcolPS_PROPERTIES
    },
    txtcolUseExpFuel: {
      name: "txtcolUseExpFuel",
      type: "TextBoxWidget",
      colName: "colUseExpFuel",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUseExpFuel_PROPERTIES

      // END_USER_CODE-USER_txtcolUseExpFuel_PROPERTIES
    },
    txtcolsFrtVendor: {
      name: "txtcolsFrtVendor",
      type: "TextBoxWidget",
      colName: "colsFrtVendor",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolsFrtVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolsFrtVendor_PROPERTIES
    },
    chkbxCol: {
      name: "chkbxCol",
      type: "CheckBoxWidget",
      colName: "colChckbx",
      parent: "gridPremsDeducts",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    grpbxDetails1: {
      name: "grpbxDetails1",
      type: "GroupBoxWidget",
      parent: "grpbxPremiumDeductionsFrtAccrl",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxDetails1_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails1_PROPERTIES
    },
    grpbxDetails2: {
      name: "grpbxDetails2",
      type: "GroupBoxWidget",
      parent: "grpbxPremiumDeductionsFrtAccrl",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxDetails2_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails2_PROPERTIES
    },
    grpbxPremsDeducts: {
      name: "grpbxPremsDeducts",
      type: "GroupBoxWidget",
      parent: "grpbxPremiumDeductionsFrtAccrl",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      // START_USER_CODE-USER_grpbxPremsDeducts_PROPERTIES

      // END_USER_CODE-USER_grpbxPremsDeducts_PROPERTIES
    },
    lblLbsPer: {
      name: "lblLbsPer",
      type: "LabelWidget",
      parent: "grpbxDetails2",
      Label: "lbs /",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLbsPer_PROPERTIES

      // END_USER_CODE-USER_lblLbsPer_PROPERTIES
    },
    lblPerTon: {
      name: "lblPerTon",
      type: "LabelWidget",
      parent: "grpbxDetails2",
      Label: "/Ton",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerTon_PROPERTIES

      // END_USER_CODE-USER_lblPerTon_PROPERTIES
    },
    lblPremDeduct: {
      name: "lblPremDeduct",
      type: "LabelWidget",
      parent: "grpbxPremsDeducts",
      Label: "Premiums/Deductions:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPremDeduct_PROPERTIES

      // END_USER_CODE-USER_lblPremDeduct_PROPERTIES
    },
    lblTons: {
      name: "lblTons",
      type: "LabelWidget",
      parent: "grpbxDetails2",
      Label: "Tons",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTons_PROPERTIES

      // END_USER_CODE-USER_lblTons_PROPERTIES
    },
    txtAccrualAmount: {
      name: "txtAccrualAmount",
      type: "TextBoxWidget",
      parent: "grpbxPremsDeducts",
      Label: "Accrual Amount:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccrualAmount_PROPERTIES

      // END_USER_CODE-USER_txtAccrualAmount_PROPERTIES
    },
    txtCarrier: {
      name: "txtCarrier",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Carrier:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrier_PROPERTIES

      // END_USER_CODE-USER_txtCarrier_PROPERTIES
    },
    txtFreightAmount: {
      name: "txtFreightAmount",
      type: "TextBoxWidget",
      parent: "grpbxDetails2",
      Label: "Freight Amount:",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreightAmount_PROPERTIES

      // END_USER_CODE-USER_txtFreightAmount_PROPERTIES
    },
    txtFreightRate: {
      name: "txtFreightRate",
      type: "TextBoxWidget",
      parent: "grpbxDetails2",
      Label: "Freight Rate:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreightRate_PROPERTIES

      // END_USER_CODE-USER_txtFreightRate_PROPERTIES
    },
    txtFreightWeight: {
      name: "txtFreightWeight",
      type: "TextBoxWidget",
      parent: "grpbxDetails2",
      Label: "Freight Weight:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreightWeight_PROPERTIES

      // END_USER_CODE-USER_txtFreightWeight_PROPERTIES
    },
    txtFuelPct: {
      name: "txtFuelPct",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Fuel Percentage:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFuelPct_PROPERTIES

      // END_USER_CODE-USER_txtFuelPct_PROPERTIES
    },
    txtMiles: {
      name: "txtMiles",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Miles:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMiles_PROPERTIES

      // END_USER_CODE-USER_txtMiles_PROPERTIES
    },
    txtRatingOption: {
      name: "txtRatingOption",
      type: "TextBoxWidget",
      parent: "grpbxDetails2",
      Label: "Rating Option:",
      ColSpan: "4",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRatingOption_PROPERTIES

      // END_USER_CODE-USER_txtRatingOption_PROPERTIES
    },
    txtShippedDate: {
      name: "txtShippedDate",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Shipped Date:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShippedDate_PROPERTIES

      // END_USER_CODE-USER_txtShippedDate_PROPERTIES
    },
    txtTons: {
      name: "txtTons",
      type: "TextBoxWidget",
      parent: "grpbxDetails2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTons_PROPERTIES

      // END_USER_CODE-USER_txtTons_PROPERTIES
    },
    txtTrailerLease: {
      name: "txtTrailerLease",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Trailer Lease:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTrailerLease_PROPERTIES

      // END_USER_CODE-USER_txtTrailerLease_PROPERTIES
    },
    grpbxPremiumDeductionsFrtAccrl: {
      name: "grpbxPremiumDeductionsFrtAccrl",
      type: "GroupBoxWidget",
      parent: "PremiumDeductionsFrtAccrl",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPremiumDeductionsFrtAccrl_PROPERTIES

      // END_USER_CODE-USER_grpbxPremiumDeductionsFrtAccrl_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(false);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj

  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolPdDesc')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolVendor')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolPayeeVendr')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolPayeeRemit')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolAmnt')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolPct')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolPdCode')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolPdInd')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolRateTyp')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolAotoInd')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolReqInd')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolBefrRecInd')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolPS')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolUseExpFuel')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolsFrtVendor')
    hideColumn(thisObj, 'gridPremsDeducts', 'txtcolInspInd')

    hide(thisObj,"btnReverseFrtAccrl",false)
    hide(thisObj,"btmViewTransferDetail",false)
    hide(thisObj,"btnCompleteVisible",false)

    disable(thisObj, 'txtFreightWeight')
    disable(thisObj, 'txtTons')
    disable(thisObj, 'txtFreightRate')
    disable(thisObj, 'txtFreightAmount')
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    return() =>{
      
      setData(thisObj,'refreshGridAccrlSettlement',true)
    }
  }, []);

  useEffect(() => {
    let dataFromParentScreen = getData(thisObj, 'PremiumDeductionsFrtAccrl')
    
    setData(thisObj,'btnNameForStockTransfer',dataFromParentScreen?.btnName || "")
    if (dataFromParentScreen?.ViewPremiumsDeductionsFrtAccrl!=undefined && dataFromParentScreen?.ViewPremiumsDeductionsFrtAccrl==true) {
      ViewPremiumsDeductionsFrtAccrl(dataFromParentScreen)
    }
    if (dataFromParentScreen?.ReadPremiumsDeductionsFrtAccrl!= undefined && dataFromParentScreen?.ReadPremiumsDeductionsFrtAccrl == true) {
      ReadPremiumsDeductionsFrtAccrl(dataFromParentScreen)
    }
  }, [getData(thisObj, 'PremiumDeductionsFrtAccrl')])


  // START_USER_CODE-USER_METHODS
  const [mStockTransfer, setStockTransfer] = useState(new clsTransfer());
  const [lblFrtAccrlComplete, setLblFrtAccrlComplete] = useState('')
  const [lblTransferNum, setLblTransferNum] = useState('')
  const [lblTransferType, setLblTransferType] = useState('')
  const [lblshp_buy_pt, setLblshp_buy_pt] = useState('')
  const [lblDestBuyPt, setLblDestBuyPt] = useState('')
  const [lblScac, setLblScac] = useState('')
  const [lblVendor, setLblVendor] = useState('')
  const [lblEffectiveDate, setLblEffectiveDate] = useState('')
  const [lblTripID, setLblTripID] = useState('')
  const [lblNetD, setLblNetD] = useState('')
  const [lblNetP, setLblNetP] = useState('')



  const ReadPremiumsDeductionsFrtAccrl = async (dataFromParentScreen) => {
    try {
      setLoading(true)
      let sShipLocList = ''
      let FrtAccrlComplete = ''
      let lblCancel = ''
      let bMsgAlreadyDisplayed = ''
      let dTotalWt
      let dThruHaulWt
      let bCalcThruHaulWt
      let dTripId
      let sLeasedTrailerInd
      let sFrtVendor
      let sFrtScacId
      let sDestBuyPt
      let shp_buy_pt
      let sEffectiveDate
      let msXferMaster = ''
      let dLegWeight = 0
      let dTotalNetWt = 0
      let dTotalLskWt = 0
      let dTotalGrossWt = 0
      let dTotalBasisGradeAmt = 0
      let dTotalMileage = 0
      let dThruHaulMiles = 0
      let sTransferType = ''
      let transfer_type
      let dMileageOverride
      let sRateBasis
      let dFrtRate
      let transfer_num
      let crop_year
      let DestBuyPt
      let scac
      let vendor
      let EffectiveDate
      let TripID

      let dataForComplete={}

      setValue(thisObj, 'txtShippedDate', '')
      setValue(thisObj, 'txtMiles', '')
      setValue(thisObj, 'txtFreightRate', '')
      setValue(thisObj, 'txtFreightAmount', '')
      setValue(thisObj, 'txtAccrualAmount', '')
      setValue(thisObj, 'txtFreightWeight', "")
      setLblFrtAccrlComplete('N')
      FrtAccrlComplete = 'N'
      lblCancel = 'N'
      bMsgAlreadyDisplayed = false

      let ClassData=dataFromParentScreen
      
      setValue(thisObj, 'txtFuelPct', '0.00%')
      setValue(thisObj, 'txtTrailerLease', '0.00%')
      dTotalWt = 0
      dThruHaulWt = 0
      bCalcThruHaulWt = false

      if (dataFromParentScreen.dTripId > 0) {
        sDestBuyPt = dataFromParentScreen.sDestBuyPt
        sFrtScacId = dataFromParentScreen.sFrtScacId
        sFrtVendor = dataFromParentScreen.sFrtVendor
        sLeasedTrailerInd = dataFromParentScreen.sLeasedTrailerInd
        dTripId = !isNaN(dataFromParentScreen.dTripId) ? dataFromParentScreen.dTripId : 0;

        if(dataFromParentScreen.btnCompleteVisible==true){
          show(thisObj,"btnComplete")
        }

        let response = await StockTransferService.RetrieveMultistopTripDetails(null, dataFromParentScreen.dTripId, null, null, null)
        let data = response;

        for (let i = 0; i < data.length; i++) {
          if (moment(data[i].shp_date).isValid() && data[i].leg_count == 1) setValue(thisObj, 'txtShippedDate', moment(data[i].shp_date).format('MM/DD/YYYY'))
          sEffectiveDate = data[i].shp_date.slice(0, -11)

          msXferMaster += data[i].shp_buy_pt + ','
          msXferMaster += data[i].transfer_num + ','
          msXferMaster += data[i].transfer_type + ','

          if (data[i].transfer_type == 'FF') {
            switch (data[i].pay_freight_ind) {
              case "R":
                msXferMaster += (!isNaN(data[i].rec_peanut_wt) ? data[i].rec_peanut_wt : 0) + ','
                dThruHaulWt = Number(dThruHaulWt) + Number(!isNaN(data[i].rec_peanut_wt) ? data[i].rec_peanut_wt : 0)
                break;

              case "S":
                msXferMaster += (!isNaN(data[i].shp_peanut_wt) ? data[i].shp_peanut_wt : 0) + ','
                dThruHaulWt = Number(dThruHaulWt) + Number(!isNaN(data[i].shp_peanut_wt) ? data[i].shp_peanut_wt : 0)
                break;

              case "O":
                msXferMaster += (!isNaN(data[i].frt_other_wt) ? data[i].frt_other_wt : 0) + ','
                dThruHaulWt = Number(dThruHaulWt) + Number(!isNaN(data[i].frt_other_wt) ? data[i].frt_other_wt : 0)
                break;

              default:
                msXferMaster += msXferMaster + '0,'
            }
            bCalcThruHaulWt = true
          }
          else {
            switch (data[i].pay_freight_ind) {
              case "R":
                msXferMaster += (!isNaN(data[i].rec_peanut_wt) ? data[i].rec_peanut_wt : 0) + ','
                dLegWeight = !isNaN(data[i].rec_peanut_wt) ? data[i].rec_peanut_wt : 0
                dTotalWt = Number(dTotalWt) + Number(dLegWeight)
                break;

              case "S":
                msXferMaster += (!isNaN(data[i].shp_peanut_wt) ? data[i].shp_peanut_wt : 0) + ','
                dLegWeight = !isNaN(data[i].shp_peanut_wt) ? data[i].shp_peanut_wt : 0
                dTotalWt = Number(dTotalWt) + Number(dLegWeight)
                break;

              case "O":
                msXferMaster += (!isNaN(data[i].frt_other_wt) ? data[i].frt_other_wt : 0) + ','
                dLegWeight = !isNaN(data[i].frt_other_wt) ? data[i].frt_other_wt : 0
                dTotalWt = Number(dTotalWt) + Number(dLegWeight)
                break;

              default:
                msXferMaster += msXferMaster + '0,'
            }
            dTotalNetWt = Number(dTotalNetWt) + Number(!isNaN(data[i].net_wt) ? data[i].net_wt : 0)
            dTotalLskWt = Number(dTotalLskWt) + Number(!isNaN(data[i].lsk_wt) ? data[i].lsk_wt : 0)
            dTotalGrossWt = Number(dTotalGrossWt) + Number(!isNaN(data[i].gross_wt) ? data[i].gross_wt : 0)
            dTotalBasisGradeAmt = Number(dTotalBasisGradeAmt) + Number(!isNaN(data[i].basis_grade_amt) ? data[i].basis_grade_amt : 0)
            dTotalBasisGradeAmt = Math.trunc(dTotalBasisGradeAmt)

            if (bCalcThruHaulWt) {
              dThruHaulWt = Number(dThruHaulWt) - Number(dLegWeight)
              bCalcThruHaulWt = false
            }
          }

          msXferMaster += (!isNaN(data[i].leg_mileage) ? data[i].leg_mileage : 0) + ','
          msXferMaster += (data[i].frt_mileage_override == "" ? "0" : data[i].frt_mileage_override) + '|'

          let value = !isNaN(data[i].frt_mileage_override?.trim()) == false ? 0 : data[i].frt_mileage_override?.trim()
          if (value > 0) {
            dTotalMileage = Number(dTotalMileage) + Number(!isNaN(data[i].frt_mileage_override) ? data[i].frt_mileage_override : 0)

            if (bCalcThruHaulWt) {
              dThruHaulMiles = !isNaN(data[i].frt_mileage_override) ? data[i].frt_mileage_override : 0
            }
          }
          else {
            dTotalMileage += !isNaN(data[i].leg_mileage) ? data[i].leg_mileage : 0
            if (bCalcThruHaulWt) {
              dThruHaulMiles = !isNaN(data[i].leg_mileage) ? data[i].leg_mileage : 0
            }
          }

          if (data[i].frt_accrl_complete == "Y") {
            setLblFrtAccrlComplete('Y')
            FrtAccrlComplete = "Y"
          }

          if (data[i].transfer_type == "FF") sTransferType = 'FF'
          else if (data[i].transfer_type == "NL") sTransferType = 'NL'
          else sTransferType = 'ST'

          setLblTransferType(sTransferType)
          transfer_type = sTransferType
        }

        dMileageOverride = 0
        sRateBasis = "NONE"
        dFrtRate = 0

        setLblTransferNum(0)
        transfer_num = 0;
        setLblshp_buy_pt(dataFromParentScreen.lblShpBuyPt)

        shp_buy_pt = dataFromParentScreen.lblShpBuyPt
        setLblDestBuyPt(sDestBuyPt)

        DestBuyPt = sDestBuyPt
        setLblDestBuyPt(sDestBuyPt)

        scac = sFrtScacId
        setLblScac(sFrtScacId)
        setValue(thisObj, 'txtCarrier', sFrtScacId)

        vendor = sFrtVendor
        setLblVendor(sFrtVendor)

        EffectiveDate = sEffectiveDate
        setLblEffectiveDate(sEffectiveDate)

        TripID = dTripId
        setLblTripID(dTripId)

        setValue(thisObj, 'txtFreightWeight', dTotalWt.toString())
        setValue(thisObj, 'txtTons', Number(dTotalWt / 2000).toFixed(2))
      }
      else {
        
        transfer_type = ClassData.mTransfer.mudtProps.transfer_type
        setLblTransferType(transfer_type)
        let shp_date=''
        if(ClassData.mTransfer.mudtProps.shp_date.length>10){
          shp_date=ClassData.mTransfer.mudtProps.shp_date.slice(0,-11)
        }
        else shp_date=moment(ClassData.mTransfer.mudtProps.shp_date).format('MM/DD/YYYY')
        setValue(thisObj, 'txtShippedDate', shp_date)
        let miles= await ClassData.mTransfer.FreightMiles()
        setValue(thisObj, 'txtMiles', miles)

        sDestBuyPt = ClassData.mTransfer.mudtProps.rec_buy_pt
        shp_buy_pt = ClassData.mTransfer.mudtProps.shp_buy_pt
        crop_year=ClassData.mTransfer.mudtProps.crop_year
        sFrtScacId = ClassData.mTransfer.mudtProps.frt_scac_id
        setValue(thisObj, 'txtCarrier', sFrtScacId)
        sFrtVendor = ClassData.mTransfer.mudtProps.frt_vendor
        let leased= await ClassData.mTransfer.LeasedTrailerInd()
        sLeasedTrailerInd = leased 
        dTripId = ClassData.mTransfer.mudtProps.trip_id

        msXferMaster+= ClassData.mTransfer.mudtProps.shp_buy_pt + ','
        transfer_num=ClassData.mTransfer.mudtProps.transfer_num
        setLblTransferNum(transfer_num)
        msXferMaster+=transfer_num +','

        sEffectiveDate= shp_date

        msXferMaster+= transfer_type + ','

        let FreightAccrualWeight= await ClassData.mTransfer.FreightAccrualWeight()
        msXferMaster+= FreightAccrualWeight + ','

        msXferMaster+= '0'+',';
        let frtMileage=ClassData.mTransfer.mudtProps.frt_mileage_override
        if(frtMileage==undefined || frtMileage==null || frtMileage==''){
          frtMileage='0'
        }
        else{
          frtMileage=ClassData.mTransfer.mudtProps.frt_mileage_override
        }
        msXferMaster+= frtMileage + '|'

        let dTotalMileage = ''
        sRateBasis = ''
        dFrtRate = ''

        let response= await SystemMaintenanceMasterManagementService.RetrieveFreightLocationToLocationDetails(crop_year,shp_buy_pt, sDestBuyPt, null, null, null, null, null, null, null, null, null, null)
        let data=response

        if (data != null && data != undefined && data.length > 0) {
          if(ClassData.mTransfer.mudtProps.frt_mileage_override>0){
            dTotalMileage= ClassData.mTransfer.mudtProps.frt_mileage_override 
          }
          else{
            dTotalMileage= !isNaN(data[0].loc_to_loc_miles) ? data[0].loc_to_loc_miles : '0';
          }
          sRateBasis = data[0].loc_to_loc_rate_basis==""?"NONE":data[0].loc_to_loc_rate_basis;
          dFrtRate = !isNaN(data[0].loc_to_loc_rate) ? data[0].loc_to_loc_rate : '0';
          
        }
        else{ 
          if(ClassData.mTransfer.mudtProps.frt_mileage_override>0) {
          dTotalMileage= ClassData.mTransfer.mudtProps.frt_mileage_override 
          
        }
        else{
          dTotalMileage=0;
        }

        sRateBasis = 'NONE'
        dFrtRate = 0
      }

      dMileageOverride= ClassData.mTransfer.mudtProps.frt_mileage_override
      dTotalWt = await ClassData.mTransfer.FreightAccrualWeight()
      dTotalNetWt = ClassData.mTransfer.mudtProps.net_wt
      dTotalLskWt = ClassData.mTransfer.mudtProps.lsk_wt
      dTotalGrossWt = await ClassData.mTransfer.WeightLessFM()
      dTotalBasisGradeAmt = await ClassData.mTransfer.WeightLessLSK()

      setLblFrtAccrlComplete(ClassData.mTransfer.mudtProps.frt_accrl_complete)

      setLblTransferNum(ClassData.mTransfer.mudtProps.transfer_num)

      setLblshp_buy_pt(ClassData.mTransfer.mudtProps.shp_buy_pt)
      setLblDestBuyPt(ClassData.mTransfer.mudtProps.rec_buy_pt)
      setLblScac(sFrtScacId)
      setLblVendor(sFrtVendor)
      setLblEffectiveDate(sEffectiveDate)
      setLblTransferType(ClassData.mTransfer.mudtProps.transfer_type)
      setLblTripID(dTripId)
      }

      if (FrtAccrlComplete == 'Y') disable(thisObj, 'btnComplete')
      else enable(thisObj, 'btnComplete')

      if (transfer_type == 'FF') dTotalMileage = 0
      let response3 = await StockTransferService.RetrievePremiumDeductionSheduleListDetails(sDestBuyPt, shp_buy_pt, transfer_type, transfer_num, dTripId, sEffectiveDate, FrtAccrlComplete, sLeasedTrailerInd, dMileageOverride, dTotalWt, dTotalMileage, sRateBasis, dFrtRate, dTotalNetWt, dTotalLskWt, dTotalGrossWt, dTotalBasisGradeAmt, dThruHaulWt, dThruHaulMiles, null, null, null, null, sFrtScacId, sFrtVendor,crop_year)
      let data3 = response3

      if (data3.length <= 0) return
      
      let getFreightRate = {}
      getFreightRate.freight_rate = data3[0].frt_rate
      getFreightRate.freight_amt = data3[0].frt_cost
      getFreightRate.frt_fuel_pct = data3[0].fuel_pct
      getFreightRate.frt_rate_basis = data3[0].rate_basis
      getFreightRate.frt_rate_source = data3[0].rate_option
      getFreightRate.trip_weight = data3[0].trip_weight
      
      setData(thisObj, "GetFreightRate", getFreightRate)
      setValue(thisObj, 'txtFreightRate', 0)
      setValue(thisObj, 'txtFreightAmount', 0)
      let gridObj = {};
      let transformedArray = [];
      if (FrtAccrlComplete != 'Y') {
        for (let i = 0; i < data3.length; i++) {
          if (bMsgAlreadyDisplayed == false) {
            if (data3[i].msg_to_client != '') {
              bMsgAlreadyDisplayed = true
              let yesNo = confirm(`${data3[i].msg_to_client} Do you wish to continue?`)
              if (yesNo == false) {
                lblCancel = 'Y'
                setData(thisObj, "closingOfPremiumDeductionsFrtAccrl", true)
                document.getElementById("StockTransfer_PremiumDeductionsFrtAccrlPopUp").childNodes[0].click();
                return
              }

            }
          } if (dataFromParentScreen.dTripId > 0) {
            if (data3[i].mileage != ""){
              setValue(thisObj, 'txtMiles', data3[i].mileage)
            } 
          }
          else {
            if (data3[i].mileage != 0) {
              setValue(thisObj, 'txtMiles', data3[i].mileage)
              dTotalMileage = !isNaN(data3[i].mileage) ? data3[i].mileage : 0
            }
            else {
              dTotalMileage = await ClassData.mTransfer.FreightMiles() 
            }
          }
          setValue(thisObj, 'txtFreightRate', !isNaN(data3[i].frt_rate) ? Number(data3[i].frt_rate).toFixed(2) : 0)
          setValue(thisObj, 'txtRatingOption', data3[i].rate_option)
          setValue(thisObj, 'txtFreightAmount', !isNaN(data3[i].frt_cost) ? Number(data3[i].frt_cost).toFixed(2) : 0)
          setData(thisObj, "txtFreightAmount", !isNaN(data3[i].frt_cost) ? Number(data3[i].frt_cost).toFixed(2) : 0)
          
         if(dTripId=="0"){
          let weight= data3[i].trip_weight;
          setValue(thisObj, 'txtFreightWeight', weight.toString())
          setValue(thisObj, 'txtTons', Number(weight / 2000).toFixed(2))
         }

          if (data3[i].pd_code == '') break

          gridObj.chkbxCol = true;

          if (data3[i].rate_type == 'FUEL') {
            document.getElementById('txtFuelPct').innerText = 'Fuel Percentage'
            setValue(thisObj, 'txtFuelPct', !isNaN(data3[i].fuel_pct) ? Number(data3[i].fuel_pct).toFixed(2) + '%' : "")

          }


          if (data3[i].rate_type == 'LEASE') {
            setValue(thisObj, 'txtTrailerLease', !isNaN(data3[i].lease_pct) ? Number(data3[i].lease_pct).toFixed(2) + '%' : "")
            gridObj.txtcolPremDed = data3[i].pd_desc == '' ? 'Lease' : data3[i].pd_desc
          }
          else {
            gridObj.txtcolPremDed = data3[i].pd_desc
          }

          gridObj.txtcolAmount = Number(data3[i].pamount).toFixed(2)
          gridObj.txtcolVendor = data3[i].vendor
          gridObj.txtcolPayeeVendr = data3[i].payee_vendor
          gridObj.txtcolPayeeRemit = data3[i].payee_remit
          gridObj.txtcolAmnt = Number(data3[i].amt).toFixed(2)
          gridObj.txtcolPct = data3[i].pct
          gridObj.txtcolPdCode = data3[i].pd_code
          gridObj.txtcolPdInd = data3[i].pd_ind
          gridObj.txtcolRateTyp = data3[i].rate_type
          gridObj.txtcolAotoInd = data3[i].auto_ind
          gridObj.txtcolInspInd = data3[i].inspect_ind
          gridObj.txtcolReqInd = data3[i].required_ind
          gridObj.txtcolBefrRecInd = data3[i].before_recv_ind
          gridObj.txtcolPS = data3[i].ps
          gridObj.txtcolUseExpFuel = data3[i].use_expired_fuel

          if (data3[i].required_ind == 'Y') {
            gridObj.chkbxCol = true;
          }

          if (data3[i].auto_ind == 'Y') {
            gridObj.chkbxCol = true;
          }

          if (data3[i].vendor == sFrtVendor) {
            gridObj.txtcolsFrtVendor = sFrtVendor
          }

          transformedArray.push(gridObj);
          gridObj = {}

          
          dataForComplete.fuelPct=!isNaN(data3[i].fuel_pct) ? Number(data3[i].fuel_pct).toFixed(2) + '%' : ""
          dataForComplete.trailerLease=!isNaN(data3[i].lease_pct) ? Number(data3[i].lease_pct).toFixed(2) + '%' : ""
          dataForComplete.ratingOption=data3[i].rate_option
          dataForComplete.calcDate=sEffectiveDate
          dataForComplete.shp_buy_pt=shp_buy_pt
          dataForComplete.dataForComplete=transfer_type
          dataForComplete.transferNum=transfer_num
          dataForComplete.dest_buy_pt=sDestBuyPt
          dataForComplete.scac=sFrtScacId
          dataForComplete.vendor=sFrtVendor
          dataForComplete.trip_id=dTripId
          dataForComplete.effective_date=sEffectiveDate
          dataForComplete.frt_rate=!isNaN(data3[i].frt_rate) ? Number(data3[i].frt_rate).toFixed(2) : 0
          dataForComplete.frt_amt=!isNaN(data3[i].frt_cost) ? Number(data3[i].frt_cost).toFixed(2) : 0
          dataForComplete.tot_frt_wt=data3[i].trip_weight.toString()
        }
        
        setValue(thisObj, 'gridPremsDeducts', transformedArray)
        dataForComplete.transformedArray=transformedArray
        dataForComplete.msXferMaster=msXferMaster

      }

      setData(thisObj, "msXferMaster", msXferMaster)
      setStockTransfer(ClassData.mTransfer)

      if (lblCancel != 'Y') {
        Calnetvalue()

        let dataComplete=getData(thisObj,"dataComplete")
        dataForComplete.d_amt=dataComplete.d_amt
        dataForComplete.p_amt=dataComplete.p_amt
        dataForComplete.net_amt=dataComplete.accuralAmount
        dataForComplete.freightAmount=dataComplete.freightAmount
        if(ClassData.lblAutorun=="Y"){
          await stockTransferSaveComplete(dataForComplete);
        }
      }
      
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred in ReadPremiumsDeductionsFrtAccrl");
      }
    }

  }

  const ViewPremiumsDeductionsFrtAccrl = async (accrlSettlementData) => {
    try {
      setLoading(true)
      let ClassData = accrlSettlementData
      let msXferMaster = getData(thisObj, 'msXferMaster') 
      setValue(thisObj, 'txtShippedDate', moment(ClassData.mTransfer.mudtProps.shp_date).format('MM/DD/YYYY')) //We will get the Data from m.Transfer
      let miles= await ClassData.mTransfer.FreightMiles()
      setValue(thisObj, 'txtMiles', miles)
      setValue(thisObj, 'txtFreightRate', '')
      setValue(thisObj, 'txtFreightAmount', '')
      setValue(thisObj, 'txtAccrualAmount', '')

      if(accrlSettlementData.DisplayViewReverse==true){
        show(thisObj,"btnReverseFrtAccrl")
        show(thisObj,"btmViewTransferDetail")
      }

      setLblFrtAccrlComplete('N')
      let FrtAccrlComplete = 'N'
      let sDestBuyPt = ClassData.mTransfer.mudtProps.rec_buy_pt
      let sFrtScacId = ClassData.mTransfer.mudtProps.frt_scac_id
      let sFrtVendor = ClassData.mTransfer.mudtProps.frt_vendor
      let leased= await ClassData.mTransfer.LeasedTrailerInd()
      let sLeasedTrailerInd = leased 
      let dTripId = ClassData.mTransfer.mudtProps.trip_id
      let sEffectiveDate = moment(ClassData.mTransfer.mudtProps.shp_date).format('MM/DD/YYYY')
      let TransferNum = ClassData.mTransfer.mudtProps.transfer_num
      setLblTransferNum(TransferNum)
      let TransferType = ClassData.mTransfer.mudtProps.transfer_type
      setLblTransferType(TransferType)

      let dTotalMileage = ''
      let sRateBasis = ''
      let dFrtRate = ''

      let crop_year=ClassData.mTransfer.mudtProps.crop_year
      let shp_buy_pt=ClassData.mTransfer.mudtProps.shp_buy_pt

      let response = await SystemMaintenanceMasterManagementService.RetrieveFreightLocationToLocationDetails(crop_year,shp_buy_pt, sDestBuyPt, null, null, null, null, null, null, null, null, null, null)
      let data = response;

      if (data != null && data != undefined && data.length > 0) {
        dTotalMileage = !isNaN(data[0].loc_to_loc_miles) ? data[0].loc_to_loc_miles : '0';
        sRateBasis = data[0].loc_to_loc_rate_basis;
        dFrtRate = !isNaN(data[0].loc_to_loc_rate) ? data[0].loc_to_loc_rate : '0';
        if (dFrtRate == '0.00000') dFrtRate = 0;
      }
      else {
        dTotalMileage = 0
        sRateBasis = 'NONE'
        dFrtRate = 0
      }

      let dMileageOverride = ClassData.mTransfer.mudtProps.frt_mileage_override
      let dTotalNetWt = ClassData.mTransfer.mudtProps.net_wt
      let dTotalLskWt = ClassData.mTransfer.mudtProps.lsk_wt
      let dTotalGrossWt = ClassData.mTransfer.mudtProps.WeightLessFM
      let dTotalBasisGradeAmt = await ClassData.mTransfer.WeightLessLSK()
      FrtAccrlComplete = 'Y'
      TransferNum = ClassData.mTransfer.mudtProps.transfer_num
      setLblshp_buy_pt(shp_buy_pt)
      let DestBuyPt = sDestBuyPt
      setLblDestBuyPt(sDestBuyPt)
      setValue(thisObj, 'txtCarrier', ClassData.mTransfer.mudtProps.frt_scac_id)
      setLblScac(sFrtScacId)
      setLblVendor(sFrtVendor)
      setLblEffectiveDate(sEffectiveDate)
      let TripID = dTripId
      setLblTripID(dTripId)

      hide(thisObj, 'btnComplete', false)
      disable(thisObj, 'btnComplete', false)

      let response2 = await StockTransferService.RetrievePremiumDeductionSheduleListDetails(DestBuyPt, shp_buy_pt, TransferType, TransferNum, TripID,sEffectiveDate, FrtAccrlComplete, sLeasedTrailerInd, dMileageOverride, 0, dTotalMileage, sRateBasis, dFrtRate, dTotalNetWt, dTotalLskWt, dTotalGrossWt, dTotalBasisGradeAmt, 0, 0, null, null, null, null, sFrtScacId, sFrtVendor,crop_year)
      let data2 = response2

      setValue(thisObj, 'txtShippedDate', sEffectiveDate) 
      setValue(thisObj, 'txtMiles', miles) 
      setValue(thisObj, 'txtFreightRate', `$${ClassData.mTransfer.mudtProps.freight_rate}`)
      setValue(thisObj, 'txtFreightAmount', `$${ClassData.mTransfer.mudtProps.freight_amt}`)
      setData(thisObj, "txtFreightAmount", `$${ClassData.mTransfer.mudtProps.freight_amt}`) 

      let transformedArray = [];
      if (data2 != undefined && data2 != null && data2.length > 0) {
        for (let i = 0; i < data2.length; i++) {
          setValue(thisObj, 'txtFreightWeight', data2[i].trip_weight)
          setValue(thisObj, 'txtTons', (data2[0].trip_weight / 2000).toFixed(2))
          setValue(thisObj, 'txtRatingOption', data2[i].rate_option)

          setValue(thisObj, 'txtFuelPct', !isNaN(data2[i].fuel_pct) ? Number(data2[i].fuel_pct).toFixed(2) + '%' : '')


          setValue(thisObj, 'txtTrailerLease', !isNaN(data2[i].lease_pct) ? Number(data2[i].lease_pct).toFixed(2) + '%' : '')

          let obj = {};
          if (data2[i].pd_code != '') {

            obj.txtcolEmpty = '';
            obj.chkbxCol = true;
            obj.txtcolPremDed = data2[i].pd_desc
            obj.txtcolAmount = data2[i].pamount
            transformedArray.push(obj);
          }

        }

        setValue(thisObj, 'gridPremsDeducts', transformedArray)
        await Calnetvalue()

      }
      setStockTransfer(ClassData.mTransfer)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in ViewPremiumsDeductionsFrtAccrl function"
        );
      }
    }
  }

  const Calnetvalue = async () => {
    try {
      let lblFreightAmount = getData(thisObj, "txtFreightAmount") || ''
      if (lblFreightAmount?.includes("$")) {
        lblFreightAmount = lblFreightAmount.replace(/\$/g, '')
      }
      if (!isNaN(lblFreightAmount)) {
        lblFreightAmount = Number(lblFreightAmount).toFixed(2)
      } else {
        lblFreightAmount = Number(lblFreightAmount).toFixed(2)
      }
      let lblNetDCaption = 0
      let lblNetPCaption = 0
      let lblNetAmountCaption = Number(lblFreightAmount).toFixed(2)
      setValue(thisObj, "txtAccrualAmount", lblNetAmountCaption)
      let gridValue = getValue(thisObj, "gridPremsDeducts")

      let colAmt = 0
      for (let i = 0; i < gridValue?.length; i++) {
        if (gridValue[i]?.chkbxCol == true) {
          colAmt = Number(colAmt) + Number(gridValue[i]?.txtcolAmount)
        }
        if (Number(gridValue[i]?.txtcolAmount) < 0) {
          lblNetDCaption = Number(lblNetDCaption) + Number(gridValue[i]?.txtcolAmount)
          
        } else {
          lblNetPCaption = Number(lblNetPCaption) + Number(gridValue[i]?.txtcolAmount)
    
        }
      }
      setValue(thisObj, "txtAccrualAmount", (Number(lblFreightAmount) + Number(colAmt)).toFixed(2))

      let mTransfer={}
      mTransfer.frt_deduct_amt=(lblNetDCaption)
      mTransfer.frt_premium_amt=(lblNetPCaption)

      setData(thisObj,"DatafromPremiumDeductionFRTAccrl",mTransfer)

      let dataComplete={}
      dataComplete.d_amt=lblNetDCaption
      dataComplete.p_amt=lblNetPCaption
      dataComplete.accuralAmount = Number(Number(lblNetAmountCaption) + Number(lblNetPCaption) + Number(lblNetDCaption)).toFixed(2)
      dataComplete.freightAmount=lblFreightAmount
      setData(thisObj,"dataComplete",dataComplete)

      setLblNetD(lblNetDCaption)
      setLblNetP(lblNetPCaption)
      
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during Calnetvalue: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  let onbtnCompleteClick = async () => {
    try {
      let s = {};
      let LdblP = 0;
      let LdblD = 0;

      let pdsData = "";
      let gridValue = getValue(thisObj, "gridPremsDeducts")
      let msXferMaster = getData(thisObj, 'msXferMaster')

      for (let i = 0; i < gridValue.length; i++) {

        let col1 = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "chkbxCol", i)
        let col5 = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolVendor", i)
        let col65 = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolPdCode", i)
        let col66 = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolPdInd", i)
        let col45 = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolPct", i)
        let col25 = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolAmnt", i)
        let col10 = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolPayeeVendr", i)
        let col11 = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolPayeeRemit", i)
        let col67 = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolRateTyp", i)
        let col92 = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolPS", i)
        let col96 = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolUseExpFuel", i)

        if (col1 == true) {
          if (col5 != "") {
            pdsData += col65 + "," + col5 + "," + "000" + "," + col45 + "," + col25 + "," + "0" + ',' + col10 + "," + col11 + "," + col67 + "," + col92 + "," + col96 + "|"

            if (col66 == "P") {
              LdblP = LdblP + col25;
            }
            if (col66 == "D") {
              LdblD = LdblD + col25;
            }
          }
        }
      }

      let fuel_pct = getValue(thisObj, 'txtFuelPct')
      fuel_pct = fuel_pct.slice(0, -1)

      let lease_pct = getValue(thisObj, "txtTrailerLease")
      lease_pct = lease_pct.slice(0, -1)

      let frt_source = getValue(thisObj, "txtRatingOption")
      let fuel_milerate = getValue(thisObj, "txtFuelPct")
      fuel_milerate = fuel_milerate.slice(0, -1)

      let dateTime = lblEffectiveDate
      dateTime = new Date(new Date(dateTime).getTime() - (new Date(dateTime).getTimezoneOffset() * 60000)).toISOString()

      s = {
        "comp_id": comp_id,
        "crop_year": Number(cropYear),
        "new_crop_year": 0,
        "user_id": user_id,
        "shp_buy_pt": lblshp_buy_pt,
        "transfer_type": lblTransferType,
        "transfer_num": lblTransferNum.toString(),
        "dest_buy_pt": lblDestBuyPt,
        "scac": lblScac,
        "vendor": lblVendor,
        "trip_id": Number(lblTripID),
        "effective_date": dateTime,
        "status": "I",
        "frt_rate": Number(getValue(thisObj, 'txtFreightRate')),
        "rate_basis": "TON",
        "frt_amt": Number(getValue(thisObj, 'txtFreightAmount')),
        "d_amt": Number(lblNetD),
        "p_amt": Number(lblNetP),
        "net_amt": Number(getValue(thisObj, "txtAccrualAmount")),
        "tot_frt_wt": Number(getValue(thisObj, "txtFreightWeight")),
        "pds_master": pdsData,
        "xfer_master": msXferMaster,
        "frt_fuel_pct": Number(fuel_pct),
        "frt_lease_pct": Number(lease_pct),
        "frt_rate_source": frt_source,
        "frt_fuel_milerate": 0 
      }

      let response = await StockTransferService.CreateFrieghtAccural(s);

      if (response.status != 200) {
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
      }
      else {
        alert("Freight Accrual has been completed.")
      }

      if (lblTripID > 0) {
        setData(thisObj, 'FrtAccrlCompleteCaption', "Y")
      }
      else {
        let mTransfer={}
        mTransfer.frt_accrl_complete="Y"
        mTransfer.freight_rate=(getValue(thisObj, 'txtFreightRate'))
        mTransfer.frt_rate_basis=("TON")
        mTransfer.freight_amt=getValue(_kaledo.thisObj, 'txtFreightAmount')
        mTransfer.frt_deduct_amt=(lblNetD)
        mTransfer.frt_premium_amt=(lblNetP)
        mTransfer.frt_proceeds_amt=(getValue(thisObj, "txtAccrualAmount"))
        setData(thisObj,"DatafromPremiumDeductionFRTAccrl",mTransfer)
      }
      setData(thisObj, "closingOfPremiumDeductionsFrtAccrl", true)
      document.getElementById("StockTransfer_PremiumDeductionsFrtAccrlPopUp").childNodes[0].click();
      
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during Complete button: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnCompleteClick = onbtnCompleteClick

  let stockTransferSaveComplete = async(dataForComplete) =>{
    try {
      let s = {};
      let LdblP = 0;
      let LdblD = 0;

      let pdsData = "";
      let gridValue = dataForComplete.transformedArray
      let msXferMaster = dataForComplete.msXferMaster

      for (let i = 0; i < gridValue.length; i++) {

        let col1 =  gridValue[i].chkbxCol
        let col5 =  gridValue[i].txtcolVendor
        let col65 = gridValue[i].txtcolPdCode
        let col66 = gridValue[i].txtcolPdInd
        let col45 = gridValue[i].txtcolPct
        let col25 = gridValue[i].txtcolAmnt
        let col10 = gridValue[i].txtcolPayeeVendr
        let col11 = gridValue[i].txtcolPayeeRemit
        let col67 = gridValue[i].txtcolRateTyp
        let col92 = gridValue[i].txtcolPS
        let col96 = gridValue[i].txtcolUseExpFuel

        if (col1 == true) {
          if (col5 != "") {
            pdsData += col65 + "," + col5 + "," + "000" + "," + col45 + "," + col25 + "," + "0" + ',' + col10 + "," + col11 + "," + col67 + "," + col92 + "," + col96 + "|"

            if (col66 == "P") {
              LdblP = LdblP + col25;
            }
            if (col66 == "D") {
              LdblD = LdblD + col25;
            }
          }
        }
      }

      let fuel_pct = dataForComplete.fuelPct
      fuel_pct = fuel_pct.slice(0, -1)

      let lease_pct = dataForComplete.trailerLease
      lease_pct = lease_pct.slice(0, -1)

      let frt_source = dataForComplete.ratingOption

      let fuel_milerate = dataForComplete.fuelPct
      fuel_milerate = fuel_milerate.slice(0, -1)

      let dateTime = dataForComplete.effective_date
      dateTime = new Date(new Date(dateTime).getTime() - (new Date(dateTime).getTimezoneOffset() * 60000)).toISOString()

      s = {
        "comp_id": comp_id,
        "crop_year": Number(cropYear),
        "new_crop_year": 0,
        "user_id": user_id,
        "shp_buy_pt": dataForComplete.shp_buy_pt,
        "transfer_type": dataForComplete.dataForComplete,
        "transfer_num": dataForComplete.transferNum,
        "dest_buy_pt": dataForComplete.dest_buy_pt,
        "scac": dataForComplete.scac,
        "vendor": dataForComplete.vendor,
        "trip_id": Number(dataForComplete.trip_id),
        "effective_date": dateTime,
        "status": "I",
        "frt_rate": Number(dataForComplete.frt_rate),
        "rate_basis": "TON",
        "frt_amt": Number(dataForComplete.frt_amt),
        "d_amt": Number(dataForComplete.d_amt),
        "p_amt": Number(dataForComplete.p_amt),
        "net_amt": Number(dataForComplete.net_amt),
        "tot_frt_wt": Number(dataForComplete.tot_frt_wt),
        "pds_master": pdsData,
        "xfer_master": msXferMaster,
        "frt_fuel_pct": Number(fuel_pct),
        "frt_lease_pct": Number(lease_pct),
        "frt_rate_source": frt_source,
        "frt_fuel_milerate": 0 
      }

      let response = await StockTransferService.CreateFrieghtAccural(s);

      if (response.status != 200) {
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
      }
      else {
        alert("Freight Accrual has been completed.")
      }

      if (dataForComplete.trip_id > 0) {
        setData(thisObj, 'FrtAccrlCompleteCaption', "Y")
      }
      else {
        let mTransfer={}
        mTransfer.frt_accrl_complete="Y"
        mTransfer.freight_rate=(dataForComplete.frt_rate)
        mTransfer.frt_rate_basis=("TON")
        mTransfer.freight_amt=(dataForComplete.freightAmount)
        mTransfer.frt_deduct_amt=(dataForComplete.d_amt)
        mTransfer.frt_premium_amt=(dataForComplete.p_amt)
        mTransfer.frt_proceeds_amt=(dataForComplete.net_amt)
        setData(thisObj,"DatafromPremiumDeductionFRTAccrl",mTransfer)
      }
      setData(thisObj, "closingOfPremiumDeductionsFrtAccrl", true)
      setLoading(false)
      document.getElementById("StockTransfer_PremiumDeductionsFrtAccrlPopUp").childNodes[0].click();

    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during Complete button: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  const onbtnReverseFrtAccrlClick = () => {
    try {

      hide(thisObj, "btnReverseFrt", false)
      hide(thisObj, "btmViewTransferDetail", false)
      setData(thisObj, 'StockTransfer_ExternalFrtAccrlRev', true)
      setData(thisObj, "closingOfPremiumDeductionsFrtAccrl", true)
      setData(thisObj, 'OpenStockTransfer', true)
      document.getElementById("StockTransfer_PremiumDeductionsFrtAccrlPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnReverseFrtAccrlClick event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnReverseFrtAccrlClick = onbtnReverseFrtAccrlClick;

  const onbtmViewTransferDetailClick = () => {
    try {

      hide(thisObj, "btnReverseFrt", false)
      hide(thisObj, "btmViewTransferDetail", false)
    
      setData(thisObj, "closingOfPremiumDeductionsFrtAccrl", true)
      setData(thisObj, 'OpenStockTransfer', true)
      document.getElementById("StockTransfer_PremiumDeductionsFrtAccrlPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtmViewTransferDetailClick event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtmViewTransferDetailClick = onbtmViewTransferDetailClick;

  const Print = async () => {
    try {
      let CropYear = cropYear
      let shpLoc = lblshp_buy_pt
      let recLoc = lblDestBuyPt
      let transferNum = lblTransferNum
      let type = lblTransferType
      let tripNum = lblTripID || ""
      let shippedDate = getValue(thisObj, "txtShippedDate")
      let carrier = getValue(thisObj, "txtCarrier")
      let miles = getValue(thisObj, "txtMiles")
      let fuelPer = getValue(thisObj, "txtFuelPct")
      let trailerLease = getValue(thisObj, "txtTrailerLease")
      let ratingOption = getValue(thisObj, "txtRatingOption")
      let freightWeight = getValue(thisObj, "txtFreightWeight")
      let freightweight1 = getValue(thisObj, "txtTons")
      let freightRate = getValue(thisObj, "txtFreightRate")
      let freightAmount = getValue(thisObj, "txtFreightAmount")
      let totalAmout = 0
      let totalAmount1 = ''
      if (freightAmount?.includes("$")) {
        totalAmount1 = freightAmount.replace(/\$/g, '')
        totalAmout = Number(totalAmount1)
      }
      else {
        totalAmout = Number(freightAmount)
      }

      let flag = false
      let gridData = getValue(thisObj, "gridPremsDeducts")
      let tripId = Number(tripNum) > 0 ? tripNum : ""
      let Trip = ''
      if (tripId != '') {
        Trip = ` -Trip#${tripId} `
      }
      else {
        Trip = ""
      }

      var obj = new jsPDF();
      let message = [
        [[`Crop Year:${Number(CropYear)}`], [`- Shp Loc:${shpLoc} - Rec Loc:${recLoc} - Transfer#:${transferNum} - Type:${type} ${Trip} `], []], //- Trip#:${tripNum} removed temp.
        [[`Shipped Date:`], [shippedDate], []],
        [[`Carrier:`], [carrier], []],
        [[`Miles:`], [miles], []],
        [[`Fuel Percentage:`], [fuelPer], []],
        [[`Trailer Lease:`], [trailerLease], []],
        [[`Rating Option:`], [ratingOption], []],
        [[`Freight Weight:`], [freightWeight + " Lbs" + " / " + freightweight1 + " Tons "], []],
        [[`Freight Rate:`], [freightRate + "/" + "TON"], []],
        [[`Freight Amount:`], [], [totalAmout]],
        [[``], [], []],
        [[''], ['Premium/Deduction'], ['Amount']]
      ]

      for (var i = 0; i < gridData?.length; i++) {
        // if (gridData[i]?.chkbxCol == true) {
          totalAmout += Number(gridData[i].txtcolAmount)
          message.push(['', gridData[i].txtcolPremDed, gridData[i].txtcolAmount])
        // }
        
      }

      message.push(['', 'Accrual Amount:', totalAmout])

      obj.autoTable({
        startY: 15,
        startX: 20,
        styles: { fontSize: 7, lineWidth: 0, color: 10 },
        body: message,
        theme: 'plain',
        fontStyle: 'normal',
        head: [],
        tableLineWidth: 0.5,
        didParseCell: function (data) {
          if (['Shipped Date:', 'Carrier:', 'Miles:', 'Fuel Percentage:', 'Trailer Lease:', 'Rating Option:', 'Freight Weight:', 'Freight Rate:', 'Freight Amount:'].includes(data?.cell?.text[0])) {
            data.cell.styles.halign = 'right'
          }
          else if (data?.cell?.text[0] == 'Premium/Deduction') {
            data.cell.styles.halign = 'center'
          }
          else {
            data.cell.styles.halign = 'left'
          }
          if (data?.cell?.text[0] == 'Amount') {
            data.cell.styles.halign = 'right'
          }

          if (flag && !isNaN(data?.cell?.text[0])) {
            data.cell.styles.halign = 'right'
          }

          if (data?.cell?.text[0] == "Freight Amount:") {
            data.cell.styles.halign = 'right'
            flag = true
          }

          if (data?.cell?.text[0]?.includes("Accrual Amount:")) {
            data.row.cells[1].styles.fontStyle = 'bold'
            data.row.cells[2].styles.fontStyle = 'bold'
            data.row.cells[1].styles.halign = 'right'
            data.row.cells[2].styles.halign = 'right'

            data.row.cells[0].styles.fillColor = [224, 224, 224]
            data.row.cells[1].styles.fillColor = [224, 224, 224]
            data.row.cells[2].styles.fillColor = [224, 224, 224]
          }

          if (data?.cell?.text[0]?.slice(0, 9) == 'Crop Year') {
            data.row.cells[0].styles.fontStyle = 'bold'
            data.row.cells[1].styles.fontStyle = 'bold'
            data.row.cells[0].styles.fontSize = 7
            data.row.cells[1].styles.fontSize = 7
          }
        },
        columnStyles: {
          0: { columnWidth: 30 },
          1: { columnWidth: 90 },
          2: { columnWidth: 62 }
        }
      })
      let pageCount = obj.internal.getNumberOfPages();
      for (let w = 1; w <= pageCount; w++) {
        obj.setPage(w);
        obj.setFontSize(10);
        obj.text('' + '  Freight Accrual Premiums/Deductions', 5, obj.internal.pageSize.height - 10);
        obj.text('', obj.internal.pageSize.width - 10, obj.internal.pageSize.height - 10);
      }
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          showMessage(thisObj, "Please enter the file name!", false)
        }
      }
      else {
        alert("Please try different file name", false)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:Print event:Click"
        );
      }
      return false;
    }
  }

  const onbtnPrintClick = async () => {
    try {
      await Print()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnPrintClick event:Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnPrintClick = onbtnPrintClick

  const ontxtcolAmountBlur = async (event) => {
    try {
      let nameEvent = event.target.name
      let row = Number(nameEvent.split(".")[1])

      let chkboxValue = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "chkbxCol", row)
      let col3Value = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolAmount", row)
      let col92Value = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolPS", row)
      let col45Value = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolPct", row)

      Calnetvalue()

      let premiumDeductionSingValue = await GetPremiumDeductSign(col3Value, row)

      setGridValue(thisObj, "gridPremsDeducts", "txtcolAmount", row, premiumDeductionSingValue)

      if (col92Value == "P") {
        let col25Value = col3Value * (col45Value * 0.01)
        setGridValue(thisObj, "gridPremsDeducts", "txtcolAmnt", row, col25Value)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error Occurred during the grid After Edit function"
        );
      }
      return false;
    }
    return true;
  }
  thisObj.ontxtcolAmountBlur = ontxtcolAmountBlur

  const onchkbxColChange = () => {
    Calnetvalue()
  }
  thisObj.onchkbxColChange = onchkbxColChange

  const onbtnCancelClick = () => {
    try {
      setData(thisObj, "closingOfPremiumDeductionsFrtAccrl", true)
      document.getElementById("StockTransfer_PremiumDeductionsFrtAccrlPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnCancelClick event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // strValue--> value that we put in column to edit in last one 
  // n which number of row we are editing need to integrate once the edit functionality for grid will in progress.
  const GetPremiumDeductSign = async (strValue, n) => {
    try {
      let GetPremiumDeductSign = ''
      let strPremDeduct = "";
      if (strValue != undefined && strValue != null && Number(strValue) == 0) {
        return GetPremiumDeductSign; //need to check
      }
      strPremDeduct = getGridValue(_kaledo.thisObj, "gridPremsDeducts", "txtcolPdInd", n)
      switch (strPremDeduct) {
        case "D":
          if (strValue?.includes("-")) {
            GetPremiumDeductSign = parseFloat(strValue)
          } else if (strValue?.includes("+")) {
            GetPremiumDeductSign = parseFloat(strValue)
          } else {
            GetPremiumDeductSign = parseFloat("-" + strValue)
          }
          break;
        case "P":
          GetPremiumDeductSign = parseFloat(strValue);
          break;
      }
      return GetPremiumDeductSign;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during GetPremiumDeductSign: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  const ExportToExcel = async () => {
    try {
      let CropYear = cropYear
      let shpLoc = lblshp_buy_pt
      let recLoc = lblDestBuyPt
      let transferNum = lblTransferNum
      let type = lblTransferType
      let tripNum = lblTripID || ""
      let shippedDate = getValue(thisObj, "txtShippedDate")
      let carrier = getValue(thisObj, "txtCarrier")
      let miles = getValue(thisObj, "txtMiles")
      let fuelPer = getValue(thisObj, "txtFuelPct")
      let trailerLease = getValue(thisObj, "txtTrailerLease")
      let ratingOption = getValue(thisObj, "txtRatingOption")
      let freightWeight = getValue(thisObj, "txtFreightWeight")
      let freightweight1 = getValue(thisObj, "txtTons")
      let freightRate = getValue(thisObj, "txtFreightRate")
      let freightAmount = getValue(thisObj, "txtFreightAmount")
      let totalAmout = 0
      let totalAmount1 = ''
      if (freightAmount?.includes("$")) {
        totalAmount1 = freightAmount.replace(/\$/g, '')
        totalAmout = Number(totalAmount1)
      }
      else {
        totalAmout = Number(freightAmount)
      }

      let gridData = getValue(thisObj, "gridPremsDeducts")
      let tripId = Number(tripNum) > 0 ? tripNum : ""
      let Trip = ''
      if (tripId != '') {
        Trip = ` - Trip# ${tripId} `
      }
      else {
        Trip = ""
      }

      let excelData = [
        {
          'A': `Crop Year: ${CropYear}`,
          'B': '',
          'C': `- Shp Loc:${shpLoc} - Rec Loc:${recLoc} - Transfer#:${transferNum} - Type:${type} ${Trip}`,
          'D': ''
        },
        {
          'A': '',
          'B': '',
          'C': '',
          'D': ''
        },
        {
          'A': 'Shipped Date:',
          'B': '',
          'C': shippedDate,
          'D': ''
        },
        {
          'A': 'Carrier:',
          'B': '',
          'C': carrier,
          'D': ''
        },
        {
          'A': 'Miles:',
          'B': '',
          'C': miles,
          'D': ''
        },
        {
          'A': 'Fuel Percentage:',
          'B': '',
          'C': fuelPer,
          'D': ''
        },
        {
          'A': 'Trailer Lease:',
          'B': '',
          'C': trailerLease,
          'D': ''
        },
        {
          'A': 'Rating Option:',
          'B': '',
          'C': ratingOption,
          'D': ''
        },
        {
          'A': 'Freight Weight:',
          'B': '',
          'C': freightWeight + " Lbs" + " / " + freightweight1 + " Tons ",
          'D': ''
        },
        {
          'A': 'Freight Rate:',
          'B': '',
          'C': freightRate + " / " + "TON",
          'D': ''
        },
        {
          'A': 'Freight Amount:',
          'B': '',
          'C': '',
          'D': Number(totalAmout)
        },
        {
          'A': '',
          'B': '',
          'C': '',
          'D': ''
        }
      ]

      excelData.push({
        'A': '',
        'B': '',
        'C': 'Premium/Deduction',
        'D': 'Amount'
      })

      for (var i = 0; i < gridData?.length; i++) {
        if (gridData[i]?.chkbxCol == true) {
          totalAmout += ExcelNumericDataConvert(gridData[i].txtcolAmount, [], [2])
          excelData.push({
          'A': '',
          'B': '',
          'C': gridData[i].txtcolPremDed,
          'D': ExcelNumericDataConvert(gridData[i].txtcolAmount, [], [2])
        })
      }

      }

      excelData.push({
        'A': '',
        'B': '',
        'C': 'Accrual Amount:',
        'D': totalAmout
      })

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const ws = XLSX.utils.json_to_sheet(excelData, { skipHeader: true });

      var wscols = [
        { width: 20 },
        { width: 2 },
        { width: 55 },
        { width: 8 }
      ];
      ws['!cols'] = wscols;

      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
      const dataBlob = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(dataBlob, `Frt_Accrl_Prem_Deduct_${lblTransferNum}.xlsx`);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:ExportToExcel event:Click"
        );
      }
      return false;
    }
  }

  const onbtnExportToExcelClick = async () => {
    try {
      await ExportToExcel()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtnExportToExcelClick event:Click"
        );
      }
      return false;
    }
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PremiumDeductionsFrtAccrl*/}

              {/* END_USER_CODE-USER_BEFORE_PremiumDeductionsFrtAccrl*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPremiumDeductionsFrtAccrl*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPremiumDeductionsFrtAccrl*/}

              <GroupBoxWidget
                conf={state.grpbxPremiumDeductionsFrtAccrl}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails1*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails1*/}

                <GroupBoxWidget conf={state.grpbxDetails1} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtShippedDate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtShippedDate*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtShippedDate}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtShippedDate*/}

                  {/* END_USER_CODE-USER_AFTER_txtShippedDate*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCarrier*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCarrier*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCarrier}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCarrier*/}

                  {/* END_USER_CODE-USER_AFTER_txtCarrier*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMiles*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMiles*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMiles}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMiles*/}

                  {/* END_USER_CODE-USER_AFTER_txtMiles*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFuelPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFuelPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFuelPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFuelPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtFuelPct*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTrailerLease*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTrailerLease*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTrailerLease}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTrailerLease*/}

                  {/* END_USER_CODE-USER_AFTER_txtTrailerLease*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails1*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails1*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails2*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails2*/}

                <GroupBoxWidget conf={state.grpbxDetails2} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtRatingOption*/}

                  {/* END_USER_CODE-USER_BEFORE_txtRatingOption*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtRatingOption}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtRatingOption*/}

                  {/* END_USER_CODE-USER_AFTER_txtRatingOption*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFreightWeight*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFreightWeight*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFreightWeight}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFreightWeight*/}

                  {/* END_USER_CODE-USER_AFTER_txtFreightWeight*/}
                  {/* START_USER_CODE-USER_BEFORE_lblLbsPer*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLbsPer*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblLbsPer}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLbsPer*/}

                  {/* END_USER_CODE-USER_AFTER_lblLbsPer*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTons*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTons*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTons}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTons*/}

                  {/* END_USER_CODE-USER_AFTER_txtTons*/}
                  {/* START_USER_CODE-USER_BEFORE_lblTons*/}

                  {/* END_USER_CODE-USER_BEFORE_lblTons*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblTons}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblTons*/}

                  {/* END_USER_CODE-USER_AFTER_lblTons*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFreightRate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFreightRate*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFreightRate}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFreightRate*/}

                  {/* END_USER_CODE-USER_AFTER_txtFreightRate*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPerTon*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPerTon*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPerTon}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPerTon*/}

                  {/* END_USER_CODE-USER_AFTER_lblPerTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFreightAmount*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFreightAmount*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFreightAmount}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFreightAmount*/}

                  {/* END_USER_CODE-USER_AFTER_txtFreightAmount*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails2*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails2*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxPremsDeducts*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPremsDeducts*/}

                <GroupBoxWidget
                  conf={state.grpbxPremsDeducts}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblPremDeduct*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPremDeduct*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPremDeduct}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPremDeduct*/}

                  {/* END_USER_CODE-USER_AFTER_lblPremDeduct*/}
                  {/* START_USER_CODE-USER_BEFORE_gridPremsDeducts*/}

                  {/* END_USER_CODE-USER_BEFORE_gridPremsDeducts*/}

                  <GridWidget
                    conf={state.gridPremsDeducts}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridPremsDeducts}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    refObject={thisObj}
                    setFieldValue={setFieldValue}
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridPremsDeducts*/}

                  {/* END_USER_CODE-USER_AFTER_gridPremsDeducts*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAccrualAmount*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAccrualAmount*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAccrualAmount}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAccrualAmount*/}

                  {/* END_USER_CODE-USER_AFTER_txtAccrualAmount*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPremsDeducts*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPremsDeducts*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndcntnrActions*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndcntnrActions*/}

                <CommandContainerWidget
                  conf={state.cmmndcntnrActions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                  <ButtonWidget
                    conf={state.btnPrint}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                  {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                  {/* START_USER_CODE-USER_BEFORE_btnComplete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnComplete*/}

                  <ButtonWidget
                    conf={state.btnComplete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnComplete*/}

                  {/* END_USER_CODE-USER_AFTER_btnComplete*/}
                  {/* START_USER_CODE-USER_BEFORE_btnReverseFrtAccrl*/}

                  {/* END_USER_CODE-USER_BEFORE_btnReverseFrtAccrl*/}

                  <ButtonWidget
                    conf={state.btnReverseFrtAccrl}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnReverseFrtAccrl*/}

                  {/* END_USER_CODE-USER_AFTER_btnReverseFrtAccrl*/}
                  {/* START_USER_CODE-USER_BEFORE_btmViewTransferDetail*/}

                  {/* END_USER_CODE-USER_BEFORE_btmViewTransferDetail*/}

                  <ButtonWidget
                    conf={state.btmViewTransferDetail}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btmViewTransferDetail*/}

                  {/* END_USER_CODE-USER_AFTER_btmViewTransferDetail*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                  <ButtonWidget
                    conf={state.btnCancel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                  {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                  {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  <ButtonWidget
                    conf={state.btnExportToExcel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndcntnrActions*/}

                {/* END_USER_CODE-USER_AFTER_cmmndcntnrActions*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPremiumDeductionsFrtAccrl*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPremiumDeductionsFrtAccrl*/}

              {/* START_USER_CODE-USER_AFTER_PremiumDeductionsFrtAccrl*/}

              {/* END_USER_CODE-USER_AFTER_PremiumDeductionsFrtAccrl*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(StockTransfer_PremiumDeductionsFrtAccrl);
