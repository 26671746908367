/* eslint-disable*/
import React from "react";
import Settlements_KCMOdataProfile from "./KCMOdataProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("KCMOdataProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_KCMOdataProfile />);
    });
  });
  afterEach(cleanup);
  test("is KCMOdataProfile Loads Successfully", () => {
    expect(screen.getByText("KCMOdataProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for KCMOdataProfile", () => {
    // START_USER_CODE-USER_KCMOdataProfile_Custom_Test_Case
    // END_USER_CODE-USER_KCMOdataProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_KCMOdataProfile />);
    });
  });
  afterEach(cleanup);
  test("buttonwidget5(Button Widget) Test Cases", async () => {
    const buttonwidget5 = screen.getByTestId("buttonwidget5");
    expect(buttonwidget5).toBeInTheDocument;
    expect(buttonwidget5.textContent).toEqual(
      t("Settlements:KCMOdataProfile_buttonwidget5")
    );
  });
  test("Custom Test Cases for buttonwidget5", () => {
    // START_USER_CODE-USER_buttonwidget5_TEST
    // END_USER_CODE-USER_buttonwidget5_TEST
  });
  test("groupboxwidget1(GroupBox Widget) Test Cases", async () => {
    const groupboxwidget1 = screen.getByTestId("groupboxwidget1");
    expect(groupboxwidget1.tagName).toBe("BUTTON");
    expect(groupboxwidget1.type).toBe("button");
    expect(groupboxwidget1.classList).toContain("btn");
    expect(groupboxwidget1.textContent).toEqual(
      t("Settlements:KCMOdataProfile_groupboxwidget1")
    );
  });
  test("Custom Test Cases for groupboxwidget1", () => {
    // START_USER_CODE-USER_groupboxwidget1_TEST
    // END_USER_CODE-USER_groupboxwidget1_TEST
  });
  test("grpbxGrades(GroupBox Widget) Test Cases", async () => {
    const grpbxGrades = screen.getByTestId("grpbxGrades");
    expect(grpbxGrades.tagName).toBe("BUTTON");
    expect(grpbxGrades.type).toBe("button");
    expect(grpbxGrades.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxGrades", () => {
    // START_USER_CODE-USER_grpbxGrades_TEST
    // END_USER_CODE-USER_grpbxGrades_TEST
  });
  test("grpbxKCMOdataProfile(GroupBox Widget) Test Cases", async () => {
    const grpbxKCMOdataProfile = screen.getByTestId("grpbxKCMOdataProfile");
    expect(grpbxKCMOdataProfile.tagName).toBe("BUTTON");
    expect(grpbxKCMOdataProfile.type).toBe("button");
    expect(grpbxKCMOdataProfile.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxKCMOdataProfile", () => {
    // START_USER_CODE-USER_grpbxKCMOdataProfile_TEST
    // END_USER_CODE-USER_grpbxKCMOdataProfile_TEST
  });
  test("grpbxValues(GroupBox Widget) Test Cases", async () => {
    const grpbxValues = screen.getByTestId("grpbxValues");
    expect(grpbxValues.tagName).toBe("BUTTON");
    expect(grpbxValues.type).toBe("button");
    expect(grpbxValues.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxValues", () => {
    // START_USER_CODE-USER_grpbxValues_TEST
    // END_USER_CODE-USER_grpbxValues_TEST
  });
  test("lblGrades(Label Widget) Test Cases", async () => {
    const lblGrades = screen.getByTestId("lblGrades");
    expect(lblGrades.tagName).toBe("LABEL");
    expect(lblGrades.classList).toContain("form-label");
    expect(lblGrades.textContent).toEqual(
      t("Settlements:KCMOdataProfile_lblGrades")
    );
  });
  test("Custom Test Cases for lblGrades", () => {
    // START_USER_CODE-USER_lblGrades_TEST
    // END_USER_CODE-USER_lblGrades_TEST
  });
  test("lblPct1(Label Widget) Test Cases", async () => {
    const lblPct1 = screen.getByTestId("lblPct1");
    expect(lblPct1.tagName).toBe("LABEL");
    expect(lblPct1.classList).toContain("form-label");
    expect(lblPct1.textContent).toEqual(
      t("Settlements:KCMOdataProfile_lblPct1")
    );
  });
  test("Custom Test Cases for lblPct1", () => {
    // START_USER_CODE-USER_lblPct1_TEST
    // END_USER_CODE-USER_lblPct1_TEST
  });
  test("lblPct2(Label Widget) Test Cases", async () => {
    const lblPct2 = screen.getByTestId("lblPct2");
    expect(lblPct2.tagName).toBe("LABEL");
    expect(lblPct2.classList).toContain("form-label");
    expect(lblPct2.textContent).toEqual(
      t("Settlements:KCMOdataProfile_lblPct2")
    );
  });
  test("Custom Test Cases for lblPct2", () => {
    // START_USER_CODE-USER_lblPct2_TEST
    // END_USER_CODE-USER_lblPct2_TEST
  });
  test("lblValues(Label Widget) Test Cases", async () => {
    const lblValues = screen.getByTestId("lblValues");
    expect(lblValues.tagName).toBe("LABEL");
    expect(lblValues.classList).toContain("form-label");
    expect(lblValues.textContent).toEqual(
      t("Settlements:KCMOdataProfile_lblValues")
    );
  });
  test("Custom Test Cases for lblValues", () => {
    // START_USER_CODE-USER_lblValues_TEST
    // END_USER_CODE-USER_lblValues_TEST
  });
  test("txt1007Num(Textbox Widget) Test Cases", async () => {
    const txt1007Num = screen.getByTestId("txt1007Num");
    expect(txt1007Num.tagName).toBe("INPUT");
    expect(txt1007Num.type).toBe("text");
    expect(txt1007Num.classList).toContain("textboxWidgetClass");
    expect(txt1007Num.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txt1007Num")
    );
    await act(async () => {
      userEvent.type(txt1007Num, "123");
    });
    expect(txt1007Num.getAttribute("value")).toBe("");
    expect(txt1007Num.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txt1007Num", () => {
    // START_USER_CODE-USER_txt1007Num_TEST
    // END_USER_CODE-USER_txt1007Num_TEST
  });
  test("txtAuthCode(Textbox Widget) Test Cases", async () => {
    const txtAuthCode = screen.getByTestId("txtAuthCode");
    expect(txtAuthCode.tagName).toBe("INPUT");
    expect(txtAuthCode.type).toBe("text");
    expect(txtAuthCode.classList).toContain("textboxWidgetClass");
    expect(txtAuthCode.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtAuthCode")
    );
    await act(async () => {
      userEvent.type(txtAuthCode, "123");
    });
    expect(txtAuthCode.getAttribute("value")).toBe("");
    expect(txtAuthCode.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtAuthCode", () => {
    // START_USER_CODE-USER_txtAuthCode_TEST
    // END_USER_CODE-USER_txtAuthCode_TEST
  });
  test("txtConcealedRMD(Textbox Widget) Test Cases", async () => {
    const txtConcealedRMD = screen.getByTestId("txtConcealedRMD");
    expect(txtConcealedRMD.tagName).toBe("INPUT");
    expect(txtConcealedRMD.type).toBe("text");
    expect(txtConcealedRMD.classList).toContain("textboxWidgetClass");
    expect(txtConcealedRMD.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtConcealedRMD")
    );
    await act(async () => {
      userEvent.type(txtConcealedRMD, "123");
    });
    expect(txtConcealedRMD.getAttribute("value")).toBe("");
    expect(txtConcealedRMD.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtConcealedRMD", () => {
    // START_USER_CODE-USER_txtConcealedRMD_TEST
    // END_USER_CODE-USER_txtConcealedRMD_TEST
  });
  test("txtCountyID(Textbox Widget) Test Cases", async () => {
    const txtCountyID = screen.getByTestId("txtCountyID");
    expect(txtCountyID.tagName).toBe("INPUT");
    expect(txtCountyID.type).toBe("text");
    expect(txtCountyID.classList).toContain("textboxWidgetClass");
    expect(txtCountyID.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtCountyID")
    );
    await act(async () => {
      userEvent.type(txtCountyID, "123");
    });
    expect(txtCountyID.getAttribute("value")).toBe("");
    expect(txtCountyID.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCountyID", () => {
    // START_USER_CODE-USER_txtCountyID_TEST
    // END_USER_CODE-USER_txtCountyID_TEST
  });
  test("txtCrkOrBrkShells(Textbox Widget) Test Cases", async () => {
    const txtCrkOrBrkShells = screen.getByTestId("txtCrkOrBrkShells");
    expect(txtCrkOrBrkShells.tagName).toBe("INPUT");
    expect(txtCrkOrBrkShells.type).toBe("text");
    expect(txtCrkOrBrkShells.classList).toContain("textboxWidgetClass");
    expect(txtCrkOrBrkShells.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtCrkOrBrkShells")
    );
    await act(async () => {
      userEvent.type(txtCrkOrBrkShells, "123");
    });
    expect(txtCrkOrBrkShells.getAttribute("value")).toBe("");
    expect(txtCrkOrBrkShells.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtCrkOrBrkShells", () => {
    // START_USER_CODE-USER_txtCrkOrBrkShells_TEST
    // END_USER_CODE-USER_txtCrkOrBrkShells_TEST
  });
  test("txtDamage(Textbox Widget) Test Cases", async () => {
    const txtDamage = screen.getByTestId("txtDamage");
    expect(txtDamage.tagName).toBe("INPUT");
    expect(txtDamage.type).toBe("text");
    expect(txtDamage.classList).toContain("textboxWidgetClass");
    expect(txtDamage.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtDamage")
    );
    await act(async () => {
      userEvent.type(txtDamage, "123");
    });
    expect(txtDamage.getAttribute("value")).toBe("");
    expect(txtDamage.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDamage", () => {
    // START_USER_CODE-USER_txtDamage_TEST
    // END_USER_CODE-USER_txtDamage_TEST
  });
  test("txtDiscoloredShells(Textbox Widget) Test Cases", async () => {
    const txtDiscoloredShells = screen.getByTestId("txtDiscoloredShells");
    expect(txtDiscoloredShells.tagName).toBe("INPUT");
    expect(txtDiscoloredShells.type).toBe("text");
    expect(txtDiscoloredShells.classList).toContain("textboxWidgetClass");
    expect(txtDiscoloredShells.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtDiscoloredShells")
    );
    await act(async () => {
      userEvent.type(txtDiscoloredShells, "123");
    });
    expect(txtDiscoloredShells.getAttribute("value")).toBe("");
    expect(txtDiscoloredShells.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtDiscoloredShells", () => {
    // START_USER_CODE-USER_txtDiscoloredShells_TEST
    // END_USER_CODE-USER_txtDiscoloredShells_TEST
  });
  test("txtELK(Textbox Widget) Test Cases", async () => {
    const txtELK = screen.getByTestId("txtELK");
    expect(txtELK.tagName).toBe("INPUT");
    expect(txtELK.type).toBe("text");
    expect(txtELK.classList).toContain("textboxWidgetClass");
    expect(txtELK.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtELK")
    );
    await act(async () => {
      userEvent.type(txtELK, "123");
    });
    expect(txtELK.getAttribute("value")).toBe("");
    expect(txtELK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtELK", () => {
    // START_USER_CODE-USER_txtELK_TEST
    // END_USER_CODE-USER_txtELK_TEST
  });
  test("txtFancy(Textbox Widget) Test Cases", async () => {
    const txtFancy = screen.getByTestId("txtFancy");
    expect(txtFancy.tagName).toBe("INPUT");
    expect(txtFancy.type).toBe("text");
    expect(txtFancy.classList).toContain("textboxWidgetClass");
    expect(txtFancy.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtFancy")
    );
    await act(async () => {
      userEvent.type(txtFancy, "123");
    });
    expect(txtFancy.getAttribute("value")).toBe("");
    expect(txtFancy.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFancy", () => {
    // START_USER_CODE-USER_txtFancy_TEST
    // END_USER_CODE-USER_txtFancy_TEST
  });
  test("txtFarmID(Textbox Widget) Test Cases", async () => {
    const txtFarmID = screen.getByTestId("txtFarmID");
    expect(txtFarmID.tagName).toBe("INPUT");
    expect(txtFarmID.type).toBe("text");
    expect(txtFarmID.classList).toContain("textboxWidgetClass");
    expect(txtFarmID.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtFarmID")
    );
    await act(async () => {
      userEvent.type(txtFarmID, "123");
    });
    expect(txtFarmID.getAttribute("value")).toBe("");
    expect(txtFarmID.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFarmID", () => {
    // START_USER_CODE-USER_txtFarmID_TEST
    // END_USER_CODE-USER_txtFarmID_TEST
  });
  test("txtFarmSuffixNum(Textbox Widget) Test Cases", async () => {
    const txtFarmSuffixNum = screen.getByTestId("txtFarmSuffixNum");
    expect(txtFarmSuffixNum.tagName).toBe("INPUT");
    expect(txtFarmSuffixNum.type).toBe("text");
    expect(txtFarmSuffixNum.classList).toContain("textboxWidgetClass");
    expect(txtFarmSuffixNum.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtFarmSuffixNum")
    );
    await act(async () => {
      userEvent.type(txtFarmSuffixNum, "123");
    });
    expect(txtFarmSuffixNum.getAttribute("value")).toBe("");
    expect(txtFarmSuffixNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFarmSuffixNum", () => {
    // START_USER_CODE-USER_txtFarmSuffixNum_TEST
    // END_USER_CODE-USER_txtFarmSuffixNum_TEST
  });
  test("txtFederalBuyingPoint(Textbox Widget) Test Cases", async () => {
    const txtFederalBuyingPoint = screen.getByTestId("txtFederalBuyingPoint");
    expect(txtFederalBuyingPoint.tagName).toBe("INPUT");
    expect(txtFederalBuyingPoint.type).toBe("text");
    expect(txtFederalBuyingPoint.classList).toContain("textboxWidgetClass");
    expect(txtFederalBuyingPoint.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtFederalBuyingPoint")
    );
    await act(async () => {
      userEvent.type(txtFederalBuyingPoint, "123");
    });
    expect(txtFederalBuyingPoint.getAttribute("value")).toBe("");
    expect(txtFederalBuyingPoint.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFederalBuyingPoint", () => {
    // START_USER_CODE-USER_txtFederalBuyingPoint_TEST
    // END_USER_CODE-USER_txtFederalBuyingPoint_TEST
  });
  test("txtFileNum(Textbox Widget) Test Cases", async () => {
    const txtFileNum = screen.getByTestId("txtFileNum");
    expect(txtFileNum.tagName).toBe("INPUT");
    expect(txtFileNum.type).toBe("text");
    expect(txtFileNum.classList).toContain("textboxWidgetClass");
    expect(txtFileNum.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtFileNum")
    );
    await act(async () => {
      userEvent.type(txtFileNum, "123");
    });
    expect(txtFileNum.getAttribute("value")).toBe("");
    expect(txtFileNum.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFileNum", () => {
    // START_USER_CODE-USER_txtFileNum_TEST
    // END_USER_CODE-USER_txtFileNum_TEST
  });
  test("txtFlavusDiscount(Textbox Widget) Test Cases", async () => {
    const txtFlavusDiscount = screen.getByTestId("txtFlavusDiscount");
    expect(txtFlavusDiscount.tagName).toBe("INPUT");
    expect(txtFlavusDiscount.type).toBe("text");
    expect(txtFlavusDiscount.classList).toContain("textboxWidgetClass");
    expect(txtFlavusDiscount.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtFlavusDiscount")
    );
    await act(async () => {
      userEvent.type(txtFlavusDiscount, "123");
    });
    expect(txtFlavusDiscount.getAttribute("value")).toBe("");
    expect(txtFlavusDiscount.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFlavusDiscount", () => {
    // START_USER_CODE-USER_txtFlavusDiscount_TEST
    // END_USER_CODE-USER_txtFlavusDiscount_TEST
  });
  test("txtForeignMaterial(Textbox Widget) Test Cases", async () => {
    const txtForeignMaterial = screen.getByTestId("txtForeignMaterial");
    expect(txtForeignMaterial.tagName).toBe("INPUT");
    expect(txtForeignMaterial.type).toBe("text");
    expect(txtForeignMaterial.classList).toContain("textboxWidgetClass");
    expect(txtForeignMaterial.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtForeignMaterial")
    );
    await act(async () => {
      userEvent.type(txtForeignMaterial, "123");
    });
    expect(txtForeignMaterial.getAttribute("value")).toBe("");
    expect(txtForeignMaterial.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtForeignMaterial", () => {
    // START_USER_CODE-USER_txtForeignMaterial_TEST
    // END_USER_CODE-USER_txtForeignMaterial_TEST
  });
  test("txtFreezeDamage(Textbox Widget) Test Cases", async () => {
    const txtFreezeDamage = screen.getByTestId("txtFreezeDamage");
    expect(txtFreezeDamage.tagName).toBe("INPUT");
    expect(txtFreezeDamage.type).toBe("text");
    expect(txtFreezeDamage.classList).toContain("textboxWidgetClass");
    expect(txtFreezeDamage.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtFreezeDamage")
    );
    await act(async () => {
      userEvent.type(txtFreezeDamage, "123");
    });
    expect(txtFreezeDamage.getAttribute("value")).toBe("");
    expect(txtFreezeDamage.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtFreezeDamage", () => {
    // START_USER_CODE-USER_txtFreezeDamage_TEST
    // END_USER_CODE-USER_txtFreezeDamage_TEST
  });
  test("txtGMAAmount(Textbox Widget) Test Cases", async () => {
    const txtGMAAmount = screen.getByTestId("txtGMAAmount");
    expect(txtGMAAmount.tagName).toBe("INPUT");
    expect(txtGMAAmount.type).toBe("text");
    expect(txtGMAAmount.classList).toContain("textboxWidgetClass");
    expect(txtGMAAmount.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtGMAAmount")
    );
    await act(async () => {
      userEvent.type(txtGMAAmount, "123");
    });
    expect(txtGMAAmount.getAttribute("value")).toBe("");
    expect(txtGMAAmount.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtGMAAmount", () => {
    // START_USER_CODE-USER_txtGMAAmount_TEST
    // END_USER_CODE-USER_txtGMAAmount_TEST
  });
  test("txtHandlerNum1(Textbox Widget) Test Cases", async () => {
    const txtHandlerNum1 = screen.getByTestId("txtHandlerNum1");
    expect(txtHandlerNum1.tagName).toBe("INPUT");
    expect(txtHandlerNum1.type).toBe("text");
    expect(txtHandlerNum1.classList).toContain("textboxWidgetClass");
    expect(txtHandlerNum1.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtHandlerNum1")
    );
    await act(async () => {
      userEvent.type(txtHandlerNum1, "123");
    });
    expect(txtHandlerNum1.getAttribute("value")).toBe("");
    expect(txtHandlerNum1.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHandlerNum1", () => {
    // START_USER_CODE-USER_txtHandlerNum1_TEST
    // END_USER_CODE-USER_txtHandlerNum1_TEST
  });
  test("txtHandlerNum2(Textbox Widget) Test Cases", async () => {
    const txtHandlerNum2 = screen.getByTestId("txtHandlerNum2");
    expect(txtHandlerNum2.tagName).toBe("INPUT");
    expect(txtHandlerNum2.type).toBe("text");
    expect(txtHandlerNum2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHandlerNum2, "123");
    });
    expect(txtHandlerNum2.getAttribute("value")).toBe("");
    expect(txtHandlerNum2.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHandlerNum2", () => {
    // START_USER_CODE-USER_txtHandlerNum2_TEST
    // END_USER_CODE-USER_txtHandlerNum2_TEST
  });
  test("txtHandlerNum3(Textbox Widget) Test Cases", async () => {
    const txtHandlerNum3 = screen.getByTestId("txtHandlerNum3");
    expect(txtHandlerNum3.tagName).toBe("INPUT");
    expect(txtHandlerNum3.type).toBe("text");
    expect(txtHandlerNum3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtHandlerNum3, "123");
    });
    expect(txtHandlerNum3.getAttribute("value")).toBe("");
    expect(txtHandlerNum3.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHandlerNum3", () => {
    // START_USER_CODE-USER_txtHandlerNum3_TEST
    // END_USER_CODE-USER_txtHandlerNum3_TEST
  });
  test("txtHulls(Textbox Widget) Test Cases", async () => {
    const txtHulls = screen.getByTestId("txtHulls");
    expect(txtHulls.tagName).toBe("INPUT");
    expect(txtHulls.type).toBe("text");
    expect(txtHulls.classList).toContain("textboxWidgetClass");
    expect(txtHulls.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtHulls")
    );
    await act(async () => {
      userEvent.type(txtHulls, "123");
    });
    expect(txtHulls.getAttribute("value")).toBe("");
    expect(txtHulls.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtHulls", () => {
    // START_USER_CODE-USER_txtHulls_TEST
    // END_USER_CODE-USER_txtHulls_TEST
  });
  test("txtKernelValue(Textbox Widget) Test Cases", async () => {
    const txtKernelValue = screen.getByTestId("txtKernelValue");
    expect(txtKernelValue.tagName).toBe("INPUT");
    expect(txtKernelValue.type).toBe("text");
    expect(txtKernelValue.classList).toContain("textboxWidgetClass");
    expect(txtKernelValue.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtKernelValue")
    );
    await act(async () => {
      userEvent.type(txtKernelValue, "123");
    });
    expect(txtKernelValue.getAttribute("value")).toBe("");
    expect(txtKernelValue.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtKernelValue", () => {
    // START_USER_CODE-USER_txtKernelValue_TEST
    // END_USER_CODE-USER_txtKernelValue_TEST
  });
  test("txtLocation(Textbox Widget) Test Cases", async () => {
    const txtLocation = screen.getByTestId("txtLocation");
    expect(txtLocation.tagName).toBe("INPUT");
    expect(txtLocation.type).toBe("text");
    expect(txtLocation.classList).toContain("textboxWidgetClass");
    expect(txtLocation.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtLocation")
    );
    await act(async () => {
      userEvent.type(txtLocation, "123");
    });
    expect(txtLocation.getAttribute("value")).toBe("");
    expect(txtLocation.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLocation", () => {
    // START_USER_CODE-USER_txtLocation_TEST
    // END_USER_CODE-USER_txtLocation_TEST
  });
  test("txtLSK(Textbox Widget) Test Cases", async () => {
    const txtLSK = screen.getByTestId("txtLSK");
    expect(txtLSK.tagName).toBe("INPUT");
    expect(txtLSK.type).toBe("text");
    expect(txtLSK.classList).toContain("textboxWidgetClass");
    expect(txtLSK.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtLSK")
    );
    await act(async () => {
      userEvent.type(txtLSK, "123");
    });
    expect(txtLSK.getAttribute("value")).toBe("");
    expect(txtLSK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtLSK", () => {
    // START_USER_CODE-USER_txtLSK_TEST
    // END_USER_CODE-USER_txtLSK_TEST
  });
  test("txtMoisture(Textbox Widget) Test Cases", async () => {
    const txtMoisture = screen.getByTestId("txtMoisture");
    expect(txtMoisture.tagName).toBe("INPUT");
    expect(txtMoisture.type).toBe("text");
    expect(txtMoisture.classList).toContain("textboxWidgetClass");
    expect(txtMoisture.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtMoisture")
    );
    await act(async () => {
      userEvent.type(txtMoisture, "123");
    });
    expect(txtMoisture.getAttribute("value")).toBe("");
    expect(txtMoisture.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtMoisture", () => {
    // START_USER_CODE-USER_txtMoisture_TEST
    // END_USER_CODE-USER_txtMoisture_TEST
  });
  test("txtNetValue(Textbox Widget) Test Cases", async () => {
    const txtNetValue = screen.getByTestId("txtNetValue");
    expect(txtNetValue.tagName).toBe("INPUT");
    expect(txtNetValue.type).toBe("text");
    expect(txtNetValue.classList).toContain("textboxWidgetClass");
    expect(txtNetValue.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtNetValue")
    );
    await act(async () => {
      userEvent.type(txtNetValue, "123");
    });
    expect(txtNetValue.getAttribute("value")).toBe("");
    expect(txtNetValue.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetValue", () => {
    // START_USER_CODE-USER_txtNetValue_TEST
    // END_USER_CODE-USER_txtNetValue_TEST
  });
  test("txtNetWt(Textbox Widget) Test Cases", async () => {
    const txtNetWt = screen.getByTestId("txtNetWt");
    expect(txtNetWt.tagName).toBe("INPUT");
    expect(txtNetWt.type).toBe("text");
    expect(txtNetWt.classList).toContain("textboxWidgetClass");
    expect(txtNetWt.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtNetWt")
    );
    await act(async () => {
      userEvent.type(txtNetWt, "123");
    });
    expect(txtNetWt.getAttribute("value")).toBe("");
    expect(txtNetWt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtNetWt", () => {
    // START_USER_CODE-USER_txtNetWt_TEST
    // END_USER_CODE-USER_txtNetWt_TEST
  });
  test("txtOtherKernels(Textbox Widget) Test Cases", async () => {
    const txtOtherKernels = screen.getByTestId("txtOtherKernels");
    expect(txtOtherKernels.tagName).toBe("INPUT");
    expect(txtOtherKernels.type).toBe("text");
    expect(txtOtherKernels.classList).toContain("textboxWidgetClass");
    expect(txtOtherKernels.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtOtherKernels")
    );
    await act(async () => {
      userEvent.type(txtOtherKernels, "123");
    });
    expect(txtOtherKernels.getAttribute("value")).toBe("");
    expect(txtOtherKernels.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtOtherKernels", () => {
    // START_USER_CODE-USER_txtOtherKernels_TEST
    // END_USER_CODE-USER_txtOtherKernels_TEST
  });
  test("txtPeanutType(Textbox Widget) Test Cases", async () => {
    const txtPeanutType = screen.getByTestId("txtPeanutType");
    expect(txtPeanutType.tagName).toBe("INPUT");
    expect(txtPeanutType.type).toBe("text");
    expect(txtPeanutType.classList).toContain("textboxWidgetClass");
    expect(txtPeanutType.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtPeanutType")
    );
    await act(async () => {
      userEvent.type(txtPeanutType, "123");
    });
    expect(txtPeanutType.getAttribute("value")).toBe("");
    expect(txtPeanutType.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPeanutType", () => {
    // START_USER_CODE-USER_txtPeanutType_TEST
    // END_USER_CODE-USER_txtPeanutType_TEST
  });
  test("txtPurchaseWt(Textbox Widget) Test Cases", async () => {
    const txtPurchaseWt = screen.getByTestId("txtPurchaseWt");
    expect(txtPurchaseWt.tagName).toBe("INPUT");
    expect(txtPurchaseWt.type).toBe("text");
    expect(txtPurchaseWt.classList).toContain("textboxWidgetClass");
    expect(txtPurchaseWt.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtPurchaseWt")
    );
    await act(async () => {
      userEvent.type(txtPurchaseWt, "123");
    });
    expect(txtPurchaseWt.getAttribute("value")).toBe("");
    expect(txtPurchaseWt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtPurchaseWt", () => {
    // START_USER_CODE-USER_txtPurchaseWt_TEST
    // END_USER_CODE-USER_txtPurchaseWt_TEST
  });
  test("txtSC95Num(Textbox Widget) Test Cases", async () => {
    const txtSC95Num = screen.getByTestId("txtSC95Num");
    expect(txtSC95Num.tagName).toBe("INPUT");
    expect(txtSC95Num.type).toBe("text");
    expect(txtSC95Num.classList).toContain("textboxWidgetClass");
    expect(txtSC95Num.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtSC95Num")
    );
    await act(async () => {
      userEvent.type(txtSC95Num, "123");
    });
    expect(txtSC95Num.getAttribute("value")).toBe("");
    expect(txtSC95Num.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSC95Num", () => {
    // START_USER_CODE-USER_txtSC95Num_TEST
    // END_USER_CODE-USER_txtSC95Num_TEST
  });
  test("txtSegType(Textbox Widget) Test Cases", async () => {
    const txtSegType = screen.getByTestId("txtSegType");
    expect(txtSegType.tagName).toBe("INPUT");
    expect(txtSegType.type).toBe("text");
    expect(txtSegType.classList).toContain("textboxWidgetClass");
    expect(txtSegType.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtSegType")
    );
    await act(async () => {
      userEvent.type(txtSegType, "123");
    });
    expect(txtSegType.getAttribute("value")).toBe("");
    expect(txtSegType.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSegType", () => {
    // START_USER_CODE-USER_txtSegType_TEST
    // END_USER_CODE-USER_txtSegType_TEST
  });
  test("txtSegValue(Textbox Widget) Test Cases", async () => {
    const txtSegValue = screen.getByTestId("txtSegValue");
    expect(txtSegValue.tagName).toBe("INPUT");
    expect(txtSegValue.type).toBe("text");
    expect(txtSegValue.classList).toContain("textboxWidgetClass");
    expect(txtSegValue.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtSegValue")
    );
    await act(async () => {
      userEvent.type(txtSegValue, "123");
    });
    expect(txtSegValue.getAttribute("value")).toBe("");
    expect(txtSegValue.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSegValue", () => {
    // START_USER_CODE-USER_txtSegValue_TEST
    // END_USER_CODE-USER_txtSegValue_TEST
  });
  test("txtSeperseded1007Num(Textbox Widget) Test Cases", async () => {
    const txtSeperseded1007Num = screen.getByTestId("txtSeperseded1007Num");
    expect(txtSeperseded1007Num.tagName).toBe("INPUT");
    expect(txtSeperseded1007Num.type).toBe("text");
    expect(txtSeperseded1007Num.classList).toContain("textboxWidgetClass");
    expect(txtSeperseded1007Num.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtSeperseded1007Num")
    );
    await act(async () => {
      userEvent.type(txtSeperseded1007Num, "123");
    });
    expect(txtSeperseded1007Num.getAttribute("value")).toBe("");
    expect(txtSeperseded1007Num.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSeperseded1007Num", () => {
    // START_USER_CODE-USER_txtSeperseded1007Num_TEST
    // END_USER_CODE-USER_txtSeperseded1007Num_TEST
  });
  test("txtSMK(Textbox Widget) Test Cases", async () => {
    const txtSMK = screen.getByTestId("txtSMK");
    expect(txtSMK.tagName).toBe("INPUT");
    expect(txtSMK.type).toBe("text");
    expect(txtSMK.classList).toContain("textboxWidgetClass");
    expect(txtSMK.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtSMK")
    );
    await act(async () => {
      userEvent.type(txtSMK, "123");
    });
    expect(txtSMK.getAttribute("value")).toBe("");
    expect(txtSMK.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSMK", () => {
    // START_USER_CODE-USER_txtSMK_TEST
    // END_USER_CODE-USER_txtSMK_TEST
  });
  test("txtSoundSplits(Textbox Widget) Test Cases", async () => {
    const txtSoundSplits = screen.getByTestId("txtSoundSplits");
    expect(txtSoundSplits.tagName).toBe("INPUT");
    expect(txtSoundSplits.type).toBe("text");
    expect(txtSoundSplits.classList).toContain("textboxWidgetClass");
    expect(txtSoundSplits.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtSoundSplits")
    );
    await act(async () => {
      userEvent.type(txtSoundSplits, "123");
    });
    expect(txtSoundSplits.getAttribute("value")).toBe("");
    expect(txtSoundSplits.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSoundSplits", () => {
    // START_USER_CODE-USER_txtSoundSplits_TEST
    // END_USER_CODE-USER_txtSoundSplits_TEST
  });
  test("txtStateID(Textbox Widget) Test Cases", async () => {
    const txtStateID = screen.getByTestId("txtStateID");
    expect(txtStateID.tagName).toBe("INPUT");
    expect(txtStateID.type).toBe("text");
    expect(txtStateID.classList).toContain("textboxWidgetClass");
    expect(txtStateID.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtStateID")
    );
    await act(async () => {
      userEvent.type(txtStateID, "123");
    });
    expect(txtStateID.getAttribute("value")).toBe("");
    expect(txtStateID.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtStateID", () => {
    // START_USER_CODE-USER_txtStateID_TEST
    // END_USER_CODE-USER_txtStateID_TEST
  });
  test("txtStorageWt(Textbox Widget) Test Cases", async () => {
    const txtStorageWt = screen.getByTestId("txtStorageWt");
    expect(txtStorageWt.tagName).toBe("INPUT");
    expect(txtStorageWt.type).toBe("text");
    expect(txtStorageWt.classList).toContain("textboxWidgetClass");
    expect(txtStorageWt.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtStorageWt")
    );
    await act(async () => {
      userEvent.type(txtStorageWt, "123");
    });
    expect(txtStorageWt.getAttribute("value")).toBe("");
    expect(txtStorageWt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtStorageWt", () => {
    // START_USER_CODE-USER_txtStorageWt_TEST
    // END_USER_CODE-USER_txtStorageWt_TEST
  });
  test("txtSupportPct(Textbox Widget) Test Cases", async () => {
    const txtSupportPct = screen.getByTestId("txtSupportPct");
    expect(txtSupportPct.tagName).toBe("INPUT");
    expect(txtSupportPct.type).toBe("text");
    expect(txtSupportPct.classList).toContain("textboxWidgetClass");
    expect(txtSupportPct.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtSupportPct")
    );
    await act(async () => {
      userEvent.type(txtSupportPct, "123");
    });
    expect(txtSupportPct.getAttribute("value")).toBe("");
    expect(txtSupportPct.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtSupportPct", () => {
    // START_USER_CODE-USER_txtSupportPct_TEST
    // END_USER_CODE-USER_txtSupportPct_TEST
  });
  test("txtTareWt(Textbox Widget) Test Cases", async () => {
    const txtTareWt = screen.getByTestId("txtTareWt");
    expect(txtTareWt.tagName).toBe("INPUT");
    expect(txtTareWt.type).toBe("text");
    expect(txtTareWt.classList).toContain("textboxWidgetClass");
    expect(txtTareWt.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtTareWt")
    );
    await act(async () => {
      userEvent.type(txtTareWt, "123");
    });
    expect(txtTareWt.getAttribute("value")).toBe("");
    expect(txtTareWt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTareWt", () => {
    // START_USER_CODE-USER_txtTareWt_TEST
    // END_USER_CODE-USER_txtTareWt_TEST
  });
  test("txtTotalKernelsAndHulls(Textbox Widget) Test Cases", async () => {
    const txtTotalKernelsAndHulls = screen.getByTestId(
      "txtTotalKernelsAndHulls"
    );
    expect(txtTotalKernelsAndHulls.tagName).toBe("INPUT");
    expect(txtTotalKernelsAndHulls.type).toBe("text");
    expect(txtTotalKernelsAndHulls.classList).toContain("textboxWidgetClass");
    expect(txtTotalKernelsAndHulls.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtTotalKernelsAndHulls")
    );
    await act(async () => {
      userEvent.type(txtTotalKernelsAndHulls, "123");
    });
    expect(txtTotalKernelsAndHulls.getAttribute("value")).toBe("");
    expect(txtTotalKernelsAndHulls.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalKernelsAndHulls", () => {
    // START_USER_CODE-USER_txtTotalKernelsAndHulls_TEST
    // END_USER_CODE-USER_txtTotalKernelsAndHulls_TEST
  });
  test("txtTotalSMKandSS(Textbox Widget) Test Cases", async () => {
    const txtTotalSMKandSS = screen.getByTestId("txtTotalSMKandSS");
    expect(txtTotalSMKandSS.tagName).toBe("INPUT");
    expect(txtTotalSMKandSS.type).toBe("text");
    expect(txtTotalSMKandSS.classList).toContain("textboxWidgetClass");
    expect(txtTotalSMKandSS.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtTotalSMKandSS")
    );
    await act(async () => {
      userEvent.type(txtTotalSMKandSS, "123");
    });
    expect(txtTotalSMKandSS.getAttribute("value")).toBe("");
    expect(txtTotalSMKandSS.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtTotalSMKandSS", () => {
    // START_USER_CODE-USER_txtTotalSMKandSS_TEST
    // END_USER_CODE-USER_txtTotalSMKandSS_TEST
  });
  test("txtVehicleWt(Textbox Widget) Test Cases", async () => {
    const txtVehicleWt = screen.getByTestId("txtVehicleWt");
    expect(txtVehicleWt.tagName).toBe("INPUT");
    expect(txtVehicleWt.type).toBe("text");
    expect(txtVehicleWt.classList).toContain("textboxWidgetClass");
    expect(txtVehicleWt.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtVehicleWt")
    );
    await act(async () => {
      userEvent.type(txtVehicleWt, "123");
    });
    expect(txtVehicleWt.getAttribute("value")).toBe("");
    expect(txtVehicleWt.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVehicleWt", () => {
    // START_USER_CODE-USER_txtVehicleWt_TEST
    // END_USER_CODE-USER_txtVehicleWt_TEST
  });
  test("txtVoidReasonCode(Textbox Widget) Test Cases", async () => {
    const txtVoidReasonCode = screen.getByTestId("txtVoidReasonCode");
    expect(txtVoidReasonCode.tagName).toBe("INPUT");
    expect(txtVoidReasonCode.type).toBe("text");
    expect(txtVoidReasonCode.classList).toContain("textboxWidgetClass");
    expect(txtVoidReasonCode.previousElementSibling.textContent).toEqual(
      t("Settlements:KCMOdataProfile_txtVoidReasonCode")
    );
    await act(async () => {
      userEvent.type(txtVoidReasonCode, "123");
    });
    expect(txtVoidReasonCode.getAttribute("value")).toBe("");
    expect(txtVoidReasonCode.getAttribute("disabled")).toContain("");
  });
  test("Custom Test Cases for txtVoidReasonCode", () => {
    // START_USER_CODE-USER_txtVoidReasonCode_TEST
    // END_USER_CODE-USER_txtVoidReasonCode_TEST
  });
});
