/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  setData,
  getData,
  setValue,
  getValue,
  enable,
  disable,
  goTo,
  hide,
  show,
} from "../../shared/WindowImports";

import "./SecurityLocationSelect.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceSpecialFunctionsService } from "../Service/SystemMaintenanceSpecialFunctionsService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceSpecialFunctions_SecurityLocationSelect(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let compId = sessionStorage.getItem('compId');
  let userId = sessionStorage.getItem("userid");
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SecurityLocationSelect",
    windowName: "SecurityLocationSelect",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceSpecialFunctions.SecurityLocationSelect",
    // START_USER_CODE-USER_SecurityLocationSelect_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Security Location Select",
      scrCode: "PN0060I",
    },
    // END_USER_CODE-USER_SecurityLocationSelect_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    ddAccessLevel: {
      name: "ddAccessLevel",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityLocationSelect",
      Label: "Access Level:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAccessLevel_PROPERTIES

      // END_USER_CODE-USER_ddAccessLevel_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxSecurityLocationSelect",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtKeyInfo: {
      name: "txtKeyInfo",
      type: "TextBoxWidget",
      parent: "grpbxSecurityLocationSelect",
      Label: "Key Information:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKeyInfo_PROPERTIES

      // END_USER_CODE-USER_txtKeyInfo_PROPERTIES
    },
    grpbxSecurityLocationSelect: {
      name: "grpbxSecurityLocationSelect",
      type: "GroupBoxWidget",
      parent: "SecurityLocationSelect",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSecurityLocationSelect_PROPERTIES

      // END_USER_CODE-USER_grpbxSecurityLocationSelect_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "SecurityLocationSelect",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  //Function to convert to Pascal Case
  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }
  // Logic to fill Buying Point dd
  async function bFillBuyingPoint(buy_pt_id) {
    try {
      setLoading(true);
      let js = []
      let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0060', null, null, null, buy_pt_id)
      if (response !== undefined && response !== null) {
        for (var i = 0; i < response.length; i++) {
          js.push({
            key: response[i].buy_pt_id,
            description: response[i].buy_pt_id + ' - ' + ConvertToPascalCase(response[i].buy_pt_name).toString()
          })
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      });
      let objData = getData(thisObj, 'frmSecurityLocationSelect')
      if (objData.cmdOk_Caption == "Update") {
        disable(thisObj, "ddBuyingPoint", true);
        setLoading(false);
      }
      setValue(thisObj, 'ddBuyingPoint', js.at(0).key);
      setLoading(false);
    } catch (err) {
      thisObj.showMessage = err.message;
      setLoading(false);
    }
    finally {
      setLoading(false);
    }
  }
  // Logic to fill Access Level dd
  // Impliment Rate Basis
  async function bFillAccessLevelList(accessLevelId) {
    try {
      // let accessLevelId = thisObj.values.ddAccessLevel
      let js = [
        { key: 'I', description: 'Inquiry' },
        { key: 'U', description: 'Update' },
        { key: 'D', description: 'Delete' },
        { key: 'X', description: 'Revoke' }
      ]

      thisObj.setState(current => {
        return {
          ...current,
          ddAccessLevel: {
            ...state["ddAccessLevel"],
            valueList: js
          }
        }
      })
      let objData = getData(thisObj, 'frmSecurityLocationSelect')
      let cmdOK = objData.cmdOk_Caption;
      if (cmdOK == "Update") {
        let indexVal = js.find(elem => elem.key == accessLevelId);
        thisObj.values.ddAccessLevel = indexVal?.key;
      }
      else {
        thisObj.values.ddAccessLevel = js.at(0).key
      }
    }
    catch (err) {
      showMessage(err.message);
      return false;
    }
  }
  // Logic for Form Load function
  const FormLoad = async () => {
    try {
      setLoading(true);
      let objData = getData(thisObj, 'frmSecurityLocationSelect');
      let cmdOk = objData.cmdOk_Caption;
      let Securitygrp = objData.security_group;
      let accessLevelId = objData.sAccessLevelId;
      let addedBy = objData.lblAddedBy;
      let chgedBy = objData.lblChangedBy;
      let buy_pt_id = objData.buy_pt_id;
      document.getElementsByClassName("btnUpdateButton")[0].innerText = cmdOk;
      disable(thisObj, "txtKeyInfo", true);
      if (cmdOk == "Add") {
        thisObj.values.txtKeyInfo = Securitygrp;
        bFillBuyingPoint();
        bFillAccessLevelList();
      } else {
        thisObj.values.txtKeyInfo = Securitygrp;
        thisObj.values.lblAddedByValue = addedBy;
        thisObj.values.lblChangedByValue = chgedBy;
        bFillBuyingPoint(buy_pt_id);
        bFillAccessLevelList(accessLevelId);
      }
    } catch (err) {
      showMessage(thisObj, err.message);
    }
    finally {
      setLoading(false);
    }
  }
  // Logic to implement Add and Update functionality
  const onbtnUpdateClick = async () => {
    try {
      setLoading(true);
      let lstrxml = [];
      let objData = getData(thisObj, 'frmSecurityLocationSelect');
      let cmdOk = objData.cmdOk_Caption;
      let buy_pt_id = getValue(thisObj, "ddBuyingPoint");
      let accessLevel = getValue(thisObj, "ddAccessLevel");
      let security_group = getValue(thisObj, "txtKeyInfo");
      let data = {
        "comp_id": compId,
        "security_group": security_group,
        "buy_pt_id": thisObj.values['ddBuyingPoint'],
        "access_level": thisObj.values['ddAccessLevel'],
        "user_id": userId
      }
      let js = {
        buyPintId: buy_pt_id,
        accessLevel: accessLevel,
        security_group: security_group
      }
      if (cmdOk == "Add") {
        lstrxml = await SystemMaintenanceSpecialFunctionsService.CreateSecurityGroupLocationTransfer(data)
        if (lstrxml?.status == 200) {
          setData(thisObj, 'frmSecurityGroup', js);
          document.getElementById("SystemMaintenanceSpecialFunctions_SecurityLocationSelectPopUp").childNodes[0].click();
          return true;
        }
        else {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\nError: \n\n" + lstrxml?.message?.split('Error Meesage :')[1])
          return false;
        }
      }
      else {
        let data = {
          "access_level": thisObj.values['ddAccessLevel'],
          "user_id": userId
        }
        lstrxml = await SystemMaintenanceSpecialFunctionsService.UpdateSecurityGroupLocationTransfer(buy_pt_id, security_group, data)
        if (lstrxml?.status == 200) {
          setData(thisObj, 'frmSecurityGroup', js);
          document.getElementById("SystemMaintenanceSpecialFunctions_SecurityLocationSelectPopUp").childNodes[0].click();
          return true;
        }
        else {
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\nError: \n\n" + lstrxml?.message?.split('Error Meesage :')[1])
          return false;
        }
      }
    } catch (err) {
      showMessage(thisObj, err.message);
      setLoading(false);
    }
    finally {
      setLoading(false);
    }
  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick;
  // Implement functionality for Cancel button___
  const onbtnCancelClick = () => {
    try {
      document.getElementById("SystemMaintenanceSpecialFunctions_SecurityLocationSelectPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SecurityLocationSelect*/}

              {/* END_USER_CODE-USER_BEFORE_SecurityLocationSelect*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSecurityLocationSelect*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSecurityLocationSelect*/}

              <GroupBoxWidget
                conf={state.grpbxSecurityLocationSelect}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtKeyInfo*/}

                {/* END_USER_CODE-USER_BEFORE_txtKeyInfo*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKeyInfo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKeyInfo*/}

                {/* END_USER_CODE-USER_AFTER_txtKeyInfo*/}
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_ddAccessLevel*/}

                {/* END_USER_CODE-USER_BEFORE_ddAccessLevel*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAccessLevel}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAccessLevel*/}

                {/* END_USER_CODE-USER_AFTER_ddAccessLevel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSecurityLocationSelect*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSecurityLocationSelect*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_SecurityLocationSelect*/}

              {/* END_USER_CODE-USER_AFTER_SecurityLocationSelect*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceSpecialFunctions_SecurityLocationSelect
);
