/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  useLocation,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  CheckboxGroupWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  TextAreaWidget,
  DateWidget,
  getData,
  setData,
  getValue,
  setValue,
  goTo,
  disable,
  enable,
  hide,
  show,
  AutocompleteWidget,
  getSelectedRowNumber,
  isEnabled,
  clearValue,
  hideWidgets,
  showWidgets,
  setFieldValues
} from "../../shared/WindowImports";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";

import "./TradeSettleProfile.scss";
import "./TradeSettleProfile.scss";
import CommonContext from "../../Store/CommonContext";
import { SettlementService } from "../Service/SettlementService";
// START_USER_CODE-USER_IMPORTS
import { Searchspecial } from "../../Common/CommonFunctions"

import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// START_USER_CODE-USER_IMPORTS

// END_USER_CODE-USER_IMPORTS
function Settlements_TradeSettleProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const location = useLocation();
  //const [locationData, setLocationData] = useState([]);
  //const [count, setCount] = useState(4);
  //const [lblLoadType, setLblLoadType] = useState('ADD');
  const [lblTKC, setLblTKC] = useState('0');
  //const [apiData, setApiData] = useState();
  const [stopFirstRender, setStopFirstRender] = useState(0)
  //const [lstNewContractLine1, setlstNewContractLine1] = useState([])
  //const [lblValueOfSeg, setlblValueOfSeg] = useState('')
  const commonContext = useContext(CommonContext);
  const [formDirty, setFormDirty] = useState(false);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES
  const compIdFromLS = sessionStorage.getItem('compId');
  const cropYearFromLS = JSON.parse(sessionStorage.getItem('year'))
  let useridFromLS = sessionStorage.getItem('userid')
  const [sPermission, setPermission] = useState("");
  let mbSettleHeaderIdtExistError = false
  let mbSettleHeaderIdtExistFlag = false
  let mbAgreeHeaderIdtExistFlag = false


  let states = {
    Label: "TradeSettleProfile",
    windowName: "TradeSettleProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.TradeSettleProfile",
    // START_USER_CODE-USER_TradeSettleProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Trade Settlement Profile",
      scrCode: "PN1140B",

    },
    headerDetails: {
      year: 2021,
      companyName: "Golden Peanut Compnay"
    },
    // END_USER_CODE-USER_TradeSettleProfile_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow0",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btn2: {
      name: "btn2",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow1",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn2_PROPERTIES

      // END_USER_CODE-USER_btn2_PROPERTIES
    },
    btn3: {
      name: "btn3",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow2",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn3_PROPERTIES

      // END_USER_CODE-USER_btn3_PROPERTIES
    },
    btn4: {
      name: "btn4",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow3",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn5: {
      name: "btn5",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow4",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn6: {
      name: "btn6",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow5",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn7: {
      name: "btn7",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow6",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn8: {
      name: "btn8",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow7",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn9: {
      name: "btn9",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow8",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn10: {
      name: "btn10",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow9",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn11: {
      name: "btn11",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow10",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn12: {
      name: "btn12",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow11",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn13: {
      name: "btn13",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow12",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn14: {
      name: "btn14",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow13",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn15: {
      name: "btn15",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow14",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn16: {
      name: "btn16",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow15",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn17: {
      name: "btn17",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow16",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn18: {
      name: "btn18",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow17",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn19: {
      name: "btn19",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow18",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btn20: {
      name: "btn20",
      type: "ButtonWidget",
      parent: "grpbxVendorSplitRow19",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btnAcctDist: {
      name: "btnAcctDist",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Acct Dist",
      CharWidth: "21",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAcctDist_PROPERTIES

      // END_USER_CODE-USER_btnAcctDist_PROPERTIES
    },
    btnAddST: {
      name: "btnAddST",
      type: "ButtonWidget",
      parent: "grpbxScaleTickets",
      Label: "Add",
      CharWidth: "10",
      // START_USER_CODE-USER_btnAddST_PROPERTIES

      // END_USER_CODE-USER_btnAddST_PROPERTIES
    },
    btnAgreeAppl: {
      name: "btnAgreeAppl",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Agree Appl.",
      CharWidth: "24",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAgreeAppl_PROPERTIES

      // END_USER_CODE-USER_btnAgreeAppl_PROPERTIES
    },
    btnCalculate: {
      name: "btnCalculate",
      type: "ButtonWidget",
      parent: "grpbxValueCalculation",
      Label: "Calculate",
      CharWidth: "20",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnCalculate_PROPERTIES

      // END_USER_CODE-USER_btnCalculate_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCreateSettlement: {
      name: "btnCreateSettlement",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Create Settlement",
      CharWidth: "35",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreateSettlement_PROPERTIES

      // END_USER_CODE-USER_btnCreateSettlement_PROPERTIES
    },
    btnDeductTrack: {
      name: "btnDeductTrack",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Deduct Track",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeductTrack_PROPERTIES

      // END_USER_CODE-USER_btnDeductTrack_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnDeleteST: {
      name: "btnDeleteST",
      type: "ButtonWidget",
      parent: "grpbxScaleTickets",
      Label: "Delete",
      CharWidth: "15",
      // START_USER_CODE-USER_btnDeleteST_PROPERTIES

      // END_USER_CODE-USER_btnDeleteST_PROPERTIES
    },
    btnEditST: {
      name: "btnEditST",
      type: "ButtonWidget",
      parent: "grpbxScaleTickets",
      Label: "Edit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnEditST_PROPERTIES

      // END_USER_CODE-USER_btnEditST_PROPERTIES
    },
    btnPayments: {
      name: "btnPayments",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Payments",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPayments_PROPERTIES

      // END_USER_CODE-USER_btnPayments_PROPERTIES
    },
    btnPremsDeduct: {
      name: "btnPremsDeduct",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Prems/Deduct",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPremsDeduct_PROPERTIES

      // END_USER_CODE-USER_btnPremsDeduct_PROPERTIES
    },
    btnPrintSettlement: {
      name: "btnPrintSettlement",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print Settlement",
      CharWidth: "33",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintSettlement_PROPERTIES

      // END_USER_CODE-USER_btnPrintSettlement_PROPERTIES
    },
    btnReversal: {
      name: "btnReversal",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Reversal",
      CharWidth: "19",
      // START_USER_CODE-USER_btnReversal_PROPERTIES

      // END_USER_CODE-USER_btnReversal_PROPERTIES
    },
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Save",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSave_PROPERTIES

      // END_USER_CODE-USER_btnSave_PROPERTIES
    },
    btnUnvoid: {
      name: "btnUnvoid",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Un-void",
      CharWidth: "15",
      // START_USER_CODE-USER_btnUnvoid_PROPERTIES

      // END_USER_CODE-USER_btnUnvoid_PROPERTIES
    },
    btnVoid: {
      name: "btnVoid",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Void",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVoid_PROPERTIES

      // END_USER_CODE-USER_btnVoid_PROPERTIES
    },
    chkboxPrintSeg: {
      name: "chkboxPrintSeg",
      type: "CheckBoxWidget",
      parent: "grpbxSeg",
      HasLabel: false,
      RowSpan: "2",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxPrintSeg_PROPERTIES

      // END_USER_CODE-USER_chkboxPrintSeg_PROPERTIES
    },
    chkboxReGrading: {
      name: "chkboxReGrading",
      type: "CheckBoxGroupFieldWidget",
      parent: "grpbxReGrading",
      Options: "Corn Found:1,A. Flavus Found:2, Vicam:3",
      RowSpanForTabPotrait: "2",
      RowSpanForLargeDesktop: "2",
      RowSpanForTabLandscape: "2",
      ColSpan: "6",
      ColSpanForLargeDesktop: "6",
      ColSpanForTabLandscape: "6",
      ColSpanForTabPotrait: "6",
      HasLabel: false,
      RowSpan: "2",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxReGrading_PROPERTIES

      // END_USER_CODE-USER_chkboxReGrading_PROPERTIES
    },
    colInventoryWt: {
      name: "colInventoryWt",
      type: "GridColumnWidget",
      parent: "gridScaleTickets",
      Label: "Inventory Wt.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInventoryWt_PROPERTIES

      // END_USER_CODE-USER_colInventoryWt_PROPERTIES
    },
    colLoad: {
      name: "colLoad",
      type: "GridColumnWidget",
      parent: "gridScaleTickets",
      Label: "Load #:",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoad_PROPERTIES

      // END_USER_CODE-USER_colLoad_PROPERTIES
    },
    colRecvDate: {
      name: "colRecvDate",
      type: "GridColumnWidget",
      parent: "gridScaleTickets",
      Label: "Recv. Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRecvDate_PROPERTIES

      // END_USER_CODE-USER_colRecvDate_PROPERTIES
    },
    colRecvTicket: {
      name: "colRecvTicket",
      type: "GridColumnWidget",
      parent: "gridScaleTickets",
      Label: "Recv. Ticket",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRecvTicket_PROPERTIES

      // END_USER_CODE-USER_colRecvTicket_PROPERTIES
    },
    colRecvWt: {
      name: "colRecvWt",
      type: "GridColumnWidget",
      parent: "gridScaleTickets",
      Label: "Recv. Wt.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRecvWt_PROPERTIES

      // END_USER_CODE-USER_colRecvWt_PROPERTIES
    },
    colShipDate: {
      name: "colShipDate",
      type: "GridColumnWidget",
      parent: "gridScaleTickets",
      Label: "Ship Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShipDate_PROPERTIES

      // END_USER_CODE-USER_colShipDate_PROPERTIES
    },
    colShipTicket: {
      name: "colShipTicket",
      type: "GridColumnWidget",
      parent: "gridScaleTickets",
      Label: "Ship Ticket #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShipTicket_PROPERTIES

      // END_USER_CODE-USER_colShipTicket_PROPERTIES
    },
    colShipWt: {
      name: "colShipWt",
      type: "GridColumnWidget",
      parent: "gridScaleTickets",
      Label: "Ship Wt.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShipWt_PROPERTIES

      // END_USER_CODE-USER_colShipWt_PROPERTIES
    },
    colVehicle: {
      name: "colVehicle",
      type: "GridColumnWidget",
      parent: "gridScaleTickets",
      Label: "Vehicle #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVehicle_PROPERTIES

      // END_USER_CODE-USER_colVehicle_PROPERTIES
    },
    ddCategory: {
      name: "ddCategory",
      type: "DropDownFieldWidget",
      parent: "grpbxTradeDetails",
      Label: "Category:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCategory_PROPERTIES

      // END_USER_CODE-USER_ddCategory_PROPERTIES
    },
    ddDTWhseBinNum: {
      name: "ddDTWhseBinNum",
      type: "DropDownFieldWidget",
      parent: "grpbxShippedTo",
      Label: "Whse#/B:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDTWhseBinNum_PROPERTIES

      // END_USER_CODE-USER_ddDTWhseBinNum_PROPERTIES
    },
    ddEdibleOil: {
      name: "ddEdibleOil",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Edible Oil:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEdibleOil_PROPERTIES

      // END_USER_CODE-USER_ddEdibleOil_PROPERTIES
    },
    ddGeneration: {
      name: "ddGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGeneration_PROPERTIES

      // END_USER_CODE-USER_ddGeneration_PROPERTIES
    },
    ddIDTfromtoCompany: {
      name: "ddIDTfromtoCompany",
      type: "DropDownFieldWidget",
      parent: "grpbxInterDepartmentalTransfer",
      Label: "IDT from/to Company:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_ddIDTfromtoCompany_PROPERTIES

      // END_USER_CODE-USER_ddIDTfromtoCompany_PROPERTIES
    },
    ddIDTfromtoLocation: {
      name: "ddIDTfromtoLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxInterDepartmentalTransfer",
      Label: "IDT from/to Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_ddIDTfromtoLocation_PROPERTIES

      // END_USER_CODE-USER_ddIDTfromtoLocation_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddRemitTo1: {
      name: "ddRemitTo1",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow0",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      optionMaxLength: 40,
      // ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitTo1_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo1_PROPERTIES
    },
    ddRemitTo2: {
      name: "ddRemitTo2",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow1",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      optionMaxLength: 40,
      // ofTypeDomain: "d_String",
      Options: '',
      // START_USER_CODE-USER_ddRemitTo2_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo2_PROPERTIES
    },
    ddRemitTo3: {
      name: "ddRemitTo3",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow2",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      optionMaxLength: 40,
      // ofTypeDomain: "d_String",
      Options: '',
      // START_USER_CODE-USER_ddRemitTo3_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo3_PROPERTIES
    },
    ddRemitTo4: {
      name: "ddRemitTo4",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow3",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      optionMaxLength: 40,
      // ofTypeDomain: "d_String",
      Options: '',
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo5: {
      name: "ddRemitTo5",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow4",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      Options: '',
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo6: {
      name: "ddRemitTo6",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow5",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      Options: '',
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo7: {
      name: "ddRemitTo7",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow6",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      Options: '',
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo8: {
      name: "ddRemitTo8",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow7",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      Options: '',
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo9: {
      name: "ddRemitTo9",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow8",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      Options: '',
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo10: {
      name: "ddRemitTo10",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow9",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      Options: '',
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo11: {
      name: "ddRemitTo11",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow10",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      Options: '',
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo12: {
      name: "ddRemitTo12",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow11",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      Options: '',
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo13: {
      name: "ddRemitTo13",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow12",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo14: {
      name: "ddRemitTo14",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow13",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo15: {
      name: "ddRemitTo15",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow14",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo16: {
      name: "ddRemitTo16",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow15",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo17: {
      name: "ddRemitTo17",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow16",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo18: {
      name: "ddRemitTo18",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow17",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo19: {
      name: "ddRemitTo19",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow18",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddRemitTo20: {
      name: "ddRemitTo20",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow19",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      optionMaxLength: 40,
      // START_USER_CODE-USER_ddRemitTo4_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo4_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Seg #:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    ddShipFromToLocation: {
      name: "ddShipFromToLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxTradeDetails",
      Label: "Ship From/To Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShipFromToLocation_PROPERTIES
      optionMaxLength: 30,
      // END_USER_CODE-USER_ddShipFromToLocation_PROPERTIES
    },
    ddWhseBinNum: {
      name: "ddWhseBinNum",
      type: "DropDownFieldWidget",
      parent: "grpbxShippedFrom",
      Label: "Whse#/Bin#:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      // ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseBinNum_PROPERTIES
      optionMaxLength: 18,
      // END_USER_CODE-USER_ddWhseBinNum_PROPERTIES
    },
    dtInspectDateTime: {
      name: "dtInspectDateTime",
      type: "DateTimeWidget",
      parent: "grpbxTradeDetails",
      Label: "Inspect Date/Time:",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_dtInspectDateTime_PROPERTIES

      // END_USER_CODE-USER_dtInspectDateTime_PROPERTIES
    },
    dtSettleDateTime: {
      name: "dtSettleDateTime",
      type: "DateTimeWidget",
      parent: "grpbxTradeDetails",
      Label: "Settle Date/Time:",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
      Enabled: false
      // START_USER_CODE-USER_dtSettleDateTime_PROPERTIES

      // END_USER_CODE-USER_dtSettleDateTime_PROPERTIES
    },
    dtStartDateTime: {
      name: "dtStartDateTime",
      type: "DateTimeWidget",
      parent: "grpbxSeg",
      Label: "Start Date/Time",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_dtStartDateTime_PROPERTIES

      // END_USER_CODE-USER_dtStartDateTime_PROPERTIES
    },
    gridScaleTickets: {
      name: "gridScaleTickets",
      type: "GridWidget",
      parent: "grpbxScaleTickets",
      gridCellsOrder:
        "txtcolLoad,txtcolVehicle,txtcolShipDate,txtcolShipTicket,txtcolShipWt,txtcolRecvDate,txtcolRecvTicket,txtcolRecvWt,txtcolInventoryWt",
      Label: "Scale Tickets",
      ColSpan: "6",
      Pagination: false,
      HasLabel: false,
      Cols: "6",
      Height: "",
      Width: "",

      // START_USER_CODE-USER_gridScaleTickets_PROPERTIES

      // END_USER_CODE-USER_gridScaleTickets_PROPERTIES
    },
    grpbxInterDepartmentalTransfer: {
      name: "grpbxInterDepartmentalTransfer",
      type: "GroupBoxWidget",
      parent: "grpbxTradeDetails",
      Label: "InterDepartmental Transfer",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxInterDepartmentalTransfer_PROPERTIES

      // END_USER_CODE-USER_grpbxInterDepartmentalTransfer_PROPERTIES
    },
    grpbxPeanutInfo: {
      name: "grpbxPeanutInfo",
      type: "GroupBoxWidget",
      parent: "grpbxPntShippd",
      Label: "Peanut Information",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxPeanutInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxPeanutInfo_PROPERTIES
    },
    grpbxPntShippd: {
      name: "grpbxPntShippd",
      type: "GroupBoxWidget",
      parent: "grpbxTradeDetails",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbxPntShippd_PROPERTIES

      // END_USER_CODE-USER_grpbxPntShippd_PROPERTIES
    },
    grpbxReGrading: {
      name: "grpbxReGrading",
      type: "GroupBoxWidget",
      parent: "grpbxTradeSettleProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxReGrading_PROPERTIES

      // END_USER_CODE-USER_grpbxReGrading_PROPERTIES
    },
    grpbxRegrdLeft: {
      name: "grpbxRegrdLeft",
      type: "GroupBoxWidget",
      parent: "grpbxTradeSettleProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "3",
      ColsForTabPotrait: "3",
      ColsForLargeDesktop: "3",
      ColsForTabLandscape: "3",
      // START_USER_CODE-USER_grpbxReGrading_PROPERTIES

      // END_USER_CODE-USER_grpbxReGrading_PROPERTIES
    },
    grpbxRegrdRight: {
      name: "grpbxRegrdRight",
      type: "GroupBoxWidget",
      parent: "grpbxTradeSettleProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      ColsForTabLandscape: "2",
      // START_USER_CODE-USER_grpbxReGrading_PROPERTIES

      // END_USER_CODE-USER_grpbxReGrading_PROPERTIES
    },
    grpbxRegrdRightBottom: {
      name: "grpbxRegrdRightBottom",
      type: "GroupBoxWidget",
      parent: "grpbxTradeSettleProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      ColsForTabLandscape: "2",
      // START_USER_CODE-USER_grpbxReGrading_PROPERTIES

      // END_USER_CODE-USER_grpbxReGrading_PROPERTIES
    },
    grpbxRegrdLeftBottom: {
      name: "grpbxRegrdLeftBottom",
      type: "GroupBoxWidget",
      parent: "grpbxTradeSettleProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      ColsForTabLandscape: "2",
      // START_USER_CODE-USER_grpbxReGrading_PROPERTIES

      // END_USER_CODE-USER_grpbxReGrading_PROPERTIES
    },
    grpbxDamage: {
      name: "grpbxDamage",
      type: "GroupBoxWidget",
      parent: "grpbxReGrading",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "3",
      HasLabel: false,
      Cols: "3",
      ColsForTabPotrait: "3",
      ColsForLargeDesktop: "3",
      // START_USER_CODE-USER_grpbxReGrading_PROPERTIES

      // END_USER_CODE-USER_grpbxReGrading_PROPERTIES
    },
    grpbxSeg: {
      name: "grpbxSeg",
      type: "GroupBoxWidget",
      parent: "grpbxTradeSettleProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxSeg_PROPERTIES

      // END_USER_CODE-USER_grpbxSeg_PROPERTIES
    },
    grpbxSettlementInformation: {
      name: "grpbxSettlementInformation",
      type: "GroupBoxWidget",
      parent: "grpbxTradeSettleProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSettlementInformation_PROPERTIES

      // END_USER_CODE-USER_grpbxSettlementInformation_PROPERTIES
    },
    grpbxShippedFrom: {
      name: "grpbxShippedFrom",
      type: "GroupBoxWidget",
      parent: "grpbxPntShippd",
      Label: "Shipped From",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxShippedFrom_PROPERTIES

      // END_USER_CODE-USER_grpbxShippedFrom_PROPERTIES
    },
    grpbxShippedTo: {
      name: "grpbxShippedTo",
      type: "GroupBoxWidget",
      parent: "grpbxPntShippd",
      Label: "Shipped To",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxShippedTo_PROPERTIES

      // END_USER_CODE-USER_grpbxShippedTo_PROPERTIES
    },
    grpbxValueCalculation: {
      name: "grpbxValueCalculation",
      type: "GroupBoxWidget",
      parent: "grpbxTradeSettleProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      clonedExtId: "35275",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxValueCalculation_PROPERTIES

      // END_USER_CODE-USER_grpbxValueCalculation_PROPERTIES
    },
    grpbxVendorSplitRow0: {
      name: "grpbxVendorSplitRow0",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow1: {
      name: "grpbxVendorSplitRow1",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow1_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow1_PROPERTIES
    },
    grpbxVendorSplitRow2: {
      name: "grpbxVendorSplitRow2",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow2_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow2_PROPERTIES
    },
    grpbxVendorSplitRow3: {
      name: "grpbxVendorSplitRow3",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow3_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow3_PROPERTIES
    },
    grpbxVendorSplitRow4: {
      name: "grpbxVendorSplitRow4",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow5: {
      name: "grpbxVendorSplitRow5",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",

      Height: "",
      Width: "",
      HasLabel: false,
      Visible: true,
      VisibleForLargeDesktop: true,
      VisibleForMobile: true,
      VisibleForTabLandscape: true,
      VisibleForTabPotrait: true,
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow6: {
      name: "grpbxVendorSplitRow6",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow7: {
      name: "grpbxVendorSplitRow7",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow8: {
      name: "grpbxVendorSplitRow8",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow9: {
      name: "grpbxVendorSplitRow9",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow10: {
      name: "grpbxVendorSplitRow10",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow11: {
      name: "grpbxVendorSplitRow11",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow12: {
      name: "grpbxVendorSplitRow12",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow13: {
      name: "grpbxVendorSplitRow13",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow14: {
      name: "grpbxVendorSplitRow14",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow15: {
      name: "grpbxVendorSplitRow15",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow16: {
      name: "grpbxVendorSplitRow16",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow17: {
      name: "grpbxVendorSplitRow17",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow18: {
      name: "grpbxVendorSplitRow18",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplitRow19: {
      name: "grpbxVendorSplitRow19",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplitSub",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow0_PROPERTIES
    },
    grpbxVendorSplits: {
      name: "grpbxVendorSplits",
      type: "GroupBoxWidget",
      parent: "grpbxTradeDetails",
      Label: "Vendor Splits",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxVendorSplits_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplits_PROPERTIES
    },
    grpbxVendorSplitSub: {
      name: "grpbxVendorSplitSub",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "4",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitSub_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitSub_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    ddbutton: {
      name: "ddbutton",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfo",
      Label: "ddbutton:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblAgreeNum: {
      name: "lblAgreeNum",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblIDTAgreeNum: {
      name: "lblIDTAgreeNum",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblCropYear2: {
      name: "lblCropYear2",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "lblCropYear2",
      ColSpan: "10",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear2_PROPERTIES

      // END_USER_CODE-USER_lblCropYear2_PROPERTIES
    },
    lblInterDeptTransfer: {
      name: "lblInterDeptTransfer",
      type: "LabelWidget",
      parent: "grpbxInterDepartmentalTransfer",
      Label: "InterDepartmental Transfer",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblInterDeptTransfer_PROPERTIES

      // END_USER_CODE-USER_lblInterDeptTransfer_PROPERTIES
    },
    txtKernRidingELKPct: {
      name: "txtKernRidingELKPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernRidingELKPct_PROPERTIES

      // END_USER_CODE-USER_txtKernRidingELKPct_PROPERTIES
    },
    lblPeanutInformation: {
      name: "lblPeanutInformation",
      type: "LabelWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Information",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPeanutInformation_PROPERTIES

      // END_USER_CODE-USER_lblPeanutInformation_PROPERTIES
    },
    lblPerLb: {
      name: "lblPerLb",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "c/lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerLb_PROPERTIES

      // END_USER_CODE-USER_lblPerLb_PROPERTIES
    },
    lblPerLb2: {
      name: "lblPerLb2",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "c/lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerLb2_PROPERTIES

      // END_USER_CODE-USER_lblPerLb2_PROPERTIES
    },
    lblPerLb3: {
      name: "lblPerLb3",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "c/lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerLb3_PROPERTIES

      // END_USER_CODE-USER_lblPerLb3_PROPERTIES
    },
    lblPerTon: {
      name: "lblPerTon",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "$/ton",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerTon_PROPERTIES

      // END_USER_CODE-USER_lblPerTon_PROPERTIES
    },
    lblPrintSeg: {
      name: "lblPrintSeg",
      type: "LabelWidget",
      parent: "grpbxSeg",
      Label: "Print Seg:",
      RowSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPrintSeg_PROPERTIES

      // END_USER_CODE-USER_lblPrintSeg_PROPERTIES
    },
    lblReGrading: {
      name: "lblReGrading",
      type: "LabelWidget",
      parent: "grpbxReGrading",
      Label: "ReGrading",
      ColSpan: "6",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblReGrading_PROPERTIES

      // END_USER_CODE-USER_lblReGrading_PROPERTIES
    },
    lblRemitTo: {
      name: "lblRemitTo",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Remit To",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo_PROPERTIES
    },
    lblScaleTickets: {
      name: "lblScaleTickets",
      type: "LabelWidget",
      parent: "grpbxScaleTickets",
      Label: "Scale Tickets",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblScaleTickets_PROPERTIES

      // END_USER_CODE-USER_lblScaleTickets_PROPERTIES
    },
    lblSettlementInformation: {
      name: "lblSettlementInformation",
      type: "LabelWidget",
      parent: "grpbxSettlementInformation",
      Label: "Settlement Information",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSettlementInformation_PROPERTIES

      // END_USER_CODE-USER_lblSettlementInformation_PROPERTIES
    },
    lblSharePercentage: {
      name: "lblSharePercentage",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Share %",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSharePercentage_PROPERTIES

      // END_USER_CODE-USER_lblSharePercentage_PROPERTIES
    },
    lblShippedFrom: {
      name: "lblShippedFrom",
      type: "LabelWidget",
      parent: "grpbxShippedFrom",
      Label: "Shipped From",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblShippedFrom_PROPERTIES

      // END_USER_CODE-USER_lblShippedFrom_PROPERTIES
    },
    lblShippedTo: {
      name: "lblShippedTo",
      type: "LabelWidget",
      parent: "grpbxShippedTo",
      Label: "Shipped To",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblShippedTo_PROPERTIES

      // END_USER_CODE-USER_lblShippedTo_PROPERTIES
    },
    lblValueCalculation: {
      name: "lblValueCalculation",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Calculation",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValueCalculation_PROPERTIES

      // END_USER_CODE-USER_lblValueCalculation_PROPERTIES
    },
    lblVendor: {
      name: "lblVendor",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Vendor",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendor_PROPERTIES

      // END_USER_CODE-USER_lblVendor_PROPERTIES
    },
    lblVendorSplits: {
      name: "lblVendorSplits",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Vendor Splits",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendorSplits_PROPERTIES

      // END_USER_CODE-USER_lblVendorSplits_PROPERTIES
    },
    lblXX1: {
      name: "lblXX1",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow0",
      Label: "Xxxxxxxxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX2: {
      name: "lblXX2",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow1",
      Label: "Xxxxxxxxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX2_PROPERTIES

      // END_USER_CODE-USER_lblXX2_PROPERTIES
    },
    lblXX3: {
      name: "lblXX3",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow2",
      Label: "Xxxxxxxxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX3_PROPERTIES

      // END_USER_CODE-USER_lblXX3_PROPERTIES
    },
    lblXX4: {
      name: "lblXX4",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow3",
      Label: "Xxxxxxxxx",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX4_PROPERTIES

      // END_USER_CODE-USER_lblXX4_PROPERTIES
    },
    lblXX5: {
      name: "lblXX5",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX6: {
      name: "lblXX6",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX7: {
      name: "lblXX7",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX8: {
      name: "lblXX8",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX9: {
      name: "lblXX9",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX10: {
      name: "lblXX10",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX11: {
      name: "lblXX11",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX12: {
      name: "lblXX12",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX13: {
      name: "lblXX13",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX14: {
      name: "lblXX14",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX15: {
      name: "lblXX15",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX16: {
      name: "lblXX16",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX17: {
      name: "lblXX17",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX18: {
      name: "lblXX18",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX19: {
      name: "lblXX19",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    lblXX20: {
      name: "lblXX20",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblXX1_PROPERTIES

      // END_USER_CODE-USER_lblXX1_PROPERTIES
    },
    radioGradePricingMethod: {
      name: "radioGradePricingMethod",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxReGrading",
      Options: "Full Grades:FULL,TKC Basis:TKC",
      Label: "Grade Pricing Method:",
      ColSpan: "3",
      RowSpan: "2",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioGradePricingMethod_PROPERTIES

      // END_USER_CODE-USER_radioGradePricingMethod_PROPERTIES
    },
    radioGramsPercentage: {
      name: "radioGramsPercentage",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxReGrading",
      Options: "Grams:1,%:2",
      ColSpan: "6",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioGramsPercentage_PROPERTIES

      // END_USER_CODE-USER_radioGramsPercentage_PROPERTIES
    },
    radioTradeSaleTradePurchase: {
      name: "radioTradeSaleTradePurchase",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxTradeDetails",
      Options: "Trade Sale:S,Trade Purchase:P",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioTradeSaleTradePurchase_PROPERTIES

      // END_USER_CODE-USER_radioTradeSaleTradePurchase_PROPERTIES
    },
    txt1007Handler: {
      name: "txt1007Handler",
      type: "TextBoxWidget",
      parent: "grpbxTradeDetails",
      Label: "1007 Handler #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007Handler_PROPERTIES

      // END_USER_CODE-USER_txt1007Handler_PROPERTIES
    },
    txt1007HandlerName: {
      name: "txt1007HandlerName",
      type: "TextBoxWidget",
      parent: "grpbxTradeDetails",
      Label: "1007 Handler Name:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007HandlerName_PROPERTIES

      // END_USER_CODE-USER_txt1007HandlerName_PROPERTIES
    },
    txtAccessFMPerPound: {
      name: "txtAccessFMPerPound",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtAccessFMPerPound_PROPERTIES

      // END_USER_CODE-USER_txtAccessFMPerPound_PROPERTIES
    },
    txtAccessFMPerTon: {
      name: "txtAccessFMPerTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Access FM:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtAccessFMPerTon_PROPERTIES

      // END_USER_CODE-USER_txtAccessFMPerTon_PROPERTIES
    },
    txtAFlavusPerPound: {
      name: "txtAFlavusPerPound",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtAFlavusPerPound_PROPERTIES

      // END_USER_CODE-USER_txtAFlavusPerPound_PROPERTIES
    },
    txtAFlavusPerTon: {
      name: "txtAFlavusPerTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "A. Flavus:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtAFlavusPerTon_PROPERTIES

      // END_USER_CODE-USER_txtAFlavusPerTon_PROPERTIES
    },
    txtAgreementPrice: {
      name: "txtAgreementPrice",
      type: "TextBoxWidget",
      parent: "grpbxSettlementInformation",
      Label: "Agreement Price",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtAgreementPrice_PROPERTIES

      // END_USER_CODE-USER_txtAgreementPrice_PROPERTIES
    },
    txtarInspectionRemarks: {
      name: "txtarInspectionRemarks",
      type: "TextAreaWidget",
      parent: "grpbxTradeSettleProfile",
      Label: "Inspection Remarks:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarInspectionRemarks_PROPERTIES

      // END_USER_CODE-USER_txtarInspectionRemarks_PROPERTIES
    },
    txtarSettleRemarks: {
      name: "txtarSettleRemarks",
      type: "TextAreaWidget",
      parent: "grpbxTradeSettleProfile",
      ColSpan: "3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarSettleRemarks_PROPERTIES

      // END_USER_CODE-USER_txtarSettleRemarks_PROPERTIES
    },
    txtBluePanWT: {
      name: "txtBluePanWT",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Blue Pan WT:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBluePanWT_PROPERTIES

      // END_USER_CODE-USER_txtBluePanWT_PROPERTIES
    },
    txtCleanSampleWt: {
      name: "txtCleanSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Clean. Sample Wt:",
      ColSpan: "3",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_txtCleanSampleWt_PROPERTIES

      // END_USER_CODE-USER_txtCleanSampleWt_PROPERTIES
    },
    txtcolInventoryWt: {
      name: "txtcolInventoryWt",
      type: "TextBoxWidget",
      colName: "colInventoryWt",
      parent: "gridScaleTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInventoryWt_PROPERTIES

      // END_USER_CODE-USER_txtcolInventoryWt_PROPERTIES
    },
    txtcolLoad: {
      name: "txtcolLoad",
      type: "TextBoxWidget",
      colName: "colLoad",
      parent: "gridScaleTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoad_PROPERTIES

      // END_USER_CODE-USER_txtcolLoad_PROPERTIES
    },
    txtcolRecvDate: {
      name: "txtcolRecvDate",
      type: "TextBoxWidget",
      colName: "colRecvDate",
      parent: "gridScaleTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRecvDate_PROPERTIES

      // END_USER_CODE-USER_txtcolRecvDate_PROPERTIES
    },
    txtcolRecvTicket: {
      name: "txtcolRecvTicket",
      type: "TextBoxWidget",
      colName: "colRecvTicket",
      parent: "gridScaleTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRecvTicket_PROPERTIES

      // END_USER_CODE-USER_txtcolRecvTicket_PROPERTIES
    },
    txtcolRecvWt: {
      name: "txtcolRecvWt",
      type: "TextBoxWidget",
      colName: "colRecvWt",
      parent: "gridScaleTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRecvWt_PROPERTIES

      // END_USER_CODE-USER_txtcolRecvWt_PROPERTIES
    },
    txtcolShipDate: {
      name: "txtcolShipDate",
      type: "TextBoxWidget",
      colName: "colShipDate",
      parent: "gridScaleTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShipDate_PROPERTIES

      // END_USER_CODE-USER_txtcolShipDate_PROPERTIES
    },
    txtcolShipTicket: {
      name: "txtcolShipTicket",
      type: "TextBoxWidget",
      colName: "colShipTicket",
      parent: "gridScaleTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShipTicket_PROPERTIES

      // END_USER_CODE-USER_txtcolShipTicket_PROPERTIES
    },
    txtcolShipWt: {
      name: "txtcolShipWt",
      type: "TextBoxWidget",
      colName: "colShipWt",
      parent: "gridScaleTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShipWt_PROPERTIES

      // END_USER_CODE-USER_txtcolShipWt_PROPERTIES
    },
    txtcolVehicle: {
      name: "txtcolVehicle",
      type: "TextBoxWidget",
      colName: "colVehicle",
      parent: "gridScaleTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVehicle_PROPERTIES

      // END_USER_CODE-USER_txtcolVehicle_PROPERTIES
    },
    txtConcealedRMD: {
      name: "txtConcealedRMD",
      type: "TextBoxWidget",
      parent: "grpbxDamage",
      Label: "Concealed RMD:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConcealedRMD_PROPERTIES

      // END_USER_CODE-USER_txtConcealedRMD_PROPERTIES
    },
    txtConcealedRMDPct: {
      name: "txtConcealedRMDPct",
      type: "TextBoxWidget",
      parent: "grpbxDamage",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConcealedRMDPct_PROPERTIES

      // END_USER_CODE-USER_txtConcealedRMDPct_PROPERTIES
    },
    txtCrackedBroken: {
      name: "txtCrackedBroken",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Cracked/Broken:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCrackedBroken_PROPERTIES

      // END_USER_CODE-USER_txtCrackedBroken_PROPERTIES
    },
    txtCrackedBrokenPct: {
      name: "txtCrackedBrokenPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCrackedBrokenPct_PROPERTIES

      // END_USER_CODE-USER_txtCrackedBrokenPct_PROPERTIES
    },
    txtDamagedKRS: {
      name: "txtDamagedKRS",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Damaged KRS:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamagedKRS_PROPERTIES

      // END_USER_CODE-USER_txtDamagedKRS_PROPERTIES
    },
    txtDamagePerPound: {
      name: "txtDamagePerPound",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtDamagePerPound_PROPERTIES

      // END_USER_CODE-USER_txtDamagePerPound_PROPERTIES
    },
    txtDamagePerTon: {
      name: "txtDamagePerTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Damage:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtDamagePerTon_PROPERTIES

      // END_USER_CODE-USER_txtDamagePerTon_PROPERTIES
    },
    txtDamageSplits: {
      name: "txtDamageSplits",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Damage Splits:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamageSplits_PROPERTIES

      // END_USER_CODE-USER_txtDamageSplits_PROPERTIES
    },
    txtDeductions: {
      name: "txtDeductions",
      type: "TextBoxWidget",
      parent: "grpbxSettlementInformation",
      Label: "-Deductions",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtDeductions_PROPERTIES

      // END_USER_CODE-USER_txtDeductions_PROPERTIES
    },
    txtDiscoloredShells: {
      name: "txtDiscoloredShells",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Discolored Shells:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDiscoloredShells_PROPERTIES

      // END_USER_CODE-USER_txtDiscoloredShells_PROPERTIES
    },
    txtDiscoloredShellsPct: {
      name: "txtDiscoloredShellsPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDiscoloredShellsPct_PROPERTIES

      // END_USER_CODE-USER_txtDiscoloredShellsPct_PROPERTIES
    },
    txtDTWhseBinNum: {
      name: "txtDTWhseBinNum",
      type: "TextBoxWidget",
      parent: "grpbxShippedTo",
      Label: "Whse#/Bin#:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDTWhseBinNum_PROPERTIES

      // END_USER_CODE-USER_txtDTWhseBinNum_PROPERTIES
    },
    txtELKDamaged: {
      name: "txtELKDamaged",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "ELK Damaged:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKDamaged_PROPERTIES

      // END_USER_CODE-USER_txtELKDamaged_PROPERTIES
    },
    txtELKPremiumPerPound: {
      name: "txtELKPremiumPerPound",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtELKPremiumPerPound_PROPERTIES

      // END_USER_CODE-USER_txtELKPremiumPerPound_PROPERTIES
    },
    txtELKPremiumPerTon: {
      name: "txtELKPremiumPerTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "ELK Premium:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtELKPremiumPerTon_PROPERTIES

      // END_USER_CODE-USER_txtELKPremiumPerTon_PROPERTIES
    },
    txtExcessMoisture: {
      name: "txtExcessMoisture",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Excess Moisture:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtExcessMoisture_PROPERTIES

      // END_USER_CODE-USER_txtExcessMoisture_PROPERTIES
    },
    txtExcessSplitsPerPound: {
      name: "txtExcessSplitsPerPound",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtExcessSplitsPerPound_PROPERTIES

      // END_USER_CODE-USER_txtExcessSplitsPerPound_PROPERTIES
    },
    txtExcessSplitsPerTon: {
      name: "txtExcessSplitsPerTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Excess Splits:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtExcessSplitsPerTon_PROPERTIES

      // END_USER_CODE-USER_txtExcessSplitsPerTon_PROPERTIES
    },
    txtFancy: {
      name: "txtFancy",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Fancy:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFancy_PROPERTIES

      // END_USER_CODE-USER_txtFancy_PROPERTIES
    },
    txtFancyPct: {
      name: "txtFancyPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFancyPct_PROPERTIES

      // END_USER_CODE-USER_txtFancyPct_PROPERTIES
    },
    txtFMSampleWt: {
      name: "txtFMSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "FM Sample Wt:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFMSampleWt_PROPERTIES

      // END_USER_CODE-USER_txtFMSampleWt_PROPERTIES
    },
    txtForeignMaterial: {
      name: "txtForeignMaterial",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Foreign Material:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtForeignMaterial_PROPERTIES

      // END_USER_CODE-USER_txtForeignMaterial_PROPERTIES
    },
    txtForeignMaterialPct: {
      name: "txtForeignMaterialPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtForeignMaterialPct_PROPERTIES

      // END_USER_CODE-USER_txtForeignMaterialPct_PROPERTIES
    },
    txtForeignMtrl: {
      name: "txtForeignMtrl",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Foreign Material:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtForeignMtrl_PROPERTIES

      // END_USER_CODE-USER_txtForeignMtrl_PROPERTIES
    },
    txtFreezeDamage: {
      name: "txtFreezeDamage",
      type: "TextBoxWidget",
      parent: "grpbxDamage",
      Label: "Freeze Damage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreezeDamage_PROPERTIES

      // END_USER_CODE-USER_txtFreezeDamage_PROPERTIES
    },
    txtFreezeDamagePct: {
      name: "txtFreezeDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxDamage",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreezeDamagePct_PROPERTIES

      // END_USER_CODE-USER_txtFreezeDamagePct_PROPERTIES
    },
    txtGrossWeight: {
      name: "txtGrossWeight",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Gross Weight:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtGrossWeight_PROPERTIES

      // END_USER_CODE-USER_txtGrossWeight_PROPERTIES
    },
    txtH: {
      name: "txtH",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "H x :",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtH_PROPERTIES

      // END_USER_CODE-USER_txtH_PROPERTIES
    },
    txtHullBrightness: {
      name: "txtHullBrightness",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Hull Brightness:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullBrightness_PROPERTIES

      // END_USER_CODE-USER_txtHullBrightness_PROPERTIES
    },
    txtHullBrightnessPct: {
      name: "txtHullBrightnessPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullBrightnessPct_PROPERTIES

      // END_USER_CODE-USER_txtHullBrightnessPct_PROPERTIES
    },
    txtHulls: {
      name: "txtHulls",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Hulls:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHulls_PROPERTIES

      // END_USER_CODE-USER_txtHulls_PROPERTIES
    },
    txtHullsPct: {
      name: "txtHullsPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullsPct_PROPERTIES

      // END_USER_CODE-USER_txtHullsPct_PROPERTIES
    },
    txtIDTfromtoVendor: {
      name: "txtIDTfromtoVendor",
      type: "TextBoxWidget",
      parent: "grpbxInterDepartmentalTransfer",
      Label: "IDT from/to Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIDTfromtoVendor_PROPERTIES

      // END_USER_CODE-USER_txtIDTfromtoVendor_PROPERTIES
    },
    txtJumbo: {
      name: "txtJumbo",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Jumbo:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtJumbo_PROPERTIES

      // END_USER_CODE-USER_txtJumbo_PROPERTIES
    },
    txtJumboPct: {
      name: "txtJumboPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtJumboPct_PROPERTIES

      // END_USER_CODE-USER_txtJumboPct_PROPERTIES
    },
    txtKernelsREL: {
      name: "txtKernelsREL",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Kernels REL:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernelsREL_PROPERTIES

      // END_USER_CODE-USER_txtKernelsREL_PROPERTIES
    },
    txtKernelsRPS: {
      name: "txtKernelsRPS",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Kernels RPS:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernelsRPS_PROPERTIES

      // END_USER_CODE-USER_txtKernelsRPS_PROPERTIES
    },
    txtKernelValuePerPound: {
      name: "txtKernelValuePerPound",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtKernelValuePerPound_PROPERTIES

      // END_USER_CODE-USER_txtKernelValuePerPound_PROPERTIES
    },
    txtKernelValuePerTon: {
      name: "txtKernelValuePerTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Kernel Value:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtKernelValuePerTon_PROPERTIES

      // END_USER_CODE-USER_txtKernelValuePerTon_PROPERTIES
    },
    txtKernRidingELK: {
      name: "txtKernRidingELK",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Kern Riding ELK:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernRidingELK_PROPERTIES

      // END_USER_CODE-USER_txtKernRidingELK_PROPERTIES
    },
    txtKernRidingPrescribe: {
      name: "txtKernRidingPrescribe",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Kern Riding Prescribe:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernRidingPrescribe_PROPERTIES

      // END_USER_CODE-USER_txtKernRidingPrescribe_PROPERTIES
    },
    txtKernRidingPrescribePct: {
      name: "txtKernRidingPrescribePct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernRidingPrescribePct_PROPERTIES

      // END_USER_CODE-USER_txtKernRidingPrescribePct_PROPERTIES
    },
    txtLoad: {
      name: "txtLoad",
      type: "TextBoxWidget",
      parent: "grpbxSeg",
      Label: "Load #:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoad_PROPERTIES

      // END_USER_CODE-USER_txtLoad_PROPERTIES
    },
    txtLot: {
      name: "txtLot",
      type: "TextBoxWidget",
      parent: "grpbxSeg",
      Label: "Lot #:",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLot_PROPERTIES

      // END_USER_CODE-USER_txtLot_PROPERTIES
    },
    txtLSK: {
      name: "txtLSK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "LSK:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtLSK_PROPERTIES

      // END_USER_CODE-USER_txtLSK_PROPERTIES
    },
    txtLSKDamageRiding: {
      name: "txtLSKDamageRiding",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "+LSK Damage Riding:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKDamageRiding_PROPERTIES

      // END_USER_CODE-USER_txtLSKDamageRiding_PROPERTIES
    },
    txtLSKFallThru: {
      name: "txtLSKFallThru",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "-LSK Fall Thru:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKFallThru_PROPERTIES

      // END_USER_CODE-USER_txtLSKFallThru_PROPERTIES
    },
    txtLSKRidingGr: {
      name: "txtLSKRidingGr",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "+LSK Riding:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKRidingGr_PROPERTIES

      // END_USER_CODE-USER_txtLSKRidingGr_PROPERTIES
    },
    txtLSKRidingPct: {
      name: "txtLSKRidingPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKRidingPct_PROPERTIES

      // END_USER_CODE-USER_txtLSKRidingPct_PROPERTIES
    },
    txtMeterReading: {
      name: "txtMeterReading",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Meter Reading:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMeterReading_PROPERTIES

      // END_USER_CODE-USER_txtMeterReading_PROPERTIES
    },
    txtMoisture: {
      name: "txtMoisture",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Moisture:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMoisture_PROPERTIES

      // END_USER_CODE-USER_txtMoisture_PROPERTIES
    },
    txtNetELK: {
      name: "txtNetELK",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Net ELK:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetELK_PROPERTIES

      // END_USER_CODE-USER_txtNetELK_PROPERTIES
    },
    txtNetELKPct: {
      name: "txtNetELKPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetELKPct_PROPERTIES

      // END_USER_CODE-USER_txtNetELKPct_PROPERTIES
    },
    txtNetValPerTonExclSLK: {
      name: "txtNetValPerTonExclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Value Per Ton Excluding SLK:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtNetValPerTonExclSLK_PROPERTIES

      // END_USER_CODE-USER_txtNetValPerTonExclSLK_PROPERTIES
    },
    txtNetWeight: {
      name: "txtNetWeight",
      type: "TextBoxWidget",
      parent: "grpbxSettlementInformation",
      Label: "(g) Net Weight",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtNetWeight_PROPERTIES

      // END_USER_CODE-USER_txtNetWeight_PROPERTIES
    },
    txtNetWt: {
      name: "txtNetWt",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Weight:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtNetWt_PROPERTIES

      // END_USER_CODE-USER_txtNetWt_PROPERTIES
    },
    txtNetWtExcludingLSK: {
      name: "txtNetWtExcludingLSK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Weight Excluding LSK:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtNetWtExcludingLSK_PROPERTIES

      // END_USER_CODE-USER_txtNetWtExcludingLSK_PROPERTIES
    },
    txtOI: {
      name: "txtOI",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "O x I $:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtOI_PROPERTIES

      // END_USER_CODE-USER_txtOI_PROPERTIES
    },
    txtOKDamageRiding: {
      name: "txtOKDamageRiding",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "+ OK Damage Riding:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOKDamageRiding_PROPERTIES

      // END_USER_CODE-USER_txtOKDamageRiding_PROPERTIES
    },
    txtOKFallThru: {
      name: "txtOKFallThru",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "-OK Fall Thru:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOKFallThru_PROPERTIES

      // END_USER_CODE-USER_txtOKFallThru_PROPERTIES
    },
    txtOKRidingGr: {
      name: "txtOKRidingGr",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "+OK Riding:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOKRidingGr_PROPERTIES

      // END_USER_CODE-USER_txtOKRidingGr_PROPERTIES
    },
    txtOKRidingPct: {
      name: "txtOKRidingPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOKRidingPct_PROPERTIES

      // END_USER_CODE-USER_txtOKRidingPct_PROPERTIES
    },
    txtOtherFM: {
      name: "txtOtherFM",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Other FM:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherFM_PROPERTIES

      // END_USER_CODE-USER_txtOtherFM_PROPERTIES
    },
    txtPerG: {
      name: "txtPerG",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "/G:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtPerG_PROPERTIES

      // END_USER_CODE-USER_txtPerG_PROPERTIES
    },
    txtPittingDamage: {
      name: "txtPittingDamage",
      type: "TextBoxWidget",
      parent: "grpbxDamage",
      Label: "Pitting Damage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPittingDamage_PROPERTIES

      // END_USER_CODE-USER_txtPittingDamage_PROPERTIES
    },
    txtPittingDamagePct: {
      name: "txtPittingDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxDamage",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPittingDamagePct_PROPERTIES

      // END_USER_CODE-USER_txtPittingDamagePct_PROPERTIES
    },
    txtPremiums: {
      name: "txtPremiums",
      type: "TextBoxWidget",
      parent: "grpbxSettlementInformation",
      Label: "+Premiums",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtPremiums_PROPERTIES

      // END_USER_CODE-USER_txtPremiums_PROPERTIES
    },
    txtProbingPattern: {
      name: "txtProbingPattern",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Probing Pattern:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProbingPattern_PROPERTIES

      // END_USER_CODE-USER_txtProbingPattern_PROPERTIES
    },
    txtProceeds: {
      name: "txtProceeds",
      type: "TextBoxWidget",
      parent: "grpbxSettlementInformation",
      Label: "Proceeds",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtProceeds_PROPERTIES

      // END_USER_CODE-USER_txtProceeds_PROPERTIES
    },
    txtSeal: {
      name: "txtSeal",
      type: "TextBoxWidget",
      parent: "grpbxSeg",
      Label: "Seal #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeal_PROPERTIES

      // END_USER_CODE-USER_txtSeal_PROPERTIES
    },
    txtSeedMeetingSpec: {
      name: "txtSeedMeetingSpec",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Seed Meeting Spec:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedMeetingSpec_PROPERTIES

      // END_USER_CODE-USER_txtSeedMeetingSpec_PROPERTIES
    },
    txtSegValue: {
      name: "txtSegValue",
      type: "TextBoxWidget",
      parent: "grpbxSettlementInformation",
      Label: "Value of Seg",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtSegValue_PROPERTIES

      // END_USER_CODE-USER_txtSegValue_PROPERTIES
    },
    txtSharePercentage1: {
      name: "txtSharePercentage1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage2: {
      name: "txtSharePercentage2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage2_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage2_PROPERTIES
    },
    txtSharePercentage3: {
      name: "txtSharePercentage3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage3_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage3_PROPERTIES
    },
    txtSharePercentage4: {
      name: "txtSharePercentage4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage4_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage4_PROPERTIES
    },
    txtSharePercentage5: {
      name: "txtSharePercentage5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage6: {
      name: "txtSharePercentage6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage7: {
      name: "txtSharePercentage7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage8: {
      name: "txtSharePercentage8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage9: {
      name: "txtSharePercentage9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage10: {
      name: "txtSharePercentage10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage11: {
      name: "txtSharePercentage11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage12: {
      name: "txtSharePercentage12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage13: {
      name: "txtSharePercentage13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage14: {
      name: "txtSharePercentage14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage15: {
      name: "txtSharePercentage15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage16: {
      name: "txtSharePercentage16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage17: {
      name: "txtSharePercentage17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage18: {
      name: "txtSharePercentage18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage19: {
      name: "txtSharePercentage19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSharePercentage20: {
      name: "txtSharePercentage20",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercentage1_PROPERTIES

      // END_USER_CODE-USER_txtSharePercentage1_PROPERTIES
    },
    txtSMK1Gr: {
      name: "txtSMK1Gr",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "SMK 1:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK1Gr_PROPERTIES

      // END_USER_CODE-USER_txtSMK1Gr_PROPERTIES
    },
    txtSMK1Pct: {
      name: "txtSMK1Pct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK1Pct_PROPERTIES

      // END_USER_CODE-USER_txtSMK1Pct_PROPERTIES
    },
    txtSMK2Gr: {
      name: "txtSMK2Gr",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "SMK 2:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK2Gr_PROPERTIES

      // END_USER_CODE-USER_txtSMK2Gr_PROPERTIES
    },
    txtSMK2Pct: {
      name: "txtSMK2Pct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK2Pct_PROPERTIES

      // END_USER_CODE-USER_txtSMK2Pct_PROPERTIES
    },
    txtSMK3Gr: {
      name: "txtSMK3Gr",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "SMK 3:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK3Gr_PROPERTIES

      // END_USER_CODE-USER_txtSMK3Gr_PROPERTIES
    },
    txtSMK3Pct: {
      name: "txtSMK3Pct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK3Pct_PROPERTIES

      // END_USER_CODE-USER_txtSMK3Pct_PROPERTIES
    },
    txtSMK4Gr: {
      name: "txtSMK4Gr",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "SMK 4:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK4Gr_PROPERTIES

      // END_USER_CODE-USER_txtSMK4Gr_PROPERTIES
    },
    txtSMK4Pct: {
      name: "txtSMK4Pct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK4Pct_PROPERTIES

      // END_USER_CODE-USER_txtSMK4Pct_PROPERTIES
    },
    txtSoundSplits: {
      name: "txtSoundSplits",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Sound Splits:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSoundSplits_PROPERTIES

      // END_USER_CODE-USER_txtSoundSplits_PROPERTIES
    },
    txtSoundSplitsPct: {
      name: "txtSoundSplitsPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSoundSplitsPct_PROPERTIES

      // END_USER_CODE-USER_txtSoundSplitsPct_PROPERTIES
    },
    txtSts: {
      name: "txtSts",
      type: "TextBoxWidget",
      parent: "grpbxTradeDetails",
      Label: "Status:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSts_PROPERTIES

      // END_USER_CODE-USER_txtSts_PROPERTIES
    },
    txtSupportPercent: {
      name: "txtSupportPercent",
      type: "TextBoxWidget",
      parent: "grpbxSettlementInformation",
      Label: "% of Support",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtSupportPercent_PROPERTIES

      // END_USER_CODE-USER_txtSupportPercent_PROPERTIES
    },
    txtTckt: {
      name: "txtTckt",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Tickets:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtTckt_PROPERTIES

      // END_USER_CODE-USER_txtTckt_PROPERTIES
    },
    txtTKCLbs: {
      name: "txtTKCLbs",
      type: "TextBoxWidget",
      parent: "grpbxSettlementInformation",
      Label: "TKC Lbs",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtTKCLbs_PROPERTIES

      // END_USER_CODE-USER_txtTKCLbs_PROPERTIES
    },
    txtTotal: {
      name: "txtTotal",
      type: "TextBoxWidget",
      parent: "grpbxScaleTickets",
      Label: "Total:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtTotal_PROPERTIES

      // END_USER_CODE-USER_txtTotal_PROPERTIES
    },
    txtTotalDamage: {
      name: "txtTotalDamage",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total Damage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDamage_PROPERTIES

      // END_USER_CODE-USER_txtTotalDamage_PROPERTIES
    },
    txtTotalDamagePct: {
      name: "txtTotalDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDamagePct_PROPERTIES

      // END_USER_CODE-USER_txtTotalDamagePct_PROPERTIES
    },
    txtTotalDiscountsPerPound: {
      name: "txtTotalDiscountsPerPound",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtTotalDiscountsPerPound_PROPERTIES

      // END_USER_CODE-USER_txtTotalDiscountsPerPound_PROPERTIES
    },
    txtTotalDiscountsPerTon: {
      name: "txtTotalDiscountsPerTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total Discounts:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtTotalDiscountsPerTon_PROPERTIES

      // END_USER_CODE-USER_txtTotalDiscountsPerTon_PROPERTIES
    },
    txtTotalELKDamage: {
      name: "txtTotalELKDamage",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total ELK Damage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalELKDamage_PROPERTIES

      // END_USER_CODE-USER_txtTotalELKDamage_PROPERTIES
    },
    txtTotalELKDamagePct: {
      name: "txtTotalELKDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalELKDamagePct_PROPERTIES

      // END_USER_CODE-USER_txtTotalELKDamagePct_PROPERTIES
    },
    txtTotalKernels: {
      name: "txtTotalKernels",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total Kernels:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernels_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernels_PROPERTIES
    },
    txtTotalKernelsAndHulls: {
      name: "txtTotalKernelsAndHulls",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total Kernels and Hulls:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernelsAndHulls_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernelsAndHulls_PROPERTIES
    },
    txtTotalLoadVal: {
      name: "txtTotalLoadVal",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total Value of Load (g):",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtTotalLoadVal_PROPERTIES

      // END_USER_CODE-USER_txtTotalLoadVal_PROPERTIES
    },
    txtTotalLoadValue: {
      name: "txtTotalLoadValue",
      type: "TextBoxWidget",
      parent: "grpbxSettlementInformation",
      Label: "(p) Total (Value of Load)",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtTotalLoadValue_PROPERTIES

      // END_USER_CODE-USER_txtTotalLoadValue_PROPERTIES
    },
    txtTotalOK: {
      name: "txtTotalOK",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total OK:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalOK_PROPERTIES

      // END_USER_CODE-USER_txtTotalOK_PROPERTIES
    },
    txtTotalOKPct: {
      name: "txtTotalOKPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalOKPct_PROPERTIES

      // END_USER_CODE-USER_txtTotalOKPct_PROPERTIES
    },
    txtTotalPerPound: {
      name: "txtTotalPerPound",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtTotalPerPound_PROPERTIES

      // END_USER_CODE-USER_txtTotalPerPound_PROPERTIES
    },
    txtTotalPerTon: {
      name: "txtTotalPerTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtTotalPerTon_PROPERTIES

      // END_USER_CODE-USER_txtTotalPerTon_PROPERTIES
    },
    txtTotalSLK: {
      name: "txtTotalSLK",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total LSK:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSLK_PROPERTIES

      // END_USER_CODE-USER_txtTotalSLK_PROPERTIES
    },
    txtTotalSLKPct: {
      name: "txtTotalSLKPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSLKPct_PROPERTIES

      // END_USER_CODE-USER_txtTotalSLKPct_PROPERTIES
    },
    txtTotalSMK: {
      name: "txtTotalSMK",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total SMK:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSMK_PROPERTIES

      // END_USER_CODE-USER_txtTotalSMK_PROPERTIES
    },
    txtTotalSMKRScreen: {
      name: "txtTotalSMKRScreen",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total SMKR Screen:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSMKRScreen_PROPERTIES

      // END_USER_CODE-USER_txtTotalSMKRScreen_PROPERTIES
    },
    txtTotalSMKRScreenPct: {
      name: "txtTotalSMKRScreenPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSMKRScreenPct_PROPERTIES

      // END_USER_CODE-USER_txtTotalSMKRScreenPct_PROPERTIES
    },
    txtTradeInspectSC95: {
      name: "txtTradeInspectSC95",
      type: "TextBoxWidget",
      parent: "grpbxTradeDetails",
      Label: "Trade Inspect/SC95 #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeInspectSC95_PROPERTIES

      // END_USER_CODE-USER_txtTradeInspectSC95_PROPERTIES
    },
    txtTradeSettle1007: {
      name: "txtTradeSettle1007",
      type: "TextBoxWidget",
      parent: "grpbxTradeDetails",
      Label: "Trade Settle 1007 #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtTradeSettle1007_PROPERTIES

      // END_USER_CODE-USER_txtTradeSettle1007_PROPERTIES
    },
    txtValPerPoundExclSLK: {
      name: "txtValPerPoundExclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Per Pound Excluding LSK:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtValPerPoundExclSLK_PROPERTIES

      // END_USER_CODE-USER_txtValPerPoundExclSLK_PROPERTIES
    },
    txtValPerPoundInclSLK: {
      name: "txtValPerPoundInclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Per Pound Including LSK:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtValPerPoundInclSLK_PROPERTIES

      // END_USER_CODE-USER_txtValPerPoundInclSLK_PROPERTIES
    },
    txtVehicleWt: {
      name: "txtVehicleWt",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Wt. of Vehicle:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtVehicleWt_PROPERTIES

      // END_USER_CODE-USER_txtVehicleWt_PROPERTIES
    },
    txtVendor1: {
      name: "txtVendor1",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor2: {
      name: "txtVendor2",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor2_PROPERTIES

      // END_USER_CODE-USER_txtVendor2_PROPERTIES
    },
    txtVendor3: {
      name: "txtVendor3",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor3_PROPERTIES

      // END_USER_CODE-USER_txtVendor3_PROPERTIES
    },
    txtVendor4: {
      name: "txtVendor4",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor4_PROPERTIES

      // END_USER_CODE-USER_txtVendor4_PROPERTIES
    },
    txtVendor5: {
      name: "txtVendor5",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor6: {
      name: "txtVendor6",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor7: {
      name: "txtVendor7",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor8: {
      name: "txtVendor8",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor9: {
      name: "txtVendor9",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor10: {
      name: "txtVendor10",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor11: {
      name: "txtVendor11",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor12: {
      name: "txtVendor12",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor13: {
      name: "txtVendor13",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor14: {
      name: "txtVendor14",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor15: {
      name: "txtVendor15",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor16: {
      name: "txtVendor16",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor17: {
      name: "txtVendor17",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor18: {
      name: "txtVendor18",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor19: {
      name: "txtVendor19",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor20: {
      name: "txtVendor20",
      type: "AutocompleteWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVicamReading: {
      name: "txtVicamReading",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Vicam Reading:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVicamReading_PROPERTIES

      // END_USER_CODE-USER_txtVicamReading_PROPERTIES
    },
    txtWeightLessFM: {
      name: "txtWeightLessFM",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Weight Less FM:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtWeightLessFM_PROPERTIES

      // END_USER_CODE-USER_txtWeightLessFM_PROPERTIES
    },
    txtWormDamage: {
      name: "txtWormDamage",
      type: "TextBoxWidget",
      parent: "grpbxSeg",
      Label: "Worm Damage %:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWormDamage_PROPERTIES

      // END_USER_CODE-USER_txtWormDamage_PROPERTIES
    },
    txtWtIncludingVehicle: {
      name: "txtWtIncludingVehicle",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Wt. Including Vehicle:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false
      // START_USER_CODE-USER_txtWtIncludingVehicle_PROPERTIES

      // END_USER_CODE-USER_txtWtIncludingVehicle_PROPERTIES
    },
    grpbxTradeDetails: {
      name: "grpbxTradeDetails",
      type: "GroupBoxWidget",
      parent: "TradeSettleProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxTradeDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxTradeDetails_PROPERTIES
    },
    grpbxScaleTickets: {
      name: "grpbxScaleTickets",
      type: "GroupBoxWidget",
      parent: "TradeSettleProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "6",
      // START_USER_CODE-USER_grpbxScaleTickets_PROPERTIES

      // END_USER_CODE-USER_grpbxScaleTickets_PROPERTIES
    },
    grpbxTradeSettleProfile: {
      name: "grpbxTradeSettleProfile",
      type: "GroupBoxWidget",
      parent: "TradeSettleProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxTradeSettleProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxTradeSettleProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "TradeSettleProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "10",
      HasLabel: false,
      Cols: "10",
      ColsForTabPotrait: "10",
      ColsForLargeDesktop: "10",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "Settlements#ReviewDeductionTracking": {},

        "Settlements#TradeSettleApply": {},

        "Settlements#TradeSettlePaymentView": {},

        "Settlements#TradeSettlePremDeductView": {},

        "Settlements#TradeSettleScaleTicket": {},
      },
      REVERSE: {
        "Settlements#ReviewDeductionTracking": {},

        "Settlements#TradeSettleApply": {},

        "Settlements#TradeSettlePaymentView": {},

        "Settlements#TradeSettlePremDeductView": {},

        "Settlements#TradeSettleScaleTicket": {},
      },
    },
  };
  let _buttonServices = {
    ReviewDeductionTracking: {},
    TradeSettleApply: {},
    TradeSettlePaymentView: {},
    TradeSettlePremDeductView: {},
    TradeSettleScaleTicket: {},
  };
  let _buttonNavigation = {
    btn1: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btn2: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btn3: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btn4: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    // btnAddST: {
    //   DEFAULT: ["Settlements#TradeSettleScaleTicket#DEFAULT#true#Click"],
    // },
    // btnEditST: {
    //   DEFAULT: ["Settlements#TradeSettleScaleTicket#DEFAULT#true#Click"],
    // },

    // btnCreateSettlement: {
    //   DEFAULT: ["Settlements#TradeSettleApply#DEFAULT#true#Click"],
    // },
    // btnPremsDeduct: {
    //   DEFAULT: ["Settlements#TradeSettlePremDeductView#DEFAULT#true#Click"],
    // },
    // btnPayments: {
    //   DEFAULT: ["Settlements#TradeSettlePaymentView#DEFAULT#true#Click"],
    // },
    // btnDeductTrack: {
    //   DEFAULT: ["Settlements#ReviewDeductionTracking#DEFAULT#true#Click"],
    // },
    // btnAgreeAppl: {
    //   DEFAULT: ["Settlements#TradeSettleApply#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};

  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const [ppsdefault, setPPSdefault] = useState(true);

  const [newppsdefault, setnewppsdefault] = useState(true);
  const [generate1007ind, setGenerate1007ind] = useState('')
  const [dblMinKernHullPct, setDblMinKernHullPct] = useState('');
  const [dblMaxKernHullPct, setDblMaxKernHullPct] = useState('');
  const [lstGramEntryInd, setlstGramEntryInd] = useState('')

  const [tradesettleheader, setTradesettleheader] = useState([])
  const [tradesettlevendorsplit, setTradesettlevendorsplit] = useState([])
  const [tradesettleremark, setTradesettleremark] = useState([])
  const [tradesettlescaletickets, setTradesettlescaletickets] = useState([])
  const [tradesettlepremdeduct, setTradesettlepremdeduct] = useState([])
  const [calculationList, setCalculationList] = useState([])
  const [allgetvalue, setAllgetvalue] = useState({})

  const [bAcceptAsSeedNotMeetingSpec, setbAcceptAsSeedNotMeetingSpec] = useState('');
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  }, [thisObj]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    initialVendorsplithide();
    FormLoad()
    setValue(thisObj, 'lblCropYear2', cropYearFromLS),
    setData(thisObj, 'CompletionStatus', true);
    setData(thisObj, "TradeSetteleProfileClose", true)
    setData(thisObj, "TradesettlevendorClose", true)
  }, []);

  useEffect(() => {
    if (getData(thisObj, 'preDectScreenOPEN') == true) {
      let sHeader = tradesettleheader;
      let RetrieveValueCalculationList = calculationList;
      let sVendorSplits = tradesettlevendorsplit;
      let sScaleTickets = tradesettlescaletickets;
      PremDeductions(sHeader[0].grade_pricing_method, RetrieveValueCalculationList, sHeader, sVendorSplits, sScaleTickets)
    }
    setData(thisObj, 'preDectScreenOPEN', '');
  }, [getData(thisObj, 'preDectScreenOPEN')])

  useEffect(() => {
    setStopFirstRender(stopFirstRender + 1)
    if (stopFirstRender >= 1) {
      let vndrnmbr = getData(thisObj, 'vendorDetails') == null ? '' : getData(thisObj, 'vendorDetails').VendorNumber
      getVendorDetails(vndrnmbr)
    }
  }, [getData(thisObj, 'vendorDetails')])

  useEffect(() => {
    let data = getData(thisObj, 'ScaleTicketData');
    if (data != undefined && data != null && data != "") {
      setScaleTicketData();
    }
    setData(thisObj, 'ScaleTicketData', '')
  }, [getData(thisObj, 'ScaleTicketData')])

  useEffect(() => {
    if (sPermission == "I") {
      disable(thisObj,'btnVoid')
      disable(thisObj,'btnUnvoid')
      disable(thisObj,'btnDelete')
      disable(thisObj,'btnSave')
      disable(thisObj,'btnCreateSettlement')
      disable(thisObj,'btnReversal')
      disable(thisObj,'btnAddST')
      disable(thisObj,'btnDeleteST')
    }
    if (sPermission == "U") {
      disable(thisObj,'btnDelete')
    }
  }, [sPermission])

  const initialVendorsplithide = () => {
    let VendorSplitsHideArray=[]
    for(let i=4;i<=19;i++){
      VendorSplitsHideArray.push(`grpbxVendorSplitRow${i}`)
    }
    hideWidgets(thisObj,VendorSplitsHideArray)
    // hide(thisObj, 'grpbxVendorSplitRow4')
    // hide(thisObj, 'grpbxVendorSplitRow5')
    // hide(thisObj, 'grpbxVendorSplitRow6')
    // hide(thisObj, 'grpbxVendorSplitRow7')
    // hide(thisObj, 'grpbxVendorSplitRow8')
    // hide(thisObj, 'grpbxVendorSplitRow9')
    // hide(thisObj, 'grpbxVendorSplitRow10')
    // hide(thisObj, 'grpbxVendorSplitRow11')
    // hide(thisObj, 'grpbxVendorSplitRow12')
    // hide(thisObj, 'grpbxVendorSplitRow13')
    // hide(thisObj, 'grpbxVendorSplitRow14')
    // hide(thisObj, 'grpbxVendorSplitRow15')
    // hide(thisObj, 'grpbxVendorSplitRow16')
    // hide(thisObj, 'grpbxVendorSplitRow17')
    // hide(thisObj, 'grpbxVendorSplitRow18')
    // hide(thisObj, 'grpbxVendorSplitRow19')
  }

  const txtVendorBlur = (event) => {
    let cntrlId = event.target.name
    let currentRow = cntrlId.match(/(\d+)/)[0]
    let vendor1 = event.target.value
    if (vendor1 != "" && vendor1 != undefined) {
      setData(thisObj, "vndrRowNo", currentRow)
      setData(thisObj, "vndrid", vendor1)
      getVendorDetails(vendor1)
    }
    else {
      let newRemit = 'ddRemitTo' + currentRow
      let newSharePct = 'txtSharePercentage' + currentRow
      let lblVendor = 'lblXX' + currentRow

      setValue(thisObj, newSharePct, "")
      setValue(thisObj, lblVendor, "")
      thisObj.state[newRemit].valueList = [];
      setValue(thisObj, newRemit, '')
    }
  }

  const txtsharePrcntgBlur = (event) => {
    let cntrlId = event.target.name
    let newCntrl = cntrlId.match(/(\d+)/)[0]
    let newTxtVndr = 'txtSharePercentage' + newCntrl;
    let addpercent = 0.00
    addpercent = addpercent + parseFloat(getValue(thisObj, newTxtVndr))
    let checknan = parseInt(getValue(thisObj, newTxtVndr)).toFixed(2)

    if (newTxtVndr !== '' && newTxtVndr !== undefined && checknan !== 'NaN') {
      setValue(thisObj, newTxtVndr, addpercent.toFixed(2))
    } else {
      setValue(thisObj, newTxtVndr, '')
    }
  }

  const RemitDetails = async (vendornum, Remit, newLblxx, addsplitremito) => {
    let response = await ContractManagementService.RetieveRemitDetails(vendornum)
    let data = response;
    if (data.length > 0) {
      let js = [];
      let lblXXname = data[0].name

      for (var i = 0; i < data.length; i++) {
        let description = data[i].remittoid + '-' + data[i].name + '-' + data[i].city + '-' + data[i].state;
        let obj = { key: data[i].remittoid, description: description };
        js.push(obj);
      }
      setValue(thisObj, newLblxx, lblXXname)
      thisObj.state[Remit].valueList = js;
      setValue(thisObj, Remit, addsplitremito);
    }
  };

  const getVendorDetails = async (vendorId) => {
    if (vendorId !== "" && vendorId !== null && vendorId !== undefined) {
      let vndrRowNo = getData(thisObj, "vndrRowNo")
      let js = [];
      //js.push({ key: '', description: '' })
      let response = await ContractManagementService.RetieveRemitDetails(vendorId)
      let data = response;

      if (data.length > 0) {

        let lblXXname = data[0].name
        for (var i = 0; i < data.length; i++) {
          let description = data[i].remittoid + '-' + data[i].name + '-' + data[i].city + '-' + data[i].state;
          let obj = { key: data[i].remittoid, description: description };
          js.push(obj);
        }
        let newVendor = 'txtVendor' + vndrRowNo
        let newLblVendor = 'lblXX' + vndrRowNo
        let newRemitTo = 'ddRemitTo' + vndrRowNo
        let arrVal = getData(thisObj, "arrRow")

        setData(thisObj, "arrRow", [...arrVal, parseInt(vndrRowNo)])
        setValue(thisObj, newVendor, [vendorId])
        setValue(thisObj, newLblVendor, lblXXname)
        thisObj.state[newRemitTo].valueList = js;
        setValue(thisObj, newRemitTo, js.at(0).key);

        let finalArr = getData(thisObj, "arrRow")
        let result = finalArr.filter((arr) => {
          return (arr == vndrRowNo)
        })
        if (vndrRowNo >= 4 && vndrRowNo <= 19 && result.length <= 1) {
          let newGrpbx = `grpbxVendorSplitRow` + (Number(vndrRowNo) + 1)
          show(thisObj, newGrpbx)
        }
      }
    }
  }

  const btnVendorClick = (cntrlId) => {
    setData(thisObj, "vndrRowNo", cntrlId.match(/(\d+)/)[0])
    goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click")
  }

  const setScaleTicketData = async () => {
    let rowValue = thisObj.state.gridScaleTickets.selected[0];
    let data = getData(thisObj, 'ScaleTicketData')
    let gridScaleTickets = getValue(thisObj, 'gridScaleTickets')
    let caption = getData(thisObj, "TradeSettleScaleTicketdata");

    let obj = {};
    if (data != undefined && data != null) {
      if (caption != undefined && caption != null) {
        if (caption.cmdok == 'Update') {
          gridScaleTickets[rowValue.rowID$].txtcolLoad = data.load_num;
          gridScaleTickets[rowValue.rowID$].txtcolVehicle = data.vehicle_num;
          gridScaleTickets[rowValue.rowID$].txtcolShipDate = data.shp_date;
          gridScaleTickets[rowValue.rowID$].txtcolShipTicket = data.shp_scale_tick !== 0 ? data.shp_scale_tick : "";
          gridScaleTickets[rowValue.rowID$].txtcolShipWt = data.shp_peanut_wt !== 0 ? data.shp_peanut_wt : "";
          gridScaleTickets[rowValue.rowID$].txtcolRecvDate = data.rec_date;
          gridScaleTickets[rowValue.rowID$].txtcolRecvTicket = data.rec_scale_tick !== 0 ? data.rec_scale_tick : "";
          gridScaleTickets[rowValue.rowID$].txtcolRecvWt = data.rec_peanut_wt !== 0 ? data.rec_peanut_wt : "";
          gridScaleTickets[rowValue.rowID$].truck_line = data.truck_line
          gridScaleTickets[rowValue.rowID$].driver_name = data.driver_name
          gridScaleTickets[rowValue.rowID$].arrTicketInfo = data
          switch (data.inventory_with_ind) {
            case "S":
              gridScaleTickets[rowValue.rowID$].txtcolInventoryWt = data.shp_peanut_wt
              break;
            case "R":
              gridScaleTickets[rowValue.rowID$].txtcolInventoryWt = data.rec_peanut_wt
              break;
            case "O":
              gridScaleTickets[rowValue.rowID$].txtcolInventoryWt = data.inventory_other_wt
              break;
          }
        } else {
          obj.txtcolLoad = data.load_num;
          obj.txtcolVehicle = data.vehicle_num;
          obj.txtcolShipDate = data.shp_date;
          obj.txtcolShipTicket = data.shp_scale_tick !== 0 ? data.shp_scale_tick : "";
          obj.txtcolShipWt = data.shp_peanut_wt !== 0 ? data.shp_peanut_wt : "";
          obj.txtcolRecvDate = data.rec_date;
          obj.txtcolRecvTicket = data.rec_scale_tick !== 0 ? data.rec_scale_tick : "";
          obj.txtcolRecvWt = data.rec_peanut_wt !== 0 ? data.rec_peanut_wt : "";
          obj.truck_line = data.truck_line
          obj.driver_name = data.driver_name
          obj.arrTicketInfo = data
          switch (data.inventory_with_ind) {
            case "S":
              obj.txtcolInventoryWt = data.shp_peanut_wt
              break;
            case "R":
              obj.txtcolInventoryWt = data.rec_peanut_wt
              break;
            case "O":
              obj.txtcolInventoryWt = data.inventory_other_wt
              break;
          }
          gridScaleTickets.push(obj);

          if (gridScaleTickets.length >= 5) {
            disable(thisObj, 'btnAddST')
          }
        }
        setValue(thisObj, "gridScaleTickets", gridScaleTickets);

        let dataArr = [];
        for (let i = 0; i < gridScaleTickets.length; i++) {
          dataArr.push(gridScaleTickets[i].arrTicketInfo);
        }
        CalculateTotalPounds(dataArr, gridScaleTickets)
      }
    }
  }
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  const FormLoad = async () => {
    bFillLocationList(true); // Method for binding Buying Points
    bFillTypeList(); // Method for binding Peanut Type dropdown
    bFillVarietyList(null, null);
    bFillGenerationList(true);
    bFillOleicList(); // Method for binding Oleic values
    bFillSegmentList(); // Method for binding Segments
    bFillEdibleOilList(true); // Method for binding Seed Grower List
    bFillCategoryList("f", "f")
    //bFillWarehouseBinList()
    RetrievePeanutStaticValues() // PPS default
    // hide(thisObj, 'radioGradePricingMethod')
    setValue(thisObj, 'radioGradePricingMethod', 'FULL')
    setValue(thisObj, 'ddShipFromToLocation', '2CY');
    disable(thisObj, 'txtSts')
    hide(thisObj, 'btnUnvoid')
    hide(thisObj, 'btnReversal')
    setData(thisObj, "arrRow", [])
    lblLoadType_Change()
  }

  const lblLoadType_Change = async () => {
    let AddUpdateDetails = getData(thisObj, 'CreateValue');
    let ddShipFromToLocation = getValue(thisObj, 'ddShipFromToLocation')
    let ddShipFromToLocationlist = thisObj.state['ddShipFromToLocation'].valueList;
    let ddShipFromToLocationval = thisObj.values['ddShipFromToLocation'];
    let sellstGramEntryIndElem = ddShipFromToLocationlist.filter(elem => elem.key === ddShipFromToLocationval);
    if (AddUpdateDetails !== null) {
      let data = AddUpdateDetails;
      //setLblLoadType(data[0].LoadTypeBtnCaption)
      if (data[0].LoadTypeBtnCaption == "Edit") {
        setData(thisObj, "ScaleTicketData", null);
        setPermission(data[0].permission)
        await bFillForm(data[0].Inspnum, data[0].Locnum, data[0].LoadTypeBtnCaption)
        setValue(thisObj, 'ddShipFromToLocation', data[0].Locnum)
          if (data[0].permission == "I") {
            disable(thisObj,'btnVoid')
            disable(thisObj,'btnUnvoid')
            disable(thisObj,'btnDelete')
            disable(thisObj,'btnSave')
            disable(thisObj,'btnCreateSettlement')
            disable(thisObj,'btnReversal')
            disable(thisObj,'btnAddST')
            disable(thisObj,'btnDeleteST')
          }
            if (data[0].permission == "U") {
          disable(thisObj,'btnDelete')
        }
        //bFillWarehouseBinList()
      } else {
        setLoading(true)
        setValue(thisObj, "gridScaleTickets", [])
        setData(thisObj, "ScaleTicketData", null);
        setValue(thisObj, 'txtTotal', '')
        setValue(thisObj, 'txtSts', 'Pending')
        setValue(thisObj, 'radioGradePricingMethod', 'FULL')
        setValue(thisObj, 'ddEdibleOil', 'E');
        setValue(thisObj, 'ddOleic', '');
        setValue(thisObj, 'ddSeg', '1');
        setValue(thisObj, 'radioGramsPercentage', '2');
        FillCompanyList(ddShipFromToLocation, data[0].Inspnum)
        await fetchRetrieveAreaControlDetail(sellstGramEntryIndElem[0].lstAreaId)
        await EnableDisableControls(ddShipFromToLocation, "Pending", '1', [], data[0].LoadTypeBtnCaption)
        setLoading(false)
      }
    }
    setData(thisObj, 'LoaderIndicator', true)
  }

  const DisablePercents = () => {
    let radioGradePricingMethod = getValue(thisObj, 'radioGradePricingMethod')
    if (radioGradePricingMethod == 'TKC', '') {
      clearValue(thisObj, 'txtDamageSplits', '')
      clearValue(thisObj, 'txtOKDamageRiding', '')
      clearValue(thisObj, 'txtOKFallThru', '')
      clearValue(thisObj, 'txtOKRidingPct', '')
      clearValue(thisObj, 'txtOKRidingGr', '')
    }
    disable(thisObj, 'txtTotalSMKRScreenPct')
    disable(thisObj, 'txtSoundSplitsPct')
    disable(thisObj, 'txtTotalOKPct')
    disable(thisObj, 'txtHullsPct')
    disable(thisObj, 'txtFreezeDamagePct')
    disable(thisObj, 'txtConcealedRMDPct')
    disable(thisObj, 'txtCrackedBrokenPct')
    disable(thisObj, 'txtDiscoloredShellsPct')
    disable(thisObj, 'txtHullBrightnessPct')
    disable(thisObj, 'txtJumboPct')
    disable(thisObj, 'txtKernRidingELKPct')
    disable(thisObj, 'txtTotalELKDamagePct')
    disable(thisObj, 'txtNetELKPct')
    disable(thisObj, 'txtKernRidingPrescribePct')
    disable(thisObj, 'txtForeignMaterialPct')
    disable(thisObj, 'txtTotalSLKPct')
    disable(thisObj, 'txtFancyPct')
    disable(thisObj, 'txtPittingDamagePct')
    disable(thisObj, 'txtTotalDamagePct')
    if (radioGradePricingMethod == 'TKC') {
      disable(thisObj, 'txtDamageSplits')
      disable(thisObj, 'txtOKDamageRiding')
      disable(thisObj, 'txtOKFallThru')
      disable(thisObj, 'txtOKRidingPct')
      disable(thisObj, 'txtOKRidingGr')
    }

  }

  const EnableGrams = () => {
    let radioGradePricingMethod = getValue(thisObj, 'radioGradePricingMethod')
    if (radioGradePricingMethod == "TKC") {
      DisableGrams()
      enable(thisObj, 'txtTotalSMKRScreen')
      enable(thisObj, 'txtHulls')
      enable(thisObj, 'txtForeignMtrl')
      enable(thisObj, 'txtTotalSLK')
    }
    else {
      enable(thisObj, 'txtTotalSMKRScreen')
      enable(thisObj, 'txtSoundSplits')
      enable(thisObj, 'txtTotalOK')
      enable(thisObj, 'txtHulls')
      enable(thisObj, 'txtFreezeDamage')
      enable(thisObj, 'txtConcealedRMD')
      enable(thisObj, 'txtCrackedBroken')
      enable(thisObj, 'txtDiscoloredShells')
      enable(thisObj, 'txtHullBrightness')
      enable(thisObj, 'txtJumbo')
      enable(thisObj, 'txtKernRidingELK')
      enable(thisObj, 'txtTotalELKDamage')
      enable(thisObj, 'txtNetELK')
      enable(thisObj, 'txtKernRidingPrescribe')
      enable(thisObj, 'txtForeignMtrl')
      enable(thisObj, 'txtTotalSLK')
      enable(thisObj, 'txtFancy')
      enable(thisObj, 'txtPittingDamage')
      enable(thisObj, 'txtTotalDamage')
      enable(thisObj, 'txtDamageSplits')
      enable(thisObj, 'txtOKDamageRiding')
      enable(thisObj, 'txtOKFallThru')
      enable(thisObj, 'txtOKRidingPct')
      enable(thisObj, 'txtOKRidingGr')
    }
  }

  const DisableGrams = () => {
    disable(thisObj, 'txtTotalSMKRScreen')
    disable(thisObj, 'txtSoundSplits')
    disable(thisObj, 'txtTotalOK')
    disable(thisObj, 'txtHulls')
    disable(thisObj, 'txtFreezeDamage')
    disable(thisObj, 'txtConcealedRMD')
    disable(thisObj, 'txtCrackedBroken')
    disable(thisObj, 'txtDiscoloredShells')
    disable(thisObj, 'txtHullBrightness')
    disable(thisObj, 'txtJumbo')
    disable(thisObj, 'txtKernRidingELK')
    disable(thisObj, 'txtTotalELKDamage')
    disable(thisObj, 'txtNetELK')
    disable(thisObj, 'txtKernRidingPrescribe')
    disable(thisObj, 'txtForeignMtrl')
    disable(thisObj, 'txtTotalSLK')
    disable(thisObj, 'txtFancy')
    disable(thisObj, 'txtPittingDamage')
    disable(thisObj, 'txtTotalDamage')
    let radioGradePricingMethod = getValue(thisObj, 'radioGradePricingMethod')
    if (radioGradePricingMethod == 'TKC') {
      disable(thisObj, 'txtDamageSplits')
      disable(thisObj, 'txtOKDamageRiding')
      disable(thisObj, 'txtOKFallThru')
      disable(thisObj, 'txtOKRidingPct')
      disable(thisObj, 'txtOKRidingGr')
    }
    clearValue(thisObj, 'txtTotalSMKRScreen', '')
    clearValue(thisObj, 'txtSoundSplits', '')
    clearValue(thisObj, 'txtTotalOK', '')
    clearValue(thisObj, 'txtHulls', '')
    clearValue(thisObj, 'txtFreezeDamage', '')
    clearValue(thisObj, 'txtConcealedRMD', '')
    clearValue(thisObj, 'txtCrackedBroken', '')
    clearValue(thisObj, 'txtDiscoloredShells', '')
    clearValue(thisObj, 'txtHullBrightness', '')
    clearValue(thisObj, 'txtJumbo', '')
    clearValue(thisObj, 'txtKernRidingELK', '')
    clearValue(thisObj, 'txtTotalELKDamage', '')
    clearValue(thisObj, 'txtNetELK', '')
    clearValue(thisObj, 'txtKernRidingPrescribe', '')
    clearValue(thisObj, 'txtForeignMtrl', '')
    clearValue(thisObj, 'txtTotalSLK', '')
    clearValue(thisObj, 'txtFancy', '')
    clearValue(thisObj, 'txtPittingDamage', '')
    clearValue(thisObj, 'txtTotalDamage', '')
    if (radioGradePricingMethod == 'TKC', '') {
      clearValue(thisObj, 'txtDamageSplits', '')
      clearValue(thisObj, 'txtOKDamageRiding', '')
      clearValue(thisObj, 'txtOKFallThru', '')
      clearValue(thisObj, 'txtOKRidingPct', '')
      clearValue(thisObj, 'txtOKRidingGr', '')
    }
  }

  const EnablePercents = () => {
    let radioGradePricingMethod = getValue(thisObj, 'radioGradePricingMethod')
    if (radioGradePricingMethod == 'TKC') {
      DisablePercents()
      enable(thisObj, 'txtTotalSMKRScreenPct')
      enable(thisObj, 'txtHullsPct')
      enable(thisObj, 'txtForeignMaterialPct')
      enable(thisObj, 'txtTotalSLKPct')
    } else {
      enable(thisObj, 'txtTotalSMKRScreenPct')
      enable(thisObj, 'txtSoundSplitsPct')
      enable(thisObj, 'txtTotalOKPct')
      enable(thisObj, 'txtHullsPct')
      enable(thisObj, 'txtFreezeDamagePct')
      enable(thisObj, 'txtConcealedRMDPct')
      enable(thisObj, 'txtCrackedBrokenPct')
      enable(thisObj, 'txtDiscoloredShellsPct')
      enable(thisObj, 'txtHullBrightnessPct')
      enable(thisObj, 'txtJumboPct')
      enable(thisObj, 'txtKernRidingELKPct')
      enable(thisObj, 'txtTotalELKDamagePct')
      enable(thisObj, 'txtNetELKPct')
      enable(thisObj, 'txtKernRidingPrescribePct')
      enable(thisObj, 'txtForeignMaterialPct')
      enable(thisObj, 'txtTotalSLKPct')
      enable(thisObj, 'txtFancyPct')
      enable(thisObj, 'txtPittingDamagePct')
      enable(thisObj, 'txtTotalDamagePct')
      enable(thisObj, 'txtDamageSplits')
      enable(thisObj, 'txtOKDamageRiding')
      enable(thisObj, 'txtOKFallThru')
      enable(thisObj, 'txtOKRidingPct')
      enable(thisObj, 'txtOKRidingGr')
    }
  }

  const DisableForPeanutType = () => {
    let ddPeanutType = getValue(thisObj, 'ddPeanutType')
    if (ddPeanutType == "RU" || ddPeanutType == "SP") {
      disable(thisObj, 'txtCrackedBroken')
      disable(thisObj, 'txtDiscoloredShells')
      disable(thisObj, 'txtHullBrightness')
      disable(thisObj, 'txtJumbo')
      disable(thisObj, 'txtKernRidingELK')
      disable(thisObj, 'txtTotalELKDamage')
      disable(thisObj, 'txtNetELK')
      disable(thisObj, 'txtKernRidingPrescribe')
      disable(thisObj, 'txtForeignMaterial')
      disable(thisObj, 'txtTotalSLK')
      disable(thisObj, 'txtFancy')
      disable(thisObj, 'txtCrackedBrokenPct')
      disable(thisObj, 'txtDiscoloredShellsPct')
      disable(thisObj, 'txtHullBrightnessPct')
      disable(thisObj, 'txtJumboPct')
      disable(thisObj, 'txtKernRidingELKPct')
      disable(thisObj, 'txtTotalELKDamagePct')
      disable(thisObj, 'txtNetELKPct')
      disable(thisObj, 'txtKernRidingPrescribePct')
      disable(thisObj, 'txtFancyPct')
      clearValue(thisObj, 'txtCrackedBroken', '')
      clearValue(thisObj, 'txtDiscoloredShells', '')
      clearValue(thisObj, 'txtHullBrightness', '')
      clearValue(thisObj, 'txtJumbo', '')
      clearValue(thisObj, 'txtKernRidingELK', '')
      clearValue(thisObj, 'txtTotalELKDamage', '')
      clearValue(thisObj, 'txtNetELK', '')
      clearValue(thisObj, 'txtKernRidingPrescribe', '')
      clearValue(thisObj, 'txtFancy', '')
      clearValue(thisObj, 'txtCrackedBrokenPct', '')
      clearValue(thisObj, 'txtDiscoloredShellsPct', '')
      clearValue(thisObj, 'txtHullBrightnessPct', '')
      clearValue(thisObj, 'txtJumboPct', '')
      clearValue(thisObj, 'txtKernRidingELKPct', '')
      clearValue(thisObj, 'txtTotalELKDamagePct', '')
      clearValue(thisObj, 'txtNetELKPct', '')
      clearValue(thisObj, 'txtKernRidingPrescribePct', '')
      clearValue(thisObj, 'txtFancyPct', '')
    }
  }

  const EnableDisableVicam = async () => {
    // setLoading(true)
    let ddShipFromToLocationlist = thisObj.state['ddShipFromToLocation'].valueList;
    let ddShipFromToLocationval = thisObj.values['ddShipFromToLocation'];
    let sellstVicamIndElem = ddShipFromToLocationlist.filter(elem => elem.key === ddShipFromToLocationval);
    let ddSegval = thisObj.values['ddSeg'];
    if (sellstVicamIndElem[0].lstVicamInd == "Y") {
      setValue(thisObj, "chkboxReGrading", '3');
    } else {
      if (ddSegval == "3") {
        setValue(thisObj, "chkboxReGrading", '3');
      } else {
        setValue(thisObj, "chkboxReGrading", '');
      }
    }
    // setLoading(false)
  }

  const EnableDisableGradeEntry = () => {
    let radioGramsPercentage = getValue(thisObj, 'radioGramsPercentage')
    if (radioGramsPercentage == "1") {
      DisablePercents()
      EnableGrams()
    } else if (radioGramsPercentage == "2") {
      DisableGrams()
      EnablePercents()
    }
    DisableForPeanutType()
  }

  const GetAccessLevel = async (strFuncSubId, strAccessLevel, buyingpoint) => {
    let Permission = false;
    let GetAccessLevel = await ContractManagementService.RetrieveAccessPermissionDetails('PN1140', strFuncSubId, strAccessLevel, buyingpoint);
    if (GetAccessLevel.length > 0) {
      if (GetAccessLevel[0].permission.toUpperCase() == 'Y') {
        Permission = true;
      }
    }
    return Permission;
  }

  const EnableDisableControls = async (buyingpoint, txtSts, seg_type, data) => {
    // setLoading(true)
    let AddUpdateDetails = getData(thisObj, 'CreateValue');

    EnableDisableGradeEntry()
    let cboLocation = getValue(thisObj, 'ddShipFromToLocation')
    let ddCategory = getValue(thisObj, 'ddCategory')
    if (cboLocation !== "IDT") {
      enable(thisObj, 'ddCategory')
    } else {
      if (txtSts !== "Pending") {
        disable(thisObj, 'ddCategory')
      } else {
        enable(thisObj, 'ddCategory')
      }
    }

    // let txtSts = txtSts
    let segtype = seg_type
    if (segtype == "1") {
      bFillEdibleOilList(false)
      // disable(thisObj,'ddEdibleOil')
    } else {
      bFillGenerationList(false)
      // disable(thisObj,'ddGeneration')
    }
    disable(thisObj, 'txtVicamReading')
    if (AddUpdateDetails[0].LoadTypeBtnCaption == "Add") {
      disable(thisObj, 'btnAcctDist')
      disable(thisObj, 'btnAgreeAppl')
      disable(thisObj, 'btnPayments')
      disable(thisObj, 'btnPremsDeduct')
      disable(thisObj, 'btnPrintSettlement')
      disable(thisObj, 'btnReverse')
      disable(thisObj, 'btnUnvoid')
      disable(thisObj, 'btnDelete')
      disable(thisObj, 'btnVoid')
      disable(thisObj, 'btnCreateSettlement')
      disable(thisObj, 'txtIDTfromtoVendor')
      disable(thisObj, 'ddIDTfromtoCompany')
      disable(thisObj, 'ddIDTfromtoLocation')
      disable(thisObj, 'txtTradeSettle1007')
      disable(thisObj, 'dtSettleDateTime')
      disable(thisObj, 'txtIDTfromtoVendor')
      disable(thisObj, 'txtTotalSMKRScreen')
      disable(thisObj, 'txtCrackedBroken')
      disable(thisObj, 'txtSoundSplits')
      disable(thisObj, 'txtTotalSMK')
      disable(thisObj, 'txtTotalOK')
      disable(thisObj, 'txtTotalDamage')
      disable(thisObj, 'txtTotalKernels')
      disable(thisObj, 'txtHulls')
      disable(thisObj, 'txtTotalKernelsAndHulls')
      disable(thisObj, 'txtFreezeDamage')
      disable(thisObj, 'txtPittingDamage')
      disable(thisObj, 'txtConcealedRMD')
      disable(thisObj, 'txtDiscoloredShells')
      disable(thisObj, 'txtHullBrightness')
      disable(thisObj, 'txtJumbo')
      disable(thisObj, 'txtKernRidingELK')
      disable(thisObj, 'txtTotalELKDamage')
      disable(thisObj, 'txtNetELK')
      disable(thisObj, 'txtKernRidingPrescribe')
      disable(thisObj, 'txtForeignMtrl')
      disable(thisObj, 'txtTotalSLK')
      disable(thisObj, 'txtFancy')
      disable(thisObj, 'txtVicamReading')
      disable(thisObj, 'txtSeedMeetingSpec')
      disable(thisObj, 'txtNetWeight')
      disable(thisObj, 'txtTotalLoadValue')
      disable(thisObj, 'txtTKCLbs')
      disable(thisObj, 'txtAgreementPrice')
      disable(thisObj, 'txtSupportPercent')
      disable(thisObj, 'txtSegValue')
      disable(thisObj, 'txtPremiums')
      disable(thisObj, 'txtDeductions')
      disable(thisObj, 'txtProceeds')
    }
    else {
      disable(thisObj, 'ddShipFromToLocation')
      if (txtSts == 'C') {
        disable(thisObj, 'txtSts')
        disable(thisObj, 'txtTradeInspectSC95')
        disable(thisObj, 'dtSettleDateTime')
        disable(thisObj, 'txt1007Handler')
        disable(thisObj, 'txt1007HandlerName')
        disable(thisObj, 'ddCategory')
        disable(thisObj, 'radioTradeSaleTradePurchase')
        disable(thisObj, 'radioGradePricingMethod')
        disable(thisObj, 'radioGramsPercentage')
        disable(thisObj, 'txtTradeInspectSC95')
        disable(thisObj, 'btnPrintSettlement')
        disable(thisObj, 'txtIDTfromtoVendor')
        disable(thisObj, 'ddIDTfromtoCompany')
        disable(thisObj, 'ddIDTfromtoLocation')
        disable(thisObj, 'txtTradeSettle1007')
        disable(thisObj, 'dtSettleDateTime')
        disable(thisObj, 'txtIDTfromtoVendor')
        disable(thisObj, 'txtTotalSMKRScreen')
        disable(thisObj, 'txtCrackedBroken')
        disable(thisObj, 'txtSoundSplits')
        disable(thisObj, 'txtTotalSMK')
        disable(thisObj, 'txtTotalOK')
        disable(thisObj, 'txtTotalDamage')
        disable(thisObj, 'txtTotalKernels')
        disable(thisObj, 'txtHulls')
        disable(thisObj, 'txtTotalKernelsAndHulls')
        disable(thisObj, 'txtFreezeDamage')
        disable(thisObj, 'txtPittingDamage')
        disable(thisObj, 'txtConcealedRMD')
        disable(thisObj, 'txtDiscoloredShells')
        disable(thisObj, 'txtHullBrightness')
        disable(thisObj, 'txtJumbo')
        disable(thisObj, 'txtKernRidingELK')
        disable(thisObj, 'txtTotalELKDamage')
        disable(thisObj, 'txtNetELK')
        disable(thisObj, 'txtKernRidingPrescribe')
        disable(thisObj, 'txtForeignMtrl')
        disable(thisObj, 'txtTotalSLK')
        disable(thisObj, 'txtFancy')
        disable(thisObj, 'txtVicamReading')
        disable(thisObj, 'txtSeedMeetingSpec')
        disable(thisObj, 'txtNetWeight')
        disable(thisObj, 'txtTotalLoadValue')
        disable(thisObj, 'txtTKCLbs')
        disable(thisObj, 'txtAgreementPrice')
        disable(thisObj, 'txtSupportPercent')
        disable(thisObj, 'txtSegValue')
        disable(thisObj, 'txtPremiums')
        disable(thisObj, 'txtDeductions')
        disable(thisObj, 'txtProceeds')
        disable(thisObj, 'txtCrackedBrokenPct')
        disable(thisObj, 'txtDiscoloredShellsPct')
        disable(thisObj, 'txtHullBrightnessPct')
        disable(thisObj, 'txtJumboPct')
        disable(thisObj, 'txtKernRidingELKPct')
        disable(thisObj, 'txtTotalELKDamagePct')
        disable(thisObj, 'txtNetELKPct')
        disable(thisObj, 'txtKernRidingPrescribePct')
        disable(thisObj, 'btnSave')
        disable(thisObj, 'btnVoid')
        disable(thisObj, 'btnDelete')
        disable(thisObj, 'btnAddST')
        disable(thisObj, 'btnDeleteST')
        show(thisObj, 'btnReversal')
        enable(thisObj, 'btnReversal')
        disable(thisObj, 'btnCreateSettlement')
        // enable(thisObj,'btnCalculate')
        enable(thisObj, 'btnPrintSettlement')
        //Hide Buttons
        hide(thisObj, 'btnCreateSettlement')
        hide(thisObj, 'btnUnvoid')
        disable(thisObj, 'ddIDTfromtoCompany')
        disable(thisObj, 'ddIDTfromtoLocation')

      } else if (txtSts == 'V') {
        disable(thisObj, 'txtSts')
        disable(thisObj, 'txtTradeInspectSC95')
        disable(thisObj, 'dtSettleDateTime')
        disable(thisObj, 'txt1007Handler')
        disable(thisObj, 'txt1007HandlerName')
        disable(thisObj, 'ddCategory')
        disable(thisObj, 'radioTradeSaleTradePurchase')
        // enable(thisObj,'btnCalculate')
        disable(thisObj, 'btnReversal')
        hide(thisObj, 'btnVoid')
        show(thisObj, 'btnUnvoid')
        enable(thisObj, 'btnUnvoid')
        disable(thisObj, 'btnSave')
        disable(thisObj, 'btnAddST')
        disable(thisObj, 'btnDeleteST')
        disable(thisObj, 'btnCreateSettlement')
        disable(thisObj, 'txtTradeInspectSC95')
        enable(thisObj, 'btnPrintSettlement')
        disable(thisObj, 'txtIDTfromtoVendor')
        disable(thisObj, 'ddIDTfromtoCompany')
        disable(thisObj, 'ddIDTfromtoLocation')
        disable(thisObj, 'txtTradeSettle1007')
        disable(thisObj, 'dtSettleDateTime')
        disable(thisObj, 'txtIDTfromtoVendor')
        disable(thisObj, 'txtTotalSMKRScreen')
        disable(thisObj, 'txtCrackedBroken')
        disable(thisObj, 'txtSoundSplits')
        disable(thisObj, 'txtTotalSMK')
        disable(thisObj, 'txtTotalOK')
        disable(thisObj, 'txtTotalDamage')
        disable(thisObj, 'txtTotalKernels')
        disable(thisObj, 'txtHulls')
        disable(thisObj, 'txtTotalKernelsAndHulls')
        disable(thisObj, 'txtFreezeDamage')
        disable(thisObj, 'txtPittingDamage')
        disable(thisObj, 'txtConcealedRMD')
        disable(thisObj, 'txtDiscoloredShells')
        disable(thisObj, 'txtHullBrightness')
        disable(thisObj, 'txtJumbo')
        disable(thisObj, 'txtKernRidingELK')
        disable(thisObj, 'txtTotalELKDamage')
        disable(thisObj, 'txtNetELK')
        disable(thisObj, 'txtKernRidingPrescribe')
        disable(thisObj, 'txtForeignMtrl')
        disable(thisObj, 'txtTotalSLK')
        disable(thisObj, 'txtFancy')
        disable(thisObj, 'txtVicamReading')
        disable(thisObj, 'txtSeedMeetingSpec')
        disable(thisObj, 'txtNetWeight')
        disable(thisObj, 'txtTotalLoadValue')
        disable(thisObj, 'txtTKCLbs')
        disable(thisObj, 'txtAgreementPrice')
        disable(thisObj, 'txtSupportPercent')
        disable(thisObj, 'txtSegValue')
        disable(thisObj, 'txtPremiums')
        disable(thisObj, 'txtDeductions')
        disable(thisObj, 'txtProceeds')
        disable(thisObj, 'txtCrackedBrokenPct')
        disable(thisObj, 'txtDiscoloredShellsPct')
        disable(thisObj, 'txtHullBrightnessPct')
        disable(thisObj, 'txtJumboPct')
        disable(thisObj, 'txtKernRidingELKPct')
        disable(thisObj, 'txtTotalELKDamagePct')
        disable(thisObj, 'txtNetELKPct')
        disable(thisObj, 'txtKernRidingPrescribePct')
        //Hide Buttons
        hide(thisObj, 'btnVoid')
        hide(thisObj, 'btnReversal')
        disable(thisObj, 'ddIDTfromtoCompany')
        disable(thisObj, 'ddIDTfromtoLocation')
      } else if (txtSts == "P") {
        disable(thisObj, 'txtSts')
        enable(thisObj, 'btnAcctDist')
        enable(thisObj, 'btnAgreeAppl')
        enable(thisObj, 'btnPayments')
        enable(thisObj, 'btnPremsDeduct')
        disable(thisObj, 'btnPrintSettlement')
        enable(thisObj, 'btnReverse')
        enable(thisObj, 'btnVoid')
        enable(thisObj, 'btnCreateSettlement')
        // enable(thisObj,'btnCalculate')
        disable(thisObj, 'txtTradeInspectSC95')
        disable(thisObj, 'txtIDTfromtoVendor')
        disable(thisObj, 'ddIDTfromtoCompany')
        disable(thisObj, 'ddIDTfromtoLocation')
        disable(thisObj, 'txtTradeSettle1007')
        disable(thisObj, 'dtSettleDateTime')
        disable(thisObj, 'txtIDTfromtoVendor')
        disable(thisObj, 'txtTotalSMKRScreen')
        disable(thisObj, 'txtCrackedBroken')
        disable(thisObj, 'txtSoundSplits')
        disable(thisObj, 'txtTotalSMK')
        disable(thisObj, 'txtTotalOK')
        disable(thisObj, 'txtTotalDamage')
        disable(thisObj, 'txtTotalKernels')
        disable(thisObj, 'txtHulls')
        disable(thisObj, 'txtTotalKernelsAndHulls')
        disable(thisObj, 'txtFreezeDamage')
        disable(thisObj, 'txtPittingDamage')
        disable(thisObj, 'txtConcealedRMD')
        disable(thisObj, 'txtDiscoloredShells')
        disable(thisObj, 'txtHullBrightness')
        disable(thisObj, 'txtJumbo')
        disable(thisObj, 'txtKernRidingELK')
        disable(thisObj, 'txtTotalELKDamage')
        disable(thisObj, 'txtNetELK')
        disable(thisObj, 'txtKernRidingPrescribe')
        disable(thisObj, 'txtForeignMtrl')
        disable(thisObj, 'txtTotalSLK')
        disable(thisObj, 'txtFancy')
        disable(thisObj, 'txtVicamReading')
        disable(thisObj, 'txtSeedMeetingSpec')
        disable(thisObj, 'txtNetWeight')
        disable(thisObj, 'txtTotalLoadValue')
        disable(thisObj, 'txtTKCLbs')
        disable(thisObj, 'txtAgreementPrice')
        disable(thisObj, 'txtSupportPercent')
        disable(thisObj, 'txtSegValue')
        disable(thisObj, 'txtPremiums')
        disable(thisObj, 'txtDeductions')
        disable(thisObj, 'txtProceeds')
        enable(thisObj, 'btnDelete')
        disable(thisObj, 'txtCrackedBrokenPct')
        disable(thisObj, 'txtDiscoloredShellsPct')
        disable(thisObj, 'txtHullBrightnessPct')
        disable(thisObj, 'txtJumboPct')
        disable(thisObj, 'txtKernRidingELKPct')
        disable(thisObj, 'txtTotalELKDamagePct')
        disable(thisObj, 'txtNetELKPct')
        disable(thisObj, 'txtKernRidingPrescribePct')
        //Hide Buttons
        hide(thisObj, 'btnUnvoid')
        hide(thisObj, 'btnReversal')
      }
    }
    
    if (ddCategory !== "IDT") {
      disable(thisObj, 'ddIDTfromtoCompany')
      // document.getElementById("ddIDTfromtoLocation").disabled = true;
      // disable(thisObj,'ddIDTfromtoLocation')
    } else {
      if (data !== "Add" && txtSts !== 'Pending') {
        disable(thisObj, 'ddIDTfromtoCompany')
        disable(thisObj, 'ddIDTfromtoLocation')
        disable(thisObj, 'ddCategory')
        disable(thisObj, 'txtIDTfromtoVendor')
      }
    }
    // setLoading(false)
  }

  const CalculateTotalPounds = (arrScaleTicket, c) => {
    // setLoading(true)
    let lblTickets = 0,
      lblTotalLBs = 0, lblshppeanutwt = 0, lblrecpeanutwt = 0, lblinventoryotherwt = 0
    if (arrScaleTicket.length > 0) {

      for (var j = 0; j < arrScaleTicket.length; j++) {
        switch (arrScaleTicket[j].inventory_with_ind) {
          case "S":
            lblTotalLBs = lblTotalLBs + parseInt(arrScaleTicket[j].shp_peanut_wt)
            break;
          case "R":
            lblTotalLBs = lblTotalLBs + parseInt(arrScaleTicket[j].rec_peanut_wt)
            break;
          case "O":
            lblTotalLBs = lblTotalLBs + parseInt(arrScaleTicket[j].inventory_other_wt)
            break;
        }
        // lblTotalLBs = lblshppeanutwt + lblrecpeanutwt + lblinventoryotherwt 
      }
      setValue(thisObj, 'txtTotal', lblTotalLBs)
    }
    if (arrScaleTicket.length >= 5) {
      disable(thisObj, 'btnAddST')
    }
    EnableDisableCalculate(c)
    // setLoading(false)
  }

  const EnableDisableCalculateEdit = (getgridScaleTickets, optTKCBasis, txtTotalSMKRScreenPct, txtHullsPct, txtFMSampleWt,
    txtCleanSampleWt, txtSoundSplitsPct, txtTotalOKPct, txtTotalDamagePct) => {
    if (getgridScaleTickets.length > 0) {
      if (optTKCBasis == "TKC") {
        if (txtTotalSMKRScreenPct !== '' && txtHullsPct !== '' && txtFMSampleWt !== '' && txtCleanSampleWt !== '') {
          enable(thisObj, 'btnCalculate')
        } else {
          disable(thisObj, 'btnCalculate')
        }
      } else {
        if (txtTotalSMKRScreenPct !== '' && txtTotalOKPct !== '' && txtTotalDamagePct !== '' && txtSoundSplitsPct !== '' && txtHullsPct !== '' && txtFMSampleWt !== '' && txtCleanSampleWt !== '') {
          enable(thisObj, 'btnCalculate')
        } else {
          disable(thisObj, 'btnCalculate')
        }
      }
    } else {
      disable(thisObj, 'btnCalculate')
    }
  }

  const EnableDisableCalculate = (getgridScaleTickets) => {
    let gridScaleTickets = []
    if (getgridScaleTickets !== undefined && getgridScaleTickets !== '' && getgridScaleTickets !== null) {
      gridScaleTickets = getgridScaleTickets
    } else {
      gridScaleTickets = getValue(thisObj, "gridScaleTickets")
    }
    let optTKCBasis = getValue(thisObj, 'radioGradePricingMethod')
    let txtTotalSMKRScreenPct = getValue(thisObj, 'txtTotalSMKRScreenPct') !== undefined ? getValue(thisObj, "txtTotalSMKRScreenPct") : 0
    let txtHullsPct = getValue(thisObj, 'txtHullsPct') !== undefined ? getValue(thisObj, "txtTotalSMKRScreenPct") : 0
    let txtFMSampleWt = getValue(thisObj, 'txtFMSampleWt') !== undefined ? getValue(thisObj, "txtFMSampleWt") : 0
    let txtCleanSampleWt = getValue(thisObj, 'txtCleanSampleWt') !== undefined ? getValue(thisObj, "txtCleanSampleWt") : 0
    let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct') !== undefined ? getValue(thisObj, "txtSoundSplitsPct") : 0
    let txtTotalOKPct = getValue(thisObj, 'txtTotalOKPct') !== undefined ? getValue(thisObj, "txtTotalOKPct") : 0
    let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct') !== undefined ? getValue(thisObj, "txtTotalDamagePct") : 0

    if (gridScaleTickets.length > 0) {
      if (optTKCBasis == "TKC") {
        if (txtTotalSMKRScreenPct !== '' && txtHullsPct !== '' && txtFMSampleWt !== '' && txtCleanSampleWt !== '') {
          enable(thisObj, 'btnCalculate')
        } else {
          disable(thisObj, 'btnCalculate')
        }
      } else {
        if (txtTotalSMKRScreenPct !== '' && txtTotalOKPct !== '' && txtTotalDamagePct !== '' && txtSoundSplitsPct !== '' && txtHullsPct !== '' && txtFMSampleWt !== '' && txtCleanSampleWt !== '') {
          enable(thisObj, 'btnCalculate')
        } else {
          disable(thisObj, 'btnCalculate')
        }
      }
    } else {
      disable(thisObj, 'btnCalculate')
    }
  }
  // #region 'bSaveInspection'
  const bSaveInspection = async (statevalue, vendorsplit, data, Value) => {
    setLoading(true)
    let strAction = ""
    if (data == "Add") {
      strAction = "INSERT"
    } else if (data == "Edit") {
      strAction = "UPDATE"
    } else if (data == "DELETE") {
      strAction = "DELETE"
    }
    if (data == "Edit" && Value !== 'Create') {
      alert('This will remove any contract applications and premium deductions setup already for this inspection. Continue?')
    }

    let chkboxReGrading = statevalue.chkboxReGrading
    let lblnetprice = 0.00
    if (statevalue.txtPerG !== 'NaN' && statevalue.txtPerG !== '') {
      lblnetprice = statevalue.txtPerG / 100
    }

    let values = statevalue.ddWhseBinNum.split('-');
    var whse_id = values[0];
    var bin_id = values[1];
    let objSave = {
      "purch_sale_ind": statevalue.radioTradeSaleTradePurchase,
      "trade_category": statevalue.ddCategory,
      "trade_status": statevalue.txtSts,
      "trade_settle_num": statevalue.txtTradeSettle1007,
      "insp_date_time":statevalue.dtInspectDateTime,
      "settle_date_time": statevalue.dtSettleDateTime,
      "handler_num": statevalue.txt1007Handler,
      "handler_name": statevalue.txt1007HandlerName,
      "pnut_type_id": statevalue.ddPeanutType,
      "pnut_variety_id": statevalue.ddPeanutVariety,
      "seed_gen": statevalue.ddGeneration,
      "seg_type": statevalue.ddSeg,
      "oleic_ind": statevalue.ddOleic,
      "edible_oil_ind": statevalue.ddEdibleOil,
      "inventory_whouse": whse_id,
      "inventory_bin": bin_id,
      "ship_to_from": statevalue.txtDTWhseBinNum,
      "delv_ind": "",
      "grade_pricing_method": statevalue.radioGradePricingMethod,
      "gram_entry_override": statevalue.radioGramsPercentage == "2" ? "N" : "Y",
      "flavus_ind": chkboxReGrading.includes('2') ? 'Y' : "N",
      "corn_ind": chkboxReGrading.includes('1') ? 'Y' : "N",
      "vicam_ind": chkboxReGrading.includes('3') ? 'Y' : "N",
      "vicam_ppb": statevalue.txtVicamReading,
      "fm_sample": statevalue.txtFMSampleWt,
      "cln_sample": statevalue.txtCleanSampleWt,
      "smk_gr": statevalue.txtTotalSMKRScreen,
      "ss_gr": statevalue.txtSoundSplits,
      "ok_gr": statevalue.txtTotalOK,
      "dam_split_gr": statevalue.txtDamageSplits,
      "dam_gr": statevalue.txtTotalDamage,
      "hull_gr": statevalue.txtHulls,
      "frez_dam_gr": statevalue.txtFreezeDamage,
      "pit_dam_gr": statevalue.txtPittingDamage,
      "cncl_rmd_gr": statevalue.txtConcealedRMD,
      "cr_br_gr": statevalue.txtCrackedBroken,
      "dcol_shel_gr": statevalue.txtDiscoloredShells,
      "hul_brit_gr": statevalue.txtHullBrightness,
      "jumbo_gr": statevalue.txtJumbo,
      "elk_ride_gr": statevalue.txtKernRidingELK,
      "elk_dam_gr": statevalue.txtTotalELKDamage,
      "elk_gr": statevalue.txtNetELK,
      "ride_pscrib_gr": statevalue.txtKernRidingPrescribe,
      "fm_gr": statevalue.txtForeignMtrl,
      "lsk_gr": statevalue.txtTotalSLK,
      "fan_gr": statevalue.txtFancy,
      "smk_pct": statevalue.txtTotalSMKRScreenPct,
      "smk_1_pct": statevalue.txtSMK1Pct,
      "smk_2_pct": statevalue.txtSMK2Pct,
      "smk_3_pct": statevalue.txtSMK3Pct,
      "smk_4_pct": statevalue.txtSMK4Pct,
      "ss_pct": statevalue.txtSoundSplitsPct,
      "ok_pct": statevalue.txtTotalOKPct,
      "ok_ride_pct": statevalue.txtOKRidingPct,
      "ok_fall_pct": statevalue.txtOKFallThru,
      "ok_dam_pct": statevalue.txtOKDamageRiding,
      "dam_pct": statevalue.txtTotalDamagePct,
      "tkc_pct": lblTKC,
      "hull_pct": statevalue.txtHullsPct,
      "frez_dam_pct": statevalue.txtFreezeDamagePct,
      "pit_dam_pct": statevalue.txtPittingDamagePct,
      "cncl_rmd_pct": statevalue.txtConcealedRMDPct,
      "cr_br_pct": statevalue.txtCrackedBrokenPct,
      "dcol_shel_pct": statevalue.txtDiscoloredShellsPct,
      "hul_brit_pct": statevalue.txtHullBrightnessPct,
      "jumbo_pct": statevalue.txtJumboPct,
      "elk_ride_pct": statevalue.txtKernRidingELKPct,
      "elk_dam_pct": statevalue.txtTotalELKDamagePct,
      "elk_pct": statevalue.txtNetELKPct,
      "ride_pscrib_pct": statevalue.txtKernRidingPrescribePct,
      "fm_pct": statevalue.txtForeignMaterialPct,
      "lsk_pct": statevalue.txtTotalSLKPct,
      "lsk_ride_pct": statevalue.txtLSKRidingPct,
      "lsk_fall_pct": statevalue.txtLSKFallThru,
      "lsk_dam_pct": statevalue.txtLSKDamageRiding,
      "moist_pct": statevalue.txtMoisture,
      "fan_pct": statevalue.txtFancyPct,
      "worm_dam_pct": statevalue.txtWormDamage,
      "smk_1_gauge": statevalue.txtSMK1Gr,
      "smk_2_gauge": statevalue.txtSMK2Gr,
      "smk_3_gauge": statevalue.txtSMK3Gr,
      "smk_4_gauge": statevalue.txtSMK4Gr,
      "ok_gauge": statevalue.txtOKRidingGr,
      "lsk_gauge": statevalue.txtLSKRidingGr,
      "oth_fm": statevalue.txtOtherFM,
      "tare_wt": statevalue.txtWtIncludingVehicle,
      "vehicle_wt": statevalue.txtVehicleWt,
      "gross_wt": statevalue.txtGrossWeight,
      "fm_wt": statevalue.txtForeignMaterial,
      "ex_moist_wt": statevalue.txtExcessMoisture,
      "net_wt": statevalue.txtNetWt,
      "lsk_wt": statevalue.txtLSK,
      "kern_value": statevalue.txtKernelValuePerTon,
      "elk_prem": statevalue.txtELKPremiumPerTon,
      "dam_discount": statevalue.txtDamagePerTon,
      "ex_fm_discount": statevalue.txtAccessFMPerTon,
      "ex_ss_discount": statevalue.txtExcessSplitsPerTon,
      "flavus_discount": statevalue.txtAFlavusPerTon,
      "net_les_lsk_val": statevalue.txtNetValPerTonExclSLK,
      "net_price": lblnetprice,
      "basis_grade_amt": statevalue.txtTotalLoadVal,
      "split_vendor1": vendorsplit[0].txtVendor1 !== '' ? vendorsplit[0].txtVendor1 : null,
      "split_remit1": vendorsplit[0].ddRemitTo1 !== '' ? vendorsplit[0].ddRemitTo1 : "",
      "split_share_pct1": vendorsplit[0].txtSharePercentage1 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage1) : 0,
      "gross_wt1": vendorsplit[0].gross_wt1 !== '' ? vendorsplit[0].gross_wt1 : 0,
      "net_wt1": vendorsplit[0].net_wt1 !== '' ? vendorsplit[0].net_wt1 : 0,
      "lsk_wt1": vendorsplit[0].lsk_wt1 !== '' ? vendorsplit[0].lsk_wt1 : 0,
      "basis_grade_amt1": vendorsplit[0].basis_grade_amt1 !== '' ? vendorsplit[0].basis_grade_amt1 : 0,
      "split_vendor2": vendorsplit[0].txtVendor2 !== '' ? vendorsplit[0].txtVendor2 : null,
      "split_remit2": vendorsplit[0].ddRemitTo2 !== '' ? vendorsplit[0].ddRemitTo2 : "",
      "split_share_pct2": vendorsplit[0].txtSharePercentage2 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage2) : 0,
      "gross_wt2": vendorsplit[0].gross_wt2 !== '' ? vendorsplit[0].gross_wt2 : 0,
      "net_wt2": vendorsplit[0].net_wt2 !== '' ? vendorsplit[0].net_wt2 : 0,
      "lsk_wt2": vendorsplit[0].lsk_wt2 !== '' ? vendorsplit[0].lsk_wt2 : 0,
      "basis_grade_amt2": vendorsplit[0].basis_grade_amt2 !== '' ? vendorsplit[0].basis_grade_amt2 : 0,
      "split_vendor3": vendorsplit[0].txtVendor3 !== '' ? vendorsplit[0].txtVendor3 : null,
      "split_remit3": vendorsplit[0].ddRemitTo3 !== '' ? vendorsplit[0].ddRemitTo3 : "",
      "split_share_pct3": vendorsplit[0].txtSharePercentage3 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage3) : 0,
      "gross_wt3": vendorsplit[0].gross_wt3 !== '' ? vendorsplit[0].gross_wt3 : 0,
      "net_wt3": vendorsplit[0].net_wt3 !== '' ? vendorsplit[0].net_wt3 : 0,
      "lsk_wt3": vendorsplit[0].lsk_wt3 !== '' ? vendorsplit[0].lsk_wt3 : 0,
      "basis_grade_amt3": vendorsplit[0].basis_grade_amt3 !== '' ? vendorsplit[0].basis_grade_amt3 : 0,
      "split_vendor4": vendorsplit[0].txtVendor4 !== '' ? vendorsplit[0].txtVendor4 : null,
      "split_remit4": vendorsplit[0].ddRemitTo4 !== '' ? vendorsplit[0].ddRemitTo4 : "",
      "split_share_pct4": vendorsplit[0].txtSharePercentage4 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage4) : 0,
      "gross_wt4": vendorsplit[0].gross_wt4 !== '' ? vendorsplit[0].gross_wt4 : 0,
      "net_wt4": vendorsplit[0].net_wt4 !== '' ? vendorsplit[0].net_wt4 : 0,
      "lsk_wt4": vendorsplit[0].lsk_wt4 !== '' ? vendorsplit[0].lsk_wt4 : 0,
      "basis_grade_amt4": vendorsplit[0].basis_grade_amt4 !== '' ? vendorsplit[0].basis_grade_amt4 : 0,
      "split_vendor5": vendorsplit[0].txtVendor5 !== '' ? vendorsplit[0].txtVendor5 : null,
      "split_remit5": vendorsplit[0].ddRemitTo5 !== '' ? vendorsplit[0].ddRemitTo5 : "",
      "split_share_pct5": vendorsplit[0].txtSharePercentage5 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage5) : 0,
      "gross_wt5": vendorsplit[0].gross_wt5 !== '' ? vendorsplit[0].gross_wt5 : 0,
      "net_wt5": vendorsplit[0].net_wt5 !== '' ? vendorsplit[0].net_wt5 : 0,
      "lsk_wt5": vendorsplit[0].lsk_wt5 !== '' ? vendorsplit[0].lsk_wt5 : 0,
      "basis_grade_amt5": vendorsplit[0].basis_grade_amt5 !== '' ? vendorsplit[0].basis_grade_amt5 : 0,
      "split_vendor6": vendorsplit[0].txtVendor6 !== '' ? vendorsplit[0].txtVendor6 : null,
      "split_remit6": vendorsplit[0].ddRemitTo6 !== '' ? vendorsplit[0].ddRemitTo6 : "",
      "split_share_pct6": vendorsplit[0].txtSharePercentage6 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage6) : 0,
      "gross_wt6": vendorsplit[0].gross_wt6 !== '' ? vendorsplit[0].gross_wt6 : 0,
      "net_wt6": vendorsplit[0].net_wt6 !== '' ? vendorsplit[0].net_wt6 : 0,
      "lsk_wt6": vendorsplit[0].lsk_wt6 !== '' ? vendorsplit[0].lsk_wt6 : 0,
      "basis_grade_amt6": vendorsplit[0].basis_grade_amt6 !== '' ? vendorsplit[0].basis_grade_amt6 : 0,
      "split_vendor7": vendorsplit[0].txtVendor7 !== '' ? vendorsplit[0].txtVendor7 : null,
      "split_remit7": vendorsplit[0].ddRemitTo7 !== '' ? vendorsplit[0].ddRemitTo7 : "",
      "split_share_pct7": vendorsplit[0].txtSharePercentage7 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage7) : 0,
      "gross_wt7": vendorsplit[0].gross_wt7 !== '' ? vendorsplit[0].gross_wt7 : 0,
      "net_wt7": vendorsplit[0].net_wt7 !== '' ? vendorsplit[0].net_wt7 : 0,
      "lsk_wt7": vendorsplit[0].lsk_wt7 !== '' ? vendorsplit[0].lsk_wt7 : 0,
      "basis_grade_amt7": vendorsplit[0].basis_grade_amt7 !== '' ? vendorsplit[0].basis_grade_amt7 : 0,
      "split_vendor8": vendorsplit[0].txtVendor8 !== '' ? vendorsplit[0].txtVendor8 : null,
      "split_remit8": vendorsplit[0].ddRemitTo8 !== '' ? vendorsplit[0].ddRemitTo8 : "",
      "split_share_pct8": vendorsplit[0].txtSharePercentage8 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage8) : 0,
      "gross_wt8": vendorsplit[0].gross_wt8 !== '' ? vendorsplit[0].gross_wt8 : 0,
      "net_wt8": vendorsplit[0].net_wt8 !== '' ? vendorsplit[0].net_wt8 : 0,
      "lsk_wt8": vendorsplit[0].lsk_wt8 !== '' ? vendorsplit[0].lsk_wt8 : 0,
      "basis_grade_amt8": vendorsplit[0].basis_grade_amt8 !== '' ? vendorsplit[0].basis_grade_amt8 : 0,
      "split_vendor9": vendorsplit[0].txtVendor9 !== '' ? vendorsplit[0].txtVendor9 : null,
      "split_remit9": vendorsplit[0].ddRemitTo9 !== '' ? vendorsplit[0].ddRemitTo9 : "",
      "split_share_pct9": vendorsplit[0].txtSharePercentage9 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage9) : 0,
      "gross_wt9": vendorsplit[0].gross_wt9 !== '' ? vendorsplit[0].gross_wt9 : 0,
      "net_wt9": vendorsplit[0].net_wt9 !== '' ? vendorsplit[0].net_wt9 : 0,
      "lsk_wt9": vendorsplit[0].lsk_wt9 !== '' ? vendorsplit[0].lsk_wt9 : 0,
      "basis_grade_amt9": vendorsplit[0].basis_grade_amt9 !== '' ? vendorsplit[0].basis_grade_amt9 : 0,
      "split_vendor10": vendorsplit[0].txtVendor10 !== '' ? vendorsplit[0].txtVendor10 : null,
      "split_remit10": vendorsplit[0].ddRemitTo10 !== '' ? vendorsplit[0].ddRemitTo10 : "",
      "split_share_pct10": vendorsplit[0].txtSharePercentage10 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage10) : 0,
      "gross_wt10": vendorsplit[0].gross_wt10 !== '' ? vendorsplit[0].gross_wt10 : 0,
      "net_wt10": vendorsplit[0].net_wt10 !== '' ? vendorsplit[0].net_wt10 : 0,
      "lsk_wt10": vendorsplit[0].lsk_wt10 !== '' ? vendorsplit[0].lsk_wt10 : 0,
      "basis_grade_amt10": vendorsplit[0].basis_grade_amt10 !== '' ? vendorsplit[0].basis_grade_amt10 : 0,
      "split_vendor11": vendorsplit[0].txtVendor11 !== '' ? vendorsplit[0].txtVendor11 : null,
      "split_remit11": vendorsplit[0].ddRemitTo11 !== '' ? vendorsplit[0].ddRemitTo11 : "",
      "split_share_pct11": vendorsplit[0].txtSharePercentage11 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage11) : 0,
      "gross_wt11": vendorsplit[0].gross_wt11 !== '' ? vendorsplit[0].gross_wt11 : 0,
      "net_wt11": vendorsplit[0].net_wt11 !== '' ? vendorsplit[0].net_wt11 : 0,
      "lsk_wt11": vendorsplit[0].lsk_wt11 !== '' ? vendorsplit[0].lsk_wt11 : 0,
      "basis_grade_amt11": vendorsplit[0].basis_grade_amt11 !== '' ? vendorsplit[0].basis_grade_amt11 : 0,
      "split_vendor12": vendorsplit[0].txtVendor12 !== '' ? vendorsplit[0].txtVendor12 : null,
      "split_remit12": vendorsplit[0].ddRemitTo12 !== '' ? vendorsplit[0].ddRemitTo12 : "",
      "split_share_pct12": vendorsplit[0].txtSharePercentage12 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage12) : 0,
      "gross_wt12": vendorsplit[0].gross_wt12 !== '' ? vendorsplit[0].gross_wt12 : 0,
      "net_wt12": vendorsplit[0].net_wt12 !== '' ? vendorsplit[0].net_wt12 : 0,
      "lsk_wt12": vendorsplit[0].lsk_wt12 !== '' ? vendorsplit[0].lsk_wt12 : 0,
      "basis_grade_amt12": vendorsplit[0].basis_grade_amt12 !== '' ? vendorsplit[0].basis_grade_amt12 : 0,
      "split_vendor13": vendorsplit[0].txtVendor13 !== '' ? vendorsplit[0].txtVendor13 : null,
      "split_remit13": vendorsplit[0].ddRemitTo13 !== '' ? vendorsplit[0].ddRemitTo13 : "",
      "split_share_pct13": vendorsplit[0].txtSharePercentage13 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage13) : 0,
      "gross_wt13": vendorsplit[0].gross_wt13 !== '' ? vendorsplit[0].gross_wt13 : 0,
      "net_wt13": vendorsplit[0].net_wt13 !== '' ? vendorsplit[0].net_wt13 : 0,
      "lsk_wt13": vendorsplit[0].lsk_wt13 !== '' ? vendorsplit[0].lsk_wt13 : 0,
      "basis_grade_amt13": vendorsplit[0].basis_grade_amt13 !== '' ? vendorsplit[0].basis_grade_amt13 : 0,
      "split_vendor14": vendorsplit[0].txtVendor14 !== '' ? vendorsplit[0].txtVendor14 : null,
      "split_remit14": vendorsplit[0].ddRemitTo14 !== '' ? vendorsplit[0].ddRemitTo14 : "",
      "split_share_pct14": vendorsplit[0].txtSharePercentage14 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage14) : 0,
      "gross_wt14": vendorsplit[0].gross_wt14 !== '' ? vendorsplit[0].gross_wt14 : 0,
      "net_wt14": vendorsplit[0].net_wt14 !== '' ? vendorsplit[0].net_wt14 : 0,
      "lsk_wt14": vendorsplit[0].lsk_wt14 !== '' ? vendorsplit[0].lsk_wt14 : 0,
      "basis_grade_amt14": vendorsplit[0].basis_grade_amt14 !== '' ? vendorsplit[0].basis_grade_amt14 : 0,
      "split_vendor15": vendorsplit[0].txtVendor15 !== '' ? vendorsplit[0].txtVendor15 : null,
      "split_remit15": vendorsplit[0].ddRemitTo15 !== '' ? vendorsplit[0].ddRemitTo15 : "",
      "split_share_pct15": vendorsplit[0].txtSharePercentage15 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage15) : 0,
      "gross_wt15": vendorsplit[0].gross_wt15 !== '' ? vendorsplit[0].gross_wt15 : 0,
      "net_wt15": vendorsplit[0].net_wt15 !== '' ? vendorsplit[0].net_wt15 : 0,
      "lsk_wt15": vendorsplit[0].lsk_wt15 !== '' ? vendorsplit[0].lsk_wt15 : 0,
      "basis_grade_amt15": vendorsplit[0].basis_grade_amt15 !== '' ? vendorsplit[0].basis_grade_amt15 : 0,
      "split_vendor16": vendorsplit[0].txtVendor16 !== '' ? vendorsplit[0].txtVendor16 : null,
      "split_remit16": vendorsplit[0].ddRemitTo16 !== '' ? vendorsplit[0].ddRemitTo16 : "",
      "split_share_pct16": vendorsplit[0].txtSharePercentage16 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage16) : 0,
      "gross_wt16": vendorsplit[0].gross_wt16 !== '' ? vendorsplit[0].gross_wt16 : 0,
      "net_wt16": vendorsplit[0].net_wt16 !== '' ? vendorsplit[0].net_wt16 : 0,
      "lsk_wt16": vendorsplit[0].lsk_wt16 !== '' ? vendorsplit[0].lsk_wt16 : 0,
      "basis_grade_amt16": vendorsplit[0].basis_grade_amt16 !== '' ? vendorsplit[0].basis_grade_amt16 : 0,
      "split_vendor17": vendorsplit[0].txtVendor17 !== '' ? vendorsplit[0].txtVendor17 : null,
      "split_remit17": vendorsplit[0].ddRemitTo17 !== '' ? vendorsplit[0].ddRemitTo17 : "",
      "split_share_pct17": vendorsplit[0].txtSharePercentage17 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage17) : 0,
      "gross_wt17": vendorsplit[0].gross_wt17 !== '' ? vendorsplit[0].gross_wt17 : 0,
      "net_wt17": vendorsplit[0].net_wt17 !== '' ? vendorsplit[0].net_wt17 : 0,
      "lsk_wt17": vendorsplit[0].lsk_wt17 !== '' ? vendorsplit[0].lsk_wt17 : 0,
      "basis_grade_amt17": vendorsplit[0].basis_grade_amt17 !== '' ? vendorsplit[0].basis_grade_amt17 : 0,
      "split_vendor18": vendorsplit[0].txtVendor18 !== '' ? vendorsplit[0].txtVendor18 : null,
      "split_remit18": vendorsplit[0].ddRemitTo18 !== '' ? vendorsplit[0].ddRemitTo18 : "",
      "split_share_pct18": vendorsplit[0].txtSharePercentage18 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage18) : 0,
      "gross_wt18": vendorsplit[0].gross_wt18 !== '' ? vendorsplit[0].gross_wt18 : 0,
      "net_wt18": vendorsplit[0].net_wt18 !== '' ? vendorsplit[0].net_wt18 : 0,
      "lsk_wt18": vendorsplit[0].lsk_wt18 !== '' ? vendorsplit[0].lsk_wt18 : 0,
      "basis_grade_amt18": vendorsplit[0].basis_grade_amt18 !== '' ? vendorsplit[0].basis_grade_amt18 : 0,
      "split_vendor19": vendorsplit[0].txtVendor19 !== '' ? vendorsplit[0].txtVendor19 : null,
      "split_remit19": vendorsplit[0].ddRemitTo19 !== '' ? vendorsplit[0].ddRemitTo19 : "",
      "split_share_pct19": vendorsplit[0].txtSharePercentage19 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage19) : 0,
      "gross_wt19": vendorsplit[0].gross_wt19 !== '' ? vendorsplit[0].gross_wt19 : 0,
      "net_wt19": vendorsplit[0].net_wt19 !== '' ? vendorsplit[0].net_wt19 : 0,
      "lsk_wt19": vendorsplit[0].lsk_wt19 !== '' ? vendorsplit[0].lsk_wt19 : 0,
      "basis_grade_amt19": vendorsplit[0].basis_grade_amt19 !== '' ? vendorsplit[0].basis_grade_amt19 : 0,
      "split_vendor20": vendorsplit[0].txtVendor20 !== '' ? vendorsplit[0].txtVendor20 : null,
      "split_remit20": vendorsplit[0].ddRemitTo20 !== '' ? vendorsplit[0].ddRemitTo20 : "",
      "split_share_pct20": vendorsplit[0].txtSharePercentage20 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage20) : 0,
      "gross_wt20": vendorsplit[0].gross_wt20 !== '' ? vendorsplit[0].gross_wt20 : 0,
      "net_wt20": vendorsplit[0].net_wt20 !== '' ? vendorsplit[0].net_wt20 : 0,
      "lsk_wt20": vendorsplit[0].lsk_wt20 !== '' ? vendorsplit[0].lsk_wt20 : 0,
      "basis_grade_amt20": vendorsplit[0].basis_grade_amt20 !== '' ? vendorsplit[0].basis_grade_amt20 : 0,
      "insp_remark":Searchspecial(statevalue.txtarInspectionRemarks),
      "settle_remark":Searchspecial(statevalue.txtarSettleRemarks),
      "loan_repay_rate": "",
      "settle_correct_cnt": 0,
      "print_seg_ind": document.getElementById('chkboxPrintSeg').checked == false ? "N" : "Y",
      "load_num": statevalue.txtLoad !== "" ? statevalue.txtLoad : 0,
      "lot_num": statevalue.txtLot,
      "seal_num": statevalue.txtSeal,
      "start_date_time": statevalue.dtStartDateTime,
      "seed_meeting_spec_ind": statevalue.txtSeedMeetingSpec == 'No' ? "N" : statevalue.txtSeedMeetingSpec == 'Yes' ? "Y" : "",
      "user_id": useridFromLS,
      "idt_auto_generated": "",
      "probe_pattern": statevalue.txtProbingPattern,
      "bluepan_wt": statevalue.txtBluePanWT,
      "meter_read": statevalue.txtMeterReading,
      "kernel_rps": statevalue.txtKernelsRPS,
      "kernel_rels": statevalue.txtKernelsREL,
      "elk_damaged": statevalue.txtELKDamaged,
      "damaged_krs": statevalue.txtDamagedKRS,
      "tot_krs_wt": 0,
      "cid":compIdFromLS
    }
    let ScaleTicketData = [];
    for (let i = 1; i <= 5; i++) {
      let data = getValue(thisObj, "gridScaleTickets");
      if (i <= data.length) {
        objSave["load_num" + (i)] = data[i - 1].arrTicketInfo.load_num
        objSave["vehicle_num" + (i)] = data[i - 1].arrTicketInfo.vehicle_num !== null && data[i - 1].arrTicketInfo.vehicle_num !== undefined && data[i - 1].arrTicketInfo.vehicle_num !== "" ? data[i - 1].arrTicketInfo.vehicle_num : ''
        objSave["truck_line" + (i)] = data[i - 1].arrTicketInfo.truck_line !== null && data[i - 1].arrTicketInfo.truck_line !== undefined && data[i - 1].arrTicketInfo.truck_line !== "" ? data[i - 1].arrTicketInfo.truck_line : ''
        objSave["driver_name" + (i)] = data[i - 1].arrTicketInfo.driver_name !== null && data[i - 1].arrTicketInfo.driver_name !== undefined && data[i - 1].arrTicketInfo.driver_name !== "" ? data[i - 1].arrTicketInfo.driver_name : ''
        objSave["shp_date" + (i)] = data[i - 1].arrTicketInfo.shp_date !== null && data[i - 1].arrTicketInfo.shp_date !== undefined && data[i - 1].arrTicketInfo.shp_date !== "" ? data[i - 1].arrTicketInfo.shp_date : ''
        objSave["shp_scale_tick" + (i)] = data[i - 1].arrTicketInfo.shp_scale_tick !== null && data[i - 1].arrTicketInfo.shp_scale_tick !== undefined && data[i - 1].arrTicketInfo.shp_scale_tick !== "" ? data[i - 1].arrTicketInfo.shp_scale_tick : ''
        objSave["shp_split_scale" + (i)] = data[i - 1].arrTicketInfo.shp_split_scale !== null && data[i - 1].arrTicketInfo.shp_split_scale !== undefined && data[i - 1].arrTicketInfo.shp_split_scale !== "" ? data[i - 1].arrTicketInfo.shp_split_scale : ''
        objSave["shp_tare_wt" + (i)] = data[i - 1].arrTicketInfo.shp_tare_wt !== null && data[i - 1].arrTicketInfo.shp_tare_wt !== undefined && data[i - 1].arrTicketInfo.shp_tare_wt !== "" ? data[i - 1].arrTicketInfo.shp_tare_wt : ''
        objSave["shp_vehicle_wt" + (i)] = data[i - 1].arrTicketInfo.shp_vehicle_wt !== null && data[i - 1].arrTicketInfo.shp_vehicle_wt !== undefined && data[i - 1].arrTicketInfo.shp_vehicle_wt !== "" ? data[i - 1].arrTicketInfo.shp_vehicle_wt : ''
        objSave["shp_peanut_wt" + (i)] = data[i - 1].arrTicketInfo.shp_peanut_wt !== null && data[i - 1].arrTicketInfo.shp_peanut_wt !== undefined && data[i - 1].arrTicketInfo.shp_peanut_wt !== "" ? data[i - 1].arrTicketInfo.shp_peanut_wt : ''
        objSave["rec_date" + (i)] = data[i - 1].arrTicketInfo.rec_date !== null && data[i - 1].arrTicketInfo.rec_date !== undefined && data[i - 1].arrTicketInfo.rec_date !== "" ? data[i - 1].arrTicketInfo.rec_date : ''
        objSave["rec_scale_tick" + (i)] = data[i - 1].arrTicketInfo.rec_scale_tick !== null && data[i - 1].arrTicketInfo.rec_scale_tick !== undefined && data[i - 1].arrTicketInfo.rec_scale_tick !== "" ? data[i - 1].arrTicketInfo.rec_scale_tick : ''
        objSave["rec_split_scale" + (i)] = data[i - 1].arrTicketInfo.rec_split_scale !== null && data[i - 1].arrTicketInfo.rec_split_scale !== undefined && data[i - 1].arrTicketInfo.rec_split_scale !== "" ? data[i - 1].arrTicketInfo.rec_split_scale : ''
        objSave["rec_tare_wt" + (i)] = data[i - 1].arrTicketInfo.rec_tare_wt !== null && data[i - 1].arrTicketInfo.rec_tare_wt !== undefined && data[i - 1].arrTicketInfo.rec_tare_wt !== "" ? data[i - 1].arrTicketInfo.rec_tare_wt : ''
        objSave["rec_vehicle_wt" + (i)] = data[i - 1].arrTicketInfo.rec_vehicle_wt !== null && data[i - 1].arrTicketInfo.rec_vehicle_wt !== undefined && data[i - 1].arrTicketInfo.rec_vehicle_wt !== "" ? data[i - 1].arrTicketInfo.rec_vehicle_wt : ''
        objSave["rec_peanut_wt" + (i)] = data[i - 1].arrTicketInfo.rec_peanut_wt !== null && data[i - 1].arrTicketInfo.rec_peanut_wt !== undefined && data[i - 1].arrTicketInfo.rec_peanut_wt !== "" ? data[i - 1].arrTicketInfo.rec_peanut_wt : ''
        objSave["inventory_with_ind" + (i)] = data[i - 1].arrTicketInfo.inventory_with_ind !== null && data[i - 1].arrTicketInfo.inventory_with_ind !== undefined && data[i - 1].arrTicketInfo.inventory_with_ind !== "" ? data[i - 1].arrTicketInfo.inventory_with_ind : ''
        objSave["inventory_other_wt" + (i)] = data[i - 1].arrTicketInfo.inventory_other_wt !== null && data[i - 1].arrTicketInfo.inventory_other_wt !== undefined && data[i - 1].arrTicketInfo.inventory_other_wt !== "" ? data[i - 1].arrTicketInfo.inventory_other_wt : '0'
      } else {
        objSave["load_num" + (i)] = 0
        objSave["vehicle_num" + (i)] = ''
        objSave["truck_line" + (i)] = ''
        objSave["driver_name" + (i)] = ''
        objSave["shp_date" + (i)] = ''
        objSave["shp_scale_tick" + (i)] = ''
        objSave["shp_split_scale" + (i)] = ''
        objSave["shp_tare_wt" + (i)] = ''
        objSave["shp_vehicle_wt" + (i)] = ''
        objSave["shp_peanut_wt" + (i)] = ''
        objSave["rec_date" + (i)] = ''
        objSave["rec_scale_tick" + (i)] = ''
        objSave["rec_split_scale" + (i)] = ''
        objSave["rec_tare_wt" + (i)] = ''
        objSave["rec_vehicle_wt" + (i)] = ''
        objSave["rec_peanut_wt" + (i)] = ''
        objSave["inventory_with_ind" + (i)] = ''
        objSave["inventory_other_wt" + (i)] = 0

      }
    }
    if(statevalue.ddCategory.toUpperCase().substring(0, 3) == "IDT"){
      objSave["idt_comp_id"]= statevalue.ddIDTfromtoCompany
      objSave["idt_comp_vendor"]=statevalue.txtIDTfromtoVendor
      objSave["idt_buy_pt_id"]=statevalue.ddIDTfromtoLocation
    }else{
      objSave["idt_comp_id"]= ""
      objSave["idt_comp_vendor"]=""
      objSave["idt_buy_pt_id"]=""
    }
//PRB0054195 - Duplicate Load Number Validation for scale tickets in settlement screen
let UpdateTradeSettlement = await SettlementService.UpdateTradeSettlement(null,strAction, statevalue.ddShipFromToLocation, statevalue.txtTradeInspectSC95, objSave);
if (UpdateTradeSettlement.status === 500 && 
  UpdateTradeSettlement.message.includes("Violation of PRIMARY KEY constraint 'PK_TRADE_SETTLE_SCALE_TICKET'")) {
  alert("Error: Duplicate Load Number detected. Please ensure that each Load Number is unique and try again.");
  setLoading(false);  // Stop the loading indicator
  return;
}   
 if (UpdateTradeSettlement.status == 200 && strAction == "INSERT" && Value !== 'Create') {
      let js = [];
      js.push({
        permission: getData(thisObj, 'accessPermission'),
        Inspnum: statevalue.txtTradeInspectSC95,
        Locnum: statevalue.ddShipFromToLocation,
        LoadTypeBtnCaption: 'Edit'
      })
      setData(thisObj, "CreateValue", js)
      onbtnCalculateClick()
      EnableDisableControls(statevalue.ddShipFromToLocation, "P", statevalue.ddSeg, data)
      alert('Changes made to this inspection have been successfully changed!!!')
    } else if (Value == 'Create') {
      let txtTradeInspNum = getValue(thisObj, 'txtTradeInspectSC95')
      let radioTradeSaleTradePurchase = thisObj.values.radioTradeSaleTradePurchase
      let ddShipFromToLocation = getValue(thisObj, 'ddShipFromToLocation')
      let ddPeanutType = getValue(thisObj, 'ddPeanutType')
      let ddPeanutVariety = getValue(thisObj, 'ddPeanutVariety')
      let ddSeg = getValue(thisObj, 'ddSeg')
      let ddGeneration = getValue(thisObj, 'ddGeneration')
      let radioGradePricingMethod = getValue(thisObj, 'radioGradePricingMethod')
      let radioGradePricingMethod_ = false
      if (radioGradePricingMethod !== "TKC") {
        radioGradePricingMethod_ = false
      } else {
        radioGradePricingMethod_ = true
      }
      let txtTotalLoadVal = getValue(thisObj, 'txtTotalLoadVal')
      let txtNetWeight = getValue(thisObj, 'txtNetWt')
      let txtTotalSMKRScreenPct = getValue(thisObj, 'txtTotalSMKRScreenPct')
      let txtLSK = getValue(thisObj, 'txtLSK')
      let RetrieveTradeSettlegetDetails = await SettlementService.RetrieveTradeSettlementDetails(ddShipFromToLocation, txtTradeInspNum, "Y");
      let sHeader = RetrieveTradeSettlegetDetails[0].trade_settle_header,
        Vendordetails = RetrieveTradeSettlegetDetails[0].trade_settle_vendor_split,
        sRemarks = RetrieveTradeSettlegetDetails[0].trade_settle_remark,
        sScaleTickets = RetrieveTradeSettlegetDetails[0].trade_settle_scale_tickets,
        sPremDeductions = RetrieveTradeSettlegetDetails[0].trade_settle_prem_deduct
      setTradesettleheader(sHeader)
      setTradesettlevendorsplit(Vendordetails)
      setTradesettleremark(sRemarks)
      setTradesettlescaletickets(sScaleTickets)
      setTradesettlepremdeduct(sPremDeductions)
      var ReviewCreateSettlementFormLoadDataObj = {}
      ReviewCreateSettlementFormLoadDataObj.txtTradeInspNum = txtTradeInspNum
      ReviewCreateSettlementFormLoadDataObj.optTradeSale = radioTradeSaleTradePurchase
      ReviewCreateSettlementFormLoadDataObj.cboPeanutType = ddPeanutType
      ReviewCreateSettlementFormLoadDataObj.cboSeg = ddSeg
      ReviewCreateSettlementFormLoadDataObj.cboGeneration = ddGeneration
      ReviewCreateSettlementFormLoadDataObj.optTKCBasis = radioGradePricingMethod
      ReviewCreateSettlementFormLoadDataObj.lblNetWeight = txtNetWeight
      ReviewCreateSettlementFormLoadDataObj.txtTotalSMKRScreenPct = txtTotalSMKRScreenPct
      ReviewCreateSettlementFormLoadDataObj.lblLSK = txtLSK
      ReviewCreateSettlementFormLoadDataObj.lblTotalValueOfLoad = txtTotalLoadVal
      ReviewCreateSettlementFormLoadDataObj.ddPeanutVariety = ddPeanutVariety
      ReviewCreateSettlementFormLoadDataObj.Buying_point_id = ddShipFromToLocation
      ReviewCreateSettlementFormLoadDataObj.sHeader = sHeader
      ReviewCreateSettlementFormLoadDataObj.Vendordetails = Vendordetails
      setData(thisObj, "ReviewCreateSettlementFormLoadData", ReviewCreateSettlementFormLoadDataObj);
      goTo(thisObj, "Settlements#TradeSettleApply#DEFAULT#true#Click");
    } else if (UpdateTradeSettlement.status == 200 && strAction == "UPDATE" && Value !== 'Create') {
      onbtnCalculateClick()
      alert('Changes made to this inspection have been successfully changed!!!')
    } else if (UpdateTradeSettlement.status == 200 && strAction == "DELETE" && Value !== 'Create') {
      alert(`The trade #"${statevalue.txtTradeInspectSC95} has been successfully deleted!!!`)
      setData(thisObj, 'CompletionStatus', true)
      document.getElementById("Settlements_TradeSettleProfilePopUp").childNodes[0].click();
    } 
    let ddIDTfromtoCompany = getValue(thisObj, 'ddIDTfromtoCompany')
    let ddIDTfromtoLocation = getValue(thisObj, 'ddIDTfromtoLocation')
    if (ddIDTfromtoCompany !== '' && ddIDTfromtoCompany!==undefined && ddIDTfromtoLocation !== '' && ddIDTfromtoLocation!==undefined) {
      bSaveInspectionIdt(statevalue, vendorsplit, data, Value)
    }
    setLoading(false)
  }

  // #region 'bSaveInspectionIdt'
  const bSaveInspectionIdt = async (statevalue, vendorsplit, data, Value) => {
    setLoading(true)
    let strAction = ""
    if(statevalue.ddCategory.toUpperCase().substring(0, 3) == "IDT"){
      await bCheckForIDT("settle_header", "")
      if(mbSettleHeaderIdtExistFlag ==true){
        if(mbSettleHeaderIdtExistError == false){
          strAction = ""
        }else{
          strAction = "INSERT"
        }SHIP_TO_FROM
      }else{
        strAction = "UPDATE"
      }
    }
    let chkboxReGrading = statevalue.chkboxReGrading
    let lblnetprice = 0.00
    if (statevalue.txtPerG !== 'NaN' && statevalue.txtPerG !== '') {
      lblnetprice = statevalue.txtPerG / 100
    }

    let values = statevalue.ddWhseBinNum.split('-');
    var whse_id = values[0];
    var bin_id = values[1];
    let objSave = {
      "purch_sale_ind": statevalue.radioTradeSaleTradePurchase,
      "trade_category": statevalue.ddCategory,
      "trade_status": statevalue.txtSts,
      "trade_settle_num": statevalue.txtTradeSettle1007,
      "insp_date_time":statevalue.dtInspectDateTime,
      "settle_date_time": statevalue.dtSettleDateTime,
      "handler_num": statevalue.txt1007Handler,
      "handler_name": statevalue.txt1007HandlerName,
      "pnut_type_id": statevalue.ddPeanutType,
      "pnut_variety_id": statevalue.ddPeanutVariety,
      "seed_gen": statevalue.ddGeneration,
      "seg_type": statevalue.ddSeg,
      "oleic_ind": statevalue.ddOleic,
      "edible_oil_ind": statevalue.ddEdibleOil,
      "inventory_whouse": whse_id,
      "inventory_bin": bin_id,
      "ship_to_from": statevalue.txtDTWhseBinNum,
      "delv_ind": "",
      "grade_pricing_method": statevalue.radioGradePricingMethod,
      "gram_entry_override": statevalue.radioGramsPercentage == "2" ? "N" : "Y",
      "flavus_ind": chkboxReGrading.includes('2') ? 'Y' : "N",
      "corn_ind": chkboxReGrading.includes('1') ? 'Y' : "N",
      "vicam_ind": chkboxReGrading.includes('3') ? 'Y' : "N",
      "vicam_ppb": statevalue.txtVicamReading,
      "fm_sample": statevalue.txtFMSampleWt,
      "cln_sample": statevalue.txtCleanSampleWt,
      "smk_gr": statevalue.txtTotalSMKRScreen,
      "ss_gr": statevalue.txtSoundSplits,
      "ok_gr": statevalue.txtTotalOK,
      "dam_split_gr": statevalue.txtDamageSplits,
      "dam_gr": statevalue.txtTotalDamage,
      "hull_gr": statevalue.txtHulls,
      "frez_dam_gr": statevalue.txtFreezeDamage,
      "pit_dam_gr": statevalue.txtPittingDamage,
      "cncl_rmd_gr": statevalue.txtConcealedRMD,
      "cr_br_gr": statevalue.txtCrackedBroken,
      "dcol_shel_gr": statevalue.txtDiscoloredShells,
      "hul_brit_gr": statevalue.txtHullBrightness,
      "jumbo_gr": statevalue.txtJumbo,
      "elk_ride_gr": statevalue.txtKernRidingELK,
      "elk_dam_gr": statevalue.txtTotalELKDamage,
      "elk_gr": statevalue.txtNetELK,
      "ride_pscrib_gr": statevalue.txtKernRidingPrescribe,
      "fm_gr": statevalue.txtForeignMtrl,
      "lsk_gr": statevalue.txtTotalSLK,
      "fan_gr": statevalue.txtFancy,
      "smk_pct": statevalue.txtTotalSMKRScreenPct,
      "smk_1_pct": statevalue.txtSMK1Pct,
      "smk_2_pct": statevalue.txtSMK2Pct,
      "smk_3_pct": statevalue.txtSMK3Pct,
      "smk_4_pct": statevalue.txtSMK4Pct,
      "ss_pct": statevalue.txtSoundSplitsPct,
      "ok_pct": statevalue.txtTotalOKPct,
      "ok_ride_pct": statevalue.txtOKRidingPct,
      "ok_fall_pct": statevalue.txtOKFallThru,
      "ok_dam_pct": statevalue.txtOKDamageRiding,
      "dam_pct": statevalue.txtTotalDamagePct,
      "tkc_pct": lblTKC,
      "hull_pct": statevalue.txtHullsPct,
      "frez_dam_pct": statevalue.txtFreezeDamagePct,
      "pit_dam_pct": statevalue.txtPittingDamagePct,
      "cncl_rmd_pct": statevalue.txtConcealedRMDPct,
      "cr_br_pct": statevalue.txtCrackedBrokenPct,
      "dcol_shel_pct": statevalue.txtDiscoloredShellsPct,
      "hul_brit_pct": statevalue.txtHullBrightnessPct,
      "jumbo_pct": statevalue.txtJumboPct,
      "elk_ride_pct": statevalue.txtKernRidingELKPct,
      "elk_dam_pct": statevalue.txtTotalELKDamagePct,
      "elk_pct": statevalue.txtNetELKPct,
      "ride_pscrib_pct": statevalue.txtKernRidingPrescribePct,
      "fm_pct": statevalue.txtForeignMaterialPct,
      "lsk_pct": statevalue.txtTotalSLKPct,
      "lsk_ride_pct": statevalue.txtLSKRidingPct,
      "lsk_fall_pct": statevalue.txtLSKFallThru,
      "lsk_dam_pct": statevalue.txtLSKDamageRiding,
      "moist_pct": statevalue.txtMoisture,
      "fan_pct": statevalue.txtFancyPct,
      "worm_dam_pct": statevalue.txtWormDamage,
      "smk_1_gauge": statevalue.txtSMK1Gr,
      "smk_2_gauge": statevalue.txtSMK2Gr,
      "smk_3_gauge": statevalue.txtSMK3Gr,
      "smk_4_gauge": statevalue.txtSMK4Gr,
      "ok_gauge": statevalue.txtOKRidingGr,
      "lsk_gauge": statevalue.txtLSKRidingGr,
      "oth_fm": statevalue.txtOtherFM,
      "tare_wt": statevalue.txtWtIncludingVehicle,
      "vehicle_wt": statevalue.txtVehicleWt,
      "gross_wt": statevalue.txtGrossWeight,
      "fm_wt": statevalue.txtForeignMaterial,
      "ex_moist_wt": statevalue.txtExcessMoisture,
      "net_wt": statevalue.txtNetWt,
      "lsk_wt": statevalue.txtLSK,
      "kern_value": statevalue.txtKernelValuePerTon,
      "elk_prem": statevalue.txtELKPremiumPerTon,
      "dam_discount": statevalue.txtDamagePerTon,
      "ex_fm_discount": statevalue.txtAccessFMPerTon,
      "ex_ss_discount": statevalue.txtExcessSplitsPerTon,
      "flavus_discount": statevalue.txtAFlavusPerTon,
      "net_les_lsk_val": statevalue.txtNetValPerTonExclSLK,
      "net_price": lblnetprice,
      "basis_grade_amt": statevalue.txtTotalLoadVal,
      "split_vendor1": vendorsplit[0].txtVendor1 !== '' ? vendorsplit[0].txtVendor1 : null,
      "split_remit1": vendorsplit[0].ddRemitTo1 !== '' ? vendorsplit[0].ddRemitTo1 : "",
      "split_share_pct1": vendorsplit[0].txtSharePercentage1 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage1) : 0,
      "gross_wt1": vendorsplit[0].gross_wt1 !== '' ? vendorsplit[0].gross_wt1 : 0,
      "net_wt1": vendorsplit[0].net_wt1 !== '' ? vendorsplit[0].net_wt1 : 0,
      "lsk_wt1": vendorsplit[0].lsk_wt1 !== '' ? vendorsplit[0].lsk_wt1 : 0,
      "basis_grade_amt1": vendorsplit[0].basis_grade_amt1 !== '' ? vendorsplit[0].basis_grade_amt1 : 0,
      "split_vendor2": vendorsplit[0].txtVendor2 !== '' ? vendorsplit[0].txtVendor2 : null,
      "split_remit2": vendorsplit[0].ddRemitTo2 !== '' ? vendorsplit[0].ddRemitTo2 : "",
      "split_share_pct2": vendorsplit[0].txtSharePercentage2 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage2) : 0,
      "gross_wt2": vendorsplit[0].gross_wt2 !== '' ? vendorsplit[0].gross_wt2 : 0,
      "net_wt2": vendorsplit[0].net_wt2 !== '' ? vendorsplit[0].net_wt2 : 0,
      "lsk_wt2": vendorsplit[0].lsk_wt2 !== '' ? vendorsplit[0].lsk_wt2 : 0,
      "basis_grade_amt2": vendorsplit[0].basis_grade_amt2 !== '' ? vendorsplit[0].basis_grade_amt2 : 0,
      "split_vendor3": vendorsplit[0].txtVendor3 !== '' ? vendorsplit[0].txtVendor3 : null,
      "split_remit3": vendorsplit[0].ddRemitTo3 !== '' ? vendorsplit[0].ddRemitTo3 : "",
      "split_share_pct3": vendorsplit[0].txtSharePercentage3 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage3) : 0,
      "gross_wt3": vendorsplit[0].gross_wt3 !== '' ? vendorsplit[0].gross_wt3 : 0,
      "net_wt3": vendorsplit[0].net_wt3 !== '' ? vendorsplit[0].net_wt3 : 0,
      "lsk_wt3": vendorsplit[0].lsk_wt3 !== '' ? vendorsplit[0].lsk_wt3 : 0,
      "basis_grade_amt3": vendorsplit[0].basis_grade_amt3 !== '' ? vendorsplit[0].basis_grade_amt3 : 0,
      "split_vendor4": vendorsplit[0].txtVendor4 !== '' ? vendorsplit[0].txtVendor4 : null,
      "split_remit4": vendorsplit[0].ddRemitTo4 !== '' ? vendorsplit[0].ddRemitTo4 : "",
      "split_share_pct4": vendorsplit[0].txtSharePercentage4 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage4) : 0,
      "gross_wt4": vendorsplit[0].gross_wt4 !== '' ? vendorsplit[0].gross_wt4 : 0,
      "net_wt4": vendorsplit[0].net_wt4 !== '' ? vendorsplit[0].net_wt4 : 0,
      "lsk_wt4": vendorsplit[0].lsk_wt4 !== '' ? vendorsplit[0].lsk_wt4 : 0,
      "basis_grade_amt4": vendorsplit[0].basis_grade_amt4 !== '' ? vendorsplit[0].basis_grade_amt4 : 0,
      "split_vendor5": vendorsplit[0].txtVendor5 !== '' ? vendorsplit[0].txtVendor5 : null,
      "split_remit5": vendorsplit[0].ddRemitTo5 !== '' ? vendorsplit[0].ddRemitTo5 : "",
      "split_share_pct5": vendorsplit[0].txtSharePercentage5 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage5) : 0,
      "gross_wt5": vendorsplit[0].gross_wt5 !== '' ? vendorsplit[0].gross_wt5 : 0,
      "net_wt5": vendorsplit[0].net_wt5 !== '' ? vendorsplit[0].net_wt5 : 0,
      "lsk_wt5": vendorsplit[0].lsk_wt5 !== '' ? vendorsplit[0].lsk_wt5 : 0,
      "basis_grade_amt5": vendorsplit[0].basis_grade_amt5 !== '' ? vendorsplit[0].basis_grade_amt5 : 0,
      "split_vendor6": vendorsplit[0].txtVendor6 !== '' ? vendorsplit[0].txtVendor6 : null,
      "split_remit6": vendorsplit[0].ddRemitTo6 !== '' ? vendorsplit[0].ddRemitTo6 : "",
      "split_share_pct6": vendorsplit[0].txtSharePercentage6 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage6) : 0,
      "gross_wt6": vendorsplit[0].gross_wt6 !== '' ? vendorsplit[0].gross_wt6 : 0,
      "net_wt6": vendorsplit[0].net_wt6 !== '' ? vendorsplit[0].net_wt6 : 0,
      "lsk_wt6": vendorsplit[0].lsk_wt6 !== '' ? vendorsplit[0].lsk_wt6 : 0,
      "basis_grade_amt6": vendorsplit[0].basis_grade_amt6 !== '' ? vendorsplit[0].basis_grade_amt6 : 0,
      "split_vendor7": vendorsplit[0].txtVendor7 !== '' ? vendorsplit[0].txtVendor7 : null,
      "split_remit7": vendorsplit[0].ddRemitTo7 !== '' ? vendorsplit[0].ddRemitTo7 : "",
      "split_share_pct7": vendorsplit[0].txtSharePercentage7 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage7) : 0,
      "gross_wt7": vendorsplit[0].gross_wt7 !== '' ? vendorsplit[0].gross_wt7 : 0,
      "net_wt7": vendorsplit[0].net_wt7 !== '' ? vendorsplit[0].net_wt7 : 0,
      "lsk_wt7": vendorsplit[0].lsk_wt7 !== '' ? vendorsplit[0].lsk_wt7 : 0,
      "basis_grade_amt7": vendorsplit[0].basis_grade_amt7 !== '' ? vendorsplit[0].basis_grade_amt7 : 0,
      "split_vendor8": vendorsplit[0].txtVendor8 !== '' ? vendorsplit[0].txtVendor8 : null,
      "split_remit8": vendorsplit[0].ddRemitTo8 !== '' ? vendorsplit[0].ddRemitTo8 : "",
      "split_share_pct8": vendorsplit[0].txtSharePercentage8 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage8) : 0,
      "gross_wt8": vendorsplit[0].gross_wt8 !== '' ? vendorsplit[0].gross_wt8 : 0,
      "net_wt8": vendorsplit[0].net_wt8 !== '' ? vendorsplit[0].net_wt8 : 0,
      "lsk_wt8": vendorsplit[0].lsk_wt8 !== '' ? vendorsplit[0].lsk_wt8 : 0,
      "basis_grade_amt8": vendorsplit[0].basis_grade_amt8 !== '' ? vendorsplit[0].basis_grade_amt8 : 0,
      "split_vendor9": vendorsplit[0].txtVendor9 !== '' ? vendorsplit[0].txtVendor9 : null,
      "split_remit9": vendorsplit[0].ddRemitTo9 !== '' ? vendorsplit[0].ddRemitTo9 : "",
      "split_share_pct9": vendorsplit[0].txtSharePercentage9 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage9) : 0,
      "gross_wt9": vendorsplit[0].gross_wt9 !== '' ? vendorsplit[0].gross_wt9 : 0,
      "net_wt9": vendorsplit[0].net_wt9 !== '' ? vendorsplit[0].net_wt9 : 0,
      "lsk_wt9": vendorsplit[0].lsk_wt9 !== '' ? vendorsplit[0].lsk_wt9 : 0,
      "basis_grade_amt9": vendorsplit[0].basis_grade_amt9 !== '' ? vendorsplit[0].basis_grade_amt9 : 0,
      "split_vendor10": vendorsplit[0].txtVendor10 !== '' ? vendorsplit[0].txtVendor10 : null,
      "split_remit10": vendorsplit[0].ddRemitTo10 !== '' ? vendorsplit[0].ddRemitTo10 : "",
      "split_share_pct10": vendorsplit[0].txtSharePercentage10 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage10) : 0,
      "gross_wt10": vendorsplit[0].gross_wt10 !== '' ? vendorsplit[0].gross_wt10 : 0,
      "net_wt10": vendorsplit[0].net_wt10 !== '' ? vendorsplit[0].net_wt10 : 0,
      "lsk_wt10": vendorsplit[0].lsk_wt10 !== '' ? vendorsplit[0].lsk_wt10 : 0,
      "basis_grade_amt10": vendorsplit[0].basis_grade_amt10 !== '' ? vendorsplit[0].basis_grade_amt10 : 0,
      "split_vendor11": vendorsplit[0].txtVendor11 !== '' ? vendorsplit[0].txtVendor11 : null,
      "split_remit11": vendorsplit[0].ddRemitTo11 !== '' ? vendorsplit[0].ddRemitTo11 : "",
      "split_share_pct11": vendorsplit[0].txtSharePercentage11 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage11) : 0,
      "gross_wt11": vendorsplit[0].gross_wt11 !== '' ? vendorsplit[0].gross_wt11 : 0,
      "net_wt11": vendorsplit[0].net_wt11 !== '' ? vendorsplit[0].net_wt11 : 0,
      "lsk_wt11": vendorsplit[0].lsk_wt11 !== '' ? vendorsplit[0].lsk_wt11 : 0,
      "basis_grade_amt11": vendorsplit[0].basis_grade_amt11 !== '' ? vendorsplit[0].basis_grade_amt11 : 0,
      "split_vendor12": vendorsplit[0].txtVendor12 !== '' ? vendorsplit[0].txtVendor12 : null,
      "split_remit12": vendorsplit[0].ddRemitTo12 !== '' ? vendorsplit[0].ddRemitTo12 : "",
      "split_share_pct12": vendorsplit[0].txtSharePercentage12 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage12) : 0,
      "gross_wt12": vendorsplit[0].gross_wt12 !== '' ? vendorsplit[0].gross_wt12 : 0,
      "net_wt12": vendorsplit[0].net_wt12 !== '' ? vendorsplit[0].net_wt12 : 0,
      "lsk_wt12": vendorsplit[0].lsk_wt12 !== '' ? vendorsplit[0].lsk_wt12 : 0,
      "basis_grade_amt12": vendorsplit[0].basis_grade_amt12 !== '' ? vendorsplit[0].basis_grade_amt12 : 0,
      "split_vendor13": vendorsplit[0].txtVendor13 !== '' ? vendorsplit[0].txtVendor13 : null,
      "split_remit13": vendorsplit[0].ddRemitTo13 !== '' ? vendorsplit[0].ddRemitTo13 : "",
      "split_share_pct13": vendorsplit[0].txtSharePercentage13 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage13) : 0,
      "gross_wt13": vendorsplit[0].gross_wt13 !== '' ? vendorsplit[0].gross_wt13 : 0,
      "net_wt13": vendorsplit[0].net_wt13 !== '' ? vendorsplit[0].net_wt13 : 0,
      "lsk_wt13": vendorsplit[0].lsk_wt13 !== '' ? vendorsplit[0].lsk_wt13 : 0,
      "basis_grade_amt13": vendorsplit[0].basis_grade_amt13 !== '' ? vendorsplit[0].basis_grade_amt13 : 0,
      "split_vendor14": vendorsplit[0].txtVendor14 !== '' ? vendorsplit[0].txtVendor14 : null,
      "split_remit14": vendorsplit[0].ddRemitTo14 !== '' ? vendorsplit[0].ddRemitTo14 : "",
      "split_share_pct14": vendorsplit[0].txtSharePercentage14 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage14) : 0,
      "gross_wt14": vendorsplit[0].gross_wt14 !== '' ? vendorsplit[0].gross_wt14 : 0,
      "net_wt14": vendorsplit[0].net_wt14 !== '' ? vendorsplit[0].net_wt14 : 0,
      "lsk_wt14": vendorsplit[0].lsk_wt14 !== '' ? vendorsplit[0].lsk_wt14 : 0,
      "basis_grade_amt14": vendorsplit[0].basis_grade_amt14 !== '' ? vendorsplit[0].basis_grade_amt14 : 0,
      "split_vendor15": vendorsplit[0].txtVendor15 !== '' ? vendorsplit[0].txtVendor15 : null,
      "split_remit15": vendorsplit[0].ddRemitTo15 !== '' ? vendorsplit[0].ddRemitTo15 : "",
      "split_share_pct15": vendorsplit[0].txtSharePercentage15 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage15) : 0,
      "gross_wt15": vendorsplit[0].gross_wt15 !== '' ? vendorsplit[0].gross_wt15 : 0,
      "net_wt15": vendorsplit[0].net_wt15 !== '' ? vendorsplit[0].net_wt15 : 0,
      "lsk_wt15": vendorsplit[0].lsk_wt15 !== '' ? vendorsplit[0].lsk_wt15 : 0,
      "basis_grade_amt15": vendorsplit[0].basis_grade_amt15 !== '' ? vendorsplit[0].basis_grade_amt15 : 0,
      "split_vendor16": vendorsplit[0].txtVendor16 !== '' ? vendorsplit[0].txtVendor16 : null,
      "split_remit16": vendorsplit[0].ddRemitTo16 !== '' ? vendorsplit[0].ddRemitTo16 : "",
      "split_share_pct16": vendorsplit[0].txtSharePercentage16 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage16) : 0,
      "gross_wt16": vendorsplit[0].gross_wt16 !== '' ? vendorsplit[0].gross_wt16 : 0,
      "net_wt16": vendorsplit[0].net_wt16 !== '' ? vendorsplit[0].net_wt16 : 0,
      "lsk_wt16": vendorsplit[0].lsk_wt16 !== '' ? vendorsplit[0].lsk_wt16 : 0,
      "basis_grade_amt16": vendorsplit[0].basis_grade_amt16 !== '' ? vendorsplit[0].basis_grade_amt16 : 0,
      "split_vendor17": vendorsplit[0].txtVendor17 !== '' ? vendorsplit[0].txtVendor17 : null,
      "split_remit17": vendorsplit[0].ddRemitTo17 !== '' ? vendorsplit[0].ddRemitTo17 : "",
      "split_share_pct17": vendorsplit[0].txtSharePercentage17 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage17) : 0,
      "gross_wt17": vendorsplit[0].gross_wt17 !== '' ? vendorsplit[0].gross_wt17 : 0,
      "net_wt17": vendorsplit[0].net_wt17 !== '' ? vendorsplit[0].net_wt17 : 0,
      "lsk_wt17": vendorsplit[0].lsk_wt17 !== '' ? vendorsplit[0].lsk_wt17 : 0,
      "basis_grade_amt17": vendorsplit[0].basis_grade_amt17 !== '' ? vendorsplit[0].basis_grade_amt17 : 0,
      "split_vendor18": vendorsplit[0].txtVendor18 !== '' ? vendorsplit[0].txtVendor18 : null,
      "split_remit18": vendorsplit[0].ddRemitTo18 !== '' ? vendorsplit[0].ddRemitTo18 : "",
      "split_share_pct18": vendorsplit[0].txtSharePercentage18 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage18) : 0,
      "gross_wt18": vendorsplit[0].gross_wt18 !== '' ? vendorsplit[0].gross_wt18 : 0,
      "net_wt18": vendorsplit[0].net_wt18 !== '' ? vendorsplit[0].net_wt18 : 0,
      "lsk_wt18": vendorsplit[0].lsk_wt18 !== '' ? vendorsplit[0].lsk_wt18 : 0,
      "basis_grade_amt18": vendorsplit[0].basis_grade_amt18 !== '' ? vendorsplit[0].basis_grade_amt18 : 0,
      "split_vendor19": vendorsplit[0].txtVendor19 !== '' ? vendorsplit[0].txtVendor19 : null,
      "split_remit19": vendorsplit[0].ddRemitTo19 !== '' ? vendorsplit[0].ddRemitTo19 : "",
      "split_share_pct19": vendorsplit[0].txtSharePercentage19 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage19) : 0,
      "gross_wt19": vendorsplit[0].gross_wt19 !== '' ? vendorsplit[0].gross_wt19 : 0,
      "net_wt19": vendorsplit[0].net_wt19 !== '' ? vendorsplit[0].net_wt19 : 0,
      "lsk_wt19": vendorsplit[0].lsk_wt19 !== '' ? vendorsplit[0].lsk_wt19 : 0,
      "basis_grade_amt19": vendorsplit[0].basis_grade_amt19 !== '' ? vendorsplit[0].basis_grade_amt19 : 0,
      "split_vendor20": vendorsplit[0].txtVendor20 !== '' ? vendorsplit[0].txtVendor20 : null,
      "split_remit20": vendorsplit[0].ddRemitTo20 !== '' ? vendorsplit[0].ddRemitTo20 : "",
      "split_share_pct20": vendorsplit[0].txtSharePercentage20 !== '' ? parseFloat(vendorsplit[0].txtSharePercentage20) : 0,
      "gross_wt20": vendorsplit[0].gross_wt20 !== '' ? vendorsplit[0].gross_wt20 : 0,
      "net_wt20": vendorsplit[0].net_wt20 !== '' ? vendorsplit[0].net_wt20 : 0,
      "lsk_wt20": vendorsplit[0].lsk_wt20 !== '' ? vendorsplit[0].lsk_wt20 : 0,
      "basis_grade_amt20": vendorsplit[0].basis_grade_amt20 !== '' ? vendorsplit[0].basis_grade_amt20 : 0,
      "insp_remark":Searchspecial(statevalue.txtarInspectionRemarks),
      "settle_remark":Searchspecial(statevalue.txtarSettleRemarks),
      "loan_repay_rate": "",
      "settle_correct_cnt": 0,
      "print_seg_ind": document.getElementById('chkboxPrintSeg').checked == false ? "N" : "Y",
      "load_num": statevalue.txtLoad !== "" ? statevalue.txtLoad : 0,
      "lot_num": statevalue.txtLot,
      "seal_num": statevalue.txtSeal,
      "start_date_time": statevalue.dtStartDateTime,
      "seed_meeting_spec_ind": statevalue.txtSeedMeetingSpec == 'No' ? "N" : statevalue.txtSeedMeetingSpec == 'Yes' ? "Y" : "",
      "user_id": useridFromLS,
      "idt_auto_generated": "",
      "probe_pattern": statevalue.txtProbingPattern,
      "bluepan_wt": statevalue.txtBluePanWT,
      "meter_read": statevalue.txtMeterReading,
      "kernel_rps": statevalue.txtKernelsRPS,
      "kernel_rels": statevalue.txtKernelsREL,
      "elk_damaged": statevalue.txtELKDamaged,
      "damaged_krs": statevalue.txtDamagedKRS,
      "tot_krs_wt": 0,
      "cid":compIdFromLS
    }
    let ScaleTicketData = [];
    for (let i = 1; i <= 5; i++) {
      let data = getValue(thisObj, "gridScaleTickets");
      if (i <= data.length) {
        objSave["load_num" + (i)] = data[i - 1].arrTicketInfo.load_num
        objSave["vehicle_num" + (i)] = data[i - 1].arrTicketInfo.vehicle_num !== null && data[i - 1].arrTicketInfo.vehicle_num !== undefined && data[i - 1].arrTicketInfo.vehicle_num !== "" ? data[i - 1].arrTicketInfo.vehicle_num : ''
        objSave["truck_line" + (i)] = data[i - 1].arrTicketInfo.truck_line !== null && data[i - 1].arrTicketInfo.truck_line !== undefined && data[i - 1].arrTicketInfo.truck_line !== "" ? data[i - 1].arrTicketInfo.truck_line : ''
        objSave["driver_name" + (i)] = data[i - 1].arrTicketInfo.driver_name !== null && data[i - 1].arrTicketInfo.driver_name !== undefined && data[i - 1].arrTicketInfo.driver_name !== "" ? data[i - 1].arrTicketInfo.driver_name : ''
        objSave["shp_date" + (i)] = data[i - 1].arrTicketInfo.shp_date !== null && data[i - 1].arrTicketInfo.shp_date !== undefined && data[i - 1].arrTicketInfo.shp_date !== "" ? data[i - 1].arrTicketInfo.shp_date : ''
        objSave["shp_scale_tick" + (i)] = data[i - 1].arrTicketInfo.shp_scale_tick !== null && data[i - 1].arrTicketInfo.shp_scale_tick !== undefined && data[i - 1].arrTicketInfo.shp_scale_tick !== "" ? data[i - 1].arrTicketInfo.shp_scale_tick : ''
        objSave["shp_split_scale" + (i)] = data[i - 1].arrTicketInfo.shp_split_scale !== null && data[i - 1].arrTicketInfo.shp_split_scale !== undefined && data[i - 1].arrTicketInfo.shp_split_scale !== "" ? data[i - 1].arrTicketInfo.shp_split_scale : ''
        objSave["shp_tare_wt" + (i)] = data[i - 1].arrTicketInfo.shp_tare_wt !== null && data[i - 1].arrTicketInfo.shp_tare_wt !== undefined && data[i - 1].arrTicketInfo.shp_tare_wt !== "" ? data[i - 1].arrTicketInfo.shp_tare_wt : ''
        objSave["shp_vehicle_wt" + (i)] = data[i - 1].arrTicketInfo.shp_vehicle_wt !== null && data[i - 1].arrTicketInfo.shp_vehicle_wt !== undefined && data[i - 1].arrTicketInfo.shp_vehicle_wt !== "" ? data[i - 1].arrTicketInfo.shp_vehicle_wt : ''
        objSave["shp_peanut_wt" + (i)] = data[i - 1].arrTicketInfo.shp_peanut_wt !== null && data[i - 1].arrTicketInfo.shp_peanut_wt !== undefined && data[i - 1].arrTicketInfo.shp_peanut_wt !== "" ? data[i - 1].arrTicketInfo.shp_peanut_wt : ''
        objSave["rec_date" + (i)] = data[i - 1].arrTicketInfo.rec_date !== null && data[i - 1].arrTicketInfo.rec_date !== undefined && data[i - 1].arrTicketInfo.rec_date !== "" ? data[i - 1].arrTicketInfo.rec_date : ''
        objSave["rec_scale_tick" + (i)] = data[i - 1].arrTicketInfo.rec_scale_tick !== null && data[i - 1].arrTicketInfo.rec_scale_tick !== undefined && data[i - 1].arrTicketInfo.rec_scale_tick !== "" ? data[i - 1].arrTicketInfo.rec_scale_tick : ''
        objSave["rec_split_scale" + (i)] = data[i - 1].arrTicketInfo.rec_split_scale !== null && data[i - 1].arrTicketInfo.rec_split_scale !== undefined && data[i - 1].arrTicketInfo.rec_split_scale !== "" ? data[i - 1].arrTicketInfo.rec_split_scale : ''
        objSave["rec_tare_wt" + (i)] = data[i - 1].arrTicketInfo.rec_tare_wt !== null && data[i - 1].arrTicketInfo.rec_tare_wt !== undefined && data[i - 1].arrTicketInfo.rec_tare_wt !== "" ? data[i - 1].arrTicketInfo.rec_tare_wt : ''
        objSave["rec_vehicle_wt" + (i)] = data[i - 1].arrTicketInfo.rec_vehicle_wt !== null && data[i - 1].arrTicketInfo.rec_vehicle_wt !== undefined && data[i - 1].arrTicketInfo.rec_vehicle_wt !== "" ? data[i - 1].arrTicketInfo.rec_vehicle_wt : ''
        objSave["rec_peanut_wt" + (i)] = data[i - 1].arrTicketInfo.rec_peanut_wt !== null && data[i - 1].arrTicketInfo.rec_peanut_wt !== undefined && data[i - 1].arrTicketInfo.rec_peanut_wt !== "" ? data[i - 1].arrTicketInfo.rec_peanut_wt : ''
        objSave["inventory_with_ind" + (i)] = data[i - 1].arrTicketInfo.inventory_with_ind !== null && data[i - 1].arrTicketInfo.inventory_with_ind !== undefined && data[i - 1].arrTicketInfo.inventory_with_ind !== "" ? data[i - 1].arrTicketInfo.inventory_with_ind : ''
        objSave["inventory_other_wt" + (i)] = data[i - 1].arrTicketInfo.inventory_other_wt !== null && data[i - 1].arrTicketInfo.inventory_other_wt !== undefined && data[i - 1].arrTicketInfo.inventory_other_wt !== "" ? data[i - 1].arrTicketInfo.inventory_other_wt : '0'
      } else {
        objSave["load_num" + (i)] = 0
        objSave["vehicle_num" + (i)] = ''
        objSave["truck_line" + (i)] = ''
        objSave["driver_name" + (i)] = ''
        objSave["shp_date" + (i)] = ''
        objSave["shp_scale_tick" + (i)] = ''
        objSave["shp_split_scale" + (i)] = ''
        objSave["shp_tare_wt" + (i)] = ''
        objSave["shp_vehicle_wt" + (i)] = ''
        objSave["shp_peanut_wt" + (i)] = ''
        objSave["rec_date" + (i)] = ''
        objSave["rec_scale_tick" + (i)] = ''
        objSave["rec_split_scale" + (i)] = ''
        objSave["rec_tare_wt" + (i)] = ''
        objSave["rec_vehicle_wt" + (i)] = ''
        objSave["rec_peanut_wt" + (i)] = ''
        objSave["inventory_with_ind" + (i)] = ''
        objSave["inventory_other_wt" + (i)] = 0

      }
    }
    if(statevalue.ddCategory.toUpperCase().substring(0, 3) == "IDT"){
      objSave["idt_comp_id"]= statevalue.ddIDTfromtoCompany
      objSave["idt_comp_vendor"]=statevalue.txtIDTfromtoVendor
      objSave["idt_buy_pt_id"]=statevalue.ddIDTfromtoLocation
    }else{
      objSave["idt_comp_id"]= ""
      objSave["idt_comp_vendor"]=""
      objSave["idt_buy_pt_id"]=""
    }
    let UpdateTradeSettlement = await SettlementService.UpdateTradeSettlement(null,strAction, statevalue.ddShipFromToLocation, statevalue.txtTradeInspectSC95, objSave);
  
    setLoading(false)
  }
 
const bFillVendorSplit = async (splitvendornumarr, splitremito, splitshareprct) => {
    // setLoading(true)
    const datan = splitvendornumarr
    let data = datan
    if (data.length > 4) {
      for (var i = 0; i < data.length; i++) {
        let newGrpbx = `grpbxVendorSplitRow` + i
        show(thisObj, newGrpbx)
      }
      setData(thisObj, "arrRow", data)
    }
    for (var i = 0; i < data.length; i++) {
      let newVendor = 'txtVendor' + (i + 1)
      let newLblxx = 'lblXX' + (i + 1)
      let newRemitTo = 'ddRemitTo' + (i + 1)
      let newshare = 'txtSharePercentage' + (i + 1)
      setValue(thisObj, newVendor, [splitvendornumarr[i]])
      setValue(thisObj, newshare, splitshareprct[i])
      RemitDetails(splitvendornumarr[i], newRemitTo, newLblxx, splitremito[i])
    }
    // setLoading(false)
  }
  // #endregion
  const bFillForm = async (Inspecnum, BUY_PT_ID, AddUpdata) => {
    let dblAgreePrice = 0,
      nAgreeCnt = 0
    setLoading(true)
    let RetrieveTradeSettlegetDetails = await SettlementService.RetrieveTradeSettlementDetails(BUY_PT_ID, Inspecnum);
    let sHeader = RetrieveTradeSettlegetDetails[0].trade_settle_header,
      sVendorSplits = RetrieveTradeSettlegetDetails[0].trade_settle_vendor_split,
      sRemarks = RetrieveTradeSettlegetDetails[0].trade_settle_remark,
      sScaleTickets = RetrieveTradeSettlegetDetails[0].trade_settle_scale_tickets,
      sPremDeductions = RetrieveTradeSettlegetDetails[0].trade_settle_prem_deduct,
      mbAreaId = sHeader[0].area_id

    let vendorjoinarray = [],
      remitvendordetails = [],
      sharearray = [],
      objVal = {}

    for (let j = 0; j < sVendorSplits.length; j++) {
      vendorjoinarray.push(sVendorSplits[j].split_vendor)
      remitvendordetails.push(sVendorSplits[j].split_remit)
      sharearray.push(sVendorSplits[j].split_share_pct)
    }
    setTradesettleheader(sHeader)
    setTradesettlevendorsplit(sVendorSplits)
    setTradesettleremark(sRemarks)
    setTradesettlescaletickets(sScaleTickets)
    setTradesettlepremdeduct(sPremDeductions)

    if (sHeader.length > 0) {
      let value = sHeader[0].buy_pt_id
      await bFillVarietyList(value, sHeader[0].pnut_type_id)
      objVal['txtTradeInspectSC95'] = sHeader[0].trade_insp_num
      objVal['txtTradeInspectSC95'] = sHeader[0].trade_insp_num
      objVal['dtInspectDateTime'] = sHeader[0].insp_date_time
      objVal['dtSettleDateTime'] = sHeader[0].settle_date_time
      objVal['txtTradeSettle1007'] = sHeader[0].trade_settle_num
      objVal['txt1007Handler'] = sHeader[0].handler_num
      objVal['txt1007HandlerName'] = sHeader[0].handler_name

      if (sHeader[0].trade_status == "V") {
        objVal['txtSts'] = 'Void'
      }
      else if (sHeader[0].trade_status == "C") {
        objVal['txtSts'] = 'Complete'
      }
      else {
        objVal['txtSts'] = 'Pending'
      }
      objVal['ddShipFromToLocation'] = value
      AccessCheck(value)

      objVal['radioTradeSaleTradePurchase'] = sHeader[0].purch_sale_ind

      bFillCategoryList(null, sHeader[0].purch_sale_ind)
      if (sHeader[0].purch_sale_ind == 'P') {
        document.getElementsByClassName('ddShipFromToLocation')[0].childNodes[0].childNodes[0].innerText = 'Received Into Location:'
        document.getElementsByClassName('lblShippedFrom')[0].childNodes[0].childNodes[0].innerText = 'Received Into'
        document.getElementsByClassName('lblShippedTo')[0].childNodes[0].childNodes[0].innerText = 'Received From'
        document.getElementsByClassName('ddIDTfromtoCompany')[0].childNodes[0].childNodes[0].innerText = 'IDT from Company:'
        document.getElementsByClassName('txtIDTfromtoVendor')[0].childNodes[0].childNodes[0].innerText = 'IDT from Vendor:'
        document.getElementsByClassName('ddIDTfromtoLocation')[0].childNodes[0].childNodes[0].innerText = 'IDT from Location:'

      } else {
        document.getElementsByClassName('ddShipFromToLocation')[0].childNodes[0].childNodes[0].innerText = 'Shipped From Location:'
        document.getElementsByClassName('lblShippedFrom')[0].childNodes[0].childNodes[0].innerText = 'Shipped From'
        document.getElementsByClassName('lblShippedTo')[0].childNodes[0].childNodes[0].innerText = 'Shipped To'
        document.getElementsByClassName('ddIDTfromtoCompany')[0].childNodes[0].childNodes[0].innerText = 'IDT to Company:'
        document.getElementsByClassName('txtIDTfromtoVendor')[0].childNodes[0].childNodes[0].innerText = 'IDT to Vendor:'
        document.getElementsByClassName('ddIDTfromtoLocation')[0].childNodes[0].childNodes[0].innerText = 'IDT to Location:'
      }
      if (sHeader[0].idt_buy_pt_id !== '' && sHeader[0].trade_status !== "Pending") {
        disable(thisObj, 'ddCategory')
      }
      if (sHeader[0].gram_entry_override == 'N') {
        objVal['radioGramsPercentage'] = "2"
      } else {
        objVal['radioGramsPercentage'] = "1"
      }
      objVal['radioGradePricingMethod'] = sHeader[0].grade_pricing_method
      objVal['ddCategory'] = sHeader[0].trade_category
      objVal['ddPeanutType'] = sHeader[0].pnut_type_id
      objVal['ddPeanutVariety'] = sHeader[0].pnut_variety_id
      objVal['ddSeg'] = sHeader[0].seg_type
      objVal['ddGeneration'] = sHeader[0].seed_gen
      objVal['ddOleic'] = sHeader[0].oleic_ind
      objVal['ddEdibleOil'] = sHeader[0].edible_oil_ind

      if (sHeader[0].trade_category.toUpperCase().substring(0, 3) == "IDT") {
        document.getElementsByClassName('txtDTWhseBinNum')[0].childNodes[0].childNodes[0].innerText = 'Whse#/B'
      }
      else {
        document.getElementsByClassName('txtDTWhseBinNum')[0].childNodes[0].childNodes[0].innerText = ''
      }

      let selectedchkboxReGrading = [];
      let obj1;
      if (sHeader[0].vicam_ind == "Y") {
        enable(thisObj, 'txtVicamReading')
        obj1 = '3'
        selectedchkboxReGrading.push(obj1)
        objVal['chkboxReGrading'] = selectedchkboxReGrading
        objVal['txtVicamReading'] = sHeader[0].vicam_ppb
      } else {
        objVal['chkboxReGrading'] = selectedchkboxReGrading
        enable(thisObj, 'txtVicamReading')
        objVal['txtVicamReading'] = ''
      }
      //PRB0053866
      //objVal['txtLSK'] = sHeader[0].lsk_wt

      // objVal['txtStsVal'] = sHeader[0].trade_status

      objVal['txtDTWhseBinNum'] = sHeader[0].ship_to_from
      if (sHeader[0].corn_ind == "Y") {
        obj1 = '1'
        selectedchkboxReGrading.push(obj1)
        objVal['chkboxReGrading'] = selectedchkboxReGrading
      } else {
        objVal['chkboxReGrading'] = selectedchkboxReGrading
      }
      if (sHeader[0].flavus_ind == "Y") {
        obj1 = '2'
        selectedchkboxReGrading.push(obj1)
        objVal['chkboxReGrading'] = selectedchkboxReGrading
      } else {
        objVal['chkboxReGrading'] = selectedchkboxReGrading
      }
      // regrade
      objVal['txtFMSampleWt'] = sHeader[0].fm_sample
      objVal['txtCleanSampleWt'] = sHeader[0].cln_sample
      objVal['txtTotalSMKRScreen'] = sHeader[0].smk_gr
      objVal['txtSoundSplits'] = sHeader[0].ss_gr
      objVal['txtTotalOK'] = sHeader[0].ok_gr
      objVal['txtDamageSplits'] = sHeader[0].dam_split_gr
      objVal['txtTotalDamage'] = sHeader[0].dam_gr
      objVal['txtHulls'] = sHeader[0].hull_gr
      objVal['txtFreezeDamage'] = sHeader[0].frez_dam_gr
      objVal['txtPittingDamage'] = sHeader[0].pit_dam_gr
      objVal['txtConcealedRMD'] = sHeader[0].cncl_rmd_gr
      objVal['txtCrackedBroken'] = sHeader[0].cr_br_gr
      objVal['txtDiscoloredShells'] = sHeader[0].dcol_shel_gr
      objVal['txtHullBrightness'] = sHeader[0].hul_brit_gr
      objVal['txtJumbo'] = sHeader[0].jumbo_gr
      objVal['txtKernRidingELK'] = sHeader[0].elk_ride_gr
      objVal['txtTotalELKDamage'] = sHeader[0].elk_dam_gr
      objVal['txtNetELK'] = sHeader[0].elk_gr
      objVal['txtKernRidingPrescribe'] = sHeader[0].ride_pscrib_gr
      objVal['txtForeignMtrl'] = sHeader[0].fm_gr
      objVal['txtTotalSLK'] = sHeader[0].lsk_gr
      objVal['txtFancy'] = sHeader[0].fan_gr
      objVal['txtTotalSMKRScreenPct'] = sHeader[0].smk_pct
      objVal['txtSMK1Pct'] = sHeader[0].smk_1_pct
      objVal['txtSMK2Pct'] = sHeader[0].smk_2_pct
      objVal['txtSMK3Pct'] = sHeader[0].smk_3_pct
      objVal['txtSMK4Pct'] = sHeader[0].smk_4_pct
      objVal['txtSoundSplitsPct'] = sHeader[0].ss_pct
      objVal['txtTotalOKPct'] = sHeader[0].ok_pct
      objVal['txtOKRidingPct'] = sHeader[0].ok_ride_pct
      objVal['txtOKFallThru'] = sHeader[0].ok_fall_pct
      objVal['txtOKDamageRiding'] = sHeader[0].ok_dam_pct
      objVal['txtTotalDamagePct'] = sHeader[0].dam_pct
      objVal['txtHullsPct'] = sHeader[0].hull_pct
      objVal['txtFreezeDamagePct'] = sHeader[0].frez_dam_pct
      objVal['txtPittingDamagePct'] = sHeader[0].pit_dam_pct
      objVal['txtConcealedRMDPct'] = sHeader[0].cncl_rmd_pct
      objVal['txtCrackedBrokenPct'] = sHeader[0].cr_br_pct
      objVal['txtDiscoloredShellsPct'] = sHeader[0].dcol_shel_pct
      objVal['txtHullBrightnessPct'] = sHeader[0].hul_brit_pct
      objVal['txtJumboPct'] = sHeader[0].jumbo_pct
      objVal['txtKernRidingELKPct'] = sHeader[0].elk_ride_pct
      objVal['txtTotalELKDamagePct'] = sHeader[0].elk_dam_pct
      objVal['txtNetELKPct'] = sHeader[0].elk_pct
      objVal['txtKernRidingPrescribePct'] = sHeader[0].ride_pscrib_pct
      objVal['txtForeignMaterialPct'] = sHeader[0].fm_pct
      objVal['txtTotalSLKPct'] = sHeader[0].lsk_pct
      objVal['txtLSKRidingPct'] = sHeader[0].lsk_ride_pct
      objVal['txtLSKFallThru'] = sHeader[0].lsk_fall_pct
      objVal['txtLSKDamageRiding'] = sHeader[0].lsk_dam_pct
      objVal['txtMoisture'] = sHeader[0].moist_pct
      objVal['txtFancyPct'] = sHeader[0].fan_pct
      setLblTKC(sHeader[0].tkc_pct)
      objVal['txtProbingPattern'] = sHeader[0].probe_pattern
      objVal['txtKernelsREL'] = sHeader[0].kernel_rels
      objVal['txtKernelsRPS'] = sHeader[0].kernel_rps
      objVal['txtDamagedKRS'] = sHeader[0].damaged_krs
      objVal['txtMeterReading'] = sHeader[0].meter_read
      objVal['txtBluePanWT'] = sHeader[0].bluepan_wt
      objVal['txtELKDamaged'] = sHeader[0].elk_damaged
      objVal['txtOtherFM'] = sHeader[0].oth_fm
      objVal['txtOKRidingGr'] = sHeader[0].ok_gauge
      objVal['txtLSKRidingGr'] = sHeader[0].lsk_gauge
      objVal['txtSMK1Gr'] = sHeader[0].smk_1_gauge
      objVal['txtSMK2Gr'] = sHeader[0].smk_2_gauge
      objVal['txtSMK3Gr'] = sHeader[0].smk_3_gauge
      objVal['txtSMK4Gr'] = sHeader[0].smk_4_gauge
      objVal['txtLoad'] = sHeader[0].load_num
      objVal['txtWormDamage'] = sHeader[0].worm_dam_pct
      objVal['txtLot'] = sHeader[0].lot_num
      objVal['txtSeal'] = sHeader[0].seal_num
      objVal['dtStartDateTime'] = sHeader[0].start_date_time
      let txtTotalSMKRScreenPct = sHeader[0].smk_pct
      if (txtTotalSMKRScreenPct == undefined || txtTotalSMKRScreenPct == '' || txtTotalSMKRScreenPct == null) {
        txtTotalSMKRScreenPct = 0;
      }
      let txtSoundSplitsPct = sHeader[0].ss_pct
      if (txtSoundSplitsPct == undefined || txtSoundSplitsPct == '' || txtSoundSplitsPct == null) {
        txtSoundSplitsPct = 0;
      }
      let lblTotalSMK = FormatNumber(Number(txtTotalSMKRScreenPct) + Number(txtSoundSplitsPct));
      objVal['txtTotalSMK'] = lblTotalSMK

      let txtTotalOKPct = sHeader[0].ok_pct;
      if (txtTotalOKPct == undefined || txtTotalOKPct == '' || txtTotalOKPct == null) {
        txtTotalOKPct = 0;
      }
      let txtTotalDamagePct = sHeader[0].dam_pct;
      if (txtTotalDamagePct == undefined || txtTotalDamagePct == '' || txtTotalDamagePct == null) {
        txtTotalDamagePct = 0;
      }
      let totalvalueoftxtTotalKernels = FormatNumber(Number(lblTotalSMK) + Number(txtTotalOKPct) + Number(txtTotalDamagePct));
      objVal['txtTotalKernels'] = totalvalueoftxtTotalKernels;

      let txtHullsPct = sHeader[0].hull_pct;
      if (txtHullsPct == undefined || txtHullsPct == '' || txtHullsPct == null) {
        txtHullsPct = 0;
      }
      let totalvalueoftxtTotalKernelsAndHulls = FormatNumber(Number(totalvalueoftxtTotalKernels) + Number(txtHullsPct));
      setValue(thisObj, 'txtTotalKernelsAndHulls', totalvalueoftxtTotalKernelsAndHulls);

      if (sHeader[0].print_seg_ind == "Y") {
        setValue(thisObj, "chkboxPrintSeg", true)
      } else {
        setValue(thisObj, "chkboxPrintSeg", false)
      }
      if (sHeader[0].seed_meeting_spec_ind == "Y") {
        objVal['txtSeedMeetingSpec'] = "Yes"
      } else if (sHeader[0].seed_meeting_spec_ind == "N") {
        objVal['txtSeedMeetingSpec'] = "No"
      } else {
        objVal['txtSeedMeetingSpec'] = ""
      }

      objVal['lblAddedByValue'] = sHeader[0].add_user + " " + moment(String(sHeader[0].add_date_time)).format('MM/DD/YYYY hh:mm A')
      objVal['lblChangedByValue'] = sHeader[0].chg_user + " " + moment(String(sHeader[0].chg_date_time)).format('MM/DD/YYYY hh:mm A')
      if (sHeader[0].inventory_whouse !== '' && sHeader[0].inventory_bin !== '') {

        bFillWarehouseBinList('ddWhseBinNum', BUY_PT_ID, sHeader[0].pnut_type_id, sHeader[0].seg_type, sHeader[0].seed_gen, sHeader[0].oleic_ind, sHeader[0].pnut_variety_id)
        setValue(thisObj, 'ddWhseBinNum', sHeader[0].inventory_whouse + "-" + sHeader[0].inventory_bin)
      }
      if (sVendorSplits.length > 0) {
        let txtNetWeight = 0,
          txtTotalLoadValue = 0.00,
          txtTKCLbs = 0.00,
          txtAgreementPrice = 0.00,
          txtSupportPercent = 0.00,
          txtSegValue = 0.00,
          txtPremiums = 0.00,
          txtDeductions = 0.00,
          txtProceedsValue = 0.00,
          priceperlbs = 0

        for (let z = 0; z < sVendorSplits.length; z++) {
          if (sHeader[0].trade_status == "C") {
            let nNetWt = parseFloat(sVendorSplits[z].net_wt),
              nLskWt = parseFloat(sVendorSplits[z].lsk_wt),
              nSmkPct = parseFloat(sHeader[0].smk_pct)
            if (sHeader[0].grade_pricing_method == "TKC") {
              dblAgreePrice = 0
              nAgreeCnt = 0
              txtTKCLbs = txtTKCLbs + ((((nNetWt - nLskWt) * (nSmkPct / 100)) + nLskWt))
              txtNetWeight = ""
            } else {
              txtTKCLbs = ""
              txtNetWeight = txtTKCLbs + nNetWt
            }
            txtTotalLoadValue += parseFloat(sVendorSplits[z].basis_grade_amt)

            if (sHeader[0].grade_pricing_method == "TKC") {
              let TradeSettlementApplicationList = await SettlementService.RetrieveTradeSettlementApplicationDetails(sHeader[0].trade_insp_num, 'N')
              //sAgreements = RetrieveTradeAgreementApplicationDetails[0]
              priceperlbs = TradeSettlementApplicationList[0].price_per_lbs
              if (priceperlbs !== '') {
                dblAgreePrice += Number(priceperlbs)
                nAgreeCnt = nAgreeCnt + 1
              }
              if (nAgreeCnt > 0 && dblAgreePrice > 0) {
                txtAgreementPrice = dblAgreePrice / nAgreeCnt
              }
            } else {
              txtAgreementPrice = parseFloat(sVendorSplits[z].value_per_ton)
            }
            txtSupportPercent += parseFloat(sVendorSplits[z].support_pct)
            txtSegValue += parseFloat(sVendorSplits[z].value_of_seg)
            txtPremiums += parseFloat(sVendorSplits[z].premium_amt)
            txtDeductions += parseFloat(sVendorSplits[z].deduct_amt)
            txtProceedsValue += parseFloat(sVendorSplits[z].proceeds_amt)
          }
        }
        if (parseInt(txtNetWeight) !== 0) {
          objVal['txtNetWeight'] = parseInt(txtNetWeight)
        } else {
          objVal['txtNetWeight'] = "0"
        }

        objVal['txtTotalLoadValue'] = parseFloat(txtTotalLoadValue).toFixed(2)
        if (parseInt(txtTKCLbs) !== 'NaN' && txtTKCLbs !== '') {
          objVal['txtTKCLbs'] = parseFloat(txtTKCLbs).toFixed(1)
        }
        objVal['txtAgreementPrice'] = parseFloat(txtAgreementPrice).toFixed(7)
        objVal['txtSupportPercent'] = parseFloat(txtSupportPercent).toFixed(2)
        objVal['txtSegValue'] = parseFloat(txtSegValue).toFixed(2)
        objVal['txtPremiums'] = parseFloat(txtPremiums).toFixed(2)
        objVal['txtDeductions'] = parseFloat(txtDeductions).toFixed(2)
        objVal['txtProceeds'] = parseFloat(txtProceedsValue).toFixed(2)
        objVal['txtarInspectionRemarks'] = sRemarks[0].insp_remark
        objVal['txtarSettleRemarks'] = sRemarks[0].settle_remark

      }
      let dblWtIncVehicle = 0,
        dblVehicleWt = 0
      let data = []
      let RetrieveTradeAgreementApplicationDetails = RetrieveTradeSettlegetDetails[0].trade_settle_scale_tickets;
      if (RetrieveTradeAgreementApplicationDetails.length > 0) {
        data = RetrieveTradeAgreementApplicationDetails[0].trade_settle_scale_ticket;
      }
      let js = [];
      let obj = {}
      if (data.length > 0) {
        for (var j = 0; j < data.length; j++) {
          obj.txtcolLoad = data[j].load_num;
          obj.txtcolVehicle = data[j].vehicle_num;
          obj.txtcolShipDate = data[j].shp_date !== "" && data[j].shp_date !== "Invalid date" && data[j].shp_date !== undefined ? moment(data[j].shp_date).format('MM/DD/YYYY') : '';
          obj.txtcolShipTicket = data[j].shp_scale_tick !== 0 ? data[j].shp_scale_tick : "";
          obj.txtcolShipWt = data[j].shp_peanut_wt !== 0 ? data[j].shp_peanut_wt : "";
          obj.txtcolRecvDate = data[j].rec_date !== "" && data[j].rec_date !== "Invalid date" && data[j].rec_date !== undefined ? moment(data[j].rec_date).format('MM/DD/YYYY') : ''
          obj.txtcolRecvTicket = data[j].rec_scale_tick !== 0 ? data[j].rec_scale_tick : "";
          obj.txtcolRecvWt = data[j].rec_peanut_wt !== 0 ? data[j].rec_peanut_wt : "";

          obj.truck_line = data[j].truck_line
          obj.driver_name = data[j].driver_name
          obj.arrTicketInfo = data[j]
          let shptarewt = data[j].shp_tare_wt !== undefined && data[j].shp_tare_wt !== "" ? data[j].shp_tare_wt : 0
          let shpvehiclewt = data[j].shp_vehicle_wt !== undefined && data[j].shp_vehicle_wt !== "" ? data[j].shp_vehicle_wt : 0
          let rectarewt = data[j].rec_tare_wt !== undefined && data[j].rec_tare_wt !== "" ? data[j].rec_tare_wt : 0
          let recvehiclewt = data[j].rec_vehicle_wt !== undefined && data[j].rec_vehicle_wt !== "" ? data[j].rec_vehicle_wt : 0

          switch (data[j].inventory_with_ind) {
            case "S":
              obj.txtcolInventoryWt = data[j].shp_peanut_wt;
              dblWtIncVehicle = dblWtIncVehicle + parseInt(shptarewt)
              dblVehicleWt = dblVehicleWt + parseInt(shpvehiclewt)
              break;
            case "R":
              obj.txtcolInventoryWt = data[j].rec_peanut_wt;
              dblWtIncVehicle = dblWtIncVehicle + parseInt(rectarewt)
              dblVehicleWt = dblVehicleWt + parseInt(recvehiclewt)
              break;
            case "O":
              obj.txtcolInventoryWt = data[j].inventory_other_wt;
              dblWtIncVehicle = dblWtIncVehicle + parseInt(rectarewt)
              dblVehicleWt = dblVehicleWt + parseInt(recvehiclewt)
              break;
          }
          js.push(obj)
          obj = {}
        }

        await CalculateValues(mbAreaId, sHeader[0].pnut_type_id, dblWtIncVehicle, dblVehicleWt, sHeader[0].moist_pct, sHeader[0].cr_br_pct, sHeader[0].dcol_shel_pct,
          sHeader[0].fan_pct, sHeader[0].fm_pct, sHeader[0].lsk_pct, sHeader[0].smk_pct, sHeader[0].ss_pct, sHeader[0].ok_pct,
          sHeader[0].elk_pct, sHeader[0].dam_pct, sHeader[0].vicam_ppb, sHeader[0].grade_pricing_method)
        setValue(thisObj, "gridScaleTickets", js)
        //setticketscaledata(data)
      } else {
        setValue(thisObj, "gridScaleTickets", [])
      }
      await setFieldValues(thisObj, objVal, true);
      let RetrieveValueCalculationList = await SettlementService.RetrieveValueCalculationList(mbAreaId, sHeader[0].pnut_type_id, dblWtIncVehicle, dblVehicleWt, sHeader[0].moist_pct, sHeader[0].cr_br_pct, sHeader[0].dcol_shel_pct,
        sHeader[0].fan_pct, sHeader[0].fm_pct, sHeader[0].lsk_pct, sHeader[0].smk_pct, sHeader[0].ss_pct, sHeader[0].ok_pct,
        sHeader[0].elk_pct, sHeader[0].dam_pct, sHeader[0].vicam_ppb);

      setCalculationList(RetrieveValueCalculationList)
      await bFillVendorSplit(vendorjoinarray, remitvendordetails, sharearray)
      await CalculateTotalPounds(data)
      await EnableDisableControls(BUY_PT_ID, sHeader[0].trade_status, sHeader[0].seg_type, AddUpdata)
      await EnableDisableCalculateEdit(js, sHeader[0].grade_pricing_method, sHeader[0].smk_pct, sHeader[0].hull_pct, sHeader[0].fm_sample, sHeader[0].cln_sample, sHeader[0].ss_pct, sHeader[0].ok_pct, sHeader[0].dam_pct)
      await PremDeductions(sHeader[0].grade_pricing_method, RetrieveValueCalculationList, sHeader, sVendorSplits, sScaleTickets)
      setLoading(false)
    }
  }

  const SaveSettlement = (sHeader, Vendordetails, lstNewContractLine, lblPurchValueOfSeg, RetrieveValueCalculationList, sScaleTickets) => {
    let lstNewContractLine1 = lstNewContractLine
    let sApplContractList = "",
      sTentContractList = "",
      sAppliedList = "",
      sPVendorList = "",
      sPrevContract = "",
      lApplLbs = 0,
      dblGrossWtP = 0,
      dblLSKWtP = 0,
      dblNetWtP = 0

    for (let i = 0; i < lstNewContractLine.length; i++) {
      let arrContractLine = lstNewContractLine[i];
      if (arrContractLine[0] != "SPOT") {
        if (sPrevContract != arrContractLine[0]) {
          if (parseFloat(lApplLbs) != 0) {
            sAppliedList += lApplLbs + ",";
            sApplContractList += sPrevContract + ",";
            lApplLbs = 0;
          }
        }
        if (arrContractLine[3] == "A") {
          lApplLbs += parseFloat(arrContractLine[2]);
        }
        sPrevContract = arrContractLine[0];
      }
    }

    if (lApplLbs != 0) {
      sAppliedList += lApplLbs + ",";
      sApplContractList += sPrevContract + ",";
      lApplLbs = 0;
    }

    if (sAppliedList.length > 0) {
      sAppliedList = sAppliedList.substring(0, sAppliedList.length - 1);
    }

    if (sApplContractList.length > 0) {
      sApplContractList = sApplContractList.substring(0, sApplContractList.length - 1);
    }

    sPVendorList = Vendordetails
    let vendorjoinarray = []
    let jsss = []
    let objjj = {}
    for (let j = 0; j < Vendordetails.length; j++) {
      objjj = { ...Vendordetails[j] }
      let lblValueOfSeg_1 = parseFloat(lblPurchValueOfSeg) * (parseFloat(Vendordetails[j].split_share_pct) * 0.01)
      vendorjoinarray.push(Vendordetails[j].split_vendor)
      objjj.value_of_seg = lblValueOfSeg_1.toFixed(2)
      jsss.push(objjj)
      objjj = {}
    }
    dblGrossWtP = sHeader[0].gross_wt
    dblLSKWtP = sHeader[0].lsk_wt
    dblNetWtP = sHeader[0].net_wt

    let radioGradePricingMethod_ = false
    if (sHeader[0].grade_pricing_method !== "TKC") {
      radioGradePricingMethod_ = false
    } else {
      radioGradePricingMethod_ = true
    }
    let ReadPremiumsDeductionsTS = {}

    ReadPremiumsDeductionsTS.LstrInspNum = sHeader[0].trade_insp_num
    ReadPremiumsDeductionsTS.LstrBuyPtID = sHeader[0].buy_pt_id
    ReadPremiumsDeductionsTS.LstrInspectInd = "N"
    ReadPremiumsDeductionsTS.lblPurchValueOfSeg = lblPurchValueOfSeg
    ReadPremiumsDeductionsTS.sApplContractList = sApplContractList
    ReadPremiumsDeductionsTS.sAppliedList = sAppliedList
    ReadPremiumsDeductionsTS.sPVendorListcount = vendorjoinarray.length
    ReadPremiumsDeductionsTS.dblNetWtP = dblNetWtP
    ReadPremiumsDeductionsTS.dblLSKWtP = dblLSKWtP
    ReadPremiumsDeductionsTS.dblGrossWtP = dblGrossWtP
    ReadPremiumsDeductionsTS.LstrPDcodes_cure_clean_costs = 0
    ReadPremiumsDeductionsTS.sPVendorList = vendorjoinarray.toString()
    ReadPremiumsDeductionsTS.sTentContractList = sTentContractList
    setData(thisObj, "ReadPremiumsDeductionsTS", ReadPremiumsDeductionsTS);

    var ReviewCreateSettlementFormLoadDataObj = {}
    ReviewCreateSettlementFormLoadDataObj.txtTradeInspNum = sHeader[0].trade_insp_num
    ReviewCreateSettlementFormLoadDataObj.optTradeSale = sHeader[0].purch_sale_ind
    ReviewCreateSettlementFormLoadDataObj.Vendordetails = jsss
    ReviewCreateSettlementFormLoadDataObj.cboPeanutType = sHeader[0].pnut_type_id
    ReviewCreateSettlementFormLoadDataObj.cboSeg = sHeader[0].seg_type
    ReviewCreateSettlementFormLoadDataObj.cboGeneration = sHeader[0].seed_gen
    ReviewCreateSettlementFormLoadDataObj.optTKCBasis = sHeader[0].grade_pricing_method
    ReviewCreateSettlementFormLoadDataObj.lblNetWeight = RetrieveValueCalculationList[0].net_weight
    ReviewCreateSettlementFormLoadDataObj.txtTotalSMKRScreenPct = sHeader[0].smk_pct
    ReviewCreateSettlementFormLoadDataObj.lblLSK = sHeader[0].lsk_wt
    ReviewCreateSettlementFormLoadDataObj.lblTotalValueOfLoad = RetrieveValueCalculationList[0].total_value_of_load
    ReviewCreateSettlementFormLoadDataObj.ddPeanutVariety = sHeader[0].pnut_variety_id
    ReviewCreateSettlementFormLoadDataObj.Buying_point_id = sHeader[0].buy_pt_id
    ReviewCreateSettlementFormLoadDataObj.sHeader = sHeader
    ReviewCreateSettlementFormLoadDataObj.mbScaleTicket = sScaleTickets
    setData(thisObj, "PremiumDeductionDataObj", ReviewCreateSettlementFormLoadDataObj);
    let newobj = {
      "cmd": "cmd=TRADE_SETTLE_COMPLETE",
      "COMP_ID": compIdFromLS,
      "CROP_YEAR": cropYearFromLS,
      "STATE_ABBR": "",
      "COUNTY_ID": "",
      "FARM_ID": "",
      "PNUT_TYPE_ID": sHeader[0].pnut_type_id,
      "PNUT_VARIETY_ID": sHeader[0].pnut_variety_id,
      "BUY_PT_ID": sHeader[0].idt_buy_pt_id,
      "INSP_NUM": sHeader[0].trade_insp_num,
      "INSP_DATE_TIME": sHeader[0].insp_date_time,
      "INSP_STATUS": sHeader[0].trade_status,
      "SEED_GEN": sHeader[0].seed_gen,
      "SEG_TYPE": sHeader[0].seg_type,
      "EDIBLE_OIL_IND": sHeader[0].edible_oil_ind,
      "OLEIC_IND": sHeader[0].oleic_ind,
      "CORN_IND": sHeader[0].corn_ind,
      "FLAVUS_IND": sHeader[0].flavus_ind,
      "VICAM_IND": sHeader[0].vicam_ind,
      "PURCH_SALE_IND": sHeader[0].purch_sale_ind,
      "VICAM_PPB": sHeader[0].vicam_ppb,
      "OBL_BUY_ID": "",
      "OBL_WHSE_ID": "",
      "OBL_BIN_ID": "",
      "UNLD_WHSE_ID": "",
      "UNLD_BIN_ID": "",
      "FM_SAMPLE": sHeader[0].fm_sample,
      "CLN_SAMPLE": sHeader[0].cln_sample,
      "SMK_GR": sHeader[0].smk_gr,
      "SS_GR": sHeader[0].ss_gr,
      "OK_GR": sHeader[0].ok_gr,
      "DAM_SPLIT_GR": sHeader[0].dam_split_gr,
      "DAM_GR": sHeader[0].dam_gr,
      "HULL_GR": sHeader[0].hull_gr,
      "FREZ_DAM_GR": sHeader[0].frez_dam_gr,
      "CNCL_RMD_GR": sHeader[0].cncl_rmd_gr,
      "CR_BR_GR": sHeader[0].cr_br_gr,
      "DCOL_SHEL_GR": sHeader[0].dcol_shel_gr,
      "HUL_BRIT_GR": sHeader[0].hul_brit_gr,
      "JUMBO_GR": sHeader[0].jumbo_gr,
      "ELK_GR": sHeader[0].elk_gr,
      "FM_GR": sHeader[0].fm_gr,
      "LSK_GR": sHeader[0].lsk_gr,
      "FAN_GR": sHeader[0].fan_gr,
      "SMK_PCT": sHeader[0].smk_pct,
      "SMK_1_PCT": sHeader[0].smk_1_pct,
      "SMK_2_PCT": sHeader[0].smk_2_pct,
      "SMK_3_PCT": sHeader[0].smk_3_pct,
      "SMK_4_PCT": sHeader[0].smk_4_pct,
      "SS_PCT": sHeader[0].ss_pct,
      "OK_PCT": sHeader[0].ok_pct,
      "DAM_PCT": sHeader[0].dam_pct,
      "TKC_PCT": sHeader[0].tkc_pct,
      "HULL_PCT": sHeader[0].hull_pct,
      "FREZ_DAM_PCT": sHeader[0].frez_dam_pct,
      "CNCL_RMD_PCT": sHeader[0].cncl_rmd_pct,
      "CR_BR_PCT": sHeader[0].cr_br_pct,
      "DCOL_SHEL_PCT": sHeader[0].dcol_shel_pct,
      "HUL_BRIT_PCT": sHeader[0].hul_brit_pct,
      "JUMBO_PCT": sHeader[0].jumbo_pct,
      "ELK_PCT": sHeader[0].elk_pct,
      "FM_PCT": sHeader[0].fm_pct,
      "LSK_PCT": sHeader[0].lsk_pct,
      "MOIST_PCT": sHeader[0].moist_pct,
      "FAN_PCT": sHeader[0].fan_pct,
      "OTH_FM": sHeader[0].oth_fm,
      "SMK_1_gauge": sHeader[0].smk_1_gauge,
      "SMK_2_gauge": sHeader[0].smk_2_gauge,
      "SMK_3_gauge": sHeader[0].smk_3_gauge,
      "SMK_4_gauge": sHeader[0].smk_4_gauge,
      "PROBE_PATTERN": sHeader[0].probe_pattern,
      "KERNEL_RELS": sHeader[0].kernel_rels,
      "KERNEL_RPS": sHeader[0].kernel_rps,
      "DAMAGED_KRS": sHeader[0].damaged_krs,
      "METER_READ": sHeader[0].meter_read,
      "BLUEPAN_WT": sHeader[0].bluepan_wt,
      "ELK_DAMAGED": sHeader[0].elk_damaged,
      "TARE_WT": sHeader[0].tare_wt,
      "VEHICLE_WT": sHeader[0].vehicle_wt,
      "GROSS_WT": sHeader[0].gross_wt,
      "FM_WT": sHeader[0].fm_wt,
      "EX_MOIST_WT": sHeader[0].ex_moist_wt,
      "NET_WT": sHeader[0].net_wt,
      "LSK_WT": sHeader[0].lsk_wt,
      "KERN_VALUE": sHeader[0].kern_value,
      "ELK_PREM": sHeader[0].elk_prem,
      "DAM_DISCOUNT": sHeader[0].dam_discount,
      "EX_FM_DISCOUNT": sHeader[0].ex_fm_discount,
      "EX_SS_DISCOUNT": sHeader[0].ex_ss_discount,
      "FLAVUS_DISCOUNT": sHeader[0].flavus_discount,
      "NET_LES_LSK_VAL": sHeader[0].net_les_lsk_val,
      "NET_PRICE": sHeader[0].net_price,
      "BASIS_GRADE_AMT": sHeader[0].basis_grade_amt,
      "SPOT_PRICE": 0,
      "SPOT_POUNDS": 0,
      "REMARKS": Searchspecial(getValue(thisObj, 'txtarSettleRemarks')),
      "SPLIT_VENDOR": 0,
      "SPLIT_REMIT": 0,
      "SPLIT_SHARE_PCT": 0,
      "WHSE_RCPT_BP": 0,
      "WHSE_RCPT_NUM": 0,
      "PURH_STOR_IND": 0,
      "VALUE_OF_SEG": lblPurchValueOfSeg,
      "VALUE_PER_TON": "",
      'SUPPORT_PCT': 0,
      "TAX_ID": 0,
      "OPTION_PAYMENT": 0,
      "SETTLEMENT_DATE": (new Date(new Date - (new Date().getTimezoneOffset() * 60000))).toISOString(),
      "IDT_COMP_ID": sHeader[0].idt_comp_id,
      "ADD_USER": sHeader[0].add_user,
      "ADD_DATE_TIME": sHeader[0].add_date_time
    }
    for (let j = 0; j < Vendordetails.length; j++) {
      let settxtvendor = "SPLIT_VENDOR" + (j + 1)
      let setremit = "SPLIT_REMIT" + (j + 1)
      let setpercendate = "SPLIT_SHARE_PCT" + (j + 1)
      let setwhsercptbp = "WHSE_RCPT_BP" + (j + 1)
      let setwhsercptnum = "WHSE_RCPT_NUM" + (j + 1)
      let setpurhstorind = "PURH_STOR_IND" + (j + 1)
      let setgrosswt = "GROSS_WT" + (j + 1)
      let setnetwt = "NET_WT" + (j + 1)
      let setlskwt = "LSK_WT" + (j + 1)
      let setbasisgrademt = "BASIS_GRADE_AMT" + (j + 1)
      let setvalueperton = "VALUE_PER_TON" + (j + 1)
      let setsupportpct = "SUPPORT_PCT" + (j + 1)
      let setvalueofseg = "VALUE_OF_SEG" + (j + 1)
      let settaxid = "TAX_ID" + (j + 1)
      let setoptionpayment = "OPTION_PAYMENT" + (j + 1)
      newobj[settxtvendor] = Vendordetails[j].split_vendor
      newobj[setremit] = Vendordetails[j].split_remit
      newobj[setpercendate] = Vendordetails[j].split_share_pct
      newobj[setwhsercptbp] = ""
      newobj[setwhsercptnum] = ""
      newobj[setpurhstorind] = "P"
      newobj[setgrosswt] = Vendordetails[j].gross_wt
      newobj[setnetwt] = Vendordetails[j].net_wt
      newobj[setlskwt] = Vendordetails[j].lsk_wt
      newobj[setbasisgrademt] = Vendordetails[j].basis_grade_amt
      newobj[setvalueperton] = Vendordetails[j].value_per_ton
      newobj[setsupportpct] = Vendordetails[j].support_pct
      newobj[settaxid] = Vendordetails[j].split_remit
      newobj[setoptionpayment] = 0
      if (lstNewContractLine1[1] == "L") {
        newobj[setvalueofseg] = (parseFloat(lblPurchValueOfSeg) * (parseFloat(Vendordetails[j].split_share_pct) * 0.01)).toFixed(2)
      } else {
        newobj[setvalueofseg] = (parseFloat(lblPurchValueOfSeg) * (parseFloat(Vendordetails[j].split_share_pct) * 0.01)).toFixed(2)
      }
    }

    let arrContractLine, nTentativeCount = 0, nApplicationCount = 0;
    for (let i = 0; i < lstNewContractLine1.length; i++) {
      arrContractLine = lstNewContractLine1[i];
      if (arrContractLine[3] == "T") {
        nTentativeCount++;
        newobj["TENT_CONTRACT_NUM" + nTentativeCount] = arrContractLine[0]
        if (arrContractLine[1] == "F") {
          newobj["TENT_PRICING_LINE" + nTentativeCount] = 1
        } else {
          newobj["TENT_PRICING_LINE" + nTentativeCount] = 2
        }
        newobj["TENT_LBS_ADJ" + nTentativeCount] = arrContractLine[2]
      } else {
        if (arrContractLine[0] !== "SPOT") {
          nApplicationCount++;
          newobj["APPL_CONTRACT_NUM" + nApplicationCount] = arrContractLine[0]
          newobj["AGREE_VALUE" + nApplicationCount] = arrContractLine[6]
          newobj["APPL_LBS_ADJ" + nApplicationCount] = arrContractLine[2]
        }
      }
    }

    newobj["TENT_APPL_COUNT"] = nTentativeCount;
    newobj["APPL_COUNT"] = nApplicationCount;

    let ddIDTfromtoCompany = getValue(thisObj, 'ddIDTfromtoCompany')
    let ddIDTfromtoLocation = getValue(thisObj, 'ddIDTfromtoLocation')
    if (ddIDTfromtoCompany !== '' && ddIDTfromtoLocation !== '') {
      SaveSettlementIdt(sHeader, Vendordetails, lstNewContractLine1, lblPurchValueOfSeg)
    }

    setData(thisObj, "lblhttps", newobj);
    goTo(thisObj, "Settlements#PremiumDeductionTrade#DEFAULT#true#Click");
  }

  const SaveSettlementIdt = (sHeader, Vendordetails, lstNewContractLine, lblPurchValueOfSeg) => {
    let lstNewContractLine1 = lstNewContractLine
    let nApplicationCount = 2,
      nTentativeCount = 0
    let newobj = {
      "cmd": "cmd=TRADE_SETTLE_COMPLETE",
      "COMP_ID": sHeader[0].comp_id,
      "CROP_YEAR": sHeader[0].crop_year,
      "STATE_ABBR": "",
      "COUNTY_ID": "",
      "FARM_ID": "",
      "PNUT_TYPE_ID": sHeader[0].pnut_type_id,
      "PNUT_VARIETY_ID": sHeader[0].pnut_variety_id,
      "BUY_PT_ID": sHeader[0].idt_buy_pt_id,
      "INSP_NUM": sHeader[0].trade_insp_num,
      "INSP_DATE_TIME": sHeader[0].insp_date_time,
      "INSP_STATUS": sHeader[0].trade_status,
      "SEED_GEN": sHeader[0].seed_gen,
      "SEG_TYPE": sHeader[0].seg_type,
      "EDIBLE_OIL_IND": sHeader[0].edible_oil_ind,
      "OLEIC_IND": sHeader[0].oleic_ind,
      "CORN_IND": sHeader[0].corn_ind,
      "FLAVUS_IND": sHeader[0].flavus_ind,
      "VICAM_IND": sHeader[0].vicam_ind,
      "PURCH_SALE_IND": sHeader[0].purch_sale_ind,
      "VICAM_PPB": sHeader[0].vicam_ppb,
      "OBL_BUY_ID": "",
      "OBL_WHSE_ID": "",
      "OBL_BIN_ID": "",
      "UNLD_WHSE_ID": "",
      "UNLD_BIN_ID": "",
      "FM_SAMPLE": sHeader[0].fm_sample,
      "CLN_SAMPLE": sHeader[0].cln_sample,
      "SMK_GR": sHeader[0].smk_gr,
      "SS_GR": sHeader[0].ss_gr,
      "OK_GR": sHeader[0].ok_gr,
      "DAM_SPLIT_GR": sHeader[0].dam_split_gr,
      "DAM_GR": sHeader[0].dam_gr,
      "HULL_GR": sHeader[0].hull_gr,
      "FREZ_DAM_GR": sHeader[0].frez_dam_gr,
      "CNCL_RMD_GR": sHeader[0].cncl_rmd_gr,
      "CR_BR_GR": sHeader[0].cr_br_gr,
      "DCOL_SHEL_GR": sHeader[0].dcol_shel_gr,
      "HUL_BRIT_GR": sHeader[0].hul_brit_gr,
      "JUMBO_GR": sHeader[0].jumbo_gr,
      "ELK_GR": sHeader[0].elk_gr,
      "FM_GR": sHeader[0].fm_gr,
      "LSK_GR": sHeader[0].lsk_gr,
      "FAN_GR": sHeader[0].fan_gr,
      "SMK_PCT": sHeader[0].smk_pct,
      "SMK_1_PCT": sHeader[0].smk_1_pct,
      "SMK_2_PCT": sHeader[0].smk_2_pct,
      "SMK_3_PCT": sHeader[0].smk_3_pct,
      "SMK_4_PCT": sHeader[0].smk_4_pct,
      "SS_PCT": sHeader[0].ss_pct,
      "OK_PCT": sHeader[0].ok_pct,
      "DAM_PCT": sHeader[0].dam_pct,
      "TKC_PCT": sHeader[0].tkc_pct,
      "HULL_PCT": sHeader[0].hull_pct,
      "FREZ_DAM_PCT": sHeader[0].frez_dam_pct,
      "CNCL_RMD_PCT": sHeader[0].cncl_rmd_pct,
      "CR_BR_PCT": sHeader[0].cr_br_pct,
      "DCOL_SHEL_PCT": sHeader[0].dcol_shel_pct,
      "HUL_BRIT_PCT": sHeader[0].hul_brit_pct,
      "JUMBO_PCT": sHeader[0].jumbo_pct,
      "ELK_PCT": sHeader[0].elk_pct,
      "FM_PCT": sHeader[0].fm_pct,
      "LSK_PCT": sHeader[0].lsk_pct,
      "MOIST_PCT": sHeader[0].moist_pct,
      "FAN_PCT": sHeader[0].fan_pct,
      "OTH_FM": sHeader[0].oth_fm,
      "SMK_1_gauge": sHeader[0].smk_1_gauge,
      "SMK_2_gauge": sHeader[0].smk_2_gauge,
      "SMK_3_gauge": sHeader[0].smk_3_gauge,
      "SMK_4_gauge": sHeader[0].smk_4_gauge,
      "PROBE_PATTERN": sHeader[0].probe_pattern,
      "KERNEL_RELS": sHeader[0].kernel_rels,
      "KERNEL_RPS": sHeader[0].kernel_rps,
      "DAMAGED_KRS": sHeader[0].damaged_krs,
      "METER_READ": sHeader[0].meter_read,
      "BLUEPAN_WT": sHeader[0].bluepan_wt,
      "ELK_DAMAGED": sHeader[0].elk_damaged,
      "TARE_WT": sHeader[0].tare_wt,
      "VEHICLE_WT": sHeader[0].vehicle_wt,
      "GROSS_WT": sHeader[0].gross_wt,
      "FM_WT": sHeader[0].fm_wt,
      "EX_MOIST_WT": sHeader[0].ex_moist_wt,
      "NET_WT": sHeader[0].net_wt,
      "LSK_WT": sHeader[0].lsk_wt,
      "KERN_VALUE": sHeader[0].kern_value,
      "ELK_PREM": sHeader[0].elk_prem,
      "DAM_DISCOUNT": sHeader[0].dam_discount,
      "EX_FM_DISCOUNT": sHeader[0].ex_fm_discount,
      "EX_SS_DISCOUNT": sHeader[0].ex_ss_discount,
      "FLAVUS_DISCOUNT": sHeader[0].flavus_discount,
      "NET_LES_LSK_VAL": sHeader[0].net_les_lsk_val,
      "NET_PRICE": sHeader[0].net_price,
      "BASIS_GRADE_AMT": sHeader[0].basis_grade_amt,
      "SPOT_PRICE": 0,
      "SPOT_POUNDS": 0,
      "REMARKS":Searchspecial(getValue(thisObj, 'txtarSettleRemarks')),
      "SETTLEMENT_DATE": (new Date(new Date - (new Date().getTimezoneOffset() * 60000))).toISOString(),
      "IDT_COMP_ID": sHeader[0].idt_comp_id,
      "ADD_USER": sHeader[0].add_user,
      "ADD_DATE_TIME": sHeader[0].add_date_time,
      "TENT_APPL_COUNT": nTentativeCount,
      "APPL_COUNT": nApplicationCount
    }
    for (let j = 0; j < Vendordetails.length; j++) {
      let settxtvendor = "SPLIT_VENDOR" + (j + 1)
      let setremit = "SPLIT_REMIT" + (j + 1)
      let setpercendate = "SPLIT_SHARE_PCT" + (j + 1)
      let setwhsercptbp = "WHSE_RCPT_BP" + (j + 1)
      let setwhsercptnum = "WHSE_RCPT_NUM" + (j + 1)
      let setpurhstorind = "PURH_STOR_IND" + (j + 1)
      let setgrosswt = "GROSS_WT" + (j + 1)
      let setnetwt = "NET_WT" + (j + 1)
      let setlskwt = "LSK_WT" + (j + 1)
      let setbasisgrademt = "BASIS_GRADE_AMT" + (j + 1)
      let setvalueperton = "VALUE_PER_TON" + (j + 1)
      let setsupportpct = "SUPPORT_PCT" + (j + 1)
      let setvalueofseg = "VALUE_OF_SEG" + (j + 1)
      let settaxid = "TAX_ID" + (j + 1)
      let setoptionpayment = "OPTION_PAYMENT" + (j + 1)
      newobj[settxtvendor] = Vendordetails[j].split_vendor
      newobj[setremit] = Vendordetails[j].split_remit
      newobj[setpercendate] = Vendordetails[j].split_share_pct
      newobj[setwhsercptbp] = ""
      newobj[setwhsercptnum] = ""
      newobj[setpurhstorind] = "P"
      newobj[setgrosswt] = Vendordetails[j].gross_wt
      newobj[setnetwt] = Vendordetails[j].net_wt
      newobj[setlskwt] = Vendordetails[j].lsk_wt
      newobj[setbasisgrademt] = Vendordetails[j].basis_grade_amt
      newobj[setvalueperton] = Vendordetails[j].value_per_ton
      newobj[setsupportpct] = Vendordetails[j].support_pct
      newobj[settaxid] = Vendordetails[j].split_remit
      newobj[setoptionpayment] = 0
      if (lstNewContractLine1[1] == "L") {
        newobj[setvalueofseg] = lblPurchValueOfSeg / 2
      } else {
        newobj[setvalueofseg] = lblPurchValueOfSeg / 2
      }
    }

    if (lstNewContractLine1[3] == "T") {
      newobj["TENT_CONTRACT_NUM1"] = lstNewContractLine1[0]
      if (lstNewContractLine1[1] == "F") {
        newobj["TENT_PRICING_LINE1"] = 1
      } else {
        newobj["TENT_PRICING_LINE1"] = 2
      }
      newobj["TENT_LBS_ADJ1"] = lstNewContractLine1[2]
    } else {
      if (lstNewContractLine1[0] !== "SPOT") {
        newobj["APPL_CONTRACT_NUM1"] = lstNewContractLine1[0]
        newobj["AGREE_VALUE1"] = lstNewContractLine1[6]
        newobj["APPL_LBS_ADJ1"] = lstNewContractLine1[2]
      }
    }
    setData(thisObj, "lblhttpsidt", newobj);
  }
  function isNumeric(value) {
    return value !== '' && !isNaN(parseFloat(value)) && isFinite(value);
  }
  const CalculateSeg_TKC = async (lstNewContractLine, netwt, lskwt, netlesslsk, mbSMKPct, RetrieveValueCalculationList, sHeader, Vendordetails, sScaleTickets) => {
    let Calculationvalue2 = await SettlementService.RetrieveValueCalculationDetails(sHeader[0].area_id, sHeader[0].pnut_type_id);
    let dblPurchTotalWeights = 0,
      dblLoanPrice = 0,
      dblPurchContractPriceTimesWt = 0,
      lblPurchValueOfSeg = 0,
      lblPurchAgreePrice = 0,
      mdblSupportPerTon = 0.00,
      dblAgreePrice = 0,
      dblVendorPurchSeg = 0,
      dblVendorStoreSeg = 0
    if (Calculationvalue2.length > 0) {
      mdblSupportPerTon = parseFloat(Calculationvalue2[0].support_per_ton)
    }
    let TKC_WT = 0,
      SEG_VALUE = 0
    let lblLoanRepayRate = getData(thisObj, 'frmTradeSettleProfileData-lblLoanRepayRate')
    if (lblLoanRepayRate !== null) {
      dblLoanPrice = parseFloat(lblLoanRepayRate)
    }
    else {
      dblLoanPrice = 0
    }

    let NET_WT = getValue(thisObj, 'txtNetWt');
    let LSK_WT = getValue(thisObj, 'txtLSK');
    let NET_LESS_LSK = getValue(thisObj, 'txtNetWtExcludingLSK');

    let arrContractLine;
    let lstNewContractLine1 = [];
    for (let i = 0; i < lstNewContractLine.length; i++) {
      arrContractLine = lstNewContractLine[i].split('|');
      if (arrContractLine[3] == "A") {
        if (arrContractLine[5] == "C") {
          if (arrContractLine[1] == "F") {
            TKC_WT = parseFloat(NET_LESS_LSK * mbSMKPct * 0.01) + parseFloat(LSK_WT);
            SEG_VALUE = TKC_WT * parseFloat(arrContractLine[4]);
            dblAgreePrice += parseFloat(arrContractLine[4]);
            lblPurchValueOfSeg += SEG_VALUE;
            arrContractLine[2] = TKC_WT;
            arrContractLine[6] = SEG_VALUE;
            let obj = [arrContractLine[0], arrContractLine[1], arrContractLine[2], arrContractLine[3], arrContractLine[4], arrContractLine[5], arrContractLine[6]];
            lstNewContractLine1.push(obj);
            //   dblPurchTotalWeights = dblPurchTotalWeights + parseFloat(arrContractLine[2])
            //   dblPurchContractPriceTimesWt = dblPurchContractPriceTimesWt + (parseFloat(arrContractLine[2]) * parseFloat(arrContractLine[4])) + dblLoanPrice
            // } else {
            //   dblPurchTotalWeights = dblPurchTotalWeights + parseFloat(arrContractLine[2])
            //   dblPurchContractPriceTimesWt = dblPurchContractPriceTimesWt + (parseFloat(arrContractLine[2]) * parseFloat(arrContractLine[4]))
          }
        }
      }
    }
    if (dblPurchTotalWeights == 0) {
      lblPurchAgreePrice = 0.00
    }
    else {
      lblPurchAgreePrice = parseFloat(dblPurchContractPriceTimesWt) / parseFloat(dblPurchTotalWeights)
    }
    let lblPurchPercentOfSupport = 0,
      valueforbasis = getValue(thisObj, 'txtTotalLoadVal') !== "" && getValue(thisObj, 'txtTotalLoadVal') !== undefined && getValue(thisObj, 'txtTotalLoadVal') !== null ? getValue(thisObj, 'txtTotalLoadVal') : 0
    let lblPurchValueOfLoad = parseFloat(valueforbasis)
    if (lblPurchValueOfLoad !== 0) {
      lblPurchPercentOfSupport = (parseFloat(lblPurchAgreePrice) / parseFloat(mdblSupportPerTon)) * 100
    }
    // if (arrContractLine[1] == "L") {
    //   lblPurchValueOfSeg = (parseFloat(dblPurchTotalWeights) * parseFloat(lblPurchAgreePrice)) / 2000

    // } else {
    //   lblPurchValueOfSeg = (parseFloat(lblPurchValueOfLoad) * (parseFloat(lblPurchPercentOfSupport) / 100))
    // }

    // let lstNewContractLine1 = [];
    // for (let j = 0; j < lstNewContractLine.length; j++) {
    //   arrContractLine = lstNewContractLine[j].split('|');
    //   let obj = [lstNewContractLine[0], lstNewContractLine[1], TKC_WT, lstNewContractLine[3], lstNewContractLine[4], lstNewContractLine[5], SEG_VALUE];
    //   lstNewContractLine1.push(obj);
    // }
    let marrVendorInfo = Vendordetails
    if (marrVendorInfo.length > 0) {
      for (let i = 0; i < marrVendorInfo.length; i++) {
        if (marrVendorInfo[i].split_share_pct !== "") {
          if (marrVendorInfo[i].split_vendor !== "") {
            marrVendorInfo[i].value_of_seg = lblPurchAgreePrice * (Number(marrVendorInfo[i].split_share_pct)) * 100
            marrVendorInfo[i].support_pct = 0
          }
        }
      }
    }
    for (let i = 0; i < marrVendorInfo.length; i++) {
      if ((marrVendorInfo[i].split_share_pct !== "" && Number(dblVendorPurchSeg).toFixed(2) !== Number(lblPurchValueOfSeg).toFixed(2))) {
        if (dblVendorPurchSeg.toFixed(2) < Number(lblPurchValueOfSeg).toFixed(2)) {
          marrVendorInfo[i].value_of_seg = Number(!isNumeric(marrVendorInfo[i].value_of_seg) ? 0 : marrVendorInfo[i].value_of_seg) + 0.01
          dblVendorPurchSeg = dblVendorPurchSeg + 0.01
        }
        if (dblVendorPurchSeg.toFixed(2) > Number(lblPurchValueOfSeg).toFixed(2)) {
          marrVendorInfo[i].value_of_seg = Number(!isNumeric(marrVendorInfo[i].value_of_seg) ? 0 : marrVendorInfo[i].value_of_seg) - 0.01
          dblVendorPurchSeg = dblVendorPurchSeg - 0.01
        }
      }
    }
    SaveSettlement(sHeader, marrVendorInfo, lstNewContractLine1, lblPurchValueOfSeg.toFixed(2), RetrieveValueCalculationList, sScaleTickets)
  }

  const CalculateSeg_Full = async (lstNewContractLine, netwt, lskwt, netlesslsk, mbSMKPct, RetrieveValueCalculationList, sHeader, Vendordetails, sScaleTickets) => {
    let Calculationvalue2 = await SettlementService.RetrieveValueCalculationDetails(sHeader[0].area_id, sHeader[0].pnut_type_id);
    let dblPurchTotalWeights = 0,
      dblLoanPrice = 0,
      dblPurchContractPriceTimesWt = 0,
      lblPurchValueOfSeg = 0,
      lblPurchAgreePrice = 0,
      dblVendorPurchSeg = 0,
      dblVendorStoreSeg = 0,
      mdblSupportPerTon = 0.00
    if (Calculationvalue2.length > 0) {
      mdblSupportPerTon = parseFloat(Calculationvalue2[0].support_per_ton)
    }
    let TKC_WT = 0,
      SEG_VALUE = 0
    let lblLoanRepayRate = getData(thisObj, 'frmTradeSettleProfileData-lblLoanRepayRate')
    if (lblLoanRepayRate !== null) {
      dblLoanPrice = parseFloat(lblLoanRepayRate)
    }
    else {
      dblLoanPrice = 0
    }

    let arrContractLine;
    for (let i = 0; i < lstNewContractLine.length; i++) {
      arrContractLine = lstNewContractLine[i].split('|');
      if (arrContractLine[3] == "A") {
        if (arrContractLine[5] == "C" || arrContractLine[5] == "S") {
          if (arrContractLine[1] == "B") {
            dblPurchTotalWeights = dblPurchTotalWeights + parseFloat(arrContractLine[2])
            dblPurchContractPriceTimesWt = dblPurchContractPriceTimesWt + (parseFloat(arrContractLine[2]) * parseFloat(arrContractLine[4])) + dblLoanPrice
          } else {
            dblPurchTotalWeights = dblPurchTotalWeights + parseFloat(arrContractLine[2])
            dblPurchContractPriceTimesWt = dblPurchContractPriceTimesWt + (parseFloat(arrContractLine[2]) * parseFloat(arrContractLine[4]))
          }
        }
      }
    }

    if (dblPurchTotalWeights == 0) {
      lblPurchAgreePrice = 0.00
    }
    else {
      lblPurchAgreePrice = parseFloat(dblPurchContractPriceTimesWt) / parseFloat(dblPurchTotalWeights)
    }

    let lblPurchPercentOfSupport = 0,
      valueforbasis = getValue(thisObj, 'txtTotalLoadVal') !== "" && getValue(thisObj, 'txtTotalLoadVal') !== undefined && getValue(thisObj, 'txtTotalLoadVal') !== null ? getValue(thisObj, 'txtTotalLoadVal') : 0
    let lblPurchValueOfLoad = parseFloat(valueforbasis)
    if (lblPurchValueOfLoad !== 0) {
      lblPurchPercentOfSupport = ((parseFloat(lblPurchAgreePrice) / parseFloat(mdblSupportPerTon)) * 100).toFixed(2)
    }
    if (arrContractLine[1] == "L") {
      lblPurchValueOfSeg = (parseFloat(dblPurchTotalWeights) * parseFloat(lblPurchAgreePrice)) / 2000

    } else {
      lblPurchValueOfSeg = (parseFloat(lblPurchValueOfLoad) * (parseFloat(lblPurchPercentOfSupport) / 100))
    }
    let marrVendorInfo = Vendordetails
    if (marrVendorInfo.length > 0) {
      for (let i = 0; i < marrVendorInfo.length; i++) {
        if (marrVendorInfo[i].split_share_pct !== "") {
          if (marrVendorInfo[i].split_vendor !== "") {
            marrVendorInfo[i].basis_grade_amt = marrVendorInfo[i].basis_grade_amt
            marrVendorInfo[i].value_per_ton = lblPurchAgreePrice
            marrVendorInfo[i].support_pct = (((Number(marrVendorInfo[i].value_per_ton)) * 100) / mdblSupportPerTon).toFixed(2)
            if (arrContractLine[1] == "L") {
              marrVendorInfo[i].value_of_seg = (((Number(marrVendorInfo[i].value_per_ton)) * (Number(marrVendorInfo[i].net_wt))) / 2000).toFixed(2)
            } else {
              marrVendorInfo[i].value_of_seg = ((Number(marrVendorInfo[i].basis_grade_amt)) * ((Number(marrVendorInfo[i].support_pct)) * 0.01)).toFixed(2)
            }
            dblVendorPurchSeg = dblVendorPurchSeg + Number(marrVendorInfo[i].value_of_seg)
          }
        }
      }
    }
    for (let i = 0; i < marrVendorInfo.length; i++) {
      if ((marrVendorInfo[i].split_share_pct !== "" && Number(dblVendorPurchSeg).toFixed(2) !== Number(lblPurchValueOfSeg).toFixed(2))) {
        if (dblVendorPurchSeg.toFixed(2) < Number(lblPurchValueOfSeg).toFixed(2)) {
          marrVendorInfo[i].value_of_seg = Number(!isNumeric(marrVendorInfo[i].value_of_seg) ? 0 : marrVendorInfo[i].value_of_seg) + 0.01
          dblVendorPurchSeg = dblVendorPurchSeg + 0.01
        }
        if (dblVendorPurchSeg.toFixed(2) > Number(lblPurchValueOfSeg).toFixed(2)) {
          marrVendorInfo[i].value_of_seg = Number(!isNumeric(marrVendorInfo[i].value_of_seg) ? 0 : marrVendorInfo[i].value_of_seg) - 0.01
          dblVendorPurchSeg = dblVendorPurchSeg - 0.01
        }
      }
    }
    let lstNewContractLine1 = [];
    for (let j = 0; j < lstNewContractLine.length; j++) {
      arrContractLine = lstNewContractLine[j].split('|');
      let arrContractLine1_6 = lblPurchValueOfSeg * (parseInt(arrContractLine[2]) / parseInt(sHeader[0].net_wt)).toFixed(2)
      let obj = [arrContractLine[0], arrContractLine[1], arrContractLine[2], arrContractLine[3], arrContractLine[4], arrContractLine[5], arrContractLine1_6];
      lstNewContractLine1.push(obj);
    }

    SaveSettlement(sHeader, marrVendorInfo, lstNewContractLine1, lblPurchValueOfSeg.toFixed(2), RetrieveValueCalculationList, sScaleTickets)
  }

  const PremDeductions = (radioGradePricingMethod, RetrieveValueCalculationList, sHeader, sVendorSplits, sScaleTickets) => {
    let preDectScreenOPEN = getData(thisObj, 'preDectScreenOPEN');
    if (preDectScreenOPEN == true) {
      // let sHeader=tradesettleheader
      // let Vendordetails=tradesettlevendorsplit
      let TradeSettleProfile_lstNewContractLine_Data = getData(thisObj, 'TradeSettleProfile_lstNewContractLine_Data')
      // let lstNewContractLine = []
      // lstNewContractLine.push(TradeSettleProfile_lstNewContractLine_Data[0].split("|"))
      //setlstNewContractLine1(lstNewContractLine)
      if (radioGradePricingMethod !== "TKC") {
        CalculateSeg_Full(TradeSettleProfile_lstNewContractLine_Data, sHeader[0].net_wt, sHeader[0].lsk_wt, sHeader[0].net_les_lsk_val, sHeader[0].smk_pct, RetrieveValueCalculationList, sHeader, sVendorSplits, sScaleTickets)
      } else {
        CalculateSeg_TKC(TradeSettleProfile_lstNewContractLine_Data, sHeader[0].net_wt, sHeader[0].lsk_wt, sHeader[0].net_les_lsk_val, sHeader[0].smk_pct, RetrieveValueCalculationList, sHeader, sVendorSplits, sScaleTickets)
      }
    }
  }

  const FillIdtBuyingPtId = async (BUY_PT_ID, Inspecnum) => {
    let status = getValue(thisObj, 'txtSts')
    let js = []

    let Temp_comp_id = ""
    let RetrieveTradeSettlegetDetails = await SettlementService.RetrieveTradeSettlementDetails(BUY_PT_ID, Inspecnum);
    if (RetrieveTradeSettlegetDetails.length > 0) {
      let sHeader = RetrieveTradeSettlegetDetails[0].trade_settle_header
      if (sHeader.length > 0) {
        Temp_comp_id = sHeader[0].idt_comp_id
      }
    }
    if (Temp_comp_id !== '' && status !== 'Pending') {
      let BuyingPointControlDetails = await ContractManagementService.RetrieveBuyingPointControlDetails();
      let data = BuyingPointControlDetails;
      js.push({ key: '', description: '' })
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let obj = {}
          obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
          js.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddIDTfromtoLocation: {
              ...state["ddIDTfromtoLocation"],
              valueList: js
            }
          }
        })
        setValue(thisObj, 'ddIDTfromtoLocation', js.at(0).key);
      }
    } else if (BUY_PT_ID !== '') {
      let BuyingPointControlDetails = await ContractManagementService.RetrieveBuyingPointControlList();
      let data = BuyingPointControlDetails;
      js.push({ key: '', description: '' })
      if (data.length > 0) {

        for (var i = 0; i < data.length; i++) {
          let buyptname = data[i].buy_pt_name !== null ? data[i].buy_pt_name.trim() : ''
          let obj = {}
          obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + buyptname }
          js.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddIDTfromtoLocation: {
              ...state["ddIDTfromtoLocation"],
              valueList: js
            }
          }
        })
        setValue(thisObj, 'ddIDTfromtoLocation', js.at(0).key);
      }
    }
  }

  const FillCompanyList = async (BUY_PT_ID, Inspecnum) => {
    let js = []
    js.push({ key: '', description: '' })
    let Temp_comp_id = "", sHeader = ''
    let RetrieveTradeSettlegetDetails = await SettlementService.RetrieveTradeSettlementDetails(BUY_PT_ID, Inspecnum);
    if (RetrieveTradeSettlegetDetails.length > 0) {
      sHeader = RetrieveTradeSettlegetDetails[0].trade_settle_header
    }
    if (sHeader.length > 0) {

      Temp_comp_id = sHeader[0].idt_comp_id
    }
    let CompanyDetails = await ContractManagementService.RetrieveCompanyDetails();
    let data = CompanyDetails;
    if (data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].compId, description: data[i].compName.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddIDTfromtoCompany: {
            ...state["ddIDTfromtoCompany"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddIDTfromtoCompany', js.at(0).key);
      FillIdtBuyingPtId()
    }
    if (Temp_comp_id !== '') {
      thisObj.setState(current => {
        return {
          ...current,
          ddIDTfromtoCompany: {
            ...state["ddIDTfromtoCompany"],
            valueList: js
          }
        }
      })
    }
  }

  const bFillWarehouseBinList = async (WhseBinNum, BUY_PT_ID, dd_PeanutType, dd_Seg, dd_Generation, dd_Oleic, dd_PeanutVariety) => {
    setValue(thisObj,'ddWhseBinNum','')
    let js = []
    let ddShipFromToLocation = '', ddPeanutType = '', ddSeg = '', ddGeneration = '', ddOleic = '', ddPeanutVariety = ''
    if (BUY_PT_ID !== null && BUY_PT_ID !== "" && BUY_PT_ID !== undefined) {
      ddShipFromToLocation = BUY_PT_ID
    } else {
      ddShipFromToLocation = getValue(thisObj, 'ddShipFromToLocation')
    }

    if (dd_PeanutType !== null && dd_PeanutType !== "" && dd_PeanutType !== undefined) {
      ddPeanutType = dd_PeanutType
    } else {
      ddPeanutType = getValue(thisObj, 'ddPeanutType')
    }

    if (dd_Seg !== null && dd_Seg !== "" && dd_Seg !== undefined) {
      ddSeg = dd_Seg
    } else {
      ddSeg = getValue(thisObj, 'ddSeg')
    }

    if (dd_Generation !== null && dd_Generation !== "" && dd_Generation !== undefined) {
      ddGeneration = dd_Generation
    } else {
      ddGeneration = getValue(thisObj, 'ddGeneration') !== undefined ? getValue(thisObj, 'ddGeneration') : ''
    }
    if (dd_Oleic !== null && dd_Oleic !== "" && dd_Oleic !== undefined) {
      ddOleic = dd_Oleic
    } else {
      ddOleic = getValue(thisObj, 'ddOleic')
    }

    if (dd_PeanutVariety !== null && dd_PeanutVariety !== "" && dd_PeanutVariety !== undefined) {
      ddPeanutVariety = dd_PeanutVariety
    } else {
      ddPeanutVariety = getValue(thisObj, 'ddPeanutVariety')
    }

    let response = await SettlementService.RetrieveWhouseBinInventoryDetails(ddShipFromToLocation, null, null, null, ddOleic)
    let data = response
    if (data.length > 0) {

      for (let i = 0; i < data.length; i++) {
        if (data[i].statusInd == 'A') {
          if (ddPeanutType == data[i].pnutTypeId) {
            if (ddSeg == data[i].segType) {
              if (ddGeneration == data[i].seedGen || data[i].seedGen == '') {
                if (ddPeanutVariety == data[i].pnutVarietyId || data[i].pnutVarietyId == '') {
                  if (ddOleic == data[i].oleicInd || data[i].oleicInd == '') {
                    let obj = {
                      key: data[i].whouseNum + '-' + data[i].binNum, binNum: data[i].binNum, description: data[i].whouseNum + ' - ' + data[i].binNum + ' - ' + data[i].whouseBinId
                    }
                    js.push(obj)

                    obj = {}
                  }
                }
              }
            }
          }
        }

      }
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddWhseBinNum: {
          ...state["ddWhseBinNum"],
          valueList: js
        }
      }
    })

  }
  const bFillIDTWarehouseBinList = async (WhseBinNum, BUY_PT_ID, dd_PeanutType, dd_Seg, dd_Generation, dd_Oleic, dd_PeanutVariety) => {
    let comid = getValue(thisObj, 'ddIDTfromtoCompany')
    let js = []
    let ddShipFromToLocation = '', ddPeanutType = '', ddSeg = '', ddGeneration = '', ddOleic = '', ddPeanutVariety = ''
    if (BUY_PT_ID !== null && BUY_PT_ID !== "" && BUY_PT_ID !== undefined) {
      ddShipFromToLocation = BUY_PT_ID
    } else {
      ddShipFromToLocation = getValue(thisObj, 'ddShipFromToLocation')
    }

    if (dd_PeanutType !== null && dd_PeanutType !== "" && dd_PeanutType !== undefined) {
      ddPeanutType = dd_PeanutType
    } else {
      ddPeanutType = getValue(thisObj, 'ddPeanutType')
    }

    if (dd_Seg !== null && dd_Seg !== "" && dd_Seg !== undefined) {
      ddSeg = dd_Seg
    } else {
      ddSeg = getValue(thisObj, 'ddSeg')
    }

    if (dd_Generation !== null && dd_Generation !== "" && dd_Generation !== undefined) {
      ddGeneration = dd_Generation
    } else {
      ddGeneration = getValue(thisObj, 'ddGeneration') !== undefined ? getValue(thisObj, 'ddGeneration') : ''
    }
    if (dd_Oleic !== null && dd_Oleic !== "" && dd_Oleic !== undefined) {
      ddOleic = dd_Oleic
    } else {
      ddOleic = getValue(thisObj, 'ddOleic')
    }

    if (dd_PeanutVariety !== null && dd_PeanutVariety !== "" && dd_PeanutVariety !== undefined) {
      ddPeanutVariety = dd_PeanutVariety
    } else {
      ddPeanutVariety = getValue(thisObj, 'ddPeanutVariety')
    }

    let response = await SettlementService.RetrieveWhouseBinInventoryDetailsForTradeSettleProfile(comid, ddShipFromToLocation, null, null, null, ddOleic)
    let data = response
    if (data.length > 0) {

      for (let i = 0; i < data.length; i++) {
        if (data[i].statusInd == 'A') {
          if (ddPeanutType == data[i].pnutTypeId) {
            if (ddSeg == data[i].segType) {
              if (ddGeneration == data[i].seedGen || data[i].seedGen == '') {
                if (ddPeanutVariety == data[i].pnutVarietyId || data[i].pnutVarietyId == '') {
                  if (ddOleic == data[i].oleicInd || data[i].oleicInd == '') {
                    let obj = {
                      key: data[i].whouseNum,
                      binNum: data[i].binNum,
                      description: data[i].whouseNum + ' - ' + data[i].binNum + ' - ' + data[i].whouseBinId
                    }
                    js.push(obj)
                    obj = {}
                  }
                }
              }
            }
          }
        }

      }
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddDTWhseBinNum: {
          ...state["ddDTWhseBinNum"],
          valueList: js
        }
      }
    })

  }
  //Method for Area control
  const fetchRetrieveAreaControlDetail = async (area_id) => {
    let areaControls = await SettlementService.RetrieveAreaControlDetails(area_id);
    if (areaControls.length > 0) {
      setDblMinKernHullPct(areaControls[0].minKernHullPct);
      setDblMaxKernHullPct(areaControls[0].maxKernHullPct);
      setlstGramEntryInd()
    }
  };
  // START_USER_CODE-USER_METHODS
  // Method for binding Buying Points
  const bFillLocationList = async (EnabledTF) => {
    let js = []
    // js.push({ key: '', description: '>>>All Buying Points<<<' });
    let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1130', null, null, null, null)
    let data = response
    for (var i = 0; i < data.length; i++) {
      let obj = {
        key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim(),
        lstAreaId: data[i].area_id, lstGramEntryInd: data[i].gram_entry_ind, lstVicamInd: data[i].vicam_lab
      }
      js.push(obj)
    }
    //setData(thisObj, 'locationData', data)
    thisObj.setState(current => {
      return {
        ...current,
        ddShipFromToLocation: {
          ...state["ddShipFromToLocation"],
          valueList: js,
          Enabled: EnabledTF
        }
      }
    })
  }

  //Method for binding Peanut Type dropdown
  const bFillTypeList = async () => {
    let js = [];
    js.push({ key: '', description: '' }) //Set All as default value
    let response = await ContractManagementService.RetrievePeanutTypeControls(null)
    let data = response
    for (var i = 0; i < data.length; i++) {
      let obj = {
        key: data[i].pnutTypeId, description: data[i].pnutTypeName,
        smk1Guage: data[i].smk1Guage,
        smk2Guage: data[i].smk2Guage,
        smk3Guage: data[i].smk3Guage,
        smk4Guage: data[i].smk4Guage
      }
      js.push(obj)
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutType: {
          ...state["ddPeanutType"],
          valueList: js,
        }
      }
    })
    // setValue(thisObj, 'ddPeanutType', js.at(0).key);
  }

  // Method for binding Segment list  
  const bFillSegmentList = () => {
    let js = []
    js.push({ key: '1', description: '1' })
    js.push({ key: '2', description: '2' })
    js.push({ key: '3', description: '3' })

    thisObj.setState(current => {
      return {
        ...current,
        ddSeg: {
          ...state["ddSeg"],
          valueList: js
        }
      }
    })
  }

  const bFillVarietyList = async (buyingPointId, pnut_type_id) => {
    let js = [];
    if (buyingPointId != "f") {
      if (pnut_type_id !== null && pnut_type_id !== '') {
        let response = await ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null)
        let data = response;
        let obj = {}
        if (data !== null && data !== undefined && data.length != 0) {
          let js1 = [];
          for (var i = 0; i < data.length; i++) {
            if (!(js1.includes(data[i].pnut_variety_id))) {
              obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name }
              if (data[i].symbol_ind == "TRADEMARK") {
                obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + 'ᵀᴹ' }
              }
              if (data[i].symbol_ind == "REGISTERED TRADEMARK") {
                obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '®' }
              }
              if (data[i].symbol_ind == "COPYRIGHT") {
                obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '©' }
              }
              js.push(obj);
              js1.push(data[i].pnut_variety_id);
              obj = {}
            }
          }
        } else {
          js.push({ key: '', description: '' })
        }
      } else {
        js.push({ key: '', description: '' })
      }
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutVariety: {
          ...state["ddPeanutVariety"],
          valueList: js
        }
      }
    });
    let ddPeanutVarietyval = thisObj.values['ddPeanutVariety'];
    let selddPeanutVariety = js.filter(elem => elem.key === ddPeanutVarietyval);
    if (selddPeanutVariety.length > 0 && selddPeanutVariety[0].pnut_variety_id == ddPeanutVarietyval) {
      setValue(thisObj, 'ddPeanutVariety', selddPeanutVariety[0].pnut_variety_id);
    } else {
      setValue(thisObj, 'ddPeanutVariety', js.at(0).key);
    }
    // if (js.length > 1) {
    //   setValue(thisObj, 'ddPeanutVariety', js.at(1).key);
    // }
    // else {
    //   setValue(thisObj, 'ddPeanutVariety', js.at(0).key);
    // }
  }


  // Method for binding Oleic values
  const bFillOleicList = () => {
    let js = []
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: '', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddOleic', js.at(2).key);
  }

  const RetrievePeanutStaticValues = async () => {
    let ppsDefault = await ContractManagementService.RetrievePeanutStaticValues();
    if (ppsDefault !== undefined && ppsDefault.length > 0) {
      let value = ppsDefault[0].pps_defaults
      let valuenew = value.bypass_curr_date_edit
      if (valuenew !== 'TRUE') {
        setPPSdefault(false)
        setnewppsdefault(false)
      } else {
        setnewppsdefault(true)
        setPPSdefault(true)
      }
    }
  }

  async function bFillCategoryList(cboCategory, purch_sale_ind) {
    try {

      let js = []
      js.push({ key: '', description: '' })
      if (cboCategory != 'f') {
        let response = await SettlementService.RetrieveTradeCategoryDetails(cboCategory, purch_sale_ind)
        if (response != null && response != undefined) {
          if (response.length != 0) {
            for (var i = 0; i < response.length; i++) {
              setGenerate1007ind(response[i].generate1007Ind)

              js.push({
                key: response[i].tradeCategory,
                description: response[i].tradeCategory
              })
            }
          }
          else {
            return; false
          }
        }
        else {
          js = [];
          setGenerate1007ind('')
          return false;
        }
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddCategory: {
            ...state["ddCategory"],
            valueList: js
          }
        }
      })
      if (js.length == 1) {
        setValue(thisObj, 'ddCategory', js.at(0).key);
      }
      return true;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in fetching location list.");
      }
      return false;
    }
  }

  // Method for binding Seed Grower List
  const bFillEdibleOilList = (EnabledTF) => {
    let js = []
    js.push({ key: 'E', description: 'Edible' })
    js.push({ key: 'O', description: 'Oil' })

    thisObj.setState(current => {
      return {
        ...current,
        ddEdibleOil: {
          ...state["ddEdibleOil"],
          Enabled: EnabledTF,
          valueList: js
        },
      }
    });
  }

  // Method for binding generation List
  const bFillGenerationList = (EnabledTF) => {
    let js = []
    js.push({ key: '', description: '' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    thisObj.setState(current => {
      return {
        ...current,
        ddGeneration: {
          ...state["ddGeneration"],
          valueList: js,
          Enabled: EnabledTF
        }
      }
    })
    setValue(thisObj, 'ddGeneration', js.at(0).key);
  }

  const onbtnPrintClick = () => {
    let agreenumer = getValue(thisObj, 'txtAgreement')
    let BUY_PT_ID = getValue(thisObj, 'ddShipFromToLocation')
    if (confirm("Changes have not been saved. Changes will not be reflected in print out. Do you wish to contine printing?") == true) {
      let js = [{
        GstrCompanyID: compIdFromLS,
        GstrCropYear: cropYearFromLS,
        lstBuyingPointID: BUY_PT_ID,
        Txt_Agree: agreenumer,
        ReportType: 'TRADE_SETTLEMENT_WORKSHEET'
      }]

      setData(thisObj, 'ReportPreviewTradedata', js)
      goTo(thisObj, "Settlements#ReportPreviewTrade#DEFAULT#true#Click");
    }
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;

  //showing vendorname txtbox
  const bCheckForIDT = async (sAction, sAgreeNum) => {
    let cboLocation = getValue(thisObj, 'ddShipFromToLocation')
    if (sAction.toUpperCase() == "AGREE_HEADER") {
      mbAgreeHeaderIdtExistFlag = false
    } else if (sAction.toUpperCase() == "SETTLE_HEADER") {
      mbSettleHeaderIdtExistFlag = false
      mbSettleHeaderIdtExistError = false
    }
    let RetrieveCheckIDTExist = await SettlementService.RetrieveCheckIdtExistList(sAction.toUpperCase(), '', sAgreeNum, '', '');
    if (RetrieveCheckIDTExist.length > 0) {
      if (sAction.toUpperCase() == "AGREE_HEADER") {
        mbAgreeHeaderIdtExistFlag = true
        setValue(thisObj, 'lblIDTAgreeNum', RetrieveCheckIDTExist[0].agreement_num)
      } else if (sAction.toUpperCase() == "SETTLE_HEADER") {
        if (RetrieveCheckIDTExist[0].agreement_num !== GstrCompanyID ||
          RetrieveCheckIDTExist[0].idt_buy_pt_id !== cboLocation) {
          mbSettleHeaderIdtExistError = true
        }
      }
    }
  }

  const CalculateValues = async (vAreaId, vPnutTypeId, vWeightIncludingVehicle, vVehicleWeight, vMoisturePct, vCrBrPct,
    vDcolShelPct, vFancyPct, vForeignMaterialPct, vLSKPct, vSMK, vSSPct, vOKPct, vELKPct, vDamagePct, vVicam, radioGradePricingMethod) => {
     if(getData(thisObj, 'LoaderIndicator') == true){
       setLoading(true)
     }
    let area_id = '', pnut_type_id = ''
    if (vAreaId !== "" && vAreaId !== undefined) {
      area_id = vAreaId
    } else {
      area_id = null
    }

    if (vPnutTypeId !== "" && vPnutTypeId !== undefined) {
      pnut_type_id = vPnutTypeId
    } else {
      pnut_type_id = null
    }
    let retrieveValueCalculationList = await SettlementService.RetrieveValueCalculationList(
      area_id, pnut_type_id, vCrBrPct, vDcolShelPct, vFancyPct, vDamagePct, vSSPct, vVicam, vForeignMaterialPct, vSMK, vSSPct, vOKPct, vELKPct,
      vLSKPct, vMoisturePct, vWeightIncludingVehicle, vVehicleWeight, vForeignMaterialPct);
    setCalculationList(retrieveValueCalculationList)
    let radioGradePricingMethod_ = ""
    if (radioGradePricingMethod != null && radioGradePricingMethod !== undefined) {
      radioGradePricingMethod_ = radioGradePricingMethod
    } else {
      radioGradePricingMethod_ = getValue(thisObj, 'radioGradePricingMethod')
    }
    if (retrieveValueCalculationList.length > 0 && radioGradePricingMethod_ == 'FULL') {
      let lbltkcvalue = parseFloat(retrieveValueCalculationList[0].total_kernel_content).toFixed(2)
      setLblTKC(lbltkcvalue)
      setValue(thisObj, 'txtWtIncludingVehicle', parseFloat(retrieveValueCalculationList[0].weight_including_vehicle).toFixed(0))
      setValue(thisObj, 'txtVehicleWt', parseFloat(retrieveValueCalculationList[0].weight_of_vehicle).toFixed(0))
      setValue(thisObj, 'txtGrossWeight', parseFloat(retrieveValueCalculationList[0].gross_weight).toFixed(0))
      setValue(thisObj, 'txtForeignMaterial', parseFloat(retrieveValueCalculationList[0].foreign_material_weight).toFixed(0))
      setValue(thisObj, 'txtWeightLessFM', parseFloat(retrieveValueCalculationList[0].weight_less_fm).toFixed(0))
      setValue(thisObj, 'txtExcessMoisture', parseFloat(retrieveValueCalculationList[0].excess_moisture_weight).toFixed(0))
      setValue(thisObj, 'txtNetWt', parseFloat(retrieveValueCalculationList[0].net_weight).toFixed(0))
      setValue(thisObj, 'txtLSK', parseFloat(retrieveValueCalculationList[0].lsk_weight).toFixed(0))
      setValue(thisObj, 'txtNetWtExcludingLSK', parseFloat(retrieveValueCalculationList[0].net_weight_excluding_lsk).toFixed(0))
      setValue(thisObj, 'txtKernelValuePerTon', parseFloat(retrieveValueCalculationList[0].kernel_value_dollars_per_ton).toFixed(2))
      setValue(thisObj, 'txtKernelValuePerPound', parseFloat(retrieveValueCalculationList[0].kernel_value_cents_per_lbs).toFixed(5))
      setValue(thisObj, 'txtELKPremiumPerTon', parseFloat(retrieveValueCalculationList[0].elk_premium_dollars_per_ton).toFixed(2))
      setValue(thisObj, 'txtELKPremiumPerPound', parseFloat(retrieveValueCalculationList[0].elk_premium_cents).toFixed(5))
      setValue(thisObj, 'txtTotalPerTon', parseFloat(retrieveValueCalculationList[0].total_dollars_per_ton).toFixed(2))
      setValue(thisObj, 'txtTotalPerPound', parseFloat(retrieveValueCalculationList[0].total_cents_per_lbs).toFixed(5))
      setValue(thisObj, 'txtDamagePerTon', parseFloat(retrieveValueCalculationList[0].damaged_discount_dollars_per_ton).toFixed(2))
      setValue(thisObj, 'txtDamagePerPound', parseFloat(retrieveValueCalculationList[0].damaged_discount_cents_per_lbs).toFixed(5))
      setValue(thisObj, 'txtAccessFMPerTon', parseFloat(retrieveValueCalculationList[0].excess_fm_discount_dollars_per_ton).toFixed(2))
      setValue(thisObj, 'txtAccessFMPerPound', parseFloat(retrieveValueCalculationList[0].excess_fm_discount_cents_per_lbs).toFixed(5))
      setValue(thisObj, 'txtExcessSplitsPerTon', parseFloat(retrieveValueCalculationList[0].excess_splits_discount_dollars_per_ton).toFixed(2))
      setValue(thisObj, 'txtExcessSplitsPerPound', parseFloat(retrieveValueCalculationList[0].excess_splits_discount_cents_per_lbs).toFixed(5))
      setValue(thisObj, 'txtAFlavusPerTon', parseFloat(retrieveValueCalculationList[0].flavus_discount_dollars_per_ton).toFixed(2))
      setValue(thisObj, 'txtAFlavusPerPound', parseFloat(retrieveValueCalculationList[0].flavus_discount_cents_per_lbs).toFixed(5))
      setValue(thisObj, 'txtTotalDiscountsPerTon', parseFloat(retrieveValueCalculationList[0].total_discounts_dollars_per_ton).toFixed(2))
      setValue(thisObj, 'txtTotalDiscountsPerPound', parseFloat(retrieveValueCalculationList[0].total_discounts_cents_per_lbs).toFixed(5))
      setValue(thisObj, 'txtNetValPerTonExclSLK', parseFloat(retrieveValueCalculationList[0].net_value_per_ton_excluding_lsk).toFixed(2))
      setValue(thisObj, 'txtValPerPoundExclSLK', parseFloat(retrieveValueCalculationList[0].value_per_pound_excluding_lsk).toFixed(5))
      setValue(thisObj, 'txtOI', parseFloat(retrieveValueCalculationList[0].value_times_weight).toFixed(2))
      // setValue(thisObj, 'txtValPerPoundInclSLK', retrieveValueCalculationList[0].lsk_per_pounds)
      setValue(thisObj, 'txtH', parseFloat(retrieveValueCalculationList[0].lsk_time_lsk_per_pounds).toFixed(2))
      setValue(thisObj, 'txtTotalLoadVal', parseFloat(retrieveValueCalculationList[0].total_value_of_load).toFixed(2))
      setValue(thisObj, 'txtPerG', parseFloat(retrieveValueCalculationList[0].value_per_pound_including_lsk).toFixed(5))
      disable(thisObj, 'btnCalculate')
    } else {
      if (retrieveValueCalculationList.length > 0 && radioGradePricingMethod_ == 'TKC') {
        let lbltkcvalue = parseFloat(retrieveValueCalculationList[0].total_kernel_content).toFixed(2)
        setLblTKC(lbltkcvalue)
        setValue(thisObj, 'txtWtIncludingVehicle', parseFloat(retrieveValueCalculationList[0].weight_including_vehicle).toFixed(0))
        setValue(thisObj, 'txtVehicleWt', parseFloat(retrieveValueCalculationList[0].weight_of_vehicle).toFixed(0))
        setValue(thisObj, 'txtGrossWeight', parseFloat(retrieveValueCalculationList[0].gross_weight).toFixed(0))
        setValue(thisObj, 'txtForeignMaterial', parseFloat(retrieveValueCalculationList[0].foreign_material_weight).toFixed(0))
        setValue(thisObj, 'txtWeightLessFM', parseFloat(retrieveValueCalculationList[0].weight_less_fm).toFixed(0))
        setValue(thisObj, 'txtExcessMoisture', parseFloat(retrieveValueCalculationList[0].excess_moisture_weight).toFixed(0))
        setValue(thisObj, 'txtNetWt', parseFloat(retrieveValueCalculationList[0].net_weight).toFixed(0))
        setValue(thisObj, 'txtLSK', parseFloat(retrieveValueCalculationList[0].lsk_weight).toFixed(0))
        setValue(thisObj, 'txtNetWtExcludingLSK', parseFloat(retrieveValueCalculationList[0].net_weight_excluding_lsk).toFixed(0))
        setValue(thisObj, 'txtKernelValuePerTon', "")
        setValue(thisObj, 'txtKernelValuePerPound', "")
        setValue(thisObj, 'txtELKPremiumPerTon', "")
        setValue(thisObj, 'txtELKPremiumPerPound', "")
        setValue(thisObj, 'txtTotalPerTon', "")
        setValue(thisObj, 'txtTotalPerPound', "")
        setValue(thisObj, 'txtDamagePerTon', "")
        setValue(thisObj, 'txtDamagePerPound', "")
        setValue(thisObj, 'txtAccessFMPerTon', "")
        setValue(thisObj, 'txtAccessFMPerPound', "")
        setValue(thisObj, 'txtExcessSplitsPerTon', "")
        setValue(thisObj, 'txtExcessSplitsPerPound', "")
        setValue(thisObj, 'txtAFlavusPerTon', "")
        setValue(thisObj, 'txtAFlavusPerPound', "")
        setValue(thisObj, 'txtTotalDiscountsPerTon', "")
        setValue(thisObj, 'txtTotalDiscountsPerPound', "")
        setValue(thisObj, 'txtNetValPerTonExclSLK', "")
        setValue(thisObj, 'txtValPerPoundExclSLK', "")
        setValue(thisObj, 'txtOI', "")
        setValue(thisObj, 'txtValPerPoundInclSLK', "")
        setValue(thisObj, 'txtH', "")
        setValue(thisObj, 'txtTotalLoadVal', "")
        setValue(thisObj, 'txtPerG', "")
        disable(thisObj, 'btnCalculate')
      }
    }
    if(getData(thisObj, 'LoaderIndicator') == true){
      setLoading(false)
    }
  }

  function FormatNumber(value, fraction = 2) {
    let formattedNo;
    if (value != null && value != undefined) {
      if (value.toString().indexOf(",") == -1) {
        formattedNo = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: fraction,
          maximumFractionDigits: fraction,
        }).format(value);
      } else {
        formattedNo = value.toString();
      }
    } else {
      formattedNo = "";
    }
    return formattedNo;
  }

  const CalculateRegradingTotals = () => {
    let txtTotalSMKRScreenPct = getValue(thisObj, 'txtTotalSMKRScreenPct');
    if (txtTotalSMKRScreenPct == undefined || txtTotalSMKRScreenPct == '' || txtTotalSMKRScreenPct == null) {
      txtTotalSMKRScreenPct = 0;
    }
    let txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct')
    if (txtSoundSplitsPct == undefined || txtSoundSplitsPct == '' || txtSoundSplitsPct == null) {
      txtSoundSplitsPct = 0;
    }
    let lblTotalSMK = FormatNumber(Number(txtTotalSMKRScreenPct) + Number(txtSoundSplitsPct));
    if (lblTotalSMK !== 'NaN' && lblTotalSMK !== '') {
      setValue(thisObj, 'txtTotalSMK', lblTotalSMK);
    } else {
      setValue(thisObj, 'txtTotalSMK', '');
    }
    let txtTotalOKPct = getValue(thisObj, 'txtTotalOKPct');
    if (txtTotalOKPct == undefined || txtTotalOKPct == '' || txtTotalOKPct == null) {
      txtTotalOKPct = 0;
    }
    let txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct');
    if (txtTotalDamagePct == undefined || txtTotalDamagePct == '' || txtTotalDamagePct == null) {
      txtTotalDamagePct = 0;
    }
    let totalvalueoftxtTotalKernels = FormatNumber(Number(lblTotalSMK) + Number(txtTotalOKPct) + Number(txtTotalDamagePct));
    if (totalvalueoftxtTotalKernels !== 'NaN' && totalvalueoftxtTotalKernels !== '') {
      setValue(thisObj, 'txtTotalKernels', totalvalueoftxtTotalKernels);
    } else {
      setValue(thisObj, 'txtTotalKernels', '');
    }
    let txtHullsPct = getValue(thisObj, 'txtHullsPct');
    if (txtHullsPct == undefined || txtHullsPct == '' || txtHullsPct == null) {
      txtHullsPct = 0;
    }
    let totalvalueoftxtTotalKernelsAndHulls = FormatNumber(Number(totalvalueoftxtTotalKernels) + Number(txtHullsPct));
    if (totalvalueoftxtTotalKernelsAndHulls !== 'NaN' && totalvalueoftxtTotalKernelsAndHulls !== '') {
      setValue(thisObj, 'txtTotalKernelsAndHulls', totalvalueoftxtTotalKernelsAndHulls);
    } else {
      setValue(thisObj, 'txtTotalKernelsAndHulls', '');
    }
  }
 
  const bGradesValid = async (data, statevalue, vendorsplit, saction, Value) => {
    let bformvaild = false
    let chkboxReGrading = getValue(thisObj, 'chkboxReGrading')
    let chkCornFound = chkboxReGrading.includes('1')
    let chkFlavusFound = chkboxReGrading.includes('2')
    let radioGradePricingMethod_ = thisObj.values.radioGradePricingMethod
    if (data[0].corn_ind !== '') {
      if ((data[0].corn_ind !== 'N' && chkCornFound == false) || (chkCornFound == true && data[0].corn_ind !== 'Y')) {
        showMessage(thisObj, 'Corn found does not match peanut specifications!!!')
      }
    }
    else if (data[0].flavus_ind !== '' && ((data[0].flavus_ind !== 'N' && chkFlavusFound == false) || (chkFlavusFound == true && data[0].flavus_ind !== 'Y'))) {
      showMessage(thisObj, 'Flavus found does not match peanut specifications!!!')
    }

    //*****MINIMUM EDITS*****'
    else if (parseInt(data[0].min_vicam_ppb) !== 0) {
      if (parseFloat(statevalue.txtVicamReading) < parseFloat(data[0].min_vicam_ppb)) {
        showMessage(thisObj, 'Vicam does not match peanut specifications!!!')
      }
    } else if ((parseInt(data[0].min_smk_pct) !== 0 && parseFloat(statevalue.txtTotalSMKRScreenPct) < parseFloat(data[0].min_smk_pct))) {
      showMessage(thisObj, 'SMK does not match peanut specifications!!!')
    } else if ((parseInt(data[0].min_hull_pct) !== 0 && parseFloat(statevalue.txtTotalSMKRScreenPct) < parseFloat(data[0].min_hull_pct))) {
      showMessage(thisObj, 'Hulls does not match peanut specifications!!!')
    } else if ((parseInt(data[0].min_fm_pct) !== 0 && parseFloat(statevalue.txtForeignMaterialPct) < parseFloat(data[0].min_fm_pct))) {
      showMessage(thisObj, 'Foreign Material does not match peanut specifications!!!')
    } else if ((parseInt(data[0].min_lsk_pct) !== 0 && parseFloat(statevalue.txtTotalSLKPct) < parseFloat(data[0].min_lsk_pct))) {
      showMessage(thisObj, 'LSK does not match peanut specifications!!!')
    } else if ((parseInt(data[0].min_moist_pct) !== 0 && parseFloat(statevalue.txtMoisture) < parseFloat(data[0].min_moist_pct))) {
      showMessage(thisObj, 'Moisture does not match peanut specifications!!!')
    }
    // '*****MAXIMUM EDITS*****'
    else if ((parseInt(data[0].max_vicam_ppb) !== 0 && parseFloat(statevalue.txtVicamReading) > parseFloat(data[0].max_vicam_ppb))) {
      showMessage(thisObj, 'Vicam does not match peanut specifications!!!')
    } else if ((parseInt(data[0].max_smk_pct) !== 0 && parseFloat(statevalue.txtTotalSMKRScreenPct) > parseFloat(data[0].max_smk_pct))) {
      showMessage(thisObj, 'SMK does not match peanut specifications!!!')
    } else if ((parseInt(data[0].max_hull_pct) !== 0 && parseFloat(statevalue.txtHullsPct) > parseFloat(data[0].max_hull_pct))) {
      showMessage(thisObj, 'Hulls does not match peanut specifications!!!')
    } else if ((parseInt(data[0].max_fm_pct) !== 0 && parseFloat(statevalue.txtForeignMaterialPct) > parseFloat(data[0].max_fm_pct))) {
      showMessage(thisObj, 'Foreign Material does not match peanut specifications!!!')
    } else if ((parseInt(data[0].max_lsk_pct) !== 0 && parseFloat(statevalue.txtTotalSLKPct) > parseFloat(data[0].max_lsk_pct))) {
      showMessage(thisObj, 'LSK does not match peanut specifications!!!')
    } else if ((parseInt(data[0].max_moist_pct) !== 0 && parseFloat(statevalue.txtMoisture) > parseFloat(data[0].max_moist_pct))) {
      showMessage(thisObj, 'Moisture does not match peanut specifications!!!')

    }
    // Skip these edits for TKC
    else if (radioGradePricingMethod_ !== 'TKC') {
      //*****MINIMUM EDITS*****'
      if ((parseInt(data[0].min_ss_pct) !== 0 && parseFloat(statevalue.txtSoundSplitsPct) < parseFloat(data[0].min_ss_pct))) {
        showMessage(thisObj, 'Sound Splits does not match peanut specifications!!!')
      } else if ((parseInt(data[0].min_ok_pct) !== 0 && parseFloat(statevalue.txtTotalOKPct) < parseFloat(data[0].min_ok_pct))) {
        showMessage(thisObj, 'Other Kernals does not match peanut specifications!!!')
      } else if ((parseInt(data[0].min_dam_pct) !== 0 && parseFloat(statevalue.txtTotalDamagePct) < parseFloat(data[0].min_dam_pct))) {
        showMessage(thisObj, 'Damage does not match peanut specifications!!!')
      } else if ((parseInt(data[0].min_frez_dam_pct) !== 0 && parseFloat(statevalue.txtFreezeDamagePct) < parseFloat(data[0].min_frez_dam_pct))) {
        showMessage(thisObj, 'Freeze Damage does not match peanut specifications!!!')
      } else if ((parseInt(data[0].min_cncl_rmd_pct) !== 0 && parseFloat(statevalue.txtConcealedRMDPct) < parseFloat(data[0].min_cncl_rmd_pct))) {
        showMessage(thisObj, 'Concealed RMD does not match peanut specifications!!!')
      } else if ((parseInt(data[0].min_cr_br_pct) !== 0 && parseFloat(statevalue.txtCrackedBrokenPct) < parseFloat(data[0].min_cr_br_pct))) {
        showMessage(thisObj, 'Crk or Brk Shells does not match peanut specifications!!!')
      } else if ((parseInt(data[0].min_dcol_shel_pct) !== 0 && parseFloat(statevalue.txtDiscoloredShellsPct) < parseFloat(data[0].min_dcol_shel_pct))) {
        showMessage(thisObj, 'Discolored Shells does not match peanut specifications!!!')
      } else if ((parseInt(data[0].min_hul_brit_pct) !== 0 && parseFloat(statevalue.txtHullBrightnessPct) < parseFloat(data[0].min_hul_brit_pct))) {
        showMessage(thisObj, 'Hull Brightness does not match peanut specifications!!!')
      } else if ((parseInt(data[0].min_jumbo_pct) !== 0 && parseFloat(statevalue.txtJumboPct) < parseFloat(data[0].min_jumbo_pct))) {
        showMessage(thisObj, 'Jumbo does not match peanut specifications!!!')
      } else if ((parseInt(data[0].min_elk_pct) !== 0 && parseFloat(statevalue.txtNetELKPct) < parseFloat(data[0].min_elk_pct))) {
        showMessage(thisObj, 'ELK does not match peanut specifications!!!')
      } else if ((parseInt(data[0].min_fm_pct) !== 0 && parseFloat(statevalue.txtFancyPct) < parseFloat(data[0].min_fm_pct))) {
        showMessage(thisObj, 'Fancy does not match peanut specifications!!!')
      } else if ((parseInt(data[0].min_fm_pct) !== 0 && parseFloat(statevalue.txtFancyPct) < parseFloat(data[0].min_fm_pct))) {
        showMessage(thisObj, 'Fancy does not match peanut specifications!!!')

      }
      // '*****MAXIMUM EDITS*****'
      else if ((parseInt(data[0].max_ss_pct) !== 0 && parseFloat(statevalue.txtSoundSplitsPct) > parseFloat(data[0].max_ss_pct))) {
        showMessage(thisObj, 'Sound Splits does not match peanut specifications!!!')
      } else if ((parseInt(data[0].max_ok_pct) !== 0 && parseFloat(statevalue.txtTotalOKPct) > parseFloat(data[0].max_ok_pct))) {
        showMessage(thisObj, 'Other Kernals does not match peanut specifications!!!')
      } else if ((parseInt(data[0].max_dam_pct) !== 0 && parseFloat(statevalue.txtTotalDamagePct) > parseFloat(data[0].max_dam_pct))) {
        showMessage(thisObj, 'Damage does not match peanut specifications!!!')
      } else if ((parseInt(data[0].max_frez_dam_pct) !== 0 && parseFloat(statevalue.txtFreezeDamagePct) > parseFloat(data[0].max_frez_dam_pct))) {
        showMessage(thisObj, 'Freeze Damage does not match peanut specifications!!!')
      } else if ((parseInt(data[0].max_cncl_rmd_pct) !== 0 && parseFloat(statevalue.txtConcealedRMDPct) > parseFloat(data[0].max_cncl_rmd_pct))) {
        showMessage(thisObj, 'Concealed RMD does not match peanut specifications!!!')
      } else if ((parseInt(data[0].max_cr_br_pct) !== 0 && parseFloat(statevalue.txtCrackedBrokenPct) > parseFloat(data[0].max_cr_br_pct))) {
        showMessage(thisObj, 'Crk or Brk Shells does not match peanut specifications!!!')
      } else if ((parseInt(data[0].max_dcol_shel_pct) !== 0 && parseFloat(statevalue.txtDiscoloredShellsPct) > parseFloat(data[0].max_dcol_shel_pct))) {
        showMessage(thisObj, 'Discolored Shells does not match peanut specifications!!!')
      } else if ((parseInt(data[0].max_hul_brit_pct) !== 0 && parseFloat(statevalue.txtHullBrightnessPct) > parseFloat(data[0].max_hul_brit_pct))) {
        showMessage(thisObj, 'Hull Brightness does not match peanut specifications!!!')
      } else if ((parseInt(data[0].max_jumbo_pct) !== 0 && parseFloat(statevalue.txtJumboPct) > parseFloat(data[0].max_jumbo_pct))) {
        showMessage(thisObj, 'Jumbo does not match peanut specifications!!!')
      } else if ((parseInt(data[0].max_elk_pct) !== 0 && parseFloat(statevalue.txtNetELKPct) > parseFloat(data[0].max_elk_pct))) {
        showMessage(thisObj, 'ELK does not match peanut specifications!!!')
      } else if ((parseInt(data[0].max_fan_pct !== 0) && parseFloat(statevalue.txtFancyPct) > parseFloat(data[0].max_fan_pct))) {
        showMessage(thisObj, 'Fancy does not match peanut specifications!!!')
      } else {
        bformvaild = true
      }
    } else {
      bformvaild = true
    }
    // Skip these edits for TKC
    if (bformvaild == true) {
      bSaveInspection(statevalue, vendorsplit, saction, Value)
    }
  }

  const bVerifyPeanutSpecs = async (area_id, pnut_type_id, seed_ind, seg_type, edible_oil_ind, ddGeneration, statevalue, vendorsplit, data, Value,pnut_variety_id,buy_pt_id) => {
    let RetrievePeanutSpecificationExceptionDetails = await SettlementService.RetrievePeanutSpecificationExceptionDetails(buy_pt_id, pnut_type_id,pnut_variety_id, seed_ind, seg_type, edible_oil_ind);
    if(RetrievePeanutSpecificationExceptionDetails.length>0){
      bGradesValid(RetrievePeanutSpecificationExceptionDetails, statevalue, vendorsplit, data, Value)
    }else{
      let RetrievePeanutSpecificationExceptionDetails = await SettlementService.RetrievePeanutSpecificationExceptionDetails(buy_pt_id, pnut_type_id,"", seed_ind, seg_type, edible_oil_ind);
      if(RetrievePeanutSpecificationExceptionDetails.length>0){
        bGradesValid(RetrievePeanutSpecificationExceptionDetails, statevalue, vendorsplit, data, Value)
      }else{
        let RetrieveAreaControlDetails = await SettlementService.RetrievePeanutSpecificationDetails(area_id, pnut_type_id, seed_ind, seg_type, edible_oil_ind);
        if (RetrieveAreaControlDetails.length > 0) {
          bGradesValid(RetrieveAreaControlDetails, statevalue, vendorsplit, data, Value)
          // setValue(thisObj, 'lblmaxmoisture', RetrieveAreaControlDetails[0].max_moist_pct)
        }else{
          if (seed_ind == 'Y') {
            showMessage(thisObj, 'No seed specification record exists matching supplied information. Please add record to the peanut specifiction control table!!!')
            setValue(thisObj, 'txtSeedMeetingSpec', 'Yes')
          } else {
            showMessage(thisObj, 'No peanut specification record exists matching supplied information. Please add record to the peanut specifiction control table!!!')
            setValue(thisObj, 'txtSeedMeetingSpec', '')
          }
        }
      }
    }
  }

  const bFormValid = async (statevalue, Value) => {
    let AddUpdateDetails = getData(thisObj, 'CreateValue');
    var rv = {};
    let txvendorch = true, txsharepercheck = true, txshareper00check = true, txshareper100check = true,
      lblGrandTotal1 = 0.00,
      txtSharePercen111 = 0.00

    for (let j = 0; j < 20; j++) {
      let settxtvendor = "txtVendor" + (j + 1)
      let setremit = "ddRemitTo" + (j + 1)
      let setpercendate = "txtSharePercentage" + (j + 1)
      let setgrosswt = "gross_wt" + (j + 1)
      let setnetwt = "net_wt" + (j + 1)
      let setlswt = "lsk_wt" + (j + 1)
      let setbasisgradeamt = "basis_grade_amt" + (j + 1)
      let gettxvendor = '', getremit = '', getpercentage = '', getgrosswt = '', getnetwt = '', getlskwt = ''
      gettxvendor = getValue(thisObj, settxtvendor)
      getremit = getValue(thisObj, setremit)
      getpercentage = getValue(thisObj, setpercendate)
      getgrosswt = statevalue.txtGrossWeight
      getnetwt = statevalue.txtNetWt
      getlskwt = statevalue.txtLSK

      let neT_PRICE = getValue(thisObj, 'txtPerG');
      if (neT_PRICE == undefined || neT_PRICE == '' || neT_PRICE == null || neT_PRICE == "0.00") {
        neT_PRICE = 0;
      } else {
        neT_PRICE = neT_PRICE / 100;
      }
      let netWeight = Math.floor(getnetwt * Number(getpercentage) * 0.01);
      rv[settxtvendor] = gettxvendor.toString() !== undefined ? gettxvendor.toString() : ''
      rv[setremit] = getremit !== undefined ? getremit : ''
      rv[setpercendate] = getpercentage !== undefined ? getpercentage : ''
      rv[setgrosswt] = getpercentage !== undefined ? (parseInt(getgrosswt) * (parseFloat(getpercentage) * 0.01)) : ''
      rv[setnetwt] = getpercentage !== undefined ? (parseInt(getnetwt) * (parseFloat(getpercentage) * 0.01)) : ''
      rv[setlswt] = getpercentage !== undefined ? (parseInt(getlskwt) * (parseFloat(getpercentage) * 0.01)) : ''
      rv[setbasisgradeamt] = getpercentage !== undefined ? Number(netWeight * neT_PRICE).toFixed(2) : ''
      let shjjjj = rv[setpercendate] !== "" ? parseFloat(rv[setpercendate]) : 0.00
      txtSharePercen111 = shjjjj + txtSharePercen111
      lblGrandTotal1 = txtSharePercen111
      if (rv[settxtvendor].length > 0) {
        txvendorch = false
      }
      if (rv[settxtvendor] > 0 && rv[setpercendate] == "") {
        txsharepercheck = false
      }
      if (rv[setpercendate] == "0.00") {
        txshareper00check = false
      }
      if (rv[setpercendate] > 100) {
        txshareper100check = false
      }
    }
    let RetrieveTradeSettlegetDetails = await SettlementService.RetrieveTradeSettlementDetails(statevalue.ddShipFromToLocation, statevalue.txtTradeInspectSC95, null, 'HEADER');

    let txtTradeInspectgetvalue = ''
    if (RetrieveTradeSettlegetDetails.length > 0) {
      let sHeader = RetrieveTradeSettlegetDetails[0].trade_settle_header
      if (sHeader.length > 0) {
        txtTradeInspectgetvalue = sHeader[0].trade_insp_num
      }

    }

    let nonseed = "N"
    let cboIDTBuyingPoint = getValue(thisObj, 'ddIDTfromtoLocation')
    let radioTradeSaleTradePurchase_ = statevalue.radioTradeSaleTradePurchase
    let cboIDTWhseBinNum = getValue(thisObj, 'ddDTWhseBinNum')
    let cboCategory = getValue(thisObj, 'ddCategory')
    let chkboxReGrading = getValue(thisObj, 'chkboxReGrading')
    let vicamselected = chkboxReGrading.includes('3')
    let gridScaleTickets = getValue(thisObj, "gridScaleTickets")
    let date = "06/01/" + (cropYearFromLS);
    let setdatetime = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString()
    var dateTime = moment(String(setdatetime)).format('MM/DD/YYYY hh:mm A');
    var setdtInspectDateTime = moment(statevalue.dtInspectDateTime).format('MM/DD/YYYY hh:mm A');
    var setdtInspectDate = moment(statevalue.dtInspectDateTime).format('MM/DD/YYYY');
    let ddShipFromToLocationlist = thisObj.state['ddShipFromToLocation'].valueList;
    let ddShipFromToLocationval = thisObj.values['ddShipFromToLocation'];
    let sellstGramEntryIndElem = ddShipFromToLocationlist.filter(elem => elem.key === ddShipFromToLocationval);

    let txtTradeInspectSC95 = getValue(thisObj, "txtTradeInspectSC95")
    let strRegex = new RegExp(/^[A-Z]\d*$/)
    let istoltalsmk=false
    if ((statevalue.txtSMK1Pct !== "" && statevalue.txtSMK1Pct !== undefined) ||
    (statevalue.txtSMK2Pct !== "" && statevalue.txtSMK2Pct !== undefined) ||
    (statevalue.txtSMK3Pct !== "" && statevalue.txtSMK3Pct !== undefined) ||
    (statevalue.txtSMK4Pct !== "" && statevalue.txtSMK4Pct !== undefined)) {
      istoltalsmk =true
    } 
    let istoltallsk=false
    if ((statevalue.txtLSKRidingPct !== "" && statevalue.txtLSKRidingPct !== undefined) ||
      (statevalue.txtLSKFallThru !== "" && statevalue.txtLSKFallThru !== undefined) ||
      (statevalue.txtLSKDamageRiding !== "" && statevalue.txtLSKDamageRiding !== undefined)) {
        istoltallsk=true
      } 
    if (cboIDTWhseBinNum == '' && cboCategory == "IDT") {
      showMessage(thisObj, "You must select a Warehouse Bin.");
    }
    else if (statevalue.ddIDTfromtoCompany !== "" && cboIDTBuyingPoint !== '') {
      bCheckForIDT("settle_header", "")
      if (mbSettleHeaderIdtExistError !== false) {
        showMessage(thisObj, "Inspection number already exists for IDT Company.  You must enter a different inspection number.")
      }
    }
    else if (statevalue.txtTradeInspectSC95 == "") {
      showMessage(thisObj, "Trade Inspection number is required!!!");
    } else if (statevalue.txtTradeInspectSC95.length !== 7) {
      showMessage(thisObj, "Trade Inspection number must be 7 characters long!!!");
    } else if (!strRegex.test((txtTradeInspectSC95).toUpperCase())) {
      showMessage(thisObj, "Trade Inspection number must be of Format A######!!!")
    }
    else if (statevalue.dtInspectDateTime == '') {
      showMessage(thisObj, "Invalid Inspection Date!!!")
    } else if ((new Date(dateTime) < new Date(setdtInspectDateTime)) && newppsdefault !== true && (process.env.REACT_APP_ENVIR == 'DEV' || process.env.REACT_APP_ENVIR == 'TEST' || process.env.REACT_APP_ENVIR == 'LOCAL' || process.env.REACT_APP_ENVIR == 'UAT')) {
      showMessage(thisObj, "Inspection Date cannot be in future!!!")
    } else if (new Date(setdtInspectDate) < new Date(date)) {
      showMessage(thisObj, "Inspection Date cannot be less than June 1 of current crop year!!!")
    }
    else if (radioTradeSaleTradePurchase_ == '') {
      showMessage(thisObj, "Either Trade Sale or Trade Purchase must be selected!!!");
    } else if (statevalue.ddCategory == "") {
      showMessage(thisObj, "A Trade Category must be selected!!!");

    } else if (generate1007ind == "Y") {
      showMessage(thisObj, `1007 Handler Number and Name are required for selected trade category of ${statevalue.ddCategory}`)
    } else if (statevalue.ddPeanutType == "") {
      showMessage(thisObj, "Please Select a Peanut Type!!!");

    }
    else if (statevalue.ddPeanutVariety == "") {
      showMessage(thisObj, "Please Select a Peanut Variety!!!");
    }
    else if (statevalue.ddSeg == "") {
      showMessage(thisObj, "Please Select a Segment!!!");
    } else if (statevalue.ddWhseBinNum == '') {
      showMessage(thisObj, "Please Select a Warehouse Bin Number!!!");
    } else if (vicamselected == true && statevalue.txtVicamReading == '' && parseFloat(statevalue.txtVicamReading) > 9999 && parseFloat(statevalue.txtVicamReading) < 0) {
      showMessage(thisObj, "Vicam must be of format ######!!!");
    } else if (statevalue.txtTradeInspectSC95 == txtTradeInspectgetvalue && AddUpdateDetails[0].LoadTypeBtnCaption == 'Add') {
      showMessage(thisObj, `Trade Inspection number " ${txtTradeInspectgetvalue} has already been created!!!`);
    } else if (txvendorch == true) {
      showMessage(thisObj, 'At least one valid vendor is required!!!');
    } else if (txsharepercheck == false) {
      showMessage(thisObj, 'Vendor share must be of format ###.##!!!');
    } else if (txshareper00check == false) {
      showMessage(thisObj, 'Vendor share must be greater than 0!!!');
    }
    else if (txshareper100check == false) {
      showMessage(thisObj, 'Vendor share must be less than 100!!!');
    }
    else if (lblGrandTotal1 !== 100) {
      showMessage(thisObj, `Total of vendor shares ${lblGrandTotal1} ` + 'does not equal 100!!!')
    } else if (gridScaleTickets.length == 0) {
      showMessage(thisObj, "Must have at least 1 scale ticket!!!");
    }
    else if (statevalue.txtFMSampleWt == '0' && parseFloat(statevalue.txtFMSampleWt) > 9999.9 && parseFloat(statevalue.txtFMSampleWt) < 0) {
      showMessage(thisObj, "FM Sample Weight must be of format ####.#!!!");
    }
    else if (statevalue.txtCleanSampleWt == '0.0' && parseFloat(statevalue.txtCleanSampleWt) > 9999.9 && parseFloat(statevalue.txtCleanSampleWt) < 0) {
      showMessage(thisObj, "Cln Sample Weight must be of format ####.#!!!");
    }
    else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtTotalSMKRScreen == '' && parseFloat(statevalue.txtTotalSMKRScreen) > 9999.9 && parseFloat(statevalue.txtTotalSMKRScreen) < 0) {
      showMessage(thisObj, "SMK(grams) must be of format ####.#!!!");
    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtSoundSplits == '' && parseFloat(statevalue.txtSoundSplits) > 9999.9 && parseFloat(statevalue.txtSoundSplits) < 0) {
      showMessage(thisObj, "Sound Splits(grams) must be of format ####.#!!!");
    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtTotalOK == '' && parseFloat(statevalue.txtTotalOK) > 9999.9 && parseFloat(statevalue.txtTotalOK) < 0) {
      showMessage(thisObj, "Other Kernals(grams) must be of format ####.#!!!", false);
    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtTotalDamage == '' && parseFloat(statevalue.txtTotalDamage) > 9999.9 && parseFloat(statevalue.txtTotalDamage) < 0) {
      showMessage(thisObj, "Damage(grams) must be of format ####.#!!!", false);

    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtHulls == '' && parseFloat(statevalue.txtHulls) > 9999.9 && parseFloat(statevalue.txtHulls) < 0) {
      showMessage(thisObj, "Hulls(grams) must be of format ####.#!!!", false);

    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtFreezeDamage == '' && parseFloat(statevalue.txtFreezeDamage) > 9999.9 && parseFloat(statevalue.txtFreezeDamage) < 0) {
      showMessage(thisObj, "Freeze Damage(grams) must be of format ####.#!!!", false);

    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtConcealedRMD == '' && parseFloat(statevalue.txtConcealedRMD) > 9999.9 && parseFloat(statevalue.txtConcealedRMD) < 0) {
      showMessage(thisObj, "Concealed RMD(grams) must be of format ####.#!!!", false);
    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtPittingDamage == '' && parseFloat(statevalue.txtPittingDamage) > 9999.9 && parseFloat(statevalue.txtPittingDamage) < 0) {
      showMessage(thisObj, "Pitting Damage(grams) must be of format ####.#!!!", false);
    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtPittingDamagegr == '' && parseFloat(statevalue.txtPittingDamagegr) > 9999.9 && parseFloat(statevalue.txtPittingDamagegr) < 0) {
      showMessage(thisObj, "Pitting Damage(grams) must be of format ####.#!!!", false);
    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtCrackedBroken == '' && parseFloat(statevalue.txtCrackedBroken) > 9999.9 && parseFloat(statevalue.txtCrackedBroken) < 0) {
      showMessage(thisObj, "Crk or Brk Shells(grams) must be of format ####.#!!!", false);
    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtDiscoloredShells == '' && parseFloat(statevalue.txtDiscoloredShells) > 9999.9 && parseFloat(statevalue.txtDiscoloredShells) < 0) {
      showMessage(thisObj, "Discolored Shells(grams) must be of format ####.#!!!", false);
    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtHullBrightness == '' && parseFloat(statevalue.txtHullBrightness) > 9999.9 && parseFloat(statevalue.txtHullBrightness) < 0) {
      showMessage(thisObj, "Hull Brightness(grams) must be of format ####.#!!!", false);
    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtJumbo == '' && parseFloat(statevalue.txtJumbo) > 9999.9 && parseFloat(statevalue.txtJumbo) < 0) {
      showMessage(thisObj, "Jumbo(grams) must be of format ####.#!!!", false);
    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtTotalELKDamage == '' && parseFloat(statevalue.txtTotalELKDamage) > 9999.9 && parseFloat(statevalue.txtTotalELKDamage) < 0) {
      showMessage(thisObj, "Total ELK Damage(grams) must be of format ####.#!!!", false);
    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtNetELK == '' && parseFloat(statevalue.txtNetELK) > 9999.9 && parseFloat(statevalue.txtNetELK) < 0) {
      showMessage(thisObj, "ELK(grams) must be of format ####.#!!!", false);
    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtForeignMtrl == '' && parseFloat(statevalue.txtForeignMtrl) > 9999.9 && parseFloat(statevalue.txtForeignMaterial) < 0) {
      showMessage(thisObj, "Foreign Material(grams) must be of format ####.#!!!", false);
    } else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtTotalSLK == '' && parseFloat(statevalue.txtTotalSLK) > 9999.9 && parseFloat(statevalue.txtTotalSLK) < 0) {
      showMessage(thisObj, "LSK(grams) must be of format ####.#!!", false);
    }
    else if (sellstGramEntryIndElem[0].lstGramEntryInd == "Y" && statevalue.txtFancy == '' && parseFloat(statevalue.txtFancy) > 9999.9 && parseFloat(statevalue.txtFancy) < 0) {
      showMessage(thisObj, "Fancy(grams) must be of format ####.#!!!", false);
    }
    else if (statevalue.txtDamageSplits == '0' && parseFloat(statevalue.txtDamageSplits) > 9999.9 && parseFloat(statevalue.txtDamageSplits) < 0) {
      showMessage(thisObj, "Damage Splits must be of format ####.#!!!", false);
    } else if (statevalue.txtTotalSMKRScreenPct == '0' && parseFloat(statevalue.txtTotalSMKRScreenPct) > 9999.9 && parseFloat(statevalue.txtTotalSMKRScreenPct) < 0) {
      showMessage(thisObj, "SMKRS(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtSMK1Pct == '' && parseFloat(statevalue.txtSMK1Pct) > 100 && parseFloat(statevalue.txtSMK1Pct) < 0) {
      showMessage(thisObj, "SMK1(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtSMK2Pct == '' && parseFloat(statevalue.txtSMK2Pct) > 100 && parseFloat(statevalue.txtSMK2Pct) < 0) {
      showMessage(thisObj, "SMK2(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtSMK3Pct == '' && parseFloat(statevalue.txtSMK3Pct) > 100 && parseFloat(statevalue.txtSMK3Pct) < 0) {
      showMessage(thisObj, "SMK3(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtSMK4Pct == '' && parseFloat(statevalue.txtSMK4Pct) > 100 && parseFloat(statevalue.txtSMK4Pct) < 0) {
      showMessage(thisObj, "SMK4(percent) must be of format ###.##!!!", false);
    } else if (istoltalsmk == true && (Number(statevalue.txtSMK1Pct) + Number(statevalue.txtSMK2Pct) + Number(statevalue.txtSMK3Pct) + Number(statevalue.txtSMK4Pct)) !== Number(statevalue.txtTotalSMKRScreenPct)) {
        showMessage(thisObj, "SMK1 through SMK4 must total SMKRS!!!", false); 
    }
     else if (statevalue.txtSoundSplitsPct == '0' && parseFloat(statevalue.txtSoundSplitsPct) > 100 && parseFloat(statevalue.txtSoundSplitsPct) < 0) {
      showMessage(thisObj, "Sound Splits(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtTotalDamagePct == '0.00' && parseFloat(statevalue.txtTotalDamagePct) > 100 && parseFloat(statevalue.txtTotalDamagePct) < 0) {
      showMessage(thisObj, "Other Kernals(Percent) must be between 0 and 100.", false);
    } else if (statevalue.txtOKFallThru == '' && parseFloat(statevalue.txtOKFallThru) > 100 && parseFloat(statevalue.txtOKFallThru) < 0) {
      showMessage(thisObj, "Other Kernals Fall Through(Percent) must be between 0 and 100.", false);
    } else if (statevalue.txtOKDamageRiding == '' && parseFloat(statevalue.txtOKDamageRiding) > 100 && parseFloat(statevalue.txtOKDamageRiding) < 0) {
      showMessage(thisObj, "Other Kernals Damage(Percent) must be between 0 and 100.", false);
    } else if (statevalue.txtOKRidingPct == '' && parseFloat(statevalue.txtOKRidingPct) > 100 && parseFloat(statevalue.txtOKRidingPct) < 0) {
      showMessage(thisObj, "OK Riding Screen data must equal total OK", false);
    } else if (statevalue.txtTotalDamagePct == '0.00' && parseFloat(statevalue.txtTotalDamagePct) > 100 && parseFloat(statevalue.txtTotalDamagePct) < 0) {
      showMessage(thisObj, "Total Damage(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtHullsPct == '0.00' && parseFloat(statevalue.txtHullsPct) > 100 && parseFloat(statevalue.txtHullsPct) < 0) {
      showMessage(thisObj, "Hulls(percent) must be of format ###.##!!!", false);
    } else if (isEnabled(thisObj, 'txtHulls') && Number(statevalue.txtCleanSampleWt) !== 0) {
        if (Number(Number(statevalue.txtHulls) / Number(statevalue.txtCleanSampleWt)).toFixed() > Number(statevalue.txtHullsPct) ||
          (Number(Number(statevalue.txtHulls) / Number(statevalue.txtClnSampleWt)).toFixed() < Number(statevalue.txtHullsPct))) {
          showMessage(thisObj, "Hulls(percent) must be of format within 1 percent of value calculated from grams!!!", false);
          return;
        }
      
    }
    else if (statevalue.txtFreezeDamagePct == '0.00' && parseFloat(statevalue.txtFreezeDamagePct) > 100 && parseFloat(statevalue.txtFreezeDamagePct) < 0) {
      showMessage(thisObj, "Freeze Damage(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtFreezeDamage == '' && parseFloat(statevalue.txtFreezeDamage) > 100 && parseFloat(statevalue.txtFreezeDamage) < 0) {
      showMessage(thisObj, "Freeze(percent) must be of format within .01 percent of value calculated from grams!!!", false);
    } else if (statevalue.txtConcealedRMDPct == '' && parseFloat(statevalue.txtConcealedRMDPct) > 100 && parseFloat(statevalue.txtConcealedRMDPct) < 0) {
      showMessage(thisObj, "Concealed RMD(percent) must be of format ###.##!!!", false);
    } else if (isEnabled(thisObj, 'txtConcealedRMD')) {
      if (Number(statevalue.txtCleanSampleWt) !== 0) {
        if (Number(Number(statevalue.txtConcealedRMD) / Number(statevalue.txtCleanSampleWt)).toFixed() > Number(statevalue.txtConcealedRMD) ||
          (Number(Number(statevalue.txtConcealedRMD) / Number(statevalue.txtClnSampleWt)).toFixed() < Number(statevalue.txtConcealedRMD))) {
          showMessage(thisObj, "Concealed(percent) must be of format within .01 percent of value calculated from grams!!!", false);

        }
      }
    } else if (statevalue.txtCrackedBrokenPct == '' && parseFloat(statevalue.txtConcealedRMDPct) > 100 && parseFloat(statevalue.txtConcealedRMDPct) < 0) {
      showMessage(thisObj, "Crk or Brk Shells(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtDiscoloredShellsPct == '' && parseFloat(statevalue.txtDiscoloredShellsPct) > 100 && parseFloat(statevalue.txtDiscoloredShellsPct) < 0) {
      showMessage(thisObj, "Discolored Shells(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtHullBrightnessPct == '' && parseFloat(statevalue.txtHullBrightnessPct) > 100 && parseFloat(statevalue.txtHullBrightnessPct) < 0) {
      showMessage(thisObj, "Hull Brightness(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtJumboPct == '' && parseFloat(statevalue.txtJumboPct) > 100 && parseFloat(statevalue.txtJumboPct) < 0) {
      showMessage(thisObj, "Jumbo(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtNetELKPct == '' && parseFloat(statevalue.txtNetELKPct) > 100 && parseFloat(statevalue.txtNetELKPct) < 0) {
      showMessage(thisObj, "ELK(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtForeignMaterialPct == '0.00' && parseFloat(statevalue.txtForeignMaterialPct) > 100 && parseFloat(statevalue.txtForeignMaterialPct) < 0) {
      showMessage(thisObj, "Foreign Material(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtTotalSLKPct == '0.00' && parseFloat(statevalue.txtTotalSLKPct) > 100 && parseFloat(statevalue.txtTotalSLKPct) < 0) {
      showMessage(thisObj, "LSK(Percent) must be between 0 and 100.", false);
    } else if (statevalue.txtLSKRidingPct == '' && parseFloat(statevalue.txtLSKRidingPct) > 100 && parseFloat(statevalue.txtLSKRidingPct) < 0) {
      showMessage(thisObj, "LSK Riding(Percent) must be between 0 and 100.", false);
    } else if (statevalue.txtLSKFallThru == '' && parseFloat(statevalue.txtLSKFallThru) > 100 && parseFloat(statevalue.txtLSKFallThru) < 0) {
      showMessage(thisObj, "LSK Fall Through(Percent) must be between 0 and 100.", false);
    } else if (statevalue.txtLSKDamageRiding == '' && parseFloat(statevalue.txtLSKDamageRiding) > 100 && parseFloat(statevalue.txtLSKDamageRiding) < 0) {
      showMessage(thisObj, "LSK Damage(Percent) must be between 0 and 100.", false);
    } else if (istoltallsk==true &&(Number(statevalue.txtLSKRidingPct) + Number(statevalue.txtLSKFallThru) + Number(statevalue.txtLSKDamageRiding)) !== Number(statevalue.txtTotalSLKPct)) {
        showMessage(thisObj, "LSK Riding Screen data must equal total LSK!!!", false);
        return
    } 
    else if (statevalue.txtFancyPct == '' && parseFloat(statevalue.txtFancyPct) > 100 && parseFloat(statevalue.txtFancyPct) < 0) {
      showMessage(thisObj, "Fancy(percent) must be of format ###.##!!!", false);
    } else if (statevalue.txtMoisture == '0' && parseFloat(statevalue.txtMoisture) > 100 && parseFloat(statevalue.txtMoisture) < 0) {
      showMessage(thisObj, "Moisture must be of format ###.##!!!", false);
    } else if (statevalue.txtKernelsREL == '0.0' && parseFloat(statevalue.txtKernelsREL) > 9999.9 && parseFloat(statevalue.txtKernelsREL) < 0) {
      showMessage(thisObj, "Kernels REL must be in format ####.#!!!", false);
    } else if (statevalue.txtKernelsRPS == '0.0' && parseFloat(statevalue.txtKernelsRPS) > 9999.9 && parseFloat(statevalue.txtKernelsRPS) < 0) {
      showMessage(thisObj, "Kernels RPS must be in format ####.#!!!", false);
    } else if (statevalue.txtDamagedKRS == '' && parseFloat(statevalue.txtDamagedKRS) > 9999.9 && parseFloat(statevalue.txtDamagedKRS) < 0) {
      showMessage(thisObj, "Damaged KRS must be in format ####.#!!!", false);
    } else if (statevalue.txtMeterReading == '0.0' && parseFloat(statevalue.txtMeterReading) > 9999.9 && parseFloat(statevalue.txtMeterReading) < 0) {
      showMessage(thisObj, "Meter Reading must be in format ####.#!!!", false);
    } else if (statevalue.txtBluePanWT == '0.0' && parseFloat(statevalue.txtBluePanWT) > 9999.9 && parseFloat(statevalue.txtBluePanWT) < 0) {
      showMessage(thisObj, "Blue Pan WT must be in format ####.#!!!", false);
    } else if (statevalue.txtELKDamaged == '0.0' && parseFloat(statevalue.txtELKDamaged) > 9999.9 && parseFloat(statevalue.txtELKDamaged) < 0) {
      showMessage(thisObj, "ELK Damaged must be in format ####.#!!!", false);
    }
    else if (parseFloat(dblMinKernHullPct) !== 0 && parseFloat(statevalue.txtTotalKernelsAndHulls) < parseFloat(dblMinKernHullPct)) {

      showMessage(thisObj, "Total Kernals and Hulls outside acceptable range!!!");
    }
    else if (parseFloat(dblMaxKernHullPct) !== 0 && parseFloat(statevalue.txtTotalKernelsAndHulls) > parseFloat(dblMaxKernHullPct)) {
      showMessage(thisObj, "Total Kernals and Hulls outside acceptable range!!!"); 
    }
    else if (nonseed == "N") {
      bVerifyPeanutSpecs(sellstGramEntryIndElem[0].lstAreaId, statevalue.ddPeanutType, 'N', statevalue.ddSeg, statevalue.ddEdibleOil, statevalue.ddGeneration, statevalue, [rv], AddUpdateDetails[0].LoadTypeBtnCaption, Value,statevalue.ddPeanutVariety,statevalue.ddShipFromToLocation)
      if (statevalue.ddGeneration !== '' && statevalue.ddSeg == '1') {
        bVerifyPeanutSpecs(sellstGramEntryIndElem[0].lstAreaId, statevalue.ddPeanutType, 'Y', statevalue.ddSeg, statevalue.ddEdibleOil, statevalue.ddGeneration, statevalue, [rv], AddUpdateDetails[0].LoadTypeBtnCaption, Value,statevalue.ddPeanutVariety,statevalue.ddShipFromToLocation)
        setValue(thisObj, 'txtSeedMeetingSpec', 'Yes')
      } else {
        setValue(thisObj, 'txtSeedMeetingSpec', '')
      }
    }
    else {
      bSaveInspection(statevalue, [rv], AddUpdateDetails[0].LoadTypeBtnCaption, Value)
    }

  }

  const onbtnSaveClick = (Value) => {
    try {
      let obj = {}
      obj.chkboxPrintSeg = getValue(thisObj, 'chkboxPrintSeg') !== undefined ? getValue(thisObj, 'chkboxPrintSeg') : ''
      obj.chkboxReGrading = getValue(thisObj, 'chkboxReGrading') !== "" && getValue(thisObj, 'chkboxReGrading') !== undefined ? getValue(thisObj, 'chkboxReGrading') : ''
      obj.ddCategory = getValue(thisObj, 'ddCategory') !== "" && getValue(thisObj, 'ddCategory') !== undefined ? getValue(thisObj, 'ddCategory') : ''
      obj.ddEdibleOil = getValue(thisObj, 'ddEdibleOil') !== "" && getValue(thisObj, 'ddEdibleOil') !== undefined ? getValue(thisObj, 'ddEdibleOil') : ''
      obj.ddGeneration = getValue(thisObj, 'ddGeneration') !== "" && getValue(thisObj, 'ddGeneration') !== undefined ? getValue(thisObj, 'ddGeneration') : ''
      obj.ddIDTfromtoCompany = getValue(thisObj, 'ddIDTfromtoCompany') !== "" && getValue(thisObj, 'ddIDTfromtoCompany') !== undefined ? getValue(thisObj, 'ddIDTfromtoCompany') : ''
      obj.ddIDTfromtoLocation = getValue(thisObj, 'ddIDTfromtoLocation') !== "" && getValue(thisObj, 'ddIDTfromtoLocation') !== undefined ? getValue(thisObj, 'ddIDTfromtoLocation') : ''
      obj.ddOleic = getValue(thisObj, 'ddOleic') !== "" && getValue(thisObj, 'ddOleic') !== undefined ? getValue(thisObj, 'ddOleic') : ''
      obj.ddPeanutType = getValue(thisObj, 'ddPeanutType') !== "" && getValue(thisObj, 'ddPeanutType') !== undefined ? getValue(thisObj, 'ddPeanutType') : ''
      obj.ddPeanutVariety = getValue(thisObj, 'ddPeanutVariety') !== "" && getValue(thisObj, 'ddPeanutVariety') !== undefined ? getValue(thisObj, 'ddPeanutVariety') : ''
      obj.ddRemitTo1 = getValue(thisObj, 'ddRemitTo1') !== "" && getValue(thisObj, 'ddRemitTo1') !== undefined ? getValue(thisObj, 'ddRemitTo1') : ''
      obj.ddRemitTo2 = getValue(thisObj, 'ddRemitTo2') !== "" && getValue(thisObj, 'ddRemitTo2') !== undefined ? getValue(thisObj, 'ddRemitTo2') : ''
      obj.ddRemitTo3 = getValue(thisObj, 'ddRemitTo3') !== "" && getValue(thisObj, 'ddRemitTo3') !== undefined ? getValue(thisObj, 'ddRemitTo3') : ''
      obj.ddRemitTo4 = getValue(thisObj, 'ddRemitTo4') !== "" && getValue(thisObj, 'ddRemitTo4') !== undefined ? getValue(thisObj, 'ddRemitTo4') : ''
      obj.ddRemitTo5 = getValue(thisObj, 'ddRemitTo5') !== "" && getValue(thisObj, 'ddRemitTo5') !== undefined ? getValue(thisObj, 'ddRemitTo5') : ''
      obj.ddRemitTo6 = getValue(thisObj, 'ddRemitTo6') !== "" && getValue(thisObj, 'ddRemitTo6') !== undefined ? getValue(thisObj, 'ddRemitTo6') : ''
      obj.ddRemitTo7 = getValue(thisObj, 'ddRemitTo7') !== "" && getValue(thisObj, 'ddRemitTo7') !== undefined ? getValue(thisObj, 'ddRemitTo7') : ''
      obj.ddRemitTo8 = getValue(thisObj, 'ddRemitTo8') !== "" && getValue(thisObj, 'ddRemitTo8') !== undefined ? getValue(thisObj, 'ddRemitTo8') : ''
      obj.ddRemitTo9 = getValue(thisObj, 'ddRemitTo9') !== "" && getValue(thisObj, 'ddRemitTo9') !== undefined ? getValue(thisObj, 'ddRemitTo9') : ''
      obj.ddRemitTo10 = getValue(thisObj, 'ddRemitTo10') !== "" && getValue(thisObj, 'ddRemitTo10') !== undefined ? getValue(thisObj, 'ddRemitTo10') : ''
      obj.ddRemitTo11 = getValue(thisObj, 'ddRemitTo11') !== "" && getValue(thisObj, 'ddRemitTo11') !== undefined ? getValue(thisObj, 'ddRemitTo11') : ''
      obj.ddRemitTo12 = getValue(thisObj, 'ddRemitTo12') !== "" && getValue(thisObj, 'ddRemitTo12') !== undefined ? getValue(thisObj, 'ddRemitTo12') : ''
      obj.ddRemitTo13 = getValue(thisObj, 'ddRemitTo13') !== "" && getValue(thisObj, 'ddRemitTo13') !== undefined ? getValue(thisObj, 'ddRemitTo13') : ''
      obj.ddRemitTo14 = getValue(thisObj, 'ddRemitTo14') !== "" && getValue(thisObj, 'ddRemitTo14') !== undefined ? getValue(thisObj, 'ddRemitTo14') : ''
      obj.ddRemitTo15 = getValue(thisObj, 'ddRemitTo15') !== "" && getValue(thisObj, 'ddRemitTo15') !== undefined ? getValue(thisObj, 'ddRemitTo15') : ''
      obj.ddRemitTo16 = getValue(thisObj, 'ddRemitTo16') !== "" && getValue(thisObj, 'ddRemitTo16') !== undefined ? getValue(thisObj, 'ddRemitTo16') : ''
      obj.ddRemitTo17 = getValue(thisObj, 'ddRemitTo17') !== "" && getValue(thisObj, 'ddRemitTo17') !== undefined ? getValue(thisObj, 'ddRemitTo17') : ''
      obj.ddRemitTo18 = getValue(thisObj, 'ddRemitTo18') !== "" && getValue(thisObj, 'ddRemitTo18') !== undefined ? getValue(thisObj, 'ddRemitTo18') : ''
      obj.ddRemitTo19 = getValue(thisObj, 'ddRemitTo19') !== "" && getValue(thisObj, 'ddRemitTo19') !== undefined ? getValue(thisObj, 'ddRemitTo19') : ''
      obj.ddRemitTo20 = getValue(thisObj, 'ddRemitTo20') !== "" && getValue(thisObj, 'ddRemitTo20') !== undefined ? getValue(thisObj, 'ddRemitTo20') : ''
      obj.ddSeg = getValue(thisObj, 'ddSeg') !== "" && getValue(thisObj, 'ddSeg') !== undefined ? getValue(thisObj, 'ddSeg') : ''
      obj.ddShipFromToLocation = getValue(thisObj, 'ddShipFromToLocation') !== "" && getValue(thisObj, 'ddShipFromToLocation') !== undefined ? getValue(thisObj, 'ddShipFromToLocation') : ''
      obj.ddWhseBinNum = getValue(thisObj, 'ddWhseBinNum') !== "" && getValue(thisObj, 'ddWhseBinNum') !== undefined ? getValue(thisObj, 'ddWhseBinNum') : ''
      obj.dtInspectDateTime = getValue(thisObj, 'dtInspectDateTime') !== "" && getValue(thisObj, 'dtInspectDateTime') !== undefined ?moment(getValue(thisObj, 'dtInspectDateTime')).format('MM/DD/YYYY hh:mm A'): ''
      obj.dtSettleDateTime = getValue(thisObj, 'dtSettleDateTime') !== "" && getValue(thisObj, 'dtSettleDateTime') !== undefined ? getValue(thisObj, 'dtSettleDateTime') : ""
      obj.dtStartDateTime = getValue(thisObj, 'dtStartDateTime') !== "" && getValue(thisObj, 'dtStartDateTime') !== undefined ? getValue(thisObj, 'dtStartDateTime') : ""
      obj.lblAddedBy = getValue(thisObj, 'lblAddedBy') !== "" && getValue(thisObj, 'lblAddedBy') !== undefined ? getValue(thisObj, 'lblAddedBy') : ''
      obj.lblAddedByValue = getValue(thisObj, 'lblAddedByValue') !== "" && getValue(thisObj, 'lblAddedByValue') !== undefined ? getValue(thisObj, 'lblAddedByValue') : ''
      obj.lblAgreeNum = getValue(thisObj, 'lblAgreeNum') !== "" && getValue(thisObj, 'lblAgreeNum') !== undefined ? getValue(thisObj, 'lblAgreeNum') : ''
      obj.lblIDTAgreeNum = getValue(thisObj, 'lblIDTAgreeNum') !== "" && getValue(thisObj, 'lblIDTAgreeNum') !== undefined ? getValue(thisObj, 'lblIDTAgreeNum') : ''
      obj.lblChangedBy = getValue(thisObj, 'lblChangedBy') !== "" && getValue(thisObj, 'lblChangedBy') !== undefined ? getValue(thisObj, 'lblChangedBy') : ''
      obj.lblChangedByValue = getValue(thisObj, 'lblChangedByValue') !== "" && getValue(thisObj, 'lblChangedByValue') !== undefined ? getValue(thisObj, 'lblChangedByValue') : ''
      obj.lblCropYear2 = getValue(thisObj, 'lblCropYear2') !== "" && getValue(thisObj, 'lblCropYear2') !== undefined ? getValue(thisObj, 'lblCropYear2') : ''
      obj.lblInterDeptTransfer = getValue(thisObj, 'lblInterDeptTransfer') !== "" && getValue(thisObj, 'lblInterDeptTransfer') !== undefined ? getValue(thisObj, 'lblInterDeptTransfer') : ''
      obj.txtKernRidingELKPct = getValue(thisObj, 'txtKernRidingELKPct') !== "" && getValue(thisObj, 'txtKernRidingELKPct') !== undefined ? getValue(thisObj, 'txtKernRidingELKPct') : ''
      obj.lblPeanutInformation = getValue(thisObj, 'lblPeanutInformation') !== "" && getValue(thisObj, 'lblPeanutInformation') !== undefined ? getValue(thisObj, 'lblPeanutInformation') : ''
      obj.lblPerLb = getValue(thisObj, 'lblPerLb') !== "" && getValue(thisObj, 'lblPerLb') !== undefined ? getValue(thisObj, 'lblPerLb') : ''
      obj.lblPerLb2 = getValue(thisObj, 'lblPerLb2') !== "" && getValue(thisObj, 'lblPerLb2') !== undefined ? getValue(thisObj, 'lblPerLb2') : ''
      obj.lblPerLb3 = getValue(thisObj, 'lblPerLb3') !== "" && getValue(thisObj, 'lblPerLb3') !== undefined ? getValue(thisObj, 'lblPerLb3') : ''
      obj.lblPerTon = getValue(thisObj, 'lblPerTon') !== "" && getValue(thisObj, 'lblPerTon') !== undefined ? getValue(thisObj, 'lblPerTon') : ''
      obj.lblPrintSeg = getValue(thisObj, 'lblPrintSeg') !== "" && getValue(thisObj, 'lblPrintSeg') !== undefined ? getValue(thisObj, 'lblPrintSeg') : ''
      obj.lblReGrading = getValue(thisObj, 'lblReGrading') !== "" && getValue(thisObj, 'lblReGrading') !== undefined ? getValue(thisObj, 'lblReGrading') : ''
      obj.lblRemitTo = getValue(thisObj, 'lblRemitTo') !== "" && getValue(thisObj, 'lblRemitTo') !== undefined ? getValue(thisObj, 'lblRemitTo') : ''
      obj.lblScaleTickets = getValue(thisObj, 'lblScaleTickets') !== "" && getValue(thisObj, 'lblScaleTickets') !== undefined ? getValue(thisObj, 'lblScaleTickets') : ''
      obj.lblSettlementInformation = getValue(thisObj, 'lblSettlementInformation') !== "" && getValue(thisObj, 'lblSettlementInformation') !== undefined ? getValue(thisObj, 'lblSettlementInformation') : ''
      obj.lblSharePercentage = getValue(thisObj, 'lblSharePercentage') !== "" && getValue(thisObj, 'lblSharePercentage') !== undefined ? getValue(thisObj, 'lblSharePercentage') : ''
      obj.lblShippedFrom = getValue(thisObj, 'lblShippedFrom') !== "" && getValue(thisObj, 'lblShippedFrom') !== undefined ? getValue(thisObj, 'lblShippedFrom') : ''
      obj.lblShippedTo = getValue(thisObj, 'lblShippedTo') !== "" && getValue(thisObj, 'lblShippedTo') !== undefined ? getValue(thisObj, 'lblShippedTo') : ''
      obj.lblValueCalculation = getValue(thisObj, 'lblValueCalculation') !== "" && getValue(thisObj, 'lblValueCalculation') !== undefined ? getValue(thisObj, 'lblValueCalculation') : ''
      obj.lblVendor = getValue(thisObj, 'lblVendor') !== "" && getValue(thisObj, 'lblVendor') !== undefined ? getValue(thisObj, 'lblVendor') : ''
      obj.lblVendorSplits = getValue(thisObj, 'lblVendorSplits') !== "" && getValue(thisObj, 'lblVendorSplits') !== undefined ? getValue(thisObj, 'lblVendorSplits') : ''
      obj.lblXX1 = getValue(thisObj, 'lblXX1') !== "" && getValue(thisObj, 'lblXX1') !== undefined ? getValue(thisObj, 'lblXX1') : ''
      obj.lblXX2 = getValue(thisObj, 'lblXX2') !== "" && getValue(thisObj, 'lblXX2') !== undefined ? getValue(thisObj, 'lblXX2') : ''
      obj.lblXX3 = getValue(thisObj, 'lblXX3') !== "" && getValue(thisObj, 'lblXX3') !== undefined ? getValue(thisObj, 'lblXX3') : ''
      obj.lblXX4 = getValue(thisObj, 'lblXX4') !== "" && getValue(thisObj, 'lblXX4') !== undefined ? getValue(thisObj, 'lblXX4') : ''
      obj.lblXX5 = getValue(thisObj, 'lblXX5') !== "" && getValue(thisObj, 'lblXX5') !== undefined ? getValue(thisObj, 'lblXX5') : ''
      obj.lblXX6 = getValue(thisObj, 'lblXX6') !== "" && getValue(thisObj, 'lblXX6') !== undefined ? getValue(thisObj, 'lblXX6') : ''
      obj.lblXX7 = getValue(thisObj, 'lblXX7') !== "" && getValue(thisObj, 'lblXX7') !== undefined ? getValue(thisObj, 'lblXX7') : ''
      obj.lblXX8 = getValue(thisObj, 'lblXX8') !== "" && getValue(thisObj, 'lblXX8') !== undefined ? getValue(thisObj, 'lblXX8') : ''
      obj.lblXX9 = getValue(thisObj, 'lblXX9') !== "" && getValue(thisObj, 'lblXX9') !== undefined ? getValue(thisObj, 'lblXX9') : ''
      obj.lblXX10 = getValue(thisObj, 'lblXX10') !== "" && getValue(thisObj, 'lblXX10') !== undefined ? getValue(thisObj, 'lblXX10') : ''
      obj.lblXX11 = getValue(thisObj, 'lblXX11') !== "" && getValue(thisObj, 'lblXX11') !== undefined ? getValue(thisObj, 'lblXX11') : ''
      obj.lblXX12 = getValue(thisObj, 'lblXX12') !== "" && getValue(thisObj, 'lblXX12') !== undefined ? getValue(thisObj, 'lblXX12') : ''
      obj.lblXX13 = getValue(thisObj, 'lblXX13') !== "" && getValue(thisObj, 'lblXX13') !== undefined ? getValue(thisObj, 'lblXX13') : ''
      obj.lblXX14 = getValue(thisObj, 'lblXX14') !== "" && getValue(thisObj, 'lblXX14') !== undefined ? getValue(thisObj, 'lblXX14') : ''
      obj.lblXX15 = getValue(thisObj, 'lblXX15') !== "" && getValue(thisObj, 'lblXX15') !== undefined ? getValue(thisObj, 'lblXX15') : ''
      obj.lblXX16 = getValue(thisObj, 'lblXX16') !== "" && getValue(thisObj, 'lblXX16') !== undefined ? getValue(thisObj, 'lblXX16') : ''
      obj.lblXX17 = getValue(thisObj, 'lblXX17') !== "" && getValue(thisObj, 'lblXX17') !== undefined ? getValue(thisObj, 'lblXX17') : ''
      obj.lblXX18 = getValue(thisObj, 'lblXX18') !== "" && getValue(thisObj, 'lblXX18') !== undefined ? getValue(thisObj, 'lblXX18') : ''
      obj.lblXX19 = getValue(thisObj, 'lblXX19') !== "" && getValue(thisObj, 'lblXX19') !== undefined ? getValue(thisObj, 'lblXX19') : ''
      obj.lblXX20 = getValue(thisObj, 'lblXX20') !== "" && getValue(thisObj, 'lblXX20') !== undefined ? getValue(thisObj, 'lblXX20') : ''
      obj.radioGradePricingMethod = getValue(thisObj, 'radioGradePricingMethod') !== "" && getValue(thisObj, 'radioGradePricingMethod') !== undefined ? getValue(thisObj, 'radioGradePricingMethod') : ''
      obj.radioGramsPercentage = getValue(thisObj, 'radioGramsPercentage') !== "" && getValue(thisObj, 'radioGramsPercentage') !== undefined ? getValue(thisObj, 'radioGramsPercentage') : ''
      obj.radioTradeSaleTradePurchase = getValue(thisObj, 'radioTradeSaleTradePurchase') !== "" && getValue(thisObj, 'radioTradeSaleTradePurchase') !== undefined ? getValue(thisObj, 'radioTradeSaleTradePurchase') : ''
      obj.txt1007Handler = getValue(thisObj, 'txt1007Handler') !== "" && getValue(thisObj, 'txt1007Handler') !== undefined ? getValue(thisObj, 'txt1007Handler') : ''
      obj.txt1007HandlerName = getValue(thisObj, 'txt1007HandlerName') !== "" && getValue(thisObj, 'txt1007HandlerName') !== undefined ? getValue(thisObj, 'txt1007HandlerName') : ''
      obj.txtAccessFMPerPound = getValue(thisObj, 'txtAccessFMPerPound') !== "" && getValue(thisObj, 'txtAccessFMPerPound') !== undefined ? getValue(thisObj, 'txtAccessFMPerPound') : ''
      obj.txtAccessFMPerTon = getValue(thisObj, 'txtAccessFMPerTon') !== "" && getValue(thisObj, 'txtAccessFMPerTon') !== undefined ? getValue(thisObj, 'txtAccessFMPerTon') : ''
      obj.txtAFlavusPerPound = getValue(thisObj, 'txtAFlavusPerPound') !== "" && getValue(thisObj, 'txtAFlavusPerPound') !== undefined ? getValue(thisObj, 'txtAFlavusPerPound') : ''
      obj.txtAFlavusPerTon = getValue(thisObj, 'txtAFlavusPerTon') !== "" && getValue(thisObj, 'txtAFlavusPerTon') !== undefined ? getValue(thisObj, 'txtAFlavusPerTon') : ''
      obj.txtAgreementPrice = getValue(thisObj, 'txtAgreementPrice') !== "" && getValue(thisObj, 'txtAgreementPrice') !== undefined ? getValue(thisObj, 'txtAgreementPrice') : ''
      obj.txtarInspectionRemarks = getValue(thisObj, 'txtarInspectionRemarks') !== "" && getValue(thisObj, 'txtarInspectionRemarks') !== undefined ? getValue(thisObj, 'txtarInspectionRemarks') : ''
      obj.txtarSettleRemarks = getValue(thisObj, 'txtarSettleRemarks') !== "" && getValue(thisObj, 'txtarSettleRemarks') !== undefined ? getValue(thisObj, 'txtarSettleRemarks') : ''
      obj.txtBluePanWT = getValue(thisObj, 'txtBluePanWT') !== "" && getValue(thisObj, 'txtBluePanWT') !== undefined ? getValue(thisObj, 'txtBluePanWT') : '0.0'
      obj.txtCleanSampleWt = getValue(thisObj, 'txtCleanSampleWt') !== "" && getValue(thisObj, 'txtCleanSampleWt') !== undefined ? getValue(thisObj, 'txtCleanSampleWt') : '0.0'
      obj.txtcolInventoryWt = getValue(thisObj, 'txtcolInventoryWt') !== "" && getValue(thisObj, 'txtcolInventoryWt') !== undefined ? getValue(thisObj, 'txtcolInventoryWt') : ''
      obj.txtcolLoad = getValue(thisObj, 'txtcolLoad') !== "" && getValue(thisObj, 'txtcolLoad') !== undefined ? getValue(thisObj, 'txtcolLoad') : ''
      obj.txtcolRecvDate = getValue(thisObj, 'txtcolRecvDate') !== "" && getValue(thisObj, 'txtcolRecvDate') !== undefined ? getValue(thisObj, 'txtcolRecvDate') : ''
      obj.txtcolRecvTicket = getValue(thisObj, 'txtcolRecvTicket') !== "" && getValue(thisObj, 'txtcolRecvTicket') !== undefined ? getValue(thisObj, 'txtcolRecvTicket') : ''
      obj.txtcolRecvWt = getValue(thisObj, 'txtcolRecvWt') !== "" && getValue(thisObj, 'txtcolRecvWt') !== undefined ? getValue(thisObj, 'txtcolRecvWt') : ''
      obj.txtcolShipDate = getValue(thisObj, 'txtcolShipDate') !== "" && getValue(thisObj, 'txtcolShipDate') !== undefined ? getValue(thisObj, 'txtcolShipDate') : ''
      obj.txtcolShipTicket = getValue(thisObj, 'txtcolShipTicket') !== "" && getValue(thisObj, 'txtcolShipTicket') !== undefined ? getValue(thisObj, 'txtcolShipTicket') : ''
      obj.txtcolShipWt = getValue(thisObj, 'txtcolShipWt') !== "" && getValue(thisObj, 'txtcolShipWt') !== undefined ? getValue(thisObj, 'txtcolShipWt') : ''
      obj.txtcolVehicle = getValue(thisObj, 'txtcolVehicle') !== "" && getValue(thisObj, 'txtcolVehicle') !== undefined ? getValue(thisObj, 'txtcolVehicle') : ''
      obj.txtConcealedRMD = getValue(thisObj, 'txtConcealedRMD') !== "" && getValue(thisObj, 'txtConcealedRMD') !== undefined ? getValue(thisObj, 'txtConcealedRMD') : ''
      obj.txtConcealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct') !== "" && getValue(thisObj, 'txtConcealedRMDPct') !== undefined ? getValue(thisObj, 'txtConcealedRMDPct') : '0.00'
      obj.txtCrackedBroken = getValue(thisObj, 'txtCrackedBroken') !== "" && getValue(thisObj, 'txtCrackedBroken') !== undefined ? getValue(thisObj, 'txtCrackedBroken') : ''
      obj.txtCrackedBrokenPct = getValue(thisObj, 'txtCrackedBrokenPct') !== "" && getValue(thisObj, 'txtCrackedBrokenPct') !== undefined ? getValue(thisObj, 'txtCrackedBrokenPct') : ''
      obj.txtDamagedKRS = getValue(thisObj, 'txtDamagedKRS') !== "" && getValue(thisObj, 'txtDamagedKRS') !== undefined ? getValue(thisObj, 'txtDamagedKRS') : '0.0'
      obj.txtDamagePerPound = getValue(thisObj, 'txtDamagePerPound') !== "" && getValue(thisObj, 'txtDamagePerPound') !== undefined ? getValue(thisObj, 'txtDamagePerPound') : ''
      obj.txtDamagePerTon = getValue(thisObj, 'txtDamagePerTon') !== "" && getValue(thisObj, 'txtDamagePerTon') !== undefined ? getValue(thisObj, 'txtDamagePerTon') : ''
      obj.txtDamageSplits = getValue(thisObj, 'txtDamageSplits') !== "" && getValue(thisObj, 'txtDamageSplits') !== undefined ? getValue(thisObj, 'txtDamageSplits') : '0'
      obj.txtDeductions = getValue(thisObj, 'txtDeductions') !== "" && getValue(thisObj, 'txtDeductions') !== undefined ? getValue(thisObj, 'txtDeductions') : ''
      obj.txtDiscoloredShells = getValue(thisObj, 'txtDiscoloredShells') !== "" && getValue(thisObj, 'txtDiscoloredShells') !== undefined ? getValue(thisObj, 'txtDiscoloredShells') : ''
      obj.txtDiscoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct') !== "" && getValue(thisObj, 'txtDiscoloredShellsPct') !== undefined ? getValue(thisObj, 'txtDiscoloredShellsPct') : ''
      obj.txtDTWhseBinNum = getValue(thisObj, 'txtDTWhseBinNum') !== "" && getValue(thisObj, 'txtDTWhseBinNum') !== undefined ? getValue(thisObj, 'txtDTWhseBinNum') : ''
      obj.txtELKDamaged = getValue(thisObj, 'txtELKDamaged') !== "" && getValue(thisObj, 'txtELKDamaged') !== undefined ? getValue(thisObj, 'txtELKDamaged') : '0.0'
      obj.txtELKPremiumPerPound = getValue(thisObj, 'txtELKPremiumPerPound') !== "" && getValue(thisObj, 'txtELKPremiumPerPound') !== undefined ? getValue(thisObj, 'txtELKPremiumPerPound') : ''
      obj.txtELKPremiumPerTon = getValue(thisObj, 'txtELKPremiumPerTon') !== "" && getValue(thisObj, 'txtELKPremiumPerTon') !== undefined ? getValue(thisObj, 'txtELKPremiumPerTon') : ''
      obj.txtExcessMoisture = getValue(thisObj, 'txtExcessMoisture') !== "" && getValue(thisObj, 'txtExcessMoisture') !== undefined ? getValue(thisObj, 'txtExcessMoisture') : ''
      obj.txtExcessSplitsPerPound = getValue(thisObj, 'txtExcessSplitsPerPound') !== "" && getValue(thisObj, 'txtExcessSplitsPerPound') !== undefined ? getValue(thisObj, 'txtExcessSplitsPerPound') : ''
      obj.txtExcessSplitsPerTon = getValue(thisObj, 'txtExcessSplitsPerTon') !== "" && getValue(thisObj, 'txtExcessSplitsPerTon') !== undefined ? getValue(thisObj, 'txtExcessSplitsPerTon') : ''
      obj.txtFancy = getValue(thisObj, 'txtFancy') !== "" && getValue(thisObj, 'txtFancy') !== undefined ? getValue(thisObj, 'txtFancy') : ''
      obj.txtFancyPct = getValue(thisObj, 'txtFancyPct') !== "" && getValue(thisObj, 'txtFancyPct') !== undefined ? getValue(thisObj, 'txtFancyPct') : ''
      obj.txtFMSampleWt = getValue(thisObj, 'txtFMSampleWt') !== "" && getValue(thisObj, 'txtFMSampleWt') !== undefined ? getValue(thisObj, 'txtFMSampleWt') : '0.0'
      obj.txtForeignMaterial = getValue(thisObj, 'txtForeignMaterial') !== "" && getValue(thisObj, 'txtForeignMaterial') !== undefined ? getValue(thisObj, 'txtForeignMaterial') : ''
      obj.txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct') !== "" && getValue(thisObj, 'txtForeignMaterialPct') !== undefined ? getValue(thisObj, 'txtForeignMaterialPct') : '0.00'
      obj.txtForeignMtrl = getValue(thisObj, 'txtForeignMtrl') !== "" && getValue(thisObj, 'txtForeignMtrl') !== undefined ? getValue(thisObj, 'txtForeignMtrl') : ''
      obj.txtFreezeDamage = getValue(thisObj, 'txtFreezeDamage') !== "" && getValue(thisObj, 'txtFreezeDamage') !== undefined ? getValue(thisObj, 'txtFreezeDamage') : ''
      obj.txtFreezeDamagePct = getValue(thisObj, 'txtFreezeDamagePct') !== "" && getValue(thisObj, 'txtFreezeDamagePct') !== undefined ? getValue(thisObj, 'txtFreezeDamagePct') : '0.00'
      obj.txtGrossWeight = getValue(thisObj, 'txtGrossWeight') !== "" && getValue(thisObj, 'txtGrossWeight') !== undefined ? getValue(thisObj, 'txtGrossWeight') : ''
      obj.txtH = getValue(thisObj, 'txtH') !== "" && getValue(thisObj, 'txtH') !== undefined ? getValue(thisObj, 'txtH') : ''
      obj.txtHullBrightness = getValue(thisObj, 'txtHullBrightness') !== "" && getValue(thisObj, 'txtHullBrightness') !== undefined ? getValue(thisObj, 'txtHullBrightness') : ''
      obj.txtHullBrightnessPct = getValue(thisObj, 'txtHullBrightnessPct') !== "" && getValue(thisObj, 'txtHullBrightnessPct') !== undefined ? getValue(thisObj, 'txtHullBrightnessPct') : ''
      obj.txtHulls = getValue(thisObj, 'txtHulls') !== "" && getValue(thisObj, 'txtHulls') !== undefined ? getValue(thisObj, 'txtHulls') : ''
      obj.txtHullsPct = getValue(thisObj, 'txtHullsPct') !== "" && getValue(thisObj, 'txtHullsPct') !== undefined ? getValue(thisObj, 'txtHullsPct') : '0.00'
      obj.txtIDTfromtoVendor = getValue(thisObj, 'txtIDTfromtoVendor') !== "" && getValue(thisObj, 'txtIDTfromtoVendor') !== undefined ? getValue(thisObj, 'txtIDTfromtoVendor') : ''
      obj.txtJumbo = getValue(thisObj, 'txtJumbo') !== "" && getValue(thisObj, 'txtJumbo') !== undefined ? getValue(thisObj, 'txtJumbo') : ''
      obj.txtJumboPct = getValue(thisObj, 'txtJumboPct') !== "" && getValue(thisObj, 'txtJumboPct') !== undefined ? getValue(thisObj, 'txtJumboPct') : ''
      obj.txtKernelsREL = getValue(thisObj, 'txtKernelsREL') !== "" && getValue(thisObj, 'txtKernelsREL') !== undefined ? getValue(thisObj, 'txtKernelsREL') : '0.0'
      obj.txtKernelsRPS = getValue(thisObj, 'txtKernelsRPS') !== "" && getValue(thisObj, 'txtKernelsRPS') !== undefined ? getValue(thisObj, 'txtKernelsRPS') : '0.0'
      obj.txtKernelValuePerPound = getValue(thisObj, 'txtKernelValuePerPound') !== "" && getValue(thisObj, 'txtKernelValuePerPound') !== undefined ? getValue(thisObj, 'txtKernelValuePerPound') : ''
      obj.txtKernelValuePerTon = getValue(thisObj, 'txtKernelValuePerTon') !== "" && getValue(thisObj, 'txtKernelValuePerTon') !== undefined ? getValue(thisObj, 'txtKernelValuePerTon') : ''
      obj.txtKernRidingELK = getValue(thisObj, 'txtKernRidingELK') !== "" && getValue(thisObj, 'txtKernRidingELK') !== undefined ? getValue(thisObj, 'txtKernRidingELK') : ''
      obj.txtKernRidingPrescribe = getValue(thisObj, 'txtKernRidingPrescribe') !== "" && getValue(thisObj, 'txtKernRidingPrescribe') !== undefined ? getValue(thisObj, 'txtKernRidingPrescribe') : ''
      obj.txtKernRidingPrescribePct = getValue(thisObj, 'txtKernRidingPrescribePct') !== "" && getValue(thisObj, 'txtKernRidingPrescribePct') !== undefined ? getValue(thisObj, 'txtKernRidingPrescribePct') : ''
      obj.txtLoad = getValue(thisObj, 'txtLoad') !== "" && getValue(thisObj, 'txtLoad') !== undefined ? getValue(thisObj, 'txtLoad') : ''
      obj.txtLot = getValue(thisObj, 'txtLot') !== "" && getValue(thisObj, 'txtLot') !== undefined ? getValue(thisObj, 'txtLot') : ''
      obj.txtLSK = getValue(thisObj, 'txtLSK') !== "" && getValue(thisObj, 'txtLSK') !== undefined ? getValue(thisObj, 'txtLSK') : ''
      obj.txtLSKDamageRiding = getValue(thisObj, 'txtLSKDamageRiding') !== "" && getValue(thisObj, 'txtLSKDamageRiding') !== undefined ? getValue(thisObj, 'txtLSKDamageRiding') : ''
      obj.txtLSKFallThru = getValue(thisObj, 'txtLSKFallThru') !== "" && getValue(thisObj, 'txtLSKFallThru') !== undefined ? getValue(thisObj, 'txtLSKFallThru') : ''
      obj.txtLSKRidingGr = getValue(thisObj, 'txtLSKRidingGr') !== "" && getValue(thisObj, 'txtLSKRidingGr') !== undefined ? getValue(thisObj, 'txtLSKRidingGr') : ''
      obj.txtLSKRidingPct = getValue(thisObj, 'txtLSKRidingPct') !== "" && getValue(thisObj, 'txtLSKRidingPct') !== undefined ? getValue(thisObj, 'txtLSKRidingPct') : ''
      obj.txtMeterReading = getValue(thisObj, 'txtMeterReading') !== "" && getValue(thisObj, 'txtMeterReading') !== undefined ? getValue(thisObj, 'txtMeterReading') : '0.0'
      obj.txtMoisture = getValue(thisObj, 'txtMoisture') !== "" && getValue(thisObj, 'txtMoisture') !== undefined ? getValue(thisObj, 'txtMoisture') : '0'
      obj.txtNetELK = getValue(thisObj, 'txtNetELK') !== "" && getValue(thisObj, 'txtNetELK') !== undefined ? getValue(thisObj, 'txtNetELK') : ''
      obj.txtNetELKPct = getValue(thisObj, 'txtNetELKPct') !== "" && getValue(thisObj, 'txtNetELKPct') !== undefined ? getValue(thisObj, 'txtNetELKPct') : ''
      obj.txtNetValPerTonExclSLK = getValue(thisObj, 'txtNetValPerTonExclSLK') !== "" && getValue(thisObj, 'txtNetValPerTonExclSLK') !== undefined ? getValue(thisObj, 'txtNetValPerTonExclSLK') : ''
      obj.txtNetWeight = getValue(thisObj, 'txtNetWeight') !== "" && getValue(thisObj, 'txtNetWeight') !== undefined ? getValue(thisObj, 'txtNetWeight') : ''
      obj.txtNetWt = getValue(thisObj, 'txtNetWt') !== "" && getValue(thisObj, 'txtNetWt') !== undefined ? getValue(thisObj, 'txtNetWt') : ''
      obj.txtNetWtExcludingLSK = getValue(thisObj, 'txtNetWtExcludingLSK') !== "" && getValue(thisObj, 'txtNetWtExcludingLSK') !== undefined ? getValue(thisObj, 'txtNetWtExcludingLSK') : ''
      obj.txtOI = getValue(thisObj, 'txtOI') !== "" && getValue(thisObj, 'txtOI') !== undefined ? getValue(thisObj, 'txtOI') : ''
      obj.txtOKDamageRiding = getValue(thisObj, 'txtOKDamageRiding') !== "" && getValue(thisObj, 'txtOKDamageRiding') !== undefined ? getValue(thisObj, 'txtOKDamageRiding') : ''
      obj.txtOKFallThru = getValue(thisObj, 'txtOKFallThru') !== "" && getValue(thisObj, 'txtOKFallThru') !== undefined ? getValue(thisObj, 'txtOKFallThru') : ''
      obj.txtOKRidingGr = getValue(thisObj, 'txtOKRidingGr') !== "" && getValue(thisObj, 'txtOKRidingGr') !== undefined ? getValue(thisObj, 'txtOKRidingGr') : ''
      obj.txtOKRidingPct = getValue(thisObj, 'txtOKRidingPct') !== "" && getValue(thisObj, 'txtOKRidingPct') !== undefined ? getValue(thisObj, 'txtOKRidingPct') : ''
      obj.txtOtherFM = getValue(thisObj, 'txtOtherFM') !== "" && getValue(thisObj, 'txtOtherFM') !== undefined ? getValue(thisObj, 'txtOtherFM') : ''
      obj.txtPerG = getValue(thisObj, 'txtPerG') !== "" && getValue(thisObj, 'txtPerG') !== undefined ? getValue(thisObj, 'txtPerG') : ''
      obj.txtPittingDamage = getValue(thisObj, 'txtPittingDamage') !== "" && getValue(thisObj, 'txtPittingDamage') !== undefined ? getValue(thisObj, 'txtPittingDamage') : ''
      obj.txtPittingDamagePct = getValue(thisObj, 'txtPittingDamagePct') !== "" && getValue(thisObj, 'txtPittingDamagePct') !== undefined ? getValue(thisObj, 'txtPittingDamagePct') : ''
      obj.txtPremiums = getValue(thisObj, 'txtPremiums') !== "" && getValue(thisObj, 'txtPremiums') !== undefined ? getValue(thisObj, 'txtPremiums') : ''
      obj.txtProbingPattern = getValue(thisObj, 'txtProbingPattern') !== "" && getValue(thisObj, 'txtProbingPattern') !== undefined ? getValue(thisObj, 'txtProbingPattern') : ''
      obj.txtProceeds = getValue(thisObj, 'txtProceeds') !== "" && getValue(thisObj, 'txtProceeds') !== undefined ? getValue(thisObj, 'txtProceeds') : ''
      obj.txtSeal = getValue(thisObj, 'txtSeal') !== "" && getValue(thisObj, 'txtSeal') !== undefined ? getValue(thisObj, 'txtSeal') : ''
      obj.txtSeedMeetingSpec = getValue(thisObj, 'txtSeedMeetingSpec') !== "" && getValue(thisObj, 'txtSeedMeetingSpec') !== undefined ? getValue(thisObj, 'txtSeedMeetingSpec') : ''
      obj.txtSegValue = getValue(thisObj, 'txtSegValue') !== "" && getValue(thisObj, 'txtSegValue') !== undefined ? getValue(thisObj, 'txtSegValue') : ''
      obj.txtSharePercentage1 = getValue(thisObj, 'txtSharePercentage1') !== "" && getValue(thisObj, 'txtSharePercentage1') !== undefined ? getValue(thisObj, 'txtSharePercentage1') : ''
      obj.txtSharePercentage2 = getValue(thisObj, 'txtSharePercentage2') !== "" && getValue(thisObj, 'txtSharePercentage2') !== undefined ? getValue(thisObj, 'txtSharePercentage2') : ''
      obj.txtSharePercentage3 = getValue(thisObj, 'txtSharePercentage3') !== "" && getValue(thisObj, 'txtSharePercentage3') !== undefined ? getValue(thisObj, 'txtSharePercentage3') : ''
      obj.txtSharePercentage4 = getValue(thisObj, 'txtSharePercentage4') !== "" && getValue(thisObj, 'txtSharePercentage4') !== undefined ? getValue(thisObj, 'txtSharePercentage4') : ''
      obj.txtSharePercentage5 = getValue(thisObj, 'txtSharePercentage5') !== "" && getValue(thisObj, 'txtSharePercentage5') !== undefined ? getValue(thisObj, 'txtSharePercentage5') : ''
      obj.txtSharePercentage6 = getValue(thisObj, 'txtSharePercentage6') !== "" && getValue(thisObj, 'txtSharePercentage6') !== undefined ? getValue(thisObj, 'txtSharePercentage6') : ''
      obj.txtSharePercentage7 = getValue(thisObj, 'txtSharePercentage7') !== "" && getValue(thisObj, 'txtSharePercentage7') !== undefined ? getValue(thisObj, 'txtSharePercentage7') : ''
      obj.txtSharePercentage8 = getValue(thisObj, 'txtSharePercentage8') !== "" && getValue(thisObj, 'txtSharePercentage8') !== undefined ? getValue(thisObj, 'txtSharePercentage8') : ''
      obj.txtSharePercentage9 = getValue(thisObj, 'txtSharePercentage9') !== "" && getValue(thisObj, 'txtSharePercentage9') !== undefined ? getValue(thisObj, 'txtSharePercentage9') : ''
      obj.txtSharePercentage10 = getValue(thisObj, 'txtSharePercentage10') !== "" && getValue(thisObj, 'txtSharePercentage10') !== undefined ? getValue(thisObj, 'txtSharePercentage10') : ''
      obj.txtSharePercentage11 = getValue(thisObj, 'txtSharePercentage11') !== "" && getValue(thisObj, 'txtSharePercentage11') !== undefined ? getValue(thisObj, 'txtSharePercentage11') : ''
      obj.txtSharePercentage12 = getValue(thisObj, 'txtSharePercentage12') !== "" && getValue(thisObj, 'txtSharePercentage12') !== undefined ? getValue(thisObj, 'txtSharePercentage12') : ''
      obj.txtSharePercentage13 = getValue(thisObj, 'txtSharePercentage13') !== "" && getValue(thisObj, 'txtSharePercentage13') !== undefined ? getValue(thisObj, 'txtSharePercentage13') : ''
      obj.txtSharePercentage14 = getValue(thisObj, 'txtSharePercentage14') !== "" && getValue(thisObj, 'txtSharePercentage14') !== undefined ? getValue(thisObj, 'txtSharePercentage14') : ''
      obj.txtSharePercentage15 = getValue(thisObj, 'txtSharePercentage15') !== "" && getValue(thisObj, 'txtSharePercentage15') !== undefined ? getValue(thisObj, 'txtSharePercentage15') : ''
      obj.txtSharePercentage16 = getValue(thisObj, 'txtSharePercentage16') !== "" && getValue(thisObj, 'txtSharePercentage16') !== undefined ? getValue(thisObj, 'txtSharePercentage16') : ''
      obj.txtSharePercentage17 = getValue(thisObj, 'txtSharePercentage17') !== "" && getValue(thisObj, 'txtSharePercentage17') !== undefined ? getValue(thisObj, 'txtSharePercentage17') : ''
      obj.txtSharePercentage18 = getValue(thisObj, 'txtSharePercentage18') !== "" && getValue(thisObj, 'txtSharePercentage18') !== undefined ? getValue(thisObj, 'txtSharePercentage18') : ''
      obj.txtSharePercentage19 = getValue(thisObj, 'txtSharePercentage19') !== "" && getValue(thisObj, 'txtSharePercentage19') !== undefined ? getValue(thisObj, 'txtSharePercentage19') : ''
      obj.txtSharePercentage20 = getValue(thisObj, 'txtSharePercentage20') !== "" && getValue(thisObj, 'txtSharePercentage20') !== undefined ? getValue(thisObj, 'txtSharePercentage20') : ''
      obj.txtSMK1Gr = getValue(thisObj, 'txtSMK1Gr') !== "" && getValue(thisObj, 'txtSMK1Gr') !== undefined ? getValue(thisObj, 'txtSMK1Gr') : ''
      obj.txtSMK1Pct = getValue(thisObj, 'txtSMK1Pct') !== "" && getValue(thisObj, 'txtSMK1Pct') !== undefined ? getValue(thisObj, 'txtSMK1Pct') : ''
      obj.txtSMK2Gr = getValue(thisObj, 'txtSMK2Gr') !== "" && getValue(thisObj, 'txtSMK2Gr') !== undefined ? getValue(thisObj, 'txtSMK2Gr') : ''
      obj.txtSMK2Pct = getValue(thisObj, 'txtSMK2Pct') !== "" && getValue(thisObj, 'txtSMK2Pct') !== undefined ? getValue(thisObj, 'txtSMK2Pct') : ''
      obj.txtSMK3Gr = getValue(thisObj, 'txtSMK3Gr') !== "" && getValue(thisObj, 'txtSMK3Gr') !== undefined ? getValue(thisObj, 'txtSMK3Gr') : ''
      obj.txtSMK3Pct = getValue(thisObj, 'txtSMK3Pct') !== "" && getValue(thisObj, 'txtSMK3Pct') !== undefined ? getValue(thisObj, 'txtSMK3Pct') : ''
      obj.txtSMK4Gr = getValue(thisObj, 'txtSMK4Gr') !== "" && getValue(thisObj, 'txtSMK4Gr') !== undefined ? getValue(thisObj, 'txtSMK4Gr') : ''
      obj.txtSMK4Pct = getValue(thisObj, 'txtSMK4Pct') !== "" && getValue(thisObj, 'txtSMK4Pct') !== undefined ? getValue(thisObj, 'txtSMK4Pct') : ''
      obj.txtSoundSplits = getValue(thisObj, 'txtSoundSplits') !== "" && getValue(thisObj, 'txtSoundSplits') !== undefined ? getValue(thisObj, 'txtSoundSplits') : ''
      obj.txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct') !== "" && getValue(thisObj, 'txtSoundSplitsPct') !== undefined ? getValue(thisObj, 'txtSoundSplitsPct') : '0'
      obj.txtSts = getValue(thisObj, 'txtSts') !== "" && getValue(thisObj, 'txtSts') !== undefined ? getValue(thisObj, 'txtSts') : ''
      obj.txtSupportPercent = getValue(thisObj, 'txtSupportPercent') !== "" && getValue(thisObj, 'txtSupportPercent') !== undefined ? getValue(thisObj, 'txtSupportPercent') : ''
      obj.txtTckt = getValue(thisObj, 'txtTckt') !== "" && getValue(thisObj, 'txtTckt') !== undefined ? getValue(thisObj, 'txtTckt') : ''
      obj.txtTKCLbs = getValue(thisObj, 'txtTKCLbs') !== "" && getValue(thisObj, 'txtTKCLbs') !== undefined ? getValue(thisObj, 'txtTKCLbs') : ''
      obj.txtTotal = getValue(thisObj, 'txtTotal') !== "" && getValue(thisObj, 'txtTotal') !== undefined ? getValue(thisObj, 'txtTotal') : ''
      obj.txtTotalDamage = getValue(thisObj, 'txtTotalDamage') !== "" && getValue(thisObj, 'txtTotalDamage') !== undefined ? getValue(thisObj, 'txtTotalDamage') : ''
      obj.txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct') !== "" && getValue(thisObj, 'txtTotalDamagePct') !== undefined ? getValue(thisObj, 'txtTotalDamagePct') : '0.00'
      obj.txtTotalDiscountsPerPound = getValue(thisObj, 'txtTotalDiscountsPerPound') !== "" && getValue(thisObj, 'txtTotalDiscountsPerPound') !== undefined ? getValue(thisObj, 'txtTotalDiscountsPerPound') : ''
      obj.txtTotalDiscountsPerTon = getValue(thisObj, 'txtTotalDiscountsPerTon') !== "" && getValue(thisObj, 'txtTotalDiscountsPerTon') !== undefined ? getValue(thisObj, 'txtTotalDiscountsPerTon') : ''
      obj.txtTotalELKDamage = getValue(thisObj, 'txtTotalELKDamage') !== "" && getValue(thisObj, 'txtTotalELKDamage') !== undefined ? getValue(thisObj, 'txtTotalELKDamage') : ''
      obj.txtTotalELKDamagePct = getValue(thisObj, 'txtTotalELKDamagePct') !== "" && getValue(thisObj, 'txtTotalELKDamagePct') !== undefined ? getValue(thisObj, 'txtTotalELKDamagePct') : ''
      obj.txtTotalKernels = getValue(thisObj, 'txtTotalKernels') !== "" && getValue(thisObj, 'txtTotalKernels') !== undefined ? getValue(thisObj, 'txtTotalKernels') : ''
      obj.txtTotalKernelsAndHulls = getValue(thisObj, 'txtTotalKernelsAndHulls') !== "" && getValue(thisObj, 'txtTotalKernelsAndHulls') !== undefined ? getValue(thisObj, 'txtTotalKernelsAndHulls') : '0.00'
      obj.txtTotalLoadVal = getValue(thisObj, 'txtTotalLoadVal') !== "" && getValue(thisObj, 'txtTotalLoadVal') !== undefined ? getValue(thisObj, 'txtTotalLoadVal') : ''
      obj.txtTotalLoadValue = getValue(thisObj, 'txtTotalLoadValue') !== "" && getValue(thisObj, 'txtTotalLoadValue') !== undefined ? getValue(thisObj, 'txtTotalLoadValue') : ''
      obj.txtTotalOK = getValue(thisObj, 'txtTotalOK') !== "" && getValue(thisObj, 'txtTotalOK') !== undefined ? getValue(thisObj, 'txtTotalOK') : ''
      obj.txtTotalOKPct = getValue(thisObj, 'txtTotalOKPct') !== "" && getValue(thisObj, 'txtTotalOKPct') !== undefined ? getValue(thisObj, 'txtTotalOKPct') : ''
      obj.txtTotalPerPound = getValue(thisObj, 'txtTotalPerPound') !== "" && getValue(thisObj, 'txtTotalPerPound') !== undefined ? getValue(thisObj, 'txtTotalPerPound') : ''
      obj.txtTotalPerTon = getValue(thisObj, 'txtTotalPerTon') !== "" && getValue(thisObj, 'txtTotalPerTon') !== undefined ? getValue(thisObj, 'txtTotalPerTon') : ''
      obj.txtTotalSLK = getValue(thisObj, 'txtTotalSLK') !== "" && getValue(thisObj, 'txtTotalSLK') !== undefined ? getValue(thisObj, 'txtTotalSLK') : ''
      obj.txtTotalSLKPct = getValue(thisObj, 'txtTotalSLKPct') !== "" && getValue(thisObj, 'txtTotalSLKPct') !== undefined ? getValue(thisObj, 'txtTotalSLKPct') : '0.00'
      obj.txtTotalSMK = getValue(thisObj, 'txtTotalSMK') !== "" && getValue(thisObj, 'txtTotalSMK') !== undefined ? getValue(thisObj, 'txtTotalSMK') : '0.00'
      obj.txtTotalSMKRScreen = getValue(thisObj, 'txtTotalSMKRScreen') !== "" && getValue(thisObj, 'txtTotalSMKRScreen') !== undefined ? getValue(thisObj, 'txtTotalSMKRScreen') : ''
      obj.txtTotalSMKRScreenPct = getValue(thisObj, 'txtTotalSMKRScreenPct') !== "" && getValue(thisObj, 'txtTotalSMKRScreenPct') !== undefined ? getValue(thisObj, 'txtTotalSMKRScreenPct') : '0'
      obj.txtTradeInspectSC95 = getValue(thisObj, 'txtTradeInspectSC95') !== "" && getValue(thisObj, 'txtTradeInspectSC95') !== undefined ? getValue(thisObj, 'txtTradeInspectSC95') : ''
      obj.txtTradeSettle1007 = getValue(thisObj, 'txtTradeSettle1007') !== "" && getValue(thisObj, 'txtTradeSettle1007') !== undefined ? getValue(thisObj, 'txtTradeSettle1007') : ""
      obj.txtValPerPoundExclSLK = getValue(thisObj, 'txtValPerPoundExclSLK') !== "" && getValue(thisObj, 'txtValPerPoundExclSLK') !== undefined ? getValue(thisObj, 'txtValPerPoundExclSLK') : ''
      obj.txtValPerPoundInclSLK = getValue(thisObj, 'txtValPerPoundInclSLK') !== "" && getValue(thisObj, 'txtValPerPoundInclSLK') !== undefined ? getValue(thisObj, 'txtValPerPoundInclSLK') : ''
      obj.txtVehicleWt = getValue(thisObj, 'txtVehicleWt') !== "" && getValue(thisObj, 'txtVehicleWt') !== undefined ? getValue(thisObj, 'txtVehicleWt') : ''
      obj.txtVendor1 = getValue(thisObj, 'txtVendor1') !== "" && getValue(thisObj, 'txtVendor1') !== undefined ? getValue(thisObj, 'txtVendor1') : ''
      obj.txtVendor2 = getValue(thisObj, 'txtVendor2') !== "" && getValue(thisObj, 'txtVendor2') !== undefined ? getValue(thisObj, 'txtVendor2') : ''
      obj.txtVendor3 = getValue(thisObj, 'txtVendor3') !== "" && getValue(thisObj, 'txtVendor3') !== undefined ? getValue(thisObj, 'txtVendor3') : ''
      obj.txtVendor4 = getValue(thisObj, 'txtVendor4') !== "" && getValue(thisObj, 'txtVendor4') !== undefined ? getValue(thisObj, 'txtVendor4') : ''
      obj.txtVendor5 = getValue(thisObj, 'txtVendor5') !== "" && getValue(thisObj, 'txtVendor5') !== undefined ? getValue(thisObj, 'txtVendor5') : ''
      obj.txtVendor6 = getValue(thisObj, 'txtVendor6') !== "" && getValue(thisObj, 'txtVendor6') !== undefined ? getValue(thisObj, 'txtVendor6') : ''
      obj.txtVendor7 = getValue(thisObj, 'txtVendor7') !== "" && getValue(thisObj, 'txtVendor7') !== undefined ? getValue(thisObj, 'txtVendor7') : ''
      obj.txtVendor8 = getValue(thisObj, 'txtVendor8') !== "" && getValue(thisObj, 'txtVendor8') !== undefined ? getValue(thisObj, 'txtVendor8') : ''
      obj.txtVendor9 = getValue(thisObj, 'txtVendor9') !== "" && getValue(thisObj, 'txtVendor9') !== undefined ? getValue(thisObj, 'txtVendor9') : ''
      obj.txtVendor10 = getValue(thisObj, 'txtVendor10') !== "" && getValue(thisObj, 'txtVendor10') !== undefined ? getValue(thisObj, 'txtVendor10') : ''
      obj.txtVendor11 = getValue(thisObj, 'txtVendor11') !== "" && getValue(thisObj, 'txtVendor11') !== undefined ? getValue(thisObj, 'txtVendor11') : ''
      obj.txtVendor12 = getValue(thisObj, 'txtVendor12') !== "" && getValue(thisObj, 'txtVendor12') !== undefined ? getValue(thisObj, 'txtVendor12') : ''
      obj.txtVendor13 = getValue(thisObj, 'txtVendor13') !== "" && getValue(thisObj, 'txtVendor13') !== undefined ? getValue(thisObj, 'txtVendor13') : ''
      obj.txtVendor14 = getValue(thisObj, 'txtVendor14') !== "" && getValue(thisObj, 'txtVendor14') !== undefined ? getValue(thisObj, 'txtVendor14') : ''
      obj.txtVendor15 = getValue(thisObj, 'txtVendor15') !== "" && getValue(thisObj, 'txtVendor15') !== undefined ? getValue(thisObj, 'txtVendor15') : ''
      obj.txtVendor16 = getValue(thisObj, 'txtVendor16') !== "" && getValue(thisObj, 'txtVendor16') !== undefined ? getValue(thisObj, 'txtVendor16') : ''
      obj.txtVendor17 = getValue(thisObj, 'txtVendor17') !== "" && getValue(thisObj, 'txtVendor17') !== undefined ? getValue(thisObj, 'txtVendor17') : ''
      obj.txtVendor18 = getValue(thisObj, 'txtVendor18') !== "" && getValue(thisObj, 'txtVendor18') !== undefined ? getValue(thisObj, 'txtVendor18') : ''
      obj.txtVendor19 = getValue(thisObj, 'txtVendor19') !== "" && getValue(thisObj, 'txtVendor19') !== undefined ? getValue(thisObj, 'txtVendor19') : ''
      obj.txtVendor20 = getValue(thisObj, 'txtVendor20') !== "" && getValue(thisObj, 'txtVendor20') !== undefined ? getValue(thisObj, 'txtVendor20') : ''
      obj.txtVicamReading = getValue(thisObj, 'txtVicamReading') !== "" && getValue(thisObj, 'txtVicamReading') !== undefined ? getValue(thisObj, 'txtVicamReading') : ''
      obj.txtWeightLessFM = getValue(thisObj, 'txtWeightLessFM') !== "" && getValue(thisObj, 'txtWeightLessFM') !== undefined ? getValue(thisObj, 'txtWeightLessFM') : ''
      obj.txtWormDamage = getValue(thisObj, 'txtWormDamage') !== "" && getValue(thisObj, 'txtWormDamage') !== undefined ? getValue(thisObj, 'txtWormDamage') : ''
      obj.txtWtIncludingVehicle = getValue(thisObj, 'txtWtIncludingVehicle') !== "" && getValue(thisObj, 'txtWtIncludingVehicle') !== undefined ? getValue(thisObj, 'txtWtIncludingVehicle') : ''
      let ddShipFromToLocationlist = thisObj.state['ddShipFromToLocation'].valueList;
      let ddShipFromToLocationval = thisObj.values['ddShipFromToLocation'];
      let lstAreaId = ddShipFromToLocationlist.filter(elem => elem.key === ddShipFromToLocationval);
      fetchRetrieveAreaControlDetail(lstAreaId[0].lstAreaId)
      setAllgetvalue(obj)
      bFormValid(obj, Value)

    }
    catch (err) {

    }
    // return true;
  }
  thisObj.onbtnSaveClick = onbtnSaveClick;

  const onbtnDeleteClick = () => {
    try {
      let txtTradeInspectSC95 = getValue(thisObj, 'txtTradeInspectSC95')
      if (confirm(`Are you sure you want to delete the Trade # ${txtTradeInspectSC95}`) == true) {
        let obj = {}
        obj.chkboxPrintSeg = getValue(thisObj, 'chkboxPrintSeg') !== undefined ? getValue(thisObj, 'chkboxPrintSeg') : ''
        obj.chkboxReGrading = getValue(thisObj, 'chkboxReGrading') !== "" && getValue(thisObj, 'chkboxReGrading') !== undefined ? getValue(thisObj, 'chkboxReGrading') : ''
        obj.ddCategory = getValue(thisObj, 'ddCategory') !== "" && getValue(thisObj, 'ddCategory') !== undefined ? getValue(thisObj, 'ddCategory') : ''
        obj.ddEdibleOil = getValue(thisObj, 'ddEdibleOil') !== "" && getValue(thisObj, 'ddEdibleOil') !== undefined ? getValue(thisObj, 'ddEdibleOil') : ''
        obj.ddGeneration = getValue(thisObj, 'ddGeneration') !== "" && getValue(thisObj, 'ddGeneration') !== undefined ? getValue(thisObj, 'ddGeneration') : ''
        obj.ddIDTfromtoCompany = getValue(thisObj, 'ddIDTfromtoCompany') !== "" && getValue(thisObj, 'ddIDTfromtoCompany') !== undefined ? getValue(thisObj, 'ddIDTfromtoCompany') : ''
        obj.ddIDTfromtoLocation = getValue(thisObj, 'ddIDTfromtoLocation') !== "" && getValue(thisObj, 'ddIDTfromtoLocation') !== undefined ? getValue(thisObj, 'ddIDTfromtoLocation') : ''
        obj.ddOleic = getValue(thisObj, 'ddOleic') !== "" && getValue(thisObj, 'ddOleic') !== undefined ? getValue(thisObj, 'ddOleic') : ''
        obj.ddPeanutType = getValue(thisObj, 'ddPeanutType') !== "" && getValue(thisObj, 'ddPeanutType') !== undefined ? getValue(thisObj, 'ddPeanutType') : ''
        obj.ddPeanutVariety = getValue(thisObj, 'ddPeanutVariety') !== "" && getValue(thisObj, 'ddPeanutVariety') !== undefined ? getValue(thisObj, 'ddPeanutVariety') : ''
        obj.ddRemitTo1 = getValue(thisObj, 'ddRemitTo1') !== "" && getValue(thisObj, 'ddRemitTo1') !== undefined ? getValue(thisObj, 'ddRemitTo1') : ''
        obj.ddRemitTo2 = getValue(thisObj, 'ddRemitTo2') !== "" && getValue(thisObj, 'ddRemitTo2') !== undefined ? getValue(thisObj, 'ddRemitTo2') : ''
        obj.ddRemitTo3 = getValue(thisObj, 'ddRemitTo3') !== "" && getValue(thisObj, 'ddRemitTo3') !== undefined ? getValue(thisObj, 'ddRemitTo3') : ''
        obj.ddRemitTo4 = getValue(thisObj, 'ddRemitTo4') !== "" && getValue(thisObj, 'ddRemitTo4') !== undefined ? getValue(thisObj, 'ddRemitTo4') : ''
        obj.ddRemitTo5 = getValue(thisObj, 'ddRemitTo5') !== "" && getValue(thisObj, 'ddRemitTo5') !== undefined ? getValue(thisObj, 'ddRemitTo5') : ''
        obj.ddRemitTo6 = getValue(thisObj, 'ddRemitTo6') !== "" && getValue(thisObj, 'ddRemitTo6') !== undefined ? getValue(thisObj, 'ddRemitTo6') : ''
        obj.ddRemitTo7 = getValue(thisObj, 'ddRemitTo7') !== "" && getValue(thisObj, 'ddRemitTo7') !== undefined ? getValue(thisObj, 'ddRemitTo7') : ''
        obj.ddRemitTo8 = getValue(thisObj, 'ddRemitTo8') !== "" && getValue(thisObj, 'ddRemitTo8') !== undefined ? getValue(thisObj, 'ddRemitTo8') : ''
        obj.ddRemitTo9 = getValue(thisObj, 'ddRemitTo9') !== "" && getValue(thisObj, 'ddRemitTo9') !== undefined ? getValue(thisObj, 'ddRemitTo9') : ''
        obj.ddRemitTo10 = getValue(thisObj, 'ddRemitTo10') !== "" && getValue(thisObj, 'ddRemitTo10') !== undefined ? getValue(thisObj, 'ddRemitTo10') : ''
        obj.ddRemitTo11 = getValue(thisObj, 'ddRemitTo11') !== "" && getValue(thisObj, 'ddRemitTo11') !== undefined ? getValue(thisObj, 'ddRemitTo11') : ''
        obj.ddRemitTo12 = getValue(thisObj, 'ddRemitTo12') !== "" && getValue(thisObj, 'ddRemitTo12') !== undefined ? getValue(thisObj, 'ddRemitTo12') : ''
        obj.ddRemitTo13 = getValue(thisObj, 'ddRemitTo13') !== "" && getValue(thisObj, 'ddRemitTo13') !== undefined ? getValue(thisObj, 'ddRemitTo13') : ''
        obj.ddRemitTo14 = getValue(thisObj, 'ddRemitTo14') !== "" && getValue(thisObj, 'ddRemitTo14') !== undefined ? getValue(thisObj, 'ddRemitTo14') : ''
        obj.ddRemitTo15 = getValue(thisObj, 'ddRemitTo15') !== "" && getValue(thisObj, 'ddRemitTo15') !== undefined ? getValue(thisObj, 'ddRemitTo15') : ''
        obj.ddRemitTo16 = getValue(thisObj, 'ddRemitTo16') !== "" && getValue(thisObj, 'ddRemitTo16') !== undefined ? getValue(thisObj, 'ddRemitTo16') : ''
        obj.ddRemitTo17 = getValue(thisObj, 'ddRemitTo17') !== "" && getValue(thisObj, 'ddRemitTo17') !== undefined ? getValue(thisObj, 'ddRemitTo17') : ''
        obj.ddRemitTo18 = getValue(thisObj, 'ddRemitTo18') !== "" && getValue(thisObj, 'ddRemitTo18') !== undefined ? getValue(thisObj, 'ddRemitTo18') : ''
        obj.ddRemitTo19 = getValue(thisObj, 'ddRemitTo19') !== "" && getValue(thisObj, 'ddRemitTo19') !== undefined ? getValue(thisObj, 'ddRemitTo19') : ''
        obj.ddRemitTo20 = getValue(thisObj, 'ddRemitTo20') !== "" && getValue(thisObj, 'ddRemitTo20') !== undefined ? getValue(thisObj, 'ddRemitTo20') : ''
        obj.ddSeg = getValue(thisObj, 'ddSeg') !== "" && getValue(thisObj, 'ddSeg') !== undefined ? getValue(thisObj, 'ddSeg') : ''
        obj.ddShipFromToLocation = getValue(thisObj, 'ddShipFromToLocation') !== "" && getValue(thisObj, 'ddShipFromToLocation') !== undefined ? getValue(thisObj, 'ddShipFromToLocation') : ''
        obj.ddWhseBinNum = getValue(thisObj, 'ddWhseBinNum') !== "" && getValue(thisObj, 'ddWhseBinNum') !== undefined ? getValue(thisObj, 'ddWhseBinNum') : ''
        obj.dtInspectDateTime = getValue(thisObj, 'dtInspectDateTime') !== "" && getValue(thisObj, 'dtInspectDateTime') !== undefined ? moment(getValue(thisObj, 'dtInspectDateTime')).format('MM/DD/YYYY hh:mm A') : ''
        obj.dtSettleDateTime = getValue(thisObj, 'dtSettleDateTime') !== "" && getValue(thisObj, 'dtSettleDateTime') !== undefined ? getValue(thisObj, 'dtSettleDateTime') : ""
        obj.dtStartDateTime = getValue(thisObj, 'dtStartDateTime') !== "" && getValue(thisObj, 'dtStartDateTime') !== undefined ? getValue(thisObj, 'dtStartDateTime') : ""
        obj.lblAddedBy = getValue(thisObj, 'lblAddedBy') !== "" && getValue(thisObj, 'lblAddedBy') !== undefined ? getValue(thisObj, 'lblAddedBy') : ''
        obj.lblAddedByValue = getValue(thisObj, 'lblAddedByValue') !== "" && getValue(thisObj, 'lblAddedByValue') !== undefined ? getValue(thisObj, 'lblAddedByValue') : ''
        obj.lblAgreeNum = getValue(thisObj, 'lblAgreeNum') !== "" && getValue(thisObj, 'lblAgreeNum') !== undefined ? getValue(thisObj, 'lblAgreeNum') : ''
        obj.lblIDTAgreeNum = getValue(thisObj, 'lblIDTAgreeNum') !== "" && getValue(thisObj, 'lblIDTAgreeNum') !== undefined ? getValue(thisObj, 'lblIDTAgreeNum') : ''
        obj.lblChangedBy = getValue(thisObj, 'lblChangedBy') !== "" && getValue(thisObj, 'lblChangedBy') !== undefined ? getValue(thisObj, 'lblChangedBy') : ''
        obj.lblChangedByValue = getValue(thisObj, 'lblChangedByValue') !== "" && getValue(thisObj, 'lblChangedByValue') !== undefined ? getValue(thisObj, 'lblChangedByValue') : ''
        obj.lblCropYear2 = getValue(thisObj, 'lblCropYear2') !== "" && getValue(thisObj, 'lblCropYear2') !== undefined ? getValue(thisObj, 'lblCropYear2') : ''
        obj.lblInterDeptTransfer = getValue(thisObj, 'lblInterDeptTransfer') !== "" && getValue(thisObj, 'lblInterDeptTransfer') !== undefined ? getValue(thisObj, 'lblInterDeptTransfer') : ''
        obj.txtKernRidingELKPct = getValue(thisObj, 'txtKernRidingELKPct') !== "" && getValue(thisObj, 'txtKernRidingELKPct') !== undefined ? getValue(thisObj, 'txtKernRidingELKPct') : ''
        obj.lblPeanutInformation = getValue(thisObj, 'lblPeanutInformation') !== "" && getValue(thisObj, 'lblPeanutInformation') !== undefined ? getValue(thisObj, 'lblPeanutInformation') : ''
        obj.lblPerLb = getValue(thisObj, 'lblPerLb') !== "" && getValue(thisObj, 'lblPerLb') !== undefined ? getValue(thisObj, 'lblPerLb') : ''
        obj.lblPerLb2 = getValue(thisObj, 'lblPerLb2') !== "" && getValue(thisObj, 'lblPerLb2') !== undefined ? getValue(thisObj, 'lblPerLb2') : ''
        obj.lblPerLb3 = getValue(thisObj, 'lblPerLb3') !== "" && getValue(thisObj, 'lblPerLb3') !== undefined ? getValue(thisObj, 'lblPerLb3') : ''
        obj.lblPerTon = getValue(thisObj, 'lblPerTon') !== "" && getValue(thisObj, 'lblPerTon') !== undefined ? getValue(thisObj, 'lblPerTon') : ''
        obj.lblPrintSeg = getValue(thisObj, 'lblPrintSeg') !== "" && getValue(thisObj, 'lblPrintSeg') !== undefined ? getValue(thisObj, 'lblPrintSeg') : ''
        obj.lblReGrading = getValue(thisObj, 'lblReGrading') !== "" && getValue(thisObj, 'lblReGrading') !== undefined ? getValue(thisObj, 'lblReGrading') : ''
        obj.lblRemitTo = getValue(thisObj, 'lblRemitTo') !== "" && getValue(thisObj, 'lblRemitTo') !== undefined ? getValue(thisObj, 'lblRemitTo') : ''
        obj.lblScaleTickets = getValue(thisObj, 'lblScaleTickets') !== "" && getValue(thisObj, 'lblScaleTickets') !== undefined ? getValue(thisObj, 'lblScaleTickets') : ''
        obj.lblSettlementInformation = getValue(thisObj, 'lblSettlementInformation') !== "" && getValue(thisObj, 'lblSettlementInformation') !== undefined ? getValue(thisObj, 'lblSettlementInformation') : ''
        obj.lblSharePercentage = getValue(thisObj, 'lblSharePercentage') !== "" && getValue(thisObj, 'lblSharePercentage') !== undefined ? getValue(thisObj, 'lblSharePercentage') : ''
        obj.lblShippedFrom = getValue(thisObj, 'lblShippedFrom') !== "" && getValue(thisObj, 'lblShippedFrom') !== undefined ? getValue(thisObj, 'lblShippedFrom') : ''
        obj.lblShippedTo = getValue(thisObj, 'lblShippedTo') !== "" && getValue(thisObj, 'lblShippedTo') !== undefined ? getValue(thisObj, 'lblShippedTo') : ''
        obj.lblValueCalculation = getValue(thisObj, 'lblValueCalculation') !== "" && getValue(thisObj, 'lblValueCalculation') !== undefined ? getValue(thisObj, 'lblValueCalculation') : ''
        obj.lblVendor = getValue(thisObj, 'lblVendor') !== "" && getValue(thisObj, 'lblVendor') !== undefined ? getValue(thisObj, 'lblVendor') : ''
        obj.lblVendorSplits = getValue(thisObj, 'lblVendorSplits') !== "" && getValue(thisObj, 'lblVendorSplits') !== undefined ? getValue(thisObj, 'lblVendorSplits') : ''
        obj.lblXX1 = getValue(thisObj, 'lblXX1') !== "" && getValue(thisObj, 'lblXX1') !== undefined ? getValue(thisObj, 'lblXX1') : ''
        obj.lblXX2 = getValue(thisObj, 'lblXX2') !== "" && getValue(thisObj, 'lblXX2') !== undefined ? getValue(thisObj, 'lblXX2') : ''
        obj.lblXX3 = getValue(thisObj, 'lblXX3') !== "" && getValue(thisObj, 'lblXX3') !== undefined ? getValue(thisObj, 'lblXX3') : ''
        obj.lblXX4 = getValue(thisObj, 'lblXX4') !== "" && getValue(thisObj, 'lblXX4') !== undefined ? getValue(thisObj, 'lblXX4') : ''
        obj.lblXX5 = getValue(thisObj, 'lblXX5') !== "" && getValue(thisObj, 'lblXX5') !== undefined ? getValue(thisObj, 'lblXX5') : ''
        obj.lblXX6 = getValue(thisObj, 'lblXX6') !== "" && getValue(thisObj, 'lblXX6') !== undefined ? getValue(thisObj, 'lblXX6') : ''
        obj.lblXX7 = getValue(thisObj, 'lblXX7') !== "" && getValue(thisObj, 'lblXX7') !== undefined ? getValue(thisObj, 'lblXX7') : ''
        obj.lblXX8 = getValue(thisObj, 'lblXX8') !== "" && getValue(thisObj, 'lblXX8') !== undefined ? getValue(thisObj, 'lblXX8') : ''
        obj.lblXX9 = getValue(thisObj, 'lblXX9') !== "" && getValue(thisObj, 'lblXX9') !== undefined ? getValue(thisObj, 'lblXX9') : ''
        obj.lblXX10 = getValue(thisObj, 'lblXX10') !== "" && getValue(thisObj, 'lblXX10') !== undefined ? getValue(thisObj, 'lblXX10') : ''
        obj.lblXX11 = getValue(thisObj, 'lblXX11') !== "" && getValue(thisObj, 'lblXX11') !== undefined ? getValue(thisObj, 'lblXX11') : ''
        obj.lblXX12 = getValue(thisObj, 'lblXX12') !== "" && getValue(thisObj, 'lblXX12') !== undefined ? getValue(thisObj, 'lblXX12') : ''
        obj.lblXX13 = getValue(thisObj, 'lblXX13') !== "" && getValue(thisObj, 'lblXX13') !== undefined ? getValue(thisObj, 'lblXX13') : ''
        obj.lblXX14 = getValue(thisObj, 'lblXX14') !== "" && getValue(thisObj, 'lblXX14') !== undefined ? getValue(thisObj, 'lblXX14') : ''
        obj.lblXX15 = getValue(thisObj, 'lblXX15') !== "" && getValue(thisObj, 'lblXX15') !== undefined ? getValue(thisObj, 'lblXX15') : ''
        obj.lblXX16 = getValue(thisObj, 'lblXX16') !== "" && getValue(thisObj, 'lblXX16') !== undefined ? getValue(thisObj, 'lblXX16') : ''
        obj.lblXX17 = getValue(thisObj, 'lblXX17') !== "" && getValue(thisObj, 'lblXX17') !== undefined ? getValue(thisObj, 'lblXX17') : ''
        obj.lblXX18 = getValue(thisObj, 'lblXX18') !== "" && getValue(thisObj, 'lblXX18') !== undefined ? getValue(thisObj, 'lblXX18') : ''
        obj.lblXX19 = getValue(thisObj, 'lblXX19') !== "" && getValue(thisObj, 'lblXX19') !== undefined ? getValue(thisObj, 'lblXX19') : ''
        obj.lblXX20 = getValue(thisObj, 'lblXX20') !== "" && getValue(thisObj, 'lblXX20') !== undefined ? getValue(thisObj, 'lblXX20') : ''
        obj.radioGradePricingMethod = getValue(thisObj, 'radioGradePricingMethod') !== "" && getValue(thisObj, 'radioGradePricingMethod') !== undefined ? getValue(thisObj, 'radioGradePricingMethod') : ''
        obj.radioGramsPercentage = getValue(thisObj, 'radioGramsPercentage') !== "" && getValue(thisObj, 'radioGramsPercentage') !== undefined ? getValue(thisObj, 'radioGramsPercentage') : ''
        obj.radioTradeSaleTradePurchase = getValue(thisObj, 'radioTradeSaleTradePurchase') !== "" && getValue(thisObj, 'radioTradeSaleTradePurchase') !== undefined ? getValue(thisObj, 'radioTradeSaleTradePurchase') : ''
        obj.txt1007Handler = getValue(thisObj, 'txt1007Handler') !== "" && getValue(thisObj, 'txt1007Handler') !== undefined ? getValue(thisObj, 'txt1007Handler') : ''
        obj.txt1007HandlerName = getValue(thisObj, 'txt1007HandlerName') !== "" && getValue(thisObj, 'txt1007HandlerName') !== undefined ? getValue(thisObj, 'txt1007HandlerName') : ''
        obj.txtAccessFMPerPound = getValue(thisObj, 'txtAccessFMPerPound') !== "" && getValue(thisObj, 'txtAccessFMPerPound') !== undefined ? getValue(thisObj, 'txtAccessFMPerPound') : ''
        obj.txtAccessFMPerTon = getValue(thisObj, 'txtAccessFMPerTon') !== "" && getValue(thisObj, 'txtAccessFMPerTon') !== undefined ? getValue(thisObj, 'txtAccessFMPerTon') : ''
        obj.txtAFlavusPerPound = getValue(thisObj, 'txtAFlavusPerPound') !== "" && getValue(thisObj, 'txtAFlavusPerPound') !== undefined ? getValue(thisObj, 'txtAFlavusPerPound') : ''
        obj.txtAFlavusPerTon = getValue(thisObj, 'txtAFlavusPerTon') !== "" && getValue(thisObj, 'txtAFlavusPerTon') !== undefined ? getValue(thisObj, 'txtAFlavusPerTon') : ''
        obj.txtAgreementPrice = getValue(thisObj, 'txtAgreementPrice') !== "" && getValue(thisObj, 'txtAgreementPrice') !== undefined ? getValue(thisObj, 'txtAgreementPrice') : ''
        obj.txtarInspectionRemarks = getValue(thisObj, 'txtarInspectionRemarks') !== "" && getValue(thisObj, 'txtarInspectionRemarks') !== undefined ? getValue(thisObj, 'txtarInspectionRemarks') : ''
        obj.txtarSettleRemarks = getValue(thisObj, 'txtarSettleRemarks') !== "" && getValue(thisObj, 'txtarSettleRemarks') !== undefined ? getValue(thisObj, 'txtarSettleRemarks') : ''
        obj.txtBluePanWT = getValue(thisObj, 'txtBluePanWT') !== "" && getValue(thisObj, 'txtBluePanWT') !== undefined ? getValue(thisObj, 'txtBluePanWT') : '0.0'
        obj.txtCleanSampleWt = getValue(thisObj, 'txtCleanSampleWt') !== "" && getValue(thisObj, 'txtCleanSampleWt') !== undefined ? getValue(thisObj, 'txtCleanSampleWt') : '0.0'
        obj.txtcolInventoryWt = getValue(thisObj, 'txtcolInventoryWt') !== "" && getValue(thisObj, 'txtcolInventoryWt') !== undefined ? getValue(thisObj, 'txtcolInventoryWt') : ''
        obj.txtcolLoad = getValue(thisObj, 'txtcolLoad') !== "" && getValue(thisObj, 'txtcolLoad') !== undefined ? getValue(thisObj, 'txtcolLoad') : ''
        obj.txtcolRecvDate = getValue(thisObj, 'txtcolRecvDate') !== "" && getValue(thisObj, 'txtcolRecvDate') !== undefined ? getValue(thisObj, 'txtcolRecvDate') : ''
        obj.txtcolRecvTicket = getValue(thisObj, 'txtcolRecvTicket') !== "" && getValue(thisObj, 'txtcolRecvTicket') !== undefined ? getValue(thisObj, 'txtcolRecvTicket') : ''
        obj.txtcolRecvWt = getValue(thisObj, 'txtcolRecvWt') !== "" && getValue(thisObj, 'txtcolRecvWt') !== undefined ? getValue(thisObj, 'txtcolRecvWt') : ''
        obj.txtcolShipDate = getValue(thisObj, 'txtcolShipDate') !== "" && getValue(thisObj, 'txtcolShipDate') !== undefined ? getValue(thisObj, 'txtcolShipDate') : ''
        obj.txtcolShipTicket = getValue(thisObj, 'txtcolShipTicket') !== "" && getValue(thisObj, 'txtcolShipTicket') !== undefined ? getValue(thisObj, 'txtcolShipTicket') : ''
        obj.txtcolShipWt = getValue(thisObj, 'txtcolShipWt') !== "" && getValue(thisObj, 'txtcolShipWt') !== undefined ? getValue(thisObj, 'txtcolShipWt') : ''
        obj.txtcolVehicle = getValue(thisObj, 'txtcolVehicle') !== "" && getValue(thisObj, 'txtcolVehicle') !== undefined ? getValue(thisObj, 'txtcolVehicle') : ''
        obj.txtConcealedRMD = getValue(thisObj, 'txtConcealedRMD') !== "" && getValue(thisObj, 'txtConcealedRMD') !== undefined ? getValue(thisObj, 'txtConcealedRMD') : ''
        obj.txtConcealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct') !== "" && getValue(thisObj, 'txtConcealedRMDPct') !== undefined ? getValue(thisObj, 'txtConcealedRMDPct') : '0.00'
        obj.txtCrackedBroken = getValue(thisObj, 'txtCrackedBroken') !== "" && getValue(thisObj, 'txtCrackedBroken') !== undefined ? getValue(thisObj, 'txtCrackedBroken') : ''
        obj.txtCrackedBrokenPct = getValue(thisObj, 'txtCrackedBrokenPct') !== "" && getValue(thisObj, 'txtCrackedBrokenPct') !== undefined ? getValue(thisObj, 'txtCrackedBrokenPct') : ''
        obj.txtDamagedKRS = getValue(thisObj, 'txtDamagedKRS') !== "" && getValue(thisObj, 'txtDamagedKRS') !== undefined ? getValue(thisObj, 'txtDamagedKRS') : '0.0'
        obj.txtDamagePerPound = getValue(thisObj, 'txtDamagePerPound') !== "" && getValue(thisObj, 'txtDamagePerPound') !== undefined ? getValue(thisObj, 'txtDamagePerPound') : ''
        obj.txtDamagePerTon = getValue(thisObj, 'txtDamagePerTon') !== "" && getValue(thisObj, 'txtDamagePerTon') !== undefined ? getValue(thisObj, 'txtDamagePerTon') : ''
        obj.txtDamageSplits = getValue(thisObj, 'txtDamageSplits') !== "" && getValue(thisObj, 'txtDamageSplits') !== undefined ? getValue(thisObj, 'txtDamageSplits') : '0'
        obj.txtDeductions = getValue(thisObj, 'txtDeductions') !== "" && getValue(thisObj, 'txtDeductions') !== undefined ? getValue(thisObj, 'txtDeductions') : ''
        obj.txtDiscoloredShells = getValue(thisObj, 'txtDiscoloredShells') !== "" && getValue(thisObj, 'txtDiscoloredShells') !== undefined ? getValue(thisObj, 'txtDiscoloredShells') : ''
        obj.txtDiscoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct') !== "" && getValue(thisObj, 'txtDiscoloredShellsPct') !== undefined ? getValue(thisObj, 'txtDiscoloredShellsPct') : ''
        obj.txtDTWhseBinNum = getValue(thisObj, 'txtDTWhseBinNum') !== "" && getValue(thisObj, 'txtDTWhseBinNum') !== undefined ? getValue(thisObj, 'txtDTWhseBinNum') : ''
        obj.txtELKDamaged = getValue(thisObj, 'txtELKDamaged') !== "" && getValue(thisObj, 'txtELKDamaged') !== undefined ? getValue(thisObj, 'txtELKDamaged') : '0.0'
        obj.txtELKPremiumPerPound = getValue(thisObj, 'txtELKPremiumPerPound') !== "" && getValue(thisObj, 'txtELKPremiumPerPound') !== undefined ? getValue(thisObj, 'txtELKPremiumPerPound') : ''
        obj.txtELKPremiumPerTon = getValue(thisObj, 'txtELKPremiumPerTon') !== "" && getValue(thisObj, 'txtELKPremiumPerTon') !== undefined ? getValue(thisObj, 'txtELKPremiumPerTon') : ''
        obj.txtExcessMoisture = getValue(thisObj, 'txtExcessMoisture') !== "" && getValue(thisObj, 'txtExcessMoisture') !== undefined ? getValue(thisObj, 'txtExcessMoisture') : ''
        obj.txtExcessSplitsPerPound = getValue(thisObj, 'txtExcessSplitsPerPound') !== "" && getValue(thisObj, 'txtExcessSplitsPerPound') !== undefined ? getValue(thisObj, 'txtExcessSplitsPerPound') : ''
        obj.txtExcessSplitsPerTon = getValue(thisObj, 'txtExcessSplitsPerTon') !== "" && getValue(thisObj, 'txtExcessSplitsPerTon') !== undefined ? getValue(thisObj, 'txtExcessSplitsPerTon') : ''
        obj.txtFancy = getValue(thisObj, 'txtFancy') !== "" && getValue(thisObj, 'txtFancy') !== undefined ? getValue(thisObj, 'txtFancy') : ''
        obj.txtFancyPct = getValue(thisObj, 'txtFancyPct') !== "" && getValue(thisObj, 'txtFancyPct') !== undefined ? getValue(thisObj, 'txtFancyPct') : ''
        obj.txtFMSampleWt = getValue(thisObj, 'txtFMSampleWt') !== "" && getValue(thisObj, 'txtFMSampleWt') !== undefined ? getValue(thisObj, 'txtFMSampleWt') : '0.0'
        obj.txtForeignMaterial = getValue(thisObj, 'txtForeignMaterial') !== "" && getValue(thisObj, 'txtForeignMaterial') !== undefined ? getValue(thisObj, 'txtForeignMaterial') : ''
        obj.txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct') !== "" && getValue(thisObj, 'txtForeignMaterialPct') !== undefined ? getValue(thisObj, 'txtForeignMaterialPct') : '0.00'
        obj.txtForeignMtrl = getValue(thisObj, 'txtForeignMtrl') !== "" && getValue(thisObj, 'txtForeignMtrl') !== undefined ? getValue(thisObj, 'txtForeignMtrl') : ''
        obj.txtFreezeDamage = getValue(thisObj, 'txtFreezeDamage') !== "" && getValue(thisObj, 'txtFreezeDamage') !== undefined ? getValue(thisObj, 'txtFreezeDamage') : ''
        obj.txtFreezeDamagePct = getValue(thisObj, 'txtFreezeDamagePct') !== "" && getValue(thisObj, 'txtFreezeDamagePct') !== undefined ? getValue(thisObj, 'txtFreezeDamagePct') : '0.00'
        obj.txtGrossWeight = getValue(thisObj, 'txtGrossWeight') !== "" && getValue(thisObj, 'txtGrossWeight') !== undefined ? getValue(thisObj, 'txtGrossWeight') : ''
        obj.txtH = getValue(thisObj, 'txtH') !== "" && getValue(thisObj, 'txtH') !== undefined ? getValue(thisObj, 'txtH') : ''
        obj.txtHullBrightness = getValue(thisObj, 'txtHullBrightness') !== "" && getValue(thisObj, 'txtHullBrightness') !== undefined ? getValue(thisObj, 'txtHullBrightness') : ''
        obj.txtHullBrightnessPct = getValue(thisObj, 'txtHullBrightnessPct') !== "" && getValue(thisObj, 'txtHullBrightnessPct') !== undefined ? getValue(thisObj, 'txtHullBrightnessPct') : ''
        obj.txtHulls = getValue(thisObj, 'txtHulls') !== "" && getValue(thisObj, 'txtHulls') !== undefined ? getValue(thisObj, 'txtHulls') : ''
        obj.txtHullsPct = getValue(thisObj, 'txtHullsPct') !== "" && getValue(thisObj, 'txtHullsPct') !== undefined ? getValue(thisObj, 'txtHullsPct') : '0.00'
        obj.txtIDTfromtoVendor = getValue(thisObj, 'txtIDTfromtoVendor') !== "" && getValue(thisObj, 'txtIDTfromtoVendor') !== undefined ? getValue(thisObj, 'txtIDTfromtoVendor') : ''
        obj.txtJumbo = getValue(thisObj, 'txtJumbo') !== "" && getValue(thisObj, 'txtJumbo') !== undefined ? getValue(thisObj, 'txtJumbo') : ''
        obj.txtJumboPct = getValue(thisObj, 'txtJumboPct') !== "" && getValue(thisObj, 'txtJumboPct') !== undefined ? getValue(thisObj, 'txtJumboPct') : ''
        obj.txtKernelsREL = getValue(thisObj, 'txtKernelsREL') !== "" && getValue(thisObj, 'txtKernelsREL') !== undefined ? getValue(thisObj, 'txtKernelsREL') : '0.0'
        obj.txtKernelsRPS = getValue(thisObj, 'txtKernelsRPS') !== "" && getValue(thisObj, 'txtKernelsRPS') !== undefined ? getValue(thisObj, 'txtKernelsRPS') : '0.0'
        obj.txtKernelValuePerPound = getValue(thisObj, 'txtKernelValuePerPound') !== "" && getValue(thisObj, 'txtKernelValuePerPound') !== undefined ? getValue(thisObj, 'txtKernelValuePerPound') : ''
        obj.txtKernelValuePerTon = getValue(thisObj, 'txtKernelValuePerTon') !== "" && getValue(thisObj, 'txtKernelValuePerTon') !== undefined ? getValue(thisObj, 'txtKernelValuePerTon') : ''
        obj.txtKernRidingELK = getValue(thisObj, 'txtKernRidingELK') !== "" && getValue(thisObj, 'txtKernRidingELK') !== undefined ? getValue(thisObj, 'txtKernRidingELK') : ''
        obj.txtKernRidingPrescribe = getValue(thisObj, 'txtKernRidingPrescribe') !== "" && getValue(thisObj, 'txtKernRidingPrescribe') !== undefined ? getValue(thisObj, 'txtKernRidingPrescribe') : ''
        obj.txtKernRidingPrescribePct = getValue(thisObj, 'txtKernRidingPrescribePct') !== "" && getValue(thisObj, 'txtKernRidingPrescribePct') !== undefined ? getValue(thisObj, 'txtKernRidingPrescribePct') : ''
        obj.txtLoad = getValue(thisObj, 'txtLoad') !== "" && getValue(thisObj, 'txtLoad') !== undefined ? getValue(thisObj, 'txtLoad') : ''
        obj.txtLot = getValue(thisObj, 'txtLot') !== "" && getValue(thisObj, 'txtLot') !== undefined ? getValue(thisObj, 'txtLot') : ''
        obj.txtLSK = getValue(thisObj, 'txtLSK') !== "" && getValue(thisObj, 'txtLSK') !== undefined ? getValue(thisObj, 'txtLSK') : ''
        obj.txtLSKDamageRiding = getValue(thisObj, 'txtLSKDamageRiding') !== "" && getValue(thisObj, 'txtLSKDamageRiding') !== undefined ? getValue(thisObj, 'txtLSKDamageRiding') : ''
        obj.txtLSKFallThru = getValue(thisObj, 'txtLSKFallThru') !== "" && getValue(thisObj, 'txtLSKFallThru') !== undefined ? getValue(thisObj, 'txtLSKFallThru') : ''
        obj.txtLSKRidingGr = getValue(thisObj, 'txtLSKRidingGr') !== "" && getValue(thisObj, 'txtLSKRidingGr') !== undefined ? getValue(thisObj, 'txtLSKRidingGr') : ''
        obj.txtLSKRidingPct = getValue(thisObj, 'txtLSKRidingPct') !== "" && getValue(thisObj, 'txtLSKRidingPct') !== undefined ? getValue(thisObj, 'txtLSKRidingPct') : ''
        obj.txtMeterReading = getValue(thisObj, 'txtMeterReading') !== "" && getValue(thisObj, 'txtMeterReading') !== undefined ? getValue(thisObj, 'txtMeterReading') : '0.0'
        obj.txtMoisture = getValue(thisObj, 'txtMoisture') !== "" && getValue(thisObj, 'txtMoisture') !== undefined ? getValue(thisObj, 'txtMoisture') : '0'
        obj.txtNetELK = getValue(thisObj, 'txtNetELK') !== "" && getValue(thisObj, 'txtNetELK') !== undefined ? getValue(thisObj, 'txtNetELK') : ''
        obj.txtNetELKPct = getValue(thisObj, 'txtNetELKPct') !== "" && getValue(thisObj, 'txtNetELKPct') !== undefined ? getValue(thisObj, 'txtNetELKPct') : ''
        obj.txtNetValPerTonExclSLK = getValue(thisObj, 'txtNetValPerTonExclSLK') !== "" && getValue(thisObj, 'txtNetValPerTonExclSLK') !== undefined ? getValue(thisObj, 'txtNetValPerTonExclSLK') : ''
        obj.txtNetWeight = getValue(thisObj, 'txtNetWeight') !== "" && getValue(thisObj, 'txtNetWeight') !== undefined ? getValue(thisObj, 'txtNetWeight') : ''
        obj.txtNetWt = getValue(thisObj, 'txtNetWt') !== "" && getValue(thisObj, 'txtNetWt') !== undefined ? getValue(thisObj, 'txtNetWt') : ''
        obj.txtNetWtExcludingLSK = getValue(thisObj, 'txtNetWtExcludingLSK') !== "" && getValue(thisObj, 'txtNetWtExcludingLSK') !== undefined ? getValue(thisObj, 'txtNetWtExcludingLSK') : ''
        obj.txtOI = getValue(thisObj, 'txtOI') !== "" && getValue(thisObj, 'txtOI') !== undefined ? getValue(thisObj, 'txtOI') : ''
        obj.txtOKDamageRiding = getValue(thisObj, 'txtOKDamageRiding') !== "" && getValue(thisObj, 'txtOKDamageRiding') !== undefined ? getValue(thisObj, 'txtOKDamageRiding') : ''
        obj.txtOKFallThru = getValue(thisObj, 'txtOKFallThru') !== "" && getValue(thisObj, 'txtOKFallThru') !== undefined ? getValue(thisObj, 'txtOKFallThru') : ''
        obj.txtOKRidingGr = getValue(thisObj, 'txtOKRidingGr') !== "" && getValue(thisObj, 'txtOKRidingGr') !== undefined ? getValue(thisObj, 'txtOKRidingGr') : ''
        obj.txtOKRidingPct = getValue(thisObj, 'txtOKRidingPct') !== "" && getValue(thisObj, 'txtOKRidingPct') !== undefined ? getValue(thisObj, 'txtOKRidingPct') : ''
        obj.txtOtherFM = getValue(thisObj, 'txtOtherFM') !== "" && getValue(thisObj, 'txtOtherFM') !== undefined ? getValue(thisObj, 'txtOtherFM') : ''
        obj.txtPerG = getValue(thisObj, 'txtPerG') !== "" && getValue(thisObj, 'txtPerG') !== undefined ? getValue(thisObj, 'txtPerG') : ''
        obj.txtPittingDamage = getValue(thisObj, 'txtPittingDamage') !== "" && getValue(thisObj, 'txtPittingDamage') !== undefined ? getValue(thisObj, 'txtPittingDamage') : ''
        obj.txtPittingDamagePct = getValue(thisObj, 'txtPittingDamagePct') !== "" && getValue(thisObj, 'txtPittingDamagePct') !== undefined ? getValue(thisObj, 'txtPittingDamagePct') : ''
        obj.txtPremiums = getValue(thisObj, 'txtPremiums') !== "" && getValue(thisObj, 'txtPremiums') !== undefined ? getValue(thisObj, 'txtPremiums') : ''
        obj.txtProbingPattern = getValue(thisObj, 'txtProbingPattern') !== "" && getValue(thisObj, 'txtProbingPattern') !== undefined ? getValue(thisObj, 'txtProbingPattern') : ''
        obj.txtProceeds = getValue(thisObj, 'txtProceeds') !== "" && getValue(thisObj, 'txtProceeds') !== undefined ? getValue(thisObj, 'txtProceeds') : ''
        obj.txtSeal = getValue(thisObj, 'txtSeal') !== "" && getValue(thisObj, 'txtSeal') !== undefined ? getValue(thisObj, 'txtSeal') : ''
        obj.txtSeedMeetingSpec = getValue(thisObj, 'txtSeedMeetingSpec') !== "" && getValue(thisObj, 'txtSeedMeetingSpec') !== undefined ? getValue(thisObj, 'txtSeedMeetingSpec') : ''
        obj.txtSegValue = getValue(thisObj, 'txtSegValue') !== "" && getValue(thisObj, 'txtSegValue') !== undefined ? getValue(thisObj, 'txtSegValue') : ''
        obj.txtSharePercentage1 = getValue(thisObj, 'txtSharePercentage1') !== "" && getValue(thisObj, 'txtSharePercentage1') !== undefined ? getValue(thisObj, 'txtSharePercentage1') : ''
        obj.txtSharePercentage2 = getValue(thisObj, 'txtSharePercentage2') !== "" && getValue(thisObj, 'txtSharePercentage2') !== undefined ? getValue(thisObj, 'txtSharePercentage2') : ''
        obj.txtSharePercentage3 = getValue(thisObj, 'txtSharePercentage3') !== "" && getValue(thisObj, 'txtSharePercentage3') !== undefined ? getValue(thisObj, 'txtSharePercentage3') : ''
        obj.txtSharePercentage4 = getValue(thisObj, 'txtSharePercentage4') !== "" && getValue(thisObj, 'txtSharePercentage4') !== undefined ? getValue(thisObj, 'txtSharePercentage4') : ''
        obj.txtSharePercentage5 = getValue(thisObj, 'txtSharePercentage5') !== "" && getValue(thisObj, 'txtSharePercentage5') !== undefined ? getValue(thisObj, 'txtSharePercentage5') : ''
        obj.txtSharePercentage6 = getValue(thisObj, 'txtSharePercentage6') !== "" && getValue(thisObj, 'txtSharePercentage6') !== undefined ? getValue(thisObj, 'txtSharePercentage6') : ''
        obj.txtSharePercentage7 = getValue(thisObj, 'txtSharePercentage7') !== "" && getValue(thisObj, 'txtSharePercentage7') !== undefined ? getValue(thisObj, 'txtSharePercentage7') : ''
        obj.txtSharePercentage8 = getValue(thisObj, 'txtSharePercentage8') !== "" && getValue(thisObj, 'txtSharePercentage8') !== undefined ? getValue(thisObj, 'txtSharePercentage8') : ''
        obj.txtSharePercentage9 = getValue(thisObj, 'txtSharePercentage9') !== "" && getValue(thisObj, 'txtSharePercentage9') !== undefined ? getValue(thisObj, 'txtSharePercentage9') : ''
        obj.txtSharePercentage10 = getValue(thisObj, 'txtSharePercentage10') !== "" && getValue(thisObj, 'txtSharePercentage10') !== undefined ? getValue(thisObj, 'txtSharePercentage10') : ''
        obj.txtSharePercentage11 = getValue(thisObj, 'txtSharePercentage11') !== "" && getValue(thisObj, 'txtSharePercentage11') !== undefined ? getValue(thisObj, 'txtSharePercentage11') : ''
        obj.txtSharePercentage12 = getValue(thisObj, 'txtSharePercentage12') !== "" && getValue(thisObj, 'txtSharePercentage12') !== undefined ? getValue(thisObj, 'txtSharePercentage12') : ''
        obj.txtSharePercentage13 = getValue(thisObj, 'txtSharePercentage13') !== "" && getValue(thisObj, 'txtSharePercentage13') !== undefined ? getValue(thisObj, 'txtSharePercentage13') : ''
        obj.txtSharePercentage14 = getValue(thisObj, 'txtSharePercentage14') !== "" && getValue(thisObj, 'txtSharePercentage14') !== undefined ? getValue(thisObj, 'txtSharePercentage14') : ''
        obj.txtSharePercentage15 = getValue(thisObj, 'txtSharePercentage15') !== "" && getValue(thisObj, 'txtSharePercentage15') !== undefined ? getValue(thisObj, 'txtSharePercentage15') : ''
        obj.txtSharePercentage16 = getValue(thisObj, 'txtSharePercentage16') !== "" && getValue(thisObj, 'txtSharePercentage16') !== undefined ? getValue(thisObj, 'txtSharePercentage16') : ''
        obj.txtSharePercentage17 = getValue(thisObj, 'txtSharePercentage17') !== "" && getValue(thisObj, 'txtSharePercentage17') !== undefined ? getValue(thisObj, 'txtSharePercentage17') : ''
        obj.txtSharePercentage18 = getValue(thisObj, 'txtSharePercentage18') !== "" && getValue(thisObj, 'txtSharePercentage18') !== undefined ? getValue(thisObj, 'txtSharePercentage18') : ''
        obj.txtSharePercentage19 = getValue(thisObj, 'txtSharePercentage19') !== "" && getValue(thisObj, 'txtSharePercentage19') !== undefined ? getValue(thisObj, 'txtSharePercentage19') : ''
        obj.txtSharePercentage20 = getValue(thisObj, 'txtSharePercentage20') !== "" && getValue(thisObj, 'txtSharePercentage20') !== undefined ? getValue(thisObj, 'txtSharePercentage20') : ''
        obj.txtSMK1Gr = getValue(thisObj, 'txtSMK1Gr') !== "" && getValue(thisObj, 'txtSMK1Gr') !== undefined ? getValue(thisObj, 'txtSMK1Gr') : ''
        obj.txtSMK1Pct = getValue(thisObj, 'txtSMK1Pct') !== "" && getValue(thisObj, 'txtSMK1Pct') !== undefined ? getValue(thisObj, 'txtSMK1Pct') : ''
        obj.txtSMK2Gr = getValue(thisObj, 'txtSMK2Gr') !== "" && getValue(thisObj, 'txtSMK2Gr') !== undefined ? getValue(thisObj, 'txtSMK2Gr') : ''
        obj.txtSMK2Pct = getValue(thisObj, 'txtSMK2Pct') !== "" && getValue(thisObj, 'txtSMK2Pct') !== undefined ? getValue(thisObj, 'txtSMK2Pct') : ''
        obj.txtSMK3Gr = getValue(thisObj, 'txtSMK3Gr') !== "" && getValue(thisObj, 'txtSMK3Gr') !== undefined ? getValue(thisObj, 'txtSMK3Gr') : ''
        obj.txtSMK3Pct = getValue(thisObj, 'txtSMK3Pct') !== "" && getValue(thisObj, 'txtSMK3Pct') !== undefined ? getValue(thisObj, 'txtSMK3Pct') : ''
        obj.txtSMK4Gr = getValue(thisObj, 'txtSMK4Gr') !== "" && getValue(thisObj, 'txtSMK4Gr') !== undefined ? getValue(thisObj, 'txtSMK4Gr') : ''
        obj.txtSMK4Pct = getValue(thisObj, 'txtSMK4Pct') !== "" && getValue(thisObj, 'txtSMK4Pct') !== undefined ? getValue(thisObj, 'txtSMK4Pct') : ''
        obj.txtSoundSplits = getValue(thisObj, 'txtSoundSplits') !== "" && getValue(thisObj, 'txtSoundSplits') !== undefined ? getValue(thisObj, 'txtSoundSplits') : ''
        obj.txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct') !== "" && getValue(thisObj, 'txtSoundSplitsPct') !== undefined ? getValue(thisObj, 'txtSoundSplitsPct') : '0'
        obj.txtSts = getValue(thisObj, 'txtSts') !== "" && getValue(thisObj, 'txtSts') !== undefined ? getValue(thisObj, 'txtSts') : ''
        obj.txtSupportPercent = getValue(thisObj, 'txtSupportPercent') !== "" && getValue(thisObj, 'txtSupportPercent') !== undefined ? getValue(thisObj, 'txtSupportPercent') : ''
        obj.txtTckt = getValue(thisObj, 'txtTckt') !== "" && getValue(thisObj, 'txtTckt') !== undefined ? getValue(thisObj, 'txtTckt') : ''
        obj.txtTKCLbs = getValue(thisObj, 'txtTKCLbs') !== "" && getValue(thisObj, 'txtTKCLbs') !== undefined ? getValue(thisObj, 'txtTKCLbs') : ''
        obj.txtTotal = getValue(thisObj, 'txtTotal') !== "" && getValue(thisObj, 'txtTotal') !== undefined ? getValue(thisObj, 'txtTotal') : ''
        obj.txtTotalDamage = getValue(thisObj, 'txtTotalDamage') !== "" && getValue(thisObj, 'txtTotalDamage') !== undefined ? getValue(thisObj, 'txtTotalDamage') : ''
        obj.txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct') !== "" && getValue(thisObj, 'txtTotalDamagePct') !== undefined ? getValue(thisObj, 'txtTotalDamagePct') : '0.00'
        obj.txtTotalDiscountsPerPound = getValue(thisObj, 'txtTotalDiscountsPerPound') !== "" && getValue(thisObj, 'txtTotalDiscountsPerPound') !== undefined ? getValue(thisObj, 'txtTotalDiscountsPerPound') : ''
        obj.txtTotalDiscountsPerTon = getValue(thisObj, 'txtTotalDiscountsPerTon') !== "" && getValue(thisObj, 'txtTotalDiscountsPerTon') !== undefined ? getValue(thisObj, 'txtTotalDiscountsPerTon') : ''
        obj.txtTotalELKDamage = getValue(thisObj, 'txtTotalELKDamage') !== "" && getValue(thisObj, 'txtTotalELKDamage') !== undefined ? getValue(thisObj, 'txtTotalELKDamage') : ''
        obj.txtTotalELKDamagePct = getValue(thisObj, 'txtTotalELKDamagePct') !== "" && getValue(thisObj, 'txtTotalELKDamagePct') !== undefined ? getValue(thisObj, 'txtTotalELKDamagePct') : ''
        obj.txtTotalKernels = getValue(thisObj, 'txtTotalKernels') !== "" && getValue(thisObj, 'txtTotalKernels') !== undefined ? getValue(thisObj, 'txtTotalKernels') : ''
        obj.txtTotalKernelsAndHulls = getValue(thisObj, 'txtTotalKernelsAndHulls') !== "" && getValue(thisObj, 'txtTotalKernelsAndHulls') !== undefined ? getValue(thisObj, 'txtTotalKernelsAndHulls') : '0.00'
        obj.txtTotalLoadVal = getValue(thisObj, 'txtTotalLoadVal') !== "" && getValue(thisObj, 'txtTotalLoadVal') !== undefined ? getValue(thisObj, 'txtTotalLoadVal') : ''
        obj.txtTotalLoadValue = getValue(thisObj, 'txtTotalLoadValue') !== "" && getValue(thisObj, 'txtTotalLoadValue') !== undefined ? getValue(thisObj, 'txtTotalLoadValue') : ''
        obj.txtTotalOK = getValue(thisObj, 'txtTotalOK') !== "" && getValue(thisObj, 'txtTotalOK') !== undefined ? getValue(thisObj, 'txtTotalOK') : ''
        obj.txtTotalOKPct = getValue(thisObj, 'txtTotalOKPct') !== "" && getValue(thisObj, 'txtTotalOKPct') !== undefined ? getValue(thisObj, 'txtTotalOKPct') : ''
        obj.txtTotalPerPound = getValue(thisObj, 'txtTotalPerPound') !== "" && getValue(thisObj, 'txtTotalPerPound') !== undefined ? getValue(thisObj, 'txtTotalPerPound') : ''
        obj.txtTotalPerTon = getValue(thisObj, 'txtTotalPerTon') !== "" && getValue(thisObj, 'txtTotalPerTon') !== undefined ? getValue(thisObj, 'txtTotalPerTon') : ''
        obj.txtTotalSLK = getValue(thisObj, 'txtTotalSLK') !== "" && getValue(thisObj, 'txtTotalSLK') !== undefined ? getValue(thisObj, 'txtTotalSLK') : ''
        obj.txtTotalSLKPct = getValue(thisObj, 'txtTotalSLKPct') !== "" && getValue(thisObj, 'txtTotalSLKPct') !== undefined ? getValue(thisObj, 'txtTotalSLKPct') : '0.00'
        obj.txtTotalSMK = getValue(thisObj, 'txtTotalSMK') !== "" && getValue(thisObj, 'txtTotalSMK') !== undefined ? getValue(thisObj, 'txtTotalSMK') : '0.00'
        obj.txtTotalSMKRScreen = getValue(thisObj, 'txtTotalSMKRScreen') !== "" && getValue(thisObj, 'txtTotalSMKRScreen') !== undefined ? getValue(thisObj, 'txtTotalSMKRScreen') : ''
        obj.txtTotalSMKRScreenPct = getValue(thisObj, 'txtTotalSMKRScreenPct') !== "" && getValue(thisObj, 'txtTotalSMKRScreenPct') !== undefined ? getValue(thisObj, 'txtTotalSMKRScreenPct') : '0'
        obj.txtTradeInspectSC95 = getValue(thisObj, 'txtTradeInspectSC95') !== "" && getValue(thisObj, 'txtTradeInspectSC95') !== undefined ? getValue(thisObj, 'txtTradeInspectSC95') : ''
        obj.txtTradeSettle1007 = getValue(thisObj, 'txtTradeSettle1007') !== "" && getValue(thisObj, 'txtTradeSettle1007') !== undefined ? getValue(thisObj, 'txtTradeSettle1007') : ""
        obj.txtValPerPoundExclSLK = getValue(thisObj, 'txtValPerPoundExclSLK') !== "" && getValue(thisObj, 'txtValPerPoundExclSLK') !== undefined ? getValue(thisObj, 'txtValPerPoundExclSLK') : ''
        obj.txtValPerPoundInclSLK = getValue(thisObj, 'txtValPerPoundInclSLK') !== "" && getValue(thisObj, 'txtValPerPoundInclSLK') !== undefined ? getValue(thisObj, 'txtValPerPoundInclSLK') : ''
        obj.txtVehicleWt = getValue(thisObj, 'txtVehicleWt') !== "" && getValue(thisObj, 'txtVehicleWt') !== undefined ? getValue(thisObj, 'txtVehicleWt') : ''
        obj.txtVendor1 = getValue(thisObj, 'txtVendor1') !== "" && getValue(thisObj, 'txtVendor1') !== undefined ? getValue(thisObj, 'txtVendor1') : ''
        obj.txtVendor2 = getValue(thisObj, 'txtVendor2') !== "" && getValue(thisObj, 'txtVendor2') !== undefined ? getValue(thisObj, 'txtVendor2') : ''
        obj.txtVendor3 = getValue(thisObj, 'txtVendor3') !== "" && getValue(thisObj, 'txtVendor3') !== undefined ? getValue(thisObj, 'txtVendor3') : ''
        obj.txtVendor4 = getValue(thisObj, 'txtVendor4') !== "" && getValue(thisObj, 'txtVendor4') !== undefined ? getValue(thisObj, 'txtVendor4') : ''
        obj.txtVendor5 = getValue(thisObj, 'txtVendor5') !== "" && getValue(thisObj, 'txtVendor5') !== undefined ? getValue(thisObj, 'txtVendor5') : ''
        obj.txtVendor6 = getValue(thisObj, 'txtVendor6') !== "" && getValue(thisObj, 'txtVendor6') !== undefined ? getValue(thisObj, 'txtVendor6') : ''
        obj.txtVendor7 = getValue(thisObj, 'txtVendor7') !== "" && getValue(thisObj, 'txtVendor7') !== undefined ? getValue(thisObj, 'txtVendor7') : ''
        obj.txtVendor8 = getValue(thisObj, 'txtVendor8') !== "" && getValue(thisObj, 'txtVendor8') !== undefined ? getValue(thisObj, 'txtVendor8') : ''
        obj.txtVendor9 = getValue(thisObj, 'txtVendor9') !== "" && getValue(thisObj, 'txtVendor9') !== undefined ? getValue(thisObj, 'txtVendor9') : ''
        obj.txtVendor10 = getValue(thisObj, 'txtVendor10') !== "" && getValue(thisObj, 'txtVendor10') !== undefined ? getValue(thisObj, 'txtVendor10') : ''
        obj.txtVendor11 = getValue(thisObj, 'txtVendor11') !== "" && getValue(thisObj, 'txtVendor11') !== undefined ? getValue(thisObj, 'txtVendor11') : ''
        obj.txtVendor12 = getValue(thisObj, 'txtVendor12') !== "" && getValue(thisObj, 'txtVendor12') !== undefined ? getValue(thisObj, 'txtVendor12') : ''
        obj.txtVendor13 = getValue(thisObj, 'txtVendor13') !== "" && getValue(thisObj, 'txtVendor13') !== undefined ? getValue(thisObj, 'txtVendor13') : ''
        obj.txtVendor14 = getValue(thisObj, 'txtVendor14') !== "" && getValue(thisObj, 'txtVendor14') !== undefined ? getValue(thisObj, 'txtVendor14') : ''
        obj.txtVendor15 = getValue(thisObj, 'txtVendor15') !== "" && getValue(thisObj, 'txtVendor15') !== undefined ? getValue(thisObj, 'txtVendor15') : ''
        obj.txtVendor16 = getValue(thisObj, 'txtVendor16') !== "" && getValue(thisObj, 'txtVendor16') !== undefined ? getValue(thisObj, 'txtVendor16') : ''
        obj.txtVendor17 = getValue(thisObj, 'txtVendor17') !== "" && getValue(thisObj, 'txtVendor17') !== undefined ? getValue(thisObj, 'txtVendor17') : ''
        obj.txtVendor18 = getValue(thisObj, 'txtVendor18') !== "" && getValue(thisObj, 'txtVendor18') !== undefined ? getValue(thisObj, 'txtVendor18') : ''
        obj.txtVendor19 = getValue(thisObj, 'txtVendor19') !== "" && getValue(thisObj, 'txtVendor19') !== undefined ? getValue(thisObj, 'txtVendor19') : ''
        obj.txtVendor20 = getValue(thisObj, 'txtVendor20') !== "" && getValue(thisObj, 'txtVendor20') !== undefined ? getValue(thisObj, 'txtVendor20') : ''
        obj.txtVicamReading = getValue(thisObj, 'txtVicamReading') !== "" && getValue(thisObj, 'txtVicamReading') !== undefined ? getValue(thisObj, 'txtVicamReading') : ''
        obj.txtWeightLessFM = getValue(thisObj, 'txtWeightLessFM') !== "" && getValue(thisObj, 'txtWeightLessFM') !== undefined ? getValue(thisObj, 'txtWeightLessFM') : ''
        obj.txtWormDamage = getValue(thisObj, 'txtWormDamage') !== "" && getValue(thisObj, 'txtWormDamage') !== undefined ? getValue(thisObj, 'txtWormDamage') : ''
        obj.txtWtIncludingVehicle = getValue(thisObj, 'txtWtIncludingVehicle') !== "" && getValue(thisObj, 'txtWtIncludingVehicle') !== undefined ? getValue(thisObj, 'txtWtIncludingVehicle') : ''
        var rv = {};
        for (let j = 0; j < 20; j++) {
          let settxtvendor = "txtVendor" + (j + 1)
          let setremit = "ddRemitTo" + (j + 1)
          let setpercendate = "txtSharePercentage" + (j + 1)
          let setgrosswt = "gross_wt12" + (j + 1)
          let setnetwt = "net_wt12" + (j + 1)
          let setlswt = "lsk_wt12" + (j + 1)
          let setbasisgradeamt = "basis_grade_amt12" + (j + 1)
          let gettxvendor = '', getremit = '', getpercentage = '', getgrosswt = '', getnetwt = '', getlskwt = ''
          gettxvendor = getValue(thisObj, settxtvendor)
          getremit = getValue(thisObj, setremit)
          getpercentage = getValue(thisObj, setpercendate)
          getgrosswt = obj.txtGrossWeight
          getnetwt = obj.txtNetWeight
          getlskwt = obj.txtLSK

          rv[settxtvendor] = gettxvendor.toString() !== undefined ? gettxvendor.toString() : ''
          rv[setremit] = getremit !== undefined ? getremit : ''
          rv[setpercendate] = getpercentage !== undefined ? getpercentage : ''
          rv[setgrosswt] = getpercentage !== undefined ? (parseInt(getgrosswt) * (parseFloat(getpercentage) * 0.01)) : ''
          rv[setnetwt] = getpercentage !== undefined ? (parseInt(getnetwt) * (parseFloat(getpercentage) * 0.01)) : ''
          rv[setlswt] = getpercentage !== undefined ? (parseInt(getlskwt) * (parseFloat(getpercentage) * 0.01)) : ''
          rv[setbasisgradeamt] = getpercentage !== undefined ? (parseInt(rv[setgrosswt]) * (parseFloat(getnetwt) * 0.01)) : ''

        }
        bSaveInspection(obj, [rv], 'DELETE')

      }

    }
    catch (err) {

    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  const onbtnCloseClick = () => {
    try {
      setData(thisObj, "ScaleTicketData", null);
      setData(thisObj, "TradeSetteleProfileClose", true)
      setData(thisObj, "TradesettlevendorClose", true)
      document.getElementById("Settlements_TradeSettleProfilePopUp").childNodes[0].click();
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js


      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {

      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const onBuyingPointChange = () => {
    try {

      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPeanutType'];
      let pnut_type_id = null
      if (SelectedPeanutTypeValue !== "" && SelectedPeanutTypeValue !== undefined) {
        pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      }

      let BuyingPointList = thisObj.state['ddShipFromToLocation'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddShipFromToLocation'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      bFillWarehouseBinList()
      AccessCheck(buyingPointId)
      //Bind Peanut Variety based on Buying Point and Peanut Type
      bFillVarietyList(buyingPointId, pnut_type_id)
      let locationDetailsById = location.find(x => x.buy_pt_id == SelectedBuyingPointValue);
      const area_id = locationDetailsById.area_id;
      fetchRetrieveAreaControlDetail(area_id)
    }
    catch (err) {
    }

  };
  thisObj.onddShipFromToLocationChange = onBuyingPointChange;

  const AccessCheck = async (buyingpoint) => {
    if (await GetAccessLevel('NULL', 'D', buyingpoint) == true) {
      setPermission("D");
    }
    else if (await GetAccessLevel('NULL', 'U', buyingpoint) == true) {
      setPermission("U")
    }
    else {
      setPermission("I")
    }
  }

  const onddCategoryChange = () => {
    try {

      if (ddCategory == "IDT") {
        enable(thisObj, 'ddIDTfromtoCompany')
        enable(thisObj, 'ddIDTfromtoLocation')
        document.getElementsByClassName('txtDTWhseBinNum')[0].childNodes[0].childNodes[0].innerText = 'Whse#/B'
        // document.getElementById("ddIDTfromtoLocation").disabled = true;
        // disable(thisObj,'ddIDTfromtoLocation')
      } else {
        disable(thisObj, 'ddIDTfromtoCompany')
        disable(thisObj, 'ddIDTfromtoLocation')
        document.getElementsByClassName('txtDTWhseBinNum')[0].childNodes[0].childNodes[0].innerText = ''
      }
      if (!formDirty) {
        setFormDirty(true)
      }
    }
    catch (err) {
    }

  };
  thisObj.onddCategoryChange = onddCategoryChange;

  const onddPeanutTypeChange = async() => {
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPeanutType'];
      let SelectedBuyingPointValue = thisObj.values['ddShipFromToLocation'];
      let smk1Guage = PeanutTypelist.filter(elem => elem.key === SelectedPeanutTypeValue);
      let objVal = {}
      objVal['txtSMK1Gr'] = smk1Guage[0].smk1Guage
      objVal['txtSMK2Gr'] = smk1Guage[0].smk2Guage
      objVal['txtSMK3Gr'] = smk1Guage[0].smk3Guage
      objVal['txtSMK4Gr'] = smk1Guage[0].smk4Guage
      await setFieldValues(thisObj, objVal, true);
      await setLoading(true)
      await bFillVarietyList(SelectedBuyingPointValue, SelectedPeanutTypeValue)
      await bFillWarehouseBinList()
      let cboIDTBuyingPoint = getValue(thisObj, 'ddIDTfromtoLocation')
      if (cboIDTBuyingPoint !== '') {
        bFillIDTWarehouseBinList()
      }
      await EnableDisableGradeEntry()
      await setLoading(false)
    }
    catch (err) {

    }
  };
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange;

  const onddPeanutVarietyChange = () => {
    try {
      bFillWarehouseBinList()
      let cboIDTBuyingPoint = getValue(thisObj, 'ddIDTfromtoLocation')
      if (cboIDTBuyingPoint !== '') {
        bFillIDTWarehouseBinList()
      }
    }
    catch (err) {

    }
  };
  thisObj.onddPeanutVarietyChange = onddPeanutVarietyChange;

  const onddFrimBasisFlatChange = () => {
    try {
      let FIRM_BASIS_OPT = getValue(thisObj, 'ddFrimBasisFlat')
      if (FIRM_BASIS_OPT !== "F") {
        hide(thisObj, 'radioGradePricingMethod')
        setValue(thisObj, 'radioGradePricingMethod', 'FULL')
      } else {
        show(thisObj, 'radioGradePricingMethod')
      }

    }
    catch (err) {

    }
  };
  thisObj.onddFrimBasisFlatChange = onddFrimBasisFlatChange

  const onradioGradePricingMethodChange = async () => {
    try {
      await setLoading(true)
      await EnableDisableGradeEntry()
      let radioGradePricingMethod = getValue(thisObj, 'radioGradePricingMethod')
      if (radioGradePricingMethod == "TKC") {
        //  Disablevalue
        clearValue(thisObj, 'txtTotalSMKRScreenPct', '')
        clearValue(thisObj, 'txtSoundSplitsPct', '')
        clearValue(thisObj, 'txtTotalOKPct', '')
        clearValue(thisObj, 'txtHullsPct', '')
        clearValue(thisObj, 'txtFreezeDamagePct', '')
        clearValue(thisObj, 'txtConcealedRMDPct', '')
        clearValue(thisObj, 'txtCrackedBrokenPct', '')
        clearValue(thisObj, 'txtDiscoloredShellsPct', '')
        clearValue(thisObj, 'txtHullBrightnessPct', '')
        clearValue(thisObj, 'txtJumboPct', '')
        clearValue(thisObj, 'txtKernRidingELKPct', '')
        clearValue(thisObj, 'txtTotalELKDamagePct', '')
        clearValue(thisObj, 'txtNetELKPct', '')
        clearValue(thisObj, 'txtKernRidingPrescribePct', '')
        clearValue(thisObj, 'txtForeignMaterialPct', '')
        clearValue(thisObj, 'txtTotalSLKPct', '')
        clearValue(thisObj, 'txtFancyPct', '')
        clearValue(thisObj, 'txtPittingDamagePct', '')
        clearValue(thisObj, 'txtTotalDamagePct', '')
        setValue(thisObj, 'txtTotalSMK', '0.00')
        setValue(thisObj, 'txtTotalKernels', '0.00')
        setValue(thisObj, 'txtTotalKernelsAndHulls', '0.00')

        // Clear the value
        clearValue(thisObj, 'txtKernelValuePerTon', '')
        clearValue(thisObj, 'txtKernelValuePerPound', '')
        clearValue(thisObj, 'txtELKPremiumPerTon', '')
        clearValue(thisObj, 'txtELKPremiumPerPound', '')
        clearValue(thisObj, 'txtTotalPerTon', '')
        clearValue(thisObj, 'txtTotalPerPound', '')
        clearValue(thisObj, 'txtDamagePerTon', '')
        clearValue(thisObj, 'txtDamagePerPound', '')
        clearValue(thisObj, 'txtAccessFMPerTon', '')
        clearValue(thisObj, 'txtAccessFMPerPound', '')
        clearValue(thisObj, 'txtExcessSplitsPerTon', '')
        clearValue(thisObj, 'txtExcessSplitsPerPound', '')
        clearValue(thisObj, 'txtAFlavusPerTon', '')
        clearValue(thisObj, 'txtAFlavusPerPound', '')
        clearValue(thisObj, 'txtTotalDiscountsPerTon', '')
        clearValue(thisObj, 'txtTotalDiscountsPerPound', '')
        clearValue(thisObj, 'txtNetValPerTonExclSLK', '')
        clearValue(thisObj, 'txtValPerPoundExclSLK', '')
        clearValue(thisObj, 'txtOI', '')
        // setValue(thisObj, 'txtValPerPoundInclSLK', retrieveValueCalculationList[0].lsk_per_pounds)
        clearValue(thisObj, 'txtH', '')
        clearValue(thisObj, 'txtTotalLoadVal', '')
        clearValue(thisObj, 'txtPerG', '')
      }
      await setLoading(false)
    }
    catch (err) {

    }
  };

  thisObj.onradioGradePricingMethodChange = onradioGradePricingMethodChange

  const onradioGramsPercentageChange = () => {
    try {
      let radioGramsPercentage = getValue(thisObj, 'radioGramsPercentage')
      if (radioGramsPercentage == "2") {
        if (confirm("This will clear gram fields. Are you sure you want to switch to percents?") == true) {
          EnableDisableGradeEntry()
        }
      } else {
        if (confirm("This will clear percent fields.  Are you sure you want to switch to grams?") == true) {
          EnableDisableGradeEntry()
        }
      }

    }
    catch (err) {

    }
  };
  thisObj.onradioGramsPercentageChange = onradioGramsPercentageChange

  const onddSegChange = () => {
    try {
      let dd_Seg = getValue(thisObj, 'ddSeg')

      if (dd_Seg == "1") {
        setValue(thisObj, 'ddEdibleOil', 'E')
        disable(thisObj, 'ddEdibleOil')
        bFillEdibleOilList(false)
        // document.getElementById("ddGeneration").disabled = false;

      } else {
        setValue(thisObj, 'ddEdibleOil', 'O')
        enable(thisObj, 'ddEdibleOil')
        bFillEdibleOilList(true)
        // document.getElementById("ddGeneration").disabled = true;
      }
      bFillWarehouseBinList()
      let cboIDTBuyingPoint = getValue(thisObj, 'ddIDTfromtoLocation')
      if (cboIDTBuyingPoint !== '') {
        bFillIDTWarehouseBinList()
      }
      EnableDisableVicam()

    }
    catch (err) {

    }
  };
  thisObj.onddSegChange = onddSegChange

  const onddOleicChange = () => {
    try {
      bFillWarehouseBinList()
      let cboIDTBuyingPoint = getValue(thisObj, 'ddIDTfromtoLocation')
      if (cboIDTBuyingPoint !== '') {
        bFillIDTWarehouseBinList()
      }
    }
    catch (err) {

    }
  };
  thisObj.onddOleicChange = onddOleicChange

  const onddEdibleOilChange = () => {
    try {

      bFillWarehouseBinList()
      let cboIDTBuyingPoint = getValue(thisObj, 'ddIDTfromtoLocation')
      if (cboIDTBuyingPoint !== '') {
        bFillIDTWarehouseBinList()
      }

    }
    catch (err) {

    }
  };
  thisObj.onddEdibleOilChange = onddEdibleOilChange

  const onddGenerationChange = () => {
    try {

      bFillWarehouseBinList()
      let cboIDTBuyingPoint = getValue(thisObj, 'ddIDTfromtoLocation')
      if (cboIDTBuyingPoint !== '') {
        bFillIDTWarehouseBinList()
      }
      let ddGeneration = getValue(thisObj, 'ddGeneration')
      if (ddGeneration == '') {
        setValue(thisObj, 'txtSeedMeetingSpec', '')
      }
    }
    catch (err) {

    }
  };
  thisObj.onddGenerationChange = onddGenerationChange

  const getallvalue = (SAction, blWtIncVehicle, dblVehicleWt) => {
    let obj = {}
    obj.chkboxPrintSeg = getValue(thisObj, 'chkboxPrintSeg') !== undefined ? getValue(thisObj, 'chkboxPrintSeg') : ''
    obj.chkboxReGrading = getValue(thisObj, 'chkboxReGrading') !== "" && getValue(thisObj, 'chkboxReGrading') !== undefined ? getValue(thisObj, 'chkboxReGrading') : ''
    obj.ddCategory = getValue(thisObj, 'ddCategory') !== "" && getValue(thisObj, 'ddCategory') !== undefined ? getValue(thisObj, 'ddCategory') : ''
    obj.ddEdibleOil = getValue(thisObj, 'ddEdibleOil') !== "" && getValue(thisObj, 'ddEdibleOil') !== undefined ? getValue(thisObj, 'ddEdibleOil') : ''
    obj.ddGeneration = getValue(thisObj, 'ddGeneration') !== "" && getValue(thisObj, 'ddGeneration') !== undefined ? getValue(thisObj, 'ddGeneration') : ''
    obj.ddIDTfromtoCompany = getValue(thisObj, 'ddIDTfromtoCompany') !== "" && getValue(thisObj, 'ddIDTfromtoCompany') !== undefined ? getValue(thisObj, 'ddIDTfromtoCompany') : ''
    obj.ddIDTfromtoLocation = getValue(thisObj, 'ddIDTfromtoLocation') !== "" && getValue(thisObj, 'ddIDTfromtoLocation') !== undefined ? getValue(thisObj, 'ddIDTfromtoLocation') : ''
    obj.ddOleic = getValue(thisObj, 'ddOleic') !== "" && getValue(thisObj, 'ddOleic') !== undefined ? getValue(thisObj, 'ddOleic') : ''
    obj.ddPeanutType = getValue(thisObj, 'ddPeanutType') !== "" && getValue(thisObj, 'ddPeanutType') !== undefined ? getValue(thisObj, 'ddPeanutType') : ''
    obj.ddPeanutVariety = getValue(thisObj, 'ddPeanutVariety') !== "" && getValue(thisObj, 'ddPeanutVariety') !== undefined ? getValue(thisObj, 'ddPeanutVariety') : ''
    obj.ddRemitTo1 = getValue(thisObj, 'ddRemitTo1') !== "" && getValue(thisObj, 'ddRemitTo1') !== undefined ? getValue(thisObj, 'ddRemitTo1') : ''
    obj.ddRemitTo2 = getValue(thisObj, 'ddRemitTo2') !== "" && getValue(thisObj, 'ddRemitTo2') !== undefined ? getValue(thisObj, 'ddRemitTo2') : ''
    obj.ddRemitTo3 = getValue(thisObj, 'ddRemitTo3') !== "" && getValue(thisObj, 'ddRemitTo3') !== undefined ? getValue(thisObj, 'ddRemitTo3') : ''
    obj.ddRemitTo4 = getValue(thisObj, 'ddRemitTo4') !== "" && getValue(thisObj, 'ddRemitTo4') !== undefined ? getValue(thisObj, 'ddRemitTo4') : ''
    obj.ddRemitTo5 = getValue(thisObj, 'ddRemitTo5') !== "" && getValue(thisObj, 'ddRemitTo5') !== undefined ? getValue(thisObj, 'ddRemitTo5') : ''
    obj.ddRemitTo6 = getValue(thisObj, 'ddRemitTo6') !== "" && getValue(thisObj, 'ddRemitTo6') !== undefined ? getValue(thisObj, 'ddRemitTo6') : ''
    obj.ddRemitTo7 = getValue(thisObj, 'ddRemitTo7') !== "" && getValue(thisObj, 'ddRemitTo7') !== undefined ? getValue(thisObj, 'ddRemitTo7') : ''
    obj.ddRemitTo8 = getValue(thisObj, 'ddRemitTo8') !== "" && getValue(thisObj, 'ddRemitTo8') !== undefined ? getValue(thisObj, 'ddRemitTo8') : ''
    obj.ddRemitTo9 = getValue(thisObj, 'ddRemitTo9') !== "" && getValue(thisObj, 'ddRemitTo9') !== undefined ? getValue(thisObj, 'ddRemitTo9') : ''
    obj.ddRemitTo10 = getValue(thisObj, 'ddRemitTo10') !== "" && getValue(thisObj, 'ddRemitTo10') !== undefined ? getValue(thisObj, 'ddRemitTo10') : ''
    obj.ddRemitTo11 = getValue(thisObj, 'ddRemitTo11') !== "" && getValue(thisObj, 'ddRemitTo11') !== undefined ? getValue(thisObj, 'ddRemitTo11') : ''
    obj.ddRemitTo12 = getValue(thisObj, 'ddRemitTo12') !== "" && getValue(thisObj, 'ddRemitTo12') !== undefined ? getValue(thisObj, 'ddRemitTo12') : ''
    obj.ddRemitTo13 = getValue(thisObj, 'ddRemitTo13') !== "" && getValue(thisObj, 'ddRemitTo13') !== undefined ? getValue(thisObj, 'ddRemitTo13') : ''
    obj.ddRemitTo14 = getValue(thisObj, 'ddRemitTo14') !== "" && getValue(thisObj, 'ddRemitTo14') !== undefined ? getValue(thisObj, 'ddRemitTo14') : ''
    obj.ddRemitTo15 = getValue(thisObj, 'ddRemitTo15') !== "" && getValue(thisObj, 'ddRemitTo15') !== undefined ? getValue(thisObj, 'ddRemitTo15') : ''
    obj.ddRemitTo16 = getValue(thisObj, 'ddRemitTo16') !== "" && getValue(thisObj, 'ddRemitTo16') !== undefined ? getValue(thisObj, 'ddRemitTo16') : ''
    obj.ddRemitTo17 = getValue(thisObj, 'ddRemitTo17') !== "" && getValue(thisObj, 'ddRemitTo17') !== undefined ? getValue(thisObj, 'ddRemitTo17') : ''
    obj.ddRemitTo18 = getValue(thisObj, 'ddRemitTo18') !== "" && getValue(thisObj, 'ddRemitTo18') !== undefined ? getValue(thisObj, 'ddRemitTo18') : ''
    obj.ddRemitTo19 = getValue(thisObj, 'ddRemitTo19') !== "" && getValue(thisObj, 'ddRemitTo19') !== undefined ? getValue(thisObj, 'ddRemitTo19') : ''
    obj.ddRemitTo20 = getValue(thisObj, 'ddRemitTo20') !== "" && getValue(thisObj, 'ddRemitTo20') !== undefined ? getValue(thisObj, 'ddRemitTo20') : ''
    obj.ddSeg = getValue(thisObj, 'ddSeg') !== "" && getValue(thisObj, 'ddSeg') !== undefined ? getValue(thisObj, 'ddSeg') : ''
    obj.ddShipFromToLocation = getValue(thisObj, 'ddShipFromToLocation') !== "" && getValue(thisObj, 'ddShipFromToLocation') !== undefined ? getValue(thisObj, 'ddShipFromToLocation') : ''
    obj.ddWhseBinNum = getValue(thisObj, 'ddWhseBinNum') !== "" && getValue(thisObj, 'ddWhseBinNum') !== undefined ? getValue(thisObj, 'ddWhseBinNum') : ''
    obj.dtInspectDateTime = getValue(thisObj, 'dtInspectDateTime') !== "" && getValue(thisObj, 'dtInspectDateTime') !== undefined ? moment(getValue(thisObj, 'dtInspectDateTime')).format('MM/DD/YYYY hh:mm A'): ''
    obj.dtSettleDateTime = getValue(thisObj, 'dtSettleDateTime') !== "" && getValue(thisObj, 'dtSettleDateTime') !== undefined ? getValue(thisObj, 'dtSettleDateTime') : ""
    obj.dtStartDateTime = getValue(thisObj, 'dtStartDateTime') !== "" && getValue(thisObj, 'dtStartDateTime') !== undefined ? getValue(thisObj, 'dtStartDateTime') : ""
    obj.lblAddedBy = getValue(thisObj, 'lblAddedBy') !== "" && getValue(thisObj, 'lblAddedBy') !== undefined ? getValue(thisObj, 'lblAddedBy') : ''
    obj.lblAddedByValue = getValue(thisObj, 'lblAddedByValue') !== "" && getValue(thisObj, 'lblAddedByValue') !== undefined ? getValue(thisObj, 'lblAddedByValue') : ''
    obj.lblAgreeNum = getValue(thisObj, 'lblAgreeNum') !== "" && getValue(thisObj, 'lblAgreeNum') !== undefined ? getValue(thisObj, 'lblAgreeNum') : ''
    obj.lblIDTAgreeNum = getValue(thisObj, 'lblIDTAgreeNum') !== "" && getValue(thisObj, 'lblIDTAgreeNum') !== undefined ? getValue(thisObj, 'lblIDTAgreeNum') : ''
    obj.lblChangedBy = getValue(thisObj, 'lblChangedBy') !== "" && getValue(thisObj, 'lblChangedBy') !== undefined ? getValue(thisObj, 'lblChangedBy') : ''
    obj.lblChangedByValue = getValue(thisObj, 'lblChangedByValue') !== "" && getValue(thisObj, 'lblChangedByValue') !== undefined ? getValue(thisObj, 'lblChangedByValue') : ''
    obj.lblCropYear2 = getValue(thisObj, 'lblCropYear2') !== "" && getValue(thisObj, 'lblCropYear2') !== undefined ? getValue(thisObj, 'lblCropYear2') : ''
    obj.lblInterDeptTransfer = getValue(thisObj, 'lblInterDeptTransfer') !== "" && getValue(thisObj, 'lblInterDeptTransfer') !== undefined ? getValue(thisObj, 'lblInterDeptTransfer') : ''
    obj.txtKernRidingELKPct = getValue(thisObj, 'txtKernRidingELKPct') !== "" && getValue(thisObj, 'txtKernRidingELKPct') !== undefined ? getValue(thisObj, 'txtKernRidingELKPct') : ''
    obj.lblPeanutInformation = getValue(thisObj, 'lblPeanutInformation') !== "" && getValue(thisObj, 'lblPeanutInformation') !== undefined ? getValue(thisObj, 'lblPeanutInformation') : ''
    obj.lblPerLb = getValue(thisObj, 'lblPerLb') !== "" && getValue(thisObj, 'lblPerLb') !== undefined ? getValue(thisObj, 'lblPerLb') : ''
    obj.lblPerLb2 = getValue(thisObj, 'lblPerLb2') !== "" && getValue(thisObj, 'lblPerLb2') !== undefined ? getValue(thisObj, 'lblPerLb2') : ''
    obj.lblPerLb3 = getValue(thisObj, 'lblPerLb3') !== "" && getValue(thisObj, 'lblPerLb3') !== undefined ? getValue(thisObj, 'lblPerLb3') : ''
    obj.lblPerTon = getValue(thisObj, 'lblPerTon') !== "" && getValue(thisObj, 'lblPerTon') !== undefined ? getValue(thisObj, 'lblPerTon') : ''
    obj.lblPrintSeg = getValue(thisObj, 'lblPrintSeg') !== "" && getValue(thisObj, 'lblPrintSeg') !== undefined ? getValue(thisObj, 'lblPrintSeg') : ''
    obj.lblReGrading = getValue(thisObj, 'lblReGrading') !== "" && getValue(thisObj, 'lblReGrading') !== undefined ? getValue(thisObj, 'lblReGrading') : ''
    obj.lblRemitTo = getValue(thisObj, 'lblRemitTo') !== "" && getValue(thisObj, 'lblRemitTo') !== undefined ? getValue(thisObj, 'lblRemitTo') : ''
    obj.lblScaleTickets = getValue(thisObj, 'lblScaleTickets') !== "" && getValue(thisObj, 'lblScaleTickets') !== undefined ? getValue(thisObj, 'lblScaleTickets') : ''
    obj.lblSettlementInformation = getValue(thisObj, 'lblSettlementInformation') !== "" && getValue(thisObj, 'lblSettlementInformation') !== undefined ? getValue(thisObj, 'lblSettlementInformation') : ''
    obj.lblSharePercentage = getValue(thisObj, 'lblSharePercentage') !== "" && getValue(thisObj, 'lblSharePercentage') !== undefined ? getValue(thisObj, 'lblSharePercentage') : ''
    obj.lblShippedFrom = getValue(thisObj, 'lblShippedFrom') !== "" && getValue(thisObj, 'lblShippedFrom') !== undefined ? getValue(thisObj, 'lblShippedFrom') : ''
    obj.lblShippedTo = getValue(thisObj, 'lblShippedTo') !== "" && getValue(thisObj, 'lblShippedTo') !== undefined ? getValue(thisObj, 'lblShippedTo') : ''
    obj.lblValueCalculation = getValue(thisObj, 'lblValueCalculation') !== "" && getValue(thisObj, 'lblValueCalculation') !== undefined ? getValue(thisObj, 'lblValueCalculation') : ''
    obj.lblVendor = getValue(thisObj, 'lblVendor') !== "" && getValue(thisObj, 'lblVendor') !== undefined ? getValue(thisObj, 'lblVendor') : ''
    obj.lblVendorSplits = getValue(thisObj, 'lblVendorSplits') !== "" && getValue(thisObj, 'lblVendorSplits') !== undefined ? getValue(thisObj, 'lblVendorSplits') : ''
    obj.lblXX1 = getValue(thisObj, 'lblXX1') !== "" && getValue(thisObj, 'lblXX1') !== undefined ? getValue(thisObj, 'lblXX1') : ''
    obj.lblXX2 = getValue(thisObj, 'lblXX2') !== "" && getValue(thisObj, 'lblXX2') !== undefined ? getValue(thisObj, 'lblXX2') : ''
    obj.lblXX3 = getValue(thisObj, 'lblXX3') !== "" && getValue(thisObj, 'lblXX3') !== undefined ? getValue(thisObj, 'lblXX3') : ''
    obj.lblXX4 = getValue(thisObj, 'lblXX4') !== "" && getValue(thisObj, 'lblXX4') !== undefined ? getValue(thisObj, 'lblXX4') : ''
    obj.lblXX5 = getValue(thisObj, 'lblXX5') !== "" && getValue(thisObj, 'lblXX5') !== undefined ? getValue(thisObj, 'lblXX5') : ''
    obj.lblXX6 = getValue(thisObj, 'lblXX6') !== "" && getValue(thisObj, 'lblXX6') !== undefined ? getValue(thisObj, 'lblXX6') : ''
    obj.lblXX7 = getValue(thisObj, 'lblXX7') !== "" && getValue(thisObj, 'lblXX7') !== undefined ? getValue(thisObj, 'lblXX7') : ''
    obj.lblXX8 = getValue(thisObj, 'lblXX8') !== "" && getValue(thisObj, 'lblXX8') !== undefined ? getValue(thisObj, 'lblXX8') : ''
    obj.lblXX9 = getValue(thisObj, 'lblXX9') !== "" && getValue(thisObj, 'lblXX9') !== undefined ? getValue(thisObj, 'lblXX9') : ''
    obj.lblXX10 = getValue(thisObj, 'lblXX10') !== "" && getValue(thisObj, 'lblXX10') !== undefined ? getValue(thisObj, 'lblXX10') : ''
    obj.lblXX11 = getValue(thisObj, 'lblXX11') !== "" && getValue(thisObj, 'lblXX11') !== undefined ? getValue(thisObj, 'lblXX11') : ''
    obj.lblXX12 = getValue(thisObj, 'lblXX12') !== "" && getValue(thisObj, 'lblXX12') !== undefined ? getValue(thisObj, 'lblXX12') : ''
    obj.lblXX13 = getValue(thisObj, 'lblXX13') !== "" && getValue(thisObj, 'lblXX13') !== undefined ? getValue(thisObj, 'lblXX13') : ''
    obj.lblXX14 = getValue(thisObj, 'lblXX14') !== "" && getValue(thisObj, 'lblXX14') !== undefined ? getValue(thisObj, 'lblXX14') : ''
    obj.lblXX15 = getValue(thisObj, 'lblXX15') !== "" && getValue(thisObj, 'lblXX15') !== undefined ? getValue(thisObj, 'lblXX15') : ''
    obj.lblXX16 = getValue(thisObj, 'lblXX16') !== "" && getValue(thisObj, 'lblXX16') !== undefined ? getValue(thisObj, 'lblXX16') : ''
    obj.lblXX17 = getValue(thisObj, 'lblXX17') !== "" && getValue(thisObj, 'lblXX17') !== undefined ? getValue(thisObj, 'lblXX17') : ''
    obj.lblXX18 = getValue(thisObj, 'lblXX18') !== "" && getValue(thisObj, 'lblXX18') !== undefined ? getValue(thisObj, 'lblXX18') : ''
    obj.lblXX19 = getValue(thisObj, 'lblXX19') !== "" && getValue(thisObj, 'lblXX19') !== undefined ? getValue(thisObj, 'lblXX19') : ''
    obj.lblXX20 = getValue(thisObj, 'lblXX20') !== "" && getValue(thisObj, 'lblXX20') !== undefined ? getValue(thisObj, 'lblXX20') : ''
    obj.radioGradePricingMethod = getValue(thisObj, 'radioGradePricingMethod') !== "" && getValue(thisObj, 'radioGradePricingMethod') !== undefined ? getValue(thisObj, 'radioGradePricingMethod') : ''
    obj.radioGramsPercentage = getValue(thisObj, 'radioGramsPercentage') !== "" && getValue(thisObj, 'radioGramsPercentage') !== undefined ? getValue(thisObj, 'radioGramsPercentage') : ''
    obj.radioTradeSaleTradePurchase = getValue(thisObj, 'radioTradeSaleTradePurchase') !== "" && getValue(thisObj, 'radioTradeSaleTradePurchase') !== undefined ? getValue(thisObj, 'radioTradeSaleTradePurchase') : ''
    obj.txt1007Handler = getValue(thisObj, 'txt1007Handler') !== "" && getValue(thisObj, 'txt1007Handler') !== undefined ? getValue(thisObj, 'txt1007Handler') : ''
    obj.txt1007HandlerName = getValue(thisObj, 'txt1007HandlerName') !== "" && getValue(thisObj, 'txt1007HandlerName') !== undefined ? getValue(thisObj, 'txt1007HandlerName') : ''
    obj.txtAccessFMPerPound = getValue(thisObj, 'txtAccessFMPerPound') !== "" && getValue(thisObj, 'txtAccessFMPerPound') !== undefined ? getValue(thisObj, 'txtAccessFMPerPound') : ''
    obj.txtAccessFMPerTon = getValue(thisObj, 'txtAccessFMPerTon') !== "" && getValue(thisObj, 'txtAccessFMPerTon') !== undefined ? getValue(thisObj, 'txtAccessFMPerTon') : ''
    obj.txtAFlavusPerPound = getValue(thisObj, 'txtAFlavusPerPound') !== "" && getValue(thisObj, 'txtAFlavusPerPound') !== undefined ? getValue(thisObj, 'txtAFlavusPerPound') : ''
    obj.txtAFlavusPerTon = getValue(thisObj, 'txtAFlavusPerTon') !== "" && getValue(thisObj, 'txtAFlavusPerTon') !== undefined ? getValue(thisObj, 'txtAFlavusPerTon') : ''
    obj.txtAgreementPrice = getValue(thisObj, 'txtAgreementPrice') !== "" && getValue(thisObj, 'txtAgreementPrice') !== undefined ? getValue(thisObj, 'txtAgreementPrice') : ''
    obj.txtarInspectionRemarks = getValue(thisObj, 'txtarInspectionRemarks') !== "" && getValue(thisObj, 'txtarInspectionRemarks') !== undefined ? getValue(thisObj, 'txtarInspectionRemarks') : ''
    obj.txtarSettleRemarks = getValue(thisObj, 'txtarSettleRemarks') !== "" && getValue(thisObj, 'txtarSettleRemarks') !== undefined ? getValue(thisObj, 'txtarSettleRemarks') : ''
    obj.txtBluePanWT = getValue(thisObj, 'txtBluePanWT') !== "" && getValue(thisObj, 'txtBluePanWT') !== undefined ? getValue(thisObj, 'txtBluePanWT') : '0.0'
    obj.txtCleanSampleWt = getValue(thisObj, 'txtCleanSampleWt') !== "" && getValue(thisObj, 'txtCleanSampleWt') !== undefined ? getValue(thisObj, 'txtCleanSampleWt') : '0.0'
    obj.txtcolInventoryWt = getValue(thisObj, 'txtcolInventoryWt') !== "" && getValue(thisObj, 'txtcolInventoryWt') !== undefined ? getValue(thisObj, 'txtcolInventoryWt') : ''
    obj.txtcolLoad = getValue(thisObj, 'txtcolLoad') !== "" && getValue(thisObj, 'txtcolLoad') !== undefined ? getValue(thisObj, 'txtcolLoad') : ''
    obj.txtcolRecvDate = getValue(thisObj, 'txtcolRecvDate') !== "" && getValue(thisObj, 'txtcolRecvDate') !== undefined ? getValue(thisObj, 'txtcolRecvDate') : ''
    obj.txtcolRecvTicket = getValue(thisObj, 'txtcolRecvTicket') !== "" && getValue(thisObj, 'txtcolRecvTicket') !== undefined ? getValue(thisObj, 'txtcolRecvTicket') : ''
    obj.txtcolRecvWt = getValue(thisObj, 'txtcolRecvWt') !== "" && getValue(thisObj, 'txtcolRecvWt') !== undefined ? getValue(thisObj, 'txtcolRecvWt') : ''
    obj.txtcolShipDate = getValue(thisObj, 'txtcolShipDate') !== "" && getValue(thisObj, 'txtcolShipDate') !== undefined ? getValue(thisObj, 'txtcolShipDate') : ''
    obj.txtcolShipTicket = getValue(thisObj, 'txtcolShipTicket') !== "" && getValue(thisObj, 'txtcolShipTicket') !== undefined ? getValue(thisObj, 'txtcolShipTicket') : ''
    obj.txtcolShipWt = getValue(thisObj, 'txtcolShipWt') !== "" && getValue(thisObj, 'txtcolShipWt') !== undefined ? getValue(thisObj, 'txtcolShipWt') : ''
    obj.txtcolVehicle = getValue(thisObj, 'txtcolVehicle') !== "" && getValue(thisObj, 'txtcolVehicle') !== undefined ? getValue(thisObj, 'txtcolVehicle') : ''
    obj.txtConcealedRMD = getValue(thisObj, 'txtConcealedRMD') !== "" && getValue(thisObj, 'txtConcealedRMD') !== undefined ? getValue(thisObj, 'txtConcealedRMD') : ''
    obj.txtConcealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct') !== "" && getValue(thisObj, 'txtConcealedRMDPct') !== undefined ? getValue(thisObj, 'txtConcealedRMDPct') : '0.00'
    obj.txtCrackedBroken = getValue(thisObj, 'txtCrackedBroken') !== "" && getValue(thisObj, 'txtCrackedBroken') !== undefined ? getValue(thisObj, 'txtCrackedBroken') : ''
    obj.txtCrackedBrokenPct = getValue(thisObj, 'txtCrackedBrokenPct') !== "" && getValue(thisObj, 'txtCrackedBrokenPct') !== undefined ? getValue(thisObj, 'txtCrackedBrokenPct') : ''
    obj.txtDamagedKRS = getValue(thisObj, 'txtDamagedKRS') !== "" && getValue(thisObj, 'txtDamagedKRS') !== undefined ? getValue(thisObj, 'txtDamagedKRS') : '0.0'
    obj.txtDamagePerPound = getValue(thisObj, 'txtDamagePerPound') !== "" && getValue(thisObj, 'txtDamagePerPound') !== undefined ? getValue(thisObj, 'txtDamagePerPound') : ''
    obj.txtDamagePerTon = getValue(thisObj, 'txtDamagePerTon') !== "" && getValue(thisObj, 'txtDamagePerTon') !== undefined ? getValue(thisObj, 'txtDamagePerTon') : ''
    obj.txtDamageSplits = getValue(thisObj, 'txtDamageSplits') !== "" && getValue(thisObj, 'txtDamageSplits') !== undefined ? getValue(thisObj, 'txtDamageSplits') : '0'
    obj.txtDeductions = getValue(thisObj, 'txtDeductions') !== "" && getValue(thisObj, 'txtDeductions') !== undefined ? getValue(thisObj, 'txtDeductions') : ''
    obj.txtDiscoloredShells = getValue(thisObj, 'txtDiscoloredShells') !== "" && getValue(thisObj, 'txtDiscoloredShells') !== undefined ? getValue(thisObj, 'txtDiscoloredShells') : ''
    obj.txtDiscoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct') !== "" && getValue(thisObj, 'txtDiscoloredShellsPct') !== undefined ? getValue(thisObj, 'txtDiscoloredShellsPct') : ''
    obj.txtDTWhseBinNum = getValue(thisObj, 'txtDTWhseBinNum') !== "" && getValue(thisObj, 'txtDTWhseBinNum') !== undefined ? getValue(thisObj, 'txtDTWhseBinNum') : ''
    obj.txtELKDamaged = getValue(thisObj, 'txtELKDamaged') !== "" && getValue(thisObj, 'txtELKDamaged') !== undefined ? getValue(thisObj, 'txtELKDamaged') : '0.0'
    obj.txtELKPremiumPerPound = getValue(thisObj, 'txtELKPremiumPerPound') !== "" && getValue(thisObj, 'txtELKPremiumPerPound') !== undefined ? getValue(thisObj, 'txtELKPremiumPerPound') : ''
    obj.txtELKPremiumPerTon = getValue(thisObj, 'txtELKPremiumPerTon') !== "" && getValue(thisObj, 'txtELKPremiumPerTon') !== undefined ? getValue(thisObj, 'txtELKPremiumPerTon') : ''
    obj.txtExcessMoisture = getValue(thisObj, 'txtExcessMoisture') !== "" && getValue(thisObj, 'txtExcessMoisture') !== undefined ? getValue(thisObj, 'txtExcessMoisture') : ''
    obj.txtExcessSplitsPerPound = getValue(thisObj, 'txtExcessSplitsPerPound') !== "" && getValue(thisObj, 'txtExcessSplitsPerPound') !== undefined ? getValue(thisObj, 'txtExcessSplitsPerPound') : ''
    obj.txtExcessSplitsPerTon = getValue(thisObj, 'txtExcessSplitsPerTon') !== "" && getValue(thisObj, 'txtExcessSplitsPerTon') !== undefined ? getValue(thisObj, 'txtExcessSplitsPerTon') : ''
    obj.txtFancy = getValue(thisObj, 'txtFancy') !== "" && getValue(thisObj, 'txtFancy') !== undefined ? getValue(thisObj, 'txtFancy') : ''
    obj.txtFancyPct = getValue(thisObj, 'txtFancyPct') !== "" && getValue(thisObj, 'txtFancyPct') !== undefined ? getValue(thisObj, 'txtFancyPct') : ''
    obj.txtFMSampleWt = getValue(thisObj, 'txtFMSampleWt') !== "" && getValue(thisObj, 'txtFMSampleWt') !== undefined ? getValue(thisObj, 'txtFMSampleWt') : '0.0'
    obj.txtForeignMaterial = getValue(thisObj, 'txtForeignMaterial') !== "" && getValue(thisObj, 'txtForeignMaterial') !== undefined ? getValue(thisObj, 'txtForeignMaterial') : ''
    obj.txtForeignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct') !== "" && getValue(thisObj, 'txtForeignMaterialPct') !== undefined ? getValue(thisObj, 'txtForeignMaterialPct') : '0.00'
    obj.txtForeignMtrl = getValue(thisObj, 'txtForeignMtrl') !== "" && getValue(thisObj, 'txtForeignMtrl') !== undefined ? getValue(thisObj, 'txtForeignMtrl') : ''
    obj.txtFreezeDamage = getValue(thisObj, 'txtFreezeDamage') !== "" && getValue(thisObj, 'txtFreezeDamage') !== undefined ? getValue(thisObj, 'txtFreezeDamage') : ''
    obj.txtFreezeDamagePct = getValue(thisObj, 'txtFreezeDamagePct') !== "" && getValue(thisObj, 'txtFreezeDamagePct') !== undefined ? getValue(thisObj, 'txtFreezeDamagePct') : '0.00'
    obj.txtGrossWeight = getValue(thisObj, 'txtGrossWeight') !== "" && getValue(thisObj, 'txtGrossWeight') !== undefined ? getValue(thisObj, 'txtGrossWeight') : ''
    obj.txtH = getValue(thisObj, 'txtH') !== "" && getValue(thisObj, 'txtH') !== undefined ? getValue(thisObj, 'txtH') : ''
    obj.txtHullBrightness = getValue(thisObj, 'txtHullBrightness') !== "" && getValue(thisObj, 'txtHullBrightness') !== undefined ? getValue(thisObj, 'txtHullBrightness') : ''
    obj.txtHullBrightnessPct = getValue(thisObj, 'txtHullBrightnessPct') !== "" && getValue(thisObj, 'txtHullBrightnessPct') !== undefined ? getValue(thisObj, 'txtHullBrightnessPct') : ''
    obj.txtHulls = getValue(thisObj, 'txtHulls') !== "" && getValue(thisObj, 'txtHulls') !== undefined ? getValue(thisObj, 'txtHulls') : ''
    obj.txtHullsPct = getValue(thisObj, 'txtHullsPct') !== "" && getValue(thisObj, 'txtHullsPct') !== undefined ? getValue(thisObj, 'txtHullsPct') : '0.00'
    obj.txtIDTfromtoVendor = getValue(thisObj, 'txtIDTfromtoVendor') !== "" && getValue(thisObj, 'txtIDTfromtoVendor') !== undefined ? getValue(thisObj, 'txtIDTfromtoVendor') : ''
    obj.txtJumbo = getValue(thisObj, 'txtJumbo') !== "" && getValue(thisObj, 'txtJumbo') !== undefined ? getValue(thisObj, 'txtJumbo') : ''
    obj.txtJumboPct = getValue(thisObj, 'txtJumboPct') !== "" && getValue(thisObj, 'txtJumboPct') !== undefined ? getValue(thisObj, 'txtJumboPct') : ''
    obj.txtKernelsREL = getValue(thisObj, 'txtKernelsREL') !== "" && getValue(thisObj, 'txtKernelsREL') !== undefined ? getValue(thisObj, 'txtKernelsREL') : '0.0'
    obj.txtKernelsRPS = getValue(thisObj, 'txtKernelsRPS') !== "" && getValue(thisObj, 'txtKernelsRPS') !== undefined ? getValue(thisObj, 'txtKernelsRPS') : '0.0'
    obj.txtKernelValuePerPound = getValue(thisObj, 'txtKernelValuePerPound') !== "" && getValue(thisObj, 'txtKernelValuePerPound') !== undefined ? getValue(thisObj, 'txtKernelValuePerPound') : ''
    obj.txtKernelValuePerTon = getValue(thisObj, 'txtKernelValuePerTon') !== "" && getValue(thisObj, 'txtKernelValuePerTon') !== undefined ? getValue(thisObj, 'txtKernelValuePerTon') : ''
    obj.txtKernRidingELK = getValue(thisObj, 'txtKernRidingELK') !== "" && getValue(thisObj, 'txtKernRidingELK') !== undefined ? getValue(thisObj, 'txtKernRidingELK') : ''
    obj.txtKernRidingPrescribe = getValue(thisObj, 'txtKernRidingPrescribe') !== "" && getValue(thisObj, 'txtKernRidingPrescribe') !== undefined ? getValue(thisObj, 'txtKernRidingPrescribe') : ''
    obj.txtKernRidingPrescribePct = getValue(thisObj, 'txtKernRidingPrescribePct') !== "" && getValue(thisObj, 'txtKernRidingPrescribePct') !== undefined ? getValue(thisObj, 'txtKernRidingPrescribePct') : ''
    obj.txtLoad = getValue(thisObj, 'txtLoad') !== "" && getValue(thisObj, 'txtLoad') !== undefined ? getValue(thisObj, 'txtLoad') : ''
    obj.txtLot = getValue(thisObj, 'txtLot') !== "" && getValue(thisObj, 'txtLot') !== undefined ? getValue(thisObj, 'txtLot') : ''
    obj.txtLSK = getValue(thisObj, 'txtLSK') !== "" && getValue(thisObj, 'txtLSK') !== undefined ? getValue(thisObj, 'txtLSK') : ''
    obj.txtLSKDamageRiding = getValue(thisObj, 'txtLSKDamageRiding') !== "" && getValue(thisObj, 'txtLSKDamageRiding') !== undefined ? getValue(thisObj, 'txtLSKDamageRiding') : ''
    obj.txtLSKFallThru = getValue(thisObj, 'txtLSKFallThru') !== "" && getValue(thisObj, 'txtLSKFallThru') !== undefined ? getValue(thisObj, 'txtLSKFallThru') : ''
    obj.txtLSKRidingGr = getValue(thisObj, 'txtLSKRidingGr') !== "" && getValue(thisObj, 'txtLSKRidingGr') !== undefined ? getValue(thisObj, 'txtLSKRidingGr') : ''
    obj.txtLSKRidingPct = getValue(thisObj, 'txtLSKRidingPct') !== "" && getValue(thisObj, 'txtLSKRidingPct') !== undefined ? getValue(thisObj, 'txtLSKRidingPct') : ''
    obj.txtMeterReading = getValue(thisObj, 'txtMeterReading') !== "" && getValue(thisObj, 'txtMeterReading') !== undefined ? getValue(thisObj, 'txtMeterReading') : '0.0'
    obj.txtMoisture = getValue(thisObj, 'txtMoisture') !== "" && getValue(thisObj, 'txtMoisture') !== undefined ? getValue(thisObj, 'txtMoisture') : '0'
    obj.txtNetELK = getValue(thisObj, 'txtNetELK') !== "" && getValue(thisObj, 'txtNetELK') !== undefined ? getValue(thisObj, 'txtNetELK') : ''
    obj.txtNetELKPct = getValue(thisObj, 'txtNetELKPct') !== "" && getValue(thisObj, 'txtNetELKPct') !== undefined ? getValue(thisObj, 'txtNetELKPct') : ''
    obj.txtNetValPerTonExclSLK = getValue(thisObj, 'txtNetValPerTonExclSLK') !== "" && getValue(thisObj, 'txtNetValPerTonExclSLK') !== undefined ? getValue(thisObj, 'txtNetValPerTonExclSLK') : ''
    obj.txtNetWeight = getValue(thisObj, 'txtNetWeight') !== "" && getValue(thisObj, 'txtNetWeight') !== undefined ? getValue(thisObj, 'txtNetWeight') : ''
    obj.txtNetWt = getValue(thisObj, 'txtNetWt') !== "" && getValue(thisObj, 'txtNetWt') !== undefined ? getValue(thisObj, 'txtNetWt') : ''
    obj.txtNetWtExcludingLSK = getValue(thisObj, 'txtNetWtExcludingLSK') !== "" && getValue(thisObj, 'txtNetWtExcludingLSK') !== undefined ? getValue(thisObj, 'txtNetWtExcludingLSK') : ''
    obj.txtOI = getValue(thisObj, 'txtOI') !== "" && getValue(thisObj, 'txtOI') !== undefined ? getValue(thisObj, 'txtOI') : ''
    obj.txtOKDamageRiding = getValue(thisObj, 'txtOKDamageRiding') !== "" && getValue(thisObj, 'txtOKDamageRiding') !== undefined ? getValue(thisObj, 'txtOKDamageRiding') : ''
    obj.txtOKFallThru = getValue(thisObj, 'txtOKFallThru') !== "" && getValue(thisObj, 'txtOKFallThru') !== undefined ? getValue(thisObj, 'txtOKFallThru') : ''
    obj.txtOKRidingGr = getValue(thisObj, 'txtOKRidingGr') !== "" && getValue(thisObj, 'txtOKRidingGr') !== undefined ? getValue(thisObj, 'txtOKRidingGr') : ''
    obj.txtOKRidingPct = getValue(thisObj, 'txtOKRidingPct') !== "" && getValue(thisObj, 'txtOKRidingPct') !== undefined ? getValue(thisObj, 'txtOKRidingPct') : ''
    obj.txtOtherFM = getValue(thisObj, 'txtOtherFM') !== "" && getValue(thisObj, 'txtOtherFM') !== undefined ? getValue(thisObj, 'txtOtherFM') : ''
    obj.txtPerG = getValue(thisObj, 'txtPerG') !== "" && getValue(thisObj, 'txtPerG') !== undefined ? getValue(thisObj, 'txtPerG') : ''
    obj.txtPittingDamage = getValue(thisObj, 'txtPittingDamage') !== "" && getValue(thisObj, 'txtPittingDamage') !== undefined ? getValue(thisObj, 'txtPittingDamage') : ''
    obj.txtPittingDamagePct = getValue(thisObj, 'txtPittingDamagePct') !== "" && getValue(thisObj, 'txtPittingDamagePct') !== undefined ? getValue(thisObj, 'txtPittingDamagePct') : ''
    obj.txtPremiums = getValue(thisObj, 'txtPremiums') !== "" && getValue(thisObj, 'txtPremiums') !== undefined ? getValue(thisObj, 'txtPremiums') : ''
    obj.txtProbingPattern = getValue(thisObj, 'txtProbingPattern') !== "" && getValue(thisObj, 'txtProbingPattern') !== undefined ? getValue(thisObj, 'txtProbingPattern') : ''
    obj.txtProceeds = getValue(thisObj, 'txtProceeds') !== "" && getValue(thisObj, 'txtProceeds') !== undefined ? getValue(thisObj, 'txtProceeds') : ''
    obj.txtSeal = getValue(thisObj, 'txtSeal') !== "" && getValue(thisObj, 'txtSeal') !== undefined ? getValue(thisObj, 'txtSeal') : ''
    obj.txtSeedMeetingSpec = getValue(thisObj, 'txtSeedMeetingSpec') !== "" && getValue(thisObj, 'txtSeedMeetingSpec') !== undefined ? getValue(thisObj, 'txtSeedMeetingSpec') : ''
    obj.txtSegValue = getValue(thisObj, 'txtSegValue') !== "" && getValue(thisObj, 'txtSegValue') !== undefined ? getValue(thisObj, 'txtSegValue') : ''
    obj.txtSharePercentage1 = getValue(thisObj, 'txtSharePercentage1') !== "" && getValue(thisObj, 'txtSharePercentage1') !== undefined ? getValue(thisObj, 'txtSharePercentage1') : ''
    obj.txtSharePercentage2 = getValue(thisObj, 'txtSharePercentage2') !== "" && getValue(thisObj, 'txtSharePercentage2') !== undefined ? getValue(thisObj, 'txtSharePercentage2') : ''
    obj.txtSharePercentage3 = getValue(thisObj, 'txtSharePercentage3') !== "" && getValue(thisObj, 'txtSharePercentage3') !== undefined ? getValue(thisObj, 'txtSharePercentage3') : ''
    obj.txtSharePercentage4 = getValue(thisObj, 'txtSharePercentage4') !== "" && getValue(thisObj, 'txtSharePercentage4') !== undefined ? getValue(thisObj, 'txtSharePercentage4') : ''
    obj.txtSharePercentage5 = getValue(thisObj, 'txtSharePercentage5') !== "" && getValue(thisObj, 'txtSharePercentage5') !== undefined ? getValue(thisObj, 'txtSharePercentage5') : ''
    obj.txtSharePercentage6 = getValue(thisObj, 'txtSharePercentage6') !== "" && getValue(thisObj, 'txtSharePercentage6') !== undefined ? getValue(thisObj, 'txtSharePercentage6') : ''
    obj.txtSharePercentage7 = getValue(thisObj, 'txtSharePercentage7') !== "" && getValue(thisObj, 'txtSharePercentage7') !== undefined ? getValue(thisObj, 'txtSharePercentage7') : ''
    obj.txtSharePercentage8 = getValue(thisObj, 'txtSharePercentage8') !== "" && getValue(thisObj, 'txtSharePercentage8') !== undefined ? getValue(thisObj, 'txtSharePercentage8') : ''
    obj.txtSharePercentage9 = getValue(thisObj, 'txtSharePercentage9') !== "" && getValue(thisObj, 'txtSharePercentage9') !== undefined ? getValue(thisObj, 'txtSharePercentage9') : ''
    obj.txtSharePercentage10 = getValue(thisObj, 'txtSharePercentage10') !== "" && getValue(thisObj, 'txtSharePercentage10') !== undefined ? getValue(thisObj, 'txtSharePercentage10') : ''
    obj.txtSharePercentage11 = getValue(thisObj, 'txtSharePercentage11') !== "" && getValue(thisObj, 'txtSharePercentage11') !== undefined ? getValue(thisObj, 'txtSharePercentage11') : ''
    obj.txtSharePercentage12 = getValue(thisObj, 'txtSharePercentage12') !== "" && getValue(thisObj, 'txtSharePercentage12') !== undefined ? getValue(thisObj, 'txtSharePercentage12') : ''
    obj.txtSharePercentage13 = getValue(thisObj, 'txtSharePercentage13') !== "" && getValue(thisObj, 'txtSharePercentage13') !== undefined ? getValue(thisObj, 'txtSharePercentage13') : ''
    obj.txtSharePercentage14 = getValue(thisObj, 'txtSharePercentage14') !== "" && getValue(thisObj, 'txtSharePercentage14') !== undefined ? getValue(thisObj, 'txtSharePercentage14') : ''
    obj.txtSharePercentage15 = getValue(thisObj, 'txtSharePercentage15') !== "" && getValue(thisObj, 'txtSharePercentage15') !== undefined ? getValue(thisObj, 'txtSharePercentage15') : ''
    obj.txtSharePercentage16 = getValue(thisObj, 'txtSharePercentage16') !== "" && getValue(thisObj, 'txtSharePercentage16') !== undefined ? getValue(thisObj, 'txtSharePercentage16') : ''
    obj.txtSharePercentage17 = getValue(thisObj, 'txtSharePercentage17') !== "" && getValue(thisObj, 'txtSharePercentage17') !== undefined ? getValue(thisObj, 'txtSharePercentage17') : ''
    obj.txtSharePercentage18 = getValue(thisObj, 'txtSharePercentage18') !== "" && getValue(thisObj, 'txtSharePercentage18') !== undefined ? getValue(thisObj, 'txtSharePercentage18') : ''
    obj.txtSharePercentage19 = getValue(thisObj, 'txtSharePercentage19') !== "" && getValue(thisObj, 'txtSharePercentage19') !== undefined ? getValue(thisObj, 'txtSharePercentage19') : ''
    obj.txtSharePercentage20 = getValue(thisObj, 'txtSharePercentage20') !== "" && getValue(thisObj, 'txtSharePercentage20') !== undefined ? getValue(thisObj, 'txtSharePercentage20') : ''
    obj.txtSMK1Gr = getValue(thisObj, 'txtSMK1Gr') !== "" && getValue(thisObj, 'txtSMK1Gr') !== undefined ? getValue(thisObj, 'txtSMK1Gr') : ''
    obj.txtSMK1Pct = getValue(thisObj, 'txtSMK1Pct') !== "" && getValue(thisObj, 'txtSMK1Pct') !== undefined ? getValue(thisObj, 'txtSMK1Pct') : ''
    obj.txtSMK2Gr = getValue(thisObj, 'txtSMK2Gr') !== "" && getValue(thisObj, 'txtSMK2Gr') !== undefined ? getValue(thisObj, 'txtSMK2Gr') : ''
    obj.txtSMK2Pct = getValue(thisObj, 'txtSMK2Pct') !== "" && getValue(thisObj, 'txtSMK2Pct') !== undefined ? getValue(thisObj, 'txtSMK2Pct') : ''
    obj.txtSMK3Gr = getValue(thisObj, 'txtSMK3Gr') !== "" && getValue(thisObj, 'txtSMK3Gr') !== undefined ? getValue(thisObj, 'txtSMK3Gr') : ''
    obj.txtSMK3Pct = getValue(thisObj, 'txtSMK3Pct') !== "" && getValue(thisObj, 'txtSMK3Pct') !== undefined ? getValue(thisObj, 'txtSMK3Pct') : ''
    obj.txtSMK4Gr = getValue(thisObj, 'txtSMK4Gr') !== "" && getValue(thisObj, 'txtSMK4Gr') !== undefined ? getValue(thisObj, 'txtSMK4Gr') : ''
    obj.txtSMK4Pct = getValue(thisObj, 'txtSMK4Pct') !== "" && getValue(thisObj, 'txtSMK4Pct') !== undefined ? getValue(thisObj, 'txtSMK4Pct') : ''
    obj.txtSoundSplits = getValue(thisObj, 'txtSoundSplits') !== "" && getValue(thisObj, 'txtSoundSplits') !== undefined ? getValue(thisObj, 'txtSoundSplits') : ''
    obj.txtSoundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct') !== "" && getValue(thisObj, 'txtSoundSplitsPct') !== undefined ? getValue(thisObj, 'txtSoundSplitsPct') : '0'
    obj.txtSts = getValue(thisObj, 'txtSts') !== "" && getValue(thisObj, 'txtSts') !== undefined ? getValue(thisObj, 'txtSts') : ''
    obj.txtSupportPercent = getValue(thisObj, 'txtSupportPercent') !== "" && getValue(thisObj, 'txtSupportPercent') !== undefined ? getValue(thisObj, 'txtSupportPercent') : ''
    obj.txtTckt = getValue(thisObj, 'txtTckt') !== "" && getValue(thisObj, 'txtTckt') !== undefined ? getValue(thisObj, 'txtTckt') : ''
    obj.txtTKCLbs = getValue(thisObj, 'txtTKCLbs') !== "" && getValue(thisObj, 'txtTKCLbs') !== undefined ? getValue(thisObj, 'txtTKCLbs') : ''
    obj.txtTotal = getValue(thisObj, 'txtTotal') !== "" && getValue(thisObj, 'txtTotal') !== undefined ? getValue(thisObj, 'txtTotal') : ''
    obj.txtTotalDamage = getValue(thisObj, 'txtTotalDamage') !== "" && getValue(thisObj, 'txtTotalDamage') !== undefined ? getValue(thisObj, 'txtTotalDamage') : ''
    obj.txtTotalDamagePct = getValue(thisObj, 'txtTotalDamagePct') !== "" && getValue(thisObj, 'txtTotalDamagePct') !== undefined ? getValue(thisObj, 'txtTotalDamagePct') : '0.00'
    obj.txtTotalDiscountsPerPound = getValue(thisObj, 'txtTotalDiscountsPerPound') !== "" && getValue(thisObj, 'txtTotalDiscountsPerPound') !== undefined ? getValue(thisObj, 'txtTotalDiscountsPerPound') : ''
    obj.txtTotalDiscountsPerTon = getValue(thisObj, 'txtTotalDiscountsPerTon') !== "" && getValue(thisObj, 'txtTotalDiscountsPerTon') !== undefined ? getValue(thisObj, 'txtTotalDiscountsPerTon') : ''
    obj.txtTotalELKDamage = getValue(thisObj, 'txtTotalELKDamage') !== "" && getValue(thisObj, 'txtTotalELKDamage') !== undefined ? getValue(thisObj, 'txtTotalELKDamage') : ''
    obj.txtTotalELKDamagePct = getValue(thisObj, 'txtTotalELKDamagePct') !== "" && getValue(thisObj, 'txtTotalELKDamagePct') !== undefined ? getValue(thisObj, 'txtTotalELKDamagePct') : ''
    obj.txtTotalKernels = getValue(thisObj, 'txtTotalKernels') !== "" && getValue(thisObj, 'txtTotalKernels') !== undefined ? getValue(thisObj, 'txtTotalKernels') : ''
    obj.txtTotalKernelsAndHulls = getValue(thisObj, 'txtTotalKernelsAndHulls') !== "" && getValue(thisObj, 'txtTotalKernelsAndHulls') !== undefined ? getValue(thisObj, 'txtTotalKernelsAndHulls') : '0.00'
    obj.txtTotalLoadVal = getValue(thisObj, 'txtTotalLoadVal') !== "" && getValue(thisObj, 'txtTotalLoadVal') !== undefined ? getValue(thisObj, 'txtTotalLoadVal') : ''
    obj.txtTotalLoadValue = getValue(thisObj, 'txtTotalLoadValue') !== "" && getValue(thisObj, 'txtTotalLoadValue') !== undefined ? getValue(thisObj, 'txtTotalLoadValue') : ''
    obj.txtTotalOK = getValue(thisObj, 'txtTotalOK') !== "" && getValue(thisObj, 'txtTotalOK') !== undefined ? getValue(thisObj, 'txtTotalOK') : ''
    obj.txtTotalOKPct = getValue(thisObj, 'txtTotalOKPct') !== "" && getValue(thisObj, 'txtTotalOKPct') !== undefined ? getValue(thisObj, 'txtTotalOKPct') : ''
    obj.txtTotalPerPound = getValue(thisObj, 'txtTotalPerPound') !== "" && getValue(thisObj, 'txtTotalPerPound') !== undefined ? getValue(thisObj, 'txtTotalPerPound') : ''
    obj.txtTotalPerTon = getValue(thisObj, 'txtTotalPerTon') !== "" && getValue(thisObj, 'txtTotalPerTon') !== undefined ? getValue(thisObj, 'txtTotalPerTon') : ''
    obj.txtTotalSLK = getValue(thisObj, 'txtTotalSLK') !== "" && getValue(thisObj, 'txtTotalSLK') !== undefined ? getValue(thisObj, 'txtTotalSLK') : ''
    obj.txtTotalSLKPct = getValue(thisObj, 'txtTotalSLKPct') !== "" && getValue(thisObj, 'txtTotalSLKPct') !== undefined ? getValue(thisObj, 'txtTotalSLKPct') : '0.00'
    obj.txtTotalSMK = getValue(thisObj, 'txtTotalSMK') !== "" && getValue(thisObj, 'txtTotalSMK') !== undefined ? getValue(thisObj, 'txtTotalSMK') : '0.00'
    obj.txtTotalSMKRScreen = getValue(thisObj, 'txtTotalSMKRScreen') !== "" && getValue(thisObj, 'txtTotalSMKRScreen') !== undefined ? getValue(thisObj, 'txtTotalSMKRScreen') : ''
    obj.txtTotalSMKRScreenPct = getValue(thisObj, 'txtTotalSMKRScreenPct') !== "" && getValue(thisObj, 'txtTotalSMKRScreenPct') !== undefined ? getValue(thisObj, 'txtTotalSMKRScreenPct') : '0'
    obj.txtTradeInspectSC95 = getValue(thisObj, 'txtTradeInspectSC95') !== "" && getValue(thisObj, 'txtTradeInspectSC95') !== undefined ? getValue(thisObj, 'txtTradeInspectSC95') : ''
    obj.txtTradeSettle1007 = getValue(thisObj, 'txtTradeSettle1007') !== "" && getValue(thisObj, 'txtTradeSettle1007') !== undefined ? getValue(thisObj, 'txtTradeSettle1007') : ""
    obj.txtValPerPoundExclSLK = getValue(thisObj, 'txtValPerPoundExclSLK') !== "" && getValue(thisObj, 'txtValPerPoundExclSLK') !== undefined ? getValue(thisObj, 'txtValPerPoundExclSLK') : ''
    obj.txtValPerPoundInclSLK = getValue(thisObj, 'txtValPerPoundInclSLK') !== "" && getValue(thisObj, 'txtValPerPoundInclSLK') !== undefined ? getValue(thisObj, 'txtValPerPoundInclSLK') : ''
    obj.txtVehicleWt = getValue(thisObj, 'txtVehicleWt') !== "" && getValue(thisObj, 'txtVehicleWt') !== undefined ? getValue(thisObj, 'txtVehicleWt') : ''
    obj.txtVendor1 = getValue(thisObj, 'txtVendor1') !== "" && getValue(thisObj, 'txtVendor1') !== undefined ? getValue(thisObj, 'txtVendor1') : ''
    obj.txtVendor2 = getValue(thisObj, 'txtVendor2') !== "" && getValue(thisObj, 'txtVendor2') !== undefined ? getValue(thisObj, 'txtVendor2') : ''
    obj.txtVendor3 = getValue(thisObj, 'txtVendor3') !== "" && getValue(thisObj, 'txtVendor3') !== undefined ? getValue(thisObj, 'txtVendor3') : ''
    obj.txtVendor4 = getValue(thisObj, 'txtVendor4') !== "" && getValue(thisObj, 'txtVendor4') !== undefined ? getValue(thisObj, 'txtVendor4') : ''
    obj.txtVendor5 = getValue(thisObj, 'txtVendor5') !== "" && getValue(thisObj, 'txtVendor5') !== undefined ? getValue(thisObj, 'txtVendor5') : ''
    obj.txtVendor6 = getValue(thisObj, 'txtVendor6') !== "" && getValue(thisObj, 'txtVendor6') !== undefined ? getValue(thisObj, 'txtVendor6') : ''
    obj.txtVendor7 = getValue(thisObj, 'txtVendor7') !== "" && getValue(thisObj, 'txtVendor7') !== undefined ? getValue(thisObj, 'txtVendor7') : ''
    obj.txtVendor8 = getValue(thisObj, 'txtVendor8') !== "" && getValue(thisObj, 'txtVendor8') !== undefined ? getValue(thisObj, 'txtVendor8') : ''
    obj.txtVendor9 = getValue(thisObj, 'txtVendor9') !== "" && getValue(thisObj, 'txtVendor9') !== undefined ? getValue(thisObj, 'txtVendor9') : ''
    obj.txtVendor10 = getValue(thisObj, 'txtVendor10') !== "" && getValue(thisObj, 'txtVendor10') !== undefined ? getValue(thisObj, 'txtVendor10') : ''
    obj.txtVendor11 = getValue(thisObj, 'txtVendor11') !== "" && getValue(thisObj, 'txtVendor11') !== undefined ? getValue(thisObj, 'txtVendor11') : ''
    obj.txtVendor12 = getValue(thisObj, 'txtVendor12') !== "" && getValue(thisObj, 'txtVendor12') !== undefined ? getValue(thisObj, 'txtVendor12') : ''
    obj.txtVendor13 = getValue(thisObj, 'txtVendor13') !== "" && getValue(thisObj, 'txtVendor13') !== undefined ? getValue(thisObj, 'txtVendor13') : ''
    obj.txtVendor14 = getValue(thisObj, 'txtVendor14') !== "" && getValue(thisObj, 'txtVendor14') !== undefined ? getValue(thisObj, 'txtVendor14') : ''
    obj.txtVendor15 = getValue(thisObj, 'txtVendor15') !== "" && getValue(thisObj, 'txtVendor15') !== undefined ? getValue(thisObj, 'txtVendor15') : ''
    obj.txtVendor16 = getValue(thisObj, 'txtVendor16') !== "" && getValue(thisObj, 'txtVendor16') !== undefined ? getValue(thisObj, 'txtVendor16') : ''
    obj.txtVendor17 = getValue(thisObj, 'txtVendor17') !== "" && getValue(thisObj, 'txtVendor17') !== undefined ? getValue(thisObj, 'txtVendor17') : ''
    obj.txtVendor18 = getValue(thisObj, 'txtVendor18') !== "" && getValue(thisObj, 'txtVendor18') !== undefined ? getValue(thisObj, 'txtVendor18') : ''
    obj.txtVendor19 = getValue(thisObj, 'txtVendor19') !== "" && getValue(thisObj, 'txtVendor19') !== undefined ? getValue(thisObj, 'txtVendor19') : ''
    obj.txtVendor20 = getValue(thisObj, 'txtVendor20') !== "" && getValue(thisObj, 'txtVendor20') !== undefined ? getValue(thisObj, 'txtVendor20') : ''
    obj.txtVicamReading = getValue(thisObj, 'txtVicamReading') !== "" && getValue(thisObj, 'txtVicamReading') !== undefined ? getValue(thisObj, 'txtVicamReading') : ''
    obj.txtWeightLessFM = getValue(thisObj, 'txtWeightLessFM') !== "" && getValue(thisObj, 'txtWeightLessFM') !== undefined ? getValue(thisObj, 'txtWeightLessFM') : ''
    obj.txtWormDamage = getValue(thisObj, 'txtWormDamage') !== "" && getValue(thisObj, 'txtWormDamage') !== undefined ? getValue(thisObj, 'txtWormDamage') : ''
    obj.txtWtIncludingVehicle = getValue(thisObj, 'txtWtIncludingVehicle') !== "" && getValue(thisObj, 'txtWtIncludingVehicle') !== undefined ? getValue(thisObj, 'txtWtIncludingVehicle') : ''
    let ddShipFromToLocationlist = thisObj.state['ddShipFromToLocation'].valueList;
    let ddShipFromToLocationval = thisObj.values['ddShipFromToLocation'];
    let lstAreaId = ddShipFromToLocationlist.filter(elem => elem.key === ddShipFromToLocationval);
    fetchRetrieveAreaControlDetail(lstAreaId[0].lstAreaId)

    if (SAction == 'Calulatevalue') {
      CalculateValues(lstAreaId[0].lstAreaId, obj.ddPeanutType, blWtIncVehicle, dblVehicleWt, obj.txtMoisture, obj.txtCrackedBrokenPct, obj.txtDiscoloredShellsPct, obj.txtFancyPct, obj.txtForeignMaterialPct, obj.txtTotalSLKPct, obj.txtTotalSMKRScreenPct, obj.txtSoundSplitsPct, obj.txtTotalOKPct,
        obj.txtNetELKPct, obj.txtTotalDamagePct, obj.txtVicamReading)
    }
  }

  const ongridScaleTicketsRowSelect = (rowSelected) => {
    let data3 = rowSelected;
    setData(thisObj, 'rowSelected', rowSelected.selectedRows);
    if (data3.selectedRows) {
      data3 = data3.selectedRows[0];
    }

  };
  thisObj.ongridScaleTicketsRowSelect = ongridScaleTicketsRowSelect;

  const onbtnAcctDistClick = () => {
    try {
      let buy_point_id = getValue(thisObj, 'ddShipFromToLocation')
      let txtTradeInspNum = getValue(thisObj, 'txtTradeInspectSC95')
      var ReviewAcctDistFormLoadDataObj = {}
      ReviewAcctDistFormLoadDataObj.GstrCompanyID = compIdFromLS,
        ReviewAcctDistFormLoadDataObj.GstrCropYear = cropYearFromLS,
        ReviewAcctDistFormLoadDataObj.buy_point_id = buy_point_id
      ReviewAcctDistFormLoadDataObj.source_type = 'TRADE'
      ReviewAcctDistFormLoadDataObj.id_number_1 = txtTradeInspNum
      ReviewAcctDistFormLoadDataObj.id_number_2 = 0
      setData(thisObj, "ReviewAcctDistFormLoadData", ReviewAcctDistFormLoadDataObj);
      commonContext.setDataForReviewAcctDist([ReviewAcctDistFormLoadDataObj])
      setData(thisObj, 'type', 'TRADE')
      commonContext.setTradeSettleProfile_BuyingPointId(buy_point_id)
      commonContext.setTradeSettleProfile_sInspNum(txtTradeInspNum)
      goTo(thisObj, "ContractManagement#AccountDistributionActivity#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnAcctDist event:Click");
      }
    }
  }
  thisObj.onbtnAcctDistClick = onbtnAcctDistClick;

  const onbtnAddSTClick = () => {
    try {

      let obj = {}
      obj.cmdok = "Add"
      obj.status = 'Pending'
      if(sPermission=="I"){
        obj.cmdokenabled = false
      }
      goTo(thisObj, "Settlements#TradeSettleScaleTicket#DEFAULT#true#Click");
      setData(thisObj, 'TradeSettleScaleTicketdata', obj)

    }
    catch (err) {

    }
  }
  thisObj.onbtnAddSTClick = onbtnAddSTClick;

  const onbtnEditSTClick = () => {
    try {
      let status = getValue(thisObj, 'txtSts')
      let rowselectedvalue = getValue(thisObj, "gridScaleTickets");
      let row = getSelectedRowNumber(thisObj, "gridScaleTickets")
      let obj = rowselectedvalue[row]
      obj.cmdok = "Update"
      obj.status = status

      let arrTicketInfo = obj.arrTicketInfo
      if (arrTicketInfo.shp_date !== '') {
        obj.txtShippedDate = arrTicketInfo.shp_date
      }
      obj.txtSScaleTicketNum = arrTicketInfo.shp_scale_tick

      if (arrTicketInfo.shp_split_scale == "Y") {
        obj.chkSSplitWeight = true;
      } else {
        obj.chkSSplitWeight = false;
      }
      obj.txtSWeightIncludingVehicle = arrTicketInfo.shp_tare_wt
      obj.txtSWeightOfVehicle = arrTicketInfo.shp_vehicle_wt
      obj.lblSLbsOfPeanuts = arrTicketInfo.shp_peanut_wt
      if (arrTicketInfo.rec_date !== '') {
        obj.txtReceivedDate = arrTicketInfo.rec_date
      }
      obj.txtRScaleTicketNum = arrTicketInfo.rec_scale_tick
      if (arrTicketInfo.rec_split_scale == "Y") {
        obj.chkRSplitWeight = true;
      } else {
        obj.chkRSplitWeight = false;
      }

      obj.txtRWeightIncludingVehicle = arrTicketInfo.rec_tare_wt
      obj.txtRWeightOfVehicle = arrTicketInfo.rec_vehicle_wt
      obj.lblRLbsOfPeanuts = arrTicketInfo.rec_peanut_wt
      switch (arrTicketInfo.inventory_with_ind) {
        case "S":
          obj.optShippedWeight = true
          break;
        case "R":
          obj.optReceivedWeight = true;
          break;
        case "O":
          obj.optOtherWeight = true;
          break;

      }

      obj.txtOtherWeight = arrTicketInfo.inventory_other_wt
      setData(thisObj, 'TradeSettleScaleTicketdata', obj)
      goTo(thisObj, "Settlements#TradeSettleScaleTicket#DEFAULT#true#Click");

    }
    catch (err) {

    }
  }
  thisObj.onbtnEditSTClick = onbtnEditSTClick;


  const onbtnAgreeApplClick = () => {
    try {
      let buy_point_id = getValue(thisObj, 'ddShipFromToLocation')
      let txtTradeInspNum = getValue(thisObj, 'txtTradeInspectSC95')
      let txtTradeSettle1007 = getValue(thisObj, 'txtTradeSettle1007')
      var ReviewAgreeApplFormLoadDataObj = {}
      ReviewAgreeApplFormLoadDataObj.buy_point_id = buy_point_id
      ReviewAgreeApplFormLoadDataObj.txtTradeSettle1007 = txtTradeSettle1007
      ReviewAgreeApplFormLoadDataObj.txtTradeInspNum = txtTradeInspNum
      setData(thisObj, "ReviewAgreeApplFormLoadData", ReviewAgreeApplFormLoadDataObj);
      // alert('Tradesettleapplyview')
      goTo(thisObj, "Settlements#TradeSettleApplyView#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnAgreeAppl event:Click");
      }
    }
  }
  thisObj.onbtnAgreeApplClick = onbtnAgreeApplClick;

  const onbtnCalculateClick = () => {
    try {
      let dblWtIncVehicle = 0,
      dblVehicleWt = 0
      let gridscalevalue = getValue(thisObj, 'gridScaleTickets')
      if (gridscalevalue.length > 0) {
        for (var i = 0; i < gridscalevalue.length; i++) {
          let arrScaleTicket = gridscalevalue[i].arrTicketInfo;
          let shptarewt = arrScaleTicket.shp_tare_wt !== undefined && arrScaleTicket.shp_tare_wt !== "" ? arrScaleTicket.shp_tare_wt : 0
          let shpvehiclewt = arrScaleTicket.shp_vehicle_wt !== undefined && arrScaleTicket.shp_vehicle_wt !== "" ? arrScaleTicket.shp_vehicle_wt : 0
          let rectarewt = arrScaleTicket.rec_tare_wt !== undefined && arrScaleTicket.rec_tare_wt !== "" ? arrScaleTicket.rec_tare_wt : 0
          let recvehiclewt = arrScaleTicket.rec_vehicle_wt !== undefined && arrScaleTicket.rec_vehicle_wt !== "" ? arrScaleTicket.rec_vehicle_wt : 0
          switch (arrScaleTicket.inventory_with_ind) {
            case "S":
              dblWtIncVehicle = dblWtIncVehicle + parseInt(shptarewt)
              dblVehicleWt = dblVehicleWt + parseInt(shpvehiclewt)
              break;
            case "R":
              dblWtIncVehicle = dblWtIncVehicle + parseInt(rectarewt)
              dblVehicleWt = dblVehicleWt + parseInt(recvehiclewt)
              break;
            case "O":
              dblWtIncVehicle = dblWtIncVehicle + parseInt(rectarewt)
              dblVehicleWt = dblVehicleWt + parseInt(recvehiclewt)
              break;
          }
        }
      }
      getallvalue('Calulatevalue', dblWtIncVehicle, dblVehicleWt)
    }
    catch (err) {

    }
  }
  thisObj.onbtnCalculateClick = onbtnCalculateClick;

  const onbtnCreateSettlementClick = async () => {
    try {
      onbtnSaveClick('Create')
    }
    catch (err) {

    }
  }
  thisObj.onbtnCreateSettlementClick = onbtnCreateSettlementClick;

  const onbtnDeductTrackClick = () => {
    try {
      let buy_point_id = getValue(thisObj, 'ddShipFromToLocation')
      let txtTradeInspNum = getValue(thisObj, 'txtTradeInspectSC95')
      var ReviewDeductionTrackingFormLoadDataObj = {}
      ReviewDeductionTrackingFormLoadDataObj.GstrCompanyID = compIdFromLS,
        ReviewDeductionTrackingFormLoadDataObj.GstrCropYear = cropYearFromLS,
        ReviewDeductionTrackingFormLoadDataObj.buy_point_id = buy_point_id
      ReviewDeductionTrackingFormLoadDataObj.source_type = 'TRADE_SETTLE'
      ReviewDeductionTrackingFormLoadDataObj.id_number_1 = txtTradeInspNum
      ReviewDeductionTrackingFormLoadDataObj.id_number_2 = '0'
      setData(thisObj, "ReviewDeductionTrackingFormLoadData", ReviewDeductionTrackingFormLoadDataObj);
      goTo(thisObj, "Settlements#ReviewDeductionTracking#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnDeductTrack event:Click");
      }
    }
  }
  thisObj.onbtnDeductTrackClick = onbtnDeductTrackClick;

  const onbtnDeleteSTClick = () => {
    try {
      let data = getData(thisObj, 'rowSelected');
      let row = getSelectedRowNumber(thisObj, "gridScaleTickets")
      let gridScaleTickets = getValue(thisObj, 'gridScaleTickets')
      if (row !== '' && row !== null && row !== undefined) {
        if (data !== null && data !== undefined && data !== "") {
          if (confirm(`Are you sure you wish to delete this ticket ${data[0].txtcolLoad}`) == true) {
            gridScaleTickets.splice(row, 1)
            setValue(thisObj, 'gridScaleTickets', gridScaleTickets)
            EnableDisableCalculate(gridScaleTickets)
          }
        } else {
          alert("At least one row must be selected!");
        }
      } else {
        alert("At least one row must be selected!");
      }
    }
    catch (err) {
    }
  }
  thisObj.onbtnDeleteSTClick = onbtnDeleteSTClick;

  const onbtnPaymentsClick = () => {
    try {
      let buy_point_id = getValue(thisObj, 'ddShipFromToLocation')
      let txtTradeInspNum = getValue(thisObj, 'txtTradeInspectSC95')
      var ReviewPaymentsFormLoadDataObj = {}
      ReviewPaymentsFormLoadDataObj.buy_point_id = buy_point_id
      ReviewPaymentsFormLoadDataObj.txtTradeInspNum = txtTradeInspNum
      setData(thisObj, "ReviewPaymentsFormLoadData", ReviewPaymentsFormLoadDataObj);
      goTo(thisObj, "Settlements#TradeSettlePaymentView#DEFAULT#true#Click");
    }
    catch (err) {

    }
  }
  thisObj.onbtnPaymentsClick = onbtnPaymentsClick;

  const onbtnPremsDeductClick = () => {
    try {
      let buy_point_id = getValue(thisObj, 'ddShipFromToLocation')
      let txtTradeInspNum = getValue(thisObj, 'txtTradeInspectSC95')
      let viewPremiumDeductionsOnLoadDataobj = {};
      viewPremiumDeductionsOnLoadDataobj.insp_num = txtTradeInspNum // This is mandatory field and must be set by Parent screen
      viewPremiumDeductionsOnLoadDataobj.buy_pt_id = buy_point_id// This is optional field and Parent screen can set null value if not setting any value
      viewPremiumDeductionsOnLoadDataobj.action = null // This is optional field and Parent screen can set null value if not setting any value
      viewPremiumDeductionsOnLoadDataobj.getvendorinfo = null // This is optional field and Parent screen can set null value if not setting any value
      setData(thisObj, "viewPremiumDeductionsOnLoadData", viewPremiumDeductionsOnLoadDataobj);
      goTo(thisObj, "Settlements#TradeSettlePremDeductView#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnPremsDeduct event:Click");
      }
    }
  }
  thisObj.onbtnPremsDeductClick = onbtnPremsDeductClick;

  const onbtnPrintSettlementClick = () => {
    try {
      //let agreenumer = getValue(thisObj, 'txtAgreement')
      let BUY_PT_ID = getValue(thisObj, 'ddShipFromToLocation')
      let js = [{
        GstrCompanyID: compIdFromLS,
        GstrCropYear: cropYearFromLS,
        lstBuyingPointID: BUY_PT_ID,
        Txt_Agree: txtTradeInspectSC95.value,
        ReportType: 'TRADE_SETTLEMENT_WORKSHEET'
      }]

      setData(thisObj, 'ReportPreviewTradedata', js)
      //alert('Trade Agreement Add successful')
      goTo(thisObj, "Settlements#ReportPreviewTrade#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in PreSubmit Event code for widget:btnPrintSettlement event:Click");
      }
    }
  }
  thisObj.onbtnPrintSettlementClick = onbtnPrintSettlementClick;

  const onbtnUnvoidClick = async () => {
    let buy_pt_id = getValue(thisObj, 'ddShipFromToLocation')
    let trade_insp_num = getValue(thisObj, 'txtTradeInspectSC95')
    let cid = 'unvoids'
    let dataObj = {
      "user_id": useridFromLS
    }
    let UpdateTradeSettlementUnvoidDetails = await SettlementService.UpdateTradeSettlementUnvoidDetails(buy_pt_id, trade_insp_num, cid, dataObj);
    if (confirm(`Are you sure you want to un-void the Trade # #${trade_insp_num}`) == true) {

      if (UpdateTradeSettlementUnvoidDetails.status == 200) {
        setValue(thisObj, 'txtSts', 'UnVoid')
        alert(`The trade #"${trade_insp_num} has been successfully un-voided!!!`)
        let js = [{
          lbl_firstload: "D"
        }]
        setData(thisObj, 'Tradeagreesearchdata', js)
        document.getElementById("Settlements_TradeSettleProfilePopUp").childNodes[0].click();
        // if(ddIDTfromtoCompany!==""&& buy_pt_id !==""){
        // let UpdateTradeSettlementUnvoidDetailscomp = await SettlementService.UpdateTradeSettlementUnvoidDetails(buy_pt_id, trade_insp_num, cid,ddIDTfromtoCompany, dataObj);
        // if(UpdateTradeSettlementUnvoidDetailscomp.length>0){
        //   setValue(thisObj,'txtSts','UnVoid')
        //   showMessage(`The trade #"${trade_insp_num} has been successfully un-voided!!!`)
        // }
        // }else{
        //   setValue(thisObj,'txtSts','UnVoid')
        //   showMessage(`The trade #"${trade_insp_num} has been successfully un-voided!!!`)
        // }
      } else {
        alert(UpdateTradeSettlementUnvoidDetails.message);
      }
    }
  };
  thisObj.onbtnUnvoidClick = onbtnUnvoidClick;

  const onbtnVoidClick = async () => {
    let buy_pt_id = getValue(thisObj, 'ddShipFromToLocation')
    let trade_insp_num = getValue(thisObj, 'txtTradeInspectSC95')
    let LstrNumber = ''
    let cid = 'voids'
    let dataObj = {
      "user_id": useridFromLS
    }
    let UpdateTradeSettlementVoidDetails = await SettlementService.UpdateTradeSettlementVoidDetails(buy_pt_id, trade_insp_num, cid, dataObj);
    if (confirm(`Are you sure you want to void the Trade #${trade_insp_num}`) == true) {
      LstrNumber = prompt("Confirm the void of the trade by entering the trade inspection number.")
      if (LstrNumber == trade_insp_num) {
        if (UpdateTradeSettlementVoidDetails.status == 200) {
          setValue(thisObj, 'txtSts', 'Void')
          alert(`The trade #"${trade_insp_num} has been successfully voided!!!`)
          let js = [{
            lbl_firstload: "D"
          }]
          setData(thisObj, 'Tradeagreesearchdata', js)
          setData(thisObj, 'CompletionStatus', true)
          document.getElementById("Settlements_TradeSettleProfilePopUp").childNodes[0].click();
          // if(ddIDTfromtoCompany!==""&& buy_pt_id !==""){
          // let UpdateTradeSettlementVoidDetailscomp = await SettlementService.UpdateTradeSettlementVoidDetails(buy_pt_id, trade_insp_num, cid, dataObj);
          // if(UpdateTradeSettlementVoidDetailscomp.length>0){
          //   setValue(thisObj,'txtSts','Void')
          //   showMessage(`The trade #"${trade_insp_num} has been successfully voided!!!`)
          // }
          // }else{
          //   setValue(thisObj,'txtSts','Void')
          //   showMessage(`The trade #"${trade_insp_num} has been successfully voided!!!`)
          // }
        } else {
          alert(UpdateTradeSettlementVoidDetails.message);
        }

      } else {
        showMessage("The entered trade inspection number does not match the trade to be voided!!!")
      }
    }
  };
  thisObj.onbtnVoidClick = onbtnVoidClick;

  const onbtnReversalClick = async () => {
    let buy_pt_id = getValue(thisObj, 'ddShipFromToLocation')
    let trade_insp_num = getValue(thisObj, 'txtTradeInspectSC95')
    let txtTradeSettleNum = getValue(thisObj, 'txtTradeSettle1007')
    let strCorrectionOrSupersede = ''
    let cid = 'Reversal'
    let bCompletedAgreementApplied =false
    let RetrieveTradeSettlementApplicationDetails =await SettlementService.RetrieveTradeSettlementApplicationDetails(trade_insp_num, 'N')
    if (RetrieveTradeSettlementApplicationDetails.length > 0) {
      let status = RetrieveTradeSettlementApplicationDetails[0].agree_status
      if (status == "C") {
        bCompletedAgreementApplied =true
      }
    }
    if(bCompletedAgreementApplied ==true){
      alert('This Trade Settlement cannot be reversed because it has been applied to a completed agreement.')
    }else{
      if (confirm(`Are you sure you want to reverse the Trade #${trade_insp_num}`) == true) {
        if (txtTradeSettleNum !== '') {
          strCorrectionOrSupersede = prompt("You must choose whether to correct or supersede the 1007.Enter 'C' for Correction or 'S' for Supersede.", "Correction/Supersede Trade Settlement")
        } else {
          strCorrectionOrSupersede = "C"
        }
        let dataObj = {
          "correction_or_supersede": strCorrectionOrSupersede,
          "user_id": useridFromLS
        }
        if (strCorrectionOrSupersede == "C" || strCorrectionOrSupersede == "S") {
          let UpdateTradeSettlementReverseDetails = await SettlementService.UpdateTradeSettlementReverseDetails(buy_pt_id, trade_insp_num, cid, dataObj);
          if (UpdateTradeSettlementReverseDetails.status == 200) {
            setValue(thisObj, 'txtSts', 'Pending')
            alert(`The trade #"${trade_insp_num} has been successfully reversed!!!`)
            let js = [{
              lbl_firstload: "D"
            }]
            setData(thisObj, 'Tradeagreesearchdata', js)
            setData(thisObj, 'CompletionStatus', true);
            document.getElementById("Settlements_TradeSettleProfilePopUp").childNodes[0].click();
          } else {
            alert(UpdateTradeSettlementReverseDetails.message);
          }
  
        }
      }
    }
    
  
  };
  thisObj.onbtnReversalClick = onbtnReversalClick;

  const ontxtTradeInspectSC95Change = () => {
    try {
      let txtTradeInspectSC95LowerCase = getValue(thisObj, 'txtTradeInspectSC95')
      let txtTradeInspectSC95 = txtTradeInspectSC95LowerCase.toUpperCase()
      setValue(thisObj, "txtTradeInspectSC95", txtTradeInspectSC95)
    } catch (err) {
      showMessage(thisObj, err.message)
    }
  }
  thisObj.ontxtTradeInspectSC95Change = ontxtTradeInspectSC95Change;

  // Check Vicam Reading Checkbox
  const onchkboxReGradingChange = () => {
    let chkboxReGrading = getValue(thisObj, 'chkboxReGrading')
    let vicamselected = chkboxReGrading.includes('3')
    if (vicamselected == true) {
      enable(thisObj, 'txtVicamReading')
    } else {
      disable(thisObj, 'txtVicamReading')
    }
    setFormDirty(true);
  };
  thisObj.onchkboxReGradingChange = onchkboxReGradingChange;

  const onradioTradeSaleTradePurchaseChange = () => {
    try {
      let radioTradeSaleTradePurchase = getValue(thisObj, 'radioTradeSaleTradePurchase')
      bFillCategoryList(null, radioTradeSaleTradePurchase)
      if (radioTradeSaleTradePurchase == 'P') {
        document.getElementsByClassName('ddShipFromToLocation')[0].childNodes[0].childNodes[0].innerText = 'Received Into Location:'
        document.getElementsByClassName('lblShippedFrom')[0].childNodes[0].childNodes[0].innerText = 'Received Into'
        document.getElementsByClassName('lblShippedTo')[0].childNodes[0].childNodes[0].innerText = 'Received From'
        document.getElementsByClassName('ddIDTfromtoCompany')[0].childNodes[0].childNodes[0].innerText = 'IDT from Company:'
        document.getElementsByClassName('txtIDTfromtoVendor')[0].childNodes[0].childNodes[0].innerText = 'IDT from Vendor:'
        document.getElementsByClassName('ddIDTfromtoLocation')[0].childNodes[0].childNodes[0].innerText = 'IDT from Location:'

      } else {
        document.getElementsByClassName('ddShipFromToLocation')[0].childNodes[0].childNodes[0].innerText = 'Shipped From Location:'
        document.getElementsByClassName('lblShippedFrom')[0].childNodes[0].childNodes[0].innerText = 'Shipped From'
        document.getElementsByClassName('lblShippedTo')[0].childNodes[0].childNodes[0].innerText = 'Shipped To'
        document.getElementsByClassName('ddIDTfromtoCompany')[0].childNodes[0].childNodes[0].innerText = 'IDT to Company:'
        document.getElementsByClassName('txtIDTfromtoVendor')[0].childNodes[0].childNodes[0].innerText = 'IDT to Vendor:'
        document.getElementsByClassName('ddIDTfromtoLocation')[0].childNodes[0].childNodes[0].innerText = 'IDT to Location:'
      }
    }
    catch (err) {

    }
  };
  thisObj.onradioTradeSaleTradePurchaseChange = onradioTradeSaleTradePurchaseChange

  const onddIDTfromtoLocationChange = () => {
    try {
      bFillWarehouseBinList('')
    }
    catch (err) {

    }
  };
  thisObj.onddIDTfromtoLocationChange = onddIDTfromtoLocationChange

  const ontxtTotalKernelsChange = () => {
    try {
      let toltaldamage = getValue(thisObj, 'txtTotalDamagePct')
      let txtHullsPct = getValue(thisObj, 'txtHullsPct')
      let value = parseFloat(toltaldamage) + parseFloat(txtHullsPct)

      setValue(thisObj, 'txtTotalKernelsAndHulls', value)
    }
    catch (err) {

    }
  };
  thisObj.ontxtTotalKernelsChange = ontxtTotalKernelsChange

  // useing rounding the 1 digit value purpose
  function roundup1digit(value) {
    let addpercent = 0.0
    addpercent = addpercent + parseFloat(value)
    if (value !== '' && value !== undefined && value !== 'NaN') {
      let value = Number(addpercent.toFixed(1))
      let newnum = value.toFixed(1)
      let newvalue = (newnum.toString()).slice(0, 6)
      return newvalue
    } else {
      return ''
    }
  }
  // useing rounding the 2 digit value purpose
  function roundup(value) {
    let addpercent = 0.00
    addpercent = addpercent + parseFloat(value)
    if (value !== '' && value !== undefined && value !== 'NaN') {
      let value = Number(addpercent.toFixed(1))
      let newnum = value.toFixed(2)
      let newvalue = (newnum.toString()).slice(0, 6)
      return newvalue
    } else {
      return ''
    }
  }

  const ontxtOKRidingPctBlur = () => {
    let txtOKRidingPctv = getValue(thisObj, 'txtOKRidingPct')
    setValue(thisObj, 'txtOKRidingPct', roundup(txtOKRidingPctv))

  }
  thisObj.ontxtOKRidingPctBlur = ontxtOKRidingPctBlur

  const ontxtOKFallThruBlur = () => {
    let txtOKFallThruv = getValue(thisObj, 'txtOKFallThru')
    setValue(thisObj, 'txtOKFallThru', roundup(txtOKFallThruv))
  }
  thisObj.ontxtOKFallThruBlur = ontxtOKFallThruBlur

  const ontxtLSKDamageRidingBlur = () => {
    let txtLSKDamageRidingv = getValue(thisObj, 'txtLSKDamageRiding')
    setValue(thisObj, 'txtLSKDamageRiding', roundup(txtLSKDamageRidingv))
  }
  thisObj.ontxtLSKDamageRidingBlur = ontxtLSKDamageRidingBlur

  const ontxtTotalDamagePctBlur = () => {
    let txtTotalDamagePctv = getValue(thisObj, 'txtTotalDamagePct')
    setValue(thisObj, 'txtTotalDamagePct', roundup(txtTotalDamagePctv))
    EnableDisableCalculate()
    CalculateRegradingTotals();
  }
  thisObj.ontxtTotalDamagePctBlur = ontxtTotalDamagePctBlur

  const ontxtKernRidingELKPctBlur = () => {
    let txtKernRidingELKPctv = getValue(thisObj, 'txtKernRidingELKPct')
    setValue(thisObj, 'txtKernRidingELKPct', roundup(txtKernRidingELKPctv))
  }
  thisObj.ontxtKernRidingELKPctBlur = ontxtKernRidingELKPctBlur

  const ontxtKernRidingELKBlur = () => {
    let txtKernRidingELKv = getValue(thisObj, 'txtKernRidingELK')
    setValue(thisObj, 'txtKernRidingELK', roundup1digit(txtKernRidingELKv))
  }
  thisObj.ontxtKernRidingELKBlur = ontxtKernRidingELKBlur

  const ontxtKernRidingPrescribeBlur = () => {
    let txtKernRidingPrescribev = getValue(thisObj, 'txtKernRidingPrescribe')
    setValue(thisObj, 'txtKernRidingPrescribe', roundup1digit(txtKernRidingPrescribev))
  }
  thisObj.ontxtKernRidingPrescribeBlur = ontxtKernRidingPrescribeBlur

  const ontxtKernRidingPrescribePctBlur = () => {
    let txtKernRidingPrescribePctv = getValue(thisObj, 'txtKernRidingPrescribePct')
    setValue(thisObj, 'txtKernRidingPrescribePct', roundup(txtKernRidingPrescribePctv))
  }
  thisObj.ontxtKernRidingPrescribePctBlur = ontxtKernRidingPrescribePctBlur

  const ontxtFMSampleWtBlur = () => {
    let txtFMSampleWtv = getValue(thisObj, 'txtFMSampleWt')
    let addpercent = 0.0
    addpercent = addpercent + parseFloat(txtFMSampleWtv)
    if (txtFMSampleWtv !== '' && txtFMSampleWtv !== undefined && txtFMSampleWtv !== 'NaN') {
      let value = addpercent.toFixed(1)
      if (value.length >= 9) {
        setValue(thisObj, "txtFMSampleWt", '0.0')
      } else {
        setValue(thisObj, "txtFMSampleWt", value)
      }
    } else {
      setValue(thisObj, "txtFMSampleWt", '')
    }
    EnableDisableCalculate()
  }
  thisObj.ontxtFMSampleWtBlur = ontxtFMSampleWtBlur;

  const ontxtCleanSampleWtBlur = () => {
    let txtCleanSampleWtv = getValue(thisObj, 'txtCleanSampleWt')
    let addpercent = 0.0
    addpercent = addpercent + parseFloat(txtCleanSampleWtv)
    if (txtCleanSampleWtv !== '' && txtCleanSampleWtv !== undefined && txtCleanSampleWtv !== 'NaN') {
      let value = addpercent.toFixed(1)
      if (value.length >= 9) {
        setValue(thisObj, "txtCleanSampleWt", '0.0')
      } else {
        setValue(thisObj, "txtCleanSampleWt", value)
      }
    } else {
      setValue(thisObj, "txtCleanSampleWt", '')
    }
    EnableDisableCalculate()
  }
  thisObj.ontxtCleanSampleWtBlur = ontxtCleanSampleWtBlur;

  const ontxtTotalSMKRScreenBlur = () => {
    let txtTotalSMKRScreenv = getValue(thisObj, 'txtTotalSMKRScreen')
    setValue(thisObj, "txtTotalSMKRScreen", roundup(txtTotalSMKRScreenv))
    EnableDisableCalculate()
    CalculateRegradingTotals();
  }
  thisObj.ontxtTotalSMKRScreenBlur = ontxtTotalSMKRScreenBlur;

  const ontxtSoundSplitsBlur = () => {
    let txtSoundSplitsv = getValue(thisObj, 'txtSoundSplits')
    setValue(thisObj, "txtSoundSplits", roundup1digit(txtSoundSplitsv))

    EnableDisableCalculate()
    CalculateRegradingTotals();
  }
  thisObj.ontxtSoundSplitsBlur = ontxtSoundSplitsBlur;

  const ontxtTotalOKPctBlur = () => {
    let txtTotalOKPctv = getValue(thisObj, 'txtTotalOKPct')
    setValue(thisObj, "txtTotalOKPct", roundup(txtTotalOKPctv))

    EnableDisableCalculate()
  }
  thisObj.ontxtTotalOKPctBlur = ontxtTotalOKPctBlur;

  const ontxtTotalOKBlur = () => {
    let txtTotalOKv = getValue(thisObj, 'txtTotalOK')
    setValue(thisObj, "txtTotalOK", roundup1digit(txtTotalOKv))

    EnableDisableCalculate()
    CalculateRegradingTotals();
  }
  thisObj.ontxtTotalOKBlur = ontxtTotalOKBlur;

  const ontxtTotalDamageBlur = () => {
    let txtTotalDamagev = getValue(thisObj, 'txtTotalDamage')
    setValue(thisObj, "txtTotalDamage", roundup1digit(txtTotalDamagev))

    EnableDisableCalculate()
    CalculateRegradingTotals();
  }
  thisObj.ontxtTotalDamageBlur = ontxtTotalDamageBlur;

  const ontxtHullsBlur = () => {
    let txtHullsv = getValue(thisObj, 'txtHulls')
    setValue(thisObj, "txtHulls", roundup1digit(txtHullsv))

    CalculateRegradingTotals();
  }
  thisObj.ontxtHullsBlur = ontxtHullsBlur;

  const ontxtFreezeDamageBlur = () => {
    let txtFreezeDamagev = getValue(thisObj, 'txtFreezeDamage')
    setValue(thisObj, "txtFreezeDamage", roundup1digit(txtFreezeDamagev))
  }
  thisObj.ontxtFreezeDamageBlur = ontxtFreezeDamageBlur;

  const ontxtConcealedRMDBlur = () => {
    setValue(thisObj, "txtConcealedRMD", roundup1digit(txtcolInventoryWt))

  }
  thisObj.ontxtConcealedRMDBlur = ontxtConcealedRMDBlur;

  const ontxtPittingDamageBlur = () => {
    let txtPittingDamagev = getValue(thisObj, 'txtPittingDamage')
    setValue(thisObj, "txtPittingDamage", roundup1digit(txtPittingDamagev))

  }
  thisObj.ontxtPittingDamageBlur = ontxtPittingDamageBlur;

  const ontxtPittingDamagePctBlur = () => {
    let txtPittingDamagePctv = getValue(thisObj, 'txtPittingDamagePct')
    setValue(thisObj, "txtPittingDamagePct", roundup(txtPittingDamagePctv))

  }
  thisObj.ontxtPittingDamagePctBlur = ontxtPittingDamagePctBlur;

  const ontxtPittingDamagegrBlur = () => {
    let txtPittingDamagegrv = getValue(thisObj, 'txtPittingDamagegr')
    setValue(thisObj, "txtPittingDamagegr", roundup1digit(txtPittingDamagegrv))

  }
  thisObj.ontxtPittingDamagegrBlur = ontxtPittingDamagegrBlur;

  const ontxtCrackedBrokenBlur = () => {
    let txtCrackedBrokenv = getValue(thisObj, 'txtCrackedBroken')
    setValue(thisObj, "txtCrackedBroken", roundup1digit(txtCrackedBrokenv))

    EnableDisableCalculate()
  }
  thisObj.ontxtCrackedBrokenBlur = ontxtCrackedBrokenBlur;


  const ontxtDiscoloredShellsBlur = () => {
    let txtDiscoloredShellsv = getValue(thisObj, 'txtDiscoloredShells')
    setValue(thisObj, "txtDiscoloredShells", roundup1digit(txtDiscoloredShellsv))

    EnableDisableCalculate()
  }
  thisObj.ontxtDiscoloredShellsBlur = ontxtDiscoloredShellsBlur;


  const ontxtHullBrightnessBlur = () => {
    let txtHullBrightnessv = getValue(thisObj, 'txtHullBrightness')
    setValue(thisObj, "txtHullBrightness", roundup1digit(txtHullBrightnessv))

  }
  thisObj.ontxtHullBrightnessBlur = ontxtHullBrightnessBlur;


  const ontxtJumboBlur = () => {
    let txtJumbov = getValue(thisObj, 'txtJumbo')
    setValue(thisObj, "txtJumbo", roundup1digit(txtJumbov))

  }
  thisObj.ontxtJumboBlur = ontxtJumboBlur;


  const ontxtTotalELKDamageBlur = () => {
    let txtTotalELKDamagev = getValue(thisObj, 'txtTotalELKDamage')
    setValue(thisObj, "txtTotalELKDamage", roundup1digit(txtTotalELKDamagev))

  }
  thisObj.ontxtTotalELKDamageBlur = ontxtTotalELKDamageBlur;

  const ontxtNetELKBlur = () => {
    let txtNetELKv = getValue(thisObj, 'txtNetELK')
    setValue(thisObj, "txtNetELK", roundup1digit(txtNetELKv))

    EnableDisableCalculate()
  }
  thisObj.ontxtNetELKBlur = ontxtNetELKBlur;


  const ontxtForeignMaterialBlur = () => {
    let txtForeignMaterialv = getValue(thisObj, 'txtForeignMaterial')
    setValue(thisObj, "txtForeignMaterial", roundup1digit(txtForeignMaterialv))

    EnableDisableCalculate()
  }
  thisObj.ontxtForeignMaterialBlur = ontxtForeignMaterialBlur;

  const ontxtOKDamageRidingBlur = () => {
    let txtOKDamageRidingv = getValue(thisObj, 'txtOKDamageRiding')
    setValue(thisObj, "txtOKDamageRiding", roundup(txtOKDamageRidingv))

  }
  thisObj.ontxtOKDamageRidingBlur = ontxtOKDamageRidingBlur;

  const ontxtTotalSLKPctBlur = () => {
    let txtTotalSLKPctv = getValue(thisObj, 'txtTotalSLKPct')
    setValue(thisObj, "txtTotalSLKPct", roundup(txtTotalSLKPctv))

    EnableDisableCalculate()
  }
  thisObj.ontxtTotalSLKPctBlur = ontxtTotalSLKPctBlur;

  const ontxtTotalSLKBlur = () => {
    let txtTotalSLKv = getValue(thisObj, 'txtTotalSLK')
    setValue(thisObj, "txtTotalSLK", roundup1digit(txtTotalSLKv))

    EnableDisableCalculate()
  }
  thisObj.ontxtTotalSLKBlur = ontxtTotalSLKBlur;

  const ontxtFancyBlur = () => {
    let txtFancyv = getValue(thisObj, 'txtFancy')
    setValue(thisObj, "txtFancy", roundup1digit(txtFancyv))

    EnableDisableCalculate()
  }
  thisObj.ontxtFancyBlur = ontxtFancyBlur;


  const ontxtDamageSplitsBlur = () => {
    let txtDamageSplitsv = getValue(thisObj, 'txtDamageSplits')
    setValue(thisObj, "txtDamageSplits", roundup(txtDamageSplitsv))

    EnableDisableCalculate()
  }
  thisObj.ontxtDamageSplitsBlur = ontxtDamageSplitsBlur;

  const ontxtTotalSMKRScreenPctBlur = () => {
    CalculateRegradingTotals();
    let txtTotalSMKRScreenPctv = getValue(thisObj, 'txtTotalSMKRScreenPct')
    setValue(thisObj, "txtTotalSMKRScreenPct", roundup(txtTotalSMKRScreenPctv))
    EnableDisableCalculate()
  }
  thisObj.ontxtTotalSMKRScreenPctBlur = ontxtTotalSMKRScreenPctBlur;

  const ontxtSMK1PctBlur = () => {
    let txtSMK1Pctv = getValue(thisObj, 'txtSMK1Pct')
    setValue(thisObj, "txtSMK1Pct", roundup(txtSMK1Pctv))
  }
  thisObj.ontxtSMK1PctBlur = ontxtSMK1PctBlur;

  const ontxtSMK2PctBlur = () => {
    let txtSMK2Pctv = getValue(thisObj, 'txtSMK2Pct')
    setValue(thisObj, "txtSMK2Pct", roundup(txtSMK2Pctv))
  }
  thisObj.ontxtSMK2PctBlur = ontxtSMK2PctBlur;

  const ontxtSMK3PctBlur = () => {
    let txtSMK3Pctv = getValue(thisObj, 'txtSMK3Pct')
    setValue(thisObj, "txtSMK3Pct", roundup(txtSMK3Pctv))
  }
  thisObj.ontxtSMK3PctBlur = ontxtSMK3PctBlur;


  const ontxtSMK4PctBlur = () => {
    let txtSMK4Pctv = getValue(thisObj, 'txtSMK4Pct')
    setValue(thisObj, "txtSMK4Pct", roundup(txtSMK4Pctv))
  }
  thisObj.ontxtSMK4PctBlur = ontxtSMK4PctBlur;


  const ontxtSoundSplitsPctBlur = () => {
    let txtSoundSplitsPctv = getValue(thisObj, 'txtSoundSplitsPct')
    setValue(thisObj, "txtSoundSplitsPct", roundup(txtSoundSplitsPctv))
    EnableDisableCalculate()
    CalculateRegradingTotals();
  }
  thisObj.ontxtSoundSplitsPctBlur = ontxtSoundSplitsPctBlur;

  const ontxtHullsPctBlur = () => {
    let txtHullsPctv = getValue(thisObj, 'txtHullsPct')
    setValue(thisObj, "txtHullsPct", roundup(txtHullsPctv))
    EnableDisableCalculate()
    CalculateRegradingTotals();
  }
  thisObj.ontxtHullsPctBlur = ontxtHullsPctBlur;

  const ontxtFreezeDamagePctBlur = () => {
    let txtFreezeDamagePctv = getValue(thisObj, 'txtFreezeDamagePct')
    setValue(thisObj, "txtFreezeDamagePct", roundup(txtFreezeDamagePctv))
    EnableDisableCalculate()
  }
  thisObj.ontxtFreezeDamagePctBlur = ontxtFreezeDamagePctBlur;

  const ontxtConcealedRMDPctBlur = () => {
    let txtConcealedRMDPctv = getValue(thisObj, 'txtConcealedRMDPct')
    setValue(thisObj, "txtConcealedRMDPct", roundup(txtConcealedRMDPctv))
  }
  thisObj.ontxtConcealedRMDPctBlur = ontxtConcealedRMDPctBlur;

  const ontxtCrackedBrokenPctBlur = () => {
    let txtCrackedBrokenPctv = getValue(thisObj, 'txtCrackedBrokenPct')
    setValue(thisObj, "txtCrackedBrokenPct", roundup(txtCrackedBrokenPctv))
    EnableDisableCalculate()
  }
  thisObj.ontxtCrackedBrokenPctBlur = ontxtCrackedBrokenPctBlur;

  const ontxtDiscoloredShellsPctBlur = () => {
    let txtDiscoloredShellsPctv = getValue(thisObj, 'txtDiscoloredShellsPct')
    setValue(thisObj, "txtDiscoloredShellsPct", roundup(txtDiscoloredShellsPctv))
    EnableDisableCalculate()
  }
  thisObj.ontxtDiscoloredShellsPctBlur = ontxtDiscoloredShellsPctBlur;

  const ontxtHullBrightnessPctBlur = () => {
    let txtHullBrightnessPctv = getValue(thisObj, 'txtHullBrightnessPct')
    setValue(thisObj, "txtHullBrightnessPct", roundup(txtHullBrightnessPctv))
  }
  thisObj.ontxtHullBrightnessPctBlur = ontxtHullBrightnessPctBlur;

  const ontxtJumboPctBlur = () => {
    let txtJumboPctv = getValue(thisObj, 'txtJumboPct')
    setValue(thisObj, "txtJumboPct", roundup(txtJumboPctv))

  }
  thisObj.ontxtJumboPctBlur = ontxtJumboPctBlur;

  const ontxtNetELKPctBlur = () => {
    let txtNetELKPctv = getValue(thisObj, 'txtNetELKPct')
    setValue(thisObj, "txtNetELKPct", roundup(txtNetELKPctv))
    EnableDisableCalculate()
  }
  thisObj.ontxtNetELKPctBlur = ontxtNetELKPctBlur;


  const ontxtForeignMaterialPctBlur = () => {
    let txtForeignMaterialPctv = getValue(thisObj, 'txtForeignMaterialPct')
    setValue(thisObj, "txtForeignMaterialPct", roundup(txtForeignMaterialPctv))
    EnableDisableCalculate()
  }
  thisObj.ontxtForeignMaterialPctBlur = ontxtForeignMaterialPctBlur;

  const ontxtFancyPctBlur = () => {
    let txtFancyPctv = getValue(thisObj, 'txtFancyPct')
    setValue(thisObj, "txtFancyPct", roundup(txtFancyPctv))
    EnableDisableCalculate()
  }
  thisObj.ontxtFancyPctBlur = ontxtFancyPctBlur;


  const ontxtMoistureBlur = () => {
    let txtMoisturev = getValue(thisObj, 'txtMoisture')
    setValue(thisObj, "txtMoisture", roundup(txtMoisturev))

    EnableDisableCalculate()
  }
  thisObj.ontxtMoistureBlur = ontxtMoistureBlur;

  const ontxtKernelsRELBlur = () => {
    let txtKernelsRELv = getValue(thisObj, 'txtKernelsREL')
    setValue(thisObj, "txtKernelsREL", roundup1digit(txtKernelsRELv))

  }
  thisObj.ontxtKernelsRELBlur = ontxtKernelsRELBlur;


  const ontxtKernelsRPSBlur = () => {
    let txtKernelsRPSv = getValue(thisObj, 'txtKernelsRPS')
    setValue(thisObj, "txtKernelsRPS", roundup1digit(txtKernelsRPSv))

  }
  thisObj.ontxtKernelsRPSBlur = ontxtKernelsRPSBlur;


  const ontxtDamagedKRSBlur = () => {
    let txtDamagedKRSv = getValue(thisObj, 'txtDamagedKRS')
    setValue(thisObj, "txtDamagedKRS", roundup1digit(txtDamagedKRSv))

  }
  thisObj.ontxtDamagedKRSBlur = ontxtDamagedKRSBlur;

  const ontxtLSKFallThruBlur = () => {
    let txtLSKFallThruv = getValue(thisObj, 'txtLSKFallThru')
    setValue(thisObj, "txtLSKFallThru", roundup(txtLSKFallThruv))
  }
  thisObj.ontxtLSKFallThruBlur = ontxtLSKFallThruBlur;

  const ontxtLSKRidingPctBlur = () => {
    let txtLSKRidingPctv = getValue(thisObj, 'txtLSKRidingPct')
    setValue(thisObj, "txtLSKRidingPct", roundup(txtLSKRidingPctv))

  }
  thisObj.ontxtLSKRidingPctBlur = ontxtLSKRidingPctBlur;

  const ontxtMeterReadingBlur = () => {
    let txtMeterReadingv = getValue(thisObj, 'txtMeterReading')
    setValue(thisObj, "txtMeterReading", roundup1digit(txtMeterReadingv))

  }
  thisObj.ontxtMeterReadingBlur = ontxtMeterReadingBlur;


  const ontxtBluePanWTBlur = () => {
    let txtBluePanWTv = getValue(thisObj, 'txtBluePanWT')
    setValue(thisObj, "txtBluePanWT", roundup1digit(txtBluePanWTv))

  }
  thisObj.ontxtBluePanWTBlur = ontxtBluePanWTBlur;


  const ontxtELKDamagedBlur = () => {
    let txtELKDamagedv = getValue(thisObj, 'txtELKDamaged')
    setValue(thisObj, "txtELKDamaged", roundup1digit(txtELKDamagedv))

  }
  thisObj.ontxtELKDamagedBlur = ontxtELKDamagedBlur;

  const ontxtVicamReadingBlur = () => {
    EnableDisableCalculate()
  }
  thisObj.ontxtVicamReadingBlur = ontxtVicamReadingBlur;

  const ontxtLSKDamageRidingChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtLSKDamageRidingChange = ontxtLSKDamageRidingChange;

  const ontxtLSKFallThruChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtLSKFallThruChange = ontxtLSKFallThruChange;

  const ontxtLSKRidingChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtLSKRidingChange = ontxtLSKRidingChange;

  const ontxtOKFallThruChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtOKFallThruChange = ontxtOKFallThruChange;

  const ontxtOKRidingChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtOKRidingChange = ontxtOKRidingChange;

  const ontxtOKDamageRidingChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtOKDamageRidingChange = ontxtOKDamageRidingChange;

  const ontxttxtOtherFMChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxttxtOtherFMChange = ontxttxtOtherFMChange;

  const ontxtBluePanWTChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtBluePanWTChange = ontxtBluePanWTChange;

  const ontxtDamagedKRSChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtDamagedKRSChange = ontxtDamagedKRSChange;

  const ontxtELKDamagedChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtELKDamagedChange = ontxtELKDamagedChange;

  const ontxtKernelRELSChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtKernelRELSChange = ontxtKernelRELSChange;

  const ontxtKernelRPSChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtKernelRPSChange = ontxtKernelRPSChange;

  const ontxtMeterReadingChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtMeterReadingChange = ontxtMeterReadingChange;

  const ontxtProbePatternChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtProbePatternChange = ontxtProbePatternChange;

  const ontxtOtherLocationChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtOtherLocationChange = ontxtOtherLocationChange;

  const ontxtPittingDamagePctChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtPittingDamagePctChange = ontxtPittingDamagePctChange;

  const ontxtSealNumChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtSealNumChange = ontxtSealNumChange;

  const ontxtSettleRemarksChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtSettleRemarksChange = ontxtSettleRemarksChange;

  const ontxtShareChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtShareChange = ontxtShareChange;

  const ontxtSMk1PctChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtSMk1PctChange = ontxtSMk1PctChange;

  const ontxtSMk2PctChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtSMk2PctChange = ontxtSMk2PctChange;

  const ontxtSMk3PctChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtSMk3PctChange = ontxtSMk3PctChange;

  const ontxtSMK4PctChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtSMK4PctChange = ontxtSMK4PctChange;

  const ontxtStartDateTimeChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtStartDateTimeChange = ontxtStartDateTimeChange;

  const ontxtTotalELKDamagePctChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtTotalELKDamagePctChange = ontxtTotalELKDamagePctChange;

  const ontxtWormDamagePctChange = () => {
    setFormDirty(true);
  };
  thisObj.ontxtWormDamagePctChange = ontxtWormDamagePctChange;

  const ontxtMoistureChange = () => {
    EnableDisableCalculate();
    setFormDirty(true);
  };
  thisObj.ontxtMoistureChange = ontxtMoistureChange;

  const ontxtVicamReadingChange = () => {
    EnableDisableCalculate();
    setFormDirty(true);
  };
  thisObj.ontxtVicamReadingChange = ontxtVicamReadingChange;

  const ontxtTotalLSKPctChange = () => {
    EnableDisableCalculate();
    setFormDirty(true);
  };
  thisObj.ontxtTotalLSKPctChange = ontxtTotalLSKPctChange;

  const ontxtNetELKPctChange = () => {
    EnableDisableCalculate();
    setFormDirty(true);
  };
  thisObj.ontxtNetELKPctChange = ontxtNetELKPctChange;

  const ontxtHullsPctChange = () => {
    EnableDisableCalculate();
    CalculateRegradingTotals();
    setFormDirty(true);
  };
  thisObj.ontxtHullsPctChange = ontxtHullsPctChange;

  const ontxtOtherKernalsPctChange = () => {
    EnableDisableCalculate();
    CalculateRegradingTotals();
    setFormDirty(true);
  };
  thisObj.ontxtOtherKernalsPctChange = ontxtOtherKernalsPctChange;

  const ontxtTotalSMKRScreenPctChange = () => {
    EnableDisableCalculate();
    CalculateRegradingTotals();
    setFormDirty(true);
  };
  thisObj.ontxtTotalSMKRScreenPctChange = ontxtTotalSMKRScreenPctChange;

  const ontxtSoundSplitsPctChange = () => {
    EnableDisableCalculate();
    CalculateRegradingTotals();
    setFormDirty(true);
  };
  thisObj.ontxtSoundSplitsPctChange = ontxtSoundSplitsPctChange;

  const ontxtTotalDamagePctChange = () => {
    EnableDisableCalculate();
    CalculateRegradingTotals();
    setFormDirty(true);
  };
  thisObj.ontxtTotalDamagePctChange = ontxtTotalDamagePctChange;

  const onddWhseBinNumChange = () => {
    setFormDirty(true);
  }
  thisObj.onddWhseBinNumChange = onddWhseBinNumChange;

  const onchkboxPrintSegChange = () => {
    setFormDirty(true);
  }
  thisObj.onchkboxPrintSegChange = onchkboxPrintSegChange;


  const ontxtConcealedRMDPctChange = () => {
    setFormDirty(true);
  }
  thisObj.ontxtConcealedRMDPctChange = ontxtConcealedRMDPctChange;

  const ontxtCrackedBrokenPctChange = () => {
    EnableDisableCalculate()
    setFormDirty(true);
  }
  thisObj.ontxtCrackedBrokenPctChange = ontxtCrackedBrokenPctChange;

  const ontxtDamageSplitsChange = () => {
    setFormDirty(true);
  }
  thisObj.ontxtDamageSplitsChange = ontxtDamageSplitsChange;

  const ontxtDiscoloredShellsPctChange = () => {
    EnableDisableCalculate()
    setFormDirty(true);
  }
  thisObj.ontxtDiscoloredShellsPctChange = ontxtDiscoloredShellsPctChange;

  const ontxtFancyPctChange = () => {
    EnableDisableCalculate()
    setFormDirty(true);
  }
  thisObj.ontxtFancyPctChange = ontxtFancyPctChange;

  const ontxtForeignMaterialPctChange = () => {
    EnableDisableCalculate()
    setFormDirty(true);
  }
  thisObj.ontxtForeignMaterialPctChange = ontxtForeignMaterialPctChange;

  const ontxtFreezeDamagePctChange = () => {
    setFormDirty(true);
  }
  thisObj.ontxtFreezeDamagePctChange = ontxtFreezeDamagePctChange;

  const ontxtHandlerNameChange = () => {
    setFormDirty(true);
  }
  thisObj.ontxtHandlerNameChange = ontxtHandlerNameChange;

  const ontxtHandlerNumChange = () => {
    setFormDirty(true);
  }
  thisObj.ontxtHandlerNumChange = ontxtHandlerNumChange;

  const ontxtHullBrightnessPctChange = () => {
    setFormDirty(true);
  }
  thisObj.ontxtHullBrightnessPctChange = ontxtHullBrightnessPctChange;

  const ondtInspectDateTimeChange = () => {
    let dtInspectDateTime= getValue(thisObj,'dtInspectDateTime')
    if(dtInspectDateTime !=='' && dtInspectDateTime !==undefined){
      setFormDirty(true);
    }
  }
  thisObj.ondtInspectDateTimeChange = ondtInspectDateTimeChange;

  const ontxtInspRemarksChange = () => {
    setFormDirty(true);
  }
  thisObj.ontxtInspRemarksChange = ontxtInspRemarksChange;

  const ontxtJumboPctChange = () => {
    setFormDirty(true);
  }
  thisObj.ontxtJumboPctChange = ontxtJumboPctChange;

  const ontxtKernRidingELKPctChange = () => {
    setFormDirty(true);
  }
  thisObj.ontxtKernRidingELKPctChange = ontxtKernRidingELKPctChange;

  const ontxtKernRidingPrescribePctChange = () => {
    setFormDirty(true);
  }
  thisObj.ontxtKernRidingPrescribePctChange = ontxtKernRidingPrescribePctChange;

  const ontxtLoadChange = () => {
    setFormDirty(true);
  }
  thisObj.ontxtLoadChange = ontxtLoadChange;


  const ontxtLotChange = () => {
    setFormDirty(true);
  }
  thisObj.ontxtLotChange = ontxtLotChange;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        setValues
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        thisObj.setValues = setValues = setValues;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={() => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <ContractManagement_header headerData={state.headerData} />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              <Loading loading={loading}></Loading>
              {/* START_USER_CODE-USER_BEFORE_TradeSettleProfile*/}

              {/* END_USER_CODE-USER_BEFORE_TradeSettleProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxTradeDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxTradeDetails*/}

              <GroupBoxWidget conf={state.grpbxTradeDetails} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtTradeInspectSC95*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeInspectSC95*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeInspectSC95}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeInspectSC95*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeInspectSC95*/}
                {/* START_USER_CODE-USER_BEFORE_txtSts*/}

                {/* END_USER_CODE-USER_BEFORE_txtSts*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSts}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSts*/}

                {/* END_USER_CODE-USER_AFTER_txtSts*/}
                {/* START_USER_CODE-USER_BEFORE_ddShipFromToLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddShipFromToLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddShipFromToLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddShipFromToLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddShipFromToLocation*/}
                {/* START_USER_CODE-USER_BEFORE_dtInspectDateTime*/}

                {/* END_USER_CODE-USER_BEFORE_dtInspectDateTime*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtInspectDateTime}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_dtInspectDateTime*/}

                {/* END_USER_CODE-USER_AFTER_dtInspectDateTime*/}
                {/* START_USER_CODE-USER_BEFORE_radioTradeSaleTradePurchase*/}

                {/* END_USER_CODE-USER_BEFORE_radioTradeSaleTradePurchase*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioTradeSaleTradePurchase}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioTradeSaleTradePurchase*/}

                {/* END_USER_CODE-USER_AFTER_radioTradeSaleTradePurchase*/}
                {/* START_USER_CODE-USER_BEFORE_ddCategory*/}

                {/* END_USER_CODE-USER_BEFORE_ddCategory*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddCategory}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddCategory*/}

                {/* END_USER_CODE-USER_AFTER_ddCategory*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeSettle1007*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeSettle1007*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeSettle1007}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeSettle1007*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeSettle1007*/}
                {/* START_USER_CODE-USER_BEFORE_txt1007Handler*/}

                {/* END_USER_CODE-USER_BEFORE_txt1007Handler*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007Handler}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1007Handler*/}

                {/* END_USER_CODE-USER_AFTER_txt1007Handler*/}
                {/* START_USER_CODE-USER_BEFORE_dtSettleDateTime*/}

                {/* END_USER_CODE-USER_BEFORE_dtSettleDateTime*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtSettleDateTime}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_dtSettleDateTime*/}

                {/* END_USER_CODE-USER_AFTER_dtSettleDateTime*/}
                {/* START_USER_CODE-USER_BEFORE_txt1007HandlerName*/}

                {/* END_USER_CODE-USER_BEFORE_txt1007HandlerName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007HandlerName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1007HandlerName*/}

                {/* END_USER_CODE-USER_AFTER_txt1007HandlerName*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplits*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplits*/}

                <GroupBoxWidget
                  conf={state.grpbxVendorSplits}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblVendorSplits*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVendorSplits*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVendorSplits}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVendorSplits*/}

                  {/* END_USER_CODE-USER_AFTER_lblVendorSplits*/}
                  {/* START_USER_CODE-USER_BEFORE_lblVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVendor*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVendor}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVendor*/}

                  {/* END_USER_CODE-USER_AFTER_lblVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_lblRemitTo*/}

                  {/* END_USER_CODE-USER_BEFORE_lblRemitTo*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblRemitTo}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblRemitTo*/}

                  {/* END_USER_CODE-USER_AFTER_lblRemitTo*/}
                  {/* START_USER_CODE-USER_BEFORE_lblSharePercentage*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSharePercentage*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSharePercentage}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSharePercentage*/}

                  {/* END_USER_CODE-USER_AFTER_lblSharePercentage*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitSub*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitSub*/}
                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitSub}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow0*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow0*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow0}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor1*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor1*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor1}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor1*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor1*/}
                      {/* START_USER_CODE-USER_BEFORE_btn1*/}

                      {/* END_USER_CODE-USER_BEFORE_btn1*/}

                      <ButtonWidget
                        conf={state.btn1}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn1")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn1*/}

                      {/* END_USER_CODE-USER_AFTER_btn1*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo1*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo1*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo1}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo1*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo1*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage1*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage1*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage1}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage1*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage1*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX1*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX1*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX1}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX1*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX1*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow0*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow0*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow1*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow1*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow1}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor2*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor2}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor2*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor2*/}
                      {/* START_USER_CODE-USER_BEFORE_btn2*/}

                      {/* END_USER_CODE-USER_BEFORE_btn2*/}

                      <ButtonWidget
                        conf={state.btn2}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn2")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn2*/}

                      {/* END_USER_CODE-USER_AFTER_btn2*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo2*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo2*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo2}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo2*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage2*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage2}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage2*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage2*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX2*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX2*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX2}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX2*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX2*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow1*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow1*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow2*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow2*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow2}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor3*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor3*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor3}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor3*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor3*/}
                      {/* START_USER_CODE-USER_BEFORE_btn3*/}

                      {/* END_USER_CODE-USER_BEFORE_btn3*/}

                      <ButtonWidget
                        conf={state.btn3}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn3")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn3*/}

                      {/* END_USER_CODE-USER_AFTER_btn3*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo3*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo3*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo3}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo3*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo3*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage3*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage3*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage3}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage3*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage3*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX3*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX3*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX3}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX3*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX3*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow2*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow2*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow3*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow3*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow3}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor4*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor4*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor4}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor4*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor4*/}
                      {/* START_USER_CODE-USER_BEFORE_btn4*/}

                      {/* END_USER_CODE-USER_BEFORE_btn4*/}

                      <ButtonWidget
                        conf={state.btn4}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn4")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn4*/}

                      {/* END_USER_CODE-USER_AFTER_btn4*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo4*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo4*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo4}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo4*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo4*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage4*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage4*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage4}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage4*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage4*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX4*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX4*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX4}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX4*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX4*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow3*/}
                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow4}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor5*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor5*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor5}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor5*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor5*/}
                      {/* START_USER_CODE-USER_BEFORE_btn5*/}

                      {/* END_USER_CODE-USER_BEFORE_btn5*/}

                      <ButtonWidget
                        conf={state.btn5}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn5")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn5*/}

                      {/* END_USER_CODE-USER_AFTER_btn5*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo5*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo5*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo5}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo5*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo5*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage5*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage5*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage5}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage5*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage5*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX5*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX5*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX5}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX5*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX5*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow5*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow5*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow6*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow6*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow5}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor6*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor6*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor6}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor6*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor6*/}
                      {/* START_USER_CODE-USER_BEFORE_btn6*/}

                      {/* END_USER_CODE-USER_BEFORE_btn6*/}

                      <ButtonWidget
                        conf={state.btn6}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn6")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn6*/}

                      {/* END_USER_CODE-USER_AFTER_btn6*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo6*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo6*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo6}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo6*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo6*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage6*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage6*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage6}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage6*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage6*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX6*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX6*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX6}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX6*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX6*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow6*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow6*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow7*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow7*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow6}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor7*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor7*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor7}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor7*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor7*/}
                      {/* START_USER_CODE-USER_BEFORE_btn7*/}

                      {/* END_USER_CODE-USER_BEFORE_btn7*/}

                      <ButtonWidget
                        conf={state.btn7}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn7")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn7*/}

                      {/* END_USER_CODE-USER_AFTER_btn7*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo7*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo7*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo7}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo7*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo7*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage7*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage7*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage7}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage7*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage7*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX7*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX7*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX7}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX7*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX7*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow7*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow7*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow8*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow8*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow7}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor8*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor8*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor8}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor8*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor8*/}
                      {/* START_USER_CODE-USER_BEFORE_btn8*/}

                      {/* END_USER_CODE-USER_BEFORE_btn8*/}

                      <ButtonWidget
                        conf={state.btn8}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn8")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn8*/}

                      {/* END_USER_CODE-USER_AFTER_btn8*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo8*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo8*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo8}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo8*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo8*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage8*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage8*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }

                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage8}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage8*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage8*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX8*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX8*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX8}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX8*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX8*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow8*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow8*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow9*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow9*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow8}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor9*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor9*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor9}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor9*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor9*/}
                      {/* START_USER_CODE-USER_BEFORE_btn9*/}

                      {/* END_USER_CODE-USER_BEFORE_btn9*/}

                      <ButtonWidget
                        conf={state.btn9}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn9")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn9*/}

                      {/* END_USER_CODE-USER_AFTER_btn9*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo9*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo9*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo9}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo9*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo9*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage9*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage9*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage9}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage9*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage9*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX9*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX9*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX9}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX9*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX9*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow9*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow9*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow10*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow10*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow9}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor10*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor10*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor10}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor10*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor10*/}
                      {/* START_USER_CODE-USER_BEFORE_btn10*/}

                      {/* END_USER_CODE-USER_BEFORE_btn10*/}

                      <ButtonWidget
                        conf={state.btn10}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn10")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn10*/}

                      {/* END_USER_CODE-USER_AFTER_btn10*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo10*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo10*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo10}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo10*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo10*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage10*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage10*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage10}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage10*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage10*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX10*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX10*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX10}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX10*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX10*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow10*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow10*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow11*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow11*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow10}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor11*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor11*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor11}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor11*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor11*/}
                      {/* START_USER_CODE-USER_BEFORE_btn11*/}

                      {/* END_USER_CODE-USER_BEFORE_btn11*/}

                      <ButtonWidget
                        conf={state.btn11}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn11")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn11*/}

                      {/* END_USER_CODE-USER_AFTER_btn11*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo11*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo11*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo11}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo11*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo11*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage11*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage11*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage11}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage11*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage11*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX11*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX11*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX11}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX11*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX11*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow11*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow11*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow12*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow12*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow11}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor12*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor12*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor12}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor12*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor12*/}
                      {/* START_USER_CODE-USER_BEFORE_btn12*/}

                      {/* END_USER_CODE-USER_BEFORE_btn12*/}

                      <ButtonWidget
                        conf={state.btn12}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn12")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn12*/}

                      {/* END_USER_CODE-USER_AFTER_btn12*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo12*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo12*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo12}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo12*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo12*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage12*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage12*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage12}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage12*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage12*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX12*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX12*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX12}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX12*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX12*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow12*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow12*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow13*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow13*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow13}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor13*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor13*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor13}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor13*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor13*/}
                      {/* START_USER_CODE-USER_BEFORE_btn13*/}

                      {/* END_USER_CODE-USER_BEFORE_btn13*/}

                      <ButtonWidget
                        conf={state.btn13}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn13")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn13*/}

                      {/* END_USER_CODE-USER_AFTER_btn13*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo13*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo13*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo13}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo13*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo13*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage13*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage13*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage13}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage13*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage13*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX13*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX13*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX13}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX13*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX13*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow13*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow13*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow14*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow14*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow12}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor14*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor14*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor14}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor14*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor14*/}
                      {/* START_USER_CODE-USER_BEFORE_btn14*/}

                      {/* END_USER_CODE-USER_BEFORE_btn14*/}

                      <ButtonWidget
                        conf={state.btn14}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn14")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn14*/}

                      {/* END_USER_CODE-USER_AFTER_btn14*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo14*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo14*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo14}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo14*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo14*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage14*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage14*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage14}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage14*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage14*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX14*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX14*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX14}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX14*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX14*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow14*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow14*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow15*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow15*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow14}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor15*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor15*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor15}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor15*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor15*/}
                      {/* START_USER_CODE-USER_BEFORE_btn15*/}

                      {/* END_USER_CODE-USER_BEFORE_btn15*/}

                      <ButtonWidget
                        conf={state.btn15}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn15")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn15*/}

                      {/* END_USER_CODE-USER_AFTER_btn15*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo15*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo15*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo15}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo15*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo15*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage15*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage15*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage15}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage15*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage15*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX15*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX15*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX15}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX15*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX15*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow15*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow15*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow16*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow16*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow15}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor16*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor16*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor16}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor16*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor16*/}
                      {/* START_USER_CODE-USER_BEFORE_btn16*/}

                      {/* END_USER_CODE-USER_BEFORE_btn16*/}

                      <ButtonWidget
                        conf={state.btn16}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn16")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn16*/}

                      {/* END_USER_CODE-USER_AFTER_btn16*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo16*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo16*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo16}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo16*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo16*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage16*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage16*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage16}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage16*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage16*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX16*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX16*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX16}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX16*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX16*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow16*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow16*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow17*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow17*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow16}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor17*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor17*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor17}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor17*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor17*/}
                      {/* START_USER_CODE-USER_BEFORE_btn17*/}

                      {/* END_USER_CODE-USER_BEFORE_btn17*/}

                      <ButtonWidget
                        conf={state.btn17}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn17")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn17*/}

                      {/* END_USER_CODE-USER_AFTER_btn17*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo17*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo17*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo17}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo17*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo17*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage17*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage17*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage17}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage17*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage17*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX17*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX17*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX17}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX17*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX17*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow17*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow17*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow18*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow18*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow17}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor18*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor18*/}
                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor18}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor18*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor18*/}
                      {/* START_USER_CODE-USER_BEFORE_btn18*/}

                      {/* END_USER_CODE-USER_BEFORE_btn18*/}

                      <ButtonWidget
                        conf={state.btn18}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn18")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn18*/}

                      {/* END_USER_CODE-USER_AFTER_btn18*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo18*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo18*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo18}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo18*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo18*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage18*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage18*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage18}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage18*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage18*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX18*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX18*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX18}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX18*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX18*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow18*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow18*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow19*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow19*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow18}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor19*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor19*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor19}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor19*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor19*/}
                      {/* START_USER_CODE-USER_BEFORE_btn19*/}

                      {/* END_USER_CODE-USER_BEFORE_btn19*/}

                      <ButtonWidget
                        conf={state.btn19}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn19")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn19*/}

                      {/* END_USER_CODE-USER_AFTER_btn19*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo19*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo19*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo19}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo19*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo19*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage19*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage19*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage19}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage19*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage19*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX19*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX19*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX19}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX19*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX19*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow19*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow19*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow20*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow20*/}

                    <GroupBoxWidget
                      conf={state.grpbxVendorSplitRow19}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtVendor20*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVendor20*/}

                      <AutocompleteWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtVendorBlur(event)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        conf={state.txtVendor20}
                        screenConf={state}
                      />
                      {/* START_USER_CODE-USER_AFTER_txtVendor20*/}

                      {/* END_USER_CODE-USER_AFTER_txtVendor20*/}
                      {/* START_USER_CODE-USER_BEFORE_btn20*/}

                      {/* END_USER_CODE-USER_BEFORE_btn20*/}

                      <ButtonWidget
                        conf={state.btn20}
                        screenConf={state}
                        onClick={event => {
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                          btnVendorClick("btn20")
                        }
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btn20*/}

                      {/* END_USER_CODE-USER_AFTER_btn20*/}
                      {/* START_USER_CODE-USER_BEFORE_ddRemitTo20*/}

                      {/* END_USER_CODE-USER_BEFORE_ddRemitTo20*/}

                      <DropDownWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddRemitTo20}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddRemitTo20*/}

                      {/* END_USER_CODE-USER_AFTER_ddRemitTo20*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSharePercentage20*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSharePercentage20*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event => txtsharePrcntgBlur(event)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSharePercentage20}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSharePercentage20*/}

                      {/* END_USER_CODE-USER_AFTER_txtSharePercentage20*/}
                      {/* START_USER_CODE-USER_BEFORE_lblXX20*/}

                      {/* END_USER_CODE-USER_BEFORE_lblXX20*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblXX20}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblXX20*/}

                      {/* END_USER_CODE-USER_AFTER_lblXX20*/}
                    </GroupBoxWidget>
                    {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow3*/}
                  </GroupBoxWidget>

                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitSub*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitSub*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxVendorSplits*/}

                {/* END_USER_CODE-USER_AFTER_grpbxVendorSplits*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxPntShippd*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPntShippd*/}

                <GroupBoxWidget conf={state.grpbxPntShippd} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_grpbxPeanutInfo*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPeanutInfo*/}

                  <GroupBoxWidget
                    conf={state.grpbxPeanutInfo}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblPeanutInformation*/}

                    {/* END_USER_CODE-USER_BEFORE_lblPeanutInformation*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblPeanutInformation}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblPeanutInformation*/}

                    {/* END_USER_CODE-USER_AFTER_lblPeanutInformation*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPeanutType}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                    {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPeanutVariety}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                    {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                    {/* START_USER_CODE-USER_BEFORE_ddGeneration*/}

                    {/* END_USER_CODE-USER_BEFORE_ddGeneration*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddGeneration}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddGeneration*/}

                    {/* END_USER_CODE-USER_AFTER_ddGeneration*/}
                    {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                    {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddSeg}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                    {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                    {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                    {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddOleic}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                    {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                    {/* START_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                    {/* END_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddEdibleOil}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddEdibleOil*/}

                    {/* END_USER_CODE-USER_AFTER_ddEdibleOil*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPeanutInfo*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPeanutInfo*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxShippedFrom*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxShippedFrom*/}

                  <GroupBoxWidget
                    conf={state.grpbxShippedFrom}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblShippedFrom*/}

                    {/* END_USER_CODE-USER_BEFORE_lblShippedFrom*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblShippedFrom}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblShippedFrom*/}

                    {/* END_USER_CODE-USER_AFTER_lblShippedFrom*/}
                    {/* START_USER_CODE-USER_BEFORE_ddWhseBinNum*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseBinNum*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseBinNum}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseBinNum*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseBinNum*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxShippedFrom*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxShippedFrom*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxShippedTo*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxShippedTo*/}

                  <GroupBoxWidget
                    conf={state.grpbxShippedTo}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblShippedTo*/}

                    {/* END_USER_CODE-USER_BEFORE_lblShippedTo*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblShippedTo}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblShippedTo*/}

                    {/* END_USER_CODE-USER_AFTER_lblShippedTo*/}
                    {/* START_USER_CODE-USER_BEFORE_txtDTWhseBinNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtDTWhseBinNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtDTWhseBinNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtDTWhseBinNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtDTWhseBinNum*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxShippedTo*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxShippedTo*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPntShippd*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPntShippd*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxInterDepartmentalTransfer*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxInterDepartmentalTransfer*/}

                <GroupBoxWidget
                  conf={state.grpbxInterDepartmentalTransfer}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblInterDeptTransfer*/}

                  {/* END_USER_CODE-USER_BEFORE_lblInterDeptTransfer*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblInterDeptTransfer}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblInterDeptTransfer*/}

                  {/* END_USER_CODE-USER_AFTER_lblInterDeptTransfer*/}
                  {/* START_USER_CODE-USER_BEFORE_ddIDTfromtoCompany*/}

                  {/* END_USER_CODE-USER_BEFORE_ddIDTfromtoCompany*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddIDTfromtoCompany}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddIDTfromtoCompany*/}

                  {/* END_USER_CODE-USER_AFTER_ddIDTfromtoCompany*/}
                  {/* START_USER_CODE-USER_BEFORE_txtIDTfromtoVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtIDTfromtoVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtIDTfromtoVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtIDTfromtoVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtIDTfromtoVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_ddIDTfromtoLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddIDTfromtoLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddIDTfromtoLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddIDTfromtoLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddIDTfromtoLocation*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxInterDepartmentalTransfer*/}

                {/* END_USER_CODE-USER_AFTER_grpbxInterDepartmentalTransfer*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxTradeDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxTradeDetails*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxScaleTickets*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxScaleTickets*/}

              <GroupBoxWidget conf={state.grpbxScaleTickets} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblScaleTickets*/}

                {/* END_USER_CODE-USER_BEFORE_lblScaleTickets*/}

                <LabelWidget
                  values={values}
                  conf={state.lblScaleTickets}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblScaleTickets*/}

                {/* END_USER_CODE-USER_AFTER_lblScaleTickets*/}
                {/* START_USER_CODE-USER_BEFORE_btnAddST*/}

                {/* END_USER_CODE-USER_BEFORE_btnAddST*/}

                <ButtonWidget
                  conf={state.btnAddST}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAddST*/}

                {/* END_USER_CODE-USER_AFTER_btnAddST*/}
                {/* START_USER_CODE-USER_BEFORE_btnEditST*/}

                {/* END_USER_CODE-USER_BEFORE_btnEditST*/}

                <ButtonWidget
                  conf={state.btnEditST}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEditST*/}

                {/* END_USER_CODE-USER_AFTER_btnEditST*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeleteST*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeleteST*/}

                <ButtonWidget
                  conf={state.btnDeleteST}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeleteST*/}

                {/* END_USER_CODE-USER_AFTER_btnDeleteST*/}
                {/* START_USER_CODE-USER_BEFORE_gridScaleTickets*/}

                {/* END_USER_CODE-USER_BEFORE_gridScaleTickets*/}

                <GridWidget
                  conf={state.gridScaleTickets}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridScaleTickets}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridScaleTickets*/}

                {/* END_USER_CODE-USER_AFTER_gridScaleTickets*/}
                {/* START_USER_CODE-USER_BEFORE_txtTckt*/}

                {/* END_USER_CODE-USER_BEFORE_txtTckt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTckt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTckt*/}

                {/* END_USER_CODE-USER_AFTER_txtTckt*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotal*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotal*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotal}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotal*/}

                {/* END_USER_CODE-USER_AFTER_txtTotal*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxScaleTickets*/}

              {/* END_USER_CODE-USER_AFTER_grpbxScaleTickets*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxTradeSettleProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxTradeSettleProfile*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxTradeSettleProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxReGrading*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxReGrading*/}

                <GroupBoxWidget conf={state.grpbxReGrading} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblReGrading*/}

                  {/* END_USER_CODE-USER_BEFORE_lblReGrading*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblReGrading}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblReGrading*/}

                  {/* END_USER_CODE-USER_AFTER_lblReGrading*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFMSampleWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFMSampleWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFMSampleWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFMSampleWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtFMSampleWt*/}
                  {/* START_USER_CODE-USER_BEFORE_radioGradePricingMethod*/}

                  {/* END_USER_CODE-USER_BEFORE_radioGradePricingMethod*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioGradePricingMethod}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioGradePricingMethod*/}

                  {/* END_USER_CODE-USER_AFTER_radioGradePricingMethod*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCleanSampleWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCleanSampleWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCleanSampleWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCleanSampleWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtCleanSampleWt*/}
                  {/* START_USER_CODE-USER_BEFORE_radioGramsPercentage*/}

                  {/* END_USER_CODE-USER_BEFORE_radioGramsPercentage*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioGramsPercentage}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioGramsPercentage*/}

                  {/* END_USER_CODE-USER_AFTER_radioGramsPercentage*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalSMKRScreen*/}
                  <GroupBoxWidget
                  conf={state.grpbxRegrdLeft}
                  screenConf={state}
                >
                  {/* END_USER_CODE-USER_BEFORE_txtTotalSMKRScreen*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalSMKRScreen}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalSMKRScreen*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalSMKRScreen*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalSMKRScreenPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalSMKRScreenPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalSMKRScreenPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalSMKRScreenPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalSMKRScreenPct*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtSMK1Gr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMK1Gr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMK1Gr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMK1Gr*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMK1Gr*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSMK1Pct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMK1Pct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMK1Pct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMK1Pct*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMK1Pct*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtSMK2Gr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMK2Gr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMK2Gr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMK2Gr*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMK2Gr*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSMK2Pct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMK2Pct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMK2Pct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMK2Pct*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMK2Pct*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtSMK3Gr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMK3Gr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMK3Gr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMK3Gr*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMK3Gr*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSMK3Pct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMK3Pct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMK3Pct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMK3Pct*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMK3Pct*/}
                 
                  {/* START_USER_CODE-USER_BEFORE_txtSMK4Gr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMK4Gr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMK4Gr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMK4Gr*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMK4Gr*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSMK4Pct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMK4Pct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMK4Pct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMK4Pct*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMK4Pct*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtSoundSplits*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSoundSplits*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSoundSplits}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSoundSplits*/}

                  {/* END_USER_CODE-USER_AFTER_txtSoundSplits*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSoundSplitsPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSoundSplitsPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSoundSplitsPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSoundSplitsPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtSoundSplitsPct*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtTotalSMK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalSMK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalSMK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalSMK*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalSMK*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtTotalOK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalOK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalOK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalOK*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalOK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalOKPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalOKPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalOKPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalOKPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalOKPct*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtOKRidingGr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOKRidingGr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOKRidingGr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOKRidingGr*/}

                  {/* END_USER_CODE-USER_AFTER_txtOKRidingGr*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOKRidingPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOKRidingPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOKRidingPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOKRidingPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtOKRidingPct*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtOKFallThru*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOKFallThru*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOKFallThru}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOKFallThru*/}

                  {/* END_USER_CODE-USER_AFTER_txtOKFallThru*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtOKDamageRiding*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOKDamageRiding*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOKDamageRiding}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOKDamageRiding*/}

                  {/* END_USER_CODE-USER_AFTER_txtOKDamageRiding*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtDamageSplits*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDamageSplits*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDamageSplits}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDamageSplits*/}

                  {/* END_USER_CODE-USER_AFTER_txtDamageSplits*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtTotalDamage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalDamage*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalDamage}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalDamage*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalDamage*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalDamage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalDamage*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalDamagePct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalDamage*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalDamage*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtTotalKernels*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalKernels*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalKernels}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalKernels*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalKernels*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtHulls*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHulls*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHulls}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHulls*/}

                  {/* END_USER_CODE-USER_AFTER_txtHulls*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHullsPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHullsPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHullsPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHullsPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtHullsPct*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtTotalKernelsAndHulls*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalKernelsAndHulls*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalKernelsAndHulls}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalKernelsAndHulls*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalKernelsAndHulls*/}
                  
                  {/* START_USER_CODE-USER_AFTER_txtFancyPct*/}
                  <GroupBoxWidget
                    conf={state.grpbxDamage}
                    screenConf={state}
                  >
                    {/* END_USER_CODE-USER_BEFORE_txtFreezeDamage*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtFreezeDamage}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtFreezeDamage*/}

                    {/* END_USER_CODE-USER_AFTER_txtFreezeDamage*/}
                    {/* START_USER_CODE-USER_BEFORE_txtFreezeDamagePct*/}

                    {/* END_USER_CODE-USER_BEFORE_txtFreezeDamagePct*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtFreezeDamagePct}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtFreezeDamagePct*/}
                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPittingDamage}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPittingDamagePct}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtConcealedRMD}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtConcealedRMDPct}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                  </GroupBoxWidget>
                  {/* END_USER_CODE-USER_AFTER_txtFancyPct*/}

                  {/* START_USER_CODE-USER_BEFORE_txtFreezeDamage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFreezeDamage*/}


                  {/* START_USER_CODE-USER_AFTER_txtFreezeDamage*/}

                  {/* END_USER_CODE-USER_AFTER_txtFreezeDamage*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFreezeDamagePct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFreezeDamagePct*/}


                  {/* START_USER_CODE-USER_AFTER_txtFreezeDamagePct*/}

                  {/* END_USER_CODE-USER_AFTER_txtFreezeDamagePct*/}
                 
                  {/* START_USER_CODE-USER_BEFORE_txtPittingDamage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPittingDamage*/}


                  {/* START_USER_CODE-USER_AFTER_txtPittingDamage*/}

                  {/* END_USER_CODE-USER_AFTER_txtPittingDamage*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPittingDamagePct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPittingDamagePct*/}


                  {/* START_USER_CODE-USER_AFTER_txtPittingDamagePct*/}

                  {/* END_USER_CODE-USER_AFTER_txtPittingDamagePct*/}
                  {/* START_USER_CODE-USER_BEFORE_txtConcealedRMD*/}

                  {/* END_USER_CODE-USER_BEFORE_txtConcealedRMD*/}


                  {/* START_USER_CODE-USER_AFTER_txtConcealedRMD*/}

                  {/* END_USER_CODE-USER_AFTER_txtConcealedRMD*/}
                  {/* START_USER_CODE-USER_BEFORE_txtConcealedRMDPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtConcealedRMDPct*/}


                  {/* START_USER_CODE-USER_AFTER_txtConcealedRMDPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtConcealedRMDPct*/}
                  
                  
                  </GroupBoxWidget>
                  <GroupBoxWidget
                  conf={state.grpbxRegrdRight}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_txtCrackedBroken*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCrackedBroken*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCrackedBroken}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCrackedBroken*/}

                  {/* END_USER_CODE-USER_AFTER_txtCrackedBroken*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCrackedBrokenPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCrackedBrokenPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCrackedBrokenPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCrackedBrokenPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtCrackedBrokenPct*/}
{/* START_USER_CODE-USER_BEFORE_txtDiscoloredShells*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDiscoloredShells*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDiscoloredShells}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDiscoloredShells*/}

                  {/* END_USER_CODE-USER_AFTER_txtDiscoloredShells*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDiscoloredShellsPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDiscoloredShellsPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDiscoloredShellsPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDiscoloredShellsPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtDiscoloredShellsPct*/}
{/* START_USER_CODE-USER_BEFORE_txtHullBrightness*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHullBrightness*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHullBrightness}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHullBrightness*/}

                  {/* END_USER_CODE-USER_AFTER_txtHullBrightness*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHullBrightnessPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHullBrightnessPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHullBrightnessPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHullBrightnessPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtHullBrightnessPct*/}
 {/* START_USER_CODE-USER_BEFORE_txtJumbo*/}

                  {/* END_USER_CODE-USER_BEFORE_txtJumbo*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtJumbo}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtJumbo*/}

                  {/* END_USER_CODE-USER_AFTER_txtJumbo*/}
                  {/* START_USER_CODE-USER_BEFORE_txtJumboPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtJumboPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtJumboPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtJumboPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtJumboPct*/}
{/* START_USER_CODE-USER_BEFORE_txtKernRidingELK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtKernRidingELK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtKernRidingELK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtKernRidingELK*/}

                  {/* END_USER_CODE-USER_AFTER_txtKernRidingELK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtKernRidingELKPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtKernRidingELKPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtKernRidingELKPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtKernRidingELKPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtKernRidingELKPct*/}
{/* START_USER_CODE-USER_BEFORE_txtTotalELKDamage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalELKDamage*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalELKDamage}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalELKDamage*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalELKDamage*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalELKDamagePct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalELKDamagePct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalELKDamagePct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalELKDamagePct*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalELKDamagePct*/}
{/* START_USER_CODE-USER_BEFORE_txtNetELK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNetELK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetELK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNetELK*/}

                  {/* END_USER_CODE-USER_AFTER_txtNetELK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNetELKPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNetELKPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetELKPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNetELKPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtNetELKPct*/}
{/* START_USER_CODE-USER_BEFORE_txtKernRidingPrescribe*/}

                  {/* END_USER_CODE-USER_BEFORE_txtKernRidingPrescribe*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtKernRidingPrescribe}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtKernRidingPrescribe*/}

                  {/* END_USER_CODE-USER_AFTER_txtKernRidingPrescribe*/}
                  {/* START_USER_CODE-USER_BEFORE_txtKernRidingPrescribePct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtKernRidingPrescribePct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtKernRidingPrescribePct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtKernRidingPrescribePct*/}

                  {/* END_USER_CODE-USER_AFTER_txtKernRidingPrescribePct*/}
{/* START_USER_CODE-USER_BEFORE_txtForeignMtrl*/}

                  {/* END_USER_CODE-USER_BEFORE_txtForeignMtrl*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtForeignMtrl}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtForeignMtrl*/}

                  {/* END_USER_CODE-USER_AFTER_txtForeignMtrl*/}
                  {/* START_USER_CODE-USER_BEFORE_txtForeignMaterialPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtForeignMaterialPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtForeignMaterialPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtForeignMaterialPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtForeignMaterialPct*/}
{/* START_USER_CODE-USER_BEFORE_txtOtherFM*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOtherFM*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOtherFM}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOtherFM*/}

                  {/* END_USER_CODE-USER_AFTER_txtOtherFM*/}
{/* START_USER_CODE-USER_BEFORE_txtTotalSLK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalSLK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalSLK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalSLK*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalSLK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalSLKPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalSLKPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalSLKPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalSLKPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalSLKPct*/}
{/* START_USER_CODE-USER_BEFORE_txtLSKRidingGr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSKRidingGr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSKRidingGr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSKRidingGr*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSKRidingGr*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLSKRidingPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSKRidingPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSKRidingPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSKRidingPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSKRidingPct*/}
{/* START_USER_CODE-USER_BEFORE_txtLSKFallThru*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSKFallThru*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSKFallThru}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSKFallThru*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSKFallThru*/}
{/* START_USER_CODE-USER_BEFORE_txtLSKDamageRiding*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSKDamageRiding*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSKDamageRiding}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSKDamageRiding*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSKDamageRiding*/}
{/* START_USER_CODE-USER_BEFORE_txtMoisture*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMoisture*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMoisture}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMoisture*/}

                  {/* END_USER_CODE-USER_AFTER_txtMoisture*/}
{/* START_USER_CODE-USER_BEFORE_txtFancy*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFancy*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFancy}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFancy*/}

                  {/* END_USER_CODE-USER_AFTER_txtFancy*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFancyPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFancyPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFancyPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
 {/* START_USER_CODE-USER_BEFORE_chkboxReGrading*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxReGrading*/}

                  <CheckboxGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxReGrading}
                    screenConf={state}
                  ></CheckboxGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxReGrading*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxReGrading*/}
{/* START_USER_CODE-USER_BEFORE_txtVicamReading*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVicamReading*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVicamReading}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVicamReading*/}

                  {/* END_USER_CODE-USER_AFTER_txtVicamReading*/}
{/* START_USER_CODE-USER_BEFORE_txtSeedMeetingSpec*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSeedMeetingSpec*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSeedMeetingSpec}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSeedMeetingSpec*/}

                  {/* END_USER_CODE-USER_AFTER_txtSeedMeetingSpec*/}

                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_BEFORE_txtProbingPattern*/}
                  <GroupBoxWidget
                  conf={state.grpbxRegrdLeftBottom}
                  screenConf={state}
                >
                  {/* END_USER_CODE-USER_BEFORE_txtProbingPattern*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtProbingPattern}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtProbingPattern*/}

                  {/* END_USER_CODE-USER_AFTER_txtProbingPattern*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtKernelsREL*/}

                  {/* END_USER_CODE-USER_BEFORE_txtKernelsREL*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtKernelsREL}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtKernelsREL*/}

                  {/* END_USER_CODE-USER_AFTER_txtKernelsREL*/}
                  
                  {/* START_USER_CODE-USER_BEFORE_txtKernelsRPS*/}

                  {/* END_USER_CODE-USER_BEFORE_txtKernelsRPS*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtKernelsRPS}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtKernelsRPS*/}

                  {/* END_USER_CODE-USER_AFTER_txtKernelsRPS*/}
                 
                  {/* START_USER_CODE-USER_BEFORE_txtDamagedKRS*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDamagedKRS*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDamagedKRS}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDamagedKRS*/}
                    </GroupBoxWidget>
                    <GroupBoxWidget
                  conf={state.grpbxRegrdRightBottom}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_txtMeterReading*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMeterReading*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMeterReading}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMeterReading*/}

                  {/* END_USER_CODE-USER_AFTER_txtMeterReading*/}
 {/* START_USER_CODE-USER_BEFORE_txtBluePanWT*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBluePanWT*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBluePanWT}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBluePanWT*/}

                  {/* END_USER_CODE-USER_AFTER_txtBluePanWT*/}
{/* START_USER_CODE-USER_BEFORE_txtELKDamaged*/}

                  {/* END_USER_CODE-USER_BEFORE_txtELKDamaged*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtELKDamaged}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtELKDamaged*/}

                  {/* END_USER_CODE-USER_AFTER_txtELKDamaged*/}
                </GroupBoxWidget>
                  {/* END_USER_CODE-USER_AFTER_txtDamagedKRS*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxReGrading*/}

                {/* END_USER_CODE-USER_AFTER_grpbxReGrading*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxValueCalculation*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxValueCalculation*/}

                <GroupBoxWidget
                  conf={state.grpbxValueCalculation}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblValueCalculation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblValueCalculation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblValueCalculation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblValueCalculation*/}

                  {/* END_USER_CODE-USER_AFTER_lblValueCalculation*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCalculate*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCalculate*/}

                  <ButtonWidget
                    conf={state.btnCalculate}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCalculate*/}

                  {/* END_USER_CODE-USER_AFTER_btnCalculate*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWtIncludingVehicle*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWtIncludingVehicle*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWtIncludingVehicle}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWtIncludingVehicle*/}

                  {/* END_USER_CODE-USER_AFTER_txtWtIncludingVehicle*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVehicleWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVehicleWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVehicleWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVehicleWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtVehicleWt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtGrossWeight*/}

                  {/* END_USER_CODE-USER_BEFORE_txtGrossWeight*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtGrossWeight}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtGrossWeight*/}

                  {/* END_USER_CODE-USER_AFTER_txtGrossWeight*/}
                  {/* START_USER_CODE-USER_BEFORE_txtForeignMaterial*/}

                  {/* END_USER_CODE-USER_BEFORE_txtForeignMaterial*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtForeignMaterial}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtForeignMaterial*/}

                  {/* END_USER_CODE-USER_AFTER_txtForeignMaterial*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWeightLessFM*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWeightLessFM*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWeightLessFM}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWeightLessFM*/}

                  {/* END_USER_CODE-USER_AFTER_txtWeightLessFM*/}
                  {/* START_USER_CODE-USER_BEFORE_txtExcessMoisture*/}

                  {/* END_USER_CODE-USER_BEFORE_txtExcessMoisture*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtExcessMoisture}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtExcessMoisture*/}

                  {/* END_USER_CODE-USER_AFTER_txtExcessMoisture*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNetWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNetWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNetWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtNetWt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLSK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSK*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNetWtExcludingLSK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNetWtExcludingLSK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetWtExcludingLSK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNetWtExcludingLSK*/}

                  {/* END_USER_CODE-USER_AFTER_txtNetWtExcludingLSK*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPerTon*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPerTon*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPerTon}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPerTon*/}

                  {/* END_USER_CODE-USER_AFTER_lblPerTon*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPerLb*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPerLb*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPerLb}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPerLb*/}

                  {/* END_USER_CODE-USER_AFTER_lblPerLb*/}
                  {/* START_USER_CODE-USER_BEFORE_txtKernelValuePerTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtKernelValuePerTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtKernelValuePerTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtKernelValuePerTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtKernelValuePerTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtKernelValuePerPound*/}

                  {/* END_USER_CODE-USER_BEFORE_txtKernelValuePerPound*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtKernelValuePerPound}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtKernelValuePerPound*/}

                  {/* END_USER_CODE-USER_AFTER_txtKernelValuePerPound*/}
                  {/* START_USER_CODE-USER_BEFORE_txtELKPremiumPerTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtELKPremiumPerTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtELKPremiumPerTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtELKPremiumPerTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtELKPremiumPerTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtELKPremiumPerPound*/}

                  {/* END_USER_CODE-USER_BEFORE_txtELKPremiumPerPound*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtELKPremiumPerPound}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtELKPremiumPerPound*/}

                  {/* END_USER_CODE-USER_AFTER_txtELKPremiumPerPound*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalPerTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalPerTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalPerTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalPerTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalPerTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalPerPound*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalPerPound*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalPerPound}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalPerPound*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalPerPound*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDamagePerTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDamagePerTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDamagePerTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDamagePerTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtDamagePerTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDamagePerPound*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDamagePerPound*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDamagePerPound}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDamagePerPound*/}

                  {/* END_USER_CODE-USER_AFTER_txtDamagePerPound*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAccessFMPerTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAccessFMPerTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAccessFMPerTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAccessFMPerTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtAccessFMPerTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAccessFMPerPound*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAccessFMPerPound*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAccessFMPerPound}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAccessFMPerPound*/}

                  {/* END_USER_CODE-USER_AFTER_txtAccessFMPerPound*/}
                  {/* START_USER_CODE-USER_BEFORE_txtExcessSplitsPerTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtExcessSplitsPerTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtExcessSplitsPerTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtExcessSplitsPerTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtExcessSplitsPerTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtExcessSplitsPerPound*/}

                  {/* END_USER_CODE-USER_BEFORE_txtExcessSplitsPerPound*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtExcessSplitsPerPound}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtExcessSplitsPerPound*/}

                  {/* END_USER_CODE-USER_AFTER_txtExcessSplitsPerPound*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAFlavusPerTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAFlavusPerTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAFlavusPerTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAFlavusPerTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtAFlavusPerTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAFlavusPerPound*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAFlavusPerPound*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAFlavusPerPound}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAFlavusPerPound*/}

                  {/* END_USER_CODE-USER_AFTER_txtAFlavusPerPound*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalDiscountsPerTon*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalDiscountsPerTon*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalDiscountsPerTon}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalDiscountsPerTon*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalDiscountsPerTon*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalDiscountsPerPound*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalDiscountsPerPound*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalDiscountsPerPound}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalDiscountsPerPound*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalDiscountsPerPound*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNetValPerTonExclSLK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNetValPerTonExclSLK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetValPerTonExclSLK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNetValPerTonExclSLK*/}

                  {/* END_USER_CODE-USER_AFTER_txtNetValPerTonExclSLK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtValPerPoundExclSLK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtValPerPoundExclSLK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtValPerPoundExclSLK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtValPerPoundExclSLK*/}

                  {/* END_USER_CODE-USER_AFTER_txtValPerPoundExclSLK*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPerLb2*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPerLb2*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPerLb2}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPerLb2*/}

                  {/* END_USER_CODE-USER_AFTER_lblPerLb2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtValPerPoundInclSLK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtValPerPoundInclSLK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtValPerPoundInclSLK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtValPerPoundInclSLK*/}

                  {/* END_USER_CODE-USER_AFTER_txtValPerPoundInclSLK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOI*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOI*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOI}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOI*/}

                  {/* END_USER_CODE-USER_AFTER_txtOI*/}
                  {/* START_USER_CODE-USER_BEFORE_txtH*/}

                  {/* END_USER_CODE-USER_BEFORE_txtH*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtH}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtH*/}

                  {/* END_USER_CODE-USER_AFTER_txtH*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalLoadVal*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalLoadVal*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalLoadVal}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalLoadVal*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalLoadVal*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPerG*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPerG*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPerG}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPerG*/}

                  {/* END_USER_CODE-USER_AFTER_txtPerG*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPerLb3*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPerLb3*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPerLb3}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPerLb3*/}

                  {/* END_USER_CODE-USER_AFTER_lblPerLb3*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxValueCalculation*/}

                {/* END_USER_CODE-USER_AFTER_grpbxValueCalculation*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSettlementInformation*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSettlementInformation*/}

                <GroupBoxWidget
                  conf={state.grpbxSettlementInformation}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblSettlementInformation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSettlementInformation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSettlementInformation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSettlementInformation*/}

                  {/* END_USER_CODE-USER_AFTER_lblSettlementInformation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNetWeight*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNetWeight*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetWeight}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNetWeight*/}

                  {/* END_USER_CODE-USER_AFTER_txtNetWeight*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalLoadValue*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalLoadValue*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalLoadValue}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalLoadValue*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalLoadValue*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTKCLbs*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTKCLbs*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTKCLbs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTKCLbs*/}

                  {/* END_USER_CODE-USER_AFTER_txtTKCLbs*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAgreementPrice*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAgreementPrice*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAgreementPrice}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAgreementPrice*/}

                  {/* END_USER_CODE-USER_AFTER_txtAgreementPrice*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSupportPercent*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSupportPercent*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSupportPercent}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSupportPercent*/}

                  {/* END_USER_CODE-USER_AFTER_txtSupportPercent*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSegValue*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSegValue*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSegValue}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSegValue*/}

                  {/* END_USER_CODE-USER_AFTER_txtSegValue*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPremiums*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPremiums*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPremiums}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPremiums*/}

                  {/* END_USER_CODE-USER_AFTER_txtPremiums*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDeductions*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDeductions*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDeductions}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDeductions*/}

                  {/* END_USER_CODE-USER_AFTER_txtDeductions*/}
                  {/* START_USER_CODE-USER_BEFORE_txtProceeds*/}

                  {/* END_USER_CODE-USER_BEFORE_txtProceeds*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtProceeds}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtProceeds*/}

                  {/* END_USER_CODE-USER_AFTER_txtProceeds*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSettlementInformation*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSettlementInformation*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSeg*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSeg*/}

                <GroupBoxWidget conf={state.grpbxSeg} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblPrintSeg*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPrintSeg*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPrintSeg}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPrintSeg*/}

                  {/* END_USER_CODE-USER_AFTER_lblPrintSeg*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxPrintSeg*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxPrintSeg*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxPrintSeg}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxPrintSeg*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxPrintSeg*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLoad*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLoad*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLoad}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLoad*/}

                  {/* END_USER_CODE-USER_AFTER_txtLoad*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLot*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLot*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLot}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLot*/}

                  {/* END_USER_CODE-USER_AFTER_txtLot*/}
                  {/* START_USER_CODE-USER_BEFORE_dtStartDateTime*/}

                  {/* END_USER_CODE-USER_BEFORE_dtStartDateTime*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.dtStartDateTime}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_dtStartDateTime*/}

                  {/* END_USER_CODE-USER_AFTER_dtStartDateTime*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWormDamage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWormDamage*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWormDamage}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWormDamage*/}

                  {/* END_USER_CODE-USER_AFTER_txtWormDamage*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSeal*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSeal*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSeal}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSeal*/}

                  {/* END_USER_CODE-USER_AFTER_txtSeal*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSeg*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSeg*/}
                {/* START_USER_CODE-USER_BEFORE_txtarInspectionRemarks*/}

                {/* END_USER_CODE-USER_BEFORE_txtarInspectionRemarks*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarInspectionRemarks}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarInspectionRemarks*/}

                {/* END_USER_CODE-USER_AFTER_txtarInspectionRemarks*/}
                {/* START_USER_CODE-USER_BEFORE_txtarSettleRemarks*/}

                {/* END_USER_CODE-USER_BEFORE_txtarSettleRemarks*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarSettleRemarks}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarSettleRemarks*/}

                {/* END_USER_CODE-USER_AFTER_txtarSettleRemarks*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxTradeSettleProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxTradeSettleProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblCropYear2*/}

                {/* END_USER_CODE-USER_BEFORE_lblCropYear2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCropYear2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCropYear2*/}

                {/* END_USER_CODE-USER_AFTER_lblCropYear2*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnSave*/}

                {/* END_USER_CODE-USER_BEFORE_btnSave*/}

                <ButtonWidget
                  conf={state.btnSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSave*/}

                {/* END_USER_CODE-USER_AFTER_btnSave*/}
                {/* START_USER_CODE-USER_BEFORE_btnVoid*/}

                {/* END_USER_CODE-USER_BEFORE_btnVoid*/}

                <ButtonWidget
                  conf={state.btnVoid}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVoid*/}

                {/* END_USER_CODE-USER_AFTER_btnVoid*/}
                {/* START_USER_CODE-USER_BEFORE_btnUnvoid*/}

                {/* END_USER_CODE-USER_BEFORE_btnUnvoid*/}

                <ButtonWidget
                  conf={state.btnUnvoid}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUnvoid*/}

                {/* END_USER_CODE-USER_AFTER_btnUnvoid*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreateSettlement*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreateSettlement*/}

                <ButtonWidget
                  conf={state.btnCreateSettlement}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreateSettlement*/}

                {/* END_USER_CODE-USER_AFTER_btnCreateSettlement*/}
                {/* START_USER_CODE-USER_BEFORE_btnReversal*/}

                {/* END_USER_CODE-USER_BEFORE_btnReversal*/}

                <ButtonWidget
                  conf={state.btnReversal}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReversal*/}

                {/* END_USER_CODE-USER_AFTER_btnReversal*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintSettlement*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintSettlement*/}

                <ButtonWidget
                  conf={state.btnPrintSettlement}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintSettlement*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintSettlement*/}
                {/* START_USER_CODE-USER_BEFORE_btnPremsDeduct*/}

                {/* END_USER_CODE-USER_BEFORE_btnPremsDeduct*/}

                <ButtonWidget
                  conf={state.btnPremsDeduct}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPremsDeduct*/}

                {/* END_USER_CODE-USER_AFTER_btnPremsDeduct*/}
                {/* START_USER_CODE-USER_BEFORE_btnPayments*/}

                {/* END_USER_CODE-USER_BEFORE_btnPayments*/}

                <ButtonWidget
                  conf={state.btnPayments}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPayments*/}

                {/* END_USER_CODE-USER_AFTER_btnPayments*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeductTrack*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeductTrack*/}

                <ButtonWidget
                  conf={state.btnDeductTrack}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeductTrack*/}

                {/* END_USER_CODE-USER_AFTER_btnDeductTrack*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_btnAgreeAppl*/}

                {/* END_USER_CODE-USER_BEFORE_btnAgreeAppl*/}

                <ButtonWidget
                  conf={state.btnAgreeAppl}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAgreeAppl*/}

                {/* END_USER_CODE-USER_AFTER_btnAgreeAppl*/}
                {/* START_USER_CODE-USER_BEFORE_btnAcctDist*/}

                {/* END_USER_CODE-USER_BEFORE_btnAcctDist*/}

                <ButtonWidget
                  conf={state.btnAcctDist}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAcctDist*/}

                {/* END_USER_CODE-USER_AFTER_btnAcctDist*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_TradeSettleProfile*/}

              {/* END_USER_CODE-USER_AFTER_TradeSettleProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_TradeSettleProfile);
