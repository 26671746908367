/* eslint-disable*/
import React from "react";
import WarehouseReceipts_WarehouseReceiptUpload from "./WarehouseReceiptUpload";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("WarehouseReceiptUpload Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WarehouseReceiptUpload />);
    });
  });
  afterEach(cleanup);
  test("is WarehouseReceiptUpload Loads Successfully", () => {
    expect(screen.getByText("WarehouseReceiptUpload")).toBeInTheDocument;
  });
  test("Custom Test Cases for WarehouseReceiptUpload", () => {
    // START_USER_CODE-USER_WarehouseReceiptUpload_Custom_Test_Case
    // END_USER_CODE-USER_WarehouseReceiptUpload_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_WarehouseReceiptUpload />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptUpload_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptUpload_btnExportToExcel")
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnImportLockin(Button Widget) Test Cases", async () => {
    const btnImportLockin = screen.getByTestId("btnImportLockin");
    expect(btnImportLockin).toBeInTheDocument;
    expect(btnImportLockin.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptUpload_btnImportLockin")
    );
  });
  test("Custom Test Cases for btnImportLockin", () => {
    // START_USER_CODE-USER_btnImportLockin_TEST
    // END_USER_CODE-USER_btnImportLockin_TEST
  });
  test("btnPrintResult(Button Widget) Test Cases", async () => {
    const btnPrintResult = screen.getByTestId("btnPrintResult");
    expect(btnPrintResult).toBeInTheDocument;
    expect(btnPrintResult.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptUpload_btnPrintResult")
    );
  });
  test("Custom Test Cases for btnPrintResult", () => {
    // START_USER_CODE-USER_btnPrintResult_TEST
    // END_USER_CODE-USER_btnPrintResult_TEST
  });
  test("btnUpdateWR(Button Widget) Test Cases", async () => {
    const btnUpdateWR = screen.getByTestId("btnUpdateWR");
    expect(btnUpdateWR).toBeInTheDocument;
    expect(btnUpdateWR.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptUpload_btnUpdateWR")
    );
  });
  test("Custom Test Cases for btnUpdateWR", () => {
    // START_USER_CODE-USER_btnUpdateWR_TEST
    // END_USER_CODE-USER_btnUpdateWR_TEST
  });
  test("btnViewlockin(Button Widget) Test Cases", async () => {
    const btnViewlockin = screen.getByTestId("btnViewlockin");
    expect(btnViewlockin).toBeInTheDocument;
    expect(btnViewlockin.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptUpload_btnViewlockin")
    );
  });
  test("Custom Test Cases for btnViewlockin", () => {
    // START_USER_CODE-USER_btnViewlockin_TEST
    // END_USER_CODE-USER_btnViewlockin_TEST
  });
  test("gridUploadLockInfo(Grid Widget) Test Cases", async () => {
    let gridUploadLockInfo = screen.getByTestId("gridUploadLockInfo");
    let gridUploadLockInfobtn =
      gridUploadLockInfo.nextElementSibling.firstElementChild;
    gridUploadLockInfo =
      gridUploadLockInfo.parentElement.parentElement.parentElement;
    expect(gridUploadLockInfo.tagName).toBe("DIV");
    expect(gridUploadLockInfo.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptUpload_gridUploadLockInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridUploadLockInfo", () => {
    // START_USER_CODE-USER_gridUploadLockInfo_TEST
    // END_USER_CODE-USER_gridUploadLockInfo_TEST
  });
  test("grpbxAction(GroupBox Widget) Test Cases", async () => {
    const grpbxAction = screen.getByTestId("grpbxAction");
    expect(grpbxAction.tagName).toBe("BUTTON");
    expect(grpbxAction.type).toBe("button");
    expect(grpbxAction.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAction", () => {
    // START_USER_CODE-USER_grpbxAction_TEST
    // END_USER_CODE-USER_grpbxAction_TEST
  });
  test("grpbxLoadhistory(GroupBox Widget) Test Cases", async () => {
    const grpbxLoadhistory = screen.getByTestId("grpbxLoadhistory");
    expect(grpbxLoadhistory.tagName).toBe("BUTTON");
    expect(grpbxLoadhistory.type).toBe("button");
    expect(grpbxLoadhistory.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxLoadhistory", () => {
    // START_USER_CODE-USER_grpbxLoadhistory_TEST
    // END_USER_CODE-USER_grpbxLoadhistory_TEST
  });
  test("grpbxUploadLockInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxUploadLockInfo = screen.getByTestId("grpbxUploadLockInfo");
    expect(grpbxUploadLockInfo.tagName).toBe("BUTTON");
    expect(grpbxUploadLockInfo.type).toBe("button");
    expect(grpbxUploadLockInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxUploadLockInfo", () => {
    // START_USER_CODE-USER_grpbxUploadLockInfo_TEST
    // END_USER_CODE-USER_grpbxUploadLockInfo_TEST
  });
  test("grpbxuploadlockinfobuttons(GroupBox Widget) Test Cases", async () => {
    const grpbxuploadlockinfobuttons = screen.getByTestId(
      "grpbxuploadlockinfobuttons"
    );
    expect(grpbxuploadlockinfobuttons.tagName).toBe("BUTTON");
    expect(grpbxuploadlockinfobuttons.type).toBe("button");
    expect(grpbxuploadlockinfobuttons.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxuploadlockinfobuttons", () => {
    // START_USER_CODE-USER_grpbxuploadlockinfobuttons_TEST
    // END_USER_CODE-USER_grpbxuploadlockinfobuttons_TEST
  });
  test("lbladdedBy(Label Widget) Test Cases", async () => {
    const lbladdedBy = screen.getByTestId("lbladdedBy");
    expect(lbladdedBy.tagName).toBe("LABEL");
    expect(lbladdedBy.classList).toContain("form-label");
    expect(lbladdedBy.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptUpload_lbladdedBy")
    );
  });
  test("Custom Test Cases for lbladdedBy", () => {
    // START_USER_CODE-USER_lbladdedBy_TEST
    // END_USER_CODE-USER_lbladdedBy_TEST
  });
  test("lblAddedbyvalue(Label Widget) Test Cases", async () => {
    const lblAddedbyvalue = screen.getByTestId("lblAddedbyvalue");
    expect(lblAddedbyvalue.tagName).toBe("LABEL");
    expect(lblAddedbyvalue.classList).toContain("form-label");
  });
  test("Custom Test Cases for lblAddedbyvalue", () => {
    // START_USER_CODE-USER_lblAddedbyvalue_TEST
    // END_USER_CODE-USER_lblAddedbyvalue_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptUpload_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedbyvalue(Label Widget) Test Cases", async () => {
    const lblChangedbyvalue = screen.getByTestId("lblChangedbyvalue");
    expect(lblChangedbyvalue.tagName).toBe("LABEL");
    expect(lblChangedbyvalue.classList).toContain("form-label");
  });
  test("Custom Test Cases for lblChangedbyvalue", () => {
    // START_USER_CODE-USER_lblChangedbyvalue_TEST
    // END_USER_CODE-USER_lblChangedbyvalue_TEST
  });
  test("lblLoadHistory(Label Widget) Test Cases", async () => {
    const lblLoadHistory = screen.getByTestId("lblLoadHistory");
    expect(lblLoadHistory.tagName).toBe("LABEL");
    expect(lblLoadHistory.classList).toContain("form-label");
    expect(lblLoadHistory.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptUpload_lblLoadHistory")
    );
  });
  test("Custom Test Cases for lblLoadHistory", () => {
    // START_USER_CODE-USER_lblLoadHistory_TEST
    // END_USER_CODE-USER_lblLoadHistory_TEST
  });
  test("lblUploadlockInfo(Label Widget) Test Cases", async () => {
    const lblUploadlockInfo = screen.getByTestId("lblUploadlockInfo");
    expect(lblUploadlockInfo.tagName).toBe("LABEL");
    expect(lblUploadlockInfo.classList).toContain("form-label");
    expect(lblUploadlockInfo.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptUpload_lblUploadlockInfo")
    );
  });
  test("Custom Test Cases for lblUploadlockInfo", () => {
    // START_USER_CODE-USER_lblUploadlockInfo_TEST
    // END_USER_CODE-USER_lblUploadlockInfo_TEST
  });
  test("lblImportedLockInMsg(Label Widget) Test Cases", async () => {
    const lblImportedLockInMsg = screen.getByTestId("lblImportedLockInMsg");
    expect(lblImportedLockInMsg.tagName).toBe("LABEL");
    expect(lblImportedLockInMsg.classList).toContain("form-label");
    expect(lblImportedLockInMsg.textContent).toEqual(
      t("WarehouseReceipts:WarehouseReceiptUpload_lblImportedLockInMsg")
    );
  });
  test("Custom Test Cases for lblImportedLockInMsg", () => {
    // START_USER_CODE-USER_lblImportedLockInMsg_TEST
    // END_USER_CODE-USER_lblImportedLockInMsg_TEST
  });
  test("gridUploadLockInfo_txtcolAction(Grid Widget) Test Cases", async () => {
    let gridUploadLockInfo_txtcolAction = screen.getByTestId(
      "gridUploadLockInfo"
    );
    let gridUploadLockInfo_txtcolActionbtn =
      gridUploadLockInfo_txtcolAction.nextElementSibling.firstElementChild;
    gridUploadLockInfo_txtcolAction =
      gridUploadLockInfo_txtcolAction.parentElement.parentElement.parentElement;
    expect(gridUploadLockInfo_txtcolAction.tagName).toBe("DIV");
    expect(gridUploadLockInfo_txtcolAction.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptUpload_gridUploadLockInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAction", () => {
    // START_USER_CODE-USER_txtcolAction_TEST
    // END_USER_CODE-USER_txtcolAction_TEST
  });
  test("gridUploadLockInfo_txtcolLckInWR(Grid Widget) Test Cases", async () => {
    let gridUploadLockInfo_txtcolLckInWR = screen.getByTestId(
      "gridUploadLockInfo"
    );
    let gridUploadLockInfo_txtcolLckInWRbtn =
      gridUploadLockInfo_txtcolLckInWR.nextElementSibling.firstElementChild;
    gridUploadLockInfo_txtcolLckInWR =
      gridUploadLockInfo_txtcolLckInWR.parentElement.parentElement
        .parentElement;
    expect(gridUploadLockInfo_txtcolLckInWR.tagName).toBe("DIV");
    expect(gridUploadLockInfo_txtcolLckInWR.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptUpload_gridUploadLockInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLckInWR", () => {
    // START_USER_CODE-USER_txtcolLckInWR_TEST
    // END_USER_CODE-USER_txtcolLckInWR_TEST
  });
  test("gridUploadLockInfo_txtcolLockInCCCLoc(Grid Widget) Test Cases", async () => {
    let gridUploadLockInfo_txtcolLockInCCCLoc = screen.getByTestId(
      "gridUploadLockInfo"
    );
    let gridUploadLockInfo_txtcolLockInCCCLocbtn =
      gridUploadLockInfo_txtcolLockInCCCLoc.nextElementSibling
        .firstElementChild;
    gridUploadLockInfo_txtcolLockInCCCLoc =
      gridUploadLockInfo_txtcolLockInCCCLoc.parentElement.parentElement
        .parentElement;
    expect(gridUploadLockInfo_txtcolLockInCCCLoc.tagName).toBe("DIV");
    expect(gridUploadLockInfo_txtcolLockInCCCLoc.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptUpload_gridUploadLockInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLockInCCCLoc", () => {
    // START_USER_CODE-USER_txtcolLockInCCCLoc_TEST
    // END_USER_CODE-USER_txtcolLockInCCCLoc_TEST
  });
  test("gridUploadLockInfo_txtcolLockInCropYear(Grid Widget) Test Cases", async () => {
    let gridUploadLockInfo_txtcolLockInCropYear = screen.getByTestId(
      "gridUploadLockInfo"
    );
    let gridUploadLockInfo_txtcolLockInCropYearbtn =
      gridUploadLockInfo_txtcolLockInCropYear.nextElementSibling
        .firstElementChild;
    gridUploadLockInfo_txtcolLockInCropYear =
      gridUploadLockInfo_txtcolLockInCropYear.parentElement.parentElement
        .parentElement;
    expect(gridUploadLockInfo_txtcolLockInCropYear.tagName).toBe("DIV");
    expect(gridUploadLockInfo_txtcolLockInCropYear.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptUpload_gridUploadLockInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLockInCropYear", () => {
    // START_USER_CODE-USER_txtcolLockInCropYear_TEST
    // END_USER_CODE-USER_txtcolLockInCropYear_TEST
  });
  test("gridUploadLockInfo_txtcolLockInDate(Grid Widget) Test Cases", async () => {
    let gridUploadLockInfo_txtcolLockInDate = screen.getByTestId(
      "gridUploadLockInfo"
    );
    let gridUploadLockInfo_txtcolLockInDatebtn =
      gridUploadLockInfo_txtcolLockInDate.nextElementSibling.firstElementChild;
    gridUploadLockInfo_txtcolLockInDate =
      gridUploadLockInfo_txtcolLockInDate.parentElement.parentElement
        .parentElement;
    expect(gridUploadLockInfo_txtcolLockInDate.tagName).toBe("DIV");
    expect(gridUploadLockInfo_txtcolLockInDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptUpload_gridUploadLockInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLockInDate", () => {
    // START_USER_CODE-USER_txtcolLockInDate_TEST
    // END_USER_CODE-USER_txtcolLockInDate_TEST
  });
  test("gridUploadLockInfo_txtcolLockInRate(Grid Widget) Test Cases", async () => {
    let gridUploadLockInfo_txtcolLockInRate = screen.getByTestId(
      "gridUploadLockInfo"
    );
    let gridUploadLockInfo_txtcolLockInRatebtn =
      gridUploadLockInfo_txtcolLockInRate.nextElementSibling.firstElementChild;
    gridUploadLockInfo_txtcolLockInRate =
      gridUploadLockInfo_txtcolLockInRate.parentElement.parentElement
        .parentElement;
    expect(gridUploadLockInfo_txtcolLockInRate.tagName).toBe("DIV");
    expect(gridUploadLockInfo_txtcolLockInRate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("WarehouseReceipts:WarehouseReceiptUpload_gridUploadLockInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLockInRate", () => {
    // START_USER_CODE-USER_txtcolLockInRate_TEST
    // END_USER_CODE-USER_txtcolLockInRate_TEST
  });
});
