import React from "react";
import Form from "react-bootstrap/Form";
const FormControl = ({
  value: initialValue,
  row,
  column,
  updateGridData, // This is a custom function that we supplied to our table instance
  name,id,placeholder,isInvalid,readOnly,
  title,maxLength,autoComplete,disabled,
  className,as,size
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)
  const onChange = e => {
    setValue(e.target.value)
  }

  if(title){
      console.error(title);  
  }
  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    updateGridData(row.rowID$, column.name, value);
  }
  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
  return (         <div className="py-2" title={title} role="tooltip">
  <Form.Control
    name={name}
    placeholder={placeholder}
    size={size}
    disabled={disabled}
    readOnly={readOnly}
    className={className}
    value={value}
    isInvalid={isInvalid}
    title={title}
    onChange={onChange}
    onBlur={onBlur} 
    as={as}
  />
</div>
)
}
export default FormControl;