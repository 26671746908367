/* eslint-disable*/
import React, { useCallback } from "react";
import { authProvider } from '../src/service/authProvider'
import Button from "react-bootstrap/Button";
import { Redirect } from "react-router-dom";

function ContactAdministrator(props) {
    function clearStorage() {
        window.sessionStorage.clear();
        window.localStorage.clear();
    }

    const onLoginClick = useCallback(() => {
        clearStorage();
        authProvider.logout();
    }, []);

    return (


        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#333E48",
            justifyContent: "center"
          }}>
            <h2 style={{ color: '#FDC741' }}>{props.msg}</h2>
            <Button onClick={onLoginClick} >Click to login</Button>
            {props.type=="clear"?clearStorage():""}
        </div>
    );
}

export default ContactAdministrator;